export const tokens = [
  {
    '592': [
        {
            'symbol': 'ACS',
            'name': 'ACryptoS',
            'address': '0xf27Ee99622C3C9b264583dACB2cCE056e194494f',
            'decimals': 18,
            'chainId': 592,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'multichain'
            ]
        },
        {
            'symbol': 'WMATIC',
            'name': 'Wrapped Matic',
            'address': '0xdd90E5E87A2081Dcf0391920868eBc2FFB81a1aF',
            'decimals': 18,
            'chainId': 592,
            'logoURI': 'https://assets.coingecko.com/coins/images/14073/thumb/matic.png?1628852392',
            'coingeckoId': 'wmatic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MUUU',
            'name': 'Muuu Finance',
            'address': '0xc5BcAC31cf55806646017395AD119aF2441Aee37',
            'decimals': 18,
            'chainId': 592,
            'logoURI': 'https://assets.coingecko.com/coins/images/26052/thumb/IMG_0551.PNG?1655451166',
            'coingeckoId': 'muuu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LAY',
            'name': 'Starlay Finance',
            'address': '0xc4335B1b76fA6d52877b3046ECA68F6E708a27dd',
            'decimals': 18,
            'chainId': 592,
            'logoURI': 'https://assets.coingecko.com/coins/images/25795/small/WKBrkX4y_400x400.png?1653920060',
            'coingeckoId': 'starlay-finance',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'ESW',
            'name': 'EmiDAO Token',
            'address': '0xb361DAD0Cc1a03404b650A69d9a5ADB5aF8A531F',
            'decimals': 18,
            'chainId': 592,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'multichain'
            ]
        },
        {
            'symbol': 'WBTC',
            'name': 'Wrapped Bitcoin',
            'address': '0xad543f18cFf85c77E140E3E5E3c3392f6Ba9d5CA',
            'decimals': 8,
            'chainId': 592,
            'logoURI': 'https://assets.coingecko.com/coins/images/7598/small/wrapped_bitcoin_wbtc.png?1548822744',
            'coingeckoId': 'wrapped-bitcoin',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'WDOT',
            'name': 'WDOT',
            'address': '0xFFfFfFffFFfffFFfFFfFFFFFffFFFffffFfFFFfF',
            'decimals': 16,
            'chainId': 592,
            'logoURI': 'https://assets.coingecko.com/coins/images/12171/small/polkadot.png?1639712644',
            'coingeckoId': 'wdot',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'SEAN',
            'name': 'Starfish Finance',
            'address': '0xEe8138B3bd03905cF84aFE10cCD0dCcb820eE08E',
            'decimals': 18,
            'chainId': 592,
            'logoURI': 'https://assets.coingecko.com/coins/images/27533/thumb/SEAN_Token_icon.png?1664411041',
            'coingeckoId': 'starfish-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARSW',
            'name': 'ArthSwap',
            'address': '0xDe2578Edec4669BA7F41c5d5D2386300bcEA4678',
            'decimals': 18,
            'chainId': 592,
            'logoURI': 'https://assets.coingecko.com/coins/images/26048/small/arsw.png?1655440190',
            'coingeckoId': 'arthswap',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'WASTR',
            'name': 'Wrapped Astar',
            'address': '0xAeaaf0e2c81Af264101B9129C00F4440cCF0F720',
            'decimals': 18,
            'chainId': 592,
            'logoURI': 'https://assets.coingecko.com/coins/images/22617/small/astr.png?1642314057',
            'coingeckoId': 'wrapped-astar',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'RIFI',
            'name': 'Rikkei Finance',
            'address': '0xABd380327Fe66724FFDa91A87c772FB8D00bE488',
            'decimals': 18,
            'chainId': 592,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'multichain'
            ]
        },
        {
            'symbol': 'ZLK',
            'name': 'Zenlink Network',
            'address': '0x998082C488e548820F970Df5173bD2061Ce90635',
            'decimals': 18,
            'chainId': 592,
            'logoURI': 'https://assets.coingecko.com/coins/images/20884/thumb/zenlink.PNG?1637824309',
            'coingeckoId': 'zenlink-network-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STAR',
            'name': 'StarSwap',
            'address': '0x8489f4554790F5A103F2B0398537eAEe68B73884',
            'decimals': 18,
            'chainId': 592,
            'logoURI': 'https://assets.coingecko.com/coins/images/26624/thumb/star-swap.png?1659319183',
            'coingeckoId': 'starswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WETH',
            'name': 'WETH',
            'address': '0x81ECac0D6Be0550A00FF064a4f9dd2400585FE9c',
            'decimals': 18,
            'chainId': 592,
            'logoURI': 'https://assets.coingecko.com/coins/images/2518/small/weth.png?1628852295',
            'coingeckoId': 'weth',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'SDN',
            'name': 'Wrapped Shiden Network',
            'address': '0x75364D4F779d0Bd0facD9a218c67f87dD9Aff3b4',
            'decimals': 18,
            'chainId': 592,
            'logoURI': 'https://assets.coingecko.com/coins/images/18027/small/tFOtjrr3_400x400.png?1630291767',
            'coingeckoId': 'wrapped-shiden-network',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'BAI',
            'name': 'BAI Stablecoin',
            'address': '0x733ebcC6DF85f8266349DEFD0980f8Ced9B45f35',
            'decimals': 18,
            'chainId': 592,
            'logoURI': 'https://assets.coingecko.com/coins/images/25385/small/BAI_Token_Logo.png?1651633359',
            'coingeckoId': 'bai-stablecoin',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USD Coin',
            'address': '0x6a2d262D56735DbA19Dd70682B39F6bE9a931D98',
            'decimals': 6,
            'chainId': 592,
            'logoURI': 'https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389',
            'coingeckoId': 'usd-coin',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'NIKA',
            'name': 'Alnair Finance NIKA',
            'address': '0x6Df98E5fBfF3041105cB986B9D44c572a43Fcd22',
            'decimals': 18,
            'chainId': 592,
            'logoURI': 'https://assets.coingecko.com/coins/images/25794/small/image_%281%29.png?1653919697',
            'coingeckoId': 'alnair-finance-nika',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'DAI',
            'name': 'Dai',
            'address': '0x6De33698e9e9b787e09d3Bd7771ef63557E148bb',
            'decimals': 18,
            'chainId': 592,
            'logoURI': 'https://assets.coingecko.com/coins/images/9956/small/4943.png?1636636734',
            'coingeckoId': 'dai',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'ATID',
            'name': 'AstridDAO',
            'address': '0x5271D85CE4241b310C0B34b7C2f1f036686A6d7C',
            'decimals': 18,
            'chainId': 592,
            'logoURI': 'https://assets.coingecko.com/coins/images/27082/thumb/Frame_55.jpg?1661763334',
            'coingeckoId': 'astriddao-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUSD',
            'name': 'Binance USD',
            'address': '0x4Bf769b05E832FCdc9053fFFBC78Ca889aCb5E1E',
            'decimals': 6,
            'chainId': 592,
            'logoURI': 'https://assets.coingecko.com/coins/images/9576/small/BUSD.png?1568947766',
            'coingeckoId': 'binance-usd',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'JPYC',
            'name': 'JPY Coin',
            'address': '0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB',
            'decimals': 18,
            'chainId': 592,
            'logoURI': 'https://assets.coingecko.com/coins/images/17277/small/WoZ8rruL_400x400.png?1627016492',
            'coingeckoId': 'jpy-coin',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'Tether',
            'address': '0x3795C36e7D12A8c252A20C5a7B455f7c57b60283',
            'decimals': 6,
            'chainId': 592,
            'logoURI': 'https://assets.coingecko.com/coins/images/325/small/Tether-logo.png?1598003707',
            'coingeckoId': 'tether',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'Bolt',
            'name': 'BoltSwap Token',
            'address': '0x2C78f1b70Ccf63CDEe49F9233e9fAa99D43AA07e',
            'decimals': 18,
            'chainId': 592,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'multichain'
            ]
        },
        {
            'symbol': 'OUSD',
            'name': 'Origin Dollar',
            'address': '0x29F6e49c6E3397C3A84F715885F9F233A441165C',
            'decimals': 18,
            'chainId': 592,
            'logoURI': 'https://assets.coingecko.com/coins/images/12589/small/ousd-logo-200x200.png?1600943287',
            'coingeckoId': 'origin-dollar',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'KGL',
            'name': 'Kagla Finance',
            'address': '0x257f1a047948f73158DaDd03eB84b34498bCDc60',
            'decimals': 18,
            'chainId': 592,
            'logoURI': 'https://assets.coingecko.com/coins/images/25789/thumb/1USwzz6C_400x400.png?1653890581',
            'coingeckoId': 'kagla-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEP',
            'name': 'DEAPCOIN',
            'address': '0x0999E8267A09d41A5a76605e3d01aE95FAfb58F4',
            'decimals': 18,
            'chainId': 592,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'multichain'
            ]
        },
        {
            'symbol': 'ASTR',
            'name': 'ASTR',
            'address': '0x0000000000000000000000000000000000000000',
            'decimals': 18,
            'chainId': 592,
            'logoURI': 'https://assets.coingecko.com/coins/images/22617/small/astr.png?1642314057',
            'coingeckoId': 'astar',
            'listedIn': [
                'xyfinance'
            ]
        }
    ],
    '1284': [
        {
            'symbol': 'xcACA',
            'name': 'Acala',
            'address': '0xffffFFffa922Fef94566104a6e5A35a4fCDDAA9f',
            'decimals': 8,
            'chainId': 1284,
            'logoURI': 'https://raw.githubusercontent.com/BeamSwap/beamswap-tokenlist/main/assets/chains/moonbeam/0xffffFFffa922Fef94566104a6e5A35a4fCDDAA9f/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'xcaUSD',
            'name': 'Acala Dollar',
            'address': '0xfFfFFFFF52C56A9257bB97f4B2b6F7B2D624ecda',
            'decimals': 12,
            'chainId': 1284,
            'logoURI': 'https://raw.githubusercontent.com/BeamSwap/beamswap-tokenlist/main/assets/chains/moonbeam/0xfFfFFFFF52C56A9257bB97f4B2b6F7B2D624ecda/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'IDIA',
            'name': 'Impossible Decentralized Incubator Access Token',
            'address': '0xfCAF13227dcBFa2dc2b1928acFcA03b85e2D25dd',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ETH',
            'name': 'Ethereum',
            'address': '0xfA9343C3897324496A05fC75abeD6bAC29f8A40f',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://api.rubic.exchange/assets/moonbeam/0xfa9343c3897324496a05fc75abed6bac29f8a40f/logo.png',
            'coingeckoId': 'weth',
            'listedIn': [
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'Tether',
            'address': '0xeFAeeE334F0Fd1712f9a8cc375f427D9Cdd40d73',
            'decimals': 6,
            'chainId': 1284,
            'logoURI': 'https://assets.coingecko.com/coins/images/325/thumb/Tether.png?1668148663',
            'coingeckoId': 'tether',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'MIMATIC',
            'name': 'MAI',
            'address': '0xdFA46478F9e5EA86d57387849598dbFB2e964b02',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://assets.coingecko.com/coins/images/15264/thumb/mimatic-red.png?1620281018',
            'coingeckoId': 'mimatic',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'GLINT',
            'name': 'BeamSwap',
            'address': '0xcd3B51D98478D53F4515A306bE565c6EebeF1D58',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://assets.coingecko.com/coins/images/22893/thumb/glint.png?1642845813',
            'coingeckoId': 'beamswap',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'MESA',
            'name': 'MESA',
            'address': '0xcFE133cD392f36DA41d287E78316b8fBba51f8DA',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BNB',
            'name': 'Binance',
            'address': '0xc9BAA8cfdDe8E328787E29b4B078abf2DaDc2055',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://api.rubic.exchange/assets/moonbeam/0xc9baa8cfdde8e328787e29b4b078abf2dadc2055/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'DAI',
            'name': 'Dai Stablecoin',
            'address': '0xc234A67a4F840E61adE794be47de455361b52413',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/dai.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'LASM',
            'name': 'LasMeta',
            'address': '0xbC2E674dFe0d885081f429b296D7aE2B784D1198',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'xcINTR',
            'name': 'Interlay',
            'address': '0xFffFFFFF4C1cbCd97597339702436d4F18a375Ab',
            'decimals': 10,
            'chainId': 1284,
            'logoURI': 'https://raw.githubusercontent.com/BeamSwap/beamswap-tokenlist/main/assets/chains/moonbeam/0xFffFFFFF4C1cbCd97597339702436d4F18a375Ab/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'XCDOT',
            'name': 'xcDOT',
            'address': '0xFfFFfFff1FcaCBd218EDc0EbA20Fc2308C778080',
            'decimals': 10,
            'chainId': 1284,
            'logoURI': 'https://assets.coingecko.com/coins/images/26044/thumb/polkadot-asset_32.png?1655372947',
            'coingeckoId': 'xcdot',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'POOP',
            'name': 'Raresama',
            'address': '0xFFfffFFecB45aFD30a637967995394Cc88C0c194',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://assets.coingecko.com/coins/images/27306/thumb/Raresama_Logo.png?1663238966',
            'coingeckoId': 'raresama',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'xcIBTC',
            'name': 'InterBTC',
            'address': '0xFFFFFfFf5AC1f9A51A93F5C527385edF7Fe98A52',
            'decimals': 8,
            'chainId': 1284,
            'logoURI': 'https://raw.githubusercontent.com/BeamSwap/beamswap-tokenlist/main/assets/chains/moonbeam/0xFFFFFfFf5AC1f9A51A93F5C527385edF7Fe98A52/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'xcUSDT',
            'name': 'xcUSDT',
            'address': '0xFFFFFFfFea09FB06d082fd1275CD48b191cbCD1d',
            'decimals': 6,
            'chainId': 1284,
            'logoURI': 'https://raw.githubusercontent.com/BeamSwap/beamswap-tokenlist/main/assets/chains/moonbeam/0xFFFFFFfFea09FB06d082fd1275CD48b191cbCD1d/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'STDOT',
            'name': 'Lido Staked Polkadot',
            'address': '0xFA36Fe1dA08C89eC72Ea1F0143a35bFd5DAea108',
            'decimals': 10,
            'chainId': 1284,
            'logoURI': 'https://assets.coingecko.com/coins/images/27791/thumb/stDOT.png?1665818581',
            'coingeckoId': null,
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TOAD',
            'name': 'Toad Network',
            'address': '0xF480f38C366dAaC4305dC484b2Ad7a496FF00CeA',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'xcRMRK',
            'name': 'xcRMRK',
            'address': '0xECf2ADafF1De8A512f6e8bfe67a2C836EDb25Da3',
            'decimals': 10,
            'chainId': 1284,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ECT',
            'name': 'Echain Token',
            'address': '0xE6cDD3a275c976BcA5d3De4F96c7514B899f0434',
            'decimals': 9,
            'chainId': 1284,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ROAR',
            'name': 'AlphaDEX',
            'address': '0xE68bA9C8411B27BD71811b1B1e5574CBAdD09655',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FLARE',
            'name': 'Solarflare',
            'address': '0xE3e43888fa7803cDC7BEA478aB327cF1A0dc11a7',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://assets.coingecko.com/coins/images/24310/thumb/Solarflare.png?1647334977',
            'coingeckoId': 'solarflare',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'ceBUSD',
            'name': 'Celer BUSD',
            'address': '0xCb4A7569a61300C50Cf80A2be16329AD9F5F8F9e',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://raw.githubusercontent.com/stellaswap/assets/main/tokenlist/0xCb4A7569a61300C50Cf80A2be16329AD9F5F8F9e/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AXLUSDC',
            'name': 'axlUSDC',
            'address': '0xCa01a1D0993565291051daFF390892518ACfAD3A',
            'decimals': 6,
            'chainId': 1284,
            'logoURI': 'https://assets.coingecko.com/coins/images/26476/thumb/axlUSDC.png?1658207579',
            'coingeckoId': 'axlusdc',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ATH',
            'name': 'Athos Finance',
            'address': '0xCBABEe0658725b5B21e1512244734A5D5C6B51D6',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://assets.coingecko.com/coins/images/26922/thumb/logo.png?1660817620',
            'coingeckoId': 'athos-finance',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'FTM',
            'name': 'Fantom',
            'address': '0xC19281F22A075E0F10351cd5D6Ea9f0AC63d4327',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://api.rubic.exchange/assets/moonbeam/0xc19281f22a075e0f10351cd5d6ea9f0ac63d4327/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'WGLMR',
            'name': 'Wrapped Moonbeam',
            'address': '0xAcc15dC74880C9944775448304B263D191c6077F',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/6836.png',
            'coingeckoId': 'wrapped-moonbeam',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'BUSD',
            'name': 'Binance USD',
            'address': '0xA649325Aa7C5093d12D6F98EB4378deAe68CE23F',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://assets.coingecko.com/coins/images/9576/thumb/BUSD.png?1568947766',
            'coingeckoId': 'binance-usd',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'EFT',
            'name': 'Energyfi',
            'address': '0xA423E7eEB60547d9C7b65005477b63ae7CE67E62',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://assets.coingecko.com/coins/images/23498/thumb/fbb562_28b2de266adc4c63b6d58e3021158c44_mv2.png?1656132886',
            'coingeckoId': 'energyfi',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'LDO',
            'name': 'Lido DAO Token',
            'address': '0x9Fda7cEeC4c18008096C2fE2B85F05dc300F94d0',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'STATIK',
            'name': 'Statik',
            'address': '0x97513e975a7fA9072c72C92d8000B0dB90b163c5',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://assets.coingecko.com/coins/images/22298/thumb/STATIK_200x200.png?1641424859',
            'coingeckoId': 'statik',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'WBTC',
            'name': 'Wrapped Bitcoin',
            'address': '0x922D641a426DcFFaeF11680e5358F34d97d112E1',
            'decimals': 8,
            'chainId': 1284,
            'logoURI': 'https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744',
            'coingeckoId': 'wrapped-bitcoin',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USD Coin',
            'address': '0x8f552a71EFE5eeFc207Bf75485b356A0b3f01eC9',
            'decimals': 6,
            'chainId': 1284,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/usdc.jpg',
            'coingeckoId': 'usd-coin',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'Tether USD',
            'address': '0x8e70cD5B4Ff3f62659049e74b6649c6603A0E594',
            'decimals': 6,
            'chainId': 1284,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/usdt.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'rubic'
            ]
        },
        {
            'symbol': 'LEMON',
            'name': 'Lemon',
            'address': '0x8F4DbDF6FDdc1c4013b2932c814Fa5dd0003443A',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://assets.coingecko.com/coins/images/21418/thumb/image2.jpg?1640222935',
            'coingeckoId': 'lemon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HPO',
            'name': 'Hippo Wallet',
            'address': '0x823d826d3aB6956ba934893f325e7c323fAAF6Ca',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://assets.coingecko.com/coins/images/28089/thumb/b77170dd-1dd1-4581-91b2-e352794fa045.jpg?1667446200',
            'coingeckoId': 'hippopotamus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ceUSDT',
            'name': 'Celer USDT',
            'address': '0x81ECac0D6Be0550A00FF064a4f9dd2400585FE9c',
            'decimals': 6,
            'chainId': 1284,
            'logoURI': 'https://static.debank.com/image/coin/logo_url/usdt/23af7472292cb41dc39b3f1146ead0fe.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USD Coin',
            'address': '0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
            'decimals': 6,
            'chainId': 1284,
            'logoURI': 'https://api.rubic.exchange/assets/moonbeam/0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b/logo.png',
            'coingeckoId': 'usd-coin',
            'listedIn': [
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'GREEN',
            'name': 'Greenhouse',
            'address': '0x7aE97042a4A0eB4D1eB370C34BfEC71042a056B7',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'OCEAN',
            'name': 'Ocean Token',
            'address': '0x7F8BC696bEBbbd29255F871cBef55B74e8F10E57',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AMY',
            'name': 'Amy Finance Token ',
            'address': '0x7EA2be2df7BA6E54B1A9C70676f668455E329d29',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CRV',
            'name': 'Curve DAO Token',
            'address': '0x7C598c96D02398d89FbCb9d41Eab3DF0C16F227D',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DAI',
            'name': 'Dai',
            'address': '0x765277EebeCA2e31912C9946eAe1021199B39C61',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://assets.coingecko.com/coins/images/9956/thumb/4943.png?1636636734',
            'coingeckoId': 'dai',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'THO',
            'name': 'Thorus',
            'address': '0x735aBE48e8782948a37C7765ECb76b98CdE97B0F',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://assets.coingecko.com/coins/images/22297/thumb/THO_200x200.png?1641425001',
            'coingeckoId': 'thorus',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'CEUSDC',
            'name': 'USD Coin   Celer',
            'address': '0x6a2d262D56735DbA19Dd70682B39F6bE9a931D98',
            'decimals': 6,
            'chainId': 1284,
            'logoURI': 'https://assets.coingecko.com/coins/images/24823/thumb/usdc.png?1649041374',
            'coingeckoId': 'usd-coin-celer',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'OH',
            'name': 'Oh! Finance',
            'address': '0x6F058086D91a181007C2325e5b285425cA84d615',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BEANS',
            'name': 'MoonBeans',
            'address': '0x65b09ef8c5A096C5Fd3A80f1F7369E56eB932412',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://api.rubic.exchange/assets/moonbeam/0x65b09ef8c5a096c5fd3a80f1f7369e56eb932412/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'OWL',
            'name': 'Athena Money Owl',
            'address': '0x5C2dA48241d3bE9626dd0c48081c76DBb6D1046E',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://assets.coingecko.com/coins/images/23124/thumb/OWL.png?1643267884',
            'coingeckoId': 'athena-money-owl',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BIFI',
            'name': 'beefy.finance',
            'address': '0x595c8481c48894771CE8FaDE54ac6Bf59093F9E8',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://static.debank.com/image/mobm_token/logo_url/0x595c8481c48894771ce8fade54ac6bf59093f9e8/98a5cc0f4596cbdeb9abe5125ae7e9e7.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WELL',
            'name': 'Moonwell Artemis',
            'address': '0x511aB53F793683763E5a8829738301368a2411E3',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://assets.coingecko.com/coins/images/26133/thumb/62b2bd0f0d661a0001b640eb_artemis.png?1656040613',
            'coingeckoId': 'moonwell-artemis',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'STHO',
            'name': 'Staked Thorus',
            'address': '0x4c4BF319237D98a30A929A96112EfFa8DA3510EB',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://assets.coingecko.com/coins/images/22383/thumb/sTHO_200x200.png?1641786706',
            'coingeckoId': 'stakedthorus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AVAX',
            'name': 'Avalanche',
            'address': '0x4792C1EcB969B036eb51330c63bD27899A13D84e',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://assets.coingecko.com/coins/images/12559/thumb/coin-round-red.png?1604021818',
            'coingeckoId': 'avalanche-2',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'AXL',
            'name': 'Axelar',
            'address': '0x467719aD09025FcC6cF6F8311755809d45a5E5f3',
            'decimals': 6,
            'chainId': 1284,
            'logoURI': 'https://assets.coingecko.com/coins/images/27277/thumb/V-65_xQ1_400x400.jpeg?1663121730',
            'coingeckoId': 'axelar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HMT',
            'name': 'Human Token',
            'address': '0x3b25BC1dC591D24d60560d0135D6750A561D4764',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ZLK',
            'name': 'Zenlink Network Token',
            'address': '0x3Fd9b6C9A24E09F67b7b706d72864aEbb439100C',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://static.debank.com/image/mobm_token/logo_url/0x3fd9b6c9a24e09f67b7b706d72864aebb439100c/977b7edace8bd8e1669c1dd1d76463d8.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'QI',
            'name': 'QiDao',
            'address': '0x3F56e0c36d275367b8C502090EDF38289b3dEa0d',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://static.debank.com/image/mobm_token/logo_url/0x3f56e0c36d275367b8c502090edf38289b3dea0d/c0524dfb9be687e8e26191eed8774fd3.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ApeCoin',
            'name': 'anyAPE | Multichain',
            'address': '0x3D632d9e1a60a0880Dd45E61f279D919b5748377',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://raw.githubusercontent.com/stellaswap/assets/main/tokenlist/0x3D632d9e1a60a0880Dd45E61f279D919b5748377/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ZOO',
            'name': 'ZooDAO',
            'address': '0x3907e6ff436e2b2B05D6B929fb05F14c0ee18d90',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://icodrops.com/wp-content/uploads/2021/12/ZooDAO_logo.jpeg',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CELR',
            'name': 'Celer Network',
            'address': '0x3795C36e7D12A8c252A20C5a7B455f7c57b60283',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://raw.githubusercontent.com/stellaswap/assets/main/tokenlist/0x3795C36e7D12A8c252A20C5a7B455f7c57b60283/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MATIC',
            'name': 'Polygon',
            'address': '0x3405A1bd46B85c5C029483FbECf2F3E611026e45',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://assets.coingecko.com/coins/images/4713/thumb/matic-token-icon.png?1624446912',
            'coingeckoId': 'matic-network',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'FRAX',
            'name': 'Frax',
            'address': '0x322E86852e492a7Ee17f28a78c663da38FB33bfb',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/frax.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'LUNA',
            'name': 'axLUNA | Axelar',
            'address': '0x31DAB3430f3081dfF3Ccd80F17AD98583437B213',
            'decimals': 6,
            'chainId': 1284,
            'logoURI': 'https://static.debank.com/image/mobm_token/logo_url/0x31dab3430f3081dff3ccd80f17ad98583437b213/b3446ee43ebbc2e32f8d13ba4b4f94a4.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WETH',
            'name': 'WETH',
            'address': '0x30D2a9F5FDf90ACe8c17952cbb4eE48a55D916A7',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/eth.jpg',
            'coingeckoId': 'weth',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic'
            ]
        },
        {
            'symbol': 'CGS',
            'name': 'CougarSwap',
            'address': '0x2Dfc76901bB2ac2A5fA5fc479590A490BBB10a5F',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://static.debank.com/image/mobm_token/logo_url/0x2dfc76901bb2ac2a5fa5fc479590a490bbb10a5f/40b0b77c20fe0adbb0f21ae261c41786.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FXS',
            'name': 'Frax Share',
            'address': '0x2CC0A9D8047A5011dEfe85328a6f26968C8aaA1C',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/fxs.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'anySUSHI',
            'name': 'Sushi',
            'address': '0x2C78f1b70Ccf63CDEe49F9233e9fAa99D43AA07e',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/sushi.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'ATOM',
            'name': 'axATOM | Axelar',
            'address': '0x27292cf0016E5dF1d8b37306B2A98588aCbD6fCA',
            'decimals': 6,
            'chainId': 1284,
            'logoURI': 'https://raw.githubusercontent.com/stellaswap/assets/main/tokenlist/0x27292cf0016E5dF1d8b37306B2A98588aCbD6fCA/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MOVR',
            'name': 'MOVR',
            'address': '0x1d4C2a246311bB9f827F4C768e277FF5787B7D7E',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://assets.coingecko.com/coins/images/17984/small/9285.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WBTC',
            'name': 'Wrapped Bitcoin',
            'address': '0x1DC78Acda13a8BC4408B207c9E48CDBc096D95e0',
            'decimals': 8,
            'chainId': 1284,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/btc.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': '1BEAM',
            'name': 'OneBeam',
            'address': '0x19d2f0CF1FC41DE2b8fd4A98065AB9284E05Cf29',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://api.rubic.exchange/assets/moonbeam/0x19d2f0cf1fc41de2b8fd4a98065ab9284e05cf29/logo.png',
            'coingeckoId': '1beam',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'STDOT',
            'name': 'Lido Staked Polkadot',
            'address': '0x191cf2602Ca2e534c5Ccae7BCBF4C46a704bb949',
            'decimals': 10,
            'chainId': 1284,
            'logoURI': 'https://assets.coingecko.com/coins/images/27791/thumb/stDOT.png?1665818581',
            'coingeckoId': 'lido-staked-polkadot',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ALM',
            'name': 'Alium Finance',
            'address': '0x1581929770bE3275a82068c1135b6dD59c5334Ed',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://assets.coingecko.com/coins/images/15621/thumb/alium.png?1655449806',
            'coingeckoId': 'alium-finance',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'PAE',
            'name': 'Ripae',
            'address': '0x130966628846BFd36ff31a822705796e8cb8C18D',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'STELLA',
            'name': 'StellaSwap',
            'address': '0x0E358838ce72d5e61E0018a2ffaC4bEC5F4c88d2',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://assets.coingecko.com/coins/images/22801/thumb/stellaswap.jpg?1642584268',
            'coingeckoId': 'stellaswap',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'veSOLAR',
            'name': 'Vested SolarBeam Token',
            'address': '0x0DB6729C03C85B0708166cA92801BcB5CAc781fC',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://api.rubic.exchange/assets/moonbeam/0x0db6729c03c85b0708166ca92801bcb5cac781fc/logo.png',
            'coingeckoId': 'vested-solarbeam',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'UST',
            'name': 'TerraUSD',
            'address': '0x085416975fe14C2A731a97eC38B9bF8135231F62',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/ust.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'xSTELLA',
            'name': 'xSTELLA',
            'address': '0x06A3b410b681c82417A906993aCeFb91bAB6A080',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://raw.githubusercontent.com/stellaswap/assets/main/tokenlist/0x0E358838ce72d5e61E0018a2ffaC4bEC5F4c88d2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'GLMR',
            'name': 'GLMR',
            'address': '0x0000000000000000000000000000000000000000',
            'decimals': 18,
            'chainId': 1284,
            'logoURI': 'https://api.rubic.exchange/assets/moonbeam/0x0000000000000000000000000000000000000000/logo.png',
            'coingeckoId': 'moonbeam',
            'listedIn': [
                'rubic',
                'lifinance'
            ]
        }
    ],
    '361': [
        {
            'symbol': 'WBNB',
            'name': 'Wrapped BNB',
            'address': '0xdFf772186aCe9b5513FB46d7B05b36Efa0A4a20d',
            'decimals': 18,
            'chainId': 361,
            'logoURI': 'https://assets.coingecko.com/coins/images/12591/thumb/binance-coin-logo.png?1600947313',
            'coingeckoId': 'wbnb',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VOLT',
            'name': 'VoltSwap',
            'address': '0xE6a991Ffa8CfE62B0bf6BF72959A3d4f11B2E0f5',
            'decimals': 18,
            'chainId': 361,
            'logoURI': 'https://assets.coingecko.com/coins/images/22841/thumb/voltswap.png?1642745860',
            'coingeckoId': 'voltswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTRG',
            'name': 'Meter Governance',
            'address': '0xBd2949F67DcdC549c6Ebe98696449Fa79D988A9F',
            'decimals': 18,
            'chainId': 361,
            'logoURI': 'https://assets.coingecko.com/coins/images/11848/thumb/meter.png?1660274270',
            'coingeckoId': 'meter',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUSD',
            'name': 'Binance USD',
            'address': '0x7B37d0787A3424A0810E02b24743a45eBd5530B2',
            'decimals': 18,
            'chainId': 361,
            'logoURI': 'https://assets.coingecko.com/coins/images/9576/thumb/BUSD.png?1568947766',
            'coingeckoId': 'binance-usd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WETH',
            'name': 'WETH',
            'address': '0x3674D64AaB971aB974B2035667a4B3d09B5ec2B3',
            'decimals': 18,
            'chainId': 361,
            'logoURI': 'https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295',
            'coingeckoId': 'weth',
            'listedIn': [
                'coingecko'
            ]
        }
    ],
    '70': [
        {
            'symbol': 'WETC',
            'name': 'Wrapped Ethereum Classic',
            'address': '0xfC523B7BfAb1656b36A57Ef35F9D5c738CbF1E6C',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0xfC523B7BfAb1656b36A57Ef35F9D5c738CbF1E6C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'LAYER2',
            'name': 'LAYER2',
            'address': '0xebBD83abDe1c794CC587dEFeA49d33573dE29211',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0xebBD83abDe1c794CC587dEFeA49d33573dE29211/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ELK',
            'name': 'Elk',
            'address': '0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'TITAN',
            'name': 'TITAN',
            'address': '0xe7135426F31aa53b7A08d157f0138E03F48954D5',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0xe7135426F31aa53b7A08d157f0138E03F48954D5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'KSM',
            'name': 'Kusama',
            'address': '0xe3f4F13ae64c1D5156d9bCff4b6a98a70fC32C57',
            'decimals': 12,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0xe3f4F13ae64c1D5156d9bCff4b6a98a70fC32C57/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'LDT',
            'name': 'LDT',
            'address': '0xd63F3cceef518e183e27615A7D6404d0803210Af',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0xd63F3cceef518e183e27615A7D6404d0803210Af/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'FLOW',
            'name': 'Flow',
            'address': '0xcB27775227Ae21dB4De6AC3F156139300Cf70aF5',
            'decimals': 8,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0xcB27775227Ae21dB4De6AC3F156139300Cf70aF5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'PUD',
            'name': 'PuddingSwap',
            'address': '0xbE8D16084841875a1f398E6C3eC00bBfcbFa571b',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0xbE8D16084841875a1f398E6C3eC00bBfcbFa571b/logo.png',
            'coingeckoId': 'puddingswap',
            'listedIn': [
                'coingecko',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WBNB',
            'name': 'Wrapped Binance Coin',
            'address': '0xa787254704339474A827bd1ee4772c455e3aE7F2',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0xa787254704339474A827bd1ee4772c455e3aE7F2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'GFC',
            'name': 'GFC',
            'address': '0xE9FB7d75822064A71aE2F4E8626D6407F70Fb4eF',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0xE9FB7d75822064A71aE2F4E8626D6407F70Fb4eF/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'oELK',
            'name': 'Old Elk',
            'address': '0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'LOOT',
            'name': 'LOOT',
            'address': '0xD8f6d61C2cC69c04F176616aD1c7de211b00af31',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0xD8f6d61C2cC69c04F176616aD1c7de211b00af31/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'Tether',
            'address': '0xD16bAbe52980554520F6Da505dF4d1b124c815a7',
            'decimals': 6,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0xD16bAbe52980554520F6Da505dF4d1b124c815a7/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SGT',
            'name': 'SGT',
            'address': '0xCaD4de5b89c1992794181E944DcBe6f17402437D',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0xCaD4de5b89c1992794181E944DcBe6f17402437D/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'GENX',
            'name': 'GENX',
            'address': '0xC48270D4672Cc0111E9340c6D813E6CBb362B888',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0xC48270D4672Cc0111E9340c6D813E6CBb362B888/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ZILD',
            'name': 'ZILD',
            'address': '0xB328002712466111606456FFd55f820bcb9cf18F',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0xB328002712466111606456FFd55f820bcb9cf18F/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'WBTC',
            'name': 'Wrapped Bitcon',
            'address': '0xAad9654a4df6973A92C1fd3e95281F0B37960CCd',
            'decimals': 8,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0xAad9654a4df6973A92C1fd3e95281F0B37960CCd/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'DOT',
            'name': 'Polkadot',
            'address': '0xA71e7ae7A5B154f1ED12476a4C54c5Ec6e3426AC',
            'decimals': 10,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0xA71e7ae7A5B154f1ED12476a4C54c5Ec6e3426AC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'WETH',
            'name': 'Wrapped Ethereum',
            'address': '0xA1588dC914e236bB5AE4208Ce3081246f7A00193',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0xA1588dC914e236bB5AE4208Ce3081246f7A00193/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'hoomoon',
            'name': 'Hoomoon',
            'address': '0x9ADd100b10442269bFb650488C5e48Ae3CBCF1d2',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0x9ADd100b10442269bFb650488C5e48Ae3CBCF1d2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'aiBED',
            'name': 'aiBED',
            'address': '0x9A9E27523bb0C59c2f4cDbFa2e19e99aB74128F7',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0x9A9E27523bb0C59c2f4cDbFa2e19e99aB74128F7/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'GTPS',
            'name': 'Gtps.Finance',
            'address': '0x9427A2a738AffBc5880F0646b5251069c022e525',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0x9427A2a738AffBc5880F0646b5251069c022e525/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USD Coin',
            'address': '0x92a0bD4584c147D1B0e8F9185dB0BDa10B05Ed7e',
            'decimals': 6,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0x92a0bD4584c147D1B0e8F9185dB0BDa10B05Ed7e/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'DOGE',
            'name': 'Dogecoin',
            'address': '0x886dB4C7827e196ECc777eEB6a94C23c79B9467A',
            'decimals': 8,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0x886dB4C7827e196ECc777eEB6a94C23c79B9467A/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'MOF',
            'name': 'MOF',
            'address': '0x7e49b17552c2e41555c3C8CBF3bE742796019442',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0x7e49b17552c2e41555c3C8CBF3bE742796019442/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'HGT',
            'name': 'HyperGraph',
            'address': '0x7DDE5e7139a6e2096aCe3C82680bF58Ca1d7feE9',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0x7DDE5e7139a6e2096aCe3C82680bF58Ca1d7feE9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'sROO',
            'name': 'RooLend',
            'address': '0x7C0241847b59e7Fc2F40FD95c58cD4af4d491fcb',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0x7C0241847b59e7Fc2F40FD95c58cD4af4d491fcb/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'FFN',
            'name': 'FFN',
            'address': '0x74248c9caf0F5F203b0A32ccB4Ce5B5427125eE8',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0x74248c9caf0F5F203b0A32ccB4Ce5B5427125eE8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'PIPI',
            'name': 'Pippi Shrimp Token',
            'address': '0x685b9D0aeecD5153a332a334A04Ce1bc7A4Bf92A',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0x685b9D0aeecD5153a332a334A04Ce1bc7A4Bf92A/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'GDC',
            'name': 'GameDAO',
            'address': '0x68411e16a4F2054e0b3F7899E9e6a23faD5500E1',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0x68411e16a4F2054e0b3F7899E9e6a23faD5500E1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': '9CAT',
            'name': '9CAT',
            'address': '0x67dF38f569546e263fEe9aD3d97276D567988560',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0x67dF38f569546e263fEe9aD3d97276D567988560/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'BCH',
            'name': 'Bitcoin Cash',
            'address': '0x6705b383dEF2D9f3f93bc00C5FDe402613d2D695',
            'decimals': 8,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0x6705b383dEF2D9f3f93bc00C5FDe402613d2D695/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'KITTY',
            'name': 'Kitty Coin',
            'address': '0x66068f0460B2932f79C0B31B02AcF0ef4ECB175C',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0x66068f0460B2932f79C0B31B02AcF0ef4ECB175C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'DP',
            'name': 'Punk World Token',
            'address': '0x61B27ADebc9b7c7ffCF8bDFd2cec3339fd582e77',
            'decimals': 9,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0x61B27ADebc9b7c7ffCF8bDFd2cec3339fd582e77/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SAME',
            'name': 'SAME',
            'address': '0x3f9B86987B96DB944b34a6A2483d488369A41e3f',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0x3f9B86987B96DB944b34a6A2483d488369A41e3f/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'WHOO',
            'name': 'Wrapped HOO',
            'address': '0x3EFF9D389D13D6352bfB498BCF616EF9b1BEaC87',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0x3EFF9D389D13D6352bfB498BCF616EF9b1BEaC87/logo.png',
            'coingeckoId': 'wrapped-hoo',
            'listedIn': [
                'coingecko',
                'elkfinance'
            ]
        },
        {
            'symbol': 'STK',
            'name': 'NFT-STK',
            'address': '0x3E259bfe720093ABb26A2c3fE57670259B2ebeA2',
            'decimals': 12,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0x3E259bfe720093ABb26A2c3fE57670259B2ebeA2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SGR',
            'name': 'SGR',
            'address': '0x3Cac9a2900227Cd33d0F40B1b5B09187c85aEF3e',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0x3Cac9a2900227Cd33d0F40B1b5B09187c85aEF3e/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ATOM',
            'name': 'Cosmos',
            'address': '0x381f6698a7f369DD729390D817A7BfeB765fB105',
            'decimals': 6,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0x381f6698a7f369DD729390D817A7BfeB765fB105/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'PIGX',
            'name': 'PIGX',
            'address': '0x35f75b8A1545Af83D71C60D5F6cdA71eCBfDC24C',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0x35f75b8A1545Af83D71C60D5F6cdA71eCBfDC24C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'AIB',
            'name': 'AIB',
            'address': '0x2fFc487Ef3A76c5794A468DA11021c9058367f7A',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0x2fFc487Ef3A76c5794A468DA11021c9058367f7A/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'RLT',
            'name': 'RLT',
            'address': '0x2a520797D0864DCA9DB40f55869673355b13AF33',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0x2a520797D0864DCA9DB40f55869673355b13AF33/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'EOS',
            'name': 'EOS',
            'address': '0x2D83F0551a65466Aa4872cf25693F4c3e834f602',
            'decimals': 4,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0x2D83F0551a65466Aa4872cf25693F4c3e834f602/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'TPT',
            'name': 'TokenPocket Token',
            'address': '0x263e10bE808bafaD9bd62a0998a36d4e6B9fcb19',
            'decimals': 4,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0x263e10bE808bafaD9bd62a0998a36d4e6B9fcb19/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SYI',
            'name': 'ShouYi',
            'address': '0x24a8449B65a81A9Fb9FDE4d508D7018a9339C5b9',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0x24a8449B65a81A9Fb9FDE4d508D7018a9339C5b9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'LTC',
            'name': 'Litecoin',
            'address': '0x13e93721DC992b3E14333dBdb48C0e7Ec55431c3',
            'decimals': 8,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0x13e93721DC992b3E14333dBdb48C0e7Ec55431c3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'CLAIM',
            'name': 'CLAIM',
            'address': '0x13154efE79d4D7f86407d56f8F6A1B07fB0b80ff',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0x13154efE79d4D7f86407d56f8F6A1B07fB0b80ff/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'MNA',
            'name': 'Moneta',
            'address': '0x124c04Ed0ED67609D194745181CcF2F1DBEf9271',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0x124c04Ed0ED67609D194745181CcF2F1DBEf9271/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'FIL',
            'name': 'Filecoin',
            'address': '0x0bf85D3B0C9ebCC282FDe0591882d12E57E700B3',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0x0bf85D3B0C9ebCC282FDe0591882d12E57E700B3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'YUNGE',
            'name': 'Yunge Protocol',
            'address': '0x07f823D3d011f7C612084f04D025F4a026F76afd',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0x07f823D3d011f7C612084f04D025F4a026F76afd/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ROO',
            'name': 'RooLend',
            'address': '0x01a2cb0cA7A03F1aC7Fc2FCe78552Ba248c2ad46',
            'decimals': 18,
            'chainId': 70,
            'logoURI': 'https://elk.finance/tokens/logos/hoo/0x01a2cb0cA7A03F1aC7Fc2FCe78552Ba248c2ad46/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        }
    ],
    '42161': [
        {
            'symbol': 'GMX',
            'name': 'GMX',
            'address': '0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0xfc5a1a6eb076a2c7ad06ed22c90d7e710e35ad0a.png',
            'coingeckoId': 'gmx',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'TLPT',
            'name': 'tLPT',
            'address': '0xfaC38532829fDD744373fdcd4708Ab90fA0c4078',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/26972/thumb/tenderLPT.png?1661152923',
            'coingeckoId': 'tlpt',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'CARBON',
            'name': 'Carboncoin',
            'address': '0xfa42DA1bd08341537a44a4ca9D236D1c00A98b40',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1637832686338_7422730180325647.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'LINK',
            'name': 'Chainlink',
            'address': '0xf97f4df75117a78c1A5a0DBb814Af92458539FB4',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0x514910771af9ca656af840dff83e8264ecf986ca.png',
            'coingeckoId': 'chainlink',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'CREAM',
            'name': 'Cream',
            'address': '0xf4D48Ce3ee1Ac3651998971541bAdbb9A14D7234',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://cryptologos.cc/logos/cream-finance-cream-logo.png',
            'coingeckoId': 'cream-2',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'RGT',
            'name': 'Rari Governance',
            'address': '0xef888bcA6AB6B1d26dbeC977C455388ecd794794',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://etherscan.io/token/images/RariGovernanceToken_32.png',
            'coingeckoId': 'rari-governance-token',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ACRV',
            'name': 'Aladdin cvxCRV',
            'address': '0xebf1F92D4384118bFb91B4496660a95931c92861',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/25395/thumb/Sv06cFHS_400x400.jpg?1651707422',
            'coingeckoId': 'aladdin-cvxcrv',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ARF',
            'name': 'ArbiRiseFinance',
            'address': '0xeb6F8eEBC83D85120F9299B84DA558825685570e',
            'decimals': 9,
            'chainId': 42161,
            'logoURI': 'https://api.rubic.exchange/assets/arbitrum/0xeb6f8eebc83d85120f9299b84da558825685570e/logo.png',
            'coingeckoId': 'arbirise-finance',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'AUC',
            'name': 'Auctus',
            'address': '0xea986d33eF8a20A96120ecc44dBdD49830192043',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/2165/thumb/Auc_Discord_Avatar1.png?1618983355',
            'coingeckoId': 'auctus',
            'listedIn': [
                'coingecko',
                'arbitrum_bridge',
                'rubic'
            ]
        },
        {
            'symbol': 'ELK',
            'name': 'Elk',
            'address': '0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0xeeeeeb57642040be42185f49c52f7e9b38f8eeee.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NYAN',
            'name': 'ArbiNYAN',
            'address': '0xeD3fB761414DA74b74F33e5c5a1f78104b188DfC',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1638170899569_12235125291982096.png',
            'coingeckoId': 'arbinyan',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'xyETH',
            'name': 'xyETH',
            'address': '0xeAe1BB0B635F6615e0B61c1Cd93d30f0CF93A96E',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
            'coingeckoId': null,
            'listedIn': [
                'xyfinance'
            ]
        },
        {
            'symbol': 'plsJONES',
            'name': 'Plutus JONES',
            'address': '0xe7f6C3c1F0018E4C08aCC52965e5cbfF99e34A44',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://static.debank.com/image/arb_token/logo_url/0xe7f6c3c1f0018e4c08acc52965e5cbff99e34a44/f5ad2bb074de623d734a4e83ac6946a2.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ETHx',
            'name': 'Super ETH',
            'address': '0xe6C8d111337D0052b9D88BF5d7D55B7f8385ACd3',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://static.debank.com/image/arb_token/logo_url/arb/d61441782d4a08a7479d54aea211679e.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AWETH',
            'name': 'Aave WETH',
            'address': '0xe50fA9b3c56FfB159cB0FCA61F5c9D750e8128c8',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/17238/thumb/aWETH_2x.png?1626940782',
            'coingeckoId': 'aave-weth',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'KNC',
            'name': 'Kyber Network Crystal',
            'address': '0xe4DDDfe67E7164b0FE14E218d80dC4C08eDC01cB',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/14899/thumb/RwdVsGcw_400x400.jpg?1618923851',
            'coingeckoId': 'kyber-network-crystal',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALTA',
            'name': 'Alta Finance',
            'address': '0xe0cCa86B254005889aC3a81e737f56a14f4A38F5',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/18713/thumb/AFN-token-Altafin-200.png?1633079552',
            'coingeckoId': 'alta-finance',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'DEFI5',
            'name': 'DEFI Top 5 Tokens Index',
            'address': '0xdeBa25AF35e4097146d7629055E0EC3C71706324',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/13691/thumb/thGDKHo.png?1610959947',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge',
                'rubic'
            ]
        },
        {
            'symbol': 'KSW',
            'name': 'KillSwitch',
            'address': '0xdc7179416c08c15f689d9214A3BeC2Dd003DeABc',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/20215/thumb/logo_%2824%29.png?1636670633',
            'coingeckoId': 'killswitch',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'Z2O',
            'name': 'ZeroTwOhm',
            'address': '0xdb96f8efd6865644993505318cc08FF9C42fb9aC',
            'decimals': 9,
            'chainId': 42161,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1637928264168_1276740912855694.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'SWPR',
            'name': 'Swapr',
            'address': '0xdE903E2712288A1dA82942DDdF2c20529565aC30',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/18740/thumb/swapr.jpg?1633516501',
            'coingeckoId': 'swapr',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'HONEY',
            'name': 'Honey Pot BeeKeepers',
            'address': '0xdE31e75182276738B0c025daa8F80020A4F2fbFE',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/18380/thumb/ac6fTCfDQDIV.jpg?1631695015',
            'coingeckoId': 'honey-pot-beekeepers',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'PERPI',
            'name': 'Perp Inu',
            'address': '0xdAB8C8776a4041415a60ED6b339D8E667cf2a934',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/28272/thumb/logo.png?1669002795',
            'coingeckoId': 'perp-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POND',
            'name': 'Marlin POND',
            'address': '0xdA0a57B710768ae17941a9Fa33f8B720c8bD9ddD',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'UMA',
            'name': 'UMA Voting Token v1',
            'address': '0xd693Ec944A85eeca4247eC1c3b130DCa9B0C3b22',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'NEC',
            'name': 'New era',
            'address': '0xd67D9F7E018B4e7613b0251BBe3Ba3988Baf7C16',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'UDT',
            'name': 'Unlock Discount Token',
            'address': '0xd5d3aA404D7562d09a848F96a8a8d5D65977bF90',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/14545/thumb/unlock.jpg?1616948136',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'SUSHI',
            'name': 'Sushi',
            'address': '0xd4d42F0b6DEF4CE0383636770eF773390d85c61A',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0x6b3595068778dd592e39a122f4f5a5cf09c90fe2.png',
            'coingeckoId': 'sushi',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'BOOST',
            'name': 'Boost',
            'address': '0xd44e8F8768D4ed25119921a53802D8758A5b20dD',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://etherscan.io/token/images/boostcoin_32.png',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'DBL',
            'name': 'Doubloon',
            'address': '0xd3f1Da62CAFB7E7BC6531FF1ceF6F414291F03D3',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/23660/thumb/galleon-sky.png?1651744892',
            'coingeckoId': 'doubloon',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'XCAL',
            'name': '3xcalibur Ecosystem Token',
            'address': '0xd2568acCD10A4C98e87c44E9920360031ad89fCB',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/28134/thumb/XCAL_Logo.png?1667719684',
            'coingeckoId': '3xcalibur',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IQ',
            'name': 'Everipedia IQ',
            'address': '0xce4DB2ce8cCa463f8Aa1e2174C244Ba4a8d672cB',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://api.rubic.exchange/assets/arbitrum/0xce4db2ce8cca463f8aa1e2174c244ba4a8d672cb/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'USX',
            'name': 'dForce USD',
            'address': '0xcd14C3A2ba27819B352aae73414A26e2b366dC50',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://etherscan.io/token/images/dforceusd_32.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'NFTI',
            'name': 'Scalara NFT Index',
            'address': '0xcFe3FBc98D80f7Eca0bC76cD1F406A19dD425896',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0xcfe3fbc98d80f7eca0bc76cd1f406a19dd425896.png',
            'coingeckoId': 'scalara-nft-index',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'WOO',
            'name': 'WOO Network',
            'address': '0xcAFcD85D8ca7Ad1e1C6F82F651fA15E33AEfD07b',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/12921/thumb/w2UiemF__400x400.jpg?1603670367',
            'coingeckoId': 'woo-network',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'deETH',
            'name': 'deBridge Ether',
            'address': '0xcAB86F6Fb6d1C2cBeeB97854A0C023446A075Fe3',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0xcab86f6fb6d1c2cbeeb97854a0c023446a075fe3.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'NFD',
            'name': 'Feisty Doge NFT',
            'address': '0xc9c2B86CD4cdbAB70cd65D22EB044574c3539F6c',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://etherscan.io/token/images/feistydoge_32.png',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'HOP',
            'name': 'Hop Protocol',
            'address': '0xc5102fE9359FD9a28f877a67E36B0F050d81a3CC',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0xc5102fe9359fd9a28f877a67e36b0f050d81a3cc.png',
            'coingeckoId': 'hop-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'OCCT',
            'name': 'Official Crypto Cowboy Token',
            'address': '0xc1a49c0B9C10F35850bd8E15EaeF0346BE63E002',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CREDA',
            'name': 'CreDA',
            'address': '0xc136E6B376a9946B156db1ED3A34b08AFdAeD76d',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/arbitrum/assets/0xc136E6B376a9946B156db1ED3A34b08AFdAeD76d/logo.png',
            'coingeckoId': 'creda',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'USDR',
            'name': 'USD Redeem',
            'address': '0xbc60FF90497F99cbf6FB84Ce1E31845637033445',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://api.rubic.exchange/assets/arbitrum/0xbc60ff90497f99cbf6fb84ce1e31845637033445/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'AAVE',
            'name': 'Aave Token',
            'address': '0xba5DdD1f9d7F570dc94a51479a000E3BCE967196',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://api.rubic.exchange/assets/arbitrum/0xba5ddd1f9d7f570dc94a51479a000e3bce967196/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'USD24',
            'name': 'Fiat24 USD',
            'address': '0xbE00f3db78688d9704BCb4e0a827aea3a9Cc0D62',
            'decimals': 2,
            'chainId': 42161,
            'logoURI': 'https://api.rubic.exchange/assets/arbitrum/0xbe00f3db78688d9704bcb4e0a827aea3a9cc0d62/logo.png',
            'coingeckoId': 'fiat24-usd',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'LASM',
            'name': 'LasMeta',
            'address': '0xbC2E674dFe0d885081f429b296D7aE2B784D1198',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FS',
            'name': 'FantomStarter',
            'address': '0xbB85D38fAA5064FAB8bf3E0a79583a2670F03DbC',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CRE8R',
            'name': 'CRE8R DAO',
            'address': '0xb96B904ba83DdEeCE47CAADa8B40EE6936D92091',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CVX',
            'name': 'Convex Token',
            'address': '0xb952A807345991BD529FDded05009F5e80Fe8F45',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://static.debank.com/image/arb_token/logo_url/0xb952a807345991bd529fdded05009f5e80fe8f45/6db831d4aa5593c6a39718a890b1bbd3.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'C4G3',
            'name': 'Cage Token',
            'address': '0xb576C9403f39829565BD6051695E2AC7Ecf850E2',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'RAI',
            'name': 'Rai Reflex Index',
            'address': '0xaeF5bbcbFa438519a5ea80B4c7181B4E78d419f2',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://etherscan.io/token/images/raireflexindex_32.png',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'PAE',
            'name': 'Ripae',
            'address': '0xae8627dB72d7Fc6241f2E2B87EB2BbC7cAfB1661',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MATTER',
            'name': 'AntiMatter',
            'address': '0xaaA62D9584Cbe8e4D68A43ec91BfF4fF1fAdB202',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://etherscan.io/token/images/antimatter_32.png',
            'coingeckoId': 'antimatter',
            'listedIn': [
                'coingecko',
                'arbitrum_bridge',
                'rubic'
            ]
        },
        {
            'symbol': 'DF',
            'name': 'dForce',
            'address': '0xaE6aab43C4f3E0cea4Ab83752C278f8dEbabA689',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/9709/thumb/xlGxxIjI_400x400.jpg?1571006794',
            'coingeckoId': 'dforce-token',
            'listedIn': [
                'coingecko',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'TNGL',
            'name': 'Tangle',
            'address': '0xa943F863fA69ff4F6D9022843Fb861BBEe45B2ce',
            'decimals': 9,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/18312/thumb/tangle.PNG?1631510159',
            'coingeckoId': 'tangle',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'BICO',
            'name': 'Biconomy Token',
            'address': '0xa68Ec98D7ca870cF1Dd0b00EBbb7c4bF60A8e74d',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'VSTA',
            'name': 'Vesta Finance',
            'address': '0xa684cd057951541187f288294a1e1C2646aA2d24',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1648808309036_20667985804740718.jpg',
            'coingeckoId': 'vesta-finance',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'LYS',
            'name': 'LYS Capital',
            'address': '0xa4f595Ba35161c9fFE3db8c03991B9C2CBB26C6b',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/20863/thumb/1637613107-lys-logo-cg-200pix.png?1637805637',
            'coingeckoId': 'lys-capital',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'GNO',
            'name': 'Gnosis',
            'address': '0xa0b862F60edEf4452F25B4160F177db44DeB6Cf1',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0x6810e776880c02933d47db1b9fc05908e5386b96.png',
            'coingeckoId': 'gnosis',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'Tether',
            'address': '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
            'decimals': 6,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0xdac17f958d2ee523a2206206994597c13d831ec7.png',
            'coingeckoId': 'tether',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MGN',
            'name': 'Mugen Finance',
            'address': '0xFc77b86F3ADe71793E1EEc1E7944DB074922856e',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/27340/thumb/Group_2915.jpg?1663549948',
            'coingeckoId': 'mugen-finance',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'UNI',
            'name': 'Uniswap',
            'address': '0xFa7F8980b0f1E64A2062791cc3b0871572f1F7f0',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0x1f9840a85d5af5bf1d1762f925bdaddc4201f984.png',
            'coingeckoId': 'uniswap',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'TAC',
            'name': 'Taekwondo Access Credit',
            'address': '0xFa51B42d4C9EA35F1758828226AaEdBeC50DD54E',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://etherscan.io/token/images/tkdcoop_32.png',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USD Coin',
            'address': '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
            'decimals': 6,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png',
            'coingeckoId': 'usd-coin',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MIM',
            'name': 'Magic Internet Money',
            'address': '0xFEa7a6a0B346362BF88A9e4A88416B77a57D6c2A',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0xfea7a6a0b346362bf88a9e4a88416b77a57d6c2a.png',
            'coingeckoId': 'magic-internet-money',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'FLUX',
            'name': 'Flux',
            'address': '0xF80D589b3Dbe130c270a69F1a69D050f268786Df',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/11756/thumb/fluxres.png?1593748917',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'sSPELL',
            'name': 'Staked Spell Token',
            'address': '0xF7428FFCb2581A2804998eFbB036A43255c8A8D3',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/logos/main/network/arbitrum/0xF7428FFCb2581A2804998eFbB036A43255c8A8D3.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'HDX',
            'name': 'Hydranet',
            'address': '0xF4fe727C855c2D395852ca43F645caB4b504Af23',
            'decimals': 9,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/25177/thumb/HDXdarkblueInv.png?1652694650',
            'coingeckoId': 'hydranet',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'EDR',
            'name': 'Endor Protocol Token',
            'address': '0xF242F6FcB8f03B2A5e22cF7a61b843C89748Abb2',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://api.rubic.exchange/assets/arbitrum/0xf242f6fcb8f03b2a5e22cf7a61b843c89748abb2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'plsDPX',
            'name': 'Plutus DPX',
            'address': '0xF236ea74B515eF96a9898F5a4ed4Aa591f253Ce1',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://static.debank.com/image/arb_token/logo_url/0xf236ea74b515ef96a9898f5a4ed4aa591f253ce1/fc2be23843f62589e32b3d48f8441f62.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'LODE',
            'name': 'Lodestar',
            'address': '0xF19547f9ED24aA66b03c3a552D181Ae334FBb8DB',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/28218/thumb/lode.png?1668429341',
            'coingeckoId': 'lodestar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MNTO',
            'name': 'Minato',
            'address': '0xF0DFAD1817b5ba73726B02Ab34dd4B4B00bcD392',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/24622/thumb/MNTO_200x200.png?1648448664',
            'coingeckoId': 'minato',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'DUSD',
            'name': 'DigitalDollar',
            'address': '0xF0B5cEeFc89684889e5F7e0A7775Bd100FcD3709',
            'decimals': 6,
            'chainId': 42161,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xF0B5cEeFc89684889e5F7e0A7775Bd100FcD3709/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'XTK',
            'name': 'xToken',
            'address': '0xF0A5717Ec0883eE56438932b0fe4A20822735fBa',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/14089/thumb/xToken.png?1614226407',
            'coingeckoId': 'xtoken',
            'listedIn': [
                'coingecko',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'jDPX',
            'name': 'Jones DPX',
            'address': '0xF018865b26fFAb9cd1735DCca549D95b0CB9Ea19',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://static.debank.com/image/arb_token/logo_url/0xf018865b26ffab9cd1735dcca549d95b0cb9ea19/79258c411d40a15bf69ce9f710d9379a.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'O3',
            'name': 'O3 Swap',
            'address': '0xEe9801669C6138E84bD50dEB500827b776777d28',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/arbitrum/assets/0xEe9801669C6138E84bD50dEB500827b776777d28/logo.png',
            'coingeckoId': 'o3-swap',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'RPLx',
            'name': 'Superfluid RPL',
            'address': '0xEFa54BE8D63FD0d95eDd7965d0bd7477C33995A8',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://static.debank.com/image/arb_token/logo_url/0xb766039cc6db368759c1e56b79affe831d0cc507/f310ef0bdbf9f72e72cd54ff7f4d3ee6.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WMEMO',
            'name': 'Wonderful Memories',
            'address': '0xECf2ADafF1De8A512f6e8bfe67a2C836EDb25Da3',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/22392/thumb/wMEMO.png?1665832927',
            'coingeckoId': 'wrapped-memory',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'DSU',
            'name': 'Digital Standard Unit',
            'address': '0xE212f5E733257ed5628a2FeBcEdBc9222e535F51',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/17482/thumb/photo_2021-08-03_09-24-16.png?1627953917',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'FOREX',
            'name': 'handle fi',
            'address': '0xDb298285FE4C5410B05390cA80e8Fbe9DE1F259B',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/18533/thumb/handle.fiFOREXLogoDark200x200.png?1632755675',
            'coingeckoId': 'handle-fi',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'DEUS',
            'name': 'DEUS',
            'address': '0xDE5ed76E7c05eC5e4572CfC88d1ACEA165109E44',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DEI',
            'name': 'DEI',
            'address': '0xDE1E704dae0B4051e80DAbB26ab6ad6c12262DA0',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'RENBTC',
            'name': 'renBTC',
            'address': '0xDBf31dF14B66535aF65AaC99C32e9eA844e14501',
            'decimals': 8,
            'chainId': 42161,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1661151044584_6641692875412855.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'PLG',
            'name': 'Plunge',
            'address': '0xDAd3ABce6Fb87FA0355203b692723A7EE8aa9D63',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DAI',
            'name': 'Dai',
            'address': '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0x6b175474e89094c44da98b954eedeac495271d0f.png',
            'coingeckoId': 'dai',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'KITTY',
            'name': 'KITTY',
            'address': '0xD98274562eb6e18f9DeecFF23E8164fF83861a6d',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'USDS',
            'name': 'Sperax USD',
            'address': '0xD74f5255D557944cf7Dd0E45FF521520002D5748',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0xd74f5255d557944cf7dd0e45ff521520002d5748.png',
            'coingeckoId': 'sperax-usd',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'EURS',
            'name': 'STASISEURSToken',
            'address': '0xD22a58f79e9481D1a88e00c343885A588b34b68B',
            'decimals': 2,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ESS',
            'name': 'Empty Set Share',
            'address': '0xCE32aA8d60807182c0003Ef9cc1976Fa10E5d312',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/17481/thumb/photo_2021-08-03_03-26-29.png?1627953584',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'ICE',
            'name': 'Ice Token',
            'address': '0xCB58418Aa51Ba525aEF0FE474109C0354d844b7c',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/logos/main/network/arbitrum/0xCB58418Aa51Ba525aEF0FE474109C0354d844b7c.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'MYC',
            'name': 'Mycelium',
            'address': '0xC74fE4c715510Ec2F8C61d70D397B32043F55Abe',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0xc74fe4c715510ec2f8c61d70d397b32043f55abe.png',
            'coingeckoId': 'mycelium',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'OSEA',
            'name': 'Omnisea',
            'address': '0xC72633F995e98Ac3BB8a89e6a9C4Af335C3D6E44',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/26475/thumb/293837892_407994084681555_3167689470652146992_n.png?1658195766',
            'coingeckoId': 'omnisea',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'DXD',
            'name': 'DXdao',
            'address': '0xC3Ae0333F0F34aa734D5493276223d95B8F9Cb37',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0xa1d65e8fb6e87b60feccbc582f7f97804b725521.png',
            'coingeckoId': 'dxdao',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'EUX',
            'name': 'dForce EUR',
            'address': '0xC2125882318d04D266720B598d620f28222F3ABd',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://static.debank.com/image/arb_token/logo_url/0xc2125882318d04d266720b598d620f28222f3abd/ab7db3e405ffcae228c7c65501b9d06e.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BADGER',
            'name': 'Badger DAO',
            'address': '0xBfa641051Ba0a0Ad1b0AcF549a89536A0D76472E',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0x3472a5a71965499acd81997a54bba8d852c6e53d.png',
            'coingeckoId': 'badger-dao',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'BARK',
            'name': 'BarkCoin',
            'address': '0xBbFbde08Bf1BE235a3Fa97d6A27fFfA19Ac4a8a8',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'FUSE',
            'name': 'Fuse Token',
            'address': '0xBDeF0E9ef12E689F366fe494A7A7D0dad25D9286',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/10347/thumb/vUXKHEe.png?1601523640',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'BTU',
            'name': 'BTU Protocol',
            'address': '0xBA9a5Dd807c9F072850bE15a52dF3408BA25Fd18',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://etherscan.io/token/images/btu_32.png',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'QI',
            'name': 'QiDao',
            'address': '0xB9C8F0d3254007eE4b98970b94544e473Cd610EC',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://static.debank.com/image/arb_token/logo_url/0xb9c8f0d3254007ee4b98970b94544e473cd610ec/c0524dfb9be687e8e26191eed8774fd3.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'NDX',
            'name': 'Indexed Finance',
            'address': '0xB965029343D55189c25a7f3e0c9394DC0F5D41b1',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/13546/thumb/indexed-light.74bb5471.png?1609712728',
            'coingeckoId': 'indexed-finance',
            'listedIn': [
                'coingecko',
                'arbitrum_bridge',
                'rubic'
            ]
        },
        {
            'symbol': 'RPL',
            'name': 'Rocket Pool Protocol',
            'address': '0xB766039cc6DB368759C1E56B79AFfE831d0Cc507',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://static.debank.com/image/arb_token/logo_url/0xb766039cc6db368759c1e56b79affe831d0cc507/f310ef0bdbf9f72e72cd54ff7f4d3ee6.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BRC',
            'name': 'Brinc Finance',
            'address': '0xB5de3f06aF62D8428a8BF7b4400Ea42aD2E0bc53',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/26116/thumb/76469697.png?1655937532',
            'coingeckoId': 'brinc-finance',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'IMO',
            'name': 'Ideamarket',
            'address': '0xB41bd4C99dA73510d9e081C5FADBE7A27Ac1F814',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/arbitrum/assets/0xB41bd4C99dA73510d9e081C5FADBE7A27Ac1F814/logo.png',
            'coingeckoId': 'ideamarket',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'PPO',
            'name': 'prePO',
            'address': '0xB40DBBb7931Cfef8Be73AEEC6c67d3809bD4600B',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/27328/thumb/PPO_logo_200x200.png?1663379860',
            'coingeckoId': 'prepo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DICE',
            'name': 'Party Dice',
            'address': '0xAeEBa475eDC438f8Eeb6BFBc3164c1C7716Fb304',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/list/master/logos/token-logos/network/arbitrum/0xAeEBa475eDC438f8Eeb6BFBc3164c1C7716Fb304.jpg',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BUCK',
            'name': 'Arbucks',
            'address': '0xAFD871f684F21Ab9D7137608C71808f83D75e6fc',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/arbitrum/assets/0xAFD871f684F21Ab9D7137608C71808f83D75e6fc/logo.png',
            'coingeckoId': 'arbucks',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'DEGEN',
            'name': 'DEGEN Index',
            'address': '0xAE6e3540E97b0b9EA8797B157B510e133afb6282',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/14143/thumb/alpha_logo.png?1614651244',
            'coingeckoId': 'degen-index',
            'listedIn': [
                'coingecko',
                'arbitrum_bridge',
                'rubic'
            ]
        },
        {
            'symbol': 'MAL',
            'name': 'My Alpha Leaderboard',
            'address': '0xAA086809EFA469631DD90D8C6cB267bAb107E958',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'SUSD',
            'name': 'sUSD',
            'address': '0xA970AF1a584579B618be4d69aD6F73459D112F95',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/5013/thumb/sUSD.png?1616150765',
            'coingeckoId': 'nusd',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ArbiMATTER',
            'name': 'ArbiMATTER',
            'address': '0xA7e1d00F1DC94040A8AAd3aD993589f3E39a6B7A',
            'decimals': 9,
            'chainId': 42161,
            'logoURI': 'https://api.rubic.exchange/assets/arbitrum/0xa7e1d00f1dc94040a8aad3ad993589f3e39a6b7a/logo.png',
            'coingeckoId': 'arbimatter',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DVF',
            'name': 'Rhino fi',
            'address': '0xA7Aa2921618e3D63dA433829d448b58C9445A4c3',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://etherscan.io/token/images/dvf_32.png',
            'coingeckoId': 'rhinofi',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'TCR',
            'name': 'Tracer DAO',
            'address': '0xA72159FC390f0E3C6D415e658264c7c4051E9b87',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/18271/thumb/tracer_logo.png?1631176676',
            'coingeckoId': 'tracer-dao',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'WCHI',
            'name': 'Wrapped CHI',
            'address': '0xA64eCCe74F8CdB7a940766B71f1b108BAC69851a',
            'decimals': 8,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/2091/thumb/xaya_logo-1.png?1547036406',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge',
                'rubic'
            ]
        },
        {
            'symbol': 'ARVAULT',
            'name': 'ArVault',
            'address': '0xA6219B4Bf4B861A2b1C02da43b2aF266186eDC04',
            'decimals': 9,
            'chainId': 42161,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/arbitrum/assets/0xA6219B4Bf4B861A2b1C02da43b2aF266186eDC04/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BLANK',
            'name': 'GoBlank Token',
            'address': '0xA5eC9d64b64b8B9E94FEaA7538c084b38117E7Ba',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://etherscan.io/token/images/blanktoken_32.png',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'ARBIS',
            'name': 'Arbis Finance',
            'address': '0x9f20de1fc9b161b34089cbEAE888168B44b03461',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/arbitrum/assets/0x9f20de1fc9b161b34089cbEAE888168B44b03461/logo.png',
            'coingeckoId': 'arbis-finance',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'XDO',
            'name': 'xDollar',
            'address': '0x9eF758aC000a354479e538B8b2f01b917b8e89e7',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1637831645816_6024340081244819.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ASCEND',
            'name': 'Ascension Protocol',
            'address': '0x9e724698051DA34994F281bD81C3E7372d1960AE',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/16019/thumb/icon200.png?1622612188',
            'coingeckoId': 'ascension-protocol',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'FXS',
            'name': 'Frax Share',
            'address': '0x9d2F299715D94d8A7E6F5eaa8E654E8c74a988A7',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1648904710979_811680259074864.png',
            'coingeckoId': 'frax-share',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'IMX',
            'name': 'Impermax  OLD ',
            'address': '0x9c67eE39e3C4954396b9142010653F17257dd39C',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://etherscan.io/token/images/impermax_32.png',
            'coingeckoId': 'impermax',
            'listedIn': [
                'coingecko',
                'arbitrum_bridge',
                'rubic'
            ]
        },
        {
            'symbol': 'ALN',
            'name': 'Aluna',
            'address': '0x9b3fa2A7C3EB36d048A5d38d81E7fAFC6bc47B25',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/14379/thumb/uaLoLU8c_400x400_%281%29.png?1627873106',
            'coingeckoId': 'aluna',
            'listedIn': [
                'coingecko',
                'arbitrum_bridge',
                'rubic'
            ]
        },
        {
            'symbol': 'SHN',
            'name': 'Shine',
            'address': '0x9b17bAADf0f21F03e35249e0e59723F34994F806',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MULTI',
            'name': 'Multichain',
            'address': '0x9Fb9a33956351cf4fa040f65A13b835A3C8764E3',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/22087/thumb/1_Wyot-SDGZuxbjdkaOeT2-A.png?1640764238',
            'coingeckoId': 'multichain',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ibBTC',
            'name': 'Interest-Bearing Bitcoin',
            'address': '0x9Ab3FD50FcAe73A1AEDa959468FD0D662c881b42',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/15500/thumb/ibbtc.png?1621077589',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'MATH',
            'name': 'MATH',
            'address': '0x99F40b01BA9C469193B360f72740E416B17Ac332',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0x08d967bb0134f2d07f7cfb6e246680c53927dd30.png',
            'coingeckoId': 'math',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'BIFI',
            'name': 'Beefy Finance',
            'address': '0x99C409E5f62E4bd2AC142f17caFb6810B8F0BAAE',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/arbitrum/assets/0x99C409E5f62E4bd2AC142f17caFb6810B8F0BAAE/logo.png',
            'coingeckoId': 'beefy-finance',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'XIM',
            'name': 'xDollar Interverse Money',
            'address': '0x99C34786eB12C547D239671bD74B2f096E811d94',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'VISR',
            'name': 'Visor',
            'address': '0x995C235521820f2637303Ca1970c7c044583df44',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/14381/thumb/visor_logo.png?1615782828',
            'coingeckoId': 'visor',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'CNT',
            'name': 'Cryption Network Token',
            'address': '0x989D099d29F62b839C8CbD41c82c6554a5515752',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://etherscan.io/token/images/cryptionnetwork_32.png',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'EUX',
            'name': 'dForce EUR',
            'address': '0x969131D8ddC06C2Be11a13e6E7fACF22CF57d95e',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://etherscan.io/token/images/dforceeur_32.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'PICKLE',
            'name': 'Pickle Finance',
            'address': '0x965772e0E9c84b6f359c8597C891108DcF1c5B1A',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://etherscan.io/token/images/pickle_32.png',
            'coingeckoId': 'pickle-finance',
            'listedIn': [
                'coingecko',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'SWPR',
            'name': 'Swapr',
            'address': '0x955b9fe60a5b5093df9Dc4B1B18ec8e934e77162',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/swpr.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'LEVI',
            'name': 'LeverageInu',
            'address': '0x954ac1c73e16c77198e83C088aDe88f6223F3d44',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/28147/thumb/IMG_20221107_091938_841.png?1667896989',
            'coingeckoId': 'leverageinu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LIQD',
            'name': 'Liquid Finance',
            'address': '0x93C15cd7DE26f07265f0272E0b831C5D7fAb174f',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0x93c15cd7de26f07265f0272e0b831c5d7fab174f.png',
            'coingeckoId': 'liquid-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'PONY',
            'name': 'PONY Index',
            'address': '0x937E077aBaEA52d3abf879c9b9d3f2eBd15BAA21',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MAGNET',
            'name': 'Magnethereum',
            'address': '0x8eD4191F81F1e1D24a8a1195267D024d9358c9d7',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://raw.githubusercontent.com/Magnethereum/MyFiles/main/magnethereum.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'VALX',
            'name': 'Validator',
            'address': '0x8d1c89DcF613e3e709AfE9Abecae591D0e2B64Ca',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://etherscan.io/token/images/validator_32.png',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'AMY',
            'name': 'Amy Finance Token',
            'address': '0x8Fbd420956FDD301f4493500fd0BCaAa80f2389C',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1637893005424_2740901520185888.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'NITRODOGE',
            'name': 'nitroDOGE',
            'address': '0x8E75DafEcf75de7747A05B0891177ba03333a166',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/27161/thumb/nitrodoge_2.png?1662280112',
            'coingeckoId': 'nitrodoge',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'GOHM',
            'name': 'Governance OHM',
            'address': '0x8D9bA570D6cb60C7e3e0F31343Efe75AB8E65FB1',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/21129/small/token_wsOHM_logo.png?1638764900',
            'coingeckoId': 'governance-ohm',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'FLUID',
            'name': 'FluidFi',
            'address': '0x876Ec6bE52486Eeec06bc06434f3E629D695c6bA',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x876Ec6bE52486Eeec06bc06434f3E629D695c6bA/logo.png',
            'coingeckoId': 'fluidfi',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'OMIC',
            'name': 'Omicron',
            'address': '0x86b3353387F560295a8Fa7902679735E5f076Bd5',
            'decimals': 9,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/19954/thumb/YHCCP1e.png?1636339997',
            'coingeckoId': 'omicron',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'ARBYS',
            'name': 'Arbys',
            'address': '0x86A1012d437BBFf84fbDF62569D12d4FD3396F8c',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/arbitrum/assets/0x86A1012d437BBFf84fbDF62569D12d4FD3396F8c/logo.png',
            'coingeckoId': 'arbys',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'FXUSD',
            'name': 'handleUSD',
            'address': '0x8616E8EA83f048ab9A5eC513c9412Dd2993bcE3F',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/26954/thumb/fxUSDLogo_200px-200px.png?1660988882',
            'coingeckoId': 'handleusd',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'AGVE',
            'name': 'Agave',
            'address': '0x848e0BA28B637e8490D88BaE51fA99C87116409B',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/14146/thumb/agve.png?1614659384',
            'coingeckoId': 'agave-token',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'YFI',
            'name': 'yearn finance',
            'address': '0x82e3A8F066a6989666b031d916c43672085b1582',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://cryptologos.cc/logos/yearn-finance-yfi-logo.png',
            'coingeckoId': 'yearn-finance',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'WETH',
            'name': 'WETH',
            'address': '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2.png',
            'coingeckoId': 'weth',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ADAI',
            'name': 'Aave DAI',
            'address': '0x82E64f49Ed5EC1bC6e43DAD4FC8Af9bb3A2312EE',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/14242/thumb/aDAI.84b6c41f.png?1615528749',
            'coingeckoId': 'aave-dai',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'CVOL',
            'name': 'Crypto Volatility',
            'address': '0x8096aD3107715747361acefE685943bFB427C722',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/24008/thumb/govi-dao.03ef3083.png?1646029661',
            'coingeckoId': 'crypto-volatility-token',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'DHT',
            'name': 'dHEDGE DAO',
            'address': '0x8038F3C971414FD1FC220bA727F2D4A0fC98cb65',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0xca1207647ff814039530d7d35df0e1dd2e91fa84.png',
            'coingeckoId': 'dhedge-dao',
            'listedIn': [
                'coingecko',
                '1inch',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': '0XBTC',
            'name': '0xBitcoin',
            'address': '0x7cb16cb78ea464aD35c8a50ABF95dff3c9e09d5d',
            'decimals': 8,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/4454/thumb/0xbtc.png?1561603765',
            'coingeckoId': 'oxbitcoin',
            'listedIn': [
                'coingecko',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'SDT',
            'name': 'Stake DAO',
            'address': '0x7bA4a00d54A07461D9DB2aEF539e91409943AdC9',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/13724/thumb/stakedao_logo.jpg?1611195011',
            'coingeckoId': 'stake-dao',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'YIN',
            'name': 'YIN Finance',
            'address': '0x794Baab6b878467F93EF17e2f2851ce04E3E34C8',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PPEGG',
            'name': 'Parrot Egg',
            'address': '0x78055dAA07035Aa5EBC3e5139C281Ce6312E1b22',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/arbitrum/assets/0x78055dAA07035Aa5EBC3e5139C281Ce6312E1b22/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'LEVR',
            'name': 'LEVR - levr.ly Logistics Token',
            'address': '0x77De4df6F2d87Cc7708959bCEa45d58B0E8b8315',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DEUSDC',
            'name': 'deUSDC',
            'address': '0x76b44e0Cf9bD024dbEd09E1785DF295D59770138',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/26669/thumb/deUSDC.png?1659509853',
            'coingeckoId': 'deusdc',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'SDL',
            'name': 'Saddle Finance',
            'address': '0x75C9bC761d88f70156DAf83aa010E84680baF131',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0x75c9bc761d88f70156daf83aa010e84680baf131.png',
            'coingeckoId': 'saddle-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'PERP',
            'name': 'Perpetual',
            'address': '0x753D224bCf9AAFaCD81558c32341416df61D3DAC',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/12381/thumb/60d18e06844a844ad75901a9_mark_only_03.png?1628674771',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'BSGG',
            'name': 'BETSWAP.GG',
            'address': '0x750bfe8490175c2A9A9387b19Aa2AaE2d75dB638',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/17169.png',
            'coingeckoId': null,
            'listedIn': [
                'RouterProtocol',
                'lifinance'
            ]
        },
        {
            'symbol': 'RODO',
            'name': 'RODO',
            'address': '0x74b4db963738A2A95bFCb1833B123495Ccc220e2',
            'decimals': 2,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'wsOHM',
            'name': 'Wrapped sOHM',
            'address': '0x739ca6D71365a08f584c8FC4e1029045Fa8ABC4B',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0x739ca6d71365a08f584c8fc4e1029045fa8abc4b.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'lqETH',
            'name': 'liquid ETH',
            'address': '0x73700aeCfC4621E112304B6eDC5BA9e36D7743D3',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/list/master/logos/token-logos/network/arbitrum/0x73700aeCfC4621E112304B6eDC5BA9e36D7743D3.jpg',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'nICE',
            'name': 'StakedIceTokens',
            'address': '0x6c2c113c8cA73dB67224EF4D8C8dFCeC61e52a9C',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DD',
            'name': 'DDstable',
            'address': '0x6b289CCeAA8639e3831095D75A3e43520faBf552',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AUSDT',
            'name': 'Aave USDT',
            'address': '0x6ab707Aca953eDAeFBc4fD23bA73294241490620',
            'decimals': 6,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/14243/thumb/aUSDT.78f5faae.png?1615528400',
            'coingeckoId': 'aave-usdt',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'COTI',
            'name': 'COTI Token',
            'address': '0x6FE14d3CC2f7bDdffBa5CdB3BBE7467dd81ea101',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://cryptologos.cc/logos/coti-coti-logo.png',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'BAC',
            'name': 'BAC',
            'address': '0x6F67043201C903bbCBC129750CB3b328Dd56a0a5',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/13246/thumb/BAC.png?1613231642',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'CNFI',
            'name': 'Connect Financial',
            'address': '0x6F5401c53e2769c858665621d22DDBF53D8d27c5',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/13592/thumb/cf-logo-iconic-black.png?1644479524',
            'coingeckoId': 'connect-financial',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'OHM',
            'name': 'Olympus',
            'address': '0x6E6a3D8F1AfFAc703B1aEF1F43B8D2321bE40043',
            'decimals': 9,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/14483/thumb/token_OHM_%281%29.png?1628311611',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'DPX',
            'name': 'Dopex',
            'address': '0x6C2C06790b3E3E3c38e12Ee22F8183b37a13EE55',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0x6c2c06790b3e3e3c38e12ee22f8183b37a13ee55.png',
            'coingeckoId': 'dopex',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'L2PAD',
            'name': 'L2PAD',
            'address': '0x6Ba4edd6dB54eD34d53D8d8883E599C4dba009fb',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/23694/thumb/l2pad_200x200.png?1645080419',
            'coingeckoId': 'l2pad',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'DODO',
            'name': 'DODO',
            'address': '0x69Eb4FA4a2fbd498C257C57Ea8b7655a2559A581',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://etherscan.io/token/images/dodo_32.png',
            'coingeckoId': 'dodo',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'POP',
            'name': 'Popcorn',
            'address': '0x68eAd55C258d6fa5e46D67fc90f53211Eab885BE',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/21438/thumb/pop-1_200_x_200.png?1662607611',
            'coingeckoId': 'popcorn',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'eFIL',
            'name': 'Ethereum FIL',
            'address': '0x66ba30d5D7c66aFd4ed41c3498eC9e20a03a1368',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://api.rubic.exchange/assets/arbitrum/0x66ba30d5d7c66afd4ed41c3498ec9e20a03a1368/logo.png',
            'coingeckoId': 'efil',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'STG',
            'name': 'Stargate Finance',
            'address': '0x6694340fc020c5E6B96567843da2df01b2CE1eb6',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0x6694340fc020c5e6b96567843da2df01b2ce1eb6.png',
            'coingeckoId': 'stargate-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'FRAX',
            'name': 'Frax',
            'address': '0x667Fd83E24Ca1D935d36717D305D54fA0CAC991C',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'wsSQUID',
            'name': 'Wrapped sSQUID',
            'address': '0x6633C083F54bB65F702F9f276319bb24b65FeC18',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'jETH',
            'name': 'Jones ETH',
            'address': '0x662d0f9Ff837A51cF89A1FE7E0882a906dAC08a3',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/list/master/logos/token-logos/network/arbitrum/0x662d0f9Ff837A51cF89A1FE7E0882a906dAC08a3.jpg',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'VST',
            'name': 'Vesta Stable',
            'address': '0x64343594Ab9b56e99087BfA6F2335Db24c2d1F17',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0x64343594ab9b56e99087bfa6f2335db24c2d1f17.png',
            'coingeckoId': 'vesta-stable',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'USX',
            'name': 'dForce USD',
            'address': '0x641441c631e2F909700d2f41FD87F0aA6A6b4EDb',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/17422/thumb/usx_32.png?1627600920',
            'coingeckoId': 'token-dforce-usd',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'KOIN',
            'name': 'Koinos',
            'address': '0x63209DE89dE9f4d5FF0777d4eb2D6Ce1A3A98A3C',
            'decimals': 8,
            'chainId': 42161,
            'logoURI': 'https://api.rubic.exchange/assets/arbitrum/0x63209de89de9f4d5ff0777d4eb2d6ce1a3a98a3c/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'gBRC',
            'name': 'BrincGovToken',
            'address': '0x62C7e128e7c3205964429F58A0C6f63a776D10d1',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://api.rubic.exchange/assets/arbitrum/0x62c7e128e7c3205964429f58a0c6f63a776d10d1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'AUSDC',
            'name': 'Aave USDC',
            'address': '0x625E7708f30cA75bfd92586e17077590C60eb4cD',
            'decimals': 6,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/14318/thumb/aUSDC.e260d492.png?1615527797',
            'coingeckoId': 'aave-usdc',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'iZi',
            'name': 'Izumi Finance',
            'address': '0x60D01EC2D5E98Ac51C8B4cF84DfCCE98D527c747',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://get.celer.app/cbridge-icons/IZI.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ACRE',
            'name': 'Arable Protocol',
            'address': '0x5f006745a9A192a7cD1236089F704f9B35D3b9Cd',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WSTETH',
            'name': 'Wrapped stETH',
            'address': '0x5979D7b546E38E414F7E9822514be443A4800529',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0x5979d7b546e38e414f7e9822514be443a4800529.png',
            'coingeckoId': 'wrapped-steth',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'HOP-LP-ETH',
            'name': 'Hop ETH LP Token',
            'address': '0x59745774Ed5EfF903e615F5A2282Cae03484985a',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://api.rubic.exchange/assets/arbitrum/0x59745774ed5eff903e615f5a2282cae03484985a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'GMX',
            'name': 'GMX',
            'address': '0x590020B1005b8b25f1a2C82c5f743c540dcfa24d',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'QSD',
            'name': 'QIAN Second Generation Dollar',
            'address': '0x5837d843D49ffdefC78Fe09B5F371427c917946F',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/17985/thumb/QSD_.png?1630029729',
            'coingeckoId': 'qian-second-generation-dollar',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'KROM',
            'name': 'Kromatika',
            'address': '0x55fF62567f09906A85183b866dF84bf599a4bf70',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/20541/thumb/KROM_Transparent.png?1641398421',
            'coingeckoId': 'kromatika',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'SPA',
            'name': 'Sperax',
            'address': '0x5575552988A3A80504bBaeB1311674fCFd40aD4B',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': 'sperax',
            'listedIn': [
                'coingecko',
                'arbitrum_bridge',
                'rubic'
            ]
        },
        {
            'symbol': 'OVR',
            'name': 'OVR',
            'address': '0x55704A0e9E2eb59E176C5b69655DbD3DCDCFc0F0',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/13429/thumb/ovr_logo.png?1608518911',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge',
                'rubic'
            ]
        },
        {
            'symbol': 'SAKE',
            'name': 'SakeToken',
            'address': '0x552E4e96A0Ce6D36d161b63984848c8dAC471ea2',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/12428/thumb/sake.png?1599777402',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'MAGIC',
            'name': 'Magic',
            'address': '0x539bdE0d7Dbd336b79148AA742883198BBF60342',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/18623/small/Magic.png?1635755672',
            'coingeckoId': 'magic',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'jgOHM',
            'name': 'Jones gOHM',
            'address': '0x5375616bB6c52A90439fF96882a986d8FCdCe421',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/list/master/logos/token-logos/network/arbitrum/0x5375616bB6c52A90439fF96882a986d8FCdCe421.jpg',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'OCTO',
            'name': 'Octo.fi',
            'address': '0x52f5d9B3a2bB89D3aEC5829A3415c21115aCD633',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/12594/thumb/octofi-256x256-radius-22percent.png?1610679969',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'MTA',
            'name': 'Meta',
            'address': '0x5298Ee77A8f9E226898403eBAC33e68a62F770A0',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/11846/thumb/mStable.png?1594950533',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge',
                'rubic'
            ]
        },
        {
            'symbol': 'ROUTE',
            'name': 'Route',
            'address': '0x5298060A95205BE6Dd4aBc21910A4bB23D6DCD8b',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/13709/thumb/route_token_200x200-19.png?1611057698',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'DAIx',
            'name': 'Superfluid DAI',
            'address': '0x521677A61D101A80cE0fB903b13cb485232774ee',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://static.debank.com/image/arb_token/logo_url/0xda10009cbd5d07dd0cecc66161fc93d7c9000da1/549c4205dbb199f1b8b03af783f35e71.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PREMIA',
            'name': 'Premia',
            'address': '0x51fC0f6660482Ea73330E414eFd7808811a57Fa2',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0x6399c842dd2be3de30bf99bc7d1bbf6fa3650e70.png',
            'coingeckoId': 'premia',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'PLS',
            'name': 'PlutusDAO',
            'address': '0x51318B7D00db7ACc4026C88c3952B66278B6A67F',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/25326/small/M6nUndNU_400x400.jpg?1651233987',
            'coingeckoId': 'plutusdao',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'kUSDC',
            'name': 'KAKI USDC',
            'address': '0x4f947b40BEEB9D8130437781a560E5c7D089730f',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'MCB',
            'name': 'MUX Protocol',
            'address': '0x4e352cF164E64ADCBad318C3a1e222E9EBa4Ce42',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0x4e352cf164e64adcbad318c3a1e222e9eba4ce42.png',
            'coingeckoId': 'mcdex',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'NISHIB',
            'name': 'NitroShiba',
            'address': '0x4DAD357726b41bb8932764340ee9108cC5AD33a0',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/27288/thumb/nishib_logo.png?1663143249',
            'coingeckoId': 'nitroshiba',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'RMAI',
            'name': 'RMAI',
            'address': '0x4D3e9D28676B061EC8d0DF663de609b4eAC1037F',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'RouterProtocol'
            ]
        },
        {
            'symbol': 'TUSD',
            'name': 'TrueUSD',
            'address': '0x4D15a3A2286D883AF0AA1B3f21367843FAc63E07',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://images.prismic.io/tusd-homepage/fb4d581a-95ed-404c-b9de-7ab1365c1386_%E5%9B%BE%E5%B1%82+1.png',
            'coingeckoId': 'true-usd',
            'listedIn': [
                'coingecko',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'GMD',
            'name': 'GMD Protocol',
            'address': '0x4945970EfeEc98D393b4b979b9bE265A3aE28A8B',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/28088/thumb/IcjYItp2_400x400.jpeg?1667445886',
            'coingeckoId': 'gmd-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FST',
            'name': 'Futureswap',
            'address': '0x488cc08935458403a0458e45E20c0159c8AB2c92',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://etherscan.io/token/images/futureswap2_32.png',
            'coingeckoId': 'futureswap',
            'listedIn': [
                'coingecko',
                'arbitrum_bridge',
                'rubic'
            ]
        },
        {
            'symbol': 'LRC',
            'name': 'Loopring',
            'address': '0x46d0cE7de6247b0A95f67b43B589b4041BaE7fbE',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://cryptologos.cc/logos/loopring-lrc-logo.png',
            'coingeckoId': 'loopring',
            'listedIn': [
                'coingecko',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ETHRISE',
            'name': 'ETHRISE',
            'address': '0x46D06cf8052eA6FdbF71736AF33eD23686eA1452',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/26761/thumb/ETHRISE.png?1660036893',
            'coingeckoId': 'ethrise',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'KTN',
            'name': 'Kostren Finance',
            'address': '0x46CA8eD5465cB859bB3C3364078912c25f4D74De',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/28214/thumb/Kostren_%281%29.png?1668428043',
            'coingeckoId': 'kostren-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELM',
            'name': 'ELLERIUM',
            'address': '0x45D55EADf0ED5495B369E040aF0717eaFaE3b731',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/28153/thumb/elm.png?1668153687',
            'coingeckoId': 'ellerium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PBX',
            'name': 'Paribus',
            'address': '0x4518231a8FDF6ac553B9BBD51Bbb86825B583263',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DOG',
            'name': 'The Doge NFT',
            'address': '0x4425742F1EC8D98779690b5A3A6276Db85Ddc01A',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://etherscan.io/token/images/thedogenft_32.png',
            'coingeckoId': 'the-doge-nft',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'PGS',
            'name': 'Pegasus',
            'address': '0x43e4Ef7e796A631539F523900da824e73EdC3110',
            'decimals': 6,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/28142/thumb/Logo1-01.png?1667791407',
            'coingeckoId': 'pegasus-pow',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HEGIC',
            'name': 'Hegic',
            'address': '0x431402e8b9dE9aa016C743880e04E517074D8cEC',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/12454/thumb/new.png?1628668523',
            'coingeckoId': 'hegic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EVO',
            'name': 'EVO',
            'address': '0x42006Ab57701251B580bDFc24778C43c9ff589A1',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/25088/large/evoToken.png?1650269135',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'RouterProtocol'
            ]
        },
        {
            'symbol': 'OOKI',
            'name': 'Ooki',
            'address': '0x400F3ff129Bc9C9d239a567EaF5158f1850c65a4',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://get.celer.app/cbridge-icons/OOKI.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CELR',
            'name': 'Celer Network',
            'address': '0x3a8B787f78D775AECFEEa15706D4221B40F345AB',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/4379/thumb/Celr.png?1554705437',
            'coingeckoId': 'celer-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'MIMATIC',
            'name': 'MAI',
            'address': '0x3F56e0c36d275367b8C502090EDF38289b3dEa0d',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0xa3fa99a148fa48d14ed51d610c367c61876997f1.png',
            'coingeckoId': 'mimatic',
            'listedIn': [
                'coingecko',
                'RouterProtocol',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'SPELL',
            'name': 'Spell',
            'address': '0x3E6648C5a70A150A88bCE65F4aD4d506Fe15d2AF',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0x3e6648c5a70a150a88bce65f4ad4d506fe15d2af.png',
            'coingeckoId': 'spell-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'SPIRIT',
            'name': 'SpiritSwapToken',
            'address': '0x3D632d9e1a60a0880Dd45E61f279D919b5748377',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'LAND',
            'name': 'Land',
            'address': '0x3CD1833Ce959E087D0eF0Cb45ed06BffE60F23Ba',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0x9d986a3f147212327dd658f712d5264a73a1fdb0.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ROOBEE',
            'name': 'Roobee',
            'address': '0x3BD2dFd03BC7c3011ed7fb8c4d0949B382726cee',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/8791/thumb/Group_11.png?1580344629',
            'coingeckoId': 'roobee',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'DIA',
            'name': 'DIAToken',
            'address': '0x39f91452a6E9994762876e1EDd87Be28a46d5c99',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PHONON',
            'name': 'Phonon DAO',
            'address': '0x39A49bc5017Fc668299Cd32e734C9269aCc35295',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/22308/thumb/ezgif-2-e7fb84364d.png?1641449852',
            'coingeckoId': 'phonon-dao',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'FOR',
            'name': 'The Force Token',
            'address': '0x3816e40c1eB106c8fb7c05f901cfD58C7292D051',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/8242/thumb/for.png?1606195375',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'ZYX',
            'name': 'ZYX',
            'address': '0x377c6E37633e390aEf9AFB4F5E0B16689351EeD4',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/11964/thumb/zyx.png?1596454904',
            'coingeckoId': 'zyx',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'PANA',
            'name': 'PANA DAO',
            'address': '0x369eB8197062093a20402935D3a707b4aE414E9D',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/27031/thumb/pana_logo.png?1661495937',
            'coingeckoId': 'pana-dao',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'PL2',
            'name': 'Plenny',
            'address': '0x3642c0680329ae3e103E2B5AB29DDfed4d43CBE5',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'sushiswap',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'COMP',
            'name': 'Compound',
            'address': '0x354A6dA3fcde098F8389cad84b0182725c6C91dE',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0xc00e94cb662c3520282e6f5717214004a7f26888.png',
            'coingeckoId': 'compound-governance-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'PRICES',
            'name': 'PRICES',
            'address': '0x354511C471C3438fe00B436Cd19B272B697A8cD4',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://api.rubic.exchange/assets/arbitrum/0x354511c471c3438fe00b436cd19b272b697a8cd4/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'xcRMRK',
            'name': 'xcRMRK',
            'address': '0x350Fa8f62E50f8ac20a840C3dC570ebC5bdffC4C',
            'decimals': 10,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'XUSD',
            'name': 'xDollar Stablecoin',
            'address': '0x3509f19581aFEDEff07c53592bc0Ca84e4855475',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1637892796159_3482286577377456.png',
            'coingeckoId': 'xdollar-stablecoin',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'RDPX',
            'name': 'Dopex Rebate',
            'address': '0x32Eb7902D4134bf98A28b963D26de779AF92A212',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/16659/small/rDPX_200x200_Coingecko.png?1624614475',
            'coingeckoId': 'dopex-rebate-token',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'STRP',
            'name': 'Strips Finance',
            'address': '0x326c33FD1113c1F29B35B4407F3d6312a8518431',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://etherscan.io/token/images/strips_32.png',
            'coingeckoId': 'strips-finance',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'xRNBW',
            'name': 'Rainbow Pool',
            'address': '0x323C11843DEaEa9f13126FE33B86f6C5086DE138',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CTSI',
            'name': 'Cartesi',
            'address': '0x319f865b287fCC10b30d8cE6144e8b6D1b476999',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://elk.finance/tokens/logos/arbitrum/0x319f865b287fCC10b30d8cE6144e8b6D1b476999/logo.png',
            'coingeckoId': 'cartesi',
            'listedIn': [
                'coingecko',
                'rubic',
                'elkfinance'
            ]
        },
        {
            'symbol': 'KNC',
            'name': 'Kyber Network Crystal v2',
            'address': '0x316772cFEc9A3E976FDE42C3Ba21F5A13aAaFf12',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'OCEAN',
            'name': 'Ocean Token',
            'address': '0x306377cFA2ac72e757151591e9eCF0135d7C9613',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'vUNIT',
            'name': 'Virtual Unit',
            'address': '0x2f4862f141eA85ebED8e28285A932A98A510c337',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WBTC',
            'name': 'Wrapped Bitcoin',
            'address': '0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f',
            'decimals': 8,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0x2260fac5e5542a773aa44fbcfedf7c193bc2c599.png',
            'coingeckoId': 'wrapped-bitcoin',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'VOX',
            'name': 'Vox.Finance',
            'address': '0x2eD14d1788dfB780fD216706096AeD018514ECcd',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/12880/thumb/BSensIa.png?1603261093',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'MKR',
            'name': 'Maker',
            'address': '0x2e9a6Df78E42a30712c10a9Dc4b1C8656f8F2879',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2.png',
            'coingeckoId': 'maker',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'MAGIC',
            'name': 'MagicLand',
            'address': '0x2c852D3334188BE136bFC540EF2bB8C37b590BAD',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/18844/thumb/logo_-_2021-10-07T141625.399.png?1633587397',
            'coingeckoId': 'magic-token',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'FRACTION',
            'name': 'Own a fraction',
            'address': '0x2bF2ba13735160624a0fEaE98f6aC8F70885eA61',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/15099/large/fraction.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'UBT',
            'name': 'UniBright',
            'address': '0x2aD62674A64E698C24831Faf824973C360430140',
            'decimals': 8,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/2707/thumb/UnibrightLogo_colorful_500x500_preview.png?1547036916',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'L2DAO',
            'name': 'Layer2DAO',
            'address': '0x2CaB3abfC1670D1a452dF502e216a66883cDf079',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/23699/thumb/Khp7Y4Sn.png?1645081048',
            'coingeckoId': 'layer2dao',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'STBZ',
            'name': 'Stabilize',
            'address': '0x2C110867CA90e43D372C1C2E92990B00EA32818b',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/12753/thumb/icon.png?1608771101',
            'coingeckoId': 'stabilize',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'AFARM',
            'name': 'AFARM Token',
            'address': '0x2AE1EF41Cd23e3E77f75789E9393D8951705470F',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://api.rubic.exchange/assets/arbitrum/0x2ae1ef41cd23e3e77f75789e9393d8951705470f/logo.png',
            'coingeckoId': 'arbifarm',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'LPT',
            'name': 'Livepeer',
            'address': '0x289ba1701C2F088cf0faf8B3705246331cB8A839',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/7137/thumb/logo-circle-green.png?1619593365',
            'coingeckoId': 'livepeer',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNT',
            'name': 'Unity Network',
            'address': '0x250F471385894fc81183a99d6fDe8CE9C5B142d6',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://etherscan.io/token/images/unitynetwork_32.png',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'GRT',
            'name': 'The Graph',
            'address': '0x23A941036Ae778Ac51Ab04CEa08Ed6e2FE103614',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0xc944e90c64b2c07662a292be6244bdf05cda44a7.png',
            'coingeckoId': 'the-graph',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'FLUX',
            'name': 'Flux Protocol',
            'address': '0x2338a5d62E9A766289934e8d2e83a443e8065b83',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://cryptologos.cc/logos/zel-flux-logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'DERI',
            'name': 'Deri Protocol',
            'address': '0x21E60EE73F17AC0A411ae5D690f908c3ED66Fe12',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/13931/thumb/200vs200.jpg?1627649443',
            'coingeckoId': 'deri-protocol',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'alUSD',
            'name': 'Alchemix USD',
            'address': '0x2130d2a1e51112D349cCF78D2a1EE65843ba36e0',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SUM',
            'name': 'SUM',
            'address': '0x20f9628a485ebCc566622314f6e07E7Ee61fF332',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://etherscan.io/token/images/sumswap_32.png',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'jrDPX',
            'name': 'Jones rDPX',
            'address': '0x1f6Fa7A58701b3773b08a1a16D06b656B0eCcb23',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/list/master/logos/token-logos/network/arbitrum/0x1f6Fa7A58701b3773b08a1a16D06b656B0eCcb23.jpg',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'deUSDC',
            'name': 'deBridge USD Coin',
            'address': '0x1dDcaa4Ed761428ae348BEfC6718BCb12e63bFaa',
            'decimals': 6,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0x1ddcaa4ed761428ae348befc6718bcb12e63bfaa_2.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'TUSD',
            'name': 'TrueUSD',
            'address': '0x1d7Ca62F6Af49ec66f6680b8606E634E55Ef22C1',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'aMoon',
            'name': 'ArbiMoon',
            'address': '0x1a7BD9EDC36Fb2b3c0852bcD7438c2A957Fd7Ad5',
            'decimals': 9,
            'chainId': 42161,
            'logoURI': 'https://raw.githubusercontent.com/ArbiMoonXyz/MyFiles/main/arbimoon.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'NIFLOKI',
            'name': 'NitroFloki',
            'address': '0x1FAe2A29940015632f2a6CE006dFA7E3225515A7',
            'decimals': 9,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/28038/thumb/nifloki.png?1667198797',
            'coingeckoId': 'nitrofloki',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'USDCx',
            'name': 'Super USD Coin (Arb1)',
            'address': '0x1Dbc1809486460DcD189b8a15990bCa3272Ee04e',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://static.debank.com/image/arb_token/logo_url/0xff970a61a04b1ca14834a43f5de4533ebddb5cc8/fffcd27b9efff5a86ab942084c05924d.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DFYN',
            'name': 'DFYN Token',
            'address': '0x1D54Aa7E322e02A0453c0F2fA21505cE7F2E9E93',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://etherscan.io/token/images/dfynnetwork_32.png',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'BFR',
            'name': 'Buffer Token',
            'address': '0x1A5B0aaF478bf1FDA7b934c76E7692D722982a6D',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1668519619894_7648242062364901.jpg',
            'coingeckoId': 'ibuffer-token',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic'
            ]
        },
        {
            'symbol': 'ALINK',
            'name': 'Aave LINK',
            'address': '0x191c10Aa4AF7C30e871E70C95dB0E4eb77237530',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/14315/thumb/aLINK.412c6589.png?1615527827',
            'coingeckoId': 'aave-link',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'FRAX',
            'name': 'Frax',
            'address': '0x17FC002b466eEc40DaE837Fc4bE5c67993ddBd6F',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0x17fc002b466eec40dae837fc4be5c67993ddbd6f.png',
            'coingeckoId': 'frax',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'UMAMI',
            'name': 'Umami',
            'address': '0x1622bF67e6e5747b81866fE0b85178a93C7F86e3',
            'decimals': 9,
            'chainId': 42161,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/arbitrum/assets/0x1622bF67e6e5747b81866fE0b85178a93C7F86e3/logo.png',
            'coingeckoId': 'umami-finance',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ADOGE',
            'name': 'ArbiDoge',
            'address': '0x155f0DD04424939368972f4e1838687d6a831151',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/doge.jpg',
            'coingeckoId': 'arbidoge',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'LDO',
            'name': 'Lido DAO',
            'address': '0x13Ad51ed4F1B7e9Dc168d8a00cB3f4dDD85EfA60',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/13573/thumb/Lido_DAO.png?1609873644',
            'coingeckoId': 'lido-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EMAX',
            'name': 'EthereumMax',
            'address': '0x123389C2f0e9194d9bA98c21E63c375B67614108',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0x123389c2f0e9194d9ba98c21e63c375b67614108.png',
            'coingeckoId': 'ethereummax',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'CRV',
            'name': 'Curve DAO',
            'address': '0x11cDb42B0EB46D95f990BeDD4695A6e3fA034978',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0xd533a949740bb3306d119cc777fa900ba034cd52.png',
            'coingeckoId': 'curve-dao-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PBTC',
            'name': 'pTokens BTC',
            'address': '0x115D8bF0a53e751f8A472F88D587944EC1C8CA6D',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/25861/thumb/wMTpRljt_400x400.png?1654228097',
            'coingeckoId': 'ptokens-btc-2',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'JONES',
            'name': 'Jones DAO',
            'address': '0x10393c20975cF177a3513071bC110f7962CD67da',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/23290/small/3c8c2ed8-afb3-4b67-9937-5493acd88b50.jpg?1648597625',
            'coingeckoId': 'jones-dao',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'HND',
            'name': 'Hundred Finance',
            'address': '0x10010078a54396F62c96dF8532dc2B4847d47ED3',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1637892347363_6124669428880605.png',
            'coingeckoId': 'hundred-finance',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'CUDL',
            'name': 'CUDL',
            'address': '0x0f4676178b5c53Ae0a655f1B19A96387E4b8B5f2',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://api.rubic.exchange/assets/arbitrum/0x0f4676178b5c53ae0a655f1b19a96387e4b8b5f2/logo.png',
            'coingeckoId': 'cudl-finance',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ALCH',
            'name': 'Alchemy',
            'address': '0x0e15258734300290a651FdBAe8dEb039a8E7a2FA',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/14719/thumb/sbEW5W8.png?1617939648',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'xPREMIA',
            'name': 'Staked Premia',
            'address': '0x0d7d0eFdCbfe5466b387e127709F24603920f671',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ZIPT',
            'name': 'Zippie',
            'address': '0x0F61B24272AF65EACF6adFe507028957698e032F',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0xedd7c94fd7b4971b916d15067bc454b9e1bad980.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'BOND',
            'name': 'BarnBridge Governance Token',
            'address': '0x0D81E50bC677fa67341c44D7eaA9228DEE64A4e1',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://cryptologos.cc/logos/barnbridge-bond-logo.png',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge',
                'rubic'
            ]
        },
        {
            'symbol': 'RDNT',
            'name': 'Radiant Capital',
            'address': '0x0C4681e6C0235179ec3D4F4fc4DF3d14FDD96017',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0x0c4681e6c0235179ec3d4f4fc4df3d14fdd96017.png',
            'coingeckoId': 'radiant-capital',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'IUSD',
            'name': 'iZUMi Bond USD',
            'address': '0x0A3BB08b3a15A19b4De82F8AcFc862606FB69A2D',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/25388/thumb/iusd-logo-symbol-10k%E5%A4%A7%E5%B0%8F.png?1651660620',
            'coingeckoId': 'izumi-bond-usd',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ARBY',
            'name': 'Adamant Token',
            'address': '0x09ad12552ec45f82bE90B38dFE7b06332A680864',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/arbitrum/assets/0x09ad12552ec45f82bE90B38dFE7b06332A680864/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SYN',
            'name': 'Synapse',
            'address': '0x080F6AEd32Fc474DD5717105Dba5ea57268F46eb',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/18024/thumb/synapse_social_icon.png?1663921797',
            'coingeckoId': 'synapse-2',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'GOVI',
            'name': 'GOVI',
            'address': '0x07E49d5dE43DDA6162Fa28D24d5935C151875283',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/13875/thumb/GOVI.png?1612451531',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'QSD',
            'name': ' QIAN second generation dollar',
            'address': '0x07AaA29E63FFEB2EBf59B33eE61437E1a91A3bb2',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1637832211908_8528681646600584.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'AWBTC',
            'name': 'Aave WBTC',
            'address': '0x078f358208685046a11C85e8ad32895DED33A249',
            'decimals': 8,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/14244/thumb/aWBTC.41453c2a.png?1615528721',
            'coingeckoId': 'aave-wbtc',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'ARRB',
            'name': 'Arrb Token',
            'address': '0x04f3636B31c1EA2E48e275aA992d99a7bdbBe738',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://api.rubic.exchange/assets/arbitrum/0x04f3636b31c1ea2e48e275aa992d99a7bdbbe738/logo.png',
            'coingeckoId': 'arrb-token',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'KUN',
            'name': 'Chemix Ecology Governance',
            'address': '0x04cb2d263a7489f02d813eaaB9Ba1bb8466347F2',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/13177/thumb/kun_logo.png?1605923919',
            'coingeckoId': 'chemix-ecology-governance-token',
            'listedIn': [
                'coingecko',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'BAL',
            'name': 'Balancer',
            'address': '0x040d1EdC9569d4Bab2D15287Dc5A4F10F56a56B8',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0xba100000625a3754423978a60c9317c58a424e3d.png',
            'coingeckoId': 'balancer',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'AGVE',
            'name': 'Agave',
            'address': '0x03b95f1C84Af0607afd5dD87ca1FDE7572aa827F',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://elk.finance/tokens/logos/arbitrum/0x03b95f1C84Af0607afd5dD87ca1FDE7572aa827F/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CAP',
            'name': 'Cap',
            'address': '0x031d35296154279DC1984dCD93E392b1f946737b',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://assets.coingecko.com/coins/images/11775/thumb/CAP.png?1594083244',
            'coingeckoId': 'cap',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'gALCX',
            'name': 'governanceALCX',
            'address': '0x026e91e4C3d35EB31a90FcdBF50313d0290Af3cb',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ETH',
            'name': 'Ethereum',
            'address': '0x0000000000000000000000000000000000000000',
            'decimals': 18,
            'chainId': 42161,
            'logoURI': 'https://tokens.1inch.io/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee.png',
            'coingeckoId': 'ethereum',
            'listedIn': [
                '1inch',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        }
    ],
    '56': [
        {
            'symbol': 'BFPS',
            'name': 'Baby FPS',
            'address': '0xfffe03b9306dF57aafe9d291aC906fBfB0b07Be3',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27569/thumb/photo_2022-09-29_22.01.39.jpeg?1664526238',
            'coingeckoId': 'baby-fps-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BORING',
            'name': 'BoringDAO',
            'address': '0xffEecbf8D7267757c2dc3d13D730E97E15BfdF7F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xffeecbf8d7267757c2dc3d13d730e97e15bfdf7f.png',
            'coingeckoId': 'boringdao',
            'listedIn': [
                'coingecko',
                '1inch',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'XVC',
            'name': 'Xave Coin',
            'address': '0xffE2A166A3Ea6dd7BB11B2C48f08F1E4202D4E78',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24084/thumb/k8qjP9t9_400x400.jpg?1646292464',
            'coingeckoId': 'xave-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DHN',
            'name': 'Dohrnii',
            'address': '0xff8BBc599EA030AA69d0298035dFE263740a95bC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24966/thumb/dhn.png?1649586405',
            'coingeckoId': 'dohrnii',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLETA',
            'name': 'FLETA',
            'address': '0xff8152F09E0FDDD1Ce1577Ef6EbA72f3A7C2e7dB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/7865/thumb/Fleta_new_logo_%282%29.png?1623520180',
            'coingeckoId': 'fleta',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALGOBLK',
            'name': 'AlgoBlocks',
            'address': '0xfecCa80fF6DeB2B492E93df3B67f0C523Cfd3a48',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25120/thumb/tncthV9K_400x400.jpg?1650370062',
            'coingeckoId': 'algoblocks',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SSW',
            'name': 'Seesaw',
            'address': '0xfe974Dfdc2dF0ed326D0B303D18560c3824b2F94',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25010/thumb/N2xq-Se-Ob-400x400.png?1649816633',
            'coingeckoId': 'seesaw',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KNC',
            'name': 'Kyber Network Crystal',
            'address': '0xfe56d5892BDffC7BF58f2E84BE1b2C32D21C308b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xdefa4e8a7bcba345f687a2f1456f5edd9ce97202.png',
            'coingeckoId': 'kyber-network-crystal',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'NODY',
            'name': 'Nody',
            'address': '0xfe01c159ECdeE4377aBfc4cD1827089C47B806EA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26161/thumb/200x.png?1656323259',
            'coingeckoId': 'nody',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOLARIX',
            'name': 'SOLARIX',
            'address': '0xfdcE54744801c9Eb88D8445673Ad267F8d43a9eE',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26757/thumb/237712644422d81b00a920ad9f9255e.png?1660032647',
            'coingeckoId': 'solarix',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LAT',
            'name': 'Liquidify',
            'address': '0xfdCE4b11d6ddc902244726B93E71e2874c7B6AF0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20628/thumb/liquidify.PNG?1637323038',
            'coingeckoId': 'liquidify',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FREL',
            'name': 'Freela',
            'address': '0xfd5af95C12446b60d23e16A4EA95690cE942e5dC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0xfd5af95c12446b60d23e16a4ea95690ce942e5dc.png',
            'coingeckoId': 'freela',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SUB',
            'name': 'Subme',
            'address': '0xfd290c590866f8282d89671A85Ac9964b165d682',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17815/thumb/sub-token-200x200.png?1629339070',
            'coingeckoId': 'subme',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IF',
            'name': 'InsuranceFI',
            'address': '0xfd141194D9d0fD62B5fC7E17DDD7d21aEB0CC7FC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27118/thumb/IMG_20220830_191414_156.jpg?1662015390',
            'coingeckoId': 'insurancefi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BRT',
            'name': 'Bikerush',
            'address': '0xfc9F81B107F51F2383fCE56650fEDB59C5fd59bD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25677/thumb/X0ZVax0H_400x400.jpg?1653366491',
            'coingeckoId': 'bikerush',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANJI',
            'name': 'Anji',
            'address': '0xfc619FfCc0e0F30427BF938f9A1B2BfAE15bDf84',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20000/thumb/anji.PNG?1636364408',
            'coingeckoId': 'anji',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GMETA',
            'name': 'Green Meta',
            'address': '0xfc352FcEc6135EDc384FE50bBF2bC2D1cDD7FC4E',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25000/thumb/200x200%281%29.png?1649739655',
            'coingeckoId': 'green-meta',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POO',
            'name': 'POOMOON',
            'address': '0xfc20A257786f2D8a038cAcA312BE0F10E206D15f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20004/thumb/poo.png?1636365199',
            'coingeckoId': 'poomoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WEMP',
            'name': 'Women Empowerment',
            'address': '0xfbe777A4376d0A7E1892162a7daAd190D7f091bA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18932/thumb/WEMP_2_200x200_copy.png?1646646768',
            'coingeckoId': 'women-empowerment-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NT',
            'name': 'NEXTYPE Finance',
            'address': '0xfbcf80ed90856AF0d6d9655F746331763EfDb22c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17415/thumb/nextype.PNG?1627597099',
            'coingeckoId': 'nextype-finance',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'SHILL',
            'name': 'Project SEED SHILL',
            'address': '0xfb9C339b4BacE4Fe63ccc1dd9a3c3C531441D5fE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18176/thumb/SHILL_Logo.png?1630896205',
            'coingeckoId': 'shill-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOWS',
            'name': 'Shadows',
            'address': '0xfb7400707dF3D76084FBEae0109f41b178f71c02',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0xfb7400707df3d76084fbeae0109f41b178f71c02.png',
            'coingeckoId': 'shadows',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'DPET',
            'name': 'My DeFi Pet',
            'address': '0xfb62AE373acA027177D1c18Ee0862817f9080d08',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0xfb62ae373aca027177d1c18ee0862817f9080d08.png',
            'coingeckoId': 'my-defi-pet',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'AAVE',
            'name': 'Aave',
            'address': '0xfb6115445Bff7b52FeB98650C87f44907E58f802',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xfb6115445Bff7b52FeB98650C87f44907E58f802/logo.png',
            'coingeckoId': 'aave',
            'listedIn': [
                'coingecko',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FLOKI',
            'name': 'FLOKI',
            'address': '0xfb5B838b6cfEEdC2873aB27866079AC55363D37E',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xfb5b838b6cfeedc2873ab27866079ac55363d37e.png',
            'coingeckoId': 'floki',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'OLE',
            'name': 'OleCoin',
            'address': '0xfb4b7ee058828b5fBF2e475dA88F1903f453334E',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17791/thumb/icon-olecoin-200x200-1.png?1629261482',
            'coingeckoId': 'olecoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PPAY',
            'name': 'Plasma Finance',
            'address': '0xfb288d60D3b66F9c3e231a9a39Ed3f158a4269aA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13340/thumb/Hi9sEGAD.png?1607586849',
            'coingeckoId': 'plasma-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOR',
            'name': 'DogyRace',
            'address': '0xfafBc48f6Aa3587984eA50E472304802B39c2604',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21514/thumb/DogyRaceLogo.png?1639376008',
            'coingeckoId': 'dogyrace',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NEXM',
            'name': 'Nexum',
            'address': '0xfa37E513E6cd506c4694b992825a8B614C035581',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23472/thumb/200_-_200_coinmarketcap.png?1644218225',
            'coingeckoId': 'nexum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CMCC',
            'name': 'CMC Coin',
            'address': '0xfa134985a4d9D10DBf2d7dCf811055aA25d0807C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21494/thumb/cmc.png?1639365775',
            'coingeckoId': 'cmc-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TGB',
            'name': 'Traders Global Business',
            'address': '0xfFEf225b4A1b5De683D53dd745664C4EF8840f61',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21218/thumb/tgblogo-500x500.png?1638671013',
            'coingeckoId': 'traders-global-business',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CLOUD',
            'name': 'CloudTx',
            'address': '0xfFAD7f9F704a5FDC6265e24b436b4b35ed52DEF2',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27634/thumb/Untitled_Artwork_%281%29.png?1664961377',
            'coingeckoId': 'cloudtx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POM',
            'name': 'PomPom',
            'address': '0xfF8372d6065AE97791E17E58e56a27c6ac16BA87',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20697/thumb/photo-2021-09-18-00-52-52.jpg?1637565135',
            'coingeckoId': 'pompom',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DPKTOKEN',
            'name': 'DPK',
            'address': '0xfF73aDCc8cC0556467d8FE78FB4fd0C3Da51f151',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22266/thumb/LNjUbO_A_400x400.jpg?1641337983',
            'coingeckoId': 'dpk',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SMB9',
            'name': 'Shine Mystic Birds',
            'address': '0xfF0AE49ea8560e303C6bACe3De4f6720d9DABe7c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26456/thumb/IMG_20220718_122804_812.jpg?1658128146',
            'coingeckoId': 'shine-mystic-birds',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VINU',
            'name': 'Vita Inu',
            'address': '0xfEbe8C1eD424DbF688551D4E2267e7A53698F0aa',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xfebe8c1ed424dbf688551d4e2267e7a53698f0aa.png',
            'coingeckoId': 'vita-inu',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'KICKS',
            'name': 'GetKicks',
            'address': '0xfEB4e9B932eF708c498Cc997ABe51D0EE39300cf',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27873/thumb/GetKicks.jpg?1666169072',
            'coingeckoId': 'getkicks',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NAGI',
            'name': 'Izanagi',
            'address': '0xfEB2D480019bc605a2Ce20903a90Db3f554F1E1c',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24129/thumb/photo_2022-03-04_23-02-56.jpg?1646481376',
            'coingeckoId': 'izanagi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WDO',
            'name': 'WatchDO',
            'address': '0xfE8E0e9a7a5Ae744AAebeac38E3B9b1dA7B17af3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22279/thumb/4409.jpg?1641364025',
            'coingeckoId': 'watchdo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHARPEI',
            'name': 'Shar Pei',
            'address': '0xfE3AF7376e412a377358d5894C790bB3e00D0dc1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19920/thumb/sp.png?1636201704',
            'coingeckoId': 'shar-pei',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLP',
            'name': 'BullPerks',
            'address': '0xfE1d7f7a8f0bdA6E415593a2e4F82c64b446d404',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16471/thumb/horizontal-standard-black.png?1624268460',
            'coingeckoId': 'bullperks',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIM',
            'name': 'Magic Internet Money',
            'address': '0xfE19F0B51438fd612f6FD59C1dbB3eA319f433Ba',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xfe19f0b51438fd612f6fd59c1dbb3ea319f433ba.png',
            'coingeckoId': 'magic-internet-money',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'GORGEOUS',
            'name': 'Gorgeous',
            'address': '0xfE1179267621899D85E6EDdC0dC9Bf9345fb5E55',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19957/thumb/gorgeous.png?1636340963',
            'coingeckoId': 'gorgeous',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POKERFI',
            'name': 'PokerFi',
            'address': '0xfE073C3B891325AE8686d9Cf2C8b3586674f7BE2',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18907/thumb/oy6nhrI0_400x400.jpg?1633910069',
            'coingeckoId': 'pokerfi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NYT',
            'name': 'New Year',
            'address': '0xfDfF7a8eDA6a3739132867f989bE4bf84E803c15',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21839/thumb/nyt_200.png?1640101725',
            'coingeckoId': 'new-year-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GHD',
            'name': 'Giftedhands',
            'address': '0xfDFD27aE39cebefDBaAc8615F18aa68DDD0F15f5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12540/thumb/K-8uHktJ.png?1600644856',
            'coingeckoId': 'giftedhands',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VRGW',
            'name': 'Virtual Reality Game World',
            'address': '0xfDD2374be7ae7a71138B9f6b93d9eF034a49edB6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xfdd2374be7ae7a71138b9f6b93d9ef034a49edb6.png',
            'coingeckoId': 'virtual-reality-game-world',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'TAP',
            'name': 'TAP',
            'address': '0xfD799ddCCA8ab5c1ad8a3D64a58d4e907C9d0B71',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0xfd799ddcca8ab5c1ad8a3d64a58d4e907c9d0b71.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'VUSDT',
            'name': 'Venus USDT',
            'address': '0xfD5840Cd36d94D7229439859C0112a4185BC0255',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xfd5840cd36d94d7229439859c0112a4185bc0255.png',
            'coingeckoId': 'venus-usdt',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'VERSA',
            'name': 'Versatile Finance',
            'address': '0xfD3dBB4709Af9FeEB87EB842Cf6b6b5F37B30fAc',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27388/thumb/VERSA-Logo-only-Transparent-Background-e1663499020411.png?1663813585',
            'coingeckoId': 'versatile-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SKYLANDS',
            'name': 'SkyLands',
            'address': '0xfD2150C67Fe2c754Ba63920aDdE3B1CA5CC536E8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23763/thumb/_U0f9llq_400x400.jpg?1645420484',
            'coingeckoId': 'skylands',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QUA',
            'name': 'Quarashi',
            'address': '0xfD0fD32A20532ad690731c2685d77c351015ebBa',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xfd0fd32a20532ad690731c2685d77c351015ebba.png',
            'coingeckoId': 'quarashi',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MTW',
            'name': 'Meta World Game',
            'address': '0xfCe3EFebC79d21a6B7cc7F3A7216E66e81f4B23B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22361/thumb/IMG-20220107-151227-500.jpg?1641565323',
            'coingeckoId': 'meta-world-game',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RENBTC',
            'name': 'renBTC',
            'address': '0xfCe146bF3146100cfe5dB4129cf6C82b0eF4Ad8c',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xfce146bf3146100cfe5db4129cf6c82b0ef4ad8c.png',
            'coingeckoId': 'renbtc',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'LIZ',
            'name': 'LizardToken Finance',
            'address': '0xfCb520B47f5601031E0Eb316F553A3641FF4B13C',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23570/thumb/download_%2847%29.png?1644475361',
            'coingeckoId': 'lizardtoken-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZADA',
            'name': 'Zada',
            'address': '0xfCaDD830fF2D6Cf3AD1681E1e8FC5DDCe9d59E74',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20602/thumb/Screenshot-2021-11-18-at-22-55-25.png?1637287967',
            'coingeckoId': 'zada',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CPC',
            'name': 'CryptoPlants Club',
            'address': '0xfC4542634EBf9b7FF344e45e9640deCfC1b676f4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21894/thumb/logoCPC.png?1640229216',
            'coingeckoId': 'cryptoplants',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLOKIV',
            'name': 'Flokiverse Token',
            'address': '0xfC4427aF0A329312fBa0851F2eF0C7Db08Aa100f',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0xfc4427af0a329312fba0851f2ef0c7db08aa100f/logo.png',
            'coingeckoId': 'flokiverse',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'REVO',
            'name': 'Revoland',
            'address': '0xfC279e6ff1FB1C7F5848d31561cAb27d34a2856b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25892/thumb/revo.jpg?1654428983',
            'coingeckoId': 'revoland',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LPY',
            'name': 'LeisurePay',
            'address': '0xfBeF7220dfEFD788A18ee634721a1C82A229f8c6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24146/thumb/lpy.png?1646559850',
            'coingeckoId': 'leisurepay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOM',
            'name': 'Somnium',
            'address': '0xfB82876a27C2D38A3E7bC4591ACe7F1a45E32Ffe',
            'decimals': 10,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21523/thumb/logo200_%2829%29.png?1639379040',
            'coingeckoId': 'somnium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CROCKET',
            'name': 'CryptoRockets',
            'address': '0xfB7D68Be68f7d01C98472fDFB62ca2e10c6f6eE1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22538/thumb/crocket.PNG?1642024933',
            'coingeckoId': 'cryptorockets',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YLB',
            'name': 'Yearnlab',
            'address': '0xfB585322fBd121cE20b857e2CcD85a43Ad496573',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21363/thumb/yearnlab.png?1639014240',
            'coingeckoId': 'yearnlab',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NOKU',
            'name': 'Noku',
            'address': '0xfB4D42BEd5618fb1a377DDB64EB56B92a6d117f2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/1577/thumb/Noku_Logo_round_coingeko.png?1643171688',
            'coingeckoId': 'noku',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MDC',
            'name': 'Mars DogeCoin',
            'address': '0xfB47E699fAcdF4493614b90234691b62a55a484C',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19152/thumb/Mars-Dogecoin-Logo.png?1634535939',
            'coingeckoId': 'mars-dogecoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PANX',
            'name': 'Pandora X',
            'address': '0xfB28110038D251F14BE3BdC5CEB00116f8969629',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0xfb28110038d251f14be3bdc5ceb00116f8969629/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SPAL',
            'name': 'Smart Palmare',
            'address': '0xfB187073c490C3cb7E66f679dbDc13e64B401273',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26296/thumb/Group_1049_%281%29.png?1657174729',
            'coingeckoId': 'smart-palmare',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WINE',
            'name': 'Wine Protocol',
            'address': '0xfAa3B1CaD63Acfd446b8bD0Fe6A157e4dC5b8A1a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26885/thumb/Cvbd-cPK_400x400.jpeg?1660698662',
            'coingeckoId': 'wine-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XEEB',
            'name': 'Xeebster',
            'address': '0xfAE5a913fAc73Ef8ED647e53dc42d2fEBc9eA6c9',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21168/thumb/XEEB_Logo_.png?1638437284',
            'coingeckoId': 'xeebster',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZOOPAD',
            'name': 'ZOOPAD',
            'address': '0xfAA0e6a0730F14BC8D99A480587D0df8D66d9109',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26907/thumb/photo_2022-08-13_00-47-24.png?1660731030',
            'coingeckoId': 'zoopad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MSD',
            'name': 'MoneydefiSwap',
            'address': '0xfA5D78d4517d2C5CCbAd2e56fA8Fc321d6544F2b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18618/thumb/20210729_105705.png?1632700656',
            'coingeckoId': 'moneydefiswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOL',
            'name': 'SOL  Wormhole ',
            'address': '0xfA54fF1a158B5189Ebba6ae130CEd6bbd3aEA76e',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22876/thumb/SOL_wh_small.png?1644224316',
            'coingeckoId': 'sol-wormhole',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FSN',
            'name': 'FUSION',
            'address': '0xfA4fA764F15D0f6E20AAEC8E0d696870E5B77c6e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/2515/thumb/Fusion_200x200.png?1639629907',
            'coingeckoId': 'fsn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SCROOGE',
            'name': 'Scrooge',
            'address': '0xfA1BA18067aC6884fB26e329e60273488a247FC3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21510/thumb/top-hat-200x200.png?1668344782',
            'coingeckoId': 'scrooge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GNP',
            'name': 'Genie Protocol',
            'address': '0xfA139CC2F5C5b8C72309BE8E63C3024D03B7E63C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18753/thumb/colori.png?1633322631',
            'coingeckoId': 'genie-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'META',
            'name': 'Metalk',
            'address': '0xf9c762822781D2A55501DfafbA00417B74c8587a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24801/thumb/meta.png?1648983422',
            'coingeckoId': 'metalk',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIELD',
            'name': 'Meta Shield',
            'address': '0xf9b25c96c659648a805Bf40b4D1dc4F22abCB936',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21991/thumb/Logo_cm.png?1640582517',
            'coingeckoId': 'meta-shield',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHR',
            'name': 'Chromia',
            'address': '0xf9CeC8d50f6c8ad3Fb6dcCEC577e05aA32B224FE',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xf9CeC8d50f6c8ad3Fb6dcCEC577e05aA32B224FE/logo.png',
            'coingeckoId': 'chromaway',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DoKEN',
            'name': 'DoKEN',
            'address': '0xf9A2d40589271Be17612A3F57A9028A568f56e3d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0xf9a2d40589271be17612a3f57a9028a568f56e3d/595823532a3bb31b13c86ef901758729.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ARES',
            'name': 'Ares Protocol',
            'address': '0xf9752A6E8A5E5f5e6EB3aB4e7d8492460fb319f0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15153/thumb/Ares-logo.png?1620638611',
            'coingeckoId': 'ares-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KLT',
            'name': 'KLend',
            'address': '0xf95995d642c652e9a7D124d546A7bDF297c6d0fB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14942/thumb/spaces_-MYEepcYKLFRJ0c-U3Pb_avatar-1618398349022.png?1619084190',
            'coingeckoId': 'klend',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VFIL',
            'name': 'Venus FIL',
            'address': '0xf91d58b5aE142DAcC749f58A49FCBac340Cb0343',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xf91d58b5ae142dacc749f58a49fcbac340cb0343.png',
            'coingeckoId': 'venus-fil',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MELD',
            'name': 'Meland ai',
            'address': '0xf8aE4Ef55628aacFb9Bd6E777b0F5D35C173F624',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21297/thumb/tDs6XoHL_400x400.png?1638878369',
            'coingeckoId': 'meland-ai',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BGOV',
            'name': 'BGOV Token',
            'address': '0xf8E026dC4C0860771f691EcFFBbdfe2fa51c77CF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xf8e026dc4c0860771f691ecffbbdfe2fa51c77cf.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ALLEY',
            'name': 'NFT Alley',
            'address': '0xf8D954168FbbF579F8FAd5F7583d4f76f10AE97D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15150/thumb/Copy_of_NFT_Alley_Final.png?1619944835',
            'coingeckoId': 'nft-alley',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'URUB',
            'name': 'Urubit',
            'address': '0xf8759DE7F2C8d3F32Fd8f8e4c0C02D436a05DdEb',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19902/thumb/54UYxwFE_400x400.jpg?1636099182',
            'coingeckoId': 'urubit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BRY',
            'name': 'Berry Data',
            'address': '0xf859Bf77cBe8699013d6Dbc7C2b926Aaf307F830',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xf859bf77cbe8699013d6dbc7c2b926aaf307f830.png',
            'coingeckoId': 'berry-data',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'KIRO',
            'name': 'KIRO',
            'address': '0xf83c0f6d3A5665BD7Cfdd5831A856d85942BC060',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12688/thumb/logo_kirobo-04.png?1668683315',
            'coingeckoId': 'kirobo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DD',
            'name': 'DDstable',
            'address': '0xf83a0cDfD0cBFC59EA23C26294251bF2152c7254',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AGSPAD',
            'name': 'Aegis Launchpad',
            'address': '0xf834673D496AEAcb5E99Dfbfc34f1bDCdf81D7Bd',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19013/thumb/mnbTEPws_400x400.jpg?1634183119',
            'coingeckoId': 'aegis-launchpad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CBA',
            'name': 'Cabana',
            'address': '0xf82f08200070DCF218769B43ab9BdDDC5c29b7f7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19939/thumb/s5y77FP1_400x400.jpg?1636327040',
            'coingeckoId': 'cabana-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APEFUND',
            'name': 'ApeFund',
            'address': '0xf81F3fF200ABd5451E78105EAA7EE9F8E8Dd20f8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20722/thumb/BavuVre2_400x400.jpg?1637589075',
            'coingeckoId': 'apefund',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NDB',
            'name': 'NDB',
            'address': '0xf8028b65005B0B45f76988d2A77910186E7af4eF',
            'decimals': 12,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26545/thumb/ba98gdMa_400x400.jpeg?1658752427',
            'coingeckoId': 'ndb',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STN',
            'name': 'STN',
            'address': '0xf7fB08C187E6CD1f2149E6C818D0b6d4d4Ef1430',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0xf7fb08c187e6cd1f2149e6c818d0b6d4d4ef1430.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'THB',
            'name': 'Thunder Brawl',
            'address': '0xf7d9f74f02F258961F229f10666A1DbA85d0529F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26938/thumb/thb.png?1660910389',
            'coingeckoId': 'thunder-brawl',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRC',
            'name': 'Crypto Classic',
            'address': '0xf7BCac494fd9530c183dae30Db48d45144FF2C77',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19813/thumb/5cd5E8xZ_400x400.jpg?1635922329',
            'coingeckoId': 'crypto-classic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HARD',
            'name': 'HARD',
            'address': '0xf79037F6f6bE66832DE4E7516be52826BC3cBcc4',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xf79037F6f6bE66832DE4E7516be52826BC3cBcc4/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BULLISH',
            'name': 'BullishApes',
            'address': '0xf78B0C636d2B35820740dC91F7790f0694503E7D',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19181/thumb/bullihs.png?1634613522',
            'coingeckoId': 'bullishapes',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTOP',
            'name': 'BotopiaFinance',
            'address': '0xf78A2e1824638d09571172368bbe1d8D399893Ab',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22129/thumb/GqSruKVo_400x400.jpg?1640872303',
            'coingeckoId': 'botopiafinance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '4PLAY',
            'name': '4PLAY',
            'address': '0xf787E1B31e12c4BE0cBFA46844c5ec851eBA098d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23664/thumb/NFT4Play-token-logo-colored-512x512.png?1644992279',
            'coingeckoId': '4play',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RUBY',
            'name': 'Ruby Play Network',
            'address': '0xf7722aA0714096f1FB5ef83e6041CEBB4d58a08e',
            'decimals': 12,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24301/thumb/v7j5bIgX_400x400.jpg?1647269369',
            'coingeckoId': 'ruby-play-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LSS',
            'name': 'Lossless',
            'address': '0xf7686F43591302Cd9B4b9c4FE1291473Fae7D9C9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xf7686f43591302cd9b4b9c4fe1291473fae7d9c9.png',
            'coingeckoId': 'lossless',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BUSDX',
            'name': 'BUSDX',
            'address': '0xf729f4D13A9C9556875D20BACf9EBd0bF891464c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20980/thumb/FgHuTG1.png?1638163513',
            'coingeckoId': 'busdx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UPR',
            'name': 'Upfire',
            'address': '0xf720E38F678B29B243F7D53B56Acbf5dE98F2385',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20562/thumb/UPR256.png?1637214757',
            'coingeckoId': 'upfire',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DPWK',
            'name': 'Dont Play With Kitty',
            'address': '0xf6d5f0BBb71545f3679B37BA8B2268dAfA71E277',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25516/thumb/19845.png?1652165048',
            'coingeckoId': 'dont-play-with-kitty',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XHUNTER',
            'name': 'MetaXHunter',
            'address': '0xf6baE461bA4dEb0039Fb36006f1d0d7cF62d84e9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24053/thumb/200x200.png?1646200738',
            'coingeckoId': 'metaxhunter',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAGS',
            'name': 'BagSoccer',
            'address': '0xf6CBD92E56c1526524429375b925Ff71E29943B1',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26209/thumb/vvx1b9W.png?1656643293',
            'coingeckoId': 'bagsoccer',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'D',
            'name': 'Denarius',
            'address': '0xf6B53b4c982b9B7e87af9dc5c66C85117A5df303',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/760/thumb/denarius.png?1547034506',
            'coingeckoId': 'denarius',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHB',
            'name': 'COINHUB',
            'address': '0xf68D4d917592f3a62417aCE42592F15296cc33A0',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26818/thumb/4DBF1DAE-0930-4925-BD70-1A446F267546.jpeg?1660278721',
            'coingeckoId': 'coinhub',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STARDUST',
            'name': 'Stargazer Protocol',
            'address': '0xf647d6B49ea7bb880EcbCFf9913dF0d634875D33',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16863/thumb/output-onlinepngtools-8.png?1625469420',
            'coingeckoId': 'stargazer-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'B8',
            'name': 'Binance8',
            'address': '0xf62A6268e3164926f8435dE771706082C73BEBd9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19179/thumb/b8.png?1634613186',
            'coingeckoId': 'binance8',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AMT',
            'name': 'Amazy Move Token',
            'address': '0xf625069dce62dF95b4910f83446954B871F0Fc4f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27246/thumb/AMT_200x200.png?1662884836',
            'coingeckoId': 'amazy-move-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BET',
            'name': 'BETCOIN',
            'address': '0xf5E1c367D2E41b9143937aD17Da6D53aBBBC306B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27373/thumb/betcoinlogo.jpeg?1663732618',
            'coingeckoId': 'betcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FOMO',
            'name': 'ForeverMoon',
            'address': '0xf5C2A8a14FA5C71c4F2684207398FbF30f835D87',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26401/thumb/Astraunaut.png?1657849260',
            'coingeckoId': 'forevermoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DLF',
            'name': 'Delfino Finance',
            'address': '0xf595b2400cb13A140Fb162b226e4Be3A1bfC3606',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25497/thumb/200.png?1652077099',
            'coingeckoId': 'delfino-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CDR',
            'name': 'CondorChain',
            'address': '0xf5930e4a767657e547C621F6c05b108b1e721614',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27806/thumb/C66960A5-44FA-4D77-922A-DF8E3AB3BC87.jpeg?1665904461',
            'coingeckoId': 'condorchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOC',
            'name': 'MetaOceanCity',
            'address': '0xf54cDA0b65235Fa1d71Ac62C383746d1dd408Ea6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26250/thumb/qs7hVlTP_400x400.jpg?1656936087',
            'coingeckoId': 'metaoceancity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KRZ',
            'name': 'Kranz',
            'address': '0xf54B304e2e4b28c7E46619D1A340F9b2B72383D7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17688/thumb/KRZ-Extra-Small-Logo-200x200-1.png?1654230602',
            'coingeckoId': 'kranz-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'URUST',
            'name': 'Urust Global',
            'address': '0xf52CE0A22a70F1e915f3B1898c97C4B836a1cf0e',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27751/thumb/20221010_164134.png?1665651586',
            'coingeckoId': 'urust-global',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'M1VERSE',
            'name': 'MetaOneVerse',
            'address': '0xf50B0a35EfdF8F247625E2A0695D56a63b30B7ff',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25940/thumb/metaoneverse-logo.jpg?1654765150',
            'coingeckoId': 'metaoneverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VETH',
            'name': 'Venus ETH',
            'address': '0xf508fCD89b8bd15579dc79A6827cB4686A3592c8',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xf508fcd89b8bd15579dc79a6827cb4686a3592c8.png',
            'coingeckoId': 'venus-eth',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MIL',
            'name': 'Military Finance',
            'address': '0xf5015995376a35b10dcBD96BA6B6e9de1c9f87c5',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15893/thumb/logo-png_%281%29.png?1622249928',
            'coingeckoId': 'military-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CGAZ',
            'name': 'CryptoGamez',
            'address': '0xf4C375fd1c53F08aD597Cf1DB60b7c23153Db3Bc',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24005/thumb/gtEbDVF.jpeg?1646027097',
            'coingeckoId': 'cryptogamez',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEXSHARE',
            'name': 'dexSHARE',
            'address': '0xf4914E6D97a75f014AcFcF4072f11be5CfFc4cA6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25749/thumb/dexSHARE_200x200.png?1653463225',
            'coingeckoId': 'dexshare',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VELO',
            'name': 'Velo',
            'address': '0xf486ad071f3bEE968384D2E39e2D8aF0fCf6fd46',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12538/thumb/Logo_200x_200.png?1625196103',
            'coingeckoId': 'velo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BULL',
            'name': 'Bull Coin',
            'address': '0xf483af09917bA63F1E274056978036d266EB56e6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15975/thumb/bull_ms.94cd70ff.png?1622525834',
            'coingeckoId': 'bull-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WBDC',
            'name': 'BullDoge Chain',
            'address': '0xf462a351cff44716B0d31DF87976467FDF83CB9d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27158/thumb/30293-01_%281%29.png?1662276408',
            'coingeckoId': 'bulldoge-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WEB3',
            'name': 'Web3 Doge',
            'address': '0xf45de5A6d02c4a6E050B90Add33ebf0A34D017e0',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23892/thumb/Web3Doge_LogoPNGversion.png?1645606985',
            'coingeckoId': 'web3-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EMC1',
            'name': 'EMCIS NETWORK',
            'address': '0xf44adBe0212B1B816F6c5D209bCE67e363f40AC8',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27086/thumb/emc1.png?1661767928',
            'coingeckoId': 'emcis-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CZBNB',
            'name': 'CZbnb',
            'address': '0xf43fffb83D7C73275c2feaAC15D5670f7D739B50',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26175/thumb/czbnb.9f7f5b59_%281%29.png?1656378083',
            'coingeckoId': 'czbnb',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SATOZ',
            'name': 'Satozhi',
            'address': '0xf4341fA52669cea0c1836095529A7E9B04b8b88D',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14786/thumb/0GeSrIaQ_400x400.jpg?1618416420',
            'coingeckoId': 'satozhi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PWT',
            'name': 'PandaInu Wallet Token',
            'address': '0xf3eDD4f14a018df4b6f02Bf1b2cF17A8120519A2',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0xf3edd4f14a018df4b6f02bf1b2cf17a8120519a2/fc326df522d79e5d1ab7c47fd7d56694.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SHBAR',
            'name': 'Shilly Bar',
            'address': '0xf3bebB4f2D348e44cC4547Cba2F96C214fE5A25A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20982/thumb/photo_2021-11-29_13.40.47.jpeg?1638164760',
            'coingeckoId': 'shilly-bar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XIL',
            'name': 'Xillion',
            'address': '0xf3bE1A4a47576208C1592Cc027087CE154B00672',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18247/thumb/projectx.PNG?1631134123',
            'coingeckoId': 'projectx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPLUSV2',
            'name': 'SafePlus V2',
            'address': '0xf3aEF59EA17d3082F368387ffF073D5E05874ad9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21303/thumb/Logo-Safeplus-200x200.png?1638883075',
            'coingeckoId': 'safeplus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CELL',
            'name': 'Cellframe',
            'address': '0xf3E1449DDB6b218dA2C9463D4594CEccC8934346',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0x0ffb06aabba09b1d6e9bd78d97791b0efac9536a.png',
            'coingeckoId': 'cellframe',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'HYDRO',
            'name': 'Hydro',
            'address': '0xf3DBB49999B25c9D6641a9423C7ad84168D00071',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/3678/thumb/New_Hydro_logo.png?1625473878',
            'coingeckoId': 'hydro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OLINK',
            'name': 'Openlink DAO',
            'address': '0xf3Bef262E70D200080AE795f41261756058d289A',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26679/thumb/4H9m1qbT_400x400.jpeg?1659525066',
            'coingeckoId': 'openlink-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DR1',
            'name': 'Dr1ver',
            'address': '0xf382d257A468f5B8b72d35e09ca0193d0C424401',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26497/thumb/7cagE5c3_400x400.jpeg?1658355742',
            'coingeckoId': 'dr1ver',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TIPSY',
            'name': 'Tipsy',
            'address': '0xf380334b9A181F54BF2f32FDD3e153D259472eBB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20639/thumb/JiudVIkB_400x400.jpg?1637462674',
            'coingeckoId': 'tipsy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAT',
            'name': 'My Master War',
            'address': '0xf3147987a00D35EeCC10C731269003CA093740CA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18772/thumb/JiuEEvte_400x400.jpg?1633393017',
            'coingeckoId': 'my-master-war',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CMF',
            'name': 'Crypto Makers Foundation',
            'address': '0xf309993f0E27a34580b2fC12fDC37095FBdc104f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23028/thumb/200x200.png?1643095567',
            'coingeckoId': 'crypto-makers-foundation',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANKR',
            'name': 'Ankr',
            'address': '0xf307910A4c7bbc79691fD374889b36d8531B08e3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x8290333cef9e6d528dd5618fb97a76f268f3edd4.png',
            'coingeckoId': 'ankr',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GAMES',
            'name': 'Gaming Stars',
            'address': '0xf300e4F1A193Dd047B0C6747aD4E16dedf886297',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/1729/thumb/logo-yt_1_1_1_200x200.png?1650868415',
            'coingeckoId': 'gaming-stars',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIELDNET',
            'name': 'Shield Network',
            'address': '0xf2E00684457de1a3C87361bC4BfE2DE92342306C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14909/thumb/ShieldNet.png?1623317875',
            'coingeckoId': 'shield-network',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'BIP',
            'name': 'Minter Network',
            'address': '0xf2Ba89A6f9670459ed5AeEfbd8Db52Be912228b8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/9982/thumb/Nvoj_6Mu_400x400.jpg?1587968303',
            'coingeckoId': 'minter-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RDF',
            'name': 'ReadFi',
            'address': '0xf29CCcC3460506e8F9bc038D4716C05b76b0441e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26780/thumb/readfi.png?1660903974',
            'coingeckoId': 'readfi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TBCC',
            'name': 'TBCC',
            'address': '0xf29480344d8e21EFeAB7Fde39F8D8299056A7FEA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13353/thumb/200-200.png?1625755548',
            'coingeckoId': 'tbcc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DPS',
            'name': 'DEEPSPACE',
            'address': '0xf275e1AC303a4C9D987a2c48b8E555A77FeC3F1C',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17953/thumb/f1LFu897_400x400.jpg?1629898614',
            'coingeckoId': 'deepspace',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUFFSHIBA',
            'name': 'Buff Shiba Inu',
            'address': '0xf2673f49d2737f97d70995eD43D128E0B8E560c5',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20018/thumb/I9yDn70Y_400x400.png?1636411749',
            'coingeckoId': 'buff-shiba-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JRIT',
            'name': 'JERITEX',
            'address': '0xf2619476bd0CA0EDa08744029c66B62a904c2bf8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19407/thumb/jerit256x256.png?1635210792',
            'coingeckoId': 'jeritex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PLATO',
            'name': 'Plato Game',
            'address': '0xf2572fDACf09bfAE08FF7D35423870B5a8aC26b7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24725/thumb/16085.png?1648700698',
            'coingeckoId': 'plato-game',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'SHIBO',
            'name': 'Shibonk',
            'address': '0xf224AdE71c20f9823E34e0792F72437596b4e28c',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22215/thumb/shibonk-MAINLOGO-CG.png?1645602871',
            'coingeckoId': 'shibonk',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'QTA',
            'name': 'QUOTA',
            'address': '0xf223Fb06766AD55272F179e59f1793ed8C27c706',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24779/thumb/qta.png?1648901247',
            'coingeckoId': 'quota',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ECTO',
            'name': 'LittleGhosts Ectoplasm',
            'address': '0xf20C8afD1391C41A4185Bb7c2D038DE7097Ba1B9',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21325/thumb/PDjB5PI.png?1638945800',
            'coingeckoId': 'littleghosts-ectoplasm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GFN',
            'name': 'Graphene',
            'address': '0xf209CE1960Fb7E750ff30Ba7794ea11C6Acdc1f3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19159/thumb/Graphene-Logo.png?1634547901',
            'coingeckoId': 'graphene',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'ICE',
            'name': 'Popsicle Finance',
            'address': '0xf16e81dce15B08F326220742020379B855B87DF9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xf16e81dce15b08f326220742020379b855b87df9.png',
            'coingeckoId': 'ice-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'RHT',
            'name': 'Reward Hunters',
            'address': '0xf1018C71eeBe32Dd85012Ad413bAB6B940d0d51E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18086/thumb/reward-hunters-token-logo-1.png?1630405794',
            'coingeckoId': 'reward-hunters-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NNI',
            'name': 'Neonomad Finance',
            'address': '0xf0eB3c9088718a533C8FD64Dbcaa5927faed6D18',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25068/thumb/SeSkZxx7_400x400.jpeg?1658118217',
            'coingeckoId': 'neonomad-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NVT',
            'name': 'NerveNetwork',
            'address': '0xf0E406c49C63AbF358030A299C0E00118C4C6BA5',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xf0e406c49c63abf358030a299c0e00118c4c6ba5.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'sushiswap',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'POWERZILLA',
            'name': 'PowerZilla',
            'address': '0xf082907bebDd0978E075621CF040Ec716De966b9',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21010/thumb/MYaRJcZd_400x400.jpg?1638189555',
            'coingeckoId': 'powerzilla',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WELL',
            'name': 'BitWell Token',
            'address': '0xf07a32Eb035b786898c00bB1C64d8c6F8E7a46D5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xf07a32Eb035b786898c00bB1C64d8c6F8E7a46D5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'IPAD',
            'name': 'Infinity Pad  OLD ',
            'address': '0xf07DFc2AD28AB5B09E8602418d2873Fcb95e1744',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17302/thumb/ipad.jpg?1627268574',
            'coingeckoId': 'infinity-pad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IGN',
            'name': 'Infinity Game NFT',
            'address': '0xf071AF4Da0D1c4f9BfD624C2C5eced7C5fe52573',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19583/thumb/igf.PNG?1635471338',
            'coingeckoId': 'infinity-game-nft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GTI',
            'name': 'GTI Token',
            'address': '0xf06Be7919E41394C7087ac2B2549C9c769d0fb04',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27954/thumb/LOGO.png?1666669681',
            'coingeckoId': 'gti-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BANKER',
            'name': 'BankerDoge',
            'address': '0xf06419C638a1d809C024Aa693147dBDc42B60145',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18507/thumb/bankerdoge.png?1632215367',
            'coingeckoId': 'bankerdoge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OG',
            'name': 'OG',
            'address': '0xf05E45aD22150677a017Fbd94b84fBB63dc9b44c',
            'decimals': 2,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xf05E45aD22150677a017Fbd94b84fBB63dc9b44c/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'TAO',
            'name': 'Friction Finance',
            'address': '0xf0443834B7b21104b7102Edbe8F9ec06204Cd395',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xf0443834b7b21104b7102edbe8f9ec06204cd395.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'RADAR',
            'name': 'Radar',
            'address': '0xf03A2dC374d494fbE894563Fe22eE544d826aa50',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13909/thumb/RADAR.png?1634183461',
            'coingeckoId': 'radar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIBM',
            'name': 'ShibMoon',
            'address': '0xf02D4e1B2685282672094b6B155FdC97B4aD9228',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25779/thumb/shibm.png?1653734799',
            'coingeckoId': 'shibmoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MMG',
            'name': 'Mad Monkey Guild',
            'address': '0xf018Aea0A08A5D88674f0837bdAC27Ab89824deE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27226/thumb/LogoAnimate.png?1662702513',
            'coingeckoId': 'mmg-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SYCLE',
            'name': 'Reesykle',
            'address': '0xefEF90b7349A48452CdaAEa398D600ddB56AEF50',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20844/thumb/252136105_112396834579033_5977036500359520487_n.png?1637744603',
            'coingeckoId': 'reesykle',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CSPD',
            'name': 'CasperPad',
            'address': '0xef9481115ff33E94d3E28A52D3A8F642bf3521e5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21469/thumb/CasperPad_New_Logo.png?1644477033',
            'coingeckoId': 'casperpad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MPC',
            'name': 'META PROTOCOL',
            'address': '0xef83eb60A5e0Fc7A7f2294cbeb5731BA5499dB82',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26986/thumb/MPC_logo.png?1664065873',
            'coingeckoId': 'meta-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOOCHII',
            'name': 'Moochii',
            'address': '0xef032F652FCE3a0eFfce3796A68eb978b465a336',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15444/thumb/QUVbHkC.png?1622196413',
            'coingeckoId': 'moochii',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOGMSK',
            'name': 'Dog Masked',
            'address': '0xeeC776a44A4D482790D6F538FCa7D45B09cD69aD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26801/thumb/dogMaskedLogo.png?1660205250',
            'coingeckoId': 'dog-masked',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PBT',
            'name': 'Project Babel',
            'address': '0xee923E118D1306B7E8358573c39499be0859Cfcf',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27875/thumb/y5dG2Zs4_400x400.jpeg?1666176273',
            'coingeckoId': 'project-babel',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HUB',
            'name': 'Scouthub',
            'address': '0xee7B7c840de85ad277CdddaEf63b3b29672a3C58',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26282/thumb/hubtoken-2-200x200.png?1657076232',
            'coingeckoId': 'scouthub',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TAZOR',
            'name': 'Tazor',
            'address': '0xee65D8B88F86ace0f7bA42BA2d2C679b6f604bf0',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27261/thumb/tazor.png?1663554021',
            'coingeckoId': 'tazor',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VYNC',
            'name': 'VYNK Chain',
            'address': '0xee1ae38BE4Ce0074C4A4A8DC821CC784778f378c',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17743/thumb/vynk_chain.PNG?1629150126',
            'coingeckoId': 'vynk-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PYRAM',
            'name': 'Pyram',
            'address': '0xedeCfB4801C04F3EB394b89397c6Aafa4ADDa15B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0xedecfb4801c04f3eb394b89397c6aafa4adda15b.png',
            'coingeckoId': 'pyram-token',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'HP',
            'name': 'HeroPark',
            'address': '0xede1f9cdB98B4cA6A804De268b0acA18DCE192e8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21951/thumb/FA_Hero_Park_Logo_Blue.png?1640323382',
            'coingeckoId': 'heropark',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SC',
            'name': 'ScarpaCoin',
            'address': '0xedB74C622D9334a58Aa1FF3E4DF83B35D6fA1F14',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27456/thumb/ScarpaCoin_Logo.png?1664100906',
            'coingeckoId': 'scarpacoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VIPS',
            'name': 'VIPS Token',
            'address': '0xedA216d12E45Ad0Ff34aC9F184AB0C0d277c7f2c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27834/thumb/200-X-200.png?1666258975',
            'coingeckoId': 'vips-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UTU',
            'name': 'UTU Coin',
            'address': '0xed4Bb33F20F32E989AF975196E86019773A7CFf0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12831/thumb/Aa5nmbkJ_400x400.png?1602884636',
            'coingeckoId': 'utu-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SMARTSHIB',
            'name': 'Smart Shiba',
            'address': '0xed4554f295A3FB3cb7D2D295cB58184415F8505e',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22665/thumb/smlogo.png?1642403284',
            'coingeckoId': 'smart-shiba',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APE',
            'name': 'APEmove',
            'address': '0xed3D88d3321f82E5C25CA9ac6d5B427ec93f567e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26594/thumb/Group_48096531.png?1658972586',
            'coingeckoId': 'apemove',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DS',
            'name': 'DiamondShiba',
            'address': '0xed0Fd5ec2ae6D1021D928E2bfD8CEC2BAC41600F',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21167/thumb/tgkXgVw0_400x400.jpg?1638435385',
            'coingeckoId': 'diamondshiba',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CYBRRRDOGE',
            'name': 'CyberDoge',
            'address': '0xecc62bd353EDd64Ed31595DbC4C92801aF1e2af0',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17455/thumb/VJJcCrdU_400x400.png?1627875883',
            'coingeckoId': 'cyberdoge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SGLDM',
            'name': 'GoldMint Shares',
            'address': '0xecc559d2bCc83F3f2110416B4911e082729e8b56',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MCF',
            'name': 'Max Crowdfund',
            'address': '0xecB19B2A4e9C76cE748cf33f68f0857288f9f090',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/7809/thumb/thumbnail.png?1550775802',
            'coingeckoId': 'max-property-group',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VUSDC',
            'name': 'Venus USDC',
            'address': '0xecA88125a5ADbe82614ffC12D0DB554E2e2867C8',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xeca88125a5adbe82614ffc12d0db554e2e2867c8.png',
            'coingeckoId': 'venus-usdc',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'NFTNDR',
            'name': 'NFTinder',
            'address': '0xec37441Dbc28C17D22740d8298Cd737B994E4ADf',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21186/thumb/15521.png?1638514214',
            'coingeckoId': 'nftinder',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VDOGE',
            'name': 'Venus DOGE',
            'address': '0xec3422Ef92B2fb59e84c8B02Ba73F1fE84Ed8D71',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15058/thumb/doge.f7fbdf1d.png?1619590113',
            'coingeckoId': 'venus-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABI',
            'name': 'Babylons',
            'address': '0xec15a508a187e8DDfe572A5423Faa82Bbdd65120',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17838/thumb/CW32Ubsk_400x400.jpg?1629462119',
            'coingeckoId': 'babylons',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ACRE',
            'name': 'Arable Protocol',
            'address': '0xebd949AaCFC681787D3d091fA2929E4413E0e4E1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DXN',
            'name': 'Dexance',
            'address': '0xebceF8ccA798BF898A47b21f8Fb6eFC4B89CB9eA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ASG',
            'name': 'Asgard Games',
            'address': '0xebE7C1395E43465Ae7A041A686e957D9aa184b0d',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27816/thumb/asg.png?1665913586',
            'coingeckoId': 'asgard-games',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASIA',
            'name': 'Asia Coin',
            'address': '0xebAFFc2d2EA7c66fb848c48124b753F93A0A90ec',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xebaffc2d2ea7c66fb848c48124b753f93a0a90ec.png',
            'coingeckoId': 'asia-coin',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'GTH',
            'name': 'Gather',
            'address': '0xeb986DA994E4a118d5956b02d8b7c3C7CE373674',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12458/thumb/Gather-Logo-Working-File.png?1599981686',
            'coingeckoId': 'gather',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RAID',
            'name': 'Ancient Raid',
            'address': '0xeb90A6273F616A8ED1cf58A05d3ae1C1129b4DE6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26990/thumb/raid_token_transparent.png?1661222820',
            'coingeckoId': 'ancient-raid',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MORA',
            'name': 'Meliora',
            'address': '0xeb633EC737889EBa802478AEA7Eb0f5203eB8deB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14553/thumb/3vcbfVWS_400x400.png?1616992649',
            'coingeckoId': 'meliora',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MFI',
            'name': 'MetFi',
            'address': '0xeb5bb9d14D27F75C787cf7475e7ED00D21dc7279',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25760/thumb/favicon-32x32.png?1653547098',
            'coingeckoId': 'metfi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GBYTE',
            'name': 'Obyte',
            'address': '0xeb34De0C4B2955CE0ff1526CDf735c9E6d249D09',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/561/thumb/byteball.png?1547564361',
            'coingeckoId': 'byteball',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MSC',
            'name': 'MetaSwap',
            'address': '0xeacAd6c99965cDE0f31513dd72DE79FA24610767',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23833/thumb/logo.png?1645516225',
            'coingeckoId': 'metaswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DINOP',
            'name': 'DinoPark',
            'address': '0xea90DC6F64d18771Ca1dac8098526a9082265B42',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17286/thumb/dinop.jpg?1627196449',
            'coingeckoId': 'dinopark',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ONI',
            'name': 'ONINO',
            'address': '0xea89199344a492853502a7A699Cc4230854451B8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/9405/thumb/Oni_Round.png?1643035358',
            'coingeckoId': 'oni-token',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'COTK',
            'name': 'Colligo',
            'address': '0xea738b6f1C888B2eCed0faD66918DEfeFE7C4494',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20903/thumb/f1fcc0d2-e1b0-4264-b7b1-9e382ba5c7ab.png?1642165801',
            'coingeckoId': 'colligo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CPO',
            'name': 'Cryptopolis',
            'address': '0xea395DFaFEd39924988b475f2Ca7f4C72655203A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19163/thumb/PgnW_SGk_400x400.jpg?1652861818',
            'coingeckoId': 'cryptopolis',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MONA',
            'name': 'Monaco Planet',
            'address': '0xeF843FB4C112E618B262f6897F479474e4586F05',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21742/thumb/mona.PNG?1639974886',
            'coingeckoId': 'mona',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NEF',
            'name': 'NEF Rune  Rune Game ',
            'address': '0xeF4F66506AAaEeFf6D10775Ad6f994105D8f11b4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27794/thumb/nef.png?1665821147',
            'coingeckoId': 'nef-rune-rune-game',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ENTITY',
            'name': 'Entity',
            'address': '0xeEf6020B7720f4e000476b017Fc4e224dFC0aA36',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26776/thumb/logo200.png?1660052177',
            'coingeckoId': 'entity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELK',
            'name': 'Elk',
            'address': '0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xeeeeeb57642040be42185f49c52f7e9b38f8eeee.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MILIT',
            'name': 'Militia Games',
            'address': '0xeEa7C025b1EeE9527d631094D45Ff9cE5d830b6f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20214/thumb/heroes_militia.PNG?1636669769',
            'coingeckoId': 'militia-games',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DHOLD',
            'name': 'DiamondHold',
            'address': '0xeE8feAeE52CE378BA356A5772BBa29d08AF25cdB',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15559/thumb/X31eQqk.png?1623039444',
            'coingeckoId': 'diamondhold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRUSTK',
            'name': 'TrustKeys Network',
            'address': '0xeE89Bd9aF5e72B19B48cac3E51acDe3A09A3AdE3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19578/thumb/BasVH3Aj_400x400.png?1635468557',
            'coingeckoId': 'trustkeys-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELM',
            'name': 'Elements',
            'address': '0xeE351682cDA9551F8EED6349F6237caB75F8c495',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17723/thumb/cropped-tokens2.png?1629092595',
            'coingeckoId': 'elements-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RMOG',
            'name': 'Reforestation Mahogany',
            'address': '0xeE33114EF9AfB0779f0505C38AFBB1644f4073cc',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22578/thumb/honWJBY.png?1642083590',
            'coingeckoId': 'reforestation-mahogany',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PUFFS',
            'name': 'Crypto Puffs',
            'address': '0xeE11dB54b66E4dbB99D497573106615bB6ac03Ab',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17672/thumb/puff.PNG?1628829844',
            'coingeckoId': 'crypto-puffs',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ECO',
            'name': 'Ormeus Ecosystem',
            'address': '0xeDe2F059545e8Cde832d8Da3985cAacf795B8765',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/8923/thumb/logo_eco_low.png?1562902804',
            'coingeckoId': 'ormeus-ecosystem',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KITE',
            'name': 'Kite Sync',
            'address': '0xeDe26a1ee14281b58A5238a3ff246b02358A13B6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18444/thumb/kite.PNG?1632053702',
            'coingeckoId': 'kite-sync',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UANG',
            'name': 'UangMarket',
            'address': '0xeDd18ee4808A9E75A2e11d1E1A0aA68b12C97140',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23674/thumb/Cgim2ou.jpeg?1644995064',
            'coingeckoId': 'uangmarket',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HPS',
            'name': 'HappinessToken',
            'address': '0xeDa21B525Ac789EaB1a08ef2404dd8505FfB973D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xeda21b525ac789eab1a08ef2404dd8505ffb973d.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'VEMP',
            'name': 'vEmpire DDAO',
            'address': '0xeDF3ce4Dd6725650a8e9398e5C6398D061Fa7955',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18074/thumb/Ay5nuny.png?1630380510',
            'coingeckoId': 'vempire-ddao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CLS',
            'name': 'Calaswap',
            'address': '0xeD9D76Ff02E036917D352955490B156d5779D311',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25814/thumb/Calaswap-3.png?1653988608',
            'coingeckoId': 'calaswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BBOXER',
            'name': 'Baby Boxer',
            'address': '0xeD7ed82D5A0f1B363309223975f899E7BEd5Fea6',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17160/thumb/P4BvEOA.png?1626673474',
            'coingeckoId': 'baby-boxer',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PBTC',
            'name': 'pTokens BTC  OLD ',
            'address': '0xeD28A457A5A76596ac48d87C0f577020F6Ea1c4C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xeD28A457A5A76596ac48d87C0f577020F6Ea1c4C/logo.png',
            'coingeckoId': 'ptokens-btc',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CHRP',
            'name': 'Chirpley',
            'address': '0xeD00Fc7D48B57B81FE65D1cE71c0985e4CF442CB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27243/thumb/Chirpley-Logo_200x200.png?1662882824',
            'coingeckoId': 'chirpley',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NUM',
            'name': 'NUM Token',
            'address': '0xeCEB87cF00DCBf2D4E2880223743Ff087a995aD9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://storage.googleapis.com/xy-finance-images/token-list/Num.png',
            'coingeckoId': 'numbers-protocol',
            'listedIn': [
                'coingecko',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'XECO',
            'name': 'X Ecosystem',
            'address': '0xeC722154F3dDf7CC08C136ADFf57c5D4aa210984',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26203/thumb/0mkm6Wsz_400x400.jpg?1656490936',
            'coingeckoId': 'x-ecosystem',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLOOP',
            'name': 'Baby Loop',
            'address': '0xeC13736FFB2A50F3A4817E5797C8A8A777A147CB',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26979/thumb/babylooplogo200X200.png?1661169853',
            'coingeckoId': 'baby-loop',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORBS',
            'name': 'Orbs',
            'address': '0xeBd49b26169e1b52c04cFd19FCf289405dF55F80',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xebd49b26169e1b52c04cfd19fcf289405df55f80.png',
            'coingeckoId': 'orbs',
            'listedIn': [
                'coingecko',
                '1inch',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MLX',
            'name': 'Metaple Finance',
            'address': '0xeBDA6aaE3f8c96eAEbe33cEEbbAE24Fc39F315d6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26939/thumb/meta.png?1660910806',
            'coingeckoId': 'metaple-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BPAY',
            'name': 'BNBPay',
            'address': '0xeBC76079Da0c245faE7225b58a57A54809b40618',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xebc76079da0c245fae7225b58a57a54809b40618.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'WAM',
            'name': 'Wam',
            'address': '0xeBBAefF6217d22E7744394061D874015709b8141',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21576/thumb/KrJueEpI_400x400.jpg?1639529423',
            'coingeckoId': 'wam',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'XPO',
            'name': 'Xpool',
            'address': '0xeBB59CeBFb63f218db6B5094DC14AbF34d56D35D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14756/thumb/SU8JVaHN_400x400_%281%29.jpg?1618202943',
            'coingeckoId': 'xpool',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RAINI',
            'name': 'Rainicorn',
            'address': '0xeB953eDA0DC65e3246f43DC8fa13f35623bDd5eD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14491/thumb/logo-200x200.png?1633314246',
            'coingeckoId': 'rainicorn',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'OMAX',
            'name': 'Omax',
            'address': '0xeB84be66c8E71f07eA57Cf3b21626d7784F32A7F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19853/thumb/omxlogo.png?1636061849',
            'coingeckoId': 'omax-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MINERS',
            'name': 'MinersDefi',
            'address': '0xeB6B00F8C7E1da78fb919C810C30DdE95475bdDe',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18809/thumb/Miners_Defi_Logo_for_Coingecko.png?1643186256',
            'coingeckoId': 'minersdefi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOLC',
            'name': 'GOLCOIN',
            'address': '0xeB52620B04e8EACFd795353F2827673887f292E0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27341/thumb/logo_in_the_middle_.png?1663551501',
            'coingeckoId': 'golcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEFC',
            'name': 'DeFi Coin',
            'address': '0xeB33cbBe6F1e699574f10606Ed9A495A196476DF',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17020/thumb/deficoin.png?1626094280',
            'coingeckoId': 'defi-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOCCER',
            'name': 'SoccerInu',
            'address': '0xeB0Ad54A88dc735e55F0d9B394C8CB6e46b62202',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27784/thumb/soccer_inu_logo.png?1665794482',
            'coingeckoId': 'soccerinu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOOFYDOGE',
            'name': 'GoofyDoge',
            'address': '0xeA7de2a4973F3b3FC1591972a30781899a0961cF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20640/thumb/LR2SGDFq_400x400.jpg?1637463616',
            'coingeckoId': 'goofydoge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOURCE',
            'name': 'ReSource Protocol',
            'address': '0xeA136Fc555E695Ba96d22e10B7E2151c4C6B2A20',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20740/thumb/source.png?1637749215',
            'coingeckoId': 'resource-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUSD',
            'name': 'Binance USD',
            'address': '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x4fabb145d64652a948d72533023f6e7a623c7c53.png',
            'coingeckoId': 'binance-usd',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'RDT',
            'name': 'Ridotto',
            'address': '0xe9c64384dEb0C2bF06D991A8D708c77eb545E3d5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18671/thumb/200x200_%2832%29.png?1632875527',
            'coingeckoId': 'ridotto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PCNT',
            'name': 'Playcent',
            'address': '0xe9b9c1c38Dab5EAB3B7E2AD295425e89bD8db066',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14335/thumb/1_B5bFcgBld5poUj_c-_K1Jw.png?1615444831',
            'coingeckoId': 'playcent',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CP',
            'name': 'Crystal Powder',
            'address': '0xe9C133D3D94263C7aC7B848168138D7cF2cEbA14',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22372/thumb/17029.png?1641769145',
            'coingeckoId': 'crystal-powder',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FIT',
            'name': 'FIT',
            'address': '0xe990B1B75C3C374BB1Fbd03faF20B5AFb6B525DA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19062/thumb/FIT-token-200.png?1634266459',
            'coingeckoId': 'fit-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SETS',
            'name': 'Sensitrust',
            'address': '0xe95fD76CF16008c12FF3b3a937CB16Cd9Cc20284',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15035/thumb/SETS-token-logo-200.png?1619503990',
            'coingeckoId': 'sensitrust',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MNTTBSC',
            'name': 'MoonTrustBSC',
            'address': '0xe9406b73d4F54572E27d60479905723DE08E689e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21685/thumb/moontrustlogo-200.jpg?1639718643',
            'coingeckoId': 'moontrustbsc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEXT',
            'name': 'DexTools',
            'address': '0xe91a8D2c584Ca93C7405F15c22CdFE53C29896E3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/11603/thumb/dext.png?1605790188',
            'coingeckoId': 'dextools',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'CXPAD',
            'name': 'CoinxPad',
            'address': '0xe90d1567ecEF9282CC1AB348D9e9E2ac95659B99',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18626/thumb/cxpad.png?1659854954',
            'coingeckoId': 'coinxpad',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'HYPES',
            'name': 'Supreme Finance HYPES',
            'address': '0xe8e0D396f0881c0fAb9319E441223F9780b8305c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27322/thumb/N9aAdTFH_400x400.jpeg?1663320033',
            'coingeckoId': 'supreme-finance-hypes',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FOF',
            'name': 'Future Of Fintech',
            'address': '0xe8A8db73f7fb1Ab5F05C619A8a631c8930c782Ae',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26837/thumb/FOF_logo.png?1660304045',
            'coingeckoId': 'future-of-fintech',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TITAN',
            'name': 'TitanSwap',
            'address': '0xe898EDc43920F357A93083F1d4460437dE6dAeC2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xe898EDc43920F357A93083F1d4460437dE6dAeC2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WQT',
            'name': 'Work Quest',
            'address': '0xe89508D74579A06A65B907c91F697CF4F8D9Fac7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14662/thumb/WQT-1_%281%29.png?1627652762',
            'coingeckoId': 'work-quest',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HOKK',
            'name': 'Hokkaido Inu',
            'address': '0xe87e15B9c7d989474Cb6d8c56b3DB4eFAD5b21E8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xe87e15b9c7d989474cb6d8c56b3db4efad5b21e8.png',
            'coingeckoId': 'hokkaidu-inu',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'XCT',
            'name': 'Citadel one',
            'address': '0xe8670901E86818745b28C8b30B17986958fCe8Cc',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17852/thumb/logo200on200.png?1648487794',
            'coingeckoId': 'citadel-one',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MLM',
            'name': 'Moonlight Metaverse',
            'address': '0xe83cCC6fF1477745459E940234D88281E0a6486a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27773/thumb/Moonlight-200-200.png?1665724929',
            'coingeckoId': 'moonlight-metaverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MSU',
            'name': 'MetaSoccer Universe',
            'address': '0xe8377A076adAbb3F9838afB77Bee96Eac101ffB1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0xe8377a076adabb3f9838afb77bee96eac101ffb1/ce7d8f1b92322872c90906d62ecc1ddb.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'USD+',
            'name': 'USD ',
            'address': '0xe80772Eaf6e2E18B651F160Bc9158b2A5caFCA65',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xe80772eaf6e2e18b651f160bc9158b2a5cafca65.png',
            'coingeckoId': 'usd',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SW',
            'name': 'Sabac Warrior',
            'address': '0xe80115dFC5E0F292113331E791E71c488E6De9B0',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20368/thumb/sbc.PNG?1636948930',
            'coingeckoId': 'sabac-warrior',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TOWER',
            'name': 'Tower',
            'address': '0xe7c9C6bc87B86f9E5b57072F907EE6460B593924',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xe7c9c6bc87b86f9e5b57072f907ee6460b593924.png',
            'coingeckoId': 'tower',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'NFTL',
            'name': 'NFTLaunch',
            'address': '0xe7F72Bc0252cA7B16dBb72eEee1AfcDb2429F2DD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18140/thumb/nftl.PNG?1630652662',
            'coingeckoId': 'nftlaunch',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CORK',
            'name': 'Cork',
            'address': '0xe7EAdA32CAF827d3bA8Cb1074830d803C9bD48c3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26056/thumb/WINERY-LOGO-transparent-200x200.png?1655454578',
            'coingeckoId': 'cork',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MST',
            'name': 'MStation',
            'address': '0xe7Af3fcC9cB79243f76947402117D98918Bd88Ea',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24752/thumb/MST.png?1648786947',
            'coingeckoId': 'mstation',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHACK',
            'name': 'Shack',
            'address': '0xe79a1163a95734ccFBd006cBAaba954f3e846BeB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25699/thumb/shack_no_bg_no_pad3.png?1653441433',
            'coingeckoId': 'shack',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'CLIQ',
            'name': 'DefiCliq',
            'address': '0xe795347731Bc547F4E4643F7945738cE2BC18529',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13242/thumb/deficliq_logo.png?1606660146',
            'coingeckoId': 'deficliq',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWG',
            'name': 'SWG',
            'address': '0xe792f64C582698b8572AAF765bDC426AC3aEfb6B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xe792f64C582698b8572AAF765bDC426AC3aEfb6B/logo.png',
            'coingeckoId': 'swgtoken',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BCF',
            'name': 'BicycleFi',
            'address': '0xe78AD8967e523a29D4D3a8e76422Aa7ab2B6A47E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25759/thumb/20251.png?1653546815',
            'coingeckoId': 'bicyclefi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MBTC',
            'name': 'Micro Bitcoin Finance',
            'address': '0xe77011ed703ed06927dBd78e60c549baBAbF913e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16086/thumb/bitcoin-96x96.png?1622797624',
            'coingeckoId': 'micro-bitcoin-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SB',
            'name': 'Scary Bunny',
            'address': '0xe74E14CB6b4F4F5d97bb0665B9a06Df49F8142D0',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28211/thumb/200_%281%29.png?1668427415',
            'coingeckoId': 'scary-bunny',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOF',
            'name': 'LonelyFans',
            'address': '0xe702c303173f90094ce8c9c6CA3f198eCA0E027C',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21317/thumb/lof.png?1638926928',
            'coingeckoId': 'lonelyfans',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MGC',
            'name': 'Magic of Universe',
            'address': '0xe6F3a6fDbd677e0f4915DF16ce35DaaD1Acd06d0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23736/thumb/jqfIMtiG_400x400.jpg?1645175075',
            'coingeckoId': 'magic-of-universe',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UFLOKI',
            'name': 'Universal Floki Coin',
            'address': '0xe6E2454bD6D85E52309bfb6A3e3521e83c53DA8A',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20475/thumb/NC4ZdXue_400x400.jpg?1637101766',
            'coingeckoId': 'universal-floki-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KOGE',
            'name': 'BNB48 Club',
            'address': '0xe6DF05CE8C8301223373CF5B969AFCb1498c5528',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0xe6df05ce8c8301223373cf5b969afcb1498c5528.png',
            'coingeckoId': 'bnb48-club-token',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'PYO',
            'name': 'Pyrrho',
            'address': '0xe6DF015f66653EcE085A5FBBa8d42C356114ce4F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22423/thumb/pyo.png?1659791372',
            'coingeckoId': 'pyrrho-defi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPORTY',
            'name': 'Sporty',
            'address': '0xe672237cC26FcfDFb593e30E7fEff450646c8A3f',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27116/thumb/rsz-img-20220827-180356-374.png?1662012268',
            'coingeckoId': 'sporty',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPHYNX',
            'name': 'Sphynx',
            'address': '0xe64972C311840cFaf2267DCfD365571F9D9544d9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21877/thumb/14461.png?1640211376',
            'coingeckoId': 'sphynx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KKT',
            'name': 'Kingdom Karnage',
            'address': '0xe64017bDAcbe7dfc84886C3704a26d566e7550de',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22598/thumb/KKT_200x200.png?1643193056',
            'coingeckoId': 'kingdom-karnage',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DERI',
            'name': 'Deri Protocol',
            'address': '0xe60eaf5A997DFAe83739e035b005A33AfdCc6df5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xe60eaf5A997DFAe83739e035b005A33AfdCc6df5/logo.png',
            'coingeckoId': 'deri-protocol',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'RUN',
            'name': 'FiveKM RUN',
            'address': '0xe600B5960B002D5f7c8d7e7Ee182065Ced999ECB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'G9',
            'name': 'GoldenDiamond9',
            'address': '0xe5f70FdE8f1AEAd197D57eedB6f9b231240D38Ca',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21501/thumb/logo_200.png?1639372819',
            'coingeckoId': 'goldendiamond9',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FPL',
            'name': 'Farm Planet',
            'address': '0xe5e29fc606A3002DEC5663568bD6739AC0a6d41c',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21042/thumb/photo-2021-11-30-00-35-04.jpg?1638254318',
            'coingeckoId': 'farm-planet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MILK',
            'name': 'CashCow Protocol Milk',
            'address': '0xe5bd6C5b1c2Df8f499847a545838C09E45f4A262',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20772/thumb/cashcow_protocol_milk.png?1648265774',
            'coingeckoId': 'milk2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KAIINU',
            'name': 'Kai Inu',
            'address': '0xe5a09784b16E1065C37dF14c6e2f06fDcE317a1b',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xe5a09784b16e1065c37df14c6e2f06fdce317a1b.png',
            'coingeckoId': 'kai-inu',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'PAY',
            'name': 'PayBolt',
            'address': '0xe580074A10360404AF3ABfe2d524D5806D993ea3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24175/thumb/logo.png?1646748269',
            'coingeckoId': 'paybolt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CLASS',
            'name': 'Class Coin',
            'address': '0xe57EDc546Ee99f17a5d6e32C2457B4569ecD40f8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24409/thumb/271156749_115119737699793_3761439985639056804_n.png?1647531368',
            'coingeckoId': 'class-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWAP',
            'name': 'SafeSwap Online',
            'address': '0xe56a473043EaAB7947c0a2408cEA623074500EE3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xe56a473043eaab7947c0a2408cea623074500ee3.png',
            'coingeckoId': 'safeswap-online',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BIN',
            'name': 'Binemon',
            'address': '0xe56842Ed550Ff2794F010738554db45E60730371',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17932/thumb/logo-v3.png?1629858800',
            'coingeckoId': 'binemon',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ROAR',
            'name': 'AlphaDEX',
            'address': '0xe561Ebd0D7f9b2BD81DA6e7DA655030DCb0a926b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0xe561ebd0d7f9b2bd81da6e7da655030dcb0a926b/1b868e3a1cd67ff691a0f3948ac142c5.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MTS',
            'name': 'Meta Shark',
            'address': '0xe558a2D2014F16e96A23b2C4EE49C2e7a0FAA9af',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24637/thumb/LOGO-2.png?1648461230',
            'coingeckoId': 'meta-shark',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METIS',
            'name': 'Metis',
            'address': '0xe552Fb52a4F19e44ef5A967632DBc320B0820639',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0xe552Fb52a4F19e44ef5A967632DBc320B0820639.png',
            'coingeckoId': 'metis-token',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'TDOGE',
            'name': ' Doge',
            'address': '0xe550a593d09FBC8DCD557b5C88Cea6946A8b404A',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xe550a593d09FBC8DCD557b5C88Cea6946A8b404A/logo.png',
            'coingeckoId': 'tdoge',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GGCM',
            'name': 'Gold Guaranteed Coin',
            'address': '0xe5431bcf8769FCc7F2F3dBDd93300B9696747C4c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26265/thumb/200x200.png?1656983771',
            'coingeckoId': 'gold-guaranteed-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KUOR',
            'name': 'Kuwa Oracle',
            'address': '0xe53b185Ed220aB14460A9a2ac46e096Ff3a90171',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28079/thumb/rk6bhsCo_400x400.jpeg?1667394851',
            'coingeckoId': 'kuwa-oracle',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEK',
            'name': 'DekBox',
            'address': '0xe52C5A3590952F3ed6fcCf89A0bD7F38e11C5B98',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15968/thumb/DEKbox-02%281%29.png?1622522389',
            'coingeckoId': 'dekbox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GENSHIN',
            'name': 'Genshin NFT',
            'address': '0xe512A26CCCF868CCC6d4F8e9A8729a365C695462',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20525/thumb/2yb2a0kp_400x400.jpg?1637198704',
            'coingeckoId': 'genshin-nft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUCHOW',
            'name': 'LunaChow',
            'address': '0xe4e8e6878718bfe533702D4a6571Eb74D79b0915',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18805/thumb/J-MwYfhD_400x400.jpg?1633475157',
            'coingeckoId': 'lunachow',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OPP',
            'name': 'OptimismPad',
            'address': '0xe4DeB5084E31B1A9A1835f4e60CA9750c831735a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27015/thumb/200x200.png?1661478401',
            'coingeckoId': 'optimismpad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GAL',
            'name': 'Galxe',
            'address': '0xe4Cc45Bb5DBDA06dB6183E8bf016569f40497Aa5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0xe4Cc45Bb5DBDA06dB6183E8bf016569f40497Aa5.png',
            'coingeckoId': 'project-galaxy',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'MOZ',
            'name': 'Mozik',
            'address': '0xe4B22193d68f18f8E8eB3a26F4D64cb6D4573022',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0xe4b22193d68f18f8e8eb3a26f4d64cb6d4573022.png',
            'coingeckoId': 'mozik',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'GNY',
            'name': 'GNY',
            'address': '0xe4A4Ad6E0B773f47D28f548742a23eFD73798332',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/5300/thumb/icon_gny.jpg?1601926183',
            'coingeckoId': 'gny',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FXS',
            'name': 'Frax Share',
            'address': '0xe48A3d7d0Bc88d552f730B62c006bC925eadB9eE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13423/small/frax_share.png?1608478989',
            'coingeckoId': 'frax-share',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'SPACE',
            'name': 'ApeRocket Space',
            'address': '0xe486a69E432Fdc29622bF00315f6b34C99b45e80',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16378/thumb/Space.png?1623832226',
            'coingeckoId': 'space-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLAFLO',
            'name': 'Flappy Floki',
            'address': '0xe477bb2F425C6184243e40174Fe276f281B1C8fB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23346/thumb/VrMX8rOf_400x400.jpg?1643923315',
            'coingeckoId': 'flappy-floki',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PADX',
            'name': 'Puzzle And Dragons X',
            'address': '0xe456a162039270a2a789dA2E8BD2B005a9835232',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25067/thumb/liHrtBjs_400x400.jpg?1650017065',
            'coingeckoId': 'puzzle-and-dragons-x',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MARSSHIB',
            'name': 'The Mars Shiba',
            'address': '0xe41A093549BeEe36b76728D456894093396D1120',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20019/thumb/eLcGGEhX_400x400.jpg?1636411863',
            'coingeckoId': 'the-mars-shiba',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DID',
            'name': 'Didcoin',
            'address': '0xe409271E955078BAA4788428a084FafdAA249C14',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25538/thumb/TFg5GVwZV8Gs9BMisa5kicE8HWXEoawiZR.png?1652258996',
            'coingeckoId': 'didcoin-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARMA',
            'name': 'Aarma',
            'address': '0xe405B8148d731E4E1117e00542264daC5375BC97',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25330/thumb/aarma-200x200.png?1651236749',
            'coingeckoId': 'aarma',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNICORN',
            'name': 'UNICORN Token',
            'address': '0xe3E1FabEaBD48491bD6902B0c32FDEee8D2Ff12b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xe3e1fabeabd48491bd6902b0c32fdeee8d2ff12b.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'FCC',
            'name': 'FarmerCryptoCoin',
            'address': '0xe3D914F704E7baBe7f445DCd02a279B48e830b2C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27033/thumb/FCC_200x200.png?1661498882',
            'coingeckoId': 'farmercryptocoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HPN',
            'name': 'HyperonChain',
            'address': '0xe3D2Ba4eBcc6e9AE3569d6418BC2eaABB8FeEf60',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27148/thumb/logo.png?1662102856',
            'coingeckoId': 'hyperonchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PFT',
            'name': 'PayFlow',
            'address': '0xe3B42852a85d38b18076Ab2dd96B0F894CC0636c',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27223/thumb/payflow.png?1663065342',
            'coingeckoId': 'payflow',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'B2P',
            'name': 'Block2Play',
            'address': '0xe3A9D3c1174f315ED5c3A8e6C643F2e0AeA58Dff',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21833/thumb/B2P-256x256pxx.png?1640086026',
            'coingeckoId': 'block2play',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELOIN',
            'name': 'Eloin',
            'address': '0xe39e2861AE9a45FA321c1B0155D2f99196b2A992',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17656/thumb/Pics-Art-08-01-02-43-25.png?1628756148',
            'coingeckoId': 'eloin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OPPA',
            'name': 'OPPA Token',
            'address': '0xe3861027F1867F25d9B0313ee633485121ddb40B',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23431/thumb/237009713_104032035322792_2289129852400869182_n.jpg?1644210023',
            'coingeckoId': 'oppa-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MSZ',
            'name': 'MegaShibaZilla',
            'address': '0xe37F5E9c1e8199bDA350243AAa50076959Ea13d2',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19941/thumb/msb.png?1636335773',
            'coingeckoId': 'megashibazilla',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FROYO',
            'name': 'Froyo Games',
            'address': '0xe369fec23380f9F14ffD07a1DC4b7c1a9fdD81c9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0xe369fec23380f9F14ffD07a1DC4b7c1a9fdD81c9.png',
            'coingeckoId': 'froyo-games',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'CHES',
            'name': 'Chain Estate DAO',
            'address': '0xe3647FB6024355dBC93133e6c4c74277366A4bdC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24099/thumb/TokenLogo.png?1646372691',
            'coingeckoId': 'chain-estate-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'META',
            'name': 'Metaland DAO',
            'address': '0xe3502567872Cb3310143D7E00ae7c93e63315862',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22322/thumb/meta.PNG?1641457212',
            'coingeckoId': 'metaland-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MEMA',
            'name': 'Meme Machine',
            'address': '0xe340aADC9DB4a73B94b13e299c26E6C8C89AcD35',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24009/thumb/200x200.png?1646029962',
            'coingeckoId': 'meme-machine',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PETO',
            'name': 'Petoverse',
            'address': '0xe327eD2037F133cdA9F56171b68B6b7b4Dfa6175',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25459/thumb/7WMDBHl.png?1651821818',
            'coingeckoId': 'petoverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOGECOLA',
            'name': 'DogeCola',
            'address': '0xe320Df552e78D57E95cF1182B6960746d5016561',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17371/thumb/logo.png?1627479895',
            'coingeckoId': 'dogecola',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAMI',
            'name': 'Bami',
            'address': '0xe2d3486f46EFBd4199ea087E9e466dCC35EE0248',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24425/thumb/token_bami_200x200.png?1647657414',
            'coingeckoId': 'bami',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UFI',
            'name': 'PureFi',
            'address': '0xe2a59D5E33c6540E18aAA46BF98917aC3158Db0D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1642568798634_37521912602121565.png',
            'coingeckoId': 'purefi',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SHR',
            'name': 'Shera',
            'address': '0xe2C5fCF777A2B860921116B275951A50e8135EEb',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25146/thumb/NtobKtpi_400x400.jpg?1650436989',
            'coingeckoId': 'shera-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MBF',
            'name': 'MoonBear Finance',
            'address': '0xe2997ae926C7a76aF782923a7fEf89f36d86C98F',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17636/thumb/mbf.PNG?1628737335',
            'coingeckoId': 'moonbear-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTX',
            'name': 'Matrix Protocol',
            'address': '0xe28832f94Aa99d3eD4c61EF805330168556b4179',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20084/thumb/MTX-200x200.png?1643187548',
            'coingeckoId': 'matrix-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GM',
            'name': 'GoldMiner',
            'address': '0xe2604C9561D490624AA35e156e65e590eB749519',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0xe2604C9561D490624AA35e156e65e590eB749519.png',
            'coingeckoId': 'goldminer',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'BABYFD',
            'name': 'Baby Floki Doge',
            'address': '0xe24F6Bc7635501d5684a963f7e75C8062b9c0eA4',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19543/thumb/babyfd.png?1635641884',
            'coingeckoId': 'baby-floki-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KONO',
            'name': 'Konomi',
            'address': '0xe22b9152914986E4020a00ab6Ef510085C26C208',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'APE-X',
            'name': 'Ape-X',
            'address': '0xe211299dcE465083f5b70F119d6620cB29B9bCAc',
            'decimals': 9,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BAN',
            'name': 'Banano',
            'address': '0xe20B9e246db5a0d21BF9209E4858Bc9A3ff7A034',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xe20b9e246db5a0d21bf9209e4858bc9a3ff7a034.png',
            'coingeckoId': 'banano',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'STARP',
            'name': 'STARPARK',
            'address': '0xe1E53E74AfB94731258Ab6c01f9780D787b162Ec',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26686/thumb/smartparklogo.gif?1659598013',
            'coingeckoId': 'starpark',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFESPACE',
            'name': 'SAFESPACE',
            'address': '0xe1DB3d1eE5CfE5C6333BE96e6421f9Bd5b85c987',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xe1db3d1ee5cfe5c6333be96e6421f9bd5b85c987.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'NAC',
            'name': 'Nowlage Coin',
            'address': '0xe198e8Fe1aaB441E54d9572E2402D7B132ccB15a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19767/thumb/logo3-01.png?1635837229',
            'coingeckoId': 'nowlage-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EHX',
            'name': 'Eterna Hybrid Exchange',
            'address': '0xe1747a23C44f445062078e3C528c9F4c28C50a51',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24825/thumb/_0VJF4cs_400x400.jpg?1649042250',
            'coingeckoId': 'eterna-hybrid-exchange',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XFT',
            'name': 'Offshift',
            'address': '0xe138c66982Fd5c890c60b94FDBa1747faF092c20',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/11977/thumb/CsBrPiA.png?1614570441',
            'coingeckoId': 'offshift',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NUSA',
            'name': 'NUSA',
            'address': '0xe11F1D5EEE6BE945BeE3fa20dBF46FeBBC9F4A19',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27967/thumb/Asset_1_2x.png?1666769269',
            'coingeckoId': 'nusa-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWAP',
            'name': 'SatoshiSwap',
            'address': '0xe0f7c8682F865B417AEB80BF237025b4cB5EbaEF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27298/thumb/7q2EUICG_400x400.jpeg?1663209691',
            'coingeckoId': 'satoshiswap-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CON',
            'name': 'Coin of Nature',
            'address': '0xe0e0Fbc7E8D881953d39CF899409410B50b8C924',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19834/thumb/logo_coinofnature-200.png?1635991553',
            'coingeckoId': 'coin-of-nature',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALTA',
            'name': 'Alta Finance',
            'address': '0xe0cCa86B254005889aC3a81e737f56a14f4A38F5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18713/thumb/AFN-token-Altafin-200.png?1633079552',
            'coingeckoId': 'alta-finance',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'CSWAP',
            'name': 'CrossSwap',
            'address': '0xe0b0C16038845BEd3fCf70304D3e167Df81ce225',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18002/thumb/cross.png?1630078540',
            'coingeckoId': 'crossswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CEEK',
            'name': 'CEEK Smart VR',
            'address': '0xe0F94Ac5462997D2BC57287Ac3a3aE4C31345D66',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0xe0F94Ac5462997D2BC57287Ac3a3aE4C31345D66.png',
            'coingeckoId': 'ceek',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'HMZ',
            'name': 'Harmonized App',
            'address': '0xe0F9052f7dB1868AcBB692f4f00C586Acd57b227',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20486/thumb/download_%2842%29.png?1637118790',
            'coingeckoId': 'harmomized-app',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MYK',
            'name': 'MyKingdom',
            'address': '0xe0C7227fec46bEA77d01DDf4CdDB680793FD9D54',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24676/thumb/IOgjEp44_400x400.jpg?1648550182',
            'coingeckoId': 'mykingdom',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TIPSY',
            'name': 'TipsyCoin',
            'address': '0xe097bcEb09bfb18047Cf259F321cC129b7bEba5e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24821/thumb/TipsyCoin-Icon.png?1649040735',
            'coingeckoId': 'tipsycoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZEROX',
            'name': 'ZeroX',
            'address': '0xe097454853f4df69dBfcf6Aeb8501A772f0D9218',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27319/thumb/Logo_200.png?1663316557',
            'coingeckoId': 'zerox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VPP',
            'name': 'Virtue Poker Points',
            'address': '0xe069Af87450fB51Fc0d0E044617f1C134163e591',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/3386/thumb/vp-logo-200x200.png?1622068750',
            'coingeckoId': 'virtue-poker',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MSI',
            'name': 'Martin Shkreli Inu',
            'address': '0xe05D1C28B3f8127b5B058F101198Ede30Fe3961d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26365/thumb/jEYEUxUI_400x400.jpeg?1657600080',
            'coingeckoId': 'martin-shkreli-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'aETHc',
            'name': 'Ankr Eth2 Reward Bearing Certificate',
            'address': '0xe05A08226c49b636ACf99c40Da8DC6aF83CE5bB3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1659434631109_3270742165656033.jpg',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'BSCD',
            'name': 'BSDium',
            'address': '0xe0387845F8289fD5875e7193064392e061f46E58',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24753/thumb/BSCD.png?1648787100',
            'coingeckoId': 'bsdium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRUX',
            'name': 'CryptoMines Reborn',
            'address': '0xe0191fEfdd0D2B39b1a2E4E029cCDA8A481b7995',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24538/small/Webp.net-resizeimage_%281%29.png?1648072028',
            'coingeckoId': 'cryptomines-reborn',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'VELON',
            'name': 'Viking Elon',
            'address': '0xe0167c41bEA56432f8588a4cEfF0f5f3642120e7',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25346/thumb/Viking_Elon_200.png?1651303559',
            'coingeckoId': 'viking-elon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MBR',
            'name': 'MetaBullRun',
            'address': '0xe0118b83775000A9a4DD2579746eE4C8C7d3d73C',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21590/thumb/bPtJEfOW_400x400.jpg?1639544127',
            'coingeckoId': 'metabullrun',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MUS',
            'name': 'Musashi Finance',
            'address': '0xdffdFd03A65A4F5a55F826C9f7a96579d7A1fD05',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17777/thumb/192092455_101594072164832_4485220528670171699_n.jpg?1629213298',
            'coingeckoId': 'mus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CERT',
            'name': 'CertRise',
            'address': '0xdf4CF10f245B3442098E6CE7A5B800F030897ffa',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20503/thumb/logo200.png?1637140308',
            'coingeckoId': 'certrise',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MXF',
            'name': 'Mixty Finance',
            'address': '0xdf065aa3a18Ae67055fF44bD26506fDe6CE13312',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14627/thumb/mixty_finance.jpg?1617342977',
            'coingeckoId': 'mixty-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUCK',
            'name': 'Luck2Earn',
            'address': '0xdf02FBbb5C7b9801df61c010A899D8157a4bbFfe',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26355/thumb/logo-200x200-1.png?1657592584',
            'coingeckoId': 'luck2earn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FACTR',
            'name': 'Defactor',
            'address': '0xdefac16715671b7b6aeeFE012125f1E19Ee4b7d7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19201/thumb/jFLSu4U9_400x400.png?1650437050',
            'coingeckoId': 'defactor',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CERBY',
            'name': 'Cerby Token',
            'address': '0xdef1fac7Bf08f173D286BbBDcBeeADe695129840',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xdef1fac7Bf08f173D286BbBDcBeeADe695129840/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'L3P',
            'name': 'Lepricon',
            'address': '0xdeF1da03061DDd2A5Ef6c59220C135dec623116d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14436/thumb/l3p.png?1636116065',
            'coingeckoId': 'lepricon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BITE',
            'name': 'DragonBite',
            'address': '0xde69C05E8121EF0db29C3D9Ceceda6EF6b606D0C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16386/thumb/dragonbite.PNG?1623893567',
            'coingeckoId': 'dragonbite',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFTB',
            'name': 'NFTb',
            'address': '0xde3dbBE30cfa9F437b293294d1fD64B26045C71A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0xde3dbbe30cfa9f437b293294d1fd64b26045c71a.png',
            'coingeckoId': 'nftb',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'LUS',
            'name': 'Luna Rush',
            'address': '0xde301D6a2569aEfcFe271B9d98f318BAee1D30a4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22295/thumb/16197.png?1641420414',
            'coingeckoId': 'luna-rush',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XODEX',
            'name': 'Xodex',
            'address': '0xddD9d7D280c78b564C55BEb26562E367d4b9Bd8a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25551/thumb/GVBRRXHM_400x400.jpg?1652402777',
            'coingeckoId': 'xodex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RAF',
            'name': 'Rafflet',
            'address': '0xdd84D5Cd7a1f6903A96CD4011f03bcad3335fBfa',
            'decimals': 12,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27765/thumb/IMG_20221013_135903_682.png?1665712472',
            'coingeckoId': 'rafflet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JELLYBEAN',
            'name': 'JellyBeanCash',
            'address': '0xdd531f921E78Fe90AB7c509FB4beb0CEB1A26244',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26605/thumb/jellybeancash.png?1658993350',
            'coingeckoId': 'jellybeancash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SQC',
            'name': 'Squoge Coin',
            'address': '0xdc95937F687F74f8A8a33bb79F5676914eF4B6F2',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21503/thumb/sq.png?1639373712',
            'coingeckoId': 'squoge-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BODAV2',
            'name': 'BODA',
            'address': '0xdc847755343C3A2B94d6aFC0aAE57651E1b14064',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19168/thumb/BODAV2-New-logo-round-200.png?1644477982',
            'coingeckoId': 'boda-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WTN',
            'name': 'WorkerTown',
            'address': '0xdc279ddC65Ea17382BbF9a141bb71550CdD587B3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24765/thumb/token.png?1648817039',
            'coingeckoId': 'workertown',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SST',
            'name': 'Soul Stone',
            'address': '0xdc0c4580aE2b8bF9Eee0760D57224AB215EF3d58',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26578/thumb/SST.png?1658887808',
            'coingeckoId': 'soul-stone',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ENSP',
            'name': 'Eternal Spire V2',
            'address': '0xdbD054baaE186f6712C18cFcE1298a2e15A76c1f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22650/thumb/Sl7FsoEx-Pmz3MLiP1uINdOiyRPpIuQGZySuJNge8VQ6W9WyfavJ5awApU9ZZXY9zomRa9YyGLMraG7EGnNnVsPLz2EXwgIh_DmNrA0p9oZcGcqEsu5ZdcH9fN8WnQbIOLgJWIlHoMNaxlmGCNzsxPVm9tD8--b-QiarGBtH2aSLArQpye_p9-sD5WZ1QvrqsCyFapwQWCGSto.jpg?1642399169',
            'coingeckoId': 'eternal-spire-v2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HONEY',
            'name': 'HONEYPAD',
            'address': '0xdb607c61Aaa2a954Bf1f9d117953F12d6c319E15',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18163/thumb/Logo-Honeypad-200x200.png?1630816297',
            'coingeckoId': 'honeypad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHG',
            'name': 'Cryptohub',
            'address': '0xdb1d5fD0A86E44Bf8a7C1A4AD2B76b14c7636AD7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26248/thumb/logo200.jpg?1656924386',
            'coingeckoId': 'cryptohub',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DFG',
            'name': 'DAO Farmer DFG',
            'address': '0xdae7a4aacbBCaf13E9A101A2bF376Fcb3931aD27',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24672/thumb/dfg.jpg?1648546250',
            'coingeckoId': 'dao-farmer-dfg',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PNT',
            'name': 'pNetwork',
            'address': '0xdaacB0Ab6Fb34d24E8a67BfA14BF4D95D4C7aF92',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xdaacB0Ab6Fb34d24E8a67BfA14BF4D95D4C7aF92/logo.png',
            'coingeckoId': 'pnetwork',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HSF',
            'name': 'Hillstone Finance',
            'address': '0xda8929a6338F408CC78c1845FB4F71BfFd2cFCfb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22335/thumb/logo_-_2022-01-07T094430.368.png?1641519883',
            'coingeckoId': 'hillstone',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LEO',
            'name': 'Leo',
            'address': '0xda38D8FacD3875DAAD437839308F1885646dfBb6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21531/thumb/FGZeCQhVUAIk5LE.png?1639386454',
            'coingeckoId': 'leo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TEN',
            'name': 'Tenet',
            'address': '0xdFF8cb622790b7F92686c722b02CaB55592f152C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xdFF8cb622790b7F92686c722b02CaB55592f152C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CINU',
            'name': 'CHEEMS INU',
            'address': '0xdFE6891ce8E5a5c7Cf54fFdE406A6C2C54145F71',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20924/thumb/Screenshot-2021-11-23-at-00-45-30.png?1637932412',
            'coingeckoId': 'cheems-inu',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'PRCY',
            'name': 'PRivaCY Coin',
            'address': '0xdFC3829b127761a3218bFceE7fc92e1232c9D116',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14151/thumb/prcy.png?1614666991',
            'coingeckoId': 'prcy-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MM72',
            'name': 'MM72',
            'address': '0xdF9e1A85dB4f985D5BB5644aD07d9D7EE5673B5E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25564/thumb/mm72.png?1652521419',
            'coingeckoId': 'mm72',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'GBD',
            'name': 'Great Bounty Dealer',
            'address': '0xdF7434d15656809F56Dd17371b2339bAaCd9b494',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19686/thumb/13606.png?1635746153',
            'coingeckoId': 'great-bounty-dealer',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INFO',
            'name': 'Infomatix',
            'address': '0xdF727040d3997b5D95deE8c661fA96E3c13eE0C9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19800/thumb/Logo_transparent_256.png?1635909082',
            'coingeckoId': 'infomatix',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MCT',
            'name': 'Metacraft',
            'address': '0xdF677713a2C661ECD0b2BD4d7485170Aa8c1ECeB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22332/thumb/F2iEsigu_400x400.jpg?1641515143',
            'coingeckoId': 'metacraft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIBSC',
            'name': 'SHIBA BSC',
            'address': '0xdF0816CC717216c8B0863aF8d4f0fC20Bc65d643',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15800/thumb/shibsc.png?1621929146',
            'coingeckoId': 'shiba-bsc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SBEAR',
            'name': 'yBEARSwap',
            'address': '0xdEC858d5ee93568CE4eA5bbf9169ceA23d2dE305',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16188/thumb/LOGO_YBEARSWAP_SBEAR-Circle.png?1623250874',
            'coingeckoId': 'yeabrswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CAN',
            'name': 'Channels',
            'address': '0xdE9a73272BC2F28189CE3c243e36FaFDA2485212',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14093/thumb/KpSUqAH.png?1614229582',
            'coingeckoId': 'channels',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABYSHIB',
            'name': 'BabyShibby Inu',
            'address': '0xdE87b96FB2b0B6b492Dc1C9B228a7C71FfBA40FB',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17004/thumb/iG11kku.png?1626062376',
            'coingeckoId': 'babyshibby-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DLC',
            'name': 'Diamond Launch',
            'address': '0xdE83180dD1166D4f8E5c2b7De14A2163b1Bb4a87',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27323/thumb/dlc.png?1663320489',
            'coingeckoId': 'diamond-launch',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ICMC',
            'name': 'IcoCryptoMarketCap',
            'address': '0xdE4723394D3552A78AA6D525Ef15488b6eE8E845',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27984/thumb/32d7333f294248fb89774ad2939fed64_%281%29.jpeg?1666862918',
            'coingeckoId': 'icocryptomarketcap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VIST',
            'name': 'VIST',
            'address': '0xdE0DB064caF3f5018c1f758334740d8e078644B3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27005/thumb/Logo1-margin-375.png?1661337554',
            'coingeckoId': 'vist',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUD',
            'name': 'Little Ugly Duck',
            'address': '0xdE009cB3371825bAfb80A01004C58f8166EE13D5',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17764/thumb/whitelogo200x200.png?1629203328',
            'coingeckoId': 'little-ugly-duck',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MEVR',
            'name': 'Metaverse VR',
            'address': '0xdDe3ed0bB77C1CafABF8B38F9a1E81EdDdC7DDc9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23437/thumb/cmc3.png?1656560704',
            'coingeckoId': 'metaverse-vr',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BCA',
            'name': 'Bitcoiva',
            'address': '0xdDaE5F343b7768eAdaAD88A7f520fFf54F198211',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13016/thumb/Untitled-design-6-removebg-preview.png?1604389289',
            'coingeckoId': 'bitcoiva',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOOGEE',
            'name': 'Doogee',
            'address': '0xdDa82F136576AE87d2702BB05370aF274Ce71dd5',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17097/thumb/logo200x200_%287%29.png?1626227554',
            'coingeckoId': 'doogee',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QI',
            'name': 'QiDao',
            'address': '0xdDC3D26BAA9D2d979F5E2e42515478bf18F354D5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0xddc3d26baa9d2d979f5e2e42515478bf18f354d5/c0524dfb9be687e8e26191eed8774fd3.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'JAWS',
            'name': 'AutoShark',
            'address': '0xdD97AB35e3C0820215bc85a395e13671d84CCBa2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16335/thumb/osP9madX_400x400.jpg?1623750298',
            'coingeckoId': 'autoshark',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BITV',
            'name': 'BitValley',
            'address': '0xdD8B490001D081eD065239644dae8D1a77b8A91F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22825/thumb/Screenshot-2022-01-19-at-17-00-20.png?1642663544',
            'coingeckoId': 'bitvalley',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWLO',
            'name': 'Swello',
            'address': '0xdD6C57f8403aEF4bd9282c81C0d1887222e86710',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26032/thumb/Swello_Logo.jpg?1655350373',
            'coingeckoId': 'swello',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MSWAP',
            'name': 'MoneySwap',
            'address': '0xdD5a149740c055bdcdC5C066888f739DBe0bf2d0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13576/thumb/logo_%281%29.png?1609888424',
            'coingeckoId': 'moneyswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METAZ',
            'name': 'MetaLandz',
            'address': '0xdD26a25F872D87dA9F0de9652085BcC8c1923dDC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24206/thumb/IMG-20220305-172915-050.jpg?1646836470',
            'coingeckoId': 'metalandz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADAT',
            'name': 'Adadex Tools',
            'address': '0xdCe6380B9D2D8beAC66B0fba2604c51519d24d77',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18702/thumb/cropped-adex-250.png?1633051556',
            'coingeckoId': 'adadex-tools',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ROY',
            'name': 'Royal Protocol',
            'address': '0xdCE27D41b4Ff72B55a876c621f5A5Fef8537c99D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15339/thumb/royal_protocol.jpg?1629087993',
            'coingeckoId': 'royal-protocol',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'TOI',
            'name': 'Toksi',
            'address': '0xdCC486e492e1C7FD7454f6f2f7183533545FD66C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27284/thumb/Group_12429.png?1663140720',
            'coingeckoId': 'toksi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SFN',
            'name': 'Strains',
            'address': '0xdCA8a5bb74Ec9536D13511C51f6A68821Bf6a1a6',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15507/thumb/WTWLlrKv_400x400.jpg?1621096693',
            'coingeckoId': 'strains',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFECOOKIE',
            'name': 'SafeCookie',
            'address': '0xdC555F1D9A66D4934687c8469888b736c65b4BF8',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15415/thumb/LJXbdphy_400x400.jpg?1620773680',
            'coingeckoId': 'safecookie',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WGR',
            'name': 'Wagerr',
            'address': '0xdBf8265B1d5244A13424f13977723AcF5395eAB2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/759/thumb/syGKmAT.png?1619597241',
            'coingeckoId': 'wagerr',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FM',
            'name': 'Follow ME',
            'address': '0xdBf3e320b9bAa9e2Bd2D3268D82DD99812B30662',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0xdbf3e320b9baa9e2bd2d3268d82dd99812b30662/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'NOSNITCH',
            'name': 'Nosnitches',
            'address': '0xdBe3Ba37d37DF906Ce8d5FfCD6fF37dc09bFc400',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25338/thumb/NoSnitch_200.png?1651300208',
            'coingeckoId': 'nosnitches',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARTK',
            'name': 'Artik',
            'address': '0xdBE373d9374cEe42cDd40754c71cD3f5eDed4Da7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25230/thumb/logo_200x200.png?1650951872',
            'coingeckoId': 'artik',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CANU',
            'name': 'Cannumo',
            'address': '0xdBB66Eb9f4D737B49aE5CD4De25E6C8da8B034f9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17340/thumb/canu.PNG?1627356656',
            'coingeckoId': 'cannumo',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'DTEP',
            'name': 'Decoin',
            'address': '0xdB9E0580B5370A05217aa99a6Fab4088eAb5074D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/3896/thumb/dtep_logo.png?1638264877',
            'coingeckoId': 'decoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABYCAKE',
            'name': 'Baby Cake',
            'address': '0xdB8D30b74bf098aF214e862C90E647bbB1fcC58c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xdB8D30b74bf098aF214e862C90E647bbB1fcC58c/logo.png',
            'coingeckoId': 'baby-cake',
            'listedIn': [
                'coingecko',
                'pancake',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'TKRN',
            'name': 'Token Runner',
            'address': '0xdB5f00592463A3F459f418eb831e4d2C4DB8E156',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26848/thumb/20220812_091410.jpg?1660463693',
            'coingeckoId': 'token-runner',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PEACHPYE',
            'name': 'PEACHPYE',
            'address': '0xdB3aBa37F0F0C0e8233FFB862FbaD2F725cdE989',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26697/thumb/PeachPYE.png?1659608819',
            'coingeckoId': 'peachpye',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNFT',
            'name': 'Ultra NFT',
            'address': '0xdB29192Fc2B487BB5185e155752328d4f249743C',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15506/thumb/VkZQUF7q_400x400.jpg?1621096185',
            'coingeckoId': 'ultra-nft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TASTE',
            'name': 'TasteNFT',
            'address': '0xdB238123939637D65a03E4b2b485650B4f9D91CB',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15532/thumb/3C1Qq1yf_400x400.jpg?1621138873',
            'coingeckoId': 'tastenft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MFT',
            'name': 'My Farm Token',
            'address': '0xdB1A277E6e0C2cc1EbA6f340de1AdDF19C9971f3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27122/thumb/logo.png?1662017441',
            'coingeckoId': 'my-farm-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KHALIFA',
            'name': 'Khalifa Finance',
            'address': '0xdAe7238A7e83b439673A7900FA3BAe2108C6ec12',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18944/thumb/khalifa.png?1635737585',
            'coingeckoId': 'khalifa-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZBTX',
            'name': 'ZiobitX',
            'address': '0xdA8e2D1a78Cf558eCed181C139317D3a4c3ea920',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27135/thumb/ziobitx_logo_%281%29.png?1662026034',
            'coingeckoId': 'ziobitx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AITN',
            'name': 'Artificial Intelligence Technology Netw',
            'address': '0xdA3d20e21caEB1Cf6dD84370aA0325087326F07A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26270/thumb/aitn-200.png?1656989595',
            'coingeckoId': 'artificial-intelligence-technology-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BINGUS',
            'name': 'Bingus Token',
            'address': '0xdA20C8a5c3B1AB48e31ba6e43f0F2830E50218D8',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xda20c8a5c3b1ab48e31ba6e43f0f2830e50218d8.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MTWR',
            'name': 'MetaWar Token',
            'address': '0xd9fC762E2D1899aAD7233De46FF9A36D034c4FFB',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27484/thumb/metawar_200.png?1664250236',
            'coingeckoId': 'metawar-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MILO',
            'name': 'Milo Inu',
            'address': '0xd9dE2B1973E57Dc9DBA90c35d6Cd940ae4A3CBe1',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23896/thumb/milo.png?1645719021',
            'coingeckoId': 'milo-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ULX',
            'name': 'ULTRON',
            'address': '0xd983AB71a284d6371908420d8Ac6407ca943F810',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26977/thumb/ULX.png?1661168951',
            'coingeckoId': 'ultron',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TGR',
            'name': 'Tegro',
            'address': '0xd9780513292477C4039dFdA1cfCD89Ff111e9DA5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26564/thumb/200x200.png?1658800017',
            'coingeckoId': 'tegro',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ATBFIG',
            'name': 'Financial Intelligence Group',
            'address': '0xd91E5e735EAcCAa569Edab1fa4F8fe2db2531A19',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18802/thumb/output-onlinepngtools_%289%29.png?1633473650',
            'coingeckoId': 'financial-intelligence-group-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CBT',
            'name': 'CryptoBattles',
            'address': '0xd9150910de9A4df5D734f4d9B5ebe82BCCe9095a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0xd9150910de9a4df5d734f4d9b5ebe82bcce9095a/logo.png',
            'coingeckoId': 'cryptobattles',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'GRT',
            'name': 'Retawars GoldRose Token',
            'address': '0xd911375E7E9582c70fC98b1C6C2fBaad86f457ef',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27648/thumb/GRT_200.png?1665025536',
            'coingeckoId': 'retawars-goldrose-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CYT',
            'name': 'Coinary',
            'address': '0xd9025e25Bb6cF39f8c926A704039D2DD51088063',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17622/thumb/CYT-LOGO-1.png?1628669366',
            'coingeckoId': 'coinary-token',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ATMUP',
            'name': 'AutoMaticUp',
            'address': '0xd8e4F8b3adc0Dc6ccD90efe8b1eD706D398F81bc',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19866/thumb/atmup.jpeg?1636079855',
            'coingeckoId': 'automaticup',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RFL',
            'name': 'Run For Life',
            'address': '0xd8b80a966b22Af8B857A10FF217B00a0c630d1DC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26278/thumb/200x200.png?1657017221',
            'coingeckoId': 'run-for-life',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AIR',
            'name': 'AirCoin',
            'address': '0xd8a2aE43Fd061d24acd538e3866Ffc2c05151b53',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16878/thumb/logo200.png?1625755457',
            'coingeckoId': 'aircoin-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NPX',
            'name': 'Napoleon X',
            'address': '0xd8CB4C2369db13C94c90C7fD3bEbc9757900Ee6b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/1471/thumb/napoleon-x.jpg?1547035570',
            'coingeckoId': 'napoleon-x',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'WENLAMBO',
            'name': 'WenLambo',
            'address': '0xd8A31016cD7da048ca21FFE04256C6d08C3A2251',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15626/small/wenlambo.jpg?1621351104',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'DOGEDEAD',
            'name': 'DogeDead',
            'address': '0xd890fEA1da0348D3981aF8Ae200Fa575068F3846',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23801/thumb/23F00458-004A-46B0-ADBC-FECE786B1FAD.jpeg?1645448804',
            'coingeckoId': 'dogedead',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XWIN',
            'name': 'xWIN Finance',
            'address': '0xd88ca08d8eec1E9E09562213Ae83A7853ebB5d28',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xd88ca08d8eec1e9e09562213ae83a7853ebb5d28.png',
            'coingeckoId': 'xwin-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'FOUR',
            'name': '4thpillar technologies',
            'address': '0xd882739Fca9CBAE00F3821c4c65189E2D7e26147',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/3434/thumb/four-ticker-2021-256x256.png?1617702287',
            'coingeckoId': 'the-4th-pillar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HOLDEX',
            'name': 'Holdex Finance',
            'address': '0xd85b5e9a7C33832dcDB554EC07cdcC56810B2e5A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18703/thumb/orgs_-MggF6rU6Dj6NgtABR78_avatar-1628535033755.png?1633053857',
            'coingeckoId': 'holdex-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SEADOG',
            'name': 'Seadog Metaverse',
            'address': '0xd827036B0173F204C57b26532a339B57D4d5AEA6',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19617/thumb/sead.PNG?1635495288',
            'coingeckoId': 'seadog-metaverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AMZ',
            'name': 'AmazonasCoin',
            'address': '0xd81A6c33f5619dEDd200DbcaF8E7AD8Af5Ad75b5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/11830/thumb/67655540_2134166093379460_7734829864909275136_o.png?1594764535',
            'coingeckoId': 'amazonacoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CLK',
            'name': 'Click',
            'address': '0xd80E4aBAFFddA331eBb7Dea2AA283E909CBBEDF3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26447/thumb/logo-200x200.png?1658111418',
            'coingeckoId': 'click',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPOX',
            'name': 'Spotrax',
            'address': '0xd7fBB6458845C7622d5343c6FB26aefbc65def90',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28002/thumb/spox_logo.png?1666927596',
            'coingeckoId': 'spotrax',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WEGRO',
            'name': 'WeGro',
            'address': '0xd7c5d2A3B7868E6Dd539e145C98a565f29ef3FA4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21675/thumb/wegrocoin_logo_favicon.png?1639711396',
            'coingeckoId': 'wegro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'O5O',
            'name': 'O5O',
            'address': '0xd79aC202089BD317c8B8aa3621cAf5c1cf6C6Ba6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27245/thumb/LOGO_JPG-removebg-preview.png?1662884680',
            'coingeckoId': 'o5o',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MARSDOGE',
            'name': 'Mars Doge',
            'address': '0xd76f84a1D7B9628280f9e9C6910866CECDc7f3A5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20892/thumb/Logo_Mars_Doge-200x200-1.png?1637846239',
            'coingeckoId': 'mars-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WTF',
            'name': 'Waterfall Governance',
            'address': '0xd73F32833B6D5D9c8070c23e599e283a3039823C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19189/thumb/wtf_icon_avatar_200x200.png?1634631004',
            'coingeckoId': 'waterfall-governance-token',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MDA',
            'name': 'Moeda Loyalty Points',
            'address': '0xd72aA9e1cDDC2F6D6e0444580002170fbA1f8eED',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xd72aa9e1cddc2f6d6e0444580002170fba1f8eed.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'STRX',
            'name': 'StrikeX',
            'address': '0xd6fDDe76B8C1C45B33790cc8751D5b88984c44ec',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15839/thumb/StrikeX-Icon-200px.png?1635161631',
            'coingeckoId': 'strikecoin',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'LNDLRD',
            'name': 'The Landlord',
            'address': '0xd6dA6491A6663B1d413715f4fD8eDD74a4b47694',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25342/thumb/LANDLORD_200.png?1651302458',
            'coingeckoId': 'the-landlord',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TKNRM',
            'name': 'Tokenarium',
            'address': '0xd6F97d97EdfA1A4ea8bE15714386455B19A67524',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25674/thumb/tokenarium.jpeg?1653364467',
            'coingeckoId': 'tokenarium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPELLFIRE',
            'name': 'Spellfire',
            'address': '0xd6F28f15A5CaFc8d29556393c08177124B88de0D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23107/thumb/17316.png?1643261163',
            'coingeckoId': 'spellfire',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CBITBOX',
            'name': 'Bitbox Classic',
            'address': '0xd6Dbc196D3faC82f24D0B6e73EB13938FAAc068C',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26438/thumb/LOGOOOOBITB0X.jpg?1658108016',
            'coingeckoId': 'bitbox-classic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DGP',
            'name': 'Digipad',
            'address': '0xd6BD902c588524F0878716BaAC0f53066d4e078C',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26850/thumb/200x200.png?1660467303',
            'coingeckoId': 'digipad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CSW',
            'name': 'Chrome Swap',
            'address': '0xd6A2397ca676faEBfcbC9CFe7d7cBE64e2a8D949',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26982/thumb/Safeimagekit-CSW_logopng.png?1661178675',
            'coingeckoId': 'chrome-swap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EGC',
            'name': 'Egoras Credit',
            'address': '0xd68e5C52F7563486CC1A15D00eFA12C8644a907e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20987/thumb/RSGoYMG.png?1638169232',
            'coingeckoId': 'egoras-credit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COPYCAT',
            'name': 'Copycat Finance',
            'address': '0xd635B32688F36ee4a7FE117b4C91DD811277ACB6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18276/thumb/copycat.PNG?1631233811',
            'coingeckoId': 'copycat-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KANGAL',
            'name': 'Kangal',
            'address': '0xd632Bd021a07AF70592CE1E18717Ab9aA126DECB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xd632bd021a07af70592ce1e18717ab9aa126decb.png',
            'coingeckoId': 'kangal',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'LOVE',
            'name': 'LovePot',
            'address': '0xd631d33F2c3f38d9ABDaE08ebC0B69fA636E8ec2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19567/thumb/13433.png?1635422764',
            'coingeckoId': 'lovepot-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLOS',
            'name': 'Blockius',
            'address': '0xd5e950837Ad48D08baD2f87bFcF8eD7167bB44BC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21889/thumb/W51smE8.png?1640227088',
            'coingeckoId': 'blockpad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FIRO',
            'name': 'Binance Peg Firo',
            'address': '0xd5d0322b6bAb6a762C79f8c81A0B674778E13aeD',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20128/thumb/firocoingecko.png?1636537460',
            'coingeckoId': 'binance-peg-firo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SUNI',
            'name': 'StarbaseUniverse',
            'address': '0xd5E3E1e39a5c57F27f8890D8B91E05039F6BAcE7',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17612/thumb/1VxZJ7.png?1628659075',
            'coingeckoId': 'starbaseuniverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TDLC',
            'name': 'The Davis Legacy Coin',
            'address': '0xd5D5e9FE07fdbCD935807C472deD65C254E8EB98',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25344/thumb/TDLC_200.png?1651303154',
            'coingeckoId': 'the-davis-legacy-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NEBULA',
            'name': 'NebulaToken',
            'address': '0xd59Af4e5a8C8D3E091d300F5416e7eF7ff28d875',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20908/thumb/rLvxXxbk_400x400.jpg?1637892653',
            'coingeckoId': 'nebulatoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HK',
            'name': 'Helkin',
            'address': '0xd55E4fCEA77fd5902Ad10914F8B2a5ad1ee606B5',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19316/thumb/12960.png?1635027214',
            'coingeckoId': 'helkin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNIC',
            'name': 'Unicorn Cake',
            'address': '0xd54ADD3414f57c47Cd8103cC588B7c1e36e53eB2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19973/thumb/unic.png?1636348997',
            'coingeckoId': 'unicorn-cake',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XCUR',
            'name': 'Curate',
            'address': '0xd52669712f253CD6b2Fe8A8638F66ed726cb770C',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13327/thumb/400x400_%281%29_%283%29_%282%29.png?1613998208',
            'coingeckoId': 'curate',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'SWAPZ',
            'name': 'SWAPZ app',
            'address': '0xd522A1DcE1CA4B138DDA042a78672307eb124CC2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16811/thumb/EWV5xQB.jpeg?1625147828',
            'coingeckoId': 'swapz-app',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FRT',
            'name': 'Fertilizer',
            'address': '0xd51237A6F3219d186f0C8d8Dd957b1Bcb3Ce5d48',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20173/thumb/Untitled-design-7-removebg-preview-2.png?1637155311',
            'coingeckoId': 'fertilizer',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MINISPORTZ',
            'name': 'MiniSportZilla',
            'address': '0xd506EE6A2bb483a953EB311e9b7E5719211702D3',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20770/thumb/zilla.PNG?1637658389',
            'coingeckoId': 'minisportzilla',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOD',
            'name': 'Modefi',
            'address': '0xd4fBc57B6233F268E7FbA3b66E62719D74deecBc',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xd4fbc57b6233f268e7fba3b66e62719d74deecbc.png',
            'coingeckoId': 'modefi',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MAG',
            'name': 'Monsterra MAG',
            'address': '0xd4c73fd18f732BC6EE9FB193D109B2eed815Df80',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27152/thumb/MAG.png?1662203851',
            'coingeckoId': 'monsterra-mag',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAT',
            'name': 'Monster Adventure',
            'address': '0xd4CC9538bbd3eda848da2292c0e383835703f138',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21227/thumb/photo-2021-12-02-10-09-21.png?1638754522',
            'coingeckoId': 'monster-adventure-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CREAM',
            'name': 'Cream',
            'address': '0xd4CB328A82bDf5f03eB737f37Fa6B370aef3e888',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xd4CB328A82bDf5f03eB737f37Fa6B370aef3e888/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'STAR',
            'name': 'Astralis',
            'address': '0xd4C77E2B344659c9D42291A788Fe5aefa71148fB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23376/thumb/astralis.png?1643959431',
            'coingeckoId': 'astralis',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RVM',
            'name': 'RavenMoon',
            'address': '0xd4B79E6EDf45bf655cDccD8a7FcdEe1aaC99F0a9',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27044/thumb/logo_200.png?1661503093',
            'coingeckoId': 'ravenmoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PROT',
            'name': 'ArmzLegends',
            'address': '0xd3c9609B6CBC6eF02390F33C230590c38F9E5f9D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1636450456526_8401839757065861.jpeg',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'DVILLE',
            'name': 'DogeVille',
            'address': '0xd3b6bFd18b34AE0E3165738bf66eBC64Cad1B944',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20961/thumb/unnamed.jpg?1638150132',
            'coingeckoId': 'dogeville',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GMC',
            'name': 'GokuMarket Credit',
            'address': '0xd3E7C41b39Bb252d909Fb64D2433Abf225Eaf49E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12561/thumb/GMC-favicon-Asset-200x200.png?1601623779',
            'coingeckoId': 'gokumarket-credit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DTUBE',
            'name': 'Dtube Coin',
            'address': '0xd3Cceb42B544e91eee02EB585cc9a7b47247BfDE',
            'decimals': 2,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13126/thumb/dtube_logo.png?1605500467',
            'coingeckoId': 'dtube-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MFTU',
            'name': 'Mainstream For The Underground',
            'address': '0xd399359683c1cD5267f611261eDE08F22cE9729F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0xd399359683c1cd5267f611261ede08f22ce9729f/0fa0c126f8fba0834fa1ce3ccc619a04.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'XGC',
            'name': 'Xiglute Coin',
            'address': '0xd37EF7df703B6f8eeC56CD4E082Bb3e899e41F2B',
            'decimals': 14,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15240/thumb/256x256_%282%29.png?1620196016',
            'coingeckoId': 'xiglute-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AIMX',
            'name': 'Aimedis',
            'address': '0xd376F64bb7db90E11e78C91cbd58b756e1B8E7a1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21316/thumb/Logo_-_2021-12-08T091940.073.png?1638926411',
            'coingeckoId': 'aimedis-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NMX',
            'name': 'Nominex',
            'address': '0xd32d01A43c869EdcD1117C640fBDcfCFD97d9d65',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xd32d01a43c869edcd1117c640fbdcfcfd97d9d65.png',
            'coingeckoId': 'nominex',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'GNFT',
            'name': 'GameNFT',
            'address': '0xd32af8BeE4095A84e258a82B512356dFC0A7D2C0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18044/thumb/game_nft.PNG?1630318047',
            'coingeckoId': 'gamenft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SANDWICH',
            'name': 'Sandwich Network',
            'address': '0xd3253Fc0A42E6dcF4F66Ab147f628E3F29e9b214',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23562/thumb/Sandwich_Token_Logo.png?1644474250',
            'coingeckoId': 'sandwich-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PLI',
            'name': 'Planet infinity',
            'address': '0xd30D754bDD1b9E29ebEEF98dD5F6Cb377888757C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26870/thumb/PlanetinfinityLogo200x200.png?1660538552',
            'coingeckoId': 'planet-infinity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WAL',
            'name': 'The Wasted Lands',
            'address': '0xd306c124282880858a634E7396383aE58d37c79c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24273/thumb/Coin_WAL_%281%29.png?1647178350',
            'coingeckoId': 'the-wasted-lands',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INFT',
            'name': 'iNFT',
            'address': '0xd2fF8c018A22Ff06F4BE781090579d0490B9A69f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18716/thumb/inft.PNG?1633089426',
            'coingeckoId': 'inft-platform',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SQUIRT',
            'name': 'Squirt Game',
            'address': '0xd2d7289DB68395593D65101753Fec9450ddFB699',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20887/thumb/squirtgame-logo-200x200.png?1637843619',
            'coingeckoId': 'squirt-game',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLK',
            'name': 'BlackCoin',
            'address': '0xd2cDfD5d26dfA1D11116B9ED7DBd7C6B88C6e1D3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/50/thumb/blackcoin.png?1547274445',
            'coingeckoId': 'blackcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UBU',
            'name': 'UBU Finance',
            'address': '0xd2DdFba7bb12f6e70c2AAB6B6bf9EdaEf42ed22F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xd2ddfba7bb12f6e70c2aab6b6bf9edaef42ed22f.png',
            'coingeckoId': 'ubu-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MOONSHOT',
            'name': 'Moonshot  OLD ',
            'address': '0xd27D3F7f329D93d897612E413F207A4dbe8bF799',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15221/thumb/JOmzmvag_400x400.jpg?1620120945',
            'coingeckoId': 'moonshot',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XTAL',
            'name': 'XTAL',
            'address': '0xd23eae3926431C794e2a04e1622A9446D61174A4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26351/thumb/XTAL-LOGO.png?1657590133',
            'coingeckoId': 'xtal',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NECO',
            'name': 'Neco Fun',
            'address': '0xd23891FC1A515A88C571064637502e3766819e2d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26394/thumb/new_neco_logo.png?1657753487',
            'coingeckoId': 'neco-fun',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GGT',
            'name': 'GameGuru',
            'address': '0xd2359c576632234D1354B20Bf51B0277Be20C81e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28125/thumb/200-icon.png?1667638365',
            'coingeckoId': 'gameguru',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BCP',
            'name': 'BitcoinPlatinums',
            'address': '0xd21d5467C341dC697B384cf4e4be56394CAE5D0B',
            'decimals': 8,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PRQ',
            'name': 'PARSIQ',
            'address': '0xd21d29B38374528675C34936bf7d5Dd693D2a577',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/11973/thumb/DsNgK0O.png?1596590280',
            'coingeckoId': 'parsiq',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'STYL',
            'name': 'Stylike Governance',
            'address': '0xd1e756A5868FCf56A01bEFc41a8163A6b4f67f43',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27368/thumb/icon-coin.png?1663721812',
            'coingeckoId': 'stylike-governance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FMC',
            'name': 'FidoMeta',
            'address': '0xd1baaED91361CEa01a2D9be0Ca8988756163971a',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25835/thumb/fmc.png?1668687351',
            'coingeckoId': 'fidometa',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GART',
            'name': 'Griffin Art',
            'address': '0xd1ECFDD45c49057479c4Ca95E045855370bC4F5B',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17504/thumb/gart.png?1647695407',
            'coingeckoId': 'griffin-art',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BPANDA',
            'name': 'Baby Panda',
            'address': '0xd1D613Cd9c385d3b50bFEC187f19638e91772Ae6',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22511/thumb/IMG-20220111-182208-149.jpg?1641965734',
            'coingeckoId': 'baby-panda',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDD',
            'name': 'USDD',
            'address': '0xd17479997F34dd9156Deef8F95A52D81D265be9c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xd17479997f34dd9156deef8f95a52d81d265be9c.png',
            'coingeckoId': 'usdd',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'COVAL',
            'name': 'Circuits of Value',
            'address': '0xd15CeE1DEaFBad6C0B3Fd7489677Cc102B141464',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/588/thumb/coval-logo.png?1599493950',
            'coingeckoId': 'circuits-of-value',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TENFI',
            'name': 'TEN',
            'address': '0xd15C444F1199Ae72795eba15E8C1db44E47abF62',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15631/thumb/TEN-Square200.png?1652945159',
            'coingeckoId': 'ten',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RASKO',
            'name': 'rASKO',
            'address': '0xd118f42eDbc839F7e1E85d5269A25288792c141B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20977/thumb/rASKO.png?1662435622',
            'coingeckoId': 'rasko',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GHC',
            'name': 'GalaxyHeroes',
            'address': '0xd10Fe9d371C22416df34340287cd5D9029842Fc3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0xd10fe9d371c22416df34340287cd5d9029842fc3/2a9582a4ebd32ae68a53f4e0043d0d2f.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MTVX',
            'name': 'MTVX',
            'address': '0xd10672A959CE3BB8939827391fe78762F518f740',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20798/thumb/mtvx-logo.png?1637678108',
            'coingeckoId': 'mtvx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MRHB',
            'name': 'MarhabaDeFi',
            'address': '0xd10332818D6A9B4b84bf5d87DbF9D80012FDF913',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18613/thumb/MRHB_DeFi-02.png?1632670864',
            'coingeckoId': 'marhabadefi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOLD',
            'name': 'GoldFarm',
            'address': '0xd0f653aD03ca32A792AbFA6d6711cdf1311e5d9D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16108/thumb/rVUV_photo_2021-06-03_13-37-31.png?1623020895',
            'coingeckoId': 'goldfarm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SRBP',
            'name': 'Super Rare Ball Potion',
            'address': '0xd0e98827d675A3231C2Ea69d1f3eD12270df1435',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25825/thumb/6295ae88b46cd60001d5ac25_SRBS_160.png?1654063925',
            'coingeckoId': 'super-rare-ball-shares',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WEB3',
            'name': 'WEB3 Inu',
            'address': '0xd0c4BC1B89BbD105EeCb7EBa3f13E7648c0De38F',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22278/thumb/logo_256px.png?1641398067',
            'coingeckoId': 'web3-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WAIFU',
            'name': 'Waifu',
            'address': '0xd0aa796e2160ED260c668E90aC5f237b4eBd4b0d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28181/thumb/200.png?1668254108',
            'coingeckoId': 'waifu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MYPO',
            'name': 'MyPoints E Commerce',
            'address': '0xd0BA1Cad35341ACd1CD88a85E16B054bA9ccC385',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28131/thumb/mypologo1.png?1667716191',
            'coingeckoId': 'mypoints-e-commerce',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PINE',
            'name': 'Atrollcity',
            'address': '0xd099990Bc62b3CAf38F0F20B280BB400F67e92d0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21253/thumb/PINELOGO.png?1638775462',
            'coingeckoId': 'atrollcity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AFEN',
            'name': 'AFEN Blockchain',
            'address': '0xd0840D5F67206f865AeE7cCE075bd4484cD3cC81',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14957/thumb/cropped-cropped-afen-e1616095076861.png?1619144008',
            'coingeckoId': 'afen-blockchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PRL',
            'name': 'The Parallel',
            'address': '0xd07e82440A395f3F3551b42dA9210CD1Ef4f8B24',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0xd07e82440A395f3F3551b42dA9210CD1Ef4f8B24.png',
            'coingeckoId': 'the-parallel',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'ROCKET',
            'name': 'RocketCoin',
            'address': '0xd051B29c5cb6c19532529d7544Dd0718dD40Ecc8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21108/thumb/Screenshot-2021-12-01-at-03-39-43.png?1638330515',
            'coingeckoId': 'rocketcoin-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BRK',
            'name': 'BlueArk',
            'address': '0xd045D776d894eC6e8b685DBEf196527ea8720BaB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22747/thumb/_vFAaJq__400x400.jpg?1642554854',
            'coingeckoId': 'blueark',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INET',
            'name': 'Ideanet',
            'address': '0xd031d7a1B9C80F2C6122A9FD76E3c53A1bc404f6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19032/thumb/ideanet-logo-200x200.png?1634196471',
            'coingeckoId': 'ideanet-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SMG',
            'name': 'SMG',
            'address': '0xd01aDc16CE42D678800B3fE67E1F053433d8e9F7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23612/thumb/smg.png?1644753960',
            'coingeckoId': 'smg',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOUL',
            'name': 'HELL HOUNDS',
            'address': '0xd01Bc67a6d82A4e07E505d198A55316EC01684A8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21919/thumb/HELLHOUND-LOGO-LARGE.png?1640266236',
            'coingeckoId': 'hell-hounds',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AF-PRESALEDAO',
            'name': 'PresaleDAO',
            'address': '0xd0141096DA823f0663873305FA34Ce4E1b72a1aD',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22596/thumb/PresaleDAO-Logo.png?1642145517',
            'coingeckoId': 'presaledao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADS',
            'name': 'Adshares',
            'address': '0xcfcEcFe2bD2FED07A9145222E8a7ad9Cf1Ccd22A',
            'decimals': 11,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xcfcecfe2bd2fed07a9145222e8a7ad9cf1ccd22a.png',
            'coingeckoId': 'adshares',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'WOFO',
            'name': 'World Football',
            'address': '0xcfae931A56aBa505893f2A7681CBB07799078054',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26703/thumb/logo.png?1659663251',
            'coingeckoId': 'world-football',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TTI',
            'name': 'Tiara',
            'address': '0xcfC1fF52a63180CC726E5795F9339b6f7e617485',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22540/thumb/dOMv0Rg2_400x400.jpg?1642029718',
            'coingeckoId': 'tiara',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HEROEGG',
            'name': 'HeroFi',
            'address': '0xcfBb1BfA710cb2ebA070CC3beC0C35226FeA4BAF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18249/thumb/herofi.png?1631703069',
            'coingeckoId': 'herofi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATLAS',
            'name': 'Atlantis',
            'address': '0xcf87Ccf958d728f50d8ae5E4f15Bc4cA5733cDf5',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20922/thumb/15211.png?1637926873',
            'coingeckoId': 'atlantis',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KING',
            'name': 'DChess King',
            'address': '0xcf2D2CE89AeD0073540C497fcF894Ea22d37C7aF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23706/thumb/king.png?1645082337',
            'coingeckoId': 'dchess-king',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TORG',
            'name': 'TORG',
            'address': '0xcea59DCE6A6d73a24e6d6944CFaBc330814c098A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17596/thumb/TORG_Logo_200x200.png?1628586056',
            'coingeckoId': 'torg',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STRANGER',
            'name': 'Strangercoin',
            'address': '0xceE4579f115aC80731A2412121E119985020d9E0',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0xcee4579f115ac80731a2412121e119985020d9e0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FCT',
            'name': 'FuturesCash',
            'address': '0xce7709B026ED17B25fEDF64b6A4c48F23580c429',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21094/thumb/FCT.png?1638323787',
            'coingeckoId': 'futurescash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZFM',
            'name': 'ZFMCOIN',
            'address': '0xce6b8B2787C657f1b98b7A66B5B63178863fd719',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28232/thumb/20221106_193725.png?1668574239',
            'coingeckoId': 'zfmcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNIF',
            'name': 'Unified',
            'address': '0xce5347fdd503f25f8428151A274544A5bD1Bd8cA',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xce5347fdd503f25f8428151a274544a5bd1bd8ca.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'VRBLOCKS',
            'name': 'VR Blocks',
            'address': '0xce42b6cdF5FAf84C6C3923a8c6c2324031f7692b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25299/thumb/IMG-20220426-151515-882.jpg?1651149708',
            'coingeckoId': 'vr-blocks',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MORTALDOGE',
            'name': 'MortalDoge',
            'address': '0xcdCBfc7902235240172a3e891dAe9044C8568453',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26898/thumb/LOGO.png?1660722917',
            'coingeckoId': 'mortaldoge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SEFI',
            'name': 'Secret Finance',
            'address': '0xcd95350c69F229E72e57A44e8C05C436E65E4bEb',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14601/thumb/_Icon___SVG__Primary_-_Metallic_Gold_in_Circle_%282%29.png?1635066611',
            'coingeckoId': 'secret-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DADDYUSDT',
            'name': 'DaddyUSDT',
            'address': '0xcd929f47CE8EB0DC88d30abAC83d002A4c000142',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18724/thumb/daddy-usdt-logo.png?1633152796',
            'coingeckoId': 'daddyusdt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BEET',
            'name': 'Beetlecoin',
            'address': '0xcd619a8ec557adB00a7c818a5AC5dFBD9007d3ac',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/3657/thumb/Beet_v2.png?1547038628',
            'coingeckoId': 'beetlecoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PARA',
            'name': 'Parabolic',
            'address': '0xcca3E26Be51b8905f1A01872524f17eb55Bd02fb',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25187/thumb/LHh3hHnQ_400x400.jpg?1650600695',
            'coingeckoId': 'parabolic-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KLING',
            'name': 'Kling',
            'address': '0xcca166E916088cCe10F4fB0fe0c8BB3577bb6e27',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23861/thumb/Kling-Logo-32.png?1645595953',
            'coingeckoId': 'kling',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHARK',
            'name': 'Baby Shark',
            'address': '0xcc9b175E4b88a22543C44F1cC65B73f63b0D4EfE',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15653/thumb/Resized_Shark_Logo.png?1624260129',
            'coingeckoId': 'baby-shark',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOV',
            'name': 'GovWorld',
            'address': '0xcc7a91413769891de2e9ebBfC96D2eb1874b5760',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22261/thumb/govworld.PNG?1641335263',
            'coingeckoId': 'govworld',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PROMISE',
            'name': 'Promise',
            'address': '0xcbcCf14B051947BDcd1E20b77015208a1AD5EA25',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17910/thumb/49c62672-0d86-4467-baea-874291066473.png?1629776137',
            'coingeckoId': 'promise-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APD',
            'name': 'ApeParkDAO',
            'address': '0xcb8B997BC685a5bDc3651a1ac30e65dC07FB7B8a',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27071/thumb/200*200.png?1661684995',
            'coingeckoId': 'apeparkdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KOT',
            'name': 'Kols Offering',
            'address': '0xcb683B189BF0EfaB9418160ac807f7c8053758Bd',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22497/thumb/kols.PNG?1641951257',
            'coingeckoId': 'kols-offering-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SRP',
            'name': 'Starpad',
            'address': '0xcb2B25e783A414f0d20A65aFa741C51b1Ad84c49',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18631/thumb/starpunk.PNG?1632713040',
            'coingeckoId': 'starpad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTOWN',
            'name': 'MetaTown',
            'address': '0xcaf1b8F0240376B616b797eC1E93794455946897',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22899/thumb/mtown.png?1642856028',
            'coingeckoId': 'metatown',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEP',
            'name': 'DEAPCOIN',
            'address': '0xcaF5191fc480F43e4DF80106c7695ECA56E48B18',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/10970/thumb/DEAPcoin_01.png?1586741677',
            'coingeckoId': 'deapcoin',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MW',
            'name': 'MetaWorld',
            'address': '0xca8a893a7464E82BDeE582017c749b92e5B45b48',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21918/thumb/8QIPjNO.png?1640266083',
            'coingeckoId': 'metaworld',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GULL',
            'name': 'PolyGod',
            'address': '0xca830317146BfdDE71e7C0B880e2ec1f66E273EE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20590/thumb/polygod.PNG?1637275789',
            'coingeckoId': 'polygod',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'LAEEB',
            'name': 'FIFA Laeeb',
            'address': '0xca07f2cADb981c7886a83357B4540002c1F41020',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27464/thumb/a02dc008c26eee9f3af510576a3672e4edfaf10c.png?1664177852',
            'coingeckoId': 'fifa-laeeb',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CMP',
            'name': 'Moonpoly',
            'address': '0xcFd947B1Ad06c39522fB67fb00b21a3FDA906e34',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18522/thumb/logo_-_2021-09-22T094540.826.png?1632275150',
            'coingeckoId': 'moonpoly',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KDOGE',
            'name': 'Koreadoge',
            'address': '0xcFE087ed979244DB595E62a7bC1E01e6De6870e9',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19265/thumb/32KDOGE_LOGO.jpg?1634828473',
            'coingeckoId': 'koreadoge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XVS',
            'name': 'Venus',
            'address': '0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63.png',
            'coingeckoId': 'venus',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ZARD',
            'name': 'FireZard',
            'address': '0xcF663a0ef9155BdC35a4B918BbEC75E9bFE40D2a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20749/thumb/FireZard_200.png?1637635239',
            'coingeckoId': 'firezard',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CARESV2',
            'name': 'Project CareCoin',
            'address': '0xcF6256895839cA82dcF8Abc54B162A5eB076f207',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27577/thumb/caresv2.png?1664694958',
            'coingeckoId': 'project-carecoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TOZ',
            'name': 'Tozex',
            'address': '0xcF0Bea8B08fd28E339EFF49F717A828f79F7F5eC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13772/thumb/Logo_toz_200x200.png?1611698823',
            'coingeckoId': 'tozex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MBTC',
            'name': '      ',
            'address': '0xcEdaA366475051A0816Cbb85Ae196E629830C30a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26782/thumb/MBTC.png?1660100286',
            'coingeckoId': '73c9fc57-af8f-4bd8-935f-de69a853598d',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RVLX',
            'name': 'RevivalX',
            'address': '0xcEb244A0E126f6CCbf37d631c7b102cf3e11a536',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26534/thumb/circle.png?1658715362',
            'coingeckoId': 'revivalx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SUCCESS',
            'name': 'SUCCESS INU',
            'address': '0xcE35f335B1Bd0eC37651E15b030cDF83c95591df',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18592/thumb/UNddrrkK.png?1632557350',
            'coingeckoId': 'success-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PPP',
            'name': 'PlayPoseidon NFT',
            'address': '0xcE355300B9d7909f577640A3FB179Cf911a23fBb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25074/thumb/Icon_PPP_Big_x200.png?1650259741',
            'coingeckoId': 'playposeidon-nft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOGEDIGGER',
            'name': 'Doge Digger',
            'address': '0xcE18eAe0303a0F285f99A345f39819b15833266b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': 'doge-digger',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOOP',
            'name': 'LoopNetwork',
            'address': '0xcE186ad6430E2Fe494a22C9eDbD4c68794a28B35',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24259/thumb/loop.png?1647170665',
            'coingeckoId': 'loopnetwork',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PSC',
            'name': 'Petsneaker',
            'address': '0xcDF3d1b32cc03080Ffa6BF7673B2526C61FEEC32',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25763/thumb/e461d5134e65c5a14ef7c16704a4a2d3.png?1653549198',
            'coingeckoId': 'petsneaker',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COW',
            'name': 'CaashCow',
            'address': '0xcDD1d715F01bF959Bf94Cd553F43250ddb303D1f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20408/thumb/caash.png?1648265562',
            'coingeckoId': 'caashcow',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DRV',
            'name': 'Dragon Verse',
            'address': '0xcD9442d70Eb0E6ecf26919E62278Fc4Fd24C3Ed2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21660/thumb/drv.png?1642931840',
            'coingeckoId': 'dragon-verse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MBNB',
            'name': 'MAGIC BNB',
            'address': '0xcD876E7F2Cd4434471Ede82B4B6C284B5328DcD8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21219/thumb/Magic.png?1638671698',
            'coingeckoId': 'magic-bnb',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RAVEN',
            'name': 'Raven Protocol',
            'address': '0xcD7C5025753a49f1881B31C48caA7C517Bb46308',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/8673/thumb/Raven_Protocol.jpg?1560248948',
            'coingeckoId': 'raven-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LORY',
            'name': 'Yield Parrot',
            'address': '0xcD5D75Dbe75449A9021B6C570a41959eB571C751',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16804/thumb/LORY.png?1625113802',
            'coingeckoId': 'yield-parrot',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AKS',
            'name': 'Arkarus',
            'address': '0xcD06bA98E8eF76C9B6Dc4b6E08caD90345D31c4a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24097/thumb/aks-coin-200.png?1646372193',
            'coingeckoId': 'arkarus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MNR',
            'name': 'Mooner',
            'address': '0xcD03f8A59252f317A679Eddb5315150F40d06E5E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24391/thumb/logo-200x200.png?1647518867',
            'coingeckoId': 'mooner',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FREY',
            'name': 'Frey',
            'address': '0xcCe5F4Cdc91fd2447FBE8A649275280af59fD832',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25168/thumb/Frey_logo.png?1650522416',
            'coingeckoId': 'frey',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HPW',
            'name': 'HappyLand Reward',
            'address': '0xcC9D97a9F3cb30f4e788BB9B46A8c1226c45315C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23546/thumb/HPW.png?1644395724',
            'coingeckoId': 'happyland-reward-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VMA',
            'name': 'Virtual Meta',
            'address': '0xcC6C9773A8a70C4642dFfCEb017742830AAAC1a6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25993/thumb/200200.png?1655193595',
            'coingeckoId': 'virtualmeta',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WOLF',
            'name': 'WOLFCOIN',
            'address': '0xcBCD9c0F344960F40c5CE7eb17A17E837Fe8Bb92',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23934/thumb/6E433721-C204-48ED-8467-EFA2FBA79794.png?1645713559',
            'coingeckoId': 'wolfcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTCZ',
            'name': 'BitcoinZ',
            'address': '0xcBBB3e5099F769F6d4E2b8b92DC0e268f7E099D8',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/1004/thumb/BTCZ_LOGO-1.png?1601429570',
            'coingeckoId': 'bitcoinz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PSP',
            'name': 'ParaSwap',
            'address': '0xcAfE001067cDEF266AfB7Eb5A286dCFD277f3dE5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20403/thumb/ep7GqM19_400x400.jpg?1636979120',
            'coingeckoId': 'paraswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FANTA',
            'name': 'Football Fantasy Pro',
            'address': '0xcAC33Ce2734D30949F5a96f7d64851830fDa7AD9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17354/thumb/Football_fantasy.png?1627369421',
            'coingeckoId': 'football-fantasy-pro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DASS',
            'name': 'DashSports',
            'address': '0xcA981Cb99478d190CAc2De266ed04E06e341E30E',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16793/thumb/200_200.png?1625034692',
            'coingeckoId': 'dashsports',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BNF',
            'name': 'BonFi',
            'address': '0xcA14caF9E8dD2793e7010fC48dFE6c6AF8445136',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12862/thumb/bonfi_logo.png?1603114422',
            'coingeckoId': 'bonfi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WJXN',
            'name': 'Jax Network',
            'address': '0xcA1262e77Fb25c0a4112CFc9bad3ff54F617f2e6',
            'decimals': 0,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18737/thumb/photo.jpg?1633360887',
            'coingeckoId': 'jax-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BRB',
            'name': 'BerylBit',
            'address': '0xcA0823d3D04b9FAeA7803cCb87fa8596775190DD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22239/thumb/brb.png?1642832868',
            'coingeckoId': 'berylbit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SGEM',
            'name': 'SGEM',
            'address': '0xc9e8c581d67Fe5cc6217B2Fa1B2d4D9Cecb60fB2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PFI',
            'name': 'PoodleFi',
            'address': '0xc9dBCff0448D330AAF1Dd78fc204370E5e54a797',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26981/thumb/Logo200x200.png?1661174967',
            'coingeckoId': 'poodlefi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MILK',
            'name': 'Milkshake Swap',
            'address': '0xc9bCF3f71E37579A4A42591B09c9dd93Dfe27965',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18654/thumb/Logo200x200_%2810%29.png?1632780043',
            'coingeckoId': 'milkshakeswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GINUX',
            'name': 'Green Shiba Inu',
            'address': '0xc9Ad37E9Baf41377540Df5a77831Db98c1915128',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15649/thumb/inu-logo-new-200x200.png?1625578449',
            'coingeckoId': 'green-shiba-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SUPS',
            'name': 'Supremacy',
            'address': '0xc99cFaA8f5D9BD9050182f29b83cc9888C5846C4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23772/thumb/Token.png?1645432783',
            'coingeckoId': 'supremacy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MYC',
            'name': 'Myteamcoin',
            'address': '0xc99a0aD9Fb77E74Dda20AC805223B760Ad3bDfd5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16041/thumb/200x2001.png?1622683811',
            'coingeckoId': 'myteamcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUNNYZILLA',
            'name': 'Bunny Zilla',
            'address': '0xc99380b4954D387E93b489e915660f634002D237',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20139/thumb/bz.png?1636531965',
            'coingeckoId': 'bunny-zilla',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASIX',
            'name': 'ASIX',
            'address': '0xc98a8EC7A07f1b743E86896a52434C4C6A0Dbc42',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23282/thumb/asix.png?1643535598',
            'coingeckoId': 'asix',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SBY',
            'name': 'Shelby',
            'address': '0xc95278Cd6e51bc2e1E30CF660E82819d296152D9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20356/thumb/hdf0.jpg?1636944326',
            'coingeckoId': 'shelby-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ACY',
            'name': 'ACY Finance',
            'address': '0xc94595b56E301f3FfeDb8CCc2d672882d623e53a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21883/thumb/ubfPhcEK_400x400.jpg?1640214613',
            'coingeckoId': 'acy-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOV',
            'name': 'Dovu',
            'address': '0xc9457161320210D22F0D0d5fC1309Acb383d4609',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0xc9457161320210d22f0d0d5fc1309acb383d4609.png',
            'coingeckoId': 'dovu',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SHIELD',
            'name': 'Crypto Shield',
            'address': '0xc944273b805DeBd35c63011943ABc5aB9eDdb8E3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21614/thumb/14834.png?1639610437',
            'coingeckoId': 'crypto-shield',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DDIM',
            'name': 'DuckDaoDime',
            'address': '0xc9132C76060F6b319764Ea075973a650A1a53bC9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xfbeea1c75e4c4465cb2fccc9c6d6afe984558e20.png',
            'coingeckoId': 'duckdaodime',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'GOSU',
            'name': 'BetGosu',
            'address': '0xc8dc1B4812409c9e335e39F6781b11bf5F8A7A48',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28027/thumb/1666426374596-d0096ec6c83575373e3a21d129ff8fef.jpg?1667046861',
            'coingeckoId': 'betgosu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZPRO',
            'name': 'ZAT Project',
            'address': '0xc8c488fDbBB2E72E41710Ade67784f0812160210',
            'decimals': 7,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25633/thumb/cmc.png?1654593879',
            'coingeckoId': 'zatcoin-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KANG3N',
            'name': 'kang3n',
            'address': '0xc8aB61bEd1d2bAa1237F7Aa4641E68342C58824f',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23898/thumb/27C4962A-FC83-42E1-B644-2B6F66E4C3A2.png?1645626736',
            'coingeckoId': 'kang3n',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DRS',
            'name': 'DragonSlayer',
            'address': '0xc8E8ecB2A5B5d1eCFf007BF74d15A86434aA0c5C',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0xc8e8ecb2a5b5d1ecff007bf74d15a86434aa0c5c/aa7d70a1f08290beba5cabbae63e9038.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TPG',
            'name': 'ThePiggyGarden',
            'address': '0xc8AE7ded8b33ea0Da0d7c7FC6FEd35e3C1822be0',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23824/thumb/icon-coin.png?1645514919',
            'coingeckoId': 'thepiggygarden',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INFLEX',
            'name': 'Inflex Finance',
            'address': '0xc883a206Bd7009f091aA17A421cABDCCa5a21a42',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15701/thumb/inflex.PNG?1621574993',
            'coingeckoId': 'inflex-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PIX',
            'name': 'Privi Pix',
            'address': '0xc875d22eaA3Bc358887bD1483E98c8796b215942',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18201/thumb/11703.png?1630974073',
            'coingeckoId': 'privi-pix',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BIT',
            'name': 'Biconomy Exchange',
            'address': '0xc864019047B864B6ab609a968ae2725DFaee808A',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1635075895501_5460770851668286.png',
            'coingeckoId': 'biconomy-exchange-token',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'FIDLE',
            'name': 'Fidlecoin',
            'address': '0xc861534EFeb8a1c5DAf31F1c13c440a7f86984F1',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26158/thumb/fidlecoin_logo%281%29.png?1656322294',
            'coingeckoId': 'fidlecoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CT',
            'name': 'CLIQ',
            'address': '0xc85a2576693e5a6206398fE1c498C4Bfe214df58',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21687/thumb/SpottR_logo.png?1639719040',
            'coingeckoId': 'cliq',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EGOD',
            'name': 'egoD',
            'address': '0xc852A20db80B7a225E6C78207E61dD4C13Fae435',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26370/thumb/rsz_q8ikvoag_400x400.png?1657607496',
            'coingeckoId': 'egod',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SW',
            'name': 'SafeWolf',
            'address': '0xc845341377C68b8003485036c3163b8DBcf8acb2',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15877/thumb/Logo-SafeWolf-white.png?1622185975',
            'coingeckoId': 'safewolf',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BPKR',
            'name': 'BlackPoker',
            'address': '0xc83c08E4Bfb9aB9346AEB233161F706e5654Fc02',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21220/thumb/logo-pinksale.png?1638674565',
            'coingeckoId': 'blackpoker',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DGZ',
            'name': 'Dogezone',
            'address': '0xc81177F2be4677EEad8aE66EB78F21f172DBD1b5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27195/thumb/logo-dogezone.png?1662540683',
            'coingeckoId': 'dogezone',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RDOGE',
            'name': 'Royal Doge',
            'address': '0xc80F6C08Df38dA4B1B0377748A9CF7196954C676',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17425/thumb/favicon_%284%29.png?1627606843',
            'coingeckoId': 'royal-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARI',
            'name': 'Manarium',
            'address': '0xc80A0A55CAF6a7bfB4Ee22f9380C4077312c4a35',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22046/thumb/TFxLm5Xv_400x400.jpg?1640675104',
            'coingeckoId': 'manarium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GLOW',
            'name': 'Glow',
            'address': '0xc7bAA7787Bb40C5d2523252bAf142413dCBbcD5b',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21338/thumb/img-m5KKk418pMFWXnpg.png?1638951109',
            'coingeckoId': 'glow',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AER',
            'name': 'Aerdrop',
            'address': '0xc7Ad2CE38f208eED77a368613C62062fCE88f125',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15528/thumb/BsoQCUVu_400x400.jpg?1621129095',
            'coingeckoId': 'aerdrop',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LIQ',
            'name': 'Liquidus',
            'address': '0xc7981767f644C7F8e483DAbDc413e8a371b83079',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18749/thumb/liq.png?1635004173',
            'coingeckoId': 'liquidus',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BSTS',
            'name': 'Magic Beasties',
            'address': '0xc77Dd3AdE7b717583E0924466E4E474A5673332c',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18189/thumb/jqhZlCH.png?1630907745',
            'coingeckoId': 'magic-beasties',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFU',
            'name': 'SAFU Protocol',
            'address': '0xc74cD0042c837Ce59210857504eBb0859E06aA22',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xc74cd0042c837ce59210857504ebb0859e06aa22.png',
            'coingeckoId': 'safu-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BABYDOGE',
            'name': 'Baby Doge Coin',
            'address': '0xc748673057861a797275CD8A068AbB95A902e8de',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16125/thumb/Baby_Doge.png?1623044048',
            'coingeckoId': 'baby-doge-coin',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'KMON',
            'name': 'Kryptomon',
            'address': '0xc732B6586A93b6B7CF5FeD3470808Bc74998224D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17886/thumb/kryptomon.png?1629713536',
            'coingeckoId': 'kryptomon',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'DOD',
            'name': 'Day Of Defeat',
            'address': '0xc709878167Ed069Aea15FD0bD4E9758CEb4Da193',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20621/thumb/dod.png?1638181015',
            'coingeckoId': 'day-of-defeat',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CCAKE',
            'name': 'CheesecakeSwap',
            'address': '0xc7091AA18598b87588e37501b6Ce865263CD67Ce',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xc7091aa18598b87588e37501b6ce865263cd67ce.png',
            'coingeckoId': 'cheesecakeswap',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'OBS',
            'name': 'Obsidium',
            'address': '0xc6F509274FcC1F485644167CB911fd0C61545E6c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19499/thumb/New-Obsidium-Logo-Circle-200x200.png?1656574019',
            'coingeckoId': 'obsidium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CVC',
            'name': 'CoviCoin',
            'address': '0xc6F0a9b75Fa529DCc0F90459e30684E7Ed9A9Ea6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18875/thumb/cvc.PNG?1633686116',
            'coingeckoId': 'covicoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'URUS',
            'name': 'Aurox',
            'address': '0xc6DdDB5bc6E61e0841C54f3e723Ae1f3A807260b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14122/thumb/Aurox.png?1648524329',
            'coingeckoId': 'urus-token',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'RUN',
            'name': 'Run Together',
            'address': '0xc643E83587818202E0fFf5eD96D10Abbc8Bb48e7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25273/thumb/run.png?1660905042',
            'coingeckoId': 'run-together',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLOKIGROW',
            'name': 'FlokiGrow',
            'address': '0xc6082790554478fF3bcD264AdC1CfAEA6dE7E64C',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27144/thumb/logo.png?1662083304',
            'coingeckoId': 'flokigrow',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALPA',
            'name': 'Alpaca City',
            'address': '0xc5E6689C9c8B02be7C49912Ef19e79cF24977f03',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xc5e6689c9c8b02be7c49912ef19e79cf24977f03.png',
            'coingeckoId': 'alpaca',
            'listedIn': [
                'coingecko',
                '1inch',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GIL',
            'name': 'FishingTownGil',
            'address': '0xc5B9B7dCB988c86bA37853761fEA692772C9937d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22698/thumb/GIL200.png?1642410801',
            'coingeckoId': 'fishingtowngiltoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWAMP',
            'name': 'Swampy',
            'address': '0xc5A49b4CBe004b6FD55B30Ba1dE6AC360FF9765d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xc5A49b4CBe004b6FD55B30Ba1dE6AC360FF9765d/logo.png',
            'coingeckoId': 'swampy',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GUM',
            'name': 'Gourmet Galaxy',
            'address': '0xc53708664b99DF348dd27C3Ac0759d2DA9c40462',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xc53708664b99DF348dd27C3Ac0759d2DA9c40462/logo.png',
            'coingeckoId': 'gourmetgalaxy',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ML',
            'name': 'Market Ledger',
            'address': '0xc4fB957e3F1c04C8Dc4000525e55920861F25bFc',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22763/thumb/Clear-background-logo-200-x-200.png?1642574489',
            'coingeckoId': 'market-ledger',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TREND',
            'name': 'UpTrend',
            'address': '0xc4ED752D658989Fe86dB8dDad3F6F19271552d23',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23828/thumb/Uptrend200x200.jpg?1645515504',
            'coingeckoId': 'uptrend',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MITHRIL',
            'name': 'Mithril',
            'address': '0xc4Caf585c2b7eAF3F61ac69b1529E79F90B15569',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0xc4caf585c2b7eaf3f61ac69b1529e79f90b15569/logo.png',
            'coingeckoId': 'bsc-mithril',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PKR',
            'name': 'Polker',
            'address': '0xc49DDe62B4A0810074721fAcA54Aab52369f486a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16803/thumb/200x200-PKR_Chip.png?1625589565',
            'coingeckoId': 'polker',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'MOVON',
            'name': 'MovingOn Finance',
            'address': '0xc4901c34cd8FB49107c1CB874EfF5cAA2eAeD7d1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25615/thumb/CMWZ4ZT.png?1652855335',
            'coingeckoId': 'movingon-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '452B',
            'name': 'Kepler452b',
            'address': '0xc4544EdeF986EAc0592516b6dE4968da2405452B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20527/thumb/unnamed_%284%29.gif?1637200705',
            'coingeckoId': 'kepler452b',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'Y5TT',
            'name': 'Y5 Trader',
            'address': '0xc41AB777C7d1d5e04414a14FC6Fa4c93766fb1Eb',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26261/thumb/Y53D.png?1656942180',
            'coingeckoId': 'y5-trader',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PREMIA',
            'name': 'Premia',
            'address': '0xc417b45e6090bd4201D9400b48F84c9f34f4d0a5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DYNA',
            'name': 'Dynamix',
            'address': '0xc41689A727469C1573009757200371edf36D540e',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18747/thumb/12275.png?1633316967',
            'coingeckoId': 'dynamix',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'renDOGE',
            'name': 'renDOGE',
            'address': '0xc3fEd6eB39178A541D274e6Fc748d48f0Ca01CC3',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xc3fEd6eB39178A541D274e6Fc748d48f0Ca01CC3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'LUNAPE',
            'name': 'Luna Ape Protocol',
            'address': '0xc3ee300e716978F36CFDca73CbdB6Fb690bCb94e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26983/thumb/Lunape.png?1661178840',
            'coingeckoId': 'luna-ape-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BEAR',
            'name': 'Bear',
            'address': '0xc3EAE9b061Aa0e1B9BD3436080Dc57D2d63FEdc1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0xc3eae9b061aa0e1b9bd3436080dc57d2d63fedc1/77f412aef1fe4bdf0721ee3228a63868.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'VERSUS',
            'name': 'Versus Farm',
            'address': '0xc3E49c20C841d6e0B2a1CCBBc5F29A64e068B25f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20618/thumb/9bUyqifr_400x400.jpg?1637316660',
            'coingeckoId': 'versus-farm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUCKY',
            'name': 'Lucky Lion',
            'address': '0xc3D912863152E1Afc935AD0D42d469e7C6B05B77',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18712/thumb/lucky-token-200px.png?1633079262',
            'coingeckoId': 'lucky-lion',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LVLUP',
            'name': 'LevelUp Gaming',
            'address': '0xc3Bd3e021801A34104fcb5E29DE6689a9b204513',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19871/thumb/lvlup.PNG?1636082651',
            'coingeckoId': 'levelup-gaming',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INFTEE',
            'name': 'Infinitee',
            'address': '0xc350CaA89Eb963D5D6b964324A0a7736D8d65533',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17010/thumb/infinitee_logo.png?1626067348',
            'coingeckoId': 'infinitee',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GEO',
            'name': 'GeoDB',
            'address': '0xc342774492b54ce5F8ac662113ED702Fc1b34972',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/11130/thumb/geodb.png?1588941704',
            'coingeckoId': 'geodb',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MATRIX',
            'name': 'Matrix Labs',
            'address': '0xc32bB619966B9a56cF2472528a36Fd099CE979E0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18297/thumb/matrixlabs.png?1643277367',
            'coingeckoId': 'matrixswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DP',
            'name': 'Dragon Pool',
            'address': '0xc31c29D89e1c351D0A41b938dC8AA0b9F07B4a29',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20730/thumb/240601798_112525817849387_6382833246720791968_n.jpg?1637616376',
            'coingeckoId': 'dragon-pool',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTS',
            'name': 'Bolt Share',
            'address': '0xc2e1acef50aE55661855E8dcB72adB182A3cC259',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xc2e1acef50ae55661855e8dcb72adb182a3cc259.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'MEC',
            'name': 'MechaShiba',
            'address': '0xc2d56D2d136f16Ed1c3e219339b272fEdDF2a376',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16952/thumb/mechashiba.png?1625754833',
            'coingeckoId': 'mechashiba',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LAYER',
            'name': 'UniLayer',
            'address': '0xc2c23a86DEF9e9f5972a633b3d25F7ecBFA5e575',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12164/thumb/Unilayer.jpg?1597779313',
            'coingeckoId': 'unilayer',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STKBNB',
            'name': 'pSTAKE Staked BNB',
            'address': '0xc2E9d07F66A89c44062459A47a0D2Dc038E4fb16',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0xc2E9d07F66A89c44062459A47a0D2Dc038E4fb16.png',
            'coingeckoId': 'pstake-staked-bnb',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'MANGA',
            'name': 'Manga',
            'address': '0xc2CB89bBB5BBA6E21db1dFe13493Dfd7dCBabD68',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17982/thumb/logo-200x200_%287%29.png?1630024708',
            'coingeckoId': 'manga-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PPOLL',
            'name': 'PancakePoll',
            'address': '0xc29000A4b1eCD326b6DafAE17bDA636475FeA1e7',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19558/thumb/ppoll.png?1635403248',
            'coingeckoId': 'pancakepoll',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PAPER',
            'name': 'Dope Wars Paper',
            'address': '0xc28Ea768221f67B6A1fD33e6aa903d4e42f6b177',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18166/thumb/EQHGcBO__400x400.jpeg?1663726283',
            'coingeckoId': 'dope-wars-paper',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'BXMI',
            'name': 'Bxmi',
            'address': '0xc26eEfA5f04eA11e5182B9f63EcF69ba9Ac5bE1A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20859/thumb/1QE9Pge.png?1637803118',
            'coingeckoId': 'bxmi-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SCC',
            'name': 'SiaCashCoin',
            'address': '0xc26EaFC627624baDf990f8d30116892eD204DB51',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/5312/thumb/SCC.png?1649213287',
            'coingeckoId': 'siacashcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABYLLAMA',
            'name': 'Babyllama',
            'address': '0xc263bB99Fa42C3493a136B9D56388de66F421aea',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27251/thumb/logo200.jpg?1662964913',
            'coingeckoId': 'babyllama',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PNIX',
            'name': 'PhoenixDefi Finance',
            'address': '0xc25D94fc3f8D7bD1d88f89802fe075338F71dEC7',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14489/thumb/logo-phoenix-200x200.png?1616569251',
            'coingeckoId': 'phoenixdefi-finance',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'JOT',
            'name': 'Jot Art',
            'address': '0xc242f265Ec93F7d0B8d7DcCb522F1d341D6DAdf4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28220/thumb/jot-blue_gradient_bg2.png?1668481653',
            'coingeckoId': 'jot-art',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CEVO',
            'name': 'CardanoEvo',
            'address': '0xc23997371bd83de427b41DAbE11846C87696f40a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20257/thumb/cevo.png?1636702097',
            'coingeckoId': 'cardanoevo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOZEN',
            'name': 'NFT Marble',
            'address': '0xc236A71F9633a0Be7D97ad68F8c16DE6ec46A252',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26120/thumb/Logo_Marble_Token.png?1655950578',
            'coingeckoId': 'nft-marble',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KCAKE',
            'name': 'KittyCake',
            'address': '0xc22e8114818A918260662375450e19aC73D32852',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17319/thumb/logo_-_2021-07-26T173233.519.png?1627291990',
            'coingeckoId': 'kittycake',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CBET',
            'name': 'CBET',
            'address': '0xc212D39E35F22F259457bE79Fc2D822FA7122e6e',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16975/thumb/token-logo-bg-200.png?1626093864',
            'coingeckoId': 'cbet-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RPG',
            'name': 'Rangers Protocol',
            'address': '0xc2098a8938119A52B1F7661893c0153A6CB116d5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0xc2098a8938119A52B1F7661893c0153A6CB116d5.png',
            'coingeckoId': 'rangers-protocol-gas',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'CCOIN',
            'name': 'Crypteriumcoin',
            'address': '0xc209831f7349D4E200d420326B3401899Ab9Ae68',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28026/thumb/363216.png?1667292647',
            'coingeckoId': 'crypteriumcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SIW',
            'name': 'Stay In Destiny World',
            'address': '0xc1Ec13469d3f9BDA35C5e7CdcBfBb390756767B6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21408/thumb/FINAL_%281%29.png?1639090989',
            'coingeckoId': 'stay-in-destiny-world',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SBS',
            'name': 'StaysBASE',
            'address': '0xc1D99537392084Cc02D3F52386729b79d01035ce',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14161/thumb/ifHot4z_%281%29.png?1614723566',
            'coingeckoId': 'staysbase',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PCHF',
            'name': 'Peachfolio',
            'address': '0xc1CbFB96A1D5361590b8DF04EF78DE2fa3178390',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16529/thumb/7d578071-601e-4ef6-9a98-cc7984b258c4.png?1624342728',
            'coingeckoId': 'peachfolio',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LNR',
            'name': 'Lunar',
            'address': '0xc1A59a17F87ba6651Eb8E8F707db7672647c45bD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27664/thumb/LNRTokenLogo2_%281%29.png?1665479443',
            'coingeckoId': 'lunar-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PABLO',
            'name': 'The Pablo',
            'address': '0xc196E98F3D0c2e973A33B0f7768Ee501dec43350',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17099/thumb/pablo.PNG?1626228430',
            'coingeckoId': 'the-pablo-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HUH',
            'name': 'HUH',
            'address': '0xc15e89f2149bCC0cBd5FB204C9e77fe878f1e9b2',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21549/thumb/huh.png?1639462106',
            'coingeckoId': 'huh',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'YNY',
            'name': 'Crypto Realms War',
            'address': '0xc153e92E11ee84BE0707c6d4C40F68f27d8444D7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23871/thumb/PNG-LOGO-YNY.png?1645599896',
            'coingeckoId': 'crypto-realms-war',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABYBNB',
            'name': 'BabyBNB',
            'address': '0xc1168B7B85B2BBc8a5C73c007B74E7523B2DA209',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16780/thumb/Babybnb-logo-200px.png?1626961003',
            'coingeckoId': 'babybnb',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BCMC',
            'name': 'Blockchain Monster Hunt',
            'address': '0xc10358f062663448a3489fC258139944534592ac',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xc10358f062663448a3489fc258139944534592ac.png',
            'coingeckoId': 'blockchain-monster-hunt',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MTR',
            'name': 'MoonstaRevenge',
            'address': '0xc0dc059E59B48016bCA8C8F57A3b40fd08B3bD77',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18766/thumb/icon_mtr.png?1633356748',
            'coingeckoId': 'moonstarevenge-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'H2O',
            'name': 'Hash2O',
            'address': '0xc0c06f6456a6eb532c6307b3A294A344C2ba6E29',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27105/thumb/Safeimagekit-resized-imgpng_%285%29.png?1662007501',
            'coingeckoId': 'hash2o',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SRG',
            'name': 'Slime Royale Gold',
            'address': '0xc0FF232D16B415FE7A0D3842d31280778a9400eF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27448/thumb/srg.png?1664017322',
            'coingeckoId': 'slime-royale-gold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHOE',
            'name': 'ShoeFy',
            'address': '0xc0F42b31D154234A0A3eBE7ec52c662101C1D9BC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19082/thumb/SHOEFY.jpg?1634371392',
            'coingeckoId': 'shoefy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLK',
            'name': 'Black Whale',
            'address': '0xc0E6AD13BD58413Ed308729b688d601243E1CF77',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22366/thumb/BLK_300x300px.png?1642605886',
            'coingeckoId': 'black-whale',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CPOS',
            'name': 'CPOS Cloud Payment',
            'address': '0xc0DC5aDfaE1DadA9111F376810d772CABD9B6f13',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19229/thumb/cpos.PNG?1634765828',
            'coingeckoId': 'cpos-cloud-payment',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RICH',
            'name': 'RichCity',
            'address': '0xc0994Af94FEe0361A1e1E1ccf72BCe19d5FD86FB',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16985/thumb/Richcity.png?1625909904',
            'coingeckoId': 'richcity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STEAMX',
            'name': 'Steam Exchange',
            'address': '0xc0924EDEFB2C0C303de2d0c21BfF07ab763163B5',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16914/thumb/steamx.png?1632824252',
            'coingeckoId': 'steam-exchange',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GSYS',
            'name': 'Genesys Token',
            'address': '0xc073eb514B96cFbe470b0d23e0a620BaE73ee165',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27721/thumb/Logo-Genesys.png?1665462741',
            'coingeckoId': 'genesys-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GENI',
            'name': 'GemUni',
            'address': '0xc04a23149efdF9A63697f3Eb60705147e9f07FfD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23697/thumb/GemUni_Logo_%28200x200%29.png?1645080772',
            'coingeckoId': 'gemuni',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ETL',
            'name': 'Eternal World',
            'address': '0xc04EDAC87f7eD7B282Cd0411672d65452fD10125',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26726/thumb/200x200.png?1659879254',
            'coingeckoId': 'eternal-world',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TIKTOKEN',
            'name': 'TikToken',
            'address': '0xc03bCd6c6616520DaBE35a9DDea92f9b91E47817',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27440/thumb/tiktoken.png?1663936863',
            'coingeckoId': 'tiktoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOME',
            'name': 'Mixsome',
            'address': '0xc039C13470be809beD1C2CD42339Ccb22e0970f2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15005/thumb/mixsome.PNG?1619412351',
            'coingeckoId': 'mixsome',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VEX',
            'name': 'Velorex',
            'address': '0xc029A12e4A002c6858878FD9D3cc74E227cc2DDa',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15793/thumb/vex.png?1637824735',
            'coingeckoId': 'velorex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRUBGR',
            'name': 'TruBadger',
            'address': '0xc003F5193CABE3a6cbB56948dFeaAE2276a6AA5E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16398/thumb/trubadger_logo.PNG?1632984133',
            'coingeckoId': 'trubadger',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APRIL',
            'name': 'April',
            'address': '0xbfeA674ce7d16E26E39e3c088810367a708eF94C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15689/thumb/200-by-200-01.png?1621565063',
            'coingeckoId': 'april',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '4JNET',
            'name': '4JNET',
            'address': '0xbfb1a68962Fb4ED040FD3a0a71dC2C2015BCc667',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21145/thumb/4jnet.PNG?1638399067',
            'coingeckoId': '4jnet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CATZ',
            'name': 'CatzCoin',
            'address': '0xbfBEe3dAc982148aC793161f7362344925506903',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15519/thumb/MX0hFr7.jpeg?1621118815',
            'coingeckoId': 'catzcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JTS',
            'name': 'Jetset',
            'address': '0xbf675ed044f2092a4F004aA5709BfA858641cE8B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0xbf675ed044f2092a4f004aa5709bfa858641ce8b/logo.png',
            'coingeckoId': 'jetset',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'TFGOLD',
            'name': 'Tap Fantasy Gold',
            'address': '0xbf3BF5975B6da4eb5DA78C3796b64eDd19E8c92b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26482/thumb/Tap-Fantasy-Gold-Token-Logo.png?1658279283',
            'coingeckoId': 'tap-fantasy-gold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZGD',
            'name': 'ZambesiGold',
            'address': '0xbf27da33A58de2Bc6Eb1C7daB6CF2e84e825D7dc',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25544/thumb/lD1w22TUaUn2AkWmkgI5WWSufl5kZ-1Gu3s-g-2A9qZ-DyW5g9UrrBRaOxKp5fcLEghB2VR-XBxw-4mu4M76SjH3YDCvh27CH4RTfKAbtlrEWHNP7oHN9Y6eBrqwfGu_-M0SAJf-dqDRnMNLWIxP8hKtAxaIFR0_JmxdInlMpBJa2WONniOHCeGomlyYeGfBKa4bYytvpIKDwZNBC-b_-enFwb.jpg?1652326768',
            'coingeckoId': 'zambesigold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AVN',
            'name': 'AVNRich',
            'address': '0xbf151F63D8d1287db5FC7a3bc104a9c38124cdeB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14819/thumb/avn.png?1618556244',
            'coingeckoId': 'avnrich',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALGOP',
            'name': 'AlgoPainter',
            'address': '0xbeE554dbBC677EB9fb711F5E939a2f2302598C75',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15273/thumb/algo.PNG?1620337035',
            'coingeckoId': 'algopainter',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KVERSE',
            'name': 'KEEPs Coin',
            'address': '0xbe5166e8e8A5CB801F09A6a0a46C42b7c27bE755',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19622/thumb/keeps.PNG?1635498049',
            'coingeckoId': 'keeps-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NVM',
            'name': 'Novem Pro',
            'address': '0xbe2D8AC2A370972C4328BED520b224C3903A4941',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24804/thumb/photo_5972293329968282493_x.jpg?1666952119',
            'coingeckoId': 'novem-pro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PRED',
            'name': 'Predictcoin',
            'address': '0xbdD2E3fdb879AA42748E9D47b7359323f226BA22',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21684/thumb/pc.png?1639717172',
            'coingeckoId': 'predictcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GHX',
            'name': 'GamerCoin',
            'address': '0xbd7B8e4de08D9b01938F7FF2058F110ee1E0E8d4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14714/thumb/ghx_icon.png?1618819440',
            'coingeckoId': 'gamercoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SRGT',
            'name': 'Severe Rise Games',
            'address': '0xbd7B45E4a8E7042B458a8ff08F29aA8EAe43F269',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21512/thumb/SevereRiseGamesLogo.png?1639375509',
            'coingeckoId': 'severe-rise-games',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPA',
            'name': 'Spartans',
            'address': '0xbcfe392E778dbB59DcAd624F10f7fa8C4a910B1e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22787/thumb/Logo_200x200.png?1650437413',
            'coingeckoId': 'spartan-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CWS',
            'name': 'Seascape Crowns',
            'address': '0xbcf39F0EDDa668C58371E519AF37CA705f2bFcbd',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xbcf39f0edda668c58371e519af37ca705f2bfcbd.png',
            'coingeckoId': 'crowns',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DUT',
            'name': 'Dar Dex',
            'address': '0xbccf768576eb4d28Fe3dD21f9EBCf9784E4e092c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20704/thumb/dardark200.png?1637570433',
            'coingeckoId': 'dar-dex-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RVC',
            'name': 'Revenue Coin',
            'address': '0xbcbdecf8e76A5C32Dba69De16985882ace1678c6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21625/thumb/RVC_logo_200_200.png?1639625596',
            'coingeckoId': 'revenue-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSCS',
            'name': 'BSC Station',
            'address': '0xbcb24AFb019BE7E93EA9C43B7E22Bb55D5B7f45D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xbcb24afb019be7e93ea9c43b7e22bb55d5b7f45d.png',
            'coingeckoId': 'bsc-station',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MONX',
            'name': 'Monster of God',
            'address': '0xbcDE162a6f7a45Fbb6913b296E325fe1267A1E9F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22742/thumb/icon2_200_200.png?1644479582',
            'coingeckoId': 'monster-of-god',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LQR',
            'name': 'Laqira Protocol',
            'address': '0xbc81ea817b579eC0334BcA8E65E436b7cB540147',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20307/thumb/lqt.PNG?1636841139',
            'coingeckoId': 'laqira-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EAD',
            'name': 'Learning Cash',
            'address': '0xbc72ff9D74D1002Cd1B07eB43D36e03611BFeb28',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28203/thumb/8AceqVsW_400x400.jpeg?1668391044',
            'coingeckoId': 'learning-cash-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DYN',
            'name': 'DYAKON',
            'address': '0xbc543B6361eD72AB4C6f91CCbb67A94A219DE3f5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23024/thumb/logo_200_x_200.png?1643095227',
            'coingeckoId': 'dyakon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ONYX',
            'name': 'Onyx',
            'address': '0xbc45EDd4b1D3bC9AA665232055cbdDAE64Ef503e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20546/thumb/gg320-FXI-400x400.jpg?1637205161',
            'coingeckoId': 'onyx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHE',
            'name': 'CherrySwap Token',
            'address': '0xbc420Bc2c015d1579F77e4a5c68270b75F2DDB9d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0xbc420bc2c015d1579f77e4a5c68270b75f2ddb9d/bd1f415a90c06a983de699f7dce89e82.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MAZE',
            'name': 'NFT MAZE',
            'address': '0xbc23833d965632CEC84bF98181CB84C31BBDfA94',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20784/thumb/maze.png?1637671162',
            'coingeckoId': 'nft-maze',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LEV',
            'name': 'Lever Network',
            'address': '0xbc194e6f748a222754C3E8b9946922c09E7d4e91',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0xbc194e6f748a222754c3e8b9946922c09e7d4e91.png',
            'coingeckoId': 'lever-network',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'EIFI',
            'name': 'EIFI Finance',
            'address': '0xbbf33a3c83Cf86D0965A66E108669D272DFE4214',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17120/thumb/eifi.PNG?1626394350',
            'coingeckoId': 'eifi-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FINU',
            'name': 'Fifa Inu',
            'address': '0xbbc915692c2F94AF359dCF2bd1890d93e4e1639E',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/22323.png',
            'coingeckoId': 'fifa-inu',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'MVP',
            'name': 'MetaversePay',
            'address': '0xbba18524CA3c6AA26306E5C402C0FEd72E62A6C3',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26071/thumb/mvp.png?1655545042',
            'coingeckoId': 'metaversepay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DHG',
            'name': 'Doom Hero Game',
            'address': '0xbbA24300490443BB0E344Bf6EC11BaC3aa91dF72',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22314/thumb/dhg.PNG?1641452573',
            'coingeckoId': 'doom-hero-game',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WBNB',
            'name': 'Wrapped BNB',
            'address': '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c_1.png',
            'coingeckoId': 'wbnb',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BEEINU',
            'name': 'Bee Inu',
            'address': '0xba049d49E6D73B463b6AE482ECF2334aFf84B83B',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24318/thumb/200.png?1647355382',
            'coingeckoId': 'bee-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OCTSMM',
            'name': 'Octus Social Media Market',
            'address': '0xbFc43a35b3aE8F7463c5Ac88a0C46107cFCe6f67',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27095/thumb/photo_2022-08-21_06.15.02.jpeg?1661998030',
            'coingeckoId': 'octus-social-media-market',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NYA',
            'name': 'Nyanswop Token',
            'address': '0xbFa0841F7a90c4CE6643f651756EE340991F99D5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xbFa0841F7a90c4CE6643f651756EE340991F99D5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WEGLD',
            'name': 'Wrapped Elrond',
            'address': '0xbF7c81FFF98BbE61B40Ed186e4AfD6DDd01337fe',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xbf7c81fff98bbe61b40ed186e4afd6ddd01337fe.png',
            'coingeckoId': 'wrapped-elrond',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SMTY',
            'name': 'Smoothy',
            'address': '0xbF776e4FCa664D791C4Ee3A71e2722990E003283',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15039/thumb/dDxKgwPN_400x400.jpg?1619507030',
            'coingeckoId': 'smoothy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ONEUSD',
            'name': '1 Dollar',
            'address': '0xbF30A1DAb8a2fa14B852a87914C377048cef18eB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18557/thumb/oneusd.PNG?1639912538',
            'coingeckoId': '1-dollar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MBK',
            'name': 'MeMeBank',
            'address': '0xbF19367080c33E8a36c87B979EDc1a5Cd8f47949',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25947/thumb/9076tn.png?1654842537',
            'coingeckoId': 'memebank',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BEPR',
            'name': 'Blockchain Euro Project',
            'address': '0xbF0cF158e84eBaCcA1b7746E794D507073e5ADFE',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18743/thumb/BEPR-1-e1633173706568.png?1633315667',
            'coingeckoId': 'blockchain-euro-project',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEFX',
            'name': 'DeFinity',
            'address': '0xbE4Cb2C354480042A39350A0c6c26bf54786539F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15875/thumb/definity-listing-logo.png?1622414896',
            'coingeckoId': 'definity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PFW',
            'name': 'Perfect World',
            'address': '0xbDFAb42a028770f42A37458dc1bfe9531158826D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20297/thumb/aap4r-tQ_400x400.jpg?1636815655',
            'coingeckoId': 'perfect-world',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COC',
            'name': 'Coin of the champions',
            'address': '0xbDC3b3639f7AA19e623A4d603A3Fb7Ab20115A91',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18478/thumb/COC-Yellow-Transparent-1.png?1632148454',
            'coingeckoId': 'coin-of-the-champions',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'NSHIBA',
            'name': 'Nowarshiba',
            'address': '0xbDB2C4ea9E904E71C7D95cB5B9017377f8847A39',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27941/thumb/LOGO.png?1666583372',
            'coingeckoId': 'nowarshiba',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STARS',
            'name': 'Mogul Productions',
            'address': '0xbD83010eB60F12112908774998F65761cf9f6f9a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14975/thumb/STARS_LOGO_PNG.png?1619214520',
            'coingeckoId': 'mogul-productions',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FRACTION',
            'name': 'Own a fraction',
            'address': '0xbD80CFA9d93A87D1bb895f810ea348E496611cD4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15099/large/fraction.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MBT',
            'name': 'Magic Birds',
            'address': '0xbD71E65149CeA83a1Dda53A9c1D2D30053604CB9',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19359/thumb/logoyeni.png?1636473353',
            'coingeckoId': 'magic-birds-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BIST',
            'name': 'Bistroo',
            'address': '0xbD525E51384905c6C0936A431BC7EFB6C4903Ea0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15645/thumb/bistroo.png?1659342030',
            'coingeckoId': 'bistroo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PAPADOGE',
            'name': 'Papa Doge',
            'address': '0xbCeeE918077F63fB1B9e10403F59cA40C7061341',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16721/thumb/PAPADOGE.jpg?1624848914',
            'coingeckoId': 'papa-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TONE',
            'name': 'NFT Tone',
            'address': '0xbCdfD50ead6b6da1970464985FAb894Fb83d17C0',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15669/thumb/nft_tone.PNG?1621495741',
            'coingeckoId': 'nft-tone',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LIO',
            'name': 'Leonidasbilic',
            'address': '0xbCc608002765339db153d07250D516bc4356531b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27084/thumb/output-onlinepngtools-55.png?1661765239',
            'coingeckoId': 'leonidasbilic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BME',
            'name': 'BitcoMine',
            'address': '0xbCba01f7d6CC0A950464a4b98BA8358c4F6B69a0',
            'decimals': 19,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18157/thumb/bmelogo.png?1630742266',
            'coingeckoId': 'bitcomine',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABYXRP',
            'name': 'Baby Ripple',
            'address': '0xbC9bB68C561a1Ff1B3fBC954FCEaD1D4862ac5CF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21064/thumb/KrrE8T3.jpeg?1638276825',
            'coingeckoId': 'baby-ripple',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HKC',
            'name': 'Hachiko Charity',
            'address': '0xbC91e5db518BACAF2629Cdc6f0eE7D42fAD1355c',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23433/thumb/C17162-BA-BAC6-4-FB1-847-B-CF956-D18-EB61.jpg?1644210735',
            'coingeckoId': 'hachiko-charity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NELUM',
            'name': 'VICDAO NELUM',
            'address': '0xbC846B8A1cAaA95cDD18FAA28d4Fd16791007801',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27394/thumb/200_050photo_2022-08-09_02-56-49.jpg?1663821696',
            'coingeckoId': 'vicdao-nelum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLOCK',
            'name': 'Blockasset',
            'address': '0xbC7A566b85eF73F935e640A06b5a8b031Cd975Df',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21332/thumb/Blockasset-Logo-Symbol.png?1648442722',
            'coingeckoId': 'blockasset',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LASM',
            'name': 'LasMeta',
            'address': '0xbC2E674dFe0d885081f429b296D7aE2B784D1198',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'VX',
            'name': 'ViteX Coin',
            'address': '0xbBdac6cA30Ba9189c7bf67a1f7160379f7e25835',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/10346/thumb/ViteX.png?1578364192',
            'coingeckoId': 'vitex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHEERS',
            'name': 'CheersLand',
            'address': '0xbBBcB350C64Fe974e5C42A55c7070644191823f3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23906/thumb/15236.png?1645681595',
            'coingeckoId': 'cheersland',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRR',
            'name': 'Terran Coin',
            'address': '0xbB95cc1c662D89822bda29D2e147B124406e6e42',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15351/thumb/TERRAN-500px.png?1620626066',
            'coingeckoId': 'terran-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REBL',
            'name': 'Rebellion Protocol',
            'address': '0xbB8b7E9A870FbC22ce4b543fc3A43445Fbf9097f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20241/thumb/01_logo.png?1636695980',
            'coingeckoId': 'rebellion-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHINE',
            'name': 'ShineMine',
            'address': '0xbB78CD9d658201119a78395dE350e3F531c12273',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24222/thumb/Shine_favicon_200x200.png?1646919734',
            'coingeckoId': 'shinemine',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSCV',
            'name': 'Bscview',
            'address': '0xbB3837Fa11d4B789717C8f622Ec4f6eee5375C49',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14356/thumb/photo_2021-03-12_23-07-18.jpg?1615562953',
            'coingeckoId': 'bscview',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHR',
            'name': 'SHREE',
            'address': '0xbAea100C5F47458E632462520e1e9cb5EEc78F49',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26457/thumb/Cgupkjb__400x400.jpeg?1658129310',
            'coingeckoId': 'shree',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FAN',
            'name': 'Fandora Network',
            'address': '0xbAa88EC0D95c12651B728c76742e70d670E3E556',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27804/thumb/Fandora_Logo_Round.png?1665902726',
            'coingeckoId': 'fandora-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHAKE',
            'name': 'Spaceswap SHAKE',
            'address': '0xbA8A6Ef5f15ED18e7184f44a775060a6bF91d8d0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x6006fc2a849fedaba8330ce36f5133de01f96189.png',
            'coingeckoId': 'spaceswap-shake',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'KODI',
            'name': 'KODI',
            'address': '0xbA5eAB68a7203C9FF72E07b708991F07f55eF40E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18794/thumb/ahAWxPp.png?1633430523',
            'coingeckoId': 'kodi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GENIUS',
            'name': 'Genius Coin',
            'address': '0xbA5b0408B0645ec091B3bB76Ee793091A9399BF2',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17095/thumb/A5-F6-C70-C-F535-4-DDA-8-EBC-31009-D9-E3-C7-E.png?1626226385',
            'coingeckoId': 'genius-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MEER',
            'name': 'Qitmeer Network',
            'address': '0xbA552586eA573Eaa3436f04027ff4effd0c0abbb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24984/thumb/34613203.png?1649669168',
            'coingeckoId': 'qitmeer-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOGE',
            'name': 'Binance Peg Dogecoin',
            'address': '0xbA2aE424d960c26247Dd6c32edC70B295c744C43',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xba2ae424d960c26247dd6c32edc70b295c744c43.png',
            'coingeckoId': 'binance-peg-dogecoin',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'AFK',
            'name': 'Idle Cyber',
            'address': '0xbA0B46F556633Bd742546E4F37D66D416753003B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19697/thumb/crypto.PNG?1635752046',
            'coingeckoId': 'idle-cyber',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABYZOROINU',
            'name': 'Babyzoro Inu',
            'address': '0xb9a22A7d3f195c3F1a4F3B984aD79Aabd51ae7EA',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25334/thumb/Babyzoro-inu_200.png?1651298318',
            'coingeckoId': 'babyzoro-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MRFLOKI',
            'name': 'MarioFloki',
            'address': '0xb9Dd2b51Ec3D52E624d05221cd8bc2fD0fdE6A92',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21926/thumb/Hf9BbqVQ_400x400.jpg?1640280229',
            'coingeckoId': 'mariofloki',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOONMINER',
            'name': 'MoonMiner',
            'address': '0xb9A0CAabd2f247631Ecf03Dd5E363C430c66a65b',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16579/thumb/YiH7Q1S.png?1624506075',
            'coingeckoId': 'moonminer',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PHO',
            'name': 'Phoswap',
            'address': '0xb9784C1633ef3b839563B988c323798634714368',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13836/thumb/200x200_%2810%29.png?1612182033',
            'coingeckoId': 'phoswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SUPE',
            'name': 'Supe Infinity',
            'address': '0xb972C4027818223BB7b9399b3cA3cA58186e1590',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21874/thumb/nF9bWyQe_400x400.jpg?1640209950',
            'coingeckoId': 'supe-infinity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NEGG',
            'name': 'Nest Egg',
            'address': '0xb96Bc98eA6A98dB32fa9Af72cD96058318e02E60',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19676/thumb/nest_egg.PNG?1635742962',
            'coingeckoId': 'nest-egg',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'THUNDERBNB',
            'name': 'ThunderBNB',
            'address': '0xb9654A42f0F5dCDEf5617DebF8bd048E33F180E7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17767/thumb/200x200-thunder-bnb.png?1629205363',
            'coingeckoId': 'thunderbnb',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WENMOON',
            'name': 'WenMoon Token',
            'address': '0xb93ba7DC61ECFced69067151FC00C41cA369A797',
            'decimals': 7,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xb93ba7dc61ecfced69067151fc00c41ca369a797.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'KRAC',
            'name': 'Krabots',
            'address': '0xb91F0fdFfdDE4d6D53ac4066AcC32aA81fC6DE2C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26441/thumb/Krabots_token_icon.png?1658108878',
            'coingeckoId': 'krabots',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TON',
            'name': 'To The Moon Token',
            'address': '0xb919149a7f1fcf4B5E7a05E6EA6f951CA6d73B5b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': 'to-the-moon-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VLXPAD',
            'name': 'VelasPad',
            'address': '0xb8e3bB633F7276cc17735D86154E0ad5ec9928C0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18535/thumb/11654.png?1632297834',
            'coingeckoId': 'velaspad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TCOIN',
            'name': 'Travel Coin',
            'address': '0xb8b10DaFb5546b3740886D8a77b8b9bACB44fFfD',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24526/thumb/ClNOufYk_400x400.jpg?1648038767',
            'coingeckoId': 'travel-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOGEPEPSI',
            'name': 'DogePepsi',
            'address': '0xb8a9c44613Bcc1c4D5454eb89071A82d8919CCcF',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18586/thumb/LOGO-DOGEPEPSI-gocce-334x600.png?1632555815',
            'coingeckoId': 'dogepepsi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HORSEDRACE',
            'name': 'HorseDrace',
            'address': '0xb8EB316D380Fa9fC454c6e6A734234E05Ee345bA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27366/thumb/200x200px.jpg?1663669492',
            'coingeckoId': 'horsedrace',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MSPACE',
            'name': 'Metaspace',
            'address': '0xb8CED2c93584C4e228Df25a88dcBe346DF89525D',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22679/thumb/A309-EBB8-43-B8-4-F3-C-8256-B027-BC58-E821.jpg?1642407497',
            'coingeckoId': 'metaspace',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QUSD',
            'name': 'QUSD Stablecoin',
            'address': '0xb8C540d00dd0Bf76ea12E4B4B95eFC90804f924E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xb8C540d00dd0Bf76ea12E4B4B95eFC90804f924E/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'YON',
            'name': 'YESorNO',
            'address': '0xb8C3e8Ff71513AfC8cfb2dDDc5A994A501Db1916',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21214/thumb/K91jws5t_400x400.png?1638572522',
            'coingeckoId': 'yesorno',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'XEP',
            'name': 'Electra Protocol',
            'address': '0xb897D0a0f68800f8Be7D69ffDD1c24b69f57Bf3e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13589/thumb/Apple-iPhone-Icon-Retina.png?1609939024',
            'coingeckoId': 'electra-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XGEM',
            'name': 'Exchange Genesis Ethlas Medium',
            'address': '0xb86DEB16E27ecE0BcD5B3A616e58926458ba72E1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ZIL',
            'name': 'Zilliqa',
            'address': '0xb86AbCb37C3A4B64f74f59301AFF131a1BEcC787',
            'decimals': 12,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xb86abcb37c3a4b64f74f59301aff131a1becc787_1.png',
            'coingeckoId': 'zilliqa',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CTP',
            'name': 'Ctomorrow Platform',
            'address': '0xb850CAC12Ab85d4400db61ac78DC5Fc2418b6868',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22256/thumb/dchSzDny_400x400.jpg?1641283473',
            'coingeckoId': 'ctomorrow-platform',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FOREVERPUMP',
            'name': 'Forever Pump',
            'address': '0xb82e7a40FbA04cFe053fF2DB6ebF4F2fb39F4C6a',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18883/thumb/Pumping-post-06.png?1633693780',
            'coingeckoId': 'forever-pump',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TCUB',
            'name': 'Tiger Cub',
            'address': '0xb7fda7374362F66a50665b991aA7Ee77b2c6abBE',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xb7fda7374362f66a50665b991aa7ee77b2c6abbe.png',
            'coingeckoId': 'tiger-cub',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'FATE',
            'name': 'Fate',
            'address': '0xb7a6E80b2ab4a1D8807B2811D44aDdd9dda8aB38',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19429/thumb/BLVk7aG.png?1635216688',
            'coingeckoId': 'fate-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PAX',
            'name': 'Paxos Standard',
            'address': '0xb7F8Cd00C5A06c0537E2aBfF0b58033d02e5E094',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x8e870d67f660d95d5be530380d0ec0bd388289e1.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'GDT',
            'name': 'Gorilla Diamond',
            'address': '0xb7F2bca9b034f8cc143339Dd12bb31D3D50Cf27a',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15212/thumb/gdt.png?1635827064',
            'coingeckoId': 'gorilla-diamond',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BKM',
            'name': 'Bunny King Metaverse',
            'address': '0xb7E02a2384b6d3D111Aa950283d3563a66f7a006',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27856/thumb/200x200.png?1666095974',
            'coingeckoId': 'bunny-king-metaverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MILK',
            'name': 'Milk',
            'address': '0xb7CEF49d89321e22dd3F51a212d58398Ad542640',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0xb7cef49d89321e22dd3f51a212d58398ad542640/logo.png',
            'coingeckoId': 'milk-token',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'STMAN',
            'name': 'Stickman Battleground',
            'address': '0xb75088a5D75e9ff7264ABc7d25721e4436ec309d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24641/thumb/KUJBPxTC_400x400.jpg?1648468481',
            'coingeckoId': 'stickman-battleground',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANONFLOKI',
            'name': 'AnonFloki',
            'address': '0xb72B6F2e5897354B485DC13c13878712CB6A76ca',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20464/thumb/download_%2841%29.png?1637063874',
            'coingeckoId': 'anonfloki',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '4MW',
            'name': '4 Meta World',
            'address': '0xb70d593ef89b707Ce05925E1d80fffCd9a655406',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22860/thumb/i9isHRLb_400x400.jpg?1642752036',
            'coingeckoId': '4mw',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FACE',
            'name': 'FaceDAO',
            'address': '0xb700597d8425CEd17677Bc68042D7d92764ACF59',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24309/thumb/1I7nII0c_400x400.jpg?1653526250',
            'coingeckoId': 'facedao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FAN',
            'name': 'Fanadise',
            'address': '0xb6d48FCEf36E19681EE29896B19c1b6CbD1eAB1B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17417/thumb/logo_fanadise_new_200x200.png?1638260115',
            'coingeckoId': 'fanadise',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'SCARY',
            'name': 'Scaryswap',
            'address': '0xb6cfC5BC09F96D46b642FF38781eA37aFc35BAF2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24724/thumb/RC-gb7iP_400x400.jpg?1648699373',
            'coingeckoId': 'scaryswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GUT',
            'name': 'GuitarSwap',
            'address': '0xb6bA8c98021C31A02DD65e9bE97729EbA859d4E2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19986/thumb/Round-2.png?1636356858',
            'coingeckoId': 'guitarswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CALO',
            'name': 'Calo',
            'address': '0xb6b91269413b6B99242B1c0Bc611031529999999',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21875/thumb/CALO-Token_200x200.png?1649846375',
            'coingeckoId': 'calo-app',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BMCC',
            'name': 'Binance Multi Chain Capital',
            'address': '0xb6D8EE99D5d6cfe7D80b666e6fF5e74e3f72756b',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22480/thumb/logo_200x200.png?1643194112',
            'coingeckoId': 'binance-multi-chain-capital',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WTLOS',
            'name': 'Wrapped Telos',
            'address': '0xb6C53431608E626AC81a9776ac3e999c5556717c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0xb6C53431608E626AC81a9776ac3e999c5556717c.png',
            'coingeckoId': 'wrapped-telos',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'GZONE',
            'name': 'GameZone',
            'address': '0xb6ADB74efb5801160Ff749b1985Fd3bD5000e938',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18697/thumb/Th98fbg__400x400.jpg?1633007475',
            'coingeckoId': 'gamezone',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GRAPE',
            'name': 'GrapeSwap Finance',
            'address': '0xb699390735ed74e2d89075b300761daE34b4b36B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27694/thumb/grape.png?1665240188',
            'coingeckoId': 'grapeswap-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LESS',
            'name': 'LessToken',
            'address': '0xb698AC9bC82C718D8ebA9590564B9a5AA53D58e6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0xb698ac9bc82c718d8eba9590564b9a5aa53d58e6/logo.png',
            'coingeckoId': 'less-network',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'HGHG',
            'name': 'HUGHUG',
            'address': '0xb626213cb1D52Caa1eD71e2a0e62c0113eD8d642',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20172/thumb/512hug.png?1636603036',
            'coingeckoId': 'hughug-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PINKM',
            'name': 'PinkMoon',
            'address': '0xb6090a50f66046E3c6aFB9311846a6432E45060A',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15018/thumb/pink-logo-200.png?1619465255',
            'coingeckoId': 'pinkmoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRGI',
            'name': 'The Real Golden Inu',
            'address': '0xb5dB7640182042a150CCdB386291f08f23B77A96',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16784/thumb/realgolden.PNG?1624965818',
            'coingeckoId': 'the-real-golden-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POLIS',
            'name': 'Polis',
            'address': '0xb5bEa8a26D587CF665f2d78f077CcA3C7f6341BD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/2452/thumb/circlePolisLogo.png?1573787189',
            'coingeckoId': 'polis',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OBOT',
            'name': 'Obortech',
            'address': '0xb5Be8D87FcE6Ce87a24b90AbDB019458A8eC31F9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14929/thumb/OBORTECH_200.png?1619070515',
            'coingeckoId': 'obortech',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'LIT',
            'name': 'Litentry',
            'address': '0xb59490aB09A0f526Cc7305822aC65f2Ab12f9723',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xb59490ab09a0f526cc7305822ac65f2ab12f9723.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'OAV',
            'name': 'Order of the Apeverse',
            'address': '0xb54c5DF6A6BD13292CdD4d661794D72e149Cc926',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22025/thumb/Kp4MqrC.png?1640610227',
            'coingeckoId': 'order-of-the-apeverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LAUNCH',
            'name': 'Superlauncher',
            'address': '0xb5389A679151C4b8621b1098C6E0961A3CFEe8d4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xb5389a679151c4b8621b1098c6e0961a3cfee8d4.png',
            'coingeckoId': 'superlauncher-dao',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'YTOFU',
            'name': 'yTOFU',
            'address': '0xb4c20Bb1C75300Fa724ec3196B5d1C854a7d58a0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17918/thumb/IEpQGhf.png?1629782029',
            'coingeckoId': 'ytofu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CROSE',
            'name': 'CryptoRose',
            'address': '0xb4Ffe8694fb15AbF40f8414d12684FC0a71D9c4D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26298/thumb/SodaPDF-converted-20220615_100737.png?1657175135',
            'coingeckoId': 'cryptorose',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELLO',
            'name': 'Ichello',
            'address': '0xb4Cc9fcC99673661cb0727891693Ad7Ecbb94507',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23470/thumb/vw0c04f__400x400.jpg?1644218017',
            'coingeckoId': 'ichello',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHD',
            'name': 'CharityDAO',
            'address': '0xb4CD493f14eDAaE27FdaaB87072F3D55d9289A18',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25401/thumb/logo200x200.png?1651728972',
            'coingeckoId': 'charitydao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHEESE',
            'name': 'Cheese Swap',
            'address': '0xb4BF64B17e270B50D00658E3c0e2fBDefABDD87b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17730/thumb/CHEESE_Transparent_%28200px%29.png?1629103740',
            'coingeckoId': 'cheese-swap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PYE',
            'name': 'PYE',
            'address': '0xb4B486496469B3269c8907543706C377daAA4dD9',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26293/thumb/iShot_2022-09-21_20.33.52.png?1663763653',
            'coingeckoId': 'pye-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PERI',
            'name': 'PERI Finance',
            'address': '0xb49B7e0742EcB4240ffE91661d2A580677460b6A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15313/thumb/6xVEMS1.png?1620375905',
            'coingeckoId': 'peri-finance',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'HEE',
            'name': 'beFITTER Health',
            'address': '0xb47e21328B4f1c6D685C213D707fab3EdB234fA0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26569/thumb/HEE.png?1658816013',
            'coingeckoId': 'befitter-health',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRIVIA',
            'name': 'Trivians',
            'address': '0xb465f3cb6Aba6eE375E12918387DE1eaC2301B05',
            'decimals': 3,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0xb465f3cb6Aba6eE375E12918387DE1eaC2301B05.png',
            'coingeckoId': 'trivian',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'EZY',
            'name': 'EZZY Game',
            'address': '0xb452bC9CEAd0b08c4ef99dA0fEb8E10eF6bB86bB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28250/thumb/ezzy_app_icon.png?1668670666',
            'coingeckoId': 'ezzy-game',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APLUS',
            'name': 'AMETA',
            'address': '0xb445B2d8831a602aE4684EC4f9316ef2091bFe37',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27737/thumb/AmetaToken.png?1665567583',
            'coingeckoId': 'ameta',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GEAR',
            'name': 'MetaGear',
            'address': '0xb4404DaB7C0eC48b428Cf37DeC7fb628bcC41B36',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0xb4404DaB7C0eC48b428Cf37DeC7fb628bcC41B36.png',
            'coingeckoId': 'metagear',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'MONKE',
            'name': 'Space Monkey MONKE',
            'address': '0xb422DE11a237d0cbCE17D7d70f53F67ef84394D3',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20847/thumb/XXqbKW2a_400x400.jpg?1637788170',
            'coingeckoId': 'space-monkey-token',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'SAFEW',
            'name': 'SafeWages',
            'address': '0xb419E3243BCDfcAb1a93A2CF42C4cD0c45020acc',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22807/thumb/Safe-Wages-Logo-Very-Small.png?1642657268',
            'coingeckoId': 'safewages',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZOOT',
            'name': 'Zoo',
            'address': '0xb3d691125514Db7a5bE3326af86a72ecdC2CDE16',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15629/thumb/gyzERsO.png?1621394316',
            'coingeckoId': 'zoo-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOLD',
            'name': 'CyberDragon Gold',
            'address': '0xb3a6381070B1a15169DEA646166EC0699fDAeA79',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1636446532287_31313732319066667.png',
            'coingeckoId': 'cyberdragon-gold',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'GAMMA',
            'name': 'Green Planet',
            'address': '0xb3Cb6d2f8f2FDe203a022201C81a96c167607F15',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20895/thumb/gamma.png?1638528110',
            'coingeckoId': 'green-planet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CTG',
            'name': 'City Tycoon Games',
            'address': '0xb3Ba14f6A482DfdeBC3c2FB726ac10df91eE504C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28118/thumb/ctg.png?1667566182',
            'coingeckoId': 'city-tycoon-games',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UV',
            'name': 'UnityVentures',
            'address': '0xb3A95BdBe4AC65B0628db1E6600F71ed59b89255',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19673/thumb/13575.png?1635739061',
            'coingeckoId': 'unityventures',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'DES',
            'name': 'DeSpace Protocol',
            'address': '0xb38b3c34e4bb6144c1e5283af720E046Ee833a2a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18517/thumb/NQQu-EsT_400x400.jpg?1632267572',
            'coingeckoId': 'despace-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CFA',
            'name': 'Coin Fast Alert  OLD ',
            'address': '0xb38A2A58911372fdB12e2D4E4da8714C7f652261',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23865/thumb/200x200.png?1645597239',
            'coingeckoId': 'coin-fast-alert',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AIDI',
            'name': 'Aidi Finance',
            'address': '0xb358b0e5A8943029e66175830D85198fE6cC93f6',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18979/thumb/aidi_200.png?1634096167',
            'coingeckoId': 'aidi-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOA',
            'name': 'Doaibu',
            'address': '0xb34FEEeC5be985BA9727B73cD24C82273a37CdD4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24467/thumb/200x200_DOA_Original.png?1650436738',
            'coingeckoId': 'doaibu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OCAH',
            'name': 'Omni Cash',
            'address': '0xb340F67E9Cc3927eBeEB04c2e03f74bd0543F4fc',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20868/thumb/Whats-App-Image-2021-11-25-at-02-58-12.png?1637808029',
            'coingeckoId': 'omni-cash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OSWAP',
            'name': 'OpenSwap',
            'address': '0xb32aC3C79A94aC1eb258f3C830bBDbc676483c93',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15785/thumb/openswap.PNG?1621847093',
            'coingeckoId': 'openswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METABOT',
            'name': 'Robot Warriors',
            'address': '0xb312b190Ef6f7d3608146c02B3617f5Ed9876300',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23629/thumb/logo-e1640961358255.jpg?1644823266',
            'coingeckoId': 'robot-warriors',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XSH',
            'name': 'X HASH',
            'address': '0xb30B27aDb3B0A45e88385eB2BB144Fad9120A420',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22168/thumb/QKhXXjAc_400x400.jpg?1641166854',
            'coingeckoId': 'x-hash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SMCW',
            'name': 'Space Misfits',
            'address': '0xb2ea51BAa12C461327d12A2069d47b30e680b69D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24394/thumb/6htFQIdk_400x400.jpg?1647521132',
            'coingeckoId': 'space-misfits',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'POLO',
            'name': 'NftyPlay',
            'address': '0xb28a7f8f5328FafFDd862985177583c2Bb71E016',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17572/thumb/polkaplay_logo_transparent_200.png?1628519230',
            'coingeckoId': 'polkaplay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PIRA',
            'name': 'Piratera',
            'address': '0xb27b68431C9A1819c8633fF75a2DD14f54799a21',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21979/thumb/PIRA_coin_copy.png?1640573411',
            'coingeckoId': 'piratera',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XRX',
            'name': 'Rex',
            'address': '0xb25583E5e2dB32b7FCbffe3f5e8E305C36157E54',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26434/thumb/token-icon-rex.jpg?1658107237',
            'coingeckoId': 'rex-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CMCX',
            'name': 'CORE MultiChain',
            'address': '0xb2343143f814639c9b1f42961C698247171dF34a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18848/thumb/O4IzY2CQ_400x400.png?1633590798',
            'coingeckoId': 'core',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIBIB',
            'name': 'Shiba Inu Billionaire',
            'address': '0xb21226a767F255d96163410Ff13B010B644dF0a6',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20375/thumb/PUD0R34.png?1636949698',
            'coingeckoId': 'shiba-inu-billionaire',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DYNMT',
            'name': 'Dynamite',
            'address': '0xb1ce906C610004E27e74415Aa9BCC90E46366F90',
            'decimals': 2,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/8951/thumb/dynamite_logo.jpg?1598851224',
            'coingeckoId': 'dynamite-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '4MOVE',
            'name': '4MOVE',
            'address': '0xb1c8b1307BFf2D335C96f87B6104CDf12a915149',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25933/thumb/200x200.png?1654758480',
            'coingeckoId': '4move',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOF',
            'name': 'Land of Fantasy',
            'address': '0xb1BcdAC12EaC178F4e1f221562a805C561599D48',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26547/thumb/LOF-LAND-LOGO-200x200.png?1658751656',
            'coingeckoId': 'land-of-fantasy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWT',
            'name': 'Smart Wallet',
            'address': '0xb1A5e3068837FCff1F7F2abF592a5dE7a20b2a40',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18159/thumb/%D0%9B%D0%BE%D0%B3%D0%BE%D1%82%D0%B8%D0%BF_%D0%BD%D0%B0_%D0%B1%D0%B5%D0%BB%D0%BE%D0%BC_%D1%84%D0%BE%D0%BD%D0%B5.png?1630742723',
            'coingeckoId': 'smart-wallet-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MESA',
            'name': 'myMessage',
            'address': '0xb192d5fC358D35194282a1a06814aba006198010',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21726/thumb/mymessage.PNG?1639964151',
            'coingeckoId': 'mymessage',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'OASIS',
            'name': 'ProjectOasis',
            'address': '0xb19289b436b2F7A92891ac391D8f52580d3087e4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18755/thumb/Oasis_Logo_1.2%28scaled%29.png?1656397634',
            'coingeckoId': 'project-oasis',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GABX',
            'name': 'Gabx Finance',
            'address': '0xb165b50332022E90f3f1AcF0da006280D8B3c9b5',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26415/thumb/GABX.png?1657925858',
            'coingeckoId': 'gabx-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WNK',
            'name': 'The Winkyverse',
            'address': '0xb160A5F19ebccd8E0549549327e43DDd1D023526',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22260/thumb/wnk.png?1644053369',
            'coingeckoId': 'the-winkyverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PASHA',
            'name': 'Pasha',
            'address': '0xb15C29Ac86459dF7Ce4Af76d0EfDE1746EEefD2a',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25689/thumb/f4nm6V_h8HBmNPQgDWoOcn6dwHUUJ5DNgf8W1VkW5h92NG2cjqkTqIp5ADdWMzvDXVR6zH-eLQ3qQxlJGwj4HD8EWOvXL-QByoIyV-51xEDh5i_bNQR5SxNBpsVZh8wsmmwm13GVij5l0ZQT3dBXt7MxFG9mIkff6vdX1Juc1OxqG_8wUcurdouajTqNAzsizP77gCuCH0UJJI-0YAAxawAg6x.jpg?1653375524',
            'coingeckoId': 'pasha',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BARK',
            'name': 'Barking',
            'address': '0xb1528a7BE5A96B77de5337988Ba69029cA6E2c7A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15828/thumb/bark.png?1622025223',
            'coingeckoId': 'barking',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DHLT',
            'name': 'DeHealth',
            'address': '0xb148DF3C114B1233b206160A0f2A74999Bb2FBf3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24839/thumb/DeHealth_FB.png?1658911320',
            'coingeckoId': 'dehealth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MELON',
            'name': 'Musk Melon',
            'address': '0xb14784b2a56945AED7b8CD41661D68F8b6CCeC8b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25089/thumb/MepNVeU.png?1650269351',
            'coingeckoId': 'musk-melon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHERRYPYE',
            'name': 'CHERRYPYE',
            'address': '0xb0de93914755A13f1581C6C0d54C74C45B474015',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26698/thumb/CherryPYE.png?1659609068',
            'coingeckoId': 'cherrypye',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STBU',
            'name': 'Stobox',
            'address': '0xb0c4080a8Fa7afa11a09473f3be14d44AF3f8743',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12637/thumb/exchange.png?1623764906',
            'coingeckoId': 'stobox-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARCHA',
            'name': 'ArchAngel',
            'address': '0xb0FF8188f374902BB180Bd186D17967B5B1188f2',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18814/thumb/ARCHA200x200.png?1633714225',
            'coingeckoId': 'archangel-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AUDIO',
            'name': 'Listenify',
            'address': '0xb0B2d54802B018B393A0086a34DD4c1f26F3D073',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27957/thumb/1666616072183-c9c2bce82b74978a3e87079f2ce6e52b.png?1666671944',
            'coingeckoId': 'listenify',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STARLY',
            'name': 'Starly',
            'address': '0xb0A480E2FA5AF51C733a0Af9FcB4De62Bc48c38B',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23713/thumb/15139.png?1645086528',
            'coingeckoId': 'starly',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INTD',
            'name': 'INTDESTCOIN  OLD ',
            'address': '0xb08bA4Ad6bC291f4F1E79C4c7f9395141B8D5797',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27134/thumb/INTD.png?1662025876',
            'coingeckoId': 'intdestcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PIZZA',
            'name': 'Pizza NFT',
            'address': '0xb07905396A419B121213efe1d17cfD0ff20aE597',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21368/thumb/mascot200.png?1639025579',
            'coingeckoId': 'pizza-nft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SKELETON',
            'name': 'Defiskeletons',
            'address': '0xb0688E82D162Df5288A0D986DfFd4CF80AFb7897',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26249/thumb/free-image-resizer-cropper_%285%29.png?1656935714',
            'coingeckoId': 'defiskeletons',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BET',
            'name': 'Betaverse',
            'address': '0xb04B8e2A10ce3147982242a8122CF3b61e2dFE7B',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27935/thumb/new-logo.png?1666509555',
            'coingeckoId': 'betaverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLOKICOKE',
            'name': 'FlokiCoke',
            'address': '0xb04Abb901A47A03A44533b80Fa02434440FC4fC3',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21924/thumb/FlokiCoke_LOGO_Square_200.png?1640269171',
            'coingeckoId': 'flokicoke',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QUOTH',
            'name': 'Quoth',
            'address': '0xb025BC1675F6BE04eC528574712f268D99dB494d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23922/thumb/quoth.jpeg?1648364696',
            'coingeckoId': 'quoth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KRD',
            'name': 'Krypton DAO',
            'address': '0xb020805e0Bc7F0e353D1343d67A239F417D57Bbf',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25467/thumb/krd.png?1651915442',
            'coingeckoId': 'krypton-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UCO',
            'name': 'Archethic',
            'address': '0xb001f1E7c8bda414aC7Cf7Ecba5469fE8d24B6de',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12330/thumb/Archethic_logo.png?1665916980',
            'coingeckoId': 'archethic',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'GREEN',
            'name': 'GreenAir',
            'address': '0xafcd56e0D0aD1a769db98F14D4149A78F52CE620',
            'decimals': 12,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22108/thumb/favicon.png?1640840660',
            'coingeckoId': 'greenair',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOONARCH',
            'name': 'Moonarch',
            'address': '0xaf96a19c2DD4a0f6B077D9481fCc8C9102FAa141',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15567/thumb/android-chrome-256x256.png?1621222240',
            'coingeckoId': 'moonarch',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PAMPTHER',
            'name': 'Pampther',
            'address': '0xaf6f4E5430F6739F85A1863434A17B9fCd4322b4',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16447/thumb/download_%283%29.png?1624238574',
            'coingeckoId': 'pampther',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MRI',
            'name': 'Marshall Inu',
            'address': '0xaf63D8032311FEF82c111c060420020f35E78111',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23784/thumb/mri.png?1647693409',
            'coingeckoId': 'marshall-rogan-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USV',
            'name': 'Atlas USV',
            'address': '0xaf6162DC717CFC8818eFC8d6f46a41Cf7042fCBA',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22066/thumb/7iUyjg5t.png?1640744823',
            'coingeckoId': 'atlas-usv',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'SPR',
            'name': 'Spring Game',
            'address': '0xaf0bd10E1b6B995E54F342C78DACd25291e0d3B2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26160/thumb/spring-logo.png?1656322979',
            'coingeckoId': 'spring-game',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WIN',
            'name': 'WINkLink BSC',
            'address': '0xaeF0d72a118ce24feE3cD1d43d383897D05B4e99',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xaeF0d72a118ce24feE3cD1d43d383897D05B4e99/logo.png',
            'coingeckoId': 'winklink-bsc',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'TOON',
            'name': 'Pontoon',
            'address': '0xaeE433ADeBe0FBB88dAa47eF0C1A513CaA52EF02',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19575/thumb/pontoon.PNG?1635467899',
            'coingeckoId': 'pontoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIBARROW',
            'name': 'Captain Shibarrow',
            'address': '0xae9e45B9a343888F6880fFd00389A16d7592ab1B',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22664/thumb/p3XyjmUp_400x400.jpg?1642402972',
            'coingeckoId': 'captain-shibarrow',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NXDT',
            'name': 'NXD Next',
            'address': '0xadf6d29572Af16dE3C44e6B89D2D8E0380044FA6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27578/thumb/nxdt.png?1664695725',
            'coingeckoId': 'nxd-next',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PSN',
            'name': 'Polkasocial Network',
            'address': '0xadB005659B1b99CbECC8B706750938Cad941290D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18906/thumb/polka.png?1633909804',
            'coingeckoId': 'polkasocial-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CPET',
            'name': 'Chain Pet',
            'address': '0xad326Fd5BC8B962063BA51FF142086b4aa1987ec',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22373/thumb/1_k7J_PpeFqdPM4TES4Pt_zA.jpeg?1641769379',
            'coingeckoId': 'chain-pet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MCRN',
            'name': 'MacaronSwap',
            'address': '0xacb2d47827C9813AE26De80965845D80935afd0B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xacb2d47827c9813ae26de80965845d80935afd0b.png',
            'coingeckoId': 'macaronswap',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FEG',
            'name': 'FEG BSC',
            'address': '0xacFC95585D80Ab62f67A14C566C1b7a49Fe91167',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14533/thumb/F-FEG-avatar-Light-03_4x-400x400-01_%281%29.png?1665915066',
            'coingeckoId': 'feg-token-bsc',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'PHX',
            'name': 'Phoenix Finance',
            'address': '0xac86e5f9bA48d680516df50C72928c2ec50F3025',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17675/thumb/phx_logo.png?1628832432',
            'coingeckoId': 'phoenix-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MONES',
            'name': 'Mones',
            'address': '0xac3050C7cc3Bd83fd5B53FB94C0bE385b1ca6D15',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26356/thumb/mones_logo.jpg?1657592678',
            'coingeckoId': 'mones',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TREAT',
            'name': 'TreatDAO  OLD ',
            'address': '0xac0C7d9B063eD2C0946982dDB378e03886C064E6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14418/thumb/treat_logo.png?1615961617',
            'coingeckoId': 'treatdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSR',
            'name': 'BinStarter',
            'address': '0xab287e6D370C61f105630e656B5468ACB4D00423',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17554/thumb/BSR200X200.png?1628227724',
            'coingeckoId': 'binstarter',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHINJA',
            'name': 'Shibnobi',
            'address': '0xab167E816E4d76089119900e941BEfdfA37d6b32',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20545/thumb/4yCR4DH.png?1655888898',
            'coingeckoId': 'shibnobi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUZZ',
            'name': 'BUZZ',
            'address': '0xaaDa984a97c9836114066b2a36401ACCd82d9871',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': 'buzz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MLB',
            'name': 'COAL',
            'address': '0xaa5A5A4d893b5F5Dc06e2200f8e46A92e3A18aAC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27127/thumb/Logo-200.png?1662020096',
            'coingeckoId': 'coal',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LAMEA',
            'name': 'LAMEA',
            'address': '0xaa44051bdd76e251aab66dbbe82a97343b4d7da3#code',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27119/thumb/LAMEA_logofekete_h%C3%A1tt%C3%A9r_200px.png?1662015860',
            'coingeckoId': 'lamea',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KOKU',
            'name': 'KOKU',
            'address': '0xaa39B60b5c4273f659674B6B34E3f187d16e7CfD',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xaa39B60b5c4273f659674B6B34E3f187d16e7CfD/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'BSCB',
            'name': 'BSCBAY',
            'address': '0xaa3387B36a4aCd9D2c1326a7f10658d7051b73a6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23998/thumb/bscb.png?1645958644',
            'coingeckoId': 'bscbay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAVEANIMAL',
            'name': 'SaveAnimal',
            'address': '0xaa2C290bF62B7d100D2D7f87b7223e3A71b737F7',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15907/thumb/200x200CG.png?1622413077',
            'coingeckoId': 'saveanimal',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABYETH',
            'name': 'BabyEth',
            'address': '0xaFfbF5D4693C93F23c35a08E31439Ea53d952351',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17891/thumb/BabyEth_Ticker.png?1629717507',
            'coingeckoId': 'babyeth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BRANA',
            'name': 'Branaverse',
            'address': '0xaFd9a60f8A91572E99CDEABfa062146aFA599FFa',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27150/thumb/BRANA_LOGO.png?1662104422',
            'coingeckoId': 'branaverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GVR',
            'name': 'Grove',
            'address': '0xaFb64E73dEf6fAa8B6Ef9a6fb7312d5C4C15ebDB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25549/thumb/200x200.png?1652335189',
            'coingeckoId': 'grove',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLUX',
            'name': 'Flux',
            'address': '0xaFF9084f2374585879e8B434C399E29E80ccE635',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1639192023550_488684033739881.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'MFLATE',
            'name': 'Memeflate',
            'address': '0xaFE3321309A994831884fc1725F4c3236AC79f76',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19598/thumb/mflate.PNG?1635489960',
            'coingeckoId': 'memeflate',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALIX',
            'name': 'AlinX',
            'address': '0xaF6Bd11A6F8f9c44b9D18f5FA116E403db599f8E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17871/thumb/1xzIPTW.png?1629694370',
            'coingeckoId': 'alinx',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'PROM',
            'name': 'Prom',
            'address': '0xaF53d56ff99f1322515E54FdDE93FF8b3b7DAFd5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xaF53d56ff99f1322515E54FdDE93FF8b3b7DAFd5/logo.png',
            'coingeckoId': 'prometeus',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MBP',
            'name': 'Mobipad',
            'address': '0xaF2F53cc6cc0384aba52275b0f715851Fb5AFf94',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26082/thumb/20182.png?1655708024',
            'coingeckoId': 'mobipad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MRFI',
            'name': 'Morphie',
            'address': '0xaF1167b1f90E4F27d9F520A4cD3a1e452e011Cea',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18818/thumb/FLPgwQdc_400x400.jpg?1633509975',
            'coingeckoId': 'morphie',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LKD',
            'name': 'LinkDao',
            'address': '0xaF027427DC6d31A3e7e162A710a5Fe27e63E275F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26446/thumb/linkdao200x200.png?1658110843',
            'coingeckoId': 'linkdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'C98',
            'name': 'Coin98',
            'address': '0xaEC945e04baF28b135Fa7c640f624f8D90F1C3a6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xaEC945e04baF28b135Fa7c640f624f8D90F1C3a6/logo.png',
            'coingeckoId': 'coin98',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HCC',
            'name': 'Hunter Crypto Coin',
            'address': '0xaE95Bab27DB001498c4985CEa5783f6a04e247D8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24957/thumb/hcc.png?1649509500',
            'coingeckoId': 'hunter-crypto-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VCE',
            'name': 'Vince Chain',
            'address': '0xaE936f3f89d2E8a86163f6fdbFEF8ae6b2BA6437',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24349/thumb/20220315-164422.png?1647433218',
            'coingeckoId': 'vince-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELT',
            'name': 'ECLAT',
            'address': '0xaE90CA218f9c3b1AA84af33A7907E4890Ec6A167',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27812/thumb/Eclat_Logo_-_100kb.png?1665907477',
            'coingeckoId': 'eclat',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LFG',
            'name': 'Lunar Flare',
            'address': '0xaE49E74D7EA6A27F1d6f9A95A050f89a068e40Fa',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26129/thumb/20720.png?1655960717',
            'coingeckoId': 'lunar-flare',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WEFIN',
            'name': 'eFin Decentralized',
            'address': '0xaE459484c895a335ceC08058290D94551DBf5fbB',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23117/thumb/We-Fin-Logo.png?1643266308',
            'coingeckoId': 'efin-decentralized',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RNB',
            'name': 'Rentible',
            'address': '0xaDEC335A2e3881303a9b0203eb99DE12202280dF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15176/thumb/Rentible-Token-Logo.png?1620025850',
            'coingeckoId': 'rentible',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHS',
            'name': 'CheeseSwap',
            'address': '0xaDD8A06fd58761A5047426e160B2B88AD3B9D464',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13605/thumb/chs-200px.png?1610273230',
            'coingeckoId': 'cheeseswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VIZSLASWAP',
            'name': 'VizslaSwap',
            'address': '0xaDAaE082237cB1772c9e079dB95c117E6Dd0C5aF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25809/thumb/E66D3A9B-3FB7-4D14-AAE9-7476F3C3F546.png?1653980204',
            'coingeckoId': 'vizslaswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORL',
            'name': 'Orlando Chain',
            'address': '0xaD21cf09549213c4f491A1e153D84104F3245957',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27212/thumb/orl-200-removebg-preview.png?1662609820',
            'coingeckoId': 'orlando-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MELI',
            'name': 'Meli Games',
            'address': '0xaD04AC36791d923DeB082dA4f91Ab71675dD18fB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20211/thumb/meli_games.PNG?1636668571',
            'coingeckoId': 'meli-games',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RBX',
            'name': 'RBX',
            'address': '0xaCE3574B8b054E074473a9Bd002e5dC6dd3dfF1B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xace3574b8b054e074473a9bd002e5dc6dd3dff1b.png',
            'coingeckoId': 'rbx-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ANI',
            'name': 'Anime',
            'address': '0xaC472D0EED2B8a2f57a6E304eA7eBD8E88D1d36f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13792/thumb/ani.png?1636333218',
            'coingeckoId': 'anime-token',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'FRZSS',
            'name': 'Frz Solar System',
            'address': '0xaC41Fb8013c0B63588fC63997785A5d79E73eb28',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24368/thumb/1100x800_cropped.jpg?1647441326',
            'coingeckoId': 'frz-solar-system',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'pOPEN',
            'name': 'pTokens OPEN',
            'address': '0xaBaE871B7E3b67aEeC6B46AE9FE1A91660AadAC5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xaBaE871B7E3b67aEeC6B46AE9FE1A91660AadAC5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'JW',
            'name': 'Jasan Wellness',
            'address': '0xaB785054251DB0fc44538F5DeeBE7507B748b692',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26143/thumb/jw.png?1656039344',
            'coingeckoId': 'jasan-wellness',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INR',
            'name': 'Inery',
            'address': '0xaB725d0A10C3f24725c89F5765Ae5794a26C1336',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26215/thumb/R9hQxqQK_400x400.jpg?1656577686',
            'coingeckoId': 'inery',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DAOVC',
            'name': 'DAOvc',
            'address': '0xaB6B429C73C22EcAbC763635DAce7efAC524993c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18259/thumb/daovc.PNG?1631156207',
            'coingeckoId': 'daovc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HVT',
            'name': 'HyperVerse',
            'address': '0xaAfA10755b3B1DbF46e86d973c3f27f3671ED9db',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23102/thumb/1_03RIVsRDc8ZvCojuBZ-obg.png?1643185572',
            'coingeckoId': 'hyperverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WST',
            'name': 'Wisteria Swap',
            'address': '0xaAdFf17d56d80312b392Ced903f3E8dBE5c3ece7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21767/thumb/200x200.png?1639993725',
            'coingeckoId': 'wisteria-swap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HAI',
            'name': 'Hacken HAI',
            'address': '0xaA9E582e5751d703F85912903bacADdFed26484C',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xaA9E582e5751d703F85912903bacADdFed26484C/logo.png',
            'coingeckoId': 'hackenai',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'XPRESS',
            'name': 'CryptoXpress',
            'address': '0xaA9826732f3A4973FF8B384B3f4e3c70c2984651',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20467/thumb/xvlKHAMJ_400x400.jpg?1637064606',
            'coingeckoId': 'cryptoexpress',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ROC',
            'name': 'Rocket Raccoon V2',
            'address': '0xaA8F550ED21aE4ecE978f4141c4551D1Deb7390A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27280/thumb/Rocket_Raccoon_Logo.png?1663136537',
            'coingeckoId': 'rocket-raccoon-v2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOG',
            'name': 'The Doge NFT',
            'address': '0xaA88C603d142C371eA0eAC8756123c5805EdeE03',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18111/thumb/Doge.png?1630696110',
            'coingeckoId': 'the-doge-nft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARKN',
            'name': 'Ark Rivals',
            'address': '0xaA20c2e278D99f978989dAa4460F933745F862d5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23852/thumb/logo1--1-.png?1645602721',
            'coingeckoId': 'ark-rivals',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WEX',
            'name': 'WaultSwap',
            'address': '0xa9c41A46a6B3531d28d5c32F6633dd2fF05dFB90',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xa9c41a46a6b3531d28d5c32f6633dd2ff05dfb90.png',
            'coingeckoId': 'waultswap',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BBETH',
            'name': 'BabyEthereum',
            'address': '0xa9a86dc63bE528F4C5D3AB28941a4A7120806d5e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20125/thumb/shVTIpHA_400x400.jpg?1636524823',
            'coingeckoId': 'babyethereum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MMON',
            'name': 'Mammon',
            'address': '0xa9F179C99567D6D671c3AC5eDf206F5fc647483f',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15664/thumb/Untitled-design-34-removebg-preview-2.png?1621490862',
            'coingeckoId': 'mommon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RENA',
            'name': 'Warena',
            'address': '0xa9D75Cc3405F0450955050C520843f99Aff8749D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18888/thumb/RENA_Web_Black_Square.png?1633748637',
            'coingeckoId': 'warena',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LAVAX',
            'name': 'LavaX Labs',
            'address': '0xa9BE3cd803Fa19F2af24412FF0a2a4a67a29dE88',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15494/thumb/image0.jpeg?1645583247',
            'coingeckoId': 'lavax-labs',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ESM',
            'name': 'EsportGame',
            'address': '0xa9A2589C2301456de4B100c79753E3b0Bdd4A0dd',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25078/thumb/04._ESPORT_GAME_1_NBG_NT.png?1650260960',
            'coingeckoId': 'esportgame',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YO',
            'name': 'YoHero',
            'address': '0xa98d662E1f7EB8f89a8f86c109b9cB61Ec2740bC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19680/thumb/1_-F2p9La1mYuEtYAm-tuSeQ.png?1635741910',
            'coingeckoId': 'yohero',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SUSHIBA',
            'name': 'Sushiba',
            'address': '0xa96658cd0D04a8fdCDc30D1156CC65BbFC7591eD',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xa96658cd0d04a8fdcdc30d1156cc65bbfc7591ed.png',
            'coingeckoId': 'sushiba',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'NZERO',
            'name': 'NETZERO',
            'address': '0xa94fb437B8BacB591c6b828BeF5A837AFe542100',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27676/thumb/Untitled_design_%281%29.png?1665211424',
            'coingeckoId': 'netzero',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FSXU',
            'name': 'FlashX Ultra',
            'address': '0xa94b7a842aADB617a0B08fA744e033C6De2f7595',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14118/thumb/200x200.png?1618282925',
            'coingeckoId': 'flashx-ultra',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARA',
            'name': 'Adora',
            'address': '0xa9243aeb1e1038273d479436d4F4DecE656c62F3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17713/thumb/Adora-200px-200px_rostsy.png?1629083234',
            'coingeckoId': 'adora-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KAOYA',
            'name': 'KAOYA',
            'address': '0xa8a33e365D5a03c94C3258A10Dd5d6dfE686941B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26647/thumb/logo_200.png?1659356039',
            'coingeckoId': 'kaoya',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFTK',
            'name': 'NFTWiki',
            'address': '0xa8F42a57d638FEa0286A28D75d7B10A6fDeDb41D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21970/thumb/PfGXwOcW_400x400.jpg?1640560379',
            'coingeckoId': 'nftwiki',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHINJA',
            'name': 'Shibnobi',
            'address': '0xa8Ea8D1d7A3e689ccc90DceCB437aB0674d5ec6f',
            'decimals': 9,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BR34P',
            'name': 'BR34P',
            'address': '0xa86d305A36cDB815af991834B46aD3d7FbB38523',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xa86d305a36cdb815af991834b46ad3d7fbb38523.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'EGT',
            'name': 'Evergreen',
            'address': '0xa865c6CC3A6164c3a0188efEED6F32ABa134693c',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19899/thumb/evergreen.PNG?1636094387',
            'coingeckoId': 'evergreen-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OLE',
            'name': 'OpenLeverage',
            'address': '0xa865197A84E780957422237B5D152772654341F3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0xa865197A84E780957422237B5D152772654341F3.png',
            'coingeckoId': 'openleverage',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'SAGAW',
            'name': 'SagaWorld',
            'address': '0xa84373988ead7EC5440A1a06792ea52BFDB3B048',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25686/thumb/output-onlinepngtools-1.png?1653374256',
            'coingeckoId': 'sagaworld',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PEFI',
            'name': 'Plant Empires',
            'address': '0xa83Bfcf9E252Adf1F39937984A4E113Eda6E445b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26159/thumb/20747.png?1656322889',
            'coingeckoId': 'plant-empires',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PRIMO',
            'name': 'Primo DAO',
            'address': '0xa7deaDE7Bbba2bf0AF1572A25B47E477749ef383',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25508/thumb/19700.png?1652161306',
            'coingeckoId': 'primo-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YY',
            'name': 'YinYang',
            'address': '0xa7Da7D9E572417Fca8a6CFE9a8F60a8a661E16ce',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27182/thumb/291773887_578212017275747_6363541010475065229_n.png?1662428560',
            'coingeckoId': 'yinyang',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTB',
            'name': 'MetaBridge',
            'address': '0xa7D8C187243Fbea88961f824d425dFce105b9Cd5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27265/thumb/%EB%A9%94%ED%83%80%EB%B8%8C%EB%A6%BF%EC%A7%80_%EC%8B%AC%EB%B2%8C%EB%A1%9C%EA%B3%A0%28PNG%29_200.png?1663060838',
            'coingeckoId': 'metabridge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SMAKI',
            'name': 'Smackerooni',
            'address': '0xa7D120D10fB120A9c7f098a34579420270bA2F97',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0xa7d120d10fb120a9c7f098a34579420270ba2f97/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'HOL',
            'name': 'Hololoot',
            'address': '0xa797Fa4BDa7C5A4B3afe73573B9d2Ab942365C6f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21538/thumb/hol.PNG?1639446836',
            'coingeckoId': 'hololoot',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METO',
            'name': 'Metafluence',
            'address': '0xa78775bba7a542F291e5ef7f13C6204E704A90Ba',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22998/thumb/Asset_1.png?1643087185',
            'coingeckoId': 'metafluence',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AMA',
            'name': 'MrWeb Finance',
            'address': '0xa77d560e34bD6A8d7265F754b4fcd65d9a8e5619',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27173/thumb/jd0i3xri_400x400.jpeg?1662352468',
            'coingeckoId': 'mrweb-finance-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BERA',
            'name': 'Bernaswap',
            'address': '0xa76a484658192cac6a0B7D135699BE2C480929aB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26665/thumb/logo_bernaswap.png?1659499347',
            'coingeckoId': 'bernaswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DINA',
            'name': 'Dina',
            'address': '0xa75e17A061Ed826C65F138B7A85B44c5D3156afF',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16126/thumb/200-2.png?1623044623',
            'coingeckoId': 'dina',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WMX',
            'name': 'Wombex',
            'address': '0xa75d9ca2a0a1D547409D82e1B06618EC284A2CeD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0xa75d9ca2a0a1D547409D82e1B06618EC284A2CeD.png',
            'coingeckoId': 'wombex',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'RYL',
            'name': 'King',
            'address': '0xa7556B9cC7588777E1Bb791D37162c7AbAD7BCfD',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0xa7556b9cc7588777e1bb791d37162c7abad7bcfd/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FDM',
            'name': 'Freedom',
            'address': '0xa74af19FC5f5996F34E8e2CB224A7a5c6C8d65B3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18548/thumb/200x200_%2831%29.png?1632363794',
            'coingeckoId': 'freedom',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOCK',
            'name': 'Sherlock365',
            'address': '0xa701d17B70FefC79F9Fe40B4b37085d2D7f418E1',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22757/thumb/cAvY6ha9_400x400.jpg?1642573430',
            'coingeckoId': 'sherlock365',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFU',
            'name': 'SAFE ZONE V2',
            'address': '0xa701EC6B9C1883fcF727FED7e41FE925A1b1E91C',
            'decimals': 9,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MILKY',
            'name': 'MilkyWayEx',
            'address': '0xa7002FCCC20F10a38c579A8D2D14D34F2A9a02A5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20471/thumb/milkywayex_32.png?1637100080',
            'coingeckoId': 'milkywayex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZVERSUS',
            'name': 'Z Versus Project',
            'address': '0xa6966B70F6d6Cc70a518550f156e81Fc11bd88f2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27695/thumb/zversus.png?1665294743',
            'coingeckoId': 'z-versus-project',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MGP',
            'name': 'Magic Power',
            'address': '0xa677bc9bDb10329E488A4d8387ED7a08b2fc9005',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22011/thumb/https-Mpg-com-Logo.jpg?1640590454',
            'coingeckoId': 'magic-power',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ILUS',
            'name': 'ILUS Coin',
            'address': '0xa6630B22974F908a98a8139CB12Ec2EbABfbe9D4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22662/thumb/Jez4bAM4.png?1642402319',
            'coingeckoId': 'ilus-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QFI',
            'name': 'QUESTRA FINANCE',
            'address': '0xa655048acaeC55AD14748c782111a7676f3A833e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27586/thumb/qfi.png?1664703234',
            'coingeckoId': 'questra-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRMX',
            'name': 'TourismX',
            'address': '0xa6472bC7C0E2266034BB40edD8c6E8961cF45826',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28164/thumb/trmx.png?1668170021',
            'coingeckoId': 'tourismx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DIWOT',
            'name': 'Digital World Travel Chain',
            'address': '0xa62b360D16732dA446f37AD203E5226F448Ca9b2',
            'decimals': 0,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0xa62b360d16732da446f37ad203e5226f448ca9b2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BFYC',
            'name': 'Bored Floki Yacht Club',
            'address': '0xa627Eb25dA4bCF6620DfF3f3fa87e4117eC71c6D',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23455/thumb/luBUevs.png?1644215035',
            'coingeckoId': 'bored-floki-yacht-club',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GRAV',
            'name': 'Graviton Zero',
            'address': '0xa6168c7E5Eb7C5c379f3A1d7Cf1073e09B2F031E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21844/thumb/kfw9xA0X_400x400.jpg?1640129436',
            'coingeckoId': 'graviton-zero',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZAMZAM',
            'name': 'ZAMZAM',
            'address': '0xa5e279E14eFd60a8F29e5ac3b464e3De0c6bB6B8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22671/thumb/d7168acd189f475ea38e113af81ebf41.png?1642404780',
            'coingeckoId': 'zamzam',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'F4H',
            'name': 'Fight 4 Hope',
            'address': '0xa5F8128d04692656a60f17B349C2146c48e6863a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27638/thumb/200x200.PNG?1664962911',
            'coingeckoId': 'fight-4-hope',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PEN',
            'name': 'Protocon',
            'address': '0xa5DeC77c4d1B4eba2807C9926b182812A0cBf9Eb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25175/thumb/pen_ticker.png?1650527396',
            'coingeckoId': 'protocon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PEPE',
            'name': 'Pepemoon',
            'address': '0xa5AC8f8E90762380cCE6C16ABa17Ed6d2Cf75888',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16067/thumb/pepemoon.PNG?1622765632',
            'coingeckoId': 'pepemoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASGARD',
            'name': 'AsgardToken',
            'address': '0xa5593837af2b99021fe657c80d70d2365F5CfD38',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0xa5593837af2b99021fe657c80d70d2365f5cfd38/logo.png',
            'coingeckoId': 'asgard-dao',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FLAG',
            'name': 'Flag Network',
            'address': '0xa5496935A247fA81B1462E553ad139d2FD0af795',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22552/thumb/17215.png?1642053401',
            'coingeckoId': 'flag-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DMC',
            'name': 'Decentralized Mining Exchange',
            'address': '0xa5342D72D04c133180f376753f90a4B2eEe29bB3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14130/thumb/1_hXrVwWTpmRJkPB6Aty0npQ.png?1614580808',
            'coingeckoId': 'decentralized-mining-exchange',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CARBO',
            'name': 'CleanCarbon',
            'address': '0xa52262dA176186105199a597aC8Cf094FF71b0C5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26311/thumb/cleancarbologo.png?1657238879',
            'coingeckoId': 'cleancarbon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RYOMA',
            'name': 'Ryoma',
            'address': '0xa4c53189EC5E6b14C0cc98651f6be8A2b4a749E6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27047/thumb/OabnykXw_400x400.jpeg?1661503607',
            'coingeckoId': 'ryoma',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FTM',
            'name': 'Fitmin',
            'address': '0xa4b6E76bba7413B9B4bD83f4e3AA63cc181D869F',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16111/thumb/gold.png?1623026639',
            'coingeckoId': 'fitmin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HOGE',
            'name': 'Hoge Finance',
            'address': '0xa4FFfc757e8c4F24E7b209C033c123D20983Ad40',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14360/thumb/hoge.jpg?1615641604',
            'coingeckoId': 'hoge-finance',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'wsHUMP',
            'name': 'Wrapped sHUMP',
            'address': '0xa4C6F8e1bE7418703934906057648Fa92A6Ddc34',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TRIAS',
            'name': 'TriasLab',
            'address': '0xa4838122c683f732289805FC3C207Febd55BabDD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13963/thumb/5ELqtwp__400x400_%281%29.jpg?1613527958',
            'coingeckoId': 'trias-token',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'GFNC',
            'name': 'GrafenoCoin',
            'address': '0xa47d87704d8fdE73A19eaB2A2343947aE8537b02',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/11881/thumb/1002992_%281%29.png?1595740445',
            'coingeckoId': 'grafenocoin-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BBS',
            'name': 'BBS Network',
            'address': '0xa477a79a118A84A0d371A53c8F46f8CE883EC1dD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xa477a79a118a84a0d371a53c8f46f8ce883ec1dd.png',
            'coingeckoId': 'bbs-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'AVG',
            'name': 'Avocado DAO',
            'address': '0xa41F142b6eb2b164f8164CAE0716892Ce02f311f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21102/thumb/logo192_%281%29.png?1640567889',
            'coingeckoId': 'avaocado-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SYN',
            'name': 'Synapse',
            'address': '0xa4080f1778e69467E905B8d6F72f6e441f9e9484',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18024/thumb/synapse_social_icon.png?1663921797',
            'coingeckoId': 'synapse-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RISE',
            'name': 'RiseHero',
            'address': '0xa3d80275E300237a62D3431C26d52f185584cC1B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23083/thumb/RiseHero-LOGO.jpg?1643180487',
            'coingeckoId': 'risehero',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GON',
            'name': 'Go Out Now',
            'address': '0xa3DE968553eE82194366b5cc676646FA8A4057B9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26073/thumb/APP-09_%282%29aa_%281%29.png?1655624888',
            'coingeckoId': 'go-out-now',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AAA',
            'name': 'Moon Rabbit',
            'address': '0xa39bF0446268D99C5c0A85ECF92970611912d386',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17310/thumb/logo_moon_no_inscriptions-01.png?1627275874',
            'coingeckoId': 'moon-rabbit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DRIVE',
            'name': 'Safe Drive',
            'address': '0xa321fC1171EB85498faD47D3d127368eBa70265C',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20897/thumb/drive.PNG?1637877141',
            'coingeckoId': 'safe-drive',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FCT',
            'name': 'Fistiana',
            'address': '0xa316f5ED04C3Cc6AfF914d9144e582B31c089FE9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26021/thumb/%E5%9B%BE%E7%89%87.png?1655373693',
            'coingeckoId': 'fistiana-circulates',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOGE',
            'name': 'DogeGaySon',
            'address': '0xa30D02C5CdB6a76e47EA0D65f369FD39618541Fe',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20631/thumb/14766.png?1637332567',
            'coingeckoId': 'dogegayson',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORIO',
            'name': 'Boorio',
            'address': '0xa30BAba694b8Fc3524C46edC5af295F55381dc60',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19749/thumb/8kZBYEzC_400x400.jpg?1635823078',
            'coingeckoId': 'boorio',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPC',
            'name': 'Spectra',
            'address': '0xa2f017966d967ec697C7A20Cf9D0b43CB8d4fF1D',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0xa2f017966d967ec697c7a20cf9d0b43cb8d4ff1d/logo.png',
            'coingeckoId': 'spectra',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'INJ',
            'name': 'Injective',
            'address': '0xa2B726B1145A4773F68593CF171187d8EBe4d495',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xe28b3b32b6c345a34ff64674606124dd5aceca30.png',
            'coingeckoId': 'injective-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CZBUSD',
            'name': 'CZbusd',
            'address': '0xa2A3Be47bEb973d48401010742D5E442177d037B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26174/thumb/czbusd.bf8b5691_%281%29.png?1656377910',
            'coingeckoId': 'czbusd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IBFR',
            'name': 'iBuffer',
            'address': '0xa296aD1C47FE6bDC133f39555C1D1177BD51fBc5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27782/thumb/Qk6pjeZ3_400x400.jpeg?1665737176',
            'coingeckoId': 'ibuffer',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ASSA',
            'name': 'AssaPlay',
            'address': '0xa25d074d5300f9F997a76994840A3266a72f77E4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25575/thumb/assa_coin_logo-02.png?1652676833',
            'coingeckoId': 'assaplay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GAIN',
            'name': 'Gain Protocol',
            'address': '0xa24e87efb0a6599C18F3710DB47f724ACef5254b',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17296/thumb/gain.PNG?1627263074',
            'coingeckoId': 'gain-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPY',
            'name': 'Snapy',
            'address': '0xa24808A57EA01a67A546A50D127aF9AFCdfeBE46',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28135/thumb/spy.png?1667734975',
            'coingeckoId': 'snapy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HYP',
            'name': 'Hyperchain',
            'address': '0xa1fd9467602DCb6fe48F8E9B150B5c96aF59aB24',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27563/thumb/photo_2022-09-23_14-41-41.jpg?1664520695',
            'coingeckoId': 'hyperchain-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRANE',
            'name': 'ProspectorCrane',
            'address': '0xa1fb785eC5D0d8A06C32B8d60dF7E2F5e3C62650',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28262/thumb/0DB25887-459D-4307-BDC0-CB92A9A2DA7E.jpeg?1668765902',
            'coingeckoId': 'prospectorcrane',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALPHA',
            'name': 'Alpha Venture DAO',
            'address': '0xa1faa113cbE53436Df28FF0aEe54275c13B40975',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xa1faa113cbe53436df28ff0aee54275c13b40975_1.png',
            'coingeckoId': 'alpha-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GEMZ',
            'name': 'BattleMechs',
            'address': '0xa1F0aC225De962e58a0ba3cc01c87A46cadf505f',
            'decimals': 0,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24658/thumb/GfSXR1v_%281%29.png?1648527600',
            'coingeckoId': 'battlemechs',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SATS',
            'name': 'Baby Satoshi',
            'address': '0xa1D04A189f8b6d5d64e8Fea7c38846AB6fa0F823',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17382/thumb/vR68Iyq.png?1628170659',
            'coingeckoId': 'baby-satoshi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HMRN',
            'name': 'Homerun',
            'address': '0xa1B57B528CAa42cdF4DBCf48F4EEEA29470Db3B6',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22702/thumb/G8qNfLO.jpg?1642411325',
            'coingeckoId': 'homerun',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STRIP',
            'name': 'Stripto',
            'address': '0xa1AC3b22b102CaA62c9ecAf418585528855B0DdD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23383/thumb/17856.png?1643963358',
            'coingeckoId': 'stripto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LMCSWAP',
            'name': 'Limocoin Swap',
            'address': '0xa1A6d574728C0125B730Cad5092B7D855F0bD920',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20943/thumb/limo.png?1638027455',
            'coingeckoId': 'limocoin-swap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AUTO',
            'name': 'Auto',
            'address': '0xa184088a740c695E156F91f5cC086a06bb78b827',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xa184088a740c695e156f91f5cc086a06bb78b827.png',
            'coingeckoId': 'auto',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'POOP',
            'name': 'Poochain',
            'address': '0xa1611E8D4070dee36EF308952CF34255c92a01c5',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26790/thumb/poochain_1.png?1660103857',
            'coingeckoId': 'poochain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WNNW',
            'name': 'WinNow',
            'address': '0xa1578dAf3EEB1ACCC3767C687cd5A0d9Df8B20Cb',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21423/thumb/cg.png?1639115633',
            'coingeckoId': 'winnow',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PMLG',
            'name': 'Polychain Monsters Genesis',
            'address': '0xa14c04DEa16798Aa8F25b1Da583cd5fBBFd6579E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25396/thumb/19329.png?1651710855',
            'coingeckoId': 'polychain-monsters-genesis',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BULK',
            'name': 'Bulk Network',
            'address': '0xa143ac515dCA260A46C742C7251eF3B268639593',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15266/thumb/Bulk_whale_round.png?1620285364',
            'coingeckoId': 'bulk-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SIF',
            'name': 'Sif',
            'address': '0xa120EF497039bd269b621Cdf31e7E9bFEf1c80a7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21329/thumb/sif.PNG?1638946478',
            'coingeckoId': 'sif',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOC',
            'name': 'SociLink',
            'address': '0xa0e9f52473AD1Eb681F1011ad51b4F16c12A8Ce6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23656/thumb/utsOLSmX_400x400.png?1644962776',
            'coingeckoId': 'socilink',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIBAKEN',
            'name': 'Shibaken Finance',
            'address': '0xa0cB0Ce7C6d93A7EBD72952Feb4407Dddee8a194',
            'decimals': 0,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15413/thumb/shibak.png?1660902286',
            'coingeckoId': 'shibaken-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEPAY',
            'name': 'DePay',
            'address': '0xa0bEd124a09ac2Bd941b10349d8d224fe3c955eb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13183/thumb/DEPAY.png?1650615816',
            'coingeckoId': 'depay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '$Apes',
            'name': 'Thunder Apes',
            'address': '0xa0F6C82bB4B09872dF3cc8369F9ED672AE8d3fAf',
            'decimals': 2,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0xa0f6c82bb4b09872df3cc8369f9ed672ae8d3faf/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'HPO',
            'name': 'Hippo Wallet',
            'address': '0xa0ED3C520dC0632657AD2EaaF19E26C4fD431a84',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28089/thumb/b77170dd-1dd1-4581-91b2-e352794fa045.jpg?1667446200',
            'coingeckoId': 'hippopotamus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CBFINU',
            'name': 'CBFINU',
            'address': '0xa0E1399de9eDf5A85a06A5CE34F8810Eb62EF40E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28264/thumb/20221117_174905.png?1668766351',
            'coingeckoId': 'cbfinu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ABCD',
            'name': 'Crypto Inu',
            'address': '0xa0CC3A881AEf241d6cB3B7Db3168BD26094560BE',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23449/thumb/logo_256px.png?1644214072',
            'coingeckoId': 'crypto-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REX',
            'name': 'REX Coin',
            'address': '0xa0C324b15EfeF0dC13163aaf60470EDE64D17B83',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MOOO',
            'name': 'Hashtagger',
            'address': '0xa0B9bB05DA11E3b19FfD64554400F59d4A378515',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21139/thumb/Hashtagger_Logo_No_text_200_200.png?1638384345',
            'coingeckoId': 'hashtagger',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ABC',
            'name': 'Abell Coin',
            'address': '0xa0B0bd446F76F66E4791631431f4034D08F4a00c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20673/thumb/QmPzxUCBJgBsTDnUGhnT1MhXXPi4xoCm2one622RJysx12.png?1637549525',
            'coingeckoId': 'abell-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARDN',
            'name': 'Ariadne',
            'address': '0xa0A9961b7477D1a530f06a1ee805d5E532e73d97',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19401/thumb/Frame_5_%287%29_%281%29.png?1635176608',
            'coingeckoId': 'ariadne',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FUNEX',
            'name': 'Funex',
            'address': '0xa07C39f8dF11cA675f77EfC19501e3Dddacd03Ed',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26814/thumb/20220809_135138.png?1660276769',
            'coingeckoId': 'funex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'mNFLX',
            'name': 'Mirror NFLX Token',
            'address': '0xa04F060077D90Fe2647B61e4dA4aD1F97d6649dc',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xa04F060077D90Fe2647B61e4dA4aD1F97d6649dc/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GHNY',
            'name': 'Grizzly Honey',
            'address': '0xa045E37a0D1dd3A45fefb8803D22457abc0A728a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xa045e37a0d1dd3a45fefb8803d22457abc0a728a.png',
            'coingeckoId': 'grizzly-honey',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BEPIS',
            'name': 'BEPIS',
            'address': '0xa031d93DD0dC21Bb799D3f7780e9D29df5210114',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22357/thumb/bepis_200x200.png?1641558358',
            'coingeckoId': 'bepis',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'BGVT',
            'name': 'Bit Game Verse Token',
            'address': '0xa03110800894b3CcF8723D991d80875561F96777',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27975/thumb/bgvt.png?1667124585',
            'coingeckoId': 'bit-game-verse-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFEHAMSTERS',
            'name': 'SafeHamsters',
            'address': '0xa016F295A5957cB80D03d8E5464A429007555124',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15831/thumb/QpwIAatV_400x400.jpg?1622066720',
            'coingeckoId': 'safehamsters',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ESHIB',
            'name': 'Euro Shiba Inu',
            'address': '0xFfaA85705aE216363e4e843B67fF3C238Fcf0de2',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26955/thumb/200_png.png?1660994255',
            'coingeckoId': 'euro-shiba-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VSN',
            'name': 'Vision',
            'address': '0xFfC71bB355770b5aBcC33ff3e652F63A1DfF442E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0xffc71bb355770b5abcc33ff3e652f63a1dff442e/a59895e7f12aae1c639d6a2e4baafbec.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CAPS',
            'name': 'Ternoa',
            'address': '0xFfBa7529AC181c2Ee1844548e6D7061c9A597dF4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xffba7529ac181c2ee1844548e6d7061c9a597df4.png',
            'coingeckoId': 'coin-capsule',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'KIK',
            'name': 'Kikswap',
            'address': '0xFef234C90b01B121C636e3C06E24cAdcA9D6404f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22274/thumb/kikswap.PNG?1641344877',
            'coingeckoId': 'kikswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '8PAY',
            'name': '8Pay',
            'address': '0xFeea0bDd3D07eb6FE305938878C0caDBFa169042',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xfeea0bdd3d07eb6fe305938878c0cadbfa169042.png',
            'coingeckoId': '8pay',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'HZD',
            'name': 'Horizon Dollar',
            'address': '0xFeFBBeEBBeEF18E3E4A2909F2B2729cA2fC61347',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15234/thumb/hzd.png?1639045268',
            'coingeckoId': 'horizondollar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ES2',
            'name': 'EverSAFUv2',
            'address': '0xFeF0c1670cb569008bB3d070918922dBb4F92De4',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27878/thumb/IMG_20221018_193306_362.png?1666230019',
            'coingeckoId': 'eversafuv2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COPI',
            'name': 'Cornucopias',
            'address': '0xFeA292e5ea4510881bDB840E3CeC63aBd43f936f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21452/thumb/g56WwJDA_400x400.jpg?1639174968',
            'coingeckoId': 'cornucopias',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MMI',
            'name': 'Midas Miner',
            'address': '0xFdcaBD329dc59615dca6Df02Ff942C047468E61C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27177/thumb/MidasMiner.png?1662385826',
            'coingeckoId': 'midas-miner',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HELIX',
            'name': 'Helix',
            'address': '0xFd9B1448A8874b03e6E8476049dB259A82569a41',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DIBS',
            'name': 'Dibs Money',
            'address': '0xFd81Ef21EA7CF1dC00e9c6Dd261B4F3BE0341d5c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22184/thumb/Group_764_4.png?1641182000',
            'coingeckoId': 'dibs-money',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ONT',
            'name': 'Binance Peg Ontology',
            'address': '0xFd7B3A77848f1C2D67E05E54d78d174a0C850335',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xfd7b3a77848f1c2d67e05e54d78d174a0c850335.png',
            'coingeckoId': 'binance-peg-ontology',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ANON',
            'name': 'Anonymous BSC',
            'address': '0xFd78aAEd1e3f2d06DbDE9510C6dC14112eCA896D',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15637/thumb/logo-main.png?1621404387',
            'coingeckoId': 'anonymous-bsc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FATOSHI',
            'name': 'Fat Satoshi',
            'address': '0xFceDD1291086CAD50f15606c7674923EAaFb2395',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22523/thumb/logo_200x200.png?1641970593',
            'coingeckoId': 'fat-satoshi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BEFX',
            'name': 'Belifex',
            'address': '0xFcab93b4b98Ac0F9C65A724B10cd5Ed1892bB9D7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/9547/thumb/belifex.png?1586748867',
            'coingeckoId': 'belifex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BULL',
            'name': 'BullDog Inu',
            'address': '0xFc93C1BDD261a377c850a13e797a296380B495b0',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27581/thumb/bull.png?1664699009',
            'coingeckoId': 'bulldog-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORO',
            'name': 'Operon Origins',
            'address': '0xFc4f5A4d1452B8Dc6C3CB745dB15B29c00812b19',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20965/thumb/oro.png?1638152385',
            'coingeckoId': 'operon-origins',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RMARS',
            'name': 'REDMARS',
            'address': '0xFc2037829F497ff68cc62771270ED1f409668439',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23980/thumb/rmars.png?1645878084',
            'coingeckoId': 'redmars',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZIN',
            'name': 'ZomaInfinity',
            'address': '0xFbe0b4aE6E5a200c36A341299604D5f71A5F0a48',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0xfbe0b4ae6e5a200c36a341299604d5f71a5f0a48/3554ec18a8ddc80511dac8054a99ea5b.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ZEDXION',
            'name': 'Zedxion',
            'address': '0xFbC4f3f645C4003a2E4F4e9b51077d2DaA9a9341',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18841/thumb/ZEDXION.png?1633585913',
            'coingeckoId': 'zedxion',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VGO',
            'name': 'Virgo',
            'address': '0xFb526228ff1C019E4604C7e7988C097D96bD5b70',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19209/thumb/vgoLogo200.png?1634689795',
            'coingeckoId': 'virgo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARTRINO',
            'name': 'Art Rino',
            'address': '0xFb2F0041441Ed3b96a0972a7f2668F3CbCaC7AAA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24346/thumb/Artrino.png?1647418160',
            'coingeckoId': 'art-rino',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HRO',
            'name': 'CryptoDiceHero',
            'address': '0xFb1a34eB2585B0AD7976420D7A21eF2f4AebeEb6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20952/thumb/zQA_yvEK_400x400.jpg?1638138694',
            'coingeckoId': 'cryptodicehero',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFT',
            'name': 'NEFTiPEDiA',
            'address': '0xFaAb744dB9def8e13194600Ed02bC5D5BEd3B85C',
            'decimals': 16,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16986/thumb/nft.PNG?1625956786',
            'coingeckoId': 'neftipedia',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CUSD',
            'name': 'Coin98 Dollar',
            'address': '0xFa4BA88Cf97e282c505BEa095297786c16070129',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26588/thumb/CUSD-01.png?1658909049',
            'coingeckoId': 'coin98-dollar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KPHI',
            'name': 'Kephi Gallery',
            'address': '0xFa4A5C4ce029FD6872400545df44675219C2e037',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17696/thumb/XJb3jROS_400x400.jpg?1628978969',
            'coingeckoId': 'kephi-gallery',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'WZRD',
            'name': 'Wizardia',
            'address': '0xFa40d8FC324bcdD6Bbae0e086De886c571C225d4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0xFa40d8FC324bcdD6Bbae0e086De886c571C225d4.png',
            'coingeckoId': 'wizardia',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'HONEY',
            'name': 'HoneyFarm Finance',
            'address': '0xFa363022816aBf82f18a9C2809dCd2BB393F6AC5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17551/thumb/download_%2830%29.png?1628226855',
            'coingeckoId': 'honeyfarm-finance',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'CRDN',
            'name': 'Cardence',
            'address': '0xFa17b330bCC4e7F3E2456996d89A5a54AB044831',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17744/thumb/logo_-_2021-08-17T084037.897.png?1629160880',
            'coingeckoId': 'cardence',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XTM',
            'name': 'XTime',
            'address': '0xFF2BF41EC57b897c914E2BAac857D621f4CB1691',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19049/thumb/xtime.PNG?1634258022',
            'coingeckoId': 'xtime',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INFP',
            'name': 'InfinityPad',
            'address': '0xFE82EfF54A58c21ffc9523c4998D5DAD84dcbD50',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19396/thumb/infinity.PNG?1635152171',
            'coingeckoId': 'infinitypad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FBB',
            'name': 'Foxboy',
            'address': '0xFE0927f43a4f0A0DC7EE946859d7109A9a090257',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21119/thumb/h6g4-dsp_400x400.jpg?1638340812',
            'coingeckoId': 'foxboy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METACAR',
            'name': 'Meta Car',
            'address': '0xFDd7c878e44ef9Eb95a0C4D40d20ceCA65E674A3',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23446/thumb/YkjaZ6M.jpeg?1644213391',
            'coingeckoId': 'meta-car',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SRV',
            'name': 'Survivor',
            'address': '0xFDCe3EBB2f5f703f350DFC08aFC014848F1BF48A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27755/thumb/SURVIVOR-COIN_%283%29.png?1665824148',
            'coingeckoId': 'survivor',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MVRS',
            'name': 'Meta MVRS ',
            'address': '0xFD9FacA15558eb243aC0012Cf7ACfBf480574668',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1641123160525_6233421079814339.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'FBX',
            'name': 'ForthBox',
            'address': '0xFD57aC98aA8E445C99bc2C41B23997573fAdf795',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21123/thumb/ForthBox_logo-200_200.png?1649751081',
            'coingeckoId': 'forthbox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KAREN',
            'name': 'KarenCoin',
            'address': '0xFD42728b76772A82cCaD527E298Dd15A55F4DdD6',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16191/thumb/karen.PNG?1623274611',
            'coingeckoId': 'karencoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MQST',
            'name': 'MonsterQuest',
            'address': '0xFD0ed86319BbF02359266d5Fa1cF10BC1720B2e0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22912/thumb/o3Dcp_Qi_400x400.jpg?1642984196',
            'coingeckoId': 'monsterquest',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZMN',
            'name': 'ZMINE',
            'address': '0xFCb8a4B1a0B645e08064e05B98E9cc6f48D2aa57',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/2103/thumb/zmn.png?1547036420',
            'coingeckoId': 'zmine',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POG',
            'name': 'PolygonumOnline',
            'address': '0xFCb0f2D2f83a32A847D8ABb183B724C214CD7dD8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21053/thumb/pog.png?1638261470',
            'coingeckoId': 'pog-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STK',
            'name': 'Sunflower Token',
            'address': '0xFC856957149478e508521e4E68D43F309D13B8FA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27124/thumb/sunflower-mother.png?1662018718',
            'coingeckoId': 'sunflower-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PIZA',
            'name': 'Half Pizza',
            'address': '0xFC646D0B564bf191B3d3adF2B620a792E485e6Da',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17785/thumb/piza.PNG?1629250720',
            'coingeckoId': 'halfpizza',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DBAY',
            'name': 'DefiBay',
            'address': '0xFC563895C1D5BB779685fB3d2ec09f5Fa5B6473c',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22705/thumb/g49IDMK.png?1642423668',
            'coingeckoId': 'defibay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ROCKS',
            'name': 'Rocks Idle Game',
            'address': '0xFC4ed6Fc4c0BB0432ec11701685578aE5E620C38',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15432/thumb/rocks-logo-black.png?1620797554',
            'coingeckoId': 'rocks-idle-game',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AMA',
            'name': 'MrWeb Finance  OLD ',
            'address': '0xFC3dA4A1b6faDaB364039525dD2AB7c0c16521cd',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15285/thumb/TVocZFCRZ6tg8MqKCKXzZ9H2qSg29T75tK.png?1621114070',
            'coingeckoId': 'mrweb-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CNAME',
            'name': 'Cloudname',
            'address': '0xFC3514474306e2D4aa8350fD8FA9c46c165fe8cD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23868/thumb/_NsbVq86_400x400.jpg?1645598280',
            'coingeckoId': 'cloudname',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FZS',
            'name': 'Fozeus Coin',
            'address': '0xFC225919d42999d23DE3B80cEd99f427f97E779A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28137/thumb/20221106_210629.png?1667785575',
            'coingeckoId': 'fozeus-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ROC',
            'name': 'Rocket Raccoon',
            'address': '0xFC111b40aD299572f74F1C119c036508C621BB19',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19623/thumb/racoon.PNG?1635498212',
            'coingeckoId': 'rocket-raccoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRACE',
            'name': 'Coinracer',
            'address': '0xFBb4F2f342c6DaaB63Ab85b0226716C4D1e26F36',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19529/thumb/13376.png?1635372064',
            'coingeckoId': 'coinracer',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SA',
            'name': 'Superalgos',
            'address': '0xFB981ED9A92377CA4d75D924b9CA06df163924fd',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xfb981ed9a92377ca4d75d924b9ca06df163924fd.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SEA',
            'name': 'Sea',
            'address': '0xFB52FC1f90Dd2B070B9Cf7ad68ac3d68905643fa',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xfb52fc1f90dd2b070b9cf7ad68ac3d68905643fa.png',
            'coingeckoId': 'sea',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'DINKO',
            'name': 'Dinko Dating DeFi',
            'address': '0xFB32b987dd069361E3F54d5c09c9BfbC63FA0cEf',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0xfb32b987dd069361e3f54d5c09c9bfbc63fa0cef/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'WEYU',
            'name': 'WEYU',
            'address': '0xFAfD4CB703B25CB22f43D017e7e0d75FEBc26743',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18112/thumb/weyu.PNG?1630542552',
            'coingeckoId': 'weyu',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'FOX',
            'name': 'Fox Finance',
            'address': '0xFAd8E46123D7b4e77496491769C167FF894d2ACB',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xfad8e46123d7b4e77496491769c167ff894d2acb.png',
            'coingeckoId': 'fox-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'DOTX',
            'name': 'DeFi of Thrones',
            'address': '0xFAb5a05C933f1A2463E334E011992E897D56eF0a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12265/thumb/logo200x200.jpg?1598599911',
            'coingeckoId': 'deli-of-thrones',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ACE',
            'name': 'ACEToken',
            'address': '0xFAaA87943bFca6D97434bE3d26C589647FEA4375',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23990/thumb/ace.png?1645949619',
            'coingeckoId': 'acetoken',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'AFNTY',
            'name': 'Affinity',
            'address': '0xFACE67C5CE2bb48c29779b0Dede5360cC9ef5fd5',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27345/thumb/YPtUpB2I_400x400.jpeg?1663647788',
            'coingeckoId': 'affinity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NBL',
            'name': 'Notable',
            'address': '0xFAA0fC7B803919B091dBe5FF709b2dAbb61b93d9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21613/thumb/Progetto_senza_titolo_%284%29.png?1668677309',
            'coingeckoId': 'notable',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PTS',
            'name': 'Petals',
            'address': '0xFA53a4778431712af31a11621eDee4D0926dF1aC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26154/thumb/pts.png?1656078078',
            'coingeckoId': 'petals',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RGP',
            'name': 'Rigel Protocol',
            'address': '0xFA262F303Aa244f9CC66f312F0755d89C3793192',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/9225.png',
            'coingeckoId': 'rigel-protocol',
            'listedIn': [
                'coingecko',
                'RouterProtocol'
            ]
        },
        {
            'symbol': 'METAS',
            'name': 'Metaseer',
            'address': '0xFA1e1754BD2896D467930C97d07aF799C531Cb7D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17443/thumb/metas.PNG?1627863142',
            'coingeckoId': 'metaseer',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YIELD',
            'name': 'Yield Protocol',
            'address': '0xF9d906a8dD25c4A4966bC075cdc946702219E62C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14220/thumb/yield.png?1615030969',
            'coingeckoId': 'yield-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOFI',
            'name': 'Doge Floki Coin',
            'address': '0xF9d6DDF44016953dBf7aB135A0F64d7A41870EDE',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19563/thumb/dofi.png?1635408618',
            'coingeckoId': 'doge-floki-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AUNI',
            'name': 'Aqua Unicorn',
            'address': '0xF9E5994d257102CA973db682adf435a0F78C5B4D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28083/thumb/Ov2LToF_%281%29.png?1667443713',
            'coingeckoId': 'aqua-unicorn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADMC',
            'name': 'Adamant Coin',
            'address': '0xF978bAa68aEe5Cd5425187BcE97f75C2817E6892',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19298/thumb/200x200-logo.png?1656132785',
            'coingeckoId': 'adamant-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EGG',
            'name': 'Goose Finance',
            'address': '0xF952Fc3ca7325Cc27D15885d37117676d25BfdA6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xf952fc3ca7325cc27d15885d37117676d25bfda6.png',
            'coingeckoId': 'goose-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'LFG',
            'name': 'Gamerse',
            'address': '0xF93f6b686f4A6557151455189a9173735D668154',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19582/thumb/gamerse.PNG?1635470285',
            'coingeckoId': 'gamerse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNO',
            'name': 'Unobtainium',
            'address': '0xF9372B1E797EcE08ECF5597EBa2a73BA61a28e94',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26103/thumb/logo_1.png?1655871926',
            'coingeckoId': 'unobtainium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNV',
            'name': 'Unvest',
            'address': '0xF915fDDa4c882731C0456a4214548Cd13A822886',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18119/thumb/unvest.PNG?1630554868',
            'coingeckoId': 'unvest',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EXZO',
            'name': 'ExzoCoin 2 0',
            'address': '0xF8fC63200e181439823251020d691312FDcF5090',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16279/thumb/cropped-exzocoin-favicon-cryptocurrency.png?1623635975',
            'coingeckoId': 'exzocoin',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'COLX',
            'name': 'ColossusXT',
            'address': '0xF8aCF86194443DCde55fc5B9e448e183c290D8Cb',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/966/thumb/colossusxt.png?1547034843',
            'coingeckoId': 'colossuscoinxt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LINK',
            'name': 'Chainlink',
            'address': '0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x514910771af9ca656af840dff83e8264ecf986ca.png',
            'coingeckoId': 'chainlink',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MBD',
            'name': 'MBD Financials',
            'address': '0xF899E83E6c6dF1a0d4887CF0209193aA97236322',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25617/thumb/mbd-transparent_%281%29.png?1652856277',
            'coingeckoId': 'mbd-financials',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FIBO',
            'name': 'FiboDex',
            'address': '0xF892561596B7b8085fAd1b03b902D00096AE31aD',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24745/thumb/logo.png?1648783762',
            'coingeckoId': 'fibodex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KLT',
            'name': 'Kamaleont',
            'address': '0xF87940f78F2f4d99A0c5c22e3fCC21795Cd53245',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26386/thumb/IMG_20220712_140305_799.jpg?1657680196',
            'coingeckoId': 'kamaleont',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FIREROCKET',
            'name': 'FireRocket',
            'address': '0xF8740cAA02D45410De2DA9823C5d8b5924bc3B52',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20384/thumb/rocketfire.PNG?1636959250',
            'coingeckoId': 'firerocket',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ABS',
            'name': 'Autobusd',
            'address': '0xF83849122F769A0a7386Df183E633607C890f6c0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21029/thumb/88858579.png?1638230804',
            'coingeckoId': 'autobusd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUCA',
            'name': 'Lucrosus Capital',
            'address': '0xF82AA46120314904Cd8119DAc84f6bcC7D90ED2e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22445/thumb/luca.png?1644047967',
            'coingeckoId': 'lucrosus-capital',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOTT',
            'name': 'LOT TRADE',
            'address': '0xF81628EDeb110a73c016ab7aFA57d80afAe07f59',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21080/thumb/logo-small.png?1638309089',
            'coingeckoId': 'lot-trade',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HTC',
            'name': 'Hat Swap City',
            'address': '0xF7cEB6394c9C96CA2Ce9afbE2Fac4783f4CeCD78',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20538/thumb/HAT_SWAP_CITY_LOGO.jpeg?1637203208',
            'coingeckoId': 'hat-swap-city',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASVA',
            'name': 'Asva Labs',
            'address': '0xF7b6d7E3434cB9441982F9534E6998C43eEF144a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22134/thumb/1_YAFDez1BKcVUHzwDoYcgPQ.png?1640907036',
            'coingeckoId': 'asva',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ACK',
            'name': 'AcknoLedger',
            'address': '0xF7B5fb4607ABfe0Ecf332c23cBdCc9E425B443A8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19110/thumb/179336914_101598385424354_5729429129362932203_n.jpeg?1634514417',
            'coingeckoId': 'acknoledger',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METASFM',
            'name': 'MetaSafeMoon',
            'address': '0xF7A086BFf67DeD4Aa785e8a0a81D4345d9bb4740',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21257/thumb/logo-200x200.png?1638778766',
            'coingeckoId': 'metasafemoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OM',
            'name': 'MANTRA',
            'address': '0xF78D2e7936F5Fe18308A3B2951A93b6c4a41F5e2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xf78d2e7936f5fe18308a3b2951a93b6c4a41f5e2.png',
            'coingeckoId': 'mantra-dao',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'NFTART',
            'name': 'NFT Art Finance',
            'address': '0xF7844CB890F4C339c497aeAb599aBDc3c874B67A',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xf7844cb890f4c339c497aeab599abdc3c874b67a.png',
            'coingeckoId': 'nft-art-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ROOBEE',
            'name': 'Roobee',
            'address': '0xF77351D8f4eE853135961A936BB8d2e4fFa75f9D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/8791/thumb/Group_11.png?1580344629',
            'coingeckoId': 'roobee',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MOONSHIB',
            'name': 'The Moon Shiba',
            'address': '0xF751f649D08dDb5Ea383487B33653BB26eEbbB43',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20575/thumb/logo_%281%29.png?1637244227',
            'coingeckoId': 'the-moon-shiba',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GNT',
            'name': 'GreenTrust',
            'address': '0xF750A26EB0aCf95556e8529E72eD530f3b60f348',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xf750a26eb0acf95556e8529e72ed530f3b60f348.png',
            'coingeckoId': 'greentrust',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'TCG2',
            'name': 'TCGCoin 2 0',
            'address': '0xF73d8276C15Ce56b2f4AeE5920e62F767A7f3aEA',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18035/thumb/TCG2.jpg?1630306761',
            'coingeckoId': 'tcgcoin-2-0',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CVT',
            'name': 'Crypto Vault',
            'address': '0xF72B0F79dC66f270FE52C67e56e12872F86cae2d',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24267/thumb/cvt.png?1647172777',
            'coingeckoId': 'crypto-vault',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MLOKY',
            'name': 'MLOKY',
            'address': '0xF71E950758310faF9f7D51C4F4250C7546086C1f',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24602/thumb/mloky.png?1648366035',
            'coingeckoId': 'mloky',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LSHARE',
            'name': 'LSHARE',
            'address': '0xF70B6D6AcD652612f24F7DD2CA2F1727eB20793a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'GQ',
            'name': 'Outer Ring MMO',
            'address': '0xF700D4c708C2be1463E355F337603183D20E0808',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0xF700D4c708C2be1463E355F337603183D20E0808.png',
            'coingeckoId': 'outer-ring',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'SYNC',
            'name': 'LinkSync',
            'address': '0xF6a22B0593df74F218027A2d8b7953c9b4542AA1',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19283/thumb/sync.png?1639204152',
            'coingeckoId': 'linksync',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CNS',
            'name': 'Centric Swap',
            'address': '0xF6Cb4ad242BaB681EfFc5dE40f7c8FF921a12d63',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/11864/thumb/CNS.png?1626960631',
            'coingeckoId': 'centric-cash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANY',
            'name': 'Anyswap',
            'address': '0xF68C9Df95a18B2A5a5fa1124d79EEEffBaD0B6Fa',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xf99d58e463a2e07e5692127302c20a191861b4d6.png',
            'coingeckoId': 'anyswap',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'HYVE',
            'name': 'Hyve',
            'address': '0xF6565A97Dc832d93DC83B75EE9aa5c7e8ecB0F9d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13072/thumb/bAe1G-lD_400x400.png?1654056255',
            'coingeckoId': 'hyve',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'DFIAT',
            'name': 'DeFiato',
            'address': '0xF64eD9Ad397A1Ae657F31131d4B189220A7f1cc7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13386/thumb/Defiato.png?1608085873',
            'coingeckoId': 'defiato',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'dYel',
            'name': 'dYEL Index Token',
            'address': '0xF6134E74b7a4ee23A888436E53e77F83e62E8df8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'GUARD',
            'name': 'Guardian GUARD',
            'address': '0xF606bd19b1E61574ED625d9ea96C841D4E247A32',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xf606bd19b1e61574ed625d9ea96c841d4e247a32.png',
            'coingeckoId': 'guardian-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'HAPPY',
            'name': 'HappyFans',
            'address': '0xF5d8A096CcCb31b9D7bcE5afE812BE23e3D4690d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0xF5d8A096CcCb31b9D7bcE5afE812BE23e3D4690d.png',
            'coingeckoId': 'happyfans',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'ARC',
            'name': 'Arc Protocol',
            'address': '0xF5Ed7a3c2b6F3F41DAFD1CB86cFa05cf29207d08',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24107/thumb/Arc-Token-Logo.png?1646376409',
            'coingeckoId': 'arc-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QUACK',
            'name': 'QuackInu',
            'address': '0xF5D4158AB289D844A639dD5ccd9D86Da7CaC2Ff4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26151/thumb/20736.png?1656053432',
            'coingeckoId': 'quackinu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AQUA',
            'name': 'AquaTank',
            'address': '0xF59a047b8A7aA114F03448362D56936693C00635',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26950/thumb/aqua.png?1660987224',
            'coingeckoId': 'aquatank',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CTT',
            'name': 'Crazy Treasure Token',
            'address': '0xF56783b3A4fA5F09EfcA962bd4Bab1F5FADD9aB9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27814/thumb/logo.png?1665911614',
            'coingeckoId': 'crazy-treasure-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YFX',
            'name': 'Your Futures Exchange',
            'address': '0xF55a93b613D172b86c2Ba3981a849DaE2aeCDE2f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15654/thumb/yfx.PNG?1621478455',
            'coingeckoId': 'yfx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOONWILLY',
            'name': 'MoonWilly',
            'address': '0xF520f5D709dCD40529caaFc02B297e7225ff8372',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17689/thumb/qq2-ODoL_400x400.jpg?1628866558',
            'coingeckoId': 'moonwilly',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JEN',
            'name': 'JEN COIN',
            'address': '0xF513203f0aD2077e4BaA8A3c02d2Fb91709e052b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26912/thumb/200x200.png?1663998982',
            'coingeckoId': 'jen-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BENX',
            'name': 'BlueBenx',
            'address': '0xF506345Ccd3F53D1d0E724992416E4F36093F67e',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27460/thumb/B3S1F8VA_400x400.jpeg?1664167125',
            'coingeckoId': 'bluebenx-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFT2',
            'name': 'NFT2STAKE',
            'address': '0xF4d198264896917E769F5bf1AE29E9a36BD72dE8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28154/thumb/DCFFCF6C-5981-419A-A730-CF35ADA60420.jpeg?1668159589',
            'coingeckoId': 'nft2stake',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BBANK',
            'name': 'blockbank',
            'address': '0xF4b5470523cCD314C6B9dA041076e7D79E0Df267',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15081/thumb/blockbank.jpg?1667183379',
            'coingeckoId': 'blockbank',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RNK',
            'name': 'RetroNeko',
            'address': '0xF4a9412831Db85A902c2f08BEC36e4B43648440b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0xf4a9412831db85a902c2f08bec36e4b43648440b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FARA',
            'name': 'FaraLand',
            'address': '0xF4Ed363144981D3A65f42e7D0DC54FF9EEf559A1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1641303642308_3787035701587649.png',
            'coingeckoId': 'faraland',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'PACK',
            'name': 'The Wolf Pack',
            'address': '0xF4706Ee4220861A0CE3A294091689Eb6D2DcCBC3',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22358/thumb/photo_2021-12-03_10-21-14.png?1641558668',
            'coingeckoId': 'the-wolf-pack',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PRSG',
            'name': 'Princess Striker Gem',
            'address': '0xF4505E3B9BeeAB5dDFaD7e7a90f0DB89dD61EfE1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25440/thumb/9b7b68_151e70f35a55490197cb6d97a234b1de_mv2.png?1651754494',
            'coingeckoId': 'princess-striker-gem',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALN',
            'name': 'Aluna',
            'address': '0xF44Fb887334Fa17d2c5c0F970B5D320ab53eD557',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14379/thumb/uaLoLU8c_400x400_%281%29.png?1627873106',
            'coingeckoId': 'aluna',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NST',
            'name': 'NFT Starter',
            'address': '0xF4476e7E0FCbC84ce730fec6749D37383f3aC39E',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17511/thumb/nft_starter.PNG?1628055343',
            'coingeckoId': 'nft-starter',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETM',
            'name': 'EtherSmart',
            'address': '0xF3eb8B90C763b8B2B53E7819ac27eca8f94C8Ec2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18019/thumb/ethersmart.PNG?1630281887',
            'coingeckoId': 'ethersmart',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MBS',
            'name': 'Monster Battle',
            'address': '0xF3adD9C58518CaC319DF8B227AeFb2403e92c6Bd',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21720/thumb/HtFQuT-c_400x400.jpg?1639946399',
            'coingeckoId': 'monster-battle',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOT',
            'name': 'Mother Earth',
            'address': '0xF3F3d7F713DF0447E9595d9B830a5f00297070e4',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22205/thumb/5NKM1gkZ_400x400.jpg?1641194152',
            'coingeckoId': 'mother-earth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DKEY',
            'name': 'DKEY Bank',
            'address': '0xF3ED4770E6eFe9168c3f2F50A6D9d0F97a550DF1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20212/thumb/dkey.PNG?1636669147',
            'coingeckoId': 'dkey-bank',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MEG',
            'name': 'Magic Elpis Gem',
            'address': '0xF3Dd37b24d007967149FC0E49d58228C304Bf797',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24748/thumb/MEG_logo.png?1648785922',
            'coingeckoId': 'magic-elpis-gem',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MCA',
            'name': 'MoveCash',
            'address': '0xF3DBdF718667874e19EF368721A10409345fC218',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25610/thumb/qnmFw6Qw_400x400.jpeg?1652845231',
            'coingeckoId': 'movecash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EFLOKI',
            'name': 'Easter Floki',
            'address': '0xF376c874eacdcaaEb2f4012e5E09cf99357d830F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23500/thumb/logo-200x200.jpeg?1644293778',
            'coingeckoId': 'easter-floki',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLITZ',
            'name': 'Blitz Labs',
            'address': '0xF376807DcdbAa0d7FA86E7c9EAcC58d11ad710E4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25554/thumb/cmc2.png?1652421101',
            'coingeckoId': 'blitz-labs',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VGO',
            'name': 'Vagabond',
            'address': '0xF365920c476491F855891dc67B23BD2D0c4c7a72',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21229/thumb/14073.png?1638756833',
            'coingeckoId': 'vagabond',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CGC',
            'name': 'Cryptoids Game Coin',
            'address': '0xF35aee66AB0D099aF233c1ab23E5f2138B0ED15B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23988/thumb/cgc.png?1645947810',
            'coingeckoId': 'cryptoids-game-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OPEN',
            'name': 'OPEN Governance',
            'address': '0xF35262a9d427F96d2437379eF090db986eaE5d42',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xF35262a9d427F96d2437379eF090db986eaE5d42/logo.png',
            'coingeckoId': 'open-governance-token',
            'listedIn': [
                'coingecko',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BGS',
            'name': 'Battle of Guardians Share',
            'address': '0xF339E8c294046E6E7ef6AD4F6fa9E202B59b556B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22285/thumb/K3hU77wS_400x400.jpg?1641365642',
            'coingeckoId': 'battle-of-guardians-share',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GAT',
            'name': 'Game Ace',
            'address': '0xF315cfC8550F6FcA969D397cA8b807C5033fA122',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17171/thumb/logo-headerx2.png?1626733816',
            'coingeckoId': 'game-ace-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOTTO',
            'name': 'Lotto',
            'address': '0xF301C8435D4dFA51641f71B0615aDD794b52c8E9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13822/thumb/Lotto-Logo256x256.png?1612150421',
            'coingeckoId': 'lotto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PENNY',
            'name': 'Penny Token',
            'address': '0xF3011f55836A9f3F3bD2F6eFcDDaf198764a3f73',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27017/thumb/penny_logo200.jpg?1661479336',
            'coingeckoId': 'penny-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BACK',
            'name': 'DollarBack',
            'address': '0xF2cAaBf67f99D3AC5D0A4529722cFB874c9b35Bf',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26973/thumb/logoring200x200.png?1661153856',
            'coingeckoId': 'dollarback',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SCLP',
            'name': 'Scallop',
            'address': '0xF2c96E402c9199682d5dED26D3771c6B192c01af',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19059/thumb/scallop.PNG?1634266022',
            'coingeckoId': 'scallop',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MEGADAO',
            'name': 'MegaDAO',
            'address': '0xF2E4F2c8109530a92a992c9e8d0E75EBf8071E48',
            'decimals': 12,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25773/thumb/logo.png?1653630989',
            'coingeckoId': 'megadao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MLNX',
            'name': 'MELONx',
            'address': '0xF28709f1daa6CEE2847C5B9526ceba457331742b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20096/thumb/melonx.PNG?1636494223',
            'coingeckoId': 'melonx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASC',
            'name': 'Ashward Coin',
            'address': '0xF27BE4A7b56E892F10861Db447dbF0B84ADB1706',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24501/thumb/Token.jpg?1647872754',
            'coingeckoId': 'asward-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NICHEMAN',
            'name': 'Nicheman',
            'address': '0xF27580F6a58FF785c60B7c15fD394AB442aAA451',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15117/thumb/Screenshot_47.png?1619752357',
            'coingeckoId': 'nicheman',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CAKESWAP',
            'name': 'CakeSwap',
            'address': '0xF24d63e8B354736C97148B8Fc2FfB4F7789FE453',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21953/thumb/BRZnDZMh.png?1640324332',
            'coingeckoId': 'cakeswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MATH',
            'name': 'MATH',
            'address': '0xF218184Af829Cf2b0019F8E6F0b2423498a36983',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x08d967bb0134f2d07f7cfb6e246680c53927dd30.png',
            'coingeckoId': 'math',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REEF',
            'name': 'Reef',
            'address': '0xF21768cCBC73Ea5B6fd3C687208a7c2def2d966e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xfe3e6a25e6b192a42a44ecddcd13796471735acf.png',
            'coingeckoId': 'reef',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'mTSLA',
            'name': 'Mirror TSLA Token',
            'address': '0xF215A127A196e3988C09d052e16BcFD365Cd7AA3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xF215A127A196e3988C09d052e16BcFD365Cd7AA3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'TINU',
            'name': 'Tsuyoki Inu',
            'address': '0xF20753320F15C990B3Ca8f435705bB13F70357dA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0xf20753320f15c990b3ca8f435705bb13f70357da/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'REGU',
            'name': 'RegularPresale',
            'address': '0xF1ca73caa1C7AD66Af11147bA7d5636243AF0493',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20797/thumb/W1byu.png?1637677488',
            'coingeckoId': 'regularpresale',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLVR',
            'name': 'FlavorsBSC',
            'address': '0xF1a45E52d6d11D0e591d4F18DA9D88a4c266b300',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0xf1a45e52d6d11d0e591d4f18da9d88a4c266b300/logo.png',
            'coingeckoId': 'flavors-bsc',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'HOOP',
            'name': 'Primal Hoop',
            'address': '0xF19cfb40B3774dF6Eed83169Ad5aB0Aaf6865F25',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0xF19cfb40B3774dF6Eed83169Ad5aB0Aaf6865F25.png',
            'coingeckoId': 'hoop',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'ELR',
            'name': 'Elron',
            'address': '0xF17f7827403C166FDa782ceb17b29900D1a2aD15',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26396/thumb/ELRON_logo.png?1657753777',
            'coingeckoId': 'elron',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MBC',
            'name': 'MetaBUSDCoin',
            'address': '0xF14D3692B0055Db9Ca4c04065165d59B87E763f1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22156/thumb/P3gIa5oH_400x400.jpg?1640941577',
            'coingeckoId': 'metabusdcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CSC',
            'name': 'Crypto Soccer',
            'address': '0xF0fB4a5ACf1B1126A991ee189408b112028D7A63',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19054/thumb/logo-CSC.png?1634264742',
            'coingeckoId': 'crypto-soccer',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GAMI',
            'name': 'Gami',
            'address': '0xF0dcF7aC48F8c745F2920D03dFf83f879b80d438',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24482/thumb/IMG_20220319_170534_629.jpg?1647822338',
            'coingeckoId': 'gami',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'UEDC',
            'name': 'United Emirate Decentralized Coin',
            'address': '0xF0b6e29C429BBb8E1448340f0776bE933805344e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16333/thumb/UEDC.jpg?1623749851',
            'coingeckoId': 'united-emirate-decentralized-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INP',
            'name': 'InPoker',
            'address': '0xF0F88d49d3feA2EE07002b9565F47666BA7439EA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20936/thumb/inpoker.PNG?1638020430',
            'coingeckoId': 'inpoker',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EPW',
            'name': 'Evoverse Power',
            'address': '0xF0E5096edf070dc9b1bC8911d63c4E448a3e14c6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21795/thumb/EPW-Icon.png?1640059346',
            'coingeckoId': 'evoverse-power',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GBOND',
            'name': 'GBOND',
            'address': '0xF0C918B2a27746afc863D32a9A07B5cad3A0Ef42',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'GODZ',
            'name': 'Cryptogodz',
            'address': '0xF0A8EcBCE8caADB7A07d1FcD0f87Ae1Bd688dF43',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1643720478284_3676874791228144.png',
            'coingeckoId': 'cryptogodz',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'GLCH',
            'name': 'Glitch Protocol',
            'address': '0xF0902eB0049A4003793BAb33F3566A22D2834442',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13712/thumb/glitch_logo.jpeg?1611100011',
            'coingeckoId': 'glitch-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FPC',
            'name': 'FamilyParty',
            'address': '0xF080021c07FB6F55a5f2954Ce8f11cEdB25821EE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24774/thumb/logo.png?1648822998',
            'coingeckoId': 'familyparty',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOD',
            'name': 'Son of Doge',
            'address': '0xF078580015Cdfaa849BDAd8B38e72F5803C316c4',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://storage.googleapis.com/xy-finance-images/token-list/SOD.png',
            'coingeckoId': 'son-of-doge-v2',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'RODEO',
            'name': 'Rodeo Coin',
            'address': '0xF04Cb10B76F1BF04B7954E2aF5D1ec3f4D967a68',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26499/thumb/R3kePX6F_400x400.jpeg?1658356220',
            'coingeckoId': 'rodeo-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METAVEGAS',
            'name': 'MetaVegas',
            'address': '0xF02c5E1959c75d8105c19EE7B45A8dBAd0C02D6a',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21911/thumb/transpmv.png?1640238603',
            'coingeckoId': 'metavegas',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATM',
            'name': 'Athos Meta',
            'address': '0xF02b31b0B6dCabd579e41A0250288608FA43F898',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27159/thumb/IMG_20220831_033854_156.png?1662277127',
            'coingeckoId': 'athos-meta',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZUSD',
            'name': 'Zasset zUSD',
            'address': '0xF0186490B18CB74619816CfC7FeB51cdbe4ae7b9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17664/thumb/zusd_200.png?1628812160',
            'coingeckoId': 'zasset-zusd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASA',
            'name': 'AstroSanta',
            'address': '0xF008b870e673A14564cFCC2aEaa4Ed3CF132B9f4',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20614/thumb/200x173-trans-1.png?1637304389',
            'coingeckoId': 'astrosanta',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DINK',
            'name': 'Dink Doink',
            'address': '0xEfd39883aDb365fbf591bC0d624c9e1b12AEA35f',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16583/thumb/dink.png?1624510168',
            'coingeckoId': 'dink-donk',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALTO',
            'name': 'Alliance',
            'address': '0xEf783D3Af83A1f6841200Fc9adcFFc4C5f968Ab2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25343/thumb/ALLIANCE_200.png?1651302973',
            'coingeckoId': 'alliance-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DECENT',
            'name': 'DECENT Database',
            'address': '0xEf31bAFde913C7664Aa1B17C41Bb26E0e4549e31',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22520/thumb/CjIOOXXc_400x400.jpg?1641969900',
            'coingeckoId': 'decent-database',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HDOGE',
            'name': 'HolyDoge',
            'address': '0xEeCa2DA13907c50308Dc6D6Fe68E6FE5aBFFcF78',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15787/thumb/hdoge_logo.jpg?1621870804',
            'coingeckoId': 'holydoge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PACT',
            'name': 'impactMarket',
            'address': '0xEe9D08840554c9f8D30D0E3833D4906d3F39A49e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21907/thumb/PACT_Token_Ticker_Blue_2x.png?1640236076',
            'coingeckoId': 'impactmarket',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'O3',
            'name': 'O3 Swap',
            'address': '0xEe9801669C6138E84bD50dEB500827b776777d28',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xee9801669c6138e84bd50deb500827b776777d28.png',
            'coingeckoId': 'o3-swap',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CROP',
            'name': 'FarmerDoge',
            'address': '0xEe0736c93E98B2e9fbf11f47dB19E7B68Db71E94',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19910/thumb/farmer.jpeg?1636107276',
            'coingeckoId': 'farmerdoge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HBIT',
            'name': 'HashBit',
            'address': '0xEda3866f07566d9379a91A07F8D62e19C03183e0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16375/thumb/HBIT_COIN_LOGO_200.png?1623830124',
            'coingeckoId': 'hashbit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PROS',
            'name': 'Prosper',
            'address': '0xEd8c8Aa8299C10f067496BB66f8cC7Fb338A3405',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0xEd8c8Aa8299C10f067496BB66f8cC7Fb338A3405.png',
            'coingeckoId': 'prosper',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'VT',
            'name': 'Virtual Tourist',
            'address': '0xEd66eC1acb7Dbd0C01cCCfF33E3fF1F423057C21',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25186/thumb/200x.png?1650599907',
            'coingeckoId': 'virtual-tourist',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RATSDAO',
            'name': 'Ratscoin Team Dao',
            'address': '0xEd447dad7b3C384f0ca6f5FcC1b5A859617dC21C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26513/thumb/QTN7EYDL_400x400.jpeg?1658449129',
            'coingeckoId': 'ratscoin-team-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOONI',
            'name': 'Mooni',
            'address': '0xEd438051437c22A9Ef249B68c7E292435fE8B858',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15045/thumb/logo_cg.png?1619521392',
            'coingeckoId': 'mooni',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTCD',
            'name': 'Bitdollars',
            'address': '0xEd11F2fBEae0E42b11fD784737E5186dEA4c43Df',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26354/thumb/logo_png_%283%29.png?1657591885',
            'coingeckoId': 'bitdollars',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BITORB',
            'name': 'BitOrbit',
            'address': '0xEd0c1C9c64Ff7C7cC37c3AF0dfcf5B02eFE0Bb5f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19850/thumb/bitorbit.PNG?1636060265',
            'coingeckoId': 'bitorbit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CMN',
            'name': 'Crypto Media Network',
            'address': '0xEc4956111924e6D18099fa19802748e222a15000',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26272/thumb/download.jpeg?1656996207',
            'coingeckoId': 'crypto-media-network-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABYDOGEMM',
            'name': 'Baby Doge Money Maker',
            'address': '0xEc3D04C82aF8C4b97Ecd058D543c2c0673ca72dC',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18937/thumb/ztJClcK.png?1633946451',
            'coingeckoId': 'baby-doge-money-maker',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FUKU',
            'name': 'Furukuru',
            'address': '0xEc181B5f1d7B069192a3554bde509728b16d5D73',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19204/thumb/furukuru.PNG?1634687345',
            'coingeckoId': 'furukuru',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARC',
            'name': 'CryptoArc',
            'address': '0xEc0AD1b4D51B056A357Fc9acB3f1881c9d3628A7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28064/thumb/Logo_200x200.jpg?1667310067',
            'coingeckoId': 'crypto-arc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FEB',
            'name': 'ForeverBlast',
            'address': '0xEbCA5a64432660dc7Aac05D52315b0583734151B',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20159/thumb/feb.png?1636596755',
            'coingeckoId': 'foreverblast',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COURT',
            'name': 'OptionRoom Governance',
            'address': '0xEb804aE530Ed9D351374E865c110ed5ce172Cea0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15467/thumb/court.png?1620915482',
            'coingeckoId': 'optionroom-governance-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GREEN',
            'name': 'Green Token',
            'address': '0xEb29F65c4DaEEA6462a2F280E3Bf418Ec8B3Da9b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'NX',
            'name': 'Nxtech Network',
            'address': '0xEb0BdD8F51C867D787f0Fc6b2deE360CA31628e3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21967/thumb/new-logo.png?1657615514',
            'coingeckoId': 'nextech-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MSH',
            'name': 'CRIR MSH',
            'address': '0xEae2BBBC0000F605bD37A02c7fE346a3b68B03eb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18561/thumb/12096.png?1632435377',
            'coingeckoId': 'crir-msh',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INUKO',
            'name': 'Inuko Finance',
            'address': '0xEa51801b8F5B88543DdaD3D1727400c15b209D8f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27821/thumb/inuko-logo-200x200.png?1666263695',
            'coingeckoId': 'inuko-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ENCWTA',
            'name': 'Encyclopedia wTa',
            'address': '0xEa4bCFFf56Ef4aeDd7f41Fc260F13f2eEE7DeFCe',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26283/thumb/ENCwTaLogo.jpg?1657076682',
            'coingeckoId': 'encyclopedia-wta',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CFOOT',
            'name': 'CarboFoot',
            'address': '0xEa0a4bFFa83dd1Ea09109878108848532B826496',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27132/thumb/cfoot_logo_200x200.png?1662024017',
            'coingeckoId': 'carbofoot',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BGLG',
            'name': 'Big League',
            'address': '0xEa01D8D9EaCCa9996Db6Bb3377c1Fe64308e7328',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18629/thumb/IMG-20210911-184336-699.jpg?1632703480',
            'coingeckoId': 'big-league',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FIU',
            'name': 'beFITTER',
            'address': '0xEF7d50069406A2F5A53806f7250A6c0f17AD9dCD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25995/thumb/FIU_Token.jpg?1655194561',
            'coingeckoId': 'befitter',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WAG8',
            'name': 'Wrapped ATROMG8',
            'address': '0xEF7C2B756149c4F5A4f6EAe1f3613fdbB6BbC568',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15182/thumb/ag8.jpg?1620031331',
            'coingeckoId': 'wrapped-atromg8',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRYV',
            'name': 'Tryvium',
            'address': '0xEF1379d571Cd110828EED91DFCa75466A8e95862',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25804/thumb/tryv_token_200x200_%281%29.png?1653978087',
            'coingeckoId': 'tryvium-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TSCT',
            'name': 'Transient',
            'address': '0xEEEd90aA795c0E7d90fCEC0fCfAa7Bf6Fc13c20a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19061/thumb/200x200_%2833%29.png?1634266319',
            'coingeckoId': 'transient',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'FSAFE',
            'name': 'Fair Safe',
            'address': '0xEE738a9e5FB78c24D26ceCD30389ED977C38D0Ca',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xee738a9e5fb78c24d26cecd30389ed977c38d0ca.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'HYPER',
            'name': 'HyperChainX',
            'address': '0xEE5b03b769Ca6c690D140CafB52fC8de3f38FC28',
            'decimals': 7,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27847/thumb/IMG_20221015_231037_796.png?1666076945',
            'coingeckoId': 'hyperchainx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PPM',
            'name': 'Punk Panda Messenger',
            'address': '0xEE246AA7e2ecF136466c6FE4808f395861C6a04e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28115/thumb/PunkPanda_200x200.png?1667553649',
            'coingeckoId': 'punk-panda-messenger',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOUGH',
            'name': 'Dough',
            'address': '0xEDE5020492Be8E265dB6141CB0a1D2dF9dBAE9BB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18800/thumb/dough.png?1647078128',
            'coingeckoId': 'dough',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOX',
            'name': 'Doxed',
            'address': '0xEDA3eB7A5494443beb800cD4cc70388228CEe84e',
            'decimals': 10,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23853/thumb/500x500-LOGO.png?1645599154',
            'coingeckoId': 'doxed',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UMW',
            'name': 'UMetaWorld',
            'address': '0xED2f47A7748616f107DD3aAcE93DA5e2D8b17e6F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22566/thumb/zjXDugqs_400x400.jpg?1642059177',
            'coingeckoId': 'umetaworld',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RODO',
            'name': 'RODO',
            'address': '0xED08918B059aFA36Ec8AB2611BdEcD2Dd7E6508c',
            'decimals': 2,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BOON',
            'name': 'Baboon Financial',
            'address': '0xECaD387080156f27399ca7dF2Bd70aFB8a5ed67D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22493/thumb/A5dZNEu.png?1641906011',
            'coingeckoId': 'baboon-financial',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TPT',
            'name': 'TokenPocket Token',
            'address': '0xECa41281c24451168a37211F0bc2b8645AF45092',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xeca41281c24451168a37211f0bc2b8645af45092.png',
            'coingeckoId': 'token-pocket',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'TECHNO',
            'name': 'Technoland',
            'address': '0xEC671EEA2971Fc5E7159D8838431CdD24C6bdbCc',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26906/thumb/IcU1yLq.png?1660730930',
            'coingeckoId': 'technoland',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DREP',
            'name': 'Drep',
            'address': '0xEC583f25A049CC145dA9A256CDbE9B6201a705Ff',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14578/thumb/KotgsCgS_400x400.jpg?1617094445',
            'coingeckoId': 'drep-new',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PNFT',
            'name': 'Pawn My NFT',
            'address': '0xEC43D3153C1f08946fA71cDD3A14Af64fd58f27e',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19998/thumb/FcVyUcDq_400x400.jpg?1636363335',
            'coingeckoId': 'pawn-my-nft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PAL',
            'name': 'Palmare',
            'address': '0xEC143f87E8C2166F0405158677b84A10C045479a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26313/thumb/Palmare01.png?1657240158',
            'coingeckoId': 'palmare',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'SEG',
            'name': 'Solar Energy',
            'address': '0xEC126e20e7cb114dd3BA356100eaca2Cc2921322',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20935/thumb/seg.png?1638010532',
            'coingeckoId': 'solar-energy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VER',
            'name': 'VersalNFT',
            'address': '0xEBD4F823B4B22c631b1Eb894f46e772B0385c948',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25155/thumb/logo_%28390x390%29.png?1650461742',
            'coingeckoId': 'versal-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MUSIC',
            'name': 'NFTMusic ai',
            'address': '0xEBADab168c1a4592c8713454c4B1bFfe38367854',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16149/thumb/nftmusic.PNG?1623126911',
            'coingeckoId': 'nftmusic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RBO',
            'name': 'Roboots',
            'address': '0xEB7b6491B21565F8FCe9834526f43F7898AC3Ac1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21698/thumb/ROBOOT_LOGO_V2.png?1639722931',
            'coingeckoId': 'roboots',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DCB',
            'name': 'Decubate',
            'address': '0xEAc9873291dDAcA754EA5642114151f3035c67A2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16909/thumb/Logo-Decubate_200x200.png?1625595024',
            'coingeckoId': 'decubate',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EARTH',
            'name': 'EmitEarthElement',
            'address': '0xEA8553CCbbf14A628750a56078aA7da425bdAe08',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26712/thumb/IMG_20220803_174342_461.png?1659680741',
            'coingeckoId': 'emitearthelement',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MINU',
            'name': 'MINU',
            'address': '0xEA5E9F39F4B4Bc2684e49A0E43127F7f6F6be3b8',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25620/small/lY8xHwF.jpg?1652857003',
            'coingeckoId': null,
            'listedIn': [
                'xyfinance'
            ]
        },
        {
            'symbol': 'OKBOOMER',
            'name': 'OKBoomer',
            'address': '0xE9db02A654b74ca04734B26ef3B2a79808d43404',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xe9db02a654b74ca04734b26ef3b2a79808d43404.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'GMEX',
            'name': 'Game Coin',
            'address': '0xE9d78BF51ae04c7E1263A76ED89a65537B9cA903',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19893/thumb/gmex.png?1636093554',
            'coingeckoId': 'game-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEC',
            'name': 'Dark Energy Crystals',
            'address': '0xE9D7023f2132D55cbd4Ee1f78273CB7a3e74F10A',
            'decimals': 3,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12923/thumb/DEC_token.png?1603683753',
            'coingeckoId': 'dark-energy-crystals',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AMA',
            'name': 'AMAUROT',
            'address': '0xE9Cd2668FB580c96b035B6d081E5753f23FE7f46',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xe9cd2668fb580c96b035b6d081e5753f23fe7f46.png',
            'coingeckoId': 'amaurot',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'VLX',
            'name': 'Velas',
            'address': '0xE9C803F48dFFE50180Bd5B01dC04DA939E3445Fc',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xe9c803f48dffe50180bd5b01dc04da939e3445fc.png',
            'coingeckoId': 'velas',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'VAP',
            'name': 'Virtus Finance',
            'address': '0xE96c3CC84947280A26aA70CCCDc5C4ba717Ae395',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24549/thumb/rsz-dark-copy.png?1648132647',
            'coingeckoId': 'virtus-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PROP',
            'name': 'PROP',
            'address': '0xE94a61004db91d1265C0A5bEd4d5Fd43a0bC8397',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://storage.googleapis.com/xy-finance-images/token-list/PROP.png',
            'coingeckoId': null,
            'listedIn': [
                'xyfinance'
            ]
        },
        {
            'symbol': 'ELV',
            'name': 'Elvantis',
            'address': '0xE942C48044FB1C7f4C9eB456f6097fa4A1A17B8f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23393/thumb/elvantis.PNG?1644116336',
            'coingeckoId': 'elvantis',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MEME',
            'name': 'Memeverse',
            'address': '0xE932902b2f8B7d4959A311b16B2672501C42b3F7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25098/thumb/meme.jpg?1650369722',
            'coingeckoId': 'memeverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REAL',
            'name': 'Real Realm',
            'address': '0xE91cd52Bd65fe23A3EAE40E3eB87180E8306399F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20880/thumb/vYjuvOhQ_400x400.jpg?1637819845',
            'coingeckoId': 'real-realm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLN',
            'name': 'Balance Network',
            'address': '0xE90334c19c798C3A18d81b8cd16594247D5B19dd',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26797/thumb/BLN_Logo.png?1668687395',
            'coingeckoId': 'balance-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOON',
            'name': 'HoneyMOON',
            'address': '0xE8c93310af068aa50bd7bF0ebFa459Df2a02ceba',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18187/thumb/download_%2833%29.png?1630906145',
            'coingeckoId': 'honeymoon-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OLYMPICDOGE',
            'name': 'Olympic Doge',
            'address': '0xE8a7dA5c28Ab1B29c4389b864Fa890E225ddD6c8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19894/thumb/doge.jpeg?1636093754',
            'coingeckoId': 'olympic-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWT',
            'name': 'SweetToken',
            'address': '0xE8EbCf4Fd1faa9B77c0ec0B26e7Cc32a251Cd799',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27730/thumb/logo200.png?1665560462',
            'coingeckoId': 'sweettoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELVN',
            'name': '11Minutes',
            'address': '0xE8DE5e3689c42729CE53BB950FfF2e625Ccf23A7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24790/thumb/11Minutes_Logo_CG.png?1648911979',
            'coingeckoId': 'eleventoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MILLIONS',
            'name': 'Floki Millions',
            'address': '0xE8Ce27F7f710982CE427D57b571Cdb041D052d9B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21947/thumb/fm-logo-200x200.png?1640321268',
            'coingeckoId': 'floki-millions',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APED',
            'name': 'Apedoge',
            'address': '0xE8B4C953A204430A7Ce064532d6283c70a609956',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27899/thumb/photo_2022-10-20_14-01-21.jpg?1666321065',
            'coingeckoId': 'apedoge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WORLD',
            'name': 'Metaverse World',
            'address': '0xE894E4296a3857E7Ed858720F2CD1093fA7dba3e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26400/thumb/l4a_LNvt_400x400.jpeg?1657842474',
            'coingeckoId': 'metaverse-world',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NAFF',
            'name': 'Naffiti',
            'address': '0xE888C5cfE7cB2aae68808D47276e5BA19E76D725',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24057/thumb/Naffiti_1200x1200.png?1646203375',
            'coingeckoId': 'naffiti',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABYDOUG',
            'name': 'Baby Doug',
            'address': '0xE887E401f43d3d51A245DEa84A709c04870C25Cf',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17631/thumb/baby_doug.PNG?1628728519',
            'coingeckoId': 'baby-doug',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POP',
            'name': 'Popcorn',
            'address': '0xE8647Ea19496E87c061bBAD79f457928b2F52b5a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21438/thumb/pop-1_200_x_200.png?1662607611',
            'coingeckoId': 'popcorn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ABNBC',
            'name': 'Ankr Reward Bearing Stake BNB',
            'address': '0xE85aFCcDaFBE7F2B096f268e31ccE3da8dA2990A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xe85afccdafbe7f2b096f268e31cce3da8da2990a.png',
            'coingeckoId': 'ankr-reward-bearing-stake',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'RETRO',
            'name': 'Retromoon',
            'address': '0xE81FE8bBBEA13A0fd5Cc0AAFb6062631C659eC54',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21041/thumb/lWdfnsR.png?1638254065',
            'coingeckoId': 'retromoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HERO',
            'name': 'Step Hero',
            'address': '0xE8176d414560cFE1Bf82Fd73B986823B89E4F545',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0xE8176d414560cFE1Bf82Fd73B986823B89E4F545.png',
            'coingeckoId': 'step-hero',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'ZUKI',
            'name': 'Zuki Moba',
            'address': '0xE81257d932280AE440B17AFc5f07C8A110D21432',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20655/thumb/C1A1yP2J_400x400.jpg?1637528993',
            'coingeckoId': 'zuki-moba',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLH',
            'name': 'Blue Horizon',
            'address': '0xE7D36C00AaBfc34f40eA17166229a0B4ae7Dc856',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20536/thumb/blh.png?1638883632',
            'coingeckoId': 'blue-horizon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UND',
            'name': 'Unbound Dollar',
            'address': '0xE7C04392A3a426D532f83cA968Bcc0341E99583D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/7848.png',
            'coingeckoId': null,
            'listedIn': [
                'dfyn'
            ]
        },
        {
            'symbol': 'ADEN',
            'name': 'Adene',
            'address': '0xE782CCb8F8749a0f23AB690aa601455e8Eb8E550',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21656/thumb/IMG-20211214-071359-679.jpg?1639658679',
            'coingeckoId': 'adene',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GFT',
            'name': 'Galaxy Finance',
            'address': '0xE77932B1216125848e82C3967e75698362168f99',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25811/thumb/logo_%281%29.png?1653980666',
            'coingeckoId': 'galaxy-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIRAI',
            'name': 'Mirai Labs',
            'address': '0xE74C273ab62169656df1Dd146F40e26Baef5B057',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19738/thumb/DJbVoCpq_400x400.jpg?1635812951',
            'coingeckoId': 'mirai-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HYSS',
            'name': 'Highest Yield Savings Service',
            'address': '0xE745C551389556f2ba42bDd27EecbaD326B7E65b',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24589/thumb/wbQp6YlL_400x400.jpg?1648228036',
            'coingeckoId': 'highest-yield-savings-service',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REDKISHU',
            'name': 'Red Kishu',
            'address': '0xE7301E73543c3149F1DAE09F6A7b0f737301A64E',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20569/thumb/iPae-l9j_400x400.jpg?1637225791',
            'coingeckoId': 'red-kishu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METASH',
            'name': 'Meta Skinny Hope',
            'address': '0xE6d80fe22C85daDE97a7F615Aab217Bb332fb75B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24981/thumb/cI0xX_Gt_400x400.jpg?1649665043',
            'coingeckoId': 'meta-skinny-hope',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHEE',
            'name': 'CHEEBS',
            'address': '0xE6c78F31e481b144df5e6e35dF8Be83F58769670',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23897/thumb/cheebs.png?1646894334',
            'coingeckoId': 'cheebs',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HIP',
            'name': 'Hippo',
            'address': '0xE6FFa2e574a8BBEb5243D2109b6B11D4a459F88B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17386/thumb/hip.PNG?1627520087',
            'coingeckoId': 'hippo-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DPT',
            'name': 'Diviner Protocol',
            'address': '0xE69cAef10A488D7AF31Da46c89154d025546e990',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0xE69cAef10A488D7AF31Da46c89154d025546e990.png',
            'coingeckoId': 'diviner-protocol',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'MIT',
            'name': 'Galaxy Blitz',
            'address': '0xE6906717f129427eEbADe5406De68CADD57AA0c0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23003/thumb/gJ21fw3N_400x400.jpg?1643091458',
            'coingeckoId': 'galaxy-blitz',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'CZUSD',
            'name': 'CZUSD',
            'address': '0xE68b79e51bf826534Ff37AA9CeE71a3842ee9c70',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24048/thumb/czusd-200.png?1663151013',
            'coingeckoId': 'czusd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VEGAS',
            'name': 'Vegasino',
            'address': '0xE6884e29ffE5C6F68F4958cf201B0e308f982AC9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25905/thumb/third_about_img.png?1654502494',
            'coingeckoId': 'vegasino',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SURF',
            'name': 'Surf Live',
            'address': '0xE664cbfAB870413da211F78709911b98950fD94c',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26719/thumb/surf_logo_200x200.png?1659865116',
            'coingeckoId': 'surf-live',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CAR',
            'name': 'Supercars',
            'address': '0xE661cAF9a306299fdd685ebdD76C55955182aa37',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25166/thumb/19647.png?1650521233',
            'coingeckoId': 'supercars',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'bROOBEE',
            'name': 'ROOBEE',
            'address': '0xE64F5Cb844946C1F102Bd25bBD87a5aB4aE89Fbe',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xa31b1767e09f842ecfd4bc471fe44f830e3891aa.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SWFTC',
            'name': 'SWFTCOIN',
            'address': '0xE64E30276C2F826FEbd3784958d6Da7B55DfbaD3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/2346/thumb/SWFTCoin.jpg?1618392022',
            'coingeckoId': 'swftcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TITI',
            'name': 'Titi Financial',
            'address': '0xE618eF7C64AFedE59A81CEf16d0161c914eBab17',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23775/thumb/Untitled-200-200-px-2.png?1645434490',
            'coingeckoId': 'titi-financial',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VMT',
            'name': 'Vemate',
            'address': '0xE615C5e7219f9801C3b75bc76E45a4dab3c38e51',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27953/thumb/Vemate.jpeg?1666668775',
            'coingeckoId': 'vemate',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GLW',
            'name': 'GoalW',
            'address': '0xE5ef4A12D42B84c965c65B782230f299f165D640',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26633/thumb/IMG_20220729_231702_543.jpg?1659326891',
            'coingeckoId': 'goalw',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FGI',
            'name': 'Frog Inu',
            'address': '0xE5c355c993dE69C06b5DD1A0c9a2c39b4Cde5511',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26039/thumb/COKaaBl.png?1655364026',
            'coingeckoId': 'frog-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EUROS',
            'name': 'SpiceEURO',
            'address': '0xE5bbB701302322acb3504d4A260eec8Dc4a36263',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26443/thumb/E4B6C93A-E2A1-4F53-BB26-15D0FE802812.png?1658110215',
            'coingeckoId': 'spiceeuro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFUU',
            'name': 'SAFUU',
            'address': '0xE5bA47fD94CB645ba4119222e34fB33F59C7CD90',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24290/thumb/86tZ8IKj_400x400.jpg?1647257304',
            'coingeckoId': 'safuu',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'WOTG',
            'name': 'War of Tribes',
            'address': '0xE586970CF8f4E8b8360BBc8222D6827a844441FA',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20651/thumb/3TVl1UZN_400x400.jpg?1637527881',
            'coingeckoId': 'war-of-tribes',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BFIRE',
            'name': 'Bitblocks Fire',
            'address': '0xE5599A12a8fF06A690ffD2790C54A37c74A43208',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22398/thumb/17042.png?1641793301',
            'coingeckoId': 'bitblocks-fire',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOOLAH',
            'name': 'Block Creatures',
            'address': '0xE51BB42f0F6D01B872cdc7e1764d53b2a81cf0aF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17588/thumb/200-moolah-logo.png?1628575004',
            'coingeckoId': 'block-creatures',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TVRS',
            'name': 'TiraVerse',
            'address': '0xE500aCdbd53a8fCbe2B01c0F9c2Ccc676D0Fc6F6',
            'decimals': 7,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24591/thumb/tvrs.png?1648298059',
            'coingeckoId': 'tiraverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MONS',
            'name': 'Monsters Clan',
            'address': '0xE4c797d43631F4d660EC67B5CB0B78Ef5c902532',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18382/thumb/Monstersclan_Icon_200X200.png?1631723283',
            'coingeckoId': 'monsters-clan',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'CATE',
            'name': 'CateCoin',
            'address': '0xE4FAE3Faa8300810C835970b9187c268f55D998F',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15364/thumb/logo.png?1620647627',
            'coingeckoId': 'catecoin',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': '1MCT',
            'name': 'MicroCredit',
            'address': '0xE4DdbBD505132519361d55c449eaCD153d6d7BCE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24144/thumb/1mct.png?1646559344',
            'coingeckoId': 'microcredittoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LLG',
            'name': 'Lucid Lands',
            'address': '0xE4C293Eadbb9d72d68a680F1A66979726992c396',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20812/thumb/t4NyStd9_400x400.jpg?1637713006',
            'coingeckoId': 'lucid-lands',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPARTA',
            'name': 'SPARTAN PROTOCOL TOKEN',
            'address': '0xE4Ae305ebE1AbE663f261Bc00534067C80ad677C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xE4Ae305ebE1AbE663f261Bc00534067C80ad677C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SCH',
            'name': 'SoccerHub',
            'address': '0xE485b2780C3822A62dA88857FD6481018EA8CB95',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18495/thumb/Color-200x200.png?1632208300',
            'coingeckoId': 'soccerhub',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MCT',
            'name': 'MyConstant',
            'address': '0xE43B00b078463cA246D285bE1254767DA0003cC8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25907/thumb/mct.png?1655449975',
            'coingeckoId': 'myconstant',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RHYTHM',
            'name': 'Rhythm',
            'address': '0xE4318F2aCf2b9c3f518A3a03B5412F4999970Ddb',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17698/thumb/rhythm.png?1629037432',
            'coingeckoId': 'rhythm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ROGAN',
            'name': 'Rogan',
            'address': '0xE42F8Cb4acdE0C81202171a3faF7ED14a33AabeE',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23488/thumb/s6guO7n.png?1645270937',
            'coingeckoId': 'rogan',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TOKI',
            'name': 'Tokyo Inu',
            'address': '0xE405f6Ae74ad3efD31C23A40CE23b3D3A3B95FB9',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15501/thumb/Tokyo-Inu-200x200.png?1621080668',
            'coingeckoId': 'tokyo-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWGb',
            'name': 'SWGb',
            'address': '0xE40255C5d7fa7ceEc5120408C78C787CECB4cfdb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0xe40255c5d7fa7ceec5120408c78c787cecb4cfdb.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'RASTA',
            'name': 'Rasta Finance',
            'address': '0xE3e8cC42DA487d1116D26687856e9FB684817c52',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15228/thumb/Rasta_Finance.png?1621401871',
            'coingeckoId': 'rasta-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASH',
            'name': 'Ash Token',
            'address': '0xE3c1bDeEc4dB91CD90c336776332FAe2E00fdDd9',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23870/thumb/Ash-Logo-256.png?1645599536',
            'coingeckoId': 'ash-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KIND',
            'name': 'Kindcow Finance',
            'address': '0xE3Ba88c38D2789FE58465020CC0FB60b70c10d32',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14363/thumb/79417130.png?1615681265',
            'coingeckoId': 'kindcow-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NELO',
            'name': 'NELO Metaverse',
            'address': '0xE38950f71E2D2Fc4cA9dC9C3625d82560b0A5d8F',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19895/thumb/NELO_Logo_%28200_A%CC%83__200px%29.png?1644389958',
            'coingeckoId': 'nelo-metaverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELP',
            'name': 'The Everlasting Parachain',
            'address': '0xE3894CB9E92ca78524Fb6a30Ff072FA5E533c162',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16230/thumb/y24nMGj.png?1624274301',
            'coingeckoId': 'the-everlasting-parachain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VTT',
            'name': 'VesTally',
            'address': '0xE34e3eDBc2964ac2B93034db83A9dc47A4E6E8Af',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23484/thumb/VTTLogo.png?1644219862',
            'coingeckoId': 'vestallytoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RELAY',
            'name': 'Relay Chain',
            'address': '0xE338D4250A4d959F88Ff8789EaaE8c32700BD175',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17816/thumb/relay-logo-200.png?1629339288',
            'coingeckoId': 'relay-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DGMV',
            'name': 'DigiMetaverse',
            'address': '0xE336a772532650BC82828e9620Dd0d5a3b78bFE8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23701/thumb/DigiCorpLabs_token.png?1645081296',
            'coingeckoId': 'digimetaverse',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'AIRPAY',
            'name': 'AirPay',
            'address': '0xE332912b9279a7D01f78Fc6dF87865c7a8FA1efb',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23782/thumb/ys_Kuqq6_400x400.jpg?1645436414',
            'coingeckoId': 'airpay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WXFR',
            'name': 'Wrapped Fire',
            'address': '0xE32e106961376A1F5e6b63F7498d72f449d956F9',
            'decimals': 30,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26222/thumb/wXFR-logo.png?1656642191',
            'coingeckoId': 'wrapped-fire',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SKUY',
            'name': 'Sekuya',
            'address': '0xE327Ce757CD206721e100812E744fc56e4E0A969',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23788/thumb/SEKUYA_LOGO_200X200.png?1645437555',
            'coingeckoId': 'sekuya',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELMON',
            'name': 'Elemon',
            'address': '0xE3233fdb23F1c27aB37Bd66A19a1f1762fCf5f3F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20295/thumb/11663.png?1636815090',
            'coingeckoId': 'elemon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CPAD',
            'name': 'Cronospad',
            'address': '0xE2f6Ef0f95A248D8d55d1D7641ea4750851fEe39',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26186/thumb/Cronospad_logo_200x200.png?1656386107',
            'coingeckoId': 'cronospad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANRX',
            'name': 'AnRKey X',
            'address': '0xE2e7329499E8DDb1f2b04EE4B35a8d7f6881e4ea',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13415/thumb/anrkey.jpg?1608311301',
            'coingeckoId': 'anrkey-x',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRL',
            'name': 'Triall',
            'address': '0xE2EB47954e821DC94e19013677004cD59Be0b17f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18679/thumb/-B7ftfN8_400x400.png?1632964301',
            'coingeckoId': 'triall',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'Army',
            'name': 'CroArmy',
            'address': '0xE29f15D5eFc30eb5E229538dc5AFf2D751494c37',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ELEPHANT',
            'name': 'Elephant Money',
            'address': '0xE283D0e3B8c102BAdF5E8166B73E02D96d92F688',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15549/thumb/elephant-200.png?1621200793',
            'coingeckoId': 'elephant-money',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PAPER',
            'name': 'Paper',
            'address': '0xE239b561369aeF79eD55DFdDed84848A3bF60480',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23510/thumb/0xea97c7c1c89d4084e0bfb88284fa90243779da9f.png?1644300084',
            'coingeckoId': 'paper-fantom',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CAKEPUNKS',
            'name': 'CAKEPUNKS',
            'address': '0xE1dfd41Fba67AB6e26a7072fA97508506093Ac01',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18215/thumb/cool_2.png?1631005870',
            'coingeckoId': 'cakepunks',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HOO',
            'name': 'HooToken',
            'address': '0xE1d1F66215998786110Ba0102ef558b22224C016',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xE1d1F66215998786110Ba0102ef558b22224C016/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ELK',
            'name': 'Elk Finance',
            'address': '0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C/logo.png',
            'coingeckoId': 'elk-finance',
            'listedIn': [
                'coingecko',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DFAI',
            'name': 'DeFiAI',
            'address': '0xE1A0CE8B94c6A5E4791401086763d7bD0a6C18f5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23345/thumb/dfai.png?1644056152',
            'coingeckoId': 'defiai',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPIN',
            'name': 'Spinada Cash',
            'address': '0xE19A9626aEf55e20400A3b82A25c003403E88b7F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19881/thumb/7sV8g8d.png?1636085279',
            'coingeckoId': 'spinada-cash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RIFI',
            'name': 'Rikkei Finance',
            'address': '0xE17fbDf671F3cCE0F354cAcBd27e03f4245A3fFe',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21309/thumb/rikkei-finance.jpeg?1638888855',
            'coingeckoId': 'rikkei-finance',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'XOT',
            'name': 'Okuru Token',
            'address': '0xE17915332D853093af2A35b0f7AcB3deA8734F0D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FISH',
            'name': 'Metafish',
            'address': '0xE15176bCb7633bE736E94aB6aa2e57A7ED5988AB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19099/thumb/metafish.PNG?1634424134',
            'coingeckoId': 'metafish',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STBB',
            'name': 'Stabilize BSC',
            'address': '0xE1316066af35fbfF54f870eA6d1468255602a696',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15187/thumb/stabilize_logo.png?1620056137',
            'coingeckoId': 'stabilize-bsc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CARROT',
            'name': 'Carrot Token',
            'address': '0xE0e5c579eb4635Df3118a05FbF3cf85c7df3a078',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16947/thumb/200_%286%29.png?1625720559',
            'coingeckoId': 'carrot-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BELT',
            'name': 'Belt',
            'address': '0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xe0e514c71282b6f4e823703a39374cf58dc3ea4f.png',
            'coingeckoId': 'belt',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'KILLER',
            'name': 'Fat Cat Killer',
            'address': '0xE0b1EF69BC4AB4173989C1190f0d77A813f3B726',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26102/thumb/IMG_20220621_220937_201.png?1655870376',
            'coingeckoId': 'fat-cat-killer',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'H2ON',
            'name': 'H2O Securities',
            'address': '0xE0E81c29A68bFDd7c48072fD94e7c58F1f0146C1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26326/thumb/H2ON_logo_200_200.png?1657674887',
            'coingeckoId': 'h2o-securities',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POOGROW',
            'name': 'PooGrow',
            'address': '0xE0E61a8677566b4e1347637C6dbf38139B8Ea967',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26755/thumb/squid.png?1660030377',
            'coingeckoId': 'poogrow',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOLDNUGGET',
            'name': 'Blockmine',
            'address': '0xE0B58022487131eC9913C1F3AcFD8F74FC6A6C7E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19008/thumb/gold_nugget.png?1636451377',
            'coingeckoId': 'blockmine',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BEE',
            'name': 'Bee Capital',
            'address': '0xE070ccA5cdFB3F2B434fB91eAF67FA2084f324D7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25626/thumb/bee.png?1652858228',
            'coingeckoId': 'bee-capital',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZION',
            'name': 'ZionTopia',
            'address': '0xE0399378f7a92A39DA849EB64CdDdE2940e234Bb',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25902/thumb/logo.png?1654501700',
            'coingeckoId': 'ziontopia',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAKE',
            'name': 'BakerySwap',
            'address': '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xe02df9e3e622debdd69fb838bb799e3f168902c5.png',
            'coingeckoId': 'bakerytoken',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'XTS',
            'name': 'Xaviera Techno Solutions',
            'address': '0xE0095510A77cF31989e286ba63F321Cf28a8ea13',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24686/thumb/XTS_LOGO-1.png?1648590492',
            'coingeckoId': 'xaviera-techno-solutions',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZOMBIE',
            'name': 'Zombie Runner',
            'address': '0xE00748A31f14eB8FdBBAA77e177480bF8DBef48d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28120/thumb/LOGO.png?1667630847',
            'coingeckoId': 'zombie-runner',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WNDR',
            'name': 'Wonderman Nation',
            'address': '0xDfd7b0dD7Bf1012DfDf3307a964c36b972300Ac8',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25461/thumb/Wonderman_Logo_Menu.png?1651827108',
            'coingeckoId': 'wonderman-nation',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IMT',
            'name': 'In Meta Travel',
            'address': '0xDfF6bcD23742B2421bE999084C352303ABE7935C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28187/thumb/im200x200.png?1668258924',
            'coingeckoId': 'in-meta-travel',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GEMS',
            'name': 'Safegem',
            'address': '0xDfDec49462f7D3C3b0A48E729F77A0645CDFA7c0',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15550/thumb/safegem_avatar_200x200.png?1656317802',
            'coingeckoId': 'safegem',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CAPT',
            'name': 'Captain',
            'address': '0xDf5301b96ceCCb9C2a61505B3A7577111056A4C5',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16035/thumb/captain.PNG?1622675470',
            'coingeckoId': 'captain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OPS',
            'name': 'Octopus Protocol',
            'address': '0xDf0121a3bA5C10816eA2943C722650C4A4B0dbE6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16383/thumb/ops_logo.jpg?1623853585',
            'coingeckoId': 'octopus-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NEKO',
            'name': 'Neko Network',
            'address': '0xDedF440AD8bf07729EAD58F4fF52edaB20d2628d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15643/thumb/nekotoken.png?1621432195',
            'coingeckoId': 'neko-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GDOGE',
            'name': 'GDOGE Finance',
            'address': '0xDeb9acA4F54ABd9b82Ca91B52fCCCF869882B9Fe',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15531/thumb/200x200.png?1621136353',
            'coingeckoId': 'gdoge-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DGN',
            'name': 'Dungeon',
            'address': '0xDe3CE2a1577167FC9a3A21256d1d1Bc9EbCc3bA2',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26042/thumb/HyvIWtS.png?1655364719',
            'coingeckoId': 'dungeon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOBLIN',
            'name': 'Goblin',
            'address': '0xDe2A66C92583332E8e1F0AEEb03DEB749A3fD33a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22726/thumb/goblin.png?1654329580',
            'coingeckoId': 'goblin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLAST',
            'name': 'SafeBlast',
            'address': '0xDdC0dBd7dC799ae53A98a60b54999cb6eBb3Abf0',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15686/thumb/safeblast.png?1621561206',
            'coingeckoId': 'safeblast',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'LANC',
            'name': 'Lanceria',
            'address': '0xDd848E0CbFD3771dC7845B10072d973C375271e2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17589/thumb/lanceria.PNG?1628577080',
            'coingeckoId': 'lanceria',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YOCO',
            'name': 'YocoinYOCO',
            'address': '0xDd17629D05E068A9D118ee35d11101d4140D0586',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17395/thumb/yocoin.PNG?1627526814',
            'coingeckoId': 'yocoinyoco',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BIBL',
            'name': 'Biblecoin',
            'address': '0xDd041E030AdE3DB3B2221CE681b65f9650F250d7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26406/thumb/tLvtI1NT_400x400.jpeg?1657924146',
            'coingeckoId': 'biblecoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YFIH2',
            'name': 'H2Finance',
            'address': '0xDcb624C870d73CDD0B3345762977CB14dE598cd0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23077/thumb/wnzHxD5M_400x400.jpg?1643176541',
            'coingeckoId': 'h2finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOGEDI',
            'name': 'DOGEDI',
            'address': '0xDc49d53330317cBc6924fA53042e0C9bCa0A8d63',
            'decimals': 12,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21334/thumb/dogedi.png?1638948569',
            'coingeckoId': 'dogedi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NIOX',
            'name': 'Autonio',
            'address': '0xDc37Fa1507C7697C0778D3A7d61B31dCE7CBBA70',
            'decimals': 4,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'METO',
            'name': 'Metoshi',
            'address': '0xDc3541806D651eC79bA8639a1b495ACf503eB2Dd',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22097/thumb/logo200x200.png?1640796397',
            'coingeckoId': 'metoshi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSH',
            'name': 'BNB Superheroes',
            'address': '0xDc2B85bbB68110626B2A72dF2ADb400640f79247',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22786/thumb/IMG-20220117-175559-343.png?1642579723',
            'coingeckoId': 'bnb-superheroes',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOS',
            'name': 'DOS Network Token BEP20',
            'address': '0xDc0f0a5719c39764b011eDd02811BD228296887C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x0a913bead80f321e7ac35285ee10d9d922659cb7.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'LTD',
            'name': 'LiveTrade',
            'address': '0xDbad544416Df0677254645422bB560AF8408cAe7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17492/thumb/LTD-02.png?1627969143',
            'coingeckoId': 'livetrade-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOT',
            'name': 'Starbots',
            'address': '0xDbCcd9131405DD1fE7320090Af337952B9845DFA',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21823/thumb/coin_%286%29.png?1640076014',
            'coingeckoId': 'starbots',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COVDR',
            'name': 'COVID 19 Recovery',
            'address': '0xDb8aFb917A2D4848Ee5b3BED7F6C0bAb0555427D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24291/thumb/wgJQPGch_400x400.jpg?1647263791',
            'coingeckoId': 'covid-19-recovery-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RVE',
            'name': 'Revive',
            'address': '0xDb3bc1e509aC55cbEFAFD82E9724C41bDA81122C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23515/thumb/2425854-heartbeat-life-pulse-r.png?1644301267',
            'coingeckoId': 'revive',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XLD',
            'name': 'Stellar Diamond',
            'address': '0xDaf4F11947E73f0eeBEf4a820f4Be9B854aa993B',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16730/thumb/stellar_diamond.PNG?1624857268',
            'coingeckoId': 'stellar-diamond',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GMS',
            'name': 'GameStar',
            'address': '0xDae3C2Cb0AC034a4A491B112D2922f12427201A6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19001/thumb/gamestar.PNG?1634135847',
            'coingeckoId': 'gamestar-exchange',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BZEN',
            'name': 'Bitzen',
            'address': '0xDacC0417ADD48B63CbeFb77Efbe4a3801aAd51BA',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27311/thumb/bzen.png?1663311420',
            'coingeckoId': 'bitzen',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRYPT',
            'name': 'Crypter',
            'address': '0xDa6802BbEC06Ab447A68294A63DE47eD4506ACAA',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19121/thumb/crypt.PNG?1634520128',
            'coingeckoId': 'cryptertoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TMDS',
            'name': 'Tremendous Coin',
            'address': '0xDa5c6c28F7705dD0aCCc0EF1CaB9185ecC494790',
            'decimals': 10,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21641/thumb/tmds.jpg?1639633003',
            'coingeckoId': 'tremendous-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AQT',
            'name': 'AQT',
            'address': '0xDa5bE69074AFD12354173b4350ec9117E73e92e2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0xda5be69074afd12354173b4350ec9117e73e92e2.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'GODZ',
            'name': 'Godzilla',
            'address': '0xDa4714fEE90Ad7DE50bC185ccD06b175D23906c1',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19672/thumb/13559.png?1635738708',
            'coingeckoId': 'godzilla',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GRAPE',
            'name': 'Grape Finance',
            'address': '0xDa00B89EA3D2d0b022C663793b3d47D5b02EC627',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'INCOME',
            'name': 'Income',
            'address': '0xDFcF44e9a6D99717FC04AddD57Fb667286Bb7DC0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xdfcf44e9a6d99717fc04addd57fb667286bb7dc0.png',
            'coingeckoId': 'income',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'OPT',
            'name': 'Optimus OPT',
            'address': '0xDFE29AFdF5A7D0bb92A01A56Adabfa87D652E0E7',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26318/thumb/OPT_Transparent.png?1657244050',
            'coingeckoId': 'optimus-opt',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'GMINESV2',
            'name': 'Gnome Mines Token V2',
            'address': '0xDFB1039Ee2896584074Ec1C610c058b44d8693f9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27522/thumb/coingecko.png?1664368172',
            'coingeckoId': 'gnome-mines-token-v2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CABO',
            'name': 'Catbonk',
            'address': '0xDFAABAa57dEc10c049335bdAA2e949B4Ce2eAD30',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20917/thumb/cabo.png?1637923769',
            'coingeckoId': 'catbonk',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'MTC',
            'name': 'MetaCars',
            'address': '0xDF779aE4CbB7C45882c3521F0574d86D3bb95B5f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25949/thumb/20537.png?1654842944',
            'coingeckoId': 'metacars',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TDG',
            'name': 'Teddy Dog',
            'address': '0xDF757108102B4B1eFeC1FB71F4C8a11D2F291Ecb',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23601/thumb/tdg.png?1644735602',
            'coingeckoId': 'teddy-dog',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEAR',
            'name': 'Dragon Evolution Augmente',
            'address': '0xDF72fFDf750d8dA2d5874C99eA00Ed0f77046B0D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26525/thumb/logo_pure_black.png?1658473596',
            'coingeckoId': 'dragon-evolution-augmente',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'Kash',
            'name': 'Kash',
            'address': '0xDF3662A2b4E308146F90E2668C0d9e38aBabdFe4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BFT',
            'name': 'Bone Fragment',
            'address': '0xDECa5EE51843D1dC986E025317207B4f6c5e8F93',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26577/thumb/BFT.png?1658887762',
            'coingeckoId': 'bone-fragment',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDS',
            'name': 'Spice USD',
            'address': '0xDE7d1CE109236b12809C45b23D22f30DbA0eF424',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0xde7d1ce109236b12809c45b23d22f30dba0ef424/86863a4bb111157ad56dd0f4941e2a14.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REFI',
            'name': 'Reimagined Finance',
            'address': '0xDE734047952E178450237948cDf2ca7F24c37Ad5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21543/thumb/refi.PNG?1639451123',
            'coingeckoId': 'refi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HVI',
            'name': 'Hungarian Vizsla Inu',
            'address': '0xDE619A9E0eEeAA9F8CD39522Ed788234837F3B26',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16812/thumb/k%C3%B6r-nincs_h%C3%A1tt%C3%A9r.png?1625148179',
            'coingeckoId': 'hungarian-vizsla-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEUS',
            'name': 'DEUS',
            'address': '0xDE5ed76E7c05eC5e4572CfC88d1ACEA165109E44',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BNBDRAGON',
            'name': 'BnB Dragon',
            'address': '0xDE23183475f26a40802CcbE48394934261EA3736',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25612/thumb/20166.png?1652854649',
            'coingeckoId': 'bnb-dragon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEI',
            'name': 'DEI',
            'address': '0xDE1E704dae0B4051e80DAbB26ab6ad6c12262DA0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'POD',
            'name': 'WhaleSwap',
            'address': '0xDDed222297B3d08DAFDAc8f65eeB799B2674C78F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26312/thumb/Symbol_Only.png?1657239212',
            'coingeckoId': 'whaleswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GFI',
            'name': 'GameFi Protocol',
            'address': '0xDD6C6C114Db071EFE0BAB6051268227CE64C3fFe',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18421/thumb/w2FyVpg.jpg?1631856660',
            'coingeckoId': 'gamefi-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUMOSX',
            'name': 'Lumos Metaverse',
            'address': '0xDD05B1110147098b26FD3D457aa2992c034D3cFc',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22083/thumb/V8.png?1640761045',
            'coingeckoId': 'lumos-metaverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAT',
            'name': 'MirArc Chain',
            'address': '0xDCc04B40F37D9a139dC00A92ADcB05d796409EAe',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26357/thumb/%ED%99%94%EB%A9%B4_%EC%BA%A1%EC%B2%98_2022-06-05_112914_%281%29.png?1657592814',
            'coingeckoId': 'mirarc-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EDAC',
            'name': 'EDAC',
            'address': '0xDCDE89001BE4E77eD88Ff0C39bfFb23055659d21',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24142/thumb/200x200.png?1646548414',
            'coingeckoId': 'edac',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COLECT',
            'name': 'COLECT',
            'address': '0xDC53cC373e888525C30946424DE8cF0BAfFCBE62',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0xdc53cc373e888525c30946424de8cf0baffcbe62/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'TEDDYV2',
            'name': 'Teddy Doge V2',
            'address': '0xDB79c12d1d0670988A39B0E48b96e955eF922d24',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27468/thumb/200X200.png?1664185042',
            'coingeckoId': 'teddy-doge-v2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ESHARE',
            'name': 'EMP Shares',
            'address': '0xDB20F6A8665432CE895D724b417f77EcAC956550',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23013/thumb/sEEkbqt.jpeg?1643093201',
            'coingeckoId': 'emp-shares',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'AMPL',
            'name': 'Ampleforth',
            'address': '0xDB021b1B247fe2F1fa57e0A87C748Cc1E321F07F',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xDB021b1B247fe2F1fa57e0A87C748Cc1E321F07F/logo.png',
            'coingeckoId': 'ampleforth',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ADAPAD',
            'name': 'ADAPad',
            'address': '0xDB0170e2d0c1CC1b2E7a90313d9B9afA4f250289',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18273/thumb/EhSqPTtG_400x400.jpg?1631181091',
            'coingeckoId': 'adapad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KTN',
            'name': 'Kattana',
            'address': '0xDAe6c2A48BFAA66b43815c5548b10800919c993E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xDAe6c2A48BFAA66b43815c5548b10800919c993E/logo.png',
            'coingeckoId': 'kattana',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HANU',
            'name': 'Hanu Yokia',
            'address': '0xDAe4F1DCa49408288b55250022F67195EfF2445a',
            'decimals': 12,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17161/thumb/Goji_Hanu_Logo_200x200.png?1627127472',
            'coingeckoId': 'hanu-yokia',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TPOS',
            'name': 'The Philosophers Stone',
            'address': '0xDAdeBb7Bc62DB8614a59f52f221Ce8c226464599',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19979/thumb/-BMejjyB_400x400.png?1636355775',
            'coingeckoId': 'the-philosophers-stone',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PLG',
            'name': 'Plunge',
            'address': '0xDAd3ABce6Fb87FA0355203b692723A7EE8aa9D63',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21552/thumb/vdlZ53J.png?1639464138',
            'coingeckoId': 'plunge',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'GOT',
            'name': 'Era7  Game of Truth',
            'address': '0xDA71E3eC0d579FED5dbABa31eEaD3cEB9e77A928',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25900/thumb/20035.png?1654501180',
            'coingeckoId': 'era7-game-of-truth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AVO',
            'name': 'Avocado',
            'address': '0xDA5d7C36513F13D275dd46CA2Bd2ADD566412A15',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27437/thumb/avo.png?1663934084',
            'coingeckoId': 'avocado',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GAINS',
            'name': 'Gains',
            'address': '0xD9eA58350Bf120E2169A35FA1aFC31975b07de01',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14681/thumb/GAINS_Token_200x200.png?1617796172',
            'coingeckoId': 'gains',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PUSSY',
            'name': 'Pussy Financial',
            'address': '0xD9e8D20BDE081600fac0d94b88EAFaDDcE55aA43',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15213/thumb/pussytoken.png?1620110339',
            'coingeckoId': 'pussy-financial',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HAPI',
            'name': 'HAPI',
            'address': '0xD9c2D319Cd7e6177336b0a9c93c21cb48d84Fb54',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0xd9c2d319cd7e6177336b0a9c93c21cb48d84fb54.png',
            'coingeckoId': 'hapi',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'UPL',
            'name': 'Universal Pickle',
            'address': '0xD9Ca1D00De092A90463F27Fc3F5bD3B3D2b0C352',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22513/thumb/PICKLEstarsROUNDsolid-Transparent.png.png?1641966332',
            'coingeckoId': 'universal-pickle',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BBQ',
            'name': 'BarbecueSwap',
            'address': '0xD9A88f9b7101046786490bAF433f0f6aB3D753E2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15262/thumb/bbq.png?1620272127',
            'coingeckoId': 'barbecueswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TORII',
            'name': 'Torii Finance',
            'address': '0xD9979e2479AEa29751D31AE512a61297B98Fbbf4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18718/thumb/torii.PNG?1633127112',
            'coingeckoId': 'torii-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WND',
            'name': 'WonderHero',
            'address': '0xD9931818D75A6ceF4Fb0092A73c6e87867A0751a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25302/thumb/XhWmpBwh_400x400.jpg?1651154133',
            'coingeckoId': 'wonderhero-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'P21',
            'name': 'Project21',
            'address': '0xD987Bf6F25d7ACd8ac6C5Cf3e92E753a69fbcdb0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26899/thumb/IMG_20220816_124852_822_%281%29.png?1660723369',
            'coingeckoId': 'project21',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DFY',
            'name': 'Defi For You',
            'address': '0xD98560689C6e748DC37bc410B4d3096B1aA3D8C2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xd98560689c6e748dc37bc410b4d3096b1aa3d8c2.png',
            'coingeckoId': 'defi-for-you',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'LFC',
            'name': 'Life Coin',
            'address': '0xD9474595Edb03E35C5843335F90eb18671921246',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24614/thumb/lfc.png?1648374164',
            'coingeckoId': 'life-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HUSKY',
            'name': 'Husky Brother',
            'address': '0xD915cEb9661963392ef75500E596CE3f63a69B61',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27224/thumb/image.jpg?1662698431',
            'coingeckoId': 'husky-brother',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HSHIBA',
            'name': 'HuskyShiba',
            'address': '0xD914Bde476B6e44b7A1579b21F0F9193EA55F852',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15517/thumb/hshiba.PNG?1621117994',
            'coingeckoId': 'huskyshiba',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARNM',
            'name': 'Arenum',
            'address': '0xD9063a24630f24cd4Dc99a9C7aBe73F82Fc6B722',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24393/thumb/6253dcf123d4f40001e9c793_CryptoCoin_1024.png?1649733527',
            'coingeckoId': 'arenum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHARD',
            'name': 'Shard Coin',
            'address': '0xD8a1734945b9Ba38eB19a291b475E31F49e59877',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/3527/thumb/8680493537c30f81917d034613b289e9.png?1547038326',
            'coingeckoId': 'shard',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSHIB',
            'name': 'BuffedShiba',
            'address': '0xD8F61cdECA41dEF1a7923F308a042F678109f54B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19786/thumb/nshb.png?1635856247',
            'coingeckoId': 'buffedshiba',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PIE',
            'name': 'Pie Share',
            'address': '0xD8C152d3B94084D7C8Ec951E81FbBFc4B6dE5B2B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22779/thumb/logo-200x200.png?1642578090',
            'coingeckoId': 'pie-share',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOJOV2',
            'name': 'Mojo V2',
            'address': '0xD893B0A45a83E6a7bE1Caa904626f38A81cBbD0D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18320/thumb/tTxPCOr.png?1631531220',
            'coingeckoId': 'mojo-v2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MPD',
            'name': 'Metapad',
            'address': '0xD88C6Ec2D3fBD90892c6749CF83De6ad10c30B4b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21671/thumb/qETzHY6J_400x400.jpg?1639709858',
            'coingeckoId': 'metapad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHX',
            'name': 'WeOwn',
            'address': '0xD883D21AF976Ec9fA409c9f2944A1E7D03D21946',
            'decimals': 7,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/1943/thumb/ZlGXwUDv_400x400.jpg?1571388675',
            'coingeckoId': 'chainium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SSD',
            'name': 'SecretWorld',
            'address': '0xD87dC2841aaB1ba556F4823Eb3E5D35abB9b9dC9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24302/thumb/logo-32-2.png?1647269522',
            'coingeckoId': 'secretworld',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REBASEAPY',
            'name': 'RebaseAPY',
            'address': '0xD86F817fd6Aa04eA2A0b443DC5B3bE4530C9D4b6',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25215/thumb/200x200.png?1650865628',
            'coingeckoId': 'rebaseapy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GCT',
            'name': 'Green CycGo',
            'address': '0xD869Ead042e8D46017c74E3Accf8bb90b7eF565A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26260/thumb/token_gct02.png?1656941908',
            'coingeckoId': 'green-cycgo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ROKK',
            'name': 'Rokkit Fuel',
            'address': '0xD85baa6cFEA9D9f64d9E341f6f58f8Aca1284817',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20136/thumb/zzNlF9br_400x400.jpg?1636530726',
            'coingeckoId': 'rokkit-fuel',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HAKA',
            'name': 'TribeOne',
            'address': '0xD85AD783cc94bd04196a13DC042A3054a9B52210',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16575/thumb/USqW1QX.png?1625577555',
            'coingeckoId': 'tribeone',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PAL',
            'name': 'Pet Alliance',
            'address': '0xD85974Cb7f70C890B926CeA6ADAA31BDe0829cb6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25703/thumb/MP-HEx3HmP-GE6yhu5wpO7_Sqd-sD1Smtlq2X5bkFUOTz9E9hk8S4cIUeULgUmiZCzh-jUe1svskME74-n4fh8IgbnPhO1zmUXZEkgw-RWwQ7_oJ4_GksTp1JwmkUSsaP0HTNjCtdjJAQTHr4gAnW4QUXCfAhR8-NDec1KO5IJ91orT66BhVEXsWNeJP1FdYHZI88CcaCSRGTVHHEymA9oHGkd.jpg?1653459931',
            'coingeckoId': 'pet-alliance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STOPELON',
            'name': 'StopElon',
            'address': '0xD83cec69ED9d8044597A793445C86a5e763b0E3D',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16136/thumb/photo_2021-06-04_18-28-00.png?1623057366',
            'coingeckoId': 'stopelon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XAGC',
            'name': 'AgroCash X',
            'address': '0xD80BeA63a208770e1c371DFBF70Cb13469D29Ae6',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17766/thumb/0Q9dbgG.png?1629204614',
            'coingeckoId': 'agrocash-x',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GGG',
            'name': 'Good Games Guild',
            'address': '0xD8047AFECB86e44eFf3aDd991B9F063eD4ca716B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1641536671746_5537710154592934.png',
            'coingeckoId': 'good-games-guild',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'DLAUNCH',
            'name': 'DeFi Launch',
            'address': '0xD7D23d89c510f306e4037385EbbEFc6Dd6C41e61',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19971/thumb/dlaunch.png?1636447988',
            'coingeckoId': 'defi-launch',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'TAKEDA',
            'name': 'Takeda Shin',
            'address': '0xD77cC5eDFe2F7dB9a7383251B7Fcdc1579B367bB',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20385/thumb/takeda.png?1638775370',
            'coingeckoId': 'takeda-shin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NAFT',
            'name': 'Nafter',
            'address': '0xD7730681B1DC8f6F969166B29D8A5EA8568616a3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0xd7730681b1dc8f6f969166b29d8a5ea8568616a3.png',
            'coingeckoId': 'nafter',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'QUACK',
            'name': 'Rich Quack',
            'address': '0xD74b782E05AA25c50e7330Af541d46E18f36661C',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16356/thumb/57198446-0-Get-Rich-Quick-Gober.png?1623812294',
            'coingeckoId': 'richquack',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'LFW',
            'name': 'Legend of Fantasy War',
            'address': '0xD71239a33C8542Bd42130c1B4ACA0673B4e4f48B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19570/thumb/lfw.png?1635430353',
            'coingeckoId': 'legend-of-fantasy-war',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADAFLECT',
            'name': 'ADAFlect',
            'address': '0xD6ca9451bba47e26706A701aE05bE45A712D4B1B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18268/thumb/ADAFlect-Logo.png?1631172017',
            'coingeckoId': 'adaflect',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MPC',
            'name': 'Metaplace',
            'address': '0xD6bb010019C9D3Ea177e6D2E9af7938C6E53dA55',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21932/thumb/6NN9OT-e_400x400.png?1640300681',
            'coingeckoId': 'metaplace',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FCB',
            'name': 'ForceCowBoy',
            'address': '0xD6F53E7fA7c6c83D749255C06E9444E3325Ab53E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19009/thumb/fcb.png?1635059638',
            'coingeckoId': 'forcecowboy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CTR',
            'name': 'Creator Platform',
            'address': '0xD6Cce248263ea1e2b8cB765178C944Fc16Ed0727',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18252/thumb/logo_%281%29.png?1631137441',
            'coingeckoId': 'creator-platform',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAGIK',
            'name': 'MAGIK',
            'address': '0xD68F75b3aa54bee23e6Ac3AD4b3C28D3E6319725',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DRACO',
            'name': 'Baby Soulja Boy',
            'address': '0xD686a95c8cc28ea3a8242aDCa4149a219Fc53f20',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24798/thumb/draco.png?1648981131',
            'coingeckoId': 'baby-soulja-boy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VAL',
            'name': 'Valhalla Protocol',
            'address': '0xD67907ceeF57bBC536094b59e1a67Edee60697E0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26889/thumb/ValhallaCoin_200_x_200.png?1660704154',
            'coingeckoId': 'valhalla-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPT',
            'name': 'Sportoken',
            'address': '0xD677Bed5adF1de76F957b108dadb280bAA8F76B5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23559/thumb/sportoken200x200.png?1644473864',
            'coingeckoId': 'sportoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RCNT',
            'name': 'Ricnatum',
            'address': '0xD676c90a3979e73dfFb61f7a8608234781fc9CF8',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24135/thumb/RCNTToken.png?1646543963',
            'coingeckoId': 'ricnatum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DFL',
            'name': 'Defily',
            'address': '0xD675fF2B0ff139E14F86D87b7a6049ca7C66d76e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18261/thumb/defily.PNG?1631157196',
            'coingeckoId': 'defily',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NBOX',
            'name': 'NBOX',
            'address': '0xD669094736423DF2c3b49E32D68108E6dCAB68c9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26395/thumb/Nbox_Coin_Blue.png?1657753670',
            'coingeckoId': 'nbox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ODEX',
            'name': 'Oceans Swap',
            'address': '0xD64797880E6144b9DE466Bff4Fae3838D55eF832',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26464/thumb/photo_2022-07-10_23-45-25.jpg?1658192713',
            'coingeckoId': 'oceans-swap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NSTE',
            'name': 'NewSolution 2 0',
            'address': '0xD60340b120E144bCC6Fd63e013f5F83c8EDCf7d2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19142/thumb/nst.jpg?1634529544',
            'coingeckoId': 'newsolution-2-0',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELONBANK',
            'name': 'ElonBank',
            'address': '0xD5f363F83b36E10e8a823166b992c0bDc3deDE2C',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24722/thumb/200.png?1648697659',
            'coingeckoId': 'elonbank',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TWOGE',
            'name': 'Twoge Inu',
            'address': '0xD5FFAB1841B9137D5528Ed09D6Ebb66c3088aeDE',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26206/thumb/rsz_ttj9bx8b_400x400.png?1656540976',
            'coingeckoId': 'twoge-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SNS',
            'name': 'Melody SNS',
            'address': '0xD5CBaE3F69B0640724A6532cC81BE9C798A755A7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27979/thumb/EEM56Urt_400x400.png?1666854225',
            'coingeckoId': 'melody-sns',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PEG',
            'name': 'Pegazus Finance',
            'address': '0xD585F9C5953CA97DA3551f20725a274c9e442ff3',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15610/thumb/Pegazus.png?1621317167',
            'coingeckoId': 'pegazus-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HEARN',
            'name': 'Hearn fi',
            'address': '0xD554172bdA501869c8a2235F796edeD1068FAbdc',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23867/thumb/h-quadrata.png?1645597648',
            'coingeckoId': 'hearn-fi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EXT',
            'name': 'ExodusEXT',
            'address': '0xD536851e2BD9b3B8EA6a46b32016D7f52B1e4327',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28241/thumb/IMG_20220614_095726_079.jpg?1668582858',
            'coingeckoId': 'exodusext',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PWR',
            'name': 'CrazyMiner',
            'address': '0xD52eFE1039D706722b3D696e74AD4095DC3d3860',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1641267470524_1496782469387261.png',
            'coingeckoId': 'crazyminer',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'PULSEDOGE',
            'name': 'PulseDoge',
            'address': '0xD4d55B811d9eDe2aDce61a98d67d7f91bFfcE495',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19695/thumb/pulse.png?1635750667',
            'coingeckoId': 'pulsedoge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PSYCHODOGE',
            'name': 'Psycho Doge',
            'address': '0xD4cdBd31f55c6F06B267809b5eca0f0C257c8A6A',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17398/thumb/JUuShTOQ.png?1627528315',
            'coingeckoId': 'psycho-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PP',
            'name': 'Pool Party',
            'address': '0xD4b52510719C594514CE7FED6CC876C03278cCf8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19350/thumb/PP_Logo_Transparent_200x200.png?1635127497',
            'coingeckoId': 'pool-party',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HKC',
            'name': 'HelpKidz Coin',
            'address': '0xD48D639F72EF29458b72cDC9A47A95fA46101529',
            'decimals': 12,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25348/thumb/HelpKidz_Coin_200.png?1651304012',
            'coingeckoId': 'helpkidz-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BBT',
            'name': 'BitBook',
            'address': '0xD48474E7444727bF500a32D5AbE01943f3A59A64',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0xD48474E7444727bF500a32D5AbE01943f3A59A64.png',
            'coingeckoId': 'bitbook-token',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'wMEMO',
            'name': 'Wrapped MEMO',
            'address': '0xD451051D7d9F0b154ed25211094eC74036F49923',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ETERNAL',
            'name': 'CryptoMines Eternal',
            'address': '0xD44FD09d74cd13838F137B590497595d6b3FEeA4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0xD44FD09d74cd13838F137B590497595d6b3FEeA4.png',
            'coingeckoId': 'cryptomines-eternal',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'SFP',
            'name': 'SafePal',
            'address': '0xD41FDb03Ba84762dD66a0af1a6C8540FF1ba5dfb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xd41fdb03ba84762dd66a0af1a6c8540ff1ba5dfb.png',
            'coingeckoId': 'safepal',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'COINSCOPE',
            'name': 'Coinscope',
            'address': '0xD41C4805A9A3128f9F7A7074Da25965371Ba50d5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20649/thumb/IpSQZCFv_400x400.jpg?1637527440',
            'coingeckoId': 'coinscope',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HERO',
            'name': 'Metahero',
            'address': '0xD40bEDb44C081D2935eebA6eF5a3c8A31A1bBE13',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xD40bEDb44C081D2935eebA6eF5a3c8A31A1bBE13/logo.png',
            'coingeckoId': 'metahero',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MONO',
            'name': 'The Monopolist',
            'address': '0xD4099A517f2Fbe8a730d2ECaad1D0824B75e084a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21184/thumb/ab05Nt4UN1q5.png?1638512515',
            'coingeckoId': 'the-monopolist',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '1ART',
            'name': 'OneArt',
            'address': '0xD3c325848D7c6E29b574Cb0789998b2ff901f17E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1635386241277_11410086439393874.png',
            'coingeckoId': '1art',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'TREES',
            'name': 'Safetrees',
            'address': '0xD3b77Ac07c963b8cEAD47000A5208434D9A8734d',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16202/thumb/ST_newLogo_200x.png?1649045576',
            'coingeckoId': 'safetrees',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YEL',
            'name': 'Yel Finance',
            'address': '0xD3b71117E6C1558c1553305b44988cd944e97300',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xd3b71117e6c1558c1553305b44988cd944e97300.png',
            'coingeckoId': 'yel-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'CHIPS',
            'name': 'Chipstars',
            'address': '0xD3C563ec2CDDEE3d68B5d361b977558bf7c02a54',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18698/thumb/chipstars_coin_200x200.png?1633007827',
            'coingeckoId': 'chipstars',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INDC',
            'name': 'Nano Dogecoin',
            'address': '0xD3Af8E09894D69E937e37eeF46Df1cBF82b35C81',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17477/thumb/Untitled-design-6.png?1627923682',
            'coingeckoId': 'nano-dogecoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WEBFOUR',
            'name': 'WEBFOUR',
            'address': '0xD3987CB8A59E8eF6Aab0d95b92254344ed9c3C6f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22323/thumb/WebFourLogoNoText.png?1641457540',
            'coingeckoId': 'web-four',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RAGE',
            'name': 'Rage Fan',
            'address': '0xD38c1B7b95D359978996e01B8a85286F65B3C011',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14585/thumb/Copy_of_RAGE_FAN_LOGO_ONLY.png?1627476357',
            'coingeckoId': 'rage-fan',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VBK',
            'name': 'VeriBlock',
            'address': '0xD347DEfFBb2e750C752b2d4Aa5C26fd57ab90D64',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/8075/thumb/photo_2021-02-03_11.54.55.jpeg?1612324612',
            'coingeckoId': 'veriblock',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MLP',
            'name': 'MetaLaunchPad',
            'address': '0xD339117821dC6Ada0aE6328ccf4FadDb744b2423',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26480/thumb/LOGO.jpg?1658279091',
            'coingeckoId': 'metalaunchpad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IMPULSE',
            'name': 'Impulse By FDR',
            'address': '0xD2fB841ad6f0655f8993461Ff7E7669a5f61545f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13871/thumb/Impulse_by_FDR_200x200.png?1612444245',
            'coingeckoId': 'impulse-by-fdr',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFEICARUS',
            'name': 'SafeIcarus',
            'address': '0xD2f9B51C6a1b5598f0ea51eF95d70cB045692D0F',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15325/thumb/safelcarus.PNG?1620513641',
            'coingeckoId': 'safelcarus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEVL',
            'name': 'Devil',
            'address': '0xD280e0Fea29BcAe6ED9DD9fb4B9e5Fa90F5C249D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20721/thumb/pLHVgHY.png?1637586445',
            'coingeckoId': 'devil-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BNBH',
            'name': 'BnbHeroes',
            'address': '0xD25631648E3Ad4863332319E8E0d6f2A8EC6f267',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20660/thumb/YmZPSg0q_400x400.jpg?1637544487',
            'coingeckoId': 'bnbheroes-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MYNE',
            'name': 'ITSMYNE',
            'address': '0xD254dC670Ff725753a826a5F29A4001600CcE29c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19817/thumb/wYmPY80__400x400.png?1635928546',
            'coingeckoId': 'itsmyne',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KNIGHT',
            'name': 'KnightSwap',
            'address': '0xD23811058Eb6e7967D9a00dc3886E75610c4AbBa',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1660734511740_5937332759492198.jpg',
            'coingeckoId': 'knightswap',
            'listedIn': [
                'coingecko',
                'openocean'
            ]
        },
        {
            'symbol': 'MNEB',
            'name': 'Minereum BSC',
            'address': '0xD22202d23fE7dE9E3DbE11a2a88F42f4CB9507cf',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0xd22202d23fe7de9e3dbe11a2a88f42f4cb9507cf/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'TANKS',
            'name': 'Tanks',
            'address': '0xD20738760aEDeDa73F6CD91A3D357746e0283A0E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19819/thumb/tank-200.png?1635930932',
            'coingeckoId': 'tanks',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BURNING',
            'name': 'EthBurn',
            'address': '0xD1c0c25c5d7BA21df222Bd2623eCBC0CB264b5f4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27026/thumb/Ethburn.png?1661487631',
            'coingeckoId': 'ethburn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUNAPAD',
            'name': 'Luna Pad',
            'address': '0xD1FdF57241df3C36dad469060caC9f1ea2eE7585',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19640/thumb/LUNAPAD_logo.png?1635725864',
            'coingeckoId': 'luna-pad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TNGL',
            'name': 'Tangle',
            'address': '0xD17584633bc8D190E5A14502976daD9640456D6d',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18312/thumb/tangle.PNG?1631510159',
            'coingeckoId': 'tangle',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HEROS',
            'name': 'Heros',
            'address': '0xD1673C00Ac7010bF2c376ebeA43633dd61A81016',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20381/thumb/200x200.png?1658719987',
            'coingeckoId': 'hero-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SEBA',
            'name': 'Seba',
            'address': '0xD15d3BaF3F40988810C5F9dA54394FFb5246dEd6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24475/thumb/seba.png?1647775173',
            'coingeckoId': 'seba',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTD',
            'name': 'Bolt Dollar',
            'address': '0xD1102332a213E21faF78B69C03572031F3552c33',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xd1102332a213e21faf78b69c03572031f3552c33.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'BPRIVA',
            'name': 'Privapp Network',
            'address': '0xD0f4afA85a667d27837e9c07c81169869c16Dd16',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15252/thumb/priv.png?1620265611',
            'coingeckoId': 'privapp-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZBTC',
            'name': 'ZBTC',
            'address': '0xD0dff49De3E314FDFD3f93c5EEee7D5d2F5515cD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xd0dff49de3e314fdfd3f93c5eeee7d5d2f5515cd.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'GSC',
            'name': 'Goldstars Coin',
            'address': '0xD0a26b697bB45469b6de986fF7eFEe6C6A0A0688',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26803/thumb/logo.png?1660206190',
            'coingeckoId': 'goldstars-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HNFT',
            'name': 'HexaNFTs',
            'address': '0xD0D1939fDb393ad57eB3daCEB2b07c60203479D2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25904/thumb/icon200.png?1654502154',
            'coingeckoId': 'hexanfts',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUXY',
            'name': 'LUXY',
            'address': '0xD0C5BaD763432F22eBf21da947395d8fC2D11145',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ARATA',
            'name': 'Arata',
            'address': '0xD07E591E10E41b6B08457C8aa0be6b79369D60a6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18617/thumb/_VHQIycq_400x400.jpg?1632699805',
            'coingeckoId': 'arata',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MGP',
            'name': 'Magpie',
            'address': '0xD06716E1Ff2E492Cc5034c2E81805562dd3b45fa',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0xD06716E1Ff2E492Cc5034c2E81805562dd3b45fa.png',
            'coingeckoId': 'magpie',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'BG',
            'name': 'BunnyPark Game',
            'address': '0xD04c116C4F02f3ccA44b7d4e5209225C8779C8B8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21422/thumb/BG_TOKEN.png?1639115205',
            'coingeckoId': 'bunnypark-game',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GIOTTO',
            'name': 'GIOTTO',
            'address': '0xD04Ee1B6A9c4E4942f4A3D88C47B256bA5323297',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26948/thumb/Bozza_1_200x200px_FRONTE.png?1660984935',
            'coingeckoId': 'giotto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CORN',
            'name': 'DecaSwap CORN',
            'address': '0xD03066E0134c34dE03eb6dbB92DFBe8EBc4d5E6f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27389/thumb/corn_200px.png?1663814363',
            'coingeckoId': 'decaswap-corn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MINIFOOTBALL',
            'name': 'Minifootball',
            'address': '0xD024Ac1195762F6F13f8CfDF3cdd2c97b33B248b',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17693/thumb/cmc_etc_200x200.png?1649390997',
            'coingeckoId': 'minifootball',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'LPOOL',
            'name': 'Launchpool',
            'address': '0xCfB24d3C3767364391340a2E6d99c64F1CBd7A3D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14041/thumb/dGUvV0HQ_400x400.jpg?1613976219',
            'coingeckoId': 'launchpool',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RICE',
            'name': 'Rice Wallet',
            'address': '0xCf909EF9A61dC5b05D46B5490A9f00D51c40Bb28',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17204/thumb/RICE-200x200.png?1626847877',
            'coingeckoId': 'rice-wallet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORK',
            'name': 'Orakuru',
            'address': '0xCed0CE92F4bdC3c2201E255FAF12f05cf8206dA8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14709/thumb/orakuru-logo-red.png?1618983016',
            'coingeckoId': 'orakuru',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'MOONSTAR',
            'name': 'MoonStar',
            'address': '0xCe5814eFfF15D53EFd8025B9F2006D4d7D640b9B',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xce5814efff15d53efd8025b9f2006d4d7d640b9b.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'TRO',
            'name': 'Trodl',
            'address': '0xCe3B0d4E4E4285E55C0BfB294112Caf19682EB8A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14713/thumb/KU_Ul7P5_400x400.jpg?1617921826',
            'coingeckoId': 'trodl',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MYST',
            'name': 'MYSTERY',
            'address': '0xCdcaef3cE3a138C47ddB0B04a9b04649c13D50Ed',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24481/thumb/myst.png?1647781433',
            'coingeckoId': 'mystery',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TLC',
            'name': 'TLChain',
            'address': '0xCd6a8C968F6820f7163e7fb41F75048b92E4318D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28177/thumb/tlc.png?1668250171',
            'coingeckoId': 'tlchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PIGGY',
            'name': 'PiggyBank',
            'address': '0xCd2eCd5e06b1a330789B30e8AdA3D11c51503a71',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15935/thumb/qfmdtpJK_400x400.png?1622447757',
            'coingeckoId': 'piggy-bank-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XTM',
            'name': 'Torum',
            'address': '0xCd1fAFf6e578Fa5cAC469d2418C95671bA1a62Fe',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1637209220613_9771524796854829.png',
            'coingeckoId': 'torum',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'DOG',
            'name': 'Dog',
            'address': '0xCcd8a5f16d5a2477809a9956429fFC7be1BF6222',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24095/thumb/dogecoin.png?1646365551',
            'coingeckoId': 'dog-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ccCLO',
            'name': 'Callisto Network Token',
            'address': '0xCcbf1C9E8b4f2cDF3Bfba1098b8f56f97d219D53',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xccbf1c9e8b4f2cdf3bfba1098b8f56f97d219d53_2.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'DXGM',
            'name': 'DEXGame',
            'address': '0xCcAA7ad74eAf153B4aB0D327794d0B6734E4D51a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SINU',
            'name': 'SuperInu',
            'address': '0xCc2857D93B2566E0e13D03109ae833408C68160f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22505/thumb/logosuperinu.png?1641956045',
            'coingeckoId': 'superinu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOF',
            'name': 'GoFit Token',
            'address': '0xCb9Eb60c38b915cc47A7601B0ff220b92a76A581',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27477/thumb/Token_GOF.png?1664198005',
            'coingeckoId': 'gofit-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SFT',
            'name': 'Scorefam',
            'address': '0xCb73918ac58D0c90d71c7992637c61094c15305b',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25490/thumb/IMG-20220504-144648.png?1652074566',
            'coingeckoId': 'scorefam',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'CYC',
            'name': 'Recycling CYC',
            'address': '0xCb64f56A1f5865907dC01BeF4f828a2ae1D957c4',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27807/thumb/logo-CYC-200x200.png?1665904946',
            'coingeckoId': 'recycling-cyc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DCU',
            'name': 'Decentralized United',
            'address': '0xCb1DDC8F705e2737685A9C1e6B84A63d04D200e5',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26968/thumb/dcu.png?1661088678',
            'coingeckoId': 'decentralized-united',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BIFI',
            'name': 'Beefy Finance',
            'address': '0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xca3f508b8e4dd382ee878a314789373d80a5190a.png',
            'coingeckoId': 'beefy-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ELEN',
            'name': 'Everlens',
            'address': '0xCa2483727292bA552AEc12DfEE4dc105CB1376B9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20829/thumb/len.PNG?1637726284',
            'coingeckoId': 'everlens',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MRUN',
            'name': 'Metarun',
            'address': '0xCa0D640a401406f3405b4C252a5d0c4d17F38EBb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24219/thumb/GDgQSjgZ_400x400.jpg?1646918963',
            'coingeckoId': 'metarun',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EZI',
            'name': 'Ezillion',
            'address': '0xCa0C1c12466A57b26850b05a0BA2Fb39F9763A0c',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25472/thumb/ezi.jpg?1652004435',
            'coingeckoId': 'ezillion',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KPAD',
            'name': 'KickPad',
            'address': '0xCFEfA64B0dDD611b125157C41cD3827f2e8e8615',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14531/thumb/kickpad_logo.jpg?1616750879',
            'coingeckoId': 'kickpad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PORNROCKET',
            'name': 'PornRocket',
            'address': '0xCF9f991b14620f5ad144Eec11f9bc7Bf08987622',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16207/thumb/PornRocket.png?1623307659',
            'coingeckoId': 'pornrocket',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BIOS',
            'name': '0x nodes',
            'address': '0xCF87d3d50A98A7832f5CfdF99aE1B88C7cFbA4a7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15600/thumb/BIOS_01.png?1621737736',
            'coingeckoId': 'bios',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'WTO',
            'name': 'Wettok Market',
            'address': '0xCED59c3249F20Ca36FBA764bFDD9D94f471b3154',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28121/thumb/output-onlinepngtools.png?1667632896',
            'coingeckoId': 'wettok-market',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CI',
            'name': 'Cow Inu',
            'address': '0xCEAfc372af7fdDCcbfD28F10329710e58bb2f970',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21563/thumb/KreHpNsX_400x400.jpg?1639473546',
            'coingeckoId': 'cow-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAHA',
            'name': 'MahaDAO',
            'address': '0xCE86F7fcD3B40791F63B86C3ea3B8B355Ce2685b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0xce86f7fcd3b40791f63b86c3ea3b8b355ce2685b/99948ee1cfc4ab1e5a88c50090e36206.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DEUX',
            'name': 'DeuxPad',
            'address': '0xCE7c2beED376FDC24fC67B2398bF60FDc3310fe4',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23730/thumb/favicon.png?1645169391',
            'coingeckoId': 'deuxpad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOBC',
            'name': 'Bobcoin',
            'address': '0xCE6bD1833BD077f62B2c1F9a777bB829801d6811',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24264/thumb/bobc.png?1647171532',
            'coingeckoId': 'bobcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MU',
            'name': 'Mu Continent',
            'address': '0xCE262761DF57c72999146b7A6a752da03835db4a',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18227/thumb/mu.PNG?1631052302',
            'coingeckoId': 'mu-continent',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'THOREUM',
            'name': 'Thoreum V3',
            'address': '0xCE1b3e5087e8215876aF976032382dd338cF8401',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22759/thumb/Thoreum_V3_logo_200x200.png?1658885596',
            'coingeckoId': 'thoreum-v2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HUGO',
            'name': 'Hugo Game',
            'address': '0xCE195c777e1ce96C30ebeC54C91d20417a068706',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14744/thumb/hugo.png?1635004399',
            'coingeckoId': 'hugo-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PLOCK',
            'name': 'PancakeLock',
            'address': '0xCE0f314013Dc814F2da9d58160C54231fb2dDae2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17375/thumb/Header-Logo1.png?1627449012',
            'coingeckoId': 'pancakelock',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FST',
            'name': 'FitR Social Token',
            'address': '0xCDeD8C06f98a396dfC52246591907CbAE900b873',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28162/thumb/fst.png?1668169355',
            'coingeckoId': 'fitr-social-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADD',
            'name': 'Add xyz  NEW ',
            'address': '0xCD7E445175fF67475F0079B13aA6bED8a4e01809',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14351/thumb/ADDBK.png?1622444542',
            'coingeckoId': 'add-xyz-new',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VS',
            'name': 'Vision Metaverse',
            'address': '0xCD76BC49a69BCDC5222D81C18D4A04Dc8a387297',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24542/thumb/W0jkQVDm_400x400.png?1648100235',
            'coingeckoId': 'vision-metaverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ODDZ',
            'name': 'Oddz',
            'address': '0xCD40F2670CF58720b694968698A5514e924F742d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xCD40F2670CF58720b694968698A5514e924F742d/logo.png',
            'coingeckoId': 'oddz',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ADON',
            'name': 'Adonis',
            'address': '0xCD392021084683803525FE5E6c00cAe9C6bE5774',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26793/thumb/Jugvt5z5_400x400.jpeg?1660129722',
            'coingeckoId': 'adonis-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SCAN',
            'name': 'Scan DeFi',
            'address': '0xCCcE542413528CB57B5761e061F4683A1247aDCB',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17931/thumb/Logo-scandefi002.png?1630047515',
            'coingeckoId': 'scan-defi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SARABI',
            'name': 'SarabiChain',
            'address': '0xCCA47330F5276F27f376c3ff410E22849DC37842',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27350/thumb/512v2.png?1663655780',
            'coingeckoId': 'sarabichain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GLORYD',
            'name': 'GloryDoge',
            'address': '0xCC5667333F5e997aC9F0C26d41b7ddA65b2b675a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19655/thumb/logo200.png?1635733873',
            'coingeckoId': 'glorydoge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOG',
            'name': 'Dog  OLD ',
            'address': '0xCC4861AdC85d86ae8c9cc9D19F4F109969eb4F7B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22116/thumb/DOG.png?1640846649',
            'coingeckoId': 'dog',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MATIC',
            'name': 'Polygon',
            'address': '0xCC42724C6683B7E57334c4E856f4c9965ED682bD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/4713/small/matic-token-icon.png?1624446912',
            'coingeckoId': 'matic-network',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'TXS',
            'name': 'Texas Protocol',
            'address': '0xCC14eB2829491c8f6840Df545d5CA2A7504DDc58',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27453/thumb/texas200.png?1665733775',
            'coingeckoId': 'texas-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'Y-5',
            'name': 'Y 5 FINANCE',
            'address': '0xCBd8aECe0c920eEF3F215ad4e7319052Bd8eaa74',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24243/thumb/y-5.png?1647091379',
            'coingeckoId': 'y-5-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IRT',
            'name': 'Infinity Rocket',
            'address': '0xCBE5BcA571628894A38836b0baE833ff012f71D8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20619/thumb/irt.PNG?1637317783',
            'coingeckoId': 'infinity-rocket-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MILF',
            'name': 'MILF Finance',
            'address': '0xCB7A1Dc3a40FB64eA57D297Cef439A103fc11E66',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15534/thumb/e8KDF1El_400x400.jpg?1621153147',
            'coingeckoId': 'milf-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PERX',
            'name': 'PeerEx Network',
            'address': '0xCB6409696c58aA777317dbdfaa8bab4AC8e39Eea',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12091/thumb/2AyoUJyQ_400x400.jpg?1597273390',
            'coingeckoId': 'peerex-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KISHIMOTO',
            'name': 'Kishimoto  old ',
            'address': '0xCAb599D699f818e6ceFd07cF54f448DAB9367B05',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19188/thumb/cmclogo.png?1666922508',
            'coingeckoId': 'kishimoto-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WBT',
            'name': 'World Bet Token',
            'address': '0xCA70e0929C6534757853b34bFcd294B22ae457F6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27121/thumb/download.png?1662017164',
            'coingeckoId': 'world-bet-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SISTA',
            'name': 'srnArtGallery Tokenized Arts',
            'address': '0xCA6d25C10dad43ae8Be0bc2af4D3CD1114583C08',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17262/thumb/SISTA_Logo200x200.png?1626964996',
            'coingeckoId': 'srnartgallery-tokenized-arts',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'CAKITA',
            'name': 'ChubbyAkita',
            'address': '0xCA3c1dC12b0dD0D65964abaA533106CF4F372C78',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15563/thumb/cakita.jpg?1621217438',
            'coingeckoId': 'chubbyakita',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANTEX',
            'name': 'Antex',
            'address': '0xCA1aCAB14e85F30996aC83c64fF93Ded7586977C',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0xCA1aCAB14e85F30996aC83c64fF93Ded7586977C.png',
            'coingeckoId': 'antex',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'FLOV',
            'name': 'Valentine Floki',
            'address': '0xCA1E6F584E0d7EEc74F553E9a7B64a4DeD8A4b61',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23311/thumb/flov.png?1644053849',
            'coingeckoId': 'valentine-floki',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BIRD',
            'name': 'Birdchain',
            'address': '0xC9c7C6A590E82C576de7553142d47a5fb63f9e90',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/1994/thumb/MLSILOZA_400x400.png?1564971298',
            'coingeckoId': 'birdchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PITQC',
            'name': 'Pitquidity Capital',
            'address': '0xC98A06E7362789F57C8861E8F33f2Ebe3d32EED1',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27603/thumb/PITQD_LOGO_TWITER.png?1664779799',
            'coingeckoId': 'pitquidity-capital',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FIST',
            'name': 'Fistbump',
            'address': '0xC9882dEF23bc42D53895b8361D0b1EDC7570Bc6A',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1654508270679_7044954954885956.png',
            'coingeckoId': 'fistbump',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'BUNNY',
            'name': 'Pancake Bunny',
            'address': '0xC9849E6fdB743d08fAeE3E34dd2D1bc69EA11a51',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xc9849e6fdb743d08faee3e34dd2d1bc69ea11a51.png',
            'coingeckoId': 'pancake-bunny',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HPPOT',
            'name': 'Healing Potion',
            'address': '0xC974bE717f52Dcc701fE50fAD36d163b1e9A3a82',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16097/thumb/hppot.JPG?1622882434',
            'coingeckoId': 'healing-potion',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TOPG',
            'name': 'TopG Coin',
            'address': '0xC973d8615536A271f736b4D66FBe0EFe81B2fDC6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26748/thumb/tate_logo.png?1660015412',
            'coingeckoId': 'topg-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SCE',
            'name': 'Slime Royale Cupid Essence',
            'address': '0xC94898C7d5E9F3E62A98995985F9a957B592140b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27059/thumb/sce.png?1661519200',
            'coingeckoId': 'slime-royale-cupid-essence',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DELO',
            'name': 'Decentra Lotto',
            'address': '0xC91B4AA7e5C247CB506e112E7FEDF6af7077b90A',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19227/thumb/delo.png?1634781451',
            'coingeckoId': 'decentra-lotto',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'MITA',
            'name': 'Legends of Mitra',
            'address': '0xC8f456Ae5797BFb754080Ab7338fd2836169d239',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23851/thumb/fcDpmLEb_400x400.png?1645529565',
            'coingeckoId': 'legends-of-mitra',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DGC',
            'name': 'MetaFishing',
            'address': '0xC8FC7A009f7c017E098A9eff33513D7D43c226A1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27904/thumb/DGC_200x200.png?1666430740',
            'coingeckoId': 'metafishing',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KSN',
            'name': 'Kissan',
            'address': '0xC8A11F433512C16ED895245F34BCC2ca44eb06bd',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26414/thumb/200x200-2.png?1657925697',
            'coingeckoId': 'kissan',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BITWALLET',
            'name': 'Bitcoin E wallet',
            'address': '0xC868642D123289F0a6Cb34a3C2810A0a46141947',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27378/thumb/photo.jpg?1663741312',
            'coingeckoId': 'bitcoin-e-wallet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NNN',
            'name': 'Ninenoble',
            'address': '0xC866987195f2EEA49A170e328ac26E7B5565352f',
            'decimals': 0,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26916/thumb/nnn200r.png?1660814205',
            'coingeckoId': 'ninenoble',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFTM',
            'name': 'Nftime',
            'address': '0xC8609465b9acCaAbEb2AB6C4667C69435F17A569',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22151/thumb/nftime.PNG?1640938955',
            'coingeckoId': 'nftime',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ECC',
            'name': 'Empire Capital',
            'address': '0xC84D8d03aA41EF941721A4D77b24bB44D7C7Ac55',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21221/thumb/2022-07-13_18.49.44.jpg?1657709418',
            'coingeckoId': 'empire-capital-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PLKG',
            'name': 'Polkago',
            'address': '0xC82B898C3207b43515758A1b066EAfC65AFE9113',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21199/thumb/Polkago.png?1638537549',
            'coingeckoId': 'polkago',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SUPD',
            'name': 'Support Doge',
            'address': '0xC81D74BC2aA0c2bE3FB56908E8cABa186E106d9d',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17315/thumb/PMTsoQt.png?1627288132',
            'coingeckoId': 'support-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DPC',
            'name': 'Developer Coin',
            'address': '0xC7e444f9a853Ce55E6d775469C8319c0A37aAB3F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ATLS',
            'name': 'Atlantis Finance',
            'address': '0xC7dDF690942b74171F7e3763baf260B686516bc0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24392/thumb/pIyyPjAw_400x400.jpg?1647520491',
            'coingeckoId': 'atlantis-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HGET',
            'name': 'Hedget',
            'address': '0xC7d8D35EBA58a0935ff2D5a33Df105DD9f071731',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xC7d8D35EBA58a0935ff2D5a33Df105DD9f071731/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NEURALINK',
            'name': 'Neuralink',
            'address': '0xC7a081c50076e0e3D9C76bEA8eA948E2a6C11017',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25455/thumb/n.png?1651815317',
            'coingeckoId': 'neuralink',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MECH',
            'name': 'Mech Master',
            'address': '0xC7B7844494c516b840A7a4575ff3E60ff0f056a9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19403/thumb/mech_master.PNG?1635198336',
            'coingeckoId': 'mech-master',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XLD',
            'name': 'Xcel Defi',
            'address': '0xC79d1fD14F514cD713b5cA43D288a782Ae53eAb2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19318/thumb/PNG_XLD.png?1642750313',
            'coingeckoId': 'xcel-swap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APE',
            'name': 'ApeCoin',
            'address': '0xC762043E211571eB34f1ef377e5e8e76914962f9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0xC762043E211571eB34f1ef377e5e8e76914962f9.png',
            'coingeckoId': null,
            'listedIn': [
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'HPAY',
            'name': 'HedgePay',
            'address': '0xC75aa1Fa199EaC5adaBC832eA4522Cff6dFd521A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27725/thumb/200x200CG.png?1665547140',
            'coingeckoId': 'hedgepay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WCFLT',
            'name': 'Coinflect',
            'address': '0xC73d9b9616e855dF4a0b32C3f1ef2ed5DC9C1990',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20815/thumb/CMC_Coinflect_Logo-01.png?1665792802',
            'coingeckoId': 'coinflect',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WSWAP',
            'name': 'Wallet Swap',
            'address': '0xC72cC401122dBDC812EC88a2150AAD5a39467401',
            'decimals': 0,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17506/thumb/wswap_200.png?1628047381',
            'coingeckoId': 'wallet-swap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MUNCH',
            'name': 'Munch',
            'address': '0xC70636a779118e57E1c6fdAfDd1f919Fae912d2f',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14804/thumb/logo_-_2021-04-16T082627.266.png?1618532828',
            'coingeckoId': 'munch-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RYIU',
            'name': 'RYI Unity',
            'address': '0xC6d736123fa8e8a5D85803b5C22799e394245faB',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14512/thumb/ryi-unity_logo.png?1616633237',
            'coingeckoId': 'ryi-unity',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'AFIB',
            'name': 'Aries Financial',
            'address': '0xC6bfcf0469a74b36c070b807162fFcbF7B0a1103',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13433/thumb/AFIB.png?1623814735',
            'coingeckoId': 'aries-financial-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GRIMACE',
            'name': 'Grimace Coin',
            'address': '0xC6759a4Fc56B3ce9734035a56B36e8637c45b77E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24207/thumb/ustF4Y_V_400x400.jpg?1646863015',
            'coingeckoId': 'grimace-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PIAS',
            'name': 'PIAS',
            'address': '0xC669A70e0B3D07e3514aFd97eBFb3D134077A4a1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28080/thumb/41DF3535-B5AA-44E0-994D-ABC56B3DE2F4.png?1667396562',
            'coingeckoId': 'pias',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POLAR',
            'name': 'Polar Sync',
            'address': '0xC64c9B30C981fc2eE4e13d0CA3f08258e725fd24',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0xc64c9b30c981fc2ee4e13d0ca3f08258e725fd24/e211c503430383192ad7353e4056de9c.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DDOGE',
            'name': 'Dabb Doge',
            'address': '0xC638a73969C0F7442Ba8F5Ffda9968434891034B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24803/thumb/ddoge.png?1648986915',
            'coingeckoId': 'dabb-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEBT',
            'name': 'The Debt Box',
            'address': '0xC632F90affeC7121120275610BF17Df9963F181c',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26349/thumb/imgonline-com-ua-Resize-FsfTbGcy5U.png?1657589484',
            'coingeckoId': 'the-debt-box',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GMX',
            'name': 'GameX',
            'address': '0xC62eF0d8e137499833AbB05Dee47007D2b334bA6',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17630/thumb/gamex.PNG?1628728262',
            'coingeckoId': 'gamex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OKEY',
            'name': 'OKEYCOIN',
            'address': '0xC628D60B7eC7504B7482bc8a65348F3b7afCCbE0',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26821/thumb/OKEYCOIN_LOGO.png?1660280496',
            'coingeckoId': 'okeycoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DK',
            'name': 'Dragon Knight',
            'address': '0xC60e8207b820ff0c1168845eD35AA47288cC6FDC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21311/thumb/15751.png?1638918472',
            'coingeckoId': 'dragonknight',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OSK-DAO',
            'name': 'Pego Network',
            'address': '0xC5db5aFee4C55DfAD5F2b8226C6ac882E6956a0A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26514/thumb/KL0qyqm5_400x400.png?1658449327',
            'coingeckoId': 'pego-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STRM',
            'name': 'StreamCoin',
            'address': '0xC598275452fA319d75ee5f176FD3B8384925b425',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25438/thumb/17464.png?1651743438',
            'coingeckoId': 'streamcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SENSEI',
            'name': 'Sensei Shib',
            'address': '0xC58B6593B37D825779330Cd58B1f250e9eD7E10A',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22618/thumb/sensei.png?1642316920',
            'coingeckoId': 'sensei-shib',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FORS',
            'name': 'Forus',
            'address': '0xC54A4640907044283e8E4885090E205992B9813e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24090/thumb/Foruslogo.png?1646299099',
            'coingeckoId': 'forus',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'SFO',
            'name': 'StarFish OS',
            'address': '0xC544D8aB2b5ED395B96E3Ec87462801ECa579aE1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26006/thumb/output-onlinepngtools.png?1655279414',
            'coingeckoId': 'starfish-os',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATMSSFT',
            'name': 'AtmosphereCCG',
            'address': '0xC53C65C4A925039cC149fA99fD011490D40270a3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ZAP',
            'name': 'Zap',
            'address': '0xC5326b32E8BaEF125AcD68f8bC646fD646104F1c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/2180/thumb/zap.png?1547036476',
            'coingeckoId': 'zap',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'GEM',
            'name': 'Age Of Knights',
            'address': '0xC526399973608E6080AFF02062A3840DA6148738',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22080/thumb/G2_%281%29.png?1640757037',
            'coingeckoId': 'age-of-knights',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHTT',
            'name': 'Cheetah',
            'address': '0xC5093EcC7Fa594A6EcD8f35538908273c93518F6',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19600/thumb/Cheetah200x200.png?1635490607',
            'coingeckoId': 'token-cheetah',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BFT',
            'name': 'Block Forest',
            'address': '0xC4e83B0EF81B4C7CAb394f1C0D4A39bf8bc4e248',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25696/thumb/%E5%BE%AE%E4%BF%A1%E6%88%AA%E5%9B%BE_20220513221539%281%29.png?1653384670',
            'coingeckoId': 'block-forest',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHIBA',
            'name': 'Chiba Inu',
            'address': '0xC4CdC643a3BA49d5cD296Db591ef0a56a9080886',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22644/thumb/1qyBuGgr_400x400.jpg?1642396338',
            'coingeckoId': 'chiba-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PIE',
            'name': 'DeFiPie',
            'address': '0xC4B35d3A24E3e8941c5d87fD21D0725642F50308',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/11956/thumb/98j5E_EG_400x400.png?1596644614',
            'coingeckoId': 'defipie',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPC',
            'name': 'Spitz Chain',
            'address': '0xC46159BE6699f8F74D39231c021529CD98e0D788',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27644/thumb/wXXGCkQ.png?1665019902',
            'coingeckoId': 'spitz-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JUV',
            'name': 'Juventus',
            'address': '0xC40C9A843E1c6D01b7578284a9028854f6683b1B',
            'decimals': 2,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xC40C9A843E1c6D01b7578284a9028854f6683b1B/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'TOKAU',
            'name': 'Tokyo AU',
            'address': '0xC409eC8a33f31437Ed753C82EEd3c5F16d6D7e22',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16182/thumb/TOKAU.jpg?1623217919',
            'coingeckoId': 'tokyo-au',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MDX',
            'name': 'Mandala Exchange Token',
            'address': '0xC40289e08BB3eb14a26c9AEc9F2EEacB9FDaB297',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SOS',
            'name': 'SOS Foundation',
            'address': '0xC3eaC7EB10cd1B6B11504259fE933D011D99B797',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18290/thumb/FkqAAqSd_400x400.jpg?1631283396',
            'coingeckoId': 'sos-foundation',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MEBU',
            'name': 'Metaburst',
            'address': '0xC3eFc6E1cFeCb4903570eD86142c7f9A8A760BF6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27466/thumb/Metaburst200.png?1664178110',
            'coingeckoId': 'metaburst',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KIBA',
            'name': 'Kiba Inu',
            'address': '0xC3afDe95B6Eb9ba8553cDAea6645D45fB3a7FAF5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19525/thumb/kiba.png?1648549322',
            'coingeckoId': 'kiba-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ESR',
            'name': 'EsportsRef',
            'address': '0xC3Ede3bB006Db3E82E436aCdF867E8c6BE0E017E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MTS',
            'name': 'Monster Saga',
            'address': '0xC3D1D24FAb71e814445B42bE5A646Dc9CaA97E14',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24472/thumb/mts.png?1647770455',
            'coingeckoId': 'monster-saga',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EVDC',
            'name': 'Electric Vehicle Direct Currency',
            'address': '0xC3B4C4eF3fE02aD91cB57efda593ed07A9278cc0',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17406/thumb/EVDC-01-1-2.png?1627539880',
            'coingeckoId': 'electric-vehicle-direct-currency',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TXC',
            'name': 'ToxicGameNft',
            'address': '0xC380Fa4b673b267b6626EF177CEA421F9e704Ed0',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20984/thumb/1638050660072-921.jpg?1638165652',
            'coingeckoId': 'toxicgamenft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NABOB',
            'name': 'Nabob',
            'address': '0xC35a2cBE337F1F6434F61FC167aE70B90AE6d212',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25961/thumb/20544.png?1654861180',
            'coingeckoId': 'nabob',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRIBEX',
            'name': 'Tribe Token',
            'address': '0xC34c85a3D7a84212b6234146773f7939a931a8AF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20294/thumb/tribe.PNG?1636814986',
            'coingeckoId': 'tribe-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DIG',
            'name': 'DIEGO',
            'address': '0xC34830df33B2B95e00e647AAA57246b6981DcBE1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27582/thumb/DIEGO_%EC%8B%AC%EB%B3%BC_200.png?1664699302',
            'coingeckoId': 'diego',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHIWA',
            'name': 'Chiwawa',
            'address': '0xC3434677Ec3048df655c39a376969eCD7b726Ef6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22074/thumb/200x200.png?1640752086',
            'coingeckoId': 'chiwawa',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ROCK',
            'name': 'Bedrock',
            'address': '0xC3387E4285e9F80A7cFDf02B4ac6cdF2476A528A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xc3387e4285e9f80a7cfdf02b4ac6cdf2476a528a.png',
            'coingeckoId': 'bedrock',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SCT',
            'name': 'Safechain',
            'address': '0xC3262500039696Ff8ef9830Fb422Ab32b15Bd366',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19382/thumb/MmOXdygU_400x400.jpg?1635143046',
            'coingeckoId': 'safechaintoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SABAKAINU',
            'name': 'Sabaka Inu',
            'address': '0xC30F12CD65f61ded24db6C415C84F999C9704eBC',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15748/thumb/Logo.png?1621782284',
            'coingeckoId': 'sabaka-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SSS',
            'name': 'StarSharks',
            'address': '0xC3028FbC1742a16A5D69dE1B334cbce28f5d7EB3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21667/small/FLjJKQd0_400x400.jpg?1639705037',
            'coingeckoId': 'starsharks',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'FMAN',
            'name': 'Florida Man',
            'address': '0xC2aEbbBc596261D0bE3b41812820dad54508575b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21564/thumb/Logo-circle-e1637974199534.png?1639473915',
            'coingeckoId': 'florida-man',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BIDZ',
            'name': 'BIDZ Coin',
            'address': '0xC2EBAA5F640b30c0D6712A6E0656fB816C10a7d4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26177/thumb/O99u27zU_400x400.png?1656380941',
            'coingeckoId': 'bidz-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOSX',
            'name': 'SocialX',
            'address': '0xC27f6473a04b2bdeE0bf650f153F1D8720AA3062',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20752/thumb/sosx_200x200.png?1637635630',
            'coingeckoId': 'socialx-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MCC',
            'name': 'MedCareCoin',
            'address': '0xC27f4f50080e1989219011AB1C6e64b960dac3b1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23995/thumb/mcc.png?1645953027',
            'coingeckoId': 'medcarecoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XGT',
            'name': 'Xion Finance',
            'address': '0xC25AF3123d2420054c8fcd144c21113aa2853F39',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xC25AF3123d2420054c8fcd144c21113aa2853F39/logo.png',
            'coingeckoId': 'xion-finance',
            'listedIn': [
                'coingecko',
                'elkfinance'
            ]
        },
        {
            'symbol': 'IDLE',
            'name': 'Idle Cyber Game',
            'address': '0xC2555F8Aef79160A2cb319781f3a5c726b4a8AE6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24019/thumb/pZgR4golsHfj-Lmw79XULWbZ7K_F7nr0C_jq2CygrCx9j-Fd9MRl8tbxQxbetJZfAB5dj2puRgExlecOjdKObRGXrC1-KmDBe17ckcLFzcIctoP330NNkTrpT_mndu4cEyp6LU9yDZN4rKbFVbM-Qn3ZAJRkFDD46piUrfPkfVsSQ8d8hlZ1Ay6vUUU7aMnpOsOqYUrORW80od.jpg?1646040073',
            'coingeckoId': 'idle-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPMETA',
            'name': 'SpaceMeta',
            'address': '0xC24c0D8528886D206eA45c5113EDb5997ca3c164',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24477/thumb/spmeta.png?1647776066',
            'coingeckoId': 'spacemeta',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LEZ',
            'name': 'Peoplez',
            'address': '0xC23BE03F64a834B3Fa6aE62C97ac8B40F3Eec6A9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21185/thumb/peoplez.PNG?1638513746',
            'coingeckoId': 'peoplez',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VOX',
            'name': 'Vox Finance',
            'address': '0xC227f8EECC481A8e8BAa30A4754B109b81C4DfA4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12880/thumb/BSensIa.png?1603261093',
            'coingeckoId': 'vox-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRYB',
            'name': 'BiLira',
            'address': '0xC1fdbed7Dac39caE2CcC0748f7a80dC446F6a594',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/10119/thumb/JBs9jiXO_400x400.jpg?1642668342',
            'coingeckoId': 'bilira',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CFL365',
            'name': 'CFL365 Finance',
            'address': '0xC1E0510A0dF7646817b6632D32CaA681A425a5e6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17548/thumb/cfl365.PNG?1628218865',
            'coingeckoId': 'cfl365-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WSO',
            'name': 'Widi Soul',
            'address': '0xC19FE21B4ef356f2f65894392dDe4252AA083605',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22832/thumb/wso2_200x200.png?1642668402',
            'coingeckoId': 'widi-soul',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIBELON',
            'name': 'ShibElon',
            'address': '0xC183062db25FC96325485ea369C979CE881Ac0eA',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20434/thumb/shibelon.png?1637037218',
            'coingeckoId': 'shibelon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RISE',
            'name': 'EverRise',
            'address': '0xC17c30e98541188614dF99239cABD40280810cA3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16367/thumb/Logo_EverRise_Icon_logo.png?1642576670',
            'coingeckoId': 'everrise',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MCC',
            'name': 'Multi Chain Capital',
            'address': '0xC146B7CdBaff065090077151d391f4c96Aa09e0C',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21621/thumb/mcc.png?1639622572',
            'coingeckoId': 'multi-chain-capital-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JGN',
            'name': 'Juggernaut',
            'address': '0xC13B7a43223BB9Bf4B69BD68Ab20ca1B79d81C75',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xc13b7a43223bb9bf4b69bd68ab20ca1b79d81c75.png',
            'coingeckoId': 'juggernaut',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HOTMOON',
            'name': 'HotMoon',
            'address': '0xC1357d32Bf23fD5fE3280681a36755B6F150442e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25206/thumb/hotmoon-coinGecko.png?1650770774',
            'coingeckoId': 'hotmoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BNSD',
            'name': 'BNSD Finance',
            'address': '0xC1165227519FfD22Fdc77Ceb1037b9b284eeF068',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xc1165227519ffd22fdc77ceb1037b9b284eef068.png',
            'coingeckoId': 'bnsd-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'HZN',
            'name': 'Horizon Protocol',
            'address': '0xC0eFf7749b125444953ef89682201Fb8c6A917CD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xC0eFf7749b125444953ef89682201Fb8c6A917CD/logo.png',
            'coingeckoId': 'horizon-protocol',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SWTH',
            'name': 'Carbon Protocol',
            'address': '0xC0ECB8499D8dA2771aBCbF4091DB7f65158f1468',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0xC0ECB8499D8dA2771aBCbF4091DB7f65158f1468.png',
            'coingeckoId': 'switcheo',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'TRFX',
            'name': 'Triflex',
            'address': '0xC0D52314f042F57112ED89b9401F5fC9b44ffd75',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27052/thumb/triflex.png?1661507193',
            'coingeckoId': 'triflex-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASY',
            'name': 'ASYAGRO',
            'address': '0xC0Cc1e5761bA5786916FD055562551798E50d573',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/10632/thumb/c0Q4z8HJ_400x400.jpg?1581203764',
            'coingeckoId': 'asyagro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATRI',
            'name': 'Atari',
            'address': '0xC0C6e4C6E70c6231b20979Bda581a66f062A7967',
            'decimals': 0,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12992/thumb/AtariLogoPS_200x200_%281%29.png?1643189483',
            'coingeckoId': 'atari',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GENF',
            'name': 'Genesis Defi',
            'address': '0xC0AB0d085DCBA5E7c65848974c928f8fD81982Eb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27833/thumb/200photo_2022-10-09_06-35-34.jpg?1665995158',
            'coingeckoId': 'genesis-defi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIP',
            'name': 'Secured Ship',
            'address': '0xC0A696BBb66352E5b88624F1d1B8909C34Dc4E4a',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19823/thumb/ship.png?1640252656',
            'coingeckoId': 'secured-ship',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NUTG',
            'name': 'Nutgain',
            'address': '0xC091377110ACFB780dFB9f6c200b2EF81d8cE4aB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0xc091377110acfb780dfb9f6c200b2ef81d8ce4ab/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MUSO',
            'name': 'MUSO Finance',
            'address': '0xC08E10b7Eb0736368A0B92EE7a140eC8C63A2dd1',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20715/thumb/cMwOUI9V_400x400.jpg?1637578611',
            'coingeckoId': 'muso-finance-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOLV',
            'name': 'Solv Protocol',
            'address': '0xC073c4eD65622A9423b5e5BDe2BFC8B81EBC471c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27987/thumb/Solv-Logo5.png?1666863912',
            'coingeckoId': 'solv-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TCGCOIN',
            'name': 'TCGCoin',
            'address': '0xC070C8Ae94977f78d04553D33449DEf944F24254',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15715/thumb/Coin-Gecko.png?1627043213',
            'coingeckoId': 'tcgcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AMG',
            'name': 'Amgen',
            'address': '0xC05f9174Eb7f8B5102D8c83F441A5F65a684AeFc',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25291/thumb/WorYFccvCfc0SQzHHCSJyVHyE7StfNOVU9NajqXcKv3G7hvinJGlISSFBgjuamai1N6aZTUCuDQD8Lm2Nod2loHKAedAtagu-DNv_r7PlxyiAr8pP6TqPHRwmLMkYeVIMAXjUIZtDwS8B1WKLdM1qYfbZb6_9aMD9WMEh2g58Oa2tac0Y1HPSy_Q1ZGIFXSMntGbeRs2-fqMPLjeFztRR0_%281%29.jpg?1651132162',
            'coingeckoId': 'amgen',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSCCROP',
            'name': 'BSCCrop',
            'address': '0xC045fb5B3AE934a7f95679852eE598D79f268114',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15883/thumb/Untitled_design__3_-removebg-preview.png?1622192037',
            'coingeckoId': 'bsccrop',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SMARS',
            'name': 'Safemars Protocol',
            'address': '0xC0366a104b429f0806BfA98d0008DAA9555b2BEd',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16143/thumb/83904828.png?1623118918',
            'coingeckoId': 'safemars-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADT',
            'name': 'DotArcade',
            'address': '0xC008debBB1f33d9453FFd2104fEB1fe7E9663524',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22802/thumb/Logo_Dot_Arcade.png?1642584310',
            'coingeckoId': 'dotarcade',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EGC',
            'name': 'EverGrow Coin',
            'address': '0xC001BBe2B87079294C63EcE98BdD0a88D761434e',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18645/thumb/egc_200x200.png?1665032635',
            'coingeckoId': 'evergrowcoin',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'PSTN',
            'name': 'Piston',
            'address': '0xBfACD29427fF376FF3BC22dfFB29866277cA5Fb4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24868/thumb/TyVqDKYf_400x400.jpg?1650551860',
            'coingeckoId': 'piston',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFTFY',
            'name': 'Nftfy',
            'address': '0xBf6Ff49FfD3d104302Ef0AB0F10f5a84324c091c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15292/thumb/nfty.PNG?1620365235',
            'coingeckoId': 'nftfy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNI',
            'name': 'Uniswap',
            'address': '0xBf5140A22578168FD562DCcF235E5D43A02ce9B1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x1f9840a85d5af5bf1d1762f925bdaddc4201f984.png',
            'coingeckoId': 'uniswap',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MI',
            'name': 'MosterIsland',
            'address': '0xBf4C3c64F8c5EA2a6183f9fa22f8356a7Ebb5f0c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19139/thumb/mi.jpg?1634527017',
            'coingeckoId': 'mosterisland',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MILK',
            'name': 'The Crypto You',
            'address': '0xBf37f781473f3b50E82C668352984865eac9853f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21308/thumb/milk200.png?1638886288',
            'coingeckoId': 'the-crypto-you',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BRZH',
            'name': 'Brayzin Heist',
            'address': '0xBefd18D6Dd7e5b98fBD57FCb61A7cB7A2fc82c68',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26213/thumb/BRAYZIN_-_PINK_logo.png?1656574052',
            'coingeckoId': 'brayzin-heist',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FR',
            'name': 'Freedom Reserve',
            'address': '0xBea7086c99A85D4b5E6A0494C18B037fDD8eE932',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13342/thumb/J6uNL2FS_400x400.jpg?1607589046',
            'coingeckoId': 'freedom-reserve',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BETA',
            'name': 'Beta Finance',
            'address': '0xBe1a001FE942f96Eea22bA08783140B9Dcc09D28',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0xBe1a001FE942f96Eea22bA08783140B9Dcc09D28.png',
            'coingeckoId': 'beta-finance',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'PLN',
            'name': 'PLEARN',
            'address': '0xBe0D3526fc797583Dada3F30BC390013062A048B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24723/thumb/plearn200x200.png?1648697974',
            'coingeckoId': 'plearn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GTPS',
            'name': 'Gtps.Finance',
            'address': '0xBdf7d9c4Dd10dAD74b46BFeeB58B2Ce5Ff055581',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xBdf7d9c4Dd10dAD74b46BFeeB58B2Ce5Ff055581/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'APPLE',
            'name': 'AppleSwap',
            'address': '0xBdbD5A8179c9BA78327a50A8C0454c6F93bC4ce2',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20974/thumb/logoapples.png?1638158416',
            'coingeckoId': 'appleswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAPES',
            'name': 'Meta Apes',
            'address': '0xBdAF8d359eAa3bF2cC46878B912261Db0503a9E9',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21778/thumb/B1GFXlg.png?1640006302',
            'coingeckoId': 'meta-apes',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EINSTEIN',
            'name': 'Polkadog V2 0',
            'address': '0xBd65a197408230247F05247A71D1A9Aea9Db0C3c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19360/thumb/einstein-200x200.png?1635130391',
            'coingeckoId': 'polkadog-v2-0',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTRG',
            'name': 'Meter Governance',
            'address': '0xBd2949F67DcdC549c6Ebe98696449Fa79D988A9F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xBd2949F67DcdC549c6Ebe98696449Fa79D988A9F/logo.png',
            'coingeckoId': 'meter',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'XCA',
            'name': 'Xcavator',
            'address': '0xBd20F9B0DbeED33FC4436F0aE7a34eedEFdA2878',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25974/thumb/XCAVATOR.png?1655104593',
            'coingeckoId': 'xcavator',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRN',
            'name': 'Cryptorun Network',
            'address': '0xBd16c4343fB5769A71983f3e131df9B1491c2314',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20343/thumb/Untitled-design-29_1.png?1636942062',
            'coingeckoId': 'cryptorun-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LTEX',
            'name': 'Ltradex',
            'address': '0xBcB3ac7a3ceB2d0C5E162A03901d6D7bb8602912',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22001/thumb/photo1637249460-removebg-preview-1.png?1640587693',
            'coingeckoId': 'ltradex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ACH',
            'name': 'Alchemy Pay',
            'address': '0xBc7d6B50616989655AfD682fb42743507003056D',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0xBc7d6B50616989655AfD682fb42743507003056D.png',
            'coingeckoId': 'alchemy-pay',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'PSG',
            'name': 'Paris Saint-Germain',
            'address': '0xBc5609612b7C44BEf426De600B5fd1379DB2EcF1',
            'decimals': 2,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xBc5609612b7C44BEf426De600B5fd1379DB2EcF1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PCKT',
            'name': 'Pocket',
            'address': '0xBc4C03104Aee81a73aFC5b09136810A19ff52dE4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17831/thumb/pckt_twitter.jpg?1645415801',
            'coingeckoId': 'pocket-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZAM',
            'name': 'Zam io',
            'address': '0xBbcF57177D8752B21d080bf30a06CE20aD6333F8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19522/thumb/zam.png?1635324134',
            'coingeckoId': 'zam-io',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UBXT',
            'name': 'UpBots',
            'address': '0xBbEB90cFb6FAFa1F69AA130B7341089AbeEF5811',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xbbeb90cfb6fafa1f69aa130b7341089abeef5811.png',
            'coingeckoId': 'upbots',
            'listedIn': [
                'coingecko',
                '1inch',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NOE',
            'name': 'NOE GLOBAL',
            'address': '0xBbE626Be0abD64e8efd72A934F08ff9E81C909c8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26627/thumb/200x200.png?1665032251',
            'coingeckoId': 'noe-crypto-bank',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FOHO',
            'name': 'Foho Coin',
            'address': '0xBb9f216baC27046C6B8bDAAE660B761B851aB068',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17933/thumb/FOHO.Coin_colour-02-1.png?1629859400',
            'coingeckoId': 'foho-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MF',
            'name': 'MetaFighter',
            'address': '0xBb6cDedac5CaB4A420211a4A8e8B5DCA879B31De',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23261/thumb/TXirEzAw_400x400.jpg?1643362619',
            'coingeckoId': 'metafighter',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHL',
            'name': 'Shelling',
            'address': '0xBb689057fE1c4bFc573A54c0679ae1a7A1982F26',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21502/thumb/shelling.png?1639373437',
            'coingeckoId': 'shelling',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SBF',
            'name': 'SteakBank Finance',
            'address': '0xBb53FcAB7A3616C5be33B9C0AF612f0462b01734',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15416/thumb/steakbank.PNG?1620773943',
            'coingeckoId': 'steakbank-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BFG',
            'name': 'BetFury',
            'address': '0xBb46693eBbEa1aC2070E59B4D043b47e2e095f86',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xbb46693ebbea1ac2070e59b4d043b47e2e095f86_1.png',
            'coingeckoId': 'bfg-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'BAGEL',
            'name': 'Bagels Finance',
            'address': '0xBb238FcE6E2eE90781cD160C9C6eAf3a4CfAD801',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xbb238fce6e2ee90781cd160c9c6eaf3a4cfad801.png',
            'coingeckoId': 'bagel',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'aBNBb',
            'name': 'Ankr BNB Reward Earning Bond',
            'address': '0xBb1Aa6e59E5163D8722a122cd66EBA614b59df0d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xbb1aa6e59e5163d8722a122cd66eba614b59df0d.png',
            'coingeckoId': 'ankr-reward-earning-bnb',
            'listedIn': [
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'USDM',
            'name': 'USD Mars',
            'address': '0xBb0fA2fBE9b37444f5D1dBD22e0e5bdD2afbbE85',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21252/thumb/WeChat_Image_20211230181747.jpg?1640859506',
            'coingeckoId': 'usd-mars',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CLPS',
            'name': 'Cleeps',
            'address': '0xBaEE0088bad4a3F8BE2eA85A93a098755eacB3DD',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26859/thumb/IMG_20220813_230600_320.jpg?1660529461',
            'coingeckoId': 'cleeps',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FIRE',
            'name': 'Fire',
            'address': '0xBaCbF768795C50FC5E877830A127276741651D0a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15392/thumb/logo_256-1.png?1620734133',
            'coingeckoId': 'fire-token-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GEM',
            'name': 'NFTmall',
            'address': '0xBaC1df744df160877cDc45E13d0394c06bC388Ff',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16217/thumb/Icon-1000x1000.png?1623316471',
            'coingeckoId': 'nftmall',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MSR',
            'name': 'MetaSnooker',
            'address': '0xBa8d9B8F138EE5269A5C23339775510D050b75C1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24038/thumb/HZ3VfBy.png?1646118467',
            'coingeckoId': 'metasnooker',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INMES',
            'name': 'Inme Swap',
            'address': '0xBa1063bb72c58D1BDe32674FaD22174F46837c72',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26523/thumb/inme_swap.png?1658469159',
            'coingeckoId': 'inme-swap-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MINIDOGE',
            'name': 'MiniDOGE',
            'address': '0xBa07EED3d09055d60CAEf2bDfCa1c05792f2dFad',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16837/thumb/MiniDoge_Token_200_svlozg.png?1625294597',
            'coingeckoId': 'minidoge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MPL',
            'name': 'MetaPlanet',
            'address': '0xBa007b6170C602C08545ff97395677408688D3a2',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22833/thumb/9uTc91QZ_400x400.jpg?1642669257',
            'coingeckoId': 'metaplanet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PITI',
            'name': 'Pitbull Inu',
            'address': '0xBFF96267C5A60aa61763dD7159DA14d6DF6Da371',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27068/thumb/photo_2022-08-26_00-59-26.jpg?1661674157',
            'coingeckoId': 'pitbull-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NAV',
            'name': 'Navcoin',
            'address': '0xBFEf6cCFC830D3BaCA4F6766a0d4AaA242Ca9F3D',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/233/thumb/Navcoin_Logo.png?1618823660',
            'coingeckoId': 'nav-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CBSL',
            'name': 'CeBioLabs',
            'address': '0xBFB8F92E8F3a9034019aC97fd9f85c6Dfb513834',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27523/thumb/XQ1K7Vy1_400x400.jpeg?1664369061',
            'coingeckoId': 'cebiolabs',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOG',
            'name': 'Soccer Galaxy',
            'address': '0xBF2A2828C7DF44Afe0ef2c14910E29b9878D9695',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27619/thumb/sog.png?1664878694',
            'coingeckoId': 'soccer-galaxy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MM',
            'name': 'Million',
            'address': '0xBF05279F9Bf1CE69bBFEd670813b7e431142Afa4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xBF05279F9Bf1CE69bBFEd670813b7e431142Afa4/logo.png',
            'coingeckoId': 'million',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'DOGEMETA',
            'name': 'Dogemetaverse',
            'address': '0xBEeEE429f8FB7ac8435902707639518D246780c7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24987/thumb/wemZesEz_400x400.jpg?1649682210',
            'coingeckoId': 'dogemetaverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EC',
            'name': 'EverChain',
            'address': '0xBEC511e6f74989002aA2BE18a651bFB290cD911D',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27707/thumb/LOGO.png?1665313877',
            'coingeckoId': 'everchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MMC',
            'name': 'Monopoly Millionaire Control',
            'address': '0xBE3fD4d1E0d244DdD98686a28f67355EFe223346',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26608/thumb/zdtbAbZJ_400x400.jpeg?1659019021',
            'coingeckoId': 'monopoly-millionaire-control',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REZ',
            'name': 'REZ',
            'address': '0xBE1a6D88192b54b3b1Fa6945B9F0d0A4349F7856',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/7332.png',
            'coingeckoId': null,
            'listedIn': [
                'RouterProtocol'
            ]
        },
        {
            'symbol': 'TRNDZ',
            'name': 'Trendsy',
            'address': '0xBD8ccac4bD523Cb2FDB34876De2f596dBF75885e',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23088/thumb/Trendsy-logo-200x200-1.png?1643181548',
            'coingeckoId': 'trendsy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DIQ',
            'name': 'DiamondQ',
            'address': '0xBD573dDc3aB93D7984012B897821F6C09F4D24E3',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21572/thumb/diq-200.png?1639486824',
            'coingeckoId': 'diamondq',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LQT',
            'name': 'Liquidifty',
            'address': '0xBD2C43Da85d007B0b3cd856FD55c299578D832bC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16384/thumb/ktQzdAe.png?1628601338',
            'coingeckoId': 'liquidifty',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AVXL',
            'name': 'Avaxlauncher',
            'address': '0xBD29490383edFd560426C3B63d01534408bC2da6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18270/thumb/avaxlauncher.png?1631174466',
            'coingeckoId': 'avaxlauncher',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IDYP',
            'name': 'iDeFiYieldProtocol',
            'address': '0xBD100d061E120b2c67A24453CF6368E63f1Be056',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21976/thumb/i-DYP-Logo-1.png?1640570294',
            'coingeckoId': 'idefiyieldprotocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHC',
            'name': 'Cherish',
            'address': '0xBCd192f38457619fbDEf609A194E8ab467Cc3A58',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24274/thumb/logo-svg.png?1647180153',
            'coingeckoId': 'cherish',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XETA',
            'name': 'XANA',
            'address': '0xBC7370641ddCF16A27eeA11230Af4a9F247B61F9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24379/thumb/XANA_Logo_neon_pink.png?1658197388',
            'coingeckoId': 'xana',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'REPO',
            'name': 'Repo Coin',
            'address': '0xBC5BE3E43D4c74960890B672236c7f2A1E78F457',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/4371/thumb/repocoin-logo.jpg?1547039719',
            'coingeckoId': 'repo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VIVA',
            'name': 'Viva',
            'address': '0xBC1afF2C8fBcF65a96e4A634F2d69D2cd483036A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25300/thumb/Logo-ReverseWebsiteColor.png?1651151937',
            'coingeckoId': 'viva',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COPTER',
            'name': 'Helicopter Finance',
            'address': '0xBC12aD556581ff7162E595E5956F5F3845FDB38c',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15217/thumb/att1YJDb_400x400.jpg?1620115502',
            'coingeckoId': 'helicopter-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ENERGYX',
            'name': 'Safe Energy',
            'address': '0xBBe899c61198D1826a43e20ea19efC46E50c2B00',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16249/thumb/kare-200x200.png?1646736281',
            'coingeckoId': 'safe-energy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOMI',
            'name': 'Domi',
            'address': '0xBBCA42c60b5290F2c48871A596492F93fF0Ddc82',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1642568723650_19067383325168286.png',
            'coingeckoId': 'domi',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'METAGS',
            'name': 'MetaGameSpace',
            'address': '0xBB77D0A1181E38a0374Dc6891E2847C2b61B3545',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24294/thumb/DdvFfVw.jpeg?1647265406',
            'coingeckoId': 'metagamespace',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XFUEL',
            'name': 'BUSDX Fuel',
            'address': '0xBB00BCaa4fe12AEa0a74956d9531ec96Cf4470B0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26852/thumb/xfuel200.png?1660481208',
            'coingeckoId': 'busdx-fuel',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CCAP',
            'name': 'CUEX Capital',
            'address': '0xBA94C527E780AFfcDcE175eB03FBa9df0e392d2D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19021/thumb/cues.png?1634189816',
            'coingeckoId': 'cuex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LYPTUS',
            'name': 'Lyptus',
            'address': '0xBA26397cdFF25F0D26E815d218Ef3C77609ae7f1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15992/thumb/logo_-_2021-06-01T193417.635.png?1622547404',
            'coingeckoId': 'lyptus-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRAFT',
            'name': 'CRAFT',
            'address': '0xBA0626Fdd269140fda8919B33c5585137daffED5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'YES',
            'name': 'Yes World',
            'address': '0xB9d35811424600fa9E8cD62A0471fBd025131cb8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26492/thumb/Yes-World-200-X-200-Pixl-PNG.png?1658284131',
            'coingeckoId': 'yes-world',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTNT',
            'name': 'BitNautic',
            'address': '0xB9c7099D2FBbF82314de08045745DaF951CDDa85',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/2875/thumb/Logobit.jpg?1547037044',
            'coingeckoId': 'bitnautic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CENT',
            'name': 'Centaurify',
            'address': '0xB9b41dA7FA895b093b95340a3379383Bba36735E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20512/thumb/GTU5RBD0.png?1645205770',
            'coingeckoId': 'centaurify',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOGE',
            'name': 'LunaDoge',
            'address': '0xB99172949554E6C10c28c880eC0306d2A9d5C753',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15647/thumb/LunaDoge.png?1621426586',
            'coingeckoId': 'lunadoge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEXF',
            'name': 'Dexfolio',
            'address': '0xB9844A9Cb6aBD9F86bb0B3aD159e37EeccE08987',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17280/thumb/dexp-ticker-logo-256.png?1627027717',
            'coingeckoId': 'dexfolio',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'AFIN',
            'name': 'Asian Fintech',
            'address': '0xB955B4cAb9Aa3B49E23AEB5204EBc5fF6678E86D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/7519/thumb/Afinlogo-200x200.png?1630048356',
            'coingeckoId': 'afin-coin',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MTVT',
            'name': 'Metaverser',
            'address': '0xB92c5e0135A510A4a3A8803F143d2Cb085BBAf73',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27472/thumb/s4Ce6y1r_400x400.jpeg?1664195404',
            'coingeckoId': 'metaverser',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PO',
            'name': 'PlayersOnly',
            'address': '0xB9218E62ec3216461D3d5147e7B37973d0eBd20a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20352/thumb/pa.PNG?1636943583',
            'coingeckoId': 'playersonly',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UDO',
            'name': 'Unus Dao',
            'address': '0xB91Ec4F9D7D12A1aC145A7Ae3b78AFb45856C9c8',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20914/thumb/3c2IfwK-_400x400.jpg?1637923093',
            'coingeckoId': 'unus-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FREEDOM',
            'name': 'Free Novak',
            'address': '0xB8c3CA595FbA7b9AF1b8A29D6FB9F0e0A682c327',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23370/thumb/logo_%282%29.png?1643955421',
            'coingeckoId': 'free-novak',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TAHU',
            'name': 'TAHU',
            'address': '0xB8EdD4261031c620431FE070B9e4B516571E9918',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18284/thumb/TAHU_LOGO_trans_200x200.png?1631252700',
            'coingeckoId': 'tahu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'H2O',
            'name': 'Trickle',
            'address': '0xB8B932D41d6bE935Ce1666AAF41f056093F9faeE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20830/thumb/gFiD-RNL_400x400.jpg?1637726434',
            'coingeckoId': 'trickle',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MNC',
            'name': 'Maincoin',
            'address': '0xB8AE295FEF08728B48094D5A1D4A01F2b85139F8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0xb8ae295fef08728b48094d5a1d4a01f2b85139f8/logo.png',
            'coingeckoId': 'n/a',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SERENE',
            'name': 'Serenity V2',
            'address': '0xB8937846d416062aE02D87391d855C2befe1F942',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'EVERBNB',
            'name': 'EverBNB',
            'address': '0xB87Bc0fF5Dd585A93c44D8511d876933e4233707',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21899/thumb/16320.png?1640232399',
            'coingeckoId': 'everbnb',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIBA',
            'name': 'BitShiba',
            'address': '0xB84cBbF09b3Ed388a45cD875ebba41a20365e6e7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20205/thumb/3g2LGTkS_400x400.jpg?1636624175',
            'coingeckoId': 'bitshiba',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LAW',
            'name': 'Law Token',
            'address': '0xB84aA25ae8e33962de303b21aF3d36d4f75D9eA9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24412/thumb/18910.png?1647559216',
            'coingeckoId': 'law-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SQUA',
            'name': 'Square',
            'address': '0xB82BEb6Ee0063Abd5fC8E544c852237aA62CBb14',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22530/thumb/logo_square_token_200x200.png?1641997078',
            'coingeckoId': 'square-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PSHARE',
            'name': 'PSHARE',
            'address': '0xB8295460962728d496a138a391F18b1b558025eD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SUNRISE',
            'name': 'The Sun Rises',
            'address': '0xB8170d3ffaF4D284bC2c3c2fEcF9aC9038b85d98',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19200/thumb/sunrise.PNG?1634680339',
            'coingeckoId': 'the-sun-rises',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UXT',
            'name': 'Uxcord Token',
            'address': '0xB80Cfe66DE9809f5528dbD794D25A4e61F5a28D9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': 'uxcord-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOD',
            'name': 'Game of Dragons',
            'address': '0xB801d03e1C394AbD0d77440376e7F0d473254C0B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26211/thumb/GoD200x200.png?1656572410',
            'coingeckoId': 'game-of-dragons',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XTS',
            'name': 'Xaviera Tech',
            'address': '0xB7E6a9Ed594954F01aA87B8A8EB0601e0eF38e88',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27249/thumb/xts.png?1662946750',
            'coingeckoId': 'xaviera-tech',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FATCAKE',
            'name': 'FatCake',
            'address': '0xB7Dba4C673beDB174DC3Ff7Ec65d17C863d39b16',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17400/thumb/Copy-of-Untitled-6.png?1631512375',
            'coingeckoId': 'fatcake',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WSC',
            'name': 'WealthSecrets',
            'address': '0xB7DAcf54a54bFea818F21472d3E71a89287841A7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20106/thumb/logo-box.png?1636512377',
            'coingeckoId': 'wealthsecrets',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CPX',
            'name': 'CenterPrime',
            'address': '0xB7B1bd104645D5A06120d369c63822b2AeAd1598',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15794/thumb/centerprime.PNG?1621906058',
            'coingeckoId': 'centerprime',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BURNDOGE',
            'name': 'BurnDoge',
            'address': '0xB73569A56614867601cD330DEa8ab5a8f5570a2C',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17944/thumb/Burndoge-200x200-no-bg.png?1629883728',
            'coingeckoId': 'burndoge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PSR',
            'name': 'Pandora Spirit',
            'address': '0xB72bA371c900aa68bb9Fa473e93CfbE212030fCb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25871/thumb/171621938-58e12d04-f26e-43f2-9ec3-8c352690defb.png?1654241031',
            'coingeckoId': 'pandora-spirit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EFT',
            'name': 'ETH Fan Token Ecosystem',
            'address': '0xB72962568345253f71A18318D67E13A282b187E6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22612/thumb/eft.png?1642236393',
            'coingeckoId': 'eth-fan-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOMA',
            'name': 'Mochi Market',
            'address': '0xB72842D6F5feDf91D22d56202802Bb9A79C6322E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14993/thumb/mochi.PNG?1619390399',
            'coingeckoId': 'mochi-market',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SB',
            'name': 'DragonSB',
            'address': '0xB7258450681f4aAd0ab24E336648d44A6696B30f',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23738/thumb/photo_2022-05-25_10-35-23.jpg?1653552638',
            'coingeckoId': 'dragonsb',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GAINZ',
            'name': 'FlokiGainz',
            'address': '0xB6a41C1fAcC08aADe9257b068041c9B3420693f3',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23545/thumb/ZJK276A.jpeg?1644393535',
            'coingeckoId': 'flokigainz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIKO',
            'name': 'Shikoku Inu',
            'address': '0xB6D053E260d410eAc02eA28755696F90A8ECca2B',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16442/thumb/shiko.PNG?1624234177',
            'coingeckoId': 'shikoku-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIX',
            'name': 'Mix',
            'address': '0xB67754f5b4C704A24d2db68e661b2875a4dDD197',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xB67754f5b4C704A24d2db68e661b2875a4dDD197/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DFG',
            'name': 'Defigram',
            'address': '0xB661F4576D5e0B622fEe6ab041Fd5451Fe02BA4C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25162/thumb/19590.png?1650519619',
            'coingeckoId': 'defigram',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PRV',
            'name': 'Incognito',
            'address': '0xB64fde8f199F073F41c132B9eC7aD5b61De0B1B7',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21971/thumb/50738351.png?1640560573',
            'coingeckoId': 'incognito-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WAULTx',
            'name': 'Wault',
            'address': '0xB64E638E60D154B43f660a6BF8fD8a3b249a6a21',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xB64E638E60D154B43f660a6BF8fD8a3b249a6a21/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BSL',
            'name': 'BSClaunch',
            'address': '0xB60501346240FCdE1615de56eA9FFf1AC1da5673',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16044/thumb/Logo_BSCLaunch_Final_Expand-03.png?1622685068',
            'coingeckoId': 'bsclaunch',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YOU',
            'name': 'Youwho',
            'address': '0xB583961E033Dfe0FfF161952f7BA21c411b6103d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25353/thumb/youwho_200.png?1651312259',
            'coingeckoId': 'youwho',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALF',
            'name': 'ALPHAFI',
            'address': '0xB547e4f2647d619123F6B92AbD01ADdED41eF3aC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27532/thumb/alphafiofficial.png?1664410852',
            'coingeckoId': 'alphafi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFEST',
            'name': 'SafuFide',
            'address': '0xB51499F983A8B186Ea2FEf4074e3A93DcC9CA355',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21480/thumb/safefidu.PNG?1639351813',
            'coingeckoId': 'safufide',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USX',
            'name': 'dForce USD',
            'address': '0xB5102CeE1528Ce2C760893034A4603663495fD72',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17422/thumb/usx_32.png?1627600920',
            'coingeckoId': 'token-dforce-usd',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'SOCAP',
            'name': 'Social Capitalism',
            'address': '0xB4eBc6Fd70A852d052163f25949C70Fb9506D6a0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26083/thumb/oPT2utjj_400x400.png?1655724337',
            'coingeckoId': 'social-capitalism-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HRS',
            'name': 'Heres',
            'address': '0xB4e4E2bA82420B07847654B163C35E2D7774af38',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24082/thumb/EkAIccL.jpg?1646289417',
            'coingeckoId': 'heres',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PAL',
            'name': 'Paladin DAO',
            'address': '0xB4dA413D7643000A84C5B62Bfb1bF2077604b165',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22822/thumb/logo_%283%29.png?1642662751',
            'coingeckoId': 'paladin-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEFI',
            'name': 'D3',
            'address': '0xB4c16Ed711c06b84e4312d5f09bcbD88E4F4d3b6',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23112/thumb/D3pro.4e6dfc66.png?1643263007',
            'coingeckoId': 'd3',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'T99',
            'name': 'T99',
            'address': '0xB4a8Aed9b705D780B29D48cCa2A6Ca9d63feaf14',
            'decimals': 17,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21504/thumb/T99.png?1639373839',
            'coingeckoId': 't99',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WHEEL',
            'name': 'Wheelers',
            'address': '0xB4B42D968CBF9e7d07ecA5f03F8B4D99Dfd7f7C9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18505/thumb/wheel_logo.png?1632211340',
            'coingeckoId': 'wheelers',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BZP',
            'name': 'BitZipp',
            'address': '0xB49988A9eCBF0455b3b43fff7e64960D8399CCB8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19122/thumb/91uivPTI_400x400.png?1634520429',
            'coingeckoId': 'bitzipp',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MFO',
            'name': 'MoonFarm Finance',
            'address': '0xB46049c79D77fF1D555a67835FbA6978536581Af',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16337/thumb/logo-mf-new-200px-trans.png?1628512247',
            'coingeckoId': 'moonfarm-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RFX',
            'name': 'Reflex',
            'address': '0xB44c63a09adF51f5E62CC7B63628b1B789941FA0',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12745/thumb/2MKGMRCT_400x400.png?1602194456',
            'coingeckoId': 'reflex',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'RWSC',
            'name': 'RewardsCoin',
            'address': '0xB44c00a75D63eB9C12ec97add741E554ebC2f4bD',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19042/thumb/rwsc.PNG?1634252707',
            'coingeckoId': 'rewardscoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COC',
            'name': 'CryptoClans',
            'address': '0xB43FCC44973738e7b8C0025Cf25C7f1c46dE1549',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22684/thumb/fPY46vLWFB7UJdt02jdx-TBiA-4uFtzHTDLuamrccOsQN5l28YGFBRgAOYhbMUeyFlARF06AgZr6JQiWKUdQj5k9g5tmCjKMHNn9t9HHE5p6j2TFpLRt5LmSsjVUMgDAreV0y8N7YqP8UL8PwxNohv6yI_YyZhvTujGU1XKquuEz5s4QAfudU4sunXWd3DVVXHKK0qtIRQ2kj5.jpg?1642408268',
            'coingeckoId': 'cryptoclans',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ITUBE',
            'name': 'iTube',
            'address': '0xB4192c333E6d38a5D2a21dcf8C83283F3c97ef3c',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27294/thumb/itube.png?1663149753',
            'coingeckoId': 'itube',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOOP',
            'name': 'Loop X Network',
            'address': '0xB3b361Ba28b1058f2dBDfBbB5Bb3B98cf46b4540',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26413/thumb/LoopCoin_200_200.png?1657925223',
            'coingeckoId': 'loop-x-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PNL',
            'name': 'True PNL',
            'address': '0xB346c52874c7023dF183068c39478C3b7B2515bC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15282/thumb/256x256logo.png?1623230007',
            'coingeckoId': 'true-pnl',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AOG',
            'name': 'smARTOFGIVING',
            'address': '0xB32D4817908F001C2A53c15bFF8c14D8813109Be',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/6050/thumb/logo_%286%29.png?1547042007',
            'coingeckoId': 'smartofgiving',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAST',
            'name': 'Magic Cube Finance',
            'address': '0xB31FD05CAF496CEf34C945667E90dd89C20E0D09',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23539/thumb/msg643016208-53328.jpg?1644390284',
            'coingeckoId': 'magic-cube-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NXG',
            'name': 'Nexa',
            'address': '0xB2feE738D28cb3095AE7ad3AeA2b4a53683aFb4B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26167/thumb/98_%281%29.jpg?1656325242',
            'coingeckoId': 'nexa',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VALI',
            'name': 'Valimarket',
            'address': '0xB2f307fc5D7B34F22267aEF01e84915a4509cF86',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26264/thumb/200to200.png?1656982970',
            'coingeckoId': 'valimarket',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XIX',
            'name': 'Million Pixel',
            'address': '0xB2ebbFF04Bdcb3DED0Cb99C544521A17e940FF96',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26915/thumb/million-logo.png?1660788871',
            'coingeckoId': 'million-pixel',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZOA',
            'name': 'Zone of Avoidance',
            'address': '0xB2e841894b1C3d638948517F6234c6e06D3B8e1C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25202/thumb/ZoA-CMC.png?1650954699',
            'coingeckoId': 'zone-of-avoidance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DUSK',
            'name': 'Dusk Network',
            'address': '0xB2BD0749DBE21f623d9BABa856D3B0f0e1BFEc9C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x940a2db1b7008b6c776d4faaca729d6d4a4aa551.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WND',
            'name': 'WonderHero  OLD ',
            'address': '0xB2AA453B56b495cb95BC9fa72E7706B84e41EE54',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19260/thumb/wnd.png?1634812369',
            'coingeckoId': 'wonderhero',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POOCOIN',
            'name': 'PooCoin',
            'address': '0xB27ADAfFB9fEa1801459a1a81B17218288c097cc',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14855/thumb/w4_9ayk3_400x400.png?1618802191',
            'coingeckoId': 'poocoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SFL',
            'name': 'ScarFace Lion',
            'address': '0xB24Ad186DC2064dab4E726679a969e71eACa74df',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27329/thumb/Group_29.png?1663396560',
            'coingeckoId': 'scarface-lion',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VXRP',
            'name': 'Venus XRP',
            'address': '0xB248a295732e0225acd3337607cc01068e3b9c10',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xb248a295732e0225acd3337607cc01068e3b9c10.png',
            'coingeckoId': 'venus-xrp',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'TAP',
            'name': 'TAPME',
            'address': '0xB229c47132d42576a15037a2931f9edD01AC0c2A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19092/thumb/tapme.PNG?1634422236',
            'coingeckoId': 'tapme-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CGT',
            'name': 'Coin Gabbar Token',
            'address': '0xB1cf48a14eA54D63505A8C4063052886E98062e5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27947/thumb/LOGO.jpg?1666611230',
            'coingeckoId': 'coin-gabbar-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZOMFI',
            'name': 'Zomfi',
            'address': '0xB1a05D0EE3c8d936F70b77318A348c0dE2F8a396',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21231/thumb/Zomfi_Coin_Logo_200x200.png?1638757232',
            'coingeckoId': 'zomfi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUTO',
            'name': 'Luto Cash',
            'address': '0xB1F0ba284811a649Ea30240Ea03A75496fa18998',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18889/thumb/CG2VIsRg_400x400.jpg?1633749578',
            'coingeckoId': 'luto-cash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VALAS',
            'name': 'Valas Finance',
            'address': '0xB1EbdD56729940089Ecc3aD0BBEEB12b6842ea6F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24733/thumb/valas-finance.png?1648738556',
            'coingeckoId': 'valas-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GSG',
            'name': 'Gold Socialfi GameFi',
            'address': '0xB1DCCaB34962a51919f2e439C010f66b28085eF7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26300/thumb/gsg.png?1657613574',
            'coingeckoId': 'gold-socialfi-gamefi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOONLIGHT',
            'name': 'Moonlight',
            'address': '0xB1CeD2e320E3f4C8e3511B1DC59203303493F382',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15908/thumb/logo-200x200_%284%29.png?1622413557',
            'coingeckoId': 'moonlight-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOND',
            'name': 'Bondly',
            'address': '0xB19a3f8761E4EcFd26AaA2e3a302b45692daCeD3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28159/thumb/logo_%283%29.png?1668163003',
            'coingeckoId': 'bondly-defi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HOT',
            'name': 'HyperOne',
            'address': '0xB15E296636CD8C447f0d2564ce4d7dFE4A72A910',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25878/thumb/HOT.png?1654581557',
            'coingeckoId': 'hyperone',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NUTGV2',
            'name': 'NUTGAIN',
            'address': '0xB149B030CFA47880aF0BDE4Cd36539E4C928b3eB',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25567/thumb/nutgv2.png?1652527105',
            'coingeckoId': 'nutgain',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'FALCONS',
            'name': 'Falcon Swaps',
            'address': '0xB139eD26b743C7254A246cf91eb594D097238524',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19164/thumb/RK04yosg_400x400.jpg?1634602931',
            'coingeckoId': 'falcon-swaps',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LEVE',
            'name': 'Leve Invest',
            'address': '0xB12418ae5284aC6215EE255B5cBC7D795E7016B5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27929/thumb/leve.png?1666422579',
            'coingeckoId': 'leve-invest',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PRI',
            'name': 'Privateum',
            'address': '0xB10be3f4C7e1f870d86eD6Cfd412fED6615FeB6F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14995/thumb/c9C2piFC_400x400.png?1654586618',
            'coingeckoId': 'privateum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EARN',
            'name': 'EarnGuild',
            'address': '0xB0eB3e295B44d7D405ba8026A9734A9ab354a8B2',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23923/thumb/Earn-Guild-Helmet-200x200-Transparent.png?1645688827',
            'coingeckoId': 'earnguild',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IF',
            'name': 'Impossible Finance',
            'address': '0xB0e1fc65C1a741b4662B813eB787d369b8614Af1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xB0e1fc65C1a741b4662B813eB787d369b8614Af1/logo.png',
            'coingeckoId': 'impossible-finance',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'POOP',
            'name': 'PoopCoin',
            'address': '0xB0c2700eEF5302Fc81F183fA35139037287AaC0A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27272/thumb/21757.png?1663064281',
            'coingeckoId': 'poopcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GEN',
            'name': 'Gen Token',
            'address': '0xB0F2939A1c0e43683E5954c9Fe142F7df9F8D967',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xb0f2939a1c0e43683e5954c9fe142f7df9f8d967.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'DXS',
            'name': 'Dx Spot',
            'address': '0xB0Df5519F460E96117C12Ea667557b161866189c',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24224/thumb/DX-ICON.png?1646920676',
            'coingeckoId': 'dx-spot',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STG',
            'name': 'Stargate Finance',
            'address': '0xB0D502E938ed5f4df2E681fE6E419ff29631d62b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xb0d502e938ed5f4df2e681fe6e419ff29631d62b.png',
            'coingeckoId': 'stargate-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'BOB',
            'name': 'BOB',
            'address': '0xB0B195aEFA3650A6908f15CdaC7D92F8a5791B0B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27266/thumb/Bob-logo.png?1663073030',
            'coingeckoId': 'bob',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BOG',
            'name': 'Bogged Finance',
            'address': '0xB09FE1613fE03E7361319d2a43eDc17422f36B09',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15980/thumb/bog.png?1638183823',
            'coingeckoId': 'bogged-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MLAND',
            'name': 'Mland',
            'address': '0xB08F67c04BfdF069017365969Ca19a0aE6e66B85',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25839/thumb/mland-logo_200x200.png?1654077586',
            'coingeckoId': 'mland',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XMETA',
            'name': 'X Metaverse',
            'address': '0xB080171C8999C336cC115d4d8224C2dE51657A1c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25645/thumb/xmeta-token.png?1652949678',
            'coingeckoId': 'x-metaverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OPOLY',
            'name': 'opoly.game',
            'address': '0xB07C4D0f7f4910721Ff405e09cCb5DF43dB7953a',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0xb07c4d0f7f4910721ff405e09ccb5df43db7953a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'NOID',
            'name': 'Tokenoid',
            'address': '0xB068bD15AEDf75Be6c2c6d0Be10b286a32087120',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19945/thumb/tokenoid.png?1636336616',
            'coingeckoId': 'tokenoid',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'mWSPP',
            'name': 'Multichain WSPP',
            'address': '0xB067C1dd69d41FfA29f959901c91Aa7731cC4E26',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'KINO',
            'name': 'Kino',
            'address': '0xB04Ef3B613F2F4634d970807d16665Cff7a4472b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22015/thumb/m5Slxksq_400x400.jpg?1640592386',
            'coingeckoId': 'kino',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OLAND',
            'name': 'OceanLand',
            'address': '0xB0461d7E8212D311b842A58e9989edE849ac6816',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24841/thumb/jP1nV5sb_400x400.jpg?1649077799',
            'coingeckoId': 'oceanland',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLURT',
            'name': 'Blurt',
            'address': '0xB0458283033e5A3f7867F409477f53754b667DCC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12996/thumb/8SzwQc8j2KJa5zQFn3ArqGwN8arxoZj3EEz8h4AtHCdLzKWsjWiXAgTPKaNsZAkzyJZdMHrdemGtWAUeeQuaxEks6SKbYTWEoHj9gRv7t322LQ7xh1p.png?1604243870',
            'coingeckoId': 'blurt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FAN',
            'name': 'Fanspel',
            'address': '0xB0228Eb6c0b49f8265e6E161c3a987eeD7471F42',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19102/thumb/fanspel.PNG?1634505348',
            'coingeckoId': 'fanspel',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YUMMY',
            'name': 'Yummy',
            'address': '0xB003C68917BaB76812797d1b8056822f48E2e4fe',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15589/thumb/eFjXvUxN_400x400.jpg?1624904467',
            'coingeckoId': 'yummy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WORM',
            'name': 'WormFi',
            'address': '0xAff6a69959314d06f7ED743232144F98701e34D5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21380/thumb/61b170643af4b2f41a36f4f2_wormicon.png?1639033927',
            'coingeckoId': 'wormfi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LINKS',
            'name': 'Links',
            'address': '0xAfFeAbc20b2caFa80d2d7fF220Ad37E4EC7541D7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21351/thumb/getlinks-coin.png?1638999202',
            'coingeckoId': 'links',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BIG',
            'name': 'BIG Token',
            'address': '0xAfAEb84415c1cd2bC99cd1F5F0B3090baE1BEb6c',
            'decimals': 9,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WENTXN',
            'name': 'RatBoy BSC',
            'address': '0xAf98e6c2D3b520F4e9b3D7ea22ad7AeFFFDbc2fd',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://storage.googleapis.com/xy-finance-images/token-list/WENTXN.png',
            'coingeckoId': 'ratboy-bsc',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'MVERSE',
            'name': 'MaticVerse',
            'address': '0xAf7BfA6240745Fd41D1ED4b5fADE9DCAF369bA6c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18403/thumb/A0782-F05-535-C-45-C8-BE4-F-FEBB4-B8-B5933.jpg?1631792934',
            'coingeckoId': 'maticverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EPX',
            'name': 'Ellipsis X',
            'address': '0xAf41054C1487b0e5E2B9250C0332eCBCe6CE9d71',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25444/thumb/ellipsis-light_%281%29.png?1651786591',
            'coingeckoId': 'ellipsis-x',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AVO',
            'name': 'Avoteo',
            'address': '0xAed8bD0608ef3Cc45290a8d0E4223Ef4C92Dd3dc',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27659/thumb/photo_2022-10-06_13-03-48.jpg?1665100954',
            'coingeckoId': 'avoteo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CBUNNY',
            'name': 'Crazy Bunny Equity',
            'address': '0xAec23008B1098E39c0f8De90Bf7431d185EfE8B3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19675/thumb/500x500px.png?1635739677',
            'coingeckoId': 'crazy-bunny-equity-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CON',
            'name': 'MetaContinental',
            'address': '0xAe9cC5236a2Fbb2A9A2E913298d3048C7Aa4B722',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24862/thumb/200-200.png?1649164549',
            'coingeckoId': 'metacontinental',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PALM',
            'name': 'PalmPay',
            'address': '0xAe9A270567dCAee18A909830b1c7F6Ec8A4243f8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28216/thumb/logoGreen_%282%29.png?1668428951',
            'coingeckoId': 'palmpay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EFT',
            'name': 'Energyfi',
            'address': '0xAe98E63dB1c4646BF5b40B29c664Bc922f71Bc65',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23498/thumb/fbb562_28b2de266adc4c63b6d58e3021158c44_mv2.png?1656132886',
            'coingeckoId': 'energyfi',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BURGER',
            'name': 'BurgerCities',
            'address': '0xAe9269f27437f0fcBC232d39Ec814844a51d6b8f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xae9269f27437f0fcbc232d39ec814844a51d6b8f.png',
            'coingeckoId': 'burger-swap',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ING',
            'name': 'Infinity Angel',
            'address': '0xAe7c682Ba26AD6835B6150FfB35F22Db9987f509',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27651/thumb/ycHkKpKm_400x400.jpeg?1665045539',
            'coingeckoId': 'infinity-angel',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FOA',
            'name': 'Fragments of Arker',
            'address': '0xAe7300B51A8cd43D5B74be0DD5047715B7017E3e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22103/thumb/16642.png?1640824684',
            'coingeckoId': 'fragments-of-arker',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CTS',
            'name': 'Citrus',
            'address': '0xAe67Cf598a349aFff89f6045108c6C1850f82839',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22519/thumb/15554.png?1641969740',
            'coingeckoId': 'citrus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOBO',
            'name': 'DogeBonk',
            'address': '0xAe2DF9F730c54400934c06a17462c41C08a06ED8',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19153/thumb/dobo.png?1640080606',
            'coingeckoId': 'dogebonk',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'X',
            'name': 'GIBX Swap',
            'address': '0xAe28714390e95B8dF1Ef847C58AEaC23ED457702',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18412/thumb/x.PNG?1631829219',
            'coingeckoId': 'gibx-swap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SCULPT',
            'name': 'Sculptor',
            'address': '0xAd3E02e83b886543D1171FF446C130D52068C106',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28102/thumb/Sculptor_master.png?1667539982',
            'coingeckoId': 'sculptor',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SKBR',
            'name': 'SkyBridger',
            'address': '0xAd1f255571aC404d1213842Ad86658F8C6b3D717',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25109/thumb/Logo-SKBR1-200x200.png?1650338350',
            'coingeckoId': 'skybridger',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NADA',
            'name': 'Nothing',
            'address': '0xAce8C5e6e492Aa7AEbF31a8053F8a487f62CeB84',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16090/thumb/Gecko-200x200.png?1624544349',
            'coingeckoId': 'nothing',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KFR',
            'name': 'KING FOREVER',
            'address': '0xAcFF4E9E9110971E1A4D8f013F5f97dd8fB4F430',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21706/thumb/king200.png?1639747034',
            'coingeckoId': 'king-forever',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MSS',
            'name': 'Monster Slayer Share',
            'address': '0xAcABD3f9b8F76fFd2724604185Fa5AFA5dF25aC6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xacabd3f9b8f76ffd2724604185fa5afa5df25ac6.png',
            'coingeckoId': 'monster-cash-share',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'VNY',
            'name': 'Vanity',
            'address': '0xAbc69f2025bDb12efcdB8fd048d240fFf943ca82',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15899/thumb/GGkuplD_%281%29.png?1627396656',
            'coingeckoId': 'vanity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIRROR',
            'name': 'Mirror Finance',
            'address': '0xAbC0eA3d5A7b4D85b44Ae90F71cc1Bbd74F98AF5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27955/thumb/Mirror_Logo_Icon.png?1666670421',
            'coingeckoId': 'mirror-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AUM',
            'name': 'Atlantis Universe Money',
            'address': '0xAb7D67D2c229c34876B1b074BFb4A723B4401723',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24937/thumb/WDpucyvw.png?1649422885',
            'coingeckoId': 'atlantis-universe-money',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFTASCII',
            'name': 'NFTASCII',
            'address': '0xAb2392379c1c8876690aeBbae735eCC2b465825c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19975/thumb/nftasii.PNG?1636349606',
            'coingeckoId': 'nftascii',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AZ',
            'name': 'Azbit',
            'address': '0xAaaAfDC2E08371B956Be515B3f3Ff735AB9c9D74',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/4504/thumb/azbit200x200.png?1665646620',
            'coingeckoId': 'azbit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BATS',
            'name': 'Batcoin',
            'address': '0xAa731Eb48f701C132D5FA69B69f5eb4064d9BE34',
            'decimals': 0,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0xaa731eb48f701c132d5fa69b69f5eb4064d9be34/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'LECLIENTE',
            'name': 'LE CALIENTE',
            'address': '0xAa66a93346E09d140e203eadf22886A21123A1B9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18424/thumb/icon_lecaliente_200.png?1631860792',
            'coingeckoId': 'le-caliente',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOMO',
            'name': 'Momo Protocol',
            'address': '0xAFb2997fE9a99022E61C7e01B974E0e3D7704B02',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14846/thumb/200logo.png?1629463786',
            'coingeckoId': 'momo-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IWR',
            'name': 'Inu Wars',
            'address': '0xAF91B59629c40B17c7E88065d5228F5a7161520f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22602/thumb/ahdQANE.png?1642162290',
            'coingeckoId': 'inu-wars',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUXE',
            'name': 'LuxeRacing',
            'address': '0xAF8A43D8a80DEb18B29ffa370ed84cF0Bf387Ae6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23478/thumb/200200luxe.png?1644219034',
            'coingeckoId': 'luxeracing',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TWIN',
            'name': 'Twinci',
            'address': '0xAF83F292fcED83032f52ced45ef7DBDdb586441a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14897/thumb/Full.png?1618916844',
            'coingeckoId': 'twinci',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAFA',
            'name': 'MafaCoin',
            'address': '0xAF44400A99a9693bF3c2e89b02652bABACc5cdb9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xaf44400a99a9693bf3c2e89b02652babacc5cdb9.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SHIBIC',
            'name': 'SHIBIC',
            'address': '0xAF3889bA617aC973b358513d9031778D2Bc783Df',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22478/thumb/shibic.jpg?1656134013',
            'coingeckoId': 'shiba-inu-classic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'H2O',
            'name': 'H2O Dao',
            'address': '0xAF3287cAe99C982586c07401C0d911Bf7De6CD82',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24588/thumb/M5-QvqZ8_400x400.jpg?1648226430',
            'coingeckoId': 'h2o-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TORPEDO',
            'name': 'Torpedo',
            'address': '0xAF169E53e9f07DE6F9F4Eac67e2833C623f0c122',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14969/thumb/l4sLwkwR_400x400.jpg?1619168895',
            'coingeckoId': 'torpedo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PRT',
            'name': 'Portion',
            'address': '0xAF00aAc2431b04EF6afD904d19B08D5146e3A9A0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13617/thumb/OKeO2FI.png?1610327038',
            'coingeckoId': 'portion',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PULI',
            'name': 'Puli',
            'address': '0xAEf0a177c8c329CBC8508292Bb7E06c00786BBfC',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xaef0a177c8c329cbc8508292bb7e06c00786bbfc.png',
            'coingeckoId': 'puli-inu',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'DUKE',
            'name': 'Duke Inu',
            'address': '0xAEe234825dC4687faE606485c1eBD06336052bCc',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16121/thumb/3axfBIw.jpeg?1623036247',
            'coingeckoId': 'duke-inu-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PEA',
            'name': 'Pea Farm',
            'address': '0xAEb9864bfB718818904e9c70e4aB4e52b50b6481',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16644/thumb/logo_256x256.png?1624588374',
            'coingeckoId': 'pea-farm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HMDX',
            'name': 'Poly Peg Mdex',
            'address': '0xAEE4164c1ee46ed0bbC34790f1a3d1Fc87796668',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18504/thumb/Capture.PNG?1632211012',
            'coingeckoId': 'poly-peg-mdex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABYSHIBAINU',
            'name': 'Baby Shiba Inu',
            'address': '0xAECf6d1afF214feF70042740054f0f6D0Caa98Ab',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16805/thumb/BabyShiba.png?1625127854',
            'coingeckoId': 'baby-shiba-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JUICE',
            'name': 'CryptoDrop',
            'address': '0xAE5Fb0C28Fb5f8FC3902708879476a2cE3432015',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20043/thumb/G0xz40p.png?1636425717',
            'coingeckoId': 'cryptodrop',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SDC',
            'name': 'Somdej',
            'address': '0xAE4cA3272792d8499575AEFacdc299F0cBdc9270',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24078/thumb/jtJwjux.png?1646381564',
            'coingeckoId': 'somdej',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OURS',
            'name': 'Flying Colours',
            'address': '0xAE4A8E2A265D799639DC2440437D401b07478E2d',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24154/thumb/ours.png?1646565458',
            'coingeckoId': 'flying-colours',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KISHIMOTO',
            'name': 'Kishimoto',
            'address': '0xAE36155a55F04a696b8362777620027882B31dB5',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28011/thumb/kishimoto.png?1666944015',
            'coingeckoId': 'kishimoto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRFI',
            'name': 'CrossFi',
            'address': '0xAE20BC46300BAb5d85612C6BC6EA87eA0F186035',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15162/thumb/11901619752967_.pic_hd.png?1620006681',
            'coingeckoId': 'crossfi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COVIDDOGE',
            'name': 'Covid Doge',
            'address': '0xAE1f20CA661eC552C0664A73aD5e5963824F92e1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24217/thumb/oyFQ8DCV_400x400.jpg?1646917487',
            'coingeckoId': 'covid-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADAL',
            'name': 'Adalend',
            'address': '0xAE1107fC7CeF1294F09185aC475c9886527DcD8a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24554/thumb/19012.png?1648134755',
            'coingeckoId': 'adalend',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AUR',
            'name': 'AUREO',
            'address': '0xADcCD9B784B1398D95c2e952df4C78DC62C7dAD9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18427/thumb/W7l4Jxsh_400x400.jpg?1631882540',
            'coingeckoId': 'aureo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GMR',
            'name': 'GAMER',
            'address': '0xADCa52302e0a6c2d5D68EDCdB4Ac75DeB5466884',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21288/thumb/ezgif-1-7f6a016717.jpg?1645270886',
            'coingeckoId': 'gamer',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'TPAD',
            'name': 'TrustPad',
            'address': '0xADCFC6bf853a0a8ad7f9Ff4244140D10cf01363C',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15272/thumb/p3zMHrp1_400x400.jpg?1620336854',
            'coingeckoId': 'trustpad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XSPACE',
            'name': 'XSPACE',
            'address': '0xAD90c05BC51672eEdfeE36E58b3ff1A78bbC146d',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xad90c05bc51672eedfee36e58b3ff1a78bbc146d.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'PAID',
            'name': 'PAID Network',
            'address': '0xAD86d0E9764ba90DDD68747D64BFfBd79879a238',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13761/thumb/512.png?1648630881',
            'coingeckoId': 'paid-network',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BAND',
            'name': 'Band Protocol Token',
            'address': '0xAD6cAEb32CD2c308980a548bD0Bc5AA4306c6c18',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xba11d00c5f74255f56a5e366f4f77f5a186d7f55.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WOM',
            'name': 'Wombat Exchange',
            'address': '0xAD6742A35fB341A9Cc6ad674738Dd8da98b94Fb1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xad6742a35fb341a9cc6ad674738dd8da98b94fb1.png',
            'coingeckoId': 'wombat-exchange',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'FTM',
            'name': 'Fantom',
            'address': '0xAD29AbB318791D579433D831ed122aFeAf29dcfe',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/4001/small/Fantom.png?1558015016',
            'coingeckoId': 'fantom',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'MBLOX',
            'name': 'MinerBlox',
            'address': '0xAD0A3901eFf23b9143985bCB42F0171a112A2fD3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23639/thumb/coin.png?1644908157',
            'coingeckoId': 'minerblox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VST',
            'name': 'Voice Street',
            'address': '0xACf34Edcc424128CccC730Bf85CDaceEbCb3eEce',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23147/thumb/LbMUkaD9_400x400.jpg?1643348841',
            'coingeckoId': 'voice-street',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BP',
            'name': 'BunnyPark',
            'address': '0xACB8f52DC63BB752a51186D1c55868ADbFfEe9C1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0xACB8f52DC63BB752a51186D1c55868ADbFfEe9C1.png',
            'coingeckoId': 'bunnypark',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'NFTD',
            'name': 'NFTrade',
            'address': '0xAC83271abB4ec95386f08aD2b904a46C61777cef',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18578/thumb/nftrade.png?1632486088',
            'coingeckoId': 'nftrade',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BNBTIGER',
            'name': 'BNBTiger',
            'address': '0xAC68931B666E086E9de380CFDb0Fb5704a35dc2D',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22565/thumb/IMG-20220111-212721-633.jpg?1642058717',
            'coingeckoId': 'bnbtiger',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DHD',
            'name': 'Doom Hero Dao',
            'address': '0xAC55b04Af8E9067d6c53785B34113e99E10C2A11',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22234/thumb/dhd.PNG?1641249359',
            'coingeckoId': 'doom-hero-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALICE',
            'name': 'My Neighbor Alice',
            'address': '0xAC51066d7bEC65Dc4589368da368b212745d63E8',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xAC51066d7bEC65Dc4589368da368b212745d63E8/logo.png',
            'coingeckoId': 'my-neighbor-alice',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'TIKKY',
            'name': 'Tikky Inu',
            'address': '0xAC378638ace29fe9288FE2215Fa947DbF5ad59D2',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27945/thumb/tikkyinu_logo.png?1666605799',
            'coingeckoId': 'tikky-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MPT',
            'name': 'Mars Panda World',
            'address': '0xABf14398786295ee4638368d2004ECA9D780097E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18608/thumb/1CAXRy7m_400x400.jpg?1632667629',
            'coingeckoId': 'mars-panda-world',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IOTN',
            'name': 'IOTEN',
            'address': '0xABe6EfDEfa75C18bd0F6B65ABddCD8ddA3992CAF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22102/thumb/dmkAPfqy_400x400.jpg?1640824033',
            'coingeckoId': 'ioten',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RHO',
            'name': 'Rhinos Finance',
            'address': '0xABd29a5a984c97737707cC68D38c2903B8811061',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17695/thumb/rhinos.PNG?1628978740',
            'coingeckoId': 'rhinos-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARTI',
            'name': 'ArtKit',
            'address': '0xAB9ed2C3D76a1877d959C0d7E8DE48a33c55205C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24808/thumb/artkit.png?1648993555',
            'coingeckoId': 'artkit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CFI',
            'name': 'Cryptofi',
            'address': '0xAB5a0F7799D057F49f1Eeb8143B0182222e274AA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28148/thumb/cfi.jpeg?1667899284',
            'coingeckoId': 'cryptofi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SMON',
            'name': 'StarMon',
            'address': '0xAB15B79880f11cFfb58dB25eC2bc39d28c4d80d2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18130/thumb/StarMon.png?1630626408',
            'coingeckoId': 'starmon-token',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'GAIN',
            'name': 'GainPool',
            'address': '0xAAc073847e989C6D9d2f115A69E57a024415c684',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20075/thumb/gain.PNG?1636451679',
            'coingeckoId': 'gain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QANX',
            'name': 'QANplatform',
            'address': '0xAAA7A10a8ee237ea61E8AC46C50A8Db8bCC1baaa',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15977/thumb/qanx.png?1637574290',
            'coingeckoId': 'qanplatform',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'WTWOOL',
            'name': 'Wolf Town Wool',
            'address': '0xAA15535fd352F60B937B4e59D8a2D52110A419dD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24680/thumb/IMG-20220327-203818-706.jpg?1648560152',
            'coingeckoId': 'wolf-town-wool',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFENAMI',
            'name': 'SafeNami',
            'address': '0xA9dc177A61b58fdBD76933DaeBD5A77A10B560aC',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15479/thumb/safenami.png?1621001499',
            'coingeckoId': 'safenami',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WKFX',
            'name': 'Wrapped KnoxFS',
            'address': '0xA9b1E2542024AC5527aFF797469B57457Bb12886',
            'decimals': 9,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': 'wrapped-knoxfs',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AI',
            'name': 'Flourishing AI',
            'address': '0xA9b038285F43cD6fE9E16B4C80B4B9bCcd3C161b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17127/thumb/logo-circle.png?1632374596',
            'coingeckoId': 'flourishing-ai-token',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'SNN',
            'name': 'SeChain',
            'address': '0xA997E5AaaE60987Eb0B59A336dce6B158B113100',
            'decimals': 3,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/11961/thumb/2AsGMTeI_400x400.jpg?1596453536',
            'coingeckoId': 'sechain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '$MF',
            'name': 'MemeForce',
            'address': '0xA97B64AFBB6D6811696CaAfD323fFc9Ea983a7bB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'RUNE',
            'name': 'Rune',
            'address': '0xA9776B590bfc2f956711b3419910A5Ec1F63153E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15606/thumb/rune-200x200.png?1621315121',
            'coingeckoId': 'rune',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'CHICKS',
            'name': 'SolChicks',
            'address': '0xA91c7bc1e07996a188C1A5b1CfDFF450389D8ACf',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20978/thumb/chicks.png?1638162821',
            'coingeckoId': 'solchicks-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GEMIT',
            'name': 'GEMIT app',
            'address': '0xA91A840eE787Bb773922f961b6C5cD2A564F535d',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19874/thumb/gemit_logo_200.png?1636082786',
            'coingeckoId': 'gemit-app',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CTK',
            'name': 'CertiK Token',
            'address': '0xA8c2B8eec3d368C0253ad3dae65a5F2BBB89c929',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xa8c2b8eec3d368c0253ad3dae65a5f2bbb89c929.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NTG',
            'name': 'NEWTOWNGAMING',
            'address': '0xA896928209881bCf9fC363210473bB10EB0c0A10',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26778/thumb/IMG_20220809_093430_348.jpg?1660098829',
            'coingeckoId': 'newtowngaming',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BILL',
            'name': 'Bill',
            'address': '0xA873e87C2C935fa11c72003231a2EEe7d391CE5f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18480/thumb/photo_2021-08-16_23-27-37.jpg?1632151517',
            'coingeckoId': 'bill-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CT',
            'name': 'Create',
            'address': '0xA85c461C66038fFC8433e2a961339b7f36656E16',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27760/thumb/logo.967f1c75.png?1665658153',
            'coingeckoId': 'create',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UAPY',
            'name': 'UltimateAPY',
            'address': '0xA83dEb06FC89c08C614aEd1fEC0490da854423D5',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0xa83deb06fc89c08c614aed1fec0490da854423d5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'CTZN',
            'name': 'Totem',
            'address': '0xA803778AB953d3FfE4FBD20Cfa0042eCeFE8319D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21558/thumb/ctzn.png?1642930817',
            'coingeckoId': 'totem-earth-systems',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'EPS',
            'name': 'Ellipsis  OLD ',
            'address': '0xA7f552078dcC247C2684336020c03648500C6d9F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xa7f552078dcc247c2684336020c03648500c6d9f.png',
            'coingeckoId': 'ellipsis',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ETER',
            'name': 'Eterland',
            'address': '0xA7d2E49C1777d294FC6346EdC25CA0EcB3577F39',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21471/thumb/eter200px.png?1639347565',
            'coingeckoId': 'eterland',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TKN',
            'name': 'TokoNFT',
            'address': '0xA79A1B19f2367E80d1859273D16796E713cc87C2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21623/thumb/logo-coin-tkn.png?1639619949',
            'coingeckoId': 'tokonft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LBR',
            'name': 'Little Bunny Rocket',
            'address': '0xA78628eCba2BF5fedf3fE27A7CEdaa363b46708f',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19233/thumb/lrb.PNG?1634768519',
            'coingeckoId': 'little-bunny-rocket',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EDC',
            'name': 'EarnDefiCoin',
            'address': '0xA7840988974dd20A78b9F21D95366C46c7637996',
            'decimals': 9,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PETS',
            'name': 'MicroPets',
            'address': '0xA77346760341460B42C230ca6D21d4c8E743Fa9c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19175/thumb/pets.png?1644048894',
            'coingeckoId': 'micropets',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'TITS',
            'name': 'TitsV2',
            'address': '0xA753D241fFcE10A3570eDa72f6561C04b1f9Ae77',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19761/thumb/tits.png?1636115605',
            'coingeckoId': 'titsv2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UMG',
            'name': 'UnderMineGold',
            'address': '0xA73505453F58E367c80F16685079dAD6f4EA6b18',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18113/thumb/goldmine.PNG?1630543011',
            'coingeckoId': 'underminegold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FEB',
            'name': 'FEB Token',
            'address': '0xA72a0564d0e887123112e6A4DC1abA7611Ad861d',
            'decimals': 0,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xa72a0564d0e887123112e6a4dc1aba7611ad861d.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'IPAD',
            'name': 'Infinity PAD',
            'address': '0xA7266989B0DF675cC8257d53B6bc1358fAF6626a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27443/thumb/idiDT_BJ_400x400.jpeg?1663974533',
            'coingeckoId': 'infinity-pad-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FRM',
            'name': 'Ferrum Network',
            'address': '0xA719b8aB7EA7AF0DDb4358719a34631bb79d15Dc',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xa719b8ab7ea7af0ddb4358719a34631bb79d15dc.png',
            'coingeckoId': 'ferrum-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'HC',
            'name': 'HashLand Coin',
            'address': '0xA6e78aD3c9B4a79A01366D01ec4016EB3075d7A0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20610/thumb/hc200x200.png?1637291655',
            'coingeckoId': 'hashland-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DRACE',
            'name': 'DeathRoad',
            'address': '0xA6c897CaaCA3Db7fD6e2D2cE1a00744f40aB87Bb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18178/thumb/DeathRoad_token_logo_200x200-25.png?1630897737',
            'coingeckoId': 'deathroad',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'EVILSQUID',
            'name': 'EvilSquidGame',
            'address': '0xA6bd5f3eb53F76AEe9250088643989e610066F74',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20648/thumb/yiT-rsHE_400x400.jpg?1637527298',
            'coingeckoId': 'evilsquidgame',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MNY',
            'name': 'MoonieNFT',
            'address': '0xA6F7645ed967FAF708A614a2fcA8D4790138586f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18226/thumb/sq8oK6mX_400x400.png?1631026813',
            'coingeckoId': 'moonienft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHOD',
            'name': 'Shopdi',
            'address': '0xA6D662dDB759F2A3C4240bca2e1cc86D383F9E71',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27869/thumb/ShopdiLogoPNG-01_%281%29.png?1666157213',
            'coingeckoId': 'shopdi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SGS',
            'name': 'Melody SGS',
            'address': '0xA6CBa90f6246AAD9B4f3DCD29918821921A5c1fF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27986/thumb/home_sgs.png?1666863582',
            'coingeckoId': 'melody-sgs',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BANDEX',
            'name': 'Banana Index',
            'address': '0xA67b8e40111A0EDD30C3210b77aadb86AD234c43',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26700/thumb/6233e7f496c72994c313a50a_cactusboy90_%282%29-p-1080.png?1659616101',
            'coingeckoId': 'banana-index',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NBL',
            'name': 'Nobility',
            'address': '0xA67a13c9283Da5AABB199Da54a9Cb4cD8B9b16bA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0xa67a13c9283da5aabb199da54a9cb4cd8b9b16ba/522851834ee09f74e3ff4f243497493c.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CBY',
            'name': 'CBerry',
            'address': '0xA667fe00E537673582cb703eCaeC7722dfB9a2E3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15765/thumb/cberry-logo-200.png?1621827369',
            'coingeckoId': 'cberry',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFERMOON',
            'name': 'SAFERMOON',
            'address': '0xA656F993bD14B2B59a28d1e610476AD18849b107',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15698/thumb/SAFERMOON-cmk-transparent.png?1621572055',
            'coingeckoId': 'safermoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SANTOS',
            'name': 'Santos FC Fan Token',
            'address': '0xA64455a4553C9034236734FadDAddbb64aCE4Cc7',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0xA64455a4553C9034236734FadDAddbb64aCE4Cc7.png',
            'coingeckoId': 'santos-fc-fan-token',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'TOK',
            'name': 'Millionaire Land',
            'address': '0xA631f1462D4A1e9F12E5c4A07F0a9Fb1B98F90c5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26510/thumb/tok%281%29.png?1658448698',
            'coingeckoId': 'millionaire-land',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KDOGE',
            'name': 'KingDoge',
            'address': '0xA60Dad644BE617d4380Ef2a03f3075Ebbc9d0770',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16073/thumb/9Yia3_KZ_400x400.jpg?1622767876',
            'coingeckoId': 'kingdoge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOTEM',
            'name': 'gotEM',
            'address': '0xA5fbc3520dD4Bb85FCd175e1E3b994546A2C1eE8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19108/thumb/logo-transparent-bg.png?1634510489',
            'coingeckoId': 'gotem',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LKR',
            'name': 'Lokr',
            'address': '0xA5Ff48e326958E0CE6FdF9518de561F2B5f57dA3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14692/thumb/lokr.png?1648886932',
            'coingeckoId': 'lokr',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAIN',
            'name': 'Main',
            'address': '0xA5F249F401bA8931899a364d8E2699b5FA1D87a9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xa5f249f401ba8931899a364d8e2699b5fa1d87a9_1.png',
            'coingeckoId': 'main',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'WSG',
            'name': 'Wall Street Games',
            'address': '0xA58950F05FeA2277d2608748412bf9F802eA4901',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xA58950F05FeA2277d2608748412bf9F802eA4901/logo.png',
            'coingeckoId': 'wall-street-games',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PIT',
            'name': 'Pitbull',
            'address': '0xA57ac35CE91Ee92CaEfAA8dc04140C8e232c2E50',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xa57ac35ce91ee92caefaa8dc04140c8e232c2e50.png',
            'coingeckoId': 'pitbull',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BFK',
            'name': 'BFK WARZONE',
            'address': '0xA5438dF34698df262D5ed463F10387c998EdC24A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19387/thumb/faviconlogo.png?1635147740',
            'coingeckoId': 'bfk-warzone',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GDOGE',
            'name': 'Golden Doge',
            'address': '0xA53E61578fF54f1ad70186Be99332a6e20b6ffa9',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17190/thumb/Golden-Doge-Logo-200x200-White-Background.png?1626832299',
            'coingeckoId': 'golden-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HIBIKI',
            'name': 'Hibiki Finance',
            'address': '0xA532cfaA916c465A094DAF29fEa07a13e41E5B36',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17936/thumb/logo_%281%29.png?1629860624',
            'coingeckoId': 'hibiki-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TOP1',
            'name': 'TOP ONE',
            'address': '0xA50c5EE5638ccDCB3eAea3ceB726659A2a109239',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26945/thumb/top1.png?1660978294',
            'coingeckoId': 'top-one',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CARROT',
            'name': 'Carrot Stable Coin',
            'address': '0xA5043373edC09f3f7d87Fe35CA933e0a7B59D005',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19098/thumb/12111.png?1634423879',
            'coingeckoId': 'carrot-stable-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DLA',
            'name': 'Diolaunch',
            'address': '0xA4bf0BaeF9e8ea3B4a1cE694428d61546Fb4EBbb',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26771/thumb/200x200.png?1660047514',
            'coingeckoId': 'diolaunch',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COR',
            'name': 'Coreto',
            'address': '0xA4b6573c9AE09d81e4D1360e6402B81F52557098',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12856/large/COR.png?1620210966',
            'coingeckoId': 'coreto',
            'listedIn': [
                'coingecko',
                'dfyn',
                'rubic'
            ]
        },
        {
            'symbol': 'BABY',
            'name': 'Babylonia',
            'address': '0xA4E26Bd6DCBa9021DCd3A1159BA52e97CD770b8a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26352/thumb/Babylonia_Logo_02.01_FFD42A_200px.png?1657590351',
            'coingeckoId': 'babylonia',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KGT',
            'name': 'Kaby Gaming',
            'address': '0xA4E208c627f3aDc60165C2a9fE79D73F4e8DD760',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22937/thumb/xnSx6H.f960d9.KGTcoin.png?1643004882',
            'coingeckoId': 'kaby-gaming-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STE',
            'name': 'Stretch To Earn',
            'address': '0xA4CcaE9F2bD6195053D675e9a09B1d894695Ca0F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25766/thumb/StE_200x200.png?1653550330',
            'coingeckoId': 'stretch-to-earn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COLL',
            'name': 'Collateral Pay',
            'address': '0xA4CB040B85e94F5c0C32ea1151B20D3aB40B3493',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14682/thumb/cpay.jpg?1617767452',
            'coingeckoId': 'collateral-pay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APT',
            'name': 'Apidae',
            'address': '0xA49c8cBBdDFe4DBc8605f0f5c8f2845c5E225d22',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26276/thumb/ApidaeTokenLogo.png?1657016442',
            'coingeckoId': 'apidae',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIFI',
            'name': 'Milky Finance',
            'address': '0xA49abB903a84Bcc4663d2f81145254d5Ab2E5BcA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26543/thumb/2022-07-07_05.12.37.jpg?1658734840',
            'coingeckoId': 'milky-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METAFLIP',
            'name': 'MetaFlip',
            'address': '0xA49F7E4c67d62D11b0867079F5C63cF4a600b55c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21663/thumb/16157.png?1639702105',
            'coingeckoId': 'metaflip',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PTX',
            'name': 'PlatinX',
            'address': '0xA469d8E55AfCd58003D6CBdC770C0ecc2Dd71945',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23726/thumb/logo200x200.png?1645162319',
            'coingeckoId': 'platinx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WOOL',
            'name': 'Wool',
            'address': '0xA46346bC9d110907b5ACE36B53263320baf1cD21',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20940/thumb/15244.png?1638023728',
            'coingeckoId': 'wool-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DBEA',
            'name': 'Deep Blue Sea',
            'address': '0xA44B2D7805Dc7Fc24A7e8e38D8EFed5078cDf640',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25145/thumb/tokenicon200_200.png?1650435234',
            'coingeckoId': 'deep-blue-sea',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EB',
            'name': 'Endless Battlefield',
            'address': '0xA41eE9a01fd417c372B318746d8891c0C240A73c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23981/thumb/eb.png?1645878954',
            'coingeckoId': 'endless-battlefield',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALPHA',
            'name': 'Alpha Labz',
            'address': '0xA40bF32b1BCDeE1b62A1b75a6d84d0ec9D1Db88b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27034/thumb/200x200_%281%29.png?1661499419',
            'coingeckoId': 'alpha-labz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LEPA',
            'name': 'Lepasa',
            'address': '0xA4050Aa9b76CcDAe1A6a8b2F3e8627Cdc1546d86',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22745/thumb/lepasa.PNG?1642550975',
            'coingeckoId': 'lepasa',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'SAINT',
            'name': 'Saint Inu',
            'address': '0xA3cedAA3b1F73cE7c95C01CaaDbCc72f17FB62c5',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19724/thumb/saint_inu.png?1648269236',
            'coingeckoId': 'saint-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MINIBABYDOGE',
            'name': 'Mini Baby Doge',
            'address': '0xA3b89C5F75C530f33E612177001F5602E9C2830F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16970/thumb/minibabydoge.PNG?1625808292',
            'coingeckoId': 'mini-baby-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ROYALADA',
            'name': 'RoyalADA',
            'address': '0xA3aE1d90fd704b0604acb8Ca840b0272E32973Dd',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19674/thumb/13589.png?1635739320',
            'coingeckoId': 'royalada',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLUF',
            'name': 'Fluffy Coin',
            'address': '0xA3aBE68db1B8467B44715eb94542B20DC134f005',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20656/thumb/fluf.png?1638775190',
            'coingeckoId': 'fluffy-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABYADA',
            'name': 'Baby ADA',
            'address': '0xA3902E6F17021121390603be54c1719DCe19aeB5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17540/thumb/BabyADA.png?1628668354',
            'coingeckoId': 'baby-ada',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QBIT',
            'name': 'Project Quantum',
            'address': '0xA38898a4Ae982Cb0131104a6746f77fA0dA57aAA',
            'decimals': 2,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15773/thumb/quantum200.png?1621834749',
            'coingeckoId': 'project-quantum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WOLF',
            'name': 'Werewolves Game',
            'address': '0xA369EaB397BeF8e35fFc065c3eC9b800A548c059',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22626/thumb/wolf.png?1642329705',
            'coingeckoId': 'werewolves-game',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WIDI',
            'name': 'WidiLand',
            'address': '0xA361C79783833524dc7838399a4862B5f47038b8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21140/thumb/widiland.PNG?1638394109',
            'coingeckoId': 'widiland',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LACE',
            'name': 'Lovelace World',
            'address': '0xA3499dd7dBBBD93CB0f8303f8a8AcE8D02508E73',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1640061712088_6462317269772366.png',
            'coingeckoId': 'lovelace-world',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'VACAY',
            'name': 'Vacay',
            'address': '0xA3424FB48b9FA2FF8D3366eD91f36b4cDa7cAdd8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15930/thumb/Be9BrmM.png?1622442032',
            'coingeckoId': 'vacay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORAI',
            'name': 'Oraichain',
            'address': '0xA325Ad6D9c92B55A3Fc5aD7e412B1518F96441C0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12931/thumb/orai.png?1647077378',
            'coingeckoId': 'oraichain-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAFI',
            'name': 'Bafi Finance',
            'address': '0xA2f46fe221f34Dac4Cf078e6946A7cb4e373AD28',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13512/thumb/BAFI.png?1617161337',
            'coingeckoId': 'bafi-finance-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BINO',
            'name': 'Binopoly',
            'address': '0xA2dF7a11baF8bB555E93b99684A2DE0f105eB592',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22591/thumb/GaNWFUwI_400x400.jpg?1642144478',
            'coingeckoId': 'binopoly',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GEFI',
            'name': 'Genesis Finance',
            'address': '0xA2bbb77aD22096443c02dc8562BCc0a8a534C7F1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27592/thumb/IMG_20220930_231934_010_%281%29.jpg?1664707188',
            'coingeckoId': 'genesis-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TSC',
            'name': 'TrusterCoin',
            'address': '0xA2a26349448ddAfAe34949a6Cc2cEcF78c0497aC',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0xa2a26349448ddafae34949a6cc2cecf78c0497ac/f84a89cb45f9025779823fd6ab86b725.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'OPA',
            'name': 'Option Panda Platform',
            'address': '0xA2F89a3be1bAda5Eb9D58D23EDc2E2FE0F82F4b0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16435/thumb/logo.jpg?1624018811',
            'coingeckoId': 'option-panda-platform',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRK',
            'name': 'Torekko',
            'address': '0xA2E3e2817F00822F3c1E71A604eCa4196Af923d6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23357/thumb/trk.PNG?1643927454',
            'coingeckoId': 'torekko',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GUMMIE',
            'name': 'Gummy Beans',
            'address': '0xA2C34a855812f1D95032f4C7626eb488CDfb6D0e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18936/thumb/gummybeans.png?1633946261',
            'coingeckoId': 'gummy-beans',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABYCHEDDA',
            'name': 'Baby Chedda',
            'address': '0xA2AaD1e49627Ff37F78E11dB1611159bc4c85122',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24721/thumb/BabyChedda-Listing-coin-icon.png?1648697485',
            'coingeckoId': 'baby-chedda',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOO',
            'name': 'MooMonster',
            'address': '0xA29b6f4E762874846c081E20ed1142FF83faaFEF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21177/thumb/moo.png?1638495997',
            'coingeckoId': 'moomonster',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RIM',
            'name': 'MetaRim',
            'address': '0xA25199A79A34Cc04B15E5c0bbA4E3a557364E532',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23904/thumb/rim.png?1645680141',
            'coingeckoId': 'metarim',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PBNB',
            'name': 'Ripae pBNB',
            'address': '0xA2315cC5A1e4aE3D0a491ED4Fe45EBF8356fEaC7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25765/thumb/OadXb6ll_400x400.jpg?1653549689',
            'coingeckoId': 'ripae-pbnb',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABYBUSD',
            'name': 'BabyBUSD',
            'address': '0xA22DC6BabAb39C2D83F0d4b174ef7016424253a7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17370/thumb/babybusd.PNG?1627439151',
            'coingeckoId': 'babybusd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OSW',
            'name': 'OpenStream World',
            'address': '0xA21b0F29bC488DF22BBd76B9Fda8539bA9c11fdc',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24316/thumb/logo-cmc.png?1647353771',
            'coingeckoId': 'openstream-world',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATA',
            'name': 'Automata',
            'address': '0xA2120b9e674d3fC3875f415A7DF52e382F141225',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xA2120b9e674d3fC3875f415A7DF52e382F141225/logo.png',
            'coingeckoId': 'automata',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SAFETESLA',
            'name': 'SafeTesla',
            'address': '0xA1efCe38CB265Af369e891bC3026d0285545D4E5',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15514/thumb/Safe-tesla-logo-horizontal.png?1621115981',
            'coingeckoId': 'safetesla',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MUDRA',
            'name': 'Mudra',
            'address': '0xA1Fa5Df3a7C98cBD97D4c4f72a995150EE4a822b',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0xa1fa5df3a7c98cbd97d4c4f72a995150ee4a822b/logo.png',
            'coingeckoId': 'mudra',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BURNZ',
            'name': 'BURNZ',
            'address': '0xA1B4C67AB08DCeaB25e7B22B4a11897Ea042a1a8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26207/thumb/burnz.jpg?1656542025',
            'coingeckoId': 'burnz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SDOGE',
            'name': 'Sincere Doge',
            'address': '0xA1ADFa98D869258356459C491D08fC1EB245705b',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23742/thumb/sdoge.png?1645271888',
            'coingeckoId': 'sincere-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METAMOON',
            'name': 'MetaMoon',
            'address': '0xA1A0C7849e6916945a78F8AF843738c051ab15F3',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15744/thumb/ezgif-3-96b1d03006c5.png?1621778407',
            'coingeckoId': 'metamoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WIRTUAL',
            'name': 'Wirtual',
            'address': '0xA19D3F4219E2Ed6DC1cb595dB20F70b8b6866734',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24435/thumb/jxtu9s6F_400x400_%281%29.jpg?1647668413',
            'coingeckoId': 'wirtual',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PRIMATE',
            'name': 'Primate',
            'address': '0xA19863E302FD1B41276fCe5A48D9C511DBeEf34c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25245/thumb/benji-logo-512x512.png?1651028701',
            'coingeckoId': 'primate',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOGE',
            'name': 'Loge Of The Rings',
            'address': '0xA17a7555587D371e17a2E27C48077686E8A0998F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27137/thumb/rsz_1ehyhuel_400x400.png?1662028013',
            'coingeckoId': 'loge-of-the-rings',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FJB',
            'name': 'Freedom  Jobs  Business',
            'address': '0xA179248E50CE5AFb507FD8C54e08A66FBAC7B6Ff',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21300/thumb/FJB_150.png?1666514854',
            'coingeckoId': 'freedom-jobs-business',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PBX',
            'name': 'Probinex',
            'address': '0xA177BdD433AEa3702beb46652aDcFc64248d4aB3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23677/thumb/ezgif-4-225dbc8377.jpg?1651217748',
            'coingeckoId': 'probinex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DVRS',
            'name': 'DaoVerse',
            'address': '0xA155464B1566cdDdf9782205602651B8B871b3d5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26762/thumb/DV-200x200-logo.png?1660730975',
            'coingeckoId': 'daoverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DRIVECRYPT',
            'name': 'Drive Crypto',
            'address': '0xA151F5d8d9558C85a70Cd556e265a874073159fb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27430/thumb/IMG_20220922_132114_882.png?1663922031',
            'coingeckoId': 'drive-crypto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BNW',
            'name': 'NagaSwap',
            'address': '0xA15083664eb19899885CCc2B4Fd03977b26d3a2d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13828/thumb/1_dQKxZmGq8IWJAJHu6kDS5Q.png?1612157547',
            'coingeckoId': 'nagaswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QKC',
            'name': 'QuarkChain Token',
            'address': '0xA1434F1FC3F437fa33F7a781E041961C0205B5Da',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xA1434F1FC3F437fa33F7a781E041961C0205B5Da/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'POND',
            'name': 'Marlin POND',
            'address': '0xA1428370F540B4C4E319224165Ecae513a391f77',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'NAR',
            'name': 'NAR Token',
            'address': '0xA1303E6199b319a891b79685F0537D289af1FC83',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0xA1303E6199b319a891b79685F0537D289af1FC83/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ANIA',
            'name': 'Arkania Protocol',
            'address': '0xA10CF1Ad3eB9724BA342E8dd2A52d0c0DCFc1AD6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23108/thumb/Logo_A.png?1652338649',
            'coingeckoId': 'arkania-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XPT',
            'name': 'XPLUS Token',
            'address': '0xA0ecAF60D01e6b62Bd2b9aAdb29a069B9d5B9684',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27990/thumb/input-onlinejpgtools.jpg?1666866377',
            'coingeckoId': 'xplus-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AWG',
            'name': 'Alien Wars Gold',
            'address': '0xA0eA34a23b790f53E9f01bE8FFc5F44D60e169Fd',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24621/thumb/AWGold_symbol__%281%29.png?1648448402',
            'coingeckoId': 'alien-wars-gold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SSG',
            'name': 'Surviving Soldiers',
            'address': '0xA0c8c80ED6B7F09F885e826386440B2349F0Da7E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20967/thumb/FFNaTMMXIAk9d5y.jpg?1638152796',
            'coingeckoId': 'surviving-soldiers',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GSTRM',
            'name': 'GaStream',
            'address': '0xA0D2cA44A6880C4703B48B77EFd0F9D6166fc9ab',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24732/thumb/gstream-200x200.png?1650437799',
            'coingeckoId': 'gastream',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DPR',
            'name': 'Deeper Network',
            'address': '0xA0A2eE912CAF7921eaAbC866c6ef6FEc8f7E90A4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14748/thumb/deeper.png?1618094356',
            'coingeckoId': 'deeper-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'vBNB',
            'name': 'Venus BNB',
            'address': '0xA07c5b74C9B40447a954e1466938b865b6BBea36',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xa07c5b74c9b40447a954e1466938b865b6bbea36.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'VICEDAO',
            'name': 'ViceWRLD DAO',
            'address': '0xA062fe972fA299978303d2dde87093360D0EFbF3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18161/thumb/cg.png?1665041639',
            'coingeckoId': 'vicewrld',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZOOSHI',
            'name': 'Zooshi',
            'address': '0xA060E0c0588D26CabA4a0009513337aCE50752dd',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17609/thumb/another.png?1628658380',
            'coingeckoId': 'zooshi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFESUN',
            'name': 'SafeSun',
            'address': '0xA03C3a233940485D46eE77407343DA3221198427',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15749/thumb/symbol_logo.png?1621784752',
            'coingeckoId': 'safesun',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ROCKI',
            'name': 'Rocki',
            'address': '0xA01000C52b234a92563BA61e5649b7C76E1ba0f3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13465/thumb/rocki_logo.png?1608786767',
            'coingeckoId': 'rocki',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BHIVE',
            'name': 'bHIVE',
            'address': '0x9faF07D1FBC130D698e227E50D1Fb72657c0A342',
            'decimals': 3,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27596/thumb/hive.png?1664709654',
            'coingeckoId': 'bhive',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DHR',
            'name': 'DeHR Network',
            'address': '0x9fEf6766ecf9e105b2753f7f8968Dc8293A69460',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21710/thumb/q8jVRGmO_400x400.jpg?1639783728',
            'coingeckoId': 'dehr-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'THG',
            'name': 'Thetan Arena',
            'address': '0x9fD87aEfe02441B123c3c32466cD9dB4c578618f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x9fD87aEfe02441B123c3c32466cD9dB4c578618f.png',
            'coingeckoId': 'thetan-arena',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'KRYPTO',
            'name': 'Kryptobellion',
            'address': '0x9f9830631cd280d62772944a2AF06213E7A23771',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15145/thumb/krypto.PNG?1619816658',
            'coingeckoId': 'kryptobellion',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIBKILLER',
            'name': 'ShibKiller',
            'address': '0x9f5E3C8E95336CEA51481F8CB02027b3C5A523a9',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24618/thumb/shibkiller.png?1648378791',
            'coingeckoId': 'shibkiller',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTCPAY',
            'name': 'Bitcoin Pay',
            'address': '0x9f5C37e0fd9bF729b1F0a6F39CE57bE5e9Bfd435',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26994/thumb/photo_2022-08-22_16-38-30-removebg-preview.png?1661250811',
            'coingeckoId': 'bitcoin-pay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHF',
            'name': 'SpookyHalloweenFloki',
            'address': '0x9f59E79127fAD40bBC26c1eC6578d345AF40bD1f',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27733/thumb/shf.png?1665563502',
            'coingeckoId': 'spookyhalloweenfloki',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TKO',
            'name': 'Tokocrypto',
            'address': '0x9f589e3eabe42ebC94A44727b3f3531C0c877809',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x9f589e3eabe42ebc94a44727b3f3531c0c877809.png',
            'coingeckoId': 'tokocrypto',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'THECAT',
            'name': 'The Cat Inu',
            'address': '0x9f485C50a611199fde9C849c56BE7Df888a90725',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24624/thumb/200.png?1648449338',
            'coingeckoId': 'the-cat-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OVL',
            'name': 'Overlord Game',
            'address': '0x9f3c31f32182564D8bD0C8d7E413e2368bbDC1cc',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18379/thumb/1pcnVg3.png?1631693204',
            'coingeckoId': 'overload-game',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MWAR',
            'name': 'MemeWars',
            'address': '0x9f28455a82BAA6B4923A5e2d7624aAf574182585',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19919/thumb/LOGO-3.png?1636198073',
            'coingeckoId': 'memewars',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STRIP',
            'name': 'StripCoin',
            'address': '0x9f080368661261ee5d03bbd2b2f09d5489225e2C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ASTAR',
            'name': 'AceStarter',
            'address': '0x9eeDDb9DA3BCBfdcFBF075441A9e14c6a8899999',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24355/thumb/FB_IMG_1643688458160.jpg?1647434430',
            'coingeckoId': 'acestarter',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IRENA',
            'name': 'IRENA Green Energy',
            'address': '0x9eeB6c5ff183E6001c65a12D70026b900AE76781',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24507/thumb/Adsiz-tasarim.png?1647884174',
            'coingeckoId': 'irena-green-energy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BRE',
            'name': 'Boba Brewery',
            'address': '0x9eBBEB7f6b842377714EAdD987CaA4510205107A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24953/thumb/bre.png?1649507380',
            'coingeckoId': 'boba-brewery',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETB',
            'name': 'Ethera Black',
            'address': '0x9e6B8D2E2A7A8fA294AA715d59b58638c858A2A6',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24049/thumb/cmc.png?1646198428',
            'coingeckoId': 'ethera-black',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SMOON',
            'name': 'Stakemoon',
            'address': '0x9e69aacE82083A2ffAcce9947a587c36cDBCcB31',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21533/thumb/90jaz35b_400x400.jpg?1639400312',
            'coingeckoId': 'stakemoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SIP',
            'name': 'Space SIP',
            'address': '0x9e5965d28E8D44CAE8F9b809396E0931F9Df71CA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19637/thumb/Sip_Coin_200x200.png?1635723373',
            'coingeckoId': 'space-sip',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DGOLD',
            'name': 'Devious Licks Gold',
            'address': '0x9e545b66AfAd4836656601B0A6C6C4508B33e2c4',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23832/thumb/H0s7FaEC_400x400.jpg?1645516086',
            'coingeckoId': 'devious-licks-gold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QDX',
            'name': 'Quidax',
            'address': '0x9e3a9F1612028eeE48F85cA85f8Bed2f37d76848',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16032/thumb/3k5qGOw1_400x400.jpg?1622673737',
            'coingeckoId': 'quidax',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MRS',
            'name': 'MetaRacers',
            'address': '0x9e05e646a18bb4caB41aa7992959f019D0aac124',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21799/thumb/_8Ijk_oO_400x400.jpg?1640062408',
            'coingeckoId': 'metaracers',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MC',
            'name': 'Mechaverse',
            'address': '0x9df90628D40c72F85137e8cEE09dde353a651266',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27200/thumb/mc.jpeg?1662568257',
            'coingeckoId': 'mechaverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GENE',
            'name': 'Genopets',
            'address': '0x9df465460938f9EBDF51C38CC87D72184471F8F0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20360/thumb/gene-token.png?1636945172',
            'coingeckoId': 'genopets',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WARE',
            'name': 'Warrior Rare Essentials',
            'address': '0x9de8418f314e888946C4220671D3d94E448bcD33',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22635/thumb/Ware-Alt_coin1_200x200.png?1643193517',
            'coingeckoId': 'warrior-rare-essentials',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XCB',
            'name': 'Crypto Birds',
            'address': '0x9dCD6ab0511b2e72Af3d088538D678bae9bBF552',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21646/thumb/XCB_Token_Logo.png?1639638096',
            'coingeckoId': 'crypto-birds',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPACE',
            'name': 'Space Token BSC',
            'address': '0x9d9AFFAc2175Ef541642035aB66f5FE7Df813851',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20676/thumb/jYw3kgsY_400x400.png?1637550733',
            'coingeckoId': 'space-token-bsc',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ROO',
            'name': 'Lucky Roo',
            'address': '0x9d7107c8E30617CAdc11f9692A19C82ae8bbA938',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27943/thumb/lucky200.png?1666603396',
            'coingeckoId': 'lucky-roo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MEPAD',
            'name': 'MemePad',
            'address': '0x9d70a3EE3079A6FA2bB16591414678b7Ad91f0b5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15141/thumb/1619694441492-Memepad_coin.png?1619779985',
            'coingeckoId': 'memepad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATG',
            'name': 'AstroGrow',
            'address': '0x9d5758D86998D74F002C218C9967980a238BEb55',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26184/thumb/astro-transparentgecko-icon.png?1656383950',
            'coingeckoId': 'astrogrow',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLOBO',
            'name': 'FlokiBonk',
            'address': '0x9d3E14B607b2f569CfAFe29AF71e811d7E575CFe',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20118/thumb/communityIcon_wz9dy5ga39x71.png?1636522288',
            'coingeckoId': 'flokibonk',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'WEAR',
            'name': 'MetaWear',
            'address': '0x9d39eF3bbCA5927909dde44476656B81BBe4eE75',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24339/thumb/MW_icon.png?1647363556',
            'coingeckoId': 'metawear',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YFFII',
            'name': 'YFFII Finance',
            'address': '0x9d31f2f011De7b84c8bD8eB907eA80Ab13e3BEB1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12398/thumb/4hWupcq.jpg?1599553239',
            'coingeckoId': 'yffii-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COM',
            'name': 'ChinaOM',
            'address': '0x9d126a63709646BD68A32e7a4f3105DF4c0d7e8D',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23836/thumb/logo-2.png?1645516764',
            'coingeckoId': 'chinaom',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COL',
            'name': 'Clash of Lilliput',
            'address': '0x9ce116224459296abC7858627AbD5879514BC629',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26070/thumb/col.png?1655540086',
            'coingeckoId': 'clash-of-lilliput',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HAVEN',
            'name': 'Safehaven DeFi',
            'address': '0x9caE753B661142aE766374CEFA5dC800d80446aC',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17453/thumb/haven.png?1636339617',
            'coingeckoId': 'haven-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GLASS',
            'name': 'OurGlass',
            'address': '0x9c9d4302A1A550b446401e56000F76Bc761C3A33',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15542/thumb/FrYgRv2.png?1621168657',
            'coingeckoId': 'ourglass',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CWE',
            'name': 'Chain Wars',
            'address': '0x9c6b7221cDDA3b8136fbF9D27ac07AeeCC1087B5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21376/thumb/token200.png?1639033012',
            'coingeckoId': 'chain-wars-essence',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARKER',
            'name': 'Arker',
            'address': '0x9c67638c4Fa06FD47fB8900fC7F932f7EAB589De',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1640059457275_3000650642155118.png',
            'coingeckoId': 'arker-2',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SPKY',
            'name': 'SpookyShiba',
            'address': '0x9c2B1B3780A8B36B695f0b2781668664aC1Bf25A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26324/thumb/H2vW_x5A_400x400.jpeg?1657249034',
            'coingeckoId': 'spookyshiba-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPAY',
            'name': 'Shopayment',
            'address': '0x9c1ae9C1772a04A2E491CfE8c84125Df9Dc537E5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27315/thumb/IMG_20220912_234845_492.png?1663315165',
            'coingeckoId': 'shopayment',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUND',
            'name': 'Bundles',
            'address': '0x9c1A3e3A69F83bDF98A51E4a552BbC2e479d45e7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13219/thumb/Bundles_Bet_-_Master-_plain_green_jpeg-01.jpg?1651131621',
            'coingeckoId': 'bundles',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VICS',
            'name': 'RoboFi',
            'address': '0x9bcaB88763c33a95e73bc6DCf80fcf27a77090b2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17582/thumb/s6cPN83.jpeg?1628562295',
            'coingeckoId': 'robofi-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHOP',
            'name': 'Shoppi Coin',
            'address': '0x9bb2b75250FC34e16D9503c04781C718B2d467c8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22722/thumb/Coin-gold_200x200.png?1642486981',
            'coingeckoId': 'shoppi-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LYO',
            'name': 'LYO Credit',
            'address': '0x9bad6C75b5a4E72dF8147cc89d068cc848648e59',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25093/thumb/imgonline-com-ua-Resize-lFBg5rEIdAB.png?1650270325',
            'coingeckoId': 'lyocredit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YHC',
            'name': 'YoHero  YHC ',
            'address': '0x9bCf82cc8B42B075d6c62436284AA59e77B67C26',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20351/thumb/13766.png?1636943468',
            'coingeckoId': 'yohero-yhc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INDSHIB',
            'name': 'Indian Shiba Inu',
            'address': '0x9bABcD3A6F62d9ADc709E919d5fAa39aA85749fc',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24941/thumb/mvnnSVVU_400x400.jpg?1649424156',
            'coingeckoId': 'indian-shiba-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GS',
            'name': 'Genesis Shards',
            'address': '0x9bA4c78b048EEed69f4eD3CFddeda7B51BAF7cA8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14784/thumb/gs.png?1618408218',
            'coingeckoId': 'genesis-shards',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STRI',
            'name': 'Strite',
            'address': '0x9b93c29595dd603f75854EbA3C5f4EE078EE4454',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15056/thumb/strite.PNG?1619588982',
            'coingeckoId': 'strite',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TIKI',
            'name': 'Tiki',
            'address': '0x9b76D1B12Ff738c113200EB043350022EBf12Ff0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16427/thumb/60cbd8fd912298ca60e1fd7e_logo_TIKI_200_square.png?1623995319',
            'coingeckoId': 'tiki-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NOBF',
            'name': 'Nobo Finance',
            'address': '0x9b3d7Fe1f729560f2958d781943EcCb33b123Fb3',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22545/thumb/ba60ykk8_400x400.png?1642050032',
            'coingeckoId': 'nobo-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHINJIZ',
            'name': 'Shinji the Zombie Slayer',
            'address': '0x9b3cc90Af60961271fC1d78ed6549a517d28EC78',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24200/thumb/logo_%281%29.png?1646834908',
            'coingeckoId': 'shinji-the-zombie-slayer',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DINGO',
            'name': 'Dingocoin',
            'address': '0x9b208b117B2C4F76C1534B6f006b033220a681A4',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15989/thumb/dingocoin.png?1634699256',
            'coingeckoId': 'dingocoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SURE',
            'name': 'inSure DeFi',
            'address': '0x9b17bAADf0f21F03e35249e0e59723F34994F806',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/10354/thumb/logo-grey-circle.png?1614910406',
            'coingeckoId': 'insure',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TABOO',
            'name': 'Taboo',
            'address': '0x9abDbA20EdFbA06B782126b4D8d72A5853918FD0',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/6111/thumb/taboo.PNG?1624500776',
            'coingeckoId': 'taboo-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XMETA',
            'name': 'TTX Metaverse',
            'address': '0x9aaB0A9B3A2F7cF669f1385c48e0a063B93834bB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23332/thumb/xmeta.png?1644907223',
            'coingeckoId': 'ttx-metaverse',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'XBLZD',
            'name': 'Blizzard',
            'address': '0x9a946c3Cb16c08334b69aE249690C236Ebd5583E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15281/thumb/android-chrome-512x512_%281%29.png?1620351260',
            'coingeckoId': 'blizzard',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COLD',
            'name': 'Cold Finance',
            'address': '0x9a6fDa69FA9859B169EF17b600Ce81c59Cf16F01',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15607/thumb/cold-finance.png?1621315330',
            'coingeckoId': 'cold-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AFP',
            'name': 'PIGS',
            'address': '0x9a3321E1aCD3B9F6debEE5e042dD2411A1742002',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25910/thumb/Screenshot_from_2022-06-07_15-42-58.png?1654587865',
            'coingeckoId': 'pigs-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HYFI',
            'name': 'Hyper Finance',
            'address': '0x9a319b959e33369C5eaA494a770117eE3e585318',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x9a319b959e33369C5eaA494a770117eE3e585318/logo.png',
            'coingeckoId': 'hyper-finance',
            'listedIn': [
                'coingecko',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WRIA',
            'name': 'Wrapped aRIA Currency',
            'address': '0x9a27BF99A177FED8d5704aB14041394bD7732f4c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27066/thumb/wRIA-logo-200x200-1.png?1661673526',
            'coingeckoId': 'wrapped-aria',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DMS',
            'name': 'Dragon Mainland Shards',
            'address': '0x9a26e6D24Df036B0b015016D1b55011c19E76C87',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20702/thumb/DMS.png?1637630616',
            'coingeckoId': 'dragon-mainland-shards',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPELL',
            'name': 'Spell',
            'address': '0x9Fe28D11ce29E340B7124C493F59607cbAB9ce48',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/logos/main/network/binance/0x9Fe28D11ce29E340B7124C493F59607cbAB9ce48.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'DG',
            'name': 'Decentral Games  Old ',
            'address': '0x9Fdc3ae5c814b79dcA2556564047C5e7e5449C19',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x9Fdc3ae5c814b79dcA2556564047C5e7e5449C19/logo.png',
            'coingeckoId': 'decentral-games-old',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BLK',
            'name': 'Belka',
            'address': '0x9Fd530fC6e6E1f37d57f4A06DF72D456E408699E',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26084/thumb/ZFM3XOTa_400x400.jpg?1655725394',
            'coingeckoId': 'belka-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MULTI',
            'name': 'Multichain',
            'address': '0x9Fb9a33956351cf4fa040f65A13b835A3C8764E3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1669116429465_4753793996709088.png',
            'coingeckoId': 'multichain',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'AQUA',
            'name': 'Bela Aqua',
            'address': '0x9Fb677928dd244beFCD0BbEbdF6068dD4BEF559C',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15566/thumb/d5bnQ0H.png?1625578396',
            'coingeckoId': 'bela',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FUTURE',
            'name': 'FutureCoin',
            'address': '0x9FBff386a9405b4C98329824418ec02b5C20976b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23806/thumb/3ptM03y.png?1645509244',
            'coingeckoId': 'futurecoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWP',
            'name': 'S Wallet Protocol',
            'address': '0x9F8a75436e7E808F3Fb348182E0ea42d2dd467Cd',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22843/thumb/P6jfqnuo.png?1643194948',
            'coingeckoId': 's-wallet-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MX',
            'name': 'MX Token',
            'address': '0x9F882567A62a5560d147d64871776EeA72Df41D3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x9F882567A62a5560d147d64871776EeA72Df41D3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SFC',
            'name': 'Small Fish Cookie',
            'address': '0x9F7B0f336f7fc969b4713615D6c42AF86Bb7c903',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21190/thumb/15536.png?1638515883',
            'coingeckoId': 'small-fish-cookie',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ACT',
            'name': 'Acet',
            'address': '0x9F3BCBE48E8b754F331Dfc694A894e8E686aC31D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18205/thumb/ACET-LOGO.png?1630975189',
            'coingeckoId': 'acet-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DMC',
            'name': 'Domestic Collectors',
            'address': '0x9F109A85eC8f181F7a0471833050D63489c93A47',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27958/thumb/IMG_20221024_213249_118.jpg?1666678116',
            'coingeckoId': 'domestic-collectors',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADMONKEY',
            'name': 'AdMonkey',
            'address': '0x9Eeb03bbDeF40980E16e6f4332F486D991d11B84',
            'decimals': 12,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17075/thumb/cropped-AdMonkey-Logo-250-PNG.png?1626152296',
            'coingeckoId': 'admonkey',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STT',
            'name': 'Stamen Tellus Token',
            'address': '0x9Ee75952E3408ed7005225855aA1835D6d0023CA',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21155/thumb/normal_bean_stt-200x200.png?1638413168',
            'coingeckoId': 'stamen-tellus-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHENGWEIHU',
            'name': 'Shengweihu',
            'address': '0x9EaFdA01a41906048e0C81C81c16047D77Ab6C55',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23596/thumb/shengweihu.png?1644658197',
            'coingeckoId': 'shengweihu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ACA',
            'name': 'ACA',
            'address': '0x9EB6935AEa6aFb5Bc6d1A74bE0C2F78280ab6448',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19127/thumb/aca-icon256x256.png?1634522374',
            'coingeckoId': 'aca-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANTA',
            'name': 'Tribalpunk Cryptoverse',
            'address': '0x9EAf5369c9A9809Bad8716591f9b2F68124ccd63',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25841/thumb/ANTA-200-200.png?1654078118',
            'coingeckoId': 'tribalpunk-cryptoverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CODEX',
            'name': 'CODEX Finance',
            'address': '0x9E95cB3D0560f9Cba88991f828322526851BFb56',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14723/thumb/TWmfx9J.png?1626961475',
            'coingeckoId': 'codex-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORT',
            'name': 'Okratech',
            'address': '0x9E711221B34A2d4B8F552BD5f4A6C4e7934920f7',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18352/thumb/fav.png?1631604240',
            'coingeckoId': 'okratech-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOGO',
            'name': 'DogemonGo',
            'address': '0x9E6B3E35c8f563B45d864f9Ff697A144ad28A371',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17480/thumb/dogemongo.PNG?1627950869',
            'coingeckoId': 'dogemon-go',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOVELY',
            'name': 'Lovely Inu finance',
            'address': '0x9E24415d1e549EBc626a13a482Bb117a2B43e9CF',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19053/thumb/lovely-inu-logo-new.png?1634264028',
            'coingeckoId': 'lovely-inu-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CENS',
            'name': 'Cens World',
            'address': '0x9E1fd9BA2590AF57f926177850EAE1611D447874',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25075/thumb/GE43rZJ799_zVmGgjsMWTmWPLUnU71OUQEiiH7zHFvLAV4hBWQ06OL-uH2nYHPPrgktz_xt6Ix_WCxRBzJr-kx3vjqDsp8lcGV6LtSmJA8Dh0lWix_SU9eC1TM5dsDsEJ6gvknXg9LQxHjKgugxllEsIzjX6lAK8PABDxOyjBvy9sCXgnlDd__GNY2Bs__XfcEQ3mThaPSIaGTza9n6cRv3dIs.jpg?1650260249',
            'coingeckoId': 'cens-world',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BE',
            'name': 'Belon DAO',
            'address': '0x9E0CE7DE58049Dd17fd22DF3706185062A64cD18',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23502/thumb/logo200.png?1644294193',
            'coingeckoId': 'belon-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MBH',
            'name': 'Marble Heroes',
            'address': '0x9D9F777D0f9c1dC2851606611822ba002665E0BF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25237/thumb/MBH_LOGO.png?1650964874',
            'coingeckoId': 'marble-heroes',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LAND',
            'name': 'Landshare',
            'address': '0x9D986A3f147212327Dd658F712d5264a73a1fdB0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x9d986a3f147212327dd658f712d5264a73a1fdb0.png',
            'coingeckoId': 'landshare',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'H2E',
            'name': 'Hold2Earn',
            'address': '0x9D97C70FA2c9e7Ad1e4e5D843d852772D63a2CEa',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24127/thumb/h2e.png?1646467417',
            'coingeckoId': 'hold2earn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MLTPX',
            'name': 'Moonlift Capital',
            'address': '0x9D7c580e0bc4eA441Db96eebc7e1440d264bcE51',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16226/thumb/moonlift.jpeg?1640765727',
            'coingeckoId': 'moonlift',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LNR',
            'name': 'Lunar  OLD ',
            'address': '0x9D4451151A8dE5B545a1bC6c8fdEB9d94a2868e1',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20446/thumb/rsz_lunarprofileiconcircleblack_1.png?1646380831',
            'coingeckoId': 'lunar',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'CREDIT',
            'name': 'Credit',
            'address': '0x9D440a8Dc5aE7DD4f6B406B78340856838c3E5Da',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/8012/thumb/Untitled_design_%2816%29.png?1663998821',
            'coingeckoId': 'credit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALRIHLA',
            'name': 'Alrihla',
            'address': '0x9D3E24252876B5F69521f403a5D9065cCDf05466',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27303/thumb/alrihla_logo200.png?1663225734',
            'coingeckoId': 'alrihla',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZOON',
            'name': 'CryptoZoon',
            'address': '0x9D173E6c594f479B4d47001F8E6A95A7aDDa42bC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0x9d173e6c594f479b4d47001f8e6a95a7adda42bc.png',
            'coingeckoId': 'cryptozoon',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'FGSPORT',
            'name': 'FootBallGo',
            'address': '0x9D12CC56d133Fc5c60E9385B7A92F35a682da0bd',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19923/thumb/DKh2rhX3_400x400.jpg?1636222643',
            'coingeckoId': 'footballgo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHEX',
            'name': 'CHEX Token',
            'address': '0x9Ce84F6A69986a83d92C324df10bC8E64771030f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/10349/thumb/1_0zxuLe6QnvfsZPFzOoUteQ.png?1578434355',
            'coingeckoId': 'chex-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORBIT',
            'name': 'First On The Moon ORBIT',
            'address': '0x9Ccd7671fde42d814430CcaBcb5dda127526ec91',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20238/thumb/orbit.png?1636691466',
            'coingeckoId': 'orbit-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GHC',
            'name': 'Galaxy Heroes',
            'address': '0x9Cb9478C5Db5dAe52D5Af91737FF706F60195e45',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25200/thumb/ghc.png?1660985792',
            'coingeckoId': 'galaxy-heroes',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MARS4',
            'name': 'MARS4',
            'address': '0x9CD9C5a44CB8fab39b2Ee3556F5c439e65E4fDdD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18709/thumb/mars4_logo.jpg?1633070237',
            'coingeckoId': 'mars4',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LAEEB',
            'name': 'LaEeb',
            'address': '0x9CD7bc7D83F31224d8CD9d8dbc9Bd453Cd64A81e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26092/thumb/IMG-20220621-105111-290.jpg?1655801701',
            'coingeckoId': 'laeeb',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PET',
            'name': 'Peace',
            'address': '0x9CA00f0B5562914bcD84Ca6e0132CaE295cc84B7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21828/thumb/Peace_v2_-_5.png?1653891330',
            'coingeckoId': 'peace-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LAEEB',
            'name': 'LaEeb Token',
            'address': '0x9C8C624e9dfF5b840e834B3336B52A224D52578A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28223/thumb/ANhKvvCm_400x400.jpg?1668495376',
            'coingeckoId': 'laeeb-token-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PX',
            'name': 'Protocol X',
            'address': '0x9C71BB500dAe9782965D660EF33C1e7c4A3D1f8A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MDX',
            'name': 'Mdex',
            'address': '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x9C65AB58d8d978DB963e63f2bfB7121627e3a739/logo.png',
            'coingeckoId': 'mdex',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MKP',
            'name': 'MetaKeeper',
            'address': '0x9BfaD67b5477e2Daf1C15Ef4b43B34F2d007B968',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25421/thumb/MKPToken.png?1651736334',
            'coingeckoId': 'metakeeper',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FRB',
            'name': 'Freebie Life Finance',
            'address': '0x9BedCE29F79076b21DD04958a9Fd4B22F63fD86D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26262/thumb/Logo_%28200x200%29.png?1656942812',
            'coingeckoId': 'freebie-life-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FEAR',
            'name': 'Fear',
            'address': '0x9Ba6a67a6F3b21705a46B380A1B97373a33da311',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15825/thumb/fear-logo-400-400.png?1625552865',
            'coingeckoId': 'fear',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BVC',
            'name': 'BattleVerse',
            'address': '0x9BEe0c15676a65ef3c8cdb38cB3DD31c675BbD12',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23750/thumb/bvc.png?1645338259',
            'coingeckoId': 'battleverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LPK',
            'name': 'Kripton',
            'address': '0x9B71b5511998e0798625b8Fa74e86D8192DE78C1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0x9b71b5511998e0798625b8fa74e86d8192de78c1/5c96d1ce2205d313171db49ddf422ba0.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TKING',
            'name': 'Tiger King Coin',
            'address': '0x9B4BdDdaeb68D85B0848Bab7774E6855439FD94E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15605/thumb/tigerking.png?1649149229',
            'coingeckoId': 'tiger-king',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PROPEL',
            'name': 'Propel',
            'address': '0x9B44Df3318972bE845d83f961735609137C4C23c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/8794/thumb/-RNl00DU_400x400.jpg?1601543259',
            'coingeckoId': 'payrue',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ALTB',
            'name': 'Altbase',
            'address': '0x9B3a01F8b4aBD2E2a74597B21b7C269ABf4E9f41',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19550/thumb/615635338527975b0314223a_Altbase_app_icon.png?1635401149',
            'coingeckoId': 'altbase',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HERO',
            'name': 'HERO',
            'address': '0x9B26e16377ad29A6CCC01770bcfB56DE3A36d8b2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16245/thumb/HERO-200.png?1628255352',
            'coingeckoId': 'hero',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ITAMCUBE',
            'name': 'CUBE',
            'address': '0x9B08f10D8C250714F6485212300a7B72f973F1Fd',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22396/thumb/CoinGecko_Logo_%281%29.jpg?1648792005',
            'coingeckoId': 'cube',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GIFT',
            'name': 'Gift Coin',
            'address': '0x9Adf6E139025B29386115dcb7970020D69011937',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15541/thumb/5fdvA6T.png?1622514698',
            'coingeckoId': 'gift-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WWY',
            'name': 'WeWay',
            'address': '0x9Ab70e92319f0b9127df78868Fd3655fB9f1E322',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x9ab70e92319f0b9127df78868fd3655fb9f1e322.png',
            'coingeckoId': 'weway',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'COCO',
            'name': 'Coco Swap',
            'address': '0x9AA6FC71aed1130DeE06a91A487BF5eA481dE80D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18811/thumb/logoicon.png?1633492304',
            'coingeckoId': 'coco-swap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GENX',
            'name': 'GENX',
            'address': '0x9AA18A4E73E1016918fA360Eed950D9580C9551d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16747/thumb/logo-pic.png?1624873606',
            'coingeckoId': 'genx',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'DSG',
            'name': 'Dinosaur Eggs',
            'address': '0x9A78649501BbAAC285Ea4187299471B7ad4ABD35',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18893/thumb/dsg.PNG?1634973546',
            'coingeckoId': 'dinosaureggs',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'FLOSHIN',
            'name': 'Floshin',
            'address': '0x9A6Fed601a11290500F8D76153C33CC254E9F6D0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21239/thumb/floshin.png?1639965698',
            'coingeckoId': 'floshin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JMZ',
            'name': 'Jimizz',
            'address': '0x9A442C0F0c6618388F1fa0E2565D365aeF520e7a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27595/thumb/token-logo.png?1664709229',
            'coingeckoId': 'jimizz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EYE',
            'name': 'MEDIA EYE',
            'address': '0x9A257C90Fa239fBA07771ef7da2d554D148c2E89',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20643/thumb/eye.png?1656052464',
            'coingeckoId': 'media-eye',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'ZILLIONXO',
            'name': 'Zillion Aakar XO',
            'address': '0x9A2478C4036548864d96a97Fbf93f6a3341fedac',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26515/thumb/tg_image_4289710331.jpeg?1658450397',
            'coingeckoId': 'zillion-aakar-xo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WEBOO',
            'name': 'WebooSwap',
            'address': '0x9A093ddcaE05496a05aC76D96d49890b528C8CE0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22509/thumb/weboo_2.png?1641965227',
            'coingeckoId': 'webooswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUCK',
            'name': 'LuckDao',
            'address': '0x99f6DEcbE2346D2Ac4f84Dd0fd0E883732b46047',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27025/thumb/Luckdao200.png?1661485858',
            'coingeckoId': 'luckdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GMT_1',
            'name': 'Gambit',
            'address': '0x99e92123eB77Bc8f999316f622e5222498438784',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x99e92123eb77bc8f999316f622e5222498438784.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ROI',
            'name': '5ROI',
            'address': '0x99ce87a1F7f36F065B4E617937d03f2b68350123',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24631/thumb/Logo-ROI-Token.png?1648459172',
            'coingeckoId': 'roi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FMT',
            'name': 'Finminity',
            'address': '0x99c6e435eC259A7E8d65E1955C9423DB624bA54C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x99c6e435ec259a7e8d65e1955c9423db624ba54c.png',
            'coingeckoId': 'finminity',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'GS1',
            'name': 'NFTGamingStars',
            'address': '0x99F1699b33F80ea30b7d0138C749363Cce3D8e39',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23583/thumb/-up9F4vj_400x400.jpg?1644647349',
            'coingeckoId': 'nftgamingstars',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CCDOGE',
            'name': 'Community Doge Coin',
            'address': '0x99E3259d1D780746c62B4a90833d607b40Fb36Ce',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18084/thumb/logo.png?1630405290',
            'coingeckoId': 'community-doge-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BULL',
            'name': 'Bullshit Inu',
            'address': '0x99Cd7207d1cbe59033a01892C06889adAe47b0e5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25613/thumb/Bullshit-inu-200x200-logo.png?1652854884',
            'coingeckoId': 'bullshit-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EBIRD',
            'name': 'Early Bird',
            'address': '0x999AEb6D778b3883C88eBC502B16A5395d011462',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17465/thumb/earlybird.png?1627888719',
            'coingeckoId': 'early-bird',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DIA',
            'name': 'DIA',
            'address': '0x99956D38059cf7bEDA96Ec91Aa7BB2477E0901DD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/11955/thumb/image.png?1646041751',
            'coingeckoId': 'dia-data',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DFC',
            'name': 'DefiConnect',
            'address': '0x996c1bf72Ec220289ae0edd3a8d77080642121a2',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18116/thumb/dfc-logo-202x200.png?1630552776',
            'coingeckoId': 'deficonnect',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEX',
            'name': 'Newdex',
            'address': '0x996F56299A5b7c4f825A44886E07daFc4660B794',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/7622/thumb/newdex_token.jpg?1548839589',
            'coingeckoId': 'newdex-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GAR',
            'name': 'Kangaroo',
            'address': '0x996134c67929aD22758F7A6A6E26A44FF83D23ef',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22562/thumb/download.png?1642058307',
            'coingeckoId': 'kangaroo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APEGROW',
            'name': 'ApeGrow',
            'address': '0x9960454321A8d30355E7325B83Bd6BF99CbB20b1',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26783/thumb/ApeGrowOriginal.png?1663768859',
            'coingeckoId': 'apegrow',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TANUKI',
            'name': 'Tanuki',
            'address': '0x9958E4C4Def1C596e5d9acE46F1Bb9828768C32C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20451/thumb/tanuki.png?1637045587',
            'coingeckoId': 'tanuki-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METADOGEV2',
            'name': 'MetaDoge V2',
            'address': '0x9953170dcAac530AD7D6949c7295207c6ec5669D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20053/thumb/metadoge.png?1636435934',
            'coingeckoId': 'metadoge-v2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CUP',
            'name': 'BabyFootball',
            'address': '0x99507f96eA5235D72DB8db3565B5fF2508b61Ce7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28278/thumb/Baby.png?1669106749',
            'coingeckoId': 'babyfootball',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTDR',
            'name': 'Matador',
            'address': '0x994517e000aa3F117E7Ad61B0e2336C76B4Fd94a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x994517e000aa3f117e7ad61b0e2336c76b4fd94a.png',
            'coingeckoId': 'matador-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ROYA',
            'name': 'Royale',
            'address': '0x99415856B37bE9E75C0153615C7954f9DDb97A6E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13602/thumb/roya.png?1636031771',
            'coingeckoId': 'royale',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WKIT',
            'name': 'WORKIT',
            'address': '0x9934C20B285ecEa71B7776a97eCF49556C176673',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26301/thumb/WORKIT_CI_%EC%8B%AC%EB%B3%BC.png?1657179133',
            'coingeckoId': 'workit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APX',
            'name': 'Apollo Coin',
            'address': '0x992AcE1Acb518837aC615B380719BFc0c64eaa8f',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21783/thumb/logo_%282%29.png?1640007631',
            'coingeckoId': 'apollo-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OPENX',
            'name': 'OpenSwap One',
            'address': '0x9929b92f4C743D014c68dFE022D04C8C8FcfA37A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19274/thumb/X_color.png?1654833718',
            'coingeckoId': 'openswap-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PNDMLV',
            'name': 'Panda Multiverse',
            'address': '0x9917D59e5ecd4BDeEDa59F4aE16f9C43f4eDe4Bf',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20442/thumb/SNyGItq.png?1637040249',
            'coingeckoId': 'panda-multiverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MARSINU',
            'name': 'Mars Inu',
            'address': '0x9910f48EF7bd0291bC050CF900D732C24b39Ec31',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20222/thumb/marsinu.png?1636683037',
            'coingeckoId': 'mars-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TT',
            'name': 'ThunderCore',
            'address': '0x990E7154bB999FAa9b2fa5Ed29E822703311eA85',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x990E7154bB999FAa9b2fa5Ed29E822703311eA85.png',
            'coingeckoId': null,
            'listedIn': [
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'NEST',
            'name': 'Nest Protocol',
            'address': '0x98f8669F6481EbB341B522fCD3663f79A3d1A6A7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/11284/thumb/52954052.png?1589868539',
            'coingeckoId': 'nest',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KTZ',
            'name': 'KILLTHEZERO',
            'address': '0x98eE2C7310e87f0748A8b6DEB80d221Dba18E0b2',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22023/thumb/nJSKxSI5_400x400.jpg?1640651186',
            'coingeckoId': 'killthezero',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DTEVIL',
            'name': 'IdleTreasureParty',
            'address': '0x98e395D44681F9f5487aef1e29817CF94a635DAf',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27974/thumb/200x200-IDLETREASUREPARTY-DTEVIL-LOGO.png?1666775971',
            'coingeckoId': 'idletreasureparty',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BRS',
            'name': 'Broovs Projects',
            'address': '0x98C6fD0281A9A0300cB88553Bf386a3492bb70F7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25020/thumb/OnV-HqI1_400x400.jpg?1649831519',
            'coingeckoId': 'broovs-projects',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MVS',
            'name': 'MVS Multiverse',
            'address': '0x98Afac3b663113D29dc2Cd8C2d1d14793692F110',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22135/thumb/logo-200-white.png?1640913611',
            'coingeckoId': 'mvs-multiverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOMMYDOGE',
            'name': 'Mommy Doge',
            'address': '0x989b8F0219EB7aa0bED22E24f053Eb2B155f4394',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16956/thumb/icon200x200_%281%29.png?1625795339',
            'coingeckoId': 'mommy-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DFD',
            'name': 'DefiDollar DAO',
            'address': '0x9899a98b222fCb2f3dbee7dF45d943093a4ff9ff',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x9899a98b222fCb2f3dbee7dF45d943093a4ff9ff/logo.png',
            'coingeckoId': 'defidollar-dao',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ANN',
            'name': 'Annex Finance',
            'address': '0x98936Bde1CF1BFf1e7a8012Cee5e2583851f2067',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x98936bde1cf1bff1e7a8012cee5e2583851f2067.png',
            'coingeckoId': 'annex',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'PTX',
            'name': 'ProtocolX',
            'address': '0x988ce53ca8d210430d4a9af0DF4b7dD107A50Db6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27766/thumb/protocol.jpeg?1665713489',
            'coingeckoId': 'ptx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'wCCX',
            'name': 'WrappedConceal',
            'address': '0x988c11625472340b7B36FF1534893780E0d8d841',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/smartchain/assets/0x988c11625472340b7B36FF1534893780E0d8d841/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'LCR',
            'name': 'Lucro',
            'address': '0x988F7c894e4001EEB7B570CDE80dffE21CF7B6B9',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27335/thumb/LCR_logo_200x200-1.png?1663483321',
            'coingeckoId': 'lucro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GMINES',
            'name': 'Gnome Mines',
            'address': '0x9888D3D9fBc12487259D1C82665b2Ffd009936C6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25775/thumb/coingecko.png?1653631324',
            'coingeckoId': 'gnome-mines',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KUY',
            'name': 'KUY',
            'address': '0x987a6509A5927dd8046e4ba9485C7e0e24c832A6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26459/thumb/KUY_TOken.png?1658129638',
            'coingeckoId': 'kuy-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IND',
            'name': 'Infinity DAO',
            'address': '0x986d88736CC8c18069B75a3023EC3880f3b20562',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25219/thumb/3079663-E-2155-4-CB4-B284-970955483392.jpg?1650874362',
            'coingeckoId': 'infinity-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LDO',
            'name': 'Lido DAO  Wormhole ',
            'address': '0x986854779804799C1d68867F5E03e601E781e41b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22995/thumb/LDO_wh_small.png?1644226233',
            'coingeckoId': 'lido-dao-wormhole',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XM',
            'name': 'xMooney',
            'address': '0x98631c69602083D04f83934576A53e2a133D482F',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20576/thumb/0d6414_04bb2c3af25b4e51835d91bc89a8c8f6_mv2.png?1637246184',
            'coingeckoId': 'xmooney',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TENDIE',
            'name': 'TendieSwap',
            'address': '0x9853A30C69474BeD37595F9B149ad634b5c323d9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16017/thumb/7huZGes.png?1627130730',
            'coingeckoId': 'tendieswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HOP',
            'name': 'HOPPY',
            'address': '0x984811e6f2695192add6f88615dF637bf52a5Cae',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16844/thumb/TOKEN-ICON-T.png?1625834520',
            'coingeckoId': 'hoppy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QNI',
            'name': 'Qroni',
            'address': '0x98343e3EaF0Aa8AF6e93fAFf70C8FF70D98862F1',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27490/thumb/2022-09-27_00.37.38.jpg?1664255006',
            'coingeckoId': 'qroni',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CC',
            'name': 'CowCoin',
            'address': '0x982de39Fb553C7B4399940a811c6078A87D4efba',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23908/thumb/gUXxPVddaYi5XcvupcNxjmI1K-PHUMxRPVA0PYWEXPjC_3qyThJskTemz_Rq1HRzdWXYJM61RlW-UM91tgKXbPTjkB2v7DOmwxiG6LjMFdIW6H5us4fbZ2dmnbzk6iaw-SL5VO3thV6kkzenPogshIFOrAoVDxhra39E8SqueWpGt5UYbvrycAGiK8f9bNIctUZo0gvDmo8Ed6SCvk6uqLg6di.jpg?1651745997',
            'coingeckoId': 'cowcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFTA',
            'name': 'NFracTion',
            'address': '0x9824aec2Bd455D921303017F9600a589E6765c15',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18693/thumb/nfta.png?1632984393',
            'coingeckoId': 'nfraction',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LFT',
            'name': 'Lifetime',
            'address': '0x981847116cA6104961929f6ece98bcd629082c74',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23595/thumb/lft.png?1644655646',
            'coingeckoId': 'lifetime',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WISH',
            'name': 'Wish Finance',
            'address': '0x980e2937677c7Af859B0A9c741370C60c0A28b26',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17670/thumb/brave_q8sUJDRjqo.png?1629282323',
            'coingeckoId': 'wish-finance-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NEXT',
            'name': 'ShopNEXT',
            'address': '0x9809e877192B510D767a94ba39a79429219a5afB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21116/thumb/sRiZ3WMj_400x400.jpg?1638340194',
            'coingeckoId': 'shopnext',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'yPANDA',
            'name': 'YieldPanda.finance',
            'address': '0x9806aec346064183b5cE441313231DFf89811f7A',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x9806aec346064183b5ce441313231dff89811f7a.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BM',
            'name': 'BurningMoon',
            'address': '0x97c6825e6911578A515B11e25B552Ecd5fE58dbA',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18050/thumb/Burning-Moon-200x200.png?1630328781',
            'coingeckoId': 'burningmoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRX',
            'name': 'CryptEx',
            'address': '0x97a30C692eCe9C317235d48287d23d358170FC40',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x97a30c692ece9c317235d48287d23d358170fc40.png',
            'coingeckoId': 'cryptex',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'HAREC',
            'name': 'Hare Chain',
            'address': '0x97a07BBB92097732a1D011811E208e4b9fa486E8',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27379/thumb/Hare_Chain.png?1663741695',
            'coingeckoId': 'hare-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KISHUBABY',
            'name': 'Kishu Baby',
            'address': '0x97Ad911101d4285a13A3240Ee35618edC511A435',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16753/thumb/kishu_icon_coingecko.png?1624927105',
            'coingeckoId': 'kishu-baby',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '1BIT',
            'name': 'OneBit',
            'address': '0x97A266490eFA4Fb564aD625AcCabE5641de2f805',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20266/thumb/0949bddc836d95169980ae5fc826a441.jpg?1636704622',
            'coingeckoId': 'onebit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TBC',
            'name': 'TeraBlock',
            'address': '0x9798dF2f5d213a872c787bD03b2b91F54D0D04A1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15622/thumb/tbc.png?1621345335',
            'coingeckoId': 'terablock',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ULAND',
            'name': 'ULAND',
            'address': '0x9789dF6753B7F813A1C55ed20eCF83571DfDe428',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22040/thumb/logo_globe_200.png?1640669852',
            'coingeckoId': 'uland',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AGA',
            'name': 'AGA',
            'address': '0x976e33B07565b0c05B08b2e13AfFD3113e3D178d',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12180/thumb/aga-logo.png?1597937396',
            'coingeckoId': 'aga-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MMIT',
            'name': 'MANGOMAN INTELLIGENT',
            'address': '0x9767c8E438Aa18f550208e6d1fDf5f43541cC2c8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26687/thumb/Mr._B.png?1659601403',
            'coingeckoId': 'mangoman-intelligent',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'A4',
            'name': 'A4 Finance',
            'address': '0x9767203e89dcD34851240B3919d4900d3E5069f1',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21992/thumb/ba384ad07217a4be75cb85314f5760f7.jpg?1640582786',
            'coingeckoId': 'a4-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'H2O',
            'name': 'IFOSwap',
            'address': '0x97641c20355571820F591839d972AD2d38ad9F00',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18246/thumb/H2o.png?1631089687',
            'coingeckoId': 'ifoswap-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'aETH',
            'name': 'Ankr Eth2 Reward Bearing Bond',
            'address': '0x973616ff3b9d8F88411C5b4E6F928EE541e4d01f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0x973616ff3b9d8f88411c5b4e6f928ee541e4d01f.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': '1TRC',
            'name': '1TRONIC',
            'address': '0x97300A4f628701672096460B5469C41a8d3ECb1A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17118/thumb/1tronic-logo_cg.png?1629464465',
            'coingeckoId': '1tronic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YLD',
            'name': 'Yield YLD',
            'address': '0x9723c96b1B92cddC036588FD08A6b92607906B57',
            'decimals': 7,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26593/thumb/trans.png?1658972234',
            'coingeckoId': 'yield-yld',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VBETH',
            'name': 'Venus BETH',
            'address': '0x972207A639CC1B374B893cc33Fa251b55CEB7c07',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x972207a639cc1b374b893cc33fa251b55ceb7c07.png',
            'coingeckoId': 'venus-beth',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'GMPD',
            'name': 'GamesPad',
            'address': '0x9720CA160Bbd4E7f3dD4bb3f8BD4227ca0342e63',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1642739611263_23170937007576353.png',
            'coingeckoId': 'gamespad',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'OGUC',
            'name': 'Only Graded Coin',
            'address': '0x97200CC65C4082E9e0f8E966b1f296A7c4C46a61',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SHF',
            'name': 'Shibafriend NFT',
            'address': '0x96baD480691FeE450598F3A6c144F09FA5DF950D',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26271/thumb/SHF_Coin200.png?1656990325',
            'coingeckoId': 'shibafriend-nft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ECOP',
            'name': 'Eco DeFi',
            'address': '0x96a16178edAFF58736567Cfcaff570C06617F0Fd',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19535/thumb/eco_global.PNG?1635374624',
            'coingeckoId': 'eco-defi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UCON',
            'name': 'YouCoin Metaverse',
            'address': '0x96F75B57ba0cf514F932D24e076b6CB52E8Fe583',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27796/thumb/ucon.png?1665821981',
            'coingeckoId': 'youcoin-metaverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DGAT',
            'name': 'Doge Army',
            'address': '0x96E3BD1915483eD6E6569e908a0F6F49434557eD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19412/thumb/pfeuRgb.png?1635212477',
            'coingeckoId': 'doge-army-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COS',
            'name': 'Contentos',
            'address': '0x96Dd399F9c3AFda1F194182F71600F1B65946501',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x96dd399f9c3afda1f194182f71600f1b65946501.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CLION',
            'name': 'CryptoLion',
            'address': '0x96B159184FD2a9323b9c29a99a07eb01c3A95050',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18725/thumb/20210809_213547-3_%282%29.png?1633152951',
            'coingeckoId': 'cryptolion',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'wsSQUID',
            'name': 'Wrapped sSQUID',
            'address': '0x96A70751Fd5BF2B18091b99EF7689b9A3A90920E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ORC',
            'name': 'Orclands Metaverse',
            'address': '0x968f9C44879F67a29B1BfcCf93ea82d46A72881F',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22720/thumb/favicon-200x200.png?1642486261',
            'coingeckoId': 'orclands-metaverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NWC',
            'name': 'Newscrypto Coin',
            'address': '0x968F6f898a6Df937fC1859b323aC2F14643e3fED',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/9805/thumb/Tu1_NI3s_%281%29.png?1614745476',
            'coingeckoId': 'newscrypto-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIKA',
            'name': 'Shika Finance',
            'address': '0x9689eD8698eFbaF6f7B9b42218E47d2dEDC134Eb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27802/thumb/Logo-Shika320.png?1665902260',
            'coingeckoId': 'shika-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BCI',
            'name': 'Baby Cheems Inu',
            'address': '0x967DA0d87a60E5fE7331156480C3F62ce4016b28',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21693/thumb/BCI.png?1639721369',
            'coingeckoId': 'baby-cheems-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IOTX',
            'name': 'Binance Peg IoTeX',
            'address': '0x9678E42ceBEb63F23197D726B29b1CB20d0064E5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x9678e42cebeb63f23197d726b29b1cb20d0064e5.png',
            'coingeckoId': 'binance-peg-iotex',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BANK',
            'name': 'Bankers Dream',
            'address': '0x966f75a3A48BD6133220Bf83A62429bf04Adf29f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23017/thumb/C47722-F6-F58-D-4945-AEE0-04020-AB24731.png?1643093867',
            'coingeckoId': 'bankers-dream',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELIXIR',
            'name': 'Starchi',
            'address': '0x9663677B81c2D427E81C01ef7315eA96546F5Bb1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18941/thumb/starchi.jpg?1633961292',
            'coingeckoId': 'starchi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SFIL',
            'name': 'Filecoin Standard Full Hashrate',
            'address': '0x965b85D4674F64422c4898C8F8083187f02B32C0',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21669/thumb/_70BfuBY_400x400.jpg?1639706531',
            'coingeckoId': 'filecoin-standard-full-hashrate',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COOK',
            'name': 'Cook',
            'address': '0x965b0Df5BDA0E7a0649324D78f03D5F7F2De086a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x965b0df5bda0e7a0649324d78f03d5f7f2de086a.png',
            'coingeckoId': 'cook',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'BSW',
            'name': 'Biswap',
            'address': '0x965F527D9159dCe6288a2219DB51fc6Eef120dD1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x965f527d9159dce6288a2219db51fc6eef120dd1.png',
            'coingeckoId': 'biswap',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'MXY',
            'name': 'Metaxy',
            'address': '0x965D3704DE812F5e1E7eEf1ac22fE92174258bd9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22842/thumb/261052729_115071754344881_6053416489823202921_n.jpg?1642746147',
            'coingeckoId': 'metaxy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MCT',
            'name': 'Mcity',
            'address': '0x964A99bc2eDd0F09fc059332F0Fd9Da3012253A0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26426/thumb/Token_icon_200x200.png?1657929206',
            'coingeckoId': 'mcity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WHIRL',
            'name': 'OmniWhirl',
            'address': '0x963FaC6d71cDdBd717D8D0c2FD06AAe5dd6072Fc',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17165/thumb/logo-200x.png?1626683241',
            'coingeckoId': 'omniwhirl',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MARCO',
            'name': 'Melega',
            'address': '0x963556de0eb8138E97A85F0A86eE0acD159D210b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27568/thumb/MELEGA_logo_halo_200x200px.png?1664524259',
            'coingeckoId': 'melega',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IMON',
            'name': 'Intelligent Monsters',
            'address': '0x9631be8566fC71d91970b10AcfdEe29F21Da6C27',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26077/thumb/imonToken.png?1655690407',
            'coingeckoId': 'intelligent-monsters',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VIAGRA',
            'name': 'Viagra',
            'address': '0x9628542656482DdeE1989b04133f02A799eB0936',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15576/thumb/viagra.PNG?1621237388',
            'coingeckoId': 'viagra-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DYP',
            'name': 'DeFi Yield Protocol',
            'address': '0x961C8c0B1aaD0c0b10a51FeF6a867E3091BCef17',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13480/thumb/DYP_Logo_Symbol-8.png?1655809066',
            'coingeckoId': 'defi-yield-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LMT',
            'name': 'Lympo Market',
            'address': '0x9617857E191354dbEA0b714d78Bc59e57C411087',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x9617857E191354dbEA0b714d78Bc59e57C411087/logo.png',
            'coingeckoId': 'lympo-market-token',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CARBON',
            'name': 'Carbon Seed',
            'address': '0x96144D560d8144757240ADD0984c7d0494B2CF3F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26775/thumb/CarbonCoin_200x200.png?1660052071',
            'coingeckoId': 'carbon-seed',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COSMIC',
            'name': 'CosmicSwap',
            'address': '0x960cC8F437165b7362a34D95D1ec62Dd2A940f00',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16197/thumb/logo_-_2021-06-10T084120.309.png?1623285688',
            'coingeckoId': 'cosmicswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BONDLY',
            'name': 'Bondly Token',
            'address': '0x96058f8C3e16576D9BD68766f3836d9A33158f89',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0x96058f8c3e16576d9bd68766f3836d9a33158f89.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'YSL',
            'name': 'YSL',
            'address': '0x95e7c70B58790A1Cbd377bC403CD7E9bE7E0AfB1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17466/thumb/YSL.png?1630309670',
            'coingeckoId': 'ysl',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATHENASV2',
            'name': 'Athenas',
            'address': '0x95d07a6B1DD22cFc6f775E9574e4374995e7Ef89',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23883/thumb/banner-atn-2.png?1645601382',
            'coingeckoId': 'athenas',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VBUSD',
            'name': 'Venus BUSD',
            'address': '0x95c78222B3D6e262426483D42CfA53685A67Ab9D',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x95c78222b3d6e262426483d42cfa53685a67ab9d.png',
            'coingeckoId': 'venus-busd',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'RABBIT',
            'name': 'Rabbit Finance',
            'address': '0x95a1199EBA84ac5f19546519e287d43D2F0E1b41',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x95a1199EBA84ac5f19546519e287d43D2F0E1b41/logo.png',
            'coingeckoId': 'rabbit-finance',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SEED',
            'name': 'Brokoli Seed',
            'address': '0x95F86c8eb9bDEA0eA52659E6801ad8E7cA2b9283',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'HIV',
            'name': 'Hive Vault',
            'address': '0x95EfDC8EBe732FEe41803478dd8Ab170d99675b9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27641/thumb/t%C3%A9l%C3%A9charger_%281%29.png?1664964501',
            'coingeckoId': 'hive-vault',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DUET',
            'name': 'Duet Protocol',
            'address': '0x95EE03e1e2C5c4877f9A298F1C0D6c98698FAB7B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16164/small/Duet.jpg?1623143640',
            'coingeckoId': 'duet-protocol',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'WOF',
            'name': 'World of Farms',
            'address': '0x95D28C216D7a39FB8Cb62fD67B47eE932C531b5B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27653/thumb/IMG_20221006_235647_271-removebg-preview_%281%29.png?1665098352',
            'coingeckoId': 'world-of-farms',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JUNI',
            'name': 'Jackpot Universe',
            'address': '0x95Aa33319698CF67C7AB33bb23A65E9d38397187',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27372/thumb/640640_%281%29.jpg?1663732464',
            'coingeckoId': 'jackpot-universe',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PHUB',
            'name': 'PegHub com',
            'address': '0x95A6772a2272b9822D4b3DfeEaedF732F1D28DB8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26655/thumb/phub.png?1659412631',
            'coingeckoId': 'peghub-com',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SANTA',
            'name': 'Rich Santa',
            'address': '0x95A035a4E8738C6F7bf1C2a29996Bcd79Fcc074f',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28097/thumb/richsanta-SANTA.png?1667724616',
            'coingeckoId': 'rich-santa',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AMB',
            'name': 'Apple  Binemon ',
            'address': '0x95977A9DaA14A00258f89a14F75B6E35d5B730d4',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25205/thumb/Apple_AMB.jpeg?1650688855',
            'coingeckoId': 'apple-binemon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IOI',
            'name': 'IOI',
            'address': '0x959229D94c9060552daea25AC17193bcA65D7884',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15952/thumb/IOI_new_logo.png?1636797701',
            'coingeckoId': 'ioi-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HKSM',
            'name': 'H Space Metaverse',
            'address': '0x958c129C3543C964c3D9fcB9dc7CF82B727aA68a',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21723/thumb/4_d5Yqsd_400x400.jpg?1639947344',
            'coingeckoId': 'h-space-metaverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWU',
            'name': 'Smart World Union',
            'address': '0x958Cc5ac2eFa569CD9ad9B9B88245E1F038b02bE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27442/thumb/swu.png?1663937574',
            'coingeckoId': 'smart-world-union',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AOT',
            'name': 'Age Of Tanks',
            'address': '0x9589014F7a8547B89A6331eEEe32b7fBd5852af9',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21757/thumb/ageoftanks_logo_metal_%281%29.png?1642749913',
            'coingeckoId': 'age-of-tanks',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELVES',
            'name': 'Elves Century',
            'address': '0x9587cec6EDDa767D68bf5ed5A3114e414A9008B0',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22674/thumb/0vch2b2v_400x400.jpg?1642406101',
            'coingeckoId': 'elves-century',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GGIVE',
            'name': 'GlobalGive',
            'address': '0x9587315BDc7a58F66eb4e9e2F8B9C29abcA15617',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15180/thumb/200logo.png?1620028414',
            'coingeckoId': 'globalgive',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CEC',
            'name': 'Chemix Ecosystem Coin',
            'address': '0x957c49A76b3e008637cA1Cce23188A8cE884911e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://get.celer.app/cbridge-icons/CEC.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MONI',
            'name': 'Monsta Infinite',
            'address': '0x9573c88aE3e37508f87649f87c4dd5373C9F31e0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x9573c88aE3e37508f87649f87c4dd5373C9F31e0.png',
            'coingeckoId': 'monsta-infinite',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'BHT',
            'name': 'Bahtcoin',
            'address': '0x9557af470Eb1B4A5F2008f1926fB24C7566247B5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24986/thumb/gWu9bO3X_400x400.jpg?1649682108',
            'coingeckoId': 'bahtcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HOR',
            'name': 'Last Horde',
            'address': '0x95368f2c269F1f3394f21d72662BFB8b00F1A7fD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22147/thumb/logo200.png?1640930556',
            'coingeckoId': 'horde',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GCME',
            'name': 'GoCryptoMe',
            'address': '0x9528cCEb678B90dAf02cA5cA45622D5cBaF58A30',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23946/thumb/WDBxWle.png?1645771102',
            'coingeckoId': 'gocryptome',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'CREO',
            'name': 'Creo Engine',
            'address': '0x9521728bF66a867BC65A93Ece4a543D817871Eb7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25419/thumb/17000.png?1651736080',
            'coingeckoId': 'creo-engine',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UTED',
            'name': 'United',
            'address': '0x951Df2682ff9a963C4243a38d3841C9bA471b8aE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12820/thumb/LOGO_%2817%29.PNG?1602749761',
            'coingeckoId': 'united-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CUBIC',
            'name': 'Cubics',
            'address': '0x950Be31c6bA1962F2B2BCCDd47f7eCb4d1D099c2',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26302/thumb/logo_200x200_color.png?1657179596',
            'coingeckoId': 'cubics',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'C24',
            'name': 'Clock 24',
            'address': '0x950642c4c8f52Ca8a7D4aF62d5e040f33F07D700',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24664/thumb/cICWHcG.jpg?1648533445',
            'coingeckoId': 'clock-24',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOVE',
            'name': 'HunnyDAO',
            'address': '0x9505dbD77DaCD1F6C89F101b98522D4b871d88C5',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20748/thumb/hunnydaologo-200x200.png?1637634867',
            'coingeckoId': 'hunny-love-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JOCK',
            'name': 'Jockey Club',
            'address': '0x950591B0f2E13453aBDF627b83D30A2Eb09C7Eaa',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26281/thumb/C8PFYr5E_400x400.jpg?1657018101',
            'coingeckoId': 'jockey-club',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KALI',
            'name': 'Kalissa',
            'address': '0x950481789959cd6D77F1b88C2e1F61e30608c4E2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21261/thumb/logo-noir.png?1654593973',
            'coingeckoId': 'kalissa',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHIV',
            'name': 'Chiva',
            'address': '0x9500e05ea02CC9Cf3f904e83248b16E54E3E6E2E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19432/thumb/chivatoken-ico.png?1635217866',
            'coingeckoId': 'chiva-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DC',
            'name': 'DavidCoin',
            'address': '0x94dF6E5BC05b6EB9eb65c918902f6F4b8EdD8833',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24964/thumb/dc.png?1649583985',
            'coingeckoId': 'davidcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOA',
            'name': 'League of Ancients',
            'address': '0x94b69263FCA20119Ae817b6f783Fc0F13B02ad50',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x94b69263FCA20119Ae817b6f783Fc0F13B02ad50.png',
            'coingeckoId': 'league-of-ancients',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'SOUP',
            'name': 'Soup',
            'address': '0x94F559aE621F1c810F31a6a620Ad7376776fe09E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x94f559ae621f1c810f31a6a620ad7376776fe09e.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'IMO',
            'name': 'IMO',
            'address': '0x94D79c325268C898d2902050730f27A478C56cC1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14831/thumb/nPpe2Js.png?1618564641',
            'coingeckoId': 'imo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MC',
            'name': 'Merit Circle',
            'address': '0x949D48EcA67b17269629c7194F4b727d4Ef9E5d6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19304/small/Db4XqML.png?1634972154',
            'coingeckoId': 'merit-circle',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'HELMET',
            'name': 'Helmet Insure',
            'address': '0x948d2a81086A075b3130BAc19e4c6DEe1D2E3fE8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x948d2a81086a075b3130bac19e4c6dee1d2e3fe8.png',
            'coingeckoId': 'helmet-insure',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WRK',
            'name': 'BlockWRK',
            'address': '0x948b7b39e665A8adD9e128b0c378F99152172274',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17282/thumb/blkwr.png?1635470283',
            'coingeckoId': 'blockwrk',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SUSHI',
            'name': 'Sushi',
            'address': '0x947950BcC74888a40Ffa2593C5798F11Fc9124C4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x947950BcC74888a40Ffa2593C5798F11Fc9124C4/logo.png',
            'coingeckoId': 'sushi',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'pancake',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': '0XPAD',
            'name': '0xPAD',
            'address': '0x94733910a43D412DDaD505a8772839AA77aC1b6d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22537/thumb/16846.png?1642024756',
            'coingeckoId': '0xpad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PERRY',
            'name': 'Swaperry',
            'address': '0x9452D45d33490234B8C96f42342F1Be28c0FE097',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18005/thumb/Swaperry_token-2.png?1630145847',
            'coingeckoId': 'swaperry',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MHAM',
            'name': 'Metahamster',
            'address': '0x9428f4cD18896eDa03633429c3f52e5244504D14',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26632/thumb/metahamster200x200.png?1659326340',
            'coingeckoId': 'metahamster',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRAZYTIME',
            'name': 'CrazyTime',
            'address': '0x94241428E0E36DF628E0C2485bC0e158A5dA3362',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15914/thumb/rLw0TL1.png?1622422179',
            'coingeckoId': 'crazytime',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VSW',
            'name': 'Venera',
            'address': '0x94174f59c009f49b6aBC362706fdA402616b0427',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26252/thumb/Venera-Swap-Token-Logo-Black-200x200-coingecko.png?1656977707',
            'coingeckoId': 'venera',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIBARMY',
            'name': 'Shib Army',
            'address': '0x940230b6b7ef1979a28F32196A8e3439C645BA49',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19779/thumb/shiba_army.PNG?1635843834',
            'coingeckoId': 'shib-army',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DRF',
            'name': 'Drife',
            'address': '0x9400Aa8eb5126d20CDE45C7822836BFB70F19878',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17566/thumb/7BAWjuKB_400x400.jpg?1628394436',
            'coingeckoId': 'drife',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WNTR',
            'name': 'Weentar',
            'address': '0x93f63d9455685621aBd73E63cC04f7e454270A66',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15709/thumb/wntr.png?1644651147',
            'coingeckoId': 'weentar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CAI',
            'name': 'CAIETF Finance',
            'address': '0x93d04D21fcb5545330817c9c0e54A61C9e14D662',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20198/thumb/cai.PNG?1636615663',
            'coingeckoId': 'caietf-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PXP',
            'name': 'Pirate x Pirate',
            'address': '0x93c27727e72EC7510a06ea450366C1418c4Ce547',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23881/thumb/AJLTxV30.png?1645601132',
            'coingeckoId': 'pirate-x-pirate',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NCC',
            'name': 'NoCapCoin',
            'address': '0x93DfC1e09b7164Bafd4860963B6D94CbC4284774',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15491/thumb/nocapcoin.PNG?1621041472',
            'coingeckoId': 'nocapcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GMI',
            'name': 'GamiFi',
            'address': '0x93D8d25E3C9A847a5Da79F79ecaC89461FEcA846',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x93D8d25E3C9A847a5Da79F79ecaC89461FEcA846.png',
            'coingeckoId': 'gamifi',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'OXFI',
            'name': 'Oxfinance',
            'address': '0x93C169b9A0Bf3478c9912d45228093EA339c00a3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25435/thumb/oxfi-logo200.png?1651740052',
            'coingeckoId': 'oxfinance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PPAD',
            'name': 'PlayPad',
            'address': '0x93Bb13E90678cCd8BBab07D1dAEF15086746dc9B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20202/thumb/playpad.PNG?1636622174',
            'coingeckoId': 'playpad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FROGEX',
            'name': 'FrogeX',
            'address': '0x93AB30c08421750d5C7993Fb621c6FF32FE3F89E',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14775/thumb/-p8cz7Bk_400x400.png?1618371907',
            'coingeckoId': 'froge-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SN',
            'name': 'SpaceN',
            'address': '0x939Dd9E433552e325D78C33a16EF4cD8004D2F9C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27576/thumb/sn.png?1664694186',
            'coingeckoId': 'spacen',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BRNG',
            'name': 'bRing Finance',
            'address': '0x939D5A13cf0074586a2Dcf17bC692B2D3CCdD517',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17836/thumb/bring_finance.PNG?1629417184',
            'coingeckoId': 'bring-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BANKERS',
            'name': 'BullBankers',
            'address': '0x938ae80A71279086fEf47A4F9F0890cE729986F0',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26654/thumb/bull-logo-200x200.png?1659409804',
            'coingeckoId': 'bullbankers',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LMU',
            'name': 'LMU',
            'address': '0x9382a71A6Af4CF69d755Ac9783728784DDb16f66',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26025/thumb/logo_lmu.png?1655349190',
            'coingeckoId': 'lmu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SU',
            'name': 'skyup',
            'address': '0x937dCCa207128Af363470A711D0C2b1Cf76C49b1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27465/thumb/%EC%8A%A4%EC%B9%B4%EC%9D%B4%EC%97%85_%EB%A1%9C%EA%B3%A0.jpg?1664177973',
            'coingeckoId': 'skyup',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DGZV',
            'name': 'DogZVerse',
            'address': '0x9376E6B29b5422f38391A2a315623cB853c3c68e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21962/thumb/ns4SkGC.jpeg?1640522658',
            'coingeckoId': 'dogzverse-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CO',
            'name': 'CO',
            'address': '0x936B6659Ad0C1b244Ba8Efe639092acae30dc8d6',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x936B6659Ad0C1b244Ba8Efe639092acae30dc8d6.png',
            'coingeckoId': null,
            'listedIn': [
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'BLZ',
            'name': 'Bluzelle',
            'address': '0x935a544Bf5816E3A7C13DB2EFe3009Ffda0aCdA2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/2848/thumb/ColorIcon_3x.png?1622516510',
            'coingeckoId': 'bluzelle',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WAI',
            'name': 'Wanaka Farm WAIRERE',
            'address': '0x934C9198582Bf2631128c5D4b051aacEF9a6224F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19591/thumb/wai.png?1635473878',
            'coingeckoId': 'wanaka-farm-wairere-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MISA',
            'name': 'Sangkara',
            'address': '0x934B0633F4874ca9340341AD66ff2F6Ce3124b4C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25360/thumb/misa.png?1651314232',
            'coingeckoId': 'sangkara',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PMC',
            'name': 'Prime Chain',
            'address': '0x933Ae540ca1096E44e9e57a88d616F01D043E48b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27307/thumb/Thi%E1%BA%BFt_k%E1%BA%BF_ch%C6%B0a_c%C3%B3_t%C3%AAn__11_-removebg-preview_%284%29.png?1663239112',
            'coingeckoId': 'prime-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DREAM',
            'name': 'DreamDAO',
            'address': '0x930F3768f29030f9Bd7aD5D3E77B731C3411E95c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23642/thumb/logo_%281%29.png?1644909079',
            'coingeckoId': 'dreamdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANTZ',
            'name': 'GameAntz',
            'address': '0x930595344BbE2934b6AEa01841D2c1b0e45E29F7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27486/thumb/Pxy6Fbi9_400x400.jpeg?1664251785',
            'coingeckoId': 'gameantz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RDR',
            'name': 'Rise of Defenders',
            'address': '0x92dA433dA84d58DFe2aade1943349e491Cbd6820',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22294/thumb/nlXMSc9__400x400.jpg?1642050440',
            'coingeckoId': 'rise-of-defenders',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ERABBIT',
            'name': 'ELONs RABBIT',
            'address': '0x92a4EbEE814AFe58741d7f216dC10211d5aBC250',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19262/thumb/erabbit.png?1634816878',
            'coingeckoId': 'elons-rabbit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRDG',
            'name': 'TRDGtoken',
            'address': '0x92a42Db88Ed0F02c71D439e55962Ca7CAB0168b5',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x92a42db88ed0f02c71d439e55962ca7cab0168b5.png',
            'coingeckoId': 'tardigrades-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'KUBIC',
            'name': 'Kubic',
            'address': '0x92DD5B17bDacbbE3868a09bE5a3df93032C29ddb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16895/thumb/kubic.png?1636253785',
            'coingeckoId': 'kubic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOR',
            'name': 'BoringDAO  OLD ',
            'address': '0x92D7756c60dcfD4c689290E8A9F4d263b3b32241',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0x92d7756c60dcfd4c689290e8a9f4d263b3b32241.png',
            'coingeckoId': 'boringdao-[old]',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'PICO',
            'name': 'PicoGo',
            'address': '0x92CBa2826ed6Fdf2583b595a47888cE367100DF4',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23091/thumb/pico.png?1649573468',
            'coingeckoId': 'picogo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MRC',
            'name': 'Moon Rocket Coin',
            'address': '0x9299C132c34E691edea58102d276A61a0a622dBD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21547/thumb/mrc.png?1639459058',
            'coingeckoId': 'moon-rocket-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FRONT',
            'name': 'Frontier',
            'address': '0x928e55daB735aa8260AF3cEDadA18B5f70C72f1b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xf8c3527cc04340b208c854e985240c02f7b7793f.png',
            'coingeckoId': 'frontier-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BRIDGE',
            'name': 'Cross Chain Bridge',
            'address': '0x92868A5255C628dA08F550a858A802f5351C5223',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20223/thumb/0x92868A5255C628dA08F550a858A802f5351C5223.png?1636684446',
            'coingeckoId': 'cross-chain-bridge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BRN',
            'name': 'BRN Metaverse',
            'address': '0x926ecC7687fCFB296E97a2b4501F41A6f5F8C214',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25363/thumb/brn.png?1651316601',
            'coingeckoId': 'brn-metaverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HBARP',
            'name': 'HbarPad',
            'address': '0x926Cc5B32c1e88D7532DEFf023ecECDd66f37bfE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21034/thumb/Z27E5jfjHjy9.png?1638249982',
            'coingeckoId': 'hbarpad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHOW',
            'name': 'Chow Chow Finance',
            'address': '0x925f2C11b99c1A4c46606898ee91eD3D450cFedA',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14275/thumb/chow-logo.png?1620787242',
            'coingeckoId': 'chow-chow-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FRR',
            'name': 'Frontrow',
            'address': '0x924d221c4Fd599e5894706aC2E8f6a35a3cC981E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1642661902671_5529776821018315.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SSHIBA',
            'name': 'Super Shiba',
            'address': '0x922c77c7724d7B77fD7791BE5CC5314B70c3a781',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15869/thumb/SuperShibaLogoSmall.png?1622178165',
            'coingeckoId': 'super-shiba',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QRT',
            'name': 'Qrkita',
            'address': '0x921d3a6ed8223AFb6358410F717e2FB13cbae700',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19320/thumb/logo_-_2021-10-25T080744.116.png?1635120472',
            'coingeckoId': 'qrkita-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'P2PTXT',
            'name': 'p2p taxi',
            'address': '0x920376BE228eB5aa973fC2e050F7a07EB9A9Bce6',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27720/thumb/19121.png?1665462492',
            'coingeckoId': 'p2p-taxi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NUARS',
            'name': 'Num ARS',
            'address': '0x91bc956F064d755dB2e4EfE839eF0131e0b07E28',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23054/thumb/logo_nuars_alta.png?1664359371',
            'coingeckoId': 'num-ars',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORE',
            'name': 'Outrace',
            'address': '0x91F006ee672F8f39C6E63cA75B1cA14067b3c366',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20951/thumb/ppLk7GCl_400x400.jpg?1638137567',
            'coingeckoId': 'outrace',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MEG',
            'name': 'MetaMic E Sports Games',
            'address': '0x919Eb4899C7f573c02f14201B7f42B67f27d773E',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28242/thumb/meg.png?1668583282',
            'coingeckoId': 'metamic-e-sports-games',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MGLC',
            'name': 'MetaverseMGL',
            'address': '0x91984CaFB26d302fEb61865dE5bfE3e5c9Af9672',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23666/thumb/16378.png?1644993021',
            'coingeckoId': 'metaversemgl',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANTX',
            'name': 'AntNetworX',
            'address': '0x9186359F82c3c0Cc005A0b3563Dc4Ccd2627D82A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27628/thumb/CG.jpg?1664955394',
            'coingeckoId': 'antnetworx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LTE',
            'name': 'Listen To Earn',
            'address': '0x916c3169C0d31bEe7Cb15F14e9A94eB10603bc13',
            'decimals': 10,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26112/thumb/200x200.png?1655898014',
            'coingeckoId': 'listen-to-earn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SFZ',
            'name': 'Safemoon Zilla',
            'address': '0x91656750Bc364ff38AdB51157AcBb76F9f5eC2FE',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22483/thumb/BXZ1CNbO_400x400.jpg?1641889347',
            'coingeckoId': 'safemoon-zilla',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XLMN',
            'name': 'XL Moon',
            'address': '0x914737dfbfdb0DBADe3C9Ff10566Fa920d04ac19',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22321/thumb/XL-MOON-LOGO-200px.png?1641455064',
            'coingeckoId': 'xl-moon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'iLOTTO2',
            'name': 'iLOTTO2',
            'address': '0x9144Ab67d29a6B9819655A850036a7Db7DE4bbe8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://storage.googleapis.com/xy-finance-images/token-list/iLOTTO.png',
            'coingeckoId': null,
            'listedIn': [
                'xyfinance'
            ]
        },
        {
            'symbol': 'RTC',
            'name': 'Rijent Coin',
            'address': '0x913aFbBA462d6ae230344209d0Bd11CE3CE92Ed1',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19024/thumb/rijent.jpg?1634191446',
            'coingeckoId': 'rijent-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PINK',
            'name': 'Dot Finance',
            'address': '0x9133049Fb1FdDC110c92BF5b7Df635abB70C89DC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16446/thumb/dot_finance.PNG?1624331635',
            'coingeckoId': 'dot-finance',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'LAND',
            'name': 'Meta Utopia',
            'address': '0x9131066022B909C65eDD1aaf7fF213dACF4E86d0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x9131066022b909c65edd1aaf7ff213dacf4e86d0.png',
            'coingeckoId': 'meta-utopia',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SULE',
            'name': 'Sule',
            'address': '0x912e2421D6F388110fe3F3003852F416675cc5BF',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24226/thumb/Photo_1636191741876_%281%29.png?1647311197',
            'coingeckoId': 'psule',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CBU',
            'name': 'Banque Universal',
            'address': '0x912EF48F4da0C68D6c7C6D0b35d4e62E71771F33',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/8112/thumb/png_tr.png?1554952499',
            'coingeckoId': 'banque-universal',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DGT',
            'name': 'DGT Community Token',
            'address': '0x90fe761C696066672190CC62A84a2a41bC630A1B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27507/thumb/ZW_D8QyY_400x400.jpeg?1664333789',
            'coingeckoId': 'dgt-community-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FAT',
            'name': 'Fatfi Protocol',
            'address': '0x90e767A68a7d707B74D569C8E79f9bBb79b98a8b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x90e767a68a7d707b74d569c8e79f9bbb79b98a8b.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'DANGERMOON',
            'name': 'DangerMoon',
            'address': '0x90c7e271F8307E64d9A1bd86eF30961e5e1031e7',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18853/thumb/darkLogo200.png?1633592702',
            'coingeckoId': 'dangermoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIBCAKE',
            'name': 'SHIB CAKE',
            'address': '0x90bdF238674569684a34F3AF8a3F55f08088bc98',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17662/thumb/IMG_20210812_190731_793.png?1628867184',
            'coingeckoId': 'shib-cake',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TYV',
            'name': 'TYV',
            'address': '0x90a8BbF934Fde975555632aDdcb258E895C69de1',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24802/thumb/tyv.png?1648983946',
            'coingeckoId': 'tyv',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LMETA',
            'name': 'Lucky Metaverse',
            'address': '0x90a79E3d12F2D285fE189D5F0D5976c1D5Ab0c32',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26402/thumb/hw6wzY48_400x400.jpeg?1657871790',
            'coingeckoId': 'lucky-metaverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FWT',
            'name': 'Freeway',
            'address': '0x90a1E4BBADE88366dC44436535f1571d95e666c7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13012/thumb/S5h7MHR.png?1652862323',
            'coingeckoId': 'freeway',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SDAO',
            'name': 'SingularityDAO',
            'address': '0x90Ed8F1dc86388f14b64ba8fb4bbd23099f18240',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x90Ed8F1dc86388f14b64ba8fb4bbd23099f18240.png',
            'coingeckoId': 'singularitydao',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'PRX',
            'name': 'Parex',
            'address': '0x90E3414e00E231B962666Bd94ADB811D5bcD0c2a',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23650/thumb/cmc.png?1645602955',
            'coingeckoId': 'parex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EPIX',
            'name': 'Byepix',
            'address': '0x90E1f81b298f6c180cE6f71a6BDB4ACf41Be8E01',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28163/thumb/byepix.png?1668169693',
            'coingeckoId': 'byepix',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BULL',
            'name': 'BullRise',
            'address': '0x90Db4C8Ddc0055Ae03cDE0b4E83fBDe7dE711464',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18188/thumb/BULLRISE.jpg?1630906709',
            'coingeckoId': 'bullrise',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STM',
            'name': 'Streamity',
            'address': '0x90DF11a8ccE420675e73922419e3f4f3Fe13CCCb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://bscscan.com/token/images/streamity_32.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'FRAX',
            'name': 'Frax',
            'address': '0x90C97F71E18723b0Cf0dfa30ee176Ab653E89F40',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x90c97f71e18723b0cf0dfa30ee176ab653e89f40.png',
            'coingeckoId': 'frax',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'MLT',
            'name': 'Metaloop Tech',
            'address': '0x90BB4c7824182e6F37c97cAD683f46bC68894907',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22072/thumb/Untitled-design-1.png?1640751590',
            'coingeckoId': 'metaloop-tech',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YUGI',
            'name': 'Yugi',
            'address': '0x90A2902D9f02D68b56D4e5a4D280f97A555A6241',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21741/thumb/yugi.jpg?1639974581',
            'coingeckoId': 'yugi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LGX',
            'name': 'Legion Network',
            'address': '0x9096B4309224d751FCB43d7eB178dcFFc122aD15',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x9096b4309224d751fcb43d7eb178dcffc122ad15.png',
            'coingeckoId': 'legion-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'OWL',
            'name': 'OwlDAO',
            'address': '0x9085B4d52c3e0B8B6F9AF6213E85A433c7D76f19',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x9085b4d52c3e0b8b6f9af6213e85a433c7d76f19.png',
            'coingeckoId': 'owldao',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'RETIRE',
            'name': 'Retire',
            'address': '0x90800E102A243d17e8d8E0f0e52284751BcAC8fE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20337/thumb/RETIRE-TOKEN-LOGO-1.png?1636940891',
            'coingeckoId': 'retire-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REFLEX',
            'name': 'Reflex MITHRIL',
            'address': '0x907b283aB2d25DC8bd196a764cB9B5B69A3be0c4',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x907b283ab2d25dc8bd196a764cb9b5b69a3be0c4/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'LEP',
            'name': 'Leprechaun Finance',
            'address': '0x907Eb72bEB08c037aB032c64E2571488a210067e',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24307/thumb/l7Xnx2t__400x400.png?1647332792',
            'coingeckoId': 'leprechaun-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FQS',
            'name': 'FqSwap',
            'address': '0x9075055C3Ee590Ee0d7a2e1EF4376A851369E8E0',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23891/thumb/020.png?1645606539',
            'coingeckoId': 'fqswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOONION',
            'name': 'Moonions',
            'address': '0x9073B858a7CDF121E6bf8d1367e200e5d0cC0188',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27454/thumb/200.png?1664090487',
            'coingeckoId': 'moonions',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETA',
            'name': 'Ethera',
            'address': '0x90727C1195fB2246fd758172f9253873790d06a4',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19414/thumb/ethera.PNG?1635212696',
            'coingeckoId': 'ethera-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YFBT',
            'name': 'Yearn Finance Bit',
            'address': '0x9042Ec50B33C41C748264e4730E7cFBDF4787782',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12551/thumb/logo-200x200.png?1600681308',
            'coingeckoId': 'yearn-finance-bit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NINKY',
            'name': 'Idle Ninja Online',
            'address': '0x90422D35496e8ed3391971DBEC894E4a8057081f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22762/thumb/NINKY.png?1642574339',
            'coingeckoId': 'ninky',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WALKN',
            'name': 'WalkN',
            'address': '0x904160F6E18D90882e85c9AC0475ef3412241723',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26199/thumb/WALKN_LOGO_PNG.png?1656484628',
            'coingeckoId': 'walkn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KFSG',
            'name': 'Kindness For Soul',
            'address': '0x903C8be73875d6bAe9Aa81D381272302ef320121',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20461/thumb/kfs.PNG?1637062808',
            'coingeckoId': 'kindness-for-soul',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOGUS',
            'name': 'Dogus',
            'address': '0x903904Cb39bac33d4983EAd3b3F573D720c7965e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19196/thumb/rsZ3wmA.png?1634643309',
            'coingeckoId': 'dogus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OMT',
            'name': 'OxyMetaToken',
            'address': '0x9036b7Ff80D87E4D4894d7765ef065Fb98282a4C',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27562/thumb/200X200_OMT.png?1664519936',
            'coingeckoId': 'oxymetatoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OOE',
            'name': 'OpenOcean',
            'address': '0x9029FdFAe9A03135846381c7cE16595C3554e10A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1666922406718_40623932820124664.png',
            'coingeckoId': 'openocean',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'CYFM',
            'name': 'CyberFM',
            'address': '0x9001fD53504F7Bf253296cfFAdF5b6030CD61abb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/5476/thumb/cyberfm.png?1547041216',
            'coingeckoId': 'cyberfm',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'STEEL',
            'name': 'Steel',
            'address': '0x9001eE054F1692feF3A48330cB543b6FEc6287eb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14589/thumb/logo_-_2021-03-31T123426.828.png?1617165280',
            'coingeckoId': 'steel',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POR',
            'name': 'Portuma',
            'address': '0x9000Cac49C3841926Baac5b2E13c87D43e51B6a4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x9000cac49c3841926baac5b2e13c87d43e51b6a4.png',
            'coingeckoId': 'portuma',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SAT',
            'name': 'SatisFinance',
            'address': '0x8fda94079913CB921D065Ed9c004Afb43e1f900e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14839/thumb/SAT_200x200.png?1618613541',
            'coingeckoId': 'satisfinance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DLD',
            'name': 'Daoland',
            'address': '0x8fc68F19503eD28A4B72361AD8aDcF7e0eE01B4C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21411/thumb/256x256_black_01.png?1639091621',
            'coingeckoId': 'daoland',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BCH',
            'name': 'Binance Peg Bitcoin Cash',
            'address': '0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x8ff795a6f4d97e7887c79bea79aba5cc76444adf.png',
            'coingeckoId': 'binance-peg-bitcoin-cash',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ARCONA',
            'name': 'Arcona',
            'address': '0x8fC4532bE3003fb5A3A2f9afc7e95b3bfbD5fAAb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/4312/thumb/icon_ARCONA_%281%29.png?1651823900',
            'coingeckoId': 'arcona',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GALS',
            'name': 'Galaxy Surge',
            'address': '0x8f7e69bFA676097A046612658DAB8fc60fA90a5D',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22577/thumb/logo_%282%29.png?1642083426',
            'coingeckoId': 'galaxy-surge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHER',
            'name': 'Cherry Network',
            'address': '0x8f36Cc333F55B09Bb71091409A3d7ADE399e3b1C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21855/thumb/cherry.PNG?1640144127',
            'coingeckoId': 'cherry-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TMC',
            'name': 'Tom Coin',
            'address': '0x8f1fE4e6707CD4236b704759d2ee15166C68183a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25849/thumb/65e20074cf9e4bba897431f80da64ffd.jpeg?1654150541',
            'coingeckoId': 'tom-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NBTC',
            'name': 'Nano Bitcoin',
            'address': '0x8f1b3aF2D1E56f7C24e80d8626a4b22D269E1792',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17006/thumb/nbtc.png?1626065463',
            'coingeckoId': 'nano-bitcoin-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TOPG',
            'name': 'Tate Token',
            'address': '0x8f1D278C438242653Ee0bdd9DbD5A7f29CD046bF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26709/thumb/AlphaLabsLogo.png?1663810507',
            'coingeckoId': 'tate-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TFT',
            'name': 'ThreeFold',
            'address': '0x8f0FB159380176D324542b3a7933F0C2Fd0c2bbf',
            'decimals': 7,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x8f0fb159380176d324542b3a7933f0c2fd0c2bbf.png',
            'coingeckoId': 'threefold-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'TOMB',
            'name': 'TOMB',
            'address': '0x8f01d597d2022656494E30fb76eccf1eEA2E092e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BX',
            'name': 'ByteEx',
            'address': '0x8ec7E8C12947642A6BD232a73BBd7b2Ff310A588',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22668/thumb/0dc42cf2df4ec721142dc8b64b39c7cd.png?1642403952',
            'coingeckoId': 'byteex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DARC',
            'name': 'Konstellation',
            'address': '0x8ebC361536094fD5B4FFB8521E31900614C9F55D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/2943/thumb/darctoken.png?1645230834',
            'coingeckoId': 'darcmatter-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ID',
            'name': 'Everest',
            'address': '0x8ea2526C2373BA3fE1D0987F5DB8ac770a42DD51',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/5209/thumb/Everest.jpg?1628042930',
            'coingeckoId': 'everid',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FRP',
            'name': 'Fame Reward Plus',
            'address': '0x8eCA5C1B51A801A822912167153041ed0B92a397',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27675/thumb/fameRewardPlus-icon.png?1665211110',
            'coingeckoId': 'fame-reward-plus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SFU',
            'name': 'Safe Universe',
            'address': '0x8eC217B71905A46aFB18350c58dc7B7d90f73F28',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27367/thumb/aPS95YjU_400x400.jpeg?1663721097',
            'coingeckoId': 'safe-universe',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RRGP',
            'name': 'RRGP',
            'address': '0x8e541B8ed78081f61432c18aEd0c7Aa8a445B8e2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/9225.png',
            'coingeckoId': null,
            'listedIn': [
                'RouterProtocol'
            ]
        },
        {
            'symbol': 'SDLN',
            'name': 'Seedling',
            'address': '0x8e2e96a890DfaCa6957Ea899e1c37411A111aFD2',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22399/thumb/0AOtDEFi_400x400.png?1641793562',
            'coingeckoId': 'seedling',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORE',
            'name': 'ORE',
            'address': '0x8e2D8f40818FbaBA663Db6a24FB9B527Fc7100BE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19047/thumb/12752.png?1634253604',
            'coingeckoId': 'ore-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WRX',
            'name': 'WazirX',
            'address': '0x8e17ed70334C87eCE574C9d537BC153d8609e2a3',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/10547/thumb/WazirX.png?1580834330',
            'coingeckoId': 'wazirx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APPLE',
            'name': 'Apple',
            'address': '0x8e03e380E6ac69B76DE7590d67296a7Eb1fFcd59',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23748/thumb/apple.png?1645275014',
            'coingeckoId': 'apple',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VTG',
            'name': 'Victory Gem',
            'address': '0x8de5aA37a7C40A53062EAD382b8EEAD3B08a7A46',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25270/thumb/vtg.png?1651117782',
            'coingeckoId': 'victory-gem',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAS',
            'name': 'Block Ape Scissors',
            'address': '0x8ddEEc6b677c7c552C9f3563B99e4fF90B862EBc',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17404/thumb/Logo_White_Face_%28200%29.png?1659093852',
            'coingeckoId': 'block-ape-scissors',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRUDE',
            'name': 'Crude',
            'address': '0x8db702D9d561921C45Be8DF38830A653e4BC0299',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17512/thumb/200x200crude.png?1628062161',
            'coingeckoId': 'crude-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HOFR',
            'name': 'House of Frenchies',
            'address': '0x8dF0F585c1d4a76B473dfb85aB55e693f7B50020',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27671/thumb/logooption2.png?1665158399',
            'coingeckoId': 'house-of-frenchies',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETHPAD',
            'name': 'ETHPad',
            'address': '0x8dB1D28Ee0d822367aF8d220C0dc7cB6fe9DC442',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17520/thumb/tHAbIBQK_400x400.jpg?1628075612',
            'coingeckoId': 'ethpad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OBT',
            'name': 'OB',
            'address': '0x8dA6113655309f84127E0837fcf5C389892578B3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19241/thumb/Group_%281%29.png?1667397411',
            'coingeckoId': 'obtoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CTSI',
            'name': 'Cartesi',
            'address': '0x8dA443F84fEA710266C8eB6bC34B71702d033EF2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x8dA443F84fEA710266C8eB6bC34B71702d033EF2/logo.png',
            'coingeckoId': 'cartesi',
            'listedIn': [
                'coingecko',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SCAR',
            'name': 'Velhalla',
            'address': '0x8d9fB713587174Ee97e91866050c383b5cEE6209',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20421/thumb/velhala-logo_token.png?1664779145',
            'coingeckoId': 'velhalla',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '1MT',
            'name': '1Million',
            'address': '0x8d67448d4f6231ABc070a42A8905084b79E09136',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/8495/thumb/1MTp.png?1586964391',
            'coingeckoId': '1million-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'M7V2',
            'name': 'M7V2',
            'address': '0x8d55eCa212a56e9D3A0C337950F910e3397E1E68',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25952/thumb/256x256-logo_%281%29.png?1654843557',
            'coingeckoId': 'm7v2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDIBS',
            'name': 'Dibs USD',
            'address': '0x8d2aa7b23aBE16E7eCa845F64C65A314d4ABa35A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26162/thumb/usdibs.9da218f9.png?1656323533',
            'coingeckoId': 'dibs-usd',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BLOSM',
            'name': 'BlossomCoin',
            'address': '0x8d03e069840D6Fb103abC4F640C8cc07F7F4bc10',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15513/thumb/coingecko_%282%29.png?1621115616',
            'coingeckoId': 'blossomcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XPNET',
            'name': 'XP Network',
            'address': '0x8cf8238abf7b933Bf8BB5Ea2C7E4Be101c11de2A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18363/thumb/200.png?1646363211',
            'coingeckoId': 'xp-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NMS',
            'name': 'Nemesis Wealth Projects BSC',
            'address': '0x8cb4FDB148d87f7Ec493e69391347bDd3Ff1163f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21486/thumb/nms_bsc.png?1639362861',
            'coingeckoId': 'nemesis-wealth-projects-bsc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNI',
            'name': 'UNI',
            'address': '0x8cBa70b78EF6760141Bf887AF9456b32e2C181D4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CRANE',
            'name': 'Crane Miners',
            'address': '0x8c85a0e86f37ca231463Ead62a40350D7Bf7cEf0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26195/thumb/20785.png?1656405141',
            'coingeckoId': 'crane-miners',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUCKY',
            'name': 'Bucky Badger',
            'address': '0x8c540DeF3b0c89A6BF1662b1789f6251c34605ec',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27857/thumb/IMG_20221017_162511_345.png?1666096129',
            'coingeckoId': 'bucky-badger',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HBG',
            'name': 'HeroBook',
            'address': '0x8c2Da84EA88151109478846cc7c6c06C481dbe97',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22928/thumb/HBGiconlogo200.png?1643003300',
            'coingeckoId': 'herobook',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOM',
            'name': 'Ancient Kingdom',
            'address': '0x8c282eA9eACd1B95D44a3A18DCdd1D0472868998',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22631/thumb/ancient_kingdom.PNG?1642343232',
            'coingeckoId': 'ancient-kingdom',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NERVE',
            'name': 'NerveFlux',
            'address': '0x8c21CEf3c0f25E7FA267E33602702e3f91775360',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22652/thumb/email_logo.png?1655358142',
            'coingeckoId': 'nerveflux',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HER',
            'name': 'Herity Network',
            'address': '0x8c18ffD66d943C9B0AD3DC40E2D64638F1e6e1ab',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22724/thumb/Herity-logo.png?1642487662',
            'coingeckoId': 'herity-network',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'FETCH',
            'name': 'MoonRetriever',
            'address': '0x8bfc1c564E4490790DFac641C9a0FecD3f91F584',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15747/thumb/Mainlogo-200x200-Moonretriever.png?1621781693',
            'coingeckoId': 'moonretriever',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'P4C',
            'name': 'Play4Cash',
            'address': '0x8bf0E5e6B9363C0bd7E3652FC1d8d319b5129B8c',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27236/thumb/logo_200.png?1662713297',
            'coingeckoId': 'play4cash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BBYXRP',
            'name': 'BabyXrp',
            'address': '0x8beAbaa4f025D00B4699d56a683758d692d17F20',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16862/thumb/Final.png?1625469041',
            'coingeckoId': 'babyxrp',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPH',
            'name': 'Sphynx Network',
            'address': '0x8bAc6b4AF65C8c1967a0FBc27cd37FD6059daa00',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x8bac6b4af65c8c1967a0fbc27cd37fd6059daa00.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ZERIV2',
            'name': 'CryptoZerofi V2',
            'address': '0x8b78bA509b1De3664Ee31DAc78DCbe2A8E17922d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24406/thumb/mYGuqXz.png?1647530630',
            'coingeckoId': 'cryptozerofi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XHP',
            'name': 'XHYPE',
            'address': '0x8b3f1F44BF4D1bBAd3965d2745917AFe3fAC7343',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27740/thumb/emago_coin.png?1665833404',
            'coingeckoId': 'xhype',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WRAITH',
            'name': 'Wraith',
            'address': '0x8b3b45E48bE6C31366ffd9dD4F29C1edFFcbA97D',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18099/thumb/LOGOTRANS-1.png?1630475109',
            'coingeckoId': 'wraith',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WOOP',
            'name': 'Woonkly Power',
            'address': '0x8b303d5BbfBbf46F1a4d9741E491e06986894e18',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x8b303d5bbfbbf46f1a4d9741e491e06986894e18.png',
            'coingeckoId': 'woonkly-power',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'AXL',
            'name': 'Axelar',
            'address': '0x8b1f4432F943c465A973FeDC6d7aa50Fc96f1f65',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27277/thumb/V-65_xQ1_400x400.jpeg?1663121730',
            'coingeckoId': 'axelar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WLBO',
            'name': 'Wenlambo',
            'address': '0x8b14A1d887D2F91Fe8c0C7dD31a454B64df4211c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26134/thumb/wenl.png?1655972205',
            'coingeckoId': 'wenlambo-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SMBR',
            'name': 'Sombra',
            'address': '0x8ad8e9B85787ddd0D31b32ECF655E93bfc0747eF',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17884/thumb/sombra-200.png?1629710204',
            'coingeckoId': 'sombra-network',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MTV',
            'name': 'MultiVAC',
            'address': '0x8aa688AB789d1848d131C65D98CEAA8875D97eF1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/4937/thumb/MultiVAC.png?1558598585',
            'coingeckoId': 'multivac',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'HUB',
            'name': 'Minter Hub',
            'address': '0x8aC0A467f878f3561D309cF9B0994b0530b0a9d2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14639/thumb/hub200.png?1651724396',
            'coingeckoId': 'minter-hub',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFESHIB',
            'name': 'SafeShiba',
            'address': '0x8aB5E2bfDf0257cA272c78b9fFC18B7242300900',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25631/thumb/kbvGzTeS_400x400.png?1652936740',
            'coingeckoId': 'safeshiba-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PLSPAD',
            'name': 'PulsePad',
            'address': '0x8a74BC8c372bC7f0E9cA3f6Ac0df51BE15aEC47A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20102/thumb/Mypk8PG_-_Imgur.jpg?1636511829',
            'coingeckoId': 'pulsepad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MYTV',
            'name': 'MyTVchain',
            'address': '0x8a682cC16dF6574801Ae578c3858F0daC44398C7',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/7026/thumb/mytv.png?1644646893',
            'coingeckoId': 'mytvchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ENG',
            'name': 'Eng Crypto',
            'address': '0x8a505D5Cb3Db9fcf404c0A72aF3dF8Be4eFB707c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26579/thumb/CoinGecko_ENG_Logo.png?1658888031',
            'coingeckoId': 'eng-crypto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '4STC',
            'name': '4 Stock',
            'address': '0x8a4840dc5975E424EC530ec88a8f27910a3CEF51',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18976/thumb/4stc.png?1638181789',
            'coingeckoId': '4-stock',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CIS',
            'name': 'Chihuahua In Space',
            'address': '0x8Fd6f1DC5F151311Ea08C6903Ae1e4a7de10e36d',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27404/thumb/200_200_%281%29_%282%29.png?1663831316',
            'coingeckoId': 'chihuahua-in-space',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GRX',
            'name': 'GravitX',
            'address': '0x8FbA8C1F92210f24fb277b588541ac1952e1aAC8',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18590/thumb/photo-2021-09-23-15-41-05.jpg?1632556832',
            'coingeckoId': 'gravitx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DAF',
            'name': 'Diamonds Are Forever',
            'address': '0x8Fb1a59cA2d57B51e5971A85277EFe72c4492983',
            'decimals': 6,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BRISE',
            'name': 'Bitgert',
            'address': '0x8FFf93E810a2eDaaFc326eDEE51071DA9d398E83',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1635753509466_5045839612838678.png',
            'coingeckoId': 'bitrise-token',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'SLVM',
            'name': 'Silver Mint',
            'address': '0x8FE6545381e88de4DF2f22F8b6b74e0060654f8A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MAINST',
            'name': 'BuyMainStreet',
            'address': '0x8FC1A944c149762B6b578A06c0de2ABd6b7d2B89',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20268/thumb/mainst.png?1636705100',
            'coingeckoId': 'buymainstreet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VANCII',
            'name': 'Vanci Finance',
            'address': '0x8F9b482b74Afc0B7E8AEfc704f1f04DF208EE332',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14185/thumb/xYIbiy7q_400x400.jpg?1614823744',
            'coingeckoId': 'vanci-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DRAGON',
            'name': 'Meta Dragon City',
            'address': '0x8F661C3C4BB09c8754907d4320E8786856D54B9d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23808/thumb/icon.png?1645510462',
            'coingeckoId': 'meta-dragon-city',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PALLA',
            'name': 'Pallapay',
            'address': '0x8F49733210700D38098d7375C221c7d02F700cc8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x8f49733210700d38098d7375c221c7d02f700cc8.png',
            'coingeckoId': 'pallapay',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'LDFI',
            'name': 'Lendefi',
            'address': '0x8F1E60D84182db487aC235acC65825e50b5477a1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17990/thumb/lendefi.jpg?1630040747',
            'coingeckoId': 'lendefi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GAN',
            'name': 'Galactic Arena  The NFTverse',
            'address': '0x8F1408171EAe06AEC4549fD0a5808A42cee6DD84',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19925/thumb/gan.png?1636446810',
            'coingeckoId': 'galactic-arena-the-nftverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BECOIN',
            'name': 'bePAY Finance',
            'address': '0x8F081Eb884Fd47b79536D28E2DD9d4886773F783',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21275/thumb/logo-becoin.png?1638861929',
            'coingeckoId': 'bepay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALPACA',
            'name': 'Alpaca Finance',
            'address': '0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x8f0528ce5ef7b51152a59745befdd91d97091d2f.png',
            'coingeckoId': 'alpaca-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'RTT',
            'name': 'Restore Truth',
            'address': '0x8F046a2457a8F1618cAe4706Fa57Bf790e2532a6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23525/thumb/W5sB5UGT_400x400.jpg?1644314969',
            'coingeckoId': 'restore-truth-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XRC',
            'name': 'xRhodium',
            'address': '0x8F0342BF1063b1d947B0F2Cc611301D611ac3487',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/7642/thumb/mlfE02__dS8lNip44E3EzOdW98xhBVC3iiDBmyBocVsOx6i44aAYaGrOpdkWFIQFXGo_lNr4f4U7cRTKnIKk0ZePM8fS_Eu7w8v6IbCw4yYaI-A5zPXEjnFREG0yLTUyG4AGstcl6laIgC8hoCq9HQO3M-9v4faj6vXyUaXw6PW1rXiYhqcu2eeE_oUqZxW1ww1Bup41yIUSFCdFyNSh.jpg?1611539770',
            'coingeckoId': 'xrhodium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KFAN',
            'name': 'KFan',
            'address': '0x8EfeF94EA1b04Aa02814F0F5c7a7389dabdB6D6A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16140/thumb/kpop.PNG?1623115693',
            'coingeckoId': 'kfan-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AU',
            'name': 'AutoCrypto',
            'address': '0x8Ea2f890CB86DFb0E376137451c6fD982AFefc15',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19157/thumb/mrineaq.png?1643187340',
            'coingeckoId': 'autocrypto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPHRI',
            'name': 'Spherium',
            'address': '0x8EA93D00Cc6252E2bD02A34782487EEd65738152',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17787/thumb/Group_15.png?1629252159',
            'coingeckoId': 'spherium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KAMPAY',
            'name': 'Kampay',
            'address': '0x8E984e03ab35795C60242c902ECe2450242C90e9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/8289/thumb/6130e86152bdb07e2848de00_200_4x.png?1630738336',
            'coingeckoId': 'kampay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SISHI',
            'name': 'Sishi Finance',
            'address': '0x8E8538c75f273aB2dF6AdEEcD3622A9c314fcCf3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14552/thumb/sishi_200_200.png?1616991068',
            'coingeckoId': 'sishi-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JKT',
            'name': 'JokerManor Metaverse',
            'address': '0x8E8123e286437B281cAa8abbF3A7f0CDf72b0aE6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20556/thumb/14732.png?1637208904',
            'coingeckoId': 'jokermanor-metaverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RBC',
            'name': 'Rubic',
            'address': '0x8E3BCC334657560253B83f08331d85267316e08a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12629/thumb/200x200.png?1607952509',
            'coingeckoId': 'rubic',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'CMD',
            'name': 'Comodo Coin',
            'address': '0x8DDD62949700937458A5C6535d1Ee5DbEBE62B77',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17879/thumb/uawrbab.png?1629704277',
            'coingeckoId': 'comodo-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PINKE',
            'name': 'PinkElon',
            'address': '0x8DA0F18e4deB7Ba81dBD061DF57325a894014B5a',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15289/thumb/qgtyiA0.png?1620358284',
            'coingeckoId': 'pinkelon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FVP',
            'name': 'FisherVsPirate',
            'address': '0x8D972727b2C0f053A2eF88A0690cDbDdbAE8A06A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22500/thumb/coin-gold.png?1641953314',
            'coingeckoId': 'fishervspirate',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RIDES',
            'name': 'Bit Rides',
            'address': '0x8D8D1a66291fED163A321d268DeCa809C1E98Dd1',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21285/thumb/CXw8bjfq_400x400.jpg?1638865082',
            'coingeckoId': 'bit-rides',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COD',
            'name': 'Crystal Of Dragon',
            'address': '0x8D8800687afeA249451734Af243A8983c8C2a9E5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22137/thumb/icon_COD_Mockup_128x128.png?1640919811',
            'coingeckoId': 'crystal-of-dragon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BNFT',
            'name': 'Beast NFT',
            'address': '0x8D7f08FfBE0506bAd571a731ECea67F7851107DA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23152/thumb/6jmyw9pN_400x400.jpg?1643351441',
            'coingeckoId': 'beast-nft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ICC',
            'name': 'Intergalactic Cockroach',
            'address': '0x8D672014Fb107cB409dCcd9042DdA3b97313F4C3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22195/thumb/cropped-logo.png?1641191593',
            'coingeckoId': 'intergalactic-cockroach',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CMERGE',
            'name': 'CoinMerge  BEP20 ',
            'address': '0x8D3E3A57c5F140B5f9Feb0d43D37A347ee01c851',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x8d3e3a57c5f140b5f9feb0d43d37a347ee01c851.png',
            'coingeckoId': 'coinmerge-bsc',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'RXT',
            'name': 'RIMAUNANGIS',
            'address': '0x8D1427a32F0A4C4BF052252e68E7FF1B2Ba80c01',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27598/thumb/Logo_rimaunangis_200x200_%283%29.png?1666607725',
            'coingeckoId': 'rimaunangis',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KEX',
            'name': 'KIRA Network',
            'address': '0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13232/thumb/avatar.png?1622601779',
            'coingeckoId': 'kira-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CrocFi',
            'name': 'Croc Finance',
            'address': '0x8D0F27b2B0c7376d25608ae83ACB92e3b695C941',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ECC',
            'name': 'Etherconnect',
            'address': '0x8D047F4F57A190C96c8b9704B39A1379E999D82B',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20287/thumb/14404.png?1636813272',
            'coingeckoId': 'etherconnect',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'HDV',
            'name': 'Hydraverse',
            'address': '0x8Cd0d76C0ad377378aB6Ce878a7BE686223497eE',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24114/thumb/HDV_token.png?1646379605',
            'coingeckoId': 'hydraverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GROW',
            'name': 'Growing fi',
            'address': '0x8CEF274596d334FFa10f8976a920DDC81ba6e29b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15253/thumb/grow.PNG?1620266437',
            'coingeckoId': 'growing-fi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POWER',
            'name': 'Civic Power',
            'address': '0x8CE7Fc007FC5D1dEA63FeD829e11eedDD6406dff',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22725/thumb/rsz-ico-power-1.png?1642487903',
            'coingeckoId': 'civic-power',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NULS',
            'name': 'Nuls',
            'address': '0x8CD6e29d3686d24d3C2018CEe54621eA0f89313B',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x8CD6e29d3686d24d3C2018CEe54621eA0f89313B/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PAPPAY',
            'name': 'Pappay',
            'address': '0x8C88699ef5adA1E5bEDFf7a4590aA346Abd3536d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15394/thumb/pappay.jpeg?1635510517',
            'coingeckoId': 'pappay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BNX',
            'name': 'BinaryX',
            'address': '0x8C851d1a123Ff703BD1f9dabe631b69902Df5f97',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0x8c851d1a123ff703bd1f9dabe631b69902df5f97.png',
            'coingeckoId': 'binaryx',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'MSC',
            'name': 'Monster Slayer Cash',
            'address': '0x8C784C49097Dcc637b93232e15810D53871992BF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x8c784c49097dcc637b93232e15810d53871992bf.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'GDE',
            'name': 'Lord of Power Golden Eagle',
            'address': '0x8C2B824B9ed7BC1eE6B83039D341b4F3dD14DF97',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27942/thumb/logonew.jpg?1666586220',
            'coingeckoId': 'lord-of-power-golden-eagle',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RVLNG',
            'name': 'RevolutionGames',
            'address': '0x8C11c352731fCEC7EA9D16357b69d91c13743DD1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24420/thumb/XQokIAeH_400x400.jpg?1647612029',
            'coingeckoId': 'revolutiongames',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INTD',
            'name': 'INDESTCOIN',
            'address': '0x8Bb93979901cd159bF6763B223FBb315C31CCF7b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27543/thumb/logoend.png?1668586274',
            'coingeckoId': 'indestcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KART',
            'name': 'Dragon Kart',
            'address': '0x8BDd8DBcBDf0C066cA5f3286d33673aA7A553C10',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x8BDd8DBcBDf0C066cA5f3286d33673aA7A553C10.png',
            'coingeckoId': 'dragon-kart-token',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'SAKE',
            'name': 'SakeSwap',
            'address': '0x8BD778B12b15416359A227F0533Ce2D91844e1eD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12428/thumb/sake.png?1599777402',
            'coingeckoId': 'sake-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CYN',
            'name': 'Cycan Network',
            'address': '0x8BAc919fBca13d67e9f901BF41537931effF0E7D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17534/thumb/rmrW1G3.jpg?1628131099',
            'coingeckoId': 'cycan-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HYPERR',
            'name': 'HyperRun',
            'address': '0x8B76cd7938e036Adf4fD6Ad80C98B7934c35091b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25588/thumb/logo_hyperrun.png?1652768738',
            'coingeckoId': 'hyperrun',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COW',
            'name': 'CashCow',
            'address': '0x8B6fA031c7D2E60fbFe4E663EC1B8f37Df1ba483',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19388/thumb/cash_cow.png?1648265503',
            'coingeckoId': 'cashcow',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WINE',
            'name': 'Wine Shares',
            'address': '0x8B3CC638431b34014FA3de174A25b18B364A7CC5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ACX',
            'name': 'AccessLauncher',
            'address': '0x8B292BAaBf70b745C791C69E0D91D2265290e53f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18783/thumb/logo_-_2021-10-05T133349.661.png?1633412067',
            'coingeckoId': 'accesslauncher',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POLYPAD',
            'name': 'PolyPad',
            'address': '0x8AE619D633cCe175A2fBcfA1cEA119DDC80F1342',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24905/thumb/BXApUK87_400x400.png?1649335541',
            'coingeckoId': 'polypad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NMS',
            'name': 'Nemesis DAO',
            'address': '0x8AC9DC3358A2dB19fDd57f433ff45d1fc357aFb3',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20389/thumb/nemesis_dao.PNG?1636961421',
            'coingeckoId': 'nemesis-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USD Coin',
            'address': '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png',
            'coingeckoId': 'usd-coin',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'METASHIB',
            'name': 'MetaShiba',
            'address': '0x8AA621be2c5F3672303c309BfB0DD4018979B970',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20930/thumb/metashiba.png?1638345755',
            'coingeckoId': 'metashiba',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'Q2',
            'name': 'Pocketful of Quarters',
            'address': '0x8A8C1F6C5B7708466807088d0Cfa10D9E39f029F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27640/thumb/Q2_Logo_Color.png?1664964159',
            'coingeckoId': 'q2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FOMOETH',
            'name': 'FomoETH',
            'address': '0x8A65B987D9813f0a97446eDa0dE918b2573Ae406',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21934/thumb/qSCAqd6m_400x400.jpg?1640301488',
            'coingeckoId': 'fomoeth',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MONSTA',
            'name': 'Cake Monster',
            'address': '0x8A5d7FCD4c90421d21d30fCC4435948aC3618B2f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16441/thumb/Monster-Icon-CG200x200transparent.png?1656392071',
            'coingeckoId': 'cake-monster',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FSD',
            'name': 'Finance Sports',
            'address': '0x8A28a8f589C9CcD55C0dF00F511C53DD368ba613',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27040/thumb/200x200px.png?1661501414',
            'coingeckoId': 'finance-sports',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'U',
            'name': 'Unidef',
            'address': '0x89dB9B433FeC1307d3dc8908f2813e9f7a1d38F0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26691/thumb/Unidef_Logo-_Square-_200_200.png?1668675266',
            'coingeckoId': 'unidef',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DRF',
            'name': 'Derify Protocol',
            'address': '0x89C1Af791d7B4cf046Dca8Fa10a41Dd2298A6a3F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24757/thumb/9VyY40eb_400x400.jpg?1648789821',
            'coingeckoId': 'derify-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GAFI',
            'name': 'GameFi',
            'address': '0x89Af13A10b32F1b2f8d1588f93027F69B6F4E27e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18292/thumb/gamefi.PNG?1631312307',
            'coingeckoId': 'gamefi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABYTETHER',
            'name': 'BABY TETHER',
            'address': '0x89AB641e93012787b25C9D73ff47eAaC705974A4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23371/thumb/For-sites-200x200.png?1643955559',
            'coingeckoId': 'baby-tether',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VIKING',
            'name': 'Viking Swap',
            'address': '0x896eDE222D3f7f3414e136a2791BDB08AAa25Ce0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14072/thumb/2_%282%29.png?1614151730',
            'coingeckoId': 'viking-swap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GRIMEX',
            'name': 'SpaceGrime',
            'address': '0x89671544190eE39E469C8393009875dF6565457a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15902/thumb/spacegrime.PNG?1622411596',
            'coingeckoId': 'spacegrime',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RMX',
            'name': 'RemeX',
            'address': '0x893e258ee221c9c8Da50d01108d1Ac47cFC3Dc9a',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17900/thumb/12.png?1629773152',
            'coingeckoId': 'remex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JET',
            'name': 'ApeJet',
            'address': '0x8925F9607d2E3fC31E11ca476b7Aa1b383E9a471',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25547/thumb/photo-2022-05-04-17-14-27.jpg?1652334643',
            'coingeckoId': 'apejet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AVE',
            'name': 'AveFarm',
            'address': '0x891c2fe01263E1154eF0E4648F4C12d402AA8e3d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26350/thumb/YJrMm6f.png?1657589599',
            'coingeckoId': 'avefarm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RBT',
            'name': 'Robust',
            'address': '0x891E4554227385c5c740F9B483E935E3CbC29F01',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17137/thumb/CG-200x.png?1626438233',
            'coingeckoId': 'robust-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FNG',
            'name': 'Fungie DAO',
            'address': '0x8918Bb91882CE41D9D9892246E4B56e4571a9fd5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18305/thumb/Fungie-DAO.png?1631507548',
            'coingeckoId': 'fungie-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFU',
            'name': 'StaySAFU',
            'address': '0x890cc7d14948478c98A6CD7F511E1f7f7f99F397',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17781/thumb/staysafu.PNG?1629247764',
            'coingeckoId': 'staysafu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YFI',
            'name': 'yearn.finance',
            'address': '0x88f1A5ae2A3BF98AEAF342D26B30a79438c9142e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PAYZ',
            'name': 'Payz Payments',
            'address': '0x88cF0A02371b9F00577552d673cF2ea0C18D0B14',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25040/thumb/payz-logo-2x.png?1649861033',
            'coingeckoId': 'payz-payments',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DLEGENDS',
            'name': 'My DeFi Legends',
            'address': '0x88c55B3255aE1e6628C953C5CDfF27Ad3Cc33C81',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18006/thumb/my_defi_legends.png?1630157431',
            'coingeckoId': 'my-defi-legends',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JMPT',
            'name': 'JumpToken',
            'address': '0x88D7e9B65dC24Cf54f5eDEF929225FC3E1580C25',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22603/thumb/200x200.png?1642162597',
            'coingeckoId': 'jumptoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORGN',
            'name': 'OragonX',
            'address': '0x88C676FC777c225B69869AeEf5d10535dE1E4F5D',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22010/thumb/oragonlnew200.png?1640590142',
            'coingeckoId': 'oragonx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EGG',
            'name': 'Waves Ducks',
            'address': '0x889eFCe29fa0BB9B26be9fda17A8003F4e8dA4dE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17298/thumb/200x200_pixel.png?1627288546',
            'coingeckoId': 'waves-ducks',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NUTS',
            'name': 'Squirrel Finance',
            'address': '0x8893D5fA71389673C5c4b9b3cb4EE1ba71207556',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x8893d5fa71389673c5c4b9b3cb4ee1ba71207556.png',
            'coingeckoId': 'squirrel-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BIRB',
            'name': 'Birb',
            'address': '0x88888888Fc33e4ECba8958c0c2AD361089E19885',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26373/thumb/BIRB_Official_Logo.jpeg?1657688291',
            'coingeckoId': 'birb-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AG',
            'name': 'AGAME',
            'address': '0x88888888FAedEB25B94A015Af705F18666079038',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28071/thumb/AG.jpeg?1667377489',
            'coingeckoId': 'agame',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MON',
            'name': 'PocMon',
            'address': '0x8888818f35BbeD1C632364bf1b15446FAD088888',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19090/thumb/communityIcon_e2ziky4lgar71.jpg?1634421204',
            'coingeckoId': 'pocmon-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IZU',
            'name': 'iZombie',
            'address': '0x8880dEF7d2753CAFda8aF3A32b381AD8f856E4A3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27997/thumb/coin_gecko.png?1666923648',
            'coingeckoId': 'izombie',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DDL',
            'name': 'DeFi Degen Land',
            'address': '0x88803312628fd21542F706B0C7dC8495c1c10B2e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20871/thumb/0inlfwNU_400x400.jpg?1637809911',
            'coingeckoId': 'defi-degen-land',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LEDU',
            'name': 'Education Ecosystem',
            'address': '0x887D9c01FFf04022da9c6D64a65a481a9e5D1FCa',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/3451/thumb/LEDU-education-ecosystem.png?1547038165',
            'coingeckoId': 'education-ecosystem',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': '$FORWARD',
            'name': 'Forward',
            'address': '0x886640149E31E1430FA74Cc39725431eb82ddFB2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'GEMG',
            'name': 'GemGuardian',
            'address': '0x885C5Fb8f0e67b2b0Cf3a437e6Cc6EBC0F9f9014',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18614/thumb/gemg.PNG?1632697389',
            'coingeckoId': 'gemguardian',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'PIG',
            'name': 'Pig Finance',
            'address': '0x8850D2c68c632E3B258e612abAA8FadA7E6958E5',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x8850d2c68c632e3b258e612abaa8fada7e6958e5.png',
            'coingeckoId': 'pig-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SQUIDGROW',
            'name': 'SquidGrow',
            'address': '0x88479186BAC914E4313389a64881F5ed0153C765',
            'decimals': 19,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26573/thumb/200x200-imgpng.png?1658885352',
            'coingeckoId': 'squidgrow',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CASHIO',
            'name': 'Cashio',
            'address': '0x88424C56DfaECb972c3163248928d00942191a92',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20141/thumb/Ak7zfzBC_400x400.jpg?1636533174',
            'coingeckoId': 'cashio-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GATOR',
            'name': 'GatorSwap',
            'address': '0x88371dEC00BC3543231E01089c3Dc6D94289d4aF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15634/thumb/Gator.png?1621402786',
            'coingeckoId': 'gatorswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VBTC',
            'name': 'Venus BTC',
            'address': '0x882C173bC7Ff3b7786CA16dfeD3DFFfb9Ee7847B',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x882c173bc7ff3b7786ca16dfed3dfffb9ee7847b.png',
            'coingeckoId': 'venus-btc',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'FREE',
            'name': 'Freedom Protocol',
            'address': '0x880BCe9321c79cAc1D290De6d31DDE722C606165',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25901/thumb/200logo.png?1654501385',
            'coingeckoId': 'freedom-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BBL',
            'name': 'Basketball Legends',
            'address': '0x8800E9902879AC7fB9823086d1749030C9a5EB91',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22131/thumb/200x200.png?1640872674',
            'coingeckoId': 'basketball-legends',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PVN',
            'name': 'Pavecoin',
            'address': '0x87f76cDa81d65D4B0983965725F598905Cbca98D',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18900/thumb/logo_-_2021-10-10T061103.040.png?1633817469',
            'coingeckoId': 'pavecoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ECM',
            'name': 'Ezcoinmarket',
            'address': '0x87e837803513e8c735a4eC1bcbf97Bc3050F41a5',
            'decimals': 0,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26704/thumb/sqfwBW7t_400x400.jpeg?1659664270',
            'coingeckoId': 'ezcoinmarket',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MGD',
            'name': 'Megla Doge',
            'address': '0x87C55991Dd7C0F946Dfc4ffcc513b61758096966',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21916/thumb/sK7fo-Ee_400x400.jpg?1640269313',
            'coingeckoId': 'megla-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FHTN',
            'name': 'Fishing Town',
            'address': '0x87BE0b856960f57fB0104Ef0922Cd1DACBd37F64',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21968/thumb/fishing_town.PNG?1640559621',
            'coingeckoId': 'fishing-town',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOR',
            'name': 'Mor Stablecoin',
            'address': '0x87BAde473ea0513D4aA7085484aEAA6cB6EBE7e3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18549/thumb/MOR_logo_icon_black_background_round_200_x_200-01.png?1632364631',
            'coingeckoId': 'mor-stablecoin',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'KDG',
            'name': 'Kingdom Game 4 0',
            'address': '0x87A2d9a9A6b2D61B2a57798f1b4b2DDd19458Fb6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/10914/thumb/8VIE2nF1_400x400.jpg?1585890794',
            'coingeckoId': 'kingdom-game-4-0',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METAN',
            'name': 'Metan Evolutions',
            'address': '0x879D239BCC0356Cf9df8c90442488bCe99554c66',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23762/thumb/metan.png?1645420372',
            'coingeckoId': 'metan-evolutions',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COP',
            'name': 'Copiosa',
            'address': '0x8789337a176e6e7223Ff115F1CD85C993D42C25c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14923/thumb/cop.png?1634697690',
            'coingeckoId': 'copiosa',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUC',
            'name': 'Lucretius',
            'address': '0x87837B7b4850687e200254f78c0AF0A34329a491',
            'decimals': 15,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24205/thumb/luc.png?1646836215',
            'coingeckoId': 'lucretius',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BIRD',
            'name': 'Bird Money',
            'address': '0x8780fEA4C6b242677D4A397fE1110AC09Ce99ad2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13260/thumb/favicon-180x180.png?1611546646',
            'coingeckoId': 'bird-money',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BHBD',
            'name': 'bHBD',
            'address': '0x874966221020D6aC1AeD0E2cfAd9cBfEe0bA713B',
            'decimals': 3,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27597/thumb/bHBD_2_200x200.png?1664709710',
            'coingeckoId': 'bhbd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VEST',
            'name': 'DAO Invest',
            'address': '0x873801Ae2ff12d816Db9a7B082F5796BEC64C82C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17901/thumb/logo-round-200.png?1629773511',
            'coingeckoId': 'dao-invest',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RVF',
            'name': 'RocketX exchange',
            'address': '0x872a34Ebb2d54Af86827810EeBC7b9dC6B2144aA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14728/thumb/avatar-correct-size-official.png?1668391022',
            'coingeckoId': 'rocketx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VENT',
            'name': 'Vent Finance',
            'address': '0x872D068c25511be88C1f5990c53eEFFCDf46c9B4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17925/thumb/Artboard_29.png?1629804982',
            'coingeckoId': 'vent-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QI',
            'name': 'BENQI',
            'address': '0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'SQUID',
            'name': 'Squid Game',
            'address': '0x87230146E138d3F296a9a77e497A2A83012e9Bc5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20506/thumb/squid.png?1638506382',
            'coingeckoId': 'squid-game',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BHO',
            'name': 'BHO Network',
            'address': '0x8717e80EfF08F53A45b4A925009957E14860A8a8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18625/thumb/12280.png?1650867353',
            'coingeckoId': 'bho-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REVT',
            'name': 'Revolt',
            'address': '0x87076daE0086d79873031E96a4b52c85f998ecB5',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15581/thumb/WhpwgTS.jpg?1621242127',
            'coingeckoId': 'revolt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NLIFE',
            'name': 'Night Life Crypto',
            'address': '0x86cbBEDCa621Ae78a421A40365081cAafDA24296',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16277/thumb/F6jp9eh.png?1623634100',
            'coingeckoId': 'night-life-crypto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OCTA',
            'name': 'Octans',
            'address': '0x86c3E4FfAcdB3AF628ef985a518cd6ee22A22b28',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x86c3e4ffacdb3af628ef985a518cd6ee22a22b28.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'PANGOLIN',
            'name': 'Pangolinswap',
            'address': '0x86aEFA44591c4E7fEf4dBf64ca39E3E24cBa3eF0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19300/thumb/logo_new.c013a6fa.png?1634917820',
            'coingeckoId': 'pangolinswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SNACK',
            'name': 'Crypto Snack',
            'address': '0x86aCCc2580dA5Bd522A0eEc7C881A0d4f33De657',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24869/thumb/Logo-Crypto-Snack-Clean-Gold-Coin-Version.png?1649211745',
            'coingeckoId': 'crypto-snack',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IHC',
            'name': 'Inflation Hedging Coin',
            'address': '0x86a53fcd199212FEa44FA7e16EB1f28812be911D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18835/thumb/imgonline-com-ua-Resize-QspvgKzsspQpANbd.png?1642665432',
            'coingeckoId': 'inflation-hedging-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NPC',
            'name': 'NPC Coin',
            'address': '0x86D6faDB5F7A0c069fB50F998e120835CaE15A54',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27016/thumb/logo.png?1661478989',
            'coingeckoId': 'npc-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOGBOSS',
            'name': 'Dog Boss',
            'address': '0x86C86FfDC0482D8DD918FC657c3FC51C4a1E3e5C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24934/thumb/278095103_108041131873840_7864245311638218164_n.jpg?1649412689',
            'coingeckoId': 'dog-boss',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DON',
            'name': 'Don key',
            'address': '0x86B3F23B6e90F5bbfac59b5b2661134Ef8Ffd255',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15482/thumb/donkey_logo.jpg?1621012824',
            'coingeckoId': 'don-key',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'FASTMOON',
            'name': 'FastMoon',
            'address': '0x869Dd7A64AfBe5370a8c591d9B8650BE60c0B8f6',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14908/thumb/7lE0hlG.png?1618986470',
            'coingeckoId': 'fastmoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WRAITH',
            'name': 'Wraith Protocol',
            'address': '0x8690Cb98496EF0f8c6417D78b5e0E29907668808',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0x8690cb98496ef0f8c6417d78b5e0e29907668808/4095726730e79914b72ffc777e0469f5.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BCDT',
            'name': 'EvidenZ',
            'address': '0x8683e604cdf911cD72652a04bf9D571697A86A60',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0x8683e604cdf911cd72652a04bf9d571697a86a60/50c3e8dd12dee473d451811347ddbbe1.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SINGH',
            'name': 'Singh',
            'address': '0x867B96B33B2c13CC8cB78A9aA95420c6cD42C4c6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25123/thumb/singh-200x200.png?1650371504',
            'coingeckoId': 'singh',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SLEEPY',
            'name': 'Sleepy Sloth Finance',
            'address': '0x86432b9beA373172C852D5bbAB1c275FeC3f15aE',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15008/thumb/logo200x200_%283%29.png?1619426268',
            'coingeckoId': 'sleepy-sloth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KTI',
            'name': 'Kermit Inu',
            'address': '0x864078171D77b9c3faaD3fEBe98954279BF56E78',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26233/thumb/IMG_20220701_063931_364.jpg?1656899963',
            'coingeckoId': 'kermit-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TAU',
            'name': 'Atlantis Metaverse',
            'address': '0x8632055b9CAeebEf7C7DccD95461608ca5378839',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21394/thumb/atlastnsis.PNG?1639041852',
            'coingeckoId': 'atlantis-metaverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FBD',
            'name': 'Football Decentralized',
            'address': '0x862a65D31DC45a0dfCcBB4DE5659137A6589c52a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27658/thumb/200x200.png?1666774116',
            'coingeckoId': 'football-decentralized',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KTY',
            'name': 'Krypto Kitty',
            'address': '0x86296279C147bd40cBe5b353F83cea9e9cC9b7bB',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x86296279c147bd40cbe5b353f83cea9e9cc9b7bb.png',
            'coingeckoId': 'krypto-kitty',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BERRY',
            'name': 'BerrySwap',
            'address': '0x8626F099434d9A7E603B8f0273880209eaBfc1c5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14609/thumb/Berry.jpg?1617245229',
            'coingeckoId': 'berryswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLKC',
            'name': 'BlackHat Coin',
            'address': '0x8626264B6a1B4e920905Efd381002abA52EA0Eea',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15987/thumb/logo_light.png?1622537905',
            'coingeckoId': 'blackhat-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFTY',
            'name': 'NFTY Token',
            'address': '0x8623e66Bea0DCe41B6d47f9C44e806A115baBae0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0x8623e66bea0dce41b6d47f9c44e806a115babae0/afa8729e81a7e9e2c401d3e8a8d39506.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MNZ',
            'name': 'Menzy',
            'address': '0x861f1E1397daD68289e8f6a09a2ebb567f1B895C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26291/thumb/Menzy-logo.jpeg?1657160543',
            'coingeckoId': 'menzy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SLR',
            'name': 'Salary',
            'address': '0x8619c4B2ecdCB716CD162ec73F332C4d7dC06f1E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20543/thumb/resized-image-Promo-removebg-preview.png?1637204634',
            'coingeckoId': 'salary',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'C4C',
            'name': 'Crypto 4 A Cause Fund',
            'address': '0x86028c1C730943AB56eBB7D6f39e20ad22121004',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ARTH',
            'name': 'ARTH',
            'address': '0x85daB10c3BA20148cA60C2eb955e1F8ffE9eAa79',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16876/small/ARTH_Token.png?1625651538',
            'coingeckoId': 'arth',
            'listedIn': [
                'coingecko',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'OMNIS',
            'name': 'OMNIS',
            'address': '0x85d8FB55EccCefAFC4A0624ea5e4bE6f521E7117',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17745/thumb/footer_logo_%282%29.png?1629164021',
            'coingeckoId': 'omnis',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KEY',
            'name': 'MoMo Key',
            'address': '0x85c128eE1feEb39A59490c720A9C563554B51D33',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x85c128ee1feeb39a59490c720a9c563554b51d33.png',
            'coingeckoId': 'momo-key',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'VINCI',
            'name': 'Vinci',
            'address': '0x85ba4988443bc8B39307Eec67B8c488666cD5819',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0x85ba4988443bc8b39307eec67b8c488666cd5819/28596ad41b692422c274bef72617b3a4.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'STORY',
            'name': 'Story',
            'address': '0x85EE8e3E0068EdeeEE960979958d7F61416a9d84',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18128/thumb/logo_-_2021-09-03T064032.320.png?1630622531',
            'coingeckoId': 'story',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRX',
            'name': 'TRON  BSC ',
            'address': '0x85EAC5Ac2F758618dFa09bDbe0cf174e7d574D5B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x85EAC5Ac2F758618dFa09bDbe0cf174e7d574D5B/logo.png',
            'coingeckoId': 'tron-bsc',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'USDG',
            'name': 'USD Gambit',
            'address': '0x85E76cbf4893c1fbcB34dCF1239A91CE2A4CF5a7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15886/thumb/usdg-02.png?1622207135',
            'coingeckoId': 'usd-gambit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FDLS',
            'name': 'FIDELIS',
            'address': '0x85Cec9d09529C4239Dcd89018889238aBdD3EDE6',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24778/thumb/fdls.png?1648900950',
            'coingeckoId': 'fidelis',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZEBRA',
            'name': 'Zebra DAO',
            'address': '0x85A8Bfc818b0F33a10d359b2E1aECbE09B96DBE5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27393/thumb/200x200.png?1663852069',
            'coingeckoId': 'zebra-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VANCAT',
            'name': 'Vancat  OLD ',
            'address': '0x8597ba143AC509189E89aaB3BA28d661A5dD9830',
            'decimals': 0,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x8597ba143ac509189e89aab3ba28d661a5dd9830.png',
            'coingeckoId': 'vancat',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'PEACE',
            'name': 'Peace DAO',
            'address': '0x85973C97919c999E5D3d8832290152a3ACdf8a6E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24555/thumb/WechatIMG444.jpeg?1648135874',
            'coingeckoId': 'peace-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTTOLD',
            'name': 'BitTorrent  OLD ',
            'address': '0x8595F9dA7b868b1822194fAEd312235E43007b49',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x8595F9dA7b868b1822194fAEd312235E43007b49/logo.png',
            'coingeckoId': 'bittorrent-old',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'LTO',
            'name': 'LTO Network',
            'address': '0x857B222Fc79e1cBBf8Ca5f78CB133d1b7CF34BBd',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x3db6ba6ab6f95efed1a6e794cad492faaabf294d.png',
            'coingeckoId': 'lto-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PANDA',
            'name': 'HashPanda',
            'address': '0x8578Eb576e126f67913a8bC0622e0A22EBa0989A',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15903/thumb/logo_-_2021-05-31T060013.983.png?1622412025',
            'coingeckoId': 'hashpanda',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KCAL',
            'name': 'Phantasma Energy',
            'address': '0x855EA8048E1852996429A50aBdA60F583909d298',
            'decimals': 10,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25447/thumb/Screenshot_from_2022-05-06_09-46-03.png?1651801573',
            'coingeckoId': 'phantasma-energy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETHAX',
            'name': 'ETHAX',
            'address': '0x854F7Cd3677737241E3eED0dC3d7F33DFAF72Bc4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25571/thumb/ethax.png?1652611600',
            'coingeckoId': 'ethax',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MNTO',
            'name': 'Minato',
            'address': '0x854A63B35B70A7becbED508ff0b6Ff5038D0C917',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24622/thumb/MNTO_200x200.png?1648448664',
            'coingeckoId': 'minato',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'INVEST',
            'name': 'InvestDex',
            'address': '0x853A8Ab1c365ea54719EB13A54d6B22F1fBe7FEb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16382/thumb/FINAL_FINAL.png?1624330606',
            'coingeckoId': 'investdex',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'METADOGE',
            'name': 'Meta Doge',
            'address': '0x8530b66ca3DDf50E0447eae8aD7eA7d5e62762eD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19656/thumb/metadoge.png?1636333901',
            'coingeckoId': 'meta-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AMC',
            'name': 'Amnext',
            'address': '0x852E3A65d0cD8561eDc0927012412D60AAAa9a4C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17192/thumb/Icon-200x200_%282%29.png?1626835661',
            'coingeckoId': 'amnext',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YUSE',
            'name': 'Yuse',
            'address': '0x8526FF6bBd8A976127443b1ce451ca1044aA3Ce2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25416/thumb/NlEpysOy_400x400.png?1651734883',
            'coingeckoId': 'yuse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FRMX',
            'name': 'FRMx',
            'address': '0x8523518001ad5d24b2A04e8729743C0643A316c0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13082/thumb/frmx.png?1660311822',
            'coingeckoId': 'frmx-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARGON',
            'name': 'Argon',
            'address': '0x851F7a700c5d67DB59612b871338a85526752c25',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x851f7a700c5d67db59612b871338a85526752c25.png',
            'coingeckoId': 'argon',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'RAMP',
            'name': 'RAMP  OLD ',
            'address': '0x8519EA49c997f50cefFa444d240fB655e89248Aa',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x8519ea49c997f50ceffa444d240fb655e89248aa.png',
            'coingeckoId': 'ramp',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'nICE',
            'name': 'NICE',
            'address': '0x8506560320826e459F356CB56cCF721Da8875414',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/logos/main/network/binance/0x8506560320826e459F356CB56cCF721Da8875414.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'GOD',
            'name': 'DOGGOD',
            'address': '0x84f8C11c37b6b1E15A645B5f5149F9DFea4C93B1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27030/thumb/logo200x200.png?1661495130',
            'coingeckoId': 'doggod',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GMEE',
            'name': 'GAMEE',
            'address': '0x84e9a6F9D240FdD33801f7135908BfA16866939A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x84e9a6f9d240fdd33801f7135908bfa16866939a.png',
            'coingeckoId': 'gamee',
            'listedIn': [
                'coingecko',
                '1inch',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'DDD',
            'name': 'Dot Dot Finance',
            'address': '0x84c97300a190676a19D1E13115629A11f8482Bd1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25212/thumb/batkXqm.png?1650859186',
            'coingeckoId': 'dot-dot-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZNN',
            'name': 'Zenon',
            'address': '0x84b174628911896a3b87Fa6980D05Dbc2eE74836',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/8177/thumb/kC-sx49e_400x400.jpg?1555989643',
            'coingeckoId': 'zenon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YEON',
            'name': 'Yeon',
            'address': '0x84b1270f5A858Dc25dB8cc005ffF27fbF6c8afD2',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27874/thumb/download_%283%29.jpeg?1666175588',
            'coingeckoId': 'yeon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PRP',
            'name': 'Perpetuum Coin',
            'address': '0x84aFB95ca5589674e02d227Bdd6DA7E7DCf31A3E',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19605/thumb/LOGO_PRP_200x200.png?1641311277',
            'coingeckoId': 'perpetuum-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CPI',
            'name': 'Crypto Pitch',
            'address': '0x84a22F4319926B85F47A33B5409CfD3E3e752a3a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27729/thumb/black-transpa-200x200.png?1665557613',
            'coingeckoId': 'crypto-pitch',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JUMPX',
            'name': 'JumpX',
            'address': '0x84a0FdfcaeA03F57fB7e7aa511DB3DA76bbEFd0F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27452/thumb/200photo_2022-09-15_12-14-58.jpg?1664089069',
            'coingeckoId': 'jumpx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KUKU',
            'name': 'panKUKU',
            'address': '0x84Fd7CC4Cd689fC021eE3D00759B6D255269D538',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26657/thumb/Official_Logo_Round_200x200.png?1659413819',
            'coingeckoId': 'pankuku',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KINGSHIB',
            'name': 'King Shiba',
            'address': '0x84F4f7cdb4574C9556A494DaB18ffc1D1D22316C',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19215/thumb/kingshib.png?1661498524',
            'coingeckoId': 'king-shiba',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHAN',
            'name': 'Shanum',
            'address': '0x84CFc0427147026368C2aaC4f502d98aaC47eB48',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': 'shanum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EVCOIN',
            'name': 'EverestCoin',
            'address': '0x84B7BB9614Cf28226d1b0a07499472bc107e3000',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19137/thumb/evcoin.png?1634525553',
            'coingeckoId': 'everestcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MCOIN',
            'name': 'mCoin',
            'address': '0x84B03133534a1Fe44A6479771574970804D3310B',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24942/thumb/mcoin_Logo_%281%29.png?1649429516',
            'coingeckoId': 'mcoin1',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XPERPS',
            'name': 'xPERPS',
            'address': '0x849Cc5ef9DDd08691EE908aD8001003b4bF104e5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27270/thumb/xperps.png?1663063638',
            'coingeckoId': 'xperps',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFTI',
            'name': 'SafuTitano',
            'address': '0x84952dd90cdC57170b13D2291A8afCEdC8F97c71',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24535/thumb/vHQ6BpuO_400x400.jpg?1648050874',
            'coingeckoId': 'safutitano',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UMB',
            'name': 'Umbrella Network',
            'address': '0x846F52020749715F02AEf25b5d1d65e48945649D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13913/thumb/Umbrella_Network_Logo-Vertical_Version.png?1612836176',
            'coingeckoId': 'umbrella-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '99DEFI',
            'name': '99Defi',
            'address': '0x8468292f02BEF940f3FB0CedA6607DaD625d8C0B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25649/thumb/99defiicon.png?1653028696',
            'coingeckoId': '99defi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'P2E',
            'name': 'Plant2Earn',
            'address': '0x8461708744DAB03391961d72DDd72e6687f478F2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17783/thumb/200x200_%2827%29.png?1629250108',
            'coingeckoId': 'plant2earn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OZG',
            'name': 'Ozagold',
            'address': '0x8460B5A15B41E3FCa7df1ed98a5017bbf518f3a9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16210/thumb/OZAGOLD.png?1623311730',
            'coingeckoId': 'ozagold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOP',
            'name': 'Dopple Finance',
            'address': '0x844FA82f1E54824655470970F7004Dd90546bB28',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x844FA82f1E54824655470970F7004Dd90546bB28/logo.png',
            'coingeckoId': 'dopple-finance',
            'listedIn': [
                'coingecko',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BEL',
            'name': 'Bella Protocol',
            'address': '0x8443f091997f06a61670B735ED92734F5628692F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x8443f091997f06a61670B735ED92734F5628692F/logo.png',
            'coingeckoId': 'bella-protocol',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'LOWB',
            'name': 'Loser Coin',
            'address': '0x843D4a358471547f51534e3e51fae91cb4Dc3F28',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x843d4a358471547f51534e3e51fae91cb4dc3f28.png',
            'coingeckoId': 'loser-coin',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'YARL',
            'name': 'Yarloo',
            'address': '0x843CbC1732aE7D7ba0533C6380989DACec315FfE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19077/thumb/yarloo.png?1634717726',
            'coingeckoId': 'yarloo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OTL',
            'name': 'OtherLife',
            'address': '0x842D91FC95d8c8002051DD415E28DC9Dc5E88c90',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22446/thumb/aswLmNG.png?1641824050',
            'coingeckoId': 'otherlife',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LIGHT',
            'name': 'Light Defi',
            'address': '0x842668E2B9A73240aBF6532DEdC89c9c3e050C98',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17880/thumb/bW0DtiU.png?1658286896',
            'coingeckoId': 'light-defi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOAK',
            'name': 'Soakmont',
            'address': '0x841dc7A49e0825bDF3eE585cfB6E553495915AcE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x841dc7A49e0825bDF3eE585cfB6E553495915AcE/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'HUDI',
            'name': 'Hudi',
            'address': '0x83d8Ea5A4650B68Cd2b57846783d86DF940F7458',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18454/thumb/coin_200x200.png?1632105535',
            'coingeckoId': 'hudi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUILD',
            'name': 'BUILD',
            'address': '0x83b27De2Fca046FA63a11C7Ce7743DE33Ec58822',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26533/thumb/BUILD.png?1658714842',
            'coingeckoId': 'build',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BWJ',
            'name': 'Baby WOJ',
            'address': '0x83F41c98d028842Ccc8060b4Ec7738Df3eb9a2e6',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22948/thumb/WOJ.png?1643009571',
            'coingeckoId': 'baby-woj',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RTE',
            'name': 'Ride To Earn RTE',
            'address': '0x83AD7F46454d28c8363035ce1f3d5eb629991ee2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26010/thumb/logobg_%282%29.png?1655280911',
            'coingeckoId': 'ride-to-earn-rte',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SYA',
            'name': 'SYA x Flooz',
            'address': '0x83A86adf1a7c56e77d36d585B808052e0a2aAD0e',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15440/thumb/syaxflr.png?1634781645',
            'coingeckoId': 'sya-x-flooz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALKOM',
            'name': 'Alpha Kombat',
            'address': '0x8379B36826675450c35e1eb45d2fd1E1aE8494A4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17573/thumb/D4rxMhz.png?1633154420',
            'coingeckoId': 'alpha-kombat',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PURPLEFLOKI',
            'name': 'Purple Floki Inu',
            'address': '0x83713F2219894deb559Ec5530e80e3bC116334dD',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21743/thumb/pf.png?1639978175',
            'coingeckoId': 'purple-floki-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OFF',
            'name': 'Offline',
            'address': '0x837086540aF27AA0a51b64873Fbc37920500Ed43',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23312/thumb/pe6yIPvb_400x400.jpg?1643754040',
            'coingeckoId': 'offline',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FANTOMAPES',
            'name': 'Fantom of the Opera Apes',
            'address': '0x836BffC28f13fF46bF4870056C2B45dc998EE50e',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20411/thumb/fantom_opera_apes.PNG?1637014000',
            'coingeckoId': 'fantom-of-the-opera-apes',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHARK',
            'name': 'SharkBNB',
            'address': '0x83667fC70D25C705F306AD009550131734CB6ed4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x83667fc70d25c705f306ad009550131734cb6ed4/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SPO',
            'name': 'Spores Network',
            'address': '0x8357c604c5533fa0053BeAaA1494Da552ceA38f7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17431/thumb/SPO_token.png?1627619762',
            'coingeckoId': 'spores-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GXP',
            'name': 'Game X Change Potion',
            'address': '0x8347AC51Ec4eF29324591D55e2b585A38b2ead5B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18471/thumb/gxp.png?1640072326',
            'coingeckoId': 'game-x-change-potion',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DKKS',
            'name': 'Daikokuten Sama',
            'address': '0x834613c64522725b23b458aF04ED1590D189962F',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18930/thumb/Logo_200x200.png?1633943278',
            'coingeckoId': 'daikokuten-sama',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HNTR',
            'name': 'Hunter',
            'address': '0x83451A4E3585FdA74FEb348Ad929f2C4cA189660',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27793/thumb/Hunter-Logo-large.png?1665819905',
            'coingeckoId': 'hunter',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TBANK',
            'name': 'Tokenbank',
            'address': '0x833b5Cb9A2Cffb1587BbefE53A451D0989FBA714',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23957/thumb/logo_fundo_branco.png?1648049953',
            'coingeckoId': 'tokenbank',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REVV',
            'name': 'REVV',
            'address': '0x833F307aC507D47309fD8CDD1F835BeF8D702a93',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x833f307ac507d47309fd8cdd1f835bef8d702a93.png',
            'coingeckoId': 'revv',
            'listedIn': [
                'coingecko',
                '1inch',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'AVR',
            'name': 'AVARA',
            'address': '0x8337b6FE7A3198FB864FfbDE97dda88cfDCCbd96',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22688/thumb/new.png?1642409100',
            'coingeckoId': 'avara',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWAT',
            'name': 'SWTCoin',
            'address': '0x82e7Eb8f4c307F2dcf522fDCA7b7038296584f29',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/5985/thumb/swtcoin.jpg?1547041927',
            'coingeckoId': 'swtcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RUNEARN',
            'name': 'RunEarner',
            'address': '0x82d2c560b9aFfFf5049bE54536C922A462D717e4',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27594/thumb/Runearner_200x200.png?1664708279',
            'coingeckoId': 'runearner',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WARZ',
            'name': 'Crypto Warz',
            'address': '0x82be242BDf3a5C8da8fE3c1Fa303C1D8d0E7Bb7f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26949/thumb/warz.png?1660986780',
            'coingeckoId': 'crypto-warz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '7PXS',
            'name': '7Pixels',
            'address': '0x82bEC5483dbAB4305f32B191D76Dc6cB020ae76D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26153/thumb/7pxs.png?1656067961',
            'coingeckoId': '7pixels',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FPG',
            'name': 'Farming Paradise',
            'address': '0x82b43184B4020F863220c53D82713C24c6e39B28',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27712/thumb/200X200.png?1665452745',
            'coingeckoId': 'farming-paradise',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MST',
            'name': 'MetaSportsToken',
            'address': '0x82aFb954fDEebD2370D575AC59AE4f119654bE14',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27877/thumb/200*200.png?1666228640',
            'coingeckoId': 'metasportstoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POPE',
            'name': 'Crypto Pote',
            'address': '0x82E684d9E0A2892E7d2ada8C8e1DCdC33eE4b645',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16404/thumb/crypto-pope-LOGO.png?1623915534',
            'coingeckoId': 'crypto-pote-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FOMOBABY',
            'name': 'FOMO BABY',
            'address': '0x82D6E409438E9c2eAEd8ceec4Bd95918cbd17c87',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20435/thumb/Artboard_29.png?1637037640',
            'coingeckoId': 'fomo-baby',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALEPH',
            'name': 'Aleph im',
            'address': '0x82D2f8E02Afb160Dd5A480a617692e62de9038C4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/11676/thumb/Monochram-aleph.png?1608483725',
            'coingeckoId': 'aleph',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CP',
            'name': 'CoPuppy',
            'address': '0x82C19905B036bf4E329740989DCF6aE441AE26c1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17544/thumb/cp.PNG?1628203894',
            'coingeckoId': 'copuppy',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MDO',
            'name': 'Medano',
            'address': '0x82A70e626729060c156A49963920D4772eEE2A56',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25214/thumb/logo_medano-1.png?1650865147',
            'coingeckoId': 'medano',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CASIO',
            'name': 'CasinoXMetaverse',
            'address': '0x82A336bA310b5a590a59dB08A0E1FF8C33Df8Bbd',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22930/thumb/Casio-Icon.png?1643003651',
            'coingeckoId': 'casinoxmetaverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CMLT',
            'name': 'Camel',
            'address': '0x829eE30A8Fac90152105DD77d6ffA01158Af9434',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22227/thumb/cmltlogo.png?1641219975',
            'coingeckoId': 'cameltoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDEX',
            'name': 'USDEX',
            'address': '0x829c09fCc46D9fd31967272ABA245BEF9f727F93',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25748/thumb/USDEX_200x200_%281%29.png?1653463053',
            'coingeckoId': 'usdex-stablecoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RETA',
            'name': 'Realital Metaverse',
            'address': '0x829555f1197171D35Ec51C095E27b47A246aC6A6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22791/thumb/reta_logo_200.png?1642581119',
            'coingeckoId': 'realital-metaverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LDX',
            'name': 'Litedex',
            'address': '0x8286387174B8667AE5222306A27e9ab5189b503b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24939/thumb/coin-litedex-200x200.png?1649423175',
            'coingeckoId': 'litedex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BIDEN',
            'name': 'BIDEN',
            'address': '0x8282c1DAA19Ee23276f307b8F4AFf5002AF53211',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19816/thumb/biden.jpeg?1635926124',
            'coingeckoId': 'biden',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FADO',
            'name': 'FADO Go',
            'address': '0x827d24bB2aad813fE6F49b798F44cCe4c48Bd478',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23593/thumb/fado.png?1647078009',
            'coingeckoId': 'fado-go',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRAVEL',
            'name': 'Travel Care',
            'address': '0x826e5Ec70DbC5607fF9218011FBb97F9a8D97953',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24164/thumb/zDm5HX8I_400x400.jpg?1646641993',
            'coingeckoId': 'travel-care-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DMOON',
            'name': 'DragonMoon',
            'address': '0x8267d169E1483E01AACBCfd22d294c47ECE9F07f',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17177/thumb/dmoon.PNG?1626816912',
            'coingeckoId': 'dragonmoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALU',
            'name': 'Altura',
            'address': '0x8263CD1601FE73C066bf49cc09841f35348e3be0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1635075338379_694667997171063.png',
            'coingeckoId': 'altura',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'METAHOUSE',
            'name': 'Meta House',
            'address': '0x825791Bc83A377Ba3931E8a56504a1EACAAE0f76',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24140/thumb/YqBBH9V.png?1646548190',
            'coingeckoId': 'meta-house',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CMC',
            'name': 'CryptoMotorcycle',
            'address': '0x8255E7B6fD3467AdF0c092A18ae01879627a9755',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20380/thumb/mt.png?1636950838',
            'coingeckoId': 'cryptomotorcycle',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEFILANCER',
            'name': 'Defilancer',
            'address': '0x82506c025AB4295f3ce6b18C28efc5F899fc7c88',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25970/thumb/download.png?1655093253',
            'coingeckoId': 'defilancer',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KICK',
            'name': 'Kick',
            'address': '0x824a50dF33AC1B41Afc52f4194E2e8356C17C3aC',
            'decimals': 10,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/981/thumb/kick.png?1568643013',
            'coingeckoId': 'kick',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWAP',
            'name': 'Trustswap',
            'address': '0x82443A77684A7Da92FdCB639c8d2Bd068a596245',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/11795/thumb/Untitled_design-removebg-preview.png?1626926355',
            'coingeckoId': 'trustswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GRT',
            'name': 'Green Ride',
            'address': '0x82338511b087c884411d7C17145f6e172Fe1B03f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26269/thumb/K-fAc7If_400x400.jpg?1656988691',
            'coingeckoId': 'green-ride-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BINS',
            'name': 'BSocial',
            'address': '0x822DC83fA4dc997fFf24D0BC0A66fCB2954a6156',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18160/thumb/200x200_%281%29.png?1630742951',
            'coingeckoId': 'bsocial',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LIFE',
            'name': 'Life Crypto',
            'address': '0x82190d28E710ea9C029D009FaD951c6F1d803BB3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18245/thumb/communityIcon_t3kzc5skazh81.png?1666058038',
            'coingeckoId': 'life-crypto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '1BOX',
            'name': '1BOX',
            'address': '0x82160113b730fC0B36C18795CC976fda93ccc1e1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20204/thumb/Coin-Icon.png?1636623868',
            'coingeckoId': '1box',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIBAPUP',
            'name': 'ShibaPup',
            'address': '0x8203612382f61baFaE549fBF31eFbf70992fA289',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15328/thumb/6096950d4ec27bf003a1cdd0_200.png?1620528626',
            'coingeckoId': 'shibapup',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FEVR',
            'name': 'RealFevr',
            'address': '0x82030CDBD9e4B7c5bb0b811A61DA6360D69449cc',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17136/thumb/Fevr-Token.png?1626869849',
            'coingeckoId': 'realfevr',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'CKP',
            'name': 'CakePad',
            'address': '0x81f7bBFc362994BadcdcaE1B38D426be4d82894a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25195/thumb/NRTOCIMm_400x400.png?1650610573',
            'coingeckoId': 'cakepad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APP',
            'name': 'Dappsy',
            'address': '0x81e07CfF8a9331eF2A837B15a3560fb186bF5E8D',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20082/thumb/gqaQ4K7l_400x400.png?1636457917',
            'coingeckoId': 'dappsy',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'UNC',
            'name': 'Utility NFT Coin',
            'address': '0x81d5791E65e0dB42687e2cAF205F14A5E550C2aF',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25427/thumb/UNC-logo-200.png?1651737772',
            'coingeckoId': 'utility-nft-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BHAX',
            'name': 'Bithashex',
            'address': '0x81cA302d88d089dD42E80e81623f84f9730e94FE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20073/thumb/Bithashex_Grey_and_Blue_Logo.jpeg?1646720263',
            'coingeckoId': 'bithashex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BitANT',
            'name': 'BitANT',
            'address': '0x81a4c8438b77221f192d8D25252Afac62FECd58f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ORC',
            'name': 'Oracle Dao',
            'address': '0x81F52Dd5FCe4D1f24e5aa839Df782244BE867B14',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28003/thumb/logo-new-200x200.png?1667532066',
            'coingeckoId': 'oracle-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOONTOKEN',
            'name': 'Moon Token',
            'address': '0x81E4d494b85A24a58a6BA45c9B418b32a4E039de',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x81e4d494b85a24a58a6ba45c9b418b32a4e039de.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BCITY',
            'name': 'Bitcoin City Coin',
            'address': '0x81D60AD757634E77D7ac321A90530EB6F0B71fa3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24611/thumb/bcity.png?1648372337',
            'coingeckoId': 'bitcoin-city-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VV',
            'name': 'Vikings Valhalla',
            'address': '0x81B86C55799Bd9e96c89D075964D54DF0D32B4b8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23541/thumb/kxdNBBO.jpeg?1644390812',
            'coingeckoId': 'vikings-valhalla',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLA',
            'name': 'BlueArt',
            'address': '0x81Aa4d3AD2A86E7A2cd44630C36ccAACD6b30568',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28040/thumb/200x200_blueart_logo.png?1667465129',
            'coingeckoId': 'blueart',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WLFGRL',
            'name': 'Wolfgirl',
            'address': '0x819a27c0D14a1D7e05D4E31a4778a0662dB5Cb00',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20724/thumb/Icon200.png?1637593750',
            'coingeckoId': 'wolfgirl',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BFI',
            'name': 'Bearn fi',
            'address': '0x81859801b01764D4f0Fa5E64729f5a6C3b91435b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x81859801b01764D4f0Fa5E64729f5a6C3b91435b/logo.png',
            'coingeckoId': 'bearn-fi',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NTR',
            'name': 'Nether',
            'address': '0x8182ac1C5512EB67756A89C40fadB2311757bD32',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18009/thumb/Icon_-_Logo_600x600px.png?1630228246',
            'coingeckoId': 'nether',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HNW',
            'name': 'Hobbs Networking',
            'address': '0x8173CcC721111b5a93CE7fa6fEc0fc077B58B1B7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19134/thumb/MWDEnHyP_400x400.jpg?1634524538',
            'coingeckoId': 'hobbs-networking',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BITTO',
            'name': 'BITTO',
            'address': '0x816e9e589F8C07149dA4E2496C547952338B27e2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/2814/thumb/BITTO.jpg?1547036986',
            'coingeckoId': 'bitto-exchange',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CZSHARES',
            'name': 'CZshares',
            'address': '0x8169a36EC368882207515D6F509A2E7Fc74203d3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26163/thumb/czlogo-small.62248bce.png?1656324283',
            'coingeckoId': 'czshares',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RISU',
            'name': 'Risu',
            'address': '0x8163100460d2186DE4e700C479D5e87283426D27',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27229/thumb/logo_%288%29.png?1667725134',
            'coingeckoId': 'risu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POLKAS',
            'name': 'PolkaStation',
            'address': '0x81402bDf885a930cCf2175B66FBd435486b33f69',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27444/thumb/200x200_logo.png?1664001786',
            'coingeckoId': 'polkastation',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWI',
            'name': 'Swinca',
            'address': '0x81372C18c87f6d2FE91F416d7c8a109CEA48C332',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25534/thumb/LOGO-TOKEN-2.jpeg?1652251811',
            'coingeckoId': 'swinca-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HUNGRY',
            'name': 'HungryBear',
            'address': '0x812Ff2420EC87eB40Da80a596f14756ACf98Dacc',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15067/thumb/hungry.PNG?1619597072',
            'coingeckoId': 'hungrybear',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DAO',
            'name': 'DAOMaker',
            'address': '0x812764fa37A4fD731A5D39F0E519b6b8d61E82A0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'KLN',
            'name': 'Kalera NFT',
            'address': '0x8116F40e9A2c4696a94eaD8F79E921b69370c478',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22563/thumb/mozinv5D_400x400.jpg?1642058416',
            'coingeckoId': 'kalera-nft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CYC',
            'name': 'Cyclone Protocol',
            'address': '0x810EE35443639348aDbbC467b33310d2AB43c168',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x810ee35443639348adbbc467b33310d2ab43c168.png',
            'coingeckoId': 'cyclone-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BOC',
            'name': 'BOCOIN',
            'address': '0x80e7dc4e726E052b0dB04ec8b506596458809c11',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26756/thumb/200-200_bo_logo.png?1660032505',
            'coingeckoId': 'bocoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XDNA',
            'name': 'extraDNA',
            'address': '0x80dbA9C32b7aB5445e482387A5522e24C0Ba4C24',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12667/thumb/Logo_coin_black.png?1601463830',
            'coingeckoId': 'extradna',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QDROP',
            'name': 'QuizDrop',
            'address': '0x80dD860289d6dafa733c85a58D9f11707838687d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21463/thumb/quizdrop.PNG?1639292994',
            'coingeckoId': 'quizdrop',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MVC',
            'name': 'Multiverse Capital',
            'address': '0x80d04E44955AA9c3F24041B2A824A20A88E735a8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21172/thumb/mvc.PNG?1638439038',
            'coingeckoId': 'multiverse-capital',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'LUCK',
            'name': 'LULU Market Luck',
            'address': '0x80F2C1E25391bbe615EF1F5cE82297fb0A624cb7',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26619/thumb/lucktoken_200.png?1659073809',
            'coingeckoId': 'lulu-market-luck',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASR',
            'name': 'AS Roma',
            'address': '0x80D5f92C2c8C682070C95495313dDB680B267320',
            'decimals': 2,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x80D5f92C2c8C682070C95495313dDB680B267320/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'RPP',
            'name': 'Red Pill',
            'address': '0x80D3A1226FE1a185834e8CB44C66d5F7859a03d1',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25125/thumb/Lkf2VKY.jpeg?1650372489',
            'coingeckoId': 'red-pill',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LIF3',
            'name': 'LIF3',
            'address': '0x80D2Fe89b6C6c24edfB553DAF35599649AC55283',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SLEEPEE',
            'name': 'SleepFuture',
            'address': '0x80CD73baDb406eA36B9a7CDeb8df06AeFa7E12d9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25469/thumb/sleepee.png?1651916574',
            'coingeckoId': 'sleepfuture',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HES',
            'name': 'Hesman Shard',
            'address': '0x80A466Cea081bDd8E9c3Be80268Aad16915D03D0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25782/thumb/hes.png?1653737462',
            'coingeckoId': 'hesman-shard',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XMPT',
            'name': 'XiamiPool',
            'address': '0x8099C0c7b3E530F563d4B121Abd2Ee365c72fB78',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15827/thumb/xiamipool.jpeg?1622023858',
            'coingeckoId': 'xiamipool',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KODA',
            'name': 'Koda Cryptocurrency',
            'address': '0x8094e772fA4A60bdEb1DfEC56AB040e17DD608D5',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15577/thumb/koda.png?1639224696',
            'coingeckoId': 'koda-finance',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'BABYFLOKICOIN',
            'name': 'Baby Floki Coin',
            'address': '0x808fac147a9C02723d0BE300AC4753eAf93C0e1F',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18882/thumb/n8hZe-5I_400x400.jpg?1633693483',
            'coingeckoId': 'baby-floki-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MFI',
            'name': 'MetaFinance',
            'address': '0x808f1350dff684C099F4837A01D863fC61A86BC6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17365/thumb/meta.PNG?1627435447',
            'coingeckoId': 'metafinance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CLOAK',
            'name': 'Cloak Coin',
            'address': '0x8077398Ff2c530f129a6dd8D7F1E8840312440CD',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27483/thumb/IMG_20220926_114311_979.jpg?1664249259',
            'coingeckoId': 'cloak-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFEMOON',
            'name': 'SafeMoon  OLD ',
            'address': '0x8076C74C5e3F5852037F31Ff0093Eeb8c8ADd8D3',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x8076C74C5e3F5852037F31Ff0093Eeb8c8ADd8D3/logo.png',
            'coingeckoId': 'safemoon',
            'listedIn': [
                'coingecko',
                'elkfinance'
            ]
        },
        {
            'symbol': 'COCN',
            'name': 'Coca Network',
            'address': '0x806337d163e420A9F8852A4682641956Febe756e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28179/thumb/cocanetworklogo_%281%29.png?1668253522',
            'coingeckoId': 'coca-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EARNY',
            'name': 'EarnyTV',
            'address': '0x805eDdD644c08E4dF3acfd2C41b2cE9d2CA1cd44',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27264/thumb/IMG-20220913-112019-700.jpg?1663059720',
            'coingeckoId': 'earnytv',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATLAS',
            'name': 'The Atlas Coin',
            'address': '0x804c1d39789403aC52C42B2aCc999BacF929d778',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27096/thumb/C31C7BC1-4D4D-461A-A084-452B64C8648D.jpeg?1661998449',
            'coingeckoId': 'the-atlas-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAWA',
            'name': 'SAWA Crypto',
            'address': '0x804E66126dAd4b0E6d639E75399a557F3f287942',
            'decimals': 12,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27996/thumb/SAWA-logo-200x200.png?1666923456',
            'coingeckoId': 'sawa-crypto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MCT',
            'name': 'MicroTuber',
            'address': '0x8038B1F3EB4f70436569618530Ac96b439D67bAe',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15489/thumb/mct.PNG?1621040638',
            'coingeckoId': 'microtuber',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CORGI',
            'name': 'Corgidoge',
            'address': '0x802C68730212295149f2bEa78C25e2Cf5A05B8A0',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17156/thumb/corgi-200.png?1626671116',
            'coingeckoId': 'corgidoge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARI10',
            'name': 'Ari10',
            'address': '0x80262F604acAC839724F66846F290A2cC8b48662',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19904/thumb/ari10.PNG?1636100074',
            'coingeckoId': 'ari10',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SEOR',
            'name': 'SEOR Network',
            'address': '0x800a25741A414Ea6E6e2B382435081A479A8Cc3c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24397/thumb/-yycZmh7_400x400.png?1647523110',
            'coingeckoId': 'seor-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MNSTP',
            'name': 'Moon Stop',
            'address': '0x7fdbd2fab9716902AA40C3bF7Da1CF71b700A143',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15865/thumb/MNSTP.jpg?1622174103',
            'coingeckoId': 'moon-stop',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTE',
            'name': 'Betero',
            'address': '0x7faAf8d4C411218415d9d3f82D56214658349dbb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25153/thumb/B_green200.png?1650461529',
            'coingeckoId': 'betero',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ZOO',
            'name': 'CryptoZoo',
            'address': '0x7fFC1243232da3Ac001994208E2002816b57c669',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1636341597801_12924766999006598.png',
            'coingeckoId': 'cryptozoo',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'DREAMN',
            'name': 'DreamN',
            'address': '0x7fCa026d67FEec1C7Cf88ab866D2678dFB2B5243',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25606/thumb/20152.png?1652777187',
            'coingeckoId': 'dreamn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPL',
            'name': 'Splash',
            'address': '0x7fC766BAF71aBa5C7835975Bbdf315bf0BEd3289',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20900/thumb/NKh15jtQ_400x400.jpg?1637878654',
            'coingeckoId': 'splash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FIRE',
            'name': 'FireFlame Inu',
            'address': '0x7fA6f63B11CC1af508cD19Ba823853d6aaCd9d65',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21151/thumb/DtRxOs2y_400x400.jpg?1638411791',
            'coingeckoId': 'fireflame-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CCF',
            'name': 'Cross Chain Farming',
            'address': '0x7f9528b913A99989B88104b633D531241591A358',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20852/thumb/logo.jpg?1638409405',
            'coingeckoId': 'cross-chain-farming',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SKI',
            'name': 'Skillchain',
            'address': '0x7f692c05058f1C77c87413a0591c7a237090DA00',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/3780/thumb/SKI.png?1589121168',
            'coingeckoId': 'skillchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPU',
            'name': 'SpacePort Universe',
            'address': '0x7f60375245cbF30A4F1FFd1278e3601FadCa2C4D',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18873/thumb/jzyaJaAh.jpg?1633656755',
            'coingeckoId': 'spaceport-universe',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WSBT',
            'name': 'WSB sh',
            'address': '0x7f4e04aA61B9a46403c1634E91Bf31Df3Bc554CF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23920/thumb/_SjqfFvj_400x400.png?1645686742',
            'coingeckoId': 'wsb-sh',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GREATAPE',
            'name': 'Great Ape',
            'address': '0x7f4a15F5cf12E1650f090FB7Bc7B0f240f1bDe98',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16224/thumb/Q2YwSCx8_400x400.jpg?1623336236',
            'coingeckoId': 'great-ape',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WHIRL',
            'name': 'Whirl Finance',
            'address': '0x7f479d78380Ad00341fdD7322fE8AEf766e29e5A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x7f479d78380ad00341fdd7322fe8aef766e29e5a.png',
            'coingeckoId': 'whirl-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ABZ',
            'name': 'AstroBirdz',
            'address': '0x7f3E9bdB55a0fA72BD6025C0ee1dfC3276cE3CF9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22242/thumb/eagle_logo-01.png?1644981501',
            'coingeckoId': 'astrobirdz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TNODE',
            'name': 'Trusted Node',
            'address': '0x7f12A37B6921FFac11FAB16338B3ae67EE0c462b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20534/thumb/trustednode.png?1637202258',
            'coingeckoId': 'trusted-node',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'KOJI',
            'name': 'Koji',
            'address': '0x7eb567F5c781EE8e47C7100DC5046955503fc26A',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16124/thumb/koji-token-200.png?1623039354',
            'coingeckoId': 'koji',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MELT',
            'name': 'MetaElfLand Token',
            'address': '0x7eb35225995b097c84eF10501dD6E93A49bdFd63',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26750/thumb/21276.png?1660017496',
            'coingeckoId': 'metaelfland-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NSI',
            'name': 'nSights',
            'address': '0x7eFb55D9AC57B23Cc6811c9068db3CF83CBDfe39',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19607/thumb/nsi.png?1635641149',
            'coingeckoId': 'nsights',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GSTS',
            'name': 'Gunstar Metaverse',
            'address': '0x7eDC0eC89F987ECd85617b891c44fE462a325869',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21079/thumb/gst.png?1639046007',
            'coingeckoId': 'gunstar-metaverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PRM',
            'name': 'Primal',
            'address': '0x7eBC642573f8E973198972746c28454ba9dE71D8',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28018/thumb/895kAFqE_400x400.jpeg?1666950174',
            'coingeckoId': 'primal-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRDC',
            'name': 'Traders Coin',
            'address': '0x7e8DB69dcff9209E486a100e611B0af300c3374e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18675/thumb/logo-200x200.png?1633360593',
            'coingeckoId': 'traders-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POLS',
            'name': 'Polkastarter',
            'address': '0x7e624FA0E1c4AbFD309cC15719b7E2580887f570',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x7e624fa0e1c4abfd309cc15719b7e2580887f570.png',
            'coingeckoId': 'polkastarter',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'SYL',
            'name': 'XSL Labs',
            'address': '0x7e52a123Ed6DB6Ac872A875552935fBbD2544c86',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x7e52a123ed6db6ac872a875552935fbbd2544c86.png',
            'coingeckoId': 'xsl-labs',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SHIBACASH',
            'name': 'ShibaCash',
            'address': '0x7e4e3BA4675c39FF2863073e171b0a2E93A592E0',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16010/thumb/shibacash_logo200.png?1622600064',
            'coingeckoId': 'shibacash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ABST',
            'name': 'Abitshadow',
            'address': '0x7e46D5EB5b7Ca573B367275fEe94aF1945f5b636',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/7882/thumb/ABST.png?1644477172',
            'coingeckoId': 'abitshadow-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WMASS',
            'name': 'Wrapped MASS',
            'address': '0x7e396BfC8a2f84748701167c2d622F041A1D7a17',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x7e396BfC8a2f84748701167c2d622F041A1D7a17/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ORME',
            'name': 'Ormeus Coin',
            'address': '0x7e2AFE446A30fA67600a5174Df7f4002B8E15B03',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/964/thumb/ORMEUS_logo.png?1606557243',
            'coingeckoId': 'ormeuscoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AIRI',
            'name': 'aiRight',
            'address': '0x7e2A35C746F2f7C240B664F1Da4DD100141AE71F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16428/thumb/alright.PNG?1623995586',
            'coingeckoId': 'airight',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VOLT',
            'name': 'Volt Inu',
            'address': '0x7db5af2B9624e1b3B4Bb69D6DeBd9aD1016A58Ac',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x7db5af2b9624e1b3b4bb69d6debd9ad1016a58ac.png',
            'coingeckoId': 'volt-inu-2',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'STATIC',
            'name': 'ChargeDeFi Static',
            'address': '0x7dEb9906BD1d77B410a56E5C23c36340Bd60C983',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21404/thumb/1_Ve6PPOEmEu1LRjg5loJkkQ.png?1639087027',
            'coingeckoId': 'chargedefi-static',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'GALA',
            'name': 'pTokens GALA',
            'address': '0x7dDEE176F665cD201F93eEDE625770E2fD911990',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12493/small/GALA-COINGECKO.png?1600233435',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'RTW',
            'name': 'RETAWATCH',
            'address': '0x7dC27Fc31924465fAA76Cb7CabF7c24e85372d03',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26749/thumb/rtw.png?1660311428',
            'coingeckoId': 'retawatch',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IBT',
            'name': 'International Blockchain Technology',
            'address': '0x7dAcc2327528A99aa1De0C1F757539A9A2380c04',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25695/thumb/%D0%97%D0%B0%D0%BB%D0%B8%D0%B2%D0%BA%D0%B0_%D0%BB%D0%BE%D0%B3%D0%BE.png?1654157089',
            'coingeckoId': 'international-blockchain-technology',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEPO',
            'name': 'DePocket',
            'address': '0x7d99eda556388Ad7743A1B658b9C4FC67D7A9d74',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21082/thumb/EnjwdEis_400x400.png?1638310364',
            'coingeckoId': 'depocket',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'BSC',
            'name': 'BABYSOCCER',
            'address': '0x7d95c863cfc17c640C5800c0B72a999B4ecBC8da',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x7d95c863cfc17c640c5800c0b72a999b4ecbc8da/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BETHER',
            'name': 'Baby Ether',
            'address': '0x7d8D4599d6b4066ebA50544DB2068465C8A14809',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x7d8d4599d6b4066eba50544db2068465c8a14809/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'INUB',
            'name': 'Inu Base',
            'address': '0x7d6c3F7aAAF01A91574c82B82E57B1F64fF48039',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x7d6c3f7aaaf01a91574c82b82e57b1f64ff48039/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SEKTOR',
            'name': 'Sektor Token',
            'address': '0x7d59368eb0C23d299ebe12ebcA58eaE59f9fE028',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26746/thumb/sektor_logo-removebg-preview.png?1660012759',
            'coingeckoId': 'sektor-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ECOIN',
            'name': 'Ecoin Finance',
            'address': '0x7d38315b92d0E7A85B35B2b7FE969B25935619D7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15901/thumb/ecoin.png?1648148099',
            'coingeckoId': 'ecoin-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ACCG',
            'name': 'Australian Crypto Coin Green',
            'address': '0x7d2a776a051D49FF77EC73323495f68F1231B792',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23744/thumb/accg.png?1645272812',
            'coingeckoId': 'australian-crypto-coin-green',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WEB5',
            'name': 'WEB5 Inu',
            'address': '0x7d220240Cf958C5c47f2DAAC821dB965f9837e82',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27027/thumb/IMG_20220812_165146_695.png?1661487767',
            'coingeckoId': 'web5-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DADDYDOGE',
            'name': 'Daddy Doge',
            'address': '0x7ccE94C0B2C8aE7661f02544E62178377Fe8cF92',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16931/thumb/icon-200x200_%281%29.png?1625650538',
            'coingeckoId': 'daddy-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JCHF',
            'name': 'Jarvis Synthetic Swiss Franc',
            'address': '0x7c869b5A294b1314E985283d01C702B62224a05f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15727/thumb/jCHF.png?1634046084',
            'coingeckoId': 'jarvis-synthetic-swiss-franc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CBT',
            'name': 'CyBall CyBloc Battery',
            'address': '0x7c73967dC8c804Ea028247f5A953052f0CD5Fd58',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24167/thumb/CBT_200x200.png?1646714918',
            'coingeckoId': 'cybloc-battery-token',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'RBT',
            'name': 'Run Burn',
            'address': '0x7c6663367f1a5fafFF37Ec36290Ccd5FE67f2BDf',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28139/thumb/Untitled_design_%283%29.png?1667789651',
            'coingeckoId': 'run-burn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAG',
            'name': 'Blockchain Adventurers Guild',
            'address': '0x7c650f39d777F40120345314Ab8009D11F70c972',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14863/thumb/3iw7MAi.png?1618810870',
            'coingeckoId': 'blockchain-adventurers-guild',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASS',
            'name': 'Australian Safe Shepherd',
            'address': '0x7c63F96fEAFACD84e75a594C00faC3693386FBf0',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14912/thumb/XCsenIJ.png?1618988361',
            'coingeckoId': 'australian-safe-shepherd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '1MT',
            'name': '1Move Token',
            'address': '0x7c56D81EcB5E1d287a1E22B89b01348f07bE3541',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27347/thumb/1MT.png?1663654594',
            'coingeckoId': '1move token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CZF',
            'name': 'CZFarm',
            'address': '0x7c1608C004F20c3520f70b924E2BfeF092dA0043',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x7c1608c004f20c3520f70b924e2bfef092da0043.png',
            'coingeckoId': 'czfarm',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BR',
            'name': 'BoHr',
            'address': '0x7be9E596896b64c88E39b7e88F8dceDDa79845C0',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25451/thumb/jMTfKKWx_400x400.jpg?1651803437',
            'coingeckoId': 'bohr-2',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ELON',
            'name': 'Dogelon Mars',
            'address': '0x7bd6FaBD64813c48545C9c0e312A0099d9be2540',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x7bd6FaBD64813c48545C9c0e312A0099d9be2540.png',
            'coingeckoId': 'dogelon-mars',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'BAFE',
            'name': 'Bafe io',
            'address': '0x7bb362962F2a4E2089466C79566572B37CFc5D78',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15120/thumb/bafeio_logo.png?1619753992',
            'coingeckoId': 'bafe-io',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LIME',
            'name': 'iMe Lab',
            'address': '0x7bC75e291E656E8658D66Be1cc8154A3769A35Dd',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x7bc75e291e656e8658d66be1cc8154a3769a35dd.png',
            'coingeckoId': 'ime-lab',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'STICKY',
            'name': 'FlyPaper',
            'address': '0x7bA953183FE5D8169924da516F1b3CaC365adD78',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18610/thumb/kjJCd8WN_400x400.jpg?1632667921',
            'coingeckoId': 'flypaper',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AZY',
            'name': 'Amazy',
            'address': '0x7b665B2F633d9363b89A98b094B1F9E732Bd8F86',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26315/thumb/photo_2022-07-07_12-38-02.jpg?1657240464',
            'coingeckoId': 'amazy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IRON',
            'name': 'Iron BSC',
            'address': '0x7b65B489fE53fCE1F6548Db886C08aD73111DDd8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x7b65b489fe53fce1f6548db886c08ad73111ddd8.png',
            'coingeckoId': 'iron-bsc',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'OSEA',
            'name': 'Omnisea',
            'address': '0x7b610012bDC4D6DeBa2C2D91684e408f40863429',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26475/thumb/293837892_407994084681555_3167689470652146992_n.png?1658195766',
            'coingeckoId': 'omnisea',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABYPIG',
            'name': 'Baby Pig',
            'address': '0x7b0b4C304B9149B692c1Acbc9dB4F4a1c17B2B91',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16990/thumb/babypig.png?1625983125',
            'coingeckoId': 'baby-pig-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SIGNA',
            'name': 'Signum',
            'address': '0x7b0E7E40eE4672599F7095D1DdD730b0805195BA',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/154/thumb/SIGNA.png?1625033010',
            'coingeckoId': 'signum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRADE',
            'name': 'Unitrade',
            'address': '0x7af173F350D916358AF3e218Bdf2178494Beb748',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x7af173f350d916358af3e218bdf2178494beb748.png',
            'coingeckoId': 'unitrade',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'JADE',
            'name': 'Jade Protocol',
            'address': '0x7ad7242A99F21aa543F9650A56D141C57e4F6081',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1641122938688_09118434845434131.png',
            'coingeckoId': 'jade-protocol',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ALM',
            'name': 'Allium Finance',
            'address': '0x7ac8A920CF1F7E7CC4f698c9C5cBC1E26F604790',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15552/thumb/idEZxWx.png?1621202117',
            'coingeckoId': 'allium-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MMETA',
            'name': 'Duckie Land Multi Metaverse',
            'address': '0x7a9c8d33963AeCcA9a821802Adfaf5Bd9392351F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24186/thumb/MMETA_200X200.png?1646808063',
            'coingeckoId': 'duckie-land-multi-metaverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DBZ',
            'name': 'Diamond Boyz Coin',
            'address': '0x7a983559e130723B70e45bd637773DbDfD3F71Db',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17308/thumb/IMG_0026.png?1627271288',
            'coingeckoId': 'diamond-boyz-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GMT',
            'name': 'GreekMythology',
            'address': '0x7a59cE9c4248F8B078Fd96D9965E6E5dC8e6F0a8',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24042/thumb/uxr1prT.png?1646138792',
            'coingeckoId': 'greekmythology',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CROWN',
            'name': 'KingPad',
            'address': '0x7a3bA320d44192ae9f6C061F15bCEbd7a6217242',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21182/thumb/IMG-20211202-223646-744.jpg?1638506560',
            'coingeckoId': 'kingpad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LASEREYES',
            'name': 'Laser Eyes',
            'address': '0x7a0Fc0f70A3028e4F6d6F61d5f0DFBC2aA300e5e',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17955/thumb/lasereyes.png?1629900244',
            'coingeckoId': 'laser-eyes',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STZ',
            'name': '99Starz',
            'address': '0x7FE378c5e0B5C32AF2ecc8829beDF02245A0E4eF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21467/thumb/stz.png?1639651623',
            'coingeckoId': '99starz',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'DDOS',
            'name': 'disBalancer',
            'address': '0x7FBEC0bb6A7152e77C30D005B5D49cbC08A602C3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14791/thumb/communityIcon_o2yriheuszk61.png?1618463138',
            'coingeckoId': 'disbalancer',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WAG',
            'name': 'WagyuSwap',
            'address': '0x7FA7dF4996AC59F398476892cfB195eD38543520',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18225/thumb/maoZ8h22_400x400.jpg?1631017339',
            'coingeckoId': 'wagyuswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WSN',
            'name': 'WallStreetNinja',
            'address': '0x7FA4CD8AeEdCb8d36DBC5d856E3A1BEe490D7b36',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21343/thumb/WSN_200_x_200px.png?1638966870',
            'coingeckoId': 'wallstreetninja',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YFII',
            'name': 'YFII.finance Token',
            'address': '0x7F70642d88cf1C4a3a7abb072B53B929b653edA5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x7F70642d88cf1C4a3a7abb072B53B929b653edA5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SHARO',
            'name': 'CrazySharo',
            'address': '0x7F3dAf301c629BfA243CbbA6654370d929379657',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27803/thumb/logosharo.png?1665902376',
            'coingeckoId': 'crazysharo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'wsSPA',
            'name': 'Wrapped sSPA',
            'address': '0x7EfabC2c92b20E0987Af979AE9F87d508C5da1C9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MOONRISE',
            'name': 'MoonRise',
            'address': '0x7Ee7F14427cC41D6dB17829eb57Dc74A26796b9D',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x7ee7f14427cc41d6db17829eb57dc74a26796b9d.png',
            'coingeckoId': 'moonrise',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'RVL',
            'name': 'REVIVAL',
            'address': '0x7EaeE60040135F20f508A393ca400dEd339d654e',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18661/thumb/rvl.png?1634020528',
            'coingeckoId': 'revival',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OMNIA',
            'name': 'OmniaVerse',
            'address': '0x7E6a1299Ae38b796404eE0d771B9eBc5Fa535e7D',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25543/thumb/OMNIA.png?1652266335',
            'coingeckoId': 'omniaverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OVR',
            'name': 'Ovr',
            'address': '0x7E35D0e9180bF3A1fc47b0d110bE7a21A10B41Fe',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13429/thumb/ovr_logo.png?1608518911',
            'coingeckoId': 'ovr',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'JOLT',
            'name': 'Joltify',
            'address': '0x7Db21353A0c4659B6A9a0519066aa8D52639Dfa5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25262/thumb/NSj-jWHR_400x400.jpg?1651044237',
            'coingeckoId': 'joltify',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RVZ',
            'name': 'Revoluzion',
            'address': '0x7D89c67d3c4E72E8c5c64BE201dC225F99d16aCa',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x7d89c67d3c4e72e8c5c64be201dc225f99d16aca.png',
            'coingeckoId': 'revoluzion',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BUGG',
            'name': 'BUGG Finance',
            'address': '0x7D62d515ca6dD901fC920e361559Fd394Eef3d52',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17408/thumb/bugg_finance.PNG?1627542965',
            'coingeckoId': 'bugg-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRPLAY',
            'name': 'CryptoSoul Roleplay',
            'address': '0x7D57760f68Ed2238E16A002D12F3a741d03f364b',
            'decimals': 10,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'OW',
            'name': 'OWGaming',
            'address': '0x7D2B2b15f1023425647626C4e411beA51F1aC0CD',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16400/thumb/nBEhjyh.png?1623914303',
            'coingeckoId': 'owgaming',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DKT',
            'name': 'Duelist King',
            'address': '0x7Ceb519718A80Dd78a8545AD8e7f401dE4f2faA7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x7Ceb519718A80Dd78a8545AD8e7f401dE4f2faA7.png',
            'coingeckoId': 'duelist-king',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'MEGALAND',
            'name': 'Metagalaxy Land',
            'address': '0x7CD8c22D3F4b66230F73D7FfCB48576233c3fe33',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20983/thumb/dud5mv4kNaj1PZwSQ3Eazt5voNIycBwyhJQFaXdE.png?1638165470',
            'coingeckoId': 'metagalaxy-land',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RXCG',
            'name': 'RXCGames',
            'address': '0x7C59A57fC16EaC270421B74615c4BC009eCd486d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20989/thumb/evyjvQB.jpeg?1638169876',
            'coingeckoId': 'rxcgames',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VITC',
            'name': 'Vitamin Coin',
            'address': '0x7C3cc93f39F0dbC9E00F96d1Fa4eA52E36b3476B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20661/thumb/vitc_200.png?1637545079',
            'coingeckoId': 'vitamin-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CTI',
            'name': 'ClinTex CTi',
            'address': '0x7C3B67B30eFBAcC8f787f7EBd3bdc65234299f4c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13266/thumb/CTI.png?1606817542',
            'coingeckoId': 'clintex-cti',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALM',
            'name': 'Alium Finance',
            'address': '0x7C38870e93A1f959cB6c533eB10bBc3e438AaC11',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15621/thumb/alium.png?1655449806',
            'coingeckoId': 'alium-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DGF',
            'name': 'Doge Gold Floki',
            'address': '0x7C22A844e3358DBB7c90B1A299E5C650B14CD21f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27398/thumb/200x200.png?1663827874',
            'coingeckoId': 'doge-gold-floki',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STAR',
            'name': 'Planet Alliance STAR DAO',
            'address': '0x7C1e899d9133f76c489cADa6F1CD2d47229B7685',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26559/thumb/star-dao-token-logo-200.png?1658797209',
            'coingeckoId': 'planet-alliance-star-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XPRO',
            'name': 'XPROJECT',
            'address': '0x7C1b2f618569789941B88680966333F3e8FEdc61',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26688/thumb/logo200x200circle.png?1659601728',
            'coingeckoId': 'xproject',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SVT',
            'name': 'SpaceVikings',
            'address': '0x7BA3cD00229C1beCC95a4B056fF15C123DcB456D',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15820/thumb/pf-46468a65--TRANS.png?1621984077',
            'coingeckoId': 'spacevikings',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IRISTOKEN',
            'name': 'Iris Ecosystem',
            'address': '0x7B9F36a2F331EcE03a7483D2713Cfd806f9bEef2',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26518/thumb/200x200.png?1658454212',
            'coingeckoId': 'iris-ecosystem',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IMT',
            'name': 'IMOV',
            'address': '0x7B8779e01d117ec7e220f8299a6f93672E8eae23',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26558/thumb/IMOV.png?1658796898',
            'coingeckoId': 'imov',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOLCASH',
            'name': 'SOLCash',
            'address': '0x7B86f5ca09Dc00502E342b0FeF5117E1c32222Ce',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21546/thumb/solcash.png?1639456567',
            'coingeckoId': 'solcash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BEM',
            'name': 'Bemil Coin',
            'address': '0x7B86b0836f3454e50C6F6a190cd692bB17da1928',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20896/thumb/12878.png?1637875878',
            'coingeckoId': 'bemil-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HOL',
            'name': 'Holiday',
            'address': '0x7B8656C95944f1d6e2E6dEaDeDD0392A4138D8dd',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20720/thumb/hol.png?1638412193',
            'coingeckoId': 'holiday-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HBX',
            'name': 'Habits',
            'address': '0x7B720b04F238A4F8D84F7Bbb4b6fe3dA01e6D344',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15707/thumb/habit.PNG?1621580913',
            'coingeckoId': 'habits',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REWARDS',
            'name': 'Rewards Token',
            'address': '0x7B5B3BC6890c1dE78FeDE739C1b02D2e51B1b58c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17563/thumb/rewards.PNG?1628284532',
            'coingeckoId': 'rewards-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOGECO',
            'name': 'Dogecolony',
            'address': '0x7B1Ebc0C4BC34964A0673cBEEF4e1dE868E8A8b6',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23432/thumb/doge-colony-200.png?1644210500',
            'coingeckoId': 'dogecolony',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RUPEE',
            'name': 'HyruleSwap',
            'address': '0x7B0409A3A3f79bAa284035d48E1DFd581d7d7654',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14760/thumb/2_%284%29.png?1618274227',
            'coingeckoId': 'hyruleswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MILE',
            'name': 'milestoneBased',
            'address': '0x7AeD3E61E67EE1bd10f441f01bf261f6e1c72355',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24690/thumb/MILE.png?1648611058',
            'coingeckoId': 'milestonebased',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOGEDASH',
            'name': 'Doge Dash',
            'address': '0x7AE5709c585cCFB3e61fF312EC632C21A5F03F70',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18993/thumb/pBqlzRVx_400x400.jpg?1634125559',
            'coingeckoId': 'doge-dash',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'WATCH',
            'name': 'Yieldwatch',
            'address': '0x7A9f28EB62C791422Aa23CeAE1dA9C847cBeC9b0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x7a9f28eb62c791422aa23ceae1da9c847cbec9b0.png',
            'coingeckoId': 'yieldwatch',
            'listedIn': [
                'coingecko',
                '1inch',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GMFLOKI',
            'name': 'GM Floki',
            'address': '0x7A9a219A23B3a9f748f087B033AF397FBf16030c',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22755/thumb/N-0aM7p6_400x400.jpg?1642573131',
            'coingeckoId': 'gm-floki',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CLIST',
            'name': 'Chainlist',
            'address': '0x7A685C6DBd6c15e6D600f7c713f9252EBb3C472a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20733/thumb/F0fir0BN_400x400.jpg?1637617857',
            'coingeckoId': 'chainlist',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOGEZILLA',
            'name': 'DogeZilla',
            'address': '0x7A565284572d03EC50c35396F7d6001252eb43B6',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19331/thumb/dogezilla.PNG?1635115275',
            'coingeckoId': 'dogezilla',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'WOLFGIRL',
            'name': 'Wolf Girl',
            'address': '0x7A3e66DAD59b99404dC28D48767B7528301318F6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20099/thumb/wolfgirl.png?1636510043',
            'coingeckoId': 'wolf-girl',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OPTCM',
            'name': 'Optimus',
            'address': '0x7A2277F34f275dED630deFF758FBC818409Ca36D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21678/thumb/rsz_logo%281%29.png?1639712718',
            'coingeckoId': 'optimus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MPRO',
            'name': 'Manager PRO',
            'address': '0x79fDe167C18C51892BE4B559b60d9420c02afd05',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18996/thumb/Backup_of_mpro_banner_logo_%282%29.png?1634126403',
            'coingeckoId': 'manager-pro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTCI',
            'name': 'Bitcoin International',
            'address': '0x79f8E9862c5240F316faBf31e5406e497d65484d',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19126/thumb/btci.png?1634521957',
            'coingeckoId': 'bitcoin-international',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CATGIRL',
            'name': 'Catgirl',
            'address': '0x79eBC9A2ce02277A4b5b3A768b1C0A4ed75Bd936',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16472/thumb/catgirl-200x200.png?1626961369',
            'coingeckoId': 'catgirl',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SCT',
            'name': 'So Cal',
            'address': '0x79D8C48DA6E3eC769A4d2097A961a9B7f00830BE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26118/thumb/iShot_2022-07-12_16.20.13.png?1657614025',
            'coingeckoId': 'so-cal',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOTC',
            'name': 'Polka Classic',
            'address': '0x79Ca48c3F6bfB3eF284b4d4C39cA51286830f9aE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22307/thumb/Polka-Classic.png?1641446596',
            'coingeckoId': 'polka-classic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POM',
            'name': 'Pomeranian',
            'address': '0x79A9b1E4298aC4A544608ff7715460b92B936C43',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20739/thumb/UxLFl_pv_400x400.jpg?1637619880',
            'coingeckoId': 'pomeranian',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MCONTENT',
            'name': 'MContent',
            'address': '0x799e1Cf88A236e42b4A87c544A22A94aE95A6910',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16781/thumb/Image_mcontent.jpeg?1653476766',
            'coingeckoId': 'mcontent',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VLK',
            'name': 'Vulkania',
            'address': '0x797Bb0BeeA437D2762A755ea911C0046C1284568',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25424/thumb/7DBYi_Zc_400x400.jpg?1651737050',
            'coingeckoId': 'vulkania-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KEBAB',
            'name': 'Kebab Token',
            'address': '0x7979F6C54ebA05E18Ded44C4F986F49a5De551c2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x7979f6c54eba05e18ded44c4f986f49a5de551c2.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ODL',
            'name': 'One Dollar',
            'address': '0x7978e2Cc23944478CbAe2ada925C79637860A218',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x7978e2cc23944478cbae2ada925c79637860a218/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'LION',
            'name': 'Lion',
            'address': '0x7969Dc3C6e925bccbEA9f7FC466a63C74F0115B8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15314/thumb/lion.PNG?1620376502',
            'coingeckoId': 'lion-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QUIDD',
            'name': 'Quidd',
            'address': '0x7961Ade0a767c0E5B67Dd1a1F78ba44F727642Ed',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x7961Ade0a767c0E5B67Dd1a1F78ba44F727642Ed.png',
            'coingeckoId': 'quidd',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'UPSHIB',
            'name': 'upShib',
            'address': '0x795D38678D5a3f7Ad80DDED5e27644e9c0352904',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15533/thumb/upshib.png?1621153135',
            'coingeckoId': 'upshib',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GWP',
            'name': 'Gateway Protocol',
            'address': '0x794a23B6b8A543b58f995ba590979e0785b60EB2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24093/thumb/gwp.PNG?1646353570',
            'coingeckoId': 'gateway-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YIN',
            'name': 'YIN Finance',
            'address': '0x794Baab6b878467F93EF17e2f2851ce04E3E34C8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18272/thumb/e37387ae6ee756fd.jpg?1631176921',
            'coingeckoId': 'yin-finance',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'PSBD',
            'name': 'Pet Store',
            'address': '0x792F42CE6e037fF63cA74AEB8a0FA95bD316902b',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27217/thumb/Pet-Store-LOGO.png?1662613576',
            'coingeckoId': 'pet-store',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CTC',
            'name': 'CTC',
            'address': '0x7921f18D516b42fc8Bf26c13c363cAc63C22f3C4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25124/thumb/6gPENcou_400x400.jpg?1650372308',
            'coingeckoId': 'ctc-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PLR',
            'name': 'Pillar',
            'address': '0x790CFDc6aB2e0eE45a433aAC5434F183BE1f6A20',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/809/thumb/v2logo-1.png?1624906282',
            'coingeckoId': 'pillar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BREW',
            'name': 'CafeSwap',
            'address': '0x790Be81C3cA0e53974bE2688cDb954732C9862e1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0x790be81c3ca0e53974be2688cdb954732c9862e1.png',
            'coingeckoId': 'cafeswap-token',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'MAOE',
            'name': 'Meta Age of Empires',
            'address': '0x7909111BfABA55EbCD88051ca9Dff0c4d93EACe5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25172/thumb/200x200-03_%281%29.png?1650526360',
            'coingeckoId': 'meta-age-of-empires',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BCW',
            'name': 'Bit Castle War',
            'address': '0x7907D16318A2936c94A808c5e222A734D4FF8CBE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25249/thumb/photo_2022-03-01_19-46-40.jpg?1651038634',
            'coingeckoId': 'bit-castle-war',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CFL',
            'name': 'Crypto Fantasy League',
            'address': '0x78ebC325641c9dB284Fc0E69305aFbc0C92AB475',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20220/thumb/6kwnmdRK_400x400.jpg?1636675534',
            'coingeckoId': 'crypto-fantasy-league',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JNTR/b',
            'name': 'JNTR/b',
            'address': '0x78e1936f065Fd4082387622878C7d11c9f05ECF4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0x78e1936f065fd4082387622878c7d11c9f05ecf4.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'HAREPLUS',
            'name': 'Hare Plus',
            'address': '0x78c6E90aFa87E2C97cac1A07652Aa0998C6C9078',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23963/thumb/200.png?1646664374',
            'coingeckoId': 'hare-plus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MGMOON',
            'name': 'MegaMoon',
            'address': '0x78b971e8B6B5A5F2Cc5feBB8aB9F046037C2107C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23977/thumb/mgmoon.png?1645872642',
            'coingeckoId': 'megamoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APX',
            'name': 'ApolloX',
            'address': '0x78F5d389F5CDCcFc41594aBaB4B0Ed02F31398b3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x78F5d389F5CDCcFc41594aBaB4B0Ed02F31398b3.png',
            'coingeckoId': 'apollox-2',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'CSTC',
            'name': 'CryptosTribe',
            'address': '0x78F1a611cceba2fF17EA53570DBee7D43629E8bc',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26509/thumb/index_one.db763c5.png?1658449567',
            'coingeckoId': 'cryptostribe',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HERO',
            'name': 'BinaHero',
            'address': '0x78C3AdE403438B37638304F9FbbFB1b76608c1BD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21890/thumb/binahero.jpg?1640248087',
            'coingeckoId': 'binahero',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VSL',
            'name': 'Vetter Skylabs',
            'address': '0x78B2425Fc305c0266143eaA527b01B043af83FB8',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28208/thumb/Vetter-Skylabs-200x200.png?1668417213',
            'coingeckoId': 'vetter-skylabs',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GEMS',
            'name': 'GemPad',
            'address': '0x78Aae7e000Bf6FC98a6b717d5eC8Ef2bcd04F428',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25319/thumb/KQLfrRw.png?1651217242',
            'coingeckoId': 'gempad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CINO',
            'name': 'Cino Games',
            'address': '0x78A9B17A3162533004EB7110b3F1f344a3ff851d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24157/thumb/cino.png?1646575256',
            'coingeckoId': 'cino-games',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JOJO',
            'name': 'JOJO',
            'address': '0x78A499a998Bdd5a84CF8b5aBe49100D82DE12f1C',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17383/thumb/JOJO.png?1627462497',
            'coingeckoId': 'jojo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPI',
            'name': 'Shopping io OLD',
            'address': '0x78A18Db278F9c7c9657F61dA519e6Ef43794DD5D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13537/thumb/SPI_Token.png?1623763713',
            'coingeckoId': 'shopping-io',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOCIN',
            'name': 'Soccer Infinity',
            'address': '0x788db23BAA806C0a1b2AF312dD9A6087dd02EF89',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20580/thumb/hrdvdur.png?1637248194',
            'coingeckoId': 'soccer-infinity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'S4F',
            'name': 'S4FE',
            'address': '0x788D2780992222360f674cc12C36478870b8E6ED',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/7405/thumb/logo_%284%29.png?1547085640',
            'coingeckoId': 's4fe',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MASH',
            'name': 'MarshmallowDeFi',
            'address': '0x787732f27D18495494cea3792ed7946BbCFF8db2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14893/thumb/gF1MHZw.png?1618912293',
            'coingeckoId': 'marshmellowdefi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JDB',
            'name': 'Jeet Detector Bot',
            'address': '0x7874CAFf04AFB8B6f5cbBE3ebec3f83Fcd882272',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28105/thumb/LOGO_CG_CMC_bright_round_200px.png?1669168429',
            'coingeckoId': 'jeet-detector-bot',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTCST',
            'name': 'BTC Standard Hashrate Token',
            'address': '0x78650B139471520656b9E7aA7A5e9276814a38e9',
            'decimals': 17,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x78650b139471520656b9e7aa7a5e9276814a38e9.png',
            'coingeckoId': 'btc-standard-hashrate-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'XMS',
            'name': 'Mars Ecosystem',
            'address': '0x7859B01BbF675d67Da8cD128a50D155cd881B576',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x7859B01BbF675d67Da8cD128a50D155cd881B576.png',
            'coingeckoId': 'mars-ecosystem-token',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'BIC',
            'name': 'Beagle Inu',
            'address': '0x785700BC30De3c9A8F1dCD5CDB2652150f36ECd5',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27167/thumb/3_%282%29_%283%29.png?1662285651',
            'coingeckoId': 'beagle-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RXC',
            'name': 'Ran x Crypto',
            'address': '0x784a5211B47ff615A19797cdbF121D0AA04D37F0',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20949/thumb/ofJzS3VY_400x400.jpg?1638102159',
            'coingeckoId': 'ran-x-crypto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TKP',
            'name': 'TOKPIE',
            'address': '0x7849ed1447250d0B896f89b58f3075B127ca29B3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/3731/thumb/tokpie-200x200.png?1562207865',
            'coingeckoId': 'tokpie',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SGT',
            'name': 'SpaceGoat',
            'address': '0x783FE4a84645431b31B914b609b86127B96057ea',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16077/thumb/Space-Goat-200-x-200-01.png?1622772917',
            'coingeckoId': 'spacegoat-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOSE',
            'name': 'DOSE',
            'address': '0x7837fd820bA38f95c54D6dAC4ca3751b81511357',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18847/thumb/dose.PNG?1633590548',
            'coingeckoId': 'dose-token',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'LUNI',
            'name': 'Lady Uni',
            'address': '0x781dc2Aa26BE80b5De971e9a232046441b721B39',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21291/thumb/luni.png?1642836054',
            'coingeckoId': 'lady-uni',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WMT',
            'name': 'Wodex',
            'address': '0x780622E3043D329abd7B1fD522C9B6c877cfb8c0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21314/thumb/xn5gni.png?1638923560',
            'coingeckoId': 'wodex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CAS',
            'name': 'Cashaa',
            'address': '0x780207B8C0Fdc32cF60E957415bFa1f2d4d9718c',
            'decimals': 0,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/2979/thumb/Logo.png?1564502509',
            'coingeckoId': 'cashaa',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BNI',
            'name': 'Bitindi Chain',
            'address': '0x77fc65dedA64f0CCA9e3aEa7b9D8521f4151882e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27811/thumb/160.png?1665906992',
            'coingeckoId': 'bitindi-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LBL',
            'name': 'LABEL Foundation',
            'address': '0x77edFaE59a7948d66E9911A30cC787d2172343d4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19202/thumb/tele_profile_%EB%8C%80%EC%A7%80_1_-_%282%29.png?1634680838',
            'coingeckoId': 'label-foundation',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ERW',
            'name': 'ZeLoop Eco Reward',
            'address': '0x77e5Cce02139814E7efF377244cAC8B802cDdAb8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19781/thumb/4767_ZeLoop_Icon_PNG.png?1635842015',
            'coingeckoId': 'zeloop-eco-reward',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LAZIO',
            'name': 'Lazio Fan Token',
            'address': '0x77d547256A2cD95F32F67aE0313E450Ac200648d',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x77d547256A2cD95F32F67aE0313E450Ac200648d.png',
            'coingeckoId': 'lazio-fan-token',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'LRC',
            'name': 'Laro',
            'address': '0x77cCBBcA68958Ee7a1786090c1dcdD45b80DFdF0',
            'decimals': 2,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25925/thumb/lorologo.png?1654673196',
            'coingeckoId': 'laro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOFI',
            'name': 'LOFI',
            'address': '0x77F86D401e067365dD911271530B0c90DeC3e0f7/',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': 'lofi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COINMAMA',
            'name': 'MamaVerse',
            'address': '0x77A7c645144fC52faae64C72D60C8F9207ae2340',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21591/thumb/mVvHDCUs_400x400.jpg?1639545182',
            'coingeckoId': 'mamaverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TPAD',
            'name': 'TorkPad',
            'address': '0x77972A1BA3cAb14E1E907b58BBc4971544f4719d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27968/thumb/pSFtjSk.png?1666769630',
            'coingeckoId': 'torkpad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FIT',
            'name': 'Calo FIT',
            'address': '0x77922A521182a719A48ba650aC2A040269888888',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25937/thumb/FIT_200x200.png?1654759198',
            'coingeckoId': 'calo-fit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WEB',
            'name': 'Webcoin',
            'address': '0x7786B28826e2DDA4dBe344bE66A0bFbfF3d3362f',
            'decimals': 1,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/3779/thumb/webcoin-logo.png?1547038866',
            'coingeckoId': 'webcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HEGG',
            'name': 'Hummingbird Egg',
            'address': '0x778682C19797d985c595429FbC51D67736013A86',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18653/thumb/hegg_200.png?1663661021',
            'coingeckoId': 'hummingbird-egg-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TOTEM',
            'name': 'Cryptotem',
            'address': '0x777994409c6B7e2393F6098A33A9Cd8B7e9d0D28',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21315/thumb/QEHj_fZJ_400x400.jpg?1638924445',
            'coingeckoId': 'cryptotem',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SIG',
            'name': 'xSigma',
            'address': '0x7777777777697cFEECF846A76326dA79CC606517',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MGC',
            'name': 'Magic Manor',
            'address': '0x7773FeAF976599a9d6A3a7B5dc43d02AC166F255',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26539/thumb/OWFtnc0v_400x400.png?1658721308',
            'coingeckoId': 'magic-manor',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OCEANS',
            'name': 'Oceans Finance',
            'address': '0x7769d930BC6B087f960C5D21e34A4449576cf22a',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x7769d930bc6b087f960c5d21e34a4449576cf22a/logo.png',
            'coingeckoId': 'oceans-finance',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PRV',
            'name': 'PrivacySwap',
            'address': '0x7762A14082Ab475C06D3868B385E46aE27017231',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15384/thumb/Logo-Round.png?1622520779',
            'coingeckoId': 'privacyswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OKG',
            'name': 'Ookeenga',
            'address': '0x7758a52c1Bb823d02878B67aD87B6bA37a0CDbF5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27297/thumb/ookeenga_32.png?1663209031',
            'coingeckoId': 'ookeenga',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FNDZ',
            'name': 'FNDZ',
            'address': '0x7754c0584372D29510C019136220f91e25a8f706',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18386/thumb/fndz.PNG?1631752227',
            'coingeckoId': 'fndz-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BINGDWENDWEN',
            'name': 'BingDwenDwen',
            'address': '0x775205af1Cf9853577C4B0fC1e6D9ec956A116a6',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23387/thumb/bingdwendwen.png?1644059541',
            'coingeckoId': 'bingdwendwen',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALTPAY',
            'name': 'ALTPAY FINANCE',
            'address': '0x773Ad0415790082EA99BAA7309376A8979a0709A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26796/thumb/logo.png?1660186267',
            'coingeckoId': 'altpay-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELONHYPE',
            'name': 'ElonHype',
            'address': '0x7730ac665114c0e343B141dAaaeB097197191F8a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25354/thumb/logo-Coin-Gecko.png?1651312546',
            'coingeckoId': 'elonhype',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NIFTSY',
            'name': 'Envelop  Niftsy ',
            'address': '0x7728cd70b3dD86210e2bd321437F448231B81733',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19161/thumb/logo200x200.png?1643188130',
            'coingeckoId': 'niftsy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KST',
            'name': 'Karus Starter',
            'address': '0x772722B55cdC2A086aBd064267a17855Eb15e8b3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18862/thumb/logo-only.png?1645962885',
            'coingeckoId': 'ksm-starter',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HI',
            'name': 'hi Dollar',
            'address': '0x77087aB5Df23cFB52449A188e80e9096201c2097',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17618/thumb/unnamed_%281%29.png?1628665739',
            'coingeckoId': 'hi-dollar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POOLZ',
            'name': 'Poolz Finance',
            'address': '0x77018282fD033DAF370337A5367E62d8811Bc885',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13679/thumb/poolz_logo.png?1610806091',
            'coingeckoId': 'poolz-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QUANTIC',
            'name': 'Quantic',
            'address': '0x7700Edc3DBb30cBB7603212E061c804220c3cA54',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23953/thumb/Logo-200.png?1645773008',
            'coingeckoId': 'quantic',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'RUBY',
            'name': 'Realms of Ruby',
            'address': '0x76ebfB435364BaA45c34F5152173101d0AB64c7d',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27114/thumb/WechatIMG716.png?1662011873',
            'coingeckoId': 'realms-of-ruby',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OCV',
            'name': 'Oculus Vision',
            'address': '0x76cF21939f7dF1E355B8919B439556B064170d49',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18770/thumb/Icon-01200.png?1633357479',
            'coingeckoId': 'oculus-vision',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PEXO',
            'name': 'Plant Exodus',
            'address': '0x76b5ea2A75E96f629d739537e152062B4B89eeE9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21895/thumb/plant_axosu.PNG?1640229804',
            'coingeckoId': 'plant-exodus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CURE',
            'name': 'CURE V2',
            'address': '0x76aECB353AbF596BD61EE6BDb07d70787DeC4FD6',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18881/thumb/CURE-v2-square.png?1633692953',
            'coingeckoId': 'cure-token-v2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFTG',
            'name': 'NFT Global Platform',
            'address': '0x76F6cd75cE81E88916f8D933ab76efE18cEd6ad3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19390/thumb/Logo_-_2021-10-25T160042.014.png?1635148852',
            'coingeckoId': 'nft-global-platform',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIBLITE',
            'name': 'ShibaLite',
            'address': '0x76Ef2A25B1EA6EB5dC4d079AE82c767D55b0A99E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19830/thumb/shibalite.PNG?1635980650',
            'coingeckoId': 'shibalite',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHAK',
            'name': 'Shakita Inu',
            'address': '0x76E08e1c693D42551dd6ba7C2A659F74ff5Ba261',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19026/thumb/shak.png?1634192974',
            'coingeckoId': 'shakita-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZINA',
            'name': 'ZINARI',
            'address': '0x76BeeD9649c0C297cB6De10d07eFd66Db38e3C47',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23984/thumb/zina.png?1645946241',
            'coingeckoId': 'zinari',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TON',
            'name': 'The Open Network',
            'address': '0x76A797A59Ba2C17726896976B7B3747BfD1d220f',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17980/thumb/ton.PNG?1630023132',
            'coingeckoId': 'the-open-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GMV',
            'name': 'GameVerse',
            'address': '0x769c49d25d9b7249Ce4600440ee1869BAd49F395',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21611/thumb/9_RXV8-O_400x400.jpg?1639608207',
            'coingeckoId': 'gameverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MMP',
            'name': 'Moon Maker Protocol',
            'address': '0x768d221E81524De52841AeD976370b2e4F990416',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16257/thumb/m_mp.PNG?1623401736',
            'coingeckoId': 'moon-maker-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MRXB',
            'name': 'Wrapped Metrix',
            'address': '0x767B28a30E3a15DCEcE7bFF7a020ADfdE9D19cf8',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20763/thumb/Copy_of_Metrix_Logo_Final_Cut.png?1637652298',
            'coingeckoId': 'wrapped-metrix',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'SEON',
            'name': 'Seedon',
            'address': '0x7672843C25c5ba11191dA8dA40C0881D7E77D9E0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23651/thumb/3nhWnC2t_400x400.jpg?1644911997',
            'coingeckoId': 'seedon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLID',
            'name': 'Bolide',
            'address': '0x766AFcf83Fd5eaf884B3d529b432CA27A6d84617',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x766afcf83fd5eaf884b3d529b432ca27a6d84617.png',
            'coingeckoId': 'bolide',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'OWN',
            'name': 'Ownly',
            'address': '0x7665CB7b0d01Df1c9f9B9cC66019F00aBD6959bA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18167/thumb/ownly.PNG?1630883498',
            'coingeckoId': 'ownly',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REI',
            'name': 'Zerogoki',
            'address': '0x765B85839717Ebfc84378b83381A4814897A0506',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17015/thumb/iconZerogoki.png?1626618921',
            'coingeckoId': 'zerogoki',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RCKC',
            'name': 'Rocket Global Coin',
            'address': '0x7658604d32a0e60D08dd77A60413897E5CeFD51f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25436/thumb/Rocket-Global-Logo.png?1651740249',
            'coingeckoId': 'rocket-global-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LIS',
            'name': 'Leisure',
            'address': '0x76536C4d3BcF124BFC84Fc0d2Ab844Ec8fB75bf3',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27783/thumb/17-19-38-leisure-logo_%281%29.png?1665792669',
            'coingeckoId': 'leisure',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SNOWBALL',
            'name': 'Snowball Token',
            'address': '0x764b602C261A929b45f9FE7a4eAbF4A9f8470458',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15439/thumb/Untitled-design-9.png?1620805759',
            'coingeckoId': 'snowballtoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LINA',
            'name': 'Linear',
            'address': '0x762539b45A1dCcE3D36d080F74d1AED37844b878',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x3e9bc21c9b189c09df3ef1b824798658d5011937.png',
            'coingeckoId': 'linear',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': '100K',
            'name': 'Wow100K',
            'address': '0x76110518dBb50c0421A00bf1Bb5aBf301Bc7B7Fe',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27058/thumb/IMG_20220824_151254_066.png?1661516870',
            'coingeckoId': 'wow100k',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TALK',
            'name': 'Talkado',
            'address': '0x7606267A4bfff2c5010c92924348C3e4221955f2',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20979/thumb/Tzu4wc-k_400x400.jpg?1638163270',
            'coingeckoId': 'talkado',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NXT',
            'name': 'Next NXT',
            'address': '0x7601157E00750e2aDc28b2cb2a51419bdca53Ac9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18939/thumb/07FgXHOc_400x400.jpg?1642402959',
            'coingeckoId': 'next-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GLMS',
            'name': 'Glimpse',
            'address': '0x75f53011f6D51C60e6DCbf54A8B1bCB54f07F0C9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18199/thumb/glm.PNG?1630965574',
            'coingeckoId': 'glimpse',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ROCKY',
            'name': 'Rocky Inu',
            'address': '0x75cC6FEB91f9cf00B41F8D2F6f66B4AedaF9727B',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21037/thumb/AjdepqD.png?1638253053',
            'coingeckoId': 'rocky-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETM',
            'name': 'Etermon',
            'address': '0x75bDEd6BF44bb01527138673dCc064DBe3E7d96d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21694/thumb/Etermon-Logo-200x200.png?1639721390',
            'coingeckoId': 'etermon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SMURF',
            'name': 'SmurfsINU',
            'address': '0x75aFA9915B2210Cd6329E820af0365e932bC1dd5',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26804/thumb/200x200.png?1660207921',
            'coingeckoId': 'smurfsinu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CFARM',
            'name': 'CoinFarm',
            'address': '0x75F01Ca420A1CB86dF6790d2839E8e34B59b7BB1',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27880/thumb/IMG_20221019_070952_177.jpg?1666231346',
            'coingeckoId': 'coinfarm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRY',
            'name': 'TryHards',
            'address': '0x75D107De2217FFe2cD574A1b3297C70C8FAfD159',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x75d107de2217ffe2cd574a1b3297c70c8fafd159.png',
            'coingeckoId': 'tryhards',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'NAOS',
            'name': 'NAOS Finance',
            'address': '0x758d08864fB6cCE3062667225ca10b8F00496cc2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x758d08864fB6cCE3062667225ca10b8F00496cc2.png',
            'coingeckoId': 'naos-finance',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'DVI',
            'name': 'Dvision Network',
            'address': '0x758FB037A375F17c7e195CC634D77dA4F554255B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x758FB037A375F17c7e195CC634D77dA4F554255B/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FITFI',
            'name': 'STEP.APP',
            'address': '0x7588DF009C3d82378Be6ab81F2108fa963c10fc8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TFI',
            'name': 'TrustFi Network',
            'address': '0x7565ab68D3F9DaDff127F864103C8c706Cf28235',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16297/thumb/frny3dSs_400x400.jpg?1623676476',
            'coingeckoId': 'trustfi-network-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NABOX',
            'name': 'Nabox',
            'address': '0x755f34709E369D37C6Fa52808aE84A32007d1155',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x755f34709E369D37C6Fa52808aE84A32007d1155.png',
            'coingeckoId': 'nabox',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'BLET',
            'name': 'Blockearth',
            'address': '0x755A1c9b62126b289338733AA4D6E9669dE2b989',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26676/thumb/20130.png?1659517563',
            'coingeckoId': 'blockearth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATR',
            'name': 'Artrade',
            'address': '0x7559C49c3Aec50E763A486bB232fA8d0d76078e4',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24894/thumb/atr_200.png?1649303771',
            'coingeckoId': 'artrade',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OXB',
            'name': 'Oxbull Tech',
            'address': '0x7536c00711E41df6aEBCCa650791107645b6bc52',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27781/thumb/CG_logo_%282%29.png?1665973528',
            'coingeckoId': 'oxbull-tech-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COSMIK',
            'name': 'Cosmik Finance',
            'address': '0x7509DB061C45e8EcEb01739D104f78F85eF22Dbf',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x7509db061c45e8eceb01739d104f78f85ef22dbf/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SMGM',
            'name': 'SMEGMARS',
            'address': '0x74f3a669A8f35010F6f8811c495c0A5F60c5d04d',
            'decimals': 10,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16123/thumb/smeg-coingecko_%281%29.png?1623118187',
            'coingeckoId': 'smegmars',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LSC',
            'name': 'Last Survivor',
            'address': '0x74e2572a1C579B4DF80D57e9698098b255F23c9e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23607/thumb/lsc.png?1644746161',
            'coingeckoId': 'last-survivor',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NAXAR',
            'name': 'Naxar',
            'address': '0x74d23DB8fD35fd20e1964F7197147c8a22d92A8d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16946/thumb/logo.png?1655886562',
            'coingeckoId': 'naxar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIH-TZU',
            'name': 'Shih Tzu Inu',
            'address': '0x74d00122a5d038914EAe062af8174C888F3166Dc',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27330/thumb/IMG_20220916_223352_686.jpg?1663396741',
            'coingeckoId': 'shih-tzu-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSPT',
            'name': 'Blocksport Token',
            'address': '0x74F996A2ED8B1370C2f12ce9D040a1d9c9254E6e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BBOX',
            'name': 'Black Box',
            'address': '0x74EE86C1B4f0b400f5fbC606152497f21b11c508',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26507/thumb/frX5rEy1_400x400.jpeg?1658384096',
            'coingeckoId': 'black-box',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NBP',
            'name': 'NFTBomb',
            'address': '0x74C22834744E8D5e36c79420Ff7b057964Aba8a7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20517/thumb/nft_bomb.PNG?1637188326',
            'coingeckoId': 'nftbomb',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OLY',
            'name': 'Oly Sport',
            'address': '0x74C1815474a75Dcb366223107CDE1bBa4a1a7296',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19930/thumb/oly_sport.PNG?1636323685',
            'coingeckoId': 'oly-sport',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EAD',
            'name': 'Learning Cash  OLD ',
            'address': '0x74B6978F0b449f71C6E7AddEfd62BBeb3fa89B71',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25405/thumb/logo.png?1651730991',
            'coingeckoId': 'learning-cash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DFX',
            'name': 'DeFireX on BSC',
            'address': '0x74B3abB94e9e1ECc25Bd77d6872949B4a9B2aACF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x74b3abb94e9e1ecc25bd77d6872949b4a9b2aacf.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'CATBREAD',
            'name': 'CatBread',
            'address': '0x74A6E371F95073005b3a5fAF4A9E25aE30290F94',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16120/thumb/22TJQu3t_400x400.jpg?1623034628',
            'coingeckoId': 'catbread',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METACLOTH',
            'name': 'Meta Cloth',
            'address': '0x74996B794b7A7C5eD3C4331F35A0AC7B673eFF97',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22588/thumb/SB1LXyqlg4EUdoV.png?1642144014',
            'coingeckoId': 'meta-cloth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOGGY',
            'name': 'Doggy',
            'address': '0x74926B3d118a63F6958922d3DC05eB9C6E6E00c6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x74926b3d118a63f6958922d3dc05eb9c6e6e00c6.png',
            'coingeckoId': 'doggy',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'CADINU',
            'name': 'Canadian Inuit Dog',
            'address': '0x748eD23b57726617069823Dc1E6267C8302d4E76',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27003/thumb/CADINU-HEAD-200pxx200px.png?1661331562',
            'coingeckoId': 'canadian-inuit-dog',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DICE',
            'name': 'Dice.finance Token',
            'address': '0x748AD98b14C814B28812eB42ad219C8672909879',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12233/small/dice2.png?1598344967',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SPICE',
            'name': 'Metayoka Spice',
            'address': '0x747fD7FB58A0437b13cBde14fdFC094397349cdf',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24528/thumb/linbxp.png?1648039140',
            'coingeckoId': 'metayoka-spice',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DELOS',
            'name': 'Delos Defi',
            'address': '0x747fD09ae18ba055978B5dA7a5cef572171C847C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18899/thumb/20211002_161455_0000.png?1633817301',
            'coingeckoId': 'delos-defi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CGU',
            'name': 'Crypto Global United',
            'address': '0x747D74dB20cc422F39ab54EDB2A3Ce21f3C98AF1',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x747d74db20cc422f39ab54edb2a3ce21f3c98af1.png',
            'coingeckoId': 'crypto-global-united',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'WSYS',
            'name': 'Wrapped Syscoin',
            'address': '0x747B1223B23E53070c54Df355FAC2E198aA54708',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23916/thumb/xDiIFaKO_400x400.jpg?1645684034',
            'coingeckoId': 'wrapped-syscoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRX',
            'name': 'CrossX',
            'address': '0x747339CaB98CC50c8698970DB1f89BB3A0bb7A03',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26556/thumb/Wn48hpj7_400x400.jpeg?1658791830',
            'coingeckoId': 'crossx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANM',
            'name': 'Animverse',
            'address': '0x7470FF44A57FCe4b7413F31Fdc9b625ff58dBb9c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24873/thumb/16410.png?1649215860',
            'coingeckoId': 'animverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PINETWORKDEFI',
            'name': 'Pi Network DeFi',
            'address': '0x746760eCF1D8088c1014Ef3d43dc45d5af8FeBf3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25046/thumb/logo.png?1649913281',
            'coingeckoId': 'pi-network-defi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AMATICB',
            'name': 'Ankr MATIC Reward Earning Bond',
            'address': '0x7465B49f83bfd74e8Df8574d43BFFF34EDbC1758',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1664267993537_9463999814946371.png',
            'coingeckoId': 'ankr-matic-reward-earning-bond',
            'listedIn': [
                'coingecko',
                'openocean'
            ]
        },
        {
            'symbol': 'KNGS',
            'name': 'Pixel Kings',
            'address': '0x7444e37559a16c5c7337f41955Cd3BB04B28A114',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26656/thumb/pixelking_2HD-2.png?1659413419',
            'coingeckoId': 'pixel-kings',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIBGX',
            'name': 'ShibaGalaxy',
            'address': '0x7420d2Bc1f8efB491D67Ee860DF1D35fe49ffb8C',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21248/thumb/cM870ezC_400x400.jpg?1638772741',
            'coingeckoId': 'shibagalaxy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEEZNUTS',
            'name': 'Deez Nuts',
            'address': '0x741F72BC9E29f662F2Eb41c5aB450A2cA33bE57D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15967/thumb/deeznuts200x200.png?1622520597',
            'coingeckoId': 'deez-nuts',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFEMONEY',
            'name': 'SafeMoneyBSC',
            'address': '0x740b4c277a94Cc781ec9da438d2eB12f75DEeE46',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22953/thumb/Safe-Money-BSC-Logo200.png?1643009911',
            'coingeckoId': 'safemoneybsc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BULLAF',
            'name': 'Bullish AF',
            'address': '0x740a00c764E8d9c7946A751526e2ba4C8A44212B',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18531/thumb/bullaf.png?1632294693',
            'coingeckoId': 'bullish-af',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CPLAY',
            'name': 'CPLAY Network',
            'address': '0x740E08E52542Df19D7f2f9cBF3924ac0Bfe53071',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23752/thumb/cplay.png?1645342837',
            'coingeckoId': 'cplay-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DYT',
            'name': 'DoYourTip',
            'address': '0x740623d2c797b7D8D1EcB98e9b4Afcf99Ec31E14',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/8936/thumb/image1.png?1578033515',
            'coingeckoId': 'dynamite',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LEAN',
            'name': 'Lean',
            'address': '0x7404B8bDA7A48319926edA374e0A6559BcFD2967',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15854/thumb/lean.PNG?1622163757',
            'coingeckoId': 'lean',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GREEN',
            'name': 'Green Chart',
            'address': '0x7403802c0FFeE2d83FB13513e23aAF4a3a9C69f0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22119/thumb/Green-Chart.png?1647877631',
            'coingeckoId': 'green-chart',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AGS',
            'name': 'Collector Coin',
            'address': '0x73ffdf2d2aFB3Def5b10bF967Da743F2306A51DB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16918/thumb/AGS.png?1625626120',
            'coingeckoId': 'collector-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOOK',
            'name': 'LooksCoin',
            'address': '0x73cBA57Ad8bC775A5345D9a0de2e90C74621D802',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/3080/thumb/lookscoin200.png?1662543370',
            'coingeckoId': 'lookscoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CGANG',
            'name': 'CryptoGangsters',
            'address': '0x73cB0a3baaC136A5577E058961fA3Afd86AC1b0E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23740/thumb/cgang.png?1645254479',
            'coingeckoId': 'cryptogangsters',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'THUNDERETH',
            'name': 'ThunderETH',
            'address': '0x73c499099BFc964D3CBcd7425026b074869748cE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18046/thumb/200x200eth.png?1630327541',
            'coingeckoId': 'thundereth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CTL',
            'name': 'Twelve Legions',
            'address': '0x73bC158e84873888cFc8b617524EEBB1cfCe8D4E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21282/thumb/eEuyEtJ.png?1638863963',
            'coingeckoId': 'twelve-legions',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFLE',
            'name': 'Safle',
            'address': '0x73afC23510b40dcbEABc25fFBc8C2976eD9f950c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21674/small/56835021.png',
            'coingeckoId': null,
            'listedIn': [
                'dfyn'
            ]
        },
        {
            'symbol': 'SOWL',
            'name': 'SOWL',
            'address': '0x73Fc38B104B45DA06F4B68782ab75b45D0B225b7',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27917/thumb/F4494FF2-2914-49A7-8AE1-49056B82D837.png?1666350560',
            'coingeckoId': 'sowl',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FABRIC',
            'name': 'MetaFabric',
            'address': '0x73FF5Dd853Cb87c144F463a555dcE0e43954220d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21233/thumb/LISTING-icon.png?1638758217',
            'coingeckoId': 'metafabric',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFT11',
            'name': 'NFT11',
            'address': '0x73F67AE7f934FF15beaBf55A28C2Da1eEb9B56Ec',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23792/thumb/token-icon.png?1645438525',
            'coingeckoId': 'nft11',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MM',
            'name': 'MMToken',
            'address': '0x73F059dB0182dc2Ed716D028E1b13033cfcbf63B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0x73f059db0182dc2ed716d028e1b13033cfcbf63b/63d3482c44dd28fc20b6615581c5e093.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'COD',
            'name': 'City of Dream',
            'address': '0x73Eb6947D72ED1979e6A935F516212A7f593bC44',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25164/thumb/YWdHwVMAbIlCp5-7vCUp8Clpp4qOvDnD9vmjAioDpwQBJbobhCW0vJa6izVdZfOeeKBzjRrTW8tk1Z2ec9W-qoYQ-PzF5_5z7iSQKpPAmd5GZSaXrPGsvrgANcZaFM4O6MFqqK8ETyOrCM56Z37XGcEGSYKjhq5x_MzrbWqbzh2eKkIKs6DX9xWzVoBKpBOvc7BceSvQ2Hi3s3GgbdB6Huy67J.jpg?1650520054',
            'coingeckoId': 'city-of-dream',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CENX',
            'name': 'Centcex',
            'address': '0x739e81BCd49854d7BDF526302989f14A2E7994B2',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20624/thumb/centx.PNG?1637318819',
            'coingeckoId': 'centcex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METAXA',
            'name': 'Metaxa',
            'address': '0x739408E1dEc3b60Cd03Fa76948c079f94f422366',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25936/thumb/200x200_%281%29.png?1654759049',
            'coingeckoId': 'metaxa',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AMATICC',
            'name': 'Ankr Reward Earning MATIC',
            'address': '0x738d96Caf7096659DB4C1aFbf1E1BDFD281f388C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1653739507057_6345922838275662.png',
            'coingeckoId': 'ankr-reward-earning-matic',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'MARTIANDOGE',
            'name': 'MartianDoge',
            'address': '0x738B9658f84b009F5Dd6ca2A5846422edFa7D562',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20101/thumb/martiandoge.png?1636511632',
            'coingeckoId': 'martiandoge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CZBOMB',
            'name': 'CZbomb',
            'address': '0x737BC70D07aA6B7f4Ba6d12B45E8CDDBa2446B77',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26173/thumb/czbomb.fa9a3c7a_%281%29.png?1656377754',
            'coingeckoId': 'czbomb',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IGR',
            'name': 'Ingress',
            'address': '0x7374Df1fD3B034B7d41c9C811a3A843fEc6511fb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25972/thumb/logo.png?1655104330',
            'coingeckoId': 'ingress',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHEESUS',
            'name': 'Cheesus',
            'address': '0x736A9964C921B75D9fB94f30CdFeD8bd5be43d0E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23278/thumb/cheesus.png?1643529316',
            'coingeckoId': 'cheesus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '0XT',
            'name': '0xTrade',
            'address': '0x735c09d9E6c68fAaA8562E7316B34A8d89f4a3B8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27557/thumb/0xlogo.png?1664517419',
            'coingeckoId': '0xtrade',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FRAT',
            'name': 'Frat',
            'address': '0x7352eEBeda1Ff0E625452Cc27D811A88216243fB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14931/thumb/updated_logo_only.png?1625668013',
            'coingeckoId': 'frat',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METAMUSK',
            'name': 'Musk Metaverse',
            'address': '0x734c5F3f8F6ad9697b26eCC6388678aaFd3dB3B2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21822/thumb/Logo-Symbol-kh-ng-n-n.png?1640075758',
            'coingeckoId': 'musk-metaverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDZ',
            'name': 'Zedxion USDZ',
            'address': '0x734D66f635523D7ddb7d2373C128333DA313041b',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25909/thumb/20475.png?1654587346',
            'coingeckoId': 'zedxion-usdz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PEEL',
            'name': 'Meta Apes PEEL',
            'address': '0x734548a9e43d2D564600b1B2ed5bE9C2b911c6aB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x734548a9e43d2D564600b1B2ed5bE9C2b911c6aB.png',
            'coingeckoId': 'meta-apes-peel',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'MIKU',
            'name': 'Miku',
            'address': '0x73419De8E3E26A17e0E6b0436e29dd04A25B061D',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26680/thumb/PNGLO.png?1659536920',
            'coingeckoId': 'miku',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIDO',
            'name': 'Shido',
            'address': '0x733Af324146DCfe743515D8D77DC25140a07F9e0',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26674/thumb/Shido.png?1667361249',
            'coingeckoId': 'shido',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BITSU',
            'name': 'Bitsubishi',
            'address': '0x732cC1642905af1B7c394c0812Dbc4779cBF2b2d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25289/thumb/CMC.png?1651131086',
            'coingeckoId': 'bitsubishi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XCN',
            'name': 'Chain',
            'address': '0x7324c7C0d95CEBC73eEa7E85CbAac0dBdf88a05b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x7324c7C0d95CEBC73eEa7E85CbAac0dBdf88a05b.png',
            'coingeckoId': null,
            'listedIn': [
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'KOKO',
            'name': 'KokoSwap',
            'address': '0x7320c128e89bA4584Ab02ed1C9c96285b726443C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15205/thumb/Kokoswap.png?1624955646',
            'coingeckoId': 'kokoswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MONO',
            'name': 'MonoMoney',
            'address': '0x7317dA9c15303bfb434690586C3373B94fb2dd31',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24399/thumb/ToL6DsC7_400x400.jpg?1647528782',
            'coingeckoId': 'monomoney',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHANGE',
            'name': 'ChangeX',
            'address': '0x731282fE28e71550dCbca783C40287a1945AbB84',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'EDAO',
            'name': 'ElonDoge DAO',
            'address': '0x730cb2BA0f654ddec32470d265555F26fe545EB8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17323/thumb/edao_logo.png?1627305989',
            'coingeckoId': 'elondoge-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IFIT',
            'name': 'Calo Indoor',
            'address': '0x73052f51b68D974D2ed6CeC7B9E817ADac168168',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27616/thumb/IFIT_200x200.png?1664861409',
            'coingeckoId': 'calo-indoor',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'bALBT',
            'name': 'AllianceBlock Token',
            'address': '0x72fAa679E1008Ad8382959FF48E392042A8b06f7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x72faa679e1008ad8382959ff48e392042a8b06f7.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DGN',
            'name': 'https://degen.trade/',
            'address': '0x72f28c09be1342447fa01Ebc76eF508473d08c5c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0x72f28c09be1342447fa01ebc76ef508473d08c5c.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ENK',
            'name': 'EnkronosToken',
            'address': '0x72ee88374D8087EEB069510A79c7220D90dC4779',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ASTRO',
            'name': 'AstroSwap',
            'address': '0x72eB7CA07399Ec402c5b7aa6A65752B6A1Dc0C27',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18816/thumb/astro.png?1633506850',
            'coingeckoId': 'astroswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PAN',
            'name': 'Pandorium',
            'address': '0x72e3d54293e2912fC66Cf4a93625Ac8305E3120D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26142/thumb/PAN.png?1656038849',
            'coingeckoId': 'pandorium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PXLC',
            'name': 'Pixl Coin',
            'address': '0x72d2946094E6E57c2faDe4964777A9af2B7A51F9',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18793/thumb/pixl_coin.jpg?1633423526',
            'coingeckoId': 'pixl-coin-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DRACE',
            'name': 'MetaDrace',
            'address': '0x72a8e7520296623f2e8C7668d01EB3fa30D9B106',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22894/thumb/drace.png?1642847376',
            'coingeckoId': 'metadrace',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WAIF',
            'name': 'Waifer',
            'address': '0x72EF0CF8dC02fE91150a2472CC551DE929e22FaC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19424/thumb/cropped-LogoMakr-3GX0jv-300dpi-1.png?1635214905',
            'coingeckoId': 'waifer',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FRATS',
            'name': 'FlappyRats',
            'address': '0x72BD2a465a87DFa23eABBF5d8d56707ea57bD40E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26865/thumb/200x200.png?1660533866',
            'coingeckoId': 'flappyrats',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AQUA',
            'name': 'Planet Finance',
            'address': '0x72B7D61E8fC8cF971960DD9cfA59B8C829D91991',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15710/thumb/aqua-icon.png?1621583770',
            'coingeckoId': 'planet-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DK',
            'name': 'Dogs Kombat',
            'address': '0x728FC32c0d2F61FFe21B6a4A7dF987DeaE0e0888',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24838/thumb/200x200.png?1649067791',
            'coingeckoId': 'dogs-kombat',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNFI',
            'name': 'Unifi Protocol DAO',
            'address': '0x728C5baC3C3e370E372Fc4671f9ef6916b814d8B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x728c5bac3c3e370e372fc4671f9ef6916b814d8b.png',
            'coingeckoId': 'unifi-protocol-dao',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MXZ',
            'name': 'Maximus Coin',
            'address': '0x72875158c818d43eA07C514Cb9C0e134bB8cb0e7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27797/thumb/Untitled_design-removebg-preview_%281%29.png?1665822563',
            'coingeckoId': 'maximus-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GRLC',
            'name': 'Garlicoin',
            'address': '0x7283DfA2d8D7e277b148cc263B5d8Ae02f1076D3',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/2699/thumb/garlicoin.png?1548126827',
            'coingeckoId': 'garlicoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ESOL',
            'name': 'EVERSOL',
            'address': '0x727F7B75BfB83Dfe10A54744dAe887843CEB673F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22096/thumb/3cZ6wDlu_400x400.jpg?1640796144',
            'coingeckoId': 'eversol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHI',
            'name': 'Shirtum',
            'address': '0x7269d98Af4aA705e0B1A5D8512FadB4d45817d5a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16955/thumb/4fWlpC0.png?1625794164',
            'coingeckoId': 'shirtum',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MEB',
            'name': 'Meblox Protocol',
            'address': '0x7268B479eb7CE8D1B37Ef1FFc3b82d7383A1162d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21885/thumb/create-meblox.png?1640215801',
            'coingeckoId': 'meblox-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IONZ',
            'name': 'IONZ',
            'address': '0x7268192a0e5882b21F13Fc857cF78299D8e3D75b',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26113/thumb/Token-IONZ.png?1655898375',
            'coingeckoId': 'meta-nebulas-ionz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LMN',
            'name': 'Lemonn',
            'address': '0x7251C7A2155d8FEDb42a1DC4333aDe589e4d6919',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21057/thumb/lemonn-logo-200x200.png?1638267162',
            'coingeckoId': 'lemonn-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SDM',
            'name': 'Syndromeswap',
            'address': '0x724eBD675840AB9F4D9f135033A47F30799283a4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25977/thumb/logosyndrome512x512-300x300.png?1655112687',
            'coingeckoId': 'syndromeswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HEP',
            'name': 'Health Potion',
            'address': '0x7242Dbed15630a29eA5347898fa15FF8d5EB3593',
            'decimals': 0,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0x7242dbed15630a29ea5347898fa15ff8d5eb3593/4f9dd95c063a798ab3cdc4a504adde7e.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'LOCO',
            'name': 'LocoMeta',
            'address': '0x723eF00502e76E8501101De1b25ef146C4b571CA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25842/thumb/pj6iF0Q7_400x400.jpg?1654093406',
            'coingeckoId': 'locometa',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'KAINET',
            'name': 'KAINET',
            'address': '0x723B6795be37Ad8a0376aCfb50034fa21912b439',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19071/thumb/kainet.png?1638345263',
            'coingeckoId': 'kainet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SRG',
            'name': 'Street Runner',
            'address': '0x722F41f6511ff7CDA73a1cb0A9eA2F731738c4a0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24382/thumb/kKx4Ivoy_400x400.jpg?1647447765',
            'coingeckoId': 'street-runner',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LTT',
            'name': 'LordToken',
            'address': '0x71e72DdE4152D274afD1F2DB43531Ed9E44A78Fa',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24193/thumb/D8YzkZxo_400x400.png?1646829681',
            'coingeckoId': 'lordtoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HMT',
            'name': 'Humanize',
            'address': '0x71e67b8D88718d113fC7eDbD95F7Ca380222b3C6',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27439/thumb/hmt.png?1663935057',
            'coingeckoId': 'humanize',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATYNE',
            'name': 'Aerotyne',
            'address': '0x71e1611075800E89A460463FCDfbA3d0FEA8c01d',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15855/thumb/ate.PNG?1622164256',
            'coingeckoId': 'aerotyne',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'P3D',
            'name': 'PoWH3D',
            'address': '0x71c300E7B6D16cf3Cdc4695fa5Ba713aE636eD9e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x71c300e7b6d16cf3cdc4695fa5ba713ae636ed9e/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'TMG',
            'name': 'T mac DAO',
            'address': '0x71b87be9ccBABe4F393e809dfc26Df3c9720E0a2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28117/thumb/tmg.png?1667565644',
            'coingeckoId': 't-mac-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MARVIN',
            'name': 'Marvin Inu',
            'address': '0x71aB195498b6dC1656aBb4D9233f83Ae5F19495B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22039/thumb/lVshyCp.png?1640669485',
            'coingeckoId': 'marvin-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIC',
            'name': 'Microcosm',
            'address': '0x71Fc2c893e41EaBDF9C4Afda3b2Cdb46B93Cd8Aa',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25085/thumb/iUuLuOEsilei-ecpryv7sGFXvtJ0WOSAX0a4MX7d0huo6DSvKYxhdOzJuNVAAklFK68tW5S5GfoiO6koLbjWp2UDvjSBXi3LCzlp437hqaSLgYwoNsLAQhaVzqwIxyvjjLluyH0R_RykeAXGz3I7wl32qnjkdt43Wa4ZgPqLTVwC8siw_50w46MLRAZWISQ_YbjSVhU20-g9dwUBsKTA4Nyw_I.jpg?1650265494',
            'coingeckoId': 'microcosm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SIMPLI',
            'name': 'Simpli Finance',
            'address': '0x71F69AFeD8825d6D9300Ba4d74103e1DCc263B93',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21264/thumb/iT_XqHrd_400x400.jpg?1638837165',
            'coingeckoId': 'simpli-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABYFLOKI',
            'name': 'Baby Floki',
            'address': '0x71E80e96Af604Afc23Ca2AED4C1C7466DB6dd0c4',
            'decimals': 7,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18436/thumb/9FkEA4bN.png?1631959196',
            'coingeckoId': 'baby-floki',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWINGBY',
            'name': 'Swingby',
            'address': '0x71DE20e0C4616E7fcBfDD3f875d568492cBE4739',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x71DE20e0C4616E7fcBfDD3f875d568492cBE4739/logo.png',
            'coingeckoId': 'swingby',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GLDM',
            'name': 'GoldMint',
            'address': '0x71B0191635B30Fb6412A2f8B721Ca1e904eB40df',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'NOMY',
            'name': 'NOMY',
            'address': '0x719673Bc6AD1C4FA216052FDA2297774bf582b05',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19991/thumb/BDRF_0xJ_400x400.jpg?1636359125',
            'coingeckoId': 'nomy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GDILDO',
            'name': 'Green Dildo Finance',
            'address': '0x718f127435213B48c1564C71558608df5770FE68',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16641/thumb/gdildo.PNG?1624585725',
            'coingeckoId': 'green-dildo-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PUNKS',
            'name': 'Punk Shiba',
            'address': '0x7189b1391f24F9a90cDB0E12ac0b3F489939921C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21013/thumb/logo200.png?1638190739',
            'coingeckoId': 'punk-shiba',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BDC',
            'name': 'BabyDogeCake',
            'address': '0x7181638E041E26321f9f2519E2F885f656458519',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17405/thumb/eWt6m4W.png?1627539644',
            'coingeckoId': 'babydogecake',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CPOO',
            'name': 'Cockapoo',
            'address': '0x71809c4fF017CEADE03038a8b597EcaBB6519918',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16852/thumb/cpoo.png?1644648415',
            'coingeckoId': 'cockapoo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TAG',
            'name': 'Tag Protocol',
            'address': '0x717fb7B6d0c3d7f1421Cc60260412558283A6ae5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20627/thumb/favicon-32x32.png?1637320093',
            'coingeckoId': 'tag-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADO',
            'name': 'ADO Network',
            'address': '0x717926326fAb42c12E7d6102e2CD6c43683aA1ae',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27346/thumb/ado-200x200.png?1663654327',
            'coingeckoId': 'ado-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'F11',
            'name': 'First Eleven',
            'address': '0x716cBD4293AB1f7aB9C8D39e51b2685f74d18891',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21295/thumb/f11.PNG?1638875819',
            'coingeckoId': 'first-eleven',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RADR',
            'name': 'CoinRadr',
            'address': '0x71648F61D21D3C75b08414FFa817C4Ac2157f89E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23334/thumb/Vt0zWsHQ_400x400.jpg?1643881443',
            'coingeckoId': 'coinradr',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AXS',
            'name': 'Axie Infinity',
            'address': '0x715D400F88C167884bbCc41C5FeA407ed4D2f8A0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x715d400f88c167884bbcc41c5fea407ed4d2f8a0.png',
            'coingeckoId': 'axie-infinity',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'KICH',
            'name': 'KichiCoin',
            'address': '0x7153431234b9A6D1Fa53cD057D5a1AAC00C85c7f',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16865/thumb/japanese_coin_logo200.png?1625472530',
            'coingeckoId': 'kichicoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YLF',
            'name': 'YieldLock',
            'address': '0x715294832DE407Dd30E6c32ebDa7256eF4B04769',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26008/thumb/Coin_5.png?1655279810',
            'coingeckoId': 'yieldlock',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RST',
            'name': 'Red Shiba',
            'address': '0x714a128bda7CfBA28F212a9Dc052467b560AB14b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20611/thumb/HTC0HiGS_400x400.png?1637293704',
            'coingeckoId': 'red-shiba-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTCB',
            'name': 'Binance Bitcoin',
            'address': '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c.png',
            'coingeckoId': 'binance-bitcoin',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'IPAY',
            'name': 'iPay',
            'address': '0x712B5A7dB54930bbb2e29F1e5219020016C4b26e',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22148/thumb/i-Pay-Logo.png?1640931056',
            'coingeckoId': 'ipay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GRCC',
            'name': 'Green Star',
            'address': '0x7128B8e084f96b8a20e7E7623E162fdc4553CCa6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25414/thumb/mbLQzw4d_400x400.jpg?1651734640',
            'coingeckoId': 'green-star',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALT',
            'name': 'Alt Coin',
            'address': '0x7120f43220D1C77cb821DDE0420293491d03F854',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20931/thumb/zM1fNLMi_400x400.png?1637969598',
            'coingeckoId': 'alt-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ULAB',
            'name': 'Unilab',
            'address': '0x7111E5C9b01ffA18957B1AA27E9Cb0e8FBA214F5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22330/thumb/unilab-logo-black-text-200.png?1641517618',
            'coingeckoId': 'unilab-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAPE',
            'name': 'Bored APEmove',
            'address': '0x70e48Eb0881a8c56BAAD37EB4491eA85Eb47b4b2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26576/thumb/Group_48096525.png?1658887505',
            'coingeckoId': 'bored-apemove',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TAU',
            'name': 'Lamden',
            'address': '0x70d7109D3AfE13EE8f9015566272838519578c6b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/2155/thumb/Lamden_Logo_2020_-_White_w_Black_Borders.png?1597732013',
            'coingeckoId': 'lamden',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SLEEP',
            'name': 'SLEEP',
            'address': '0x70b514801E7e59C24c32a353AC00951BB6979944',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27696/thumb/sleep.png?1665294942',
            'coingeckoId': 'sleep',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GAMESAFE',
            'name': 'Gamesafe',
            'address': '0x70a44672B9BBcF4D49dCEaA610eB580BD3c94c52',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16065/thumb/logo_200x200.png?1623049875',
            'coingeckoId': 'gamesafe',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DUW',
            'name': 'Dreamy Undersea World',
            'address': '0x70DDEf2114b6369656E5B52456bEa372901c23B1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24683/thumb/ncAw1BdF_400x400.jpg?1648561324',
            'coingeckoId': 'dreamy-undersea-world',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MEXI',
            'name': 'Metaxiz',
            'address': '0x70D8d5B3416888fD05e806195312DD2D9597d50C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21727/thumb/metaxiz.PNG?1639964527',
            'coingeckoId': 'metaxiz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NASA',
            'name': 'Not Another Shit Altcoin',
            'address': '0x70CF8d40A3D0F7BC88077Ba7D103050d0001A653',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16151/thumb/NASA.png?1623131502',
            'coingeckoId': 'not-another-shit-altcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KURAI',
            'name': 'Kurai Inu',
            'address': '0x70C82588FCc570cd4885CA50b8d34c225f431EE8',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20114/thumb/KuraiInu.png?1636514407',
            'coingeckoId': 'kurai-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XTT-B20',
            'name': 'XTblock',
            'address': '0x70B6C6A555507EE4ac91C15E5c80b7dc8FF3b489',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17921/thumb/XTblock-Icon-round-200.png?1632122725',
            'coingeckoId': 'xtblock-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POLAR',
            'name': 'Polarisdefi',
            'address': '0x70905594023Cb2f37F3103fDc70315ad1601D6EF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14606/thumb/Polaris.png?1623727828',
            'coingeckoId': 'polaris',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ELAND',
            'name': 'Etherland',
            'address': '0x708Cb02ad77E1B245B1640cee51B3Cc844bcAeF4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14432/thumb/eland.png?1616044253',
            'coingeckoId': 'etherland',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTL',
            'name': 'Battle Saga',
            'address': '0x708955Db0d4c52FFBf9AA34aF7f3CA8bf07390a8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21990/thumb/200x200.png?1640581733',
            'coingeckoId': 'battle-saga',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLS',
            'name': 'Metacourt',
            'address': '0x708739980021A0b0B2E555383fE1283697e140e9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15708/thumb/metacourt.png?1661489311',
            'coingeckoId': 'blocsport-one',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOT',
            'name': 'Binance Peg Polkadot',
            'address': '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x7083609fce4d1d8dc0c979aab8c869ea2c873402.png',
            'coingeckoId': 'binance-peg-polkadot',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'UDO',
            'name': 'Unido',
            'address': '0x70802Af0Ba10Dd5bB33276B5b37574b6451Db3D9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14176/thumb/unido.jpg?1614792353',
            'coingeckoId': 'unido-ep',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SBSH',
            'name': 'Safe Baby Shiba',
            'address': '0x707C9088313B20931c05D16227f146279eC28e4E',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16311/thumb/logo_-_2021-06-15T105921.126.png?1623726182',
            'coingeckoId': 'safe-baby-shiba',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'B21',
            'name': 'B21',
            'address': '0x70512C7f3D3009BE997559D279B991461c451D70',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/3510/thumb/B21_%283%29.png?1608706191',
            'coingeckoId': 'b21',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GALAXY',
            'name': 'GalaxyBUSD',
            'address': '0x70375E2ADF741229d1b6D0E3F78bB30F4Ac67260',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22625/thumb/galaxy.png?1642329072',
            'coingeckoId': 'galaxybusd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PINKS',
            'name': 'PinkSwap',
            'address': '0x702b3f41772e321aacCdea91e1FCEF682D21125D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16223/thumb/pinkswap.png?1623331173',
            'coingeckoId': 'pinkswap-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FRGST',
            'name': 'Froggies Token',
            'address': '0x7029994f28fd39ff934A96b25591D250A2183f67',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20405/thumb/Logo-2-1.png?1668390336',
            'coingeckoId': 'froggies-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARG',
            'name': 'Argonon Helium',
            'address': '0x701d9A068d1EeC64fbC10299B9f1B18Fbb355DDB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25542/thumb/EP_f1090image_story.jpeg?1652266154',
            'coingeckoId': 'argonon-helium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XERM',
            'name': 'Xerium',
            'address': '0x700d6ECA747892FbaD8f51CaE938a56b84AAe207',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26284/thumb/Xerium_Logo_200x200_PNG.png?1657077128',
            'coingeckoId': 'xerium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APOC',
            'name': 'Apocalypse',
            'address': '0x700AfD366D8cD7CC30f17E215F83BEeaf92459DF',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24073/thumb/download.png?1646213166',
            'coingeckoId': 'apocalypse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTBS',
            'name': 'BitBase Token',
            'address': '0x6feFd97F328342a8A840546A55FDcfEe7542F9A8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17414/thumb/btbs.PNG?1627596633',
            'coingeckoId': 'bitbase-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BHC',
            'name': 'BillionHappiness',
            'address': '0x6fd7c98458a943f469E1Cf4eA85B173f5Cd342F4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x6fd7c98458a943f469e1cf4ea85b173f5cd342f4.png',
            'coingeckoId': 'billionhappiness',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'METAROID',
            'name': 'Metaroid NFT',
            'address': '0x6fceD7dfDa0C5C0e213D8ade9AF6dC6c727A0840',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23779/thumb/logo.png?1645435572',
            'coingeckoId': 'metaroid-nft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WDOG',
            'name': 'Winterdog',
            'address': '0x6fFcEbB2F9360Bc233cc0aa35d15b4999cD6Af29',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27907/thumb/WD300x300.png?1666337555',
            'coingeckoId': 'winterdog',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MILKY',
            'name': 'Milky',
            'address': '0x6fE3d0F096FC932A905accd1EB1783F6e4cEc717',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20269/thumb/milky.png?1638884521',
            'coingeckoId': 'milky-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BIG',
            'name': 'Dark Land Survival',
            'address': '0x6fDDA284250795DfBAB53955dA78de4b6F72c4f9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24439/thumb/dI9fHKQH_400x400.jpg?1647668868',
            'coingeckoId': 'dark-land-survival',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPRITZMOON',
            'name': 'SpritzMoon Crypto',
            'address': '0x6fC39AC154cfd20F1951A2823Abab7ec471B783a',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24401/thumb/SpritzMoon_Logo_200x200-ts1627473125.png?1647529710',
            'coingeckoId': 'spritzmoon-crypto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CVZ',
            'name': 'CryptoVsZombie',
            'address': '0x6fBB278C4eaa5218495a1858447B26D905ac0010',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22187/thumb/Copy_of_Copy_of_Logo_without_background%281%29.png?1641188118',
            'coingeckoId': 'cryptovszombie',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KAIJU',
            'name': 'Kaiju Worlds',
            'address': '0x6fB9D47EA4379CcF00A7dcb17E0a2C6C755a9b4b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18282/thumb/kaijuworlds_200.png?1631248864',
            'coingeckoId': 'kaiju-worlds',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IDTT',
            'name': 'Identity',
            'address': '0x6fB1E018f107d3352506c23777e4cd62e063584a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18384/thumb/identity.PNG?1631745919',
            'coingeckoId': 'identity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ERA',
            'name': 'Era7',
            'address': '0x6f9F0c4ad9Af7EbD61Ac5A1D4e0F2227F7B0E5f9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x6f9F0c4ad9Af7EbD61Ac5A1D4e0F2227F7B0E5f9.png',
            'coingeckoId': 'era7',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'FSTAR',
            'name': 'Future Star',
            'address': '0x6f8F7FD4C1C07dA8FA5C4D4e59AfbFD7b47d39C4',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17974/thumb/logo_-_2021-08-26T155653.745.png?1629964648',
            'coingeckoId': 'future-star',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SNFTS',
            'name': 'Seedify NFT Space',
            'address': '0x6f51A1674BEFDD77f7ab1246b83AdB9f13613762',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27131/thumb/seedify.jpg?1662516745',
            'coingeckoId': 'snfts-seedify-nft-space',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOGEY',
            'name': 'Doge Yellow Coin',
            'address': '0x6f373cD69c329B1DA2e00b861Ad950e59454aa18',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19966/thumb/dogeyellow.png?1636343629',
            'coingeckoId': 'doge-yellow-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MYRA',
            'name': 'Mytheria',
            'address': '0x6ef238E9E8CD2A96740897761C18894Fc086B9d0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20470/thumb/mytheria.PNG?1637099647',
            'coingeckoId': 'mytheria',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MARK',
            'name': 'Mark',
            'address': '0x6ea5f827130E81c6b82b6B852b7f0b505110e939',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0x6ea5f827130e81c6b82b6b852b7f0b505110e939/8ed97443a3a1f78044023621ff879a67.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'LEAP',
            'name': 'LEAP Token',
            'address': '0x6eEd9140F80F9E989CB23AeCBD20b97a29FFc80F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27258/thumb/LEAPtoken_LOGO.png?1663053803',
            'coingeckoId': 'leap-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFS',
            'name': 'Nfans',
            'address': '0x6eE002Ce31Ce4E52211EEfb2d3986082663D5F60',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19523/thumb/nfs.png?1635329151',
            'coingeckoId': 'nfans',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABYDOGO',
            'name': 'Baby Dogo Coin',
            'address': '0x6eB52Af9541BC5a7e9f671dAeCd300Cb55831A1E',
            'decimals': 0,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17369/thumb/200_%287%29.png?1627438733',
            'coingeckoId': 'baby-dogo-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NORD',
            'name': 'Nord Finance',
            'address': '0x6e9730EcFfBed43fD876A264C982e254ef05a0DE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13630/thumb/nord.jpg?1610465136',
            'coingeckoId': 'nord-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CSR',
            'name': 'Cashera',
            'address': '0x6e8Ff72962750F0Fa57A906F7833d1C657614abE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25286/thumb/icon200.png?1651129033',
            'coingeckoId': 'cashera',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DKD',
            'name': 'Dokdo',
            'address': '0x6e74332922C8AF262b3b8d682D85bD9D3c86D1Eb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27112/thumb/dokdo-dkd-logo.png?1662011442',
            'coingeckoId': 'dokdo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PSI',
            'name': 'Passive Income',
            'address': '0x6e70194F3A2D1D0a917C2575B7e33cF710718a17',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13695/thumb/psi.png?1634697509',
            'coingeckoId': 'passive-income',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FALAFEL',
            'name': 'Falafel',
            'address': '0x6e653888eD5e756840982BBA98D82Dd5EEf5D31B',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20426/thumb/falafel.png?1637032199',
            'coingeckoId': 'falafel',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ROCKETFI',
            'name': 'RocketFi',
            'address': '0x6e61579c22F9a6dA63a33e819f29B6697d2a126E',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26378/thumb/30jxINoE_400x400.jpeg?1657665435',
            'coingeckoId': 'rocketfi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'C4G3',
            'name': 'Cage Token',
            'address': '0x6e5476e9C14F3a3c3611BfCE694F2b3ceD81FA4C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BRG',
            'name': 'Bridge Oracle',
            'address': '0x6e4a971B81CA58045a2AA982EaA3d50C4Ac38F42',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12512/thumb/brg.png?1661754280',
            'coingeckoId': 'bridge-oracle',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'B2M',
            'name': 'Bit2Me',
            'address': '0x6e2a5EA25B161Befa6A8444C71ae3A89C39933c6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19848/thumb/b2m-circle-solid-default.png?1636036332',
            'coingeckoId': 'bit2me',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPHYNX',
            'name': 'Sphynx Labs',
            'address': '0x6e281f90c6D1b5E5A01FB6f14C428afF69c04C3F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25079/thumb/200x200-TRANS.png?1650261405',
            'coingeckoId': 'sphynx-labs',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GABECOIN',
            'name': 'Gabecoin',
            'address': '0x6e0BEF56b648b3eEBaE3808B8cBe7EFE8755AD9C',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16276/thumb/2021-06-05_14.31.59.png?1623633650',
            'coingeckoId': 'gabecoin',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'SBCC',
            'name': 'Smart Block Chain City',
            'address': '0x6e02Be885FcA1138038420fDdD4B41C59a8Cea6D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24822/thumb/V6xfvLzv_400x400.jpg?1649040978',
            'coingeckoId': 'smart-block-chain-city',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RU',
            'name': 'RIFI United',
            'address': '0x6dc923900B3000Bd074D1Fea072839D51c76E70e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20539/thumb/N7d6LRy.png?1637203621',
            'coingeckoId': 'rifi-united',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RVL',
            'name': 'Revolotto',
            'address': '0x6dc3d0D6EC970BF5522611D8eFF127145D02b675',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x6dc3d0d6ec970bf5522611d8eff127145d02b675.png',
            'coingeckoId': 'revolotto',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'DVX',
            'name': 'DRIVENx',
            'address': '0x6db3972c6a5535708e7A4F7Ad52F24d178D9A93e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x6db3972c6a5535708e7a4f7ad52f24d178d9a93e.png',
            'coingeckoId': 'drivenx',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'MS',
            'name': 'Morphswap',
            'address': '0x6d6554939D646f274d0FC3cEcB7dab5d76bc908F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28114/thumb/mslogo200.png?1668056773',
            'coingeckoId': 'morphswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATH',
            'name': 'AetherV2',
            'address': '0x6d3A0Fb0070EA61F901eBc0b675c30450ACAc737',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18588/thumb/logo_%281%29.png?1632556246',
            'coingeckoId': 'aetherv2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AKW',
            'name': 'ArkWorld',
            'address': '0x6d349929aFFB41Ba0b542a5981b24B426badF0a2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26478/thumb/logo.png?1658216625',
            'coingeckoId': 'arkworld',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COOKIE',
            'name': 'CookieSale',
            'address': '0x6d342877fC199c629f49A5C6C521C297b15BC92d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27898/thumb/logo.png?1666320004',
            'coingeckoId': 'cookiesale',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MFET',
            'name': 'MFET',
            'address': '0x6d23970ce32Cb0F1929bECE7C56D71319e1b4F01',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25555/thumb/200x200.png?1652421384',
            'coingeckoId': 'mfet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UCT',
            'name': 'UnitedCrowd',
            'address': '0x6d1DC3928604b00180Bb570BdAe94b9698d33b79',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14956/thumb/eUvRU9wm.png?1619142511',
            'coingeckoId': 'unitedcrowd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FDC',
            'name': 'Fidance',
            'address': '0x6d1A4650E83708b583C35d5E0952A0B46354Ca9b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27244/thumb/FDC_LOGO.jpg?1662883259',
            'coingeckoId': 'fidance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MERCE',
            'name': 'MetaMerce',
            'address': '0x6d163b653010740bfb41BED4bee23f94b3285cBA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25867/thumb/merce.png?1654431637',
            'coingeckoId': 'metamerce',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KUNCI',
            'name': 'Kunci Coin',
            'address': '0x6cf271270662be1C4fc1b7BB7D7D7Fc60Cc19125',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23723/thumb/xZX34B26_400x400.jpg?1645161855',
            'coingeckoId': 'kunci-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GTC',
            'name': 'Global Trust Coin',
            'address': '0x6cd871fb811224aa23B6bF1646177CdFe5106416',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/7752/thumb/gtib.png?1550222444',
            'coingeckoId': 'global-trust-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COGI',
            'name': '9D NFT',
            'address': '0x6cB755C4B82e11e727C05f697c790FdBC4253957',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19619/thumb/COGI_coin.png?1635496367',
            'coingeckoId': 'cogiverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOOND',
            'name': 'MoonsDust',
            'address': '0x6cA5FAc496bf94345958635E6e6171Dfe78f36bb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22050/thumb/moondlogo.png?1640676772',
            'coingeckoId': 'moonsdust',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PAE',
            'name': 'Ripae',
            'address': '0x6c7fc3Fd4a9f1Cfa2a69B83F92b9DA7EC26240A2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0x6c7fc3fd4a9f1cfa2a69b83f92b9da7ec26240a2/b7b649b74a01f26407e0b62bba80a8b0.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SNT',
            'name': 'Supernova Token',
            'address': '0x6c5544e720DCf8173Ff06FDC7Aa3A634994375D2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MOCEANS',
            'name': 'Oceans Miner',
            'address': '0x6c4D9bB6Be7dbB88cE3e0e7b6848E306e03fEC55',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26050/thumb/200x200-coin-mining_%281%29.png?1655450615',
            'coingeckoId': 'oceans-miner',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHILL',
            'name': 'Shillit App',
            'address': '0x6c427C94293335cA8D9b12EaD509398762B4D4BD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19486/thumb/gJcOvcoW_400x400.jpg?1635286531',
            'coingeckoId': 'shillit-app',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PNDR',
            'name': 'Pandora Protocol',
            'address': '0x6c1eFbEd2F57dd486Ec091dFfd08eE5235A570b1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18062/thumb/pandora.PNG?1630372562',
            'coingeckoId': 'pandora-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OCTI',
            'name': 'Oction',
            'address': '0x6c1dE9907263F0c12261d88b65cA18F31163F29D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14736/thumb/Oction_PNG.png?1618005253',
            'coingeckoId': 'oction',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WNYC',
            'name': 'Wrapped NewYorkCoin',
            'address': '0x6c015277B0f9b8c24B20BD8BbbD29FDb25738A69',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17123/thumb/J53IJI7.png?1626399992',
            'coingeckoId': 'wrapped-newyorkcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SMG',
            'name': 'Smaugs NFT',
            'address': '0x6bfd576220e8444CA4Cc5f89Efbd7f02a4C94C16',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15032/thumb/logo_-_2021-04-27T133336.154.png?1619501626',
            'coingeckoId': 'smaugs-nft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADX',
            'name': 'Ambire AdEx',
            'address': '0x6bfF4Fb161347ad7de4A625AE5aa3A1CA7077819',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x6bfF4Fb161347ad7de4A625AE5aa3A1CA7077819/logo.png',
            'coingeckoId': 'adex',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SFC',
            'name': 'SafeCap',
            'address': '0x6bbf411a9a50ef4427d64d1Ea74ad294c2BBb0c8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16907/thumb/safecap.jpeg?1625582714',
            'coingeckoId': 'safecap-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFEGALAXY',
            'name': 'SafeGalaxy',
            'address': '0x6b51231c43B1604815313801dB5E9E614914d6e4',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x6b51231c43b1604815313801db5e9e614914d6e4.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ASSET',
            'name': 'iAssets',
            'address': '0x6b471d5Ab9f3d92A600e7d49A0b135BF6D4c6A5b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25475/thumb/asset.png?1652006656',
            'coingeckoId': 'iassets',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XWG',
            'name': 'X World Games',
            'address': '0x6b23C89196DeB721e6Fd9726E6C76E4810a464bc',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x6b23C89196DeB721e6Fd9726E6C76E4810a464bc.png',
            'coingeckoId': 'x-world-games',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'SATA',
            'name': 'Signata',
            'address': '0x6b1C8765C7EFf0b60706b0ae489EB9bb9667465A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14704/thumb/logo.png?1617853256',
            'coingeckoId': 'signata',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'NFA',
            'name': 'NFTFundArt',
            'address': '0x6ae7E3BCB49A405061c6c5e18122AED853Be26bA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20340/thumb/200x200_%2839%29.png?1636941345',
            'coingeckoId': 'nftfundart',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BREWLABS',
            'name': 'Brewlabs',
            'address': '0x6aAc56305825f712Fd44599E59f2EdE51d42C3e7',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21928/thumb/7xgmOCBW_400x400.jpg?1640298648',
            'coingeckoId': 'brewlabs',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QATAR',
            'name': 'Qatar Inu',
            'address': '0x6aA97D60bf8647159E3B46c1A6a5132F1C740B54',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27572/thumb/IMG_20220930_030313_220.jpg?1664527907',
            'coingeckoId': 'qatar-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PFI',
            'name': 'Protocol Finance',
            'address': '0x6a9ba54F86b46900b1E4d1e6a13FB9F15F016A7F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13810/thumb/17RgoN2.png?1612047312',
            'coingeckoId': 'protocol-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRVL',
            'name': 'TRVL',
            'address': '0x6a8Fd46F88dBD7bdC2D536C604f811C63052ce0F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20911/thumb/trvl.jpeg?1649390006',
            'coingeckoId': 'dtravel',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KOL',
            'name': 'King of Legends',
            'address': '0x6a731582f6189477c379A8da7d26Dcec3F0a0919',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27855/thumb/logo_200_200.png?1666263753',
            'coingeckoId': 'king-of-legends-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MNST',
            'name': 'MoonStarter',
            'address': '0x6a6Ccf15B38DA4b5B0eF4C8fe9FefCB472A893F9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16146/thumb/MoonStarter.png?1623120360',
            'coingeckoId': 'moonstarter',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SMC',
            'name': 'Skate Metaverse Coin',
            'address': '0x6a6585B7C3DeF7B8b65C4Bc670f2f9713846c9d1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26109/thumb/200.png?1655884488',
            'coingeckoId': 'skate-metaverse-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ECON',
            'name': 'Recession Coin',
            'address': '0x6a59e047Bd8AFa31a175B2056b44D1235AD4D852',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26819/thumb/21193.png?1660279551',
            'coingeckoId': 'recession-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CFI',
            'name': 'CyberFi',
            'address': '0x6a545f9c64d8f7B957D8D2e6410B52095A9E6c29',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13112/thumb/cyberfi_logo.jpeg?1605283367',
            'coingeckoId': 'cyberfi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BINOM',
            'name': 'Binom Protocol',
            'address': '0x6a4368C8358488c0ab37F561Fcb16AB751917201',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20037/thumb/binom-logo-200px.png?1636424202',
            'coingeckoId': 'binom-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FFN',
            'name': 'Fairy Forest',
            'address': '0x6a381bbCA11E8DA31331E2A9821aa7e7B04717A7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25076/thumb/3g200x200-px-2.png?1650260507',
            'coingeckoId': 'fairy-forest',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PEX',
            'name': 'PearDAO',
            'address': '0x6a0b66710567b6beb81A71F7e9466450a91a384b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x6a0b66710567b6beb81A71F7e9466450a91a384b.png',
            'coingeckoId': 'peardao',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'XP',
            'name': 'Xpose Protocol',
            'address': '0x6a0b045Ea1cbC87946eE6ca4D118dC0B5c152455',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15414/thumb/xpose.PNG?1620773427',
            'coingeckoId': 'xpose-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WAULT',
            'name': 'Wault Finance',
            'address': '0x6Ff2d9e5891a7a7c554b80e0D1B791483C78BcE9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0x6ff2d9e5891a7a7c554b80e0d1b791483c78bce9.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'AIRX',
            'name': 'Aircoins',
            'address': '0x6Fb05b156788E88c8ad1e057e729362ff8c39d93',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/9201/thumb/Aircoins.png?1591615033',
            'coingeckoId': 'aircoins',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TOTM',
            'name': 'TotemFi',
            'address': '0x6FF1BFa14A57594a5874B37ff6AC5efbD9F9599A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14680/thumb/TOTM.png?1617721704',
            'coingeckoId': 'totemfi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VET',
            'name': 'VeChain',
            'address': '0x6FDcdfef7c496407cCb0cEC90f9C5Aaa1Cc8D888',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x6FDcdfef7c496407cCb0cEC90f9C5Aaa1Cc8D888.png',
            'coingeckoId': null,
            'listedIn': [
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'SWPR',
            'name': 'The Swappery',
            'address': '0x6FA23529476a1337EB5da8238b778e7122d79666',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24260/thumb/output-onlinepngtools.png?1647170874',
            'coingeckoId': 'swappery-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'anyETH',
            'name': 'ANY Ethereum',
            'address': '0x6F817a0cE8F7640Add3bC0c1C2298635043c2423',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x6f817a0ce8f7640add3bc0c1c2298635043c2423.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SGMT',
            'name': 'SuperStep',
            'address': '0x6F7a88274647ad54BCbE25e9d28c51DDB6b5B55F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26232/thumb/33z7sf9g_400x400.png?1656899678',
            'coingeckoId': 'superstep',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARPA',
            'name': 'ARPA',
            'address': '0x6F769E65c14Ebd1f68817F5f1DcDb61Cfa2D6f7e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x6F769E65c14Ebd1f68817F5f1DcDb61Cfa2D6f7e/logo.png',
            'coingeckoId': 'arpa',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ST',
            'name': 'Sealem',
            'address': '0x6F706319C46D1b28508E8e4bCaA59e47D15e028C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26227/thumb/200X200_2.png?1656907010',
            'coingeckoId': 'sealem-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METANIA',
            'name': 'MetaniaGames',
            'address': '0x6F64cC61d0d5542E40e6f2828cBddA84507D214D',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22927/thumb/metania-200.png?1643003026',
            'coingeckoId': 'metaniagames',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FRA',
            'name': 'France Fan Token',
            'address': '0x6F6312245B45F96461b10647097187CC2cbDb374',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27780/thumb/France_Fan_Token.jpg?1665732745',
            'coingeckoId': 'france-fan-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WPC',
            'name': 'WePiggy Coin',
            'address': '0x6F620EC89B8479e97A6985792d0c64F237566746',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21914/thumb/WPC200.png?1640262945',
            'coingeckoId': 'wepiggy-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XPX',
            'name': 'ProximaX',
            'address': '0x6F3AAf802F57D045efDD2AC9c06d8879305542aF',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/3735/thumb/proximax.png?1548332126',
            'coingeckoId': 'proximax',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SNT',
            'name': 'Share NFT',
            'address': '0x6F3619D033fc96D322aACd22665461F040106a04',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25517/thumb/19868.png?1652167282',
            'coingeckoId': 'share-nft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HOPE',
            'name': 'Hope',
            'address': '0x6F2aFbF4f5e5e804C5b954889d7Bf3768A3C9a45',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15106/thumb/hope.PNG?1619733594',
            'coingeckoId': 'hope-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WKC',
            'name': 'Wiki Cat',
            'address': '0x6Ec90334d89dBdc89E08A133271be3d104128Edb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26037/thumb/Wiki-Logo.png?1655886306',
            'coingeckoId': 'wiki-cat',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GLD',
            'name': 'Goldario',
            'address': '0x6EF7E2D571f9806ab8FAAB73a76A97442BF78e3b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19125/thumb/8uCoY6hD_400x400.jpg?1634521398',
            'coingeckoId': 'goldario',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FFS',
            'name': 'FiFaSport',
            'address': '0x6E9da6BC1ACDC6fCD01e89233D00F9d335BBAe99',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26902/thumb/FFS-logo-200x200.png?1660724883',
            'coingeckoId': 'fifasport',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RPT',
            'name': 'Represent',
            'address': '0x6E9A01F93ff2634F3296C536738DD593B10C524F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18456/thumb/NmHcS53-_400x400.jpg?1632106591',
            'coingeckoId': 'represent',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INAZ',
            'name': 'Infinity Arena',
            'address': '0x6E551e88D0eD3EBD56F6b1F42B03BF9E4d68C47f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24315/thumb/0tMkBSx9_400x400.jpg?1647353638',
            'coingeckoId': 'infinity-arena',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MARS',
            'name': 'Marsupilamii',
            'address': '0x6E53E24f0f7B273d27D92a909A30762d5734B649',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24480/thumb/mars.png?1647777582',
            'coingeckoId': 'marsupilamii',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POLP',
            'name': 'PolkaParty',
            'address': '0x6E3bF2fFf13e18413D3780f93753D6CFf5AEE3e1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17867/thumb/polp_logo.jpg?1641355239',
            'coingeckoId': 'polkaparty',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'PICO',
            'name': 'PiConnect',
            'address': '0x6E1d1F8f91e5C9C35B8fd361471286487Cc1eAA4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27840/thumb/LOGO_200x200.png?1666058023',
            'coingeckoId': 'piconnect',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHAIN',
            'name': 'ChainSwaps',
            'address': '0x6DdA867f8f0019Fe108e3b4c7187C1D0a2d57897',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27192/thumb/ChainSwapsCoin_200_200.png?1662534608',
            'coingeckoId': 'chainswaps',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORCAS',
            'name': 'Orcinus',
            'address': '0x6Da5BFe611853036B507fad9E9D1717CD5c34915',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x6da5bfe611853036b507fad9e9d1717cd5c34915/logo.png',
            'coingeckoId': 'n/a',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'TRV',
            'name': 'Triveum',
            'address': '0x6Da17A4f3Df75533ab1bcf7ff73c3F03dA70B7BD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25619/thumb/TRV2.png?1652856777',
            'coingeckoId': 'triveum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MRAT',
            'name': 'Moon Rat Token',
            'address': '0x6D949f9297A522c0f97C232CC209a67Bd7CfA471',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x6d949f9297a522c0f97c232cc209a67bd7cfa471.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'TGOLD',
            'name': 'Taroverse Gold',
            'address': '0x6D91eD61CE0af2Bd8b9037462443385A72DCB1DE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26595/thumb/TaroGold_%281%29.png?1658973613',
            'coingeckoId': 'taroverse-gold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NUX',
            'name': 'Peanut',
            'address': '0x6D8734002fBffE1c86495e32c95f732fC77F6F2A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13958/thumb/2sAMZXpO_400x400.jpg?1613353972',
            'coingeckoId': 'peanut',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KATA',
            'name': 'Katana Inu',
            'address': '0x6D6bA21E4C4b29CA7Bfa1c344Ba1E35B8DaE7205',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21872/thumb/Katana_Inu512.png?1640236880',
            'coingeckoId': 'katana-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FWC',
            'name': 'FWC community Token',
            'address': '0x6D3a160B86eDcD46D8F9bBa25c2F88ccCADe19fc',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24258/thumb/CKea7QZVZpo4kuYW8A2V5pkOXMMUMyY6UH7kdvKS.png?1653635936',
            'coingeckoId': 'qatar-2022',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'ZBC',
            'name': 'ZBC',
            'address': '0x6D1054C3102E842314e250b9e9C4Be327b8DaaE2',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/18860.png',
            'coingeckoId': null,
            'listedIn': [
                'xyfinance'
            ]
        },
        {
            'symbol': 'ToySHIBA',
            'name': 'ToySHIBA',
            'address': '0x6D050F273D1588AF26F61bf58f4D98EFFB3dBA82',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x6d050f273d1588af26f61bf58f4d98effb3dba82/logo.png',
            'coingeckoId': 'toyshiba',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'WEAVE',
            'name': 'Weave',
            'address': '0x6CC97eF7D330C090681c3a6d266F6AdeDf80e56B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22539/thumb/Weave_logo.png?1642025896',
            'coingeckoId': 'weave',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EMBR',
            'name': 'Embr',
            'address': '0x6CB8065F96d63630425fd95A408A0D6cD697C662',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20904/thumb/embr.png?1659854613',
            'coingeckoId': 'embr',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPT',
            'name': 'Septillion',
            'address': '0x6CAF6B9761b786a9E9f3634320Ee5061d3C0Ab59',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26408/thumb/token.png?1657924387',
            'coingeckoId': 'septillion',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NINO',
            'name': 'Ninneko',
            'address': '0x6CAD12b3618a3C7ef1FEb6C91FdC3251f58c2a90',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19936/thumb/CXZox2iX_400x400.jpg?1636327291',
            'coingeckoId': 'ninneko',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FINO',
            'name': 'FINO DAO',
            'address': '0x6C9AE5d6746ee1B6bD03310079AA11d8EC409212',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23993/thumb/fino.png?1645951880',
            'coingeckoId': 'fino-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TNNS',
            'name': 'TNNS',
            'address': '0x6C7C87D9868b1dB5a0F62d867bAa90e0AdFA7Cfd',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23926/thumb/output-onlinepngtools.png?1645689924',
            'coingeckoId': 'tnns',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELFI',
            'name': 'ELYFI',
            'address': '0x6C619006043EaB742355395690c7b42d3411E8c0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23733/thumb/elyfi_logo.png?1645691480',
            'coingeckoId': 'elyfi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'THS',
            'name': 'Theoscoin',
            'address': '0x6C5FF116bFFc50Ff59d0334d5643e15b71C6414A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18497/thumb/logo_theos_200x200.png?1632208429',
            'coingeckoId': 'theoscoin',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'LTRBT',
            'name': 'Little Rabbit V2',
            'address': '0x6C46422A0f7dbbAD9BEC3BbBC1189bfAf9794B05',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27327/thumb/ltrbt.png?1663326133',
            'coingeckoId': 'little-rabbit-v2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CR7',
            'name': 'CR7 Token',
            'address': '0x6C43751FeF27C956f7E75D5c345a65df1465f7E0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x6c43751fef27c956f7e75d5c345a65df1465f7e0.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'TARP',
            'name': 'Totally A Rug Pull',
            'address': '0x6C0A568a3fFb61957812fb3e300e4C10B708d336',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22338/thumb/146998865-2b14c6b0-0a51-43c5-b8f0-78b994e71522.png?1641540732',
            'coingeckoId': 'totally-a-rug-pull',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ECT',
            'name': 'Ecochain Finance',
            'address': '0x6Bfd4cA8eC078d613eD6a5248Eb2c7a0d5c38b7b',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18304/thumb/Logo-Transparency.png?1631503556',
            'coingeckoId': 'ecochain-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RAIN',
            'name': 'Rainmaker Games',
            'address': '0x6Bcd897D4BA5675F860C7418ddc034f6c5610114',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21485/thumb/Final-Flip-Rain-Makers-44-1.png?1639362827',
            'coingeckoId': 'rainmaker-games',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRADE',
            'name': 'Polytrade',
            'address': '0x6Ba7a8f9063c712C1c8CabC776B1dA7126805f3b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16416/thumb/Logo_colored_200.png?1623979654',
            'coingeckoId': 'polytrade',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OUSD',
            'name': 'Synth oUSD',
            'address': '0x6BF2Be9468314281cD28A94c35f967caFd388325',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16830/thumb/cUixn42.png?1625580669',
            'coingeckoId': 'synth-ousd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RH31',
            'name': 'Rabbit Halloween',
            'address': '0x6BE940759586A4f479086d7C606cdb6ba60Ce6B3',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28067/thumb/200.png?1667374961',
            'coingeckoId': 'rabbit-halloween',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HER',
            'name': 'HeroVerse',
            'address': '0x6B9F6f911384886b2e622e406327085238F8A3C5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18571/thumb/hero.png?1633415641',
            'coingeckoId': 'heroverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLOW',
            'name': 'Flower',
            'address': '0x6B9943e598D8dA89757Caa687eC141c961231FA8',
            'decimals': 10,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24630/thumb/Logo_solo_flower_en_rosado.png?1648459025',
            'coingeckoId': 'flower',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AERO-V2',
            'name': 'Aerochain Coin V2',
            'address': '0x6B992D72bDA6a5463aefA01e4790ea3767Af91c2',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23807/thumb/5_6312017193856926639.png?1645510058',
            'coingeckoId': 'aerochain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DNL',
            'name': 'Dinoland',
            'address': '0x6B9481FB5465ef9Ab9347b332058D894aB09B2f6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23554/thumb/Logo-token200px.png?1644471651',
            'coingeckoId': 'dinoland',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BDC',
            'name': 'Based',
            'address': '0x6B925F0C776263bF8B3579825e94E40a5631e1cE',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23021/thumb/basedtoken.png?1643095018',
            'coingeckoId': 'based-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABYOKX',
            'name': 'BABYOKX  BSC ',
            'address': '0x6B670D593d10207Cb59b1A88ae4b8b8BA18E52b4',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24970/thumb/BABYOKX-200x200.png?1649645366',
            'coingeckoId': 'babyokx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOR',
            'name': 'Morcilla War',
            'address': '0x6B61B24504a6378e1A99d2aA2A5EFCB1F5627A3a',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21661/thumb/16150.png?1639698814',
            'coingeckoId': 'morcilla-war',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PXT',
            'name': 'POLYX',
            'address': '0x6B226E4F3eF5708D496cD1b9E582ea090F3fFf70',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19717/thumb/Screenshot-2021-10-31-at-15-10-38.png?1635759418',
            'coingeckoId': 'polyx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IVG',
            'name': 'IVOGEL',
            'address': '0x6Af6789856a2e820E3d145bfe4950Ff17e3A4Ecb',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17691/thumb/IMG_20210808_035857_984.jpg?1628947200',
            'coingeckoId': 'ivogel',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POLC',
            'name': 'Polkacity',
            'address': '0x6Ae9701B9c423F40d54556C9a443409D79cE170a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14066/thumb/vykih1Nq_400x400.png?1614130959',
            'coingeckoId': 'polka-city',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'WOLFIES',
            'name': 'WOLF PUPS',
            'address': '0x6Ad2B6d5d8F96c8E581D3100C12878b2151A0423',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25133/thumb/wolfies.png?1650426815',
            'coingeckoId': 'wolf-pups-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MEFA',
            'name': 'Metaverse Face',
            'address': '0x6Ad0F087501Eee603AeDa0407c52864bc7f83322',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21375/thumb/ZBC4FWKR_400x400.jpg?1639032278',
            'coingeckoId': 'metaverse-face',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PLGR',
            'name': 'Pledge',
            'address': '0x6Aa91CbfE045f9D154050226fCc830ddbA886CED',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18243/thumb/PLGR.png?1644808236',
            'coingeckoId': 'pledge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ACEC',
            'name': 'Ace Cash',
            'address': '0x6AF7bbF137b93aB5cc8F24F85B9c8cfAb807bC7A',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26314/thumb/IMG_20220707_112252_931.jpg?1657240356',
            'coingeckoId': 'ace-cash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SCIE',
            'name': 'Scientia',
            'address': '0x6AF2f57f61Cec0883C71F3175774EBeb290a10e6',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20450/thumb/scienta.PNG?1637044446',
            'coingeckoId': 'scientia',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRYSTAL',
            'name': 'Cyber Crystal',
            'address': '0x6AD7e691f1d2723523e70751f82052A8A2C47726',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22614/thumb/crystal.png?1642250414',
            'coingeckoId': 'cyber-crystal',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPIN',
            'name': 'Spintop',
            'address': '0x6AA217312960A21aDbde1478DC8cBCf828110A67',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x6AA217312960A21aDbde1478DC8cBCf828110A67.png',
            'coingeckoId': 'spintop',
            'listedIn': [
                'coingecko',
                'pancake',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'FERMA',
            'name': 'Ferma',
            'address': '0x6AA150fff813e0bEc1273691f349Ad080DF7216d',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14482/thumb/ferma.png?1616463681',
            'coingeckoId': 'ferma',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TTC',
            'name': 'TechTrees',
            'address': '0x6A684b3578f5B07c0Aa02fAFc33ED248AE0c2dB2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27256/thumb/304860515_105919468924966_4999712311597560770_n.jpg?1663051760',
            'coingeckoId': 'techtrees',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'EGGPLUS',
            'name': 'EggPlus',
            'address': '0x6A4fcA7762C961F877Edbb486Dfbdb08483BEa0f',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26362/thumb/200x200.png?1657595342',
            'coingeckoId': 'eggplus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KIRBY',
            'name': 'Kirby',
            'address': '0x6A1Ce0B1858D38CF1c0346d8471a3E1967bF5A1B',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26714/thumb/Vt4BKVKT_400x400.jpg?1659687935',
            'coingeckoId': 'kirby',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MEFI',
            'name': 'Metabusiness',
            'address': '0x6A06d3fDD17bc335fa076F544794879E22aAd4B2',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25189/thumb/200x200.png?1650605062',
            'coingeckoId': 'metabusiness',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NRCH',
            'name': 'EnreachDAO',
            'address': '0x69fa8e7F6bf1ca1fB0de61e1366f7412b827CC51',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14694/thumb/enreachdao.jpg?1617813758',
            'coingeckoId': 'enreachdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APK',
            'name': 'Ape King',
            'address': '0x69e4c35C48ABB20E83813cADF8Dbe92Df6FE34Bd',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27343/thumb/logo_%281%29.png?1663583793',
            'coingeckoId': 'ape-king',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EVAPE',
            'name': 'EveryApe BSC',
            'address': '0x69d1ff85004a445A892dFbD88DF00D48fb0aF638',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18477/thumb/EVERYAPE.jpg?1632135546',
            'coingeckoId': 'everyape-bsc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETHSHIB',
            'name': 'Eth Shiba',
            'address': '0x69d10c8Bd0de1a9345AFA36819490D8BbCE0E5A3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24579/thumb/ETH-SHIBA-200x200-1.png?1648207935',
            'coingeckoId': 'eth-shiba',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CTG',
            'name': 'Cryptorg',
            'address': '0x69bFa36D50d92e8985d27E6AA6e685C0325ce7B4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/11474/thumb/crystal_200.png?1590450209',
            'coingeckoId': 'cryptorg-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPACEPI',
            'name': 'SpacePi',
            'address': '0x69b14e8D3CEBfDD8196Bfe530954A0C226E5008E',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x69b14e8d3cebfdd8196bfe530954a0c226e5008e.png',
            'coingeckoId': 'spacepi',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'RFUEL',
            'name': 'RioDeFi',
            'address': '0x69a1913d334b524ea1632461C78797c837CA9fa6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12623/thumb/RFUEL_SQR.png?1602481093',
            'coingeckoId': 'rio-defi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INUGAMI',
            'name': 'Inugami',
            'address': '0x69FFEFF99cD788e5E737FaA713C6bbf822d53913',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24562/thumb/INUGAMI-BLACK.jpg?1648189557',
            'coingeckoId': 'inugami',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BBL',
            'name': 'BlockBlend',
            'address': '0x69B0af16FDd2E80968eb505cd41DC26efb2B80BF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24858/thumb/blockblend.png?1649146225',
            'coingeckoId': 'blockblend',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UDOGE',
            'name': 'Uka Doge Coin',
            'address': '0x698111b771363B81D1179AD102e3d8B9cD093a11',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25705/thumb/logo.png?1653460360',
            'coingeckoId': 'uka-doge-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DIFX',
            'name': 'Digital Financial Exchange',
            'address': '0x697bd938e7E572E787ecd7bC74a31f1814C21264',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23241/thumb/difx.png?1647167550',
            'coingeckoId': 'digital-financial-exchange',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MATE',
            'name': 'Vmates',
            'address': '0x696c2D3c711d5727c3686672F411583faeDAA29F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21931/thumb/vates.PNG?1640300141',
            'coingeckoId': 'vmates',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'renZEC',
            'name': 'renZEC',
            'address': '0x695FD30aF473F2960e81Dc9bA7cB67679d35EDb7',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x695FD30aF473F2960e81Dc9bA7cB67679d35EDb7/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'TRIP',
            'name': 'Tripedia',
            'address': '0x692F6bbeA88Da58268cCE0fc92897E416eCCC03C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/11014/thumb/Tripedia.jpeg?1647487922',
            'coingeckoId': 'tripedia',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CSD',
            'name': 'Crystal Dust',
            'address': '0x69285d53A5005E96dE2a13834a802F9368150cE3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26416/thumb/Logo_Vector_M_%281%29.png?1657926046',
            'coingeckoId': 'crystal-dust',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DKC',
            'name': 'Dukecoin',
            'address': '0x691D658ecDC3554672Ba007335cC139BFe67bab9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20232/thumb/duke-coin-profile.png?1650968549',
            'coingeckoId': 'dukecoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SNP',
            'name': 'Synapse Network',
            'address': '0x6911F552842236bd9E8ea8DDBB3fb414e2C5FA9d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x6911f552842236bd9e8ea8ddbb3fb414e2c5fa9d.png',
            'coingeckoId': 'synapse-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'PFY',
            'name': 'Portify',
            'address': '0x69083b64988933E8B4783E8302b9bBf90163280E',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x69083b64988933e8b4783e8302b9bbf90163280e.png',
            'coingeckoId': 'portify',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SURGE',
            'name': 'Surge Inu',
            'address': '0x68fbD4D89BA15343E7D2457189459b7AC80a20a3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18963/thumb/91mZRJs.png?1634026306',
            'coingeckoId': 'surge-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PKEX',
            'name': 'PolkaEx',
            'address': '0x68edF56289134b41C6583c0e8fc29fbD7828aCa4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18616/thumb/1024-1024-02.png?1632698540',
            'coingeckoId': 'polkaex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PHL',
            'name': 'Philcoin',
            'address': '0x68dD887d012aBdF99d3492621E4D576A3F75019D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24178/thumb/2ZFyoMSk.png?1646901982',
            'coingeckoId': 'philcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GA',
            'name': 'Golden Age',
            'address': '0x68dB7736Df537fcf5504dc701D720457C279719D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20745/thumb/IwqVsND.png?1637634015',
            'coingeckoId': 'golden-age',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SILVA',
            'name': 'Silva',
            'address': '0x68b5edb385b59E30a7A7Db1E681a449E94DF0213',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19689/thumb/13592.png?1635748090',
            'coingeckoId': 'silva-token',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'MIST',
            'name': 'Mist',
            'address': '0x68E374F856bF25468D365E539b700b648Bf94B67',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0x68e374f856bf25468d365e539b700b648bf94b67.png',
            'coingeckoId': 'mist',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'MTG',
            'name': 'MagnetGold',
            'address': '0x68D10dFe87a838D63BbEf6c9A0D0b44beB799DC1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19302/thumb/mtg.PNG?1634917649',
            'coingeckoId': 'magnetgold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BB',
            'name': 'BB Gaming',
            'address': '0x688eC465111ed639267cB17C47E790c9cc7279c1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24616/thumb/bb.png?1648375444',
            'coingeckoId': 'bb-gaming',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ABR',
            'name': 'Allbridge',
            'address': '0x68784ffaa6Ff05E3e04575DF77960DC1D9F42b4a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18690/thumb/abr.png?1640742053',
            'coingeckoId': 'allbridge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OPUL',
            'name': 'Opulous',
            'address': '0x686318000d982bc8dCC1cdCF8fFd22322F0960Ed',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16548/thumb/opulous.PNG?1624418744',
            'coingeckoId': 'opulous',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SMRAT',
            'name': 'Secured MoonRat',
            'address': '0x68590a47578E5060a29fd99654f4556dBfa05D10',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15241/thumb/SMRAT_200x200.png?1620199864',
            'coingeckoId': 'secured-moonrat-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STACK',
            'name': 'StackOS',
            'address': '0x6855f7bb6287F94ddcC8915E37e73a3c9fEe5CF3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x6855f7bb6287f94ddcc8915e37e73a3c9fee5cf3.png',
            'coingeckoId': 'stackos',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'GHC',
            'name': 'Galaxy Heroes Coin  OLD ',
            'address': '0x683fae4411249Ca05243dfb919c20920f3f5bfE0',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1642565332542_9980540162447473.png',
            'coingeckoId': 'galaxy-heroes-coin',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'KATZ',
            'name': 'Lucky Cats',
            'address': '0x683bbAA70fd8E2CF62617E48B100A7609Ee48946',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27662/thumb/Screenshot_2022-09-01_at_13.49.26.png?1665103433',
            'coingeckoId': 'lucky-cats',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPARK',
            'name': 'SparkLab',
            'address': '0x683b383E9D6Cc523F4C9764daceBB5752892fc53',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19839/thumb/sparklab.PNG?1636033340',
            'coingeckoId': 'sparklab',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SSF',
            'name': 'SecretSky Finance',
            'address': '0x6836162E97657759AeDa8048e4308050cbAB7fFa',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16467/thumb/icon-ssf.png?1624265389',
            'coingeckoId': 'secretsky-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POOR',
            'name': 'Poor Quack',
            'address': '0x682cA13182eCA9e5230d8654215037815288B556',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22525/thumb/16932.png?1641980086',
            'coingeckoId': 'poor-quack',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GBAG',
            'name': 'Giftbag',
            'address': '0x6820f244b81d0906D70266Cc9bCcC2A8EAa5464c',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20593/thumb/oqwoymka_400x400.jpg?1637285892',
            'coingeckoId': 'giftbag',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLY',
            'name': 'Franklin',
            'address': '0x681fd3e49a6188Fc526784eE70AA1C269ee2B887',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14810/thumb/fly_logo_sq_bArtboard_4.png?1626420796',
            'coingeckoId': 'franklin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DNS',
            'name': 'DinoStep',
            'address': '0x680891034831e06ba48bC5283412D41A5F675404',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25675/thumb/1FVbo3BN_400x400.jpeg?1653364921',
            'coingeckoId': 'dinostep',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARC',
            'name': 'Arcade',
            'address': '0x67f785D5CF3784E8F35876dF323acf193350081f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21697/thumb/arcade.jpg?1639722477',
            'coingeckoId': 'arcade',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PULSEMOON',
            'name': 'PulseMoon',
            'address': '0x67efDE48F3D64d9fc5493258A75B43CE3E3f5425',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20456/thumb/14628.png?1637062079',
            'coingeckoId': 'pulsemoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DODO',
            'name': 'DODO',
            'address': '0x67ee3Cb086F8a16f34beE3ca72FAD36F7Db929e2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x43dfc4159d86f3a37a5a4b3d4580b888ad7d4ddd.png',
            'coingeckoId': 'dodo',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FEED',
            'name': 'Feeder Finance',
            'address': '0x67d66e8Ec1Fd25d98B3Ccd3B19B7dc4b4b7fC493',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x67d66e8ec1fd25d98b3ccd3b19b7dc4b4b7fc493.png',
            'coingeckoId': 'feeder-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SOUL',
            'name': 'APOyield',
            'address': '0x67d012F731c23F0313CEA1186d0121779c77fcFE',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x67d012f731c23f0313cea1186d0121779c77fcfe.png',
            'coingeckoId': 'apoyield',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'TAF',
            'name': 'TAF',
            'address': '0x67c6E77E2C783a818Bbea7733585779b38B00ef2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22808/thumb/TAF-Logo.png?1649835774',
            'coingeckoId': 'taf-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAND',
            'name': 'The Sandbox',
            'address': '0x67b725d7e342d7B611fa85e859Df9697D9378B2e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x67b725d7e342d7B611fa85e859Df9697D9378B2e.png',
            'coingeckoId': null,
            'listedIn': [
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'PEECOIN',
            'name': 'PeeCoin Charts',
            'address': '0x67Fe403A75d871b992143fa15474f542a7892A6e',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24946/thumb/peecoin.png?1649495734',
            'coingeckoId': 'peecoin-charts',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MMSC',
            'name': 'MMS Coin',
            'address': '0x67Db74b6D1Ea807CB47248489c99D144323D348d',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18695/thumb/MMSC_logo.png?1654677904',
            'coingeckoId': 'mms-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TLW',
            'name': 'The Last War',
            'address': '0x67CdB81B89eDD61158C60E8B97A65f3Cb115d52E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23986/thumb/tlw.png?1645947161',
            'coingeckoId': 'the-last-war',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HAM',
            'name': 'Hamster',
            'address': '0x679D5b2d94f454c950d683D159b87aa8eae37C9e',
            'decimals': 7,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16115/thumb/hamster.png?1623033031',
            'coingeckoId': 'hamster',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BONE',
            'name': 'Bone',
            'address': '0x67915E893b68FbC436a288564ffF1476B632B009',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24860/thumb/Bone_200_x_200.jpeg?1656560269',
            'coingeckoId': 'bone-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRTS',
            'name': 'Cratos',
            'address': '0x678e840C640F619E17848045D23072844224dD37',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17322/thumb/cratos.png?1627301460',
            'coingeckoId': 'cratos',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUSDM',
            'name': 'BUSDmeta',
            'address': '0x678C109Bc4A812A80550707a4062442C0Ca0D2Ca',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x678c109bc4a812a80550707a4062442c0ca0d2ca/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BYP',
            'name': 'BrightyPad',
            'address': '0x6785342b199aa152F69f577AC241A88663B5d57A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27076/thumb/rsz_byp_logo%281%29.png?1661756351',
            'coingeckoId': 'brightypad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALP',
            'name': 'CoinAlpha',
            'address': '0x6775729FaD1438116b2E3B4Fb2878539795297A7',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18797/thumb/alp.PNG?1633472202',
            'coingeckoId': 'coinalpha',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LAEEB',
            'name': 'Laeeb Inu',
            'address': '0x676F2E357c08a513c2F680694Bb28668bcd936C0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27146/thumb/200x200px.png?1662086885',
            'coingeckoId': 'laeeb-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AGEN',
            'name': 'Alpha Genesis',
            'address': '0x67678538f1dC3AB1da94ca8D048B5670B3961cef',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26334/thumb/iScv-pkp_400x400.jpeg?1657495584',
            'coingeckoId': 'alpha-genesis',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DBET',
            'name': 'DefiBet',
            'address': '0x67654acD0fA49f98c2A9a6a6135D0CCB88836A85',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23125/thumb/R4y30VWE_400x400.jpg?1643268068',
            'coingeckoId': 'defibet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VIP',
            'name': 'VIP',
            'address': '0x6759565574De509b7725ABb4680020704B3F404e',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20994/thumb/hVOVuxlC_400x400.jpg?1638172062',
            'coingeckoId': 'vip-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EFUN',
            'name': 'EFUN',
            'address': '0x6746E37A756DA9E34f0BBF1C0495784Ba33b79B4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21882/thumb/6GyaKgva_400x400.jpg?1640214177',
            'coingeckoId': 'efun',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'THUGS',
            'name': 'Thugs',
            'address': '0x6742240F60C52197b9fadca1f1347F45f6897F92',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25988/thumb/giAEOuRd_400x400.jpg?1655178999',
            'coingeckoId': 'thugs',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RAINBOWTOKEN',
            'name': 'RainbowToken',
            'address': '0x673Da443da2f6aE7c5c660A9F0D3DD24d1643D36',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17828/thumb/WsLiOeJ.png?1637337787',
            'coingeckoId': 'rainbowtoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ISTEP',
            'name': 'iSTEP',
            'address': '0x67343c29c0fD9827F33E675e0eB80773f9444444',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25888/thumb/ISTEP.png?1654420630',
            'coingeckoId': 'istep',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SDC',
            'name': 'SkyDOS',
            'address': '0x672d15D2B8FB13f5295faB4b2e82b394AB967dE6',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25161/thumb/logo.jpg?1650510114',
            'coingeckoId': 'skydos',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAMA',
            'name': 'Masterpiece Maker',
            'address': '0x6710D8658094D1F02276b677345078f816BdB8f3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27788/thumb/mama.png?1665816392',
            'coingeckoId': 'masterpiece-maker',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASHIB',
            'name': 'Alien Shiba Inu',
            'address': '0x66eb97542e35FB514ab378253386792eA6536FB6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20050/thumb/ashib.png?1636427051',
            'coingeckoId': 'alien-shiba-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'sSPELL',
            'name': 'Staked Spell',
            'address': '0x66eFF5221ca926636224650Fd3B9c497FF828F7D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/logos/main/network/binance/0x66eFF5221ca926636224650Fd3B9c497FF828F7D.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'PACT',
            'name': 'PactSwap',
            'address': '0x66e7CE35578A37209d01F99F3d2fF271f981F581',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15265/thumb/pact.PNG?1620281262',
            'coingeckoId': 'packswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TPV',
            'name': 'TravGoPV',
            'address': '0x66deDB1A6D137C29274489F5d68E9840e1eEc213',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21250/thumb/248041546_104759122006442_8579359337207343298_n.jpg?1638773363',
            'coingeckoId': 'travgopv',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VODKA',
            'name': 'Vodka',
            'address': '0x66dA5cc445feC38A9475Ae7941f89110b9d256b9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20848/thumb/Vodka-Token-LOGO.png?1637760753',
            'coingeckoId': 'vodka-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFFRON',
            'name': 'Saffron',
            'address': '0x66cdd54Dfdf653FF288eD3c85D7CDD82bA142Ce7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26009/thumb/Saffron_logo-200.png?1655280038',
            'coingeckoId': 'saffron-qaenat',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RD2E',
            'name': 'Ride To Earn',
            'address': '0x66E1e0EA96aBB155932aF031d41Bf1F21b235958',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25702/thumb/20260.png?1653459711',
            'coingeckoId': 'ride-to-earn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BRKL',
            'name': 'Brokoli',
            'address': '0x66Cafcf6C32315623C7fFd3f2FF690aa36EBeD38',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18763/thumb/brkl.png?1633356263',
            'coingeckoId': 'brokoli',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALGOPAD',
            'name': 'AlgoPad',
            'address': '0x66A6606F4B7FF09b078B2DBA848581833005737F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19690/thumb/hcyg4Kz.png?1635749322',
            'coingeckoId': 'algopad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CLS',
            'name': 'Coldstack',
            'address': '0x668048E70284107A6aFab1711f28D88dF3E72948',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15499/thumb/logo_200x200.png?1621072403',
            'coingeckoId': 'coldstack',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BEST',
            'name': 'Bitcoin and Ethereum Standard',
            'address': '0x667bEbFf5cda3C4A460B514aB478Da0A8cF80910',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15891/thumb/best.PNG?1622238807',
            'coingeckoId': 'bitcoin-and-ethereum-standard-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARV',
            'name': 'Ariva',
            'address': '0x6679eB24F59dFe111864AEc72B443d1Da666B360',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x6679eb24f59dfe111864aec72b443d1da666b360.png',
            'coingeckoId': 'ariva',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'BWC',
            'name': 'BongWeedCoin',
            'address': '0x66696AB8c6aAeb22dc14a2Dc4A833682388Ea901',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16138/thumb/bongweed.PNG?1623115285',
            'coingeckoId': 'bongweedcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XY',
            'name': 'XY Finance',
            'address': '0x666666661f9B6D8c581602AAa2f76cbead06C401',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21541/small/xy.png?1639913622',
            'coingeckoId': 'xy-finance',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'LCS',
            'name': 'LetCoinShop',
            'address': '0x6641fa061a7D0B8955D945b9c981e7FF296812FA',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25468/thumb/lcs.png?1651916023',
            'coingeckoId': 'letcoinshop',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DSC',
            'name': 'DefiSportsCoin',
            'address': '0x662cBBEb9872251a795F85648333Be0dbFcAd653',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19340/thumb/XKnIoqD.png?1635125170',
            'coingeckoId': 'defisportscoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DMG',
            'name': 'DAOMERGE',
            'address': '0x6612535D85249736821b3c95319f2faF383f37CC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'GAME',
            'name': 'Gamestarter',
            'address': '0x66109633715d2110ddA791E64a7B2afadb517aBB',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17604/thumb/gpMi14-r_400x400.jpg?1628647205',
            'coingeckoId': 'gamestarter',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RAZE',
            'name': 'Raze Network',
            'address': '0x65e66a61D0a8F1e686C2D6083ad611a10D84D97A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14767/thumb/logo-2.png?1623867120',
            'coingeckoId': 'raze-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STORE',
            'name': 'Bit Store',
            'address': '0x65d9033cff96782394dAB5dbEf17Fa771bbe1732',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20463/thumb/bit_store.PNG?1637064215',
            'coingeckoId': 'bit-store-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BPLC',
            'name': 'BlackPearl',
            'address': '0x65c8743A5A266c3512eAbD34e65ADe42D4355Ef1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/8931/thumb/EJIpComQ_400x400.png?1584653141',
            'coingeckoId': 'blackpearl-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GFX',
            'name': 'GamyFi',
            'address': '0x65ad6A2288b2Dd23E466226397c8F5D1794e58fC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14559/thumb/circle-cropped_%281%29.png?1617008124',
            'coingeckoId': 'gamyfi-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOCA',
            'name': 'Socaverse',
            'address': '0x6598463D6cBe4b51e9977437bf1200df4c45286C',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23872/thumb/PINK-Token-LOGO.png?1645599962',
            'coingeckoId': 'socaverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TC',
            'name': 'TTcoin',
            'address': '0x659049786cB66E4486b8C0E0cCC90a5929a21162',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18887/thumb/200px.png?1642084842',
            'coingeckoId': 'ttcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DAGO',
            'name': 'Dawn Of Gods',
            'address': '0x658cE4EfD3dF2aaFdf78179FaB81347daB87FF83',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22536/thumb/17205.png?1642024270',
            'coingeckoId': 'dawn-of-gods',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OIN',
            'name': 'OIN Finance',
            'address': '0x658E64FFcF40D240A43D52CA9342140316Ae44fA',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x658E64FFcF40D240A43D52CA9342140316Ae44fA/logo.png',
            'coingeckoId': 'oin-finance',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FOR',
            'name': 'ForTube',
            'address': '0x658A109C5900BC6d2357c87549B651670E5b0539',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x658a109c5900bc6d2357c87549b651670e5b0539.png',
            'coingeckoId': 'force-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'F2C',
            'name': 'Ftribe Fighters',
            'address': '0x657B632714E08ac66B79444Ad3F3875526eE6689',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22250/thumb/d2pxPhBW_400x400.png?1641278639',
            'coingeckoId': 'ftribe-fighters',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DSOLAR',
            'name': 'Doge Solar',
            'address': '0x6556153450C85Ce348b2C26022566cbeb1a95F8f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27403/thumb/logo200.png?1663830923',
            'coingeckoId': 'doge-solar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KRP',
            'name': 'Krakenpad',
            'address': '0x65291ba9eA608f87EdFa0AdF83D7Bc4a2364706c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27963/thumb/logo200x200.png?1666752286',
            'coingeckoId': 'krakenpad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADACASH',
            'name': 'ADAcash',
            'address': '0x651a89fed302227d41425235F8E934502FB94C48',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19509/thumb/adacash.png?1638250717',
            'coingeckoId': 'adacash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ROSN',
            'name': 'Roseon Finance',
            'address': '0x651Cd665bD558175A956fb3D72206eA08Eb3dF5b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15478/thumb/Roseon_Finance.png?1620996737',
            'coingeckoId': 'roseon-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NLC',
            'name': 'NoLimitCoin',
            'address': '0x6519cb1F694CcBCc72417570b364F2D051EEfb9d',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/758/thumb/nolimitcoin.png?1548329142',
            'coingeckoId': 'nolimitcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VLINK',
            'name': 'Venus LINK',
            'address': '0x650b940a1033B8A1b1873f78730FcFC73ec11f1f',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x650b940a1033b8a1b1873f78730fcfc73ec11f1f.png',
            'coingeckoId': 'venus-link',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'FTS',
            'name': 'FootballStars',
            'address': '0x6507458BB53aec6Be863161641ec28739C41cC97',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15627/thumb/footballstars.PNG?1621892838',
            'coingeckoId': 'footballstars',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AQUARI',
            'name': 'Aquari',
            'address': '0x6500197A2488610ACA288fd8E2DFE88Ec99E596c',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15177/thumb/aquari-blue-200-notext.png?1622439133',
            'coingeckoId': 'aquari',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTVP',
            'name': 'MultiversePad',
            'address': '0x64d2906391a82721BB24925fc16A3EfF20c8756e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25129/thumb/mtvp_200x200.png?1650373395',
            'coingeckoId': 'multiversepad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IMV',
            'name': 'Imminentverse',
            'address': '0x64c5F47e530841684a4EeF408e9a4366585B88F9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x64c5f47e530841684a4eef408e9a4366585b88f9/logo.png',
            'coingeckoId': 'imminentverse',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'NPI',
            'name': 'Ninja Panda Inu',
            'address': '0x64FEf0AE518727fa694d50e267017E7C4149e4c4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18000/thumb/logo-npi.png?1630073007',
            'coingeckoId': 'ninja-panda-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AINU',
            'name': 'Anon Inu',
            'address': '0x64F36701138f0E85cC10c34Ea535FdBADcB54147',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17151/thumb/LOGO-AINU-COIN-GECKO.png?1649142760',
            'coingeckoId': 'anon-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RAI',
            'name': 'Raicoin',
            'address': '0x64EF755D5A2627538CAA3Eb62ee07f96f9B608E4',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13127/thumb/logo.png?1605503124',
            'coingeckoId': 'raicoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INMES',
            'name': 'Inme Swap  OLD ',
            'address': '0x64EF3AE48977075D917e448B61c7B030B9C387Fc',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26267/thumb/rsz_final_png.png?1656985286',
            'coingeckoId': 'inme-swap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GTON',
            'name': 'GTON CAPITAL',
            'address': '0x64D5BaF5ac030e2b7c435aDD967f787ae94D0205',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x01e0e2e61f554ecaaec0cc933e739ad90f24a86d_1.png',
            'coingeckoId': 'gton-capital',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'CVP',
            'name': 'Community Vote Power',
            'address': '0x64AE88A79f79A2Cf3bF032ff6b687966b09645d9',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23573/thumb/CVPCMC.png?1644480670',
            'coingeckoId': 'community-vote-power',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFS',
            'name': 'Ninja Fantasy',
            'address': '0x64815277c6CAF24c1C2B55B11c78EF393237455C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19719/thumb/200x200_%2836%29.png?1635759963',
            'coingeckoId': 'ninja-fantasy-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RBTR',
            'name': 'Arbitrage Token',
            'address': '0x6469b35d2D5FAb6c53F73c7d9aBF537892DdB34a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26904/thumb/rbtr.png?1660904497',
            'coingeckoId': 'arbitrage-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHAR',
            'name': 'Charitas',
            'address': '0x6466849a30247D90f0c228A6c4b6b106ff18cAB9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15095/thumb/456DC7B8-2651-4993-B8E7-5AD14756CC7E.png?1619679428',
            'coingeckoId': 'charitas',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QUINT',
            'name': 'Quint',
            'address': '0x64619f611248256F7F4b72fE83872F89d5d60d64',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25194/thumb/logo-200x200.png?1650608504',
            'coingeckoId': 'quint',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BULLDOG',
            'name': 'BullDog Coin',
            'address': '0x645C86b54B6bAC4E3a70085EDDE137732FB692e1',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22344/thumb/bulldog.PNG?1641538492',
            'coingeckoId': 'bulldog-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IEUROS',
            'name': 'Inflation Adjusted EURO',
            'address': '0x6458df5d764284346c19D88A104Fd3D692471499',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26442/thumb/894CE468-A364-42BA-83B0-60A7CA859614.png?1658110157',
            'coingeckoId': 'inflation-adjusted-euro',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ROVER',
            'name': 'Rover Inu',
            'address': '0x6452D525532658B23484EB1897aF8b9325cA67b9',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15418/thumb/inu.png?1620774499',
            'coingeckoId': 'rover-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PS1',
            'name': 'POLYSPORTS',
            'address': '0x6451C6484D23889003C20BE51819D6Aa7dbd2b35',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25070/thumb/L-T2x_cG_400x400.jpg?1650024620',
            'coingeckoId': 'polysports',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BLEO',
            'name': 'BEP20 LEO',
            'address': '0x6421531AF54C7B14Ea805719035EBf1e3661c44A',
            'decimals': 3,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x6421531af54c7b14ea805719035ebf1e3661c44a.png',
            'coingeckoId': 'bep20-leo',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'TMAC',
            'name': 'Tip Me A Coffee',
            'address': '0x6421282c7f14670D738F4651311C5a1286e46484',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28243/thumb/IMG_20221109_032028_480.jpg?1668584308',
            'coingeckoId': 'tip-me-a-coffee',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REFI',
            'name': 'Realfinance Network',
            'address': '0x641a6Dc991A49f7BE9Fe3C72c5d0FBb223eDb12f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15194/thumb/high.png?1620082636',
            'coingeckoId': 'realfinance-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOGEKING',
            'name': 'DogeKing',
            'address': '0x641EC142E67ab213539815f67e4276975c2f8D50',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23349/thumb/kq245eMw_400x400.jpg?1643924448',
            'coingeckoId': 'dogeking',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOZ',
            'name': 'League of Zodiacs',
            'address': '0x6415670B1818bdC690B3405a5717cF252dBA63a4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22897/thumb/loz.png?1642853091',
            'coingeckoId': 'league-of-zodiacs',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUMP',
            'name': 'BabyPumpkin Finance',
            'address': '0x6401b1630B065Db49C8f545384c652AcD7A9fC7A',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19301/thumb/babypump.PNG?1634917398',
            'coingeckoId': 'babypumpkin-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METAR',
            'name': 'MetaRaca',
            'address': '0x63eC19c2983bfB0D43232aa9C792Fe6F27F63361',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20742/thumb/Logo-01_s1.png?1637632808',
            'coingeckoId': 'metaraca',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SENSI',
            'name': 'Sensi',
            'address': '0x63e77cF206801782239D4F126cfa22b517FB4eDb',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25449/thumb/xdirLdlX_400x400.png?1651802649',
            'coingeckoId': 'sensi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SC',
            'name': 'ShibChain',
            'address': '0x63cA7Bec70f325511Ec7D07f7B10Aa1699CfAaB5',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27338/thumb/IMG_20220917_135639_644.png?1663546080',
            'coingeckoId': 'shibchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POP',
            'name': 'Popcoin',
            'address': '0x63bc9770Ea9a2F21Df6cc1224D64d8dEc9c61a74',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28160/thumb/Popcoin-logo-200x200.png?1668163551',
            'coingeckoId': 'popcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EARN',
            'name': 'Yearn Classic Finance',
            'address': '0x63a18BC38D1101DB7F0efCbCBdCbe927A5879039',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13009/thumb/1_s2SlrgrX3CnRJIzUkRaFEA.jpeg?1604373076',
            'coingeckoId': 'yearn-classic-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GSC',
            'name': 'Gunstar Metaverse Currency',
            'address': '0x639FC0c006bd7050E2c359295B41a79cB28694BA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23258/thumb/GSC.png?1643360904',
            'coingeckoId': 'gunstar-metaverse-currency',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BBBR',
            'name': 'Baby BitBurnReflect',
            'address': '0x639CF44b66D02F822c57F97dabAF3547462f0CE9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27156/thumb/IMG_20220901_200536_561.jpg?1662275214',
            'coingeckoId': 'baby-bitburnreflect',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CANDY',
            'name': 'TripCandy',
            'address': '0x639AD7c49EC616a64e074c21a58608C0d843A8a3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16288/thumb/CANDY.jpg?1626938951',
            'coingeckoId': 'tripcandy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WORTH',
            'name': 'Worthpad',
            'address': '0x63982bBD062cf9d8efcA59EC37609DCC0bc2F305',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23341/thumb/worth.PNG?1643893062',
            'coingeckoId': 'worthpad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SIN',
            'name': 'Sinverse',
            'address': '0x6397de0F9aEDc0F7A8Fa8B438DDE883B9c201010',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19291/thumb/Sin_Verse_Logo_White_Text_White_Windows.png?1645276533',
            'coingeckoId': 'sin-city',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'HEC',
            'name': 'Hector Network',
            'address': '0x638EEBe886B0e9e7C6929E69490064a6C94d204d',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19832/thumb/logo-final.png?1635981937',
            'coingeckoId': 'hector-dao',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BLK',
            'name': 'BLINk',
            'address': '0x63870A18B6e42b01Ef1Ad8A2302ef50B7132054F',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x63870A18B6e42b01Ef1Ad8A2302ef50B7132054F/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FOOD',
            'name': 'Food Bank',
            'address': '0x6359F0d2004433D2A38b03DCe8f966Cc243F1da9',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24147/thumb/food.png?1646560102',
            'coingeckoId': 'food-bank',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLV3',
            'name': 'Crypto Legions V3',
            'address': '0x63441E5C9F55B5A9141f3D834a28426Ca1c5C5cC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27526/thumb/200-200.jpg?1664371269',
            'coingeckoId': 'crypto-legions-v3',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STW',
            'name': 'StepWell',
            'address': '0x634113efb704A4bC3C4b10eb786cB60B69E56017',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27049/thumb/logo200x200.png?1661504976',
            'coingeckoId': 'stepwell',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FIWA',
            'name': 'Defi Warrior',
            'address': '0x633237C6FA30FAe46Cc5bB22014DA30e50a718cC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18208/thumb/defi_warrior.PNG?1630986310',
            'coingeckoId': 'defi-warrior',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'vUNIT',
            'name': 'Virtual Unit',
            'address': '0x632fbF85F77978437073a8CE5CEEC29e3209514c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WET',
            'name': 'Weble Ecosystem',
            'address': '0x632B8c4e95B2F8A9309417f8D990ab9C04c77369',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17353/thumb/cropped-logo-wombat.png?1627368904',
            'coingeckoId': 'weble-ecosystem-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATHD',
            'name': 'ATH Games',
            'address': '0x632A21a913a4B59b561B999cf473109e37E87B39',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21705/thumb/HI-Smile.1eb82fa1.png?1639746559',
            'coingeckoId': 'ath-games',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RAM',
            'name': 'Ramifi Protocol',
            'address': '0x63290fC683d11ea077abA09596Ff7387D49dF912',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14602/thumb/76572462.png?1618003618',
            'coingeckoId': 'ramifi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MSN',
            'name': 'Maison Capital',
            'address': '0x631b92596bc7f5c4537F1a7Cd4CaEF2Db0d3000d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18765/thumb/Maison_200x200.png?1633356616',
            'coingeckoId': 'maison-capital',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PINKPANDA',
            'name': 'Pink Panda',
            'address': '0x631E1e455019c359b939fE214EDC761d36bF6aD6',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15946/thumb/pinkpanda.png?1633582660',
            'coingeckoId': 'pink-panda',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PLT',
            'name': 'Poollotto finance',
            'address': '0x631C2f0EdABaC799f07550aEE4fF0Bf7fd35212B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21425/thumb/polotto.png?1639116841',
            'coingeckoId': 'poollotto-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSCM',
            'name': 'BSC MemePad',
            'address': '0x63133dE69e94f225726426FA729a7c515A51D75a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19120/thumb/YTCqjSB.png?1634519927',
            'coingeckoId': 'bsc-memepad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GWAR',
            'name': 'Gadget War',
            'address': '0x63129Aa653E414A174202d5D6670038E409d64d8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23686/thumb/D743-B6-E4-282-B-494-A-BC0-B-DE70667-FC561.jpg?1644997932',
            'coingeckoId': 'gadget-war',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PEAK',
            'name': 'PEAKDEFI',
            'address': '0x630d98424eFe0Ea27fB1b3Ab7741907DFFEaAd78',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x630d98424efe0ea27fb1b3ab7741907dffeaad78.png',
            'coingeckoId': 'marketpeak',
            'listedIn': [
                'coingecko',
                '1inch',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'KS',
            'name': 'Kingdomswap  OLD ',
            'address': '0x630aeA3d41945A367C8cE920cC247Ee5084EF1aB',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22444/thumb/YX0i-Zz-WP-1-1.png?1641823370',
            'coingeckoId': 'kingdomswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FON',
            'name': 'Force of Nature',
            'address': '0x62d70E77038c4009645D7DE007373e4873BB4898',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25752/thumb/20278.png?1653465872',
            'coingeckoId': 'force-of-nature',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPAT',
            'name': 'Meta Spatial',
            'address': '0x62cd26cb698FA8067D9c76f8ccb26922F6a86909',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19933/thumb/metaspatial.PNG?1636325383',
            'coingeckoId': 'meta-spatial',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LEVR',
            'name': 'LEVR - levr.ly Logistics Token',
            'address': '0x62c3C2C714F934B5e2A7A3D225CC23fb279060c1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FUMA',
            'name': 'Fuma Finance',
            'address': '0x62F06ad3ebda0A6322df7bfE0870348577491bA1',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19282/thumb/logo_400.png?1634870646',
            'coingeckoId': 'fuma-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'mGOOGL',
            'name': 'Mirror GOOGL Token',
            'address': '0x62D71B23bF15218C7d2D7E48DBbD9e9c650B173f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x62D71B23bF15218C7d2D7E48DBbD9e9c650B173f/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PINKIE',
            'name': 'Pinkie Inu',
            'address': '0x628e3a6FD5E78564dA8de2aa7386d57B84902380',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27801/thumb/Pinkie-01-b.png?1666427472',
            'coingeckoId': 'pinkie-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRUSADER',
            'name': 'Crusaders of Crypto',
            'address': '0x6289812163af9421E566B3d74774074fAc2A0441',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17178/thumb/crusaders.PNG?1626817123',
            'coingeckoId': 'crusaders-of-crypto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HYPE',
            'name': 'Supreme Finance',
            'address': '0x62891201468A517EeEc00FE72f33595a3F9047eE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24675/thumb/8130.png?1648547739',
            'coingeckoId': 'supreme-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METAV',
            'name': 'MetaVPad',
            'address': '0x62858686119135cc00C4A3102b436a0eB314D402',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21397/thumb/metav.png?1639044315',
            'coingeckoId': 'metavpad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ERTHA',
            'name': 'Ertha',
            'address': '0x62823659d09F9F9D2222058878f89437425eB261',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x62823659d09F9F9D2222058878f89437425eB261.png',
            'coingeckoId': 'ertha',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'GALE',
            'name': 'Gale Network',
            'address': '0x627E86E9eC832b59018Bf91456599e752288Aa97',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25228/thumb/gale200.png?1650950859',
            'coingeckoId': 'gale-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KEK',
            'name': 'Cryptokek',
            'address': '0x627524d78B4fC840C887ffeC90563c7A42b671fD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13513/thumb/Cryptokek-Logo-256px.png?1609292074',
            'coingeckoId': 'cryptokek',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MFX',
            'name': 'METFX Watch To Earn',
            'address': '0x6266a18F1605DA94e8317232ffa634C74646ac40',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26557/thumb/metFx200_wcaqdp.png?1658789320',
            'coingeckoId': 'metfx-watch-to-earn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BBK',
            'name': 'BNB Bank',
            'address': '0x6249428345819cAC8B8C7f1f68771073CB689Ab1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22510/thumb/Final-Logo_1.png?1641965441',
            'coingeckoId': 'bnb-bank',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPACETOAST',
            'name': 'SpaceToast',
            'address': '0x623b3A37E06268E99096a56A8A0c1207a024245d',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16026/thumb/88c025a7a41b9dd03f79409135b65c2c.jpeg?1623039591',
            'coingeckoId': 'spacetoast',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANGEL',
            'name': 'Angel Nodes',
            'address': '0x623974FA31D79d12dC8a2EC8DFEa9BCDF8938889',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23468/thumb/angel200.png?1644217769',
            'coingeckoId': 'angel-nodes',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SUPDOG',
            'name': 'SuperDoge',
            'address': '0x622A1297057ea233287ce77bdBF2AB4E63609F23',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15743/thumb/supdog.png?1635735982',
            'coingeckoId': 'superdoge',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'CSHIP',
            'name': 'CryptoShips',
            'address': '0x6218079f1a5d50d3a8358093699B9943A662ef7C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21880/thumb/15269.png?1640213050',
            'coingeckoId': 'cryptoships',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFE',
            'name': 'Safe',
            'address': '0x62175af6D9B045D8435CDeDd9Bf542c7bcc56dCC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22451/thumb/safelogo_%282%29.png?1641824877',
            'coingeckoId': 'safe-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KTE',
            'name': 'Kyte One',
            'address': '0x61fA01129aC0bB124D1C60Dc9f735C6C579A858B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25225/thumb/3l4OKCIt_400x400.png?1650935126',
            'coingeckoId': 'kyte-one',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'P202',
            'name': 'Project 202',
            'address': '0x61a960C3F213B80EaC761e2f996414AB52C08985',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26590/thumb/hqdf9m6R_400x400.png?1658958301',
            'coingeckoId': 'project202',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VINA',
            'name': 'VICUNA',
            'address': '0x61a802dE6327A05dDa95812ae1535109599f7DF2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28261/thumb/light-removebg-preview.png?1668765757',
            'coingeckoId': 'vicuna',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RSD',
            'name': 'Reference System for DeFi',
            'address': '0x61Ed1C66239d29Cc93C8597c6167159e8F69a823',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'RKF',
            'name': 'FlokiRocket',
            'address': '0x61D99Ac4e23a5F44c850245fCB24D06D42982a5C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23119/thumb/3ejS6SG.png?1643266829',
            'coingeckoId': 'flokirocket',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GCAKE',
            'name': 'Pancake Games',
            'address': '0x61D5822DD7b3Ed495108733e6550d4529480C8F6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20217/thumb/6oc-L2UC_400x400.png?1636671365',
            'coingeckoId': 'pancake-games',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'XL',
            'name': 'Xolo Inu',
            'address': '0x61AE5B63C8F7400C21613F882C9b0a8eeC586a10',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21174/thumb/E2cXNzlO_400x400.jpg?1639549304',
            'coingeckoId': 'xolo-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LITHO',
            'name': 'Lithosphere',
            'address': '0x61909950e1bfB5d567C5463CbD33Dc1cdC85eE93',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21128/thumb/6gizpBLn.png?1638347028',
            'coingeckoId': 'lithosphere',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IDO',
            'name': 'Interstellar Domain Order',
            'address': '0x617bA3d39E96C084E60C6db3f7B365A96eE4e555',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20523/thumb/logo_-_2021-11-18T065417.612.png?1637189699',
            'coingeckoId': 'interstellar-domain-order',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OLIVE',
            'name': 'Olive Cash',
            'address': '0x617724974218A18769020A70162165A539c07E8a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x617724974218A18769020A70162165A539c07E8a/logo.png',
            'coingeckoId': 'olivecash',
            'listedIn': [
                'coingecko',
                'elkfinance'
            ]
        },
        {
            'symbol': 'VETTER',
            'name': 'Vetter',
            'address': '0x6169b3b23e57dE79a6146A2170980cEb1F83b9e0',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19235/thumb/Vetter_Logo.jpg?1649412533',
            'coingeckoId': 'vetter-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CCV2',
            'name': 'CryptoCart V2',
            'address': '0x612E1726435fE38dD49A0B35b4065B56f49c8F11',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15210/thumb/DP7-T6rox-400x400_%281%29.png?1638928576',
            'coingeckoId': 'cryptocart',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'DFSG',
            'name': 'DFSocial Gaming',
            'address': '0x612C49b95c9121107BE3A2FE1fcF1eFC1C4730AD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19918/thumb/Recurso_1_2x.png?1643452540',
            'coingeckoId': 'dfsocial-gaming-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MGG',
            'name': 'MetaGaming Guild',
            'address': '0x6125aDCAb2F171BC70cfe2CAeCFeC5509273A86A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23287/thumb/mgg.png?1643543010',
            'coingeckoId': 'metagaming-guild',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POLY',
            'name': 'FarmPoly',
            'address': '0x61073ED3aCEfAFc5E8F87afAedacf9b8586FB28c',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20237/thumb/Logo_3.0_200x200.jpg?1638372946',
            'coingeckoId': 'farmpoly',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MD3',
            'name': 'MaskDogeV3',
            'address': '0x610243eE8EaC0D212Ac2792f70617Fcb9b4fA583',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18266/thumb/0rUOqRY.png?1631171595',
            'coingeckoId': 'maskdoge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SATSALL',
            'name': 'All Best ICO Satoshi',
            'address': '0x6101Dc7289eb8F31a0C93E6b36feCB52fd3dE9F2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26468/thumb/allbestico200x200.png?1658193732',
            'coingeckoId': 'all-best-ico-satoshi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USE',
            'name': 'Useless',
            'address': '0x60d66a5152612F7D550796910d022Cb2c77B09de',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24924/thumb/useless.jpeg?1649388116',
            'coingeckoId': 'useless-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'iZi',
            'name': 'Izumi Finance',
            'address': '0x60D01EC2D5E98Ac51C8B4cF84DfCCE98D527c747',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0x60d01ec2d5e98ac51c8b4cf84dfcce98d527c747/7b759cd6fd6ff6c1059272152e61a922.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'HE',
            'name': 'Haino',
            'address': '0x60BD7439500aDeE0C77CDeF527FF0d070b18a7AC',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24113/thumb/3fau.png?1646378874',
            'coingeckoId': 'haino-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BBT',
            'name': 'Booby Trap',
            'address': '0x609b88f5a4aBB7A55bA0c6d255C3F1b1bC7A4D76',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23889/thumb/round.png?1645606123',
            'coingeckoId': 'booby-trap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IMT',
            'name': 'Infinite Metaverse',
            'address': '0x6076aeDbE9Af4E70550c341842bab5624b12395E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22351/thumb/-YUwJ0YJ_400x400.jpg?1641541034',
            'coingeckoId': 'idle-mystic-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OKSE',
            'name': 'Okse',
            'address': '0x606FB7969fC1b5CAd58e64b12Cf827FB65eE4875',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27185/thumb/Icon_Okse_copy.png?1662432854',
            'coingeckoId': 'okse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EVER',
            'name': 'EverSwap',
            'address': '0x60684A1F27876aab7022Da66909Dd234f9CC9E3C',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16943/thumb/8GcpNCM.png?1625719645',
            'coingeckoId': 'everswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MMPRO',
            'name': 'Market Making Pro',
            'address': '0x6067490d05F3cF2fdFFC0e353b1f5Fd6E5CCDF70',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20386/thumb/MMPRO_LOGO_W.png?1636960601',
            'coingeckoId': 'market-making-pro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BITGATTI',
            'name': 'Bitgatti',
            'address': '0x60531D9DC6Ca16AC18d43588d2845d69f8A8aA59',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16962/thumb/bitgatti.PNG?1625799415',
            'coingeckoId': 'biitgatti',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BANANA',
            'name': 'ApeSwap',
            'address': '0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95.png',
            'coingeckoId': 'apeswap-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MDAO',
            'name': 'MarsDAO',
            'address': '0x60322971a672B81BccE5947706D22c19dAeCf6Fb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24126/thumb/mdao.png?1647700233',
            'coingeckoId': 'marsdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PINKSALE',
            'name': 'PinkSale',
            'address': '0x602bA546A7B06e0FC7f58fD27EB6996eCC824689',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x602ba546a7b06e0fc7f58fd27eb6996ecc824689.png',
            'coingeckoId': 'pinksale',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'LC',
            'name': 'LuckyChip',
            'address': '0x6012C3a742f92103d238F1c8306cF8fbcDEca8B3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25614/thumb/logo.png?1652855117',
            'coingeckoId': 'luckychip',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DFH',
            'name': 'DeFiHorse',
            'address': '0x5fdAb5BDbad5277B383B3482D085f4bFef68828C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24293/thumb/uxwvJpWa_400x400.jpg?1647265261',
            'coingeckoId': 'defihorse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VCC',
            'name': 'Victorum',
            'address': '0x5fc6179FCf814Fcd4344Ee03376BA717a95992b6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17391/thumb/Victorum-logo200X200.png?1627525058',
            'coingeckoId': 'victorum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WEB30',
            'name': 'Web3',
            'address': '0x5fbC8000e7D9F619E43b3FBfce9Aba70494A57C3',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26347/thumb/wevb3.jpeg?1657588795',
            'coingeckoId': 'web3',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHR',
            'name': 'Share',
            'address': '0x5fb4968fC85868DF3aD2d6e59883a10570f01D18',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/3609/thumb/74586729_2443914875881351_2785018663453851648_n.png?1574898410',
            'coingeckoId': 'sharering',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'JIND',
            'name': 'Jindo Inu',
            'address': '0x5fEAD99998788AC1BCA768796483d899F1Aef4c4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15521/thumb/_POP28D3_400x400.jpg?1621119276',
            'coingeckoId': 'jindo-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MCB',
            'name': 'MUX Protocol',
            'address': '0x5fE80d2CD054645b9419657d3d10d26391780A7B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x5fE80d2CD054645b9419657d3d10d26391780A7B.png',
            'coingeckoId': 'mcdex',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'EURS',
            'name': 'STASISEURSToken',
            'address': '0x5fE5A66c84c6F8c213503A04f95a417AC6684361',
            'decimals': 2,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TSA',
            'name': 'Teaswap Art',
            'address': '0x5f99ACF13CAff815DD9cB4A415c0fB34e9F4545b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17826/thumb/tsa.PNG?1629358290',
            'coingeckoId': 'teaswap-art',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BGOF',
            'name': 'Bingo Family',
            'address': '0x5f949De3131f00B296Fc4c99058D40960B90FAbC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26057/thumb/BGOF-TOKEN.png?1655454729',
            'coingeckoId': 'bingo-family',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YMPA',
            'name': 'YmplePay',
            'address': '0x5f7871433322B9F9795b97801Ec3396276D1D056',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22343/thumb/ymplepay-200x200.png?1641536970',
            'coingeckoId': 'ymplepay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MVL',
            'name': 'MVL',
            'address': '0x5f588EfAf8eB57e3837486e834fC5a4E07768D98',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/3476/thumb/mass-vehicle-ledger.png?1547978299',
            'coingeckoId': 'mass-vehicle-ledger',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BRO',
            'name': 'BlockRock',
            'address': '0x5f54B428f08BCF68c8C1Dc07db9971040e5997Ec',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27846/thumb/Logo-200x200-1.png?1666263832',
            'coingeckoId': 'blockrock',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SGLY',
            'name': 'Singularity',
            'address': '0x5f50411CDE3eEC27b0eaC21691b4e500c69a5a2e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22071/thumb/sgly_square.png?1640751285',
            'coingeckoId': 'singularity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HIGH',
            'name': 'Highstreet',
            'address': '0x5f4Bde007Dc06b867f86EBFE4802e34A1fFEEd63',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x5f4Bde007Dc06b867f86EBFE4802e34A1fFEEd63.png',
            'coingeckoId': 'highstreet',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'PLY',
            'name': 'PlayNity',
            'address': '0x5f39DD1bB6Db20F3e792c4489F514794caC6392c',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21479/thumb/ply.png?1639651574',
            'coingeckoId': 'playnity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JNTR',
            'name': 'Jointer',
            'address': '0x5f2Caa99Fc378248Ac02CbbAaC27e3Fa155Ed2C4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12964/thumb/JNTR-coingecko.png?1603867872',
            'coingeckoId': 'jointer',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GFCE',
            'name': 'GFORCE',
            'address': '0x5f136383E230F972739FaE2E81E7E774AfE64c66',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14475/thumb/gforce_logo.png?1616407253',
            'coingeckoId': 'gforce',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLASH',
            'name': 'Flash Loans',
            'address': '0x5f0366c9962193fA774cdce9602195593B49f23C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19827/thumb/flash.PNG?1635979476',
            'coingeckoId': 'flash-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADAM',
            'name': 'ADAM',
            'address': '0x5f026f015773C3250EdD3Cf9EcBCC0e2Ff5e712E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26861/thumb/fvr0B85g_400x400.jpeg?1660530689',
            'coingeckoId': 'adam',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALLOY',
            'name': 'HyperAlloy',
            'address': '0x5eF5994fA33FF4eB6c82d51ee1DC145c546065Bd',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x5ef5994fa33ff4eb6c82d51ee1dc145c546065bd.png',
            'coingeckoId': 'hyperalloy',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BONFIRE',
            'name': 'Bonfire',
            'address': '0x5e90253fbae4Dab78aa351f4E6fed08A64AB5590',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15020/thumb/Logo_-_2021-04-27T062421.226.png?1619475866',
            'coingeckoId': 'bonfire',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PIN',
            'name': 'Pay It Now',
            'address': '0x5e8f6244A57f4F6c47DAaFc14AA0723D2a9F594c',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22482/thumb/PINlogo.png?1668686774',
            'coingeckoId': 'pay-it-now',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BBFEG',
            'name': 'Babyfeg',
            'address': '0x5e8F1Ac3930461A467C3a4fa349D7cFa6f211c8F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17778/thumb/logo_%281%29.png?1629215242',
            'coingeckoId': 'babyfeg',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MUDOL2',
            'name': 'Hero Blaze  Three Kingdoms',
            'address': '0x5e7f472B9481C80101b22D0bA4ef4253Aa61daBc',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26432/thumb/iShot_2022-07-16_09.59.28.png?1657936904',
            'coingeckoId': 'hero-blaze-three-kingdoms',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STEPG',
            'name': 'STEPG',
            'address': '0x5e6D3BB496301ECdfa34FA1Ed2d3BaDa250f0409',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25572/thumb/4eb9a3f341d20ae03a51c3af1e90a3f5.png?1652674341',
            'coingeckoId': 'stepg',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SCARY',
            'name': 'Halloween Crows',
            'address': '0x5e2dd42B97fb9c01A1AeE381568797d973cE72D2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28056/thumb/IMG_20221025_030416_451-removebg-preview_%282%29.png?1667295049',
            'coingeckoId': 'halloween-crows',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XPR',
            'name': 'Proton',
            'address': '0x5de3939b2F811A61d830E6F52d13B066881412ab',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/10941/thumb/Proton-Icon.png?1588283737',
            'coingeckoId': 'proton',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'ZLW',
            'name': 'Zelwin',
            'address': '0x5dd1E31E1a0e2E077aC98d2a4b781F418ca50387',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/11547/thumb/5614.png?1590991128',
            'coingeckoId': 'zelwin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HIRO',
            'name': 'Hiroki',
            'address': '0x5dC1962beCe0F0753268604A8D7f3E89A16AE851',
            'decimals': 2,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26342/thumb/IMG-20220707-162424-700.jpg?1657586065',
            'coingeckoId': 'hiroki',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MILE',
            'name': 'Motoverse',
            'address': '0x5dA6860540E088273839E4B4da522Bb596776F90',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28094/thumb/qbWqM1Bl_400x400.jpeg?1667536616',
            'coingeckoId': 'motoverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFTN',
            'name': 'NFTNetwork',
            'address': '0x5d8c0E2288704d566c62c9C4DD849c389D4D482e',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26552/thumb/nftnetwork_logo.png?1658753169',
            'coingeckoId': 'nftnetwork',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOLIDETH',
            'name': 'SolidETH',
            'address': '0x5d772Ca965648BcdBC263a7E672B46d214CcA432',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20867/thumb/solideth.png?1638347712',
            'coingeckoId': 'solideth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SMOON',
            'name': 'SaylorMoon',
            'address': '0x5d6CDf1b7F7C35EAE688E4C563b6f16eeAC2cB6b',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16294/thumb/SaylorMoon_With_Moon_%283%29-min.png?1623813524',
            'coingeckoId': 'saylor-moon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LIEN',
            'name': 'Lien',
            'address': '0x5d684ADaf3FcFe9CFb5ceDe3abf02F0Cdd1012E3',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x5d684ADaf3FcFe9CFb5ceDe3abf02F0Cdd1012E3/logo.png',
            'coingeckoId': 'lien',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'IOWN',
            'name': 'iOWN',
            'address': '0x5d681b9839e237C4f1dC7D7486e6cb0A12b9654f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/8270/thumb/iOWN-Lion-Face.png?1585695262',
            'coingeckoId': 'iown',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RVT',
            'name': 'Rocket Video',
            'address': '0x5d4eaf65B348Be3889193913ef366D306a9fbD73',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26600/thumb/Rocket_Logo.png?1658978573',
            'coingeckoId': 'rocket-video',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KGO',
            'name': 'Kiwigo',
            'address': '0x5d3AfBA1924aD748776E4Ca62213BF7acf39d773',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14443/thumb/8VKJDPsp_400x400.jpg?1616107715',
            'coingeckoId': 'kiwigo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFTSTYLE',
            'name': 'NFTStyle',
            'address': '0x5d33E26336C445c71F206dd18B64cE11C2eeE3f0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19928/thumb/nftstyle.PNG?1636323387',
            'coingeckoId': 'nftstyle',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'zSEED',
            'name': 'zSeedToken',
            'address': '0x5cd50Aae14E14B3fdF3fF13c7A40e8cf5ae8b0A5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x5cd50aae14e14b3fdf3ff13c7a40e8cf5ae8b0a5.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ADOGE',
            'name': 'Arabian Doge',
            'address': '0x5cB7e2dC122b33c7b191799ca7E23d5b4A15fBD0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26076/thumb/Safeimagekit-ArabianDogepng.png?1655689730',
            'coingeckoId': 'arabian-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OCCT',
            'name': 'Official Crypto Cowboy Token',
            'address': '0x5c865352F9012308378B1c74fE01D899f28e8FD9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x5c865352f9012308378b1c74fe01d899f28e8fd9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'BLWA',
            'name': 'BlockWarrior',
            'address': '0x5c7C45E7C8Febb2a16092FE32Bc465e88d4389Eb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20803/thumb/logo_-_2021-11-24T063238.897.png?1637706766',
            'coingeckoId': 'blockwarrior',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOXERDOGE',
            'name': 'BoxerDOGE',
            'address': '0x5c70b22F1E6aB0f1cDe92bbBaF18568Baf67b13f',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17610/thumb/logo200x.png?1628658560',
            'coingeckoId': 'boxerdoge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSCGIRL',
            'name': 'Binance Smart Chain Girl',
            'address': '0x5c6Fb802F173Dba15E2CAada433032B1368aF59f',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17271/thumb/icon_200px_16bit.png?1627005622',
            'coingeckoId': 'binance-smart-chain-girl',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'GEMS',
            'name': 'GemCave Token',
            'address': '0x5c647208486231becCABAACf5F2B7358937D3b99',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28141/thumb/logo.png?1667790921',
            'coingeckoId': 'gemcave-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YOUB',
            'name': 'Youbie',
            'address': '0x5c4ADaF43D676Fb1BacEFEca8008799B03746D22',
            'decimals': 9,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': 'youbie',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IBG',
            'name': 'iBG Finance  BSC ',
            'address': '0x5c46c55A699A6359E451B2c99344138420c87261',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18026/thumb/IBG.png?1630291248',
            'coingeckoId': 'ibg-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPEP',
            'name': 'Stadium Pepe',
            'address': '0x5c2d4caEfAE1B1a746706acA1bDDed72D463215e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20581/thumb/pepe_300.png?1637248559',
            'coingeckoId': 'stadium-pepe',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METAGIN',
            'name': 'MetaGin',
            'address': '0x5c046f3AEE5f771070939a170C435058e1e6bD73',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25869/thumb/Logo-200x200-2.png?1654240341',
            'coingeckoId': 'metagin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OCW',
            'name': 'Online Cold Wallet',
            'address': '0x5bf059DDa26dA45e300aacC4347e8ad872381A15',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25096/thumb/gTdrrooG_400x400.jpg?1650272008',
            'coingeckoId': 'online-cold-wallet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VIM',
            'name': 'VicMove',
            'address': '0x5bcd91C734d665Fe426A5D7156f2aD7d37b76e30',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25188/thumb/1_trans.png?1650610033',
            'coingeckoId': 'vicmove',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MNRY',
            'name': 'Moonery',
            'address': '0x5baeBd214FdAe546ce39750B8363e799E2ABa21E',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16309/thumb/moonery-icon-200.png?1623725563',
            'coingeckoId': 'moonery',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WPC',
            'name': 'World Pay Coin',
            'address': '0x5b9861770Cc78B6e4FAd6C448C532e20CBB890c5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27908/thumb/%EC%9B%94%EB%93%9C%ED%8E%98%EC%9D%B4_%ED%86%A0%ED%81%B0_%EB%A1%9C%EA%B3%A0_200.jpg?1666339223',
            'coingeckoId': 'world-pay-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CROSS',
            'name': 'CrossPad',
            'address': '0x5b6eF1f87D5cEc1e8508ddB5De7E895869e7A4A3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15191/thumb/cross.PNG?1620079526',
            'coingeckoId': 'crosspad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DXCT',
            'name': 'DNAxCAT',
            'address': '0x5b1BaeC64aF6dC54E6e04349315919129A6d3c23',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17603/thumb/dxct.png?1634545609',
            'coingeckoId': 'dnaxcat',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LITH',
            'name': 'Litherium',
            'address': '0x5b180109332b079c44447F52A8aDad5B1Cd9dcfd',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20080/thumb/rHeGfsvH_400x400.jpg?1636453426',
            'coingeckoId': 'litherium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ACSI',
            'name': 'ACryptoSI',
            'address': '0x5b17b4d5e4009B5C43e3e3d63A5229F794cBA389',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x5b17b4d5e4009b5c43e3e3d63a5229f794cba389.png',
            'coingeckoId': 'acryptosi',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'FACE',
            'name': 'Faceter',
            'address': '0x5ae9604fea71d4916ECedb3AF1dF8c4903A064B7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/4383/thumb/faceter-logo.png?1547039727',
            'coingeckoId': 'face',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'NIOB',
            'name': 'NIOB',
            'address': '0x5ac5e6Af46Ef285B3536833E65D245c49b608d9b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24141/thumb/niob.png?1646548221',
            'coingeckoId': 'niob',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CBS',
            'name': 'Columbus',
            'address': '0x5a9908eC2173BAc7423F490BFD2B24Ae9cBA7115',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21808/thumb/cbs.png?1642919153',
            'coingeckoId': 'columbus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SCORGI',
            'name': 'SpaceCorgi',
            'address': '0x5a81b31b4a5F2D2a36BBd4d755dAb378dE735565',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15428/thumb/6085dd530067e37032adedc4_astronaut-corgi-nobg-medium.png?1620788445',
            'coingeckoId': 'spacecorgi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRBN',
            'name': 'Carbon',
            'address': '0x5a4fb10e7C4Cbb9a2b9d9A942f9a875EbD3489ea',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13262/thumb/carbon.png?1662693418',
            'coingeckoId': 'carbon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DART',
            'name': 'dART Insurance',
            'address': '0x5a4623F305A8d7904ED68638AF3B4328678edDBF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14834/thumb/dart.PNG?1618611161',
            'coingeckoId': 'dart-insurance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTR',
            'name': 'Bittrue',
            'address': '0x5a16E8cE8cA316407c6E6307095dc9540a8D62B3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x5a16E8cE8cA316407c6E6307095dc9540a8D62B3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DALI',
            'name': 'DALI',
            'address': '0x5a119762B09Ed0bcB3b16075159AE43A62651383',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20245/thumb/dali.png?1636699012',
            'coingeckoId': 'dali',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TAX',
            'name': 'MetaToll',
            'address': '0x5FE52Cfc3390751f610075646BebFbCA5E937fF7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24799/thumb/tax.png?1648981334',
            'coingeckoId': 'metatoll',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POWA',
            'name': 'Powabit',
            'address': '0x5FDa925294F9e5B31b7c0e81Bad6BAD5b5E792eE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27007/thumb/logo_powa_200.png?1661338268',
            'coingeckoId': 'powabit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HONEY',
            'name': 'Honeycomb',
            'address': '0x5FB61ac238fD2FE599Ad748Ae1707ad0D508AC8A',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15496/thumb/UKgDguR6_400x400.jpg?1621056588',
            'coingeckoId': 'honeycomb-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KAVA',
            'name': 'KAVA',
            'address': '0x5F88AB06e8dfe89DF127B2430Bba4Af600866035',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x5F88AB06e8dfe89DF127B2430Bba4Af600866035/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'VRT',
            'name': 'Venus Reward',
            'address': '0x5F84ce30DC3cF7909101C69086c50De191895883',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x5F84ce30DC3cF7909101C69086c50De191895883/logo.png',
            'coingeckoId': 'venus-reward-token',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MNS',
            'name': 'Monnos Token',
            'address': '0x5F58Aee77fbA2760CeC20cCDCd982Bd42E56FC4e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TIGER',
            'name': 'Forest Tiger',
            'address': '0x5F351820674cCD9203Ee9b019031647f31EFd2d9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26628/thumb/Tiger-removebg-preview.png?1659324553',
            'coingeckoId': 'forest-tiger',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NLC',
            'name': 'Nelore Coin',
            'address': '0x5F320C3b8f82AcFe8f2Bb1C85D63aA66A7FF524f',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26208/thumb/NLC.jpg?1656554961',
            'coingeckoId': 'nelore-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MLS',
            'name': 'Metaland Shares',
            'address': '0x5F2F6c4C491B690216E0f8Ea753fF49eF4E36ba6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25665/thumb/I3pUwLZp_400x400.jpg?1653287346',
            'coingeckoId': 'pikaster',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SF',
            'name': 'SphynxFi',
            'address': '0x5F2D91c698f2Bc1Fd9E4a92b1fcdA4D4aD17e0d3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27686/thumb/SphynxFi-b.png?1665215019',
            'coingeckoId': 'sphynxfi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XNL',
            'name': 'Chronicle',
            'address': '0x5F26Fa0C2Ee5d3c0323D861d0C503f31Ac212662',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18413/thumb/xnl_logo.png?1633517024',
            'coingeckoId': 'chronicle',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CAKEBANK',
            'name': 'Cake Bank',
            'address': '0x5F1b95784a033CD6842cf26eb9A9687F91Ad9e78',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17924/thumb/logo_256px.jpg?1629791331',
            'coingeckoId': 'cake-bank',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VBCH',
            'name': 'Venus BCH',
            'address': '0x5F0388EBc2B94FA8E123F404b79cCF5f40b29176',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x5f0388ebc2b94fa8e123f404b79ccf5f40b29176.png',
            'coingeckoId': 'venus-bch',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'XTRA',
            'name': 'XTRA',
            'address': '0x5F02C4Dcb270999282b850Caa47Af749Ce49FE00',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18734/thumb/tara.PNG?1633297854',
            'coingeckoId': 'xtra-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOLAR',
            'name': 'Solarmoon',
            'address': '0x5F00052b8c81a799b4f3bbC9eA2A1b7Ecd06fba6',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17805/thumb/196284873_107740531529163_654745816856139759_n.png?1629292878',
            'coingeckoId': 'solarmoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SINU',
            'name': 'Shepherd Inu',
            'address': '0x5Efe9c3e5b43580328104Da18A091cE6a3D40651',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26532/thumb/SHEPHERD-200x200.png?1658712056',
            'coingeckoId': 'shepherd-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRIME',
            'name': 'Crime Gold',
            'address': '0x5Efe0D32F7129497ccE3e371223F9aa569e023a9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25254/thumb/crime.png?1651041053',
            'coingeckoId': 'crime-gold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XTR',
            'name': 'Xtremcoin',
            'address': '0x5EcFcced226bA9fCcD8663e7b3263cBd8C84eDB5',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21281/thumb/xtr.png?1639120057',
            'coingeckoId': 'xtremcoin',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'CVP',
            'name': 'Concentrated Voting Power',
            'address': '0x5Ec3AdBDae549Dce842e24480Eb2434769e22B2E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x5Ec3AdBDae549Dce842e24480Eb2434769e22B2E.png',
            'coingeckoId': null,
            'listedIn': [
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'MZT',
            'name': 'Meta Z',
            'address': '0x5EF6b5ABaA7e9b75Fa4DaEBF0Fc722f9AFF12B40',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25287/thumb/PMZ.png?1651130474',
            'coingeckoId': 'meta-z',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TCH',
            'name': 'TigerCash',
            'address': '0x5ECc4B299e23F526980c33fe35EFF531A54AEDB1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/4956/thumb/tigercash-logo.png?1547040378',
            'coingeckoId': 'tigercash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KIAN',
            'name': 'Porta',
            'address': '0x5ECE3F1542C4e1a06767457e4D8286beA772fc41',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14844/thumb/Kianite.png?1626060939',
            'coingeckoId': 'porta',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PLAY',
            'name': 'Playmusic',
            'address': '0x5ECBaD49898f06FDBAA01282BC6edB83f217F365',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26894/thumb/Playmusic.png?1660709410',
            'coingeckoId': 'playmusic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KP3RB',
            'name': 'Keep3r BSC Network',
            'address': '0x5EA29eEe799aA7cC379FdE5cf370BC24f2Ea7c81',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x5ea29eee799aa7cc379fde5cf370bc24f2ea7c81/logo.png',
            'coingeckoId': 'keep3r-bsc-network',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SNB',
            'name': 'Safe Nebula',
            'address': '0x5E7fC3844463745FcA858f85D6b90D9a03fCBe93',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16944/thumb/SNB-Logo-400x400.png?1625719831',
            'coingeckoId': 'safe-nebula',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABYDOGEINU',
            'name': 'Baby Doge Inu',
            'address': '0x5E5C9001Aa81332D405D993FFd1468751D659d1e',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17012/thumb/KEtLxnLH_400x400.jpg?1633713669',
            'coingeckoId': 'baby-doge-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BRTR',
            'name': 'Barter',
            'address': '0x5E57f24415f37c7d304E85DF9B4C36bC08789794',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13401/thumb/BRTR.png?1612843022',
            'coingeckoId': 'barter',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPORT',
            'name': 'Sport',
            'address': '0x5E54301415546401626036a10f85F9f06DE8dFfd',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MON',
            'name': 'MONNFTS',
            'address': '0x5E4f0f6FAC92E76E41a0d043DF565fe8fBFc1De3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23589/thumb/MONlogo.png?1644648952',
            'coingeckoId': 'monnfts',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HTD',
            'name': 'Heroes TD',
            'address': '0x5E2689412Fae5c29BD575fbe1d5C1CD1e0622A8f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x5E2689412Fae5c29BD575fbe1d5C1CD1e0622A8f.png',
            'coingeckoId': 'heroes-td',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'CLEAN',
            'name': 'CleanOcean',
            'address': '0x5E19266e4a17521d65EFF608517fA0c82CA24813',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21421/thumb/clean.png?1639111797',
            'coingeckoId': 'cleanocean',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DGCL',
            'name': 'DigiCol Token',
            'address': '0x5E0691Ba91E21f3FDc88a0550Aa4f7304eD89b5C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1636266071547_15035687284769095.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'NMBTC',
            'name': 'NanoMeter Bitcoin',
            'address': '0x5DdB331C3Ba48A1D68CbF50dD3bC7Aac407Dc115',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20059/thumb/logo_-_2021-11-09T161322.006.png?1636445625',
            'coingeckoId': 'nanometer-bitcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTE',
            'name': 'Bet To Earn',
            'address': '0x5D7f9C9F3f901f2c1B576b8D81Bd4165647855A4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26498/thumb/bettoearn.jpg?1658355939',
            'coingeckoId': 'bet-to-earn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FAVE',
            'name': 'Favecoin',
            'address': '0x5D6Df41D39EFB3b07eAf79494E35C417F9d6910B',
            'decimals': 7,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17749/thumb/200x200-favecoin.png?1629173567',
            'coingeckoId': 'favecoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NNN',
            'name': 'Novem Gold',
            'address': '0x5D5c5c1d14FaF8Ff704295b2F502dAA9D06799a0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/11852/thumb/nnn.png?1648987249',
            'coingeckoId': 'novem-gold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OMC',
            'name': 'Ormeus Cash',
            'address': '0x5D2F9a9DF1ba3C8C00303D0b4C431897eBc6626A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/11798/thumb/Vooo8SX.png?1594359387',
            'coingeckoId': 'ormeus-cash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTA',
            'name': 'Bitcoin Asset',
            'address': '0x5D2436c74b8Ab54F3199f76a0761D30ca64A0827',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24967/thumb/bta.png?1649589020',
            'coingeckoId': 'bitcoin-asset-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DUCK',
            'name': 'DLP Duck',
            'address': '0x5D186E28934c6B0fF5Fc2feCE15D1F34f78cBd87',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13440/thumb/DLP_Duck_Token.png?1612840740',
            'coingeckoId': 'dlp-duck-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KWS',
            'name': 'Knight War Spirits',
            'address': '0x5D0E95C15cA50F13fB86938433269D03112409Fe',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18635/thumb/logo-200x200_game_kn.png?1632979979',
            'coingeckoId': 'knight-war-spirits',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZUNA',
            'name': 'Zuna',
            'address': '0x5D07eDAbA2151a3C6802B28636eCC23082398EdB',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19997/thumb/ZUNA_logo_for_CMC.png?1666262979',
            'coingeckoId': 'zuna',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BONDLY',
            'name': 'Forj',
            'address': '0x5D0158A5c3ddF47d4Ea4517d8DB0D76aA2e87563',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x5d0158a5c3ddf47d4ea4517d8db0d76aa2e87563.png',
            'coingeckoId': 'bondly',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'MHT',
            'name': 'Mouse Haunt',
            'address': '0x5Cb2C3Ed882E37DA610f9eF5b0FA25514d7bc85B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21845/thumb/EP4fc4J-_400x400.jpg?1640129584',
            'coingeckoId': 'mouse-haunt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GRBE',
            'name': 'Green Beli',
            'address': '0x5Ca4e7294B14EA5745EE2A688990e0cb68503219',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18776/thumb/Logo-200x200_%288%29.png?1633402162',
            'coingeckoId': 'green-beli',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALT',
            'name': 'Alitas',
            'address': '0x5Ca09af27b8a4F1D636380909087536BC7e2D94D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16330/thumb/ALT.png?1623748504',
            'coingeckoId': 'alitas',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POSI',
            'name': 'Position',
            'address': '0x5CA42204cDaa70d5c773946e69dE942b85CA6706',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17459/thumb/posi.png?1627887743',
            'coingeckoId': 'position-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CART',
            'name': 'CryptoArt.ai',
            'address': '0x5C8C8D560048F34E5f7f8ad71f2f81a89DBd273e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x5C8C8D560048F34E5f7f8ad71f2f81a89DBd273e.png',
            'coingeckoId': null,
            'listedIn': [
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'MURPHY',
            'name': 'MURPHYCAT',
            'address': '0x5C6CdAf28F26d8ed41A90f0B5C898CcA304Ecba4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17839/thumb/murphy-200x200.png?1629462409',
            'coingeckoId': 'murphycat',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LCT',
            'name': 'Local Traders',
            'address': '0x5C65BAdf7F97345B7B92776b22255c973234EfE7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27936/thumb/LT-Logo-200x200_%281%29_%281%29.png?1666510280',
            'coingeckoId': 'local-traders',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NTX',
            'name': 'NuNet',
            'address': '0x5C4Bcc4DbaEAbc7659f6435bCE4E659314ebad87',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20950/thumb/8Zb2W2Wi_400x400.png?1638137477',
            'coingeckoId': 'nunet',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'PRESALE',
            'name': 'Presale World',
            'address': '0x5C197A2D2c9081D30715C80bD1b57c996A14cda0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27627/thumb/200x200.png?1664954826',
            'coingeckoId': 'presale-world',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PDO',
            'name': 'pDollar',
            'address': '0x5BCcFbd33873A5498F8406146868eDdd5E998962',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x5bccfbd33873a5498f8406146868eddd5e998962.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'LEA',
            'name': 'LeapableIO',
            'address': '0x5BC61Ea7ff31bB59d022AE85AE877796b68c3f82',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23516/thumb/leapableio-icon.png?1644301598',
            'coingeckoId': 'leapableio',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ERON',
            'name': 'ERON',
            'address': '0x5B858f8f2369220bc840c06E0bFb9C2742879480',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21746/thumb/EPA_logo_2.png?1639981528',
            'coingeckoId': 'eron',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WELD',
            'name': 'WELD',
            'address': '0x5B6ebB33eea2D12eeFd4a9b2aEaf733231169684',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18544/thumb/weld.png?1661497968',
            'coingeckoId': 'weld',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GMM',
            'name': 'Gamium',
            'address': '0x5B6bf0c7f989dE824677cFBD507D9635965e9cD3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22749/thumb/14304.png?1642557454',
            'coingeckoId': 'gamium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIR',
            'name': 'Mirror Protocol',
            'address': '0x5B6DcF557E2aBE2323c48445E8CC948910d8c2c9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x5B6DcF557E2aBE2323c48445E8CC948910d8c2c9/logo.png',
            'coingeckoId': 'mirror-protocol',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BBTC',
            'name': 'Baby Bitcoin',
            'address': '0x5B0Dfe077B16479715C9838eb644892008abbFe6',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16997/thumb/logo_p_200.png?1626054508',
            'coingeckoId': 'baby-bitcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANATION',
            'name': 'A Nation',
            'address': '0x5Ae89b52f6B5D25EBA50f0441F91A81968689D95',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25174/thumb/Logo_Icon_ColorGradient200x200_nvsxez.png?1650526997',
            'coingeckoId': 'a-nation',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSCX',
            'name': 'BSCEX',
            'address': '0x5Ac52EE5b2a633895292Ff6d8A89bB9190451587',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x5ac52ee5b2a633895292ff6d8a89bb9190451587.png',
            'coingeckoId': 'bscex',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GSI',
            'name': 'Global Innovative Solutions',
            'address': '0x5AF2fe41Bd068e5469b69Bfa5a4dBB616D6aB25C',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27778/thumb/F6B68321-DEBD-40AE-8B78-EC07EF14D0CD.png?1665731232',
            'coingeckoId': 'global-innovative-solutions',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DGSTB',
            'name': 'Dogestribute',
            'address': '0x5AA638c6777e2F041951A3d539a32caC83503441',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17929/thumb/Dogestribute-logo.png?1629813723',
            'coingeckoId': 'dogestribute',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CWT',
            'name': 'CrossWallet',
            'address': '0x5A726a26eDB0Df8Fd55f03cc30aF8A7cEa81e78D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16460/thumb/AAoUhOw.png?1624259013',
            'coingeckoId': 'crosswallet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REUM',
            'name': 'Rewardeum',
            'address': '0x5A68431398A6DE785994441e206259702e259C5E',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18217/thumb/RLogoPNG.png?1631006904',
            'coingeckoId': 'rewardeum',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'BURN',
            'name': 'Bitburn',
            'address': '0x5A5844CAb96A7b8B257Fc606Aa6119c5cBc6D4c4',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26721/thumb/burn.png?1659867556',
            'coingeckoId': 'bitburn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JULD',
            'name': 'JulSwap',
            'address': '0x5A41F637C3f7553dBa6dDC2D3cA92641096577ea',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x5a41f637c3f7553dba6ddc2d3ca92641096577ea.png',
            'coingeckoId': 'julswap',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'AETH',
            'name': 'Aetherius',
            'address': '0x5A3B6f18Dc79D50ab208af2fCd61D10BF7e4896F',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22467/thumb/download.png?1641884214',
            'coingeckoId': 'aetherius',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RTO',
            'name': 'Reflecto',
            'address': '0x5A341DCF49e161CC73591f02e5f8CDE8A29733fb',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20483/thumb/cmc-logo.png?1637117236',
            'coingeckoId': 'reflecto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSCPAD',
            'name': 'BSCPAD',
            'address': '0x5A3010d4d8D3B5fB49f8B6E57FB9E48063f16700',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x5a3010d4d8d3b5fb49f8b6e57fb9e48063f16700.png',
            'coingeckoId': 'bscpad',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FRF',
            'name': 'France REV Finance',
            'address': '0x5A29c96fA93FFA8845fb7F8616a03Aa85fCc11d6',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22224/thumb/LOGO200_FRF.png?1641218289',
            'coingeckoId': 'france-rev-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TBL',
            'name': 'Tank Battle',
            'address': '0x59F6B2435Cd1421f409907aD2D9F811849ca555f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22917/thumb/15695.png?1643000287',
            'coingeckoId': 'tank-battle',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NOMAD',
            'name': 'NOMADLAND',
            'address': '0x59Cde41a855682349edaEA221169d9b686687748',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25379/thumb/nomad.png?1651410897',
            'coingeckoId': 'nomadland',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TFMC',
            'name': 'Tap Fantasy MC',
            'address': '0x59C49B54D4425C7fF1E49f40958a14d48cC87c26',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26483/thumb/Tap-Fantasy-MC-Logo.png?1658279327',
            'coingeckoId': 'tap-fantasy-mc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MASTER',
            'name': 'Master Floki',
            'address': '0x59A99b55C6E12d026CfD02Ae3E38f94f102fB46b',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x59a99b55c6e12d026cfd02ae3e38f94f102fb46b/logo.png',
            'coingeckoId': 'master-floki',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FOXGIRL',
            'name': 'FoxGirl',
            'address': '0x599beec263FA6eA35055011967597B259FC012A4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19448/thumb/foxgirl.png?1635639329',
            'coingeckoId': 'foxgirl',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BULLD',
            'name': 'Bulldoge Inu',
            'address': '0x599209ab40daeb44108d9E9426ED1DC207b85460',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20524/thumb/hpO1AKVq_400x400.png?1637189902',
            'coingeckoId': 'bulldoge-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'N1',
            'name': 'NFTify',
            'address': '0x5989D72a559eB0192F2d20170A43a4Bd28A1B174',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16095/thumb/n1-token-logo-circle-200x200.png?1627130530',
            'coingeckoId': 'nftify',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'bDIGG',
            'name': 'Badger Sett Digg',
            'address': '0x5986D5c77c65e5801a5cAa4fAE80089f870A71dA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x5986d5c77c65e5801a5caa4fae80089f870a71da.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': '3AIR',
            'name': '3air',
            'address': '0x596834746B5b78F31A089EE7853fa595682824B7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27399/thumb/200x200_icon.png?1664246520',
            'coingeckoId': '3air',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SUZA',
            'name': 'Suzaku',
            'address': '0x5967323c756293FA2431aDD76869A5C95764a8a9',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27055/thumb/630593a38b3a0019bc34a746_120x120_trans.png?1661511775',
            'coingeckoId': 'suzaku',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KAZOKU',
            'name': 'Kazoku INU',
            'address': '0x594d541aB4767Ad608E457F310045740B5Cc6071',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27136/thumb/kazoku_inu_logo.png?1662027061',
            'coingeckoId': 'kazoku-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MFY',
            'name': 'MetaFooty',
            'address': '0x594B8D6B0fA22683b991AECfa7b489731b6ae916',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27091/thumb/metafooty-3d.png?1661827048',
            'coingeckoId': 'metafooty',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GLAND',
            'name': 'Griffin Land',
            'address': '0x59234F46AcdB449d5A43eeFd841Bc07a21613Fc1',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26637/thumb/logo-200x200.png?1659328887',
            'coingeckoId': 'griffin-land',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTLX',
            'name': 'Mettalex',
            'address': '0x5921DEE8556c4593EeFCFad3CA5e2f618606483b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x5921DEE8556c4593EeFCFad3CA5e2f618606483b/logo.png',
            'coingeckoId': 'mettalex',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ASET',
            'name': 'Parasset',
            'address': '0x591AAaDBc85e19065C88a1B0C2Ed3F58295f47Df',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19395/thumb/token_200.png?1635151501',
            'coingeckoId': 'parasset',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOOKS',
            'name': 'LooksRare',
            'address': '0x590D11c0696b0023176f5D7587d6b2f502a08047',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x590D11c0696b0023176f5D7587d6b2f502a08047.png',
            'coingeckoId': null,
            'listedIn': [
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'GREED',
            'name': 'Greed',
            'address': '0x5905df3D03E29a22e22462D3257E6AC731E22C15',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19462/thumb/greed.png?1635248186',
            'coingeckoId': 'greed',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WETUX',
            'name': 'WETUX',
            'address': '0x58f0C2fd4B7B0fBaC97c9cC71A5Eb810627734C8',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26781/thumb/512x512.png?1660099697',
            'coingeckoId': 'wetux',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TSUGA',
            'name': 'Tsukiverse Galactic Adventures',
            'address': '0x58d372A8db7696C0C066F25c9eAF2AF6F147B726',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x58d372a8db7696c0c066f25c9eaf2af6f147b726.png',
            'coingeckoId': 'tsukiverse-galactic-adventures',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BINGO',
            'name': 'Tomorrowland',
            'address': '0x58C2Cc04b2859916C5E5683545B349df3d7530B8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27887/thumb/200_%282%29.png?1668586821',
            'coingeckoId': 'bingo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'V',
            'name': 'Valor',
            'address': '0x588Ff59EE7ba59c33d1e891468a168770E0AE7d8',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24087/thumb/Valor-200.png?1646294611',
            'coingeckoId': 'valor',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DCT',
            'name': 'DisciplesDAO',
            'address': '0x587f594635ACD348Fc45cCFA5BA79926003785d4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25903/thumb/20010.png?1654501984',
            'coingeckoId': 'disciplesdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WHALE',
            'name': 'Whale Fall',
            'address': '0x587C16b84c64751760f6e3e7e32F896634704352',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16783/thumb/whale_fall.PNG?1624964847',
            'coingeckoId': 'whale-fall',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SBT',
            'name': 'Solaris Betting Token',
            'address': '0x5879e032b77099bCA9c62B27A9b004Fd7E6334A1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27772/thumb/Logo_200.png?1665724260',
            'coingeckoId': 'solaris-betting-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DHV',
            'name': 'DeHive',
            'address': '0x58759Dd469ae5631C42cf8a473992335575b58D7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14926/thumb/logo_200x200.png?1626181831',
            'coingeckoId': 'dehive',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BYTZ',
            'name': 'BYTZ',
            'address': '0x586fC153Cf7e9c029D8C30842c4CB6a86F03B816',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/4017/thumb/bytz.png?1584519997',
            'coingeckoId': 'bytz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FUSE',
            'name': 'Fuse',
            'address': '0x5857c96DaE9cF8511B08Cb07f85753C472D36Ea3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x970b9bb2c0444f5e81e9d0efb84c8ccdcdcaf84d.png',
            'coingeckoId': 'fuse-network-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'SHD',
            'name': 'ShardingDAO',
            'address': '0x5845Cd0205b5d43AF695412a79Cf7C1Aeddb060F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14700/thumb/I1QLkPTq_400x400.png?1617836174',
            'coingeckoId': 'shardingdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XMT',
            'name': 'MetalSwap',
            'address': '0x582C12b30F85162Fa393e5DBe2573f9F601f9D91',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22075/thumb/xmt.png?1640943637',
            'coingeckoId': 'metalswap',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'KIT',
            'name': 'Kitty',
            'address': '0x580cf2C36b913228dd0194a833F0EAD8938F18AE',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19378/thumb/13019.png?1635141889',
            'coingeckoId': 'kitty',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GAL',
            'name': 'Gallant',
            'address': '0x580655E2C6D4fC125C0B2D37B2a3e56219bF9f78',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19319/thumb/ZflIjgF4_400x400.jpg?1635028709',
            'coingeckoId': 'gallant',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDO',
            'name': 'USD Open Dollar',
            'address': '0x5801D0e1C7D977D78E4890880B8E579eb4943276',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14828/thumb/New_USDO_32px_.png?1627365493',
            'coingeckoId': 'usd-open-dollar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DIS',
            'name': 'TosDis',
            'address': '0x57efFdE2759b68d86C544e88F7977e3314144859',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13745/thumb/Tosdis-black.png?1611379744',
            'coingeckoId': 'tosdis',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'xyETH',
            'name': 'xyETH',
            'address': '0x57eA46759fed1B47C200a9859e576239A941df76',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
            'coingeckoId': null,
            'listedIn': [
                'xyfinance'
            ]
        },
        {
            'symbol': 'SNAP',
            'name': 'SnapEx',
            'address': '0x57cd5A91c18D21DeDd72d3bcf255b60Aef290f53',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21458/thumb/snap.png?1639291894',
            'coingeckoId': 'snapex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HOME',
            'name': 'Home Coin',
            'address': '0x57c9487f30D04aEE52D323586EC9A23d05A1504e',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15818/thumb/fdZnO58.png?1621982577',
            'coingeckoId': 'home-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EVM',
            'name': 'EverMars',
            'address': '0x57c56665B2bcdf3CB86E40A9D3DC21f5b0AeD7Ad',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16424/thumb/evermars.PNG?1623991384',
            'coingeckoId': 'evermars',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RUGBUST',
            'name': 'Rug Busters ',
            'address': '0x57bb0f40479D7Dd0caa67f2A579273A8e9c038Ee',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x57bb0f40479d7dd0caa67f2a579273a8e9c038ee.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'RATS',
            'name': 'Ratscoin',
            'address': '0x57b798d2252557f13A9148A075a72816f2707356',
            'decimals': 0,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24612/thumb/rats.png?1648373609',
            'coingeckoId': 'ratscoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KCCM',
            'name': 'KCC MemePad',
            'address': '0x57F239E1e62abd0f68D7B7C0FE11Df25b3217d99',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17748/thumb/kcc.PNG?1629169680',
            'coingeckoId': 'kcc-memepad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IBS',
            'name': 'IBS',
            'address': '0x57D2A45653B329FAc354B04cEAd92C4db71cF09f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26003/thumb/a.jpg?1655277968',
            'coingeckoId': 'ibs',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZENITH',
            'name': 'Zenith Chain',
            'address': '0x57C81885FaAd67fC4dE892102f6FEAd3b9215f6b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21266/thumb/Jqtp-OVG_400x400.png?1638837643',
            'coingeckoId': 'zenith-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTH',
            'name': 'Bit Hotel',
            'address': '0x57Bc18F6177cDafFb34aCE048745bc913a1B1b54',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20363/thumb/BIT_HOTEL_LOGO.png?1636946715',
            'coingeckoId': 'bit-hotel',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VLTC',
            'name': 'Venus LTC',
            'address': '0x57A5297F2cB2c0AaC9D554660acd6D385Ab50c6B',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x57a5297f2cb2c0aac9d554660acd6d385ab50c6b.png',
            'coingeckoId': 'venus-ltc',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BFHT',
            'name': 'BeFaster Holder Token',
            'address': '0x577aD06F635b402fC2724Efd6a53A3A0aeD3d155',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13205/thumb/BFHT_Logo_200.png?1666607647',
            'coingeckoId': 'befasterholdertoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFTY',
            'name': 'NFTY Labs',
            'address': '0x5774B2fc3e91aF89f89141EacF76545e74265982',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18741/thumb/logo_%281%29.png?1643188469',
            'coingeckoId': 'nfty-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EVS',
            'name': 'Evoverse Shard',
            'address': '0x576AC55622333c7193447D20B985Ed8ed096065b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25408/thumb/RBX_Symbol.png?1651732272',
            'coingeckoId': 'evoverse-shard',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SK',
            'name': 'SideKick',
            'address': '0x5755E18D86c8a6d7a6E25296782cb84661E6c106',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16295/thumb/kyvXrEK.png?1623665144',
            'coingeckoId': 'sidekick-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YSOY',
            'name': 'YSOY Chain',
            'address': '0x57488Fcc3dC72Edb0a4c06a356c2c43C08BdfB42',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16822/thumb/ewnD8Bb.png?1625177403',
            'coingeckoId': 'ysoy-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PETN',
            'name': 'Pylon Eco',
            'address': '0x57457B5D725D85A70a3625D6a71818304e773618',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x57457b5d725d85a70a3625d6a71818304e773618.png',
            'coingeckoId': 'pylon-eco-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'PRVC',
            'name': 'PrivaCoin',
            'address': '0x5711f19b7B21938D31d07E5736b4660c1159d7D3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28140/thumb/PrivaCoin_-_PRVC.png?1667790417',
            'coingeckoId': 'privacoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '37C',
            'name': '37Protocol',
            'address': '0x570B17ADA7CfaF5c9dEb08b923125982BF817c63',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26942/thumb/37%EB%A1%9C%EA%B3%A0.png?1660914492',
            'coingeckoId': '37protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOL',
            'name': 'Wrapped Solana',
            'address': '0x570A5D26f7765Ecb712C0924E4De545B89fD43dF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21629/thumb/solana.jpg?1639626543',
            'coingeckoId': 'wrapped-solana',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PLX',
            'name': 'Octaplex Network',
            'address': '0x57022eDD5C7Ed7b6bd870488853Fe961dfDD3fB6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18013/thumb/octaplex-icon.png?1630232850',
            'coingeckoId': 'octaplex-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BIHODL',
            'name': 'BinanceHODL',
            'address': '0x56ea3A560086d7961CFa29B4eDE10Eb2300F53E4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20712/thumb/wUhzIPYJ_400x400.jpg?1637575654',
            'coingeckoId': 'binancehodl',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EOS',
            'name': 'Binance Peg EOS',
            'address': '0x56b6fB708fC5732DEC1Afc8D8556423A2EDcCbD6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x56b6fb708fc5732dec1afc8d8556423a2edccbd6.png',
            'coingeckoId': 'binance-peg-eos',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NOVA',
            'name': 'ShibaNova',
            'address': '0x56E344bE9A7a7A1d27C854628483Efd67c11214F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17946/thumb/shibanova.PNG?1631084117',
            'coingeckoId': 'shibanova',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELF',
            'name': 'ELF Wallet',
            'address': '0x56D594D76b37BE83C54aBF8a4747D60CE58D32C2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27571/thumb/logo-02.png?1664527696',
            'coingeckoId': 'elf-wallet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WNOW',
            'name': 'WalletNow',
            'address': '0x56AA0237244C67B9A854B4Efe8479cCa0B105289',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20149/thumb/walletnow.PNG?1636582255',
            'coingeckoId': 'walletnow',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'OTIUM',
            'name': 'Otium Technologies',
            'address': '0x56A02228CE17DBBbc809fe2262B36Dd99f28E824',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19935/thumb/KGBTUm7f_400x400.jpg?1636325773',
            'coingeckoId': 'otium-technologies',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CPS',
            'name': 'Cryptostone',
            'address': '0x569f4957176Ffa0dff76c507604f6a66d4B9C578',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26068/thumb/Cryptostone.png?1655539372',
            'coingeckoId': 'cryptostone',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STUD',
            'name': 'Studyum',
            'address': '0x56900Be791Fb3c6aBF1932df0F20DAaE6208dbE0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22005/thumb/jPzDUeNn_400x400.jpg?1640589211',
            'coingeckoId': 'studyum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STUDIO',
            'name': 'AVOSTUDIO',
            'address': '0x568336E556549AD455A847e09E38Da48AA150453',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x568336e556549ad455a847e09e38da48aa150453/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'HC',
            'name': 'SAVEBEE FARM HONEYCOMB',
            'address': '0x567d9bF9cdF2b8b6AC2962cB882EAbf08bd1fd22',
            'decimals': 12,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22932/thumb/RyM5VYj.png?1643003934',
            'coingeckoId': 'savebee-farm-honeycomb',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RANTS',
            'name': 'redFireAnts',
            'address': '0x567BBEF0efDF53355C569b7AeddE4C4f7c008014',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25269/thumb/website-rants-300x300.png?1651068334',
            'coingeckoId': 'redfireants',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OPIUM',
            'name': 'Opium',
            'address': '0x566cedD201F67E542A6851A2959c1a449a041945',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13758/thumb/opium-token-black_%281%29.png?1611767960',
            'coingeckoId': 'opium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MITHRIL',
            'name': 'Mithrilverse',
            'address': '0x566FdF5bF13b9642CbDfDd1e5F2A67DC2Bc1755A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20563/thumb/mithril.png?1640146488',
            'coingeckoId': 'mithrilverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BIS',
            'name': 'Bismuth',
            'address': '0x56672ecb506301b1E32ED28552797037c54D36A9',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/974/thumb/bis200px.png?1615787741',
            'coingeckoId': 'bismuth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CAF',
            'name': 'CAIRO',
            'address': '0x5662ac531A2737C3dB8901E982B43327a2fDe2ae',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27461/thumb/200x200.png?1665735450',
            'coingeckoId': 'cairo-finance-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HUNNY',
            'name': 'Hunny Finance',
            'address': '0x565b72163f17849832A692A3c5928cc502f46D69',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16043/thumb/hunny-logo.c87da9c4.png?1622684248',
            'coingeckoId': 'pancake-hunny',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'TKB',
            'name': 'TokenBot',
            'address': '0x5655592bADF214bbD520187DE0a766CD7Bd7C712',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SHEESH',
            'name': 'Sheesh',
            'address': '0x564F184692952ADF6A9512a13dc49cAF45E76b12',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15483/thumb/sheesh.png?1638093700',
            'coingeckoId': 'sheesh',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZDC',
            'name': 'Zodiacs',
            'address': '0x5649e392A1BAC3e21672203589aDF8f6C99f8dB3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20069/thumb/zodiacs.PNG?1636448156',
            'coingeckoId': 'zodiacs',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DFM',
            'name': 'DAO Farmer DFM',
            'address': '0x5645e089AAbbda1Aa3FEe7C56208013cFeFd1850',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24673/thumb/dfm.jpg?1648546430',
            'coingeckoId': 'dao-farmer-dfm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RBP',
            'name': 'Rare Ball Potion',
            'address': '0x563cA064E41f3B5D80ADEEcfE49Ab375fd7afBEf',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25824/thumb/6295aea8b46cd60001d5ac44_RBS160.png?1654063768',
            'coingeckoId': 'rare-ball-shares',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XED',
            'name': 'Exeedme',
            'address': '0x5621b5A3f4a8008c4CCDd1b942B121c8B1944F1f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x5621b5a3f4a8008c4ccdd1b942b121c8b1944f1f.png',
            'coingeckoId': 'exeedme',
            'listedIn': [
                'coingecko',
                '1inch',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PUMPKIN',
            'name': 'Pumpkin Inu',
            'address': '0x5619c8eAA4B45af1199c179a6ec77BCEb7c38103',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19447/thumb/pumpkin.png?1635236659',
            'coingeckoId': 'pumpkin-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPRT',
            'name': 'Sportium',
            'address': '0x56156Fb7860d7EB0B4b1a5356C5354b295194A45',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20981/thumb/sportium_logo_symbolmark_skyblue.png?1638163975',
            'coingeckoId': 'sportium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UART',
            'name': 'UniArts',
            'address': '0x5608D5159ab98881bc116ae7761c74Ad2eBb0448',
            'decimals': 12,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22092/thumb/3Rx82r_w_400x400.png?1640823145',
            'coingeckoId': 'uniarts',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'THC',
            'name': 'Transhuman Coin',
            'address': '0x56083560594E314b5cDd1680eC6a493bb851BBd8',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20328/thumb/kdEastmD_400x400.jpg?1636900381',
            'coingeckoId': 'transhuman-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'Tether',
            'address': '0x55d398326f99059fF775485246999027B3197955',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xdac17f958d2ee523a2206206994597c13d831ec7.png',
            'coingeckoId': 'tether',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WOJ',
            'name': 'Wojak Finance',
            'address': '0x55F96C7005D7C684A65Ee653B07b5FE1507C56ab',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18823/thumb/200x200.png?1660620693',
            'coingeckoId': 'wojak-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OKLG',
            'name': 'ok lets go ',
            'address': '0x55E8b37a3c43B049deDf56C77f462Db095108651',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20377/thumb/Screenshot-2021-11-14-at-23-45-11.png?1636950187',
            'coingeckoId': 'ok-lets-go',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FSK',
            'name': 'Farm Skylines',
            'address': '0x55E63EC9f5FB21372235fC88b9f6D6EC78aEEc21',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24317/thumb/qqaS9RUi_400x400.jpg?1647355244',
            'coingeckoId': 'farm-skylines',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SSB',
            'name': 'SatoshiStreetBets',
            'address': '0x55B53855eaE06c4744841dbFA06FCe335dB4355B',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19295/thumb/ssb.png?1634877031',
            'coingeckoId': 'satoshistreetbets',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATH',
            'name': 'Athena Crypto Bank',
            'address': '0x5597D204118436B5BcA397aded5aC6923a26033D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25880/thumb/ath.png?1654333860',
            'coingeckoId': 'athena-crypto-bank',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOCK',
            'name': 'Contracto',
            'address': '0x559100c4C697Ad514340943625bCeeFF8605cDC3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20085/thumb/contracto.PNG?1636458590',
            'coingeckoId': 'contracto',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'M',
            'name': 'MetaVerse M',
            'address': '0x558ad2b02cE979Ca54F88206ED8597c8c740774E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26104/thumb/M.png?1656656415',
            'coingeckoId': 'metaverse-m',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LAND',
            'name': 'LandBox',
            'address': '0x557f20CE25b41640ADe4a3085d42d7e626d7965A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14875/thumb/jmKvZDn7_400x400.jpg?1618826129',
            'coingeckoId': 'new-landbox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MSG',
            'name': 'Meta Sports',
            'address': '0x557c86c9C9E003CD74AFf798f3F540DdC546Ab9B',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28230/thumb/icon_200x200.png?1668570578',
            'coingeckoId': 'meta-sports',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ABLC',
            'name': 'ARABELLA',
            'address': '0x557a09f2a257e7ea0C9EdD45F4ABc1F5Eca05dfF',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27670/thumb/ablc.png?1665158139',
            'coingeckoId': 'arabella',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALI',
            'name': 'Alita',
            'address': '0x557233E794d1a5FbCc6D26dca49147379ea5073c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17750/thumb/Logo-200x200_%286%29.png?1629173982',
            'coingeckoId': 'alita',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AXSET',
            'name': 'Axie Infinity Shard  Wormhole ',
            'address': '0x556b60c53fbC1518Ad17E03d52E47368dD4d81B3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22952/thumb/AXSet_wh_small.png?1644224450',
            'coingeckoId': 'axie-infinity-shard-wormhole',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PACOCA',
            'name': 'Pacoca',
            'address': '0x55671114d774ee99D653D6C12460c780a67f1D18',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x55671114d774ee99d653d6c12460c780a67f1d18.png',
            'coingeckoId': 'pacoca',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'RWX',
            'name': 'RacewayX',
            'address': '0x555581c3124a07d0121b1E27a7abe8BC09288097',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ZENC',
            'name': 'Zenc Coin',
            'address': '0x55533Be59DE022d585a57E29539452d708D4A410',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25055/thumb/1TMxKir4_400x400.png?1649939681',
            'coingeckoId': 'zenc-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PING',
            'name': 'Sonar',
            'address': '0x5546600f77EdA1DCF2e8817eF4D617382E7f71F5',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16463/thumb/sonar_logo-200.png?1651140004',
            'coingeckoId': 'sonar',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'SMOL',
            'name': 'Smolting Inu',
            'address': '0x553539d40AE81FD0d9C4b991B0b77bE6f6Bc030e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25420/thumb/awL5SVbh_400x400.jpg?1651736210',
            'coingeckoId': 'smolting-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GWT',
            'name': 'Galaxy War',
            'address': '0x552594612f935441c01c6854EDf111F343c1Ca07',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22166/thumb/e2x7gMJ4_400x400.jpg?1641166277',
            'coingeckoId': 'galaxy-war',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSD',
            'name': 'Baby Shiba Dot',
            'address': '0x5517F1515DC48Fe10C0aF3730C6A64DfCF4dF6c6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20452/thumb/bsd.png?1637045824',
            'coingeckoId': 'baby-shiba-dot',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EZ',
            'name': 'EasyFi V2',
            'address': '0x5512014efa6Cd57764Fa743756F7a6Ce3358cC83',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x5512014efa6Cd57764Fa743756F7a6Ce3358cC83/logo.png',
            'coingeckoId': 'easyfi',
            'listedIn': [
                'coingecko',
                'dfyn',
                'RouterProtocol',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'COAPE',
            'name': 'Council of Apes',
            'address': '0x55006895f398D7713d69081144B19d8C9022B582',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21372/thumb/cofape.PNG?1639031337',
            'coingeckoId': 'council-of-apes',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HARL',
            'name': 'Harmony Launcher',
            'address': '0x54f85cDB537d5a5eBc76f55B13c6DBc3b6828D57',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ORI',
            'name': 'HNK Orijent 1919',
            'address': '0x54cC4dB6f878A1cDE6BdD0c8bEfCf70f5DABF206',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23696/thumb/ori-token-logo.png?1645080658',
            'coingeckoId': 'hnk-orijent-1919-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AEL',
            'name': 'Spantale',
            'address': '0x54c2a9EDd2Dd99502C831b76feB2Bd6eadd9343A',
            'decimals': 10,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21701/thumb/CX0eHTU.png?1639745127',
            'coingeckoId': 'spantale',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BMF',
            'name': 'Be Meta Famous',
            'address': '0x54c159b71262878Bf096b45a3c6A8FD0a3250B10',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25192/thumb/Meta_Fame_Token.png?1650605900',
            'coingeckoId': 'be-meta-famous',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTSC',
            'name': 'Metars',
            'address': '0x54a1d188461DC254C19B8f8CbEb0dFf1Ec960281',
            'decimals': 2,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25231/thumb/XtVAGCV7L-AVQuLrf7Zq1mKhHsE75KoQPGsB9rJIfpL4h5fZd_CfsZvFKCc6OSlaB1iYWUvNFOuHYulj0piiyD_6JR82NFiVAn9G30t-6fcf6JMHPuG8frRCWRaXMLusgDBzjZg214-z4s-cbhLqLn0qoRIn_jGR-hohCps_e0Pnl9NXPC-Y_vjviL6T6SruKklirqBXxvVSTc_mwfSo-g-0Lg.jpg?1650952900',
            'coingeckoId': 'metars',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLOKI',
            'name': 'Baby Moon Floki',
            'address': '0x54E87ed5A096f09d9665fD114002bdDFc2084a7F',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19333/thumb/wg8-8vHI_400x400.jpg?1635115616',
            'coingeckoId': 'baby-moon-floki',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PORTX',
            'name': 'ChainPort',
            'address': '0x54C3B88b7e9702F915DDc6E483Aaf369b2615F8D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24490/thumb/VE-tUL-q_400x400.png?1647855096',
            'coingeckoId': 'chainport',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZUM',
            'name': 'ZUM',
            'address': '0x54C2c07b3Af037567269Ad6A168D5bD527867b58',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/9721/thumb/zum256x256.png?1571264005',
            'coingeckoId': 'zum-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HWL',
            'name': 'Howl City',
            'address': '0x549CC5dF432cdbAEbc8B9158A6bDFe1cbD0Ba16d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20344/thumb/hwl.png?1636942327',
            'coingeckoId': 'howl-city',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHMB',
            'name': 'Chumbi Valley',
            'address': '0x5492Ef6aEebA1A3896357359eF039a8B11621b45',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1639983973447_09695190070221504.png',
            'coingeckoId': 'chumbai-valley',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'XBN',
            'name': 'Elastic BNB',
            'address': '0x547CBE0f0c25085e7015Aa6939b28402EB0CcDAC',
            'decimals': 148,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x547cbe0f0c25085e7015aa6939b28402eb0ccdac.png',
            'coingeckoId': 'xbn',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BT',
            'name': 'BT Finance',
            'address': '0x5478a575Ece4FB4768d1d43eDf5826cfe1c6225A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13824/thumb/BT-logo.324f553c.png?1612152632',
            'coingeckoId': 'bt-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COURSE',
            'name': 'Meta Course',
            'address': '0x5466B664a8B18A4545b15d41D01BaDA764c918dB',
            'decimals': 17,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25832/thumb/200.png?1654066631',
            'coingeckoId': 'meta-course',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHECOIN',
            'name': 'CheCoin',
            'address': '0x54626300818E5C5b44Db0fcf45Ba4943CA89A9e2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17399/thumb/hmvUIO89jIpjmOD6astn5S5pTSJqnhc3KowWEcI2.png?1627528698',
            'coingeckoId': 'checoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NEWB',
            'name': 'NewB Farm',
            'address': '0x545f90dC35CA1e6129f1fEd354b3e2DF12034261',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16029/thumb/NewB_Token_FA_CMC.png?1622624885',
            'coingeckoId': 'newb-farm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DREAMS',
            'name': 'Dreams Quest',
            'address': '0x54523D5fB56803baC758E8B10b321748A77ae9e9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19012/thumb/DQ-token.png?1635856569',
            'coingeckoId': 'dreams-quest',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZWZ',
            'name': 'Zombie World Z',
            'address': '0x5445451C07E20bA1ca887B6c74d66D358F46D083',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23749/thumb/zwz.png?1645337899',
            'coingeckoId': 'zombie-world-z',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SDT',
            'name': 'Stabledoc',
            'address': '0x543C7eBb52D56985f63F246A5b3558AFF79037d7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19124/thumb/stabledoc.PNG?1634521177',
            'coingeckoId': 'stabledoc-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'anyBTC',
            'name': 'ANY Bitcoin',
            'address': '0x54261774905f3e6E9718f2ABb10ed6555cae308a',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x54261774905f3e6e9718f2abb10ed6555cae308a.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BPLUS',
            'name': 'Billionaire Plus',
            'address': '0x542312eCa286C95A42495f9b7c89d3D2453e2B6A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21969/thumb/15163.png?1640559844',
            'coingeckoId': 'billionaire-plus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'wSOTE',
            'name': 'Soteria',
            'address': '0x541E619858737031A1244A5d0Cd47E5ef480342c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x541E619858737031A1244A5d0Cd47E5ef480342c/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'RMAI',
            'name': 'ROIMA INC',
            'address': '0x5416f06830C7826A2ee774c53a3589e707269AB3',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26952/thumb/logormai.png?1660988008',
            'coingeckoId': 'roima-inc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OCTANE',
            'name': 'Octane Protocol',
            'address': '0x5416AB2B4B5a40F740B67a83dc5939591B5c08BE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18521/thumb/octane-launcher-black.png?1632274923',
            'coingeckoId': 'octane-protocol-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PASSIVE',
            'name': 'Passive',
            'address': '0x540f8165E1052E3C61a57EaEFa7932359677CC9E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21255/thumb/passive_token.jpg?1638778033',
            'coingeckoId': 'passive-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MARVIN',
            'name': 'Elon s Marvin',
            'address': '0x54017FDa0ff8f380CCEF600147A66D2e262d6B17',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20886/thumb/marvin.png?1637832213',
            'coingeckoId': 'elons-marvin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DAL',
            'name': 'DAOLaunch',
            'address': '0x53e4b7Aa6cACcb9576548Be3259e62dE4Ddd4417',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20208/thumb/dal.png?1636642223',
            'coingeckoId': 'daolaunch',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GFLOKI',
            'name': 'GenshinFlokiInu',
            'address': '0x53b3338E3345758aE88b930e3d9759A95C5cE05c',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20292/thumb/sUdVrvb2_400x400.jpg?1636814111',
            'coingeckoId': 'genshinflokiinu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SNK',
            'name': 'CryptoSnake',
            'address': '0x53a38D12b84a7413eDa4B1206A7A28B59Aed9850',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19660/thumb/icon_%289%29.png?1635735597',
            'coingeckoId': 'snake-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VIC',
            'name': 'VICSTEP',
            'address': '0x53F542f581cA69076eB2c08f8E2aab97C07d21Dd',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25963/thumb/vic.png?1654939840',
            'coingeckoId': 'vicstep',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MPG',
            'name': 'Medping',
            'address': '0x53F0E242eA207B6E9b63E0a53e788267aA99fF9B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20971/thumb/MEDPING_LOGO.png?1638154197',
            'coingeckoId': 'medping',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NSH',
            'name': 'NoShit',
            'address': '0x53F042f3e809d2DcC9492dE2DbF05d1DA0EF5fbb',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22003/thumb/Screenshot-2021-12-26-at-22-12-23.png?1640588255',
            'coingeckoId': 'noshit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABY',
            'name': 'BabySwap',
            'address': '0x53E562b9B7E5E94b81f10e96Ee70Ad06df3D2657',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1660734656084_05897697921487688.png',
            'coingeckoId': 'babyswap',
            'listedIn': [
                'coingecko',
                'openocean'
            ]
        },
        {
            'symbol': 'SSTX',
            'name': 'Silver Stonks',
            'address': '0x5396734569e26101677Eb39C89413F7fa7d8006f',
            'decimals': 7,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17000/thumb/Logo-Silver-Stonks.png?1630048454',
            'coingeckoId': 'silverstonks',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SFEX',
            'name': 'SafeLaunch',
            'address': '0x5392Ff4a9BD006DC272C1855af6640e17Cc5ec0B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16938/thumb/sfex.png?1640336533',
            'coingeckoId': 'safelaunch',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '1-UP',
            'name': '1-UP',
            'address': '0x538D4622c34064DC532f232842B72A99cDEfe4C8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'GAMINGDOGE',
            'name': 'Gaming Doge',
            'address': '0x536627083e216C3Cf4476ec504eb51Bbd47cA4ac',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19783/thumb/gamingdoge.png?1635846659',
            'coingeckoId': 'gaming-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ART',
            'name': 'ArtOnline',
            'address': '0x535e67270f4FEb15BFFbFE86FEE308b81799a7a5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20170/thumb/logo-200x200_%2811%29.png?1636602614',
            'coingeckoId': 'artonline',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WKD',
            'name': 'Wakanda Inu',
            'address': '0x5344C20FD242545F31723689662AC12b9556fC3d',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20121/thumb/wakanda.PNG?1636522997',
            'coingeckoId': 'wakanda-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PHT',
            'name': 'ParadiseHotel NFT',
            'address': '0x533A36e7Dc77e6B936f2797f2c677679b9573fE7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22623/thumb/download.png?1642405791',
            'coingeckoId': 'paradisehotel-nft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '4CH5',
            'name': '4CHAN500',
            'address': '0x5325BaA42dC7604f427A8bDE6732F83C19028942',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x5325baa42dc7604f427a8bde6732f83c19028942/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BSHARE',
            'name': 'Bomb Money BShare',
            'address': '0x531780FAcE85306877D7e1F05d713D1B50a37F7A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x531780face85306877d7e1f05d713d1b50a37f7a.png',
            'coingeckoId': 'bomb-money-bshare',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ESPRO',
            'name': 'EsportsPro',
            'address': '0x5304adfD82E5A24b70fE7ac1F45FE50B1ab4cb1d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14187/thumb/logo.jpg?1614830245',
            'coingeckoId': 'esportspro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRZ',
            'name': 'Trazable',
            'address': '0x52fe7b439753092f584917e3EFEa86A1cFD210f9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24851/thumb/Logo_TRZ-Token_200.png?1649133506',
            'coingeckoId': 'trazable',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GLB',
            'name': 'Golden Ball',
            'address': '0x52eb6c887A4691f10Bee396778603927C23bE1fc',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20274/thumb/glb.PNG?1636707869',
            'coingeckoId': 'golden-ball',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUCKY',
            'name': 'LuckyDragon',
            'address': '0x52d95492F421425A5A507C0516034041b95fDAa3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26895/thumb/IMG_20220816_124857_873.jpg?1660717341',
            'coingeckoId': 'luckydragon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWIRL',
            'name': 'Swirl.Cash',
            'address': '0x52d86850bc8207b520340B7E39cDaF22561b9E56',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x52d86850bc8207b520340b7e39cdaf22561b9e56.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SCRL',
            'name': 'Wizarre Scroll',
            'address': '0x52c1751C89fc913ed274d72e8d56DcE4Ee44A5cf',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21046/thumb/scroll_200.png?1638257148',
            'coingeckoId': 'wizarre-scroll',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MON',
            'name': 'Medamon',
            'address': '0x52b7C9D984EA17E9EE31159Ca3FfF3790981B64A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25081/thumb/B1-F2-FD78-8375-439-A-B7-B0-88492-B55-F6-FD.png?1650263137',
            'coingeckoId': 'medamon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CGC',
            'name': 'HeroesTD CGC',
            'address': '0x52F8d048Ba279556dd981036e7fa0345B5a90c7a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22840/thumb/17508.png?1642745721',
            'coingeckoId': 'heroestd-cgc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ACK',
            'name': 'Arcade Kingdoms',
            'address': '0x52EC25E58a9e144ff002625BB2AA58Cc6DA24Cb2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26923/thumb/rsz_3uzi4ohb_400x400_%281%29.png?1660829616',
            'coingeckoId': 'arcade-kingdoms',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KT',
            'name': 'KingdomX',
            'address': '0x52Da44B5E584F730005daC8D2D2AcbDee44D4ba3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23411/thumb/kt.png?1644151409',
            'coingeckoId': 'kingdomx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HUSKY',
            'name': 'Husky AVAX',
            'address': '0x52D88a9a2a20A840d7A336D21e427E9aD093dEEA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17812/thumb/husky.png?1629721413',
            'coingeckoId': 'husky-avax',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COMP',
            'name': 'Compound',
            'address': '0x52CE071Bd9b1C4B00A0b92D298c512478CaD67e8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xc00e94cb662c3520282e6f5717214004a7f26888.png',
            'coingeckoId': 'compound-governance-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BNBx',
            'name': 'Super BNB',
            'address': '0x529A4116F160c833c61311569D6B33dFF41fD657',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/bsc/8bfdeaa46fe9be8f5cd43a53b8d1eea1.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SHIBAW',
            'name': 'Shiba Watch',
            'address': '0x52941a733F7bAb6E52d5C8f2045c9D9D9eA246Ff',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19626/thumb/shibaw.png?1635499044',
            'coingeckoId': 'shiba-watch',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NICHO',
            'name': 'Nichonft',
            'address': '0x52904d8bB07e72541C854793242D51128043d527',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25700/thumb/token_logo.png?1653451375',
            'coingeckoId': 'nichonft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALORA',
            'name': 'Alora',
            'address': '0x52840a1c56a40386dea15C33eE1D27599b1f8872',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26321/thumb/IMG_20220706_231539_617.jpg?1657245349',
            'coingeckoId': 'alora',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WETA',
            'name': 'WETA VR',
            'address': '0x527D5cBB37C56304121f05D54E4Fb75737517Ae0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21020/thumb/RLcto7b.png?1638192691',
            'coingeckoId': 'weta-vr',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MSP',
            'name': 'MetaSpets',
            'address': '0x5270556791Ae9f924a892A46DDd0b0b449281365',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24879/thumb/msp.png?1651301537',
            'coingeckoId': 'metaspets',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FRG',
            'name': 'Freedom Gold',
            'address': '0x525E6f5Ea64B6750C18B97CE535177F680f15211',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25939/thumb/icon.png?1654764774',
            'coingeckoId': 'freedom-gold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YAY',
            'name': 'YAY Games',
            'address': '0x524dF384BFFB18C0C8f3f43d012011F8F9795579',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18251/thumb/spaces_-MacxYkWFZNLr04KrFOx_avatar-1626443663682.png?1631136343',
            'coingeckoId': 'yay-games',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'TSHARE',
            'name': 'TSHARE',
            'address': '0x5248f6c6196EB9DF6fa4870c66f97638F33D13B0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'STARSHIP',
            'name': 'StarShip',
            'address': '0x52419258E3fa44DEAc7E670eaDD4c892B480A805',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x52419258e3fa44deac7e670eadd4c892b480a805.png',
            'coingeckoId': 'starship',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'OK',
            'name': 'Okcash',
            'address': '0x523821d20a283d955f6205B4C9252779Cd0f964B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/274/thumb/ok-logo-200px.png?1665792994',
            'coingeckoId': 'okcash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANOM',
            'name': 'Anomus Coin',
            'address': '0x5224F552f110eC78E6E0468138950AE5F3040942',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20865/thumb/w07_TWqn_400x400.png?1637807012',
            'coingeckoId': 'anomus-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOMB',
            'name': 'Bomb Money',
            'address': '0x522348779DCb2911539e76A1042aA922F9C47Ee3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x522348779dcb2911539e76a1042aa922f9c47ee3.png',
            'coingeckoId': 'bomb-money',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'RING',
            'name': 'RING Financial',
            'address': '0x521ef54063148E5F15F18B9631426175ceE23DE2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20677/thumb/Logo-rond-RING.png?1637550886',
            'coingeckoId': 'ring-financial',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'UTC',
            'name': 'Unitech',
            'address': '0x52171FFA4ceDa60ff9795e1F111adB14c3d7025e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24240/thumb/utc.png?1647088925',
            'coingeckoId': 'unitech',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LEGO',
            'name': 'Lego Coin V2',
            'address': '0x520EbCcc63E4d0804b35Cda25978BEb7159bF0CC',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27545/thumb/jP0kpqR6_400x400.jpeg?1664441103',
            'coingeckoId': 'lego-coin-v2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTL',
            'name': 'Bitlocus',
            'address': '0x51e7B598C9155b9DCcB04Eb42519F6EeC9C841e9',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20913/thumb/btl.png?1637922710',
            'coingeckoId': 'bitlocus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHI',
            'name': 'CHI Coin',
            'address': '0x51d9aB40FF21f5172B33e3909d94abdC6D542679',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26051/thumb/CHI_Logo.png?1655450771',
            'coingeckoId': 'chi-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETNA',
            'name': 'ETNA Network',
            'address': '0x51F35073FF7cF54c9e86b7042E59A8cC9709FC46',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14518/thumb/EtnaNewLogo200x200.png?1643193912',
            'coingeckoId': 'etna-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUCA',
            'name': 'LUCA',
            'address': '0x51E6Ac1533032E72e92094867fD5921e3ea1bfa0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21278/thumb/luca.PNG?1638862587',
            'coingeckoId': 'luca',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GAM',
            'name': 'Gamma GAM',
            'address': '0x51CB2cfF2DC93291CC3a56d74Dec731d5a9D3162',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20071/thumb/13893.png?1636448759',
            'coingeckoId': 'gamma-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KING',
            'name': 'KingFund',
            'address': '0x51C5807dd8398aeDFCc91E6483417838B41EAeB8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0x51c5807dd8398aedfcc91e6483417838b41eaeb8/c3831032924b9b3c8370e031588acd91.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'RGLD',
            'name': 'Rolaz Gold',
            'address': '0x51BFa748fBdEeE68626f9a1d41ddEC7Ea05aAf54',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25893/thumb/rgld.png?1654429266',
            'coingeckoId': 'rolaz-gold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SUPER',
            'name': 'SuperFarm',
            'address': '0x51BA0b044d96C3aBfcA52B64D733603CCC4F0d4D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x51ba0b044d96c3abfca52b64d733603ccc4f0d4d.png',
            'coingeckoId': 'superfarm',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'ZOMI',
            'name': 'ZOMI',
            'address': '0x5194433e6fB169AE74f06215D1FCfB642626CFEE',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21505/thumb/zomi-coin-front.png?1639374025',
            'coingeckoId': 'zomi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SSM',
            'name': 'Satoshi Monsters',
            'address': '0x518c521b262204eb1EDa670771CfC1D3bb6A9c34',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23436/thumb/logo200.png?1644211793',
            'coingeckoId': 'satoshi-monsters',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRB',
            'name': 'CRB Coin',
            'address': '0x518afa06aECA8dD0946B89a565e51F5a91d81176',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19399/thumb/crb.png?1635158925',
            'coingeckoId': 'crb-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAGIC',
            'name': 'MagicofGold',
            'address': '0x518705632201Fe5aAFBBCD80e9de427402a38f92',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25556/thumb/magicofgold-200x200.png?1652421668',
            'coingeckoId': 'magicofgold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BITT',
            'name': 'BITT',
            'address': '0x518445F0dB93863E5e93A7F70617c05AFa8048f1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13783/thumb/BITT_Logo_256pixels.png?1611733961',
            'coingeckoId': 'bittoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CORN',
            'name': 'ChickenLegs',
            'address': '0x518388Eba644E6DC68aDc9a9d480Af46A0742A56',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22863/thumb/Corn_200x200.png?1642752842',
            'coingeckoId': 'chickenlegs',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KFC',
            'name': 'Kentucky Fried Crypto',
            'address': '0x5175334d05FEb677b0910fb902454aeE10Fe96fd',
            'decimals': 2,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26069/thumb/DED11330-5553-49EC-AF7E-CDAC9E75BA73.jpeg?1655539754',
            'coingeckoId': 'kentucky-fried-crypto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ITEM',
            'name': 'ITEMVERSE',
            'address': '0x517396bD11d750E4417B82F2b0FcFa62a4f2bB96',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26716/thumb/%ED%86%A0%ED%81%B0%ED%8B%B0%EC%BB%A4%28%EC%8B%AC%EB%B2%8C%29.jpg?1659857195',
            'coingeckoId': 'itemverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LIFT',
            'name': 'Uplift',
            'address': '0x513C3200F227ebB62e3B3d00B7a83779643a71CF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20666/thumb/logo-200x200.png?1657675050',
            'coingeckoId': 'uplift',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FORGE',
            'name': 'Forge Finance',
            'address': '0x5138868Ed1814Be113227b8c6025cdc46d9D1D16',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24533/thumb/io0E0FB2_400x400.jpg?1648043609',
            'coingeckoId': 'forge-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TENW',
            'name': 'TEN Wallet',
            'address': '0x5132e359C9E784e339F803B9b73895335f3387cf',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23915/thumb/SqGBZEb.png?1645683124',
            'coingeckoId': 'ten-wallet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MRLM',
            'name': 'MetaRealm',
            'address': '0x510fdB9f25bC7960717B21C7C97FEFDEeD1c3934',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23441/thumb/TNTDWqp.png?1644212615',
            'coingeckoId': 'metarealm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METAWEB3PA',
            'name': 'MetaWeb3Pad',
            'address': '0x510b841dD8246C2793932661Ff412d0d8C022209',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27028/thumb/logo2.png?1661488029',
            'coingeckoId': 'metaweb3pad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TITANIA',
            'name': 'Titania',
            'address': '0x5108C0E857b30A8d191554134628fe0f1B7e78b4',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20457/thumb/titania.PNG?1637062179',
            'coingeckoId': 'titania-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WDEX',
            'name': 'Windex',
            'address': '0x5106B6165D4CE323edE6aC821DbCF83494e5D4C3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27613/thumb/photo_2022-09-23_06-49-58.jpg?1664853266',
            'coingeckoId': 'windex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FOXT',
            'name': 'Fox Trading',
            'address': '0x50ea9C9F88AEAB9f554b8ffB4d7a1017957e866A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/5182/thumb/foxtrading-logo.png?1547040619',
            'coingeckoId': 'fox-trading-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WARS',
            'name': 'MetaWars',
            'address': '0x50e756a22ff5cEE3559D18B9D9576bc38F09Fa7c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1635758239845_3011665961126244.png',
            'coingeckoId': 'metawars',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ONX',
            'name': 'OnX.finance',
            'address': '0x50dfd52c9f6961Bf94D1d5489AE4B3e37D2F1fe7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ZMBE',
            'name': 'RugZombie',
            'address': '0x50ba8BF9E34f0F83F96a340387d1d3888BA4B3b5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18670/thumb/MKHznEfH_400x400.jpg?1632875374',
            'coingeckoId': 'rugzombie',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DECODE',
            'name': 'Decode Coin',
            'address': '0x50bAFBA28852d2816EB62DA5c3137dC9b05858e8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27149/thumb/DecodeCoin_LOGO_200X200.png?1662102917',
            'coingeckoId': 'decode-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AKL',
            'name': 'Akil Coin',
            'address': '0x50F72B83A5AD59AD71c28e2e4B58C73740E2168C',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21455/thumb/Dt6kooD6_400x400.jpg?1639281925',
            'coingeckoId': 'akil-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RAZOR',
            'name': 'Razor Network',
            'address': '0x50DE6856358Cc35f3A9a57eAAA34BD4cB707d2cd',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13797/thumb/icon.png?1611919354',
            'coingeckoId': 'razor-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CUB',
            'name': 'Cub Finance',
            'address': '0x50D809c74e0B8e49e7B4c65BB3109AbE3Ff4C1C1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x50d809c74e0b8e49e7b4c65bb3109abe3ff4c1c1.png',
            'coingeckoId': 'cub-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'FUFU',
            'name': 'Fufu',
            'address': '0x509a51394CC4D6bb474FeFB2994b8975A55A6e79',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18812/thumb/fufu_icon_RGB.png?1633497540',
            'coingeckoId': 'fufu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PNIXS',
            'name': 'Phoenix Defi Finance',
            'address': '0x509949c4ee0C1C2c765CF9B23c75D823981E22aE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15319/thumb/dxsqGESE_400x400.jpg?1620511843',
            'coingeckoId': 'phoenix-defi-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WIZARD',
            'name': 'Wizard BSC',
            'address': '0x5066C68cAe3B9BdaCD6A1A37c90F2d1723559D18',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17132/thumb/Untitled-design-1.png?1626413294',
            'coingeckoId': 'wizard-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAINT',
            'name': 'Saint',
            'address': '0x503576189Edd9FEb058ad3f17f1E6e9172A11D75',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16324/thumb/Saint.png?1623740840',
            'coingeckoId': 'saint-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CCAR',
            'name': 'CryptoCars',
            'address': '0x50332bdca94673F33401776365b66CC4e81aC81d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1637660548456_5149320731324383.png',
            'coingeckoId': 'cryptocars',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'JEDALS',
            'name': 'Yoda Coin Swap',
            'address': '0x50288F36d77d08093809f65B0ADf4DED9f5c6236',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26117/thumb/YODA_COIN_SWAP_200x200.png?1655939290',
            'coingeckoId': 'yoda-coin-swap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SIMPLY',
            'name': 'Simply',
            'address': '0x500dd87DECC43dD9cCFa66343A1EbFA9F99b9dF4',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20650/thumb/simply.PNG?1637527583',
            'coingeckoId': 'simply',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OX2',
            'name': 'OxyO2',
            'address': '0x4ff08F7F52Ddba3E78C7754331c1baE737b0C50d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27591/thumb/oxyo2_logo_200x200.png?1665027489',
            'coingeckoId': 'oxyo2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METAHOME',
            'name': 'MetaHome',
            'address': '0x4feB347f1BE86019A94E3ac8F47CDF552590d8f2',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26439/thumb/200x200_%283%29.png?1658108135',
            'coingeckoId': 'metahome',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XWAP',
            'name': 'SwapX',
            'address': '0x4fe2598B2B29840c42Ff1Cb1a9cC151B09522A27',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21851/thumb/Logo-Twitter-2.png?1640142154',
            'coingeckoId': 'swapx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GTR',
            'name': 'Ghost Trader',
            'address': '0x4fb36A89eDcd377D63Fc7e39bD143685a5563594',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21869/thumb/trader-ghost-1.png?1640177528',
            'coingeckoId': 'ghost-trader',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LINKIES',
            'name': 'Sergey\'s BigMac Fund',
            'address': '0x4fD6b4DA1773C25D8904FC27f91085f235b4Ed0A',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x4fd6b4da1773c25d8904fc27f91085f235b4ed0a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DNDB',
            'name': 'DnD Metaverse',
            'address': '0x4f857cFE2cd50C1a94eC88232EE0F5e3FD5Db1Ff',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23938/thumb/logodndb.png?1645766277',
            'coingeckoId': 'dnd-metaverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZIRVE',
            'name': 'Zirve Coin',
            'address': '0x4f7a1C4c1E89aF01789dEa1a81cDECAbe203c8b8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24864/thumb/200.png?1649166072',
            'coingeckoId': 'zirve-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XPS',
            'name': 'Xpansion Game',
            'address': '0x4f745c0c7da552a348C384dA1a5BaEb28f2C607C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21539/thumb/15997.png?1639447406',
            'coingeckoId': 'xpansion-game',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ICR',
            'name': 'InterCrone',
            'address': '0x4f60Ad2c684296458b12053c0EF402e162971e00',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/5419/thumb/icr.png?1642835408',
            'coingeckoId': 'intercrone',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'STI',
            'name': 'Seek Tiger',
            'address': '0x4f5f7a7Dca8BA0A7983381D23dFc5eaF4be9C79a',
            'decimals': 10,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24628/thumb/50d6850967efa02b20fb24c6c7ba518.png?1648457363',
            'coingeckoId': 'seek-tiger',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DBTYCOON',
            'name': 'DeFi Bank Tycoon',
            'address': '0x4f5580dD96D8e9f361955001ce47b862b02C52f7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20705/thumb/db-transparent-200x200.png?1637571433',
            'coingeckoId': 'defi-bank-tycoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BEACH',
            'name': 'Beach Token',
            'address': '0x4f4543Fa5F7FE28a7758ef43FeA71d11fCCDAD5c',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17875/thumb/Beach-token-200x200.png?1629700664',
            'coingeckoId': 'beach-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FIGHT',
            'name': 'Crypto Fight Club',
            'address': '0x4f39c3319188A723003670c3F9B9e7EF991E52F3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x4f39c3319188A723003670c3F9B9e7EF991E52F3.png',
            'coingeckoId': 'crypto-fight-club',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'UBXS',
            'name': 'UBXS',
            'address': '0x4f1960E29b2cA581a38c5c474e123f420F8092db',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22558/thumb/logo_200x200.png?1642056380',
            'coingeckoId': 'ubxs-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VBSWAP',
            'name': 'vBSWAP',
            'address': '0x4f0ed527e8A95ecAA132Af214dFd41F30b361600',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x4f0ed527e8a95ecaa132af214dfd41f30b361600.png',
            'coingeckoId': 'vbswap',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'QA',
            'name': 'Quantum Assets',
            'address': '0x4ef29F3B804C316bA8bA464A765C601Fc092a2e9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x4ef29f3b804c316ba8ba464a765c601fc092a2e9.png',
            'coingeckoId': 'quantum-assets',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MNX',
            'name': 'MiniX',
            'address': '0x4ee83441a22BEB71eaAc91a225C39B6eC53D4FDd',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21320/thumb/minix-logo-circle-200x200.png?1638943435',
            'coingeckoId': 'minix',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WOW',
            'name': 'WOWNFT',
            'address': '0x4eaf5492838F34AAf6a5E1c603872DA94BAeDc7d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25128/thumb/wow_200x200.png?1650373062',
            'coingeckoId': 'wow-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YAE',
            'name': 'Cryptonovae',
            'address': '0x4eE438be38F8682ABB089F2BFeA48851C5E71EAF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14693/thumb/yae.png?1640337904',
            'coingeckoId': 'cryptonovae',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'LUNAR',
            'name': 'LunarHighway',
            'address': '0x4e8a9D0BF525d78fd9E0c88710099f227F6924cf',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x4e8a9d0bf525d78fd9e0c88710099f227f6924cf.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'HTB',
            'name': 'Hotbit',
            'address': '0x4e840AADD28DA189B9906674B4Afcb77C128d9ea',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x4e840AADD28DA189B9906674B4Afcb77C128d9ea/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PERP',
            'name': 'Perpetual Protocol',
            'address': '0x4e7f408be2d4E9D60F49A64B89Bb619c84C7c6F5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://get.celer.app/cbridge-icons/PERP.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'KRN',
            'name': 'KRYZA Network',
            'address': '0x4e6d79CDdEc12C229D53b38c11B204bcec118885',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18416/thumb/Je71mCoZ_400x400.png?1631841515',
            'coingeckoId': 'kryza-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FINE',
            'name': 'Refinable',
            'address': '0x4e6415a5727ea08aAE4580057187923aeC331227',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x4e6415a5727ea08aae4580057187923aec331227.png',
            'coingeckoId': 'refinable',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BRICK',
            'name': 'Brick',
            'address': '0x4e5aB517719a2BDbafEFC22C712d7b5BC5F5544E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16741/thumb/TILE1.png?1649223169',
            'coingeckoId': 'brick-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DFW',
            'name': 'DAO Farmer DFW',
            'address': '0x4e52D1dCE54AB9D3C1798673B9cF1947E6660A3e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24671/thumb/dfw.jpg?1648546033',
            'coingeckoId': 'dao-farmer-dfw',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TITANO',
            'name': 'Titano',
            'address': '0x4e3cABD3AD77420FF9031d19899594041C420aeE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20542/thumb/CGTitano.png?1637204414',
            'coingeckoId': 'titano',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KEL',
            'name': 'KelVPN',
            'address': '0x4e1b16Ef22935A575a6811D4616f98C4077E4408',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0x4e1b16ef22935a575a6811d4616f98c4077e4408.png',
            'coingeckoId': 'kelvpn',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'DAFI',
            'name': 'Dafi Protocol',
            'address': '0x4e0Fe270B856EeBb91Fb4B4364312be59F499A3F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14428/thumb/Dafi_Black_Icon.png?1616040406',
            'coingeckoId': 'dafi-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HUA',
            'name': 'Chihuahua',
            'address': '0x4dfAD9A4cBa318EFC53743b803588B113f8A84Bd',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15651/thumb/chihua.PNG?1621476950',
            'coingeckoId': 'chihuahua',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOGEX',
            'name': 'Doge X',
            'address': '0x4ddBa7c715072D797e28f79fd8Afc0942498E9A3',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x4ddba7c715072d797e28f79fd8afc0942498e9a3/logo.png',
            'coingeckoId': 'doge-x',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'B8T',
            'name': 'B8DEX',
            'address': '0x4dcCa80514c13dAcBd4A00c4E8dB891592a89306',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26201/thumb/logo_200%D1%85200.png?1656489082',
            'coingeckoId': 'b8dex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RIFICO',
            'name': 'Rin Finance Coin',
            'address': '0x4da8265bE61b9DA59c8e207BFcBC075F1D611F36',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25332/thumb/rifico_logo_png_1.png?1665371994',
            'coingeckoId': 'rin-finance-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KIMOCHI',
            'name': 'KimochiToken',
            'address': '0x4dA95bd392811897cde899d25FACe253a424BfD4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x4da95bd392811897cde899d25face253a424bfd4/logo.png',
            'coingeckoId': 'kimochi-finance',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BDOG',
            'name': 'Big Dog Coin',
            'address': '0x4dA2b1FaFb2E54a775b624e822504A4b504899f2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20437/thumb/bdog.jpeg?1637038334',
            'coingeckoId': 'big-dog-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BRAND',
            'name': 'BrandPad Finance',
            'address': '0x4d993ec7b44276615bB2F6F20361AB34FbF0ec49',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19532/thumb/brandpad.PNG?1635373732',
            'coingeckoId': 'brandpad-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'META',
            'name': 'Meta Musk',
            'address': '0x4d8164892df2d5cf4754799cb2a001E3D5832B30',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21019/thumb/5258415-copy-2.png?1644476311',
            'coingeckoId': 'meta-musk',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'SAFE',
            'name': 'SAFE AnWang ',
            'address': '0x4d7Fa587Ec8e50bd0E9cD837cb4DA796f47218a1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26604/thumb/logo.png?1658992439',
            'coingeckoId': 'safe-anwang',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEGENR',
            'name': 'Degenerate Money',
            'address': '0x4d6d0335E3B715341eB4F10b5751771cbCb7cEa3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15040/thumb/tokenlogo2.png?1619509522',
            'coingeckoId': 'degenerate-money',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PET',
            'name': 'Battle Pets',
            'address': '0x4d4e595d643dc61EA7FCbF12e4b1AAA39f9975B8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x4d4e595d643dc61ea7fcbf12e4b1aaa39f9975b8.png',
            'coingeckoId': 'battle-pets',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SANINU',
            'name': 'Santa Inu',
            'address': '0x4d496eFc21754481Fe7A9F3f0f758785adE8E1D3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20647/thumb/wzP7T0v1_400x400.jpg?1637527137',
            'coingeckoId': 'santa-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DAO',
            'name': 'DAO Maker',
            'address': '0x4d2d32d8652058Bf98c772953E1Df5c5c85D9F45',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13915/thumb/4.png?1612838831',
            'coingeckoId': 'dao-maker',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SMC',
            'name': 'Smart Money Coin',
            'address': '0x4d00DDCC526c14Fd353131F289b1e62C856E9737',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25916/thumb/ZjMK4Adm_400x400.jpg?1654592191',
            'coingeckoId': 'smart-money-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ISLBYZ',
            'name': 'Island Boyz',
            'address': '0x4cf7ea6CB0Af1b157b59372780c9b68147eD0608',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20481/thumb/1_%283%29.png?1637115988',
            'coingeckoId': 'island-boyz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AMT',
            'name': 'Amateras',
            'address': '0x4ce5f6Bf8e996Ae54709C75865709ACA5127Dd54',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21759/thumb/logo.png?1639988777',
            'coingeckoId': 'amateras',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MBT',
            'name': 'MagicBox',
            'address': '0x4ce2DB133035519F538b7849201D6D541972164c',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20165/thumb/logo_200.jpg?1636600809',
            'coingeckoId': 'magicbox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARCANELEAGUE',
            'name': 'ArcaneLeague',
            'address': '0x4cd980dD7f319688088a7d26F982b95719203EAC',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20937/thumb/OVax62Hr_400x400.jpg?1638022822',
            'coingeckoId': 'arcaneleague',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PKT',
            'name': 'PiratesKing',
            'address': '0x4cbd8ADCD7eCa7D1B71ADbBF484F1e8014681a9D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23930/thumb/C20D7CC4-7282-4BDE-9AE3-08F6705CCBBB.png?1645708312',
            'coingeckoId': 'piratesking',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAKK',
            'name': 'Makk',
            'address': '0x4cE3dBF21F46FEbE307BE777d9BB5638D6B0A8d4',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20399/thumb/makk.png?1636966683',
            'coingeckoId': 'makk',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABYDOGECASH',
            'name': 'Baby Doge Cash',
            'address': '0x4cDa4daAd72340B28925cCd6fA78db631267D3C4',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16846/thumb/babydogecash-cmc-logo.png?1625391403',
            'coingeckoId': 'baby-doge-cash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MND',
            'name': 'Mound',
            'address': '0x4c97c901B5147F8C1C7Ce3c5cF3eB83B44F244fE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17922/thumb/token-mnd.83a59964.png?1629790253',
            'coingeckoId': 'mound-token',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'REAU',
            'name': 'Vira Lata Finance',
            'address': '0x4c79b8c9cB0BD62B047880603a9DEcf36dE28344',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15173/thumb/1LU1ujd.png?1622175606',
            'coingeckoId': 'vira-lata-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ESC',
            'name': 'The Essential Coin',
            'address': '0x4c48cca6153Db911002F965D22fdeFcD95f33BE9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21913/thumb/The-Essential-Coin-200x200.png?1640241497',
            'coingeckoId': 'the-essential-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AI',
            'name': 'Artificial Intelligence',
            'address': '0x4c403b1879aa6A79BA9C599A393ccc5D9fD2E788',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20632/thumb/200x200.png?1637333095',
            'coingeckoId': 'artificial-intelligence',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APES',
            'name': 'Apes',
            'address': '0x4c2a5a1A4B01d293aFfaA4739F884d7a905A5A8f',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22073/thumb/npOIT79d_400x400.png?1640751823',
            'coingeckoId': 'apes-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MU',
            'name': 'Miracle Universe',
            'address': '0x4c2D292d4c72Ea7003793d86014941522B821fDa',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25301/thumb/2zkUxCiY_400x400.jpg?1651155436',
            'coingeckoId': 'miracle-universe',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PBL',
            'name': 'Pebble',
            'address': '0x4c21105de9927063490238Fd8d15c622078F4a7E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ANIFI',
            'name': 'AniFi World',
            'address': '0x4c161d6Cf0ec884141c44c852510Ff5B1b2D5092',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27057/thumb/token-200-200.png?1661514249',
            'coingeckoId': 'anifi-world',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XDOGE',
            'name': 'Xdoge',
            'address': '0x4c0415A6e340eCCebff58131799C6c4127cc39FA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19222/thumb/token3.png?1634709710',
            'coingeckoId': 'xdoge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORBIT',
            'name': 'Orbit',
            'address': '0x4bf5cd1AC6FfF12E88AEDD3c70EB4148F90F8894',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25807/thumb/apple-touch-icon.png?1653978963',
            'coingeckoId': 'orbit-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XCV',
            'name': 'XCarnival',
            'address': '0x4be63a9b26EE89b9a3a13fd0aA1D0b2427C135f8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x4be63a9b26EE89b9a3a13fd0aA1D0b2427C135f8.png',
            'coingeckoId': 'xcarnival',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'OUT',
            'name': 'OutPost',
            'address': '0x4b9FB6D85f1F51a9CC29aaf7127125737D94536E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27302/thumb/OUTpostDevcoin_200_200.png?1663224732',
            'coingeckoId': 'outpost',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BFT',
            'name': 'The Big Five',
            'address': '0x4b87F578d6FaBf381f43bd2197fBB2A877da6ef8',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27429/thumb/photo_2022-08-01_16-49-53.jpg?1663919791',
            'coingeckoId': 'the-big-five',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MCRT',
            'name': 'MagicCraft',
            'address': '0x4b8285aB433D8f69CB48d5Ad62b415ed1a221e4f',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1641123245045_9125764216427192.png',
            'coingeckoId': 'magiccraft',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'GMY',
            'name': 'Gameology',
            'address': '0x4b71BD5E1DB6CCE4179E175a3A2033E4F17b7432',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16364/thumb/CRfFHQ7.png?1623815522',
            'coingeckoId': 'gameology',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KEYFI',
            'name': 'KeyFi',
            'address': '0x4b6000F9163de2E3f0a01eC37E06e1469DBbcE9d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15098/thumb/keyfi_logo.jpg?1619690054',
            'coingeckoId': 'keyfi',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'PESA',
            'name': 'Pesabase',
            'address': '0x4adc604A0261E3D340745533964FFf6bB130f3c3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25437/thumb/Pesabase-logo-200x200.png?1651741561',
            'coingeckoId': 'pesabase',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPE',
            'name': 'SavePlanetEarth',
            'address': '0x4ac81e3631DcDa62109e3117c4CAE7bf70BbbbD2',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19759/thumb/spe.png?1635829598',
            'coingeckoId': 'saveplanetearth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANCHOR',
            'name': 'AnchorSwap',
            'address': '0x4aac18De824eC1b553dbf342829834E4FF3F7a9F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18909/thumb/anchorswap.PNG?1633910516',
            'coingeckoId': 'anchorswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANFT',
            'name': 'Artwork NFT',
            'address': '0x4aD871D02415717F55Adb23deF23FaEc98e7bba8',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18919/thumb/artwork_nft.PNG?1633922382',
            'coingeckoId': 'artwork-nft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLG',
            'name': 'Blue Gold',
            'address': '0x4aCE2020Ba93E393CdB128779Af1CDfD935E8918',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21263/thumb/blue_gold.PNG?1638836702',
            'coingeckoId': 'blue-gold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TWO',
            'name': '2gather',
            'address': '0x4a9e1259B79708700Fd4554Ff8BE20Dfde5f159b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18911/thumb/2gather.PNG?1633911539',
            'coingeckoId': '2gather',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFTPAD',
            'name': 'NFTPad',
            'address': '0x4a72AF9609d22Bf2fF227AEC333c7d0860f3dB36',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17930/thumb/nftpad.PNG?1629857163',
            'coingeckoId': 'nftpad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POODL',
            'name': 'Poodl',
            'address': '0x4a68C250486a116DC8D6A0C5B0677dE07cc09C5D',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x56a980328aee33aabb540a02e002c8323326bf36.png',
            'coingeckoId': 'poodle',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'POORDOGE',
            'name': 'PoorDoge',
            'address': '0x4a5eb6574Fe68c55362fc73b9EA76953d837985B',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20112/thumb/poordoge_logo.png?1636513927',
            'coingeckoId': 'poordoge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GST-BSC',
            'name': 'STEPN Green Satoshi Token on BSC',
            'address': '0x4a2c860cEC6471b9F5F5a336eB4F38bb21683c98',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1653832039516_7209871158661254.png',
            'coingeckoId': 'green-satoshi-token-bsc',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'GHSP',
            'name': 'Ghospers Game',
            'address': '0x4a0Cc0876EC16428A4Fa4a7C4c300dE2DB73b75b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19684/thumb/ghsp.png?1647695745',
            'coingeckoId': 'ghospers-game',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VERA',
            'name': 'Vera',
            'address': '0x4a0A3902e091cdb3AEc4279a6BFAC50297F0A79e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18519/thumb/JJXTVFOE_400x400.png?1632273174',
            'coingeckoId': 'vera',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XEND',
            'name': 'Xend Finance',
            'address': '0x4a080377f83D669D7bB83B3184a8A5E61B500608',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x4a080377f83d669d7bb83b3184a8a5e61b500608.png',
            'coingeckoId': 'xend-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REVA',
            'name': 'Revault Network',
            'address': '0x4FdD92Bd67Acf0676bfc45ab7168b3996F7B4A3B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19048/thumb/61920010aaba4d16b1d57215_gradient-bg_200.png?1637155545',
            'coingeckoId': 'revault-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HOTCROSS',
            'name': 'Hot Cross',
            'address': '0x4FA7163E153419E0E1064e418dd7A99314Ed27b6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x4FA7163E153419E0E1064e418dd7A99314Ed27b6/logo.png',
            'coingeckoId': 'hot-cross',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'KOMBAT',
            'name': 'MetaKombat',
            'address': '0x4F814F93E59242D96595844eb3882BbEE0398F7C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22415/thumb/I99B1oK9_400x400.jpg?1641804513',
            'coingeckoId': 'metakombat',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PHI',
            'name': 'Prometheus',
            'address': '0x4F7620a4e134B1D3fca3f419663aCf351b225C74',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23284/thumb/phi.png?1643538505',
            'coingeckoId': 'prometheus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HORGI',
            'name': 'Horgi',
            'address': '0x4F5C381861333097AFA97E98a8D6DC0eB0D69ec4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24615/thumb/horgi.png?1648374535',
            'coingeckoId': 'horgi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'R3FI',
            'name': 'Recharge Finance',
            'address': '0x4F55ab914CE8A633C7eb5d8b4D190A96E9ed7F90',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14036/thumb/IMG_20210518_073652_114.png?1623833528',
            'coingeckoId': 'recharge-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RAMEN',
            'name': 'RamenSwap',
            'address': '0x4F47A0d15c1E53F3d94c069C7D16977c29F9CB6B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x4f47a0d15c1e53f3d94c069c7d16977c29f9cb6b.png',
            'coingeckoId': 'ramenswap',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'LICO',
            'name': 'Liquid Collectibles',
            'address': '0x4F3266a56589357B4f8082918b14B923693e57f0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19772/thumb/AjjErjeF_400x400.png?1635838249',
            'coingeckoId': 'liquid-collectibles',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SANTA',
            'name': 'Santa Coin',
            'address': '0x4F1A6FC6A7B65Dc7ebC4EB692Dc3641bE997c2F2',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19654/thumb/eXsu0oNf_400x400.jpg?1637117362',
            'coingeckoId': 'santa-coin-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LEOPARD',
            'name': 'Leopard',
            'address': '0x4Efab39b14167Da54aebed2094a61aA1FD384056',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16270/thumb/IMG_20210430_200245_458.jpg?1623564509',
            'coingeckoId': 'leopard',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NVE',
            'name': 'Neloverse',
            'address': '0x4Ef420Cd10d732Dfdcd85a11a065a9c17961c5Ec',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26467/thumb/Neloverse_%28NVE%29_Logo_200x200_%281%29.png?1658193686',
            'coingeckoId': 'neloverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MYOBU',
            'name': 'My bu',
            'address': '0x4Ef06d1A55962f29D362c53c864a4f0c791DcB9E',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16122/thumb/myobu.png?1623037167',
            'coingeckoId': 'myobu',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'SBE',
            'name': 'Shiba Evil',
            'address': '0x4Ed6DfA9cf301504CEEe1c06827ea2fAca128f73',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26901/thumb/shiba_evil_logo_200.png?1660724661',
            'coingeckoId': 'shiba-evil',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AGAR',
            'name': 'AGA Rewards',
            'address': '0x4Ec16da4C9007462DE151C0DA9f5426c69978A7B',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14207/thumb/AR256.png?1614931200',
            'coingeckoId': 'aga-rewards-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DGS',
            'name': 'Deblox',
            'address': '0x4Ea636B489F51e2c332e2a6203Bf3FCc0954a5F4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24100/thumb/272017889_106112668638625_8328245986225138945_n.jpg?1646373677',
            'coingeckoId': 'deblox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AMZE',
            'name': 'The Amaze World',
            'address': '0x4EF519EC0108A7fa29C96860c076667A7Fe7C8D4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21620/thumb/BcE98e6z_400x400.png?1639619105',
            'coingeckoId': 'the-amaze-world',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORE',
            'name': 'ORE Network',
            'address': '0x4EF285c8cbe52267c022c39da98b97ca4b7e2fF9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x4ef285c8cbe52267c022c39da98b97ca4b7e2ff9.png',
            'coingeckoId': 'ptokens-ore',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'LOT',
            'name': 'Lottery Token',
            'address': '0x4E7Ae924FD9a5D60b56BE486b2900efE0c6a9CA7',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x4e7ae924fd9a5d60b56be486b2900efe0c6a9ca7.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'KS2',
            'name': 'Kingdom Swap 2 0',
            'address': '0x4E7181434f6fC45cA4A7fc88A5c0201Cd781aCe7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25458/thumb/KS.jpeg?1651820846',
            'coingeckoId': 'kingdomswap-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XEUS',
            'name': 'XEUS',
            'address': '0x4E141769366634D9c4e498257Fa7EC204d22b634',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19917/thumb/xeus.bd3217c8.png?1636185147',
            'coingeckoId': 'xeus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSKT',
            'name': 'BasketCoin',
            'address': '0x4Dd1984a706E1c2C227bea67AD2F92dbdE30AfCE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14766/thumb/BasketCoin_logo_light-250x250_wbg.png?1618306694',
            'coingeckoId': 'basketcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MJACK',
            'name': 'MjackSwap',
            'address': '0x4Dad73719c4F4D5197b89c19460b1171d800097d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24402/thumb/m-logo.png?1647529842',
            'coingeckoId': 'mjackswap',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'BOULPIK',
            'name': 'Boulpik Token',
            'address': '0x4Da5F4A448CbA9dEf3970F4F22409C9D785F6F53',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26871/thumb/BOULPIK_TOKEN.png?1660539821',
            'coingeckoId': 'boulpik-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CIFI',
            'name': 'Citizen Finance',
            'address': '0x4DCd4700B38ce6562730c27Da557F6de819b347B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24255/thumb/cifi.png?1647157454',
            'coingeckoId': 'citizen-finance',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'DPAD',
            'name': 'Dpad Finance',
            'address': '0x4DCAaa68170053aFBBdE15774931adba09272A55',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24312/thumb/0vrp38DQ_400x400.jpg?1647352973',
            'coingeckoId': 'dpad-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETH',
            'name': 'Ethereum  Wormhole ',
            'address': '0x4DB5a66E937A9F4473fA95b1cAF1d1E1D62E29EA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22990/thumb/ETH_wh_small.png?1644225466',
            'coingeckoId': 'ethereum-wormhole',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARIX',
            'name': 'Arix',
            'address': '0x4DB2495AfAd4c0E481fFc40FDaF66E13A786b619',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13485/thumb/ARIX.png?1632731110',
            'coingeckoId': 'arix',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WOW',
            'name': 'WOWswap',
            'address': '0x4DA996C5Fe84755C80e108cf96Fe705174c5e36A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x4da996c5fe84755c80e108cf96fe705174c5e36a.png',
            'coingeckoId': 'wowswap',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BNBP',
            'name': 'BNBPot',
            'address': '0x4D9927a8Dc4432B93445dA94E4084D292438931F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27629/thumb/bnbp-logocircle.png?1664955503',
            'coingeckoId': 'bnbpot',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KRAKBABY',
            'name': 'BabyKraken',
            'address': '0x4D79DA6F390D9E9d475802a693353C3715b7Bd75',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16771/thumb/pdXolk5.png?1624943361',
            'coingeckoId': 'babykraken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ENV',
            'name': 'Envoy',
            'address': '0x4D6B129db8A502B85FEDc3443FA4f58b50327238',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19631/thumb/env.png?1638148461',
            'coingeckoId': 'envoy-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VFOX',
            'name': 'VFOX',
            'address': '0x4D61577d8Fd2208A0afb814ea089fDeAe19ed202',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15363/thumb/vfox2.png?1629870083',
            'coingeckoId': 'vfox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STA',
            'name': 'STA',
            'address': '0x4D1E90aB966ae26c778b2f9f365aA40abB13f53C',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26189/thumb/200x200.png?1656393801',
            'coingeckoId': 'sta-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STFU',
            'name': 'STFU Labs',
            'address': '0x4D0cC4786b383e2C7846bA994546B85954924802',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27364/thumb/logo_CMC.png?1663666573',
            'coingeckoId': 'stfu-labs',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'Suter',
            'name': 'Suterusu',
            'address': '0x4CfbBdfBd5BF0814472fF35C72717Bd095ADa055',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x4CfbBdfBd5BF0814472fF35C72717Bd095ADa055/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SOUND',
            'name': 'Sound Coin',
            'address': '0x4CbdeB687443E2e4Dcea1A955186B86C365A2e20',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20290/thumb/94028407.png?1636813757',
            'coingeckoId': 'sound-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PSTAKE',
            'name': 'pSTAKE Finance',
            'address': '0x4C882ec256823eE773B25b414d36F92ef58a7c0C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x4C882ec256823eE773B25b414d36F92ef58a7c0C.png',
            'coingeckoId': 'pstake-finance',
            'listedIn': [
                'coingecko',
                'pancake',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'BITCOIN',
            'name': 'HarryPotterObamaSonic10Inu',
            'address': '0x4C769928971548eb71A3392EAf66BeDC8bef4B80',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21453/thumb/PYNP2xv.jpeg?1639268312',
            'coingeckoId': 'harrypotterobamasonic10inu',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'ARKS',
            'name': 'Ark Of The Universe',
            'address': '0x4C3D5aF5c43dBecee525327E93d51eB4D6ddabec',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23817/thumb/lwvmM0f.jpg?1645513736',
            'coingeckoId': 'ark-of-the-universe',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JKWON',
            'name': 'JAIL KWON',
            'address': '0x4C267653e851876dEdE1c90d494c2fC3F7F01178',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27160/thumb/Untitled_design_%2813%29.png?1662279931',
            'coingeckoId': 'jail-kwon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RUSD',
            'name': 'Reflecto USD',
            'address': '0x4Be8c674C51674bEb729832682bBA5E5b105b6e2',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26144/thumb/rusd-logo.png?1656039675',
            'coingeckoId': 'reflecto-usd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JPEG',
            'name': 'JPEG',
            'address': '0x4BFcE5A1aCC3B847AFa9579bA91DA33b08e66fb7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://get.celer.app/cbridge-icons/JPEG.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ROYAL',
            'name': 'RoyalPay',
            'address': '0x4BED6C95AeF12f6c3e680132Ba17b7FdFB79DB7d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25923/thumb/20460.png?1654673671',
            'coingeckoId': 'royalpay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VAI',
            'name': 'Vai',
            'address': '0x4BD17003473389A42DAF6a0a729f6Fdb328BbBd7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x4bd17003473389a42daf6a0a729f6fdb328bbbd7.png',
            'coingeckoId': 'vai',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'KALM',
            'name': 'KALM',
            'address': '0x4BA0057f784858a48fe351445C672FF2a3d43515',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x4BA0057f784858a48fe351445C672FF2a3d43515/logo.png',
            'coingeckoId': 'kalmar',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GOC',
            'name': 'GoCrypto',
            'address': '0x4B85a666deC7C959e88b97814E46113601B07e57',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/3181/thumb/gocrypto.png?1597810330',
            'coingeckoId': 'eligma',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DHC',
            'name': 'Diamond Hands DHC',
            'address': '0x4B747B2Ed0C445bD52a3A559fF12700689870ca9',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17605/thumb/mMr-qYsX_400x400.png?1628647249',
            'coingeckoId': 'diamond-hands-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DDDX',
            'name': 'DDDX Protocol',
            'address': '0x4B6ee8188d6Df169E1071a7c96929640D61f144f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24938/thumb/200_200.png?1649423045',
            'coingeckoId': 'dddx-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AKI',
            'name': 'Aki Inu',
            'address': '0x4B6Cb2bF1F0B594D0211c4Df2B0DD1aA4C7A1892',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26767/thumb/Logo.png?1660038730',
            'coingeckoId': 'aki-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLOWZ',
            'name': 'Flowz Coin',
            'address': '0x4B6A384D3A67954f29576c99f4972e79c86f3013',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x4b6a384d3a67954f29576c99f4972e79c86f3013/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PKN',
            'name': 'Poken',
            'address': '0x4B5DeCb9327B4D511A58137A1aDE61434AacdD43',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21574/thumb/G10VNBAR_400x400.jpg?1639490463',
            'coingeckoId': 'poken',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'FARM',
            'name': 'Harvest Finance',
            'address': '0x4B5C23cac08a567ecf0c1fFcA8372A45a5D33743',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12304/thumb/Harvest.png?1613016180',
            'coingeckoId': 'harvest-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DVDX',
            'name': 'Derived',
            'address': '0x4B4135A99775368f349b6f904808B648A9948393',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19052/thumb/DERIVED_Logo_Small_Green.png?1634262562',
            'coingeckoId': 'derived',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GAMEBOX',
            'name': 'Gamebox',
            'address': '0x4B31E9F15211768e4d16b31c11Eb0D6c402b2230',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19433/thumb/gamebox.PNG?1635217927',
            'coingeckoId': 'gamebox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLIP',
            'name': 'FlipStar',
            'address': '0x4B0b206d43d8Bd2C52147F46436c62Fb4c214388',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21030/thumb/9VBcwEsp_400x400.jpg?1638230939',
            'coingeckoId': 'flipstar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TWT',
            'name': 'Trust Wallet',
            'address': '0x4B0F1812e5Df2A09796481Ff14017e6005508003',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x4b0f1812e5df2a09796481ff14017e6005508003.png',
            'coingeckoId': 'trust-wallet-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'XGRO',
            'name': 'GROWTH DeFi',
            'address': '0x4AdAE3Ad22c4e8fb56D4Ae5C7Eb3abC0dd2d3379',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12239/thumb/GRO_logo_icon_black_background_round_500_x_500-01.png?1628171711',
            'coingeckoId': 'growth-defi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLOKI',
            'name': 'Shiba Floki Inu',
            'address': '0x4AadaD81487c3fadD9F162b851E6a61b729200cb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17115/thumb/shiba-logo-v2_2x.png?1642928563',
            'coingeckoId': 'shiba-floki',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TINU',
            'name': 'Telegram Inu',
            'address': '0x4Aa22532e3E8B051eae48e60C58426c8553D5df5',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27085/thumb/TELEGRAMINU_logo.png?1661765748',
            'coingeckoId': 'telegram-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HARE',
            'name': 'Hare  OLD ',
            'address': '0x4AFc8c2Be6a0783ea16E16066fde140d15979296',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16327/thumb/200ico.png?1623745151',
            'coingeckoId': 'hare-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CODI',
            'name': 'Coin Discovery',
            'address': '0x4AC32178097c1f62beadCC2D215B54D6915013ee',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18839/thumb/IdvWAhot_400x400.jpg?1633572605',
            'coingeckoId': 'coin-discovery',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TIME',
            'name': 'Madagascar',
            'address': '0x4AAd6A01068c2621545d087A3c5281837112585b',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17624/thumb/7U1c0mS4_400x400.jpg?1628672279',
            'coingeckoId': 'madagascar-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DF',
            'name': 'dForce',
            'address': '0x4A9A2b2b04549C3927dd2c9668A5eF3fCA473623',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/9709/thumb/xlGxxIjI_400x400.jpg?1571006794',
            'coingeckoId': 'dforce-token',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'KABOSU',
            'name': 'Kabosu',
            'address': '0x4A824eE819955A7D769e03fe36f9E0C3Bd3Aa60b',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15396/thumb/o7LLTW8.png?1620713528',
            'coingeckoId': 'kabosu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MILK2',
            'name': 'Spaceswap MILK2',
            'address': '0x4A5a34212404f30C5aB7eB61b078fA4A55AdC5a5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x80c8c3dcfb854f9542567c8dac3f44d709ebc1de.png',
            'coingeckoId': 'spaceswap-milk2',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'SHWA',
            'name': 'ShibaWallet',
            'address': '0x4A2940263acfd179dBc1C6B69A3392671ACCaf5B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://shibawallet.pro/assets/favicon32x32.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'DBT',
            'name': 'Disco Burn Token',
            'address': '0x4A251d4fDcbbbc0A3d6Aa44F14B96480C4933C9C',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x4a251d4fdcbbbc0a3d6aa44f14b96480c4933c9c/logo.png',
            'coingeckoId': 'disco-burn-token',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SRD',
            'name': 'SpaceRobotDao',
            'address': '0x4A24EDda87797Fe0Ac6dfB6f3c40D60753d29cD9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28023/thumb/9999.png?1667031063',
            'coingeckoId': 'spacerobotdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PORTO',
            'name': 'FC Porto',
            'address': '0x49f2145d6366099e13B10FbF80646C0F377eE7f6',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x49f2145d6366099e13B10FbF80646C0F377eE7f6.png',
            'coingeckoId': 'fc-porto',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'USDTSO',
            'name': 'Tether USD  Wormhole ',
            'address': '0x49d5cC521F75e13fa8eb4E89E9D381352C897c96',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22881/thumb/USDTso_wh_small.png?1644223113',
            'coingeckoId': 'tether-usd-wormhole',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GTF',
            'name': 'GLOBALTRUSTFUND',
            'address': '0x49d20165FBdAd66B7e87937acAA74AF47beb32C3',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/11817/thumb/gtf.png?1594679456',
            'coingeckoId': 'globaltrustfund',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LAEEB',
            'name': 'La eeb',
            'address': '0x49aC596e7915Bd40C887a75c5093C60fFB8B34B0',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27852/thumb/laeeb.png?1666094533',
            'coingeckoId': 'la-eeb',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALYA',
            'name': 'Alyattes',
            'address': '0x49a9F9A2271d8c5dA44C57e7102ACA79C222F4A9',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22645/thumb/lEKKpUDi_400x400.jpg?1642397002',
            'coingeckoId': 'alyattes',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MICROSANTA',
            'name': 'Micro Santa Coin',
            'address': '0x49E558939F6dD099c2BC29BD84b75aF82b5Ad1b3',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21388/thumb/K6sCZ-le_400x400.jpg?1639035093',
            'coingeckoId': 'micro-santa-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HERA',
            'name': 'Hero Arena',
            'address': '0x49C7295ff86EaBf5bf58C6eBC858DB4805738c01',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19160/thumb/hera.png?1634549874',
            'coingeckoId': 'hero-arena',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FTT',
            'name': 'FTX  Wormhole ',
            'address': '0x49BA054B9664e99ac335667a917c63bB94332E84',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22996/thumb/FTT_wh_small.png?1644225637',
            'coingeckoId': 'ftx-wormhole',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IKOLF',
            'name': 'IKOLF',
            'address': '0x49A516BD4406b2D4074C738a58De6DB397D0ABC9',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26868/thumb/IMG_20220814_132323_400.jpg?1660537118',
            'coingeckoId': 'ikolf',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZSKRAT',
            'name': 'ZOMBIE SKRAT',
            'address': '0x49954Ad438fa9e27C21AeCd35078dD52cDDd96be',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27593/thumb/200.png?1664707995',
            'coingeckoId': 'zombie-skrat',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PAYGO',
            'name': 'PayGo',
            'address': '0x49708C95FBD8EB65831811f7f2E19DDdA008Fd51',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24972/thumb/271869870-650496619328235-2114002614573935348-n-3.png?1649646277',
            'coingeckoId': 'paygo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTS',
            'name': 'Metastrike',
            'address': '0x496cC0b4ee12Aa2AC4c42E93067484e7Ff50294b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22892/thumb/weD5ZQkc_400x400.jpg?1642832800',
            'coingeckoId': 'metastrike',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSGG',
            'name': 'BETSWAP.GG',
            'address': '0x496E80Cad8712025dAfc5b2C96FC36c395af684a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/17169.png',
            'coingeckoId': null,
            'listedIn': [
                'RouterProtocol',
                'lifinance'
            ]
        },
        {
            'symbol': 'SENSEI',
            'name': 'Sensei',
            'address': '0x495C3b71de97290361C6A7eAbeBd5C20cD60326c',
            'decimals': 7,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18281/thumb/sensei.png?1653739069',
            'coingeckoId': 'sensei',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RLTV2',
            'name': 'RLTv2',
            'address': '0x4958c51fb5Cb1dd06D96198F3da6eD1800Ecf940',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25762/thumb/rlttoken.png?1653548984',
            'coingeckoId': 'runner-land',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BNU',
            'name': 'ByteNext',
            'address': '0x4954e0062E0A7668A2FE3df924cD20E6440a7b77',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16000/thumb/logo.png?1641777102',
            'coingeckoId': 'bytenext',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KHAOS',
            'name': 'Khaos Finance',
            'address': '0x4954CEb270BF39dBefCF6E769597D3C14faF9e8f',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24881/thumb/logo_icon_200.png?1649230922',
            'coingeckoId': 'khaos-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GAFA',
            'name': 'Gafa',
            'address': '0x495205d4c6307A73595C5C11B44Bee9B3418Ac69',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24625/thumb/gafalogot.png?1648449612',
            'coingeckoId': 'gafa',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CASHDOG',
            'name': 'CashDog',
            'address': '0x494A2f5395aC213622762e4ef4d44661758Ca639',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16180/thumb/muEcKFE.png?1623214824',
            'coingeckoId': 'cashdog',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POKELON',
            'name': 'POKELON',
            'address': '0x4945c9E9dF719ad0602a10F4064e71326e5628f0',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24793/thumb/pokelon.png?1648977172',
            'coingeckoId': 'pokelon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOSS',
            'name': 'Boss',
            'address': '0x49324d59327fB799813B902dB55b2a118d601547',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17927/thumb/XL7jo4j.png?1629809801',
            'coingeckoId': 'boss',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FAR',
            'name': 'Farmland Protocol',
            'address': '0x49236832a3f75D3B13cBA687dA952AEA5042E2Aa',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DART',
            'name': 'Dart Inu',
            'address': '0x49152DD96bE5a710fAcF9a8aB7DE4343A49186d5',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20997/thumb/dart.PNG?1638175952',
            'coingeckoId': 'dart-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'mCOIN',
            'name': 'Mirror COIN',
            'address': '0x49022089e78a8D46Ec87A3AF86a1Db6c189aFA6f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x49022089e78a8D46Ec87A3AF86a1Db6c189aFA6f/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ETHO',
            'name': 'Etho Protocol',
            'address': '0x48b19b7605429aCAa8Ea734117F39726a9AAb1f9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/5194/thumb/ether1new-transparent.png?1578298993',
            'coingeckoId': 'ether-1',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EMC',
            'name': 'Emirate Swap Token',
            'address': '0x48a9fDAc66AD79483244957C37C0c37d3CB87f40',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27888/thumb/02-59-48-22275.png?1666244503',
            'coingeckoId': 'emirate-swap-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATPAD',
            'name': 'AtomPad',
            'address': '0x48EE0cC862143772feaBaF9b4757C36735d1052e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20700/thumb/PU0GdfUG_400x400.jpg?1637565993',
            'coingeckoId': 'atompad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AUCTION',
            'name': 'Bounce Token',
            'address': '0x48DC0190dF5ece990c649A7A07bA19D3650a9572',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1634884765220_4038457789109604.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'OUSE',
            'name': 'Ouse',
            'address': '0x48CB1c21aF7b5D04a8c48479D1BDfa783ce94d99',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22276/thumb/ouse-token.png?1641361425',
            'coingeckoId': 'ouse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RADAR',
            'name': 'DappRadar',
            'address': '0x489580eB70a50515296eF31E8179fF3e77E24965',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20894/thumb/radar.png?1640306268',
            'coingeckoId': 'dappradar',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'CTN',
            'name': 'Continuum Finance',
            'address': '0x4861bA0CE919FeE66B41c85a08A7476557914275',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24338/thumb/LMWqfjP6_400x400.png?1647362901',
            'coingeckoId': 'continuum-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BASHTANK',
            'name': 'Baby Shark Tank',
            'address': '0x484312A0AAeaE3aE36a74FF3e294246F35dDDf4F',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15390/thumb/IqTGmFXy_400x400.jpg?1620703139',
            'coingeckoId': 'baby-shark-tank',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OTAKU',
            'name': 'FOMO Chronicles Manga',
            'address': '0x484215873a674F9af73367a8F94c2C591e997521',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19093/thumb/Otaku_200x200.png?1634504166',
            'coingeckoId': 'fomo-chronicles-manga',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SUI',
            'name': 'Salmonation',
            'address': '0x4841181Ae4079072EbE83a29B718388a387169E3',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24408/thumb/logo-sui-200.png?1647531006',
            'coingeckoId': 'salmonation',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FUR',
            'name': 'Furio',
            'address': '0x48378891d6E459ca9a56B88b406E8F4eAB2e39bF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26035/thumb/furio.png?1655448700',
            'coingeckoId': 'furio',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GLORY',
            'name': 'League Of Pets',
            'address': '0x483322Bd0327E47B03Fb1d39Bffb8b94B81ef53E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27747/thumb/logo_200px.png?1665645710',
            'coingeckoId': 'league-of-pets',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZEUM',
            'name': 'Colizeum',
            'address': '0x482e6BD0a178f985818c5DFB9AC77918E8412FBA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24448/small/AB0cGpnx_400x400.jpg?1647681843',
            'coingeckoId': 'colizeum',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'ABIC',
            'name': 'Arabic',
            'address': '0x4823A096382f4Fa583b55d563afb9F9a58C72FC0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19549/thumb/abic.png?1635400194',
            'coingeckoId': 'arabic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZIX',
            'name': 'Coinzix Token',
            'address': '0x48077400FAF11183c043Feb5184a13ea628Bb0DB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27886/thumb/logo_coinzix_200x200_transparent_png.png?1666239312',
            'coingeckoId': 'coinzix-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TMT',
            'name': 'TopManager',
            'address': '0x4803Ac6b79F9582F69c4fa23c72cb76dD1E46d8d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1645065357654_28759165278764054.png',
            'coingeckoId': 'topmanager',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'NSK',
            'name': 'NSKSwap',
            'address': '0x47fB260e384C807C7f365F754239408cD1ff34f2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27390/thumb/jJVgRCVA_400x400.jpeg?1663814841',
            'coingeckoId': 'nskswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BZZONE',
            'name': 'Bzzone',
            'address': '0x47fA20ba81333BA507d687913bAF7c89432182A1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17784/thumb/IMG-20210817-182009-762.png?1629250494',
            'coingeckoId': 'bzzone',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XDEX',
            'name': 'XDEFIGovernanceToken',
            'address': '0x47f46484A8D7573e07513B6e6b2BDC4f748Ba294',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'EARN',
            'name': 'EverEarn',
            'address': '0x47d42E0e864ceFf8C4270F7b6E1f6F91Da45882C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23310/thumb/hCHEYlIv_400x400.jpg?1643753184',
            'coingeckoId': 'everearn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OLA',
            'name': 'Ola City',
            'address': '0x47d0F6195911e93fE2b9b456289B6769aa47268f',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25072/thumb/-INBZWHO_400x400.png?1650243241',
            'coingeckoId': 'ola-city',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EPICHERO',
            'name': 'EpicHero',
            'address': '0x47cC5334F65611EA6Be9e933C49485c88C17F5F0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18449/thumb/epichero.png?1633573456',
            'coingeckoId': 'epichero',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLURRY',
            'name': 'Flurry Finance',
            'address': '0x47c9BcEf4fE2dBcdf3Abf508f147f1bBE8d4fEf2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16235/thumb/flurry_logo_only_200x200.png?1623365114',
            'coingeckoId': 'flurry',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YVS',
            'name': 'YVS.Finance on BSC',
            'address': '0x47c1C7B9D7941A7265D123DCfb100D8FB5348213',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xec681f28f4561c2a9534799aa38e0d36a83cf478.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'KCLP',
            'name': 'Kross Chain Launchpad',
            'address': '0x47b8806C2891c4a92b5c590C32CFE1Eb617648EF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17272/thumb/KCLP.jpg?1630906316',
            'coingeckoId': 'korss-chain-launchpad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOBY',
            'name': 'Moby Dick',
            'address': '0x47a57a9174Fd8E4228e31735238A3059CFE6eCd2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24998/thumb/nG3ToMV5_400x400.png?1649731122',
            'coingeckoId': 'moby-dick-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HDO',
            'name': 'HADO',
            'address': '0x47E3d11bA6FBBb317F92d542Ba875562132E47C9',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0x47e3d11ba6fbbb317f92d542ba875562132e47c9/2b5bdc8aa995a01863a1f7c9cbe0fde3.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PIXEL',
            'name': 'PixelVerse',
            'address': '0x47DB24E17C0C4622523449a239b3de746E2b0b23',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19934/thumb/pixelverse.PNG?1636325521',
            'coingeckoId': 'pixelverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MGT',
            'name': 'Moongame',
            'address': '0x47CBd8454C986a15C16d285bD228D214F662Dcf3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19618/thumb/iconmoongame.jpg?1635495594',
            'coingeckoId': 'moongame',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SXP',
            'name': 'SXP',
            'address': '0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x8ce9137d39326ad0cd6491fb5cc0cba0e089b6a9.png',
            'coingeckoId': 'swipe',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SFUND',
            'name': 'Seedify fund',
            'address': '0x477bC8d23c634C154061869478bce96BE6045D12',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x477bc8d23c634c154061869478bce96be6045d12.png',
            'coingeckoId': 'seedify-fund',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ARCANE',
            'name': 'Arcane Token',
            'address': '0x477565B356B3973d16e8CD837C6970613f13e24A',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21529/thumb/arcane.png?1640325139',
            'coingeckoId': 'arcane-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOME',
            'name': 'Everdome',
            'address': '0x475bFaa1848591ae0E6aB69600f48d828f61a80E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x475bfaa1848591ae0e6ab69600f48d828f61a80e.png',
            'coingeckoId': 'everdome',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'APO',
            'name': 'ApolloFi',
            'address': '0x475DB18D764DF7FaDfbD3e73fcCbBC1e14342aB3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26451/thumb/apo.png?1660311541',
            'coingeckoId': 'apollofi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PETT',
            'name': 'Pett Network',
            'address': '0x474aB5a9103D5cD91cbAffC43f1842C25915DBEc',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27147/thumb/pett-logo.png?1662093299',
            'coingeckoId': 'pett-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNO',
            'name': 'Uno Re',
            'address': '0x474021845C4643113458ea4414bdb7fB74A01A77',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/8875.png',
            'coingeckoId': 'uno-re',
            'listedIn': [
                'coingecko',
                'RouterProtocol'
            ]
        },
        {
            'symbol': 'HIPPO',
            'name': 'Hippo Inu',
            'address': '0x473F9e0d804338edBC2c5853A749D31121006592',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26196/thumb/Safeimagekit-resized-imgpng_%282%29.png?1656457085',
            'coingeckoId': 'hippo-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DIGICHAIN',
            'name': 'Digichain Coin',
            'address': '0x4732A86106064577933552FCea993D30BEC950a5',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22213/thumb/digichain.png?1641195867',
            'coingeckoId': 'digichain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPONGS',
            'name': 'SpongeBob Square',
            'address': '0x471f883BBd2c705F418Ba3d6667ef05342C4ee05',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0x471f883bbd2c705f418ba3d6667ef05342c4ee05/3cb8b8a1413add8d9284f07dff55ae28.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FAS',
            'name': 'Fasst',
            'address': '0x471ed23831918959Bbd85C479120A4365DB7F31a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26469/thumb/logo_200X200.png?1658194511',
            'coingeckoId': 'fasst',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KOM',
            'name': 'Kommunitas',
            'address': '0x471Ea49dd8E60E697f4cac262b5fafCc307506e4',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17483/thumb/1_f1S3h57YLT1e1cl8g7RJpw_2x.jpeg?1627956421',
            'coingeckoId': 'kommunitas',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CONJ',
            'name': 'Conjee',
            'address': '0x471Ae8201D8eBcD4411Dd02A7091b00829995a44',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22360/thumb/VAfhxZ9__400x400.jpg?1641564352',
            'coingeckoId': 'conjee',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HUB',
            'name': 'HubGame',
            'address': '0x46bCBBB0D888dF90Eda86c9CA159C7727a8731D5',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27316/thumb/token_logo_200.png?1663315357',
            'coingeckoId': 'hubgame',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TGDAO',
            'name': 'TG DAO',
            'address': '0x46F275321107d7c49cF80216371AbF1a1599c36F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22834/thumb/JjRiXuqw_400x400.jpg?1642670277',
            'coingeckoId': 'tg-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METAP',
            'name': 'MetapPlay',
            'address': '0x46E7e56D237D0CbfbBF375f9e0b2fc3F8BC1C88A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22608/thumb/Screenshot_2022-01-15_112002.jpg?1642217727',
            'coingeckoId': 'metapplay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WSPP',
            'name': 'WolfSafePoorPeople',
            'address': '0x46D502Fac9aEA7c5bC7B13C8Ec9D02378C33D36F',
            'decimals': 0,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x46d502fac9aea7c5bc7b13c8ec9d02378c33d36f.png',
            'coingeckoId': 'wolfsafepoorpeople',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'HIMO',
            'name': 'Himo World',
            'address': '0x469aCf8e1F29C1B5Db99394582464Fad45A1fC6F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23000/thumb/16726.png?1643090812',
            'coingeckoId': 'himo-world',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UMI',
            'name': 'UMI',
            'address': '0x46943113Ae84e732bB510B5F7686D8B76fF56774',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16953/thumb/umi.PNG?1625793521',
            'coingeckoId': 'umi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WOO',
            'name': 'WOO Network',
            'address': '0x4691937a7508860F876c9c0a2a617E7d9E945D4B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x4691937a7508860f876c9c0a2a617e7d9e945d4b.png',
            'coingeckoId': 'woo-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DGE',
            'name': 'DragonSea',
            'address': '0x467f4773879a3917DDc2A6beFA430c5D8Ac22BeE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20276/thumb/IMG-20211112-152137-726.jpg?1636708673',
            'coingeckoId': 'dragonsea',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DO',
            'name': 'Do',
            'address': '0x4676ef21Ee45872a2fb4c82a238170da249ba758',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23790/thumb/O1M_2pwe_400x400.jpg?1645438223',
            'coingeckoId': 'do-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FCF',
            'name': 'French Connection Finance',
            'address': '0x4673f018cc6d401AAD0402BdBf2abcBF43dd69F3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19089/thumb/transparent_background.png?1665646769',
            'coingeckoId': 'french-connection-finance',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'STEP',
            'name': 'Step',
            'address': '0x465707181ACba42Ed01268A33f0507e320a154bD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22352/thumb/fOag4nRa_400x400.jpg?1647429563',
            'coingeckoId': 'step',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAGICDOGE',
            'name': 'MagicDOGE',
            'address': '0x46568808a8d73c301071b8dF710402Ce44F1C472',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19050/thumb/-MagicDoge.png?1634260119',
            'coingeckoId': 'magicdoge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALM',
            'name': 'Realm',
            'address': '0x464FdB8AFFC9bac185A7393fd4298137866DCFB8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18366/thumb/realm.PNG?1631665838',
            'coingeckoId': 'realm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MEONG',
            'name': 'Meong',
            'address': '0x464ACc420607d818f271875552868dDF8095CAfE',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20219/thumb/93235804.png?1636674976',
            'coingeckoId': 'meong-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CTT',
            'name': 'CryptoTycoon',
            'address': '0x464863745ED3aF8b9f8871f1082211C55f8f884D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15882/thumb/ctt.PNG?1622191728',
            'coingeckoId': 'cryptotycoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TOAD',
            'name': 'Toad Network',
            'address': '0x463E737D8F740395ABf44f7aac2D9531D8D539E9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0x463e737d8f740395abf44f7aac2d9531d8d539e9/f2b188ab7a3127a29ef8d465e6b7a10f.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'HMOON',
            'name': 'HELLMOON',
            'address': '0x46328f831cFF23F44D65fa5e53a7217a660554Aa',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19585/thumb/hmoon.png?1635733236',
            'coingeckoId': 'hellmoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POFI',
            'name': 'PoFi',
            'address': '0x461f6C9aE13a7daC7055C73fBF8daB529D667041',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14734/thumb/POLLO200_200.png?1617963715',
            'coingeckoId': 'pofi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DRIV',
            'name': 'Drivez',
            'address': '0x461daB902f38210b42b7D8B4bfc71296E0629006',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26648/thumb/N-ld8PAU_400x400.jpeg?1659359253',
            'coingeckoId': 'drivez',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TOKO',
            'name': 'Tokoin',
            'address': '0x45f7967926e95FD161E56ED66B663c9114C5226f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/8807/thumb/TOKOIN_LOGO_%28no_text%29.png?1646664093',
            'coingeckoId': 'toko',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSCAKE',
            'name': 'Bunscake',
            'address': '0x45d0f429790Bec7AD4b68330B1051c7b8fE9d8aF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19826/thumb/bunscake.PNG?1635978566',
            'coingeckoId': 'bunscake',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TBS',
            'name': 'TLabs',
            'address': '0x45Fffed8d9651fE9EA0321fcC9b15Ee5e052A208',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26376/thumb/input-onlinepngtools.png?1657614907',
            'coingeckoId': 'tlabs',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PLN',
            'name': 'Plutonium',
            'address': '0x45E027fCA0364333BDB725a524cB401531A0f1d1',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20735/thumb/pln.png?1638346853',
            'coingeckoId': 'plutonium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '2044',
            'name': '2044 Nuclear Apocalypse',
            'address': '0x45D91829c81a25541669dED69A494982f26E5c43',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27111/thumb/2044.jpg?1662010789',
            'coingeckoId': '2044-nuclear-apocalypse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COMT',
            'name': 'Community Metaverse',
            'address': '0x45B239Cc0a760D1AFd276B749141c7E404844Ee6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22353/thumb/500x500comt.png?1642172851',
            'coingeckoId': 'community-metaverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELNC',
            'name': 'EloniumCoin',
            'address': '0x45B110fCdd1a20fCEb1805ec0F7Ca6ef3712BeFc',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17864/thumb/fVZiSxD.png?1629686061',
            'coingeckoId': 'eloniumcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EKTA',
            'name': 'Ekta',
            'address': '0x45808Ce43eb2D7685fF0242631f0FeB6f3D8701a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22350/thumb/token.jpg?1641882684',
            'coingeckoId': 'ekta-2',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'DEFI',
            'name': 'BNBDeFi',
            'address': '0x455dedAcbe41c178953119847F2b95E2d9AD0a1D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24469/thumb/_defi.png?1647768034',
            'coingeckoId': 'bnbdefi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FAM',
            'name': 'FAM',
            'address': '0x4556A6f454f15C4cD57167a62bdA65A6be325D1F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17809/thumb/Logo_Fam_200px.png?1629333750',
            'coingeckoId': 'fam-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KZEN',
            'name': 'Kaizen',
            'address': '0x4550003152F12014558e5CE025707E4DD841100F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24396/thumb/PKl5OVRv_400x400.png?1647522756',
            'coingeckoId': 'kaizen',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SRCX',
            'name': 'Source Protocol',
            'address': '0x454B90716a9435E7161a9AEa5Cf00e0aCBE565aE',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25898/thumb/80368b_7b0a30441d7c4134a35067553ef6de3e_mv2_%281%29.png?1654591482',
            'coingeckoId': 'source-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAPE',
            'name': 'Bored Battle Apes',
            'address': '0x452c180E8573133C15275C3F81D11DE7ADB9aE1D',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23451/thumb/logo.png?1644214414',
            'coingeckoId': 'bored-battle-apes',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MLT',
            'name': 'Media Licensing Token',
            'address': '0x4518231a8FDF6ac553B9BBD51Bbb86825B583263',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x4518231a8fdf6ac553b9bbd51bbb86825b583263.png',
            'coingeckoId': 'media-licensing-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'AFIT',
            'name': 'Actifit',
            'address': '0x4516Bb582F59bEfcbC945D8c2DaC63ef21fba9f6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25229/thumb/9244.png?1650951199',
            'coingeckoId': 'actifit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CORGI',
            'name': 'CorgiCoin',
            'address': '0x450dCf93160A30BE156A4600802c91BF64dFFD2E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16096/thumb/BMfphzgF_400x400.png?1622881650',
            'coingeckoId': 'corgicoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MDR',
            'name': 'Mudra MDR',
            'address': '0x4505e2ae4F9C512fd2E7E4d99C99DC94e0E93CCB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26145/thumb/MudraLogo200x200.png?1656039972',
            'coingeckoId': 'mudra-mdr',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZEG',
            'name': 'Zagent Gem',
            'address': '0x44fC1739a34628C06aeE84906359a35f648b5e7f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23794/thumb/SZelTvS.png?1645438839',
            'coingeckoId': 'zagent-gem',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LGO',
            'name': 'LegioDAO',
            'address': '0x44c663476eCb54487c95e82C5bD05B9694253A9F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25422/thumb/LegioDAO_transparent.png?1651736525',
            'coingeckoId': 'legiodao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AMAZINGTEAM',
            'name': 'AmazingTeamDAO',
            'address': '0x44Ece1031e5b5e2D9169546cC10EA5C95BA96237',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25064/thumb/EXlV29q.jpg?1650013930',
            'coingeckoId': 'amazingteamdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HFT',
            'name': 'Hashflow',
            'address': '0x44Ec807ce2F4a6F2737A92e985f318d035883e47',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x44ec807ce2f4a6f2737a92e985f318d035883e47.png',
            'coingeckoId': 'hashflow',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'RPTR',
            'name': 'Raptor Finance',
            'address': '0x44C99Ca267C2b2646cEEc72e898273085aB87ca5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25265/thumb/Vy7-XGjj_400x400.jpg?1651053305',
            'coingeckoId': 'raptor-finance-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NIFTY',
            'name': 'NiftyPays',
            'address': '0x448E2671B8b074FD8E71D7AA62D6cC3fAD5dd793',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23300/thumb/p44KTVAa_400x400.jpg?1643668330',
            'coingeckoId': 'niftypays',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SATT',
            'name': 'Smart Advertising Transaction Token',
            'address': '0x448BEE2d93Be708b54eE6353A7CC35C4933F1156',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0x448bee2d93be708b54ee6353a7cc35c4933f1156/16f78da93e47dffc540e6b605c8fe457.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'RFG',
            'name': 'Refugees',
            'address': '0x4477b28E8b797eBaebd2539bb24290Fdfcc27807',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x4477b28e8b797ebaebd2539bb24290fdfcc27807.png',
            'coingeckoId': 'refugees-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MUNDO',
            'name': 'MUNDO',
            'address': '0x44757fA720548d472B5a87a119401627f935A6A2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23663/thumb/crystal_token_logo.png?1644992097',
            'coingeckoId': 'mundo-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZEE',
            'name': 'ZeroSwap',
            'address': '0x44754455564474A89358B2C2265883DF993b12F0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x44754455564474A89358B2C2265883DF993b12F0/logo.png',
            'coingeckoId': 'zeroswap',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': '5STAR',
            'name': '5Star',
            'address': '0x446E078aF272A34155419c9c0CfaF3005CDf5bE1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25520/thumb/5-STAR-LOGO.png?1652174148',
            'coingeckoId': '5star',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUDG',
            'name': 'BullDogSwap',
            'address': '0x446320C9FfA57030ca977A1f90F8049DF4004647',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18699/thumb/budg.PNG?1633037662',
            'coingeckoId': 'bulldogswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TT',
            'name': 'TDEX Token',
            'address': '0x445cC9518cF7bc7386A2e3aaF510650b0FB05f5F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26036/thumb/tt.png?1660903901',
            'coingeckoId': 'tdex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COCA',
            'name': 'CYBER SOCCER',
            'address': '0x44516Eb3D488175128D257748610426a866937D8',
            'decimals': 9,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': 'cyber-soccer',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DNA',
            'name': 'RxcDna',
            'address': '0x444ddc9ab6e938511fDD56f89eC334d38CAdee0f',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24892/thumb/dna-icon.png?1649285788',
            'coingeckoId': 'rxcdnatoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GST',
            'name': 'Gemstone Token',
            'address': '0x444A0E0c139Cac67e8f9be945C6Dfe01A2766ED1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x444a0e0c139cac67e8f9be945c6dfe01a2766ed1.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'TANK',
            'name': 'CryptoTanks',
            'address': '0x4444A19C8bb86E9BDbC023709A363bbCE91aF33E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21966/thumb/LN-MRxWg_400x400.jpg?1640531630',
            'coingeckoId': 'cryptotanks',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FTS',
            'name': 'Fortress Loans',
            'address': '0x4437743ac02957068995c48E08465E0EE1769fBE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15036/thumb/FTS_logo_200x200.png?1619504869',
            'coingeckoId': 'fortress',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FODL',
            'name': 'Fodl Finance',
            'address': '0x43f5b29D63ceDC5a7c1724dbB1D698FDe05Ada21',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19040/thumb/Fodl_Symbol_Gradient.png?1651644153',
            'coingeckoId': 'fodl-finance',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BABYDOGE',
            'name': 'Save Baby Doge',
            'address': '0x43f102bbd52259f2CFd0EF82e8807e3610aE3e40',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16923/thumb/BABYDOGE.png?1625635407',
            'coingeckoId': 'save-baby-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FT',
            'name': 'Fanverse Token',
            'address': '0x43d1416171d683816B067347A1727861a9CfeF9a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28157/thumb/output-fanverse.png?1668162287',
            'coingeckoId': 'fanverse-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NEKOS',
            'name': 'Nekocoin',
            'address': '0x43ce64A4FBa3c30C0164E935B0095862a67dc0c9',
            'decimals': 0,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24122/thumb/sVQIaHgA_400x400.jpg?1646461535',
            'coingeckoId': 'nekocoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RACE',
            'name': 'Race',
            'address': '0x43bdA2973141F4B0A17e9C389E2b75DBc8C9fDed',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25601/thumb/OtACwDyzHqk3vdx.png?1652775054',
            'coingeckoId': 'race',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOGEWHALE',
            'name': 'Dogewhale',
            'address': '0x43adC41cf63666EBB1938B11256f0ea3f16e6932',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22068/thumb/dogewhale_logo_3d_x200.png?1642148107',
            'coingeckoId': 'dogewhale',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SBANK',
            'name': 'SafeBank BSC',
            'address': '0x43acEDd39Ba4B0bfcCd92897fCe617Fb90a971d8',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17179/thumb/SafeBank_200x200.png?1627134559',
            'coingeckoId': 'safebank-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PMA',
            'name': 'PumaPay',
            'address': '0x43a167B15a6F24913A8B4D35488B36Ac15d39200',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/2307/thumb/pumapay_dark_rounded_256x256.png?1622176373',
            'coingeckoId': 'pumapay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PDX',
            'name': 'PokeDX',
            'address': '0x43a0C5EB1763A211Aa3c05849A617f2eE0452767',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19075/thumb/200x200_%2834%29.png?1634289460',
            'coingeckoId': 'pokedx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MSG',
            'name': 'MsgSender',
            'address': '0x43F10Fb99DBb8a80d1394Cf452F255D4814E6495',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24643/thumb/Group_298.png?1648476125',
            'coingeckoId': 'msgsender',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XPAY',
            'name': 'Wallet Pay',
            'address': '0x43B72F3B99564257671D5c47dF663585344C1459',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20410/thumb/walleyt_pay.PNG?1637013883',
            'coingeckoId': 'wallet-pay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PHM',
            'name': 'Phantom Protocol',
            'address': '0x4399AE7538c33cA24edD4C28C5dd7Ce9a80acF81',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18253/thumb/phm.PNG?1631137732',
            'coingeckoId': 'phantom-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOAL',
            'name': 'Goal',
            'address': '0x438Fc473Ba340D0734E2D05acdf5BEE775D1B0A4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20197/thumb/Goal_Token_200_x_200.png?1656397827',
            'coingeckoId': 'goal-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YOSHI',
            'name': 'Yoshi exchange',
            'address': '0x4374F26F0148a6331905eDf4cD33B89d8Eed78d1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19355/thumb/YOSHI_200.png?1641569394',
            'coingeckoId': 'yoshi-exchange',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'EQX',
            'name': 'EQIFi',
            'address': '0x436C52A8ceE41D5e9c5E6f4Cb146e66D552Fb700',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17490/thumb/EQIFI_Logo_Color.png?1627968404',
            'coingeckoId': 'eqifi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MNOP',
            'name': 'Memenopoly Money',
            'address': '0x435D4fd0bfDeb588626fE5AbF6A6eb2Ef2E26988',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17765/thumb/mnop-token-200.png?1629204266',
            'coingeckoId': 'memenopoly-money',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FOXY',
            'name': 'Foxy Equilibrium',
            'address': '0x4354a4F710182966E55EA30CFa807FA1b821a67b',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17303/thumb/cmc200x200.png?1627268759',
            'coingeckoId': 'foxy-equilibrium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IGT',
            'name': 'Starfish OS IGT',
            'address': '0x4340499A4BD2869630393B7E4709E4f4581A9793',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26924/thumb/output-onlinepngtools_%281%29.png?1660830764',
            'coingeckoId': 'starfish-os-igt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CNW',
            'name': 'CoinWealth',
            'address': '0x433FCe7dfbEc729A79999EAf056Cb073B2153ebA',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23769/thumb/cw_logo-4955f59a5c8079f246fa07ac71b2541870ca7d906ca1d9c26d74a3870fafef2f_%281%29.png?1645425522',
            'coingeckoId': 'coinwealth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LTC',
            'name': 'Binance Peg Litecoin',
            'address': '0x4338665CBB7B2485A8855A139b75D5e34AB0DB94',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x4338665cbb7b2485a8855a139b75d5e34ab0db94.png',
            'coingeckoId': 'binance-peg-litecoin',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FIRSTHARE',
            'name': 'FirstHare',
            'address': '0x432B4F994760Ea0C5f48BaAb6217e82A2B7F2c55',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22788/thumb/17453.png?1642580538',
            'coingeckoId': 'firsthare',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XCAD',
            'name': 'XCAD Network',
            'address': '0x431e0cD023a32532BF3969CddFc002c00E98429d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x431e0cd023a32532bf3969cddfc002c00e98429d.png',
            'coingeckoId': 'xcad-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FLOKIFM',
            'name': 'FLOKIFOMO',
            'address': '0x4317708Ad04D36AFDB0E7A5d62154D896B01b392',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0x4317708ad04d36afdb0e7a5d62154d896b01b392/9d61f39dc44ce49bc04b1e820231fdee.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'COB',
            'name': 'Cards of BSC',
            'address': '0x4312b655c6de6eA86b8159D16FcAdF5B937a6721',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26243/thumb/Cards_of_BSC_Logo.2def57c0_%281%29.png?1656918859',
            'coingeckoId': 'cards-of-bsc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KENSHI',
            'name': 'Kenshi',
            'address': '0x42f9c5a27a2647a64f7D3d58d8f896C60a727b0f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21904/thumb/32x32_%281%29.png?1654588618',
            'coingeckoId': 'kenshi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WALBT',
            'name': 'Wrapped AllianceBlock Token',
            'address': '0x42f3008F6945f052C31e7680F7f78c512099b904',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x42f3008f6945f052c31e7680f7f78c512099b904/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'WAR',
            'name': 'Warrior',
            'address': '0x42dbD44a8883D4363B395F77547812DC7dB806d5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14174/thumb/WAR_Token_.png?1615261213',
            'coingeckoId': 'warrior-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZFARM',
            'name': 'ZFarm',
            'address': '0x42d1B21EaBE04D308148eA9Ab90Be674b64b4eef',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16159/thumb/Fs7z-EZk.png?1623141218',
            'coingeckoId': 'zfarm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'S2K',
            'name': 'Sports 2K75',
            'address': '0x42bfa18f3f7D82BD7240d8Ce5935d51679C5115d',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25810/thumb/s2k.png?1660293265',
            'coingeckoId': 'sports-2k75',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'NAMI',
            'name': 'Nami Corporation',
            'address': '0x42Fa9f0a91Cd338f5Ad277Aa0BebD5f2cCd50643',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13729/thumb/nami_logo_256.png?1611224464',
            'coingeckoId': 'nami-corporation-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NRV',
            'name': 'Nerve Finance',
            'address': '0x42F6f551ae042cBe50C739158b4f0CAC0Edb9096',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x42f6f551ae042cbe50c739158b4f0cac0edb9096.png',
            'coingeckoId': 'nerve-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WNDG95',
            'name': 'Windoge95',
            'address': '0x42E70587ee2A272d215F67e3B0eDCbf35E232c94',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19965/thumb/windoge95.png?1636469428',
            'coingeckoId': 'windoge95',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WINU',
            'name': 'Walter Inu',
            'address': '0x42C1613dD2236CFd60B5cbEc846b5EAD71be99Df',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23429/thumb/Walter-Bigger-Circle-Logo.jpg?1654070957',
            'coingeckoId': 'walter-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ULTI',
            'name': 'Ulti Arena',
            'address': '0x42BFE4A3E023f2C90aEBFfbd9B667599Fa38514F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18419/thumb/84153495.png?1631854947',
            'coingeckoId': 'ulti-arena',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLI',
            'name': 'Bali Token',
            'address': '0x42BE29132756ddd6e8B3B94584cA0bAb20545EEc',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21989/thumb/logo.jpg?1640582239',
            'coingeckoId': 'bali-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SFM',
            'name': 'SafeMoon',
            'address': '0x42981d0bfbAf196529376EE702F2a9Eb9092fcB5',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x42981d0bfbaf196529376ee702f2a9eb9092fcb5.png',
            'coingeckoId': 'safemoon-2',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'AM',
            'name': 'AlternateMoney',
            'address': '0x4292275aff96566f4e10FC1EE814255087aEC8cF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20743/thumb/am200-200.png?1637633218',
            'coingeckoId': 'alternatemoney',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '$Pulsar',
            'name': 'PulsarToken',
            'address': '0x427FEbb891BF5144B36125C6704168324dC9c6DF',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x427febb891bf5144b36125c6704168324dc9c6df/logo.png',
            'coingeckoId': 'pulsar-token',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DFT',
            'name': 'DFuture',
            'address': '0x42712dF5009c20fee340B245b510c0395896cF6e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x42712dF5009c20fee340B245b510c0395896cF6e/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FINA',
            'name': 'Defina Finance',
            'address': '0x426c72701833fdDBdFc06c944737C6031645c708',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x426c72701833fdDBdFc06c944737C6031645c708.png',
            'coingeckoId': 'defina-finance',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'SPICE',
            'name': 'Spice',
            'address': '0x42586Ef4495BB512A86cf7496f6eF85ae7D69a64',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0x42586ef4495bb512a86cf7496f6ef85ae7d69a64/e7d4e56dc3306e9d0f56566c5390ca90.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PSWAP',
            'name': 'PorkSwap',
            'address': '0x42539F50c5F8a0c929E7895EB265391F58b22a19',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15014/thumb/bbPTbZZY_400x400.jpg?1619428037',
            'coingeckoId': 'porkswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TARO',
            'name': 'Taroverse',
            'address': '0x424aa711301c82252ecCaccF01301aD7Ad7b5D40',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25276/thumb/Taroverse.png?1651124099',
            'coingeckoId': 'taroverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PTE',
            'name': 'Peet DeFi',
            'address': '0x424B50dcB78f459d11A95bedDd13788296281987',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13100/thumb/peetdefi_logo.png?1605148557',
            'coingeckoId': 'peet-defi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STC',
            'name': 'Step C',
            'address': '0x4236D8c6CC30Ecf6f62D972c3B0074C7FDA05f18',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25683/thumb/resize-logo-stepc-200-200-removebg-preview.png?1653372744',
            'coingeckoId': 'step-c',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COW',
            'name': 'CoinWind',
            'address': '0x422E3aF98bC1dE5a1838BE31A56f75DB4Ad43730',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0x422e3af98bc1de5a1838be31a56f75db4ad43730.png',
            'coingeckoId': 'coinwind',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'DOGE',
            'name': 'Dogecoin',
            'address': '0x4206931337dc273a630d328dA6441786BfaD668f',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x4206931337dc273a630d328da6441786bfad668f/logo.png',
            'coingeckoId': 'dogecoin',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'GAMINGSHIBA',
            'name': 'GamingShiba',
            'address': '0x41cf3E9534156405a133Cda545aF9fF0E586500A',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21165/thumb/969696.png?1638428373',
            'coingeckoId': 'gamingshiba',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STKK',
            'name': 'Streakk',
            'address': '0x41Fe2441c9eeaB2158e29185D128EBAB82aa8198',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26815/thumb/streakk.png?1660374950',
            'coingeckoId': 'streakk',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLINU',
            'name': 'Baby Lambo Inu',
            'address': '0x41F831c60c7051CffA756ab5F9fEE81a670ECde0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23252/thumb/BabyLamboInuLogo.png?1643359369',
            'coingeckoId': 'baby-lambo-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LEXI',
            'name': 'LEXIT',
            'address': '0x41A95f152e50FfFd7184d9388956a2D0B8756BA9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15699/thumb/LEXi_Coin_Logo.png?1621572477',
            'coingeckoId': 'lexit-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MMS',
            'name': 'Minimals',
            'address': '0x4198B601f9F9b725Ce522a19B702Aa849128463C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17978/thumb/8-CF6-EDB1-181-B-4103-8-A60-3-AD6-F73-EA39-C.jpg?1629984192',
            'coingeckoId': 'minimals',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ACS',
            'name': 'ACryptoS',
            'address': '0x4197C6EF3879a08cD51e5560da5064B773aa1d29',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x4197c6ef3879a08cd51e5560da5064b773aa1d29.png',
            'coingeckoId': 'acryptos',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'GBOX',
            'name': 'GBOX',
            'address': '0x419317E3e1Ee4703D20f9a5344E81D35eA1A09bE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22672/thumb/Gbox-face.jpg?1642405194',
            'coingeckoId': 'gbox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DGA',
            'name': 'Doge Gamer',
            'address': '0x41882daC959c83e9f66B778E747dc164603eAA05',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20418/thumb/TsYnMvAF_400x400.jpg?1637017649',
            'coingeckoId': 'dogegamer',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OGS',
            'name': 'Ouro Governance Share',
            'address': '0x416947e6Fc78F158fd9B775fA846B72d768879c2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21369/thumb/ogs.png?1639025935',
            'coingeckoId': 'ouro-governance-share',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLR',
            'name': 'Blur Finance',
            'address': '0x4165084A6e5388ce53c9D9892f904a2712Dd943A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26382/thumb/logo200.png?1657678445',
            'coingeckoId': 'blur-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEGEN',
            'name': 'Degen Finance',
            'address': '0x4164a997b0b9A3de5E8ED35e59B015F9E892B7a9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'RA',
            'name': 'Rabona',
            'address': '0x413fc0343c72D2D4BD676b91CB6e8929aC4e72C0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27933/thumb/IMG-20221016-WA0017.png?1666508418',
            'coingeckoId': 'rabona',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SON',
            'name': 'SONofSHIB',
            'address': '0x413EcA30181F7eD78De38da95F44fC53F664157A',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20140/thumb/son.png?1636532300',
            'coingeckoId': 'sonofshib',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FMT',
            'name': 'FitR Metaverse Token',
            'address': '0x4133ae786381c028C3D7AA738a07368D96EaB9dc',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27960/thumb/FMT.png?1666751173',
            'coingeckoId': 'fitr-metaverse-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BMXX',
            'name': 'Multiplier  BSC ',
            'address': '0x4131b87F74415190425ccD873048C708F8005823',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x4131b87F74415190425ccD873048C708F8005823/logo.png',
            'coingeckoId': 'multiplier-bsc',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'TDF',
            'name': 'Trade Fighter',
            'address': '0x41242f41e998A294F4c2C01a953279C2c6fD8F5B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19276/thumb/Screenshot-2021-10-21-at-20-29-34.png?1634869651',
            'coingeckoId': 'trade-fighter',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOWA',
            'name': 'Moniwar',
            'address': '0x411Ec510c85C9e56271bF4E10364Ffa909E685D9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x411ec510c85c9e56271bf4e10364ffa909e685d9.png',
            'coingeckoId': 'moniwar',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BTCMT',
            'name': 'Minto',
            'address': '0x410a56541bD912F9B60943fcB344f1E3D6F09567',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x410a56541bd912f9b60943fcb344f1e3d6f09567.png',
            'coingeckoId': 'minto',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SWASH',
            'name': 'Swash',
            'address': '0x41065E3428188bA6Eb27FBDDE8526aE3Af8E3830',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18774/thumb/swash.png?1634089759',
            'coingeckoId': 'swash',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'SHA',
            'name': 'Safe Haven',
            'address': '0x40fEd5691e547885cABd7A2990De719DCc8497FC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/2584/thumb/safehaven.png?1620037471',
            'coingeckoId': 'safe-haven',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TIP',
            'name': 'SugarBounce',
            'address': '0x40f906e19B14100D5247686E08053c4873c66192',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21487/thumb/sugarbounce.PNG?1639363106',
            'coingeckoId': 'sugarbounce',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PURR',
            'name': 'Spartacats',
            'address': '0x40f48d91b4e5Ae546C1E4c59822147A18536BFad',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26192/thumb/20771.png?1656400022',
            'coingeckoId': 'spartacats',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EVERSAFU',
            'name': 'EverSAFU',
            'address': '0x40eD092304dBae1bcf1858EB24e1B14141126AcB',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24494/thumb/MtFSM75.png?1647867933',
            'coingeckoId': 'eversafu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAS',
            'name': 'DracooMaster',
            'address': '0x40FfAFcd7415ed2F7A902312407181140Ad14E68',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25509/thumb/19587.png?1652162023',
            'coingeckoId': 'dracoomaster',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTP',
            'name': 'Bitpaid',
            'address': '0x40F75eD09c7Bc89Bf596cE0fF6FB2ff8D02aC019',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24867/thumb/32F4D530-6587-4498-B9E3-CC99B6A73357.png?1649166657',
            'coingeckoId': 'bitpaid-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLXM',
            'name': 'bloXmove',
            'address': '0x40E51e0eC04283e300F12f6bB98DA157Bb22036E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19310/thumb/blxm_200x200.png?1635238192',
            'coingeckoId': 'bloxmove-erc20',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSCDEFI',
            'name': 'BSC Ecosystem Defi blue chips',
            'address': '0x40E46dE174dfB776BB89E04dF1C47d8a66855EB3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x40E46dE174dfB776BB89E04dF1C47d8a66855EB3.png',
            'coingeckoId': null,
            'listedIn': [
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'AOG',
            'name': 'AgeOfGods',
            'address': '0x40C8225329Bd3e28A043B029E0D07a5344d2C27C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x40C8225329Bd3e28A043B029E0D07a5344d2C27C.png',
            'coingeckoId': 'ageofgods',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'CFC',
            'name': 'Crypto Fantasy Coin',
            'address': '0x40A9CBCA594e59D5700C324baB76b693F95B7D59',
            'decimals': 1,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21775/thumb/monster-06.png?1640004993',
            'coingeckoId': 'crypto-fantasy-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PAWTH',
            'name': 'Pawthereum',
            'address': '0x409e215738E31d8aB252016369c2dd9c2008Fee0',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19275/thumb/pawth.png?1635127429',
            'coingeckoId': 'pawthereum',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'DaoX',
            'name': 'DaoX',
            'address': '0x409F1B7dd13D0DeC4FAF91DB72325BA07e46CAa1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TYPH',
            'name': 'Typhoon Network',
            'address': '0x4090e535F2e251F5F88518998B18b54d26B3b07c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x4090e535f2e251f5f88518998b18b54d26b3b07c.png',
            'coingeckoId': 'typhoon-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': '1ETH',
            'name': '1ETH',
            'address': '0x408e95E474Dd37E0ef659e389C84abAEa18c3676',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27912/thumb/unnamed.jpg?1666343264',
            'coingeckoId': '1eth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CPTL',
            'name': 'Capitol',
            'address': '0x407Ee64dA5bC8107Ea8e9cf132b33092374E5894',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20391/thumb/XbepaiH__400x400.jpg?1636962132',
            'coingeckoId': 'capitol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MONDAY',
            'name': 'MondayClub',
            'address': '0x407836435A30C7FA62DB326D4f2D87bC1a87cc1A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24767/thumb/token.png?1648817450',
            'coingeckoId': 'mondayclub',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INCO',
            'name': 'DRIVEZ Earn',
            'address': '0x406D80D4E1fE1B3e68bbBd3E02c7b2de658eb451',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27063/thumb/_INCO_200x200.png?1661670797',
            'coingeckoId': 'drivez-earn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPLY',
            'name': 'ShiPlay',
            'address': '0x40671E899e915d39036935F7cFFe35a5348E0a5c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23276/thumb/sply.png?1643527426',
            'coingeckoId': 'shiplay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '1DOGE',
            'name': '1Doge',
            'address': '0x40619dc9F00ea34e51D96b6EC5d8a6aD75457434',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17768/thumb/1DogeTransparent_png_%281%29.png?1629205738',
            'coingeckoId': '1doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VALK',
            'name': 'Valkyrio',
            'address': '0x405cFf4cE041d3235E8b1f7AaA4E458998A47363',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18781/thumb/615ad21779268a6dd2ecc250_Square_logo.png?1633411575',
            'coingeckoId': 'valkyrio-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METR',
            'name': 'Metria Network',
            'address': '0x405CE8B2eAeeA7D4Ba5Fc160848cB2A6569e03f0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24870/thumb/Metria-logo_1.png?1649213114',
            'coingeckoId': 'metria',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JTS',
            'name': 'Jetset',
            'address': '0x405BE90996e7F995A08C2FBD8d8822EF5b03466C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25295/thumb/lAsTdP6-_400x400.jpg?1651147535',
            'coingeckoId': 'jetset',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWHAL',
            'name': 'SafeWhale',
            'address': '0x4048A31e0A4fA577D343290835b34eBB4e3dBB1b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21012/thumb/photo-2021-11-28-22-36-58.jpg?1638190454',
            'coingeckoId': 'safewhale',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RON',
            'name': 'Rise Of Nebula',
            'address': '0x40300e0045239Fe76Cd07251313f923B29df1e55',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17128/thumb/ron.PNG?1626409615',
            'coingeckoId': 'rise-of-nebula',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CLEG',
            'name': 'Chain of Legends',
            'address': '0x4027d91eCD3140e53AE743d657549adfeEbB27AB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26440/thumb/logo1.png?1658108765',
            'coingeckoId': 'chain-of-legends',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUY',
            'name': 'Buying com',
            'address': '0x40225C6277b29Bf9056B4ACB7Ee1512CbFF11671',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/5290/thumb/buying_200x200.png?1628587856',
            'coingeckoId': 'buying',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SO',
            'name': 'Shiny Ore',
            'address': '0x4011EE1EfD5Cb4ef4A6910B884000b645E703d25',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25960/thumb/SO.png?1654860983',
            'coingeckoId': 'shiny-ore',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZUF',
            'name': 'ZuFinance',
            'address': '0x400A581C2056463Dc573BBbB4b247c6c0769fbA6',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20548/thumb/download-2.png?1637205571',
            'coingeckoId': 'zufinance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UPDOG',
            'name': 'UpDog',
            'address': '0x400613f184D1207f5C07a67D67040A4e23E92feB',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15369/thumb/wKA2xZr.png?1620665433',
            'coingeckoId': 'updog',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OXB',
            'name': 'Oxbull Tech  OLD ',
            'address': '0x3fd5B5746315E3F8d43A46b09c826a001EBb977d',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14111/thumb/oxb_old.png?1665980065',
            'coingeckoId': 'oxbull-tech',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WUSD',
            'name': 'WUSD',
            'address': '0x3fF997eAeA488A082fb7Efc8e6B9951990D0c3aB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x3fF997eAeA488A082fb7Efc8e6B9951990D0c3aB/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'NOWAR',
            'name': 'Nowar',
            'address': '0x3f8b23753807B83312545b1f6Ff265f13D7Be970',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26463/thumb/12.jpg?1658192538',
            'coingeckoId': 'nowar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GAMER',
            'name': 'GameStation',
            'address': '0x3f6b3595ecF70735D3f48D69b09C4E4506DB3F47',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19584/thumb/game_station.PNG?1635471603',
            'coingeckoId': 'gamestation',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUFFY',
            'name': 'Luffy',
            'address': '0x3f6B2D68980Db7371D3D0470117393c9262621ea',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17736/thumb/LUFFY_LOGO.png?1668675291',
            'coingeckoId': 'luffy-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BISWAP',
            'name': 'Biswap Token',
            'address': '0x3f3608a1896c0D29c95dF5B3F2bB549b755A5674',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17487/thumb/biswap.PNG?1627960291',
            'coingeckoId': 'biswap-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DENTRO',
            'name': 'Dentrocoin',
            'address': '0x3f30b6169B2178fA205A7a77E8823D2B359E7224',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24956/thumb/dentro.png?1649509204',
            'coingeckoId': 'dentrocoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BGC',
            'name': 'Bee Token',
            'address': '0x3eaC3819403fF5aeC83Dc87c93E3Ec3951183799',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25941/thumb/logoBeeco.png?1654765502',
            'coingeckoId': 'beeco',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BEX',
            'name': 'BelecX Protocol',
            'address': '0x3eE08275b513f3085231Ccc85de4C386FCc1f18b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25470/thumb/bex.png?1651916924',
            'coingeckoId': 'belecx-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SEAH',
            'name': 'SeahorseChain',
            'address': '0x3eCc5A95AADD617a8f6ACc5fFB94094122024E35',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27705/thumb/512.png?1665308200',
            'coingeckoId': 'seahorsechain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QUBE',
            'name': 'Qube',
            'address': '0x3e9d6430144485873248251fCB92bD856E95D1CD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21599/thumb/hYjrsaHU_400x400.png?1639547873',
            'coingeckoId': 'qube-2',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'GEMDAO',
            'name': 'Gemdao',
            'address': '0x3e990DE85Dbd92c9F616A1a4AbeAAE6243Be374b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': 'gemdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LIC',
            'name': 'Lightening Cash',
            'address': '0x3e900195B93423b8E7bdDD63d1d51c02fD18CCdf',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14560/thumb/Lightening-cash-logo-circle-200x200.png?1617013314',
            'coingeckoId': 'lightening-cash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MULTI',
            'name': 'Multigame',
            'address': '0x3e6C9eE69B90B53628051F8D6CB5b6b2EaCCB438',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18063/thumb/multigame.PNG?1630372698',
            'coingeckoId': 'multigame',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PLOT',
            'name': 'Earncraft',
            'address': '0x3e63e9C8F2297E3C027f8444b4591E2583d8780B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24495/thumb/3MgvCVi.jpeg?1647868123',
            'coingeckoId': 'earncraft',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BABYAETH',
            'name': 'Baby Aetherius',
            'address': '0x3e60050ba280fbda68d57dACE6B4Ec15d52683d8',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23080/thumb/baby-aetherious-icon-1.png?1643178326',
            'coingeckoId': 'baby-aetherius',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'X99',
            'name': 'x99',
            'address': '0x3e520f16aC21C271dB7117e8D6574b81FdC9F66C',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20570/thumb/V5so3FrH_400x400.jpg?1637227372',
            'coingeckoId': 'x99token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CCS',
            'name': 'CloutContracts',
            'address': '0x3e3B357061103DC040759aC7DceEaba9901043aD',
            'decimals': 0,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19072/thumb/200.png?1634829111',
            'coingeckoId': 'cloutcontracts',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DELOT',
            'name': 'DELOT IO',
            'address': '0x3e24BBb2c9a0f2fAEcFDBDCa20BBa6F35b73C4CB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25561/thumb/Logo_200.png?1654230685',
            'coingeckoId': 'delot-io',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BMI',
            'name': 'Bridge Mutual',
            'address': '0x3e1b4Ff4AE3Ab8f0Cb40a34a6ad3fC817F7dA2b6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://get.celer.app/cbridge-icons/BMI.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BETS',
            'name': 'BetSwirl',
            'address': '0x3e0a7C7dB7bB21bDA290A80c9811DE6d47781671',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26618/thumb/icon_200.png?1659073275',
            'coingeckoId': 'betswirl',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'CATGE',
            'name': 'Catge Coin',
            'address': '0x3e07a8a8f260edeeca24139B6767A073918E8674',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16045/thumb/Pics-Art-05-16-06-08-53.png?1622687404',
            'coingeckoId': 'catge-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BELLY',
            'name': 'Crypto Piece',
            'address': '0x3dfa90540cCDC77d543E6e61cacD5880F5C62391',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21754/thumb/NO68UIhV_400x400.jpg?1639986118',
            'coingeckoId': 'crypto-piece',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RICH',
            'name': 'Richie 2 0',
            'address': '0x3df3B11d3fE27242d4A74F7340Baa17f73912c52',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15379/thumb/Richie_2.0_Logo_32_x_32.png?1651211548',
            'coingeckoId': 'richie',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAGF',
            'name': 'Magic Forest',
            'address': '0x3deA912D5c04A1988aD6b003d224B9b616F723a1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21298/thumb/TxU58lA.png?1638880331',
            'coingeckoId': 'magic-forest',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CST',
            'name': 'CryptoStake',
            'address': '0x3dd16134F2a87Bd0D339976f0090C2ee312a6654',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24322/thumb/cs_logo-150.png?1647356813',
            'coingeckoId': 'crypto-stake-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORION',
            'name': 'Orion Money',
            'address': '0x3dcB18569425930954feb191122e574b87F66abd',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18630/thumb/YtrqPIWc.png?1632710781',
            'coingeckoId': 'orion-money',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BKS',
            'name': 'Baby KShark',
            'address': '0x3da69D719AD12EEAb2b7031697E84c2c62299C13',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17775/thumb/TKH-0Ju5_400x400.jpg?1629211276',
            'coingeckoId': 'baby-kshark',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FUR',
            'name': 'Pagan Gods Fur',
            'address': '0x3d81473920De7F4eEf8ec71E0121BAEA7Fc5b6CA',
            'decimals': 10,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19351/thumb/photo_2021-08-18_12-05-50.jpg?1635127742',
            'coingeckoId': 'pagan-gods-fur-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TNC',
            'name': 'The Next World Coin',
            'address': '0x3d6C437A6cB176bF608186ad570B3256d7096c31',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27949/thumb/Untitled_design_%2844%29.png?1666665835',
            'coingeckoId': 'the-next-world-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETC',
            'name': 'Ethereum Classic',
            'address': '0x3d6545b08693daE087E957cb1180ee38B9e3c25E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x3d6545b08693dae087e957cb1180ee38b9e3c25e.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'UST',
            'name': 'TerraUSD  Wormhole ',
            'address': '0x3d4350cD54aeF9f9b2C29435e0fa809957B3F30a',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21150/thumb/UST_wh_small.png?1644223065',
            'coingeckoId': 'terrausd-wormhole',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOUD',
            'name': 'Loud Market',
            'address': '0x3d0E22387DdfE75D1AEa9D7108a4392922740B96',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18528/thumb/Loud-Market-200x200.png?1632280075',
            'coingeckoId': 'loud-market',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STREAMN',
            'name': 'Stream2Earn',
            'address': '0x3d051A2eFbb7441e54C55285D983E86093780526',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27043/thumb/logo.png?1661503022',
            'coingeckoId': 'stream2earn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BNBD',
            'name': 'BNB Diamond',
            'address': '0x3c730718C97A77562866B5D29B33228c019eAC68',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15354/thumb/J6gLEc0.png?1620631478',
            'coingeckoId': 'bnb-diamond',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAITO',
            'name': 'Saito',
            'address': '0x3c6DAd0475d3a1696B359dc04C99FD401BE134dA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x3c6dad0475d3a1696b359dc04c99fd401be134da.png',
            'coingeckoId': 'saito',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'NSUR',
            'name': 'NSUR Coin',
            'address': '0x3c5fc9D51e99C26a6dB1304F6C9Dd10a85805cE5',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21690/thumb/nsurcoin.png?1639720906',
            'coingeckoId': 'nsur-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DNXC',
            'name': 'DinoX',
            'address': '0x3c1748D647E6A56B37B66fcD2B5626D0461D3aA0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17321/thumb/asset_icon_dnxc_200.png?1627292452',
            'coingeckoId': 'dinox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHREW',
            'name': 'Shrew',
            'address': '0x3c168c8E0bb11B0A6DEEba6c0c6Be100E82451BB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x3c168c8e0bb11b0a6deeba6c0c6be100e82451bb/logo.png',
            'coingeckoId': 'shrew',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'JNTR/b',
            'name': 'JNTR/b',
            'address': '0x3c037C4c2296f280bB318D725D0b454B76c199b9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12830/small/jntr_logo.jpg?1602837322',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'MINIBTC',
            'name': 'MiniBTC',
            'address': '0x3bC72EFf96752C2f66c7dE855f97C23eC4A1aC01',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21391/thumb/minibtc.png?1639037401',
            'coingeckoId': 'minibtc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TCAKE',
            'name': 'PancakeTools',
            'address': '0x3b831d36ed418e893F42d46ff308C326C239429f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14620/thumb/sygnet-e1616750584571.png?1617268153',
            'coingeckoId': 'pancaketools',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PIN',
            'name': 'Public Index Network',
            'address': '0x3b79a28264fC52c7b4CEA90558AA0B162f7Faf57',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/241/thumb/pin.jpeg?1623054253',
            'coingeckoId': 'public-index-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADAO',
            'name': 'ADADao',
            'address': '0x3b76374Cc2DfE28Cc373DcA6d5024791B2586335',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24372/thumb/dkw8F6mr_400x400.jpg?1647443499',
            'coingeckoId': 'adadao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPO',
            'name': 'Space Ore',
            'address': '0x3b56a620d8a4f68049964CfFe674Da9174193bC2',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24244/thumb/spo.png?1647092098',
            'coingeckoId': 'space-ore',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TCAT',
            'name': 'TomCat',
            'address': '0x3b368B649994DC33E08315E400F2ECd23723974f',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27632/thumb/rsz_ewewewrwr.png?1664959652',
            'coingeckoId': 'tomcat',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FBURN',
            'name': 'Forever Burn',
            'address': '0x3b2CB8B2A9BaF200142456C550A328E6C45c176B',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28156/thumb/Forever_Burn.jpeg?1668161617',
            'coingeckoId': 'forever-burn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EMP',
            'name': 'Emp Money',
            'address': '0x3b248CEfA87F836a4e6f6d6c9b42991b88Dc1d58',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22938/thumb/lDuYojVA_400x400.png?1643007907',
            'coingeckoId': 'emp-money',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MBIRD',
            'name': 'Moonbird',
            'address': '0x3b23a0FFbc53198d86fa5927E8ee32F7EF699A14',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18978/thumb/logo_-_2021-10-13T113335.825.png?1634096024',
            'coingeckoId': 'moonbird',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TIME',
            'name': 'chrono tech',
            'address': '0x3b198e26E473b8faB2085b37978e36c9DE5D7f68',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/604/thumb/time-32x32.png?1627130666',
            'coingeckoId': 'chronobank',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'BLT',
            'name': 'Bitlorrent',
            'address': '0x3b1394c1542A797e98B4ff4E049E434d820D8299',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15950/thumb/logos.png?1622513993',
            'coingeckoId': 'bitlorrent',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SON',
            'name': 'Souni',
            'address': '0x3b0E967cE7712EC68131A809dB4f78ce9490e779',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24297/thumb/WcOQ_Ees_400x400.jpg?1647267814',
            'coingeckoId': 'souni-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WHEAT',
            'name': 'Wheat  BSC ',
            'address': '0x3ab63309F85df5D4c3351ff8EACb87980E05Da4E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14778/thumb/wheat_logo.png?1637737901',
            'coingeckoId': 'wheat-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTW',
            'name': 'Meta Space 2045',
            'address': '0x3aFE2901489f0Cd7503a2815A655829F00305270',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28168/thumb/KakaoTalk_20221111_171601048.png?1668239034',
            'coingeckoId': 'meta-space-2045',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFEMARS',
            'name': 'Safemars',
            'address': '0x3aD9594151886Ce8538C1ff615EFa2385a8C3A88',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x3ad9594151886ce8538c1ff615efa2385a8c3a88.png',
            'coingeckoId': 'safemars',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'INCOME',
            'name': 'Income Island',
            'address': '0x3a91A88Ef4bB813BB99EF5a745bcb56e3A3b403b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21125/thumb/income_island.PNG?1638342832',
            'coingeckoId': 'income-island',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '2CRZ',
            'name': '2crazyNFT',
            'address': '0x3a6e8B36645D6c252714dadDD28eC0673535a109',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16797/thumb/2crz.png?1635525909',
            'coingeckoId': '2crazynft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PEN',
            'name': 'Pecora Network',
            'address': '0x3a6b593fb353FD5a3BB68Ea120f3bA4f78637C30',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26484/thumb/QHDvwsu.jpg?1658280618',
            'coingeckoId': 'pecora-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VDORA',
            'name': 'VeldoraBSC',
            'address': '0x3a5FcCBdcc2684be588575f063acbA78e09AAD4a',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22737/thumb/CMC-VDORA-LOGO-200x200.png?1642491365',
            'coingeckoId': 'veldorabsc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FDT',
            'name': 'Frutti Dino',
            'address': '0x3a599e584075065eAAAc768D75EaEf85c2f2fF64',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27626/thumb/FDT_color_symbol_200.png?1668587251',
            'coingeckoId': 'frutti-dino',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ULE',
            'name': 'YouLiveEveryday',
            'address': '0x3a549866a592C81719F3b714a356A8879E20F5d0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26951/thumb/20220819_225616_0000.png?1660987632',
            'coingeckoId': 'youliveeveryday',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POLAR',
            'name': 'Polaris',
            'address': '0x3a5325F0E5Ee4da06a285E988f052D4e45Aa64b4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x1c545e9943cfd1b41e60a7917465911fa00fc28c.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BCAT',
            'name': 'BeerusCat',
            'address': '0x3a310e6EAf960F9EA208764e9D299ea62A0ea53d',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27162/thumb/logo_%281%29.png?1662282308',
            'coingeckoId': 'beeruscat',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ECY',
            'name': 'Ecoway',
            'address': '0x3a14785035CF80aD95a969Bfc750584a3C16A6E6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26741/thumb/logo200x200.PNG?1660004454',
            'coingeckoId': 'ecoway',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DLTA',
            'name': 'delta theta',
            'address': '0x3a06212763CAF64bf101DaA4b0cEbb0cD393fA1a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15697/thumb/logo-DT-square.png?1621570627',
            'coingeckoId': 'delta-theta',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DMN',
            'name': 'Domain Coin',
            'address': '0x3Ffbe849A2666657B729a6bf19befD54D9E57c8b',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22212/thumb/IMG-20220103-031446-496.jpg?1641195507',
            'coingeckoId': 'domain-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEGO',
            'name': 'Dego.Finance',
            'address': '0x3FdA9383A84C05eC8f7630Fe10AdF1fAC13241CC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x3FdA9383A84C05eC8f7630Fe10AdF1fAC13241CC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'POTS',
            'name': 'Moonpot',
            'address': '0x3Fcca8648651E5b974DD6d3e50F61567779772A8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x3fcca8648651e5b974dd6d3e50f61567779772a8.png',
            'coingeckoId': 'moonpot',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ARC',
            'name': 'ArcadeNetwork',
            'address': '0x3Fb2Adf906030a5BebDBf9EF42AAD978151a2676',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21270/thumb/arcadenetwork.PNG?1638843348',
            'coingeckoId': 'arcadenetwork',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XCAP',
            'name': 'X Capital',
            'address': '0x3Fa9B0b2ce48666C5bE52e0325EECe35aA6221a1',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26764/thumb/200-2.png?1660037921',
            'coingeckoId': 'x-capital',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SDC',
            'name': 'Smart Donation Coin',
            'address': '0x3F9De0DE2abc8E0460c26533E031799C6CEb141D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23822/thumb/nlogo-200px.png?1645514609',
            'coingeckoId': 'smart-donation-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LSWAP',
            'name': 'LoopSwap',
            'address': '0x3F8a14f5a3Ee2F4A3Ed61cCF5EEA3c9535C090C8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24300/thumb/5D7QqYm.png?1647269009',
            'coingeckoId': 'loopswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIMATIC',
            'name': 'MAI',
            'address': '0x3F56e0c36d275367b8C502090EDF38289b3dEa0d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xa3fa99a148fa48d14ed51d610c367c61876997f1.png',
            'coingeckoId': 'mimatic',
            'listedIn': [
                'coingecko',
                'RouterProtocol',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'VRFY',
            'name': 'Verify VRFY',
            'address': '0x3F52FF3137b52fCa2f96f41656632Ee8D5e79401',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19456/thumb/vrfy.png?1637742962',
            'coingeckoId': 'verify-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FAFI',
            'name': 'Famous Five',
            'address': '0x3F2A5d9A225655dDCcaDFDA9c5f36F53A8A149a5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20248/thumb/200x200_%2837%29.png?1636700988',
            'coingeckoId': 'famous-five',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABYKITTY',
            'name': 'BabyKitty',
            'address': '0x3Efe3beE4dbEB77D260BC12AeB62072cF6e68478',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22127/thumb/2021-12-26-12-51-04-1-1.png?1640870060',
            'coingeckoId': 'babykitty',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LITE',
            'name': 'LiteUSD',
            'address': '0x3Ef3f6Cf9f867A33C4109208a6bd043A9Ef0E091',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26304/thumb/LITE%28200%29%E5%A4%87%E4%BB%BD_%282%29.png?1657180319',
            'coingeckoId': 'liteusd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DMT',
            'name': 'DMT',
            'address': '0x3Eb05a201817F87C198930B86F40C6829340b4B7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18564/thumb/DMT_200.png?1632445748',
            'coingeckoId': 'dmt-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAGA',
            'name': 'MAGA Coin BSC',
            'address': '0x3EF144cB45C8a390EB207A6aa9bFcf3DA639cb5C',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19835/thumb/maga.png?1635991979',
            'coingeckoId': 'maga-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADA',
            'name': 'Binance Peg Cardano',
            'address': '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x3ee2200efb3400fabb9aacf31297cbdd1d435d47.png',
            'coingeckoId': 'binance-peg-cardano',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CATCOIN',
            'name': 'CatCoin com',
            'address': '0x3E362283B86C1b45097CC3FB02213b79CF6211Df',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25279/thumb/logo_%281%29.png?1651126078',
            'coingeckoId': 'catcoin-cash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MANY',
            'name': 'Many Worlds',
            'address': '0x3E2ed75F718251Fd011312b9E33Bf10A4dbfda54',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21937/thumb/fiJanA94_400x400.jpg?1640310149',
            'coingeckoId': 'many-worlds',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BM',
            'name': 'BtMiracles',
            'address': '0x3De90aac2dE63981b441Ba84F48BD063C158e566',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22047/thumb/output-onlinepngtools_%2812%29.png?1640675270',
            'coingeckoId': 'btmiracles',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KBD',
            'name': 'Kyberdyne',
            'address': '0x3Dde01a467f99E58f996de835058C767A3edd2AC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24003/thumb/mrSWHBjQ_400x400.jpg?1646026119',
            'coingeckoId': 'kyberdyne',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QUAI',
            'name': 'Quai Dao',
            'address': '0x3Dc2d7434bDbB4Ca1A8A6bCC8a8075AEaE2d2179',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13815/thumb/QUIA_32x32.png?1656133041',
            'coingeckoId': 'quai-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEGO',
            'name': 'Dego Finance',
            'address': '0x3Da932456D082CBa208FEB0B096d49b202Bf89c8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x3da932456d082cba208feb0b096d49b202bf89c8.png',
            'coingeckoId': 'dego-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'TIIM',
            'name': 'TriipMiles',
            'address': '0x3DA4F9e86DEeF7C50a8b167493f26e894edcd7d5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12185/thumb/fOf8ZxL.png?1597961299',
            'coingeckoId': 'triipmiles',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AFREP',
            'name': 'Afrep',
            'address': '0x3D49d6F854620057FD4408B8daa8c0B15c48fcE0',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26407/thumb/Afrep._1_%281%29.png?1657924298',
            'coingeckoId': 'afrep',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SMASH',
            'name': 'Smash Cash',
            'address': '0x3D0e93bfCb8FB46331Ea8c98B6ab8C575aB424C3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21286/thumb/30537055.png?1638865813',
            'coingeckoId': 'smash-cash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DELTAF',
            'name': 'DeltaFlip',
            'address': '0x3D06CB9E8Fa1c7638a8B3D8d8B8755f1F6B7164B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22328/thumb/HxJa683.png?1641478302',
            'coingeckoId': 'deltaflip',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CGT',
            'name': 'Curio Governance',
            'address': '0x3D04EDC843e74935C09F54cc4b2fe1870E347AC9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13607/thumb/QLwpua7.png?1610273891',
            'coingeckoId': 'curio-governance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BNB20',
            'name': 'Proof of Degen',
            'address': '0x3D01755E06FD11a08Cd2995Abd183Cd8990256DF',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27753/thumb/7D3C8EC8-CA17-4AC6-8437-C5A1CBEAA784.png?1665654109',
            'coingeckoId': 'proof-of-degen',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ROMEODOGE',
            'name': 'Romeo Doge',
            'address': '0x3Ce9f4E83eC642e6aE25169061120d4d157256ef',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18154/thumb/200x200.png?1630741414',
            'coingeckoId': 'romeo-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SMG',
            'name': 'Samurai Legends',
            'address': '0x3Ca994D9f723736381d44388bC8dD1e7eE8C1653',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x3Ca994D9f723736381d44388bC8dD1e7eE8C1653/logo.png',
            'coingeckoId': 'samurai-legends',
            'listedIn': [
                'coingecko',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SDBY',
            'name': 'Sadbaby',
            'address': '0x3CE0f6944279a58A366cc2E89585B376306B9b4F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25692/thumb/jIwE4iKm_400x400.jpeg?1657941823',
            'coingeckoId': 'sadbaby-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WING',
            'name': 'Wing Finance',
            'address': '0x3CB7378565718c64Ab86970802140Cc48eF1f969',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0x3cb7378565718c64ab86970802140cc48ef1f969/456f8e0797e635197cd3ee1be0e3c294.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'OCP',
            'name': 'Omni Consumer Protocol',
            'address': '0x3C70260eEe0a2bFc4b375feB810325801f289fBd',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15243/thumb/ocp-200px.png?1622175368',
            'coingeckoId': 'omni-consumer-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CK',
            'name': 'CK Finance',
            'address': '0x3C5e3d85dfE23302F9439DEe89Ee4AE3D57f619C',
            'decimals': 9,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': '300',
            'name': 'Spartan',
            'address': '0x3C462E6Fe7043F076Af33640C5E24388E2ee9EbC',
            'decimals': 7,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13371/thumb/spartan300-200x200.png?1607986424',
            'coingeckoId': 'spartan',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ROOM',
            'name': 'OptionRoom',
            'address': '0x3C45A24d36Ab6fc1925533C1F57bC7e1b6fbA8a4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x3c45a24d36ab6fc1925533c1f57bc7e1b6fba8a4.png',
            'coingeckoId': 'option-room',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'EVRF',
            'name': 'EverReflect',
            'address': '0x3C2e501B08CF5C16061468c96b19b32bae451dA3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22903/thumb/evrf.png?1642935322',
            'coingeckoId': 'everreflect',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PATH',
            'name': 'PathFundV2',
            'address': '0x3C0b6FCB2F62990f477d9AF611e4Da3fF1F08222',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24795/thumb/path.png?1648979291',
            'coingeckoId': 'pathfundv2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFESTAR',
            'name': 'Safestar',
            'address': '0x3C00F8FCc8791fa78DAA4A480095Ec7D475781e2',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x3c00f8fcc8791fa78daa4a480095ec7d475781e2.png',
            'coingeckoId': 'safestar',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'IGT',
            'name': 'Ignite',
            'address': '0x3Bed11BB72F11FA734d5286537829E0C7C950d03',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25912/thumb/ignite-200.png?1654594234',
            'coingeckoId': 'ignite-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CLASS',
            'name': 'Cyberclassic',
            'address': '0x3Be661b51Ce0D375E3857cfcc1EE5ae3f7060c3B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23336/thumb/rKziC8Zt_400x400.jpg?1643882455',
            'coingeckoId': 'cyberclassic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MSHARE',
            'name': 'MSHARE',
            'address': '0x3Bd92db7B1Ce1A3A8704EE5898578c866FDf12c4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FITN',
            'name': 'FITN',
            'address': '0x3Bc1A74a483d721E7B41981DD445298024Af620C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26575/thumb/qryiWVm__400x400.jpg?1658886392',
            'coingeckoId': 'fitn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VICAT',
            'name': 'ViCat',
            'address': '0x3BFAD48181C9E88E1dD9C1B48887E33E2653dB4d',
            'decimals': 7,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26125/thumb/ViCat_Logo_200x200.PNG?1655952116',
            'coingeckoId': 'vicat',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DXW',
            'name': 'Dexscrow',
            'address': '0x3BE068Bcc82460d866EE6816aC986630964371B7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23543/thumb/logo-small.png?1644391982',
            'coingeckoId': 'dexscrow',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SD',
            'name': 'Stader',
            'address': '0x3BC5AC0dFdC871B365d159f728dd1B9A0B5481E8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20658/thumb/sd.png?1642927667',
            'coingeckoId': 'stader',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KINGDOG',
            'name': 'King Dog Inu',
            'address': '0x3BB55Ba11f1A220c7C1e15b56E6cF9c69519c50F',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23703/thumb/200.png?1645081581',
            'coingeckoId': 'king-dog-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ACED',
            'name': 'Aced  OLD ',
            'address': '0x3B98bbefe14B98000f10124ca95eD298AC9dB3Ff',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/4761/thumb/aced.png?1547040086',
            'coingeckoId': 'aced',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LZ',
            'name': 'LaunchZone',
            'address': '0x3B78458981eB7260d1f781cb8be2CaAC7027DbE2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17761/thumb/icon_%287%29.png?1629187241',
            'coingeckoId': 'launchzone',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SMOO',
            'name': 'SheeshMoon',
            'address': '0x3B44E6D655226E79B3d2AC510C11b2637a9f50Dd',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15524/thumb/sheeshmoon2cowcrop_200x200.png?1621120118',
            'coingeckoId': 'sheeshmoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RICH',
            'name': 'RichieRich Coin',
            'address': '0x3B4443F97B37A6Ef864c3f0f1632E366B4f1d20f',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15337/thumb/RICH_logo_transparent.png?1623685673',
            'coingeckoId': 'richierich-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '5TABLE',
            'name': '5table Token',
            'address': '0x3B29E11FADA25Ec959d732684E783A4069f26ceC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BUMN',
            'name': 'BUMooN',
            'address': '0x3AefF4E27E1F9144eD75Ba65a80BdfEE345F413e',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17570/thumb/bumn-logo-200.png?1628514543',
            'coingeckoId': 'bumoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KSC',
            'name': 'KingSpeed',
            'address': '0x3AC0F8CEcc1Fb0Ee6C2017A072D52E85B00c6694',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21246/thumb/attachment.png?1645412281',
            'coingeckoId': 'kingspeed',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LIBERA',
            'name': 'Libera Financial',
            'address': '0x3A806A3315E35b3F5F46111ADb6E2BAF4B14A70D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26194/thumb/20791.png?1656403503',
            'coingeckoId': 'libera-financial',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FUSII',
            'name': 'Fusible',
            'address': '0x3A50d6daacc82f17A2434184fE904fC45542A734',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x4c924a1fe185c6c6f870bc6bf1762b832208d748.png',
            'coingeckoId': 'fusible',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'YLGY',
            'name': 'SheepAsheep',
            'address': '0x3A4329758940Fd7ea94b3754158795Eb412b824a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27436/thumb/photo_2022-09-19_03-51-46.jpg?1663927410',
            'coingeckoId': 'sheepasheep',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NNT',
            'name': 'Nunu Spirits',
            'address': '0x3A2927E68749Dd6ad0A568d7c05b587863C0bC10',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24378/thumb/NNT_99cc33.png?1649120059',
            'coingeckoId': 'nunu-spirits',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MBT',
            'name': 'MetaBlackout',
            'address': '0x3A20c33691295da2f71B291449C137067a2c1355',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24002/thumb/logo_-_2022-02-28T113304.386.png?1646019233',
            'coingeckoId': 'metablackout',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FAWA',
            'name': 'Fantasy War',
            'address': '0x3A141d768298e9fDacCF9BA59B07d5FA5705F118',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25897/thumb/logo-token.png?1654496842',
            'coingeckoId': 'fantasy-war',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HORD',
            'name': 'Hord',
            'address': '0x39d4549908e7Adcee9b439429294eEb4c65c2C9e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14972/thumb/Avatar_white.png?1619513849',
            'coingeckoId': 'hord',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FROSTYFLOKI',
            'name': 'Frosty Floki V2',
            'address': '0x39d0291f0aaad6CF0b5213F7cc6DF72493692cAe',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22053/thumb/l7uyznt__400x400.jpg?1640678077',
            'coingeckoId': 'frosty-floki-v2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AGRO',
            'name': 'Agro Global',
            'address': '0x39cC67690D0F2d4aCD68d3d9B612a80D780b84c0',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21511/thumb/200x200.png?1639375342',
            'coingeckoId': 'agro-global',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LLVERSE',
            'name': 'LESLAR Metaverse',
            'address': '0x39bca4d597062378b5669CD31a3bBFECCDd36B3c',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24230/thumb/logo_leslar_1.png?1654237622',
            'coingeckoId': 'leslar-metaverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CATBOY',
            'name': 'CatBoy',
            'address': '0x39C3F75B837EAE0d6d0eA87425Ac99bD646a7B1E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24051/thumb/2.png?1646199770',
            'coingeckoId': 'catboy-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CBIX-P',
            'name': 'Cubiex Power',
            'address': '0x39BfF8613deFD221b0410eD3D4E5C07512d55F2d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16183/thumb/Cubiex.jpg?1623218662',
            'coingeckoId': 'cubiex-power',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LC',
            'name': 'Light Year',
            'address': '0x39BD64E5b9e025b0C8D91ee2CFec9125A388894B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22138/thumb/20211028133910.png?1640920358',
            'coingeckoId': 'light-year',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MEGG',
            'name': 'Metaegg DeFi',
            'address': '0x39Af062b155978f1D41B299601DeFac54E94Cbd8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26710/thumb/62b87e40c8261218d388be04.png?1659668347',
            'coingeckoId': 'metaegg-defi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KAI',
            'name': 'KardiaChain',
            'address': '0x39Ae8EEFB05138f418Bb27659c21632Dc1DDAb10',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/7942/thumb/kai.png?1647079540',
            'coingeckoId': 'kardiachain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZPTC',
            'name': 'Zeptacoin',
            'address': '0x39Ae6D231d831756079ec23589D2D37A739F2E89',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19109/thumb/2000x2000zptc_square3_%281%29.png?1639667686',
            'coingeckoId': 'zeptagram',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PTN',
            'name': 'Pluto Network',
            'address': '0x3999565294cA158d3D7cde9C74C86be928317ea0',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15703/thumb/pluto-logo-horizontal.png?1621576452',
            'coingeckoId': 'pluto-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CST',
            'name': 'CrabStrike',
            'address': '0x3993453B9587E06a0E925727Fd844D2D2babd39f',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27250/thumb/crabstrikelogocmc.png?1662963359',
            'coingeckoId': 'crabstrike',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIX',
            'name': 'MixMarvel',
            'address': '0x398f7827DcCbeFe6990478876bBF3612D93baF05',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x398f7827DcCbeFe6990478876bBF3612D93baF05.png',
            'coingeckoId': 'mixmarvel',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'DINGO',
            'name': 'Dingo',
            'address': '0x397E6d4fd68A9E25e93fC712B8F72F826f48a8ff',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16315/thumb/tzI4QPI7_400x400.png?1623733248',
            'coingeckoId': 'dingo-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TTK',
            'name': 'The Three Kingdoms',
            'address': '0x39703A67bAC0E39f9244d97f4c842D15Fbad9C1f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18948/thumb/TTK_-_Square_Icon_01.png?1634008903',
            'coingeckoId': 'the-three-kingdoms',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TYC',
            'name': 'Tianyu Finance',
            'address': '0x39647d0D700Ef077c6D90De963cF6989Ef38E341',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17516/thumb/200x200_%2826%29.png?1628067149',
            'coingeckoId': 'tianyu-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POCO',
            'name': 'Pocoland',
            'address': '0x394bBA8F309f3462b31238B3fd04b83F71A98848',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18293/thumb/poco.PNG?1631312700',
            'coingeckoId': 'pocoland',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'mAMZN',
            'name': 'Mirror AMZN Token',
            'address': '0x3947B992DC0147D2D89dF0392213781b04B25075',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x3947B992DC0147D2D89dF0392213781b04B25075/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'EBA',
            'name': 'Elpis Battle',
            'address': '0x3944aC66b9b9B40a6474022D6962b6cAA001b5E3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20642/thumb/B%E1%BA%A3n_sao_c%E1%BB%A7a_token_EBA.png?1637547442',
            'coingeckoId': 'elpis-battle',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLES',
            'name': 'Blind Boxes',
            'address': '0x393d87E44C7b1F5Ba521b351532C24ECE253B849',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0x393d87e44c7b1f5ba521b351532c24ece253b849.png',
            'coingeckoId': 'blind-boxes',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'JIMN',
            'name': 'JimnGalaxy',
            'address': '0x393EEd64C53D766dD7d9572593be0D4B5B4f2Ca4',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25915/thumb/20476.png?1654591852',
            'coingeckoId': 'jimngalaxy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FBL',
            'name': 'Football Battle',
            'address': '0x393C44A497706DDE15996BB0C7Bdf691A79De38a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26124/thumb/logo.png?1655951408',
            'coingeckoId': 'football-battle',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FRIES',
            'name': 'fry.world',
            'address': '0x393B312C01048b3ed2720bF1B090084C09e408A1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x393B312C01048b3ed2720bF1B090084C09e408A1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MONSTR',
            'name': 'Monverse',
            'address': '0x3933E1cDD51DE9b0f7d062AC8549cCdf63C219a9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26466/thumb/Safeimagekit-resized-imgpng_%283%29.png?1658193123',
            'coingeckoId': 'monverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LAMA',
            'name': 'MetaLama',
            'address': '0x3933D585d7c41ee55fe93342f8d9E27632f1d169',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22495/thumb/MetaLama_CG_submission.png?1641909260',
            'coingeckoId': 'metalama',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EULER',
            'name': 'Euler Tools',
            'address': '0x3920123482070C1a2dff73AaD695c60e7c6F6862',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14677/thumb/euler_logo.png?1617705515',
            'coingeckoId': 'euler-tools',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TOPIA',
            'name': 'Utopia',
            'address': '0x391748379827340DB2daFFC845AC6Cffad431B50',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21732/thumb/topia.png?1639968367',
            'coingeckoId': 'utopia-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PUBE',
            'name': 'Pube Finance',
            'address': '0x3916984fa787d89B648Ccd8d60B5ff07E0E8e4F4',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15425/thumb/2618620f.jpg?1622440947',
            'coingeckoId': 'pube-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPARTA',
            'name': 'Spartan Protocol',
            'address': '0x3910db0600eA925F63C36DdB1351aB6E2c6eb102',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x3910db0600ea925f63c36ddb1351ab6e2c6eb102.png',
            'coingeckoId': 'spartan-protocol-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'MNTT',
            'name': 'MoonTrust',
            'address': '0x390037d710C86F88F05174599425B77c72Eda305',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15386/thumb/moontrust_logo_200x200px.png?1620701584',
            'coingeckoId': 'moontrust',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWIRL',
            'name': 'Swirl',
            'address': '0x38fFA52C7628f5cCf871472e40c462E4483215C9',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22575/thumb/newLogo.png?1642082207',
            'coingeckoId': 'swirltoken',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'CJET',
            'name': 'CryptoJetski',
            'address': '0x38FC43bbdDB64cd23bC8d085F88722AB1F9a6C50',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22907/thumb/cjet.png?1642941799',
            'coingeckoId': 'cryptojetski',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'agEUR',
            'name': 'agEUR',
            'address': '0x38C84D5bBAD726D465Cf6A5349E41D6d7095faf7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'GAC',
            'name': 'GACUBE',
            'address': '0x38C3E4cD2bFB89a57495acadE0c49e49C3Db8dC8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22453/thumb/gacube.PNG?1641863847',
            'coingeckoId': 'gacube',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MKAT',
            'name': 'MoonKat Finance',
            'address': '0x38Bd8CD90374DBc903AeD9d2eE28f5Ab856342Ce',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16516/thumb/moonkat_transparent_png.png?1624324961',
            'coingeckoId': 'moonkat-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MELO',
            'name': 'Melo',
            'address': '0x38A62b2030068e7b7a5268df7Ab7a48Bc6e396b4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16571/thumb/melo.PNG?1624495558',
            'coingeckoId': 'melo-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VLTY',
            'name': 'Vaulty',
            'address': '0x38A5cbe2FB53d1d407Dd5A22C4362daF48EB8526',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17779/thumb/vault.PNG?1629246934',
            'coingeckoId': 'vaulty-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JM',
            'name': 'JustMoney',
            'address': '0x388D819724dD6d71760A38F00dc01D310d879771',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25450/thumb/jm.png?1651803017',
            'coingeckoId': 'justmoney-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PTA',
            'name': 'La Peseta',
            'address': '0x3843F234B35A311e195608D32283a68284B3c44D',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21645/thumb/VB_hrqI7_400x400.jpg?1639636015',
            'coingeckoId': 'la-peseta',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CKT',
            'name': 'Caketools',
            'address': '0x3837e18B901629fcB200e0aD9c2f2441804bd6c8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20620/thumb/caketools-round-200.png?1637769605',
            'coingeckoId': 'caketools',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RC2',
            'name': 'Reward Cycle 2',
            'address': '0x38330281b627240b1F5470014091F69ad5a84146',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22259/thumb/rc2-new-coins-logo-200x200-1.png?1641302260',
            'coingeckoId': 'reward-cycle-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KALLY',
            'name': 'Polkally',
            'address': '0x3831dfEf762F4f64261F2D875C710F90038578E4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'YUCJ',
            'name': 'Yu Coin  OLD ',
            'address': '0x382578D2Fc653a537f9C71C3ff290c93264a48c6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26255/thumb/yucjlogo200x200.png?1656937415',
            'coingeckoId': 'yu-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VULC',
            'name': 'Vulcano',
            'address': '0x3810a078AA274Ea6d06a480588eFf8fE517220a4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24726/thumb/YdPqyazR_400x400.jpg?1648702826',
            'coingeckoId': 'vulcano-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFEBTC',
            'name': 'SafeBitcoin',
            'address': '0x380624A4a7e69dB1cA07deEcF764025FC224D056',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x380624a4a7e69db1ca07deecf764025fc224d056.png',
            'coingeckoId': 'safebitcoin',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BBC',
            'name': 'Bull BTC Club',
            'address': '0x37e5da11b6A4DC6d2f7Abe232cDd30B0B8Fc63B1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28030/thumb/bbc.png?1667048849',
            'coingeckoId': 'bull-btc-club',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APYS',
            'name': 'APYSwap',
            'address': '0x37dfACfaeDA801437Ff648A1559d73f4C40aAcb7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x37dfACfaeDA801437Ff648A1559d73f4C40aAcb7/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FROGGO',
            'name': 'Mutant Froggo',
            'address': '0x37cE6D9660aA14EF27aFeFe060f4044565397EA3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27863/thumb/mutantfroggo-200.jpg?1666149958',
            'coingeckoId': 'mutant-froggo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUMI',
            'name': 'Luminos Mining Protocol',
            'address': '0x37c9aB94818c995569fd6f23fECCEC54bce900e0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19809/thumb/Untitled-design-removebg-preview_%281%29.png?1635916843',
            'coingeckoId': 'luminos-mining-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DIAMONDS',
            'name': 'Black Diamond',
            'address': '0x37c4bcf0b8fC6f074be933Af7fb9D1DDe55f979C',
            'decimals': 12,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16991/thumb/blackdiamond.jpeg?1625985321',
            'coingeckoId': 'black-diamond',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KRS',
            'name': 'Kingdom Raids',
            'address': '0x37b53894e7429f794B56F22a32E1695567Ee9913',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x37b53894e7429f794B56F22a32E1695567Ee9913.png',
            'coingeckoId': 'kingdom-raids',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'YUCJ',
            'name': 'Yu Coin',
            'address': '0x37a7A914432BfB236dC0EE37c4dB08aA5F06706a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26794/thumb/a6l_prN5_400x400.jpeg?1660130664',
            'coingeckoId': 'yu-coin-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VPK',
            'name': 'Vulture Peak',
            'address': '0x37Ac5F3bfd18a164Fc6cF0f0f0ECD334D9179d57',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23729/thumb/vulture_logo_final-05.png?1645169060',
            'coingeckoId': 'vulture-peak',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SFUEL',
            'name': 'SparkPoint Fuel',
            'address': '0x37Ac4D6140e54304D77437A5c11924f61a2D976f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13564/thumb/SFUEL.png?1614675479',
            'coingeckoId': 'sparkpoint-fuel',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'GLXC',
            'name': 'Galaxy Coin',
            'address': '0x37A9357B0d575d419595Cb56974fb89729E737de',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15630/thumb/logo-2_%283%29.png?1621395930',
            'coingeckoId': 'galaxy-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FREN',
            'name': 'FREN',
            'address': '0x37941b3Fdb2bD332e667D452a58Be01bcacb923e',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20259/thumb/5oR6DSf.png?1636702537',
            'coingeckoId': 'fren',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BST',
            'name': 'GEM Slots',
            'address': '0x3783Ba99A39A178DC6Dd46048Ba1d70461217c74',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26093/thumb/zDGtgqXu_400x400.jpg?1655797977',
            'coingeckoId': 'gem-slots',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIBADOLLARS',
            'name': 'Shiba Dollars',
            'address': '0x3780aB762d530614F72F94c7CF3396c488c0B57c',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22275/thumb/download.png?1641361041',
            'coingeckoId': 'shiba-dollars',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOGE2',
            'name': 'Dogecoin 2 0',
            'address': '0x3780E00D4c60887AF38345cCd44f7617dBFB10A0',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17539/thumb/k4NUHHaO_400x400.jpg?1628141758',
            'coingeckoId': 'dogecoin-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUNR',
            'name': 'Lunr',
            'address': '0x37807D4fbEB84124347B8899Dd99616090D3e304',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19256/thumb/lunr.png?1639975807',
            'coingeckoId': 'lunr-token',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ZYX',
            'name': 'ZYX',
            'address': '0x377c6E37633e390aEf9AFB4F5E0B16689351EeD4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/11964/thumb/zyx.png?1596454904',
            'coingeckoId': 'zyx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALTRUCOIN',
            'name': 'Altrucoin',
            'address': '0x377Ef66728d344BFa2BB370186AB4B57092577bD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24512/thumb/Sn_fybmb_400x400.png?1647942034',
            'coingeckoId': 'altrucoin-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DUSA',
            'name': 'Medusa',
            'address': '0x376ba3FFAc2643555731349faE9FC8Ca28E2c9A2',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14337/thumb/COINGECKO_logo_medusa.png?1615446752',
            'coingeckoId': 'medusa',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REDLUNA',
            'name': 'Redluna',
            'address': '0x376C4773C3280a8f029aeb674ACAfdce9B20d26b',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26490/thumb/redluna-logo-2.png?1658283532',
            'coingeckoId': 'redluna',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NRFX',
            'name': 'Narfex',
            'address': '0x3764Be118a1e09257851A3BD636D48DFeab5CAFE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26178/thumb/image_6483441.JPG?1656381875',
            'coingeckoId': 'narfex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XIDO',
            'name': 'Xido Finance',
            'address': '0x3764Bc0de9B6a68c67929130aaEC16b6060cAb8c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16161/thumb/KJw4clj.png?1623141959',
            'coingeckoId': 'xido-finance',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'DSOCCER',
            'name': 'Dream Soccer',
            'address': '0x3761493C189F9c5483302d823CFfE8108c21d668',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27750/thumb/DvyI43z.jpg?1665650972',
            'coingeckoId': 'dream-soccer',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YDR',
            'name': 'YDragon',
            'address': '0x3757232B55E60da4A8793183aC030CfCE4c3865d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17807/thumb/icon.png?1629302175',
            'coingeckoId': 'ydragon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ECP',
            'name': 'Eclipse',
            'address': '0x375483CfA7Fc18F6b455E005D835A8335FbdbB1f',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x375483cfa7fc18f6b455e005d835a8335fbdbb1f.png',
            'coingeckoId': 'eclipse-2',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SV',
            'name': 'Sportsverse',
            'address': '0x374827A70f08805650f2408B4aaC1B490275eF0B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27355/thumb/200_050photo_2022-08-09_19-28-56.jpg?1663661229',
            'coingeckoId': 'sportsverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DERC',
            'name': 'DeRace',
            'address': '0x373E768f79c820aA441540d254dCA6d045c6d25b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x373e768f79c820aa441540d254dca6d045c6d25b.png',
            'coingeckoId': 'derace',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'BOXC',
            'name': 'BOXCASINO',
            'address': '0x372E5F4cb6668A2C7A655a6325a483e3a73c8bA9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27940/thumb/BOXC.png?1666525001',
            'coingeckoId': 'boxcasino',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATEM',
            'name': 'ATEM',
            'address': '0x3708DEA52A5846FD16d0060AaB01c87bb5932b02',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'NGT',
            'name': 'Gold Nugget',
            'address': '0x370527c29113aaD172D1dEF6c42d0C924DF124cE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21427/thumb/goldnugget.png?1639118046',
            'coingeckoId': 'goldnugget',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MILLION',
            'name': 'Millionaire Maker',
            'address': '0x370220f460C65E87a87341F48cC18BCbdbc18433',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20910/thumb/VpuyUpT8_400x400.png?1637895376',
            'coingeckoId': 'millionaire-maker',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SME',
            'name': 'SafeMeme',
            'address': '0x36dBcBCA106353D49e1E0E8974492fFB862a0C92',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15529/thumb/safememe.png?1621134539',
            'coingeckoId': 'safememe',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PSB',
            'name': 'Planet Sandbox',
            'address': '0x36bfBb1d5B3C9b336f3D64976599B6020cA805F1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18804/thumb/PS_logo_shape_%282%29.png?1633474856',
            'coingeckoId': 'planet-sandbox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SEACHAIN',
            'name': 'SeaChain',
            'address': '0x36b24B2F78725495e858AF9e72f7Df69DaDE3dca',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18466/thumb/seachain_INLINE-logo-nosubtext-nostripes_darkBG01.png?1632114970',
            'coingeckoId': 'seachain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADR',
            'name': 'Adroverse',
            'address': '0x36F1f32c728C3F330409eC1F0928Fa3aB3C8A76F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24556/thumb/92mtj657_400x400.jpg?1648136168',
            'coingeckoId': 'adroverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GUT',
            'name': 'Genesis Universe',
            'address': '0x36E714D63B676236B72a0a4405F726337b06b6e5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27065/thumb/token_logo.jpg?1661671814',
            'coingeckoId': 'genesis-universe',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OPV',
            'name': 'OpenLive NFT',
            'address': '0x36C7B164F85D6F775cD128966D5819c7d36FEfF3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22622/thumb/opv.png?1642321243',
            'coingeckoId': 'openlive-nft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTB',
            'name': 'ETNA Metabolism',
            'address': '0x36C618F869050106e1F64d777395baF7d56A9Ead',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23529/thumb/MTBlogo200.png?1644386113',
            'coingeckoId': 'etna-metabolism',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORKL',
            'name': 'Orakler',
            'address': '0x36BC1F4D4Af21df024398150Ad39627FB2c8A847',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21936/thumb/zWNiTkm.png?1640309648',
            'coingeckoId': 'orakler',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLY',
            'name': 'FlyApy',
            'address': '0x36B4A286068E43E18d58c9a594734a0B55F9C6fd',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24527/thumb/Design-uten-navn-14.png?1648038969',
            'coingeckoId': 'flyapy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MNFT',
            'name': 'ManuFactory',
            'address': '0x36953B5Ec00A13eDcEceB3aF258D034913D2A79D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21677/thumb/logo1_%288%29.png?1639712452',
            'coingeckoId': 'manufactory-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CST',
            'name': 'CryptoSkates',
            'address': '0x368eB5efdca39126e8e76Aae5187166De7c2766c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22906/thumb/cst.png?1642940972',
            'coingeckoId': 'cryptoskates',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EPIK',
            'name': 'Epik Prime',
            'address': '0x368ce786Ea190f32439074e8d22e12ecb718B44c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17907/thumb/EPIK_Prime_LOGO.jpg?1630738458',
            'coingeckoId': 'epik-prime',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'EUX',
            'name': 'dForce EUR',
            'address': '0x367c17D19fCd0f7746764455497D63c8e8b2BbA3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0x367c17d19fcd0f7746764455497d63c8e8b2bba3/b5db640b44846812951922e2f8e24b2c.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'APAD',
            'name': 'Anypad',
            'address': '0x366d71ab095735b7Dae83ce2b82D5262ef655F10',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18385/thumb/vxBgiYKj_400x400.png?1631746435',
            'coingeckoId': 'anypad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WEC',
            'name': 'Whole Earth Coin',
            'address': '0x3623f2B63d8F50b477849d29e7C9A6625331E89D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16313/thumb/61ea0d78e70bb93dee3c03a0_whole-earth-coin_brand.png?1643189537',
            'coingeckoId': 'whole-earth-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MFM',
            'name': 'MoonFarmer',
            'address': '0x361F3D81E9B249cFaFa7a047d9225c10323F10Bd',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17283/thumb/8LwYXCz.jpeg?1627132432',
            'coingeckoId': 'moonfarmer',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DFI',
            'name': 'DeFiChain',
            'address': '0x361C60b7c2828fCAb80988d00D1D542c83387b50',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/11757/thumb/symbol-defi-blockchain_200.png?1597306538',
            'coingeckoId': 'defichain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PLAC',
            'name': 'PlanetApeClub',
            'address': '0x360857806FA520A5fFB74a3CA6E04253e48e7d16',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': 'planetapeclub',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATOZ',
            'name': 'Race Kingdom',
            'address': '0x3606F220daeaEb3d47aC1923A8Ce2A61205C88cD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26472/thumb/Race_Kingdom_Logo.png?1658195315',
            'coingeckoId': 'race-kingdom',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PIRATE',
            'name': 'PirateCash',
            'address': '0x35f3fffFcb622bC9f64fA561D74e983Fd488D90c',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/7155/thumb/logo_%281%29.png?1637683371',
            'coingeckoId': 'piratecash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MDO',
            'name': 'Midas Dollar',
            'address': '0x35e869B7456462b81cdB5e6e42434bD27f3F788c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x35e869b7456462b81cdb5e6e42434bd27f3f788c.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'TAP',
            'name': 'Tap Fantasy',
            'address': '0x35bEdBF9291b22218a0dA863170dcC9329Ef2563',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23854/thumb/17718.png?1645576059',
            'coingeckoId': 'tap-fantasy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRINITY',
            'name': 'Trinity Swap',
            'address': '0x35aA633282b071b1Ab5Ba8e031e41EA6e271f50f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27890/thumb/TrinitySWap.png?1666247930',
            'coingeckoId': 'trinity-swap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JIGSAW',
            'name': 'Jigsaw',
            'address': '0x35E446a313369F2CCa5592E62dB9edBB23233Dd2',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16306/thumb/Logo_200x200_%282%29.png?1623721841',
            'coingeckoId': 'jigsaw',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHAIN',
            'name': 'Chain Games',
            'address': '0x35DE111558F691F77f791fb0c08b2D6B931A9d47',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12257/thumb/chain-logo-centered-500x500.png?1599617244',
            'coingeckoId': 'chain-games',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MINISHIB',
            'name': 'miniSHIB Token',
            'address': '0x35Bd36597fF784FCc30D08EBE4Bd917374C40d4B',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17122/thumb/miniSHIB-new-200.png?1626399094',
            'coingeckoId': 'minishib-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ESWAPV2',
            'name': 'eSwapping v2',
            'address': '0x358eF99D093F4d425DBD0e8e797d9185e4Aaf4B4',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22728/thumb/83286022.png?1642488878',
            'coingeckoId': 'eswapping-v2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFEDOGE',
            'name': 'SafeDogeCoin V2',
            'address': '0x3587aF633dD49cd0230625A1c0549D6e8E475F0A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25259/thumb/My_Post__57_-removebg-preview.png?1651042832',
            'coingeckoId': 'safedogecoin-v2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COR',
            'name': 'CorgiNFTGame',
            'address': '0x3581a7B7BE2eD2EDf98710910fD05b0e8545f1DB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19326/thumb/bitcor.png?1635119337',
            'coingeckoId': 'corginftgame',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'CSM',
            'name': 'CityStates Medieval',
            'address': '0x35754E4650c8ab582F4e2Cb9225E77e6685be25A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17392/thumb/csm200x200.png?1634017115',
            'coingeckoId': 'citystates-medieval',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TATA',
            'name': 'HakunaMatata  old ',
            'address': '0x355389292D8c963719FDaF0651f7846D6c504448',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15718/thumb/output-onlinepngtools-1.png?1621589980',
            'coingeckoId': 'hakuna-metata',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CACTI',
            'name': 'Cacti Club',
            'address': '0x35482D03A807aBDf5B25f277bFE744A4E2fb19a2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18914/thumb/Cacti-tokensymbol.png?1633917345',
            'coingeckoId': 'cacti-club',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHIM',
            'name': 'Chimeras',
            'address': '0x3544a07e9f771EF0f6c80C6E79715869Ed291086',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19638/thumb/chimeras_icon_4_%282%29.png?1635717889',
            'coingeckoId': 'chimeras',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABYWKD',
            'name': 'Babywakandainu',
            'address': '0x353a94E74750c5D2D5b3C52ABaD54D76cB08a151',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20995/thumb/OZryZPiqih0h.png?1638173462',
            'coingeckoId': 'babywakandainu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BPM',
            'name': 'Baby Pokemoon',
            'address': '0x35359f21Abdf0f2B6aE01bFb96814738B515111e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23869/thumb/bpm_200.png?1645598777',
            'coingeckoId': 'baby-pokemoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTT',
            'name': 'Bittorrent',
            'address': '0x352Cb5E19b12FC216548a2677bD0fce83BaE434B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x352Cb5E19b12FC216548a2677bD0fce83BaE434B.png',
            'coingeckoId': null,
            'listedIn': [
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'KBOX',
            'name': 'The Killbox Game',
            'address': '0x3523d58d8036B1C5C9A13493143c97aEfC5Ad422',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20828/thumb/icon_abe_token-200.png?1637726119',
            'coingeckoId': 'the-killbox-game',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELOINU',
            'name': 'Elo Inu',
            'address': '0x35207068e057a01861A654463c01B04cc111E760',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21570/thumb/5gLcVCKI.png?1639486080',
            'coingeckoId': 'elo-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHIRO',
            'name': 'Chihiro Inu',
            'address': '0x35156b404C3f9bdaf45ab65Ba315419bcDe3775c',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19721/thumb/chiro200x200.png?1642166355',
            'coingeckoId': 'chihiro-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SDX',
            'name': 'SwapDEX',
            'address': '0x351494731D28f35d648C200b35E628aecba3E577',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13717/thumb/sdx.png?1611116537',
            'coingeckoId': 'swapdex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DFSM',
            'name': 'DFS Mafia V2',
            'address': '0x350494Bcc94EfB5c6080f6A6F0043da27be2AD2C',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27931/thumb/dfsm.png?1666426557',
            'coingeckoId': 'dfs-mafia',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BALA',
            'name': 'Shambala',
            'address': '0x34bA3af693d6c776d73C7fa67e2B2e79be8ef4ED',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19635/thumb/shambala.PNG?1635715476',
            'coingeckoId': 'shambala',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DND',
            'name': 'Diamond DND',
            'address': '0x34EA3F7162E6f6Ed16bD171267eC180fD5c848da',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16269/thumb/dnd.png?1623577410',
            'coingeckoId': 'diamond-dnd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOONER',
            'name': 'CoinMooner',
            'address': '0x34E942859469c9dB9C22F4eAF866E2c2401BB795',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19537/thumb/coinmooner.PNG?1635382243',
            'coingeckoId': 'coinmooner',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLX',
            'name': 'Felix',
            'address': '0x34E4A7454cAE15990850166A8771CB8408b62a26',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23492/thumb/Felix.jpeg?1644224052',
            'coingeckoId': 'felix',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STZ',
            'name': 'Starz',
            'address': '0x34BEd6DB1e7dDD73B6a68d05e43f9f29c45f56bE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28193/thumb/app-icon2.png?1668328588',
            'coingeckoId': 'starz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLS',
            'name': 'Blocks Space',
            'address': '0x34Aa9099D924F3FB2377ff20D81b235311c15346',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18023/thumb/1000Blocks-Logo-Small-Circle.png?1630496721',
            'coingeckoId': 'blockspace-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MLAND',
            'name': 'MetaLandmap',
            'address': '0x349A1b99944914628D13963E2905D6Da95695f3B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': 'metalandmap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LGC',
            'name': 'LiveGreen Coin',
            'address': '0x3496212eC43Cc49F5151ec4405efD4975e036f89',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28197/thumb/lgclogo_final-200px.png?1668335368',
            'coingeckoId': 'livegreen-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CPTLC',
            'name': 'Cryptolic',
            'address': '0x348e9EcB19a3f141A60845a29A32c701dC0EcB3B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27744/thumb/cptlc-logo-200.png?1665631864',
            'coingeckoId': 'cryptolic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FIA',
            'name': 'FIA Protocol',
            'address': '0x3489bE00546e660b58d182Bab7720de5aACb00F6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20002/thumb/fia_protocol.PNG?1636364632',
            'coingeckoId': 'fia-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GAIA',
            'name': 'Gaia Everworld',
            'address': '0x347E430b7Cd1235E216be58ffA13394e5009E6e2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x347e430b7cd1235e216be58ffa13394e5009e6e2.png',
            'coingeckoId': 'gaia-everworld',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'COLI',
            'name': 'Coliquidity',
            'address': '0x3470C81026C8085b7B743695f851353043Ff0d0D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15813/thumb/sign_dark_200x200.png?1646641417',
            'coingeckoId': 'shield-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOR',
            'name': 'DoragonLand',
            'address': '0x3465fD2D9f900e34280aBab60E8d9987B5b5bb47',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21143/thumb/13977.png?1638396483',
            'coingeckoId': 'doragonland',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAR',
            'name': 'Hierocoin',
            'address': '0x34550001Fbf7d6e42e812763C91eF96F129742AB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21429/thumb/heiro.png?1639119385',
            'coingeckoId': 'hierocoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TCS',
            'name': 'Timechain Swap Token',
            'address': '0x3447Ac4aD1153bA762034EE7B8Ac637c553A9134',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'HECTA',
            'name': 'Hectagon',
            'address': '0x343915085b919fbd4414F7046f903d194c6F60EE',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26795/thumb/Icon_%281%29.png?1664359647',
            'coingeckoId': 'hectagon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LPI',
            'name': 'LPI DAO',
            'address': '0x3428ebF69d2Db64a056c68871F27C9e0310F26c9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18551/thumb/IMG-20210923-102411-497.jpg?1632374872',
            'coingeckoId': 'lpi-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSCGOLD',
            'name': 'BSCGold',
            'address': '0x34195FD824355aec1BDCeEA97697B35be691bcB3',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15034/thumb/Untitled-design-32-removebg-preview-1.png?1619502565',
            'coingeckoId': 'bscgold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STC',
            'name': 'Satoshi Island',
            'address': '0x340724464cf51a551106cC6657606Ee7d87B28b9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26975/thumb/2KstL9si_400x400.jpeg?1661163830',
            'coingeckoId': 'satoshi-island',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETHE',
            'name': 'ETHEKing',
            'address': '0x33efbC79BbF863dB11b4A1BF6570FFB6Bf41d0d2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22940/thumb/logomini.jpg?1643008027',
            'coingeckoId': 'etheking',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AIOZ',
            'name': 'AIOZ Network',
            'address': '0x33d08D8C7a168333a85285a68C0042b39fC3741D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14631/thumb/aioz-logo-200.png?1649237507',
            'coingeckoId': 'aioz-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SSAP',
            'name': 'Human World',
            'address': '0x33c8e2FF6299950354Ce06cB1e578226bBF29362',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23161/thumb/humanworld-logo200x200.png?1643354359',
            'coingeckoId': 'human-world',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORT',
            'name': 'ORT Rune  Rune Game ',
            'address': '0x33bc7539D83C1ADB95119A255134e7B584cd5c59',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27580/thumb/ort.png?1664696955',
            'coingeckoId': 'ort-rune-rune-game',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTIX',
            'name': 'Matrix',
            'address': '0x33b783A4833f7613cCB6569a9F39a261b311AFbb',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23403/thumb/mtix.png?1647077921',
            'coingeckoId': 'matrix-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COWS',
            'name': 'Cowboy Snake',
            'address': '0x33b48893B8f119Fb45F431b36F830a9584804201',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21876/thumb/cowboy_snake.PNG?1640210787',
            'coingeckoId': 'cowboy-snake',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AQDC',
            'name': 'Aquanee',
            'address': '0x33aa7797ac6CB8B4652B68E33e5ADd8Ad1218A8D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25771/thumb/aqdc.png?1653624068',
            'coingeckoId': 'aquanee',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VOYRME',
            'name': 'VOYR',
            'address': '0x33a887Cf76383be39DC51786e8f641c9D6665D84',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16214/thumb/VOYR_Icon_Red300dpi-200x200.png?1623314649',
            'coingeckoId': 'voyr',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OGC',
            'name': 'Onegetcoin',
            'address': '0x33D53608167aC3679c0FE9f7A7A725891cF0f471',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14657/thumb/ogc.png?1617594609',
            'coingeckoId': 'onegetcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MNFT',
            'name': 'Marvelous NFTs',
            'address': '0x33BE7644c0E489b3A0c639D103392D4F3e338158',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22613/thumb/mnft.png?1642238172',
            'coingeckoId': 'marvelous-nfts',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHG',
            'name': 'Shib Generating',
            'address': '0x33AF7644B1F60c8e8d62689b19d60a5d132164fC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21650/thumb/shg.png?1639640589',
            'coingeckoId': 'shib-generating',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPORE',
            'name': 'Spore',
            'address': '0x33A3d962955A3862C8093D1273344719f03cA17C',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14470/thumb/SPORE.png?1616567184',
            'coingeckoId': 'spore',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TXBIT',
            'name': 'Txbit',
            'address': '0x339Fe932809E39A95B621A7f88BbF6C08eb6C978',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21122/thumb/Ft4mjdHu_400x400.jpg?1638342134',
            'coingeckoId': 'txbit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WANA',
            'name': 'Wanaka Farm',
            'address': '0x339C72829AB7DD45C3C52f965E7ABe358dd8761E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18020/thumb/wanaka.PNG?1630282979',
            'coingeckoId': 'wanaka-farm',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'SOCCER',
            'name': 'SOCCER',
            'address': '0x338d44d19c8d45ed9771b8D2B95D988F0e42187F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0x338d44d19c8d45ed9771b8d2b95d988f0e42187f.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'RENS',
            'name': 'Rens',
            'address': '0x338DbFfc5A1D19473d4762a84b79fCdf2C6A4736',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25681/thumb/200cx200.png?1653372173',
            'coingeckoId': 'rens',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RICE',
            'name': 'Rooster Battle',
            'address': '0x338AF54976B9D4F7F41c97dcb60dFEc0694149f9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20975/thumb/roosterbattle.png?1638161550',
            'coingeckoId': 'rooster-battle',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EBOX',
            'name': 'Ebox',
            'address': '0x33840024177A7DacA3468912363BeD8b425015c5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14528/thumb/ebox.png?1639203519',
            'coingeckoId': 'ebox',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'NOTSAFEMOON',
            'name': 'NotSafeMoon',
            'address': '0x337e35Ed5B38D5C7Ec9F8d7cF78fe7F43d7DEC6F',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16075/thumb/whiteborderlogo.png?1622771208',
            'coingeckoId': 'notsafemoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XBX',
            'name': 'X BLOX',
            'address': '0x337C218f16dBc290fB841Ee8B97A74DCdAbfeDe8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26381/thumb/xblox-logo.png?1657678295',
            'coingeckoId': 'x-blox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOLE',
            'name': 'BoleToken',
            'address': '0x3375AfA606F5836154C95F1Df5830EA2e4F41DF2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0x3375afa606f5836154c95f1df5830ea2e4f41df2/57407e9c64605afbb59dde66f8b4b6f4.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SAFEZONE',
            'name': 'SafeZone',
            'address': '0x33714356e2a3E216D055440Eb24D0E23458B1B85',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28204/thumb/ykjBAkw9_400x400.jpeg?1668392527',
            'coingeckoId': 'safezone-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CU',
            'name': 'Celestial Unity',
            'address': '0x3370a0377D4A1b1a1865b5A8E6e77F51e85198EF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23641/thumb/qPDCKIz-3yUx4zkAPevBpNrr-KoBMEeW2Vifqrr2MLL45DkTKh5aI6CEjw4dq5HMKjmaWgoUGEZrahB9n5PbnBAeopZGABkGL2ljxMFnNtuNATeKK_9rT5iyAgUzaYUnVC-AW66mEv8SgX4VFHkBkwHQ3PzC8qRaEIRjUSD62-yHZftCxZB0tmZGb6i1e7ZB8sWDieTWxCOgB5apTEva-6CZGK.jpg?1644908878',
            'coingeckoId': 'celestial-unity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIMIR',
            'name': 'Mimir',
            'address': '0x336f5A68fD46A25056a6C1D9c06072c691486acC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19551/thumb/xaq5Xlzg_400x400.jpg?1635401626',
            'coingeckoId': 'mimir-token',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'AMPLE',
            'name': 'AmpleSwap',
            'address': '0x335F6e0E804B70A96bf9eB8AF31588942e9B2515',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19475/thumb/ample.PNG?1635282418',
            'coingeckoId': 'ampleswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VDAI',
            'name': 'Venus DAI',
            'address': '0x334b3eCB4DCa3593BCCC3c7EBD1A1C1d1780FBF1',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x334b3ecb4dca3593bccc3c7ebd1a1c1d1780fbf1.png',
            'coingeckoId': 'venus-dai',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'WEB3',
            'name': 'The Web3 Project',
            'address': '0x333FD139cAeF6Aa31056cC905987b77B1044d259',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x333fd139caef6aa31056cc905987b77b1044d259/logo.png',
            'coingeckoId': 'the-web3-project',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'LIQR',
            'name': 'Topshelf Finance',
            'address': '0x33333ee26a7d02e41c33828B42Fb1E0889143477',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21580/thumb/b-MDs-Uqg-Q-400x400.jpg?1639532412',
            'coingeckoId': 'topshelf-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VSP',
            'name': 'VesperToken',
            'address': '0x332730a4F6E03D9C55829435f10360E13cfA41Ff',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'JADE',
            'name': 'Jade Currency',
            'address': '0x330F4fe5ef44B4d0742fE8BED8ca5E29359870DF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/10072/thumb/photo_2021-05-31_15.02.34.jpeg?1622444576',
            'coingeckoId': 'jade-currency',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOIN',
            'name': 'GOIN',
            'address': '0x32ff208a18A5fbC81F6Fd262020b1415bfcb3b33',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21773/thumb/GOinfluencer-20.png?1640004302',
            'coingeckoId': 'goin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DNKY',
            'name': 'AstroDonkey',
            'address': '0x32f3145A98A61813Af1ab0631A6a81850cEB8CaD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21279/thumb/KGBvdHyw_400x400.jpg?1638863001',
            'coingeckoId': 'astrodonkey',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DESU',
            'name': 'Dexsport',
            'address': '0x32f1518BaAcE69e85b9E5fF844eBd617c52573ac',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20583/thumb/desu.png?1638775497',
            'coingeckoId': 'dexsport',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BAKECOIN',
            'name': 'Bake Coin',
            'address': '0x32eD23b9D263138695168850Ac04609f6e5e0aB4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18296/thumb/IMG-20210909-160846-983.jpg?1631346278',
            'coingeckoId': 'bake-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JULb',
            'name': 'JULb',
            'address': '0x32dFFc3fE8E3EF3571bF8a72c0d0015C5373f41D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x32dffc3fe8e3ef3571bf8a72c0d0015c5373f41d.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'GINZA',
            'name': 'Ginza Network',
            'address': '0x32d7DA6a7cf25eD1B86E1B0Ee9a62B0252D46B16',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19906/thumb/GDZGB-Qw_400x400.png?1636101895',
            'coingeckoId': 'ginza-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSAFU',
            'name': 'BlockSafu',
            'address': '0x32bFd701655EDF95809EaA5e525F0024ea571267',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26872/thumb/IMG-20220812-152357-743.jpg?1660549570',
            'coingeckoId': 'blocksafu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WFAC',
            'name': 'Fahrenheit Chain',
            'address': '0x32aF3e999D657917aA646FCad40520686CD41667',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27862/thumb/200.png?1666147702',
            'coingeckoId': 'fahrenheit-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PEAX',
            'name': 'Prelax',
            'address': '0x32D7b49e5E69c6C2f6d05CF4d86D9857D83aDD48',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18593/thumb/undefined_-_Imgur.jpg?1632559932',
            'coingeckoId': 'prelax',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HMR',
            'name': 'Homeros',
            'address': '0x32D12029F62260E239b5b5C8F0BeA9Cb382Cfdd6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/11048/thumb/g1jAE5knZSlNUdLN8fEAQFMI4mTXCuNdrjj3i77rWhsIQADp3VWH2BHTkH7-oWwExxJk7Fu545LPeT3gMNzGlCZ63oX3-9sTjiqlSDo3fRLwHmhUgjD_jtKTl1AJ_F_UOOpsTqob9CxiWHXsSTlz0zODxOrjnqpMe4_cPT_C4EskSEVee_oooTasTQ6ONrv85Zcvc8Eeb8cHLsV.jpg?1587532938',
            'coingeckoId': 'homeros',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SFO',
            'name': 'Sunflower Finance',
            'address': '0x3295fdE99976e6B6b477E6834b2651a22DeB1dd7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18943/thumb/7qv-tC6x_400x400.jpg?1633997379',
            'coingeckoId': 'sunflower-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HETH',
            'name': 'Hodl ETH',
            'address': '0x328ACc7cd3e2340ceF992B67c852095C0ee98603',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21076/thumb/eKKSueB.png?1638285093',
            'coingeckoId': 'hodl-eth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ECIO',
            'name': 'ECIO Space',
            'address': '0x327A3e880bF2674Ee40b6f872be2050Ed406b021',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21884/thumb/Ecio_Token_200x200.png?1640215495',
            'coingeckoId': 'ecio-space',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFTBS',
            'name': 'NFTBooks',
            'address': '0x3279510E89600ee1767a037DBD0aD49c974063AE',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17291/thumb/200x200_newBook.png?1633259260',
            'coingeckoId': 'nftbooks',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VIVA',
            'name': 'Viva Classic',
            'address': '0x32767ca0B39a1261E4bA392A605F7FAB37d059c7',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25293/thumb/EeIoXr_0_400x400.jpg?1651140109',
            'coingeckoId': 'viva-classic-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LILFLOKI',
            'name': 'Lil Floki',
            'address': '0x3271D12D5ba36B6582fafA029598FEe0F5F6Db35',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19198/thumb/lilfloki200x200coingecko.png?1634656630',
            'coingeckoId': 'lil-floki',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CNL',
            'name': 'CNL',
            'address': '0x3266566Bf7851a542e3e5bbeceB406eA293Fb648',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26481/thumb/logo.png?1658279159',
            'coingeckoId': 'cnl-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VERVE',
            'name': 'Verve',
            'address': '0x32561fA6D2D3E2191bF50f813DF2C34fb3C89B62',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22804/thumb/16902.png?1642638793',
            'coingeckoId': 'verve',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XUSD',
            'name': 'xUSD Token',
            'address': '0x324E8E649A6A3dF817F97CdDBED2b746b62553dD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21657/thumb/image.png?1639696944',
            'coingeckoId': 'xusd-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CLCT',
            'name': 'CollectCoin',
            'address': '0x3249fA9E11EfeCe7cb03b4Ad2994F46e54a35843',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25857/thumb/atUulXVV_400x400.jpg?1654156558',
            'coingeckoId': 'collectcoin-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ROFI',
            'name': 'HeroFi ROFI',
            'address': '0x3244B3B6030f374bAFA5F8f80Ec2F06aAf104B64',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21296/thumb/herofi_logo.jpg?1638875897',
            'coingeckoId': 'herofi-token-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTM',
            'name': 'MetaMate',
            'address': '0x3244478Da3F518B33b99D5929Dd0bC3396C56981',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25017/thumb/logo_metamate.png?1649830557',
            'coingeckoId': 'metamate',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MNFT',
            'name': 'MongolNFT',
            'address': '0x3235B13708F178Af6F110dE7177ED5De10c1093d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PUPDOGE',
            'name': 'Pup Doge',
            'address': '0x3220CCbbC29d727Bde85b7333D31b21e0d6bC6F4',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17473/thumb/pupdoge.PNG?1627894702',
            'coingeckoId': 'pup-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LHC',
            'name': 'Lightcoin',
            'address': '0x320d31183100280CcdF69366CD56180Ea442A3E8',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16381/thumb/lhc.PNG?1623835248',
            'coingeckoId': 'lightcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SLF',
            'name': 'Solarfare',
            'address': '0x32089eafFaf278C82cda2c8d37e7b6b6faBBaAF2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15389/thumb/43GSNCY.png?1620702876',
            'coingeckoId': 'solarfare',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MBOX',
            'name': 'Mobox',
            'address': '0x3203c9E46cA618C8C1cE5dC67e7e9D75f5da2377',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x3203c9e46ca618c8c1ce5dc67e7e9d75f5da2377.png',
            'coingeckoId': 'mobox',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'METAVIE',
            'name': 'Metavie',
            'address': '0x31fF78bf15CD6F53fC10Db52a0B4C9a2d1EC1808',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26157/thumb/wNItJMGS_400x400.png?1656322566',
            'coingeckoId': 'metavie-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOM',
            'name': 'Souls of Meta',
            'address': '0x31c573d1A50A745b01862edAf2ae72017cea036A',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25515/thumb/soulsofmeta.7527682e.jpg?1652164537',
            'coingeckoId': 'souls-of-meta',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PORN',
            'name': 'Porn',
            'address': '0x31b9773f225408129a90788EF013Bd449e283865',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x31b9773f225408129a90788ef013bd449e283865.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'WORLD',
            'name': 'World',
            'address': '0x31FFbe9bf84b4d9d02cd40eCcAB4Af1E2877Bbc6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13801/thumb/WORLD.png?1612843088',
            'coingeckoId': 'world-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FREEMOON',
            'name': 'Freemoon',
            'address': '0x31F0Bc450C12eb62B4c617d4C876f7a66470Fcb3',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19384/thumb/freemoo.png?1635143395',
            'coingeckoId': 'freemoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INFLUENCE',
            'name': 'Influencer Finance',
            'address': '0x31E4660915558b408dBA65F7F39E317BED63367B',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22867/thumb/DsKJ3tDPdw7113vY1f2RDW1IqRVCOxAwRy88sMUxX_MDT3smSOABQNlm-YB7aAp-SV8Ig_GQnZDTDgC7M8HaRPKrtFyCPG__QaU0uWpB9E7OtOMP8YRMB1MEEJrHvl-pYKZTE6aCTQvErJqNbv9bG-WAJkXjqThG6PXUF8o0rpzoiWY29epXRiz9J6eHmMG66ec_UibxZRl_6r.jpg?1642755307',
            'coingeckoId': 'influencer-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFTPUNK',
            'name': 'NFTPunk Finance',
            'address': '0x31DE61D9a39CB9f479570Bd3dC3AC93BC0402CDB',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15544/thumb/200x200%281%29.png?1628508990',
            'coingeckoId': 'nftpunk-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LAEEB',
            'name': 'La eeb Football',
            'address': '0x31D91887fabC81690525c25b76022796D4091E8a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27732/thumb/download.png?1665560682',
            'coingeckoId': 'la-eeb-football',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'START',
            'name': 'Starter xyz',
            'address': '0x31D0a7AdA4d4c131Eb612DB48861211F63e57610',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x31d0a7ada4d4c131eb612db48861211f63e57610.png',
            'coingeckoId': 'bscstarter',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'CP',
            'name': 'Coinpad',
            'address': '0x31Ce6ac6007eB0f5AbbC1CA824a9e62dB81e8933',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21915/thumb/050be0_11787acad80d4871a2b3041debab57c0_mv2.png?1640263673',
            'coingeckoId': 'coinpad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PIGGY',
            'name': 'PIGGY',
            'address': '0x31CdD9F5a6a1E39a38A6b073Fc3FaBE6CbfeAc76',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'METOO',
            'name': 'Metoo',
            'address': '0x31B35FdAa0780a75dd08A1A631c03e34FCeF7173',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26512/thumb/glimPMvM_400x400.jpeg?1658448983',
            'coingeckoId': 'metoo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LKN',
            'name': 'Lockness',
            'address': '0x31ACFCE536B824ad0739E8D7b27CEFAa4b8E4673',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20176/thumb/lockness_bubles.png?1636603769',
            'coingeckoId': 'lockness',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BURN1COIN',
            'name': 'Burn1Coin',
            'address': '0x31A9042dCE0C13975D04d0E9758Cb7B596F0dD43',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16227/thumb/LqID7du8_400x400.jpg?1623342104',
            'coingeckoId': 'burn1coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INSUR',
            'name': 'InsurAce',
            'address': '0x3192CCDdf1CDcE4Ff055EbC80f3F0231b86A7E30',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x3192ccddf1cdce4ff055ebc80f3f0231b86a7e30.png',
            'coingeckoId': 'insurace',
            'listedIn': [
                'coingecko',
                '1inch',
                'pancake',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'UNITY',
            'name': 'Unity Protocol',
            'address': '0x3182d2983fA1b6928A581CF30a5fca094736200f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19068/thumb/data-footer-logo.png?1634270041',
            'coingeckoId': 'unity-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STZU',
            'name': 'Shihtzu Exchange',
            'address': '0x31801B15215C021e7988Fa0bc37dCfa6a303BD00',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27599/thumb/hero-shihtzu_2.png?1664715424',
            'coingeckoId': 'shihtzu-exchange',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TDX',
            'name': 'Tidex',
            'address': '0x317eb4ad9cfaC6232f0046831322E895507bcBeb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/3137/thumb/1.png?1656309141',
            'coingeckoId': 'tidex-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALICN',
            'name': 'Alicoin',
            'address': '0x316FC277D70F967A3F93Ed59FB46c92435a5c7F0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23395/thumb/alicn.png?1644128874',
            'coingeckoId': 'alicoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZNS',
            'name': 'Zeronauts',
            'address': '0x31582e4Dd4906f2eb71C0D7b141AA097FB715491',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21216/thumb/logo_200x200_black_transparent.png?1638669523',
            'coingeckoId': 'zeronauts',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COLLIE',
            'name': 'COLLIE INU',
            'address': '0x31491c35C094A0336f4859Dd94aB9466709deC45',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27365/thumb/pfp2.jpg?1663668006',
            'coingeckoId': 'collie-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PVU',
            'name': 'Plant vs Undead',
            'address': '0x31471E0791fCdbE82fbF4C44943255e923F1b794',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0x31471e0791fcdbe82fbf4c44943255e923f1b794.png',
            'coingeckoId': 'plant-vs-undead-token',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'KIT',
            'name': 'DexKit',
            'address': '0x314593fa9a2fa16432913dBcCC96104541d32D11',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13187/thumb/7739.png?1643187354',
            'coingeckoId': 'dexkit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WOW',
            'name': 'World of Waves',
            'address': '0x31353E2826Cc4402735E55a175a75CE2569B4226',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15412/thumb/wow.png?1628665655',
            'coingeckoId': 'world-of-waves',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VITY',
            'name': 'Vitteey',
            'address': '0x311E85452Ec46d03D056317b979d444Ea717dc7E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24796/thumb/vity.png?1648980069',
            'coingeckoId': 'vitteey',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLASH',
            'name': 'Flash',
            'address': '0x311E015E2e255Dd14edDc4a2107e39649dc81c5D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22327/thumb/logo-Flash-Token-valide-2-01.png?1641478173',
            'coingeckoId': 'flash-token-2',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'GXT',
            'name': 'Gem Exchange and Trading',
            'address': '0x3107C0A1126268CA303f8d99c712392fA596e6D7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12226/thumb/a.png?1653378779',
            'coingeckoId': 'gem-exchange-and-trading',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POOL',
            'name': 'Pool',
            'address': '0x30F11795B21b5EFC1A2DeD78d04574d0e36442BD',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19748/thumb/bFKovOQ.png?1635822934',
            'coingeckoId': 'pool-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STR',
            'name': 'Sourceless',
            'address': '0x30DCf96a8a0c742AA1F534FAC79e99D320c97901',
            'decimals': 13,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25931/thumb/https___s3.us-east-2.amazonaws.com_nomics-api_static_images_currencies_STR6.png?1654747973',
            'coingeckoId': 'sourceless',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABYMETA',
            'name': 'Baby Meta',
            'address': '0x30C49441d326677892891b97B34743306Bc97934',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20623/thumb/P6N-L2Br_400x400.jpg?1637318386',
            'coingeckoId': 'baby-meta',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REV3L',
            'name': 'REV3AL',
            'address': '0x30B5E345C79255101B8af22a19805A6fb96DdEBb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26121/thumb/rev3l.png?1661498194',
            'coingeckoId': 'rev3al',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CDDSP',
            'name': 'Can Devs Do Something Please',
            'address': '0x309c3A9e662F29e91a6C4335619A08A545743072',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23298/thumb/cddsp.png?1643602113',
            'coingeckoId': 'can-devs-do-something-please',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BFLOKI',
            'name': 'BurnFloki',
            'address': '0x308d6B4bDeD91f99b58bdaAfCC1137Fb4cFd4AB2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24775/thumb/bfloki.png?1648898007',
            'coingeckoId': 'burnfloki',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DCIP',
            'name': 'Decentralized Community Investment Prot',
            'address': '0x308FC5CdD559Be5cB62B08A26a4699bBef4a888f',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17274/thumb/DCIP200.png?1627009259',
            'coingeckoId': 'decentralized-community-investment-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SDK',
            'name': 'StarDuke',
            'address': '0x308757ba7F2172b89c73a4Cb8A7692d6Cd11E3dF',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26462/thumb/Starduke_200x200.png?1658135110',
            'coingeckoId': 'starduke',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RADIO',
            'name': 'RadioShack',
            'address': '0x30807D3b851A31d62415B8bb7Af7dCa59390434a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x30807D3b851A31d62415B8bb7Af7dCa59390434a/logo.png',
            'coingeckoId': 'radioshack',
            'listedIn': [
                'coingecko',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GTFX',
            'name': 'Gitshock Finance',
            'address': '0x306e974624511E3937F37E551C5736F1b2aD21eb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28033/thumb/Logo_Gitshock_HD.png?1667117754',
            'coingeckoId': 'gitshock-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FONT',
            'name': 'Font',
            'address': '0x3053ad3b31600074e9A90440770f78D5e8Fc5A54',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14085/thumb/font.png?1614183855',
            'coingeckoId': 'font',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FOC',
            'name': 'TheForce Trade',
            'address': '0x3051CFb958dcD408FBa70256073Adba943Fdf552',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15490/thumb/force.PNG?1621040891',
            'coingeckoId': 'theforce-trade',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COT',
            'name': 'CoTrader',
            'address': '0x304fC73e86601a61a6C6db5B0eAfEA587622acdC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/4205/thumb/logo_black.png?1547039508',
            'coingeckoId': 'cotrader',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ROAR',
            'name': 'RoArts',
            'address': '0x3046f5cD17Cb6A7f5BBcc3A23309dD32106C9116',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21256/thumb/roarts.PNG?1638778690',
            'coingeckoId': 'roarts',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORI',
            'name': 'Orica',
            'address': '0x3045d1A840364c3657b8Df6c6F86a4359c23472B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x3045d1a840364c3657b8df6c6f86a4359c23472b_1.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'DORD',
            'name': 'DeFi Or Die',
            'address': '0x303212DA4fe2ec8939E12B816D654B74F0D433f1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27432/thumb/20220923_035557.png?1663926249',
            'coingeckoId': 'defi-or-die',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AUC',
            'name': 'Auctus',
            'address': '0x3028b4395F98777123C7da327010c40f3c7Cc4Ef',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/2165/thumb/Auc_Discord_Avatar1.png?1618983355',
            'coingeckoId': 'auctus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NUDES',
            'name': 'NUDES',
            'address': '0x301FF7C013ec7043fFB9453cd3FB32754cCAA1a5',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24888/thumb/NUDES.png?1649252682',
            'coingeckoId': 'nudes',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNB',
            'name': 'Unbound Finance',
            'address': '0x301AF3Eff0c904Dc5DdD06FAa808f653474F7FcC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/7846.png',
            'coingeckoId': 'unbound-finance',
            'listedIn': [
                'coingecko',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'GMT',
            'name': 'STEPN',
            'address': '0x3019BF2a2eF8040C242C9a4c5c4BD4C81678b2A1',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x3019bf2a2ef8040c242c9a4c5c4bd4c81678b2a1_1.png',
            'coingeckoId': 'stepn',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'CHH',
            'name': 'Chihuahua Token',
            'address': '0x2fd6c9B869Dea106730269E13113361b684f843a',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26766/thumb/200x200.png?1663763840',
            'coingeckoId': 'chihuahua-token-19fcd0de-eb4d-4fd7-bc4a-a202247dfdbb',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GINOA',
            'name': 'Ginoa',
            'address': '0x2fF90B0c29ededDAF11c847925ea4a17789e88c3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24033/thumb/gintoken.png?1646117177',
            'coingeckoId': 'ginoa',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VSXP',
            'name': 'Venus SXP',
            'address': '0x2fF3d0F6990a40261c66E1ff2017aCBc282EB6d0',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x2ff3d0f6990a40261c66e1ff2017acbc282eb6d0.png',
            'coingeckoId': 'venus-sxp',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MYST',
            'name': 'Mysterium',
            'address': '0x2fF0B946A6782190C4Fe5D4971CFE79F0b6E4df2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x2ff0b946a6782190c4fe5d4971cfe79f0b6e4df2.png',
            'coingeckoId': 'mysterium',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SEAMLESS',
            'name': 'SeamlessSwap',
            'address': '0x2fC9E0F34d86f65b495De7EE3BB5cBeAc7F92b04',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20836/thumb/h9aSYw1B_400x400.jpg?1637736753',
            'coingeckoId': 'seamlessswap-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TT',
            'name': 'Trillium',
            'address': '0x2f8A4c0CA0b45A2FB240CBA7010c34BA60aD9B6D',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22770/thumb/39cb52d6-cd27-510e-6c7b-a876a455613a.png?1642576177',
            'coingeckoId': 'trillium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFTL',
            'name': 'NFTL Token',
            'address': '0x2f7b4C618Dc8E0bBA648E54cDADce3D8361f9816',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x2f7b4c618dc8e0bba648e54cdadce3d8361f9816.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'PXDOGE',
            'name': 'Pixel Doge',
            'address': '0x2f625713D80bd14d2ABa2E902d5739270Fd8C81b',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27919/thumb/pxdoge.png?1666351894',
            'coingeckoId': 'pixel-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MELODY',
            'name': 'Melody',
            'address': '0x2f619a1270226fD8ed3Df82f0C1e0cd90804346d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15442/thumb/UajWzxT.png?1620807695',
            'coingeckoId': 'melody',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EXMOC',
            'name': 'EXMOC',
            'address': '0x2f53C0E79646c414FcF5aACAf7C8310b074C40A3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24592/thumb/exmoc.png?1648299916',
            'coingeckoId': 'exmoc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REFLEX',
            'name': 'Reflex Finance V2',
            'address': '0x2f499c6DA2C84063BB7e0CB1C478687210cDB615',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23417/thumb/Reflex_V2_200x200.png?1653289543',
            'coingeckoId': 'reflex-finance-v2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CATS',
            'name': 'CatCoin Token',
            'address': '0x2f0c6e147974BfbF7Da557b88643D74C324053A2',
            'decimals': 0,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23569/thumb/17318.png?1653115443',
            'coingeckoId': 'catcoin-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VENTION',
            'name': 'Vention',
            'address': '0x2f053e33bd590830858161d42C67e9E8A9390019',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17497/thumb/logo-black-200.png?1627973980',
            'coingeckoId': 'vention',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZAFI',
            'name': 'ZakumiFi',
            'address': '0x2efDff1e566202f82e774bB7aDD18c56CbB9427D',
            'decimals': 17,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28138/thumb/Logo-Zakumi.png?1667786381',
            'coingeckoId': 'zakumifi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LEAD',
            'name': 'Lead',
            'address': '0x2ed9e96EDd11A1fF5163599A66fb6f1C77FA9C66',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12384/thumb/lead.jpg?1599491466',
            'coingeckoId': 'lead-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALTS',
            'name': 'AltSwitch',
            'address': '0x2ec79904C2aB4F8b6e8e89c743CB7F7a88DFc0fE',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22785/thumb/download.png?1646290010',
            'coingeckoId': 'altswitch',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'NFTC',
            'name': 'NFTCircle',
            'address': '0x2eFCDD1383EaE3aF14D785dcc65d6B865B562312',
            'decimals': 2,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21898/thumb/9681.png?1640231930',
            'coingeckoId': 'nftcircle',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MASK',
            'name': 'Mask Network',
            'address': '0x2eD9a5C8C13b93955103B9a7C167B67Ef4d568a3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x2ed9a5c8c13b93955103b9a7c167b67ef4d568a3.png',
            'coingeckoId': 'mask-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SCROOGEJR',
            'name': 'SCROOGE JUNIOR',
            'address': '0x2e9F79aF51dD1bb56Bbb1627FBe4Cc90aa8985Dd',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26966/thumb/JR_LOGO.PNG?1661084777',
            'coingeckoId': 'scrooge-junior',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOON',
            'name': 'MoonCake',
            'address': '0x2e8C05582176Fa93b4590382e8290C73Deb82176',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17229/thumb/IMG-6490.png?1626921183',
            'coingeckoId': 'mooncake',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'META',
            'name': 'MetaCash',
            'address': '0x2e42C9EAc96833C6e16Dc71c743cECC114CCD7e3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22217/thumb/hzBuuMFM_400x400.jpg?1641198854',
            'coingeckoId': 'metacash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GNLR',
            'name': 'Gods and Legends',
            'address': '0x2e2b887c76cc1B5c5Ef27c6361101BFd57E0bB98',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18310/thumb/IGXvGf5rPk7a.png?1631508760',
            'coingeckoId': 'gods-and-legends',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XLN',
            'name': 'LunaOne',
            'address': '0x2e2EA48C9412E0ABb2D6accCeC571C6b6411725a',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28227/thumb/input-onlinepngtools.png?1668502565',
            'coingeckoId': 'lunaone',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PUNCH',
            'name': 'Punch',
            'address': '0x2e25eA4a99Cfed65d1A6804Fa7394F5b44C69524',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19911/thumb/punch.jpeg?1636107627',
            'coingeckoId': 'punch',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOV',
            'name': 'LoveChain',
            'address': '0x2e01A3Df69E387E769cC0429f697fD207c02e2f0',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13023/thumb/lovechain.png?1661408131',
            'coingeckoId': 'lovechain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LORD',
            'name': 'Overlord',
            'address': '0x2daf1a83Aa348afbcbC73F63BB5Ee3154d9F5776',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18204/thumb/qkgkBYw.png?1630974966',
            'coingeckoId': 'overlord',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MSRT',
            'name': 'Moonseen Rose Token',
            'address': '0x2dF28d35EbcE1cb05aDe0e341e62052dC3d4bbC5',
            'decimals': 8,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WHE',
            'name': 'Worthwhile',
            'address': '0x2dECB6d8789777910cA5e425370Bc9b6e5Eb7B9c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19056/thumb/whe.jpg?1634265192',
            'coingeckoId': 'worthwhile',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BVC',
            'name': 'Crypto Chip',
            'address': '0x2dEA12c4f08074032a9997F0a199b04aF0fC0392',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25829/thumb/LOGO.png?1654064740',
            'coingeckoId': 'crypto-chip',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MANY',
            'name': 'Manyswap',
            'address': '0x2dD6c9c5BCD16816226542688788932c6e79A600',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14660/thumb/QggrCvFc_400x400.jpg?1617610823',
            'coingeckoId': 'manyswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRUST',
            'name': 'Trustworks',
            'address': '0x2dBd86A424a815AD2A08a2D8dAE07007fed6Dc91',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14656/thumb/trustworks.PNG?1617593637',
            'coingeckoId': 'trustworks',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FORTUNE',
            'name': 'FORTUNEUM',
            'address': '0x2d716831D82d837C3922aD8c10FE70757b5814FE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28055/thumb/IMG_20221101_002714_180.jpg?1667725623',
            'coingeckoId': 'fortuneum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAUDISHIB',
            'name': 'SAUDI SHIBA INU',
            'address': '0x2d6F3Dc5b202ccd91Db114B592872BCA32a7e292',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26708/thumb/FvmN7Wa.png?1659666550',
            'coingeckoId': 'saudi-shiba-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TFF',
            'name': 'Tutti Frutti',
            'address': '0x2d69c55baEcefC6ec815239DA0a985747B50Db6E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x2d69c55baecefc6ec815239da0a985747b50db6e.png',
            'coingeckoId': 'tutti-frutti-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MONEY',
            'name': 'MoneyTree',
            'address': '0x2d5b21074D81Ae888c01722ec0657f20521be893',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19287/thumb/money.png?1639913277',
            'coingeckoId': 'moneytree',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOOBR',
            'name': 'LooBr',
            'address': '0x2d537cc7a9aA092ca3dD1Ee542c0B73560cB14f0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26652/thumb/LooBr_logo_200x200_1_1.png?1659408566',
            'coingeckoId': 'loobr',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ROEMP',
            'name': 'Rise Of Empire',
            'address': '0x2d2526186598F150f7c94c3dd5A2Ef6e83DF0Ef7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26460/thumb/photo_2022-07-14_23-44-12.jpg?1658132015',
            'coingeckoId': 'rise-of-empire',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EBUSD',
            'name': 'EarnBUSD',
            'address': '0x2ce6D3B1dDd7B263c118CEF2E50b03eA2A28eC0d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17686/thumb/EarnBUSD_200.png?1628846310',
            'coingeckoId': 'earnbusd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GLD',
            'name': 'Goldenzone',
            'address': '0x2cde61622C61274e714ec7933A3C2e614F5DE44e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27654/thumb/logo.png?1665099227',
            'coingeckoId': 'goldenzone',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PIZZA',
            'name': 'PizzaSwap',
            'address': '0x2cc26dd730F548dc4ac291ae7D84a0C96980d2cB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14029/thumb/pizzaswap_logo.png?1613919303',
            'coingeckoId': 'pizzaswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ACCEL',
            'name': 'Accel Defi',
            'address': '0x2caCe984Dab08bd192A7fD044276060CB955dD9C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x2cace984dab08bd192a7fd044276060cb955dd9c.png',
            'coingeckoId': 'accel-defi',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'LBLOCK',
            'name': 'Lucky Block',
            'address': '0x2cD96e8C3FF6b5E01169F6E3b61D28204E7810Bb',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23126/thumb/logo-200x200-LB-09.png?1643268144',
            'coingeckoId': 'lucky-block',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TMON',
            'name': 'Two Monkey Juice Bar',
            'address': '0x2cD24AAf0aeAbDe7f830d6719EEb8EB3837712DE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24585/thumb/Gm_8ewah_400x400.jpg?1648218167',
            'coingeckoId': 'two-monkey-juice-bar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TBTC',
            'name': ' Bitcoin',
            'address': '0x2cD1075682b0FCCaADd0Ca629e138E64015Ba11c',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x2cD1075682b0FCCaADd0Ca629e138E64015Ba11c/logo.png',
            'coingeckoId': 't-bitcoin',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CAT',
            'name': 'CATCOIN',
            'address': '0x2cA7D8566ba9FF99ACfc871930e7bc25Cd154b52',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23960/thumb/Image_from_iOS.jpg?1645790678',
            'coingeckoId': 'catcoin-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KLH',
            'name': 'Crypto Klash',
            'address': '0x2c9856BCD97782bBCD3c6477Cdf5755050907DEB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24820/thumb/logo.2fe68a84.png?1649040269',
            'coingeckoId': 'crypto-klash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LEOS',
            'name': 'Leonicorn Swap',
            'address': '0x2c8368f8F474Ed9aF49b87eAc77061BEb986c2f1',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16899/thumb/LEOS-icon.png?1629120216',
            'coingeckoId': 'leonicorn-swap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'F1T',
            'name': 'MetaF1',
            'address': '0x2c60810F96c2C7aa61405c29D812173447D48245',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26646/thumb/logo200.jpg?1659351961',
            'coingeckoId': 'metaf1',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NEKO',
            'name': 'Sakura Neko',
            'address': '0x2c5f1769B819B5e70AA7106c989D38Ad71ba2546',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19765/thumb/neko.jpeg?1635833808',
            'coingeckoId': 'sakura-neko',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PEOPLE',
            'name': 'ConstitutionDAO',
            'address': '0x2c44b726ADF1963cA47Af88B284C06f30380fC78',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x2c44b726ADF1963cA47Af88B284C06f30380fC78.png',
            'coingeckoId': null,
            'listedIn': [
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'TOSHINORI',
            'name': 'Toshinori Inu',
            'address': '0x2c3FfA02D582a08e27289A3FE67D79c1834A87ef',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21068/thumb/2Dp0LWna_400x400.jpg?1638279103',
            'coingeckoId': 'toshinori-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLOH',
            'name': 'Halloween Floki',
            'address': '0x2c0e76DadE015bc390a13c1B80CC1bAFd9edD326',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27615/thumb/1889d663-2d57-47c9-9f48-f8362abe360e.png?1664854997',
            'coingeckoId': 'halloween-floki',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CROX',
            'name': 'Crox Token',
            'address': '0x2c094F5A7D1146BB93850f629501eB749f6Ed491',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0x2c094f5a7d1146bb93850f629501eb749f6ed491/bf3fed912b54e817baeb685894c97c19.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TROLLER',
            'name': 'The Troller Coin',
            'address': '0x2bfc8fbECBE66E0d27E11ed9e2f66ACDcd058D17',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19317/thumb/13048.png?1635028360',
            'coingeckoId': 'the-troller-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QDAO',
            'name': 'Q DAO Governance v1 0',
            'address': '0x2bf5b0Df27F31388D5b825B39Bd752BEd6C7f7e9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/8599/thumb/QDAO_logo_white_black.png?1562131656',
            'coingeckoId': 'q-dao-governance-token-v1-0',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTB',
            'name': 'MetaBomb',
            'address': '0x2baD52989Afc714C653da8e5C47bF794A8f7b11D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25213/thumb/token-icon.png?1650869814',
            'coingeckoId': 'metabomb',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DECA',
            'name': 'DecaSwap',
            'address': '0x2ba63e81CF28DC82e81A6b31516323FFED2f3A25',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26845/thumb/deca_200px.png?1660395788',
            'coingeckoId': 'decaswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASIX+',
            'name': 'AsixPlus',
            'address': '0x2bF4BE7C4520C41d012EB09a034179E03b898534',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25323/thumb/logo_%282%29.png?1651222441',
            'coingeckoId': 'asixplus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XTRA',
            'name': 'Xtra Fund',
            'address': '0x2bD5fF741F267C34E9736353e5ceC600EEC73473',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21664/thumb/16155.png?1639702871',
            'coingeckoId': 'xtra-fund',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOVEY',
            'name': 'Movey',
            'address': '0x2b511AA476213E9081Dd6A59a3739f0CB9d01162',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25309/thumb/uh6VZ8b.png?1651212010',
            'coingeckoId': 'movey',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SMD',
            'name': 'SMD Coin',
            'address': '0x2b31b83D2a960d648e9c8d3B84dF5452c80aB947',
            'decimals': 10,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17760/thumb/smd.PNG?1629186291',
            'coingeckoId': 'smd-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TOO',
            'name': 'TOO',
            'address': '0x2b25f930786e7B91B61292C15095DecD2AAecd4f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19207/thumb/TOO_coin_too_token_200x200_transparent.png?1634844100',
            'coingeckoId': 'too-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUFLOKI',
            'name': 'Buff Floki',
            'address': '0x2b13058002970071CeB1e682793d7096220eAe11',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22585/thumb/QhM6uGv9_400x400.jpg?1642143540',
            'coingeckoId': 'buff-floki',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METAUFO',
            'name': 'MetaUFO',
            'address': '0x2ad7F18DcFA131e33411770A9c6c4fe49b187Bc2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24351/thumb/KdWInHx.png?1647433523',
            'coingeckoId': 'metaufo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TCT',
            'name': 'Top Coin',
            'address': '0x2ac6E39C37e6Ab10158eA03d5c96548a3861E0C7',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16971/thumb/TopCoin_logo_remove200x200.png?1625812220',
            'coingeckoId': 'top-coin-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CIOTX',
            'name': 'Crosschain IOTX',
            'address': '0x2aaF50869739e317AB80A57Bf87cAA35F5b60598',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18331/thumb/iotx.PNG?1631579018',
            'coingeckoId': 'crosschain-iotx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFEBANK',
            'name': 'SafeBank',
            'address': '0x2aB90FBb649bA3DDD4269764eb2975d8F32b7b5a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15367/thumb/SnCqyhNo_400x400.jpg?1620655318',
            'coingeckoId': 'safebank',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASSA',
            'name': 'ASSARA',
            'address': '0x2a988E52baC4D22F3adbe1d5191CF3D240F01876',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25570/thumb/assa.png?1652607352',
            'coingeckoId': 'assara',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '0XW',
            'name': '0xWallet Token',
            'address': '0x2a92467E6b9fa6191f3290D21a221D6664865775',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26863/thumb/logo_200.png?1660532675',
            'coingeckoId': '0xwallet-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BX',
            'name': 'BlockXpress',
            'address': '0x2a6788Bf2354EBC4940656D4721AFa0aD0f25F6B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26765/thumb/200x200.jpg?1660038252',
            'coingeckoId': 'blockxpress',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALT',
            'name': 'Allspot',
            'address': '0x2a6677F132D4aB9c9F8d6a72113094232eA1F830',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25349/thumb/Allspot_200.png?1651304367',
            'coingeckoId': 'allspot',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FRZW',
            'name': 'FRZSwap',
            'address': '0x2a5dFE9EDd6D1431eecEA70cBb1635Ba88347BaD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27701/thumb/Logo_.png?1665307064',
            'coingeckoId': 'frzswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'META',
            'name': 'META GROW',
            'address': '0x2a5E3D7570425A7e7D0E7722C352d8d2b6945c7C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20645/thumb/RbRriQ5I_400x400.jpg?1637480536',
            'coingeckoId': 'meta-grow',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OCC',
            'name': 'OccamFi',
            'address': '0x2a4DFfa1Fa0F86CE7f0982F88Aecc199FB3476bc',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14801/thumb/occfi.PNG?1618531140',
            'coingeckoId': 'occamfi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CORIS',
            'name': 'CorgiSwap',
            'address': '0x2a2cD8b1F69EB9dda5d703b3498d97080C2F194F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21355/thumb/FAVICON_CORGISWAPNEWS_200x200.jpg?1639000264',
            'coingeckoId': 'corgiswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUCKYDOGE',
            'name': 'LuckyDoge',
            'address': '0x2a11BD0d1e928C98570d85c72398Dd96802705Aa',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25253/thumb/200.png?1651040520',
            'coingeckoId': 'luckydoge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLOKIS',
            'name': 'FlokiSwap',
            'address': '0x2Fb09B8749A3A13eEfA3fd4e643Fc44DbDbA5B39',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19370/thumb/flokis.png?1635133449',
            'coingeckoId': 'flokiswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHAINCADE',
            'name': 'ChainCade',
            'address': '0x2FFEE7b4DF74F7C6508A4Af4D6D91058dA5420D0',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17001/thumb/chaincade.png?1637045853',
            'coingeckoId': 'chaincade',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JAIHO',
            'name': 'Jaiho Crypto',
            'address': '0x2FD2799e83A723B19026A979899DfB70bBf6Bf6b',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20311/thumb/jaiho.PNG?1636842000',
            'coingeckoId': 'jaiho-crypto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MARSH',
            'name': 'Unmarshal',
            'address': '0x2FA5dAF6Fe0708fBD63b1A7D1592577284f52256',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x5a666c7d92e5fa7edcb6390e4efd6d0cdd69cf37.png',
            'coingeckoId': 'unmarshal',
            'listedIn': [
                'coingecko',
                '1inch',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NRB',
            'name': 'Neorbit',
            'address': '0x2F725CB7B6827bB4B02dB1369B1E4c6a33e5633d',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28042/thumb/neorbit_logo_cg.png?1667202543',
            'coingeckoId': 'neorbit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AFRO',
            'name': 'Afrostar',
            'address': '0x2F4e9c97aAFFD67D98A640062d90e355B4a1C539',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19784/thumb/afrostar.PNG?1635848538',
            'coingeckoId': 'afrostar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TAKO',
            'name': 'Tako',
            'address': '0x2F3391AeBE27393aBa0a790aa5E1577fEA0361c2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16522/thumb/logo192.png?1624330730',
            'coingeckoId': 'tako-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EAGON',
            'name': 'EagonSwap',
            'address': '0x2F25d402829cA4085B8Ea4D3BC68Bf203F5a9faB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20431/thumb/IMG-20210912-WA0003.jpg?1637035180',
            'coingeckoId': 'eagonswap-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CLY',
            'name': 'Clytie',
            'address': '0x2Ea96AA00b2A07A236A65118Ab6C377169BB5db7',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20811/thumb/IMG_20211104_182317_937.png?1637709965',
            'coingeckoId': 'clytie',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NAFTY',
            'name': 'Nafty',
            'address': '0x2EED4682197834708c0ea8D11D683440Bbe104d1',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16264/thumb/logo.213aa375.png?1623539093',
            'coingeckoId': 'nafty',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AZY',
            'name': 'Azuray',
            'address': '0x2Df2Ae560CBaFA7fdAaD3301F785513a325DF4C1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25777/thumb/Azuray-AZY-logo-200x200.png?1653631767',
            'coingeckoId': 'azuray',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JOKE',
            'name': 'Joke Community',
            'address': '0x2Df0c13487Efdf4Eb7f6C042273b7AcA781b29A0',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21854/thumb/XL19JbPU_400x400.jpg?1640143814',
            'coingeckoId': 'joke-community',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AINU',
            'name': 'Ainu',
            'address': '0x2Db0d5Cb907014C67Dc201886624716fb5c71123',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17022/thumb/AINU.jpg?1629342905',
            'coingeckoId': 'ainu-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARC',
            'name': 'Arcadia Token',
            'address': '0x2DEdE4b234A735cB76A00dF33588f2B8F0AA0b6a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26770/thumb/rsz_1ezgue9tq_400x400.png?1660047420',
            'coingeckoId': 'arcadia-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CatG',
            'name': 'CatGecko',
            'address': '0x2DC1700BdC5d23F4614F70Aa65B3dF3E552B470d',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x2dc1700bdc5d23f4614f70aa65b3df3e552b470d/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FALCX',
            'name': 'FalconX',
            'address': '0x2D862c9FC46608d7ff83293cEB83330D6135BE5E',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19992/thumb/L55TBQhW_400x400.jpg?1636359801',
            'coingeckoId': 'falconx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LENDA',
            'name': 'Lenda',
            'address': '0x2D7A47908d817dd359f9aBA7FEaA89c92a289c7E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22925/thumb/logo-200x200.png?1643002526',
            'coingeckoId': 'lenda',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MERGE',
            'name': 'Merge',
            'address': '0x2D5c9167fDd5c068c8fcB8992e6Af639b42FBf70',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/8769/thumb/Merge_Icon_Colored.png?1607391272',
            'coingeckoId': 'merge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RC',
            'name': 'RetroCade',
            'address': '0x2D220f7F7eA450bae821424A4cDD5fF0B4513970',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19063/thumb/200x200.jpg?1634266633',
            'coingeckoId': 'retrocade',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MHDC',
            'name': 'MahaDevCoiN',
            'address': '0x2D0872fE12F05d7518e784CbB700E6233b1b6629',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25946/thumb/IMG_4393.JPG?1654842303',
            'coingeckoId': 'mahadevcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GRUSH',
            'name': 'Gold Rush Finance',
            'address': '0x2Cd6f07Bf8A32B43Fc1d8C06fd910fd59eC7b9B7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26437/thumb/Grushlogo200.png?1658107718',
            'coingeckoId': 'gold-rush-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SCIX',
            'name': 'Scientix',
            'address': '0x2CFC48CdFea0678137854F010b5390c5144C0Aa5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18367/thumb/LBNawZoQ_400x400.jpg?1631666115',
            'coingeckoId': 'scientix',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SGPro',
            'name': 'SquidGameProtocol',
            'address': '0x2CDc833216d5f76B89e51B66297Da0bc2AC4FA7A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x2cdc833216d5f76b89e51b66297da0bc2ac4fa7a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'GREATDANE',
            'name': 'GreatDane',
            'address': '0x2CDc34B884a37aa4054C4D9fB87FBe43721817Bd',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27080/thumb/0t9XjT4T_t.jpg?1661761945',
            'coingeckoId': 'greatdane',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GENS',
            'name': 'Genshiro',
            'address': '0x2CD14cbA3F26254beeD1d78158cd2B6F91809600',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16444/thumb/genshiro.PNG?1624234640',
            'coingeckoId': 'genshiro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BARMY',
            'name': 'BscArmy',
            'address': '0x2C80bC9bFa4EC680EfB949C51806bDDEE5Ac8299',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15850/thumb/bscarmy_logo.jpg?1622126086',
            'coingeckoId': 'bscarmy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GIN',
            'name': 'Ginga Finance',
            'address': '0x2C77778C189AbAe5951AAB1100CeBd99A42307c5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20948/thumb/An27hEg.png?1638101521',
            'coingeckoId': 'ginga-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MHUNT',
            'name': 'MetaShooter',
            'address': '0x2C717059b366714d267039aF8F59125CAdce6D8c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x2C717059b366714d267039aF8F59125CAdce6D8c.png',
            'coingeckoId': 'metashooter',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'FLQ',
            'name': 'FlexQ',
            'address': '0x2C55279F5D493c179849D380Db159FA19288E6C8',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22143/thumb/fq_256.png?1640929095',
            'coingeckoId': 'flexq',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KENNEL',
            'name': 'Kennel',
            'address': '0x2C319Cde4E46F85f7a1004B9a81D4A52d896e208',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14807/thumb/kenenlsphere.jpg?1666339497',
            'coingeckoId': 'token-kennel',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GM',
            'name': 'GrowMoon',
            'address': '0x2BacbA5078611E261Dc55487E1bB9c145Df49Cc7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27038/thumb/gm.png?1661512230',
            'coingeckoId': 'growmoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RDN',
            'name': 'Ride2Earn',
            'address': '0x2B916Bdf78dAc3a3F7AE8134622147ACf3E548E4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26519/thumb/logo200.jpg?1658454352',
            'coingeckoId': 'ride2earn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SRY',
            'name': 'Serey Coin',
            'address': '0x2B618835a1eEfcBf41e33497451Ca1F3aa62f2d8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16260/thumb/serey.png?1663139968',
            'coingeckoId': 'serey-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLOKI',
            'name': 'FLOKI',
            'address': '0x2B3F34e9D4b127797CE6244Ea341a83733ddd6E4',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x2b3f34e9d4b127797ce6244ea341a83733ddd6e4/logo.png',
            'coingeckoId': 'floki-inu',
            'listedIn': [
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'DXT',
            'name': 'Dexit Network',
            'address': '0x2B2fF80c489dad868318a19FD6F258889a026da5',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17611/thumb/Dexit_Logo.png?1645084928',
            'coingeckoId': 'dexit-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EDUX',
            'name': 'Edufex',
            'address': '0x2B1B730c997D81dB2e792b47D0bC42A64ee6aA55',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18365/thumb/edufex.PNG?1631665623',
            'coingeckoId': 'edufex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOD',
            'name': 'GoldeFy',
            'address': '0x2Aaa5cb023cf458E7dFa765FD48b64Cd95995ab7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24429/thumb/RoundLogo_200x200.png?1647659024',
            'coingeckoId': 'goldefy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABYHARMONY',
            'name': 'BabyHarmony',
            'address': '0x2AB6Ed0eEf712006082c9cF583a6d12676d8f4cB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19105/thumb/BQmwDF6P_400x400.jpg?1634506588',
            'coingeckoId': 'babyharmony',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XEN',
            'name': 'XEN Crypto',
            'address': '0x2AB0e9e4eE70FFf1fB9D67031E44F6410170d00e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1667531747758_5721975039255991.png',
            'coingeckoId': 'xen-crypto',
            'listedIn': [
                'coingecko',
                'openocean'
            ]
        },
        {
            'symbol': 'APC',
            'name': 'ArenaPlay',
            'address': '0x2AA504586d6CaB3C59Fa629f74c586d78b93A025',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26425/thumb/200X200_thumb.png?1658130730',
            'coingeckoId': 'arenaplay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELONGATE',
            'name': 'ElonGate',
            'address': '0x2A9718defF471f3Bb91FA0ECEAB14154F150a385',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x2a9718deff471f3bb91fa0eceab14154f150a385.png',
            'coingeckoId': 'elongate',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'OCEANSV2',
            'name': 'Oceans Finance',
            'address': '0x2A54F9710ddeD0eBdde0300BB9ac7e21cF0E8DA5',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27652/thumb/X-kvuVQ1_400x400_%281%29.jpeg?1665050947',
            'coingeckoId': 'oceans-finance-v2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUNAT',
            'name': 'Lunatics',
            'address': '0x2A48eCe377b87ce941406657B9278b4459595E06',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26684/thumb/rsz_3-pca17g_400x400.png?1659594562',
            'coingeckoId': 'lunatics',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'LLN',
            'name': 'LunaLand',
            'address': '0x2A354f59ED1dd485129891E718865eB55ebdB8b3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19097/thumb/lunaland.PNG?1634423361',
            'coingeckoId': 'lunaland',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CLIMB',
            'name': 'Climb Token Finance',
            'address': '0x2A1d286ed5edAD78BeFD6E0d8BEb38791e8cD69d',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x2a1d286ed5edad78befd6e0d8beb38791e8cd69d.png',
            'coingeckoId': 'climb-token-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ARENA',
            'name': 'ArenaSwap',
            'address': '0x2A17Dc11a1828725cdB318E0036ACF12727d27a2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17273/thumb/1A08E0A4-6486-4D35-BC0E-436551ECC078.jpg?1627008978',
            'coingeckoId': 'arena-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SNO Token',
            'name': 'SNO',
            'address': '0x2A0da514b2281B12A9B93ff1b5ed738a91e0da22',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MKOALA',
            'name': 'Koala',
            'address': '0x2A0F5257F4bfE6c75cd58A14A0e7c4651E2160DE',
            'decimals': 0,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15904/thumb/10137.png?1622412225',
            'coingeckoId': 'koala-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '7UP',
            'name': '7up',
            'address': '0x29f350B3822F51dc29619C583AdBC9628646E315',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12953/thumb/7up-token.png?1603790389',
            'coingeckoId': '7up',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KOMBAT',
            'name': 'Crypto Kombat',
            'address': '0x29e0a541c368528009715c03558C1EC4b8D0aeEE',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15147/thumb/Kombat-token-logo-200.jpg?1619859575',
            'coingeckoId': 'crypto-kombat',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FRAX',
            'name': 'Frax',
            'address': '0x29cED01C447166958605519F10DcF8b0255fB379',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DEXI',
            'name': 'Dexioprotocol V1',
            'address': '0x29b1E39A529d3B3cacEA55989594F71813e998Bb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18435/thumb/Dexi_Logo_200x200.png?1646034019',
            'coingeckoId': 'dexioprotocol-v1',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PURSE',
            'name': 'Pundi X PURSE',
            'address': '0x29a63F4B209C29B4DC47f06FFA896F32667DAD2C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x29a63f4b209c29b4dc47f06ffa896f32667dad2c.png',
            'coingeckoId': 'pundi-x-purse',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BCAKE',
            'name': 'Burnt Cake',
            'address': '0x29FA9CCED410c5F62Ec503019FcC8eD5B1be59Fd',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21000/thumb/Untitled-design-6.png?1638177739',
            'coingeckoId': 'burnt-cake',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CONC',
            'name': 'Concrete Codes',
            'address': '0x29Db672f68C80854F5F9C023596EAb2D9841d2E6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23356/thumb/concrete_codes.PNG?1643927232',
            'coingeckoId': 'concrete-codes',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SSR',
            'name': 'Star Ship Royal',
            'address': '0x29D266B5d6CdC0Cd0a775F18FFf0176842D4B1D2',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20508/thumb/3wmqZyF.png?1637152888',
            'coingeckoId': 'star-ship-royal',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FICO',
            'name': 'Fish Crypto',
            'address': '0x29CABF2a1E5De6f0ebC39ca6Fe83c687FE90fB6c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22627/thumb/fico.png?1642336838',
            'coingeckoId': 'fish-crypto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DRUN',
            'name': 'Doge Run',
            'address': '0x29B86eCb7D434a5571F959F1C9A812b7Aab34894',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21986/thumb/nAAX7HYj_400x400.jpg?1640580353',
            'coingeckoId': 'doge-run',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TUR',
            'name': 'Turex',
            'address': '0x29A75Ec2d2B8a57fDc45094dC51feFD147c908d8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15539/thumb/turex-logo.png?1621155664',
            'coingeckoId': 'turex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HCT',
            'name': 'Hero Cat',
            'address': '0x29A1e54DE0fCE58E1018535d30aF77A9d2D940c4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20905/thumb/hct.png?1640573431',
            'coingeckoId': 'hero-cat-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WATER',
            'name': 'Water Reminder',
            'address': '0x299A6C70893268E8a81D3492F1ae46f37D982C0A',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27179/thumb/rsz_owp0qhx8_400x400.png?1662419767',
            'coingeckoId': 'water-reminder',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOUL',
            'name': 'Phantasma',
            'address': '0x298Eff8af1ecEbbB2c034eaA3b9a5d0Cc56c59CD',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/4130/thumb/phantasma.png?1548331035',
            'coingeckoId': 'phantasma',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SLYR',
            'name': 'RO Slayers',
            'address': '0x2988810A56eDCf3840871695D587A5AFF280D37b',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20016/thumb/roslayers.PNG?1636411077',
            'coingeckoId': 'ro-slayers',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WOD',
            'name': 'World of Defish',
            'address': '0x298632D8EA20d321fAB1C9B473df5dBDA249B2b6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22126/thumb/Logo.jpg?1640869389',
            'coingeckoId': 'world-of-defish',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PLUT',
            'name': 'Plutos Network',
            'address': '0x2984F825Bfe72e55e1725D5c020258E81ff97450',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16585/thumb/82007275.png?1624512000',
            'coingeckoId': 'plutos-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TDG',
            'name': 'ToyDOGE',
            'address': '0x298499f46FbbD468cA1Ec7DE1e29ADfde2E5b373',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x298499f46fbbd468ca1ec7de1e29adfde2e5b373/logo.png',
            'coingeckoId': 'toydoge',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DUEL',
            'name': 'Duel Network',
            'address': '0x297817CE1a8De777e7ddbed86C3B7f9Dc9349f2c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18061/thumb/duel.PNG?1630372398',
            'coingeckoId': 'duel-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PALM',
            'name': 'PalmSwap',
            'address': '0x29745314B4D294B7C77cDB411B8AAa95923aae38',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27604/thumb/22000.png?1664782743',
            'coingeckoId': 'palmswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLOKI',
            'name': 'Mars Floki Inu',
            'address': '0x2969FAC8eaBBB3CbDac56778323930BbC90E471C',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22123/thumb/logo_2.png?1640848586',
            'coingeckoId': 'mars-floki-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APAY',
            'name': 'AnonPay',
            'address': '0x2967Ef0b2BBaa15ACA50028acEBE26e8Ad9Ed4D2',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25828/thumb/20387.png?1654064548',
            'coingeckoId': 'anonpay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PEX',
            'name': 'Pexcoin',
            'address': '0x2963dCc52549573BBFBe355674724528532C0867',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20916/thumb/RC5dZfC4_400x400.jpg?1637923227',
            'coingeckoId': 'pexcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EMPIRE',
            'name': 'Empire',
            'address': '0x293C3Ee9ABaCb08BB8ceD107987F00EfD1539288',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1635075683985_06312261865855517.png',
            'coingeckoId': 'empire-token',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'GLA',
            'name': 'Galaxy Adventure',
            'address': '0x292Bb969737372E48D97C78c19B6a40347C33B45',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18494/thumb/logo1_%285%29.png?1632207841',
            'coingeckoId': 'galaxy-adventure',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOLDIER',
            'name': 'Space Soldier',
            'address': '0x2921872530F53eb8E6fC388676B141EF41Ee2d4e',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15575/thumb/F63vqcy.png?1621235076',
            'coingeckoId': 'space-soldier',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LZN',
            'name': 'Luzion Protocol',
            'address': '0x291C4e4277F8717e0552D108dBd7f795a9fEF016',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25165/thumb/cmclznlogo200x.png?1650520729',
            'coingeckoId': 'luzion-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BPAD',
            'name': 'BlokPad',
            'address': '0x29132062319AA375e764Ef8ef756F2B28c77a9C9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23309/thumb/bpad.PNG?1643752909',
            'coingeckoId': 'blokpad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MUU',
            'name': 'MUU',
            'address': '0x2900e6b68658128784B9a1de242F811d938d8bA7',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25620/thumb/muu.jpg?1662436796',
            'coingeckoId': 'muu-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FAME',
            'name': 'Fame MMA',
            'address': '0x28cE223853D123b52C74439B10b43366d73Fd3B5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25267/thumb/FJTwuXxE_400x400.jpg?1651066664',
            'coingeckoId': 'fame-mma',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TLNT',
            'name': 'TalentCoin',
            'address': '0x28Fa624E706015E84F740232F3E8B90e466AEeC4',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23098/thumb/200pngtrans.png?1643184369',
            'coingeckoId': 'talentcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUNG',
            'name': 'LunaGens',
            'address': '0x28B9aed756De31B6b362aA0f23211D13093EBb79',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26592/thumb/4-FAECD18-620-B-421-E-A27-E-70-D58-DDCE11-B.png?1658972100',
            'coingeckoId': 'lunagens',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FCN',
            'name': 'Feichang Niu',
            'address': '0x289f347aC469bc1B7359ED95C87c75Ac2c3EB16F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17476/thumb/logo_-_2021-08-02T172142.650.png?1627896154',
            'coingeckoId': 'feichang-niu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CPSP',
            'name': 'Crypto Swap',
            'address': '0x289A72776D457556c5D71b70a5C0F048c338244e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26571/thumb/IMG_20220725_001821_239.jpg?1665647579',
            'coingeckoId': 'crypto-swap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MNSTRS',
            'name': 'Block Monsters',
            'address': '0x287Db351d5230716246CfB46AF8153025eDa6A0a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18248/thumb/MNSTRS_coingecko.png?1631134658',
            'coingeckoId': 'block-monsters',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHAG',
            'name': 'SHAGGY TOKEN',
            'address': '0x287C2fB71F034F089f24baB8B58b6F7653febe09',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26758/thumb/SHAG-200.png?1660034457',
            'coingeckoId': 'shaggy-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALPINE',
            'name': 'Alpine F1 Team Fan Token',
            'address': '0x287880Ea252b52b63Cc5f40a2d3E5A44aa665a76',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23717/thumb/18112.png?1645154685',
            'coingeckoId': 'alpine-f1-team-fan-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BIGETH',
            'name': 'BIG ETH',
            'address': '0x2866e9546Be562301046206824ab33283983C6dB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19868/thumb/Bigethlogo.png?1636080394',
            'coingeckoId': 'big-eth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIB',
            'name': 'SHIBA INU',
            'address': '0x2859e4544C4bB03966803b044A93563Bd2D0DD4D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0x2859e4544c4bb03966803b044a93563bd2d0dd4d.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'HUSL',
            'name': 'The HUSL',
            'address': '0x284AC5aF363BDe6eF5296036aF8fb0e9Cc347B41',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18619/thumb/W8ZIHKU.png?1650437671',
            'coingeckoId': 'the-husl',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SALT',
            'name': 'Salt Token',
            'address': '0x2849b1aE7E04A3D9Bc288673A92477CF63F28aF4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x2849b1ae7e04a3d9bc288673a92477cf63f28af4.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'WMF',
            'name': 'Whale Maker Fund',
            'address': '0x28470aDc7FeF2e29E38632d8C17748F53C04e2E0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27433/thumb/IMG_20220923_042845_107.jpg?1663926656',
            'coingeckoId': 'whale-maker-fund',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RPG',
            'name': 'CryptoRPG',
            'address': '0x284643d9df25551A85D68eF903E59f8Ef90Bec01',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24015/thumb/logo.png?1646031270',
            'coingeckoId': 'cryptorpg',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWDAO',
            'name': 'Super Whale DAO',
            'address': '0x284189F2804D779380377a6583F2Bc4dfA034cbE',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23864/thumb/kBgO5aB.jpeg?1645596924',
            'coingeckoId': 'super-whale-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DTNG',
            'name': 'DTNG',
            'address': '0x28337d750194c17769bf31324512Ca4E217174Fa',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24128/thumb/dtng.png?1646467707',
            'coingeckoId': 'dtng',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METAQ',
            'name': 'MetaQ',
            'address': '0x2824d8ecDED273E296CA57d583d80614093C87d4',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24584/thumb/PoQiwrce8gcDf9KxXDk_fU9WEP7ljoPRJnYounF7pgJi-_Jnt3AFXQqB1lLtKDiezguUYEGvntBGCKMCB22Cecf5AcU9bbFV38Sdihravj6x65pVunbXmrl39hyfqQAUyjum_l5KqJgUcsTtLJTBqdpLiAmcg_igURSkEaef_APdfkYepHZ0tJh8TglKgCzLpAle_F-aNMfmAX4.jpg?1648218007',
            'coingeckoId': 'metaq',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BNBBACK',
            'name': 'BNBBack',
            'address': '0x280EbB7c9F2C90Ac6dD136516598a2f9efe70507',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23890/thumb/MxdOnJKe_400x400.jpg?1645606314',
            'coingeckoId': 'bnbback',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RD',
            'name': 'RocketDoge',
            'address': '0x27eb4587783F2744c489aD2e64269A2e86daeB80',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16300/thumb/banner.png?1623683766',
            'coingeckoId': 'rocketdoge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CARMA',
            'name': 'Carma Coin',
            'address': '0x27e89d357957cE332Ff442DB69F4b476401BbBc5',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15955/thumb/Color_Mark_Only.png?1622516756',
            'coingeckoId': 'carma-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DRUG',
            'name': 'DopeWarz',
            'address': '0x27e2A0E643c7f17959F84C345d2123B77bbd412c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21215/thumb/1s6XP8k.png?1638659369',
            'coingeckoId': 'dopewarz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GEM',
            'name': 'GEM',
            'address': '0x27de370Fe0498307E91047C9a60051d53b92114a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SUNEKU',
            'name': 'Suneku',
            'address': '0x27d51bcA0B145b3F701178c3Da5b66cDF66bD04E',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26672/thumb/Logo_%286%29.png?1659512761',
            'coingeckoId': 'suneku',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BERN',
            'name': 'Bernard',
            'address': '0x27d0408A868Cf4E89B37D20b5E32888dcED95BCB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16827/thumb/Bernard.png?1625210613',
            'coingeckoId': 'bernard',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OPEN',
            'name': 'OpenWorld',
            'address': '0x27a339d9B59b21390d7209b78a839868E319301B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19416/thumb/open-world-icon-logo-favicon.png?1648527433',
            'coingeckoId': 'cryptowar-xblade',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'LEON',
            'name': 'Leonicorn LEON',
            'address': '0x27E873bee690C8E161813DE3566E9E18a64b0381',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23714/thumb/leon-logo-square.png?1645436145',
            'coingeckoId': 'leon-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWC',
            'name': 'Shiba World Cup',
            'address': '0x27DCC73CbBbe57d006303316dD3e91A0D5d58eeA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28054/thumb/logo.jpg?1667287324',
            'coingeckoId': 'shiba-world-cup',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLOW',
            'name': 'BlowUP',
            'address': '0x27C5b6CFD52f82a3428996C6FDdD0173E29B7064',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18962/thumb/Blowup-2.png?1634025971',
            'coingeckoId': 'blowup',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WGICT',
            'name': 'Wrapped  GICT',
            'address': '0x27B2D695eF01d10EE96582a23db201B0Aa338639',
            'decimals': 8,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CUMMIES',
            'name': 'CumRocket',
            'address': '0x27Ae27110350B98d564b9A3eeD31bAeBc82d878d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x27ae27110350b98d564b9a3eed31baebc82d878d.png',
            'coingeckoId': 'cumrocket',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'CBD',
            'name': 'Coinbond',
            'address': '0x27AA68F36B9e5220B6D57521CEDf97fFA5B490E0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27754/thumb/Coinbond_logo_0101.png?1665654209',
            'coingeckoId': 'coinbond',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VITE',
            'name': 'Vite',
            'address': '0x2794DAD4077602eD25A88d03781528D1637898B4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/4513/thumb/Vite.png?1558014583',
            'coingeckoId': 'vite',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CATCHY',
            'name': 'Catchy',
            'address': '0x2789604Fe261ADC1aED3927f41277D8bFfe33C36',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20252/thumb/Catchy-200x200.png?1636701403',
            'coingeckoId': 'catchy',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'ABB',
            'name': 'Astro Babies',
            'address': '0x277aE79C42c859cA858d5A92C22222C8b65c6D94',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28158/thumb/Untitled_design.png?1668162808',
            'coingeckoId': 'astro-babies',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REV',
            'name': 'Revolution',
            'address': '0x276B440fdB4C54631C882caC9e4317929e751FF8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1642601141187_07998671988025308.jpg',
            'coingeckoId': 'revolution',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SQM',
            'name': 'Squid Moon',
            'address': '0x2766CC2537538aC68816B6B5a393fA978A4a8931',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20560/thumb/squidmoon-logo.png?1637212036',
            'coingeckoId': 'squid-moon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PLASTIK',
            'name': 'Plastiks',
            'address': '0x2764be4756fEc8De911D8D37efE4AE8AFF178254',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21212/thumb/plastik.png?1660904251',
            'coingeckoId': 'plastiks',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHT',
            'name': 'Charlie Finance',
            'address': '0x275b686A5c7312E50299b1c64507c90eE8a381A0',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23333/thumb/LusnySJq_400x400.jpg?1643881234',
            'coingeckoId': 'charlie-finance',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'POL',
            'name': 'Polars',
            'address': '0x273a4fFcEb31B8473D51051Ad2a2EdbB7Ac8Ce02',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24220/thumb/alpha_logo_polars.png?1646919342',
            'coingeckoId': 'polars',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MEMELON',
            'name': 'Meme Elon Doge Floki',
            'address': '0x273971059E0082AAc306A78acC67Ac01551B565F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25587/thumb/SggRCvQ.png?1652768519',
            'coingeckoId': 'meme-elon-doge-floki',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'YAG',
            'name': 'Yaki Gold',
            'address': '0x2722c9db0Fc6818DC9DD3A01254Afc3804438b64',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18030/thumb/V6wG7jI.png?1630295335',
            'coingeckoId': 'yaki-gold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'READ',
            'name': 'Reach DAO',
            'address': '0x271b446D0C2230F59A7C5cC4B61cb84D7e4BD0DD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28275/thumb/4cb31e07-9b23-4e2d-82d7-2fe3e3dc8887.png?1669079744',
            'coingeckoId': 'reach-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFE',
            'name': 'SpaceXliFe',
            'address': '0x271C48bb9b0dF4b604184Ce22D436E271d42fa61',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21721/thumb/pWUDuKr7_400x400.jpg?1639946837',
            'coingeckoId': 'spacexlife',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VRAP',
            'name': 'VeraSwap',
            'address': '0x271C418B045d05A1D52c6bF849d47b5B5B4d769e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14324/thumb/2fde7Uc2_400x400.png?1615428194',
            'coingeckoId': 'veraswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MMA',
            'name': 'MMACoin',
            'address': '0x270A84DCdfb305cC68be21e767Ef11Aac5Dc5cF0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18647/thumb/logo-main.png?1632754563',
            'coingeckoId': 'mmacoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TSX',
            'name': 'TradeStars',
            'address': '0x270388e0CA29CFd7C7E73903D9d933a23D1BAB39',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0x270388e0ca29cfd7c7e73903d9d933a23d1bab39.png',
            'coingeckoId': 'tradestars',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'KSW',
            'name': 'KillSwitch',
            'address': '0x270178366a592bA598C2e9d2971DA65f7bAa7C86',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20215/thumb/logo_%2824%29.png?1636670633',
            'coingeckoId': 'killswitch',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHIHUA',
            'name': 'Chihua',
            'address': '0x26fF6D16549A00BA8b36ce3159b5277E6e798d18',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15655/thumb/chihua_token_icon_200x200.png?1622129847',
            'coingeckoId': 'chihua-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '3QT',
            'name': '3QT',
            'address': '0x26e0AcB5C0f17cB13658820a7457De91d9439F94',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26941/thumb/3qt_logo_200x200_transparent.png?1660913343',
            'coingeckoId': '3qt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CGS',
            'name': 'CougarSwap Token',
            'address': '0x26d88B1E61E22Da3f1A1BA95A1bA278f6FCef00b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0x26d88b1e61e22da3f1a1ba95a1ba278f6fcef00b/014e7b303e135eb53667f4cd9e78751e.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DSHARE',
            'name': 'Dibs Share',
            'address': '0x26d3163b165BE95137CEe97241E716b2791a7572',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22194/thumb/dshares.png?1641191230',
            'coingeckoId': 'dibs-share',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ZLDA',
            'name': 'Zelda Inu',
            'address': '0x26a7546c8f5e5f706cb598CAA68134f6eCf8d657',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22185/thumb/Transparent-1-768x768.png?1641187711',
            'coingeckoId': 'zelda-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KAKA',
            'name': 'KAKA NFT World',
            'address': '0x26a1BdFa3bb86b2744c4A42EBfDd205761d13a8a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20877/thumb/10768.png?1637818350',
            'coingeckoId': 'kaka-nft-world',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TBAKE',
            'name': 'BakeryTools',
            'address': '0x26D6e280F9687c463420908740AE59f712419147',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15184/thumb/tbakelogo-1.png?1620034899',
            'coingeckoId': 'bakerytools',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELFT',
            'name': 'Elfworld',
            'address': '0x26B9A652e7D45fFF08f2229a835862cDC811e54A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25308/thumb/L-gWeX0PUsG32TXHRZC5k4sClQWI3EMJ5WCMCvklBbNq0dAlGe2DRnqmch_oUyFheOJS1zhxFGAoByR1bGwBNUDACZdn29vcjmvrbcNuGoeY9097HcE3ZrljFFw8_BD7L77MhHBePDvYTzRhwgySxMOVmZFFmkBHhAK18R-qFIQp_zfs0eOD1uj_aKvySIN4aTPEQsV88KZJk9RP-MlKkitR8l.jpg?1651211737',
            'coingeckoId': 'elfworld',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EATER',
            'name': 'Eat to Earn',
            'address': '0x26B588749B772ca39dA0cC580E1866C6065a11Ff',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28195/thumb/cute_%281%29.png?1668333606',
            'coingeckoId': 'eat-to-earn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XMARK',
            'name': 'xMARK',
            'address': '0x26A5dFab467d4f58fB266648CAe769503CEC9580',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x26a5dfab467d4f58fb266648cae769503cec9580.png',
            'coingeckoId': 'xmark',
            'listedIn': [
                'coingecko',
                '1inch',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SHIBU',
            'name': 'Shibu Life',
            'address': '0x2690A474c68f4313603816E3Cc6576A74b56944f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19818/thumb/shibu.PNG?1635930423',
            'coingeckoId': 'shibu-life',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTSKS',
            'name': 'MetaStocks',
            'address': '0x26817C1B50F4fe54dcc2f7AdC8345D52400B89aD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27145/thumb/IMG_20220818_213242_850.png?1662083997',
            'coingeckoId': 'metastocks',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STEMX',
            'name': 'STEMX',
            'address': '0x26734ADd0650719eA29087fe5CC0AaB81b4f237D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19739/thumb/13702.png?1635813079',
            'coingeckoId': 'stemx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RPS',
            'name': 'Rps League',
            'address': '0x267022751E06d97B9eE4e5f26cc1023670BDB349',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20918/thumb/nS5mB7aP_400x400.jpg?1637924138',
            'coingeckoId': 'rps-league',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIVRS',
            'name': 'Minionverse',
            'address': '0x266A9Bc534b19369B00380a86f74612dD236a0aF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27042/thumb/MINIONVERSE_LOGO_200x200.png?1661502781',
            'coingeckoId': 'minionverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PRM',
            'name': 'Primal  OLD ',
            'address': '0x2668BAbeAB11780c516B1d3aD02011668AFF8aa0',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25357/thumb/prm.png?1651313168',
            'coingeckoId': 'primal',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'PLS',
            'name': 'iPulse',
            'address': '0x2659Cb928F6f705C1e574464361882fd6E92e1f9',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21902/thumb/Ipulse-con.png?1640234165',
            'coingeckoId': 'ipulse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QATAR',
            'name': 'QAtar',
            'address': '0x2651793Ce5Ec4d7f18322314F5FBA1bE3a8d9f0c',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28226/thumb/qatar200x200.png?1668502305',
            'coingeckoId': 'qatar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UFT',
            'name': 'UniLend Finance',
            'address': '0x2645d5f59D952ef2317C8e0AaA5A61c392cCd44d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12819/thumb/UniLend_Finance_logo_PNG.png?1602748658',
            'coingeckoId': 'unlend-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FND',
            'name': 'Rare FND',
            'address': '0x264387Ad73d19408e34b5d5E13A93174a35CEA33',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26690/thumb/200_LOGO.png?1659602105',
            'coingeckoId': 'rare-fnd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MANA',
            'name': 'Decentraland',
            'address': '0x26433c8127d9b4e9B71Eaa15111DF99Ea2EeB2f8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x26433c8127d9b4e9B71Eaa15111DF99Ea2EeB2f8.png',
            'coingeckoId': null,
            'listedIn': [
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'HOTZILLA',
            'name': 'HotZilla',
            'address': '0x26303D4f293B70529C845a14cC4cDB475baC456a',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20239/thumb/HotZilla.png?1636691774',
            'coingeckoId': 'hotzilla',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIBEV',
            'name': 'ShibaElonVerse',
            'address': '0x2629EB2eDEF0F45B9f250F4Ec934BbC8628dbaca',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22851/thumb/photo_2022-01-20-16.00.15.jpeg?1642747464',
            'coingeckoId': 'shibaelonverse',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'FCITY',
            'name': 'FreeCity',
            'address': '0x262216C8aE15C08A2BF37Fc29aE6Ee5a742f3049',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26410/thumb/FREEC%C4%B0TY_LOGO.jpeg?1657924693',
            'coingeckoId': 'freecity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SEA',
            'name': 'StarSharks SEA',
            'address': '0x26193C7fa4354AE49eC53eA2cEBC513dc39A10aa',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21711/small/12432.png?1639784107',
            'coingeckoId': 'starsharks-sea',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'META',
            'name': 'Meta BSC',
            'address': '0x26165a5a3Dd21FA528bECf3Ff7F114D00a517344',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22243/thumb/RXfj1lF_d.png?1641434287',
            'coingeckoId': 'meta-bsc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HEROES',
            'name': 'Dehero Community',
            'address': '0x261510Dd6257494eEA1DDA7618DBe8a7b87870dd',
            'decimals': 12,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17917/thumb/33b73b5a11f3391dd9ef009985308cf5.png?1629781766',
            'coingeckoId': 'dehero-community-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '777',
            'name': 'Lucky777',
            'address': '0x2600cf5213c212734AB6EaB98AD72c59B4775D80',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25938/thumb/P4UG38vF.jpg?1654764573',
            'coingeckoId': 'lucky777',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CSCT',
            'name': 'Corsac v2',
            'address': '0x25c39B6c5Ec8a9d81826F9F0E6448ceAEA1570b4',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19751/thumb/csct.png?1636035351',
            'coingeckoId': 'corsac-v2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADON',
            'name': 'Adonis  OLD ',
            'address': '0x25baE90BB709DA479A3Ca5bc98E2965EdB18A181',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26587/thumb/200x200adonis.png?1658903429',
            'coingeckoId': 'adonis',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AXL',
            'name': 'AXL INU',
            'address': '0x25b24B3c47918b7962B3e49C4F468367F73CC0E0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22191/thumb/Original_Logo-01.png?1641188792',
            'coingeckoId': 'axl-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIBAX',
            'name': 'Shiba X',
            'address': '0x25b0c0D8dcEE3051337ba9382eAD9040aB3D5531',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x25b0c0d8dcee3051337ba9382ead9040ab3d5531/logo.png',
            'coingeckoId': 'shiba-x',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ATM',
            'name': 'Atletico de Madrid',
            'address': '0x25E9d05365c867E59C1904E7463Af9F312296f9E',
            'decimals': 2,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x25E9d05365c867E59C1904E7463Af9F312296f9E/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SST',
            'name': 'SIMBA Storage',
            'address': '0x25E0C222F687510e90B7a93650483DB59418C41A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/11908/thumb/sst.png?1660381253',
            'coingeckoId': 'simba-storage-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PIP',
            'name': 'Pi Protocol',
            'address': '0x25C30340e6f9f6e521827cF03282943dA00c0ECE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27476/thumb/LOGO_%281%29.png?1664197877',
            'coingeckoId': 'pi-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TEN',
            'name': 'Teneo',
            'address': '0x25B9d4b9535920194c359d2879dB6a1382c2ff26',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23856/thumb/18320.png?1645655998',
            'coingeckoId': 'teneo',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'FORM',
            'name': 'Formation FI',
            'address': '0x25A528af62e56512A19ce8c3cAB427807c28CC19',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x25A528af62e56512A19ce8c3cAB427807c28CC19/logo.png',
            'coingeckoId': 'formation-fi',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'STB',
            'name': 'Storm Bringer',
            'address': '0x25926E600725227B61e187ae4df6D3A0e06802a4',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20707/thumb/-flagDxC_400x400.jpg?1637572980',
            'coingeckoId': 'storm-bringer-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SBTC',
            'name': 'Sweet BTC',
            'address': '0x258d3bbDb04C08353a7B7Ac44b103C4f80c1FE4C',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23465/thumb/logo_%281%29.png?1644217244',
            'coingeckoId': 'sweet-btc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MEDOC',
            'name': 'MetaDoctor',
            'address': '0x25815d4B4e40B30039F10A4CeD43a98aB8176E63',
            'decimals': 12,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23739/thumb/17996.png?1645224255',
            'coingeckoId': 'metadoctor',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KWT',
            'name': 'Kawaii Islands',
            'address': '0x257a8d1E03D17B8535a182301f15290F11674b53',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18880/thumb/8f4IyY3_%281%29.jpg?1634095004',
            'coingeckoId': 'kawaii-islands',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WALV',
            'name': 'Alvey Chain',
            'address': '0x256D1fCE1b1221e8398f65F9B36033CE50B2D497',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26964/thumb/logo200x200.png?1661079637',
            'coingeckoId': 'alvey-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REDFEG',
            'name': 'RedFeg',
            'address': '0x25574Cad6F03FFacD9D08b288e8D5d88997fb2f3',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17292/thumb/redfeg.PNG?1627253259',
            'coingeckoId': 'redfeg',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LORDZ',
            'name': 'Meme Lordz',
            'address': '0x2541Be91fE0D220fFCbe65f11d88217a87A43bDA',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17412/thumb/meme-lordz-icon-green.png?1627570797',
            'coingeckoId': 'meme-lordz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RGEN',
            'name': 'Paragen',
            'address': '0x25382Fb31e4b22E0EA09cB0761863dF5AD97ed72',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24198/thumb/TH-Y4qWF_400x400.jpg?1646863203',
            'coingeckoId': 'paragen',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VNES',
            'name': 'Vanesse',
            'address': '0x252d8F5847204960255fD0bA654848076b57D551',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27113/thumb/logo_%281%29.png?1662011642',
            'coingeckoId': 'vanesse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GRUSH',
            'name': 'Gold Rush',
            'address': '0x251Fceb0a6EdDB6E31ca3840b2542685bf835E94',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25247/thumb/GoldRush-1A.png?1651030938',
            'coingeckoId': 'gold-rush',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWTH',
            'name': 'Switcheo Token',
            'address': '0x250b211EE44459dAd5Cd3bCa803dD6a7EcB5d46C',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x250b211EE44459dAd5Cd3bCa803dD6a7EcB5d46C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BETH',
            'name': 'Binance ETH staking',
            'address': '0x250632378E573c6Be1AC2f97Fcdf00515d0Aa91B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x250632378e573c6be1ac2f97fcdf00515d0aa91b.png',
            'coingeckoId': 'binance-eth',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SDFI',
            'name': 'StingDefi',
            'address': '0x250342dD21cAE01583e8F3eDe4eB64753f665084',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14738/thumb/download_%2819%29.png?1618004015',
            'coingeckoId': 'stingdefi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTE',
            'name': 'Meta to Earn',
            'address': '0x24e2403142b9794487A494644d209BDd76210854',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26974/thumb/IMG-20220809-WA0020.jpg?1661155208',
            'coingeckoId': 'meta-to-earn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METAPETS',
            'name': 'MetaPets',
            'address': '0x24cE3d571fBcFD9D81dc0e1a560504636a4D046d',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1641309568963_3212573638393179.png',
            'coingeckoId': 'metapets',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'FIT',
            'name': 'FitScrypt',
            'address': '0x24aa6A92BE9d1C1cac5b625e650BB2b974eeE16c',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25398/thumb/photo-2022-02-26-12-48-53.jpg?1651721096',
            'coingeckoId': 'fitscrypt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BPET',
            'name': 'Binapet',
            'address': '0x24D787e9B88Cb62D74e961C1C1d78E4ee47618E5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17703/thumb/Binapet-200x200.png?1629077289',
            'coingeckoId': 'binapet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOUVRE',
            'name': 'Louvre Finance',
            'address': '0x24905b248dCEEb17b221A48eFA0d0388b3b38Fe4',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16274/thumb/Louvre_Finance_Logo_-_CoinGecko.png?1623625700',
            'coingeckoId': 'louvre-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASTRAFER',
            'name': 'Astrafer',
            'address': '0x248D995F14dEeBb179A222cd36C4767388545A62',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TRUE',
            'name': 'TrueBurn',
            'address': '0x2480334963D06Dc39dd148f504a13ef3E6D8d896',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20972/thumb/61962c17bdb496f689e0612d_Phoenix-Alpha-1_200x200.png?1638156933',
            'coingeckoId': 'trueburn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'THC',
            'name': 'Thetan Coin',
            'address': '0x24802247bD157d771b7EFFA205237D8e9269BA8A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20959/thumb/coin_ui.png?1638142381',
            'coingeckoId': 'thetan-coin',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'IGO',
            'name': 'Meta Islands',
            'address': '0x247F7Ad24A2A21BA3Ae475Fb0114528C916ea3e4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21752/thumb/igo.png?1639985502',
            'coingeckoId': 'meta-islands',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TCW',
            'name': 'TCW',
            'address': '0x2478d6c228c75EAf53c60707D0102F7bFE1aE379',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22686/thumb/tcw-token-logo.jpg?1642408586',
            'coingeckoId': 'tcw-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PAPR',
            'name': 'Paprprintr Finance',
            'address': '0x246475dF8703BE0C2bA2f8d0fb7248D95Cc1Ba26',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x246475df8703be0c2ba2f8d0fb7248d95cc1ba26.png',
            'coingeckoId': 'paprprintr-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'GODE',
            'name': 'Gode Chain',
            'address': '0x245d9f531757f83064aD808b4c9b220C703a4934',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25140/thumb/KQkiY5pe_400x400.jpg?1650433701',
            'coingeckoId': 'gode-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MR',
            'name': 'Meta Ruffy  OLD ',
            'address': '0x2456BBAD80BfAD346AecEa45fA38C81a6963132D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23490/thumb/MR_Logo.png?1665971822',
            'coingeckoId': 'meta-ruffy-old',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DNT',
            'name': 'Definder Network',
            'address': '0x2456493e757fDeedF569781F053998A72adfad03',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/2073/thumb/2aSL-Tvl_400x400.jpeg?1654659741',
            'coingeckoId': 'smartlands',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PEAK',
            'name': 'Peakmines PEAK',
            'address': '0x243A6D8189d30884eD9e45BC4A2Daec73F8ADaA0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23747/thumb/peak.png?1645274430',
            'coingeckoId': 'peakmines-peak',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HMC',
            'name': 'Hamdan Coin',
            'address': '0x2407F9136dF8dDF84fd9bDe34D48cFc32305447B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20430/thumb/hmc-300x300.png?1637034786',
            'coingeckoId': 'hamdan-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CPD',
            'name': 'CoinsPaid',
            'address': '0x2406dCe4dA5aB125A18295f4fB9FD36a0f7879A2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18092/thumb/coinspaid.PNG?1630452234',
            'coingeckoId': 'coinspaid',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CDS',
            'name': 'Crypto Development Services',
            'address': '0x23f07a1C03e7C6D0C88e0E05E79B6E3511073fD5',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21304/thumb/JhUZ3Rk.png?1638883308',
            'coingeckoId': 'crypto-development-services',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'LUSD',
            'name': 'LUSD',
            'address': '0x23e8a70534308a4AAF76fb8C32ec13d17a3BD89e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x23e8a70534308a4AAF76fb8C32ec13d17a3BD89e/logo.png',
            'coingeckoId': 'lusd',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'YCT',
            'name': 'Youclout',
            'address': '0x23e3981052d5280C658e5e18D814Fa9582BFbc9E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18808/thumb/7W5vEIz-_400x400.jpg?1633480030',
            'coingeckoId': 'youclout',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MASTER',
            'name': 'Beast Masters',
            'address': '0x23e2efA091112B85aF95Cf9195E505FD58a3DC8F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21365/thumb/Logo200x200_%2815%29.png?1639014548',
            'coingeckoId': 'beast-masters',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EXALT',
            'name': 'Exalt',
            'address': '0x23dff15157f2456d0FE8839Ae15438bA85734F8B',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25415/thumb/avatar-1671691499-0.jpg?1651734874',
            'coingeckoId': 'exalt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AURA',
            'name': 'Aura Network',
            'address': '0x23c5D1164662758b3799103Effe19cC064d897D6',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25768/thumb/LOGO-AURA-WHITE.png?1653604372',
            'coingeckoId': 'aura-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JEUR',
            'name': 'Jarvis Synthetic Euro',
            'address': '0x23b8683Ff98F9E4781552DFE6f12Aa32814924e8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15725/thumb/jEUR.png?1634046044',
            'coingeckoId': 'jarvis-synthetic-euro',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'RUYI',
            'name': 'Ruyi',
            'address': '0x23Ec2149c6A9Bea7D50C48bFe2E4542fF8A94c4c',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22855/thumb/logo200.png?1642750982',
            'coingeckoId': 'ruyi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZEFU',
            'name': 'Zenfuse',
            'address': '0x23EC58e45ac5313BCB6681F4f7827B8a8453AC45',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12796/thumb/zenfuse.jpg?1602640333',
            'coingeckoId': 'zenfuse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSCB',
            'name': 'BscBond',
            'address': '0x23E67EA4903cBE238a4f6CE5E5C35E12f28a41C3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15717/thumb/bscbond.PNG?1621589684',
            'coingeckoId': 'bscbond',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DAR',
            'name': 'Mines of Dalarnia',
            'address': '0x23CE9e926048273eF83be0A3A8Ba9Cb6D45cd978',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x23CE9e926048273eF83be0A3A8Ba9Cb6D45cd978.png',
            'coingeckoId': 'mines-of-dalarnia',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'WRB3G',
            'name': 'Web3Gold',
            'address': '0x23BD85120f192AAd5f5d7590e271F1D03993aaC8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27351/thumb/IMG_20220919_114203_341.jpg?1663656069',
            'coingeckoId': 'web3gold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DFI',
            'name': 'DfiStarter',
            'address': '0x239EC95667e37929D33066a8Df8ddC9444DbCBca',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19037/thumb/Dfistarter-LOGO-4-02.png?1634202836',
            'coingeckoId': 'defistarter',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MRS',
            'name': 'Metars Genesis',
            'address': '0x238D02eE3F80FBf5E381F049616025c186889B68',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26625/thumb/PRmUU8O1_400x400.jpeg?1659322669',
            'coingeckoId': 'metars-genesis',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SING',
            'name': 'Sing  BSC ',
            'address': '0x23894C0ce2d79B79Ea33A4D02e67ae843Ef6e563',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18845/thumb/sing_token.png?1633589456',
            'coingeckoId': 'sing-token-bsc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FSHR',
            'name': 'Fishera',
            'address': '0x237939F39061031150330bc7BAcC05ca5879ddE1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26254/thumb/200_%281%29.png?1656937223',
            'coingeckoId': 'fishera',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SLG',
            'name': 'Land Of Conquest SLG',
            'address': '0x2348b010Fa9c0Ce30Bb042D54c298a3411361a01',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26436/thumb/_SLG.png?1658107611',
            'coingeckoId': 'land-of-conquest-slg',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABYX',
            'name': 'BabyXape',
            'address': '0x234003FFa399E8bc53236AC777F4C781B10D1344',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19757/thumb/babyx.jpeg?1635827752',
            'coingeckoId': 'babyxape',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DITTO',
            'name': 'Ditto',
            'address': '0x233d91A0713155003fc4DcE0AFa871b508B3B715',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x233d91A0713155003fc4DcE0AFa871b508B3B715/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WHX',
            'name': 'WhiteX',
            'address': '0x233a010FE3ABE7B354941399A3Dc09f267bA90E0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14557/thumb/output-onlinepngtools_%282%29.png?1617002945',
            'coingeckoId': 'whitex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USTC',
            'name': 'Wrapped USTC',
            'address': '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xa47c8bf37f92abed4a126bda807a7b7498661acd.png',
            'coingeckoId': 'wrapped-ust',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SEN',
            'name': 'Senspark',
            'address': '0x23383e18dEedF460EbB918545C8b0588038B7998',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25033/thumb/Senspark_logo_200_x_200.png?1649852190',
            'coingeckoId': 'senspark',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MVEDA',
            'name': 'MedicalVeda',
            'address': '0x23316e6B09E8F4F67B95d53B4f1e59D1Fb518F29',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13069/thumb/medicalveda_new_logo_final_%281%29.png?1604894690',
            'coingeckoId': 'medicalveda',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHEESHA',
            'name': 'Sheesha Finance  BEP20 ',
            'address': '0x232FB065D9d24c34708eeDbF03724f2e95ABE768',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x232fb065d9d24c34708eedbf03724f2e95abe768.png',
            'coingeckoId': 'sheesha-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'TBAC',
            'name': 'BlockAura',
            'address': '0x2326C7395d02A8C89a9d7A0b0C1Cf159D49cE51c',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26081/thumb/20655.png?1655707151',
            'coingeckoId': 'blockaura',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FAST',
            'name': 'Fastswap  BSC ',
            'address': '0x2322AfAaC81697E770c19a58df587d8739777536',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19218/thumb/Fastswap.jpg?1634700925',
            'coingeckoId': 'fastswap-bsc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOVE',
            'name': 'MarketMove',
            'address': '0x231cF6F78620e42Fe00D0c5C3088b427F355d01c',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18148/thumb/61f2944781aa2fadbd51bade_circle.png?1643290037',
            'coingeckoId': 'marketmove',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AGN',
            'name': 'Agrinoble',
            'address': '0x2317f8C321954070b57019BfBD9A1FAE1F3C04D9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18524/thumb/logo_coingecko_%281%29.png?1632276140',
            'coingeckoId': 'agrinoble',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOGECOIN',
            'name': 'Buff Doge Coin',
            'address': '0x23125108bc4c63E4677b2E253Fa498cCb4B3298b',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18516/thumb/BUFF_KOIN.png?1632231567',
            'coingeckoId': 'buff-doge-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POSS',
            'name': 'Posschain',
            'address': '0x230f6e7904FFc156Abd8aDfd7556e56E2A358cB1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25994/thumb/Posschain-200x200.png?1655447861',
            'coingeckoId': 'posschain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DUSD',
            'name': 'Demeter USD',
            'address': '0x22fDa23aED456F2de139c6240F0776Ef031c8B6b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18459/thumb/DUSD-200.png?1632108436',
            'coingeckoId': 'demeter-usd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QTC',
            'name': 'Quick Transfer Coin',
            'address': '0x22eEf13c3CCd9a506d43FE260f7B05116AE1AF97',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26471/thumb/qtc200x200.png?1658195013',
            'coingeckoId': 'quick-transfer-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'M-X',
            'name': 'MuscleX',
            'address': '0x22e88b8AbECc7e510c98D55991c626D67cdC52Ea',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27380/thumb/musclex_logo-14.png?1663742946',
            'coingeckoId': 'musclex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HAO',
            'name': 'History Dao Token',
            'address': '0x22d224CE83103A8D218B1E6cc2A38Bd94f60f066',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ERO',
            'name': 'Eroverse',
            'address': '0x22cbd249e6c68712dA6767f1077b28C87745FA6D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21680/thumb/ero.PNG?1639713613',
            'coingeckoId': 'eroverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABYCATGIRL',
            'name': 'BabyCatGirl',
            'address': '0x22EE49aa40f33AC7dBc3D67dE2EB3C2c40e2c5eA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20265/thumb/photo_2021-11-11_05-04-52.jpg?1636704442',
            'coingeckoId': 'babycatgirl',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '0XMR',
            'name': '0xMonero',
            'address': '0x22A213852Cee93EB6d41601133414d180c5684C2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/11035/thumb/0xmnr.PNG?1587357680',
            'coingeckoId': '0xmonero',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'RC',
            'name': 'Reward Cycle',
            'address': '0x229a54Fb9De889C271380452C0483ce89b8C1e0D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19760/thumb/RC-logo-200x200-1.png?1636988853',
            'coingeckoId': 'reward-cycle',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KINGSHIBA',
            'name': 'King of Shiba',
            'address': '0x22918b2b01b1809d95CaC26B3861dE9dd5EE7806',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20089/thumb/kingofshiba.PNG?1636461833',
            'coingeckoId': 'king-of-shiba',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MSTR',
            'name': 'Monsterra',
            'address': '0x2290C6bD9560E6498dFDf10F9eCB17997CA131f2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24377/thumb/MSTR.png?1660801591',
            'coingeckoId': 'monsterra',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SCK',
            'name': 'Space Corsair Key',
            'address': '0x227a3EF4d41d0215123f3197Faa087Bf71d2236a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24371/thumb/sck.png?1647443091',
            'coingeckoId': 'space-corsair-key',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FIWA',
            'name': 'DeFiWall',
            'address': '0x22784e951971d91F46EFA64454c31872d607F25f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27810/thumb/admin-ajax.png?1665906815',
            'coingeckoId': 'defiwall',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'F5S',
            'name': 'F5 Sleep',
            'address': '0x2273ba24aC3Cbd931f39A9Df40613Ab97c5A488a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26063/thumb/F5S.jpg?1655462744',
            'coingeckoId': 'f5-sleep',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABYKISHU',
            'name': 'Baby Kishu',
            'address': '0x2270A2E12Ad55D2493c85D4D92e648741d4c045b',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16645/thumb/baby_kishu.PNG?1624589690',
            'coingeckoId': 'baby-kishu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BMINU',
            'name': 'Bill Murray Inu',
            'address': '0x226832D91e92ed8851344466f3cbf0261322EAcB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27176/thumb/JMpESsLL_400x400.jpeg?1662372374',
            'coingeckoId': 'bill-murray-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COX',
            'name': 'Coxswap',
            'address': '0x2266CFA689Da2186F307F2dd8beAfA12A4ad9709',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26374/thumb/SoXX-GZs_400x400.jpeg?1657611312',
            'coingeckoId': 'coxswap-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WCHI',
            'name': 'XAYA',
            'address': '0x22648C12acD87912EA1710357B1302c6a4154Ebc',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/2091/thumb/xaya200x200.png?1656561479',
            'coingeckoId': 'chimaera',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BGL',
            'name': 'BGL Token',
            'address': '0x225d0236Ce8C9d753dFAe9CD0265dA2d2699Ba4F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x225d0236ce8c9d753dfae9cd0265da2d2699ba4f/logo.png',
            'coingeckoId': 'bgl-token',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'VNLA',
            'name': 'Vanilla Network',
            'address': '0x22517fA2A5341453f1F9ebd5Ecb8620a90dc8E4b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13223/thumb/vanilla_network_logo.jpeg?1606352291',
            'coingeckoId': 'vanilla-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CREDI',
            'name': 'Credefi',
            'address': '0x2235e79086dd23135119366da45851c741874e5B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21396/thumb/e1QbZ4qQ_400x400.jpg?1639042835',
            'coingeckoId': 'credefi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DAWGS',
            'name': 'SpaceDawgs',
            'address': '0x222cF80A8514f8ce551C06D1b8d01DB3678688AD',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17076/thumb/twitter-facebook-Instagram-pfp.png?1646792743',
            'coingeckoId': 'spacedawgs',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BIG',
            'name': 'Bigwinner',
            'address': '0x222E0eaed3e3E91039E199E28b7F70801AFa2bc7',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22690/thumb/logo2.png?1642409366',
            'coingeckoId': 'bigwinner',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KXA',
            'name': 'Kryxivia Game',
            'address': '0x2223bF1D7c19EF7C06DAB88938EC7B85952cCd89',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21225/thumb/kxa.png?1638742914',
            'coingeckoId': 'kryxivia-game',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TLM',
            'name': 'Alien Worlds',
            'address': '0x2222227E22102Fe3322098e4CBfE18cFebD57c95',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x2222227e22102fe3322098e4cbfe18cfebd57c95.png',
            'coingeckoId': 'alien-worlds',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'JFM',
            'name': 'JustFarm',
            'address': '0x221d20Fe8996b49590513E5b5b8f9d8b0024Dc40',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19773/thumb/jf.PNG?1635838433',
            'coingeckoId': 'justfarm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WSB',
            'name': 'WallStreetBets DApp',
            'address': '0x22168882276e5D5e1da694343b41DD7726eeb288',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15093/thumb/Pe1mrDu.png?1629882480',
            'coingeckoId': 'wall-street-bets-dapp',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DBC',
            'name': 'Dhabicoin',
            'address': '0x220e6A613F00c79025d5611B73639E045B186fF8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22803/thumb/Ia0yVef.png?1642584684',
            'coingeckoId': 'dhabicoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BCPAY',
            'name': 'BCPAY FinTech',
            'address': '0x21f1ce0FCf1E9E39F8e79B7762801E8096d9f6CD',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18342/thumb/bcpay.jpg?1631601832',
            'coingeckoId': 'bcpay-fintech',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TOD',
            'name': 'TraDAO',
            'address': '0x21d5Fa5ECf2605c0E835Ae054AF9bbA0468e5951',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24270/thumb/photo_2022-03-02_23-11-22.jpg?1647175450',
            'coingeckoId': 'tradao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EGGP',
            'name': 'Eggplant Finance',
            'address': '0x21adB1c644663069e83059AC3f9d9Ca1133D29e4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15481/thumb/eggp.png?1621010694',
            'coingeckoId': 'eggplant-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DAO',
            'name': 'GameDAO',
            'address': '0x21a807b748423e5c37A914fb4Fb40850037DafDd',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17846/thumb/gamedao.PNG?1629498646',
            'coingeckoId': 'gamedao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZINU',
            'name': 'Zombie Inu',
            'address': '0x21F9B5b2626603e3F40bfc13d01AfB8c431D382F',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19516/thumb/zinu.jpeg?1635321575',
            'coingeckoId': 'zombie-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REU',
            'name': 'REU  BSC ',
            'address': '0x21Ed225dADA8130549d2403C224EB989E08ced43',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25948/thumb/kqnBJPt.png?1654842789',
            'coingeckoId': 'reu-bsc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RR',
            'name': 'DeFiHorse Rocket Race',
            'address': '0x21B4449EFf59524BF30Cc89B11C47dF5405cAde5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25546/thumb/19738.png?1652332644',
            'coingeckoId': 'defihorse-rocket-race',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MATE',
            'name': 'Mate',
            'address': '0x2198B69B36B86F250549d26D69C5957912A34Ec2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x2198b69b36b86f250549d26d69c5957912a34ec2/logo.png',
            'coingeckoId': 'mate',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BENX',
            'name': 'BlueBenx  OLD ',
            'address': '0x217ca5cE67166a3024527eE3a3289635603AED31',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26055/thumb/BENX_Token_96x96_Blue.png?1655454202',
            'coingeckoId': 'bluebenx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WETH',
            'name': 'WETH',
            'address': '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x2170ed0880ac9a755fd29b2688956bd959f933f8.png',
            'coingeckoId': 'weth',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GDS',
            'name': 'Golddoge Sachs',
            'address': '0x216A2C74fF51538D9Ae1beE4958b29C2D778cD21',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27022/thumb/IMG_20220824_194242_889.jpg?1661484378',
            'coingeckoId': 'golddoge-sachs',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EV',
            'name': 'Evai',
            'address': '0x2167AfA1C658DC5C4eC975f4aF608FF075A8b8AE',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25313/thumb/EVAI_LOGO.png?1667522856',
            'coingeckoId': 'evai-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HES',
            'name': 'Hero Essence',
            'address': '0x215Fab3842Bfa19Dc0DD7248746c0Ab72a8fE813',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19972/thumb/13992.png?1636348544',
            'coingeckoId': 'hero-essence',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZDCV2',
            'name': 'ZodiacsV2',
            'address': '0x213Fd3C787b6c452F50Fd91f14e12Ddf04A7aB4A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22589/thumb/LUl0lEMr_400x400.jpg?1642144242',
            'coingeckoId': 'zodiacsv2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ABLE',
            'name': 'Able Finance',
            'address': '0x2136cD209bB3D8E4c008ec2791B5D6790B5E33A9',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17819/thumb/aac9bfb0-f27f-48f3-9b3e-ec090e10d3e1.png?1629341559',
            'coingeckoId': 'able-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FPS',
            'name': 'MetaPlayers gg',
            'address': '0x213503534d927424A5cdf6d580E9Fd408be9337a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21725/thumb/RXvVSpD4_400x400.jpg?1639964033',
            'coingeckoId': 'metaplayers-gg',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SFMS',
            'name': 'SafeMoon Swap',
            'address': '0x212fE6ACbd2083BA0fd7c13Bc57100Ce8bf52e75',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16239/thumb/Untitled-design-5.png?1623372205',
            'coingeckoId': 'safemoon-swap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUX',
            'name': 'BUX',
            'address': '0x211FfbE424b90e25a15531ca322adF1559779E45',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x211ffbe424b90e25a15531ca322adf1559779e45.png',
            'coingeckoId': 'blockport',
            'listedIn': [
                'coingecko',
                '1inch',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MOM',
            'name': 'Mother of Memes',
            'address': '0x211Fa9E7E390c29B0AB1a9248949A0aB716c4154',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22388/thumb/bQ1PGpaR_400x400.jpg?1641790045',
            'coingeckoId': 'mother-of-memes',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'EL',
            'name': 'EL Rune  Rune Game ',
            'address': '0x210C14fbeCC2BD9B6231199470DA12AD45F64D45',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27450/thumb/el.png?1664088183',
            'coingeckoId': 'el-rune-rune-game',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EFT',
            'name': 'EternalFlow',
            'address': '0x21004b11939359E7E962Db6675d56f50353dF29C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21536/thumb/EFTLogoWP.png?1639401769',
            'coingeckoId': 'eternalflow',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DRIP',
            'name': 'Drip Network',
            'address': '0x20f663CEa80FaCE82ACDFA3aAE6862d246cE0333',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21340/thumb/11093.png?1638951857',
            'coingeckoId': 'drip-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFT',
            'name': 'APENFT',
            'address': '0x20eE7B720f4E4c4FFcB00C4065cdae55271aECCa',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x20eE7B720f4E4c4FFcB00C4065cdae55271aECCa.png',
            'coingeckoId': 'apenft',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'POK',
            'name': 'Pokmonsters',
            'address': '0x20e90eC60Ef7Ae33AC04a48d5f45dF9a15e76a01',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18615/thumb/logo200x200_%289%29.png?1632697613',
            'coingeckoId': 'pokmonsters',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHESS',
            'name': 'Tranchess',
            'address': '0x20de22029ab63cf9A7Cf5fEB2b737Ca1eE4c82A6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x20de22029ab63cf9A7Cf5fEB2b737Ca1eE4c82A6/logo.png',
            'coingeckoId': 'tranchess',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'LPDI',
            'name': 'Lucky Property Development Invest',
            'address': '0x20b1290DEdb94328705b21986485A447C63B8506',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18866/thumb/lpdi.PNG?1633644330',
            'coingeckoId': 'lucky-property-development-invest',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HON',
            'name': 'WonderHero HON',
            'address': '0x20a30a68bEa440c34f7Ca4Aa31527c9180836f86',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23632/thumb/HONOR_icon.png?1644902662',
            'coingeckoId': 'wonderhero-hon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HE',
            'name': 'Heroes   Empires',
            'address': '0x20D39a5130F799b95B55a930E5b7eBC589eA9Ed8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18903/thumb/he.png?1635385428',
            'coingeckoId': 'heroes-empires',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RXS',
            'name': 'Rune Shards',
            'address': '0x2098fEf7eEae592038f4f3C4b008515fed0d5886',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19831/thumb/rune.PNG?1635981317',
            'coingeckoId': 'rune-shards',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FUEL',
            'name': 'Jetfuel Finance',
            'address': '0x2090c8295769791ab7A3CF1CC6e0AA19F35e441A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x2090c8295769791ab7a3cf1cc6e0aa19f35e441a.png',
            'coingeckoId': 'fuel-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SHELL',
            'name': 'Meta Apes Shell',
            'address': '0x208cfEc94d2BA8B8537da7A9BB361c6baAD77272',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x208cfEc94d2BA8B8537da7A9BB361c6baAD77272.png',
            'coingeckoId': 'meta-apes-shell',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'BROWN',
            'name': 'BrowniesSwap',
            'address': '0x208FE37358d6aA767af66C4D87d5542EE2f35334',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20826/thumb/200x200-LOGO.png?1637725008',
            'coingeckoId': 'browniesswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ULTGG',
            'name': 'UltimoGG',
            'address': '0x2065E3BD318f155abE5Ad6aa263596f197112261',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17033/thumb/ULTGG-COIN-GRADIENT.png?1626142078',
            'coingeckoId': 'ultimogg',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANGEL',
            'name': 'AngelsCreed',
            'address': '0x205F93cD558aAc99c4609d0511829194B5405533',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22094/thumb/LoZsoZZf_400x400.jpg?1640795601',
            'coingeckoId': 'angelscreed',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OBROK',
            'name': 'OBRok',
            'address': '0x205AFD08ceFe438377a0abC5a20Cb4462E1a8C5C',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22078/thumb/logo_-_2021-12-29T130335.005.png?1640754224',
            'coingeckoId': 'obrok',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FUD',
            'name': 'FUDcoin Official',
            'address': '0x2046954284c631C285B59e0919BcCcd66b3BD289',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19439/thumb/xqVmqZKF_400x400.jpeg?1635223504',
            'coingeckoId': 'fudcoin-official',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TSY',
            'name': 'Token Shelby',
            'address': '0x2033f3599E070C3BECd09B0b28988A9c45a059d5',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22020/thumb/NPt7LCJ3_400x400.jpg?1640599517',
            'coingeckoId': 'token-shelby',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NEXUS',
            'name': 'Nexus Crypto Services',
            'address': '0x201Ec81532FcA95fbb45204d6764d1a9Eed08856',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19091/thumb/x-on-trans-200x200.png?1642666766',
            'coingeckoId': 'nexus-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DXSANTA',
            'name': 'Doxxed Santa',
            'address': '0x2013A45418B1fDF7d0e1FA046e6Cc27FaA290bE5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21074/thumb/photo-2021-11-29-17-28-11.jpg?1638284137',
            'coingeckoId': 'doxxed-santa',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METAL',
            'name': 'Drunk Robots',
            'address': '0x200C234721b5e549c3693CCc93cF191f90dC2aF9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24376/thumb/metal.png?1649571721',
            'coingeckoId': 'drunk-robots',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SFN',
            'name': 'Sinfinite',
            'address': '0x1ff7491fC8B3d4D479A1A022984b10186E38b50f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27991/thumb/Sinfinite-coin.png?1667292215',
            'coingeckoId': 'sinfinite',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MONSTER',
            'name': 'Monster Valley',
            'address': '0x1fE34D34EC67Ef7020874A69A9dD1fB778CF9522',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18066/thumb/logo200_%2823%29.png?1630374476',
            'coingeckoId': 'monster-valley',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATL',
            'name': 'Atlantis Loans',
            'address': '0x1fD991fb6c3102873ba68a4e6e6a87B3a5c10271',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x1fd991fb6c3102873ba68a4e6e6a87b3a5c10271.png',
            'coingeckoId': 'atlantis-loans',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'CELR',
            'name': 'CELR',
            'address': '0x1f9f6a696C6Fd109cD3956F45dC709d2b3902163',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0x1f9f6a696c6fd109cd3956f45dc709d2b3902163/1eb9c8f251098ea22a2cb30a877da86a.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CRL',
            'name': 'Crypto Rocket Launch',
            'address': '0x1f8e76F65140428Cd05EB000B975937Ce07b175b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22736/thumb/logo_%281%29.png?1642491142',
            'coingeckoId': 'crypto-rocket-launch',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FREN',
            'name': 'FrenCoin',
            'address': '0x1f8a1eFbf6247139fB9cBdb9d4deA34E3D18C20A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22752/thumb/fren-logo-200x200.png?1642572432',
            'coingeckoId': 'frencoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TETU',
            'name': 'TETURewardToken',
            'address': '0x1f681B1c4065057E07b95a1E5e504fB2c85F4625',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PANTHER',
            'name': 'PantherSwap',
            'address': '0x1f546aD641B56b86fD9dCEAc473d1C7a357276B7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15130/thumb/panther.PNG?1619764096',
            'coingeckoId': 'pantherswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZERO',
            'name': '0 exchange',
            'address': '0x1f534d2B1ee2933f1fdF8e4b63A44b2249d77EAf',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13706/thumb/0.exchange_%28logo%29.jpg?1617070530',
            'coingeckoId': 'zero-exchange',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HIDEOUS',
            'name': 'Hideous Finance',
            'address': '0x1f4105a5231e95BD087Cc28cfF30595346e1e07C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19999/thumb/nL_0yV_P_400x400.jpg?1636364127',
            'coingeckoId': 'hideous-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MINT',
            'name': 'Mint Club',
            'address': '0x1f3Af095CDa17d63cad238358837321e95FC5915',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17174/thumb/mint.PNG?1626815527',
            'coingeckoId': 'mint-club',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OKA',
            'name': 'Okaleido',
            'address': '0x1f3A0425f0d50F1c277B617374Ed6c2e95A4ca84',
            'decimals': 10,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27120/thumb/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20220830211831.png?1662016906',
            'coingeckoId': 'okaleido',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SLD',
            'name': 'Shield  SLD ',
            'address': '0x1ef6A7e2c966fb7C5403EFEFdE38338b1a95a084',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16170/thumb/SLD_TOKEN.png?1656907214',
            'coingeckoId': 'shield-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SDT',
            'name': 'StoneDAO',
            'address': '0x1eeAf13BD1b50d510D25880cC302403478db7097',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26168/thumb/photo_2022-02-28_11-20-13.png?1656328378',
            'coingeckoId': 'stonedao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIBAD',
            'name': 'Shiba Dragon',
            'address': '0x1ed3cC3490efD36E78F301523CcD169330520D3f',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21093/thumb/Logo-111.png?1638323805',
            'coingeckoId': 'shiba-dragon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AMI',
            'name': 'AMMYI Coin',
            'address': '0x1eF72a1DF5e4d165F84fc43B20D56cAA7DaD46e1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14469/thumb/AMMYI.png?1630917084',
            'coingeckoId': 'ammyi-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SCV',
            'name': 'Super CoinView',
            'address': '0x1eCEc64957A7f83F90e77bD1B1816Ab40DF4f615',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/8840/thumb/Hpio6etr_400x400.jpg?1562036775',
            'coingeckoId': 'super-coinview-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JK',
            'name': 'JK Coin',
            'address': '0x1eC58Fe5e681E35e490B5D4cBECdF42B29C1B063',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20392/thumb/14488.png?1636962392',
            'coingeckoId': 'jk-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEPI',
            'name': 'Decentralized Pirates',
            'address': '0x1eBB8947bdcd3079D910CaaF8d9ae1a57a300bE3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28008/thumb/logo_200.png?1666942255',
            'coingeckoId': 'decentralized-pirates',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIH',
            'name': 'Shih Tzu',
            'address': '0x1e8150ea46E2A7FBB795459198fBB4B35715196c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15309/thumb/shit.PNG?1620374274',
            'coingeckoId': 'shih-tzu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PWG',
            'name': 'PW GOLD',
            'address': '0x1e70f310c902edff4F6176142831b02dc68564d5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21735/thumb/logo-risorse.png?1639970927',
            'coingeckoId': 'pw-gold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZABAKU',
            'name': 'Zabaku Inu',
            'address': '0x1e5F009d4f8cA44b5FcC4963dD301Da82b4eed09',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15944/thumb/5-E31387-E-08-F6-4-A29-AC33-EC138-E09-E9-A7.jpg?1630331287',
            'coingeckoId': 'zabaku-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WGC',
            'name': 'Green Climate World',
            'address': '0x1e4fFa373d94c95717Fb83eC026b2e0e2F443bB0',
            'decimals': 16,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17776/thumb/Green_Climate.png?1629463942',
            'coingeckoId': 'green-climate-world',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ST',
            'name': 'Sacred Tails',
            'address': '0x1e4f2Ab406aa9764Ff05a9a8c8bF6B1c8B6f531F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22543/thumb/STLogo200x.png?1657688459',
            'coingeckoId': 'sacred-tails',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAT',
            'name': 'Saturna',
            'address': '0x1e446CbEa52BAdeB614FBe4Ab7610F737995fB44',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15545/thumb/dkejn0.png?1621296947',
            'coingeckoId': 'saturna',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MW',
            'name': 'Metaficial World',
            'address': '0x1e3eB1a4C1830e7f4f231D2c7752dAE004980253',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22924/thumb/Logo-Transparency-02-2.png?1643002229',
            'coingeckoId': 'metaficial-world',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CPX',
            'name': 'Cat Sphynx',
            'address': '0x1e2b832EDD325e85EAC987d3E6A95861f6280C46',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27451/thumb/fcWV8Qq.png?1664088553',
            'coingeckoId': 'cat-sphynx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAKE',
            'name': 'NFTMAKE',
            'address': '0x1e2701D1e4F11262Dad7C6F7922E3e44e14D7e40',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24914/thumb/make-icon.png?1649342362',
            'coingeckoId': 'nftmake',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KARA',
            'name': 'KaraStar',
            'address': '0x1e155e26085Be757780B45a5420D9f16a938f76b',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21744/thumb/2AEMfaC4nJimG5B.png?1639979186',
            'coingeckoId': 'karastar-kara',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADXX',
            'name': 'AnonyDoxx',
            'address': '0x1de305515a132Db0eD46E9fA2aD2804F066E43E3',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25600/thumb/1_bUfRZ1ZwuVjSi83vd_vv4Q.jpeg?1652774716',
            'coingeckoId': 'anonydoxx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PCL',
            'name': 'Peculium',
            'address': '0x1dbDf52915875f749CBAEAaf515252455b623F6e',
            'decimals': 10,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21649/thumb/peculium.jpg?1639640332',
            'coingeckoId': 'peculium-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AUR',
            'name': 'Aurum',
            'address': '0x1dEb45C74E0192D9272ADF54e9a7519C48C2bd81',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0x1deb45c74e0192d9272adf54e9a7519c48c2bd81/529104043a5a2cd92a0e1a80dcca0669.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'deUSDC',
            'name': 'deBridge USD Coin',
            'address': '0x1dDcaa4Ed761428ae348BEfC6718BCb12e63bFaa',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x1ddcaa4ed761428ae348befc6718bcb12e63bfaa_2.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'PMR',
            'name': 'Pomerium',
            'address': '0x1dC5779ed65BCc1F0A42d654444FB0Ce59D7783b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25854/thumb/pomerium-PMR_2000x2000.png?1668653725',
            'coingeckoId': 'pomerium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CORNX',
            'name': 'Chaincorn',
            'address': '0x1d9a278a0f84cb13CebF154FaF766a98F63B7b12',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24576/thumb/logo200x200.png?1648198552',
            'coingeckoId': 'chaincorn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BST',
            'name': 'Bitsten',
            'address': '0x1d89272821b3ACC245AcC1794e79A07D13C3E7E7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27544/thumb/EQBj46ow_400x400.jpeg?1664439661',
            'coingeckoId': 'bitsten',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOSS',
            'name': 'BossDao',
            'address': '0x1d79c69DD5471649a115258a7A5f1D352238FCEb',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27839/thumb/1_Zgnz_s2XuaeSDqRjsRc9zQ.png?1666057367',
            'coingeckoId': 'bossdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TOR',
            'name': 'TOR',
            'address': '0x1d6Cbdc6b29C6afBae65444a1f65bA9252b8CA83',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23609/thumb/tor.png?1644747242',
            'coingeckoId': 'tor',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ORT',
            'name': 'Omni Real Estate',
            'address': '0x1d64327C74d6519afeF54E58730aD6fc797f05Ba',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15538/thumb/photo_2021-05-12_16-25-40.png?1621155497',
            'coingeckoId': 'omni-real-estate-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ESI',
            'name': 'Evil Shiba Inu',
            'address': '0x1d42bd785092261256C29990E029b8b79611d705',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23485/thumb/output-onlinepngtools.png?1644219947',
            'coingeckoId': 'evil-shiba-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GALEON',
            'name': 'Galeon',
            'address': '0x1d0Ac23F03870f768ca005c84cBb6FB82aa884fD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24261/thumb/Logo-Galeon-Coinmarketcap.png?1647171045',
            'coingeckoId': 'galeon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CORGIB',
            'name': 'The Corgi of PolkaBridge',
            'address': '0x1cfD6813a59d7B90c41dd5990Ed99c3bf2Eb8F55',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16069/thumb/corbi.PNG?1622766692',
            'coingeckoId': 'the-corgi-of-polkabridge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MND',
            'name': 'Mind Music',
            'address': '0x1cd2528522A17B6Be63012fB63AE81f3e3e29D97',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19104/thumb/logo-main_%282%29.png?1634506312',
            'coingeckoId': 'mind-music',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CBBN',
            'name': 'CBBN',
            'address': '0x1ccb41B89933F2CD871a759d03b54b7EFA13A19C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18786/thumb/logo-White.png?1633416611',
            'coingeckoId': 'cbbn-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LSR',
            'name': 'LaserEyes',
            'address': '0x1cb9cA00538265A22E56B758026948608bA5D86F',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22597/thumb/lsr.png?1643524878',
            'coingeckoId': 'lasereyes',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LEVL',
            'name': 'Levolution',
            'address': '0x1cDEE2f21c066848A8A135E19F5f302CA29F1F69',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/7370/thumb/L_Icon_Blue_200x200.png?1618983083',
            'coingeckoId': 'levolution',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CPLT',
            'name': 'Catapult ac',
            'address': '0x1cCAbE9A0d53636770f0d5c6Ce33f797E698C9D0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26707/thumb/Logo-CPLT-200x.png?1659666433',
            'coingeckoId': 'catapult-ac',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHUMI',
            'name': 'SHUMI',
            'address': '0x1cABcd4B66d86D703866050f6430eA86b66188BB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28095/thumb/144CE478-72EA-41C1-9002-6A83400344E6.jpeg?1667537141',
            'coingeckoId': 'shumi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DMLG',
            'name': 'Demole',
            'address': '0x1c796C140de269E255372ea687EF7644BAb87935',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19195/thumb/dmlg.png?1634638943',
            'coingeckoId': 'demole',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SQUID',
            'name': 'SQUID Finance',
            'address': '0x1c3C3941aCB8A9Be35e50F086fAE6a481f7d9DF7',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x1c3c3941acb8a9be35e50f086fae6a481f7d9df7.png',
            'coingeckoId': 'squid-2',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ZD',
            'name': 'ZD',
            'address': '0x1c213179C2c08906fB759878860652a61727Ed14',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x1c213179c2c08906fb759878860652a61727ed14.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BNBX',
            'name': 'Stader BNBx',
            'address': '0x1bdd3Cf7F79cfB8EdbB955f20ad99211551BA275',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1664269044377_4727615335877742.jpg',
            'coingeckoId': 'stader-bnbx',
            'listedIn': [
                'coingecko',
                'openocean'
            ]
        },
        {
            'symbol': 'DOGEFOOD',
            'name': 'DogeFood',
            'address': '0x1bEc41a36356D5574Aeb068B599Ab7e48dD008b8',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22475/thumb/LNbEc9.png?1643275794',
            'coingeckoId': 'dogefood',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PIGGY',
            'name': 'Piggy',
            'address': '0x1bEaC6DF550be0ad146DD99b4726c6bec9C5c6a5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18869/thumb/200x200_piggy.png?1633654942',
            'coingeckoId': 'piggy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RIFF',
            'name': 'MetaTariff',
            'address': '0x1bDAF24Bdf9BFa845AF9F8D037b44E345bD968a0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24370/thumb/I3DrbUx1_400x400.jpg?1647442908',
            'coingeckoId': 'metatariff',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOVX',
            'name': 'MoveX',
            'address': '0x1bD8F7db38E3657b072c8F214CB83fbD7b99D46c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25895/thumb/download.png?1654496365',
            'coingeckoId': 'movex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POP',
            'name': 'POP Network',
            'address': '0x1bB76a939D6B7f5BE6b95c4f9f822B02B4D62Ced',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/7281/thumb/Logo_%28Light_Mode%29_%281%29.png?1644482888',
            'coingeckoId': 'pop-chest-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SACT',
            'name': 'srnArtGallery',
            'address': '0x1bA8c21c623C843Cd4c60438d70E7Ad50f363fbb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x1ba8c21c623c843cd4c60438d70e7ad50f363fbb.png',
            'coingeckoId': 'srnartgallery',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'TORN',
            'name': 'Tornado Cash',
            'address': '0x1bA8D3C4c219B124d351F603060663BD1bcd9bbF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13496/thumb/ZINt8NSB_400x400.jpg?1609193407',
            'coingeckoId': 'tornado-cash',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MOMENTO',
            'name': 'Momento',
            'address': '0x1b9A8C4f2DF5Dc7B8744B1A170d8d727360C67eE',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18532/thumb/momento.png?1632295065',
            'coingeckoId': 'momento',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TITANS',
            'name': 'Tower Defense Titans',
            'address': '0x1b880988A93f7133Be0F0749f39a9316e99fA70e',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22929/thumb/logo.png?1643003645',
            'coingeckoId': 'tower-defense-titans',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAO',
            'name': 'BAO',
            'address': '0x1b7cc2E9DfeAdc0AA3c283D727C50DF84558dA59',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27910/thumb/ph.png?1666341910',
            'coingeckoId': 'bao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POC',
            'name': 'Pocket Arena',
            'address': '0x1b6609830C695F1c0692123Bd2FD6D01f6794B98',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/8731/thumb/poc.png?1638521756',
            'coingeckoId': 'pocket-arena',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PIM',
            'name': 'PIMRIDE',
            'address': '0x1b46052e2b86a5C6c5D5080F702b18B487904273',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27128/thumb/200x200_PIM_logo-removebg-preview.png?1662020254',
            'coingeckoId': 'pimride',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KFT',
            'name': 'Knit Finance',
            'address': '0x1b41a1BA7722E6431b1A782327DBe466Fe1Ee9F9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15632/thumb/knit.jpg?1621396114',
            'coingeckoId': 'knit-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASTRO',
            'name': 'Astro Cash',
            'address': '0x1b24ebbEc03298576337B1805c733cD225C8a6BC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26817/thumb/logoredonda111.png?1664010291',
            'coingeckoId': 'astro-cash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPW',
            'name': 'Spaceship War',
            'address': '0x1b23340f5221FBD2e14f36e5b3E5d833D4D215b5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21087/thumb/logo_-_2021-12-01T082041.526.png?1638318048',
            'coingeckoId': 'spaceship-war',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FINS',
            'name': 'Fins',
            'address': '0x1b219Aca875f8C74c33CFF9fF98f3a9b62fCbff5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18568/thumb/fins_200x200.png?1632448775',
            'coingeckoId': 'fins-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOJI',
            'name': 'Boji',
            'address': '0x1b19c6bB5ea3290dc8b4Cb722Dee9EEa7BC7B164',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25290/thumb/CAt5H2S.png?1651131273',
            'coingeckoId': 'boji',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLUX',
            'name': 'Flux Protocol',
            'address': '0x1aB6478B47270fF05Af11A012Ac17b098758e193',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0x1ab6478b47270ff05af11a012ac17b098758e193.png',
            'coingeckoId': 'flux-protocol',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'BETS',
            'name': 'BSCBETS',
            'address': '0x1aA11b096f54E9556a772360d939625A939F3D26',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28198/thumb/logo200.png?1668336470',
            'coingeckoId': 'bscbets',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASPO',
            'name': 'ASPO World',
            'address': '0x1a9b49E9f075c37Fe5f86C916bac9DEb33556D7E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20329/thumb/12599.png?1636900554',
            'coingeckoId': 'aspo-world',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'AUR',
            'name': 'Aurix Exchange',
            'address': '0x1a91c7852FB2436ac87671BdDf8f720E83ef1FD3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'HTF',
            'name': 'Healthify',
            'address': '0x1a8B6E637aAB57c8544DC90BD813c62fAA68AA1C',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23574/thumb/logo-200.png?1644483564',
            'coingeckoId': 'healthify',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DRIFT',
            'name': 'DriftDelivery CC',
            'address': '0x1a8006d0A5fa2D0Baae74f5571133787B9B150dc',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25753/thumb/fRwV8k3kD1n1SkMrwdYzBcPFvR6GZeAPXGz_oWV7rBMbSv2nh8-C_PK2STbsxWwwB8ajcyCXq1jfJmVMI1h8ojBfF4zKpApw39uUuvKtiSUaQYkXo2wr6ucX_EGWKBed7Lh-1ckPR0LD9NHT1iZ6kptiWsGU-fcCdmDGBbx48C2es-0_7Jmzrvaji5iuIRx_7fjlaZ3sGe-eR0kfCU3d_92j8B.jpg?1653466109',
            'coingeckoId': 'driftdelivery-cc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FRAG',
            'name': 'Fragmint',
            'address': '0x1a73308d8Eeb3c1a2b771E9ace73508C52706B76',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27999/thumb/264931173_126331099842141_4644032570266092138_n.jpeg?1666924736',
            'coingeckoId': 'fragmint',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SINGME',
            'name': 'SingMe',
            'address': '0x1a5978aF0c006bA46F1Eb92000bf1040A97f647e',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26090/thumb/Singme_Logo%28200x200%29-13.png?1655778970',
            'coingeckoId': 'singme',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XF',
            'name': 'XRPFarm',
            'address': '0x1a591BC628458A76D0553A8B8C57bf32d3ac150F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28022/thumb/IMG_20221027_233155_765.png?1667029857',
            'coingeckoId': 'xrpfarm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '2GCC',
            'name': '2G Carbon Coin',
            'address': '0x1a515bf4e35AA2DF67109281DE6B3b00Ec37675E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27514/thumb/Ojh3Dm3-_400x400.jpeg?1664352445',
            'coingeckoId': '2g-carbon-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NOE',
            'name': 'NOE CRYPTO BANK',
            'address': '0x1a38F34dF3E4E480BFc57b789F605ce87B807848',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x1a38f34df3e4e480bfc57b789f605ce87b807848/logo.png',
            'coingeckoId': 'noe-crypto-bank',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'WNEAR',
            'name': 'Wrapped Near',
            'address': '0x1Fa4a73a3F0133f0025378af00236f3aBDEE5D63',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x1fa4a73a3f0133f0025378af00236f3abdee5d63.png',
            'coingeckoId': 'wrapped-near',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'TXL',
            'name': 'Autobahn Network',
            'address': '0x1FFD0b47127fdd4097E54521C9E2c7f0D66AafC5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x1ffd0b47127fdd4097e54521c9e2c7f0d66aafc5.png',
            'coingeckoId': 'autobahn-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PINU',
            'name': 'Pige Inu',
            'address': '0x1FDB2c3851D067502Ce2122bE80a41ea212949E2',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25885/thumb/pinu.png?1654415141',
            'coingeckoId': 'pige-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LEGO',
            'name': 'Lego Coin V1',
            'address': '0x1F98BD9CB8Db314Ced46Dc43C0a91a1F9aDAD4F5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25432/thumb/logolego.png?1651739234',
            'coingeckoId': 'lego-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GC',
            'name': 'Goal Champion',
            'address': '0x1F93D65D5115BC8785920d75120eA26a52ca4392',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28270/thumb/200x200_%282%29.png?1668999623',
            'coingeckoId': 'goal-champion',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MADCHAD',
            'name': 'MadChad',
            'address': '0x1F91F6c93023A441c3a72B24120E19acEF677911',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28101/thumb/Mad-Chad-Logo_%281%29.png?1667539553',
            'coingeckoId': 'madchad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GGTKN',
            'name': 'GGTKN',
            'address': '0x1F7e8fe01AEbA6fDAEA85161746f4D53DC9bdA4F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26275/thumb/ggtokenofficiallogo.png?1657016270',
            'coingeckoId': 'ggtkn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'bBADGER',
            'name': 'Badger Sett Badger',
            'address': '0x1F7216fdB338247512Ec99715587bb97BBf96eae',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x1f7216fdb338247512ec99715587bb97bbf96eae.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'LAZYSHIBA',
            'name': 'Lazy Shiba',
            'address': '0x1F6D33DadFFB37f1BA4582B2694a67a14AD587e5',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21565/thumb/logo-with-lace.png?1639474235',
            'coingeckoId': 'lazy-shiba',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'INX',
            'name': 'InfinitX',
            'address': '0x1F5d9fFAFa57604Ae2A43cebAdEcC175DF2F069e',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19212/thumb/inx.jpeg?1634696261',
            'coingeckoId': 'infinitx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NORA',
            'name': 'SnowCrash',
            'address': '0x1F39Dd2Bf5A27e2D4ED691DCF933077371777CB0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1636702852000_6955849088043498.png',
            'coingeckoId': 'snowcrash-token',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'VCG',
            'name': 'VCGamers',
            'address': '0x1F36FB2D91d9951Cf58aE4c1956C0b77e224F1E9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22371/thumb/VCG-Token-Logo-PNG.png?1643187590',
            'coingeckoId': 'vcgamers',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AZW',
            'name': 'AZ World SocialFi',
            'address': '0x1F2Cfde19976A2bF0A250900f7aCe9c362908C93',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25930/thumb/logoazw.png?1654731287',
            'coingeckoId': 'az-world-socialfi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AJE',
            'name': 'Ajeverse',
            'address': '0x1F0DD405aC14eD96Dec617c525Ed41E0130457bc',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21283/thumb/ajeverse.PNG?1638864443',
            'coingeckoId': 'ajeverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IFC',
            'name': 'InfinityCash',
            'address': '0x1Ef741cc15CC3Ade55A628F26cBbab4369Ea2912',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21066/thumb/logo-infinity-cash-BETA-Piece%28formatpetit%29.png?1638278015',
            'coingeckoId': 'infinitycash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DSD',
            'name': 'Dachshund',
            'address': '0x1E888338623c910463ba7d1c67659140fAD09E9e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27304/thumb/photo_2022-09-07_17-20-18.jpg?1663227127',
            'coingeckoId': 'dachshund',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MATICPAD',
            'name': 'Matic Launchpad',
            'address': '0x1E7e0EFb87e609b87F12F1cEa1DAC48569dA2328',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24421/thumb/ILN63o4w_400x400.jpg?1647612315',
            'coingeckoId': 'matic-launchpad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLK',
            'name': 'Belka  OLD ',
            'address': '0x1E7681E86027D8556B0c7eFC7bA213B8940e2788',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21428/thumb/Screenshot-2021-12-08-at-17-54-36.png?1639118972',
            'coingeckoId': 'belka',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADABOY',
            'name': 'ADABoy',
            'address': '0x1E653794A6849bC8A78be50C4D48981AfAD6359d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19096/thumb/ADABoy-Trans.png?1634612549',
            'coingeckoId': 'adaboy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LAND',
            'name': 'Outlanders Token',
            'address': '0x1E34fcc9a67E05D4bEED8C6a70F98C3E4D1e1e96',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x1e34fcc9a67e05d4beed8c6a70f98c3e4d1e1e96/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FENOMY',
            'name': 'Fenomy',
            'address': '0x1E226F8527D9F73048F4b660AF44D902d4508Bc2',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23625/thumb/communityIcon_e419z9o5oua81.png?1644819561',
            'coingeckoId': 'fenomy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LCDP',
            'name': 'LA CASA DE PAPEL TOKEN',
            'address': '0x1E19f5f52527700186339da4409bb19fbC622F2C',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x1e19f5f52527700186339da4409bb19fbc622f2c/logo.png',
            'coingeckoId': 'la-casa-de-papel',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BVT',
            'name': 'Bovineverse BVT',
            'address': '0x1DaCbcD9B3fc2d6A341Dca3634439D12bC71cA4d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': 'bovineverse-bvt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EQZ',
            'name': 'Equalizer',
            'address': '0x1Da87b114f35E1DC91F72bF57fc07A768Ad40Bb0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14741/thumb/X2p5mb2f_400x400.png?1618005664',
            'coingeckoId': 'equalizer',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IDOSCAN',
            'name': 'Idoscan',
            'address': '0x1Da25D0a242c3B42D6774e0A41d5A142e127f891',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19956/thumb/IDOscan_logo_full_%281%29.png?1636340452',
            'coingeckoId': 'idoscan',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LTT',
            'name': 'LocalTrade',
            'address': '0x1DC84Fc11E48aE640D48044F22A603BBE914A612',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22838/thumb/odbAFHBFZOoBqBWusCYcMjubLI8GJOArvJtuAWzDBRw3bZf2vRmJogFe1sOaIKfGtQmLTcRSzSDskopEHk3Gkpq6gYmE4IPJjMTxAppDTH4s7878Ht-LjC-ejUOD6krZ55SvvZtyZSfiZoxPE_aQGO62-m_B2tpQrFXlJk4orXx49TP-ggeZ8pc3-3j97fRqDegEm1F89eq3gL.jpg?1642745247',
            'coingeckoId': 'localtrade',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NBT',
            'name': 'NanoByte',
            'address': '0x1D3437E570e93581Bd94b2fd8Fbf202d4a65654A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x1D3437E570e93581Bd94b2fd8Fbf202d4a65654A.png',
            'coingeckoId': 'nanobyte',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'XRP',
            'name': 'Binance Peg XRP',
            'address': '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe.png',
            'coingeckoId': 'binance-peg-xrp',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ZOO',
            'name': 'ZOO Crypto World',
            'address': '0x1D229B958D5DDFca92146585a8711aECbE56F095',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x1D229B958D5DDFca92146585a8711aECbE56F095.png',
            'coingeckoId': 'zoo-crypto-world',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'HAKKA',
            'name': 'Hakka Finance on xDai on BSC',
            'address': '0x1D1eb8E8293222e1a29d2C0E4cE6C0Acfd89AaaC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x1d1eb8e8293222e1a29d2c0e4ce6c0acfd89aaac.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PBR',
            'name': 'PolkaBridge',
            'address': '0x1D1cB8997570e73949930c01Fe5796C88d7336c6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13744/thumb/symbol-whitebg200x200.png?1611377553',
            'coingeckoId': 'polkabridge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATD',
            'name': 'A2DAO',
            'address': '0x1Ce440d1A64eeA6AA1dB2A5Aa51C9B326930957C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14509/thumb/8926.png?1645686353',
            'coingeckoId': 'catapult',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHTS',
            'name': 'Creature Hunters',
            'address': '0x1CdB9b4465F4e65B93D0aD802122C7C9279975c9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24203/thumb/CH-TOKEN-200x200.png?1646835756',
            'coingeckoId': 'creature_hunters',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASV',
            'name': 'Astro Verse',
            'address': '0x1Cd9D494bfdD8bB4132E6D1f905952b654dd1DeA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22241/thumb/200200.png?1641274375',
            'coingeckoId': 'astro-verse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LEAF',
            'name': 'Seeder Finance',
            'address': '0x1CbDdf83De068464Eba3a4E319bd3197a7EEa12c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16358/thumb/SEEDR-32.png?1623809712',
            'coingeckoId': 'seeder-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AVAX',
            'name': 'Binance Peg Avalanche',
            'address': '0x1CE0c2827e2eF14D5C4f29a091d735A204794041',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18674/thumb/avax_logo_1.png?1632889651',
            'coingeckoId': 'binance-peg-avalanche',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ISHND',
            'name': 'StrongHands Finance',
            'address': '0x1CC1aCa0DaE2D6c4A0e8AE7B4f2D01eAbbC435EE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20158/thumb/ISHND_512x512px.png?1647512898',
            'coingeckoId': 'stronghands-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHARGE',
            'name': 'ChargeDeFi Charge',
            'address': '0x1C6bc8e962427dEb4106aE06A7fA2d715687395c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21405/thumb/afuIh6o9_400x400.jpg?1639087246',
            'coingeckoId': 'chargedefi-charge',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'FOOT',
            'name': 'BIGFOOT',
            'address': '0x1C64Fd4f55E1A3C1f737Dfa47ee5F97eaF413cf0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17773/thumb/AThX0Y8g_400x400.jpg?1629209521',
            'coingeckoId': 'bigfoot',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IDS',
            'name': 'IDEAS',
            'address': '0x1C61a220a0F1DfC750D28188a97a6c7bF14E9851',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21535/thumb/ids.png?1640578499',
            'coingeckoId': 'ideas',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KRED',
            'name': 'KRED',
            'address': '0x1C50E72b9b7a44bf7e63FE7735d67d82c3E4bF74',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22935/thumb/kred_logo_200.png?1643004614',
            'coingeckoId': 'kred',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MMG',
            'name': 'Monopoly Millionaire Game',
            'address': '0x1C376275791069349fef8bd9F431382D384657a3',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23522/thumb/mmg.png?1647700364',
            'coingeckoId': 'monopoly-millionaire-game',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WPKT',
            'name': 'Wrapped PKT',
            'address': '0x1C25222994531C4AC35E4d94bbf7552c9aa92E32',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19003/thumb/200x200wpkt-04.png?1634138697',
            'coingeckoId': 'wrapped-pkt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRONPAD',
            'name': 'TRONPAD',
            'address': '0x1Bf7AedeC439D6BFE38f8f9b20CF3dc99e3571C4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16066/thumb/weUUiy33_400x400.jpg?1622737145',
            'coingeckoId': 'tronpad',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BABYCATS',
            'name': 'Baby Catcoin',
            'address': '0x1Bd5ddCD740F2D13b2255fc325c02509da35C4e4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23959/thumb/1F7D09EB-6E0E-4AD3-9CF8-128D2A8C2C7F.png?1645787565',
            'coingeckoId': 'baby-catcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZEC',
            'name': 'Zcash Token',
            'address': '0x1Ba42e5193dfA8B03D15dd1B86a3113bbBEF8Eeb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x1ba42e5193dfa8b03d15dd1b86a3113bbbef8eeb.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'sushiswap',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'UIM',
            'name': 'Universe Island',
            'address': '0x1BB132D6039b81FaEdc524a30E52586b6Ca15f48',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21415/thumb/FULL.png?1639092541',
            'coingeckoId': 'universe-island',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOGEKONGZILLA',
            'name': 'DogeKongZilla',
            'address': '0x1B442512ED276e3e874149e4f4e51f06AEE8B58c',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19722/thumb/13610.png?1635761518',
            'coingeckoId': 'dogekongzilla',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SET',
            'name': 'Sustainable Energy',
            'address': '0x1B391f9d0FfFa86A6088a73ac4AC28d12c9ccFbd',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15592/thumb/new_logo_200.png?1621292943',
            'coingeckoId': 'sustainable-energy-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SKMT',
            'name': 'Soakmont',
            'address': '0x1B2fdB1626285B94782af2Fda8e270E95cEbC3b4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19014/thumb/Soakmont_-_Icone_RGB_Normal.jpeg?1653013817',
            'coingeckoId': 'soakmont',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NRT',
            'name': 'NFT Royal',
            'address': '0x1B2F67679798C764f2C0c69DFB6bDa8b30a094cf',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19277/thumb/royal.png?1634869798',
            'coingeckoId': 'nft-royal-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GYRO',
            'name': 'Gyro',
            'address': '0x1B239ABe619e74232c827FBE5E49a4C072bD869D',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19020/thumb/gyro.jpg?1634185058',
            'coingeckoId': 'gyro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QUAM',
            'name': 'Quam Network',
            'address': '0x1AdE17B4B38B472B5259BbC938618226dF7b5Ca8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14438/thumb/cropped-logonew.png?1616057567',
            'coingeckoId': 'quam-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YSHIBAINU',
            'name': 'Yooshiba Inu',
            'address': '0x1AdA8bB610C59Aae25d8DD8f354282F5693CefB1',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21193/thumb/yshibainu.png?1638520766',
            'coingeckoId': 'yooshiba-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOT',
            'name': 'Bot Planet',
            'address': '0x1Ab7E7DEdA201E5Ea820F6C02C65Fce7ec6bEd32',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24650/thumb/McLc4iA.png?1648612075',
            'coingeckoId': 'bot-planet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DAI',
            'name': 'Dai',
            'address': '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x6b175474e89094c44da98b954eedeac495271d0f.png',
            'coingeckoId': 'dai',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'TWEP',
            'name': 'The Web3 Project',
            'address': '0x1AEb3f66d96bFaF74fCBD15Dc21798De36F6F933',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24550/thumb/twep.jpg?1663666560',
            'coingeckoId': 'the-web3-project',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '10SET',
            'name': 'Tenset',
            'address': '0x1AE369A6AB222aFF166325B7b87Eb9aF06C86E57',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14629/thumb/10set.png?1617353812',
            'coingeckoId': 'tenset',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAG',
            'name': 'BondAppetit Governance',
            'address': '0x1AD0132D8B5Ef3cEBDA1A9692f36AC30be871b6b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14724/thumb/BAG2x.png?1618568573',
            'coingeckoId': 'bondappetit-gov-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BEE',
            'name': 'HoneyBee',
            'address': '0x1A8d7AC01d21991BF5249A3657C97b2B6d919222',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19112/thumb/download_%2835%29.png?1634515063',
            'coingeckoId': 'honeybee',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IPX',
            'name': 'InpulseX',
            'address': '0x1A3eE33da561642bA6bE4671A06267ee0F36cEDd',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23386/thumb/IPX_Logo_200x200.png?1644032911',
            'coingeckoId': 'inpulse-x',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ROAD',
            'name': 'Yellow Road',
            'address': '0x1A3057027032a1AF433f6f596CAB15271e4D8196',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14747/thumb/yellowroad.PNG?1618093885',
            'coingeckoId': 'yellow-road',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KUN',
            'name': 'Chemix Ecology Governance',
            'address': '0x1A2fb0Af670D0234c2857FaD35b789F8Cb725584',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x1A2fb0Af670D0234c2857FaD35b789F8Cb725584/logo.png',
            'coingeckoId': 'chemix-ecology-governance-token',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SOFI',
            'name': 'RAI Finance',
            'address': '0x1A28ed8472F644E8898A169a644503B779748d6e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14686/thumb/sofi.png?1640249595',
            'coingeckoId': 'rai-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JIG',
            'name': 'Jigen',
            'address': '0x1A122941ff2ef376f065e0404a0a9CECBfe94551',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21716/thumb/MbfJ2kSM_400x400.png?1639863526',
            'coingeckoId': 'jigen',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'TEM',
            'name': 'TemplarDAO',
            'address': '0x19e6BfC1A6e4B042Fb20531244D47E252445df01',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x19e6BfC1A6e4B042Fb20531244D47E252445df01.png',
            'coingeckoId': 'templardao',
            'listedIn': [
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'IBAT',
            'name': 'Battle Infinity',
            'address': '0x19cd9B8e42d4EF62c3EA124110D5Cfd283CEaC43',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27069/thumb/ibat_logo.png?1661674405',
            'coingeckoId': 'battle-infinity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RLQ',
            'name': 'Realliq',
            'address': '0x19bB77FD24fc09Dd0C3E8Ea8b3781172479791E4',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21896/thumb/realliq_copy.png?1640250916',
            'coingeckoId': 'realliq',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TAUR',
            'name': 'Marnotaur',
            'address': '0x19b99162ADAAB85134E781Ac0048C275C31b205a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18655/thumb/taur.PNG?1632795749',
            'coingeckoId': 'marnotaur',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELONJET',
            'name': 'ElonJet',
            'address': '0x19F7C542836C33677aC92B42C453269468bDF4Dc',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23453/thumb/elonjet-32x32.png?1644214529',
            'coingeckoId': 'elonjet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'Z7',
            'name': 'Z7DAO',
            'address': '0x19E3CAd0891595D27A501301A075Eb680A4348B6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25962/thumb/z7.png?1654939651',
            'coingeckoId': 'z7dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTA',
            'name': 'MetaAxis',
            'address': '0x19D3A7c26a1271eeBB6f04bDb4565b7F2B7EFc30',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20093/thumb/aaalogo-135x95.png?1636492903',
            'coingeckoId': 'metaaxis',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PAR',
            'name': 'Parachute',
            'address': '0x19C91764A976aC6C1E2C2E4c5856F2939342a814',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/7590/thumb/Parachute_Logo.png?1560918207',
            'coingeckoId': 'parachute',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POMO',
            'name': 'Pomo',
            'address': '0x19Af9DaB517746257dBa18fCd74f7dF5383bdF1B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25355/thumb/200x200.png?1651312794',
            'coingeckoId': 'pomo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CBD',
            'name': 'CBD Coin',
            'address': '0x19ACe850213c518E3b29b57F4aAB4A0fB60AcdB9',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16328/thumb/CBD-COin.png?1623745536',
            'coingeckoId': 'cbd-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BISON',
            'name': 'Bison',
            'address': '0x19A6Da6e382b85F827088092a3DBe864d9cCba73',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0x19a6da6e382b85f827088092a3dbe864d9ccba73/4e9669177d2eec6bba18c4390a87e812.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'HOD',
            'name': 'HoDooi com',
            'address': '0x19A4866a85c652EB4a2ED44c42e4CB2863a62D51',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16301/thumb/Monogram_2x-100.jpg?1645205976',
            'coingeckoId': 'hodooi-com',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'DEFIDO',
            'name': 'DeFido',
            'address': '0x199F788DDb566B7eBB59bf35B36914F2aCdb33DE',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18861/thumb/defido.png?1635638445',
            'coingeckoId': 'defido',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INFINITY',
            'name': 'Infinite Ecosystem',
            'address': '0x1996294d6Fbda0e441f6A1C0A8d65b9D7aa17145',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22580/thumb/0DvUslSU_400x400.jpg?1642114037',
            'coingeckoId': 'infinite-ecosystem',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HOTDOGE',
            'name': 'HotDoge  OLD ',
            'address': '0x1991501f1398663F69dD7391C055bb0DF6514F76',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15404/thumb/525ea2_88a58501323f44c7a806c008ae2b4858_mv2.png?1622197208',
            'coingeckoId': 'hot-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BMND',
            'name': 'Baby Mind',
            'address': '0x198fa9F58F8f5a492BEB40d61C1aB65B4efE1104',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20182/thumb/Baby200x200.png?1636606709',
            'coingeckoId': 'baby-mind',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAMBOO',
            'name': 'BambooDeFi',
            'address': '0x198abB2D13fAA2e52e577D59209B5c23c20CD6B3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13856/thumb/Bamboo-token-logo-200.png?1612404311',
            'coingeckoId': 'bamboo-defi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SUTEKU',
            'name': 'Suteku',
            'address': '0x198800aF50914004A9E9D19cA18C0b24587a50cf',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20824/thumb/hJIxZGE.png?1653548066',
            'coingeckoId': 'suteku',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KNB',
            'name': 'Kronobit',
            'address': '0x197dc4bfc82ad08E3FBaa930A869485236e6A792',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18083/thumb/logoxx.png?1664016158',
            'coingeckoId': 'kronobit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CTAX',
            'name': 'Cryptotaxis',
            'address': '0x196a03f90b9742f2DCbEe3C3C0e6579DEf219728',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21393/thumb/6cfkpTn.png?1639039002',
            'coingeckoId': 'cryptotaxis-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UVT',
            'name': 'UvToken',
            'address': '0x196Eb1D21C05CC265Ea0a1479E924E7983467838',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26875/thumb/photo_2022-04-08_10-31-38_%282%29.png?1660558292',
            'coingeckoId': 'uvtoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALTI',
            'name': 'Altimatum',
            'address': '0x195e3087ea4d7eec6e9c37e9640162Fe32433D5e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26772/thumb/footerlogo.png?1660048555',
            'coingeckoId': 'altimatum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'bBzB',
            'name': 'BlazarBits',
            'address': '0x195De011a1F5bf44b64A9474C62dA964F68CD34f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WBM',
            'name': 'WB Mining',
            'address': '0x19575B407e2dd49cb2BA46375A7fba37c8EC553a',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28240/thumb/logo200.png?1668581954',
            'coingeckoId': 'wb-mining',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TM',
            'name': 'Templar Money',
            'address': '0x194d1D62d8d798Fcc81A6435e6d13adF8bcC2966',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FS',
            'name': 'FantomStarter',
            'address': '0x1935C03bB7DbC3623bD992CE599bC26bD30A43EB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MFLOKI',
            'name': 'Meta Floki',
            'address': '0x1931461C770509cC20ACA503D3c5637e7B6C5220',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19766/thumb/mfloki.png?1635836944',
            'coingeckoId': 'meta-floki',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOBI',
            'name': 'Moebius',
            'address': '0x192a4fFCCAc2D29Fe3f84cAEe5e94Ed3737c705b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22693/thumb/1_9h4WGsh8a2t2kPeOgkSyeg.png?1642410623',
            'coingeckoId': 'moebius',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOXER',
            'name': 'Boxer Inu',
            'address': '0x192E9321b6244D204D4301AfA507EB29CA84D9ef',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16748/thumb/boxer_logo.png?1624874379',
            'coingeckoId': 'boxer-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UPCOIN',
            'name': 'Upcoin',
            'address': '0x191Deb496940b830dF8d52b3c0F0B09fc36F5B74',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17077/thumb/Upcoin-logo.png?1626152950',
            'coingeckoId': 'upcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BDO',
            'name': 'bDollar',
            'address': '0x190b589cf9Fb8DDEabBFeae36a813FFb2A702454',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x190b589cf9fb8ddeabbfeae36a813ffb2a702454.png',
            'coingeckoId': 'bdollar',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WILD',
            'name': 'Wild Island Game',
            'address': '0x19048172b5A0893ad008e7c52C11a6DD3C8784a1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22196/thumb/logo-moneda200.png?1641191848',
            'coingeckoId': 'wild-island-game',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OLYMPUS',
            'name': 'OLYMPUS',
            'address': '0x18b426813731C144108c6D7FAf5EdE71a258fD9A',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x18b426813731c144108c6d7faf5ede71a258fd9a.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'OKS',
            'name': 'Oikos',
            'address': '0x18aCf236eB40c0d4824Fb8f2582EBbEcD325Ef6a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/11486/thumb/B49FnNM.png?1625754985',
            'coingeckoId': 'oikos',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTSC',
            'name': 'BTS Chain',
            'address': '0x18E596bcEfdd0fF9dC8c50D0b9d329eA770d5eF1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19386/thumb/mDVn3lgM_400x400.jpg?1635147385',
            'coingeckoId': 'bts-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MBL',
            'name': 'MovieBloc',
            'address': '0x18E37F96628dB3037d633FE4D469Fb1933a63C5B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x18E37F96628dB3037d633FE4D469Fb1933a63C5B.png',
            'coingeckoId': null,
            'listedIn': [
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'PALS',
            'name': 'FlokiMarvin',
            'address': '0x18D0717E9B3371a9EC4089CF0FE89e6591724556',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26661/thumb/fmpals.png?1659422073',
            'coingeckoId': 'flokimarvin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EVU',
            'name': 'Evulus',
            'address': '0x18B5F22266343cCD180C6285a66cC9A23Dc262E9',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22081/thumb/isotipo-evulus.png?1640759271',
            'coingeckoId': 'evulus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HALO',
            'name': 'Halo Coin',
            'address': '0x1894251aEBCfF227133575eD3069Be9670E25dB0',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27758/thumb/coingeckoq.png?1665656946',
            'coingeckoId': 'halo-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CTY',
            'name': 'CUSTODIY',
            'address': '0x188173379AC8963048Afe01C5d3D5998FEe67254',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26702/thumb/IMG_20220804_011032_777.jpg?1659662673',
            'coingeckoId': 'custodiy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AFT',
            'name': 'Ape Fun',
            'address': '0x187647f4f531D16ABb53cDE3A608Ee8D0A123C8d',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17976/thumb/afgspinner2.png?1657617074',
            'coingeckoId': 'ape-fun-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HFT',
            'name': 'Hodl Finance',
            'address': '0x186866858aEf38c05829166A7711b37563e15994',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20213/thumb/hft_coingecko.png?1645690810',
            'coingeckoId': 'hodl-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DSLA',
            'name': 'DSLA Protocol',
            'address': '0x1861C9058577C3b48e73d91d6f25C18B17FBFFE0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/6694/thumb/dsla_logo-squared_200x200.png?1569571063',
            'coingeckoId': 'stacktical',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ONUS',
            'name': 'ONUS',
            'address': '0x1851ccD370C444ff494d7505e6103959bCE9F9d9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24599/thumb/onus.png?1648364408',
            'coingeckoId': 'onus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KCC',
            'name': 'KingCorgi Chain',
            'address': '0x184aF67D26d7DB08F5dAaB8A43315D25f76819C7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27339/thumb/200x200.png?1663549437',
            'coingeckoId': 'kingcorgi-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SEKO',
            'name': 'SekoPay',
            'address': '0x1840EFA31D2e26dc8AF5502eFA5124DA450995F1',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25833/thumb/Ads%C4%B1z_tasar%C4%B1m.png?1654156935',
            'coingeckoId': 'sekopay-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MARSRISE',
            'name': 'MarsRise',
            'address': '0x184079Ca987F562ae6a0c59f4BE5cADB20323863',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19029/thumb/marsrise.png?1634194715',
            'coingeckoId': 'marsrise',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FFT',
            'name': 'Futura Finance',
            'address': '0x183B2c147fE11788b73BC0D6A7E0186aA5464BfA',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x183b2c147fe11788b73bc0d6a7e0186aa5464bfa/logo.png',
            'coingeckoId': 'futura-finance',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DOGEMOON',
            'name': 'Dogemoon',
            'address': '0x18359CF655A444204e46F286eDC445C9D30fFc54',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15308/thumb/DOGEMOON.png?1629434312',
            'coingeckoId': 'dogemoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HOGL',
            'name': 'HOGL Finance',
            'address': '0x182c763a4b2Fbd18C9B5f2D18102a0dDd9D5DF26',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x182c763a4b2fbd18c9b5f2d18102a0ddd9d5df26.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MAIR',
            'name': 'MetaAir',
            'address': '0x1828e38E7271c44e4BeC6810dC5279223cCd14ff',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23850/thumb/MetaAir.png?1645523178',
            'coingeckoId': 'metaair',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'BELUGA',
            'name': 'Beluga fi',
            'address': '0x181dE8C57C4F25eBA9Fd27757BBd11Cc66a55d31',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13790/thumb/brand-logo-v2.23e5d279.png?1624906164',
            'coingeckoId': 'beluga-fi',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'DOJO',
            'name': 'DOJO',
            'address': '0x180dAe91D6d56235453a892d2e56a3E40Ba81DF8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16396/thumb/074606deafab3872.png?1623910120',
            'coingeckoId': 'dojo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOJO',
            'name': 'Dojocoin',
            'address': '0x180d64F6326015cb0c4aBdD00DCEFe52058eB84A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28249/thumb/Dojo.jpeg?1668659419',
            'coingeckoId': 'dojocoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XP',
            'name': 'PolkaFantasy',
            'address': '0x180cFBE9843d79BcAFcbcDF23590247793DFc95B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18299/thumb/XP_Token_Icon.png?1631498467',
            'coingeckoId': 'polkafantasy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DDN',
            'name': 'Den Domains',
            'address': '0x180cAF92A1989efc5Af3efe154DB0DD73199930F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19123/thumb/dxwS1PQ0_400x400.png?1634520988',
            'coingeckoId': 'dendomains',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DYT',
            'name': 'The Dynasty',
            'address': '0x1802a63EFe3255aBC3004F8e63047dB96300bd1c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20296/thumb/HUA9G2S4_400x400.jpg?1636815277',
            'coingeckoId': 'the-dynasty',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPY',
            'name': 'Smarty Pay',
            'address': '0x17fd3cAa66502C6F1CbD5600D8448f3aF8f2ABA1',
            'decimals': 0,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18813/thumb/logo_smarty-pay.png?1633499996',
            'coingeckoId': 'smarty-pay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METAM',
            'name': 'MetaMars',
            'address': '0x17f312147027fdc250B384D886Ba8f14d8459c6B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22340/thumb/image.png?1641534953',
            'coingeckoId': 'metamars',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UCHAD',
            'name': 'UltraChad',
            'address': '0x17e7668dBcAae71b8965cd44785D070BD4e129cf',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19777/thumb/uchad.PNG?1635839973',
            'coingeckoId': 'ultrachad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CNT',
            'name': 'Crinet',
            'address': '0x17d0b69a947Db94c825c07216905103dca2Dc732',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25090/thumb/Crinet_Logo_Icon_%281%29_%281%29.png?1650269805',
            'coingeckoId': 'crinet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COX',
            'name': 'Coxswap  OLD ',
            'address': '0x17FEa92CA2e7570d07b63ecD6B698e01386555A4',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21191/thumb/SoXX-GZs_400x400.jpg?1638516746',
            'coingeckoId': 'coxswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ROCKETBUSD',
            'name': 'RocketBUSD',
            'address': '0x17FAbAF66256fb40F350576bafA1807429708E34',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19977/thumb/ROCKET__ICON_GRADIENT.png?1650954296',
            'coingeckoId': 'rocketbusd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TIFI',
            'name': 'TiFi',
            'address': '0x17E65E6b9B166Fb8e7c59432F0db126711246BC0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24990/thumb/logo200_%281%29.png?1649686507',
            'coingeckoId': 'tifi-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TWITTERX',
            'name': 'TwitterX',
            'address': '0x17C3B737985D091AE9Df3CA40F01e1431F2e32B1',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27809/thumb/200photo_2022-10-11_03-48-36.jpg?1665906562',
            'coingeckoId': 'twitterx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETHFIN',
            'name': 'Ethernal Finance',
            'address': '0x17Bd2E09fA4585c15749F40bb32a6e3dB58522bA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20190/thumb/CMC_%282%29.png?1636612448',
            'coingeckoId': 'ethernal-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QBT',
            'name': 'Qubit',
            'address': '0x17B7163cf1Dbd286E262ddc68b553D899B93f526',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x17B7163cf1Dbd286E262ddc68b553D899B93f526.png',
            'coingeckoId': 'qubit',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'CNT',
            'name': 'Connect Stela',
            'address': '0x17B6115A4d0292745D122A49aDe76ed5Ac16b19d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24927/thumb/61f1d2b3bae74faea4ea34ef_32x32.png?1649389084',
            'coingeckoId': 'connect-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CDZ',
            'name': 'CDzExchange',
            'address': '0x17Acc21Da1Cd31d273c3f54b7d5Dd556C8715b79',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18560/thumb/cdz.PNG?1632435088',
            'coingeckoId': 'cdzexchange',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PMON',
            'name': 'Polychain Monsters',
            'address': '0x1796ae0b0fa4862485106a0de9b654eFE301D0b2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x1796ae0b0fa4862485106a0de9b654eFE301D0b2/logo.png',
            'coingeckoId': 'polychain-monsters',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WELT',
            'name': 'Fabwelt',
            'address': '0x1785113910847770290F5F840b4c74fc46451201',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20505/thumb/welt.PNG?1637143324',
            'coingeckoId': 'fabwelt',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MATA',
            'name': 'Mata',
            'address': '0x175fAcDD947C995ad547F6AD952D26826758A4dA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22934/thumb/AvPezpY4TzT4sYmaRRELb7Vyg4GfJNv2CX0nmJehBlBpyNoU9onhbM6-HbcXhz_7yhCjkZlNh8H-gPk5R0IMtfZXyKw00Xc4Zvf_EpvjmcD-Q6liwiladoBHVFpJwj0nf4cdLJm71fs6mW_1wd9P68uabxHOb94gDljk_CmrWG4KHNaEEmITOKoUO39Vc55FML6qYoNiyXYGBo.jpg?1643004421',
            'coingeckoId': 'mata',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FSHIBA',
            'name': 'FriendShiba',
            'address': '0x175995B6897C5e8D89a96A44E68024139fAb24dd',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x175995b6897c5e8d89a96a44e68024139fab24dd/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'UPBNB',
            'name': 'upBNB',
            'address': '0x1759254EB142bcF0175347D5A0f3c19235538a9A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15092/thumb/upbnb_logo.jpg?1619677104',
            'coingeckoId': 'upbnb',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABYETHV2',
            'name': 'BabyETH V2',
            'address': '0x1718C8727fa248F8b2521e05e1d1065e3Be56d55',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18895/thumb/babyeth.PNG?1633815702',
            'coingeckoId': 'babyeth-v2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IXIR',
            'name': 'Ixirswap',
            'address': '0x17179f506B89D80291B581F200F34B17ff172CD3',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23884/thumb/CMC_CG_%282%29.png?1645601643',
            'coingeckoId': 'ixirswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IMRTL',
            'name': 'Immortl',
            'address': '0x170DC35C5B58AEdEdC81960691eb4b389Eba9E95',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21983/thumb/ONE.png?1640575713',
            'coingeckoId': 'immortl',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'VEED',
            'name': 'VEED',
            'address': '0x16fdd1edb14ac4012395A0617a682D81595dB486',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15919/thumb/VEED.png?1622429588',
            'coingeckoId': 'veed',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BB',
            'name': 'Baby Bali',
            'address': '0x16f9cc3c6F8d8006cfc0ee693CeF9D76b0d44C36',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20789/thumb/New-Era-of-Baby-Bali-200x200pxl.png?1637671831',
            'coingeckoId': 'baby-bali',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEP',
            'name': 'Depth',
            'address': '0x16f99577b259B069a2d1D166e70d349b11b1D325',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14574/thumb/depth_icon_round__500.png?1617086437',
            'coingeckoId': 'depth-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SCOOBI',
            'name': 'Scoobi Doge',
            'address': '0x16dFEfF64c532370e8FAa73a7e94e2cCda9342EF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16101/thumb/scoobi_doge.jpg?1622963978',
            'coingeckoId': 'scoobi-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EVERETH',
            'name': 'EverETH',
            'address': '0x16dCc0eC78E91e868DCa64bE86aeC62bf7C61037',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18221/thumb/200x200.png?1631007784',
            'coingeckoId': 'evereth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EXOS',
            'name': 'Exobots',
            'address': '0x16b8dBa442cc9fAa40d0Dd53f698087546CCF096',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25528/thumb/16521.png?1652242218',
            'coingeckoId': 'exobots',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FOX',
            'name': 'Fox Finance V2',
            'address': '0x16a7460B9246AE508f18e87bDa4e5B4C1AE8F112',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25627/thumb/ouIXdRFJ_400x400.png?1652861555',
            'coingeckoId': 'fox-financev2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IETH',
            'name': 'Infinity ETH',
            'address': '0x16F761F89967A1dFC8E851a48904FB0c177bB373',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20051/thumb/photo-2021-10-24-17-37-28.jpg?1636427099',
            'coingeckoId': 'infinity-eth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SIU',
            'name': 'Siuuu',
            'address': '0x16E33015C466c0183cd1c2adbC5B4E7bf97548Da',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26531/thumb/zx46tIq7_400x400.jpeg?1658667013',
            'coingeckoId': 'siuuu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XTZ',
            'name': 'Tezos Token',
            'address': '0x16939ef78684453bfDFb47825F8a5F714f12623a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x16939ef78684453bfdfb47825f8a5f714f12623a.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'sushiswap',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'LTNV2',
            'name': 'Life v2',
            'address': '0x167e5455E4C978883B414E7f02c0147eec9a18e9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20568/thumb/1-j2MSwg_400x400.jpg?1637225507',
            'coingeckoId': 'life-token-v2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TKB',
            'name': 'TokenBot',
            'address': '0x16594930D16f3970e1a4317c6016555cb2e7b7Fc',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27106/thumb/tokenbot-logo-icon.png?1662007634',
            'coingeckoId': 'tokenbot',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EDOGE',
            'name': 'ElonDoge io',
            'address': '0x163f182C32d24A09d91EB75820cDe9FD5832b329',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15806/thumb/Edoge.png?1621934765',
            'coingeckoId': 'elon-doge-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPS',
            'name': 'Splintershards',
            'address': '0x1633b7157e7638C4d6593436111Bf125Ee74703F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x1633b7157e7638C4d6593436111Bf125Ee74703F/logo.png',
            'coingeckoId': 'splinterlands',
            'listedIn': [
                'coingecko',
                'pancake',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DNFT',
            'name': 'DareNFT',
            'address': '0x162C2332e92BE409254AC7c59aD559C16a3f506E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19006/thumb/dnft.PNG?1634170397',
            'coingeckoId': 'darenft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RUNO',
            'name': 'RUNO',
            'address': '0x1621E43FDf85a088F40fab0b4259650d2Cb5F661',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/8875.png',
            'coingeckoId': null,
            'listedIn': [
                'RouterProtocol'
            ]
        },
        {
            'symbol': 'PWAR',
            'name': 'PolkaWar',
            'address': '0x16153214E683018D5aA318864c8e692b66E16778',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16776/thumb/polkawar.PNG?1624947747',
            'coingeckoId': 'polkawar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CGG',
            'name': 'Chain Guardians',
            'address': '0x1613957159E9B0ac6c80e824F7Eea748a32a0AE2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x1613957159e9b0ac6c80e824f7eea748a32a0ae2.png',
            'coingeckoId': 'chain-guardians',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'VDOT',
            'name': 'Venus DOT',
            'address': '0x1610bc33319e9398de5f57B33a5b184c806aD217',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x1610bc33319e9398de5f57b33a5b184c806ad217.png',
            'coingeckoId': 'venus-dot',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'AQUAGOAT',
            'name': 'Aqua Goat',
            'address': '0x1606940bB5Cd6de59A7E25367F4fB8965f38F122',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18676/thumb/ag.png?1634864671',
            'coingeckoId': 'aqua-goat',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SLRM',
            'name': 'Salary Mining',
            'address': '0x15adA4EA653e6E87B7F981C943965b20b2DCF703',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25255/thumb/SLRM_120X120.png?1651041525',
            'coingeckoId': 'salary-mining',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CYM',
            'name': 'Cylum Finance',
            'address': '0x15Ea6B8481bF1C991aC3dC8e67279d31651a56FE',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24642/thumb/nL86PqSc_400x400.jpg?1648509467',
            'coingeckoId': 'cylum-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FATHER',
            'name': 'DogeFather Token',
            'address': '0x15E2BFF3AB5dcF1F7b5b854636939F3649ce3cf8',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23668/thumb/logo_256px.png?1644993679',
            'coingeckoId': 'dogefather-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NEWO',
            'name': 'NEWO Coin',
            'address': '0x15D57CE57AB752a069fB6Fc76fF431812fD3aDA3',
            'decimals': 16,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24236/thumb/newo.png?1647077641',
            'coingeckoId': 'newo-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTC',
            'name': 'Meta Cosmos',
            'address': '0x15D1D8274277A2f4c1F45BE70ab189A16Ab15673',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SAVE',
            'name': 'SaveTheWorld',
            'address': '0x159802FBE16Aa6a0863a56a18DD41Afce546c93E',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15353/thumb/200_Save_black.jpg?1620630046',
            'coingeckoId': 'savetheworld',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MXM',
            'name': 'MXM',
            'address': '0x158d41854b4F6A0f55051989EA5e27705C277180',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26346/thumb/Qi3QBp_m_400x400.jpeg?1657587089',
            'coingeckoId': 'mxm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EGGPLANT',
            'name': 'Eggplant',
            'address': '0x1583fFE1720027500ba6b284B1b1e9755d43e479',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15594/thumb/Logo_Eggplant_Circle_200x200.png?1625578241',
            'coingeckoId': 'eggplant',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPACES',
            'name': 'AstroSpaces io',
            'address': '0x156df0dd6300C73ac692D805720967cf4464776e',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22394/thumb/17014.png?1641792560',
            'coingeckoId': 'astrospaces-io',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUNA',
            'name': 'LUNA (Wormhole)',
            'address': '0x156ab3346823B651294766e23e6Cf87254d68962',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x156ab3346823b651294766e23e6cf87254d68962/logo.png',
            'coingeckoId': 'luna-wormhole',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'JASMY',
            'name': 'JasmyCoin',
            'address': '0x15669CF161946C09a8B207650BfBB00e3d8A2E3E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x15669CF161946C09a8B207650BfBB00e3d8A2E3E.png',
            'coingeckoId': null,
            'listedIn': [
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'BRDG',
            'name': 'Bridge Network',
            'address': '0x1562c99ad7179b7D1d862fF4E8BfF6CC016a97ee',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25139/thumb/bridge-logo-blue.png?1650433261',
            'coingeckoId': 'bridge-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FCP',
            'name': 'Filipcoin',
            'address': '0x155e8A74dAC3d8560DDaBBc26aa064B764535193',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21354/thumb/filip.PNG?1639000706',
            'coingeckoId': 'filipcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MRCR',
            'name': 'Mercor Finance',
            'address': '0x155dab50F1DdeD25c099E209E7b375456a70e504',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15938/thumb/6LIc1-Sm_400x400.png?1622459039',
            'coingeckoId': 'mercor-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DXB',
            'name': 'DXBPay',
            'address': '0x155DD107ae0fB0D5872A4613F9F08914bC03a632',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19681/thumb/13590.png?1635743492',
            'coingeckoId': 'dxbpay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REVO',
            'name': 'Revomon',
            'address': '0x155040625D7ae3e9caDA9a73E3E44f76D3Ed1409',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0x155040625d7ae3e9cada9a73e3e44f76d3ed1409.png',
            'coingeckoId': 'revomon',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SKILL',
            'name': 'CryptoBlades',
            'address': '0x154A9F9cbd3449AD22FDaE23044319D6eF2a1Fab',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x154A9F9cbd3449AD22FDaE23044319D6eF2a1Fab/logo.png',
            'coingeckoId': 'cryptoblades',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'TTC',
            'name': 'Tao Te Ching',
            'address': '0x152ad7Dc399269FA65D19BD7A790Ea8aa5b23DaD',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26621/thumb/5888.png?1659074068',
            'coingeckoId': 'tao-te-ching',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VXVS',
            'name': 'Venus XVS',
            'address': '0x151B1e2635A717bcDc836ECd6FbB62B674FE3E1D',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x151b1e2635a717bcdc836ecd6fbb62b674fe3e1d.png',
            'coingeckoId': 'venus-xvs',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'TOURISTS',
            'name': 'Tourist Shiba Inu',
            'address': '0x14eB173d2b4B8d888F9886BBEC41EC1B0e18f2b6',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23878/thumb/TOURISTS-200x200px.png?1645600641',
            'coingeckoId': 'tourist-shiba-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SIMBAINU',
            'name': 'Simba Inu',
            'address': '0x14e2C19Dd223603dEF5ef4A2a6AF9D1eB03186E2',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21525/thumb/simba_inu.PNG?1639379399',
            'coingeckoId': 'simba-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VIRAL',
            'name': 'ViralUp',
            'address': '0x14d158df59Cd8ba430F669473C0E50573E58310a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26172/thumb/viralup.png?1656369113',
            'coingeckoId': 'viralup',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DND',
            'name': 'DungeonSwap',
            'address': '0x14c358b573a4cE45364a3DBD84BBb4Dae87af034',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x14c358b573a4ce45364a3dbd84bbb4dae87af034.png',
            'coingeckoId': 'dungeonswap',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'PUPPETS',
            'name': 'Puppets Arts',
            'address': '0x14bb7a637fAb7Ef189Ddb052153239cf31892D8c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25335/thumb/Puppets_Arts_200.png?1651299128',
            'coingeckoId': 'puppets-arts',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'bSRK',
            'name': 'SparkPoint',
            'address': '0x14b1166aB53A237c8cEaeE2BBc4BbCa200cb7da8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x14b1166ab53a237c8ceaee2bbc4bbca200cb7da8.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'W3B',
            'name': 'Web3 Betting',
            'address': '0x14F31E8067513a1E9b8604d105582F3760E071A5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27531/thumb/200x200.png?1664372453',
            'coingeckoId': 'web3-betting',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VFT',
            'name': 'Value Finance',
            'address': '0x14E8BCd053e68A22f239b9e9bEaD87932465D245',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23384/thumb/vft_new_%281%29.png?1644480192',
            'coingeckoId': 'value-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IDM',
            'name': 'IDM Coop',
            'address': '0x14B13E06f75E1F0Fd51ca2E699589Ef398E10F4C',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19665/thumb/8jVtkWu.png?1635736981',
            'coingeckoId': 'idm-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DCH',
            'name': 'DeChart',
            'address': '0x14ACe3d3429408bFA8562099A253172913AD71bd',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15543/thumb/91Md4Vq.jpg?1621168919',
            'coingeckoId': 'dechart',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIBEMP',
            'name': 'Shiba Inu Empire',
            'address': '0x14AAD57fB5f9A0C9ce136CF93521cBeBE14EC2e6',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20201/thumb/shibemp_200_200.png?1636768787',
            'coingeckoId': 'shiba-inu-empire',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOOT',
            'name': 'Lootex',
            'address': '0x14A9A94E555FDd54C21d7f7E328e61D7eBEce54b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22895/small/loot.png?1642850037',
            'coingeckoId': 'loot',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'MEIN',
            'name': 'Me in',
            'address': '0x14A83Bb74dA432258c4051E3976a3F8f6B02a4D9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27820/thumb/mein-symbol.png?1665978960',
            'coingeckoId': 'me-in',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AMC',
            'name': 'AMC Fight Night',
            'address': '0x1496fB27D8cF1887d21cAc161987821859CA56Ba',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17290/thumb/amc.PNG?1627252646',
            'coingeckoId': 'amc-fight-night',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HOHOHO',
            'name': 'Santa Floki v2 0',
            'address': '0x14940169E2Db1595CDD3CACd30DECC5bbB4d9f19',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23936/thumb/18344.png?1645750762',
            'coingeckoId': 'santa-floki-v2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MW2',
            'name': 'MoonWay',
            'address': '0x1481EA464DB1a24Ccd4D59799a28b00D6c2f3271',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21073/thumb/output-onlinepngtools-2.png?1638282942',
            'coingeckoId': 'moonway',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ICN',
            'name': 'I Coin V2',
            'address': '0x1480A5dc8DC864E671023DdD65e8B1fb41262EB8',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23797/thumb/Icoin-200x200.png?1645439275',
            'coingeckoId': 'i-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEX',
            'name': 'dexIRA',
            'address': '0x147E07976E1ae78287c33aAFAab87760d32E50A5',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x147e07976e1ae78287c33aafaab87760d32e50a5/logo.png',
            'coingeckoId': 'dexira',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'RLOKI',
            'name': 'Floki Rocket',
            'address': '0x1476e96FAdb37668d7680921297e2ab98Ec36c2F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20299/thumb/200-x-200.png?1636816304',
            'coingeckoId': 'floki-rocket',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTX',
            'name': 'Metagox',
            'address': '0x14737B458C1582331897da2f37F208e15FEaF5A7',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24569/thumb/metagox.png?1651745413',
            'coingeckoId': 'metagox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CTNT',
            'name': 'CryptoniteGame',
            'address': '0x145812dd822Cca602161e9E1ea96963be290b549',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23447/thumb/cbec3d4e1eeb29f9f560c827ac3a79a0.png?1644213730',
            'coingeckoId': 'cryptonite-token',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'MTVG',
            'name': 'Metaverse Box Game',
            'address': '0x1453dEa8A8adF80fabA94D169435E9E5966847E8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26220/thumb/MTVG_Logo.png?1656640344',
            'coingeckoId': 'metaverse-box-game',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FNIX',
            'name': 'Fenix Inu',
            'address': '0x14511C5Bbe1477B97DDFaFE1e3BB2b423760EF49',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27392/thumb/qmxma6p3bf7y2vlsjqcr66r7yszatn6j1wowfesm9phhvu.png?1663818844',
            'coingeckoId': 'fenix-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KRW',
            'name': 'KROWN',
            'address': '0x1446f3CEdf4d86a9399E49f7937766E6De2A3AAB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16530/thumb/KRW_token_logo_200x200.png?1624343058',
            'coingeckoId': 'krown',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HMNG',
            'name': 'Hummingbird Finance',
            'address': '0x14357D294fBabbE0fbF59503370c772d563b35b6',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15403/thumb/Untitled-design-8-removebg-preview.png?1623050079',
            'coingeckoId': 'hummingbird-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRYPT',
            'name': 'Cryptia',
            'address': '0x142Ba459218CaFDCf8F98a5FA3e139F32fccec70',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18905/thumb/cryptia.png?1634020281',
            'coingeckoId': 'cryptia',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CORX',
            'name': 'CorionX',
            'address': '0x141383CDB8158982fB3469C3e49cC82F8026d968',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13129/thumb/x_log.png?1605515405',
            'coingeckoId': 'corionx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUFFS',
            'name': 'BuffSwap',
            'address': '0x140B890BF8e2FE3E26fcD516c75728fB20b31c4f',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21189/thumb/buffsw.PNG?1638515236',
            'coingeckoId': 'buffswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TUSD',
            'name': 'TrueUSD',
            'address': '0x14016E85a25aeb13065688cAFB43044C2ef86784',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x0000000000085d4780b73119b644ae5ecd22b376.png',
            'coingeckoId': 'true-usd',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ELONGRAB',
            'name': 'Elongrab',
            'address': '0x13ea1Bea2161F3799417AeEbBE8d4443F13B847a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22532/thumb/200-200-elongrab.png?1641998355',
            'coingeckoId': 'elongrab',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLT',
            'name': 'Bullet',
            'address': '0x13c944Ef30a2B9b1e4D5CFCec65411278b7b7524',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25951/thumb/101215144.png?1654843384',
            'coingeckoId': 'bullet-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JYC',
            'name': 'Joe Yo Coin',
            'address': '0x13b8abB1cFd7BBE1F5764fE967ED049d488d9D1d',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25337/thumb/Joe_Yo_Coin_200.png?1651299958',
            'coingeckoId': 'joe-yo-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HBEE',
            'name': 'Hungry Bees',
            'address': '0x13b4b65E6CCB4e81b78Ae237E5A7Fac667c08B03',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24081/thumb/HBEE_200.png?1646289226',
            'coingeckoId': 'hungry-bees',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SD',
            'name': 'Soccers Dog',
            'address': '0x13E2450f1C834d944AA51cEcDAaaeF78fc3ddfF6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28215/thumb/sd_logo.png?1668428424',
            'coingeckoId': 'soccers-dog',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BRICKS',
            'name': 'MyBricks',
            'address': '0x13E1070E3a388e53Ec35480Ff494538f9FFc5b8D',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17311/thumb/DEV6onU.png?1627278432',
            'coingeckoId': 'mybricks',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MNG',
            'name': 'Moon Nation Game',
            'address': '0x13DfE44c7B461222e10597E517e4485Ff4766582',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16064/thumb/MoonNationLogo.png?1622720382',
            'coingeckoId': 'moon-nation-game',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DBDOGE',
            'name': 'DaddyBabyDoge',
            'address': '0x13D9c4DD5Fd9323ff1BC8E3bC087070cbf64F025',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26777/thumb/daddybabydoge.png?1660098264',
            'coingeckoId': 'daddybabydoge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUXETOKEN',
            'name': 'LuxeToken',
            'address': '0x13D5e09c1E2648cb94D1AD67eD266D581F6E1926',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28098/thumb/1974A5AA-D9A9-4FBB-BE56-F60BAB25D27D.png?1667538426',
            'coingeckoId': 'luxetoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BMIC',
            'name': 'Bitmic',
            'address': '0x13CADdDD0fB00bA2FE0Fb3D308E90bB15Ae625EE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22154/thumb/Hb_g8BVi_400x400.jpg?1640940693',
            'coingeckoId': 'bitmic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SFT',
            'name': 'Safety',
            'address': '0x13B6e448eB51fa9289EaBeB67C775f755652652A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19955/thumb/logo_200.200.png?1636340450',
            'coingeckoId': 'safety',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BEND',
            'name': 'The Bend',
            'address': '0x13AbEC309d958c7900e8DEA7d930D794981507Ad',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25616/thumb/bend200x200.png?1652855567',
            'coingeckoId': 'the-bend',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPAY',
            'name': 'SpaceY 2025',
            'address': '0x13A637026dF26F846D55ACC52775377717345c06',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20499/thumb/spacey2025.PNG?1637132488',
            'coingeckoId': 'spacey-2025',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOONPAW',
            'name': 'MoonPaw',
            'address': '0x139B2c2C6dD8ee165D41788877Df5b2eF74b1ff1',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15471/thumb/MoonPaw-Main-Transparent-2-1-e1622472182767.png?1624905041',
            'coingeckoId': 'moonpaw',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FREN',
            'name': 'Frenchie',
            'address': '0x13958e1eb63dFB8540Eaf6ed7DcbBc1A60FD52AF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x13958e1eb63dfb8540eaf6ed7dcbbc1a60fd52af.png',
            'coingeckoId': 'frenchie',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MINTME',
            'name': 'MintMe com Coin',
            'address': '0x138218c8e064ED2A011c9ff203759A8A1E23e6C8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/5127/thumb/MINTME_logo.png?1666845801',
            'coingeckoId': 'webchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CANDYLAD',
            'name': 'Candylad',
            'address': '0x136BFcdec5DB31b565052451AD806014ab9F2b38',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20938/thumb/8Q1tiqi0_400x400.jpg?1638022978',
            'coingeckoId': 'candylad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TFBX',
            'name': 'Truefeedback Token',
            'address': '0x1354E1C028Ad93eD9992eE3bFd3ee5608489439C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'LABS',
            'name': 'LABS Group',
            'address': '0x134B372f5543C5CCa30Be6a796Da032c8274bDdF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14401/thumb/CMC_logo.jpg?1664964940',
            'coingeckoId': 'labs-group',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SIL',
            'name': 'SIL Finance V2',
            'address': '0x133Bb423d9248a336D2b3086b8F44A7DbFF3a13C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16660/thumb/3Dyt_hYY_400x400.jpg?1624802958',
            'coingeckoId': 'sil-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HOLO',
            'name': 'HOLOCLEAR',
            'address': '0x1337bae199225795070DCF4f7aea733296Ba77Ea',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28077/thumb/holo200.png?1667394340',
            'coingeckoId': 'holoclear',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FFA',
            'name': 'FFA Games',
            'address': '0x1336f5D9BBa2EB910d67c4d8dA114256f8C04b2A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15751/thumb/cryptofifi.PNG?1621813991',
            'coingeckoId': 'cryptofifa',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TOOLS',
            'name': 'TOOLS',
            'address': '0x1311b352467d2B5c296881BaDEA82850bcd8f886',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x1311b352467d2b5c296881badea82850bcd8f886.png',
            'coingeckoId': 'tools',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'FREE',
            'name': 'FREEdom coin',
            'address': '0x12e34cDf6A031a10FE241864c32fB03a4FDaD739',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x12e34cdf6a031a10fe241864c32fb03a4fdad739.png',
            'coingeckoId': 'freedom-coin',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'DARK',
            'name': 'Dark Frontiers',
            'address': '0x12FC07081Fab7DE60987cAd8E8dc407B606FB2F8',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18856/thumb/dark.png?1633597226',
            'coingeckoId': 'dark-frontiers',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PST',
            'name': 'Peseta Token',
            'address': '0x12F5b3aea951eBD313ea802a1DBce2Be9369Cb93',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27067/thumb/200.png?1664009737',
            'coingeckoId': 'peseta-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NBM',
            'name': 'NFTBlackmarket',
            'address': '0x12Da2f2761038486271C99DA7e0FB4413e2B5E38',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17606/thumb/200_%289%29.png?1628647597',
            'coingeckoId': 'nftblackmarket',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTF',
            'name': 'MetaFootball',
            'address': '0x12DE91ACb5f544b37b1e66438324b8dB26A91D8a',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21945/thumb/2duK7I_h_400x400.png?1640317237',
            'coingeckoId': 'metafootball',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOGERISEUP',
            'name': 'Doge Rise Up',
            'address': '0x12D31D5DDF12784AB9fa123CE7f735c76FFcD9bB',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21201/thumb/U3nxk6ow_400x400.jpg?1638539451',
            'coingeckoId': 'doge-rise-up',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RACA',
            'name': 'Radio Caca',
            'address': '0x12BB890508c125661E03b09EC06E404bc9289040',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17841/small/ez44_BSs_400x400.jpg?1629464170',
            'coingeckoId': 'radio-caca',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'TST',
            'name': 'The Sprint Token',
            'address': '0x12Aa33065EDf46d41e42945046aAE8A6f5c1622F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27046/thumb/Icon200x200.png?1661503264',
            'coingeckoId': 'the-sprint-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTK',
            'name': 'Metakings',
            'address': '0x129385C4ACd0075e45A0C9a5177BdfEc9678A138',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21495/thumb/mtk.png?1639367403',
            'coingeckoId': 'metakings',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GNL',
            'name': 'Green Life Energy',
            'address': '0x128699dBEaFd83Ad25D16D394b7849e27A7C4607',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20350/thumb/gnl.jpeg?1636943243',
            'coingeckoId': 'green-life-energy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAYAN',
            'name': 'Saiyan Inu',
            'address': '0x127CF9704DDFcf0e9eC1Dc20f11877731D18ac34',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16302/thumb/Logo1_%283%29.png?1623717480',
            'coingeckoId': 'saiyan-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STAY',
            'name': 'STAY',
            'address': '0x127415D59E508c70A3990175C8267eb9C49b84fC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25479/thumb/stay.png?1652008638',
            'coingeckoId': 'stay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GELDF',
            'name': 'GELD Finance',
            'address': '0x1273980950cEac86C983530B90d18AA8f92c04d2',
            'decimals': 11,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19597/thumb/geld.PNG?1635489576',
            'coingeckoId': 'geld-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MVD',
            'name': 'MvPad',
            'address': '0x1252E02728eC919aB841aE9af5777095B1CBAa16',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23145/thumb/MeQ7gBjE_400x400.jpg?1643348090',
            'coingeckoId': 'mvpad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GAMI',
            'name': 'GAMI World',
            'address': '0x1236a887ef31B4d32E1F0a2b5e4531F52CeC7E75',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19841/thumb/GAMI_B.png?1664069716',
            'coingeckoId': 'gami-world',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DRAC',
            'name': 'DRAC Network',
            'address': '0x123458C167a371250d325Bd8B1ffF12C8AF692A7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27438/thumb/200X200.png?1663934782',
            'coingeckoId': 'drac-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BRAWL',
            'name': 'Meta Brawl',
            'address': '0x122edffCa1c940A82574B21031623f063fC8C7F9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21858/thumb/logo-4-9.png?1640147473',
            'coingeckoId': 'meta-brawl',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GKF',
            'name': 'Galatic Kitty Fighters',
            'address': '0x122De580A9456F4015aA3eC095485Ed7656Ed63F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23397/thumb/gkf.png?1644134110',
            'coingeckoId': 'galatic-kitty-fighters',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALT',
            'name': 'altfolio',
            'address': '0x121BCf841e987CBC41541496100Fd5741C75C1c9',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26978/thumb/logo_%287%29.png?1661169229',
            'coingeckoId': 'altfolio',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PID',
            'name': 'PIDAO',
            'address': '0x1215Ed20aa507578ca352E195016F289e7A17f3A',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20062/thumb/pid.png?1639969336',
            'coingeckoId': 'pidao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DKS',
            'name': 'DarkShield',
            'address': '0x121235cfF4c59EEC80b14c1d38B44e7de3A18287',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21737/thumb/dks.jpg?1639971988',
            'coingeckoId': 'darkshield',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDX',
            'name': 'USDX',
            'address': '0x1203355742e76875154C0D13eB81DCD7711dC7d9',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x1203355742e76875154C0D13eB81DCD7711dC7d9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SMTF',
            'name': 'SmartFi',
            'address': '0x11fD9ED04f1Eb43eF9df6425a6990609F2468895',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25264/thumb/618d410e849c474a30fe91ae_SMTF_token_-_transparent_background.png?1651044829',
            'coingeckoId': 'smartfi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MYP',
            'name': 'Mystic Poker',
            'address': '0x11f59d29Ba0A7B765C4a454fd46Ff40525a85402',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26846/thumb/Mystic_Poker_logo_%28transparent_background%29.png?1660459675',
            'coingeckoId': 'mystic-poker',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MPAD',
            'name': 'MultiPad',
            'address': '0x11d1ac5ec23e3a193E8a491a198f5fc9ee715839',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18145/thumb/200_x_200_%281%29.png?1630670156',
            'coingeckoId': 'multipad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADTX',
            'name': 'Aurora Dimension',
            'address': '0x11Fe7a37f2923566cAA0dE7D37C868631c695205',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21198/thumb/aurora.png?1643525166',
            'coingeckoId': 'aurora-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MILKY',
            'name': 'MilkyWay',
            'address': '0x11F814bf948c1e0726c738c6d42fA7234f32b6E8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26263/thumb/icon_200x200px-01.png?1656981891',
            'coingeckoId': 'milkyway',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '2LC',
            'name': '2local',
            'address': '0x11F6ECC9E2658627e0876212f1078b9F84d3196e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16062/thumb/2local_symbol-512px_margin.png?1623049696',
            'coingeckoId': '2local-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NBL',
            'name': 'Nobility',
            'address': '0x11F331c62AB3cA958c5212d21f332a81c66F06e7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26873/thumb/nobility.jpg?1660550600',
            'coingeckoId': 'nobility',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RBXS',
            'name': 'RBXSamurai',
            'address': '0x11DBF6E897804fbed56CfF8AC54Fc7BDAe50E86a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22342/thumb/rbxs.jpg?1641536586',
            'coingeckoId': 'rbxsamurai',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XFR',
            'name': 'The Fire',
            'address': '0x11C3f759c0AAE61078ec923Af15F2f6FA2D326CE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16331/thumb/XFR-logo.png?1623748946',
            'coingeckoId': 'the-fire-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KSHIB',
            'name': 'Kaiken Shiba',
            'address': '0x11C0c93035d1302083eB09841042cFa582839A8C',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17462/thumb/kaikenshiba_150x.png?1627887502',
            'coingeckoId': 'kaiken-shiba',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SURV',
            'name': 'Survival Game Online',
            'address': '0x1180C484f55024C5Ce1765101f4efaC1e7A3F6d4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23155/thumb/61f253fde5546b0001579b6c_SURV_logo.png?1643352558',
            'coingeckoId': 'survival-game-online',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CLU',
            'name': 'CluCoin',
            'address': '0x1162E2EfCE13f99Ed259fFc24d99108aAA0ce935',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15685/thumb/CoinGecko.png?1623337199',
            'coingeckoId': 'clucoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KCCPAD',
            'name': 'KCCPad',
            'address': '0x11582Ef4642B1e7F0a023804B497656E2663bC9B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16994/thumb/kcc.png?1625999319',
            'coingeckoId': 'kccpad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AGF',
            'name': 'Augmented Finance',
            'address': '0x114597F4260CAf4cDe1eeB0B9D9865B0B7b9a46a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20077/thumb/200x200-agf.png?1636452467',
            'coingeckoId': 'augmented-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CDONK',
            'name': 'Club Donkey',
            'address': '0x11414DfE93d9A8907937ba296E850447d9fC4423',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20468/thumb/11698.png?1637064828',
            'coingeckoId': 'club-donkey',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KLC',
            'name': 'Kalycoin',
            'address': '0x1136634119DC04F7Cf1aFd954C5d229CF91CCF41',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25927/thumb/ZfXZEcqB_400x400.jpg?1654674400',
            'coingeckoId': 'kalycoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '1INCH',
            'name': '1inch',
            'address': '0x111111111117dC0aa78b770fA6A738034120C302',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x111111111117dc0aa78b770fa6a738034120c302.png',
            'coingeckoId': '1inch',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'MIDA',
            'name': 'MIDA',
            'address': '0x1104918312cc9Ad88EadaBE07b5110cAE99583f6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27337/thumb/_MIDA_dark.png?1663499691',
            'coingeckoId': 'mida',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'TEDDY',
            'name': 'Teddy Doge',
            'address': '0x10f6f2b97F3aB29583D9D38BaBF2994dF7220C21',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23389/thumb/17859.png?1644112621',
            'coingeckoId': 'teddy-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAIT',
            'name': 'Baitcoin',
            'address': '0x10f6F43d8321A3C86c2986BA4753ceBEba477BAa',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17616/thumb/bait.PNG?1628664653',
            'coingeckoId': 'baitcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLST',
            'name': 'Crypto Legions Bloodstone',
            'address': '0x10cb66ce2969d8c8193707A9dCD559D2243B8b37',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26049/thumb/pCJvGq0A_%281%29.png?1655450454',
            'coingeckoId': 'crypto-legions-bloodstone',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORYX',
            'name': 'OryxFi',
            'address': '0x10bb58010CB58e7249099eF2efdFfe342928B639',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26146/thumb/NS6sVTy.jpg?1656040209',
            'coingeckoId': 'oryxfi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RET',
            'name': 'Renewable Energy',
            'address': '0x10b9dd394467f2CFbc769e07E88dC7e2c41B0965',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25457/thumb/LOGO.png?1651815990',
            'coingeckoId': 'renewable-energy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MNTG',
            'name': 'Monetas',
            'address': '0x10adF50e15611d5A4DE3bd21F0DB7f3491A8ae0f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18700/thumb/logo_-_2021-10-01T091320.526.png?1633050813',
            'coingeckoId': 'monetas',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFEZONE',
            'name': 'SafeZone  OLD ',
            'address': '0x10F181B87E2A4Af6120452A29570fcf74B082c16',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15485/thumb/safe.PNG?1621038627',
            'coingeckoId': 'safezone',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AWT',
            'name': 'Airdrop World',
            'address': '0x10E73d296FB247dC6c5F134B7DC2aa3583a9E3b0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14742/thumb/20210307_101132.png?1618006108',
            'coingeckoId': 'airdrop-world',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHEFCAKE',
            'name': 'ChefCake',
            'address': '0x10E24a15bB2B49F6211401af82F0f3EbEd05BF71',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19976/thumb/chefcake.png?1636351365',
            'coingeckoId': 'chefcake',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MVX',
            'name': 'Mavaverse',
            'address': '0x10C9524DBF934B3B625Dce3bDC0efdc367F4E84b',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23830/thumb/mvx.png?1652433931',
            'coingeckoId': 'mavaverse-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RIV2',
            'name': 'RiseUpV2',
            'address': '0x10C723D4a9180C468055a548BF58d7AB279Fe4Ac',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20686/thumb/logo.png?1637557699',
            'coingeckoId': 'riseupv2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MGOD',
            'name': 'MetaGods',
            'address': '0x10A12969cB08a8d88D4BFB5d1FA317d41e0fdab3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21666/thumb/n9xlniyOphEZp40cR7OnXO1p5H8kI8rLGn6vbx8AaVGiYi250HIeNISTx7eMHU-Ym5XRc9nFPmNXfRZmr3QglH1Svte4Tisu2EZPU3JVFKlPLLnQwDXhodgYcSt0Xb33kyrA_yqFEZnMw1q5qi-4KgdHd4bHv7MKNz2rF3MuA_RWdylgpB3xdUoaxoiMLqzlVbEatJYnDQyhqvh.jpg?1639704500',
            'coingeckoId': 'metagods',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RIPH',
            'name': 'Harambe Protocol',
            'address': '0x10964C2ffDEA1e99B5e26D102516d9b03368915f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22084/thumb/RIPH_Coin_200px.png?1640761470',
            'coingeckoId': 'harambe-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'aETHb',
            'name': 'Ankr Reward Earning Staked ETH',
            'address': '0x1075bEa848451a13fD6F696b5D0FdA52743E6439',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1659433664755_481994250643091.jpg',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'FOOTIE',
            'name': 'Footie Plus',
            'address': '0x1062b27581401aAC6B32A41D9507D1986f8b23e7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17628/thumb/footie.jpeg?1628683039',
            'coingeckoId': 'footie-plus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CBI',
            'name': 'Cyptobit Network',
            'address': '0x1060Ac0554fac1bD674A9927f7e7Ac3E234cD1D0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28186/thumb/200-cbi.png?1668258630',
            'coingeckoId': 'cyptobit-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AGGL',
            'name': 'aggle io',
            'address': '0x1042aA383CAb145dc77121ca75650804A5c134ff',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22265/thumb/aggle.PNG?1641336897',
            'coingeckoId': 'aggle-io',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAT',
            'name': 'Basic Attention Token',
            'address': '0x101d82428437127bF1608F699CD651e6Abf9766E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x0d8775f648430679a709e98d2b0cb6250d2887ef.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PWARS',
            'name': 'PETWARS',
            'address': '0x101Fd1FB9bc912aCF6D0120824F71bB6017724c5',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x101fd1fb9bc912acf6d0120824f71bb6017724c5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DBA',
            'name': 'Digital Bank of Africa',
            'address': '0x1006EA3289b833B6720AAA82746990ec77De8c36',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19177/thumb/DBA-icon-black-08-01.jpg?1635159644',
            'coingeckoId': 'digital-bank-of-africa',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DYOR',
            'name': 'DYOR',
            'address': '0x10051147418C42218986CeDD0aDC266441F8a14f',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23654/thumb/13987.png?1644926775',
            'coingeckoId': 'dyor',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'STRIP',
            'name': 'Strip Finance',
            'address': '0x0fe178b9a471b3698cB6FcB4625DF9A756A2C55C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20289/thumb/strip_finance.PNG?1636813662',
            'coingeckoId': 'strip-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHOO',
            'name': 'SHOOT',
            'address': '0x0fcc11F873360450a1afD8CB7Cfe0a9d787cc25E',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21544/thumb/shoo.jpeg?1639455390',
            'coingeckoId': 'shoot',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'Warden',
            'name': 'WardenSwap Token',
            'address': '0x0fEAdcC3824E7F3c12f40E324a60c23cA51627fc',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x0feadcc3824e7f3c12f40e324a60c23ca51627fc.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'CDTC',
            'name': 'DeCredit',
            'address': '0x0fAf802036E30B4b58a20C359012821152872397',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18921/thumb/horizontal-logo.png?1633924734',
            'coingeckoId': 'decredit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATC',
            'name': 'Atlantis Coin',
            'address': '0x0fAD86Cc1b412b330494E350Bf1dC1994AC55c9e',
            'decimals': 2,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27308/thumb/ATC.png?1665646737',
            'coingeckoId': 'atlantis-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHPZ',
            'name': 'Chipz',
            'address': '0x0fABCB70eeDA798F9241F4bb11cceEa7d93B157a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x0fabcb70eeda798f9241f4bb11cceea7d93b157a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'XMN',
            'name': 'Xmine',
            'address': '0x0fA9651a0ecC19906843C13c60443300B9d37355',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18733/thumb/n96tvvsB_400x400.jpg?1633297368',
            'coingeckoId': 'xmine',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EARNX',
            'name': 'EarnX V2',
            'address': '0x0f76142D83ddEF359753ccA33647Cc4dCEe1C6d1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24234/thumb/earnx.PNG?1646990052',
            'coingeckoId': 'earnx-v2',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'FLOKIM',
            'name': 'Flokimooni',
            'address': '0x0f5351B9EAefd6687dfF143DE6eA5d01cB9c1205',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19449/thumb/flokim_logo.png?1635395531',
            'coingeckoId': 'flokimooni',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FIRE',
            'name': 'Firework Games',
            'address': '0x0f0Dd5E2c0e0c4A41F8908D73D36B8D142F6745a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25063/thumb/firetoken.png?1649993446',
            'coingeckoId': 'firetoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JETS',
            'name': 'JeToken',
            'address': '0x0f005Dfe97c5041E538B7075915b2eE706677C26',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21104/thumb/fav-icon.png?1638327812',
            'coingeckoId': 'jetoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWAPP',
            'name': 'SWAPP Protocol',
            'address': '0x0efE961C733FF46ce34C56a73eba0c6a0E18E0F5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16216/thumb/swapp.png?1634259998',
            'coingeckoId': 'swapp',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SDAO',
            'name': 'SincereDogeDAO',
            'address': '0x0edF75489041a0eFE404E81b149Cfd8CEFAe4Fa0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26343/thumb/YpyYshd.png?1657586442',
            'coingeckoId': 'sinceredogedao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPG',
            'name': 'Space Crypto',
            'address': '0x0ecAF010fC192E2d5cBeB4dFb1FeE20fbd733aa1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1645065436327_14786590885670825.jpg',
            'coingeckoId': 'space-crypto',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'MOOV',
            'name': 'dotmoovs',
            'address': '0x0ebd9537A25f56713E34c45b38F421A1e7191469',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15817/thumb/dotmoovs-symbol-gradient.png?1635332626',
            'coingeckoId': 'dotmoovs',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRC',
            'name': 'NFT Crosschain',
            'address': '0x0eD30fe8FD51C593b077315a6Cb60a9Fb9B15155',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27013/thumb/j3bsk-13_400x400.png?1661424066',
            'coingeckoId': 'nft-crosschain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BNTX',
            'name': 'Bintex Futures',
            'address': '0x0eC04ECe89609E545b8768E303986421FFc32eaF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12117/thumb/Bintexfutures_Icon_Logo.png?1597272672',
            'coingeckoId': 'bintex-futures',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FTS',
            'name': 'Fantasy Monster',
            'address': '0x0e804A8b4DC7D8afC9d38CE852512968881d7e10',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24517/thumb/logo200.png?1647961130',
            'coingeckoId': 'fantasy-monster',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NOC',
            'name': 'NewOriginToken',
            'address': '0x0e6457233baA6477F660666624Dc29d8e88d9fdf',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x0e6457233baa6477f660666624dc29d8e88d9fdf/logo.png',
            'coingeckoId': 'new-origin',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SOKU',
            'name': 'SokuSwap',
            'address': '0x0e4B5Ea0259eB3D66E6FCB7Cc8785817F8490a53',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18378/thumb/VCIEHaG.png?1653547690',
            'coingeckoId': 'sokuswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IQ',
            'name': 'IQ',
            'address': '0x0e37d70b51ffA2B98b4D34a5712C5291115464e3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/5010/thumb/YAIS3fUh.png?1626267646',
            'coingeckoId': 'everipedia',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CAIRO',
            'name': 'Cairo Finance  OLD ',
            'address': '0x0e2eca3aB92ed805F6fAC946078155fc256572be',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26091/thumb/Cairo-Finance-Logo-200x200-CMC.png?1655779391',
            'coingeckoId': 'cairo-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BRGX',
            'name': 'Bridges Exchange',
            'address': '0x0e2114955023B736fa97D9E2FCDe2836d10b7A5C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20875/thumb/bridgescrypto.PNG?1637814449',
            'coingeckoId': 'bridge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XIASI',
            'name': 'Xiasi Inu',
            'address': '0x0e20E3216EA172fcf9eAa19723b119e090fD353f',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16116/thumb/NvQK4SV.png?1623680506',
            'coingeckoId': 'xiasi-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOONMOON',
            'name': 'MoonMoon',
            'address': '0x0e0e877894a101Ad8711AE3A0194Fa44Ca837a79',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x0e0e877894a101ad8711ae3a0194fa44ca837a79.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BETU',
            'name': 'Betu',
            'address': '0x0df1B3F30865C5b324797F8dB9d339514caC4e94',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18184/thumb/BETU_Token_-_200x200.png?1630904644',
            'coingeckoId': 'betu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AEQ',
            'name': 'Aequinox',
            'address': '0x0dDef12012eD645f12AEb1B845Cb5ad61C7423F5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26980/thumb/token-aeq-round%283%29.png?1661174596',
            'coingeckoId': 'aequinox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SMX',
            'name': 'SolarMineX',
            'address': '0x0dA967a07D833c4B322221cF903Ce48f3CD9e4b7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21331/thumb/solarmine.PNG?1638947595',
            'coingeckoId': 'solarminex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SBDO',
            'name': 'bDollar Share',
            'address': '0x0d9319565be7f53CeFE84Ad201Be3f40feAE2740',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x0d9319565be7f53cefe84ad201be3f40feae2740.png',
            'coingeckoId': 'bdollar-share',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HMT',
            'name': 'Human Token',
            'address': '0x0d501B743F22b641B8C8dfe00F1AAb881D57DDC7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'INUJUMP',
            'name': 'Inu Jump and the Temple of Shiba',
            'address': '0x0d3A973fA626B797cd4498f65D62BBDf69fC353F',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22758/thumb/logo_%282%29.png?1642573638',
            'coingeckoId': 'inu-jump-and-the-temple-of-shiba',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DINGER',
            'name': 'Dinger',
            'address': '0x0d3843F92D622468BA67DF5A6a4149B484a75af3',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19443/thumb/dinger.png?1661498131',
            'coingeckoId': 'dinger-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HPL',
            'name': 'HappyLand',
            'address': '0x0d0621aD4EC89Da1cF0F371d6205229f04bCb378',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21450/thumb/happy_land.PNG?1639174498',
            'coingeckoId': 'happyland',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZODI',
            'name': 'Zodium',
            'address': '0x0ccA2F5561Bb0fCa88e5b9B48b7Fbf000349c357',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21181/thumb/s4TSr4S_-_Imgur.png?1638505121',
            'coingeckoId': 'zodium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTCBR',
            'name': 'Bitcoin BR',
            'address': '0x0cF8e180350253271f4b917CcFb0aCCc4862F262',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21205/thumb/btcbr.png?1661666691',
            'coingeckoId': 'bitcoin-br',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NCAT',
            'name': 'NCAT',
            'address': '0x0cF011A946f23a03CeFF92A4632d5f9288c6C70D',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14556/thumb/ncat_logo.jpg?1617002485',
            'coingeckoId': 'nyan-cat',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KROOT',
            'name': 'K Root Wallet',
            'address': '0x0cD3a8Ce155A8d9DAAF19E5caa642e72a2a24Cd8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18141/thumb/kroot.PNG?1630652879',
            'coingeckoId': 'k-root-wallet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KING',
            'name': 'CryptoBlades Kingdoms',
            'address': '0x0cCD575bf9378c06f6DCa82f8122F570769F00C2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18728/thumb/cryptoblades-kingdoms.jpeg?1633228872',
            'coingeckoId': 'cryptoblades-kingdoms',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LTY',
            'name': 'Ledgity',
            'address': '0x0cBE5C4F318035b866AAcFaf7D018FB4C5F920F3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0x0cbe5c4f318035b866aacfaf7d018fb4c5f920f3/7580f6330ff629247cf4cbee9d84084f.png',
            'coingeckoId': 'ledgity',
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CDT',
            'name': 'CheckDot',
            'address': '0x0cBD6fAdcF8096cC9A43d90B45F65826102e3eCE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20370/thumb/token-200x200_%281%29.png?1636949075',
            'coingeckoId': 'checkdot',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'EXN',
            'name': 'Exeno Coin',
            'address': '0x0c9b3aB1bd0CF0745625381F5C3Aa1CD9BBc7Abb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25845/thumb/20323.png?1654145185',
            'coingeckoId': 'exeno',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FST',
            'name': 'Fans Squid',
            'address': '0x0c30AAa3BA8D581A46Db87e3904f1c482d2f26e9',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20846/thumb/Ye1rP4dW_400x400.jpg?1637748058',
            'coingeckoId': 'fans-squid',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CC',
            'name': 'CloudChat',
            'address': '0x0c2bfA54d6d4231b6213803dF616a504767020eA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25932/thumb/IMG_20220608_151712_912.png?1654758140',
            'coingeckoId': 'cloudchat',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TITA',
            'name': 'Titan Hunters',
            'address': '0x0c1253a30da9580472064a91946c5ce0C58aCf7f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20588/thumb/tita.png?1638343393',
            'coingeckoId': 'titan-hunters',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ME',
            'name': 'Moon Eye',
            'address': '0x0c088bed7207E5E37BD3022d58aE40D4a3490ABc',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24754/thumb/MOO-NEYE.png?1648788676',
            'coingeckoId': 'moon-eye',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SCATE',
            'name': 'Sincere Cate',
            'address': '0x0bf3163Be50D06Df23ae52F3ae123AcE838F1C1A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25178/thumb/Whats-App-Image-2022-03-20-at-4-35-57-PM.jpg?1650541568',
            'coingeckoId': 'sincere-cate',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOSF',
            'name': 'SOS Fidelity',
            'address': '0x0bd669a4cE836f70c3814b22a142116851D2c04A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26088/thumb/SOSF_200x200.png?1655778376',
            'coingeckoId': 'sosf',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BATH',
            'name': 'Battle Hero',
            'address': '0x0bc89aa98Ad94E6798Ec822d0814d934cCD0c0cE',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x0bc89aa98Ad94E6798Ec822d0814d934cCD0c0cE.png',
            'coingeckoId': 'battle-hero',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'BIGB',
            'name': 'Bigh Bull',
            'address': '0x0bCF5693655A159bd7D9DC5064de9BD692a7b7C6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25590/thumb/logo.png?1652769453',
            'coingeckoId': 'bigh-bull',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HDFL',
            'name': 'Hyper Deflate',
            'address': '0x0b4922132ec425806Fd8AEE94b5788F9B70D60ca',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16640/thumb/hdfl.PNG?1624585288',
            'coingeckoId': 'hyper-deflate',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ULTRA',
            'name': 'UltraSafe',
            'address': '0x0b3f42481C228F70756DbFA0309d3ddC2a5e0F6a',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15503/thumb/YjaL3nG.png?1621086488',
            'coingeckoId': 'ultrasafe',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'SHON',
            'name': 'Shon',
            'address': '0x0b34D4A7C5BFc7004b9a193F8309523E99ca766e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17135/thumb/200x200_LOGO.png?1626425839',
            'coingeckoId': 'shontoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETHM',
            'name': 'Ethereum Meta',
            'address': '0x0b33542240d6fA323c796749F6D6869fdB7F13cA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/6586/thumb/ethereum-meta.png?1548125409',
            'coingeckoId': 'ethereum-meta',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IDIA',
            'name': 'Impossible Finance Launchpad',
            'address': '0x0b15Ddf19D47E6a86A56148fb4aFFFc6929BcB89',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x0b15Ddf19D47E6a86A56148fb4aFFFc6929BcB89.png',
            'coingeckoId': 'idia',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'APE',
            'name': 'ApeCoin',
            'address': '0x0b079B33B6e72311c6BE245F9f660CC385029fc3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1647685207270_7574985937300007.jpg',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'TBT',
            'name': 'TheBoring',
            'address': '0x0ae1bD8E41AE13776D5D038Ce5316df21e00fD15',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23885/thumb/200x200.png?1645601791',
            'coingeckoId': 'theboringtoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MMO',
            'name': 'MMOCoin',
            'address': '0x0aa086e7A108D387dE63294fe2A88B05820a9855',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/4364/thumb/xWGfHzg.png?1547039713',
            'coingeckoId': 'mmocoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VERSA',
            'name': 'VersaGames',
            'address': '0x0aCfc95A193009F1EA45b7fAEEF2d5e4a47c21b4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'GSK',
            'name': 'Game Stake',
            'address': '0x0a85fDA537395b85082d4C3E1b0a885d2beADE75',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27527/thumb/IMG_20220927_052445_104.jpg?1664371414',
            'coingeckoId': 'game-stake',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OURO',
            'name': 'Ouro Stablecoin',
            'address': '0x0a4FC79921f960A4264717FeFEE518E088173a79',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22767/thumb/1AzbOG9Q_400x400.png?1642575842',
            'coingeckoId': 'ouro-stablecoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RFOX',
            'name': 'RFOX',
            'address': '0x0a3A21356793B49154Fd3BbE91CBc2A16c0457f5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x0a3A21356793B49154Fd3BbE91CBc2A16c0457f5/logo.png',
            'coingeckoId': 'redfox-labs-2',
            'listedIn': [
                'coingecko',
                'pancake',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DIGIS',
            'name': 'DigiSwap',
            'address': '0x0Ff81F18bCb9519ac6027c732D196945CA4D2a9A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27202/thumb/digiswap.jpeg?1662603726',
            'coingeckoId': 'digiswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NASSR',
            'name': 'Alnassr FC Fan Token',
            'address': '0x0Fc85D9F186A01A8b315cF23Dcf1a0CbDF1A9ccB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25121/thumb/r4YopolZ_400x400.jpg?1650374129',
            'coingeckoId': 'alnassr-fc-fan-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TARAL',
            'name': 'Tarality',
            'address': '0x0Fc812b96De7e910878039121938f6d5471b73Dc',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21385/thumb/Tarality_Logo.png?1639034652',
            'coingeckoId': 'tarality',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FTE',
            'name': 'Fishy Tank',
            'address': '0x0FEB3BDf0D619191a25BfAe0b8069164511Cd8c9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19041/thumb/12748.png?1634252556',
            'coingeckoId': 'fishy-tank-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KAZAMA',
            'name': 'Kazama Senshi',
            'address': '0x0FEA9F83E029F54a46F8e8dd507BdD59Ed1C57Ac',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27818/thumb/kazama.png?1665923101',
            'coingeckoId': 'kazama-senshi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFD',
            'name': 'Feisty Doge NFT',
            'address': '0x0FE9778c005a5A6115cBE12b0568a2d50b765A51',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DEFLY',
            'name': 'Deflyball',
            'address': '0x0FE6A599C280853621A11C12e1a68E6949CbD08A',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25281/thumb/OJ2HPSh-_400x400.png?1651127421',
            'coingeckoId': 'deflyball',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEVO',
            'name': 'DeVolution',
            'address': '0x0FD98b8C58560167A236f1D0553A9c2a42342ccf',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20353/thumb/Devolution-200x200.png?1636943721',
            'coingeckoId': 'devolution',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'PERL',
            'name': 'PERL eco',
            'address': '0x0F9E4D49f25de22c2202aF916B681FBB3790497B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x0f9e4d49f25de22c2202af916b681fbb3790497b.png',
            'coingeckoId': 'perlin',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'OLOID',
            'name': 'OLOID',
            'address': '0x0F6266A9e9214ea129D4A001E9541d643a34C772',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25950/thumb/OLOID-COIN-3-200x200.png?1654843124',
            'coingeckoId': 'oloid',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NGL',
            'name': 'Gold Fever Native Gold',
            'address': '0x0F5d8CD195a4539bcf2eC6118C6dA50287c6d5f5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20633/thumb/2ypNydrG_400x400.jpg?1637338673',
            'coingeckoId': 'gold-fever-native-gold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RITE',
            'name': 'ritestream',
            'address': '0x0F5D54b27bDb556823F96f2536496550f8816dC5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24130/thumb/rite.png?1646482219',
            'coingeckoId': 'ritestream',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'COV',
            'name': 'Covesting',
            'address': '0x0F237Db17AA4E6dE062e6f052bD9C805789b01C3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/1950/thumb/covesting.png?1547036237',
            'coingeckoId': 'covesting',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HELLO',
            'name': 'HELLO',
            'address': '0x0F1cBEd8EFa0E012AdbCCB1638D0aB0147D5Ac00',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27891/thumb/uFXN2S1N_400x400.jpeg?1666248413',
            'coingeckoId': 'hello-labs',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TAMA',
            'name': 'Tama Finance',
            'address': '0x0F1C6791a8b8D764c78dd54F0A151EC4D3A0c090',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15702/thumb/Q0bhsxl.png?1621576078',
            'coingeckoId': 'tama-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHECK',
            'name': 'Paycheck',
            'address': '0x0F17771cCF1B990DbE2a6D7f61183C9b29997e36',
            'decimals': 6,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20251/thumb/Paycheck-Token-Logo-Square-200-X200-X.png?1659069270',
            'coingeckoId': 'paycheck-defi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LPG',
            'name': 'LPGToken',
            'address': '0x0Ee249311cd882ca360567960ce9559F5d4184c6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ATOM',
            'name': 'Cosmos Hub',
            'address': '0x0Eb3a705fc54725037CC9e008bDede697f62F335',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x0eb3a705fc54725037cc9e008bdede697f62f335.png',
            'coingeckoId': 'cosmos',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MCAT',
            'name': 'Meta Cat',
            'address': '0x0EaD9d6Bda8408cC34e938Ea35559Db738A7c76a',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19735/thumb/200.png?1635785234',
            'coingeckoId': 'meta-cat',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HEFI',
            'name': 'HealthFi App',
            'address': '0x0EFfeC9798e84b70c9136F9E086fDD5baaD4A2d5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26574/thumb/200x200_hefi_symbol_logo.png?1658885771',
            'coingeckoId': 'healthfi-app',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNIQO',
            'name': 'Uniqo',
            'address': '0x0ED82faE72b6833Ce9cf1928128FC828f5c59e77',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28271/thumb/logo_coingecko.png?1669002595',
            'coingeckoId': 'uniqo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADOGE',
            'name': 'AmazingDoge',
            'address': '0x0EBc30459551858e81306d583025d12C7d795FA2',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24052/thumb/aZ3d84N.jpg?1650619369',
            'coingeckoId': 'amazingdoge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRW',
            'name': 'Crowdy',
            'address': '0x0EA95B72E70C7fcB5bAbB877876Ea5152B0e357a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27098/thumb/coincrowdify2.png?1661999843',
            'coingeckoId': 'crowdy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GER',
            'name': 'Ginza Eternity Reward',
            'address': '0x0E9B29aa5aed3D65867Db8B980484C8C49A7F915',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21482/thumb/GER.png?1639450319',
            'coingeckoId': 'ginza-eternity-reward',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HODL',
            'name': 'HODL',
            'address': '0x0E9766dF73973abCfEDDE700497c57110ee5c301',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15546/thumb/hodl.png?1643459301',
            'coingeckoId': 'hodl-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PHI',
            'name': 'PHI Token',
            'address': '0x0E8c9611bc2fefB0a0adEb3f12EE7b11aB206c49',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'UNCL',
            'name': 'UNCL',
            'address': '0x0E8D5504bF54D9E44260f8d153EcD5412130CaBb',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13102/thumb/uncl_logo.png?1605230945',
            'coingeckoId': 'uncl',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BUNI',
            'name': 'Bunicorn',
            'address': '0x0E7BeEc376099429b85639Eb3abE7cF22694ed49',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1636342284095_16387264595202877.png',
            'coingeckoId': 'bunicorn',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'RYOSHI',
            'name': 'Ryoshi',
            'address': '0x0E5f989ce525acC4ee45506AF91964F7f4C9f2e9',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16394/thumb/RyoshiLogo.png.jpg?1626869344',
            'coingeckoId': 'ryoshi-token',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'CHIP',
            'name': 'Chip',
            'address': '0x0E5c51cE714bd319A11BFb26BF0e13D8CEED1Ebe',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22304/thumb/16877.png?1641445793',
            'coingeckoId': 'chip',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BT',
            'name': 'Bozkurt',
            'address': '0x0E5366c4B3Eb849A711932c027fb0d2D2D834846',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20138/thumb/bozkurttokenlogo_%282%29.png?1636531704',
            'coingeckoId': 'bozkurt-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CBD',
            'name': 'Greenheart CBD',
            'address': '0x0E2b41eA957624A314108cc4E33703e9d78f4b3C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15284/thumb/GreenHeart-CBD-Logo-200x200px.png?1622197407',
            'coingeckoId': 'greenheart-cbd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RMAI',
            'name': 'RMAI',
            'address': '0x0E2200f49c7041348e470850A734c0b65732032D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'RouterProtocol'
            ]
        },
        {
            'symbol': 'CAKE',
            'name': 'PancakeSwap',
            'address': '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82.png',
            'coingeckoId': 'pancakeswap-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'ZYTH',
            'name': 'Uzyth',
            'address': '0x0Df044b7f0aadfEaB21bC134fE525A19484Ac133',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21909/thumb/TTuwJNdp_400x400.jpg?1640236951',
            'coingeckoId': 'uzyth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'nuBTC',
            'name': 'Unvaxxed Sperm',
            'address': '0x0DeA5Ac2898E6Aa637Ece0db750Fd6D35e460392',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x0dea5ac2898e6aa637ece0db750fd6d35e460392/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'IDNA',
            'name': 'Idena',
            'address': '0x0De08C1AbE5fB86Dd7FD2ac90400AcE305138d5B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/10548/thumb/idena200x200.png?1580852687',
            'coingeckoId': 'idena',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STAX',
            'name': 'StableXSwap',
            'address': '0x0Da6Ed8B13214Ff28e9Ca979Dd37439e8a88F6c4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x0Da6Ed8B13214Ff28e9Ca979Dd37439e8a88F6c4/logo.png',
            'coingeckoId': 'stablexswap',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'VANCAT',
            'name': 'Vancat',
            'address': '0x0Da1774e58eD28fF9749340F116055F8d836A7C8',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24287/thumb/RTXxbEj__400x400.jpg?1647246056',
            'coingeckoId': 'vancat-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LIBERO',
            'name': 'Libero Financial',
            'address': '0x0DFCb45EAE071B3b846E220560Bbcdd958414d78',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23971/thumb/libero.png?1645869416',
            'coingeckoId': 'libero-financial',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WE',
            'name': 'Wanda Exchange',
            'address': '0x0DD3a140346a94d403Ac62385DaaF5a86b50e752',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19239/thumb/wanda.PNG?1634775471',
            'coingeckoId': 'wanda-exchange',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FIL',
            'name': 'Binance Peg Filecoin',
            'address': '0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x0d8ce2a99bb6e3b7db580ed848240e4a0f9ae153.png',
            'coingeckoId': 'binance-peg-filecoin',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'S3EK',
            'name': 'S3EK',
            'address': '0x0D5372267343Ede4802b9c96B44e746C3698e770',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://storage.googleapis.com/xy-finance-images/token-list/Seek%20v3%20Twitter%20header%20PF.png',
            'coingeckoId': null,
            'listedIn': [
                'xyfinance'
            ]
        },
        {
            'symbol': 'AD',
            'name': 'Angel Dust',
            'address': '0x0D536B28Cb33226DBab5B3B086b2c257F859E16B',
            'decimals': 4,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27475/thumb/odxhNmxsQTOcmhLv9udx_7jn37ENNEEs0ZZSz.jpg?1664197786',
            'coingeckoId': 'angel-dust',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MZ',
            'name': 'MetaZilla',
            'address': '0x0D4992E48278aA7F7C915f820743d9FaB7FeA713',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20369/thumb/metazilla_auto_x2.png?1636948937',
            'coingeckoId': 'metazilla',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SCB',
            'name': 'SpaceCowBoy',
            'address': '0x0D36cC179019dB9A65aa3B85db59E4Bb52df0B12',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14178/thumb/AUC3R40.png?1615105143',
            'coingeckoId': 'spacecowboy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UPI',
            'name': 'Pawtocol',
            'address': '0x0D35A2B85c5A63188d566D104bEbf7C694334Ee4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12186/thumb/pawtocol.jpg?1597962008',
            'coingeckoId': 'pawtocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRDO',
            'name': 'TRONADO',
            'address': '0x0D2af8e1F7Dec481d17e87Cddb13e870cB0B034b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27130/thumb/trondao.png?1662022333',
            'coingeckoId': 'tronado',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EBYT',
            'name': 'EarthByt',
            'address': '0x0D1AfEcE252fF513C5D210AEAE88f6C7D37e6ab2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23491/thumb/Untitled-design-17.png?1644221335',
            'coingeckoId': 'earthbyt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STEP',
            'name': 'Step Hero Soul',
            'address': '0x0CCA95FD87441DCD90fDDB4e34526C1B3cc6F660',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18633/thumb/11965.png?1632715406',
            'coingeckoId': 'step-hero-soul',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CU',
            'name': 'Catch Up',
            'address': '0x0CA4244633114de94A2868155AB4E84ca6b0fe48',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27184/thumb/logo_catchup-03.png?1662431831',
            'coingeckoId': 'catch-up',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EVX',
            'name': 'Everex',
            'address': '0x0C9b7103E16f171f60448E039F48d3b50241fcBC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DOGENFT',
            'name': 'DogeNFTcollector.com',
            'address': '0x0C9A6e9173f1345e911C275801327dfdc706937B',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x0c9a6e9173f1345e911c275801327dfdc706937b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'METAWORLD',
            'name': 'Meta World',
            'address': '0x0C6D23aBf24f8C97Ca1397547D50EE22BDCEE7d6',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21995/thumb/metaworldio.png?1640583739',
            'coingeckoId': 'meta-world',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PLUTO',
            'name': 'PlutoPepe',
            'address': '0x0C3Ea5aAC39101E5b3989b3f19181D8591b734d0',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15427/thumb/pluto.PNG?1620788253',
            'coingeckoId': 'plutopepe',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GBR',
            'name': 'Gabur',
            'address': '0x0Bbe4eBC8A16C759eA82D0950f29Aad3E3F10070',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27980/thumb/Gabur.png?1666854689',
            'coingeckoId': 'gabur',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CREAM',
            'name': 'IceCream Finance',
            'address': '0x0Baf6f41514812880212B2E2BB59fe3ca147D6A2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'GKEN',
            'name': 'Robocock Ultra World',
            'address': '0x0B5a6E082C8243AD568a35230B327F2c043D3d1f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26388/thumb/GKEN_TOKEN_LOGO.png?1657751099',
            'coingeckoId': 'robocock-ultra-world',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GM',
            'name': 'GhostMarket',
            'address': '0x0B53b5dA7d0F275C31a6A182622bDF02474aF253',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22765/thumb/gm.png?1642574907',
            'coingeckoId': 'ghostmarket',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EVERDOT',
            'name': 'EverDot',
            'address': '0x0B40BBF9265b7F93169B087b9749D9D059D82FFe',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20773/thumb/Logo-200x200.png?1637660192',
            'coingeckoId': 'everdot',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSL',
            'name': 'BankSocial',
            'address': '0x0AF55d5fF28A3269d69B98680Fd034f115dd53Ac',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15738/thumb/banksocial_small.png?1621685752',
            'coingeckoId': 'banksocial',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EVER',
            'name': 'Everscale',
            'address': '0x0A7e7D210C45c4abBA183C1D0551B53AD1756ecA',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12783/thumb/everscale_badge_main_round_1x.png?1640050196',
            'coingeckoId': 'everscale',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'THUNDERADA',
            'name': 'ThunderADA app',
            'address': '0x0A7bB2bddA1c0eA02d98a7b048f4bF809F40277b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17950/thumb/logo-thunderada200x200.png?1629894669',
            'coingeckoId': 'thunderada-app',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HELPS',
            'name': 'HelpSeed',
            'address': '0x0A6e6D2F58d22E267Fdc9bfB295F0d43985FEBB4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23858/thumb/helpslogo.png?1645594947',
            'coingeckoId': 'helpseed',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FOTA',
            'name': 'Fight Of The Ages',
            'address': '0x0A4E1BdFA75292A98C15870AeF24bd94BFFe0Bd4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26023/thumb/logo_thunder.jpg?1655283704',
            'coingeckoId': 'fight-of-the-ages',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FACEMETA',
            'name': 'Face Meta 2 0',
            'address': '0x0A45B65Fd550D81cf4b685613C7D19AdB0D63153',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26918/thumb/fmeta.png?1660814911',
            'coingeckoId': 'face-meta-2-0',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'iUSD',
            'name': 'iZUMi Bond USD',
            'address': '0x0A3BB08b3a15A19b4De82F8AcFc862606FB69A2D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0x0a3bb08b3a15a19b4de82f8acfc862606fb69a2d/098f1985f11704e21fe9520dfb609f29.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'HIFI',
            'name': 'HiFi Gaming Society',
            'address': '0x0A38bc18022b0cCB043F7b730B354d554C6230F1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17635/thumb/hifi.PNG?1628735543',
            'coingeckoId': 'hifi-gaming-society',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UFARM',
            'name': 'UniFarm',
            'address': '0x0A356f512f6fCE740111ee04Ab1699017a908680',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15247/thumb/ufarm.jpeg?1620218765',
            'coingeckoId': 'unifarm',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'KSHIBA',
            'name': 'Kawai Shiba',
            'address': '0x0A24646623a95248e6D9c153F2bE6FEf4B78D00d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19400/thumb/logo200.jpg?1635175706',
            'coingeckoId': 'kawai-shiba',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FVT',
            'name': 'Finance Vote',
            'address': '0x0A232cb2005Bda62D3DE7Ab5DEb3ffe4c456165a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/13181/thumb/finance.png?1606015010',
            'coingeckoId': 'finance-vote',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EJS',
            'name': 'Enjinstarter',
            'address': '0x09f423aC3C9baBBfF6F94D372b16E4206e71439f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18732/thumb/l-R1nYA0_400x400.jpg?1633297242',
            'coingeckoId': 'enjinstarter',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'CISLA',
            'name': 'Crypto Island',
            'address': '0x09d975C3351DBdED28617517FC6982284a787f03',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17897/thumb/200x200_CISLA.png?1629769640',
            'coingeckoId': 'crypto-island',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DODB',
            'name': 'DODbase',
            'address': '0x09d2355140725c9eA740A9e6e7C0e5F4050C6Fb3',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23244/thumb/ozmhlZ-N_400x400.png?1655180380',
            'coingeckoId': 'moon-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CITY',
            'name': 'SatoshiCity',
            'address': '0x09c995F6D7d3B8B1058Fbc7570d909C83a2BB88C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24679/thumb/qeBsYVE0_400x400.png?1648621928',
            'coingeckoId': 'satoshicity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWEEP',
            'name': 'Sweep Token',
            'address': '0x09c704c1EB9245Af48f058878e72129557a10F04',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27848/thumb/sweep200.png?1666083170',
            'coingeckoId': 'sweep-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SALO',
            'name': 'Salo Players',
            'address': '0x09b69fC31642020Ae53148926aAb6733703E9027',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27674/thumb/token.png?1665210799',
            'coingeckoId': 'salo-players',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNCX',
            'name': 'UniCrypt',
            'address': '0x09a6c44c3947B69E2B45F4D51b67E6a39ACfB506',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x09a6c44c3947b69e2b45f4d51b67e6a39acfb506.png',
            'coingeckoId': 'unicrypt-2',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BXR',
            'name': 'Blockster',
            'address': '0x09a15e2ecC0306046826d95696C183d533C228dF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CLEAR',
            'name': 'Clear Water',
            'address': '0x09Faf80c7Df27d0Fb8A717324963cB871f6814f7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23798/thumb/attachment.png?1646034809',
            'coingeckoId': 'clear-water',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CLV',
            'name': 'Clover Finance',
            'address': '0x09E889BB4D5b474f561db0491C38702F367A4e4d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15278/thumb/photo_2022-03-24_10-22-33.jpg?1648531786',
            'coingeckoId': 'clover-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZOO',
            'name': 'Zoo Labs',
            'address': '0x09E2b83Fe5485a7c8BeAa5DffD1D324A2B2D5c13',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19768/thumb/QbPB_djV_400x400.jpg?1635837426',
            'coingeckoId': 'zoo-labs',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFEARN',
            'name': 'Safe Earn',
            'address': '0x099f551eA3cb85707cAc6ac507cBc36C96eC64Ff',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17186/thumb/logo_-_2021-07-21T064633.059.png?1626821200',
            'coingeckoId': 'safe-earn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KAWAII',
            'name': 'kawaiINU',
            'address': '0x099D6CC403C0a5B46A6dD384c622Da39f1e1001C',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15999/thumb/keanuinu.PNG?1622589086',
            'coingeckoId': 'kawaiinu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APP',
            'name': 'SappChat',
            'address': '0x097F8aE21e81D4F248A2E2D18543c6b3CC0D8E59',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20605/thumb/sapp.PNG?1637289596',
            'coingeckoId': 'sappchat',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GEC',
            'name': 'Green Energy Coin',
            'address': '0x0965d7717068FF4f8F302556316F7B8e8661E485',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18465/thumb/GEC_200x200.png?1632114652',
            'coingeckoId': 'green-energy-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MB',
            'name': 'Milk and Butter',
            'address': '0x0962840397B0ebbFbb152930AcB0583e94F49B5c',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22288/thumb/oNmIh8Ar_400x400.jpg?1641366756',
            'coingeckoId': 'milk-and-butter',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PETG',
            'name': 'Pet Games',
            'address': '0x09607078980CbB0665ABa9c6D1B84b8eAD246aA0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18072/thumb/logo200_%2824%29.png?1630379786',
            'coingeckoId': 'pet-games',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'OBS',
            'name': 'One Basis Cash',
            'address': '0x095956B142431Eb9Cf88B99F392540B91aCbF4ad',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16521/thumb/OBS.50eb9474.png?1624330479',
            'coingeckoId': 'one-basis-cash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GHOSTFACE',
            'name': 'Ghostface',
            'address': '0x0952dDFfDE60786497C7CEd1f49B4A14cF527f76',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15937/thumb/J5oSbp1.png?1622449532',
            'coingeckoId': 'ghostface',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PLEB',
            'name': 'Plebe Gaming',
            'address': '0x09521aB68230a28a0e6814a35cB3FC2F7fa11E00',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22093/thumb/LOGO_for_Listing.png?1640795092',
            'coingeckoId': 'plebe-gaming',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHICO',
            'name': 'ShibaCorgi',
            'address': '0x092BBec1342affFd16Cfb41B56343D5A299CDf0D',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15608/thumb/shibacorgi-200.jpg?1621316524',
            'coingeckoId': 'shibacorgi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOGE-1',
            'name': 'Doge 1 Mission to the moon',
            'address': '0x08cCAC619e9c6e95d48DFD23793d722A994b95B8',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23778/thumb/IMMAGINE-PER-CMC.png?1645434864',
            'coingeckoId': 'doge-1-mission-to-the-moon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BDOT',
            'name': 'BabyDot',
            'address': '0x08bd7F9849f8EEC12fd78c9fED6ba4e47269e3d5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17213/thumb/bdot.PNG?1626854166',
            'coingeckoId': 'babydot',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BMON',
            'name': 'Binamon',
            'address': '0x08ba0619b1e7A582E0BCe5BBE9843322C954C340',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x08ba0619b1e7A582E0BCe5BBE9843322C954C340/logo.png',
            'coingeckoId': 'binamon',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'QUO',
            'name': 'Quoll Finance',
            'address': '0x08b450e4a48C04CDF6DB2bD4cf24057f7B9563fF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28078/thumb/logo_quoll_asset.png?1667394645',
            'coingeckoId': 'quo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ENVIRO',
            'name': 'Enviro',
            'address': '0x08a68Ef8f28Fbe9008ADCB5bf4a7140cb1bBf23a',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16028/thumb/enviro.PNG?1622624315',
            'coingeckoId': 'enviro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRUSTNFT',
            'name': 'TrustNFT',
            'address': '0x08F725D2809FdA409Bc23493F3615a4c85a22d7d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20478/thumb/logo-3_%281%29.png?1637114682',
            'coingeckoId': 'trustnft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTRX',
            'name': 'Metarix',
            'address': '0x08B87b1cFdba00dFb79D77Cac1a5970ba6c9cDE2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27565/thumb/output-onlinepngtools_%287%29.png?1664520885',
            'coingeckoId': 'metarix',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHEESE',
            'name': 'Cheese',
            'address': '0x08Aed8578dAaBf12d48031fA5d9727e4afD42dee',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28062/thumb/photo_2022-10-24_18-55-25.jpg?1667305728',
            'coingeckoId': 'cheese',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MARU',
            'name': 'marumaruNFT',
            'address': '0x08A84af1368Cd333073Ac5DfB2254208e06b3a70',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27672/thumb/90e23f86-426d-4a0f-9d66-10da3d58baf5.png?1665158519',
            'coingeckoId': 'marumarunft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DPL',
            'name': 'DarePlay',
            'address': '0x08A5390Fee3602CA833a9b6D3F12B0891B08e689',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23585/thumb/Logo-Dareplay-social.jpg?1666846070',
            'coingeckoId': 'dareplay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOAT',
            'name': 'Goat Coin',
            'address': '0x08922cC5e9bD4c74Ff570E27a40B84084a7f9988',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15525/thumb/qqked5P.png?1621126222',
            'coingeckoId': 'goat-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TGNB',
            'name': 'Tiger',
            'address': '0x087b005B57C286886e4c6175822929ea42832719',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22406/thumb/250.png?1641794928',
            'coingeckoId': 'tiger-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MW',
            'name': 'Monster World',
            'address': '0x08773d48C1a4289Dd2A800685899899bcc929340',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27446/thumb/2E459E6A-E147-478F-8DFD-89FD819C537A.png?1664003611',
            'coingeckoId': 'monster-world',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EVOC',
            'name': 'EvoCardano',
            'address': '0x086CC8e468B3cB5494F18a7aBc2De1321306aF12',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19539/thumb/evoc.png?1635386206',
            'coingeckoId': 'evocardano',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DATA',
            'name': 'Streamr',
            'address': '0x0864c156b3C5F69824564dEC60c629aE6401bf2a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x0864c156b3c5f69824564dec60c629ae6401bf2a.png',
            'coingeckoId': 'streamr',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'IDEX',
            'name': 'IDEX Token ',
            'address': '0x0856978F7fFff0a2471B4520E3521c4B3343e36f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CBT',
            'name': 'CryptoBlast',
            'address': '0x0853eABE53157D911E0137a9ef987874289ae9d0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20393/thumb/14479.png?1636963052',
            'coingeckoId': 'cryptoblast',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BONES',
            'name': 'Moonshots Farm',
            'address': '0x08426874d46f90e5E527604fA5E3e30486770Eb3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22487/thumb/moonshots200.png?1641904223',
            'coingeckoId': 'moonshots-farm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RTT',
            'name': 'RebelTrader',
            'address': '0x0834605689fAAe41708607a2761CD063775038E5',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21699/thumb/IMG_2510.PNG?1639724054',
            'coingeckoId': 'rebeltradertoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ROCKS',
            'name': 'Social Rocket',
            'address': '0x0829d2d5cC09d3d341E813c821B0cfAE272D9fb2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0x0829d2d5cc09d3d341e813c821b0cfae272d9fb2.png',
            'coingeckoId': 'social-rocket',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'GROW',
            'name': 'Grow',
            'address': '0x081A4D4e4A0cC74D6a7A61578f86b8C93CC950a0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16049/thumb/grow-logo-200x200.png?1622691274',
            'coingeckoId': 'grow-token-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KITTENS',
            'name': 'Kitten Coin',
            'address': '0x0812C7045D69cd3A881BecAB80E23C222cfbE032',
            'decimals': 0,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23947/thumb/IIOISE0-Imgur.png?1645771280',
            'coingeckoId': 'kitten-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETS',
            'name': 'Ethersniper',
            'address': '0x080f2ed125C2E41E979d1Ae9F8EF8c262B344855',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20566/thumb/ZENsgzic_400x400.jpg?1637220054',
            'coingeckoId': 'ethersniper',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NATURE',
            'name': 'The Nature',
            'address': '0x080cC6eb47DCae6a42fc5408eBd5CF2AA05eAEc3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23582/thumb/eFkXKa8T_400x400.jpg?1644646877',
            'coingeckoId': 'the-nature-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFTS',
            'name': 'NFT Stars',
            'address': '0x08037036451C768465369431Da5C671ad9B37dBc',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16141/thumb/j2KHi8zR_400x400.png?1623116004',
            'coingeckoId': 'nft-stars',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'LAB-V2',
            'name': 'Little Angry Bunny v2',
            'address': '0x07f5cEdeD6B3dba557B3663EDC8941FB37b63945',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20327/thumb/lab_v2.png?1636888545',
            'coingeckoId': 'little-angry-bunny-v2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QMALL',
            'name': 'Qmall',
            'address': '0x07e551E31A793E20dc18494ff6b03095A8F8Ee36',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23630/thumb/tjVN6bQ5_400x400.jpg?1644891636',
            'coingeckoId': 'qmall',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SDAO',
            'name': 'SDAO',
            'address': '0x07dbEF0F356623168e6279788507Bd98dd9D6304',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27884/thumb/S_x_1.png?1666238459',
            'coingeckoId': 'sdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KEY',
            'name': 'KeySwap',
            'address': '0x07b1681C082039551952bDee4A505cecC2FF4998',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25306/thumb/1.png?1651210948',
            'coingeckoId': 'keyswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AQUAGOAT',
            'name': 'Aquagoat',
            'address': '0x07af67b392B7A202fAD8E0FBc64C34F33102165B',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x07af67b392b7a202fad8e0fbc64c34f33102165b.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'OBT',
            'name': 'Oobit',
            'address': '0x07F9702ce093db82dfdC92c2C6E578d6EA8D5E22',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19855/thumb/obt.png?1636062272',
            'coingeckoId': 'oobit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRISE',
            'name': 'TrustRise',
            'address': '0x07F805698e8E610d52FBf6fB3D8e636EAC060b5e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21519/thumb/gRGDFsS6_400x400.jpg?1639377286',
            'coingeckoId': 'trustrise',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VOLT',
            'name': 'Volt Inu  OLD ',
            'address': '0x07EC61Ae90860641972E9B41A706325a1E928BF8',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21832/thumb/nl250.png?1643191234',
            'coingeckoId': 'volt-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WIFEDOGE',
            'name': 'Wifedoge',
            'address': '0x07B36F2549291d320132712a1E64d3826B1FB4D7',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17346/thumb/w_doge_logo200.png?1627365473',
            'coingeckoId': 'wifedoge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QSD',
            'name': 'QIAN Second Generation Dollar',
            'address': '0x07AaA29E63FFEB2EBf59B33eE61437E1a91A3bb2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x07AaA29E63FFEB2EBf59B33eE61437E1a91A3bb2/logo.png',
            'coingeckoId': 'qian-second-generation-dollar',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BG',
            'name': 'Bagus Wallet',
            'address': '0x07ABe236bb21E96c2E2319766745F782dcd0b0C8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19577/thumb/1_vagg0mEPwG-Dc8f2XMM9tg.png?1635468324',
            'coingeckoId': 'bagus-wallet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WALL',
            'name': 'LaunchWall',
            'address': '0x07A9E0f02a129Fd420fbeda766bd793860a9E812',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20234/thumb/ovnDSUV_d.png?1636690335',
            'coingeckoId': 'launchwall',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MEGA',
            'name': 'MegaToken',
            'address': '0x079F0f5f3Ad15E01a5CD919564A8F52DdE03431B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20671/thumb/undefined-Imgur-5.png?1637548941',
            'coingeckoId': 'megatoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NASADOGE',
            'name': 'Nasa Doge',
            'address': '0x079Dd74Cc214Ac5f892f6a7271ef0722F6D0c2e6',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17463/thumb/tok.png?1627887895',
            'coingeckoId': 'nasa-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LIMC',
            'name': 'LimCore',
            'address': '0x078ca3af061603bd5a1Ee2388116DAaCe87244C3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24608/thumb/%D1%82%D0%BE%D0%BA%D0%B5%D0%BD.png?1649045030',
            'coingeckoId': 'limcore',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HAY',
            'name': 'Destablecoin HAY',
            'address': '0x0782b6d8c4551B9760e74c0545a9bCD90bdc41E5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x0782b6d8c4551b9760e74c0545a9bcd90bdc41e5.png',
            'coingeckoId': 'helio-protocol-hay',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'MV',
            'name': 'MULTIVERSE',
            'address': '0x078053F10ACd423E9357d80C5828dDf69D2bCD36',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PLACE',
            'name': 'PlaceWar Governance',
            'address': '0x07728696eE70A28c9C032926577AF1D524Df30f9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19993/thumb/place.png?1636361494',
            'coingeckoId': 'place-war',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PARA',
            'name': 'Paralink Network',
            'address': '0x076DDcE096C93dcF5D51FE346062bF0Ba9523493',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0x076ddce096c93dcf5d51fe346062bf0ba9523493.png',
            'coingeckoId': 'paralink-network',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'RUSD',
            'name': 'rUSD',
            'address': '0x07663837218A003e66310a01596af4bf4e44623D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x07663837218A003e66310a01596af4bf4e44623D.png',
            'coingeckoId': 'rusd',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'CMQ',
            'name': 'Communique',
            'address': '0x074E91c178e4B4c6228357A5A0B6df5aD824f0d8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26171/thumb/256x256_%281%29.png?1656368857',
            'coingeckoId': 'communique',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLUX',
            'name': 'Flux Protocol',
            'address': '0x0747CDA49C82d3fc6B06dF1822E1DE0c16673e9F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15002/thumb/logo.dabc411c.png?1619402947',
            'coingeckoId': null,
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOTA',
            'name': 'SOTA Finance',
            'address': '0x0742b62eFB5f2eaBBc14567dfC0860CE0565bCf4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x0742b62eFB5f2eaBBc14567dfC0860CE0565bCf4/logo.png',
            'coingeckoId': 'sota-finance',
            'listedIn': [
                'coingecko',
                'elkfinance'
            ]
        },
        {
            'symbol': 'KIRADOGE',
            'name': 'Kiradoge',
            'address': '0x07236AA0A4886Ae2c097C86cc29954F78165B327',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21136/thumb/200x200.-CMC.png?1638362440',
            'coingeckoId': 'kiradoge-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FFA',
            'name': 'Festa Finance',
            'address': '0x071faf7b2fbEF75e725879448dFE87D56dC5107E',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26095/thumb/festa_finance.png?1655967201',
            'coingeckoId': 'festa-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BKING',
            'name': 'King Arthur',
            'address': '0x071f3A63549af1B82466ACE48FA742D7E8aD6eDf',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16162/thumb/king-arthur.png?1623142524',
            'coingeckoId': 'king-arthur',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOHO',
            'name': 'GooseHonk',
            'address': '0x0712bc03807EBacC3a457a7149E7Ab3bCD2e8009',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x0712bc03807ebacc3a457a7149e7ab3bcd2e8009/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'VTX',
            'name': 'Vortex DeFi',
            'address': '0x070625d5A14706c30b8E2264753B2f5D035Bc1B4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14120/thumb/vortex_defi.png?1614514332',
            'coingeckoId': 'vortex-defi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CAGE',
            'name': 'Coinage Finance',
            'address': '0x06ebc9c542357e2129D16717CD02c02FBC835d33',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22300/thumb/Coinage_v2_200x200_white.png?1653464214',
            'coingeckoId': 'coinage-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABYEVERDOGE',
            'name': 'Baby EverDoge',
            'address': '0x06c4212Ae2fEa51a27A045D968E73F7E91eA5521',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16462/thumb/Baby-Ever-Doge-Logo.png?1625066133',
            'coingeckoId': 'baby-everdoge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAUNA',
            'name': 'SaunaFinance',
            'address': '0x06b889a7a7fa15D8cC7CFf147962C4232cCE7CF0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18313/thumb/wBS86Ool_400x400.jpg?1647420983',
            'coingeckoId': 'saunafinance-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NEURON',
            'name': 'Neuron Chain',
            'address': '0x06ae7A979D9818B64498c8acaFDd0ccc78bC6fd2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26789/thumb/rsz_pujbbxta_400x400.png?1660103593',
            'coingeckoId': 'neuron-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BORK',
            'name': 'Bork Inu',
            'address': '0x06FDecaED03BFd18489aCA95fd1d6c9fE2BDC95F',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23438/thumb/dXZsosv.png?1644212254',
            'coingeckoId': 'bork-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANML',
            'name': 'Animal Concerts Token',
            'address': '0x06FDA0758c17416726f77Cb11305EAC94C074Ec0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0x06fda0758c17416726f77cb11305eac94c074ec0/b3d2118e9b5af1c5031a484d97dab030.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DOPE',
            'name': 'Dopamine',
            'address': '0x067edE5097574b387ff30999e3a1775f3D7D569d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'GOLD8',
            'name': 'GOLD8',
            'address': '0x066aeE69D93dEe28b32A57fEbd1878a2D94F6b0c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27481/thumb/gold8-200x200.png?1664246277',
            'coingeckoId': 'gold8',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WT',
            'name': 'Wohlstand',
            'address': '0x065b77156B9aB417Bd30Fc25e30Ce5B9A7dD2066',
            'decimals': 11,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25152/thumb/logo_200x200.png?1650460509',
            'coingeckoId': 'wohlstand-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOL',
            'name': 'Mollector',
            'address': '0x06597FFaFD82E66ECeD9209d539032571ABD50d9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24744/thumb/MOL_200x200.png?1648783528',
            'coingeckoId': 'mollector',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MBX',
            'name': 'MobieCoin',
            'address': '0x064C8E55Aa484AdBD58ca2d43343eF50137473b7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/12874/thumb/MBX_token.jpg?1603186697',
            'coingeckoId': 'mobiecoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JPD',
            'name': 'JackpotDoge',
            'address': '0x0627E7ee0D14FCdd2ff30d1563AeBDBccec678be',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28001/thumb/62caad33f3c5f30014065a1d-image-p-1660157227220-File-name.png?1666927257',
            'coingeckoId': 'jackpotdoge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BICO',
            'name': 'Biconomy Token (Wormhole)',
            'address': '0x06250a4962558F0F3E69FC07F4c67BB9c9eAc739',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0x06250a4962558f0f3e69fc07f4c67bb9c9eac739/377a83289e2b525eb301ab52abab3e43.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BACON',
            'name': 'BaconDAO',
            'address': '0x0615Dbba33Fe61a31c7eD131BDA6655Ed76748B1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18059/thumb/xDV_bhdA_400x400.jpg?1630333329',
            'coingeckoId': 'bacondao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CATPAY',
            'name': 'CATpay',
            'address': '0x0611686A2558de495617685b3Da12448657170FE',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24731/thumb/catpay.png?1661765563',
            'coingeckoId': 'catpay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WTN',
            'name': 'Wateenswap',
            'address': '0x06082951EFDb5095E45e3C08cB103782645a2e69',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26333/thumb/Xs5yrJym_400x400.jpeg?1657492066',
            'coingeckoId': 'wateenswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GRAPE',
            'name': 'Grape',
            'address': '0x06063B2b2a9c56671973AAB29d6A1645A5bFb9f8',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23238/thumb/grape_128_%281%29.png?1643355985',
            'coingeckoId': 'grape-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZORO',
            'name': 'Zoro Inu',
            'address': '0x05ad901cf196cbDCEaB3F8e602a47AAdB1a2e69d',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24155/thumb/zoro.png?1646565848',
            'coingeckoId': 'zoro-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TINC',
            'name': 'Tiny Coin',
            'address': '0x05aD6E30A855BE07AfA57e08a4f30d00810a402e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x05aD6E30A855BE07AfA57e08a4f30d00810a402e.png',
            'coingeckoId': 'tiny-coin',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'GLOWV2',
            'name': 'GlowV2',
            'address': '0x05De1dbFCdc876F790371508b97C337640Dcd6a9',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23301/thumb/17513.png?1643668784',
            'coingeckoId': 'glowv2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NAUT',
            'name': 'Astronaut',
            'address': '0x05B339B0A346bF01f851ddE47a5d485c34FE220c',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x05b339b0a346bf01f851dde47a5d485c34fe220c.png',
            'coingeckoId': 'astronaut',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'EGON',
            'name': 'EAGLE MINING NETWORK',
            'address': '0x05995a068bdac17c582eC75AB46bb8e7394be1d9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26866/thumb/photo_2022-08-14_20-49-43.jpg?1660534002',
            'coingeckoId': 'eagle-mining-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLUC',
            'name': 'Bluca',
            'address': '0x05928783B1F0b29A884aF248AAdE809BBa41Ad00',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24661/thumb/BlucToken.png?1648529902',
            'coingeckoId': 'bluca',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VOYCE',
            'name': 'Voyce',
            'address': '0x05901cB9cbD15ECBe48cC98F946045661a7E12bA',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21993/thumb/logo.png?1640582896',
            'coingeckoId': 'voyce',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JED',
            'name': 'JEDSTAR',
            'address': '0x058a7Af19BdB63411d0a84e79E3312610D7fa90c',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18509/thumb/jedstar_logo_coingecko.png?1632216213',
            'coingeckoId': 'jedstar',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'SHF',
            'name': 'ShibaForest',
            'address': '0x058F54Ec846cf0fe82C06a03D63c2aec62fE0b82',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27703/thumb/forestshiba.png?1665307765',
            'coingeckoId': 'shibaforest',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HARE',
            'name': 'Hare',
            'address': '0x05846682625B7F9D605cb13A1Cb4FA71D213A279',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23479/thumb/brand_hare-token-200.png?1644219192',
            'coingeckoId': 'hare',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOGEALLY',
            'name': 'Doge Alliance',
            'address': '0x05822195B28613b0F8A484313d3bE7B357C53A4a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21545/thumb/dogealliance_sq_x200.png?1639455993',
            'coingeckoId': 'doge-alliance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PTT',
            'name': 'Potent Coin',
            'address': '0x057AFf3E314e1ca15BED75510df81A20098cE456',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17651/thumb/Untitled-design_%281%29.png?1628751305',
            'coingeckoId': 'potent-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIBGOTCHI',
            'name': 'SHiBGOTCHi',
            'address': '0x05732b6D78591E75B31022d71E55Aa810498A5a4',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25097/thumb/IMG_1881.png?1650279461',
            'coingeckoId': 'shibgotchi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FGD',
            'name': 'Freedom God DAO',
            'address': '0x0566B9a8fFb8908682796751EEd00722da967Be0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24928/thumb/logo.png?1649389263',
            'coingeckoId': 'freedom-god-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GUILD',
            'name': 'BlockchainSpace',
            'address': '0x0565805CA3A4105FAEE51983b0bD8ffB5ce1455C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21271/small/BednjMw.png?1638857799',
            'coingeckoId': 'blockchainspace',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'MDB',
            'name': 'MillionDollarBaby',
            'address': '0x0557a288A93ed0DF218785F2787dac1cd077F8f3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27062/thumb/20039.png?1661670436',
            'coingeckoId': 'milliondollarbaby',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SFC',
            'name': 'Solar Full Cycle',
            'address': '0x0556551F81623aE32c5c7CF853c67fafBE61648a',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21204/thumb/SFC-200x200-Transparent-logo.png?1638548613',
            'coingeckoId': 'solar-full-cycle',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INS',
            'name': 'Instinct',
            'address': '0x0555FFB055181c94e16f4dd1698e225717C671Fe',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18012/thumb/instincttglogo.png?1657768470',
            'coingeckoId': 'instinct',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DSHARE',
            'name': 'DSHARE',
            'address': '0x0551b4C819bc50101F6961F29cFb277aA998CEbC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PACIFIC',
            'name': 'Pacific DeFi',
            'address': '0x054Ae19876B2a669c282503D5a76313290Bc6453',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18269/thumb/Webp.net-resizeimage_%281%29.png?1631172491',
            'coingeckoId': 'pacific-defi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OPBR',
            'name': 'OPBR',
            'address': '0x05475026D2aeF144AB89BD8431A3858Db4dCF18F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26435/thumb/Logo_Oficial_OPBR_-_Ingles_ok.png?1658107420',
            'coingeckoId': 'opbr',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PEKC',
            'name': 'PEACOCKCOIN',
            'address': '0x050787DE0cF5Da03D9387b344334D51cAE5DD0Fd',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x050787de0cf5da03d9387b344334d51cae5dd0fd.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'OSK',
            'name': 'FstSwap OSK',
            'address': '0x04fA9Eb295266d9d4650EDCB879da204887Dc3Da',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26234/thumb/xv8iGCKB_400x400.png?1656900390',
            'coingeckoId': 'fstswap-osk',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OCTF',
            'name': 'Octafarm',
            'address': '0x04d4F38Dcdfe976Cb325dB16b868F0020104014e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22746/thumb/17432.png?1642554535',
            'coingeckoId': 'octafarm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SALE',
            'name': 'DxSale Network',
            'address': '0x04F73A09e2eb410205BE256054794fB452f0D245',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x04f73a09e2eb410205be256054794fb452f0d245.png',
            'coingeckoId': 'dxsale-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BLOVELY',
            'name': 'Baby Lovely Inu',
            'address': '0x04Df8C91FCcFd703cD15047bF6C1cE76D335C6cE',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23608/thumb/blovely.png?1644746419',
            'coingeckoId': 'baby-lovely-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ITAM',
            'name': 'ITAM Games',
            'address': '0x04C747b40Be4D535fC83D09939fb0f626F32800B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x04c747b40be4d535fc83d09939fb0f626f32800b.png',
            'coingeckoId': 'itam-games',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ONE',
            'name': 'One',
            'address': '0x04BAf95Fd4C52fd09a56D840bAEe0AB8D7357bf0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x04BAf95Fd4C52fd09a56D840bAEe0AB8D7357bf0/logo.png',
            'coingeckoId': 'one',
            'listedIn': [
                'coingecko',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'TEX',
            'name': 'IoTeXPad',
            'address': '0x049Dd7532148826CdE956c7B45fec8c30b514052',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20964/thumb/7gyi5TV8_400x400.jpg?1638152165',
            'coingeckoId': 'iotexpad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RGOLD',
            'name': 'Royal Gold',
            'address': '0x0496CcD13c9848f9c7d1507D1dd86a360B51b596',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21324/thumb/rgold-token.png?1638945446',
            'coingeckoId': 'royal-gold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JDI',
            'name': 'JDI',
            'address': '0x0491648C910ad2c1aFaab733faF71D30313Df7FC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15220/thumb/JDI.png?1620119566',
            'coingeckoId': 'jdi-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JGBP',
            'name': 'Jarvis Synthetic British Pound',
            'address': '0x048E9b1ddF9EBbb224812372280e94Ccac443f9e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15726/thumb/jGBP.png?1634046064',
            'coingeckoId': 'jarvis-synthetic-british-pound',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '2022M',
            'name': '2022MOON',
            'address': '0x047a02a57501a813975b2D347278Fdd3dF671E86',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://static.debank.com/image/bsc_token/logo_url/0x047a02a57501a813975b2d347278fdd3df671e86/138bb5ad989a2f0c592307749e1f5a45.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'bCFX',
            'name': 'Conflux',
            'address': '0x045c4324039dA91c52C55DF5D785385Aab073DcF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x045c4324039dA91c52C55DF5D785385Aab073DcF/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PERSIA',
            'name': 'Persia',
            'address': '0x043ab85dA1d804a03C411e9A1d61c12FF99f0B02',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22856/thumb/rqOrKl3v_400x400.jpg?1642751201',
            'coingeckoId': 'persia',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GFULL',
            'name': 'Gainfull',
            'address': '0x043E149bE2eaE117a9514428ec1b30F3004BEab2',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27535/thumb/IMG_20220926_213043_820.jpg?1664419613',
            'coingeckoId': 'gainfull',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MT3',
            'name': 'Meta3',
            'address': '0x0429011FBC9721Ce100B8a6812A294CB973256A3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25868/thumb/meta_%281%29-modified_%281%29.png?1654240157',
            'coingeckoId': 'meta3',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CPAN',
            'name': 'CryptoPlanes',
            'address': '0x04260673729c5F2b9894A467736f3D85F8d34fC8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1637670990321_05553070031092133.png',
            'coingeckoId': 'cryptoplanes',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'DKN',
            'name': 'DoKEN V2',
            'address': '0x0420EB45AC5a4f04763f679c07c3A637741E0289',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20283/thumb/Qu5sof9.png?1638363195',
            'coingeckoId': 'doken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RCH',
            'name': 'Rich',
            'address': '0x041E714Aa0DcE7d4189441896486d361e98BAD5f',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22896/thumb/rch.png?1642851498',
            'coingeckoId': 'rich',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PIRATECOIN',
            'name': 'PirateCoin',
            'address': '0x041640eA980e3fE61e9C4ca26D9007Bc70094C15',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18278/thumb/logo_200_%284%29.png?1631234630',
            'coingeckoId': 'piratecoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PHB',
            'name': 'Phoenix Global',
            'address': '0x0409633A72D846fc5BBe2f98D88564D35987904D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20052/thumb/Phoenix_logo_blue_bird.png?1666335691',
            'coingeckoId': 'phoenix-global',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'META',
            'name': 'Metaverse Miner',
            'address': '0x04073D16C6a08C27e8BbEbe262Ea4D1C6fa4C772',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18408/thumb/d360aee2b1bd9fd9.png?1631795640',
            'coingeckoId': 'metaverse-miner',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YACHTX',
            'name': 'YachtX',
            'address': '0x0403d215067cd359F4a9dC124776D262d0896662',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24105/thumb/yacht_logo_200.png?1646375930',
            'coingeckoId': 'yachtx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WONE',
            'name': 'Wrapped One',
            'address': '0x03fF0ff224f904be3118461335064bB48Df47938',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x03fF0ff224f904be3118461335064bB48Df47938/logo.png',
            'coingeckoId': 'wrapped-one',
            'listedIn': [
                'coingecko',
                'pancake',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': '1AMD',
            'name': 'Free Speech',
            'address': '0x03e93598Ea83DfD1A3c02e34576F75dA1b8e1407',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26713/thumb/200x200_%281%29.png?1659682734',
            'coingeckoId': 'free-speech',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ESHIB',
            'name': 'Shiba Elon',
            'address': '0x03e1c8d25B0DCD41E1073D65CcDd4e40E672A68D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20320/thumb/shibaelon-logo200x200.png?1637767380',
            'coingeckoId': 'shiba-elon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INES',
            'name': 'Inescoin',
            'address': '0x03db11045b299b1148F73c1Ad07A22E5785985Bf',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26537/thumb/Piece_face_inescoin_1_3_bryefr.png?1658720155',
            'coingeckoId': 'inescoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MGA',
            'name': 'Metagame Arena',
            'address': '0x03aC6AB6A9a91a0fcdec7D85b38bDFBb719ec02f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20947/thumb/mga.png?1638073074',
            'coingeckoId': 'metagame-arena',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TUSK',
            'name': 'Mammoth Bet',
            'address': '0x03FfFb18C0EA6B0aD4fa3b5226512B334587686F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16533/thumb/tusk_logo.png?1624347877',
            'coingeckoId': 'tusk-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOVEZ',
            'name': 'MoveZ',
            'address': '0x039cD22cb49084142d55FCD4B6096A4F51ffb3B4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25661/thumb/JYwFw77.png?1654768153',
            'coingeckoId': 'movez',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEXE',
            'name': 'DeXe',
            'address': '0x039cB485212f996A9DBb85A9a75d898F94d38dA6',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x039cb485212f996a9dbb85a9a75d898f94d38da6.png',
            'coingeckoId': 'dexe',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pancake',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DFH',
            'name': 'DeFiHelper Governance Token',
            'address': '0x039b724994260d7C956A06f85C565355a8383198',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TREE',
            'name': 'OakTree Token',
            'address': '0x039471b9e8a86B977aaeb5eC4182Cf3866f436B0',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x039471b9e8a86b977aaeb5ec4182cf3866f436b0.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'TRAVA',
            'name': 'Trava Finance',
            'address': '0x0391bE54E72F7e001f6BBc331777710b4f2999Ef',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/11209.png',
            'coingeckoId': 'trava-finance',
            'listedIn': [
                'coingecko',
                'dfyn'
            ]
        },
        {
            'symbol': 'LIGHT',
            'name': 'Lightning Protocol',
            'address': '0x037838b556d9c9d654148a284682C55bB5f56eF4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x037838b556d9c9d654148a284682C55bB5f56eF4.png',
            'coingeckoId': 'lightning-protocol',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'CTRAIN',
            'name': 'CryptoTrains',
            'address': '0x0367035F7114C72141589058d09F11D0E76988E9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22849/thumb/RlJMgaB.png?1642747317',
            'coingeckoId': 'cryptotrains',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAFC',
            'name': 'BabyApeFunClub',
            'address': '0x035aD59058c557be4532141FBCD60f0998fCE413',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27287/thumb/LOGO-200x200.png?1663142835',
            'coingeckoId': 'babyapefunclub',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CANDY',
            'name': 'Sugar Kingdom',
            'address': '0x03436Fe878e33Be3C8CAEbd205ebc04A66aA3248',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24416/thumb/Token_200x200-02-02.png?1647574913',
            'coingeckoId': 'sugar-kingdom',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MDB',
            'name': 'MetaDubai',
            'address': '0x033Fc4eC65a22D29A87631e5feD9616A0d343f42',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19568/thumb/metadubai.PNG?1635423224',
            'coingeckoId': 'metadubai',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CLX',
            'name': 'CargoLink',
            'address': '0x03363A7314F0f1CED76892348909f36d11c475de',
            'decimals': 16,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23773/thumb/Dozvuvjc5KQHodLsrGZFdoJGcb9mNvSO3QklDVq4.jpg?1645433614',
            'coingeckoId': 'cargolink',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HCK',
            'name': 'Hero Cat Key',
            'address': '0x032A4E59387e08443Cf1df662141da09c31ceFa1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21929/thumb/16422.png?1640299379',
            'coingeckoId': 'hero-cat-key',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KANA',
            'name': 'Kanaloa Network',
            'address': '0x0328A69b363a16f66810B23cB0b8d32Abadb203D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x0328A69b363a16f66810B23cB0b8d32Abadb203D/logo.png',
            'coingeckoId': 'kanaloa-network',
            'listedIn': [
                'coingecko',
                'elkfinance'
            ]
        },
        {
            'symbol': 'XBC',
            'name': 'XBN Community',
            'address': '0x0321394309CaD7E0E424650844c3AB3b659315d3',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15161/thumb/logo_xbc.png?1620006072',
            'coingeckoId': 'xbn-community-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FET',
            'name': 'Fetch ai',
            'address': '0x031b41e504677879370e9DBcF937283A8691Fa7f',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/5681/thumb/Fetch.jpg?1572098136',
            'coingeckoId': 'fetch-ai',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COLOS',
            'name': 'Chain Colosseum',
            'address': '0x0318c252e0A310B94D1ab5B70D81E56bBD24F479',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21350/thumb/15800.png?1638999015',
            'coingeckoId': 'chain-colosseum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BDS',
            'name': 'Big Digital Shares',
            'address': '0x030ce78aa5be014976BcA9B8448e78d1d87FCe0B',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17877/thumb/60ztTDu.jpg?1629702327',
            'coingeckoId': 'big-digital-shares',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RB',
            'name': 'REBorn',
            'address': '0x0307ad99Bd569FD8d4934b7313b9B479caF7F225',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28155/thumb/rb.png?1668159763',
            'coingeckoId': 'reborn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KBN',
            'name': 'Koban',
            'address': '0x030708208dC29B1688b212081F31cDB59097A67D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x030708208dC29B1688b212081F31cDB59097A67D/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'YOOSHI',
            'name': 'YooShi',
            'address': '0x02fF5065692783374947393723dbA9599e59F591',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1635844962326_15063996345059216.png',
            'coingeckoId': 'yooshi',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'TAUM',
            'name': 'Orbitau Taureum',
            'address': '0x02e22Eb7F6e73EF313DD71248cD164b1Bdc5aAdd',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23905/thumb/_Token_TAUM_final.png?1645681470',
            'coingeckoId': 'orbitau-taureum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'X2',
            'name': 'X2',
            'address': '0x02d7F3313e8fE95d2150E0c63461fbC6944cfCaF',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/17289/thumb/download_%284%29.png?1627252036',
            'coingeckoId': 'x2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BCL',
            'name': 'Bitcoin Legend',
            'address': '0x02b84f8B64e6c67aE6C797ff4Ec4efb06Ff19b44',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27932/thumb/DjB0X_rP_400x400.jpeg?1666775010',
            'coingeckoId': 'bitcoin-legend',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GALAXYDOGE',
            'name': 'Galaxy Doge',
            'address': '0x02b69bb65a300DE06099B9D025Ea4bAdFf7F1A88',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23933/thumb/1DCA5DD9-844F-4AE9-811A-A64266FBCFB5.png?1645711832',
            'coingeckoId': 'galaxy-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NLCR',
            'name': 'Nolian Credits',
            'address': '0x02b439484227EC3A8798B1777B3076f29Ba2e387',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x02b439484227ec3a8798b1777b3076f29ba2e387/logo.png',
            'coingeckoId': 'nolian-credits',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BFLOKI',
            'name': 'Baby Floki Inu',
            'address': '0x02a9D7162BD73c2b35c5Cf6cDD585e91928c850A',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/16759/thumb/floki.PNG?1624934064',
            'coingeckoId': 'baby-floki-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VENO',
            'name': 'Veno',
            'address': '0x02C24afd0eB2dd298cD0d72D3Be930f4a09D2429',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28000/thumb/VENO.jpg?1666926025',
            'coingeckoId': 'veno',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SENSO',
            'name': 'Sensorium',
            'address': '0x02A9CD311c4d7244F7A0Ac7735b5F1987B76E3DB',
            'decimals': 0,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'KABY',
            'name': 'Kaby Arena',
            'address': '0x02A40C048eE2607B5f5606e445CFc3633Fb20b58',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18090/thumb/URPKhnop_400x400.jpg?1630416255',
            'coingeckoId': 'kaby-arena',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'SHR',
            'name': 'shera  OLD ',
            'address': '0x029E391FC9fbE3183ecCaDBDd029149B49B1dbC5',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23743/thumb/P8dAWBj.jpeg?1650331705',
            'coingeckoId': 'shera',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZEFI',
            'name': 'ZCore Finance',
            'address': '0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x0288d3e353fe2299f11ea2c2e1696b4a648ecc07.png',
            'coingeckoId': 'zcore-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'LVM',
            'name': 'LakeViewMeta',
            'address': '0x02678125Fb30d0fE77fc9D10Ea531f8b4348c603',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27097/thumb/200x200logo.png?1661999646',
            'coingeckoId': 'lakeviewmeta',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NLC',
            'name': 'Nippon Lagoon',
            'address': '0x0260a326cA2d49C981eaa0335C4551d0B4216227',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27441/thumb/nlc.png?1663937288',
            'coingeckoId': 'nippon-lagoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DARA',
            'name': 'Immutable',
            'address': '0x0255af6c9f86F6B0543357baCefA262A2664f80F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x0255af6c9f86f6b0543357bacefa262a2664f80f.png',
            'coingeckoId': 'immutable',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'JUP',
            'name': 'Jupiter',
            'address': '0x0231f91e02DebD20345Ae8AB7D71A41f8E140cE7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x0231f91e02debd20345ae8ab7d71a41f8e140ce7.png',
            'coingeckoId': 'jupiter',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'GNNX',
            'name': 'Gennix',
            'address': '0x022b0f871eDc12B1549f894e3A2d721BBEf7519b',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19938/thumb/gennix.PNG?1636326762',
            'coingeckoId': 'gennix',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RING',
            'name': 'Ring',
            'address': '0x021988d2c89b1A9Ff56641b2F247942358FF05c9',
            'decimals': 5,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24275/thumb/b98GNkOS_400x400.png?1647209734',
            'coingeckoId': 'ring',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RAHO',
            'name': 'Radio Hero',
            'address': '0x0206CFD417f7BfA500B029558232a5f5294dAEd2',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22604/thumb/SkxO6vEv_400x400.jpg?1642162825',
            'coingeckoId': 'radio-hero',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HUNGER',
            'name': 'Hunger',
            'address': '0x02052B756Bf368eFb177B6Ec7F960ef8F67f7e1d',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20335/thumb/xY3MmSf7_400x400.jpg?1636940294',
            'coingeckoId': 'hunger-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USHIBA',
            'name': 'American Shiba',
            'address': '0x01e04C6e0B2C93Bb4f8eE4B71072b861F9352660',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15650/thumb/american_shiba.PNG?1621476610',
            'coingeckoId': 'american-shiba',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DRAGON',
            'name': 'Dragon',
            'address': '0x01dAae426946A93681525bB97496A3A6279faC5D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25803/thumb/200.png?1653977527',
            'coingeckoId': 'dragon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNL',
            'name': 'Unilock Network',
            'address': '0x01dA2A0Af42A382C0D17134Acbdfc2Fd6C92c306',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/23576/thumb/unilock-logo.png?1650449646',
            'coingeckoId': 'unilock-network-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHELL',
            'name': 'Shell',
            'address': '0x01c16da6E041Cf203959624Ade1F39652973D0EB',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18744/thumb/SHELL-icon_cg200x200.png?1633315948',
            'coingeckoId': 'shell-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TREAT',
            'name': 'TreatDAO',
            'address': '0x01bd7acb6fF3B6Dd5aefA05CF085F2104f3fC53F',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19156/thumb/TREAT.jpg?1634543260',
            'coingeckoId': 'treatdao-v2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPACEXDOGE',
            'name': 'Doge Universe',
            'address': '0x01aF9fC6130aedDBD2cDB6E684262F5b605471Cc',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18406/thumb/Capture.PNG?1631795131',
            'coingeckoId': 'doge-universe',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GES',
            'name': 'Stoneage NFT',
            'address': '0x01aD5C8Ca6B2470CbC81c398336F83AAE22E4471',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20156/thumb/1_0-ESEp5iNGUymtUe5nEeoQ.jpeg?1636585963',
            'coingeckoId': 'stoneage-nft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SELECT',
            'name': 'SmartElectrum',
            'address': '0x01FB70eA8AcFDd5435c8e8bd1531d96D57cEd0ec',
            'decimals': 18,
            'chainId': 56,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TLT',
            'name': 'Trip Leverage',
            'address': '0x01F959f4B637ECa434682b620542454572d07042',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18193/thumb/TripLeverage_final_Icon-2.png?1630912165',
            'coingeckoId': 'trip-leverage-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RPG',
            'name': 'Revolve Games',
            'address': '0x01E0d17a533E5930A349C2BB71304F04F20AB12B',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18481/thumb/HQ3.png?1632150968',
            'coingeckoId': 'revolve-games',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'LST',
            'name': 'Lovely Swap',
            'address': '0x019992270e95b800671d3bc1d763E07e489687B2',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26465/thumb/square2.png?1658192916',
            'coingeckoId': 'lovely-swap-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GGT',
            'name': 'Goat Gang',
            'address': '0x0195Af07ff81E2dCdd40BB8F7719fb4426a978C9',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25873/thumb/logo200png.png?1654241785',
            'coingeckoId': 'goat-gang',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWPT',
            'name': 'SwapTracker',
            'address': '0x01832E3346fd3a0d38CA589D836bd78d1dE7030C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/20420/thumb/swaptracker-logo-200x200.png?1637029259',
            'coingeckoId': 'swaptracker',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PRNTR',
            'name': 'PRNTR',
            'address': '0x016c8dA9D916905a00eF26a2e7dC2EE67B6020cF',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x016c8da9d916905a00ef26a2e7dc2ee67b6020cf.png',
            'coingeckoId': 'prntr',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'AIRT',
            'name': 'AirNFT',
            'address': '0x016CF83732f1468150D87dCC5BdF67730B3934D3',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x016cf83732f1468150d87dcc5bdf67730b3934d3.png',
            'coingeckoId': 'airnft-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'GULF',
            'name': 'GulfCoin',
            'address': '0x014a087b646Bd90E7DCEad3993F49EB1F4B5f30a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26053/thumb/gulf-coin-logo-A2F3BBC063-seeklogo.com.png?1655453831',
            'coingeckoId': 'gulfcoin-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '1RT',
            'name': '1Reward Token',
            'address': '0x012a6A39eeC345A0Ea2B994B17875E721d17eE45',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27348/thumb/1RT.png?1663654775',
            'coingeckoId': '1reward-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSP',
            'name': 'BallSwap',
            'address': '0x011734f6Ed20E8D011d85Cf7894814B897420acf',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14050/thumb/bsp.png?1637556507',
            'coingeckoId': 'ballswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PHA',
            'name': 'Phala Network',
            'address': '0x0112e557d400474717056C4e6D40eDD846F38351',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x0112e557d400474717056C4e6D40eDD846F38351.png',
            'coingeckoId': null,
            'listedIn': [
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'BUTTER',
            'name': 'Butter',
            'address': '0x0110fF9e7E4028a5337F07841437B92d5bf53762',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://api.rubic.exchange/assets/binance-smart-chain/0x0110ff9e7e4028a5337f07841437b92d5bf53762/logo.png',
            'coingeckoId': 'butter-token',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DRAGACE',
            'name': 'Dragonrace',
            'address': '0x0104978B2f8AaE89547b7769BDF90cA53236D409',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27735/thumb/Safeimagekit-resized-imgpng_%286%29.png?1665564460',
            'coingeckoId': 'dragonrace',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIELD',
            'name': 'Shield Protocol',
            'address': '0x00f97C17f4Dc4F3BFD2DD9cE5E67f3A339A8a261',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x00f97c17f4dc4f3bfd2dd9ce5e67f3a339a8a261.png',
            'coingeckoId': 'shield-protocol-2',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BOUNTIE',
            'name': 'Bountie Hunter',
            'address': '0x00f80a8f39bb4D04a3038C497E3642bf1B0A304e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24559/thumb/BountieHunter_LogoDesign_TransparentBackground.png?1648186808',
            'coingeckoId': 'bountie-hunter',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BCOIN',
            'name': 'BombCrypto',
            'address': '0x00e1656e45f18ec6747F5a8496Fd39B50b38396D',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.pancakeswap.finance/images/0x00e1656e45f18ec6747F5a8496Fd39B50b38396D.png',
            'coingeckoId': 'bomber-coin',
            'listedIn': [
                'coingecko',
                'openocean',
                'pancake',
                'lifinance'
            ]
        },
        {
            'symbol': 'BURD',
            'name': 'tudaBirds',
            'address': '0x00ceb4868501B456207856bB6f949c3d2af09a66',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/22839/thumb/_TOvRxfx_400x400.jpg?1642745695',
            'coingeckoId': 'tudabirds',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EYN',
            'name': 'Enjoy Network',
            'address': '0x00b22De698ABbb1Ce16a1AcBC7D6B2a3a853Dbf1',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26881/thumb/logo-removebg-preview_%282%29_%281%29_%281%29.png?1660637449',
            'coingeckoId': 'enjoy-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FTF',
            'name': 'Fan Tokens Football',
            'address': '0x00F692B0C9b9182fb7B7a4a2E765fEbC12322Dd3',
            'decimals': 8,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/28020/thumb/logo_cgk.png?1667027032',
            'coingeckoId': 'fan-tokens-football',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TFLOW',
            'name': 'TradeFlow',
            'address': '0x00Ee89F7F21B60b72dD5D4070a4310f796c38C32',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27843/thumb/photo_2022-10-17_12-17-44.jpg?1666061785',
            'coingeckoId': 'tradeflow',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ECH',
            'name': 'Echelon',
            'address': '0x00EaFc9C1fb1b7f7889c7E91441ab62dE0259ad5',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25498/thumb/SpO_F8a0_400x400.jpg?1652077746',
            'coingeckoId': 'echelon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EXC',
            'name': 'Excursion Token',
            'address': '0x00A0De196A68Ec968792A831EC79E9736Ab24396',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26811/thumb/logo_transparent.png?1660272587',
            'coingeckoId': 'excursion-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YKS',
            'name': 'YourKiss',
            'address': '0x00855c21754FE85fD4E38Ac23D2B3E091B04a042',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/25333/thumb/YourKiss_200.png?1651298071',
            'coingeckoId': 'yourkiss',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CAN',
            'name': 'CanYaCoin',
            'address': '0x007EA5C0Ea75a8DF45D288a4debdD5bb633F9e56',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://elk.finance/tokens/logos/bsc/0x007EA5C0Ea75a8DF45D288a4debdD5bb633F9e56/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HORDE',
            'name': 'Horde',
            'address': '0x0069FF45ea9F7c28E0A2dEDb9BEf8144af8131BC',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24586/thumb/cKqzagS.png?1648218405',
            'coingeckoId': 'horde-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XIV',
            'name': 'Planet Inverse',
            'address': '0x00518f36d2E0E514E8EB94D34124fC18eE756f10',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/14231/thumb/photo_2021-06-07_22-10-41.jpg?1623075271',
            'coingeckoId': 'project-inverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XYSL',
            'name': 'xYSL',
            'address': '0x0047A0DEaDAfb7ee6B1A0d219E70Fb6767057D93',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x0047a0deadafb7ee6b1a0d219e70fb6767057d93.png',
            'coingeckoId': 'xysl',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BITD',
            'name': '8Bit Doge',
            'address': '0x003F83da9868AcC151Be89eeFA4D19838FFE5D64',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19244/thumb/8bit.png?1634779507',
            'coingeckoId': '8bit-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JOIN',
            'name': 'JoinCoin',
            'address': '0x003771227d08ac9961B9160b1219fef136546E90',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24245/thumb/join.png?1647093438',
            'coingeckoId': 'joincoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EFB',
            'name': 'ETHFan Burn',
            'address': '0x002d3C280f719c4B0444680A8C4B1785b6cC2A59',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24268/thumb/efb.png?1647174388',
            'coingeckoId': 'ethfan-burn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DMOD',
            'name': 'Demodyfi',
            'address': '0x002D8563759f5e1EAf8784181F3973288F6856e4',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://ethapi.openocean.finance/logos/bsc/0x002d8563759f5e1eaf8784181f3973288f6856e4.png',
            'coingeckoId': 'demodyfi',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SHEEP',
            'name': 'Sheep',
            'address': '0x0025B42bfc22CbbA6c02d23d4Ec2aBFcf6E014d4',
            'decimals': 9,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/18820/thumb/L-sb1AHr_400x400.jpg?1633520042',
            'coingeckoId': 'sheeptoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IVAR',
            'name': 'Ivar Coin',
            'address': '0x001D68afF5407F90957C4aFA7b7a3CfE4e421BB8',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/27014/thumb/ivar-coin-logo-200x200.png?1661477932',
            'coingeckoId': 'ivar-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STELIA',
            'name': 'Stelia',
            'address': '0x0019450b0FB021ad2e9f7928101B171272cd537C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/26041/thumb/X1oqqYf.jpg?1655364474',
            'coingeckoId': 'stelia',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GYMNET',
            'name': 'Gym Network',
            'address': '0x0012365F0a1E5F30a5046c680DCB21D07b15FcF7',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/24654/thumb/Blue.png?1661408547',
            'coingeckoId': 'gym-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SLAM',
            'name': 'Slam',
            'address': '0x000851476180bfc499ea68450A5327D21C9b050e',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/15023/thumb/slam2-0-logo.png?1656643117',
            'coingeckoId': 'slam-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SCOTTY',
            'name': 'Scotty Beam',
            'address': '0x000351d035D8BBf2aa3131EbFECD66Fb21836f6c',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21755/thumb/sign-logo.png?1639986799',
            'coingeckoId': 'scotty-beam',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INF',
            'name': 'Infam',
            'address': '0x000000ef379eE7F4C051f4B9aF901A9219d9ec5C',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/21175/thumb/logo.a475509.png?1638454624',
            'coingeckoId': 'infam',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MERKLE',
            'name': 'Merkle Network',
            'address': '0x000000000ca5171087C18fB271ca844A2370fC0a',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://assets.coingecko.com/coins/images/19838/thumb/merkle.PNG?1636032921',
            'coingeckoId': 'merkle-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHI',
            'name': 'Chi Gastoken by 1inch',
            'address': '0x0000000000004946c0e9F43F4Dee607b0eF1fA1c',
            'decimals': 0,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0x0000000000004946c0e9f43f4dee607b0ef1fa1c.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BNB',
            'name': 'BNB',
            'address': '0x0000000000000000000000000000000000000000',
            'decimals': 18,
            'chainId': 56,
            'logoURI': 'https://tokens.1inch.io/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c_1.png',
            'coingeckoId': 'binancecoin',
            'listedIn': [
                '1inch',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        }
    ],
    '66': [
        {
            'symbol': 'HEP',
            'name': 'Health Potion',
            'address': '0xfD83168291312A0800f44610974350C569d12e42',
            'decimals': 0,
            'chainId': 66,
            'logoURI': 'https://assets.coingecko.com/coins/images/18705/thumb/0xfd83168291312a0800f44610974350c569d12e42.png?1633057969',
            'coingeckoId': 'health-potion',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'LTCK',
            'name': 'OEC LTC',
            'address': '0xfA520efC34C81bfC1E3DD48b7fE9fF326049f986',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://elk.finance/tokens/logos/okex/0xfA520efC34C81bfC1E3DD48b7fE9fF326049f986/logo.png',
            'coingeckoId': 'oec-ltc',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SPE',
            'name': 'SpadeFinance Token',
            'address': '0xf338704c74212D152cB41CeC1DB11E2E783Bcf94',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://ethapi.openocean.finance/logos/okex/0xf338704c74212d152cb41cec1db11e2e783bcf94.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ELK',
            'name': 'Elk',
            'address': '0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://elk.finance/tokens/logos/okex/0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SPIN',
            'name': 'Spintop Token',
            'address': '0xeC8d1E2e8B8B9302d448E24A94399134f124796d',
            'decimals': 18,
            'chainId': 66,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'OKB',
            'name': 'OKB',
            'address': '0xdF54B6c6195EA4d948D03bfD818D365cf175cFC2',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://elk.finance/tokens/logos/okex/0xdF54B6c6195EA4d948D03bfD818D365cf175cFC2/logo.png',
            'coingeckoId': 'okb',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'USDK',
            'name': 'USDK',
            'address': '0xdCAC52E001f5bd413aa6ea83956438F29098166b',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://elk.finance/tokens/logos/okex/0xdCAC52E001f5bd413aa6ea83956438F29098166b/logo.png',
            'coingeckoId': 'usdk',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NVT',
            'name': 'NerveNetwork',
            'address': '0xd1351Ec15E8511658C2Ba1e1e81e1E60aA39c9Cd',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://assets.coingecko.com/coins/images/11873/small/NerveNetwork.png?1595541544',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'FLUX',
            'name': 'Flux Protocol',
            'address': '0xd0C6821aba4FCC65e8f1542589e64BAe9dE11228',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://assets.coingecko.com/coins/images/15002/small/logo.dabc411c.png?1619402947',
            'coingeckoId': 'flux-protocol',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'DESU',
            'name': 'Dexsport Protocol Native Token',
            'address': '0xcc9b1F919282c255eB9AD2C0757E8036165e0cAd',
            'decimals': 18,
            'chainId': 66,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USD Coin',
            'address': '0xc946DAf81b08146B1C7A8Da2A851Ddf2B3EAaf85',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://elk.finance/tokens/logos/okex/0xc946DAf81b08146B1C7A8Da2A851Ddf2B3EAaf85/logo.png',
            'coingeckoId': 'usd-coin',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'dfyn',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CLAW',
            'name': 'Dogeclaw',
            'address': '0xc2f1a8570361DAA6994936d1Dd397e1434F2E2B3',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://assets.coingecko.com/coins/images/26622/thumb/photo_2022-07-20_16-20-59.jpg?1659078436',
            'coingeckoId': 'dogeclaw',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KINE',
            'name': 'KINE',
            'address': '0xc05760d75E7F5Ad428a906674Ce7c7c82d003d01',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://ethapi.openocean.finance/logos/okex/0xc05760d75e7f5ad428a906674ce7c7c82d003d01.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'LINKK',
            'name': 'OEC Chainlink',
            'address': '0xbeb67dE6cC5aF652b2d9B0235750eD70F5a2cB0D',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://ethapi.openocean.finance/logos/okex/0xbeb67de6cc5af652b2d9b0235750ed70f5a2cb0d.png',
            'coingeckoId': 'oec-chainlink',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'HELIX',
            'name': 'Helix',
            'address': '0xb5687be50e1506820996dB6C1EF3a9CD86a7eB66',
            'decimals': 18,
            'chainId': 66,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DOTK',
            'name': 'OEC DOT',
            'address': '0xabc732f6f69a519F6d508434481376B6221eb7d5',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://elk.finance/tokens/logos/okex/0xabc732f6f69a519F6d508434481376B6221eb7d5/logo.png',
            'coingeckoId': 'oec-dot',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'KST',
            'name': 'KSwapToken',
            'address': '0xab0d1578216A545532882e420A8C61Ea07B00B12',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://ethapi.openocean.finance/logos/okex/0xab0d1578216a545532882e420a8c61ea07b00b12.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SHIBK',
            'name': 'OEC SHIB',
            'address': '0xaa27bADaa3C9ec9081b8f6C9cDd2bf375F143780',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://elk.finance/tokens/logos/okex/0xaa27bADaa3C9ec9081b8f6C9cDd2bf375F143780/logo.png',
            'coingeckoId': 'oec-shib',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PIPI',
            'name': 'PippiShrimpToken',
            'address': '0xFdfBC559953557F5442eee7c4bA4AEDc1156caE3',
            'decimals': 18,
            'chainId': 66,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PLATO',
            'name': 'Plato Game',
            'address': '0xF7428FFCb2581A2804998eFbB036A43255c8A8D3',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://assets.coingecko.com/coins/images/24725/thumb/16085.png?1648700698',
            'coingeckoId': 'plato-game',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'O3',
            'name': 'O3 Swap',
            'address': '0xEe9801669C6138E84bD50dEB500827b776777d28',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://assets.coingecko.com/coins/images/15460/thumb/o3.png?1620904316',
            'coingeckoId': 'o3-swap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETHK',
            'name': 'OEC ETH',
            'address': '0xEF71CA2EE68F45B9Ad6F72fbdb33d707b872315C',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://elk.finance/tokens/logos/okex/0xEF71CA2EE68F45B9Ad6F72fbdb33d707b872315C/logo.png',
            'coingeckoId': 'oec-eth',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'dfyn',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BABYOKX',
            'name': 'BABYOKX',
            'address': '0xE24b533D2170E808B64e7e22cC4006d19dfAd70e',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://assets.coingecko.com/coins/images/25227/thumb/babyokx.png?1650950680',
            'coingeckoId': 'babyokx-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'oELK',
            'name': 'Old Elk',
            'address': '0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://elk.finance/tokens/logos/okex/0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ZKS',
            'name': 'ZKS',
            'address': '0xCD08D321f6Bc10a10f094e4b2E6C9b8bF9906401',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://ethapi.openocean.finance/logos/okex/0xcd08d321f6bc10a10f094e4b2e6c9b8bf9906401.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'Glory',
            'name': 'Glory',
            'address': '0xC3BdFEe6186849D5509601045af4Af567A001C94',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://elk.finance/tokens/logos/okex/0xC3BdFEe6186849D5509601045af4Af567A001C94/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'CRYSTAL',
            'name': 'Crystal',
            'address': '0xBD87c06D865a3717A16041aCD8d33922e60E85CE',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://assets.coingecko.com/coins/images/18902/thumb/Crystal200X200.png?1633818428',
            'coingeckoId': 'crystal',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTCBR',
            'name': 'Bitcoin BR',
            'address': '0xB355f4F4CC84a9429a59d5c2B98d77016f7EC482',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://assets.coingecko.com/coins/images/21205/thumb/btcbr.png?1661666691',
            'coingeckoId': 'bitcoin-br',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELE',
            'name': 'ELE',
            'address': '0xAcD7B3D9c10e97d0efA418903C0c7669E702E4C0',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://elk.finance/tokens/logos/okex/0xAcD7B3D9c10e97d0efA418903C0c7669E702E4C0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'pUSDT',
            'name': 'WePiggy USDT',
            'address': '0xADf040519FE24bA9Df6670599B2dE7FD6049772f',
            'decimals': 8,
            'chainId': 66,
            'logoURI': 'https://ethapi.openocean.finance/logos/okex/0xadf040519fe24ba9df6670599b2de7fd6049772f.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'XRPK',
            'name': 'XRPK',
            'address': '0xA715f624EB32c6C960b0E044B10CDe585d5f5984',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://ethapi.openocean.finance/logos/okex/0xa715f624eb32c6c960b0e044b10cde585d5f5984.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'BLADE',
            'name': 'BladeWarrior',
            'address': '0xA07403C1Bd0C5cF53DF07F15fAA589241352527B',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://elk.finance/tokens/logos/okex/0xA07403C1Bd0C5cF53DF07F15fAA589241352527B/logo.png',
            'coingeckoId': 'blade',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'LINK',
            'name': 'LINK',
            'address': '0x9B99c3Ce751aA292320033f93a1376902d4bA58b',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/link.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ETCK',
            'name': 'OEC ETC',
            'address': '0x99970778E2715bBc9Cf8fb83D10dCBC2D2D551A3',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://ethapi.openocean.finance/logos/okex/0x99970778e2715bbc9cf8fb83d10dcbc2d2d551a3.png',
            'coingeckoId': 'oec-etc',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'HOGE',
            'name': 'hoge.finance',
            'address': '0x9873795F5DAb11e1c0342C4a58904c59827ede0c',
            'decimals': 9,
            'chainId': 66,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BabyDoge',
            'name': 'BabyDoge',
            'address': '0x97513e975a7fA9072c72C92d8000B0dB90b163c5',
            'decimals': 9,
            'chainId': 66,
            'logoURI': 'https://elk.finance/tokens/logos/okex/0x97513e975a7fA9072c72C92d8000B0dB90b163c5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'PROPEL',
            'name': 'Propel',
            'address': '0x94977c9888F3D2FAfae290d33fAB4a5a598AD764',
            'decimals': 18,
            'chainId': 66,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PND',
            'name': 'PND',
            'address': '0x8eAC9d49F71A9393eD38a619038e880c86d5745c',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://elk.finance/tokens/logos/okex/0x8eAC9d49F71A9393eD38a619038e880c86d5745c/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'WOKT',
            'name': 'Wrapped OKT',
            'address': '0x8F8526dbfd6E38E3D8307702cA8469Bae6C56C15',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://elk.finance/tokens/logos/okex/0x8F8526dbfd6E38E3D8307702cA8469Bae6C56C15/logo.png',
            'coingeckoId': 'wrapped-okt',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FIN',
            'name': 'DeFiner',
            'address': '0x8D3573f24c0aa3819A2f5b02b2985dD82B487715',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://assets.coingecko.com/coins/images/12780/small/PdaW8Lb.png?1602500407',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'NULS',
            'name': 'Nuls',
            'address': '0x8CD6e29d3686d24d3C2018CEe54621eA0f89313B',
            'decimals': 8,
            'chainId': 66,
            'logoURI': 'https://ethapi.openocean.finance/logos/okex/0x8cd6e29d3686d24d3c2018cee54621ea0f89313b.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'CGS',
            'name': 'Crypto Gladiator Shards',
            'address': '0x81fDe2721F556e402296B2a57E1871637c27D5E8',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://assets.coingecko.com/coins/images/18291/thumb/Scalable.png?1631312146',
            'coingeckoId': 'crypto-gladiator-shards',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHE',
            'name': 'CherrySwap',
            'address': '0x8179D97Eb6488860d816e3EcAFE694a4153F216c',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://ethapi.openocean.finance/logos/okex/0x8179d97eb6488860d816e3ecafe694a4153f216c.png',
            'coingeckoId': 'cherryswap',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'MHUNT',
            'name': 'MetaShooter',
            'address': '0x8057687CBB5D8DDE94a0E11557D5355c4Aecd322',
            'decimals': 18,
            'chainId': 66,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'COOK',
            'name': 'Poly-Peg COOK',
            'address': '0x7f1ae1C42f5f967c0E1ABa3ed7b6D62f88BE4275',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://ethapi.openocean.finance/logos/okex/0x7f1ae1c42f5f967c0e1aba3ed7b6d62f88be4275.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'WING',
            'name': 'Wing Token',
            'address': '0x7A47ab305b8a2A3F4020d13FA9EF73cDdCc0e7D4',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://assets.coingecko.com/coins/images/12477/small/s_wing_icon.png?1610631390',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ORG',
            'name': 'ORG',
            'address': '0x79B627bC95fa5B36ecA53eB39c3cdf43AaFDD10f',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://elk.finance/tokens/logos/okex/0x79B627bC95fa5B36ecA53eB39c3cdf43AaFDD10f/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'AUCTION',
            'name': 'AUCTION',
            'address': '0x77Df6ebec3316792D4ea5bc0f8286c27905Aa8e8',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://ethapi.openocean.finance/logos/okex/0x77df6ebec3316792d4ea5bc0f8286c27905aa8e8.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'COCO',
            'name': 'COCO',
            'address': '0x748dEF2e7fbB37111761Aa78260B0ce71e41d4CA',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://elk.finance/tokens/logos/okex/0x748dEF2e7fbB37111761Aa78260B0ce71e41d4CA/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'HERO',
            'name': 'HERO',
            'address': '0x6b7a87899490EcE95443e979cA9485CBE7E71522',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://elk.finance/tokens/logos/okex/0x6b7a87899490EcE95443e979cA9485CBE7E71522/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'WPC',
            'name': 'WePiggy Coin',
            'address': '0x6F620EC89B8479e97A6985792d0c64F237566746',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://assets.coingecko.com/coins/images/21914/thumb/WPC200.png?1640262945',
            'coingeckoId': 'wepiggy-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GWT',
            'name': 'Galaxy War',
            'address': '0x65511cE6980418dB9dB829b669E7dFD653dae420',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://assets.coingecko.com/coins/images/22166/thumb/e2x7gMJ4_400x400.jpg?1641166277',
            'coingeckoId': 'galaxy-war',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JF',
            'name': 'Jswap Finance',
            'address': '0x5fAc926Bf1e638944BB16fb5B787B5bA4BC85b0A',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://assets.coingecko.com/coins/images/22263/thumb/jswap.PNG?1641335953',
            'coingeckoId': 'jswap-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CELT',
            'name': 'Celestial',
            'address': '0x5Ab622494AB7C5e81911558c9552DBd517F403fb',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://elk.finance/tokens/logos/okex/0x5Ab622494AB7C5e81911558c9552DBd517F403fb/logo.png',
            'coingeckoId': 'celestial',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'UNIK',
            'name': 'OEC UNI',
            'address': '0x59D226BB0a4d74274D4354EBb6a0E1A1Aa5175B6',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://ethapi.openocean.finance/logos/okex/0x59d226bb0a4d74274d4354ebb6a0e1a1aa5175b6.png',
            'coingeckoId': 'oec-uni',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'TTC',
            'name': 'Tech Trees Coin',
            'address': '0x55aF5865807b196bD0197e0902746F31FBcCFa58',
            'decimals': 18,
            'chainId': 66,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BTCK',
            'name': 'OEC BTC',
            'address': '0x54e4622DC504176b3BB432dCCAf504569699a7fF',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://elk.finance/tokens/logos/okex/0x54e4622DC504176b3BB432dCCAf504569699a7fF/logo.png',
            'coingeckoId': 'oec-btc',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'dfyn',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'EB',
            'name': 'Endless Battlefield',
            'address': '0x52Bf8ccD76Aa5d117324750B8F1187FCA4E5332a',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://assets.coingecko.com/coins/images/23981/thumb/eb.png?1645878954',
            'coingeckoId': 'endless-battlefield',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WBTC',
            'name': 'WBTC',
            'address': '0x506f731F7656e2FB34b587B912808f2a7aB640BD',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/btc.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'AST',
            'name': 'AST finance',
            'address': '0x493d8CBd9533E57D4BEfb17cc2eC1dB76828261d',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://assets.coingecko.com/coins/images/22393/thumb/e0ZnF14k_400x400.jpg?1641792528',
            'coingeckoId': 'ast-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FILK',
            'name': 'OEC FIL',
            'address': '0x3F8969Be2FC0770dCc174968e4B4ff146E0ACDaF',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://elk.finance/tokens/logos/okex/0x3F8969Be2FC0770dCc174968e4B4ff146E0ACDaF/logo.png',
            'coingeckoId': 'oec-fil',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'STK',
            'name': 'Super Three Kingdoms',
            'address': '0x3E259bfe720093ABb26A2c3fE57670259B2ebeA2',
            'decimals': 12,
            'chainId': 66,
            'logoURI': 'https://ethapi.openocean.finance/logos/okex/0x3e259bfe720093abb26a2c3fe57670259b2ebea2.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'RUN',
            'name': 'FiveKM RUN',
            'address': '0x3907e6ff436e2b2B05D6B929fb05F14c0ee18d90',
            'decimals': 18,
            'chainId': 66,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BR',
            'name': 'BOHR',
            'address': '0x38389eB214C4AC1CDDa7A7582Ab01e8a9bB548bA',
            'decimals': 6,
            'chainId': 66,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'Tether',
            'address': '0x382bB369d343125BfB2117af9c149795C6C65C50',
            'decimals': 6,
            'chainId': 66,
            'logoURI': 'https://elk.finance/tokens/logos/okex/0x382bB369d343125BfB2117af9c149795C6C65C50/logo.png',
            'coingeckoId': 'tether',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'dfyn',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BUSD',
            'name': 'Binance USD',
            'address': '0x332730a4F6E03D9C55829435f10360E13cfA41Ff',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://elk.finance/tokens/logos/okex/0x332730a4F6E03D9C55829435f10360E13cfA41Ff/logo.png',
            'coingeckoId': 'binance-usd',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SFG',
            'name': 'SFG',
            'address': '0x3212606F74Cc59656E1EC6f587FCA61bA3B85eb0',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://ethapi.openocean.finance/logos/okex/0x3212606f74cc59656e1ec6f587fca61ba3b85eb0.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ACMD',
            'name': 'ACMD TOKEN',
            'address': '0x2f8eB6F75Df534C4992Ec79d9825C5eA4b1b2A57',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://ethapi.openocean.finance/logos/okex/0x2f8eb6f75df534c4992ec79d9825c5ea4b1b2a57.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'VEMP',
            'name': 'vEmpire DDAO',
            'address': '0x2c9a1d0E1226939EdB7BBb68C43a080c28743C5C',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://assets.coingecko.com/coins/images/18074/thumb/Ay5nuny.png?1630380510',
            'coingeckoId': 'vempire-ddao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OFI',
            'name': 'OFI cash',
            'address': '0x2E2f982c3e66Db8604B0A99862dD97D31ed740B3',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://ethapi.openocean.finance/logos/okex/0x2e2f982c3e66db8604b0a99862dd97d31ed740b3.png',
            'coingeckoId': 'ofi-cash',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SUSHI',
            'name': 'SUSHI',
            'address': '0x2218E0D5E0173769F5b4939a3aE423f7e5E4EAB7',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://ethapi.openocean.finance/logos/okex/0x2218e0d5e0173769f5b4939a3ae423f7e5e4eab7.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'DAIK',
            'name': 'OEC DAI',
            'address': '0x21cDE7E32a6CAF4742d00d44B07279e7596d26B9',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://static.oklink.com/cdn/explorer/okexchain/exchain_daik.png',
            'coingeckoId': 'oec-dai',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'BNB',
            'name': 'OEC Binance Coin',
            'address': '0x218c3c3D49d0E7B37aff0D8bB079de36Ae61A4c0',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://elk.finance/tokens/logos/okex/0x218c3c3D49d0E7B37aff0D8bB079de36Ae61A4c0/logo.png',
            'coingeckoId': 'oec-binance-coin',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'KOA',
            'name': 'Koala Token',
            'address': '0x2168795125D44653E0b858b5F6DCc86d719b6A36',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://ethapi.openocean.finance/logos/okex/0x2168795125d44653e0b858b5f6dcc86d719b6a36.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'MARK',
            'name': 'Mark',
            'address': '0x1fE622E91e54D6AD00B01917351Ea6081426764A',
            'decimals': 18,
            'chainId': 66,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'okXEN',
            'name': 'XEN Crypto',
            'address': '0x1cC4D981e897A3D2E7785093A648c0a75fAd0453',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://static.okx.com/cdn/wallet/logo/XEN-20221010.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BCHK',
            'name': 'OEC BCH',
            'address': '0x18D103b7066AEedb6005B78A462Ef9027329b1EA',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://elk.finance/tokens/logos/okex/0x18D103b7066AEedb6005B78A462Ef9027329b1EA/logo.png',
            'coingeckoId': 'oec-bch',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MNTO',
            'name': 'Minato',
            'address': '0x130966628846BFd36ff31a822705796e8cb8C18D',
            'decimals': 18,
            'chainId': 66,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'RACA',
            'name': 'Radio Caca',
            'address': '0x12BB890508c125661E03b09EC06E404bc9289040',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://assets.coingecko.com/coins/images/17841/thumb/ez44_BSs_400x400.jpg?1629464170',
            'coingeckoId': 'radio-caca',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ASPO',
            'name': 'ASPOToken',
            'address': '0x0ebd9537A25f56713E34c45b38F421A1e7191469',
            'decimals': 18,
            'chainId': 66,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'nHUSD',
            'name': 'nHUSD',
            'address': '0x03D1E72765545729A035e909eDd9371a405f77Fb',
            'decimals': 8,
            'chainId': 66,
            'logoURI': 'https://elk.finance/tokens/logos/okex/0x03D1E72765545729A035e909eDd9371a405f77Fb/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'OKFLY',
            'name': 'Okex Fly',
            'address': '0x02F093513b7872CdFC518e51eD67f88F0e469592',
            'decimals': 9,
            'chainId': 66,
            'logoURI': 'https://elk.finance/tokens/logos/okex/0x02F093513b7872CdFC518e51eD67f88F0e469592/logo.png',
            'coingeckoId': 'okex-fly',
            'listedIn': [
                'coingecko',
                'elkfinance'
            ]
        },
        {
            'symbol': 'TRXK',
            'name': 'OEC Tron',
            'address': '0x00505505a7576d6734704FABB16f41924e3E384b',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://elk.finance/tokens/logos/okex/0x00505505a7576d6734704FABB16f41924e3E384b/logo.png',
            'coingeckoId': 'oec-tron',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'OKT',
            'name': 'OKXChain Token',
            'address': '0x0000000000000000000000000000000000000000',
            'decimals': 18,
            'chainId': 66,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1650002331626_7782774638975714.png',
            'coingeckoId': 'oec-token',
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        }
    ],
    '250': [
        {
            'symbol': 'BAKER',
            'name': 'Baker Token',
            'address': '0xfe27133F2E8C8539363883d914Bccb4b21eBd28a',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0xfe27133f2e8c8539363883d914bccb4b21ebd28a/logo.png',
            'coingeckoId': 'baker-guild',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FHM',
            'name': 'Fantohm',
            'address': '0xfa1FBb8Ef55A4855E5688C0eE13aC3f202486286',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0xfa1fbb8ef55a4855e5688c0ee13ac3f202486286.png',
            'coingeckoId': 'fantohm',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ATMSSFT',
            'name': 'AtmosphereCCG',
            'address': '0xfC74d58550485e54dc3A001f6F371741dCEEA094',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MIMATIC',
            'name': 'MAI',
            'address': '0xfB98B335551a418cD0737375a2ea0ded62Ea213b',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0xa3fa99a148fa48d14ed51d610c367c61876997f1.png',
            'coingeckoId': 'mimatic',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'RouterProtocol',
                'SpookySwap',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ORK',
            'name': 'Orkan',
            'address': '0xfB66e49e303A186a4c57414Ceeed651a7a78161a',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/23975/thumb/ork.png?1645872181',
            'coingeckoId': 'orkan',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NOKU',
            'name': 'Noku',
            'address': '0xfB4D42BEd5618fb1a377DDB64EB56B92a6d117f2',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0xfb4d42bed5618fb1a377ddb64eb56b92a6d117f2.png',
            'coingeckoId': 'noku',
            'listedIn': [
                'coingecko',
                '1inch',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'STN',
            'name': 'Stone',
            'address': '0xfA9343C3897324496A05fC75abeD6bAC29f8A40f',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/14593/thumb/stone-logo.png?1617164796',
            'coingeckoId': 'stone-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RAINSPIRIT',
            'name': 'rainSPIRIT',
            'address': '0xf9c6e3C123f0494A4447100bD7dbd536F43CC33A',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/27041/thumb/Coin_1.png?1661502008',
            'coingeckoId': 'rainspirit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LEVL',
            'name': 'Levolution',
            'address': '0xf8a363Cf116b6B633faEDF66848ED52895CE703b',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/7370/thumb/L_Icon_Blue_200x200.png?1618983083',
            'coingeckoId': 'levolution',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RADIO',
            'name': 'RadioShack',
            'address': '0xf899e3909B4492859d44260E1de41A9E663e70F5',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/25307/thumb/ZVoPiysPJq6dPIZm_Se-6vjmsBepwhHlTQfdYZRILbHyVVTRUYCO-wmJJ4zT10HXCGv1j-ZyWr2u2sBaVlap5Y-ILqeXZuIquWdBDxxG0E0qDpgH7omLqYdgWWLSM_TUK9d1PiiYdu6bERdCDaucgFjlqwmhVQK4uV4jyUiXzchVUnu8Qt6SnxlNxz88G0mQ_tfiwkFv_vKqtgb1CcPycVZVz9.jpg?1651211260',
            'coingeckoId': 'radioshack',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'mooCurveG3CRV',
            'name': 'Moo Curve g3CRV',
            'address': '0xf710D90A1Bd702dAEa619eEbbe876e7085c2A1df',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PSHARE',
            'name': 'PSHARE',
            'address': '0xf6036DD216B6B97482510CB647c5D1fEB83cE910',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0xf6036dd216b6b97482510cb647c5d1feb83ce910/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ELITE',
            'name': 'ftm guru',
            'address': '0xf43Cc235E686d7BC513F53Fbffb61F760c3a1882',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://elk.finance/tokens/logos/ftm/0xf43Cc235E686d7BC513F53Fbffb61F760c3a1882/logo.png',
            'coingeckoId': 'ftm-guru',
            'listedIn': [
                'coingecko',
                'SpookySwap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ICE',
            'name': 'Popsicle Finance',
            'address': '0xf16e81dce15B08F326220742020379B855B87DF9',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0xe0ce60af0850bf54072635e66e79df17082a1109.png',
            'coingeckoId': 'ice-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'fSILVER',
            'name': 'fSilver',
            'address': '0xf15e88EEf35BF4709A4C3E99c00358F9247D4531',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/fsilver.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'FMF',
            'name': 'Fantom-moon.finance',
            'address': '0xef656b9EB5A039e46d3A68a9a8614f1a40b0D77c',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0xef656b9eb5a039e46d3a68a9a8614f1a40b0d77c/logo.png',
            'coingeckoId': 'fantom-moon-finance',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SUPRA',
            'name': 'Supra Token',
            'address': '0xee90fAF3216dFAE5E8aC1f3F48f10527f38fFf78',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://ethapi.openocean.finance/logos/fantom/0xee90faf3216dfae5e8ac1f3f48f10527f38fff78.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'TAZOR',
            'name': 'Tazor',
            'address': '0xee65D8B88F86ace0f7bA42BA2d2C679b6f604bf0',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/27261/thumb/tazor.png?1663554021',
            'coingeckoId': 'tazor',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DSHARE',
            'name': 'DSHARE',
            'address': '0xeddF0Dc0772D69572C0b9fFFaFF335ceBC1B6140',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0xeddf0dc0772d69572c0b9fffaff335cebc1b6140/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FRANK',
            'name': 'Frankenstein Finance',
            'address': '0xeb86B05cFEE3e48DB9a44275701Ed099462c8ba0',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0xeb86b05cfee3e48db9a44275701ed099462c8ba0/logo.png',
            'coingeckoId': 'frankenstein-finance',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DEATH',
            'name': 'Death',
            'address': '0xeaF45B62d9d0Bdc1D763baF306af5eDD7C0d7e55',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/DEATH.png',
            'coingeckoId': 'death-token',
            'listedIn': [
                'coingecko',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'NORD',
            'name': 'Nord Finance',
            'address': '0xeaF26191ac1D35AE30Baa19a5ad5558DD8156Aef',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/13630/thumb/nord.jpg?1610465136',
            'coingeckoId': 'nord-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PAPER',
            'name': 'Paper',
            'address': '0xea97c7c1c89d4084e0BFB88284FA90243779da9f',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/23510/thumb/0xea97c7c1c89d4084e0bfb88284fa90243779da9f.png?1644300084',
            'coingeckoId': 'paper-fantom',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XEN',
            'name': 'XEN Crypto',
            'address': '0xeF4B763385838FfFc708000f884026B8c0434275',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1667531965339_6646293409434278.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'ELK',
            'name': 'Elk Finance',
            'address': '0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0xeeeeeb57642040be42185f49c52f7e9b38f8eeee.png',
            'coingeckoId': 'elk-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'IMX',
            'name': 'Impermax  OLD ',
            'address': '0xeA38F1CCF77Bf43F352636241b05dd8f6F5f52B2',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/15126/thumb/Copy_of_200px.png?1619761475',
            'coingeckoId': 'impermax',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WSEXOD',
            'name': 'Wrapped sEXOD',
            'address': '0xe992C5Abddb05d86095B18a158251834D616f0D1',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/22640/thumb/ecod.PNG?1642384698',
            'coingeckoId': 'wrapped-sexod',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RAINI',
            'name': 'Rainicorn',
            'address': '0xe83dFaaAfd3310474D917583Ae9633B4f68fB036',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0xe83dfaaafd3310474d917583ae9633b4f68fb036.png',
            'coingeckoId': 'rainicorn',
            'listedIn': [
                'coingecko',
                '1inch',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'GFC',
            'name': 'Ghost Farmer Capital',
            'address': '0xe794e5cFf1367BC06B6661eA4472e4919CB00186',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0xe794e5cff1367bc06b6661ea4472e4919cb00186/logo.png',
            'coingeckoId': 'ghost-farmer-capital',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'Charge',
            'name': 'Charge',
            'address': '0xe74621A75C6ADa86148b62Eef0894E05444EAE69',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0xe74621a75c6ada86148b62eef0894e05444eae69/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'MOD',
            'name': 'Modefi',
            'address': '0xe64B9fd040D1F9D4715C645e0D567EF69958D3D9',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0xd4fbc57b6233f268e7fba3b66e62719d74deecbc.png',
            'coingeckoId': 'modefi',
            'listedIn': [
                'coingecko',
                '1inch',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'GUSDC',
            'name': 'Geist USDC',
            'address': '0xe578C856933D8e1082740bf7661e379Aa2A30b26',
            'decimals': 6,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/19002/thumb/preview-gallery-gUSDCgecko_%281%29.png?1634790726',
            'coingeckoId': 'geist-usdc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'alUSD',
            'name': 'Alchemix USD',
            'address': '0xe5130D3DbfaC6Ae7d73a24D719762df74d8E4c27',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AWETH',
            'name': 'Aave WETH',
            'address': '0xe50fA9b3c56FfB159cB0FCA61F5c9D750e8128c8',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/17238/thumb/aWETH_2x.png?1626940782',
            'coingeckoId': 'aave-weth',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'TART',
            'name': 'Tartarus Finance',
            'address': '0xe36AC17Edb23Bdd89D363D152365DF019E3eAF71',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/22731/thumb/tk4yctbE_400x400_%281%29.jpg?1647998364',
            'coingeckoId': 'tart',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOUL',
            'name': 'Soul Swap',
            'address': '0xe2fb177009FF39F52C0134E8007FA0e4BaAcBd07',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1648799578344_8740259747892407.png',
            'coingeckoId': 'soul-swap',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'USTC',
            'name': 'Wrapped USTC',
            'address': '0xe2D27f06F63d98b8e11b38b5b08A75D0c8dD62B9',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/15462/thumb/ust.png?1620910058',
            'coingeckoId': 'wrapped-ust',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'fWTIOIL',
            'name': 'fWTI Oil',
            'address': '0xe297e06761a5489380538A0308B6F9b4A53Bea45',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/fwtioil.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BAN',
            'name': 'Banano',
            'address': '0xe20B9e246db5a0d21BF9209E4858Bc9A3ff7A034',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/wBAN.png',
            'coingeckoId': 'banano',
            'listedIn': [
                'coingecko',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'XRUNE',
            'name': 'Thorstarter',
            'address': '0xe1e6B01aE86Ad82B1F1B4EB413B219aC32E17Bf6',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0xe1e6b01ae86ad82b1f1b4eb413b219ac32e17bf6.png',
            'coingeckoId': 'thorstarter',
            'listedIn': [
                'coingecko',
                '1inch',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'fBTC',
            'name': 'fBTC',
            'address': '0xe1146b9AC456fCbB60644c36Fd3F868A9072fc6E',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/btc.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'fEUR',
            'name': 'fEUR',
            'address': '0xe105621721D1293c27be7718e041a4Ce0EbB227E',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/feur.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ALTA',
            'name': 'Alta Finance',
            'address': '0xe0cCa86B254005889aC3a81e737f56a14f4A38F5',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SCREAM',
            'name': 'Scream',
            'address': '0xe0654C8e6fd4D733349ac7E09f6f23DA256bF475',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0xe0654c8e6fd4d733349ac7e09f6f23da256bf475.png',
            'coingeckoId': 'scream',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'CERBY',
            'name': 'Cerby Token',
            'address': '0xdef1fac7Bf08f173D286BbBDcBeeADe695129840',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/fantom/assets/0xdef1fac7Bf08f173D286BbBDcBeeADe695129840/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'BFTM',
            'name': 'Batasm Protocol BFTM',
            'address': '0xdc79AFCe5AE2300834B2bB575bC40cF78EF7b5e3',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/25685/thumb/BFTM.png?1653373134',
            'coingeckoId': 'batasm-protocol-bftm',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'FRAX',
            'name': 'Frax',
            'address': '0xdc301622e621166BD8E82f2cA0A26c13Ad0BE355',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0xd24c2ad096400b6fbcd2ad8b24e7acbc21a1da64.png',
            'coingeckoId': 'frax',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'ANY',
            'name': 'Anyswap',
            'address': '0xdDcb3fFD12750B45d32E084887fdf1aABAb34239',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0xf99d58e463a2e07e5692127302c20a191861b4d6.png',
            'coingeckoId': 'anyswap',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SOL',
            'name': 'Token Wrapped SOL (Wormhole)',
            'address': '0xd99021C2A33e4Cf243010539c9e9b7c52E0236c1',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/4128/small/solana.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ELYS',
            'name': 'Elyseos',
            'address': '0xd89cc0d2A28a769eADeF50fFf74EBC07405DB9Fc',
            'decimals': 5,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/ELYS.png',
            'coingeckoId': null,
            'listedIn': [
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'GEIST',
            'name': 'Geist Finance',
            'address': '0xd8321AA83Fb0a4ECd6348D4577431310A6E0814d',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0xd8321aa83fb0a4ecd6348d4577431310a6e0814d.png',
            'coingeckoId': 'geist-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'SFTMX',
            'name': 'Stader sFTMX',
            'address': '0xd7028092c830b5C8FcE061Af2E593413EbbC1fc1',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0xd7028092c830b5c8fce061af2e593413ebbc1fc1.png',
            'coingeckoId': 'stader-sftmx',
            'listedIn': [
                'coingecko',
                '1inch',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'BIFI',
            'name': 'Beefy Finance',
            'address': '0xd6070ae98b8069de6B494332d1A1a81B6179D960',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0xfbdd194376de19a88118e84e279b977f165d01b8.png',
            'coingeckoId': 'beefy-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'PAWN',
            'name': 'Kingdoms',
            'address': '0xd3b8037853785463D06957Fefa0f06Af53B9e504',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0xd3b8037853785463d06957fefa0f06af53b9e504/logo.png',
            'coingeckoId': 'pawn',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MAGNUM',
            'name': 'MAGNUM',
            'address': '0xd36150041eB168B55Efd4fE9681afC258D5C1C10',
            'decimals': 8,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0xd36150041eb168b55efd4fe9681afc258d5c1c10/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BISON',
            'name': 'Bison',
            'address': '0xd2f38621c3C65300ECabA7020c05d1350f9C265c',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0xd2f38621c3c65300ecaba7020c05d1350f9c265c/logo.png',
            'coingeckoId': 'bishares',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BASTILLE',
            'name': 'Bastille De La Bouje',
            'address': '0xcef2b88d5599d578c8d92E7a6e6235FBfaD01eF4',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0xcef2b88d5599d578c8d92e7a6e6235fbfad01ef4/logo.png',
            'coingeckoId': 'bastille-de-la-bouje',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PARA',
            'name': 'Parabolic',
            'address': '0xcca3E26Be51b8905f1A01872524f17eb55Bd02fb',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/25187/thumb/LHh3hHnQ_400x400.jpg?1650600695',
            'coingeckoId': 'parabolic-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QUINT',
            'name': 'QUINT',
            'address': '0xcc3BED3712CbC88994f85c8a06405A6CE807ba1f',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FLUAN',
            'name': 'FanLuan',
            'address': '0xcbA3307C80Ba758c5A0f0BFdcf1E3029B5C473D8',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0xcba3307c80ba758c5a0f0bfdcf1e3029b5c473d8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'RGT',
            'name': 'Rari Governance',
            'address': '0xcF726A06f3dcEc8eF2b033336D138CaA0EAe5af2',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/12900/thumb/Rari_Logo_Transparent.png?1613978014',
            'coingeckoId': 'rari-governance-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'fGBP',
            'name': 'fGBP',
            'address': '0xcDE58e4B6c7b78B04da664Edb0a9752CC5FEDBd5',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/fgbp.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'PSP',
            'name': 'ParaSwap',
            'address': '0xcAfE001067cDEF266AfB7Eb5A286dCFD277f3dE5',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SGEM',
            'name': 'SGEM',
            'address': '0xc9e8c581d67Fe5cc6217B2Fa1B2d4D9Cecb60fB2',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ICEBRK',
            'name': 'IceBreak R',
            'address': '0xc6E3f3083a15468350976369898381Eec5cfE10f',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/16142/thumb/logo_7faf7883d62a6da137abdfa0da9161c9_2x.png?1623117172',
            'coingeckoId': 'icebreak-r',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TREEB',
            'name': 'Retreeb',
            'address': '0xc60D7067dfBc6f2caf30523a064f416A5Af52963',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0xc60d7067dfbc6f2caf30523a064f416a5af52963.png',
            'coingeckoId': 'treeb',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'OXDV2',
            'name': '0xDAO V2',
            'address': '0xc5A9848b9d145965d821AaeC8fA32aaEE026492d',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/24468/thumb/rjks-OoT_400x400.jpeg?1647710295',
            'coingeckoId': '0xdao-v2',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'LINSPIRIT',
            'name': 'linSpirit',
            'address': '0xc5713B6a0F26bf0fdC1c52B90cd184D950be515C',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/22634/thumb/pirate.4fb08b14.png?1642344628',
            'coingeckoId': 'linspirit',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': '2SHARES',
            'name': '2SHARE',
            'address': '0xc54A1684fD1bef1f077a336E6be4Bd9a3096a6Ca',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0xc54a1684fd1bef1f077a336e6be4bd9a3096a6ca.png',
            'coingeckoId': '2share',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'DUCAT',
            'name': 'Ducat',
            'address': '0xc3118248e7A3b2c103D87392Fca5EB6ED8DaA754',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0xc3118248e7a3b2c103d87392fca5eb6ed8daa754.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'LCD',
            'name': 'Lucidao Token',
            'address': '0xc2A45FE7d40bCAc8369371B08419DDAFd3131b4a',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/LCD.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'OXD',
            'name': '0xDAO',
            'address': '0xc165d941481e68696f43EE6E99BFB2B23E0E3114',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0xc165d941481e68696f43ee6e99bfb2b23e0e3114.png',
            'coingeckoId': '0xdao',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'LIF3',
            'name': 'LIF3',
            'address': '0xbf60e7414EF09026733c1E7de72E7393888C64DA',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/26007/thumb/LIF3.png?1655279569',
            'coingeckoId': 'lif3',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'FONT',
            'name': 'Font',
            'address': '0xbbc4A8d076F4B1888fec42581B6fc58d242CF2D5',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x4c25bdf026ea05f32713f00f73ca55857fbf6342.png',
            'coingeckoId': 'font',
            'listedIn': [
                'coingecko',
                '1inch',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'CASH',
            'name': 'Gangster Legends Cash',
            'address': '0xbb4f92034927d4cE195b7D8f0acD4b3f661c5c45',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/25604/thumb/cash-token-logo.png?1652776559',
            'coingeckoId': 'richcity-cash',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'RMAI',
            'name': 'RMAI',
            'address': '0xbaD3C121641159242Ce9f30984f490E4CC74Bb1c',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'RouterProtocol'
            ]
        },
        {
            'symbol': 'MM',
            'name': 'MMToken',
            'address': '0xbFaf328Fe059c53D936876141f38089df0D1503D',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://elk.finance/tokens/logos/ftm/0xbFaf328Fe059c53D936876141f38089df0D1503D/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'Army',
            'name': 'CroArmy',
            'address': '0xbE08CAEe66D2C1DfF3Ff79B89aA05d647b51D219',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'sSPELL',
            'name': 'Staked Spell',
            'address': '0xbB29D2A58d880Af8AA5859e30470134dEAf84F2B',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/logos/main/network/fantom/0xbB29D2A58d880Af8AA5859e30470134dEAf84F2B.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BITB',
            'name': 'BitBean',
            'address': '0xbAc5d43A56696e5D0CB631609E85798f564b513b',
            'decimals': 0,
            'chainId': 250,
            'logoURI': 'https://elk.finance/tokens/logos/ftm/0xbAc5d43A56696e5D0CB631609E85798f564b513b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'OH',
            'name': 'Oh! Finance',
            'address': '0xb8930BD2dD53D449f83466705827F3dBF90eA7Fc',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ECH',
            'name': 'Echelon',
            'address': '0xb868B46BcEC6595A953e351e6d8459bedEF6805E',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/25498/thumb/SpO_F8a0_400x400.jpg?1652077746',
            'coingeckoId': 'echelon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INV',
            'name': 'Inverse DAO',
            'address': '0xb84527D59b6Ecb96F433029ECc890D4492C5dCe1',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/INV.png',
            'coingeckoId': null,
            'listedIn': [
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'XSTEAK',
            'name': 'xSTEAK',
            'address': '0xb632c5d42BD4a44a617608Ad1c7d38f597E22E3C',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://ethapi.openocean.finance/logos/fantom/0xb632c5d42bd4a44a617608ad1c7d38f597e22e3c.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'SCC',
            'name': 'ScaryChainCapital',
            'address': '0xb63029E37fC5186CB58C3B8C193Fd37b1E1e1D43',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0xb63029e37fc5186cb58c3b8c193fd37b1e1e1d43/logo.png',
            'coingeckoId': 'scary-chain-capital',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MIDAS',
            'name': 'Midas Finance',
            'address': '0xb37528DA6b4D378305d000a66Ad91bd88E626761',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/MIDAS.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'LINK',
            'name': 'Chainlink',
            'address': '0xb3654dc3D10Ea7645f8319668E8F54d2574FBdC8',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0xe2e73a1c69ecf83f464efce6a5be353a37ca09b2.png',
            'coingeckoId': 'chainlink',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'wsSQUID',
            'name': 'Wrapped sSQUID',
            'address': '0xb280458B3cf0FAcC33671D52FB0E894447C2539A',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MSHARE',
            'name': 'Miniverse Share',
            'address': '0xb011EC534d9175cD7a69aFBfc1bcc9990862c462',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/MSHARE.png',
            'coingeckoId': 'miniverse-share',
            'listedIn': [
                'coingecko',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'anyFRAX',
            'name': 'Anyswap Frax',
            'address': '0xaf319E5789945197e365E7f7fbFc56B130523B33',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://elk.finance/tokens/logos/ftm/0xaf319E5789945197e365E7f7fbFc56B130523B33/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SUSHI',
            'name': 'Sushi',
            'address': '0xae75A438b2E0cB8Bb01Ec1E1e376De11D44477CC',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x6b3595068778dd592e39a122f4f5a5cf09c90fe2.png',
            'coingeckoId': 'sushi',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BAG',
            'name': 'Blockchain Adventurers Guild',
            'address': '0xabb63BfCBBe7A928864BD3EB75Ff06746E1B8A40',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/14863/thumb/3iw7MAi.png?1618810870',
            'coingeckoId': 'blockchain-adventurers-guild',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': '0XMR',
            'name': '0xMonero',
            'address': '0xab41861399eb56896b24FBaaBaA8bce45e4A626B',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/11035/thumb/0xmnr.PNG?1587357680',
            'coingeckoId': '0xmonero',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'FSM',
            'name': 'Fantasm FSM Token',
            'address': '0xaa621D2002b5a6275EF62d7a065A865167914801',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0xaa621d2002b5a6275ef62d7a065a865167914801/logo.png',
            'coingeckoId': 'fantasm-fsm',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'COMB',
            'name': 'Comb Finance',
            'address': '0xaE45a827625116d6C0C40B5D7359EcF68F8e9AFD',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/22376/thumb/logo-background-circle-200x200.png?1641782421',
            'coingeckoId': 'comb-finance',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'ALPACA',
            'name': 'Alpaca Finance',
            'address': '0xaD996A45fd2373ed0B10Efa4A8eCB9de445A4302',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x8f0528ce5ef7b51152a59745befdd91d97091d2f.png',
            'coingeckoId': 'alpaca-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'UNBNK',
            'name': 'Unbanked',
            'address': '0xa9dd96d15CAdf566CB39e5c3c327C991E6ED1F4E',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'RAI',
            'name': 'Rai Reflex Index',
            'address': '0xa71353Bb71DdA105D383B02fc2dD172C4D39eF8B',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://ethapi.openocean.finance/logos/fantom/0xa71353bb71dda105d383b02fc2dd172c4d39ef8b.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'WLUM',
            'name': 'Wrapped Lumens',
            'address': '0xa69557e01B0a6b86E5b29BE66d730c0Bfff68208',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://raw.githubusercontent.com/soulswapfinance/assets/analytics/blockchains/fantom/assets/0xa69557e01B0a6b86E5b29BE66d730c0Bfff68208/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'Our',
            'name': 'Our',
            'address': '0xa68eEEe3EB02B4195537DC3326436c83505fB5cf',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/OUR.png',
            'coingeckoId': null,
            'listedIn': [
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'BUILD',
            'name': 'BUILD',
            'address': '0xa6097a4DbEF3EB44c50bad6286a5ED2bc4418AA2',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/26533/thumb/BUILD.png?1658714842',
            'coingeckoId': 'build',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'CRE8',
            'name': 'Creaticles',
            'address': '0xa5DECE54840587B267633dCAD1359cE54C8958F0',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'XBOO',
            'name': 'Boo MirrorWorld',
            'address': '0xa48d959AE2E88f1dAA7D5F611E01908106dE7598',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/22609/thumb/xboo.png?1642220445',
            'coingeckoId': 'boo-mirrorworld',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PROTO',
            'name': 'Protofi',
            'address': '0xa23c4e69e5Eaf4500F2f9301717f12B578b948FB',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0xa23c4e69e5eaf4500f2f9301717f12b578b948fb.png',
            'coingeckoId': 'protofi',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SCC',
            'name': 'Scary Chain Capital',
            'address': '0xa231D452e4bCA86672FD6109de94688d1E17Aae5',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/SCC.png',
            'coingeckoId': null,
            'listedIn': [
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'TCS',
            'name': 'Timechain Swap',
            'address': '0xFbfAE0DD49882e503982f8eb4b8B1e464ecA0b91',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/TCS.png',
            'coingeckoId': 'timechain-swap-token',
            'listedIn': [
                'coingecko',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'FOO',
            'name': 'Fantums of Opera',
            'address': '0xFbc3c04845162F067A0B6F8934383E63899c3524',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/FOO.png',
            'coingeckoId': 'fantums-of-opera-token',
            'listedIn': [
                'coingecko',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'gDELI',
            'name': 'GorskisDeli',
            'address': '0xFa1807BD24a438b389Ca33072375dfbBA74dfaAb',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://ethapi.openocean.finance/logos/fantom/0xfa1807bd24a438b389ca33072375dfbba74dfaab.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'INK',
            'name': 'Ink Fantom',
            'address': '0xFFAbb85ADb5c25D57343547a8b32B62f03814B12',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/23511/thumb/0xffabb85adb5c25d57343547a8b32b62f03814b12.png?1644300315',
            'coingeckoId': 'ink-fantom',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MGG',
            'name': 'MetaGaming Guild',
            'address': '0xFDa8355e8CE22Ac44F2d175f4AcFEC8fAc7472d7',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/23287/thumb/mgg.png?1643543010',
            'coingeckoId': 'metagaming-guild',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FROG',
            'name': 'Frog',
            'address': '0xFA5c941BC491Ee6Dc1E933f38d01d8B5D5637205',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0xfa5c941bc491ee6dc1e933f38d01d8b5d5637205/logo.png',
            'coingeckoId': 'frog-nation-farm',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DNA',
            'name': 'DNA Genesys',
            'address': '0xF8b234A1CE59991006930de8B0525f9013982746',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0xf8b234a1ce59991006930de8b0525f9013982746/logo.png',
            'coingeckoId': 'dna-genesys',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'EURS',
            'name': 'STASISEURSToken',
            'address': '0xF8aCF86194443DCde55fc5B9e448e183c290D8Cb',
            'decimals': 2,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'OCCT',
            'name': 'Official Crypto Cowboy Token',
            'address': '0xF887906E130349A624ffA3CF1FA5b56f48b11c08',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ACRE',
            'name': 'Arable Protocol',
            'address': '0xF792F5FD59679edA6F3a9b0443DC4C1338a95df1',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CODE7',
            'name': 'Code 7',
            'address': '0xF77864FCFfeC4598813E3378681c9330B771cA88',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/CODE7.png',
            'coingeckoId': 'code-7',
            'listedIn': [
                'coingecko',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'POTS',
            'name': 'Moonpot',
            'address': '0xF7554D17d1c3F09899dCc8B404beCAE6dFA584Fa',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://static.debank.com/image/ftm_token/logo_url/0xf7554d17d1c3f09899dcc8b404becae6dfa584fa/60ceb01f080214114be5cf7c1d2523af.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DEGEN',
            'name': 'Degen Finance',
            'address': '0xF61d81d623d9c4a45ff5766EDa5AF224c3dde1A5',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/24148/thumb/degen.png?1646560553',
            'coingeckoId': 'degen-finance',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'dYel',
            'name': 'dYEL Index Token',
            'address': '0xF6134E74b7a4ee23A888436E53e77F83e62E8df8',
            'decimals': 6,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CRIME',
            'name': 'Crime Gold',
            'address': '0xF378f05a51868C29D94f57E4240d7423cB526083',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/25254/thumb/crime.png?1651041053',
            'coingeckoId': 'crime-gold',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'HOGE',
            'name': 'hoge.finance',
            'address': '0xF31778D591c558140398F46feCA42A6a2dbFFe90',
            'decimals': 9,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WIS',
            'name': 'WingSwap',
            'address': '0xF24be6c063Bee7c7844dD90a21fdf7d783d41a94',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/WIS.png',
            'coingeckoId': 'wingswap',
            'listedIn': [
                'coingecko',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BEETS',
            'name': 'Beethoven X',
            'address': '0xF24Bcf4d1e507740041C9cFd2DddB29585aDCe1e',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0xf24bcf4d1e507740041c9cfd2dddb29585adce1e.png',
            'coingeckoId': 'beethoven-x',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'KLR',
            'name': 'Kalori',
            'address': '0xF1b8C53dE4A10668330537608d79a8B581439221',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/23466/thumb/PHOTO-2021-06-24-19-11-38.jpg?1644217406',
            'coingeckoId': 'kalori',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GBOND',
            'name': 'GBOND',
            'address': '0xF0C918B2a27746afc863D32a9A07B5cad3A0Ef42',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'O3',
            'name': 'O3 Swap',
            'address': '0xEe9801669C6138E84bD50dEB500827b776777d28',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/O3.png',
            'coingeckoId': 'o3-swap',
            'listedIn': [
                'coingecko',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'FDOGE',
            'name': 'FDOGE',
            'address': '0xEb0a2D1b1a33D95204af5d00f65FD9e349419878',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0xeb0a2d1b1a33d95204af5d00f65fd9e349419878/logo.png',
            'coingeckoId': 'fdoge-finance',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SOR',
            'name': 'SOR',
            'address': '0xEFFd4874AcA3Acd19a24dF3281b5cdAdD823801A',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://raw.githubusercontent.com/soulswapfinance/assets/prod/blockchains/fantom/assets/0xEFFd4874AcA3Acd19a24dF3281b5cdAdD823801A/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'JulD',
            'name': 'JulSwap',
            'address': '0xEFF6FcfBc2383857Dd66ddf57effFC00d58b7d9D',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x5a41f637c3f7553dba6ddc2d3ca92641096577ea.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'fDLAUNCH',
            'name': 'DefiLaunch Token',
            'address': '0xEF393310B708761E3B6B59813EF41FE45D853CeC',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/fDLAUNCH.png',
            'coingeckoId': null,
            'listedIn': [
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'MAKI',
            'name': 'MakiSwap',
            'address': '0xEDf5e2Ac09002094Cc51B597CebE58C70182ADC5',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/16051/thumb/makiswap.jpg?1622691783',
            'coingeckoId': 'makiswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TAKE',
            'name': 'Takepile',
            'address': '0xE9e5a97aCc59BB68813bF368487fBFfd0a39713b',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/27285/thumb/TAKE_token_200_x_200.png?1663141019',
            'coingeckoId': 'takepile',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WIGO',
            'name': 'WigoSwap',
            'address': '0xE992bEAb6659BFF447893641A378FbbF031C5bD6',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1646911622355_8086848485197822.png',
            'coingeckoId': 'wigoswap',
            'listedIn': [
                'coingecko',
                'openocean'
            ]
        },
        {
            'symbol': 'BORG',
            'name': 'TheBorg.Eth.Link',
            'address': '0xE9828045632b32E2aaC7f3a4C1511C0d8965576C',
            'decimals': 8,
            'chainId': 250,
            'logoURI': 'https://ethapi.openocean.finance/logos/fantom/0xe9828045632b32e2aac7f3a4c1511c0d8965576c.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'DSTEIN',
            'name': 'Dogenstein',
            'address': '0xE91d855e870bb6462ef8876d9AB9C130968b1131',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://elk.finance/tokens/logos/ftm/0xE91d855e870bb6462ef8876d9AB9C130968b1131/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'MCRT',
            'name': 'MagicCraft',
            'address': '0xE705aF5f63fcaBDCDF5016aA838EAaac35D12890',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/MCRT.png',
            'coingeckoId': 'magiccraft',
            'listedIn': [
                'coingecko',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SYN',
            'name': 'Synapse',
            'address': '0xE55e19Fb4F2D85af758950957714292DAC1e25B2',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/18024/thumb/synapse_social_icon.png?1663921797',
            'coingeckoId': 'synapse-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VIVE',
            'name': 'Vive la Bouje',
            'address': '0xE509Db88B3c26D45f1fFf45b48E7c36A8399B45A',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/VIVE.png',
            'coingeckoId': null,
            'listedIn': [
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'cUSD',
            'name': 'Creditum USD',
            'address': '0xE3a486C1903Ea794eED5d5Fa0C9473c7D7708f40',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/cUSD.png',
            'coingeckoId': null,
            'listedIn': [
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'lELK',
            'name': 'Elk Legacy',
            'address': '0xE1C8f3d529BEa8E3fA1FAC5B416335a2f998EE1C',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://elk.finance/tokens/logos/ftm/0xE1C8f3d529BEa8E3fA1FAC5B416335a2f998EE1C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'oELK',
            'name': 'Old Elk',
            'address': '0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://elk.finance/tokens/logos/ftm/0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'WHIRL',
            'name': 'OmniWhirl',
            'address': '0xDfed31E640b7280F76f046a97179E5E369D209b5',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/17165/thumb/logo-200x.png?1626683241',
            'coingeckoId': 'omniwhirl',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'FETHp',
            'name': 'FANTOM ETHPrinter',
            'address': '0xDa25293B6186d597ddf4986c82245f2A48cD6B4D',
            'decimals': 6,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0xda25293b6186d597ddf4986c82245f2a48cd6b4d/logo.png',
            'coingeckoId': 'fantom-ethprinter',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DEUS',
            'name': 'DEUS Finance',
            'address': '0xDE5ed76E7c05eC5e4572CfC88d1ACEA165109E44',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/18778/small/DEUS-logo-200x200.png?1646318385',
            'coingeckoId': 'deus-finance-2',
            'listedIn': [
                'coingecko',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'DEI',
            'name': 'DEI',
            'address': '0xDE1E704dae0B4051e80DAbB26ab6ad6c12262DA0',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/DEI.png',
            'coingeckoId': 'dei-token',
            'listedIn': [
                'coingecko',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'DEI',
            'name': 'DEI Token',
            'address': '0xDE12c7959E1a72bbe8a5f7A1dc8f8EeF9Ab011B3',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/DEI.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'WMEMO',
            'name': 'Wonderful Memories',
            'address': '0xDDc0385169797937066bBd8EF409b5B3c0dFEB52',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/22392/thumb/wMEMO.png?1665832927',
            'coingeckoId': 'wrapped-memory',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'DIAMOND',
            'name': 'Diamond Coin',
            'address': '0xDDa0F0E1081b8d64aB1D64621eb2679F93086705',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/24493/thumb/diamondtoken_%281%29.png?1647872017',
            'coingeckoId': 'diamond-coin',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'renBTC',
            'name': 'renBTC ',
            'address': '0xDBf31dF14B66535aF65AaC99C32e9eA844e14501',
            'decimals': 8,
            'chainId': 250,
            'logoURI': 'https://ethapi.openocean.finance/logos/fantom/0xdbf31df14b66535af65aac99c32e9ea844e14501.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'rubic'
            ]
        },
        {
            'symbol': 'DANTE',
            'name': 'Dante Finance',
            'address': '0xDA763530614fb51DFf9673232C8B3b3e0A67bcf2',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/25190/thumb/DANTE.png?1650605358',
            'coingeckoId': 'dante-finance',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'OXSOLID',
            'name': 'oxSOLID',
            'address': '0xDA0053F0bEfCbcaC208A3f867BB243716734D809',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/24257/thumb/oxSOLID.png?1647168851',
            'coingeckoId': 'oxsolid',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JEWEL',
            'name': 'Jewels',
            'address': '0xD97F9674E2597e7a252de4875985f4385B9608fB',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://static.debank.com/image/ftm_token/logo_url/0xd97f9674e2597e7a252de4875985f4385b9608fb/066eb34f522851f37d1da7b31393eb60.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WBNB',
            'name': 'Wrapped BNB',
            'address': '0xD67de0e0a0Fd7b15dC8348Bb9BE742F3c5850454',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c_1.png',
            'coingeckoId': 'wbnb',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'KOGECOIN',
            'name': 'kogecoin.io',
            'address': '0xD4d026322C88C2d49942A75DfF920FCfbC5614C1',
            'decimals': 9,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ARROWS',
            'name': 'Cupid.Farm',
            'address': '0xD4C000c09bfeF49ABBd5c3728fcec3a42c68eBa1',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0xd4c000c09bfef49abbd5c3728fcec3a42c68eba1/logo.png',
            'coingeckoId': 'cupid-farm',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': '1ART',
            'name': 'OneArt',
            'address': '0xD3c325848D7c6E29b574Cb0789998b2ff901f17E',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0xd3c325848d7c6e29b574cb0789998b2ff901f17e.png',
            'coingeckoId': '1art',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'YEL',
            'name': 'Yel Finance',
            'address': '0xD3b71117E6C1558c1553305b44988cd944e97300',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0xd3b71117e6c1558c1553305b44988cd944e97300.png',
            'coingeckoId': 'yel-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'SEX',
            'name': 'Solidex',
            'address': '0xD31Fcd1f7Ba190dBc75354046F6024A9b86014d7',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/23855/thumb/photo_2022-02-23_09.01.21.jpeg?1645578201',
            'coingeckoId': 'solidex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TNGL',
            'name': 'Tangle',
            'address': '0xD17584633bc8D190E5A14502976daD9640456D6d',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/18312/thumb/tangle.PNG?1631510159',
            'coingeckoId': 'tangle',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WOOFY',
            'name': 'Woofy',
            'address': '0xD0660cD418a64a1d44E9214ad8e459324D8157f1',
            'decimals': 12,
            'chainId': 250,
            'logoURI': 'https://elk.finance/tokens/logos/ftm/0xD0660cD418a64a1d44E9214ad8e459324D8157f1/logo.png',
            'coingeckoId': 'woofy',
            'listedIn': [
                'coingecko',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'G3CRV',
            'name': 'Curve fi gDAI gUSDC gUSDT',
            'address': '0xD02a30d33153877BC20e5721ee53DeDEE0422B2F',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/24284/thumb/Untitled_design_%282%29.png?1647243402',
            'coingeckoId': 'curve-fi-gdai-gusdc-gusdt',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'AFTMC',
            'name': 'Ankr Reward Bearing FTM',
            'address': '0xCfC785741Dc0e98ad4c9F6394Bb9d43Cd1eF5179',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/26031/thumb/a-ftm-c-54cdd766209f469501af5a25f86bb443.png?1655350183',
            'coingeckoId': 'ankr-reward-bearing-ftm',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'LSHARE',
            'name': 'LIF3 LSHARE',
            'address': '0xCbE0CA46399Af916784cADF5bCC3aED2052D6C45',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1660709030350_06707583359611102.png',
            'coingeckoId': 'lif3-lshare',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'WSTA',
            'name': 'Wrapped Statera',
            'address': '0xCEeBDE49eC95E21F7eE63C5c6f98CaB3519570de',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/13599/thumb/wsta_logo.png?1610011862',
            'coingeckoId': 'wrapped-statera',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'BUSD',
            'name': 'Binance USD',
            'address': '0xC931f61B1534EB21D8c11B24f3f5Ab2471d4aB50',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://ethapi.openocean.finance/logos/fantom/0xc931f61b1534eb21d8c11b24f3f5ab2471d4ab50.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'MSHARE',
            'name': 'MShare',
            'address': '0xC8Ca9026Ad0882133Ef126824F6852567c571A4E',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/23400/thumb/mshare.png?1644137282',
            'coingeckoId': 'mshare',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'FS',
            'name': 'FantomStarter',
            'address': '0xC758295Cd1A564cdb020a78a681a838CF8e0627D',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0xc758295cd1a564cdb020a78a681a838cf8e0627d.png',
            'coingeckoId': 'fantomstarter',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'TAROT',
            'name': 'Tarot',
            'address': '0xC5e2B037D30a390e62180970B3aa4E91868764cD',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0xc5e2b037d30a390e62180970b3aa4e91868764cd.png',
            'coingeckoId': 'tarot',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'FLIBERO',
            'name': 'Fantom Libero Financial',
            'address': '0xC3f069D7439baf6D4D6E9478D9Cc77778E62D147',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/FLIBERO.png',
            'coingeckoId': 'fantom-libero-financial',
            'listedIn': [
                'coingecko',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'CASPER',
            'name': 'CASPER TOKEN',
            'address': '0xC30d1b0Ce932C3dd3373a2C23aDA4E9608CAf345',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0xc30d1b0ce932c3dd3373a2c23ada4e9608caf345/logo.png',
            'coingeckoId': 'casper-defi',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'GTON',
            'name': 'GTON CAPITAL',
            'address': '0xC1Be9a4D5D45BeeACAE296a7BD5fADBfc14602C4',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/GTON.png',
            'coingeckoId': 'gton-capital',
            'listedIn': [
                'coingecko',
                'openocean',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'RISE',
            'name': 'EverRise',
            'address': '0xC17c30e98541188614dF99239cABD40280810cA3',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/16367/thumb/Logo_EverRise_Icon_logo.png?1642576670',
            'coingeckoId': 'everrise',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FFF',
            'name': 'FoodFarmer.Finance',
            'address': '0xC16B2419494aE0604432297D40CDF0e8d68dE8d6',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0xc16b2419494ae0604432297d40cdf0e8d68de8d6/logo.png',
            'coingeckoId': 'food-farmer-finance',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MOVE',
            'name': 'Mover',
            'address': '0xC055c698f3793577707B3e6979B089F50C314D3a',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/13719/thumb/o0KIvs7T_400x400.jpg?1617672818',
            'coingeckoId': 'holyheld-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MARS',
            'name': 'ProjectMars',
            'address': '0xBE41772587872A92184873d55B09C6bB6F59f895',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://ethapi.openocean.finance/logos/fantom/0xbe41772587872a92184873d55b09c6bb6f59f895.png',
            'coingeckoId': 'projectmars',
            'listedIn': [
                'coingecko',
                'openocean'
            ]
        },
        {
            'symbol': 'pSHARE',
            'name': 'pSHARE',
            'address': '0xB92E1FdA97e94B474516E9D8A9E31736f542e462',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://static.debank.com/image/ftm_token/logo_url/0xb92e1fda97e94b474516e9d8a9e31736f542e462/785b75922281005825d153354eac56fc.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'RSHARE',
            'name': 'RSHARE',
            'address': '0xB835631824ED68D84c5f41C231f3C2b6C2D709d5',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0xb835631824ed68d84c5f41c231f3c2b6c2d709d5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ALUSD',
            'name': 'Alchemix USD',
            'address': '0xB67FA6deFCe4042070Eb1ae1511Dcd6dcc6a532E',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/14114/thumb/Alchemix_USD.png?1614410406',
            'coingeckoId': 'alchemix-usd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PILLS',
            'name': 'Morpheus Swap',
            'address': '0xB66b5D38E183De42F21e92aBcAF3c712dd5d6286',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1645065551648_5450712678708434.png',
            'coingeckoId': 'morpheus-token',
            'listedIn': [
                'coingecko',
                'openocean'
            ]
        },
        {
            'symbol': 'COIN',
            'name': 'Mushroom Coin',
            'address': '0xB4559681514867B6Db1e7e805118Cfad16949D37',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0xb4559681514867b6db1e7e805118cfad16949d37/logo.png',
            'coingeckoId': 'mushroom-coin',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'AFTMB',
            'name': 'ANKR Reward Earning FTM',
            'address': '0xB42bF10ab9Df82f9a47B86dd76EEE4bA848d0Fa2',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1647240777446_33337125732765927.png',
            'coingeckoId': 'ankr-reward-earning-ftm',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic'
            ]
        },
        {
            'symbol': 'BTCBR',
            'name': 'Bitcoin BR',
            'address': '0xB355f4F4CC84a9429a59d5c2B98d77016f7EC482',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/21205/thumb/btcbr.png?1661666691',
            'coingeckoId': 'bitcoin-br',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CYBER',
            'name': 'Cyber',
            'address': '0xB2d65f66B69BF3ac78ad2396d6356F4F0e1036B7',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0xb2d65f66b69bf3ac78ad2396d6356f4f0e1036b7/logo.png',
            'coingeckoId': 'cyberdao',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': '7SHARE',
            'name': '7SHARE Token',
            'address': '0xB215014176720EdA5334df07f827c3f11ec0f1bD',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0xb215014176720eda5334df07f827c3f11ec0f1bd/logo.png',
            'coingeckoId': '7share',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BSM',
            'name': 'Batasm Protocol BSM',
            'address': '0xB214d491a58a250A99f11cE01C361E7fAd4d3E69',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/25684/thumb/BSM.png?1653372942',
            'coingeckoId': 'batasm-protocol-bsm',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'COVER',
            'name': 'Cover Protocol',
            'address': '0xB01E8419d842beebf1b70A7b5f7142abbaf7159D',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://elk.finance/tokens/logos/ftm/0xB01E8419d842beebf1b70A7b5f7142abbaf7159D/logo.png',
            'coingeckoId': 'cover-protocol',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FTMP',
            'name': 'FTMPay',
            'address': '0xAfF15DAe5C956f9D7d5B98F304863201EA47c656',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0xaff15dae5c956f9d7d5b98f304863201ea47c656/logo.png',
            'coingeckoId': 'ftmpay',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BIG',
            'name': 'BIG Token',
            'address': '0xAfAEb84415c1cd2bC99cd1F5F0B3090baE1BEb6c',
            'decimals': 9,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WILD',
            'name': 'WILD',
            'address': '0xAe0C241Ec740309c2cbdc27456eB3C1a2aD74737',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://elk.finance/tokens/logos/ftm/0xAe0C241Ec740309c2cbdc27456eB3C1a2aD74737/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'FUSD',
            'name': 'Fantom USD',
            'address': '0xAd84341756Bf337f5a0164515b1f6F993D194E1f',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://elk.finance/tokens/logos/ftm/0xAd84341756Bf337f5a0164515b1f6F993D194E1f/logo.png',
            'coingeckoId': 'fantom-usd',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ELE',
            'name': 'Eleven.finance',
            'address': '0xAcD7B3D9c10e97d0efA418903C0c7669E702E4C0',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0xacd7b3d9c10e97d0efa418903c0c7669e702e4c0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SEX',
            'name': 'Solidex',
            'address': '0xADC55B2336f0ec7Eb47c383D9518282f702E9C70',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0xadc55b2336f0ec7eb47c383d9518282f702e9c70/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BIFI',
            'name': 'BiFi',
            'address': '0xAD260f380C9a30B1d60e4548A75010ede630B665',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/13671/thumb/ysYIu7Q.png?1610679337',
            'coingeckoId': 'bifi',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'WNOW',
            'name': 'WalletNow',
            'address': '0xA9CAd0165C155f3998b0001b3eF30bCa0aa6B591',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/20149/thumb/walletnow.PNG?1636582255',
            'coingeckoId': 'walletnow',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'OLIVE',
            'name': 'Olive Cash',
            'address': '0xA9937092c4E2B0277C16802Cc8778D252854688A',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://elk.finance/tokens/logos/ftm/0xA9937092c4E2B0277C16802Cc8778D252854688A/logo.png',
            'coingeckoId': 'olivecash',
            'listedIn': [
                'coingecko',
                'rubic',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PANIC',
            'name': 'PanicSwap',
            'address': '0xA882CeAC81B22FC2bEF8E1A82e823e3E9603310B',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/PANIC.png',
            'coingeckoId': 'panicswap',
            'listedIn': [
                'coingecko',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'WSSCR',
            'name': 'Wrapped Staked SCR',
            'address': '0xA7727db8DB5afcA6d88eb7FB9E8e322dc043325a',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/23956/thumb/wsSCR200px.png?1645774656',
            'coingeckoId': 'wrapped-staked-scr',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TGOLD',
            'name': 'Tank Gold',
            'address': '0xA74bb2C6e5fE2805a0086D05e37c256274935690',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/TGOLD.png',
            'coingeckoId': 'tank-gold',
            'listedIn': [
                'coingecko',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'OLE',
            'name': 'OpenLeverage',
            'address': '0xA6ad0EF910CA4C54A97CEcF82bf5892c0fA4913b',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WRAITH',
            'name': 'Wraith Protocol',
            'address': '0xA6D8D321Ab1ec5349fA2a628E8649681822119B4',
            'decimals': 9,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'fLINK',
            'name': 'fLINK',
            'address': '0xA649A19423052dC6b320360B3C760884E095AC57',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/flink.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'TRICK',
            'name': 'Trick',
            'address': '0xA5aFce54270D9afA6a80464bBD383BE506888e6A',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0xa5afce54270d9afa6a80464bbd383be506888e6a/logo.png',
            'coingeckoId': 'trick',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'STAY',
            'name': 'StrategyX',
            'address': '0xA5365f2E77bCe1cb2D42F5c808012C01b1548d3C',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/26216/thumb/5_m07Fm2_400x400.jpg?1656578053',
            'coingeckoId': 'strategyx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABY',
            'name': 'Babylonia',
            'address': '0xA4E26Bd6DCBa9021DCd3A1159BA52e97CD770b8a',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/26352/thumb/Babylonia_Logo_02.01_FFD42A_200px.png?1657590351',
            'coingeckoId': 'babylonia',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'iFUSD',
            'name': 'iFUSD',
            'address': '0x9fC071cE771c7B27b7d9A57C32c0a84c18200F8a',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://ethapi.openocean.finance/logos/fantom/0x9fc071ce771c7b27b7d9a57c32c0a84c18200f8a.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'OOE',
            'name': 'OpenOcean',
            'address': '0x9d8F97A3C2f9f397B6D46Cbe2d39CC1D8Cf19010',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/OOE.png',
            'coingeckoId': 'openocean',
            'listedIn': [
                'coingecko',
                'openocean',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'FTMO',
            'name': 'Fantom Oasis',
            'address': '0x9bD0611610A0f5133e4dd1bFdd71C5479Ee77f37',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x9bd0611610a0f5133e4dd1bfdd71c5479ee77f37.png',
            'coingeckoId': 'fantom-oasis',
            'listedIn': [
                'coingecko',
                '1inch',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'PBL',
            'name': 'Pebble',
            'address': '0x9a2d0935E6CC558aaBa4EbD280F03A74b4752ADd',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/26668/thumb/pbl_%281%29.png?1659501596',
            'coingeckoId': 'pebble',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MULTI',
            'name': 'Multichain',
            'address': '0x9Fb9a33956351cf4fa040f65A13b835A3C8764E3',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x9fb9a33956351cf4fa040f65a13b835a3c8764e3.png',
            'coingeckoId': 'multichain',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'SpookySwap',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'JOE',
            'name': 'JoeToken',
            'address': '0x9F47F313ACFd4bdC52F4373b493EaE7d5aC5b765',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x6e84a6216ea6dacc71ee8e6b0a5b7322eebc0fdd.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'KIF',
            'name': 'KittenFinance',
            'address': '0x9D3E3624b3de482331F4f091Fd8b76D3F344bd8C',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://ethapi.openocean.finance/logos/fantom/0x9d3e3624b3de482331f4f091fd8b76d3f344bd8c.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'SHIBA',
            'name': 'Shiba Fantom',
            'address': '0x9Ba3e4F84a34DF4e08C112e1a0FF148b81655615',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://elk.finance/tokens/logos/ftm/0x9Ba3e4F84a34DF4e08C112e1a0FF148b81655615/logo.png',
            'coingeckoId': 'shiba-fantom',
            'listedIn': [
                'coingecko',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BLOOM',
            'name': 'Bloom',
            'address': '0x9B2e37cDC711CfcAC1E1482B5741c74dd3924199',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x9b2e37cdc711cfcac1e1482b5741c74dd3924199/logo.png',
            'coingeckoId': 'bloom-token',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'TUSD',
            'name': 'TrueUSD',
            'address': '0x9879aBDea01a879644185341F7aF7d8343556B7a',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x1c20e891bab6b1727d14da358fae2984ed9b59eb.png',
            'coingeckoId': 'true-usd',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'TEN',
            'name': 'Teneo',
            'address': '0x9833c643f387ecFB76AA8114546AD524703c66Fb',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'KLING',
            'name': 'Klingon Token',
            'address': '0x983235dC2a7b5e5a0820066480b19f04EEf83986',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://ethapi.openocean.finance/logos/fantom/0x983235dc2a7b5e5a0820066480b19f04eef83986.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'A4',
            'name': 'A4 Finance',
            'address': '0x9767203e89dcD34851240B3919d4900d3E5069f1',
            'decimals': 6,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/21992/thumb/ba384ad07217a4be75cb85314f5760f7.jpg?1640582786',
            'coingeckoId': 'a4-finance',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': '$TRDL',
            'name': 'Strudel Finance',
            'address': '0x96b14E64b53B9950d2a4D2292f8dc7d51a4f467A',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FTML',
            'name': 'FTMlaunch',
            'address': '0x9613288dCF37c14324AB64EB53b2e7Be7292d724',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/FTML.png',
            'coingeckoId': 'ftmlaunch',
            'listedIn': [
                'coingecko',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BCP',
            'name': 'PieDAO Balanced Crypto Pie',
            'address': '0x9611579c926294b0e29E5371A81a3e463650Be17',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/13560/thumb/BCP.png?1609813753',
            'coingeckoId': 'piedao-balanced-crypto-pie',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'WSHEC',
            'name': 'Wrapped HEC',
            'address': '0x94CcF60f700146BeA8eF7832820800E2dFa92EdA',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x94ccf60f700146bea8ef7832820800e2dfa92eda.png',
            'coingeckoId': 'wrapped-hec',
            'listedIn': [
                'coingecko',
                '1inch',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'GFUSDT',
            'name': 'Geist fUSDT',
            'address': '0x940F41F0ec9ba1A34CF001cc03347ac092F5F6B5',
            'decimals': 6,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x940f41f0ec9ba1a34cf001cc03347ac092f5f6b5.png',
            'coingeckoId': 'geist-fusdt',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ATLAS',
            'name': 'Atlas Cloud',
            'address': '0x92df3eaBf7c1c2A6b3D5793f6d53778eA78c48b2',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/Atlas.png',
            'coingeckoId': null,
            'listedIn': [
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BRIDGE',
            'name': 'Cross Chain Bridge',
            'address': '0x92868A5255C628dA08F550a858A802f5351C5223',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/20223/thumb/0x92868A5255C628dA08F550a858A802f5351C5223.png?1636684446',
            'coingeckoId': 'cross-chain-bridge',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'SFI',
            'name': 'Spice',
            'address': '0x924828a9Fb17d47D0eb64b57271D10706699Ff11',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://elk.finance/tokens/logos/ftm/0x924828a9Fb17d47D0eb64b57271D10706699Ff11/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'VID',
            'name': 'Vivid Labs',
            'address': '0x922D641a426DcFFaeF11680e5358F34d97d112E1',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/3376/thumb/1638280236344.jpg?1645083911',
            'coingeckoId': 'videocoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOHM',
            'name': 'Governance OHM',
            'address': '0x91fa20244Fb509e8289CA630E5db3E9166233FDc',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x91fa20244fb509e8289ca630e5db3e9166233fdc.png',
            'coingeckoId': 'governance-ohm',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'XGRO',
            'name': 'GROWTH DeFi',
            'address': '0x91F1430833879272643658f8eD07d60257dDf321',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/GRO.png',
            'coingeckoId': 'growth-defi',
            'listedIn': [
                'coingecko',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'WEVE',
            'name': 'veDAO',
            'address': '0x911da02C1232A3c3E1418B834A311921143B04d7',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1643475764148_5880781854202268.jpg',
            'coingeckoId': 'vedao',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic'
            ]
        },
        {
            'symbol': 'WV',
            'name': 'Wolf Ventures',
            'address': '0x911d1FEaE99e112B5014cfa60038325d863352f1',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/28051/thumb/wv_trans_logo_%281%29.png?1667281353',
            'coingeckoId': 'wolf-ventures',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HYVE',
            'name': 'Hyve',
            'address': '0x90b89e881961E1053AeaDdbA13217d56F747349a',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/13072/thumb/bAe1G-lD_400x400.png?1654056255',
            'coingeckoId': 'hyve',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DMD',
            'name': 'Dark Matter Defi',
            'address': '0x90E892FED501ae00596448aECF998C88816e5C0F',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x90e892fed501ae00596448aecf998c88816e5c0f.png',
            'coingeckoId': 'dark-matter-defi',
            'listedIn': [
                'coingecko',
                '1inch',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'CZTEARS',
            'name': 'CzTears',
            'address': '0x907f1A48918Bb5DE07c12443CAB0e6EEfCC611BC',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://elk.finance/tokens/logos/ftm/0x907f1A48918Bb5DE07c12443CAB0e6EEfCC611BC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FAME',
            'name': 'Fantom Maker',
            'address': '0x904f51a2E7eEaf76aaF0418cbAF0B71149686f4A',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/FAME.png',
            'coingeckoId': 'fantom-maker',
            'listedIn': [
                'coingecko',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'DEP',
            'name': 'DEAPCOIN',
            'address': '0x8dFDC61c7c7551D0DEec950A2822eB59cddb8f59',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/DEP.png',
            'coingeckoId': null,
            'listedIn': [
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SINGLE',
            'name': 'Single Token',
            'address': '0x8cc97B50Fe87f31770bcdCd6bc8603bC1558380B',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/22616/small/single.png?1642313588',
            'coingeckoId': null,
            'listedIn': [
                'SpookySwap',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'START',
            'name': 'BSCstarter',
            'address': '0x8ca2ecbCE34c322fceA6Db912d9DbfD2DdA5920D',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://ethapi.openocean.finance/logos/fantom/0x8ca2ecbce34c322fcea6db912d9dbfd2dda5920d.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'COVAL',
            'name': 'Circuits of Value',
            'address': '0x8b8407c6184f1f0Fd1082e83d6A3b8349cAcEd12',
            'decimals': 8,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/588/thumb/coval-logo.png?1599493950',
            'coingeckoId': 'circuits-of-value',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'AXL',
            'name': 'Axelar',
            'address': '0x8b1f4432F943c465A973FeDC6d7aa50Fc96f1f65',
            'decimals': 6,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/27277/thumb/V-65_xQ1_400x400.jpeg?1663121730',
            'coingeckoId': 'axelar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NBK',
            'name': 'Nova Token',
            'address': '0x8a7DC00BbFCd44344DE7BC6E01D82d518032110c',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/22237/thumb/512NT-300x300.png?1641262914',
            'coingeckoId': 'nova-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PAE',
            'name': 'Ripae',
            'address': '0x8a41f13a4FaE75ca88B1ee726ee9D52B148b0498',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x8a41f13a4fae75ca88b1ee726ee9d52b148b0498.png',
            'coingeckoId': 'ripae',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'POND',
            'name': 'Marlin POND',
            'address': '0x8Fbf3759cDe693B19493663a4ef5853728Cb71f8',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SUMMIT',
            'name': 'summit defi',
            'address': '0x8F9bCCB6Dd999148Da1808aC290F2274b13D7994',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1635386858646_7691864304094242.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'BASED',
            'name': 'Based Finance',
            'address': '0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/23507/small/based.PNG?1644298153',
            'coingeckoId': 'based-finance',
            'listedIn': [
                'coingecko',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'DAI',
            'name': 'Dai',
            'address': '0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e.png',
            'coingeckoId': 'dai',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'dfyn',
                'SpookySwap',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SPR',
            'name': 'Spiral',
            'address': '0x8C739564345DfCb7e4C7e520B0E8fA142c358a78',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/23007/thumb/Z0y8aqD.png?1643092035',
            'coingeckoId': 'spiral',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INTD',
            'name': 'INDESTCOIN',
            'address': '0x8Bb93979901cd159bF6763B223FBb315C31CCF7b',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/27543/thumb/logoend.png?1668586274',
            'coingeckoId': 'indestcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KISHIMOTO',
            'name': 'Kishimoto  old ',
            'address': '0x8B6663725D7e7368B01EeFdDc16Be085e492498E',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/19188/thumb/cmclogo.png?1666922508',
            'coingeckoId': 'kishimoto-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAN',
            'name': 'MangaMon',
            'address': '0x8A88b501A68ceA5844B9d95F41892b05c4cd1d73',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/25914/thumb/Screenshot_2022-03-31_163228.png?1654588337',
            'coingeckoId': 'mangamon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STA',
            'name': 'Statera',
            'address': '0x89D5e71E275B4bE094Df9551627BCF4E3b24cE22',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/STA.png',
            'coingeckoId': 'statera',
            'listedIn': [
                'coingecko',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'wsSPA',
            'name': 'Wrapped sSPA',
            'address': '0x89346B51A54263cF2e92dA79B1863759eFa68692',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/wsSPA.png',
            'coingeckoId': null,
            'listedIn': [
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SOLID',
            'name': 'Solidly',
            'address': '0x888EF71766ca594DED1F0FA3AE64eD2941740A20',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/SOLID.png',
            'coingeckoId': 'solidly',
            'listedIn': [
                'coingecko',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'RNDM',
            'name': 'Random',
            'address': '0x87d57F92852D7357Cf32Ac4F6952204f2B0c1A27',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/RNDM.png',
            'coingeckoId': 'random',
            'listedIn': [
                'coingecko',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'MAGIK',
            'name': 'Magik',
            'address': '0x87a5C9B60A3aaf1064006FE64285018e50e0d020',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/23399/thumb/magik.png?1644136961',
            'coingeckoId': 'magik',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BUDS',
            'name': 'Hashkings Buds',
            'address': '0x87FCE3A8A93860f4c6F1e5f8Ce3346caF968ae01',
            'decimals': 0,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x87fce3a8a93860f4c6f1e5f8ce3346caf968ae01/logo.png',
            'coingeckoId': 'hashkings-buds',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BRUSH',
            'name': 'Paint Swap',
            'address': '0x85dec8c4B2680793661bCA91a8F129607571863d',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x85dec8c4b2680793661bca91a8f129607571863d.png',
            'coingeckoId': 'paint-swap',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'BOMB',
            'name': 'fBOMB',
            'address': '0x8503eb4A136bDBeB323E37Aa6e0FA0C772228378',
            'decimals': 0,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/24109/thumb/logo-blue.png?1646376874',
            'coingeckoId': 'fbomb',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'CSC',
            'name': 'Cosmic Cash',
            'address': '0x84F8D24231DfbBfae7f39415cD09c8f467729fc8',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/26338/thumb/csc-256x256.png?1657497462',
            'coingeckoId': 'cosmic-cash',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'BFC',
            'name': 'Bifrost',
            'address': '0x84C882A4d8Eb448cE086EA19418Ca0f32F106117',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/4639/thumb/bifrost_32.png?1608520677',
            'coingeckoId': 'bifrost',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'UST',
            'name': 'TerraUSD  Wormhole ',
            'address': '0x846e4D51d7E2043C1a87E0Ab7490B93FB940357b',
            'decimals': 6,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/USTWORMHOLE.png',
            'coingeckoId': 'terrausd-wormhole',
            'listedIn': [
                'coingecko',
                'openocean',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BOO',
            'name': 'Spookyswap',
            'address': '0x841FAD6EAe12c286d1Fd18d1d525DFfA75C7EFFE',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0xbd83010eb60f12112908774998f65761cf9f6f9a.png',
            'coingeckoId': 'spookyswap',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MIM',
            'name': 'Magic Internet Money',
            'address': '0x82f0B8B456c1A451378467398982d4834b6829c1',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x130966628846bfd36ff31a822705796e8cb8c18d.png',
            'coingeckoId': 'magic-internet-money',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'anyFXS',
            'name': 'Anyswap Frax Share',
            'address': '0x82F8Cb20c14F134fe6Ebf7aC3B903B2117aAfa62',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://elk.finance/tokens/logos/ftm/0x82F8Cb20c14F134fe6Ebf7aC3B903B2117aAfa62/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'SpookySwap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ADAI',
            'name': 'Aave DAI',
            'address': '0x82E64f49Ed5EC1bC6e43DAD4FC8Af9bb3A2312EE',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/14242/thumb/aDAI.84b6c41f.png?1615528749',
            'coingeckoId': 'aave-dai',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'scCRV',
            'name': 'Scream Wrapped CRV',
            'address': '0x820BdA1786AFA19DA6B92d6AC603574962337326',
            'decimals': 8,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x820bda1786afa19da6b92d6ac603574962337326/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DBOO',
            'name': 'Dark SpookyToken',
            'address': '0x81ea8770A6c85Fd57441FECD735e80156BAe2C09',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/DBOO.png',
            'coingeckoId': null,
            'listedIn': [
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ATRI',
            'name': 'Atari',
            'address': '0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
            'decimals': 0,
            'chainId': 250,
            'logoURI': 'https://ethapi.openocean.finance/logos/fantom/0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b.png',
            'coingeckoId': 'atari',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic'
            ]
        },
        {
            'symbol': 'SCR',
            'name': 'Secure',
            'address': '0x8183C18887aC4386CE09Dbdf5dF7c398DAcB2B5a',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/22414/thumb/sFtYBwVh_400x400.png?1641804282',
            'coingeckoId': 'secure',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'fCHF',
            'name': 'fCHF',
            'address': '0x81740D647493a61329E1c574A11ee7577659fb14',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/fchf.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'VOID',
            'name': 'VOID',
            'address': '0x80F2B8CdbC470c4DB4452Cc7e4a62F5277Db7061',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/27400/thumb/VOID200x200.png?1663830373',
            'coingeckoId': 'void-ad9a561a-8bca-4c17-9a3f-483f5cf20ac0',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '$NODAC',
            'name': 'Node Aggregator Capital',
            'address': '0x803e78269f7F013b7D13ba13243Be10C66418a70',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x803e78269f7f013b7d13ba13243be10c66418a70/logo.png',
            'coingeckoId': 'node-aggregator-capital',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'nICE',
            'name': 'NICE',
            'address': '0x7f620d7d0b3479b1655cEFB1B0Bc67fB0EF4E443',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/logos/main/network/fantom/0x7f620d7d0b3479b1655cEFB1B0Bc67fB0EF4E443.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'APP',
            'name': 'DAPPSY',
            'address': '0x7f352fA977713019C484C58E9BDfB04910016871',
            'decimals': 8,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'GRIM',
            'name': 'Grim',
            'address': '0x7eC94C4327dC757601B4273cD67014d7760Be97E',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/17866/thumb/83727875.png?1629691396',
            'coingeckoId': 'grimtoken',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'BLAST',
            'name': 'SafeBLAST',
            'address': '0x7d226bff737C5855254C307523afB3c464C5680b',
            'decimals': 9,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FXS',
            'name': 'Frax Share',
            'address': '0x7d016eec9c25232b01F23EF992D98ca97fc2AF5a',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1659415929777_9022797446774049.png',
            'coingeckoId': 'frax-share',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': '2OMB',
            'name': '2omb',
            'address': '0x7a6e4E3CC2ac9924605DCa4bA31d1831c84b44aE',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x7a6e4e3cc2ac9924605dca4ba31d1831c84b44ae.png',
            'coingeckoId': '2omb-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'DFYN',
            'name': 'Dfyn Token',
            'address': '0x7a4b1aBC1409C69c2ed71Ab34daE43E2Ff6f9928',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://raw.githubusercontent.com/dfyn/assets/main/DFYN_logo.png',
            'coingeckoId': null,
            'listedIn': [
                'dfyn'
            ]
        },
        {
            'symbol': 'TNODE',
            'name': 'Trusted Node',
            'address': '0x7FC5670B2041d34414B0b2178Fc660b1E1faF801',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/TNODE.png',
            'coingeckoId': 'trusted-node',
            'listedIn': [
                'coingecko',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'GRAPE',
            'name': 'Grape Finance',
            'address': '0x7Dd7eAf5872E260F35Dc60a54E0F25193128c765',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'NDFI',
            'name': 'Nodeify',
            'address': '0x7Dcd01E3a119C8592A5aEd681cDC9eD163090362',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/NDFI.png',
            'coingeckoId': 'nodeify',
            'listedIn': [
                'coingecko',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'EXZO',
            'name': 'ExzoCoin 2.0',
            'address': '0x7DB1B41e7a3a54b89272973189aEe6548DD9C199',
            'decimals': 9,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'NEXO',
            'name': 'NEXO',
            'address': '0x7C598c96D02398d89FbCb9d41Eab3DF0C16F227D',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/3695/thumb/nexo.png?1548086057',
            'coingeckoId': 'nexo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BHC',
            'name': 'BillionHappiness',
            'address': '0x7BEB05cf5681f402E762F8569c2Fc138a2172978',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://static.debank.com/image/ftm_token/logo_url/0x7beb05cf5681f402e762f8569c2fc138a2172978/0d9a82af09a54a392b3c7219f55b03ab.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SAFEBORG',
            'name': 'SafeBORG',
            'address': '0x7B5A8F4C92BA4796C932654ba1DAAA1E74C1a3B2',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://ethapi.openocean.finance/logos/fantom/0x7b5a8f4c92ba4796c932654ba1daaa1e74c1a3b2.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'xyETH',
            'name': 'xyETH',
            'address': '0x7AeB6c52392A53Ecedf4aE99B363Ca8B71341ad7',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
            'coingeckoId': null,
            'listedIn': [
                'xyfinance'
            ]
        },
        {
            'symbol': 'CREAM',
            'name': 'IceCream Finance',
            'address': '0x799F44d26e0c51205FE61E3fB0Fa6A097BBF933F',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'UNB',
            'name': 'Unbound',
            'address': '0x79637D860380bd28dF5a07329749654790FAc1Df',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'RDL',
            'name': 'Radial Finance',
            'address': '0x79360aF49edd44F3000303ae212671ac94bB8ba7',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/24166/thumb/F96xM49N_400x400.jpg?1646698990',
            'coingeckoId': 'radial-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNI',
            'name': 'UNI',
            'address': '0x79068bc529e109DC50b2BEdaEbD63DE6f986d900',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ZOO',
            'name': 'ZooDAO',
            'address': '0x77d97B782f2AeE174AAD81eb17583438F95BE5EA',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DUO',
            'name': 'DUO',
            'address': '0x779d5AECf90c724347B6A5a92083DaF57296459E',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x779d5aecf90c724347b6a5a92083daf57296459e/logo.png',
            'coingeckoId': 'duo-2',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'CREDIT',
            'name': 'Creditum',
            'address': '0x77128DFdD0ac859B33F44050c6fa272F34872B5E',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x77128dfdd0ac859b33f44050c6fa272f34872b5e.png',
            'coingeckoId': 'creditum',
            'listedIn': [
                'coingecko',
                '1inch',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'CYT',
            'name': 'Coinary Token',
            'address': '0x76ce14947f74A2664700e68452fe9166174AF9Ab',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'YEL',
            'name': 'YEL Token',
            'address': '0x76a3D96726C0ED756eA420d239d3Feb998EBf528',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'KNCL',
            'name': 'Kyber Network Crystal Legacy',
            'address': '0x765277EebeCA2e31912C9946eAe1021199B39C61',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/947/thumb/logo-kncl.png?1618984814',
            'coingeckoId': 'kyber-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BIOS',
            'name': '0x nodes',
            'address': '0x75e0eB8e6d92ab832bB11E46c041D06a89aC5F0D',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/15600/thumb/BIOS_01.png?1621737736',
            'coingeckoId': 'bios',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'FTMX',
            'name': 'Fantastic Protocol Peg FTM',
            'address': '0x75Ab56F4eFE81598a78EF3079a331F1D0336765D',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/24759/thumb/XFTM.png?1648790313',
            'coingeckoId': 'fantastic-protocol-peg-ftm',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'BADGER',
            'name': 'Badger DAO',
            'address': '0x753fbc5800a8C8e3Fb6DC6415810d627A387Dfc9',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://elk.finance/tokens/logos/ftm/0x753fbc5800a8C8e3Fb6DC6415810d627A387Dfc9/logo.png',
            'coingeckoId': 'badger-dao',
            'listedIn': [
                'coingecko',
                'SpookySwap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WETH',
            'name': 'WETH',
            'address': '0x74b23882a30290451A17c44f4F05243b6b58C76d',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2.png',
            'coingeckoId': 'weth',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'dfyn',
                'SpookySwap',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'TOR',
            'name': 'TOR',
            'address': '0x74E23dF9110Aa9eA0b6ff2fAEE01e740CA1c642e',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x74e23df9110aa9ea0b6ff2faee01e740ca1c642e.png',
            'coingeckoId': 'tor',
            'listedIn': [
                'coingecko',
                '1inch',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'XTAROT',
            'name': 'Staked TAROT',
            'address': '0x74D1D2A851e339B8cB953716445Be7E8aBdf92F4',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/xTAROT.png',
            'coingeckoId': 'staked-tarot',
            'listedIn': [
                'coingecko',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'DOA',
            'name': 'Doaibu',
            'address': '0x744C01bb3A9cB59392E7F0d515F6b3e92b11c2ef',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/24467/thumb/200x200_DOA_Original.png?1650436738',
            'coingeckoId': 'doaibu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BEFTM',
            'name': 'Beefy Escrowed Fantom',
            'address': '0x7381eD41F6dE418DdE5e84B55590422a57917886',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/beFTM.png',
            'coingeckoId': 'beefy-escrowed-fantom',
            'listedIn': [
                'coingecko',
                'openocean',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'TIME',
            'name': 'Time',
            'address': '0x71604Bc2766B8045a47B905FfEA2038F7cDD43E9',
            'decimals': 9,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'R4ID',
            'name': 'Raid Token',
            'address': '0x70f52878ca86c5f03d603CDDA414295EE410E8f3',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/R4ID.png',
            'coingeckoId': null,
            'listedIn': [
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'gALCX',
            'name': 'governanceALCX',
            'address': '0x70F9fd19f857411b089977E7916c05A0fc477Ac9',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/gALCX.png',
            'coingeckoId': null,
            'listedIn': [
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'EXC',
            'name': 'Excalibur',
            'address': '0x6e99e0676A90b2a5a722C44109db22220382cc9F',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1648802267911_9520579251605203.png',
            'coingeckoId': 'excalibur',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic'
            ]
        },
        {
            'symbol': 'DKNIGHT',
            'name': 'Dark Knight',
            'address': '0x6cc0E0AedbbD3C35283e38668D959F6eb3034856',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1648799461888_8624223703296714.png',
            'coingeckoId': 'darkknight',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic'
            ]
        },
        {
            'symbol': 'TOMB',
            'name': 'Tomb',
            'address': '0x6c021Ae822BEa943b2E66552bDe1D2696a53fbB7',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x6c021ae822bea943b2e66552bde1d2696a53fbb7.png',
            'coingeckoId': 'tomb',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'WATERFALL',
            'name': 'Waterfall Finance',
            'address': '0x6b2a7B82d3F7a6e1F5A5831aB40666Ec717645d5',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/22507/thumb/9pkqOOZq_400x400.jpg?1641958035',
            'coingeckoId': 'waterfall-finance',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'AUSDT',
            'name': 'Aave USDT',
            'address': '0x6ab707Aca953eDAeFBc4fD23bA73294241490620',
            'decimals': 6,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/14243/thumb/aUSDT.78f5faae.png?1615528400',
            'coingeckoId': 'aave-usdt',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'GSCARAB',
            'name': 'GScarab',
            'address': '0x6ab5660f0B1f174CFA84e9977c15645e4848F5D6',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/22101/thumb/gscarab-logo.bd366d92.png?1641567098',
            'coingeckoId': 'gscarab',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'CFI',
            'name': 'CyberFi',
            'address': '0x6a545f9c64d8f7B957D8D2e6410B52095A9E6c29',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/CFi.png',
            'coingeckoId': 'cyberfi',
            'listedIn': [
                'coingecko',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'WTRTL',
            'name': 'Wrapped TurtleCoin',
            'address': '0x6a31Aca4d2f7398F04d9B6ffae2D898d9A8e7938',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/wTRTL.png',
            'coingeckoId': 'wrapped-turtlecoin',
            'listedIn': [
                'coingecko',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'AAVE',
            'name': 'Aave',
            'address': '0x6a07A792ab2965C72a5B8088d3a069A7aC3a993B',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9.png',
            'coingeckoId': 'aave',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'USDB',
            'name': 'USD Balance',
            'address': '0x6Fc9383486c163fA48becdEC79d6058f984f62cA',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/24341/thumb/usdb.png?1647398082',
            'coingeckoId': 'usd-balance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ENERGY',
            'name': 'ENERGY Token',
            'address': '0x6F65b9ee6cafa95b641488f3188f937Fc1a4772A',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x6f65b9ee6cafa95b641488f3188f937fc1a4772a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DSHARE',
            'name': 'DShares',
            'address': '0x6E209329A33a63C463dbb65AE2d6655Fe5C98411',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/24149/thumb/dshare.png?1646563774',
            'coingeckoId': 'dshares',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'MENSA',
            'name': 'Mensa',
            'address': '0x6B77d8B9be440FcbfE72CB4104d98bBd7cF91B14',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://ethapi.openocean.finance/logos/fantom/0x6b77d8b9be440fcbfe72cb4104d98bbd7cf91b14.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'sFTM',
            'name': 'Staked FTM',
            'address': '0x69c744D3444202d35a2783929a0F930f2FBB05ad',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://elk.finance/tokens/logos/ftm/0x69c744D3444202d35a2783929a0F930f2FBB05ad/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SNT',
            'name': 'Supernova',
            'address': '0x69D17C151EF62421ec338a0c92ca1c1202A427EC',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x744d70fdbe2ba4cf95131626614a1763df805b9e.png',
            'coingeckoId': 'supernova',
            'listedIn': [
                'coingecko',
                '1inch',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'HTZ',
            'name': 'Hertz Token',
            'address': '0x68F7880F7af43a81bEf25E2aE83802Eb6c2DdBFD',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'GEM',
            'name': 'Mine Empire',
            'address': '0x68EFc4716507709691d5e7AD9906a44FaBCdb1CA',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/26516/thumb/gem-256.png?1658451109',
            'coingeckoId': 'mine-empire',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'QI',
            'name': 'Qi Dao Protocol',
            'address': '0x68Aa691a8819B07988B18923F712F3f4C8d36346',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://elk.finance/tokens/logos/ftm/0x68Aa691a8819B07988B18923F712F3f4C8d36346/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'STG',
            'name': 'StargateToken',
            'address': '0x6694340fc020c5E6B96567843da2df01b2CE1eb6',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/logos/main/network/arbitrum/0x6694340fc020c5E6B96567843da2df01b2CE1eb6.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'ONI',
            'name': 'ONINO',
            'address': '0x667c856f1A624BAeFE89fC4909C8701296C86c98',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/ONI.png',
            'coingeckoId': 'oni-token',
            'listedIn': [
                'coingecko',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'NIPS',
            'name': 'NIPS',
            'address': '0x667Afbb7D558c3dFd20fAbd295D31221Dab9dBC2',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://ethapi.openocean.finance/logos/fantom/0x667afbb7d558c3dfd20fabd295d31221dab9dbc2.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'LUX',
            'name': 'Luxor',
            'address': '0x6671E20b83Ba463F270c8c75dAe57e3Cc246cB2b',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/22229/thumb/2kldLo1i_400x400.jpg?1641246287',
            'coingeckoId': 'luxor',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ACRV',
            'name': 'Aladdin cvxCRV',
            'address': '0x666a3776b3e82F171CB1dFF7428B6808D2Cd7d02',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/25395/thumb/Sv06cFHS_400x400.jpg?1651707422',
            'coingeckoId': 'aladdin-cvxcrv',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'WOO',
            'name': 'WOO Network',
            'address': '0x6626c47c00F1D87902fc13EECfaC3ed06D5E8D8a',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x1b815d120b3ef02039ee11dc2d33de7aa4a8c603.png',
            'coingeckoId': 'woo-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'SHIB',
            'name': 'SHIBA INU',
            'address': '0x65e66a61D0a8F1e686C2D6083ad611a10D84D97A',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x65e66a61d0a8f1e686c2d6083ad611a10d84d97a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'TETU',
            'name': 'TETU',
            'address': '0x65c9d9d080714cDa7b5d58989Dc27f897F165179',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/17882/thumb/gradient_icon_926.png?1629707907',
            'coingeckoId': 'tetu',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'KAE',
            'name': 'Kanpeki',
            'address': '0x65Def5029A0e7591e46B38742bFEdd1Fb7b24436',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x65def5029a0e7591e46b38742bfedd1fb7b24436.png',
            'coingeckoId': 'kanpeki',
            'listedIn': [
                'coingecko',
                '1inch',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'fETH',
            'name': 'fETH',
            'address': '0x658b0c7613e890EE50B8C4BC6A3f41ef411208aD',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/eth.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'CREAM',
            'name': 'Cream',
            'address': '0x657A1861c15A3deD9AF0B6799a195a249ebdCbc6',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://elk.finance/tokens/logos/ftm/0x657A1861c15A3deD9AF0B6799a195a249ebdCbc6/logo.png',
            'coingeckoId': 'cream-2',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MODA',
            'name': 'MODA DAO',
            'address': '0x6496994241804D7fE2b032901931e03bCD82301F',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/MODA.png',
            'coingeckoId': 'moda-dao',
            'listedIn': [
                'coingecko',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': '3SHARE',
            'name': '3Share',
            'address': '0x6437ADAC543583C4b31Bf0323A0870430F5CC2e7',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/3HARES.png',
            'coingeckoId': '3shares',
            'listedIn': [
                'coingecko',
                'openocean',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'OCTO',
            'name': 'OctoFi',
            'address': '0x639A647fbe20b6c8ac19E48E2de44ea792c62c5C',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/12594/thumb/octofi-256x256-radius-22percent.png?1610679969',
            'coingeckoId': 'octofi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CPshare',
            'name': 'CP Shares',
            'address': '0x6338d92D24e94d3A79aBC57aCB3783288Af89495',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x6338d92d24e94d3a79abc57acb3783288af89495/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'KEK',
            'name': 'Cryptokek',
            'address': '0x627524d78B4fC840C887ffeC90563c7A42b671fD',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x627524d78b4fc840c887ffec90563c7a42b671fd.png',
            'coingeckoId': 'cryptokek',
            'listedIn': [
                'coingecko',
                '1inch',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'AUSDC',
            'name': 'Aave USDC',
            'address': '0x625E7708f30cA75bfd92586e17077590C60eb4cD',
            'decimals': 6,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/14318/thumb/aUSDC.e260d492.png?1615527797',
            'coingeckoId': 'aave-usdc',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'GAMI',
            'name': 'Gami Token',
            'address': '0x623332A33Ae3a8fC9B121322C72550Decc0eD81C',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FFS',
            'name': 'Fantom Frens',
            'address': '0x616e302F89d1b21a2e3EC2AB8DaC01E01CBBadfB',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x616e302f89d1b21a2e3ec2ab8dac01e01cbbadfb/logo.png',
            'coingeckoId': 'fantom-frens',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'OOZE',
            'name': 'Ooze',
            'address': '0x60e6AFEb3ac2fBc82A8d312BEa3B47DC6b4848d2',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/27660/thumb/ooze.coin.200.png?1665101471',
            'coingeckoId': 'ooze',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SERENE',
            'name': 'Serene',
            'address': '0x60d8D17D6b824E19f77eACcAf16ED7BA6FB209C2',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/26658/thumb/Asset_1.png?1659414691',
            'coingeckoId': 'serene',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'DUCK',
            'name': 'Unit Protocol',
            'address': '0x602a3AD311e66B6F5e567a13016B712ABa0625C6',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/13359/thumb/6f38719f-fe83-44ff-af30-7965fd23ac06.png?1660612730',
            'coingeckoId': 'unit-protocol-duck',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'SPACE',
            'name': 'Space Token',
            'address': '0x5f7F94a1dd7b15594d17543BEB8B30b111DD464c',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://static.debank.com/image/ftm_token/logo_url/0x5f7f94a1dd7b15594d17543beb8b30b111dd464c/934201c14d045663ba04b55929590888.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'L3USD',
            'name': 'L3USD',
            'address': '0x5f0456F728E2D59028b4f5B8Ad8C604100724C6A',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/26937/thumb/L3USD.png?1660999834',
            'coingeckoId': 'l3usd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XIM',
            'name': 'xDollar Interverse Money',
            'address': '0x5d85dd95bccC40b8E24d10808B5BCC5ACcF36597',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FEED',
            'name': 'Feeder Finance',
            'address': '0x5d5530eb3147152FE78d5C4bFEeDe054c8d1442A',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/15151/thumb/feeder_finance_logo.png?1619968857',
            'coingeckoId': 'feeder-finance',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'JEFE',
            'name': 'Jefe',
            'address': '0x5b2AF7fd27E2Ea14945c82Dd254c79d3eD34685e',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/25336/thumb/JEFE_200.png?1651299561',
            'coingeckoId': 'jefe',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CGS',
            'name': 'Cougar Token',
            'address': '0x5a2e451Fb1b46FDE7718315661013ae1aE68e28C',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/CGS.png',
            'coingeckoId': null,
            'listedIn': [
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'MFTU',
            'name': 'Mainstream For The Underground',
            'address': '0x5Fdf0b736b0Eb0564B50Baf0D15a1A8ae6C6C1D9',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'creditp',
            'name': 'Credit Printer',
            'address': '0x5E5AabC95380B38C014FDc5e602C5183177fcCa4',
            'decimals': 6,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x5e5aabc95380b38c014fdc5e602c5183177fcca4/logo.png',
            'coingeckoId': 'credit-printer',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ARCHA',
            'name': 'ArchAngel',
            'address': '0x5E2e2d3Ee4944d0E6c0b663625859CF8cC45ca88',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/18814/thumb/ARCHA200x200.png?1633714225',
            'coingeckoId': 'archangel-token',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'xPREMIA',
            'name': 'Staked Premia',
            'address': '0x5Dd3411c533AB5a1D416E47756E261bd733dc103',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FRANK',
            'name': 'Dark Opera Share',
            'address': '0x5Cf2a4184707D5177D9A838acfe3999EE79d3f49',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/FRANK.png',
            'coingeckoId': null,
            'listedIn': [
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SPIRIT',
            'name': 'SpiritSwap',
            'address': '0x5Cc61A78F164885776AA610fb0FE1257df78E59B',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x5cc61a78f164885776aa610fb0fe1257df78e59b.png',
            'coingeckoId': 'spiritswap',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HEC',
            'name': 'Hector Network',
            'address': '0x5C4FDfc5233f935f20D2aDbA572F770c2E377Ab0',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x5c4fdfc5233f935f20d2adba572f770c2e377ab0.png',
            'coingeckoId': 'hector-dao',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'DCT',
            'name': 'Debt_Consolidation',
            'address': '0x5BD481174725139bCc8124dCdB1E0b2F81D7539d',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ASNT',
            'name': 'Assent Protocol',
            'address': '0x5B3C1F260E09e653290f24F75abC5e466fD42310',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/24959/thumb/asnt.png?1649519753',
            'coingeckoId': 'assent-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSGG',
            'name': 'Betswap gg',
            'address': '0x5A33869045db8A6a16c9f351293501CFD92cf7ed',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/17169.png',
            'coingeckoId': 'betswap-gg',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'RouterProtocol',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'RICH',
            'name': 'Rich DAO',
            'address': '0x5A272C478609F448C566c7155577f6432fdd8B79',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/23318/thumb/tokenlogo_%282%29.png?1643757238',
            'coingeckoId': 'rich-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WBOND',
            'name': 'War Bond',
            'address': '0x59c6606ED2AF925F270733e378D6aF7829B5b3cf',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x59c6606ed2af925f270733e378d6af7829b5b3cf.png',
            'coingeckoId': 'war-bond',
            'listedIn': [
                'coingecko',
                '1inch',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SUPA',
            'name': 'SUPA Foundation',
            'address': '0x59D07a115fe3FFe5db3D52029D43Cc0ef5e9ba08',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x59d07a115fe3ffe5db3d52029d43cc0ef5e9ba08.png',
            'coingeckoId': 'supa-foundation',
            'listedIn': [
                'coingecko',
                '1inch',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'DUAH',
            'name': 'Dark Ukrainian Hryvnia',
            'address': '0x593Decf7D214FA902321639e785e88aa4E9150AC',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/DUAH.png',
            'coingeckoId': null,
            'listedIn': [
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'COFFIN',
            'name': 'Coffin Finance',
            'address': '0x593Ab53baFfaF1E821845cf7080428366F030a9c',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x593ab53baffaf1e821845cf7080428366f030a9c.png',
            'coingeckoId': 'coffin-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'LUNA(Wormhole)',
            'name': 'LUNA',
            'address': '0x593AE1d34c8BD7587C11D539E4F42BFf242c82Af',
            'decimals': 6,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/LUNAWORMHOLE.png',
            'coingeckoId': null,
            'listedIn': [
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BAL',
            'name': 'Balancer',
            'address': '0x58a547Ed09684ac2A688610d5Caf8e8968b51908',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ICR',
            'name': 'InterCrone',
            'address': '0x58759Dd469ae5631C42cf8a473992335575b58D7',
            'decimals': 8,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'RING',
            'name': 'OneRing',
            'address': '0x582423C10c9e83387a96d00A69bA3D11ee47B7b5',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x9469d013805bffb7d3debe5e7839237e535ec483.png',
            'coingeckoId': 'onering',
            'listedIn': [
                'coingecko',
                '1inch',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'MVDOLLAR',
            'name': 'MiniVerse Dollar',
            'address': '0x57976c467608983513c9355238dc6de1B1aBbcCA',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/MvDOLLAR.png',
            'coingeckoId': 'miniverse-dollar',
            'listedIn': [
                'coingecko',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SNX',
            'name': 'Synthetix Network',
            'address': '0x56ee926bD8c72B2d5fa1aF4d9E4Cbb515a1E3Adc',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://elk.finance/tokens/logos/ftm/0x56ee926bD8c72B2d5fa1aF4d9E4Cbb515a1E3Adc/logo.png',
            'coingeckoId': 'havven',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SPA',
            'name': 'Spartacus',
            'address': '0x5602df4A94eB6C680190ACCFA2A475621E0ddBdc',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x5602df4a94eb6c680190accfa2a475621e0ddbdc.png',
            'coingeckoId': 'spartacus',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'UNDEAD',
            'name': 'Undead Finance',
            'address': '0x551C61DB482289994e7d426Fc4DB6493918bB81D',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/19548/thumb/200x_200.png?1635399715',
            'coingeckoId': 'undead-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ECHO',
            'name': 'Echo',
            'address': '0x54477A1D1bb8C1139eEF754Fd2eFd4DDeE7933dd',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://ethapi.openocean.finance/logos/fantom/0x54477a1d1bb8c1139eef754fd2efd4ddee7933dd.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'PROPEL',
            'name': 'Propel',
            'address': '0x5444C30210d8A0A156178CFb8048b4137c0d40d1',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ABR',
            'name': 'Allbridge',
            'address': '0x543Acd673960041eEe1305500893260F1887B679',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/18690/thumb/abr.png?1640742053',
            'coingeckoId': 'allbridge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SING',
            'name': 'Sing FTM',
            'address': '0x53D831e1db0947c74e8a52618f662209ec5dE0cE',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://elk.finance/tokens/logos/ftm/0x53D831e1db0947c74e8a52618f662209ec5dE0cE/logo.png',
            'coingeckoId': 'sing-token-ftm',
            'listedIn': [
                'coingecko',
                'rubic',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ACRV',
            'name': 'Aave CRV',
            'address': '0x513c7E3a9c69cA3e22550eF58AC1C0088e918FFf',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/17250/thumb/aCRV_2x.png?1626941642',
            'coingeckoId': 'aave-crv',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'WAVAX',
            'name': 'Wrapped AVAX',
            'address': '0x511D35c52a3C244E7b8bd92c0C297755FbD89212',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7.png',
            'coingeckoId': 'wrapped-avax',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'FSN',
            'name': 'FUSION',
            'address': '0x50EB82CC284E3D35936827023B048106aAEcfc5F',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/2515/thumb/Fusion_200x200.png?1639629907',
            'coingeckoId': 'fsn',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'PORTX',
            'name': 'ChainPort',
            'address': '0x504eC4f9AF7bBf8cAD73ccC2121A3A7FB4c81Bcf',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/24490/thumb/VE-tUL-q_400x400.png?1647855096',
            'coingeckoId': 'chainport',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KALM',
            'name': 'KALM',
            'address': '0x4f851750a3e6f80f1E1f89C67B56960Bfc29A934',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/15849/thumb/kalmar.png?1660289892',
            'coingeckoId': 'kalmar',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'TSHARE',
            'name': 'Tomb Shares',
            'address': '0x4cdF39285D7Ca8eB3f090fDA0C069ba5F4145B37',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x4cdf39285d7ca8eb3f090fda0c069ba5f4145b37.png',
            'coingeckoId': 'tomb-shares',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'POS',
            'name': 'Poseidon',
            'address': '0x4ae3Fa715E21f328c2B61c037B9CC1761B6aF0bD',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/POS.png',
            'coingeckoId': null,
            'listedIn': [
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'FATCAKE',
            'name': 'Fatcake Token',
            'address': '0x4a3DCE89cA816D4F10864664B6503df95a92d879',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x4a3dce89ca816d4f10864664b6503df95a92d879/logo.png',
            'coingeckoId': 'fantom-cake',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SPIRIT-LP',
            'name': 'Spirit LPs',
            'address': '0x4Fe6f19031239F105F753D1DF8A0d24857D0cAA2',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x4fe6f19031239f105f753d1df8a0d24857d0caa2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BORGDAI',
            'name': 'BORGDAI',
            'address': '0x4F16b83e55b92E6F8F7Da6D78Dd19d5a00954864',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://ethapi.openocean.finance/logos/fantom/0x4f16b83e55b92e6f8f7da6d78dd19d5a00954864.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'scFRAX',
            'name': 'Scream Wrapped FRAX',
            'address': '0x4E6854EA84884330207fB557D1555961D85Fc17E',
            'decimals': 8,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x4e6854ea84884330207fb557d1555961d85fc17e/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MESO',
            'name': 'Meso',
            'address': '0x4D9361A86D038C8adA3db2457608e2275B3E08d4',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/18782/thumb/sEYpoSe.png?1633411723',
            'coingeckoId': 'meso',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'gALCX',
            'name': 'governanceALCX',
            'address': '0x4CbA8902ce48AB1d5eEa1920D65faeDB934B9916',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'VOLTa',
            'name': 'Volta',
            'address': '0x4C9993C7107495020c2Ce9A13d11839F48EcD2E6',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/VOLTa.png',
            'coingeckoId': null,
            'listedIn': [
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BELUGA',
            'name': 'Beluga fi',
            'address': '0x4A13a2cf881f5378DEF61E430139Ed26d843Df9A',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/13790/thumb/brand-logo-v2.23e5d279.png?1624906164',
            'coingeckoId': 'beluga-fi',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'RNDM',
            'name': 'Random',
            'address': '0x49aC072c793FB9523f0688A0d863AAdfbFb5d475',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://ethapi.openocean.finance/logos/fantom/0x49ac072c793fb9523f0688a0d863aadfbfb5d475.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'BSHARE',
            'name': 'BASED Shares',
            'address': '0x49C290Ff692149A4E16611c694fdED42C954ab7a',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x531780face85306877d7e1f05d713d1b50a37f7a.png',
            'coingeckoId': 'based-shares',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'FANG',
            'name': 'FANG',
            'address': '0x49894fCC07233957c35462cfC3418Ef0CC26129f',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x49894fcc07233957c35462cfc3418ef0cc26129f.png',
            'coingeckoId': 'fang-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'RABBIT',
            'name': 'Rabbit Coin',
            'address': '0x48844dDBa89799DC40eC31728dAC629802D407f3',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TREAT',
            'name': 'Treat',
            'address': '0x484f2ff94a7790759D56Fb1eFbAce8075aBA5e06',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/19794/thumb/XSzNcWZ.png?1635907149',
            'coingeckoId': 'treat',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTD',
            'name': 'Bat True Dollar',
            'address': '0x4792C1EcB969B036eb51330c63bD27899A13D84e',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x4792c1ecb969b036eb51330c63bd27899a13d84e/logo.png',
            'coingeckoId': 'bolt-true-dollar',
            'listedIn': [
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'TRAVA',
            'name': 'Trava Finance',
            'address': '0x477a9D5dF9bedA06F6b021136a2efe7BE242fCC9',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/TRAVA.png',
            'coingeckoId': 'trava-finance',
            'listedIn': [
                'coingecko',
                'dfyn',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BABYBOO',
            'name': 'BabyBoo',
            'address': '0x471762A7017A5B1A3e931F1A97aa03Ef1E7F4A73',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/BABYBOO.png',
            'coingeckoId': 'babyboo',
            'listedIn': [
                'coingecko',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'SCARE',
            'name': 'ScareCrow',
            'address': '0x46e1Ee17f51c52661D04238F1700C547dE3B84A1',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1635757463555_5294638395195801.png',
            'coingeckoId': 'scarecrow',
            'listedIn': [
                'coingecko',
                'openocean'
            ]
        },
        {
            'symbol': 'BAND',
            'name': 'Band Protocol',
            'address': '0x46E7628E8b4350b2716ab470eE0bA1fa9e76c6C5',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://elk.finance/tokens/logos/ftm/0x46E7628E8b4350b2716ab470eE0bA1fa9e76c6C5/logo.png',
            'coingeckoId': 'band-protocol',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'SpookySwap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SPELL',
            'name': 'Spell',
            'address': '0x468003B688943977e6130F4F68F23aad939a1040',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0xce1bffbd5374dac86a2893119683f4911a2f7814.png',
            'coingeckoId': 'spell-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'SHACK',
            'name': 'Shack',
            'address': '0x462Fa81050f0fF732D59df121BFd9b8Bab072018',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/25699/thumb/shack_no_bg_no_pad3.png?1653441433',
            'coingeckoId': 'shack',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'VSP',
            'name': 'Vesper Finance',
            'address': '0x461d52769884ca6235B685EF2040F47d30C94EB5',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/13527/thumb/vesper_logo.jpg?1609399927',
            'coingeckoId': 'vesper-finance',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'scWBTC',
            'name': 'Scream Wrapped WBTC',
            'address': '0x4565DC3Ef685E4775cdF920129111DdF43B9d882',
            'decimals': 8,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x4565dc3ef685e4775cdf920129111ddf43b9d882/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BINSPIRIT',
            'name': 'binSPIRIT',
            'address': '0x44e314190D9E4cE6d4C0903459204F8E21ff940A',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/23392/thumb/binspirit.png?1644115996',
            'coingeckoId': 'binspirit',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'SOL',
            'name': 'Wrapped Solana',
            'address': '0x44F7237df00E386af8e79B817D05ED9f6FE0f296',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/21629/thumb/solana.jpg?1639626543',
            'coingeckoId': 'wrapped-solana',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HEGIC',
            'name': 'Hegic',
            'address': '0x44B26E839eB3572c5E959F994804A5De66600349',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/hegic.jpg',
            'coingeckoId': 'hegic',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'PRAGMA',
            'name': 'Pragma',
            'address': '0x449F45DEB9C57350130732733bD96AaD7203342a',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x449f45deb9c57350130732733bd96aad7203342a/logo.png',
            'coingeckoId': 'pragma',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'XY',
            'name': 'XY Finance',
            'address': '0x444444443B0fcB2733b93F23C910580FBa52FFFA',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/21541/small/xy.png?1639913622',
            'coingeckoId': 'xy-finance',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'RENA',
            'name': 'Rena',
            'address': '0x432CEF16E244C595f8B0513251F6A604Fac7688a',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'METTI',
            'name': 'Metti Inu',
            'address': '0x42aE8468A1FDDB965d420BD71368a87Ec3a2B4b8',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/Metti.png',
            'coingeckoId': 'metti-inu',
            'listedIn': [
                'coingecko',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'PPDEX',
            'name': 'Pepedex',
            'address': '0x4272dD51961A5181AcE0DC7EB6f9807311345559',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/13022/thumb/output-onlinepngtools-1.png?1604720841',
            'coingeckoId': 'pepedex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AST',
            'name': 'The Ant',
            'address': '0x425c5424E08c06D8E4640725Ce62Ec81d2cea516',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x425c5424e08c06d8e4640725ce62ec81d2cea516/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'EVO',
            'name': 'EVO',
            'address': '0x42006Ab57701251B580bDFc24778C43c9ff589A1',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/25088/large/evoToken.png?1650269135',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'RouterProtocol'
            ]
        },
        {
            'symbol': 'aUSD',
            'name': 'aUSD',
            'address': '0x41e3dF7f716aB5AF28c1497B354D79342923196a',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x41e3df7f716ab5af28c1497b354d79342923196a/logo.png',
            'coingeckoId': 'ausd',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SOLIDSEX',
            'name': 'SOLIDsex  Tokenized veSOLID',
            'address': '0x41adAc6C1Ff52C5e27568f27998d747F7b69795B',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/23992/thumb/solidSEX.png?1645951074',
            'coingeckoId': 'solidsex-tokenized-vesolid',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'SD',
            'name': 'Stader',
            'address': '0x412a13C109aC30f0dB80AD3Bd1DeFd5D0A6c0Ac6',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/SDWORMHOLE.png',
            'coingeckoId': 'stader',
            'listedIn': [
                'coingecko',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'WMATIC',
            'name': 'Wrapped Matic',
            'address': '0x40DF1Ae6074C35047BFF66675488Aa2f9f6384F3',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0.png',
            'coingeckoId': 'wmatic',
            'listedIn': [
                'coingecko',
                '1inch',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'WINE',
            'name': 'Wine Shares',
            'address': '0x3fF1Af9E583d3790ec51E1407c9E94D8D1133dC3',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BLOOD',
            'name': 'Blood',
            'address': '0x3f97C4c3C907999c8309a0752362e541398198E0',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x3f97c4c3c907999c8309a0752362e541398198e0/logo.png',
            'coingeckoId': 'blood-token',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'YOSHI',
            'name': 'Yoshi exchange',
            'address': '0x3dc57B391262e3aAe37a08D91241f9bA9d58b570',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x3dc57b391262e3aae37a08d91241f9ba9d58b570.png',
            'coingeckoId': 'yoshi-exchange',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'EXOD',
            'name': 'Exodia',
            'address': '0x3b57f3FeAaF1e8254ec680275Ee6E7727C7413c7',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1635756429071_26521933029323175.jpeg',
            'coingeckoId': 'exodia',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic'
            ]
        },
        {
            'symbol': 'OKSE',
            'name': 'Okse',
            'address': '0x3b53D2C7B44d40BE05Fa5E2309FFeB6eB2492d88',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/27185/thumb/Icon_Okse_copy.png?1662432854',
            'coingeckoId': 'okse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EQUAL',
            'name': 'Equalizer DEX',
            'address': '0x3Fd3A0c85B70754eFc07aC9Ac0cbBDCe664865A6',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/28231/thumb/hq_png_icon_file.png?1668572967',
            'coingeckoId': 'equalizer-dex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORBS',
            'name': 'Orbs',
            'address': '0x3E01B7E242D5AF8064cB9A8F9468aC0f8683617c',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/ORBS.png',
            'coingeckoId': null,
            'listedIn': [
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'fWINGS',
            'name': 'JetSwap Fantom Token',
            'address': '0x3D8f1ACCEe8e263F837138829B6C4517473d0688',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1637633178090_7782875328762422.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'PLAZA',
            'name': 'Plaza Finance (Omega)',
            'address': '0x3C146ff186e438f60EF63E3Da101984a72DB9f1C',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x3c146ff186e438f60ef63e3da101984a72db9f1c/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'fJPY',
            'name': 'fJPY',
            'address': '0x3B74389bc23057325BaB96523DBec8c445F55799',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/fjpy.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SHADE',
            'name': 'Shade Cash',
            'address': '0x3A3841f5fa9f2c283EA567d5Aeea3Af022dD2262',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/SHADE.png',
            'coingeckoId': 'shade-cash',
            'listedIn': [
                'coingecko',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'GFTM',
            'name': 'Geist FTM',
            'address': '0x39B3bd37208CBaDE74D0fcBDBb12D606295b430a',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/18991/thumb/preview-gallery-gFTMgecko_%281%29.png?1634790810',
            'coingeckoId': 'geist-ftm',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'GWBTC',
            'name': 'Geist WBTC',
            'address': '0x38aCa5484B8603373Acc6961Ecd57a6a594510A3',
            'decimals': 8,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/18999/thumb/preview-gallery-gWBTCgecko_%281%29.png?1634790748',
            'coingeckoId': 'geist-wbtc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALM',
            'name': 'Alium Finance',
            'address': '0x38540b4613d2e57ECf190D3486Ae6f74591eb8A9',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/15621/thumb/alium.png?1655449806',
            'coingeckoId': 'alium-finance',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'JUST',
            'name': 'JustYours',
            'address': '0x37C045bE4641328DFEB625f1Dde610D061613497',
            'decimals': 10,
            'chainId': 250,
            'logoURI': 'https://ethapi.openocean.finance/logos/fantom/0x37c045be4641328dfeb625f1dde610d061613497.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'DRACO',
            'name': 'DRACO',
            'address': '0x37863ea4bf6ef836bC8bE909221BAF09A2aF43d7',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x37863ea4bf6ef836bc8be909221baf09a2af43d7/logo.png',
            'coingeckoId': 'draco-finance',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SMART',
            'name': 'Smart',
            'address': '0x34D33dc8Ac6f1650D94A7E9A972B47044217600b',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x34d33dc8ac6f1650d94a7e9a972b47044217600b.png',
            'coingeckoId': 'smart-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'MVC',
            'name': 'Multiverse Capital (MVC.finance)',
            'address': '0x34324E1598Bf02cCD3deA93f4E332b5507097473',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'GIZA',
            'name': 'GizaDao',
            'address': '0x3389492f36642f27F7bF4a7749fb3FC2c8fbB7EE',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/21144/thumb/_dLPsk9__400x400.jpg?1638396595',
            'coingeckoId': 'gizadao',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'LIQR',
            'name': 'Topshelf Finance',
            'address': '0x33333ee26a7d02e41c33828B42Fb1E0889143477',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x33333ee26a7d02e41c33828b42fb1e0889143477.png',
            'coingeckoId': 'topshelf-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'FRY',
            'name': 'FoundryDAO Logistics',
            'address': '0x332730a4F6E03D9C55829435f10360E13cfA41Ff',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/12348/thumb/fry-icon.png?1614064283',
            'coingeckoId': 'foundrydao-logistics',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'AUR',
            'name': 'Aurum',
            'address': '0x32f1200bFb876FBB8f6e6a2Dea8f07F279146422',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MP',
            'name': 'MiniPanther',
            'address': '0x3264810174f577F82DDD4FD08818F368AC363505',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/23671/thumb/G7TivHP.png?1644994098',
            'coingeckoId': 'minipanther',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DFI',
            'name': 'DiversiFi',
            'address': '0x323b05C10029AE66b46bAF0604739331edb4808F',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/22637/thumb/17360.png?1642379172',
            'coingeckoId': 'diversifi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BETS',
            'name': 'BetSwirl Token',
            'address': '0x323D0dba53ee9A6F0fAA464FB099ec562b591fC7',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WBTC',
            'name': 'Wrapped Bitcoin',
            'address': '0x321162Cd933E2Be498Cd2267a90534A804051b11',
            'decimals': 8,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x2260fac5e5542a773aa44fbcfedf7c193bc2c599.png',
            'coingeckoId': 'wrapped-bitcoin',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'dfyn',
                'SpookySwap',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'TOTEM',
            'name': 'Totem Finance',
            'address': '0x31a37aedC0C18AA139e120e1CDC673BbB2063e6f',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://ethapi.openocean.finance/logos/fantom/0x31a37aedc0c18aa139e120e1cdc673bbb2063e6f.png',
            'coingeckoId': 'totem-finance',
            'listedIn': [
                'coingecko',
                'openocean'
            ]
        },
        {
            'symbol': 'POOCH',
            'name': 'Pooch',
            'address': '0x31A47B49b4DBDC54d403b8c4880Ac9BB1A9EbAE8',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/fantom/assets/0x31A47B49b4DBDC54d403b8c4880Ac9BB1A9EbAE8/logo.png',
            'coingeckoId': 'pooch',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'DOLA',
            'name': 'Dola',
            'address': '0x3129662808bEC728a27Ab6a6b9AFd3cBacA8A43c',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/DOLA.png',
            'coingeckoId': 'dola-usd',
            'listedIn': [
                'coingecko',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'GTFX',
            'name': 'Gitshock Finance',
            'address': '0x306e974624511E3937F37E551C5736F1b2aD21eb',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/28033/thumb/Logo_Gitshock_HD.png?1667117754',
            'coingeckoId': 'gitshock-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PREMIA',
            'name': 'Premia',
            'address': '0x3028b4395F98777123C7da327010c40f3c7Cc4Ef',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/13962/thumb/apple-touch-icon.png?1623679482',
            'coingeckoId': 'premia',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'wsHUMP',
            'name': 'Wrapped sHUMP',
            'address': '0x301DE15579C37d1e1cC8B46D74324b885A77291B',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SCARAB',
            'name': 'Scarab Finance',
            'address': '0x2e79205648B85485731CFE3025d66cF2d3B059c4',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/22033/thumb/scarab-logo.d6fea1b1.png?1640659016',
            'coingeckoId': 'scarab-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APE',
            'name': 'ApeCoin',
            'address': '0x2d72A97a31Dc920dB03330780d30074626e39C8A',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/APE.png',
            'coingeckoId': null,
            'listedIn': [
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'PONY',
            'name': 'PONY Index',
            'address': '0x2c2c1CD892cFE2368a0070b7aE28740e954B0920',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'fKRW',
            'name': 'fKRW',
            'address': '0x2b9C073Ec670b70F417bbaf3BbB052AA563A0a23',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/fkrw.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'CRE8R',
            'name': 'CRE8R DAO',
            'address': '0x2aD402655243203fcfa7dCB62F8A08cc2BA88ae0',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/22568/thumb/cropped-FINALwithbrick-2-1-1.png?1642060468',
            'coingeckoId': 'cre8r-dao',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'STG',
            'name': 'Stargate Finance',
            'address': '0x2F6F07CDcf3588944Bf4C42aC74ff24bF56e7590',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x2f6f07cdcf3588944bf4c42ac74ff24bf56e7590.png',
            'coingeckoId': 'stargate-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'SpookySwap',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'ZLOT',
            'name': 'zLOT',
            'address': '0x2F60C28fB2fDc90a2a5644442D0F6d8998101e76',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/13073/thumb/z-LOT-logo-transparent.png?1604900416',
            'coingeckoId': 'zlot',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CEL',
            'name': 'Celsius Network',
            'address': '0x2C78f1b70Ccf63CDEe49F9233e9fAa99D43AA07e',
            'decimals': 4,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/3263/thumb/CEL_logo.png?1609598753',
            'coingeckoId': 'celsius-degree-token',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'ZSP',
            'name': 'Zest Synthetic Protocol',
            'address': '0x2C26617034C840C9412CD67aE0Fc68A6755D00BF',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/26258/thumb/Zest_Logo_Logomark.png?1656941237',
            'coingeckoId': 'zest-synthetic-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KP3R',
            'name': 'Keep3rV1',
            'address': '0x2A5062D22adCFaAfbd5C541d4dA82E4B450d4212',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/kp3r.jpg',
            'coingeckoId': 'keep3rv1',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'YFI',
            'name': 'yearn finance',
            'address': '0x29b0Da86e484E1C0029B56e817912d778aC0EC69',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e.png',
            'coingeckoId': 'yearn-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DARK',
            'name': 'Dark Fantom',
            'address': '0x28d81863438F25b6EC4c9DA28348445FC5E44196',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/DARK.png',
            'coingeckoId': null,
            'listedIn': [
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SPECT',
            'name': 'SpectToken',
            'address': '0x2834d4F4cC40bd7D78c07E2D848358364222C272',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x2834d4f4cc40bd7d78c07e2d848358364222c272/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BNBBACK',
            'name': 'BNBBack',
            'address': '0x280EbB7c9F2C90Ac6dD136516598a2f9efe70507',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/23890/thumb/MxdOnJKe_400x400.jpg?1645606314',
            'coingeckoId': 'bnbback',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'fBNB',
            'name': 'fBNB',
            'address': '0x27f26F00e1605903645BbaBC0a73E35027Dccd45',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/bnb.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'GEM',
            'name': 'GEM',
            'address': '0x27de370Fe0498307E91047C9a60051d53b92114a',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DAI+USDC',
            'name': 'Curve fi DAI USDC',
            'address': '0x27E611FD27b276ACbd5Ffd632E5eAEBEC9761E40',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/26753/thumb/W1sQNVWo_400x400.jpeg?1660293542',
            'coingeckoId': 'curve-fi-dai-usdc',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'mooBooUSDC-MAI',
            'name': 'Moo Boo USDC-MAI',
            'address': '0x278E3E675F673dB344EF14a4Dc2F12218967a313',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'GINSPIRIT',
            'name': 'GinSpirit',
            'address': '0x2787BEa3366335068bF8B4a253044d09eA4E1C96',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://ethapi.openocean.finance/logos/fantom/0x2787bea3366335068bf8b4a253044d09ea4e1c96.png',
            'coingeckoId': 'ginspirit',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic'
            ]
        },
        {
            'symbol': 'ONX',
            'name': 'OnX Finance',
            'address': '0x27749e79Ad796c4251E0A0564AeF45235493a0b6',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/13445/thumb/onxlogo-1.png?1608629659',
            'coingeckoId': 'onx-finance',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MVD',
            'name': 'Metavault',
            'address': '0x27746007e821aeec6F9C65CBFda04870c236346c',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/MVD.png',
            'coingeckoId': null,
            'listedIn': [
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'DARKO',
            'name': 'DarkOpera Finance',
            'address': '0x274B7EB297615Ce21A1cD5B920f867389A367a8A',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/DARKO.png',
            'coingeckoId': 'darkopera-finance',
            'listedIn': [
                'coingecko',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'Static',
            'name': 'Static',
            'address': '0x27182C8b647fd83603bB442C0E450DE7445ccfB8',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://static.debank.com/image/ftm_token/logo_url/0x27182c8b647fd83603bb442c0e450de7445ccfb8/378df2c96c9bea1a554e28a49c9c6896.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'tCATS',
            'name': 'Wrapped CATS Token',
            'address': '0x26D572B0aecB57abF53EF27770E4003f3daf7150',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://ethapi.openocean.finance/logos/fantom/0x26d572b0aecb57abf53ef27770e4003f3daf7150.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'IRON',
            'name': 'Iron BSC',
            'address': '0x260b3E40c714Ce8196465Ec824Cd8Bb915081812',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/16409/thumb/qBa5_yCL_400x400.jpg?1623927990',
            'coingeckoId': 'iron-bsc',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'GETH',
            'name': 'Geist ETH',
            'address': '0x25c130B2624CF12A4Ea30143eF50c5D68cEFA22f',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/18998/thumb/preview-gallery-gETHgecko_%281%29.png?1634790781',
            'coingeckoId': 'geist-eth',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'ANT',
            'name': 'The Ant',
            'address': '0x25A559E18b1b11a24D50CcC3A70f9dfB079a44D3',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x25a559e18b1b11a24d50ccc3a70f9dfb079a44d3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DSLA',
            'name': 'DSLA Protocol',
            'address': '0x25A528af62e56512A19ce8c3cAB427807c28CC19',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/6694/thumb/dsla_logo-squared_200x200.png?1569571063',
            'coingeckoId': 'stacktical',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'TLC',
            'name': 'TLChain',
            'address': '0x2578850d2a34e7DcE51355895dB05F3bBE562822',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/28177/thumb/tlc.png?1668250171',
            'coingeckoId': 'tlchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GRAIL',
            'name': 'Grail',
            'address': '0x255861B569D44Df3E113b6cA090a1122046E6F89',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/25217/thumb/GRAIL.png?1650868777',
            'coingeckoId': 'grail',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'fCNY',
            'name': 'fCNY',
            'address': '0x24d39324CF3697Fd9Fd78714E8cdeB5Df66E3DCd',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/fcny.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'CHARM',
            'name': 'Charm',
            'address': '0x248CB87DDA803028dfeaD98101C9465A2fbdA0d4',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x248cb87dda803028dfead98101c9465a2fbda0d4.png',
            'coingeckoId': 'charm',
            'listedIn': [
                'coingecko',
                '1inch',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'fSWAMP',
            'name': 'fSWAMP Token',
            'address': '0x23cBC7C95a13071562af2C4Fb1Efa7a284d0543a',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x23cbc7c95a13071562af2c4fb1efa7a284d0543a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BRO',
            'name': 'DexBrowser',
            'address': '0x230576a0455d7Ae33c6dEfE64182C0BB68bAFAF3',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x230576a0455d7ae33c6defe64182c0bb68bafaf3.png',
            'coingeckoId': 'dexbrowser',
            'listedIn': [
                'coingecko',
                '1inch',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'MOR',
            'name': 'Mor Stablecoin',
            'address': '0x22A6aC883B2f5007486C0D0EBC520747c0702Ad5',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/18549/thumb/MOR_logo_icon_black_background_round_200_x_200-01.png?1632364631',
            'coingeckoId': 'mor-stablecoin',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'fGOLD',
            'name': 'fGold',
            'address': '0x2202C52C9076A49400aaccf159e1956269eaa673',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/fgold.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'WFTM',
            'name': 'Wrapped Fantom',
            'address': '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83.png',
            'coingeckoId': 'wrapped-fantom',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'dfyn',
                'SpookySwap',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'OATH',
            'name': 'Oath',
            'address': '0x21Ada0D2aC28C3A5Fa3cD2eE30882dA8812279B6',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/OATH.png',
            'coingeckoId': 'oath',
            'listedIn': [
                'coingecko',
                'openocean',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'UNIDX',
            'name': 'UniDex',
            'address': '0x2130d2a1e51112D349cCF78D2a1EE65843ba36e0',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://ethapi.openocean.finance/logos/fantom/0x2130d2a1e51112d349ccf78d2a1ee65843ba36e0.png',
            'coingeckoId': 'unidex',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'PWFL',
            'name': 'Powerful',
            'address': '0x1f797FDeBD1C9Bf015643D0cc8BD00ecE9e709D7',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/27892/thumb/78plUacg_400x400.jpeg?1666249271',
            'coingeckoId': 'powerful',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'GEMMINE',
            'name': 'GemMine DAO',
            'address': '0x1e2a499fAefb88B2d085d7036f3f7895542b09De',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x1e2a499faefb88b2d085d7036f3f7895542b09de/logo.png',
            'coingeckoId': 'gemmine',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'KNC',
            'name': 'Kyber Network Crystal',
            'address': '0x1e1085eFaA63EDFE74aaD7C05a28EAE4ef917C3F',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/14899/thumb/RwdVsGcw_400x400.jpg?1618923851',
            'coingeckoId': 'kyber-network-crystal',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MND',
            'name': 'Mind Music',
            'address': '0x1cd2528522A17B6Be63012fB63AE81f3e3e29D97',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/19104/thumb/logo-main_%282%29.png?1634506312',
            'coingeckoId': 'mind-music',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TEMP',
            'name': 'Tempus',
            'address': '0x1c174f6AB0753162befbB916c69dEF2cc1bfdEc1',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/20547/thumb/Tempus_CoinGecko_200x200.png?1657674634',
            'coingeckoId': 'tempus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BBADGER',
            'name': 'Badger Sett Badger',
            'address': '0x1F7216fdB338247512Ec99715587bb97BBf96eae',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/14502/thumb/BADGER_DAO.png?1616559959',
            'coingeckoId': 'badger-sett-badger',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'PAWN',
            'name': 'Pawn',
            'address': '0x1F35B7A92564a50fE9c3A9aE8B3864464A950318',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/26190/thumb/9K3MCtws_400x400.jpg?1656394467',
            'coingeckoId': 'pawn-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRV',
            'name': 'Curve DAO',
            'address': '0x1E4F97b9f9F913c46F1632781732927B9019C68b',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0xd533a949740bb3306d119cc777fa900ba034cd52.png',
            'coingeckoId': 'curve-dao-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'RIP',
            'name': 'Fantom Doge',
            'address': '0x1D43697D67cB5D0436cc38d583Ca473a1bFEbC7a',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x1d43697d67cb5d0436cc38d583ca473a1bfebc7a.png',
            'coingeckoId': 'fantom-doge',
            'listedIn': [
                'coingecko',
                '1inch',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'MIMO',
            'name': 'Mimo Governance',
            'address': '0x1D1764F04DE29da6b90ffBef372D1A45596C4855',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x1d1764f04de29da6b90ffbef372d1a45596c4855.png',
            'coingeckoId': 'mimo-parallel-governance-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ASHARE',
            'name': 'ASHARE',
            'address': '0x1C9324d4C71602Af7874A48d76d48f8a9E1b87Bb',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x1c9324d4c71602af7874a48d76d48f8a9e1b87bb/logo.png',
            'coingeckoId': 'ashare',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SYF',
            'name': 'Syfin',
            'address': '0x1BCF4DC879979C68fA255f731FE8Dcf71970c9bC',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/SYF.png',
            'coingeckoId': 'syfin',
            'listedIn': [
                'coingecko',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'AXLUSDC',
            'name': 'axlUSDC',
            'address': '0x1B6382DBDEa11d97f24495C9A90b7c88469134a4',
            'decimals': 6,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/26476/thumb/axlUSDC.png?1658207579',
            'coingeckoId': 'axlusdc',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'ALINK',
            'name': 'Aave LINK',
            'address': '0x191c10Aa4AF7C30e871E70C95dB0E4eb77237530',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/14315/thumb/aLINK.412c6589.png?1615527827',
            'coingeckoId': 'aave-link',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'PGK',
            'name': 'Penguin Karts',
            'address': '0x188a168280589bC3E483d77aae6b4A1d26bD22dC',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/PGK.png',
            'coingeckoId': 'penguin-karts',
            'listedIn': [
                'coingecko',
                'openocean',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'EST',
            'name': 'Ester Token',
            'address': '0x181F3F22C9a751E2ce673498A03E1FDFC0ebBFB6',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x181f3f22c9a751e2ce673498a03e1fdfc0ebbfb6/logo.png',
            'coingeckoId': 'ester-finance',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DEVIL',
            'name': 'Devil Finance',
            'address': '0x174c7106AEeCdC11389f7dD21342F05f46CCB40F',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/DEVIL.png',
            'coingeckoId': 'devil-finance',
            'listedIn': [
                'coingecko',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'KENSHI',
            'name': 'Kenshi',
            'address': '0x164caf66c45e483F7eE647CcaD275B35B4C75719',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/21904/thumb/32x32_%281%29.png?1654588618',
            'coingeckoId': 'kenshi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WEXRD',
            'name': 'E-RADIX',
            'address': '0x161DE8f0b9a59C8197E152da422B9031D3eaf338',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'GEL',
            'name': 'Gelato',
            'address': '0x15b7c0c907e4C6b9AdaAaabC300C08991D6CEA05',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x15b7c0c907e4c6b9adaaaabc300c08991d6cea05.png',
            'coingeckoId': 'gelato',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'OBOL',
            'name': 'Obol',
            'address': '0x1539C63037D95f84A5981F96e43850d1451b6216',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/26585/thumb/obol_%282%29.png?1658892645',
            'coingeckoId': 'obol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MST',
            'name': 'Monster',
            'address': '0x152888854378201e173490956085c711f1DeD565',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x152888854378201e173490956085c711f1ded565.png',
            'coingeckoId': 'metaland-gameverse',
            'listedIn': [
                'coingecko',
                '1inch',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': '5TABLE',
            'name': '5table Token',
            'address': '0x14d6111dbfD64CEb9676a494BF86AA9f7DD54acC',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/5TABLE.png',
            'coingeckoId': null,
            'listedIn': [
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': '3OMB',
            'name': '3OMB',
            'address': '0x14DEf7584A6c52f470Ca4F4b9671056b22f4FfDE',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/3OMB.png',
            'coingeckoId': '30mb-token',
            'listedIn': [
                'coingecko',
                'openocean',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'FEAR',
            'name': 'Fear',
            'address': '0x14418C22165553251B002b289F8AbE4D1Ed41d76',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/15825/thumb/fear-logo-400-400.png?1625552865',
            'coingeckoId': 'fear',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'SMELT',
            'name': 'Smelt',
            'address': '0x141FaA507855E56396EAdBD25EC82656755CD61e',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/26584/thumb/smelt_%282%29.png?1658892551',
            'coingeckoId': 'smelt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSCR',
            'name': 'Binance Scorum',
            'address': '0x133bf038277FDA5eaa2B5fc4CC4e6E15bc5CACE3',
            'decimals': 8,
            'chainId': 250,
            'logoURI': 'https://ethapi.openocean.finance/logos/fantom/0x133bf038277fda5eaa2b5fc4cc4e6e15bc5cace3.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'FXM',
            'name': 'Fantastic Protocol FXM',
            'address': '0x132b56763C0e73F95BeCA9C452BadF89802ba05e',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/24758/thumb/FXM.png?1648790071',
            'coingeckoId': 'fantastic-protocol-fxm-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POWER',
            'name': 'Power Nodes',
            'address': '0x131c7afb4E5f5c94A27611f7210dfEc2215E85Ae',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0xf2f9a7e93f845b3ce154efbeb64fb9346fcce509.png',
            'coingeckoId': 'power-nodes',
            'listedIn': [
                'coingecko',
                '1inch',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'PAR',
            'name': 'Parallel',
            'address': '0x13082681E8CE9bd0aF505912d306403592490Fc7',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x13082681e8ce9bd0af505912d306403592490fc7.png',
            'coingeckoId': 'par-stablecoin',
            'listedIn': [
                'coingecko',
                '1inch',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SEED',
            'name': 'SeedSwap SEED',
            'address': '0x12E62CeCa188E4BC266D3844781c619638ECcf29',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/15636/thumb/2_%287%29.png?1621403830',
            'coingeckoId': 'seedswap-token',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'SEANCE',
            'name': 'SeanceCircle',
            'address': '0x124B06C5ce47De7A6e9EFDA71a946717130079E6',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/22231/thumb/01ed0a6a-35e8-45e1-9cdd-ff4a4756a36f.png?1641249570',
            'coingeckoId': 'seancecircle',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALPHA',
            'name': 'AlphaToken',
            'address': '0x11eb3aA66FE1f2B75cB353D3e874E96968182BdA',
            'decimals': 18,
            'chainId': 250,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REAPER',
            'name': 'Reaper',
            'address': '0x117dB78176C8eDe4F12fCd29d85Cd96b91A4cbBb',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/19133/thumb/Reaper_Logo.png?1634523828',
            'coingeckoId': 'reaper-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FROG',
            'name': 'Frog',
            'address': '0x11319cD0bA5e4ce6c302B275b613962B5cC00aDe',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x11319cd0ba5e4ce6c302b275b613962b5cc00ade/logo.png',
            'coingeckoId': 'frog',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PFTM',
            'name': 'pFTM',
            'address': '0x112dF7E3b4B7Ab424F07319D4E92F41e6608c48B',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x112df7e3b4b7ab424f07319d4e92f41e6608c48b.png',
            'coingeckoId': 'pftm',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'QTM',
            'name': 'QUANTUM',
            'address': '0x11223344D5B43ec0930af1236f4CF67BF7D81217',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/QTM.png',
            'coingeckoId': null,
            'listedIn': [
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'LQDR',
            'name': 'LiquidDriver',
            'address': '0x10b620b2dbAC4Faa7D7FFD71Da486f5D44cd86f9',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x10b620b2dbac4faa7d7ffd71da486f5d44cd86f9.png',
            'coingeckoId': 'liquiddriver',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'HND',
            'name': 'Hundred Finance',
            'address': '0x10010078a54396F62c96dF8532dc2B4847d47ED3',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x10010078a54396f62c96df8532dc2b4847d47ed3.png',
            'coingeckoId': 'hundred-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'AQU',
            'name': 'Aquarius Fi',
            'address': '0x0f7ADb77B6334B9B5A3c4f78aAa073eA1D915bF6',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/15933/thumb/mHNYideX_400x400.png?1622446997',
            'coingeckoId': 'aquarius-fi',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'FBA',
            'name': 'Firebird Aggregator',
            'address': '0x0e249130b3545a2a287DE9f27d805CAB95f03DB9',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/26164/thumb/logo_token-2.2.png?1656324549',
            'coingeckoId': 'firebird-aggregator',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'DIS',
            'name': 'TosDis',
            'address': '0x0e121961DD741C9D49C9A04379da944A9D2FAc7a',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://ethapi.openocean.finance/logos/fantom/0x0e121961dd741c9d49c9a04379da944a9d2fac7a.png',
            'coingeckoId': 'tosdis',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic'
            ]
        },
        {
            'symbol': 'TPIGGY',
            'name': 'TPIGGY',
            'address': '0x0d3d5BF98D7B9961C602fbfB8032c7BDC6a0C386',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x0d3d5bf98d7b9961c602fbfb8032c7bdc6a0c386/logo.png',
            'coingeckoId': 'tpiggy',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'RISE',
            'name': 'EverRise',
            'address': '0x0cD022ddE27169b20895e0e2B2B8A33B25e63579',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x0cd022dde27169b20895e0e2b2b8a33b25e63579/logo.png',
            'coingeckoId': 'everrise',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'GRIMEVO',
            'name': 'Grim EVO',
            'address': '0x0a77866C01429941BFC7854c0c0675dB1015218b',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/23329/thumb/image_%286%29.png?1643851996',
            'coingeckoId': 'grim-evo',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'EARS',
            'name': 'Ferengi Vaults',
            'address': '0x0EC4B89462557150302AC6e81270a081F2e3BD20',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://ethapi.openocean.finance/logos/fantom/0x0ec4b89462557150302ac6e81270a081f2e3bd20.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'SUSD',
            'name': 'sUSD',
            'address': '0x0E1694483eBB3b74d3054E383840C6cf011e518e',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/susd.jpg',
            'coingeckoId': 'nusd',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'COUSD',
            'name': 'Coffin Dollar',
            'address': '0x0DeF844ED26409C5C46dda124ec28fb064D90D27',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x0def844ed26409c5c46dda124ec28fb064d90d27.png',
            'coingeckoId': 'coffin-dollar',
            'listedIn': [
                'coingecko',
                '1inch',
                'SpookySwap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'SCAR',
            'name': 'Scarface Finance',
            'address': '0x0B41469497f46efA3937fba4ed92153146F55ca9',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/23805/thumb/Xe5h3l0p_400x400.jpg?1645508704',
            'coingeckoId': 'scarface-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZOO',
            'name': 'ZooCoin',
            'address': '0x09e145A1D53c0045F41aEEf25D8ff982ae74dD56',
            'decimals': 0,
            'chainId': 250,
            'logoURI': 'https://elk.finance/tokens/logos/ftm/0x09e145A1D53c0045F41aEEf25D8ff982ae74dD56/logo.png',
            'coingeckoId': 'zoo-coin',
            'listedIn': [
                'coingecko',
                'openocean',
                'SpookySwap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DIGG',
            'name': 'Digg',
            'address': '0x08f6fE8f4dC577CF81E40E03E561d29B8b33E19b',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://elk.finance/tokens/logos/ftm/0x08f6fE8f4dC577CF81E40E03E561d29B8b33E19b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'KELVIN',
            'name': 'Kelvin',
            'address': '0x08d70A47D3f28BbF755ae050a783844b40ae5761',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/26522/thumb/1_SOLAR_simbolo_fondo_negro.png?1658455419',
            'coingeckoId': 'kelvin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DP',
            'name': 'Dark Planet',
            'address': '0x08B1c9A96c663EE1E0cD7029F13aceD7dcF5e373',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/21850/thumb/icon_%2814%29.png?1640140269',
            'coingeckoId': 'dark-planet',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'GDAI',
            'name': 'Geist Dai',
            'address': '0x07E6332dD090D287d3489245038daF987955DCFB',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/18997/thumb/preview-gallery-gDAIgecko_%281%29.png?1634790790',
            'coingeckoId': 'geist-dai',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'AWBTC',
            'name': 'Aave WBTC',
            'address': '0x078f358208685046a11C85e8ad32895DED33A249',
            'decimals': 8,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/14244/thumb/aWBTC.41453c2a.png?1615528721',
            'coingeckoId': 'aave-wbtc',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'fBAND',
            'name': 'fBAND',
            'address': '0x078EEF5A2fb533e1a4d487ef64b27DF113d12C32',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/band.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'MORPH',
            'name': 'Morpheus Token',
            'address': '0x0789fF5bA37f72ABC4D561D00648acaDC897b32d',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1634804122642_3022787585299036.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'rubic'
            ]
        },
        {
            'symbol': 'POOP',
            'name': 'Poopsicle',
            'address': '0x070eb1a48725622De867a7E3d1dd4F0108966ED1',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/21826/thumb/poopsicle-200x200.png?1640076755',
            'coingeckoId': 'poopsicle',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'CAGE',
            'name': 'Coinage Finance',
            'address': '0x06ebc9c542357e2129D16717CD02c02FBC835d33',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.coingecko.com/coins/images/22300/thumb/Coinage_v2_200x200_white.png?1653464214',
            'coingeckoId': 'coinage-finance',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'ANKR',
            'name': 'Ankr',
            'address': '0x0615Dbba33Fe61a31c7eD131BDA6655Ed76748B1',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1660706442024_12579897895417402.jpg',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'STEAK',
            'name': 'SteakToken',
            'address': '0x05848B832E872d9eDd84AC5718D58f21fD9c9649',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://ethapi.openocean.finance/logos/fantom/0x05848b832e872d9edd84ac5718d58f21fd9c9649.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'Tether',
            'address': '0x049d68029688eAbF473097a2fC38ef61633A3C7A',
            'decimals': 6,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x049d68029688eabf473097a2fc38ef61633a3c7a.png',
            'coingeckoId': 'tether',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'dfyn',
                'SpookySwap',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'scBIFI',
            'name': 'Scream Wrapped BIFI',
            'address': '0x0467c22fB5aF07eBb14C851C75bFf4180674Ed64',
            'decimals': 8,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x0467c22fb5af07ebb14c851c75bff4180674ed64/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USD Coin',
            'address': '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
            'decimals': 6,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0xddafbb505ad214d7b80b1f830fccc89b60fb7a83.png',
            'coingeckoId': 'usd-coin',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'dfyn',
                'SpookySwap',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FM',
            'name': 'Follow ME',
            'address': '0x03943C3ef00d92e130185CeBC0bcc435Def2cC94',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x03943c3ef00d92e130185cebc0bcc435def2cc94/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ANYP',
            'name': 'AnyPrinter V2',
            'address': '0x0356ED6caec19D3F353821480CA44Bf7dB6D713d',
            'decimals': 9,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x0356ed6caec19d3f353821480ca44bf7db6d713d/logo.png',
            'coingeckoId': 'anyprinter',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'agEUR',
            'name': 'agEUR',
            'address': '0x02a2b736F9150d36C0919F3aCEE8BA2A92FBBb40',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://assets.spooky.fi/tokens/agEUR.png',
            'coingeckoId': null,
            'listedIn': [
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'FAST',
            'name': 'Fast Yield Token',
            'address': '0x0299461eE055bbb6dE11fAfE5a0636A0C3Bd5E8d',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1637632858475_7010644710589458.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'scFUSDT',
            'name': 'Scream Wrapped FUSDT',
            'address': '0x02224765BC8D54C21BB51b0951c80315E1c263F9',
            'decimals': 8,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x02224765bc8d54c21bb51b0951c80315e1c263f9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SFI',
            'name': 'Spice',
            'address': '0x01c3D9CbcF40482ba0D5206f63e3f04Ef9e134D9',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/sfi.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ORBIT',
            'name': 'ORBIT',
            'address': '0x010eB21BB38c60042f0412FAa8869b4FaA6Ee80b',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x010eb21bb38c60042f0412faa8869b4faa6ee80b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FEAR',
            'name': 'SpookyVerse',
            'address': '0x00cD9937cac40Ced12E00728B104961d59add366',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://api.rubic.exchange/assets/fantom/0x00cd9937cac40ced12e00728b104961d59add366/logo.png',
            'coingeckoId': 'fear-token',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'IB',
            'name': 'Iron Bank',
            'address': '0x00a35FD824c717879BF370E70AC6868b95870Dfb',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x00a35fd824c717879bf370e70ac6868b95870dfb.png',
            'coingeckoId': 'iron-bank',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'SpookySwap',
                'lifinance'
            ]
        },
        {
            'symbol': 'FTM',
            'name': 'Fantom Token',
            'address': '0x0000000000000000000000000000000000000000',
            'decimals': 18,
            'chainId': 250,
            'logoURI': 'https://tokens.1inch.io/0x4e15361fd6b4bb609fa63c81a2be19d873717870.png',
            'coingeckoId': 'fantom',
            'listedIn': [
                '1inch',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        }
    ],
    '88': [
        {
            'symbol': 'MASQ',
            'name': 'MASQ',
            'address': '0xeA478716a70DC086707E23De17965aCB5C7b7840',
            'decimals': 18,
            'chainId': 88,
            'logoURI': 'https://assets.coingecko.com/coins/images/13699/thumb/masq.png?1648364813',
            'coingeckoId': 'masq',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZPAY',
            'name': 'ZoidPay',
            'address': '0xe1A9a5FaE06696d314994a9d6915C12a63aD055d',
            'decimals': 18,
            'chainId': 88,
            'logoURI': 'https://assets.coingecko.com/coins/images/16813/thumb/zpay.png?1647693485',
            'coingeckoId': 'zoid-pay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SRM',
            'name': 'Serum',
            'address': '0xc01643aC912B6a8ffC50CF8c1390934A6142bc91',
            'decimals': 4,
            'chainId': 88,
            'logoURI': 'https://assets.coingecko.com/coins/images/11970/thumb/serum-logo.png?1597121577',
            'coingeckoId': 'serum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USD Coin',
            'address': '0xCCA4E6302510d555B654B3EaB9c0fCB223BCFDf0',
            'decimals': 6,
            'chainId': 88,
            'logoURI': 'https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389',
            'coingeckoId': 'usd-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WTOMO',
            'name': 'Wrapped TOMO',
            'address': '0xB1f66997A5760428D3a87D68b90BfE0aE64121cC',
            'decimals': 18,
            'chainId': 88,
            'logoURI': 'https://assets.coingecko.com/coins/images/27618/thumb/tomochain.jpeg?1664874783',
            'coingeckoId': 'wrapped-tomo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TAB',
            'name': 'Tabank',
            'address': '0x8103Bb77eD2035b5da9A3A65d9c313cb6eE41dbD',
            'decimals': 18,
            'chainId': 88,
            'logoURI': 'https://assets.coingecko.com/coins/images/17549/thumb/Mat_sau_512.png?1628226185',
            'coingeckoId': 'tabank',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUA',
            'name': 'LuaSwap',
            'address': '0x7262fa193e9590B2E075c3C16170f3f2f32F5C74',
            'decimals': 18,
            'chainId': 88,
            'logoURI': 'https://assets.coingecko.com/coins/images/12627/thumb/Screenshot_2020-09-28_at_6.24.59_PM.jpg?1601288721',
            'coingeckoId': 'lua-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MLR',
            'name': 'Mega Lottery Services Global',
            'address': '0x5080781e91237b6D7f026D5306b7233e26Cd6C16',
            'decimals': 18,
            'chainId': 88,
            'logoURI': 'https://assets.coingecko.com/coins/images/11072/thumb/gg1NiOsG_400x400.jpg?1587958538',
            'coingeckoId': 'mega-lottery-services-global',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WBTC',
            'name': 'Wrapped Bitcoin',
            'address': '0x503B2dDc059b81788FD1239561596614b27fAADE',
            'decimals': 8,
            'chainId': 88,
            'logoURI': 'https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744',
            'coingeckoId': 'wrapped-bitcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'Tether',
            'address': '0x381B31409e4D220919B2cFF012ED94d70135A59e',
            'decimals': 6,
            'chainId': 88,
            'logoURI': 'https://assets.coingecko.com/coins/images/325/thumb/Tether.png?1668148663',
            'coingeckoId': 'tether',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FTT',
            'name': 'FTX',
            'address': '0x33fa3c0c714638f12339F85dae89c42042a2D9Af',
            'decimals': 17,
            'chainId': 88,
            'logoURI': 'https://assets.coingecko.com/coins/images/9026/thumb/F.png?1609051564',
            'coingeckoId': 'ftx-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WETH',
            'name': 'WETH',
            'address': '0x2EAA73Bd0db20c64f53fEbeA7b5F5E5Bccc7fb8b',
            'decimals': 18,
            'chainId': 88,
            'logoURI': 'https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295',
            'coingeckoId': 'weth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MM',
            'name': 'Million',
            'address': '0x0F320856Fa4Bc7E4030a6ab989D494C5E4d58308',
            'decimals': 17,
            'chainId': 88,
            'logoURI': 'https://assets.coingecko.com/coins/images/16825/thumb/logo200x200.png?1625834139',
            'coingeckoId': 'million',
            'listedIn': [
                'coingecko'
            ]
        }
    ],
    '82': [
        {
            'symbol': 'WBTC',
            'name': 'Wrapped Bitcoin',
            'address': '0xc1f6C86ABEe8e2e0B6fd5BD80F0b51fef783635C',
            'decimals': 8,
            'chainId': 82,
            'logoURI': 'https://assets.coingecko.com/coins/images/7598/thumb/wrapped_bitcoin_wbtc.png?1548822744',
            'coingeckoId': 'wrapped-bitcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOVR',
            'name': 'Moonriver',
            'address': '0xb158870beB809Ad955Bf56065C5C10D7Fd957cC0',
            'decimals': 18,
            'chainId': 82,
            'logoURI': 'https://assets.coingecko.com/coins/images/17984/thumb/9285.png?1630028620',
            'coingeckoId': 'moonriver',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WBNB',
            'name': 'Wrapped BNB',
            'address': '0xF8BBb44E6fD13632D36fe09eB61820f9A44f5D74',
            'decimals': 18,
            'chainId': 82,
            'logoURI': 'https://assets.coingecko.com/coins/images/12591/thumb/binance-coin-logo.png?1600947313',
            'coingeckoId': 'wbnb',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USD Coin',
            'address': '0xD86e243FC0007e6226B07c9A50C9d70D78299EB5',
            'decimals': 6,
            'chainId': 82,
            'logoURI': 'https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389',
            'coingeckoId': 'usd-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VOLT',
            'name': 'VoltSwap',
            'address': '0x8Df95e66Cb0eF38F91D2776DA3c921768982fBa0',
            'decimals': 18,
            'chainId': 82,
            'logoURI': 'https://assets.coingecko.com/coins/images/22841/thumb/voltswap.png?1642745860',
            'coingeckoId': 'voltswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WETH',
            'name': 'WETH',
            'address': '0x79A61D3A28F8c8537A3DF63092927cFa1150Fb3C',
            'decimals': 18,
            'chainId': 82,
            'logoURI': 'https://assets.coingecko.com/coins/images/2518/thumb/weth.png?1628852295',
            'coingeckoId': 'weth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'Tether',
            'address': '0x5Fa41671c48e3C951AfC30816947126CCC8C162e',
            'decimals': 6,
            'chainId': 82,
            'logoURI': 'https://assets.coingecko.com/coins/images/325/thumb/Tether.png?1668148663',
            'coingeckoId': 'tether',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUSD',
            'name': 'Binance USD',
            'address': '0x24aA189DfAa76c671c279262F94434770F557c35',
            'decimals': 18,
            'chainId': 82,
            'logoURI': 'https://assets.coingecko.com/coins/images/9576/thumb/BUSD.png?1568947766',
            'coingeckoId': 'binance-usd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTRG',
            'name': 'Meter Governance',
            'address': '0x228ebBeE999c6a7ad74A6130E81b12f9Fe237Ba3',
            'decimals': 18,
            'chainId': 82,
            'logoURI': 'https://assets.coingecko.com/coins/images/11848/thumb/meter.png?1660274270',
            'coingeckoId': 'meter',
            'listedIn': [
                'coingecko'
            ]
        }
    ],
    '42220': [
        {
            'symbol': 'PLASTIK',
            'name': 'Plastiks',
            'address': '0xf4361a8Fbcf6b6A2402f1A1d6c4b945e59a29934',
            'decimals': 9,
            'chainId': 42220,
            'logoURI': 'https://assets.coingecko.com/coins/images/21212/thumb/plastik.png?1660904251',
            'coingeckoId': 'plastiks',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'G$',
            'name': 'GoodDollar',
            'address': '0xf2283840cE37DAe0a06B40a9A80603977f36fA3F',
            'decimals': 2,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/list/master/logos/token-logos/network/celo/0xf2283840cE37DAe0a06B40a9A80603977f36fA3F.jpg',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'Optics USDC v2',
            'address': '0xef4229c8c3250C675F21BCefa42f58EfbfF6002a',
            'decimals': 6,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_USDC.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'CREAL',
            'name': 'Celo Real  cREAL ',
            'address': '0xe8537a3d056DA446677B9E9d6c5dB704EaAb4787',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_cREAL.png',
            'coingeckoId': 'celo-real-creal',
            'listedIn': [
                'coingecko',
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'RODO',
            'name': 'RODO',
            'address': '0xe5CF1558A1470cb5C166c2e8651eD0F3c5fb8F42',
            'decimals': 2,
            'chainId': 42220,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'KRW',
            'name': 'KROWN',
            'address': '0xd252E98C5B6ea1E29A7e2789A9eC0493707A60B9',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://assets.coingecko.com/coins/images/16530/thumb/KRW_token_logo_200x200.png?1624343058',
            'coingeckoId': 'krown',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MATIC',
            'name': 'Matic',
            'address': '0xc9BAA8cfdDe8E328787E29b4B078abf2DaDc2055',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TFBX',
            'name': 'TrueFeedBack New',
            'address': '0xbDd31EFfb9E9f7509fEaAc5B4091b31645A47e4b',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_TFBX.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'USDTv1',
            'name': 'Optics USDT v1',
            'address': '0xb020D981420744F6b0FedD22bB67cd37Ce18a1d5',
            'decimals': 6,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/usdt.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'mcEUR',
            'name': 'Moola cEUR',
            'address': '0xa8d0E6799FF3Fd19c6459bf02689aE09c4d78Ba7',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/mceur.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'KNX',
            'name': 'KnoxEdge',
            'address': '0xa81D9a2d29373777E4082d588958678a6Df5645c',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_KNX.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'AVAX',
            'name': 'Avalanche',
            'address': '0xFbdd194376de19a88118e84E279b977f165d01b8',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CMETA',
            'name': 'MetaCelo',
            'address': '0xF3608F846cA73147F08FdE8D57f45E27CeEA4d61',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/celo/assets/0xF3608F846cA73147F08FdE8D57f45E27CeEA4d61/logo.png',
            'coingeckoId': 'metacelo',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'O3',
            'name': 'O3 Swap Token',
            'address': '0xEe9801669C6138E84bD50dEB500827b776777d28',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_O3.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'pUSD',
            'name': 'Poof USD',
            'address': '0xEadf4A7168A82D30Ba0619e64d5BCf5B30B45226',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_pUSD.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'WETHv1',
            'name': 'Optics WETH v1',
            'address': '0xE919F65739c26a42616b7b8eedC6b5524d1e3aC4',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/eth.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'IMMO',
            'name': 'ImmortalDAO',
            'address': '0xE685d21b7B0FC7A248a6A8E03b8Db22d013Aa2eE',
            'decimals': 9,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_IMMO.png',
            'coingeckoId': 'immortaldao',
            'listedIn': [
                'coingecko',
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'DAIv1',
            'name': 'Optics DAI v1',
            'address': '0xE4fE50cdD716522A56204352f00AA110F731932d',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/dai.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'MCEUR',
            'name': 'mcEUR',
            'address': '0xE273Ad7ee11dCfAA87383aD5977EE1504aC07568',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_mcEUR.png',
            'coingeckoId': 'mceur',
            'listedIn': [
                'coingecko',
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'cDOGE',
            'name': 'CeloDoge',
            'address': '0xD90BBdf5904cb7d275c41f897495109B9A5adA58',
            'decimals': 9,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/cdoge.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'CEUR',
            'name': 'Celo Euro',
            'address': '0xD8763CBa276a3738E6DE85b4b3bF5FDed6D6cA73',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_cEUR.png',
            'coingeckoId': 'celo-euro',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'pEUR',
            'name': 'Poof EUR',
            'address': '0xD8761DD6c7cB54febD33adD699F5E4440b62E01B',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_pEUR.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'WBTC',
            'name': 'Wrapped Bitcoin',
            'address': '0xD629eb00dEced2a080B7EC630eF6aC117e614f1b',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_cBTC.png',
            'coingeckoId': 'wrapped-bitcoin',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'SUSHI',
            'name': 'Sushi',
            'address': '0xD15EC721C2A896512Ad29C671997DD68f9593226',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/sushi.jpg',
            'coingeckoId': 'sushi',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'STCELO',
            'name': 'Staked Celo',
            'address': '0xC668583dcbDc9ae6FA3CE46462758188adfdfC24',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://assets.coingecko.com/coins/images/27088/thumb/For_larger_icons_%28192x192__512x512__etc%29.png?1661777411',
            'coingeckoId': 'staked-celo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WBTCv1',
            'name': 'Optics WBTC v1',
            'address': '0xBe50a3013A1c94768A1ABb78c3cB79AB28fc1aCE',
            'decimals': 8,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/btc.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'WBTC',
            'name': 'Optics WBTC v2',
            'address': '0xBAAB46E28388d2779e6E31Fd00cF0e5Ad95E327B',
            'decimals': 8,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_WBTC.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'MIMATIC',
            'name': 'MAI',
            'address': '0xB9C8F0d3254007eE4b98970b94544e473Cd610EC',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://assets.coingecko.com/coins/images/15264/thumb/mimatic-red.png?1620281018',
            'coingeckoId': 'mimatic',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BNB',
            'name': 'Binance',
            'address': '0xA649325Aa7C5093d12D6F98EB4378deAe68CE23F',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_BNB.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'cDEFI',
            'name': 'Celo DeFi Index',
            'address': '0xA287A3722c367849eFA5c76e96BE36efd65C290e',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_cDEFI.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'ETHIX',
            'name': 'EthicHub',
            'address': '0x9995cc8F20Db5896943Afc8eE0ba463259c931ed',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_ETHIX.png',
            'coingeckoId': 'ethichub',
            'listedIn': [
                'coingecko',
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'MCREAL',
            'name': 'Moola interest bearing CREAL',
            'address': '0x9802d866fdE4563d088a6619F7CeF82C0B991A55',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_mcREAL.png',
            'coingeckoId': 'moola-interest-bearing-creal',
            'listedIn': [
                'coingecko',
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'PREMIO',
            'name': 'Premio',
            'address': '0x94140c2eA9D208D8476cA4E3045254169791C59e',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_PREMIO.png',
            'coingeckoId': 'premio',
            'listedIn': [
                'coingecko',
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'MCUSD',
            'name': 'Moola Celo Dollars',
            'address': '0x918146359264C492BD6934071c6Bd31C854EDBc3',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_mcUSD.png',
            'coingeckoId': 'moola-celo-dollars',
            'listedIn': [
                'coingecko',
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'DAI',
            'name': 'Optics Dai v2',
            'address': '0x90Ca507a5D4458a4C6C6249d186b6dCb02a5BCCd',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_DAI.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'AVAX',
            'name': 'AVAX',
            'address': '0x8E3670FD7B0935d3FE832711deBFE13BB689b690',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_AVAX.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'Optics USDT v2',
            'address': '0x88eeC49252c8cbc039DCdB394c0c2BA2f1637EA0',
            'decimals': 6,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_USDT.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'SYMM',
            'name': 'Symmetric',
            'address': '0x8427bD503dd3169cCC9aFF7326c15258Bc305478',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_SYMM.png',
            'coingeckoId': 'symmetric',
            'listedIn': [
                'coingecko',
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'cXOF',
            'name': 'Duniapay West African CFA franc',
            'address': '0x832F03bCeE999a577cb592948983E35C048B5Aa4',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_cXOF.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'MCELO',
            'name': 'mCELO',
            'address': '0x7D00cd74FF385c955EA3d79e47BF06bD7386387D',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_mCELO.png',
            'coingeckoId': 'mcelo',
            'listedIn': [
                'coingecko',
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'xABR',
            'name': 'Staked Allbridge',
            'address': '0x788BA01f8E2b87c08B142DB46F82094e0bdCad4F',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_xABR.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'CUSD',
            'name': 'Celo Dollar',
            'address': '0x765DE816845861e75A25fCA122bb6898B8B1282a',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_cUSD.png',
            'coingeckoId': 'celo-dollar',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'SOURCE',
            'name': 'ReSource Protocol',
            'address': '0x74c0C58B99b68cF16A717279AC2d056A34ba2bFe',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_SOURCE.png',
            'coingeckoId': 'resource-protocol',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'MOBI',
            'name': 'Mobius Money',
            'address': '0x73a210637f6F6B7005512677Ba6B3C96bb4AA44B',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_MOBI.png',
            'coingeckoId': 'mobius-money',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'MCELO',
            'name': 'Moola CELO AToken',
            'address': '0x7037F7296B2fc7908de7b57a89efaa8319f0C500',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/mcelo.jpg',
            'coingeckoId': 'moola-celo-atoken',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ABR',
            'name': 'Allbridge',
            'address': '0x6e512BFC33be36F2666754E996ff103AD1680Cc9',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_ABR.png',
            'coingeckoId': 'allbridge',
            'listedIn': [
                'coingecko',
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'ETH',
            'name': 'Portal Ether',
            'address': '0x66803FB87aBd4aaC3cbB3fAd7C3aa01f6F3FB207',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_ETH.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'mcUSD',
            'name': 'Moola cUSD',
            'address': '0x64dEFa3544c695db8c535D289d843a189aa26b98',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/mcusd.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BIFI',
            'name': 'Beefy Finance',
            'address': '0x639A647fbe20b6c8ac19E48E2de44ea792c62c5C',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/celo/assets/0x639A647fbe20b6c8ac19E48E2de44ea792c62c5C/logo.png',
            'coingeckoId': 'beefy-finance',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'CLA',
            'name': 'CeloLaunch',
            'address': '0x5927fd244E11dB1c7b1215619144d2aAbAc80a4F',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/celo/assets/0x5927fd244E11dB1c7b1215619144d2aAbAc80a4F/logo.png',
            'coingeckoId': 'celolaunch',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SBR',
            'name': 'Allbridge SBR',
            'address': '0x47264aE1Fc0c8e6418ebe78630718E11a07346A8',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_SBR.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'CELO',
            'name': 'Celo',
            'address': '0x471EcE3750Da237f93B8E339c536989b8978a438',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_CELO.png',
            'coingeckoId': 'celo',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'PACT',
            'name': 'impactMarket',
            'address': '0x46c9757C5497c5B1f2eb73aE79b6B67D119B0B58',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_PACT.png',
            'coingeckoId': 'impactmarket',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'WSOL',
            'name': 'Token Wrapped SOL (Wormhole)',
            'address': '0x4581E64115d46CcdeE65Be2336bEc86c9BA54C01',
            'decimals': 9,
            'chainId': 42220,
            'logoURI': 'https://assets.coingecko.com/coins/images/4128/small/solana.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CSTAR',
            'name': 'CeloStarter',
            'address': '0x452EF5a4bD00796e62E5e5758548e0dA6e8CCDF3',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_cStar.png',
            'coingeckoId': 'celostarter',
            'listedIn': [
                'coingecko',
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'MMOO',
            'name': 'Interest Bearing MOO',
            'address': '0x3A5024E3AAB31A1d3184127B52b0e4B4E9ADcC34',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': null,
            'coingeckoId': 'interest-bearing-moo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDCet',
            'name': 'Portal USDC from Ethereum',
            'address': '0x37f750B7cC259A2f741AF45294f6a16572CF5cAd',
            'decimals': 6,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_USDCet.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'cMCO2',
            'name': 'Celo Moss Carbon Credit',
            'address': '0x32A9FE697a32135BFd313a6Ac28792DaE4D9979d',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_cMCO2.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'pCELO',
            'name': 'Poof CELO',
            'address': '0x301a61D01A63c8D670c2B8a43f37d12eF181F997',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_pCELO.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'WMATIC',
            'name': 'Optics WMATIC',
            'address': '0x2E3487F967DF2Ebc2f236E16f8fCAeac7091324D',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_WMATIC.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'WETH',
            'name': 'WETH',
            'address': '0x2DEf4285787d58a2f811AF24755A8150622f4361',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_cETH.svg',
            'coingeckoId': 'weth',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'USDCv1',
            'name': 'Optics USDC v1',
            'address': '0x2A3684e9Dc20B857375EA04235F2F7edBe818FA7',
            'decimals': 6,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/usdc.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SUSHI',
            'name': 'Optics SushiToken v2',
            'address': '0x29dFce9c22003A4999930382Fd00f9Fd6133Acd1',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_SUSHI.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'sCELO',
            'name': 'Savings CELO',
            'address': '0x2879BFD5e7c4EF331384E908aaA3Bd3014b703fA',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_sCELO.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'cSHIBA',
            'name': 'CeloShiba',
            'address': '0x22401536505dd5d85F7d57f8B37172feDa8f499d',
            'decimals': 9,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/cshiba.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'FTM',
            'name': 'Fantom',
            'address': '0x218c3c3D49d0E7B37aff0D8bB079de36Ae61A4c0',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_FTM.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'ARI',
            'name': 'Ari Swap',
            'address': '0x20677d4f3d0F08e735aB512393524A3CfCEb250C',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_ARI.png',
            'coingeckoId': 'ari-swap',
            'listedIn': [
                'coingecko',
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'USDC-POS',
            'name': 'Optics USDC v2 POS',
            'address': '0x1bfc26cE035c368503fAE319Cc2596716428ca44',
            'decimals': 6,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/celo/assets//logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'rCELO',
            'name': 'Rewards CELO',
            'address': '0x1a8Dbe5958c597a744Ba51763AbEBD3355996c3e',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_rCELO.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'MOO',
            'name': 'Moola Market',
            'address': '0x17700282592D6917F6A73D0bF8AcCf4D578c131e',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_MOO.png',
            'coingeckoId': 'moola-market',
            'listedIn': [
                'coingecko',
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'CRV',
            'name': 'Curve DAO Token',
            'address': '0x173fd7434B8B50dF08e3298f173487ebDB35FD14',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SOL',
            'name': 'Allbridge SOL',
            'address': '0x173234922eB27d5138c5e481be9dF5261fAeD450',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_SOL.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'NTMX',
            'name': 'NetM Token',
            'address': '0x123ED050805E0998EBEf43671327139224218e50',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_NTMX.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'WETH',
            'name': 'Optics WETH v2',
            'address': '0x122013fd7dF1C6F636a5bb8f03108E876548b455',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_WETH.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'BUSD',
            'name': 'Binance-Peg BUSD Token',
            'address': '0x0dCb0CB0120d355CdE1ce56040be57Add0185BAa',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'stabilUSD',
            'name': 'Stabilite USD',
            'address': '0x0a60c25Ef6021fC3B479914E6bcA7C03c18A97f1',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_stabilUSD.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'QI',
            'name': 'QiDao',
            'address': '0x062016Cd29Fabb26c52BAB646878987fC9B0Bc55',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://static.debank.com/image/celo_token/logo_url/0x062016cd29fabb26c52bab646878987fc9b0bc55/c0524dfb9be687e8e26191eed8774fd3.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'NCT',
            'name': 'Nature Carbon Tonne',
            'address': '0x02De4766C272abc10Bc88c220D214A26960a7e92',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_NCT.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'UBE',
            'name': 'Ubeswap',
            'address': '0x00Be915B9dCf56a3CBE739D9B9c202ca692409EC',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_UBE.png',
            'coingeckoId': 'ubeswap',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance',
                'ubeswap'
            ]
        },
        {
            'symbol': 'POOF',
            'name': 'PoofCash',
            'address': '0x00400FcbF0816bebB94654259de7273f4A05c762',
            'decimals': 18,
            'chainId': 42220,
            'logoURI': 'https://raw.githubusercontent.com/ubeswap/default-token-list/master/assets/asset_POOF.png',
            'coingeckoId': 'poofcash',
            'listedIn': [
                'coingecko',
                'lifinance',
                'ubeswap'
            ]
        }
    ],
    '10': [
        {
            'symbol': 'RADIO',
            'name': 'RadioShack',
            'address': '0xf899e3909B4492859d44260E1de41A9E663e70F5',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/25307/thumb/ZVoPiysPJq6dPIZm_Se-6vjmsBepwhHlTQfdYZRILbHyVVTRUYCO-wmJJ4zT10HXCGv1j-ZyWr2u2sBaVlap5Y-ILqeXZuIquWdBDxxG0E0qDpgH7omLqYdgWWLSM_TUK9d1PiiYdu6bERdCDaucgFjlqwmhVQK4uV4jyUiXzchVUnu8Qt6SnxlNxz88G0mQ_tfiwkFv_vKqtgb1CcPycVZVz9.jpg?1651211260',
            'coingeckoId': 'radioshack',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ELK',
            'name': 'Elk',
            'address': '0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://tokens.1inch.io/0xeeeeeb57642040be42185f49c52f7e9b38f8eeee.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'RFWSTETH',
            'name': 'Respawn Finance Wrapped Staked Ethereum',
            'address': '0xeAeAdAC73baaF4cB8B024dE9D65B2eeFa722856C',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/RFWSTETH/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'AWETH',
            'name': 'Aave WETH',
            'address': '0xe50fA9b3c56FfB159cB0FCA61F5c9D750e8128c8',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/17238/thumb/aWETH_2x.png?1626940782',
            'coingeckoId': 'aave-weth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUILD',
            'name': 'BUILD',
            'address': '0xe4dE4B87345815C71aa843ea4841bCdc682637bb',
            'decimals': 4,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/26533/thumb/BUILD.png?1658714842',
            'coingeckoId': 'build',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'VEE',
            'name': 'BLOCKv',
            'address': '0xe3C332a5DcE0e1d9bC2cC72A68437790570C28a4',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/1266/thumb/blockv.png?1547351380',
            'coingeckoId': 'blockv',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALTA',
            'name': 'Alta Finance',
            'address': '0xe0cCa86B254005889aC3a81e737f56a14f4A38F5',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/18713/thumb/AFN-token-Altafin-200.png?1633079552',
            'coingeckoId': 'alta-finance',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': '0XBTC',
            'name': '0xBitcoin',
            'address': '0xe0BB0D3DE8c10976511e5030cA403dBf4c25165B',
            'decimals': 8,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/0xBTC/logo.png',
            'coingeckoId': 'oxbitcoin',
            'listedIn': [
                'coingecko',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'MIMATIC',
            'name': 'MAI',
            'address': '0xdFA46478F9e5EA86d57387849598dbFB2e964b02',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://tokens.1inch.io/0xa3fa99a148fa48d14ed51d610c367c61876997f1.png',
            'coingeckoId': 'mimatic',
            'listedIn': [
                'coingecko',
                'openocean',
                'RouterProtocol',
                'lifinance'
            ]
        },
        {
            'symbol': 'TheDAO',
            'name': 'TheDAO',
            'address': '0xd8f365c2c85648f9B89D9f1BF72C0aE4b1C36cFD',
            'decimals': 16,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/TheDAO/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'BICO',
            'name': 'Biconomy Token',
            'address': '0xd6909e9e702024eb93312B989ee46794c0fB1C9D',
            'decimals': 18,
            'chainId': 10,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'L2DAO',
            'name': 'Layer2DAO',
            'address': '0xd52f94DF742a6F4B4C8b033369fE13A41782Bf44',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/23699/thumb/Khp7Y4Sn.png?1645081048',
            'coingeckoId': 'layer2dao',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'SPANK',
            'name': 'SPANK',
            'address': '0xcfD1D50ce23C46D3Cf6407487B2F8934e96DC8f9',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/SPANK/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'OPX',
            'name': 'OPX Finance',
            'address': '0xcdB4bB51801A1F399d4402c61bC098a72c382E65',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/28225/thumb/logo_OPX.png?1668501930',
            'coingeckoId': 'opx-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TUSD',
            'name': 'TrueUSD',
            'address': '0xcB59a0A753fDB7491d5F3D794316F1adE197B21E',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/TUSD/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'BitBTC',
            'name': 'BitBTC',
            'address': '0xc98B98d17435AA00830c87eA02474C5007E1f272',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/BitBTC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'sLINK',
            'name': 'Synthetic Chainlink',
            'address': '0xc5Db22719A06418028A40A9B5E9A7c02959D0d08',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/sLINK/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'HOP',
            'name': 'Hop Protocol',
            'address': '0xc5102fE9359FD9a28f877a67E36B0F050d81a3CC',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://tokens.1inch.io/0xc5102fe9359fd9a28f877a67e36b0f050d81a3cc.png',
            'coingeckoId': 'hop-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'PAE',
            'name': 'Ripae',
            'address': '0xc46fedc533E9f14c99C4c5124e2085701fa9B255',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://static.debank.com/image/op_token/logo_url/0xc46fedc533e9f14c99c4c5124e2085701fa9b255/70b044215e3adcbe163246e4787e77fa.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'LUSD',
            'name': 'Liquity USD',
            'address': '0xc40F949F8a4e094D1b49a23ea9241D289B7b2819',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/LUSD/logo.svg',
            'coingeckoId': 'liquity-usd',
            'listedIn': [
                'coingecko',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'OPENX',
            'name': 'OpenXSwap',
            'address': '0xc3864f98f2a61A7cAeb95b039D031b4E2f55e0e9',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/27663/thumb/sx7muGTv_400x400.jpeg?1665116515',
            'coingeckoId': 'openxswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MNTO',
            'name': 'Minato',
            'address': '0xc04Fb38f10AD352c5f16Bd4546F7456E7f1A2D9e',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/24622/thumb/MNTO_200x200.png?1648448664',
            'coingeckoId': 'minato',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USX',
            'name': 'dForce USD',
            'address': '0xbfD291DA8A403DAAF7e5E9DC1ec0aCEaCd4848B9',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/USX/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'FRACTION',
            'name': 'Own a fraction',
            'address': '0xbD80CFA9d93A87D1bb895f810ea348E496611cD4',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/15099/large/fraction.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SOLUNAVAX',
            'name': 'SOLUNAVAX Index',
            'address': '0xbA6a2Fa321BB06D668c5192Be77428106c5C01E5',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/23549/thumb/soluna_avax.png?1644458922',
            'coingeckoId': 'solunavax-index',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'alUSD',
            'name': 'Multichain alUSD',
            'address': '0xb2c22A9fb4FC02eb9D1d337655Ce079a04a526C7',
            'decimals': 18,
            'chainId': 10,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ROOBEE',
            'name': 'Roobee',
            'address': '0xb12c13e66AdE1F72f71834f2FC5082Db8C091358',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/8791/thumb/Group_11.png?1580344629',
            'coingeckoId': 'roobee',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'TRB',
            'name': 'Tellor',
            'address': '0xaf8cA653Fa2772d58f4368B0a71980e9E3cEB888',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/TRB/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'MKR',
            'name': 'Maker',
            'address': '0xab7bAdEF82E9Fe11f6f33f87BC9bC2AA27F2fCB5',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/MKR/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'KNC',
            'name': 'Kyber Network Crystal',
            'address': '0xa00E3A3511aAC35cA78530c85007AFCd31753819',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/KNC/logo.png',
            'coingeckoId': 'kyber-network-crystal',
            'listedIn': [
                'coingecko',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'ACX',
            'name': 'Across Protocol Token',
            'address': '0xFf733b2A3557a7ed6697007ab5D11B79FdD1b76B',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/ACX/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'LDO',
            'name': 'Lido DAO',
            'address': '0xFdb794692724153d1488CcdBE0C56c252596735F',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/LDO/logo.svg',
            'coingeckoId': 'lido-dao',
            'listedIn': [
                'coingecko',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'PHTK',
            'name': 'PhunToken',
            'address': '0xFa956eB0c4b3E692aD5a6B2f08170aDE55999ACa',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/PHTK/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'LRC',
            'name': 'LoopringCoin V2',
            'address': '0xFEaA9194F9F8c1B65429E31341a103071464907E',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/LRC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'BAL',
            'name': 'Balancer',
            'address': '0xFE8B128bA8C78aabC59d4c64cEE7fF28e9379921',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/BAL/logo.png',
            'coingeckoId': 'balancer',
            'listedIn': [
                'coingecko',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'ZIP',
            'name': 'ZipSwap',
            'address': '0xFA436399d0458Dbe8aB890c3441256E3E09022a8',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/22910/small/zip.png?1642949795',
            'coingeckoId': 'zipswap',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'KROM',
            'name': 'Kromatika',
            'address': '0xF98dCd95217E15E05d8638da4c91125E59590B07',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://tokens.1inch.io/0xf98dcd95217e15e05d8638da4c91125e59590b07.png',
            'coingeckoId': 'kromatika',
            'listedIn': [
                'coingecko',
                '1inch',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'SUKU',
            'name': 'SUKU',
            'address': '0xEf6301DA234fC7b0545c6E877D3359FE0B9E50a4',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/SUKU/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'O3',
            'name': 'O3 Swap',
            'address': '0xEe9801669C6138E84bD50dEB500827b776777d28',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/15460/thumb/o3.png?1620904316',
            'coingeckoId': 'o3-swap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CTSI',
            'name': 'Cartesi',
            'address': '0xEc6adef5E1006bb305bB1975333e8fc4071295bf',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/CTSI/logo.svg',
            'coingeckoId': 'cartesi',
            'listedIn': [
                'coingecko',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'UMA',
            'name': 'UMA Voting Token v1',
            'address': '0xE7798f023fC62146e8Aa1b36Da45fb70855a77Ea',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/UMA/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'xcRMRK',
            'name': 'xcRMRK',
            'address': '0xE6cDD3a275c976BcA5d3De4F96c7514B899f0434',
            'decimals': 10,
            'chainId': 10,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'XCHF',
            'name': 'CryptoFranc',
            'address': '0xE4F27b04cC7729901876B44f4EAA5102EC150265',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/XCHF/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'SETH',
            'name': 'sETH',
            'address': '0xE405de8F52ba7559f9df3C368500B6E6ae6Cee49',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://tokens.1inch.io/0xe405de8f52ba7559f9df3c368500b6e6ae6cee49.png',
            'coingeckoId': 'seth',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'SPELL',
            'name': 'Spell Token',
            'address': '0xE3AB61371ECc88534C522922a026f2296116C109',
            'decimals': 18,
            'chainId': 10,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'xyETH',
            'name': 'xyETH',
            'address': '0xE2E785E3fcB00F3d2f5E01291E7741635c54F140',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
            'coingeckoId': null,
            'listedIn': [
                'xyfinance'
            ]
        },
        {
            'symbol': 'ETH',
            'name': 'Ether',
            'address': '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/ETH/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'BAXA',
            'name': 'BAXagent Coin',
            'address': '0xDb9888b842408B0b8eFa1f5477bD9F351754999E',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/BAXA/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'DAI',
            'name': 'Dai',
            'address': '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://elk.finance/tokens/logos/optimism/0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1/logo.png',
            'coingeckoId': 'dai',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'optimism',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'RMAI',
            'name': 'RMAI',
            'address': '0xD753EBf3041A084D902eB5A035c4C289eBfe0216',
            'decimals': 18,
            'chainId': 10,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'RouterProtocol'
            ]
        },
        {
            'symbol': 'GTPS',
            'name': 'Global Transaction Payment Solution',
            'address': '0xD22a58f79e9481D1a88e00c343885A588b34b68B',
            'decimals': 18,
            'chainId': 10,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ZRX',
            'name': '0x Protocol Token',
            'address': '0xD1917629B3E6A72E6772Aab5dBe58Eb7FA3C2F33',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/ZRX/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'SHN',
            'name': 'Shine',
            'address': '0xCE1bFFBD5374Dac86a2893119683F4911a2F7814',
            'decimals': 18,
            'chainId': 10,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ALUSD',
            'name': 'Alchemix USD',
            'address': '0xCB8FA9a76b8e203D8C3797bF438d8FB81Ea3326A',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/14114/thumb/Alchemix_USD.png?1614410406',
            'coingeckoId': 'alchemix-usd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UBI',
            'name': 'Universal Basic Income',
            'address': '0xBb586Ed34974B15049a876fd5366A4C2d1203115',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/UBI/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'UST',
            'name': 'UST (Wormhole)',
            'address': '0xBA28feb4b6A6b81e3F26F08b83a19E715C4294fd',
            'decimals': 6,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/UST/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'RGT',
            'name': 'Rari Governance Token',
            'address': '0xB548f63D4405466B36C0c0aC3318a22fDcec711a',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/RGT/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'MIM',
            'name': 'Magic Internet Money',
            'address': '0xB153FB3d196A8eB25522705560ac152eeEc57901',
            'decimals': 18,
            'chainId': 10,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BOB',
            'name': 'BOB',
            'address': '0xB0B195aEFA3650A6908f15CdaC7D92F8a5791B0B',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://tokens.1inch.io/0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b.png',
            'coingeckoId': 'bob',
            'listedIn': [
                'coingecko',
                '1inch',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'SDL',
            'name': 'Saddle DAO',
            'address': '0xAe31207aC34423C41576Ff59BFB4E036150f9cF7',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://tokens.1inch.io/0xae31207ac34423c41576ff59bfb4e036150f9cf7.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'DHT',
            'name': 'dHEDGE DAO',
            'address': '0xAF9fE3B5cCDAe78188B1F8b9a49Da7ae9510F151',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/DHT/logo.svg',
            'coingeckoId': 'dhedge-dao',
            'listedIn': [
                'coingecko',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'CRE8R',
            'name': 'CRE8R DAO',
            'address': '0xABd380327Fe66724FFDa91A87c772FB8D00bE488',
            'decimals': 18,
            'chainId': 10,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DF',
            'name': 'dForce',
            'address': '0x9e5AAC1Ba1a2e6aEd6b32689DFcF62A509Ca96f3',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/DF/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'PERP',
            'name': 'Perpetual Protocol',
            'address': '0x9e1028F5F1D5eDE59748FFceE5532509976840E0',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://tokens.1inch.io/0x9e1028f5f1d5ede59748ffcee5532509976840e0.png',
            'coingeckoId': 'perpetual-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'OPP',
            'name': 'OptimismPad',
            'address': '0x9E841f05Dc2b92199F3FCE96bDEfc36A08Dcb9F6',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/27015/thumb/200x200.png?1661478401',
            'coingeckoId': 'optimismpad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'rETH',
            'name': 'Rocket Pool ETH',
            'address': '0x9Bcef72be871e61ED4fBbc7630889beE758eb81D',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/rETH/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'BEETS',
            'name': 'Beethoven X',
            'address': '0x97513e975a7fA9072c72C92d8000B0dB90b163c5',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/19158/thumb/beets-icon-large.png?1634545465',
            'coingeckoId': 'beethoven-x',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'IMON',
            'name': 'Intelligent Monsters',
            'address': '0x9631be8566fC71d91970b10AcfdEe29F21Da6C27',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/26077/thumb/imonToken.png?1655690407',
            'coingeckoId': 'intelligent-monsters',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'Tether',
            'address': '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
            'decimals': 6,
            'chainId': 10,
            'logoURI': 'https://tokens.1inch.io/0x94b008aa00579c1307b0ef2c499ad98a8ce58e58.png',
            'coingeckoId': 'tether',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'optimism',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BYE',
            'name': 'Basis Yield ETH Index',
            'address': '0x927Eb0dBC5c3FD172Fdfa72D563f71612eCB6122',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/25989/thumb/BYE.png?1655188679',
            'coingeckoId': 'basis-yield-eth-index',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KWENTA',
            'name': 'Kwenta',
            'address': '0x920Cf626a271321C151D027030D5d08aF699456b',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/27409/thumb/kwenta.png?1668768595',
            'coingeckoId': 'kwenta',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SUSD',
            'name': 'sUSD',
            'address': '0x8c6f28f2F1A3C87F0f938b96d27520d9751ec8d9',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://tokens.1inch.io/0x57ab1ec28d129707052df4df418d58a2d46d5f51.png',
            'coingeckoId': 'nusd',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'DOLA',
            'name': 'Dola USD Stablecoin',
            'address': '0x8aE125E8653821E851F12A49F7765db9a9ce7384',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://tokens.1inch.io/0x8ae125e8653821e851f12a49f7765db9a9ce7384.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'DOG',
            'name': 'The Doge NFT',
            'address': '0x8F69Ee043d52161Fd29137AeDf63f5e70cd504D5',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/18111/thumb/Doge.png?1630696110',
            'coingeckoId': 'the-doge-nft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SNX',
            'name': 'Synthetix Network',
            'address': '0x8700dAec35aF8Ff88c16BdF0418774CB3D7599B4',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/SNX/logo.svg',
            'coingeckoId': 'havven',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'FLASH',
            'name': 'Flashstake',
            'address': '0x86bEA60374f220dE9769b2fEf2db725bc1cDd335',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/FLASH/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'USDCx',
            'name': 'Superfluid USDC',
            'address': '0x8430F084B939208E2eDEd1584889C9A66B90562f',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://static.debank.com/image/op_token/logo_url/0x7f5c764cbc14f9669b88837ca1490cca17c31607/773a0161709a55edc211c3fa67f7c1a7.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'RODO',
            'name': 'RODO',
            'address': '0x82f0B8B456c1A451378467398982d4834b6829c1',
            'decimals': 2,
            'chainId': 10,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ADAI',
            'name': 'Aave DAI',
            'address': '0x82E64f49Ed5EC1bC6e43DAD4FC8Af9bb3A2312EE',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/14242/thumb/aDAI.84b6c41f.png?1615528749',
            'coingeckoId': 'aave-dai',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EQZ',
            'name': 'Equalizer',
            'address': '0x81aB7E0D570b01411fcC4afd3D50eC8C241cb74b',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/EQZ/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'DAIx',
            'name': 'Superfluid DAI',
            'address': '0x7d342726B69C28D942ad8BfE6Ac81b972349d524',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://static.debank.com/image/op_token/logo_url/0xda10009cbd5d07dd0cecc66161fc93d7c9000da1/45965130df45ecf234ff03ce28299cd1.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SARCO',
            'name': 'Sarcophagus',
            'address': '0x7c6b91D9Be155A6Db01f749217d76fF02A7227F2',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/SARCO/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'EST',
            'name': 'Erica Social Token',
            'address': '0x7b0bcC23851bBF7601efC9E9FE532Bf5284F65d3',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/EST/logo.png',
            'coingeckoId': 'erica-social-token',
            'listedIn': [
                'coingecko',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'UNLOCK',
            'name': 'Unlock Token',
            'address': '0x7aE97042a4A0eB4D1eB370C34BfEC71042a056B7',
            'decimals': 18,
            'chainId': 10,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'RAI',
            'name': 'Rai Reflex Index',
            'address': '0x7FB688CCf682d58f86D7e38e03f9D22e7705448B',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://tokens.1inch.io/0x7fb688ccf682d58f86d7e38e03f9d22e7705448b.png',
            'coingeckoId': 'rai',
            'listedIn': [
                'coingecko',
                '1inch',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'PONY',
            'name': 'PONY Index',
            'address': '0x7F8BC696bEBbbd29255F871cBef55B74e8F10E57',
            'decimals': 18,
            'chainId': 10,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USD Coin',
            'address': '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
            'decimals': 6,
            'chainId': 10,
            'logoURI': 'https://tokens.1inch.io/0x7f5c764cbc14f9669b88837ca1490cca17c31607.png',
            'coingeckoId': 'usd-coin',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'optimism',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'POTS',
            'name': 'Moonpot',
            'address': '0x76a3D96726C0ED756eA420d239d3Feb998EBf528',
            'decimals': 18,
            'chainId': 10,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AAVE',
            'name': 'Aave',
            'address': '0x76FB31fb4af56892A25e32cFC43De717950c9278',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/AAVE/logo.svg',
            'coingeckoId': 'aave',
            'listedIn': [
                'coingecko',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'aCRV',
            'name': 'AladdinCRV',
            'address': '0x764aD60e1B81F6CACfec1a2926393D688D4493E6',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/aCRV/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'USD+',
            'name': 'USD ',
            'address': '0x73cb180bf0521828d8849bc8CF2B920918e23032',
            'decimals': 6,
            'chainId': 10,
            'logoURI': 'https://tokens.1inch.io/0x73cb180bf0521828d8849bc8cf2b920918e23032.png',
            'coingeckoId': 'usd',
            'listedIn': [
                'coingecko',
                '1inch',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'USDD',
            'name': 'Decentralized USD',
            'address': '0x7113370218f31764C1B6353BDF6004d86fF6B9cc',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/USDD/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'WAD',
            'name': 'WardenSwap',
            'address': '0x703D57164CA270b0B330A87FD159CfEF1490c0a5',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/WAD/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'UNI',
            'name': 'Uniswap',
            'address': '0x6fd9d7AD17242c41f7131d257212c54A0e816691',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/UNI/logo.png',
            'coingeckoId': 'uniswap',
            'listedIn': [
                'coingecko',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'ASUSD',
            'name': 'Aave SUSD',
            'address': '0x6d80113e533a2C0fe82EaBD35f1875DcEA89Ea97',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/14266/thumb/aSUSD.9b00ffc6.png?1615528241',
            'coingeckoId': 'aave-susd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HMT',
            'name': 'Human Token',
            'address': '0x6b7a87899490EcE95443e979cA9485CBE7E71522',
            'decimals': 18,
            'chainId': 10,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AUSDT',
            'name': 'Aave USDT',
            'address': '0x6ab707Aca953eDAeFBc4fD23bA73294241490620',
            'decimals': 6,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/14243/thumb/aUSDT.78f5faae.png?1615528400',
            'coingeckoId': 'aave-usdt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WPC',
            'name': 'WePiggy Coin',
            'address': '0x6F620EC89B8479e97A6985792d0c64F237566746',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/21914/thumb/WPC200.png?1640262945',
            'coingeckoId': 'wepiggy-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POP',
            'name': 'Popcorn',
            'address': '0x6F0fecBC276de8fC69257065fE47C5a03d986394',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/POP/logo.svg',
            'coingeckoId': 'popcorn',
            'listedIn': [
                'coingecko',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'WBTC',
            'name': 'Wrapped Bitcoin',
            'address': '0x68f180fcCe6836688e9084f035309E29Bf0A2095',
            'decimals': 8,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/7598/small/wrapped_bitcoin_wbtc.png?1548822744',
            'coingeckoId': 'wrapped-bitcoin',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'optimism',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'FXS',
            'name': 'Frax Share',
            'address': '0x67CCEA5bb16181E7b4109c9c2143c24a1c2205Be',
            'decimals': 18,
            'chainId': 10,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'veKWENTA',
            'name': 'veKwenta',
            'address': '0x678d8f4Ba8DFE6bad51796351824DcCECeAefF2B',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/veKWENTA/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'SHACK',
            'name': 'Shack',
            'address': '0x66e8617d1Df7ab523a316a6c01D16Aa5beD93681',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/25699/thumb/shack_no_bg_no_pad3.png?1653441433',
            'coingeckoId': 'shack',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'OCEAN',
            'name': 'Ocean Token',
            'address': '0x667Fd83E24Ca1D935d36717D305D54fA0CAC991C',
            'decimals': 18,
            'chainId': 10,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ENS',
            'name': 'Ethereum Name Service',
            'address': '0x65559aA14915a70190438eF90104769e5E890A00',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/ENS/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'AUSDC',
            'name': 'Aave USDC',
            'address': '0x625E7708f30cA75bfd92586e17077590C60eb4cD',
            'decimals': 6,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/14318/thumb/aUSDC.e260d492.png?1615527797',
            'coingeckoId': 'aave-usdc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AELIN',
            'name': 'Aelin',
            'address': '0x61BAADcF22d2565B0F471b291C475db5555e0b76',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://tokens.1inch.io/0x61baadcf22d2565b0f471b291c475db5555e0b76.png',
            'coingeckoId': 'aelin',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'MOM',
            'name': 'Monetum',
            'address': '0x5e70AfFE232e2919792f77EB94e566db0320fa88',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/MOM/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'UNIDX',
            'name': 'UniDex',
            'address': '0x5d47bAbA0d66083C52009271faF3F50DCc01023C',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/13178/thumb/unidx.png?1634888975',
            'coingeckoId': 'unidex',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'SYN',
            'name': 'Synapse',
            'address': '0x5A5fFf6F753d7C11A56A52FE47a177a87e431655',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/18024/thumb/synapse_social_icon.png?1663921797',
            'coingeckoId': 'synapse-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LYRA',
            'name': 'Lyra Finance',
            'address': '0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://tokens.1inch.io/0x50c5725949a6f0c72e6c4a641f24049a917db0cb.png',
            'coingeckoId': 'lyra-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'HAN',
            'name': 'HanChain',
            'address': '0x50Bce64397C75488465253c0A034b8097FeA6578',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/HAN/logo.svg',
            'coingeckoId': 'hanchain',
            'listedIn': [
                'coingecko',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'BITANT',
            'name': 'BitANT',
            'address': '0x5029C236320b8f15eF0a657054B84d90bfBEDED3',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/BitANT/logo.png',
            'coingeckoId': 'bitant',
            'listedIn': [
                'coingecko',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'ETHx',
            'name': 'Super ETH',
            'address': '0x4ac8bD1bDaE47beeF2D1c6Aa62229509b962Aa0d',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://static.debank.com/image/op_token/logo_url/op/d61441782d4a08a7479d54aea211679e.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BIFI',
            'name': 'beefy.finance',
            'address': '0x4E720DD3Ac5CFe1e1fbDE4935f386Bb1C66F4642',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://tokens.1inch.io/0x4e720dd3ac5cfe1e1fbde4935f386bb1c66f4642.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'KNC',
            'name': 'Kyber Network Crystal v2',
            'address': '0x4518231a8FDF6ac553B9BBD51Bbb86825B583263',
            'decimals': 18,
            'chainId': 10,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'EVO',
            'name': 'EVO',
            'address': '0x42006Ab57701251B580bDFc24778C43c9ff589A1',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/25088/large/evoToken.png?1650269135',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'RouterProtocol'
            ]
        },
        {
            'symbol': 'OP',
            'name': 'Optimism',
            'address': '0x4200000000000000000000000000000000000042',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://tokens.1inch.io/0x4200000000000000000000000000000000000042.png',
            'coingeckoId': 'optimism',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'optimism',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'WETH',
            'name': 'WETH',
            'address': '0x4200000000000000000000000000000000000006',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://tokens.1inch.io/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2.png',
            'coingeckoId': 'weth',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'optimism',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BOND',
            'name': 'BarnBridge',
            'address': '0x3e7eF8f50246f725885102E8238CBba33F276747',
            'decimals': 17,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/BOND/logo.svg',
            'coingeckoId': 'barnbridge',
            'listedIn': [
                'coingecko',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'VELO',
            'name': 'Velodrome Finance',
            'address': '0x3c8B650257cFb5f272f799F5e2b4e65093a11a05',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://tokens.1inch.io/0x3c8b650257cfb5f272f799f5e2b4e65093a11a05.png',
            'coingeckoId': 'velodrome-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'LIZ',
            'name': 'Theranos Coin',
            'address': '0x3bB4445D30AC020a84c1b5A8A2C6248ebC9779D0',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/LIZ/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'QI',
            'name': 'Qi Dao',
            'address': '0x3F56e0c36d275367b8C502090EDF38289b3dEa0d',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/15329/thumb/qi.png?1620540969',
            'coingeckoId': 'qi-dao',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ALETH',
            'name': 'Alchemix ETH',
            'address': '0x3E29D3A9316dAB217754d13b28646B76607c5f04',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/16271/thumb/uS04dyYy_400x400.jpeg?1663051098',
            'coingeckoId': 'alchemix-eth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OATH',
            'name': 'Oath Token',
            'address': '0x39FdE572a18448F8139b7788099F0a0740f51205',
            'decimals': 18,
            'chainId': 10,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'POOL',
            'name': 'PoolTogether',
            'address': '0x395Ae52bB17aef68C2888d941736A71dC6d4e125',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/POOL/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'TAROT',
            'name': 'Tarot',
            'address': '0x375488F097176507e39B9653b88FDc52cDE736Bf',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/17881/thumb/tarot-200px.png?1629704943',
            'coingeckoId': 'tarot',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'PREMIA',
            'name': 'Premia',
            'address': '0x374Ad0f47F4ca39c78E5Cc54f1C9e426FF8f231A',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/PREMIA/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'LINK',
            'name': 'Chainlink',
            'address': '0x350a791Bfc2C21F9Ed5d10980Dad2e2638ffa7f6',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://tokens.1inch.io/0x350a791bfc2c21f9ed5d10980dad2e2638ffa7f6.png',
            'coingeckoId': 'chainlink',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'MASK',
            'name': 'Mask Network',
            'address': '0x3390108E913824B8eaD638444cc52B9aBdF63798',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/MASK/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'FRAX',
            'name': 'Frax',
            'address': '0x2E3D870790dC77A83DD1d18184Acc7439A53f475',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1656664682747_6323500197263012.png',
            'coingeckoId': 'frax',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'BANK',
            'name': 'Bankless Token',
            'address': '0x29FAF5905bfF9Cfcc7CF56a5ed91E0f091F8664B',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/BANK/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'sBTC',
            'name': 'Synth sBTC',
            'address': '0x298B9B95708152ff6968aafd889c6586e9169f1D',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/sBTC/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'STG',
            'name': 'Stargate Finance',
            'address': '0x296F55F8Fb28E498B858d0BcDA06D955B2Cb3f97',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://tokens.1inch.io/0x296f55f8fb28e498b858d0bcda06d955b2cb3f97.png',
            'coingeckoId': 'stargate-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'FS',
            'name': 'FantomStarter',
            'address': '0x22648C12acD87912EA1710357B1302c6a4154Ebc',
            'decimals': 18,
            'chainId': 10,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'mooVelodromesUSD-USDC',
            'name': 'Moo Velodrome sUSD-USDC',
            'address': '0x2232455bf4622002c1416153EE59fd32B239863B',
            'decimals': 18,
            'chainId': 10,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'THALES',
            'name': 'Thales',
            'address': '0x217D47011b23BB961eB6D93cA9945B7501a5BB11',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://tokens.1inch.io/0x217d47011b23bb961eb6d93ca9945b7501a5bb11.png',
            'coingeckoId': 'thales',
            'listedIn': [
                'coingecko',
                '1inch',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'DCN',
            'name': 'Dentacoin',
            'address': '0x1da650C3B2DaA8AA9Ff6F661d4156Ce24d08A062',
            'decimals': 0,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/DCN/logo.svg',
            'coingeckoId': 'dentacoin',
            'listedIn': [
                'coingecko',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'WSTETH',
            'name': 'Wrapped stETH',
            'address': '0x1F32b1c2345538c0c6f582fCB022739c4A194Ebb',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://tokens.1inch.io/0x1f32b1c2345538c0c6f582fcb022739c4a194ebb.png',
            'coingeckoId': 'wrapped-steth',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'GTC',
            'name': 'Gitcoin',
            'address': '0x1EBA7a6a72c894026Cd654AC5CDCF83A46445B08',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/GTC/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'SONNE',
            'name': 'Sonne Finance',
            'address': '0x1DB2466d9F5e10D7090E7152B68d62703a2245F0',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/27540/thumb/Token1.png?1664422231',
            'coingeckoId': 'sonne-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'alETH',
            'name': 'Multichain alETH',
            'address': '0x1CcCA1cE62c62F7Be95d4A67722a8fDbed6EEcb4',
            'decimals': 18,
            'chainId': 10,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ALINK',
            'name': 'Aave LINK',
            'address': '0x191c10Aa4AF7C30e871E70C95dB0E4eb77237530',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/14315/thumb/aLINK.412c6589.png?1615527827',
            'coingeckoId': 'aave-link',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KITTY',
            'name': 'KITTY',
            'address': '0x165DBb08de0476271714952C3C1F068693bd60D7',
            'decimals': 18,
            'chainId': 10,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'GYSR',
            'name': 'Geyser',
            'address': '0x117cFd9060525452db4A34d51c0b3b7599087f05',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/GYSR/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'mooCurveFsUSD',
            'name': 'Moo Curve f-sUSD',
            'address': '0x107Dbf9c9C0EF2Df114159e5C7DC2baf7C444cFF',
            'decimals': 18,
            'chainId': 10,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'HND',
            'name': 'Hundred Finance',
            'address': '0x10010078a54396F62c96dF8532dc2B4847d47ED3',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/18445/thumb/hnd.PNG?1632054703',
            'coingeckoId': 'hundred-finance',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'PICKLE',
            'name': 'PickleToken',
            'address': '0x0c5b4c92c948691EEBf185C17eeB9c230DC019E9',
            'decimals': 18,
            'chainId': 10,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DUCK',
            'name': 'Unit protocol',
            'address': '0x0E49ca6EA763190084C846d3Fc18f28BC2aC689a',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/DUCK/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'MNYE',
            'name': 'Market Neutral Yield ETH',
            'address': '0x0Be27c140f9Bdad3474bEaFf0A413EC7e19e9B93',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/25387/thumb/MNY-E-token-logo.png?1651644528',
            'coingeckoId': 'market-neutral-yield-eth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LPF',
            'name': 'Loopfi',
            'address': '0x0B3e851cf6508A16266BC68A651ea68b31ef673b',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/LPF/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'VALX',
            'name': 'Validator',
            'address': '0x0A9AAa1c7542b42233AC7FfdA364E97adE21f160',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/VALX/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'CRV',
            'name': 'Curve DAO',
            'address': '0x0994206dfE8De6Ec6920FF4D779B0d950605Fb53',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://elk.finance/tokens/logos/optimism/0x0994206dfe8de6ec6920ff4d779b0d950605fb53/logo.png',
            'coingeckoId': 'curve-dao-token',
            'listedIn': [
                'coingecko',
                'optimism',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'AWBTC',
            'name': 'Aave WBTC',
            'address': '0x078f358208685046a11C85e8ad32895DED33A249',
            'decimals': 8,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/14244/thumb/aWBTC.41453c2a.png?1615528721',
            'coingeckoId': 'aave-wbtc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'mooVelodromeMAI-USDC',
            'name': 'Moo Velodrome MAI-USDC',
            'address': '0x01D9cfB8a9D43013a1FdC925640412D8d2D900F0',
            'decimals': 18,
            'chainId': 10,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'IB',
            'name': 'Iron Bank',
            'address': '0x00a35FD824c717879BF370E70AC6868b95870Dfb',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://assets.coingecko.com/coins/images/22902/thumb/ironbank.png?1642872464',
            'coingeckoId': 'iron-bank',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'PAPER',
            'name': 'Paper',
            'address': '0x00F932F0FE257456b32dedA4758922E56A4F4b42',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://ethereum-optimism.github.io/data/PAPER/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'ETH',
            'name': 'Ethereum',
            'address': '0x0000000000000000000000000000000000000000',
            'decimals': 18,
            'chainId': 10,
            'logoURI': 'https://tokens.1inch.io/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee.png',
            'coingeckoId': 'ethereum',
            'listedIn': [
                '1inch',
                'openocean',
                'lifinance',
                'xyfinance'
            ]
        }
    ],
    '137': [
        {
            'symbol': 'B20',
            'name': 'B.20 (PoS)',
            'address': '0xff898089e368eCc47Fc37Fd0D905Fbcf0D70E820',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xff898089e368ecc47fc37fd0d905fbcf0d70e820/logo.png',
            'coingeckoId': 'b20',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BOR',
            'name': 'BoringDao',
            'address': '0xff88434E29d1E2333aD6baa08D358b436196da6b',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0xff88434E29d1E2333aD6baa08D358b436196da6b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'MUSD',
            'name': 'Mad USD',
            'address': '0xfe949DaA7C65a0348e79e5F2Ae2755Df22654Bf2',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MVI',
            'name': 'Metaverse Index',
            'address': '0xfe712251173A2cd5F5bE2B46Bb528328EA3565E1',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xfe712251173a2cd5f5be2b46bb528328ea3565e1.png',
            'coingeckoId': 'metaverse-index',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'CXETH',
            'name': 'CelsiusX Wrapped ETH',
            'address': '0xfe4546feFe124F30788c4Cc1BB9AA6907A7987F9',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xfe4546fefe124f30788c4cc1bb9aa6907a7987f9.png',
            'coingeckoId': 'celsiusx-wrapped-eth',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'idleWETHYield',
            'name': 'IdleWETH [Best yield]',
            'address': '0xfdA25D931258Df948ffecb66b5518299Df6527C4',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ZEE',
            'name': 'ZeroSwap',
            'address': '0xfd4959c06FbCc02250952DAEbf8e0Fb38cF9FD8C',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://bscscan.com/token/images/zeroswapprotocol_32.png',
            'coingeckoId': 'zeroswap',
            'listedIn': [
                'coingecko',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'KOLNET',
            'name': 'KOLNET',
            'address': '0xfd195a17e2a60D248a7148A919c5166AE907479A',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26064/thumb/Icon_color2.png?1655464393',
            'coingeckoId': 'kolnet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BITT',
            'name': 'BITT',
            'address': '0xfd0cbdDec28a93bB86B9db4A62258F5EF25fEfdE',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/13783/thumb/BITT_Logo_256pixels.png?1611733961',
            'coingeckoId': 'bittoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'eUSDT',
            'name': 'Easy USDT',
            'address': '0xfc39742Fe9420a7Af23757Fc7E78D1c3AE4A9474',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xfc39742Fe9420a7Af23757Fc7E78D1c3AE4A9474/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NDR',
            'name': 'NodeRunners',
            'address': '0xfb65ef42F7c8A70ff73F627DB6E9ba2Aff1F20fa',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xfb65ef42F7c8A70ff73F627DB6E9ba2Aff1F20fa/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CPIE',
            'name': 'CremePieSwap',
            'address': '0xfad70FD116559914240faB82b0078c4E82a6a1B8',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xfad70fd116559914240fab82b0078c4e82a6a1b8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MATICX',
            'name': 'Stader MaticX',
            'address': '0xfa68FB4628DFF1028CFEc22b4162FCcd0d45efb6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xfa68fb4628dff1028cfec22b4162fccd0d45efb6.png',
            'coingeckoId': 'stader-maticx',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'LITHIUM',
            'name': 'Lithium',
            'address': '0xfE1a200637464FBC9B60Bc7AeCb9b86c0E1d486E',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17207/thumb/B4XYNU5.png?1626851250',
            'coingeckoId': 'lithium-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BFORM',
            'name': 'Blatform',
            'address': '0xfE1DfcCe53bC2A7d93b4f02587DB372a9B7A0172',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26040/thumb/blatform3_32.png?1655364337',
            'coingeckoId': 'blatform',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RUSD',
            'name': 'rUSD',
            'address': '0xfC40a4F89b410a1b855b5e205064a38fC29F5eb5',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/16184/small/rUSD-Logo-200.png?1623231145',
            'coingeckoId': 'rusd',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'sdeursCRV',
            'name': 'Stake DAO EUR',
            'address': '0xfBdb45075Fb73ca4CC8b83FD6fdB4F9B696B1BA1',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xfBdb45075Fb73ca4CC8b83FD6fdB4F9B696B1BA1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'INDEX',
            'name': 'Index Cooperative',
            'address': '0xfBd8A3b908e764dBcD51e27992464B4432A1132b',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/12729/thumb/index.png?1634894321',
            'coingeckoId': 'index-cooperative',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADD',
            'name': 'ADD.XYZ (PoS)',
            'address': '0xfA15611AA3775FBfE1375Fb56cd3027F5D3DD2Db',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xfa15611aa3775fbfe1375fb56cd3027f5d3dd2db/logo.png',
            'coingeckoId': 'add-xyz-new',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DHC',
            'name': 'DeltaHub Community',
            'address': '0xf9c1F70f9bF57FAD5f63c6E1E25C2e895f04c0A6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/12656/thumb/DHC_Transparent_200x200.png?1601440150',
            'coingeckoId': 'deltahub-community',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MARK',
            'name': 'Mark',
            'address': '0xf99FC9f2D4B9F6cc4d6e56B13C5Ae7E0030FB406',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'IBEX',
            'name': 'Impermax',
            'address': '0xf972DACEd7C6b03223710C11413036D17eB298f6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27606/thumb/IqwOmX-c_400x400.jpeg?1664846656',
            'coingeckoId': 'impermax-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SYN',
            'name': 'Synapse',
            'address': '0xf8F9efC0db77d8881500bb06FF5D6ABc3070E695',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1659091490088_7069071853576678.jpg',
            'coingeckoId': 'synapse-2',
            'listedIn': [
                'coingecko',
                'openocean'
            ]
        },
        {
            'symbol': 'SOLAR',
            'name': 'Dawn Star Share',
            'address': '0xf8Eed914a0BAcAF30C13420989bB7C81b75D833A',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://storage.googleapis.com/xy-finance-images/token-list/Solar.png',
            'coingeckoId': 'dawn-star-share',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': '$TRDL',
            'name': 'Strudel Finance',
            'address': '0xf89887862538cE8eFBdbAdD5d483AE4A09dD9688',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xf89887862538cE8eFBdbAdD5d483AE4A09dD9688/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NFTP',
            'name': 'NFT Platform Index',
            'address': '0xf7d9e281c5Cb4C6796284C5b663b3593D2037aF2',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xf7d9e281c5Cb4C6796284C5b663b3593D2037aF2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'OCAVU',
            'name': 'Ocavu Network',
            'address': '0xf796969fA47Fb0748c80b8B153cBb895E88cBD54',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25781/thumb/ocavu.png?1653736917',
            'coingeckoId': 'ocavu-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FCD',
            'name': 'FreshCut Diamond',
            'address': '0xf78610D0a197842bF98ca45254897edd13c5D182',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24570/thumb/FCD_small_200.png?1648193725',
            'coingeckoId': 'freshcut-diamond',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YAYO',
            'name': 'Yayo Coin',
            'address': '0xf7058856f405542cd660e8ce4751248F2d037f2B',
            'decimals': 4,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/16008/thumb/84341687.png?1622598816',
            'coingeckoId': 'yayo-coin',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'Cu',
            'name': 'Copper',
            'address': '0xf6351c1163314e95d025575c5CeA314b055b7ED3',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/Cu.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WSTA',
            'name': 'Wrapped Statera',
            'address': '0xf629712180bEF6F4c569B704e03d0AcbE276Eb6d',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/13599/thumb/wsta_logo.png?1610011862',
            'coingeckoId': 'wrapped-statera',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPADE',
            'name': 'PolygonFarm Finance',
            'address': '0xf5EA626334037a2cf0155D49eA6462fDdC6Eff19',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0xf5EA626334037a2cf0155D49eA6462fDdC6Eff19/logo.png',
            'coingeckoId': 'polygonfarm-finance',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'INST',
            'name': 'Instadapp',
            'address': '0xf50D05A1402d0adAfA880D36050736f9f6ee7dee',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xf50d05a1402d0adafa880d36050736f9f6ee7dee.png',
            'coingeckoId': 'instadapp',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BLANK',
            'name': 'BlockWallet',
            'address': '0xf4C83080E80AE530d6f8180572cBbf1Ac9D5d435',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xf4c83080e80ae530d6f8180572cbbf1ac9d5d435.png',
            'coingeckoId': 'blank',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'VP',
            'name': 'Vortex Protocol',
            'address': '0xf46CB10e8c5FB9368bbF497A3176b80c0AF66d44',
            'decimals': 11,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/28122/thumb/vp.png?1667636113',
            'coingeckoId': 'vortex-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CBL',
            'name': 'CherryBlossom (PoS)',
            'address': '0xf35a85C493c49f19bCC176E718865b148958a130',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xf35a85c493c49f19bcc176e718865b148958a130/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MEME',
            'name': 'MEME',
            'address': '0xf2b5a8c37278bcdD50727D5CA879f8e5A4642e2e',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xf2b5a8c37278bcdD50727D5CA879f8e5A4642e2e/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CXO',
            'name': 'CargoX',
            'address': '0xf2ae0038696774d65E67892c9D301C5f2CbbDa58',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xf2ae0038696774d65E67892c9D301C5f2CbbDa58/logo.png',
            'coingeckoId': 'cargox',
            'listedIn': [
                'coingecko',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WCRO',
            'name': 'Wrapped CRO',
            'address': '0xf2D8124b8F9267DaD61351c7aD252362880C6638',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/7310/small/cypto.png?1547043960',
            'coingeckoId': null,
            'listedIn': [
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'MATIC2X-FLI-P',
            'name': 'Index Coop   MATIC 2x Flexible Leverage',
            'address': '0xf287D97B6345bad3D88856b26Fb7c0ab3F2C7976',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/23388/thumb/MATIC2x-FLI.png?1644061428',
            'coingeckoId': 'index-coop-matic-2x-flexible-leverage-index',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DQUICK',
            'name': 'Dragon s Quick',
            'address': '0xf28164A485B0B2C90639E47b0f377b4a438a16B1',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15185/thumb/quickswap.png?1620044811',
            'coingeckoId': 'dragons-quick',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IbAlluoBTC',
            'name': 'Interest Bearing Alluo BTC',
            'address': '0xf272Ff86c86529504f0d074b210e95fc4cFCDce2',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'VSN',
            'name': 'Vision',
            'address': '0xf24fD75c5BF897F272aE79A878089b12dE5f1d62',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'UART',
            'name': 'UniArts',
            'address': '0xf244E91A46A9cdd48da295cA5d0B27894f8032B1',
            'decimals': 12,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/22092/thumb/3Rx82r_w_400x400.png?1640823145',
            'coingeckoId': 'uniarts',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VEE',
            'name': 'BLOCKv',
            'address': '0xf1c1A3C2481A3a8A3f173A9ab5Ade275292A6fA3',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/1266/thumb/blockv.png?1547351380',
            'coingeckoId': 'blockv',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHZ',
            'name': 'CHZ (PoS)',
            'address': '0xf1938Ce12400f9a761084E7A80d37e732a4dA056',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xf1938ce12400f9a761084e7a80d37e732a4da056/logo.png',
            'coingeckoId': 'chiliz',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'XMARK',
            'name': 'xMARK',
            'address': '0xf153EfF70DC0bf3b085134928daeEA248d9B30d0',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xf153EfF70DC0bf3b085134928daeEA248d9B30d0/logo.png',
            'coingeckoId': 'xmark',
            'listedIn': [
                'coingecko',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GEO',
            'name': 'Geopoly',
            'address': '0xf1428850f92B87e629c6f3A3B75BffBC496F7Ba6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xf1428850f92b87e629c6f3a3b75bffbc496f7ba6.png',
            'coingeckoId': 'geopoly',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'RVLT',
            'name': 'Revolt 2 Earn',
            'address': '0xf0f9D895aCa5c8678f706FB8216fa22957685A13',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/19893.png',
            'coingeckoId': 'revolt-2-earn',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ULT',
            'name': 'Shardus',
            'address': '0xf0059CC2b3E980065A906940fbce5f9Db7ae40A7',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/8383/thumb/final_logo_photoshop.png?1557890272',
            'coingeckoId': 'shardus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RBAG',
            'name': 'RebalanceGovernance Token',
            'address': '0xefFCa2dBB2D9F3816ceE07aE2134574FA9210dcb',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xefFCa2dBB2D9F3816ceE07aE2134574FA9210dcb/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DG',
            'name': 'Decentral Games',
            'address': '0xef938b6da8576a896f6E0321ef80996F4890f9c4',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xef938b6da8576a896f6e0321ef80996f4890f9c4.png',
            'coingeckoId': 'decentral-games',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'MCHC',
            'name': 'MCH Coin',
            'address': '0xee7666aACAEFaa6efeeF62ea40176d3eB21953B9',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15399/small/MCHC.jpg?1620721307',
            'coingeckoId': 'mch-coin',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'TAZOR',
            'name': 'Tazor',
            'address': '0xee65D8B88F86ace0f7bA42BA2d2C679b6f604bf0',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27261/thumb/tazor.png?1663554021',
            'coingeckoId': 'tazor',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TNODE',
            'name': 'Trusted Node',
            'address': '0xed3D0319C0a04b02e856FfAF6eA140D5CfaC2E73',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'VAB',
            'name': 'Vabble',
            'address': '0xed28B1890fBB4aA9ded528c1034fED278ff68f5d',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17508/thumb/vabble_tplogo_200_x_200.png?1628508875',
            'coingeckoId': 'vabble',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'CRTS',
            'name': 'Cryptotipsfr Token V2 (PoS)',
            'address': '0xebf9B87583C284F0a1b7aF72371F84D2A7567285',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xebf9b87583c284f0a1b7af72371f84d2a7567285/logo.png',
            'coingeckoId': 'cryptotipsfr',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'mooMaiUSDC-miMATIC',
            'name': 'Moo Mai USDC-miMATIC',
            'address': '0xebe0c8d842AA5A57D7BEf8e524dEabA676F91cD1',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'pZUG',
            'name': 'pZUG',
            'address': '0xeb45921FEDaDF41dF0BfCF5c33453aCedDA32441',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0xeb45921FEDaDF41dF0BfCF5c33453aCedDA32441/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'PZAP',
            'name': 'PolyZap',
            'address': '0xeb2778f74E5ee038E67AA6c77f0F0451ABd748FD',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15623/thumb/polyzap.png?1621349109',
            'coingeckoId': 'polyzap',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'NZDS',
            'name': 'NZD Stablecoin',
            'address': '0xeaFE31Cd9e8E01C8f0073A2C974f728Fb80e9DcE',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/20394/thumb/nzds.png?1636964381',
            'coingeckoId': 'nzd-stablecoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DERC20',
            'name': 'Dummy ERC20',
            'address': '0xeFfdCB49C2D0EF813764B709Ca3c6fe71f230E3e',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xeffdcb49c2d0ef813764b709ca3c6fe71f230e3e/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PolyMoon',
            'name': 'PolyMoon',
            'address': '0xeFb3009DdAc87E8144803d78E235E7fb4cd36e61',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://ethapi.openocean.finance/logos/polygon/0xefb3009ddac87e8144803d78e235e7fb4cd36e61.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ELK',
            'name': 'Elk Finance',
            'address': '0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xeeeeeb57642040be42185f49c52f7e9b38f8eeee.png',
            'coingeckoId': 'elk-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DOG',
            'name': 'The Doge NFT',
            'address': '0xeEe3371B89FC43Ea970E908536Fcddd975135D8a',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18111/thumb/Doge.png?1630696110',
            'coingeckoId': 'the-doge-nft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BED',
            'name': 'Bankless BED Index',
            'address': '0xeEDa694439C6FB56CbAA011cC849650b7273285B',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17175/thumb/BED_Logo_-_No_border.png?1626833695',
            'coingeckoId': 'bankless-bed-index',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAHA',
            'name': 'MahaDAO',
            'address': '0xeDd6cA8A4202d4a36611e2fff109648c4863ae19',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/13404/small/MAHA_Token.png?1625651604',
            'coingeckoId': 'mahadao',
            'listedIn': [
                'coingecko',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'CFI',
            'name': 'CyberFi',
            'address': '0xeCf8f2FA183b1C4d2A269BF98A54fCe86C812d3e',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xeCf8f2FA183b1C4d2A269BF98A54fCe86C812d3e/logo.png',
            'coingeckoId': 'cyberfi',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BNB',
            'name': 'Binance Coin  Wormhole ',
            'address': '0xeCDCB5B88F8e3C15f95c720C51c71c9E2080525d',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/825/small/bnb-icon2_2x.png?1644979850',
            'coingeckoId': 'binance-coin-wormhole',
            'listedIn': [
                'coingecko',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'TORG',
            'name': 'TORG',
            'address': '0xe9993763E0B7f7d915A62A5f22A6E151F91d98A8',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17596/thumb/TORG_Logo_200x200.png?1628586056',
            'coingeckoId': 'torg',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUILD',
            'name': 'BUILD',
            'address': '0xe94845Ac6782A2E71C407ABe4D5201445C26a62B',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26533/thumb/BUILD.png?1658714842',
            'coingeckoId': 'build',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'DMG',
            'name': 'DAOMERGE',
            'address': '0xe8A978336bD8B2c29E41B2d4ba916441f8cDc5Db',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ZUT',
            'name': 'Zero Utility',
            'address': '0xe86E8beb7340659DDDCE61727E500e3A5aD75a90',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xe86E8beb7340659DDDCE61727E500e3A5aD75a90/logo.png',
            'coingeckoId': 'zero-utility-token',
            'listedIn': [
                'coingecko',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'VRJ',
            'name': 'VRJAM',
            'address': '0xe84c2Edca71fC3cc570c70665178d90C8721623b',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/28049/thumb/VRJAM_coin_logo_%28gradient_on_white%29.png?1667279615',
            'coingeckoId': 'vrjam',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MSU',
            'name': 'MetaSoccer',
            'address': '0xe8377A076adAbb3F9838afB77Bee96Eac101ffB1',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xe8377a076adabb3f9838afb77bee96eac101ffb1.png',
            'coingeckoId': 'metasoccer',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'PLOT',
            'name': 'PlotX',
            'address': '0xe82808eaA78339b06a691fd92E1Be79671cAd8D3',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x72f020f8f3e8fd9382705723cd26380f8d0c66bb.png',
            'coingeckoId': 'plotx',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BLUE',
            'name': 'Ethereum Blue (PoS)',
            'address': '0xe7f960685aba7327ADA3b161Ea252703C87be0bF',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xe7f960685aba7327ada3b161ea252703c87be0bf/logo.png',
            'coingeckoId': 'blue',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'IGG',
            'name': 'IG Gold',
            'address': '0xe6FC6C7CB6d2c31b359A49A33eF08aB87F4dE7CE',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xe6fc6c7cb6d2c31b359a49a33ef08ab87f4de7ce.png',
            'coingeckoId': 'ig-gold',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'dfyn',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PAY',
            'name': 'PayBolt',
            'address': '0xe580074A10360404AF3ABfe2d524D5806D993ea3',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24175/thumb/logo.png?1646748269',
            'coingeckoId': 'paybolt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AWETH',
            'name': 'Aave WETH',
            'address': '0xe50fA9b3c56FfB159cB0FCA61F5c9D750e8128c8',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17238/thumb/aWETH_2x.png?1626940782',
            'coingeckoId': 'aave-weth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DODO',
            'name': 'DODO',
            'address': '0xe4Bf2864ebeC7B7fDf6Eeca9BaCAe7cDfDAffe78',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/12651/thumb/dodo_logo.png?1601433025',
            'coingeckoId': 'dodo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOOV',
            'name': 'dotmoovs',
            'address': '0xe46F5128B933e5A6F907Fe73EcE80059c222DB0A',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15817/thumb/dotmoovs-symbol-gradient.png?1635332626',
            'coingeckoId': 'dotmoovs',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PFI',
            'name': 'PartyFi',
            'address': '0xe46B4A950c389e80621d10Dfc398e91613C7e25E',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27548/thumb/GU_yPtme_400x400.jpeg?1664442276',
            'coingeckoId': 'partyfi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RBLS',
            'name': 'Rebel Bots',
            'address': '0xe26cda27c13f4f87cFFc2F437C5900b27eBb5bbB',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24094/thumb/logo-footer-356.88b37f33.png?1646364881',
            'coingeckoId': 'rebel-bots',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'sdcrvRenWSBTC',
            'name': 'Stake DAO BTC',
            'address': '0xe212f92E5Af85268B33D2Aa587B51f49C3B945Be',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xe212f92E5Af85268B33D2Aa587B51f49C3B945Be/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MAYFI',
            'name': 'Matic Aave Interest Bearing YFI',
            'address': '0xe20f7d1f0eC39C4d5DB01f53554F2EF54c71f613',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xe20f7d1f0eC39C4d5DB01f53554F2EF54c71f613/logo.png',
            'coingeckoId': 'matic-aave-yfi',
            'listedIn': [
                'coingecko',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BIOS',
            'name': '0x nodes',
            'address': '0xe20D2Df5041f8Ed06976846470f727295cDd4D23',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15600/small/BIOS_01.png?1621737736',
            'coingeckoId': 'bios',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BAN',
            'name': 'Banano',
            'address': '0xe20B9e246db5a0d21BF9209E4858Bc9A3ff7A034',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0xe20B9e246db5a0d21BF9209E4858Bc9A3ff7A034/logo.png',
            'coingeckoId': 'banano',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'QIx',
            'name': 'Superfluid QI',
            'address': '0xe1cA10e6a10c0F72B74dF6b7339912BaBfB1f8B5',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x580a84c73811e1839f75d86d75d88cca0c241ff4/be77e8e47016b1e9d3f01b7edc9b8d4f.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'StIbAlluoEUR',
            'name': 'Superfluid IbAlluoEUR',
            'address': '0xe199f1B01Dd3e8a1C43B62279FEb20547a2EB3eF',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'RODO',
            'name': 'RODO',
            'address': '0xe1398B5d2f3CEF77a13a7CcBae33F2121c217301',
            'decimals': 2,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FIRE',
            'name': 'FireBall',
            'address': '0xe118e8b6dc166CD83695825eB1d30e792435Bb00',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15644/thumb/fireball.png?1621413326',
            'coingeckoId': 'fireball-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALTA',
            'name': 'Alta Finance',
            'address': '0xe0cCa86B254005889aC3a81e737f56a14f4A38F5',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18713/thumb/AFN-token-Altafin-200.png?1633079552',
            'coingeckoId': 'alta-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FACTR',
            'name': 'Defactor',
            'address': '0xe0bCEEF36F3a6eFDd5EEBFACD591423f8549B9D5',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/19201/thumb/jFLSu4U9_400x400.png?1650437050',
            'coingeckoId': 'defactor',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PROPEL',
            'name': 'Propel',
            'address': '0xe0Ce60AF0850bF54072635e66E79Df17082A1109',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0xe0ce60af0850bf54072635e66e79df17082a1109/logo.png',
            'coingeckoId': 'payrue',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'POLYBABYDOGE',
            'name': 'Polygon BabyDoge',
            'address': '0xdf2140DEe6B07ae495382bc1cd446F7B328F63e3',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/16937/thumb/WMpWi7ub4A1FUu8OZBMgBbn5gexHP0b0abL98yQz_rMdF4FWjwwQa7t4P8ktfWZMrJokFdH7MeYjOWqVf5qBsnInzL2CEl8jhgYS_Sxs0bLh07tUOWAMyYyGfae3EzadIBRSFHZ1P368gDOC7q6cLkNyJGMf2rgE_K2ZV7YWWPjT6bvLF9UX28h1Gup3BhtSOYzRCvaAUpUB.jpg?1627567492',
            'coingeckoId': 'polygon-babydoge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CERBY',
            'name': 'Cerby Token',
            'address': '0xdef1fac7Bf08f173D286BbBDcBeeADe695129840',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0xdef1fac7Bf08f173D286BbBDcBeeADe695129840/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'XIV',
            'name': 'Planet Inverse',
            'address': '0xde799636aF0d8D65a17AAa83b66cBBE9B185EB01',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14231/thumb/photo_2021-06-07_22-10-41.jpg?1623075271',
            'coingeckoId': 'project-inverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INRP',
            'name': 'Rupeeto',
            'address': '0xde485931674F4EdD3Ed3bf22e86E7d3C7D5347a1',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://cdn.rupeeto.com/images/rupeeto-symbol.jpeg',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'EDAT',
            'name': 'EnviDa',
            'address': '0xdd9bA3B2571BEA0854beb0508CE10FeD0eCa7e3e',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/20342/thumb/logo1.png?1636941786',
            'coingeckoId': 'envida',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HOGE',
            'name': 'hoge.finance (PoS)',
            'address': '0xdd539b42dadDc0Ca55fb3aC2260240E61414d92B',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xdd539b42daddc0ca55fb3ac2260240e61414d92b/logo.png',
            'coingeckoId': 'hoge-finance',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'mWETH',
            'name': 'Mushrooming WETH',
            'address': '0xdc0f3C12dF89593357985491bdAcc5Fd789cA4B2',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xdc0f3C12dF89593357985491bdAcc5Fd789cA4B2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GET',
            'name': 'GET Protocol',
            'address': '0xdb725f82818De83e99F1dAc22A9b5B51d3d04DD4',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xdb725f82818de83e99f1dac22a9b5b51d3d04dd4.png',
            'coingeckoId': 'get-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'CNTR',
            'name': 'Centaur',
            'address': '0xdae89dA41a96956e9e70320Ac9c0dd077070D3a5',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/12743/small/logo_%2898%29.png?1602630445',
            'coingeckoId': 'centaur',
            'listedIn': [
                'coingecko',
                'quickswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'IRIS',
            'name': 'Iris',
            'address': '0xdaB35042e63E93Cc8556c9bAE482E5415B5Ac4B1',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xdab35042e63e93cc8556c9bae482e5415b5ac4b1.png',
            'coingeckoId': 'iris-token-2',
            'listedIn': [
                'coingecko',
                '1inch',
                'dfyn',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'PRCY',
            'name': 'PRivaCY Coin',
            'address': '0xdFC3829b127761a3218bFceE7fc92e1232c9D116',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14151/thumb/prcy.png?1614666991',
            'coingeckoId': 'prcy-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TEL',
            'name': 'Telcoin',
            'address': '0xdF7837DE1F2Fa4631D716CF2502f8b230F1dcc32',
            'decimals': 2,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x467bccd9d29f223bce8043b84e8c8b282827790f.png',
            'coingeckoId': 'telcoin',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'quickswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ADX',
            'name': 'Ambire AdEx',
            'address': '0xdDa7b23D2D72746663E7939743f929a3d85FC975',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/847/thumb/Ambire_AdEx_Symbol_color.png?1655432540',
            'coingeckoId': 'adex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOGIRA',
            'name': 'Dogira',
            'address': '0xdDa40cdfe4A0090f42Ff49f264A831402ADB801A',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xdda40cdfe4a0090f42ff49f264a831402adb801a.png',
            'coingeckoId': 'dogira',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'GOGO',
            'name': 'GOGOcoin',
            'address': '0xdD2AF2E723547088D3846841fbDcC6A8093313d6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xdd2af2e723547088d3846841fbdcc6a8093313d6.png',
            'coingeckoId': 'gogocoin',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'TLC',
            'name': 'TLChain',
            'address': '0xdC7858415244B20c903E0cd857aC58b7225f046F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/28177/thumb/tlc.png?1668250171',
            'coingeckoId': 'tlchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POLAR',
            'name': 'Polaris Token',
            'address': '0xdC3aCB92712D1D44fFE15d3A8D66d9d18C81e038',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0xdC3aCB92712D1D44fFE15d3A8D66d9d18C81e038/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BUSD',
            'name': 'Binance USD',
            'address': '0xdAb529f40E671A1D4bF91361c21bf9f0C9712ab7',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/9576/thumb/BUSD.png?1568947766',
            'coingeckoId': 'binance-usd',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'PCAKE',
            'name': 'PolyCake Finance',
            'address': '0xd9a2C5C0Fb2F138C2B96582d29A648DF70F80465',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15783/thumb/0S_K8CFb_400x400.jpg?1621844145',
            'coingeckoId': 'polycake-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EWT',
            'name': 'Ecowatt',
            'address': '0xd9E838dd60c8ea1e7dD4E670913323bB87DB112c',
            'decimals': 4,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24407/thumb/28382.jpeg?1647530872',
            'coingeckoId': 'ecowatt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FRM',
            'name': 'Ferrum Network',
            'address': '0xd99baFe5031cC8B345cb2e8c80135991F12D7130',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xd99bafe5031cc8b345cb2e8c80135991f12d7130.png',
            'coingeckoId': 'ferrum-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'ARTx',
            'name': 'Superfluid ART',
            'address': '0xd98A3EaBB78a759868d5a35677fFA99A78F33Ec7',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SOL',
            'name': 'SOL  Wormhole ',
            'address': '0xd93f7E271cB87c23AaA73edC008A79646d1F9912',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/22876/thumb/SOL_wh_small.png?1644224316',
            'coingeckoId': 'sol-wormhole',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'mBONK',
            'name': 'MegaBONK',
            'address': '0xd93c61D4418d77A537b6B57478C108E193362f0C',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xd93c61D4418d77A537b6B57478C108E193362f0C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GOHM',
            'name': 'Governance OHM',
            'address': '0xd8cA34fd379d9ca3C6Ee3b3905678320F5b45195',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xd8ca34fd379d9ca3c6ee3b3905678320f5b45195.png',
            'coingeckoId': 'governance-ohm',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BXR',
            'name': 'Blockster',
            'address': '0xd86eEE98E7D6726A1701f004f747e5Ce3E48e87c',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'RAVEN',
            'name': 'RavenToken',
            'address': '0xd86D318F5241EcAe47D6Df5a9e2394FE19ccc4f9',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xd86D318F5241EcAe47D6Df5a9e2394FE19ccc4f9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'PBTC',
            'name': 'pTokens BTC',
            'address': '0xd7ecf95Cf7eF5256990BeAf4ac895cD9e64cb947',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xd7ecf95Cf7eF5256990BeAf4ac895cD9e64cb947/logo.png',
            'coingeckoId': 'ptokens-btc-2',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'POT',
            'name': 'Potcoin',
            'address': '0xd7c8469c7eC40f853dA5f651DE81b45aeD47e5aB',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/51/thumb/potcoin.png?1547033701',
            'coingeckoId': 'potcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HOPE',
            'name': 'Firebird.Finance',
            'address': '0xd78C475133731CD54daDCb430F7aAE4F03C1E660',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xd78c475133731cd54dadcb430f7aae4f03c1e660.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'STORJ',
            'name': 'Storj Token',
            'address': '0xd72357dAcA2cF11A5F155b9FF7880E595A3F5792',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xB64ef51C888972c908CFacf59B47C1AfBC0Ab8aC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'uniswap'
            ]
        },
        {
            'symbol': 'IONS',
            'name': 'Lithium Ventures',
            'address': '0xd6d3B4254B4526c3095d8AB00B75f186c56dD72C',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15298/thumb/lithium_ions.jpg?1654845787',
            'coingeckoId': 'lithium-ventures',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTC2X-FLI-P',
            'name': 'BTC 2x Flexible Leverage Index  Polygon',
            'address': '0xd6cA869a4EC9eD2C7E618062Cdc45306d8dBBc14',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24179/thumb/Copy_of_BTC2x-FLI_token_logo.png?1646783823',
            'coingeckoId': 'btc-2x-flexible-leverage-index-polygon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EMON',
            'name': 'Ethermon',
            'address': '0xd6A5aB46ead26f49b03bBB1F9EB1Ad5c1767974a',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://polygonscan.com/token/images/ethermontoken2_32.png',
            'coingeckoId': 'ethermon',
            'listedIn': [
                'coingecko',
                'quickswap',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'KOIN',
            'name': 'Koinos (PoS)',
            'address': '0xd617a689F1B8d3b27D51Cf7789f18bD43d8c1706',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xd617a689f1b8d3b27d51cf7789f18bd43d8c1706/logo.png',
            'coingeckoId': 'koinos',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'COLLAR',
            'name': 'Dog Collar',
            'address': '0xd5fa77A860FeA9cFf31DA91BbF9e0FaEa9538290',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18324/thumb/dcLogo.png?1631532599',
            'coingeckoId': 'dog-collar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PGOV',
            'name': 'PGOV',
            'address': '0xd5d84e75f48E75f01fb2EB6dFD8eA148eE3d0FEb',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0xd5d84e75f48E75f01fb2EB6dFD8eA148eE3d0FEb/logo.png',
            'coingeckoId': 'pgov',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'OSEA',
            'name': 'Omnisea',
            'address': '0xd57f8b6F3e5d1e0ab85118F5E0Dd893A08C43346',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26475/thumb/293837892_407994084681555_3167689470652146992_n.png?1658195766',
            'coingeckoId': 'omnisea',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHAIN',
            'name': 'Chain Games',
            'address': '0xd55fCe7CDaB84d84f2EF3F99816D765a2a94a509',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/12257/thumb/chain-logo-centered-500x500.png?1599617244',
            'coingeckoId': 'chain-games',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'EASYX',
            'name': 'EASYX',
            'address': '0xd4c1E34187EDc72Fb6EBf3c4011dee50CAbF89c2',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xd4c1e34187edc72fb6ebf3c4011dee50cabf89c2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PRGD',
            'name': 'Prosperity Gold',
            'address': '0xd45579dE96a574EFAc915e6718604fF481Bfa47F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26664/thumb/PRGD-Icon-001_%281%29.png?1659498830',
            'coingeckoId': 'prosperity-gold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NDEFI',
            'name': 'Polly DeFi Nest',
            'address': '0xd3f07EA86DDf7BAebEfd49731D7Bbd207FedC53B',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18317/thumb/nDEFI.png?1631516554',
            'coingeckoId': 'polly-defi-nest',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ESW',
            'name': 'EmiSwap',
            'address': '0xd2A2a353D28e4833FAFfC882f6649c9c884a7D8f',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17526/thumb/Emiswap_Logo_light_colour_horizontal.png?1628114900',
            'coingeckoId': 'emiswap',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'DMT',
            'name': 'Dark Matter',
            'address': '0xd28449BB9bB659725aCcAd52947677ccE3719fD7',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xd28449bb9bb659725accad52947677cce3719fd7.png',
            'coingeckoId': 'dark-matter',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MNFT',
            'name': 'Marvelous NFTs',
            'address': '0xd281aF594Cbb99E8469f3591D57A0C72EB725bdB',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/22613/thumb/mnft.png?1642238172',
            'coingeckoId': 'marvelous-nfts',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'xcRMRK',
            'name': 'xcRMRK',
            'address': '0xd225eAD1Ce2554F6CB519894Fc98cFB882566339',
            'decimals': 10,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SUNNY',
            'name': 'Sunny Jaycer Token',
            'address': '0xd1d410dB734D634414594898b39608b27f166AAB',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'VVT',
            'name': 'VersoView',
            'address': '0xd192BAc61522E0Bc13543528173E69c44472c93a',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/13380/thumb/HkfxEtWh_400x400.jpg?1608031723',
            'coingeckoId': 'versoview',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIMIR',
            'name': 'Mimir',
            'address': '0xd1790c5435b9fB7C9444c749ceFE53D40D751eaC',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/19551/thumb/xaq5Xlzg_400x400.jpg?1635401626',
            'coingeckoId': 'mimir-token',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'BLAST',
            'name': 'SafeBLAST',
            'address': '0xd145261DD80BC5388ea8f437D503897060410f1C',
            'decimals': 9,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PKEX',
            'name': 'PolkaEx',
            'address': '0xd13eB71515DC48a8a367D12F844e5737bab415dF',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18616/thumb/1024-1024-02.png?1632698540',
            'coingeckoId': 'polkaex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CGT',
            'name': 'CryptoGamesToken (PoS)',
            'address': '0xd13C829Bf9bf2a097B1E185E897c78448Ed750e6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xd13c829bf9bf2a097b1e185e897c78448ed750e6/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'YELD',
            'name': 'PolyYeld Token',
            'address': '0xd0f3121A190d85dE0AB6131f2bCEcdbfcfB38891',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xd0f3121a190d85de0ab6131f2bcecdbfcfb38891/logo.png',
            'coingeckoId': 'polyyeld-token',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'Ti',
            'name': 'Titanium',
            'address': '0xd0ecD879dC1403697D341c9Db1db51075D2adC69',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/Ti.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'IOEN',
            'name': 'Internet of Energy Network',
            'address': '0xd0e9c8f5Fae381459cf07Ec506C1d2896E8b5df6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/19095/thumb/12799.png?1634422996',
            'coingeckoId': 'internet-of-energy-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SSGTX',
            'name': 'Safeswap SSGTX',
            'address': '0xd0cfd20E8bBDb7621B705a4FD61de2E80C2cD02F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/20769/thumb/GBpj6TpI.png?1638362807',
            'coingeckoId': 'safeswap-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VOXEL',
            'name': 'Voxies',
            'address': '0xd0258a3fD00f38aa8090dfee343f10A9D4d30D3F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xd0258a3fd00f38aa8090dfee343f10a9d4d30d3f.png',
            'coingeckoId': 'voxies',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'quickswap',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'CBOMBER',
            'name': 'CBOMBER',
            'address': '0xcf74Ae52ae2c848387e6cD0048e1eC5a93ee2c66',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27060/thumb/bomb_symbol200.png?1661656686',
            'coingeckoId': 'cbomber',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STBU',
            'name': 'Stobox',
            'address': '0xcf403036bc139d30080D2Cf0F5b48066F98191bB',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/12637/thumb/exchange.png?1623764906',
            'coingeckoId': 'stobox-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GMEE',
            'name': 'GAMEE',
            'address': '0xcf32822ff397Ef82425153a9dcb726E5fF61DCA7',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xcf32822ff397ef82425153a9dcb726e5ff61dca7.png',
            'coingeckoId': 'gamee',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'OPCT',
            'name': 'Opacity',
            'address': '0xce6bF09e5c7A3E65B84F88DcC6475c88d38BA5eF',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/7237/thumb/Opacity.jpg?1551843524',
            'coingeckoId': 'opacity',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'RAIDER',
            'name': 'Crypto Raiders',
            'address': '0xcd7361ac3307D1C5a46b63086a90742Ff44c63B3',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xcd7361ac3307d1c5a46b63086a90742ff44c63b3.png',
            'coingeckoId': 'crypto-raiders',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'PARA',
            'name': 'Parabolic',
            'address': '0xcca3E26Be51b8905f1A01872524f17eb55Bd02fb',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25187/thumb/LHh3hHnQ_400x400.jpg?1650600695',
            'coingeckoId': 'parabolic-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STACK',
            'name': 'Stacker Ventures',
            'address': '0xccBe9B810d6574701d324fD6DbE0A1b68f9d5bf7',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14218/thumb/stack.jpg?1615000614',
            'coingeckoId': 'stacker-ventures',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'PLOT',
            'name': 'PLOT',
            'address': '0xcb9d0b8CfD8371143ba5A794c7218D4766c493e2',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'INSTx',
            'name': 'Superfluid INST',
            'address': '0xcb5676568FeBb4e4f0DCa9407318836e7a973183',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0xf50d05a1402d0adafa880d36050736f9f6ee7dee/376bab6e695ee40bf7de593c204e3aa3.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DNXC',
            'name': 'DinoX',
            'address': '0xcaF5191fc480F43e4DF80106c7695ECA56E48B18',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://dinox.io/images/asset_icon_dnx.png',
            'coingeckoId': 'dinox',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'GULL',
            'name': 'Polygod',
            'address': '0xca830317146BfdDE71e7C0B880e2ec1f66E273EE',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PUP',
            'name': 'PolyPup',
            'address': '0xcFe2cF35D2bDDE84967e67d00aD74237e234CE59',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/16420/thumb/2_%288%29.png?1623983944',
            'coingeckoId': 'polypup',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CYC',
            'name': 'Cyclone Protocol',
            'address': '0xcFb54a6D2dA14ABeCD231174FC5735B4436965D8',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xcfb54a6d2da14abecd231174fc5735b4436965d8.png',
            'coingeckoId': 'cyclone-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'MOCA',
            'name': 'Museum of Crypto Art',
            'address': '0xcE899f26928a2B21c6a2Fddd393EF37c61dbA918',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15829/small/photo_2021-06-04_09.36.16.jpeg?1622770606',
            'coingeckoId': 'museum-of-crypto-art',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'RENDOGE',
            'name': 'renDOGE',
            'address': '0xcE829A89d4A55a63418bcC43F00145adef0eDB8E',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xcE829A89d4A55a63418bcC43F00145adef0eDB8E/logo.png',
            'coingeckoId': 'rendoge',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NACHO',
            'name': 'Nacho Finance',
            'address': '0xcD86152047e800d67BDf00A4c635A8B6C0e5C4c2',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xcd86152047e800d67bdf00a4c635a8b6c0e5c4c2.png',
            'coingeckoId': 'nacho-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BZN',
            'name': 'Benzene (PoS)',
            'address': '0xcD0D64C971AF8B477042130c5e6cD2A6f7842869',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xcd0d64c971af8b477042130c5e6cd2a6f7842869/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'VIS',
            'name': 'Vigorus',
            'address': '0xcC1B9517460D8aE86fe576f614d091fCa65a28Fc',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/21245/thumb/VIS.PNG?1638768736',
            'coingeckoId': 'vigorus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ACAR',
            'name': 'AGA Carbon Rewards',
            'address': '0xcBce9f77921C2E90522d438dF4C5582F4c617768',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xcBce9f77921C2E90522d438dF4C5582F4c617768/logo.png',
            'coingeckoId': 'aga-carbon-rewards',
            'listedIn': [
                'coingecko',
                'dfyn',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'XSHIP',
            'name': 'XSHIP',
            'address': '0xcB3FE445E9c118E2232203b1d4a44f900cBD95b2',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/28084/thumb/CRYTOLOGO%281%29.png?1667443876',
            'coingeckoId': 'spaceshipx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STACKx',
            'name': 'Superfluid STACK',
            'address': '0xcAE73e9EeE8a01b8B7F94b59133e3821F21470AB',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0xccbe9b810d6574701d324fd6dbe0a1b68f9d5bf7/a7a99d85cc3a379e441c8e8057e9f921.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'IbAlluoEUR',
            'name': 'Interest Bearing Alluo EUR',
            'address': '0xc9d8556645853C465D1D5e7d2c81A0031F0B8a92',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PEAR',
            'name': 'Pear',
            'address': '0xc8bcb58caEf1bE972C0B638B1dD8B0748Fdc8A44',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17173/thumb/pear200.png?1626793002',
            'coingeckoId': 'pear',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAO',
            'name': 'BaoToken (PoS)',
            'address': '0xc81278a52AD0e1485B7C3cDF79079220Ddd68b7D',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xc81278a52ad0e1485b7c3cdf79079220ddd68b7d/logo.png',
            'coingeckoId': 'bao-finance',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'NFTI',
            'name': 'Scalara NFT Index',
            'address': '0xc75ea0c71023C14952F3C7B9101ECbbAa14aA27A',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xc75ea0c71023c14952f3c7b9101ecbbaa14aa27a.png',
            'coingeckoId': 'scalara-nft-index',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'HMT',
            'name': 'HUMAN Protocol',
            'address': '0xc748B2A084F8eFc47E086ccdDD9b7e67aEb571BF',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/16412/thumb/human_protocol.PNG?1623971316',
            'coingeckoId': 'human-protocol',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ICE',
            'name': 'Decentral Games ICE',
            'address': '0xc6C855AD634dCDAd23e64DA71Ba85b8C51E5aD7c',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xc6c855ad634dcdad23e64da71ba85b8c51e5ad7c.png',
            'coingeckoId': 'decentral-games-ice',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'IbAlluoETH',
            'name': 'Interest Bearing Alluo ETH',
            'address': '0xc677B0918a96ad258A68785C2a3955428DeA7e50',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FINA',
            'name': 'DEFINA TOKEN',
            'address': '0xc6527b9dB96b4790604a3F8873D23140C8391D07',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'XDG',
            'name': 'Decentral Games Governance',
            'address': '0xc6480Da81151B2277761024599E8Db2Ad4C388C8',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xc6480da81151b2277761024599e8db2ad4c388c8.png',
            'coingeckoId': 'decentral-games-governance',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'D11',
            'name': 'DeFi11',
            'address': '0xc58158c14D4757EF36Ce25e493758F2fcEEDec5D',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/11147.png',
            'coingeckoId': 'defi11',
            'listedIn': [
                'coingecko',
                'quickswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'MOON',
            'name': 'Polywolf',
            'address': '0xc56d17dD519e5eB43a19C9759b5D5372115220BD',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/16034/thumb/moon-black.png?1622675193',
            'coingeckoId': 'polywolf',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'SCO2',
            'name': 'Carbonic Shares',
            'address': '0xc54884870F7A9c9fFb3541462e192D5eb1BBBB8C',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25976/thumb/SCO2.png?1655112461',
            'coingeckoId': 'carbonic-shares',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HOP',
            'name': 'Hop',
            'address': '0xc5102fE9359FD9a28f877a67E36B0F050d81a3CC',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25445/small/hop.png?1665541677',
            'coingeckoId': null,
            'listedIn': [
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'KGR',
            'name': 'Kagra (PoS)',
            'address': '0xc4ec74F50dd4A9ae7268898B3292DEcEf8Eda226',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xc4ec74f50dd4a9ae7268898b3292decef8eda226/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'LUCHOW',
            'name': 'LunaChow',
            'address': '0xc4BB7277A74678f053259CB1F96140347efbfd46',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18805/thumb/J-MwYfhD_400x400.jpg?1633475157',
            'coingeckoId': 'lunachow',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'renFIL',
            'name': 'renFIL',
            'address': '0xc4Ace9278e7E01755B670C0838c3106367639962',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0xc4Ace9278e7E01755B670C0838c3106367639962/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ACDCx',
            'name': 'Superfluid ACDC',
            'address': '0xc49c15350f001efEe91E154F03BdDF38CFD0d96e',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'GYSR',
            'name': 'Geyser',
            'address': '0xc48F61a288A08F1B80c2edd74652e1276B6A168c',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0xc48F61a288A08F1B80c2edd74652e1276B6A168c/logo.png',
            'coingeckoId': 'geyser',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'DSFR',
            'name': 'Digital Swiss Franc',
            'address': '0xc45ABE05e9DB3739791D1Dc1B1638Be8aD68B10b',
            'decimals': 4,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18672/thumb/1627014783964.png?1632875931',
            'coingeckoId': 'digital-swis-franc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RBC',
            'name': 'Rubic',
            'address': '0xc3cFFDAf8F3fdF07da6D5e3A89B8723D5E385ff8',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/12629/thumb/200x200.png?1607952509',
            'coingeckoId': 'rubic',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'ADDY',
            'name': 'Adamant',
            'address': '0xc3FdbadC7c795EF1D6Ba111e06fF8F16A20Ea539',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xc3fdbadc7c795ef1d6ba111e06ff8f16a20ea539.png',
            'coingeckoId': 'adamant',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'quickswap',
                'dfyn',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MGH',
            'name': 'MetaGameHub DAO',
            'address': '0xc3C604F1943B8C619c5D65cd11A876e9C8eDCF10',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/20625/thumb/mgh.PNG?1637318966',
            'coingeckoId': 'metagamehub-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EarnX',
            'name': 'EarnX V2',
            'address': '0xc2dA68Cd8B7Fc32CF2fcECC35Ca33471cC91C481',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xc2da68cd8b7fc32cf2fcecc35ca33471cc91c481/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'LCD',
            'name': 'Lucidao',
            'address': '0xc2A45FE7d40bCAc8369371B08419DDAFd3131b4a',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/23693/small/lcd-icon-color-200px.png?1645450706',
            'coingeckoId': 'lucidao',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BNT',
            'name': 'Bancor Network Token',
            'address': '0xc26D47d5c33aC71AC5CF9F776D63Ba292a4F7842',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x1F573D6Fb3F13d689FF844B4cE37794d79a7FF1C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'uniswap',
                'rubic'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'Tether',
            'address': '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xdac17f958d2ee523a2206206994597c13d831ec7.png',
            'coingeckoId': 'tether',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'quickswap',
                'dfyn',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PGX',
            'name': 'Pegaxy Stone',
            'address': '0xc1c93D475dc82Fe72DBC7074d55f5a734F8cEEAE',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xc1c93d475dc82fe72dbc7074d55f5a734f8ceeae.png',
            'coingeckoId': 'pegaxy-stone',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'CGT',
            'name': 'CACHE Gold (PoS)',
            'address': '0xc17D249C8A675C2e67A5B305A3e6b23cC45d81Aa',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xc17d249c8a675c2e67a5b305a3e6b23cc45d81aa/logo.png',
            'coingeckoId': 'cache-gold',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'GFT',
            'name': 'Galactican Founders Token (PoS)',
            'address': '0xc10aAcff41Af60BfCfaba3A03099c4AEdB113E15',
            'decimals': 0,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xc10aacff41af60bfcfaba3a03099c4aedb113e15/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'wXRNBW',
            'name': 'Wrapped Vesting Rainbow Token',
            'address': '0xc104e54803abA12f7a171a49DDC333Da39f47193',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BCMC',
            'name': 'Blockchain Monster Hunt',
            'address': '0xc10358f062663448a3489fC258139944534592ac',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xc10358f062663448a3489fc258139944534592ac.png',
            'coingeckoId': 'blockchain-monster-hunt',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'wPTG',
            'name': 'wrapped Prestige',
            'address': '0xc0f14C88250E680eCd70224B7fBa82b7C6560d12',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xc0f14C88250E680eCd70224B7fBa82b7C6560d12/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CO2',
            'name': 'Carbonic',
            'address': '0xc0eB3503F35E736F6c2861FAfcDe9BafF72A50fF',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25975/thumb/logo_%281%29.png?1655112288',
            'coingeckoId': 'carbonic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RRGP',
            'name': 'RRGP',
            'address': '0xc0C081e3790E921eda7E73013a23f21ADDbd8970',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/9225.png',
            'coingeckoId': null,
            'listedIn': [
                'RouterProtocol'
            ]
        },
        {
            'symbol': 'CHONK',
            'name': 'CHONKER FINANCE (PoS)',
            'address': '0xc059a4777279E30C5238Da786384700cD14d8f47',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xc059a4777279e30c5238da786384700cd14d8f47/logo.png',
            'coingeckoId': 'chonk',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SPIRIT',
            'name': 'SpiritSwapToken',
            'address': '0xbf926e44884F76Cd211B800488905ce12158aE67',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BOBA',
            'name': 'bobabet.dcl.eth.link (PoS)',
            'address': '0xbf3d5d13b3Cb79A6173394046973C34A60Ac8A41',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xbf3d5d13b3cb79a6173394046973c34a60ac8a41/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'GBTS',
            'name': 'GemBites',
            'address': '0xbe9512e2754cb938dd69Bbb96c8a09Cb28a02D6D',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0xbe9512e2754cb938dd69bbb96c8a09cb28a02d6d/c618b140ea998399174f9bfbeb87dab7.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'EXO',
            'name': 'Exohood',
            'address': '0xbd8005612124DC30601E22D8B5d188A89767c640',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15590/thumb/LOGO_200x200_CIRCLE.png?1658837040',
            'coingeckoId': 'exohood',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SILVER',
            'name': 'Silver',
            'address': '0xbc7cB585346f4F59d07121Bb9Ed7358076243539',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://afksystem.finance/images/tokens/silver.svg',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'NEBULA',
            'name': 'NEBULA',
            'address': '0xba47320eF6B1039F19F3C4096F0f3B3e8Eb8d1F8',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/Palette-Finance/images/main/nebula.png',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'PBX',
            'name': 'Paribus',
            'address': '0xbF77E7B4CcC92BbaFf26dD08A6a00609d05FF459',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'GLCH',
            'name': 'Glitch Protocol',
            'address': '0xbE5cf150E1fF59ca7F2499Eaa13BFC40AAE70e78',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://etherscan.io/token/images/glitch_32.png',
            'coingeckoId': 'glitch-protocol',
            'listedIn': [
                'coingecko',
                'dfyn',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'CHERRY',
            'name': 'CHERRY (PoS)',
            'address': '0xbE32718f77Aa63d95658Ddd8EF472039c71Eb704',
            'decimals': 4,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xbe32718f77aa63d95658ddd8ef472039c71eb704/logo.png',
            'coingeckoId': 'cherry',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FRACTION',
            'name': 'Own a fraction',
            'address': '0xbD80CFA9d93A87D1bb895f810ea348E496611cD4',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15099/large/fraction.png?1619691519',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ENS',
            'name': 'Ethereum Name Service',
            'address': '0xbD7A5Cf51d22930B8B3Df6d834F9BCEf90EE7c4f',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/19785/thumb/acatxTm8_400x400.jpg?1635850140',
            'coingeckoId': null,
            'listedIn': [
                'uniswap'
            ]
        },
        {
            'symbol': 'JCHF',
            'name': 'Jarvis Synthetic Swiss Franc',
            'address': '0xbD1463F02f61676d53fd183C2B19282BFF93D099',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xbd1463f02f61676d53fd183c2b19282bff93d099.png',
            'coingeckoId': 'jarvis-synthetic-swiss-franc',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'GAME',
            'name': 'GAME Credits',
            'address': '0xbCc7f67d1696fDa9c66e9B2538d0B770090c3c10',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xbcc7f67d1696fda9c66e9b2538d0b770090c3c10/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BANANA',
            'name': 'Banana',
            'address': '0xbC91347e80886453F3f8bBd6d7aC07C122D87735',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17521/small/banana-token-cg.png?1646285527',
            'coingeckoId': 'banana',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'Kash',
            'name': 'Kash',
            'address': '0xbC2c3F3AefDbe28bEe0e29fABC6Df860903A4843',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'LASM',
            'name': 'LasMeta',
            'address': '0xbC2E674dFe0d885081f429b296D7aE2B784D1198',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REMI',
            'name': 'REMI',
            'address': '0xbB6129911d3bBdAdB447241d433b4eD529aeBBd8',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27218/thumb/image_%283%29.png?1662613755',
            'coingeckoId': 'remi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'dUSD',
            'name': 'dHEDGE Stablecoin Yield',
            'address': '0xbAe28251B2a4E621aA7e20538c06DEe010Bc06DE',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xbae28251b2a4e621aa7e20538c06dee010bc06de.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SWASH',
            'name': 'Swash',
            'address': '0xbA3CB8329D442E6F9Eb70fafe1E214251df3D275',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xba3cb8329d442e6f9eb70fafe1e214251df3d275.png',
            'coingeckoId': 'swash',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'INS',
            'name': 'iNFTspace',
            'address': '0xb988Bd378A0754957d5D9471C96E0F8051645a26',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24681/thumb/4hTuPCCC_400x400.jpg?1648560503',
            'coingeckoId': 'inftspace',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WHALE',
            'name': 'WHALE',
            'address': '0xb9585ec9d4C97Ad9DEd7250Bb9a199fe8eED0ECA',
            'decimals': 4,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/11797/thumb/WHALE.png?1595004706',
            'coingeckoId': 'whale',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YFI',
            'name': 'yearn.finance',
            'address': '0xb8cb8a7F4C2885C03e57E973C74827909Fdc2032',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/yfi.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'WUSD',
            'name': 'WUSD',
            'address': '0xb8ab048D6744a276b2772dC81e406a4b769A5c3D',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xb8ab048D6744a276b2772dC81e406a4b769A5c3D/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'FGH',
            'name': 'Flowing Hair (PoS)',
            'address': '0xb8E5b39689f886f8C34D3E5ac09F513A282d486D',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xb8e5b39689f886f8c34d3e5ac09f513a282d486d/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'OTM',
            'name': 'GO2E OTM',
            'address': '0xb832b1c86C79FA560eE817001f7b7ADa1F0cdC85',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/28112/thumb/logo_OTM_200x200.png?1667549057',
            'coingeckoId': 'go2e-otm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HONOR',
            'name': 'Honor',
            'address': '0xb82A20B4522680951F11c94c54B8800c1C237693',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/16654/thumb/Qkj26Aj.png?1624599365',
            'coingeckoId': 'honor-token',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'KNOT',
            'name': 'Karmaverse',
            'address': '0xb763F1177E9B2Fb66FBe0d50372E3e2575c043e5',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24239/thumb/knot.png?1647088638',
            'coingeckoId': 'karmaverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DAM',
            'name': 'Datamine',
            'address': '0xb75bBd79985a8092B05224f62D7fED25924B075d',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/11695/thumb/qxsFH8W.png?1592880463',
            'coingeckoId': 'datamine',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'FAN',
            'name': 'Fanadise',
            'address': '0xb6d48FCEf36E19681EE29896B19c1b6CbD1eAB1B',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WFAIR',
            'name': 'Wallfair',
            'address': '0xb6B5CDF74606181a1b05bfC0B9F17fC2A64B0cD5',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18920/thumb/wallfair_logo.png?1633942136',
            'coingeckoId': 'wallfair',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPORKx',
            'name': 'Superfluid SPORK',
            'address': '0xb683fb34A77C06931Ba62d804252D1f60596A36A',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x9ca6a77c8b38159fd2da9bd25bc3e259c33f5e39/a74f0633228035e7198527f84011b00a.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DSG',
            'name': 'DinosaurEggsToken',
            'address': '0xb65Ce345e1d6786C55c847076563b24B8B34bc2A',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DUDE',
            'name': 'DuDe',
            'address': '0xb6169E71144B4DEbd9CAa2dbb851fC8FFde6119F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25924/thumb/dude-logo-png.jpg?1654672849',
            'coingeckoId': 'dude',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDR',
            'name': 'Real USD',
            'address': '0xb5DFABd7fF7F83BAB83995E72A52B97ABb7bcf63',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27973/thumb/USDR-200x200.png?1666772134',
            'coingeckoId': 'real-usd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZLW',
            'name': 'Zelwin',
            'address': '0xb5B8381B67248F832c7961bd265f021CD8D291A4',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/11547/thumb/5614.png?1590991128',
            'coingeckoId': 'zelwin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YVS',
            'name': 'YVS Finance',
            'address': '0xb565CF70613ca464D68427106af80C67a8e4b801',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/13471/thumb/cu0LSzE.png?1608852718',
            'coingeckoId': 'yvs-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REX',
            'name': 'REX Coin',
            'address': '0xb521022EeaD7E7eEe95D30BA1A1f0aB657F83a61',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'LAVA',
            'name': 'Vulcan Forged LAVA',
            'address': '0xb4666B7402D287347DbBDC4EA5b30E80C376c0B3',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26858/thumb/13856.png?1660528181',
            'coingeckoId': 'vulcan-forged-lava',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KITTY',
            'name': 'Kitty Coin',
            'address': '0xb4228798fF437ecD8fa43429664e9992256fe6Ac',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/19555/thumb/kitty.png?1636332916',
            'coingeckoId': 'kittycoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EBOX',
            'name': 'Ebox',
            'address': '0xb41c43faBD22A6C6ea135e975769e9051f9ee8ad',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14528/thumb/ebox.png?1639203519',
            'coingeckoId': 'ebox',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'RBN',
            'name': 'Robinos',
            'address': '0xb3bC8daE5A49ed956B5D2207d9040C816c56398f',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18864/thumb/1vZaRdJ__400x400.png?1633643012',
            'coingeckoId': 'robinos',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XPRT',
            'name': 'Persistence',
            'address': '0xb3b9c016AD1E9f7EFdAE451b04EF696e05658b32',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14582/small/512_Light.png?1617149658',
            'coingeckoId': null,
            'listedIn': [
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'YAM',
            'name': 'YAM',
            'address': '0xb3B681dEE0435eCc0a508e40B02b3C9068D618cd',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xb3B681dEE0435eCc0a508e40B02b3C9068D618cd/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HNY',
            'name': 'Honey (PoS)',
            'address': '0xb371248Dd0f9E4061ccf8850E9223Ca48Aa7CA4b',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xb371248Dd0f9E4061ccf8850E9223Ca48Aa7CA4b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'UNI',
            'name': 'Uniswap',
            'address': '0xb33EaAd8d922B1083446DC23f610c2567fB5180f',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x1f9840a85d5af5bf1d1762f925bdaddc4201f984.png',
            'coingeckoId': 'uniswap',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'quickswap',
                'dfyn',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SBX',
            'name': 'SeedBox',
            'address': '0xb32b9e8bc382E7BB64D8DEF2982CD19785ef64cf',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/28028/thumb/logo_200.png?1667047200',
            'coingeckoId': 'seedbox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SMCW',
            'name': 'Space Crown',
            'address': '0xb2ea51BAa12C461327d12A2069d47b30e680b69D',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ICH',
            'name': 'ICOStart Token (PoS)',
            'address': '0xb257De22059cd200e34139d27CE0e415E173cE49',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xb257de22059cd200e34139d27ce0e415e173ce49/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'UBQ',
            'name': 'Ubiq',
            'address': '0xb1c5C9b97B35592777091cD34fFff141ae866AbD',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/181/thumb/ubiq.png?1547033919',
            'coingeckoId': 'ubiq',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INTD',
            'name': 'INTDESTCOIN  OLD ',
            'address': '0xb08bA4Ad6bC291f4F1E79C4c7f9395141B8D5797',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27134/thumb/INTD.png?1662025876',
            'coingeckoId': 'intdestcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AAH',
            'name': 'AAH (PoS)',
            'address': '0xaf9444bc9D6117121Aa108fC6c45835Be60E7d07',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xaf9444bc9d6117121aa108fc6c45835be60e7d07/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SWD',
            'name': 'SW DAO',
            'address': '0xaeE24d5296444c007a532696aaDa9dE5cE6caFD0',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/19336/thumb/mxhLivtN.png?1635122459',
            'coingeckoId': 'sw-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XMT',
            'name': 'MetalSwap',
            'address': '0xadBe0eac80F955363f4Ff47B0f70189093908c04',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/22075/thumb/xmt.png?1640943637',
            'coingeckoId': 'metalswap',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'NIOX',
            'name': 'Autonio',
            'address': '0xad684e79CE4b6D464f2Ff7c3FD51646892e24b96',
            'decimals': 4,
            'chainId': 137,
            'logoURI': 'https://i.ibb.co/NtzR67m/Group-357556-2.png',
            'coingeckoId': 'autonio',
            'listedIn': [
                'coingecko',
                'dfyn',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'FSW',
            'name': 'Falconswap',
            'address': '0xad5dc12E88C6534Eea8cFe2265851D9d4A1472AD',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/12256/thumb/falconswap.png?1598534184',
            'coingeckoId': 'fsw-token',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'C3',
            'name': 'C3 Token',
            'address': '0xad01DFfe604CDc172D8237566eE3a3ab6524d4C6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0xad01dffe604cdc172d8237566ee3a3ab6524d4c6/28d6565445f7d18a295fa76d9a05f588.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SIGN',
            'name': 'Signed',
            'address': '0xacbDc2b7a577299718309Ed5C4B703fb5ed7af90',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24030/thumb/wjnKYes.png?1646115896',
            'coingeckoId': 'signed',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATOM',
            'name': 'Cosmos',
            'address': '0xac51C4c48Dc3116487eD4BC16542e27B5694Da1b',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/1481/small/cosmos_hub.png?1555657960',
            'coingeckoId': null,
            'listedIn': [
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SWP',
            'name': 'Stepwatch',
            'address': '0xabEDe05598760E399ed7EB24900b30C51788f00F',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26384/thumb/MPY_u-OE_400x400.jpg?1657678801',
            'coingeckoId': 'stepwatch',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PRXY',
            'name': 'Proxy',
            'address': '0xab3D689C22a2Bb821f50A4Ff0F21A7980dCB8591',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xab3d689c22a2bb821f50a4ff0f21a7980dcb8591.png',
            'coingeckoId': 'proxy',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'IFARM',
            'name': 'iFARM',
            'address': '0xab0b2ddB9C7e440fAc8E140A89c0dbCBf2d7Bbff',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xa0246c9032bc3a600820415ae600c6388619a14d.png',
            'coingeckoId': 'ifarm',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CPR',
            'name': 'CIPHER',
            'address': '0xaa404804Ba583c025Fa64c9a276A6127CEB355c6',
            'decimals': 2,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/22719/thumb/E-97BTDPNxk2PYsd8EwHzDbEpM6w-VoJc3t5DutqiPPydT57269nRUrxk8Pqig4CboNanb2Rd0MA8a0N9CGUmGG1jKdbzhy8pIwiDEGcPBP0mslchklUxO7BWSbDOv-ouncsR0aHjYGlG1hTWueUJOeqSLcVR90UKpCLhEWtJ6mS4o8_XQh57pEAEw_aiZmetQ9z0-rsla4JFo.jpg?1642485655',
            'coingeckoId': 'cipher-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RAMP',
            'name': 'RAMP  OLD ',
            'address': '0xaECeBfcF604AD245Eaf0D5BD68459C3a7A6399c2',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/12837/small/RAMP-Logo-v2-1000pxsq.png?1617952606',
            'coingeckoId': 'ramp',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'EVE',
            'name': 'EVE',
            'address': '0xaE29AC47A9E3b0a52840E547aDf74B912999F7fc',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/22744/thumb/eve-exchange-logo-200-200-03.png?1643459572',
            'coingeckoId': 'eve-exchange',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AQR',
            'name': 'Aqar Chain',
            'address': '0xaE204EE82E60829A5850FE291C10bF657AF1CF02',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/22632/thumb/Xvl0W-30_400x400.jpg?1642343620',
            'coingeckoId': 'aqar-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MECHA',
            'name': 'Mechanium',
            'address': '0xaCd4E2d936Be9B16c01848A3742A34B3D5A5bDfa',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24374/thumb/w4K4OOMo_400x400.jpg?1647940092',
            'coingeckoId': 'mechachain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BETA',
            'name': 'PolyBeta Finance',
            'address': '0xaC3090B7042FCA2cDBF233022e4a9823a032600c',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18746/thumb/Poly-Beta-200-x-200-px.png?1633316632',
            'coingeckoId': 'polybeta-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OAI',
            'name': 'OMNI Token',
            'address': '0xaBc6790673a60b8A7f588450f59D2d256b1aeF7F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://omni.ai/images/cf5b6ec4.png',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'TITAN',
            'name': 'IRON Titanium',
            'address': '0xaAa5B9e6c589642f98a1cDA99B9D024B8407285A',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xaaa5b9e6c589642f98a1cda99b9d024b8407285a.png',
            'coingeckoId': 'iron-titanium-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'dfyn',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ASK',
            'name': 'Permission Coin',
            'address': '0xaA3717090CDDc9B227e49d0D84A28aC0a996e6Ff',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/12521/thumb/rjQjTU3c_400x400.jpg?1600383907',
            'coingeckoId': 'permission-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METAGON',
            'name': 'Metagon',
            'address': '0xa9cB489B3e59CA5f30B45c1ec2fC068aD6551686',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/23008/thumb/metagon.png?1643092533',
            'coingeckoId': 'metagon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'jSGD',
            'name': 'Jarvis Synthetic Singapore Dollar',
            'address': '0xa926db7a4CC0cb1736D5ac60495ca8Eb7214B503',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0xa926db7a4cc0cb1736d5ac60495ca8eb7214b503/8e51d27ec37601c77b4017ca7f1fa4ff.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'wsHUMP',
            'name': 'Wrapped sHUMP',
            'address': '0xa91Ac275f21F880d598C25DC801b0a9a3b7dd3DC',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ALN',
            'name': 'Aluna',
            'address': '0xa8fcEe762642f156b5D757b6FabC36E06b6d4A1A',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14379/thumb/uaLoLU8c_400x400_%281%29.png?1627873106',
            'coingeckoId': 'aluna',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MORK',
            'name': 'Mork',
            'address': '0xa707734BD310883e133e0ca23F6C166AEB2A1A7a',
            'decimals': 4,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/Mork.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'EROWAN',
            'name': 'Sifchain',
            'address': '0xa7051C5a22d963b81D71C2BA64D46a877fBc1821',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14044/small/EROWAN.png?1614656300',
            'coingeckoId': 'sifchain',
            'listedIn': [
                'coingecko',
                'quickswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'PLR',
            'name': 'Pillar',
            'address': '0xa6b37fC85d870711C56FbcB8afe2f8dB049AE774',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/809/thumb/v2logo-1.png?1624906282',
            'coingeckoId': 'pillar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GCR',
            'name': 'Global Coin Research',
            'address': '0xa69d14d6369E414a32a5C7E729B7afbAfd285965',
            'decimals': 4,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xa69d14d6369e414a32a5c7e729b7afbafd285965.png',
            'coingeckoId': 'global-coin-research',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'KHIZAx',
            'name': 'Superfluid KHIZA',
            'address': '0xa6810e06fb8c93b2C53C0D6415c885719CBA6EB5',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PCR',
            'name': 'Paycer Protocol',
            'address': '0xa6083abe845fbB8649d98B8586cBF50b7f233612',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/23658/thumb/8pZI6RZG_400x400.jpg?1644988756',
            'coingeckoId': 'paycer-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COSMIC',
            'name': 'CosmicSwap',
            'address': '0xa5Eb60CA85898f8b26e18fF7c7E43623ccbA772C',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/10521.png',
            'coingeckoId': 'cosmicswap',
            'listedIn': [
                'coingecko',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'FRONT',
            'name': 'Frontier',
            'address': '0xa3eD22EEE92a3872709823a6970069e12A4540Eb',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/12479/large/frontier_logo.png?1600145472',
            'coingeckoId': 'frontier-token',
            'listedIn': [
                'coingecko',
                'dfyn',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'MIMATIC',
            'name': 'MAI',
            'address': '0xa3Fa99A148fA48D14Ed51d610c367C61876997F1',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xa3fa99a148fa48d14ed51d610c367c61876997f1.png',
            'coingeckoId': 'mimatic',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'quickswap',
                'dfyn',
                'RouterProtocol',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SPN',
            'name': 'Sportzchain',
            'address': '0xa3C53b53Fe43083aa31c6F32ffe90C4d91b44391',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24915/thumb/Sportzchain_logo_195_195.png?1650240200',
            'coingeckoId': 'sportzchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MST',
            'name': 'Idle Mystic',
            'address': '0xa353dEb6Fb81dF3844D8bd614D33d040fDBb8188',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/19624/thumb/vcAqmDN-_400x400.jpg?1635498385',
            'coingeckoId': 'idle-mystic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QUINT',
            'name': 'QUINT',
            'address': '0xa31DFe74cDA04de990CafC6a0aB2BB6d6C9077CD',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FEAR',
            'name': 'Fear',
            'address': '0xa2CA40DBe72028D3Ac78B5250a8CB8c404e7Fb8C',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15825/small/fear-logo-400-400.png?1625552865',
            'coingeckoId': 'fear',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'MCASH',
            'name': 'Monsoon Finance',
            'address': '0xa25610a77077390A75aD9072A084c5FbC7d43A0d',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18632/small/DdcZZeP_%282%29.png?1632716316',
            'coingeckoId': 'monsoon-finance',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'eDAI',
            'name': 'Easy DAI',
            'address': '0xa1C09C8F4f5D03fcC27b456475d53d988e98D7C5',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xa1C09C8F4f5D03fcC27b456475d53d988e98D7C5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PMLG',
            'name': 'Polychain Monsters Genesis',
            'address': '0xa14c04DEa16798Aa8F25b1Da583cd5fBBFd6579E',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25396/thumb/19329.png?1651710855',
            'coingeckoId': 'polychain-monsters-genesis',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SUPER',
            'name': 'SuperFarm',
            'address': '0xa1428174F516F527fafdD146b883bB4428682737',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xe53ec727dbdeb9e2d5456c3be40cff031ab40a55.png',
            'coingeckoId': 'superfarm',
            'listedIn': [
                '1inch',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SHIBAKEN',
            'name': 'Shibaken Finance',
            'address': '0xa0cB0Ce7C6d93A7EBD72952Feb4407Dddee8a194',
            'decimals': 0,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15413/thumb/shibak.png?1660902286',
            'coingeckoId': 'shibaken-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DSLA',
            'name': 'DSLA Protocol',
            'address': '0xa0E390e9ceA0D0e8cd40048ced9fA9EA10D71639',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xa0E390e9ceA0D0e8cd40048ced9fA9EA10D71639/logo.png',
            'coingeckoId': 'stacktical',
            'listedIn': [
                'coingecko',
                'quickswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DUCKIES',
            'name': 'Yellow Duckies',
            'address': '0xFfb9F1907F827709b0eD09B37956cD3c7462abdb',
            'decimals': 2,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27630/thumb/Icon_coin_200px.png?1665748614',
            'coingeckoId': 'duckies',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DRC',
            'name': 'Digital Reserve Currency',
            'address': '0xFeD16c746CB5BFeD009730f9E3e6A673006105c7',
            'decimals': 0,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xFeD16c746CB5BFeD009730f9E3e6A673006105c7/logo.png',
            'coingeckoId': 'digital-reserve-currency',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BNSD',
            'name': 'BNSD Finance',
            'address': '0xFe457497A2A71bCE1eb93eA9e6a685057dd93dEe',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/12368/thumb/bnsd.png?1599358388',
            'coingeckoId': 'bnsd-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTU',
            'name': 'BTU Protocol',
            'address': '0xFdc26CDA2d2440d0E83CD1DeE8E8bE48405806DC',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xFdc26CDA2d2440d0E83CD1DeE8E8bE48405806DC/logo.png',
            'coingeckoId': 'btu-protocol',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PAPR',
            'name': 'PAPR',
            'address': '0xFbe49330E7B9F58a822788F86c1be38Ab902Bab1',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xfbe49330e7b9f58a822788f86c1be38ab902bab1.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'xyETH',
            'name': 'xyETH',
            'address': '0xFbe19956395baE1065206255bC333E493702f221',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
            'coingeckoId': null,
            'listedIn': [
                'xyfinance'
            ]
        },
        {
            'symbol': 'BIFI',
            'name': 'Beefy Finance',
            'address': '0xFbdd194376de19a88118e84E279b977f165d01b8',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xca3f508b8e4dd382ee878a314789373d80a5190a.png',
            'coingeckoId': 'beefy-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'quickswap',
                'dfyn',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'RIKEN',
            'name': 'Poriverse',
            'address': '0xFbBd93fC3BE8B048c007666AF4846e4A36BACC95',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25167/thumb/FiRSQOuA_400x400.jpg?1650521971',
            'coingeckoId': 'poriverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ROOBEE',
            'name': 'Roobee',
            'address': '0xFaFA220145DfA5C3Ec85B6FA8a75aEE2451CDe5E',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/8791/thumb/Group_11.png?1580344629',
            'coingeckoId': 'roobee',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MV',
            'name': 'MULTIVERSE',
            'address': '0xFFec5b0Ec59a5760a3273764a9d100F782d8e221',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PIKA',
            'name': 'Pika',
            'address': '0xFFb89d7637Cf4860884ED48b57Ae5562bF64e10F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14419/thumb/pika-logo-2022-nbg.png?1641971069',
            'coingeckoId': 'pikachu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDD',
            'name': 'Decentralized USD',
            'address': '0xFFA4D863C96e743A2e1513824EA006B8D0353C57',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25380/small/UUSD.jpg?1651823371',
            'coingeckoId': null,
            'listedIn': [
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ORARE',
            'name': 'OneRare',
            'address': '0xFF2382Bd52efaceF02Cc895bcBFc4618608AA56F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/19696/small/Thumbnail_-_500_px_-_Black.png?1635751681',
            'coingeckoId': 'onerare',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'DIP',
            'name': 'Etherisc DIP (PoS)',
            'address': '0xFEFF6c1643D38B13a198cfe1d76505701c380aF0',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xfeff6c1643d38b13a198cfe1d76505701c380af0/logo.png',
            'coingeckoId': 'etherisc',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ISLA',
            'name': 'DefiVille Island',
            'address': '0xFE6A2342f7C5D234E8496dF12c468Be17e0c181F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14471/thumb/256.png?1616384288',
            'coingeckoId': 'defiville-island',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'bDIGG',
            'name': 'Badger Sett Digg (PoS)',
            'address': '0xFDde616084427f0A231D0664a985E1F820E34693',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xFDde616084427f0A231D0664a985E1F820E34693/logo.png',
            'coingeckoId': 'badger-sett-digg',
            'listedIn': [
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ACPT',
            'name': 'Crypto Accept (PoS)',
            'address': '0xFCf1E2fC0A398925ddb09222842b376E3A7D573B',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xfcf1e2fc0a398925ddb09222842b376e3a7d573b/logo.png',
            'coingeckoId': 'crypto-accept',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MTBC',
            'name': 'Metabolic',
            'address': '0xFC541ec44A41974d76FC0b2F526CAE781ffAbaED',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/23837/thumb/IMG-20211124-162844-449.png?1645516931',
            'coingeckoId': 'metabolic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JPYCx',
            'name': 'Superfluid JPYC',
            'address': '0xFBb291570DE4B87353B1e0f586Df97A1eD856470',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x6ae7dfc73e0dde2aa99ac063dcf7e8a63265108c/ace37007349b9cecb59c3c7bb0fbbc68.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'LSHARE',
            'name': 'LSHARE',
            'address': '0xFB40b1eFe90D4b786D2D9d9dc799B18BDe92923b',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ERR',
            'name': 'Coinerr',
            'address': '0xFB32513135e3267995268E3099d2B6114d20B6eD',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24031/thumb/ERR-logo.png?1646116183',
            'coingeckoId': 'coinerr',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XCRE',
            'name': 'Cresio',
            'address': '0xFA3c05C2023918A4324fDE7163591Fe6BEBd1692',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/8032/thumb/logoXCRE.png?1644387161',
            'coingeckoId': 'cresio',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LEPA',
            'name': 'Lepasa',
            'address': '0xF9a4BBAa7fA1DD2352F1A47d6d3fcfF259A6D05F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/22745/thumb/lepasa.PNG?1642550975',
            'coingeckoId': 'lepasa',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'FUSE',
            'name': 'Fuse',
            'address': '0xF915fDDa4c882731C0456a4214548Cd13A822886',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/10347/small/vUXKHEe.png?1601523640',
            'coingeckoId': 'fuse-network-token',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SURE',
            'name': 'inSure DeFi',
            'address': '0xF88332547c680F755481Bf489D890426248BB275',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/10354/thumb/logo-grey-circle.png?1614910406',
            'coingeckoId': 'insure',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATK',
            'name': 'Attack Wagon',
            'address': '0xF868939Ee81F04f463010BC52EAb91c0839eF08c',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/21333/small/attack.PNG?1638948221',
            'coingeckoId': 'attack-wagon',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'AGAR',
            'name': 'AGA Rewards',
            'address': '0xF84BD51eab957c2e7B7D646A3427C5A50848281D',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xb453f1f2ee776daf2586501361c457db70e1ca0f.png',
            'coingeckoId': 'aga-rewards-2',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'dfyn',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'sUSD',
            'name': 'Synth sUSD',
            'address': '0xF81b4Bec6Ca8f9fe7bE01CA734F55B2b6e03A7a0',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/5013/thumb/sUSD.png?1616150765',
            'coingeckoId': null,
            'listedIn': [
                'uniswap'
            ]
        },
        {
            'symbol': 'NORD',
            'name': 'Nord Finance',
            'address': '0xF6F85b3f9fd581C2eE717c404F7684486F057F95',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/13630/small/nord.jpg?1610465136',
            'coingeckoId': 'nord-finance',
            'listedIn': [
                'coingecko',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'AMMF',
            'name': 'aMMF',
            'address': '0xF63A19b7793a2779566068dEb1e9102868dB56E6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/28015/thumb/CRYTOLOGO%282%29.png?1666948864',
            'coingeckoId': 'ammf',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'dYel',
            'name': 'dYEL Index Token',
            'address': '0xF6134E74b7a4ee23A888436E53e77F83e62E8df8',
            'decimals': 6,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PAR',
            'name': 'Parachute',
            'address': '0xF521D590Fb1e0b432fD0e020cdbD6c6397d652C2',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xF521D590Fb1e0b432fD0e020cdbD6c6397d652C2/logo.png',
            'coingeckoId': 'parachute',
            'listedIn': [
                'coingecko',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MTA',
            'name': 'mStable Governance  Meta',
            'address': '0xF501dd45a1198C2E1b5aEF5314A68B9006D842E0',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0xF501dd45a1198C2E1b5aEF5314A68B9006D842E0/logo.png',
            'coingeckoId': 'meta',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'MATUSD',
            'name': 'Matic Aave interest bearing TUSD',
            'address': '0xF4b8888427b00d7caf21654408B7CBA2eCf4EbD9',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xF4b8888427b00d7caf21654408B7CBA2eCf4EbD9/logo.png',
            'coingeckoId': 'matic-aave-tusd',
            'listedIn': [
                'coingecko',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'RGT',
            'name': 'Rari Governance',
            'address': '0xF4BB0ED25Ac7bcc9c327B88BaC5CA288A08eC41e',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/12900/thumb/Rari_Logo_Transparent.png?1613978014',
            'coingeckoId': 'rari-governance-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GAJ',
            'name': 'Gaj Finance',
            'address': '0xF4B0903774532AEe5ee567C02aaB681a81539e92',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xF4B0903774532AEe5ee567C02aaB681a81539e92/logo.png',
            'coingeckoId': 'gaj',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'dfyn',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GTON',
            'name': 'GTON CAPITAL',
            'address': '0xF480f38C366dAaC4305dC484b2Ad7a496FF00CeA',
            'decimals': 15,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15728/thumb/GC_logo_200x200.png?1642669327',
            'coingeckoId': 'gton-capital',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHI3LD',
            'name': 'PolyShield',
            'address': '0xF239E69ce434c7Fb408b05a0Da416b14917d934e',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xF239E69ce434c7Fb408b05a0Da416b14917d934e/logo.png',
            'coingeckoId': 'polyshield',
            'listedIn': [
                'coingecko',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FLOAT',
            'name': 'Float Protocol: FLOAT (PoS)',
            'address': '0xF0Ae1EFdE60BAb0a830673747138F12367858e8D',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MASQ',
            'name': 'MASQ',
            'address': '0xEe9A352F6aAc4aF1A5B9f467F6a93E0ffBe9Dd35',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xee9a352f6aac4af1a5b9f467f6a93e0ffbe9dd35.png',
            'coingeckoId': 'masq',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'O3',
            'name': 'O3 Swap',
            'address': '0xEe9801669C6138E84bD50dEB500827b776777d28',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xee9801669c6138e84bd50deb500827b776777d28.png',
            'coingeckoId': 'o3-swap',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'BURROW',
            'name': 'Burrow Token',
            'address': '0xEe031bc406D919467E1D5DD4Bd8982E8a26F7bA9',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WFIL',
            'name': 'Wrapped Filecoin',
            'address': '0xEde1B77C0Ccc45BFa949636757cd2cA7eF30137F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/fil.jpg',
            'coingeckoId': 'wrapped-filecoin',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ANML',
            'name': 'Animal Concerts Token (PoS)',
            'address': '0xEcc4176B90613Ed78185f01bd1E42C5640C4F09d',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0xecc4176b90613ed78185f01bd1e42c5640c4f09d/b3d2118e9b5af1c5031a484d97dab030.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SPACE',
            'name': 'Space Rebase',
            'address': '0xEc6c6F7E666D8a3FA0FD612B97d6A6D9E7d1E3f3',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xec6c6f7e666d8a3fa0fd612b97d6a6d9e7d1e3f3.png',
            'coingeckoId': 'space-rebase',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'REGEN',
            'name': 'Regen Network',
            'address': '0xEc482De9569a5EA3Dd9779039b79e53F15791fDE',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/16733/small/REGEN.png?1624861317',
            'coingeckoId': null,
            'listedIn': [
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ONX',
            'name': 'OnX Finance',
            'address': '0xEb94A5e2C643403E29fa1d7197e7E0708B09aD84',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/13445/thumb/onxlogo-1.png?1608629659',
            'coingeckoId': 'onx-finance',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'TRY',
            'name': 'TryHards',
            'address': '0xEFeE2de82343BE622Dcb4E545f75a3b9f50c272D',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xefee2de82343be622dcb4e545f75a3b9f50c272d.png',
            'coingeckoId': 'tryhards',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ARPA',
            'name': 'ARPA',
            'address': '0xEE800B277A96B0f490a1A732e1D6395FAD960A26',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0xEE800B277A96B0f490a1A732e1D6395FAD960A26/logo.png',
            'coingeckoId': 'arpa',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'META',
            'name': 'ABCMETA',
            'address': '0xEDcFb6984a3c70501BAA8b7f5421Ae795ecC1496',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27723/thumb/meta-gecko50.png?1668597397',
            'coingeckoId': 'abcmeta',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HER',
            'name': 'HerityNetwork',
            'address': '0xEDad6876ab29B08e133e4f11D19162738E140734',
            'decimals': 9,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'LUMIII',
            'name': 'Lumiii',
            'address': '0xED88227296943857409A8e0f15Ad7134e70D0F73',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/21892.png',
            'coingeckoId': 'lumiiitoken',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'CHAMP',
            'name': 'Ultimate Champions',
            'address': '0xED755dBa6Ec1eb520076Cec051a582A6d81A8253',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1668594279439_1547284331169272.jpg',
            'coingeckoId': 'ultimate-champions',
            'listedIn': [
                'coingecko',
                'openocean'
            ]
        },
        {
            'symbol': 'DM',
            'name': 'Dogematic',
            'address': '0xED42605D2F2aBd7Dff808662a322A87804eF3a00',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18220/thumb/iIhpL4K.png?1631055291',
            'coingeckoId': 'dogematic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELIXIR',
            'name': 'Starchi',
            'address': '0xED0bb69f9F96E8C5FffdF16c468eceC385134eA5',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18941/thumb/starchi.jpg?1633961292',
            'coingeckoId': 'starchi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'idleWETHx',
            'name': 'Superfluid idleWETHYield',
            'address': '0xEB5748f9798B11aF79F892F344F585E3a88aA784',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DFH',
            'name': 'DeFiHelper Governance Token',
            'address': '0xEB4c3961f9c06307aF0e6Bc195204352bD3DbfC6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SEED',
            'name': 'MetaGame',
            'address': '0xEAeCC18198a475c921B24b8A6c1C1f0f5F3F7EA0',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/13099/thumb/V8phEz8V.png?1612854078',
            'coingeckoId': 'metagame',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'StIbAlluoUSD',
            'name': 'Superfluid IbAlluoUSD',
            'address': '0xE9E759B969B991F2bFae84308385405B9Ab01541',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DEV',
            'name': 'Devcash (PoS)',
            'address': '0xE951e4C7B0B91447C1a0A46BFb46630682A3f229',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xe951e4c7b0b91447c1a0a46bfb46630682a3f229/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'NSDX',
            'name': 'NASDEX',
            'address': '0xE8d17b127BA8b9899a160D9a07b69bCa8E08bfc6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18634/small/nadex.PNG?1632727863',
            'coingeckoId': 'nasdex-token',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'OPIUM',
            'name': 'Opium',
            'address': '0xE8F157E041Df3B28151B667364e9c90789Da7923',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/13758/thumb/opium-token-black_%281%29.png?1611767960',
            'coingeckoId': 'opium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LCX',
            'name': 'LCX (PoS)',
            'address': '0xE8A51D0dD1b4525189ddA2187F90ddF0932b5482',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xe8a51d0dd1b4525189dda2187f90ddf0932b5482/logo.png',
            'coingeckoId': 'lcx',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'VOLT',
            'name': 'VOLTAGE',
            'address': '0xE8A05E85883F9663b18a38d7aa89853deaba56e3',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18515/small/volt.png?1632219924',
            'coingeckoId': null,
            'listedIn': [
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'DUET',
            'name': 'Duet Governance Token',
            'address': '0xE8521b61f64Fc45A0bC3db36D2A524fe61a69b52',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'mUSD',
            'name': 'mUSD',
            'address': '0xE840B73E5287865EEc17d250bFb1536704B43B21',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0xe840b73e5287865eec17d250bfb1536704b43b21/8d72747ec45f49e310be371a7204a52a.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AM3CRV',
            'name': 'Curve fi amDAI amUSDC amUSDT',
            'address': '0xE7a24EF0C5e95Ffb0f6684b813A78F2a3AD7D171',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26740/thumb/W1sQNVWo_400x400.jpeg?1659953467',
            'coingeckoId': 'curve-fi-amdai-amusdc-amusdt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASVA',
            'name': 'Asva Finance',
            'address': '0xE7E0bA6f84D843d17Cb8410810Bf3E8Bcda0caA1',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://get.celer.app/cbridge-icons/ASVA.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WCHI',
            'name': 'XAYA',
            'address': '0xE79feAAA457ad7899357E8E2065a3267aC9eE601',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/2091/thumb/xaya200x200.png?1656561479',
            'coingeckoId': 'chimaera',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'DFX',
            'name': 'DFX Finance',
            'address': '0xE7804D91dfCDE7F776c90043E03eAa6Df87E6395',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xe7804d91dfcde7f776c90043e03eaa6df87e6395.png',
            'coingeckoId': 'dfx-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'jCOP',
            'name': 'Jarvis Synthetic Colombian Peso',
            'address': '0xE6d222caAed5F5DD73A9713AC91C95782e80ACBf',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'ipfs://QmeX4b1G5vCP5fzS4wcpjbbpmy5gVZzbvNmt98awMftKeP',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FEVR',
            'name': 'RealFevr',
            'address': '0xE6B9d092223f39013656702A40dbE6B7DeCc5746',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17136/thumb/Fevr-Token.png?1626869849',
            'coingeckoId': 'realfevr',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'UST',
            'name': 'TerraUSD  Wormhole ',
            'address': '0xE6469Ba6D2fD6130788E0eA9C0a0515900563b59',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/21150/small/UST_wh_small.png?1644223065',
            'coingeckoId': 'terrausd-wormhole',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'oWBTC',
            'name': 'Orbit Bridge Polygon Wrapped BTC',
            'address': '0xE631FfAA2cF4d91aac3E9589a5d5B390C82A032E',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xe631ffaa2cf4d91aac3e9589a5d5b390c82a032e.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SMT',
            'name': 'Swarm Markets',
            'address': '0xE631DABeF60c37a37d70d3B4f812871df663226f',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17488/thumb/swarm-SMT-token-symbol_200x200.png?1655373659',
            'coingeckoId': 'swarm-markets',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'B2M',
            'name': 'Bit2Me',
            'address': '0xE613a914bbb433855378183c3aB13003285da40A',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/19848/thumb/b2m-circle-solid-default.png?1636036332',
            'coingeckoId': 'bit2me',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GNFT',
            'name': 'GNFT',
            'address': '0xE58e8391BA17731C5671F9c6E00e420608Dca10e',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/23532/thumb/gnft_200x200.png?1644386728',
            'coingeckoId': 'gnft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GNS',
            'name': 'Gains Network',
            'address': '0xE5417Af564e4bFDA1c483642db72007871397896',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xe5417af564e4bfda1c483642db72007871397896.png',
            'coingeckoId': 'gains-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ARTH',
            'name': 'ARTH',
            'address': '0xE52509181FEb30EB4979E29EC70D50FD5C44D590',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/16876/small/ARTH_Token.png?1625651538',
            'coingeckoId': 'arth',
            'listedIn': [
                'coingecko',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'SKRT',
            'name': 'Sekuritance',
            'address': '0xE51E88dD08499762B8e4EB3a9f3Da9b8e79608c3',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15495/thumb/skrt.png?1621053881',
            'coingeckoId': 'sekuritance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CORE',
            'name': 'cVault.finance (PoS)',
            'address': '0xE47dbe919C8AB0c1A1C1e97A1Eefc2B946253924',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xe47dbe919c8ab0c1a1c1e97a1eefc2b946253924/logo.png',
            'coingeckoId': 'cvault-finance',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'GM',
            'name': 'Guild Member Token',
            'address': '0xE40d881Cf66e1F6D19979A5fF6c830C6Af65D278',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://ipfs.io/ipfs/QmY1uqAaLvQ2cnax4P6ETQwHJmsigS6tLUQkomd4eZJMpM',
            'coingeckoId': null,
            'listedIn': [
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'MOMA',
            'name': 'Mochi Market',
            'address': '0xE3AB61371ECc88534C522922a026f2296116C109',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14993/thumb/mochi.PNG?1619390399',
            'coingeckoId': 'mochi-market',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WARU',
            'name': 'Waru Token',
            'address': '0xE3627374Ac4baf5375e79251B0af23aFc450FC0E',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/list/master/logos/token-logos/network/polygon/0xE3627374Ac4baf5375e79251B0af23aFc450FC0E.jpg',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TST',
            'name': 'The Standard Token',
            'address': '0xE342eBB6A56cd3dBF0fe01A447FE367b9290ecf8',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/20598/thumb/TheStandard-logo_variation-01.png?1668676004',
            'coingeckoId': 'standard-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'jCHFx',
            'name': 'Superfluid jCHF',
            'address': '0xE2d04ab74eed9627c828B3fc10e5fC96FAE70348',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0xbd1463f02f61676d53fd183c2b19282bff93d099/22c2a4c156203b9278c78037fe4fc3ae.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PAR',
            'name': 'Parallel',
            'address': '0xE2Aa7db6dA1dAE97C5f5C6914d285fBfCC32A128',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xe2aa7db6da1dae97c5f5c6914d285fbfcc32a128.png',
            'coingeckoId': 'par-stablecoin',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'lELK',
            'name': 'Elk Legacy',
            'address': '0xE1C8f3d529BEa8E3fA1FAC5B416335a2f998EE1C',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xE1C8f3d529BEa8E3fA1FAC5B416335a2f998EE1C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'XTM',
            'name': 'Torum',
            'address': '0xE1C42BE9699Ff4E11674819c1885D43Bd92E9D15',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18393/thumb/torum-transparent-cmc.png?1631760805',
            'coingeckoId': 'torum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'oELK',
            'name': 'Old Elk',
            'address': '0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'EURS',
            'name': 'STASIS EURO',
            'address': '0xE111178A87A3BFf0c8d18DECBa5798827539Ae99',
            'decimals': 2,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xe111178a87a3bff0c8d18decba5798827539ae99.png',
            'coingeckoId': 'stasis-eurs',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'SNOW',
            'name': 'Snowflake',
            'address': '0xE0f463832295ADf63eB6CA053413a3f9cd8bf685',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/28228/thumb/logo200.png?1668567816',
            'coingeckoId': 'snowflake',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MADAI',
            'name': 'Matic Aave Interest Bearing DAI',
            'address': '0xE0b22E0037B130A9F56bBb537684E6fA18192341',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xE0b22E0037B130A9F56bBb537684E6fA18192341/logo.png',
            'coingeckoId': 'matic-aave-dai',
            'listedIn': [
                'coingecko',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'AGEUR',
            'name': 'agEUR',
            'address': '0xE0B52e49357Fd4DAf2c15e02058DCE6BC0057db4',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xe0b52e49357fd4daf2c15e02058dce6bc0057db4.png',
            'coingeckoId': 'ageur',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'IXT',
            'name': 'IX',
            'address': '0xE06Bd4F5aAc8D0aA337D13eC88dB6defC6eAEefE',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xe06bd4f5aac8d0aa337d13ec88db6defc6eaeefe.png',
            'coingeckoId': 'ix-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'FOLK',
            'name': ' FOLK',
            'address': '0xE05fba9fb4796deDf2E81e6F85F11D0fd6f1aDE0',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25667/thumb/folk-cryptovoxels.png?1653288100',
            'coingeckoId': 'folk',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELON',
            'name': 'Dogelon Mars',
            'address': '0xE0339c80fFDE91F3e20494Df88d4206D86024cdF',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14962/small/6GxcPRo3_400x400.jpg?1619157413',
            'coingeckoId': 'dogelon-mars',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ASTRAFER',
            'name': 'Astrafer',
            'address': '0xDfCe1e99A31C4597a3f8A8945cBfa9037655e335',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26246/small/ATSRA_Token.png?1657276411',
            'coingeckoId': 'astrafer',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'DONUT',
            'name': 'DONUT (PoS)',
            'address': '0xDe4e5B3672c4077B69216b77d99D8Ec35ec6C31A',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xde4e5b3672c4077b69216b77d99d8ec35ec6c31a/logo.png',
            'coingeckoId': 'donut',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PLAY',
            'name': 'PLAY',
            'address': '0xDd24aA9166dCDf0B37D0Fca17D6Ea87748771dCC',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xDd24aA9166dCDf0B37D0Fca17D6Ea87748771dCC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GROW',
            'name': 'Grow',
            'address': '0xDcDC86A38d1ddA13EEB346eeBf34d0148C8197d9',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/16049/thumb/grow-logo-200x200.png?1622691274',
            'coingeckoId': 'grow-token-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EASY',
            'name': 'EASY',
            'address': '0xDb3b3b147A030F032633f6C4BEBf9a2fB5a882B5',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xDb3b3b147A030F032633f6C4BEBf9a2fB5a882B5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FOREX',
            'name': 'handle fi',
            'address': '0xDb298285FE4C5410B05390cA80e8Fbe9DE1F259B',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18533/thumb/handle.fiFOREXLogoDark200x200.png?1632755675',
            'coingeckoId': 'handle-fi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANSR',
            'name': 'Answerly',
            'address': '0xDaCC6be178f123D22B672808d8494Eda03c6EbC4',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18426/thumb/logo_-_2021-09-17T161041.106.png?1631866268',
            'coingeckoId': 'answerly',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CYFM',
            'name': 'CyberFM',
            'address': '0xDa5949544aAf6124D06F398BFdE4C86Cc33B0Ee7',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/5476/thumb/cyberfm.png?1547041216',
            'coingeckoId': 'cyberfm',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'DEUSDC',
            'name': 'deUSDC',
            'address': '0xDa43Bfd7Ecc6835AA6f1761ced30b986A574c0d2',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26669/thumb/deUSDC.png?1659509853',
            'coingeckoId': 'deusdc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RMAI',
            'name': 'RMAI',
            'address': '0xDF7439E4848409f54c4E353B288Ddf56071843Fc',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'RouterProtocol'
            ]
        },
        {
            'symbol': 'DEUS',
            'name': 'DEUS Finance',
            'address': '0xDE5ed76E7c05eC5e4572CfC88d1ACEA165109E44',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18778/thumb/DEUS-logo-200x200.png?1646318385',
            'coingeckoId': 'deus-finance-2',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'DEI',
            'name': 'DEI',
            'address': '0xDE1E704dae0B4051e80DAbB26ab6ad6c12262DA0',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'XSGD',
            'name': 'XSGD',
            'address': '0xDC3326e71D45186F113a2F448984CA0e8D201995',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/12832/thumb/StraitsX_Singapore_Dollar_%28XSGD%29_Token_Logo.png?1633936813',
            'coingeckoId': 'xsgd',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'PERI',
            'name': 'PERI Finance',
            'address': '0xDC0E17eAE3B9651875030244b971fa0223a1764f',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15313/thumb/6xVEMS1.png?1620375905',
            'coingeckoId': 'peri-finance',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'RENBTC',
            'name': 'renBTC',
            'address': '0xDBf31dF14B66535aF65AaC99C32e9eA844e14501',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0xDBf31dF14B66535aF65AaC99C32e9eA844e14501/logo.png',
            'coingeckoId': 'renbtc',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BANK',
            'name': 'Bankless DAO',
            'address': '0xDB7Cb471dd0b49b29CAB4a1C14d070f27216a0Ab',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xdb7cb471dd0b49b29cab4a1c14d070f27216a0ab.png',
            'coingeckoId': 'bankless-dao',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'CrocFi',
            'name': 'Croc Finance',
            'address': '0xDB54F141f97193Cd199AB1ccF7FB2Cd2DaEc5496',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'maUSDT',
            'name': 'Matic Aave interest bearing USDT',
            'address': '0xDAE5F1590db13E3B40423B5b5c5fbf175515910b',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xDAE5F1590db13E3B40423B5b5c5fbf175515910b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NCR',
            'name': 'Neos Credits',
            'address': '0xDA6f726E2088F129D3Ecb2257206AdF7D8537Ba5',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/logos/main/network/ethereum/0xDA6f726E2088F129D3Ecb2257206AdF7D8537Ba5.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'YFI',
            'name': 'yearn finance',
            'address': '0xDA537104D6A5edd53c6fBba9A898708E465260b6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xDA537104D6A5edd53c6fBba9A898708E465260b6/logo.png',
            'coingeckoId': 'yearn-finance',
            'listedIn': [
                'coingecko',
                'uniswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DCN',
            'name': 'Dentacoin (PoS)',
            'address': '0xD9E658CAEd24fB0486E92F0F1702f77A712790DA',
            'decimals': 0,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xd9e658caed24fb0486e92f0f1702f77a712790da/logo.png',
            'coingeckoId': 'dentacoin',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'xSDT',
            'name': 'Staked SDT',
            'address': '0xD921F8318cfd786baB1ea7492673F053c518Ac04',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xD921F8318cfd786baB1ea7492673F053c518Ac04/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HAKKA',
            'name': 'Hakka Finance (PoS)',
            'address': '0xD893dde7346cc0aB46dc7EA468AeB69D9BF087c5',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xd893dde7346cc0ab46dc7ea468aeb69d9bf087c5/logo.png',
            'coingeckoId': 'hakka-finance',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'IRON',
            'name': 'Iron',
            'address': '0xD86b5923F3AD7b585eD81B448170ae026c65ae9a',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xd86b5923f3ad7b585ed81b448170ae026c65ae9a.png',
            'coingeckoId': 'iron-stablecoin',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'CEL',
            'name': 'Celsius Network',
            'address': '0xD85d1e945766Fea5Eda9103F918Bd915FbCa63E6',
            'decimals': 4,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xaaaebe6fe48e54f431b0c390cfaf0b017d09d42d.png',
            'coingeckoId': 'celsius-degree-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NCT',
            'name': 'Toucan Protocol  Nature Carbon Tonne',
            'address': '0xD838290e877E0188a4A44700463419ED96c16107',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xd838290e877e0188a4a44700463419ed96c16107.png',
            'coingeckoId': 'toucan-protocol-nature-carbon-tonne',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'UNB',
            'name': 'Unbound',
            'address': '0xD81F558b71A5323e433729009D55159955F8A7f9',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/7846.png',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'ART',
            'name': 'Artizen Token',
            'address': '0xD7F3aaC7557c213b035C19Bd89bd693396accCD6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AAVE',
            'name': 'Aave',
            'address': '0xD6DF932A45C0f255f85145f286eA0b292B21C90B',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9.png',
            'coingeckoId': 'aave',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'quickswap',
                'dfyn',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WRLD',
            'name': 'NFT Worlds',
            'address': '0xD5d86FC8d5C0Ea1aC1Ac5Dfab6E529c9967a45E9',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/22112/thumb/ZyBrRgfO.jpg?1640842284',
            'coingeckoId': 'nft-worlds',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAS',
            'name': 'MAS FINANCE',
            'address': '0xD57e36df3E3180ccDAbfAFAcedF3DfAA08390fE9',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27093/thumb/20220822_011701.png?1661992074',
            'coingeckoId': 'mas-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORE',
            'name': 'ORE Network',
            'address': '0xD52f6CA48882Be8fbaa98ce390db18e1dbe1062d',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xd52f6ca48882be8fbaa98ce390db18e1dbe1062d.png',
            'coingeckoId': 'ptokens-ore',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'LUXY',
            'name': 'Luxy',
            'address': '0xD4945a3D0De9923035521687D4bf18cC9B0c7c2A',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xd4945a3d0de9923035521687d4bf18cc9b0c7c2a.png',
            'coingeckoId': 'luxy',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': '1ART',
            'name': 'ArtWallet',
            'address': '0xD3c325848D7c6E29b574Cb0789998b2ff901f17E',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1635386525569_726327472199642.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'YEL',
            'name': 'Yel Finance',
            'address': '0xD3b71117E6C1558c1553305b44988cd944e97300',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xd3b71117e6c1558c1553305b44988cd944e97300.png',
            'coingeckoId': 'yel-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'CXBTC',
            'name': 'CelsiusX Wrapped BTC',
            'address': '0xD30DC92b8ec0fa8de625768d208f51A93C10aFF2',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25535/thumb/cxBTC.png?1652252129',
            'coingeckoId': 'celsiusx-wrapped-btc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOA',
            'name': 'Doaibu',
            'address': '0xD2Ee3816c553E06DdE44A7eEd920E7F7b5A49230',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24467/thumb/200x200_DOA_Original.png?1650436738',
            'coingeckoId': 'doaibu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHARK',
            'name': 'PolyShark Finance',
            'address': '0xD201B8511aaB3E9b094b35ABcD5d7863c78D6d0e',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15398/thumb/2KY2RdEo_400x400.png?1620714513',
            'coingeckoId': 'polyshark-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SFL',
            'name': 'Sunflower Land',
            'address': '0xD1f9c58e33933a993A3891F8acFe05a68E1afC05',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25514/small/download.png?1652164203',
            'coingeckoId': 'sunflower-land',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'CNT',
            'name': 'Cryption Network',
            'address': '0xD1e6354fb05bF72A8909266203dAb80947dcEccF',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://cryption-network.s3.us-east-2.amazonaws.com/Globe+Gradient.png',
            'coingeckoId': 'cryption-network',
            'listedIn': [
                'coingecko',
                'quickswap',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'KEYFI',
            'name': 'KeyFi',
            'address': '0xD1A5f2A049343fc4D5F8d478f734eBa51B22375E',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/8561.png',
            'coingeckoId': 'keyfi',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BOOTY',
            'name': 'Pirate Booty',
            'address': '0xD12DC5319808Bb31ba95AE5764def2627d5966CE',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/16130/small/boty.PNG?1623052148',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'FBX',
            'name': 'FireBot',
            'address': '0xD125443F38A69d776177c2B9c041f462936F8218',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xd125443f38a69d776177c2b9c041f462936f8218.png',
            'coingeckoId': 'firebot',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'APE',
            'name': 'ApeCoin',
            'address': '0xD10DA41D535078F23f68bD90e28B50b31fc40DBc',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DEP',
            'name': 'DEAPCOIN (PoS)',
            'address': '0xD0ee109352c6116dB0f17F4Aa8519CdBfc7e7887',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xd0ee109352c6116db0f17f4aa8519cdbfc7e7887/logo.png',
            'coingeckoId': 'deapcoin',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'WOOFY',
            'name': 'Woofy',
            'address': '0xD0660cD418a64a1d44E9214ad8e459324D8157f1',
            'decimals': 12,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0xD0660cD418a64a1d44E9214ad8e459324D8157f1/logo.png',
            'coingeckoId': 'woofy',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'FINT',
            'name': 'Fintropy',
            'address': '0xD0513DB39d87e8825389fEB10BD911dC53B3a153',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/21222/thumb/quExJXdW_400x400.png?1638740691',
            'coingeckoId': 'fintropy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARGO',
            'name': 'ArGo Token',
            'address': '0xD0252fB67606Ed74d0cACD17B2eb38446e4466c9',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://i.imgur.com/8ItdsvJ.png',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'PSPACE',
            'name': 'pSPACE',
            'address': '0xD016cAAe879c42cB0D74BB1A265021bf980A7E96',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/16984/thumb/space_token_2.png?1625908642',
            'coingeckoId': 'pspace',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USX',
            'name': 'dForce USD',
            'address': '0xCf66EB3D546F0415b368d98A95EAF56DeD7aA752',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://get.celer.app/cbridge-icons/USX.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'OPT3',
            'name': 'Optimus OPT3',
            'address': '0xCf630283E8Ff2e30C29093bC8aa58CADD8613039',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27817/thumb/optimus-logo-03.png?1665913885',
            'coingeckoId': 'optimus-opt3',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OOKI',
            'name': 'OOKI',
            'address': '0xCd150B1F528F326f5194c012f32Eb30135C7C2c9',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0xCd150B1F528F326f5194c012f32Eb30135C7C2c9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'oXRP',
            'name': 'Orbit Bridge Polygon XRP Token',
            'address': '0xCc2a9051E904916047c26C90f41c000D4f273456',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xcc2a9051e904916047c26c90f41c000d4f273456.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'RIV',
            'name': 'Richverse',
            'address': '0xCa5e32d44F1744001b5600Dc2F5F5e0BbB6E9D3e',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26892/thumb/WechatIMG2340.jpeg?1660708132',
            'coingeckoId': 'richverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YIELD',
            'name': 'PolyYield',
            'address': '0xCE4e6DA9c509Cb33C23d748713c681C959f68658',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/16656/thumb/polylogo.png?1624602286',
            'coingeckoId': 'polyyield-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TDL',
            'name': 'TDL Fake Token',
            'address': '0xCD1F2F1a1d1ba631A06b957DB77BB9D7b13bF861',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'NFTY',
            'name': 'NFTY Labs',
            'address': '0xCC081220542a60A8Ea7963C4F53D522b503272c1',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18741/thumb/logo_%281%29.png?1643188469',
            'coingeckoId': 'nfty-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SING',
            'name': 'Sing',
            'address': '0xCB898b0eFb084Df14dd8E018dA37B4d0f06aB26D',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/singularfarm/assets/main/200.png',
            'coingeckoId': 'sing-token',
            'listedIn': [
                'coingecko',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'jAUD',
            'name': 'Jarvis Synthetic Australian Dollar',
            'address': '0xCB7F1Ef7246D1497b985f7FC45A1A31F04346133',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'ipfs://QmW4pR69muQ3Ceqni5ThLvhtd7MrUbQwgjcm4NrJt8ZMjS',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'USDCx',
            'name': 'Super USDC (PoS)',
            'address': '0xCAa7349CEA390F89641fe306D93591f87595dc1F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x2791bca1f2de4661ed88a30c99a7a9449aa84174/fffcd27b9efff5a86ab942084c05924d.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FTM',
            'name': 'Fantom Token (PoS)',
            'address': '0xC9c1c1c20B3658F8787CC2FD702267791f224Ce1',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xc9c1c1c20b3658f8787cc2fd702267791f224ce1/logo.png',
            'coingeckoId': 'fantom',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'RAZOR',
            'name': 'Razor Network',
            'address': '0xC91c06DB0f7bFFba61e2A5645CC15686F0a8c828',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xC91c06DB0f7bFFba61e2A5645CC15686F0a8c828/logo.png',
            'coingeckoId': 'razor-network',
            'listedIn': [
                'coingecko',
                'dfyn',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NEXM',
            'name': 'Nexum',
            'address': '0xC88640B734fEA3b35c132Fe757AeB5ca6C8cDC66',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/23472/thumb/200_-_200_coinmarketcap.png?1644218225',
            'coingeckoId': 'nexum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OLE',
            'name': 'OpenLeverage',
            'address': '0xC7eD9aA2B7d302FD5a68dFbefe5F530F9bd35A06',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PAXW',
            'name': 'pax world',
            'address': '0xC79Ae93D9C215EAa8c8DA5C77E465baC7dE28891',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27944/thumb/paxcoin_200.fw.png?1666604081',
            'coingeckoId': 'pax-world',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ROLL',
            'name': 'Polyroll',
            'address': '0xC68e83a305b0FaD69E264A1769a0A070F190D2d6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0xC68e83a305b0FaD69E264A1769a0A070F190D2d6/logo.png',
            'coingeckoId': 'polyroll',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'POP',
            'name': 'Popcorn',
            'address': '0xC5B57e9a1E7914FDA753A88f24E5703e617Ee50c',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xc5b57e9a1e7914fda753a88f24e5703e617ee50c.png',
            'coingeckoId': 'popcorn',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'MFTU',
            'name': 'Mainstream For The Underground',
            'address': '0xC46A37fBBe433EF24bc7b9388C8728DDcf3cA87c',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/5519/thumb/Mainstream_for_the_underground.png?1534426154',
            'coingeckoId': 'mainstream-for-the-underground',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'FTRB',
            'name': 'Faith Tribe',
            'address': '0xC3f56d567E7663e8932E65d85ae4be7Eb5575cA7',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/23939/thumb/ym1Hf4x2_400x400.jpg?1645766940',
            'coingeckoId': 'faith-tribe',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OM',
            'name': 'MANTRA',
            'address': '0xC3Ec80343D2bae2F8E680FDADDe7C17E71E114ea',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x3593d125a4f7849a1b059e64f4517a86dd60c95d.png',
            'coingeckoId': 'mantra-dao',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'dfyn',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'LDO',
            'name': 'Lido DAO',
            'address': '0xC3C7d422809852031b44ab29EEC9F1EfF2A58756',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xc3c7d422809852031b44ab29eec9f1eff2a58756.png',
            'coingeckoId': 'lido-dao',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'IbAlluoUSD',
            'name': 'Interest Bearing Alluo USD',
            'address': '0xC2DbaAEA2EfA47EBda3E572aa0e55B742E408BF6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'OATH',
            'name': 'Oath Token',
            'address': '0xC2C52FF5134596f5FF1b1204d3304228f2432836',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'IDLE',
            'name': 'IDLE',
            'address': '0xC25351811983818c9Fe6D8c580531819c8ADe90f',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0xC25351811983818c9Fe6D8c580531819c8ADe90f/logo.png',
            'coingeckoId': 'idle',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'CLAM',
            'name': 'OtterClam',
            'address': '0xC250e9987A032ACAC293d838726C511E6E1C029d',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xc250e9987a032acac293d838726c511e6e1c029d.png',
            'coingeckoId': 'otterclam',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'RISE',
            'name': 'EverRise',
            'address': '0xC17c30e98541188614dF99239cABD40280810cA3',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/16367/thumb/Logo_EverRise_Icon_logo.png?1642576670',
            'coingeckoId': 'everrise',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DFYN',
            'name': 'Dfyn Network',
            'address': '0xC168E40227E4ebD8C1caE80F7a55a4F0e6D66C97',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xc168e40227e4ebd8c1cae80f7a55a4f0e6d66c97.png',
            'coingeckoId': 'dfyn-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'BWO',
            'name': 'Battle World',
            'address': '0xC1543024DC71247888a7e139c644F44E75E96d38',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25799/thumb/battleworld_logo_200x200.png?1653983575',
            'coingeckoId': 'battle-world',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIVA',
            'name': 'Minerva Wallet SuperToken',
            'address': '0xC0b2983A17573660053BEEED6FDb1053107cf387',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0xc0b2983a17573660053beeed6fdb1053107cf387/b65645c87f93ec15abc7f99ad973f393.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'KOM',
            'name': 'Kommunitas',
            'address': '0xC004e2318722EA2b15499D6375905d75Ee5390B8',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xc004e2318722ea2b15499d6375905d75ee5390b8.png',
            'coingeckoId': 'kommunitas',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ARDU',
            'name': 'Circolo Arduino Token (PoS)',
            'address': '0xBe319A08eBE5da37C5A8ce8a0D8463ff6750e53B',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xbe319a08ebe5da37c5a8ce8a0d8463ff6750e53b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'WEB3',
            'name': 'Arch Ethereum Web3',
            'address': '0xBcD2C5C78000504EFBC1cE6489dfcaC71835406A',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26562/thumb/WEB3-TOKEN.png?1658798344',
            'coingeckoId': 'arch-ethereum-web3',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWISE',
            'name': 'StakeWise',
            'address': '0xBbbe128568222623D21299f019a978C8587B33dC',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xBbbe128568222623D21299f019a978C8587B33dC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SAND',
            'name': 'The Sandbox',
            'address': '0xBbba073C31bF03b8ACf7c28EF0738DeCF3695683',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xbbba073c31bf03b8acf7c28ef0738decf3695683.png',
            'coingeckoId': 'the-sandbox',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'quickswap',
                'dfyn',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'POL',
            'name': 'Polars',
            'address': '0xBb5FcFCC96E9e8E22A1a7C12abbA67FDd0f84665',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'UGT',
            'name': 'Unreal Governance Token',
            'address': '0xBa4c54Ea2d66b904C82847A7d2357d22B857E812',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/200x200/11292.png',
            'coingeckoId': null,
            'listedIn': [
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'DEFY',
            'name': 'DEFY',
            'address': '0xBF9f916bBda29A7F990F5F55c7607D94D7C3A60b',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26877/thumb/8ybr83fb0ca45cm1yvrcaclwbvcp.jpeg?1660622066',
            'coingeckoId': 'defy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AIRT',
            'name': 'AirNFT Token',
            'address': '0xBCD287ca1C614Ecd8c5Ca95bFf3c13c392192df5',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PAW',
            'name': 'Paw V2',
            'address': '0xBC5b59EA1b6f8Da8258615EE38D40e999EC5D74F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/22836/thumb/v9uvwsJ.png?1642727101',
            'coingeckoId': 'paw-v2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CCAKE',
            'name': 'CheesecakeSwap',
            'address': '0xBC2597D3f1F9565100582CDe02E3712D03B8B0f6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14547/thumb/CCAKElogo.png?1616984147',
            'coingeckoId': 'cheesecakeswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUNR',
            'name': 'Lunr',
            'address': '0xBBfE0B60dE96a189BF09079DE86A2Db7bF0C7883',
            'decimals': 4,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/19256/thumb/lunr.png?1639975807',
            'coingeckoId': 'lunr-token',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MCRN',
            'name': 'MacaronSwap',
            'address': '0xBA25B552C8A098AFdf276324C32C71fE28e0Ad40',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14633/thumb/macaron.png?1623038435',
            'coingeckoId': 'macaronswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REI',
            'name': 'Zerogoki',
            'address': '0xB9f9e37c2CdbaFF928C3Da730b02F06fE09aE70E',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17015/thumb/iconZerogoki.png?1626618921',
            'coingeckoId': 'zerogoki',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IQ',
            'name': 'IQ',
            'address': '0xB9638272aD6998708de56BBC0A290a1dE534a578',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xb9638272ad6998708de56bbc0a290a1de534a578.png',
            'coingeckoId': 'everipedia',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'LMT',
            'name': 'LuckyMeta',
            'address': '0xB8bDB98AF8C987336B9476Fd8a98BdF190b6f10E',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26554/thumb/LMT.png?1658890729',
            'coingeckoId': 'luckymeta',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FTM',
            'name': 'Fantom Token',
            'address': '0xB85517b87BF64942adf3A0B9E4c71E4Bc5Caa4e5',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/4001/small/Fantom.png?1558015016',
            'coingeckoId': null,
            'listedIn': [
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'nICE',
            'name': 'Staked Ice Tokens',
            'address': '0xB808D767DDf0F61Ca62331c1bBbebe0530b41079',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ROTIx',
            'name': 'Superfluid ROTI',
            'address': '0xB7d60973cC27324Dcae837d89d7Bd9c5f15e3833',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'APE',
            'name': 'ApeCoin',
            'address': '0xB7b31a6BC18e48888545CE79e83E06003bE70930',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24383/thumb/apecoin.jpg?1647476455',
            'coingeckoId': 'apecoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WISE',
            'name': 'Wise Token',
            'address': '0xB77e62709e39aD1cbeEBE77cF493745AeC0F453a',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x66a0f676479cee1d7373f3dc2e2952778bff5bd6.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'quickswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PNT',
            'name': 'pTokens PNT',
            'address': '0xB6bcae6468760bc0CDFb9C8ef4Ee75C9dd23e1Ed',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/11659/small/pNetwork.png?1592411134',
            'coingeckoId': null,
            'listedIn': [
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'IEUROS',
            'name': 'Inflation Adjusted EURO',
            'address': '0xB66930d1299eaF9f6B8354c539E5F83B75794aC4',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26442/thumb/894CE468-A364-42BA-83B0-60A7CA859614.png?1658110157',
            'coingeckoId': 'inflation-adjusted-euro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DRK',
            'name': 'Drakoin (PoS)',
            'address': '0xB6509Cbd9e2D1CeC787a7357eB1578B86a0c702d',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xb6509cbd9e2d1cec787a7357eb1578b86a0c702d/logo.png',
            'coingeckoId': 'drakoin',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'IDLEx',
            'name': 'Superfluid IDLE',
            'address': '0xB63E38D21B31719e6dF314D3d2c351dF0D4a9162',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0xc25351811983818c9fe6d8c580531819c8ade90f/a611f79214d38d424c3cab44f47af9df.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'QUICK',
            'name': 'Quickswap',
            'address': '0xB5C064F955D8e7F38fE0460C556a72987494eE17',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://i.ibb.co/HGWTLM7/Quick-Icon-V2.png',
            'coingeckoId': 'quickswap',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SPACE',
            'name': 'Space Token BSC',
            'address': '0xB53Ec4aCe420a62Cfb75aFdEba600D284777cd65',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/20676/thumb/jYw3kgsY_400x400.png?1637550733',
            'coingeckoId': 'space-token-bsc',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'TIDAL',
            'name': 'Tidal Finance',
            'address': '0xB41EC2c036f8a42DA384DDE6ADA79884F8b84b26',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14460/thumb/Tidal-mono.png?1616233894',
            'coingeckoId': 'tidal-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KIRO',
            'name': 'KIRO',
            'address': '0xB382C1cfA622795a534e5bd56Fac93d59BAc8B0D',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/12688/small/QmScxyKBwqbGJZmp38EwaoRpXbzPkq3tvuMjeuJE1YLZeG.png?1601672684',
            'coingeckoId': 'kirobo',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'DERC',
            'name': 'DeRace',
            'address': '0xB35fcBCF1fD489fCe02Ee146599e893FDCdC60e6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xb35fcbcf1fd489fce02ee146599e893fdcdc60e6.png',
            'coingeckoId': 'derace',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BTCBR',
            'name': 'Bitcoin BR',
            'address': '0xB355f4F4CC84a9429a59d5c2B98d77016f7EC482',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/21205/thumb/btcbr.png?1661666691',
            'coingeckoId': 'bitcoin-br',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOMB',
            'name': 'Bombcrypto Coin',
            'address': '0xB2C63830D4478cB331142FAc075A39671a5541dC',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26399/thumb/Bombcrypto_Coin.png?1657797692',
            'coingeckoId': 'bombcrypto-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GTE',
            'name': 'GO2E GTE',
            'address': '0xB2780c1D05E50a98f8851668B1B59b92054D1fbc',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27885/thumb/logo_200_x_200.png?1666239126',
            'coingeckoId': 'go2e-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REQ',
            'name': 'Request',
            'address': '0xB25e20De2F2eBb4CfFD4D16a55C7B395e8a94762',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xb25e20de2f2ebb4cffd4d16a55c7b395e8a94762.png',
            'coingeckoId': 'request-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BAG',
            'name': 'Blockchain Adventurers Guild',
            'address': '0xB1d82666384bE5F8C59AA18e650493ABb8A614Ad',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14863/thumb/3iw7MAi.png?1618810870',
            'coingeckoId': 'blockchain-adventurers-guild',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '$TRDL',
            'name': 'Strudel Finance (PoS)',
            'address': '0xB1c0559c31b4882840614A12aDafef36d31ED905',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xb1c0559c31b4882840614a12adafef36d31ed905/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'POLYSAFE',
            'name': 'PolySafe',
            'address': '0xB1CB4aC6b1685DcbE691b9246406748805aa6FA8',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/BlockTimeWorld/crawler/main/icons/polysafe_32.png',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'ATRI',
            'name': 'Atari',
            'address': '0xB140665ddE25c644c6B418e417C930dE8A8a6Ac9',
            'decimals': 0,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/12992/thumb/AtariLogoPS_200x200_%281%29.png?1643189483',
            'coingeckoId': 'atari',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'GUARD',
            'name': 'Guardian',
            'address': '0xB0B2Ef34D412d73b0Ff90A709D1779A20655165A',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BOB',
            'name': 'BOB',
            'address': '0xB0B195aEFA3650A6908f15CdaC7D92F8a5791B0B',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b.png',
            'coingeckoId': 'bob',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'FLOATx',
            'name': 'Superfluid FLOAT',
            'address': '0xAff1CE7832a1c7655803533DAb391920caFE467F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BIG',
            'name': 'BIG Token',
            'address': '0xAfAEb84415c1cd2bC99cd1F5F0B3090baE1BEb6c',
            'decimals': 9,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ETM',
            'name': 'ethersmart (PoS)',
            'address': '0xAf95C20C60b49aD88024d8e77858900D9f2c98Bc',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xaf95c20c60b49ad88024d8e77858900d9f2c98bc/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ELE',
            'name': 'Eleven.finance',
            'address': '0xAcD7B3D9c10e97d0efA418903C0c7669E702E4C0',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xAcD7B3D9c10e97d0efA418903C0c7669E702E4C0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'dfyn',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NGNC',
            'name': 'NGN Coin',
            'address': '0xAb9aD9089f23e6779a8727900709427719f753e1',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/list/master/logos/token-logos/network/polygon/0xAb9aD9089f23e6779a8727900709427719f753e1.jpg',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'mUSDx',
            'name': 'Superfluid mUSD',
            'address': '0xAb0b048E8b60EB9e8c7a2d46634326143393f2Ea',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0xe840b73e5287865eec17d250bfb1536704b43b21/8d72747ec45f49e310be371a7204a52a.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DINO',
            'name': 'DinoSwap',
            'address': '0xAa9654BECca45B5BDFA5ac646c939C62b527D394',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://ethapi.openocean.finance/logos/polygon/0xaa9654becca45b5bdfa5ac646c939c62b527d394.png',
            'coingeckoId': 'dinoswap',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'MCO2',
            'name': 'Moss Carbon Credit (PoS)',
            'address': '0xAa7DbD1598251f856C12f63557A4C4397c253Cea',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xaa7dbd1598251f856c12f63557a4c4397c253cea.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'VINU',
            'name': 'Vita Inu',
            'address': '0xAFCdd4f666c84Fed1d8BD825aA762e3714F652c9',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/20594/thumb/vita-inu-head-wallet-icon-transparent.png?1637286122',
            'coingeckoId': 'vita-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IOI',
            'name': 'IOI',
            'address': '0xAF24765F631C8830B5528B57002241eE7eef1C14',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15952/thumb/IOI_new_logo.png?1636797701',
            'coingeckoId': 'ioi-token',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'AWG',
            'name': 'AurusGOLD',
            'address': '0xAEe0ffb690B37449B7f1C49B199E1E3ec6084490',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/11594/thumb/2021-12-06-Aurus-tokens-for-coingecko-AWG-flat-color-v1-r1-AS.png?1640223324',
            'coingeckoId': 'aurusgold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IDOL',
            'name': 'IDOL',
            'address': '0xAEc815Ae1E9AB58f49692aeC2840FfC82C56F7E5',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/22458/thumb/2cUUypxU_400x400.png?1641879690',
            'coingeckoId': 'idol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIMO',
            'name': 'Mimo Governance',
            'address': '0xADAC33f543267c4D59a8c299cF804c303BC3e4aC',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xadac33f543267c4d59a8c299cf804c303bc3e4ac.png',
            'coingeckoId': 'mimo-parallel-governance-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'jPHPx',
            'name': 'Superfluid jPHP',
            'address': '0xAD0FfBB4E45C42A45A6206383Ce2AdF9102cDcB9',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'ipfs://QmS65LifvH8eiuZPW4xbBxDCCgdZ1S9jWrCtEe489MHBtV',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'LACE',
            'name': 'Lovelace',
            'address': '0xAD0Ddb54d79D2de572e25cd9375782AABb440353',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FLOKIFM',
            'name': 'FLOKIFOMO',
            'address': '0xACb5F391F51e15418845BEC74781BDd1CbE0Fd89',
            'decimals': 9,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'USV',
            'name': 'Atlas USV',
            'address': '0xAC63686230f64BDEAF086Fe6764085453ab3023F',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0xAC63686230f64BDEAF086Fe6764085453ab3023F/logo.png',
            'coingeckoId': 'atlas-usv',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SDS',
            'name': 'SafeDollar Shares',
            'address': '0xAB72EE159Ff70b64beEcBbB0FbBE58b372391C54',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/16867/thumb/1_551bIKFiNCvKXdUloML0wA.png?1625474832',
            'coingeckoId': 'safedollar-shares',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GBYTE',
            'name': 'Obyte',
            'address': '0xAB5F7a0e20b0d056Aed4Aa4528C78da45BE7308b',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/561/thumb/byteball.png?1547564361',
            'coingeckoId': 'byteball',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'mooCurveAm3CRV',
            'name': 'Moo Curve am3CRV',
            'address': '0xAA7C2879DaF8034722A0977f13c343aF0883E92e',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MAMA',
            'name': 'Mama DAO',
            'address': '0xA9a779aeA33b6f40CfC78A29Cf56Fc7e6fb329AB',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/22315/thumb/Y8Jennvy_400x400.jpg?1641452792',
            'coingeckoId': 'mama-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AWS',
            'name': 'AurusSILVER',
            'address': '0xA96D47c621a8316d4F9539E3B38180C7067e84CA',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14965/small/2021-12-06-Aurus-tokens-for-coingecko-AWS-flat-color-v1-r1-AS.png?1640223368',
            'coingeckoId': 'aurus-silver',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'PECO',
            'name': 'Amun Polygon Ecosystem Index',
            'address': '0xA9536B9c75A9E0faE3B56a96AC8EdF76AbC91978',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xa9536b9c75a9e0fae3b56a96ac8edf76abc91978.png',
            'coingeckoId': 'polygon-ecosystem-index',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'WLD',
            'name': 'wLitiDAO',
            'address': '0xA936e1f747d14fC30d08272D065c8aeF4ab7f810',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xa936e1f747d14fc30d08272d065c8aef4ab7f810.png',
            'coingeckoId': 'wlitidao',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'QUARTZ',
            'name': 'Sandclock',
            'address': '0xA91FE5a535967F52D3abEBDFFb3B306D964ace13',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/19368/thumb/sandclock.jpg?1635132501',
            'coingeckoId': 'sandclock',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LIT',
            'name': 'LITION (PoS)',
            'address': '0xA8e198FC5C68264B185619A96748f855720494c0',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0xa8e198fc5c68264b185619a96748f855720494c0/logo.png',
            'coingeckoId': 'lition',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BAND',
            'name': 'Band Protocol',
            'address': '0xA8b1E0764f85f53dfe21760e8AfE5446D82606ac',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/9545/thumb/band-protocol.png?1568730326',
            'coingeckoId': null,
            'listedIn': [
                'uniswap',
                'rubic'
            ]
        },
        {
            'symbol': 'STR',
            'name': 'Stater',
            'address': '0xA79E0Bfc579c709819f4A0E95d4597f03093B011',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xA79E0Bfc579c709819f4A0E95d4597f03093B011/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'TDLx',
            'name': 'Superfluid TDL',
            'address': '0xA794221D92d77490Ff319e95dA1461bdF2bd3953',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'HT',
            'name': 'Huobi Token',
            'address': '0xA731349fa468614c1698fc46ebf06Da6F380239e',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/2822/small/huobi-token-logo.png?1547036992',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'UFARM',
            'name': 'UniFarm',
            'address': '0xA7305Ae84519fF8Be02484CdA45834C4E7D13Dd6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://oropocket.com/ufarm.png',
            'coingeckoId': 'unifarm',
            'listedIn': [
                'coingecko',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'TXAU',
            'name': 'tGOLD',
            'address': '0xA6da8C8999c094432c77E7d318951D34019AF24B',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27828/thumb/tGOLD_token_2D.jpg?1666165789',
            'coingeckoId': 'tgold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WBNB',
            'name': 'Wrapped BNB',
            'address': '0xA649325Aa7C5093d12D6F98EB4378deAe68CE23F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0xA649325Aa7C5093d12D6F98EB4378deAe68CE23F/logo.png',
            'coingeckoId': 'wbnb',
            'listedIn': [
                'coingecko',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'LKR',
            'name': 'Lokr',
            'address': '0xA5Ff48e326958E0CE6FdF9518de561F2B5f57dA3',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14692/thumb/lokr.png?1648886932',
            'coingeckoId': 'lokr',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XCAD',
            'name': 'XCAD Network',
            'address': '0xA55870278d6389ec5B524553D03C04F5677c061E',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xa55870278d6389ec5b524553d03c04f5677c061e.png',
            'coingeckoId': 'xcad-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BABY',
            'name': 'Babylonia',
            'address': '0xA4E26Bd6DCBa9021DCd3A1159BA52e97CD770b8a',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26352/thumb/Babylonia_Logo_02.01_FFD42A_200px.png?1657590351',
            'coingeckoId': 'babylonia',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ONSTON',
            'name': 'Onston',
            'address': '0xA4Ce4a467E51aEfEc683a649C3F14427f104667f',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/20669/thumb/onston.PNG?1637547859',
            'coingeckoId': 'onston',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'NEX',
            'name': 'Nash',
            'address': '0xA486c6BC102f409180cCB8a94ba045D39f8fc7cB',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xa486c6bc102f409180ccb8a94ba045d39f8fc7cb.png',
            'coingeckoId': 'neon-exchange',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'EB',
            'name': 'Endless Battlefield',
            'address': '0xA41eE9a01fd417c372B318746d8891c0C240A73c',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/23981/thumb/eb.png?1645878954',
            'coingeckoId': 'endless-battlefield',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MV',
            'name': 'GensoKishi Metaverse',
            'address': '0xA3c322Ad15218fBFAEd26bA7f616249f7705D945',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xa3c322ad15218fbfaed26ba7f616249f7705d945.png',
            'coingeckoId': 'gensokishis-metaverse',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ECH',
            'name': 'Echelon',
            'address': '0xA3baC05723c35aA0b30Ea63F5A5e9986465a9391',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25498/thumb/SpO_F8a0_400x400.jpg?1652077746',
            'coingeckoId': 'echelon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YGN',
            'name': 'YUGEN (PoS)',
            'address': '0xA33aAa07853038943eF2c32cDE73a094cF993EE0',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MANA',
            'name': 'Decentraland',
            'address': '0xA1c57f48F0Deb89f569dFbE6E2B7f46D33606fD4',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x0f5d2fb29fb7d3cfee444a200298f468908cc942.png',
            'coingeckoId': 'decentraland',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'quickswap',
                'dfyn',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'VERSE',
            'name': 'Shibaverse',
            'address': '0xA1388e73c51B37383B1Ab9dCe8317eF5A0349CC5',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18407/thumb/logo_200.png?1631795328',
            'coingeckoId': 'shibaverse',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MIM',
            'name': 'Mint Marble',
            'address': '0xA12566Dd315B1419e7B9F5f4E5559a7E7E48Db22',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26170/thumb/mim.png?1661689463',
            'coingeckoId': 'mint-marble',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PMATIC',
            'name': 'Ripae pMATIC',
            'address': '0xA0dF47432d9d88bcc040E9ee66dDC7E17A882715',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://matic.ripae.finance/static/media/ripae_pftm.1a3fe2b4.svg',
            'coingeckoId': 'ripae-pmatic',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BOND',
            'name': 'BarnBridge Governance Token',
            'address': '0xA041544fe2BE56CCe31Ebb69102B965E06aacE80',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0xA041544fe2BE56CCe31Ebb69102B965E06aacE80/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BULL',
            'name': 'Bullieverse',
            'address': '0x9f95e17b2668AFE01F8fbD157068b0a4405Cc08D',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24174/thumb/KR3qVAQe_400x400.jpg?1646747837',
            'coingeckoId': 'bullieverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XSHARE',
            'name': 'SpaceShipX',
            'address': '0x9f64D1aAcb129B844500119B50938c57aa9aD6E5',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/28004/thumb/CRYTOLOGO.png?1666929728',
            'coingeckoId': 'xshare',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OM',
            'name': 'OM',
            'address': '0x9f5755D47fB80100E7ee65Bf7e136FCA85Dd9334',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x9f5755D47fB80100E7ee65Bf7e136FCA85Dd9334/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'AMLP',
            'name': 'aMLP',
            'address': '0x9e84154798a96E8b50d96d423862da2c7c30eC4C',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/28014/thumb/CRYTOLOGO%281%29.png?1666948582',
            'coingeckoId': 'amlp',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHICK',
            'name': 'loserchick',
            'address': '0x9e725Cf7265D12fd5f59499AFf1258CA92CAc74d',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://i.loli.net/2021/07/28/K83jnTJzG9bq7Xt.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ISHND',
            'name': 'StrongHands Finance',
            'address': '0x9e6b19874e97fe8E8CAD77f2c0AB5E7a693E5dbf',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/20158/thumb/ISHND_512x512px.png?1647512898',
            'coingeckoId': 'stronghands-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHO',
            'name': 'Showcase',
            'address': '0x9e01e0A928588aE6E669b8d1F0f1fa4AB976f617',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x9e01e0A928588aE6E669b8d1F0f1fa4AB976f617/logo.png',
            'coingeckoId': 'showcase-token',
            'listedIn': [
                'coingecko',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CADC',
            'name': 'CAD Coin',
            'address': '0x9de41aFF9f55219D5bf4359F167d1D0c772A396D',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14149/thumb/cadc_2.png?1614666625',
            'coingeckoId': 'cad-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UPO',
            'name': 'UpOnly',
            'address': '0x9dBfc1cbf7a1E711503a29B4b5F9130ebeCcaC96',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24900/thumb/f-WNwLNJ_400x400.jpg?1649323846',
            'coingeckoId': 'uponly-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AKT',
            'name': 'akash.network (PoS)',
            'address': '0x9d600c84f5C9cCfe36c03Cb34D63Ea834CC335D8',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x9d600c84f5c9ccfe36c03cb34d63ea834cc335d8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'OOE',
            'name': 'OpenOcean',
            'address': '0x9d5565dA88e596730522CbC5a918d2A89dbC16d9',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://cloudstorage.openocean.finance/image/ooe_log.png',
            'coingeckoId': 'openocean',
            'listedIn': [
                'coingecko',
                'openocean',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'KYL',
            'name': 'Kylin Network (PoS)',
            'address': '0x9ccdf331f97449A84D100918e1e233B097A3E13F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x9ccdf331f97449a84d100918e1e233b097a3e13f/logo.png',
            'coingeckoId': 'kylin-network',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ISLAMI',
            'name': 'ISLAMICOIN',
            'address': '0x9c891326Fd8b1a713974f73bb604677E1E63396D',
            'decimals': 7,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24749/thumb/3100-x-3100-px-e1632233028219.png?1648786180',
            'coingeckoId': 'islamicoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CC10',
            'name': 'Cryptocurrency Top Tokens Index (PoS)',
            'address': '0x9c49BA0212Bb5Db371e66b59D1565b7c06E4894e',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x9c49BA0212Bb5Db371e66b59D1565b7c06E4894e/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': '1INCH',
            'name': '1inch',
            'address': '0x9c2C5fd7b07E95EE044DDeba0E97a665F142394f',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/13469/thumb/1inch-token.png?1608803028',
            'coingeckoId': '1inch',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'PUSD',
            'name': 'PUSD Polyquity',
            'address': '0x9aF3b7DC29D3C4B1A5731408B6A9656fA7aC3b72',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x9aF3b7DC29D3C4B1A5731408B6A9656fA7aC3b72/logo.png',
            'coingeckoId': 'pusd',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'FFF',
            'name': 'Future of Finance Fund',
            'address': '0x9aCeB6f749396d1930aBc9e263eFc449E5e82c13',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15761/small/xg1NFl0.png?1621825451',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BAL',
            'name': 'Balancer',
            'address': '0x9a71012B13CA4d3D0Cdc72A177DF3ef03b0E76A3',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x9a71012b13ca4d3d0cdc72a177df3ef03b0e76a3.png',
            'coingeckoId': 'balancer',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'SKZ',
            'name': 'SNKRZ',
            'address': '0x9a6469aaC7cd70385bdCa6fA9E9356ACec9c5B16',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27657/thumb/skz_200_200.png?1665099924',
            'coingeckoId': 'snkrz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHAIN',
            'name': 'Arch Blockchains',
            'address': '0x9a41E03fEF7f16f552C6FbA37fFA7590fb1Ec0c4',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26563/thumb/CHAINS.png?1658798607',
            'coingeckoId': 'arch-blockchains',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABYQUICK',
            'name': 'BabyQuick',
            'address': '0x9a05D1FF699ea187Dc8523E333eD63503f0d82db',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/20147/thumb/4fa6uMzp_400x400.jpg?1636551709',
            'coingeckoId': 'babyquick',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MULTI',
            'name': 'Multichain',
            'address': '0x9Fb9a33956351cf4fa040f65A13b835A3C8764E3',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1669116498450_2410692863955417.png',
            'coingeckoId': 'multichain',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'UNCX',
            'name': 'UniCrypt (PoS)',
            'address': '0x9EECD634c7a934F752aF0EB90DdA9Ecc262F199F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'KONG',
            'name': 'kongdefi.finance (PoS)',
            'address': '0x9ECf85badEbd3ecF6eF79db44bB59519Cbd21d82',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x9ecf85badebd3ecf6ef79db44bb59519cbd21d82/logo.png',
            'coingeckoId': 'kong-defi',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'OPT2',
            'name': 'Optimus OPT2',
            'address': '0x9E25126EBCD57C8eb6eb6c2ffC67810d365CFC3E',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27187/thumb/optimus-logo-03.png?1662434333',
            'coingeckoId': 'optimus-opt2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLAG',
            'name': 'Flag Media',
            'address': '0x9DBAD35E5ff07bDC1Ed5cF37BC30F7439996DF63',
            'decimals': 10,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/28178/thumb/81220B16-8ED7-4AEB-8610-CEA74D1A4D36.jpeg?1668253199',
            'coingeckoId': 'flag-media',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AMG',
            'name': 'Amgen',
            'address': '0x9D934DE468689e07D3D6e1C6Ee2093d5acBfD90C',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25291/thumb/WorYFccvCfc0SQzHHCSJyVHyE7StfNOVU9NajqXcKv3G7hvinJGlISSFBgjuamai1N6aZTUCuDQD8Lm2Nod2loHKAedAtagu-DNv_r7PlxyiAr8pP6TqPHRwmLMkYeVIMAXjUIZtDwS8B1WKLdM1qYfbZb6_9aMD9WMEh2g58Oa2tac0Y1HPSy_Q1ZGIFXSMntGbeRs2-fqMPLjeFztRR0_%281%29.jpg?1651132162',
            'coingeckoId': 'amgen',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWT',
            'name': 'Super WinGoal Token',
            'address': '0x9D2Aa8B9Cd32fe46F4E177F49871518D942B1E47',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://i.imgur.com/GO11OUH.png',
            'coingeckoId': null,
            'listedIn': [
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'IDEX',
            'name': 'IDEX Token (PoS)',
            'address': '0x9Cb74C8032b007466865f060ad2c46145d45553D',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x9cb74c8032b007466865f060ad2c46145d45553d/aebb10de5cc5193e0c955db6a4175a93.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CVOL',
            'name': 'Crypto Volatility',
            'address': '0x9CD552551EC130b50c1421649C8d11E76aC821e1',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24008/small/govi-dao.03ef3083.png?1646029661',
            'coingeckoId': 'crypto-volatility-token',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SPORK',
            'name': 'SporkDAO',
            'address': '0x9CA6a77C8B38159fd2dA9Bd25bc3E259C33F5E39',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/23358/thumb/sporkdao.PNG?1643942687',
            'coingeckoId': 'sporkdao',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BUSD',
            'name': 'BUSD Token',
            'address': '0x9C9e5fD8bbc25984B178FdCE6117Defa39d2db39',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x9c9e5fd8bbc25984b178fdce6117defa39d2db39/588ad5043e23b6c46aeda945852c3273.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MUST',
            'name': 'Must',
            'address': '0x9C78EE466D6Cb57A4d01Fd887D2b5dFb2D46288f',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f.png',
            'coingeckoId': 'must',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'dfyn',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PHX',
            'name': 'Phoenix Finance',
            'address': '0x9C6BfEdc14b5C23E3900889436Edca7805170f01',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17675/thumb/phx_logo.png?1628832432',
            'coingeckoId': 'phoenix-token',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'BTCPX',
            'name': 'BTC Proxy',
            'address': '0x9C32185b81766a051E08dE671207b34466DD1021',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/22630/thumb/MB1aYO7T_400x400.jpg?1642342656',
            'coingeckoId': 'btc-proxy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RING',
            'name': 'Darwinia Native Token',
            'address': '0x9C1C23E60B72Bc88a043bf64aFdb16A02540Ae8f',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://get.celer.app/cbridge-icons/RING.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FRB',
            'name': 'Freebie Life Finance',
            'address': '0x9BedCE29F79076b21DD04958a9Fd4B22F63fD86D',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26262/thumb/Logo_%28200x200%29.png?1656942812',
            'coingeckoId': 'freebie-life-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CXDOGE',
            'name': 'CelsiusX Wrapped DOGE',
            'address': '0x9Bd9aD490dD3a52f096D229af4483b94D63BE618',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/23683/thumb/R2747rb.png?1644997607',
            'coingeckoId': 'celsiusx-wrapped-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CMT',
            'name': 'CommitDAO',
            'address': '0x9Bc0abf87F9350cDE55b2CDA219871726d620Bf9',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/CMT.svg',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'LPK',
            'name': 'Kripton',
            'address': '0x9B71b5511998e0798625b8Fa74e86D8192DE78C1',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x9b71b5511998e0798625b8fa74e86d8192de78c1/5c96d1ce2205d313171db49ddf422ba0.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FID',
            'name': 'Fidira',
            'address': '0x9A4Eb698e5DE3D3Df0a68F681789072DE1E50222',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/19446/thumb/nl0qESy3_400x400.jpg?1635236436',
            'coingeckoId': 'fidira',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZKP',
            'name': 'Panther Protocol',
            'address': '0x9A06Db14D639796B25A6ceC6A1bf614fd98815EC',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18611/thumb/1_FVXTRf8HogOiXOQDzonE5g.png?1653199708',
            'coingeckoId': 'panther',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QUICKCHART',
            'name': 'QuickChart',
            'address': '0x99dA82C5464C49962Cdda44fe30d352Bc5Da0580',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15387/thumb/3-E7934-AD-31-CC-4-A62-8526-82-B0-DE53-BBFB.png?1620702415',
            'coingeckoId': 'quickchart',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'KIND',
            'name': 'Kindly',
            'address': '0x999E2e604f48De45480F97B5037A70Aa2a78B488',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27434/thumb/200x200_Logo_Kindly_Symbol_Green_White-Inside_Green_Transparent.png?1663927057',
            'coingeckoId': 'kindly',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ITSB',
            'name': 'ITSBLOC',
            'address': '0x998013798440d44C1dD4c767bdD9580E16E46e28',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27268/thumb/ezgif-2-1826f94a8a.jpeg?1663135869',
            'coingeckoId': 'itsbloc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAIx',
            'name': 'Superfluid miMATIC',
            'address': '0x992446B88a7E62C7235Bd88108f44543C1887C1F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0xa3fa99a148fa48d14ed51d610c367c61876997f1/be77e8e47016b1e9d3f01b7edc9b8d4f.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CDOG',
            'name': 'Cypherdog Token',
            'address': '0x990a524EBc0A54fd2A7ebC02A1CE54D309fb5CD3',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27845/thumb/Cypherdog_Token_Coin.png?1666066592',
            'coingeckoId': 'cypherdog-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NEST',
            'name': 'Nest Protocol',
            'address': '0x98f8669F6481EbB341B522fCD3663f79A3d1A6A7',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/11284/thumb/52954052.png?1589868539',
            'coingeckoId': 'nest',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MALINK',
            'name': 'Matic Aave Interest Bearing LINK',
            'address': '0x98ea609569bD25119707451eF982b90E3eb719cD',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x98ea609569bD25119707451eF982b90E3eb719cD/logo.png',
            'coingeckoId': 'matic-aave-link',
            'listedIn': [
                'coingecko',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'OXT',
            'name': 'Orchid',
            'address': '0x9880e3dDA13c8e7D4804691A45160102d31F6060',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x4575f41308EC1483f3d399aa9a2826d74Da13Deb/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'uniswap'
            ]
        },
        {
            'symbol': 'SAVG',
            'name': 'SAVAGE',
            'address': '0x981AeCC6EB4d382b96A02B75E931900705e95A31',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/22750/thumb/savg_logo.jpg?1642562253',
            'coingeckoId': 'savage',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STACK',
            'name': 'StackOS',
            'address': '0x980111ae1B84E50222C8843e3A7a038F36Fecd2b',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x980111ae1b84e50222c8843e3a7a038f36fecd2b.png',
            'coingeckoId': 'stackos',
            'listedIn': [
                'coingecko',
                '1inch',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'UNCL',
            'name': 'UNCL (PoS)',
            'address': '0x97C415D4c8ea982aaabCBb65506D37FA6631C351',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'A4',
            'name': 'A4 Finance',
            'address': '0x9767203e89dcD34851240B3919d4900d3E5069f1',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/21992/thumb/ba384ad07217a4be75cb85314f5760f7.jpg?1640582786',
            'coingeckoId': 'a4-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PRPS',
            'name': 'Purpose',
            'address': '0x972999c58BbcE63a2e398d4ED3Bde414b8349eB3',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/2539/thumb/purpose.png?1547036654',
            'coingeckoId': 'purpose',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAUSDC',
            'name': 'Matic Aave Interest Bearing USDC',
            'address': '0x9719d867A500Ef117cC201206B8ab51e794d3F82',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x9719d867A500Ef117cC201206B8ab51e794d3F82/logo.png',
            'coingeckoId': 'matic-aave-usdc',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NWC',
            'name': 'Newscrypto',
            'address': '0x968F6f898a6Df937fC1859b323aC2F14643e3fED',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/9805/small/Tu1_NI3s_%281%29.png?1614745476',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'IMON',
            'name': 'Intelligent Monsters',
            'address': '0x9631be8566fC71d91970b10AcfdEe29F21Da6C27',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26077/thumb/imonToken.png?1655690407',
            'coingeckoId': 'intelligent-monsters',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALCX',
            'name': 'Alchemix (PoS)',
            'address': '0x95c300e7740D2A88a44124B424bFC1cB2F9c3b89',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x95c300e7740D2A88a44124B424bFC1cB2F9c3b89/logo.png',
            'coingeckoId': 'alchemix',
            'listedIn': [
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SEED',
            'name': 'Brokoli Seed',
            'address': '0x95F86c8eb9bDEA0eA52659E6801ad8E7cA2b9283',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CONV',
            'name': 'Convergence (PoS)',
            'address': '0x95Bbe8fbD32088bC13E67b05c7E783AD6CA854D8',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x95bbe8fbd32088bc13e67b05c7e783ad6ca854d8/logo.png',
            'coingeckoId': 'convergence',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'oUSDT',
            'name': 'Orbit Bridge Polygon Tether USD',
            'address': '0x957da9EbbCdC97DC4a8C274dD762EC2aB665E15F',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x957da9ebbcdc97dc4a8c274dd762ec2ab665e15f.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'NOIA',
            'name': 'Syntropy',
            'address': '0x957d1AD5214468332C5e6C00305a25116f9A46BB',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/3269/thumb/Component_1.png?1608275724',
            'coingeckoId': 'noia-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DMR',
            'name': 'Dreamr Platform',
            'address': '0x955CE23f20217a6Aa205620b40EdE4C9E83d325f',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18307/thumb/DMR_SYMBOL_OFFICIAL_200X200.png?1631508227',
            'coingeckoId': 'dreamr-platform-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DUBI',
            'name': 'Decentralized Universal Basic Income',
            'address': '0x950e1561B7A7dEB1A32A6419FD435410daf851B0',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/2606/thumb/decentralized-universal-basic-income.png?1547036752',
            'coingeckoId': 'decentralized-universal-basic-income',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'Guard',
            'name': 'Helmet.insure on Polygon',
            'address': '0x948d2a81086A075b3130BAc19e4c6DEe1D2E3fE8',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://i.imgur.com/VtDIzy7.png',
            'coingeckoId': null,
            'listedIn': [
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'NSHARE',
            'name': 'NSHARE',
            'address': '0x948D0a28b600BDBd77AF4ea30E6F338167034181',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/21692/small/NF_nshare_2_200x200.png?1639721239',
            'coingeckoId': 'nshare',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SROCKET',
            'name': 'Stable One Rocket',
            'address': '0x94788309D420ad9f9f16d79fC13Ab74de83f85F7',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api-live.stableonegroup.com/media-upload/mediaFiles/test/2bed54cfb8aa3c851e7ea43d1b900367.png',
            'coingeckoId': 'stable-one-rocket',
            'listedIn': [
                'coingecko',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'ETHICA',
            'name': 'Ethica',
            'address': '0x93dCfE01dD256B9C9593Ce2F5bf8488C4a78DdE6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26179/thumb/D5qFCZtn_400x400.png?1656382314',
            'coingeckoId': 'ethica',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNLOCK',
            'name': 'UNLOCK',
            'address': '0x930A7Dc10ae084FBbddC6537D7df7d4c65a40944',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/23646/thumb/unlock.png?1644910855',
            'coingeckoId': 'unlock',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'DBEAT',
            'name': 'Dbeats Token',
            'address': '0x92e918ea7aa872F91BF7EC9BcD248a5920C9f3CB',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BHC',
            'name': 'Billion Happiness',
            'address': '0x92DF60c51C710a1b1C20E42D85e221f3A1bFc7f2',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PHNX',
            'name': 'PhoenixDAO',
            'address': '0x92C59F1cC9A322670CCa29594e4D994d48BDFd36',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/11523/thumb/Token_Icon.png?1618447147',
            'coingeckoId': 'phoenixdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RAILPOLY',
            'name': 'Rail Polygon',
            'address': '0x92A9C92C215092720C731c96D4Ff508c831a714f',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25595/thumb/logo_512px.png?1652772731',
            'coingeckoId': 'rail-polygon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BRIDGE',
            'name': 'Cross Chain Bridge',
            'address': '0x92868A5255C628dA08F550a858A802f5351C5223',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/20223/thumb/0x92868A5255C628dA08F550a858A802f5351C5223.png?1636684446',
            'coingeckoId': 'cross-chain-bridge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KALLY',
            'name': 'Polkally',
            'address': '0x92804831aC036Ca77D57Db8BBA0E522Cd35a8049',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AUTOS',
            'name': 'AutoSingle',
            'address': '0x925FAdb35B73720238cc78777d02ED4dD3100816',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27083/thumb/autos.png?1661764923',
            'coingeckoId': 'autosingle',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BETS',
            'name': 'BetSwirl',
            'address': '0x9246a5F10A79a5a939b0C2a75A3AD196aAfDB43b',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/18453.png',
            'coingeckoId': 'betswirl',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'MFT',
            'name': 'Hifi Finance',
            'address': '0x91cA694d2B293f70Fe722fbA7d8A5259188959c3',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/3293/small/hifi_logo.png?1613102742',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BICO',
            'name': 'Biconomy Token',
            'address': '0x91c89A94567980f0e9723b487b0beD586eE96aa7',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/21061/small/biconomy_logo.jpg?1638269749',
            'coingeckoId': null,
            'listedIn': [
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'AUDT',
            'name': 'Auditchain',
            'address': '0x91c5A5488c0dEcde1Eacd8a4F10e0942fb925067',
            'decimals': 17,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/6398/thumb/auditchain.png?1547042519',
            'coingeckoId': 'auditchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FTT',
            'name': 'FTT (PoS)',
            'address': '0x918C749A09fA539E1Dc8019F875fab9EB1EBc6A3',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x918c749a09fa539e1dc8019f875fab9eb1ebc6a3/logo.png',
            'coingeckoId': 'ftx-token',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FLAG',
            'name': 'For Loot And Glory',
            'address': '0x9111D6446Ac5b88A84cf06425c6286658368542F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/20726/thumb/token_logo.ico?1637595445',
            'coingeckoId': 'for-loot-and-glory',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DDAO',
            'name': 'DeFi Hunters DAO',
            'address': '0x90F3edc7D5298918F7BB51694134b07356F7d0C7',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x90f3edc7d5298918f7bb51694134b07356f7d0c7.png',
            'coingeckoId': 'defi-hunters-dao',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'OWL',
            'name': 'OwlDAO',
            'address': '0x9085B4d52c3e0B8B6F9AF6213E85A433c7D76f19',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18717/thumb/owldao.PNG?1633089590',
            'coingeckoId': 'owldao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PUN',
            'name': 'CryptoPunt',
            'address': '0x908435624Fc23130fDA2d07b10C91cA2B6A57abA',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/19230/thumb/cryptopunt.PNG?1634767637',
            'coingeckoId': 'cryptopunt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RELAY',
            'name': 'Relay Chain',
            'address': '0x904371845Bc56dCbBcf0225ef84a669b2fD6bd0d',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17816/small/relay-logo-200.png?1629339288',
            'coingeckoId': 'relay-token',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'HYDRO',
            'name': 'HYDRO (PoS)',
            'address': '0x902742E3c5169657B1028977371CDF2c1608F67a',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x902742e3c5169657b1028977371cdf2c1608f67a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PEN',
            'name': 'Penrose Finance',
            'address': '0x9008D70A5282a936552593f410AbcBcE2F891A97',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26105/thumb/6iWxdkG.png?1655872325',
            'coingeckoId': 'penrose-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHAMP',
            'name': 'NFT Champions',
            'address': '0x8f9E8e833A69Aa467E42c46cCA640da84DD4585f',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x8f9e8e833a69aa467e42c46cca640da84dd4585f.png',
            'coingeckoId': 'nft-champions',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SALE',
            'name': 'DxSale Network',
            'address': '0x8f6196901a4a153d8eE8F3fa779A042F6092D908',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x8f6196901a4a153d8ee8f3fa779a042f6092d908.png',
            'coingeckoId': 'dxsale-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'DAI',
            'name': 'Dai',
            'address': '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x6b175474e89094c44da98b954eedeac495271d0f.png',
            'coingeckoId': 'dai',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'quickswap',
                'dfyn',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CHER',
            'name': 'Cherry Network',
            'address': '0x8f36Cc333F55B09Bb71091409A3d7ADE399e3b1C',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/21855/thumb/cherry.PNG?1640144127',
            'coingeckoId': 'cherry-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WOLF',
            'name': 'moonwolf io',
            'address': '0x8f18dC399594b451EdA8c5da02d0563c0b2d0f16',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x8f18dc399594b451eda8c5da02d0563c0b2d0f16.png',
            'coingeckoId': 'moonwolf-io',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NSFW',
            'name': 'Pleasure Coin',
            'address': '0x8f006D1e1D9dC6C98996F50a4c810F17a47fBF19',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15315/small/pleasurecoin-icon.png?1648724879',
            'coingeckoId': 'pleasure-coin',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'sdam3CRVx',
            'name': 'Superfluid sdam3CRV',
            'address': '0x8ef4F0C0753048a39B4Bc4eB3f545Fdae00618B7',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TXL',
            'name': 'Autobahn Network',
            'address': '0x8eEF5a82E6Aa222a60F009ac18c24EE12dBf4b41',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/12432/thumb/txl.png?1646463631',
            'coingeckoId': 'autobahn-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNI',
            'name': 'UNI',
            'address': '0x8e734e30270292993dBE71c421ad2b128B6dAE11',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SCOOBI',
            'name': 'Scoobi Doge',
            'address': '0x8dFDC61c7c7551D0DEec950A2822eB59cddb8f59',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/16101/thumb/scoobi_doge.jpg?1622963978',
            'coingeckoId': 'scoobi-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AMWMATIC',
            'name': 'Aave Polygon WMATIC',
            'address': '0x8dF3aad3a84da6b69A4DA8aeC3eA40d9091B2Ac4',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x8df3aad3a84da6b69a4da8aec3ea40d9091b2ac4.png',
            'coingeckoId': 'aave-polygon-wmatic',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'COT',
            'name': 'Cosplay Token',
            'address': '0x8d520c8E66091cfD6743fe37Fbe3A09505616C4b',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x8d520c8e66091cfd6743fe37fbe3a09505616c4b.png',
            'coingeckoId': 'cosplay-token-2',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'GAME',
            'name': 'GameCredits',
            'address': '0x8d1566569d5b695d44a9a234540f68D393cDC40D',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x8d1566569d5b695d44a9a234540f68D393cDC40D/logo.png',
            'coingeckoId': 'gamecredits',
            'listedIn': [
                'coingecko',
                'quickswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'jCAD',
            'name': 'Jarvis Synthetic Canadian Dollar',
            'address': '0x8ca194A3b22077359b5732DE53373D4afC11DeE3',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x8ca194a3b22077359b5732de53373d4afc11dee3/8a140e27c8634f10c616926cfe8d660d.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'NTIC',
            'name': 'Entice',
            'address': '0x8c9bAC920CD9CF8c61F7fA4f34f43C572d040F61',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/28257/thumb/ntic.png?1668688637',
            'coingeckoId': 'entice-v2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'maUNI',
            'name': 'Matic Aave interest bearing UNI',
            'address': '0x8c8bdBe9CeE455732525086264a4Bf9Cf821C498',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x8c8bdBe9CeE455732525086264a4Bf9Cf821C498/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SOS',
            'name': 'SOS (PoS)',
            'address': '0x8c059898ca6274750b6bF1cf38F2848347C490cc',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/list/master/logos/token-logos/network/polygon/0x8c059898ca6274750b6bF1cf38F2848347C490cc.jpg',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ZCN',
            'name': '0chain',
            'address': '0x8bB30E0e67b11b978a5040144c410e1ccDDcba30',
            'decimals': 10,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/4934/thumb/0_Black-svg.png?1600757954',
            'coingeckoId': '0chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FGP',
            'name': 'FingerPrint (PoS)',
            'address': '0x8b62011D9e73922Ac5eF6d30A82588cB33F98055',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x8b62011d9e73922ac5ef6d30a82588cb33f98055/logo.png',
            'coingeckoId': 'fingerprint',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'CBT',
            'name': 'CyBloc Battery Token',
            'address': '0x8a874960986f4ED95Cd8cF4B209311b5953791D5',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TAG',
            'name': 'Dog Tag',
            'address': '0x8a6F1738b2Ff63C7C29F7E2B0073D96C839b0B23',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26418/thumb/TAG_PNG.png?1657926565',
            'coingeckoId': 'dog-tag',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEGEN',
            'name': 'DEGEN Index',
            'address': '0x8a2870fb69A90000D6439b7aDfB01d4bA383A415',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x8a2870fb69A90000D6439b7aDfB01d4bA383A415/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'INSUR',
            'name': 'InsurAce',
            'address': '0x8a0e8b4b0903929f47C3ea30973940D4a9702067',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x8a0e8b4b0903929f47c3ea30973940d4a9702067.png',
            'coingeckoId': 'insurace',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'C4C',
            'name': 'Crypto 4 A Cause Fund',
            'address': '0x8Fd0195469b51a935dC3C48617ced6B400E38C9C',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'oDAI',
            'name': 'Orbit Bridge Polygon Dai',
            'address': '0x8Ece0a50A025A7E13398212a5BEd2ded11959949',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x8ece0a50a025a7e13398212a5bed2ded11959949.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ENCTR',
            'name': 'Encountr',
            'address': '0x8EA89Abb7236aE4880EA8FDb42B98768d8EeD505',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26428/thumb/CG_ENCTR_TokenIMG_200x.png?1657929778',
            'coingeckoId': 'encountr',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EGX',
            'name': 'Enegra',
            'address': '0x8Db0A6d1b06950b4e81c4F67D1289Fc7B9359c7f',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/23082/thumb/imgonline-com-ua-Resize-LHUSvWAko1XuD.png?1643179587',
            'coingeckoId': 'enegra',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KWIK',
            'name': 'Kwikswap (PoS)',
            'address': '0x8DF74088b3aeCfd0cB97BcFd053B173782f01e3A',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x8df74088b3aecfd0cb97bcfd053b173782f01e3a/9c928678a00199f4283f3278142252c0.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'COLLAR',
            'name': 'PolyPup Finance',
            'address': '0x8DF26a1BD9bD98e2eC506fc9d8009954716A05DC',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x8DF26a1BD9bD98e2eC506fc9d8009954716A05DC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'TRO',
            'name': 'Trodl',
            'address': '0x8D43b5c7E3d7577BbF75B70c3880b99164A23634',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14713/thumb/KU_Ul7P5_400x400.jpg?1617921826',
            'coingeckoId': 'trodl',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XFUN',
            'name': 'XFUN',
            'address': '0x8Cf745561791A43d70F75e85FbC6e3752395C5f0',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x8cf745561791a43d70f75e85fbc6e3752395c5f0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DHT',
            'name': 'dHEDGE DAO',
            'address': '0x8C92e38eCA8210f4fcBf17F0951b198Dd7668292',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x8c92e38eca8210f4fcbf17f0951b198dd7668292.png',
            'coingeckoId': 'dhedge-dao',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'UNIX',
            'name': 'UniX',
            'address': '0x8C4476DFEC8e7EEdf2DE3e9E9461B7c14C828d46',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x8c4476dfec8e7eedf2de3e9e9461b7c14c828d46.png',
            'coingeckoId': 'unix',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'NFTZ',
            'name': 'ZelaaNFT',
            'address': '0x8C42c4B5a49B33f895A41B45d17655b90c2C3945',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26454/thumb/gNBjBgOO_400x400.jpeg?1658114894',
            'coingeckoId': 'zelaanft-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INTD',
            'name': 'INDESTCOIN',
            'address': '0x8Bb93979901cd159bF6763B223FBb315C31CCF7b',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27543/thumb/logoend.png?1668586274',
            'coingeckoId': 'indestcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POLYDOGE',
            'name': 'PolyDoge',
            'address': '0x8A953CfE442c5E8855cc6c61b1293FA648BAE472',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x8a953cfe442c5e8855cc6c61b1293fa648bae472.png',
            'coingeckoId': 'polydoge',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'quickswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GREEN',
            'name': 'Green Token (PoS)',
            'address': '0x8A4001fD666BE272605c56BB956d11A46200Db81',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x8a4001fd666be272605c56bb956d11a46200db81/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'CRBN',
            'name': 'Carbon',
            'address': '0x89eF0900b0A6b5548ab2FF58EF588F9433b5fCf5',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x89ef0900b0a6b5548ab2ff58ef588f9433b5fcf5.png',
            'coingeckoId': 'carbon',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ACRV',
            'name': 'Aladdin cvxCRV',
            'address': '0x89C90E480A39fBE3886bB5bD53ba5B1Acc69D4Fb',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25395/thumb/Sv06cFHS_400x400.jpg?1651707422',
            'coingeckoId': 'aladdin-cvxcrv',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'RCN',
            'name': 'RipioCreditNetwork',
            'address': '0x89C296Be2F904F3e99a6125815b4B78F5388d2dd',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/rcn.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'MSHEESHA',
            'name': 'Sheesha Finance Polygon',
            'address': '0x88C949b4eB85a90071f2C0beF861BDDEe1a7479D',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x88c949b4eb85a90071f2c0bef861bddee1a7479d.png',
            'coingeckoId': 'sheesha-finance-polygon',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BALL',
            'name': 'Ball',
            'address': '0x883aBe4168705d2e5dA925d28538B7a6AA9d8419',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17557/thumb/2_%289%29.png?1628228675',
            'coingeckoId': 'ball-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DWEB',
            'name': 'DecentraWeb',
            'address': '0x8839e639F210B80ffea73AedF51baed8DAc04499',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x8839e639f210b80ffea73aedf51baed8dac04499.png',
            'coingeckoId': 'decentraweb',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'DEFI',
            'name': 'DEFICHAIN (PoS)',
            'address': '0x8836130081aa7A46726d8c27aA813DAC2fe198D2',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x8836130081aa7a46726d8c27aa813dac2fe198d2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'WBUSD',
            'name': 'Wrapped BUSD',
            'address': '0x87ff96aba480f1813aF5c780387d8De7cf7D8261',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/9576/small/BUSD.png?1568947766',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'YAMP',
            'name': 'Yamp Finance',
            'address': '0x87f654c4b347230C60CAD8d7ea9cF0D7238bcc79',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17301/thumb/0x87f654c4b347230C60CAD8d7ea9cF0D7238bcc79.png?1627267863',
            'coingeckoId': 'yamp-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'sd3Crv',
            'name': 'Stake DAO USD',
            'address': '0x87f0BFEE4435CE2B8643b221D0C1CAd21F5328B4',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x87f0BFEE4435CE2B8643b221D0C1CAd21F5328B4/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'QUA',
            'name': 'Quantum Tech',
            'address': '0x87e28BD5499e6846141a85659bc7f0CF815DB11D',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/22465/thumb/Pm9J4C3KD-5W41f2VH2IIWUY3gRCNJqTyu7X4SQt0ptuUYnhm68zPVBxTEgehAE_WSZBSz_TzxLfAWu8P7a8zOSZa8MpuqUmntsWVeK01s7IoEBERumgQq1mcTuxyOQXBH68OZBkC_hqVEiSf4vNw_EgbtCSATx6XTaLUmCO33kTaolgB5CU33MiINZkwvRDsexAQnxT7BWO6F.jpg?1641883901',
            'coingeckoId': 'quantum-tech',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HID',
            'name': 'Hypersign Identity',
            'address': '0x87847703D4bb4FCD42DB887FfdcB94496e77e3ab',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/16158/thumb/hypersign_ONLYlogo_Yellow.png?1623140987',
            'coingeckoId': 'hypersign-identity-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PLA',
            'name': 'PlayDapp',
            'address': '0x8765f05ADce126d70bcdF1b0a48Db573316662eB',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x8765f05adce126d70bcdf1b0a48db573316662eb.png',
            'coingeckoId': 'playdapp',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'GFI',
            'name': 'Gravity Finance',
            'address': '0x874e178A2f3f3F9d34db862453Cd756E7eAb0381',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15871/thumb/GFI-Icon.png?1622178588',
            'coingeckoId': 'gravity-finance',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'LMT',
            'name': 'LympoMarketToken',
            'address': '0x873801Ae2ff12d816Db9a7B082F5796BEC64C82C',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DELOT',
            'name': 'DELOT IO',
            'address': '0x86A298581388bc199e61BFeCDcA8eA22Cf6C0Da3',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25561/thumb/Logo_200.png?1654230685',
            'coingeckoId': 'delot-io',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'DUS',
            'name': 'Dallor',
            'address': '0x867D46fd484358A6f25655a705aa6AD804E6C6eB',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'XEND',
            'name': 'Xend Finance',
            'address': '0x86775d0B80B3df266AF5377dB34Ba8f318d715ec',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14496/thumb/WeChat_Image_20210325163206.png?1616661216',
            'coingeckoId': 'xend-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SKILL',
            'name': 'CryptoBlades',
            'address': '0x863D6074aFaF02D9D41A5f8Ea83278DF7089aA86',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15334/thumb/cryptoblade.PNG?1620596874',
            'coingeckoId': 'cryptoblades',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFTY',
            'name': 'NFTY Token',
            'address': '0x8623e66Bea0DCe41B6d47f9C44e806A115baBae0',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x8623e66bea0dce41b6d47f9c44e806a115babae0.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MMO',
            'name': 'Mad Meerkat Optimizer  Polygon ',
            'address': '0x859a50979fdB2A2fD8Ba1AdCC66977C6f6b1CD5B',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://storage.googleapis.com/xy-finance-images/token-list/MMO.png',
            'coingeckoId': 'mad-meerkat-optimizer-polygon',
            'listedIn': [
                'coingecko',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'DPI',
            'name': 'DeFi Pulse Index',
            'address': '0x85955046DF4668e1DD369D2DE9f3AEB98DD2A369',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/12465/small/defi_pulse_index_set.png?1600051053',
            'coingeckoId': 'defipulse-index',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'quickswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'WOW',
            'name': 'WOWswap',
            'address': '0x855D4248672a1fCE482165e8DBE1207b94b1968a',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14101/small/Group-423.png?1614317472',
            'coingeckoId': 'wowswap',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'COMP',
            'name': 'Compound',
            'address': '0x8505b9d2254A7Ae468c0E9dd10Ccea3A837aef5c',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xc00e94cb662c3520282e6f5717214004a7f26888.png',
            'coingeckoId': 'compound-governance-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'quickswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'LRC',
            'name': 'LoopringCoin V2',
            'address': '0x84e1670F61347CDaeD56dcc736FB990fBB47ddC1',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xBBbbCA6A901c926F240b89EacB641d8Aec7AEafD/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'uniswap'
            ]
        },
        {
            'symbol': 'sSDT',
            'name': 'Superfluid SDT',
            'address': '0x84B2e92E08008c0081C8c21a35FdA4DdC5d21aC6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x361a5a4993493ce00f61c32d4ecca5512b82ce90/4d8272a2602fefc2855afa132ef94115.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'VERSA',
            'name': 'VersaGames',
            'address': '0x8497842420cFdbc97896C2353D75d89Fc8D5Be5D',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25491/small/versa_token_96_96.png?1652074785',
            'coingeckoId': 'versagames',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'NHT',
            'name': 'Neighbourhoods',
            'address': '0x84342e932797FC62814189f01F0Fb05F52519708',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/22055/thumb/social_media_logo_black_bg.png?1640682530',
            'coingeckoId': 'neighbourhoods',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SX',
            'name': 'SX Network',
            'address': '0x840195888Db4D6A99ED9F73FcD3B225Bb3cB1A79',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x99fe3b1391503a1bc1788051347a1324bff41452.png',
            'coingeckoId': 'sx-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'dfyn',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FITFI',
            'name': 'STEP.APP',
            'address': '0x83c13eCAEa386b0D13ec1970C409c2a291617f31',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CMI',
            'name': 'Cryptocurrency Market Index',
            'address': '0x83C92e1a4a824CD42F94012B7b50AFf372E4d25f',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/23542/thumb/CMI_200x200.png?1644391107',
            'coingeckoId': 'cryptocurrency-market-index',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAS',
            'name': 'Basis Share',
            'address': '0x83A6DA342099835bCAA9C219DD76a5033c837de5',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/13251/thumb/BAS.png?1613231139',
            'coingeckoId': 'basis-share',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AXN',
            'name': 'Axion',
            'address': '0x839F1a22A59eAAf26c85958712aB32F80FEA23d9',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x839F1a22A59eAAf26c85958712aB32F80FEA23d9/logo.png',
            'coingeckoId': 'axion',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ROGUE',
            'name': 'Rogue Coin',
            'address': '0x835dF131C80cf4b27cEfdE256498E363cF8a4C27',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18569/thumb/rogue.png?1652268183',
            'coingeckoId': 'rogue-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOD',
            'name': 'Modefi',
            'address': '0x8346Ab8d5EA7A9Db0209aEd2d1806AFA0E2c4C21',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x8346ab8d5ea7a9db0209aed2d1806afa0e2c4c21.png',
            'coingeckoId': 'modefi',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'JJPY',
            'name': 'Jarvis Synthetic Japanese Yen',
            'address': '0x8343091F2499FD4b6174A46D067A920a3b851FF9',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24327/thumb/jJPY.png?1647357906',
            'coingeckoId': 'jarvis-synthetic-japanese-yen',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'QUICK',
            'name': 'Quickswap  OLD ',
            'address': '0x831753DD7087CaC61aB5644b308642cc1c33Dc13',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x6c28aef8977c9b773996d0e8376d2ee379446f2f.png',
            'coingeckoId': 'quick',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'quickswap',
                'dfyn',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CYT',
            'name': 'Coinary Token',
            'address': '0x83102Ec9B8A4DbF2E6aA41f6E7C8B5863B800681',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ALEPH',
            'name': 'aleph.im v2 (PoS)',
            'address': '0x82dCf1Df86AdA26b2dCd9ba6334CeDb8c2448e9e',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x82dcf1df86ada26b2dcd9ba6334cedb8c2448e9e/logo.png',
            'coingeckoId': 'aleph',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'WNT',
            'name': 'Wicrypt',
            'address': '0x82a0E6c02b91eC9f6ff943C0A933c03dBaa19689',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x82a0e6c02b91ec9f6ff943c0a933c03dbaa19689.png',
            'coingeckoId': 'wicrypt',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ADAI',
            'name': 'Aave DAI',
            'address': '0x82E64f49Ed5EC1bC6e43DAD4FC8Af9bb3A2312EE',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14242/thumb/aDAI.84b6c41f.png?1615528749',
            'coingeckoId': 'aave-dai',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'mDEF',
            'name': 'Matic Deflect Protocol',
            'address': '0x82B6205002ecd05e97642D38D61e2cFeaC0E18cE',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x82B6205002ecd05e97642D38D61e2cFeaC0E18cE/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MAAAVE',
            'name': 'Matic Aave Interest Bearing AAVE',
            'address': '0x823CD4264C1b951C9209aD0DeAea9988fE8429bF',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x823CD4264C1b951C9209aD0DeAea9988fE8429bF/logo.png',
            'coingeckoId': 'matic-aave-aave',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MESH',
            'name': 'Meshswap Protocol',
            'address': '0x82362Ec182Db3Cf7829014Bc61E9BE8a2E82868a',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x82362ec182db3cf7829014bc61e9be8a2e82868a.png',
            'coingeckoId': 'meshswap-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'BTZC',
            'name': '(PoS) BeatzCoin',
            'address': '0x81a9d2ecEd101479FaD8c9663f4A997fbE5c83cA',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x81a9d2eced101479fad8c9663f4a997fbe5c83ca/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'LOAD',
            'name': 'LOAD (PoS)',
            'address': '0x81C88f5CFF4fd506df906cb32F8b02218701b0C7',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x81c88f5cff4fd506df906cb32f8b02218701b0c7/logo.png',
            'coingeckoId': 'load-network',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BOOK',
            'name': 'Bookie',
            'address': '0x8192759Bf7f247cC92F74E39B3A4225516624fC1',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://bookie.farm/logoether/bookie200.png',
            'coingeckoId': null,
            'listedIn': [
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'DOSE',
            'name': 'DOSE',
            'address': '0x81382e9693dE2aFc33F69B70a6C12CA9B3a73F47',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WONE',
            'name': 'Wrapped ONE',
            'address': '0x80c0CBDB8d0B190238795d376f0bD57fd40525F2',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/4344/small/Y88JAze.png?1565065793',
            'coingeckoId': null,
            'listedIn': [
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'DEFLCT',
            'name': 'Deflect (PoS)',
            'address': '0x80E3E8f5D06CbeF73c12e6bdc2f63563fE57f866',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x80e3e8f5d06cbef73c12e6bdc2f63563fe57f866/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PAE',
            'name': 'Ripae',
            'address': '0x8063037ea50E4a066bF1430EA1E3e609CD5cEf6B',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/23420/small/logo192_%282%29.png?1644194954',
            'coingeckoId': null,
            'listedIn': [
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'CASH',
            'name': 'Stabl fi',
            'address': '0x80487b4f8f70e793A81a42367c225ee0B94315DF',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27558/thumb/qRnUZoW2_400x400.jpeg?1664517986',
            'coingeckoId': 'stabl-fi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BONE',
            'name': 'BoneSwap',
            'address': '0x80244C2441779361F35803b8C711C6c8fC6054a3',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17126/thumb/logo_-_2021-07-16T103422.157.png?1626402887',
            'coingeckoId': 'boneswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AGI',
            'name': 'SingularityNET Token (PoS)',
            'address': '0x800eB319e3F0E962d3ca8D625C871B8F1bdf2bC8',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x800eb319e3f0e962d3ca8d625c871b8f1bdf2bc8/logo.png',
            'coingeckoId': 'singularitynet',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'OPU',
            'name': 'Opu Coin',
            'address': '0x7ff2FC33E161E3b1C6511B934F0209D304267857',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x7ff2FC33E161E3b1C6511B934F0209D304267857/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'TETUBAL',
            'name': 'tetuBAL',
            'address': '0x7fC9E0Aa043787BFad28e29632AdA302C790Ce33',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27969/thumb/tetuBal.png?1667556746',
            'coingeckoId': 'tetubal',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LIME',
            'name': 'iMe Lab',
            'address': '0x7f67639Ffc8C93dD558d452b8920b28815638c44',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x7f67639ffc8c93dd558d452b8920b28815638c44.png',
            'coingeckoId': 'ime-lab',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'WSBT',
            'name': 'WSB sh',
            'address': '0x7f4e04aA61B9a46403c1634E91Bf31Df3Bc554CF',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/23920/thumb/_SjqfFvj_400x400.png?1645686742',
            'coingeckoId': 'wsb-sh',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AUTO',
            'name': 'Auto',
            'address': '0x7f426F6Dc648e50464a0392E60E1BB465a67E9cf',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x7f426F6Dc648e50464a0392E60E1BB465a67E9cf/logo.png',
            'coingeckoId': 'auto',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ENO',
            'name': 'ENO',
            'address': '0x7f36C54Da31b2Dd355CAfFEC0249F26Da41e3fcD',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26501/thumb/Eno_logo.png?1658364451',
            'coingeckoId': 'eno',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FNC',
            'name': 'Fancy Games',
            'address': '0x7f280daC515121DcdA3EaC69eB4C13a52392CACE',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x7f280dac515121dcda3eac69eb4c13a52392cace.png',
            'coingeckoId': 'fancy-games',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ENJ',
            'name': 'Enjin Coin (PoS)',
            'address': '0x7eC26842F195c852Fa843bB9f6D8B583a274a157',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x7ec26842f195c852fa843bb9f6d8b583a274a157/logo.png',
            'coingeckoId': 'enjincoin',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PBNB',
            'name': 'Orbit Bridge Polygon Binance Coin',
            'address': '0x7e9928aFe96FefB820b85B4CE6597B8F660Fe4F4',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/825/small/binance-coin-logo.png?1547034615',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'UNITY',
            'name': 'Unity',
            'address': '0x7e94De269df2eb9F4D0443d46500191F19C9A8dA',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x7e94de269df2eb9f4d0443d46500191f19c9a8da/d175c17f26941d810d6ba4b186fd2eb0.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CATHEON',
            'name': 'Catheon Gaming',
            'address': '0x7e7737C40878e720b32e7bC9CD096259f876d69f',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/28052/small/_removal.ai__tmp-635fe0271d6a3%281%29.png?1667286253',
            'coingeckoId': 'catheon-gaming',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'sdam3CRV',
            'name': 'stake dao Curve.fi amDAI/amUSDC/amUSDT',
            'address': '0x7d60F21072b585351dFd5E8b17109458D97ec120',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WETH',
            'name': 'WETH',
            'address': '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619.png',
            'coingeckoId': 'weth',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'quickswap',
                'dfyn',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SOFI',
            'name': 'Rai.Finance',
            'address': '0x7cb810ecBFd6125E65f451cFBd8Ae657dFFDd6b4',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14686/small/sofi.png?1640249595',
            'coingeckoId': null,
            'listedIn': [
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'POLYCUB',
            'name': 'PolyCub',
            'address': '0x7cC15fEf543f205Bf21018F038F591C6BaDa941c',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24124/thumb/polycub.png?1646462759',
            'coingeckoId': 'polycub',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'PAINT',
            'name': 'MurAll',
            'address': '0x7c28F627eA3aEc8B882b51eb1935f66e5b875714',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14103/thumb/paint_logo_200x200.png?1614325640',
            'coingeckoId': 'paint',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'PSC',
            'name': 'Promo Swipe Coin',
            'address': '0x7a372798F9baFEc9A4dCe817E9D86C73E8C7d1ca',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/21640/thumb/psc.png?1639631952',
            'coingeckoId': 'promo-swipe-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UBT',
            'name': 'Unibright',
            'address': '0x7FBc10850caE055B27039aF31bD258430e714c62',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x7FBc10850caE055B27039aF31bD258430e714c62/logo.png',
            'coingeckoId': 'unibright',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GMI',
            'name': 'Bankless DeFi Innovation Index',
            'address': '0x7FB27EE135dB455de5Ab1CcEC66a24cBC82E712D',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/22238/thumb/GMI_LOGO-07.png?1641266293',
            'coingeckoId': 'bankless-defi-innovation-index',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YF-DAI',
            'name': 'YfDAI finance',
            'address': '0x7E7fF932FAb08A0af569f93Ce65e7b8b23698Ad8',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/12385/thumb/1619048513068.png?1622193581',
            'coingeckoId': 'yfdai-finance',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'MOONED',
            'name': 'MoonEdge',
            'address': '0x7E4c577ca35913af564ee2a24d882a4946Ec492B',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x7e4c577ca35913af564ee2a24d882a4946ec492b.png',
            'coingeckoId': 'moonedge',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SOL',
            'name': 'SOL',
            'address': '0x7DfF46370e9eA5f0Bad3C4E29711aD50062EA7A4',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/4128/small/coinmarketcap-solana-200.png?1616489452',
            'coingeckoId': null,
            'listedIn': [
                'quickswap',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'BOLLY',
            'name': 'BollyCoin',
            'address': '0x7Dc47Cfb674bEb5827283F6140F635680A5cE992',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/21610/thumb/IMG-20211208-124337-701.jpg?1639579540',
            'coingeckoId': 'bollycoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FDZ',
            'name': 'Friendz Coin  (PoS)',
            'address': '0x7D836dA4Dd2aFda1d09992980569E22597F09981',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x7d836da4dd2afda1d09992980569e22597f09981/logo.png',
            'coingeckoId': 'friendz',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'AZUKI',
            'name': 'Azuki',
            'address': '0x7CdC0421469398e0F3aA8890693d86c840Ac8931',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x910524678c0b1b23ffb9285a81f99c29c11cbaed.png',
            'coingeckoId': 'azuki',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'EAT',
            'name': 'EDGE Activity',
            'address': '0x7C58D971A5dAbd46BC85e81fDAE87b511431452E',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26268/thumb/dp_YT.jpg?1658838042',
            'coingeckoId': 'edge-activity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FEED',
            'name': 'Feeder.finance',
            'address': '0x7C10B66c442fc403Ab2b8Bd73553D0dF262AD8a5',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'LEVR',
            'name': 'LEVR - levr.ly Logistics Token',
            'address': '0x7C083Ab0612cA7fBCd893E1B3ed01FC5401C1Df7',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PLX',
            'name': 'PolyDEX',
            'address': '0x7A5dc8A09c831251026302C93A778748dd48b4DF',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/28043/thumb/ZIPoL6gZ_400x400.jpeg?1667206092',
            'coingeckoId': 'polydex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OMP',
            'name': 'Omega Particle',
            'address': '0x79667854d97744D32b1Ba5c51c25fBE791b424c8',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27731/thumb/OMP_%28200x200%29.png?1665560550',
            'coingeckoId': 'omega-particle',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PLATO',
            'name': 'Plato Game',
            'address': '0x79637D860380bd28dF5a07329749654790FAc1Df',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24725/thumb/16085.png?1648700698',
            'coingeckoId': 'plato-game',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'TGR',
            'name': 'Tegro',
            'address': '0x7961Ade0a767c0E5B67Dd1a1F78ba44F727642Ed',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'YIN',
            'name': 'YIN Finance',
            'address': '0x794Baab6b878467F93EF17e2f2851ce04E3E34C8',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'jBGNx',
            'name': 'Superfluid jBGN',
            'address': '0x7893304db2C9e5dab18fd5B41855EA5A40FB070d',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WIVA',
            'name': 'WIVA',
            'address': '0x784641e51C300120A8D15bFDb3b45375D4352748',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18142/thumb/n8Vu43vs.png?1630656531',
            'coingeckoId': 'wiva',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'jCADx',
            'name': 'Superfluid jCAD',
            'address': '0x782B2725593B18C8453568f3fFb2645b6Cb5033e',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x8ca194a3b22077359b5732de53373d4afc11dee3/8a140e27c8634f10c616926cfe8d660d.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FOTO',
            'name': 'FOTO (PoS)',
            'address': '0x7827F070D3F0DCAE72aC6a9E1953459d50A426A7',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x7827f070d3f0dcae72ac6a9e1953459d50a426a7/logo.png',
            'coingeckoId': 'unique-photo',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'LFI',
            'name': 'Lunafi',
            'address': '0x77D97db5615dFE8a2D16b38EAa3f8f34524a0a74',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24594/small/lfi.png?1648310927',
            'coingeckoId': 'lunafi',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'GSG',
            'name': 'Gamesta',
            'address': '0x778949e707A0eb6c32ca20b1F9E0Db9685d18024',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/21410/thumb/logo_-_2021-12-10T070925.667.png?1639091381',
            'coingeckoId': 'gamesta',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MEMELON',
            'name': 'MEME ELON DOGE FLOKI',
            'address': '0x77609d4F3ae31382389374101Aca6108D95b093A',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'OMEN',
            'name': 'Augury Finance',
            'address': '0x76e63a3E7Ba1e2E61D3DA86a87479f983dE89a7E',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x76e63a3e7ba1e2e61d3da86a87479f983de89a7e.png',
            'coingeckoId': 'augury-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'CRYSTL',
            'name': 'Crystl Finance',
            'address': '0x76bF0C28e604CC3fE9967c83b3C3F31c213cfE64',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17023/thumb/CRYSTL_Rebrand_Logo.png?1651745246',
            'coingeckoId': 'crystl-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RMTX',
            'name': 'RematicEGC',
            'address': '0x76a15a8628CE4C66629eA964f8DC796A8159170B',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/21240/thumb/Rematic_Circle_Logo_200x200.png?1647851612',
            'coingeckoId': 'rematicegc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XSGD',
            'name': 'XSGD (PoS)',
            'address': '0x769434dcA303597C8fc4997Bf3DAB233e961Eda2',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x769434dca303597c8fc4997bf3dab233e961eda2.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'JGBP',
            'name': 'Jarvis Synthetic British Pound',
            'address': '0x767058F11800FBA6A682E73A6e79ec5eB74Fac8c',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15726/thumb/jGBP.png?1634046064',
            'coingeckoId': 'jarvis-synthetic-british-pound',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'AGVE',
            'name': 'Agave',
            'address': '0x75EB9Fd8608E2ff9418821062398E4F4B8d53644',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/1Hive/default-token-list/master/src/assets/xdai/0x3a97704a1b25f08aa230ae53b352e2e72ef52843/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'vDFYN',
            'name': 'vDFYN',
            'address': '0x75455c3DE45dD32CBE9a5aD5E518D3D50823c976',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/dfyn/assets/main/vDFYN.png',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'WAVN',
            'name': 'Wrapped Avian',
            'address': '0x752DC265EAf6Da2Db0F8e4a32D5596D3f18e8701',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26238/thumb/raven.png?1656904300',
            'coingeckoId': 'wrapped-avian',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AXLUSDC',
            'name': 'axlUSDC',
            'address': '0x750e4C4984a9e0f12978eA6742Bc1c5D248f40ed',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26476/thumb/axlUSDC.png?1658207579',
            'coingeckoId': 'axlusdc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DCASH',
            'name': 'Diabolo',
            'address': '0x74ba6A10978F643A84C0b37fCB599081079811cB',
            'decimals': 10,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25926/thumb/diabolo-logo.png?1654673914',
            'coingeckoId': 'diabolo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WRX',
            'name': 'WazirX',
            'address': '0x72d6066F486bd0052eefB9114B66ae40e0A6031a',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x72d6066F486bd0052eefB9114B66ae40e0A6031a/logo.png',
            'coingeckoId': 'wazirx',
            'listedIn': [
                'coingecko',
                'quickswap',
                'dfyn',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NEAR',
            'name': 'NEAR',
            'address': '0x72bd80445b0db58ebe3E8dB056529D4C5FAF6F2f',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/10365/small/near_icon.png?1601359077',
            'coingeckoId': null,
            'listedIn': [
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'DBD',
            'name': 'Day By Day',
            'address': '0x72b9f88e822cf08B031c2206612B025a82fb303C',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/21691/thumb/DBD-icon_200x200_%281%29.png?1639721079',
            'coingeckoId': 'day-by-day',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FVT',
            'name': 'Finance Vote',
            'address': '0x72a5a58f79FFc2102227B92fAeBA93B169a3A3F1',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/13181/thumb/finance.png?1606015010',
            'coingeckoId': 'finance-vote',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WT',
            'name': 'WinGoal Token',
            'address': '0x72B6A3155EF6C5a6222f2f946A03E3f3Fd991E3c',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://i.imgur.com/yrxXY9u.png',
            'coingeckoId': null,
            'listedIn': [
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'EURex',
            'name': 'Superfluid EURe',
            'address': '0x72A9bae5ce6de9816AADcbc24dAa09f5D169a980',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x18ec0a6e18e5bc3784fdd3a3634b31245ab704f6/2b7c77728757af62032284b30b1ffd9d.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'HDAO',
            'name': 'humanDAO',
            'address': '0x72928d5436Ff65e57F72D5566dCd3BaEDC649A88',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/21138/thumb/Untitled-2.jpg?1645163749',
            'coingeckoId': 'humandao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CYBR',
            'name': 'Cyber City',
            'address': '0x728D06c26bE85eBc8efA334453863B1Df00Ec493',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27985/thumb/Logo_Black.png?1666863227',
            'coingeckoId': 'cyber-city',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GAIA',
            'name': 'Gaia Everworld',
            'address': '0x723B17718289A91AF252D616DE2C77944962d122',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x723b17718289a91af252d616de2c77944962d122.png',
            'coingeckoId': 'gaia-everworld',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'XDAO',
            'name': 'XDAO',
            'address': '0x71eebA415A523F5C952Cc2f06361D5443545Ad28',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27363/thumb/XDAO_Token_img.png?1665738325',
            'coingeckoId': 'xdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '0XBTC',
            'name': '0xBitcoin',
            'address': '0x71B821aa52a49F32EEd535fCA6Eb5aa130085978',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x71B821aa52a49F32EEd535fCA6Eb5aa130085978/logo.png',
            'coingeckoId': 'oxbitcoin',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REVV',
            'name': 'REVV',
            'address': '0x70c006878a5A50Ed185ac4C87d837633923De296',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x70c006878a5a50ed185ac4c87d837633923de296.png',
            'coingeckoId': 'revv',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'CGU',
            'name': 'Crypto Global United',
            'address': '0x709D140925272ee606825781b1bEF7bE6b1412CD',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/20751/thumb/2022_CGU-MG_RGB.png?1666606977',
            'coingeckoId': 'crypto-global-united',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HANU',
            'name': 'Hanu Yokia',
            'address': '0x709A4B6217584188DDb93c82f5d716D969accE1C',
            'decimals': 12,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17161/thumb/Goji_Hanu_Logo_200x200.png?1627127472',
            'coingeckoId': 'hanu-yokia',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SIM',
            'name': 'Simba Empire',
            'address': '0x70784d8A360491562342B4F3d3D039AaAcAf8F5D',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17712/thumb/Logo_Simba_200x200.png?1629083120',
            'coingeckoId': 'simba-empire',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GFARM2',
            'name': 'Gains Farm',
            'address': '0x7075cAB6bCCA06613e2d071bd918D1a0241379E2',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x7075cab6bcca06613e2d071bd918d1a0241379e2.png',
            'coingeckoId': 'gains-farm',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'STRIP',
            'name': 'StripCoin',
            'address': '0x702D161Ed480e4a367a5810a6086074B791cf6A0',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'POLI',
            'name': 'Polinate',
            'address': '0x6fb54Ffe60386aC33b722be13d2549dd87BF63AF',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18096/thumb/Polinate_Games___Guilds_Elements-05.png?1648806645',
            'coingeckoId': 'polinate',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FCL',
            'name': 'Fractal Protocol Token (PoS)',
            'address': '0x6f934b45fc6043527d73C7c99313dd838E723DB7',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x6f934b45fc6043527d73c7c99313dd838e723db7/logo.png',
            'coingeckoId': 'fractal',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SHIB',
            'name': 'SHIBA INU',
            'address': '0x6f8a06447Ff6FcF75d803135a7de15CE88C1d4ec',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/11939/small/shiba.png?1622619446',
            'coingeckoId': null,
            'listedIn': [
                'quickswap',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'MKR',
            'name': 'Maker',
            'address': '0x6f7C932e7684666C9fd1d44527765433e01fF61d',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/mkr.jpg',
            'coingeckoId': null,
            'listedIn': [
                'uniswap',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'AXL',
            'name': 'Axelar',
            'address': '0x6e4E624106Cb12E168E6533F8ec7c82263358940',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27277/thumb/V-65_xQ1_400x400.jpeg?1663121730',
            'coingeckoId': 'axelar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ABI',
            'name': 'Abachi',
            'address': '0x6d5f5317308C6fE7D6CE16930353a8Dfd92Ba4D7',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x6d5f5317308c6fe7d6ce16930353a8dfd92ba4d7.png',
            'coingeckoId': 'abachi',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'HOPR',
            'name': 'Hopr (PoS)',
            'address': '0x6cCBF3627b2C83AFEF05bf2F035E7f7B210Fe30D',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x6ccbf3627b2c83afef05bf2f035e7f7b210fe30d/logo.png',
            'coingeckoId': 'hopr',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'GENI',
            'name': 'GemUni',
            'address': '0x6c8c448f060904E3352B0cBeEeF1052b08Cb0A40',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'KRW',
            'name': 'KROWN',
            'address': '0x6c3B2f402CD7d22AE2C319B9d2f16f57927a4A17',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/16530/thumb/KRW_token_logo_200x200.png?1624343058',
            'coingeckoId': 'krown',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BONE',
            'name': 'PolyPup Bone',
            'address': '0x6bb45cEAC714c52342Ef73ec663479da35934bf7',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x6bb45cEAC714c52342Ef73ec663479da35934bf7/logo.png',
            'coingeckoId': 'bone-token',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ONE',
            'name': 'ONE',
            'address': '0x6b7a87899490EcE95443e979cA9485CBE7E71522',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x6b7a87899490ece95443e979ca9485cbe7e71522/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MEED',
            'name': 'Meeds DAO',
            'address': '0x6acA77CF3BaB0C4E8210A09B57B07854a995289a',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24281/thumb/s-YfFWYu_400x400.png?1647218173',
            'coingeckoId': 'meeds-dao',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'AUSDT',
            'name': 'Aave USDT',
            'address': '0x6ab707Aca953eDAeFBc4fD23bA73294241490620',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14243/thumb/aUSDT.78f5faae.png?1615528400',
            'coingeckoId': 'aave-usdt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANY',
            'name': 'Anyswap',
            'address': '0x6aB6d61428fde76768D7b45D8BFeec19c6eF91A8',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xf99d58e463a2e07e5692127302c20a191861b4d6.png',
            'coingeckoId': 'anyswap',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'dfyn',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ALPHA',
            'name': 'Aavegotchi ALPHA',
            'address': '0x6a3E7C3c6EF65Ee26975b12293cA1AAD7e1dAeD2',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24738/small/alpha.png?1648769768',
            'coingeckoId': 'aavegotchi-alpha',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'GM',
            'name': 'GhostMarket',
            'address': '0x6a335AC6A3cdf444967Fe03E7b6B273c86043990',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/22765/thumb/gm.png?1642574907',
            'coingeckoId': 'ghostmarket',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOO',
            'name': 'Gooeys',
            'address': '0x6F3Cc27E17a0f2e52D8e7693FF0d892Cea1854bF',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://playgooeys.com/GOO-200x200.png',
            'coingeckoId': 'gooeys',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'EXZO',
            'name': 'ExzoCoin 2.0',
            'address': '0x6F1Bc0967945465539877b39ba48373b0219248f',
            'decimals': 9,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FOOD',
            'name': 'FoodChain Global',
            'address': '0x6F06e6beD64cF4c4187c06Ee2a4732f6a171BC4e',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/23755/small/food.png?1645347956',
            'coingeckoId': 'foodchain-global',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'GNOME',
            'name': 'GNOME',
            'address': '0x6E8a8726639d12935b3219892155520bdC57366B',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/20885/thumb/gnome.png?1637829973',
            'coingeckoId': 'gnome',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHACK',
            'name': 'Shack',
            'address': '0x6E65Ae5572DF196FAe40Be2545Ebc2A9A24eAcE9',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x6E65Ae5572DF196FAe40Be2545Ebc2A9A24eAcE9/logo.png',
            'coingeckoId': 'shack',
            'listedIn': [
                'coingecko',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MTT',
            'name': 'Hakuna Share',
            'address': '0x6E03c512CBA7023Fc4812C71d030092E6018F421',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'COMBO',
            'name': 'Furucombo',
            'address': '0x6DdB31002abC64e1479Fc439692F7eA061e78165',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/13629/small/COMBO_token_ol.png?1610701537',
            'coingeckoId': 'furucombo',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'PHBD',
            'name': 'Polygon HBD',
            'address': '0x6D969cEA201e427d2875724Fd4E8044833fBc7f4',
            'decimals': 3,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25142/thumb/image.png?1650434212',
            'coingeckoId': 'polygon-hbd',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MOV',
            'name': 'MOVN',
            'address': '0x6D802Ac662b898622F7A13e1F4cB1A50C7085b6E',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26445/thumb/MOVN_Logo.png?1658110694',
            'coingeckoId': 'movn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TAKO',
            'name': 'Tako',
            'address': '0x6D2a71f4edF10ab1E821B9B373363e1E24E5DF6b',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/16522/thumb/logo192.png?1624330730',
            'coingeckoId': 'tako-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'START',
            'name': 'Starter xyz',
            'address': '0x6Ccf12B480A99C54b23647c995f4525D544A7E72',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14301/thumb/logo_poly_sym.png?1624893386',
            'coingeckoId': 'bscstarter',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'XWIN',
            'name': 'xWIN Finance',
            'address': '0x6CD6CB131764c704bA9167C29930Fbdc38901aB7',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15400/thumb/200x200_%28transparent_background%29.png?1622515538',
            'coingeckoId': 'xwin-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PHNX',
            'name': 'Phenix Finance',
            'address': '0x6C997a37f5a1Dca62b58EEB01041f056942966B3',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27724/thumb/phenix.png?1666262269',
            'coingeckoId': 'phenix-finance-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SBAR',
            'name': 'Selfbar',
            'address': '0x6C526368e1185e4Cd8A50468Eb3429c5AeB674f3',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/20599/thumb/sbar-icon.png?1637286886',
            'coingeckoId': 'selfbar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CORDBRIDGE',
            'name': 'CORD.Finance Bridge (PoS)',
            'address': '0x6C222361B1407c196F740c1BfFC17d1770BFD55c',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x6c222361b1407c196f740c1bffc17d1770bfd55c/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'APW',
            'name': 'APWine',
            'address': '0x6C0AB120dBd11BA701AFF6748568311668F63FE0',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x4104b135dbc9609fc1a9490e61369036497660c8.png',
            'coingeckoId': 'apwine',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NBO',
            'name': 'Nature Based Offset',
            'address': '0x6BCa3B77C1909Ce1a4Ba1A20d1103bDe8d222E48',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/28076/thumb/C3_NBO_Gradient-01.png?1667393634',
            'coingeckoId': 'nature-based-offset',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'EPAN',
            'name': 'Paypolitan Token (PoS)',
            'address': '0x6B4E056B0A2c7d967728B0d0329A61b746B04cd8',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x6b4e056b0a2c7d967728b0d0329a61b746b04cd8/logo.png',
            'coingeckoId': 'paypolitan-token',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SYC',
            'name': 'SynchroCoin',
            'address': '0x6B24e2771d6a18ba38c32F9b616eB1cd7948745f',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/1469/thumb/ud643DVg.png?1621828021',
            'coingeckoId': 'synchrocoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZRO',
            'name': 'Carb0n fi',
            'address': '0x6AcdA5E7EB1117733DC7Cb6158fc67f226b32022',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/23378/thumb/Signet_Zoom_200px_Transparent.png?1643961561',
            'coingeckoId': 'zro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JPYC',
            'name': 'JPY Coin v1',
            'address': '0x6AE7Dfc73E0dDE2aa99ac063DcF7e8A63265108c',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17277/small/WoZ8rruL_400x400.png?1627016492',
            'coingeckoId': 'jpyc',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'quickswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'MSQ',
            'name': 'MSquare Global',
            'address': '0x6A8Ec2d9BfBDD20A7F5A4E89D640F7E7cebA4499',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25305/thumb/19743.png?1651204766',
            'coingeckoId': 'msquare-global',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'gSAT',
            'name': 'GrumpySat',
            'address': '0x6A174B728D8b7C0C1F239910BB90B3540e1Cc0Dd',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x6A174B728D8b7C0C1F239910BB90B3540e1Cc0Dd/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HAWK',
            'name': 'HawkDex',
            'address': '0x69CBC7449ee102eB792f1656744bF1A7c1bACB7e',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15149/thumb/hawk.jpg?1619924676',
            'coingeckoId': 'hawkdex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MATE',
            'name': 'Vmates',
            'address': '0x696c2D3c711d5727c3686672F411583faeDAA29F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/21931/thumb/vates.PNG?1640300141',
            'coingeckoId': 'vmates',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MONA',
            'name': 'Monavale',
            'address': '0x6968105460f67c3BF751bE7C15f92F5286Fd0CE5',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x275f5ad03be0fa221b4c6649b8aee09a42d9412a.png',
            'coingeckoId': 'monavale',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NITRO',
            'name': 'Nitro League',
            'address': '0x695FC8B80F344411F34bDbCb4E621aA69AdA384b',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x695fc8b80f344411f34bdbcb4e621aa69ada384b.png',
            'coingeckoId': 'nitro-league',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'THALES',
            'name': 'Thales',
            'address': '0x692C44990E4F408ba0917f5c78A83160c1557237',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18388/thumb/CLVZJN_C_400x400.png?1631758808',
            'coingeckoId': 'thales',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'TRADE',
            'name': 'Polytrade',
            'address': '0x692AC1e363ae34b6B489148152b12e2785a3d8d6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/10465.png',
            'coingeckoId': 'polytrade',
            'listedIn': [
                'coingecko',
                'quickswap',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'USTC',
            'name': 'Wrapped USTC',
            'address': '0x692597b009d13C4049a947CAB2239b7d6517875F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x692597b009d13c4049a947cab2239b7d6517875f.png',
            'coingeckoId': 'wrapped-ust',
            'listedIn': [
                'coingecko',
                '1inch',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'SNP',
            'name': 'Synapse Network',
            'address': '0x6911F552842236bd9E8ea8DDBB3fb414e2C5FA9d',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x6911f552842236bd9e8ea8ddbb3fb414e2c5fa9d.png',
            'coingeckoId': 'synapse-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ROY',
            'name': 'Crypto Royale',
            'address': '0x68EE0D0aad9e1984aF85CA224117E4D20eaF68BE',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/20668/thumb/ROY_logo_new_design_small.png?1637547627',
            'coingeckoId': 'crypto-royale',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'SNK',
            'name': 'Snook',
            'address': '0x689f8e5913C158fFB5Ac5aeb83b3C875F5d20309',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x689f8e5913c158ffb5ac5aeb83b3c875f5d20309.png',
            'coingeckoId': 'snook',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BPT',
            'name': 'BlackPool',
            'address': '0x6863BD30C9e313B264657B107352bA246F8Af8e0',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x6863BD30C9e313B264657B107352bA246F8Af8e0/logo.png',
            'coingeckoId': 'blackpool-token',
            'listedIn': [
                'coingecko',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'xSUSHI',
            'name': 'SushiBar',
            'address': '0x6811079E3c63ED96Eb005384d7E7ec8810E3D521',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/xsushi.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'GDDY',
            'name': 'Giddy',
            'address': '0x67eB41A14C0fe5CD701FC9d5A3D6597A72F641a6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24960/thumb/gddy.png?1649573862',
            'coingeckoId': 'giddy',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'CHSB',
            'name': 'SwissBorg (PoS)',
            'address': '0x67Ce67ec4fCd4aCa0Fcb738dD080b2a21ff69D75',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x67ce67ec4fcd4aca0fcb738dd080b2a21ff69d75/logo.png',
            'coingeckoId': 'swissborg',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MSHARE',
            'name': 'Savanna Share',
            'address': '0x67927780E399d839cbA088F75700b49A8896584C',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'LUCHA',
            'name': 'Lucha',
            'address': '0x6749441Fdc8650b5b5a854ed255C82EF361f1596',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/22794/thumb/lucha.png?1642581523',
            'coingeckoId': 'lucha',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'UNN',
            'name': 'Union',
            'address': '0x67480287CB3715D1d9429B38772c71d6E94C16da',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://etherscan.io/token/images/unionprotocol_32.png',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'SPICE',
            'name': 'Spice Trade',
            'address': '0x66e8617d1Df7ab523a316a6c01D16Aa5beD93681',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25770/thumb/SPICE.png?1653607602',
            'coingeckoId': 'spice-trade',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ETH2x-FLI',
            'name': 'ETH 2x Flexible Leverage Index PoS',
            'address': '0x66d7FDCc7403f18cAE9b0e2e8385649D2AcBC12A',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x66d7FDCc7403f18cAE9b0e2e8385649D2AcBC12A/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'IUSDS',
            'name': 'Inflation Adjusted USDS',
            'address': '0x66F31345Cb9477B427A1036D43f923a557C432A4',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25698/thumb/iUSDS2.png?1653441251',
            'coingeckoId': 'inflation-adjusted-usds',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'LOOM',
            'name': 'Loom Network',
            'address': '0x66EfB7cC647e0efab02eBA4316a2d2941193F6b3',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA4e8C3Ec456107eA67d3075bF9e3DF3A75823DB0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'uniswap'
            ]
        },
        {
            'symbol': 'CVC',
            'name': 'Civic',
            'address': '0x66Dc5A08091d1968e08C16aA5b27BAC8398b02Be',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/788/thumb/civic.png?1547034556',
            'coingeckoId': null,
            'listedIn': [
                'uniswap'
            ]
        },
        {
            'symbol': 'SDO',
            'name': 'SafeDollar',
            'address': '0x66C59Dded4EF01a3412a8B019B6e41D4a8C49A35',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/16887/thumb/1_551bIKFiNCvKXdUloML0wA_%281%29.png?1625549180',
            'coingeckoId': 'safedollar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AGAC',
            'name': 'AGA Carbon Credit',
            'address': '0x669ddc70273084Ea30e6cd4f28CA6e2C70735065',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x669ddc70273084Ea30e6cd4f28CA6e2C70735065/logo.png',
            'coingeckoId': 'aga-carbon-credit',
            'listedIn': [
                'coingecko',
                'dfyn',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'mRBAL',
            'name': 'Matic Rebalance Token',
            'address': '0x66768ad00746aC4d68ded9f64886d55d5243f5Ec',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x66768ad00746aC4d68ded9f64886d55d5243f5Ec/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'dSNX',
            'name': 'SNX Debt Mirror',
            'address': '0x65bb99e80a863e0e27Ee6D09c794ed8C0bE47186',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DEXI',
            'name': 'Dexioprotocol',
            'address': '0x65ba64899c2c7DbFDb5130e42E2CC56de281c78b',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x65ba64899c2c7DbFDb5130e42E2CC56de281c78b/logo.png',
            'coingeckoId': 'dexioprotocol-v2',
            'listedIn': [
                'coingecko',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GFX',
            'name': 'GamyFi',
            'address': '0x65ad6A2288b2Dd23E466226397c8F5D1794e58fC',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14559/thumb/circle-cropped_%281%29.png?1617008124',
            'coingeckoId': 'gamyfi-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FOX',
            'name': 'ShapeShift FOX',
            'address': '0x65A05DB8322701724c197AF82C9CaE41195B0aA8',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x65a05db8322701724c197af82c9cae41195b0aa8.png',
            'coingeckoId': 'shapeshift-fox-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'FITT',
            'name': 'Fitmint',
            'address': '0x656Bf6767Fa8863aC0dD0b7d2a26602B838A2E70',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27021/thumb/Fitmint_logo.png?1661483846',
            'coingeckoId': 'fitmint',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REPv2',
            'name': 'Reputation Augur v2',
            'address': '0x6563c1244820CfBd6Ca8820FBdf0f2847363F733',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x221657776846890989a759BA2973e427DfF5C9bB/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'uniswap'
            ]
        },
        {
            'symbol': 'mWBTC',
            'name': 'Mushrooming WBTC',
            'address': '0x652E36ecFA0E4F7e435F6D38688D68a3bE65639c',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x652E36ecFA0E4F7e435F6D38688D68a3bE65639c/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BONDLY',
            'name': 'Forj',
            'address': '0x64ca1571d1476b7a21C5aaf9f1a750A193A103C0',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x64ca1571d1476b7a21c5aaf9f1a750a193a103c0.png',
            'coingeckoId': 'bondly',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MEEB',
            'name': 'Meeb Master',
            'address': '0x64aFDF9e28946419E325d801Fb3053d8B8FFdC23',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17312/thumb/logo_-_2021-07-26T144950.172.png?1627282207',
            'coingeckoId': 'meeb-master',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNV',
            'name': 'Unvest',
            'address': '0x64Ee4f41A15D6c431AB6607d4e95462169d50f6C',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18119/thumb/unvest.PNG?1630554868',
            'coingeckoId': 'unvest',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CXADA',
            'name': 'CelsiusX Wrapped ADA',
            'address': '0x64875Aaa68d1d5521666C67d692Ee0B926b08b2F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/23536/thumb/cxADA_128x128.png?1644388456',
            'coingeckoId': 'celsiusx-wrapped-ada',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNIM',
            'name': 'Unicorn Milk',
            'address': '0x64060aB139Feaae7f06Ca4E63189D86aDEb51691',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x64060ab139feaae7f06ca4e63189d86adeb51691.png',
            'coingeckoId': 'unicorn-milk',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'wsSQUID',
            'name': 'Wrapped sSQUID',
            'address': '0x6390cB919Eeda9174146C32E1A974119aC786F98',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DAFI',
            'name': 'Dafi Protocol',
            'address': '0x638Df98Ad8069a15569dA5a6b01181804c47e34c',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14428/thumb/Dafi_Black_Icon.png?1616040406',
            'coingeckoId': 'dafi-protocol',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'MNS',
            'name': 'Monnos Token',
            'address': '0x636Db84815d6A5af9202CBb44DAd7fd672F178e1',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'vUNIT',
            'name': 'Virtual Unit',
            'address': '0x632fbF85F77978437073a8CE5CEEC29e3209514c',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WET',
            'name': 'Weble Ecosystem',
            'address': '0x632B8c4e95B2F8A9309417f8D990ab9C04c77369',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17353/thumb/cropped-logo-wombat.png?1627368904',
            'coingeckoId': 'weble-ecosystem-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'jGBPx',
            'name': 'Superfluid jGBP',
            'address': '0x6328C1C2E258a314BDac5227E9C7d212312297aD',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x767058f11800fba6a682e73a6e79ec5eb74fac8c/65a8ec523d827d3aec41d332b0b24f7d.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'NEUY',
            'name': 'NEUY',
            'address': '0x62a872d9977Db171d9e213A5dc2b782e72ca0033',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25870/thumb/logo200x200.png?1654240813',
            'coingeckoId': 'neuy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPHERE',
            'name': 'Sphere Finance',
            'address': '0x62F594339830b90AE4C084aE7D223fFAFd9658A7',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x62f594339830b90ae4c084ae7d223ffafd9658a7.png',
            'coingeckoId': 'sphere-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'TECH',
            'name': 'Cryptomeda',
            'address': '0x6286A9e6f7e745A6D884561D88F94542d6715698',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17983/small/tech.png?1654330771',
            'coingeckoId': 'cryptomeda',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'AUSDC',
            'name': 'Aave USDC',
            'address': '0x625E7708f30cA75bfd92586e17077590C60eb4cD',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14318/thumb/aUSDC.e260d492.png?1615527797',
            'coingeckoId': 'aave-usdc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SERUM',
            'name': 'Karmaverse Zombie Serum',
            'address': '0x623a1350f6B749575F92Ea54D0F745f9F07D3665',
            'decimals': 0,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26002/thumb/serum.png?1660904985',
            'coingeckoId': 'karmaverse-zombie-serum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MHUNT',
            'name': 'MetaShooter',
            'address': '0x61f95bd637e3034133335C1baA0148E518D438ad',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24985/small/200x200.png?1649681575',
            'coingeckoId': 'metashooter',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'DMAGIC',
            'name': 'Dark Magic',
            'address': '0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x61dAECaB65EE2A1D5b6032df030f3fAA3d116Aa7/logo.png',
            'coingeckoId': 'dark-magic',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'HYVE',
            'name': 'Hyve',
            'address': '0x61aEe582896064ecD5D85D66a32DDEB5574A699D',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/13072/thumb/bAe1G-lD_400x400.png?1654056255',
            'coingeckoId': 'hyve',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RSD',
            'name': 'Reference System for DeFi',
            'address': '0x61Ed1C66239d29Cc93C8597c6167159e8F69a823',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PIXEL',
            'name': 'Pixel',
            'address': '0x61E9c2F3501889f6167921087Bd6EA306002904a',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x61E9c2F3501889f6167921087Bd6EA306002904a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'GCAKE',
            'name': 'Pancake Games',
            'address': '0x61D5822DD7b3Ed495108733e6550d4529480C8F6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AXS',
            'name': 'Axie Infinity',
            'address': '0x61BDD9C7d4dF4Bf47A4508c0c8245505F2Af5b7b',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x61BDD9C7d4dF4Bf47A4508c0c8245505F2Af5b7b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'WORKx',
            'name': 'Superfluid WORK',
            'address': '0x61A7B6F0A7737d9bD38fdeaf1d4160E16bf23043',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x6002410dda2fb88b4d0dc3c1d562f7761191ea80/0bf96eaf72281ded70096d58159a2543.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'RETH',
            'name': 'Realms of Ethernity',
            'address': '0x614Af88dAc8bbFfC5a167BB79Ff3F5e0769F1A3F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24842/thumb/reth.png?1651909371',
            'coingeckoId': 'realms-of-ethernity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORBS',
            'name': 'Orbs',
            'address': '0x614389EaAE0A6821DC49062D56BDA3d9d45Fa2ff',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x614389eaae0a6821dc49062d56bda3d9d45fa2ff.png',
            'coingeckoId': 'orbs',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'RADIO',
            'name': 'RadioShack',
            'address': '0x613a489785C95afEB3b404CC41565cCff107B6E0',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x613a489785C95afEB3b404CC41565cCff107B6E0/logo.png',
            'coingeckoId': 'radioshack',
            'listedIn': [
                'coingecko',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'RNDR',
            'name': 'Render',
            'address': '0x61299774020dA444Af134c82fa83E3810b309991',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/11636/small/uTDd98ZN_400x400.jpg?1592200150',
            'coingeckoId': 'render-token',
            'listedIn': [
                'coingecko',
                'openocean',
                'quickswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'PROS',
            'name': 'Prosper',
            'address': '0x6109cB051c5C64093830121ed76272aB04bBDD7C',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/13668/small/heD6cg22l3sF5VgPh4G1xC6lnKEWXJif-jbaqUpv8CDP6jbWaqn9UjBdkXWNrw1CewaQOxb8zXRdNeNJWWiUDjfsEl_d7E3bPLg4cFoilQF5TGKHfWyJlnpm3UYc9ytvRvOjxOevMuiu8-lusnNoOcwgsJpMkYWHqe322GAxLt0_30kFMVAcjEDUrOlkK6hUYi0m9P433mvNlOm.jpg?1610671732',
            'coingeckoId': 'prosper',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'CUBAN',
            'name': 'TITANDAO',
            'address': '0x60caA53091da042196Cb9B5f98aAA9552AeF8c82',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://drive.google.com/file/d/1yuahTv9deIVo5hRT01rTBApmmtftCYts/view',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'IMX',
            'name': 'Impermax  OLD ',
            'address': '0x60bB3D364B765C497C8cE50AE0Ae3f0882c5bD05',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://polygon.impermax.finance/build/assets/icons/0x60bb3d364b765c497c8ce50ae0ae3f0882c5bd05.png',
            'coingeckoId': 'impermax',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'PBIRB',
            'name': 'Parrotly',
            'address': '0x60EeC374a1Ba3907e9BdD8a74cE368D041d89C79',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/28219/thumb/PARROTLY_BRAND_ASSETS__PARROTLY_ICON_BLUE_-_Copy_-_Copy_-_Copy.png?1668474102',
            'coingeckoId': 'parrotly',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EP',
            'name': 'Elemental Particles',
            'address': '0x60Ed6aCEF3a96F8CDaF0c0D207BbAfA66e751af2',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x60ed6acef3a96f8cdaf0c0d207bbafa66e751af2/9cbd7383ce02539e86ff2197ded9f810.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'jBGN',
            'name': 'Jarvis Synthetic Bulgarian Lev',
            'address': '0x60E91fc3a60362ca44aea0263DBc4b96302f6ADe',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'KTX',
            'name': 'KwikTrust',
            'address': '0x60E6895184448f3e8EF509D083e3cC3AC31F82Fd',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24352/thumb/KwikTrustLogo.png?1647433638',
            'coingeckoId': 'kwiktrust',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AMUSDT',
            'name': 'Aave Polygon USDT',
            'address': '0x60D55F02A771d515e077c9C2403a1ef324885CeC',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x3ed3b47dd13ec9a98b44e6204a523e766b225811.png',
            'coingeckoId': 'aave-polygon-usdt',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'iZi',
            'name': 'Izumi Finance',
            'address': '0x60D01EC2D5E98Ac51C8B4cF84DfCCE98D527c747',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x60d01ec2d5e98ac51c8b4cf84dfcce98d527c747/e1df9915834abea10d21f273949180b1.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ALOHA',
            'name': 'Aloha',
            'address': '0x60AC2E84078Ce30CBC68e3d7b18bCF613271ce6B',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14048/thumb/aloha.png?1615527835',
            'coingeckoId': 'aloha',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'RICE',
            'name': 'Rice Wallet',
            'address': '0x60138EFCeB1F7D8e10C8Cb89bb61BbDbEeBB4Ffc',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17204/thumb/RICE-200x200.png?1626847877',
            'coingeckoId': 'rice-wallet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WORK',
            'name': 'The Employment Commons Work',
            'address': '0x6002410dDA2Fb88b4D0dc3c1D562F7761191eA80',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x6002410dDA2Fb88b4D0dc3c1D562F7761191eA80/logo.png',
            'coingeckoId': 'the-employment-commons-work-token',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'GRT',
            'name': 'The Graph',
            'address': '0x5fe2B58c013d7601147DcdD68C143A77499f5531',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x5fe2b58c013d7601147dcdd68c143a77499f5531.png',
            'coingeckoId': 'the-graph',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'HDO',
            'name': 'HADO',
            'address': '0x5fE80d2CD054645b9419657d3d10d26391780A7B',
            'decimals': 9,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DHV',
            'name': 'DeHive',
            'address': '0x5fCB9de282Af6122ce3518CDe28B7089c9F97b26',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14926/small/logo_200x200.png?1626181831',
            'coingeckoId': 'dehive',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'TSA',
            'name': 'Teaswap Art',
            'address': '0x5f99ACF13CAff815DD9cB4A415c0fB34e9F4545b',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17826/thumb/tsa.PNG?1629358290',
            'coingeckoId': 'teaswap-art',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'pSWAMP',
            'name': 'pSwampy',
            'address': '0x5f1657896B38c4761dbc5484473c7A7C845910b6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://ethapi.openocean.finance/logos/polygon/0x5f1657896b38c4761dbc5484473c7a7c845910b6.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'KIF',
            'name': 'KIF',
            'address': '0x5f084F3EE7EA09E4C01CEE3cDF1B5620A3344fd0',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x5f084F3EE7EA09E4C01CEE3cDF1B5620A3344fd0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': '1FLR',
            'name': 'Flare',
            'address': '0x5f0197Ba06860DaC7e31258BdF749F92b6a636d4',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x5f0197ba06860dac7e31258bdf749f92b6a636d4.png',
            'coingeckoId': 'flare-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MTB',
            'name': 'ETNA Metabolism',
            'address': '0x5eE0fE440a4cA7F41bCF06b20c2A30a404E21069',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/23529/thumb/MTBlogo200.png?1644386113',
            'coingeckoId': 'etna-metabolism',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZED',
            'name': 'ZED RUN',
            'address': '0x5eC03C1f7fA7FF05EC476d19e34A22eDDb48ACdc',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26607/thumb/zed-run.jpeg?1659010387',
            'coingeckoId': 'zed-run',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'AUDIO',
            'name': 'Audius',
            'address': '0x5eB8D998371971D01954205c7AFE90A7AF6a95AC',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/7455.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ARMOR',
            'name': 'Armor (PoS)',
            'address': '0x5de4005155933c0e1612Ce808f12B4cd8DAabc82',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x5de4005155933c0e1612ce808f12b4cd8daabc82/logo.png',
            'coingeckoId': 'armor',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'RVRS',
            'name': 'Reverse',
            'address': '0x5dD175A4242afE19e5c1051d8cd13Fc8979f2329',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://i.imgur.com/AezG5si.png',
            'coingeckoId': 'reverse',
            'listedIn': [
                'coingecko',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'WNOW',
            'name': 'WalletNow',
            'address': '0x5d5530eb3147152FE78d5C4bFEeDe054c8d1442A',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/20149/thumb/walletnow.PNG?1636582255',
            'coingeckoId': 'walletnow',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BANANA',
            'name': 'ApeSwap',
            'address': '0x5d47bAbA0d66083C52009271faF3F50DCc01023C',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x5d47baba0d66083c52009271faf3f50dcc01023c.png',
            'coingeckoId': 'apeswap-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MSC',
            'name': 'MetaSoccer Cash',
            'address': '0x5d2375C6af4b7dE4e395ADa20aab937895B4fa70',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/list/master/logos/token-logos/network/polygon/0x5d2375C6af4b7dE4e395ADa20aab937895B4fa70.jpg',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'pTREAT',
            'name': 'pTREAT',
            'address': '0x5d0915f929FC090fd9c843a53e7e30335dD199bc',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x5d0915f929FC090fd9c843a53e7e30335dD199bc/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'TIME',
            'name': 'Timeleap Finance',
            'address': '0x5c59D7Cb794471a9633391c4927ADe06B8787a90',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18854/thumb/timeleap.PNG?1633596238',
            'coingeckoId': 'timeleap-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BNB',
            'name': 'Binance Token',
            'address': '0x5c4b7CCBF908E64F32e12c6650ec0C96d717f03F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/825/small/binance-coin-logo.png?1547034615',
            'coingeckoId': null,
            'listedIn': [
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'AMWBTC',
            'name': 'Aave Polygon WBTC',
            'address': '0x5c2ed810328349100A66B82b78a1791B101C9D61',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x9ff58f4ffb29fa2266ab25e75e2a8b3503311656.png',
            'coingeckoId': 'aave-polygon-wbtc',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'oUSDC',
            'name': 'Orbit Bridge Polygon USD Coin',
            'address': '0x5bEF2617eCCA9a39924c09017c5F1E25Efbb3bA8',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x5bef2617ecca9a39924c09017c5f1e25efbb3ba8.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'DEFI++',
            'name': 'PieDAO DEFI  ',
            'address': '0x5b6ab5078Bd2bbF1A215fFFBa16a94b7DF7F639d',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/13304/thumb/piedao__.png?1607322674',
            'coingeckoId': 'piedao-defi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PYQ',
            'name': 'PolyQuity',
            'address': '0x5a3064CbDCCF428ae907796cF6aD5a664CD7F3d8',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x5a3064CbDCCF428ae907796cF6aD5a664CD7F3d8/logo.png',
            'coingeckoId': 'polyquity',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'GNO',
            'name': 'Gnosis Token',
            'address': '0x5FFD62D3C3eE2E81C00A7b9079FB248e7dF024A8',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6810e776880C02933D47DB1b9fc05908e5386b96/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'uniswap',
                'rubic'
            ]
        },
        {
            'symbol': 'SOLAR',
            'name': 'SOLAR',
            'address': '0x5FBccc4Acc0B9339D7CDC5d6336A5c88D89e5327',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/Palette-Finance/images/main/solar.png',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'RNDM',
            'name': 'Random',
            'address': '0x5F8A43dd6afCE2b4eAE1dAd91dAEA5fB403cE6a6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BSGG',
            'name': 'BETSWAP.GG',
            'address': '0x5EEE4d55961705Eb55D12Cb6FA70EB93c4063216',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/17169.png',
            'coingeckoId': null,
            'listedIn': [
                'RouterProtocol',
                'lifinance'
            ]
        },
        {
            'symbol': 'MODA',
            'name': 'MODA DAO',
            'address': '0x5E430F88D1BE82EB3eF92b6fF06125168fD5DCf2',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/20870/small/ModaDAO__logomark-primary_3x.png?1637809145',
            'coingeckoId': 'moda-dao',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ORION',
            'name': 'Orion Money',
            'address': '0x5E0294Af1732498C77F8dB015a2d52a76298542B',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18630/small/YtrqPIWc.png?1632710781',
            'coingeckoId': 'orion-money',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ISKY',
            'name': 'Infinity Skies',
            'address': '0x5DFD5edFde4d8EC9e632dCA9d09Fc7E833f74210',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/22583/thumb/isky.png?1642141576',
            'coingeckoId': 'infinity-skies',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OKLP',
            'name': 'OkLetsPlay',
            'address': '0x5D48a5E5a3e737322Ae27e25897F1c9E19EcC941',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/22796/thumb/262654028_2202751929890590_982664396355018092_n.png?1642582073',
            'coingeckoId': 'okletsplay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '4INT',
            'name': '4INT',
            'address': '0x5CEeBB0947d58Fabde2fc026Ffe4B33ccFE1bA8B',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://polygonscan.com/token/images/forintfinance_32.png',
            'coingeckoId': '4int',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'DOKI',
            'name': 'Doki Doki',
            'address': '0x5C7F7Fe4766fE8f0fa9b41E2E4194d939488ff1C',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x5C7F7Fe4766fE8f0fa9b41E2E4194d939488ff1C/logo.png',
            'coingeckoId': 'doki-doki-finance',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CRPLAY',
            'name': 'CryptoSoul Roleplay',
            'address': '0x5C742c8BEB1fE8C105FCA6462A70E31A2d244a5C',
            'decimals': 10,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'UFT',
            'name': 'UniLend Finance',
            'address': '0x5B4CF2C120A9702225814E18543ee658c5f8631e',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x0202Be363B8a4820f3F4DE7FaF5224fF05943AB1/logo.png',
            'coingeckoId': 'unlend-finance',
            'listedIn': [
                'coingecko',
                'dfyn',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'BFLY',
            'name': 'Butterfly Protocol (PoS)',
            'address': '0x5B0a0CD03e9Df1829E00128ebE277Cc3247da346',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x5b0a0cd03e9df1829e00128ebe277cc3247da346/logo.png',
            'coingeckoId': 'butterfly-protocol-2',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ETHS',
            'name': '(PoS) Ether Stone',
            'address': '0x5Adf17a97Ef5DB18ad4cFC4B3F05128b922E0a58',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x5adf17a97ef5db18ad4cfc4b3f05128b922e0a58/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ETHA',
            'name': 'ETHA',
            'address': '0x59E9261255644c411AfDd00bD89162d09D862e38',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x59e9261255644c411afdd00bd89162d09d862e38.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'CHP',
            'name': 'CoinPoker',
            'address': '0x59B5654a17Ac44F3068b3882F298881433bB07Ef',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x59b5654a17ac44f3068b3882f298881433bb07ef.png',
            'coingeckoId': 'coinpoker',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ADS',
            'name': 'Adshares',
            'address': '0x598e49f01bEfeB1753737934a5b11fea9119C796',
            'decimals': 11,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x598e49f01befeb1753737934a5b11fea9119c796.png',
            'coingeckoId': 'adshares',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'JRT',
            'name': 'Jarvis Reward',
            'address': '0x596eBE76e2DB4470966ea395B0d063aC6197A8C5',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/trustwallet/assets/b8086dbc7040582d1412effe7a951914d4a96eef/blockchains/ethereum/assets/0x8A9C67fee641579dEbA04928c4BC45F66e26343A/logo.png',
            'coingeckoId': 'jarvis-reward-token',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'quickswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'HOGE',
            'name': 'hoge.finance',
            'address': '0x58c1BBb508e96CfEC1787Acf6Afe1C7008A5B064',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x58c1bbb508e96cfec1787acf6afe1c7008a5b064/401f6abf3179fb79103f10a09332143d.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ABT',
            'name': 'Aprobit',
            'address': '0x5827661d7b95C99cE6ac832B89F8B77414e55Ff8',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27620/thumb/signage_%283%29.png?1664879025',
            'coingeckoId': 'aprobit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QI',
            'name': 'Qi Dao',
            'address': '0x580A84C73811E1839F75d86d75d88cCa0c241fF4',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x580a84c73811e1839f75d86d75d88cca0c241ff4.png',
            'coingeckoId': 'qi-dao',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'quickswap',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'TXAG',
            'name': 'tSILVER',
            'address': '0x57fCbd6503C8BE3B1AbAD191Bc7799ef414A5b31',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27829/thumb/tSILVER_token_2D.jpg?1666165851',
            'coingeckoId': 'tsilver',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GFOOD',
            'name': 'RatAlert Gourmet Food',
            'address': '0x57d43Cfe565A2e6C181662aE73A9F1EC6A830351',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25954/thumb/gfood.png?1654853755',
            'coingeckoId': 'ratalert-gourmet-food',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KIWI',
            'name': 'KIWI Token',
            'address': '0x578360AdF0BbB2F10ec9cEC7EF89Ef495511ED5f',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x578360adf0bbb2f10ec9cec7ef89ef495511ed5f/logo.png',
            'coingeckoId': 'kiwi-token',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'APOLLO',
            'name': 'Apollo',
            'address': '0x577aa684B89578628941D648f1Fbd6dDE338F059',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://i.ibb.co/YLYTVJ7/APOLLO-PNG2.png',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'FIN',
            'name': 'DeFiner',
            'address': '0x576c990A8a3E7217122e9973b2230A3be9678E94',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/12780/small/PdaW8Lb.png?1602500407',
            'coingeckoId': null,
            'listedIn': [
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'LIF3',
            'name': 'LIF3',
            'address': '0x56ac3Cb5E74b8A5A25ec9Dc05155aF1dad2715bd',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'eXRD',
            'name': 'E-RADIX (PoS)',
            'address': '0x56B1c1e028c2E0a8852906E1eE7730ED67A3D9Bb',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x56b1c1e028c2e0a8852906e1ee7730ed67a3d9bb/logo.png',
            'coingeckoId': 'e-radix',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'AWX',
            'name': 'AurusDeFi',
            'address': '0x56A0eFEFC9F1FBb54FBd25629Ac2aA764F1b56F7',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/12684/small/2021-12-06-Aurus-tokens-for-coingecko-AWX-flat-color-v1-r1-AS.png?1640223413',
            'coingeckoId': 'auruscoin',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'EKX',
            'name': 'EnkiX',
            'address': '0x566664c32138605176F9D3f50C747aB417b1b256',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24541/thumb/hero-1.png?1648090252',
            'coingeckoId': 'enkix',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DCT',
            'name': 'Debt_Consolidation',
            'address': '0x5650C49D662cb83666aB0FAD224453B603179D2A',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WALLET',
            'name': 'Ambire Wallet',
            'address': '0x564906ec1DF8399F00e4ad32c0eCAC0404a27A1c',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/23154/thumb/wallet.PNG?1643352408',
            'coingeckoId': 'ambire-wallet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MM',
            'name': 'Million',
            'address': '0x5647Fe4281F8F6F01E84BCE775AD4b828A7b8927',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/16825/small/logo200x200.png?1625834139',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'REB',
            'name': 'Rebellion DAO',
            'address': '0x561f7AE92a00C73DC48282d384e0A4FC1f4bC305',
            'decimals': 21,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24805/thumb/rebellion_dao.png?1648991371',
            'coingeckoId': 'rebellion-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AX',
            'name': 'AthleteX',
            'address': '0x5617604BA0a30E0ff1d2163aB94E50d8b6D0B0Df',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x5617604BA0a30E0ff1d2163aB94E50d8b6D0B0Df/logo.png',
            'coingeckoId': 'athletex',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ETHM',
            'name': 'Ethereum Meta',
            'address': '0x55b1a124c04A54eeFDEFE5FA2Ef5f852FB5f2f26',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/6586/thumb/ethereum-meta.png?1548125409',
            'coingeckoId': 'ethereum-meta',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KING',
            'name': 'KingFund',
            'address': '0x557461d81574030b5DfEBf238762f00EC8228cD7',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DUST',
            'name': 'Distant Universe Stardust Token',
            'address': '0x556f501CF8a43216Df5bc9cC57Eb04D4FFAA9e6D',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x556f501cf8a43216df5bc9cc57eb04d4ffaa9e6d/logo.png',
            'coingeckoId': 'dust-token',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ZRX',
            'name': '0x Protocol Token',
            'address': '0x5559Edb74751A0edE9DeA4DC23aeE72cCA6bE3D5',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xE41d2489571d322189246DaFA5ebDe1F4699F498/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'uniswap'
            ]
        },
        {
            'symbol': 'XY',
            'name': 'XY Finance',
            'address': '0x55555555A687343C6Ce28C8E1F6641dc71659fAd',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/21541/small/xy.png?1639913622',
            'coingeckoId': 'xy-finance',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'ANRX',
            'name': 'AnRKey X',
            'address': '0x554f074d9cCda8F483d1812d4874cBebD682644E',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x554f074d9ccda8f483d1812d4874cbebd682644e.png',
            'coingeckoId': 'anrkey-x',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'PAXG',
            'name': 'Paxos Gold',
            'address': '0x553d3D295e0f695B9228246232eDF400ed3560B5',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/9519/small/paxg.PNG?1568542565',
            'coingeckoId': null,
            'listedIn': [
                'quickswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'BZRX',
            'name': 'BZRX Token',
            'address': '0x54cFe73f2c7d0c4b62Ab869B473F5512Dc0944D2',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x54cFe73f2c7d0c4b62Ab869B473F5512Dc0944D2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'LINK',
            'name': 'Chainlink',
            'address': '0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x514910771af9ca656af840dff83e8264ecf986ca.png',
            'coingeckoId': 'chainlink',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'quickswap',
                'dfyn',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SHN',
            'name': 'Shine (PoS)',
            'address': '0x53D76f967De13E7F95e90196438DCe695eCFA957',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'KP3R',
            'name': 'Keep3rV1 (PoS)',
            'address': '0x53AEc293212E3B792563Bc16f1be26956adb12e9',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x53aec293212e3b792563bc16f1be26956adb12e9/logo.png',
            'coingeckoId': 'keep3rv1',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BURP',
            'name': 'Burp',
            'address': '0x538D47D142F6993038a667E9D6210d3735749B36',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17317/thumb/Big_Town_Chef_-__BURP_-_Avatar_3x.png?1646202255',
            'coingeckoId': 'burp',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHA',
            'name': 'Safe Haven',
            'address': '0x534F39c5f4DF9cB13E16B24cA07c7C8C0E2eadB7',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/2584/thumb/safehaven.png?1620037471',
            'coingeckoId': 'safe-haven',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FODL',
            'name': 'Fodl Finance',
            'address': '0x5314bA045a459f63906Aa7C76d9F337DcB7d6995',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x5314ba045a459f63906aa7c76d9f337dcb7d6995.png',
            'coingeckoId': 'fodl-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'CVP',
            'name': 'Concentrated Voting Power',
            'address': '0x530d954549F3Efa00799784BC7FE0C3FED76Da19',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x530d954549f3efa00799784bc7fe0c3fed76da19/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'W',
            'name': 'Tungsten',
            'address': '0x52fc543Bd23D1948F11eb265eAbDEfBbB0131572',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/W.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': '0XMR',
            'name': '0xMonero',
            'address': '0x52eDe6bbA83B7b4Ba1D738Df0dF713D6A2036B71',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/11035/thumb/0xmnr.PNG?1587357680',
            'coingeckoId': '0xmonero',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'FS',
            'name': 'FantomStarter',
            'address': '0x525b43A49bE2Ed530e3516C22bd7ECbcF1586AD4',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PODO',
            'name': 'Power Of Deep Ocean',
            'address': '0x52468c88e8b4F5bCCA20a6A7813355637Dc5e3Ad',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27645/thumb/PODO_TICKER_200.png?1665020330',
            'coingeckoId': 'power-of-deep-ocean',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOVE',
            'name': 'Mover',
            'address': '0x521CddC0CBa84F14c69C1E99249F781AA73Ee0BC',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x521CddC0CBa84F14c69C1E99249F781AA73Ee0BC/logo.png',
            'coingeckoId': 'holyheld-2',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'EGG',
            'name': 'Waves Ducks',
            'address': '0x51dE72b17c7bD12E9E6d69eb506a669EB6b5249E',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17298/small/200x200_pixel.png?1627288546',
            'coingeckoId': 'waves-ducks',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'VHC',
            'name': 'Vault Hill City',
            'address': '0x51b5619F5180e333d18b6310C8D540AEa43a0371',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x51b5619f5180e333d18b6310c8d540aea43a0371.png',
            'coingeckoId': 'vault-hill-city',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'KABY',
            'name': 'Kaby Arena',
            'address': '0x5198E7cC1640049dE37D1Bd10b03Fa5A3AFDA120',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18090/thumb/URPKhnop_400x400.jpg?1630416255',
            'coingeckoId': 'kaby-arena',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GENESIS',
            'name': 'Genesis Worlds',
            'address': '0x51869836681BcE74a514625c856aFb697a013797',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/19905/small/kKkWmniB_400x400.jpg?1636100432',
            'coingeckoId': 'genesis-worlds',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'iBFR',
            'name': 'iBuffer Token',
            'address': '0x5157629E486b36f5862d163C119c4E86506cA15e',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ACRV',
            'name': 'Aave CRV',
            'address': '0x513c7E3a9c69cA3e22550eF58AC1C0088e918FFf',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17250/thumb/aCRV_2x.png?1626941642',
            'coingeckoId': 'aave-crv',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASIA',
            'name': 'Asia Coin',
            'address': '0x50bCBC40306230713239Ae1BdDD5eefEEaa273Dc',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18589/thumb/Ou7mp_R1TQ5B9vsBiZ8oQnSv36M6hiA2hESxV_7YSw0.png?1632556422',
            'coingeckoId': 'asia-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TBTC',
            'name': 'tBTC',
            'address': '0x50a4a434247089848991DD8f09b889D4e2870aB6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/11224/thumb/tBTC.png?1589620754',
            'coingeckoId': null,
            'listedIn': [
                'uniswap'
            ]
        },
        {
            'symbol': 'SNX',
            'name': 'Synthetix Network',
            'address': '0x50B728D8D964fd00C2d0AAD81718b71311feF68a',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x50b728d8d964fd00c2d0aad81718b71311fef68a.png',
            'coingeckoId': 'havven',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'SPORT',
            'name': 'SPORT',
            'address': '0x503836c8c3A453c57f58CC99B070F2E78Ec14fC0',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25823/thumb/SPORT_LOGO_PNG.png?1654057785',
            'coingeckoId': 'sport',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'SOLACE',
            'name': 'SOLACE',
            'address': '0x501acE9c35E60f03A2af4d484f49F9B1EFde9f40',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/21277/thumb/IMG_6599.png?1638862378',
            'coingeckoId': 'solace',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COR',
            'name': 'Coreto',
            'address': '0x4fdCE518fE527439Fe76883e6b51A1c522B61b7C',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://etherscan.io/token/images/cortoken_32.png?v=124124521',
            'coingeckoId': 'coreto',
            'listedIn': [
                'coingecko',
                'dfyn',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'IETH-FLI-P',
            'name': 'Index Coop   Inverse ETH Flexible Lever',
            'address': '0x4f025829C4B13dF652f38Abd2AB901185fF1e609',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/23426/thumb/iETH-FLI-P-512_%28pulse_red%29.png?1644204746',
            'coingeckoId': 'index-coop-inverse-eth-flexible-leverage-index',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YAE',
            'name': 'Cryptonovae',
            'address': '0x4eE438be38F8682ABB089F2BFeA48851C5E71EAF',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'eUSDC',
            'name': 'Easy USDC',
            'address': '0x4eBdE54ba404bE158262EDe801744b92b9878c61',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x4eBdE54ba404bE158262EDe801744b92b9878c61/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ODDZ',
            'name': 'Oddz',
            'address': '0x4e830F67Ec499E69930867f9017AEb5B3f629c73',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14421/small/img_circle_200x200.png?1617345151',
            'coingeckoId': 'oddz',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'KLIMA',
            'name': 'Klima DAO',
            'address': '0x4e78011Ce80ee02d2c3e649Fb657E45898257815',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x4e78011ce80ee02d2c3e649fb657e45898257815.png',
            'coingeckoId': 'klima-dao',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'JEUR',
            'name': 'Jarvis Synthetic Euro',
            'address': '0x4e3Decbb3645551B8A19f0eA1678079FCB33fB4c',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x4e3decbb3645551b8a19f0ea1678079fcb33fb4c.png',
            'coingeckoId': 'jarvis-synthetic-euro',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ICE',
            'name': 'Popsicle Finance',
            'address': '0x4e1581f01046eFDd7a1a2CDB0F82cdd7F71F2E59',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x4e1581f01046efdd7a1a2cdb0f82cdd7f71f2e59.png',
            'coingeckoId': 'ice-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'MRCH',
            'name': 'MerchDAO',
            'address': '0x4dF071FB2D145bE595b767f997C91818694A6CE1',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14540/thumb/logo_256x256.png?1616891356',
            'coingeckoId': 'merchdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DIGI',
            'name': 'Digible',
            'address': '0x4d8181f051E617642e233Be09Cea71Cc3308ffD4',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14960/thumb/RE3Fiua.png?1619148839',
            'coingeckoId': 'digible',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'DPC',
            'name': 'Developer Coin',
            'address': '0x4d59249877CfFf9aaa3aE572d06c5B71a79B6215',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SKP',
            'name': 'SKYPlay',
            'address': '0x4c665BBafd28Ec9e5D792345f470ebFcA21E3d15',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27129/thumb/SKP_logo_20x20.png?1662021510',
            'coingeckoId': 'skyplay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WEXPOLY',
            'name': 'WaultSwap Polygon',
            'address': '0x4c4BF319237D98a30A929A96112EfFa8DA3510EB',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x4c4bf319237d98a30a929a96112effa8da3510eb.png',
            'coingeckoId': 'waultswap-polygon',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'wMatic',
            'name': 'wMatic',
            'address': '0x4c28f48448720e9000907BC2611F73022fdcE1fA',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://cryptologos.cc/logos/matic-network-matic-logo.svg?v=006',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'jEURx',
            'name': 'Superfluid jEUR',
            'address': '0x4bDe23854e7C81218463f6C8f331b46144E98eaC',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x4e3decbb3645551b8a19f0ea1678079fcb33fb4c/1e153fa6bb41ac7181ec0204b24932d7.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BTC2x-FLI',
            'name': 'BTC 2x Flexible Leverage Index',
            'address': '0x4b54Bc363f5F9c6E0fcd82EaC6919aE213464Cc6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x4b54Bc363f5F9c6E0fcd82EaC6919aE213464Cc6/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BLID',
            'name': 'Bolide',
            'address': '0x4b27Cd6E6a5E83d236eAD376D256Fe2F9e9f0d2E',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TRYB',
            'name': 'BiLira',
            'address': '0x4Fb71290Ac171E1d144F7221D882BECAc7196EB5',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/10119/thumb/JBs9jiXO_400x400.jpg?1642668342',
            'coingeckoId': 'bilira',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RIOT',
            'name': 'Riot Racers',
            'address': '0x4FF0b68ABc2b9E4e1401E9b691DBa7d66B264AC8',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x4ff0b68abc2b9e4e1401e9b691dba7d66b264ac8.png',
            'coingeckoId': 'riot-racers',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'QWLA',
            'name': 'Qawalla',
            'address': '0x4FAfad147c8Cd0e52f83830484d164e960BdC6C3',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15242/thumb/qwla.png?1648268288',
            'coingeckoId': 'qawalla',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WHIRL',
            'name': 'OmniWhirl',
            'address': '0x4F6cbacA3151f7746273004Fd7295933a9b70E69',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17165/thumb/logo-200x.png?1626683241',
            'coingeckoId': 'omniwhirl',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ibBTC',
            'name': 'Interest-Bearing BTC',
            'address': '0x4EaC4c4e9050464067D673102F8E24b2FccEB350',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x4EaC4c4e9050464067D673102F8E24b2FccEB350/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'CBK',
            'name': 'Cobak',
            'address': '0x4EC203dD0699Fac6adAF483CDd2519BC05D2c573',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/13459/thumb/cbk-128-128.png?1608760936',
            'coingeckoId': 'cobak-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSCGIRL',
            'name': 'Binance Smart Chain Girl',
            'address': '0x4E9A46EA6A22f3894aBEE2302Ad42fd3b69E21E2',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17271/thumb/icon_200px_16bit.png?1627005622',
            'coingeckoId': 'binance-smart-chain-girl',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INJ',
            'name': 'Injective Token (PoS)',
            'address': '0x4E8dc2149EaC3f3dEf36b1c281EA466338249371',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x4e8dc2149eac3f3def36b1c281ea466338249371/logo.png',
            'coingeckoId': 'injective-protocol',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'LOOT',
            'name': 'LOOT Token',
            'address': '0x4E2B07c0bCd975CAF4F6B10cA30D59D568d0fB0d',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WAVE',
            'name': 'WAVE Token',
            'address': '0x4DE7FEA447b837d7E77848a4B6C0662a64A84E14',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x4DE7FEA447b837d7E77848a4B6C0662a64A84E14/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'KIT',
            'name': 'DexKit',
            'address': '0x4D0Def42Cf57D6f27CD4983042a55dce1C9F853c',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/13187/thumb/7739.png?1643187354',
            'coingeckoId': 'dexkit',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'YLD',
            'name': 'Yield App',
            'address': '0x4CEBdBCB286101A17D3eA1f7fe7bbDeD2B2053dd',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/13365/thumb/Google_Play_Store_Icon.png?1657012535',
            'coingeckoId': 'yield-app',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WSB',
            'name': 'Wallstreetbets com',
            'address': '0x4CB9050EA02395a8941715ceeffe0cDf60c59f07',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24170/thumb/18622.png?1646718687',
            'coingeckoId': 'wallstreetbets-com',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MNTO',
            'name': 'Minato',
            'address': '0x4C9f66B2806538cf00ef596E09FB05bcb0D17Dc8',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24622/thumb/MNTO_200x200.png?1648448664',
            'coingeckoId': 'minato',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POLLY',
            'name': 'Polly Finance',
            'address': '0x4C392822D4bE8494B798cEA17B43d48B2308109C',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18354/thumb/POLLY.png?1631604593',
            'coingeckoId': 'polly',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POLYBUNNY',
            'name': 'Pancake Bunny Polygon',
            'address': '0x4C16f69302CcB511c5Fac682c7626B9eF0Dc126a',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/PancakeBunny-finance/PolygonBUNNY/main/assets/token-bunny_32x32.png',
            'coingeckoId': 'bunny-token-polygon',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'RGP',
            'name': 'Rigel Protocol',
            'address': '0x4AF5ff1A60a6eF6C7c8f9C4E304cD9051fCa3Ec0',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/9225.png',
            'coingeckoId': 'rigel-protocol',
            'listedIn': [
                'coingecko',
                'RouterProtocol'
            ]
        },
        {
            'symbol': 'EGG',
            'name': 'PolyFarm EGG',
            'address': '0x4AC6E3CdA66967F1286dA690129a33638f9E7088',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/23512/thumb/polyfarm_egg.png?1644300527',
            'coingeckoId': 'polyfarm-egg',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ICE',
            'name': 'Iron Finance',
            'address': '0x4A81f8796e0c6Ad4877A51C86693B0dE8093F2ef',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x4a81f8796e0c6ad4877a51c86693b0de8093f2ef.png',
            'coingeckoId': 'iron-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'GEM',
            'name': 'NFTmall',
            'address': '0x4A7b9A4589A88A06ca29F99556dB08234078D727',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/16217/thumb/Icon-1000x1000.png?1623316471',
            'coingeckoId': 'nftmall',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'TNGBL',
            'name': 'Tangible',
            'address': '0x49e6A20f1BBdfEeC2a8222E052000BbB14EE6007',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x49e6a20f1bbdfeec2a8222e052000bbb14ee6007/2f07fea3de4943f1b4b662a55dcfe084.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MIM',
            'name': 'Magic Internet Money',
            'address': '0x49a0400587A7F65072c87c4910449fDcC5c47242',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/logos/main/network/matic/0x49a0400587A7F65072c87c4910449fDcC5c47242.jpg',
            'coingeckoId': 'magic-internet-money',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': '1337',
            'name': '1337 (PoS)',
            'address': '0x49Fc111e5Ddd5580f48D6fDc4314540cb3A5CC4B',
            'decimals': 4,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x49fc111e5ddd5580f48d6fdc4314540cb3a5cc4b/logo.png',
            'coingeckoId': 'e1337',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'GGTK',
            'name': 'GG',
            'address': '0x49B1bE61A8Ca3f9A9F178d6550e41E00D9162159',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/13666/thumb/ggtk.png?1629348879',
            'coingeckoId': 'gg-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XSGDx',
            'name': 'Superfluid XSGD',
            'address': '0x49765f8FCF0a1cD4f98DA906f0974A9085D43e51',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0xdc3326e71d45186f113a2f448984ca0e8d201995/ff149cce106e05c9271f55866f905a29.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AXIAV3',
            'name': 'Axia',
            'address': '0x49690541E3f6E933A9aa3cFFEe6010a7BB5B72d7',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/12906/thumb/axia_200x200.png?1613988058',
            'coingeckoId': 'axia',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SENT',
            'name': 'Sentinel',
            'address': '0x48e3883233461C2eF4cB3FcF419D6db07fb86CeA',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x48e3883233461C2eF4cB3FcF419D6db07fb86CeA/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FOUR',
            'name': '4thpillar technologies',
            'address': '0x48cBc913dE09317dF2365e6827Df50dA083701D5',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/3434/thumb/four-ticker-2021-256x256.png?1617702287',
            'coingeckoId': 'the-4th-pillar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VERSE',
            'name': 'Verse',
            'address': '0x48C97cf0A3837106Cb58009D308DF4DfAbe441C7',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18946/thumb/verse_200.png?1634006367',
            'coingeckoId': 'verse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLY',
            'name': 'Flycoin FLY',
            'address': '0x486FFAf06A681bf22B5209e9fFCE722662A60E8C',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26596/thumb/flatArtboard_20tfr.png?1658974032',
            'coingeckoId': 'flycoin-fly',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'jPHP',
            'name': 'Jarvis Synthetic Philippine Peso',
            'address': '0x486880FB16408b47f928F472f57beC55AC6089d1',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'ipfs://QmS65LifvH8eiuZPW4xbBxDCCgdZ1S9jWrCtEe489MHBtV',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CROWD',
            'name': 'CrowdSwap',
            'address': '0x483dd3425278C1f79F377f1034d9d2CaE55648B6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/23928/thumb/icon_192.png?1645690364',
            'coingeckoId': 'crowdswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PAY',
            'name': 'Webpay',
            'address': '0x48128d062e44364d4C277d9AEd71d7F53977937B',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26429/thumb/logo.jpg?1657930482',
            'coingeckoId': 'webpay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'H3RO3S',
            'name': 'H3RO3S',
            'address': '0x480fD103973357266813EcfcE9faABAbF3C4ca3A',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/20906/thumb/h3roes.PNG?1639784887',
            'coingeckoId': 'h3ro3s',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FOOTIE',
            'name': 'Footie Plus',
            'address': '0x47cAc8a868e301aFb89B52AC31665858938E96b5',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17628/thumb/footie.jpeg?1628683039',
            'coingeckoId': 'footie-plus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MS',
            'name': 'Morphswap',
            'address': '0x476718Ea98525f6EEBa3689b321E709522aE0930',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/28114/thumb/mslogo200.png?1668056773',
            'coingeckoId': 'morphswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APO',
            'name': 'ApolloFi',
            'address': '0x475DB18D764DF7FaDfbD3e73fcCbBC1e14342aB3',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26451/thumb/apo.png?1660311541',
            'coingeckoId': 'apollofi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BELUGA',
            'name': 'Beluga fi',
            'address': '0x47536F17F4fF30e64A96a7555826b8f9e66ec468',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/13790/thumb/brand-logo-v2.23e5d279.png?1624906164',
            'coingeckoId': 'beluga-fi',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ARIA20',
            'name': 'Arianee',
            'address': '0x46F48FbdedAa6F5500993BEDE9539ef85F4BeE8e',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x46f48fbdedaa6f5500993bede9539ef85f4bee8e.png',
            'coingeckoId': 'arianee',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WSPP',
            'name': 'WolfSafePoorPeople Polygon',
            'address': '0x46D502Fac9aEA7c5bC7B13C8Ec9D02378C33D36F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x46D502Fac9aEA7c5bC7B13C8Ec9D02378C33D36F/logo.png',
            'coingeckoId': 'wolfsafepoorpeople-polygon',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'TIC',
            'name': 'Infinite Arcade TIC',
            'address': '0x46AB9e9153446d4016bCC3B6d4F6920d8b24E434',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25919/thumb/VNReVzc5Sg6NqHONgxEQ_4BI9yq3820sYQx0R.jpg?1654593140',
            'coingeckoId': 'infinite-arcade-tic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STAK',
            'name': 'Jigstack',
            'address': '0x46A5D492788f8AFdfC743Ab7D7bD13F996249ED5',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x46a5d492788f8afdfc743ab7d7bd13f996249ed5/a368214eff1db278145795386f115ec0.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BZB',
            'name': 'BlazarBits',
            'address': '0x462D8d82C2B2D2DDabf7f8a93928De09d47A5807',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://flowimage.flow.page/resize?img=https%3A%2F%2Fcdn.flow.page%2Fimages%2Fe5e655f1-421f-4948-be2e-c679ea1cdc2d-profile-picture%3Fm%3D1610401591&w=320',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'FRAX',
            'name': 'Frax',
            'address': '0x45c32fA6DF82ead1e2EF74d17b76547EDdFaFF89',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x45c32fa6df82ead1e2ef74d17b76547eddfaff89.png',
            'coingeckoId': 'frax',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'quickswap',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'API3',
            'name': 'API3 (PoS)',
            'address': '0x45C27821E80F8789b60Fd8B600C73815d34DDa6C',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x45c27821e80f8789b60fd8b600c73815d34dda6c/logo.png',
            'coingeckoId': 'api3',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'COVAL',
            'name': 'Circuits of Value',
            'address': '0x4597c8A59Ab28B36840B82B3A674994A279593D0',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/588/thumb/coval-logo.png?1599493950',
            'coingeckoId': 'circuits-of-value',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YON',
            'name': 'YES||NO',
            'address': '0x45827b9457F8B7bEf503172819f333d1a9eb85f6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PHIVE',
            'name': 'Polygon Hive',
            'address': '0x456320E9B87a7C84a53B8ab300067F3a29Aab301',
            'decimals': 3,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25495/thumb/Hive_Logo__200x200_.jpg?1652176902',
            'coingeckoId': 'polygon-hive',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KZEN',
            'name': 'Kaizen',
            'address': '0x4550003152F12014558e5CE025707E4DD841100F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24396/thumb/PKl5OVRv_400x400.png?1647522756',
            'coingeckoId': 'kaizen',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '300',
            'name': 'Spartan (PoS)',
            'address': '0x454E1Ec2F58ee542B75d2AB713Ed53Cd33Ba4308',
            'decimals': 7,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x454e1ec2f58ee542b75d2ab713ed53cd33ba4308/logo.png',
            'coingeckoId': 'spartan',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MYT',
            'name': 'Mytrade',
            'address': '0x4535E52cdF3AB787B379b7b72B5990767E6747E4',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24134/thumb/transparent_logo.png?1646543530',
            'coingeckoId': 'mytrade',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '$KMC',
            'name': '$KMC',
            'address': '0x44d09156c7b4ACf0C64459Fbcced7613F5519918',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x44d09156c7b4acf0c64459fbcced7613f5519918.png',
            'coingeckoId': 'kitsumon',
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'FOMO',
            'name': 'Aavegotchi FOMO',
            'address': '0x44A6e0BE76e1D9620A7F76588e4509fE4fa8E8C8',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24737/small/fomo.png?1648769659',
            'coingeckoId': 'aavegotchi-fomo',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'EUX',
            'name': 'dForce EUR',
            'address': '0x448BBbDB706cD0a6AB74fA3d1157e7A33Dd3A4a8',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://get.celer.app/cbridge-icons/EUX.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'KNIGHT',
            'name': 'Forest Knight',
            'address': '0x4455eF8B4B4A007a93DaA12DE63a47EEAC700D9D',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18441/small/Coin_Forest_Knight_200x200.png?1632480031',
            'coingeckoId': 'forest-knight',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'TOP',
            'name': 'Tokenplay',
            'address': '0x4438da4Ef8e791A9964D27742676e6A70BeB2514',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17687/thumb/tokenplay.PNG?1628846988',
            'coingeckoId': 'tokenplay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PRYZ',
            'name': 'Pryz',
            'address': '0x4414aC21b60c504DFEa0A27679b90a278C2ca962',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15361/thumb/pryz.png?1639199590',
            'coingeckoId': 'pryz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EWTB',
            'name': 'Energy Web Token Bridged (PoS)',
            'address': '0x43E4B063F96c33F0433863A927F5bad34bb4B03d',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x43e4b063f96c33f0433863a927f5bad34bb4b03d/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'GOVI',
            'name': 'CVI',
            'address': '0x43Df9c0a1156c96cEa98737b511ac89D0e2A1F46',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/13875/thumb/GOVI.png?1612451531',
            'coingeckoId': 'govi',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'NIFTSY',
            'name': 'Envelop  Niftsy ',
            'address': '0x432cdbC749FD96AA35e1dC27765b23fDCc8F5cf1',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/19161/thumb/logo200x200.png?1643188130',
            'coingeckoId': 'niftsy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JPYC',
            'name': 'JPY Coin',
            'address': '0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x431d5dff03120afa4bdf332c61a6e1766ef37bdb.png',
            'coingeckoId': 'jpy-coin',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'RBW',
            'name': 'Crypto Unicorns Rainbow',
            'address': '0x431CD3C9AC9Fc73644BF68bF5691f4B83F9E104f',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x431cd3c9ac9fc73644bf68bf5691f4b83f9e104f.png',
            'coingeckoId': 'rainbow-token-2',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'PYR',
            'name': 'Vulcan Forged',
            'address': '0x430EF9263E76DAE63c84292C3409D61c598E9682',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14770/small/1617088937196.png?1619414736',
            'coingeckoId': 'vulcan-forged',
            'listedIn': [
                'coingecko',
                'openocean',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'KEEP',
            'name': 'Keep Network',
            'address': '0x42f37A1296b2981F7C3cAcEd84c5096b2Eb0C72C',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/3373/thumb/IuNzUb5b_400x400.jpg?1589526336',
            'coingeckoId': null,
            'listedIn': [
                'uniswap',
                'rubic'
            ]
        },
        {
            'symbol': 'MEM',
            'name': 'Memecoin',
            'address': '0x42dbBd5ae373FEA2FC320F62d44C058522Bb3758',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/16370/thumb/mem_gold_200x200_copy.png?1623821100',
            'coingeckoId': 'memecoin',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'PSP',
            'name': 'ParaSwap',
            'address': '0x42d61D766B85431666B39B89C43011f24451bFf6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x42d61d766b85431666b39b89c43011f24451bff6.png',
            'coingeckoId': 'paraswap',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'CIV',
            'name': 'Civilization',
            'address': '0x42F6bdCfd82547e89F1069Bf375aa60e6c6c063d',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17626/thumb/civ-200x200.png?1628674350',
            'coingeckoId': 'civilization',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KEK',
            'name': 'Aavegotchi KEK',
            'address': '0x42E5E06EF5b90Fe15F853F59299Fc96259209c5C',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24739/small/kek.png?1648769879',
            'coingeckoId': 'aavegotchi-kek',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'IBZ',
            'name': 'Ibiza',
            'address': '0x428aC1de3FC08c0F3A47745C964f7d677716981F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/19349/thumb/AZHnAHiY_400x400.jpg?1635127261',
            'coingeckoId': 'ibiza-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRACE',
            'name': 'Trace Network Labs',
            'address': '0x4287F07CBE6954f9F0DecD91d0705C926d8d03A4',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/23266/small/Token_Icon_02.png?1655797843',
            'coingeckoId': 'trace-network-labs',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'DEFIT',
            'name': 'Digital Fitness',
            'address': '0x428360b02C1269bc1c79fbC399ad31d58C1E8fdA',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x428360b02c1269bc1c79fbc399ad31d58c1e8fda.png',
            'coingeckoId': 'defit',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'DEFI5',
            'name': 'DEFI Top 5 Tokens Index (PoS)',
            'address': '0x42435F467D33e5C4146a4E8893976ef12BBCE762',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x42435F467D33e5C4146a4E8893976ef12BBCE762/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FBIRD',
            'name': 'Firebird DAO (PoS)',
            'address': '0x422E0Cba8e57Acc799fC14DAdf2b72DfbA281c5F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x422e0cba8e57acc799fc14dadf2b72dfba281c5f/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DoTxUNI-V2',
            'name': 'DoTx Uniswap V2 (PoS)',
            'address': '0x421E0acEa8cD9D4aa2bcEdB407726b6A4Bffb664',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x421e0acea8cd9d4aa2bcedb407726b6a4bffb664/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'EVO',
            'name': 'EVO',
            'address': '0x42006Ab57701251B580bDFc24778C43c9ff589A1',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25088/large/evoToken.png?1650269135',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'RouterProtocol',
                'lifinance'
            ]
        },
        {
            'symbol': 'NEXO',
            'name': 'Nexo',
            'address': '0x41b3966B4FF7b427969ddf5da3627d6AEAE9a48E',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://cryptologos.cc/logos/nexo-nexo-logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'quickswap',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'PONY',
            'name': 'PONY Index',
            'address': '0x417871f0682Db63924B931Fc16ba3Ff40343Cdcb',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'OH',
            'name': 'Oh! Finance',
            'address': '0x411f3e09C66B30E7FACFec45Cd823B2E19dFAD2D',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MRST',
            'name': 'Mars Token',
            'address': '0x411bc96881A62572FF33c9d8CE60dF99E3d96cd8',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27230/thumb/MRST.png?1669106886',
            'coingeckoId': 'the-mars',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LFG',
            'name': 'Gamerse',
            'address': '0x411bE1E071675dF40fE1c08CA760Bb7aA707CEdF',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/19582/thumb/gamerse.PNG?1635470285',
            'coingeckoId': 'gamerse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GREEN',
            'name': 'Greenhouse',
            'address': '0x40DB6d7812b8288eCA452F912ca9F262b186f278',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24415/thumb/greenhouse.PNG?1647573129',
            'coingeckoId': 'greenhouse',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'RAGE',
            'name': 'Rage',
            'address': '0x40CCD55B789fdEE8D434915dC2Aa6Bd938506A92',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14585/small/AcNx2x6P_400x400.jpg?1617153476',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'WBTCx',
            'name': 'Superfluid WBTC',
            'address': '0x4086eBf75233e8492F1BCDa41C7f2A8288c2fB92',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6/d3c52e7c7449afa8bd4fad1c93f50d93.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'METR',
            'name': 'Metria Network',
            'address': '0x405CE8B2eAeeA7D4Ba5Fc160848cB2A6569e03f0',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24870/thumb/Metria-logo_1.png?1649213114',
            'coingeckoId': 'metria',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FUD',
            'name': 'Aavegotchi FUD',
            'address': '0x403E967b044d4Be25170310157cB1A4Bf10bdD0f',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24736/small/fud.png?1648769512',
            'coingeckoId': 'aavegotchi-fud',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'LIQ',
            'name': 'Liquidus',
            'address': '0x4036f3d9c45a20f44f0b8B85dD6CA33005fF9654',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'KARMA',
            'name': 'Karma (PoS)',
            'address': '0x4016489B230980fd43511e28E7Fb91ED795c90C5',
            'decimals': 4,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x4016489b230980fd43511e28e7fb91ed795c90c5/logo.png',
            'coingeckoId': 'karma-dao',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PQBERT',
            'name': 'pQBERT',
            'address': '0x40038C83E459937A988b669f1159cC78d8fdad68',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/19223/thumb/pqbert.jpeg?1634712069',
            'coingeckoId': 'pqbert',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EMT',
            'name': 'Emanate',
            'address': '0x3fB256CFefedb6A54De7465c0EE86dC574AE464D',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/4152/thumb/emanate.png?1558422824',
            'coingeckoId': 'emanate',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'DTX',
            'name': 'Data Exchange Token',
            'address': '0x3f717919deF69f81d17b80839bf8af35697ccbFa',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x3f717919deF69f81d17b80839bf8af35697ccbFa/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'GAMER',
            'name': 'GameStation',
            'address': '0x3f6b3595ecF70735D3f48D69b09C4E4506DB3F47',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/19584/small/game_station.PNG?1635471603',
            'coingeckoId': 'gamestation',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'GENX',
            'name': 'GENX',
            'address': '0x3eCdeB8fC5023839B92b0c293D049D61069e02b1',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/16747/thumb/logo-pic.png?1624873606',
            'coingeckoId': 'genx',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'AUMI',
            'name': 'AutoMatic Network',
            'address': '0x3eB177A6693eC81d1E170136f8AD02fffBE172a7',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1635228244417_8097348181280004.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'BMI',
            'name': 'Bridge Mutual',
            'address': '0x3e1b4Ff4AE3Ab8f0Cb40a34a6ad3fC817F7dA2b6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://get.celer.app/cbridge-icons/BMI.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'polyFXS',
            'name': 'Poly Frax Share',
            'address': '0x3e121107F6F22DA4911079845a470757aF4e1A1b',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x3e121107F6F22DA4911079845a470757aF4e1A1b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'IRIS',
            'name': 'IRISnet',
            'address': '0x3dc6052a693E4a2fc28Eb2Ea12fe0CfD3BD221D1',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/5135/small/IRIS.png?1557999365',
            'coingeckoId': null,
            'listedIn': [
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'MTAx',
            'name': 'Superfluid MTA',
            'address': '0x3d9CC088bD9357E5941b68d26d6D09254A69949d',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0xf501dd45a1198c2e1b5aef5314a68b9006d842e0/bce0f1b12aa06a7ca9ddde3b75eec2a9.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': '20WETH-80BAL',
            'name': '20WETH 80BAL',
            'address': '0x3d468AB2329F296e1b9d8476Bb54Dd77D8c2320f',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/28005/thumb/0x3d468ab2329f296e1b9d8476bb54dd77d8c2320f.png?1666937655',
            'coingeckoId': '20weth-80bal',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHML',
            'name': 'CheerMedal',
            'address': '0x3c711f725272B05336b44C5c9dcCb9FE2FF42Ef4',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x3c711f725272b05336b44c5c9dccb9fe2ff42ef4/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'GLMS',
            'name': 'Glimpse',
            'address': '0x3c6DAd0475d3a1696B359dc04C99FD401BE134dA',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DAO1',
            'name': 'DAO1',
            'address': '0x3c5D1617C30BA71972adD4b0C9A6B9848f2afeeD',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/16193/thumb/dao1.PNG?1623276970',
            'coingeckoId': 'dao1',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KRIDA',
            'name': 'Krida Fans',
            'address': '0x3c5A5885F6EE4aCC2597069FE3C19F49c6EfBA96',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://www.dropbox.com/s/m4smz3c7036gipr/Krida_token_logo.png',
            'coingeckoId': 'krida-fans',
            'listedIn': [
                'coingecko',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'UFI',
            'name': 'PureFi',
            'address': '0x3c205C8B3e02421Da82064646788c82f7bd753B9',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17341/thumb/purefi.PNG?1627362147',
            'coingeckoId': 'purefi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '8PAY',
            'name': '8PAY Network',
            'address': '0x3bd9856BF578910b55261d45d9148d61C177B092',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x3bd9856bf578910b55261d45d9148d61c177b092/cad33d92fa5197b4dc404b7594f7693a.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MATICx',
            'name': 'Super MATIC',
            'address': '0x3aD736904E9e65189c3000c7DD2c8AC8bB7cD4e3',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270/f6e604ba0324726a3d687c618aa4f163.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DATA',
            'name': 'Streamr',
            'address': '0x3a9A81d576d83FF21f26f325066054540720fC34',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x3a9a81d576d83ff21f26f325066054540720fc34.png',
            'coingeckoId': 'streamr',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ULE',
            'name': 'YouLiveEveryday',
            'address': '0x3a549866a592C81719F3b714a356A8879E20F5d0',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26951/thumb/20220819_225616_0000.png?1660987632',
            'coingeckoId': 'youliveeveryday',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FISH',
            'name': 'Polycat Finance',
            'address': '0x3a3Df212b7AA91Aa0402B9035b098891d276572B',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x3a3df212b7aa91aa0402b9035b098891d276572b.png',
            'coingeckoId': 'polycat-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'quickswap',
                'dfyn',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DARK',
            'name': 'DarkToken',
            'address': '0x3F99009295dEDd768f7284AFE07cDfED70c3E3AC',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x3f99009295dedd768f7284afe07cdfed70c3e3ac/logo.png',
            'coingeckoId': 'darkbuild',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PRAY',
            'name': 'PRAY TOKEN',
            'address': '0x3F4203223440348344d730FBBA18797F55dCA628',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26521/thumb/d0RD3_4W_400x400.png?1658455932',
            'coingeckoId': 'pray',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARCADIUM',
            'name': 'Arcadium',
            'address': '0x3F374ed3C8e61A0d250f275609be2219005c021e',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17717/thumb/4dyLxHe.png?1629085958',
            'coingeckoId': 'arcadium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DoTx',
            'name': 'DeFi Of Thrones (PoS)',
            'address': '0x3Ef402Af15EEB3c80b4441E60913f31a5C41E077',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x3ef402af15eeb3c80b4441e60913f31a5c41e077/logo.png',
            'coingeckoId': 'deli-of-thrones',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'StIbAlluoBTC',
            'name': 'Superfluid IbAlluoBTC',
            'address': '0x3E70E15c189e1FFe8FF44d713605528dC1701b63',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'XDO',
            'name': 'xDollar',
            'address': '0x3Dc7B06dD0B1f08ef9AcBbD2564f8605b4868EEA',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://app.fluidy.fi/logo-xdo_origin.png',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'APP',
            'name': 'DAPPSY',
            'address': '0x3D8E0bb811C45845687BAa32B6e092238C919ed9',
            'decimals': 8,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'oKSP',
            'name': 'Orbit Bridge Polygon KlaySwap Protocol',
            'address': '0x3D3B92Fe0B4c26b74F8fF13A32dD764F4DFD8b51',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x3d3b92fe0b4c26b74f8ff13a32dd764f4dfd8b51.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'DERI',
            'name': 'Deri Protocol',
            'address': '0x3D1D2aFd191b165D140e3E8329E634665fFB0E5e',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x3D1D2aFd191b165D140e3E8329E634665fFB0E5e/logo.png',
            'coingeckoId': 'deri-protocol',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BAT',
            'name': 'Basic Attention',
            'address': '0x3Cef98bb43d732E2F285eE605a8158cDE967D219',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/bat.jpg',
            'coingeckoId': 'basic-attention-token',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'mooQiMai3Pool',
            'name': 'Moo QiDao MAI+3Pool',
            'address': '0x3CB928f9B49D9bD6eF43B7310dcC17dB0528CCc6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'UCO',
            'name': 'Archethic',
            'address': '0x3C720206bFaCB2d16fA3ac0ed87D2048Dbc401Fc',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/12330/small/e353ZVj.png?1599112996',
            'coingeckoId': 'archethic',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'WSG',
            'name': 'Wall Street Games',
            'address': '0x3C1BB39bb696B443a1D80BB2b3a3d950Ba9DEE87',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://i.imgur.com/BDibHLm.png',
            'coingeckoId': 'wall-street-games',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'MATPAD',
            'name': 'MATICPAD',
            'address': '0x3BfcE6D6F0d3D3f1326d86abdbe2845b4740Dc2E',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://maticpad.com/images/MaticPadLogoicon32x32.png',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'FYN',
            'name': 'Affyn',
            'address': '0x3B56a704C01D650147ADE2b8cEE594066b3F9421',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x3b56a704c01d650147ade2b8cee594066b3f9421.png',
            'coingeckoId': 'affyn',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'UM',
            'name': 'Continuum World',
            'address': '0x3B1A0c9252ee7403093fF55b4a5886d49a3d837a',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x3b1a0c9252ee7403093ff55b4a5886d49a3d837a.png',
            'coingeckoId': 'continuum-world',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ETH2X-FLI-P',
            'name': 'Index Coop   ETH 2x Flexible Leverage I',
            'address': '0x3Ad707dA309f3845cd602059901E39C4dcd66473',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x3ad707da309f3845cd602059901e39c4dcd66473.png',
            'coingeckoId': 'index-coop-eth-2x-flexible-leverage-index',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'STMATIC',
            'name': 'Lido Staked Matic',
            'address': '0x3A58a54C066FdC0f2D55FC9C89F0415C92eBf3C4',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x3a58a54c066fdc0f2d55fc9c89f0415c92ebf3c4.png',
            'coingeckoId': 'lido-staked-matic',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'CARD',
            'name': 'Cardstack (PoS)',
            'address': '0x3A4BeD49Ab592A227bAe8F41eFd0d38E6e06d96c',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x3a4bed49ab592a227bae8f41efd0d38e6e06d96c/logo.png',
            'coingeckoId': 'cardstack',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'XUSD',
            'name': 'xDollar Stablecoin',
            'address': '0x3A3e7650f8B9f667dA98F236010fBf44Ee4B2975',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x3a3e7650f8b9f667da98f236010fbf44ee4b2975.png',
            'coingeckoId': 'xdollar-stablecoin',
            'listedIn': [
                'coingecko',
                '1inch',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'KAMPAY',
            'name': 'Kampay',
            'address': '0x39fC9e94Caeacb435842FADeDeCB783589F50f5f',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/8289/thumb/6130e86152bdb07e2848de00_200_4x.png?1630738336',
            'coingeckoId': 'kampay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DYST',
            'name': 'Dystopia',
            'address': '0x39aB6574c289c3Ae4d88500eEc792AB5B947A5Eb',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x39ab6574c289c3ae4d88500eec792ab5b947a5eb.png',
            'coingeckoId': 'dystopia',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'CK',
            'name': 'CK Finance',
            'address': '0x3984faC1Bd61fc62c5D5797205dd1679Da6437F0',
            'decimals': 9,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'EQ9',
            'name': 'Equals9',
            'address': '0x3963a400b42377376d6c3d92Ddf2d6288D8EE0d6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/28233/thumb/1648392274941.jpeg?1668574545',
            'coingeckoId': 'eq9',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MNTL',
            'name': 'AssetMantle',
            'address': '0x38A536A31bA4d8C1Bcca016AbBf786ecD25877E8',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25181/small/thumbnail.png?1658821784',
            'coingeckoId': null,
            'listedIn': [
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'JM',
            'name': 'JustMoney',
            'address': '0x388D819724dD6d71760A38F00dc01D310d879771',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25450/thumb/jm.png?1651803017',
            'coingeckoId': 'justmoney-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SUNNYx',
            'name': 'Superfluid SUNNY',
            'address': '0x3862c15CDC4C38517Aa4C6F94197A93253502168',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'GHST',
            'name': 'Aavegotchi',
            'address': '0x385Eeac5cB85A38A9a07A70c73e0a3271CfB54A7',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x3f382dbd960e3a9bbceae22651e88158d2791550.png',
            'coingeckoId': 'aavegotchi',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'quickswap',
                'dfyn',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'mRTK',
            'name': 'Matic Ruletka',
            'address': '0x38332D8671961aE13d0BDe040d536eB336495eEA',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/11102/small/rtk-logo.png',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'VEST',
            'name': 'DAO Invest',
            'address': '0x381caf412B45dAc0f62FBEeC89DE306D3eABE384',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17901/thumb/logo-round-200.png?1629773511',
            'coingeckoId': 'dao-invest',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWAP',
            'name': 'Trustswap',
            'address': '0x3809dcDd5dDe24B37AbE64A5a339784c3323c44F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x3809dcDd5dDe24B37AbE64A5a339784c3323c44F/logo.png',
            'coingeckoId': 'trustswap',
            'listedIn': [
                'coingecko',
                'quickswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GLTR',
            'name': 'GAX Liquidity Token Reward',
            'address': '0x3801C3B3B5c98F88a9c9005966AA96aa440B9Afc',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://i.ibb.co/qMMwsK7/gltr-token.png',
            'coingeckoId': 'gax-liquidity-token-reward',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'HANZO',
            'name': 'Hanzo',
            'address': '0x37eB60F78e06c4BB2A5F836B0Fc6BCcBbaA995b3',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15486/small/hanzo.png?1635830733',
            'coingeckoId': 'hanzo-inu',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'WTF',
            'name': 'Waterfall Governance Token',
            'address': '0x37EE376553833D9247d38EeE826b696bD3aE1B1f',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'pCOMB',
            'name': 'Polygon Native Comb',
            'address': '0x37D1EbC3Af809b8fADB45DCE7077eFc629b2B5BB',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x37D1EbC3Af809b8fADB45DCE7077eFc629b2B5BB/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ORBIT',
            'name': 'Orbit',
            'address': '0x372d044DD3391ab6B1eFFDE76D2579Cf77A56F26',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25807/thumb/apple-touch-icon.png?1653978963',
            'coingeckoId': 'orbit-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EUROS',
            'name': 'SpiceEURO',
            'address': '0x37129b96896891e56bc099540fe7D1841005A367',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26443/thumb/E4B6C93A-E2A1-4F53-BB26-15D0FE802812.png?1658110215',
            'coingeckoId': 'spiceeuro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAD',
            'name': 'BAD Token',
            'address': '0x36dE20a30386b5Bf99762e4B7e62f6A28DceA3Ae',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x36de20a30386b5bf99762e4b7e62f6a28dcea3ae/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'HBN',
            'name': 'Hobonickels',
            'address': '0x36FF415d0BcEdF976d60E85c72E01D19230FBd29',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/59/thumb/hobonickels.png?1547033710',
            'coingeckoId': 'hobonickels',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SDT',
            'name': 'Stake DAO',
            'address': '0x361A5a4993493cE00f61C32d4EcCA5512b82CE90',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x73968b9a57c6e53d41345fd57a6e6ae27d6cdb2f.png',
            'coingeckoId': 'stake-dao',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SPIN',
            'name': 'Spintop',
            'address': '0x35f80a39eeFe33D0dfD2aD2daa6aD6A9D472CEbd',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x35f80a39eefe33d0dfd2ad2daa6ad6a9d472cebd/2a4f96b097717c6e9b5e86e5775c4ec9.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'COR',
            'name': 'CorgiToken',
            'address': '0x3581a7B7BE2eD2EDf98710910fD05b0e8545f1DB',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'USDTSO',
            'name': 'Tether USD  Wormhole ',
            'address': '0x3553f861dEc0257baDA9F8Ed268bf0D74e45E89C',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/22881/thumb/USDTso_wh_small.png?1644223113',
            'coingeckoId': 'tether-usd-wormhole',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KANGAL',
            'name': 'Kangal',
            'address': '0x34f380a4e3389e99C0369264453523Bbe5aF7faB',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14241/thumb/logo-200.png?1622341641',
            'coingeckoId': 'kangal',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'AURUM',
            'name': 'Raider Aurum',
            'address': '0x34d4ab47Bee066F361fA52d792e69AC7bD05ee23',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x34d4ab47bee066f361fa52d792e69ac7bd05ee23.png',
            'coingeckoId': 'raider-aurum',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'EZ',
            'name': 'EasyFi V2',
            'address': '0x34C1b299A74588D6Abdc1b85A53345A48428a521',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://etherscan.io/token/images/easyfi_32.png',
            'coingeckoId': 'easyfi',
            'listedIn': [
                'coingecko',
                'quickswap',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'PYR',
            'name': 'PYR Token',
            'address': '0x348e62131fce2F4e0d5ead3Fe1719Bc039B380A9',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x348e62131fce2f4e0d5ead3fe1719bc039b380a9/logo.png',
            'coingeckoId': 'vulcan-forged',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'GENE',
            'name': 'GenomesDAO',
            'address': '0x34667ED7C36cBBbF2d5d5c5c8d6Eb76a094EDb9F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x34667ed7c36cbbbf2d5d5c5c8d6eb76a094edb9f.png',
            'coingeckoId': 'genomesdao',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'IUX',
            'name': 'GeniuX',
            'address': '0x346404079b3792a6c548B072B9C4DDdFb92948d5',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26078/small/black_IUX-coin-logo2.png?1661767150',
            'coingeckoId': 'geniux',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'JDI',
            'name': 'JDI',
            'address': '0x340fE1D898ECCAad394e2ba0fC1F93d27c7b717A',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15220/thumb/JDI.png?1620119566',
            'coingeckoId': 'jdi-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IMATIC-FLI-P',
            'name': 'Index Coop   Inverse MATIC Flexible Lev',
            'address': '0x340f412860dA7b7823df372a2b59Ff78b7ae6abc',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/23424/thumb/iMATIC-FLI-P-512_%28pulse_red%29.png?1644203917',
            'coingeckoId': 'index-coop-inverse-matic-flexible-leverage-index',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTD',
            'name': 'BatTrueDollar',
            'address': '0x3405A1bd46B85c5C029483FbECf2F3E611026e45',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CFOOD',
            'name': 'RatAlert Casual FOOD',
            'address': '0x33CC3b1852939Ef8CFd77BB5c3707cF2D3E72490',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25955/thumb/cfood.png?1654853782',
            'coingeckoId': 'ratalert-casual-food',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NTTC',
            'name': 'Navigator DAO',
            'address': '0x337Bbd8e004C635d4B09f3e783742F2A8DC896e3',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/19576/thumb/nttc.PNG?1635468113',
            'coingeckoId': 'navigator',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AXS',
            'name': 'Axie Infinity Shard (PoS)',
            'address': '0x334D7Ae7F1D21CeB74537391558Ce57bbF3cCf73',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x334d7ae7f1d21ceb74537391558ce57bbf3ccf73/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'REQx',
            'name': 'Superfluid REQ',
            'address': '0x32cefdF2b3df73BDeBaA7cD3B0135B3A79d28Dcc',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0xb25e20de2f2ebb4cffd4d16a55c7b395e8a94762/332d9168c95953276e7db6d4b2032c15.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PS1',
            'name': 'POLYSPORTS',
            'address': '0x32Cd1BCB75473845b5d1dB6ecE60AEC6E41d8518',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25070/small/L-T2x_cG_400x400.jpg?1650024620',
            'coingeckoId': 'polysports',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'GAMMA',
            'name': 'PolyGamma Finance',
            'address': '0x329F5e8Aff351327E63ACdB264389c798a46c2D3',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/19590/thumb/gamma.png?1635473502',
            'coingeckoId': 'polygamma',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SNE',
            'name': 'StrongNode',
            'address': '0x32934CB16DA43fd661116468c1B225Fc26CF9A8c',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/12258.png',
            'coingeckoId': 'strongnode',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BAKED',
            'name': 'Baked',
            'address': '0x32515ffdc3a84cfbf9AD4dB14EF8f0A535c7Afd6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/19178/thumb/rebaked-logo-full.png?1634613132',
            'coingeckoId': 'baked-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KNC',
            'name': 'Kyber Network Crystal',
            'address': '0x324b28d6565f784d596422B0F2E5aB6e9CFA1Dc7',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdd974D5C2e2928deA5F71b9825b8b646686BD200/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'uniswap'
            ]
        },
        {
            'symbol': 'MNFT',
            'name': 'MongolNFT',
            'address': '0x3235B13708F178Af6F110dE7177ED5De10c1093d',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'RENZEC',
            'name': 'renZEC',
            'address': '0x31a0D1A199631D244761EEba67e8501296d2E383',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/11564/thumb/Zcash.jpg?1628072865',
            'coingeckoId': 'renzec',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEC',
            'name': 'Decentr  (PoS)',
            'address': '0x314338767151e6Ed23DB8A41c6F6943c1958Ee56',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x314338767151e6ed23db8a41c6f6943c1958ee56/logo.png',
            'coingeckoId': 'decentr',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'LEND',
            'name': 'EthLend Token',
            'address': '0x313d009888329C9d1cf4f75CA3f32566335bd604',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x313d009888329C9d1cf4f75CA3f32566335bd604/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NAKA',
            'name': 'Nakamoto Games',
            'address': '0x311434160D7537be358930def317AfB606C0D737',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/19073/thumb/flCKDeh6_400x400.jpg?1634280509',
            'coingeckoId': 'nakamoto-games',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MITX',
            'name': 'Morpheus Labs',
            'address': '0x31042A4E66eDa0d12143ffc8cC1552D611dA4cbA',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/3164/thumb/mitx.png?1604888269',
            'coingeckoId': 'morpheus-labs',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POLYPAD',
            'name': 'PolyPad',
            'address': '0x30eA765D4DDA26e0f89e1B23A7c7b2526B7d29ec',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24905/thumb/BXApUK87_400x400.png?1649335541',
            'coingeckoId': 'polypad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XZAR',
            'name': 'South African Tether (PoS)',
            'address': '0x30DE46509Dbc3a491128F97be0aAf70dc7Ff33cB',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x30de46509dbc3a491128f97be0aaf70dc7ff33cb.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'bat',
            'name': 'bat token (PoS)',
            'address': '0x30A8e1c256143AD440faa9042722929B0BC0Fc7D',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x30a8e1c256143ad440faa9042722929b0bc0fc7d/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'GTFX',
            'name': 'Gitshock Finance',
            'address': '0x306e974624511E3937F37E551C5736F1b2aD21eb',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/28033/thumb/Logo_Gitshock_HD.png?1667117754',
            'coingeckoId': 'gitshock-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PDSHARE',
            'name': 'PDSHARE',
            'address': '0x3068382885602FC0089aeC774944b5ad6123ae60',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x3068382885602FC0089aeC774944b5ad6123ae60/logo.png',
            'coingeckoId': 'pdshare',
            'listedIn': [
                'coingecko',
                'elkfinance'
            ]
        },
        {
            'symbol': 'UMA',
            'name': 'UMA Voting Token v1',
            'address': '0x3066818837c5e6eD6601bd5a91B0762877A6B731',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x3066818837c5e6eD6601bd5a91B0762877A6B731/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'uniswap',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'WAULTX',
            'name': 'WAULTx',
            'address': '0x3053ad3b31600074e9A90440770f78D5e8Fc5A54',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x3053ad3b31600074e9A90440770f78D5e8Fc5A54/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ECG',
            'name': 'ECS Gold',
            'address': '0x3050731a7Ab18D05eAa5E01D66DF33E04444e72c',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27542/thumb/ECOSMART_LOGO_200X200.png?1664432758',
            'coingeckoId': 'ecs-gold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'agEURx',
            'name': 'Superfluid agEUR',
            'address': '0x3038B359240DFF5CCd42DfFd21f12b428034bE38',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0xe0b52e49357fd4daf2c15e02058dce6bc0057db4/950fda44a9f4598d2a7a6e9df24b7332.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WTPOKT',
            'name': 'Wrapped ThunderPOKT',
            'address': '0x301595f6fd5f69faD7a488DaCB8971e7c0C2f559',
            'decimals': 12,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24835/thumb/vjCQH6sw.jpg?1649060228',
            'coingeckoId': 'wrapped-thunderpokt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CIOTX',
            'name': 'Crosschain IOTX',
            'address': '0x300211Def2a644b036A9bdd3e58159bb2074d388',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x300211def2a644b036a9bdd3e58159bb2074d388.png',
            'coingeckoId': 'crosschain-iotx',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'XED',
            'name': 'Exeedme',
            'address': '0x2fe8733dcb25BFbbA79292294347415417510067',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://i.imgur.com/Kte7hVj.jpg',
            'coingeckoId': 'exeedme',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SPHRI',
            'name': 'Spherium',
            'address': '0x2fD4D793c1905D82018d75e3b09d3035856890a1',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17787/thumb/Group_15.png?1629252159',
            'coingeckoId': 'spherium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SCLP',
            'name': 'ScallopX (PoS)',
            'address': '0x2fC711518aaE7C87D7002566c5d43B0e5D2B1932',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'USDS',
            'name': 'SpiceUSD',
            'address': '0x2f1b1662A895C6Ba01a99DcAf56778E7d77e5609',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25697/thumb/USDS.png?1653440948',
            'coingeckoId': 'spiceusd',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'DAF',
            'name': 'Diamonds Are Forever',
            'address': '0x2f0e07E881363Bb1cdFF32971B2f8C87EF8FF432',
            'decimals': 6,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SAGE',
            'name': 'Polysage',
            'address': '0x2ed945Dc703D85c80225d95ABDe41cdeE14e1992',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18967/thumb/polysage.PNG?1634027493',
            'coingeckoId': 'polysage',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TXPT',
            'name': 'tPLATINUM',
            'address': '0x2e6978ceeA865948f4c5685e35AEC72652e3Cb88',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27830/thumb/tPLATINUM_token_2D.jpg?1666165908',
            'coingeckoId': 'tplatinum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UMBR',
            'name': 'Umbria Network',
            'address': '0x2e4b0Fb46a46C90CB410FE676f24E466753B469f',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14712/thumb/RX7VJg6.png?1617891954',
            'coingeckoId': 'umbra-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHUM',
            'name': 'ChumHum',
            'address': '0x2e2DDe47952b9c7deFDE7424d00dD2341AD927Ca',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x2e2DDe47952b9c7deFDE7424d00dD2341AD927Ca/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'TUSD',
            'name': 'TrueUSD',
            'address': '0x2e1AD108fF1D8C782fcBbB89AAd783aC49586756',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x2e1ad108ff1d8c782fcbbb89aad783ac49586756.png',
            'coingeckoId': 'true-usd',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'quickswap',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'DBEATx',
            'name': 'Superfluid DBEAT',
            'address': '0x2e12D38C6aa87cb68cE96C044b9A68dD98233Ceb',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'IMRTL',
            'name': 'Immortl',
            'address': '0x2df54842cD85C60f21b4871E09bcc6047b2dcC4d',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/21983/thumb/ONE.png?1640575713',
            'coingeckoId': 'immortl',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MOT',
            'name': 'Mobius Finance',
            'address': '0x2db0Db271a10661e7090b6758350E18F6798a49D',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18144/thumb/11322.png?1630668459',
            'coingeckoId': 'mobius-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POLP',
            'name': 'PolkaParty',
            'address': '0x2d72A97a31Dc920dB03330780d30074626e39C8A',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17867/thumb/polp_logo.jpg?1641355239',
            'coingeckoId': 'polkaparty',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'FLYP',
            'name': 'FLYPE DAO',
            'address': '0x2cC58c81cFD3958e268bC68d3DDa08Fd4E17977C',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27283/thumb/FLYPE_LOGO_200X200.jpg?1663140374',
            'coingeckoId': 'flype-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STZ',
            'name': '99Starz',
            'address': '0x2c92a8A41f4b806a6f6F1F7C9D9DEc78DCd8c18e',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/21467/small/n1fqEYRI_400x400.png?1639345175',
            'coingeckoId': '99starz',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'XIDR',
            'name': 'XIDR',
            'address': '0x2c826035c1C36986117A0e949bD6ad4baB54afE2',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x2c826035c1c36986117a0e949bd6ad4bab54afe2.png',
            'coingeckoId': 'straitsx-indonesia-rupiah',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'TREEB',
            'name': 'Treeb',
            'address': '0x2c69095d81305F1e3c6ed372336D407231624CEa',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MKRx',
            'name': 'Superfluid MKR',
            'address': '0x2c530aF1f088B836FA0dCa23c7Ea50E669508C4C',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/list/master/logos/token-logos/token/mkr.jpg',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FSN',
            'name': 'FUSION',
            'address': '0x2bF9b864cdc97b08B6D79ad4663e71B8aB65c45c',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x2bF9b864cdc97b08B6D79ad4663e71B8aB65c45c/logo.png',
            'coingeckoId': 'fsn',
            'listedIn': [
                'coingecko',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'TOWER',
            'name': 'Tower',
            'address': '0x2bC07124D8dAc638E290f401046Ad584546BC47b',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x2bc07124d8dac638e290f401046ad584546bc47b.png',
            'coingeckoId': 'tower',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'PICKLE',
            'name': 'Pickle Finance',
            'address': '0x2b88aD57897A8b496595925F43048301C37615Da',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x429881672b9ae42b8eba0e26cd9c73711b891ca5.png',
            'coingeckoId': 'pickle-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'Mg',
            'name': 'Magnesium',
            'address': '0x2b70b0dDb96E2581a311dEB25A1424C7167E4A53',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/Mg.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FIBO',
            'name': 'FibSwap DEX',
            'address': '0x2b3B16826719bF0B494c8ddebaA5E882093eE37e',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17306/thumb/coingeckofile.png?1655195281',
            'coingeckoId': 'fibo-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRMT',
            'name': 'Tierra Meta',
            'address': '0x2ad20CDC0e722AaC2772CB02d46A01a858899358',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25127/thumb/tierrameta_logo-8.png?1650372911',
            'coingeckoId': 'tierra-meta',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DG',
            'name': 'Decentral Games  Old ',
            'address': '0x2a93172c8DCCbfBC60a39d56183B7279a2F647b4',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x2a93172c8DCCbfBC60a39d56183B7279a2F647b4/logo.png',
            'coingeckoId': 'decentral-games-old',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CIRUS',
            'name': 'Cirus',
            'address': '0x2a82437475A60BebD53e33997636fadE77604fc2',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/aion-ollie/new-host/icon/cirus_logo_256.png',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'BCT',
            'name': 'Toucan Protocol  Base Carbon Tonne',
            'address': '0x2F800Db0fdb5223b3C3f354886d907A671414A7F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x2f800db0fdb5223b3c3f354886d907a671414a7f.png',
            'coingeckoId': 'toucan-protocol-base-carbon-tonne',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'STG',
            'name': 'Stargate Finance',
            'address': '0x2F6F07CDcf3588944Bf4C42aC74ff24bF56e7590',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1648128003267_8819963035591498.png',
            'coingeckoId': 'stargate-finance',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'METF',
            'name': 'Mad Meerkat ETF',
            'address': '0x2F69db9aE23041cC78e3ABf73ee8BB13c14a5f37',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'EAGON',
            'name': 'EagonSwap',
            'address': '0x2F25d402829cA4085B8Ea4D3BC68Bf203F5a9faB',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/20431/thumb/IMG-20210912-WA0003.jpg?1637035180',
            'coingeckoId': 'eagonswap-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOG',
            'name': 'LoganCoin (PoS)',
            'address': '0x2F128A29F2b070a68d92777EBBc0B1E8B1D5Bcd0',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x2f128a29f2b070a68d92777ebbc0b1e8b1d5bcd0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'AAA',
            'name': 'Moon Rabbit',
            'address': '0x2EBD50AE084e71eed419cB6C620B3BbD3927bF7e',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17310/thumb/logo_moon_no_inscriptions-01.png?1627275874',
            'coingeckoId': 'moon-rabbit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MM',
            'name': 'MM Token',
            'address': '0x2E220744f9aC1BF3045B0588D339F5fD3bB8623A',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x2E220744f9aC1BF3045B0588D339F5fD3bB8623A/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'StIbAlluoEth',
            'name': 'Superfluid IbAlluoETH',
            'address': '0x2D4Dc956FBd0044a4EBA945e8bbaf98a14025C2d',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TSHARE',
            'name': 'TSHARE',
            'address': '0x2D1c6a7362E75d88B4Cf3b66b83052775846E2b8',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BTL',
            'name': 'Betbeetle (PoS)',
            'address': '0x2D0b6b0C7468C7cFb716e9Cf0ff24F45A239a3A0',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x2d0b6b0c7468c7cfb716e9cf0ff24f45a239a3a0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'RAYS',
            'name': 'RAYS',
            'address': '0x2D027E55B85429e9F205930A8afF6D8E6C8c3021',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26609/thumb/mch_rays_200.png?1659015796',
            'coingeckoId': 'rays',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RVF',
            'name': 'RocketX exchange',
            'address': '0x2CE13E4199443FDfFF531ABb30c9B6594446bbC7',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14728/small/7.png?1618414105',
            'coingeckoId': 'rocketx',
            'listedIn': [
                'coingecko',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'AVAX',
            'name': 'Avalanche',
            'address': '0x2C89bbc92BD86F8075d1DEcc58C7F4E0107f286b',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x2c89bbc92bd86f8075d1decc58c7f4e0107f286b.png',
            'coingeckoId': 'avalanche-2',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'MASK',
            'name': 'Mask Network',
            'address': '0x2B9E7ccDF0F4e5B24757c1E1a80e311E34Cb10c7',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x2b9e7ccdf0f4e5b24757c1e1a80e311e34cb10c7.png',
            'coingeckoId': 'mask-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'UBO',
            'name': 'Universal Basic Offset',
            'address': '0x2B3eCb0991AF0498ECE9135bcD04013d7993110c',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/28075/thumb/C3_UBO_Gradient-01.png?1667393410',
            'coingeckoId': 'universal-basic-offset',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'CGG',
            'name': 'Chain Guardians',
            'address': '0x2Ab4f9aC80F33071211729e45Cfc346C1f8446d5',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x2ab4f9ac80f33071211729e45cfc346c1f8446d5.png',
            'coingeckoId': 'chain-guardians',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'XEN',
            'name': 'XEN Crypto',
            'address': '0x2AB0e9e4eE70FFf1fB9D67031E44F6410170d00e',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27713/thumb/Xen.jpeg?1665453190',
            'coingeckoId': 'xen-crypto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRD',
            'name': 'The Realm Defenders',
            'address': '0x2A1f0aD3Cab040e28845734d932f3fF0A24B14F4',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/20734/thumb/g5IogLIJ_400x400.jpg?1637617954',
            'coingeckoId': 'the-realm-defenders',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AES',
            'name': 'Aree Shards',
            'address': '0x2A07461a493b994C2a32f549Fd185524f306aB38',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27231/thumb/AES_TICKER_200.png?1662706815',
            'coingeckoId': 'aree-shards',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VSQ',
            'name': 'VESQ',
            'address': '0x29F1e986FCa02B7E54138c04C4F503DdDD250558',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x29f1e986fca02b7e54138c04c4f503dddd250558.png',
            'coingeckoId': 'vesq',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'TNDR',
            'name': 'Thunder Lands',
            'address': '0x29E3E6AD4EEAdF767919099Ee23c907946435a70',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26488/thumb/logo_v3.png?1658283295',
            'coingeckoId': 'thunder-lands',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GPAY',
            'name': 'GemPay',
            'address': '0x29C28f7E50ea89343fE2B2c75f2652b883eD0Cbd',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/20766/thumb/200x200_%281%29.jpg?1637654513',
            'coingeckoId': 'gempay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BCM',
            'name': 'Blockchain Mind (PoS)',
            'address': '0x29B9c200B27683feA3512911DAFAf0AfD60dA4E3',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x29b9c200b27683fea3512911dafaf0afd60da4e3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'THX',
            'name': 'THX Network',
            'address': '0x2934b36ca9A4B31E633C5BE670C8C8b28b6aA015',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x2934b36ca9a4b31e633c5be670c8c8b28b6aa015.png',
            'coingeckoId': 'thx-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'wsSPA',
            'name': 'Wrapped sSPA',
            'address': '0x28f5FB63482980f182a38D2D109A0bf3C9176bf2',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ERP',
            'name': 'Entropyfi',
            'address': '0x28accA4ed2F6186c3D93e20e29e6e6a9Af656341',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/19633/small/erp.PNG?1635715161',
            'coingeckoId': 'entropyfi',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'JUICE',
            'name': 'JUICE (PoS)',
            'address': '0x28E96FFe75CdCc97044585b866BD02bD79C12dc0',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x28e96ffe75cdcc97044585b866bd02bd79c12dc0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BEL',
            'name': 'Bella',
            'address': '0x28C388FB1F4fa9F9eB445f0579666849EE5eeb42',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x28c388fb1f4fa9f9eb445f0579666849ee5eeb42/ce1950cb7bf0cb48a226d5f96d596c6c.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AMWETH',
            'name': 'Aave Polygon WETH',
            'address': '0x28424507fefb6f7f8E9D3860F56504E4e5f5f390',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x030ba81f1c18d280636f32af80b9aad02cf0854e.png',
            'coingeckoId': 'aave-polygon-weth',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'mOCEAN',
            'name': 'Ocean Token',
            'address': '0x282d8efCe846A88B159800bd4130ad77443Fa1A1',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x967da4048cd07ab37855c090aaf366e4ce1b9f48.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'quickswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FLUX',
            'name': 'FLUX (PoS)',
            'address': '0x2808edb7398b25E0f6C41cD98fD114d924008C87',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x2808edb7398b25e0f6c41cd98fd114d924008c87/logo.png',
            'coingeckoId': 'flux',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BITE',
            'name': 'DragonBite',
            'address': '0x280724409b288dE06C6D66c05965D3d456e2283a',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/16386/thumb/dragonbite.PNG?1623893567',
            'coingeckoId': 'dragonbite',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETHx',
            'name': 'Superfluid WETH',
            'address': '0x27e1e4E6BC79D93032abef01025811B7E4727e85',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x7ceb23fd6bc0add59e62ac25578270cff1b9f619/61844453e63cf81301f845d7864236f6.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'OPEN',
            'name': 'Openworld',
            'address': '0x27a339d9B59b21390d7209b78a839868E319301B',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AMDAI',
            'name': 'Aave Polygon DAI',
            'address': '0x27F8D03b3a2196956ED754baDc28D73be8830A6e',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x028171bca77440897b824ca71d1c56cac55b68a3.png',
            'coingeckoId': 'aave-polygon-dai',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USD Coin',
            'address': '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png',
            'coingeckoId': 'usd-coin',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'quickswap',
                'dfyn',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MVX',
            'name': 'Metavault Trade',
            'address': '0x2760E46d9BB43dafCbEcaad1F64b93207f9f0eD7',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25402/thumb/mvx.png?1660380728',
            'coingeckoId': 'metavault-trade',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CTSI',
            'name': 'Cartesi',
            'address': '0x2727Ab1c2D22170ABc9b595177B2D5C6E1Ab7B7B',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x2727Ab1c2D22170ABc9b595177B2D5C6E1Ab7B7B/logo.png',
            'coingeckoId': 'cartesi',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FFOOD',
            'name': 'RatAlert FastFood',
            'address': '0x2721d859EE8d03599F628522d30f14d516502944',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25690/thumb/ffood.png?1653375324',
            'coingeckoId': 'ratalert-fastfood',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFU',
            'name': 'polySAFU',
            'address': '0x26f6Cb841F9D4D72b68D7dCb6fDB5d6C832dD2A7',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/16074/thumb/polysafu.PNG?1622770619',
            'coingeckoId': 'polysafu',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'GTPS',
            'name': 'Gtps.Finance',
            'address': '0x26d326B1fc702260baeB62334d7c1Da6f1a2C386',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x26d326B1fc702260baeB62334d7c1Da6f1a2C386/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'COIN',
            'name': 'coin_artist (PoS)',
            'address': '0x26F10028C105501678531A19Fe025b93Ae055a9f',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x26f10028c105501678531a19fe025b93ae055a9f/logo.png',
            'coingeckoId': 'coin-artist',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'JULIEN',
            'name': 'Julien (PoS)',
            'address': '0x268Ad27C28601D28b79c792C14e95BD2b7A030F8',
            'decimals': 4,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x268Ad27C28601D28b79c792C14e95BD2b7A030F8/logo.png',
            'coingeckoId': 'julien',
            'listedIn': [
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SPACE',
            'name': 'The Space',
            'address': '0x264808855b0a6A5a318D238C6ee9F299179f27FC',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25929/thumb/The_Space_Logo.png?1654694545',
            'coingeckoId': 'the-space',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OJA',
            'name': 'Ojamu',
            'address': '0x26373EC913876C9e6d38494dde458Cb8649cb30c',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18947/thumb/ojamu-icon-PNK.png?1634006741',
            'coingeckoId': 'ojamu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RIC',
            'name': 'Ricochet',
            'address': '0x263026E7e53DBFDce5ae55Ade22493f828922965',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x263026E7e53DBFDce5ae55Ade22493f828922965/logo.png',
            'coingeckoId': 'richochet',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SWAY',
            'name': 'Sway Social',
            'address': '0x262B8AA7542004f023B0eB02bc6b96350A02b728',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18915/thumb/sway.png?1638944259',
            'coingeckoId': 'sway-social',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'bBADGER',
            'name': 'Badger Sett Badger (PoS)',
            'address': '0x2628D301b161DB70E3dBbAc88d9D900cA426fF02',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x2628D301b161DB70E3dBbAc88d9D900cA426fF02/logo.png',
            'coingeckoId': 'badger-sett-badger',
            'listedIn': [
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'renDGB',
            'name': 'renDGB',
            'address': '0x2628568509E87c4429fBb5c664Ed11391BE1BD29',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x2628568509E87c4429fBb5c664Ed11391BE1BD29/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'TAT2',
            'name': 'TattooMoney',
            'address': '0x2596A8B90D39EB89d5668ca5B6deE54E9ae3d4C0',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24772/thumb/logo_200x200.png?1649835408',
            'coingeckoId': 'tattoomoney',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KHIZA',
            'name': 'Khiza DAO',
            'address': '0x25856d46748ee959f99A0FBBB5312C73473cb533',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'POOL',
            'name': 'PoolTogether',
            'address': '0x25788a1a171ec66Da6502f9975a15B609fF54CF6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14003/small/PoolTogether.png?1613585632',
            'coingeckoId': 'pooltogether',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'quickswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'TETU',
            'name': 'TETU',
            'address': '0x255707B70BF90aa112006E1b07B9AeA6De021424',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17882/small/gradient_icon_926.png?1629707907',
            'coingeckoId': 'tetu',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'COC',
            'name': 'Coin of the champions',
            'address': '0x2489eb26B6C3eE7815e024d14aED4DAd71EbeF51',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'LUNC',
            'name': 'Wrapped Terra Classic',
            'address': '0x24834BBEc7E39ef42f4a75EAF8E5B6486d3F0e57',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://etherscan.io/token/images/terra-luna_32.png',
            'coingeckoId': 'wrapped-terra',
            'listedIn': [
                'coingecko',
                'dfyn',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'CRT',
            'name': 'CrypToads (PoS)',
            'address': '0x24792302477Ec0e95dEE67ffD01549774ce55BF5',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x24792302477ec0e95dee67ffd01549774ce55bf5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'EGG',
            'name': 'LoserChick EGG',
            'address': '0x245e5ddb65eFea6522Fa913229dF1f4957fB2e21',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17574/thumb/lRAQ2MOTpWqrHhI.png?1628551880',
            'coingeckoId': 'loserchick-egg',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ANTS',
            'name': 'FireAnts (PoS)',
            'address': '0x24212089B9D90B4D06ef10bD80CA14ec9356D98f',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x24212089b9d90b4d06ef10bd80ca14ec9356d98f/logo.png',
            'coingeckoId': 'fireants',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'TORN',
            'name': 'Tornado Cash',
            'address': '0x23fE1Ee2f536427B7e8aC02FB037A7f867037Fe8',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x23fE1Ee2f536427B7e8aC02FB037A7f867037Fe8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'CDS',
            'name': 'Crypto Development Services',
            'address': '0x23f07a1C03e7C6D0C88e0E05E79B6E3511073fD5',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/21304/thumb/JhUZ3Rk.png?1638883308',
            'coingeckoId': 'crypto-development-services',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'REIN',
            'name': 'Reyna Ventures',
            'address': '0x23c70Dd93d4ecaC7fb93631488C5412E02f4A57c',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x23c70dd93d4ecac7fb93631488c5412e02f4a57c/6a7936ecd748013c5c1e1473bdaf84e5.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WELT',
            'name': 'Fabwelt',
            'address': '0x23E8B6A3f6891254988B84Da3738D2bfe5E703b9',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x23e8b6a3f6891254988b84da3738d2bfe5e703b9.png',
            'coingeckoId': 'fabwelt',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'HEX',
            'name': 'HEX',
            'address': '0x23D29D30e35C5e8D321e1dc9A8a61BFD846D4C5C',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x2b591e99afe9f32eaa6214f7b7629768c40eeb39.png',
            'coingeckoId': 'hex',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'USD+',
            'name': 'USD ',
            'address': '0x236eeC6359fb44CCe8f97E99387aa7F8cd5cdE1f',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x236eec6359fb44cce8f97e99387aa7f8cd5cde1f.png',
            'coingeckoId': 'usd',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'KASTA',
            'name': 'Kasta',
            'address': '0x235737dBb56e8517391473f7c964DB31fA6ef280',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x235737dbb56e8517391473f7c964db31fa6ef280.png',
            'coingeckoId': 'kasta',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ZUZ',
            'name': 'Zeus',
            'address': '0x232eaB56c4fB3f84c6Fb0a50c087c74b7B43c6Ad',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x232eaB56c4fB3f84c6Fb0a50c087c74b7B43c6Ad/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'LUSD',
            'name': 'Liquity USD',
            'address': '0x23001f892c0C82b79303EDC9B9033cD190BB21c7',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14666/thumb/Group_3.png?1617631327',
            'coingeckoId': 'liquity-usd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLAME',
            'name': 'FireStarter',
            'address': '0x22e3f02f86Bc8eA0D73718A2AE8851854e62adc5',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17359/small/Logo_black_%282%29.png?1634095075',
            'coingeckoId': 'firestarter',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'MMF',
            'name': 'MMFinance  Polygon ',
            'address': '0x22a31bD4cB694433B6de19e0aCC2899E553e9481',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26840/small/1.png?1660352178',
            'coingeckoId': 'mmfinance-polygon',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'BPTx',
            'name': 'Superfluid BPT',
            'address': '0x229c5D13452dc302499B5C113768A0db0c9D5c05',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x6863bd30c9e313b264657b107352ba246f8af8e0/8b4ad02c904251c1e2858a65c5c49e74.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BLOK',
            'name': 'Bloktopia',
            'address': '0x229b1b6C23ff8953D663C4cBB519717e323a0a84',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x229b1b6c23ff8953d663c4cbb519717e323a0a84.png',
            'coingeckoId': 'bloktopia',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'NXD',
            'name': 'Nexus Dubai',
            'address': '0x228b5C21ac00155cf62c57bcc704c0dA8187950b',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x228b5c21ac00155cf62c57bcc704c0da8187950b.png',
            'coingeckoId': 'nexus-dubai',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'OBOT',
            'name': 'Obortech',
            'address': '0x221743dc9E954bE4f86844649Bf19B43D6F8366d',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14929/thumb/OBORTECH_200.png?1619070515',
            'coingeckoId': 'obortech',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'LADZ',
            'name': 'LADZ',
            'address': '0x220ED61d1f1CC754cB71978585D69e07Be576315',
            'decimals': 4,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x220ED61d1f1CC754cB71978585D69e07Be576315/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'YORK',
            'name': 'PolyYork',
            'address': '0x21dE43d96CFddd203DA3352545E0054534776652',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17558/thumb/polyyork.PNG?1628228872',
            'coingeckoId': 'polyyork',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ECO',
            'name': 'EcoCREDIT',
            'address': '0x21FDB8e43d84420AfbD6351D645F50c9138DAae0',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/23363/thumb/Eco-CREDIT-Logo.png?1643947272',
            'coingeckoId': 'ecocredit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNIC',
            'name': 'Unicly',
            'address': '0x21CE5251d47AA72d2d1dc849b1Bcce14d2467D1b',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14720/thumb/Unicly.png?1621132059',
            'coingeckoId': 'unicly',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JOY',
            'name': 'Joystick club',
            'address': '0x218645F85Ff27FC456ef46c3CdacBF5c40B2F9E8',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/21473/thumb/android-chrome-192x192.png?1639349299',
            'coingeckoId': 'joystick-club',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PX',
            'name': 'Protocol X',
            'address': '0x2127b96A05f9bF9095913d1e8b7bdB203a683A9f',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MATRIX',
            'name': 'Matrix Labs',
            'address': '0x211F4e76fcB811ed2B310A232a24B3445d95E3bC',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18297/small/nYGHNWiB_400x400.jpg?1631364044',
            'coingeckoId': 'matrixswap',
            'listedIn': [
                'coingecko',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'SINGLE',
            'name': 'SINGLE Token',
            'address': '0x20F6769c72d93BFB07a03e8570c037410D3FB056',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MAWETH',
            'name': 'Matic Aave Interest Bearing WETH',
            'address': '0x20D3922b4a1A8560E1aC99FBA4faDe0c849e2142',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x20D3922b4a1A8560E1aC99FBA4faDe0c849e2142/logo.png',
            'coingeckoId': 'matic-aave-weth',
            'listedIn': [
                'coingecko',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': '1MT',
            'name': '1Million Token (PoS)',
            'address': '0x2052344CeAce7f140da3E819c7729fEF72F97851',
            'decimals': 7,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x2052344ceace7f140da3e819c7729fef72f97851/logo.png',
            'coingeckoId': '1million-token',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ROND',
            'name': 'ROND',
            'address': '0x204820B6e6FEae805e376D2C6837446186e57981',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27601/small/rond.png?1664716228',
            'coingeckoId': 'rond',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'YELD',
            'name': 'PolyYeld',
            'address': '0x1fd6cF265fd3428F655378a803658942095b4C4e',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/16401/thumb/pxSN2UtB_400x400.jpg?1623913991',
            'coingeckoId': 'polyyeld-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DCB',
            'name': 'Digital Coin (PoS)',
            'address': '0x1f71bC1F88f09EcE59E844dEA4FCc4fA73934117',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x1f71bc1f88f09ece59e844dea4fcc4fa73934117/logo.png',
            'coingeckoId': 'digital-coin',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ACDC',
            'name': 'Adept Camp Doge Coin',
            'address': '0x1f02Ec2dD0fF3E31CA893AEC6C3585FE88243dF3',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'UND',
            'name': 'Unbound Dollar',
            'address': '0x1eBA4B44C4F8cc2695347C6a78F0B7a002d26413',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/7848.png',
            'coingeckoId': null,
            'listedIn': [
                'quickswap',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'deUSDC',
            'name': 'deBridge USD Coin',
            'address': '0x1dDcaa4Ed761428ae348BEfC6718BCb12e63bFaa',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x1ddcaa4ed761428ae348befc6718bcb12e63bfaa_2.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SD',
            'name': 'Stader',
            'address': '0x1d734A02eF1e1f5886e66b0673b71Af5B53ffA94',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/20658/small/sd.png?1642927667',
            'coingeckoId': 'stader',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'AMAAVE',
            'name': 'Aave Polygon AAVE',
            'address': '0x1d2a0E5EC8E5bBDCA5CB219e649B565d8e5c3360',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xffc97d72e13e01096502cb8eb52dee56f74dad7b.png',
            'coingeckoId': 'aave-polygon-aave',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MND',
            'name': 'Mind Music',
            'address': '0x1cd2528522A17B6Be63012fB63AE81f3e3e29D97',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/19104/thumb/logo-main_%282%29.png?1634506312',
            'coingeckoId': 'mind-music',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CPD',
            'name': 'CoinsPaid',
            'address': '0x1cE4A2C355F0DcC24E32A9Af19F1836D6F4f98ae',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18092/thumb/coinspaid.PNG?1630452234',
            'coingeckoId': 'coinspaid',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ESPRO',
            'name': 'EsportsPro',
            'address': '0x1c2E7118591ec1b6122e4fE3d645706a9747b10E',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14187/thumb/logo.jpg?1614830245',
            'coingeckoId': 'esportspro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AURA',
            'name': 'AuraSwap',
            'address': '0x1b7805e2829fd7D194DCc3078a4199b13c77E467',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26130/thumb/200x200_Token_transparent_for_coingecko.png?1655964506',
            'coingeckoId': 'auraswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TOTEM',
            'name': 'DragonMaster Totem',
            'address': '0x1aDCef5c780d8895AC77E6Ee9239B4b3eCb76DA2',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25056/thumb/C6_miUEU_400x400.jpg?1649939854',
            'coingeckoId': 'dragonmaster-totem',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AX',
            'name': 'AurusX',
            'address': '0x1a763170B96F23f15576D0fa0b2619d1254c437d',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27871/thumb/AurusX_token_2D.jpg?1666165444',
            'coingeckoId': 'aurusx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FXS',
            'name': 'Frax Share',
            'address': '0x1a3acf6D19267E2d3e7f898f42803e90C9219062',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/13423/small/frax_share.png?1608478989',
            'coingeckoId': 'frax-share',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'AMUSDC',
            'name': 'Aave Polygon USDC',
            'address': '0x1a13F4Ca1d028320A707D99520AbFefca3998b7F',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xbcca60bb61934080951369a648fb03df4f96263c.png',
            'coingeckoId': 'aave-polygon-usdc',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BADGER',
            'name': 'Badger (PoS)',
            'address': '0x1FcbE5937B0cc2adf69772D228fA4205aCF4D9b2',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x1fcbe5937b0cc2adf69772d228fa4205acf4d9b2/logo.png',
            'coingeckoId': 'badger-dao',
            'listedIn': [
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'HNY',
            'name': 'Hive Investments HONEY',
            'address': '0x1FA2F83BA2DF61c3d370071d61B17Be01e224f3a',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24797/thumb/hny.png?1648980896',
            'coingeckoId': 'hive-investments-honey',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SURF',
            'name': 'Surf Finance',
            'address': '0x1E42EDbe5376e717C1B22904C59e406426E8173F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x1E42EDbe5376e717C1B22904C59e406426E8173F/logo.png',
            'coingeckoId': 'surf-finance',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'LION',
            'name': 'PolyLion',
            'address': '0x1DA554D34027ca8dE74C5b1cd2FA53A8a1492C94',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15578/thumb/logo_-_2021-05-17T161550.501.png?1621239357',
            'coingeckoId': 'polylion',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAD',
            'name': 'Mad Bucks',
            'address': '0x1D89935ad3fC72E5f4aF709F81BBF03367090614',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DATA',
            'name': 'Data Economy Index',
            'address': '0x1D607Faa0A51518a7728580C238d912747e71F7a',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x1D607Faa0A51518a7728580C238d912747e71F7a/logo.png',
            'coingeckoId': 'data-economy-index',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'CELL',
            'name': 'Cellframe Token (PoS)',
            'address': '0x1D545D7ff917e08D7Bf26320343b16BdF51FdBb6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x1d545d7ff917e08d7bf26320343b16bdf51fdbb6/logo.png',
            'coingeckoId': 'cellframe',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'KNC',
            'name': 'Kyber Network Crystal',
            'address': '0x1C954E8fe737F99f68Fa1CCda3e51ebDB291948C',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x1c954e8fe737f99f68fa1ccda3e51ebdb291948c.png',
            'coingeckoId': 'kyber-network-crystal',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'LOWB',
            'name': 'Loser Coin',
            'address': '0x1C0a798B5a5273a9e54028eb1524fD337B24145F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15378/thumb/loser.PNG?1620693920',
            'coingeckoId': 'loser-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DRAX',
            'name': 'Drax',
            'address': '0x1Ba3510A9ceEb72E5CdBa8bcdDe9647E1f20fB4b',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x1Ba3510A9ceEb72E5CdBa8bcdDe9647E1f20fB4b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'WBTC',
            'name': 'Wrapped Bitcoin',
            'address': '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x2260fac5e5542a773aa44fbcfedf7c193bc2c599.png',
            'coingeckoId': 'wrapped-bitcoin',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'quickswap',
                'dfyn',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'IXS',
            'name': 'IX Swap',
            'address': '0x1BA17C639BdaeCd8DC4AAc37df062d17ee43a1b8',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x1ba17c639bdaecd8dc4aac37df062d17ee43a1b8.png',
            'coingeckoId': 'ix-swap',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'WOO',
            'name': 'WOO Network',
            'address': '0x1B815d120B3eF02039Ee11dC2d33DE7aA4a8C603',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x1b815d120b3ef02039ee11dc2d33de7aa4a8c603.png',
            'coingeckoId': 'woo-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BAD',
            'name': 'BAD Token',
            'address': '0x1B5522f315E21614b8e67f2523B4165160FeDD1b',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x1b5522f315e21614b8e67f2523b4165160fedd1b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SDAO',
            'name': 'Singularity Dao',
            'address': '0x1B493CE65a66e82dC6d09bF696609a4C797ED731',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'LICP',
            'name': 'Liquid ICP',
            'address': '0x1B43b97094Aa3c6Cc678eDb9e28Ac67dAaa7Cc64',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x1B43b97094Aa3c6Cc678eDb9e28Ac67dAaa7Cc64/logo.png',
            'coingeckoId': 'liquid-icp',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ROSN',
            'name': 'Roseon token',
            'address': '0x1B2a3C1417C69DA16bb9193268d0AAbFe54b3b39',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/9783.png',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'BDGV',
            'name': 'BUIDL Acadamey',
            'address': '0x1AF640AD5378144318fc7758F4c5546F6c435e88',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/28110/thumb/IMG_20221102_101102_540.jpg?1667545858',
            'coingeckoId': 'buidl-acadamey',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOCAx',
            'name': 'Superfluid MOCA',
            'address': '0x1ADcA32B906883e474aEbcBA5708B41F3645f941',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0xce899f26928a2b21c6a2fddd393ef37c61dba918/fbca8d128c567e9b4010b10c64e2b200.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BMI',
            'name': 'Bridge Mutual (PoS)',
            'address': '0x1A47E3316282d57DF146F87c58A1FB4b26992Bbf',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x1a47e3316282d57df146f87c58a1fb4b26992bbf/logo.png',
            'coingeckoId': 'bridge-mutual',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'OCCT',
            'name': 'Official Crypto Cowboy Token',
            'address': '0x1A056a5fcbbe5aD69793cea8569C6dBa33e4080A',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'jSEK',
            'name': 'Jarvis Synthetic Swedish Krona',
            'address': '0x197E5d6CcfF265AC3E303a34Db360ee1429f5d1A',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x197e5d6ccff265ac3e303a34db360ee1429f5d1a/80750a30b954db616e7bebfd9d8ff60f.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REN',
            'name': 'Republic Token',
            'address': '0x19782D3Dc4701cEeeDcD90f0993f0A9126ed89d0',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x408e41876cCCDC0F92210600ef50372656052a38/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'uniswap',
                'rubic'
            ]
        },
        {
            'symbol': 'DUSx',
            'name': 'Superfluid DUS',
            'address': '0x1963e341FF5a75C41ca5Ac400c828E636B70546E',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ALINK',
            'name': 'Aave LINK',
            'address': '0x191c10Aa4AF7C30e871E70C95dB0E4eb77237530',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14315/thumb/aLINK.412c6589.png?1615527827',
            'coingeckoId': 'aave-link',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EURe',
            'name': 'Monerium EUR emoney',
            'address': '0x18ec0A6E18E5bc3784fDd3a3634b31245ab704F6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x18ec0a6e18e5bc3784fdd3a3634b31245ab704f6/2b7c77728757af62032284b30b1ffd9d.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'POTS',
            'name': 'Moonpot',
            'address': '0x18caFd594E8C400a6d2c0182F94b81E25736266F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ORC',
            'name': 'Orion Cash',
            'address': '0x18a4F192Fa327F4b8Ab0A67E938df17383b4cf96',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27377/thumb/LOGO.png?1663734828',
            'coingeckoId': 'orion-cash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PORTX',
            'name': 'ChainPort',
            'address': '0x189586b5f6317538ae50c20a976597Da38984A24',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24490/thumb/VE-tUL-q_400x400.png?1647855096',
            'coingeckoId': 'chainport',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POLX',
            'name': 'Polylastic',
            'address': '0x187Ae45f2D361CbCE37c6A8622119c91148F261b',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x187ae45f2d361cbce37c6a8622119c91148f261b.png',
            'coingeckoId': 'polylastic',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'RUUF',
            'name': 'RuufCoin',
            'address': '0x182f1d39dF9460D7AEf29afBc80bBD68ED0A41d5',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17968/thumb/44d23204-3919-4ba6-adaf-69a51b9621d8.png?1629947200',
            'coingeckoId': 'ruufcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MKITTY',
            'name': 'mKitty',
            'address': '0x182dB1252C39073eeC9d743F13b5eeb80FDE314e',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/22180/thumb/kitty_matic.6e35abf7.png?1641178714',
            'coingeckoId': 'mkitty',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SC',
            'name': 'ScarpaCoin',
            'address': '0x18104f8E31786F0f519fF59D1B24C31A2F8feF40',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27456/thumb/ScarpaCoin_Logo.png?1664100906',
            'coingeckoId': 'scarpacoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XP',
            'name': 'PolkaFantasy',
            'address': '0x180cFBE9843d79BcAFcbcDF23590247793DFc95B',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18299/thumb/XP_Token_Icon.png?1631498467',
            'coingeckoId': 'polkafantasy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PMON',
            'name': 'Polychain Monsters',
            'address': '0x1796ae0b0fa4862485106a0de9b654eFE301D0b2',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14604/thumb/polkamon.png?1617238350',
            'coingeckoId': 'polychain-monsters',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GRAPE',
            'name': 'GrapeSwap Finance',
            'address': '0x17757dcE604899699b79462a63dAd925B82FE221',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27694/thumb/grape.png?1665240188',
            'coingeckoId': 'grapeswap-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HXN',
            'name': 'Havens Nook',
            'address': '0x175Bdc4e52EECB675B86FC4C9A3Ea3f80adBb610',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14876/thumb/havennook.PNG?1618881827',
            'coingeckoId': 'havens-nook',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'NFTZ',
            'name': 'ZelaaNFT  OLD ',
            'address': '0x173bEAd008df7Ba34d0d04e3D6dd01a6Db345C52',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26379/thumb/ZelaaNFT_%2817%29.png?1657677382',
            'coingeckoId': 'zelaanft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WGICT',
            'name': 'Wrapped  GICT',
            'address': '0x172A7BEE1fa5617431ca0D8324947d9a4c60196f',
            'decimals': 8,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CRV',
            'name': 'Curve DAO',
            'address': '0x172370d5Cd63279eFa6d502DAB29171933a610AF',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0xd533a949740bb3306d119cc777fa900ba034cd52.png',
            'coingeckoId': 'curve-dao-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'dfyn',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'GOB',
            'name': 'Goons of Balatroon',
            'address': '0x16d63619Cd67B15Ff822bFeB60388a226D2e452b',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27104/thumb/q1_hZykF_400x400.jpeg?1662006909',
            'coingeckoId': 'goons-of-balatroon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ROUTE',
            'name': 'Router Protocol',
            'address': '0x16ECCfDbb4eE1A85A33f3A9B21175Cd7Ae753dB4',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x16eccfdbb4ee1a85a33f3a9b21175cd7ae753db4.png',
            'coingeckoId': 'route',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'dfyn',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'DMA',
            'name': 'Dragoma',
            'address': '0x16DFb898cf7029303c2376031392cb9baC450f94',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26244/thumb/DMA.png?1656920073',
            'coingeckoId': 'dragoma',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SMBR',
            'name': 'Sombra',
            'address': '0x16B3E050e9e2f0Ac4f1BEA1b3E4fdc43d7f062Dd',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17884/thumb/sombra-200.png?1629710204',
            'coingeckoId': 'sombra-network',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BRND',
            'name': 'Brand Tokens (PoS)',
            'address': '0x1683767B3e3a40a61D9faa94CF86E5788F0a834a',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x1683767b3e3a40a61d9faa94cf86e5788f0a834a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DLYCOP',
            'name': 'Daily COP',
            'address': '0x1659fFb2d40DfB1671Ac226A0D9Dcc95A774521A',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18283/small/logo.png?1631366443',
            'coingeckoId': null,
            'listedIn': [
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'KENSHI',
            'name': 'Kenshi',
            'address': '0x164caf66c45e483F7eE647CcaD275B35B4C75719',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/21904/thumb/32x32_%281%29.png?1654588618',
            'coingeckoId': 'kenshi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HBAR',
            'name': 'HBAR',
            'address': '0x1646C835d70F76D9030DF6BaAeec8f65c250353d',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/3688/small/mqTDGK7Q.png?1566256777',
            'coingeckoId': null,
            'listedIn': [
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'OVR',
            'name': 'Ovr',
            'address': '0x1631244689EC1fEcbDD22fb5916E920dFC9b8D30',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/13429/thumb/ovr_logo.png?1608518911',
            'coingeckoId': 'ovr',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'GEN',
            'name': 'Gen Token',
            'address': '0x161c0EcE60dCFcdC3e4BDd5F1cDe3eD2f68285A9',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x161c0ece60dcfcdc3e4bdd5f1cde3ed2f68285a9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BONDLY ',
            'name': 'Bondly (PoS)',
            'address': '0x160d64f91AD7c4d9aC4Ba2C44A0E77373cA69ebe',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x160d64f91ad7c4d9ac4ba2c44a0e77373ca69ebe/logo.png',
            'coingeckoId': 'bondly',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'GEL',
            'name': 'Gelato',
            'address': '0x15b7c0c907e4C6b9AdaAaabC300C08991D6CEA05',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15026/thumb/Gelato_Icon_Logo_1024x1024.png?1619491717',
            'coingeckoId': 'gelato',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MILK',
            'name': 'Cool Cats Milk',
            'address': '0x1599fE55Cda767b1F631ee7D414b41F5d6dE393d',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x1599fe55cda767b1f631ee7d414b41f5d6de393d.png',
            'coingeckoId': 'milk',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ALM',
            'name': 'Alium Finance',
            'address': '0x1581929770bE3275a82068c1135b6dD59c5334Ed',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15621/thumb/alium.png?1655449806',
            'coingeckoId': 'alium-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LESS',
            'name': 'LessToken',
            'address': '0x155f60428d0eAF41b68525e1A781bcCD429E343f',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x155f60428d0eaf41b68525e1a781bccd429e343f/logo.png',
            'coingeckoId': 'less-network',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'AZUM',
            'name': 'Azuma coin',
            'address': '0x152c0f9c32C393961e9803a9467B2194E1c00b21',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'KROM',
            'name': 'Kromatika',
            'address': '0x14Af1F2f02DCcB1e43402339099A05a5E363b83c',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/20541/thumb/KROM_Transparent.png?1641398421',
            'coingeckoId': 'kromatika',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'JEWEL',
            'name': 'Jewels',
            'address': '0x149D190D1057e15e85Fb9e31ce5b3382B147A97F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'vBTC',
            'name': 'Strudel BTC',
            'address': '0x1489F4F7E3a80A0aD9fA23C39D2E8af818204ce9',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x1489F4F7E3a80A0aD9fA23C39D2E8af818204ce9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PDDOLLAR',
            'name': 'PolyDoge Dollar',
            'address': '0x146e58D34EaB0bFf7e0a63cfe9332908d680c667',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x146e58D34EaB0bFf7e0a63cfe9332908d680c667/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ROTI',
            'name': 'Slatan ROTI Token',
            'address': '0x13B4bd4c0719F9F46df35Bfc234213b4Df6E9B7c',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'wCCX',
            'name': 'WrappedConceal',
            'address': '0x137Ee749f0F8c2eD34cA00dE33BB59E3dafA494A',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x137Ee749f0F8c2eD34cA00dE33BB59E3dafA494A/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'MYST',
            'name': 'Mysterium',
            'address': '0x1379E8886A944d2D9d440b3d88DF536Aea08d9F3',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x1379e8886a944d2d9d440b3d88df536aea08d9f3.png',
            'coingeckoId': 'mysterium',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'KOGECOIN',
            'name': 'KogeCoin',
            'address': '0x13748d548D95D78a3c83fe3F32604B4796CFfa23',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x13748d548d95d78a3c83fe3f32604b4796cffa23.png',
            'coingeckoId': 'kogecoin',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'CRED',
            'name': 'Street Cred (PoS)',
            'address': '0x13654478010268c8c729E8078313C3d0acC21819',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x13654478010268c8c729e8078313c3d0acc21819/logo.png',
            'coingeckoId': 'street-cred',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DOGEFI',
            'name': 'DOGEFI (PoS)',
            'address': '0x13405CC33ffae0a9f91FeBb66B6b85A9C54DA13A',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x13405cc33ffae0a9f91febb66b6b85a9c54da13a/logo.png',
            'coingeckoId': 'dogefi',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'IBTC-FLI-P',
            'name': 'Inverse BTC Flexible Leverage Index',
            'address': '0x130cE4E4F76c2265f94a961D70618562de0bb8d2',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24177/thumb/iBTC-FLI-P_pulse_red.png?1646749712',
            'coingeckoId': 'inverse-btc-flexible-leverage-index',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DAIx',
            'name': 'Superfluid DAI',
            'address': '0x1305F6B6Df9Dc47159D12Eb7aC2804d4A33173c2',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x8f3cf7ad23cd3cadbd9735aff958023239c6a063/549c4205dbb199f1b8b03af783f35e71.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SDTx',
            'name': 'Superfluid SDT',
            'address': '0x12c294107772b10815307c05989DABD71C21670e',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x361a5a4993493ce00f61c32d4ecca5512b82ce90/4d8272a2602fefc2855afa132ef94115.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TUT',
            'name': 'Tutellus',
            'address': '0x12a34A6759c871C4C1E8A0A42CFc97e4D7Aaf68d',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x12a34A6759c871C4C1E8A0A42CFc97e4D7Aaf68d/logo.png',
            'coingeckoId': 'tutellus',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'GNUS',
            'name': 'Genius Token & NFT Collections',
            'address': '0x127E47abA094a9a87D084a3a93732909Ff031419',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/list/master/logos/token-logos/network/polygon/0x127E47abA094a9a87D084a3a93732909Ff031419.jpg',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PPDEX',
            'name': 'Pepedex',
            'address': '0x127984b5E6d5c59f81DACc9F1C8b3Bdc8494572e',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x127984b5e6d5c59f81dacc9f1c8b3bdc8494572e.png',
            'coingeckoId': 'pepedex',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FANZ',
            'name': 'FrontFanz',
            'address': '0x124C31a823CDf7CD391aDAEE4aA32455eB3E76Ca',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27722/thumb/FrontFanz.jpg?1665537401',
            'coingeckoId': 'frontfanz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QUIDD',
            'name': 'Quidd',
            'address': '0x123706cDD8e60324e610E9A2CC7012D0F45A5b8E',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/19725/small/quidd.png?1637303435',
            'coingeckoId': 'quidd',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'CIFI',
            'name': 'Citizen Finance',
            'address': '0x12365293cb6477d4fc2686e46BB97E3Fb64f1550',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24255/thumb/cifi.png?1647157454',
            'coingeckoId': 'citizen-finance',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'DIGG',
            'name': 'Digg (PoS)',
            'address': '0x12255b2DaDC7E18Fd34353DAa161974cC917C8dd',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x12255b2dadc7e18fd34353daa161974cc917c8dd/logo.png',
            'coingeckoId': 'digg',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'AXMATIC',
            'name': 'axMatic',
            'address': '0x1221591c1d77A9c334aBb0fe530ae6EE3aF51Af9',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x1221591c1d77A9c334aBb0fe530ae6EE3aF51Af9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SCA',
            'name': 'Scaleswap',
            'address': '0x11a819Beb0AA3327E39f52F90d65Cc9bCA499F33',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/16360/small/thumbnail_1170823958_vertical_logo_lateral_radiance.png?1623810516',
            'coingeckoId': 'scaleswap-token',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'PAPER',
            'name': 'Paper',
            'address': '0x11a1779ae6b02bb8E7ff847919bcA3e55BcbB3D5',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/23510/thumb/0xea97c7c1c89d4084e0bfb88284fa90243779da9f.png?1644300084',
            'coingeckoId': 'paper-fantom',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETH',
            'name': 'Ethereum  Wormhole ',
            'address': '0x11CD37bb86F65419713f30673A480EA33c826872',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/22990/thumb/ETH_wh_small.png?1644225466',
            'coingeckoId': 'ethereum-wormhole',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BYN',
            'name': 'BeyondFi',
            'address': '0x11602A402281974a70C2B4824d58ebeDe967E2bE',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14746/thumb/byn.png?1636079656',
            'coingeckoId': 'beyond-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SRAT',
            'name': 'SpaceRat',
            'address': '0x1132f58810Ee9fF13E97aECCd8DDa688Cc5eb8F4',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15970/thumb/logo_200.png?1622522829',
            'coingeckoId': 'spacerat',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ICHI',
            'name': 'ICHI',
            'address': '0x111111517e4929D3dcbdfa7CCe55d30d4B6BC4d6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/13119/thumb/ICHI_%28Round%29.jpg?1614308761',
            'coingeckoId': 'ichi-farm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FFWOOL',
            'name': 'Fast Food Wolf Game',
            'address': '0x10b1c123183E191E8e2d5B209323DE51c655e384',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/22142/thumb/ffwg-logo-200res.png?1640928500',
            'coingeckoId': 'fast-food-wolf-game',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UMI',
            'name': 'UMI',
            'address': '0x10Adf16a60F0020477c70473D6EA595D9bc29372',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/16953/thumb/umi.PNG?1625793521',
            'coingeckoId': 'umi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TAP',
            'name': 'TapX',
            'address': '0x10635bF5c17F5E4c0Ed9012aEf7C12f96a57a4Dd',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/13942/thumb/tapx.png?1652444051',
            'coingeckoId': 'tapmydata',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'polyFRAX',
            'name': 'Poly Frax',
            'address': '0x104592a158490a9228070E0A8e5343B499e125D0',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x104592a158490a9228070E0A8e5343B499e125D0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'dfyn',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ANKR',
            'name': 'Ankr',
            'address': '0x101A023270368c0D50BFfb62780F4aFd4ea79C35',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1664246990204_3407976441131719.png',
            'coingeckoId': 'ankr',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'XOT',
            'name': 'Okuru Token',
            'address': '0x100432c8c862B42A2b93d784E7eBe5a61e4AcFAa',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'HND',
            'name': 'Hundred Finance',
            'address': '0x10010078a54396F62c96dF8532dc2B4847d47ED3',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x10010078a54396f62c96df8532dc2b4847d47ed3/1831c155dc9bba59192a0828177afded.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CUM',
            'name': 'CumCoin (PoS)',
            'address': '0x0f92D459B20D21F6bf9E02056EA9165d3f78bA62',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x0f92d459b20d21f6bf9e02056ea9165d3f78ba62/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'AUR',
            'name': 'Aurix Exchange',
            'address': '0x0f730D43d85f722903b8ADed334B32332466b207',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BNTY',
            'name': 'Bounty0x Token (PoS)',
            'address': '0x0f40D69Ff494a9e74b1F20A89EfF299B2A05918F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x0f40d69ff494a9e74b1f20a89eff299b2a05918f/logo.png',
            'coingeckoId': 'bounty0x',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'TOMB',
            'name': 'Tomb',
            'address': '0x0e98C977B943f06075b2D795794238fBfB9b9a34',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/16133/small/tomb_icon_noBG.png?1623055476',
            'coingeckoId': 'tomb',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'DOGEGF',
            'name': 'DogeGF',
            'address': '0x0e7252706393470FfB0629DA2CAa39Fc9340F2d4',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18651/thumb/dogf.png?1632758659',
            'coingeckoId': 'dogegf',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DB',
            'name': 'Dark Build',
            'address': '0x0e59D50adD2d90f5111aca875baE0a72D95B4762',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x0e59D50adD2d90f5111aca875baE0a72D95B4762/logo.png',
            'coingeckoId': 'darkbuild-v2',
            'listedIn': [
                'coingecko',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DOM',
            'name': 'Dominium',
            'address': '0x0e2c818FEa38E7df50410F772b7d59aF20589A62',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24506/thumb/DOM-Token-Icon-200-200-px.png?1647883646',
            'coingeckoId': 'dominium-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATA',
            'name': 'Automata',
            'address': '0x0df0f72EE0e5c9B7ca761ECec42754992B2Da5BF',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15985/thumb/ATA.jpg?1622535745',
            'coingeckoId': 'automata',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GMS',
            'name': 'Gemstones',
            'address': '0x0d962A1A2A27B402e4D84772dea65Ac8592Eb6bF',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x0d962A1A2A27B402e4D84772dea65Ac8592Eb6bF/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'MLP',
            'name': 'MLP',
            'address': '0x0d5665A2319526A117E68E38EBEA4610aA8298F8',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://storage.googleapis.com/xy-finance-images/token-list/MLP.png',
            'coingeckoId': null,
            'listedIn': [
                'xyfinance'
            ]
        },
        {
            'symbol': 'WMATIC',
            'name': 'Wrapped Matic',
            'address': '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0.png',
            'coingeckoId': 'wmatic',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'quickswap',
                'dfyn',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NXTT',
            'name': 'Next Earth',
            'address': '0x0d0B8488222F7f83B23E365320a4021b12eAD608',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x0d0b8488222f7f83b23e365320a4021b12ead608.png',
            'coingeckoId': 'next-earth',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'WHEY',
            'name': 'WHEY',
            'address': '0x0cfbBb89Fd58F6B49B7bF5665397ddc84003d47c',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14883/thumb/WHEY_logo_black_0421_v1.png?1618893173',
            'coingeckoId': 'whey',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EXN',
            'name': 'Exeno Coin',
            'address': '0x0c9b3aB1bd0CF0745625381F5C3Aa1CD9BBc7Abb',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25845/thumb/20323.png?1654145185',
            'coingeckoId': 'exeno',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AURORA',
            'name': 'AuroraToken',
            'address': '0x0c8C8Ae8bc3a69dC8482C01CEacfB588bb516B01',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/Palette-Finance/images/main/aurora_token_logo.png',
            'coingeckoId': 'auroratoken',
            'listedIn': [
                'coingecko',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'TKB',
            'name': 'TokenBot',
            'address': '0x0c5F149362cA17Eac5D18E6912Ab4F5aEabf88E6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'VGTG',
            'name': 'VGTGToken',
            'address': '0x0c5EBeD5cB5BD838BDc43E1583D9d054f3d7Be6c',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x0c5EBeD5cB5BD838BDc43E1583D9d054f3d7Be6c/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ROYA',
            'name': 'Royale',
            'address': '0x0bD820aD2d7Ab7305b5C9538ba824C9b9bEb0561',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/RoyaleFinanceV1/royale-contracts/master/royale.png',
            'coingeckoId': 'royale',
            'listedIn': [
                'coingecko',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'PYM',
            'name': 'Playermon',
            'address': '0x0bD49815EA8e2682220BCB41524c0dd10Ba71d41',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/20682/thumb/eALRa3rZ_400x400.png?1637552321',
            'coingeckoId': 'playermon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATLX',
            'name': 'Atlantis Loans Polygon',
            'address': '0x0b68782eFF3177f1F9240B64A7e2F8E0497e2454',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/19362/small/atl.png?1635209629',
            'coingeckoId': 'atlantis-loans-polygon',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SUSHI',
            'name': 'Sushi',
            'address': '0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x6b3595068778dd592e39a122f4f5a5cf09c90fe2.png',
            'coingeckoId': 'sushi',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'XPOP',
            'name': 'XPOP',
            'address': '0x0ae1daF8329923cCe587e994DF500a8F1Dd5Fc6F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/26251/thumb/%EC%9E%90%EC%82%B0_1.png?1656936169',
            'coingeckoId': 'xpop',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATCx',
            'name': 'AutoChainTokenCandy (PoS)',
            'address': '0x0F933Ae2ffb1ee8b7385647Eb6A5610F31d5B56F',
            'decimals': 4,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x0f933ae2ffb1ee8b7385647eb6a5610f31d5b56f/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'AMATICC',
            'name': 'Ankr Reward Earning MATIC',
            'address': '0x0E9b89007eEE9c958c0EDA24eF70723C2C93dD58',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25742/small/a-matic-c-da4ec10dc9723e695700e25dbf8c8edf.png?1653462321',
            'coingeckoId': 'ankr-reward-earning-matic',
            'listedIn': [
                'coingecko',
                'openocean',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ERN',
            'name': 'Ethernity Chain',
            'address': '0x0E50BEA95Fe001A370A4F1C220C49AEdCB982DeC',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14238/small/ethernity_logo.png?1615189750',
            'coingeckoId': null,
            'listedIn': [
                'quickswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'CFO',
            'name': 'Cfoforum',
            'address': '0x0E2a70C08e884eA76116fccdC8b8be559162f08e',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17592/thumb/CFO_logo_200_200px.png?1628580306',
            'coingeckoId': 'cforforum-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOV',
            'name': 'DOVU (PoS)',
            'address': '0x0E0ffc562d72316b783E887bbAAe1FD794ADb530',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x0e0ffc562d72316b783e887bbaae1fd794adb530/logo.png',
            'coingeckoId': 'dovu',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MT',
            'name': 'MT Token',
            'address': '0x0Da6A7a0A2E7525950204Bb2C511E47a52235B17',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27457/thumb/mt_logo_200x200.png?1664105234',
            'coingeckoId': 'mt-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPROCKET',
            'name': 'Sprocket',
            'address': '0x0D98EAe620491d8f6836a39Ac45E54b286FDd2d7',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://i.ibb.co/6th7s4m/sprocket-symbol-200x200-col-trans.png',
            'coingeckoId': null,
            'listedIn': [
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'PBR',
            'name': 'PolkaBridge',
            'address': '0x0D6ae2a429df13e44A07Cd2969E085e4833f64A0',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://i.imgur.com/DWEwZ26.jpeg',
            'coingeckoId': 'polkabridge',
            'listedIn': [
                'coingecko',
                'quickswap',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'GLQ',
            'name': 'GraphLinq (PoS)',
            'address': '0x0CfC9a713A5C17Bc8a5fF0379467f6558bAcD0e0',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x0cfc9a713a5c17bc8a5ff0379467f6558bacd0e0/logo.png',
            'coingeckoId': 'graphlinq-protocol',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'WOMBAT',
            'name': 'Wombat',
            'address': '0x0C9c7712C83B3C70e7c5E11100D33D9401BdF9dd',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x0c9c7712c83b3c70e7c5e11100d33d9401bdf9dd.png',
            'coingeckoId': 'wombat',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'HOT',
            'name': 'HOLO (PoS)',
            'address': '0x0C51f415cF478f8D08c246a6C6Ee180C5dC3A012',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x0c51f415cf478f8d08c246a6c6ee180c5dc3a012/logo.png',
            'coingeckoId': 'holotoken',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'NMR',
            'name': 'Numeraire',
            'address': '0x0Bf519071b02F22C17E7Ed5F4002ee1911f46729',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x1776e1F26f98b1A5dF9cD347953a26dd3Cb46671/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'uniswap'
            ]
        },
        {
            'symbol': 'MNE',
            'name': 'Minereum',
            'address': '0x0B91B07bEb67333225A5bA0259D55AeE10E3A578',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/702/thumb/mne.png?1587615060',
            'coingeckoId': 'minereum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANGEL',
            'name': 'Polylauncher',
            'address': '0x0B6afe834dab840335F87d99b45C2a4bd81A93c7',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/17739/thumb/Polylauncher_-_200_x_200.png?1629121147',
            'coingeckoId': 'polylauncher',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALPHA',
            'name': 'PolyAlpha Finance',
            'address': '0x0B048D6e01a6b9002C291060bF2179938fd8264c',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x0B048D6e01a6b9002C291060bF2179938fd8264c/logo.png',
            'coingeckoId': 'polyalpha-finance',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'NFD',
            'name': 'Feisty Doge NFT (PoS)',
            'address': '0x0A5926027d407222F8fe20f24cB16e103f617046',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'IUSD',
            'name': 'iZUMi Bond USD',
            'address': '0x0A3BB08b3a15A19b4De82F8AcFc862606FB69A2D',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25388/thumb/iusd-logo-symbol-10k%E5%A4%A7%E5%B0%8F.png?1651660620',
            'coingeckoId': 'izumi-bond-usd',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'CNW',
            'name': 'CoinWealth',
            'address': '0x0A307BD521701F9D70FB29BfA9E2e36DC998daDB',
            'decimals': 6,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/23769/small/cw_logo-4955f59a5c8079f246fa07ac71b2541870ca7d906ca1d9c26d74a3870fafef2f_%281%29.png?1645425522',
            'coingeckoId': 'coinwealth',
            'listedIn': [
                'coingecko',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'oKLAY',
            'name': 'Orbit Bridge Polygon Klay',
            'address': '0x0A02D33031917d836Bd7Af02F9f7F6c74d67805F',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x0a02d33031917d836bd7af02f9f7f6c74d67805f.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'VSP',
            'name': 'Vesper Finance',
            'address': '0x09C5a4BCA808bD1ba2b8E6B3aAF7442046B4ca5B',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/13527/thumb/vesper_logo.jpg?1609399927',
            'coingeckoId': 'vesper-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DSF',
            'name': 'Dawn Star Token',
            'address': '0x095BC617b36AB227A379550633DFDCBf43f236F6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://storage.googleapis.com/xy-finance-images/token-list/DSF.png',
            'coingeckoId': 'dawn-star-token',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'NEARx',
            'name': 'Super NEAR',
            'address': '0x094Ed09F072596C34C5c0b197dcEB0da6b04C580',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/10365/small/near_icon.png?1601359077',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WATCH',
            'name': 'Yieldwatch',
            'address': '0x09211Dc67f9fe98Fb7bBB91Be0ef05f4a12FA2b2',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14186/small/WATCH1.png?1614825718',
            'coingeckoId': 'yieldwatch',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'E8',
            'name': 'Energy8',
            'address': '0x08e175A1eac9744a0F1cCaeb8F669af6a2BDa3Ce',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/19707/thumb/OZ52Oms.png?1652677165',
            'coingeckoId': 'energy8',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DF',
            'name': 'dForce',
            'address': '0x08C15FA26E519A78a666D19CE5C646D55047e0a3',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x08c15fa26e519a78a666d19ce5c646d55047e0a3/02df44f03a3c1f24cf95915fcbdc57bb.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'STND',
            'name': 'Standard',
            'address': '0x08BE454de533509e8832B257116C5506E55b0b64',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x08BE454de533509e8832B257116C5506E55b0b64/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'FUEGO',
            'name': 'Fuego Token (PoS)',
            'address': '0x088C9b7FC7d1E8F36028996236C9993BDf25Ac3E',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x088c9b7fc7d1e8f36028996236c9993bdf25ac3e/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'HUH',
            'name': 'HUH_Token',
            'address': '0x08648471B5AAd25fEEeb853d6829048f3Fc37786',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://get.celer.app/cbridge-icons/HUH.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'YUP',
            'name': 'Yup',
            'address': '0x086373fad3447F7F86252fb59d56107e9E0FaaFa',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/12322/thumb/photo_2021-10-26_00-47-35.jpg?1635227479',
            'coingeckoId': 'yup',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHART',
            'name': 'ChartEx',
            'address': '0x083c56d87eAD73D6231C165Ec450C6E28f3399C9',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/12523/small/chartex.png',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'DAOfi',
            'name': 'DAOfi (PoS)',
            'address': '0x083BdF826F6A7419d3Cef30B9E338316224c8e8B',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x083bdf826f6a7419d3cef30b9e338316224c8e8b/logo.png',
            'coingeckoId': 'daofi',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PPAY',
            'name': 'Plasma Finance',
            'address': '0x08158A6b5d4018340387d1A302f882E98a8bC5b4',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/13340/thumb/Hi9sEGAD.png?1607586849',
            'coingeckoId': 'plasma-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BEPRO',
            'name': 'BetProtocolToken (PoS)',
            'address': '0x07CC1cC3628Cc1615120DF781eF9fc8EC2feAE09',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x07cc1cc3628cc1615120df781ef9fc8ec2feae09/logo.png',
            'coingeckoId': 'bet-protocol',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'CRE8R',
            'name': 'CRE8R DAO (PoS)',
            'address': '0x079202AD852ccc46d8E73815f10Ff055049D3916',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AWBTC',
            'name': 'Aave WBTC',
            'address': '0x078f358208685046a11C85e8ad32895DED33A249',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14244/thumb/aWBTC.41453c2a.png?1615528721',
            'coingeckoId': 'aave-wbtc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELET',
            'name': 'Elementeum (PoS)',
            'address': '0x07738Eb4ce8932CA961c815Cb12C9d4ab5Bd0Da4',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x07738Eb4ce8932CA961c815Cb12C9d4ab5Bd0Da4/logo.png',
            'coingeckoId': 'ether-legends',
            'listedIn': [
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GCOIN',
            'name': 'Galaxy Fight Club',
            'address': '0x071AC29d569a47EbfFB9e57517F855Cb577DCc4C',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x071ac29d569a47ebffb9e57517f855cb577dcc4c.png',
            'coingeckoId': 'galaxy-fight-club',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'CAGE',
            'name': 'Coinage Finance',
            'address': '0x06ebc9c542357e2129D16717CD02c02FBC835d33',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/22300/thumb/Coinage_v2_200x200_white.png?1653464214',
            'coingeckoId': 'coinage-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GREEN',
            'name': 'Green Token',
            'address': '0x06E2DDd17F1043c0a4Cbe72cc7c256E4d8a3E374',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AMP',
            'name': 'Amp',
            'address': '0x0621d647cecbFb64b79E44302c1933cB4f27054d',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/12409/thumb/amp-200x200.png?1599625397',
            'coingeckoId': null,
            'listedIn': [
                'uniswap',
                'rubic'
            ]
        },
        {
            'symbol': 'XIDR',
            'name': 'XIDR',
            'address': '0x05eC81610aB47A094502bAC0D7eC256f06a1c6f8',
            'decimals': 6,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'GURU',
            'name': 'Guru',
            'address': '0x057E0bd9B797f9Eeeb8307B35DbC8c12E534c41E',
            'decimals': 9,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x057E0bd9B797f9Eeeb8307B35DbC8c12E534c41E/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'PYD',
            'name': 'PolyQuity Dollar',
            'address': '0x0527c8C43250279D6Eb74dA1078193f5316fc9a0',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x0527c8C43250279D6Eb74dA1078193f5316fc9a0/logo.png',
            'coingeckoId': 'polyquity-dollar',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'KRILL',
            'name': 'Polywhale',
            'address': '0x05089C9EBFFa4F0AcA269e32056b1b36B37ED71b',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x05089c9ebffa4f0aca269e32056b1b36b37ed71b.png',
            'coingeckoId': 'polywhale',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CREAM',
            'name': 'Cream',
            'address': '0x04f3C4cF2e806da6DF31e80E8A5D121F98eDD61d',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x04f3C4cF2e806da6DF31e80E8A5D121F98eDD61d/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ESNC',
            'name': 'Galaxy Arena Metaverse',
            'address': '0x04d80CdF20285d5Ac590BBAd97C887b9C6781774',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27923/thumb/esnc.png?1666606196',
            'coingeckoId': 'galaxy-arena',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFLE',
            'name': 'Safle',
            'address': '0x04b33078Ea1aEf29bf3fB29c6aB7B200C58ea126',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x04b33078ea1aef29bf3fb29c6ab7b200c58ea126.png',
            'coingeckoId': 'safle',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'DMT',
            'name': 'DragonMaster',
            'address': '0x04F177FCacF6fB4d2F95d41d7D3fee8E565cA1d0',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25171/thumb/JNBofoc.png?1650524900',
            'coingeckoId': 'dragonmaster-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTT',
            'name': 'BoughtThe.top',
            'address': '0x04AE4aE7F9af802DE5A2Da9561948F6f24D4b043',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x04ae4ae7f9af802de5a2da9561948f6f24d4b043/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DAO',
            'name': 'DAO Maker (PoS)',
            'address': '0x048c83a1176A1d3d12cb8C305179D360B1E4C958',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x048c83a1176a1d3d12cb8c305179d360b1e4c958/logo.png',
            'coingeckoId': 'dao-maker',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'CGS',
            'name': 'Cougar',
            'address': '0x047fD3B3D2366F9babe105ade4598E263d6c699c',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x047fd3b3d2366f9babe105ade4598e263d6c699c/logo.png',
            'coingeckoId': 'cougar-token',
            'listedIn': [
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ABR',
            'name': 'Allbridge',
            'address': '0x04429fbb948BBD09327763214b45e505A5293346',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/18690/thumb/abr.png?1640742053',
            'coingeckoId': 'allbridge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWG',
            'name': 'Swirge',
            'address': '0x043A3Aa319B563aC25D4E342d32bFfb51298DB7b',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x043A3Aa319B563aC25D4E342d32bFfb51298DB7b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BASE',
            'name': 'Defido Coin',
            'address': '0x0422b63182798ae9876cae3caBd225d96B284D14',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'Al',
            'name': 'Aluminium',
            'address': '0x041b4394F2a1A2EAF7e21460Ef59370789cb1F42',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/cometh-game/default-token-list/master/img/Al.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'GEMG',
            'name': 'GemGuardian',
            'address': '0x040F6B41d36F8c8e81f05ae156CD6605aBF60662',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0x040f6b41d36f8c8e81f05ae156cd6605abf60662/f6ebbbfd911a9183cf0a58100793188a.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'XPND',
            'name': 'Xpendium',
            'address': '0x03f61137BFb86be07394f0FD07a33984020F96D8',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/24520/thumb/2XyH_HyB_400x400.jpg?1647963390',
            'coingeckoId': 'xpendium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KEY',
            'name': 'KEY Token (PoS)',
            'address': '0x03bb597efd7Bb986222aFdA80858F05a3BC4A2E9',
            'decimals': 0,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x03bb597efd7bb986222afda80858f05a3bc4a2e9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MOSS',
            'name': 'Moss Governance',
            'address': '0x03E3369af9390493CB7CC599Cd5233D50e674Da4',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/25639/thumb/moss-governance-token.png?1652942056',
            'coingeckoId': 'moss-governance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CTI',
            'name': 'ClinTex CTi',
            'address': '0x03C2F6808502fFD4aB2787aD1A98eC13dbD5718b',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/13266/thumb/CTI.png?1606817542',
            'coingeckoId': 'clintex-cti',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AMATICB',
            'name': 'Ankr MATIC Reward Earning Bond',
            'address': '0x03A97594aA5ecE130E2E956fc0cEd2fea8ED8989',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1650031618318_8256145980879306.jpg',
            'coingeckoId': 'ankr-matic-reward-earning-bond',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'FM',
            'name': 'Follow ME',
            'address': '0x03943C3ef00d92e130185CeBC0bcc435Def2cC94',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x03943c3ef00d92e130185cebc0bcc435def2cc94/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'dTOP',
            'name': 'dHEDGE Top Index',
            'address': '0x0361BdEAB89DF6BBcc52c43589FABba5143d19dD',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0x0361BdEAB89DF6BBcc52c43589FABba5143d19dD/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'VISION',
            'name': 'APY vision',
            'address': '0x034b2090b579228482520c589dbD397c53Fc51cC',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x034b2090b579228482520c589dbd397c53fc51cc.png',
            'coingeckoId': 'apy-vision',
            'listedIn': [
                'coingecko',
                '1inch',
                'quickswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'AGA',
            'name': 'AGA',
            'address': '0x033d942A6b495C4071083f4CDe1f17e986FE856c',
            'decimals': 4,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x2d80f5f5328fdcb6eceb7cacf5dd8aedaec94e20.png',
            'coingeckoId': 'aga-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'dfyn',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ATTR',
            'name': 'Attrace',
            'address': '0x0335DeFC8A1977b99488e0b5f5c6bc3D44fAcdD4',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://raw.githubusercontent.com/attrace/assets/main/images/attrace_logo_v2_256.png',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'BND',
            'name': 'DokiDoki.Chainbinders',
            'address': '0x032b3217241fe53fFaDB334a9d06aACd2A9153D9',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x032b3217241fe53fFaDB334a9d06aACd2A9153D9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'TCP',
            'name': 'The Crypto Prophecies',
            'address': '0x032F85b8FbF8540a92B986d953e4C3A61C76d39E',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/15054/small/tcp.PNG?1619579195',
            'coingeckoId': null,
            'listedIn': [
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'RBAL',
            'name': 'Rebalance Token',
            'address': '0x03247a4368A280bEc8133300cD930A3a61d604f6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://elk.finance/tokens/logos/matic/0x03247a4368A280bEc8133300cD930A3a61d604f6/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'EURSx',
            'name': 'Superfluid EURS',
            'address': '0x02Ef6868d69707b6093A2962BB5fe5661fCC0dEb',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://static.debank.com/image/matic_token/logo_url/0xe111178a87a3bff0c8d18decba5798827539ae99/ce17cec4be00b080e51cec3e2c7d0a0a.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'POLR',
            'name': 'POLYSTARTER',
            'address': '0x029C2bf9E5e7bf11328f045205308244e11Efc46',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x029c2bf9e5e7bf11328f045205308244e11efc46/logo.png',
            'coingeckoId': 'polystarter',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'XGEM',
            'name': 'Exchange Genesis Ethlas Medium',
            'address': '0x02649C1Ff4296038De4b9bA8F491b42b940A8252',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x02649c1ff4296038de4b9ba8f491b42b940a8252.png',
            'coingeckoId': 'exchange-genesis-ethlas-medium',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'AXI',
            'name': 'Axioms (PoS)',
            'address': '0x01fA5b3A5d77BcF705DD505bBcBb34bce310E7FE',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x01fa5b3a5d77bcf705dd505bbcbb34bce310e7fe/logo.png',
            'coingeckoId': 'axioms',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PGEN',
            'name': 'Polygen',
            'address': '0x01d35cbC2070a3B76693Ce2b6364Eae24eb88591',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/21476/thumb/polygen-logo_1.jpeg?1639350369',
            'coingeckoId': 'polygen',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POLYGOLD',
            'name': 'PolyGold',
            'address': '0x0184316f58B9A44aCDD3e683257259dC0CF2202a',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/16055/thumb/wYRJKCT.png?1622699065',
            'coingeckoId': 'polygold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALGB',
            'name': 'Algebra',
            'address': '0x0169eC1f8f639B32Eec6D923e24C2A2ff45B9DD6',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/19580/small/13211.png?1635469023',
            'coingeckoId': 'algebra',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'quickswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ETNA',
            'name': 'ETNA Network',
            'address': '0x015C425f6dfabC31E1464cC4339954339f096061',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/14518/thumb/EtnaNewLogo200x200.png?1643193912',
            'coingeckoId': 'etna-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDN',
            'name': 'Neutrino USD',
            'address': '0x013F9c3fAc3e2759d7e90AcA4F9540F75194A0d7',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/10117/thumb/78GWcZu.png?1600845716',
            'coingeckoId': 'neutrino',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BEETS',
            'name': 'BeethovenxToken',
            'address': '0x0135D67E81fa29B31fe456c75756226b20437218',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ACRE',
            'name': 'Arable Protocol',
            'address': '0x011734f6Ed20E8D011d85Cf7894814B897420acf',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/23659/small/acre_token-02.png?1644989289',
            'coingeckoId': 'arable-protocol',
            'listedIn': [
                'coingecko',
                'quickswap',
                'lifinance'
            ]
        },
        {
            'symbol': '50C',
            'name': '50Cent',
            'address': '0x0102bbfDdFFBd8d28d3a1b9C47017F62F42768f2',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/16431/thumb/50cents.png?1624271368',
            'coingeckoId': '50cent',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RAI',
            'name': 'Rai Reflex Index',
            'address': '0x00e5646f60AC6Fb446f621d146B6E1886f002905',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x00e5646f60ac6fb446f621d146b6e1886f002905.png',
            'coingeckoId': 'rai',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'xCRE8R',
            'name': 'Superfluid CRE8R',
            'address': '0x00F22A2B5c40CE03FA4c96bA97605e5A40cC97D4',
            'decimals': 18,
            'chainId': 137,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FRMX',
            'name': 'FRMx',
            'address': '0x00E197Ac4735F1D6a0a2f0DF3947e6eD86D09260',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/13082/thumb/frmx.png?1660311822',
            'coingeckoId': 'frmx-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POWER',
            'name': 'UniPower',
            'address': '0x00D5149cDF7CEC8725bf50073c51c4fa58eCCa12',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/11618/thumb/unipower.png?1591943398',
            'coingeckoId': 'unipower',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SIKKA',
            'name': 'SIKKA',
            'address': '0x00658FC8ec685727F3F59d381B8Ad8f5E0FeDBc2',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/27201/thumb/SIKKA_logo_cmc.png?1662601915',
            'coingeckoId': 'sikka',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOND',
            'name': 'BOND (PoS)',
            'address': '0x000DE668684839F97d4845f32a43E913366ec08C',
            'decimals': 8,
            'chainId': 137,
            'logoURI': 'https://api.rubic.exchange/assets/polygon/0x000de668684839f97d4845f32a43e913366ec08c/logo.png',
            'coingeckoId': 'bonded-finance',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'INF',
            'name': 'Infam',
            'address': '0x000000ef379eE7F4C051f4B9aF901A9219d9ec5C',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://assets.coingecko.com/coins/images/21175/thumb/logo.a475509.png?1638454624',
            'coingeckoId': 'infam',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MATIC',
            'name': 'Polygon',
            'address': '0x0000000000000000000000000000000000000000',
            'decimals': 18,
            'chainId': 137,
            'logoURI': 'https://tokens.1inch.io/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0.png',
            'coingeckoId': 'matic-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        }
    ],
    '43114': [
        {
            'symbol': 'AVAX',
            'name': 'Avalanche',
            'address': 'FvwEAhmxKfeiG8SnEvq42hc6whRyY3EFYAvebMqDNDGCgxN5Z',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/12559/thumb/coin-round-red.png?1604021818',
            'coingeckoId': 'avalanche-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LVT',
            'name': 'Louverture',
            'address': '0xff579d6259dEDcc80488c9b89d2820bCb5609160',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xff579d6259dedcc80488c9b89d2820bcb5609160.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'NEXO',
            'name': 'Nexo',
            'address': '0xfe87Aba89d58da09d5bC13b4A1dC873C1b901806',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xfe87Aba89d58da09d5bC13b4A1dC873C1b901806/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'FIRE',
            'name': 'The Phoenix',
            'address': '0xfcc6CE74f4cd7eDEF0C5429bB99d38A3608043a5',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xfcc6CE74f4cd7eDEF0C5429bB99d38A3608043a5/logo.png',
            'coingeckoId': 'the-phoenix',
            'listedIn': [
                'coingecko',
                'openocean',
                'joe',
                'pangolin',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'WXT',
            'name': 'WXT Token',
            'address': '0xfcDe4A87b8b6FA58326BB462882f1778158B02F1',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xfcde4a87b8b6fa58326bb462882f1778158b02f1.png',
            'coingeckoId': 'wirex',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'AXLUSDC',
            'name': 'axlUSDC',
            'address': '0xfaB550568C688d5D8A52C7d794cb93Edc26eC0eC',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/26476/thumb/axlUSDC.png?1658207579',
            'coingeckoId': 'axlusdc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HSHARES',
            'name': 'HERMES Shares',
            'address': '0xfa4B6db72A650601E7Bd50a0A9f537c9E98311B2',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xfa4B6db72A650601E7Bd50a0A9f537c9E98311B2/logo_24.png',
            'coingeckoId': 'harmes-shares',
            'listedIn': [
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'SURF',
            'name': 'SURF.Finance',
            'address': '0xfa0bDf9dF8bb8b3d7687a44dAD2F69Bc7a7B294f',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xfa0bDf9dF8bb8b3d7687a44dAD2F69Bc7a7B294f/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'INJ',
            'name': 'Injective Token',
            'address': '0xfE057C0496eF3CCa8d85d847dA99c9815ba9981F',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xfE057C0496eF3CCa8d85d847dA99c9815ba9981F/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'PERA',
            'name': 'Pera Finance',
            'address': '0xfDA866CFEcE71F4C17b4a5e5f9A00ac08C72Eadc',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/16185/thumb/Pera-Finance-200x200.png?1650104052',
            'coingeckoId': 'pera-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELF',
            'name': 'ELF Token',
            'address': '0xfCDf63735c1Cf3203CE64fEf59DcA6A7aC9A6D54',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xfCDf63735c1Cf3203CE64fEf59DcA6A7aC9A6D54/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'NEIBR',
            'name': 'TheNeighbours',
            'address': '0xfCB3a43B6b5466105A318fD58e87728ad5658c7d',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0xfcb3a43b6b5466105a318fd58e87728ad5658c7d/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'IDIA',
            'name': 'Impossible Finance Launchpad',
            'address': '0xfCAF13227dcBFa2dc2b1928acFcA03b85e2D25dd',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/17803/thumb/IDIA.png?1629292292',
            'coingeckoId': 'idia',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'HONEY',
            'name': 'WASPvsBEE',
            'address': '0xfC904876Fa8bd0EE4e80AD1AA2ff380FEe3013A5',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0xfc904876fa8bd0ee4e80ad1aa2ff380fee3013a5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'IronICE',
            'name': 'Iron Finance ICE Token',
            'address': '0xfC108f21931576a21D0b4b301935DAc80d9E5086',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xfC108f21931576a21D0b4b301935DAc80d9E5086/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'PENDLE',
            'name': 'Pendle',
            'address': '0xfB98B335551a418cD0737375a2ea0ded62Ea213b',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x808507121b80c02388fad14726482e061b8da827.png',
            'coingeckoId': 'pendle',
            'listedIn': [
                'coingecko',
                '1inch',
                'joe',
                'lifinance'
            ]
        },
        {
            'symbol': 'LOOM',
            'name': 'LoomToken',
            'address': '0xfA178938Da2d58e55e52dc6dB92B99d9B2102EaE',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xfA178938Da2d58e55e52dc6dB92B99d9B2102EaE/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'DIS',
            'name': 'TosDis',
            'address': '0xf981547a1D9A0c59C9aba1e2b826BF01a2e5E263',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xf981547a1D9A0c59C9aba1e2b826BF01a2e5E263/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'AVAMIM',
            'name': 'AVA MIM',
            'address': '0xf97D917a0ef1Bb6919C36489Eb0Fd00dB41fFBa8',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0xf97d917a0ef1bb6919c36489eb0fd00db41ffba8/logo.png',
            'coingeckoId': 'ava-mim',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'STRAW',
            'name': 'Strawberry Share',
            'address': '0xf8D0C6c3ddC03F43A0687847f2b34bfd6941C2A2',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/26909/thumb/Sundae_finance_straw.png?1660732409',
            'coingeckoId': 'strawberry-share',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CC',
            'name': 'Culture Coin',
            'address': '0xf7262d90250453DFba6AaFa4D842555d45C901b1',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xf7262d90250453DFba6AaFa4D842555d45C901b1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'FORT',
            'name': 'Fortress',
            'address': '0xf6d46849DB378AE01D93732585BEc2C4480D1fD5',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/20626/thumb/dANE5hB.png?1637319869',
            'coingeckoId': 'fortressdao',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'NFSG',
            'name': 'NFT Soccer Games',
            'address': '0xf69c2fcd9128d49DfA22348C69177f9380438eB8',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xf69c2fcd9128d49DfA22348C69177f9380438eB8/logo_24.png',
            'coingeckoId': 'nft-soccer-games',
            'listedIn': [
                'coingecko',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'TUS',
            'name': 'Treasure Under Sea',
            'address': '0xf693248F96Fe03422FEa95aC0aFbBBc4a8FdD172',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xf693248f96fe03422fea95ac0afbbbc4a8fdd172.png',
            'coingeckoId': 'treasure-under-sea',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'joe',
                'pangolin',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'VOLT',
            'name': 'Asgardian Aereus',
            'address': '0xf5ee578505f4D876FeF288DfD9fD5e15e9EA1318',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/22419/thumb/VoltToken.png?1641807414',
            'coingeckoId': 'asgardian-aereus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TMT',
            'name': 'FarmersOnly\'s Tomato Coin',
            'address': '0xf5760bbbC3565f6A513a9c20300a335A1250C57e',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0xf5760bbbc3565f6a513a9c20300a335a1250c57e/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ANYAN',
            'name': 'AvaNYAN',
            'address': '0xf54501FBc97d5CB9C8B96480996a397E07516b2E',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0xf54501fbc97d5cb9c8b96480996a397e07516b2e/logo.png',
            'coingeckoId': 'avanyan',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'NEMO',
            'name': 'NEMO',
            'address': '0xf538030Ba4B39E35A3576bD6698cfcc6AC34A81f',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': 'nemo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLANC',
            'name': 'Blanc',
            'address': '0xf481Eec738C46F675e077ee966A680a19210Af11',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/21815/thumb/jpz9chVT_400x400.jpg?1640071751',
            'coingeckoId': 'blanc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KING',
            'name': 'KING',
            'address': '0xf3EC49ACb3084618121741e4BBB20996D383E9b2',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xf3EC49ACb3084618121741e4BBB20996D383E9b2/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'UNI',
            'name': 'Uniswap',
            'address': '0xf39f9671906d8630812f9d9863bBEf5D523c84Ab',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xf39f9671906d8630812f9d9863bBEf5D523c84Ab/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'AAAVE',
            'name': 'Aave AAVE',
            'address': '0xf329e36C7bF6E5E86ce2150875a84Ce77f477375',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1648801885630_9658702907022438.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'KACY',
            'name': 'Kassandra',
            'address': '0xf32398dae246C5f672B52A54e9B413dFFcAe1A44',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xf32398dae246C5f672B52A54e9B413dFFcAe1A44/logo.png',
            'coingeckoId': 'kassandra',
            'listedIn': [
                'coingecko',
                'pangolin',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SPICE',
            'name': 'Spice Trade',
            'address': '0xf30C5083a1479865C9A8916dEc6DDAdD82E8907B',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/25770/thumb/SPICE.png?1653607602',
            'coingeckoId': 'spice-trade',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'GLDM',
            'name': 'GoldMint',
            'address': '0xf24fD75c5BF897F272aE79A878089b12dE5f1d62',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'HAKKA',
            'name': 'Hakka Finance',
            'address': '0xf21074038dc2ea2A280EC890be55Ae3Be84616e3',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xf21074038dc2ea2A280EC890be55Ae3Be84616e3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'oldWETH',
            'name': 'Old Wrapped Ether',
            'address': '0xf20d962a6c8f70c731bd838a3a388D7d48fA6e15',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/logos/main/network/avalanche/0xf20d962a6c8f70c731bd838a3a388D7d48fA6e15.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'NFD',
            'name': 'Feisty Doge NFT',
            'address': '0xf1293574EE43950E7a8c9F1005Ff097A9A713959',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CHRO',
            'name': 'WyndBlast',
            'address': '0xf101a3CfEA61Ff7931344C13c1955b86A7f0f826',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0xf101a3cfea61ff7931344c13c1955b86a7f0f826/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'YDR',
            'name': 'YDragon',
            'address': '0xf03Dccaec9A28200A6708c686cf0b8BF26dDc356',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xf03Dccaec9A28200A6708c686cf0b8BF26dDc356/logo_24.png',
            'coingeckoId': 'ydragon',
            'listedIn': [
                'coingecko',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'CBIX7',
            'name': 'CBI Index 7',
            'address': '0xeeD4DcDFaE91D39d2b851338433F0013AF5D1406',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xeeD4DcDFaE91D39d2b851338433F0013AF5D1406/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'TAZOR',
            'name': 'Tazor',
            'address': '0xee65D8B88F86ace0f7bA42BA2d2C679b6f604bf0',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/27261/thumb/tazor.png?1663554021',
            'coingeckoId': 'tazor',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'pSHARE',
            'name': 'pSHARE',
            'address': '0xed0fa235F3e72E82dA51FC17e35157C232Fa879b',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SPIRIT',
            'name': 'SpiritSwap Token',
            'address': '0xecB6D48E04d1DF057e398b98AC8b3833eb3839Ec',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CLY',
            'name': 'Colony',
            'address': '0xec3492a2508DDf4FDc0cD76F31f340b30d1793e6',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xec3492a2508ddf4fdc0cd76f31f340b30d1793e6.png',
            'coingeckoId': 'colony',
            'listedIn': [
                'coingecko',
                '1inch',
                'joe',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'ECD',
            'name': 'Echidna',
            'address': '0xeb8343D5284CaEc921F035207ca94DB6BAaaCBcd',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xeb8343d5284caec921f035207ca94db6baaacbcd/logo.png',
            'coingeckoId': 'echidna',
            'listedIn': [
                'coingecko',
                'joe',
                'lifinance'
            ]
        },
        {
            'symbol': 'BANANA',
            'name': 'Gorilla Nodes',
            'address': '0xeacD4F4d93527CB5d0cDFE5930D612CCfD5Af436',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/24479/thumb/banana.png?1647777001',
            'coingeckoId': 'gorilla-nodes',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'uDOO',
            'name': 'uDOO',
            'address': '0xeaa4F0C8bDC6109C92f0A5bE88A035ee11D40928',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xeaa4F0C8bDC6109C92f0A5bE88A035ee11D40928/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ELK',
            'name': 'Elk Finance',
            'address': '0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xeeeeeb57642040be42185f49c52f7e9b38f8eeee.png',
            'coingeckoId': 'elk-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DHT',
            'name': 'dHedge DAO Token',
            'address': '0xeE33c5804b759cCf3A9de88c9E772374147dCdEE',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xeE33c5804b759cCf3A9de88c9E772374147dCdEE/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'BNT',
            'name': 'Bancor Network Token',
            'address': '0xeD44979561a797515767B0201121afC4b5eE2838',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xeD44979561a797515767B0201121afC4b5eE2838/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'LUA',
            'name': 'LuaToken',
            'address': '0xeCd99fe115553493C6BF41C27da69E131766baAd',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xeCd99fe115553493C6BF41C27da69E131766baAd/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'IMX',
            'name': 'Impermax  OLD ',
            'address': '0xeA6887e4a9CdA1B77E70129E5Fba830CdB5cdDef',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xeA6887e4a9CdA1B77E70129E5Fba830CdB5cdDef/logo_24.png',
            'coingeckoId': 'impermax',
            'listedIn': [
                'coingecko',
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'DMG',
            'name': 'DMM - Governance',
            'address': '0xe9925Cd8639c21DFcEA667D40dD2c8f54f420618',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xe9925Cd8639c21DFcEA667D40dD2c8f54f420618/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'PEFI',
            'name': 'Penguin Finance',
            'address': '0xe896CDeaAC9615145c0cA09C8Cd5C25bced6384c',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xe896cdeaac9615145c0ca09c8cd5c25bced6384c.png',
            'coingeckoId': 'penguin-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pangolin',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'USD+',
            'name': 'USD ',
            'address': '0xe80772Eaf6e2E18B651F160Bc9158b2A5caFCA65',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/25757/thumb/USD__logo.png?1653519267',
            'coingeckoId': 'usd',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'SNOSHARE',
            'name': 'Snoshares',
            'address': '0xe7A102Fbc8AB3581d62830DdB599eCCaae5e7875',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/23776/thumb/snoshare.png?1645434651',
            'coingeckoId': 'snoshares',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'hJoe',
            'name': 'hJoe v2',
            'address': '0xe7250b05bd8DEE615ecC681eda1196aDD5156F2B',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xe7250b05bd8DEE615ecC681eda1196aDD5156F2B/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'joe',
                'lifinance'
            ]
        },
        {
            'symbol': 'WSHARE',
            'name': 'Frozen Walrus Share',
            'address': '0xe6d1aFea0B76C8f51024683DD27FA446dDAF34B6',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xe6d1afea0b76c8f51024683dd27fa446ddaf34b6.png',
            'coingeckoId': 'frozen-walrus-share',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'WSPP',
            'name': 'WolfSafePoorPeople',
            'address': '0xe668f8030bf17f3931A3069f31f4Fa56EFE9DD54',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'pBTC',
            'name': 'pTokens BTC',
            'address': '0xe6338226c321f3089c645ab526f844713C2F7Be3',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xe6338226c321f3089c645ab526f844713C2F7Be3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'APP',
            'name': 'DAPPSY',
            'address': '0xe5130D3DbfaC6Ae7d73a24D719762df74d8E4c27',
            'decimals': 8,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AWETH',
            'name': 'Aave WETH',
            'address': '0xe50fA9b3c56FfB159cB0FCA61F5c9D750e8128c8',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1648801472951_9881779257920693.png',
            'coingeckoId': 'aave-weth',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'OWL',
            'name': 'OWL Token',
            'address': '0xe49403892253A3d7952A45d43C630126D0b8D1f2',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xe49403892253A3d7952A45d43C630126D0b8D1f2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'COSMIC',
            'name': 'Cosmic Coin',
            'address': '0xe48C74833ce6f18A8e54f73f1D02B8E9f9Ff8Caa',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/20596/thumb/COSMIC_Logo_200x200.png?1637286370',
            'coingeckoId': 'cosmic-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOLDx',
            'name': 'dForce',
            'address': '0xe3EDb25E952e9b3575EFb71DE14651EF3F2e8FaF',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xe3EDb25E952e9b3575EFb71DE14651EF3F2e8FaF/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'AVATRU',
            'name': 'Ava Trust',
            'address': '0xe2Ef00E27a13e9010E53C280886A4b5A6768388B',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0xe2ef00e27a13e9010e53c280886a4b5a6768388b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BAVA',
            'name': 'Baklava',
            'address': '0xe19A1684873faB5Fb694CfD06607100A632fF21c',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xe19A1684873faB5Fb694CfD06607100A632fF21c/logo_24.png',
            'coingeckoId': 'baklava',
            'listedIn': [
                'coingecko',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'ALTA',
            'name': 'Alta Finance',
            'address': '0xe0cCa86B254005889aC3a81e737f56a14f4A38F5',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/18713/thumb/AFN-token-Altafin-200.png?1633079552',
            'coingeckoId': 'alta-finance',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'HOOF',
            'name': 'Metaderby Hoof',
            'address': '0xe0bb6feD446A2dbb27F84D3C27C4ED8EA7603366',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xe0bb6feD446A2dbb27F84D3C27C4ED8EA7603366/logo.png',
            'coingeckoId': 'metaderby-hoof',
            'listedIn': [
                'coingecko',
                'joe',
                'lifinance'
            ]
        },
        {
            'symbol': 'ICE',
            'name': 'Ice Token',
            'address': '0xe0Ce60AF0850bF54072635e66E79Df17082A1109',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xe0Ce60AF0850bF54072635e66E79Df17082A1109/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'USDtx',
            'name': 'Superfluid USDt',
            'address': '0xdf37ee57B2efD215A6a8329D6B8b72064F09BBD0',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://static.debank.com/image/avax_token/logo_url/0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7/afcc6e8c91a0ce1270eae92a121dcf25.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CERBY',
            'name': 'Cerby Token',
            'address': '0xdef1fac7Bf08f173D286BbBDcBeeADe695129840',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xdef1fac7Bf08f173D286BbBDcBeeADe695129840/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'xyETH',
            'name': 'xyETH',
            'address': '0xde6146A0B751c31eA73A4E51300b6c8A8BF6D798',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
            'coingeckoId': null,
            'listedIn': [
                'xyfinance'
            ]
        },
        {
            'symbol': 'oldUSDT',
            'name': 'Old Tether USD',
            'address': '0xde3A24028580884448a5397872046a019649b084',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/logos/main/network/avalanche/0xc7198437980c041c805A1EDcbA50c1Ce5db95118.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'CABAG',
            'name': 'Cabbage.Cash',
            'address': '0xdb333724fAE72b4253FC3d44c8270CBBC86d147b',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xdb333724fAE72b4253FC3d44c8270CBBC86d147b/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'TSHARE',
            'name': 'TSHARE',
            'address': '0xda5B232FB6e3E897d2f84797D19c53Cf3f5ae938',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TRAC',
            'name': 'Trace Token',
            'address': '0xdEA3Da33bDee64487358DB66d9AbC9EC256D1BFb',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xdEA3Da33bDee64487358DB66d9AbC9EC256D1BFb/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SDOG',
            'name': 'Snowdog',
            'address': '0xdE9E52F1838951e4d2bb6C59723B003c353979b6',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/20544/thumb/ZFnQky2H_400x400.jpg?1637204918',
            'coingeckoId': 'snowdog',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEC',
            'name': 'Decentr',
            'address': '0xdC6D33821606f6c5FfceD7Bb315152210F3f31d9',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xdC6D33821606f6c5FfceD7Bb315152210F3f31d9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SUN',
            'name': 'Aztec Nodes SUN',
            'address': '0xdC05BFF771Cd5eb1BdC3A76950144fFd8d64EFc0',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/24832/thumb/token_V2.png?1649059442',
            'coingeckoId': 'aztec-nodes-sun',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XGEM',
            'name': 'Exchange Genesis Ethlas Medium',
            'address': '0xdB12c7E30Dc2A2C421724D07C7A09147bD9f61bb',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'QUINT',
            'name': 'QUINT',
            'address': '0xd9F942e36b4Cac550DaA0cb31EF09eFb1D1505f3',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MORE',
            'name': 'Moremoney Finance',
            'address': '0xd9D90f882CDdD6063959A9d837B05Cb748718A05',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xd9D90f882CDdD6063959A9d837B05Cb748718A05/logo_24.png',
            'coingeckoId': 'more-token',
            'listedIn': [
                'coingecko',
                'joe',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'TLC',
            'name': 'TLChain',
            'address': '0xd97136B7caB42058F75E05e3D8C5C87CceaC8Bc3',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/28177/thumb/tlc.png?1668250171',
            'coingeckoId': 'tlchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IEUROS',
            'name': 'Inflation Adjusted EURO',
            'address': '0xd7a5197b78E6c139531C3D2Ff064215902B72c2E',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/26442/thumb/894CE468-A364-42BA-83B0-60A7CA859614.png?1658110157',
            'coingeckoId': 'inflation-adjusted-euro',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'YFV',
            'name': 'YFValue',
            'address': '0xd79FA6aC3D484CbbcbE3208518bD4Ae03519E0DB',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xd79FA6aC3D484CbbcbE3208518bD4Ae03519E0DB/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'CFi',
            'name': 'CyberFi Token',
            'address': '0xd77b301D644608eE1E3dC56C3CF8540E6C9EC60F',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xd77b301D644608eE1E3dC56C3CF8540E6C9EC60F/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'BIOS',
            'name': '0x nodes',
            'address': '0xd7783a275E53FC6746dEDfbAD4a06059937502A4',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/15600/thumb/BIOS_01.png?1621737736',
            'coingeckoId': 'bios',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'UFARM',
            'name': 'UniFarm',
            'address': '0xd60efFED653f3f1B69047F2D2dC4E808A548767b',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/15247/thumb/ufarm.jpeg?1620218765',
            'coingeckoId': 'unifarm',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BIFI',
            'name': 'Beefy Finance',
            'address': '0xd6070ae98b8069de6B494332d1A1a81B6179D960',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xd6070ae98b8069de6B494332d1A1a81B6179D960/logo.png',
            'coingeckoId': 'beefy-finance',
            'listedIn': [
                'coingecko',
                'joe',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'DAI',
            'name': 'Dai',
            'address': '0xd586E7F844cEa2F87f50152665BCbc2C279D8d70',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x6b175474e89094c44da98b954eedeac495271d0f.png',
            'coingeckoId': 'dai',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'joe',
                'pangolin',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': '1INCH',
            'name': '1inch',
            'address': '0xd501281565bf7789224523144Fe5D98e8B28f267',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/13469/thumb/1inch-token.png?1608803028',
            'coingeckoId': '1inch',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'MNDL',
            'name': 'Mandala',
            'address': '0xd3DF6106346Ac91CE76D1AdF291a68581D3fa782',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/23518/thumb/mndl.png?1644302116',
            'coingeckoId': 'mandala-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FUSE',
            'name': 'Fuse Token',
            'address': '0xd3974AE5A9BCD4AE4c9037a25A67374a11df4154',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xd3974AE5A9BCD4AE4c9037a25A67374a11df4154/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'DEUSDC',
            'name': 'deUSDC',
            'address': '0xd39016475200ab8957e9C772C949Ef54bDA69111',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/26669/thumb/deUSDC.png?1659509853',
            'coingeckoId': 'deusdc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REACT',
            'name': 'Rebase Aggregator Capital',
            'address': '0xd33df97747dD6bEcAD26B2e61F818c94B7588E69',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/25034/thumb/qCOTjLd.png?1649852338',
            'coingeckoId': 'rebase-aggregator-capital',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'RCN',
            'name': 'Ripio Credit Network Token',
            'address': '0xd2427C8a8dA88c0Ea24370A971Dad6EEd2ff63b7',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xd2427C8a8dA88c0Ea24370A971Dad6EEd2ff63b7/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'XAVA',
            'name': 'Avalaunch',
            'address': '0xd1c3f94DE7e5B45fa4eDBBA472491a9f4B166FC4',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xd1c3f94de7e5b45fa4edbba472491a9f4b166fc4.png',
            'coingeckoId': 'avalaunch',
            'listedIn': [
                '1inch',
                'openocean',
                'joe',
                'pangolin',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SFI',
            'name': 'Spice',
            'address': '0xd13eB71515DC48a8a367D12F844e5737bab415dF',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xd13eB71515DC48a8a367D12F844e5737bab415dF/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ANKR',
            'name': 'Ankr Network',
            'address': '0xd09Af6A3C12EC24CeD114A0829F5Bf73D40dC5A8',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xd09Af6A3C12EC24CeD114A0829F5Bf73D40dC5A8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'GRIFFIN',
            'name': 'Griffin',
            'address': '0xd08654347A882f080CF7da8FFFAe703cF3652a0C',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/25918/thumb/7FN2QiPv_400x400.jpg?1654592816',
            'coingeckoId': 'griffin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DIA',
            'name': 'DIAToken',
            'address': '0xd072dEcEb5FD919bF8853CeB1068438652a06c00',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xd072dEcEb5FD919bF8853CeB1068438652a06c00/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'APE-X',
            'name': 'Ape-X',
            'address': '0xd039C9079ca7F2a87D632A9C0d7cEa0137bAcFB5',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xd039c9079ca7f2a87d632a9c0d7cea0137bacfb5.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'VLT',
            'name': 'Bankroll Vault',
            'address': '0xd02D849512780BF29Cf48D56900a4B025e478D3E',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xd02D849512780BF29Cf48D56900a4B025e478D3E/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'USDD',
            'name': 'Decentralized USD',
            'address': '0xcf799767d366d789e8B446981C2D578E241fa25c',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xcf799767d366d789e8B446981C2D578E241fa25c/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'LYXe',
            'name': 'LUKSO Token',
            'address': '0xcd82B8f5f145abb08f8c495BDB675d1Ac4D40Eb2',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xcd82B8f5f145abb08f8c495BDB675d1Ac4D40Eb2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'PARA',
            'name': 'Parabolic',
            'address': '0xcca3E26Be51b8905f1A01872524f17eb55Bd02fb',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/25187/thumb/LHh3hHnQ_400x400.jpg?1650600695',
            'coingeckoId': 'parabolic-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AGRI',
            'name': 'AgriChain Utility Token',
            'address': '0xcc6421b76190b5e7D1029824F6C988456C798291',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xcc6421b76190b5e7D1029824F6C988456C798291/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'AKITA',
            'name': 'Akita Inu',
            'address': '0xcaF5191fc480F43e4DF80106c7695ECA56E48B18',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/14115/thumb/akita.png?1661666578',
            'coingeckoId': 'akita-inu',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'AXIAL',
            'name': 'Axial Token',
            'address': '0xcF8419A615c57511807236751c0AF38Db4ba3351',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xcf8419a615c57511807236751c0af38db4ba3351.png',
            'coingeckoId': 'axial-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'DSD',
            'name': 'Dynamic Set Dollar',
            'address': '0xcE4Af1de4A61C02E590cA8AD2a1493FF2A3D5fB5',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xcE4Af1de4A61C02E590cA8AD2a1493FF2A3D5fB5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'OBX',
            'name': 'OpenBlox',
            'address': '0xcCf719c44e2C36E919335692E89d22Cf13D6aaEB',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xcCf719c44e2C36E919335692E89d22Cf13D6aaEB/logo.png',
            'coingeckoId': 'openblox',
            'listedIn': [
                'coingecko',
                'joe',
                'lifinance'
            ]
        },
        {
            'symbol': 'GLDB',
            'name': 'BiAltin',
            'address': '0xcA220F1e486A8e35d6F1DCD62073Ad8DD04659ED',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xcA220F1e486A8e35d6F1DCD62073Ad8DD04659ED/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'SGEM',
            'name': 'SGEM',
            'address': '0xc9e8c581d67Fe5cc6217B2Fa1B2d4D9Cecb60fB2',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TRADE',
            'name': 'UniTrade',
            'address': '0xc95d97181857469f7d3CcACB20a84fC0Dfd69a1b',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xc95d97181857469f7d3CcACB20a84fC0Dfd69a1b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'LIF3',
            'name': 'LIF3',
            'address': '0xc944151ba61CFedD9625bfC086398b55642c1492',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AURA',
            'name': 'Aurora Finance',
            'address': '0xc8a251142722CeB4a524876b89CC4c0709585818',
            'decimals': 5,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/25204/thumb/auro.jpeg?1650686160',
            'coingeckoId': 'aurora-finance',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'THKD',
            'name': 'TrueHKD',
            'address': '0xc8Eb95dac0033024B4b3fc87569824C4416F495d',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xc8Eb95dac0033024B4b3fc87569824C4416F495d/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SWAP.e',
            'name': 'TrustSwap Token',
            'address': '0xc7B5D72C836e718cDA8888eaf03707fAef675079',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xcc4304a31d09258b0029ea7fe63d032f52e44efe.png',
            'coingeckoId': 'trustswap',
            'listedIn': [
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'BETS',
            'name': 'BetSwirl',
            'address': '0xc763f8570A48c4c00C80B76107cbE744dDa67b79',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/26618/thumb/icon_200.png?1659073275',
            'coingeckoId': 'betswirl',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'USDTE',
            'name': 'Tether Avalanche Bridged  USDT e ',
            'address': '0xc7198437980c041c805A1EDcbA50c1Ce5db95118',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xc7198437980c041c805a1edcba50c1ce5db95118.png',
            'coingeckoId': 'tether-avalanche-bridged-usdt-e',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'joe',
                'pangolin',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NCASH',
            'name': 'Nucleus Vision',
            'address': '0xc69Eba65e87889f0805dB717Af06797055A0BA07',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xc69Eba65e87889f0805dB717Af06797055A0BA07/logo_24.png',
            'coingeckoId': 'nucleus-vision',
            'listedIn': [
                'coingecko',
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'CHAM',
            'name': 'Champion',
            'address': '0xc65bC1E906771e105fBAcBD8dfE3862Ee7BE378E',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/26841/thumb/cham-200x200.png?1660387462',
            'coingeckoId': 'champion',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTSG',
            'name': 'BitSong',
            'address': '0xc5bac6dc06d1FC9F5eD7b0a04D08747b4c938B6d',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xc5bac6dc06d1FC9F5eD7b0a04D08747b4c938B6d/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'YEL',
            'name': 'YEL Token',
            'address': '0xc5E7520432EbDBf0a610287Ac45fc231876f5E99',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PBL',
            'name': 'Pebble',
            'address': '0xc5C71CD0b734Dae8c6eBa9D79C3Db693d112DDA1',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'QDAO',
            'name': 'Q DAO Governance token v1.0',
            'address': '0xc57719864387B11B8915eDE8f84A8d2CCA282451',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xc57719864387B11B8915eDE8f84A8d2CCA282451/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'TUSD',
            'name': 'TrueUSD',
            'address': '0xc458770B5fA66f4DF1498c3D824261D5f5EC3582',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xc458770B5fA66f4DF1498c3D824261D5f5EC3582/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'GRS',
            'name': 'GREASE',
            'address': '0xc3581D4727f674B8280D04E5615CBf3F06a58CEB',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0xc3581d4727f674b8280d04e5615cbf3f06a58ceb/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'AAVAXC',
            'name': 'Ankr Reward Bearing AVAX',
            'address': '0xc3344870d52688874b06d844E0C36cc39FC727F6',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1658311661542_9477867315587034.JPG',
            'coingeckoId': 'ankr-reward-bearing-avax',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'COMP',
            'name': 'Compound',
            'address': '0xc3048E19E76CB9a3Aa9d77D8C03c29Fc906e2437',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/10775/thumb/COMP.png?1592625425',
            'coingeckoId': 'compound-governance-token',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'CHAD',
            'name': 'GigaChad',
            'address': '0xc16A1b60175d16be6F2ED0bA506ca0e6EfBA8e08',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0xc16a1b60175d16be6f2ed0ba506ca0e6efba8e08/logo.png',
            'coingeckoId': 'gigachad',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'LAR',
            'name': 'Linkart',
            'address': '0xc162e489C7f39676F6376CA79df3e728f101a895',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xc162e489C7f39676F6376CA79df3e728f101a895/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'BLUE',
            'name': 'Tip Blue',
            'address': '0xc12e249FaBe1c5Eb7C558E5F50D187687a244E31',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xc12e249FaBe1c5Eb7C558E5F50D187687a244E31/logo_24.png',
            'coingeckoId': 'tip-blue',
            'listedIn': [
                'coingecko',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'STND',
            'name': 'Standard',
            'address': '0xc12caC7090baa48Ec750CceeC57C80768F6ee58E',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TCAD',
            'name': 'TrueCAD',
            'address': '0xbf65c0f333954EBC49aab45fB6F04Bee27F72495',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xbf65c0f333954EBC49aab45fB6F04Bee27F72495/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'CHRO',
            'name': 'Chronicum',
            'address': '0xbf1230bb63bfD7F5D628AB7B543Bcefa8a24B81B',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xbf1230bb63bfd7f5d628ab7b543bcefa8a24b81b.png',
            'coingeckoId': 'chronicum',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'POND',
            'name': 'Marlin POND',
            'address': '0xbeeA21cC5D10e21dF6aB42Bd2D5e748e4EF59293',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xbeeA21cC5D10e21dF6aB42Bd2D5e748e4EF59293/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'AVXL',
            'name': 'Avaxlauncher',
            'address': '0xbeE994aD257dCC84672c0c6e6168A4701041F39f',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/18270/thumb/avaxlauncher.png?1631174466',
            'coingeckoId': 'avaxlauncher',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'URQA',
            'name': 'UREEQA',
            'address': '0xbd3936eC8D83A5D4e73ecA625eCFa006da8C8F52',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/14605/thumb/R_O2enOX_400x400.png?1617243310',
            'coingeckoId': 'ureeqa',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'KITTY',
            'name': 'Kitty',
            'address': '0xbca7f1998Dc9FFB70b086543a808960a460aBcA7',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xbca7f1998Dc9FFB70b086543a808960a460aBcA7/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'OKSE',
            'name': 'Okse',
            'address': '0xbc7B0223Dd16cbc679c0D04bA3F4530D76DFbA87',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/27185/thumb/Icon_Okse_copy.png?1662432854',
            'coingeckoId': 'okse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WAVE',
            'name': 'Wave Token',
            'address': '0xbc6f589171d6d66EB44ebCC92dFFb570Db4208da',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xbc6f589171d6d66EB44ebCC92dFFb570Db4208da/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'TVK',
            'name': 'Terra Virtua Kolect',
            'address': '0xbE53F019a8786227E3D258A47a0D96BCf24A09A6',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xbE53F019a8786227E3D258A47a0D96BCf24A09A6/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'BOO',
            'name': 'Spookyswap',
            'address': '0xbD83010eB60F12112908774998F65761cf9f6f9a',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/15223/thumb/logo_200x200.png?1621992076',
            'coingeckoId': 'spookyswap',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'FRACTION',
            'name': 'Own a fraction',
            'address': '0xbD80CFA9d93A87D1bb895f810ea348E496611cD4',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/15099/large/fraction.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'RNDM',
            'name': 'Random',
            'address': '0xbC03e9059B77B770f9aD987b4E3cC8868Af8bD47',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'LODE',
            'name': 'LODE Token',
            'address': '0xbBAAA0420D474B34Be197f95A323C2fF3829E811',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xbBAAA0420D474B34Be197f95A323C2fF3829E811/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'ONG',
            'name': 'onG',
            'address': '0xbAeE145A92A0c7C2FEd63d62d61E9B7eAe0396d9',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xbAeE145A92A0c7C2FEd63d62d61E9B7eAe0396d9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'TBX',
            'name': 'Tokenbox',
            'address': '0xbAb918cfBD3c53Ce1516E6AA97c8342B15c26BcC',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xbAb918cfBD3c53Ce1516E6AA97c8342B15c26BcC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'CREAM',
            'name': 'Cream',
            'address': '0xb9AB39F9b4E3af0c97aE55EA48A960656C681A88',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xb9AB39F9b4E3af0c97aE55EA48A960656C681A88/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'REP',
            'name': 'Reputation',
            'address': '0xb9924372Ddc7e7F13757C8B9ae0F03906a684D65',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xb9924372Ddc7e7F13757C8B9ae0F03906a684D65/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ETHV',
            'name': 'Ethverse Token',
            'address': '0xb98B8Ea9E894Caa5155Da32646152303839890a3',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xb98B8Ea9E894Caa5155Da32646152303839890a3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'RUG',
            'name': 'R U Generous',
            'address': '0xb8EF3a190b68175000B74B4160d325FD5024760e',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/20708/thumb/nnKwMye.png?1637573199',
            'coingeckoId': 'r-u-generous',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'TOMB',
            'name': 'Tomb',
            'address': '0xb84527D59b6Ecb96F433029ECc890D4492C5dCe1',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xb84527D59b6Ecb96F433029ECc890D4492C5dCe1/logo_24.png',
            'coingeckoId': 'tomb',
            'listedIn': [
                'coingecko',
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'KNC',
            'name': 'Kyber Network Crystal',
            'address': '0xb7f7C9347f55d6d6265e152c636cD29aB17Dc9F6',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xb7f7C9347f55d6d6265e152c636cD29aB17Dc9F6/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'LOC',
            'name': 'LockChain',
            'address': '0xb7617ad97645729f41cfd969312532e080f03864',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xb7617ad97645729f41cfd969312532e080f03864/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'CCY',
            'name': 'ChoccySwap',
            'address': '0xb723783e0f9015c8E20b87F6CF7ae24dF6479e62',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/25252/thumb/logo200.png?1651040284',
            'coingeckoId': 'choccyswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AGF',
            'name': 'Augmented Finance',
            'address': '0xb67a9374Da03d4114a6FB8f0E7F2b82b5cB34ee3',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xb67a9374Da03d4114a6FB8f0E7F2b82b5cB34ee3/logo_24.png',
            'coingeckoId': 'augmented-finance',
            'listedIn': [
                'coingecko',
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'UST',
            'name': 'TerraUSD  Wormhole ',
            'address': '0xb599c3590F42f8F995ECfa0f85D2980B76862fc1',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xb599c3590F42f8F995ECfa0f85D2980B76862fc1/logo_24.png',
            'coingeckoId': 'terrausd-wormhole',
            'listedIn': [
                'coingecko',
                'openocean',
                'joe',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'TIME',
            'name': 'Wonderland TIME',
            'address': '0xb54f16fB19478766A268F172C9480f8da1a7c9C3',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x485d17a6f1b8780392d53d64751824253011a260.png',
            'coingeckoId': 'wonderland',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'joe',
                'pangolin',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DUA',
            'name': 'DUA Token',
            'address': '0xb418417374fcA27bb54169D3C777492e6Fe17Ee7',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/27976/thumb/profile-200x200.png?1666776590',
            'coingeckoId': 'dua-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHRO',
            'name': 'Chronicum',
            'address': '0xb4008fcA9c0234153f325BD71e203cdA613Ba81c',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0xb4008fca9c0234153f325bd71e203cda613ba81c/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ARMY',
            'name': 'Army Node Finance',
            'address': '0xb323c7f46f7F1CA0215553E794F7407c2Fa71eeB',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/24089/thumb/IMG-1283.png?1646295839',
            'coingeckoId': 'army-node-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ROCO',
            'name': 'Roco Finance',
            'address': '0xb2a85C5ECea99187A977aC34303b80AcbDdFa208',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xb2a85c5ecea99187a977ac34303b80acbddfa208.png',
            'coingeckoId': 'roco-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'CAP',
            'name': 'Cap',
            'address': '0xb2Fb27f45189F3c621545e5E3aAe668A9B1BDf1d',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xb2Fb27f45189F3c621545e5E3aAe668A9B1BDf1d/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'DONUT',
            'name': 'Donut',
            'address': '0xb2Ef3c8A2b27C219FC3396F0e9320df0E29Ec037',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xb2Ef3c8A2b27C219FC3396F0e9320df0E29Ec037/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ecdPTP',
            'name': 'Echidna PTP',
            'address': '0xb2C5172E5C15aF6aDD1ec92e518A5Ea1c7DeD2ad',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xb2C5172E5C15aF6aDD1ec92e518A5Ea1c7DeD2ad/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'joe',
                'lifinance'
            ]
        },
        {
            'symbol': 'KLO',
            'name': 'Kalao',
            'address': '0xb27c8941a7Df8958A1778c0259f76D1F8B711C35',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xb27c8941a7df8958a1778c0259f76d1f8b711c35.png',
            'coingeckoId': 'kalao',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'joe',
                'pangolin',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'STEAK',
            'name': 'SteakHut Finance',
            'address': '0xb279f8DD152B99Ec1D84A489D32c35bC0C7F5674',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xb279f8DD152B99Ec1D84A489D32c35bC0C7F5674/logo.png',
            'coingeckoId': 'steakhut-finance',
            'listedIn': [
                'coingecko',
                'joe',
                'lifinance'
            ]
        },
        {
            'symbol': 'FXS',
            'name': 'Frax Share',
            'address': '0xb1BA5Cf8EAa4D3c3439e91c190553E9c92e98E30',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xb1BA5Cf8EAa4D3c3439e91c190553E9c92e98E30/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'MAHA',
            'name': 'MahaDAO',
            'address': '0xb0cb6B9C9B47a3974044AE906E8865165D2e0889',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xb0cb6B9C9B47a3974044AE906E8865165D2e0889/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'USV',
            'name': 'Atlas USV',
            'address': '0xb0a8E082E5f8d2a04e74372c1bE47737d85A0E73',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/22066/thumb/7iUyjg5t.png?1640744823',
            'coingeckoId': 'atlas-usv',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'WSDQ',
            'name': 'Wasdaq Finance',
            'address': '0xafc4d521DF3C0566d61931F81f02f1A525Bad04D',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/21268/thumb/Dn1G5rLx_400x400.jpg?1638840878',
            'coingeckoId': 'wasdaq-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LEND',
            'name': 'EthLend Token',
            'address': '0xaeF85E9F467b2Dc187351b37BF63124C0A9bB913',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xaeF85E9F467b2Dc187351b37BF63124C0A9bB913/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'TOK',
            'name': 'Tokenplace',
            'address': '0xae9d2385Ff2E2951Dd4fA061e74c4d3deDD24347',
            'decimals': 8,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xae9d2385Ff2E2951Dd4fA061e74c4d3deDD24347/logo_24.png',
            'coingeckoId': 'tokenplace',
            'listedIn': [
                'coingecko',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'SLD',
            'name': 'SoldierNodes',
            'address': '0xad7476c49D3f82a144f4836aACb9b069c188b759',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/21478/thumb/logo_SoldierNodes_200x200.png?1639351090',
            'coingeckoId': 'soldiernodes',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'FEED',
            'name': 'chikn feed',
            'address': '0xab592d197ACc575D16C3346f4EB70C703F308D1E',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xab592d197acc575d16c3346f4eb70c703f308d1e.png',
            'coingeckoId': 'chikn-feed',
            'listedIn': [
                'coingecko',
                '1inch',
                'joe',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'ABR',
            'name': 'Allbridge',
            'address': '0xaFc43610C7840b20b90CAaF93759bE5b54B291c9',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/18690/thumb/abr.png?1640742053',
            'coingeckoId': 'allbridge',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'BCDT',
            'name': 'Blockchain Certified Data Token',
            'address': '0xaFb2780CBb58b2AF27023Eb2a0e60c8Ca0eEE9bb',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BUSD',
            'name': 'Binance USD',
            'address': '0xaEb044650278731Ef3DC244692AB9F64C78FfaEA',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xaEb044650278731Ef3DC244692AB9F64C78FfaEA/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ASKO',
            'name': 'Askobar Network',
            'address': '0xaDCfCb796ab61Ebcd0676c6B6e13a270Fd55b402',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xaDCfCb796ab61Ebcd0676c6B6e13a270Fd55b402/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ASTRO',
            'name': 'AstroTools.io',
            'address': '0xaDA58D37D13EF5B665C46e474ac4D1AEf12EBdB1',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xaDA58D37D13EF5B665C46e474ac4D1AEf12EBdB1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'FTX',
            'name': 'FintruX Network',
            'address': '0xaD4c2Ba8C983E6A06685b6F90D0c517cD3C4301e',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xaD4c2Ba8C983E6A06685b6F90D0c517cD3C4301e/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'WOO',
            'name': 'WOO Network',
            'address': '0xaBC9547B534519fF73921b1FBA6E672b5f58D083',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1647941920208_5275606528824555.jpg',
            'coingeckoId': 'woo-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'USDS',
            'name': 'SpiceUSD',
            'address': '0xaB05b04743E0aeAF9D2cA81E5D3b8385e4BF961e',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/25697/thumb/USDS.png?1653440948',
            'coingeckoId': 'spiceusd',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'QKC',
            'name': 'QuarkChain Token',
            'address': '0xa9B41c348717F755101189b907F37Ee4ec703E8C',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xa9B41c348717F755101189b907F37Ee4ec703E8C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ROPE',
            'name': 'ROPE',
            'address': '0xa99DFda608D5c9E7f091e857EfB256cEDA48D57e',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xa99DFda608D5c9E7f091e857EfB256cEDA48D57e/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'COT',
            'name': 'CoTrader',
            'address': '0xa8cE5107A2770959edB27529E56E84e11eF55a58',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xa8cE5107A2770959edB27529E56E84e11eF55a58/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'BHC',
            'name': 'Billion Happiness',
            'address': '0xa8752333f6a6fe47323a4eDAC3D09Bb1048A0E23',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BETR',
            'name': 'Better Betting',
            'address': '0xa6c55D876E920e34203072891c720Ac19f425a2B',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xa6c55D876E920e34203072891c720Ac19f425a2B/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SHERPA',
            'name': 'Sherpa',
            'address': '0xa5E59761eBD4436fa4d20E1A27cBa29FB2471Fc6',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xa5E59761eBD4436fa4d20E1A27cBa29FB2471Fc6/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DIP',
            'name': 'Decentralized Insurance Protocol',
            'address': '0xa571971CD50b3c17c9F82f43965319907D50341E',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xa571971CD50b3c17c9F82f43965319907D50341E/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'PNT',
            'name': 'pNetwork Token',
            'address': '0xa4EFc8d7007851CFE5313c02aC2516f33f199364',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xa4EFc8d7007851CFE5313c02aC2516f33f199364/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'MPH',
            'name': '88mph.app',
            'address': '0xa477b670C46fe58cF48708D9519a5E8875a48062',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xa477b670C46fe58cF48708D9519a5E8875a48062/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'BLIZZ',
            'name': 'Blizzard Network',
            'address': '0xa2776A53Da0bf664EA34B8Efa1c8aB4241A10968',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/18446/thumb/blizz.PNG?1632059955',
            'coingeckoId': 'blizzard-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAG',
            'name': 'Baguette',
            'address': '0xa1144a6A1304bd9cbb16c800F7a867508726566E',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xa1144a6A1304bd9cbb16c800F7a867508726566E/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'mooMaiMAI-av3CRV',
            'name': 'Moo Mai MAI-av3CRV',
            'address': '0xFeA7976733f47557860f4483f2147a3e99C76b58',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BFG',
            'name': 'Battle For Giostone',
            'address': '0xFd538CA3f58dC309dA55B11f007ff53fb4602876',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/27715/thumb/BFG-token-logo.00b4b09c.png?1665453193',
            'coingeckoId': 'battle-for-giostone',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'DOMI',
            'name': 'Domi',
            'address': '0xFc6Da929c031162841370af240dEc19099861d3B',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xFc6Da929c031162841370af240dEc19099861d3B/logo.png',
            'coingeckoId': 'domi',
            'listedIn': [
                'coingecko',
                'joe',
                'lifinance'
            ]
        },
        {
            'symbol': 'PHOON',
            'name': 'Typhoon',
            'address': '0xFb59DE6961D7D7D153bb82FCBCc2a7F5Da8Db56D',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xFb59DE6961D7D7D153bb82FCBCc2a7F5Da8Db56D/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SOL',
            'name': 'SOL  Wormhole ',
            'address': '0xFE6B19286885a4F7F55AdAD09C3Cd1f906D2478F',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/22876/thumb/SOL_wh_small.png?1644224316',
            'coingeckoId': 'sol-wormhole',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'VEE',
            'name': 'BLOCKv Token',
            'address': '0xFE017733FF7E4D2AE17C98B2774fB4d5E3EA46DD',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xFE017733FF7E4D2AE17C98B2774fB4d5E3EA46DD/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'YFM',
            'name': 'yfarm.finance',
            'address': '0xFC492B9DC6aDE871c20e9169f2600DFd2718dF4E',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xFC492B9DC6aDE871c20e9169f2600DFd2718dF4E/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'POWER',
            'name': 'UniPower',
            'address': '0xFBD70543a1456ECa6570743256dc2D6E5CE43a2e',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xFBD70543a1456ECa6570743256dc2D6E5CE43a2e/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'X3TA',
            'name': 'XETA',
            'address': '0xFB6523a6799129caF098E8a500A787135A5eaE49',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/26926/thumb/XETA-icon.png?1660834507',
            'coingeckoId': 'xeta',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'zLOT',
            'name': 'zLOT',
            'address': '0xF9F0BB57D247a8c55c463b9a231de7E998bdc9a0',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xF9F0BB57D247a8c55c463b9a231de7E998bdc9a0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SING',
            'name': 'Sing  Avalanche ',
            'address': '0xF9A075C9647e91410bF6C402bDF166e1540f67F0',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/18846/thumb/sing_token.png?1633589686',
            'coingeckoId': 'sing-token-avalanche',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'UNB',
            'name': 'Unbound',
            'address': '0xF915fDDa4c882731C0456a4214548Cd13A822886',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xF915fDDa4c882731C0456a4214548Cd13A822886/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'COMBO',
            'name': 'Furucombo',
            'address': '0xF8fc972343698Ac3C466858ce6CF48bBc83a0852',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xF8fc972343698Ac3C466858ce6CF48bBc83a0852/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'IME',
            'name': 'Imperium Empires',
            'address': '0xF891214fdcF9cDaa5fdC42369eE4F27F226AdaD6',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xf891214fdcf9cdaa5fdc42369ee4f27f226adad6.png',
            'coingeckoId': 'imperium-empires',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'joe',
                'pangolin',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'MOVR',
            'name': 'MoonRiver',
            'address': '0xF873633DF9D5cDd62BB1f402499CC470a72A02D7',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/movr.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'LAYER',
            'name': 'Unilayer',
            'address': '0xF8342EBdc7C4860Fe16eaB3318ddA110305F6597',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xF8342EBdc7C4860Fe16eaB3318ddA110305F6597/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'MUG',
            'name': 'Mu Gold',
            'address': '0xF7ed17f0Fb2B7C9D3DDBc9F0679b2e1098993e81',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/24885/thumb/mugold1-1-.png?1649236701',
            'coingeckoId': 'mu-gold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YYAVAX',
            'name': 'Yield Yak AVAX',
            'address': '0xF7D9281e8e363584973F946201b82ba72C965D27',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/27221/thumb/yyAVAX_Token__200x200.png?1662685308',
            'coingeckoId': 'yield-yak-avax',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAGIK',
            'name': 'MAGIK',
            'address': '0xF7554D17d1c3F09899dCc8B404beCAE6dFA584Fa',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BCS',
            'name': 'BCS',
            'address': '0xF7427e4A97f3c68fEbC6E6a90632c369524db9Ea',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xF7427e4A97f3c68fEbC6E6a90632c369524db9Ea/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'PAN',
            'name': 'Panvala pan',
            'address': '0xF6Ef95FaD0CdddfCCC312679779516107a980E0a',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xF6Ef95FaD0CdddfCCC312679779516107a980E0a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'VCS',
            'name': 'Vacus Finance',
            'address': '0xF6557bC62AC67744961479eA6c131B61444714d6',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/26666/thumb/logo200.png?1659500028',
            'coingeckoId': 'vacus-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DMST',
            'name': 'DMScript',
            'address': '0xF64DBa678d653D5Bb743b1E764De0A11fAb0f0a3',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xF64DBa678d653D5Bb743b1E764De0A11fAb0f0a3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'dYel',
            'name': 'dYEL Index Token',
            'address': '0xF6134E74b7a4ee23A888436E53e77F83e62E8df8',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MINI',
            'name': 'MINISWAP',
            'address': '0xF553b0fA370E11d945D1eDA4267437C9e4C51D8a',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xF553b0fA370E11d945D1eDA4267437C9e4C51D8a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'UFT',
            'name': 'UniLend Finance Token',
            'address': '0xF5182C77B4a5Dd11a59a83FB54aBaf7Dd3099041',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xF5182C77B4a5Dd11a59a83FB54aBaf7Dd3099041/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'MvDOLLAR',
            'name': 'MiniVerse Dollar',
            'address': '0xF47A640FF9745B5591eDd446Cb02eD6d096c99bD',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'START',
            'name': 'Starter xyz',
            'address': '0xF44Fb887334Fa17d2c5c0F970B5D320ab53eD557',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/14301/thumb/logo_poly_sym.png?1624893386',
            'coingeckoId': 'bscstarter',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DDIM',
            'name': 'DuckDaoDime',
            'address': '0xF40920212A74387387328Db8e30726C0cc62ae33',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xF40920212A74387387328Db8e30726C0cc62ae33/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'GG',
            'name': 'GalaxyGoggle',
            'address': '0xF2F7CE610a091B94d41D69f4fF1129434a82E2f0',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1638963262869_334559632639281.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'BRIG',
            'name': 'Brig Finance',
            'address': '0xF2CfC11093edB5A2DC7F49e70A3a3a9cd4F4FeE4',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0xf2cfc11093edb5a2dc7f49e70a3a3a9cd4f4fee4/logo.png',
            'coingeckoId': 'brig-finance',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'VIKING',
            'name': 'AVAX-Viking',
            'address': '0xF1b0F6DF4fc3710b3497c34B0Ee366099054add8',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0xf1b0f6df4fc3710b3497c34b0ee366099054add8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'NXUSD',
            'name': 'NXUSD',
            'address': '0xF14f4CE569cB3679E99d5059909E23B07bd2F387',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/25101/thumb/nxusd-logo.png?1650286304',
            'coingeckoId': 'nxusd',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'USDTSO',
            'name': 'Tether USD  Wormhole ',
            'address': '0xF0FF231e3F1A50F83136717f287ADAB862f89431',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/22881/thumb/USDTso_wh_small.png?1644223113',
            'coingeckoId': 'tether-usd-wormhole',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GBOND',
            'name': 'GBOND',
            'address': '0xF0C918B2a27746afc863D32a9A07B5cad3A0Ef42',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'KP4R',
            'name': 'Keep4r',
            'address': '0xEeB395dEc67742cCF7E6Aea920DC2b7FCF01e725',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xEeB395dEc67742cCF7E6Aea920DC2b7FCF01e725/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'O3',
            'name': 'O3 Swap',
            'address': '0xEe9801669C6138E84bD50dEB500827b776777d28',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/15460/thumb/o3.png?1620904316',
            'coingeckoId': 'o3-swap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADX',
            'name': 'AdEx Network',
            'address': '0xEdd6ce14626B228D90aF0fB126a432e4b2174844',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xEdd6ce14626B228D90aF0fB126a432e4b2174844/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'HON',
            'name': 'Heroes of NFT',
            'address': '0xEd2b42D3C9c6E97e11755BB37df29B6375ede3EB',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xEd2b42D3C9c6E97e11755BB37df29B6375ede3EB/logo_24.png',
            'coingeckoId': 'heroes-of-nft',
            'listedIn': [
                'coingecko',
                'openocean',
                'joe',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'NRGY',
            'name': 'ENERGY',
            'address': '0xEcC5C2015a9A5320bcBB08EE445D2Cf374F3AC14',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/23997/thumb/nrgy.png?1645956004',
            'coingeckoId': 'energy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REZ',
            'name': 'REZ',
            'address': '0xEba910B7C5A4302f80286062DD3c737d36FcFa05',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/7332.png',
            'coingeckoId': null,
            'listedIn': [
                'RouterProtocol'
            ]
        },
        {
            'symbol': 'COL',
            'name': 'COL',
            'address': '0xEFb603A7844f11D17aDA15B63e3A876AB443372C',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xEFb603A7844f11D17aDA15B63e3A876AB443372C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': '5TABLE',
            'name': '5table Token',
            'address': '0xEF3a28035fE545cB4c86e110f1B72e3fC6a21206',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BLY',
            'name': 'Blocery Token',
            'address': '0xEEE72b81fc4cf5A4bf9dC10c1d12F73C440ff7e9',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xEEE72b81fc4cf5A4bf9dC10c1d12F73C440ff7e9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'DOGEALLY',
            'name': 'Doge Alliance',
            'address': '0xECeE7B9B7aa9efF9f89f1fE7fC77a2e5f6e491eA',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/21545/thumb/dogealliance_sq_x200.png?1639455993',
            'coingeckoId': 'doge-alliance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FXC',
            'name': 'Flexacoin',
            'address': '0xE9C418927B36F2668dC4fe02028Aa08535a8E347',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xE9C418927B36F2668dC4fe02028Aa08535a8E347/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SWAG',
            'name': 'Swag Token',
            'address': '0xE8ddE6E36ae86E3c61Dc13DFf908B4a12d50F754',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xE8ddE6E36ae86E3c61Dc13DFf908B4a12d50F754/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'wsSQUID',
            'name': 'Wrapped sSQUID',
            'address': '0xE87d84DcBb848fe94C6dAEdc9c81C913F707F2D2',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'VSP',
            'name': 'VesperToken',
            'address': '0xE70EFb1ff1C0b63FDF84B5EA319693cf804914A8',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://static.debank.com/image/avax_token/logo_url/0xe70efb1ff1c0b63fdf84b5ea319693cf804914a8/c3e8d6c1c1396118b0c8168ae7626b1c.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'XSLR',
            'name': 'NovaXSolar',
            'address': '0xE6eE049183B474ecf7704da3F6F555a1dCAF240F',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/19729/thumb/xslr.png?1635767231',
            'coingeckoId': 'novaxsolar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANGLE',
            'name': 'ANGLE',
            'address': '0xE6B9d092223f39013656702A40dbE6B7DeCc5746',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xE6B9d092223f39013656702A40dbE6B7DeCc5746/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'FRM',
            'name': 'Ferrum Network',
            'address': '0xE5CAeF4Af8780E59Df925470b050Fb23C43CA68C',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/8251/thumb/FRM.png?1658819429',
            'coingeckoId': 'ferrum-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '1INCH',
            'name': '1INCH Token',
            'address': '0xE54EB2C3009Fa411BF24fB017F9725b973CE36F0',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xE54EB2C3009Fa411BF24fB017F9725b973CE36F0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'VRYNT',
            'name': 'VRYNT',
            'address': '0xE457c5Fe85d0563B2CA389354Da307516a8EDD87',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/25887/thumb/vrynt.png?1654416392',
            'coingeckoId': 'vrynt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YFL',
            'name': 'YFLink',
            'address': '0xE3a13E41eCAdcc611a5D8415c2b8C0802197bA96',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xE3a13E41eCAdcc611a5D8415c2b8C0802197bA96/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'NEIBR',
            'name': 'The Neighbours',
            'address': '0xE337a99fE0AC0F3E01A97167D21082A2Bb96326E',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/24929/thumb/TheNeighboursLogo-color_nbyeag.png?1649389492',
            'coingeckoId': 'the-neighbours',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VIDT',
            'name': 'VIDT Datalink',
            'address': '0xE1Df06E09531aeD339Cf6C97bEb59De94675d5a8',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xE1Df06E09531aeD339Cf6C97bEb59De94675d5a8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'lELK',
            'name': 'Elk Legacy',
            'address': '0xE1C8f3d529BEa8E3fA1FAC5B416335a2f998EE1C',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xE1C8f3d529BEa8E3fA1FAC5B416335a2f998EE1C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ELK',
            'name': 'Elk',
            'address': '0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xe1c110e1b1b4a1ded0caf3e42bfbdbb7b5d7ce1c.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'joe',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ICY',
            'name': 'ICY MONEY',
            'address': '0xE18950c8F3b01f549cFc79dC44C3944FBd43fB76',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/21921/thumb/MDtozAk.png?1640266762',
            'coingeckoId': 'icy-money',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'SPLASH',
            'name': 'Splash Token',
            'address': '0xE16253892F126D068E711C2fdde6DB56969dBCf6',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x4ec58f9D205F9c919920313932cc71EC68d123C7/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'POLS',
            'name': 'PolkastarterToken',
            'address': '0xE1463E8991c8A62e64b77b5Fb6B22F190344C2A9',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xE1463E8991c8A62e64b77b5Fb6B22F190344C2A9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'EZ',
            'name': 'Easy',
            'address': '0xE0ddA2fc4f4750276641BE3a82634fa5Dd956EDE',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/7332.png',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'RouterProtocol'
            ]
        },
        {
            'symbol': 'Yf-DAI',
            'name': 'YfDAI.finance',
            'address': '0xE09CD46BBE2144b53C6265c2D1B3d01cfDadB786',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xE09CD46BBE2144b53C6265c2D1B3d01cfDadB786/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'COVER',
            'name': 'Cover Protocol',
            'address': '0xE0976dCa075C4055cADa33C2452429572885aE7E',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xE0976dCa075C4055cADa33C2452429572885aE7E/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'AKITAX',
            'name': 'Akitavax',
            'address': '0xE06fba763C2104dB5027F57f6A5Be0a0D86308af',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xE06fba763C2104dB5027F57f6A5Be0a0D86308af/logo_24.png',
            'coingeckoId': 'akitavax',
            'listedIn': [
                'coingecko',
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'GEM',
            'name': 'NFTmall',
            'address': '0xDebB1d6a2196F2335aD51FBdE7Ca587205889360',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/16217/thumb/Icon-1000x1000.png?1623316471',
            'coingeckoId': 'nftmall',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'LSHARE',
            'name': 'LSHARE',
            'address': '0xDe2f7E3C7fbf2d1A68FB7F64ACfc05E6D5D7dD83',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DUNE',
            'name': 'Frost-Finance',
            'address': '0xDd57fA6A38326b5c040a6bFce7A0a7C65588175A',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xDd57fA6A38326b5c040a6bFce7A0a7C65588175A/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ZABU',
            'name': 'Zabu Token',
            'address': '0xDd453dBD253fA4E5e745047d93667Ce9DA93bbCF',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xDd453dBD253fA4E5e745047d93667Ce9DA93bbCF/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'avWAVAX',
            'name': 'Aave Avalanche Market WAVAX',
            'address': '0xDFE521292EcE2A4f44242efBcD66Bc594CA9714B',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xdfe521292ece2a4f44242efbcd66bc594ca9714b.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'PLAGUE',
            'name': 'PlagueDao.com',
            'address': '0xDF9E874894196919f7B2bCae336463103DEA0E3D',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0xdf9e874894196919f7b2bcae336463103dea0e3d/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'CVP',
            'name': 'Concentrated Voting Power',
            'address': '0xDF9A6628235C90da0a475519D85C9CFFa2a11D4C',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xDF9A6628235C90da0a475519D85C9CFFa2a11D4C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SPI',
            'name': 'Shopping.io',
            'address': '0xDCd9b9b00a7bA3afb6E8F5058945a1A946810D29',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xDCd9b9b00a7bA3afb6E8F5058945a1A946810D29/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'LOCK',
            'name': 'Meridian Network',
            'address': '0xDC59a3AC96dc1e86bB93Ed0248fB4bA6127BA64c',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xDC59a3AC96dc1e86bB93Ed0248fB4bA6127BA64c/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'FRAX',
            'name': 'Frax',
            'address': '0xDC42728B0eA910349ed3c6e1c9Dc06b5FB591f98',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xDC42728B0eA910349ed3c6e1c9Dc06b5FB591f98/logo.png',
            'coingeckoId': 'frax',
            'listedIn': [
                'rubic',
                'elkfinance'
            ]
        },
        {
            'symbol': 'renBTC',
            'name': 'renBTC',
            'address': '0xDBf31dF14B66535aF65AaC99C32e9eA844e14501',
            'decimals': 8,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xeb4c2781e4eba804ce9a9803c67d0893436bb27d.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'PICKLE',
            'name': 'PickleToken',
            'address': '0xD9eD8258c3ECBB5E4ECf3b91dc0ca693e80934CC',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xD9eD8258c3ECBB5E4ECf3b91dc0ca693e80934CC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'FUDGE',
            'name': 'Fudge',
            'address': '0xD9FF12172803c072a36785DeFea1Aa981A6A0C18',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/26908/thumb/Sundae_finance_fudge_.png?1660732346',
            'coingeckoId': 'fudge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORAI',
            'name': 'Oraichain Token',
            'address': '0xD9A0B28305951758b9CdBbf7e18CC85B868f973C',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xD9A0B28305951758b9CdBbf7e18CC85B868f973C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'EMBR',
            'name': 'EmbrToken',
            'address': '0xD81D45E7635400dDD9c028839e9a9eF479006B28',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1646218584742_47622400801306086.jpg',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'WHIRL',
            'name': 'OmniWhirl',
            'address': '0xD806a4a473E44578796898A4c546f575D7247b2B',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/17165/thumb/logo-200x.png?1626683241',
            'coingeckoId': 'omniwhirl',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'DSLA',
            'name': 'DSLA Protocol',
            'address': '0xD7c295E399CA928A3a14b01D760E794f1AdF8990',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/6694/thumb/dsla_logo-squared_200x200.png?1569571063',
            'coingeckoId': 'stacktical',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEV',
            'name': 'Dev',
            'address': '0xD7c26758CA255fb1d7559B02Ff36295be61f6822',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xD7c26758CA255fb1d7559B02Ff36295be61f6822/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ETHRSIAPY',
            'name': 'ETH RSI 60/40 Yield II',
            'address': '0xD7b63a3Cf593E72c385A72e28029Bcae6db766D5',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xD7b63a3Cf593E72c385A72e28029Bcae6db766D5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'CHART',
            'name': 'ChartEx',
            'address': '0xD769bDFc0CaEe933dc0a047C7dBad2Ec42CFb3E2',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xD769bDFc0CaEe933dc0a047C7dBad2Ec42CFb3E2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'MONG',
            'name': 'Mongoose',
            'address': '0xD74CCA216F7c8Fe33f0911da8698E2B3eFc98835',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0xd74cca216f7c8fe33f0911da8698e2b3efc98835/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'STMX',
            'name': 'StormX',
            'address': '0xD6C7f1C0b553B820739A9bEFa30e1A4990DB67dC',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xD6C7f1C0b553B820739A9bEFa30e1A4990DB67dC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'FXS',
            'name': 'Frax Share',
            'address': '0xD67de0e0a0Fd7b15dC8348Bb9BE742F3c5850454',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xD67de0e0a0Fd7b15dC8348Bb9BE742F3c5850454/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'LVT',
            'name': 'Louverture V2',
            'address': '0xD641E62525e830e98Cb9D7D033a538A1f092ff34',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/21634/thumb/gybjAKy.png?1639627383',
            'coingeckoId': 'louverture',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BONDLY',
            'name': 'Bondly Token',
            'address': '0xD61B293AEFb71f9B83670133d1FAad8487567a53',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xD61B293AEFb71f9B83670133d1FAad8487567a53/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'GDL',
            'name': 'Gondola Finance',
            'address': '0xD606199557c8Ab6F4Cc70bD03FaCc96ca576f142',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xD606199557c8Ab6F4Cc70bD03FaCc96ca576f142/logo.png',
            'coingeckoId': 'gondola-finance',
            'listedIn': [
                'coingecko',
                'pangolin',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PLR',
            'name': 'PILLAR',
            'address': '0xD523c90aCb4415A48e1504BaAd3a2Aa8fd86dEF9',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xD523c90aCb4415A48e1504BaAd3a2Aa8fd86dEF9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'DEP',
            'name': 'DEAPCOIN',
            'address': '0xD4d026322C88C2d49942A75DfF920FCfbC5614C1',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xD4d026322C88C2d49942A75DfF920FCfbC5614C1/logo_24.png',
            'coingeckoId': 'deapcoin',
            'listedIn': [
                'coingecko',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'avAAVE',
            'name': 'Aave Avalanche Market AAVE',
            'address': '0xD45B7c061016102f9FA220502908f2c0f1add1D7',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xffc97d72e13e01096502cb8eb52dee56f74dad7b.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MCB',
            'name': 'MCDEX Token',
            'address': '0xD4355F4608277a616111B35A77E6C58F4B4B69c6',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xD4355F4608277a616111B35A77E6C58F4B4B69c6/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'wANATHA',
            'name': 'Wrapped ANATHA',
            'address': '0xD3e6c7E9Dc08173276A17d23546cb9C24D7cD7b0',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xD3e6c7E9Dc08173276A17d23546cb9C24D7cD7b0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'PRICES',
            'name': 'PRICES',
            'address': '0xD39Fe405c2Edb420f050EE6534Acb888E09AcdA6',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0xd39fe405c2edb420f050ee6534acb888e09acda6/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': '0xB',
            'name': '0xBlock',
            'address': '0xD2ad73Ce020911A4C04c284bfd2d451b4A777BDB',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0xd2ad73ce020911a4c04c284bfd2d451b4a777bdb/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FRAX',
            'name': 'Frax',
            'address': '0xD24C2Ad096400B6FBcd2ad8B24E7acBc21A1da64',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x853d955acef822db058eb8505911ed77f175b99e.png',
            'coingeckoId': 'frax',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pangolin',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'MEAD',
            'name': 'Mead',
            'address': '0xD21CdCA47Fa45A0A51eec030E27AF390ab3aa489',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/24400/thumb/mead.png?1647699187',
            'coingeckoId': 'mead',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SLUSH',
            'name': 'Slushie Capital',
            'address': '0xD197c8B939F199c7d70d030c1931d3C24442dF60',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0xd197c8b939f199c7d70d030c1931d3c24442df60/logo.png',
            'coingeckoId': 'slushie-capital',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'TNGL',
            'name': 'Tangle',
            'address': '0xD17584633bc8D190E5A14502976daD9640456D6d',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/18312/thumb/tangle.PNG?1631510159',
            'coingeckoId': 'tangle',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNC',
            'name': 'UniCrypt',
            'address': '0xD084b89B8f04f3E2360EBd600360C358aA122BfB',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xD084b89B8f04f3E2360EBd600360C358aA122BfB/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'MU',
            'name': 'Mu Coin',
            'address': '0xD036414fa2BCBb802691491E323BFf1348C5F4Ba',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/24884/thumb/coingeckomucoin.png?1649236392',
            'coingeckoId': 'mu-coin',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ESH',
            'name': 'Switch',
            'address': '0xCff97feeC839C59BA94FA453D47263a9Cf4d4C28',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xCff97feeC839C59BA94FA453D47263a9Cf4d4C28/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'STRONG',
            'name': 'Strong',
            'address': '0xCf68248eeF35b725512724178Da55ad7DB59A5F1',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xCf68248eeF35b725512724178Da55ad7DB59A5F1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'BRIBE',
            'name': 'BRIBE Token',
            'address': '0xCe2fbed816E320258161CeD52c2d0CEBcdFd8136',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xCe2fbed816E320258161CeD52c2d0CEBcdFd8136/logo_24.png',
            'coingeckoId': 'bribe-token-2',
            'listedIn': [
                'coingecko',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'ENJ',
            'name': 'Enjin Coin',
            'address': '0xCde255522146ddF36d57BA5Cd8D74695bD13C994',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xCde255522146ddF36d57BA5Cd8D74695bD13C994/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'CHAOS',
            'name': 'Chaos',
            'address': '0xCd17D12dfD16051b5d2895E4dFC72AfD82DBB529',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/26541/thumb/KPyq4RWO_400x400.jpg?1658732888',
            'coingeckoId': 'chaos',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'iBFR',
            'name': 'iBuffer Token',
            'address': '0xCaf870DaD882b00F4b20D714Bbf7fceADA5E4195',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'GAU',
            'name': 'Gamer Arena',
            'address': '0xCa8EBfB8e1460Aaac7c272CB9053B3D42412AAc2',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/28151/thumb/200x200.png?1668150505',
            'coingeckoId': 'gamer-arena',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANTG',
            'name': 'AntGold',
            'address': '0xCa1068444196cdfE676Fd15A29F35e502580A69E',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/24320/thumb/200x200.png?1647355899',
            'coingeckoId': 'antgold',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'SPELL',
            'name': 'Spell',
            'address': '0xCE1bFFBD5374Dac86a2893119683F4911a2F7814',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x090185f2135308bad17527004364ebcc2d37e5f6.png',
            'coingeckoId': 'spell-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'joe',
                'pangolin',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HT',
            'name': 'Huobi',
            'address': '0xCDEB5641dC5BF05845317B00643A713CCC3b22e6',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/ht.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SHIP',
            'name': 'ShipChain SHIP',
            'address': '0xCCD9a2fa0A31506E5D881981B702e9476feFAE56',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xCCD9a2fa0A31506E5D881981B702e9476feFAE56/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SMRT',
            'name': 'SmartCoin',
            'address': '0xCC2f1d827b18321254223dF4e84dE399D9Ff116c',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1634806070088_48027228841072933.jpeg',
            'coingeckoId': 'smartcoin-2',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'AMN',
            'name': 'Amon',
            'address': '0xC95F62A06BA7Fb11389474EE1d4aA606A2A0D125',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xC95F62A06BA7Fb11389474EE1d4aA606A2A0D125/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ABLOCK',
            'name': 'ANY Blocknet',
            'address': '0xC931f61B1534EB21D8c11B24f3f5Ab2471d4aB50',
            'decimals': 8,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xC931f61B1534EB21D8c11B24f3f5Ab2471d4aB50/logo_24.png',
            'coingeckoId': 'any-blocknet',
            'listedIn': [
                'coingecko',
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ZRX',
            'name': '0x Protocol Token',
            'address': '0xC8E94215b75F5B9c3b5fB041eC3A97B7D17a37Ff',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xC8E94215b75F5B9c3b5fB041eC3A97B7D17a37Ff/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'UMA',
            'name': 'UMA Voting Token v1',
            'address': '0xC84d7bfF2555955b44BDF6A307180810412D751B',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xC84d7bfF2555955b44BDF6A307180810412D751B/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'BIRD',
            'name': 'Bird.Money',
            'address': '0xC83F0172352692A4481dBf07Ddd9F0e3dC5c70D1',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xC83F0172352692A4481dBf07Ddd9F0e3dC5c70D1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'JUL',
            'name': 'JUL',
            'address': '0xC839E0D590BBb1b64A46A3F6aB6Feb596ced7439',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xC839E0D590BBb1b64A46A3F6aB6Feb596ced7439/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'HEC',
            'name': 'HeroesChained',
            'address': '0xC7f4debC8072e23fe9259A5C0398326d8EfB7f5c',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/22813/small/logo_-_2022-01-20T140628.062.png?1642658814',
            'coingeckoId': 'heroeschained',
            'listedIn': [
                'coingecko',
                'openocean',
                'pangolin',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'EVO',
            'name': 'Evolution',
            'address': '0xC636AD16dd87C2D412d2c62276813dFC35558A81',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xC636AD16dd87C2D412d2c62276813dFC35558A81/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'WINE',
            'name': 'Wine Shares',
            'address': '0xC55036B5348CfB45a932481744645985010d3A44',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xC55036B5348CfB45a932481744645985010d3A44/logo.png',
            'coingeckoId': 'wine-shares',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'TLN',
            'name': 'Trustlines Network Token',
            'address': '0xC394FBa894e6cD201478BC0F2EF121Acb3182EB4',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xC394FBa894e6cD201478BC0F2EF121Acb3182EB4/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SNOB',
            'name': 'Snowball',
            'address': '0xC38f41A296A4493Ff429F1238e030924A1542e50',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xc38f41a296a4493ff429f1238e030924a1542e50.png',
            'coingeckoId': 'snowball-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pangolin',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': '0xMR',
            'name': '0xMonero',
            'address': '0xC309fd43f845A46AA2A4C75459b076543C6E9F4a',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xC309fd43f845A46AA2A4C75459b076543C6E9F4a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'NEIBR',
            'name': 'TheNeighbours',
            'address': '0xC250e12c5DAb88F0d3ece052986009E459ad123a',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0xc250e12c5dab88f0d3ece052986009e459ad123a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SHAKE',
            'name': 'Spaceswap SHAKE',
            'address': '0xC1d02E488a9Ce2481BFdcd797d5373Dd2E70a9C2',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xC1d02E488a9Ce2481BFdcd797d5373Dd2E70a9C2/logo_24.png',
            'coingeckoId': 'spaceswap-shake',
            'listedIn': [
                'coingecko',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'SDT',
            'name': 'Stake DAO Token',
            'address': '0xC19CD5D80B52118A99B23941Eaf1bb58Bb79f1fa',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xC19CD5D80B52118A99B23941Eaf1bb58Bb79f1fa/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'RISE',
            'name': 'EverRise',
            'address': '0xC17c30e98541188614dF99239cABD40280810cA3',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/16367/thumb/Logo_EverRise_Icon_logo.png?1642576670',
            'coingeckoId': 'everrise',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALBT',
            'name': 'AllianceBlock Token',
            'address': '0xC0c9b6714a482AAD7b11327cCf4d7a0545A828a5',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xC0c9b6714a482AAD7b11327cCf4d7a0545A828a5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'XEN',
            'name': 'XEN Crypto',
            'address': '0xC0C5AA69Dbe4d6DDdfBc89c0957686ec60F24389',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1667531785629_4504213763629119.png',
            'coingeckoId': 'xen-crypto',
            'listedIn': [
                'coingecko',
                'openocean'
            ]
        },
        {
            'symbol': 'OCTO',
            'name': 'Octo.fi',
            'address': '0xC0735F8b43B6879FED7070044211bFcd9C3d633B',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xC0735F8b43B6879FED7070044211bFcd9C3d633B/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'BRIDGE',
            'name': 'Cross Chain Bridge',
            'address': '0xC0367f9b1f84Ca8DE127226AC2A994EA4bf1e41b',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/20223/thumb/0x92868A5255C628dA08F550a858A802f5351C5223.png?1636684446',
            'coingeckoId': 'cross-chain-bridge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'API3',
            'name': 'API3',
            'address': '0xBf853B96F95Fae6883E9cBC813B4021FCcF1eED4',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xBf853B96F95Fae6883E9cBC813B4021FCcF1eED4/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SNX',
            'name': 'Synthetix Network',
            'address': '0xBeC243C995409E6520D7C41E404da5dEba4b209B',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/3406/thumb/SNX.png?1598631139',
            'coingeckoId': 'havven',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'REPv2',
            'name': 'Reputation',
            'address': '0xBe7AFAa2833d7F461D8751f1f46bF259fc4459C6',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xBe7AFAa2833d7F461D8751f1f46bF259fc4459C6/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'PAY',
            'name': 'TenX Pay Token',
            'address': '0xBdFc6443428DEcf3cCDc7472DF5d96c2FA8C2E70',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xBdFc6443428DEcf3cCDc7472DF5d96c2FA8C2E70/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'PLT',
            'name': 'PlutusDeFi',
            'address': '0xBc6e632244FD9a79e863B87841FFD9962B725895',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xBc6e632244FD9a79e863B87841FFD9962B725895/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'GOLD',
            'name': 'GOLD',
            'address': '0xBEf8F941F0b9FF97355435974fa2a124b647C8e1',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0xbef8f941f0b9ff97355435974fa2a124b647c8e1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'AVAXx',
            'name': 'Super AVAX',
            'address': '0xBE916845D8678b5d2F7aD79525A62D7c08ABba7e',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://static.debank.com/image/avax_token/logo_url/avax/0b9c84359c84d6bdd5bfda9c2d4c4a82.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'IDYP',
            'name': 'iDeFiYieldProtocol',
            'address': '0xBD100d061E120b2c67A24453CF6368E63f1Be056',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xbd100d061e120b2c67a24453cf6368e63f1be056.png',
            'coingeckoId': 'idefiyieldprotocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'QRX',
            'name': 'QuiverX',
            'address': '0xBB9a99de392Fb34d9f4F59c2b7Ea72cE7f1570e4',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xBB9a99de392Fb34d9f4F59c2b7Ea72cE7f1570e4/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'FRAX',
            'name': 'Frax',
            'address': '0xBB69c92FBb4F1aFf528875056650c862F94D3CC1',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xBB69c92FBb4F1aFf528875056650c862F94D3CC1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'GRO',
            'name': 'Growth',
            'address': '0xBAd7b06c436200dB693Dd49418A96E2bF857f9a2',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xBAd7b06c436200dB693Dd49418A96E2bF857f9a2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'GNO',
            'name': 'Gnosis Token',
            'address': '0xBAA66822055AD37EC05638eC5AAfDC6Ef0e96445',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xBAA66822055AD37EC05638eC5AAfDC6Ef0e96445/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'IND',
            'name': 'Indorse Token',
            'address': '0xB9d0574a8049e5fd4331ACDc1CF3ce2FF3261bE9',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xB9d0574a8049e5fd4331ACDc1CF3ce2FF3261bE9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'LID',
            'name': 'Liquidity Dividends Protocol',
            'address': '0xB9b00fbac0F8B7c25a360664CE0Bf819771B4144',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xB9b00fbac0F8B7c25a360664CE0Bf819771B4144/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USD Coin',
            'address': '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png',
            'coingeckoId': 'usd-coin',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'joe',
                'pangolin',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'NADO',
            'name': 'Nado',
            'address': '0xB80323c7aA915CB960b19B5cCa1D88a2132F7BD1',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0xb80323c7aa915cb960b19b5cca1d88a2132f7bd1/logo.png',
            'coingeckoId': 'tornadao',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'WINGS',
            'name': 'WINGS',
            'address': '0xB6F1A43ccc654026DEAd1b566b948dBFBa23f75A',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xB6F1A43ccc654026DEAd1b566b948dBFBa23f75A/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'a.BEE',
            'name': 'HoneyBee',
            'address': '0xB669c71431bc4372140bC35Aa1962C4B980bA507',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0xb669c71431bc4372140bc35aa1962c4b980ba507/logo.png',
            'coingeckoId': 'avalanche-honeybee',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'AFINS',
            'name': 'altFINS',
            'address': '0xB648fA7A5f5ED3b3c743140346E3Dc3Fe94a9125',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/26280/thumb/AFINS_token_logo_200x200.png?1657017692',
            'coingeckoId': 'altfins',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'USDD',
            'name': 'USDD',
            'address': '0xB514CABD09eF5B169eD3fe0FA8DBd590741E81C2',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/25380/thumb/UUSD.jpg?1651823371',
            'coingeckoId': 'usdd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANY',
            'name': 'Anyswap',
            'address': '0xB44a9B6905aF7c801311e8F4E76932ee959c663C',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/12242/thumb/anyswap.jpg?1598443880',
            'coingeckoId': 'anyswap',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'KP3R',
            'name': 'Keep3rV1',
            'address': '0xB42F2c83b4ee3C3620789B5603f4bdf01792e0a0',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xB42F2c83b4ee3C3620789B5603f4bdf01792e0a0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'BTCBR',
            'name': 'Bitcoin BR',
            'address': '0xB355f4F4CC84a9429a59d5c2B98d77016f7EC482',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/21205/thumb/btcbr.png?1661666691',
            'coingeckoId': 'bitcoin-br',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WAVAX',
            'name': 'Wrapped AVAX',
            'address': '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7.png',
            'coingeckoId': 'wrapped-avax',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'joe',
                'pangolin',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BTE',
            'name': 'Betero',
            'address': '0xB25e20De2F2eBb4CfFD4D16a55C7B395e8a94762',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'HERMES',
            'name': 'HERMES',
            'address': '0xB15f02F9Da8CD1f99E9dd375F21dc96D25ddd82C',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xB15f02F9Da8CD1f99E9dd375F21dc96D25ddd82C/logo_24.png',
            'coingeckoId': 'hermes',
            'listedIn': [
                'coingecko',
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'BLIZZ',
            'name': 'Blizzard',
            'address': '0xB147656604217a03Fe2c73c4838770DF8d9D21B8',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0xb147656604217a03fe2c73c4838770df8d9d21b8/logo.png',
            'coingeckoId': 'blizzard-network',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'WET',
            'name': 'Weble Ecosystem',
            'address': '0xB1466d4cf0DCfC0bCdDcf3500F473cdACb88b56D',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xb1466d4cf0dcfc0bcddcf3500f473cdacb88b56d.png',
            'coingeckoId': 'weble-ecosystem-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'pangolin',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ODDZ',
            'name': 'Oddz',
            'address': '0xB0a6e056B587D0a85640b39b1cB44086F7a26A1E',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xB0a6e056B587D0a85640b39b1cB44086F7a26A1E/logo_24.png',
            'coingeckoId': 'oddz',
            'listedIn': [
                'coingecko',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'BOG',
            'name': 'Bogged Finance',
            'address': '0xB09FE1613fE03E7361319d2a43eDc17422f36B09',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/15980/thumb/bog.png?1638183823',
            'coingeckoId': 'bogged-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REVO',
            'name': 'REVO',
            'address': '0xB065a867a1baa919F0A9a3F5C1543D19768CeFBD',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'RouterProtocol'
            ]
        },
        {
            'symbol': 'BOOFI',
            'name': 'Boo Finance',
            'address': '0xB00F1ad977a949a3CCc389Ca1D1282A2946963b0',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xb00f1ad977a949a3ccc389ca1d1282a2946963b0.png',
            'coingeckoId': 'boo-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'DFIAT',
            'name': 'DeFiato',
            'address': '0xAfE3d2A31231230875DEe1fa1eEF14a412443d22',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xAfE3d2A31231230875DEe1fa1eEF14a412443d22/logo.png',
            'coingeckoId': 'defiato',
            'listedIn': [
                'coingecko',
                'joe',
                'lifinance'
            ]
        },
        {
            'symbol': 'BIG',
            'name': 'BIG Token',
            'address': '0xAfAEb84415c1cd2bC99cd1F5F0B3090baE1BEb6c',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AUC',
            'name': 'Auctus Token',
            'address': '0xAeaDfDc09c284E848aeBA876FF086Ed06A95B4b2',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xAeaDfDc09c284E848aeBA876FF086Ed06A95B4b2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SPANK',
            'name': 'SPANK',
            'address': '0xAdcE0b08127EFd11d4A6CDAA82feDe77b0Fa57F9',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xAdcE0b08127EFd11d4A6CDAA82feDe77b0Fa57F9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ELE',
            'name': 'Eleven Finance',
            'address': '0xAcD7B3D9c10e97d0efA418903C0c7669E702E4C0',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xAcD7B3D9c10e97d0efA418903C0c7669E702E4C0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'RMAI',
            'name': 'RMAI',
            'address': '0xAc6C66aCF2B25448fF469894a061983050D73768',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'RouterProtocol'
            ]
        },
        {
            'symbol': 'REN',
            'name': 'Republic Token',
            'address': '0xAc6C38f2DeC391b478144Ae7F078D08B08d0a284',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xAc6C38f2DeC391b478144Ae7F078D08B08d0a284/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SUSD',
            'name': 'Stabilize USD',
            'address': '0xAafd2577Fb67366d3C89DB0d627C49D769ee2e5D',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/19754/thumb/SUSD_200.png?1635824557',
            'coingeckoId': 'stabilize-usd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'THO',
            'name': 'Thorus',
            'address': '0xAE4AA155D2987B454C29450ef4f862CF00907B61',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xAE4AA155D2987B454C29450ef4f862CF00907B61/logo.png',
            'coingeckoId': 'thorus',
            'listedIn': [
                'coingecko',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CREAM',
            'name': 'IceCream Finance',
            'address': '0xAE21d31a6494829a9E4B2B291F4984AAE8121757',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/23295/thumb/neWvicW.png?1645776713',
            'coingeckoId': 'icecream-finance',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'OWL',
            'name': 'Owloper Owl',
            'address': '0xAB547A8Dc764408Ce9DEA41bDf689aeEB349Da12',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/26453/thumb/Jq6a3dLK_400x400.jpeg?1658114178',
            'coingeckoId': 'owloper',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AURORA',
            'name': 'Aurora',
            'address': '0xA9D633A1ac5Aa995C5e4F7d915ADe1Be565A97e8',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0xa9d633a1ac5aa995c5e4f7d915ade1be565a97e8/logo.png',
            'coingeckoId': 'arctic-finance',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'eQUAD',
            'name': 'QuadrantProtocol',
            'address': '0xA8990B4FA2ba67f3B14814Be106B88f251397D3F',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xA8990B4FA2ba67f3B14814Be106B88f251397D3F/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'wsHUMP',
            'name': 'Wrapped sHUMP',
            'address': '0xA873e87C2C935fa11c72003231a2EEe7d391CE5f',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USD Coin Avalanche Bridged  USDC e ',
            'address': '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png',
            'coingeckoId': 'usd-coin-avalanche-bridged-usdc-e',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'joe',
                'pangolin',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GHOST',
            'name': 'SpiritDAO Ghost',
            'address': '0xA72eAb7327e48bdE0FF873eAF1D4FecF6036504F',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/21888/thumb/444z.png?1640226990',
            'coingeckoId': 'spiritdao-ghost',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SNT',
            'name': 'Supernova Token',
            'address': '0xA6ad0EF910CA4C54A97CEcF82bf5892c0fA4913b',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'STM',
            'name': 'Streamity',
            'address': '0xA62CBCB4F5485Ee6EF6B44083c561f9E1f2B740a',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xA62CBCB4F5485Ee6EF6B44083c561f9E1f2B740a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'JOEx',
            'name': 'Superfluid JOE',
            'address': '0xA60C5BeBCcDb9738f63891bBDD7FeC3e762f9098',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://static.debank.com/image/avax_token/logo_url/0x6e84a6216ea6dacc71ee8e6b0a5b7322eebc0fdd/25f094b523a2d6c51e084eeb8f60dd2f.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': '',
            'name': ' (Wormhole)',
            'address': '0xA608D79C5f695C0D4C0E773A4938b57e18e0fc57',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0xa608d79c5f695c0d4c0e773a4938b57e18e0fc57/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PSHARE',
            'name': 'Piggy Share',
            'address': '0xA5e2cFe48fe8C4ABD682CA2B10fCAaFE34b8774c',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1645065656628_4353546372297692.png',
            'coingeckoId': 'piggy-share',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'KRW',
            'name': 'KROWN',
            'address': '0xA5aCFECA5270bC9768633FBC86caA959B85Ec8b7',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/16530/thumb/KRW_token_logo_200x200.png?1624343058',
            'coingeckoId': 'krown',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QI',
            'name': 'Qi Dao',
            'address': '0xA56F9A54880afBc30CF29bB66d2D9ADCdcaEaDD6',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xA56F9A54880afBc30CF29bB66d2D9ADCdcaEaDD6/logo.png',
            'coingeckoId': 'qi-dao',
            'listedIn': [
                'coingecko',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MATIC',
            'name': 'Matic',
            'address': '0xA56B1b9f4e5A1A1e0868F5Fd4352ce7CdF0C2A4F',
            'decimals': 19,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/matic.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'HOT',
            'name': 'HoloToken',
            'address': '0xA471033610995EEdF0D6E4C598a4A9b4EC99c700',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xA471033610995EEdF0D6E4C598a4A9b4EC99c700/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'DEBASE',
            'name': 'Debase',
            'address': '0xA449DE69B549B416690aB15D2E67E7fCcD464347',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xA449DE69B549B416690aB15D2E67E7fCcD464347/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'WOW',
            'name': 'WOWswap',
            'address': '0xA384Bc7Cdc0A93e686da9E7B8C0807cD040F4E0b',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0xA384Bc7Cdc0A93e686da9E7B8C0807cD040F4E0b/logo_24.png',
            'coingeckoId': 'wowswap',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'CRA',
            'name': 'Crabada',
            'address': '0xA32608e873F9DdEF944B24798db69d80Bbb4d1ed',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xa32608e873f9ddef944b24798db69d80bbb4d1ed.png',
            'coingeckoId': 'crabada',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'joe',
                'pangolin',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'VF',
            'name': 'ValhalaFloki',
            'address': '0xA2f256FA1593bb1C55DE8145146aF4275a7c4DC3',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0xa2f256fa1593bb1c55de8145146af4275a7c4dc3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BAL',
            'name': 'Balancer',
            'address': '0xA2A035Dd93b0e963864FA14A240401d6CeAc5558',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xA2A035Dd93b0e963864FA14A240401d6CeAc5558/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SAND',
            'name': 'SAND',
            'address': '0xA29d60Ef9706571bBDa9b505A117e1D36a0D683C',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xA29d60Ef9706571bBDa9b505A117e1D36a0D683C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'RUX',
            'name': 'RunBlox',
            'address': '0xA1AfCC973d44cE1C65a21D9e644CB82489d26503',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xA1AfCC973d44cE1C65a21D9e644CB82489d26503/logo.png',
            'coingeckoId': 'runblox',
            'listedIn': [
                'coingecko',
                'joe',
                'lifinance'
            ]
        },
        {
            'symbol': 'YFII',
            'name': 'YFII.finance',
            'address': '0xA0e1645A594a3ac2556Ad0707D89B908B1A17d03',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0xA0e1645A594a3ac2556Ad0707D89B908B1A17d03/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'KGC',
            'name': 'KingdomQuest',
            'address': '0x9f3C3C7Df958ECb2758A937531B2107Bf7A1E5c2',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x9f3c3c7df958ecb2758a937531b2107bf7a1e5c2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DEG',
            'name': 'Degis',
            'address': '0x9f285507Ea5B4F33822CA7aBb5EC8953ce37A645',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0x9f285507Ea5B4F33822CA7aBb5EC8953ce37A645/logo.png',
            'coingeckoId': 'degis',
            'listedIn': [
                'coingecko',
                'openocean',
                'joe',
                'lifinance'
            ]
        },
        {
            'symbol': 'XDO',
            'name': 'xDollar',
            'address': '0x9eF758aC000a354479e538B8b2f01b917b8e89e7',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'YFI',
            'name': 'yearn finance',
            'address': '0x9eAaC1B23d935365bD7b542Fe22cEEe2922f52dc',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://ethapi.openocean.finance/logos/avax/0x9eaac1b23d935365bd7b542fe22ceee2922f52dc.png',
            'coingeckoId': 'yearn-finance',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'PXT2',
            'name': 'ProjectX2',
            'address': '0x9e20Af05AB5FED467dFDd5bb5752F7d5410C832e',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x9e20af05ab5fed467dfdd5bb5752f7d5410c832e/logo.png',
            'coingeckoId': 'project-x-nodes',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': '3ULL',
            'name': 'Playa3ull Games',
            'address': '0x9e15f045e44ea5a80e7Fbc193A35287712Cc5569',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/25115/thumb/logo_200px2.png?1666431111',
            'coingeckoId': 'playa3ull-games',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RADI',
            'name': 'RADI',
            'address': '0x9c5bBb5169B66773167d86818b3e149A4c7e1d1A',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x9c5bBb5169B66773167d86818b3e149A4c7e1d1A/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ALPHA',
            'name': 'Alpha Shares V2',
            'address': '0x9c3254bb4F7f6A05A4Aaf2Cadce592C848d043c1',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/28136/thumb/alpha.png?1667739192',
            'coingeckoId': 'alpha-shares-v2',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'BTCPrinter',
            'name': 'BTCPrinter',
            'address': '0x9c2ADAbBF6E6947AeEf4cE0532754827D8774D4D',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x9c2adabbf6e6947aeef4ce0532754827d8774d4d/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'LASM',
            'name': 'LasMeta',
            'address': '0x9c0C0E21A8A4787e3304F93767695101217DE113',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BAMBOO-V2',
            'name': 'BambooToken v2',
            'address': '0x9a928D7dcD8D7E5Cb6860B7768eC2D87B8934267',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x9a928d7dcd8d7e5cb6860b7768ec2d87b8934267/logo.png',
            'coingeckoId': 'bamboo-token-2',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MFI',
            'name': 'Marginswap',
            'address': '0x9Fda7cEeC4c18008096C2fE2B85F05dc300F94d0',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x9Fda7cEeC4c18008096C2fE2B85F05dc300F94d0/logo_24.png',
            'coingeckoId': 'marginswap',
            'listedIn': [
                'coingecko',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'MULTI',
            'name': 'Multichain',
            'address': '0x9Fb9a33956351cf4fa040f65A13b835A3C8764E3',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/22087/thumb/1_Wyot-SDGZuxbjdkaOeT2-A.png?1640764238',
            'coingeckoId': 'multichain',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'TERRA',
            'name': 'Avaterra',
            'address': '0x9F87C6c30F4b23C7B51Aa7465A9e0A836514700D',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/19788/thumb/avaterra.PNG?1635888526',
            'coingeckoId': 'avaterra',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPC',
            'name': 'SpaceChainV2',
            'address': '0x9E692659CdEDf13e85eADC38fCf7bC9F6329Db69',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x9E692659CdEDf13e85eADC38fCf7bC9F6329Db69/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'NFTD',
            'name': 'NFTrade',
            'address': '0x9E3Ca00f2d4A9E5d4f0add0900de5f15050812cF',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x9E3Ca00f2d4A9E5d4f0add0900de5f15050812cF/logo_24.png',
            'coingeckoId': 'nftrade',
            'listedIn': [
                'coingecko',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'ALBT',
            'name': 'AllianceBlock',
            'address': '0x9E037dE681CaFA6E661e6108eD9c2bd1AA567Ecd',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x9E037dE681CaFA6E661e6108eD9c2bd1AA567Ecd/logo_24.png',
            'coingeckoId': 'allianceblock',
            'listedIn': [
                'coingecko',
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'TEND',
            'name': 'Tendies Token',
            'address': '0x9D8CA87A15230b112D90f9eEE7C1C6fDAB92cAed',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x9D8CA87A15230b112D90f9eEE7C1C6fDAB92cAed/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'GVT',
            'name': 'Genesis Vision Token',
            'address': '0x9D3b7a5e30654Ab86039c929880b078B34c41625',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x9D3b7a5e30654Ab86039c929880b078B34c41625/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'BUSD',
            'name': 'Binance USD',
            'address': '0x9C9e5fD8bbc25984B178FdCE6117Defa39d2db39',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x9C9e5fD8bbc25984B178FdCE6117Defa39d2db39/logo_24.png',
            'coingeckoId': 'binance-usd',
            'listedIn': [
                'coingecko',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'HTZ',
            'name': 'Hertz Token',
            'address': '0x9C8E99eb130AED653Ef90fED709D9C3E9cC8b269',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x9C8E99eb130AED653Ef90fED709D9C3E9cC8b269/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'FERT',
            'name': 'Chikn Fert',
            'address': '0x9C846D808A41328A209e235B5e3c4E626DAb169E',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0x9C846D808A41328A209e235B5e3c4E626DAb169E/logo.png',
            'coingeckoId': 'chikn-fert',
            'listedIn': [
                'coingecko',
                'joe',
                'lifinance'
            ]
        },
        {
            'symbol': 'FRB',
            'name': 'Freebie Life Finance',
            'address': '0x9BedCE29F79076b21DD04958a9Fd4B22F63fD86D',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/26262/thumb/Logo_%28200x200%29.png?1656942812',
            'coingeckoId': 'freebie-life-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZERO',
            'name': 'Zero.Exchange Token',
            'address': '0x9Bdd302e506C3F6c23c085C37789cce6d3C1a233',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x9Bdd302e506C3F6c23c085C37789cce6d3C1a233/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SHACK',
            'name': 'Shack',
            'address': '0x9AD274e20a153451775Ff29D546949a254C4a1bc',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/25699/thumb/shack_no_bg_no_pad3.png?1653441433',
            'coingeckoId': 'shack',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MAGIC',
            'name': 'Cosmic Universe Magic',
            'address': '0x9A8E0217cD870783c3f2317985C57Bf570969153',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/19313/thumb/13037.png?1635025843',
            'coingeckoId': 'cosmic-universe-magic-token',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'OGN',
            'name': 'OriginToken',
            'address': '0x9A1712dBEd062dB70c6c4C235Be9dCd10a9Dac59',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x9A1712dBEd062dB70c6c4C235Be9dCd10a9Dac59/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'LACE',
            'name': 'Lovelace',
            'address': '0x99f7D752745f270E100233cC05768B4a12Bd09f0',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'eMTRG',
            'name': 'Meter Governance mapped by Meter.io',
            'address': '0x99B1b197D53511929A082EE66e7aC7E23257a4c4',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x99B1b197D53511929A082EE66e7aC7E23257a4c4/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'YFI',
            'name': 'yearn.finance',
            'address': '0x99519AcB025a0e0d44c3875A4BbF03af65933627',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x99519AcB025a0e0d44c3875A4BbF03af65933627/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'UTK',
            'name': 'Utrust Token',
            'address': '0x994921baDc83D4F16eEde22B81b64162c50A49EB',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x994921baDc83D4F16eEde22B81b64162c50A49EB/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ADEL',
            'name': 'Akropolis Delphi',
            'address': '0x98E1cF8b9D1DEb2F0C9F2a0A59Ee2fB60a6F10C9',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x98E1cF8b9D1DEb2F0C9F2a0A59Ee2fB60a6F10C9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'BAT',
            'name': 'Basic Attention',
            'address': '0x98443B96EA4b0858FDF3219Cd13e98C7A4690588',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/677/thumb/basic-attention-token.png?1547034427',
            'coingeckoId': 'basic-attention-token',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'STATIK',
            'name': 'Statik',
            'address': '0x97d367A5f900F5c9dB4370D0D801Fc52332244C7',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/22298/thumb/STATIK_200x200.png?1641424859',
            'coingeckoId': 'statik',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'DXN',
            'name': 'Dexance',
            'address': '0x97F5A66dbF53036a4B988B2dE3003e640602c86f',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'EM',
            'name': 'EMERALD',
            'address': '0x97E7044ab98B7891546485f5f66EDcc2F58be106',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x97E7044ab98B7891546485f5f66EDcc2F58be106/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'RAI',
            'name': 'Rai Reflex Index',
            'address': '0x97Cd1CFE2ed5712660bb6c14053C0EcB031Bff7d',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/14004/thumb/RAI-logo-coin.png?1613592334',
            'coingeckoId': 'rai',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'OML',
            'name': 'Omlira',
            'address': '0x979fFD8eEd7a43629eA29581DF4Bfe2b3F224e47',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x979fFD8eEd7a43629eA29581DF4Bfe2b3F224e47/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'YAX',
            'name': 'yAxis',
            'address': '0x977788025632E20360E4bB4867ef2C498A4EE4a6',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x977788025632E20360E4bB4867ef2C498A4EE4a6/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'A4',
            'name': 'A4 Finance',
            'address': '0x9767203e89dcD34851240B3919d4900d3E5069f1',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/21992/thumb/ba384ad07217a4be75cb85314f5760f7.jpg?1640582786',
            'coingeckoId': 'a4-finance',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'X8X',
            'name': 'X8XToken',
            'address': '0x974E0e514D1413001201D5a629fF8dEfd188E3fd',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x974E0e514D1413001201D5a629fF8dEfd188E3fd/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'Tether',
            'address': '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7.png',
            'coingeckoId': 'tether',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'joe',
                'pangolin',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'POOLZ',
            'name': 'Poolz Finance',
            'address': '0x96CE026f10890f4836937e6FDe75f13252fdf414',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x96CE026f10890f4836937e6FDe75f13252fdf414/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ALEPH',
            'name': 'aleph.im v2',
            'address': '0x969A3f4481583843dB706332E344412235c0892a',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x969A3f4481583843dB706332E344412235c0892a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'EQMT',
            'name': 'EQUUSMiningToken',
            'address': '0x964A11836e6Ac44E07F8632a9FF6C380Ef0113fd',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x964A11836e6Ac44E07F8632a9FF6C380Ef0113fd/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'DYP',
            'name': 'DeFi Yield Protocol',
            'address': '0x961C8c0B1aaD0c0b10a51FeF6a867E3091BCef17',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x961c8c0b1aad0c0b10a51fef6a867e3091bcef17.png',
            'coingeckoId': 'defi-yield-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'BUSD',
            'name': 'Binance-Peg BUSD Token',
            'address': '0x9610b01AAa57Ec026001F7Ec5CFace51BfEA0bA6',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x9610b01aaa57ec026001f7ec5cface51bfea0ba6/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'XGRAPE',
            'name': 'GrapeVine',
            'address': '0x95CED7c63eA990588F3fd01cdDe25247D04b8D98',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/27741/thumb/CRYTOLOGO.png?1665579088',
            'coingeckoId': 'grapevine',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNIV',
            'name': 'Universe',
            'address': '0x959b88966fC5B261dF8359961357d34F4ee27b4a',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1643719344123_41268550283451755.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'GDSY',
            'name': 'Golden Society Token V2',
            'address': '0x955e02d81cA94d8Af5A74aC44e19CbE921b9a51B',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/26086/thumb/gdsy_token.png?1655772153',
            'coingeckoId': 'golden-society-token-v2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KSHARE',
            'name': 'King Shares',
            'address': '0x94d649E017625dE1Aa04733aA046dE0161DF18eF',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x94d649E017625dE1Aa04733aA046dE0161DF18eF/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'RBX',
            'name': 'RBX',
            'address': '0x94960952876e3ED6A7760B198354fCc5319A406a',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/19253/thumb/output-onlinepngtools-9.png?1634801960',
            'coingeckoId': 'rbx-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'vUNIT',
            'name': 'Virtual Unit',
            'address': '0x94695C9942b1F306bC9A6dC38563EC02312446Ff',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PAE',
            'name': 'Ripae',
            'address': '0x9466Ab927611725B9AF76b9F31B2F879Ff14233d',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0x9466Ab927611725B9AF76b9F31B2F879Ff14233d/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'joe',
                'lifinance'
            ]
        },
        {
            'symbol': 'LEO',
            'name': 'Leonidas',
            'address': '0x945a6869819cdAD404DC80647a2085957CB1f28b',
            'decimals': 5,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/24976/thumb/leonidasfinancepic.jpg?1649653344',
            'coingeckoId': 'leonidas',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'APEIN',
            'name': 'Ape In',
            'address': '0x938FE3788222A74924E062120E7BFac829c719Fb',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x938fe3788222a74924e062120e7bfac829c719fb.png',
            'coingeckoId': 'ape-in',
            'listedIn': [
                'coingecko',
                '1inch',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'OH',
            'name': 'Oh  Finance',
            'address': '0x937E077aBaEA52d3abf879c9b9d3f2eBd15BAA21',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x937e077abaea52d3abf879c9b9d3f2ebd15baa21.png',
            'coingeckoId': 'oh-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'BIOFI',
            'name': 'BiometricFinancial',
            'address': '0x9366d30FeBA284E62900f6295BC28c9906f33172',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/25207/thumb/CoinGekoBioFiImage.png?1650771084',
            'coingeckoId': 'biometric-financial',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'buidl',
            'name': 'dfohub',
            'address': '0x933753c297aDe672D68fa3296FdafFD76Db7DFA2',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x933753c297aDe672D68fa3296FdafFD76Db7DFA2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'MIND+',
            'name': 'BiggerMINDS',
            'address': '0x92876C3A3E2B0788C841587a14989392A555BffE',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/24546/thumb/iShot_2022-07-14_12.06.21.png?1657771731',
            'coingeckoId': 'biggerminds',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SLOT',
            'name': 'Snowtomb LOT',
            'address': '0x924157B5dbB387A823719916B25256410a4Ad470',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1644403513197_6776366153258726.png',
            'coingeckoId': 'snowtomb-lot',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'MAGE',
            'name': 'MetaBrands',
            'address': '0x921f99719Eb6C01b4B8f0BA7973A7C24891e740A',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x921f99719eb6c01b4b8f0ba7973a7c24891e740a.png',
            'coingeckoId': 'metabrands',
            'listedIn': [
                'coingecko',
                '1inch',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'RSR',
            'name': 'Reserve Rights',
            'address': '0x91C20a30ebA9795BBdEd46df9ad5b215DFa04fcD',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x91C20a30ebA9795BBdEd46df9ad5b215DFa04fcD/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'YAXIS',
            'name': 'yAxis',
            'address': '0x91A1700835230B8b3B06B5B3DD1Fe70D48ACbd91',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'GB',
            'name': 'Good Bridging',
            'address': '0x90842eb834cFD2A1DB0b1512B254a18E4D396215',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x90842eb834cFD2A1DB0b1512B254a18E4D396215/logo.png',
            'coingeckoId': 'good-bridging',
            'listedIn': [
                'coingecko',
                'openocean',
                'pangolin',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REEF',
            'name': 'Reef.finance',
            'address': '0x90557E63339cAed393ee15Cb6236Bb746DeD11D3',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x90557E63339cAed393ee15Cb6236Bb746DeD11D3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'TGBP',
            'name': 'TrueGBP',
            'address': '0x9018775d36d3e39AE5d88a5F502d4cBe430734C5',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x9018775d36d3e39AE5d88a5F502d4cBe430734C5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'PERL',
            'name': 'Perlin',
            'address': '0x8f4Dee85B841723bdCEcdc9Ed68Cda662f56e82A',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x8f4Dee85B841723bdCEcdc9Ed68Cda662f56e82A/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'DEGEN',
            'name': 'Degen Finance',
            'address': '0x8f0224e841b78BF380649b6b19503E2BFc76efC7',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'UNI',
            'name': 'Uniswap',
            'address': '0x8eBAf22B6F053dFFeaf46f4Dd9eFA95D89ba8580',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://ethapi.openocean.finance/logos/avax/0x8ebaf22b6f053dffeaf46f4dd9efa95d89ba8580.png',
            'coingeckoId': 'uniswap',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'AUDIO',
            'name': 'Audius',
            'address': '0x8e32F45c87b39F15529787A77cFa7bA48CFAC7f0',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x8e32F45c87b39F15529787A77cFa7bA48CFAC7f0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'DINO',
            'name': 'Jurassic Nodes',
            'address': '0x8d8Db824baFdb617646E80360509274909c48127',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/24191/thumb/Logo_200x200.png?1646820399',
            'coingeckoId': 'jurassic_nodes',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TJOMA',
            'name': 'Tjoma.Finance',
            'address': '0x8d4A8522f2FB040d4755757C05d99976C0D29fCE',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x8d4a8522f2fb040d4755757c05d99976c0d29fce/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BUD',
            'name': 'BudToken',
            'address': '0x8cb66252e8C03791de080dF5fb3D979E46F1cC27',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x8cb66252e8c03791de080df5fb3d979e46f1cc27/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ETH',
            'name': 'Ethereum  Wormhole ',
            'address': '0x8b82A291F83ca07Af22120ABa21632088fC92931',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/22990/thumb/ETH_wh_small.png?1644225466',
            'coingeckoId': 'ethereum-wormhole',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LIBRE',
            'name': 'Libre',
            'address': '0x8afa62Fa8DdE8888405c899D7Da077A61a87EeD3',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x8afa62fa8dde8888405c899d7da077a61a87eed3/logo.png',
            'coingeckoId': 'libre-defi',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SAN',
            'name': 'SANtiment network token',
            'address': '0x8ae71C763700F22f1bb137F1D8767826d7f02d3a',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x8ae71C763700F22f1bb137F1D8767826d7f02d3a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'CRAFT',
            'name': 'TaleCraft',
            'address': '0x8aE8be25C23833e0A01Aa200403e826F611f9CD2',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x8ae8be25c23833e0a01aa200403e826f611f9cd2.png',
            'coingeckoId': 'talecraft',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pangolin',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'ANODE',
            'name': 'Avax Nodes',
            'address': '0x8aD883CCC39c87315e1Ee796aCb44743E624e1d5',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/25409/thumb/AvaxN_logo.png?1651732507',
            'coingeckoId': 'avax-nodes',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'LMY',
            'name': 'Lunch Money',
            'address': '0x8a86e6dC6611c34d5c92fC563f426D2E378b3f1C',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x8a86e6dC6611c34d5c92fC563f426D2E378b3f1C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'GRT',
            'name': 'The Graph',
            'address': '0x8a0cAc13c7da965a312f08ea4229c37869e85cB9',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/13397/thumb/Graph_Token.png?1608145566',
            'coingeckoId': 'the-graph',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'REL',
            'name': 'Relevant',
            'address': '0x8FF91E20Aab3D5A21b0c5ecd45FC942c52f578b3',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x8FF91E20Aab3D5A21b0c5ecd45FC942c52f578b3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'THOR',
            'name': 'Thor',
            'address': '0x8F47416CaE600bccF9530E9F3aeaA06bdD1Caa79',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x8f47416cae600bccf9530e9f3aeaa06bdd1caa79.png',
            'coingeckoId': 'thor',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'joe',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'ALPHA',
            'name': 'AlphaToken',
            'address': '0x8Ea071D1903B27Ee57c82710B3a7cF660f285Bb8',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x8Ea071D1903B27Ee57c82710B3a7cF660f285Bb8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'REQ',
            'name': 'Request Token',
            'address': '0x8E729Ad67D81d220B7aB6e00440f785bD08187fE',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x8E729Ad67D81d220B7aB6e00440f785bD08187fE/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'MKR',
            'name': 'Maker',
            'address': '0x8DF92E9C0508aB0030d432DA9F2C65EB1Ee97620',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x8DF92E9C0508aB0030d432DA9F2C65EB1Ee97620/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'CNR',
            'name': 'Canary',
            'address': '0x8D88e48465F30Acfb8daC0b3E35c9D6D7d36abaf',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x8D88e48465F30Acfb8daC0b3E35c9D6D7d36abaf/logo_24.png',
            'coingeckoId': 'canary',
            'listedIn': [
                'coingecko',
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'MCX',
            'name': 'MachiX Token',
            'address': '0x8CFD9b30B18B3aF9Ce905561F749626ef06B1717',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x8CFD9b30B18B3aF9Ce905561F749626ef06B1717/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'wsOHM',
            'name': 'Wrapped sOHM',
            'address': '0x8CD309e14575203535EF120b5b0Ab4DDeD0C2073',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1637660405200_18417188279485397.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ZEUS',
            'name': 'Zeus Finance',
            'address': '0x8C3633eE619a42d3755327C2524E4d108838c47f',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/27107/thumb/logo.9dcd586c.png?1662008384',
            'coingeckoId': 'zeus-finance',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'UCAP',
            'name': 'UniCap.finance',
            'address': '0x8C32D46C073694045E8409251BE1FFD6720a94F3',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x8C32D46C073694045E8409251BE1FFD6720a94F3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'INTD',
            'name': 'INDESTCOIN',
            'address': '0x8Bb93979901cd159bF6763B223FBb315C31CCF7b',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/27543/thumb/logoend.png?1668586274',
            'coingeckoId': 'indestcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KISHIMOTO',
            'name': 'Kishimoto  old ',
            'address': '0x8B6663725D7e7368B01EeFdDc16Be085e492498E',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/19188/thumb/cmclogo.png?1666922508',
            'coingeckoId': 'kishimoto-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORCA',
            'name': 'Orca DAO',
            'address': '0x8B1d98A91F853218ddbb066F20b8c63E782e2430',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x8B1d98A91F853218ddbb066F20b8c63E782e2430/logo.png',
            'coingeckoId': 'orcadao',
            'listedIn': [
                'coingecko',
                'pangolin',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'iDAI',
            'name': 'Fulcrum DAI iToken',
            'address': '0x8A8DD8dd5639174F032877b2D6c7467D8B51D561',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x8A8DD8dd5639174F032877b2D6c7467D8B51D561/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'NORD',
            'name': 'Nord Finance',
            'address': '0x8965349fb649A33a30cbFDa057D8eC2C48AbE2A2',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/13630/small/nord.jpg?1610465136',
            'coingeckoId': null,
            'listedIn': [
                'dfyn'
            ]
        },
        {
            'symbol': 'XED',
            'name': 'AVAXED',
            'address': '0x89176D15a1022431AE856186d77A975D152aDfaf',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x89176d15a1022431ae856186d77a975d152adfaf/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'RBC',
            'name': 'Rubic',
            'address': '0x88f87bb181cd974f3AaE5002F5E6D4E1EB463f9C',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x88f87bb181cd974f3AaE5002F5E6D4E1EB463f9C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'PERP',
            'name': 'Perpetual',
            'address': '0x88Af8D172e64326A71C1a7756CB4F6125D98F2A5',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x88Af8D172e64326A71C1a7756CB4F6125D98F2A5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'IUSDS',
            'name': 'Inflation Adjusted USDS',
            'address': '0x8861f5C40A0961579689FDf6CDEA2Be494F9B25A',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/25698/thumb/iUSDS2.png?1653441251',
            'coingeckoId': 'inflation-adjusted-usds',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MAXI',
            'name': 'Maximus',
            'address': '0x885d748C00A279B67A7749EC6b03301700dd0455',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x885d748C00A279B67A7749EC6b03301700dd0455/logo_24.png',
            'coingeckoId': 'maximus',
            'listedIn': [
                'coingecko',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'WMATIC',
            'name': 'Wrapped Matic Token',
            'address': '0x885ca6663E1E19DAD31c1e08D9958a2b8F538D53',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x885ca6663E1E19DAD31c1e08D9958a2b8F538D53/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'LEVR',
            'name': 'LEVR - levr.ly Logistics Token',
            'address': '0x884247ec9921195C1e722d5e8E812415BD8715d2',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MKR',
            'name': 'Maker',
            'address': '0x88128fd4b259552A9A1D457f435a6527AAb72d42',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/1364/thumb/Mark_Maker.png?1585191826',
            'coingeckoId': 'maker',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'MOR',
            'name': 'Mor Stablecoin',
            'address': '0x87BAde473ea0513D4aA7085484aEAA6cB6EBE7e3',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/18549/thumb/MOR_logo_icon_black_background_round_200_x_200-01.png?1632364631',
            'coingeckoId': 'mor-stablecoin',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'KRU',
            'name': 'Kairu',
            'address': '0x8785FaFCb35f92452701EdEa4Bb8DE548824e9bF',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x8785fafcb35f92452701edea4bb8de548824e9bf/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'WTF',
            'name': 'Waterfall Governance',
            'address': '0x873801Ae2ff12d816Db9a7B082F5796BEC64C82C',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/19189/thumb/wtf_icon_avatar_200x200.png?1634631004',
            'coingeckoId': 'waterfall-governance-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QI',
            'name': 'BENQI',
            'address': '0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x8729438eb15e2c8b576fcc6aecda6a148776c0f5.png',
            'coingeckoId': 'benqi',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'joe',
                'pangolin',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'AXS',
            'name': 'Axie Infinity Shard',
            'address': '0x860d87C4EE3bf2F001a641e32FbeF8F0342Ba924',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x860d87C4EE3bf2F001a641e32FbeF8F0342Ba924/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'FNX',
            'name': 'FinNexus',
            'address': '0x85B87e0A36865dbdeF50bdFAFB49B272077E0026',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x85B87e0A36865dbdeF50bdFAFB49B272077E0026/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'KEY',
            'name': 'SelfKey',
            'address': '0x858950767B333d45B90C28709e97605e1829f907',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x858950767B333d45B90C28709e97605e1829f907/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'USX',
            'name': 'dForce USD',
            'address': '0x853ea32391AaA14c112C645FD20BA389aB25C5e0',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://get.celer.app/cbridge-icons/USX.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'VSO',
            'name': 'Verso',
            'address': '0x846D50248BAf8b7ceAA9d9B53BFd12d7D7FBB25a',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x846d50248baf8b7ceaa9d9b53bfd12d7d7fbb25a.png',
            'coingeckoId': 'verso',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'joe',
                'pangolin',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'VPND',
            'name': 'VaporNodes',
            'address': '0x83a283641C6B4DF383BCDDf807193284C84c5342',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0x83a283641C6B4DF383BCDDf807193284C84c5342/logo.png',
            'coingeckoId': 'vapornodes',
            'listedIn': [
                'coingecko',
                'joe',
                'lifinance'
            ]
        },
        {
            'symbol': 'TESLABTC',
            'name': 'TeslaBitcoin',
            'address': '0x8349088C575cA45f5A63947FEAeaEcC41136fA01',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x8349088c575ca45f5a63947feaeaecc41136fa01/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'KEN',
            'name': 'Kenysians Network',
            'address': '0x833A32E28Bbb289C0ba13c69A08dB9E9526D4907',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x833A32E28Bbb289C0ba13c69A08dB9E9526D4907/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'PIE',
            'name': 'DeFiPIE Token',
            'address': '0x8320c3cd3a0d671650F5600Cc9d907749AeDa7E1',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x8320c3cd3a0d671650F5600Cc9d907749AeDa7E1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'HAT',
            'name': 'Joe Hat',
            'address': '0x82FE038Ea4b50f9C957da326C412ebd73462077C',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x82FE038Ea4b50f9C957da326C412ebd73462077C/logo.png',
            'coingeckoId': 'joe-hat-token',
            'listedIn': [
                'coingecko',
                'joe',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ADAI',
            'name': 'Aave DAI',
            'address': '0x82E64f49Ed5EC1bC6e43DAD4FC8Af9bb3A2312EE',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1648800277587_1321964226633754.png',
            'coingeckoId': 'aave-dai',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'TAUD',
            'name': 'TrueAUD',
            'address': '0x82913383A48712C6A876E611A0412395B86e74B1',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x82913383A48712C6A876E611A0412395B86e74B1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'XETA',
            'name': 'XETA',
            'address': '0x827eb4bada6cb76c90f887969b3fe5faD585FFe3',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x827eb4bada6cb76c90f887969b3fe5faD585FFe3/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'RLOOP',
            'name': 'rLoop',
            'address': '0x822b906E74D493D07223CF6858620ccDa66b2154',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/4698/thumb/12903040.png?1651730348',
            'coingeckoId': 'rloop',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VIB',
            'name': 'Vibe',
            'address': '0x81C8d264f14bF69a083446Fd19fFE9A8fE80E3C0',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x81C8d264f14bF69a083446Fd19fFE9A8fE80E3C0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'STA',
            'name': 'Statera',
            'address': '0x8194f4FE1fb50A945eB5dB226689066BE5E208D4',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x8194f4FE1fb50A945eB5dB226689066BE5E208D4/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'CYCLE',
            'name': 'Cycle',
            'address': '0x81440C939f2C1E34fc7048E518a637205A632a74',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x81440C939f2C1E34fc7048E518a637205A632a74/logo_24.png',
            'coingeckoId': 'cycle-token',
            'listedIn': [
                'coingecko',
                'openocean',
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'axlATOM',
            'name': 'Axelar Wrapped ATOM',
            'address': '0x80D18b1c9Ab0c9B5D6A6d5173575417457d00a12',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x80D18b1c9Ab0c9B5D6A6d5173575417457d00a12/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'JADE',
            'name': 'Jade Protocol',
            'address': '0x80B010450fDAf6a3f8dF033Ee296E92751D603B3',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x80b010450fdaf6a3f8df033ee296e92751d603b3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': '1UP',
            'name': 'Uptrennd',
            'address': '0x7fB11D8945653F0978cCA2Ef51442bF31c84e142',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x7fB11D8945653F0978cCA2Ef51442bF31c84e142/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SERENE',
            'name': 'Serenity V2',
            'address': '0x7f352fA977713019C484C58E9BDfB04910016871',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'LOOT',
            'name': 'LOOT',
            'address': '0x7f041ce89A2079873693207653b24C15B5e6A293',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x7f041ce89A2079873693207653b24C15B5e6A293/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'FOX',
            'name': 'FOX',
            'address': '0x7e7034845b581B959Ad90a6D7424382DDd70C196',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x7e7034845b581B959Ad90a6D7424382DDd70C196/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'FTOMB',
            'name': 'Frozentomb',
            'address': '0x7dFCe792c83F283ECfE7EA7ed308F9B891073540',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/23590/thumb/200ftomb.png?1644649183',
            'coingeckoId': 'frozentomb',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SB',
            'name': 'Snowbank',
            'address': '0x7d1232B90D3F809A54eeaeeBC639C62dF8a8942f',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1636445434248_176771240066546.png',
            'coingeckoId': 'snowbank',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'DAI.ex',
            'name': 'Superfluid DAI.e',
            'address': '0x7cd00C2b9A78f270B897457ab070274e4a17dE83',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://static.debank.com/image/avax_token/logo_url/0xd586e7f844cea2f87f50152665bcbc2c279d8d70/549c4205dbb199f1b8b03af783f35e71.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ORDER',
            'name': 'Order',
            'address': '0x7c24b813089675cf5484afa4850FE9276D97b461',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/26550/thumb/KPyq4RWO_400x400.jpeg?1658752806',
            'coingeckoId': 'order',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DATA',
            'name': 'Streamr DATAcoin',
            'address': '0x7b73CEEed704556355D03aF8888da3bCD4434CF9',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x7b73CEEed704556355D03aF8888da3bCD4434CF9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'PLN',
            'name': 'Pollen',
            'address': '0x7b2B702706D9b361dfE3f00bD138C0CFDA7FB2Cf',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x7b2B702706D9b361dfE3f00bD138C0CFDA7FB2Cf/logo_24.png',
            'coingeckoId': 'pollen',
            'listedIn': [
                'coingecko',
                '1inch',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'BOOST',
            'name': 'Boosted Finance',
            'address': '0x7Ffc73532E29Ac0845E494ec021F1A0791EBd4A7',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x7Ffc73532E29Ac0845E494ec021F1A0791EBd4A7/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'FIN',
            'name': 'DeFiner',
            'address': '0x7FA965Ebd5bBBee983681E571091A31dDDB2E510',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x7FA965Ebd5bBBee983681E571091A31dDDB2E510/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'DGC',
            'name': 'DGC Token',
            'address': '0x7F29e7086453ed97283893E763aE0989F4B70f8d',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x7F29e7086453ed97283893E763aE0989F4B70f8d/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'ST',
            'name': 'Simple Token',
            'address': '0x7De7dFE1A594BCBab1C0cd2AE0a530A019cE14Ed',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x7De7dFE1A594BCBab1C0cd2AE0a530A019cE14Ed/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'UNCL',
            'name': 'UNCL',
            'address': '0x7D86F1eafF29F076576b2Ff09CE3bcC7533fD2C5',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AGAr',
            'name': 'AGA Rewards',
            'address': '0x7D3B448c1242b563c65e8DAfCc13557c3069B5b6',
            'decimals': 8,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x7d3b448c1242b563c65e8dafcc13557c3069b5b6/logo.png',
            'coingeckoId': 'aga-rewards-2',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'TORN',
            'name': 'TornadoCash',
            'address': '0x7CCF19824c351e57C00633b46bbbff495E12d89d',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x7CCF19824c351e57C00633b46bbbff495E12d89d/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'MAXI',
            'name': 'Maximizer',
            'address': '0x7C08413cbf02202a1c13643dB173f2694e0F73f0',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x7C08413cbf02202a1c13643dB173f2694e0F73f0/logo_24.png',
            'coingeckoId': 'maximizer',
            'listedIn': [
                'coingecko',
                'openocean',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'KITE',
            'name': 'KITE420',
            'address': '0x7A2Fc1F6955888E161c3af68ee685E706B51a7AC',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x7a2fc1f6955888e161c3af68ee685e706b51a7ac/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'JNS',
            'name': 'Janus Network',
            'address': '0x7A023A408F51c23760Eb31190fc731bc12B52954',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/27115/thumb/200x200.png?1662012142',
            'coingeckoId': 'janus-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HEZ',
            'name': 'Hermez Network Token',
            'address': '0x79c340eAFaC9Cc81d9BF128aa1785E669e06FBe2',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x79c340eAFaC9Cc81d9BF128aa1785E669e06FBe2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'TOR',
            'name': 'TOR',
            'address': '0x790772d8f4115b608340a7aB25258fCa8da1ca2e',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AVE',
            'name': 'Avaware',
            'address': '0x78ea17559B3D2CF85a7F9C2C704eda119Db5E6dE',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x78ea17559b3d2cf85a7f9c2c704eda119db5e6de.png',
            'coingeckoId': 'avaware',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pangolin',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'LDC',
            'name': 'LEADCOIN',
            'address': '0x78c703129FA14c96164c6e14497edAB6CF215A93',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x78c703129FA14c96164c6e14497edAB6CF215A93/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'RELAY',
            'name': 'Relay Chain',
            'address': '0x78c42324016cd91D1827924711563fb66E33A83A',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x78c42324016cd91D1827924711563fb66E33A83A/logo.png',
            'coingeckoId': 'relay-token',
            'listedIn': [
                'coingecko',
                'rubic',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FLY',
            'name': 'Hoppers Game',
            'address': '0x78Ea3fef1c1f07348199Bf44f45b803b9B0Dbe28',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/24296/small/FLY.png?1647266279',
            'coingeckoId': 'hoppers-game',
            'listedIn': [
                'coingecko',
                'joe',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'KITTY',
            'name': 'Kitty Finance',
            'address': '0x788AE3b5D153d49F8DB649aacbA1857f744b739e',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/21483/thumb/kittyicon-white.d1fdf9f3.png?1639360102',
            'coingeckoId': 'kitty-finance',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'AUSD',
            'name': 'Avaware USD',
            'address': '0x783C08b5F26E3daf8C4681F3bf49844e425b6393',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x783C08b5F26E3daf8C4681F3bf49844e425b6393/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'MIS',
            'name': 'MIS',
            'address': '0x7823dAa7A5B86dd4E7a54c1ae70A14cf15758316',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x7823dAa7A5B86dd4E7a54c1ae70A14cf15758316/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'KIKI',
            'name': 'Kiki',
            'address': '0x781c9cA04c0938b981B3383653Ab186961eA9084',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/22289/thumb/A8oLUJW8_400x400.jpg?1641367572',
            'coingeckoId': 'kiki',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MXT',
            'name': 'MixTrust',
            'address': '0x77d208c2b37051957C2B7D88a3682C280d70e7e6',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x77d208c2b37051957C2B7D88a3682C280d70e7e6/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'FODL',
            'name': 'Fodl',
            'address': '0x77c62e549ca46EF8Dd5Edc052B1edC783F60A400',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AVOGE',
            'name': 'Avoge',
            'address': '0x779595F212B53e4f1F51b0875405Bf21B5e4D805',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x779595f212b53e4f1f51b0875405bf21b5e4d805/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'YETI',
            'name': 'Yeti Finance',
            'address': '0x77777777777d4554c39223C354A05825b2E8Faa3',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/25022/small/1_oJ0F2Zf6CuAhLP0zOboo6w.png?1649837363',
            'coingeckoId': 'yeti-finance',
            'listedIn': [
                'coingecko',
                'joe',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'EGG',
            'name': 'Chikn Egg',
            'address': '0x7761E2338B35bCEB6BdA6ce477EF012bde7aE611',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x7761E2338B35bCEB6BdA6ce477EF012bde7aE611/logo_24.png',
            'coingeckoId': 'chikn-egg',
            'listedIn': [
                'coingecko',
                '1inch',
                'joe',
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ATIS',
            'name': 'ATIS Token',
            'address': '0x77619878ccDd644Dd5e67cA0f8F04Dfaae42D542',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x77619878ccDd644Dd5e67cA0f8F04Dfaae42D542/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'NNT',
            'name': 'Nunu Spirits',
            'address': '0x771c01e1917B5aB5b791f7b96F0CD69E22f6dBcF',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/24378/thumb/NNT_99cc33.png?1649120059',
            'coingeckoId': 'nunu-spirits',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ZJOE',
            'name': 'zJOE',
            'address': '0x769bfeb9fAacD6Eb2746979a8dD0b7e9920aC2A4',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0x769bfeb9fAacD6Eb2746979a8dD0b7e9920aC2A4/logo.png',
            'coingeckoId': 'zjoe',
            'listedIn': [
                'coingecko',
                'joe',
                'lifinance'
            ]
        },
        {
            'symbol': 'TOMOE',
            'name': 'TomoChain',
            'address': '0x7657F9ee5f31868CcaC0EC4306a92B0E2F5660d2',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x7657F9ee5f31868CcaC0EC4306a92B0E2F5660d2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'USDB',
            'name': 'Bancor USD Token',
            'address': '0x764CC68cd46cB00644216682C3ee120b2f1EB5F6',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x764CC68cd46cB00644216682C3ee120b2f1EB5F6/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'NDX',
            'name': 'Indexed',
            'address': '0x7633b4710042F9Dd22e3FC63E59E4BFDcb6813B9',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x7633b4710042F9Dd22e3FC63E59E4BFDcb6813B9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'NOBEL',
            'name': 'Nobelium',
            'address': '0x75EcfC5825DC0f1b346dB945D5c463d212CDf2a3',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/23513/thumb/Nob-200x200.png?1644300658',
            'coingeckoId': 'nobelium',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'VAL',
            'name': 'Sora Validator Token',
            'address': '0x7583FD3Aa918896700F4F106Df7387e1943a31aa',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x7583FD3Aa918896700F4F106Df7387e1943a31aa/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'TIC',
            'name': 'ElasticSwap',
            'address': '0x75739a693459f33B1FBcC02099eea3eBCF150cBe',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x75739a693459f33b1fbcc02099eea3ebcf150cbe.png',
            'coingeckoId': 'elasticswap',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'EVIC',
            'name': 'Ethereum Victory',
            'address': '0x74FeFa839A96A1632A29E0fcf0907d0F88528658',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/27292/thumb/evic_200x200.png?1663148567',
            'coingeckoId': 'ethereum-victory',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MET',
            'name': 'Metronome',
            'address': '0x74F691fe2F89055cb1E641b840C8e7f12552dd6A',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x74F691fe2F89055cb1E641b840C8e7f12552dd6A/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'NFTART',
            'name': 'NFTArt.Finance',
            'address': '0x74A488BcCb16d90Fd628E75132e49ff067384aA9',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AMP',
            'name': 'Amp',
            'address': '0x74A13926Df3e38a7BB7D12f566694f2E66Ba145E',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x74A13926Df3e38a7BB7D12f566694f2E66Ba145E/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'RIDES',
            'name': 'Rides Finance',
            'address': '0x73F2aF605eA8A3D8410B83Ee05b8D6fc0Da1Aca4',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/24172/thumb/5Hj1cTR.png?1646744055',
            'coingeckoId': 'ride_finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KEEP',
            'name': 'KEEP Token',
            'address': '0x73945347fbCBFed872D590110f817621440a9d39',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x73945347fbCBFed872D590110f817621440a9d39/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SWFL',
            'name': 'Swapfolio',
            'address': '0x733793e8F93afD40d9322a4FBA46de661e4F8B83',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x733793e8F93afD40d9322a4FBA46de661e4F8B83/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'FEVR',
            'name': 'RealFevr',
            'address': '0x73178fcEB736A9d6c1A9EF1fe413F09CbA2D4a68',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/17136/thumb/Fevr-Token.png?1626869849',
            'coingeckoId': 'realfevr',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ONSTON',
            'name': 'Onston',
            'address': '0x72BC9D71dd9ad563F52270C6ce5FB30F617C7a1d',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/20669/thumb/onston.PNG?1637547859',
            'coingeckoId': 'onston',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'CGS',
            'name': 'Cougar',
            'address': '0x727C43b707C6Fe3ACD92f17EFAC8e05476DFa81c',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x727c43b707c6fe3acd92f17efac8e05476dfa81c/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'XGRO',
            'name': 'GROWTH DeFi',
            'address': '0x72699ba15CC734F8db874fa9652c8DE12093F187',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/12239/thumb/GRO_logo_icon_black_background_round_500_x_500-01.png?1628171711',
            'coingeckoId': 'growth-defi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MILK2',
            'name': 'Spaceswap MILK2',
            'address': '0x721C299E6BF7D6a430d9bEA3364Ea197314bcE09',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x721C299E6BF7D6a430d9bEA3364Ea197314bcE09/logo_24.png',
            'coingeckoId': 'spaceswap-milk2',
            'listedIn': [
                'coingecko',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'DUSD',
            'name': 'DefiDollar',
            'address': '0x71645323F647488209eAAB8d08900576502160c8',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x71645323F647488209eAAB8d08900576502160c8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'FITFI',
            'name': 'Step App',
            'address': '0x714f020C54cc9D104B6F4f6998C63ce2a31D1888',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x714f020C54cc9D104B6F4f6998C63ce2a31D1888/logo_24.png',
            'coingeckoId': 'step-app-fitfi',
            'listedIn': [
                'coingecko',
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'XCRS',
            'name': 'NovaXCrystal',
            'address': '0x70b4aE8eb7bd572Fc0eb244Cd8021066b3Ce7EE4',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/19645/thumb/xcrs.png?1635729443',
            'coingeckoId': 'novaxcrystal',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PAPA',
            'name': 'PAPA',
            'address': '0x70b33ebC5544C12691d055b49762D0F8365d99Fe',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x70b33ebc5544c12691d055b49762d0f8365d99fe/logo.png',
            'coingeckoId': 'papa-dao',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'LUNA',
            'name': 'LUNA (Wormhole)',
            'address': '0x70928E5B188def72817b7775F0BF6325968e563B',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x70928e5b188def72817b7775f0bf6325968e563b/logo.png',
            'coingeckoId': 'luna-wormhole',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'RACEX',
            'name': 'RaceX',
            'address': '0x7086e045b78E1e72F741F25231c08d238812CF8a',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x7086e045b78e1e72f741f25231c08d238812cf8a.png',
            'coingeckoId': 'racex',
            'listedIn': [
                'coingecko',
                '1inch',
                'pangolin',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'AVAT',
            'name': 'AVATA Network',
            'address': '0x7086C48c997b8597a1692798155B4fCf2cee7F0f',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/24899/thumb/round.png?1649322555',
            'coingeckoId': 'avata-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XNK',
            'name': 'Ink Protocol',
            'address': '0x703a3cC60E5E7ed2b28Bdc50D66C260b4aAB03dC',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x703a3cC60E5E7ed2b28Bdc50D66C260b4aAB03dC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'EGG',
            'name': 'EGG',
            'address': '0x701f26AB61aD19Acc9F218b42500D7598c50130c',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x701f26ab61ad19acc9f218b42500d7598c50130c/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SGLDM',
            'name': 'GoldMint Shares',
            'address': '0x700843380021776970A28A7A74dA3E5908755FCc',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'agEUR',
            'name': 'agEUR',
            'address': '0x6feFd97F328342a8A840546A55FDcfEe7542F9A8',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x6feFd97F328342a8A840546A55FDcfEe7542F9A8/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'JOE',
            'name': 'JOE',
            'address': '0x6e84a6216eA6dACC71eE8E6b0a5B7322EEbC0fDd',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x6e84a6216ea6dacc71ee8e6b0a5b7322eebc0fdd.png',
            'coingeckoId': 'joe',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'joe',
                'pangolin',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SPORE',
            'name': 'Spore',
            'address': '0x6e7f5C0b9f4432716bDd0a77a3601291b9D9e985',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x6e7f5c0b9f4432716bdd0a77a3601291b9d9e985.png',
            'coingeckoId': 'spore',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pangolin',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SINGLE',
            'name': 'SINGLE Token',
            'address': '0x6e0c55f4f52E2BE3eAbbd368F1aE7edDeb4Ebf8a',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'aAvaWAVAX',
            'name': 'Aave Avalanche WAVAX',
            'address': '0x6d80113e533a2C0fe82EaBD35f1875DcEA89Ea97',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x6d80113e533a2c0fe82eabd35f1875dcea89ea97/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ASTRO',
            'name': '100 Days Ventures',
            'address': '0x6d2f5dBf3a7396FCe32CfE406Aef7a8AFF812Fbb',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/25533/thumb/Official_v1.1_ASTRO-Token-Icon.jpeg?1652251108',
            'coingeckoId': '100-days-ventures',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'PAVAX',
            'name': 'Ripae AVAX',
            'address': '0x6ca558bd3eaB53DA1B25aB97916dd14bf6CFEe4E',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/26065/thumb/pavax.png?1655464794',
            'coingeckoId': 'ripae-avax',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'GENI',
            'name': 'GemUni',
            'address': '0x6c8c448f060904E3352B0cBeEeF1052b08Cb0A40',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TNS',
            'name': 'Transcodium',
            'address': '0x6b944c575376460edC27Be19c999654E5982D971',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x6b944c575376460edC27Be19c999654E5982D971/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'BAT',
            'name': 'Basic Attention Token',
            'address': '0x6b329326E0F6b95B93b52229b213334278D6f277',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x6b329326E0F6b95B93b52229b213334278D6f277/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'CTSI',
            'name': 'Cartesi',
            'address': '0x6b289CCeAA8639e3831095D75A3e43520faBf552',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x6b289CCeAA8639e3831095D75A3e43520faBf552/logo.png',
            'coingeckoId': 'cartesi',
            'listedIn': [
                'coingecko',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'APEU',
            'name': 'Ape Universe',
            'address': '0x6b0d2a3c37d551963275bB104F045F6a68AB6374',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/24006/thumb/Glassape-new-1.png?1646380939',
            'coingeckoId': 'ape-universe',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AUSDT',
            'name': 'Aave USDT',
            'address': '0x6ab707Aca953eDAeFBc4fD23bA73294241490620',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1648801688472_23325592396064065.png',
            'coingeckoId': 'aave-usdt',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'BAND',
            'name': 'Band Protocol',
            'address': '0x6Fd02c0789797e595751208a2446faF721B9f3C2',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x6Fd02c0789797e595751208a2446faF721B9f3C2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'mETH',
            'name': 'DMM - ETH',
            'address': '0x6FEC3a5e49748088C2b9b1Ef9A6a762ABdD07805',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x6FEC3a5e49748088C2b9b1Ef9A6a762ABdD07805/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SMRTR',
            'name': 'SmarterCoin',
            'address': '0x6D923f688C7FF287dc3A5943CAeefc994F97b290',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x6d923f688c7ff287dc3a5943caeefc994f97b290.png',
            'coingeckoId': 'smart-coin-smrtr',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'DLC',
            'name': 'DashLeague Crystals',
            'address': '0x6Cc8EF6CfC173Fc55A920C29E05222DEaD33B573',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/26339/thumb/soul_prism.png?1657498000',
            'coingeckoId': 'dashleague-crystals',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'mDAI',
            'name': 'DMM - DAI',
            'address': '0x6CB5008Ca0CC13862CB47906F541672e8f51A6EF',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x6CB5008Ca0CC13862CB47906F541672e8f51A6EF/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'OGUC',
            'name': 'Only Graded Coin',
            'address': '0x6C7b8DC81Ae49eA793998a0c784db9Bc05981A3c',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'aAVAXb',
            'name': 'Ankr Reward-Earning Staked AVAX',
            'address': '0x6C6f910A79639dcC94b4feEF59Ff507c2E843929',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x6c6f910a79639dcc94b4feef59ff507c2e843929.png',
            'coingeckoId': 'ankr-avalanche-reward-earning-bond',
            'listedIn': [
                '1inch',
                'openocean',
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ANT',
            'name': 'Aragon Network Token',
            'address': '0x6C67e7D38570d6c7FFFdbB930cF204D97C62C470',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x6C67e7D38570d6c7FFFdbB930cF204D97C62C470/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'POLAR',
            'name': 'POLAR',
            'address': '0x6C1c0319d8dDcb0ffE1a68C5b3829Fd361587DB4',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/22682/small/lZqXDw8a_400x400.png?1649143703',
            'coingeckoId': 'polar',
            'listedIn': [
                'coingecko',
                'joe',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'UND',
            'name': 'Unbound Dollar',
            'address': '0x6B56Ec4a92765203508FB40feC9fa23E549B705a',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x6B56Ec4a92765203508FB40feC9fa23E549B705a/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'STORM',
            'name': 'Storm',
            'address': '0x6AFD5A1ea4b793CC1526d6Dc7e99A608b356eF7b',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://ethapi.openocean.finance/logos/avax/0x6afd5a1ea4b793cc1526d6dc7e99a608b356ef7b.png',
            'coingeckoId': 'storm-token',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'PRQ',
            'name': 'Parsiq Token',
            'address': '0x6A8E6794ab77C63c3C90A62F1088F16AC61F463D',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x6A8E6794ab77C63c3C90A62F1088F16AC61F463D/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'PARTY',
            'name': 'Partyswap',
            'address': '0x69A61f38Df59CBB51962E69C54D39184E21C27Ec',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x69a61f38df59cbb51962e69c54d39184e21c27ec/logo.png',
            'coingeckoId': 'partyswap',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'HAKU',
            'name': 'HakuSwap',
            'address': '0x695Fa794d59106cEbd40ab5f5cA19F458c723829',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x695Fa794d59106cEbd40ab5f5cA19F458c723829/logo.png',
            'coingeckoId': 'hakuswap',
            'listedIn': [
                'coingecko',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PRDX',
            'name': 'Predix Network',
            'address': '0x693656BE08A4C74236110ccdf4da42Ef31379E25',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x693656BE08A4C74236110ccdf4da42Ef31379E25/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ACS',
            'name': 'ACryptoS',
            'address': '0x68f7a9e795C7074F2D0e698101d5c3Fea7aa0acb',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SNX',
            'name': 'Synthetix Network Token',
            'address': '0x68e44C4619db40ae1a0725e77C02587bC8fBD1c9',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x68e44C4619db40ae1a0725e77C02587bC8fBD1c9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ANRX',
            'name': 'AnRKey X',
            'address': '0x68Fa782392ff75689b6EE6E1559de2Afc634DCe8',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x68Fa782392ff75689b6EE6E1559de2Afc634DCe8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ROY',
            'name': 'Crypto Royale',
            'address': '0x68EE0D0aad9e1984aF85CA224117E4D20eaF68BE',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/20668/thumb/ROY_logo_new_design_small.png?1637547627',
            'coingeckoId': 'crypto-royale',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'AVWBTC',
            'name': 'avWBTC',
            'address': '0x686bEF2417b6Dc32C50a3cBfbCC3bb60E1e9a15D',
            'decimals': 8,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x9ff58f4ffb29fa2266ab25e75e2a8b3503311656.png',
            'coingeckoId': 'avwbtc',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'FUKU',
            'name': 'Furukuru',
            'address': '0x685b63CFE0179b3EFb70A01dCb1D648549AA192d',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/19204/thumb/furukuru.PNG?1634687345',
            'coingeckoId': 'furukuru',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PLU',
            'name': 'Pluton',
            'address': '0x680e3f5d629ECF176150E343D9EfA1aA1062659D',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x680e3f5d629ECF176150E343D9EfA1aA1062659D/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'MDT',
            'name': 'Measurable Data Token',
            'address': '0x66a41BAD9103435c57E1dABE10093Dc5a19ee99f',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x66a41BAD9103435c57E1dABE10093Dc5a19ee99f/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'PROPEL',
            'name': 'Propel',
            'address': '0x65c9d9d080714cDa7b5d58989Dc27f897F165179',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BLZ',
            'name': 'Bluzelle Token',
            'address': '0x6572450E88918154B6f059aa7fCAbA37f5ddc490',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x6572450E88918154B6f059aa7fCAbA37f5ddc490/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'HUSKY',
            'name': 'Husky AVAX',
            'address': '0x65378b697853568dA9ff8EaB60C13E1Ee9f4a654',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x65378b697853568da9ff8eab60c13e1ee9f4a654.png',
            'coingeckoId': 'husky-avax',
            'listedIn': [
                'coingecko',
                '1inch',
                'joe',
                'pangolin',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BRO',
            'name': 'Brokkr',
            'address': '0x65031e28Cb0E8CC21Ae411f9dD22c9b1bd260Ce4',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/27813/thumb/bro.png?1665908418',
            'coingeckoId': 'brokkr',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FEED',
            'name': 'Feeder.finance',
            'address': '0x6496994241804D7fE2b032901931e03bCD82301F',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x6496994241804d7fe2b032901931e03bcd82301f/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'GYSR',
            'name': 'Geyser',
            'address': '0x6419e589dB783c5396d94f3237879a010fdB5C44',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x6419e589dB783c5396d94f3237879a010fdB5C44/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'AAVE',
            'name': 'Aave',
            'address': '0x63a72806098Bd3D9520cC43356dD78afe5D386D9',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9.png',
            'coingeckoId': 'aave',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'joe',
                'pangolin',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CYT',
            'name': 'Coinary Token',
            'address': '0x63D6258E3143a1392D8343222d669de204398c8F',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'COOK',
            'name': 'Cook',
            'address': '0x637afeff75ca669fF92e4570B14D6399A658902f',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xff75ced57419bcaebe5f05254983b013b0646ef5.png',
            'coingeckoId': 'cook',
            'listedIn': [
                'coingecko',
                '1inch',
                'joe',
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'BSGG',
            'name': 'Betswap gg',
            'address': '0x63682bDC5f875e9bF69E201550658492C9763F89',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/17169.png',
            'coingeckoId': 'betswap-gg',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'RouterProtocol',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'STHO',
            'name': 'Staked Thorus',
            'address': '0x63468133ed352E602bEB61DD254D6060Ad2fe419',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/22383/thumb/sTHO_200x200.png?1641786706',
            'coingeckoId': 'stakedthorus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GMX',
            'name': 'GMX',
            'address': '0x62edc0692BD897D2295872a9FFCac5425011c661',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x62edc0692bd897d2295872a9ffcac5425011c661.png',
            'coingeckoId': 'gmx',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'joe',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'KALM',
            'name': 'KALM',
            'address': '0x62AceEA3e666C5706Ce1c61055fAc1A669d31D93',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/15849/thumb/kalmar.png?1660289892',
            'coingeckoId': 'kalmar',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'LRC',
            'name': 'LoopringCoin V2',
            'address': '0x628A9639cc78F46604A625452C0242c7B487BA3c',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x628A9639cc78F46604A625452C0242c7B487BA3c/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'PLEB',
            'name': 'PlebToken',
            'address': '0x625fc9bb971BB305A2Ad63252665DcFE9098bEE9',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x625fc9bb971bb305a2ad63252665dcfe9098bee9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'AUSDC',
            'name': 'Aave USDC',
            'address': '0x625E7708f30cA75bfd92586e17077590C60eb4cD',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1648800598729_3527648953250244.png',
            'coingeckoId': 'aave-usdc',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'JPEG',
            'name': 'JPEGvaultDAO',
            'address': '0x6241af3817Db48a7F9E19FD9446d78E50936d275',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/24656/thumb/JPEGvault_-_Black_font.png?1648526731',
            'coingeckoId': 'jpegvaultdao-2',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'RUGPULL',
            'name': 'Rugpull Prevention',
            'address': '0x61eCd63e42C27415696e10864d70ecEA4aA11289',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x61eCd63e42C27415696e10864d70ecEA4aA11289/logo.png',
            'coingeckoId': 'rugpull-prevention',
            'listedIn': [
                'coingecko',
                'pangolin',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MTA',
            'name': 'Meta',
            'address': '0x61EDA5B986b9da6A67a2a128e67ee7CED890DEAb',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x61EDA5B986b9da6A67a2a128e67ee7CED890DEAb/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ECH',
            'name': 'Echelon',
            'address': '0x6195DE423da947f52AE8d55331C207c963eD2b3f',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/25498/thumb/SpO_F8a0_400x400.jpg?1652077746',
            'coingeckoId': 'echelon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEXT',
            'name': 'DEXTools',
            'address': '0x618B994F06F7168bd3e24C05321cCf0Afd30D6bc',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x618B994F06F7168bd3e24C05321cCf0Afd30D6bc/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'OLIVE',
            'name': 'Olive Cash',
            'address': '0x617724974218A18769020A70162165A539c07E8a',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x617724974218A18769020A70162165A539c07E8a/logo.png',
            'coingeckoId': 'olivecash',
            'listedIn': [
                'coingecko',
                'elkfinance'
            ]
        },
        {
            'symbol': 'Nsure',
            'name': 'Nsure Network Token',
            'address': '0x6169F17c609D14F253d0d54a96df6eFD2A44147a',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x6169F17c609D14F253d0d54a96df6eFD2A44147a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'PIZZA',
            'name': 'Pizza Game',
            'address': '0x6121191018BAf067c6Dc6B18D42329447a164F05',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0x6121191018BAf067c6Dc6B18D42329447a164F05/logo.png',
            'coingeckoId': 'pizza-game',
            'listedIn': [
                'coingecko',
                'joe',
                'lifinance'
            ]
        },
        {
            'symbol': 'KALLY',
            'name': 'Polkally',
            'address': '0x609D6eA495E00c982Df8f76fDbAA14cD8F922d16',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PNG',
            'name': 'Pangolin',
            'address': '0x60781C2586D68229fde47564546784ab3fACA982',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x60781c2586d68229fde47564546784ab3faca982.png',
            'coingeckoId': 'pangolin',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'joe',
                'pangolin',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PIPT',
            'name': 'Power Index Pool Token',
            'address': '0x606f5C16c01372C28345de54cf6F4ff901d934B6',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x606f5C16c01372C28345de54cf6F4ff901d934B6/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'CVR',
            'name': 'PolkaCover',
            'address': '0x606E714710b0426d3E786394Ada61d5B1492C39f',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x606E714710b0426d3E786394Ada61d5B1492C39f/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SURE',
            'name': 'inSure DeFi',
            'address': '0x5fC17416925789E0852FBFcd81c490ca4abc51F9',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/10354/thumb/logo-grey-circle.png?1614910406',
            'coingeckoId': 'insure',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUILD',
            'name': 'BUILD',
            'address': '0x5f018e73C185aB23647c82bD039e762813877f0e',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/26533/thumb/BUILD.png?1658714842',
            'coingeckoId': 'build',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'DTH',
            'name': 'Dether',
            'address': '0x5eC7E661fB06ccFBC371Fb463a284705D53DF32a',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x5eC7E661fB06ccFBC371Fb463a284705D53DF32a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'FERA',
            'name': 'FERA',
            'address': '0x5dc1E85C8Db7F79bbd63e6Ae35AbEb50AD55756D',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x5dc1E85C8Db7F79bbd63e6Ae35AbEb50AD55756D/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'FLIXX',
            'name': 'Flixx',
            'address': '0x5d1CE423031a2661F960740f15a93073e6ccAb13',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x5d1CE423031a2661F960740f15a93073e6ccAb13/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'RPL',
            'name': 'Rocket Pool',
            'address': '0x5cDAD843078930C8fEB1d50bE474acCf11B7ada1',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x5cDAD843078930C8fEB1d50bE474acCf11B7ada1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'MAI',
            'name': 'miMatic',
            'address': '0x5c49b268c9841AFF1Cc3B0a418ff5c3442eE3F3b',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xa3fa99a148fa48d14ed51d610c367c61876997f1.png',
            'coingeckoId': null,
            'listedIn': [
                'RouterProtocol',
                'pangolin',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PAMP',
            'name': 'Pamp Network',
            'address': '0x5b1cdDC4e6C9E6864832954d0cF43f91952CD7B9',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x5b1cdDC4e6C9E6864832954d0cF43f91952CD7B9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'STBZ',
            'name': 'Stabilize Token',
            'address': '0x5a21a9e09667A67a898dE061D4bC61e92f20404e',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x5a21a9e09667A67a898dE061D4bC61e92f20404e/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SLIME',
            'name': 'Snail Trail',
            'address': '0x5a15Bdcf9a3A8e799fa4381E666466a516F2d9C8',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x5a15Bdcf9a3A8e799fa4381E666466a516F2d9C8/logo_24.png',
            'coingeckoId': 'snail-trail',
            'listedIn': [
                'coingecko',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'ZIPT',
            'name': 'Zippie',
            'address': '0x5ED880a1a8e25515D2e881eEBa115462b824Ac5B',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x5ED880a1a8e25515D2e881eEBa115462b824Ac5B/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'FARM',
            'name': 'FARM Reward Token',
            'address': '0x5E92Fb74d337cd3914E0E48a7E679f87f2585471',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x5E92Fb74d337cd3914E0E48a7E679f87f2585471/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'AHE',
            'name': 'AbeatsHeroEnhencement',
            'address': '0x5E46c4AA8d9284d08e2Ad399b389ea4Cf0D51Bdc',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/26054/thumb/coin-ahe.png?1655454024',
            'coingeckoId': 'abeatsheroenhencement',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'DIBS',
            'name': 'dibs.money',
            'address': '0x5E430F88D1BE82EB3eF92b6fF06125168fD5DCf2',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BOND',
            'name': 'BarnBridge Governance Token',
            'address': '0x59Cd8bb3E49930F313eD744585E4067bc45cF85d',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x59Cd8bb3E49930F313eD744585E4067bc45cF85d/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'AVIC',
            'name': 'Victory',
            'address': '0x59B18817CA9f4ad2dEE6FBf30132dF6AEb9D763d',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/26603/thumb/avic-02.png?1658989396',
            'coingeckoId': 'victory',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZRX',
            'name': '0x',
            'address': '0x596fA47043f99A4e0F122243B841E55375cdE0d2',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/863/thumb/0x.png?1547034672',
            'coingeckoId': '0x',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'GAJ',
            'name': 'Gaj Finance',
            'address': '0x595c8481c48894771CE8FaDE54ac6Bf59093F9E8',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x595c8481c48894771CE8FaDE54ac6Bf59093F9E8/logo_24.png',
            'coingeckoId': 'gaj',
            'listedIn': [
                'coingecko',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'IDEX',
            'name': 'IDEX Token',
            'address': '0x59535b9BA6029edb7588dF41Ed388584FBEA706C',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x59535b9BA6029edb7588dF41Ed388584FBEA706C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'LINK',
            'name': 'Chainlink',
            'address': '0x5947BB275c521040051D82396192181b413227A3',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x697256caa3ccafd62bb6d3aa1c7c5671786a5fd9.png',
            'coingeckoId': 'chainlink',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'joe',
                'pangolin',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'YAK',
            'name': 'Yield Yak',
            'address': '0x59414b3089ce2AF0010e7523Dea7E2b35d776ec7',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x59414b3089ce2af0010e7523dea7e2b35d776ec7.png',
            'coingeckoId': 'yield-yak',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'joe',
                'pangolin',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WISE',
            'name': 'Wise Token',
            'address': '0x5940b937d1dc86Bd44E535b75C95e9bA10e1ac33',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x5940b937d1dc86Bd44E535b75C95e9bA10e1ac33/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ZEE',
            'name': 'ZeroSwapToken',
            'address': '0x58DC26DA5bfc714F73fD4a4dc768901ed9B8Ed1a',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x58DC26DA5bfc714F73fD4a4dc768901ed9B8Ed1a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'VTX',
            'name': 'Vector Finance',
            'address': '0x5817D4F0b62A59b17f75207DA1848C2cE75e7AF4',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0x5817D4F0b62A59b17f75207DA1848C2cE75e7AF4/logo.png',
            'coingeckoId': 'vector-finance',
            'listedIn': [
                'coingecko',
                'openocean',
                'joe',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'BCDT',
            'name': 'Blockchain Certified Data Token',
            'address': '0x57D4A335260af430F5e0754D99d20Da2f1528BdF',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x57D4A335260af430F5e0754D99d20Da2f1528BdF/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'YFFI',
            'name': 'yffi.finance',
            'address': '0x5777E014b585A5F05dB9902ef944Df9C45F2054C',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x5777E014b585A5F05dB9902ef944Df9C45F2054C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'NMR',
            'name': 'Numeraire',
            'address': '0x57541c10591Df7568BDc9D93f769d44eAc1e3c3a',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x57541c10591Df7568BDc9D93f769d44eAc1e3c3a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'xJOE',
            'name': 'JoeBar',
            'address': '0x57319d41F71E81F3c65F2a47CA4e001EbAFd4F33',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://snowtrace.io/token/images/xjoe_32.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'STONK',
            'name': 'STONK',
            'address': '0x571FB151CC10F76d5A9a4b48f6D568Fb7E1eEbF8',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x571FB151CC10F76d5A9a4b48f6D568Fb7E1eEbF8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'MNTP',
            'name': 'Goldmint MNT Prelaunch Token',
            'address': '0x56f45Ca7C6e8d5550b36f4C69a0dEA44defEe3Ef',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x56f45Ca7C6e8d5550b36f4C69a0dEA44defEe3Ef/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'LF',
            'name': 'Life DAO',
            'address': '0x5684a087C739A2e845F4AaAaBf4FBd261edc2bE8',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1640172932821_9120856902758341.png',
            'coingeckoId': 'life-dao',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'TRYB',
            'name': 'BiLira',
            'address': '0x564A341Df6C126f90cf3ECB92120FD7190ACb401',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x2c537e5624e4af88a7ae4060c022609376c8d0eb.png',
            'coingeckoId': 'bilira',
            'listedIn': [
                'coingecko',
                '1inch',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'PLOT',
            'name': 'PLOT',
            'address': '0x5643c59d08d9ac382EeB224894608D52c7fcd908',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x5643c59d08d9ac382EeB224894608D52c7fcd908/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'DEXE',
            'name': 'Dexe',
            'address': '0x5574eDff4EF9Fce15B22DDB37A7F419b2Abd729E',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x5574eDff4EF9Fce15B22DDB37A7F419b2Abd729E/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'GRAPE',
            'name': 'Grape Finance',
            'address': '0x5541D83EFaD1f281571B343977648B75d95cdAC2',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x5541D83EFaD1f281571B343977648B75d95cdAC2/logo.png',
            'coingeckoId': 'grape-finance',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'PHA',
            'name': 'Phala',
            'address': '0x5535483ed8781784b1b1cC431c4dc9c25D39eCB5',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x5535483ed8781784b1b1cC431c4dc9c25D39eCB5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'KGC',
            'name': 'Kingdom Quest',
            'address': '0x54a77F27D2346c3A8F5B43A501434f0f21f33e3C',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/25021/thumb/Q-SHreiW_400x400.jpg?1649835774',
            'coingeckoId': 'kingdom-quest',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VALOR',
            'name': 'ValorToken',
            'address': '0x5499B77D5Ddc35680A26Ff270D96A5c2eB859df4',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x5499B77D5Ddc35680A26Ff270D96A5c2eB859df4/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'LGCY',
            'name': 'LGCY Network',
            'address': '0x548A24D7eB18ea8A771645651EE799807d41F2dF',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x548A24D7eB18ea8A771645651EE799807d41F2dF/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'nICE',
            'name': 'Staked Ice Tokens',
            'address': '0x546D10C1378FEBEf063B72Ae4865496Ad801c386',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'INSUR',
            'name': 'InsurAce',
            'address': '0x544c42fBB96B39B21DF61cf322b5EDC285EE7429',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x544c42fbb96b39b21df61cf322b5edc285ee7429.png',
            'coingeckoId': 'insurace',
            'listedIn': [
                'coingecko',
                '1inch',
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'TRACTOR',
            'name': 'Tractor Joe',
            'address': '0x542fA0B261503333B90fE60c78F2BeeD16b7b7fD',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1635754380046_7659663962089835.png',
            'coingeckoId': 'tractor-joe',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'PARETO',
            'name': 'Pareto Network Token',
            'address': '0x54266edA68834321B5BB81a8A8a48d5459c92456',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x54266edA68834321B5BB81a8A8a48d5459c92456/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'STAKE',
            'name': 'STAKE',
            'address': '0x540641C9B0fcb979496A8c03C711033239C841d5',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x540641C9B0fcb979496A8c03C711033239C841d5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'AVWETH',
            'name': 'avWETH',
            'address': '0x53f7c5869a859F0AeC3D334ee8B4Cf01E3492f21',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x030ba81f1c18d280636f32af80b9aad02cf0854e.png',
            'coingeckoId': 'avweth',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'PAR',
            'name': 'Parachute',
            'address': '0x53b464Bb9EfEBe9b314f97e631b47e1C0300FE21',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x53b464Bb9EfEBe9b314f97e631b47e1C0300FE21/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'COMP',
            'name': 'Compound',
            'address': '0x53CEedB4f6f277edfDDEdB91373B044FE6AB5958',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x53CEedB4f6f277edfDDEdB91373B044FE6AB5958/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'eXRD',
            'name': 'E-RADIX',
            'address': '0x535E15B13f2A82350E8C02d62BDbA385a6307c30',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x535E15B13f2A82350E8C02d62BDbA385a6307c30/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'avUSDT',
            'name': 'Aave Avalanche Market USDT',
            'address': '0x532E6537FEA298397212F09A61e03311686f548e',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x3ed3b47dd13ec9a98b44e6204a523e766b225811.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'RWX',
            'name': 'RacewayX',
            'address': '0x525b43A49bE2Ed530e3516C22bd7ECbcF1586AD4',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'APY',
            'name': 'APY Governance Token',
            'address': '0x524CefBaD8aa1e7921d465A9f056fc52FF6a284F',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x524CefBaD8aa1e7921d465A9f056fc52FF6a284F/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'DGNX',
            'name': 'DegenX',
            'address': '0x51e48670098173025C477D9AA3f0efF7BF9f7812',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://storage.googleapis.com/xy-finance-images/token-list/DGNX.png',
            'coingeckoId': 'degenx',
            'listedIn': [
                'coingecko',
                'pangolin',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'LIFT',
            'name': 'Uplift',
            'address': '0x513C3200F227ebB62e3B3d00B7a83779643a71CF',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DSHARE',
            'name': 'DSHARE',
            'address': '0x5126f540A90B43eB3c20fc5d734EcC749f2AaDC7',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BETA',
            'name': 'Beta Finance',
            'address': '0x511D35c52a3C244E7b8bd92c0C297755FbD89212',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/18715/thumb/beta_finance.jpg?1633087053',
            'coingeckoId': 'beta-finance',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'DAXE',
            'name': 'DAXE',
            'address': '0x5106f787E8778a86D1928ed5ad0B0215dBFA00b8',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x5106f787e8778a86d1928ed5ad0b0215dbfa00b8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DLQ',
            'name': 'Deliq',
            'address': '0x50c72103940D419FB64448F258f7EAbbA784F84B',
            'decimals': 16,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/24457/thumb/JT5SiYhr_400x400.jpg?1647679369',
            'coingeckoId': 'deliq',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WBTC',
            'name': 'Wrapped Bitcoin',
            'address': '0x50b7545627a5162F82A992c33b87aDc75187B218',
            'decimals': 8,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x2260fac5e5542a773aa44fbcfedf7c193bc2c599.png',
            'coingeckoId': 'wrapped-bitcoin',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'joe',
                'pangolin',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DBY',
            'name': 'Metaderby',
            'address': '0x5085434227aB73151fAd2DE546210Cbc8663dF96',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0x5085434227aB73151fAd2DE546210Cbc8663dF96/logo.png',
            'coingeckoId': 'metaderby',
            'listedIn': [
                'coingecko',
                'joe',
                'lifinance'
            ]
        },
        {
            'symbol': 'HATCHY',
            'name': 'HatchyPocket',
            'address': '0x502580fc390606b47FC3b741d6D49909383c28a9',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/27560/thumb/HATCHY_200x200_Logo.png?1664519097',
            'coingeckoId': 'hatchypocket',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QNT',
            'name': 'Quant',
            'address': '0x4fcC1E009ef85B35d39B3Fe533d27751e4CFa8f7',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x4fcC1E009ef85B35d39B3Fe533d27751e4CFa8f7/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'TSD',
            'name': 'Teddy Dollar',
            'address': '0x4fbf0429599460D327BD5F55625E30E4fC066095',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x4fbf0429599460D327BD5F55625E30E4fC066095/logo_24.png',
            'coingeckoId': 'teddy-dollar',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'JEWEL',
            'name': 'Jewels',
            'address': '0x4f60a160D8C2DDdaAfe16FCC57566dB84D674BD6',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x4f60a160d8c2dddaafe16fcc57566db84d674bd6.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'joe',
                'pangolin',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'JGN',
            'name': 'Juggernaut',
            'address': '0x4e3642603a75528489C2D94f86e9507260d3c5a1',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/12761/thumb/juggernaut_logo.png?1602428976',
            'coingeckoId': 'juggernaut',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORBIT',
            'name': 'Orbit',
            'address': '0x4bf5cd1AC6FfF12E88AEDD3c70EB4148F90F8894',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/25807/thumb/apple-touch-icon.png?1653978963',
            'coingeckoId': 'orbit-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'Diamond',
            'name': 'diamondtoken.world',
            'address': '0x4aBBc3275f8419685657C2DD69b8ca2e26F23F8E',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x4abbc3275f8419685657c2dd69b8ca2e26f23f8e/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SPARQ',
            'name': 'Sparq',
            'address': '0x4FA62300C7A499D088cbf9FF079ad5d105d8b46b',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/27893/thumb/sparq.jpeg?1666253180',
            'coingeckoId': 'sparq',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GHOST',
            'name': 'GHOST',
            'address': '0x4F229fF652D4dB584BF4b3512aE430edECb85971',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x4F229fF652D4dB584BF4b3512aE430edECb85971/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'GTON',
            'name': 'GTON CAPITAL',
            'address': '0x4E720DD3Ac5CFe1e1fbDE4935f386Bb1C66F4642',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/15728/thumb/GC_logo_200x200.png?1642669327',
            'coingeckoId': 'gton-capital',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LST',
            'name': 'Lendroid Support Token',
            'address': '0x4E0226a638adCBB43C99131c743B9Aba15Ff3040',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x4E0226a638adCBB43C99131c743B9Aba15Ff3040/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'MILK',
            'name': 'Dairy Money MILK',
            'address': '0x4D81911F0E30D2E12dcc954091701B39dC59e34a',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/23599/thumb/milk.png?1644669841',
            'coingeckoId': 'dairy-money-milk',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TAU',
            'name': 'Lamden Tau',
            'address': '0x4D49159F233506087426094CDa371B5817f30331',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x4D49159F233506087426094CDa371B5817f30331/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'mUSD',
            'name': 'mStable USD',
            'address': '0x4D06D5296c0BE7857a9C43B5EB1770909d40CB25',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x4D06D5296c0BE7857a9C43B5EB1770909d40CB25/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'LYD',
            'name': 'Lydia Finance',
            'address': '0x4C9B4E1AC6F24CdE3660D5E4Ef1eBF77C710C084',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x4c9b4e1ac6f24cde3660d5e4ef1ebf77c710c084.png',
            'coingeckoId': 'lydia-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pangolin',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'XMTL',
            'name': 'NovaXMetal',
            'address': '0x4C1057455747e3eE5871D374FdD77A304cE10989',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/19644/thumb/xmtl.png?1635729097',
            'coingeckoId': 'novaxmetal',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NEWO',
            'name': 'New Order',
            'address': '0x4Bfc90322dD638F81F034517359BD447f8E0235a',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0x4Bfc90322dD638F81F034517359BD447f8E0235a/logo.png',
            'coingeckoId': 'new-order',
            'listedIn': [
                'coingecko',
                'joe',
                'lifinance'
            ]
        },
        {
            'symbol': 'DOLLA',
            'name': 'dolla',
            'address': '0x4B322765D761984C0f9E72200B62a9b445F38caC',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x4B322765D761984C0f9E72200B62a9b445F38caC/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'SONIC',
            'name': 'AVAXRush',
            'address': '0x4Aca0ad6357b918e3d06BB1a0BCC403619177523',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x4Aca0ad6357b918e3d06BB1a0BCC403619177523/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'BOT',
            'name': 'Bounce Token',
            'address': '0x4AcEA0eB348a6DB49cb3F8A1D62625342D5f8751',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x4AcEA0eB348a6DB49cb3F8A1D62625342D5f8751/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'RDN',
            'name': 'Raiden Token',
            'address': '0x4A8918352cCB78CF6bd34Bf89D501d5578ee6504',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x4A8918352cCB78CF6bd34Bf89D501d5578ee6504/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'WETH',
            'name': 'WETH',
            'address': '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2.png',
            'coingeckoId': 'weth',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'joe',
                'pangolin',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'RICH',
            'name': 'RICH',
            'address': '0x4960bc6032351ab87627B8F36b37B4938A44C7eF',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x4960bc6032351ab87627B8F36b37B4938A44C7eF/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'CCC',
            'name': 'Cross Chain Capital',
            'address': '0x4939B3313E73ae8546b90e53E998E82274afDbDB',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/20834/thumb/CCC_updated_logo.jpg?1637737447',
            'coingeckoId': 'cross-chain-capital',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BRZ',
            'name': 'BRZ',
            'address': '0x491a4eB4f1FC3BfF8E1d2FC856a6A46663aD556f',
            'decimals': 4,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x491a4eB4f1FC3BfF8E1d2FC856a6A46663aD556f/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'PLAYMATES',
            'name': 'Redlight Node District',
            'address': '0x490bf3ABcAb1fB5c88533d850F2a8d6D38298465',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/23338/small/rnd-coin.png?1643883118',
            'coingeckoId': 'redlight-node-district',
            'listedIn': [
                'coingecko',
                'joe',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'CAI',
            'name': 'Colony Avalanche Index',
            'address': '0x48f88A3fE843ccb0b5003e70B4192c1d7448bEf0',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0x48f88A3fE843ccb0b5003e70B4192c1d7448bEf0/logo.png',
            'coingeckoId': 'colony-avalanche-index',
            'listedIn': [
                'coingecko',
                'joe',
                'lifinance'
            ]
        },
        {
            'symbol': 'YTS',
            'name': 'YetiSwap',
            'address': '0x488F73cddDA1DE3664775fFd91623637383D6404',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x488f73cddda1de3664775ffd91623637383d6404.png',
            'coingeckoId': 'yetiswap',
            'listedIn': [
                'coingecko',
                '1inch',
                'pangolin',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WELT',
            'name': 'FABWELT',
            'address': '0x48844dDBa89799DC40eC31728dAC629802D407f3',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SPEAR',
            'name': 'Spear Finance',
            'address': '0x4860A28Be33aD04dAdFc91722728EFfa8430F836',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/25513/thumb/a.png?1652163937',
            'coingeckoId': 'spear-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SKILL',
            'name': 'Skill Token',
            'address': '0x483416eB3aFA601B9C6385f63CeC0C82B6aBf1fb',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x483416eB3aFA601B9C6385f63CeC0C82B6aBf1fb/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'DODO',
            'name': 'DODO bird',
            'address': '0x480d6193B2a2Db2702F3ce6FE5Bc1F0c8a95336B',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x480d6193B2a2Db2702F3ce6FE5Bc1F0c8a95336B/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'kSEED',
            'name': 'KUSH.FINANCE',
            'address': '0x47e4c63922766e1b386fa7296c994aC474062Bd4',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x47e4c63922766e1b386fa7296c994aC474062Bd4/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'MELT',
            'name': 'Defrost Finance',
            'address': '0x47EB6F7525C1aA999FBC9ee92715F5231eB1241D',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x47eb6f7525c1aa999fbc9ee92715f5231eb1241d.png',
            'coingeckoId': 'defrost-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'joe',
                'pangolin',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'avDAI',
            'name': 'Aave Avalanche Market DAI',
            'address': '0x47AFa96Cdc9fAb46904A55a6ad4bf6660B53c38a',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x028171bca77440897b824ca71d1c56cac55b68a3.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'FSW',
            'name': 'FalconSwap Token',
            'address': '0x479914df5B637aa9d439246116d49245Db678f97',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x479914df5B637aa9d439246116d49245Db678f97/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'CRV',
            'name': 'Curve DAO Token',
            'address': '0x47536F17F4fF30e64A96a7555826b8f9e66ec468',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://static.debank.com/image/avax_token/logo_url/0x47536f17f4ff30e64a96a7555826b8f9e66ec468/42e8c4eb3a83479f172dd56c67eb7e88.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CHIHUA',
            'name': 'Chihuahuax',
            'address': '0x4735721ED62713E3A141C939f4Aa55cA8aD5F66A',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/18300/thumb/Chihuahuax.png?1631500443',
            'coingeckoId': 'chihuahuax',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'RAE',
            'name': 'RAE Token',
            'address': '0x4732Cc19937e4DaF4BDcA6698f2552b2E9F04813',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x4732Cc19937e4DaF4BDcA6698f2552b2E9F04813/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'GRT',
            'name': 'Graph Token',
            'address': '0x46C54b16aF7747067f412c78eBaDaE203a26aDa0',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x46C54b16aF7747067f412c78eBaDaE203a26aDa0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'avUSDC',
            'name': 'Aave Avalanche Market USDC',
            'address': '0x46A51127C3ce23fb7AB1DE06226147F446e4a857',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xbcca60bb61934080951369a648fb03df4f96263c.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ACTA',
            'name': 'ACTA [via ChainPort.io]',
            'address': '0x4636e16444720075db87A0a4fd998B423c2B5456',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x4636e16444720075db87a0a4fd998b423c2b5456/logo.png',
            'coingeckoId': 'acta-finance',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'HCT',
            'name': 'HurricaneSwap',
            'address': '0x45C13620B55C35A5f539d26E88247011Eb10fDbd',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x45C13620B55C35A5f539d26E88247011Eb10fDbd/logo_24.png',
            'coingeckoId': 'hurricaneswap-token',
            'listedIn': [
                'coingecko',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'ESD',
            'name': 'Empty Set Dollar',
            'address': '0x455b3FD5eF7bcA83C0c1Cd71695Ec7aEda773E4f',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x455b3FD5eF7bcA83C0c1Cd71695Ec7aEda773E4f/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SAFEMOONA',
            'name': 'SafemoonAvax',
            'address': '0x4501e37a79cf953116331a105B3644d07b74C0F8',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x4501e37a79cf953116331a105B3644d07b74C0F8/logo.png',
            'coingeckoId': 'safemoonavax',
            'listedIn': [
                'coingecko',
                'rubic',
                'elkfinance'
            ]
        },
        {
            'symbol': 'AXL',
            'name': 'Axelar',
            'address': '0x44c784266cf024a60e8acF2427b9857Ace194C5d',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/27277/thumb/V-65_xQ1_400x400.jpeg?1663121730',
            'coingeckoId': 'axelar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MEAD',
            'name': 'Thors Mead',
            'address': '0x44a45a9BaEb63c6ea4860ecf9ac5732c330C4d4E',
            'decimals': 8,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/20837/thumb/mead.png?1638251983',
            'coingeckoId': 'thors-mead',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOF',
            'name': 'Golff.finance',
            'address': '0x44D24Df9732DA8b230c819b0bf22b6C6377B42c4',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x44D24Df9732DA8b230c819b0bf22b6C6377B42c4/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'LOST',
            'name': 'Lost World',
            'address': '0x449674B82F05d498E126Dd6615a1057A9c088f2C',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x449674B82F05d498E126Dd6615a1057A9c088f2C/logo.png',
            'coingeckoId': 'lost-world',
            'listedIn': [
                'coingecko',
                'joe',
                'pangolin',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ZEE',
            'name': 'ZeroSwap',
            'address': '0x44754455564474A89358B2C2265883DF993b12F0',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x44754455564474A89358B2C2265883DF993b12F0/logo_24.png',
            'coingeckoId': 'zeroswap',
            'listedIn': [
                'coingecko',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'BNB',
            'name': 'Binance Coin  Wormhole ',
            'address': '0x442F7f22b1EE2c842bEAFf52880d4573E9201158',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/22884/thumb/BNB_wh_small.png?1644224553',
            'coingeckoId': 'binance-coin-wormhole',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIBX',
            'name': 'Shibavax',
            'address': '0x440aBbf18c54b2782A4917b80a1746d3A2c2Cce1',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x440abbf18c54b2782a4917b80a1746d3a2c2cce1.png',
            'coingeckoId': 'shibavax',
            'listedIn': [
                'coingecko',
                '1inch',
                'pangolin',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'JPYC',
            'name': 'JPY Coin',
            'address': '0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB/logo_24.png',
            'coingeckoId': 'jpy-coin',
            'listedIn': [
                'coingecko',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'AFM',
            'name': 'Alfheim Token',
            'address': '0x431289cb3a2eC0875351e33767e175c816C73B3A',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x431289cb3a2ec0875351e33767e175c816c73b3a/logo.png',
            'coingeckoId': 'alfheim-finance',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'mooJoeUSDC.e-USDC',
            'name': 'Moo Joe USDC.e-USDC',
            'address': '0x42ab5A790E99dF1b5d46f1C5C3e61d0Cd63D1f6E',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'USDQ',
            'name': 'USDQ Stablecoin by Q DAO v1.0',
            'address': '0x4247beA779fE14dabD38547A7eA49d7f57bd1bea',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x4247beA779fE14dabD38547A7eA49d7f57bd1bea/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'CORE',
            'name': 'cVault.finance',
            'address': '0x424587becE1A7436Ae4a38eD9E8686992236618B',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x424587becE1A7436Ae4a38eD9E8686992236618B/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'CUDOS',
            'name': 'CudosToken',
            'address': '0x422b2328A16b41ecCA56E1854be9943526aD7647',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x422b2328A16b41ecCA56E1854be9943526aD7647/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'HT',
            'name': 'HuobiToken',
            'address': '0x421b2a69b886BA17a61C7dAd140B9070d5Ef300B',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x421b2a69b886BA17a61C7dAd140B9070d5Ef300B/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'EVO',
            'name': 'EvoVerses',
            'address': '0x42006Ab57701251B580bDFc24778C43c9ff589A1',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/25088/large/evoToken.png?1650269135',
            'coingeckoId': 'evoverses',
            'listedIn': [
                'coingecko',
                'dfyn',
                'RouterProtocol'
            ]
        },
        {
            'symbol': 'ZEUS',
            'name': 'Zeus Node Finance',
            'address': '0x4156F18bF7C1ef04248632C66Aa119De152D8f2E',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x4156F18bF7C1ef04248632C66Aa119De152D8f2E/logo_24.png',
            'coingeckoId': 'zeus-node-finance',
            'listedIn': [
                'coingecko',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'SNOW',
            'name': 'SnowSwap',
            'address': '0x40eB65be917e7A5AE529B2e1279E4b548A36C465',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x40eB65be917e7A5AE529B2e1279E4b548A36C465/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'WBTC',
            'name': 'Wrapped BTC',
            'address': '0x408D4cD0ADb7ceBd1F1A1C33A0Ba2098E1295bAB',
            'decimals': 8,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x408d4cd0adb7cebd1f1a1c33a0ba2098e1295bab/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ZKS',
            'name': 'Zks',
            'address': '0x40871A08cd7b9751639a0831e5a83808F4c7EBA9',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x40871A08cd7b9751639a0831e5a83808F4c7EBA9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'PAX',
            'name': 'Paxos Standard',
            'address': '0x403985fD6628E44b6fca9876575b9503cB80A47A',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x403985fD6628E44b6fca9876575b9503cB80A47A/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ROOBEE',
            'name': 'Roobee',
            'address': '0x4036f3d9c45a20f44f0b8B85dD6CA33005fF9654',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/8791/thumb/Group_11.png?1580344629',
            'coingeckoId': 'roobee',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'CATS',
            'name': 'Catoshi',
            'address': '0x402FD175049e95CeF2cC9ca1FECdb6d9736e690d',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x402FD175049e95CeF2cC9ca1FECdb6d9736e690d/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'BAS',
            'name': 'BAS',
            'address': '0x3f4409c13C3BB310317643C6ee15576b3d427Ddd',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x3f4409c13C3BB310317643C6ee15576b3d427Ddd/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'OTO',
            'name': 'OTO Protocol',
            'address': '0x3e5a9F09923936427aD6e487b24E23a862FCf6b7',
            'decimals': 5,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/24547/thumb/5TkXMvj.png?1648131261',
            'coingeckoId': 'oto-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INDEX',
            'name': 'Index',
            'address': '0x3d26cefE5fAE96FFd48801e1E61975b3CB75036B',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x3d26cefE5fAE96FFd48801e1E61975b3CB75036B/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'PBR',
            'name': 'PolkaBridge',
            'address': '0x3c09D70fB667e2B680d4FeE2951d6BCD3f8Fbaf9',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x3c09D70fB667e2B680d4FeE2951d6BCD3f8Fbaf9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'BLAST',
            'name': 'SafeBLAST',
            'address': '0x3bd9856BF578910b55261d45d9148d61C177B092',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'UNCX',
            'name': 'UniCrypt',
            'address': '0x3b9e3b5c616A1A038fDc190758Bbe9BAB6C7A857',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ACN',
            'name': 'AVAX Capital Node',
            'address': '0x3b1468a6f1E6030aBF31D81337C5D5D73d5ED5C4',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x3b1468a6f1e6030abf31d81337c5d5d73d5ed5c4/logo.png',
            'coingeckoId': 'avax-capital-node',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ARFV2',
            'name': 'BostonTerrier',
            'address': '0x3ab71CA6DA13e50Ab4966E3A0566D1b6B118c4aE',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x3ab71CA6DA13e50Ab4966E3A0566D1b6B118c4aE/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'VSN',
            'name': 'Vision Network',
            'address': '0x3aCf5DfE16cE85ED98339Dfebf2283537F2229d0',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x3aCf5DfE16cE85ED98339Dfebf2283537F2229d0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'cyUSD',
            'name': 'CreamY USD',
            'address': '0x3a9ea8880643211413609A7B717e3884816D15e7',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x3a9ea8880643211413609A7B717e3884816D15e7/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'TRND',
            'name': 'Trendering',
            'address': '0x3a2d191AE83223ed0668d9AAF180Be147Ec05ad3',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x3a2d191AE83223ed0668d9AAF180Be147Ec05ad3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'XDCE',
            'name': 'XinFin XDCE',
            'address': '0x3a162d08Fbfa687Ca00F5682c5c4F51b3aEe181C',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x3a162d08Fbfa687Ca00F5682c5c4F51b3aEe181C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': '2KEY',
            'name': 'TwoKeyEconomy',
            'address': '0x3F6372f530203daA26eF31F55017a36d6f7405E5',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x3F6372f530203daA26eF31F55017a36d6f7405E5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'AVAPAY',
            'name': 'AvaPay',
            'address': '0x3F3Fea5dd9b1940C2ee4822D7B7a06F19d58656e',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/21196/thumb/IOowVeS.png?1638523082',
            'coingeckoId': 'avapay',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'ISA',
            'name': 'Islander',
            'address': '0x3EeFb18003D033661f84e48360eBeCD181A84709',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x3EeFb18003D033661f84e48360eBeCD181A84709/logo.png',
            'coingeckoId': 'islander',
            'listedIn': [
                'coingecko',
                '1inch',
                'joe',
                'pangolin',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'sSPELL',
            'name': 'Staked Spell Token',
            'address': '0x3Ee97d514BBef95a2f110e6B9b73824719030f7a',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/logos/main/network/avalanche/0x3Ee97d514BBef95a2f110e6B9b73824719030f7a.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'XCHF',
            'name': 'CryptoFranc',
            'address': '0x3E8a3b1db5401938F6F34E4e6f2560354C182c46',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x3E8a3b1db5401938F6F34E4e6f2560354C182c46/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'NBT',
            'name': 'NIX Bridge Token',
            'address': '0x3E6867bB936e83BC686A89fdBfab3FD0D6ee3DE8',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x3E6867bB936e83BC686A89fdBfab3FD0D6ee3DE8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ONX',
            'name': 'OnX Finance',
            'address': '0x3D8f74620857dd8ED6D0dA02ceB13fd0Ed8Ba678',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/13445/thumb/onxlogo-1.png?1608629659',
            'coingeckoId': 'onx-finance',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MYB',
            'name': 'MyBit',
            'address': '0x3D7af5Cc0143402A65d0dDC7E4C559fed65AE78c',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x3D7af5Cc0143402A65d0dDC7E4C559fed65AE78c/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'NHCT',
            'name': 'Hurricane NFT',
            'address': '0x3CE2fceC09879af073B53beF5f4D04327a1bC032',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/24974/thumb/624fd8f9e5546b00017c915d_NHCT_icon_3x.png?1649651709',
            'coingeckoId': 'hurricane-nft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIC',
            'name': 'MIC',
            'address': '0x3C4dd53806347D37aF1F9CCA08C5aCA7363abADe',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x3C4dd53806347D37aF1F9CCA08C5aCA7363abADe/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SLVM',
            'name': 'Silver Mint',
            'address': '0x3C08cDC102fd746D06f85d3Ea348Ba6102C43f32',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SPD',
            'name': 'SPINDLE',
            'address': '0x3BfCDB1Ec986430ffF0e35C00D71888D305E48f3',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x3BfCDB1Ec986430ffF0e35C00D71888D305E48f3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'UMA',
            'name': 'UMA',
            'address': '0x3Bd2B1c7ED8D396dbb98DED3aEbb41350a5b2339',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/10951/thumb/UMA.png?1586307916',
            'coingeckoId': 'uma',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'MIMATIC',
            'name': 'MAI',
            'address': '0x3B55E45fD6bd7d4724F5c47E0d1bCaEdd059263e',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/15264/thumb/mimatic-red.png?1620281018',
            'coingeckoId': 'mimatic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PPAY',
            'name': 'Plasma',
            'address': '0x3B295608d13083270214C0778624BCebCa5df3DA',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x3B295608d13083270214C0778624BCebCa5df3DA/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SXP',
            'name': 'Swipe',
            'address': '0x3AfAD3EB65DeAf28f594958717530bC66D6Cdd1c',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x3AfAD3EB65DeAf28f594958717530bC66D6Cdd1c/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'PINT',
            'name': 'Pub Finance',
            'address': '0x3Af0eB8BcBd4C4C6E26e309c4E47Af59Bad5FC2f',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/14016/thumb/PINT.png?1614064578',
            'coingeckoId': 'pub-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KNC',
            'name': 'Kyber Network Crystal',
            'address': '0x39fC9e94Caeacb435842FADeDeCB783589F50f5f',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/14899/thumb/RwdVsGcw_400x400.jpg?1618923851',
            'coingeckoId': 'kyber-network-crystal',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'oldSUSHI',
            'name': 'Old SushiToken',
            'address': '0x39cf1BD5f15fb22eC3D9Ff86b0727aFc203427cc',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/logos/main/network/avalanche/0x39cf1BD5f15fb22eC3D9Ff86b0727aFc203427cc.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'FLY',
            'name': 'FLY',
            'address': '0x39aA4a3eD26f47a1Abe31De8a3722834E94aa6a5',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x39aa4a3ed26f47a1abe31de8a3722834e94aa6a5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ACE',
            'name': 'ACEToken',
            'address': '0x39D486FF2a97b0724C5f012Ccb84F2a58F05057C',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AVXT',
            'name': 'Avaxtars',
            'address': '0x397bBd6A0E41bdF4C3F971731E180Db8Ad06eBc1',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x397bBd6A0E41bdF4C3F971731E180Db8Ad06eBc1/logo.png',
            'coingeckoId': 'avaxtars',
            'listedIn': [
                'coingecko',
                'openocean',
                'pangolin',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WLRS',
            'name': 'Walrus',
            'address': '0x395908aeb53d33A9B8ac35e148E9805D34A555D3',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x395908aeb53d33a9b8ac35e148e9805d34a555d3.png',
            'coingeckoId': 'walrus',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'CREAMA',
            'name': 'Creama',
            'address': '0x3947146F331b9Ef6448064c6311cdd24d9467a1A',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/28073/thumb/200.png?1667379552',
            'coingeckoId': 'creama',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RUNE',
            'name': 'THORChain ETH.RUNE',
            'address': '0x390ba0fb0Bd3Aa2a5484001606329701148074e6',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x390ba0fb0Bd3Aa2a5484001606329701148074e6/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'HBOT',
            'name': 'Hummingbot',
            'address': '0x38Dcf0532699b880E6a125F7d918380524CD60a6',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/21717/thumb/PDPuf0tJ_400x400.jpg?1639863782',
            'coingeckoId': 'hummingbot',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHEESE',
            'name': 'CheeseFry',
            'address': '0x381fAE62DA74959a4D89E452872991767171EB4b',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': '$KANDY',
            'name': 'Kandy',
            'address': '0x37deD665a387a6f170FB60376B3057f09df6c0Ea',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x37ded665a387a6f170fb60376b3057f09df6c0ea/logo.png',
            'coingeckoId': 'kandy',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SET',
            'name': 'Stabilize SET',
            'address': '0x37d87e316CB4e35163881fDb6c6Bc0CdBa91dc0A',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/19753/thumb/SET_200.png?1635824246',
            'coingeckoId': 'stabilize-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SUSHI',
            'name': 'Sushi',
            'address': '0x37B608519F91f70F2EeB0e5Ed9AF4061722e4F76',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x6b3595068778dd592e39a122f4f5a5cf09c90fe2.png',
            'coingeckoId': 'sushi',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pangolin',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MATH',
            'name': 'MATH Token',
            'address': '0x374C62a3B07350de41C4A95c4094474f84d7BF66',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x374C62a3B07350de41C4A95c4094474f84d7BF66/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'HONEY',
            'name': 'Honey',
            'address': '0x372B73B2225f4f422F58A772d2DCaa9048904C1d',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x372b73b2225f4f422f58a772d2dcaa9048904c1d/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'VEE',
            'name': 'Vee Finance',
            'address': '0x3709E8615E02C15B096f8a9B460ccb8cA8194e86',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x3709E8615E02C15B096f8a9B460ccb8cA8194e86/logo_24.png',
            'coingeckoId': 'vee-finance',
            'listedIn': [
                'coingecko',
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'wNXM',
            'name': 'Wrapped NXM',
            'address': '0x3585E1f43Af5A0E5a9429A8058BDf999ED67f81d',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x3585E1f43Af5A0E5a9429A8058BDf999ED67f81d/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'BAO',
            'name': 'BaoToken',
            'address': '0x353Be78373b6b75B5A61d952fccCb95C1d3e0bc8',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x353Be78373b6b75B5A61d952fccCb95C1d3e0bc8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'AVAI',
            'name': 'Orca AVAI',
            'address': '0x346A59146b9b4a77100D369a3d18E8007A9F46a6',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x346A59146b9b4a77100D369a3d18E8007A9F46a6/logo.png',
            'coingeckoId': 'orca-avai',
            'listedIn': [
                'coingecko',
                '1inch',
                'pangolin',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'EWTB',
            'name': 'Energy Web Token Bridged',
            'address': '0x3447d187934d323bDd1BCc6EDC643D3C8d05D86c',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x3447d187934d323bDd1BCc6EDC643D3C8d05D86c/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'FRONT',
            'name': 'Frontier Token',
            'address': '0x3441061CbfDF2351E010DF5D962f1cF0626CF19F',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x3441061CbfDF2351E010DF5D962f1cF0626CF19F/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ORBS',
            'name': 'Orbs',
            'address': '0x340fE1D898ECCAad394e2ba0fC1F93d27c7b717A',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x340fE1D898ECCAad394e2ba0fC1F93d27c7b717A/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'ORBIT',
            'name': 'Europa',
            'address': '0x340990358AE38008181b6dB6156D9021A2D425dA',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/25648/thumb/cN22QtVT.jpg?1653026998',
            'coingeckoId': 'europa',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OM',
            'name': 'MANTRA DAO',
            'address': '0x33d6584872635e1BA681Ad814B98b57198cf33eF',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x33d6584872635e1BA681Ad814B98b57198cf33eF/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'RGT',
            'name': 'Rari Governance Token',
            'address': '0x337e4Ff263BC2E8dFb9a1a8a1AF883f0AdF953f0',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x337e4Ff263BC2E8dFb9a1a8a1AF883f0AdF953f0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SRN',
            'name': 'SIRIN',
            'address': '0x3365A191353a7670CaAC8B4BE19C2F34DcD07320',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x3365A191353a7670CaAC8B4BE19C2F34DcD07320/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'RFuel',
            'name': 'Rio Fuel Token',
            'address': '0x3361a925eCBa04e4De70C0Fa6310e710a2079a28',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x3361a925eCBa04e4De70C0Fa6310e710a2079a28/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'UNI',
            'name': 'UNI',
            'address': '0x3350289891543865F8bb41EE747f31ce5D5e3739',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'LIQR',
            'name': 'Topshelf Finance',
            'address': '0x33333ee26a7d02e41c33828B42Fb1E0889143477',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/21580/thumb/b-MDs-Uqg-Q-400x400.jpg?1639532412',
            'coingeckoId': 'topshelf-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MANA',
            'name': 'Decentraland MANA',
            'address': '0x332877d7b83D98eFC3e22C203c54E6e62F7f35e9',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x332877d7b83D98eFC3e22C203c54E6e62F7f35e9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'GEEQ',
            'name': 'Geeq',
            'address': '0x3326235Ec1AFf2799dE463413114b800d251089d',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x3326235Ec1AFf2799dE463413114b800d251089d/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'INK',
            'name': 'INK',
            'address': '0x32975907733f93305BE28E2bfd123666b7A9c863',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x32975907733f93305be28e2bfd123666b7a9c863/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DAMO',
            'name': 'Hai DAMO',
            'address': '0x32958A28D1641AF1d4bdd790550fC0D8049dbd3A',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/23419/thumb/61fca5f0a6d496a64342a1d5_twitter_logo_%281%29.png?1644194693',
            'coingeckoId': 'hai-governence-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOHM',
            'name': 'Governance OHM',
            'address': '0x321E7092a180BB43555132ec53AaA65a5bF84251',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x321e7092a180bb43555132ec53aaa65a5bf84251.png',
            'coingeckoId': 'governance-ohm',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'joe',
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'NU',
            'name': 'NuCypher',
            'address': '0x32141622A7C79790176670ffFcA17154678A9A24',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x32141622A7C79790176670ffFcA17154678A9A24/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'FROST',
            'name': 'FROST',
            'address': '0x320aDa89DbFA3A154613D2731c9BC3a4030DbA19',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1640318459223_9295976235790222.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'XETA',
            'name': 'XANA',
            'address': '0x31c994AC062C1970C086260Bc61babB708643fAc',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/24379/thumb/XANA_Logo_neon_pink.png?1658197388',
            'coingeckoId': 'xana',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'NGNC',
            'name': 'NGN Coin',
            'address': '0x3134210a6BdB55eED6E59905bC34928DF6d74830',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/list/master/logos/token-logos/network/avalanche/0x3134210a6BdB55eED6E59905bC34928DF6d74830.jpg',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SWAPXI',
            'name': 'SwapXI',
            'address': '0x312eE43Df66d1Fd1EA28e5b28F355Da84dCA13C2',
            'decimals': 12,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x312eE43Df66d1Fd1EA28e5b28F355Da84dCA13C2/logo_24.png',
            'coingeckoId': 'swapxi-token',
            'listedIn': [
                'coingecko',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'DFD',
            'name': 'DefiDollar DAO',
            'address': '0x30EB0D35147B7a40dB1A54a98F25317E844670e5',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x30EB0D35147B7a40dB1A54a98F25317E844670e5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'BUIDL',
            'name': 'DFOHub',
            'address': '0x30D26864af10565CEdD9E4d5b1fdBd52B49144DD',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x30D26864af10565CEdD9E4d5b1fdBd52B49144DD/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'XOR',
            'name': 'Sora Token',
            'address': '0x307A2a7127429f0C24c607E4633d17B6E98E8372',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x307A2a7127429f0C24c607E4633d17B6E98E8372/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'GTFX',
            'name': 'Gitshock Finance',
            'address': '0x306e974624511E3937F37E551C5736F1b2aD21eb',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/28033/thumb/Logo_Gitshock_HD.png?1667117754',
            'coingeckoId': 'gitshock-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RIBT',
            'name': 'Ribbit',
            'address': '0x302Abf007C2045F1bC0867a4b7abaaE2152e0EB3',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/25404/thumb/EunlozE__400x400.jpg?1651730745',
            'coingeckoId': 'ribbit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PONY',
            'name': 'PONY Index',
            'address': '0x2fF0B946A6782190C4Fe5D4971CFE79F0b6E4df2',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SPHRI',
            'name': 'Spherium',
            'address': '0x2fD4D793c1905D82018d75e3b09d3035856890a1',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/17787/thumb/Group_15.png?1629252159',
            'coingeckoId': 'spherium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HOTCROSS',
            'name': 'Hot Cross',
            'address': '0x2f86508f41310D8D974B76deb3D246c0caa71cf5',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/15706/thumb/Hotcross.png?1632197570',
            'coingeckoId': 'hot-cross',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OLE',
            'name': 'OpenLeverage',
            'address': '0x2f50405FFe9b8AA109836D18F217929f33A177bb',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AID',
            'name': 'AidCoin',
            'address': '0x2e1Bc9fA6F579471e7e09084a054a858d792D981',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x2e1Bc9fA6F579471e7e09084a054a858d792D981/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'BAG',
            'name': 'BananaApeGarden',
            'address': '0x2dee42737d769f7444F8E0C0365E0EE1f992A88c',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/24502/thumb/logo-200x200.jpg?1647874007',
            'coingeckoId': 'aananaapegarden',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XWLRS',
            'name': 'xWalrus',
            'address': '0x2dc3Bb328000553D1D64ec1BEF00572F62B5Ec7C',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/27728/thumb/2.png?1667726876',
            'coingeckoId': 'xwalrus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DFH',
            'name': 'DeFiHelper Governance Token',
            'address': '0x2c90fA253A19ebeCD05456955958765162A98C4F',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'LPT',
            'name': 'Livepeer Token',
            'address': '0x2c67EC45B2E7138823dee0576D0d17Ac6Aa36b74',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x2c67EC45B2E7138823dee0576D0d17Ac6Aa36b74/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'MLN',
            'name': 'Melon Token',
            'address': '0x2bD2e0C3d39d6c82EaCA300958aa2E4be6740223',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x2bD2e0C3d39d6c82EaCA300958aa2E4be6740223/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ETHP',
            'name': 'ETHP',
            'address': '0x2b3A6972a3450697c64a0B50EF0D859dBe657b77',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x2b3a6972a3450697c64a0b50ef0d859dbe657b77/logo.png',
            'coingeckoId': 'etherprint',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SAVAX',
            'name': 'BENQI Liquid Staked AVAX',
            'address': '0x2b2C81e08f1Af8835a78Bb2A90AE924ACE0eA4bE',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/23657/small/savax_blue.png?1644989825',
            'coingeckoId': 'benqi-liquid-staked-avax',
            'listedIn': [
                'coingecko',
                'openocean',
                'joe',
                'pangolin',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'METAG',
            'name': 'MetagamZ',
            'address': '0x2b0B320B47D8e0DD0E4477cf90c307c7ed984Ad2',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/23929/thumb/FlrPQkBM_400x400.jpg?1645692811',
            'coingeckoId': 'metagamz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFE2',
            'name': 'SAFE2',
            'address': '0x2a1F8a24575261919f839f4254FdE9bc4b8edE7A',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x2a1F8a24575261919f839f4254FdE9bc4b8edE7A/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'STG',
            'name': 'Stargate Finance',
            'address': '0x2F6F07CDcf3588944Bf4C42aC74ff24bF56e7590',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x2f6f07cdcf3588944bf4c42ac74ff24bf56e7590.png',
            'coingeckoId': 'stargate-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'joe',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'CRND',
            'name': 'Crypto Nodes',
            'address': '0x2EfB4FEc95DeAAb8c199CA5089E0cFD52373f92D',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/25288/thumb/LOGO_%282%29.png?1651130786',
            'coingeckoId': 'crypto-nodes',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'C4C',
            'name': 'Crypto 4 A Cause Fund',
            'address': '0x2EBfF165CB363002C5f9cBcfd6803957BA0B7208',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'APEX',
            'name': 'Apex Foundation',
            'address': '0x2E768E86F691AFe8a6F9277bBCD9c570Ab868FC0',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/25863/thumb/YpSle3LT_400x400.jpg?1654230888',
            'coingeckoId': 'apex-foundation',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOLD',
            'name': 'MinerJoe',
            'address': '0x2D052048Fe7e4f59277E5E5ED5D21d35419Fe747',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/23508/thumb/L1jI4JfA_400x400.jpg?1644299173',
            'coingeckoId': 'minerjoe',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XIO',
            'name': 'Blockzero Labs',
            'address': '0x2CF51e73C3516f3d86E9C0B4De0971Dbf0766Fd4',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/10029/thumb/blockzero.jpg?1611110205',
            'coingeckoId': 'xio',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ABYSS',
            'name': 'ABYSS',
            'address': '0x2C4Ac7ABe6D09F81a775DE153E5593c8C56884eb',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x2C4Ac7ABe6D09F81a775DE153E5593c8C56884eb/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'DOS',
            'name': 'DOS Network Token',
            'address': '0x2C05b134888419b497fE5489D2762031a2de8031',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x2C05b134888419b497fE5489D2762031a2de8031/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'iETH',
            'name': 'Fulcrum ETH iToken',
            'address': '0x2Ae2CD4e23c09a2B68a24D56D5cf3c4e887Da849',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x2Ae2CD4e23c09a2B68a24D56D5cf3c4e887Da849/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'PROPS',
            'name': 'Props Token',
            'address': '0x2A5A930d00110a4970bC68cF4Bcb207588cA0D2d',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x2A5A930d00110a4970bC68cF4Bcb207588cA0D2d/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'KDAO',
            'name': 'KarmaDao',
            'address': '0x29DEBf83Eb8Bc91ce9A13Ac7E42D37E6e2Abb480',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/24640/thumb/KARMA_LOGO.jpg?1648468676',
            'coingeckoId': 'karmadao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SNCT',
            'name': 'Snake City',
            'address': '0x2905d6D6957983d9ED73BC019fF2782c39dd7a49',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/27331/thumb/02-38-02-LogoFooter_%281%29.png?1663397749',
            'coingeckoId': 'snake-city',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SLUSH',
            'name': 'Iceslush',
            'address': '0x28bF9CB0a8c0a6Fb01680dAAae9D6aE76234491b',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x28bf9cb0a8c0a6fb01680daaae9d6ae76234491b/logo.png',
            'coingeckoId': 'iceslush-finance',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'USDK',
            'name': 'USDK',
            'address': '0x28A9f61B5dB4F4349C2edFE7a9B234f71e4ad2A7',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x28A9f61B5dB4F4349C2edFE7a9B234f71e4ad2A7/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'USDCx',
            'name': 'Superfluid USDC',
            'address': '0x288398F314D472B82C44855F3f6fF20b633c2A97',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://static.debank.com/image/avax_token/logo_url/0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e/708ab2e9c38549ac4cf9294b996d4dce.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'deUSDC',
            'name': 'deBridge USD Coin',
            'address': '0x28690ec942671aC8d9Bc442B667EC338eDE6dFd3',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x28690ec942671ac8d9bc442b667ec338ede6dfd3.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'MIMiverse',
            'name': 'MIMivese',
            'address': '0x284748b6C0c1Ec75C73285f4557fE8F51F800480',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x284748b6c0c1ec75c73285f4557fe8f51f800480/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SL3',
            'name': 'SL3',
            'address': '0x2841A8a2ce98A9d21aD8C3B7Fc481527569bd7bb',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x2841A8a2ce98A9d21aD8C3B7Fc481527569bd7bb/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'GEM',
            'name': 'GEM',
            'address': '0x27de370Fe0498307E91047C9a60051d53b92114a',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'OPEN',
            'name': 'Openworld',
            'address': '0x27a339d9B59b21390d7209b78a839868E319301B',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ORO',
            'name': 'ORO Token',
            'address': '0x2796213bd26CE2270839b6d40E8D5904d8cDAA42',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x2796213bd26CE2270839b6d40E8D5904d8cDAA42/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'OM',
            'name': 'OM Token',
            'address': '0x27850FcbCfF7DAFB16176144B9193C6D310DCF72',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x27850FcbCfF7DAFB16176144B9193C6D310DCF72/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'OMG',
            'name': 'OMGToken',
            'address': '0x276C6670b97F22cE7Ad754b08CB330DECb6a3332',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x276C6670b97F22cE7Ad754b08CB330DECb6a3332/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'RVT',
            'name': 'RvT',
            'address': '0x26526EBD75Ed27d5B553d06d6bcE8210bA0bc50b',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x26526EBD75Ed27d5B553d06d6bcE8210bA0bc50b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'WBNB',
            'name': 'Wrapped BNB',
            'address': '0x264c1383EA520f73dd837F915ef3a732e204a493',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c.png',
            'coingeckoId': 'wbnb',
            'listedIn': [
                'coingecko',
                '1inch',
                'joe',
                'pangolin',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'axlUST',
            'name': 'Axelar Wrapped UST',
            'address': '0x260Bbf5698121EB85e7a74f2E45E16Ce762EbE11',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/12681/small/UST.png?1601612407',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'pangolin',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'mooJoeUSDT-USDTe',
            'name': 'Moo Joe USDT-USDTe',
            'address': '0x258e9884c111E2e3e0273372521982bd57ef29Bd',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MFT',
            'name': 'Mainframe Token',
            'address': '0x254Aa21D2996400b19CeE93623C307D6E973Ea3f',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x254Aa21D2996400b19CeE93623C307D6E973Ea3f/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'DCAR',
            'name': 'Dragon Crypto Argenti',
            'address': '0x250bdca7D1845cd543BB55E7D82dcA24D48E9f0F',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0x250bdca7D1845cd543BB55E7D82dcA24D48E9f0F/logo.png',
            'coingeckoId': 'dragon-crypto-argenti',
            'listedIn': [
                'coingecko',
                'joe',
                'lifinance'
            ]
        },
        {
            'symbol': 'CRV.e',
            'name': 'Curve DAO Token',
            'address': '0x249848BeCA43aC405b8102Ec90Dd5F22CA513c06',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x249848beca43ac405b8102ec90dd5f22ca513c06/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'AVANET',
            'name': 'AvangerNews',
            'address': '0x246A08a58549E14D0Fcb24D7E9227661EdE807A8',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x246a08a58549e14d0fcb24d7e9227661ede807a8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'POPS',
            'name': 'Swapsicle',
            'address': '0x240248628B7B6850352764C5dFa50D1592A033A8',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x240248628b7b6850352764c5dfa50d1592a033a8.png',
            'coingeckoId': 'swapsicle-pops',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'yyDAI+yUSDC+yUSDT',
            'name': 'yearn Curve.fi yDAI/yUSDC/yUSDT/yTUSD',
            'address': '0x23f717b177eaf0bB93a726D2b8C4Bd11d4c4950b',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x23f717b177eaf0bB93a726D2b8C4Bd11d4c4950b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'CDS',
            'name': 'Crypto Development Services',
            'address': '0x23f07a1C03e7C6D0C88e0E05E79B6E3511073fD5',
            'decimals': 8,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/21304/thumb/JhUZ3Rk.png?1638883308',
            'coingeckoId': 'crypto-development-services',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'FOAM',
            'name': 'FOAM Token',
            'address': '0x23F3C4fF7ef5C752593966BDe70de2Db81398Aa6',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x23F3C4fF7ef5C752593966BDe70de2Db81398Aa6/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'FET',
            'name': 'Fetch',
            'address': '0x23D7e6Af758883F4976617DAB2641af94FF7CA1F',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x23D7e6Af758883F4976617DAB2641af94FF7CA1F/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SIFU',
            'name': 'SIFU',
            'address': '0x237917E8a998b37759c8EE2fAa529D60c66c2927',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/24692/thumb/token_%283%29.png?1648620836',
            'coingeckoId': 'sifu-vision',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'OPT',
            'name': 'Open Predict Token',
            'address': '0x2378B1EEb109bd40FF585AB9A92aC3Fd9E90cee3',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x2378B1EEb109bd40FF585AB9A92aC3Fd9E90cee3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'XPANZ',
            'name': 'Expanz',
            'address': '0x235Dc4738290Aad823b8C4Ac2012C188C5232933',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/25874/thumb/20439.png?1654250175',
            'coingeckoId': 'expanz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STX',
            'name': 'Stox',
            'address': '0x232F79C05CB34de19C79104068E76452B624baB3',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x232F79C05CB34de19C79104068E76452B624baB3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'PTP',
            'name': 'Platypus Finance',
            'address': '0x22d4002028f537599bE9f666d1c4Fa138522f9c8',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x22d4002028f537599be9f666d1c4fa138522f9c8.png',
            'coingeckoId': 'platypus-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'joe',
                'pangolin',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'GEN',
            'name': 'DAOstack',
            'address': '0x2263483B187d8C99d1E7D1f737183097c7071fe2',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x2263483B187d8C99d1E7D1f737183097c7071fe2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'TUNDRA',
            'name': 'Tundra',
            'address': '0x21c5402C3B7d40C89Cc472C9dF5dD7E51BbAb1b1',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x21c5402C3B7d40C89Cc472C9dF5dD7E51BbAb1b1/logo.png',
            'coingeckoId': 'tundra-token',
            'listedIn': [
                'coingecko',
                'pangolin',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ANORTIS',
            'name': 'Anortis',
            'address': '0x218aC1bc9Ad8c0562988B03263540ec85C462F5a',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x218ac1bc9ad8c0562988b03263540ec85c462f5a/logo.png',
            'coingeckoId': 'anortis',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ESR',
            'name': 'EsportsRef',
            'address': '0x21804205C744dd98fbc87898704564d5094bB167',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'HOLY',
            'name': 'Holyheld',
            'address': '0x2167d6A882e9beB324D08e6663d4D419ac578792',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x2167d6A882e9beB324D08e6663d4D419ac578792/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'FXS',
            'name': 'Frax Share',
            'address': '0x214DB107654fF987AD859F34125307783fC8e387',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x3432b6a60d23ca0dfca7761b7ab56459d9c964d0.png',
            'coingeckoId': 'frax-share',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'pangolin',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'ALPHA',
            'name': 'Alpha Venture DAO',
            'address': '0x2147EFFF675e4A4eE1C2f918d181cDBd7a8E208f',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xa1faa113cbe53436df28ff0aee54275c13b40975.png',
            'coingeckoId': 'alpha-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'joe',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'BTU',
            'name': 'BTU Protocol',
            'address': '0x211960f8260DB1B0171c33931a2aeFd9562592B0',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x211960f8260DB1B0171c33931a2aeFd9562592B0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ANKR',
            'name': 'Ankr',
            'address': '0x20CF1b6E9d856321ed4686877CF4538F2C84B4dE',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1663822909158_03175858068336357.svg',
            'coingeckoId': 'ankr',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'HEGIC',
            'name': 'Hegic',
            'address': '0x20642e9cdd6BFe701817A7b50dE89777C8F2b208',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x20642e9cdd6BFe701817A7b50dE89777C8F2b208/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SNO Token',
            'name': 'SNO',
            'address': '0x1fE4869f2C5181b9CD780a7E16194FA2c4C4293D',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x1fe4869f2c5181b9cd780a7e16194fa2c4c4293d/logo.png',
            'coingeckoId': 'snowy-owl',
            'listedIn': [
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ARGON',
            'name': 'ArgonToken',
            'address': '0x1fE4751d9bDabaC8D90067056cB45AB6823d2C12',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x1fE4751d9bDabaC8D90067056cB45AB6823d2C12/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'SYN',
            'name': 'Synapse',
            'address': '0x1f1E7c893855525b303f99bDF5c3c05Be09ca251',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x1f1e7c893855525b303f99bdf5c3c05be09ca251_1.png',
            'coingeckoId': 'synapse-2',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'joe',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ETHS',
            'name': 'Etherstones',
            'address': '0x1efE6a8AF01920912F0B4f7F26fc16e294664c4C',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x1efe6a8af01920912f0b4f7f26fc16e294664c4c/logo.png',
            'coingeckoId': 'etherstones',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': '3ULL',
            'name': 'PLAYA3ULL Token',
            'address': '0x1e5e26aF04Af6b1e215199Baf8B5d8C64838f0b0',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x1e5e26af04af6b1e215199baf8b5d8c64838f0b0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'INFI',
            'name': 'INFI',
            'address': '0x1d590AD61A7b56071A5858301aCe7DD3D31f0Dd0',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x1d590AD61A7b56071A5858301aCe7DD3D31f0Dd0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'MND',
            'name': 'Mind Music',
            'address': '0x1cd2528522A17B6Be63012fB63AE81f3e3e29D97',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/19104/thumb/logo-main_%282%29.png?1634506312',
            'coingeckoId': 'mind-music',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TBTC',
            'name': 'tBTC',
            'address': '0x1c24D4ef397F6F8c80403f52E9D11Bef1D129a93',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x1c24D4ef397F6F8c80403f52E9D11Bef1D129a93/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'PAPER',
            'name': 'Paper',
            'address': '0x1affBc17938a25d245e1B7eC6f2fc949df8E9760',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/23510/thumb/0xea97c7c1c89d4084e0bfb88284fa90243779da9f.png?1644300084',
            'coingeckoId': 'paper-fantom',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PIGGY',
            'name': 'Piggy Finance',
            'address': '0x1a877B68bdA77d78EEa607443CcDE667B31B0CdF',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/22076/thumb/crypto-tomb-cash-3f0e3fdc.png?1640754032',
            'coingeckoId': 'piggy-finance',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'XBP',
            'name': 'BlitzPredict',
            'address': '0x1F5AE9F37A18Aa6797cdb58838F2E05160082Ae7',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x1F5AE9F37A18Aa6797cdb58838F2E05160082Ae7/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SFIN',
            'name': 'sled.finance',
            'address': '0x1F1FE1eF06ab30a791d6357FdF0a7361B39b1537',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x1F1FE1eF06ab30a791d6357FdF0a7361B39b1537/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'AVME',
            'name': 'AVME',
            'address': '0x1ECd47FF4d9598f89721A2866BFEb99505a413Ed',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x1ecd47ff4d9598f89721a2866bfeb99505a413ed.png',
            'coingeckoId': 'avme',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'pangolin',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'Joseph',
            'name': 'Joseph',
            'address': '0x1E5aB54f76aD72e3De5D99bD86F0eE7623c9C08A',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x1e5ab54f76ad72e3de5d99bd86f0ee7623c9c08a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MSHARE',
            'name': 'MSHARE',
            'address': '0x1E0E8E6a2C8a3Ec61F65E97338871Bd7dDcb76d0',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DON',
            'name': 'Dogeon',
            'address': '0x1DB749847C4abB991d8B6032102383e6BfD9B1c7',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x1DB749847C4abB991d8B6032102383e6BfD9B1c7/logo_24.png',
            'coingeckoId': 'dogeon',
            'listedIn': [
                'coingecko',
                '1inch',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'KAI',
            'name': 'KardiaChain Token',
            'address': '0x1D81360dADf2E1756FaeAe46072dD12997170F46',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x1D81360dADf2E1756FaeAe46072dD12997170F46/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'DSHARE',
            'name': 'DSHARE',
            'address': '0x1D80583D5C03b70720a91bd4C17BB744183C1b9C',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'XIO',
            'name': 'XIO Network',
            'address': '0x1Ce24Ac9EC3fbc4BA0c3836123953EA0c86336b9',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x1Ce24Ac9EC3fbc4BA0c3836123953EA0c86336b9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'TUSD',
            'name': 'TrueUSD',
            'address': '0x1C20E891Bab6b1727d14Da358FAe2984Ed9B59EB',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x1c20e891bab6b1727d14da358fae2984ed9b59eb.png',
            'coingeckoId': 'true-usd',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ZOO',
            'name': 'ZooKeeper',
            'address': '0x1B88D7aD51626044Ec62eF9803EA264DA4442F32',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x1B88D7aD51626044Ec62eF9803EA264DA4442F32/logo_24.png',
            'coingeckoId': 'zookeeper',
            'listedIn': [
                'coingecko',
                'joe',
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'MILK2',
            'name': 'MilkyWay Token by SpaceSwap v2',
            'address': '0x1A4a456DcB9415D6FBac1148A656BD93a78c43e1',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x1A4a456DcB9415D6FBac1148A656BD93a78c43e1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'BUSD.e',
            'name': 'Binance USD',
            'address': '0x19860CCB0A68fd4213aB9D8266F7bBf05A8dDe98',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x4fabb145d64652a948d72533023f6e7a623c7c53.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ALINK',
            'name': 'Aave LINK',
            'address': '0x191c10Aa4AF7C30e871E70C95dB0E4eb77237530',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1648800470041_9427809593504186.png',
            'coingeckoId': 'aave-link',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'DIGITS',
            'name': 'Digits DAO',
            'address': '0x18E2269F98DB2eda3CFc06E6cCA384b291e553d9',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/23551/thumb/Logo-Digits-DAO-Icon-01.jpg?1644462126',
            'coingeckoId': 'digits-dao',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'WOZX',
            'name': 'EFFORCE IEO',
            'address': '0x1830DD37A0ddd3207fFAc9013E4F4D60FEC22036',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x1830DD37A0ddd3207fFAc9013E4F4D60FEC22036/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'RAMP',
            'name': 'RAMP DEFI',
            'address': '0x182795eE69b458930633A60DA79E8F9787A4828c',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x182795eE69b458930633A60DA79E8F9787A4828c/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SWAP',
            'name': 'TrustSwap Token',
            'address': '0x17908a369a1884Ce287Bf79c269a16F0Fb84082E',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x17908a369a1884Ce287Bf79c269a16F0Fb84082E/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'GRAPE',
            'name': 'GrapeSwap Finance',
            'address': '0x17757dcE604899699b79462a63dAd925B82FE221',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/27694/thumb/grape.png?1665240188',
            'coingeckoId': 'grapeswap-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASPELL',
            'name': 'AVA SPELL',
            'address': '0x1762f180381Ca1de617Aa1F1b90a359376E67cA5',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x1762f180381ca1de617aa1f1b90a359376e67ca5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'XTR',
            'name': 'Xtremcoin',
            'address': '0x1748E1A07E82762660b4e57838B570dCb73bF75D',
            'decimals': 8,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BAC',
            'name': 'BAC',
            'address': '0x17002A182B20F7a393808c5E013EC5fe770C9302',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x17002A182B20F7a393808c5E013EC5fe770C9302/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'PAXG',
            'name': 'Paxos Gold',
            'address': '0x1687b16087B576E403C8d6926fBc0798e48FD0de',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x1687b16087B576E403C8d6926fBc0798e48FD0de/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'POLY',
            'name': 'Polymath',
            'address': '0x1676C3D77ac75741678d6Ca28f288352a57D0973',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x1676C3D77ac75741678d6Ca28f288352a57D0973/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'C98',
            'name': 'Coin98',
            'address': '0x165DBb08de0476271714952C3C1F068693bd60D7',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'KENSHI',
            'name': 'Kenshi',
            'address': '0x164caf66c45e483F7eE647CcaD275B35B4C75719',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/21904/thumb/32x32_%281%29.png?1654588618',
            'coingeckoId': 'kenshi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ENXT',
            'name': 'ENXT Token',
            'address': '0x164334Ed9E63FbEdC8B52E6dbD408Af4F051419f',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x164334Ed9E63FbEdC8B52E6dbD408Af4F051419f/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'BUSINESSES',
            'name': 'VM Tycoons Businesses',
            'address': '0x15c841043e13fFAA9a99FabEa236D40F45615623',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x15c841043e13fFAA9a99FabEa236D40F45615623/logo_24.png',
            'coingeckoId': 'vm-tycoons-businesses',
            'listedIn': [
                'coingecko',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'FLOT',
            'name': 'Frozentomb LOT',
            'address': '0x155dBbB7CE079f7b8EcfFf9688AdF6e6efb69a24',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/23591/thumb/200flot.png?1644649435',
            'coingeckoId': 'frozentomb-lot',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EUROS',
            'name': 'SpiceEURO',
            'address': '0x1556e2d8ff76DFbC5946aE18260e78A6ed745FED',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/26443/thumb/E4B6C93A-E2A1-4F53-BB26-15D0FE802812.png?1658110215',
            'coingeckoId': 'spiceeuro',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'POA20',
            'name': 'POA ERC20 on Foundation',
            'address': '0x153446d731f6a23661BebCD3e86431c36bA440fB',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x153446d731f6a23661BebCD3e86431c36bA440fB/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'BTCB',
            'name': 'Bitcoin Avalanche Bridged  BTC b ',
            'address': '0x152b9d0FdC40C096757F570A51E494bd4b943E50',
            'decimals': 8,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x152b9d0fdc40c096757f570a51e494bd4b943e50.png',
            'coingeckoId': 'bitcoin-avalanche-bridged-btc-b',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'joe',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'DPI',
            'name': 'DefiPulse Index',
            'address': '0x150DC9795908a27988aBf71C30E9B1647922A7B3',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x150DC9795908a27988aBf71C30E9B1647922A7B3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ZINC',
            'name': 'ZINC',
            'address': '0x14B1f37c46ECf29C9657585DF0Dd7CEe1eC7C569',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x14B1f37c46ECf29C9657585DF0Dd7CEe1eC7C569/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'MEMO',
            'name': 'MEMOries',
            'address': '0x136Acd46C134E8269052c62A67042D6bDeDde3C9',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x136acd46c134e8269052c62a67042d6bdedde3c9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': '3CRV',
            'name': 'LP 3pool Curve',
            'address': '0x1337BedC9D22ecbe766dF105c9623922A27963EC',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/12972/thumb/3pool_128.png?1603948039',
            'coingeckoId': 'lp-3pool-curve',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIM',
            'name': 'Magic Internet Money',
            'address': '0x130966628846BFd36ff31a822705796e8cb8C18D',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x130966628846BFd36ff31a822705796e8cb8C18D/logo.png',
            'coingeckoId': 'magic-internet-money',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'joe',
                'pangolin',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BNPL',
            'name': 'BNPL Pay',
            'address': '0x1263fEA931b86f3e8cE8AFBf29f66631b7be9347',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/18543/thumb/Untitled_%28200_%C3%97_200_px%29_%281%29.png?1667541672',
            'coingeckoId': 'bnpl-pay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HMT',
            'name': 'Human Token',
            'address': '0x12365293cb6477d4fc2686e46BB97E3Fb64f1550',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'LUNA',
            'name': 'Axelar Wrapped LUNA',
            'address': '0x120AD3e5A7c796349e591F1570D9f7980F4eA9cb',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x120AD3e5A7c796349e591F1570D9f7980F4eA9cb/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'HDO',
            'name': 'HADO',
            'address': '0x11eb3aA66FE1f2B75cB353D3e874E96968182BdA',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SOUL',
            'name': 'SoulPower',
            'address': '0x11d6DD25c1695764e64F439E32cc7746f3945543',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BPT',
            'name': 'Bold Point',
            'address': '0x1111111111182587795eF1098ac7da81a108C97a',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/25112/small/LogoToken.png?1650348493',
            'coingeckoId': 'bold-point',
            'listedIn': [
                'coingecko',
                'joe',
                'pangolin',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'YUSD',
            'name': 'YUSD Stablecoin',
            'address': '0x111111111111ed1D73f860F57b2798b683f2d325',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x111111111111ed1d73f860f57b2798b683f2d325.png',
            'coingeckoId': 'yusd-stablecoin',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'joe',
                'lifinance'
            ]
        },
        {
            'symbol': 'RARI',
            'name': 'Rarible',
            'address': '0x10D56b868A32670f27478Ac628a2376A235F9bB8',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x10D56b868A32670f27478Ac628a2376A235F9bB8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'DCAU',
            'name': 'Dragon Crypto Aurum',
            'address': '0x100Cc3a819Dd3e8573fD2E46D1E66ee866068f30',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x100cc3a819dd3e8573fd2e46d1e66ee866068f30.png',
            'coingeckoId': 'dragon-crypto-aurum',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'joe',
                'pangolin',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HOOP',
            'name': 'Hoopoe Ventures',
            'address': '0x0fEc6d8A84A85b79A1FFE0E28c1902E08b653EFE',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x0fEc6d8A84A85b79A1FFE0E28c1902E08b653EFE/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'MONEY',
            'name': 'Moremoney USD',
            'address': '0x0f577433Bf59560Ef2a79c124E9Ff99fCa258948',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x0f577433Bf59560Ef2a79c124E9Ff99fCa258948/logo_24.png',
            'coingeckoId': 'moremoney-usd',
            'listedIn': [
                'coingecko',
                'joe',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'BLZZ',
            'name': 'Blizz Finance',
            'address': '0x0f34919404a290e71fc6A510cB4a6aCb8D764b24',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x0f34919404a290e71fc6a510cb4a6acb8d764b24.png',
            'coingeckoId': 'blizz-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'OOE',
            'name': 'OpenOcean',
            'address': '0x0ebd9537A25f56713E34c45b38F421A1e7191469',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x0ebd9537A25f56713E34c45b38F421A1e7191469/logo_24.png',
            'coingeckoId': 'openocean',
            'listedIn': [
                'coingecko',
                'openocean',
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'SUS',
            'name': 'Amogus.Finance',
            'address': '0x0dd2d581994132C919827A2d5401ed6FFDAEFFc9',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x0dd2d581994132C919827A2d5401ed6FFDAEFFc9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'WMEMO',
            'name': 'Wonderful Memories',
            'address': '0x0da67235dD5787D67955420C84ca1cEcd4E5Bb3b',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1653301580734_6192884379415022.png',
            'coingeckoId': 'wrapped-memory',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'OTO',
            'name': 'OTO V2',
            'address': '0x0aC80E1753deA5e298E8a2b6CF53f161937806A1',
            'decimals': 5,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x0ac80e1753dea5e298e8a2b6cf53f161937806a1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'USDibs',
            'name': 'Dibs USD',
            'address': '0x0EfA5328FEfCe96C8d10661bd97403764D477853',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'XED',
            'name': 'Exeedme',
            'address': '0x0DCE209Cd97C8bd136E433703645Dc431eF93075',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x0DCE209Cd97C8bd136E433703645Dc431eF93075/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'RWS',
            'name': 'Robonomics Web Services - V1',
            'address': '0x0Cb3Fe222303e1419EE73216e90322ae4635Fc5E',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x0Cb3Fe222303e1419EE73216e90322ae4635Fc5E/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'MEGA',
            'name': 'MegaCryptoPolis MEGA Token',
            'address': '0x0C452CCc765Ac4A5d90E40585487c482597dFDdE',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x0C452CCc765Ac4A5d90E40585487c482597dFDdE/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'GM',
            'name': 'GhostMarket',
            'address': '0x0B53b5dA7d0F275C31a6A182622bDF02474aF253',
            'decimals': 8,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/22765/thumb/gm.png?1642574907',
            'coingeckoId': 'ghostmarket',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRUST',
            'name': 'TRUST DAO',
            'address': '0x0B483a7E8119d9f9FbFF4a86cd751c51B6a81af9',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x0B483a7E8119d9f9FbFF4a86cd751c51B6a81af9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SWIFT',
            'name': 'Swift Token',
            'address': '0x0Aa4ef05B43700BF4b6E6Dc83eA4e9C2CF6Af0fA',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x0aa4ef05b43700bf4b6e6dc83ea4e9c2cf6af0fa/logo.png',
            'coingeckoId': 'swift-finance',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'AceD',
            'name': 'AceD',
            'address': '0x0A15ed1B1F1BE0b2024b4d22B4f19Ebb99A6fd11',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x0A15ed1B1F1BE0b2024b4d22B4f19Ebb99A6fd11/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SYN',
            'name': 'SynLev',
            'address': '0x09f60a231C989d0c5AdC9d3609936A3409885Bd0',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x09f60a231C989d0c5AdC9d3609936A3409885Bd0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'OCEAN',
            'name': 'Ocean Token',
            'address': '0x09b809fbB0a1fA04916Fb47b24DBc33529FD0C73',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'GF',
            'name': 'Good Fire',
            'address': '0x09Ad4a1746Ab9ECC96BB67d919DE3C6b54cc879f',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/20188/thumb/good_fire.PNG?1636611825',
            'coingeckoId': 'good-fire',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TEDDY',
            'name': 'Teddy Cash',
            'address': '0x094bd7B2D99711A1486FB94d4395801C6d0fdDcC',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x094bd7B2D99711A1486FB94d4395801C6d0fdDcC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'pangolin',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CAT',
            'name': 'CAT',
            'address': '0x094BFaC9894d2A2A35771D0BD6d2447689190F32',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/21491/thumb/kittyicon-yellow-cat.60a0fd5e.png?1639364389',
            'coingeckoId': 'cat',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'ALOT',
            'name': 'Dexalot',
            'address': '0x093783055F9047C2BfF99c4e414501F8A147bC69',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/24188/thumb/logo_200x200.png?1646838979',
            'coingeckoId': 'dexalot',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'SPACE',
            'name': 'Space Token',
            'address': '0x09211Dc67f9fe98Fb7bBB91Be0ef05f4a12FA2b2',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AKRO',
            'name': 'Akropolis',
            'address': '0x086A23685F2A33BfdeDF4dEd738e9afDdfb854Ed',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x086A23685F2A33BfdeDF4dEd738e9afDdfb854Ed/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'APE',
            'name': 'ApeCoin',
            'address': '0x0802d66f029c46E042b74d543fC43B6705ccb4ba',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0x0802d66f029c46E042b74d543fC43B6705ccb4ba/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'joe',
                'lifinance'
            ]
        },
        {
            'symbol': 'NPXS',
            'name': 'Pundi X Token',
            'address': '0x07d83B7101c540fcC1720c3d51923f218Ae9b6Ac',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x07d83B7101c540fcC1720c3d51923f218Ae9b6Ac/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'MAPLE',
            'name': 'Province',
            'address': '0x0795b0DA5AbA851E8D1f0C59771d602cAdB40D61',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/26548/thumb/logo.png?1658752071',
            'coingeckoId': 'province',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AWBTC',
            'name': 'Aave WBTC',
            'address': '0x078f358208685046a11C85e8ad32895DED33A249',
            'decimals': 8,
            'chainId': 43114,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1648801354201_4787929897969496.png',
            'coingeckoId': 'aave-wbtc',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ETHP',
            'name': 'ETHP',
            'address': '0x0755FA2F4AA6311E1D7C19990416c86F17D16F86',
            'decimals': 6,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x0755fa2f4aa6311e1d7c19990416c86f17d16f86/logo.png',
            'coingeckoId': 'etherprint',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'CUR',
            'name': 'CurToken',
            'address': '0x06f9FD59D9E009E7e47a07Df79BC994A07dcFb95',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x06f9FD59D9E009E7e47a07Df79BC994A07dcFb95/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'CAGE',
            'name': 'Coinage Finance',
            'address': '0x06ebc9c542357e2129D16717CD02c02FBC835d33',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/22300/thumb/Coinage_v2_200x200_white.png?1653464214',
            'coingeckoId': 'coinage-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHAKE',
            'name': 'SHAKE token by SpaceSwap v2',
            'address': '0x0680298EEA69e413eD02b393Fc269C2757033Ab0',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x0680298EEA69e413eD02b393Fc269C2757033Ab0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'DLAUNCH',
            'name': 'DefiLaunch Token',
            'address': '0x0659133127749Cc0616Ed6632912ddF7cc8D7545',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x0659133127749Cc0616Ed6632912ddF7cc8D7545/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'XPTP',
            'name': 'xPTP',
            'address': '0x060556209E507d30f2167a101bFC6D256Ed2f3e1',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0x060556209E507d30f2167a101bFC6D256Ed2f3e1/logo.png',
            'coingeckoId': 'xptp',
            'listedIn': [
                'coingecko',
                'joe',
                'lifinance'
            ]
        },
        {
            'symbol': 'MRFI',
            'name': 'Morphie',
            'address': '0x05e481B19129B560E921E487AdB281E70Bdba463',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/18818/thumb/FLPgwQdc_400x400.jpg?1633509975',
            'coingeckoId': 'morphie',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VALUE',
            'name': 'Value Liquidity',
            'address': '0x05Fe3039ecc7E03342521f583e9B3bb8B1bf5EB1',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x05Fe3039ecc7E03342521f583e9B3bb8B1bf5EB1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'KTE',
            'name': 'Kyte One',
            'address': '0x056D114fF1e01dE3BCA30F0Efa3655DF42880e5B',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x056D114fF1e01dE3BCA30F0Efa3655DF42880e5B/logo_24.png',
            'coingeckoId': 'kyte-one',
            'listedIn': [
                'coingecko',
                'pangolin',
                'lifinance'
            ]
        },
        {
            'symbol': 'ROOK',
            'name': 'ROOK',
            'address': '0x052c1e9de172366F30F300e805707a6520615977',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x052c1e9de172366F30F300e805707a6520615977/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SAFE',
            'name': 'yieldfarming.insure',
            'address': '0x0439b92098Bf71Dd4AbF1bA73B974a8c52f1F5F3',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x0439b92098Bf71Dd4AbF1bA73B974a8c52f1F5F3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'DEXTF',
            'name': 'Domani Protocol',
            'address': '0x03E8D118A1864c7Dc53bf91e007ab7D91f5A06fA',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/12634/thumb/0qgT0aMu_400x400.jpg?1639175406',
            'coingeckoId': 'dextf',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FM',
            'name': 'Follow ME',
            'address': '0x03943C3ef00d92e130185CeBC0bcc435Def2cC94',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x03943c3ef00d92e130185cebc0bcc435def2cc94/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ANYP',
            'name': 'AnyPrinter V2',
            'address': '0x0356ED6caec19D3F353821480CA44Bf7dB6D713d',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x0356ed6caec19d3f353821480ca44bf7db6d713d/logo.png',
            'coingeckoId': 'anyprinter',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PERX',
            'name': 'PeerEx Network',
            'address': '0x02f8a8e78E02768A5c2f9Cf1Bfa8Ec2f821E869d',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x02f8a8e78E02768A5c2f9Cf1Bfa8Ec2f821E869d/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SHIB.e',
            'name': 'SHIBA INU',
            'address': '0x02D980A0D7AF3fb7Cf7Df8cB35d9eDBCF355f665',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x02d980a0d7af3fb7cf7df8cb35d9edbcf355f665/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'RADIO',
            'name': 'RadioShack',
            'address': '0x02Bfd11499847003De5f0F5AA081C43854d48815',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x02Bfd11499847003De5f0F5AA081C43854d48815/logo.png',
            'coingeckoId': 'radioshack',
            'listedIn': [
                'coingecko',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'AMPL',
            'name': 'Ampleforth',
            'address': '0x027dbcA046ca156De9622cD1e2D907d375e53aa7',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xd46ba6d942050d489dbd938a2c909a5d5039a161.png',
            'coingeckoId': 'ampleforth',
            'listedIn': [
                'coingecko',
                '1inch',
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'H2O',
            'name': 'Defrost Finance H2O',
            'address': '0x026187BdbC6b751003517bcb30Ac7817D5B766f8',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x026187BdbC6b751003517bcb30Ac7817D5B766f8/logo.png',
            'coingeckoId': 'defrost-finance-h2o',
            'listedIn': [
                'coingecko',
                '1inch',
                'joe',
                'pangolin',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ARUSD',
            'name': 'Arable USD',
            'address': '0x025AB35fF6AbccA56d57475249baaEae08419039',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/27792/thumb/ArUSD_125x125.png?1665819211',
            'coingeckoId': 'arable-usd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CAT',
            'name': 'BitClave',
            'address': '0x024fC8Fe444CFf7682499c08F5bb14241E082d49',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x024fC8Fe444CFf7682499c08F5bb14241E082d49/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'PTF',
            'name': 'PowerTrade Fuel Token',
            'address': '0x01cC32A282050740a88c43DAc0B56bf90f6435eF',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x01cC32A282050740a88c43DAc0B56bf90f6435eF/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'Hatter',
            'name': 'Hatter',
            'address': '0x01c5E2a4af2bd9f62c7d55C6105b8a64e6905be5',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': 'https://api.rubic.exchange/assets/avalanche/0x01c5e2a4af2bd9f62c7d55c6105b8a64e6905be5/logo.png',
            'coingeckoId': 'hatter',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'YAY',
            'name': 'YAY Games',
            'address': '0x01C2086faCFD7aA38f69A6Bd8C91BEF3BB5adFCa',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0x01c2086facfd7aa38f69a6bd8c91bef3bb5adfca.png',
            'coingeckoId': 'yay-games',
            'listedIn': [
                'coingecko',
                '1inch',
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'HEC',
            'name': 'Hector',
            'address': '0x0149E2FA4104666f6af136f731757A04df5C8A68',
            'decimals': 9,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'IB',
            'name': 'IronBank',
            'address': '0x00a35FD824c717879BF370E70AC6868b95870Dfb',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ACRE',
            'name': 'Arable Protocol',
            'address': '0x00EE200Df31b869a321B10400Da10b561F3ee60d',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43114/0x00EE200Df31b869a321B10400Da10b561F3ee60d/logo_24.png',
            'coingeckoId': 'arable-protocol',
            'listedIn': [
                'coingecko',
                'pangolin',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ZERO',
            'name': '0 exchange',
            'address': '0x008E26068B3EB40B443d3Ea88c1fF99B789c10F7',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://assets.coingecko.com/coins/images/13706/thumb/0.exchange_%28logo%29.jpg?1617070530',
            'coingeckoId': 'zero-exchange',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OCEAN',
            'name': 'Ocean Token',
            'address': '0x0057371Cd534577b6040E140654DE0958116Cf3A',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://elk.finance/tokens/logos/avax/0x0057371Cd534577b6040E140654DE0958116Cf3A/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'AVAX',
            'name': 'Avalanche',
            'address': '0x0000000000000000000000000000000000000000',
            'decimals': 18,
            'chainId': 43114,
            'logoURI': 'https://tokens.1inch.io/0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7.png',
            'coingeckoId': 'avalanche-2',
            'listedIn': [
                '1inch',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        }
    ],
    '1285': [
        {
            'symbol': 'RMRK',
            'name': 'RMRK',
            'address': '0xffffffFF893264794d9d57E1E0E21E0042aF5A0A',
            'decimals': 10,
            'chainId': 1285,
            'logoURI': 'https://assets.coingecko.com/coins/images/18656/small/download_%281%29_%281%29.png?1632865271',
            'coingeckoId': 'rmrk',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'xcKINT',
            'name': 'Kintsugi Native Token',
            'address': '0xfffFFFFF83F4f317d3cbF6EC6250AeC3697b3fF2',
            'decimals': 12,
            'chainId': 1285,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'xcPHA',
            'name': 'Phala Token',
            'address': '0xffFfFFff8E6b63d9e447B6d4C45BDA8AF9dc9603',
            'decimals': 12,
            'chainId': 1285,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FHM',
            'name': 'Fantohm',
            'address': '0xfa1FBb8Ef55A4855E5688C0eE13aC3f202486286',
            'decimals': 9,
            'chainId': 1285,
            'logoURI': 'https://assets.coingecko.com/coins/images/20021/thumb/fhm.png?1638251002',
            'coingeckoId': 'fantohm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WMOVR',
            'name': 'Wrapped Moonriver',
            'address': '0xf50225a84382c74CbdeA10b0c176f71fc3DE0C4d',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/movr.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'CHAOS',
            'name': 'Chaotic Finance',
            'address': '0xf4c6850B6e3288E81Be542909b76865a0BdF9585',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://assets.coingecko.com/coins/images/27609/thumb/chaotic_profile_%281%29.png?1664850590',
            'coingeckoId': 'chaotic-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SUSHI',
            'name': 'SushiToken - AnySwap',
            'address': '0xf390830DF829cf22c53c8840554B98eafC5dCBc2',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/sushi.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'AAVE',
            'name': 'Aave - AnySwap',
            'address': '0xf27Ee99622C3C9b264583dACB2cCE056e194494f',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/aave.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ELK',
            'name': 'Elk Finance',
            'address': '0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://elk.finance/tokens/logos/moonriver/0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE/logo.png',
            'coingeckoId': 'elk-finance',
            'listedIn': [
                'coingecko',
                'rubic',
                'elkfinance'
            ]
        },
        {
            'symbol': 'RIFI',
            'name': 'Rikkei Finance',
            'address': '0xe6F658118BCc6D344c812826b1AF13bD7d59956c',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TM',
            'name': 'Templar Money',
            'address': '0xd4143E8dB48a8f73afCDF13D7B3305F28DA38116',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ORE',
            'name': 'pTokens ORE',
            'address': '0xd1a891E6eCcB7471Ebd6Bc352F57150d4365dB21',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'RIB',
            'name': 'RiverBoat',
            'address': '0xbD90A6125a84E5C512129D622a75CDDE176aDE5E',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://elk.finance/tokens/logos/moonriver/0xbD90A6125a84E5C512129D622a75CDDE176aDE5E/logo.png',
            'coingeckoId': 'riverboat',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SPDR',
            'name': 'SpiderDAO',
            'address': '0xb2c22A9fb4FC02eb9D1d337655Ce079a04a526C7',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://assets.coingecko.com/coins/images/13378/thumb/spiderdao_logo.png?1608029180',
            'coingeckoId': 'spiderdao',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'DFH',
            'name': 'DeFiHelper Governance Token',
            'address': '0xaeDdc4A469acE97e90C605e3F52EB89620e305C0',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FTM',
            'name': 'Fantom',
            'address': '0xaD12daB5959f30b9fF3c2d6709f53C335dC39908',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://elk.finance/tokens/logos/moonriver/0xaD12daB5959f30b9fF3c2d6709f53C335dC39908/logo.png',
            'coingeckoId': 'fantom',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ATH',
            'name': 'ATH',
            'address': '0xa8E472b1446E6d9Fb97A9478e5BC13fF1815e730',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://api.rubic.exchange/assets/moonriver/0xa8e472b1446e6d9fb97a9478e5bc13ff1815e730/logo.png',
            'coingeckoId': 'athena-money',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'stKSM',
            'name': 'Liquid staked KSM',
            'address': '0xFfc7780C34B450d917d557E728f033033CB4fA8C',
            'decimals': 12,
            'chainId': 1285,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'xcKSM',
            'name': 'xcKSM',
            'address': '0xFfFFfFff1FcaCBd218EDc0EbA20Fc2308C778080',
            'decimals': 12,
            'chainId': 1285,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'xcKAR',
            'name': 'Karura Native Token',
            'address': '0xFfFFFFfF08220AD2E6e157f26eD8bD22A336A0A5',
            'decimals': 12,
            'chainId': 1285,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ANKR',
            'name': 'Ankr',
            'address': '0xFd301Ca82D007880e678bB750a771550C5104Ff2',
            'decimals': 9,
            'chainId': 1285,
            'logoURI': 'https://assets.coingecko.com/coins/images/4324/thumb/U85xTl2.png?1608111978',
            'coingeckoId': 'ankr',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'MAI',
            'name': 'MAI Stablecoin',
            'address': '0xFb2019DfD635a03cfFF624D210AEe6AF2B00fC2C',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://elk.finance/tokens/logos/moonriver/0xFb2019DfD635a03cfFF624D210AEe6AF2B00fC2C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'xcKMA',
            'name': 'Calamari Native Token',
            'address': '0xFFffFffFA083189f870640b141ae1E882c2b5bad',
            'decimals': 12,
            'chainId': 1285,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'xcBNC',
            'name': 'Bifrost Native Token',
            'address': '0xFFfFFfFFF075423be54811EcB478e911F22dDe7D',
            'decimals': 12,
            'chainId': 1285,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'xcSDN',
            'name': 'Shiden Native Token',
            'address': '0xFFFfffFF0Ca324C842330521525E7De111F38972',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'xcKBTC',
            'name': 'Kintsugi Wrapped BTC',
            'address': '0xFFFfFfFfF6E528AD57184579beeE00c5d5e646F0',
            'decimals': 8,
            'chainId': 1285,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CRV',
            'name': 'Curve DAO Token - AnySwap',
            'address': '0xFEa7a6a0B346362BF88A9e4A88416B77a57D6c2A',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/crv.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SNX',
            'name': 'Synthetix Network Token - AnySwap',
            'address': '0xF480f38C366dAaC4305dC484b2Ad7a496FF00CeA',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/snx.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'TMAP',
            'name': 'DPS TreasureMaps',
            'address': '0xF37626E2284742305858052615E94B380B23B3b7',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://assets.coingecko.com/coins/images/27655/thumb/tmap.png?1665099449',
            'coingeckoId': 'dps-treasuremaps',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DBOX',
            'name': 'Donation Box',
            'address': '0xF21B8d52D4Cb44a7E35349daa7cd1CCC8B54b23b',
            'decimals': 9,
            'chainId': 1285,
            'logoURI': 'https://api.rubic.exchange/assets/moonriver/0xf21b8d52d4cb44a7e35349daa7cd1ccc8b54b23b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DODO',
            'name': 'DODO',
            'address': '0xE9460BD2FFB12b668fA32919C785C239f974D37C',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1645695886269_18104466444652156.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WBTC',
            'name': 'Wrapped Bitcoin - Meter',
            'address': '0xE6a991Ffa8CfE62B0bf6BF72959A3d4f11B2E0f5',
            'decimals': 8,
            'chainId': 1285,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/btc.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BTCBR',
            'name': 'Bitcoin BR',
            'address': '0xE57425F1598f9b0d6219706b77f4b3DA573a3695',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://assets.coingecko.com/coins/images/21205/thumb/btcbr.png?1661666691',
            'coingeckoId': 'bitcoin-br',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'DBL',
            'name': 'DPS Doubloon',
            'address': '0xE413a631E8a9a10958D9b7c64157449eAe7C2064',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://assets.coingecko.com/coins/images/27642/thumb/6lCO7C9y_400x400.jpeg?1665019747',
            'coingeckoId': 'dps-doubloon ',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USD Coin - AnySwap',
            'address': '0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D',
            'decimals': 6,
            'chainId': 1285,
            'logoURI': 'https://elk.finance/tokens/logos/moonriver/0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'oELK',
            'name': 'Old Elk',
            'address': '0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://elk.finance/tokens/logos/moonriver/0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'KRUSTY',
            'name': 'Krusty the clown',
            'address': '0xDE34C2532E3a91c2E1b6788A082635eba7ce05B4',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://api.rubic.exchange/assets/moonriver/0xde34c2532e3a91c2e1b6788a082635eba7ce05b4/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'H2O',
            'name': 'H2O',
            'address': '0xDC151BC48a5F77288cdE9DdbFf2e32e6bcF4791F',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://api.rubic.exchange/assets/moonriver/0xdc151bc48a5f77288cde9ddbff2e32e6bcf4791f/logo.png',
            'coingeckoId': 'hydro-h2o',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'OH',
            'name': 'Oh! Finance',
            'address': '0xD96ddb35C6268CB3085003248853c39F3BfFfB4b',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TEM',
            'name': 'Templar DAO',
            'address': '0xD86E3F7B2Ff4e803f90c799D702955003bcA9875',
            'decimals': 9,
            'chainId': 1285,
            'logoURI': 'https://assets.coingecko.com/coins/images/21001/thumb/93573503.png?1638181698',
            'coingeckoId': 'templardao',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'SEEDS',
            'name': 'MoonSeeds',
            'address': '0xD5059D18A77b3fdFA524eF3Dfc03FeF63165C908',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://api.rubic.exchange/assets/moonriver/0xd5059d18a77b3fdfa524ef3dfc03fef63165c908/logo.png',
            'coingeckoId': 'moonseeds',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'wsOHM',
            'name': 'Wrapped sOHM',
            'address': '0xD22a58f79e9481D1a88e00c343885A588b34b68B',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BEPRO',
            'name': 'BEPRO Network',
            'address': '0xCb4a593ce512D78162C58384f0b2Fd6e802c2c47',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/moonriver/assets/0xCb4a593ce512D78162C58384f0b2Fd6e802c2c47/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'RKITTY',
            'name': 'RivrKitty',
            'address': '0xC2b0435276139731d82Ae2Fa8928c9b9De0761c1',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://api.rubic.exchange/assets/moonriver/0xc2b0435276139731d82ae2fa8928c9b9de0761c1/logo.png',
            'coingeckoId': 'rivrkitty',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BEANS',
            'name': 'MoonBeans',
            'address': '0xC2392DD3e3fED2c8Ed9f7f0bDf6026fcd1348453',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://elk.finance/tokens/logos/moonriver/0xC2392DD3e3fED2c8Ed9f7f0bDf6026fcd1348453/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MFAM',
            'name': 'Moonwell Apollo',
            'address': '0xBb8d88bcD9749636BC4D2bE22aaC4Bb3B01A58F1',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://assets.coingecko.com/coins/images/23914/thumb/moonwell-logo-200px.png?1645682927',
            'coingeckoId': 'moonwell',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'OSHARE',
            'name': 'OSHARE',
            'address': '0xBEcc61601c59d5aFFFE750D201eC98CdC70DB796',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://api.rubic.exchange/assets/moonriver/0xbecc61601c59d5afffe750d201ec98cdc70db796/logo.png',
            'coingeckoId': 'owl-share',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'Tether',
            'address': '0xB44a9B6905aF7c801311e8F4E76932ee959c663C',
            'decimals': 6,
            'chainId': 1285,
            'logoURI': 'https://elk.finance/tokens/logos/moonriver/0xB44a9B6905aF7c801311e8F4E76932ee959c663C/logo.png',
            'coingeckoId': 'tether',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'RIVRFLOKI',
            'name': 'RivrFloki',
            'address': '0xAe762762D5e40669AF789C8c996451449eF40C71',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://api.rubic.exchange/assets/moonriver/0xae762762d5e40669af789c8c996451449ef40c71/logo.png',
            'coingeckoId': 'rivrfloki',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'RELAY',
            'name': 'Relay Chain',
            'address': '0xAd7F1844696652ddA7959a49063BfFccafafEfe7',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://elk.finance/tokens/logos/moonriver/0xAd7F1844696652ddA7959a49063BfFccafafEfe7/logo.png',
            'coingeckoId': 'relay-token',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'RUM',
            'name': 'DPS Rum',
            'address': '0xA73288bb8a196Ed8B2F2f8DD17cd765c6FD2d8C2',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://assets.coingecko.com/coins/images/27656/thumb/rum.png?1665099688',
            'coingeckoId': 'dps-rum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MULTI',
            'name': 'Multichain',
            'address': '0x9Fb9a33956351cf4fa040f65A13b835A3C8764E3',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://assets.coingecko.com/coins/images/22087/thumb/1_Wyot-SDGZuxbjdkaOeT2-A.png?1640764238',
            'coingeckoId': 'multichain',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'aIRON',
            'name': 'Alpha Iron',
            'address': '0x9E403aA2DFEF9BA2a2b82286D13864A64D90BF36',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': '$ROVR',
            'name': 'MoonRover',
            'address': '0x9AbbE055b25247a7336d9Dc7b751C84e0A68Fb00',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FINN',
            'name': 'Huckleberry',
            'address': '0x9A92B5EBf1F6F6f7d93696FCD44e5Cf75035A756',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://assets.coingecko.com/coins/images/19642/thumb/Finn_1_%28200x200%29.png?1635726736',
            'coingeckoId': 'huckleberry',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'OCEAN',
            'name': 'Ocean Token',
            'address': '0x99C409E5f62E4bd2AC142f17caFb6810B8F0BAAE',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/list/master/logos/token-logos/network/moonriver/0x99C409E5f62E4bd2AC142f17caFb6810B8F0BAAE.jpg',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MOVR',
            'name': 'Moonriver',
            'address': '0x98878B06940aE243284CA214f92Bb71a2b032B8A',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://elk.finance/tokens/logos/moonriver/0x98878B06940aE243284CA214f92Bb71a2b032B8A/logo.png',
            'coingeckoId': 'moonriver',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'anyFRAX',
            'name': 'Anyswap Frax',
            'address': '0x965f84D915a9eFa2dD81b653e3AE736555d945f4',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://static.debank.com/image/movr_token/logo_url/0x965f84d915a9efa2dd81b653e3ae736555d945f4/1f2c42cba1add081f314ee899ab67816.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'APET',
            'name': 'APEToken',
            'address': '0x9457c50D14381e36Bb4BD7d63B57f260f16a19E7',
            'decimals': 9,
            'chainId': 1285,
            'logoURI': 'https://api.rubic.exchange/assets/moonriver/0x9457c50d14381e36bb4bd7d63b57f260f16a19e7/logo.png',
            'coingeckoId': 'apetoken',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'IMX',
            'name': 'Impermax  OLD ',
            'address': '0x900f1Ec5819FA087d368877cD03B265Bf1802667',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://assets.coingecko.com/coins/images/15126/thumb/Copy_of_200px.png?1619761475',
            'coingeckoId': 'impermax',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'aWOOD',
            'name': 'Alpha Wood',
            'address': '0x8cE2BDc6e0319cea87337d027382f09B715C9601',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'POLK',
            'name': 'Polkamarkets',
            'address': '0x8b29344f368b5FA35595325903fE0eAab70C8E1F',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/moonriver/assets/0x8b29344f368b5FA35595325903fE0eAab70C8E1F/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ROAR',
            'name': 'Alpha DEX',
            'address': '0x8E7Cd893D8f371051a39aA65976Bca22d7b02A60',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://assets.coingecko.com/coins/images/23127/thumb/61f1198172efd5000109e2bf_MicrosoftTeams-image_%28160%29.png?1643268545',
            'coingeckoId': 'alpha-dex',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'BNB',
            'name': 'BNB - Meter',
            'address': '0x868892CCcEdbfF0B028F3b3595205Ea91b99376B',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/bnb.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'DAI',
            'name': 'Dai',
            'address': '0x80A16016cC4A2E6a2CACA8a4a498b1699fF0f844',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://elk.finance/tokens/logos/moonriver/0x80A16016cC4A2E6a2CACA8a4a498b1699fF0f844/logo.png',
            'coingeckoId': 'dai',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MIMATIC',
            'name': 'MAI',
            'address': '0x7f5a79576620C046a293F54FFCdbd8f2468174F1',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/polygon/assets/0xa3Fa99A148fA48D14Ed51d610c367C61876997F1/logo.png',
            'coingeckoId': 'mimatic',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'aSTONE',
            'name': 'Alpha Stone',
            'address': '0x77709C42d43F2e53c24b8fA623A207abDC89857c',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MD',
            'name': 'MoonDao',
            'address': '0x761cb807bFbF14A4f4bA980f29F43F009F6a18c0',
            'decimals': 9,
            'chainId': 1285,
            'logoURI': 'https://api.rubic.exchange/assets/moonriver/0x761cb807bfbf14a4f4ba980f29f43f009f6a18c0/logo.png',
            'coingeckoId': 'moondao-2',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'CWS',
            'name': 'Crowns',
            'address': '0x6fc9651f45B262AE6338a701D563Ab118B1eC0Ce',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FXS',
            'name': 'Frax Share',
            'address': '0x6f1D1Ee50846Fcbc3de91723E61cb68CFa6D0E98',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/fxs.jpg',
            'coingeckoId': 'frax-share',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'SOLAR',
            'name': 'Solarbeam',
            'address': '0x6bD193Ee6D2104F14F94E2cA6efefae561A4334B',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://elk.finance/tokens/logos/moonriver/0x6bD193Ee6D2104F14F94E2cA6efefae561A4334B/logo.png',
            'coingeckoId': 'solarbeam',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WBTC',
            'name': 'Wrapped Bitcoin',
            'address': '0x6aB6d61428fde76768D7b45D8BFeec19c6eF91A8',
            'decimals': 8,
            'chainId': 1285,
            'logoURI': 'https://elk.finance/tokens/logos/moonriver/0x6aB6d61428fde76768D7b45D8BFeec19c6eF91A8/logo.png',
            'coingeckoId': 'wrapped-bitcoin',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'LDO',
            'name': 'Lido DAO Token',
            'address': '0x6Ccf12B480A99C54b23647c995f4525D544A7E72',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MATIC',
            'name': 'Polygon',
            'address': '0x682F81e57EAa716504090C3ECBa8595fB54561D8',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://elk.finance/tokens/logos/moonriver/0x682F81e57EAa716504090C3ECBa8595fB54561D8/logo.png',
            'coingeckoId': 'matic-network',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'LAIKA',
            'name': 'LaikaProtocol',
            'address': '0x65e66a61D0a8F1e686C2D6083ad611a10D84D97A',
            'decimals': 9,
            'chainId': 1285,
            'logoURI': 'https://elk.finance/tokens/logos/moonriver/0x65e66a61D0a8F1e686C2D6083ad611a10D84D97A/logo.png',
            'coingeckoId': 'laika-protocol',
            'listedIn': [
                'rubic',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WETH',
            'name': 'WETH',
            'address': '0x639A647fbe20b6c8ac19E48E2de44ea792c62c5C',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://elk.finance/tokens/logos/moonriver/0x639A647fbe20b6c8ac19E48E2de44ea792c62c5C/logo.png',
            'coingeckoId': 'weth',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MSCP',
            'name': 'Moonscape',
            'address': '0x5c22ba65F65ADfFADFc0947382f2E7C286A0Fe45',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://assets.coingecko.com/coins/images/21142/thumb/14522.png?1638396270',
            'coingeckoId': 'moonscape',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BUSD',
            'name': 'Binance USD',
            'address': '0x5D9ab5522c64E1F6ef5e3627ECCc093f56167818',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://elk.finance/tokens/logos/moonriver/0x5D9ab5522c64E1F6ef5e3627ECCc093f56167818/logo.png',
            'coingeckoId': 'binance-usd',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'RIVRDOGE',
            'name': 'RivrDoge',
            'address': '0x5D4360f1Be94bD6f182F09cFE5EF9832e65EB1ac',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://rivrdoge.com/wp-content/uploads/2021/09/logo.png',
            'coingeckoId': 'rivrdoge',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'HYDRO',
            'name': 'Hydro',
            'address': '0x56cfe76545939AFAbc8BDbA94192A96801D24d28',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://api.rubic.exchange/assets/moonriver/0x56cfe76545939afabc8bdba94192a96801d24d28/logo.png',
            'coingeckoId': 'hydro-protocol-token',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SPACE',
            'name': 'Space Token',
            'address': '0x55aF5865807b196bD0197e0902746F31FBcCFa58',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'KAFE',
            'name': 'MOONKAFE',
            'address': '0x52f04C806EB82930F40D410259b7AF8E18d3BDc9',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://elk.finance/tokens/logos/moonriver/0x52f04C806EB82930F40D410259b7AF8E18d3BDc9/logo.png',
            'coingeckoId': 'kukafe-finance',
            'listedIn': [
                'rubic',
                'elkfinance'
            ]
        },
        {
            'symbol': 'KOGECOIN',
            'name': 'kogecoin.io',
            'address': '0x4f3Aff3A747fCADe12598081e80c6605A8be192F',
            'decimals': 9,
            'chainId': 1285,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ROME',
            'name': 'Rome',
            'address': '0x4a436073552044D5f2f49B176853ad3Ad473d9d6',
            'decimals': 9,
            'chainId': 1285,
            'logoURI': 'https://assets.coingecko.com/coins/images/21078/small/rome-dao.jpeg?1638291936',
            'coingeckoId': 'rome',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'KMOON',
            'name': 'KusaMOON',
            'address': '0x480CD4Fa911eeEFf93cB11135C97237455617862',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://elk.finance/tokens/logos/moonriver/0x480CD4Fa911eeEFf93cB11135C97237455617862/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ZRG',
            'name': 'Zircon Gamma Token',
            'address': '0x4545E94974AdACb82FC56BCf136B07943e152055',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://assets.coingecko.com/coins/images/27832/thumb/zrg_300.png?1665994776',
            'coingeckoId': 'zircon-gamma-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RIVRSHIBA',
            'name': 'Rivr Shiba',
            'address': '0x3d593056F3e34dB7A4720d7F171447C489CFa195',
            'decimals': 9,
            'chainId': 1285,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/moonriver/assets/0x3d593056F3e34dB7A4720d7F171447C489CFa195/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'WSTKSM',
            'name': 'Lido on Kusama',
            'address': '0x3bfd113ad0329a7994a681236323fb16E16790e3',
            'decimals': 12,
            'chainId': 1285,
            'logoURI': 'https://assets.coingecko.com/coins/images/27858/thumb/wstKSM.png?1666096461',
            'coingeckoId': 'lido-on-kusama',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'gOHM',
            'name': 'Governance OHM',
            'address': '0x3bF21Ce864e58731B6f28D68d5928BcBEb0Ad172',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://static.debank.com/image/movr_token/logo_url/0x3bf21ce864e58731b6f28d68d5928bcbeb0ad172/6452ac694998bcd3defc6b5cc3e68926.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'VETH',
            'name': 'Voucher ETH',
            'address': '0x3b25BC1dC591D24d60560d0135D6750A561D4764',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://assets.coingecko.com/coins/images/14484/thumb/veth_logo_uniswap_200x200.png?1616471309',
            'coingeckoId': 'voucher-eth',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'MNTO',
            'name': 'Minato',
            'address': '0x3D51a9fB5dCc87F7B237B04975559b920a9a56Ff',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'aROME',
            'name': 'Alpha Rome',
            'address': '0x3D2D044E8C6dAd46b4F7896418d3d4DFaAD902bE',
            'decimals': 9,
            'chainId': 1285,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/logos/main/network/moonriver/0x3D2D044E8C6dAd46b4F7896418d3d4DFaAD902bE.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'NEKU',
            'name': 'Neku Finance',
            'address': '0x3Bf0880fd26E49c46d1A1e69ADb268889B4be840',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://api.rubic.exchange/assets/moonriver/0x3bf0880fd26e49c46d1a1e69adb268889b4be840/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'YFI',
            'name': 'yearn.finance - AnySwap',
            'address': '0x375488F097176507e39B9653b88FDc52cDE736Bf',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/yfi.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': '1SWAP',
            'name': '1Swap Token',
            'address': '0x3516a7588C2E6FFA66C9507eF51853eb85d76e5B',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://elk.finance/tokens/logos/moonriver/0x3516a7588C2E6FFA66C9507eF51853eb85d76e5B/logo.png',
            'coingeckoId': '1swap',
            'listedIn': [
                'rubic',
                'elkfinance'
            ]
        },
        {
            'symbol': 'RIVRSAITAMA',
            'name': 'RivrSaitama',
            'address': '0x343E8F5A79C324105dFC1F5644f9d8C31Cf75e5E',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://api.rubic.exchange/assets/moonriver/0x343e8f5a79c324105dfc1f5644f9d8c31cf75e5e/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PINK',
            'name': 'Pink Token',
            'address': '0x30A54FbFF50f86C3a289fa70e677051a86677be4',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'IDIA',
            'name': 'Impossible Finance Launchpad',
            'address': '0x2d28AA28fA1E5e6bF121CF688309Bf3faAAe3C70',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://assets.coingecko.com/coins/images/17803/thumb/IDIA.png?1629292292',
            'coingeckoId': 'idia',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'WBNB',
            'name': 'Wrapped BNB',
            'address': '0x2bF9b864cdc97b08B6D79ad4663e71B8aB65c45c',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://elk.finance/tokens/logos/moonriver/0x2bF9b864cdc97b08B6D79ad4663e71B8aB65c45c/logo.png',
            'coingeckoId': 'wbnb',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MV',
            'name': 'MULTIVERSE',
            'address': '0x21d963E5B223fFA178dE7398F3bcAf367D8d1730',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MTRG',
            'name': 'Meter Governance',
            'address': '0x1e24EC84F66cd26Dad607d81796DbeB13Cb22692',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://raw.githubusercontent.com/solarbeamio/assets/master/blockchains/moonriver/assets/0x1e24EC84F66cd26Dad607d81796DbeB13Cb22692/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'PETS',
            'name': 'PolkaPet World',
            'address': '0x1e0F2A75Be02c025Bd84177765F89200c04337Da',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://assets.coingecko.com/coins/images/19409/thumb/pets_polka.PNG?1635211453',
            'coingeckoId': 'polkapet-world',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'SOLARp',
            'name': 'SolarPrinter',
            'address': '0x1AF50f7Bd6f98988dF418654832D7c3728e2a867',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://api.rubic.exchange/assets/moonriver/0x1af50f7bd6f98988df418654832d7c3728e2a867/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FRAX',
            'name': 'Frax',
            'address': '0x1A93B23281CC1CDE4C4741353F3064709A16197d',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://assets.coingecko.com/coins/images/13422/small/frax_logo.png?1608476506',
            'coingeckoId': 'frax',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'BIFI',
            'name': 'Beefy Finance',
            'address': '0x173fd7434B8B50dF08e3298f173487ebDB35FD14',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x173fd7434B8B50dF08e3298f173487ebDB35FD14/logo.png',
            'coingeckoId': 'beefy-finance',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'DOT.m',
            'name': 'DOT',
            'address': '0x15B9CA9659F5dfF2b7d35a98dd0790a3CBb3D445',
            'decimals': 10,
            'chainId': 1285,
            'logoURI': 'https://elk.finance/tokens/logos/moonriver/0x15B9CA9659F5dfF2b7d35a98dd0790a3CBb3D445/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ALM',
            'name': 'Alium Finance',
            'address': '0x1581929770bE3275a82068c1135b6dD59c5334Ed',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://assets.coingecko.com/coins/images/15621/thumb/alium.png?1655449806',
            'coingeckoId': 'alium-finance',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'AVAX',
            'name': 'Avalanche',
            'address': '0x14a0243C333A5b238143068dC3A7323Ba4C30ECB',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://elk.finance/tokens/logos/moonriver/0x14a0243C333A5b238143068dC3A7323Ba4C30ECB/logo.png',
            'coingeckoId': 'avalanche-2',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'aEXP',
            'name': 'Alpha Experience',
            'address': '0x138a90f246Abb23A157DA7a1D9dB19dCf1691362',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'POP!',
            'name': 'POP!',
            'address': '0x1351Ab0323b1127e1cFb8406ADf87cA88A5A4ff6',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'HND',
            'name': 'Hundred Finance',
            'address': '0x10010078a54396F62c96dF8532dc2B4847d47ED3',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://static.debank.com/image/movr_token/logo_url/0x10010078a54396f62c96df8532dc2b4847d47ed3/a27fffbdafdae8936cf53389264e8ebe.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ZLK',
            'name': 'Zenlink Network',
            'address': '0x0f47ba9d9Bde3442b42175e51d6A367928A1173B',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://assets.coingecko.com/coins/images/20884/thumb/zenlink.PNG?1637824309',
            'coingeckoId': 'zenlink-network-token',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'LINK',
            'name': 'ChainLink Token - AnySwap',
            'address': '0x0dCb0CB0120d355CdE1ce56040be57Add0185BAa',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/link.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'MARA',
            'name': 'Amara Finance',
            'address': '0x0d2faA2064129ecBfAccD45be33DEA3bCD3F8863',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://assets.coingecko.com/coins/images/24314/thumb/Ijmm6Xws_400x400.jpg?1647353447',
            'coingeckoId': 'amara-finance',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'MIM',
            'name': 'Magic Internet Money',
            'address': '0x0caE51e1032e8461f4806e26332c030E34De3aDb',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://elk.finance/tokens/logos/moonriver/0x0caE51e1032e8461f4806e26332c030E34De3aDb/logo.png',
            'coingeckoId': 'magic-internet-money',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'aGOLD',
            'name': 'Alpha Gold',
            'address': '0x088Fe6e0e1CaCA1eE45E8DE96AbE79e4e139F4Ab',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FM',
            'name': 'Follow ME',
            'address': '0x03943C3ef00d92e130185CeBC0bcc435Def2cC94',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://api.rubic.exchange/assets/moonriver/0x03943c3ef00d92e130185cebc0bcc435def2cc94/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MOVR',
            'name': 'MOVR',
            'address': '0x0000000000000000000000000000000000000000',
            'decimals': 18,
            'chainId': 1285,
            'logoURI': 'https://assets.coingecko.com/coins/images/17984/small/9285.png?1630028620',
            'coingeckoId': 'moonriver',
            'listedIn': [
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        }
    ],
    '25': [
        {
            'symbol': 'CRV',
            'name': 'Curve DAO Token',
            'address': '0xfEf44a0C77eca218F443382d3128a7A251a8C542',
            'decimals': 18,
            'chainId': 25,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BNB',
            'name': 'BNB',
            'address': '0xfA9343C3897324496A05fC75abeD6bAC29f8A40f',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xfA9343C3897324496A05fC75abeD6bAC29f8A40f/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'cronaswap'
            ]
        },
        {
            'symbol': 'MSHARE',
            'name': 'Meerkat Shares',
            'address': '0xf8b9facB7B4410F5703Eb29093302f2933D6E1Aa',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/24035/small/SVN_LOGO_%281%29.png?1646117686',
            'coingeckoId': 'meerkat-shares',
            'listedIn': [
                'coingecko',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'RADIO',
            'name': 'RadioShack',
            'address': '0xf899e3909B4492859d44260E1de41A9E663e70F5',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/25307/thumb/ZVoPiysPJq6dPIZm_Se-6vjmsBepwhHlTQfdYZRILbHyVVTRUYCO-wmJJ4zT10HXCGv1j-ZyWr2u2sBaVlap5Y-ILqeXZuIquWdBDxxG0E0qDpgH7omLqYdgWWLSM_TUK9d1PiiYdu6bERdCDaucgFjlqwmhVQK4uV4jyUiXzchVUnu8Qt6SnxlNxz88G0mQ_tfiwkFv_vKqtgb1CcPycVZVz9.jpg?1651211260',
            'coingeckoId': 'radioshack',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ECH',
            'name': 'Echelon',
            'address': '0xf69B774BE9ACA90e87D2788BE7E6b1D492fA7ACd',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/25498/thumb/SpO_F8a0_400x400.jpg?1652077746',
            'coingeckoId': 'echelon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRN',
            'name': 'Crown Finance',
            'address': '0xf287E8999fcbea602D2c5DD699ea2FF7f3685964',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/25996/thumb/logo200.png?1655194877',
            'coingeckoId': 'crown-finance-855746ec-db3e-4c45-a9f2-c6aaf16031d2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELK',
            'name': 'Elk Finance',
            'address': '0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://elk.finance/tokens/logos/cronos/0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE/logo.png',
            'coingeckoId': 'elk-finance',
            'listedIn': [
                'coingecko',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GDRT',
            'name': 'GDRT',
            'address': '0xeC0d0f2D7dDF5e6F1Ed18711fE5DD5C790E1C4d6',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/26287/thumb/YouTube_Profile_Image.png?1657092334',
            'coingeckoId': 'gdrt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BCRO',
            'name': 'Bonded Cronos',
            'address': '0xeBAceB7F193955b946cC5dd8f8724a80671a1F2F',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://storage.googleapis.com/xy-finance-images/token-list/bcro.png',
            'coingeckoId': 'bonded-cronos',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'BIFI',
            'name': 'Beefy Finance',
            'address': '0xe6801928061CDbE32AC5AD0634427E140EFd05F9',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/12704/small/token.png?1601876182',
            'coingeckoId': 'beefy-finance',
            'listedIn': [
                'coingecko',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'xUSD',
            'name': 'Cross USD Stablecoin',
            'address': '0xe6052a9a4c0A2f14ADC9876A3A1a7b2882f5F139',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xe6052a9a4c0A2f14ADC9876A3A1a7b2882f5F139/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'cronaswap'
            ]
        },
        {
            'symbol': 'BOC',
            'name': 'Bank Of Cronos',
            'address': '0xe5786DDFc4D6DcA0973D1c5b02987cBbac66ed87',
            'decimals': 9,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xe5786DDFc4D6DcA0973D1c5b02987cBbac66ed87/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'cronaswap'
            ]
        },
        {
            'symbol': 'WETH',
            'name': 'WETH',
            'address': '0xe44Fd7fCb2b1581822D0c862B68222998a0c299a',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xe44Fd7fCb2b1581822D0c862B68222998a0c299a/logo.png',
            'coingeckoId': 'weth',
            'listedIn': [
                'coingecko',
                'lifinance',
                'xyfinance',
                'elkfinance',
                'cronaswap'
            ]
        },
        {
            'symbol': 'CRX',
            'name': 'Crodex',
            'address': '0xe243CCab9E66E6cF1215376980811ddf1eb7F689',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://elk.finance/tokens/logos/cronos/0xe243CCab9E66E6cF1215376980811ddf1eb7F689/logo.png',
            'coingeckoId': 'crodex',
            'listedIn': [
                'coingecko',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DXP',
            'name': 'DexPad',
            'address': '0xe0c41FF9a7032de445771E12C14868CbE061C993',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/23106/thumb/dxp.png?1643236999',
            'coingeckoId': 'dexpad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VSHARE',
            'name': 'V3S Share',
            'address': '0xdcC261c03cD2f33eBea404318Cdc1D9f8b78e1AD',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xdcC261c03cD2f33eBea404318Cdc1D9f8b78e1AD/logo.png',
            'coingeckoId': 'v3s-share',
            'listedIn': [
                'coingecko',
                'cronaswap'
            ]
        },
        {
            'symbol': 'MINTME',
            'name': 'MintMe com Coin',
            'address': '0xd652776dE7Ad802be5EC7beBfafdA37600222B48',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/5127/thumb/MINTME_logo.png?1666845801',
            'coingeckoId': 'webchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FORT',
            'name': 'FortuneDAO',
            'address': '0xcA558149225Fb386B9c26716e8C35a650C74d35e',
            'decimals': 9,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xcA558149225Fb386B9c26716e8C35a650C74d35e/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'cronaswap'
            ]
        },
        {
            'symbol': 'SPHERE',
            'name': 'Cronosphere',
            'address': '0xc9FDE867a14376829Ab759F4C4871F67e2d3E441',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xc9FDE867a14376829Ab759F4C4871F67e2d3E441/logo.png',
            'coingeckoId': 'cronosphere',
            'listedIn': [
                'coingecko',
                'cronaswap'
            ]
        },
        {
            'symbol': 'MATIC',
            'name': 'Matic',
            'address': '0xc9BAA8cfdDe8E328787E29b4B078abf2DaDc2055',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xc9BAA8cfdDe8E328787E29b4B078abf2DaDc2055/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'cronaswap'
            ]
        },
        {
            'symbol': 'ESSO',
            'name': 'IMPRESSO',
            'address': '0xc82eA25896F065cB6E3ae298cD1f23FE58516A35',
            'decimals': 8,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xc82eA25896F065cB6E3ae298cD1f23FE58516A35/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance',
                'cronaswap'
            ]
        },
        {
            'symbol': 'CROPPERFIELD',
            'name': 'CROPPERFIELD',
            'address': '0xc40469cCcb76e080d26B84f460753Bc0cf57f90b',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://storage.googleapis.com/xy-finance-images/token-list/cropperfield_32.png',
            'coingeckoId': null,
            'listedIn': [
                'xyfinance'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USD Coin',
            'address': '0xc21223249CA28397B4B6541dfFaEcC539BfF0c59',
            'decimals': 6,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xc21223249CA28397B4B6541dfFaEcC539BfF0c59/logo.png',
            'coingeckoId': 'usd-coin',
            'listedIn': [
                'coingecko',
                'lifinance',
                'xyfinance',
                'elkfinance',
                'cronaswap'
            ]
        },
        {
            'symbol': 'PHOTON',
            'name': 'PhotonSwap',
            'address': '0xbdd4e5660839a088573191A9889A262c0Efc0983',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://elk.finance/tokens/logos/cronos/0xbdd4e5660839a088573191A9889A262c0Efc0983/logo.png',
            'coingeckoId': 'photonswap',
            'listedIn': [
                'coingecko',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SHIB',
            'name': 'SHIBA INU',
            'address': '0xbED48612BC69fA1CaB67052b42a95FB30C1bcFee',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xbED48612BC69fA1CaB67052b42a95FB30C1bcFee/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'cronaswap'
            ]
        },
        {
            'symbol': 'CRONA',
            'name': 'CronaSwap',
            'address': '0xadbd1231fb360047525BEdF962581F3eee7b49fe',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xadbd1231fb360047525BEdF962581F3eee7b49fe/logo.png',
            'coingeckoId': 'cronaswap',
            'listedIn': [
                'coingecko',
                'lifinance',
                'xyfinance',
                'cronaswap'
            ]
        },
        {
            'symbol': 'MATIC',
            'name': 'MATIC',
            'address': '0xad79AC3c5a5c15C6B9194F5568e451b3fc3C2B40',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1650434638704_045322303327145264.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CLMRS',
            'name': 'Crolon Mars',
            'address': '0xaAd00d36Dbc8343C3505Ba51418a43D3622D2964',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://storage.googleapis.com/xy-finance-images/token-list/CLMRS.png',
            'coingeckoId': 'crolon-mars',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'HORGI',
            'name': 'Horgi',
            'address': '0xa3089f1462426398eb586E07700ae08Aba7324C4',
            'decimals': 16,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/24615/thumb/horgi.png?1648374535',
            'coingeckoId': 'horgi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CROISSANT',
            'name': 'Croissant Games',
            'address': '0xa0C3c184493f2Fae7d2f2Bd83F195a1c300FA353',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xa0C3c184493f2Fae7d2f2Bd83F195a1c300FA353/logo.png',
            'coingeckoId': 'croissant-games',
            'listedIn': [
                'coingecko',
                'xyfinance',
                'cronaswap'
            ]
        },
        {
            'symbol': 'DCF',
            'name': 'DarkCrypto Foundation',
            'address': '0xFa202a63234A7089EDdbFEee83c378ba248f0e9a',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/27488/thumb/Main_Token.png?1664252242',
            'coingeckoId': 'darkcrypto-foundation',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEGG',
            'name': 'DuckyDefi',
            'address': '0xFD71FC52D34eD1Cfc8363e5528285B12b6b942c2',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/23564/thumb/LDoi0N5YEA_l8vbR4lL_6plCcklHA3ZNEu5Kgf5kVCqKBe8cMKo7azDzuAp2ivUMWlZtHPBcZMirGW-4E6-9Fz8noNIX-1eaVcDYig-QkhNRn5DF8zNO6NPSoIARer8a5Zcbs1thirIU9D9waIOdB2b_y3DcOtX_S8qvVeRxcP0XM6qE1fdyqqkSLOe63JmKXt_Dd9de2k_EkC.jpg?1644474684',
            'coingeckoId': 'duckydefi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MMO',
            'name': 'Mad Meerkat Optimizer',
            'address': '0xF8aCF86194443DCde55fc5B9e448e183c290D8Cb',
            'decimals': 18,
            'chainId': 25,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DAI',
            'name': 'Dai',
            'address': '0xF2001B145b43032AAF5Ee2884e456CCd805F677D',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xF2001B145b43032AAF5Ee2884e456CCd805F677D/logo.png',
            'coingeckoId': 'dai',
            'listedIn': [
                'coingecko',
                'lifinance',
                'xyfinance',
                'elkfinance',
                'cronaswap'
            ]
        },
        {
            'symbol': 'LHRC',
            'name': 'Lazy Horse Race Club',
            'address': '0xEfD450027bD0EE84AfaFbC75f5A80C367eFBF651',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xEfD450027bD0EE84AfaFbC75f5A80C367eFBF651/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'cronaswap'
            ]
        },
        {
            'symbol': 'DARKCRYSTL',
            'name': 'DarkCrystl',
            'address': '0xEfA1FABC2AB6219174aD1c912F56f7de53cDc1E1',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/26486/thumb/1-modified.png?1658281857',
            'coingeckoId': 'darkcrystl',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'xyETH',
            'name': 'xyETH',
            'address': '0xEf367cdB37Ce3F43130fB9651d3db8fBF6045c87',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
            'coingeckoId': null,
            'listedIn': [
                'xyfinance'
            ]
        },
        {
            'symbol': 'NESS',
            'name': 'Darkness Share',
            'address': '0xE727240728C1a5f95437b8b50AFDd0EA4AE5F0c8',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/24247/small/ness.png?1647094614',
            'coingeckoId': 'darkness-share',
            'listedIn': [
                'coingecko',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'GHC',
            'name': 'Galaxy Heroes',
            'address': '0xE6BF0E14e33A469F2b0640B53949A9F90E675135',
            'decimals': 18,
            'chainId': 25,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BTCBR',
            'name': 'Bitcoin BR',
            'address': '0xE57425F1598f9b0d6219706b77f4b3DA573a3695',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/21205/thumb/btcbr.png?1661666691',
            'coingeckoId': 'bitcoin-br',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'oELK',
            'name': 'Old Elk',
            'address': '0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://elk.finance/tokens/logos/cronos/0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'TONIC',
            'name': 'Tectonic',
            'address': '0xDD73dEa10ABC2Bff99c60882EC5b2B81Bb1Dc5B2',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/21982/small/TONIC_logo.png?1640575290',
            'coingeckoId': 'tectonic',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'CRONEX',
            'name': 'CRONEX',
            'address': '0xDB2Bb0A1481FCF0df33720d80A660dD4d060B6c0',
            'decimals': 9,
            'chainId': 25,
            'logoURI': 'https://storage.googleapis.com/xy-finance-images/token-list/Cronex.png',
            'coingeckoId': null,
            'listedIn': [
                'xyfinance'
            ]
        },
        {
            'symbol': 'CRON',
            'name': 'CronosNode',
            'address': '0xD8eB4845B094e05f4d01d7BB3468238076F744f1',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/26359/thumb/V5-transparent.png?1657593867',
            'coingeckoId': 'cronosnode',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '10SHARE',
            'name': '10SHARE',
            'address': '0xD8d40DcEE0C2B486EEBd1FEDb3F507B011De7Ff0',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/26219/thumb/10mb_share_200.png?1656638041',
            'coingeckoId': '10share',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TIGER',
            'name': 'Tiger Scrub Money  OLD ',
            'address': '0xD6597AA36DD90d7fCcBd7B8A228F2d5CdC88eAd0',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/24951/thumb/tiger.png?1649504961',
            'coingeckoId': 'tiger-scrub-money',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BETIFY',
            'name': 'Betify',
            'address': '0xD465b6B4937D768075414D413e981Af0b49349Cc',
            'decimals': 9,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/23722/thumb/Logo_200px.png?1645161787',
            'coingeckoId': 'betify',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CROBLANC',
            'name': 'Croblanc',
            'address': '0xD3ceCBE5639D05Aed446Da11F08D495CA6bF359F',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xD3ceCBE5639D05Aed446Da11F08D495CA6bF359F/logo.png',
            'coingeckoId': 'croblanc',
            'listedIn': [
                'coingecko',
                'cronaswap'
            ]
        },
        {
            'symbol': 'DNA',
            'name': 'DNA Dollar',
            'address': '0xCc57F84637B441127f2f74905b9d99821b47b20c',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/22549/thumb/dna_cmc.png?1642052419',
            'coingeckoId': 'dna-dollar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRYSTL',
            'name': 'Crystl Finance',
            'address': '0xCbDE0E17d14F49e10a10302a32d17AE88a7Ecb8B',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xCbDE0E17d14F49e10a10302a32d17AE88a7Ecb8B/logo.png',
            'coingeckoId': 'crystl-finance',
            'listedIn': [
                'coingecko',
                'lifinance',
                'cronaswap'
            ]
        },
        {
            'symbol': 'CORGI',
            'name': 'Corgi',
            'address': '0xCC6e924721F75af3A8F8282216569276f3F2a104',
            'decimals': 9,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xCC6e924721F75af3A8F8282216569276f3F2a104/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'cronaswap'
            ]
        },
        {
            'symbol': 'MNG',
            'name': 'SaharaDAO',
            'address': '0xC9b23289c60783649AA327732FCCEc2f5d0aC466',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/25997/thumb/41625-E26-037-E-430-D-B125-DB02558-C5024.png?1655275764',
            'coingeckoId': 'saharadao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'V3S',
            'name': 'V3SToken',
            'address': '0xC7e99a2f064Cf86aF91Db252a9D8Bc16e6fE7427',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xC7e99a2f064Cf86aF91Db252a9D8Bc16e6fE7427/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'cronaswap'
            ]
        },
        {
            'symbol': 'BUSD',
            'name': 'Binance USD',
            'address': '0xC74D59A548ecf7fc1754bb7810D716E9Ac3e3AE5',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/9576/thumb/BUSD.png?1568947766',
            'coingeckoId': 'binance-usd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CROGE',
            'name': 'Crogecoin',
            'address': '0xC4a174cCb5fb54a6721e11e0Ca961e42715023F9',
            'decimals': 9,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/24903/small/transparent.png?1649335022',
            'coingeckoId': 'crogecoin',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'KINU',
            'name': 'Kragger Inu',
            'address': '0xC0d4bB262d63A95f85Ae841b4e2B0dACc54Fdc0a',
            'decimals': 2,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/25374/thumb/kinu.png?1651408648',
            'coingeckoId': 'kragger-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MMFp',
            'name': 'MMFp',
            'address': '0xBDC8fd437C489Ca3c6DA3B5a336D11532a532303',
            'decimals': 18,
            'chainId': 25,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'METF',
            'name': 'Mad Meerkat ETF',
            'address': '0xB8Df27c687c6af9aFE845A2aFAD2D01e199f4878',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/24282/small/METF_Token-svg.png?1647221647',
            'coingeckoId': 'mad-meerkat-etf',
            'listedIn': [
                'coingecko',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'ATOM',
            'name': 'Cosmos Hub',
            'address': '0xB888d8Dd1733d72681b30c00ee76BDE93ae7aa93',
            'decimals': 6,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xB888d8Dd1733d72681b30c00ee76BDE93ae7aa93/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'cronaswap'
            ]
        },
        {
            'symbol': 'Kagu',
            'name': 'Kaguni',
            'address': '0xB73b49aAb0B354A7868f9AD36c7a6AC357E20492',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xB73b49aAb0B354A7868f9AD36c7a6AC357E20492/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'cronaswap'
            ]
        },
        {
            'symbol': 'FTM',
            'name': 'Fantom',
            'address': '0xB44a9B6905aF7c801311e8F4E76932ee959c663C',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xB44a9B6905aF7c801311e8F4E76932ee959c663C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'cronaswap'
            ]
        },
        {
            'symbol': 'Army',
            'name': 'CroArmy',
            'address': '0xB20115B8B176D72F077C4f93e95492870240a0b2',
            'decimals': 18,
            'chainId': 25,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'LIQ',
            'name': 'Liquidus',
            'address': '0xABd380327Fe66724FFDa91A87c772FB8D00bE488',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xABd380327Fe66724FFDa91A87c772FB8D00bE488/logo.png',
            'coingeckoId': 'liquidus',
            'listedIn': [
                'coingecko',
                'lifinance',
                'cronaswap'
            ]
        },
        {
            'symbol': 'CRODOGE',
            'name': 'CRODOGE',
            'address': '0xA7aaf2d57179BdA516374b9947e608D01F6ED0A4',
            'decimals': 4,
            'chainId': 25,
            'logoURI': 'https://elk.finance/tokens/logos/cronos/0xA7aaf2d57179BdA516374b9947e608D01F6ED0A4/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'MAGUS',
            'name': 'Magus Nodes',
            'address': '0xA57ca211cd6820bd3d930978271538d07e31A212',
            'decimals': 6,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/25776/thumb/da8dc4_c44a030347ac48dd9df5ec1bc5306ef0_mv2.png?1653631535',
            'coingeckoId': 'magus-nodes',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AGL',
            'name': 'Agile',
            'address': '0xA4434AFEae0dECb9820D906BF01b13291D00651a',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xA4434AFEae0dECb9820D906BF01b13291D00651a/logo.png',
            'coingeckoId': 'agile',
            'listedIn': [
                'coingecko',
                'cronaswap'
            ]
        },
        {
            'symbol': 'EOS',
            'name': 'EOS',
            'address': '0xA37caA841072a305a0799718aFA16cd504C52118',
            'decimals': 4,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xA37caA841072a305a0799718aFA16cd504C52118/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'cronaswap'
            ]
        },
        {
            'symbol': 'V3TONIC',
            'name': 'V3S-Peg Tectonic',
            'address': '0xA29EB0318F522ca98FdB5cEdc0c7549526625517',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0xA29EB0318F522ca98FdB5cEdc0c7549526625517/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'cronaswap'
            ]
        },
        {
            'symbol': 'APP',
            'name': 'DAPPSY',
            'address': '0x9fB83c0635De2E815fd1c21b3a292277540C2e8d',
            'decimals': 8,
            'chainId': 25,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PolyDoge',
            'name': 'PolyDoge',
            'address': '0x9b8077C6590b560f1A9D60512648277D29b35A3B',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1650435434916_5749926628730133.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BLAST',
            'name': 'SafeBLAST',
            'address': '0x9D3E3624b3de482331F4f091Fd8b76D3F344bd8C',
            'decimals': 9,
            'chainId': 25,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SKY',
            'name': 'DarkCrypto Share',
            'address': '0x9D3BBb0e988D9Fb2d55d07Fe471Be2266AD9c81c',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/22517/small/logo-token-2.e9c15b63.png?1641968423',
            'coingeckoId': 'darkcrypto-share',
            'listedIn': [
                'coingecko',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'PAE',
            'name': 'Ripae',
            'address': '0x99415856B37bE9E75C0153615C7954f9DDb97A6E',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://static.debank.com/image/cro_token/logo_url/0x99415856b37be9e75c0153615c7954f9ddb97a6e/5c5dcaed082e1117f50494c96c6dabda.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DOT',
            'name': 'DOT',
            'address': '0x994047FE66406CbD646cd85B990E11D7F5dB8fC7',
            'decimals': 10,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x994047FE66406CbD646cd85B990E11D7F5dB8fC7/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'cronaswap'
            ]
        },
        {
            'symbol': 'ANN',
            'name': 'Annex Finance',
            'address': '0x98936Bde1CF1BFf1e7a8012Cee5e2583851f2067',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/18180/thumb/ANN_logo_200x200.png?1630899163',
            'coingeckoId': 'annex',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'GRVE',
            'name': 'Grave',
            'address': '0x9885488cD6864DF90eeBa6C5d07B35f08CEb05e9',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/24088/thumb/monetone_solo.png?1646295290',
            'coingeckoId': 'grave',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORBS',
            'name': 'Orbs',
            'address': '0x9873795F5DAb11e1c0342C4a58904c59827ede0c',
            'decimals': 18,
            'chainId': 25,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MMF',
            'name': 'MMFinance  Cronos ',
            'address': '0x97749c9B61F878a880DfE312d2594AE07AEd7656',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/22273/small/g7GDg3bv_400x400.jpg?1641344286',
            'coingeckoId': 'mmfinance',
            'listedIn': [
                'coingecko',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'RMAI',
            'name': 'RMAI',
            'address': '0x9700104AC74C0d52E9441f3324AdC97059EE429b',
            'decimals': 18,
            'chainId': 25,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'RouterProtocol'
            ]
        },
        {
            'symbol': 'MUSD',
            'name': 'Mad USD',
            'address': '0x95aEaF383E2e86A47c11CffdE1F7944eCB2C38C2',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/25480/small/musd.png?1652010034',
            'coingeckoId': 'mad-usd',
            'listedIn': [
                'coingecko',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'XCRX',
            'name': 'xCRX',
            'address': '0x9315054f01bF8c13EE67C8498Af09A1933cbF24c',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/28034/thumb/xCRX_%28200_%C3%97_200_px%29.png?1667118641',
            'coingeckoId': 'xcrx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUNA',
            'name': 'LUNA Token',
            'address': '0x9278C8693e7328bef49804BacbFb63253565dffD',
            'decimals': 6,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x9278C8693e7328bef49804BacbFb63253565dffD/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'cronaswap'
            ]
        },
        {
            'symbol': 'CroATOM',
            'name': 'CroATOM',
            'address': '0x8b0D1164e98DD0592598B56C1Baa86462c73A2d1',
            'decimals': 9,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x8b0D1164e98DD0592598B56C1Baa86462c73A2d1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'cronaswap'
            ]
        },
        {
            'symbol': 'TUSD',
            'name': 'TrueUSD',
            'address': '0x87EFB3ec1576Dec8ED47e58B832bEdCd86eE186e',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://static.debank.com/image/cro_token/logo_url/0x87efb3ec1576dec8ed47e58b832bedcd86ee186e/100307a7dfc8a5281a76beda99c17cd8.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CGO',
            'name': 'CGO',
            'address': '0x869c3e35Ef9E5F50003D9a2A80f1D61d670D4CB6',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/26058/thumb/cgo_logo_200.png?1655454967',
            'coingeckoId': 'cgo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DARK',
            'name': 'DarkCrypto',
            'address': '0x83b2AC8642aE46FC2823Bc959fFEB3c1742c48B5',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x83b2AC8642aE46FC2823Bc959fFEB3c1742c48B5/logo.png',
            'coingeckoId': 'darkcrypto',
            'listedIn': [
                'coingecko',
                'lifinance',
                'xyfinance',
                'cronaswap'
            ]
        },
        {
            'symbol': 'CRN',
            'name': 'CroNodes',
            'address': '0x8174BaC1453c3AC7CaED909c20ceaDeb5E1CDA00',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x8174BaC1453c3AC7CaED909c20ceaDeb5E1CDA00/logo.png',
            'coingeckoId': 'cronodes',
            'listedIn': [
                'coingecko',
                'xyfinance',
                'cronaswap'
            ]
        },
        {
            'symbol': 'CRP',
            'name': 'CRO Predict',
            'address': '0x7b8aD6d7560FAcd1959cfb4b4163D7d297c4bFc0',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/23364/thumb/SAVE_20220126_232119.jpg?1643947481',
            'coingeckoId': 'cro-predict',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APE',
            'name': 'ApeCoin',
            'address': '0x7aE97042a4A0eB4D1eB370C34BfEC71042a056B7',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x7aE97042a4A0eB4D1eB370C34BfEC71042a056B7/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'cronaswap'
            ]
        },
        {
            'symbol': 'CBRDOG',
            'name': 'CbrdogToken',
            'address': '0x7a6a832eB5F58245F7d75eD980cED849D69A98FD',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x7a6a832eB5F58245F7d75eD980cED849D69A98FD/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'cronaswap'
            ]
        },
        {
            'symbol': 'ONI',
            'name': 'Onino',
            'address': '0x7D3B448c1242b563c65e8DAfCc13557c3069B5b6',
            'decimals': 18,
            'chainId': 25,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CroMoon',
            'name': 'CroMoon',
            'address': '0x7D30c36f845d1dEe79f852abF3A8A402fAdF3b53',
            'decimals': 9,
            'chainId': 25,
            'logoURI': 'https://static.debank.com/image/cro_token/logo_url/0x7d30c36f845d1dee79f852abf3a8a402fadf3b53/935c8992b5198034a6f09c47f587d64c.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MSHARE',
            'name': 'MSHARE',
            'address': '0x7D09a42045359Aa85488bC07D0ADa83E22d50017',
            'decimals': 18,
            'chainId': 25,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FIRA',
            'name': 'Defira  Cronos ',
            'address': '0x7ABa852082b6F763E13010CA33B5D9Ea4EeE2983',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/27211/thumb/fira-flat.png?1662609278',
            'coingeckoId': 'fira-cronos',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OLE',
            'name': 'OpenLeverage',
            'address': '0x76a3D96726C0ED756eA420d239d3Feb998EBf528',
            'decimals': 18,
            'chainId': 25,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DoKEN',
            'name': 'DoKEN',
            'address': '0x76C1E6ABB673E535A7EbceE2EEA968ed88022202',
            'decimals': 18,
            'chainId': 25,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AVAX',
            'name': 'AVAX',
            'address': '0x765277EebeCA2e31912C9946eAe1021199B39C61',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x765277EebeCA2e31912C9946eAe1021199B39C61/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'cronaswap'
            ]
        },
        {
            'symbol': 'LAB',
            'name': 'The Laboratory',
            'address': '0x75F55E718Ea51191966f256893bD96Db2DE9eE94',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/25176/thumb/Lab_Logo_circular.png?1650527640',
            'coingeckoId': 'the-laboratory',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CROTERRA',
            'name': 'CROTerra',
            'address': '0x74F53e67D68A348611979e3012EDf9781C437529',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x74F53e67D68A348611979e3012EDf9781C437529/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'cronaswap'
            ]
        },
        {
            'symbol': 'CROSS',
            'name': 'AvtoCROSS',
            'address': '0x6ef20cA7E493c52095e892DAB78a7FD0e7e2a279',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x6ef20cA7E493c52095e892DAB78a7FD0e7e2a279/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'cronaswap'
            ]
        },
        {
            'symbol': 'CRNF',
            'name': 'CroNodes Finance',
            'address': '0x6eDd4e7A5bd9688AAb374E5A0447c2A2FF10fAEA',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x6eDd4e7A5bd9688AAb374E5A0447c2A2FF10fAEA/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'cronaswap'
            ]
        },
        {
            'symbol': 'BUSD',
            'name': 'BUSD',
            'address': '0x6aB6d61428fde76768D7b45D8BFeec19c6eF91A8',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x6aB6d61428fde76768D7b45D8BFeec19c6eF91A8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'cronaswap'
            ]
        },
        {
            'symbol': 'XDSHARE',
            'name': 'ToxicDeer Share',
            'address': '0x6F715158d4B1468528DA002F5941c72fe4159520',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/25964/thumb/xdshare.png?1654940069',
            'coingeckoId': 'toxicdeer-share',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'DEER',
            'name': 'ToxicDeer Finance',
            'address': '0x6D6D6ba0c7e7DBaFffeC82b1ddF92e271650a63A',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/25890/thumb/deer.png?1654426919',
            'coingeckoId': 'toxicdeer-finance',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'HIBIKI',
            'name': 'Hibiki Finance',
            'address': '0x6B66fCB66Dba37F99876a15303b759c73fc54ed0',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x6B66fCB66Dba37F99876a15303b759c73fc54ed0/logo.png',
            'coingeckoId': 'hibiki-finance',
            'listedIn': [
                'coingecko',
                'cronaswap'
            ]
        },
        {
            'symbol': 'LOOTDOLLAR',
            'name': 'Loot Dollar',
            'address': '0x68C4FD0c9dE98f05D206cd04c958a0Ec14cE016e',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/25605/thumb/20148.png?1652776763',
            'coingeckoId': 'loot-dollar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHACK',
            'name': 'Shack',
            'address': '0x671D2E0E1a9c8E117A071bFC5763aF3fBe9bDF0b',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/25699/thumb/shack_no_bg_no_pad3.png?1653441433',
            'coingeckoId': 'shack',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'GSHARE',
            'name': 'Gaur Shares',
            'address': '0x66ec6E9F61ac288f5BA661CD9a2dBe3aBf9871C9',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/22900/thumb/gshare.png?1653810600',
            'coingeckoId': 'gaur-shares',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'Tether',
            'address': '0x66e428c3f67a68878562e79A0234c1F83c208770',
            'decimals': 6,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x66e428c3f67a68878562e79A0234c1F83c208770/logo.png',
            'coingeckoId': 'tether',
            'listedIn': [
                'coingecko',
                'lifinance',
                'xyfinance',
                'elkfinance',
                'cronaswap'
            ]
        },
        {
            'symbol': 'DUSD',
            'name': 'Darkness Dollar',
            'address': '0x6582C738660bf0701f05b04DcE3c4E5Fcfcda47a',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/24319/small/DUSD_Logo.png?1647355734',
            'coingeckoId': 'darkness-dollar',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'SVN',
            'name': 'Savanna',
            'address': '0x654bAc3eC77d6dB497892478f854cF6e8245DcA9',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/23692/small/SVN_LOGO.png?1645080153',
            'coingeckoId': 'savanna',
            'listedIn': [
                'coingecko',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'BUILD',
            'name': 'BUILD',
            'address': '0x6467df17771AB26D1825bF0891b3c421D92EBC1d',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/26533/thumb/BUILD.png?1658714842',
            'coingeckoId': 'build',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MHUB',
            'name': 'Metaverse Hub',
            'address': '0x63E65C6d7c43F1BA6188943Cc89ba4b002a2AD0D',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/26529/thumb/MHUB.png?1658576763',
            'coingeckoId': 'metaverse-hub',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SALEM',
            'name': 'Salem Finance',
            'address': '0x637CB66526244029407046867E1E0DFD28b2294E',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x637CB66526244029407046867E1E0DFD28b2294E/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'cronaswap'
            ]
        },
        {
            'symbol': 'UNI',
            'name': 'UNI',
            'address': '0x5fC17416925789E0852FBFcd81c490ca4abc51F9',
            'decimals': 18,
            'chainId': 25,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SUS',
            'name': 'PegasusDAO',
            'address': '0x5b5Fe1238aca91C65683aCd7f9D9Bf922e271EAA',
            'decimals': 9,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x5b5Fe1238aca91C65683aCd7f9D9Bf922e271EAA/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'cronaswap'
            ]
        },
        {
            'symbol': 'WCRO',
            'name': 'Wrapped CRO',
            'address': '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23/logo.png',
            'coingeckoId': 'wrapped-cro',
            'listedIn': [
                'coingecko',
                'lifinance',
                'xyfinance',
                'elkfinance',
                'cronaswap'
            ]
        },
        {
            'symbol': 'CKRACING',
            'name': 'Crypto Kart Racing',
            'address': '0x5B3c7b96ea7be0367d054a8ef62557d4f4Dd0fE7',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/25443/thumb/CKR-logo-200x200-1.png?1651755064',
            'coingeckoId': 'crypto-kart-racing',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PHNX',
            'name': 'Phenix Finance  OLD ',
            'address': '0x57d06bB1e3B60C875cD3A4445a53217F9B44d390',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/24551/thumb/pf-200.png?1648134213',
            'coingeckoId': 'phenix-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CROSHIBA',
            'name': 'CROSHIBA',
            'address': '0x5617EA36c5da5dAFaB51A8634B02609459fEE94F',
            'decimals': 4,
            'chainId': 25,
            'logoURI': 'https://elk.finance/tokens/logos/cronos/0x5617EA36c5da5dAFaB51A8634B02609459fEE94F/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'BANK',
            'name': 'CroBank',
            'address': '0x55210C2a69b4c52a9d9289A257D54d35C4a2d2eC',
            'decimals': 9,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x55210C2a69b4c52a9d9289A257D54d35C4a2d2eC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance',
                'cronaswap'
            ]
        },
        {
            'symbol': 'PHNX',
            'name': 'Phenix Finance',
            'address': '0x52438441073A33b6FcBc70fa3F3F334F2dA19906',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/27724/small/phenix.png?1666262269',
            'coingeckoId': 'phenix-finance-2',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'MMO',
            'name': 'Mad Meerkat Optimizer',
            'address': '0x50c0C5bda591bc7e89A342A3eD672FB59b3C46a7',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/22367/small/MMO.png?1641763762',
            'coingeckoId': 'mad-meerkat-optimizer',
            'listedIn': [
                'coingecko',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'DUO',
            'name': 'Singular.farm',
            'address': '0x4ff6334aa95aFfC85F09738eEfc866cBEA7DC7c6',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x4ff6334aa95aFfC85F09738eEfc866cBEA7DC7c6/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'cronaswap'
            ]
        },
        {
            'symbol': 'DDL',
            'name': 'DeFi Degen Land',
            'address': '0x4e60216965e37e54DC94A1D9e473211e9DBC35bA',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/20871/thumb/0inlfwNU_400x400.jpg?1637809911',
            'coingeckoId': 'defi-degen-land',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CGS',
            'name': 'Cougar Token',
            'address': '0x4e57e27e4166275Eb7f4966b42A201d76e481B03',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x4e57e27e4166275Eb7f4966b42A201d76e481B03/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'cronaswap'
            ]
        },
        {
            'symbol': 'CROTAMA',
            'name': 'CROTAMA',
            'address': '0x4c76a10A658d71AdB6431765a0a3cA13B6e2D491',
            'decimals': 2,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/24923/small/crotamalogo-200x200.png?1651745551',
            'coingeckoId': null,
            'listedIn': [
                'xyfinance'
            ]
        },
        {
            'symbol': 'DKN',
            'name': 'DoKEN V2',
            'address': '0x4a1F7d14d68B8D5c1604ad235f06e57054435ed9',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/20283/thumb/Qu5sof9.png?1638363195',
            'coingeckoId': 'doken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LION',
            'name': 'Lion Scrub Money  OLD ',
            'address': '0x49fB98F9b4a3183Cd88e7a115144fdf00fa6fB95',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/24950/thumb/lion.png?1649504730',
            'coingeckoId': 'lion-scrub-money',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'PENDLE',
            'name': 'Pendle',
            'address': '0x49c3bBB239f4FB44327073510f4bA72D207a81D6',
            'decimals': 18,
            'chainId': 25,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BURROW',
            'name': 'MMF Money',
            'address': '0x49ab7ca4D2Cf6777c8C56C5E9Edb7D1218AE1299',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/25492/small/BURROW.png?1652075351',
            'coingeckoId': 'mmf-money',
            'listedIn': [
                'coingecko',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'WIND',
            'name': 'StormSwap Finance',
            'address': '0x48713151E5AFB7b4CC45f3653c1c59CF81E88D4B',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x48713151E5AFB7b4CC45f3653c1c59CF81E88D4B/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'cronaswap'
            ]
        },
        {
            'symbol': 'ARGO',
            'name': 'Argo Finance',
            'address': '0x47A9D630dc5b28F75d3AF3be3AAa982512Cd89Aa',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/25381/small/argo-protocol.jpeg?1651468819',
            'coingeckoId': 'argo-finance',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': '$ROX$',
            'name': '$ROX$',
            'address': '0x46cAe37a4C67Cba4642904cC46344B463c5A531D',
            'decimals': 9,
            'chainId': 25,
            'logoURI': 'https://storage.googleapis.com/xy-finance-images/token-list/%24ROX%24.PNG',
            'coingeckoId': null,
            'listedIn': [
                'xyfinance'
            ]
        },
        {
            'symbol': 'ALI',
            'name': 'Alethea Artificial Liquid Intelligence',
            'address': '0x45C135C1CDCE8d25A3B729A28659561385C52671',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/22062/thumb/alethea-logo-transparent-colored.png?1642748848',
            'coingeckoId': 'alethea-artificial-liquid-intelligence-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EVO',
            'name': 'EVO',
            'address': '0x42006Ab57701251B580bDFc24778C43c9ff589A1',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/25088/large/evoToken.png?1650269135',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'RouterProtocol'
            ]
        },
        {
            'symbol': 'DEXI',
            'name': 'Dexioprotocol',
            'address': '0x3e1bEF464F2600eF94dE4d965c8991e06570d2e8',
            'decimals': 9,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/27075/thumb/dexio-v2.png?1661691044',
            'coingeckoId': 'dexioprotocol-v2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTE',
            'name': 'Betero',
            'address': '0x3D632d9e1a60a0880Dd45E61f279D919b5748377',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/25153/thumb/B_green200.png?1650461529',
            'coingeckoId': 'betero',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'FER',
            'name': 'Ferro',
            'address': '0x39bC1e38c842C60775Ce37566D03B41A7A66C782',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/26111/thumb/FER_Token.png?1655887206',
            'coingeckoId': 'ferro',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MTT',
            'name': 'Hakuna Share',
            'address': '0x388c07066AA6cEa2Be4db58e702333df92c3A074',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://static.debank.com/image/cro_token/logo_url/0x388c07066aa6cea2be4db58e702333df92c3a074/a7857b646a8c4c1d2b275dfad9ee814d.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'UNICORN',
            'name': 'Fairy Finance UNICORN',
            'address': '0x387c06f6e8d1F65839FCb9171171aBb5F49F8b20',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/27233/thumb/unicorn.90630d4c.png?1662710115',
            'coingeckoId': 'fairy-finance-unicorn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRD',
            'name': 'Crolend',
            'address': '0x34DeB73e57f7be74D2ccA1869d2c721e16C7a32C',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/28188/thumb/CRDlogo200_%281%29.png?1668259759',
            'coingeckoId': 'crolend',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BISON',
            'name': 'Bison',
            'address': '0x3405A1bd46B85c5C029483FbECf2F3E611026e45',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://static.debank.com/image/cro_token/logo_url/0x3405a1bd46b85c5c029483fbecf2f3e611026e45/843eb2196b5399ef6b8be2e90236c12c.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'UCRO',
            'name': 'UtilityCro',
            'address': '0x30078453DEaD93bdBC31b9A18AC0a6ece171F459',
            'decimals': 9,
            'chainId': 25,
            'logoURI': 'https://elk.finance/tokens/logos/cronos/0x30078453DEaD93bdBC31b9A18AC0a6ece171F459/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'DARKG',
            'name': 'DarkGang Finance',
            'address': '0x2c43d9f78e4132FBe40BEBB8EC5F61377472b2C0',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/24638/thumb/DARKG.png?1648463537',
            'coingeckoId': 'darkgang-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VVS',
            'name': 'VVS Finance',
            'address': '0x2D03bECE6747ADC00E1a131BBA1469C15fD11e03',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/20210/small/8glAYOTM_400x400.jpg?1636667919',
            'coingeckoId': 'vvs-finance',
            'listedIn': [
                'coingecko',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'VRSE',
            'name': 'CronosVerse',
            'address': '0x2Ba01269EaFCe04c8DCCC4A9887884AB66E4Bcb1',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/26188/thumb/VRSE_Token_%283%29.png?1656386751',
            'coingeckoId': 'cronosverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIM',
            'name': 'MIM',
            'address': '0x2BC472832Eb20C65F82d6A869db845aB0C0099ba',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://static.debank.com/image/cro_token/logo_url/0x2bc472832eb20c65f82d6a869db845ab0c0099ba/aa79f041c1e6e1f1405963bae9ac1068.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MIMATIC',
            'name': 'MAI',
            'address': '0x2Ae35c8E3D4bD57e8898FF7cd2bBff87166EF8cb',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://elk.finance/tokens/logos/cronos/0x2Ae35c8E3D4bD57e8898FF7cd2bBff87166EF8cb/logo.png',
            'coingeckoId': 'mimatic',
            'listedIn': [
                'coingecko',
                'RouterProtocol',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ARC',
            'name': 'Arcane',
            'address': '0x289B8f552c9fD66f9cEA35B193F7ca73ae24A5d5',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x289B8f552c9fD66f9cEA35B193F7ca73ae24A5d5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'cronaswap'
            ]
        },
        {
            'symbol': 'MAD',
            'name': 'MAD Bucks',
            'address': '0x212331e1435A8df230715dB4C02B2a3A0abF8c61',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/24770/small/mmt_200x200.png?1648817945',
            'coingeckoId': 'mad-bucks',
            'listedIn': [
                'coingecko',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'ESC',
            'name': 'Empty Set Crypto',
            'address': '0x1eCAF6a3551E5822F142c9689D8F36a9B5F68217',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/25496/thumb/279202274_334067105301119_7381058283149128150_n.png?1652076813',
            'coingeckoId': 'empty-set-crypto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MND',
            'name': 'Mind Music',
            'address': '0x1cd2528522A17B6Be63012fB63AE81f3e3e29D97',
            'decimals': 9,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/19104/thumb/logo-main_%282%29.png?1634506312',
            'coingeckoId': 'mind-music',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAI',
            'name': 'miMatic',
            'address': '0x1c965D8E53fb1a448789e2B0FA5abc3EB2c36993',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://static.debank.com/image/cro_token/logo_url/0x1c965d8e53fb1a448789e2b0fa5abc3eb2c36993/be77e8e47016b1e9d3f01b7edc9b8d4f.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PAPER',
            'name': 'Paper',
            'address': '0x1affBc17938a25d245e1B7eC6f2fc949df8E9760',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/23510/thumb/0xea97c7c1c89d4084e0bfb88284fa90243779da9f.png?1644300084',
            'coingeckoId': 'paper-fantom',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CROMAX',
            'name': 'CRO Max Token',
            'address': '0x1985127d1B21af04d09a69Cdcb1dD33fd8961e9c',
            'decimals': 9,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x1985127d1B21af04d09a69Cdcb1dD33fd8961e9c/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'cronaswap'
            ]
        },
        {
            'symbol': 'RLM',
            'name': 'MarbleVerse',
            'address': '0x19258a1df9E929D02b34621CF52797998aE1Aa27',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/24844/thumb/RLM200x200.png?1649109426',
            'coingeckoId': 'rollium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CROCRO',
            'name': 'CrO cRo',
            'address': '0x17C00923Ce44f2748DB6A14f979D55C497A267e4',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x17C00923Ce44f2748DB6A14f979D55C497A267e4/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'cronaswap'
            ]
        },
        {
            'symbol': 'POOR',
            'name': 'Poor Quack',
            'address': '0x1703Dd2d4a1b14D0Bcb87C445f3263179d523b16',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/22525/thumb/16932.png?1641980086',
            'coingeckoId': 'poor-quack',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DIBS',
            'name': 'dibs.money',
            'address': '0x165DBb08de0476271714952C3C1F068693bd60D7',
            'decimals': 18,
            'chainId': 25,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SCRATCH',
            'name': 'Scratch',
            'address': '0x14fB0e7640e7fb7765CFA87cEc973ff5465d1c66',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/27706/thumb/LogoPlain.png?1665309451',
            'coingeckoId': 'scratch',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CROSHI',
            'name': 'CROSHI',
            'address': '0x10faae6A25354544081D1aa3438e66086218d851',
            'decimals': 9,
            'chainId': 25,
            'logoURI': 'https://storage.googleapis.com/xy-finance-images/token-list/CROSHI.png',
            'coingeckoId': null,
            'listedIn': [
                'xyfinance'
            ]
        },
        {
            'symbol': 'MIMAS',
            'name': 'Mimas Finance',
            'address': '0x10C9284E6094b71D3CE4E38B8bFfc668199da677',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/22516/thumb/mimas.PNG?1641967894',
            'coingeckoId': 'mimas-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WADA',
            'name': 'Wrapped ADA',
            'address': '0x0e517979C2c1c1522ddB0c73905e0D39b3F990c0',
            'decimals': 6,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x0e517979C2c1c1522ddB0c73905e0D39b3F990c0/logo.svg',
            'coingeckoId': 'wrapped-ada',
            'listedIn': [
                'coingecko',
                'cronaswap'
            ]
        },
        {
            'symbol': 'AUTO',
            'name': 'AUTOv2',
            'address': '0x0dCb0CB0120d355CdE1ce56040be57Add0185BAa',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x0dCb0CB0120d355CdE1ce56040be57Add0185BAa/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance',
                'cronaswap'
            ]
        },
        {
            'symbol': 'CADDY',
            'name': 'Adamant Token',
            'address': '0x09ad12552ec45f82bE90B38dFE7b06332A680864',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x09ad12552ec45f82bE90B38dFE7b06332A680864/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'cronaswap'
            ]
        },
        {
            'symbol': 'MINE',
            'name': 'Mine Shares',
            'address': '0x0944d9a8Dea16af1170c51E8DD3921C727A06cBd',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/26444/thumb/Logotype_Fashion_Store_Logo_Neon_%282%29-modified.png?1658110350',
            'coingeckoId': 'mine-shares',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SINGLE',
            'name': 'Single Finance',
            'address': '0x0804702a4E749d39A35FDe73d1DF0B1f1D6b8347',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/22616/small/single.png?1642313588',
            'coingeckoId': 'single-finance',
            'listedIn': [
                'coingecko',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'SH33P',
            'name': 'Degen Protocol',
            'address': '0x0694c9bf930b7456712cEde98bE770e110212B38',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/22464/thumb/96222235.png?1641883603',
            'coingeckoId': 'degen-protocol-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRK',
            'name': 'Croking',
            'address': '0x065DE42E28E42d90c2052a1B49e7f83806Af0e1F',
            'decimals': 9,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/22667/small/Logo_200x200.png?1642403624',
            'coingeckoId': 'croking',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'WBTC',
            'name': 'Wrapped Bitcoin',
            'address': '0x062E66477Faf219F25D27dCED647BF57C3107d52',
            'decimals': 8,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x062E66477Faf219F25D27dCED647BF57C3107d52/logo.png',
            'coingeckoId': 'wrapped-bitcoin',
            'listedIn': [
                'coingecko',
                'lifinance',
                'xyfinance',
                'elkfinance',
                'cronaswap'
            ]
        },
        {
            'symbol': 'GAUR',
            'name': 'Gaur Money',
            'address': '0x046cb616d7a52173e4Da9efF1BFd590550aa3228',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/22515/thumb/gaur.png?1653810487',
            'coingeckoId': 'gaur-money',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BASE',
            'name': 'Defido Coin',
            'address': '0x0422b63182798ae9876cae3caBd225d96B284D14',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x0422b63182798ae9876cae3caBd225d96B284D14/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'cronaswap'
            ]
        },
        {
            'symbol': 'CRONAVIRUS',
            'name': 'Cronavirus',
            'address': '0x039AcEC8659Cc69a35E8A48c618c47128934B575',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://elk.finance/tokens/logos/cronos/0x039AcEC8659Cc69a35E8A48c618c47128934B575/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'MetaPx',
            'name': 'MetaversePixels',
            'address': '0x03627840190152771e86e5E702175B00219F6471',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x03627840190152771e86e5E702175B00219F6471/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'cronaswap'
            ]
        },
        {
            'symbol': 'ELON',
            'name': 'Dogelon Mars',
            'address': '0x02DCcaf514C98451320a9365C5b46C61d3246ff3',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://raw.githubusercontent.com/cronaswap/default-token-list/main/assets/tokens/cronos/0x02DCcaf514C98451320a9365C5b46C61d3246ff3/logo.png',
            'coingeckoId': 'dogelon-mars',
            'listedIn': [
                'coingecko',
                'lifinance',
                'cronaswap'
            ]
        },
        {
            'symbol': '10MB',
            'name': '10mb',
            'address': '0x02A8Dc66334B1cc6CD8F28Fe8DBF6b58B49b47B6',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/26218/thumb/10mb_logo_200.png?1656637898',
            'coingeckoId': '10mb',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTD',
            'name': 'Minted',
            'address': '0x0224010BA2d567ffa014222eD960D1fa43B8C8E1',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/26893/thumb/MTD_Token_%282%29.png?1660708482',
            'coingeckoId': 'minted',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOAL',
            'name': 'CronosFC',
            'address': '0x00fe915a5209e74D5a88334cC2daA4541AEC8278',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/24505/thumb/logo_v13.jpg?1647882353',
            'coingeckoId': 'goal',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VERSA',
            'name': 'VersaGames',
            'address': '0x00D7699b71290094CcB1a5884cD835bD65a78c17',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/25491/small/versa_token_96_96.png?1652074785',
            'coingeckoId': 'versagames',
            'listedIn': [
                'coingecko',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'CRO',
            'name': 'CRO',
            'address': '0x0000000000000000000000000000000000000000',
            'decimals': 18,
            'chainId': 25,
            'logoURI': 'https://assets.coingecko.com/coins/images/7310/small/oCw2s3GI_400x400.jpeg?1645172042',
            'coingeckoId': 'crypto-com-chain',
            'listedIn': [
                'lifinance',
                'xyfinance'
            ]
        }
    ],
    '288': [
        {
            'symbol': 'AVAX',
            'name': 'Avalanche',
            'address': '0xfA9343C3897324496A05fC75abeD6bAC29f8A40f',
            'decimals': 18,
            'chainId': 288,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1639129975284_24194513240630244.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'DAI',
            'name': 'Dai',
            'address': '0xf74195Bb8a5cf652411867c5C2C5b8C2a402be35',
            'decimals': 18,
            'chainId': 288,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1639130905265_9701560750428779.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'FTM',
            'name': 'Fantom',
            'address': '0xeFAeeE334F0Fd1712f9a8cc375f427D9Cdd40d73',
            'decimals': 18,
            'chainId': 288,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1639131240817_3745464539583767.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'GIN',
            'name': 'Gin Finance',
            'address': '0xe48703F5AdfE59d271D951c2E602F654B86736E7',
            'decimals': 18,
            'chainId': 288,
            'logoURI': 'https://assets.coingecko.com/coins/images/25785/thumb/logo-04.png?1653882271',
            'coingeckoId': 'gin-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OMG',
            'name': 'OMG Network',
            'address': '0xe1E2ec9a85C607092668789581251115bCBD20de',
            'decimals': 18,
            'chainId': 288,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1639128976536_02180437769771748.png',
            'coingeckoId': 'omisego',
            'listedIn': [
                'coingecko',
                'openocean'
            ]
        },
        {
            'symbol': 'WBTC',
            'name': 'Wrapped Bitcoin',
            'address': '0xdc0486f8bf31DF57a952bcd3c1d3e166e3d9eC8b',
            'decimals': 8,
            'chainId': 288,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1639129132128_3891051687439073.png',
            'coingeckoId': 'wrapped-bitcoin',
            'listedIn': [
                'coingecko',
                'openocean'
            ]
        },
        {
            'symbol': 'gOHM',
            'name': 'Governance OHM',
            'address': '0xd22C0a4Af486C7FA08e282E9eB5f30F9AaA62C95',
            'decimals': 18,
            'chainId': 288,
            'logoURI': 'https://raw.githubusercontent.com/OolongSwap/token-logos/main/288/0xd22C0a4Af486C7FA08e282E9eB5f30F9AaA62C95/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'oolongswap'
            ]
        },
        {
            'symbol': 'BANA',
            'name': 'Banana',
            'address': '0xc67b9B1B0557aeAfA10AA1fFa1d7c87087a6149E',
            'decimals': 18,
            'chainId': 288,
            'logoURI': 'https://raw.githubusercontent.com/OolongSwap/token-logos/main/288/0xc67b9B1B0557aeAfA10AA1fFa1d7c87087a6149E/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'oolongswap'
            ]
        },
        {
            'symbol': 'BOBA',
            'name': 'Boba Network',
            'address': '0xa18bF3994C0Cc6E3b63ac420308E5383f53120D7',
            'decimals': 18,
            'chainId': 288,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1639130528284_184421563689809.png',
            'coingeckoId': 'boba-network',
            'listedIn': [
                'coingecko',
                'openocean'
            ]
        },
        {
            'symbol': 'SHIBUI',
            'name': 'Shibui DAO',
            'address': '0xF08AD7C3f6b1c6843ba027AD54Ed8DDB6D71169b',
            'decimals': 18,
            'chainId': 288,
            'logoURI': 'https://raw.githubusercontent.com/OolongSwap/token-logos/main/288/0xF08AD7C3f6b1c6843ba027AD54Ed8DDB6D71169b/logo.png',
            'coingeckoId': 'shibui-dao',
            'listedIn': [
                'coingecko',
                'oolongswap'
            ]
        },
        {
            'symbol': 'WETH',
            'name': 'WETH',
            'address': '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000',
            'decimals': 18,
            'chainId': 288,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1640068479467_666268388062502.png',
            'coingeckoId': 'weth',
            'listedIn': [
                'coingecko',
                'openocean'
            ]
        },
        {
            'symbol': 'UNI',
            'name': 'Uniswap',
            'address': '0xDBDE1347fED5dC03C74059010D571a16417d307e',
            'decimals': 18,
            'chainId': 288,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1639131692553_8148288195463247.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'WAGMIV1',
            'name': 'Boba WAGMI v1 Option',
            'address': '0xCe055Ea4f29fFB8bf35E852522B96aB67Cbe8197',
            'decimals': 18,
            'chainId': 288,
            'logoURI': 'https://raw.githubusercontent.com/OolongSwap/token-logos/main/288/0xCe055Ea4f29fFB8bf35E852522B96aB67Cbe8197/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'oolongswap'
            ]
        },
        {
            'symbol': 'WAGMIV3',
            'name': 'Boba WAGMI v3 Option',
            'address': '0xC6158B1989f89977bcc3150fC1F2eB2260F6cabE',
            'decimals': 18,
            'chainId': 288,
            'logoURI': 'https://raw.githubusercontent.com/OolongSwap/token-logos/main/288/0xC6158B1989f89977bcc3150fC1F2eB2260F6cabE/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'oolongswap'
            ]
        },
        {
            'symbol': 'BTEA',
            'name': 'BobaTeaInu',
            'address': '0x9c3a582A678BE0B0E954d7a5C07b0C7Eb8EF1e03',
            'decimals': 9,
            'chainId': 288,
            'logoURI': 'https://raw.githubusercontent.com/OolongSwap/token-logos/main/288/0x9c3a582A678BE0B0E954d7a5C07b0C7Eb8EF1e03/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'oolongswap'
            ]
        },
        {
            'symbol': 'MATIC',
            'name': 'Polygon',
            'address': '0x922D641a426DcFFaeF11680e5358F34d97d112E1',
            'decimals': 18,
            'chainId': 288,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1639131547439_2810875297987778.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'WAGMIV0',
            'name': 'Boba WAGMI v0 Option',
            'address': '0x8493C4d9Cd1a79be0523791E3331c78Abb3f9672',
            'decimals': 18,
            'chainId': 288,
            'logoURI': 'https://raw.githubusercontent.com/OolongSwap/token-logos/main/288/0x8493C4d9Cd1a79be0523791E3331c78Abb3f9672/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'oolongswap'
            ]
        },
        {
            'symbol': 'BNB',
            'name': 'Binance Coin',
            'address': '0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
            'decimals': 18,
            'chainId': 288,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1639130240397_9744304549952987.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'FRAX',
            'name': 'Frax',
            'address': '0x7562F525106F5d54E891e005867Bf489B5988CD9',
            'decimals': 18,
            'chainId': 288,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1640069122683_8257756398337042.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'ZENC',
            'name': 'Zencha',
            'address': '0x7445Efc8A6F1279434133408BEdeb6Eb4d1537Ab',
            'decimals': 18,
            'chainId': 288,
            'logoURI': 'https://raw.githubusercontent.com/OolongSwap/token-logos/main/288/0x7445Efc8A6F1279434133408BEdeb6Eb4d1537Ab/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'oolongswap'
            ]
        },
        {
            'symbol': 'oloWAGMIV3',
            'name': 'Boba WAGMI v3 Oolong Option',
            'address': '0x70bf3c5B5d80C4Fece8Bde0fCe7ef38B688463d4',
            'decimals': 18,
            'chainId': 288,
            'logoURI': 'https://raw.githubusercontent.com/OolongSwap/token-logos/main/288/0x70bf3c5B5d80C4Fece8Bde0fCe7ef38B688463d4/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'oolongswap'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USD Coin',
            'address': '0x66a2A913e447d6b4BF33EFbec43aAeF87890FBbc',
            'decimals': 6,
            'chainId': 288,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1639126999624_564648928450491.png',
            'coingeckoId': 'usd-coin',
            'listedIn': [
                'coingecko',
                'openocean'
            ]
        },
        {
            'symbol': 'aCYBER',
            'name': 'AlphaCyber',
            'address': '0x641e3C61DBFc5C9eD8f620eb474271cd4439f7d5',
            'decimals': 18,
            'chainId': 288,
            'logoURI': 'https://raw.githubusercontent.com/OolongSwap/token-logos/main/288/0x641e3C61DBFc5C9eD8f620eb474271cd4439f7d5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'oolongswap'
            ]
        },
        {
            'symbol': 'KYO',
            'name': 'K y ',
            'address': '0x618CC6549ddf12de637d46CDDadaFC0C2951131C',
            'decimals': 18,
            'chainId': 288,
            'logoURI': 'https://raw.githubusercontent.com/OolongSwap/token-logos/main/288/0x618CC6549ddf12de637d46CDDadaFC0C2951131C/logo.png',
            'coingeckoId': 'koyo',
            'listedIn': [
                'coingecko',
                'oolongswap'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'Tether',
            'address': '0x5DE1677344D3Cb0D7D465c10b72A8f60699C062d',
            'decimals': 6,
            'chainId': 288,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1639128907722_5976524470347457.png',
            'coingeckoId': 'tether',
            'listedIn': [
                'coingecko',
                'openocean'
            ]
        },
        {
            'symbol': 'OLO',
            'name': 'OolongSwap',
            'address': '0x5008F837883EA9a07271a1b5eB0658404F5a9610',
            'decimals': 18,
            'chainId': 288,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1639126902514_02776739497922076.png',
            'coingeckoId': 'oolongswap',
            'listedIn': [
                'coingecko',
                'openocean'
            ]
        },
        {
            'symbol': 'WAGMIV2',
            'name': 'Boba WAGMI v2 Oolong Option',
            'address': '0x49a3e4a1284829160f95eE785a1A5FfE2DD5Eb1D',
            'decimals': 18,
            'chainId': 288,
            'logoURI': 'https://raw.githubusercontent.com/OolongSwap/token-logos/main/288/0x49a3e4a1284829160f95eE785a1A5FfE2DD5Eb1D/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'oolongswap'
            ]
        },
        {
            'symbol': 'BUSD',
            'name': 'Binance USD',
            'address': '0x461d52769884ca6235B685EF2040F47d30C94EB5',
            'decimals': 18,
            'chainId': 288,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1640064724192_49362385974171685.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'BRE',
            'name': 'Boba Brewery',
            'address': '0x3A93bD0fA10050d206370eeA53276542A105C885',
            'decimals': 18,
            'chainId': 288,
            'logoURI': 'https://raw.githubusercontent.com/OolongSwap/token-logos/main/288/0x3A93bD0fA10050d206370eeA53276542A105C885/logo.png',
            'coingeckoId': 'boba-brewery',
            'listedIn': [
                'coingecko',
                'oolongswap'
            ]
        },
        {
            'symbol': 'UNIDX',
            'name': 'UniDex',
            'address': '0x375488F097176507e39B9653b88FDc52cDE736Bf',
            'decimals': 18,
            'chainId': 288,
            'logoURI': 'https://raw.githubusercontent.com/OolongSwap/token-logos/main/288/0x375488F097176507e39B9653b88FDc52cDE736Bf/logo.png',
            'coingeckoId': 'unidex',
            'listedIn': [
                'coingecko',
                'oolongswap'
            ]
        },
        {
            'symbol': 'BDOGE',
            'name': 'BobaDoge',
            'address': '0x121636C43E96D97AB00B6c6994cDDEBEf27dE1C7',
            'decimals': 18,
            'chainId': 288,
            'logoURI': 'https://raw.githubusercontent.com/OolongSwap/token-logos/main/288/0x121636C43E96D97AB00B6c6994cDDEBEf27dE1C7/logo.png',
            'coingeckoId': 'bobadoge',
            'listedIn': [
                'coingecko',
                'openocean',
                'oolongswap'
            ]
        },
        {
            'symbol': 'BORING',
            'name': 'BoringDAO',
            'address': '0x017Ff87AB312301aDE54f7cf9Cc5AEA28C9De024',
            'decimals': 18,
            'chainId': 288,
            'logoURI': 'https://raw.githubusercontent.com/OolongSwap/token-logos/main/288/0x017Ff87AB312301aDE54f7cf9Cc5AEA28C9De024/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'oolongswap'
            ]
        }
    ],
    '10000': [
        {
            'symbol': 'MARGARITA',
            'name': 'Margarita',
            'address': '0xe935C33b314330C773f9A135C0c8D8E857588609',
            'decimals': 18,
            'chainId': 10000,
            'logoURI': 'https://assets.coingecko.com/coins/images/24743/thumb/margarita.PNG?1648783473',
            'coingeckoId': 'margarita',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DAO',
            'name': 'Decentralized Autonomous Organization',
            'address': '0xca0235058985fcC1839E9e37c10900a73C126708',
            'decimals': 7,
            'chainId': 10000,
            'logoURI': 'https://assets.coingecko.com/coins/images/21439/thumb/0xca0235058985fcC1839E9e37c10900a73C126708.png?1639125143',
            'coingeckoId': 'decentralized-autonomous-organization',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPICE',
            'name': 'SPICE',
            'address': '0xE11829A7D5d8806BB36E118461a1012588faFD89',
            'decimals': 18,
            'chainId': 10000,
            'logoURI': 'https://assets.coingecko.com/coins/images/13398/thumb/VUenmQt_%281%29.png?1624954218',
            'coingeckoId': 'spice-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLEX',
            'name': 'FLEX Coin',
            'address': '0x98Dd7eC28FB43b3C4c770AE532417015fa939Dd3',
            'decimals': 18,
            'chainId': 10000,
            'logoURI': 'https://assets.coingecko.com/coins/images/9108/thumb/coinflex_logo.png?1628750583',
            'coingeckoId': 'flex-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SBUSD',
            'name': 'Smart BUSD',
            'address': '0x9288df32951386A8254aEaF80a66B78cCaf75b82',
            'decimals': 2,
            'chainId': 10000,
            'logoURI': 'https://assets.coingecko.com/coins/images/21481/thumb/sbusd-icon.png?1639352593',
            'coingeckoId': 'smart-busd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BPAD',
            'name': 'BCHPad',
            'address': '0x9192940099fDB2338B928DE2cad9Cd1525fEa881',
            'decimals': 18,
            'chainId': 10000,
            'logoURI': 'https://assets.coingecko.com/coins/images/22460/thumb/bmjzaQY1_400x400.jpg?1641881396',
            'coingeckoId': 'bchpad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WOJ',
            'name': 'Wojak Finance',
            'address': '0x8d7Ea0ec6CaB515463121A3c70Df541f2F534909',
            'decimals': 9,
            'chainId': 10000,
            'logoURI': 'https://assets.coingecko.com/coins/images/18823/thumb/200x200.png?1660620693',
            'coingeckoId': 'wojak-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLEXUSD',
            'name': 'flexUSD',
            'address': '0x7b2B3C5308ab5b2a1d9a94d20D35CCDf61e05b72',
            'decimals': 18,
            'chainId': 10000,
            'logoURI': 'https://assets.coingecko.com/coins/images/13323/thumb/flexUSD_2x.png?1607480702',
            'coingeckoId': 'flex-usd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '1BCH',
            'name': '1BCH',
            'address': '0x77d4b6e44a53bBdA9a1D156B32Bb53A2D099e53D',
            'decimals': 18,
            'chainId': 10000,
            'logoURI': 'https://assets.coingecko.com/coins/images/23460/thumb/1bch.png?1644217305',
            'coingeckoId': '1bch',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EBEN',
            'name': 'Green Ben',
            'address': '0x77CB87b57F54667978Eb1B199b28a0db8C8E1c0B',
            'decimals': 18,
            'chainId': 10000,
            'logoURI': 'https://assets.coingecko.com/coins/images/21330/thumb/200x200.png?1638946647',
            'coingeckoId': 'green-ben',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CLY',
            'name': 'Celery',
            'address': '0x7642Df81b5BEAeEb331cc5A104bd13Ba68c34B91',
            'decimals': 18,
            'chainId': 10000,
            'logoURI': 'https://assets.coingecko.com/coins/images/21457/thumb/C1tpQek6bQ25.png?1639291682',
            'coingeckoId': 'celery',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TANGO',
            'name': 'TangoSwap',
            'address': '0x73BE9c8Edf5e951c9a0762EA2b1DE8c8F38B5e91',
            'decimals': 18,
            'chainId': 10000,
            'logoURI': 'https://assets.coingecko.com/coins/images/21853/thumb/Adobe_20211124_194931.png?1640143427',
            'coingeckoId': 'tangoswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JOY',
            'name': 'Joystick club',
            'address': '0x6732E55Ac3ECa734F54C26Bd8DF4eED52Fb79a6E',
            'decimals': 18,
            'chainId': 10000,
            'logoURI': 'https://assets.coingecko.com/coins/images/21473/thumb/android-chrome-192x192.png?1639349299',
            'coingeckoId': 'joystick-club',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIST',
            'name': 'MistSwap',
            'address': '0x5fA664f69c2A4A3ec94FaC3cBf7049BD9CA73129',
            'decimals': 18,
            'chainId': 10000,
            'logoURI': 'https://assets.coingecko.com/coins/images/21389/thumb/mist.PNG?1639035441',
            'coingeckoId': 'mistswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WBCH',
            'name': 'Wrapped BCH',
            'address': '0x3743eC0673453E5009310C727Ba4eaF7b3a1cc04',
            'decimals': 18,
            'chainId': 10000,
            'logoURI': 'https://assets.coingecko.com/coins/images/21386/thumb/0x3743eC0673453E5009310C727Ba4eaF7b3a1cc04.png?1639034830',
            'coingeckoId': 'wrapped-bch',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CATS',
            'name': 'CashCats',
            'address': '0x265bD28d79400D55a1665707Fa14A72978FA6043',
            'decimals': 2,
            'chainId': 10000,
            'logoURI': 'https://assets.coingecko.com/coins/images/21387/thumb/ZQc8svVW_400x400.jpg?1639035078',
            'coingeckoId': 'cashcats',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DAIQUIRI',
            'name': 'Tropical Finance',
            'address': '0x24d8d5Cbc14FA6A740c3375733f0287188F8dF3b',
            'decimals': 18,
            'chainId': 10000,
            'logoURI': 'https://assets.coingecko.com/coins/images/22452/thumb/6787241B-90A3-4D28-8F06-7CE575186CF0.png?1641825262',
            'coingeckoId': 'tropical-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LAW',
            'name': 'LAW',
            'address': '0x0b00366fBF7037E9d75E4A569ab27dAB84759302',
            'decimals': 18,
            'chainId': 10000,
            'logoURI': 'https://assets.coingecko.com/coins/images/21468/thumb/0x0b00366fBF7037E9d75E4A569ab27dAB84759302.png?1639345859',
            'coingeckoId': 'law',
            'listedIn': [
                'coingecko'
            ]
        }
    ],
    '1313161554': [
        {
            'symbol': 'LUNA',
            'name': 'wrapped luna token',
            'address': '0xfCA152a9916895bF564E3f26a611f9e1e6AA6E73',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://api.rubic.exchange/assets/aurora/0xfca152a9916895bf564e3f26a611f9e1e6aa6e73/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'POLAR',
            'name': 'Polaris Finance Polar',
            'address': '0xf0f3b9Eee32b1F490A4b8720cf6F005d4aE9eA86',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0xf0f3b9Eee32b1F490A4b8720cf6F005d4aE9eA86/logo.svg',
            'coingeckoId': 'polar-token',
            'listedIn': [
                'coingecko',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'FLX',
            'name': 'FluxProtocol',
            'address': '0xea62791aa682d455614eaA2A12Ba3d9A2fD197af',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://tokens.1inch.io/0xea62791aa682d455614eaa2a12ba3d9a2fd197af.png',
            'coingeckoId': 'flux-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'ALM',
            'name': 'Alium Finance',
            'address': '0xe8532E5514d9F80C7d0B1F29948873ee59Fb5B06',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://assets.coingecko.com/coins/images/15621/thumb/alium.png?1655449806',
            'coingeckoId': 'alium-finance',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'bHOME',
            'name': 'bHome',
            'address': '0xe4baf0af161bf03434d1c5A53957981493c12c99',
            'decimals': 6,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0xe4baf0af161bf03434d1c5A53957981493c12c99/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'DAI',
            'name': 'Dai',
            'address': '0xe3520349F477A5F6EB06107066048508498A291b',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://tokens.1inch.io/0xe3520349f477a5f6eb06107066048508498a291b.png',
            'coingeckoId': 'dai',
            'listedIn': [
                'coingecko',
                '1inch',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'DODO',
            'name': 'DODO',
            'address': '0xe301eD8C7630C9678c39E4E45193D1e7Dfb914f7',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0xe301eD8C7630C9678c39E4E45193D1e7Dfb914f7/logo.svg',
            'coingeckoId': 'dodo',
            'listedIn': [
                'coingecko',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'OSHARE',
            'name': 'OSHARE',
            'address': '0xdcefBd8f92683541e428DD53Cd31356f38d69CaA',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0xdcefBd8f92683541e428DD53Cd31356f38d69CaA/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris'
            ]
        },
        {
            'symbol': 'ROSE',
            'name': 'Rose',
            'address': '0xdcD6D4e2B3e1D1E1E6Fa8C21C8A323DcbecfF970',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://tokens.1inch.io/0xdcd6d4e2b3e1d1e1e6fa8c21c8a323dcbecff970.png',
            'coingeckoId': 'rose',
            'listedIn': [
                'coingecko',
                '1inch',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'agEUR',
            'name': 'agEUR',
            'address': '0xdc7AcDE9ff18B4D189010a21a44cE51ec874eA7C',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0xdc7AcDE9ff18B4D189010a21a44cE51ec874eA7C/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'MIMATIC',
            'name': 'MAI',
            'address': '0xdFA46478F9e5EA86d57387849598dbFB2e964b02',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://tokens.1inch.io/0xa3fa99a148fa48d14ed51d610c367c61876997f1.png',
            'coingeckoId': 'mimatic',
            'listedIn': [
                'coingecko',
                'RouterProtocol',
                'rubic'
            ]
        },
        {
            'symbol': 'COMP',
            'name': 'Compound',
            'address': '0xdEaCF0faa2B80aF41470003b5f6Cd113d47B4Dcd',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0xdEaCF0faa2B80aF41470003b5f6Cd113d47B4Dcd/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'SNX',
            'name': 'Synthetix Network Token',
            'address': '0xdC9bE1FF012D3c6dA818D136a3b2E5FDD4442F74',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0xdC9bE1FF012D3c6dA818D136a3b2E5FDD4442F74/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'rMC',
            'name': 'rMutantCoin',
            'address': '0xd9a4C034e69e5162AC02BEc627475470a53C9a14',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0xd9a4C034e69e5162AC02BEc627475470a53C9a14/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris'
            ]
        },
        {
            'symbol': 'SYN',
            'name': 'Synapse',
            'address': '0xd80d8688b02B3FD3afb81cDb124F188BB5aD0445',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0xd80d8688b02B3FD3afb81cDb124F188BB5aD0445/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'USDT (Wormhole)',
            'address': '0xd80890AFDBd7148456D8Ee358eF9127F0F8c7faf',
            'decimals': 6,
            'chainId': 1313161554,
            'logoURI': 'https://api.rubic.exchange/assets/aurora/0xd80890afdbd7148456d8ee358ef9127f0f8c7faf/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MNFT',
            'name': 'MANUFACTORY',
            'address': '0xd126B48c072f4668e944A8895bC74044D5f2E85b',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://tokens.1inch.io/0xd126b48c072f4668e944a8895bc74044d5f2e85b.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'FXS',
            'name': 'Frax Share',
            'address': '0xc8fdD32E0bf33F0396a18209188bb8C6Fb8747d2',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0xc8fdD32E0bf33F0396a18209188bb8C6Fb8747d2/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'GBA',
            'name': 'Golden Banana',
            'address': '0xc2ac78FFdDf39e5cD6D83bbD70c1D67517C467eF',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0xc2ac78FFdDf39e5cD6D83bbD70c1D67517C467eF/logo.png',
            'coingeckoId': 'golden-banana',
            'listedIn': [
                'coingecko',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'PACHA',
            'name': 'PachaVerse DAO',
            'address': '0xc2Aa4b56E325266e32582F5F5CEcE7E88f0C11D2',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0xc2Aa4b56E325266e32582F5F5CEcE7E88f0C11D2/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris'
            ]
        },
        {
            'symbol': 'META',
            'name': 'Meta Pool',
            'address': '0xc21Ff01229e982d7c8b8691163B0A3Cb8F357453',
            'decimals': 24,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0xc21Ff01229e982d7c8b8691163B0A3Cb8F357453/logo.svg',
            'coingeckoId': 'meta-pool',
            'listedIn': [
                'coingecko',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'BAL',
            'name': 'Balancer',
            'address': '0xb59d0FDAf498182Ff19C4E80C00ECfC4470926e2',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0xb59d0FDAf498182Ff19C4E80C00ECfC4470926e2/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'NEARX',
            'name': 'Stader NearX',
            'address': '0xb39EEB9E168eF6c639f5e282FEf1F6bC4Dcae375',
            'decimals': 24,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0xb39EEB9E168eF6c639f5e282FEf1F6bC4Dcae375/logo.svg',
            'coingeckoId': 'stader-nearx',
            'listedIn': [
                'coingecko',
                'trisolaris'
            ]
        },
        {
            'symbol': 'abBNB',
            'name': 'BNB - Allbridge',
            'address': '0xb14674C7264eC7d948B904Aab2c0E0F906F6e762',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0xb14674c7264ec7d948b904aab2c0e0f906f6e762/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris'
            ]
        },
        {
            'symbol': 'BINARIS',
            'name': 'BINARIS',
            'address': '0xafE0d6ca6AAbB43CDA024895D203120831Ba0370',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0xafE0d6ca6AAbB43CDA024895D203120831Ba0370/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris'
            ]
        },
        {
            'symbol': 'auUSDT',
            'name': 'auUSDT',
            'address': '0xaD5A2437Ff55ed7A8Cad3b797b3eC7c5a19B1c54',
            'decimals': 8,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0xaD5A2437Ff55ed7A8Cad3b797b3eC7c5a19B1c54/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris'
            ]
        },
        {
            'symbol': 'USP',
            'name': 'USP',
            'address': '0xa69d9Ba086D41425f35988613c156Db9a88a1A96',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0xa69d9Ba086D41425f35988613c156Db9a88a1A96/logo.svg',
            'coingeckoId': 'usp',
            'listedIn': [
                'coingecko',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'MECHA',
            'name': 'Mecha',
            'address': '0xa33C3B53694419824722C10D99ad7cB16Ea62754',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0xa33C3B53694419824722C10D99ad7cB16Ea62754/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'TRI',
            'name': 'Trisolaris',
            'address': '0xFa94348467f64D5A457F75F8bc40495D33c65aBB',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://tokens.1inch.io/0xfa94348467f64d5a457f75f8bc40495d33c65abb.png',
            'coingeckoId': 'trisolaris',
            'listedIn': [
                'coingecko',
                '1inch',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'DLTA',
            'name': 'delta.theta',
            'address': '0xFBD1D8dcE2F97BC14239fd507183b98Ff1354B39',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0xFBD1D8dcE2F97BC14239fd507183b98Ff1354B39/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris'
            ]
        },
        {
            'symbol': 'WBTC',
            'name': 'Wrapped Bitcoin',
            'address': '0xF4eB217Ba2454613b15dBdea6e5f22276410e89e',
            'decimals': 8,
            'chainId': 1313161554,
            'logoURI': 'https://tokens.1inch.io/0xf4eb217ba2454613b15dbdea6e5f22276410e89e.png',
            'coingeckoId': 'wrapped-bitcoin',
            'listedIn': [
                'coingecko',
                '1inch',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'WSTR',
            'name': 'WrappedStar',
            'address': '0xF34d508BaC379825255cc80F66CBc89DFeFF92E4',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0xF34d508BaC379825255cc80F66CBc89DFeFF92E4/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'EMPYR',
            'name': 'Empyrean',
            'address': '0xE9F226a228Eb58d408FdB94c3ED5A18AF6968fE1',
            'decimals': 9,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0xE9F226a228Eb58d408FdB94c3ED5A18AF6968fE1/logo.svg',
            'coingeckoId': 'empyrean',
            'listedIn': [
                'coingecko',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'KSW',
            'name': 'KillSwitch',
            'address': '0xE4eB03598f4DCAB740331fa432f4b85FF58AA97E',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0xE4eB03598f4DCAB740331fa432f4b85FF58AA97E/logo.svg',
            'coingeckoId': 'killswitch',
            'listedIn': [
                'coingecko',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'FRAX',
            'name': 'Frax',
            'address': '0xE4B9e004389d91e4134a28F19BD833cBA1d994B6',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0xE4B9e004389d91e4134a28F19BD833cBA1d994B6/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USD Coin (Wormhole)',
            'address': '0xDd1DaFedeBa5F9851C4F4a2876E0f3aF3c774B1A',
            'decimals': 6,
            'chainId': 1313161554,
            'logoURI': 'https://api.rubic.exchange/assets/aurora/0xdd1dafedeba5f9851c4f4a2876e0f3af3c774b1a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FRAX',
            'name': 'Frax',
            'address': '0xDA2585430fEf327aD8ee44Af8F1f989a2A91A3d2',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://tokens.1inch.io/0xda2585430fef327ad8ee44af8f1f989a2a91a3d2.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'FAME',
            'name': 'FAME',
            'address': '0xD5c997724e4b5756d08E6464C01AFbC5F6397236',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0xD5c997724e4b5756d08E6464C01AFbC5F6397236/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'ORA',
            'name': 'ORA',
            'address': '0xCda8e67F3457Db7c8A62F7EaAdbaB74bfd29BeC8',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0xCda8e67F3457Db7c8A62F7EaAdbaB74bfd29BeC8/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris'
            ]
        },
        {
            'symbol': 'WETH',
            'name': 'WETH',
            'address': '0xC9BdeEd33CD01541e1eeD10f90519d2C06Fe3feB',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://tokens.1inch.io/0xc9bdeed33cd01541e1eed10f90519d2c06fe3feb.png',
            'coingeckoId': 'weth',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic'
            ]
        },
        {
            'symbol': 'NDOL',
            'name': 'Necc Dollars',
            'address': '0xC86Ca2BB9C9c9c9F140d832dE00BfA9e153FA1e3',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0xC86Ca2BB9C9c9c9F140d832dE00BfA9e153FA1e3/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'LUNC',
            'name': 'Wrapped Terra Classic',
            'address': '0xC4bdd27c33ec7daa6fcfd8532ddB524Bf4038096',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0xC4bdd27c33ec7daa6fcfd8532ddB524Bf4038096/logo.svg',
            'coingeckoId': 'wrapped-terra',
            'listedIn': [
                'coingecko',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'WNEAR',
            'name': 'Wrapped Near',
            'address': '0xC42C30aC6Cc15faC9bD938618BcaA1a1FaE8501d',
            'decimals': 24,
            'chainId': 1313161554,
            'logoURI': 'https://tokens.1inch.io/0xc42c30ac6cc15fac9bd938618bcaa1a1fae8501d.png',
            'coingeckoId': 'wrapped-near',
            'listedIn': [
                'coingecko',
                '1inch',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'ANGLE',
            'name': 'ANGLE',
            'address': '0xB7e3617aDB58dc34068522bD20Cfe1660780B750',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0xB7e3617aDB58dc34068522bD20Cfe1660780B750/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'AVRIT',
            'name': 'Avrit Learning',
            'address': '0xB4530AA877D25E51c18677753ACd80fFa54009b2',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0xB4530AA877D25E51c18677753ACd80fFa54009b2/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USD Coin',
            'address': '0xB12BFcA5A55806AaF64E99521918A4bf0fC40802',
            'decimals': 6,
            'chainId': 1313161554,
            'logoURI': 'https://tokens.1inch.io/0xb12bfca5a55806aaf64e99521918a4bf0fc40802.png',
            'coingeckoId': 'usd-coin',
            'listedIn': [
                'coingecko',
                '1inch',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'CREAM',
            'name': 'Cream Finance',
            'address': '0xAbe9818c5Fb5e751C4310BE6f0F18c8D85f9Bd7F',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0xAbe9818c5Fb5e751C4310BE6f0F18c8D85f9Bd7F/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'YFI',
            'name': 'yearn.finance',
            'address': '0xA64514A8Af3Ff7366ad3d5Daa5A548EEFceF85e0',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0xA64514A8Af3Ff7366ad3d5Daa5A548EEFceF85e0/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'BSTN',
            'name': 'Bastion Protocol',
            'address': '0x9f1F933C660a1DC856F0E0Fe058435879c5CCEf0',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://tokens.1inch.io/0x9f1f933c660a1dc856f0e0fe058435879c5ccef0.png',
            'coingeckoId': 'bastion-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'SPOLAR',
            'name': 'Polar Shares',
            'address': '0x9D6fc90b25976E40adaD5A3EdD08af9ed7a21729',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x9D6fc90b25976E40adaD5A3EdD08af9ed7a21729/logo.svg',
            'coingeckoId': 'polar-shares',
            'listedIn': [
                'coingecko',
                'trisolaris'
            ]
        },
        {
            'symbol': 'WOO',
            'name': 'Wootrade Network',
            'address': '0x99ec8F13b2AFeF5eC49073B9D20df109D25F78c0',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x99ec8F13b2AFeF5eC49073B9D20df109D25F78c0/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris'
            ]
        },
        {
            'symbol': 'UMINT',
            'name': 'YouMinter',
            'address': '0x984c2505A14DA732D7271416356f535953610340',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x984c2505A14DA732D7271416356f535953610340/logo.svg',
            'coingeckoId': 'youminter',
            'listedIn': [
                'coingecko',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'OCT',
            'name': 'Octopus Network Token',
            'address': '0x951cfdC9544b726872A8faF56792ef6704731aAe',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x951cfdC9544b726872A8faF56792ef6704731aAe/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'HAPI',
            'name': 'HAPI',
            'address': '0x943F4Bf75D5854E92140403255a471950aB8a26f',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x943F4Bf75D5854E92140403255a471950aB8a26f/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris'
            ]
        },
        {
            'symbol': 'LINK',
            'name': 'ChainLink Token',
            'address': '0x94190d8EF039C670c6d6B9990142e0CE2A1E3178',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x94190d8EF039C670c6d6B9990142e0CE2A1E3178/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'LINEAR',
            'name': 'LiNEAR Protocol',
            'address': '0x918dBe087040A41b786f0Da83190c293DAe24749',
            'decimals': 24,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x918dBe087040A41b786f0Da83190c293DAe24749/logo.svg',
            'coingeckoId': 'linear-protocol',
            'listedIn': [
                'coingecko',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'NFD',
            'name': 'Feisty Doge NFT',
            'address': '0x90eB16621274fb47A071001fBbF7550948874CB5',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x90eB16621274fb47A071001fBbF7550948874CB5/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'UST',
            'name': 'UST (WormHole)',
            'address': '0x8D07bBb478B84f7E940e97C8e9cF7B3645166b03',
            'decimals': 6,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x8D07bBb478B84f7E940e97C8e9cF7B3645166b03/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'AURORA',
            'name': 'Aurora',
            'address': '0x8BEc47865aDe3B172A928df8f990Bc7f2A3b9f79',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://tokens.1inch.io/0x8bec47865ade3b172a928df8f990bc7f2a3b9f79.png',
            'coingeckoId': 'aurora-near',
            'listedIn': [
                'coingecko',
                '1inch',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'BAKED',
            'name': 'Baked',
            'address': '0x8973c9eC7B79fE880697cDBcA744892682764c37',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x8973c9eC7B79fE880697cDBcA744892682764c37/logo.svg',
            'coingeckoId': 'baked-token',
            'listedIn': [
                'coingecko',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'PAD',
            'name': 'NearPad',
            'address': '0x885f8CF6E45bdd3fdcDc644efdcd0AC93880c781',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://tokens.1inch.io/0x885f8cf6e45bdd3fdcdc644efdcd0ac93880c781.png',
            'coingeckoId': 'nearpad',
            'listedIn': [
                'coingecko',
                '1inch',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'PULSE',
            'name': 'Pulse',
            'address': '0x8828a5047D093f6354E3fe29fFCb2761300DC994',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x8828a5047D093f6354E3fe29fFCb2761300DC994/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'WETH',
            'name': 'Wrapped Ether (Wormhole)',
            'address': '0x811Cc0d762eA72aC72385d93b98a97263AE37E4C',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://api.rubic.exchange/assets/aurora/0x811cc0d762ea72ac72385d93b98a97263ae37e4c/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'AVAX',
            'name': 'Avalanche',
            'address': '0x80A16016cC4A2E6a2CACA8a4a498b1699fF0f844',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x80A16016cC4A2E6a2CACA8a4a498b1699fF0f844/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris'
            ]
        },
        {
            'symbol': 'XTRI',
            'name': 'Tribar',
            'address': '0x802119e4e253D5C19aA06A5d567C5a41596D6803',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x802119e4e253D5C19aA06A5d567C5a41596D6803/logo.svg',
            'coingeckoId': 'tribar',
            'listedIn': [
                'coingecko',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'WANNA',
            'name': 'WannaSwap',
            'address': '0x7faA64Faf54750a2E3eE621166635fEAF406Ab22',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://tokens.1inch.io/0x7faa64faf54750a2e3ee621166635feaf406ab22.png',
            'coingeckoId': 'wannaswap',
            'listedIn': [
                'coingecko',
                '1inch',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'XNL',
            'name': 'Chronicle',
            'address': '0x7cA1C28663b76CFDe424A9494555B94846205585',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x7cA1C28663b76CFDe424A9494555B94846205585/logo.svg',
            'coingeckoId': 'chronicle',
            'listedIn': [
                'coingecko',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': '0XMR',
            'name': '0xMonero',
            'address': '0x7EA2be2df7BA6E54B1A9C70676f668455E329d29',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://assets.coingecko.com/coins/images/11035/thumb/0xmnr.PNG?1587357680',
            'coingeckoId': '0xmonero',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'MFF',
            'name': 'Moonflower Farm',
            'address': '0x78B65477bBa78fc11735801D559C386611d07529',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://api.rubic.exchange/assets/aurora/0x78b65477bba78fc11735801d559c386611d07529/logo.png',
            'coingeckoId': 'moonflower-farm',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SUSHI',
            'name': 'SushiToken',
            'address': '0x7821c773a12485b12a2b5b7BC451c3eB200986b1',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x7821c773a12485b12a2b5b7BC451c3eB200986b1/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'ASHIB',
            'name': 'AuroShib',
            'address': '0x76c384c6b56e859E15e9E46bFA9d077ecC2d57e6',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://api.rubic.exchange/assets/aurora/0x76c384c6b56e859e15e9e46bfa9d077ecc2d57e6/logo.png',
            'coingeckoId': 'auroshiba',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MODA',
            'name': 'moda',
            'address': '0x74974575D2f1668C63036D51ff48dbaa68E52408',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://tokens.1inch.io/0x74974575d2f1668c63036d51ff48dbaa68e52408.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'MATIC',
            'name': 'Polygon',
            'address': '0x6aB6d61428fde76768D7b45D8BFeec19c6eF91A8',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x6aB6d61428fde76768D7b45D8BFeec19c6eF91A8/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris'
            ]
        },
        {
            'symbol': 'NECC',
            'name': 'Necc',
            'address': '0x6EBA841F1201fFDDe7DDC2ba995D3308f6C4aEa0',
            'decimals': 9,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x6EBA841F1201fFDDe7DDC2ba995D3308f6C4aEa0/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'SLBZ',
            'name': 'Veelancing Token',
            'address': '0x6D5bfD02b543e7D49f88Fe78628A42Ac815D46f2',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://api.rubic.exchange/assets/aurora/0x6d5bfd02b543e7d49f88fe78628a42ac815d46f2/logo.png',
            'coingeckoId': 'veelancing',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SHITZU',
            'name': 'Shitzu',
            'address': '0x68e401B61eA53889505cc1366710f733A60C2d41',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x68e401B61eA53889505cc1366710f733A60C2d41/logo.png',
            'coingeckoId': 'shitzu',
            'listedIn': [
                'coingecko',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'JUMBO',
            'name': 'Jumbo Exchange',
            'address': '0x6454e4a4891C6B78a5A85304d34558DDa5F3d6D8',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x6454e4a4891C6B78a5A85304d34558DDa5F3d6D8/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'PLRS',
            'name': 'Polaris',
            'address': '0x631edd14bb0b2505c91176fEeDC5f082D27Dd5B8',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://api.rubic.exchange/assets/aurora/0x631edd14bb0b2505c91176feedc5f082d27dd5b8/logo.png',
            'coingeckoId': 'polaris-token',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'TRIPOLAR',
            'name': 'Tripolar',
            'address': '0x60527a2751A827ec0Adf861EfcAcbf111587d748',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x60527a2751A827ec0Adf861EfcAcbf111587d748/logo.png',
            'coingeckoId': 'tripolar',
            'listedIn': [
                'coingecko',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'USTC',
            'name': 'Wrapped USTC',
            'address': '0x5ce9F0B6AFb36135b5ddBF11705cEB65E634A9dC',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://tokens.1inch.io/0x5ce9f0b6afb36135b5ddbf11705ceb65e634a9dc.png',
            'coingeckoId': 'wrapped-ust',
            'listedIn': [
                'coingecko',
                '1inch',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'HAK',
            'name': 'Hakuna Matata',
            'address': '0x5ac53F985ea80c6Af769b9272F35F122201D0F56',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x5ac53F985ea80c6Af769b9272F35F122201D0F56/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'USD TLP',
            'name': 'Trisolaris USDC/USDT',
            'address': '0x5EB99863f7eFE88c447Bc9D52AA800421b1de6c9',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x5EB99863f7eFE88c447Bc9D52AA800421b1de6c9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris'
            ]
        },
        {
            'symbol': 'abBUSD',
            'name': 'BUSD BSC - Allbridge',
            'address': '0x5C92A4A7f59A9484AFD79DbE251AD2380E589783',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x5C92A4A7f59A9484AFD79DbE251AD2380E589783/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'TUSD',
            'name': 'TrueUSD',
            'address': '0x5454bA0a9E3552f7828616D80a9D2D869726e6F5',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x5454bA0a9E3552f7828616D80a9D2D869726e6F5/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris'
            ]
        },
        {
            'symbol': 'USN',
            'name': 'USN',
            'address': '0x5183e1B1091804BC2602586919E6880ac1cf2896',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://tokens.1inch.io/0x5183e1b1091804bc2602586919e6880ac1cf2896.png',
            'coingeckoId': 'usn',
            'listedIn': [
                'coingecko',
                '1inch',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'SOLACE',
            'name': 'SOLACE',
            'address': '0x501acE9c35E60f03A2af4d484f49F9B1EFde9f40',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://tokens.1inch.io/0x501ace9c35e60f03a2af4d484f49f9b1efde9f40.png',
            'coingeckoId': 'solace',
            'listedIn': [
                'coingecko',
                '1inch',
                'trisolaris'
            ]
        },
        {
            'symbol': 'auUSDC',
            'name': 'auUSDC',
            'address': '0x4f0d864b1ABf4B701799a0b30b57A22dFEB5917b',
            'decimals': 8,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x4f0d864b1ABf4B701799a0b30b57A22dFEB5917b/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris'
            ]
        },
        {
            'symbol': 'AAVE',
            'name': 'Aave Token',
            'address': '0x4e834cDCc911605227eedDDb89Fad336AB9dc00a',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x4e834cDCc911605227eedDDb89Fad336AB9dc00a/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'Tether',
            'address': '0x4988a896b1227218e4A686fdE5EabdcAbd91571f',
            'decimals': 6,
            'chainId': 1313161554,
            'logoURI': 'https://tokens.1inch.io/0x4988a896b1227218e4a686fde5eabdcabd91571f.png',
            'coingeckoId': 'tether',
            'listedIn': [
                'coingecko',
                '1inch',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'OBOND',
            'name': 'OBOND',
            'address': '0x453E9619b9d5B7062D0d463750fD125a473c65C7',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x453E9619b9d5B7062D0d463750fD125a473c65C7/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris'
            ]
        },
        {
            'symbol': 'nNECC',
            'name': 'Wrapped Staked Necc',
            'address': '0x449f661c53aE0611a24c2883a910A563A7e42489',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x449f661c53aE0611a24c2883a910A563A7e42489/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'axlUSDC',
            'name': 'Axelar Wrapped USDC',
            'address': '0x4268B8F0B87b6Eae5d897996E6b845ddbD99Adf3',
            'decimals': 6,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x4268B8F0B87b6Eae5d897996E6b845ddbD99Adf3/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris'
            ]
        },
        {
            'symbol': 'BBT',
            'name': 'BlueBit',
            'address': '0x4148d2Ce7816F0AE378d98b40eB3A7211E1fcF0D',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x4148d2Ce7816F0AE378d98b40eB3A7211E1fcF0D/logo.svg',
            'coingeckoId': 'bluebit',
            'listedIn': [
                'coingecko',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'ORBITAL',
            'name': 'Polaris Finance Orbital',
            'address': '0x3AC55eA8D2082fAbda674270cD2367dA96092889',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x3AC55eA8D2082fAbda674270cD2367dA96092889/logo.svg',
            'coingeckoId': 'polaris-finance-orbital',
            'listedIn': [
                'coingecko',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'PAD',
            'name': 'SmartPad',
            'address': '0x34F291934b88c7870B7A17835B926B264fc13a81',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x34F291934b88c7870B7A17835B926B264fc13a81/logo.svg',
            'coingeckoId': 'smartpad-2',
            'listedIn': [
                'coingecko',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'GTFX',
            'name': 'Gitshock Finance',
            'address': '0x306e974624511E3937F37E551C5736F1b2aD21eb',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://assets.coingecko.com/coins/images/28033/thumb/Logo_Gitshock_HD.png?1667117754',
            'coingeckoId': 'gitshock-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BNB',
            'name': 'Binance Coin',
            'address': '0x2bF9b864cdc97b08B6D79ad4663e71B8aB65c45c',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x2bF9b864cdc97b08B6D79ad4663e71B8aB65c45c/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'BAT',
            'name': 'Basic Attention Token',
            'address': '0x2b9025aecc5Ce7A8E6880D3E9c6E458927eCba04',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x2b9025aecc5Ce7A8E6880D3E9c6E458927eCba04/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'ATO',
            'name': 'Atocha Protocol',
            'address': '0x2ab98d9eA81aF20037AF1a4f43CC3e6977545840',
            'decimals': 16,
            'chainId': 1313161554,
            'logoURI': 'https://assets.coingecko.com/coins/images/26183/thumb/atocha_icon_1142x1142.png?1656383611',
            'coingeckoId': 'atocha-coin',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'ABR',
            'name': 'Allbridge',
            'address': '0x2BAe00C8BC1868a5F7a216E881Bae9e662630111',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://tokens.1inch.io/0x2bae00c8bc1868a5f7a216e881bae9e662630111.png',
            'coingeckoId': 'allbridge',
            'listedIn': [
                'coingecko',
                '1inch',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'aUSDO',
            'name': 'aUSDO',
            'address': '0x293074789b247cab05357b08052468B5d7A23c5a',
            'decimals': 8,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x293074789b247cab05357b08052468B5d7A23c5a/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris'
            ]
        },
        {
            'symbol': 'PICKLE',
            'name': 'Pickle Finance',
            'address': '0x291C8FCeAcA3342B29CC36171DEB98106f712C66',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://tokens.1inch.io/0x291c8fceaca3342b29cc36171deb98106f712c66.png',
            'coingeckoId': 'pickle-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'LUNAR',
            'name': 'Lunar',
            'address': '0x25e801Eb75859Ba4052C4ac4233ceC0264eaDF8c',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x25e801Eb75859Ba4052C4ac4233ceC0264eaDF8c/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris'
            ]
        },
        {
            'symbol': 'VWAVE',
            'name': 'Vaporwave',
            'address': '0x2451dB68DeD81900C4F16ae1af597E9658689734',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x2451dB68DeD81900C4F16ae1af597E9658689734/logo.svg',
            'coingeckoId': 'vaporwave',
            'listedIn': [
                'coingecko',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'REF',
            'name': 'Ref Finance Token',
            'address': '0x221292443758F63563a1ed04b547278B05845FCb',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x221292443758F63563a1ed04b547278B05845FCb/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris'
            ]
        },
        {
            'symbol': 'BIFI',
            'name': 'Beefy Finance',
            'address': '0x218c3c3D49d0E7B37aff0D8bB079de36Ae61A4c0',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://assets.coingecko.com/coins/images/12704/thumb/token.png?1601876182',
            'coingeckoId': 'beefy-finance',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'MKR',
            'name': 'Maker',
            'address': '0x1d1f82D8B8fc72f29A8c268285347563CB6cD8B3',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x1d1f82D8B8fc72f29A8c268285347563CB6cD8B3/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'UNI',
            'name': 'Uniswap',
            'address': '0x1Bc741235EC0Ee86ad488fa49B69Bb6C823eE7b7',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x1Bc741235EC0Ee86ad488fa49B69Bb6C823eE7b7/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'RUSD',
            'name': 'Rose USD',
            'address': '0x19cc40283B057D6608C22F1D20F17e16C245642E',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x19cc40283B057D6608C22F1D20F17e16C245642E/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris'
            ]
        },
        {
            'symbol': 'REN',
            'name': 'Republic',
            'address': '0x18921F1E257038E538bA24D49fa6495c8b1617bC',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x18921F1E257038E538bA24D49fa6495c8b1617bC/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'PEA',
            'name': 'PinkPea Finance',
            'address': '0x184F2C422dc49d0DF6977e6079c73F731B7Bf94e',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://assets.coingecko.com/coins/images/25840/thumb/pea-big.1f28f16.png?1654077801',
            'coingeckoId': 'pinkpea-finance',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'ETHERNAL',
            'name': 'Ethernal',
            'address': '0x17cbd9C274e90C537790C51b4015a65cD015497e',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x17cbd9C274e90C537790C51b4015a65cD015497e/logo.svg',
            'coingeckoId': 'ethernal',
            'listedIn': [
                'coingecko',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'VRA',
            'name': 'Virtual Reality Asset',
            'address': '0x17bC24b9bDD8A3E7486E3C3458a5954412F2ff60',
            'decimals': 4,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x17bC24b9bDD8A3E7486E3C3458a5954412F2ff60/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris'
            ]
        },
        {
            'symbol': 'BIVE',
            'name': 'Bizverse',
            'address': '0x130E6203F05805cd8C44093a53C7b50775eb4ca3',
            'decimals': 4,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x130E6203F05805cd8C44093a53C7b50775eb4ca3/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris'
            ]
        },
        {
            'symbol': 'BRL',
            'name': 'Borealis',
            'address': '0x12c87331f086c3C926248f964f8702C0842Fd77F',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://assets.coingecko.com/coins/images/22089/thumb/auroraswap.png?1640774528',
            'coingeckoId': 'borealis',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUNA',
            'name': 'LUNA (Wormhole)',
            'address': '0x12302fbE05a7e833f87d4B7843F58d19BE4FdE3B',
            'decimals': 6,
            'chainId': 1313161554,
            'logoURI': 'https://api.rubic.exchange/assets/aurora/0x12302fbe05a7e833f87d4b7843f58d19be4fde3b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PAD',
            'name': 'smartpad token',
            'address': '0x0fAD0ED848A7A16526E8a7574e418B015Dbf41B5',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://api.rubic.exchange/assets/aurora/0x0fad0ed848a7a16526e8a7574e418b015dbf41b5/logo.png',
            'coingeckoId': 'smartpad',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SOL',
            'name': 'SOL - Allbridge',
            'address': '0x0f00576d07B594Bdc1caf44b6014A6A02E5AFd48',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x0f00576d07B594Bdc1caf44b6014A6A02E5AFd48/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'PLY',
            'name': 'Aurigami',
            'address': '0x09C9D464b58d96837f8d8b6f4d9fE4aD408d3A4f',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://tokens.1inch.io/0x09c9d464b58d96837f8d8b6f4d9fe4ad408d3a4f.png',
            'coingeckoId': 'aurigami',
            'listedIn': [
                'coingecko',
                '1inch',
                'trisolaris'
            ]
        },
        {
            'symbol': 'UST',
            'name': 'wrapped ust token',
            'address': '0x098D5b6a26BCA1d71f2335805d71b244f94E8A5f',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://api.rubic.exchange/assets/aurora/0x098d5b6a26bca1d71f2335805d71b244f94e8a5f/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'OIN',
            'name': 'oinfinance',
            'address': '0x07b2055fBD17b601C780aEb3abF4C2B3a30c7aae',
            'decimals': 8,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x07b2055fBD17b601C780aEb3abF4C2B3a30c7aae/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'STNEAR',
            'name': 'Staked NEAR',
            'address': '0x07F9F7f963C5cD2BBFFd30CcfB964Be114332E30',
            'decimals': 24,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x07F9F7f963C5cD2BBFFd30CcfB964Be114332E30/logo.svg',
            'coingeckoId': 'staked-near',
            'listedIn': [
                'coingecko',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'SD',
            'name': 'Stader',
            'address': '0x078E103d5F9629aC48B8563Fa2BFcD2f6d5Fde84',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x078E103d5F9629aC48B8563Fa2BFcD2f6d5Fde84/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris'
            ]
        },
        {
            'symbol': 'nUSD',
            'name': 'nUSD',
            'address': '0x07379565cD8B0CaE7c60Dc78e7f601b34AF2A21c',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x07379565cD8B0CaE7c60Dc78e7f601b34AF2A21c/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris'
            ]
        },
        {
            'symbol': 'CRF',
            'name': 'Crafting Finance',
            'address': '0x026ddA7F0F0A2E42163C9c80D2a5b6958E35fc49',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://tokens.1inch.io/0x026dda7f0f0a2e42163c9c80d2a5b6958e35fc49.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'trisolaris',
                'rubic'
            ]
        },
        {
            'symbol': 'BRRR',
            'name': 'BRRR',
            'address': '0x0240aE04c9F47B91Cf47Ca2E7ef44c9De0D385Ac',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://raw.githubusercontent.com/trisolaris-labs/tokens/master/assets/0x0240aE04c9F47B91Cf47Ca2E7ef44c9De0D385Ac/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'trisolaris'
            ]
        },
        {
            'symbol': 'IZA',
            'name': 'AmaterasuFi Izanagi',
            'address': '0x0017Be3E7e36ABF49FE67a78D08bf465bB755120',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://assets.coingecko.com/coins/images/25859/thumb/spaces_ebXpMFLzH2sowWKTINL3_uploads_git-blob-99be8625d129c1d92248059e04dffebe380e34b7_Izanagi_light_blue_-_transparent_%282%29.png?1654214255',
            'coingeckoId': 'amaterasufi-izanagi',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'ETH',
            'name': 'Ethereum',
            'address': '0x0000000000000000000000000000000000000000',
            'decimals': 18,
            'chainId': 1313161554,
            'logoURI': 'https://tokens.1inch.io/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee.png',
            'coingeckoId': 'ethereum',
            'listedIn': [
                '1inch',
                'rubic'
            ]
        }
    ],
    '1666600000': [
        {
            'symbol': 'USDS',
            'name': 'Stably USDS',
            'address': 'one1egzwqa9pys2easrxaa28xgll3yxu83gmcnf08f',
            'decimals': 6,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/7596/thumb/Stably_USDS_-_Icon_%28Standard%29.png?1663824111',
            'coingeckoId': 'stableusd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AVAX',
            'name': 'Avalanche',
            'address': 'one14tvk6p8spdcch8k58cuahrnnmesua79he3xzld',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/12559/thumb/coin-round-red.png?1604021818',
            'coingeckoId': 'avalanche-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ROY',
            'name': 'Crypto Royale',
            'address': '0xfe1b516A7297eb03229A8B5AfAD80703911E81cB',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/20668/thumb/ROY_logo_new_design_small.png?1637547627',
            'coingeckoId': 'crypto-royale',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'RAVE',
            'name': 'RAVE',
            'address': '0xf9F0724cEB8671390bc422003628cB230d513702',
            'decimals': 9,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0xf9f0724ceb8671390bc422003628cb230d513702/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'YLDY',
            'name': 'Yieldly',
            'address': '0xf498a433819a5DB3DFCc110100527CF4bFcAFe47',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/16757/thumb/YIELDLY_TOKEN_256x256px.png?1625732457',
            'coingeckoId': 'yieldly',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANKR',
            'name': 'Ankr',
            'address': '0xf307910A4c7bbc79691fD374889b36d8531B08e3',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0xf307910a4c7bbc79691fd374889b36d8531b08e3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'HEX',
            'name': 'HEX',
            'address': '0xf26D8c787e66254eE8B7A500073da8fb1ab1992D',
            'decimals': 8,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/10103/thumb/HEX-logo.png?1575942673',
            'coingeckoId': 'hex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LAZR',
            'name': 'LAZR',
            'address': '0xed2A4E1577F7C90d84915250278B685bD6e498b3',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0xed2a4e1577f7c90d84915250278b685bd6e498b3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ELK',
            'name': 'Elk',
            'address': '0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'BUSD',
            'name': 'BUSD Token',
            'address': '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': '1FRAX',
            'name': 'Frax',
            'address': '0xeB6C08ccB4421b6088e581ce04fcFBed15893aC3',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0xeb6c08ccb4421b6088e581ce04fcfbed15893ac3/logo.png',
            'coingeckoId': 'frax',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BUSD',
            'name': 'BUSD Token',
            'address': '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0xe9e7cea3dedca5984780bafc599bd69add087d56/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DRC',
            'name': 'Digital Reserve Currency',
            'address': '0xe74A0BA232a62Ddb80e53EA7ed9B799445F52876',
            'decimals': 0,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/12802/thumb/DRC_Logo.jpg?1654229818',
            'coingeckoId': 'digital-reserve-currency',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TM',
            'name': 'Templar Money',
            'address': '0xe5CF1558A1470cb5C166c2e8651eD0F3c5fb8F42',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AWETH',
            'name': 'Aave WETH',
            'address': '0xe50fA9b3c56FfB159cB0FCA61F5c9D750e8128c8',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/17238/thumb/aWETH_2x.png?1626940782',
            'coingeckoId': 'aave-weth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '1BTC',
            'name': '1BTC',
            'address': '0xdc54046c0451f9269FEe1840aeC808D36015697d',
            'decimals': 8,
            'chainId': 1666600000,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/harmony/assets/0xdc54046c0451f9269FEe1840aeC808D36015697d/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'TEM',
            'name': 'Templar DAO',
            'address': '0xd754ae7bb55fEB0c4BA6bC037b4A140f14ebE018',
            'decimals': 9,
            'chainId': 1666600000,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/harmony/assets/0xd754ae7bb55fEB0c4BA6bC037b4A140f14ebE018/logo.png',
            'coingeckoId': 'templardao',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'bscATOM',
            'name': 'Cosmos Token',
            'address': '0xd6bAd903e550822d51073AFb79581BF5aAE9243F',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0xd6bAd903e550822d51073AFb79581BF5aAE9243F/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ETH',
            'name': 'Ethereum Token',
            'address': '0xd66c6B4F0be8CE5b39D52E0Fd1344c389929B378',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0xd66c6b4f0be8ce5b39d52e0fd1344c389929b378/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'CEL',
            'name': 'Celsius Network',
            'address': '0xd562c88E0f8E7dAe43076018Bb1ea3115617984D',
            'decimals': 4,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/3263/thumb/CEL_logo.png?1609598753',
            'coingeckoId': 'celsius-degree-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CREAM',
            'name': 'Cream',
            'address': '0xd4CB328A82bDf5f03eB737f37Fa6B370aef3e888',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0xd4cb328a82bdf5f03eb737f37fa6b370aef3e888/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'APE',
            'name': 'HarmonApe',
            'address': '0xd3a50c0dCE15C12fe64941FFd2B864e887c9B9E1',
            'decimals': 9,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0xd3a50c0dce15c12fe64941ffd2b864e887c9b9e1/logo.png',
            'coingeckoId': 'harmon-ape',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'USHARE',
            'name': 'USHARE',
            'address': '0xd0105CFF72A89f6Ff0BD47e1209bf4Bdfb9deA8A',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/22031/thumb/UShare.png?1640658403',
            'coingeckoId': 'ushare',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TVK',
            'name': 'The Virtua Kolect',
            'address': '0xced019c13AeC9dC2C641FC3Bb0B24C7231f0fFF1',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/13330/thumb/virtua_original.png?1656043619',
            'coingeckoId': 'the-virtua-kolect',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XVS',
            'name': 'Venus',
            'address': '0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'WONE',
            'name': 'Wrapped One',
            'address': '0xcF664087a5bB0237a0BAd6742852ec6c8d69A27a',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0xcF664087a5bB0237a0BAd6742852ec6c8d69A27a/logo.png',
            'coingeckoId': 'wrapped-one',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'RouterProtocol',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'AAVE',
            'name': 'Aave',
            'address': '0xcF323Aad9E522B93F11c352CaA519Ad0E14eB40F',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0xcF323Aad9E522B93F11c352CaA519Ad0E14eB40F/logo.png',
            'coingeckoId': 'aave',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MNTO',
            'name': 'Minato',
            'address': '0xcAD5937a790541bA912542d81fcF36d75fBf7DD7',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/24622/thumb/MNTO_200x200.png?1648448664',
            'coingeckoId': 'minato',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KNIGHT',
            'name': 'Knights   Peasants',
            'address': '0xc5843b3Dd9b912fd5958e5b1d1f27189eF7189Da',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/24836/thumb/TokenTR.png?1655886633',
            'coingeckoId': 'knights-peasants',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BELUGA',
            'name': 'BelugaToken',
            'address': '0xc46fedc533E9f14c99C4c5124e2085701fa9B255',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ONEUSD',
            'name': 'ONEUSD',
            'address': '0xc1873199284B4490A4AACbE7CD6710Dc6aA95e08',
            'decimals': 8,
            'chainId': 1666600000,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/harmony/assets/0xc1873199284B4490A4AACbE7CD6710Dc6aA95e08/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'GREEN',
            'name': 'Greenhouse',
            'address': '0xbfC044a234E45412eCfeC2Cd6aAe2dd0C083a7cd',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WBNB',
            'name': 'Wrapped BNB',
            'address': '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'USTC',
            'name': 'Wrapped USTC',
            'address': '0xb82307fF75F0CD2cFc253BA2621851fd9123a818',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/15462/thumb/ust.png?1620910058',
            'coingeckoId': 'wrapped-ust',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SMCW',
            'name': 'Space Crown',
            'address': '0xb2ea51BAa12C461327d12A2069d47b30e680b69D',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'bscBNB',
            'name': 'BNB',
            'address': '0xb1f6E61E1e113625593a22fa6aa94F8052bc39E0',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0xb1f6E61E1e113625593a22fa6aa94F8052bc39E0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'AVAX',
            'name': 'Avalanche',
            'address': '0xb12c13e66AdE1F72f71834f2FC5082Db8C091358',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://static.debank.com/image/hmy_token/logo_url/0xb12c13e66ade1f72f71834f2fc5082db8c091358/e195cdd89f44bf3d0c65d38ce2c6c662.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WBNB',
            'name': 'Wrapped BNB',
            'address': '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0xae13d989dac2f0debff460ac112a837c89baa7cd/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ENJ',
            'name': 'Enjin Coin',
            'address': '0xadbd41bFb4389dE499535C14A8a3A12Fead8F66A',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/1102/thumb/enjin-coin-logo.png?1547035078',
            'coingeckoId': 'enjincoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '1xSUSHI',
            'name': 'xSUSHI',
            'address': '0xa7a22299918828D791240f9Ec310c2E066592053',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0xa7a22299918828D791240f9Ec310c2E066592053/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic',
                'elkfinance'
            ]
        },
        {
            'symbol': 'YFI',
            'name': 'yearn finance',
            'address': '0xa0dc05F84A27FcCBD341305839019aB86576bc07',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0xa0dc05F84A27FcCBD341305839019aB86576bc07/logo.png',
            'coingeckoId': 'yearn-finance',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ONT',
            'name': 'Ontology Token',
            'address': '0xFd7B3A77848f1C2D67E05E54d78d174a0C850335',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0xfd7b3a77848f1c2d67e05e54d78d174a0c850335/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ROT',
            'name': 'Rotten',
            'address': '0xFd2a8F8cF7CFFeA4a613F1DFf39b22881D4a1f92',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/12560/thumb/rot_logo.png?1600762626',
            'coingeckoId': 'rotten',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FRAX',
            'name': 'Frax',
            'address': '0xFa7191D292d5633f702B0bd7E3E3BcCC0e633200',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/frax.jpg',
            'coingeckoId': 'frax',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'HVILLE',
            'name': 'Harmonyville',
            'address': '0xF9565E8c4E13862f677F144B3cdC8700D9c4BA31',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/23661/thumb/harmonyville200x200.png?1644990399',
            'coingeckoId': 'harmonyville',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'LINK',
            'name': 'ChainLink Token',
            'address': '0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'VI',
            'name': 'Vybit',
            'address': '0xF3f8089362dD4C0FD56f12B7D34eDBddc7a12D6F',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/10978/thumb/vi.png?1639659576',
            'coingeckoId': 'vybit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'sWAGMI',
            'name': 'Staked WAGMI',
            'address': '0xF38593388079F7f5130d605E38aBF6090D981eC2',
            'decimals': 9,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0xf38593388079f7f5130d605e38abf6090d981ec2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'AMPL',
            'name': 'Ampleforth',
            'address': '0xF2f5BF00cd952f3f980a02f5dce278CbFf4daE05',
            'decimals': 9,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/4708/thumb/Ampleforth.png?1561684250',
            'coingeckoId': 'ampleforth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOGE',
            'name': 'Binance Peg Dogecoin',
            'address': '0xF155E1a57DB0Ca820aE37Ab4050e0e4C7cFcEcd0',
            'decimals': 8,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/15768/thumb/dogecoin.png?1621833687',
            'coingeckoId': 'binance-peg-dogecoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DAI',
            'name': 'Dai',
            'address': '0xEf977d2f931C1978Db5F6747666fa1eACB0d0339',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0xEf977d2f931C1978Db5F6747666fa1eACB0d0339/logo.png',
            'coingeckoId': 'dai',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'VIPER',
            'name': 'Viper',
            'address': '0xEa589E93Ff18b1a1F1e9BaC7EF3E86Ab62addc79',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0xEa589E93Ff18b1a1F1e9BaC7EF3E86Ab62addc79/logo.png',
            'coingeckoId': 'viper',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'RVRS',
            'name': 'Reverse Protocol',
            'address': '0xED0B4b0F0E2c17646682fc98ACe09feB99aF3adE',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/harmony/assets/0xED0B4b0F0E2c17646682fc98ACe09feB99aF3adE/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'DAI',
            'name': 'Dai Token',
            'address': '0xEC5dCb5Dbf4B114C9d0F65BcCAb49EC54F6A0867',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0xec5dcb5dbf4b114c9d0f65bccab49ec54f6a0867/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'LAYER',
            'name': 'UniLayer',
            'address': '0xE88699AD32d5A610987a3BA8519C06289549cCa7',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/12164/thumb/Unilayer.jpg?1597779313',
            'coingeckoId': 'unilayer',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WISE',
            'name': 'Wise',
            'address': '0xE7e3C4D1cFc722b45A428736845B6AfF862842a1',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0xE7e3C4D1cFc722b45A428736845B6AfF862842a1/logo.png',
            'coingeckoId': 'wise-token11',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BOSS',
            'name': 'Boss Swap',
            'address': '0xE7bBE0E193FdFe95d2858F5C46d036065f8F735c',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/22032/thumb/Token_%283%29.png?1640658612',
            'coingeckoId': 'bossswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YIN',
            'name': 'Yin',
            'address': '0xE59AA7f9e91B4Cc6C25D3542CEcb851e0316138c',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0xe59aa7f9e91b4cc6c25d3542cecb851e0316138c/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BTCBR',
            'name': 'Bitcoin BR',
            'address': '0xE57425F1598f9b0d6219706b77f4b3DA573a3695',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/21205/thumb/btcbr.png?1661666691',
            'coingeckoId': 'bitcoin-br',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MARK',
            'name': 'Benchmark Protocol',
            'address': '0xE52c7144fEd0aeCa882dD93Bc76c9135f5598DDD',
            'decimals': 9,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/13212/thumb/benchmark_protocol.jpg?1606267583',
            'coingeckoId': 'benchmark-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'oELK',
            'name': 'Old Elk',
            'address': '0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'BUSD',
            'name': 'Binance USD',
            'address': '0xE176EBE47d621b984a73036B9DA5d834411ef734',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0xE176EBE47d621b984a73036B9DA5d834411ef734/logo.png',
            'coingeckoId': 'binance-usd',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'XVIPER',
            'name': 'ViperPit',
            'address': '0xE064a68994e9380250CfEE3E8C0e2AC5C0924548',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0xE064a68994e9380250CfEE3E8C0e2AC5C0924548/logo.png',
            'coingeckoId': 'viperpit',
            'listedIn': [
                'coingecko',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BAKE',
            'name': 'Bakery Token',
            'address': '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0xe02df9e3e622debdd69fb838bb799e3f168902c5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'CREDIT',
            'name': 'Credit',
            'address': '0xDfb01A88D1e6099B42c9Bae38F8070027143b850',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0xdfb01a88d1e6099b42c9bae38f8070027143b850/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BAL',
            'name': 'Balancer',
            'address': '0xDc5f76104D0B8D2bF2c2Bbe06CDFE17004E9010f',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0xDc5f76104D0B8D2bF2c2Bbe06CDFE17004E9010f/logo.png',
            'coingeckoId': 'balancer',
            'listedIn': [
                'coingecko',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MIS',
            'name': 'Artemis',
            'address': '0xD74433B187Cf0ba998Ad9Be3486B929c76815215',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/18916/thumb/136704691-920ef315-4906-4a82-bca1-144b07dea3fb.png?1633919295',
            'coingeckoId': 'artemis',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRANQ',
            'name': 'Tranquil Finance',
            'address': '0xCf1709Ad76A79d5a60210F23e81cE2460542A836',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/harmony/assets/0xCf1709Ad76A79d5a60210F23e81cE2460542A836/logo.png',
            'coingeckoId': 'tranquil-finance',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BIFI',
            'name': 'beefy.finance',
            'address': '0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0xca3f508b8e4dd382ee878a314789373d80a5190a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'IDRT',
            'name': 'Rupiah',
            'address': '0xCEFbeA899CfcCdc653b171d063481b622086Be3F',
            'decimals': 2,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/9441/thumb/57421944_1371636006308255_3647136573922738176_n.jpg?1567462531',
            'coingeckoId': 'rupiah-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ONEMOON',
            'name': 'OneMoon',
            'address': '0xCB35e4945c7F463c5CCBE3BF9f0389ab9321248F',
            'decimals': 9,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/17702/thumb/ONEMOON_%281%29.jpg?1629077134',
            'coingeckoId': 'onemoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUNNY',
            'name': 'Bunny Token',
            'address': '0xC9849E6fdB743d08fAeE3E34dd2D1bc69EA11a51',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0xc9849e6fdb743d08faee3e34dd2d1bc69ea11a51/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FEG',
            'name': 'FEG BSC',
            'address': '0xC7eaAa7FC41Fa4D814192979f267D80CB48fB760',
            'decimals': 9,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/14533/thumb/F-FEG-avatar-Light-03_4x-400x400-01_%281%29.png?1665915066',
            'coingeckoId': 'feg-token-bsc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KICK',
            'name': 'Kick',
            'address': '0xC63Fd3e9C9527cCdF1d331bbADFE21E77E357B5E',
            'decimals': 8,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/981/thumb/kick.png?1568643013',
            'coingeckoId': 'kick',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWAG',
            'name': 'SWAG Finance',
            'address': '0xC63F45B5F6D63D8c424E408ACFB4B5823955F12A',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/12805/thumb/photo_2020-10-14_23.17.02.jpeg?1602688642',
            'coingeckoId': 'swag-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '1DAI',
            'name': 'OneDAI',
            'address': '0xC27255D7805Fc79e4616d5CD50D6f4464AEa75A3',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0xc27255d7805fc79e4616d5cd50d6f4464aea75a3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'USE',
            'name': 'Useless',
            'address': '0xC079d0385492Ac2D0e89ca079c186Dd71ef49B1e',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/24924/thumb/useless.jpeg?1649388116',
            'coingeckoId': 'useless-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '1MAGGOT',
            'name': 'MaggotToken',
            'address': '0xBfD4F1699b83eDBa1106B6E224b7aC599A40be1F',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0xbfd4f1699b83edba1106b6e224b7ac599a40be1f/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'CHEEZ',
            'name': 'Cheese',
            'address': '0xBbD83eF0c9D347C85e60F1b5D2c58796dBE1bA0d',
            'decimals': 9,
            'chainId': 1666600000,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/harmony/assets/0xBbD83eF0c9D347C85e60F1b5D2c58796dBE1bA0d/logo.png',
            'coingeckoId': 'cheesedao',
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'wsWAGMI',
            'name': 'Wrapped sWAGMI',
            'address': '0xBb948620Fa9cD554eF9A331B13eDeA9B181F9D45',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0xbb948620fa9cd554ef9a331b13edea9b181f9d45/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SUSHI',
            'name': 'Sushi',
            'address': '0xBEC775Cb42AbFa4288dE81F387a9b1A3c4Bc552A',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0xBEC775Cb42AbFa4288dE81F387a9b1A3c4Bc552A/logo.png',
            'coingeckoId': 'sushi',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'LPT',
            'name': 'Livepeer',
            'address': '0xBD3E698b51D340Cc53B0CC549b598c13e0172B7c',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/7137/thumb/logo-circle-green.png?1619593365',
            'coingeckoId': 'livepeer',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HT',
            'name': 'Huobi',
            'address': '0xBAA0974354680B0e8146d64bB27Fb92C03C4A2f2',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/2822/thumb/huobi-token-logo.png?1547036992',
            'coingeckoId': 'huobi-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STAR',
            'name': 'Speed Star STAR',
            'address': '0xB914e7A183AbCD46300584DA828f62A39516F33b',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/24982/thumb/f2zOnAa4_400x400.jpg?1649667541',
            'coingeckoId': 'speed-star-star',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VINCI',
            'name': 'DaVinci',
            'address': '0xB8E0497018c991E86311b64EFd9D57b06aEDbBAE',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0xB8E0497018c991E86311b64EFd9D57b06aEDbBAE/logo.png',
            'coingeckoId': 'davinci-token',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'EDEN',
            'name': 'ParadiseFi',
            'address': '0xB55106308974CEbe299A0f0505435C47b404b9a6',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/19404/thumb/paradisefi.PNG?1635199656',
            'coingeckoId': 'paradisefi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNITE',
            'name': 'Unite',
            'address': '0xB4441013EA8aA3a9E35c5ACa2B037e577948C59e',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/22034/thumb/Unite.png?1640659159',
            'coingeckoId': 'unite',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AAG',
            'name': 'AAG',
            'address': '0xAE0609A062a4eAED49dE28C5f6A193261E0150eA',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/21382/thumb/aag-token-icon.png?1661849633',
            'coingeckoId': 'aag-ventures',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAND',
            'name': 'Band Protocol Token',
            'address': '0xAD6cAEb32CD2c308980a548bD0Bc5AA4306c6c18',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0xad6caeb32cd2c308980a548bd0bc5aa4306c6c18/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'EPS',
            'name': 'Ellipsis',
            'address': '0xA7f552078dcC247C2684336020c03648500C6d9F',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0xa7f552078dcc247c2684336020c03648500c6d9f/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ABYSS',
            'name': 'Abyss',
            'address': '0xA52D0c7943cc1020A926B23dd1c64Fc60b4fadDe',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/2105/thumb/NrFmpxs.png?1600318377',
            'coingeckoId': 'the-abyss',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XYA',
            'name': 'GameFi Crossing',
            'address': '0x9b68BF4bF89c115c721105eaf6BD5164aFcc51E4',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/17017/thumb/logo_gfx.png?1645602237',
            'coingeckoId': 'freyala',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REEF',
            'name': 'Reef',
            'address': '0x9aB0DB833557d95AFf98C09B560145Ad34E681b8',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/13504/thumb/Group_10572.png?1610534130',
            'coingeckoId': 'reef',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '1MIM',
            'name': 'OneMIM',
            'address': '0x9F952fc002D16d672f49387A83Aeb968A276d6F1',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x9f952fc002d16d672f49387a83aeb968a276d6f1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'bscUSDT',
            'name': 'Tether USD Token',
            'address': '0x9A89d0e1b051640C6704Dde4dF881f73ADFEf39a',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0x9A89d0e1b051640C6704Dde4dF881f73ADFEf39a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic',
                'elkfinance'
            ]
        },
        {
            'symbol': 'xVIPER',
            'name': 'xVIPER',
            'address': '0x98b792BD73E10CF96b9f7b3A34baCdCe06349e3b',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x98b792bd73e10cf96b9f7b3a34bacdce06349e3b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USD Coin',
            'address': '0x985458E523dB3d53125813eD68c274899e9DfAb4',
            'decimals': 6,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0x985458E523dB3d53125813eD68c274899e9DfAb4/logo.png',
            'coingeckoId': 'usd-coin',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FUZZ',
            'name': 'Fuzz Finance',
            'address': '0x984b969a8E82F5cE1121CeB03f96fF5bB3f71FEe',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/18395/thumb/fuzz.PNG?1631764255',
            'coingeckoId': 'fuzz-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RFUST',
            'name': 'rfUST',
            'address': '0x971803c316d80eE490156aF78e8eb5067EDB73AC',
            'decimals': 9,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/22190/thumb/XTOotuku_400x400.jpg?1641188829',
            'coingeckoId': 'rfust',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DFKGREGG',
            'name': 'Grey Pet Egg',
            'address': '0x95d02C1Dc58F05A015275eB49E107137D9Ee81Dc',
            'decimals': 0,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/27551/thumb/pet-egg-grey.png?1664443845',
            'coingeckoId': 'grey-pet-egg',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUNA',
            'name': 'Wrapped LUNA Token',
            'address': '0x95CE547D730519A90dEF30d647F37D9E5359B6Ae',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0x95CE547D730519A90dEF30d647F37D9E5359B6Ae/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SRK',
            'name': 'SparkPoint',
            'address': '0x9500A1FbEF7014dbD384633fD20bb1e6916d6Fca',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/8371/thumb/SRK.png?1614675526',
            'coingeckoId': 'sparkpoint',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ODAO',
            'name': 'ODAO',
            'address': '0x947394294F75D7502977AC6813FD99f77C2931ec',
            'decimals': 9,
            'chainId': 1666600000,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/harmony/assets/0x947394294F75D7502977AC6813FD99f77C2931ec/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'LINA',
            'name': 'Linear',
            'address': '0x946c8286bd9B52B81F681903210E1a57872fdD85',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/12509/thumb/1649227343-linalogo200px.png?1649229117',
            'coingeckoId': 'linear',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNI',
            'name': 'Uniswap',
            'address': '0x90D81749da8867962c760414C1C25ec926E889b6',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0x90D81749da8867962c760414C1C25ec926E889b6/logo.png',
            'coingeckoId': 'uniswap',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BCH',
            'name': 'Bitcoin Cash Token',
            'address': '0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x8ff795a6f4d97e7887c79bea79aba5cc76444adf/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'GST2',
            'name': 'GasToken',
            'address': '0x8e34D1351ef8DB0AcAC008761075F33D072Cd753',
            'decimals': 2,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/10779/thumb/gas.png?1583466756',
            'coingeckoId': 'gastoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALPACA',
            'name': 'Alpaca',
            'address': '0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x8f0528ce5ef7b51152a59745befdd91d97091d2f/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'HLY',
            'name': 'HolyGrail',
            'address': '0x8D760497554eecC3B9036fb0364156ef2F0D02BC',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/23416/thumb/holygrail.PNG?1644188214',
            'coingeckoId': 'holygrail',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USDC Token',
            'address': '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'LYXE',
            'name': 'LUKSO',
            'address': '0x8A8ca151562a68ED3732Fd963Ec4e0E713B39BB3',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/11423/thumb/1_QAHTciwVhD7SqVmfRW70Pw.png?1590110612',
            'coingeckoId': 'lukso-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAGIC',
            'name': 'Magic',
            'address': '0x892D81221484F690C0a97d3DD18B9144A3ECDFB7',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/harmony/assets/0x892D81221484F690C0a97d3DD18B9144A3ECDFB7/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'EUSK',
            'name': 'Mintbes Token',
            'address': '0x85a1DD919cd605aa2EAD4b01ff1190504BcAb609',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x85a1dd919cd605aa2ead4b01ff1190504bcab609/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'IRIS',
            'name': 'Iris',
            'address': '0x85FD5f8dBD0c9Ef1806E6c7d4B787d438621C1dC',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/18361/thumb/hermes.PNG?1631609306',
            'coingeckoId': 'iris-token-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHAIN',
            'name': 'Chain Games',
            'address': '0x84Ec08c887DD8c14D389abe56e609379B7C5262E',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/12257/thumb/chain-logo-centered-500x500.png?1599617244',
            'coingeckoId': 'chain-games',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADAI',
            'name': 'Aave DAI',
            'address': '0x82E64f49Ed5EC1bC6e43DAD4FC8Af9bb3A2312EE',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/14242/thumb/aDAI.84b6c41f.png?1615528749',
            'coingeckoId': 'aave-dai',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZRX',
            'name': '0x',
            'address': '0x8143E2A1085939cAA9cEf6665c2Ff32f7bc08435',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/863/thumb/0x.png?1547034672',
            'coingeckoId': '0x',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LMA',
            'name': 'LMA-Art-Gallery',
            'address': '0x7d0546dBb1Dca8108d99Aa389A8e9Ce0C40B2370',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://swoop-exchange.s3-us-west-1.amazonaws.com/tokens/LMA.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'SNX',
            'name': 'Synthetix Network',
            'address': '0x7b9c523d59AeFd362247Bd5601A89722e3774dD2',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0x7b9c523d59AeFd362247Bd5601A89722e3774dD2/logo.png',
            'coingeckoId': 'havven',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'XIM',
            'name': 'xDollar Interverse Money',
            'address': '0x7b5C335e29607334f7Decc7c668A8fb0C6b49c47',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PAXG',
            'name': 'PAX Gold',
            'address': '0x7aFB0E2ebA6Dc938945FE0f42484d3b8F442D0AC',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/9519/thumb/paxg.PNG?1568542565',
            'coingeckoId': 'pax-gold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '1VIPER',
            'name': 'Viper',
            'address': '0x7E080699D0F306dbAE458b13EA6fa8BfD0efe752',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x7e080699d0f306dbae458b13ea6fa8bfd0efe752/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'bscETH',
            'name': 'Ethereum Token',
            'address': '0x783EE3E955832a3D52CA4050c4C251731c156020',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0x783EE3E955832a3D52CA4050c4C251731c156020/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SXP',
            'name': 'SXP',
            'address': '0x77d046614710fdDf5CA3E3cE85F4f09f7ABC283c',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/9368/thumb/swipe.png?1566792311',
            'coingeckoId': 'swipe',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUNNY',
            'name': 'Rocket Bunny',
            'address': '0x758765664769D82380c95CC3489d533FA21974fa',
            'decimals': 9,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/14350/thumb/n8TgW06A_400x400.jpg?1615514406',
            'coingeckoId': 'rocket-bunny',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WONE',
            'name': 'Wrapped ONE',
            'address': '0x7466d7d0C21Fa05F32F5a0Fa27e12bdC06348Ce2',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x7466d7d0c21fa05f32f5a0fa27e12bdc06348ce2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FTM',
            'name': 'Fantom',
            'address': '0x735aBE48e8782948a37C7765ECb76b98CdE97B0F',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://static.debank.com/image/hmy_token/logo_url/0x735abe48e8782948a37c7765ecb76b98cde97b0f/f0cd86ba8ddb907aabaf5a37a282b971.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'JEWEL',
            'name': 'DeFi Kingdoms',
            'address': '0x72Cb10C6bfA5624dD07Ef608027E366bd690048F',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/18570/thumb/fAisLIV.png?1632449282',
            'coingeckoId': 'defi-kingdoms',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MET',
            'name': 'Metatrone',
            'address': '0x725553bc9Aa0939362671407dfDEb162DD37d168',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/harmony/assets/0x725553bc9Aa0939362671407dfDEb162DD37d168/logo.png',
            'coingeckoId': 'metatrone',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'YAM',
            'name': 'YAM',
            'address': '0x7202ADF025CbD1cC9411fd56E3Cc8EF2E9dFFA9D',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/12530/thumb/YAM-icon.png?1600495536',
            'coingeckoId': 'yam-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTCB',
            'name': 'BTCB Token',
            'address': '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DOT',
            'name': 'Polkadot Token',
            'address': '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x7083609fce4d1d8dc0c979aab8c869ea2c873402/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'COMFY',
            'name': 'Comfy',
            'address': '0x702f78E81Cf3DfaE89648b5a9e2e1aa8db1De546',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/23062/thumb/COMFY-200x200.png?1643162411',
            'coingeckoId': 'comfy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DFKGREENEGG',
            'name': 'Green Pet Egg',
            'address': '0x6d605303e9Ac53C59A3Da1ecE36C9660c7A71da5',
            'decimals': 0,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/27550/thumb/green-pet-egg.png?1664443318',
            'coingeckoId': 'green-pet-egg',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTCB',
            'name': 'BTCB Token',
            'address': '0x6ce8dA28E2f864420840cF74474eFf5fD80E65B8',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x6ce8da28e2f864420840cf74474eff5fd80e65b8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'CGS',
            'name': 'Cougar',
            'address': '0x6cc35220349C444c39b8e26b359757739aAEc952',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x6cc35220349c444c39b8e26b359757739aaec952/logo.png',
            'coingeckoId': 'cougar-token',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'WBTC',
            'name': 'Wrapped BTC',
            'address': '0x6c4387C4f570Aa8cAdcaFFc5E73ecb3D0F8Fc593',
            'decimals': 8,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x6c4387c4f570aa8cadcaffc5e73ecb3d0f8fc593/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'AUSDT',
            'name': 'Aave USDT',
            'address': '0x6ab707Aca953eDAeFBc4fD23bA73294241490620',
            'decimals': 6,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/14243/thumb/aUSDT.78f5faae.png?1615528400',
            'coingeckoId': 'aave-usdt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BIFI',
            'name': 'Beefy Finance',
            'address': '0x6aB6d61428fde76768D7b45D8BFeec19c6eF91A8',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/harmony/assets/0x6aB6d61428fde76768D7b45D8BFeec19c6eF91A8/logo.png',
            'coingeckoId': 'beefy-finance',
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'COBRA',
            'name': 'COBRA',
            'address': '0x6D88599555BEdA404F8df2b9484e867E7d03EdDf',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x6d88599555beda404f8df2b9484e867e7d03eddf/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'EOS',
            'name': 'EOS Token',
            'address': '0x6B043332090a6BE81De1503949Cfc8bac2cA1c33',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x6b043332090a6be81de1503949cfc8bac2ca1c33/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'WETH',
            'name': 'WETH',
            'address': '0x6983D1E6DEf3690C4d616b13597A09e6193EA013',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0x6983D1E6DEf3690C4d616b13597A09e6193EA013/logo.png',
            'coingeckoId': 'weth',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WBNB',
            'name': 'Wrapped BNB',
            'address': '0x673D2EC54E0a6580fc7E098295B70e3cE0350D03',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/12591/thumb/binance-coin-logo.png?1600947313',
            'coingeckoId': 'wbnb',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HPLAY',
            'name': 'Harmony Play',
            'address': '0x66CF8375fd083E7d103D69378e9AF20F1b46DC59',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/20788/thumb/one1vm8cxa0apql86ypadymcaxhjpud5dhzeetm2yj.png?1637671819',
            'coingeckoId': 'harmony-play',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'YGG',
            'name': 'Yield Guild Games',
            'address': '0x63cf309500d8be0B9fDB8F1fb66C821236c0438c',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/harmony/assets/0x63cf309500d8be0B9fDB8F1fb66C821236c0438c/logo.png',
            'coingeckoId': 'yield-guild-games',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'agEUR',
            'name': 'agEUR',
            'address': '0x634398cB81B76bfc75Ebb434cf7C82036F9e7d78',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AUSDC',
            'name': 'Aave USDC',
            'address': '0x625E7708f30cA75bfd92586e17077590C60eb4cD',
            'decimals': 6,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/14318/thumb/aUSDC.e260d492.png?1615527797',
            'coingeckoId': 'aave-usdc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HOT',
            'name': 'Holo',
            'address': '0x5dfEaDCDD2d4eB29aC5Ae876dAA07FfD07Bf6483',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/3348/thumb/Holologo_Profile.png?1547037966',
            'coingeckoId': 'holotoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '1CREAM',
            'name': 'Cream',
            'address': '0x5c99F47e749d2Fb9F6002a2945df5Ea3F7162dFe',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0x5c99F47e749d2Fb9F6002a2945df5Ea3F7162dFe/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic',
                'elkfinance'
            ]
        },
        {
            'symbol': 'EVO',
            'name': 'EVO',
            'address': '0x5b747e23a9E4c509dd06fbd2c0e3cB8B846e398F',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/25088/large/evoToken.png?1650269135',
            'coingeckoId': null,
            'listedIn': [
                'dfyn',
                'RouterProtocol',
                'lifinance'
            ]
        },
        {
            'symbol': 'WARU',
            'name': 'Waru',
            'address': '0x59fA612968F54080aba370Fd822343c43F23C874',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/26059/thumb/logo.94e2b55c.png?1658824826',
            'coingeckoId': 'waru',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': '1INCH',
            'name': '1inch',
            'address': '0x58f1b044d8308812881a1433d9Bbeff99975e70C',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0x58f1b044d8308812881a1433d9Bbeff99975e70C/logo.png',
            'coingeckoId': '1inch',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SAFEMOON',
            'name': 'SafeMoon  OLD ',
            'address': '0x58c5E26fcc4d1d442396D33b58af31549C64d22F',
            'decimals': 9,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/14362/thumb/174x174-white.png?1617174846',
            'coingeckoId': 'safemoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADA',
            'name': 'Binance Peg Cardano',
            'address': '0x582617bD8Ca80d22D4432E63Fda52D74dcDCEe4c',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0x582617bD8Ca80d22D4432E63Fda52D74dcDCEe4c/logo.png',
            'coingeckoId': 'binance-peg-cardano',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'EOS',
            'name': 'EOS Token',
            'address': '0x56b6fB708fC5732DEC1Afc8D8556423A2EDcCbD6',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x56b6fb708fc5732dec1afc8d8556423a2edccbd6/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'COMBO',
            'name': 'Furucombo',
            'address': '0x5693FE17Ad04F0D8F768cEeB863e62B522901440',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/13629/thumb/COMBO_token_ol.png?1610701537',
            'coingeckoId': 'furucombo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'Tether USD',
            'address': '0x55d398326f99059fF775485246999027B3197955',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x55d398326f99059ff775485246999027b3197955/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'TUSD',
            'name': 'TrueUSD',
            'address': '0x553A1151F3Df3620fC2B5A75A6edDa629e3dA350',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/3449/thumb/tusd.png?1618395665',
            'coingeckoId': 'true-usd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IMRTL',
            'name': 'Immortl',
            'address': '0x550D9923693998A6FE20801ABe3f1A78e0d75089',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/21983/thumb/ONE.png?1640575713',
            'coingeckoId': 'immortl',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'SEAHORSE',
            'name': 'Seahorse',
            'address': '0x54b3cc870576b2059f464929aE52196719631EaC',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x54b3cc870576b2059f464929ae52196719631eac/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'LFGT',
            'name': 'Let\'s Fucking Go Token',
            'address': '0x5415B30fc46317ef494fa96d755350437aE15ac9',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x5415b30fc46317ef494fa96d755350437ae15ac9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FRATE',
            'name': 'Fraterium',
            'address': '0x5305D2fcfc9981c4518C498194Df493968D1D822',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x5305d2fcfc9981c4518c498194df493968d1d822/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'fRENT',
            'name': 'Fraterium Rent',
            'address': '0x52274a3aF646153faB00b8605E1F6F7072ce71F3',
            'decimals': 9,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x52274a3af646153fab00b8605e1f6f7072ce71f3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'RONE',
            'name': 'RONE',
            'address': '0x510d0372F55A1687CE73e9826d33d7f3E43D8492',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'RouterProtocol'
            ]
        },
        {
            'symbol': '1TEL',
            'name': 'Telcoin',
            'address': '0x50aE1ABA372B836523C982d8CE88E89AA8A92863',
            'decimals': 2,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0x50aE1ABA372B836523C982d8CE88E89AA8A92863/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic',
                'elkfinance'
            ]
        },
        {
            'symbol': 'JULD',
            'name': 'JulSwap',
            'address': '0x504D7d5bd2075FA782Fbd0bE9bEA4CDC7e25f5a1',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/13839/thumb/Logo-D-Unicorn.png?1622435397',
            'coingeckoId': 'julswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CGS',
            'name': 'CougarSwap',
            'address': '0x4f529873288668815d66EDa11314F719b23Dd846',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/19402/thumb/logo.png?1644812043',
            'coingeckoId': 'cougar-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAKE',
            'name': 'BakerySwap',
            'address': '0x4da9464DaF2b878e32E29115E2cFD786fE84692a',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/12588/thumb/bakerytoken_logo.jpg?1600940032',
            'coingeckoId': 'bakerytoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTOP',
            'name': 'MTOP',
            'address': '0x4a986Bb7909D361F3191Ea08d0C4B328295841A4',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/26774/thumb/Standalone_Square.png?1660051919',
            'coingeckoId': 'mtop',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TWT',
            'name': 'Trust Wallet',
            'address': '0x4B0F1812e5Df2A09796481Ff14017e6005508003',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x4b0f1812e5df2a09796481ff14017e6005508003/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'COINK',
            'name': 'Piggybankone',
            'address': '0x4970417a897Cc7ae812b9b8Db34bb44833C26739',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x4970417a897cc7ae812b9b8db34bb44833c26739/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BOOM',
            'name': 'Boomers',
            'address': '0x495500b582ab72AD47Eb2D301469CE799BDdF9e4',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x495500b582ab72ad47eb2d301469ce799bddf9e4/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'GAME',
            'name': 'GameToken',
            'address': '0x491614c6d1A7cc8b0A3Ed0bBdecd35a0110c11e6',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x491614c6d1a7cc8b0a3ed0bbdecd35a0110c11e6/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SXP',
            'name': 'Swipe',
            'address': '0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x47bead2563dcbf3bf2c9407fea4dc236faba485a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'USDS',
            'name': 'Stably USD',
            'address': '0x471f66F75af9238A2FA23bA23862B5957109fB21',
            'decimals': 6,
            'chainId': 1666600000,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/list/master/logos/token-logos/network/harmony/0x471f66F75af9238A2FA23bA23862B5957109fB21.jpg',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'xCOBRA',
            'name': 'xCOBRA',
            'address': '0x45f785581e0787D3C0C62676ABc6f17783e021f0',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x45f785581e0787d3c0c62676abc6f17783e021f0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'REN',
            'name': 'REN',
            'address': '0x451E129b6045b6E4F48E7247388f21163f7743B7',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/3139/thumb/REN.png?1589985807',
            'coingeckoId': 'republic-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HARL',
            'name': 'Harmony Launcher',
            'address': '0x4518231a8FDF6ac553B9BBD51Bbb86825B583263',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'bscUSDC',
            'name': 'USD Coin Token',
            'address': '0x44cED87b9F1492Bf2DCf5c16004832569f7f6cBa',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0x44cED87b9F1492Bf2DCf5c16004832569f7f6cBa/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'MEME',
            'name': 'Meme',
            'address': '0x43ba3c1eb73FfECFbF22c44C21b26CdE1012353a',
            'decimals': 8,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/12140/thumb/meme-coin.jpeg?1619249191',
            'coingeckoId': 'degenerator',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KEEP',
            'name': 'Keep Network',
            'address': '0x43bF77db5e784b263a459141BdCDf5Cf6987936d',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/3373/thumb/IuNzUb5b_400x400.jpg?1589526336',
            'coingeckoId': 'keep-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LTC',
            'name': 'Litecoin Token',
            'address': '0x4338665CBB7B2485A8855A139b75D5e34AB0DB94',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x4338665cbb7b2485a8855a139b75d5e34ab0db94/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'EBOX',
            'name': 'Ebox',
            'address': '0x4328588AaE1108FBD36e5cdB57C8128dcF7a6D9A',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/14528/thumb/ebox.png?1639203519',
            'coingeckoId': 'ebox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'bscEPS',
            'name': 'Ellipsis',
            'address': '0x4261b6a6e3Ca6D186C0fE7d0510e707aaf30A42b',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0x4261b6a6e3Ca6D186C0fE7d0510e707aaf30A42b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic',
                'elkfinance'
            ]
        },
        {
            'symbol': 'YFL',
            'name': 'YF Link',
            'address': '0x421b372389f89E2Abbf2C91f006Fc04a1f424B2D',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/12081/thumb/YFLink.png?1596987945',
            'coingeckoId': 'yflink',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RENBTC',
            'name': 'renBTC',
            'address': '0x41CA97b94D5deE79195856034D196dDfa0D43EDD',
            'decimals': 8,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/11370/thumb/Bitcoin.jpg?1628072791',
            'coingeckoId': 'renbtc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '1OPCT',
            'name': 'Opacity',
            'address': '0x40f5C9b19472b2D7Ee4dc080ae1BE4DE2F402BfE',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x40f5c9b19472b2d7ee4dc080ae1be4de2f402bfe/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'IKURA',
            'name': 'Ikura',
            'address': '0x40d2f81bD135B5282CB2aA18F19cF7098079D012',
            'decimals': 9,
            'chainId': 1666600000,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/harmony/assets/0x40d2f81bD135B5282CB2aA18F19cF7098079D012/logo.png',
            'coingeckoId': 'ikura-token',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'bscCAKE',
            'name': 'PancakeSwap Token',
            'address': '0x3e9D32580B0BF3aE72AFCBEbC68710d2Fd9a18F0',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0x3e9D32580B0BF3aE72AFCBEbC68710d2Fd9a18F0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MIMATIC',
            'name': 'MAI',
            'address': '0x3F56e0c36d275367b8C502090EDF38289b3dEa0d',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://tokens.1inch.io/0xa3fa99a148fa48d14ed51d610c367c61876997f1.png',
            'coingeckoId': 'mimatic',
            'listedIn': [
                'coingecko',
                'RouterProtocol',
                'rubic',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BRNG',
            'name': 'bRing Finance',
            'address': '0x3Ecb96039340630c8B82E5A7732bc88b2aeadE82',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/17836/thumb/bring_finance.PNG?1629417184',
            'coingeckoId': 'bring-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADA',
            'name': 'Cardano Token',
            'address': '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x3ee2200efb3400fabb9aacf31297cbdd1d435d47/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'KURO',
            'name': 'Kuro Shiba',
            'address': '0x3E018675c0Ef63eB361b9EF4bfEa3A3294C74C7b',
            'decimals': 9,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/19325/thumb/83105670.png?1635113296',
            'coingeckoId': 'kuro-shiba',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'CSHARE',
            'name': 'Comfy Share',
            'address': '0x3CB98cacd44Ee77eb35E99EB74Ace91bF550c964',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/23063/thumb/CSHARE-200x200.png?1643162580',
            'coingeckoId': 'comfy-share',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'Tether',
            'address': '0x3C2B8Be99c50593081EAA2A724F0B8285F5aba8f',
            'decimals': 6,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0x3C2B8Be99c50593081EAA2A724F0B8285F5aba8f/logo.png',
            'coingeckoId': 'tether',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'bscCOBRA',
            'name': 'COBRA',
            'address': '0x3Aa9470bdf720D7FF3D3fB78231803431970A329',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0x3Aa9470bdf720D7FF3D3fB78231803431970A329/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FTM',
            'name': 'Fantom',
            'address': '0x39aB439897380eD10558666C4377fACB0322Ad48',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/4001/thumb/Fantom.png?1558015016',
            'coingeckoId': 'fantom',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAND',
            'name': 'The Sandbox',
            'address': '0x35de8649e1e4Fd1A7Bd3B14F7e24e5e7887174Ed',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/12129/thumb/sandbox_logo.jpg?1597397942',
            'coingeckoId': 'the-sandbox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '1CRV',
            'name': 'Curve DAO Token',
            'address': '0x352cd428EFd6F31B5cae636928b7B84149cF369F',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0x352cd428EFd6F31B5cae636928b7B84149cF369F/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DSLA',
            'name': 'DSLA Protocol',
            'address': '0x34704c70e9eC9fB9A921da6DAAD7D3e19f43c734',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0x34704c70e9eC9fB9A921da6DAAD7D3e19f43c734/logo.png',
            'coingeckoId': 'stacktical',
            'listedIn': [
                'coingecko',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BTCB',
            'name': 'Binance Bitcoin',
            'address': '0x34224dCF981dA7488FdD01c7fdd64E74Cd55DcF7',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0x34224dCF981dA7488FdD01c7fdd64E74Cd55DcF7/logo.png',
            'coingeckoId': 'binance-bitcoin',
            'listedIn': [
                'coingecko',
                'elkfinance'
            ]
        },
        {
            'symbol': 'YANG',
            'name': 'Yang',
            'address': '0x340042552D19211795dbe55d84FA2E63bc49B890',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x340042552d19211795dbe55d84fa2e63bc49b890/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': '1USDC',
            'name': 'OneUSDC',
            'address': '0x33B465B61EBb322E6336437b2624F705a34a0Df0',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x33b465b61ebb322e6336437b2624f705a34a0df0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'Tether USD',
            'address': '0x337610d27c682E347C9cD60BD4b3b107C9d34dDd',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x337610d27c682e347c9cd60bd4b3b107c9d34ddd/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'COMP',
            'name': 'Compound',
            'address': '0x32137b9275EA35162812883582623cd6f6950958',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0x32137b9275EA35162812883582623cd6f6950958/logo.png',
            'coingeckoId': 'compound-governance-token',
            'listedIn': [
                'coingecko',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WBTC',
            'name': 'Wrapped Bitcoin',
            'address': '0x3095c7557bCb296ccc6e363DE01b760bA031F2d9',
            'decimals': 8,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0x3095c7557bCb296ccc6e363DE01b760bA031F2d9/logo.png',
            'coingeckoId': 'wrapped-bitcoin',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FEG',
            'name': 'FEG',
            'address': '0x3042BB02E308431e1D8cD5785A60BbD7ED2E3f54',
            'decimals': 9,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/13878/thumb/F-Icon-FEG-200x200-288dpi-01_%281%29.png?1665641152',
            'coingeckoId': 'feg-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MATIC',
            'name': 'Polygon',
            'address': '0x301259f392B551CA8c592C9f676FCD2f9A0A84C5',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0x301259f392B551CA8c592C9f676FCD2f9A0A84C5/logo.png',
            'coingeckoId': 'matic-network',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'JENN',
            'name': 'TokenJenny',
            'address': '0x2F459Dd7CBcC9D8323621f6Fb430Cd0555411E7B',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0x2F459Dd7CBcC9D8323621f6Fb430Cd0555411E7B/logo.png',
            'coingeckoId': 'tokenjenny',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BELUGA',
            'name': 'Beluga fi',
            'address': '0x2F20a4022Bf71daf47eC43972B6ecF56e0DB0609',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/13790/thumb/brand-logo-v2.23e5d279.png?1624906164',
            'coingeckoId': 'beluga-fi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '1LINK',
            'name': 'OneChainlink',
            'address': '0x2C6e26B2faD89bc52d043e78E3D980A08af0Ce88',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x2c6e26b2fad89bc52d043e78e3d980a08af0ce88/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'COBRA',
            'name': 'COBRA',
            'address': '0x2C449bA613873e7B980FaF2b686207d7bd205541',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x2c449ba613873e7b980faf2b686207d7bd205541/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FIRA',
            'name': 'FIRA',
            'address': '0x2A719aF848bf365489E548BE5edbEC1D65858e59',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/23983/thumb/fira.png?1645881719',
            'coingeckoId': 'fira',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAT',
            'name': 'Basic Attention',
            'address': '0x2875B4CfAb0A4cc4bdc7fBDf94b6E376826A4332',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/677/thumb/basic-attention-token.png?1547034427',
            'coingeckoId': 'basic-attention-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OPEN',
            'name': 'OpenWorld',
            'address': '0x27a339d9B59b21390d7209b78a839868E319301B',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CRO',
            'name': 'Cronos',
            'address': '0x2672B791D23879995AaBdf51Bc7d3DF54BB4e266',
            'decimals': 8,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/7310/thumb/oCw2s3GI_400x400.jpeg?1645172042',
            'coingeckoId': 'crypto-com-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STORJ',
            'name': 'Storj',
            'address': '0x266F341E33aa61c30c6a9af89314811A5b097cb4',
            'decimals': 8,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/949/thumb/storj.png?1547034811',
            'coingeckoId': 'storj',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLOKI',
            'name': 'Floki One',
            'address': '0x24b6179E4062ED9BAEacE0E6081Dbd55B6072380',
            'decimals': 9,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/19646/thumb/logo-big_%281%29.png?1635729814',
            'coingeckoId': 'floki-one',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JOC',
            'name': 'Speed Star JOC',
            'address': '0x22FB638a010e922D53Fd2671a598a3334C228B62',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/24883/thumb/f2zOnAa4_400x400.jpg?1649232735',
            'coingeckoId': 'speed-star-joc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STONE',
            'name': 'Tranquil Staked ONE',
            'address': '0x22D62b19b7039333ad773b7185BB61294F3AdC19',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/harmony/assets/0x22D62b19b7039333ad773b7185BB61294F3AdC19/logo.png',
            'coingeckoId': 'tranquil-staked-one',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'UST',
            'name': 'TerraUSD',
            'address': '0x224e64ec1BDce3870a6a6c777eDd450454068FEC',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0x224e64ec1BDce3870a6a6c777eDd450454068FEC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'LINK',
            'name': 'Chainlink',
            'address': '0x218532a12a389a4a92fC0C5Fb22901D1c19198aA',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0x218532a12a389a4a92fC0C5Fb22901D1c19198aA/logo.png',
            'coingeckoId': 'chainlink',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ETH',
            'name': 'Ethereum Token',
            'address': '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x2170ed0880ac9a755fd29b2688956bd959f933f8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'LOTTO',
            'name': 'Lotto',
            'address': '0x1f7178D868ED7da31f8E8a15d9f499bCBdaEFFf2',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/13822/thumb/Lotto-Logo256x256.png?1612150421',
            'coingeckoId': 'lotto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FRONT',
            'name': 'Frontier',
            'address': '0x1eE5839950Fd7a227f91CF679b1931dd6F5798B3',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/12479/thumb/frontier_logo.png?1600145472',
            'coingeckoId': 'frontier-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'bscDAI',
            'name': 'Dai Token',
            'address': '0x1d374ED0700a0aD3cd4945D66a5B1e08e5db20A8',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0x1d374ED0700a0aD3cd4945D66a5B1e08e5db20A8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic',
                'elkfinance'
            ]
        },
        {
            'symbol': 'LOGE',
            'name': 'Logecoin',
            'address': '0x1c0869a9ee9e866288Fd4A62342BFe8941DACf94',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x1c0869a9ee9e866288fd4a62342bfe8941dacf94/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MM',
            'name': 'Million',
            'address': '0x1b2bc2683F85bFb9c4C90e8322d62A20B97cF87D',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/harmony/assets/0x1b2bc2683F85bFb9c4C90e8322d62A20B97cF87D/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'PAPER',
            'name': 'Paper',
            'address': '0x1affBc17938a25d245e1B7eC6f2fc949df8E9760',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/23510/thumb/0xea97c7c1c89d4084e0bfb88284fa90243779da9f.png?1644300084',
            'coingeckoId': 'paper-fantom',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNISTAKE',
            'name': 'Unistake',
            'address': '0x1Ec56F91d61F2c2CB1af0a2B9BB8d7984F0A6626',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/12813/thumb/unistake.png?1612346684',
            'coingeckoId': 'unistake',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '1ETH',
            'name': 'OneETH',
            'address': '0x1E120B3b4aF96e7F394ECAF84375b1C661830013',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x1e120b3b4af96e7f394ecaf84375b1c661830013/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'XRP',
            'name': 'XRP Token',
            'address': '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DAI',
            'name': 'Dai Token',
            'address': '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ALINK',
            'name': 'Aave LINK',
            'address': '0x191c10Aa4AF7C30e871E70C95dB0E4eb77237530',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/14315/thumb/aLINK.412c6589.png?1615527827',
            'coingeckoId': 'aave-link',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XTZ',
            'name': 'Tezos Token',
            'address': '0x16939ef78684453bfDFb47825F8a5F714f12623a',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x16939ef78684453bfdfb47825f8a5f714f12623a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'AXS',
            'name': 'Axie Infinity',
            'address': '0x14A7B318fED66FfDcc80C1517C172c13852865De',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/harmony/assets/0x14A7B318fED66FfDcc80C1517C172c13852865De/logo.png',
            'coingeckoId': 'axie-infinity',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'OIL',
            'name': 'Oil',
            'address': '0x1449Ab6C24Dcf3DbC1971021f465af1B81F48F07',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/list/master/logos/token-logos/network/harmony/0x1449Ab6C24Dcf3DbC1971021f465af1B81F48F07.jpg',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'QDROP',
            'name': 'QuizDrop',
            'address': '0x13F7203b2e101858933b82Df1FA13f81AaA38597',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/21463/thumb/quizdrop.PNG?1639292994',
            'coingeckoId': 'quizdrop',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BETS',
            'name': 'BetSwirl Token',
            'address': '0x130966628846BFd36ff31a822705796e8cb8C18D',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': '1USDT',
            'name': 'OneUSDT',
            'address': '0x12f839b098d1446ba9b25c4F6f7Ef49cc1846dEd',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x12f839b098d1446ba9b25c4f6f7ef49cc1846ded/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': '1UST',
            'name': 'OneUST',
            'address': '0x12b7146CC70F2F1422B3b11B116f10F1925d256A',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x12b7146cc70f2f1422b3b11b116f10f1925d256a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ELONGATE',
            'name': 'ElonGate',
            'address': '0x11FC804f435c601335b55189fE519fdb88f23A29',
            'decimals': 9,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/15017/thumb/fRRPDnh4_400x400.jpg?1619448867',
            'coingeckoId': 'elongate',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VIPER',
            'name': 'Viper',
            'address': '0x11F477aE5f42335928fC94601a8A81ec77b27b2b',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x11f477ae5f42335928fc94601a8a81ec77b27b2b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'xCOBRA',
            'name': 'xCOBRA',
            'address': '0x11110118d34FdE9c609E072D05C017C74190B758',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x11110118d34fde9c609e072d05c017c74190b758/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'RMAI',
            'name': 'RMAI',
            'address': '0x1046D4D30a357c61e674038d8344Dd70c0327896',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'RouterProtocol'
            ]
        },
        {
            'symbol': 'HND',
            'name': 'Hundred Finance',
            'address': '0x10010078a54396F62c96dF8532dc2B4847d47ED3',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/harmony/assets/0x10010078a54396F62c96dF8532dc2B4847d47ED3/logo.png',
            'coingeckoId': 'hundred-finance',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'WAGMI',
            'name': 'Euphoria',
            'address': '0x0dc78c79B4eB080eaD5C1d16559225a46b580694',
            'decimals': 9,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/20054/thumb/euphoria-512x512.png?1636443725',
            'coingeckoId': 'euphoria-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'hMOCHI',
            'name': 'MochiSwap Token',
            'address': '0x0dD740Db89B9fDA3Baadf7396DdAD702b6E8D6f5',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x0dd740db89b9fda3baadf7396ddad702b6e8d6f5/logo.png',
            'coingeckoId': 'mochiswap-token',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'bscBUSD',
            'name': 'BUSD Token',
            'address': '0x0aB43550A6915F9f67d0c454C2E90385E6497EaA',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0x0aB43550A6915F9f67d0c454C2E90385E6497EaA/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'KNCL',
            'name': 'Kyber Network Crystal Legacy',
            'address': '0x0a47D2dC4B7Ee3D4D7FD471d993b0821621e1769',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/947/thumb/logo-kncl.png?1618984814',
            'coingeckoId': 'kyber-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '1BUSD',
            'name': 'OneBUSD',
            'address': '0x0E80905676226159cC3FF62B1876C907C91F7395',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x0e80905676226159cc3ff62b1876c907c91f7395/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'CAKE',
            'name': 'Pancake Token',
            'address': '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'CLNY',
            'name': 'ColonyToken',
            'address': '0x0D625029E21540aBdfAFa3BFC6FD44fB4e0A66d0',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/harmony/assets/0x0D625029E21540aBdfAFa3BFC6FD44fB4e0A66d0/logo.png',
            'coingeckoId': 'marscolony',
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'bscDOT',
            'name': 'Polkadot Token',
            'address': '0x08CB2917245BBE75C8C9c6Dc4a7B3765Dae02b31',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0x08CB2917245BBE75C8C9c6Dc4a7B3765Dae02b31/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ROOK',
            'name': 'Rook',
            'address': '0x08310baF9DeB5F13B885eDf5eeA6fD19DC21AF3A',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/13005/thumb/keeper_dao_logo.jpg?1604316506',
            'coingeckoId': 'rook',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AWBTC',
            'name': 'Aave WBTC',
            'address': '0x078f358208685046a11C85e8ad32895DED33A249',
            'decimals': 8,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/14244/thumb/aWBTC.41453c2a.png?1615528721',
            'coingeckoId': 'aave-wbtc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FXS',
            'name': 'Frax Share',
            'address': '0x0767D8E1b05eFA8d6A301a65b324B6b66A1CC14c',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/fxs.jpg',
            'coingeckoId': 'frax-share',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BADGER',
            'name': 'Badger DAO',
            'address': '0x06b19a0ce12dC71f1C7a6DD39E8983E089C40E0d',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/13287/thumb/badger_dao_logo.jpg?1607054976',
            'coingeckoId': 'badger-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'bscXVS',
            'name': 'Venus',
            'address': '0x04f308C988ec1C5A4e4A99d3d2A5B8F4287260bD',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0x04f308C988ec1C5A4e4A99d3d2A5B8F4287260bD/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FM',
            'name': 'Follow ME',
            'address': '0x03943C3ef00d92e130185CeBC0bcc435Def2cC94',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x03943c3ef00d92e130185cebc0bcc435def2cc94/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'anyBTC',
            'name': 'ANY Bitcoin',
            'address': '0x03732a1b4297eC285999402A9129CFaD62a65463',
            'decimals': 8,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0x03732a1b4297eC285999402A9129CFaD62a65463/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic',
                'elkfinance'
            ]
        },
        {
            'symbol': 'bscZIL',
            'name': 'Zilliqa',
            'address': '0x0341D02C0fD5439576742750e6F2a2C0993A520b',
            'decimals': 12,
            'chainId': 1666600000,
            'logoURI': 'https://elk.finance/tokens/logos/harmony/0x0341D02C0fD5439576742750e6F2a2C0993A520b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic',
                'elkfinance'
            ]
        },
        {
            'symbol': 'OPENX',
            'name': 'OpenSwap One',
            'address': '0x01A4b054110d57069c1658AFBC46730529A3E326',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/harmony/assets/0x01A4b054110d57069c1658AFBC46730529A3E326/logo.png',
            'coingeckoId': 'openswap-token',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'FOX',
            'name': 'FoxSwap',
            'address': '0x0159ED2E06DDCD46a25E74eb8e159Ce666B28687',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/harmony/assets/0x0159ED2E06DDCD46a25E74eb8e159Ce666B28687/logo.png',
            'coingeckoId': 'farmers-only',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'GRT',
            'name': 'The Graph',
            'address': '0x002FA662F2E09de7C306d2BaB0085EE9509488Ff',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/13397/thumb/Graph_Token.png?1608145566',
            'coingeckoId': 'the-graph',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VITC',
            'name': 'Vitamin Coin',
            'address': '0x0007E7f456A6Bb854606f04cc16989aCFe42F2F2',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://assets.coingecko.com/coins/images/20661/thumb/vitc_200.png?1637545079',
            'coingeckoId': 'vitamin-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ONE',
            'name': 'ONE',
            'address': '0x0000000000000000000000000000000000000000',
            'decimals': 18,
            'chainId': 1666600000,
            'logoURI': 'https://api.rubic.exchange/assets/harmony/0x0000000000000000000000000000000000000000/logo.png',
            'coingeckoId': 'harmony',
            'listedIn': [
                'rubic',
                'lifinance'
            ]
        }
    ],
    '100': [
        {
            'symbol': 'REALT-S-14882-TROES',
            'name': 'RealT   14882 Troester St  Detroit  MI ',
            'address': '0xff1B4D71Ae12538d86777A954b136cF723fCcEFD',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16600/thumb/14882-Troester-hero-1.jpg?1624542180',
            'coingeckoId': '14882-troester',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'TRIB',
            'name': 'Contribute on xDai',
            'address': '0xff0Ce179a303F26017019acf78B951cB743B8D9b',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xff0Ce179a303F26017019acf78B951cB743B8D9b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SWISE',
            'name': 'StakeWise from Mainnet',
            'address': '0xfdA94F056346d2320d4B5E468D6Ad099b2277746',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://static.debank.com/image/xdai_token/logo_url/0xfda94f056346d2320d4b5e468d6ad099b2277746/c23c49fcf9096ab73cf0001d78f570a3.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'XYO',
            'name': 'XY Oracle on xDai',
            'address': '0xfd4e5f45eA24eC50C4Db4367380b014875caF219',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xfd4e5f45eA24eC50C4Db4367380b014875caF219/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ALBC',
            'name': 'AlbCoin from Ethereum',
            'address': '0xfb23CfD35046466FdBA7f73dC2FcCb5b17ABf1Aa',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xfb23CfD35046466FdBA7f73dC2FcCb5b17ABf1Aa/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MPS',
            'name': 'Mt Pelerin Shares',
            'address': '0xfa57AA7beED63D03Aaf85fFd1753f5f6242588fb',
            'decimals': 0,
            'chainId': 100,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/logos/master/network/xdai/0xfa57AA7beED63D03Aaf85fFd1753f5f6242588fb.jpg',
            'coingeckoId': 'mt-pelerin-shares',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'aDAI',
            'name': 'Aave interest bearing DAI from Mainnet',
            'address': '0xfE334eD5b2498F83f8f35cb63aEb80759A9b1176',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'HGET',
            'name': 'Hedget on xDai',
            'address': '0xfB83e5e86A9E71dfc6cbaCc6E9019C5B8d66B80b',
            'decimals': 6,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ION',
            'name': 'Peerion',
            'address': '0xfA9454BBBA1DDF83747bdC42bEa8641475753c42',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'XRT',
            'name': 'Robonomics on xDai',
            'address': '0xf54b47B00B6916974c73B81B7d9929a4f443DB49',
            'decimals': 9,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xf54b47B00B6916974c73B81B7d9929a4f443DB49/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BFED',
            'name': 'Fedoragold Coin from Ethereum',
            'address': '0xf2197c8B553e37E949815360D5ca5E745E925511',
            'decimals': 8,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xf2197c8B553e37E949815360D5ca5E745E925511/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'XGT',
            'name': 'XionGlobal Token',
            'address': '0xf1738912ae7439475712520797583ac784ea9033',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xf1738912ae7439475712520797583ac784ea9033/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MLN',
            'name': 'Melon Token on xDai',
            'address': '0xf0dd817FF483535f4059781441596AEa4F32a4B9',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'KNC',
            'name': 'Kyber Network Crystal v2 on xDai',
            'address': '0xf0D226E9ABc400fb4376AfC5BD7A6Dfa8269Ca3F',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ETHIX',
            'name': 'EthicHub',
            'address': '0xec3f3e6d7907acDa3A7431abD230196CDA3FbB19',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/3031/thumb/ETHIX_icon_256x256-256.png?1622195164',
            'coingeckoId': 'ethichub',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-4680-BUCKIN',
            'name': 'RealT   4680 Buckingham Ave  Detroit  M',
            'address': '0xeFe82D6baF0dB71f92889eB9d00721bD49121316',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16629/thumb/4680-Buckingham-hero-1.jpeg?1624553513',
            'coingeckoId': '4680-buckingham',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'FTM',
            'name': 'Fantom Token from Mainnet',
            'address': '0xeEfeA398213938bA56B1C5D282187862c9Ca5D0D',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ELK',
            'name': 'Elk Finance',
            'address': '0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://tokens.1inch.io/0xeeeeeb57642040be42185f49c52f7e9b38f8eeee.png',
            'coingeckoId': 'elk-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'TVK',
            'name': 'Terra Virtua Kolect',
            'address': '0xeB2BCaBb0cDC099978A74cFE4Ab4d45E7e677a45',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xeB2BCaBb0cDC099978A74cFE4Ab4d45E7e677a45/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WXDAI',
            'name': 'Wrapped XDAI',
            'address': '0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xe91D153E0b41518A2Ce8Dd3D7944Fa863463a97d/logo.png',
            'coingeckoId': 'wrapped-xdai',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALT-S-14066-SANTA',
            'name': 'RealT   14066 Santa Rosa Dr  Detroit  M',
            'address': '0xe887DC4fcb5240c0c080AEAb8870421d3ebd0B28',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16677/thumb/14066-Santa-Rosa-hero-1.5.jpg?1624629250',
            'coingeckoId': '14066-santa-rosa',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'HAUSx',
            'name': 'Superfluid HAUS',
            'address': '0xe83fD17028c2Dd3cA4a9B75f2836d4558Fe00686',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://static.debank.com/image/xdai_token/logo_url/0xb0c5f3100a4d9d9532a4cfd68c55f1ae8da987eb/445ed9b4d899940d75305b98e2697f0a.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REALTOKEN-S-10700-WHITTIER-AVE-DETROIT-MI',
            'name': 'RealToken S 10700 Whittier Ave Detroit MI',
            'address': '0xe82CBb7C29d00a4296EE505d12a473C26cd9c423',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://api.rubic.exchange/assets/xdai/0xe82cbb7c29d00a4296ee505d12a473c26cd9c423/logo.png',
            'coingeckoId': '10700-whittier',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'NCR',
            'name': 'Neos Credits on xDai',
            'address': '0xe7f5f654c57236a651f576155C63428432d03943',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MCX',
            'name': 'MachiX Token on xDai',
            'address': '0xe72cbA323f5A67eEc73869EaE483dA7EC70A52E1',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FOUR',
            'name': 'The 4th Pillar Token on xDai',
            'address': '0xe6fF35dc3227a0c46e92b640bCb5c5895AD8C687',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REALTOKEN-15048-FREELAND-ST-DETROIT-MI',
            'name': 'RealToken S 15048 Freeland St Detroit MI',
            'address': '0xe3902E329Ef2D3FD7666022C139d75BCc984b7a5',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://api.rubic.exchange/assets/xdai/0xe3902e329ef2d3fd7666022c139d75bcc984b7a5/logo.png',
            'coingeckoId': '15048-freeland',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MOONS',
            'name': 'MoonTools.io on xDai',
            'address': '0xe1Fe0C26aa72Cd058286B5D296F32aCb27102786',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BAND',
            'name': 'BandToken on xDai',
            'address': '0xe154A435408211AC89757B76C4FbE4Dc9ED2Ef27',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xe154A435408211AC89757B76C4FbE4Dc9ED2Ef27/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BAOcx',
            'name': 'BaoToken Coupon',
            'address': '0xe0d0b1DBbCF3dd5CAc67edaf9243863Fd70745DA',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xe0d0b1DBbCF3dd5CAc67edaf9243863Fd70745DA/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BADGER',
            'name': 'Badger DAO',
            'address': '0xdfc20AE04ED70bd9c7D720F449eEDAe19F659D65',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xdfc20AE04ED70bd9c7D720F449eEDAe19F659D65/logo.png',
            'coingeckoId': 'badger-dao',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FSW',
            'name': 'FalconSwap Token on xDai',
            'address': '0xde1E70ed71936E4C249a7d43E550f0b99FCCDDfC',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xde1E70ed71936E4C249a7d43E550f0b99FCCDDfC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BUSD',
            'name': 'Binance USD',
            'address': '0xdd96B45877d0E8361a4DDb732da741e97f3191Ff',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://tokens.1inch.io/0x4fabb145d64652a948d72533023f6e7a623c7c53.png',
            'coingeckoId': 'binance-usd',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'COLD',
            'name': 'COLD TRUTH CASH',
            'address': '0xdbcadE285846131a5e7384685EADDBDFD9625557',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xdbcadE285846131a5e7384685EADDBDFD9625557/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'QI',
            'name': 'QiDao',
            'address': '0xdFA46478F9e5EA86d57387849598dbFB2e964b02',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xdFA46478F9e5EA86d57387849598dbFB2e964b02/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BZZ',
            'name': 'BZZ from Ethereum',
            'address': '0xdBF3Ea6F5beE45c02255B2c26a16F300502F68da',
            'decimals': 16,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xdBF3Ea6F5beE45c02255B2c26a16F300502F68da/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALT-S-15350-GREYD',
            'name': 'RealT   15350 Greydale St  Detroit  MI ',
            'address': '0xdA47bD33e8f5d17Bb81b8752784bfb46C1c44B2A',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16564/thumb/15350-Greydale-hero-1.jpg?1624460101',
            'coingeckoId': '15350-greydale',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'REALT-S-4061-GRAND-',
            'name': 'RealT   4061 Grand St  Detroit  MI 4823',
            'address': '0xd9e89bFebAe447B42C1Fa85C590716eC8820f737',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16616/thumb/4061-Grand-hero-1.jpeg?1624551866',
            'coingeckoId': '4061-grand',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'HEX',
            'name': 'HEX on xDai',
            'address': '0xd9Fa47e33d4Ff7a1ACA489DE1865ac36c042B07a',
            'decimals': 8,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xd9Fa47e33d4Ff7a1ACA489DE1865ac36c042B07a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HBTC',
            'name': 'Huobi BTC on xDai',
            'address': '0xd87FCB23da48D4D9B70c6F39B46debb5d993Ad19',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'HOP',
            'name': 'Hop from Mainnet',
            'address': '0xd859C12fc06045b0bd399ac55Ffa75cAdc378F69',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'RAI',
            'name': 'Rai Reflex Index from Mainnet',
            'address': '0xd7a28Aa9c470e7e9D8c676BCd5dd2f40c5683afa',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'UBT',
            'name': 'UniBright on xDai',
            'address': '0xd3b93fF74E43Ba9568e5019b38AdDB804feF719B',
            'decimals': 8,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xd3b93fF74E43Ba9568e5019b38AdDB804feF719B/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MCB',
            'name': 'MCDEX Token on xDai',
            'address': '0xd361c1FD663d8f2dc36ae07FF6F3623532cAbdD3',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xd361c1FD663d8f2dc36ae07FF6F3623532cAbdD3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALT-S-272-NE-42ND',
            'name': 'RealT   272 N E  42nd Court  Deerfield ',
            'address': '0xce111a198eB04F388AceB78c40ceD6daF1b0514a',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16635/thumb/272-Court-1.jpeg?1624556789',
            'coingeckoId': '272-n-e-42nd-court',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'AUT',
            'name': 'Autopia Token on xDai',
            'address': '0xcaE40062a887581A3d1661d0AC2b481c32e3E938',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xcaE40062a887581A3d1661d0AC2b481c32e3E938/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FRAX',
            'name': 'Frax on xDai',
            'address': '0xca5d82E40081F220d59f7ED9e2e1428DEAf55355',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'LTI',
            'name': 'Layer 2 Index on xDai',
            'address': '0xcF9Dc2de2A67d7db1A7171e3b8456D2171E4da75',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xcF9Dc2de2A67d7db1A7171e3b8456D2171E4da75/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MRP',
            'name': 'MORPHINE',
            'address': '0xcC043D8820a6dc3E74EF6fb4772faE00c1563489',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xcC043D8820a6dc3E74EF6fb4772faE00c1563489/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HAPI',
            'name': 'HAPI on xDai',
            'address': '0xcBBe9bD32d4Df6c328befD853ba083C5A85403f1',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CLNY',
            'name': 'Colony Network Token on xDai',
            'address': '0xc9B6218AffE8Aba68a13899Cbf7cF7f14DDd304C',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://static.debank.com/image/xdai_token/logo_url/0xc9b6218affe8aba68a13899cbf7cf7f14ddd304c/d70433051d292c17e2a25fb7ab2fb751.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AFI',
            'name': 'AriesFinancial on xDai',
            'address': '0xc81c785653D97766b995D867CF91F56367742eAC',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xc81c785653D97766b995D867CF91F56367742eAC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NODE',
            'name': 'DAppNode DAO Token from Ethereum',
            'address': '0xc60e38C6352875c051B481Cbe79Dd0383AdB7817',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xc60e38C6352875c051B481Cbe79Dd0383AdB7817/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'OPEN',
            'name': 'OPEN Governance Token on xDai',
            'address': '0xc51BA153Cc67C78B63e47A9B7B910e933244Aa53',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'HOP',
            'name': 'Hop Protocol',
            'address': '0xc5102fE9359FD9a28f877a67E36B0F050d81a3CC',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://tokens.1inch.io/0xc5102fe9359fd9a28f877a67e36b0f050d81a3cc.png',
            'coingeckoId': 'hop-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'HEGIC',
            'name': 'Hegic on xDai',
            'address': '0xc44048a4d13548E30DBF01555e6A3C3EEe08D258',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SAI',
            'name': 'Sai on xDai',
            'address': '0xc439E5B1DEe4f866B681E7c5E5dF140aA47fBf19',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xc439E5B1DEe4f866B681E7c5E5dF140aA47fBf19/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'LEND',
            'name': 'EthLend Token on xDai',
            'address': '0xc1b42BDb485dEb24C74f58399288d7915a726C1D',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xc1b42BDb485dEb24C74f58399288d7915a726C1D/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SURF',
            'name': 'SURF.Finance from Ethereum',
            'address': '0xc12956b840B403b600014a3092f6EBD9259738fE',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xc12956b840B403b600014a3092f6EBD9259738fE/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HNYx',
            'name': 'Superfluid HNY',
            'address': '0xc0712524B39323eb2437E69226b261d928629dC8',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://static.debank.com/image/xdai_token/logo_url/0x71850b7e9ee3f13ab46d67167341e4bdc905eef9/d7a1c268a0f2d7f2cfca62c7d98a9b6c.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'YFI',
            'name': 'yearn finance',
            'address': '0xbf65bfcb5da067446CeE6A706ba3Fe2fB1a9fdFd',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xbf65bfcb5da067446CeE6A706ba3Fe2fB1a9fdFd/logo.png',
            'coingeckoId': 'yearn-finance',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ADAI',
            'name': 'Aave Interest bearing DAI on xDai',
            'address': '0xbcfB2B889F7bAa29Dd7A7B447b6C87Aca572F4f4',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xbcfB2B889F7bAa29Dd7A7B447b6C87Aca572F4f4/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BNSD',
            'name': 'bns.finance on xDai',
            'address': '0xbDB90BDAdae84Af0b07abf4cEFcC7989F909f9bD',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xbDB90BDAdae84Af0b07abf4cEFcC7989F909f9bD/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MDZA',
            'name': 'MEDOOZA Ecosystem v2.0 from Ethereum',
            'address': '0xbAb3cbDcBCc578445480a79ed80269C50bB5B718',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xbAb3cbDcBCc578445480a79ed80269C50bB5B718/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DXD',
            'name': 'DXdao from Ethereum',
            'address': '0xb90D6bec20993Be5d72A5ab353343f7a0281f158',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://tokens.1inch.io/0xa1d65e8fb6e87b60feccbc582f7f97804b725521.png',
            'coingeckoId': 'dxdao',
            'listedIn': [
                '1inch',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALT-S-11078-WAYBU',
            'name': 'RealT   11078 Wayburn St  Detroit  MI 4',
            'address': '0xb8403b7730368942a5BFe5aaC04a31B44015b1cc',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16596/thumb/11078-Wayburn-hero-1.jpg?1624541135',
            'coingeckoId': '11078-wayburn',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'STAKE',
            'name': 'STAKE',
            'address': '0xb7D311E2Eb55F2f68a9440da38e7989210b9A05e',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://tokens.1inch.io/0x0ae055097c6d159879521c384f1d2123d1f195e6.png',
            'coingeckoId': 'xdai-stake',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALT-S-12866-LAUDE',
            'name': 'RealT   12866 Lauder St  Detroit  MI 48',
            'address': '0xb5DD2b6E0A0422e069E1D2CC3Ed16533488a05e3',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16565/thumb/12866-Lauder-hero-1.jpg?1624460677',
            'coingeckoId': '12866-lauder',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'BOND',
            'name': 'BarnBridge',
            'address': '0xb31a2595E4Cf66EfBC1Fe348b1429E5730891382',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xb31a2595E4Cf66EfBC1Fe348b1429E5730891382/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HAUS',
            'name': 'DAOhaus',
            'address': '0xb0C5f3100A4d9d9532a4CfD68c55F1AE8da987Eb',
            'decimals': 35,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xb0C5f3100A4d9d9532a4CfD68c55F1AE8da987Eb/logo.png',
            'coingeckoId': 'daohaus',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALT-S-14229-WILSH',
            'name': 'RealT   14229 Wilshire Dr  Detroit  MI ',
            'address': '0xb09850e2B93aa3AAa1476bF0c007cfc960E2de79',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16604/thumb/14229-Wilshire-hero-1.jpg?1624543967',
            'coingeckoId': '14229-wilshire',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'REALT-S-18983-ALCOY',
            'name': 'RealT   18983 Alcoy Ave  Detroit  MI 48',
            'address': '0xaD91999F534F4075B00bA4231C018e57bdeBb342',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16619/thumb/18983-Alcoy-hero-1.jpg?1624550570',
            'coingeckoId': '18983-alcoy',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'COWx',
            'name': 'Superfluid COW',
            'address': '0xaC945dC430ceF2BB8FB78B3cb5fbC936dD2C8ab6',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://static.debank.com/image/xdai_token/logo_url/0x177127622c4a00f3d409b75571e12cb3c8973d3c/01b4a8c7bfa381d0f47d140d58d06d54.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'APY',
            'name': 'APY Governance Token on xDai',
            'address': '0xaC3b8D827b59fa9109f54034CAECaA4c53A6E106',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SWM',
            'name': 'SWARM on xDai',
            'address': '0xaB57C72BFe106E6D4f8D82BC8CAD9614cdd890Fa',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SOON',
            'name': 'RealT SOON Token',
            'address': '0xaA2C0cf54cB418eB24E7e09053B82C875C68bb88',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://tokens.1inch.io/0xaa2c0cf54cb418eb24e7e09053b82c875c68bb88.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'PROPHET',
            'name': 'prophet.finance on xDai',
            'address': '0xa9e5cd4Efc86c01FaE9a9Fcd6E8669B97c92a937',
            'decimals': 9,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xa9e5cd4Efc86c01FaE9a9Fcd6E8669B97c92a937/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ACx',
            'name': 'Superfluid AC',
            'address': '0xa94c0e84B8f27DeB34F50001D47d870B67E47466',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://adept.camp/wp-content/uploads/2020/11/cropped-comingoutofblack.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BTCBR',
            'name': 'Bitcoin BR',
            'address': '0xa899e4476FF5dA39Dcd9DDEC7a6e2C651d8614Bf',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/21205/thumb/btcbr.png?1661666691',
            'coingeckoId': 'bitcoin-br',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'REALTOKEN-15634-LIBERAL-ST-DETROIT-MI',
            'name': 'RealToken S 15634 Liberal St Detroit MI',
            'address': '0xa81F77E8988b28fB74243B907acE3c83353Dc80a',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://api.rubic.exchange/assets/xdai/0xa81f77e8988b28fb74243b907ace3c83353dc80a/logo.png',
            'coingeckoId': '15634-liberal',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'REALT-S-9166-DEVONS',
            'name': 'RealT   9166 Devonshire Rd  Detroit MI ',
            'address': '0xa69D7d4DdF397f3D1E7EbAf108555d1107b3B117',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16690/thumb/Devonshire-3x.jpeg?1624636640',
            'coingeckoId': '9166-devonshire',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'REALT-S-9920-BISHOP',
            'name': 'RealT   9920 Bishop St  Detroit  MI 482',
            'address': '0xa2b2ae397492C7ed8A4c1e751aC72D2b59947E6b',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16615/thumb/9920-Bishop-hero-1.jpg?1624549554',
            'coingeckoId': '9920-bishop',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'ASKO',
            'name': 'Askobar Network on xDai',
            'address': '0xa1F8d469309342fF99Ee6c04DaE41e67406882aA',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-5601-SWOOD-',
            'name': 'RealT   5601 S Wood St  Chicago  IL 606',
            'address': '0xa137D82197Ea4cdfd5f008A91Ba816b8324F59E1',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16605/thumb/5601-S-Wood-hero-1.jpg?1624544382',
            'coingeckoId': '5601-s-wood',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'WORKtx',
            'name': 'Superfluid WORKt',
            'address': '0xa025dF2bF4Ff47eC458AB41e89d3A9538a768EA9',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'USDP',
            'name': 'USDP Stablecoin on xDai',
            'address': '0xFe7ed09C4956f7cdb54eC4ffCB9818Db2D7025b8',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'renDOGE',
            'name': 'renDOGE on xDai',
            'address': '0xFe1Ec48605344641cF4Ba618FC4Aa5a49DB2B563',
            'decimals': 8,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-15777-ARDMO',
            'name': 'RealT   15777 Ardmore St  Detroit  MI 4',
            'address': '0xFe17C3C0B6F38cF3bD8bA872bEE7a18Ab16b43fB',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16542/thumb/15777-Ardmore-hero-2.jpg?1624371814',
            'coingeckoId': '15777-ardmore',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'JASMY',
            'name': 'JasmyCoin on xDai',
            'address': '0xFcb0320d0CE08536A58495b75bf4262E4acc04af',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DAI',
            'name': 'Dai Token on xDai',
            'address': '0xFc8B2690F66B46fEC8B3ceeb95fF4Ac35a0054BC',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xFc8B2690F66B46fEC8B3ceeb95fF4Ac35a0054BC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DHV',
            'name': 'DeHive',
            'address': '0xFbdd194376de19a88118e84E279b977f165d01b8',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/14926/thumb/logo_200x200.png?1626181831',
            'coingeckoId': 'dehive',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'GRT',
            'name': 'Graph Token on xDai',
            'address': '0xFAdc59D012Ba3c110B08A15B7755A5cb7Cbe77D7',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xFAdc59D012Ba3c110B08A15B7755A5cb7Cbe77D7/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'LOCK',
            'name': 'Meridian Network on xDai',
            'address': '0xF99efEB34aff6D3099C41605e9Ee778cAEC39317',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xF99efEB34aff6D3099C41605e9Ee778cAEC39317/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'USDP',
            'name': 'Pax Dollar from Mainnet',
            'address': '0xF992e7e717255Ee2180e6443c23e09EDeDBF7887',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ACRE',
            'name': 'Arable Protocol',
            'address': '0xF480f38C366dAaC4305dC484b2Ad7a496FF00CeA',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-1617-SAVERS',
            'name': 'RealT   1617 S Avers Ave  Chicago  IL 6',
            'address': '0xF4657AB08681214Bcb1893aa8E9C7613459250ec',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16675/thumb/1617-S-Avers-hero-1.jpg?1624628860',
            'coingeckoId': '1617-s-avers',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'REALTOKEN-S-15778-MANOR-ST-DETROIT-MI',
            'name': 'RealToken S 15778 Manor St Detroit MI',
            'address': '0xF23B80216a10E6f0c0D3b5AD5C9349e9425cAd40',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://api.rubic.exchange/assets/xdai/0xf23b80216a10e6f0c0d3b5ad5c9349e9425cad40/logo.png',
            'coingeckoId': '15778-manor',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'REALT-S-25097-ANDOV',
            'name': 'RealT   25097 Andover Dr  Dearborn Heig',
            'address': '0xF18CfFB528eca0ea31D1d6b28BC80d2eca34D14D',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16698/thumb/Andover-25097.jpeg?1624641026',
            'coingeckoId': '25097-andover',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'MNTO',
            'name': 'Minato from Mainnet',
            'address': '0xEfE7F404957bcC40E8551965FBff6aBc895a51b0',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-20200-LESUR',
            'name': 'RealT   20200 Lesure St  Detroit  MI 48',
            'address': '0xEeDc2F5F4D1226759B1AcF9EfA23a99661De6663',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16702/thumb/20200-Lesure-003.jpeg?1624643190',
            'coingeckoId': '20200-lesure',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'REALT-S-12334-LANSD',
            'name': 'RealT   12334 Lansdowne Street  Detroit',
            'address': '0xEe2f2212a64Ec3f6BC0F7580e10c53CB38B57508',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16692/thumb/Landsdowne-hero.jpeg?1624638212',
            'coingeckoId': '12334-lansdowne',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'TRAC',
            'name': 'Trace Token on xDai',
            'address': '0xEddd81E0792E764501AaE206EB432399a0268DB5',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xEddd81E0792E764501AaE206EB432399a0268DB5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CHEEMS',
            'name': 'Cheemscoin',
            'address': '0xEaF7B3376173DF8BC0C22Ad6126943cC8353C1Ee',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xEaF7B3376173DF8BC0C22Ad6126943cC8353C1Ee/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BNS',
            'name': 'BNS Token on xDai',
            'address': '0xEC84A3bB48D70553C2599AC2d0Db07b2DFdF6364',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xEC84A3bB48D70553C2599AC2d0Db07b2DFdF6364/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SWAP',
            'name': 'TrustSwap Token on xDai',
            'address': '0xEAaccE3E5bCC10FB32c2553f8d6Fc4C3888ffDaD',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xEAaccE3E5bCC10FB32c2553f8d6Fc4C3888ffDaD/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MAI',
            'name': 'Mai Stablecoin from Mainnet',
            'address': '0xE959db3c04376B017b37C95618bBaEB59F51AbBA',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SKL',
            'name': 'SKALE from Mainnet',
            'address': '0xE7f0CFc2043b8872F35DbeF4ebF6eea41a8B2bBe',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MESH',
            'name': 'BLOCKMESH',
            'address': '0xE7EF58d8180Cc269C6620dED3E6cc536A52E2ebD',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xE7EF58d8180Cc269C6620dED3E6cc536A52E2ebD/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALT-S-4852-4854-W',
            'name': 'RealT   4852 4854 W Cortez St  Chicago ',
            'address': '0xE7B6dE709fFC3BD237c2f2c800E1002F97A760F3',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16670/thumb/Cortez-facade-1.jpg?1624627398',
            'coingeckoId': '4852-4854-w-cortez',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'INJ',
            'name': 'Injective Token on xDai',
            'address': '0xE73d646157765f8b8B8f28506Df0c99178256Fb9',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BZRX',
            'name': 'BZRX',
            'address': '0xE6A1f98b0F4368559BD16639C844510f5DB6Fe48',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xE6A1f98b0F4368559BD16639C844510f5DB6Fe48/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALTOKEN-S-10616-McKINNEY-ST-DETROIT-MI',
            'name': 'RealToken S 10616 McKinney St Detroit MI',
            'address': '0xE5Ce63AC9a08c1EB160889151cD84855F16C94d2',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://api.rubic.exchange/assets/xdai/0xe5ce63ac9a08c1eb160889151cd84855f16c94d2/logo.png',
            'coingeckoId': '10616-mckinney',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DATA',
            'name': 'Streamr DATA on xDai',
            'address': '0xE4a2620edE1058D61BEe5F45F6414314fdf10548',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xE4a2620edE1058D61BEe5F45F6414314fdf10548/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'LINK',
            'name': 'Chainlink',
            'address': '0xE2e73A1c69ecF83F464EFCE6A5be353a37cA09b2',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://tokens.1inch.io/0x514910771af9ca656af840dff83e8264ecf986ca.png',
            'coingeckoId': 'chainlink',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALT-S-402-SKOSTNE',
            'name': 'RealT   402 S Kostner Ave  Chicago  IL ',
            'address': '0xE255cAF8893382465368b8e1cd4Ef8436ACf0ADE',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16627/thumb/402-S-Kostner-hero-1.jpg?1624553033',
            'coingeckoId': '402-s-kostner',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'oELK',
            'name': 'Old Elk',
            'address': '0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'HOGE',
            'name': 'hoge.finance on xDai',
            'address': '0xDfF7fcF6a86F7Dc86E7facECA502851f82a349A6',
            'decimals': 9,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'COMP',
            'name': 'Compound on xDai',
            'address': '0xDf6FF92bfDC1e8bE45177DC1f4845d391D3ad8fD',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xDf6FF92bfDC1e8bE45177DC1f4845d391D3ad8fD/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALT-S-11957-OLGA-',
            'name': 'RealT   11957 Olga St  Detroit  MI 4821',
            'address': '0xDd833D0eEF6d5d7Cec781b03c19f3B425F3039Df',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16672/thumb/11957-Olga-hero-1.jpg?1624628071',
            'coingeckoId': '11957-olga',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'AAVE',
            'name': 'Aave Token on xDai',
            'address': '0xDF613aF6B44a31299E48131e9347F034347E2F00',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xDF613aF6B44a31299E48131e9347F034347E2F00/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USD Coin',
            'address': '0xDDAfbb505ad214D7b80b1f830fcCc89B60fb7A83',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://tokens.1inch.io/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png',
            'coingeckoId': 'usd-coin',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'RSV',
            'name': 'Reserve on xDai',
            'address': '0xD9C31db155a48f3d7304De85EC7AB7B705659bE9',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FLX',
            'name': 'Flex Ungovernance Token from Mainnet',
            'address': '0xD87eaA26dCfB0C0A6160cCf8c8a01BEB1C15fB00',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PSP',
            'name': 'ParaSwap from Mainnet',
            'address': '0xD6Dcb8DD30c79b588F0A8FAC8e2dDcFea4D5f6d1',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-10629-MCKIN',
            'name': 'RealT   10629 McKinney St  Detroit  MI ',
            'address': '0xD5d1adf54Fbf73a00b197DdCfDAD7cc27D93002f',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16611/thumb/10629-McKinney-hero-1.jpg?1624545790',
            'coingeckoId': '10629-mckinney',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'BUSD',
            'name': 'Binance USD on xDai',
            'address': '0xD5Fe5f651dDe69F6FC444d123f2C0CFB804542Cd',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-11078-LONGV',
            'name': 'RealT   11078 Longview St  Detroit  MI ',
            'address': '0xD5Fc0C4C4C5ff316e1E91494d963Ff1D52Ba25fF',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16630/thumb/11078-Longview-hero-2.5.jpg?1624553559',
            'coingeckoId': '11078-longview',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'AMIS',
            'name': 'AMIS',
            'address': '0xD51e1ddD116fFF9A71C1B8FEEb58113aFa2B4d93',
            'decimals': 9,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xD51e1ddD116fFF9A71C1B8FEEb58113aFa2B4d93/logo.png',
            'coingeckoId': 'amis',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'KEYFI',
            'name': 'KeyFi Token on xDai',
            'address': '0xD3F899283C8203A87C6c1E0e017e3A641E2CC1cD',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DPI',
            'name': 'DeFi Pulse Index',
            'address': '0xD3D47d5578e55C880505dC40648F7F9307C3e7A8',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://tokens.1inch.io/0x1494ca1f11d487c2bbe4543e90080aeba4ba3c2b.png',
            'coingeckoId': 'defipulse-index',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ESR',
            'name': 'EsportsRef',
            'address': '0xD3226B12e6188133b19Ac0419f34B0ed5B10f069',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xD3226B12e6188133b19Ac0419f34B0ed5B10f069/logo.png',
            'coingeckoId': 'esportsref',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GEO',
            'name': 'GeoDB Coin on xDai',
            'address': '0xD2fc023851D1cdd6cDfa2C29f90512E89E02D606',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AKRO',
            'name': 'Akropolis on xDai',
            'address': '0xD27E1ECC4748F42e052331BeA917D89bEB883fc3',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xD27E1ECC4748F42e052331BeA917D89bEB883fc3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALT-S-10612-SOMER',
            'name': 'RealT   10612 Somerset Ave  Detroit  MI',
            'address': '0xD1c15CEbfDcd16F00D91666bf64C8b66CbF5e9b5',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16680/thumb/Somerset-3.jpeg?1624630432',
            'coingeckoId': '10612-somerset',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'TKN',
            'name': 'Monolith TKN on xDai',
            'address': '0xD1B11356464Ac5B48172fa6bD14Ac2417631BEDa',
            'decimals': 8,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USD Coin on xDai',
            'address': '0xD10Cc63531a514BBa7789682E487Add1f15A51E2',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xD10Cc63531a514BBa7789682E487Add1f15A51E2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALT-S-6923-GREENV',
            'name': 'RealT   6923 Greenview Ave  Detroit  MI',
            'address': '0xD08d2b199E9E5df407427d4085877d1fDFf3b1d6',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16676/thumb/6923-Greenview.jpeg?1624629085',
            'coingeckoId': '6923-greenview',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'HOPR',
            'name': 'HOPR Token on xDai',
            'address': '0xD057604A14982FE8D88c5fC25Aac3267eA142a08',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xD057604A14982FE8D88c5fC25Aac3267eA142a08/logo.png',
            'coingeckoId': 'hopr',
            'listedIn': [
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WBNB',
            'name': 'Wrapped BNB on xDai',
            'address': '0xCa8d20f3e0144a72C6B5d576e9Bd3Fd8557E2B04',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xCa8d20f3e0144a72C6B5d576e9Bd3Fd8557E2B04/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'TDL',
            'name': 'TDL Fake Token',
            'address': '0xCD1F2F1a1d1ba631A06b957DB77BB9D7b13bF861',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'JRT',
            'name': 'Jarvis Reward Token on xDai',
            'address': '0xCCF1279C3406Ad1181bee00daB4b28B23D17Ddb1',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WORKt',
            'name': 'Commons Work Test',
            'address': '0xC8992645D060703fb1582c422c96e16941c7eb14',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AMPL',
            'name': 'Ampleforth on xDai',
            'address': '0xC84DD5B971521B6C9fA5E10d25E6428b19710e05',
            'decimals': 9,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xC84DD5B971521B6C9fA5E10d25E6428b19710e05/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALTOKEN-S-19218-HOUGHTON-ST-DETROIT-MI',
            'name': 'RealToken S 19218 Houghton St Detroit MI',
            'address': '0xC731EcA970979Cd2Da2a1094A808F49894070D35',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://api.rubic.exchange/assets/xdai/0xc731eca970979cd2da2a1094a808f49894070d35/logo.png',
            'coingeckoId': '19218-houghton',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BAT',
            'name': 'Basic Attention Token on xDai',
            'address': '0xC6cC63f4AA25BBD4453eB5F3a0DfE546feF9b2f3',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xC6cC63f4AA25BBD4453eB5F3a0DfE546feF9b2f3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MCDC',
            'name': 'McDonaldsCoin on xDai',
            'address': '0xC577cDdABB7893cC2cA15eF4b5D5e5E13c3FeeD3',
            'decimals': 2,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xC577cDdABB7893cC2cA15eF4b5D5e5E13c3FeeD3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SYMM',
            'name': 'Symmetric',
            'address': '0xC45b3C1c24d5F54E7a2cF288ac668c74Dd507a84',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xC45b3C1c24d5F54E7a2cF288ac668c74Dd507a84/logo.png',
            'coingeckoId': 'symmetric',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MXX',
            'name': 'Multiplier on xDai',
            'address': '0xC2d20e5b9F4A9b9046169a731dbdE97De2643fb8',
            'decimals': 8,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'XGT',
            'name': 'Xion Finance',
            'address': '0xC25AF3123d2420054c8fcd144c21113aa2853F39',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xC25AF3123d2420054c8fcd144c21113aa2853F39/logo.png',
            'coingeckoId': 'xion-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'STORJ',
            'name': 'StorjToken on xDai',
            'address': '0xBc650b9cC12dB4da14b2417c60CCd6F4d77c3998',
            'decimals': 8,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xBc650b9cC12dB4da14b2417c60CCd6F4d77c3998/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'AXS',
            'name': 'Axie Infinity',
            'address': '0xBDE011911128F6bD4ABb1d18F39fdc3614Ca2cfe',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xBDE011911128F6bD4ABb1d18F39fdc3614Ca2cfe/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALT-S-18776-SUNDE',
            'name': 'RealT   18776 Sunderland Rd  Detroit  M',
            'address': '0xBA07997F594A52DF179620284B52B50A4E66227D',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16602/thumb/18776-Sunderland-hero-1.jpg?1624543588',
            'coingeckoId': '18776-sunderland',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'TECH',
            'name': '0xF3.tech',
            'address': '0xB7D918D7631fCDD0954205e3A6B205a10a31A085',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xB7D918D7631fCDD0954205e3A6B205a10a31A085/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'TUSD',
            'name': 'TrueUSD on xDai',
            'address': '0xB714654e905eDad1CA1940b7790A8239ece5A9ff',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xB714654e905eDad1CA1940b7790A8239ece5A9ff/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PRTCLE',
            'name': 'Particle',
            'address': '0xB5d592f85ab2D955c25720EbE6FF8D4d1E1Be300',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xB5d592f85ab2D955c25720EbE6FF8D4d1E1Be300/logo.png',
            'coingeckoId': 'particle-2',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALTOKEN-10024-APPOLINE-ST-DETROIT-MI',
            'name': 'RealToken S 10024-10028 Appoline St Detroit MI',
            'address': '0xB5D30c28F87acf675Ed5B9f343E5ffF39eC9942C',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://api.rubic.exchange/assets/xdai/0xb5d30c28f87acf675ed5b9f343e5fff39ec9942c/logo.png',
            'coingeckoId': '10024-10028-appoline',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MET',
            'name': 'Metronome on xDai',
            'address': '0xB4B6f80d8E573e9867c90163BFdb00E29d92716a',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xB4B6f80d8E573e9867c90163BFdb00E29d92716a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALT-S-15796-HARTW',
            'name': 'RealT   15796 Hartwell St  Detroit  MI ',
            'address': '0xB3D3C1bBcEf737204AADb4fA6D90e974bc262197',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16607/thumb/15796-Hartwell-hero-1.jpeg?1624545151',
            'coingeckoId': '15796-hartwell',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'BTCCB',
            'name': 'BTCCB',
            'address': '0xB2aE7983A8142401d45546aAb981e5fbff520991',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xB2aE7983A8142401d45546aAb981e5fbff520991/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'sUSD',
            'name': 'Synth sUSD on xDai',
            'address': '0xB1950Fb2C9C0CbC8553578c67dB52Aa110A93393',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xB1950Fb2C9C0CbC8553578c67dB52Aa110A93393/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ETHO',
            'name': 'Etho Protocol',
            'address': '0xB17d999E840e0c1B157Ca5Ab8039Bd958b5fA317',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xB17d999E840e0c1B157Ca5Ab8039Bd958b5fA317/logo.png',
            'coingeckoId': 'ether-1',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MATH',
            'name': 'MATH Token on xDai',
            'address': '0xAf4D17A2077e1dE12DE66a44DE1B4f14C120d32D',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'HAKKA',
            'name': 'Hakka Finance on xDai',
            'address': '0xAD601530859513371fA107Ae6a7E18e08d69F155',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'UMB',
            'name': 'Umbrella on xDai',
            'address': '0xACB7AB752072c1b2a5448672a12c124a532954De',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TRB',
            'name': 'Tellor Tributes from Mainnet',
            'address': '0xAAd66432d27737ecf6ED183160Adc5eF36aB99f2',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-15860-HARTW',
            'name': 'RealT   15860 Hartwell St  Detroit  MI ',
            'address': '0xA9F30C907321718e655B74463CA665B690B78894',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16595/thumb/15860-Hartwell-hero-1.jpg?1624540454',
            'coingeckoId': '15860-hartwell',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'DNT',
            'name': 'district0x Network Token on xDai',
            'address': '0xA83ecA53705f21A99E9DE9eEdDdF2d1D9a5593c4',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REALTOKEN-S-4340-EAST-71-CLEVELAND-OH',
            'name': 'RealToken S 4338-4340 East 71 Cleveland OH',
            'address': '0xA68b7779504b0AE372ddCC109f8786DB9B91e93e',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://api.rubic.exchange/assets/xdai/0xa68b7779504b0ae372ddcc109f8786db9b91e93e/logo.png',
            'coingeckoId': '4340-east-71',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'sGNO',
            'name': 'StakeWise Staked GNO',
            'address': '0xA4eF9Da5BA71Cc0D2e5E877a910A37eC43420445',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://api.rubic.exchange/assets/xdai/0xa4ef9da5ba71cc0d2e5e877a910a37ec43420445/logo.png',
            'coingeckoId': 'stakewise-staked-gno',
            'listedIn': [
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'YLD',
            'name': 'Yield on xDai',
            'address': '0xA2FEc95B3d3feCb39098E81f108533E1abF22CcF',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xA2FEc95B3d3feCb39098E81f108533E1abF22CcF/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALT-S-581-587-JEF',
            'name': 'RealT   581 587 Jefferson Ave  Rocheste',
            'address': '0xA29ae272bC89e5f315B2793925f700045F845d82',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16637/thumb/571-jefferson-corner-1.1.jpg?1624557964',
            'coingeckoId': '581-587-jefferson',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'FREE',
            'name': 'FreeToken',
            'address': '0xA106739De31FA7a9DF4a93c9bea3E1bAdE0924e2',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0xA106739De31FA7a9DF4a93c9bea3E1bAdE0924e2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MPH',
            'name': '88mph.app on xDai',
            'address': '0xA088D78e9c9CBccAD3a0153341385633B1B1125d',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FRY',
            'name': 'Foundry Logistics Token on xDai',
            'address': '0x9fac2d280690C59083943D6A2641399a9019787C',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'JEUR',
            'name': 'Jarvis Synthetic Euro',
            'address': '0x9fB1d52596c44603198fB0aee434fac3a679f702',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/15725/thumb/jEUR.png?1634046044',
            'coingeckoId': 'jarvis-synthetic-euro',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'ETHM',
            'name': 'Ethereum Meta on xDai',
            'address': '0x9bD5E0ce813d5172859b0b70Ff7Bb3C325CEE913',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x9bD5E0ce813d5172859b0b70Ff7Bb3C325CEE913/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALT-S-11300-ROXBU',
            'name': 'RealT   11300 Roxbury St  Detroit  MI 4',
            'address': '0x9b5B4886033B4ABc5Eb0552ffF7c15A67C3c47C7',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16610/thumb/11300-Roxbury-hero-1.jpg?1624545386',
            'coingeckoId': '11300-roxbury',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': '⚗️',
            'name': 'Alchemist on xDai',
            'address': '0x9b388743bdD602Ed922E88d5C3C607c1585Ecf51',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BNT',
            'name': 'Bancor Network Token on xDai',
            'address': '0x9a495a281D959192343B0e007284bf130bd05F86',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x9a495a281D959192343B0e007284bf130bd05F86/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALT-S-15095-HARTW',
            'name': 'RealT  15095 Hartwell St  Detroit  MI 4',
            'address': '0x9FEF44FC4C571010BCCD5b63e1Cdc807D3b347bF',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16664/thumb/15095-Hartwell-hero-1.jpeg?1624625590',
            'coingeckoId': '15095-hartwell',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'REALT-S-13114-GLENF',
            'name': 'RealT   13114 Glenfield Ave  Detroit  M',
            'address': '0x9F923653A19537b5a1b003854A1920fe67a8ffEB',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16678/thumb/13114-Glenfield-Ave.jpeg?1624629523',
            'coingeckoId': '13114-glenfield',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'REALT-S-18900-MANSF',
            'name': 'RealT   18900 Mansfield St  Detroit  MI',
            'address': '0x9Eb90Ec3FAafC22092C9b91559FdDde538042093',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16697/thumb/18900-Mansfield-flowers.jpeg?1624640555',
            'coingeckoId': '18900-mansfield',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'EURS',
            'name': 'STASIS EURS Token on xDai',
            'address': '0x9EE40742182707467f78344F6b287bE8704F27E2',
            'decimals': 2,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-12405-SANTA',
            'name': 'RealT   12405 Santa Rosa Dr  Detroit  M',
            'address': '0x9D918eE39a356bE8eF99734599c7e70160dB4Db6',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16671/thumb/12405-Santa-Rosa-hero-1.jpg?1624627801',
            'coingeckoId': '12405-santa-rosa',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'protoWORK',
            'name': 'Commons protoWORK on xDai',
            'address': '0x9C940356F5846ecefF282D3ee8b33015d93502a9',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'GNO',
            'name': 'Gnosis',
            'address': '0x9C58BAcC331c9aa871AFD802DB6379a98e80CEdb',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://tokens.1inch.io/0x6810e776880c02933d47db1b9fc05908e5386b96.png',
            'coingeckoId': 'gnosis',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALTOKEN-S-18466-FIELDING-ST-DETROIT-MI',
            'name': 'RealToken S 18466 Fielding St Detroit MI',
            'address': '0x9A99f283e1F6c3b7F24901995624Ef7b78E94471',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://api.rubic.exchange/assets/xdai/0x9a99f283e1f6c3b7f24901995624ef7b78e94471/logo.png',
            'coingeckoId': '18466-fielding',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PUNK',
            'name': 'Punk from Mainnet',
            'address': '0x988d1Be68F2C5cDE2516a2287c59Bd6302b7D20D',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x988d1Be68F2C5cDE2516a2287c59Bd6302b7D20D/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'POA20',
            'name': 'POA20 on xDai',
            'address': '0x985e144EB355273c4B4D51E448B68b657F482E26',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x985e144EB355273c4B4D51E448B68b657F482E26/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALT-S-18273-MONTE',
            'name': 'RealT   18273 Monte Vista St  Detroit  ',
            'address': '0x9856C5CA15A4Ac9C65AAC090c38a9f39EB3b5eeC',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16665/thumb/18273-Monte-Vista-hero-1b.jpeg?1624626435',
            'coingeckoId': '18273-monte-vista',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'PAN',
            'name': 'Panvala Pan',
            'address': '0x981fB9BA94078a2275A8fc906898ea107B9462A8',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://tokens.1inch.io/0xd56dac73a4d6766464b38ec6d91eb45ce7457c44.png',
            'coingeckoId': 'panvala-pan',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'RICE',
            'name': 'DAOSquare',
            'address': '0x97Edc0e345FbBBd8460847Fcfa3bc2a13bF8641F',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://daodkp.oss-ap-southeast-1.aliyuncs.com/assets/logo-sm.png',
            'coingeckoId': 'daosquare',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'HIVESHARE',
            'name': 'HiveShares from Ethereum',
            'address': '0x97E4ebc14C117C1ac2D032a5A8140C84628b0d17',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x97E4ebc14C117C1ac2D032a5A8140C84628b0d17/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'TORN',
            'name': 'TornadoCash from Mainnet',
            'address': '0x97699C8AE5Bf3e6783Da14b291085f760a917924',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ETHx',
            'name': 'Superfluid WETH',
            'address': '0x9757d68A4273635c69D93B84ee3cdac2304DD467',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://static.debank.com/image/xdai_token/logo_url/0x6a023ccd1ff6f2045c3309768ead9e68f978f6e1/61844453e63cf81301f845d7864236f6.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'LDO',
            'name': 'Lido DAO Token on xDai',
            'address': '0x96e334926454CD4B7b4efb8a8fcb650a738aD244',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REALTOKEN-S-12409-WHITEHILL-ST-DETROIT-MI',
            'name': 'RealToken S 12409 Whitehill St Detroit MI',
            'address': '0x969d42ad7008e6651E1Fd52742153F8743225D98',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://api.rubic.exchange/assets/xdai/0x969d42ad7008e6651e1fd52742153f8743225d98/logo.png',
            'coingeckoId': '12409-whitehill',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'REALT-S-4380-BEACON',
            'name': 'RealT   4380 Beaconsfield St  Detroit  ',
            'address': '0x96700Ffae33c651bC329c3f3fbFE56e1f291f117',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16603/thumb/4380-Beaconsfield-hero-1.jpeg?1624543909',
            'coingeckoId': '4380-beaconsfield',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'REALT-S-14231-STRAT',
            'name': 'RealT   14231 Strathmoor St  Detroit  M',
            'address': '0x9528a7402C0Fe85B817aa6E106EAFa03A02924c4',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16563/thumb/14231-Strathmoor-hero-1.jpg?1624459471',
            'coingeckoId': '14231-strathmoor',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'REALT-S-1244-SAVERS',
            'name': 'RealT   1244 S  Avers St  Chicago  IL 6',
            'address': '0x94Fa7F8cb8453AD57cd133363b3012044647078C',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16634/thumb/1244-S-Avers-hero-3.jpeg?1624555551',
            'coingeckoId': '1244-s-avers',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'NDX',
            'name': 'Indexed on xDai',
            'address': '0x944D62470dd34B0c6B332E1807dcAF8D826f9277',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REALTOKEN-S-8181-BLISS-ST-DETROIT-MI',
            'name': 'RealToken S 8181 Bliss St Detroit MI',
            'address': '0x92d31E19F88597F368825ba16410f263a844527A',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://api.rubic.exchange/assets/xdai/0x92d31e19f88597f368825ba16410f263a844527a/logo.png',
            'coingeckoId': '8181-bliss',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'REALT-S-10084-GRAYT',
            'name': 'RealT   10084 Grayton St  Detroit  MI 4',
            'address': '0x92161385C9dE8798ad5fB01c0bE99fFcbC84dFD8',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16693/thumb/Grayton_84_side1x.jpeg?1624638633',
            'coingeckoId': '10084-grayton',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'RLY',
            'name': 'Rally from Mainnet',
            'address': '0x91c22F57Df810D541239fbB262AfB36Cef2814C5',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'KP4R',
            'name': 'Keep4r on xDai',
            'address': '0x918889aBFab8DC54DD665B8CdF5EE2562DeC0B04',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-1815-SAVERS',
            'name': 'RealT   1815 S Avers Ave  Chicago  IL 6',
            'address': '0x8fcB39A25e639C8fbD28E8a018227D6570E02352',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16674/thumb/1815-S-Avers-hero-1.5.jpg?1624628519',
            'coingeckoId': '1815-s-avers',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'LAYER',
            'name': 'Unilayer on xDai',
            'address': '0x8fBEDD16904B561e30ea402F459900E9D90614af',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x8fBEDD16904B561e30ea402F459900E9D90614af/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WBTC',
            'name': 'Wrapped Bitcoin',
            'address': '0x8e5bBbb09Ed1ebdE8674Cda39A0c169401db4252',
            'decimals': 8,
            'chainId': 100,
            'logoURI': 'https://tokens.1inch.io/0x2260fac5e5542a773aa44fbcfedf7c193bc2c599.png',
            'coingeckoId': 'wrapped-bitcoin',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NFTX',
            'name': 'NFTX on xDai',
            'address': '0x8e1A12dA00BBF9DB10d48bd66Ff818bE933964d5',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': '0XMR',
            'name': '0xMonero',
            'address': '0x8c88eA1fD60462Ef7004B9e288afCB4680a3c50c',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x8c88eA1fD60462Ef7004B9e288afCB4680a3c50c/logo.png',
            'coingeckoId': '0xmonero',
            'listedIn': [
                'coingecko',
                'openocean',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'COVAL',
            'name': 'Circuits of Value',
            'address': '0x8b8407c6184f1f0Fd1082e83d6A3b8349cAcEd12',
            'decimals': 8,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/588/thumb/coval-logo.png?1599493950',
            'coingeckoId': 'circuits-of-value',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'REALTOKEN-S-19311-KEYSTONE-ST-DETROIT-MI',
            'name': 'RealToken S 19311 Keystone St Detroit MI',
            'address': '0x8a9F904B4EaD6a97F3aB304d0D2196f5c602c807',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://api.rubic.exchange/assets/xdai/0x8a9f904b4ead6a97f3ab304d0d2196f5c602c807/logo.png',
            'coingeckoId': '19311-keystone',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SETH',
            'name': 'Synth sETH on xDai',
            'address': '0x8F365b41B98Fe84aCB287540b4B4AB633e07EDb2',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x8F365b41B98Fe84aCB287540b4B4AB633e07EDb2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DUCK',
            'name': 'Unit Protocol',
            'address': '0x8E7aB03cA7D17996b097D5866bFAA1e251c35c6a',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x8E7aB03cA7D17996b097D5866bFAA1e251c35c6a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALT-S-15753-HARTW',
            'name': 'RealT   15753 Hartwell St  Detroit  MI ',
            'address': '0x8D1090dF790FFAFdACCda03015c05dF3b4cC9c21',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16623/thumb/15753-Hartwell-hero-2.jpg?1624551749',
            'coingeckoId': '15753-hartwell',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'ROBOT',
            'name': 'MetaFactory on xDai',
            'address': '0x8D02b73904856De6998Ffdf6e7ee18cC21137a79',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x8D02b73904856De6998Ffdf6e7ee18cC21137a79/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'AUDIO',
            'name': 'Audius on xDai',
            'address': '0x8A95ea379E1Fa4C749dd0A7A21377162028C479e',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x8A95ea379E1Fa4C749dd0A7A21377162028C479e/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MM',
            'name': 'Million on xDai',
            'address': '0x896B192C84e5d042F78d0D33e39e2E8c0c4c9008',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REP',
            'name': 'Reputation on xDai',
            'address': '0x874623A3e613b43Efa4dcC2CB04a03Da1442Db6C',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ATRI',
            'name': 'AtariToken on xDai',
            'address': '0x87038E66b40F7d1fb1aA6c957a266037aD5C867c',
            'decimals': 0,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DEP',
            'name': 'DEAPCOIN on xDai',
            'address': '0x86567281a326d8c8DD92427c8f3C33f83Ac8E9a3',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-10604-SOMER',
            'name': 'RealT   10604 Somerset Ave  Detroit  MI',
            'address': '0x8626B38267e4FC0D8C92E0bB86F97Acab3f6AA05',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16682/thumb/10604-Somerset-hero-1.jpeg?1624632454',
            'coingeckoId': '10604-somerset',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'TRADE',
            'name': 'UniTrade on xDai',
            'address': '0x860182180e146300dF38aab8d328C6E80BEC9547',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x860182180e146300dF38aab8d328C6E80BEC9547/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'UBI',
            'name': 'Universal Basic Income on xDai',
            'address': '0x855888B91BA0A7d6ae9C801868211EAcF38b4019',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SWASH',
            'name': 'Swash',
            'address': '0x84E2C67CBEfae6B5148fcA7d02B341B12ff4b5Bb',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/18774/thumb/swash.png?1634089759',
            'coingeckoId': 'swash',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'HND',
            'name': 'Hundred Finance from Mainnet',
            'address': '0x848c5DF3137818C35A55A21e368E9C894fEb79Fd',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SAVEx',
            'name': 'Superfluid SAVE',
            'address': '0x83cCC929C1131d2d18b001C05CFeED9f3364a506',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BRIGHT',
            'name': 'BrightID',
            'address': '0x83FF60E2f93F8eDD0637Ef669C69D5Fb4f64cA8E',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x83FF60E2f93F8eDD0637Ef669C69D5Fb4f64cA8E/logo.png',
            'coingeckoId': 'bright-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'OMG',
            'name': 'OMGToken on xDai',
            'address': '0x8395F7123ba3FFAD52E7414433D825931C81C879',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x8395F7123ba3FFAD52E7414433D825931C81C879/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALT-S-19201-WESTP',
            'name': 'RealT   19201 Westphalia St  Detroit  M',
            'address': '0x830B0e9a5ecf36D0A886D21e1C20043cD2d16515',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16613/thumb/19201-Westphalia-hero-1.jpeg?1624547689',
            'coingeckoId': '19201-westphalia',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'BAO',
            'name': 'BaoToken on xDai',
            'address': '0x82dFe19164729949fD66Da1a37BC70dD6c4746ce',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://tokens.1inch.io/0x374cb8c27130e2c9e04f44303f3c8351b9de61c1.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'sushiswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALTOKEN-S-9169-BOLEYN-ST-DETROIT-MI',
            'name': 'RealToken S 9169 Boleyn St Detroit MI',
            'address': '0x806690B7a093d2Cf6419a515ABEdb7f28595bc5E',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://api.rubic.exchange/assets/xdai/0x806690b7a093d2cf6419a515abedb7f28595bc5e/logo.png',
            'coingeckoId': '9169-boleyn',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'REALTOKEN-S-13606-WINTHROP-ST-DETROIT-MI',
            'name': 'RealToken S 13606 Winthrop St Detroit MI',
            'address': '0x804f6bAa10615C85E4B4a5Bc4eFe516d9f7a4365',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://api.rubic.exchange/assets/xdai/0x804f6baa10615c85e4b4a5bc4efe516d9f7a4365/logo.png',
            'coingeckoId': '13606-winthrop',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'REALTOKEN-S-15784-MONTEVISTA-ST-DETROIT-MI',
            'name': 'RealToken S 15784 Monte Vista St Detroit MI',
            'address': '0x7f940B5509a22e81D29167581bdEea3Fa5a0abEE',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://api.rubic.exchange/assets/xdai/0x7f940b5509a22e81d29167581bdeea3fa5a0abee/logo.png',
            'coingeckoId': '15784-monte-vista',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': '1INCH',
            'name': '1INCH Token on xDai',
            'address': '0x7f7440C5098462f833E123B44B8A03E1d9785BAb',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x7f7440C5098462f833E123B44B8A03E1d9785BAb/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PERP',
            'name': 'Perpetual on xDai',
            'address': '0x7ecF26cd9A36990b8ea477853663092333f59979',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x7ecF26cd9A36990b8ea477853663092333f59979/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BAL',
            'name': 'Balancer on xDai',
            'address': '0x7eF541E2a22058048904fE5744f9c7E4C57AF717',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x7eF541E2a22058048904fE5744f9c7E4C57AF717/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PHA',
            'name': 'Phala on xDai',
            'address': '0x7eA8aF7301b763451B7FB25F8Fc2406819A7E36f',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x7eA8aF7301b763451B7FB25F8Fc2406819A7E36f/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SUKU',
            'name': 'SUKU on xDai',
            'address': '0x7e40559c80e0512E75fbA5E0CE80fC4D54174BB4',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ENG',
            'name': 'Enigma on xDai',
            'address': '0x7a7d81657A1A66b38a6cA2565433A9873C6913B2',
            'decimals': 8,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x7a7d81657A1A66b38a6cA2565433A9873C6913B2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALT-S-15039-WARD-',
            'name': 'RealT   15039 Ward Ave  Detroit  MI 482',
            'address': '0x7E95b310724334FF74537dc08bfD3377d25E65Ce',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16632/thumb/15039-Ward-hero-1.jpeg?1624554456',
            'coingeckoId': '15039-ward',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'CVP',
            'name': 'Concentrated Voting Power on xDai',
            'address': '0x7Da0bFE9D26C5b64c7580c04Bb1425364273E4b0',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x7Da0bFE9D26C5b64c7580c04Bb1425364273E4b0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'LPT',
            'name': 'Livepeer Token on xDai',
            'address': '0x7DB0be7A41b5395268e065776e800e27181C81AB',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x7DB0be7A41b5395268e065776e800e27181C81AB/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'RAMP',
            'name': 'RAMP DEFI on xDai',
            'address': '0x7DA55417CaC4053C0D3b6dBbd74D7F0680F41DdF',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SXP',
            'name': 'Swipe on xDai',
            'address': '0x7CC4d60a3C83e91d8c2ec2127A10Bab5c6Ab209d',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DJ15',
            'name': 'Davincij15 Token on xDai',
            'address': '0x7C16c63684D86BaCC52e8793B08a5a1A3cB1BA1e',
            'decimals': 9,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x7C16c63684D86BaCC52e8793B08a5a1A3cB1BA1e/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'Brightx',
            'name': 'Superfluid BRIGHT',
            'address': '0x7AecA73f38f8F33AB7FF067fED1268384d12324d',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://static.debank.com/image/xdai_token/logo_url/0x83ff60e2f93f8edd0637ef669c69d5fb4f64ca8e/b4e2dafcc5a12a560eafa76879de7550.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'LYXe',
            'name': 'LUKSO Token on xDai',
            'address': '0x79CF2029717E2E78C8927F65F079Ab8dA21781Ee',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x79CF2029717E2E78C8927F65F079Ab8dA21781Ee/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DATA',
            'name': 'Streamr DATAcoin on xDai',
            'address': '0x796879025A627d34042E3eDd2E239E75ba4445e6',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MANA',
            'name': 'Decentraland MANA on xDai',
            'address': '0x7838796B6802B18D7Ef58fc8B757705D6c9d12b3',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x7838796B6802B18D7Ef58fc8B757705D6c9d12b3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BDT',
            'name': 'BlackDragon Token on xDai',
            'address': '0x778aa03021B0CD2b798b0b506403e070125D81C9',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x778aa03021B0CD2b798b0b506403e070125D81C9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CHSB',
            'name': 'SwissBorg Token on xDai',
            'address': '0x76eaFffA1873a8aCd43864B66A728bd873c5E08a',
            'decimals': 8,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x76eaFffA1873a8aCd43864B66A728bd873c5E08a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALTOKEN-S-9133-DEVONSHIRE-RD-DETROIT-MI',
            'name': 'RealToken S 9133 Devonshire Rd Detroit MI',
            'address': '0x76dbEb740ecd1F3b052a9AFA302ABc7EB4Fb5390',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://api.rubic.exchange/assets/xdai/0x76dbeb740ecd1f3b052a9afa302abc7eb4fb5390/logo.png',
            'coingeckoId': '9133-devonshire',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MUSE',
            'name': 'Muse on xDai',
            'address': '0x7671Cbe4320C0772C00B5Ce157ac94b936Cb083F',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-18481-WESTP',
            'name': 'RealT   18481 Westphalia St  Detroit  M',
            'address': '0x75f06B482adbFb04b877D8ee683E2FCDf18AD153',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16633/thumb/18481-Westphalia-hero-1.jpeg?1624554981',
            'coingeckoId': '18481-westphalia',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'FTX',
            'name': 'FTT on xDai',
            'address': '0x75886F00c1a20Ec1511111Fb4Ec3C51de65B1fe7',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x75886F00c1a20Ec1511111Fb4Ec3C51de65B1fe7/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'POLS',
            'name': 'PolkastarterToken on xDai',
            'address': '0x75481A953a4bBa6B3C445907dB403E4b5D222174',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x75481A953a4bBa6B3C445907dB403E4b5D222174/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'OWL',
            'name': 'OWL Token on xDai',
            'address': '0x750eCf8c11867Ce5Dbc556592c5bb1E0C6d16538',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REALTOKEN-S-9465-BEACONSFIELD-ST-DETROIT-MI',
            'name': 'RealToken S 9465 Beaconsfield St Detroit MI',
            'address': '0x750FA12Ae51d1515c893C1aaABE2C135937a2c8B',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://api.rubic.exchange/assets/xdai/0x750fa12ae51d1515c893c1aaabe2c135937a2c8b/logo.png',
            'coingeckoId': '9465-beaconsfield',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'AST',
            'name': 'AirSwap Token on xDai',
            'address': '0x743a991365ba94BFC90Ad0002CAD433c7a33cb4a',
            'decimals': 4,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x743a991365ba94BFC90Ad0002CAD433c7a33cb4a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALTOKEN-S-9717-EVERTS-ST-DETROIT-MI',
            'name': 'RealToken S 9717 Everts St Detroit MI',
            'address': '0x73BdE888664DF8DDfD156B52e6999EEaBAB57C94',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://api.rubic.exchange/assets/xdai/0x73bde888664df8ddfd156b52e6999eeabab57c94/logo.png',
            'coingeckoId': '9717-everts',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'mUSD',
            'name': 'mStable USD on xDai',
            'address': '0x7300AaFC0Ef0d47Daeb850f8b6a1931b40aCab33',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'xSUSHI',
            'name': 'SushiBar on xDai',
            'address': '0x726f66BBdaf5DC0D66CADdc24dA13C3E9301Fc2A',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WCHI',
            'name': 'Wrapped CHI from Ethereum',
            'address': '0x7211ab649A4139561a152B787de52D257cbAAee9',
            'decimals': 8,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x7211ab649A4139561a152B787de52D257cbAAee9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HNY',
            'name': 'Honey',
            'address': '0x71850b7E9Ee3f13Ab46d67167341E4bDc905Eef9',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x71850b7E9Ee3f13Ab46d67167341E4bDc905Eef9/logo.png',
            'coingeckoId': 'honey',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CRV',
            'name': 'Curve DAO Token on xDai',
            'address': '0x712b3d230F3C1c19db860d80619288b1F0BDd0Bd',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x712b3d230F3C1c19db860d80619288b1F0BDd0Bd/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MATIC',
            'name': 'Matic Token on xDai',
            'address': '0x7122d7661c4564b7C6Cd4878B06766489a6028A2',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x7122d7661c4564b7C6Cd4878B06766489a6028A2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'UNCL',
            'name': 'UNCL',
            'address': '0x703120F2f2011a0D03A03a531Ac0e84e81F15989',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x703120F2f2011a0D03A03a531Ac0e84e81F15989/logo.png',
            'coingeckoId': 'uncl',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SPANK',
            'name': 'SPANK on xDai',
            'address': '0x6fe914fcA932Af29Fc500392D6E42387dC67eED1',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'GEAR',
            'name': 'Bitgear on xDai',
            'address': '0x6f09CF96558d44584dB07f8477Dd3490599aA63E',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x6f09CF96558d44584dB07f8477Dd3490599aA63E/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DOUGH',
            'name': 'PieDAO Dough',
            'address': '0x6d237bb2248d3b40b1a54F3417667B2f39984fC8',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x6d237bb2248d3b40b1a54F3417667B2f39984fC8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'STACK',
            'name': 'StackOS on xDai',
            'address': '0x6c01227Ee27036351b3e83458DCa4c08B9221D3E',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'RGNO',
            'name': 'StakeWise Reward GNO',
            'address': '0x6aC78efae880282396a335CA2F79863A1e6831D4',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': 'stakewise-reward-gno',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'GTON',
            'name': 'GTON CAPITAL',
            'address': '0x6aB6d61428fde76768D7b45D8BFeec19c6eF91A8',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/15728/thumb/GC_logo_200x200.png?1642669327',
            'coingeckoId': 'gton-capital',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'REALT-S-13895-SARAT',
            'name': 'RealT   13895 Saratoga St  Detroit  MI ',
            'address': '0x6F442Da588232DC57Bf0096E8dE48D6961D5CC83',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16599/thumb/13895-Saratoga-hero-1.jpeg?1624541955',
            'coingeckoId': 'realtoken-s-13895-saratoga-st-detroit-mi',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'ANTv2',
            'name': 'Aragon Network Token on xDai v2',
            'address': '0x6EECeab954EFDBd7A8a8D9387bC719959B04b9CA',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x6EECeab954EFDBd7A8a8D9387bC719959B04b9CA/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALT-S-5942-AUDUBO',
            'name': 'RealT   5942 Audubon Rd  Detroit  MI 48',
            'address': '0x6Db6d540F5614e6BaB7475Af3F430F46a0B083e2',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16699/thumb/5942-Audubon-HERO-2.jpeg?1624641512',
            'coingeckoId': '5942-audubon',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'RXDAI',
            'name': 'xdai dao',
            'address': '0x6B0f8A3Fb7cb257AD7c72ADa469Ba1D3C19C5094',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x6B0f8A3Fb7cb257AD7c72ADa469Ba1D3C19C5094/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'EWT',
            'name': 'Energy Web',
            'address': '0x6A8cb6714B1EE5b471a7D2eC4302cb4f5Ff25eC2',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x6A8cb6714B1EE5b471a7D2eC4302cb4f5Ff25eC2/logo.png',
            'coingeckoId': 'energy-web-token',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WETH',
            'name': 'WETH',
            'address': '0x6A023CCd1ff6F2045C3309768eAd9E68F978f6e1',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://tokens.1inch.io/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2.png',
            'coingeckoId': 'weth',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALTOKEN-S-1542-S.RIDGEWAY-AVE-CHICAGO-IL',
            'name': 'RealToken S 1542 S Ridgeway Ave Chicago IL',
            'address': '0x69D1B42b20f3DED07bEC322253D0140b04Cbb6F5',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://api.rubic.exchange/assets/xdai/0x69d1b42b20f3ded07bec322253d0140b04cbb6f5/logo.png',
            'coingeckoId': '1542-s-ridgeway',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BASE',
            'name': 'Base Protocol on xDai',
            'address': '0x699D001ef13B15335193bC5FAd6CFC6747eeE8BE',
            'decimals': 9,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x699D001ef13B15335193bC5FAd6CFC6747eeE8BE/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALTOKEN-S-9309-COURVILLE-ST-DETROIT-MI',
            'name': 'RealToken S 9309 Courville St Detroit MI',
            'address': '0x67a83B28F6dd8C07301495eE2C6f83b73FD21092',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://api.rubic.exchange/assets/xdai/0x67a83b28f6dd8c07301495ee2c6f83b73fd21092/logo.png',
            'coingeckoId': '9309-courville',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FORTH',
            'name': 'Ampleforth Governance on xDai',
            'address': '0x679922D1edCA00D2F41Ec9aEb023CcC1d58d045f',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TDLx',
            'name': 'Superfluid TDL',
            'address': '0x66e454105Ae553cFA87Ad4DC4cDF128c841fcD73',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WORK',
            'name': 'Superfluid protoWORK',
            'address': '0x6689A8F4fdAd6712888682C9ce81B26762389F40',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CFXQ',
            'name': 'CFX Quantum on xDai',
            'address': '0x64b17a95e6C45306FB23Bc526eB2dc9E1331a1b1',
            'decimals': 6,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x64b17a95e6C45306FB23Bc526eB2dc9E1331a1b1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MIVA',
            'name': 'Minerva Wallet',
            'address': '0x63e62989D9EB2d37dfDB1F93A22f063635b07d51',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x63e62989D9EB2d37dfDB1F93A22f063635b07d51/logo.png',
            'coingeckoId': 'minerva-wallet',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALTOKEN-S-14918-JOY-RD-DETROIT-MI',
            'name': 'RealToken S 14918 Joy Rd Detroit MI',
            'address': '0x63A1849b47eF5913ccc5adb0E2708b11A4Ba6972',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://api.rubic.exchange/assets/xdai/0x63a1849b47ef5913ccc5adb0e2708b11a4ba6972/logo.png',
            'coingeckoId': '14918-joy',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'Coval',
            'name': 'CircuitsOfValue on xDai',
            'address': '0x62D963C32cF49215948e2855529790e7f41BDA71',
            'decimals': 8,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DIGG',
            'name': 'Digg on xDai',
            'address': '0x6185B184B3bBD36331a7430A61d59C8EF2845c39',
            'decimals': 9,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'RLC',
            'name': 'iEx.ec Network Token on xDai',
            'address': '0x60e668f54106222adC1Da80c169281B3355B8e5D',
            'decimals': 9,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x60e668f54106222adC1Da80c169281B3355B8e5D/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NU',
            'name': 'NuCypher on xDai',
            'address': '0x60E663eb97bd747566bad4fB736DDc671fabbe95',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PGT',
            'name': 'Polyient Games Governance Token',
            'address': '0x6099280dC5FC97CBB61B456246316a1B8f79534B',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x6099280dC5FC97CBB61B456246316a1B8f79534B/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'INDEX',
            'name': 'Index on xDai',
            'address': '0x6052245Ec516D0F653794052D24EFca8A39fcBC3',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SWASH',
            'name': 'Swash Token from Mainnet',
            'address': '0x601541640D14b0c707591b7e74b05E25C3983586',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MKR',
            'name': 'Maker on xDai',
            'address': '0x5fd896D248fbfa54d26855C267859eb1b4DAEe72',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x5fd896D248fbfa54d26855C267859eb1b4DAEe72/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'JOON',
            'name': 'JOON on xDai',
            'address': '0x5fE9885226677F3Eb5C9ad8aB6c421B4EA38535d',
            'decimals': 4,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x5fE9885226677F3Eb5C9ad8aB6c421B4EA38535d/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'AC',
            'name': 'Adept Coin Governance on xDai',
            'address': '0x5f1F81de1D21b97a5d0D5d62d89BDE9DdEc27325',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x5f1F81de1D21b97a5d0D5d62d89BDE9DdEc27325/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'TEC',
            'name': 'Engineering Commons',
            'address': '0x5dF8339c5E282ee48c0c7cE8A7d01a73D38B3B27',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/18233/thumb/72481541.png?1643198626',
            'coingeckoId': 'token-engineering-commons',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'DUSD',
            'name': 'DefiDollar on xDai',
            'address': '0x5d1C44f1136ba5B8412441FB04332ADF90F26b24',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'USDB',
            'name': 'Bancor USD Token from Mainnet',
            'address': '0x5c40bdA735796bC1BDA83B12c8ec524faDDD151d',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SORA',
            'name': 'Sora Token on xDai',
            'address': '0x5bbfBfB123B72A255504BE985bd2B474e481e866',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x5bbfBfB123B72A255504BE985bd2B474e481e866/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MOON',
            'name': 'MoonToken on xDai',
            'address': '0x5b917D4fb9B27591353211c32F1552A527987AFC',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x5b917D4fb9B27591353211c32F1552A527987AFC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'renZEC',
            'name': 'renZEC on xDai',
            'address': '0x5F2852AFd20C39849f6f56F4102b8c29Ee141ADD',
            'decimals': 8,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x5F2852AFd20C39849f6f56F4102b8c29Ee141ADD/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALT-S-11201-COLLE',
            'name': 'RealT   11201 College St  Detroit  MI 4',
            'address': '0x5E2a09064B2DCa8c44aaD8A5b69a69Bb1854fE72',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16594/thumb/11201-College-hero-1.jpg?1624540102',
            'coingeckoId': '11201-college',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'rSURF',
            'name': 'rSURF',
            'address': '0x5C8C83e5D5F7BE815863B810D45D7Bc706D7b15b',
            'decimals': 9,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x5C8C83e5D5F7BE815863B810D45D7Bc706D7b15b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MTA',
            'name': 'Meta on xDai',
            'address': '0x5B9EED77d9500aDF7200fEeB395647be1950F7d2',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'KP3R',
            'name': 'Keep3rV1 on xDai',
            'address': '0x5B449Ea0e550C143074146abc89A6328D9E70798',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'RSR',
            'name': 'Reserve Rights on xDai',
            'address': '0x5A87eaC5642BfEd4e354Ee8738DACd298E07D1Af',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x5A87eaC5642BfEd4e354Ee8738DACd298E07D1Af/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ENJ',
            'name': 'Enjin Coin on xDai',
            'address': '0x5A757F0BcAdFDb78651B7bDBe67e44e8Fd7F7f6b',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x5A757F0BcAdFDb78651B7bDBe67e44e8Fd7F7f6b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'xDAIx',
            'name': 'Super xDAI',
            'address': '0x59988e47A3503AaFaA0368b9deF095c818Fdca01',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://static.debank.com/image/xdai_token/logo_url/0xe91d153e0b41518a2ce8dd3d7944fa863463a97d/3fedab836c5425fc3fc2eb542c34c81a.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ALVIN',
            'name': 'Alvin',
            'address': '0x59715D8d206b3D4748Cec55E7c2DE26F23AF45D5',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://raw.githubusercontent.com/1Hive/default-token-list/master/src/assets/xdai/0x59715D8d206b3D4748Cec55E7c2DE26F23AF45D5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'UMA',
            'name': 'UMA Voting Token v1 on xDai',
            'address': '0x5806212Bec491bEb309E3F5C1F911eaC6f24cd6b',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'RAREv2',
            'name': 'Rare Coin v2',
            'address': '0x57e93BB58268dE818B42E3795c97BAD58aFCD3Fe',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x57e93BB58268dE818B42E3795c97BAD58aFCD3Fe/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALT-S-13045-WADE-',
            'name': 'RealT   13045 Wade St  Detroit  MI 4821',
            'address': '0x5600e25b4f24c63Afa655C3bd96E3C178B654Fa1',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16617/thumb/13045-Wade-hero-1.jpg?1624549855',
            'coingeckoId': '13045-wade',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'PTF',
            'name': 'PowerTrade Fuel Token on xDai',
            'address': '0x53ef00be819A062533a0E699077c621a28EADEd1',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x53ef00be819A062533a0E699077c621a28EADEd1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MARK',
            'name': 'Benchmark on xDai',
            'address': '0x53Ee8D3641df5a757daE4de6c9e0A2f715475f54',
            'decimals': 9,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'IDLE',
            'name': 'Idle on xDai',
            'address': '0x534179b3d7292d8a82A2985ee80a6D2027ee8378',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SWPR',
            'name': 'Swapr',
            'address': '0x532801ED6f82FFfD2DAB70A19fC2d7B2772C4f4b',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/18740/thumb/swapr.jpg?1633516501',
            'coingeckoId': 'swapr',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'DONUT',
            'name': 'Donut',
            'address': '0x524B969793a64a602342d89BC2789D43a016B13A',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://tokens.1inch.io/0xc0f9bd5fa5698b6505f643900ffa515ea5df54a9.png',
            'coingeckoId': 'donut',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'TRU',
            'name': 'Truebit on xDai',
            'address': '0x51aD25F47c5E7a5E2A2e6CA40C4cf117D9C0d7A9',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'OCEAN',
            'name': 'Ocean Token on xDai',
            'address': '0x51732a6fC4673d1aCca4c047F5465922716508Ad',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x51732a6fC4673d1aCca4c047F5465922716508Ad/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MEME',
            'name': 'Meme',
            'address': '0x512a2Eb0277573ae9Be0d48c782590b624048fdF',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x512a2Eb0277573ae9Be0d48c782590b624048fdF/logo.png',
            'coingeckoId': 'degenerator',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GIV',
            'name': 'Giveth',
            'address': '0x4f4F9b8D5B4d0Dc10506e5551B0513B61fD59e75',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x4f4F9b8D5B4d0Dc10506e5551B0513B61fD59e75/logo.png',
            'coingeckoId': 'giveth',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MASK',
            'name': 'Mask Network on xDai',
            'address': '0x4e1a2bFfe81000F7be4807FAF0315173c817d6F4',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-15373-PARKS',
            'name': 'RealT   15373 Parkside St  Detroit  MI ',
            'address': '0x4d0dA4E75d40Bd7D9C4f7A292BF883BcDf38c45D',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16562/thumb/15373-Parkside-hero-1.jpg?1624458568',
            'coingeckoId': '15373-parkside',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'RUNE',
            'name': 'THORChain ETH.RUNE on xDai',
            'address': '0x4c68041898bfEfbfCc4253fbE8eD30830E6eb767',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'RARI',
            'name': 'Rarible on xDai',
            'address': '0x4bE85ACC1cd711F403dC7BdE9e6caDfC5A94744b',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x4bE85ACC1cd711F403dC7BdE9e6caDfC5A94744b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALTOKEN-S-10639-STRATMAN-ST-DETROIT-MI',
            'name': 'RealToken S 10639 Stratman St Detroit MI',
            'address': '0x4a99cc509f7FaCF58D7B67E99236dB5e0921ef81',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://api.rubic.exchange/assets/xdai/0x4a99cc509f7facf58d7b67e99236db5e0921ef81/logo.png',
            'coingeckoId': '10639-stratman',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DAIX',
            'name': 'Daibase xDAI v0.1.61',
            'address': '0x4Ef1d9A329a0CB0658156aFF55c406cC4393A987',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x4Ef1d9A329a0CB0658156aFF55c406cC4393A987/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'Tether',
            'address': '0x4ECaBa5870353805a9F068101A40E0f32ed605C6',
            'decimals': 6,
            'chainId': 100,
            'logoURI': 'https://tokens.1inch.io/0xdac17f958d2ee523a2206206994597c13d831ec7.png',
            'coingeckoId': 'tether',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SHIB',
            'name': 'SHIBA INU on xDai',
            'address': '0x4EA1172F4C4e8E8D3C9e1Be4269B696bF19D24fE',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REALTOKEN-S-13991-WARWICK-ST-DETROIT-MI',
            'name': 'RealToken S 13991 Warwick St Detroit MI',
            'address': '0x4E98493920b16Dd6642e9D48497c8d0A49150f6F',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://api.rubic.exchange/assets/xdai/0x4e98493920b16dd6642e9d48497c8d0a49150f6f/logo.png',
            'coingeckoId': '13991-warwick',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ZAP',
            'name': 'ZAP TOKEN on xDai',
            'address': '0x4D87cA8587D3c7A9b6125758487c19e4b760ca91',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REALTOKEN-S-19163-MITCHELL-ST-DETROIT-MI',
            'name': 'RealToken S 19163 Mitchell St Detroit MI',
            'address': '0x4Cc53Ee5ef306a95d407321d4B4acc30814C04ee',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://api.rubic.exchange/assets/xdai/0x4cc53ee5ef306a95d407321d4b4acc30814c04ee/logo.png',
            'coingeckoId': '19163-mitchell',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ALEPH',
            'name': 'aleph.im v2 on xDai',
            'address': '0x4BC97997883C0397F556bd0F9Da6Fb71da22F9a2',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x4BC97997883C0397F556bd0F9Da6Fb71da22F9a2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'renBTC',
            'name': 'renBTC on xDai',
            'address': '0x4A88248BAa5b39bB4A9CAa697Fb7f8ae0C3f0ddB',
            'decimals': 8,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x4A88248BAa5b39bB4A9CAa697Fb7f8ae0C3f0ddB/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'crDAI',
            'name': 'Cream Dai Stablecoin on xDai',
            'address': '0x49B408a651d80c61BA2f15F3Dfb2Cf455078e8Ee',
            'decimals': 8,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-17813-BRADF',
            'name': 'RealT   17813 Bradford St  Detroit  MI ',
            'address': '0x499A6c19F5537dd6005E2B5c6E1263103f558Ba4',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16606/thumb/17813-Bradford-hero-1.jpeg?1624544519',
            'coingeckoId': '17813-bradford',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'DIP',
            'name': 'Etherisc DIP',
            'address': '0x48b1B0d077b4919b65b4E4114806dD803901E1D9',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x48b1B0d077b4919b65b4E4114806dD803901E1D9/logo.png',
            'coingeckoId': 'etherisc',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MR',
            'name': 'Moon Rock',
            'address': '0x481D6104761442F162d1f7AC3DC6F98896e7A4ef',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://moonrock.herodao.org/_next/image?url=%2Fmoonrock_token.png&w=640&q=75',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TRIPS',
            'name': 'Trips Community',
            'address': '0x479e32cDFF5F216f93060700C711D1cC8E811a6B',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x479e32cDFF5F216f93060700C711D1cC8E811a6B/logo.png',
            'coingeckoId': 'trips-community',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALT-S-18433-FAUST',
            'name': 'RealT   18433 Faust Ave  Detroit  MI  4',
            'address': '0x46F8A600337dec5CaB03aa9b8F67f1D5B788ce28',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16684/thumb/Faust-1.jpeg?1624633417',
            'coingeckoId': '18433-faust',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'UNI',
            'name': 'Uniswap',
            'address': '0x4537e328Bf7e4eFA29D05CAeA260D7fE26af9D74',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x4537e328Bf7e4eFA29D05CAeA260D7fE26af9D74/logo.png',
            'coingeckoId': 'uniswap',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DAI',
            'name': 'Dai',
            'address': '0x44fA8E6f47987339850636F88629646662444217',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://tokens.1inch.io/0x6b175474e89094c44da98b954eedeac495271d0f.png',
            'coingeckoId': 'dai',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'API3',
            'name': 'API3',
            'address': '0x44b6bBA599F100006143E82A60462D71Ac1331Da',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x44b6bBA599F100006143E82A60462D71Ac1331Da/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'arte',
            'name': 'ethart on xDai',
            'address': '0x4463c577A66F350dE17edFeE83CAcCE93A44e0BC',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REALTOKEN-S-14825-WILFRIED-ST-DETROIT-MI',
            'name': 'RealToken S 14825 Wilfried St Detroit MI',
            'address': '0x43fED9F9BF7DeedCb314b432a8e38219dd62CE9e',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://api.rubic.exchange/assets/xdai/0x43fed9f9bf7deedcb314b432a8e38219dd62ce9e/logo.png',
            'coingeckoId': '14825-wilfred',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'TRU',
            'name': 'TrueFi on xDai',
            'address': '0x4384a7C9498f905e433Ee06B6552a18e1D7cD3a4',
            'decimals': 8,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ANTv1',
            'name': 'Aragon Network Token on xDai',
            'address': '0x437a044fb4693890E61D2C1c88E3718E928B8E90',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x437a044fb4693890E61D2C1c88E3718E928B8E90/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'JPYC',
            'name': 'JPY Coin',
            'address': '0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/25971/thumb/JPYC_logo_3D_cmyk_0-1_%282%29.jpg?1667467959',
            'coingeckoId': 'jpy-coin',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'xREAP',
            'name': 'xREAP',
            'address': '0x42c6b3aC30Ae82D754498f56D9372F0070349409',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x42c6b3aC30Ae82D754498f56D9372F0070349409/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WATER',
            'name': '1Hive Water',
            'address': '0x4291F029B9e7acb02D49428458cf6fceAC545f81',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/26089/thumb/water_logo.png?1655778743',
            'coingeckoId': '1hive-water',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'RGT',
            'name': 'Rari Governance Token on xDai',
            'address': '0x417Ae38B3053A736B4274aed8DBD1a8A6FDbc974',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'JPYC',
            'name': 'JPY Coin v1',
            'address': '0x417602f4fbdd471A431Ae29fB5fe0A681964C11b',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x417602f4fbdd471A431Ae29fB5fe0A681964C11b/logo.png',
            'coingeckoId': 'jpyc',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALT-S-14319-ROSEM',
            'name': 'RealT   14319 Rosemary St  Detroit  MI ',
            'address': '0x41599149f1B52035392402F9e311b1edb0C9f699',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16592/thumb/14319-Rosemary-hero-1-800x600.jpeg?1624539171',
            'coingeckoId': '14319-rosemary',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'REALTOKEN-S-3432-HARDING-ST-DETROIT-MI',
            'name': 'RealToken S 3432 Harding St Detroit MI',
            'address': '0x400B5716B0c23B6f1f0f2A5fDb038949962B803E',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://api.rubic.exchange/assets/xdai/0x400b5716b0c23b6f1f0f2a5fdb038949962b803e/logo.png',
            'coingeckoId': '3432-harding',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DUCK',
            'name': 'DLP Duck Token on xDai',
            'address': '0x3fAC3877BBB50DBbb76C690D3ee733156f5E75e5',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'xMARK',
            'name': 'Standard on xDai',
            'address': '0x3e33cF23073FD8d5aD1d48d1860a96C0D8E56193',
            'decimals': 9,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x3e33cF23073FD8d5aD1d48d1860a96C0D8E56193/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'AGVE',
            'name': 'Agave',
            'address': '0x3a97704a1b25F08aa230ae53B352e2e72ef52843',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x3a97704a1b25F08aa230ae53B352e2e72ef52843/logo.png',
            'coingeckoId': 'agave-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MIMATIC',
            'name': 'MAI',
            'address': '0x3F56e0c36d275367b8C502090EDF38289b3dEa0d',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x3F56e0c36d275367b8C502090EDF38289b3dEa0d/logo.png',
            'coingeckoId': 'mimatic',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PAXG',
            'name': 'Paxos Gold from Mainnet',
            'address': '0x3E76f9CAaf9B47089810B4579C598228735E7a11',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DHV',
            'name': 'DeHive.finance from Mainnet',
            'address': '0x3E34A554b7B7274852050D0cB9E7C48F07812148',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-9165-KENSIN',
            'name': 'RealT   9165 Kensington Ave  Detroit  M',
            'address': '0x3C56D5E887D8fA7AE1Ba65BF7eCCC25eC09EAF18',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16687/thumb/9165-Kensington-hero-1.jpeg?1624634948',
            'coingeckoId': '9165-kensington',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'stETH',
            'name': 'Liquid staked Ether 2.0 on xDai',
            'address': '0x3C037849a8ffcf19886e2f5B04f293B7847D0377',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SRM',
            'name': 'Serum on xDai',
            'address': '0x3AE8c08cD61d05ad6e22973E4b675A92D412EE3C',
            'decimals': 6,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SNX',
            'name': 'Synthetix Network Token on xDai',
            'address': '0x3A00E08544d589E19a8e7D97D0294331341cdBF6',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x3A00E08544d589E19a8e7D97D0294331341cdBF6/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BANK',
            'name': 'Bankless Token on xDai',
            'address': '0x3985DBA7Fb95eF9E35142cfF0e829979F2491E3b',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CYFM',
            'name': 'CyberFM Radio on xDai',
            'address': '0x3901403F4dE78135434a47e2F2dC9804831f06Bc',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'XCOMB',
            'name': 'xDai Native Comb',
            'address': '0x38Fb649Ad3d6BA1113Be5F57B927053E97fC5bF7',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x38Fb649Ad3d6BA1113Be5F57B927053E97fC5bF7/logo.png',
            'coingeckoId': 'xdai-native-comb',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PNK',
            'name': 'Kleros',
            'address': '0x37b60f4E9A31A64cCc0024dce7D0fD07eAA0F7B3',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://tokens.1inch.io/0x93ed3fbe21207ec2e8f2d3c3de6e058cb73bc04d.png',
            'coingeckoId': 'kleros',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': '0xBTC',
            'name': '0xBitcoin Token on xDai',
            'address': '0x370825bc4F39C72BA84d290dB131bcCB35aA1D49',
            'decimals': 8,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TORG',
            'name': 'TORG',
            'address': '0x364b5975385B4DE226cF450dF82D7ed69902Ac5a',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/17596/thumb/TORG_Logo_200x200.png?1628586056',
            'coingeckoId': 'torg',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'SOCKS',
            'name': 'Unisocks',
            'address': '0x35f346Cb4149746272974a92d719Fd48ae2F72FA',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x35f346Cb4149746272974a92d719Fd48ae2F72FA/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ALBT',
            'name': 'AllianceBlock Token on xDai',
            'address': '0x3581cc6A09DE85e9B91Ef93F2a5eF837706b84a5',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x3581cc6A09DE85e9B91Ef93F2a5eF837706b84a5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALT-S-19317-GABLE',
            'name': 'RealT   19317 Gable St  Detroit  MI 482',
            'address': '0x34eD9e71449529E034d0326cfBB3b5ccDCa00CBC',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16638/thumb/19317-Gable-hero-1.jpeg?1624558479',
            'coingeckoId': '19317-gable',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'HOT',
            'name': 'HoloToken on xDai',
            'address': '0x346b2968508d32f0192cD7a60Ef3D9C39a3cF549',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x346b2968508d32f0192cD7a60Ef3D9C39a3cF549/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SAVE',
            'name': 'Save Coin from Mainnet',
            'address': '0x338D4527c9E7F7316c43581684Bc510b851F832a',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-19136-TRACE',
            'name': 'RealT   19136 Tracey St  Detroit MI 482',
            'address': '0x33722EA778dF197f1b7B1Cf0b124d7A962181d65',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16706/thumb/19136-Tracey-hero-1.jpeg?1624644977',
            'coingeckoId': '19136-tracey',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'PICKLE',
            'name': 'PickleToken on xDai',
            'address': '0x326bdA8a9D3e4F1443cD00ec330bF6AF1c664976',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-17809-CHARE',
            'name': 'RealT   17809 Charest St  Detroit  MI 4',
            'address': '0x31AA5fA895Fd186fDE12347A6fCaf540875b6434',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16601/thumb/17809-Charest-hero-1.jpg?1624543227',
            'coingeckoId': '17809-charest',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'REALT-S-11653-NOTTI',
            'name': 'RealT   11653 Nottingham Rd  Detroit  M',
            'address': '0x31820af2d43c08BD82Bd94B08974062482bD98D3',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16622/thumb/11653-Nottingham-hero-1.jpg?1624551450',
            'coingeckoId': '11653-nottingham',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'PIE',
            'name': 'DeFiPIE Token on xDai',
            'address': '0x317eab07380d670Ea814025CbA40f5624354a32f',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x317eab07380d670Ea814025CbA40f5624354a32f/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALT-S-14078-CARLI',
            'name': 'RealT   14078 Carlisle St  Detroit  MI ',
            'address': '0x315699f1BA88383CFF2F2f30FcaD187aDb2E4D72',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16597/thumb/14078-Carlisle-hero-1-800x600.jpeg?1624541175',
            'coingeckoId': '14078-carlisle',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'REALT-S-19596-GOULB',
            'name': 'RealT   19596 Goulburn Ave  Detroit  MI',
            'address': '0x3150f0EbC0eFEe280b5348b9C8C271AD44Eb8B13',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16608/thumb/19596-Goulburn-hero-1.jpg?1624544786',
            'coingeckoId': '19596-goulburn',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'REALT-S-19996-JOANN',
            'name': 'RealT   19996 Joann Ave  Detroit  MI 48',
            'address': '0x311FC485f1FeA0c8Cc9b5c783E79f4313DDFa720',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16666/thumb/19996-Joanne-hero-1.jpg?1624626495',
            'coingeckoId': '19996-joann',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'OM',
            'name': 'OM Token on xDai',
            'address': '0x309Bc6DbcbFB9c84D26FDF65E8924367efCCBdb9',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x309Bc6DbcbFB9c84D26FDF65E8924367efCCBdb9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GLDB',
            'name': 'Golden Bull Token on xDAI',
            'address': '0x30610f98b61593DE963b2303AeeaeE69823f561f',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x30610f98b61593DE963b2303AeeaeE69823f561f/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'xSEED',
            'name': 'Seed on xDai',
            'address': '0x2fd0c73Ad006407F0A96c984f06A9CE8415B094E',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x2fd0c73Ad006407F0A96c984f06A9CE8415B094E/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'xBRICK',
            'name': 'Bricks on xDai',
            'address': '0x2f9ceBf5De3bc25E0643D0E66134E5bf5c48e191',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x2f9ceBf5De3bc25E0643D0E66134E5bf5c48e191/logo.png',
            'coingeckoId': 'brick',
            'listedIn': [
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BCDT',
            'name': 'Blockchain Certified Data Token on xDai',
            'address': '0x2d86Ee4f488d914877880987D2E7d20A83701D21',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'JCHF',
            'name': 'Jarvis Synthetic Swiss Franc',
            'address': '0x2d5563da42b06FbBF9c67b7DC073cF6A7842239e',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/15727/thumb/jCHF.png?1634046084',
            'coingeckoId': 'jarvis-synthetic-swiss-franc',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'FRACTION',
            'name': 'Fraction',
            'address': '0x2bF2ba13735160624a0fEaE98f6aC8F70885eA61',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x2bF2ba13735160624a0fEaE98f6aC8F70885eA61/logo.png',
            'coingeckoId': 'fraction',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'LRC',
            'name': 'LoopringCoin V2 on xDai',
            'address': '0x2bE73bFeEC620aa9B67535A4D3827bB1e29436D1',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x2bE73bFeEC620aa9B67535A4D3827bB1e29436D1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALT-S-14494-CHELS',
            'name': 'RealT   14494 Chelsea Ave  Detroit  MI ',
            'address': '0x2adc1cfA726A45264A328D9d2e2C692ceac97458',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16618/thumb/14494-Chelsea-hero-1.jpg?1624550267',
            'coingeckoId': '14494-chelsea',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'RPL',
            'name': 'Rocket Pool on xDai',
            'address': '0x2F0E755Efe6b58238A67DB420Ff3513Ec1fb31eF',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x2F0E755Efe6b58238A67DB420Ff3513Ec1fb31eF/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SAN',
            'name': 'SANtiment network token on xDai',
            'address': '0x2A49347B5e267B462E379EcE891df901377eA1B1',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SUSHI',
            'name': 'SushiToken on xDai',
            'address': '0x2995D1317DcD4f0aB89f4AE60F3f020A4F17C7CE',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x2995D1317DcD4f0aB89f4AE60F3f020A4F17C7CE/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BID',
            'name': 'Bidao on xDai',
            'address': '0x2977893F4C04bfbd6EFc68d0e46598d27810d3dB',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x2977893F4C04bfbd6EFc68d0e46598d27810d3dB/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GTC',
            'name': 'Gitcoin from Mainnet',
            'address': '0x2853F6E9605419CCf38D102fb1FB3961904aE263',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-19020-ROSEM',
            'name': 'RealT   19020 Rosemont Ave  Detroit  MI',
            'address': '0x280e9ed3B20c580a2f4219657bB9332D80Bc01f1',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16705/thumb/19020-Rosemont-hero-1.jpeg?1624644549',
            'coingeckoId': '19020-rosemont',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'SNAFU',
            'name': 'SNAFU',
            'address': '0x27B9C2Bd4BaEa18ABdF49169054c1C1c12af9862',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x27B9C2Bd4BaEa18ABdF49169054c1C1c12af9862/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FR',
            'name': 'Freedom Reserve on xDai',
            'address': '0x270DE58F54649608D316fAa795a9941b355A2Bd0',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x270DE58F54649608D316fAa795a9941b355A2Bd0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NPXS',
            'name': 'Pundi X Token on xDai',
            'address': '0x26dD64bdCB2FaF4F7E49A73145752e8d9cb34C94',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x26dD64bdCB2FaF4F7E49A73145752e8d9cb34C94/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NEXO',
            'name': 'Nexo on xDai',
            'address': '0x26DC03E492763068CCfE7C39B93A22442807C360',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x26DC03E492763068CCfE7C39B93A22442807C360/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'IONx',
            'name': 'Superfluid ION',
            'address': '0x263647097A185eE0A9ded3e3e46D7eb931c23ddA',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AMP',
            'name': 'Amp on xDai',
            'address': '0x260B0cc1dE83e4f8Db8361E81aCf73d1F597a695',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DATA',
            'name': 'Streamr',
            'address': '0x256eb8a51f382650B2A1e946b8811953640ee47D',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://tokens.1inch.io/0x8f693ca8d21b157107184d29d398a8d082b38b76.png',
            'coingeckoId': 'streamr',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'UDT',
            'name': 'Unlock Discount Token on xDai',
            'address': '0x24Cb95c7f0c39759c4165b053730126C7102BbE8',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CELR',
            'name': 'CelerToken on xDai',
            'address': '0x248c54B3fc3bC8b20D0CDEE059E17C67e4a3299d',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x248c54B3fc3bC8b20D0CDEE059E17C67e4a3299d/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ORBS',
            'name': 'Orbs on xDai',
            'address': '0x247E04Cc5D6d046679cdcF33C70D61A4c68C4037',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REALTOKEN-S-19333-MOENART-ST-DETROIT-MI',
            'name': 'RealToken S 19333 Moenart St Detroit MI',
            'address': '0x24293ab20159cfc0f3D7C8727CD827fbA63d4F64',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://api.rubic.exchange/assets/xdai/0x24293ab20159cfc0f3d7c8727cd827fba63d4f64/logo.png',
            'coingeckoId': '19333-moenart',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'REALT-S-9336-PATTON',
            'name': 'RealT   9336 Patton St  Detroit  MI 482',
            'address': '0x23684569c0636C9aEa246551879d457D0a0E6F58',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16701/thumb/9336-Patton-HERO-2.jpeg?1624642655',
            'coingeckoId': '9336-patton',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'yCRV',
            'name': 'yCurve on xDai',
            'address': '0x22Bd2A732b39dACe37AE7E8f50A186f3D9702e87',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x22Bd2A732b39dACe37AE7E8f50A186f3D9702e87/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ZRX',
            'name': '0x Protocol Token on xDai',
            'address': '0x226bCf0e417428a25012d0fA2183d37f92bCeDF6',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x226bCf0e417428a25012d0fA2183d37f92bCeDF6/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALTOKEN-18276-APPOLINE-ST-DETROIT-MI',
            'name': 'RealToken S 18276 Appoline St Detroit MI',
            'address': '0x21f1aF3e751317a2F7De7Df31D5d092E6A907Bde',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://api.rubic.exchange/assets/xdai/0x21f1af3e751317a2f7de7df31d5d092e6a907bde/logo.png',
            'coingeckoId': '18276-appoline',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FOX',
            'name': 'ShapeShift FOX',
            'address': '0x21a42669643f45Bc0e086b8Fc2ed70c23D67509d',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://tokens.1inch.io/0xc770eefad204b5180df6a14ee197d99d808ee52d.png',
            'coingeckoId': 'shapeshift-fox-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALT-S-19200-STRAS',
            'name': 'RealT   19200 Strasburg St  Detroit  MI',
            'address': '0x211618Fa0934910666f2C2731101F5A3aC013fD8',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16620/thumb/19200-Strasburg-hero-1.jpg?1624550918',
            'coingeckoId': '19200-strasburg',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'HUSD',
            'name': 'HUSD on xDai',
            'address': '0x1e37E5b504F7773460d6eB0e24D2e7C223B66EC7',
            'decimals': 8,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'XMOON',
            'name': 'xMOON on xDai',
            'address': '0x1e16aa4Df73d29C029d94CeDa3e3114EC191E25A',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x1e16aa4Df73d29C029d94CeDa3e3114EC191E25A/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'sushiswap',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FRONT',
            'name': 'Frontier Token on xDai',
            'address': '0x1bbca7491f14b46788Ff9c834d97a668C4886523',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AZUKI',
            'name': 'DokiDokiAzuki on xDai',
            'address': '0x1b5C72bbc29859b005c940AAA6C29Fd7BE73aC8c',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-17500-EVERG',
            'name': 'RealT   17500 Evergreen Rd  Detroit  MI',
            'address': '0x1FdB4015fD5E031C5641752C1e03B973ad5eA168',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16624/thumb/17500-Evergreen-hero-1.jpg?1624552051',
            'coingeckoId': '17500-evergreen',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'REALTOKEN-S-9481-WAYBURN-ST-DETROIT-MI',
            'name': 'RealToken S 9481 Wayburn St Detroit MI',
            'address': '0x1E001730A23c7EBaFF35BC8bc90DA5a9b20804A4',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://api.rubic.exchange/assets/xdai/0x1e001730a23c7ebaff35bc8bc90da5a9b20804a4/logo.png',
            'coingeckoId': '9481-wayburn',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MNS',
            'name': 'Monnos Token on xDai',
            'address': '0x1AEA02878C6dbc94CC56c4caC6975F2Bcf557776',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'NIF',
            'name': 'Unifty on xDai',
            'address': '0x1A186E7268F3Ed5AdFEa6B9e0655f70059941E11',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x1A186E7268F3Ed5AdFEa6B9e0655f70059941E11/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CREAM',
            'name': 'Cream on xDai',
            'address': '0x1939D3431CF0E44B1d63b86e2cE489E5a341B1Bf',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x1939D3431CF0E44B1d63b86e2cE489E5a341B1Bf/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CC10',
            'name': 'Cryptocurrency Top 10 Tokens Index on xDai',
            'address': '0x19200282fd98361c8C0840694b60495117F42e19',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'RAID',
            'name': 'Raid',
            'address': '0x18E9262e68Cc6c6004dB93105cc7c001BB103e49',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://raw.githubusercontent.com/1Hive/default-token-list/master/src/assets/xdai/0x18e9262e68cc6c6004db93105cc7c001bb103e49/logo.png',
            'coingeckoId': 'raid-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'WISE',
            'name': 'Wise Token on xDai',
            'address': '0x178578BCC316C60282C1B73556B1FE10fB3a742a',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'COW',
            'name': 'CoW Protocol',
            'address': '0x177127622c4A00F3d409B75571e12cB3c8973d3c',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/24384/thumb/cow.png?1660960589',
            'coingeckoId': 'cow-protocol',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'REALTOKEN-S-15770-PREST-ST-DETROIT-MI',
            'name': 'RealToken S 15770 Prest St Detroit MI',
            'address': '0x175cbD54d38F58B530785e01471a2Ec0D4596EB5',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://api.rubic.exchange/assets/xdai/0x175cbd54d38f58b530785e01471a2ec0d4596eb5/logo.png',
            'coingeckoId': '15770-prest',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'UBXT',
            'name': 'UpBots on xDai',
            'address': '0x173E235da85Ab16d4247662c050BE2870Dd2D177',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TESTA',
            'name': 'Testa on xDai',
            'address': '0x16AFe6E6754FA3694afD0Ce48f4Bea102Efacc17',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x16AFe6E6754FA3694afD0Ce48f4Bea102Efacc17/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'LEVIN',
            'name': 'Levin',
            'address': '0x1698cD22278ef6E7c0DF45a8dEA72EDbeA9E42aa',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16004/thumb/JHqkC8Z.png?1622594623',
            'coingeckoId': 'levin',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'KNC',
            'name': 'Kyber Network Crystal on xDai',
            'address': '0x1534fB3E82849314360C267FE20Df3901A2ED3f9',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x1534fB3E82849314360C267FE20Df3901A2ED3f9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'KEEP',
            'name': 'KEEP Token on xDai',
            'address': '0x1509465AFBd36C09B2F6501bcC1384A12Ef22d66',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'RING',
            'name': 'Darwinia Network Native Token on xDai',
            'address': '0x1479ebFe327B62bFF255C0749a242748D3e7347a',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x1479ebFe327B62bFF255C0749a242748D3e7347a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'COIN',
            'name': 'coin_artist on xDai',
            'address': '0x14411aecA652F5131834Bf0c8fF581B5dDf3bc03',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x14411aecA652F5131834Bf0c8fF581B5dDf3bc03/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MIR',
            'name': 'Wrapped MIR Token on xDai',
            'address': '0x1326f053e2452e73C66f38914Fb338c8DE331684',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DDAI',
            'name': 'DeFireX DAI on xDai',
            'address': '0x1319067e82F0b9981F19191E1C08bb6E6e055DD3',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x1319067e82F0b9981F19191E1C08bb6E6e055DD3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GEN',
            'name': 'DAOstack on xDai',
            'address': '0x12daBe79cffC1fdE82FCd3B96DBE09FA4D8cd599',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x12daBe79cffC1fdE82FCd3B96DBE09FA4D8cd599/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'USDCx',
            'name': 'Superfluid USDC',
            'address': '0x1234756ccf0660E866305289267211823Ae86eEc',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://static.debank.com/image/xdai_token/logo_url/0xddafbb505ad214d7b80b1f830fccc89b60fb7a83/fffcd27b9efff5a86ab942084c05924d.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PLR',
            'name': 'PILLAR on xDai',
            'address': '0x10bEEa85519a704A63765d396415f9eA5aa30A17',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://etherscan.io/token/images/pillar_32.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'HND',
            'name': 'Hundred Finance',
            'address': '0x10010078a54396F62c96dF8532dc2B4847d47ED3',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://static.debank.com/image/xdai_token/logo_url/0x10010078a54396f62c96df8532dc2b4847d47ed3/cae168ae659748f245f0d57b76521952.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REN',
            'name': 'Republic Token on xDai',
            'address': '0x0da1a02CDF84C44021671d183d616925164E08Aa',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x0da1a02CDF84C44021671d183d616925164E08Aa/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NRGE',
            'name': 'Energi on xDai',
            'address': '0x0dCfEd2C3041e66b2D8c4Ea39782c60355716316',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x0dCfEd2C3041e66b2D8c4Ea39782c60355716316/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NMR',
            'name': 'Numeraire on xDai',
            'address': '0x0b7A1c1A3D314DCC271EA576dA400B24e9ad3094',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CEL',
            'name': 'Celsius on xDai',
            'address': '0x0aCD91f92Fe07606ab51EA97d8521E29D110fD09',
            'decimals': 4,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x0aCD91f92Fe07606ab51EA97d8521E29D110fD09/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WOO',
            'name': 'Wootrade Network on xDai',
            'address': '0x0E9f346dE9780FB966EEB763AA89d254d606B9d8',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PANTS',
            'name': 'SHE Sweatpants',
            'address': '0x0DAe13FAe64180D3CadCAD22329a4abcaEF15cA6',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://raw.githubusercontent.com/ShenaniganDApp/unisocks/master/src/components/Gallery/pantsToken.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BCP',
            'name': 'BitcashPay on xDai',
            'address': '0x09Cd39FE6C923959c4603aCf5f00325137fD2A0a',
            'decimals': 8,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AXS',
            'name': 'Axie Infinity Shard on xDai',
            'address': '0x0987c6B9357Dee87404dFeA0483C337de530be5A',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'VITA',
            'name': 'VitaDAO from Mainnet',
            'address': '0x0939a7c3f8D37C1ce67fAda4963aE7E0bd112ff3',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://raw.githubusercontent.com/VitaDAO/assets/master/vitadao-icon.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'OWL',
            'name': 'OWL on xDai',
            'address': '0x0905Ab807F8FD040255F0cF8fa14756c1D824931',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x0905Ab807F8FD040255F0cF8fa14756c1D824931/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALT-S-13116-KILBO',
            'name': 'RealT   13116 Kilbourne Ave  Detroit  M',
            'address': '0x08Ad1F3a48Be1D23C723a6cC8486b247F5dE935a',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16679/thumb/13116-Kilbourne-hero-1.jpeg?1624630007',
            'coingeckoId': '13116-kilbourne',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'TBTC',
            'name': 'tBTC on xDai',
            'address': '0x0811E451447D5819976a95a02f130c3b00D59346',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x0811E451447D5819976a95a02f130c3b00D59346/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'REALT-S-10617-HATHA',
            'name': 'RealT   10617 Hathaway Ave  Cleveland  ',
            'address': '0x06D0e5Aee443093aC5635B709C8a01342E59Df19',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://assets.coingecko.com/coins/images/16709/thumb/10617-Hathaway-hero-1.jpeg?1624647056',
            'coingeckoId': '10617-hathaway',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'SALE',
            'name': 'DxSale.Network on xDai',
            'address': '0x05363de9f9940609B48eb268d6D6D1f86a6F17F5',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SNT',
            'name': 'Status Network Token on xDai',
            'address': '0x044F6ae3aEF34fdB8FdDc7c05F9cC17F19Acd516',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x044F6ae3aEF34fdB8FdDc7c05F9cC17F19Acd516/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'OPIUM',
            'name': 'Opium Governance Token on xDai',
            'address': '0x043D4FCE2fdEF7eFb4F5A398C30A363FeaF2b2A5',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ROOK',
            'name': 'ROOK on xDai',
            'address': '0x03959Ac65e621e8C95d5E0f75EA96E5C03A15009',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FM',
            'name': 'Follow ME',
            'address': '0x03943C3ef00d92e130185CeBC0bcc435Def2cC94',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://api.rubic.exchange/assets/xdai/0x03943c3ef00d92e130185cebc0bcc435def2cc94/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'HT',
            'name': 'HuobiToken on xDai',
            'address': '0x036328204f84b423F0e1A12C3B8777aE08758481',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DEFI5',
            'name': 'DEFI Top 5 Tokens Index on xDai',
            'address': '0x027687bE2F0C999aFbb1D365C9d1BA5151A51f7D',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REALTOKEN-16200-FULLERTON-AVE-DETROIT-MI',
            'name': 'RealToken S 16200 Fullerton Ave Detroit MI',
            'address': '0x021Bb23a45e9FC824260435e670fC383b7b8cbbB',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://api.rubic.exchange/assets/xdai/0x021bb23a45e9fc824260435e670fc383b7b8cbbb/logo.png',
            'coingeckoId': '16200-fullerton',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'VXV',
            'name': 'VectorspaceAI on xDai',
            'address': '0x020Ae8FC1c19f4d1312Cf6a72291f52849791E7C',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x020Ae8FC1c19f4d1312Cf6a72291f52849791E7C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'wNXM',
            'name': 'Wrapped NXM on xDai',
            'address': '0x01e92E3791f8c1D6599B2F80A4bFF9b43949aC7C',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://elk.finance/tokens/logos/xdai/0x01e92E3791f8c1D6599B2F80A4bFF9b43949aC7C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ENS',
            'name': 'Ethereum Name Service from Mainnet',
            'address': '0x012e2CaFEbC30a603C049159D946c9d344d979a8',
            'decimals': 18,
            'chainId': 100,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'UNCX',
            'name': 'UniCrypt',
            'address': '0x0116e28B43A358162B96f70B4De14C98A4465f25',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://tokens.1inch.io/0xadb2437e6f65682b85f814fbc12fec0508a7b1d0.png',
            'coingeckoId': 'unicrypt-2',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'xDAI',
            'name': 'xDAI',
            'address': '0x0000000000000000000000000000000000000000',
            'decimals': 18,
            'chainId': 100,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1641700780963_888840142160457.png',
            'coingeckoId': 'gnosis',
            'listedIn': [
                '1inch',
                'openocean',
                'rubic',
                'lifinance'
            ]
        }
    ],
    '1': [
        {
            'symbol': 'FSW',
            'name': 'Falconswap',
            'address': '0xfffffffFf15AbF397dA76f1dcc1A1604F45126DB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xfffffffff15abf397da76f1dcc1a1604f45126db.png',
            'coingeckoId': 'fsw-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'URAC',
            'name': 'Uranus',
            'address': '0xff8Be4B22CeDC440591dcB1E641EB2a0dd9d25A5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4683/thumb/Leood48Z_400x400.jpg?1563118461',
            'coingeckoId': 'uranus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASD',
            'name': 'AscendEx',
            'address': '0xff742d05420B6Aca4481F635aD8341F81A6300C2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5003/thumb/bitmax.png?1621310871',
            'coingeckoId': 'asd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KISHIBURNO',
            'name': 'Kishiburno',
            'address': '0xff5C69e8edc6446a1898698adCf9B1149d5E0306',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26751/thumb/coing_gecko.jpg?1660020251',
            'coingeckoId': 'kishiburno',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORBS',
            'name': 'Orbs',
            'address': '0xff56Cc6b1E6dEd347aA0B7676C85AB0B3D08B0FA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xff56cc6b1e6ded347aa0b7676c85ab0b3d08b0fa.png',
            'coingeckoId': 'orbs',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BITX',
            'name': 'BitScreener',
            'address': '0xff2b3353c3015E9f1FBF95B9Bda23F58Aa7cE007',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4241/thumb/bitx.png?1547039570',
            'coingeckoId': 'bitscreener',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ART',
            'name': 'Maecenas',
            'address': '0xfec0cF7fE078a500abf15F1284958F22049c2C7e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1025/thumb/maecenas.png?1547034953',
            'coingeckoId': 'maecenas',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VIDT',
            'name': 'VIDT Datalink',
            'address': '0xfeF4185594457050cC9c23980d301908FE057Bb1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xfef4185594457050cc9c23980d301908fe057bb1.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SNOW',
            'name': 'Snowswap',
            'address': '0xfe9A29aB92522D14Fc65880d817214261D8479AE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xfe9a29ab92522d14fc65880d817214261d8479ae.png',
            'coingeckoId': 'snowswap',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'LBA',
            'name': 'Libra Credit',
            'address': '0xfe5F141Bf94fE84bC28deD0AB966c16B17490657',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3673/thumb/libra-credit.png?1547975828',
            'coingeckoId': 'libra-credit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALGOHEDGE',
            'name': '1X Short Algorand Token',
            'address': '0xfdc3D57eB7839ca68A2fAD7A93799c8e8aFA61B7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11987/thumb/683JEXMN_400x400.png?1596687107',
            'coingeckoId': '1x-short-algorand-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SKRP',
            'name': 'Skraps',
            'address': '0xfdFE8b7aB6CF1bD1E3d14538Ef40686296C42052',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7987/thumb/60EnDfxK_400x400.jpg?1552888803',
            'coingeckoId': 'skraps',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STELLARINU',
            'name': 'StellarInu',
            'address': '0xfdE57fee4bcCA80485714bd640d6e5aFE8aC4D66',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20615/thumb/Screenshot-2021-11-18-at-20-09-23.png?1637307533',
            'coingeckoId': 'stellarinu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GIL',
            'name': 'Gilgamesh ETH',
            'address': '0xfdE19f0de7a4E7ECa8AB29c9f202A21A3B3503De',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19882/thumb/GILcoin.png?1636085605',
            'coingeckoId': 'gilgamesh-eth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'API',
            'name': 'The APIS',
            'address': '0xfd4168e642EbD04C3684A6cDb3A5E86DE85d3908',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14055/thumb/Screenshot_2022-03-10_at_11.09.50_AM.png?1646882043',
            'coingeckoId': 'the-apis',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KALLY',
            'name': 'Kally',
            'address': '0xfd30C9BEA1A952FEeEd2eF2C6B2Ff8A8FC4aAD07',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15812/thumb/polkally.PNG?1621979722',
            'coingeckoId': 'polkally',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MAIN',
            'name': 'Main',
            'address': '0xfcEB206E1a80527908521121358B5e26cAABaa75',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25258/thumb/logo_circle.png?1651042603',
            'coingeckoId': 'main',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IXT',
            'name': 'iXledger',
            'address': '0xfcA47962D45ADFdfd1Ab2D972315dB4ce7CCf094',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/814/thumb/ixledger.png?1547034602',
            'coingeckoId': 'insurex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PROM',
            'name': 'Prom',
            'address': '0xfc82bb4ba86045Af6F327323a46E80412b91b27d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xfc82bb4ba86045af6f327323a46e80412b91b27d.png',
            'coingeckoId': 'prometeus',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ICSA',
            'name': 'Icosa',
            'address': '0xfc4913214444aF5c715cc9F7b52655e788A569ed',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27708/thumb/icsa.e2b79cbc.png?1665314034',
            'coingeckoId': 'icosa',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NA',
            'name': 'Its Not Art',
            'address': '0xfc3718CD921549a2cb67636b82a01d067726C428',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27039/thumb/NA.png?1661501367',
            'coingeckoId': 'its-not-art',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TOTORO',
            'name': 'Totoro Inu',
            'address': '0xfc09c7cFD9c175DD9423ca02aE1249579AB12F12',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20277/thumb/cLogo.png?1636708928',
            'coingeckoId': 'totoro-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ESS',
            'name': 'Essentia',
            'address': '0xfc05987bd2be489ACCF0f509E44B0145d68240f7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xfc05987bd2be489accf0f509e44b0145d68240f7.png',
            'coingeckoId': 'essentia',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'DPET',
            'name': 'My DeFi Pet',
            'address': '0xfb62AE373acA027177D1c18Ee0862817f9080d08',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15321/thumb/mydefi.PNG?1620512455',
            'coingeckoId': 'my-defi-pet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ROBOT',
            'name': 'Robot',
            'address': '0xfb5453340C03db5aDe474b27E68B6a9c6b2823Eb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xfb5453340c03db5ade474b27e68b6a9c6b2823eb.png',
            'coingeckoId': 'robot',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ROTTS',
            'name': 'ROTTSCHILD.com',
            'address': '0xfb40e79E56cc7D406707B66C4Fd175E07EB2Ae3C',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xfb40e79e56cc7d406707b66c4fd175e07eb2ae3c.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'DOGEGF',
            'name': 'DogeGF',
            'address': '0xfb130d93E49DcA13264344966A611dc79a456Bc5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18651/thumb/dogf.png?1632758659',
            'coingeckoId': 'dogegf',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEFI5',
            'name': 'DEFI Top 5 Tokens Index',
            'address': '0xfa6de2697D59E88Ed7Fc4dFE5A33daC43565ea41',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/8430.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'VCK',
            'name': '28VCK',
            'address': '0xfa5B75a9e13Df9775cf5b996A049D9cc07c15731',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20063/thumb/9435.png?1636446186',
            'coingeckoId': '28vck',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BDAMM',
            'name': 'Bonded dAMM',
            'address': '0xfa372fF1547fa1a283B5112a4685F1358CE5574d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27646/thumb/t9rbdwlR_400x400.jpg?1665022129',
            'coingeckoId': 'bonded-damm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUN',
            'name': 'Lunyr',
            'address': '0xfa05A73FfE78ef8f1a739473e462c54bae6567D9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/735/thumb/lunyr.png?1547976492',
            'coingeckoId': 'lunyr',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COMBO',
            'name': 'Furucombo',
            'address': '0xfFffFffF2ba8F66D4e51811C5190992176930278',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xffffffff2ba8f66d4e51811c5190992176930278.png',
            'coingeckoId': 'furucombo',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'VOLT',
            'name': 'Voltage',
            'address': '0xfFbF315f70E458e49229654DeA4cE192d26f9b25',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18515/thumb/volt.png?1632219924',
            'coingeckoId': 'voltage',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FL',
            'name': 'Freeliquid',
            'address': '0xfFED56a180f23fD32Bc6A1d8d3c09c283aB594A8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13698/thumb/freeliquid_logo.png?1610980336',
            'coingeckoId': 'freeliquid',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LBXC',
            'name': 'LUX BIO EXCHANGE COIN',
            'address': '0xfFE510a92434a0Df346C5E72a3494b043Cf249eB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9482/thumb/sam7Fy3A_400x400.jpg?1568000741',
            'coingeckoId': 'lux-bio-exchange-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APEUSD',
            'name': 'ApeUSD',
            'address': '0xfF709449528B6fB6b88f557F7d93dEce33bca78D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25621/small/ApeUSD.png?1652857577',
            'coingeckoId': 'apeusd',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'TESINU',
            'name': 'Tesla Inu',
            'address': '0xfF69e48af1174Da7F15D0c771861c33d3f19eD8a',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17468/thumb/400_filter_nobg_60c70a3c5aae1.jpg?1628214173',
            'coingeckoId': 'tesla-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'nICE',
            'name': 'StakedIceTokens',
            'address': '0xfF3Ac80c1caA08Cbd43a7e90d20c398d54C7342f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AMP',
            'name': 'Amp',
            'address': '0xfF20817765cB7f73d4bde2e66e067E58D11095C2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xff20817765cb7f73d4bde2e66e067e58d11095c2.png',
            'coingeckoId': 'amp-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NAGI',
            'name': 'Izanagi',
            'address': '0xfEB2D480019bc605a2Ce20903a90Db3f554F1E1c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24129/thumb/photo_2022-03-04_23-02-56.jpg?1646481376',
            'coingeckoId': 'izanagi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SBTC',
            'name': 'sBTC',
            'address': '0xfE18be6b3Bd88A2D2A7f928d00292E7a9963CfC6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xfe18be6b3bd88a2d2a7f928d00292e7a9963cfc6.png',
            'coingeckoId': 'sbtc',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'mooCurveAm3CRV',
            'name': 'Moo Curve am3CRV',
            'address': '0xfDECfC325B584A96E86caBD2eb492320467ed1d8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SCIFI',
            'name': 'SCIFI Index',
            'address': '0xfDC4a3FC36df16a78edCAf1B837d3ACAaeDB2CB4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13868/thumb/yaxtD4c.png?1624955109',
            'coingeckoId': 'scifi-index',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '1WO',
            'name': '1World',
            'address': '0xfDBc1aDc26F0F8f8606a5d63b7D3a3CD21c22B23',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3443/thumb/unnamed.png?1547038151',
            'coingeckoId': '1world',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HUSKY',
            'name': 'Husky VC',
            'address': '0xfCDc835939a23C5DB3f056470341E1947BB7A552',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27353/thumb/logo_husky200.png?1663659027',
            'coingeckoId': 'husky-vc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KEES',
            'name': 'Korea Entertainment Education   Shoppin',
            'address': '0xfCC63e6459936216b7b887F6dA5fCD90c0C62b7c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27513/thumb/lo2XCLcf_400x400.jpeg?1664351911',
            'coingeckoId': 'korea-entertainment-education-shopping',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MCO2',
            'name': 'Moss Carbon Credit',
            'address': '0xfC98e825A2264D890F9a1e68ed50E1526abCcacD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xfc98e825a2264d890f9a1e68ed50e1526abccacd.png',
            'coingeckoId': 'moss-carbon-credit',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ADAI',
            'name': 'Aave DAI v1',
            'address': '0xfC1E690f61EFd961294b3e1Ce3313fBD8aa4f85d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xfc1e690f61efd961294b3e1ce3313fbd8aa4f85d.png',
            'coingeckoId': 'aave-dai-v1',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ELT',
            'name': 'EdenLoop',
            'address': '0xfC1Cb4920dC1110fD61AfaB75Cf085C1f871b8C6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24746/thumb/voRjghoA_400x400.jpg?1648784263',
            'coingeckoId': 'edenloop',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DHOLD',
            'name': 'DeFi Holdings',
            'address': '0xfBa34C7Da885885c597b8eeaB61fDC0EEe7ACf3C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23094/thumb/DefiHoldings_Logo.png?1643182605',
            'coingeckoId': 'defi-holdings',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEXT',
            'name': 'DexTools',
            'address': '0xfB7B4564402E5500dB5bB6d63Ae671302777C75a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x26ce25148832c04f3d7f26f32478a9fe55197166.png',
            'coingeckoId': 'dextools',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'PSTAKE',
            'name': 'pSTAKE Finance',
            'address': '0xfB5c6815cA3AC72Ce9F5006869AE67f18bF77006',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23931/thumb/PSTAKE_Dark.png?1645709930',
            'coingeckoId': 'pstake-finance',
            'listedIn': [
                'coingecko',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'PROB',
            'name': 'Probit',
            'address': '0xfB559CE67Ff522ec0b9Ba7f5dC9dc7EF6c139803',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6765/thumb/ProBit-Exchange-logo.png?1547043029',
            'coingeckoId': 'probit-exchange',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZIOT',
            'name': 'Ziot',
            'address': '0xfB22cED41B1267dA411F68c879f4Defd0bD4796a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14546/thumb/1_iSvPXhw4frHgjyMejcmoKQ.png?1616973916',
            'coingeckoId': 'ziot',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HOGE',
            'name': 'Hoge Finance',
            'address': '0xfAd45E47083e4607302aa43c65fB3106F1cd7607',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xfad45e47083e4607302aa43c65fb3106f1cd7607.png',
            'coingeckoId': 'hoge-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ONDO',
            'name': 'Ondo Finance',
            'address': '0xfAbA6f8e4a5E8Ab82F62fe7C39859FA577269BE3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26580/thumb/YUBxfjcj_400x400.jpg?1658888594',
            'coingeckoId': 'ondo-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CPC',
            'name': 'CPChain',
            'address': '0xfAE4Ee59CDd86e3Be9e8b90b53AA866327D7c090',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2760/thumb/cpchain.png?1547036953',
            'coingeckoId': 'cpchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CBT',
            'name': 'Community Business Token',
            'address': '0xfA93660C3f6a848556Bb8E265f994160A1F2B289',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14701/thumb/CBT-Token-Small.png?1617838284',
            'coingeckoId': 'community-business-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ROOK',
            'name': 'Rook',
            'address': '0xfA5047c9c78B8877af97BDcb85Db743fD7313d4a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xfa5047c9c78b8877af97bdcb85db743fd7313d4a.png',
            'coingeckoId': 'rook',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'CXDOGE',
            'name': 'CelsiusX Wrapped DOGE',
            'address': '0xf9e293D5D793DDc1Ae4F778761e0b3E4aA7cF2dD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23683/thumb/R2747rb.png?1644997607',
            'coingeckoId': 'celsiusx-wrapped-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CASTLE',
            'name': 'bitcastle',
            'address': '0xf9d4DAaE1300CfF251979722C4A3C45857973079',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26551/thumb/blue_logo03.png?1658753049',
            'coingeckoId': 'bitcastle',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DGM',
            'name': 'DeGEM',
            'address': '0xf9a7e47f6D00a75bE08BB23a804f4153c01a1636',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27449/thumb/556.JPG?1664017738',
            'coingeckoId': 'degemeth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AAMMWETH',
            'name': 'Aave AMM WETH',
            'address': '0xf9Fb4AD91812b704Ba883B11d2B576E890a6730A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17259/thumb/aAMMWETH_2x.png?1626958225',
            'coingeckoId': 'aave-amm-weth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RADAR',
            'name': 'Radar',
            'address': '0xf9FBE825BFB2bF3E387af0Dc18caC8d87F29DEa8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf9fbe825bfb2bf3e387af0dc18cac8d87f29dea8.png',
            'coingeckoId': 'radar',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ANY',
            'name': 'Anyswap',
            'address': '0xf99d58e463A2E07e5692127302C20A191861b4D6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf99d58e463a2e07e5692127302c20a191861b4d6.png',
            'coingeckoId': 'anyswap',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'KIRA',
            'name': 'KIRA',
            'address': '0xf98AB0874b13a7Fdc39D7295DEdd49850A5D426B',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28196/thumb/KIRA_CMC.png?1668334302',
            'coingeckoId': 'kira',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZYX',
            'name': 'ZYX',
            'address': '0xf974b5f9Ac9c6632FeE8b76C61B0242ce69C839D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11964/thumb/zyx.png?1596454904',
            'coingeckoId': 'zyx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MCAT20',
            'name': 'Wrapped Moon Cats',
            'address': '0xf961A1Fa7C781Ecd23689fE1d0B7f3B6cBB2f972',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14387/thumb/MCAT20.png?1615803341',
            'coingeckoId': 'wrapped-moon-cats',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOTO',
            'name': 'Lotoblock',
            'address': '0xf947B0824c3995787EFC899017A36bC9f281265e',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6611/thumb/xkKemBDV_400x400.jpg?1547042813',
            'coingeckoId': 'lotoblock',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HEEL',
            'name': 'Good Dog',
            'address': '0xf941D3AAbf2EE0F5589E68Ba6047b8329592B366',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27138/thumb/Heel_Circle.png?1662029905',
            'coingeckoId': 'good-dog',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABIL',
            'name': 'BABIL TOKEN',
            'address': '0xf92364c2369a2633FfcD7dB1B18D1Fafff6bCbaB',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27252/thumb/PKUhX3jRL.png?1666258453',
            'coingeckoId': 'babil-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAX',
            'name': 'BABB',
            'address': '0xf920e4F3FBEF5B3aD0A25017514B769bDc4Ac135',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2168/thumb/babb.png?1547036466',
            'coingeckoId': 'babb',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LIFT',
            'name': 'LIFT.Kitchen',
            'address': '0xf9209d900f7ad1DC45376a2caA61c78f6dEA53B6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xf9209d900f7ad1DC45376a2caA61c78f6dEA53B6/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'LOL',
            'name': 'LOL',
            'address': '0xf91AC30E9b517f6D57e99446ee44894e6C22C032',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20354/thumb/lollogo.png?1648442855',
            'coingeckoId': 'lol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XAMP',
            'name': 'Antiample',
            'address': '0xf911a7ec46a2c6fa49193212fe4a2a9B95851c27',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf911a7ec46a2c6fa49193212fe4a2a9b95851c27.png',
            'coingeckoId': 'antiample',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'IND',
            'name': 'Indorse',
            'address': '0xf8e386EDa857484f5a12e4B5DAa9984E06E73705',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf8e386eda857484f5a12e4b5daa9984e06e73705.png',
            'coingeckoId': 'indorse',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ABL',
            'name': 'Airbloc',
            'address': '0xf8b358b3397a8ea5464f8cc753645d42e14b79EA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4393/thumb/airbloc-protocol-logo.png?1547039734',
            'coingeckoId': 'airbloc-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLY',
            'name': 'Blocery',
            'address': '0xf8aD7dFe656188A23e89da09506Adf7ad9290D5d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12095/thumb/Blocery_logo_200x200_.png?1597046107',
            'coingeckoId': 'blocery',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOE',
            'name': 'Dogs Of Elon',
            'address': '0xf8E9F10c22840b613cdA05A0c5Fdb59A4d6cd7eF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf8e9f10c22840b613cda05a0c5fdb59a4d6cd7ef.png',
            'coingeckoId': 'dogsofelon',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'EXCHHEDGE',
            'name': '1X Short Exchange Token Index Token',
            'address': '0xf8CC67e304f8e1A351ED83b4DBBe6B4076d51376',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11985/thumb/683JEXMN_400x400__1_.png?1596683946',
            'coingeckoId': '1x-short-exchange-token-index-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FRONT',
            'name': 'Frontier',
            'address': '0xf8C3527CC04340b208C854E985240c02F7B7793f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf8c3527cc04340b208c854e985240c02f7b7793f.png',
            'coingeckoId': 'frontier-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'LITH',
            'name': 'Lith  OLD ',
            'address': '0xf8A4A419c2d7140e49eF952a7e7Ae1Bd4A8b6B9C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16199/thumb/200x200px_LOGO.png?1657177574',
            'coingeckoId': 'lith-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WMLK',
            'name': 'Wrapped MiL.k',
            'address': '0xf87C4B9C0c1528147CAc4E05b7aC349A9Ab23A12',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf87c4b9c0c1528147cac4e05b7ac349a9ab23a12.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'CMT',
            'name': 'CyberMiles',
            'address': '0xf85fEea2FdD81d51177F6b8F35F0e6734Ce45F5F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1300/thumb/cybermiles.png?1547035327',
            'coingeckoId': 'cybermiles',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SODATSU',
            'name': 'Sodatsu',
            'address': '0xf8497f1dab075A3674363B5609Ba679F7dc9C885',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28061/thumb/SODATSU_Token_Logo_-_200x200.png?1667300564',
            'coingeckoId': 'sodatsu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ICH',
            'name': 'IdeaChain',
            'address': '0xf8483E2d6560585C02D46bF7B3186Bf154a96166',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11053/thumb/4fJlFzca_400x400.jpg?1587609361',
            'coingeckoId': 'ideachain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'uvwFi',
            'name': 'uvwFi.finance',
            'address': '0xf83ae621A52737e3Ef9307af91df834Ed8431aC3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xf83ae621a52737e3ef9307af91df834ed8431ac3.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'FIFTY',
            'name': 'FIFTYONEFIFTY',
            'address': '0xf7DD746A613Fb6362D44eCEDEB743f62ade6c3aa',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18207/thumb/5150icon.png?1630980446',
            'coingeckoId': 'fiftyonefifty',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GUP',
            'name': 'Guppy',
            'address': '0xf7B098298f7C69Fc14610bf71d5e02c60792894C',
            'decimals': 3,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/683/thumb/matchpool.png?1547034437',
            'coingeckoId': 'matchpool',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABYSAITAMA',
            'name': 'Baby Saitama',
            'address': '0xf79F9020560963422eCC9C0c04D3a21190BBf045',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17584/thumb/babysaitama.png?1635907049',
            'coingeckoId': 'baby-saitama',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APYS',
            'name': 'APYSwap',
            'address': '0xf7413489c474ca4399eeE604716c72879Eea3615',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14163/thumb/apys.png?1635831990',
            'coingeckoId': 'apyswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MVIXY',
            'name': 'Mirrored ProShares',
            'address': '0xf72FCd9DCF0190923Fadd44811E240Ef4533fc86',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/mvixy.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'APAD',
            'name': 'Alpha Pad',
            'address': '0xf722B7b4876Cbba560846556dD1F5932eb503DE2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24324/thumb/Untitled-design-1.png?1647357411',
            'coingeckoId': 'alpha-pad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UPR',
            'name': 'Upfire',
            'address': '0xf720E38F678B29B243F7D53B56Acbf5dE98F2385',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20562/thumb/UPR256.png?1637214757',
            'coingeckoId': 'upfire',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GENE',
            'name': 'Gene',
            'address': '0xf6ec87DFE1Ed3a7256Cc0c38e3c8139103e9aF3b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14145/thumb/logo.a60a0c80_%281%29.png?1614653629',
            'coingeckoId': 'gene',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CELT',
            'name': 'Celestial',
            'address': '0xf6e06B54855eFf198a2d9A8686113665499a6134',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18683/thumb/celt.png?1632966755',
            'coingeckoId': 'celestial',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'X1',
            'name': 'MetaX',
            'address': '0xf6c2A37AcC8Ba45874808837486110dC0Afc63C8',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27411/thumb/pNi9ej83_400x400.png?1663853065',
            'coingeckoId': 'metax',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USD1',
            'name': 'Psyche',
            'address': '0xf6c0aA7eBFE9992200C67E5388E4F42da49E1783',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13661/thumb/Psyche-logo-256.png?1610600682',
            'coingeckoId': 'psyche',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SBLX',
            'name': 'SocialBlox',
            'address': '0xf6af18F94d55B8888B557cb3da0Ec623BA245F80',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24769/thumb/x86VE7oQ.png?1648817788',
            'coingeckoId': 'socialblox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CXBTC',
            'name': 'CelsiusX Wrapped BTC',
            'address': '0xf6a9baEf8C66e9B24152014222871c8E02d0ee97',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25535/thumb/cxBTC.png?1652252129',
            'coingeckoId': 'celsiusx-wrapped-btc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HEAL',
            'name': 'Etheal',
            'address': '0xf6Fd82dEdBBe0ffadb5e1ecc2a283AB52B9ED2B0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3267/thumb/heal.png?1547037807',
            'coingeckoId': 'etheal',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHB',
            'name': 'COINHUB',
            'address': '0xf68D4d917592f3a62417aCE42592F15296cc33A0',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26818/thumb/4DBF1DAE-0930-4925-BD70-1A446F267546.jpeg?1660278721',
            'coingeckoId': 'coinhub',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FRMX',
            'name': 'FRMx',
            'address': '0xf6832EA221ebFDc2363729721A146E6745354b14',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13082/thumb/frmx.png?1660311822',
            'coingeckoId': 'frmx-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BFLY',
            'name': 'Butterfly Protocol',
            'address': '0xf680429328caaaCabee69b7A9FdB21a71419c063',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf680429328caaacabee69b7a9fdb21a71419c063.png',
            'coingeckoId': 'butterfly-protocol-2',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'PGEN',
            'name': 'Polygen',
            'address': '0xf6719e1A8fcBB1B9c290019E37e004966A8916C9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21476/thumb/polygen-logo_1.jpeg?1639350369',
            'coingeckoId': 'polygen',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COREDAO',
            'name': 'coreDAO',
            'address': '0xf66Cd2f8755a21d3c8683a10269F795c0532Dd58',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23745/thumb/coredao.png?1645273627',
            'coingeckoId': 'coredao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRU',
            'name': 'Truebit Protocol',
            'address': '0xf65B5C5104c4faFD4b709d9D60a185eAE063276c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf65b5c5104c4fafd4b709d9d60a185eae063276c.png',
            'coingeckoId': 'truebit-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'GIV',
            'name': 'GIV',
            'address': '0xf6537FE0df7F0Cc0985Cf00792CC98249E73EFa0',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8996/thumb/giv.png?1629952637',
            'coingeckoId': 'givly-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CUSDT',
            'name': 'cUSDT',
            'address': '0xf650C3d88D12dB855b8bf7D11Be6C55A4e07dCC9',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf650c3d88d12db855b8bf7d11be6c55a4e07dcc9.png',
            'coingeckoId': 'compound-usdt',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'PORT',
            'name': 'Teleport',
            'address': '0xf6158bdFE9e013673269b4D1ca468E8eFD77Ca3f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27342/thumb/logo.png?1663552195',
            'coingeckoId': 'teleport',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JELLY',
            'name': 'Jelly',
            'address': '0xf5f06fFa53Ad7F5914F493F16E57B56C8dd2eA80',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf5f06ffa53ad7f5914f493f16e57b56c8dd2ea80.png',
            'coingeckoId': 'jelly',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'RIZE',
            'name': 'Rizespor Token',
            'address': '0xf5bb30EBC95dcA53e3320eb05d3d1bcAb806B9bF',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24027/thumb/mqQwSHB__400x400.jpg?1646113446',
            'coingeckoId': 'rizespor-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KISHIMOTO',
            'name': 'Kishimoto  old ',
            'address': '0xf5b1Fd29d23e98Db2D9EBb8435E1082e3B38FB65',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19188/thumb/cmclogo.png?1666922508',
            'coingeckoId': 'kishimoto-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLOOR',
            'name': 'FloorDAO',
            'address': '0xf59257E961883636290411c11ec5Ae622d19455e',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24026/thumb/icon-floor_2x.png?1646102857',
            'coingeckoId': 'floordao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAMBOO',
            'name': 'BambooDeFi',
            'address': '0xf56842Af3B56Fd72d17cB103f92d027bBa912e89',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13856/thumb/Bamboo-token-logo-200.png?1612404311',
            'coingeckoId': 'bamboo-defi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RIOT',
            'name': 'Riot Racers',
            'address': '0xf56408077487cB879c992909C5b5C66D68c02Eb4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19238/thumb/jyxvIbmJ_400x400.png?1634774849',
            'coingeckoId': 'riot-racers',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PMD',
            'name': 'Promodio',
            'address': '0xf5555732B3925356964695578feFcFfCD31bCbb8',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18423/thumb/pmd_%281%29.png?1631858128',
            'coingeckoId': 'promodio',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MORK',
            'name': 'MORK',
            'address': '0xf552b656022c218C26dAd43ad88881Fc04116F76',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12183/thumb/mork-logo.png?1597941710',
            'coingeckoId': 'mork',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KRZ',
            'name': 'Kranz',
            'address': '0xf54B304e2e4b28c7E46619D1A340F9b2B72383D7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17688/thumb/KRZ-Extra-Small-Logo-200x200-1.png?1654230602',
            'coingeckoId': 'kranz-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OPP',
            'name': 'Open Proprietary Protocol',
            'address': '0xf51ccB15d3F2D304Ae6C409303e8b3a3e397A80b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27417/thumb/1_YTzF4KXNbEMRykWKGgKpwg.jpeg?1663858531',
            'coingeckoId': 'open-proprietary-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APIX',
            'name': 'APIX',
            'address': '0xf51EBf9a26DbC02B13F8B3a9110dac47a4d62D78',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10124/thumb/5sSKmtlA_400x400.png?1576126911',
            'coingeckoId': 'apix',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLOKIPUP',
            'name': 'Floki Pup',
            'address': '0xf50a07e4FF052A14f3f608DA8936D8AE0Ed5bE50',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf50a07e4ff052a14f3f608da8936d8ae0ed5be50.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'LOOKS',
            'name': 'LooksRare',
            'address': '0xf4d2888d29D722226FafA5d9B24F9164c092421E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf4d2888d29d722226fafa5d9b24f9164c092421e.png',
            'coingeckoId': 'looksrare',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'AGV',
            'name': 'Astra Guild Ventures',
            'address': '0xf4F618Eff5eF36Cde2FCa4FBD86554c62Fb1382B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21603/thumb/astra_guild.PNG?1639551997',
            'coingeckoId': 'astra-guild-ventures',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YF-DAI',
            'name': 'YfDAI finance',
            'address': '0xf4CD3d3Fda8d7Fd6C5a500203e38640A70Bf9577',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf4cd3d3fda8d7fd6c5a500203e38640a70bf9577.png',
            'coingeckoId': 'yfdai-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'LEVX',
            'name': 'LevX DAO',
            'address': '0xf474E526ADe9aD2CC2B66ffCE528B1A51B91FCdC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/logos/main/network/ethereum/0xf474E526ADe9aD2CC2B66ffCE528B1A51B91FCdC.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'MHUNT',
            'name': 'MetaShooter',
            'address': '0xf460d98a3517b45Edd8063dd3847de92a8038aa2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://static.debank.com/image/eth_token/logo_url/0xf460d98a3517b45edd8063dd3847de92a8038aa2/9984d8a66ebbce79ac1c5f84166c272d.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'LEMD',
            'name': 'Lemond',
            'address': '0xf45f6c8bB3d77eA762175b8f7ca4D251941649fA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14964/thumb/D-smP_i-_400x400.png?1619166250',
            'coingeckoId': 'lemond',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALIEN',
            'name': 'Alien Inu',
            'address': '0xf42965f82f9e3171d1205C5e3058Caf324a09432',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20719/thumb/alien_logo.png?1642675038',
            'coingeckoId': 'alien-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PUSH',
            'name': 'Push Protocol',
            'address': '0xf418588522d5dd018b425E472991E52EBBeEEEEE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf418588522d5dd018b425e472991e52ebbeeeeee.png',
            'coingeckoId': 'ethereum-push-notification-service',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ONEPIECE',
            'name': 'ONE PIECE',
            'address': '0xf40eDF22e4aB146a32eCD8d659Cedc7A2a55Cd43',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19421/thumb/g842dxb.png?1635213981',
            'coingeckoId': 'one-piece',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BDP',
            'name': 'Big Data Protocol',
            'address': '0xf3dcbc6D72a4E1892f7917b7C43b74131Df8480e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf3dcbc6d72a4e1892f7917b7c43b74131df8480e.png',
            'coingeckoId': 'big-data-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'HATAY',
            'name': 'Hatayspor Token',
            'address': '0xf3c6327B4c58e38A7986Edb4a8F236031708f280',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24813/thumb/5pj7q96D_400x400.png?1649027829',
            'coingeckoId': 'hatayspor-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CAW',
            'name': 'A Hunters Dream',
            'address': '0xf3b9569F82B18aEf890De263B84189bd33EBe452',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf3b9569f82b18aef890de263b84189bd33ebe452.png',
            'coingeckoId': 'a-hunters-dream',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'EVX',
            'name': 'Everex',
            'address': '0xf3Db5Fa2C66B7aF3Eb0C0b782510816cbe4813b8',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/997/thumb/everex.png?1548125695',
            'coingeckoId': 'everex',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'DPR',
            'name': 'Deeper Network',
            'address': '0xf3AE5d769e153Ef72b4e3591aC004E89F48107a1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf3ae5d769e153ef72b4e3591ac004e89f48107a1.png',
            'coingeckoId': 'deeper-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'HXY',
            'name': 'HXY Money',
            'address': '0xf3A2ace8e48751c965eA0A1D064303AcA53842b9',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11225/thumb/hexmoneygradientsmall.png?1595305947',
            'coingeckoId': 'hex-money',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RBIS',
            'name': 'ArbiSmart',
            'address': '0xf34B1Db61ACa1a371fE97BAd2606c9f534fb9D7D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21906/thumb/imgonline-com-ua-Resize-cMjOYOwg12bLazt.png?1640235509',
            'coingeckoId': 'arbismart-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WEB',
            'name': 'Webcoin',
            'address': '0xf344490ABd414DaFa97f34c8Ca501B0aa5Bf8873',
            'decimals': 1,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3779/thumb/webcoin-logo.png?1547038866',
            'coingeckoId': 'webcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAG',
            'name': 'Blockchain Adventurers Guild',
            'address': '0xf33121A2209609cAdc7349AcC9c40E41CE21c730',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14863/thumb/3iw7MAi.png?1618810870',
            'coingeckoId': 'blockchain-adventurers-guild',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEFIDO',
            'name': 'DeFido',
            'address': '0xf32122561d51E891B823Dec2B42F644884c1Cd91',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18861/thumb/defido.png?1635638445',
            'coingeckoId': 'defido',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASTA',
            'name': 'ASTA',
            'address': '0xf2ddae89449b7D26309a5D54614B1FC99C608AF5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11123/thumb/asta_logo.png?1588734656',
            'coingeckoId': 'asta',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTCB',
            'name': 'BitcoinBrand',
            'address': '0xf2cee90309418353a57717ECa26C4f8754F0d84e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6607/thumb/y76xcDSr_400x400.jpg?1547042811',
            'coingeckoId': 'bitcoinbrand',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALY',
            'name': 'Ally',
            'address': '0xf2CDF38e24738bA379ffa38D47bc88a941dF5627',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9619/thumb/BTf_sTXi_400x400.jpg?1569854715',
            'coingeckoId': 'ally',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNC',
            'name': 'UniCrypt',
            'address': '0xf29e46887FFAE92f1ff87DfE39713875Da541373',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf29e46887ffae92f1ff87dfe39713875da541373.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SYLO',
            'name': 'Sylo',
            'address': '0xf293d23BF2CDc05411Ca0edDD588eb1977e8dcd4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf293d23bf2cdc05411ca0eddd588eb1977e8dcd4.png',
            'coingeckoId': 'sylo',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'RUFF',
            'name': 'Ruff',
            'address': '0xf278c1CA969095ffddDED020290cf8B5C424AcE2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2726/thumb/ruff.png?1548608556',
            'coingeckoId': 'ruff',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CEDS',
            'name': 'CEDARS',
            'address': '0xf256f3ac4176126F55af7c6E25fA5F3Cdf518b38',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12255/thumb/cedars.png?1598522772',
            'coingeckoId': 'cedars',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ISDT',
            'name': 'Istardust',
            'address': '0xf2354F740f31704820f6FcfBA70B9dA065459b62',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12568/thumb/istardust.png?1600840530',
            'coingeckoId': 'istardust',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RIO',
            'name': 'Realio Network',
            'address': '0xf21661D0D1d76d3ECb8e1B9F1c923DBfffAe4097',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12206/thumb/0.jpg?1598083003',
            'coingeckoId': 'realio-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HAUS',
            'name': 'DAOhaus',
            'address': '0xf2051511B9b121394FA75B8F7d4E7424337af687',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14551/thumb/jN3kkqke_400x400.png?1616990048',
            'coingeckoId': 'daohaus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WNCG',
            'name': 'Wrapped NCG',
            'address': '0xf203Ca1769ca8e9e8FE1DA9D147DB68B6c919817',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/wncg.jpg',
            'coingeckoId': 'wrapped-ncg',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'RLY',
            'name': 'Rally',
            'address': '0xf1f955016EcbCd7321c7266BccFB96c68ea5E49b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf1f955016ecbcd7321c7266bccfb96c68ea5e49b.png',
            'coingeckoId': 'rally-2',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'GOLD',
            'name': 'GoldFarm',
            'address': '0xf1b8762a7fa8C244e36F7234EDF40cFaE24394e3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16108/thumb/rVUV_photo_2021-06-03_13-37-31.png?1623020895',
            'coingeckoId': 'goldfarm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'C3',
            'name': 'CHARLI3',
            'address': '0xf1a91C7d44768070F711c68f33A7CA25c8D30268',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://api.rubic.exchange/assets/ethereum/0xf1a91c7d44768070f711c68f33a7ca25c8d30268/logo.png',
            'coingeckoId': 'charli3',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PPDEX',
            'name': 'Pepedex',
            'address': '0xf1F508c7C9f0d1b15a76fbA564eEf2d956220cf7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13022/thumb/output-onlinepngtools-1.png?1604720841',
            'coingeckoId': 'pepedex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SDL',
            'name': 'Saddle Finance',
            'address': '0xf1Dc500FdE233A4055e25e5BbF516372BC4F6871',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf1dc500fde233a4055e25e5bbf516372bc4f6871.png',
            'coingeckoId': 'saddle-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'OSQTH',
            'name': 'Opyn Squeeth',
            'address': '0xf1B99e3E573A1a9C5E6B2Ce818b617F0E664E86B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22806/thumb/DyVT5XPV_400x400.jpg?1642656239',
            'coingeckoId': 'opyn-squeeth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ICE',
            'name': 'Popsicle Finance',
            'address': '0xf16e81dce15B08F326220742020379B855B87DF9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf16e81dce15b08f326220742020379b855b87df9.png',
            'coingeckoId': 'ice-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'PP',
            'name': 'Pension Plan',
            'address': '0xf14b9ADF84812bA463799357f4dc716b4384010B',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf14b9adf84812ba463799357f4dc716b4384010b.png',
            'coingeckoId': 'pension-plan',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'DIT',
            'name': 'Direct Insurance',
            'address': '0xf14922001A2FB8541a433905437ae954419C2439',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4220/thumb/Direct_Insurance_Token.jpg?1547039537',
            'coingeckoId': 'inmediate',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STACY',
            'name': 'Stacy',
            'address': '0xf12EC0D3Dab64DdEfBdC96474bDe25af3FE1B327',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12986/thumb/Stacy.png?1604384468',
            'coingeckoId': 'stacy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CULT',
            'name': 'Cult DAO',
            'address': '0xf0f9D895aCa5c8678f706FB8216fa22957685A13',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf0f9d895aca5c8678f706fb8216fa22957685a13.png',
            'coingeckoId': 'cult-dao',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ENG',
            'name': 'Enigma',
            'address': '0xf0Ee6b27b759C9893Ce4f094b49ad28fd15A23e4',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1007/thumb/enigma-logo.png?1547034914',
            'coingeckoId': 'enigma',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '1MT',
            'name': '1Million',
            'address': '0xf0Bc1ae4eF7ffb126A8347D06Ac6f8AdD770e1CE',
            'decimals': 7,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8495/thumb/1MTp.png?1586964391',
            'coingeckoId': '1million-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COU',
            'name': 'Couchain',
            'address': '0xf091Cf09c51811819DB705710e9634B8bf18F164',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4529/thumb/couchain.png?1547039825',
            'coingeckoId': 'couchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'THX',
            'name': 'Thorenext',
            'address': '0xf08c68bD5f4194d994FD70726746Bf529eE5a617',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6838/thumb/Screenshot-2018-11-12-at-11-58-10-PM.png?1547043151',
            'coingeckoId': 'thorenext',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CLEVCVX',
            'name': 'CLever CVX',
            'address': '0xf05e58fCeA29ab4dA01A495140B349F8410Ba904',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27902/thumb/%E6%88%AA%E5%B1%8F2022-10-21_%E4%B8%8A%E5%8D%8812.43.46.png?1666334411',
            'coingeckoId': 'clever-cvx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DMX',
            'name': 'Dymmax',
            'address': '0xf058501585023d040eA9493134ED72C083553eED',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13476/thumb/dmmx.png?1608934713',
            'coingeckoId': 'dymmax',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPRING',
            'name': 'Spring',
            'address': '0xf04aF3f4E4929F7CD25A751E6149A3318373d4FE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25364/thumb/spring.png?1651317225',
            'coingeckoId': 'spring',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLIXX',
            'name': 'Flixxo',
            'address': '0xf04a8ac553FceDB5BA99A64799155826C136b0Be',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xf04a8ac553fcedb5ba99a64799155826c136b0be.png',
            'coingeckoId': 'flixxo',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'MATICX',
            'name': 'Stader MaticX',
            'address': '0xf03A7Eb46d01d9EcAA104558C732Cf82f6B6B645',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25383/thumb/6GWyFVHD_400x400.jpg?1651615396',
            'coingeckoId': 'stader-maticx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BMT',
            'name': 'BMCHAIN',
            'address': '0xf028ADEe51533b1B47BEaa890fEb54a457f51E89',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7419/thumb/bchain_logo.jpg?1547199925',
            'coingeckoId': 'bmchain-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNV',
            'name': 'Unvest',
            'address': '0xf009f5531dE69067435e32c4b9D36077F4C4A673',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf009f5531de69067435e32c4b9d36077f4c4a673.png',
            'coingeckoId': 'unvest',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SQUAWK',
            'name': 'Squawk  OLD ',
            'address': '0xeff3f1b9400D6D0f1E8805BddE592F61535F5EcD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25087/thumb/soo.png?1650534077',
            'coingeckoId': 'squawk',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LYNK',
            'name': 'LynKey',
            'address': '0xef952363C1d990A2fa58F8b379A9fa33bad1dfd1',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25069/thumb/oOcTsBsg_400x400_%281%29.jpg?1650038695',
            'coingeckoId': 'lynkey',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DNA',
            'name': 'EncrypGen',
            'address': '0xef6344de1fcfC5F48c30234C16c1389e8CdC572C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1215/thumb/encrypgen.png?1547393601',
            'coingeckoId': 'encrypgen',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FIS',
            'name': 'Stafi',
            'address': '0xef3A930e1FfFFAcd2fc13434aC81bD278B0ecC8d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xef3a930e1ffffacd2fc13434ac81bd278b0ecc8d.png',
            'coingeckoId': 'stafi',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'YSEC',
            'name': 'Yearn Secure',
            'address': '0xeea9aE787f3A620072d13b2cdC8cabFFb9c0aB96',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12993/thumb/ysec.png?1604212852',
            'coingeckoId': 'yearn-secure',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XED',
            'name': 'Exeedme',
            'address': '0xee573a945B01B788B9287CE062A0CFC15bE9fd86',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xee573a945b01b788b9287ce062a0cfc15be9fd86.png',
            'coingeckoId': 'exeedme',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'VYNC',
            'name': 'VYNK Chain',
            'address': '0xee1ae38BE4Ce0074C4A4A8DC821CC784778f378c',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17743/thumb/vynk_chain.PNG?1629150126',
            'coingeckoId': 'vynk-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNIS',
            'name': 'Universe Coin',
            'address': '0xedC87caB8bd12ca39088DeAF9fdfb63503f19f85',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10389/thumb/9lTQkX7d_400x400.jpg?1578868716',
            'coingeckoId': 'universe-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NGM',
            'name': 'e Money',
            'address': '0xed0d5747A9AB03a75fBfEC3228cd55848245B75d',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13722/thumb/logo-200x200.jpg?1626095888',
            'coingeckoId': 'e-money',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MEL',
            'name': 'MELX',
            'address': '0xed0889F7E1c7C7267407222Be277e1f1Ef4d4892',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xed0889f7e1c7c7267407222be277e1f1ef4d4892.png',
            'coingeckoId': 'melalie',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MXNT',
            'name': 'Mexican Peso Tether',
            'address': '0xed03Ed872159e199065401b6d0D487d78d9464AA',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26087/thumb/MXNT_green.jpg?1655772424',
            'coingeckoId': 'mexican-peso-tether',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PERL',
            'name': 'PERL eco',
            'address': '0xeca82185adCE47f39c684352B0439f030f860318',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xeca82185adce47f39c684352b0439f030f860318.png',
            'coingeckoId': 'perlin',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'AXIS',
            'name': 'Axis DeFi',
            'address': '0xecC0F1F860a82aB3b442382D93853C02d6384389',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12199/thumb/YeLWZ3V.jpg?1597998424',
            'coingeckoId': 'axis-defi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MLN',
            'name': 'Enzyme',
            'address': '0xec67005c4E498Ec7f55E092bd1d35cbC47C91892',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xec67005c4e498ec7f55e092bd1d35cbc47c91892.png',
            'coingeckoId': 'melon',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'XIDR',
            'name': 'XIDR',
            'address': '0xebF2096E01455108bAdCbAF86cE30b6e5A72aa52',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xebf2096e01455108badcbaf86ce30b6e5a72aa52.png',
            'coingeckoId': 'straitsx-indonesia-rupiah',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'GTH',
            'name': 'Gather',
            'address': '0xeb986DA994E4a118d5956b02d8b7c3C7CE373674',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12458/thumb/Gather-Logo-Working-File.png?1599981686',
            'coingeckoId': 'gather',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SLAB',
            'name': 'SLINK LABS',
            'address': '0xeb494890465f49C2b94457d9b61811392E5B1Fea',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xeb494890465f49c2b94457d9b61811392e5b1fea.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'USDx',
            'name': 'dForce',
            'address': '0xeb269732ab75A6fD61Ea60b06fE994cD32a83549',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xeb269732ab75a6fd61ea60b06fe994cd32a83549.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'TYPE',
            'name': 'Typerium',
            'address': '0xeaf61FC150CD5c3BeA75744e830D916E60EA5A9F',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4019/thumb/type.png?1547039124',
            'coingeckoId': 'typerium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EOSBULL',
            'name': '3X Long EOS Token',
            'address': '0xeaD7F3ae4e0Bb0D8785852Cc37CC9d0B5e75c06a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10236/thumb/683JEXMN_400x400_%281%29.png?1576632680',
            'coingeckoId': '3x-long-eos-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PAD',
            'name': 'NearPad',
            'address': '0xea7Cc765eBC94C4805e3BFf28D7E4aE48D06468A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xea7Cc765eBC94C4805e3BFf28D7E4aE48D06468A/logo.png',
            'coingeckoId': 'nearpad',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'Mush',
            'name': 'Mushroom',
            'address': '0xea6412Fb370e8d1605E6aEeAA21aD07C3C7e9F24',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/8502.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'PARETO',
            'name': 'Pareto Network Token',
            'address': '0xea5f88E54d982Cbb0c441cde4E79bC305e5b43Bc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xea5f88e54d982cbb0c441cde4e79bc305e5b43bc.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'UDO',
            'name': 'Unido',
            'address': '0xea3983Fc6D0fbbC41fb6F6091f68F3e08894dC06',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14176/thumb/unido.jpg?1614792353',
            'coingeckoId': 'unido-ep',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-4680-BUCKIN',
            'name': 'RealT   4680 Buckingham Ave  Detroit  M',
            'address': '0xeFe82D6baF0dB71f92889eB9d00721bD49121316',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16629/thumb/4680-Buckingham-hero-1.jpeg?1624553513',
            'coingeckoId': '4680-buckingham',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUKH',
            'name': 'bUKHI',
            'address': '0xeFe2aFB5f2a9EA8Ec6D8a57FE88fEBCFE29db813',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18401/thumb/bukh_new.png?1632739515',
            'coingeckoId': 'bukh',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METAI',
            'name': 'Metaverse Index Token',
            'address': '0xeFC996CE8341cd36c55412B51DF5BBCa429a7617',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22233/thumb/16782.png?1641247980',
            'coingeckoId': 'metaverse-index-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FNX',
            'name': 'FinNexus',
            'address': '0xeF9Cd7882c067686691B6fF49e650b43AFBBCC6B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xef9cd7882c067686691b6ff49e650b43afbbcc6b.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'YFIIG',
            'name': 'YFII Gold',
            'address': '0xeF8bA8cBa86f81B3108f60186FCe9c81B5096D5c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12587/thumb/yfiigold_logo.png?1600937694',
            'coingeckoId': 'yfii-gold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HOMT',
            'name': 'HOMT',
            'address': '0xeF7A985E4FF9B5DcCD6eDdF58577486887288711',
            'decimals': 15,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11597/thumb/HOM-Token-Logo.jpg?1591668252',
            'coingeckoId': 'hom-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HARU',
            'name': 'HARU',
            'address': '0xeF497B970f50286a9805b91809b8615E6d0eF19B',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26786/thumb/haru.png?1661062811',
            'coingeckoId': 'haru',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TTX',
            'name': 'Talent TTX',
            'address': '0xeF2e239b21A81719abAabC6239e2e90859B44D9B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12770/thumb/ttx.png?1640758151',
            'coingeckoId': 'talent-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELK',
            'name': 'Elk Finance',
            'address': '0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://elk.finance/tokens/logos/ethereum/0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE/logo.png',
            'coingeckoId': 'elk-finance',
            'listedIn': [
                'coingecko',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PGX',
            'name': 'Page Network',
            'address': '0xeEd4d7316a04ee59de3d301A384262FFbDbd589a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26319/thumb/ICfb5Z0c_400x400.jpg?1657244779',
            'coingeckoId': 'page-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ibETHv2',
            'name': 'Interest Bearing Ether v2',
            'address': '0xeEa3311250FE4c3268F8E684f7C87A82fF183Ec1',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/ibethv2.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'ASKO',
            'name': 'Asko',
            'address': '0xeEEE2a622330E6d2036691e983DEe87330588603',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xeeee2a622330e6d2036691e983dee87330588603.png',
            'coingeckoId': 'askobar-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'STEAK',
            'name': 'Steaks Finance',
            'address': '0xeED9e4F2450035D6426276a8aA2084966EE3b1bb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12672/thumb/MtZuJ_Gq6mqy4BaGacnKNMKmviA1xeDWg2wUiPMqZ-1MzfSiTiAbEzfclWWsU4FF3QxYJcrs5ia_DSWAvdwNYbROJEkm-UK9mZRBPK_z61JciH4XhqMEDpYParjxnkEDqIoobaGaW9bOzPPN_YYHYcv0A1LxjnzdPZF47ZigVuAoOa46YZNFJ_IkM-e-Cuf_-XCpbS2EtbMYvCEhbSuzrI.jpg?1601518956',
            'coingeckoId': 'steaks-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOVI',
            'name': 'CVI',
            'address': '0xeEAA40B28A2d1b0B08f6f97bB1DD4B75316c6107',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xeeaa40b28a2d1b0b08f6f97bb1dd4b75316c6107.png',
            'coingeckoId': 'govi',
            'listedIn': [
                'coingecko',
                '1inch',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'ENO',
            'name': 'ENO',
            'address': '0xeEA509C221c5C6979e974f4501b4829be0Ab2f8c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26501/thumb/Eno_logo.png?1658364451',
            'coingeckoId': 'eno',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MKONG',
            'name': 'Meme Kong',
            'address': '0xeE6b9CF11D968E0bAc7BFfF547577B8AE35B8065',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23448/thumb/ssynFLdjk2SIPSop_i8_8tCRn4rnV9hILMl8AnIFx3DtMIHRVoQ48QHOoqYwGuW9DSbZSJWoaPM0NEypRmrU3ZSXf_ZC16p3YBdT7mbP5vhnGyZMDvVoYm8GwKMYgvEeS9kzqlrNZ3bPWFasbdZD3TKh37BvLgUQiDchz5NiAVpUtrew5Frmox4CoEu_TOSvAV_OvG6A2RYeudy0bY0LUCfuU2.jpg?1644213902',
            'coingeckoId': 'meme-kong',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PASS',
            'name': 'Blockpass',
            'address': '0xeE4458e052B533b1aABD493B5f8c4d85D7B263Dc',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5462/thumb/cQl0gX0.png?1604815859',
            'coingeckoId': 'blockpass',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOSA',
            'name': 'Dosa',
            'address': '0xeE2B297408063E0967096bafDCfD1278d5BF1b8a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27587/thumb/ed15a1c1-e0c5-4b6d-8cc5-54e69e296f96.png?1664703528',
            'coingeckoId': 'dosa',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'L2P',
            'name': 'Lung Protocol',
            'address': '0xeE0f286776639cD363Da810DAF3e0623F82576b0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11799/thumb/64280570.png?1594359841',
            'coingeckoId': 'lung-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARIA20',
            'name': 'Arianee',
            'address': '0xeDF6568618A00C6F0908Bf7758A16F76B6E04aF9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5054/thumb/Aria_Logo_256.png?1610097866',
            'coingeckoId': 'arianee',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WSTA',
            'name': 'Wrapped Statera',
            'address': '0xeDEec5691f23E4914cF0183A4196bBEb30d027a0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13599/thumb/wsta_logo.png?1610011862',
            'coingeckoId': 'wrapped-statera',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OBOT',
            'name': 'Obortech',
            'address': '0xeDADeB5fAa413e6c8623461849DFD0B7C3790c32',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14929/thumb/OBORTECH_200.png?1619070515',
            'coingeckoId': 'obortech',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'OMI',
            'name': 'ECOMI',
            'address': '0xeD35af169aF46a02eE13b9d79Eb57d6D68C1749e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4428/thumb/ECOMI.png?1557928886',
            'coingeckoId': 'ecomi',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'PP',
            'name': 'Phoenix Protocol',
            'address': '0xeCe8531550Fc599ccB2c7734494FBc358d0a9483',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26883/thumb/NEW-PHOENIX-LOGO-200x200.png?1660708389',
            'coingeckoId': 'phoenix-protocol-b7a9513c-36e9-4a6b-b6ae-6a1a76bb913e',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NTRS',
            'name': 'Nosturis',
            'address': '0xeCcf15A4B5976a1365BAeD5297058B4cA42777C0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9990/thumb/47010860_203643113858754_2966343352238211072_n.jpg?1574373395',
            'coingeckoId': 'nosturis',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DBT',
            'name': 'Dengba Planet',
            'address': '0xeC79E0eFA4ae3d8B3C9fbCEe21683c7f2e507b66',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9042/thumb/FVNxxLbx_400x400.jpg?1563833177',
            'coingeckoId': 'dengba-planet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ACE',
            'name': 'Acent',
            'address': '0xeC5483804e637D45cDe22Fa0869656B64b5AB1ab',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15211/thumb/acent-logo.png?1620108963',
            'coingeckoId': 'acent',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YO',
            'name': 'Yobit',
            'address': '0xeBF4CA5319F406602EEFf68da16261f1216011B5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9231/thumb/ybx_logo.gif?1565306320',
            'coingeckoId': 'yobit-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RAINI',
            'name': 'Rainicorn',
            'address': '0xeB953eDA0DC65e3246f43DC8fa13f35623bDd5eD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14491/thumb/logo-200x200.png?1633314246',
            'coingeckoId': 'rainicorn',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'IDLEUSDC',
            'name': 'IdleUSDC',
            'address': '0xeB66ACc3d011056B00ea521F8203580C2E5d3991',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xeb66acc3d011056b00ea521f8203580c2e5d3991.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'VRH',
            'name': 'Versailles Heroes',
            'address': '0xeAd482da0793B00bbAe0E34C8cfaE6DAf29a44b2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27860/thumb/VRH___200-200.png?1667467208',
            'coingeckoId': 'versailles-heroes',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'sCEX',
            'name': 'Synth sCEX',
            'address': '0xeABACD844A196D7Faf3CE596edeBF9900341B420',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/scex.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'JCC',
            'name': 'Junca cash',
            'address': '0xeA7aA1eDd21735A5ab05EE3E90869016191e274E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12889/thumb/junca.jpg?1603279160',
            'coingeckoId': 'junca-cash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FIEF',
            'name': 'Fief',
            'address': '0xeA068Fba19CE95f12d252aD8Cb2939225C4Ea02D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23710/thumb/61f837d2703b649ab0213d45_Black_logo_-_no_background-p-500.png?1645083867',
            'coingeckoId': 'fief',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ECTE',
            'name': 'Eurocoin ECTE',
            'address': '0xe9fa21E671BcfB04E6868784b89C19d5aa2424Ea',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7634/thumb/logotoken200x200.png?1550742362',
            'coingeckoId': 'eurocoinpay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BASE',
            'name': 'Maximus BASE',
            'address': '0xe9f84d418B008888A992Ff8c6D22389C2C3504e0',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27683/thumb/IMG_1131.PNG?1665214262',
            'coingeckoId': 'maximus-base',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAK3',
            'name': 'SAKE',
            'address': '0xe9F84dE264E91529aF07Fa2C746e934397810334',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xe9F84dE264E91529aF07Fa2C746e934397810334/logo.png',
            'coingeckoId': 'sak3',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'XOY',
            'name': 'XOYCoin',
            'address': '0xe9F1d62c671EFe99896492766c0B416bD3FB9e52',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26641/thumb/xoycoin_logo.png?1659347530',
            'coingeckoId': 'xoycoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HRDG',
            'name': 'HRDGCOIN',
            'address': '0xe9D0331AD47148925c5009D33a428eE301C9D534',
            'decimals': 12,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22769/thumb/hrdg_logo_200X200.png?1642576030',
            'coingeckoId': 'hrdgcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UnisETHETH',
            'name': 'Uniswap sETH LP',
            'address': '0xe9Cf7887b93150D4F2Da7dFc6D502B216438F244',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'ipfs://QmZcwn4eZJpjihH8TApRczQQJdAzpR6Er7g1bvo6PGhxWi',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SATX',
            'name': 'SatoExchange',
            'address': '0xe96F2c381E267a96C29bbB8ab05AB7d3527b45Ab',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8500/thumb/satx-logo.png?1559015585',
            'coingeckoId': 'satoexchange-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIV',
            'name': 'ShibVinci',
            'address': '0xe9615071341c6F0392A5dFDE1645Ad01b810cB43',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24703/thumb/gk-logo.png?1648645069',
            'coingeckoId': 'shibvinci',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VNT',
            'name': 'InventoryClub',
            'address': '0xe912b8bA2513D7e29b7b2E5B14398dbf77503Fb4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14410/thumb/_SWCTiTC_400x400.png?1615946356',
            'coingeckoId': 'inventoryclub',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WEB3',
            'name': 'Arch Ethereum Web3',
            'address': '0xe8e8486228753E01Dbc222dA262Aa706Bd67e601',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26562/thumb/WEB3-TOKEN.png?1658798344',
            'coingeckoId': 'arch-ethereum-web3',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VIBE',
            'name': 'VIBE',
            'address': '0xe8Ff5C9c75dEb346acAc493C463C8950Be03Dfba',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/948/thumb/vibe.png?1547034809',
            'coingeckoId': 'vibe',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FEY',
            'name': 'Feyorra',
            'address': '0xe8E06a5613dC86D459bC8Fb989e173bB8b256072',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13689/thumb/1_XiKKk5_400x400.jpg?1610953208',
            'coingeckoId': 'feyorra',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KMPL',
            'name': 'KiloAmple',
            'address': '0xe8D17542dfe79Ff4FBd4b850f2d39DC69c4489a2',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13449/thumb/kappa_logo.png?1608681544',
            'coingeckoId': 'kiloample',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VAL',
            'name': 'Sora Validator',
            'address': '0xe88f8313e61A97cEc1871EE37fBbe2a8bf3ed1E4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xe88f8313e61a97cec1871ee37fbbe2a8bf3ed1e4.png',
            'coingeckoId': 'sora-validator-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-14066-SANTA',
            'name': 'RealT   14066 Santa Rosa Dr  Detroit  M',
            'address': '0xe887DC4fcb5240c0c080AEAb8870421d3ebd0B28',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16677/thumb/14066-Santa-Rosa-hero-1.5.jpg?1624629250',
            'coingeckoId': '14066-santa-rosa',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HOKK',
            'name': 'Hokkaido Inu',
            'address': '0xe87e15B9c7d989474Cb6d8c56b3DB4eFAD5b21E8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xe87e15b9c7d989474cb6d8c56b3db4efad5b21e8.png',
            'coingeckoId': 'hokkaidu-inu',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ESGC',
            'name': 'ESG Chain',
            'address': '0xe857734840dc188B4283d5aF14aB8685467aB87D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22085/thumb/logo-01.png?1640761969',
            'coingeckoId': 'esg-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KOI',
            'name': 'Koi Network',
            'address': '0xe84d9e32dC8cE819b8D6c83e50EDAfD46c6354dB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26221/thumb/KXc9agT1_400x400.jpeg?1656641746',
            'coingeckoId': 'koi-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLKS',
            'name': 'Bal kesirspor Token',
            'address': '0xe8389374A482d031703AE0E4cb3FE34B045081Ee',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24811/thumb/18434.png?1649024433',
            'coingeckoId': 'balikesirspor-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NEXM',
            'name': 'Nexum',
            'address': '0xe831F96A7a1DcE1aa2EB760b1e296c6A74CaA9d5',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23472/thumb/200_-_200_coinmarketcap.png?1644218225',
            'coingeckoId': 'nexum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'A5T',
            'name': 'Alpha5',
            'address': '0xe8272210954eA85DE6D2Ae739806Ab593B5d9c51',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13848/thumb/a5t.jpg?1614652988',
            'coingeckoId': 'alpha5',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CL',
            'name': 'Coinlancer',
            'address': '0xe81D72D14B1516e68ac3190a46C93302Cc8eD60f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2445/thumb/coinlancer.png?1516072576',
            'coingeckoId': 'coinlancer',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOBC',
            'name': 'Bobcoin',
            'address': '0xe803178b48A0e560C2b19F3b3d4e504f79D229ce',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24264/thumb/bobc.png?1647171532',
            'coingeckoId': 'bobcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BVL',
            'name': 'Bullswap Protocol',
            'address': '0xe7d324B2677440608fb871981B220ECa062c3FbF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13195/thumb/4HPwA1D2_400x400.png?1606115706',
            'coingeckoId': 'bullswap-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANI',
            'name': 'Anime',
            'address': '0xe7c9C6bc87B86f9E5b57072F907EE6460B593924',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'VAB',
            'name': 'Vabble',
            'address': '0xe7aE6D0C56CACaf007b7e4d312f9af686a9E9a04',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17508/thumb/vabble_tplogo_200_x_200.png?1628508875',
            'coingeckoId': 'vabble',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'DOBE',
            'name': 'Dobermann',
            'address': '0xe7aB45162f5979F09B0bDa1CC7DfC97C270EA3d5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16002/thumb/doberman.PNG?1622593349',
            'coingeckoId': 'dobermann',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFTL',
            'name': 'NFTLaunch',
            'address': '0xe7F72Bc0252cA7B16dBb72eEee1AfcDb2429F2DD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18140/thumb/nftl.PNG?1630652662',
            'coingeckoId': 'nftlaunch',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAX',
            'name': 'MAX',
            'address': '0xe7976c4Efc60d9f4C200Cc1bCEF1A1e3B02c73e7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6454/thumb/MAX_Token.jpg?1547042651',
            'coingeckoId': 'max-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RAIL',
            'name': 'Railgun',
            'address': '0xe76C6c83af64e4C60245D8C7dE953DF673a7A33D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xe76C6c83af64e4C60245D8C7dE953DF673a7A33D/logo.png',
            'coingeckoId': 'railgun',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic'
            ]
        },
        {
            'symbol': 'AAC',
            'name': 'Double A Chain',
            'address': '0xe75ad3aAB14E4B0dF8c5da4286608DaBb21Bd864',
            'decimals': 5,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2577/thumb/acute-angle-cloud.png?1547036708',
            'coingeckoId': 'acute-angle-cloud',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VAN',
            'name': 'Vanspor Token',
            'address': '0xe75097D3Ec88701361E670e065b8d5bC4dAfBC9D',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24814/thumb/E0t1GdwXMAcMoGD.png?1649028292',
            'coingeckoId': 'vanspor-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RCG',
            'name': 'Recharge',
            'address': '0xe74bE071f3b62f6A4aC23cA68E5E2A39797A3c30',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18120/thumb/thecharge.PNG?1630559768',
            'coingeckoId': 'recharge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USACOIN',
            'name': 'The Americans NFT',
            'address': '0xe70Ea90718fD21471DB240F3baE343daece65f53',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26566/thumb/american_NFT.jpg?1658800655',
            'coingeckoId': 'the-americans-nft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELA',
            'name': 'Elastos',
            'address': '0xe6fd75ff38Adca4B97FBCD938c86b98772431867',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2780/thumb/Elastos.png?1597048112',
            'coingeckoId': 'elastos',
            'listedIn': [
                'coingecko',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'EXEN',
            'name': 'Exen',
            'address': '0xe6Cc10ef4de1cCFb821C99C04ABFE1859D8eAB8f',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19036/thumb/Exen_Logo_f1f7d970fd.png?1634201249',
            'coingeckoId': 'exentoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUNNYROCKET',
            'name': 'BunnyRocket',
            'address': '0xe6716b29900a5776DF4bAe51338f78F102333A79',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19140/thumb/OvhRGGx.png?1634527738',
            'coingeckoId': 'bunnyrocket',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JULIEN',
            'name': 'JULIEN',
            'address': '0xe6710e0CdA178f3D921f456902707B0d4C4A332B',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12954/thumb/julien_logo.jpg?1603792446',
            'coingeckoId': 'julien',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FRR',
            'name': 'Frontrow',
            'address': '0xe6602B34D8510B033E000975B3322537c7172441',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1642943926005_8067235013017533.jpg',
            'coingeckoId': 'front-row',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'CAAVE',
            'name': 'cAAVE',
            'address': '0xe65cdB6479BaC1e22340E4E755fAE7E509EcD06c',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17527/thumb/caave.PNG?1628122171',
            'coingeckoId': 'caave',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUD',
            'name': 'Ludos Protocol',
            'address': '0xe64b47931f28f89Cc7A0C6965Ecf89EaDB4975f5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5118/thumb/Ludos_Protocol.jpg?1557216263',
            'coingeckoId': 'ludos',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KATANA',
            'name': 'Katana Finance',
            'address': '0xe6410569602124506658Ff992F258616Ea2D4A3D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12473/thumb/AyHMMbK.jpeg?1600124081',
            'coingeckoId': 'katana-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STN',
            'name': 'Stone',
            'address': '0xe63d6B308BCe0F6193AeC6b7E6eBa005f41e36AB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1634704130746_1494402537345132.png',
            'coingeckoId': 'stone-token',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'THX',
            'name': 'THX Network',
            'address': '0xe632ea2eF2CFD8Fc4a2731C76F99078Aef6a4B31',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21323/thumb/logo-thx-resized-200-200.png?1638945575',
            'coingeckoId': 'thx-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAKC',
            'name': 'BAKC Vault  NFTX ',
            'address': '0xe632e1EA781df32c60AB11052958744CBfBc439A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17052/thumb/BYAC.png?1626149096',
            'coingeckoId': 'bakc-vault-nftx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NAMI',
            'name': 'Nami Inu',
            'address': '0xe624A29CB327b03350820EF98584d56083eaF392',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18897/thumb/Fux1PzU6_400x400.jpg?1633816011',
            'coingeckoId': 'nami-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFTI',
            'name': 'NFT Index',
            'address': '0xe5feeaC09D36B18b3FA757E5Cf3F8dA6B8e27F4C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xe5feeac09d36b18b3fa757e5cf3f8da6b8e27f4c.png',
            'coingeckoId': 'nft-index',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MSOT',
            'name': 'BTour Chain',
            'address': '0xe5eF42D0E5E4Aa6B36c613d00Db8dad303d505F3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13141/thumb/BTour_Icon.png?1605595523',
            'coingeckoId': 'btour-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLU',
            'name': 'Bluejay',
            'address': '0xe5D2e173B120341face9e9970889C9FE64081FfD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28085/thumb/Bluejay.png?1667444163',
            'coingeckoId': 'bluejay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AAMMUNIAAVEWETH',
            'name': 'Aave AMM UniAAVEWETH',
            'address': '0xe59d2FF6995a926A574390824a657eEd36801E55',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17232/thumb/aAmmUniAAVEWETH.png?1626921301',
            'coingeckoId': 'aave-amm-uniaaveweth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRXHEDGE',
            'name': '1X Short TRX',
            'address': '0xe58C8dF0088Cf27b26C7D546A9835deAcC29496c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12060/thumb/683JEXMN_400x400.png?1596705582',
            'coingeckoId': '1x-short-trx-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SMS',
            'name': 'Speed Mining Service',
            'address': '0xe5867608b51A2c9C78B9587355cC093140A49B0A',
            'decimals': 3,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1807/thumb/speed-mining-service.png?1548610827',
            'coingeckoId': 'speed-mining-service',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PAY',
            'name': 'PayBolt',
            'address': '0xe580074A10360404AF3ABfe2d524D5806D993ea3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24175/thumb/logo.png?1646748269',
            'coingeckoId': 'paybolt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GM',
            'name': 'GM',
            'address': '0xe5597f0723EeaBa1B26948e06f008Bf0fc1e37e6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20066/thumb/PQ29R4D.png?1636768949',
            'coingeckoId': 'gm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSC',
            'name': 'Bitsonic',
            'address': '0xe541504417670FB76b612B41B4392d967a1956c7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9238/thumb/image.png?1604295837',
            'coingeckoId': 'bitsonic-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SUPER',
            'name': 'SuperFarm',
            'address': '0xe53EC727dbDEB9E2d5456c3be40cFF031AB40A55',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xe53ec727dbdeb9e2d5456c3be40cff031ab40a55.png',
            'coingeckoId': 'superfarm',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'GSE',
            'name': 'GSENetwork',
            'address': '0xe530441f4f73bDB6DC2fA5aF7c3fC5fD551Ec838',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4776/thumb/gsenetwork.png?1547743285',
            'coingeckoId': 'gsenetwork',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AC',
            'name': 'ArtCoin',
            'address': '0xe52e8876fbE83D6091DF4aC3e9ADC13bE6533849',
            'decimals': 3,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26127/thumb/ngiBSUT7_400x400.png?1655954442',
            'coingeckoId': 'artcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPWN',
            'name': 'Bitspawn',
            'address': '0xe516D78d784C77D479977BE58905B3f2b1111126',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16513/thumb/token_logo.png?1631603192',
            'coingeckoId': 'bitspawn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PRMX',
            'name': 'PREMA',
            'address': '0xe4dae00bc1c46ea2F44ae71B1BEb8b171C15d812',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27600/thumb/prmx.png?1664715769',
            'coingeckoId': 'prema',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BITCCA',
            'name': 'Bitcci Cash',
            'address': '0xe4D75e9b493458D032A5C3CC1eE9B0712C1EcE06',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25998/thumb/20574.png?1655276250',
            'coingeckoId': 'bitcci-cash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TXAU',
            'name': 'tGOLD',
            'address': '0xe4A6f23Fb9e00Fca037Aa0EA0a6954dE0a6C53bF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27828/thumb/tGOLD_token_2D.jpg?1666165789',
            'coingeckoId': 'tgold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KUNU',
            'name': 'KuramaInu',
            'address': '0xe49Cb97091b5bDe1E8B7043e3D5717E64fDE825e',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19520/thumb/robert199805_Order_FO41AEBD476C8_KZ00A_R00A%28SY%29_copy.png?1641439224',
            'coingeckoId': 'kuramainu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEFO',
            'name': 'DefHold',
            'address': '0xe481f2311C774564D517d015e678c2736A25Ddd3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13168/thumb/defhold_logo.png?1605849148',
            'coingeckoId': 'defhold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZKS',
            'name': 'ZKSpace',
            'address': '0xe4815AE53B124e7263F08dcDBBB757d41Ed658c6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xe4815ae53b124e7263f08dcdbbb757d41ed658c6.png',
            'coingeckoId': 'zkspace',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'EXRN',
            'name': 'EXRNchain',
            'address': '0xe469c4473af82217B30CF17b10BcDb6C8c796e75',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1049/thumb/exrn.png?1616039914',
            'coingeckoId': 'exrnchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEFUN',
            'name': 'Defun Gaming',
            'address': '0xe44d9F88C84Af1765B48BAB1d09c0D9C39B01056',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27906/thumb/trans.png?1666335908',
            'coingeckoId': 'defun-gaming',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WRIA',
            'name': 'Wrapped aRIA Currency',
            'address': '0xe4174143F0d6B1eBc0E25E1ACd975c8f9FD06539',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27066/thumb/wRIA-logo-200x200-1.png?1661673526',
            'coingeckoId': 'wrapped-aria',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GMT',
            'name': 'STEPN',
            'address': '0xe3c408BD53c31C085a1746AF401A4042954ff740',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23597/thumb/gmt.png?1644658792',
            'coingeckoId': 'stepn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FM',
            'name': 'Follow ME',
            'address': '0xe3a4a881d18F219Aedb42EFD58a76C6786640515',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://api.rubic.exchange/assets/ethereum/0xe3a4a881d18f219aedb42efd58a76c6786640515/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'renDGB',
            'name': 'renDGB',
            'address': '0xe3Cb486f3f5C639e98cCBaF57d95369375687F80',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xe3Cb486f3f5C639e98cCBaF57d95369375687F80/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'DST',
            'name': 'DAYSTARTER',
            'address': '0xe3A46b2Bc1d83C731D58cAb765D3b45bCe789095',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28006/thumb/daystarter_logo_200_200.png?1667148509',
            'coingeckoId': 'daystarter',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PLR',
            'name': 'Pillar',
            'address': '0xe3818504c1B32bF1557b16C238B2E01Fd3149C17',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xe3818504c1b32bf1557b16c238b2e01fd3149c17.png',
            'coingeckoId': 'pillar',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'BASID',
            'name': 'Basid Coin',
            'address': '0xe33f363351186e185470f35F6DeB94ABa44347D8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12566/thumb/basid-logo.png?1600827668',
            'coingeckoId': 'basid-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TOTO',
            'name': 'Tourist',
            'address': '0xe3278DF3eB2085bA9B6899812A99a10f9CA5E0Df',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5271/thumb/tourist-token.png?1548759070',
            'coingeckoId': 'tourist-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GEC',
            'name': 'Gecoin',
            'address': '0xe304283C3e60Cefaf7eA514007Cf4E8fdC3d869d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25646/thumb/62162f0d583e5a047dc02e7e_gecoin_200x200.png?1653019356',
            'coingeckoId': 'gecoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MUSD',
            'name': 'mStable USD',
            'address': '0xe2f2a5C287993345a840Db3B0845fbC70f5935a5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xe2f2a5c287993345a840db3b0845fbc70f5935a5.png',
            'coingeckoId': 'musd',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'JIG',
            'name': 'Jigen',
            'address': '0xe2Fc859C838F31C1450448f4FeD92e3284aE49E9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DUST',
            'name': 'Dust',
            'address': '0xe2E109f1b4eaA8915655fE8fDEfC112a34ACc5F0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19086/thumb/WdTDLpcA_400x400.png?1634420382',
            'coingeckoId': 'dust',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETHBTCMOON',
            'name': 'ETHBTC 2x Long',
            'address': '0xe2D8F9557fD46AD134C8fb5Eabf6f4DA3E0d3f68',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xe2D8F9557fD46AD134C8fb5Eabf6f4DA3E0d3f68/logo.png',
            'coingeckoId': 'ethbtc-2x-long-token',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'INJ',
            'name': 'Injective',
            'address': '0xe28b3B32B6c345A34Ff64674606124Dd5Aceca30',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xe28b3b32b6c345a34ff64674606124dd5aceca30.png',
            'coingeckoId': 'injective-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'MAN',
            'name': 'Matrix AI Network',
            'address': '0xe25bCec5D3801cE3a794079BF94adF1B8cCD802D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2698/thumb/matrix-ai-network.png?1547978533',
            'coingeckoId': 'matrix-ai-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIP',
            'name': 'ShipChain',
            'address': '0xe25b0BBA01Dc5630312B6A21927E578061A13f55',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xe25b0bba01dc5630312b6a21927e578061a13f55.png',
            'coingeckoId': 'shipchain',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'BAN',
            'name': 'Banano',
            'address': '0xe20B9e246db5a0d21BF9209E4858Bc9A3ff7A034',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6226/thumb/banano-transparent.png?1619589798',
            'coingeckoId': 'banano',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'sDEFI',
            'name': 'Synth sDEFI',
            'address': '0xe1aFe1Fd76Fd88f78cBf599ea1846231B8bA3B6B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1634701446804_697181923882993.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'NAME',
            'name': 'PolkaDomain',
            'address': '0xe1A4c5BBb704a92599FEdB191f451E0d3a1ed842',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14832/thumb/name.png?1618580733',
            'coingeckoId': 'polkadomain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOX',
            'name': 'BOX Token',
            'address': '0xe1A178B681BD05964d3e3Ed33AE731577d9d96dD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6511/thumb/box-token.png?1547563043',
            'coingeckoId': 'box-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JOJO',
            'name': 'JoJo Inu',
            'address': '0xe18024f4838962D61eb591982390DFfC762f2cD7',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19149/thumb/jojo_logo_200.png?1634535364',
            'coingeckoId': 'jojo-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EHX',
            'name': 'Eterna Hybrid Exchange',
            'address': '0xe1747a23C44f445062078e3C528c9F4c28C50a51',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24825/thumb/_0VJF4cs_400x400.jpg?1649042250',
            'coingeckoId': 'eterna-hybrid-exchange',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KAU',
            'name': 'Kauri',
            'address': '0xe172F366678EC7B559F6C2913a437BaaDfd4e6c8',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17802/thumb/200%C3%97200-logo.png?1629291816',
            'coingeckoId': 'kauri',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WSB',
            'name': 'WallStreetBets DApp',
            'address': '0xe1590A6FA0CFf9C960181cb77d8a873601772f64',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15093/thumb/Pe1mrDu.png?1629882480',
            'coingeckoId': 'wall-street-bets-dapp',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'vBTC',
            'name': 'vBTC',
            'address': '0xe1406825186D63980fd6e2eC61888f7B91C4bAe4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/vbtc.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'GTC',
            'name': 'Global Trust Coin',
            'address': '0xe138FDa441fC31B36171122397a8A11d6cd2c479',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7752/thumb/gtib.png?1550222444',
            'coingeckoId': 'global-trust-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MEFI',
            'name': 'The meme finance',
            'address': '0xe13879194578C6F1A591a96C45B65022c43CEc07',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27837/thumb/57678426-EC89-4BA2-87AB-301B7D915F1B.jpeg?1666056561',
            'coingeckoId': 'the-meme-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SUR',
            'name': 'Suretly',
            'address': '0xe120c1ECBfdFeA7F0A8f0Ee30063491E8c26fedf',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/914/thumb/suretly.png?1547034752',
            'coingeckoId': 'suretly',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PEEPS',
            'name': 'The People s Coin',
            'address': '0xe1030B48b2033314979143766d7dC1F40ef8CE11',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xe1030b48b2033314979143766d7dc1f40ef8ce11.png',
            'coingeckoId': 'the-people-coin',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ALTA',
            'name': 'Alta Finance',
            'address': '0xe0cCa86B254005889aC3a81e737f56a14f4A38F5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18713/thumb/AFN-token-Altafin-200.png?1633079552',
            'coingeckoId': 'alta-finance',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'LFC',
            'name': 'Linfinity',
            'address': '0xe0c8087CE1a17bdd5D6c12eb52F8d7efF7791987',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5364/thumb/Linfinity-logo.jpg?1547040963',
            'coingeckoId': 'linfinity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FACTR',
            'name': 'Defactor',
            'address': '0xe0bCEEF36F3a6eFDd5EEBFACD591423f8549B9D5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19201/thumb/jFLSu4U9_400x400.png?1650437050',
            'coingeckoId': 'defactor',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZUM',
            'name': 'ZUM',
            'address': '0xe0b9BcD54bF8A730EA5d3f1fFCe0885E911a502c',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9721/thumb/zum256x256.png?1571264005',
            'coingeckoId': 'zum-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CSWAP',
            'name': 'CrossSwap',
            'address': '0xe0b0C16038845BEd3fCf70304D3e167Df81ce225',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18002/thumb/cross.png?1630078540',
            'coingeckoId': 'crossswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KEYS',
            'name': 'Keys',
            'address': '0xe0a189C975e4928222978A74517442239a0b86ff',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20604/thumb/200x200_%2843%29.png?1637289427',
            'coingeckoId': 'keys-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TOSHINORI',
            'name': 'Toshinori Inu',
            'address': '0xe0Bcd056b6a8c7fD4983cb56c162799E498E85D3',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21068/thumb/2Dp0LWna_400x400.jpg?1638279103',
            'coingeckoId': 'toshinori-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GS',
            'name': 'Genesis Shards',
            'address': '0xe0B9a2C3E9f40CF74B2C7F591B2b0CCa055c3112',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xe0b9a2c3e9f40cf74b2c7f591b2b0cca055c3112.png',
            'coingeckoId': 'genesis-shards',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'STACK',
            'name': 'Stacker Ventures',
            'address': '0xe0955F26515d22E347B17669993FCeFcc73c3a0a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14218/thumb/stack.jpg?1615000614',
            'coingeckoId': 'stacker-ventures',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRIB',
            'name': 'Contribute',
            'address': '0xe09216F1d343Dd39D6Aa732a08036fee48555Af0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12576/thumb/New_logo_circle.png?1604214723',
            'coingeckoId': 'contribute',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QINDO',
            'name': 'Qindo Token',
            'address': '0xe07332FdCf3A8489B752CD86f3FdCD79eE9C373A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xe07332FdCf3A8489B752CD86f3FdCD79eE9C373A/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'BCP',
            'name': 'BitcashPay',
            'address': '0xe047705117Eb07e712C3d684f5B18E74577e83aC',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xe047705117eb07e712c3d684f5b18e74577e83ac.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'WPT',
            'name': 'WORLDPET',
            'address': '0xe04491D64Eaa464Ec8Fdf53c7a4C92BF5B2278Cd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11707/thumb/worldpet3.png?1592986614',
            'coingeckoId': 'worldpet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'aUniUSDC',
            'name': 'Aave Interest bearing UniUSDC',
            'address': '0xe02b2Ad63eFF3Ac1D5827cBd7AB9DD3DaC4f4AD0',
            'decimals': 6,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'HOODIE',
            'name': 'CryptoPunk  7171',
            'address': '0xdffA3a7f5B40789C7A437DbE7B31b47F9B08FE75',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17842/thumb/cryptopunk-hoodie.png?1629468579',
            'coingeckoId': 'cryptopunk-7171-hoodie',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KARMA',
            'name': 'Karma DAO',
            'address': '0xdfe691F37b6264a90Ff507EB359C45d55037951C',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xdfe691f37b6264a90ff507eb359c45d55037951c.png',
            'coingeckoId': 'karma-dao',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SCCN',
            'name': 'Succession',
            'address': '0xdfdDF7a69716124Bc346bA556d4b9f9E74C4A8Bc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25377/thumb/sccn.png?1651410204',
            'coingeckoId': 'succession',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JOB',
            'name': 'Jobchain',
            'address': '0xdfbc9050F5B01DF53512DCC39B4f2B2BBaCD517A',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9352/thumb/Logo_%283%29.png?1566510871',
            'coingeckoId': 'jobchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MSU',
            'name': 'Metasens',
            'address': '0xdfD8D604951eBF1b2297285F1B68de140C43992b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24418/thumb/Metasens-3d-LOGO.png?1647611333',
            'coingeckoId': 'metasens',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XAEA-XII',
            'name': 'XAEA Xii',
            'address': '0xdf3F28189516647F8cd2414B6aDD5aC948F3fc41',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23325/thumb/xNXRIgWn_400x400.jpg?1643839082',
            'coingeckoId': 'xaea-xii-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PKN',
            'name': 'Poken',
            'address': '0xdf09a216Fac5ADC3e640Db418C0b956076509503',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21574/thumb/G10VNBAR_400x400.jpg?1639490463',
            'coingeckoId': 'poken',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'CERBY',
            'name': 'Cerby Token',
            'address': '0xdef1fac7Bf08f173D286BbBDcBeeADe695129840',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xdef1fac7Bf08f173D286BbBDcBeeADe695129840/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'KNC',
            'name': 'Kyber Network Crystal',
            'address': '0xdeFA4e8a7bcBA345F687a2f1456F5Edd9CE97202',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xdefa4e8a7bcba345f687a2f1456f5edd9ce97202.png',
            'coingeckoId': 'kyber-network-crystal',
            'listedIn': [
                'coingecko',
                '1inch',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'L3P',
            'name': 'Lepricon',
            'address': '0xdeF1da03061DDd2A5Ef6c59220C135dec623116d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14436/thumb/l3p.png?1636116065',
            'coingeckoId': 'lepricon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLUFFY',
            'name': 'Fluffy Inu',
            'address': '0xde74BB5A16000DeEA9Eb26Ef3941344325dbDb8C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21769/thumb/fluffy-logo-md.png?1639994182',
            'coingeckoId': 'fluffy-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEXE',
            'name': 'DeXe',
            'address': '0xde4EE8057785A7e8e800Db58F9784845A5C2Cbd6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xde4ee8057785a7e8e800db58f9784845a5c2cbd6.png',
            'coingeckoId': 'dexe',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MCRT',
            'name': 'MagicCraft',
            'address': '0xde16Ce60804a881e9F8c4eBB3824646EDecd478D',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21318/thumb/mcrt.png?1638932009',
            'coingeckoId': 'magiccraft',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'GMR',
            'name': 'GAMER ',
            'address': '0xde0518903B3a0e7904B08547F73D4350Be7EB798',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CYOP',
            'name': 'CyOp Protocol',
            'address': '0xddaC9C604BA6Bc4ACEc0FBB485B83f390ECF2f31',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20672/thumb/cyop.png?1637549090',
            'coingeckoId': 'cyop-protocol',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'KNCL',
            'name': 'Kyber Network Crystal Legacy',
            'address': '0xdd974D5C2e2928deA5F71b9825b8b646686BD200',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xdd974d5c2e2928dea5f71b9825b8b646686bd200.png',
            'coingeckoId': 'kyber-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'TAVITT',
            'name': 'Tavittcoin',
            'address': '0xdd690D8824c00C84d64606FFb12640e932C1AF56',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5155/thumb/tavitt-logo.png?1547040566',
            'coingeckoId': 'tavittcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AVASTR',
            'name': 'AVASTR Vault  NFTX ',
            'address': '0xdcDc1c1cC33AA817CbDBe8F5E2390BF7cc43dc4B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17034/thumb/AVASTR.png?1626142802',
            'coingeckoId': 'avastr-vault-nftx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TOP',
            'name': 'TOP Network',
            'address': '0xdcD85914b8aE28c1E62f1C488E1D968D5aaFfE2b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7058/thumb/topnetwork-logo.png?1547043514',
            'coingeckoId': 'top-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UTK',
            'name': 'Utrust',
            'address': '0xdc9Ac3C20D1ed0B540dF9b1feDC10039Df13F99c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xdc9ac3c20d1ed0b540df9b1fedc10039df13f99c.png',
            'coingeckoId': 'utrust',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'RVF',
            'name': 'RocketX exchange',
            'address': '0xdc8aF07A7861bedD104B8093Ae3e9376fc8596D2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14728/thumb/avatar-correct-size-official.png?1668391022',
            'coingeckoId': 'rocketx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WITCH',
            'name': 'Witch Token',
            'address': '0xdc524e3c6910257744C1F93Cf15E9F472b5bD236',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17205/thumb/download_%2829%29.png?1626848072',
            'coingeckoId': 'witch-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UWL',
            'name': 'UniWhales',
            'address': '0xdbDD6F355A37b94e6C7D32fef548e98A280B8Df5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xdbDD6F355A37b94e6C7D32fef548e98A280B8Df5/logo.png',
            'coingeckoId': 'uniwhales',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'ZANO',
            'name': 'Zano',
            'address': '0xdb85f6685950E285b1E611037BEBe5B34e2B7d78',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8370/thumb/zano.png?1638343828',
            'coingeckoId': 'zano',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MEDIA',
            'name': 'Media Network',
            'address': '0xdb726152680eCe3c9291f1016f1d36f3995f6941',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xdb726152680eCe3c9291f1016f1d36f3995f6941/logo.png',
            'coingeckoId': 'media-network',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'PUGL',
            'name': 'PugLife',
            'address': '0xdb69c3f8977f78952303987E87153c1988CF65a9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16105/thumb/puglife.png?1625756103',
            'coingeckoId': 'puglife',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EMB',
            'name': 'Overline Emblem',
            'address': '0xdb0aCC14396D108b3C5574483aCB817855C9dc8d',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xdb0acc14396d108b3c5574483acb817855c9dc8d.png',
            'coingeckoId': 'overline-emblem',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ATRI',
            'name': 'Atari',
            'address': '0xdacD69347dE42baBfAEcD09dC88958378780FB62',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xdacd69347de42babfaecd09dc88958378780fb62.png',
            'coingeckoId': 'atari',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'GFI',
            'name': 'Goldfinch',
            'address': '0xdab396cCF3d84Cf2D07C4454e10C8A6F5b008D2b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19081/thumb/GOLDFINCH.png?1634369662',
            'coingeckoId': 'goldfinch',
            'listedIn': [
                'coingecko',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'GUSS',
            'name': 'GUSS ONE',
            'address': '0xdaE6f68DA3bab6866742A7f4050366F6AC48760d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8509/thumb/gussone_mid.png?1559030931',
            'coingeckoId': 'guss-one',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HDAO',
            'name': 'humanDAO',
            'address': '0xdaC657ffD44a3B9d8aba8749830Bf14BEB66fF2D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21138/thumb/Untitled-2.jpg?1645163749',
            'coingeckoId': 'humandao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ECT',
            'name': 'Echain Network',
            'address': '0xda4Dd9586D27202A338843Dd6B9824d267006783',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28133/thumb/1666808362379-8226c3c3451e049842cfe21d65cb91ad.png?1667719253',
            'coingeckoId': 'echain-network',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'TKINU',
            'name': 'Tsuki Inu',
            'address': '0xda23d301761E4e2bF474951f978f6DFB6F3C9F14',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15681/thumb/tkinu.png?1621508357',
            'coingeckoId': 'tsuki-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PRCY',
            'name': 'PRivaCY Coin',
            'address': '0xdFC3829b127761a3218bFceE7fc92e1232c9D116',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14151/thumb/prcy.png?1614666991',
            'coingeckoId': 'prcy-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YETIC',
            'name': 'YetiCoin',
            'address': '0xdF96bDE075d59e9143b325C75aF38e208c986E6F',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20493/thumb/yetic.png?1647695460',
            'coingeckoId': 'yeticoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'mETH',
            'name': 'DMM: ETH',
            'address': '0xdF9307DFf0a1B57660F60f9457D32027a55ca0B2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xdf9307dff0a1b57660f60f9457d32027a55ca0b2.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'YCURVE',
            'name': 'LP yCurve',
            'address': '0xdF5e0e81Dff6FAF3A7e52BA697820c5e32D806A8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xdf5e0e81dff6faf3a7e52ba697820c5e32d806a8.png',
            'coingeckoId': 'curve-fi-ydai-yusdc-yusdt-ytusd',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'HUSD',
            'name': 'HUSD',
            'address': '0xdF574c24545E5FfEcb9a659c229253D4111d87e1',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xdf574c24545e5ffecb9a659c229253d4111d87e1.png',
            'coingeckoId': 'husd',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'BABYFLOKI',
            'name': 'BabyFloki',
            'address': '0xdF35988d795d90711e785B488Bb2127692e6f956',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16712/thumb/floki.jpg?1624810144',
            'coingeckoId': 'babyfloki',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BP',
            'name': 'Beyond Protocol',
            'address': '0xdF290B162a7D3E0A328cF198308D421954f08b94',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4732/thumb/b-logo-200x200.png?1633423345',
            'coingeckoId': 'beyond-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YNI',
            'name': 'YNI',
            'address': '0xdEaA665aB71785Ccf576dc76e5fcB5a2283ea0C6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1634706286119_5747107054114409.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'FML',
            'name': 'FormulA',
            'address': '0xdE522a2778E4554707E6a8Df36a4871ce9967BB5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6878/thumb/formula_token.png?1547043223',
            'coingeckoId': 'formula',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOVE',
            'name': 'Deesse',
            'address': '0xdE4CE5447ce0c67920a1371605A39187Cb6847c8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22524/thumb/GaqpKHEP_400x400.jpg?1641975550',
            'coingeckoId': 'deesse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SG',
            'name': 'SocialGood',
            'address': '0xdDF7Fd345D54ff4B40079579d4C4670415DbfD0A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3948/thumb/logo_200.png?1596589686',
            'coingeckoId': 'social-good-project',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AXPR',
            'name': 'Moola',
            'address': '0xdD0020B1D5Ba47A54E2EB16800D73Beb6546f91A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2682/thumb/6QRknY7.png?1657767443',
            'coingeckoId': 'axpire',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PAYB',
            'name': 'PayB',
            'address': '0xdCB5645eDa1ed34C5641d81b927D33EBaE9cF2A4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25805/thumb/logo_payb_200_x_200.png?1653978259',
            'coingeckoId': 'payb',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STATE',
            'name': 'ParaState',
            'address': '0xdC6104b7993e997Ca5f08aCAB7D3AE86E13D20a6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23707/thumb/vertical.png?1645082931',
            'coingeckoId': 'parastate',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FRANK',
            'name': 'Frank Inu',
            'address': '0xdC4c9bFBB37C7cDeeBc53C0BA5C7474FEA5E106D',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24474/thumb/frank.png?1647771278',
            'coingeckoId': 'frank-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STON',
            'name': 'Ston',
            'address': '0xdC47f2Ba852669B178699449E50682D6CEAF8C07',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13001/thumb/ston-v2-200x200.png?1604284194',
            'coingeckoId': 'ston',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALCX',
            'name': 'Alchemix',
            'address': '0xdBdb4d16EdA451D0503b854CF79D55697F90c8DF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xdbdb4d16eda451d0503b854cf79d55697f90c8df.png',
            'coingeckoId': 'alchemix',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'CCBCH',
            'name': 'Cross Chain BCH',
            'address': '0xdB792B1D8869A7CFc34916d6c845Ff05A7C9b789',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19747/thumb/ccBCH.png?1635822615',
            'coingeckoId': 'cross-chain-bch',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AGS',
            'name': 'Aegis',
            'address': '0xdB2F2bCCe3efa95EDA95a233aF45F3e0d4f00e2A',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12391/thumb/logo-3.png?1599540071',
            'coingeckoId': 'aegis',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EURS',
            'name': 'STASIS EURO',
            'address': '0xdB25f211AB05b1c97D595516F45794528a807ad8',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xdb25f211ab05b1c97d595516f45794528a807ad8.png',
            'coingeckoId': 'stasis-eurs',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': '$ROVR',
            'name': 'MoonRover',
            'address': '0xdB0eE671B52a866DF968EE0913E906BfB743A6d5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': '3GG',
            'name': '3gg',
            'address': '0xdAE5add81B1fa1b04C0DF225F0C3A029b93cB91B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27864/thumb/%E8%9E%A2%E5%B9%95%E6%88%AA%E5%9C%96_2022-10-17_%E4%B8%8B%E5%8D%888.28.14.png?1666151176',
            'coingeckoId': '3gg',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'Tether',
            'address': '0xdAC17F958D2ee523a2206206994597C13D831ec7',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xdac17f958d2ee523a2206206994597c13d831ec7.png',
            'coingeckoId': 'tether',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'optimism',
                'arbitrum_bridge',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'TUBE2',
            'name': 'TUBE2',
            'address': '0xdA86006036540822e0cd2861dBd2fD7FF9CAA0e8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18754/thumb/Tube-2.png?1633328940',
            'coingeckoId': 'tube2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-15350-GREYD',
            'name': 'RealT   15350 Greydale St  Detroit  MI ',
            'address': '0xdA47bD33e8f5d17Bb81b8752784bfb46C1c44B2A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16564/thumb/15350-Greydale-hero-1.jpg?1624460101',
            'coingeckoId': '15350-greydale',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORBR',
            'name': 'Orbler',
            'address': '0xdA30f261a962d5AAe94C9ecd170544600d193766',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24056/thumb/fA2YATWT_400x400.jpg?1646203182',
            'coingeckoId': 'orbler',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AIDI',
            'name': 'Aidi Inu',
            'address': '0xdA1E53E088023Fe4D1DC5a418581748f52CBd1b8',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16339/thumb/aidi_200.png?1634199020',
            'coingeckoId': 'aidi-inu',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'REALT-S-4061-GRAND-',
            'name': 'RealT   4061 Grand St  Detroit  MI 4823',
            'address': '0xd9e89bFebAe447B42C1Fa85C590716eC8820f737',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16616/thumb/4061-Grand-hero-1.jpeg?1624551866',
            'coingeckoId': '4061-grand',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ENTRC',
            'name': 'EnterCoin',
            'address': '0xd9d01D4Cb824219A8F482a0FAd479cB971Fd0628',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8163/thumb/entrc-logo200.png?1555901482',
            'coingeckoId': 'entercoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GAINS',
            'name': 'Gains',
            'address': '0xd9b312D77Bc7BEd9B9CecB56636300bED4Fe5Ce9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xd9b312d77bc7bed9b9cecb56636300bed4fe5ce9.png',
            'coingeckoId': 'gains',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'LBK',
            'name': 'Legal Block',
            'address': '0xd9af2d11d788da0097076f4Eb21bd1C5533743D9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9524/thumb/62783498_2399147633483417_3919658167318872064_n.jpg?1568605802',
            'coingeckoId': 'legal-block',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '2022M',
            'name': '2022MOON',
            'address': '0xd9adb7536E90660F601c5BFB15cB49BFa78cA0a6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://static.debank.com/image/eth_token/logo_url/0xd9adb7536e90660f601c5bfb15cb49bfa78ca0a6/138bb5ad989a2f0c592307749e1f5a45.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'EUL',
            'name': 'Euler',
            'address': '0xd9Fcd98c322942075A5C3860693e9f4f03AAE07b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26149/thumb/YCvKDfl8_400x400.jpeg?1656041509',
            'coingeckoId': 'euler',
            'listedIn': [
                'coingecko',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'APP',
            'name': 'DAPPSY',
            'address': '0xd9F942e36b4Cac550DaA0cb31EF09eFb1D1505f3',
            'decimals': 8,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SHR',
            'name': 'Share',
            'address': '0xd98F75b1A3261dab9eEd4956c93F33749027a964',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3609/thumb/74586729_2443914875881351_2785018663453851648_n.png?1574898410',
            'coingeckoId': 'sharering',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'PAL',
            'name': 'Palmare',
            'address': '0xd95f99d6e5527eeb35EbA9e024900eE7f8EAEAFc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BMC',
            'name': 'BountyMarketCap',
            'address': '0xd945d2031b4C63C0E363304FB771F709b502DC0a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13472/thumb/bmc-logo-200.png?1608852915',
            'coingeckoId': 'bountymarketcap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IPT',
            'name': 'Interest Protocol Token',
            'address': '0xd909C5862Cdb164aDB949D92622082f0092eFC3d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27000/thumb/logo.white_%281%29.png?1661328083',
            'coingeckoId': 'interest-protocol-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RZN',
            'name': 'Rizen Coin  Old ',
            'address': '0xd8c82FbC4D8Ed0644a7eC04cF973e84c6153c1d7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13373/thumb/Rizen_logo_black.png?1620206327',
            'coingeckoId': 'rizen-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RDX',
            'name': 'Redux Protocol',
            'address': '0xd8C4ef2D99c9F962180008418a5665Fcc4b99ecD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26737/thumb/-L4oVsFB_400x400.png?1659935437',
            'coingeckoId': 'redux-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DaoX',
            'name': 'DaoX',
            'address': '0xd8485513dd9947aa3287D90Fd3804D3dF52D0Da1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'NEEO',
            'name': 'NEEO',
            'address': '0xd8446236FA95b9b5f9fd0f8E7Df1a944823c683d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26060/thumb/ZbD8a9pz_400x400.jpg?1655455842',
            'coingeckoId': 'neeo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ICAP',
            'name': 'Invictus Capital',
            'address': '0xd83C569268930fadAd4cdE6D0cB64450fef32b65',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18334/thumb/ICAP-logo-icon.png?1631584746',
            'coingeckoId': 'invictus-capital-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INSURE',
            'name': 'InsureDAO',
            'address': '0xd83AE04c9eD29d6D3E6Bf720C71bc7BeB424393E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23913/thumb/InsureDAO_-_Final_Logo_%28solo%29.png?1645682885',
            'coingeckoId': 'insuredao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DAV',
            'name': 'DAV Network',
            'address': '0xd82Df0ABD3f51425Eb15ef7580fDA55727875f14',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2842/thumb/DAV-Network-logo.jpg?1547037011',
            'coingeckoId': 'dav',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALTBULL',
            'name': '3X Long Altcoin Index Token',
            'address': '0xd829664CDbF3195b2cE76047A65de29e7ED0a9A8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10208/thumb/683JEXMN_400x400_%281%29.png?1576535126',
            'coingeckoId': '3x-long-altcoin-index-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NMT',
            'name': 'NFTMart',
            'address': '0xd81b71cBb89B2800CDb000AA277Dc1491dc923C3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15811/thumb/NFTMART.png?1624962351',
            'coingeckoId': 'nftmart-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETH2SOCKS',
            'name': 'Etherean Socks',
            'address': '0xd80776BBF68CDE466900a7214C772A7D179C420F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17714/thumb/Q4NQZWp.png?1629083998',
            'coingeckoId': 'etherean-socks',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DRC',
            'name': 'DRC Mobility',
            'address': '0xd7f5CABdF696D7d1bf384D7688926A4bdB092c67',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13457/thumb/drc_symbol.png?1608759323',
            'coingeckoId': 'drc-mobility',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VERA',
            'name': 'Vera',
            'address': '0xd7f0cC50AD69408aE58be033F4f85D2367C2e468',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18519/thumb/JJXTVFOE_400x400.png?1632273174',
            'coingeckoId': 'vera',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DAIN',
            'name': 'Dain',
            'address': '0xd7d706ED3598a354A4aDFce5D8C5383dF99a4461',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14811/thumb/%C2%B4%C3%99%C3%80%C3%8E%C2%B7%C3%8E%C2%B0%C3%AD.png?1618541340',
            'coingeckoId': 'dain-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NXM',
            'name': 'Nexus Mutual',
            'address': '0xd7c49CEE7E9188cCa6AD8FF264C1DA2e69D4Cf3B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11810/thumb/nexus-mutual.jpg?1594547726',
            'coingeckoId': 'nxm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'B2M',
            'name': 'Bit2Me',
            'address': '0xd7c302fc3ac829C7E896a32c4Bd126f3e8Bd0a1f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19848/thumb/b2m-circle-solid-default.png?1636036332',
            'coingeckoId': 'bit2me',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EVRY',
            'name': 'Evrynet',
            'address': '0xd7DCd9B99787C619b4D57979521258D1A7267ad7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19194/thumb/evry.png?1634637970',
            'coingeckoId': 'evrynet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XAI',
            'name': 'XAI',
            'address': '0xd7C9F0e536dC865Ae858b0C0453Fe76D13c3bEAc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28254/thumb/Xai_logo.png?1668674085',
            'coingeckoId': 'xai',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METACEX',
            'name': 'Metaverse Exchange',
            'address': '0xd796B8EFf23d5C4C71f43C99ffD7D8A3119F7475',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21179/thumb/metacex.png?1638707090',
            'coingeckoId': 'metaverse-exchange',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HYVE',
            'name': 'Hyve',
            'address': '0xd794DD1CAda4cf79C9EebaAb8327a1B0507ef7d4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13072/thumb/bAe1G-lD_400x400.png?1654056255',
            'coingeckoId': 'hyve',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'EVN',
            'name': 'Envion',
            'address': '0xd780Ae2Bf04cD96E577D3D014762f831d97129d0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1547/thumb/envion.jpg?1547035704',
            'coingeckoId': 'envion',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ENTR',
            'name': 'EnterDAO',
            'address': '0xd779eEA9936B4e323cDdff2529eb6F13d0A4d66e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xd779eEA9936B4e323cDdff2529eb6F13d0A4d66e/logo.png',
            'coingeckoId': 'enterdao',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'SCL',
            'name': 'Sociall',
            'address': '0xd7631787B4dCc87b1254cfd1e5cE48e96823dEe8',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xd7631787b4dcc87b1254cfd1e5ce48e96823dee8.png',
            'coingeckoId': 'sociall',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'INUSANITY',
            'name': 'Inusanity',
            'address': '0xd73Cab6a16c67Cb79fc61F8c13DfEc4F32f9DCBF',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27719/thumb/inusanity200.png?1665462344',
            'coingeckoId': 'inusanity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLACK',
            'name': 'BlackHole Protocol',
            'address': '0xd714d91A169127e11D8FAb3665d72E8b7ef9Dbe2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15124/thumb/blackhole.PNG?1620094580',
            'coingeckoId': 'blackhole-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EVA',
            'name': 'Evanesco Network',
            'address': '0xd6cAF5Bd23CF057f5FcCCE295Dcc50C01C198707',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16722/thumb/evan.PNG?1624849154',
            'coingeckoId': 'evanesco-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OMC',
            'name': 'Ormeus Cash',
            'address': '0xd6bD97a26232bA02172Ff86b055d5D7bE789335B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11798/thumb/Vooo8SX.png?1594359387',
            'coingeckoId': 'ormeus-cash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'yUSDCv2',
            'name': 'iearn USDC v2',
            'address': '0xd6aD7a6750A7593E092a9B218d66C0A814a3436e',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xd6ad7a6750a7593e092a9b218d66c0a814a3436e.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'EMON',
            'name': 'Ethermon',
            'address': '0xd6A5aB46ead26f49b03bBB1F9EB1Ad5c1767974a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15889/thumb/LtET0reH_400x400.jpg?1622213962',
            'coingeckoId': 'ethermon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOB',
            'name': 'Goons of Balatroon',
            'address': '0xd6929179D752d5D25c5efe2d9729eb77D7138A80',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27104/thumb/q1_hZykF_400x400.jpeg?1662006909',
            'coingeckoId': 'goons-of-balatroon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WALLSTREETINU',
            'name': 'Wall Street Inu',
            'address': '0xd68Cb7a643079311B8645ED90F2D5267CfA6eA26',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20551/thumb/logo-coingecko.png?1637767297',
            'coingeckoId': 'wall-street-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YMS',
            'name': 'Yeni Malatyaspor Token',
            'address': '0xd64126835ceCAcA345c8f137bda68e975f5aB790',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24815/thumb/Yeni_Malatyaspor_logo.svg.png?1649029086',
            'coingeckoId': 'yeni-malatyaspor-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CUMINU',
            'name': 'Cuminu',
            'address': '0xd6327ce1fb9D6020E8C2c0E124A1eC23DCAb7536',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15832/thumb/CUMINU.png?1628849016',
            'coingeckoId': 'cuminu',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'FINT',
            'name': 'Fintropy',
            'address': '0xd61143652aF94f570C7D9429356662dD859cA6EC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21222/thumb/quExJXdW_400x400.png?1638740691',
            'coingeckoId': 'fintropy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IDIA',
            'name': 'Impossible Decentralized Incubator Access Token',
            'address': '0xd5b87813A5ecb2eaC24b6B3F2d4b8b69F583aF4e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ACAP',
            'name': 'Alpha Capital',
            'address': '0xd5A98E77d1fEB091344096301Ea336a5C07a6A41',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24044/thumb/gfSKeO3.png?1646289796',
            'coingeckoId': 'alpha-brain-capital-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BRT',
            'name': 'Britto',
            'address': '0xd578779dbC9252218E12d18D628e3cb27E4a56f2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22830/thumb/britto-coin-logo.jpg?1642664707',
            'coingeckoId': 'britto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FTX',
            'name': 'FintruX',
            'address': '0xd559f20296FF4895da39b5bd9ADd54b442596a61',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xd559f20296ff4895da39b5bd9add54b442596a61.png',
            'coingeckoId': 'fintrux',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'CW',
            'name': 'CardWallet',
            'address': '0xd55236D48606c295adEbF129dAD04Fc74BFaA708',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17820/thumb/cardwallet%28circle%29.png?1629341892',
            'coingeckoId': 'cardwallet',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'MAGE',
            'name': 'MetaBrands',
            'address': '0xd52AAE39a2b5CC7812F7B9450EBB61DfEf702B15',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21244/thumb/MAGE-CG.png?1638766340',
            'coingeckoId': 'metabrands',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HUSKY',
            'name': 'Husky',
            'address': '0xd5281BB2d1eE94866B03A0fcCDd4e900c8Cb5091',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14842/thumb/husky_logo.png?1618634383',
            'coingeckoId': 'husky',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'PPT',
            'name': 'Populous',
            'address': '0xd4fa1460F537bb9085d22C7bcCB5DD450Ef28e3a',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/753/thumb/populous.png?1548331905',
            'coingeckoId': 'populous',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XRPC',
            'name': 'XRP Classic',
            'address': '0xd4cA5c2AFf1eeFb0BeA9e9Eab16f88DB2990C183',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7259/thumb/xrpc.png?1572011410',
            'coingeckoId': 'xrp-classic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CND',
            'name': 'Cindicator',
            'address': '0xd4c435F5B09F855C3317c8524Cb1F586E42795fa',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xd4c435f5b09f855c3317c8524cb1f586e42795fa.png',
            'coingeckoId': 'cindicator',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'COLI',
            'name': 'Coliquidity',
            'address': '0xd49EFA7BC0D339D74f487959C573d518BA3F8437',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15813/thumb/sign_dark_200x200.png?1646641417',
            'coingeckoId': 'shield-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRVL',
            'name': 'TRVL',
            'address': '0xd47bDF574B4F76210ed503e0EFe81B58Aa061F3d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20911/thumb/trvl.jpeg?1649390006',
            'coingeckoId': 'dtravel',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WCO',
            'name': 'Winco',
            'address': '0xd44bb6663936CAb1310584A277f7DAa6943d4904',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3628/thumb/Webp.net-resizeimage_%281%29.png?1547038550',
            'coingeckoId': 'winco',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APN',
            'name': 'Apron',
            'address': '0xd4342a57eCf2Fe7ffA37c33cb8f63b1500e575E6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14916/thumb/1_GOjoDhGzzpqnMPGpHGeWhg.png?1618998577',
            'coingeckoId': 'apron',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTR',
            'name': 'Bitrue Coin',
            'address': '0xd433138d12beB9929FF6fd583DC83663eea6Aaa5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8873/thumb/Bittrue_logo.png?1637142758',
            'coingeckoId': 'bitrue-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MNW',
            'name': 'Morpheus Network',
            'address': '0xd3E4Ba569045546D09CF021ECC5dFe42b1d7f6E4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2379/thumb/MRPH_CoinGecko.png?1635847791',
            'coingeckoId': 'morpheus-network',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'LKT',
            'name': 'Locker Token',
            'address': '0xd3E133a0A14Bb8B595e5Fbf9851c7c783685BA69',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28269/thumb/Locker.jpeg?1668995910',
            'coingeckoId': 'locker-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ECU',
            'name': 'Decurian',
            'address': '0xd3CDc4e75750DC1e59F8342200742B6B29490e70',
            'decimals': 3,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10988/thumb/ECU-LOGO-200x200.png?1586914495',
            'coingeckoId': 'decurian',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOOI',
            'name': 'Moona ',
            'address': '0xd3C89cAC4A4283EDbA6927e2910fD1eBC14fe006',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14125/thumb/mooi.png?1617866132',
            'coingeckoId': 'moonai',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SIS',
            'name': 'Symbiosis Finance',
            'address': '0xd38BB40815d2B0c2d2c866e0c72c5728ffC76dd9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xd38bb40815d2b0c2d2c866e0c72c5728ffc76dd9.png',
            'coingeckoId': 'symbiosis-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ZAM',
            'name': 'Zam io',
            'address': '0xd373576a9e738F37Dc6882328358Ff69c4cAf4c6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19522/thumb/zam.png?1635324134',
            'coingeckoId': 'zam-io',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QUA',
            'name': 'Quarashi',
            'address': '0xd35c06a2781f648c75290976ecF71E71582188B7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25589/thumb/Lk2A7ta.png?1652769197',
            'coingeckoId': 'quarashi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DFE',
            'name': 'DFE Finance',
            'address': '0xd35Ce4FD815522Ff52243EB587020d557617f4dB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13682/thumb/200x200DFE.png?1610835715',
            'coingeckoId': 'dfe-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REZ',
            'name': 'REZ',
            'address': '0xd34c4074636330397A2Ea26012e6C26a15363B5F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/7332.png',
            'coingeckoId': null,
            'listedIn': [
                'RouterProtocol'
            ]
        },
        {
            'symbol': 'ONG',
            'name': 'SoMee Social  OLD ',
            'address': '0xd341d1680Eeee3255b8C4c75bCCE7EB57f144dAe',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xd341d1680eeee3255b8c4c75bcce7eb57f144dae.png',
            'coingeckoId': 'somee-social-old',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'FANS',
            'name': 'Unique Fans',
            'address': '0xd33D0EB1c0c0295c3A2409a9101dd511823Bf217',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14715/thumb/fans_200X200.png?1617922576',
            'coingeckoId': 'unique-fans',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AEL',
            'name': 'Aelysir',
            'address': '0xd337382da15d12Bb6e56498e91DF64f86c8f1EA8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12955/thumb/ael_logo.png?1603794538',
            'coingeckoId': 'aelysir',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TMED',
            'name': 'MDsquare',
            'address': '0xd32641191578Ea9b208125dDD4EC5E7B84FcaB4C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8558/thumb/bAdnHTWUIl4rFB4EfbH2L50BW7xi2T9ubci7v6xfYDipXkrG8Wb4LS8g6eAiMsSKqLJfV-FjH5TrYALqACw4MM2A9NfK_L6Ujnddhw1uEgL0hmVQnY96xZeL0C4kkFRJalreK9wUBzjZ4pRhZLdQf9h1drSTF7NE9moAdQ7KtuOvJqommn_q-TjQpF8GUB7J2Ejs7zIvhXXsUHGI1.jpg?1559198882',
            'coingeckoId': 'mdsquare',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TKP',
            'name': 'TOKPIE',
            'address': '0xd31695a1d35E489252CE57b129FD4b1B05E6AcaC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3731/thumb/tokpie-200x200.png?1562207865',
            'coingeckoId': 'tokpie',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BNTY',
            'name': 'Bounty0x',
            'address': '0xd2d6158683aeE4Cc838067727209a0aAF4359de3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1444/thumb/bounty0x.png?1547562674',
            'coingeckoId': 'bounty0x',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DTUBE',
            'name': 'Dtube Coin',
            'address': '0xd2be3722B17b616c51ed9B8944A227D1ce579C24',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13126/thumb/dtube_logo.png?1605500467',
            'coingeckoId': 'dtube-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DFND',
            'name': 'dFund',
            'address': '0xd2adC1C84443Ad06f0017aDCa346Bd9b6fc52CaB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15286/thumb/1zbdX36.png?1620355973',
            'coingeckoId': 'dfund',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ICA',
            'name': 'Icarus Network',
            'address': '0xd2F29748F8698606dca9f48b8967241b595ab9E5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16325/thumb/Icarusnetwork_200-200.png?1623742696',
            'coingeckoId': 'icarus-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VIKKY',
            'name': 'Vikky',
            'address': '0xd2946be786F35c3Cc402C29b323647aBda799071',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5278/thumb/vikkytoken.png?1548760050',
            'coingeckoId': 'vikkytoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUNC',
            'name': 'Wrapped Terra Classic',
            'address': '0xd2877702675e6cEb975b4A1dFf9fb7BAF4C91ea9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xd2877702675e6ceb975b4a1dff9fb7baf4c91ea9.png',
            'coingeckoId': 'wrapped-terra',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'BCP',
            'name': 'bitcoinplatinums',
            'address': '0xd26fb114401Ec86887CD09F62eccd95fCf20B571',
            'decimals': 8,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AZUM',
            'name': 'Azuma Coin',
            'address': '0xd26a9C3437f7D121098c8C05C7413F5Cc70BB070',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10483/thumb/WDncHJ5.png?1580161760',
            'coingeckoId': 'azuma-coin',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'OMG',
            'name': 'OMG Network',
            'address': '0xd26114cd6EE289AccF82350c8d8487fedB8A0C07',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xd26114cd6ee289accf82350c8d8487fedb8a0c07.png',
            'coingeckoId': 'omisego',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'MDM',
            'name': 'MEDIUM',
            'address': '0xd24DFf6117936B6ff97108CF26c1dD8865743d87',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9961/thumb/VEEFC4sG.png?1574293042',
            'coingeckoId': 'medium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AAMMUSDC',
            'name': 'Aave AMM USDC',
            'address': '0xd24946147829DEaA935bE2aD85A3291dbf109c80',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17226/thumb/aAMMUSDC_2x.png?1626939994',
            'coingeckoId': 'aave-amm-usdc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BBW',
            'name': 'BabyWhale',
            'address': '0xd2287a52287dd64AE2c690D74322E2e345dC8F69',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24168/thumb/babywhale_32.png?1646716918',
            'coingeckoId': 'babywhale',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KING',
            'name': 'King Swap',
            'address': '0xd2057d71fE3F5b0dc1E3e7722940E1908Fc72078',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13622/thumb/rsz_king_token_new.png?1610437757',
            'coingeckoId': 'king-swap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HMT',
            'name': 'HUMAN Protocol',
            'address': '0xd1ba9BAC957322D6e8c07a160a3A8dA11A0d2867',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16412/thumb/human_protocol.PNG?1623971316',
            'coingeckoId': 'human-protocol',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'LQNTY',
            'name': 'Liquinity',
            'address': '0xd1bE6C91352E5AFCa7C0db69Eaa390dBCBFCa762',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26844/thumb/photo_5161276562430077559_x.jpg?1660391137',
            'coingeckoId': 'liquinity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NKCLC',
            'name': 'NKCL Classic',
            'address': '0xd1b624f07a4D9B3e3746E33cb58f42dF079b5444',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17893/thumb/w02Nksm-.png?1629718144',
            'coingeckoId': 'nkcl-classic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETHY',
            'name': 'Ethereum Yield',
            'address': '0xd1AFBCCC9A2c2187ea544363B986EA0AB6EF08B5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13191/thumb/rOIuPZM.png?1606101103',
            'coingeckoId': 'ethereum-yield',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TUBER',
            'name': 'TokenTuber',
            'address': '0xd1766A85B0d6F81185782dC07F15326d63C3cBaa',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9617/thumb/Wlk5tQQe_400x400.png?1569853753',
            'coingeckoId': 'tokentuber',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MCX',
            'name': 'Machi X',
            'address': '0xd15eCDCF5Ea68e3995b2D0527A0aE0a3258302F8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xd15ecdcf5ea68e3995b2d0527a0ae0a3258302f8.png',
            'coingeckoId': 'machix',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'AGOV',
            'name': 'Answer Governance',
            'address': '0xd1420AF453Fd7bf940573431D416cAcE7fF8280c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14383/thumb/AgoV_Logo1.png?1622448433',
            'coingeckoId': 'answer-governance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EXP',
            'name': 'Exp',
            'address': '0xd137561C20BE8e870D62e2aE92269f0950702f77',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24263/thumb/explogo.png?1648627774',
            'coingeckoId': 'exp',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WWish',
            'name': 'Wrapped Wish',
            'address': '0xd123575D94A7aD9Bff3ad037aE9d4d52f41a7518',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://api.rubic.exchange/assets/ethereum/0xd123575d94a7ad9bff3ad037ae9d4d52f41a7518/logo.png',
            'coingeckoId': 'mywish',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'AAMMBPTBALWETH',
            'name': 'Aave AMM BptBALWETH',
            'address': '0xd109b2A304587569c84308c55465cd9fF0317bFB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17261/thumb/aAmmBptBALWETH.png?1626959531',
            'coingeckoId': 'aave-amm-bptbalweth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ENX',
            'name': 'ENEX',
            'address': '0xd0d7A9f2021958e51d60D6966b7BbeD9D1CB22B5',
            'decimals': 10,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15070/thumb/ENEXpng200.png?1619621442',
            'coingeckoId': 'enex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INKZ',
            'name': 'INKz',
            'address': '0xd0c37Cda48Fe5D6Ee7132181a90DC58206Db5404',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21860/thumb/INKZ_TOKEN.png?1640149351',
            'coingeckoId': 'inkz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAROSCA',
            'name': 'Marosca Inu',
            'address': '0xd0A2e008eb1f160fa6055A28D5224034d8f527F7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27881/thumb/Cr3sFSxf_400x400.jpg?1666233042',
            'coingeckoId': 'marosca-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EGG',
            'name': 'EGG Protocol',
            'address': '0xd0983dD6a0334c71d0478255D1F794508026b888',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18735/thumb/EGG_%281%29.png?1633308617',
            'coingeckoId': 'egg-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RFR',
            'name': 'Refereum',
            'address': '0xd0929d411954c47438dc1d871dd6081F5C5e149c',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2102/thumb/refereum.png?1548608001',
            'coingeckoId': 'refereum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TVK',
            'name': 'The Virtua Kolect',
            'address': '0xd084B83C305daFD76AE3E1b4E1F1fe2eCcCb3988',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xd084b83c305dafd76ae3e1b4e1f1fe2ecccb3988.png',
            'coingeckoId': 'the-virtua-kolect',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'FOLD',
            'name': 'Manifold Finance',
            'address': '0xd084944d3c05CD115C09d072B9F44bA3E0E45921',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xd084944d3c05cd115c09d072b9f44ba3e0e45921.png',
            'coingeckoId': 'manifold-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'TOL',
            'name': 'Tolar',
            'address': '0xd07D9Fe2d2cc067015E2b4917D24933804f42cFA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4487/thumb/tolar.png?1548759060',
            'coingeckoId': 'tolar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WPE',
            'name': 'OPES  Wrapped PE ',
            'address': '0xd075e95423C5c4BA1E122CaE0f4CdFA19b82881b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16647/thumb/opes.png?1625834316',
            'coingeckoId': 'opes-wrapped-pe',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BULLSHIT',
            'name': '3X Long Shitcoin Index Token',
            'address': '0xd06b25F67A17f12b41F615b34D87ECd716fF55a0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10228/thumb/683JEXMN_400x400_%281%29.png?1576618335',
            'coingeckoId': '3x-long-shitcoin-index-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AAPL',
            'name': 'Apple Protocol',
            'address': '0xd059c8a4c7f53C4352d933b059349Ba492294ac9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13208/thumb/aapl.png?1606200126',
            'coingeckoId': 'apple-protocol-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NAO',
            'name': 'NFTDAO',
            'address': '0xd049206fB408a611E543791F2d8F102a8bC253dc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22576/thumb/NAO.png?1642083280',
            'coingeckoId': 'nftdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LIX',
            'name': 'Lixir Finance',
            'address': '0xd0345D30FD918D7682398ACbCdf139C808998709',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15333/thumb/SVxrN7DF_400x400.jpg?1637855435',
            'coingeckoId': 'lixir-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TSANGNYON',
            'name': 'HERUKA TSANGNYON',
            'address': '0xd031edafac6a6ae5425e77F936022E506444C242',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27611/thumb/81b3e527-f8a9-47f7-8d4d-10f754f501c2.png?1664853112',
            'coingeckoId': 'heruka-tsangnyon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LTO',
            'name': 'LTO Network',
            'address': '0xd01409314aCb3b245CEa9500eCE3F6Fd4d70ea30',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6068/thumb/lto.png?1638855565',
            'coingeckoId': 'lto-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADS',
            'name': 'Adshares',
            'address': '0xcfcEcFe2bD2FED07A9145222E8a7ad9Cf1Ccd22A',
            'decimals': 11,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xcfcecfe2bd2fed07a9145222e8a7ad9cf1ccd22a.png',
            'coingeckoId': 'adshares',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'GOLDR',
            'name': 'Golden Ratio Coin',
            'address': '0xcfE4F03C3AFbB9857b29fC706180Bf0044900D59',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10363/thumb/original_GRO-768x768.png?1578612119',
            'coingeckoId': 'golden-ratio-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ONIGIRI',
            'name': 'Onigiri',
            'address': '0xcf9c692F7e62Af3c571D4173fd4ABf9A3E5330D0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12699/thumb/onigiri-coingecko.png?1601857724',
            'coingeckoId': 'onigiri',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLOKI',
            'name': 'FLOKI',
            'address': '0xcf0C122c6b73ff809C693DB761e7BaeBe62b6a2E',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xcf0c122c6b73ff809c693db761e7baebe62b6a2e.png',
            'coingeckoId': 'floki',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'Froyo',
            'name': 'Froyo',
            'address': '0xcf0BCf85082e3CFE591bd2451Bc4B46faa34c7De',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'VTX',
            'name': 'Vortex DeFi',
            'address': '0xceb286C9604c542d3cc08b41AA6C9675B078A832',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xceb286c9604c542d3cc08b41aa6c9675b078a832.png',
            'coingeckoId': 'vortex-defi',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'GOTG',
            'name': 'Got Guaranteed',
            'address': '0xceEB07Dd26b36287B6d109f0b06d7e8202Ce8c1D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26107/thumb/%EA%B0%93%EC%A7%80%EB%A1%9C%EA%B3%A0.png?1655882278',
            'coingeckoId': 'got-guaranteed',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-272-NE-42ND',
            'name': 'RealT   272 N E  42nd Court  Deerfield ',
            'address': '0xce111a198eB04F388AceB78c40ceD6daF1b0514a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16635/thumb/272-Court-1.jpeg?1624556789',
            'coingeckoId': '272-n-e-42nd-court',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DUZCE',
            'name': 'Duzce',
            'address': '0xcdd2FA4c2b36a1a14eDC41dA1C9F9B2CB9f981aa',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25979/thumb/20569.png?1655113117',
            'coingeckoId': 'duzce',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FACE',
            'name': 'FACE Vault  NFTX ',
            'address': '0xcd46d92C46be1DbbD5CcC497e95611ABE9D507Bc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17039/thumb/FACE.png?1626146356',
            'coingeckoId': 'face-vault-nftx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTRM',
            'name': 'Materium',
            'address': '0xcd17fA52528f37FACB3028688E62ec82d9417581',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24613/thumb/mtrm.png?1648373908',
            'coingeckoId': 'materium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UFEWO',
            'name': 'Unicly Fewocious Collection',
            'address': '0xcccF837f40D334F8602f031e64B52AD4CD2b6601',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16387/thumb/uFEWO.png?1623894677',
            'coingeckoId': 'unicly-fewocious-collection',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ECOX',
            'name': 'ECOx',
            'address': '0xcccD1Ba9f7acD6117834E0D28F25645dECb1736a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27962/thumb/_ECOx.png?1666751574',
            'coingeckoId': 'ecox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PARA',
            'name': 'Parabolic',
            'address': '0xcca3E26Be51b8905f1A01872524f17eb55Bd02fb',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25187/thumb/LHh3hHnQ_400x400.jpg?1650600695',
            'coingeckoId': 'parabolic-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTMX',
            'name': 'BitMax token',
            'address': '0xcca0c9c383076649604eE31b20248BC04FdF61cA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xcca0c9c383076649604ee31b20248bc04fdf61ca.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'CWBTC',
            'name': 'cWBTC',
            'address': '0xccF4429DB6322D5C611ee964527D42E5d685DD6a',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10823/thumb/cwbtc.png?1584331700',
            'coingeckoId': 'compound-wrapped-btc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GODS',
            'name': 'Gods Unchained',
            'address': '0xccC8cb5229B0ac8069C51fd58367Fd1e622aFD97',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xccC8cb5229B0ac8069C51fd58367Fd1e622aFD97/logo.png',
            'coingeckoId': 'gods-unchained',
            'listedIn': [
                'coingecko',
                'uniswap',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SPACE',
            'name': 'Spacelens',
            'address': '0xcc7ab8d78dBA187dC95bF3bB86e65E0C26d0041f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16979/thumb/SPACE___Spacelens.png?1625900475',
            'coingeckoId': 'spacelens',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MRG',
            'name': 'WemergeToken',
            'address': '0xcbee6459728019CB1f2bB971dDe2eE3271BC7617',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6833/thumb/wemerge200.png?1547043144',
            'coingeckoId': 'wemergetoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YOYOW',
            'name': 'YOYOW',
            'address': '0xcbeAEc699431857FDB4d37aDDBBdc20E132D4903',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1270/thumb/yoyow.png?1548761123',
            'coingeckoId': 'yoyow',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASTRO',
            'name': 'AstroTools',
            'address': '0xcbd55D4fFc43467142761A764763652b48b969ff',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xcbd55d4ffc43467142761a764763652b48b969ff.png',
            'coingeckoId': 'astrotools',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SPO',
            'name': 'Spores Network',
            'address': '0xcbE771323587EA16dACB6016e269D7F08A7ACC4E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17431/thumb/SPO_token.png?1627619762',
            'coingeckoId': 'spores-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HMQ',
            'name': 'Humaniq',
            'address': '0xcbCC0F036ED4788F63FC0fEE32873d6A7487b908',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/647/thumb/humaniq.jpg?1547034375',
            'coingeckoId': 'humaniq',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZEUS10000',
            'name': 'ZEUS10000',
            'address': '0xcbC1Aa6961B28cCcb11E1cdDd84Ad8B42Ec920b1',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22559/thumb/2022-01-11_22.10.11.jpg?1642056602',
            'coingeckoId': 'zeus10000',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SURE',
            'name': 'inSure DeFi',
            'address': '0xcb86c6A22CB56B6cf40CaFEDb06BA0DF188a416E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10354/thumb/logo-grey-circle.png?1614910406',
            'coingeckoId': 'insure',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JENNY',
            'name': 'Jenny DAO V2',
            'address': '0xcb4D749FD1FF081D6fEA3bd978de87603E098E63',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28116/thumb/jenny.png?1667554356',
            'coingeckoId': 'jenny-dao-v2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'pONT',
            'name': 'Poly Ontology Token',
            'address': '0xcb46C550539ac3DB72dc7aF7c89B11c306C727c2',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xcb46c550539ac3db72dc7af7c89b11c306c727c2.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'RISU',
            'name': 'Risu',
            'address': '0xcb3c5438Dae9Fe30B18eA53dA3Dab0E7dcaA0E4b',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27229/thumb/logo_%288%29.png?1667725134',
            'coingeckoId': 'risu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CDL',
            'name': 'CoinDeal Token',
            'address': '0xcb17cD357c7acD594717D899ecb9df540F633F27',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9960/thumb/2ZP5JaIL_400x400.jpg?1574233581',
            'coingeckoId': 'coindeal-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BIP',
            'name': 'Minter Network',
            'address': '0xcafE34BAE6F1b23A6B575303EdCc0578d2188131',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9982/thumb/Nvoj_6Mu_400x400.jpg?1587968303',
            'coingeckoId': 'minter-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CADC',
            'name': 'CAD Coin',
            'address': '0xcaDC0acd4B445166f12d2C07EAc6E2544FbE2Eef',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xcadc0acd4b445166f12d2c07eac6e2544fbe2eef.png',
            'coingeckoId': 'cad-coin',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MON',
            'name': 'Monfter',
            'address': '0xcaCc19C5Ca77E06D6578dEcaC80408Cc036e0499',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2546/thumb/monft.PNG?1641281398',
            'coingeckoId': 'monfter',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UniLENDETH',
            'name': 'Uniswap LEND LP',
            'address': '0xcaA7e4656f6A2B59f5f99c745F91AB26D1210DCe',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'ipfs://Qmcbin86EXd14LhbqLknH9kM3N7oueBYt9qQmZdmMWqrgu',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WOLVERINU',
            'name': 'WOLVERINU  OLD ',
            'address': '0xca7b3ba66556C4Da2E2A9AFeF9C64F909A59430a',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19374/thumb/logo200.png?1643189122',
            'coingeckoId': 'wolverinu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DHT',
            'name': 'dHEDGE DAO',
            'address': '0xca1207647Ff814039530D7d35df0e1Dd2e91Fa84',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xca1207647ff814039530d7d35df0e1dd2e91fa84.png',
            'coingeckoId': 'dhedge-dao',
            'listedIn': [
                'coingecko',
                '1inch',
                'optimism',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'WNL',
            'name': 'WinStars Live',
            'address': '0xcFbf70e33d5163E25B0dad73955c1BD9E8cd8BA2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4178/thumb/HEq48ZDOCvVTVFw8J8qws9les1E27b_8F_k34sVhpwULn3muT66wiQI9HjNNUhGLELkGGSi0daPgxt8YHjbRqHzX1uurAbySQdtr_FW0GRBNN3CEWGU03Vn_z9NdJiGHV193Y_RWPDAGlm69ISIMSdTxT34VycLZTMrgJeWaNXrblBrXpPprJXKDJCKsa_Wt1jofCsygfNLdWQ0j_.jpg?1563768641',
            'coingeckoId': 'winstars',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VEMP',
            'name': 'vEmpire DDAO',
            'address': '0xcFEB09C3c5F0f78aD72166D55f9e6E9A60e96eEC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18074/thumb/Ay5nuny.png?1630380510',
            'coingeckoId': 'vempire-ddao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EXPO',
            'name': 'Exponential Capital',
            'address': '0xcFAF8EDCEA94eBAA080DC4983C3f9BE5701D6613',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23409/thumb/expo.png?1645856094',
            'coingeckoId': 'exponential-capital',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MESH',
            'name': 'MeshBox',
            'address': '0xcF9fBFfEC9e0e5BbC62E79bf1965f5Db76955661',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6200/thumb/DPuEPJQ6_400x400.jpg?1547042229',
            'coingeckoId': 'meshbox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ONX',
            'name': 'Ownix',
            'address': '0xcF78C7dD70d6F30F6E3609e905e78305Da98c863',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17469/thumb/200_200.jpg?1633360949',
            'coingeckoId': 'ownix',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CBU',
            'name': 'Banque Universal',
            'address': '0xcEf46305D096fa876Dd23048bf80F9345282e3fc',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8112/thumb/png_tr.png?1554952499',
            'coingeckoId': 'banque-universal',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GR',
            'name': 'GROM',
            'address': '0xcE593a29905951E8Fc579bC092ecA72577dA575c',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13216/thumb/gr.png?1606278269',
            'coingeckoId': 'grom',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KEYT',
            'name': 'Rebit',
            'address': '0xcE13aBCE0DB5A8224616ef24D3979d466F19CF90',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9891/thumb/rebit.PNG?1573119183',
            'coingeckoId': 'rebit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHUEY',
            'name': 'Shuey Rhon Inu',
            'address': '0xcDcA1B81DbB543BAA92c97b701396Cd3bA315E5D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24306/thumb/shuey.jpg?1647316292',
            'coingeckoId': 'shuey-rhon-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UFI',
            'name': 'PureFi',
            'address': '0xcDa4e840411C00a614aD9205CAEC807c7458a0E3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17341/thumb/purefi.PNG?1627362147',
            'coingeckoId': 'purefi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STABLEx',
            'name': 'ARC STABLEx',
            'address': '0xcD91538B91B4ba7797D39a2f66E63810b50A33d0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xcd91538b91b4ba7797d39a2f66e63810b50a33d0.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'EWC',
            'name': 'Erugo World Coin',
            'address': '0xcD74Cf66c43e45Abd5703f3642F73d0675D6AFF7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21652/thumb/Swhwfr3G_400x400.jpg?1639650872',
            'coingeckoId': 'erugo-world-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANJ',
            'name': 'Aragon Network Juror',
            'address': '0xcD62b1C403fa761BAadFC74C525ce2B51780b184',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xcd62b1c403fa761baadfc74c525ce2b51780b184.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'GPUNKS20',
            'name': 'Gan Punks',
            'address': '0xcCcBF11AC3030ee8CD7a04CFE15a3718df6dD030',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17859/thumb/unnamed_%282%29.gif?1629683073',
            'coingeckoId': 'gan-punks',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OWN',
            'name': 'OWN Token',
            'address': '0xcC6F15Be8573cB8243C42d300565566D328213Dd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10950/thumb/cropped-WhatsApp-Image-2020-04-04-at-9.04.01-PM.jpeg?1586305520',
            'coingeckoId': 'own-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASAP',
            'name': 'Chainswap',
            'address': '0xcC665390b03c5D324D8fAF81C15eCee29A73bCB4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14945/thumb/chainswap_logo.png?1619109717',
            'coingeckoId': 'chainswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PATX',
            'name': 'Patriot Exchange',
            'address': '0xcC52F2a2B86bE8b45dDbA59D627D19233e1F2c29',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25881/thumb/patx.png?1654334290',
            'coingeckoId': 'patriot-exchange',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WEC',
            'name': 'Whole Earth Coin',
            'address': '0xcC1a8BD438BebC4b2a885a34475BB974f2124317',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16313/thumb/61ea0d78e70bb93dee3c03a0_whole-earth-coin_brand.png?1643189537',
            'coingeckoId': 'whole-earth-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRETH2',
            'name': 'Cream ETH 2',
            'address': '0xcBc1065255cBc3aB41a6868c22d1f1C573AB89fd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xcBc1065255cBc3aB41a6868c22d1f1C573AB89fd/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'BTM',
            'name': 'Bytom',
            'address': '0xcB97e65F07DA24D46BcDD078EBebd7C6E6E3d750',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/btm.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'CTI',
            'name': 'ClinTex CTi',
            'address': '0xcB8fb2438A805664cD8c3e640b85AC473DA5BE87',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13266/thumb/CTI.png?1606817542',
            'coingeckoId': 'clintex-cti',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFT',
            'name': 'NFT Protocol',
            'address': '0xcB8d1260F9c92A3A545d409466280fFdD7AF7042',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12174/thumb/nftprotocol_32.png?1597818115',
            'coingeckoId': 'nft-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VEGA',
            'name': 'Vega Protocol',
            'address': '0xcB84d72e61e383767C4DFEb2d8ff7f4FB89abc6e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xcB84d72e61e383767C4DFEb2d8ff7f4FB89abc6e/logo.png',
            'coingeckoId': 'vega-protocol',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'PSP',
            'name': 'ParaSwap',
            'address': '0xcAfE001067cDEF266AfB7Eb5A286dCFD277f3dE5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xcafe001067cdef266afb7eb5a286dcfd277f3de5.png',
            'coingeckoId': 'paraswap',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'EAC',
            'name': 'Education Assessment Cult',
            'address': '0xcAe636167db2369bd746F2bcBa79a6e8b0d4E000',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25466/thumb/eac.png?1651915165',
            'coingeckoId': 'education-assessment-cult',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRD',
            'name': 'CRD Network',
            'address': '0xcAaa93712BDAc37f736C323C93D4D5fDEFCc31CC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2859/small/CRD.png?1623397651',
            'coingeckoId': 'crd-network',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'MARVIN',
            'name': 'Marvin Inu',
            'address': '0xcAa9Ed6D7502595B555113D4517668aE24038C8a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22039/thumb/lVshyCp.png?1640669485',
            'coingeckoId': 'marvin-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HDS',
            'name': 'HotDollars',
            'address': '0xcAFE27178308351a12ffFffDeb161d9d730DA082',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8505/thumb/pvGh4E0b_400x400.jpg?1559026304',
            'coingeckoId': 'hotdollars-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INARI',
            'name': 'Inari',
            'address': '0xcA75C43f8c9AfD356c585ce7AA4490B48A95C466',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16795/thumb/token_logo_%281%29.png?1625036828',
            'coingeckoId': 'inari',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'ECN',
            'name': 'Ecosystem Coin Network',
            'address': '0xcA47bC04ecfcd324b238Ed411741efb65B3C777b',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18529/thumb/WeChat_Image_20210928174723.jpg?1632822490',
            'coingeckoId': 'ecosystem-coin-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BWT',
            'name': 'Bittwatt',
            'address': '0xcA3Ea3061d638E02113aA960340c98343b5aCd62',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4777/thumb/bittwatt.png?1547274101',
            'coingeckoId': 'bittwatt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WJXN',
            'name': 'Jax Network',
            'address': '0xcA1262e77Fb25c0a4112CFc9bad3ff54F617f2e6',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18737/thumb/photo.jpg?1633360887',
            'coingeckoId': 'jax-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAGA',
            'name': 'MAGA Coin ETH',
            'address': '0xc9b6A17EbB43491635F603A01f8Bb3e4B5D22228',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27455/thumb/rsz_jimerkya_400x400.png?1664090952',
            'coingeckoId': 'maga-coin-eth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAT',
            'name': 'SoMee Advertising',
            'address': '0xc9F1016d336ef77AeE75Fc11Ad64C5Ecf9121332',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16040/thumb/SoMee.png?1622683511',
            'coingeckoId': 'somee-advertising-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARAI',
            'name': 'Aave RAI',
            'address': '0xc9BC48c72154ef3e5425641a3c747242112a46AF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17245/thumb/aRAI_2x.png?1626942652',
            'coingeckoId': 'aave-rai',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RSS3',
            'name': 'RSS3',
            'address': '0xc98D64DA73a6616c42117b582e832812e7B8D57F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23575/thumb/RSS3.png?1644494645',
            'coingeckoId': 'rss3',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INO',
            'name': 'Ino Coin',
            'address': '0xc9859fccC876e6b4B3C749C5D29EA04F48aCb74F',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4096/thumb/inologo.jpg?1547039275',
            'coingeckoId': 'ino-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LP-YCRV',
            'name': 'LP Yearn CRV Vault',
            'address': '0xc97232527B62eFb0D8ed38CF3EA103A6CcA4037e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27624/thumb/yearncrvlp_32.png?1664885596',
            'coingeckoId': 'lp-yearn-crv-vault',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORME',
            'name': 'Ormeus Coin',
            'address': '0xc96DF921009B790dfFcA412375251ed1A2b75c60',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/964/thumb/ORMEUS_logo.png?1606557243',
            'coingeckoId': 'ormeuscoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IFX24',
            'name': 'IFX24',
            'address': '0xc962ad021a69D457564e985738C719aE3f79B707',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10444/thumb/lpFSaoD.png?1579475634',
            'coingeckoId': 'ifx24',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GRT',
            'name': 'The Graph',
            'address': '0xc944E90C64B2c07662A292be6244BDf05Cda44a7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xc944e90c64b2c07662a292be6244bdf05cda44a7.png',
            'coingeckoId': 'the-graph',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SHIELD',
            'name': 'Crypto Shield',
            'address': '0xc944273b805DeBd35c63011943ABc5aB9eDdb8E3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21614/thumb/14834.png?1639610437',
            'coingeckoId': 'crypto-shield',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XAUTBULL',
            'name': '3X Long Tether Gold Token',
            'address': '0xc9287623832668432099CEF2FfDEF3CeD14f4315',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11979/thumb/683JEXMN_400x400__1_.png?1596618665',
            'coingeckoId': '3x-long-tether-gold-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZKAS',
            'name': 'ZKasino',
            'address': '0xc91c885580da11Ac060bDd692BdAA7eE29cfc976',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26649/thumb/zkasino.jpeg?1659405528',
            'coingeckoId': 'zkasino',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AI',
            'name': 'Multiverse',
            'address': '0xc91B523A59ACC63A64f61fc7bBfB4bfc82DD25f2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16329/thumb/multiverse_logo_full_color_rgb_200px_72ppi.png?1652011384',
            'coingeckoId': 'multiverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CCP',
            'name': 'CryptoCoinPay',
            'address': '0xc8d07671aFbA9492Da95819De4AD10859e00aB7F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7127/thumb/T9ywgTGYuoYBFagC3PzdSVNKHxZSPB6Kmz.png?1647433798',
            'coingeckoId': 'cryptocoinpay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MATRIX',
            'name': 'Matrix Labs',
            'address': '0xc8D3DCb63C38607Cb0c9d3F55E8eccE628A01C36',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18297/thumb/matrixlabs.png?1643277367',
            'coingeckoId': 'matrixswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LVN',
            'name': 'LivenPay',
            'address': '0xc8Cac7672f4669685817cF332a33Eb249F085475',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9554/thumb/8PgKjhJn_400x400.jpg?1568837435',
            'coingeckoId': 'livenpay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DFN',
            'name': 'Difo Network',
            'address': '0xc8BA3cf103E5A1658209C366153197AC7FA9C9B1',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14504/thumb/difo.png?1616566305',
            'coingeckoId': 'difo-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'aUniLENDETH',
            'name': 'Aave Interest bearing UniLENDETH',
            'address': '0xc88ebbf7C523f38Ef3eB8A151273C0F0dA421e63',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'ipfs://QmaznB5PRhMC696u8yZuzN6Uwrnp7Zmfa5CydVUMvLJc9i/aUNI%20LEND%20ETH.svg',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'IUSDC',
            'name': 'Instadapp USDC',
            'address': '0xc8871267e07408b89aA5aEcc58AdCA5E574557F8',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25820/thumb/iUSDC_100x100.png?1654055475',
            'coingeckoId': 'instadapp-usdc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IEUROS',
            'name': 'Inflation Adjusted EURO',
            'address': '0xc87f0a3F3671F7d01278625561dc6e284B62daE9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26442/thumb/894CE468-A364-42BA-83B0-60A7CA859614.png?1658110157',
            'coingeckoId': 'inflation-adjusted-euro',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'CONV',
            'name': 'Convergence',
            'address': '0xc834Fa996fA3BeC7aAD3693af486ae53D8aA8B50',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xc834fa996fa3bec7aad3693af486ae53d8aa8b50.png',
            'coingeckoId': 'convergence',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'NIOX',
            'name': 'Autonio',
            'address': '0xc813EA5e3b48BEbeedb796ab42A30C5599b01740',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xc813ea5e3b48bebeedb796ab42a30c5599b01740.png',
            'coingeckoId': 'autonio',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'IJC',
            'name': 'IjasCoin',
            'address': '0xc7fF1e126cC81e816915FF48c940ED9d4E6d05d6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14099/thumb/Ijascode-Flat-Logo-2-24-2021.png?1614304358',
            'coingeckoId': 'ijascoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GEM',
            'name': 'Gems',
            'address': '0xc7BbA5b765581eFb2Cdd2679DB5Bea9eE79b201f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3945/thumb/Webp.net-resizeimage_%2817%29.jpg?1547038970',
            'coingeckoId': 'gems-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LSC',
            'name': 'Littlesesame',
            'address': '0xc77D7E0dD7b2A01B990e866FeB21d031f1418c2E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8893/thumb/5yIumMGs_400x400.png?1562579316',
            'coingeckoId': 'littlesesame',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FOX',
            'name': 'ShapeShift FOX',
            'address': '0xc770EEfAd204B5180dF6a14Ee197D99d808ee52d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xc770eefad204b5180df6a14ee197d99d808ee52d.png',
            'coingeckoId': 'shapeshift-fox-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'HIZ',
            'name': 'Hiz Finance',
            'address': '0xc761D1ccb38a94703675d2cDb15F7F1B3dcFF7B7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12974/thumb/73051179.png?1603959145',
            'coingeckoId': 'hiz-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZZZ',
            'name': 'zzz.finance',
            'address': '0xc75F15AdA581219c95485c578E124df3985e4CE0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xc75f15ada581219c95485c578e124df3985e4ce0.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ANONS',
            'name': 'Anons Network',
            'address': '0xc74Cb1bBC2a1bc6E0C9E35ee176F832Ad7CDb3Ab',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22095/thumb/ANONS.png?1640795860',
            'coingeckoId': 'anons-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SANSHU',
            'name': 'Sanshu Inu',
            'address': '0xc73C167E7a4Ba109e4052f70D5466D0C312A344D',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15370/thumb/m9DgRlXY.jpg?1623177779',
            'coingeckoId': 'sanshu-inu',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'TRIBE',
            'name': 'Tribe',
            'address': '0xc7283b66Eb1EB5FB86327f08e1B5816b0720212B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xc7283b66eb1eb5fb86327f08e1b5816b0720212b.png',
            'coingeckoId': 'tribe-2',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SUTEKU',
            'name': 'Suteku',
            'address': '0xc7230BADF274995F1933598c249c824fDE26F426',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20824/thumb/hJIxZGE.png?1653548066',
            'coingeckoId': 'suteku',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DIP',
            'name': 'Etherisc DIP',
            'address': '0xc719d010B63E5bbF2C0551872CD5316ED26AcD83',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xc719d010b63e5bbf2c0551872cd5316ed26acd83.png',
            'coingeckoId': 'etherisc',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'AMKR',
            'name': 'Aave MKR',
            'address': '0xc713e5E149D5D0715DcD1c156a020976e7E56B88',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xc713e5e149d5d0715dcd1c156a020976e7e56b88.png',
            'coingeckoId': 'aave-mkr',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'TRYBBULL',
            'name': '3X Long BiLira Token',
            'address': '0xc7038cCf60E48C5b7119E55566A6aD9f2D66C7c2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10449/thumb/683JEXMN_400x400.png?1579561683',
            'coingeckoId': '3x-long-bilira-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TGR',
            'name': 'Tegro',
            'address': '0xc7026a20a640Bc71B9074F7aed52A00cD9147091',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CPRX',
            'name': 'Crypto Perx',
            'address': '0xc6e145421FD494B26dCF2BFeB1b02b7c5721978f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xc6e145421FD494B26dCF2BFeB1b02b7c5721978f/logo.png',
            'coingeckoId': 'crypto-perx',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'CMB',
            'name': 'Cool Monke Banana',
            'address': '0xc6dB556FD9EC09bAB6DFea320e52D8476F61d424',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23385/thumb/cmb-200x200.png?1643965383',
            'coingeckoId': 'cool-monke-banana',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'URUS',
            'name': 'Aurox',
            'address': '0xc6DdDB5bc6E61e0841C54f3e723Ae1f3A807260b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14122/thumb/Aurox.png?1648524329',
            'coingeckoId': 'urus-token',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'POLA',
            'name': 'Polaris Share',
            'address': '0xc691bc298a304d591Ad9b352c7A8D216dE9f2CED',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13817/thumb/tHQrxdYQ_400x400.png?1612051098',
            'coingeckoId': 'polaris-share',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LYM',
            'name': 'Lympo',
            'address': '0xc690F7C7FcfFA6a82b79faB7508c466FEfdfc8c5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xc690f7c7fcffa6a82b79fab7508c466fefdfc8c5.png',
            'coingeckoId': 'lympo',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'GDT',
            'name': 'Globe Derivative Exchange',
            'address': '0xc67B12049c2D0CF6e476BC64c7F82fc6C63cFFc5',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15335/thumb/gdt.PNG?1620597146',
            'coingeckoId': 'globe-derivative-exchange',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MCD',
            'name': 'CDbio',
            'address': '0xc66cDac744916AFb6811C71c277d88dE90cE8D5b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26957/thumb/mcd.png?1660998546',
            'coingeckoId': 'cdbio',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DGTX',
            'name': 'Digitex',
            'address': '0xc666081073E8DfF8D3d1c2292A29aE1A2153eC09',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2188/thumb/DGTX.png?1616385887',
            'coingeckoId': 'digitex-futures-exchange',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FITFI',
            'name': 'STEP.APP',
            'address': '0xc629d02732EE932db1fa83E1fcF93aE34aBFc96B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TSUKA',
            'name': 'Dejitaru Tsuka',
            'address': '0xc5fB36dd2fb59d3B98dEfF88425a3F425Ee469eD',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26405/thumb/Tsuka_200x200.png?1657923568',
            'coingeckoId': 'dejitaru-tsuka',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YVE-CRVDAO',
            'name': 'veCRV DAO yVault',
            'address': '0xc5bDdf9843308380375a611c18B50Fb9341f502A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xc5bddf9843308380375a611c18b50fb9341f502a.png',
            'coingeckoId': 'vecrv-dao-yvault',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'AAMMUNIWBTCWETH',
            'name': 'Aave AMM UniWBTCWETH',
            'address': '0xc58F53A8adff2fB4eb16ED56635772075E2EE123',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17231/thumb/aAmmUniWBTCWETH.png?1626921113',
            'coingeckoId': 'aave-amm-uniwbtcweth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZUSD',
            'name': 'ZUSD',
            'address': '0xc56c2b7e71B54d38Aab6d52E94a04Cbfa8F604fA',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1669025740931_8474358298913196.png',
            'coingeckoId': 'zusd',
            'listedIn': [
                'coingecko',
                'openocean'
            ]
        },
        {
            'symbol': 'SAT',
            'name': 'Social Activity',
            'address': '0xc56b13ebbCFfa67cFb7979b900b736b3fb480D78',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2586/thumb/sat.png?1547036721',
            'coingeckoId': 'sphere-social',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STARS',
            'name': 'Mogul Productions',
            'address': '0xc55c2175E90A46602fD42e931f62B3Acc1A013Ca',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14975/thumb/STARS_LOGO_PNG.png?1619214520',
            'coingeckoId': 'mogul-productions',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTRFLY',
            'name': 'Redacted',
            'address': '0xc55126051B22eBb829D00368f4B12Bde432de5Da',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26745/thumb/redacted_v2.jpg?1660011507',
            'coingeckoId': 'redacted',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EDR',
            'name': 'Endor Protocol',
            'address': '0xc528c28FEC0A90C083328BC45f587eE215760A0F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3683/thumb/0b805574-ef0d-41dc-b518-8d6148bf4716.png?1547038680',
            'coingeckoId': 'endor',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HOP',
            'name': 'Hop Protocol',
            'address': '0xc5102fE9359FD9a28f877a67E36B0F050d81a3CC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xc5102fe9359fd9a28f877a67e36b0f050d81a3cc.png',
            'coingeckoId': 'hop-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ZINU',
            'name': 'Zombie Inu',
            'address': '0xc50EF449171a51FbeAFd7c562b064B6471C36caA',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xc50ef449171a51fbeafd7c562b064b6471c36caa.png',
            'coingeckoId': 'zombie-inu',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'GNFT',
            'name': 'GNFT',
            'address': '0xc502002aEB1b9309FcCb016adF50507987Fc6C2b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23532/thumb/gnft_200x200.png?1644386728',
            'coingeckoId': 'gnft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GN',
            'name': 'GN',
            'address': '0xc5019E129b75D380d3d837B8e609dEc6c8f5d044',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1636698211080_16966893102938707.png',
            'coingeckoId': 'gn',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'HDP',
            'name': 'HEdpAY',
            'address': '0xc4d5545392f5Fc57EBa3AF8981815669bb7E2A48',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7496/thumb/icon_hedpay.png?1555485710',
            'coingeckoId': 'hedpay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COCOS',
            'name': 'COCOS BCX',
            'address': '0xc4c7Ea4FAB34BD9fb9a5e1B1a98Df76E26E6407c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xc4c7ea4fab34bd9fb9a5e1b1a98df76e26e6407c.png',
            'coingeckoId': 'cocos-bcx',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'IBBTC',
            'name': 'Badger Interest Bearing Bitcoin',
            'address': '0xc4E15973E6fF2A35cC804c2CF9D2a1b817a8b40F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15500/thumb/ibbtc.png?1621077589',
            'coingeckoId': 'interest-bearing-bitcoin',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'B20',
            'name': 'B20',
            'address': '0xc4De189Abf94c57f396bD4c52ab13b954FebEfD8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xc4de189abf94c57f396bd4c52ab13b954febefd8.png',
            'coingeckoId': 'b20',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MSI',
            'name': 'Martin Shkreli Inu',
            'address': '0xc4C75F2A0cB1a9ACc33929512dc9733EA1Fd6fde',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26365/thumb/jEYEUxUI_400x400.jpeg?1657600080',
            'coingeckoId': 'martin-shkreli-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COC',
            'name': 'Coin of the champions',
            'address': '0xc4BB7277A74678f053259CB1F96140347efbfd46',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18478/thumb/COC-Yellow-Transparent-1.png?1632148454',
            'coingeckoId': 'coin-of-the-champions',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'SVX',
            'name': 'Savix',
            'address': '0xc434b27736A6882D33094d34792999702860a13C',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14591/thumb/savix_200px.png?1617160141',
            'coingeckoId': 'savix',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SGT',
            'name': 'snglsDAO Governance',
            'address': '0xc4199fB6FFDb30A829614becA030f9042f1c3992',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11823/thumb/SGT-icon.png?1594681863',
            'coingeckoId': 'snglsdao-governance-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DHP',
            'name': 'dHealth',
            'address': '0xc40F23A3E9613E012944f7957edCe97899Fa920d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20167/thumb/dHealth_Network_Icon_200w.png?1636601369',
            'coingeckoId': 'dhealth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRND',
            'name': 'Trendering',
            'address': '0xc3dD23A0a854b4f9aE80670f528094E9Eb607CCb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xc3dd23a0a854b4f9ae80670f528094e9eb607ccb.png',
            'coingeckoId': 'trendering',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ORO',
            'name': 'ORO',
            'address': '0xc3Eb2622190c57429aac3901808994443b64B466',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13114/thumb/oro_logo.png?1605338447',
            'coingeckoId': 'oro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VETH',
            'name': 'Voucher ETH',
            'address': '0xc3D088842DcF02C13699F936BB83DFBBc6f721Ab',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xc3d088842dcf02c13699f936bb83dfbbc6f721ab.png',
            'coingeckoId': 'voucher-eth',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'KMASK',
            'name': 'Kitsune Mask',
            'address': '0xc3CaB0d0918c39E29088c495a077EcD022758798',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27866/thumb/kitsun.png?1666152169',
            'coingeckoId': 'kitsune-mask',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BGAN',
            'name': 'BGAN Vault  NFTX ',
            'address': '0xc3B5284B2c0cfa1871a6ac63B6d6ee43c08BDC79',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17051/thumb/bgan.png?1626149084',
            'coingeckoId': 'bgan-vault-nftx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IETH',
            'name': 'Instadapp ETH',
            'address': '0xc383a3833A87009fD9597F8184979AF5eDFad019',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25822/thumb/iETH_100x100.png?1654055637',
            'coingeckoId': 'instadapp-eth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EUBC',
            'name': 'EUB Chain',
            'address': '0xc37E8a31BA2d110c12f09f0239954A68b00bC599',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6547/thumb/j2Lj7uGH_400x400.jpg?1547042762',
            'coingeckoId': 'eub-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRE8',
            'name': 'Creaticles',
            'address': '0xc36b4311B21Fc0c2eAd46f1eA6ce97c9C4D98D3d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xc36b4311B21Fc0c2eAd46f1eA6ce97c9C4D98D3d/logo.png',
            'coingeckoId': 'creaticles',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'HNY',
            'name': 'Honey',
            'address': '0xc3589F56B6869824804A5EA29F2c9886Af1B0FcE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12895/thumb/hnys.png?1614100588',
            'coingeckoId': 'honey',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XUC',
            'name': 'Exchange Union',
            'address': '0xc324a2f6b05880503444451B8b27e6f9e63287Cb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1052/thumb/exchange-union.png?1548125817',
            'coingeckoId': 'exchange-union',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADP',
            'name': 'Adappter',
            'address': '0xc314b0E758D5FF74f63e307A86EbfE183C95767b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14203/thumb/logo_on.png?1614909616',
            'coingeckoId': 'adappter-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SXC',
            'name': 'SocialxClub',
            'address': '0xc2DD7a72f16Cfa5DD8f9f5B49d251b2fd2163FF6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27819/thumb/coingesxc.png?1665977868',
            'coingeckoId': 'socialxclub',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CURRY',
            'name': 'CurrySwap',
            'address': '0xc2D3AE29c8309c14994D02Ecd228cf86f3Efde77',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13656/thumb/curry_logo.jpg?1610581982',
            'coingeckoId': 'curryswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MASK20',
            'name': 'Hashmasks',
            'address': '0xc2BdE1A2fA26890c8E6AcB10C91CC6D9c11F4a73',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17856/thumb/unnamed_%284%29.jpg?1629681381',
            'coingeckoId': 'hashmasks',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TAU',
            'name': 'Lamden Tau',
            'address': '0xc27A2F05fa577a83BA0fDb4c38443c0718356501',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xc27a2f05fa577a83ba0fdb4c38443c0718356501.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'IDLETUSDYIELD',
            'name': 'IdleTUSD  Best Yield ',
            'address': '0xc278041fDD8249FE4c1Aad1193876857EEa3D68c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11935/thumb/idletusdv3mMaxyield_32.png?1596263719',
            'coingeckoId': 'idle-tusd-yield',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FPIS',
            'name': 'Frax Price Index Share',
            'address': '0xc2544A32872A91F4A553b404C6950e89De901fdb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24944/thumb/fpis.png?1649485832',
            'coingeckoId': 'frax-price-index-share',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RAC',
            'name': 'RAC',
            'address': '0xc22B30E4cce6b78aaaADae91E44E73593929a3e9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12675/thumb/rac_logo_%281%29.jpg?1601526417',
            'coingeckoId': 'rac',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WCFG',
            'name': 'Wrapped Centrifuge',
            'address': '0xc221b7E65FfC80DE234bbB6667aBDd46593D34F0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xc221b7e65ffc80de234bbb6667abdd46593d34f0.png',
            'coingeckoId': 'wrapped-centrifuge',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'DTRC',
            'name': 'Datarius Credit',
            'address': '0xc20464e0C373486d2B3335576e83a218b1618A5E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xc20464e0c373486d2b3335576e83a218b1618a5e.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'PIN',
            'name': 'Public Index Network',
            'address': '0xc1f976B91217E240885536aF8b63bc8b5269a9BE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/241/thumb/pin.jpeg?1623054253',
            'coingeckoId': 'public-index-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MSRT',
            'name': 'Moonseen Rose Token',
            'address': '0xc1F1699fe47Bd5512e6dd8353DB43b8186B67FD4',
            'decimals': 8,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PAX',
            'name': 'PayperEx',
            'address': '0xc1D204d77861dEf49b6E769347a883B15EC397Ff',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1601/thumb/pax.png?1547035800',
            'coingeckoId': null,
            'listedIn': [
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'POL',
            'name': 'Polars',
            'address': '0xc17fbe1d709dDf6C0B6665Dd0591046815AC7554',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24220/thumb/alpha_logo_polars.png?1646919342',
            'coingeckoId': 'polars',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'TRXBULL',
            'name': '3X Long TRX Token',
            'address': '0xc175E77b04F2341517334Ea3Ed0b198A01A97383',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10266/thumb/683JEXMN_400x400_%281%29.png?1576834617',
            'coingeckoId': '3x-long-trx-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHINY',
            'name': 'Shiny',
            'address': '0xc150bAc3Cd3678aCB2C093E433BED40a6eF08542',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20832/thumb/shiny-token-200px.png?1637734428',
            'coingeckoId': 'shiny',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PXT',
            'name': 'Populous XBRL',
            'address': '0xc14830E53aA344E8c14603A91229A0b925b0B262',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6207/thumb/populous.jpg?1564992440',
            'coingeckoId': 'populous-xbrl-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CREDS',
            'name': 'Creds',
            'address': '0xc13F4F0F865bAc08F62654B57E38669EbC4747a3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22122/thumb/creds.png?1640848447',
            'coingeckoId': 'creds',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NAAL',
            'name': 'Ethernaal',
            'address': '0xc133529e57681b2999708F9458Be5634e293995E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19909/thumb/miso-ethernaal-logo-256.png?1636104593',
            'coingeckoId': 'ethernaal',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRY',
            'name': 'Try Finance',
            'address': '0xc12eCeE46ed65D970EE5C899FCC7AE133AfF9b03',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/try.jpg',
            'coingeckoId': 'try-finance',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'AUC',
            'name': 'Auctus',
            'address': '0xc12d099be31567add4e4e4d0D45691C3F58f5663',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xc12d099be31567add4e4e4d0d45691c3f58f5663.png',
            'coingeckoId': 'auctus',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'LIBFX',
            'name': 'Libfx',
            'address': '0xc0ea83113038987d974FE667831a36E442e661E7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12225/thumb/libfx.png?1598306252',
            'coingeckoId': 'libfx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UMEE',
            'name': 'Umee',
            'address': '0xc0a4Df35568F116C370E6a6A6022Ceb908eedDaC',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20635/thumb/1Ab_Umee_Brand_Icon_Full_Color.png?1645018295',
            'coingeckoId': 'umee',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWAT',
            'name': 'SWTCoin',
            'address': '0xc0F1728d9513EFC316D0E93A0758c992f88b0809',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5985/thumb/swtcoin.jpg?1547041927',
            'coingeckoId': 'swtcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EGC',
            'name': 'EcoG9coin',
            'address': '0xc0EC8CaEC55F37D47fBfA595727418868A21fd48',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8038/thumb/Screenshot_2019-12-05_at_7.14.34_AM.png?1575501284',
            'coingeckoId': 'ecog9coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELT',
            'name': 'Element Black',
            'address': '0xc0AE17Eb994fa828540FFa53776B3830233A1B02',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25160/thumb/4TCtUKHs_400x400.jpg?1650464346',
            'coingeckoId': 'element-black',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFMT',
            'name': 'Non Fungible Media Token',
            'address': '0xc08515d77C8E20D5957DF9ad492E426020be64fE',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26880/thumb/6mZw77As_400x400.jpeg?1660636409',
            'coingeckoId': 'non-fungible-media-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BIOT',
            'name': 'Bio Passport',
            'address': '0xc07A150ECAdF2cc352f5586396e344A6b17625EB',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14167/thumb/logo_%2895%29.png?1614753428',
            'coingeckoId': 'biopassport',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AGAIN',
            'name': 'Again Project',
            'address': '0xc01A327e30B0fBf32861333F238b5C36a60ABC09',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21384/thumb/-Jjz5ZxF_400x400.jpg?1639034594',
            'coingeckoId': 'again-project',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KEI',
            'name': 'Keisuke Inu',
            'address': '0xc0114F14638a333A4D5C3b04F09b96372348A842',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15797/thumb/realicon.png?1628170332',
            'coingeckoId': 'keisuke-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COMP',
            'name': 'Compound',
            'address': '0xc00e94Cb662C3520282E6f5717214004A7f26888',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xc00e94cb662c3520282e6f5717214004a7f26888.png',
            'coingeckoId': 'compound-governance-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MINIMONGOOSE',
            'name': 'mini Mongoose',
            'address': '0xbfc5c333B096C6A171bAA43Bb2Dfb321e05967Da',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21443/thumb/6fpRJII.jpeg?1639144809',
            'coingeckoId': 'mini-mongoose',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AAPX',
            'name': 'AMPnet',
            'address': '0xbfD815347d024F449886c171f78Fa5B8E6790811',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14303/thumb/AAPX.png?1615356946',
            'coingeckoId': 'ampnet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NARUTO',
            'name': 'Naruto Inu',
            'address': '0xbfCe0E06deDCBEA3E170BA4df2A6793334cAC5ef',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20509/thumb/6HHkduP.jpeg?1637153210',
            'coingeckoId': 'naruto-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VNL',
            'name': 'Vanilla',
            'address': '0xbf900809f4C73e5a3476eb183d8b06a27e61F8E5',
            'decimals': 12,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14679/thumb/vnl_logo.jpg?1635746910',
            'coingeckoId': 'vanilla',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AT',
            'name': 'ABCC',
            'address': '0xbf8fB919A8bbF28e590852AeF2D284494eBC0657',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5132/thumb/abcc-token.png?1547040523',
            'coingeckoId': 'abcc-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ODE',
            'name': 'ODEM',
            'address': '0xbf52F2ab39e26E0951d2a02b49B7702aBe30406a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3752/thumb/odem.png?1548329893',
            'coingeckoId': 'odem',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWIV',
            'name': 'Swivel Governance',
            'address': '0xbf30461210b37012783957D90dC26B95Ce3b6f2d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24496/thumb/tKRKjCx.png?1647868486',
            'coingeckoId': 'swivel-governance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELF',
            'name': 'aelf',
            'address': '0xbf2179859fc6D5BEE9Bf9158632Dc51678a4100e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xbf2179859fc6d5bee9bf9158632dc51678a4100e.png',
            'coingeckoId': 'aelf',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ZUSD',
            'name': 'Zytara Dollar',
            'address': '0xbf0f3cCB8fA385A287106FbA22e6BB722F94d686',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13957/thumb/zusd.png?1613353410',
            'coingeckoId': 'zytara-dollar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPLC',
            'name': 'SupplyCon',
            'address': '0xbe7A4f88c6B15a722aa960B487BAdF9880184Ec5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28192/thumb/SupplyCon_Logo_.png?1668327647',
            'coingeckoId': 'supplycon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GHT',
            'name': 'Global Human Trust',
            'address': '0xbe30F684d62C9F7883a75A29c162c332c0d98f23',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12040/thumb/WechatIMG6648.png?1596700737',
            'coingeckoId': 'global-human-trust',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAV',
            'name': 'Massive Protocol',
            'address': '0xbe1dBe6741fB988fb571AB1E28CfFB36e3C62629',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25134/thumb/logo.png?1650431353',
            'coingeckoId': 'massive-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EMRX',
            'name': 'Emirex',
            'address': '0xbdbC2a5B32F3a5141ACd18C39883066E4daB9774',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9507/thumb/download.png?1568589320',
            'coingeckoId': 'emirex-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOV',
            'name': 'Sovryn',
            'address': '0xbdab72602e9AD40FC6a6852CAf43258113B8F7a5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16248/thumb/sov.PNG?1623380748',
            'coingeckoId': 'sovryn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SVD',
            'name': 'Savedroid',
            'address': '0xbdEB4b83251Fb146687fa19D1C660F99411eefe3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xbdeb4b83251fb146687fa19d1c660f99411eefe3.png',
            'coingeckoId': 'savedroid',
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'LUNA',
            'name': 'LUNA (Wormhole)',
            'address': '0xbd31EA8212119f94A611FA969881CBa3EA06Fa3d',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://api.rubic.exchange/assets/ethereum/0xbd31ea8212119f94a611fa969881cba3ea06fa3d/logo.png',
            'coingeckoId': 'luna-wormhole',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MOMA',
            'name': 'Mochi Market',
            'address': '0xbd1848e1491d4308Ad18287A745DD4DB2A4BD55B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14993/thumb/mochi.PNG?1619390399',
            'coingeckoId': 'mochi-market',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TKNT',
            'name': 'TKN',
            'address': '0xbcE7BD79558dda90B261506768f265c5543A9f90',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12808/thumb/1_jLY3Tj0kuBTjZGHFCqkTGA.png?1602713481',
            'coingeckoId': 'tkn-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PAZZI',
            'name': 'Paparazzi',
            'address': '0xbcD8756Ea481608Ea3DD5a555493305Cf0A79640',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11558/thumb/jMJC4g9m_400x400.jpg?1591079702',
            'coingeckoId': 'paparazzi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPDR',
            'name': 'SpiderDAO',
            'address': '0xbcD4b7dE6fde81025f74426D43165a5b0D790Fdd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13378/thumb/spiderdao_logo.png?1608029180',
            'coingeckoId': 'spiderdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SYNR',
            'name': 'MOBLAND',
            'address': '0xbc6E06778708177a18210181b073DA747C88490a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22868/thumb/FullML_Yellow_200px.png?1648031600',
            'coingeckoId': 'syndicate-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XTZBEAR',
            'name': '3X Short Tezos Token',
            'address': '0xbc41d05287498DEc58129560De6bd1b8d4E3aC1d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10175/thumb/683JEXMN_400x400_%281%29.png?1576506808',
            'coingeckoId': '3x-short-tezos-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DORA',
            'name': 'Dora Factory',
            'address': '0xbc4171f45EF0EF66E76F979dF021a34B46DCc81d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xbc4171f45ef0ef66e76f979df021a34b46dcc81d.png',
            'coingeckoId': 'dora-factory',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'GMX',
            'name': 'GMX',
            'address': '0xbc30049ADC73dE06D7a98A5189203aAC66B2c830',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'LEV',
            'name': 'Lever Network',
            'address': '0xbc194e6f748a222754C3E8b9946922c09E7d4e91',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1634699312660_6606014097399895.png',
            'coingeckoId': 'lever-network',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'CNDL',
            'name': 'Candle',
            'address': '0xbc138bD20C98186CC0342C8e380953aF0cb48BA8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xbc138bD20C98186CC0342C8e380953aF0cb48BA8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'SS',
            'name': 'Sharder protocol',
            'address': '0xbbFF862d906E348E9946Bfb2132ecB157Da3D4b4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2921/thumb/sharder-200px.png?1595305234',
            'coingeckoId': 'sharder-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BPRO',
            'name': 'B Protocol',
            'address': '0xbbBBBBB5AA847A2003fbC6b5C16DF0Bd1E725f61',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15110/thumb/66428641.jpg?1619749844',
            'coingeckoId': 'b-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANTE',
            'name': 'AnteDAO',
            'address': '0xbb70AdbE39408cB1E5258702ea8ADa7c81165b73',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25855/thumb/ante_symbol.png?1654151638',
            'coingeckoId': 'antedao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DINU',
            'name': 'Dogey Inu',
            'address': '0xbb1EE07d6c7BAeB702949904080eb61f5D5e7732',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xbb1ee07d6c7baeb702949904080eb61f5d5e7732.png',
            'coingeckoId': 'dogey-inu',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'UCAP',
            'name': 'Unicap Finance',
            'address': '0xbaA70614C7AAfB568a93E62a98D55696bcc85DFE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13300/thumb/unicap256.png?1607308439',
            'coingeckoId': 'unicap-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHSB',
            'name': 'SwissBorg',
            'address': '0xba9d4199faB4f26eFE3551D490E3821486f135Ba',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2117/thumb/YJUrRy7r_400x400.png?1589794215',
            'coingeckoId': 'swissborg',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MONSTR',
            'name': 'MonstaVerse',
            'address': '0xba75fBC4C7a553081F7A137b6e652520Db444660',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21887/thumb/yn3q0se.png?1640226777',
            'coingeckoId': 'monstaverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RARE',
            'name': 'SuperRare',
            'address': '0xba5BDe662c17e2aDFF1075610382B9B691296350',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xba5BDe662c17e2aDFF1075610382B9B691296350/logo.png',
            'coingeckoId': 'superrare',
            'listedIn': [
                'coingecko',
                'uniswap',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'aAAVEv1',
            'name': 'Aave Interest bearing Aave Token',
            'address': '0xba3D9687Cf50fE253cd2e1cFeEdE1d6787344Ed5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xba3d9687cf50fe253cd2e1cfeede1d6787344ed5.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'HUB',
            'name': 'Hub',
            'address': '0xba358B6f5b4c0215650444B8C30D870B55050D2D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13345/thumb/Hub-Logo-Transparent-BG-200x200_%281%29.png?1607661813',
            'coingeckoId': 'hub-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAL',
            'name': 'Balancer',
            'address': '0xba100000625a3754423978a60c9317c58a424e3D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xba100000625a3754423978a60c9317c58a424e3d.png',
            'coingeckoId': 'balancer',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'optimism',
                'arbitrum_bridge',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'OCTSMM',
            'name': 'Octus Social Media Market',
            'address': '0xbFc43a35b3aE8F7463c5Ac88a0C46107cFCe6f67',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27095/thumb/photo_2022-08-21_06.15.02.jpeg?1661998030',
            'coingeckoId': 'octus-social-media-market',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SMTY',
            'name': 'Smoothy',
            'address': '0xbF776e4FCa664D791C4Ee3A71e2722990E003283',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15039/thumb/dDxKgwPN_400x400.jpg?1619507030',
            'coingeckoId': 'smoothy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OT-PE-29DEC2022',
            'name': 'OT PENDLE ETH',
            'address': '0xbF682bd31a615123D28d611b38b0aE3d2b675C2C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18136/thumb/ot-pendle_eth_slp.573d443c.png?1630640424',
            'coingeckoId': 'ot-pendle-eth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NYAN-2',
            'name': 'Nyan V2',
            'address': '0xbF4a9A37EcFc21825011285222c36aB35De51F14',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13093/thumb/v2-logo.png?1605067493',
            'coingeckoId': 'nyan-v2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GYSR',
            'name': 'Geyser',
            'address': '0xbEa98c05eEAe2f3bC8c3565Db7551Eb738c8CCAb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xbea98c05eeae2f3bc8c3565db7551eb738c8ccab_1.png',
            'coingeckoId': 'geyser',
            'listedIn': [
                'coingecko',
                '1inch',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'MORE',
            'name': 'BuyMORE',
            'address': '0xbEEf3bB9dA340EbdF0f5bae2E85368140d7D85D0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26769/thumb/0BD4687E-6C18-43D2-87C7-C5BC144B49D0_200x200.jpeg?1660040979',
            'coingeckoId': 'buymore',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TOMS',
            'name': 'TomTomCoin',
            'address': '0xbE9Ab37a414c517b2be2bfA5945665bB07379054',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22864/thumb/toms_logo_200.png?1642753016',
            'coingeckoId': 'tomtomcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STMX',
            'name': 'Kyber StormX Token',
            'address': '0xbE9375C6a420D2eEB258962efB95551A5b722803',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xbe9375c6a420d2eeb258962efb95551a5b722803.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'JCG',
            'name': 'JustCarbon Governance',
            'address': '0xbE601dD49da9EE1d2F64D422c4AECf8EB83c119f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25701/thumb/Just-Carbon-JCG.png?1653451947',
            'coingeckoId': 'justcarbon-governance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NTB',
            'name': 'TokenAsset',
            'address': '0xbE393AA534F82c0ffAC31BF06A23e283AcB3352B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13710/thumb/8284.png?1611097251',
            'coingeckoId': 'tokenasset',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SUNDER',
            'name': 'Sunder Goverance',
            'address': '0xbDbf245c690d54b67C6e610A28486A2C6dE08bE6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xbDbf245c690d54b67C6e610A28486A2C6dE08bE6/logo.png',
            'coingeckoId': 'sunder-goverance-token',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'MOGX',
            'name': 'Mogu',
            'address': '0xbDEC45952B5E234EdDC2981B43eeD360826D5087',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9687/thumb/UZpzCJc1_400x400.jpg?1570828056',
            'coingeckoId': 'mogu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RICE',
            'name': 'DAOSquare',
            'address': '0xbD9908b0Cdd50386F92efCC8e1d71766C2782Df0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19084/thumb/tw5gu1dW_400x400.jpeg?1634389807',
            'coingeckoId': 'daosquare',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GRANX',
            'name': 'GranX Chain',
            'address': '0xbD668EC47e10D3bf23b1d68139E545162a0dfCFA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18658/thumb/granx-002.png?1632812430',
            'coingeckoId': 'cranx-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FNTB',
            'name': 'FinTab',
            'address': '0xbD4B60a138b3fce3584EA01f50c0908c18f9677A',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3773/thumb/300x300_%281%29.png?1547038862',
            'coingeckoId': 'fintab',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HINA',
            'name': 'Hina Inu',
            'address': '0xbD0a4bf098261673d5E6e600Fd87ddcd756e6764',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15993/thumb/download.png?1622549919',
            'coingeckoId': 'hina-inu',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'DML',
            'name': 'Decentralized Machine Learning Protocol',
            'address': '0xbCdfE338D55c061C084D81fD793Ded00A27F226D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3297/thumb/decentralized-machine-learning.png?1547037880',
            'coingeckoId': 'decentralized-machine-learning',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MCAP',
            'name': 'Meta Capital',
            'address': '0xbCE0665b20164d6cd6D15E70feD1E094AD4A44f0',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22320/thumb/EMWN_Ybl_400x400.jpg?1641454816',
            'coingeckoId': 'meta-capital',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IONC',
            'name': 'IONChain',
            'address': '0xbC647aAd10114B89564c0a7aabE542bd0cf2C5aF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6572/thumb/3PD7ycbU_400x400.jpg?1547042781',
            'coingeckoId': 'ionchain-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PERP',
            'name': 'Perpetual Protocol',
            'address': '0xbC396689893D065F41bc2C6EcbeE5e0085233447',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xbc396689893d065f41bc2c6ecbee5e0085233447.png',
            'coingeckoId': 'perpetual-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'optimism',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'NEZUKO',
            'name': 'Nezuko Inu',
            'address': '0xbC298DfAA2EDda095b924F1390cC38fB7c5f6250',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18652/thumb/O7TBGRA.png?1632759790',
            'coingeckoId': 'nezuko-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LEPA',
            'name': 'Lepasa',
            'address': '0xbBa6C7c7d673c48d90069ad2e9d2fE587fcB6bc3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22745/thumb/lepasa.PNG?1642550975',
            'coingeckoId': 'lepasa',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WBX',
            'name': 'WiBX',
            'address': '0xbB97e381F1d1e94ffa2A5844F6875e6146981009',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11009/thumb/wibx_new_logo.png?1632122685',
            'coingeckoId': 'wibx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOLTT',
            'name': 'BolttCoin',
            'address': '0xbB340A2eaF55C5e67a5A05FE5cEed9B9702d76f4',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3928/thumb/Ticker.jpeg?1551231549',
            'coingeckoId': 'boltt-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FANC',
            'name': 'fanC',
            'address': '0xbB126042235E6bD38B17744cb31a8bF4A206c045',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26500/thumb/hJ6MD49P_400x400.jpeg?1658356676',
            'coingeckoId': 'fanc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOTT',
            'name': 'Beauty Bakery Linked Operation Transact',
            'address': '0xbA93EF534094F8b7001ECe2691168140965341ab',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27688/thumb/LOTT.jpg?1665217304',
            'coingeckoId': 'beauty-bakery-linked-operation-transaction-technology',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QUARTZ',
            'name': 'Sandclock',
            'address': '0xbA8A621b4a54e61C442F5Ec623687e2a942225ef',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xbA8A621b4a54e61C442F5Ec623687e2a942225ef/logo.png',
            'coingeckoId': 'sandclock',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'BGLD',
            'name': 'Based Gold',
            'address': '0xbA7970f10D9f0531941DcEd1dda7ef3016B24e5b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xbA7970f10D9f0531941DcEd1dda7ef3016B24e5b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'MFTU',
            'name': 'Mainstream For The Underground',
            'address': '0xbA745513ACEbcBb977497C569D4F7d340f2A936B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5519/thumb/Mainstream_for_the_underground.png?1534426154',
            'coingeckoId': 'mainstream-for-the-underground',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HSF',
            'name': 'Hillstone Finance',
            'address': '0xbA6B0dbb2bA8dAA8F5D6817946393Aef8D3A4487',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22335/thumb/logo_-_2022-01-07T094430.368.png?1641519883',
            'coingeckoId': 'hillstone',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MBASE',
            'name': 'Minebase',
            'address': '0xbA3E5F8b4200a5eb856FF2C3E001aB29444491AA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27313/thumb/mbase.png?1663312352',
            'coingeckoId': 'minebase',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UBIN',
            'name': 'Ubiner',
            'address': '0xb9EcEb9F717852Ad0D936B46155cB0c0f43cBE8E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12190/thumb/ubin.PNG?1597965582',
            'coingeckoId': 'ubiner',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZCN',
            'name': '0chain',
            'address': '0xb9EF770B6A5e12E45983C5D80545258aA38F3B78',
            'decimals': 10,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xb9ef770b6a5e12e45983c5d80545258aa38f3b78.png',
            'coingeckoId': '0chain',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'PIRATE',
            'name': 'PirateCash',
            'address': '0xb990d93C308A31c737Aa91839E8bA8eAF4017D7A',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7155/thumb/logo_%281%29.png?1637683371',
            'coingeckoId': 'piratecash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EUX',
            'name': 'dForce EUR',
            'address': '0xb986F3a2d91d3704Dc974A24FB735dCc5E3C1E70',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://etherscan.io/token/images/dforceeur_32.png',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'aUniUSDT',
            'name': 'Aave Interest bearing UniUSDT',
            'address': '0xb977ee318010A5252774171494a1bCB98E7fab65',
            'decimals': 6,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'VLXPAD',
            'name': 'VelasPad',
            'address': '0xb8e3bB633F7276cc17735D86154E0ad5ec9928C0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18535/thumb/11654.png?1632297834',
            'coingeckoId': 'velaspad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SBTC',
            'name': 'SiamBitcoin',
            'address': '0xb8e103b60A33597136EA9511F46b6dBeB643a3a5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7089/thumb/image-62DF_5C05991C.jpg?1547043573',
            'coingeckoId': 'siambitcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AAMMUNILINKWETH',
            'name': 'Aave AMM UniLINKWETH',
            'address': '0xb8db81B84d30E2387de0FF330420A4AAA6688134',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17221/thumb/aAmmUniLINKWETH.png?1626879669',
            'coingeckoId': 'aave-amm-unilinkweth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RPILL',
            'name': 'RedPill',
            'address': '0xb8d313068891137952E7dfF19d8AFaa64DBc6FcB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27879/thumb/3177f501-401e-418d-a4bc-f72fdf9fe5d7.png?1666230108',
            'coingeckoId': 'redpill-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOM',
            'name': 'Gomics',
            'address': '0xb8c6ad2586bB71d518C2aaf510Efe91f82022F58',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9506/thumb/69581895_377122223186787_4719544883591774208_n.png?1568184472',
            'coingeckoId': 'gomics',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AWS',
            'name': 'AurusSILVER',
            'address': '0xb89903ddE3899f0280B99913168Ee833a7896B93',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14965/thumb/2021-12-06-Aurus-tokens-for-coingecko-AWS-flat-color-v1-r1-AS.png?1640223368',
            'coingeckoId': 'aurus-silver',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GSHIBA',
            'name': 'Gambler Shiba',
            'address': '0xb892249939AdBf6D7851864CA9A5c7D2d537af97',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17130/thumb/RHzOHCyb_400x400.jpg?1626410775',
            'coingeckoId': 'gambler-shiba',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HOME',
            'name': 'Bacon Protocol Home',
            'address': '0xb8919522331C59f5C16bDfAA6A121a6E03A91F62',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26185/thumb/homecoin_200.png?1656384254',
            'coingeckoId': 'bacon-protocol-home',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-11078-WAYBU',
            'name': 'RealT   11078 Wayburn St  Detroit  MI 4',
            'address': '0xb8403b7730368942a5BFe5aaC04a31B44015b1cc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16596/thumb/11078-Wayburn-hero-1.jpg?1624541135',
            'coingeckoId': '11078-wayburn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CC',
            'name': 'CCSwap',
            'address': '0xb7fbFf4Ce5200215c690cc95855e5d6C5236Ee9f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15037/thumb/NEW-Logo-1.png?1622196132',
            'coingeckoId': 'ccswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WTC',
            'name': 'Waltonchain',
            'address': '0xb7cB1C96dB6B22b0D3d9536E0108d062BD488F74',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1093/thumb/ggx6nnW.png?1604815811',
            'coingeckoId': 'waltonchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VST',
            'name': 'VentiSwap',
            'address': '0xb7C2fcD6d7922eddd2A7A9B0524074A60D5b472C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25591/thumb/output-onlinepngtools-2_2.png?1652862384',
            'coingeckoId': 'ventiswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DRC',
            'name': 'Dracula',
            'address': '0xb78B3320493a4EFaa1028130C5Ba26f0B6085Ef8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xb78b3320493a4efaa1028130c5ba26f0b6085ef8.png',
            'coingeckoId': 'dracula',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SFI',
            'name': 'saffron finance',
            'address': '0xb753428af26E81097e7fD17f40c88aaA3E04902c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xb753428af26e81097e7fd17f40c88aaa3e04902c.png',
            'coingeckoId': 'saffron-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'QM',
            'name': 'Quick Mining',
            'address': '0xb73404280697080dA0116cFAc0e577fAFdf44b37',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15916/thumb/qm.PNG?1622427094',
            'coingeckoId': 'quick-mining',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REL',
            'name': 'Relevant',
            'address': '0xb6c4267C4877BB0D6b1685Cfd85b0FBe82F105ec',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xb6c4267c4877bb0d6b1685cfd85b0fbe82f105ec.png',
            'coingeckoId': 'relevant',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'IIC',
            'name': 'Intelligent Investment Chain',
            'address': '0xb6F43025B29196Af2dddd69b0a58AFBa079cD600',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4307/thumb/intelligent-investment-chain.png?1547974303',
            'coingeckoId': 'intelligent-investment-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CXO',
            'name': 'CargoX',
            'address': '0xb6EE9668771a79be7967ee29a63D4184F8097143',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2580/thumb/cargox.png?1547738832',
            'coingeckoId': 'cargox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FCP',
            'name': 'Filipcoin',
            'address': '0xb6Dd77FD132dcAa10F1858734e838a0FA7431580',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21354/thumb/filip.PNG?1639000706',
            'coingeckoId': 'filipcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTU',
            'name': 'BTU Protocol',
            'address': '0xb683D83a532e2Cb7DFa5275eED3698436371cc9f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/3737.png',
            'coingeckoId': 'btu-protocol',
            'listedIn': [
                'coingecko',
                'openocean',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'SPORK',
            'name': 'SporkDAO',
            'address': '0xb624FdE1a972B1C89eC1dAD691442d5E8E891469',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23358/thumb/sporkdao.PNG?1643942687',
            'coingeckoId': 'sporkdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HEROS',
            'name': 'Heros',
            'address': '0xb622400807765e73107B7196F444866D7EdF6f62',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20381/thumb/200x200.png?1658719987',
            'coingeckoId': 'hero-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STONK',
            'name': 'STONK',
            'address': '0xb60Fde5D798236fBF1e2697B2A0645380921FccF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xb60fde5d798236fbf1e2697b2a0645380921fccf.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'PS1',
            'name': 'POLYSPORTS',
            'address': '0xb5be7557fe8f69a2B5707D25fA0aeE80DfDA512E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25070/thumb/L-T2x_cG_400x400.jpg?1650024620',
            'coingeckoId': 'polysports',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-12866-LAUDE',
            'name': 'RealT   12866 Lauder St  Detroit  MI 48',
            'address': '0xb5DD2b6E0A0422e069E1D2CC3Ed16533488a05e3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16565/thumb/12866-Lauder-hero-1.jpg?1624460677',
            'coingeckoId': '12866-lauder',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BCASH',
            'name': 'BankCoin BCash',
            'address': '0xb5BB48567BfD0bFE9e4B08EF8b7f91556CC2a112',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7692/thumb/bcash.png?1549865564',
            'coingeckoId': 'bankcoincash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PERL',
            'name': 'Perlin',
            'address': '0xb5A73f5Fc8BbdbcE59bfD01CA8d35062e0dad801',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xb5a73f5fc8bbdbce59bfd01ca8d35062e0dad801.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'LIT',
            'name': 'Litentry',
            'address': '0xb59490aB09A0f526Cc7305822aC65f2Ab12f9723',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13825/thumb/logo_200x200.png?1612153317',
            'coingeckoId': 'litentry',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIS2',
            'name': 'MIS2',
            'address': '0xb58DFBB72e648a0b035B8C85B3628123CC9bb881',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xb58DFBB72e648a0b035B8C85B3628123CC9bb881/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'WAIT',
            'name': 'WAIT',
            'address': '0xb5588C411ba0bb7D38865fdC51D082d004e519F7',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26860/thumb/wait_etherscan_logo.png?1660529678',
            'coingeckoId': 'wait',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIT',
            'name': 'Meta Interstellar Token',
            'address': '0xb546c4a4FbFb0Ae97b589e6887d198d11e9f375e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'O',
            'name': 'Childhoods End',
            'address': '0xb53ecF1345caBeE6eA1a65100Ebb153cEbcac40f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25670/thumb/photo_2022-05-27_12-45-29.jpg?1653626809',
            'coingeckoId': 'childhoods-end',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YETI',
            'name': 'Yearn Ecosystem Index',
            'address': '0xb4bebD34f6DaaFd808f73De0d10235a92Fbb6c3D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xb4bebD34f6DaaFd808f73De0d10235a92Fbb6c3D/logo.png',
            'coingeckoId': 'yearn-ecosystem-token-index',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'DOGES',
            'name': 'Dogeswap',
            'address': '0xb4FBed161bEbcb37afB1Cb4a6F7cA18b977cCB25',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12763/thumb/20200926-220107.png?1622520018',
            'coingeckoId': 'dogeswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LTG',
            'name': 'LiteGold',
            'address': '0xb4C9abc8a74Bd2E0E0b7AC5ecE30792e65D86c59',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6792/thumb/O4v8ll_f_400x400.jpg?1547043077',
            'coingeckoId': 'litegold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CXN',
            'name': 'CXN Network',
            'address': '0xb48E0F69e6A3064f5498D495F77AD83e0874ab28',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12622/thumb/Webp.net-resizeimage.png?1601282522',
            'coingeckoId': 'cxn-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TAT2',
            'name': 'TattooMoney',
            'address': '0xb487d0328b109e302b9d817b6f46Cbd738eA08C2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24772/thumb/logo_200x200.png?1649835408',
            'coingeckoId': 'tattoomoney',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TYP',
            'name': 'The Youth Pay',
            'address': '0xb46eda6219ba121cE9280388e7afb7dc84be3fF2',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27412/thumb/8xJB5QnY_400x400.jpeg?1663855027',
            'coingeckoId': 'the-youth-pay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AGAR',
            'name': 'AGA Rewards',
            'address': '0xb453f1f2EE776dAF2586501361c457dB70e1ca0F',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14207/thumb/AR256.png?1614931200',
            'coingeckoId': 'aga-rewards-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DREAM',
            'name': 'Dream',
            'address': '0xb44377B74Ef1773639B663d0754cB8410A847d02',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24139/thumb/image0.jpeg?1659000537',
            'coingeckoId': 'dream-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BULL',
            'name': 'BullionFX',
            'address': '0xb439B8731ee047799019eF0b745a51d256B116Af',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19251/thumb/237813872_101246862292596_7969491648103245595_n.png?1634797631',
            'coingeckoId': 'bullionfx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHS',
            'name': 'Chainsquare',
            'address': '0xb41380174d0B06181513A5677b60200b93b5Efb4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14858/thumb/chainsquare.png?1618804636',
            'coingeckoId': 'chainsquare',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STONK',
            'name': 'Stonk',
            'address': '0xb4058411967D5046f3510943103805be61f0600E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14805/thumb/f9YBOaOF_400x400.jpg?1618534520',
            'coingeckoId': 'stonk-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HIFIDENZA',
            'name': 'hiFIDENZA',
            'address': '0xb3e6Ee8d2c586FA03Ab70Aef96B8aE6D12D64eC7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28237/thumb/hifidenza.png?1668579144',
            'coingeckoId': 'hifidenza',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AGF',
            'name': 'Augmented Finance',
            'address': '0xb3ED706B564BBa9CAB64042f4e1b391bE7BEbCe5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20077/thumb/200x200-agf.png?1636452467',
            'coingeckoId': 'augmented-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOGGER',
            'name': 'Dogger',
            'address': '0xb3Cc3d7E656893F22d2372b0aE57106F6b155Cbe',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25460/thumb/GC94Zwub_400x400.jpg?1651822625',
            'coingeckoId': 'dogger',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MEV',
            'name': 'MEVerse',
            'address': '0xb3Cb8d5AEFF0F4d1f432f353309f47b885E404E3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24566/thumb/0sp1DWVw_400x400.png?1648191682',
            'coingeckoId': 'meverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WPX',
            'name': 'Wallet Plus X',
            'address': '0xb3BACe433288645114FE8e8aA91F87659CBF665b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10302/thumb/WPX_LOGO_200X200.png?1577693149',
            'coingeckoId': 'wallet-plus-x',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CTR',
            'name': 'Concentrator',
            'address': '0xb3Ad645dB386D7F6D753B2b9C3F4B853DA6890B8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26364/thumb/%E6%9C%AA%E5%91%BD%E5%90%8D%E7%9A%84%E8%AE%BE%E8%AE%A1.jpg?1657596066',
            'coingeckoId': 'concentrator',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HFT',
            'name': 'Hashflow',
            'address': '0xb3999F658C0391d94A37f7FF328F3feC942BcADC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xb3999f658c0391d94a37f7ff328f3fec942bcadc.png',
            'coingeckoId': 'hashflow',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SEURO',
            'name': 'Standard Euro',
            'address': '0xb399511642FE1666c6a07f83483e6E4feAed9A00',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28201/thumb/Picture2.png?1668338664',
            'coingeckoId': 'standard-euro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EOSHEDGE',
            'name': '1X Short EOS Token',
            'address': '0xb38f206615325306DddEB0794A6482486B6B78b8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10221/thumb/683JEXMN_400x400.png?1596706630',
            'coingeckoId': '1x-short-eos-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OSWAP',
            'name': 'OpenSwap',
            'address': '0xb32aC3C79A94aC1eb258f3C830bBDbc676483c93',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15785/thumb/openswap.PNG?1621847093',
            'coingeckoId': 'openswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADABEAR',
            'name': '3X Short Cardano Token',
            'address': '0xb3299d4BaB93Bf04d5b11bc49CD6DFAD1f77d23f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10232/thumb/683JEXMN_400x400_%281%29.png?1576618766',
            'coingeckoId': '3x-short-cardano-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DAMM',
            'name': 'dAMM',
            'address': '0xb3207935FF56120f3499e8aD08461Dd403bF16b8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28082/thumb/dAMM_logo_round_-_dark.png?1667443628',
            'coingeckoId': 'damm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOSE',
            'name': 'DOSE',
            'address': '0xb31eF9e52d94D4120eb44Fe1ddfDe5B4654A6515',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18847/thumb/dose.PNG?1633590548',
            'coingeckoId': 'dose-token',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'SMCW',
            'name': 'Space Crown',
            'address': '0xb2ea51BAa12C461327d12A2069d47b30e680b69D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ACRE',
            'name': 'Arable Protocol',
            'address': '0xb2cABf797bc907B049e4cCB5b84d13be3a8CFC21',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SHIBARAMEN',
            'name': 'Shiba Ramen',
            'address': '0xb2E20502c7593674509b8384ED9240a03869Faf3',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16134/thumb/60bbdb756d4e3aea1bfa5478_image0_copy.jpeg?1623055808',
            'coingeckoId': 'shiba-ramen',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LDN',
            'name': 'Ludena Protocol',
            'address': '0xb29663Aa4E2e81e425294193616c1B102B70a158',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/7992.png',
            'coingeckoId': 'ludena-protocol',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'xyfinance'
            ]
        },
        {
            'symbol': 'LFI',
            'name': 'Lunafi',
            'address': '0xb28f803A8772E6584A65Ab6dfC535aE6fEf8a0b2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24594/thumb/lfi.png?1648310927',
            'coingeckoId': 'lunafi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALD',
            'name': 'Aladdin DAO',
            'address': '0xb26C4B3Ca601136Daf98593feAeff9E0CA702a8D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18277/thumb/78200839.png?1631234134',
            'coingeckoId': 'aladdin-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAK',
            'name': 'Sakura Planet',
            'address': '0xb269Ab3B20E09e6fd17C3ED5B4CECF3dEA1a5141',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27225/thumb/SAK.?1662698993',
            'coingeckoId': 'sakura-planet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZIG',
            'name': 'Zignaly',
            'address': '0xb2617246d0c6c0087f18703d576831899ca94f01',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14796/thumb/zignaly.jpg?1618496339',
            'coingeckoId': 'zignaly',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CODE',
            'name': 'Developer DAO',
            'address': '0xb24cd494faE4C180A89975F1328Eab2a7D5d8f11',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27011/thumb/CHWxD9GV_400x400.jpeg?1661421133',
            'coingeckoId': 'developer-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EXRT',
            'name': 'EXRT Network',
            'address': '0xb20043F149817bff5322F1b928e89aBFC65A9925',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xb20043f149817bff5322f1b928e89abfc65a9925.png',
            'coingeckoId': 'exrt-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'GNY',
            'name': 'GNY',
            'address': '0xb1f871Ae9462F1b2C6826E88A7827e76f86751d4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xb1f871ae9462f1b2c6826e88a7827e76f86751d4.png',
            'coingeckoId': 'gny',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'HIPUNKS',
            'name': 'hiPunks',
            'address': '0xb1f136a74e18E4E2921feBbf25820d1Bb65B5647',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26739/thumb/hipunks.png?1659946062',
            'coingeckoId': 'hipunks',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YAX',
            'name': 'yAxis',
            'address': '0xb1dC9124c395c1e97773ab855d66E879f053A289',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xb1dC9124c395c1e97773ab855d66E879f053A289/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'ETHBNT',
            'name': 'ETHBNT Relay',
            'address': '0xb1CD6e4153B2a390Cf00A6556b0fC1458C4A5533',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10664/thumb/ETHBNT_Relay.png?1581486882',
            'coingeckoId': 'ethbnt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UM',
            'name': 'Continuum World',
            'address': '0xb19Dd661F076998e3B0456935092a233e12C2280',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18798/thumb/Moneda.png?1633474110',
            'coingeckoId': 'continuum-world',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SER',
            'name': 'Serum SER',
            'address': '0xb19189Fb36c816f3e0f16065057B07B790998fDC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27757/thumb/Untitled_design_%2810%29.png?1665824038',
            'coingeckoId': 'serum-ser',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHFT',
            'name': 'Shyft Network',
            'address': '0xb17C88bDA07D28B3838E0c1dE6a30eAfBCF52D85',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14534/thumb/TOKEN-Gradient.png?1616768352',
            'coingeckoId': 'shyft-network-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MELON',
            'name': 'Musk Melon',
            'address': '0xb14784b2a56945AED7b8CD41661D68F8b6CCeC8b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25089/thumb/MepNVeU.png?1650269351',
            'coingeckoId': 'musk-melon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IMPACTXP',
            'name': 'ImpactXP',
            'address': '0xb12494C8824fc069757F47d177E666c571Cd49aE',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21118/thumb/TJpEKVBW_400x400.jpg?1638340573',
            'coingeckoId': 'impactxp',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'Plinu',
            'name': 'Pluto Inu',
            'address': '0xb0f68B3256a2fBD632F134CF6B08BE3FA2690BF8',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://api.rubic.exchange/assets/ethereum/0xb0f68b3256a2fbd632f134cf6b08be3fa2690bf8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'LOTTO',
            'name': 'Lotto',
            'address': '0xb0dFd28d3CF7A5897C694904Ace292539242f858',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13822/thumb/Lotto-Logo256x256.png?1612150421',
            'coingeckoId': 'lotto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DXC',
            'name': 'Dex Trade Coin',
            'address': '0xb0E99627bC29adEf1178f16117BF495351E81997',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15025/thumb/R1A63oDx_400x400.jpg?1619489389',
            'coingeckoId': 'dex-trade-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRAD',
            'name': 'Tradcoin',
            'address': '0xb09aD98524780228D2df4f34AA665D9Dbb9999E4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6924/thumb/tmTPJCkZ_400x400.jpg?1547043296',
            'coingeckoId': 'tradcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-14229-WILSH',
            'name': 'RealT   14229 Wilshire Dr  Detroit  MI ',
            'address': '0xb09850e2B93aa3AAa1476bF0c007cfc960E2de79',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16604/thumb/14229-Wilshire-hero-1.jpg?1624543967',
            'coingeckoId': '14229-wilshire',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INTD',
            'name': 'INTDESTCOIN  OLD ',
            'address': '0xb08bA4Ad6bC291f4F1E79C4c7f9395141B8D5797',
            'decimals': 17,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27134/thumb/INTD.png?1662025876',
            'coingeckoId': 'intdestcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SIGN',
            'name': 'Sign',
            'address': '0xb06B8186cc008A79FD6722B1eefad07c14E97dA0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26495/thumb/PWRjbqG9_400x400.png?1658303265',
            'coingeckoId': 'sign',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CEEK',
            'name': 'CEEK Smart VR',
            'address': '0xb056c38f6b7Dc4064367403E26424CD2c60655e1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xb056c38f6b7dc4064367403e26424cd2c60655e1.png',
            'coingeckoId': 'ceek',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'FLOAT',
            'name': 'Float Protocol  Float',
            'address': '0xb05097849BCA421A3f51B249BA6CCa4aF4b97cb9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xb05097849BCA421A3f51B249BA6CCa4aF4b97cb9/logo.png',
            'coingeckoId': 'float-protocol-float',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'TNS',
            'name': 'Transcodium',
            'address': '0xb0280743b44bF7db4B6bE482b2Ba7b75E5dA096C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xb0280743b44bf7db4b6be482b2ba7b75e5da096c.png',
            'coingeckoId': 'transcodium',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'LFBTC',
            'name': 'LIFT.Kitchen BTC',
            'address': '0xafcE9B78D409bF74980CACF610AFB851BF02F257',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xafcE9B78D409bF74980CACF610AFB851BF02F257/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'RFUEL',
            'name': 'RioDeFi',
            'address': '0xaf9f549774ecEDbD0966C52f250aCc548D3F36E5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xaf9f549774ecedbd0966c52f250acc548d3f36e5.png',
            'coingeckoId': 'rio-defi',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'CBT',
            'name': 'CryBet',
            'address': '0xaf5f584d79701d5BDc9CA045E66ae130b67A68ad',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10973/thumb/d4zbv0h.jpg?1586743861',
            'coingeckoId': 'crybet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TTC',
            'name': 'Tech Trees Coin',
            'address': '0xaf3ccfD9B59b36628cC2F659a09d6440795B2520',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FET',
            'name': 'Fetch ai',
            'address': '0xaea46A60368A7bD060eec7DF8CBa43b7EF41Ad85',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xaea46a60368a7bd060eec7df8cba43b7ef41ad85.png',
            'coingeckoId': 'fetch-ai',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'TOON',
            'name': 'Pontoon',
            'address': '0xaeE433ADeBe0FBB88dAa47eF0C1A513CaA52EF02',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19575/thumb/pontoon.PNG?1635467899',
            'coingeckoId': 'pontoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SNGLS',
            'name': 'SingularDTV',
            'address': '0xaeC2E87E0A235266D9C5ADc9DEb4b2E29b54D009',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/521/thumb/singulardtv.png?1547034199',
            'coingeckoId': 'singulardtv',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CMSN',
            'name': 'The Commission',
            'address': '0xaeB813653bb20d5Fa4798dc4fc63Af9cad4f3f67',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24993/thumb/pGaXc6eB_400x400.jpg?1649719689',
            'coingeckoId': 'the-commission',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STETH',
            'name': 'Lido Staked Ether',
            'address': '0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xae7ab96520de3a18e5e111b5eaab095312d7fe84.png',
            'coingeckoId': 'staked-ether',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'RETH',
            'name': 'Rocket Pool ETH',
            'address': '0xae78736Cd615f374D3085123A210448E74Fc6393',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xae78736cd615f374d3085123a210448e74fc6393.png',
            'coingeckoId': 'rocket-pool-eth',
            'listedIn': [
                'coingecko',
                '1inch',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'BB-A-DAI',
            'name': 'Balancer Boosted Aave DAI',
            'address': '0xae37D54Ae477268B9997d4161B96b8200755935c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25883/thumb/bb-a-dai.png?1654347111',
            'coingeckoId': 'balancer-boosted-aave-dai',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MINX',
            'name': 'InnovaMinex',
            'address': '0xae353DaEed8DCc7a9a12027F7e070c0A50B7b6A4',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7116/thumb/innovaminex.jpg?1547043613',
            'coingeckoId': 'innovaminex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VITE',
            'name': 'Vite',
            'address': '0xadd5E881984783dD432F80381Fb52F45B53f3e70',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xadd5E881984783dD432F80381Fb52F45B53f3e70/logo.png',
            'coingeckoId': 'vite',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'WCUSD',
            'name': 'Wrapped Celo Dollar',
            'address': '0xad3E3Fc59dff318BecEaAb7D00EB4F68b1EcF195',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xad3E3Fc59dff318BecEaAb7D00EB4F68b1EcF195/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'DOUGH',
            'name': 'PieDAO DOUGH v2',
            'address': '0xad32A8e6220741182940c5aBF610bDE99E737b2D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xad32a8e6220741182940c5abf610bde99e737b2d.png',
            'coingeckoId': 'piedao-dough-v2',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'MBONK',
            'name': 'megaBonk',
            'address': '0xacFe45C352C902ae3A3F9B6bfE6eC994c5D791BF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14996/thumb/Bonk_v2_gecko.png?1619391636',
            'coingeckoId': 'megabonk',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SFRXETH',
            'name': 'Staked Frax Ether',
            'address': '0xac3E018457B222d93114458476f3E3416Abbe38F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28285/thumb/JjqQ9ROz_400x400.jpeg?1669170466',
            'coingeckoId': 'staked-frax-ether',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOV',
            'name': 'Dovu',
            'address': '0xac3211a5025414Af2866FF09c23FC18bc97e79b1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xac3211a5025414af2866ff09c23fc18bc97e79b1.png',
            'coingeckoId': 'dovu',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'BBL',
            'name': 'Bubble Network',
            'address': '0xac00797df10e825589D8b53E715393BE4E617459',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13443/thumb/bubble_network_logo.png?1608612666',
            'coingeckoId': 'bubble-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHINJA',
            'name': 'Shibnobi',
            'address': '0xab167E816E4d76089119900e941BEfdfA37d6b32',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20545/thumb/4yCR4DH.png?1655888898',
            'coingeckoId': 'shibnobi',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'GSWAP',
            'name': 'Gameswap',
            'address': '0xaac41EC512808d64625576EDdd580e7Ea40ef8B2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xaac41ec512808d64625576eddd580e7ea40ef8b2.png',
            'coingeckoId': 'gameswap-org',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'CEL',
            'name': 'Celsius Network',
            'address': '0xaaAEBE6Fe48E54f431b0C390CfaF0b017d09D42d',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xaaaebe6fe48e54f431b0c390cfaf0b017d09d42d.png',
            'coingeckoId': 'celsius-degree-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'ALPHR',
            'name': 'Alphr',
            'address': '0xaa99199d1e9644b588796F3215089878440D58e0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15029/thumb/alphr.jpg?1619495712',
            'coingeckoId': 'alphr',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIT',
            'name': 'ShitCoin',
            'address': '0xaa7FB1c8cE6F18d4fD4Aabb61A2193d4D441c54F',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9472/thumb/ShitCoin_200x200.png?1567723695',
            'coingeckoId': 'shitcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRE8R',
            'name': 'CRE8R DAO',
            'address': '0xaa61D5dec73971CD4a026ef2820bB87b4a4Ed8d6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22568/thumb/cropped-FINALwithbrick-2-1-1.png?1642060468',
            'coingeckoId': 'cre8r-dao',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ZMT',
            'name': 'Zipmex',
            'address': '0xaa602dE53347579f86b996D2Add74bb6F79462b2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13866/thumb/ZMT_Token.png?1637241562',
            'coingeckoId': 'zipmex-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MMAI',
            'name': 'MetamonkeyAi',
            'address': '0xaa0c5B3567fd1bac8a2a11EB16c3F81a49eea90F',
            'decimals': 7,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27208/thumb/metamonkey.jpeg?1662608488',
            'coingeckoId': 'metamonkeyai',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MBN',
            'name': 'Mobilian Coin',
            'address': '0xaF80951201a0EFF85A0Fd3aDF4c7043dB856d3E6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12472/thumb/TqhkduvK_400x400.jpg?1600122610',
            'coingeckoId': 'mobilian-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTH',
            'name': 'Monetha',
            'address': '0xaF4DcE16Da2877f8c9e00544c93B62Ac40631F16',
            'decimals': 5,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/902/thumb/LOGO-MTH-200-200.png?1656643069',
            'coingeckoId': 'monetha',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IHF',
            'name': 'Invictus Hyperion Fund',
            'address': '0xaF1250fa68D7DECD34fD75dE8742Bc03B29BD58e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3747/thumb/ihp.png?1547038807',
            'coingeckoId': 'invictus-hyprion-fund',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INTD',
            'name': 'INDESTCOIN',
            'address': '0xaEeb517E65501BCD72399D639A5D993661EFeB68',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27543/thumb/logoend.png?1668586274',
            'coingeckoId': 'indestcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIKABOSHI',
            'name': 'Amatsu Mikaboshi',
            'address': '0xaE86f48c0B00F2a3eaeF4ba4c23d17368f0f63f4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27048/thumb/photo_2022-08-25_23-44-10.jpg?1661504716',
            'coingeckoId': 'amatsu-mikaboshi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CREDI',
            'name': 'Credefi',
            'address': '0xaE6e307c3Fe9E922E5674DBD7F830Ed49c014c6B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21396/thumb/e1QbZ4qQ_400x400.jpg?1639042835',
            'coingeckoId': 'credefi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LGCY',
            'name': 'LGCY Network',
            'address': '0xaE697F994Fc5eBC000F8e22EbFfeE04612f98A0d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xae697f994fc5ebc000f8e22ebffee04612f98a0d.png',
            'coingeckoId': 'lgcy-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'UNCX',
            'name': 'UniCrypt',
            'address': '0xaDB2437e6F65682B85F814fBc12FeC0508A7B1D0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xadb2437e6f65682b85f814fbc12fec0508a7b1d0.png',
            'coingeckoId': 'unicrypt-2',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'PLF',
            'name': 'PlayFuel',
            'address': '0xaDA62f7CCd6af6cAcff04ACCBC4f56f3D4FFd4Ef',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10313/thumb/BNXcW4kq_400x400.jpg?1577833532',
            'coingeckoId': 'playfuel',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHI',
            'name': 'Shirtum',
            'address': '0xaD996A45fd2373ed0B10Efa4A8eCB9de445A4302',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16955/thumb/4fWlpC0.png?1625794164',
            'coingeckoId': 'shirtum',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-18983-ALCOY',
            'name': 'RealT   18983 Alcoy Ave  Detroit  MI 48',
            'address': '0xaD91999F534F4075B00bA4231C018e57bdeBb342',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16619/thumb/18983-Alcoy-hero-1.jpg?1624550570',
            'coingeckoId': '18983-alcoy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEFI+S',
            'name': 'PieDAO DEFI Small Cap',
            'address': '0xaD6A626aE2B43DCb1B39430Ce496d2FA0365BA9C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xad6a626ae2b43dcb1b39430ce496d2fa0365ba9c.png',
            'coingeckoId': 'piedao-defi-small-cap',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'TRXC',
            'name': 'TronClassic',
            'address': '0xaD5Fe5B0B8eC8fF4565204990E4405B2Da117d8e',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4626/thumb/trxc.png?1547039893',
            'coingeckoId': 'tronclassic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AWC',
            'name': 'Atomic Wallet Coin',
            'address': '0xaD22f63404f7305e4713CcBd4F296f34770513f4',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7430/thumb/Atomic_Wallet_Aeron_%281%29.png?1547274057',
            'coingeckoId': 'atomic-wallet-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAC',
            'name': 'Stand Cash',
            'address': '0xaCd8F2523a4613Eee78904354187c81Bb05ae2b8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13542/thumb/sac.jpg?1609648101',
            'coingeckoId': 'stand-cash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'N1',
            'name': 'NFTify',
            'address': '0xaCbd826394189Cf2623C6DF98a18b41fC8fFC16D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16095/thumb/n1-token-logo-circle-200x200.png?1627130530',
            'coingeckoId': 'nftify',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AENJ',
            'name': 'Aave ENJ',
            'address': '0xaC6Df26a590F08dcC95D5a4705ae8abbc88509Ef',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xac6df26a590f08dcc95d5a4705ae8abbc88509ef.png',
            'coingeckoId': 'aave-enj',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'CIND',
            'name': 'Cindrum',
            'address': '0xaC0968a3E2020aC8ca83e60CcF69081EBc6d3bC3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20449/thumb/1_sbv9l6ArIn7oYIhi0oQWoQ.png?1637043983',
            'coingeckoId': 'cindrum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CWS',
            'name': 'Seascape Crowns',
            'address': '0xaC0104Cca91D167873B8601d2e71EB3D4D8c33e0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xac0104cca91d167873b8601d2e71eb3d4d8c33e0.png',
            'coingeckoId': 'crowns',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SQUEEZE',
            'name': 'Squeeze',
            'address': '0xaBd4dc8fDe9848CBc4Ff2c0Ee81d4A49F4803Da4',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18554/thumb/squeeze.png?1632377096',
            'coingeckoId': 'squeeze-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DATX',
            'name': 'DATx',
            'address': '0xaBbBB6447B68ffD6141DA77C18c7B5876eD6c5ab',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2942/thumb/datx.png?1547037168',
            'coingeckoId': 'datx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DETO',
            'name': 'Delta Exchange',
            'address': '0xaB93dF617F51E1E415b5b4f8111f122d6b48e55C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14610/thumb/deto-logo.png?1617247295',
            'coingeckoId': 'delta-exchange-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SGE',
            'name': 'Society of Galactic Exploration',
            'address': '0xaB456bDb0A373BBaC6C4A76176E9f159cAcD5752',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15819/thumb/sge.png?1636253933',
            'coingeckoId': 'society-of-galactic-exploration',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'LIEN',
            'name': 'Lien',
            'address': '0xaB37e1358b639Fd877f015027Bb62d3ddAa7557E',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12224/thumb/Lien.png?1598262819',
            'coingeckoId': 'lien',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WOOP',
            'name': 'Woonkly Power',
            'address': '0xaAD483F97f13C6A20B9D05d07c397CE85c42C393',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14494/thumb/Icono_Infinito_Circular_Blanco_Fondo_Azul_2x.png?1658830979',
            'coingeckoId': 'woonkly-power',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HBCH',
            'name': 'Huobi Bitcoin Cash',
            'address': '0xaAC679720204aAA68B6C5000AA87D789a3cA0Aa5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14105/thumb/HBCh.png?1614330789',
            'coingeckoId': 'huobi-bitcoin-cash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TKN',
            'name': 'Monolith',
            'address': '0xaAAf91D9b90dF800Df4F55c205fd6989c977E73a',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xaaaf91d9b90df800df4f55c205fd6989c977e73a.png',
            'coingeckoId': 'tokencard',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'POLC',
            'name': 'Polkacity',
            'address': '0xaA8330FB2B4D5D07ABFE7A72262752a8505C6B37',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14066/thumb/vykih1Nq_400x400.png?1614130959',
            'coingeckoId': 'polka-city',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'TRAC',
            'name': 'OriginTrail',
            'address': '0xaA7a9CA87d3694B5755f213B5D04094b8d0F0A6F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xaa7a9ca87d3694b5755f213b5d04094b8d0f0a6f.png',
            'coingeckoId': 'origintrail',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'SOON',
            'name': 'RealT SOON Token',
            'address': '0xaA2C0cf54cB418eB24E7e09053B82C875C68bb88',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xaa2c0cf54cb418eb24e7e09053b82c875c68bb88.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BCHBEAR',
            'name': '3X Short Bitcoin Cash Token',
            'address': '0xa9fC65Da36064cE545e87690e06f5de10C52C690',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10268/thumb/683JEXMN_400x400_%281%29.png?1576835432',
            'coingeckoId': '3x-short-bitcoin-cash-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLOCK-E',
            'name': 'BLOCK E',
            'address': '0xa9f9aCB92E4E2f16410511D56839A5Bd1d630a60',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26905/thumb/photo_2022-09-07_10.17.08.jpeg?1664065816',
            'coingeckoId': 'block-e',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETGP',
            'name': 'Ethereum Gold Project',
            'address': '0xa96F31F1C187c28980176C3A27ba7069f48abDE4',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7337/thumb/llxg4bqE_400x400.jpg?1547044001',
            'coingeckoId': 'ethereum-gold-project',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ODEX',
            'name': 'One DEX',
            'address': '0xa960d2bA7000d58773E7fa5754DeC3Bb40A069D5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7712/thumb/WzsJ6pIr_400x400.jpg?1549940214',
            'coingeckoId': 'one-dex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELY',
            'name': 'Elysian',
            'address': '0xa95592DCFfA3C080B4B40E459c5f5692F67DB7F8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3558/thumb/elysian.png?1548124914',
            'coingeckoId': 'elysian',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DREAM',
            'name': 'Dream Swap',
            'address': '0xa93D5Cfaa41193b13321c035b4bDD2B534172762',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13033/thumb/dream_32.png?1604503082',
            'coingeckoId': 'dream-swap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KLTR',
            'name': 'Kollector',
            'address': '0xa92c49C403386111C1629AEe00936eED2A9E74a6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19926/thumb/kbase_logo.jpg?1636302934',
            'coingeckoId': 'kollector',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARA',
            'name': 'Ara',
            'address': '0xa92E7c82B11d10716aB534051B271D2f6aEf7Df5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa92e7c82b11d10716ab534051b271d2f6aef7df5.png',
            'coingeckoId': 'ara-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'UCOIN',
            'name': 'Universal Coin',
            'address': '0xa918897bd10d6DeE614470C24a061b78b021B3a9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13650/thumb/UCOIN_LOGO-min.png?1610549326',
            'coingeckoId': 'universal-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NOIA',
            'name': 'Syntropy',
            'address': '0xa8c8CfB141A3bB59FEA1E2ea6B79b5ECBCD7b6ca',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3269/thumb/Component_1.png?1608275724',
            'coingeckoId': 'noia-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LVL',
            'name': 'LevelApp',
            'address': '0xa8EdA9D4Aee0eb882F8752C6bA7e16d9233C9Ad2',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5588/thumb/n5DaGBqY_400x400.jpg?1559789019',
            'coingeckoId': 'levelapp',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YIELD',
            'name': 'Yield Protocol',
            'address': '0xa8B61CfF52564758A204F841E636265bEBC8db9B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa8b61cff52564758a204f841e636265bebc8db9b.png',
            'coingeckoId': 'yield-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'RAK',
            'name': 'Rake Finance',
            'address': '0xa8B0F154A688c22142E361707df64277e0A0bE66',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13078/thumb/rake-logo-200x200.png?1604931839',
            'coingeckoId': 'rake-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HIENS3',
            'name': 'hiENS3',
            'address': '0xa88842Ae47dbe87116cF7001DDdD1b246fcd8262',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27262/thumb/hiens3.png?1663059024',
            'coingeckoId': 'hiens3',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OPT',
            'name': 'Optimus',
            'address': '0xa85d8C972E1d54136e4BbEd3D108dB6e108e98f9',
            'decimals': 9,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'KGC',
            'name': 'Krypton Galaxy Coin',
            'address': '0xa8262Eb913FccEa4C3f77fc95b8b4043B384cFbB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9152/thumb/krypton.PNG?1564733517',
            'coingeckoId': 'krypton-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNQT',
            'name': 'Unique Utility',
            'address': '0xa80f2C8f61c56546001f5FC2eb8D6E4e72c45d4C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23382/thumb/D31DBA6B-FB7E-4ACB-82B4-98E255C1C483.jpg?1643962784',
            'coingeckoId': 'unique-utility-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TFL',
            'name': 'TrueFlip',
            'address': '0xa7f976C360ebBeD4465c2855684D1AAE5271eFa9',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/940/thumb/tfl.png?1634521414',
            'coingeckoId': 'trueflip',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BR',
            'name': 'BOHR',
            'address': '0xa7d838bfc461b777D14B05823063D2118F5D91ac',
            'decimals': 6,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'STA',
            'name': 'Statera',
            'address': '0xa7DE087329BFcda5639247F96140f9DAbe3DeED1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa7de087329bfcda5639247f96140f9dabe3deed1.png',
            'coingeckoId': 'statera',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SPIZ',
            'name': 'SPACE iZ',
            'address': '0xa7A5c1058194Af8F00c187adB7FcC0c95f1C6c2d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12213/thumb/aEXTI0vf_400x400.jpg?1598236662',
            'coingeckoId': 'space-iz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLEXUSD',
            'name': 'flexUSD',
            'address': '0xa774FFB4AF6B0A91331C084E1aebAE6Ad535e6F3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13323/thumb/flexUSD_2x.png?1607480702',
            'coingeckoId': 'flex-usd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QOM',
            'name': 'Shiba Predator',
            'address': '0xa71d0588EAf47f12B13cF8eC750430d21DF04974',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24430/thumb/l1KzMcL.png?1647660619',
            'coingeckoId': 'shiba-predator',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AGRI',
            'name': 'AgriChain',
            'address': '0xa704fCe7b309Ec09DF16e2F5Ab8cAf6Fe8A4BAA9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa704fce7b309ec09df16e2f5ab8caf6fe8a4baa9.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'TKNFY',
            'name': 'Tokenfy',
            'address': '0xa6dD98031551C23bb4A2fBE2C4d524e8f737c6f7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22901/thumb/tknfy.png?1642859030',
            'coingeckoId': 'tokenfy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EKO',
            'name': 'EchoLink',
            'address': '0xa6a840E50bCaa50dA017b91A0D86B8b2d41156EE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2437/thumb/echolink.png?1547224598',
            'coingeckoId': 'echolink',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LWC',
            'name': 'Linework Coin',
            'address': '0xa6a1Cc527d48585538b137E6Abc14B2a55489D11',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27739/thumb/Linework-logo_coin_200px-01.png?1665578755',
            'coingeckoId': 'linework-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BIGO',
            'name': 'BIGOCOIN',
            'address': '0xa6E7dc135Bdf4b3FEe7183EAB2E87C0BB9684783',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10564/thumb/Bigocoin_1200px.jpg?1597463586',
            'coingeckoId': 'bigo-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CLT',
            'name': 'Cexlt',
            'address': '0xa69f7a10dF90C4D6710588Bc18ad9bF08081f545',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13171/thumb/clt_logo.png?1605855281',
            'coingeckoId': 'cexlt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-9166-DEVONS',
            'name': 'RealT   9166 Devonshire Rd  Detroit MI ',
            'address': '0xa69D7d4DdF397f3D1E7EbAf108555d1107b3B117',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16690/thumb/Devonshire-3x.jpeg?1624636640',
            'coingeckoId': '9166-devonshire',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UST',
            'name': 'TerraUSD  Wormhole ',
            'address': '0xa693B19d2931d498c5B318dF961919BB4aee87a5',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa693b19d2931d498c5b318df961919bb4aee87a5.png',
            'coingeckoId': 'terrausd-wormhole',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'optimism',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'AMANA',
            'name': 'Aave MANA',
            'address': '0xa685a61171bb30d4072B338c80Cb7b2c865c873E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa685a61171bb30d4072b338c80cb7b2c865c873e.png',
            'coingeckoId': 'aave-mana',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SOMM',
            'name': 'Sommelier',
            'address': '0xa670d7237398238DE01267472C6f13e5B8010FD1',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23308/thumb/somm_new.png?1650884424',
            'coingeckoId': 'sommelier',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HPT',
            'name': 'Huobi Pool',
            'address': '0xa66Daa57432024023DB65477BA87D4E7F5f95213',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6628/thumb/Huobi_pool_token.png?1547042829',
            'coingeckoId': 'huobi-pool-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YFM',
            'name': 'yfarm.finance',
            'address': '0xa665FED1b0C9dA00e91ca582f77dF36E325048c5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa665fed1b0c9da00e91ca582f77df36e325048c5.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'STBU',
            'name': 'Stobox',
            'address': '0xa6422E3E219ee6d4C1B18895275FE43556fd50eD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12637/thumb/exchange.png?1623764906',
            'coingeckoId': 'stobox-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STMX',
            'name': 'StormX',
            'address': '0xa62cc35625B0C8dc1fAEA39d33625Bb4C15bD71C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1369/thumb/StormX.png?1603113002',
            'coingeckoId': 'storm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'THOR',
            'name': 'THORSwap',
            'address': '0xa5f2211B9b8170F694421f2046281775E8468044',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa5f2211b9b8170f694421f2046281775e8468044.png',
            'coingeckoId': 'thorswap',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ONTP',
            'name': 'ONTPAY',
            'address': '0xa5E85CFB2c6dB032dAD992516A6F48529581Cba3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27496/thumb/ezgif.com-gif-maker.jpg?1664271339',
            'coingeckoId': 'ontpay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEPO',
            'name': 'Depo',
            'address': '0xa5DEf515cFd373D17830E7c1de1639cB3530a112',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa5def515cfd373d17830e7c1de1639cb3530a112.png',
            'coingeckoId': 'depo',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'MOH',
            'name': 'Medal of Honour',
            'address': '0xa59e341e8047498700eD244814b01b34547fb21B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27861/thumb/MOH___200-200.png?1667467198',
            'coingeckoId': 'medal-of-honour',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UTU',
            'name': 'UTU Coin',
            'address': '0xa58a4f5c4Bb043d2CC1E170613B74e767c94189B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa58a4f5c4bb043d2cc1e170613b74e767c94189b.png',
            'coingeckoId': 'utu-coin',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'CYNC',
            'name': 'Cyn C',
            'address': '0xa5522d6137Ece774e8084BBf9c34398192cCdCa5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24065/thumb/logo_disc.png?1646206132',
            'coingeckoId': 'cyn-c',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BACON',
            'name': 'Bacon Coin',
            'address': '0xa54d2EBfD977ad836203c85F18db2F0a0cF88854',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25173/thumb/bacon-200.png?1650526720',
            'coingeckoId': 'bacon-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATL',
            'name': 'Atlantis',
            'address': '0xa5342D72D04c133180f376753f90a4B2eEe29bB3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DUCKER',
            'name': 'Duckereum',
            'address': '0xa52bfFAD02B1FE3f86A543a4e81962d3B3bB01A7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa52bffad02b1fe3f86a543a4e81962d3b3bb01a7.png',
            'coingeckoId': 'duckereum',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'YFIX',
            'name': 'YFIX finance',
            'address': '0xa4f779074850D320B5553C9Db5Fc6A8ab15Bd34a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12554/thumb/yfix-icon-200.png?1600739144',
            'coingeckoId': 'yfix-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '1MIL',
            'name': '1MillionNFTs',
            'address': '0xa4eF4b0B23C1fc81d3f9ecF93510e64f58A4A016',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa4ef4b0b23c1fc81d3f9ecf93510e64f58a4a016.png',
            'coingeckoId': '1million-nfts',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BAKED',
            'name': 'Baked',
            'address': '0xa4cb0dCe4849BdcAd2d553E9e68644Cf40E26ccE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19178/thumb/rebaked-logo-full.png?1634613132',
            'coingeckoId': 'baked-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UMBR',
            'name': 'Umbria Network',
            'address': '0xa4bBE66f151B22B167127c770016b15fF97Dd35C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14712/thumb/RX7VJg6.png?1617891954',
            'coingeckoId': 'umbra-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FOREX',
            'name': 'FOREXCOIN',
            'address': '0xa4E9584DAa093Cb1205E17bA737c3fd015748087',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6852/thumb/forex-logo.PNG?1547043174',
            'coingeckoId': 'forexcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MONK',
            'name': 'Monastery Finance',
            'address': '0xa4E27ea37D18bb0f483779f9E75A6024EFa5E73e',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24563/thumb/s0vLTcH.png?1648189796',
            'coingeckoId': 'monastery-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIBAKEN',
            'name': 'Shibaken Finance',
            'address': '0xa4Cf2aFD3B165975afFFBf7e487CDd40C894Ab6B',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa4cf2afd3b165975afffbf7e487cdd40c894ab6b.png',
            'coingeckoId': 'shibaken-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MR',
            'name': 'Meta Ruffy',
            'address': '0xa4Cb3ef5f41a4D89D6FCed22ea8a1C57957629Aa',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27831/thumb/1tRmvYZ8_400x400.jpeg?1665993865',
            'coingeckoId': 'meta-ruffy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UJENNY',
            'name': 'Jenny DAO V1',
            'address': '0xa499648fD0e80FD911972BbEb069e4c20e68bF22',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15453/thumb/ujenny.png?1620870247',
            'coingeckoId': 'jenny-metaverse-dao-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PRUF',
            'name': 'PR F Protocol',
            'address': '0xa49811140E1d6f653dEc28037Be0924C811C4538',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27482/thumb/prufu-200x200_1.png?1664246561',
            'coingeckoId': 'pruf-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USTC',
            'name': 'Wrapped USTC',
            'address': '0xa47c8bf37f92aBed4A126BDA807A7b7498661acD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa47c8bf37f92abed4a126bda807a7b7498661acd.png',
            'coingeckoId': 'wrapped-ust',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'EQMT',
            'name': 'EQUUSMiningToken',
            'address': '0xa462d0E6Bb788c7807B1B1C96992CE1f7069E195',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa462d0e6bb788c7807b1b1c96992ce1f7069e195.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'CORN',
            'name': 'Cornichon',
            'address': '0xa456b515303B2Ce344E9d2601f91270f8c2Fea5E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13235/thumb/cornichon.png?1606629943',
            'coingeckoId': 'cornichon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DVPN',
            'name': 'Sentinel  OLD ',
            'address': '0xa44E5137293E855B1b7bC7E2C6f8cD796fFCB037',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3625/thumb/Sentinel_512X512.png?1622174555',
            'coingeckoId': 'sentinel-group',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CATE',
            'name': 'Cash Tech',
            'address': '0xa42F266684ac2aD6ecb00df95b1c76EFbb6f136c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa42f266684ac2ad6ecb00df95b1c76efbb6f136c.png',
            'coingeckoId': 'cash-tech',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'AVG',
            'name': 'Avocado DAO',
            'address': '0xa41F142b6eb2b164f8164CAE0716892Ce02f311f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21102/thumb/logo192_%281%29.png?1640567889',
            'coingeckoId': 'avaocado-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MET',
            'name': 'Metronome',
            'address': '0xa3d58c4E56fedCae3a7c43A725aeE9A71F0ece4e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa3d58c4e56fedcae3a7c43a725aee9a71f0ece4e.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SAITOKI',
            'name': 'Saitoki Inu',
            'address': '0xa3c56427683a19F7574b9fc219CFD27d5d6e87Fa',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23586/thumb/saitoki_200.png?1667467076',
            'coingeckoId': 'saitoki-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DINERO',
            'name': 'Dinerobet',
            'address': '0xa3ad8c7AB6b731045b5b16E3fDf77975c71ABe79',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28248/thumb/CABA1DE1-2CB1-46D3-97BC-20305EA67FBB.png?1668656923',
            'coingeckoId': 'dinerobet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CTC',
            'name': 'Creditcoin',
            'address': '0xa3EE21C306A700E682AbCdfe9BaA6A08F3820419',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10569/thumb/ctc.png?1644650695',
            'coingeckoId': 'creditcoin-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STRAX',
            'name': 'Stratis',
            'address': '0xa3C22370de5f9544f0c4De126b1e46cEadF0A51B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/531/thumb/stratis.png?1547034209',
            'coingeckoId': 'stratis',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTA',
            'name': 'mStable Governance  Meta',
            'address': '0xa3BeD4E1c75D00fa6f4E5E6922DB7261B5E9AcD2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa3bed4e1c75d00fa6f4e5e6922db7261b5e9acd2.png',
            'coingeckoId': 'meta',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'LTX',
            'name': 'Lattice',
            'address': '0xa393473d64d2F9F026B60b6Df7859A689715d092',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa393473d64d2f9f026b60b6df7859a689715d092.png',
            'coingeckoId': 'lattice-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'TOMOBULL',
            'name': '3X Long TomoChain Token',
            'address': '0xa38920C00D1a5303dB538A3Ea08da7a779e1F751',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10170/thumb/683JEXMN_400x400_%281%29.png?1576505510',
            'coingeckoId': '3x-long-tomochain-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSPT',
            'name': 'Blocksport',
            'address': '0xa350DA05405cc204e551C4eeD19C3039646528d5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23735/thumb/lUjOcgBNs1fSMbGND5bmUMSKaVYybK5oDblEFgqX5Tvcg89_U3yzRRHzcR5UMpzObjlps5ZpumoYjtP9R2ddgsUkP0xyw3p5R_VMAh5qIQfUu8IGicH2xEzzwHM5ayPOh8PaO_BEFxjvYlYpt1Fknz6OdOhQrTBhh8kBfG0De1HEGZLe7dJGKnoLRzlyYkzYcIimMy-rzp2swB.jpg?1645174474',
            'coingeckoId': 'blocksport',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'Q8E20',
            'name': 'Q8E20 Token',
            'address': '0xa31f7a32Db329F270A0E6b59558823e64D8eF0A6',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10427/thumb/F366Xq9.png?1579215048',
            'coingeckoId': 'q8e20-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ABI',
            'name': 'Abachi',
            'address': '0xa31DFe74cDA04de990CafC6a0aB2BB6d6C9077CD',
            'decimals': 9,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-9920-BISHOP',
            'name': 'RealT   9920 Bishop St  Detroit  MI 482',
            'address': '0xa2b2ae397492C7ed8A4c1e751aC72D2b59947E6b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16615/thumb/9920-Bishop-hero-1.jpg?1624549554',
            'coingeckoId': '9920-bishop',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TWA',
            'name': 'Adventure',
            'address': '0xa2EF2757D2eD560c9e3758D1946d7bcccBD5A7fe',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13284/thumb/twa_logo.jpg?1606979012',
            'coingeckoId': 'adventure-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'sXRP',
            'name': 'Synth XRP',
            'address': '0xa2B0fDe6D710e201d0d608e924A484d1A5fEd57c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/sxrp.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'MVRC',
            'name': 'MoveRich',
            'address': '0xa291B47EFE802de81Ac425c67D2B8d2925FA31A4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28176/thumb/MoveRich_logo%28200x200%29.png?1668247458',
            'coingeckoId': 'moverich',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HUSL',
            'name': 'The HUSL',
            'address': '0xa2881F7F441267042f9778fFA0d4F834693426be',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18619/thumb/W8ZIHKU.png?1650437671',
            'coingeckoId': 'the-husl',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MM',
            'name': 'MMToken',
            'address': '0xa283aA7CfBB27EF0cfBcb2493dD9F4330E0fd304',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://elk.finance/tokens/logos/ethereum/0xa283aA7CfBB27EF0cfBcb2493dD9F4330E0fd304/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'JAR',
            'name': 'Jarvis ',
            'address': '0xa249DE6948022783765Fee4850d7b85E43118FCc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8774/thumb/3jmUWB3e_400x400.jpg?1561085148',
            'coingeckoId': 'jarvis',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LBP',
            'name': 'LaunchBlock',
            'address': '0xa23C1194d421F252b4e6D5edcc3205F7650a4eBE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24181/thumb/S6z_-9GB_400x400.jpg?1646786017',
            'coingeckoId': 'launchblock',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHER',
            'name': 'Cherry Network',
            'address': '0xa20f77B7ad5A88badC48800C56507B7274c06Fdc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21855/thumb/cherry.PNG?1640144127',
            'coingeckoId': 'cherry-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LINKBEAR',
            'name': '3X Short Chainlink Token',
            'address': '0xa209Ba34C01A2713a4453A656630cc9De8A362Bc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10233/thumb/683JEXMN_400x400_%281%29.png?1576618846',
            'coingeckoId': '3x-short-chainlink-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALPHA',
            'name': 'Alpha Venture DAO',
            'address': '0xa1faa113cbE53436Df28FF0aEe54275c13B40975',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa1faa113cbe53436df28ff0aee54275c13b40975_1.png',
            'coingeckoId': 'alpha-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'tfTUSD',
            'name': 'TrueFi TrueUSD',
            'address': '0xa1e72267084192Db7387c8CC1328fadE470e4149',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/tftusd.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'RFOX',
            'name': 'RFOX',
            'address': '0xa1d6Df714F91DeBF4e0802A542E13067f31b8262',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa1d6df714f91debf4e0802a542e13067f31b8262.png',
            'coingeckoId': 'redfox-labs-2',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'DXD',
            'name': 'DXdao',
            'address': '0xa1d65E8fB6e87b60FECCBc582F7f97804B725521',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa1d65e8fb6e87b60feccbc582f7f97804b725521.png',
            'coingeckoId': 'dxdao',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'YFII',
            'name': 'DFI money',
            'address': '0xa1d0E215a23d7030842FC67cE582a6aFa3CCaB83',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa1d0e215a23d7030842fc67ce582a6afa3ccab83.png',
            'coingeckoId': 'yfii-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'POLI',
            'name': 'Polinate',
            'address': '0xa1a36D3537bBe375Cc9694795F663DDC8D516Db9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18096/thumb/Polinate_Games___Guilds_Elements-05.png?1648806645',
            'coingeckoId': 'polinate',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GAME',
            'name': 'MetaPortal Gaming Index',
            'address': '0xa1EA9aA32CBba8c203DDC510a144aD1F439d436c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24071/thumb/GAME.Logo.png?1646216027',
            'coingeckoId': 'metaportal-gaming-index',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MONGOOSE',
            'name': 'Mongoose',
            'address': '0xa1817B6d8D890F3943b61648992730373B71f156',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21456/thumb/61b142508fba4235f29d57eb_Frame_24_%281%29.png?1639291023',
            'coingeckoId': 'mongoose',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'SPUME',
            'name': 'Spume',
            'address': '0xa16a609fF4e1A15b6cCb469E7a5dD14E89305283',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27089/thumb/D6lMeW9k_400x400.jpeg?1661824399',
            'coingeckoId': 'spume',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DRC',
            'name': 'Digital Reserve Currency',
            'address': '0xa150Db9b1Fa65b44799d4dD949D922c0a33Ee606',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa150db9b1fa65b44799d4dd949d922c0a33ee606.png',
            'coingeckoId': 'digital-reserve-currency',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'IDLEDAISAFE',
            'name': 'IdleDAI  Risk Adjusted ',
            'address': '0xa14eA0E11121e6E951E87c66AFe460A00BCD6A16',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11929/thumb/idledai-safe.png?1596263241',
            'coingeckoId': 'idle-dai-risk-adjusted',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PMLG',
            'name': 'Polychain Monsters Genesis',
            'address': '0xa14c04DEa16798Aa8F25b1Da583cd5fBBFd6579E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25396/thumb/19329.png?1651710855',
            'coingeckoId': 'polychain-monsters-genesis',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BULK',
            'name': 'Bulk Network',
            'address': '0xa143ac515dCA260A46C742C7251eF3B268639593',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15266/thumb/Bulk_whale_round.png?1620285364',
            'coingeckoId': 'bulk-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-5601-SWOOD-',
            'name': 'RealT   5601 S Wood St  Chicago  IL 606',
            'address': '0xa137D82197Ea4cdfd5f008A91Ba816b8324F59E1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16605/thumb/5601-S-Wood-hero-1.jpg?1624544382',
            'coingeckoId': '5601-s-wood',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MYTH',
            'name': 'Myth',
            'address': '0xa12D7e5319f5b43476eF19D1569E10097caCdFE2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17593/thumb/GT_Logo-01.png?1628581478',
            'coingeckoId': 'myth-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'kUSDC',
            'name': 'KAKI USDC',
            'address': '0xa124ff1e97e7f3e4a796f6a2d3Bf5d0e2D41973D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'ABR',
            'name': 'Allbridge',
            'address': '0xa11bD36801d8fa4448F0ac4ea7A62e3634cE8C7C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18690/thumb/abr.png?1640742053',
            'coingeckoId': 'allbridge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANT',
            'name': 'Aragon',
            'address': '0xa117000000f279D81A1D3cc75430fAA017FA5A2e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa117000000f279d81a1d3cc75430faa017fa5a2e.png',
            'coingeckoId': 'aragon',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'STKABPT',
            'name': 'Staked Aave Balancer Pool Token',
            'address': '0xa1116930326D21fB917d5A27F1E9943A9595fb47',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16789/thumb/StkABPT_2x.png?1625046066',
            'coingeckoId': 'staked-aave-balancer-pool-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WG0',
            'name': 'Wrapped Gen 0 CryptoKitties',
            'address': '0xa10740ff9FF6852eac84cdcfF9184e1D6d27C057',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12870/thumb/wg0_logo.png?1603176705',
            'coingeckoId': 'wrapped-gen-0-cryptokitties',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEPAY',
            'name': 'DePay',
            'address': '0xa0bEd124a09ac2Bd941b10349d8d224fe3c955eb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa0bed124a09ac2bd941b10349d8d224fe3c955eb.png',
            'coingeckoId': 'depay',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'TST',
            'name': 'The Standard Token',
            'address': '0xa0b93B9e90aB887E53F9FB8728c009746e989B53',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20598/thumb/TheStandard-logo_variation-01.png?1668676004',
            'coingeckoId': 'standard-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TOMB',
            'name': 'TOMB',
            'address': '0xa0b20DecBc557E3f68E140eD5a0c69bc865F865A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TOKO',
            'name': 'Tokoin',
            'address': '0xa0F0546Eb5E3eE7e8cfC5DA12e5949F3AE622675',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8807/thumb/TOKOIN_LOGO_%28no_text%29.png?1646664093',
            'coingeckoId': 'toko',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HPO',
            'name': 'Hippo Wallet',
            'address': '0xa0ED3C520dC0632657AD2EaaF19E26C4fD431a84',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28089/thumb/b77170dd-1dd1-4581-91b2-e352794fa045.jpg?1667446200',
            'coingeckoId': 'hippopotamus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GTFO',
            'name': 'DumpBuster',
            'address': '0xa0A9C16856C96D5E9d80a8696eEA5E02B2Dc3398',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24344/thumb/logo2.png?1647412138',
            'coingeckoId': 'dumpbuster',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALINK',
            'name': 'Aave LINK',
            'address': '0xa06bC25B5805d5F8d82847D191Cb4Af5A3e873E0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa06bc25b5805d5f8d82847d191cb4af5a3e873e0.png',
            'coingeckoId': 'aave-link',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'FARM',
            'name': 'Harvest Finance',
            'address': '0xa0246c9032bC3A600820415aE600c6388619A14D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa0246c9032bc3a600820415ae600c6388619a14d.png',
            'coingeckoId': 'harvest-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'WXT',
            'name': 'WXT Token',
            'address': '0xa02120696c7B8fE16C09C749E4598819b2B0E915',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa02120696c7b8fe16c09c749e4598819b2b0e915.png',
            'coingeckoId': 'wirex',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'AMDG',
            'name': 'AMDG',
            'address': '0xFf743A38efCF6f46fC0768B46a5Bdc264F7e92B3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24545/thumb/amdg-logo.png?1648127822',
            'coingeckoId': 'amdg-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ROCKI',
            'name': 'Rocki',
            'address': '0xFf44b937788215ecA197BAaf9AF69dbdC214aa04',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13465/thumb/rocki_logo.png?1608786767',
            'coingeckoId': 'rocki',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SNET',
            'name': 'Snetwork',
            'address': '0xFf19138b039D938db46bDDA0067DC4BA132ec71C',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5382/thumb/snetwork.png?1548609904',
            'coingeckoId': 'snetwork',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TAIL',
            'name': 'Tail',
            'address': '0xFeeeef4D7b4Bf3cc8BD012D02D32Ba5fD3D51e31',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28260/thumb/Tail_logo.png?1668762735',
            'coingeckoId': 'tail',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '8PAY',
            'name': '8Pay',
            'address': '0xFeea0bDd3D07eb6FE305938878C0caDBFa169042',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xfeea0bdd3d07eb6fe305938878c0cadbfa169042.png',
            'coingeckoId': '8pay',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'GLDN',
            'name': 'Gold Retriever',
            'address': '0xFeeB4D0f5463B1b04351823C246bdB84c4320CC2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xfeeb4d0f5463b1b04351823c246bdb84c4320cc2.png',
            'coingeckoId': 'gold-retriever',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ECH',
            'name': 'Echelon',
            'address': '0xFea300256736DC102F63552303a5B0995B15F79E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25498/thumb/SpO_F8a0_400x400.jpg?1652077746',
            'coingeckoId': 'echelon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WHITE',
            'name': 'White Ethereum',
            'address': '0xFe4BEb9217cdDf2422d4bd65449b76d807b30fe1',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26667/thumb/IMG_2923.PNG?1659500526',
            'coingeckoId': 'white-ethereum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BBS',
            'name': 'BBS Network',
            'address': '0xFe459828c90c0BA4bC8b42F5C5D44F316700B430',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23715/thumb/Ni13Pg1K_400x400.jpg?1645110585',
            'coingeckoId': 'bbs-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SETH2',
            'name': 'sETH2',
            'address': '0xFe2e637202056d30016725477c5da089Ab0A043A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xfe2e637202056d30016725477c5da089ab0a043a.png',
            'coingeckoId': 'seth2',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'REALT-S-15777-ARDMO',
            'name': 'RealT   15777 Ardmore St  Detroit  MI 4',
            'address': '0xFe17C3C0B6F38cF3bD8bA872bEE7a18Ab16b43fB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16542/thumb/15777-Ardmore-hero-2.jpg?1624371814',
            'coingeckoId': '15777-ardmore',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DCAP',
            'name': 'DCAP',
            'address': '0xFd5F65a7E4Fae82778Be1085FFd2f1DE5B2dE92C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25478/thumb/dcap.png?1652007843',
            'coingeckoId': 'dcap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETHIX',
            'name': 'EthicHub',
            'address': '0xFd09911130e6930Bf87F2B0554c44F400bD80D3e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xfd09911130e6930bf87f2b0554c44f400bd80d3e.png',
            'coingeckoId': 'ethichub',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'RARI',
            'name': 'Rarible',
            'address': '0xFca59Cd816aB1eaD66534D82bc21E7515cE441CF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xfca59cd816ab1ead66534d82bc21e7515ce441cf.png',
            'coingeckoId': 'rarible',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'FLEX',
            'name': 'FLEX Coin',
            'address': '0xFcF8eda095e37A41e002E266DaAD7efC1579bc0A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9108/thumb/coinflex_logo.png?1628750583',
            'coingeckoId': 'flex-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DAFI',
            'name': 'Dafi Protocol',
            'address': '0xFc979087305A826c2B2a0056cFAbA50aad3E6439',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xfc979087305a826c2b2a0056cfaba50aad3e6439.png',
            'coingeckoId': 'dafi-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ACDC',
            'name': 'Volt',
            'address': '0xFc44EC51C80e35A87Bc2140299B1636eC83DFb04',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5321/thumb/vivo.png?1548760105',
            'coingeckoId': 'volt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RBLX',
            'name': 'Rublix',
            'address': '0xFc2C4D8f95002C14eD0a7aA65102Cac9e5953b5E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xfc2c4d8f95002c14ed0a7aa65102cac9e5953b5e.png',
            'coingeckoId': 'rublix',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'NTVRK',
            'name': 'Netvrk',
            'address': '0xFc0d6Cf33e38bcE7CA7D89c0E292274031b7157A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xfc0d6cf33e38bce7ca7d89c0e292274031b7157a.png',
            'coingeckoId': 'netvrk',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'FOXT',
            'name': 'Fox Trading',
            'address': '0xFbe878CED08132bd8396988671b450793C44bC12',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5182/thumb/foxtrading-logo.png?1547040619',
            'coingeckoId': 'fox-trading-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTD',
            'name': 'BatTrueDollar',
            'address': '0xFbdd194376de19a88118e84E279b977f165d01b8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'EDDA',
            'name': 'EDDASwap',
            'address': '0xFbbE9b1142C699512545f47937Ee6fae0e4B0aA9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xfbbe9b1142c699512545f47937ee6fae0e4b0aa9.png',
            'coingeckoId': 'eddaswap',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'DDIM',
            'name': 'DuckDaoDime',
            'address': '0xFbEEa1C75E4c4465CB2FCCc9c6d6afe984558E20',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xfbeea1c75e4c4465cb2fccc9c6d6afe984558e20.png',
            'coingeckoId': 'duckdaodime',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ZEDXION',
            'name': 'Zedxion',
            'address': '0xFbC4f3f645C4003a2E4F4e9b51077d2DaA9a9341',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18841/thumb/ZEDXION.png?1633585913',
            'coingeckoId': 'zedxion',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HBC',
            'name': 'Hybrid Bank Cash',
            'address': '0xFb9553aFa2B5c19c5F8e5b8eE175Fc01abD1555F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12489/thumb/hb_logo.png?1600208518',
            'coingeckoId': 'hybrid-bank-cash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LEAN',
            'name': 'Lean Management',
            'address': '0xFb83869f7EE52AEBadDEA254F647953AfB1F99F8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19602/thumb/tIajRRh.png?1635491525',
            'coingeckoId': 'lean-management-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DIVER',
            'name': 'Divergence Protocol',
            'address': '0xFb782396c9b20E564A64896181c7AC8d8979d5F4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xfb782396c9b20e564a64896181c7ac8d8979d5f4.png',
            'coingeckoId': 'divergence-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ELAMA',
            'name': 'Elamachain',
            'address': '0xFb444c1f2B718dDfC385cB8Fd9f2D1D776b24668',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5650/thumb/Elamachain.png?1584505477',
            'coingeckoId': 'elamachain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EPTT',
            'name': 'Evident Proof Transaction',
            'address': '0xFac8F483877f42702aAD3C4FCB508cfba09153B5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22377/thumb/EPT.png?1641782531',
            'coingeckoId': 'evident-proof-transaction-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FAU',
            'name': 'FaucetToken',
            'address': '0xFab46E002BbF0b4509813474841E0716E6730136',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xfab46e002bbf0b4509813474841e0716e6730136.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'APED',
            'name': 'Baddest Alpha Ape Bundle',
            'address': '0xFa898efdB91E35BD311c45b9B955F742b6719aa2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18874/thumb/bteKOcAH_400x400.jpg?1633658256',
            'coingeckoId': 'baddest-alpha-ape-bundle',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAITO',
            'name': 'Saito',
            'address': '0xFa14Fa6958401314851A17d6C5360cA29f74B57B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xfa14fa6958401314851a17d6c5360ca29f74b57b.png',
            'coingeckoId': 'saito',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'DUCK',
            'name': 'DUCK Vault  NFTX ',
            'address': '0xFFE136de12a2cd95f64ceF9F36414c93e9003959',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18346/thumb/duck.png?1631603088',
            'coingeckoId': 'duck-vault-nftx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AAAVE',
            'name': 'Aave AAVE',
            'address': '0xFFC97d72E13E01096502Cb8Eb52dEe56f74DAD7B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xffc97d72e13e01096502cb8eb52dee56f74dad7b.png',
            'coingeckoId': 'aave-aave',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'STIMMY',
            'name': ' STIMMY',
            'address': '0xFF7e285b87e7F9247f0953cf8CF5cB24eeDe4B9C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20796/thumb/logo.gif?1637677225',
            'coingeckoId': 'stimmy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COOK',
            'name': 'Cook',
            'address': '0xFF75CEd57419bcaEBe5F05254983b013B0646eF5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xff75ced57419bcaebe5f05254983b013b0646ef5.png',
            'coingeckoId': 'cook',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'RNT',
            'name': 'OneRoot Network',
            'address': '0xFF603F43946A3A28DF5E6A73172555D8C8b02386',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2520/thumb/OneRootNetwork.PNG?1558014608',
            'coingeckoId': 'oneroot-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '4ART',
            'name': '4ART Coin',
            'address': '0xFF44b5719f0B77A9951636fc5e69d3a1fc9E7d73',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4690/thumb/0_%284%29.png?1595544690',
            'coingeckoId': '4artechnologies',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OGO',
            'name': 'Origo',
            'address': '0xFF0E5e014cf97e0615cb50F6f39Da6388E2FaE6E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4377/thumb/Origo_Logo_png.png?1580430001',
            'coingeckoId': 'origo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEMIR',
            'name': 'Adana Demirspor',
            'address': '0xFEc82a1B2638826Bfe53ae2F87CfD94329CDe60d',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21789/thumb/16300.png?1640051015',
            'coingeckoId': 'adana-demirspor',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DGTV',
            'name': 'Doge TV',
            'address': '0xFEb6d5238Ed8F1d59DCaB2db381AA948e625966D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28050/thumb/532a5b9e-8294-4be1-be18-f04a2cf5f0a9.png?1667280533',
            'coingeckoId': 'doge-tv',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METM',
            'name': 'MetaMorph',
            'address': '0xFEF3884b603C33EF8eD4183346E093A173C94da6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3929/thumb/metamorph.png?1548084814',
            'coingeckoId': 'metamorph',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PINT',
            'name': 'Pub Finance',
            'address': '0xFECBa472B2540C5a2d3700b2C9E06F0aa7dC6462',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14016/thumb/PINT.png?1614064578',
            'coingeckoId': 'pub-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PTN',
            'name': 'PalletOne',
            'address': '0xFE76BE9cEC465ed3219a9972c21655D57d21aec6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6669/thumb/palletone.png?1548330460',
            'coingeckoId': 'palletone',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARCHIE',
            'name': 'Archie Neko',
            'address': '0xFE5F69dfa2d4501E78078266F6d430c079098f90',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28234/thumb/photo1667927837.jpeg?1668575767',
            'coingeckoId': 'archie-neko',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REEF',
            'name': 'Reef',
            'address': '0xFE3E6a25e6b192A42a44ecDDCd13796471735ACf',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xfe3e6a25e6b192a42a44ecddcd13796471735acf.png',
            'coingeckoId': 'reef',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'NTX',
            'name': 'NitroEX',
            'address': '0xFDb15e5E6799Be72798b1ccFAEcbF186Bf73A0c4',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13976/thumb/ntx-icon.png?1613435774',
            'coingeckoId': 'nitroex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETHM',
            'name': 'Ethereum Meta',
            'address': '0xFD957F21bd95E723645C07C48a2d8ACB8Ffb3794',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6586/thumb/ethereum-meta.png?1548125409',
            'coingeckoId': 'ethereum-meta',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CTCN',
            'name': 'Contracoin',
            'address': '0xFD6C31bb6F05Fc8dB64F4b740Ab758605c271FD8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10801/thumb/Contracoin-symbol.png?1583881685',
            'coingeckoId': 'contracoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GDG',
            'name': 'Golden Goal',
            'address': '0xFD020998A1bB316dfE7B136Fe59Ae4B365D79978',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26345/thumb/3kmKPRHZ_400x400.jpeg?1657586811',
            'coingeckoId': 'golden-goal',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YCRV',
            'name': 'Yearn CRV',
            'address': '0xFCc5c47bE19d06BF83eB04298b026F81069ff65b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27622/thumb/yearncrvnew_32.png?1664881722',
            'coingeckoId': 'yearn-crv',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AWBTC',
            'name': 'Aave WBTC v1',
            'address': '0xFC4B8ED459e00e5400be803A9BB3954234FD50e3',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xfc4b8ed459e00e5400be803a9bb3954234fd50e3.png',
            'coingeckoId': 'aave-wbtc-v1',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'KITSUNE',
            'name': 'Kitsune Inu',
            'address': '0xFB378115726a9Ab2E0DCCeBbe2d53Dc625Ae201b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27661/thumb/200pxtranparent.png?1665102751',
            'coingeckoId': 'kitsune-inu-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GES',
            'name': 'Galaxy eSolutions',
            'address': '0xFB1e5F5e984C28Ad7E228CDaA1F8A0919BB6a09B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xfb1e5f5e984c28ad7e228cdaa1f8a0919bb6a09b.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'FB',
            'name': 'Fenerbah e',
            'address': '0xFB19075D77a0F111796FB259819830F4780f1429',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17711/thumb/FB_Logo.png?1629082961',
            'coingeckoId': 'fenerbahce-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CLINK',
            'name': 'cLINK',
            'address': '0xFAce851a4921ce59e912d19329929CE6da6EB0c7',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23943/thumb/iShot2022-02-25_14.35.54.png?1645770966',
            'coingeckoId': 'compound-chainlink-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GGTK',
            'name': 'GG',
            'address': '0xFA99A87b14B02e2240C79240C5a20F945ca5EF76',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13666/thumb/ggtk.png?1629348879',
            'coingeckoId': 'gg-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FRIES',
            'name': 'friesDAO',
            'address': '0xFA57F00D948bb6a28072f5416fCbF7836C3d62dD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23951/thumb/friespng.png?1645772414',
            'coingeckoId': 'friesdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LET',
            'name': 'Linkeye',
            'address': '0xFA3118B34522580c35Ae27F6cf52da1dBb756288',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1814/thumb/linkeye.png?1547975964',
            'coingeckoId': 'linkeye',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASSY',
            'name': 'ASSY Index',
            'address': '0xFA2562da1Bba7B954f26C74725dF51fb62646313',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xFA2562da1Bba7B954f26C74725dF51fb62646313/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'IOST',
            'name': 'IOST',
            'address': '0xFA1a856Cfa3409CFa145Fa4e20Eb270dF3EB21ab',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2523/small/IOST.png?1557555183',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'QCX',
            'name': 'QuickX Protocol',
            'address': '0xF9e5aF7B42D31D51677c75bbBD37c1986eC79AEE',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4971/thumb/qcx.png?1547040393',
            'coingeckoId': 'quickx-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IDO',
            'name': 'Idexo',
            'address': '0xF9c53268e9de692AE1b2ea5216E24e1c3ad7CB1E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18523/thumb/qOiqm7T8_400x400.jpg?1632275628',
            'coingeckoId': 'idexo-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IUSDS',
            'name': 'Inflation Adjusted USDS',
            'address': '0xF9C2B386FF5Df088AC717ab0010587bad3bC1ab1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25698/thumb/iUSDS2.png?1653441251',
            'coingeckoId': 'inflation-adjusted-usds',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'SHAMAN',
            'name': 'Shaman King Inu  OLD ',
            'address': '0xF98E38C3F287304a1F2d4879E741d2BF55474e84',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20189/thumb/shamna.png?1636611907',
            'coingeckoId': 'shaman-king-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RCN',
            'name': 'Ripio Credit Network',
            'address': '0xF970b8E36e23F7fC3FD752EeA86f8Be8D83375A6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf970b8e36e23f7fc3fd752eea86f8be8d83375a6.png',
            'coingeckoId': 'ripio-credit-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'YLD',
            'name': 'Yield App',
            'address': '0xF94b5C5651c888d928439aB6514B93944eEE6F48',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf94b5c5651c888d928439ab6514b93944eee6f48.png',
            'coingeckoId': 'yield-app',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'VISR',
            'name': 'Visor',
            'address': '0xF938424F7210f31dF2Aee3011291b658f872e91e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/9170.png',
            'coingeckoId': 'visor',
            'listedIn': [
                'coingecko',
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'FIRE',
            'name': 'Fire Protocol',
            'address': '0xF921ae2DAC5fa128DC0F6168Bf153ea0943d2D43',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf921ae2dac5fa128dc0f6168bf153ea0943d2d43.png',
            'coingeckoId': 'fire-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'HAKI',
            'name': 'HAKI Token',
            'address': '0xF90c7f66eAc7E2130bF677D69a250b2136Cf6697',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7945/thumb/haki.png?1667030461',
            'coingeckoId': 'haki-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SLEEPY-SHIB',
            'name': 'Sleepy Shib',
            'address': '0xF8e1D008A98350aBB4241139Fbc851aAAd51eda6',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20321/thumb/OYfY70z.png?1636877246',
            'coingeckoId': 'sleepy-shib',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TGAME',
            'name': 'Truegame',
            'address': '0xF8e06E4e4A80287FDCa5b02dcCecAa9D0954840F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2974/thumb/tgame.png?1518620605',
            'coingeckoId': 'truegame',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PQT',
            'name': 'Prediqt',
            'address': '0xF8c17C840549974eC17C6573B3963f1E3046c0eb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14407/thumb/57197945.png?1615936268',
            'coingeckoId': 'prediqt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AXA',
            'name': 'Alldex Alliance',
            'address': '0xF8Ed6c51762208FF26F8f3E4EFd4e06AF2da649C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9438/thumb/alldex.png?1569122383',
            'coingeckoId': 'alldex-alliance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PIXLS',
            'name': 'PIXLS Vault  NFTX ',
            'address': '0xF8EB56C7e4e3c885c905A19583bF41644d35AA0a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18351/thumb/pixls.png?1631604206',
            'coingeckoId': 'pixls-vault-nftx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DACC',
            'name': 'DACC',
            'address': '0xF8C595D070d104377f58715ce2E6C93E49a87f3c',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4781/thumb/dacc-token.png?1547040122',
            'coingeckoId': 'dacc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ITG',
            'name': 'iTrust Governance',
            'address': '0xF88b137cfa667065955ABD17525e89EDCF4D6426',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16713/thumb/anchor.png?1624840761',
            'coingeckoId': 'itrust-governance-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PXL',
            'name': 'Piction Network',
            'address': '0xF88951D7B676798705fd3a362ba5B1DBca2B233b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7870/thumb/4091.png?1562798644',
            'coingeckoId': 'piction-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FXT',
            'name': 'FXT',
            'address': '0xF83Bf320A4A3f4bf365c3114b6F19FC3F6b1228C',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14131/thumb/7rgEwxRT_400x400.jpg?1614588199',
            'coingeckoId': 'fxt-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CMS',
            'name': 'COMSA',
            'address': '0xF83301c5Cd1CCBB86f466A6B3c53316ED2f8465a',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2500/thumb/comsa-_xem_.png?1547036614',
            'coingeckoId': 'comsa',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRDC',
            'name': 'Cardiocoin',
            'address': '0xF81421fc15300c5a8CCa9aFE12F5CBAd502Fa756',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25135/thumb/gQiyJrGcTRjEN-6bI22FOtHOmYF8-_MMYS_U1gIlUm1wf6QxiGnZ64tIeGNNFk9z5xPPch5FMie2grAN4fXQfcjKI9L0EQDVzDEdYDfqWl8ch4ree_q4pypGPNHeSCxnynfOOLAvd9XbKguJHEJNTLAdSKnWCKu92Kho49SplFvDBplt2ruvOz1v5QB795cnjZg4ZemfgfAB_ipJU1YE4TA3uL.jpg?1650432016',
            'coingeckoId': 'cardiocoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DAM',
            'name': 'Datamine',
            'address': '0xF80D589b3Dbe130c270a69F1a69D050f268786Df',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11695/thumb/qxsFH8W.png?1592880463',
            'coingeckoId': 'datamine',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CTPL',
            'name': 'Cultiplan',
            'address': '0xF7e40AF830a2EcbFe72C5210C1Ca272e3Aa7cA1B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15798/thumb/Cultiplan_200x200_logo.png?1621927264',
            'coingeckoId': 'cultiplan',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RUNO',
            'name': 'RUNO',
            'address': '0xF7c3ccBf8f8BE81061FaF79cAD296b3921a79eca',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/8875.png',
            'coingeckoId': null,
            'listedIn': [
                'RouterProtocol'
            ]
        },
        {
            'symbol': 'TOM',
            'name': 'TOM Finance',
            'address': '0xF7970499814654CD13Cb7B6E7634A12a7A8A9ABc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13173/thumb/TOM_logo.png?1605857896',
            'coingeckoId': 'tom-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TNB',
            'name': 'Time New Bank',
            'address': '0xF7920B0768Ecb20A123fAc32311d07D193381d6f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1265/thumb/time-new-bank.png?1547035290',
            'coingeckoId': 'time-new-bank',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MEET',
            'name': 'CoinMeet',
            'address': '0xF77f4810e7521298a6e2a04f82A6c3492706d74F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2688/thumb/coinmeet.png?1547036895',
            'coingeckoId': 'coinmeet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DMR',
            'name': 'Dreamr Platform',
            'address': '0xF74941046389c78F12fE44784C0EC9ca7CEb7dc2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18307/thumb/DMR_SYMBOL_OFFICIAL_200X200.png?1631508227',
            'coingeckoId': 'dreamr-platform-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AIR',
            'name': 'Ape In Records',
            'address': '0xF725f73CAEe250AE384ec38bB2C77C38ef2CcCeA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24194/thumb/gCZZdeR.jpeg?1646830675',
            'coingeckoId': 'ape_in_records',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STAR',
            'name': 'Starbase',
            'address': '0xF70a642bD387F94380fFb90451C2c81d4Eb82CBc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1449/thumb/starbase.png?1548610771',
            'coingeckoId': 'starbase',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FOTO',
            'name': 'UniqueOne Photo',
            'address': '0xF6eA0E60cee427B90aA36f327eE61d1d4CABC49F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14274/thumb/Symbol_Master-01_200x200_%281%29.png?1626868667',
            'coingeckoId': 'uniqueone-photo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAP',
            'name': 'SwapAll',
            'address': '0xF6Ed276a69270A895d6E419D99dcB5AAA2f3Cb4A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13014/thumb/fav.png?1604383601',
            'coingeckoId': 'swapall',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WLK',
            'name': 'Wolk',
            'address': '0xF6B55acBBC49f4524Aa48D19281A9A77c54DE10f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xf6b55acbbc49f4524aa48d19281a9a77c54de10f.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'XIDO',
            'name': 'Xido Finance',
            'address': '0xF6650117017FFD48B725B4EC5A00B414097108A7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16161/thumb/KJw4clj.png?1623141959',
            'coingeckoId': 'xido-finance',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'IBEX',
            'name': 'Impermax',
            'address': '0xF655C8567E0f213e6C634CD2A68d992152161dC6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27606/thumb/IqwOmX-c_400x400.jpeg?1664846656',
            'coingeckoId': 'impermax-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ENJ',
            'name': 'Enjin Coin',
            'address': '0xF629cBd94d3791C9250152BD8dfBDF380E2a3B9c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c.png',
            'coingeckoId': 'enjincoin',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HAY',
            'name': 'Hayfever',
            'address': '0xF6269e2e0C271fb6AF35E7f8a539EbC7155E33bb',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21063/thumb/Contagion_Crew_5-2.jpg?1651745521',
            'coingeckoId': 'hayfever',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TR3',
            'name': 'Tr3zor',
            'address': '0xF61BF4d1A948487d61b8fa63808AAC06bda55F98',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21980/thumb/9T9OoYXE_400x400.jpg?1640573690',
            'coingeckoId': 'tr3zor',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'dYel',
            'name': 'dYEL Index Token',
            'address': '0xF6134E74b7a4ee23A888436E53e77F83e62E8df8',
            'decimals': 6,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'OCT',
            'name': 'Octopus Network',
            'address': '0xF5cFBC74057C610c8EF151A439252680AC68c6DC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf5cfbc74057c610c8ef151a439252680ac68c6dc.png',
            'coingeckoId': 'octopus-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'cSAI',
            'name': 'Compound Sai',
            'address': '0xF5DCe57282A584D2746FaF1593d3121Fcac444dC',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf5dce57282a584d2746faf1593d3121fcac444dc.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'UBX',
            'name': 'UBIX Network',
            'address': '0xF5B5eFc906513b4344EbAbCF47A04901f99F09f3',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13000/thumb/UBIX.jpg?1626406542',
            'coingeckoId': 'ubix-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PDEX',
            'name': 'Polkadex',
            'address': '0xF59ae934f6fe444afC309586cC60a84a0F89Aaea',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14833/thumb/dIze5Ztl_400x400.jpg?1618610724',
            'coingeckoId': 'polkadex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IMX',
            'name': 'ImmutableX',
            'address': '0xF57e7e7C23978C3cAEC3C3548E3D615c346e79fF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf57e7e7c23978c3caec3c3548e3d615c346e79ff.png',
            'coingeckoId': 'immutable-x',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'SNN',
            'name': 'SeChain',
            'address': '0xF5717f5DF41eA67Ef67DFD3c1d02F9940bcF5d08',
            'decimals': 3,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11961/thumb/2AsGMTeI_400x400.jpg?1596453536',
            'coingeckoId': 'sechain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CGT',
            'name': 'Curio Governance',
            'address': '0xF56b164efd3CFc02BA739b719B6526A6FA1cA32a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13607/thumb/QLwpua7.png?1610273891',
            'coingeckoId': 'curio-governance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRIO',
            'name': 'Maximus TRIO',
            'address': '0xF55cD1e399e1cc3D95303048897a680be3313308',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27684/thumb/IMG_1127.PNG?1665214637',
            'coingeckoId': 'maximus-trio',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YFX',
            'name': 'Your Futures Exchange',
            'address': '0xF55a93b613D172b86c2Ba3981a849DaE2aeCDE2f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15654/thumb/yfx.PNG?1621478455',
            'coingeckoId': 'yfx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HOPR',
            'name': 'HOPR',
            'address': '0xF5581dFeFD8Fb0e4aeC526bE659CFaB1f8c781dA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf5581dfefd8fb0e4aec526be659cfab1f8c781da.png',
            'coingeckoId': 'hopr',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BOBO',
            'name': 'Bobo Cash',
            'address': '0xF53c24f7729C88C110265929c7124E6259eFccAB',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17620/thumb/bobo_coingecko.png?1628667747',
            'coingeckoId': 'bobo-cash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IDLESUSDYIELD',
            'name': 'IdleSUSD  Yield ',
            'address': '0xF52CDcD458bf455aeD77751743180eC4A595Fd3F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11934/thumb/idlesusdv3mmaxyield_32.png?1596263703',
            'coingeckoId': 'idle-susd-yield',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TZKI',
            'name': 'Tsuzuki Inu',
            'address': '0xF527d24391C767B86b8e91385e1cE9C54D230A2B',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19437/thumb/tzki.png?1635470570',
            'coingeckoId': 'tsuzuki-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CGT',
            'name': 'CACHE Gold',
            'address': '0xF5238462E7235c7B62811567E63Dd17d12C2EAA0',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf5238462e7235c7b62811567e63dd17d12c2eaa0.png',
            'coingeckoId': 'cache-gold',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ASIA',
            'name': 'Asia Coin',
            'address': '0xF519381791C03DD7666C142D4E49Fd94d3536011',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18589/thumb/Ou7mp_R1TQ5B9vsBiZ8oQnSv36M6hiA2hESxV_7YSw0.png?1632556422',
            'coingeckoId': 'asia-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FCL',
            'name': 'Fractal',
            'address': '0xF4d861575ecC9493420A3f5a14F85B13f0b50EB3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf4d861575ecc9493420a3f5a14f85b13f0b50eb3.png',
            'coingeckoId': 'fractal',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'OPM',
            'name': 'Omega Protocol Money',
            'address': '0xF4c17Bc4979c1dc7b4CA50115358Dec58C67fD9d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12337/thumb/opm-200px.png?1599136480',
            'coingeckoId': 'omega-protocol-money',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BBANK',
            'name': 'blockbank',
            'address': '0xF4b5470523cCD314C6B9dA041076e7D79E0Df267',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15081/thumb/blockbank.jpg?1667183379',
            'coingeckoId': 'blockbank',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'WHEN',
            'name': 'WhenHub',
            'address': '0xF4FE95603881D0e07954fD7605E0e9a916e42C44',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4136/thumb/when.png?1547039367',
            'coingeckoId': 'when-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABL',
            'name': 'Babylon Finance',
            'address': '0xF4Dc48D260C93ad6a96c5Ce563E70CA578987c74',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20049/thumb/favicon-260x260.png?1636426809',
            'coingeckoId': 'babylon-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-1617-SAVERS',
            'name': 'RealT   1617 S Avers Ave  Chicago  IL 6',
            'address': '0xF4657AB08681214Bcb1893aa8E9C7613459250ec',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16675/thumb/1617-S-Avers-hero-1.jpg?1624628860',
            'coingeckoId': '1617-s-avers',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MNMC',
            'name': 'MNMCoin',
            'address': '0xF45091f25d374BbE956c0bb64bB85e02D07Aa741',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11163/thumb/MNMC.png?1589280919',
            'coingeckoId': 'mnmcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTL',
            'name': 'Metal DAO',
            'address': '0xF433089366899D83a9f26A773D59ec7eCF30355e',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf433089366899d83a9f26a773d59ec7ecf30355e.png',
            'coingeckoId': 'metal',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'CRC',
            'name': 'CRYCASH',
            'address': '0xF41e5Fbc2F6Aac200Dd8619E121CE1f05D150077',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1945/thumb/crycash.png?1547790664',
            'coingeckoId': 'crycash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SNC',
            'name': 'SunContract',
            'address': '0xF4134146AF2d511Dd5EA8cDB1C4AC88C57D60404',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1166/thumb/suncontract.png?1548611174',
            'coingeckoId': 'suncontract',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VRA',
            'name': 'Verasity',
            'address': '0xF411903cbC70a74d22900a5DE66A2dda66507255',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf411903cbc70a74d22900a5de66a2dda66507255.png',
            'coingeckoId': 'verasity',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'VISION',
            'name': 'APY vision',
            'address': '0xF406F7A9046793267bc276908778B29563323996',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf406f7a9046793267bc276908778b29563323996.png',
            'coingeckoId': 'apy-vision',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ETM',
            'name': 'EtherSmart',
            'address': '0xF3eb8B90C763b8B2B53E7819ac27eca8f94C8Ec2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18019/thumb/ethersmart.PNG?1630281887',
            'coingeckoId': 'ethersmart',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PAT',
            'name': 'Patron',
            'address': '0xF3b3Cad094B89392fcE5faFD40bC03b80F2Bc624',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3169/thumb/patron.png?1548330790',
            'coingeckoId': 'patron',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ESTI',
            'name': 'Easticoin',
            'address': '0xF3924dF14812B3D3dB29124AA9DA4353Ed5e04A3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12988/thumb/EST2.png?1604093746',
            'coingeckoId': 'easticoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IDLEUSDTYIELD',
            'name': 'IdleUSDT  Yield ',
            'address': '0xF34842d05A1c888Ca02769A633DF37177415C2f8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11933/thumb/idleusdtv3maxyield_32.png?1596263688',
            'coingeckoId': 'idle-usdt-yield',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INU',
            'name': 'HachikoInu',
            'address': '0xF32aa187d5Bc16A2C02A6aFb7Df1459d0D107574',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf32aa187d5bc16a2c02a6afb7df1459d0d107574.png',
            'coingeckoId': 'hachikoinu',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MKCY',
            'name': 'Markaccy',
            'address': '0xF3281c539716a08c754EC4C8F2B4cEe0faB64BB9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12522/thumb/e2eLPzTF_400x400.png?1600499534',
            'coingeckoId': 'markaccy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POWER',
            'name': 'UniPower',
            'address': '0xF2f9A7e93f845b3ce154EfbeB64fB9346FCCE509',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf2f9a7e93f845b3ce154efbeb64fb9346fcce509.png',
            'coingeckoId': 'unipower',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'DMST',
            'name': 'DMScript',
            'address': '0xF29992D7b589A0A6bD2de7Be29a97A6EB73EaF85',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xf29992d7b589a0a6bd2de7be29a97a6eb73eaf85.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'GROW',
            'name': 'Grow',
            'address': '0xF268fE3d6909508ddA90DccF2bf69050D19f4CdD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16049/thumb/grow-logo-200x200.png?1622691274',
            'coingeckoId': 'grow-token-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BASIC',
            'name': 'BASIC',
            'address': '0xF25c91C87e0B1fd9B4064Af0F427157AaB0193A7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11050/thumb/unTgJN6U_400x400.jpg?1587540882',
            'coingeckoId': 'basic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AXSUSHI',
            'name': 'Aave XSUSHI',
            'address': '0xF256CC7847E919FAc9B808cC216cAc87CCF2f47a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17256/thumb/aXSUSHI_2x.png?1626957111',
            'coingeckoId': 'aave-xsushi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SDBAL',
            'name': 'Stake DAO Balancer',
            'address': '0xF24d8651578a55b0C119B9910759a351A3458895',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26224/thumb/6uqFPXny_400x400.jpg?1656642803',
            'coingeckoId': 'stake-dao-balancer',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-15778-MANOR',
            'name': 'RealT   15778 Manor St  Detroit  MI 482',
            'address': '0xF23B80216a10E6f0c0D3b5AD5C9349e9425cAd40',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16689/thumb/15778-manor-hero-2.jpeg?1624636173',
            'coingeckoId': '15778-manor',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KAIBA',
            'name': 'Kaiba Defi',
            'address': '0xF2210f65235c2FB391aB8650520237E6378e5C5A',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19990/thumb/2Le4vyc.png?1646120236',
            'coingeckoId': 'kaiba-defi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TELE',
            'name': 'Telefy',
            'address': '0xF1e345Ea7c33fd6c05F5512a780Eb5839EE31674',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26485/thumb/WhatsApp_Image_2022-07-20_at_2.51.42_AM.jpeg?1658281111',
            'coingeckoId': 'telefy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ENV',
            'name': 'Envoy',
            'address': '0xF1d1A5306daaE314AF6c5d027a492B313e07e1A0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19631/thumb/env.png?1638148461',
            'coingeckoId': 'envoy-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AVINOC',
            'name': 'AVINOC',
            'address': '0xF1cA9cb74685755965c7458528A36934Df52A3EF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf1ca9cb74685755965c7458528a36934df52a3ef.png',
            'coingeckoId': 'avinoc',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'xPREMIA',
            'name': 'Staked Premia',
            'address': '0xF1bB87563A122211d40d393eBf1c633c330377F9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-25097-ANDOV',
            'name': 'RealT   25097 Andover Dr  Dearborn Heig',
            'address': '0xF18CfFB528eca0ea31D1d6b28BC80d2eca34D14D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16698/thumb/Andover-25097.jpeg?1624641026',
            'coingeckoId': '25097-andover',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BICO',
            'name': 'Biconomy',
            'address': '0xF17e65822b568B3903685a7c9F496CF7656Cc6C2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1639019133083_027415277814302863.png',
            'coingeckoId': 'biconomy',
            'listedIn': [
                'coingecko',
                'uniswap',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'UniLINKETH',
            'name': 'Uniswap LINK LP',
            'address': '0xF173214C720f58E03e194085B1DB28B50aCDeeaD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'ipfs://QmQWb2cb9QZbTeMTtoWzUpJGNXcZiGXTygbRLKHNNwhk4Y',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'IBG',
            'name': 'iBG Finance  ETH ',
            'address': '0xF16CD087e1C2C747b2bDF6f9A5498AA400D99C24',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18756/thumb/ibg.png?1633337424',
            'coingeckoId': 'ibg-eth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TOSHI',
            'name': 'Toshimon',
            'address': '0xF136D7b0B7AE5b86D21E7B78DFA95375a7360f19',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14019/thumb/9E1K1PIZ_400x400.png?1613716582',
            'coingeckoId': 'toshi-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HEDG',
            'name': 'HedgeTrade',
            'address': '0xF1290473E210b2108A85237fbCd7b6eb42Cc654F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf1290473e210b2108a85237fbcd7b6eb42cc654f.png',
            'coingeckoId': 'hedgetrade',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'WSTR',
            'name': 'Wrapped Star',
            'address': '0xF0dC76C22139ab22618dDFb498BE1283254612B1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23972/thumb/wstr.png?1645870711',
            'coingeckoId': 'wrapped-star',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NTX',
            'name': 'NuNet',
            'address': '0xF0d33BeDa4d734C72684b5f9abBEbf715D0a7935',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20950/thumb/8Zb2W2Wi_400x400.png?1638137477',
            'coingeckoId': 'nunet',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'AXIS',
            'name': 'AXIS',
            'address': '0xF0c5831EC3Da15f3696B4DAd8B21c7Ce2f007f28',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16972/thumb/lUeFYUeu_400x400.png?1625815058',
            'coingeckoId': 'axis-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PAMP',
            'name': 'Pamp Network',
            'address': '0xF0FAC7104aAC544e4a7CE1A55ADF2B5a25c65bD1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xf0fac7104aac544e4a7ce1a55adf2b5a25c65bd1.png',
            'coingeckoId': 'pamp-network',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ENGN',
            'name': 'Engine Token',
            'address': '0xF0D243ee0d7517B1FCf2445AB624DEd2DBe3BA54',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20492/thumb/200x200_%2842%29.png?1637129962',
            'coingeckoId': 'engine-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BRTR',
            'name': 'Barter',
            'address': '0xF0ACF8949e705E0ebb6CB42c2164B0B986454223',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13401/thumb/BRTR.png?1612843022',
            'coingeckoId': 'barter',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZERO',
            'name': '0 exchange',
            'address': '0xF0939011a9bb95c3B791f0cb546377Ed2693a574',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf0939011a9bb95c3b791f0cb546377ed2693a574.png',
            'coingeckoId': 'zero-exchange',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'JIC',
            'name': 'JoorsChain',
            'address': '0xF07376F0E3bfF57116eD62e5e8e2Bb1341E4B4C2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7128/thumb/H0kIwyNs_400x400.jpg?1547043626',
            'coingeckoId': 'joorschain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SALE',
            'name': 'DxSale Network',
            'address': '0xF063fE1aB7a291c5d06a86e14730b00BF24cB589',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xf063fe1ab7a291c5d06a86e14730b00bf24cb589.png',
            'coingeckoId': 'dxsale-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'fiUSDC',
            'name': 'bZx USDC iToken',
            'address': '0xF013406A0B1d544238083DF0B93ad0d2cBE0f65f',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xf013406a0b1d544238083df0b93ad0d2cbe0f65f.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'TARA',
            'name': 'Taraxa',
            'address': '0xF001937650bb4f62b57521824B2c20f5b91bEa05',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4372/thumb/CPuCDZX.jpg?1616391360',
            'coingeckoId': 'taraxa',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METRIC',
            'name': 'MetricExchange',
            'address': '0xEfc1C73A3D8728Dc4Cf2A18ac5705FE93E5914AC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12664/thumb/metric_exchange_logo.png?1601453711',
            'coingeckoId': 'metric-exchange',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DACXI',
            'name': 'Dacxi',
            'address': '0xEfaB7248D36585e2340E5d25F8a8D243E6e3193F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4466/thumb/dacxi.png?1639042471',
            'coingeckoId': 'dacxi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZANTEPAY',
            'name': 'Zantepay',
            'address': '0xEfFeA57067E02999fDCd0Bb45c0f1071a29472D9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7122/thumb/984cSZaC_400x400.jpg?1547043619',
            'coingeckoId': 'zantepay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DPX',
            'name': 'Dopex',
            'address': '0xEec2bE5c91ae7f8a338e1e5f3b5DE49d07AfdC81',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/11188.png',
            'coingeckoId': 'dopex',
            'listedIn': [
                'coingecko',
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'ETHV',
            'name': 'Ethverse',
            'address': '0xEeEeeeeEe2aF8D0e1940679860398308e0eF24d6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xeeeeeeeee2af8d0e1940679860398308e0ef24d6.png',
            'coingeckoId': 'ethverse',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-20200-LESUR',
            'name': 'RealT   20200 Lesure St  Detroit  MI 48',
            'address': '0xEeDc2F5F4D1226759B1AcF9EfA23a99661De6663',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16702/thumb/20200-Lesure-003.jpeg?1624643190',
            'coingeckoId': '20200-lesure',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FT',
            'name': 'Fracton Protocol',
            'address': '0xEe9E7Bb7E55bBc86414047B61D65c9C0d91fFBd0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26809/thumb/CtH9UlRl_400x400.jpeg?1660227771',
            'coingeckoId': 'fracton-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AFIN',
            'name': 'Asian Fintech',
            'address': '0xEe9E5eFF401ee921b138490d00CA8D1F13f67A72',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7519/thumb/Afinlogo-200x200.png?1630048356',
            'coingeckoId': 'afin-coin',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'O3',
            'name': 'O3 Swap',
            'address': '0xEe9801669C6138E84bD50dEB500827b776777d28',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xee9801669c6138e84bd50deb500827b776777d28.png',
            'coingeckoId': 'o3-swap',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-12334-LANSD',
            'name': 'RealT   12334 Lansdowne Street  Detroit',
            'address': '0xEe2f2212a64Ec3f6BC0F7580e10c53CB38B57508',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16692/thumb/Landsdowne-hero.jpeg?1624638212',
            'coingeckoId': '12334-lansdowne',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LSV',
            'name': 'Litecoin SV',
            'address': '0xEe059F0ca1507e4E20C689b20CFf71B5E924f7bd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11100/thumb/1GyOsGG.png?1588749283',
            'coingeckoId': 'litecoin-sv',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IHT',
            'name': 'IHT Real Estate Protocol',
            'address': '0xEda8B016efA8b1161208Cf041cD86972eeE0F31E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3158/thumb/iht-real-estate-protocol.png?1547744404',
            'coingeckoId': 'iht-real-estate-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DMG',
            'name': 'DMM  Governance',
            'address': '0xEd91879919B71bB6905f23af0A68d231EcF87b14',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xed91879919b71bb6905f23af0a68d231ecf87b14.png',
            'coingeckoId': 'dmm-governance',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'WARP',
            'name': 'Warp Finance',
            'address': '0xEd40834A13129509A89be39a9bE9C0E96A0DDd71',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xed40834a13129509a89be39a9be9c0e96a0ddd71.png',
            'coingeckoId': 'warp-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'VDR',
            'name': 'Vodra',
            'address': '0xEd3D4e446A96dC3b181B64B75c3c70dA41dC3CBe',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19338/thumb/vdr.png?1635832496',
            'coingeckoId': 'vodra',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FDT',
            'name': 'Fiat DAO',
            'address': '0xEd1480d12bE41d92F36f5f7bDd88212E381A3677',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20388/thumb/MLamPGZI_400x400.jpg?1636960965',
            'coingeckoId': 'fiat-dao-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HEX2T',
            'name': 'HEX2T',
            'address': '0xEd1199093b1aBd07a368Dd1C0Cdc77D8517BA2A0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xed1199093b1abd07a368dd1c0cdc77d8517ba2a0.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ACH',
            'name': 'Alchemy Pay',
            'address': '0xEd04915c23f00A313a544955524EB7DBD823143d',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12390/thumb/ACH_%281%29.png?1599691266',
            'coingeckoId': 'alchemy-pay',
            'listedIn': [
                'coingecko',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SHROOM',
            'name': 'Niftyx Protocol',
            'address': '0xEd0439EACf4c4965AE4613D77a5C2Efe10e5f183',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xed0439eacf4c4965ae4613d77a5c2efe10e5f183.png',
            'coingeckoId': 'shroom-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'RPC',
            'name': 'Ripio Coin',
            'address': '0xEd025A9Fe4b30bcd68460BCA42583090c2266468',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24901/thumb/624e429cffe3fe0001456cb8_01_rpc-icon.png?1649324477',
            'coingeckoId': 'ripio-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TT',
            'name': 'Trillion',
            'address': '0xEcacaB6725aC1711d97E55DF35d525b863B8B9f7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17216/thumb/EC65-CF15-3575-41-C7-8-D2-F-0-B2-FDC82-DF96.jpg?1626877437',
            'coingeckoId': 'trillion',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'oneETH',
            'name': 'oneETH',
            'address': '0xEc0d77a58528a218cBf41Fa6E1585c8D7A085868',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/oneeth.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'JT',
            'name': 'Jubi Token',
            'address': '0xEb7355C2f217b3485a591332Fe13C8c5A76A581D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10994/thumb/Af5MFcVY_400x400.jpg?1586998222',
            'coingeckoId': 'jubi-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOC',
            'name': 'All Sports',
            'address': '0xEb6026D3BEAA308D5822C44cDd2Ca8c7714237EC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25629/thumb/auC_SL79_400x400.jpg?1652863712',
            'coingeckoId': 'all-sports-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOOMI',
            'name': 'Loomi',
            'address': '0xEb57Bf569Ad976974C1F861a5923A59F40222451',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22919/thumb/C8miy1Qi_400x400.jpg?1643000828',
            'coingeckoId': 'loomi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHUMO',
            'name': 'Shumo',
            'address': '0xEaa2C985abF14Ac850F6614faebd6E4436BeA65f',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23842/thumb/XKGVYIc.png?1645517676',
            'coingeckoId': 'shumo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CYCE',
            'name': 'Crypto Carbon Energy',
            'address': '0xEaDD9B69F96140283F9fF75DA5FD33bcF54E6296',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17806/thumb/cyceicon.png?1629464571',
            'coingeckoId': 'crypto-carbon-energy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MFI',
            'name': 'MetaFinance',
            'address': '0xEaDC218Ac4cB7895a5A56e6484646b48f841C45a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17365/thumb/meta.PNG?1627435447',
            'coingeckoId': 'metafinance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EASE',
            'name': 'EASE',
            'address': '0xEa5eDef1287AfDF9Eb8A46f9773AbFc10820c61c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27228/thumb/ease_symbol-200x200.png?1664098304',
            'coingeckoId': 'ease',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SURF',
            'name': 'Surf Finance',
            'address': '0xEa319e87Cf06203DAe107Dd8E5672175e3Ee976c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xea319e87cf06203dae107dd8e5672175e3ee976c.png',
            'coingeckoId': 'surf-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'wanWASP',
            'name': 'wanWASP',
            'address': '0xEF5C6A88710a3C857105058F947D249bc490909D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/wanwasp.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'PINU',
            'name': 'Pirate Inu',
            'address': '0xEF5B32486ed432B804a51D129f4D2fbdf18057eC',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19959/thumb/pinu.png?1636341206',
            'coingeckoId': 'pirate-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KFT',
            'name': 'Knit Finance',
            'address': '0xEF53462838000184F35f7D991452e5f25110b207',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15632/thumb/knit.jpg?1621396114',
            'coingeckoId': 'knit-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOTX',
            'name': 'BOTXCOIN',
            'address': '0xEF19F4E48830093Ce5bC8b3Ff7f903A0AE3E9Fa1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6827/thumb/botx.jpg?1548317786',
            'coingeckoId': 'botxcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KPETS',
            'name': 'KryptoPets',
            'address': '0xEEf37d7bD4Ae03fD8ee1C44491987e422b53Ed25',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23337/thumb/kyTyD2Fi_400x400.jpg?1643882890',
            'coingeckoId': 'kryptopets',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EVY',
            'name': 'EveryCoin',
            'address': '0xEEd3aE7b0F8b5B9BB8C035A9941382B1822671CD',
            'decimals': 12,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5629/thumb/TutkUC1x_400x400_%281%29.jpg?1547447105',
            'coingeckoId': 'everycoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HEZ',
            'name': 'Hermez Network',
            'address': '0xEEF9f339514298C6A857EfCfC1A762aF84438dEE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xeef9f339514298c6a857efcfc1a762af84438dee.png',
            'coingeckoId': 'hermez-network-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'CEUR',
            'name': 'Celo Euro  Wormhole ',
            'address': '0xEE586e7Eaad39207F0549BC65f19e336942C992f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27870/thumb/5ETdHxeE_400x400.jpeg?1666164533',
            'coingeckoId': 'celo-euro-wormhole',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORAO',
            'name': 'ORAO Network',
            'address': '0xEE1CeA7665bA7aa97e982EdeaeCb26B59a04d035',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15189/thumb/orao.PNG?1620078596',
            'coingeckoId': 'orao-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DG',
            'name': 'Decentral Games  Old ',
            'address': '0xEE06A81a695750E71a662B51066F2c74CF4478a0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xEE06A81a695750E71a662B51066F2c74CF4478a0/logo.png',
            'coingeckoId': 'decentral-games-old',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'MARS',
            'name': 'Mars Network',
            'address': '0xEDD8DA5C20EB014E550008DF3304213ddE5e29F0',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12828/thumb/Mars-logo-2.jpg?1602832489',
            'coingeckoId': 'mar-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZIPT',
            'name': 'Zippie',
            'address': '0xEDD7c94FD7B4971b916d15067Bc454b9E1bAD980',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/2724.png',
            'coingeckoId': 'zippie',
            'listedIn': [
                'coingecko',
                'openocean',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'WAMPL',
            'name': 'Wrapped Ampleforth',
            'address': '0xEDB171C18cE90B633DB442f2A6F72874093b49Ef',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20825/thumb/photo_2021-11-25_02-05-11.jpg?1637811951',
            'coingeckoId': 'wrapped-ampleforth',
            'listedIn': [
                'coingecko',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'CWV',
            'name': 'CryptoWorld VIP',
            'address': '0xED494c9e2F8E34e53BDD0EA9B4d80305cb15C5c2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6570/thumb/s-ZJaJdw_400x400.jpg?1547042779',
            'coingeckoId': 'cryptoworld-vip',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARCX_OLD',
            'name': 'ARC Governance Token (Old)',
            'address': '0xED30Dd7E50EdF3581AD970eFC5D9379Ce2614AdB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xed30dd7e50edf3581ad970efc5d9379ce2614adb.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'FX1',
            'name': 'FANZY',
            'address': '0xED0e2041BFb5a426e5ED426A73765624E08BbB75',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11117/thumb/FX.png?1604900016',
            'coingeckoId': 'fanzy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YVS',
            'name': 'YVS Finance',
            'address': '0xEC681F28f4561c2a9534799AA38E0d36A83Cf478',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xec681f28f4561c2a9534799aa38e0d36a83cf478.png',
            'coingeckoId': 'yvs-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'IWBTC',
            'name': 'Instadapp WBTC',
            'address': '0xEC363faa5c4dd0e51f3D9B5d0101263760E7cdeB',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25784/thumb/iWBTC-100x100.png?1654055142',
            'coingeckoId': 'instadapp-wbtc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PRE',
            'name': 'Presearch',
            'address': '0xEC213F83defB583af3A000B1c0ada660b1902A0F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xec213f83defb583af3a000b1c0ada660b1902a0f.png',
            'coingeckoId': 'presearch',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'NPAS',
            'name': 'New Paradigm Assets Solution',
            'address': '0xEBe807bFDf8b2092E36e964C75463A8Aaa642139',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24660/thumb/S3i9bI6n_400x400.jpg?1648529725',
            'coingeckoId': 'new-paradigm-assets-solution',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ID',
            'name': 'Everest',
            'address': '0xEBd9D99A3982d547C5Bb4DB7E3b1F9F14b67Eb83',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xebd9d99a3982d547c5bb4db7e3b1f9f14b67eb83.png',
            'coingeckoId': 'everid',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ORS',
            'name': 'Origin Sport',
            'address': '0xEB9A4B185816C354dB92DB09cC3B50bE60b901b6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4646/thumb/origin-sport-logo.png?1547039912',
            'coingeckoId': 'origin-sport',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NBU',
            'name': 'Nimbus',
            'address': '0xEB58343b36C7528F23CAAe63a150240241310049',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xeb58343b36c7528f23caae63a150240241310049.png',
            'coingeckoId': 'nimbus',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BSI',
            'name': 'Bali Social Integrated',
            'address': '0xEB50455805ebF8396d9177BBC4A371A376D00ECC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19589/thumb/bsi.PNG?1635473270',
            'coingeckoId': 'bali-social-integrated',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'mooCurveFsUSD',
            'name': 'Moo Curve f-sUSD',
            'address': '0xEB4c3961f9c06307aF0e6Bc195204352bD3DbfC6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'RENBTC',
            'name': 'renBTC',
            'address': '0xEB4C2781e4ebA804CE9a9803C67d0893436bB27D',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xeb4c2781e4eba804ce9a9803c67d0893436bb27d.png',
            'coingeckoId': 'renbtc',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'CNFI',
            'name': 'Connect Financial',
            'address': '0xEABB8996eA1662cAd2f7fB715127852cd3262Ae9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13592/thumb/cf-logo-iconic-black.png?1644479524',
            'coingeckoId': 'connect-financial',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOZOX',
            'name': 'MozoX',
            'address': '0xEA4931BfCf3260da6DBF0550e27f5C214E3c268b',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10798/thumb/Wd7sb8F7VxkzGIkjbIHd1KAaguILwTMnafTK5-aJSWnOylO0D9otRGafiDdS9WBzxKUvqbLeguGq5iTdNdYJp3QJvGI-l_AsXscfjJGiTmn4Bw4G5bi_902OtUeKyQwvGEukbFnNpeoDyCyQ3tAW5-37sIjjj9WcgL3u3gLgdDSne6h0aRG7sBXl1AuYMQb9W_JnnplkyBoA37ZrWMfZc.jpg?1583724469',
            'coingeckoId': 'mozox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAYC',
            'name': 'BAYC Vault  NFTX ',
            'address': '0xEA47B64e1BFCCb773A0420247C0aa0a3C1D2E5C5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17035/thumb/BAYC.png?1626142944',
            'coingeckoId': 'bayc-vault-nftx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FUEL',
            'name': 'Etherparty',
            'address': '0xEA38eAa3C86c8F9B751533Ba2E562deb9acDED40',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1082/thumb/etherparty.png?1547394573',
            'coingeckoId': 'etherparty',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QKC',
            'name': 'QuarkChain',
            'address': '0xEA26c4aC16D4a5A106820BC8AEE85fd0b7b2b664',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xea26c4ac16d4a5a106820bc8aee85fd0b7b2b664.png',
            'coingeckoId': 'quark-chain',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'MOD',
            'name': 'Modefi',
            'address': '0xEA1ea0972fa092dd463f2968F9bB51Cc4c981D71',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xea1ea0972fa092dd463f2968f9bb51cc4c981d71.png',
            'coingeckoId': 'modefi',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'UFR',
            'name': 'Upfiring',
            'address': '0xEA097A2b1dB00627B2Fa17460Ad260c016016977',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1170/thumb/upfiring.png?1548759693',
            'coingeckoId': 'upfiring',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QTO',
            'name': 'Qtoken',
            'address': '0xEA01906843Ea8D910658a2c485ffCe7C104AB2b6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26024/thumb/8QyOrTCm_400x400.jpg?1655332588',
            'coingeckoId': 'qtoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STRIP',
            'name': 'StripCoin',
            'address': '0xE9cB6838902CCF711f16a9EA5A1170F8e9853C02',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20242/thumb/um7swakg.png?1636696116',
            'coingeckoId': 'stripcoin',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'CAMP',
            'name': 'Camp',
            'address': '0xE9E73E1aE76D17A16cC53E3e87a9a7dA78834d37',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13338/thumb/CampGlobal_256256.png?1607576849',
            'coingeckoId': 'camp',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NOW',
            'name': 'ChangeNOW',
            'address': '0xE9A95d175a5f4C9369f3b74222402eB1b837693b',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xe9a95d175a5f4c9369f3b74222402eb1b837693b.png',
            'coingeckoId': 'changenow',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'UMINT',
            'name': 'YouMinter',
            'address': '0xE99De844EF3Ef72806cf006224EF3b813e82662f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24564/thumb/xXlHazn__400x400.jpg?1648190269',
            'coingeckoId': 'youminter',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MYCE',
            'name': 'MY Ceremonial Event',
            'address': '0xE9966C1184f8552Fcb16F65addba9Dd08fe8f4EA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19182/thumb/BuoVrgpA_400x400.png?1634613857',
            'coingeckoId': 'my-ceremonial-event',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AETHC',
            'name': 'Ankr Reward Bearing Staked ETH',
            'address': '0xE95A203B1a91a908F9B9CE46459d101078c2c3cb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xe95a203b1a91a908f9b9ce46459d101078c2c3cb.png',
            'coingeckoId': 'ankreth',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ZNT',
            'name': 'Zenswap Network ZNT',
            'address': '0xE95990825AAB1a7f0Af4cc648f76a3Bcc99F25B2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6452/thumb/3CGDn4e.png?1547042648',
            'coingeckoId': 'zenswap-network-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RNBW',
            'name': 'HaloDAO',
            'address': '0xE94B97b6b43639E238c851A7e693F50033EfD75C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xe94b97b6b43639e238c851a7e693f50033efd75c.png',
            'coingeckoId': 'rainbow-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'OG',
            'name': 'Oogear',
            'address': '0xE89C20096b636fFec9fd26d1a623F42A33eaD309',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23970/thumb/og.png?1645869076',
            'coingeckoId': 'oogear',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ULT',
            'name': 'Ultiledger',
            'address': '0xE884cc2795b9c45bEeac0607DA9539Fd571cCF85',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6747/thumb/Ultiledger.jpeg?1547042996',
            'coingeckoId': 'ultiledger',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RATING',
            'name': 'DPRating',
            'address': '0xE8663A64A96169ff4d95b4299E7ae9a76b905B31',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5383/thumb/dprating.png?1547224023',
            'coingeckoId': 'dprating',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DUET',
            'name': 'Duet Governance Token',
            'address': '0xE8521b61f64Fc45A0bC3db36D2A524fe61a69b52',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ARTIC',
            'name': 'ARTIC Foundation',
            'address': '0xE83D5FB2c60B3A2597452e248CF7b2f52a7E731e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24279/thumb/Artic_logo_02.png?1647181452',
            'coingeckoId': 'artic-foundation',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DAY',
            'name': 'Chronologic',
            'address': '0xE814aeE960a85208C3dB542C53E7D4a6C8D5f60F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/951/thumb/Chronologic-network.png?1547034815',
            'coingeckoId': 'chronologic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JPEG',
            'name': 'JPEG d',
            'address': '0xE80C0cd204D654CEbe8dd64A4857cAb6Be8345a3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24025/small/et_43CNi_400x400.jpg?1646100264',
            'coingeckoId': 'jpeg-d',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'xyfinance'
            ]
        },
        {
            'symbol': 'DWEB',
            'name': 'DecentraWeb',
            'address': '0xE7f58A92476056627f9FdB92286778aBd83b285F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xe7f58a92476056627f9fdb92286778abd83b285f.png',
            'coingeckoId': 'decentraweb',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'DOGEP',
            'name': 'Doge Protocol',
            'address': '0xE7eaec9Bca79d537539C00C58Ae93117fB7280b9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23689/thumb/LjhKBldd_400x400.png?1645079751',
            'coingeckoId': 'doge-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WAIFU',
            'name': 'WAIFU Vault  NFTX ',
            'address': '0xE7F4c89032A2488D327323548AB0459676269331',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17045/thumb/waifu.png?1626147639',
            'coingeckoId': 'waifu-vault-nftx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSDS',
            'name': 'Basis Dollar Share',
            'address': '0xE7C9C188138f7D70945D420d75F8Ca7d8ab9c700',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13411/thumb/bshare.png?1608267551',
            'coingeckoId': 'basis-dollar-share',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-4852-4854-W',
            'name': 'RealT   4852 4854 W Cortez St  Chicago ',
            'address': '0xE7B6dE709fFC3BD237c2f2c800E1002F97A760F3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16670/thumb/Cortez-facade-1.jpg?1624627398',
            'coingeckoId': '4852-4854-w-cortez',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLES',
            'name': 'Blind Boxes',
            'address': '0xE796d6ca1ceb1b022EcE5296226BF784110031Cd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xe796d6ca1ceb1b022ece5296226bf784110031cd.png',
            'coingeckoId': 'blind-boxes',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'CWAP',
            'name': 'DeFIRE',
            'address': '0xE74dC43867E0cbEB208F1a012fc60DcBbF0E3044',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15722/thumb/defire.PNG?1621635373',
            'coingeckoId': 'defire',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RZRV',
            'name': 'Rezerve',
            'address': '0xE70b94C2320fB0289Db0Cac0cCBD60dd8F51ff89',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://api.rubic.exchange/assets/ethereum/0xe70b94c2320fb0289db0cac0ccbd60dd8f51ff89/logo.png',
            'coingeckoId': 'rezerve',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'WILLIE',
            'name': 'World Cup Willie',
            'address': '0xE7094Edf87a4e307590D011FB2dB50219131d9ed',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27948/thumb/YiFf3bWN_400x400.jpg?1666611450',
            'coingeckoId': 'world-cup-willie',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PKEX',
            'name': 'PolkaEx',
            'address': '0xE6f143a0e0A8f24F6294CE3432eA10FaD0206920',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18616/thumb/1024-1024-02.png?1632698540',
            'coingeckoId': 'polkaex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KUKY',
            'name': 'Kuky Star',
            'address': '0xE6b7743e2B9Aa2d0A9B163C4e69186ABb57817D9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7465/thumb/images_%283%29.jpeg?1547622295',
            'coingeckoId': 'kuky-star',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFEEARTH',
            'name': 'SafeEarth',
            'address': '0xE6F1966d04CfcB9cd1B1dC4E8256D8b501b11CbA',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15449/thumb/SafeEarthLogo2000.png?1620833647',
            'coingeckoId': 'safeearth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FNB',
            'name': 'Finexbox',
            'address': '0xE6D2c3cB986db66818c14C7032DB05D1d2A6ee74',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8419/thumb/p1WP-viw_400x400.jpg?1558395663',
            'coingeckoId': 'finexbox-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KBTC',
            'name': 'Klondike BTC',
            'address': '0xE6C3502997f97F9BDe34CB165fBce191065E068f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xe6c3502997f97f9bde34cb165fbce191065e068f.png',
            'coingeckoId': 'klondike-btc',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ABLOCK',
            'name': 'ANY Blocknet',
            'address': '0xE692c8D72bd4aC7764090d54842a305546dd1dE5',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14869/thumb/blocknet.jpg?1618816800',
            'coingeckoId': 'any-blocknet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WATT',
            'name': 'WATTTON',
            'address': '0xE67F943af5Eb6051ef56f05979cc30B732717fa6',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27215/thumb/CG_WATT_LOGO.png?1662611245',
            'coingeckoId': 'wattton',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTO',
            'name': 'Merchant',
            'address': '0xE66b3AA360bB78468c00Bebe163630269DB3324F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17157/thumb/mto.PNG?1626672046',
            'coingeckoId': 'merchant-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GT',
            'name': 'Gate',
            'address': '0xE66747a101bFF2dBA3697199DCcE5b743b454759',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8183/thumb/gt.png?1556085624',
            'coingeckoId': 'gatechain-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KFC',
            'name': 'Chicken',
            'address': '0xE63684BcF2987892CEfB4caA79BD21b34e98A291',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12569/thumb/download.png?1600840301',
            'coingeckoId': 'chicken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'yUSDTv3',
            'name': 'iearn USDT v3',
            'address': '0xE6354ed5bC4b393a5Aad09f21c46E101e692d447',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xe6354ed5bc4b393a5aad09f21c46e101e692d447.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'COIN',
            'name': 'Coin',
            'address': '0xE61fDAF474Fac07063f2234Fb9e60C1163Cfa850',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2394/thumb/coindefi.png?1629442922',
            'coingeckoId': 'coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HEY',
            'name': 'Hey',
            'address': '0xE61F6e39711cEc14f8D6c637c2f4568bAA9FF7Ee',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28092/thumb/HEY-logo-2-1024x1024.png?1667534940',
            'coingeckoId': 'hey',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SGLDM',
            'name': 'GoldMint Shares',
            'address': '0xE61776305003d700FBC8318da97806032a3b08ED',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'LLAND',
            'name': 'Lyfe Land',
            'address': '0xE5bF6790D138B154f1DF3Db8d245bE46A5D05eE4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14940/thumb/LLAND-Logo-200.png?1619083033',
            'coingeckoId': 'lyfe-land',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RAE',
            'name': 'Receive Access Ecosystem',
            'address': '0xE5a3229CCb22b6484594973A03a3851dCd948756',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xe5a3229ccb22b6484594973a03a3851dcd948756.png',
            'coingeckoId': 'rae-token',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'FRM',
            'name': 'Ferrum Network',
            'address': '0xE5CAeF4Af8780E59Df925470b050Fb23C43CA68C',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xe5caef4af8780e59df925470b050fb23c43ca68c.png',
            'coingeckoId': 'ferrum-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ZEON',
            'name': 'ZEON Network',
            'address': '0xE5B826Ca2Ca02F09c1725e9bd98d9a8874C30532',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4247/thumb/XZqXYc2j_400x400.jpg?1547039580',
            'coingeckoId': 'zeon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DPAY',
            'name': 'Devour',
            'address': '0xE5A733681bbE6cd8c764Bb8078ef8E13a576Dd78',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27191/thumb/dpay.png?1665907874',
            'coingeckoId': 'devour-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PURR',
            'name': 'PURR Vault  NFTX ',
            'address': '0xE581F272706581F9Dcc362dF3C7934E99192c492',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18329/thumb/purr.png?1631557276',
            'coingeckoId': 'purr-vault-nftx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTCBR',
            'name': 'Bitcoin BR',
            'address': '0xE57425F1598f9b0d6219706b77f4b3DA573a3695',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21205/thumb/btcbr.png?1661666691',
            'coingeckoId': 'bitcoin-br',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AT',
            'name': 'Artfinity',
            'address': '0xE54B3458C47E44C37a267E7C633AFEF88287C294',
            'decimals': 5,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8339/thumb/artfinity.png?1557604049',
            'coingeckoId': 'artfinity-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FAITH',
            'name': 'FaithCoin',
            'address': '0xE531642e9bb5d027E9C20E03284287B97919a9a5',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3648/thumb/Y4u0Cl8.png?1547038612',
            'coingeckoId': 'faithcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RTK',
            'name': 'Three Kingdoms',
            'address': '0xE529b502fEc3Ff0Ea86fE63dA505ec62Fdf4F387',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25987/thumb/un_8XpAR_400x400.jpg?1655178518',
            'coingeckoId': 'three-kingdoms',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSP',
            'name': 'BallSwap',
            'address': '0xE51b8Ab09008285a0380Dd2680cD9Dd5e13924D3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14050/thumb/bsp.png?1637556507',
            'coingeckoId': 'ballswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HBOT',
            'name': 'Hummingbot',
            'address': '0xE5097D9baeAFB89f9bcB78C9290d545dB5f9e9CB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21717/thumb/PDPuf0tJ_400x400.jpg?1639863782',
            'coingeckoId': 'hummingbot',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LA',
            'name': 'LA',
            'address': '0xE50365f5D679CB98a1dd62D6F6e58e59321BcdDf',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1051/thumb/LA_token.png?1605772040',
            'coingeckoId': 'latoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CORGI',
            'name': 'Corgi Inu',
            'address': '0xE50006781F435AE43096288334AFd9Ae5Ba50065',
            'decimals': 10,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15646/thumb/mWum355Y_400x400.jpg?1621422930',
            'coingeckoId': 'corgi-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BCP',
            'name': 'PieDAO Balanced Crypto Pie',
            'address': '0xE4f726Adc8e89C6a6017F01eadA77865dB22dA14',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xE4f726Adc8e89C6a6017F01eadA77865dB22dA14/logo.png',
            'coingeckoId': 'piedao-balanced-crypto-pie',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'DONK',
            'name': 'Donkey',
            'address': '0xE4F6D46C244Bb7Cf3e218CDFB5C35cf9a4d9c920',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15330/thumb/donkey_logo.jpg?1620549377',
            'coingeckoId': 'donkey',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XFI',
            'name': 'Dfinance',
            'address': '0xE4E822C0d5b329E8BB637972467d2E313824eFA0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14002/thumb/7223.png?1613579385',
            'coingeckoId': 'dfinance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XEND',
            'name': 'Xend Finance',
            'address': '0xE4CFE9eAa8Cdb0942A80B7bC68fD8Ab0F6D44903',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xe4cfe9eaa8cdb0942a80b7bc68fd8ab0f6d44903.png',
            'coingeckoId': 'xend-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'UOP',
            'name': 'Utopia Genesis Foundation',
            'address': '0xE4AE84448DB5CFE1DaF1e6fb172b469c161CB85F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xE4AE84448DB5CFE1DaF1e6fb172b469c161CB85F/logo.png',
            'coingeckoId': 'utopia-genesis-foundation',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'SYC',
            'name': 'SynchroCoin',
            'address': '0xE49214e4c92dc9bcb3B56C1309aFE0D626dD730E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1469/thumb/ud643DVg.png?1621828021',
            'coingeckoId': 'synchrocoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '2KEY',
            'name': '2key network',
            'address': '0xE48972fCd82a274411c01834e2f031D4377Fa2c0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xe48972fcd82a274411c01834e2f031d4377fa2c0.png',
            'coingeckoId': '2key',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'PLAY',
            'name': 'HEROcoin',
            'address': '0xE477292f1B3268687A29376116B0ED27A9c76170',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2221/thumb/herocoin.png?1547744781',
            'coingeckoId': 'herocoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WCELO',
            'name': 'Wrapped Celo',
            'address': '0xE452E6Ea2dDeB012e20dB73bf5d3863A3Ac8d77a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xE452E6Ea2dDeB012e20dB73bf5d3863A3Ac8d77a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'KAIDA',
            'name': 'Dejitaru Kaida',
            'address': '0xE44aa0354341F02D51C14FE2248B005B2E3372d4',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27867/thumb/Dejitaru_Kaida.jpeg?1666153265',
            'coingeckoId': 'dejitaru-kaida',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZRX',
            'name': '0x',
            'address': '0xE41d2489571d322189246DaFA5ebDe1F4699F498',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xe41d2489571d322189246dafa5ebde1f4699f498.png',
            'coingeckoId': '0x',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'optimism',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PST',
            'name': 'Primas',
            'address': '0xE3feDAeCD47aa8EAb6b23227b0eE56F092C967a9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/917/thumb/primas.png?1548331951',
            'coingeckoId': 'primas',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DFGL',
            'name': 'DeFi Gold',
            'address': '0xE3a64A3c4216B83255b53Ec7eA078B13f21a7daD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12883/thumb/defi_gold.jpg?1603281766',
            'coingeckoId': 'defi-gold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TCR',
            'name': 'TecraCoin ERC20',
            'address': '0xE38B72d6595FD3885d1D2F770aa23E94757F91a1',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7836/thumb/tcr.jpg?1604903462',
            'coingeckoId': 'tecracoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VIDA',
            'name': 'Vidiachange',
            'address': '0xE35f19E4457A114A951781aaF421EC5266eF25Fe',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14346/thumb/vidia.png?1615504632',
            'coingeckoId': 'vidiachange',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AAMMUNIDAIUSDC',
            'name': 'Aave AMM UniDAIUSDC',
            'address': '0xE340B25fE32B1011616bb8EC495A4d503e322177',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17218/thumb/aAmmUniDAIUSDC.png?1626878648',
            'coingeckoId': 'aave-amm-unidaiusdc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MCF',
            'name': 'MCFinance',
            'address': '0xE33AE4E795114279721047484E5ad5cc7DF24fcB',
            'decimals': 10,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17608/thumb/arFRQ0b.png?1628650181',
            'coingeckoId': 'mcfinance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BM',
            'name': 'Bitcomo',
            'address': '0xE2fe5E7E206e7B46CAd6A5146320e5b4b9A18E97',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4127/thumb/Webp.net-resizeimage_%2828%29.png?1547039349',
            'coingeckoId': 'bitcomo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NEX',
            'name': 'Nash',
            'address': '0xE2dc070524A6e305ddB64d8513DC444B6a1ec845',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3246/thumb/nash.png?1653656442',
            'coingeckoId': 'neon-exchange',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CWI',
            'name': 'Crypto Wrestling Inu',
            'address': '0xE2d310CB8992b3Fa1051BA4710F41c43eB5Bba5D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25350/thumb/logo.png?1651304842',
            'coingeckoId': 'crypto-wrestling-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NUVO',
            'name': 'Nuvo Cash',
            'address': '0xE2Db94E8D4E4144c336e45668a792D17D48a482c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11422/thumb/nuvo.png?1590109082',
            'coingeckoId': 'nuvo-cash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ERT',
            'name': 'Esports com',
            'address': '0xE29c5B523590165795BBD7d52369c2895B18841f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1684/thumb/esports.jpg?1547035941',
            'coingeckoId': 'esports',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FIRU',
            'name': 'Firulais',
            'address': '0xE276056f924E15BEac26F7b10B6D2805F9c64Bb5',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17564/thumb/firulais.jpg?1628321879',
            'coingeckoId': 'firulais',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-402-SKOSTNE',
            'name': 'RealT   402 S Kostner Ave  Chicago  IL ',
            'address': '0xE255cAF8893382465368b8e1cd4Ef8436ACf0ADE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16627/thumb/402-S-Kostner-hero-1.jpg?1624553033',
            'coingeckoId': '402-s-kostner',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WGMI',
            'name': 'WGMI',
            'address': '0xE23FaEfcdAAe646527eb86b1C746A25Ae4a36f75',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20330/thumb/new_WGMI_logo-01.png?1650534006',
            'coingeckoId': 'wgmi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRNO',
            'name': 'Chronoly',
            'address': '0xE23311294467654E0CaB14cD32A169A41be5ca8E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27752/thumb/Chronoly.jpeg?1665651796',
            'coingeckoId': 'chronoly',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BANANA',
            'name': 'Banana',
            'address': '0xE2311ae37502105b442bBef831E9b53c5d2e9B3b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xE2311ae37502105b442bBef831E9b53c5d2e9B3b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'TRAT',
            'name': 'Tratok',
            'address': '0xE225AcA29524bB65fd82C79a9602f3b4F9c6Fe3f',
            'decimals': 5,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6791/thumb/tratok.png?1547043075',
            'coingeckoId': 'tratok',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MLP',
            'name': 'My Liquidity Partner',
            'address': '0xE22020F47B7378dfedcedd2C81d4137c22fE1152',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xe22020f47b7378dfedcedd2c81d4137c22fe1152.png',
            'coingeckoId': 'my-liquidity-partner',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'XCUR',
            'name': 'Curate',
            'address': '0xE1c7E30C42C24582888C758984f6e382096786bd',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xe1c7e30c42c24582888c758984f6e382096786bd.png',
            'coingeckoId': 'curate',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'FCT',
            'name': 'Firmachain',
            'address': '0xE1bAD922F84b198A08292FB600319300ae32471b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9892/thumb/6mHcLurm_400x400.jpg?1573160342',
            'coingeckoId': 'firmachain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PHTR',
            'name': 'Phuture',
            'address': '0xE1Fc4455f62a6E89476f1072530C20CF1A0622dA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xE1Fc4455f62a6E89476f1072530C20CF1A0622dA/logo.png',
            'coingeckoId': 'phuture',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'NFTY',
            'name': 'NFTY Labs',
            'address': '0xE1D7C7a4596B038CEd2A84bF65B8647271C53208',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18741/thumb/logo_%281%29.png?1643188469',
            'coingeckoId': 'nfty-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRX',
            'name': 'TRON',
            'address': '0xE1Be5D3f34e89dE342Ee97E6e90D405884dA6c67',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/logos/main/network/ethereum/0xE1Be5D3f34e89dE342Ee97E6e90D405884dA6c67.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'WNK',
            'name': 'The Winkyverse',
            'address': '0xE1BDA0c3Bfa2bE7f740f0119B6a34F057BD58Eba',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22260/thumb/wnk.png?1644053369',
            'coingeckoId': 'the-winkyverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ABAT',
            'name': 'Aave BAT v1',
            'address': '0xE1BA0FB44CCb0D11b80F92f4f8Ed94CA3fF51D00',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xe1ba0fb44ccb0d11b80f92f4f8ed94ca3ff51d00.png',
            'coingeckoId': 'aave-bat-v1',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'BZNT',
            'name': 'Bezant',
            'address': '0xE1Aee98495365fc179699C1bB3E761FA716beE62',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3825/thumb/bezant.png?1546240528',
            'coingeckoId': 'bezant',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RMPL',
            'name': 'RMPL',
            'address': '0xE17f017475a709De58E976081eB916081ff4c9d5',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xe17f017475a709de58e976081eb916081ff4c9d5.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'WIT',
            'name': 'WITChain',
            'address': '0xE13Ef257cF4D5Df928ca11d230427C037666d466',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6420/thumb/wit-logo.png?1547042587',
            'coingeckoId': 'witchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'G',
            'name': 'GRN Grid',
            'address': '0xE1005BfBBC9A17d5d844C7a4371CBF6B2B387380',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26126/thumb/MQrn5TGl_400x400.jpg?1655954068',
            'coingeckoId': 'grn-grid',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USF',
            'name': 'Unslashed Finance',
            'address': '0xE0e05c43c097B0982Db6c9d626c4eb9e95C3b9ce',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14177/thumb/Unslashed.jpeg?1614793365',
            'coingeckoId': 'unslashed-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MX',
            'name': 'MarsX',
            'address': '0xE0dF31d06d72b2f5231489AF0edc422B372F49f1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19659/thumb/png256x256.png?1636908533',
            'coingeckoId': 'marsx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MANC',
            'name': 'Mancium',
            'address': '0xE0c05ec44775e4AD62CDC2eEcdF337aA7A143363',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25084/thumb/IKpuMYuGaMHEE0nrDUwpf6DqLQ5zTfZ1sak6ZqeIe2snV4GqQbh4Eic6hHcPl_OpBmlhcrRJ5kqoGzwB1CezMiDYnwR6rDT08_l8zb3-y3aPhTv7_0x5g4IO9JpQXDViEIqTZ7gYzTXqkcP6MH_uCFxQF-l-ohR0-FdT8O-ZTmNUKm--OuqEf9DVa-miAFp4t2pN-kcJ5Jv8woPj51yk-zna8c.jpg?1650265130',
            'coingeckoId': 'mancium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ONX',
            'name': 'OnX Finance',
            'address': '0xE0aD1806Fd3E7edF6FF52Fdb822432e847411033',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xe0ad1806fd3e7edf6ff52fdb822432e847411033.png',
            'coingeckoId': 'onx-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'NCDT',
            'name': 'Nuco Cloud',
            'address': '0xE0C8b298db4cfFE05d1bEA0bb1BA414522B33C1B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8932/thumb/nucloud_logo.png?1650524741',
            'coingeckoId': 'nuco-cloud',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DGD',
            'name': 'DigixDAO',
            'address': '0xE0B7927c4aF23765Cb51314A0E0521A9645F0E2A',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/397/thumb/dgd.png?1547034124',
            'coingeckoId': 'digixdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GREEN',
            'name': 'Greeneum Network',
            'address': '0xE0A16435Df493Bd17A58CB2eE58675f5ea069517',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3069/thumb/Logo_web.png?1629084636',
            'coingeckoId': 'greeneum-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FESS',
            'name': 'Fesschain',
            'address': '0xE09394F8BA642430eD448CA20f342EC7aa1Ba2E1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8414/thumb/IMG_20220221_115001_051.jpg?1645459447',
            'coingeckoId': 'fess-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DAOX',
            'name': 'The DAOX Index',
            'address': '0xE00639A1f59B52773b7d39d9F9beF07F6248dbAe',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25042/thumb/200px-DAOx.png?1649911875',
            'coingeckoId': 'the-daox-index',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RESTAURANTS',
            'name': 'Devour Token',
            'address': '0xDffC63f92c939deB112D88735ade3B4D21B6D491',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22817/thumb/Devour-200x200-2.png?1645602391',
            'coingeckoId': 'devour',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MYOBU',
            'name': 'My bu',
            'address': '0xDfDd3459D4F87234751696840092ee20C970Fb07',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16122/thumb/myobu.png?1623037167',
            'coingeckoId': 'myobu',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'CUBE',
            'name': 'Somnium Space CUBEs',
            'address': '0xDf801468a808a32656D2eD2D2d80B72A129739f4',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10687/thumb/CUBE_icon.png?1617026861',
            'coingeckoId': 'somnium-space-cubes',
            'listedIn': [
                'coingecko',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'AZRX',
            'name': 'Aave ZRX',
            'address': '0xDf7FF54aAcAcbFf42dfe29DD6144A69b629f8C9e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xdf7ff54aacacbff42dfe29dd6144a69b629f8c9e.png',
            'coingeckoId': 'aave-zrx',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ALFW3',
            'name': 'ALFweb3Project',
            'address': '0xDf7A6A1214B3CBd3F9812434437F61A0D4cBBE1F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26643/thumb/ALFw3_200.png?1659351452',
            'coingeckoId': 'alfweb3project',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SATT',
            'name': 'SaTT',
            'address': '0xDf49C9f599A0A9049D97CFF34D0C30E468987389',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4070/thumb/r3s9XKk.png?1623046839',
            'coingeckoId': 'satt',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BST',
            'name': 'BOOSTO',
            'address': '0xDf0041891BdA1f911C4243f328F7Cf61b37F965b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3448/thumb/Untitled2222.png?1547038159',
            'coingeckoId': 'boosto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TAC',
            'name': 'Taekwondo Access Credit',
            'address': '0xDeeB6091A5ADc78fA0332BEE5A38a8908b6b566e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://etherscan.io/token/images/tkdcoop_32.png',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'WIS',
            'name': 'Experty Wisdom',
            'address': '0xDecade1c6Bf2cD9fb89aFad73e4a519C867adcF5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13133/thumb/n0MTVBrm_400x400.jpg?1605543934',
            'coingeckoId': 'experty-wisdom-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HTRE',
            'name': 'HodlTree',
            'address': '0xDea67845A51E24461D5fED8084E69B426AF3D5Db',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xdea67845a51e24461d5fed8084e69b426af3d5db.png',
            'coingeckoId': 'hodltree',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'IAB',
            'name': 'IAB',
            'address': '0xDeD2583b3fbf4B381851f5031188A5a3562Ab2cD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6549/thumb/iabchain.png?1547042763',
            'coingeckoId': 'iab',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STPT',
            'name': 'STP',
            'address': '0xDe7D85157d9714EADf595045CC12Ca4A5f3E2aDb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8713/thumb/STP.png?1560262664',
            'coingeckoId': 'stp-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GERA',
            'name': 'Gera Coin',
            'address': '0xDe5eA375FFBDc8b25a80fe13D631E8Ba0AB4BB02',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13686/thumb/GeraCoin_Logo-icon-1000px.png?1610919942',
            'coingeckoId': 'gera-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XAC',
            'name': 'General Attention Currency',
            'address': '0xDe4C5a791913838027a2185709E98c5C6027EA63',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9203/thumb/-LF1XcKS_400x400.png?1565131564',
            'coingeckoId': 'general-attention-currency',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GTC',
            'name': 'Gitcoin',
            'address': '0xDe30da39c46104798bB5aA3fe8B9e0e1F348163F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xde30da39c46104798bb5aa3fe8b9e0e1f348163f.png',
            'coingeckoId': 'gitcoin',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-11957-OLGA-',
            'name': 'RealT   11957 Olga St  Detroit  MI 4821',
            'address': '0xDd833D0eEF6d5d7Cec781b03c19f3B425F3039Df',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16672/thumb/11957-Olga-hero-1.jpg?1624628071',
            'coingeckoId': '11957-olga',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FOHO',
            'name': 'Foho Coin',
            'address': '0xDd2e93924BDD4E20C3CF4A8736E5955224FA450E',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17933/thumb/FOHO.Coin_colour-02-1.png?1629859400',
            'coingeckoId': 'foho-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UBI',
            'name': 'Universal Basic Income',
            'address': '0xDd1Ad9A21Ce722C151A836373baBe42c868cE9a4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xdd1ad9a21ce722c151a836373babe42c868ce9a4.png',
            'coingeckoId': 'universal-basic-income',
            'listedIn': [
                'coingecko',
                '1inch',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'DEGENS',
            'name': 'Degens',
            'address': '0xDd039990Bd551Ce7437d3bF54d155220B7988B71',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14526/thumb/degens_logo.png?1616740013',
            'coingeckoId': 'degens',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YLD',
            'name': 'Yield',
            'address': '0xDcB01cc464238396E213a6fDd933E36796eAfF9f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xDcB01cc464238396E213a6fDd933E36796eAfF9f/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'GOSH',
            'name': 'GOSH Realm',
            'address': '0xDc732972811d25de61808b3d6e0d2ac44A7F036F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24768/thumb/Untitled.png?1648817598',
            'coingeckoId': 'gosh-realm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FNT',
            'name': 'Falcon Project',
            'address': '0xDc5864eDe28BD4405aa04d93E05A0531797D9D59',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xdc5864ede28bd4405aa04d93e05a0531797d9d59.png',
            'coingeckoId': 'falcon-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'KISHUTAMA',
            'name': 'Kishutama',
            'address': '0xDc0804eD9ABA9202E2b976C031d3144BB63a7990',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23483/thumb/Screenshot-2022-02-05-at-09-20-53.png?1644219727',
            'coingeckoId': 'kishutama',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STRNGR',
            'name': 'Stronger',
            'address': '0xDc0327D50E6C73db2F8117760592C8BBf1CDCF38',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24922/thumb/200x200_logo.png?1650609989',
            'coingeckoId': 'stronger',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'FOREX',
            'name': 'handle fi',
            'address': '0xDb298285FE4C5410B05390cA80e8Fbe9DE1F259B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18533/thumb/handle.fiFOREXLogoDark200x200.png?1632755675',
            'coingeckoId': 'handle-fi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'pWING',
            'name': 'Poly Ontology Wing Token',
            'address': '0xDb0f18081b505A7DE20B18ac41856BCB4Ba86A1a',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1634701785987_5608612087913547.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'OPCT',
            'name': 'Opacity',
            'address': '0xDb05EA0877A2622883941b939f0bb11d1ac7c400',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7237/thumb/Opacity.jpg?1551843524',
            'coingeckoId': 'opacity',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'CRDT',
            'name': 'CRDT',
            'address': '0xDaab5E695bb0E8Ce8384ee56BA38fA8290618e52',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11859/thumb/image_%281%29.png?1600937373',
            'coingeckoId': 'crdt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EPK',
            'name': 'EpiK Protocol',
            'address': '0xDaF88906aC1DE12bA2b1D2f7bfC94E9638Ac40c4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15188/thumb/epk.PNG?1620078400',
            'coingeckoId': 'epik-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TWI',
            'name': 'Trade win',
            'address': '0xDaD26BCE7dCF59cd03a2455558E4Dd73e1c07B66',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13060/thumb/Logo.png?1604821765',
            'coingeckoId': 'trade-win',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NZDS',
            'name': 'NZD Stablecoin',
            'address': '0xDa446fAd08277B4D2591536F204E018f32B6831c',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20394/thumb/nzds.png?1636964381',
            'coingeckoId': 'nzd-stablecoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JMT',
            'name': 'JMTIME',
            'address': '0xDa022Ca91DF99413e8CB0CAAB4d1CbA4e9018bea',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7397/thumb/SQA_voBI.png?1555664534',
            'coingeckoId': 'jmtime',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NODE',
            'name': 'DAppNode',
            'address': '0xDa007777D86AC6d989cC9f79A73261b3fC5e0DA0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17187/thumb/36789279.png?1626828678',
            'coingeckoId': 'dappnode',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'stETH/StakeHound',
            'name': 'stakedETH',
            'address': '0xDFe66B14D37C77F4E9b180cEb433d1b164f0281D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/steth.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'NFD',
            'name': 'Feisty Doge NFT',
            'address': '0xDFDb7f72c1F195C5951a234e8DB9806EB0635346',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/11368.png',
            'coingeckoId': 'feisty-doge-nft',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'GOLD',
            'name': 'Projekt Gold',
            'address': '0xDFC628A33C18e856Cd1c59583cB5aCe8dB706F14',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16417/thumb/gold.png?1623982279',
            'coingeckoId': 'projekt-gold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOB',
            'name': 'Bob s Repair',
            'address': '0xDF347911910b6c9A4286bA8E2EE5ea4a39eB2134',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3550/thumb/PNG_BOB_Token_Large.png?1547038375',
            'coingeckoId': 'bobs_repair',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MFT',
            'name': 'Hifi Finance',
            'address': '0xDF2C7238198Ad8B389666574f2d8bc411A4b7428',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xdf2c7238198ad8b389666574f2d8bc411a4b7428.png',
            'coingeckoId': 'mainframe',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'COW',
            'name': 'CoW Protocol',
            'address': '0xDEf1CA1fb7FBcDC777520aa7f396b4E015F497aB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xdef1ca1fb7fbcdc777520aa7f396b4e015f497ab.png',
            'coingeckoId': 'cow-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'LOBI',
            'name': 'Lobis',
            'address': '0xDEc41Db0c33F3F6f3cb615449C311ba22D418A8d',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xDEc41Db0c33F3F6f3cb615449C311ba22D418A8d/logo.png',
            'coingeckoId': 'lobis',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'SHUNAV2',
            'name': 'Shuna Inuverse',
            'address': '0xDE72a052cBB212eFf11A4A33d61dF9C5A2De8dd1',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21526/thumb/Untitled-design-15.png?1639382131',
            'coingeckoId': 'shuna-inuverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEUS',
            'name': 'DEUS Finance',
            'address': '0xDE5ed76E7c05eC5e4572CfC88d1ACEA165109E44',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xde5ed76e7c05ec5e4572cfc88d1acea165109e44.png',
            'coingeckoId': 'deus-finance-2',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'META',
            'name': 'Metadium',
            'address': '0xDE2F7766C8BF14CA67193128535E5c7454f8387C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5247/thumb/META_Logo_black.png?1548833790',
            'coingeckoId': 'metadium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEI',
            'name': 'DEI',
            'address': '0xDE1E704dae0B4051e80DAbB26ab6ad6c12262DA0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DELTA',
            'name': 'DeltaChain',
            'address': '0xDE1E0AE6101b46520cF66fDC0B1059c5cC3d106c',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5794/thumb/deltachain.png?1547041664',
            'coingeckoId': 'deltachain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BBF',
            'name': 'Bubblefong',
            'address': '0xDE075D9ADbD0240b4462F124af926452Ad0BAC91',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24634/thumb/BBF-Token-200x200.png?1648460352',
            'coingeckoId': 'bubblefong',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DVF',
            'name': 'Rhino fi',
            'address': '0xDDdddd4301A082e62E84e43F474f044423921918',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xdddddd4301a082e62e84e43f474f044423921918.png',
            'coingeckoId': 'rhinofi',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'SHITZUINU',
            'name': 'Shitzu Inu',
            'address': '0xDDF825aA40aba689E040fd9bE863573999314773',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://api.rubic.exchange/assets/ethereum/0xddf825aa40aba689e040fd9be863573999314773/logo.png',
            'coingeckoId': 'shitzu-inu',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'UNIX',
            'name': 'UniX',
            'address': '0xDDD6A0ECc3c6F6C102E5eA3d8Af7B801d1a77aC8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xddd6a0ecc3c6f6c102e5ea3d8af7b801d1a77ac8.png',
            'coingeckoId': 'unix',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'COTI',
            'name': 'COTI',
            'address': '0xDDB3422497E61e13543BeA06989C0789117555c5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xddb3422497e61e13543bea06989c0789117555c5.png',
            'coingeckoId': 'coti',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'WELD',
            'name': 'WELD',
            'address': '0xDD2a36AE937bc134ea694d77fC7e2E36f5d86dE0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18544/thumb/weld.png?1661497968',
            'coingeckoId': 'weld',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TEN',
            'name': 'Tokenomy',
            'address': '0xDD16eC0F66E54d453e6756713E533355989040E4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2286/thumb/logo_%281%29.png?1604543144',
            'coingeckoId': 'tokenomy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PEBBLE',
            'name': 'Etherrock  72',
            'address': '0xDC98c5543F3004DEBfaad8966ec403093D0aa4A8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17963/thumb/Screen-Shot-2021-08-25-at-8-42-22-PM.png?1629943788',
            'coingeckoId': 'etherrock-72',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BEZOGE',
            'name': 'Bezoge Earth',
            'address': '0xDC349913d53b446485E98b76800b6254f43Df695',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xdc349913d53b446485e98b76800b6254f43df695.png',
            'coingeckoId': 'bezoge-earth',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'LTCBULL',
            'name': '3X Long Litecoin Token',
            'address': '0xDB61354E9cf2217a29770E9811832B360a8DAad3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10227/thumb/683JEXMN_400x400_%281%29.png?1576618224',
            'coingeckoId': '3x-long-litecoin-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NCR',
            'name': 'Neos Credits',
            'address': '0xDB5C3C46E28B53a39C255AA39A411dD64e5fed9c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xdb5c3c46e28b53a39c255aa39a411dd64e5fed9c.png',
            'coingeckoId': 'neos-credits',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'NAFF',
            'name': 'Naffiti',
            'address': '0xDB262c7d67FE7336574f005Db47B7c8E1dF41852',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24057/thumb/Naffiti_1200x1200.png?1646203375',
            'coingeckoId': 'naffiti',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SNT',
            'name': 'Supernova Token',
            'address': '0xDB04345BA2d2F02ef0DbAAfFAE8CeF0da28952cf',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ADAPAD',
            'name': 'ADAPad',
            'address': '0xDB0170e2d0c1CC1b2E7a90313d9B9afA4f250289',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18273/thumb/EhSqPTtG_400x400.jpg?1631181091',
            'coingeckoId': 'adapad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INVE',
            'name': 'InterValue',
            'address': '0xDAC4AE188AcE3C8985765eDc6C9B4739D4845DdC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4756/thumb/intervalue-logo.png?1547040078',
            'coingeckoId': 'intervalue',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QUIDD',
            'name': 'Quidd',
            'address': '0xDA9FDAb21bC4A5811134A6E0Ba6CA06624e67c07',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19725/thumb/quidd.png?1637303435',
            'coingeckoId': 'quidd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STANDARD',
            'name': 'Stakeborg DAO',
            'address': '0xDA0c94c73D127eE191955FB46bACd7FF999b2bcd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20119/thumb/stquY-WB_400x400.jpg?1636522705',
            'coingeckoId': 'stakeborg-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HAPI',
            'name': 'HAPI',
            'address': '0xD9c2D319Cd7e6177336b0a9c93c21cb48d84Fb54',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xd9c2d319cd7e6177336b0a9c93c21cb48d84fb54.png',
            'coingeckoId': 'hapi',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'YSR',
            'name': 'Ystar',
            'address': '0xD9A947789974bAD9BE77E45C2b327174A9c59D71',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11575/thumb/256_256.png?1600495476',
            'coingeckoId': 'ystar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GTPS',
            'name': 'Global Transaction Payment Solution',
            'address': '0xD9A8bB44968F35282F1b91c353f77a61BaF31A4B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MCN',
            'name': 'MCN Ventures',
            'address': '0xD91E9a0fEf7C0fa4EBdAF4d0aCF55888949A2a9b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17580/thumb/mcn-logo-white-bg.png?1628561611',
            'coingeckoId': 'mcn-ventures',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ONC',
            'name': 'One Cash',
            'address': '0xD90E69f67203EBE02c917B5128629E77B4cd92dc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13529/thumb/onc_logo.png?1609406029',
            'coingeckoId': 'one-cash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARNM',
            'name': 'Arenum',
            'address': '0xD9063a24630f24cd4Dc99a9C7aBe73F82Fc6B722',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24393/thumb/6253dcf123d4f40001e9c793_CryptoCoin_1024.png?1649733527',
            'coingeckoId': 'arenum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GMEE',
            'name': 'GAMEE',
            'address': '0xD9016A907Dc0ECfA3ca425ab20B6b785B42F2373',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xd9016a907dc0ecfa3ca425ab20b6b785b42f2373.png',
            'coingeckoId': 'gamee',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ZORA',
            'name': 'Zoracles',
            'address': '0xD8E3FB3b08eBA982F2754988d70D57eDc0055ae6',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xd8e3fb3b08eba982f2754988d70d57edc0055ae6.png',
            'coingeckoId': 'zoracles',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'DOGIRA',
            'name': 'Dogira',
            'address': '0xD8C1232FcD219286E341271385bd70601503B3D7',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14634/thumb/IQgaRw0.png?1622439620',
            'coingeckoId': 'dogira',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'PLU',
            'name': 'Pluton',
            'address': '0xD8912C10681D8B21Fd3742244f44658dBA12264E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xd8912c10681d8b21fd3742244f44658dba12264e.png',
            'coingeckoId': 'pluton',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'CGT',
            'name': 'CLOID',
            'address': '0xD8625aDA877773b76636a1C8519c5f9e3BE6E243',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26000/thumb/220427_CLOID-logo_200x200.png?1655277305',
            'coingeckoId': 'cloid',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CBK',
            'name': 'Cobak',
            'address': '0xD85a6Ae55a7f33B0ee113C234d2EE308EdeAF7fD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13459/thumb/cbk-128-128.png?1608760936',
            'coingeckoId': 'cobak-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HAKA',
            'name': 'TribeOne',
            'address': '0xD85AD783cc94bd04196a13DC042A3054a9B52210',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xd85ad783cc94bd04196a13dc042a3054a9b52210.png',
            'coingeckoId': 'tribeone',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'CHPZ',
            'name': 'Chipz',
            'address': '0xD7d6ab522fA41caae78F6edeb46A14954aE0c772',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17598/thumb/chipz-logo-22.png?1628591100',
            'coingeckoId': 'chipz',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'STA',
            'name': 'STABLE ASSET',
            'address': '0xD7d05bDa4bf5876bA1254b3Eaaf8b47D2F5676eb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12313/thumb/stable_200x200.png?1599083938',
            'coingeckoId': 'stable-asset',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XPR',
            'name': 'Proton',
            'address': '0xD7EFB00d12C2c13131FD319336Fdf952525dA2af',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xd7efb00d12c2c13131fd319336fdf952525da2af.png',
            'coingeckoId': 'proton',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'CLH',
            'name': 'ClearDAO',
            'address': '0xD7D8f3b8Bc8bC48d3AcC37879EABA7b85889FA52',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22389/thumb/clh_small_logo_png.png?1643194446',
            'coingeckoId': 'cleardao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PYLON',
            'name': 'PYLON',
            'address': '0xD7B7d3C0bdA57723Fb54ab95Fd8F9EA033AF37f2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xd7b7d3c0bda57723fb54ab95fd8f9ea033af37f2.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'YASHA',
            'name': 'YASHA',
            'address': '0xD75f1f81B69bDd4Df8EfbB70e9C6F4609009D753',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24040/thumb/oX9E7Q7.png?1646119331',
            'coingeckoId': 'yasha-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RET',
            'name': 'RealTract',
            'address': '0xD7394087E1DBBE477FE4F1CF373B9Ac9459565fF',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5955/thumb/200-01.png?1547041895',
            'coingeckoId': 'realtract',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SEUR',
            'name': 'sEUR',
            'address': '0xD71eCFF9342A5Ced620049e616c5035F1dB98620',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13637/thumb/sEUR.png?1610609277',
            'coingeckoId': 'seur',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GLYPH',
            'name': 'GLYPH Vault  NFTX ',
            'address': '0xD70240Dd62F4ea9a6A2416e0073D72139489d2AA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17050/thumb/Glyph.png?1626149071',
            'coingeckoId': 'glyph-vault-nftx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FUNEX',
            'name': 'Funex',
            'address': '0xD6e460F70e1CF60E55B770f66E568B44bf3657d0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26814/thumb/20220809_135138.png?1660276769',
            'coingeckoId': 'funex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EXNT',
            'name': 'ExNetwork',
            'address': '0xD6c67B93a7b248dF608a653d82a100556144c5DA',
            'decimals': 16,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12328/thumb/exnt_logo.png?1599102916',
            'coingeckoId': 'exnetwork-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ESH',
            'name': 'Switch',
            'address': '0xD6a55C63865AffD67E2FB9f284F87b7a9E5FF3bD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xd6a55c63865affd67e2fb9f284f87b7a9e5ff3bd.png',
            'coingeckoId': 'switch',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'BUIDL_2',
            'name': 'DFOHub',
            'address': '0xD6F0Bb2A45110f819e908a915237D652Ac7c5AA8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xd6f0bb2a45110f819e908a915237d652ac7c5aa8.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MCHC',
            'name': 'MCH Coin',
            'address': '0xD69F306549e9d96f183B1AecA30B8f4353c2ECC3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15399/thumb/MCHC.jpg?1620721307',
            'coingeckoId': 'mch-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LSILVER',
            'name': 'Lyfe Silver',
            'address': '0xD64809f5F7d772D9112A6BD379De00A77188199E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14939/thumb/LSILVER-Logo-200.png?1619082811',
            'coingeckoId': 'lyfe-silver',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHESS',
            'name': 'Tranchess DAO Token',
            'address': '0xD6123271F980D966B00cA4FCa6C2c021f05e2E73',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MIYAZAKI',
            'name': 'Miyazaki Inu',
            'address': '0xD5e7D22362BCC9881D06512d3189eAe79DD98d70',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19889/thumb/DzuUGOg.png?1636091545',
            'coingeckoId': 'miyazaki-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WRLD',
            'name': 'NFT Worlds',
            'address': '0xD5d86FC8d5C0Ea1aC1Ac5Dfab6E529c9967a45E9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xd5d86fc8d5c0ea1ac1ac5dfab6e529c9967a45e9.png',
            'coingeckoId': 'nft-worlds',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-10629-MCKIN',
            'name': 'RealT   10629 McKinney St  Detroit  MI ',
            'address': '0xD5d1adf54Fbf73a00b197DdCfDAD7cc27D93002f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16611/thumb/10629-McKinney-hero-1.jpg?1624545790',
            'coingeckoId': '10629-mckinney',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ONE',
            'name': 'Harmony ONE',
            'address': '0xD5cd84D6f044AbE314Ee7E414d37cae8773ef9D3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/one.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'REALT-S-11078-LONGV',
            'name': 'RealT   11078 Longview St  Detroit  MI ',
            'address': '0xD5Fc0C4C4C5ff316e1E91494d963Ff1D52Ba25fF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16630/thumb/11078-Longview-hero-2.5.jpg?1624553559',
            'coingeckoId': '11078-longview',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOLT',
            'name': 'Bolt',
            'address': '0xD5930C307d7395Ff807F2921F12C5EB82131a789',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4489/thumb/bolt.png?1555648257',
            'coingeckoId': 'bolt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PAN',
            'name': 'Panvala Pan',
            'address': '0xD56daC73A4d6766464b38ec6D91eB45Ce7457c44',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xd56dac73a4d6766464b38ec6d91eb45ce7457c44.png',
            'coingeckoId': 'panvala-pan',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'GAME',
            'name': 'Gamestarter',
            'address': '0xD567B5F02b9073aD3a982a099a23Bf019FF11d1c',
            'decimals': 5,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17604/thumb/gpMi14-r_400x400.jpg?1628647205',
            'coingeckoId': 'gamestarter',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MEME',
            'name': 'Meme',
            'address': '0xD5525D397898e5502075Ea5E830d8914f6F0affe',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xd5525d397898e5502075ea5e830d8914f6f0affe.png',
            'coingeckoId': 'degenerator',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'CRV',
            'name': 'Curve DAO',
            'address': '0xD533a949740bb3306d119CC777fa900bA034cd52',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xd533a949740bb3306d119cc777fa900ba034cd52.png',
            'coingeckoId': 'curve-dao-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'optimism',
                'arbitrum_bridge',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PBX',
            'name': 'Paribus',
            'address': '0xD528cf2E081f72908e086F8800977df826B5a483',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18410/thumb/paribus.PNG?1631828570',
            'coingeckoId': 'paribus',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'RENFIL',
            'name': 'renFIL',
            'address': '0xD5147bc8e386d91Cc5DBE72099DAC6C9b99276F5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xD5147bc8e386d91Cc5DBE72099DAC6C9b99276F5/logo.png',
            'coingeckoId': 'renfil',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'ITGR',
            'name': 'Integral',
            'address': '0xD502F487e1841Fdc805130e13eae80c61186Bc98',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18381/thumb/icon.png?1650331643',
            'coingeckoId': 'integral',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BST',
            'name': 'Bitsten  OLD ',
            'address': '0xD4f6f9Ae14399fD5Eb8DFc7725F0094a1A7F5d80',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7335/thumb/bitsten.png?1589629209',
            'coingeckoId': 'bitsten-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WEB69',
            'name': 'Web69',
            'address': '0xD4eb79a193E7E1A7B998202A9407E5CE3fF02B4F',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26928/thumb/web69200x200.png?1660880767',
            'coingeckoId': 'web69',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UZUMAKI',
            'name': 'Uzumaki Inu',
            'address': '0xD4c64f0Fe38ecdaF0Baefd7859E18185a73b9aa3',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18585/thumb/XjTLLU9c_400x400.jpg?1637157360',
            'coingeckoId': 'uzumaki-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELEC',
            'name': 'Electrify Asia',
            'address': '0xD49ff13661451313cA1553fd6954BD1d9b6E02b9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3415/thumb/d45b1d82743c749d05697da200179874.jpg?1547038096',
            'coingeckoId': 'electrify-asia',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BISHUFI',
            'name': 'Bishu Finance',
            'address': '0xD49374D1D6d2b2D793035A0116F2b0D19230DE26',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19606/thumb/bishu.jpeg?1635492531',
            'coingeckoId': 'bishu-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HVE2',
            'name': 'Uhive',
            'address': '0xD487892BB4C57edBe7aB401d9fe801c8FE6473f5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3811/thumb/hve2.png?1639045485',
            'coingeckoId': 'uhive',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POLK',
            'name': 'Polkamarkets',
            'address': '0xD478161C952357F05f0292B56012Cd8457F1cfbF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xd478161c952357f05f0292b56012cd8457f1cfbf.png',
            'coingeckoId': 'polkamarkets',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'AMPL',
            'name': 'Ampleforth',
            'address': '0xD46bA6D942050d489DBd938a2C909A5d5039A161',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xd46ba6d942050d489dbd938a2c909a5d5039a161.png',
            'coingeckoId': 'ampleforth',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'TRAXX',
            'name': 'Traxx',
            'address': '0xD43Be54C1aedf7Ee4099104f2DaE4eA88B18A249',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24441/thumb/TT.png?1658383110',
            'coingeckoId': 'traxx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZECBULL',
            'name': '3X Long Zcash',
            'address': '0xD437d88153daEF4784CAcd2084b1d8Cc2D3312B8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16544/thumb/download_%2828%29.png?1624414707',
            'coingeckoId': '3x-long-zcash-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CQT',
            'name': 'Covalent',
            'address': '0xD417144312DbF50465b1C641d016962017Ef6240',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14168/small/covalent-cqt.png?1624545218',
            'coingeckoId': 'covalent',
            'listedIn': [
                'coingecko',
                'uniswap',
                'sushiswap',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'XHT',
            'name': 'HollaEx',
            'address': '0xD3c625F54dec647DB8780dBBe0E880eF21BA4329',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16157/thumb/xGyZxK8.png?1623139610',
            'coingeckoId': 'hollaex-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MCONTENT',
            'name': 'MContent',
            'address': '0xD3c51de3E6DD9b53D7f37699AFB3EE3Bf9B9b3F4',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16781/thumb/Image_mcontent.jpeg?1653476766',
            'coingeckoId': 'mcontent',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUXO',
            'name': 'Luxo',
            'address': '0xD39a2CeCBA2657e125Ba6a5c98ad2F6b6D7E83FD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25545/thumb/ik7UTHJR_400x400.jpg?1652327828',
            'coingeckoId': 'luxo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AENS',
            'name': 'AEN Smart',
            'address': '0xD38dE88687172BDE440755b5237987e4A87c23A7',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15163/thumb/x58-DmSw.png?1620007469',
            'coingeckoId': 'aen-smart-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MRXe',
            'name': 'Wrapped Metrix',
            'address': '0xD38B1159c8aEE064Af2D869Afa1c2c1632da8B97',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://static.debank.com/image/eth_token/logo_url/0xd38b1159c8aee064af2d869afa1c2c1632da8b97/a1a9d63998434ff6c1631df7e530390a.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AGUSD',
            'name': 'Aave GUSD',
            'address': '0xD37EE7e4f452C6638c96536e68090De8cBcdb583',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17246/thumb/aGUSD_2x.png?1626942603',
            'coingeckoId': 'aave-gusd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RPL',
            'name': 'Rocket Pool',
            'address': '0xD33526068D116cE69F19A9ee46F0bd304F21A51f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xd33526068d116ce69f19a9ee46f0bd304f21a51f.png',
            'coingeckoId': 'rocket-pool',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SOL',
            'name': 'SOL  Wormhole ',
            'address': '0xD31a59c85aE9D8edEFeC411D448f90841571b89c',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22876/thumb/SOL_wh_small.png?1644224316',
            'coingeckoId': 'sol-wormhole',
            'listedIn': [
                'coingecko',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'STIMA',
            'name': 'STIMA',
            'address': '0xD2e5decc08A80be6538F89f9AB8ff296e2f724Df',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26101/thumb/STIMA_200x200.png?1655867272',
            'coingeckoId': 'stima',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DORAEMONINU',
            'name': 'DoraemonInu',
            'address': '0xD2d9E0B2942943269D5224E0048Bbc32380aC19B',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19141/thumb/DI.jpg?1634528340',
            'coingeckoId': 'doraemoninu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RGT',
            'name': 'Rari Governance',
            'address': '0xD291E7a03283640FDc51b121aC401383A46cC623',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xd291e7a03283640fdc51b121ac401383a46cc623.png',
            'coingeckoId': 'rari-governance-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'optimism',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'FMON',
            'name': 'FMONEY FINANCE',
            'address': '0xD283EC9db7B3094ED5a2c87Eb48D54f5FA96e96D',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27808/thumb/logo_%281%29.png?1665906035',
            'coingeckoId': 'fmoney-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HOO',
            'name': 'Hoo',
            'address': '0xD241D7b5cb0eF9fC79D9e4eb9e21F5e209f52f7D',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12968/thumb/LOGO1.jpeg?1654140098',
            'coingeckoId': 'hoo-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SI',
            'name': 'Siren',
            'address': '0xD23Ac27148aF6A2f339BD82D0e3CFF380b5093de',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xd23ac27148af6a2f339bd82d0e3cff380b5093de.png',
            'coingeckoId': 'siren',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'AMPLIFI',
            'name': 'AmpliFi',
            'address': '0xD23367155B55d67492DFDC0FC7f8bB1dF7114fD9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xd23367155b55d67492dfdc0fc7f8bb1df7114fd9.png',
            'coingeckoId': 'amplifi',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SHIBOKI',
            'name': 'Shiboki',
            'address': '0xD1e94958DEFB1C56516329A21f072951B22301Aa',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24620/thumb/Shiboki.jpeg?1648446706',
            'coingeckoId': 'shiboki-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-10612-SOMER',
            'name': 'RealT   10612 Somerset Ave  Detroit  MI',
            'address': '0xD1c15CEbfDcd16F00D91666bf64C8b66CbF5e9b5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16680/thumb/Somerset-3.jpeg?1624630432',
            'coingeckoId': '10612-somerset',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SDCRV',
            'name': 'Stake DAO CRV',
            'address': '0xD1b5651E55D4CeeD36251c61c50C889B36F6abB5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27756/thumb/scCRV-2.png?1665654580',
            'coingeckoId': 'stake-dao-crv',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GAZE',
            'name': 'GazeTV',
            'address': '0xD1E06952708771f71E6dd18F06EE418F6e8FC564',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14717/thumb/Gaze_Icon_Positive.png?1617937326',
            'coingeckoId': 'gazetv',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PARA',
            'name': 'Para',
            'address': '0xD16fd95D949F996e3808EeEa0e3881C59E76EF1E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24652/thumb/para.jpg?1662464045',
            'coingeckoId': 'paratoken-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WZM',
            'name': 'Woozoo Music',
            'address': '0xD15A1A2A3211b58113e45809f05934252E34e2F8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21891/thumb/logo_200_200_%281%29.png?1640228330',
            'coingeckoId': 'woozoo-music',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VAI',
            'name': 'Vaiot',
            'address': '0xD13cfD3133239a3c73a9E535A5c4DadEE36b395c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13981/thumb/VAIOT_logo.png?1613456546',
            'coingeckoId': 'vaiot',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UOS',
            'name': 'Ultra',
            'address': '0xD13c7342e1ef687C5ad21b27c2b65D772cAb5C8c',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xd13c7342e1ef687c5ad21b27c2b65d772cab5c8c.png',
            'coingeckoId': 'ultra',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'STSR',
            'name': 'SatelStar',
            'address': '0xD13432fdD2ae4eeD84C3A0233D6c5265087C96c3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28202/thumb/Satelstar_logo_200x.png?1668338911',
            'coingeckoId': 'satelstar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RSC',
            'name': 'ResearchCoin',
            'address': '0xD101dCC414F310268c37eEb4cD376CcFA507F571',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28146/thumb/RH_Logo_200x200.png?1668678529',
            'coingeckoId': 'researchcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETHYS',
            'name': 'Ethereum Stake',
            'address': '0xD0d3EbCAd6A20ce69BC3Bc0e1ec964075425e533',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13328/thumb/eths_logo.png?1607494708',
            'coingeckoId': 'ethereum-stake',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADT',
            'name': 'adChain',
            'address': '0xD0D6D6C5Fe4a677D343cC433536BB717bAe167dD',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/778/thumb/adtoken.png?1547034537',
            'coingeckoId': 'adtoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KOROMARU',
            'name': 'KOROMARU',
            'address': '0xD0D42005e7B3c0812b1268F0e5FAF97Ff2423651',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19884/thumb/koromaru.png?1642838436',
            'coingeckoId': 'koromaru',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POP',
            'name': 'Popcorn',
            'address': '0xD0Cd466b34A24fcB2f87676278AF2005Ca8A78c4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xd0cd466b34a24fcb2f87676278af2005ca8a78c4.png',
            'coingeckoId': 'popcorn',
            'listedIn': [
                'coingecko',
                '1inch',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'LTCHEDGE',
            'name': '1X Short Litecoin Token',
            'address': '0xD0C64D6c0E9aA53fFFd8B80313e035f7B83083F3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10230/thumb/683JEXMN_400x400_%281%29.png?1576618594',
            'coingeckoId': '1x-short-litecoin-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-6923-GREENV',
            'name': 'RealT   6923 Greenview Ave  Detroit  MI',
            'address': '0xD08d2b199E9E5df407427d4085877d1fDFf3b1d6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16676/thumb/6923-Greenview.jpeg?1624629085',
            'coingeckoId': '6923-greenview',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WOOFY',
            'name': 'Woofy',
            'address': '0xD0660cD418a64a1d44E9214ad8e459324D8157f1',
            'decimals': 12,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/woofy.jpg',
            'coingeckoId': 'woofy',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'ROT',
            'name': 'Rotten',
            'address': '0xD04785C4d8195e4A54d9dEc3a9043872875ae9E2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12560/thumb/rot_logo.png?1600762626',
            'coingeckoId': 'rotten',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AETHB',
            'name': 'Ankr Reward Earning Staked ETH',
            'address': '0xD01ef7C0A5d8c432fc2d1a85c66cF2327362E5C6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1637161807072_8622245356951528.png',
            'coingeckoId': 'ankr-reward-earning-staked-eth',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'CFTI',
            'name': 'Confetti',
            'address': '0xCfef8857E9C80e3440A823971420F7Fa5F62f020',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24524/thumb/confetti_28.png?1648021097',
            'coingeckoId': 'confetti',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TORG',
            'name': 'TORG',
            'address': '0xCfa0885131F602D11D4DA248D2c65A62063567A9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17596/thumb/TORG_Logo_200x200.png?1628586056',
            'coingeckoId': 'torg',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MKS',
            'name': 'Makes',
            'address': '0xCfF20cE22e71EcF2Ea89c86eCbD4a3CF513768c7',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13318/thumb/MKS200.png?1607412065',
            'coingeckoId': 'makes',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CBIX7',
            'name': 'CBI Index 7',
            'address': '0xCf8f9555D55CE45a3A33a81D6eF99a2a2E71Dee2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xcf8f9555d55ce45a3a33a81d6ef99a2a2e71dee2.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'YFFI',
            'name': 'yffi finance',
            'address': '0xCee1d3c3A02267e37E6B373060F79d5d7b9e1669',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11940/thumb/yffi-finance.jpg?1596289302',
            'coingeckoId': 'yffi-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YFILD',
            'name': 'YFILEND FINANCE',
            'address': '0xCec2387e04F9815BF12670dBf6cf03bba26DF25F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12414/thumb/ylend.png?1599684775',
            'coingeckoId': 'yfilend-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HRTS',
            'name': 'YellowHeart Protocol',
            'address': '0xCeB53519a2B891d33b36E3826962D2a48f878e45',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25304/thumb/HRTS.png?1652087596',
            'coingeckoId': 'yellowheart-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSVBEAR',
            'name': '3X Short Bitcoin SV Token',
            'address': '0xCe49c3c92b33a1653F34811a9d7e34502bF12B89',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10329/thumb/683JEXMN_400x400_%281%29.png?1578051844',
            'coingeckoId': '3x-short-bitcoin-sv-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'mooBooUSDC-MAI',
            'name': 'Moo Boo USDC-MAI',
            'address': '0xCe3B0d4E4E4285E55C0BfB294112Caf19682EB8A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BAO',
            'name': 'Bao Finance V2',
            'address': '0xCe391315b414D4c7555956120461D21808A69F3A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28287/thumb/Oe8-vORu_400x400.png?1669191823',
            'coingeckoId': 'bao-finance-v2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HOD',
            'name': 'Hodooi.com',
            'address': '0xCe16A802725438AF9b4dcac00E7791E3D890E3b4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://static.debank.com/image/eth_token/logo_url/0xce16a802725438af9b4dcac00e7791e3d890e3b4/acc00cc06ebd35f6653d248ba6218e89.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CRBN',
            'name': 'Carbon',
            'address': '0xCdeee767beD58c5325f68500115d4B722b3724EE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13262/thumb/carbon.png?1662693418',
            'coingeckoId': 'carbon',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'FAI',
            'name': 'Fairum',
            'address': '0xCda2f16C6Aa895D533506B426AFF827b709c87F5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13894/thumb/logo-65ce48ea8d2b64b6478a42c0050214e2.png?1612645237',
            'coingeckoId': 'fairum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'T',
            'name': 'Threshold Network',
            'address': '0xCdF7028ceAB81fA0C6971208e83fa7872994beE5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xcdf7028ceab81fa0c6971208e83fa7872994bee5.png',
            'coingeckoId': 'threshold-network-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SMI',
            'name': 'SafeMoon Inu',
            'address': '0xCd7492db29E2ab436e819b249452EE1bbDf52214',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16091/thumb/SMI.png?1622801787',
            'coingeckoId': 'safemoon-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ODDZ',
            'name': 'Oddz',
            'address': '0xCd2828fc4D8E8a0eDe91bB38CF64B1a81De65Bf6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xcd2828fc4d8e8a0ede91bb38cf64b1a81de65bf6.png',
            'coingeckoId': 'oddz',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'YFOS',
            'name': 'YFOS finance',
            'address': '0xCd254568EBF88f088E40f456db9E17731243cb25',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12707/thumb/wHQeaUF.jpg?1601888512',
            'coingeckoId': 'yfos-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XTM',
            'name': 'Torum',
            'address': '0xCd1fAFf6e578Fa5cAC469d2418C95671bA1a62Fe',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18393/thumb/torum-transparent-cmc.png?1631760805',
            'coingeckoId': 'torum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WINTER',
            'name': 'Winter',
            'address': '0xCcbA0b2bc4BAbe4cbFb6bD2f1Edc2A9e86b7845f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25367/thumb/winter.png?1651317666',
            'coingeckoId': 'winter',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NEC',
            'name': 'Ethfinex Nectar Token',
            'address': '0xCc80C051057B774cD75067Dc48f8987C4Eb97A5e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xcc80c051057b774cd75067dc48f8987c4eb97a5e.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'BHNY',
            'name': 'SBU Honey',
            'address': '0xCc802c45B55581713cEcd1Eb17BE9Ab7fcCb0844',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26079/thumb/honey.png?1655694712',
            'coingeckoId': 'sbu-honey',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHO',
            'name': 'Showcase',
            'address': '0xCc0014cCb39F6e86b1BE0f17859A783B6722722F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15084/thumb/icon_%284%29.png?1619662298',
            'coingeckoId': 'showcase-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KINE',
            'name': 'Kine Protocol',
            'address': '0xCbfef8fdd706cde6F208460f2Bf39Aa9c785F05D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xcbfef8fdd706cde6f208460f2bf39aa9c785f05d.png',
            'coingeckoId': 'kine-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MFF',
            'name': 'Meta Farmer Finance',
            'address': '0xCbfa5F7E44D7cd08666766C2935e2fdaA953178F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23800/thumb/RGJiUIeo_400x400.jpg?1645442711',
            'coingeckoId': 'meta-farmer-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRST',
            'name': 'WeTrust',
            'address': '0xCb94be6f13A1182E4A4B6140cb7bf2025d28e41B',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/645/thumb/wetrust.png?1547034370',
            'coingeckoId': 'wetrust',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ROOT',
            'name': 'Rootkit',
            'address': '0xCb5f72d37685C3D5aD0bB5F982443BC8FcdF570E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xcb5f72d37685c3d5ad0bb5f982443bc8fcdf570e.png',
            'coingeckoId': 'rootkit',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'KOBE',
            'name': 'Shabu Shabu',
            'address': '0xCb4e8CafEDa995da5cEdfda5205BD5664a12b848',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13577/thumb/shabu_shabu_logo.jpg?1609901993',
            'coingeckoId': 'shabu-shabu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANRX',
            'name': 'AnRKey X',
            'address': '0xCae72A7A0Fd9046cf6b165CA54c9e3a3872109E0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xcae72a7a0fd9046cf6b165ca54c9e3a3872109e0.png',
            'coingeckoId': 'anrkey-x',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'wsOHM',
            'name': 'WrappedsOHM',
            'address': '0xCa76543Cf381ebBB277bE79574059e32108e3E65',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://static.debank.com/image/token/logo_url/0xca76543cf381ebbb277be79574059e32108e3e65/c7b207d06b64e982e3bedfacb7b02a1a.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'RIV',
            'name': 'Richverse',
            'address': '0xCa5e32d44F1744001b5600Dc2F5F5e0BbB6E9D3e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26892/thumb/WechatIMG2340.jpeg?1660708132',
            'coingeckoId': 'richverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WHEY',
            'name': 'WHEY',
            'address': '0xCa5d29B3e74D59EBcDF09111495d86F319886A40',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14883/thumb/WHEY_logo_black_0421_v1.png?1618893173',
            'coingeckoId': 'whey',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SEEN',
            'name': 'SEEN',
            'address': '0xCa3FE04C7Ee111F0bbb02C328c699226aCf9Fd33',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xca3fe04c7ee111f0bbb02c328c699226acf9fd33.png',
            'coingeckoId': 'seen',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'NOTE',
            'name': 'Notional Finance',
            'address': '0xCFEAead4947f0705A14ec42aC3D44129E1Ef3eD5',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xcfeaead4947f0705a14ec42ac3d44129e1ef3ed5.png',
            'coingeckoId': 'notional-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SDG',
            'name': 'SyncDAO Governance',
            'address': '0xCF8829AE9384540c886A151fac3a865794cB9a01',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21085/thumb/SyncDAO-s-icon_1_200x200.png?1638312055',
            'coingeckoId': 'syncdao-governance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GMNG',
            'name': 'Global Gaming',
            'address': '0xCF81E6FDB8d7a743c3d4C3542a5356ff197eA1C0',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12792/thumb/output-onlinepngtools.png_small.png?1602581337',
            'coingeckoId': 'global-gaming',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EDGELON',
            'name': 'Lorde Edge',
            'address': '0xCF58b4E4863C0D085bd1C65B3f2932e261547fab',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20006/thumb/edgelon.png?1636365734',
            'coingeckoId': 'lorde-edge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BEPRO',
            'name': 'BEPRO Network',
            'address': '0xCF3C8Be2e2C42331Da80EF210e9B1b307C03d36A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xcf3c8be2e2c42331da80ef210e9b1b307c03d36a.png',
            'coingeckoId': 'bepro-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'DAO1',
            'name': 'DAO1',
            'address': '0xCE3f6f6672616c39D8B6858F8DAC9902eCa42C84',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16193/thumb/dao1.PNG?1623276970',
            'coingeckoId': 'dao1',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAITAMA',
            'name': 'Saitama',
            'address': '0xCE3f08e664693ca792caCE4af1364D5e220827B2',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xce3f08e664693ca792cace4af1364d5e220827b2.png',
            'coingeckoId': 'saitama-inu',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MVRS',
            'name': 'MetaverseAir',
            'address': '0xCDeEeaaf2E96c25c679155e3854169c2f336b931',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21426/thumb/mvrs.jpg?1639117594',
            'coingeckoId': 'metaverseair',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MNDCC',
            'name': 'Mondo Community Coin',
            'address': '0xCDb9D30a3BA48CdfCB0EcbE19317E6cf783672f1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18680/thumb/mndcc_bildmarke_256px.png?1632965673',
            'coingeckoId': 'mondo-community-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BCIO',
            'name': 'Blockchain io',
            'address': '0xCDC412F306e0C51e3249B88C65423Cd16b322673',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4168/thumb/BCIO_logo_square_256px.png?1547039429',
            'coingeckoId': 'blockchain-io',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HUR',
            'name': 'Hurify',
            'address': '0xCDB7eCFd3403Eef3882c65B761ef9B5054890a47',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2705/thumb/Hurify.jpg?1547036915',
            'coingeckoId': 'hurify',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CFL365',
            'name': 'CFL365 Finance',
            'address': '0xCD6Adc6b8Bd396E2D53cCD7D7257B4De55bE4fbe',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17548/thumb/cfl365.PNG?1628218865',
            'coingeckoId': 'cfl365-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIS',
            'name': 'Themis',
            'address': '0xCD1cb16a67937ff8Af5D726e2681010cE1E9891a',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13478/thumb/3uZAPv2CbXF5txM.png?1608947522',
            'coingeckoId': 'themis-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFTK',
            'name': 'NFTWiki',
            'address': '0xCCb4dFdb4f95697ab5c389185f0Ba9042A78576F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21970/thumb/PfGXwOcW_400x400.jpg?1640560379',
            'coingeckoId': 'nftwiki',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SLP',
            'name': 'Smooth Love Potion',
            'address': '0xCC8Fa225D80b9c7D42F96e9570156c65D6cAAa25',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xcc8fa225d80b9c7d42f96e9570156c65d6caaa25.png',
            'coingeckoId': 'smooth-love-potion',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SWAP',
            'name': 'Trustswap',
            'address': '0xCC4304A31d09258b0029eA7FE63d032f52e44EFe',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xcc4304a31d09258b0029ea7fe63d032f52e44efe.png',
            'coingeckoId': 'trustswap',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'CDAO',
            'name': 'Catcher DAO',
            'address': '0xCC1c0FBAEcf0fe1C4703d77ec284Cdf8a06253C5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26747/thumb/logo200.png?1660014948',
            'coingeckoId': 'catcher-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GET',
            'name': 'GET',
            'address': '0xCC1F757E3fa67B70E761C71F4b75B1E9F72263AF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27210/thumb/LOGO_%281%29.jpeg?1662609050',
            'coingeckoId': 'get',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AREN',
            'name': 'Aave REN',
            'address': '0xCC12AbE4ff81c9378D670De1b57F8e0Dd228D77a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xcc12abe4ff81c9378d670de1b57f8e0dd228d77a.png',
            'coingeckoId': 'aave-ren',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MVEDA',
            'name': 'MedicalVeda',
            'address': '0xCBe7142F5c16755D8683BA329EFA1ABF7b54482d',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13069/thumb/medicalveda_new_logo_final_%281%29.png?1604894690',
            'coingeckoId': 'medicalveda',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OSAKU',
            'name': 'The Tale of Osaku',
            'address': '0xCBCCBb06F572df174a7DA47684cdC28121fec421',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26953/thumb/photo_2022-08-12_05-35-57.jpg?1660988433',
            'coingeckoId': 'the-tale-of-osaku',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CIG',
            'name': 'Cigarette',
            'address': '0xCB56b52316041A62B6b5D0583DcE4A8AE7a3C629',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xCB56b52316041A62B6b5D0583DcE4A8AE7a3C629/logo.png',
            'coingeckoId': 'cigarette-token',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'AX',
            'name': 'AurusX',
            'address': '0xCB0D82f4DFA503C9e3b8Abc7A3Caa01175b2dA39',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27871/thumb/AurusX_token_2D.jpg?1666165444',
            'coingeckoId': 'aurusx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'THEAD',
            'name': 'The Akragas Decadrachm',
            'address': '0xCB056A2f3cA0B82aB69dcbd677875D68B74D6189',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27078/thumb/200x200.PNG?1661761303',
            'coingeckoId': 'the-akragas-decadrachm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TALK',
            'name': 'Talken',
            'address': '0xCAabCaA4ca42e1d86dE1a201c818639def0ba7A7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18146/thumb/4NNLx3BF_400x400.jpg?1630675291',
            'coingeckoId': 'talken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'R34P',
            'name': 'R34P',
            'address': '0xCAEaf8381D4B20b43AFA42061D6f80319A8881F6',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13393/thumb/r34p_logo.png?1608100330',
            'coingeckoId': 'r34p',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HLD',
            'name': 'Hackerlabs DAO',
            'address': '0xCA1308E38340C69E848061aA2C3880daeB958187',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24670/thumb/YFYHhGlJ_400x400.jpg?1648540176',
            'coingeckoId': 'hackerlabs-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AMLT',
            'name': 'AMLT Network',
            'address': '0xCA0e7269600d353F70b14Ad118A49575455C0f2f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xca0e7269600d353f70b14ad118a49575455c0f2f.png',
            'coingeckoId': 'coinfirm-amlt',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'FRIN',
            'name': 'Fringe Finance',
            'address': '0xC9fE6E1C76210bE83DC1B5b20ec7FD010B0b1D15',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13222/thumb/frin.png?1642932787',
            'coingeckoId': 'fringe-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '2GT',
            'name': '2gether',
            'address': '0xC96c1609A1a45CcC667B2b7FA6508e29617f7b69',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14516/thumb/m329Ic5i_400x400.jpg?1616647761',
            'coingeckoId': '2gether-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SEED',
            'name': 'Seed Venture',
            'address': '0xC969e16e63fF31ad4BCAc3095C616644e6912d79',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10860/thumb/Seed.png?1585204998',
            'coingeckoId': 'seed-venture',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PGPAY',
            'name': 'PGPay',
            'address': '0xC949fC82A15964FB5B97e5cf8f9ffed139086821',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6351/thumb/pgpay-logo.jpg?1582515723',
            'coingeckoId': 'puregold-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COLLG',
            'name': 'Collateral Pay Governance',
            'address': '0xC92276872CD1907d2e00B58A81a9bbd275B0a4ba',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17026/thumb/collg.PNG?1626138703',
            'coingeckoId': 'collateral-pay-governance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZZ',
            'name': 'ZigZag',
            'address': '0xC91a71A1fFA3d8B22ba615BA1B9c01b2BBBf55ad',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26141/thumb/zig_zag.?1656248564',
            'coingeckoId': 'zigzag-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RIFI',
            'name': 'Rikkei Finance',
            'address': '0xC8EF1460277EA47d179dEc66D1c5f8b7f7aE5a28',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21309/thumb/rikkei-finance.jpeg?1638888855',
            'coingeckoId': 'rikkei-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APM',
            'name': 'apM Coin',
            'address': '0xC8C424B91D8ce0137bAB4B832B7F7D154156BA6c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10069/thumb/PvDCvh5h_400x400.png?1575460334',
            'coingeckoId': 'apm-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ONEBTC',
            'name': 'Legacy oneBTC',
            'address': '0xC88F47067dB2E25851317A2FDaE73a22c0777c37',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/onebtc.jpg',
            'coingeckoId': 'onebtc',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'FAST',
            'name': 'FastSwap',
            'address': '0xC888A0Ab4831A29e6cA432BaBf52E353D23Db3c2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13567/thumb/fastswap.jpeg?1622794717',
            'coingeckoId': 'fastswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ISP',
            'name': 'Ispolink',
            'address': '0xC8807f0f5BA3fa45FfBdc66928d71c5289249014',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15283/thumb/isolink.PNG?1620352267',
            'coingeckoId': 'ispolink',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UPP',
            'name': 'Sentinel Protocol',
            'address': '0xC86D054809623432210c107af2e3F619DcFbf652',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xc86d054809623432210c107af2e3f619dcfbf652.png',
            'coingeckoId': 'sentinel-protocol',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': '1-UP',
            'name': '1 UP',
            'address': '0xC86817249634ac209bc73fCa1712bBd75E37407d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17376/thumb/1up.png?1636535847',
            'coingeckoId': '1-up',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ECC',
            'name': 'Empire Capital',
            'address': '0xC84D8d03aA41EF941721A4D77b24bB44D7C7Ac55',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21221/thumb/2022-07-13_18.49.44.jpg?1657709418',
            'coingeckoId': 'empire-capital-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIDBEAR',
            'name': '3X Short Midcap Index Token',
            'address': '0xC82abB524257C8ee4790BFDefB452b2d6a395e21',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10330/thumb/683JEXMN_400x400_%281%29.png?1578051961',
            'coingeckoId': '3x-short-midcap-index-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARC',
            'name': 'Arc',
            'address': '0xC82E3dB60A52CF7529253b4eC688f631aad9e7c2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24235/thumb/arc.png?1647694270',
            'coingeckoId': 'arc',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'SKRY',
            'name': 'Sakaryaspor',
            'address': '0xC81946C6E0e15163b14aBD4B5008F3D900B2A736',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22042/thumb/16529.png?1640674353',
            'coingeckoId': 'sakaryaspor',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MASK',
            'name': 'MASK Vault  NFTX ',
            'address': '0xC7a8B45E184138114E6085C82936A8Db93DD156a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17046/thumb/Hashie.png?1626147674',
            'coingeckoId': 'mask-vault-nftx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MNT',
            'name': 'microNFT',
            'address': '0xC7C36Ee729968944A86e0cD504DeCcd3B0eDBaE1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27510/thumb/qEXG4boD_400x400.jpeg?1664349800',
            'coingeckoId': 'micronft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAITAX',
            'name': 'SaitamaX',
            'address': '0xC795fBa221f7920F1C6ac0f1598886742D8Ea661',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19419/thumb/6mrg5N1.png?1635213446',
            'coingeckoId': 'saitamax',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LACE',
            'name': 'Lovelace',
            'address': '0xC789f877FBB6aa4457EE5531cCeB76da6d3D1e3F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'VIDY',
            'name': 'VIDY',
            'address': '0xC77b230F31b517F1ef362e59c173C2BE6540B5E8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5088/thumb/vidy-logo.jpg?1547040486',
            'coingeckoId': 'vidy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PSL',
            'name': 'Pastel',
            'address': '0xC775C0C30840Cb9F51e21061B054ebf1A00aCC29',
            'decimals': 5,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14293/thumb/A4qGwEij_400x400.jpg?1615290394',
            'coingeckoId': 'pastel',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COL',
            'name': 'COL',
            'address': '0xC76FB75950536d98FA62ea968E1D6B45ffea2A55',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xc76fb75950536d98fa62ea968e1d6b45ffea2a55.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'RED',
            'name': 'RED TOKEN',
            'address': '0xC744df3419A8c9Bd4D6b9852A503eb1C5308A326',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27153/thumb/red.png?1662204588',
            'coingeckoId': 'red-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LEDU',
            'name': 'Education Ecosystem',
            'address': '0xC741f06082AA47F93729070aD0dD95E223Bda091',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3451/thumb/LEDU-education-ecosystem.png?1547038165',
            'coingeckoId': 'education-ecosystem',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'OSEA',
            'name': 'Omnisea',
            'address': '0xC72633F995e98Ac3BB8a89e6a9C4Af335C3D6E44',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26475/thumb/293837892_407994084681555_3167689470652146992_n.png?1658195766',
            'coingeckoId': 'omnisea',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IETH',
            'name': 'iEthereum',
            'address': '0xC71D8BaAa436aa7E42DA1f40bEc48F11AB3c9E4A',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1065/thumb/ieth.png?1600765203',
            'coingeckoId': 'iethereum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HELIOS',
            'name': 'Mission Helios',
            'address': '0xC6d1f1D5a46de07E73091f1c8793293B203f01a1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18295/thumb/helios.png?1644049696',
            'coingeckoId': 'mission-helios',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INUINU',
            'name': 'Inu Inu',
            'address': '0xC6bDb96E29c38DC43f014Eed44dE4106A6A8eB5f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26391/thumb/logo_square_200.png?1657752596',
            'coingeckoId': 'inu-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTT',
            'name': 'BitTorrent',
            'address': '0xC669928185DbCE49d2230CC9B0979BE6DC797957',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22457/thumb/btt_logo.png?1643857231',
            'coingeckoId': 'bittorrent',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASUNAINU',
            'name': 'Asuna Inu',
            'address': '0xC656B2279B0FdF761e832133B06CE607fBBcbceb',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19214/thumb/asuna-logo-200px.jpg?1642668987',
            'coingeckoId': 'asuna-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOCUS',
            'name': 'Locus Chain',
            'address': '0xC64500DD7B0f1794807e67802F8Abbf5F8Ffb054',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6903/thumb/32169759_2050428658545017_7287666225423843328_n.jpg?1547043264',
            'coingeckoId': 'locus-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTFA',
            'name': 'Banana Task Force Ape',
            'address': '0xC631bE100F6Cf9A7012C23De5a6ccb990EAFC133',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25032/thumb/ZZpipp8e_400x400.jpg?1649851881',
            'coingeckoId': 'banana-task-force-ape',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GLDX',
            'name': 'Goldex',
            'address': '0xC631120155621Ee625835ec810B9885cDd764cd6',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17814/thumb/gldx-logo-200x200.png?1629337485',
            'coingeckoId': 'goldex-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTRA',
            'name': 'MetaRare',
            'address': '0xC62DEF1701309bb76e6B39B6ab8B5fAC910A3C87',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24276/thumb/Logo_200x200_PNG.png?1647180519',
            'coingeckoId': 'metarare',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FR',
            'name': 'Freedom Reserve',
            'address': '0xC626e0619aC79AFEa9281c8eB9b1a9f9D3Fab532',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13342/thumb/J6uNL2FS_400x400.jpg?1607589046',
            'coingeckoId': 'freedom-reserve',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WFAIR',
            'name': 'Wallfair',
            'address': '0xC6065B9fc8171Ad3D29bad510709249681758972',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18920/thumb/wallfair_logo.png?1633942136',
            'coingeckoId': 'wallfair',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OJE',
            'name': 'Oje',
            'address': '0xC5ec4f1FC86DA45AE788EC98756BA70B57676409',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19811/thumb/Hv-ROnYV_400x400.jpg?1635924634',
            'coingeckoId': 'oje-token',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'STP',
            'name': 'ShowTime Potocol',
            'address': '0xC5e86E01F8B63178eE2039bfe51A4f73dd10d402',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27509/thumb/D92uffI__400x400.jpeg?1664335190',
            'coingeckoId': 'showtime-potocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MECHA',
            'name': 'Mechanium',
            'address': '0xC5bcc8BA3F33Ab0d64f3473e861BDC0685b19Ef5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24374/thumb/w4K4OOMo_400x400.jpg?1647940092',
            'coingeckoId': 'mechachain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GTO',
            'name': 'Gifto',
            'address': '0xC5bBaE50781Be1669306b9e001EFF57a2957b09d',
            'decimals': 5,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xc5bbae50781be1669306b9e001eff57a2957b09d.png',
            'coingeckoId': 'gifto',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'LIT',
            'name': 'LIT',
            'address': '0xC5b3D3231001a776123194Cf1290068e8b0C783b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21344/thumb/LitLogo_CG.png?1639990605',
            'coingeckoId': 'lit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WCI',
            'name': 'WORLD CUP INU',
            'address': '0xC5a9BC46A7dbe1c6dE493E84A18f02E70E2c5A32',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27489/thumb/2022-09-26_20.55.02-removebg-preview.png?1664253723',
            'coingeckoId': 'world-cup-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EURT',
            'name': 'Euro Tether',
            'address': '0xC581b735A1688071A1746c968e0798D642EDE491',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xdac17f958d2ee523a2206206994597c13d831ec7.png',
            'coingeckoId': 'tether-eurt',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'PTF',
            'name': 'PowerTrade Fuel',
            'address': '0xC57d533c50bC22247d49a368880fb49a1caA39F7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xc57d533c50bc22247d49a368880fb49a1caa39f7.png',
            'coingeckoId': 'powertrade-fuel',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BBH',
            'name': 'Beavis and Butthead',
            'address': '0xC56f38A1E1A14727D89eccdc97d23A59159b31Bd',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19213/thumb/bbh.jpeg?1634696594',
            'coingeckoId': 'beavis-and-butthead',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CNB',
            'name': 'Coinsbit Token',
            'address': '0xC538143202f3b11382D8606aae90a96b042a19DB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10340/thumb/cbicon.png?1578637386',
            'coingeckoId': 'coinsbit-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETHV',
            'name': 'Ethereum Volatility Index Token',
            'address': '0xC53342fd7575f572b0fF4569e31941A5B821aC76',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xc53342fd7575f572b0ff4569e31941a5b821ac76.png',
            'coingeckoId': 'ethereum-volatility-index-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ZCX',
            'name': 'Unizen',
            'address': '0xC52C326331E9Ce41F04484d3B5E5648158028804',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14830/thumb/7xjpHaG.png?1618564961',
            'coingeckoId': 'unizen',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HI',
            'name': 'hi Dollar',
            'address': '0xC4f6E93AEDdc11dc22268488465bAbcAF09399aC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17618/thumb/unnamed_%281%29.png?1628665739',
            'coingeckoId': 'hi-dollar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STARX',
            'name': 'STARX',
            'address': '0xC4e8A9D47000Ab8E59c7031e311762c68215e467',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8417/thumb/starworks.PNG?1640681958',
            'coingeckoId': 'starworks-global-ecosystem',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CREDIT',
            'name': 'PROXI DeFi',
            'address': '0xC4cB5793BD58BaD06bF51FB37717b86B02CBe8A4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12212/thumb/_credit.png?1598235420',
            'coingeckoId': 'credit-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HOICHI',
            'name': 'Hoichi',
            'address': '0xC4EE0aA2d993ca7C9263eCFa26c6f7e13009d2b6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27227/thumb/3dBs4VvW_400x400.jpeg?1662711951',
            'coingeckoId': 'hoichi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHAIN',
            'name': 'Chain Games',
            'address': '0xC4C2614E694cF534D407Ee49F8E44D125E4681c4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xc4c2614e694cf534d407ee49f8e44d125e4681c4.png',
            'coingeckoId': 'chain-games',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'CHG',
            'name': 'Charg Coin',
            'address': '0xC4A86561cb0b7EA1214904f26E6D50FD357C7986',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2159/thumb/charg-coin.png?1547036459',
            'coingeckoId': 'charg-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CMERGE',
            'name': 'CoinMerge  ERC20 ',
            'address': '0xC48b4814fAEd1CCc885DD6fDe62A6474AeCbb19a',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xc48b4814faed1ccc885dd6fde62a6474aecbb19a.png',
            'coingeckoId': 'coinmerge',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BOSON',
            'name': 'Boson Protocol',
            'address': '0xC477D038d5420C6A9e0b031712f61c5120090de9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xc477d038d5420c6a9e0b031712f61c5120090de9.png',
            'coingeckoId': 'boson-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BTNT',
            'name': 'BitNautic',
            'address': '0xC45DbdF28844fdB1482C502897d433aC08d6cCd0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2875/thumb/Logobit.jpg?1547037044',
            'coingeckoId': 'bitnautic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VINCI',
            'name': 'Vinci',
            'address': '0xC4398e483B178AC559b4fe665c6BCE70A179f80F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'HOKK',
            'name': 'Hokkaidu Inu',
            'address': '0xC40AF1E4fEcFA05Ce6BAb79DcD8B373d2E436c4E',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://api.rubic.exchange/assets/ethereum/0xc40af1e4fecfa05ce6bab79dcd8b373d2e436c4e/logo.png',
            'coingeckoId': 'hokkaidu-inu',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MM',
            'name': 'Millimeter',
            'address': '0xC3c221fE28c33814c28c822b631fd76047eF1a63',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12941/thumb/mm%EB%A1%9C%EA%B3%A0-2.png?1603765868',
            'coingeckoId': 'millimeter',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FAKT',
            'name': 'Medifakt',
            'address': '0xC3FFbE26E9446aC52008FfC8C1191d60A12bdC48',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25248/thumb/fxOi9ZYI_400x400.png?1651031310',
            'coingeckoId': 'medifakt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NPICK',
            'name': 'NPick Block',
            'address': '0xC3DCA8F61B275d1e88f3ea31B3e311c49f56b24B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25151/thumb/Logo200_%281%29.png?1650459923',
            'coingeckoId': 'npick-block',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COGE',
            'name': 'Cogecoin',
            'address': '0xC382e04099A435439725BB40647e2B32dC136806',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16791/thumb/Dog-Logo_1x.png?1625033809',
            'coingeckoId': 'cogecoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAB',
            'name': 'Basis Bond',
            'address': '0xC36824905dfF2eAAEE7EcC09fCC63abc0af5Abc5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xC36824905dfF2eAAEE7EcC09fCC63abc0af5Abc5/logo.png',
            'coingeckoId': 'basis-bond',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'ITS',
            'name': 'Iteration Syndicate',
            'address': '0xC32cC5b70BEe4bd54Aa62B9Aefb91346d18821C4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13297/thumb/1_LOssD4ENHv72I5e9PAsndA_%281%29.png?1607223580',
            'coingeckoId': 'iteration-syndicate',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'yDAIv3',
            'name': 'iearn DAI v3',
            'address': '0xC2cB1040220768554cf699b0d863A3cd4324ce32',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xc2cb1040220768554cf699b0d863a3cd4324ce32.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'NRGY',
            'name': 'NRGY Defi',
            'address': '0xC29aCAc647c63DBd8618E817d41eA9De69174aE1',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18075/thumb/nrgy.jpeg?1630391703',
            'coingeckoId': 'nrgy-defi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EQUAD',
            'name': 'Quadrant Protocol',
            'address': '0xC28e931814725BbEB9e670676FaBBCb694Fe7DF2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xc28e931814725bbeb9e670676fabbcb694fe7df2.png',
            'coingeckoId': 'quadrant-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SASHIMI',
            'name': 'Sashimi',
            'address': '0xC28E27870558cF22ADD83540d2126da2e4b464c2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xc28e27870558cf22add83540d2126da2e4b464c2.png',
            'coingeckoId': 'sashimi',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'CUSD',
            'name': 'Coin98 Dollar',
            'address': '0xC285B7E09A4584D027E5BC36571785B515898246',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26588/thumb/CUSD-01.png?1658909049',
            'coingeckoId': 'coin98-dollar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EGG',
            'name': 'Waves Ducks',
            'address': '0xC2708A3A4ba7F64bddc1A49F92f941Bc77Cad23a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17298/thumb/200x200_pixel.png?1627288546',
            'coingeckoId': 'waves-ducks',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LEOBULL',
            'name': '3X Long LEO Token',
            'address': '0xC2685307Ef2B8842fbf3DeF432408C46Bd0420fd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10231/thumb/683JEXMN_400x400_%281%29.png?1576618672',
            'coingeckoId': '3x-long-leo-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SCURVE',
            'name': 'LP sCurve',
            'address': '0xC25a3A3b969415c80451098fa907EC722572917F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11899/thumb/Curvefi_sCrv_32.png?1595931870',
            'coingeckoId': 'lp-scurve',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ECO',
            'name': 'EcoFi',
            'address': '0xC242Eb8e4E27EAE6a2A728A41201152F19595C83',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14803/thumb/logo_-_2021-04-16T081409.817.png?1618532056',
            'coingeckoId': 'ecofi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WGR',
            'name': 'Wagerr',
            'address': '0xC237868a9c5729bdF3173dDDacaa336a0a5BB6e0',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/759/thumb/syGKmAT.png?1619597241',
            'coingeckoId': 'wagerr',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PTU',
            'name': 'Pintu',
            'address': '0xC229c69eB3BB51828D0cAA3509A05a51083898dd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20281/thumb/image_1_8dd79a68aa.png?1636733599',
            'coingeckoId': 'pintu-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MGCHI',
            'name': 'Metagochi',
            'address': '0xC1a85Faa09c7f7247899F155439c5488B43E8429',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21403/thumb/Lw9uYzse_400x400.jpg?1639086534',
            'coingeckoId': 'metagochi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZONE',
            'name': 'GridZone io',
            'address': '0xC1D9B5A0776d7C8B98b8A838e5a0DD1Bc5Fdd53C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18098/thumb/Gridzone_logo_V2.png?1630468285',
            'coingeckoId': 'gridzone',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SENSO',
            'name': 'SENSO',
            'address': '0xC19B6A4Ac7C7Cc24459F08984Bbd09664af17bD1',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11064/thumb/senso.png?1587776458',
            'coingeckoId': 'senso',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'QLINDO',
            'name': 'QLINDO',
            'address': '0xC18c07a18198A6340cf4d94855fE5eb6DD33b46E',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26851/thumb/21315.png?1660467490',
            'coingeckoId': 'qlindo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ENS',
            'name': 'Ethereum Name Service',
            'address': '0xC18360217D8F7Ab5e7c516566761Ea12Ce7F9D72',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xc18360217d8f7ab5e7c516566761ea12ce7f9d72.png',
            'coingeckoId': 'ethereum-name-service',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'optimism',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'RISE',
            'name': 'EverRise',
            'address': '0xC17c30e98541188614dF99239cABD40280810cA3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16367/thumb/Logo_EverRise_Icon_logo.png?1642576670',
            'coingeckoId': 'everrise',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MCC',
            'name': 'Multi Chain Capital',
            'address': '0xC146B7CdBaff065090077151d391f4c96Aa09e0C',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21621/thumb/mcc.png?1639622572',
            'coingeckoId': 'multi-chain-capital-2',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'PMT',
            'name': 'DAO PlayMarket 2 0',
            'address': '0xC1322D8aE3B0e2E437e0AE36388D0CFD2C02f1c9',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1514/thumb/pmt.png?1547035648',
            'coingeckoId': 'playmarket',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'cWBTC',
            'name': 'Compound Wrapped BTC',
            'address': '0xC11b1268C1A384e55C48c2391d8d480264A3A7F4',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xc11b1268c1a384e55c48c2391d8d480264a3a7f4.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'GLO',
            'name': 'Glosfer',
            'address': '0xC0e6737A29DE7a00e2f6011924eB257106CB082f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9237/thumb/ItBMqQKt_400x400.jpg?1565336364',
            'coingeckoId': 'glosfer-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTRFLY',
            'name': 'Redacted Cartel',
            'address': '0xC0d4Ceb216B3BA9C3701B291766fDCbA977ceC3A',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://static.debank.com/image/eth_token/logo_url/0xc0d4ceb216b3ba9c3701b291766fdcba977cec3a/037388753fc088e354331f3c7194641c.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AURA',
            'name': 'Aura Finance',
            'address': '0xC0c293ce456fF0ED870ADd98a0828Dd4d2903DBF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25942/thumb/logo.png?1654784187',
            'coingeckoId': 'aura-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DUCK',
            'name': 'DLP Duck',
            'address': '0xC0bA369c8Db6eB3924965e5c4FD0b4C1B91e305F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xc0ba369c8db6eb3924965e5c4fd0b4c1b91e305f.png',
            'coingeckoId': 'dlp-duck-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'DONUT',
            'name': 'Donut',
            'address': '0xC0F9bD5Fa5698B6505F643900FFA515Ea5dF54A9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xc0f9bd5fa5698b6505f643900ffa515ea5df54a9.png',
            'coingeckoId': 'donut',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'HVN',
            'name': 'Hiveterminal',
            'address': '0xC0Eb85285d83217CD7c891702bcbC0FC401E2D9D',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/899/thumb/Hiveterminal_token.jpg?1547034726',
            'coingeckoId': 'hiveterminal',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PTPA',
            'name': 'Pumptopia',
            'address': '0xC0B182B2E06164568a3310Ca1086e9bf19209E6c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24262/thumb/pinksale-200x200-1.jpg?1647171185',
            'coingeckoId': 'pumptopia',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GYEN',
            'name': 'GYEN',
            'address': '0xC08512927D12348F6620a698105e1BAac6EcD911',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xc08512927d12348f6620a698105e1baac6ecd911.png',
            'coingeckoId': 'gyen',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'LINA',
            'name': 'LINA',
            'address': '0xC05d14442A510De4D3d71a3d316585aA0CE32b50',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5412/thumb/3083.png?1642981471',
            'coingeckoId': 'lina',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QUROZ',
            'name': 'Qfora',
            'address': '0xC05A4eD46Ef5b0678D56FFF5a877b4B6B32077bB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25827/thumb/20386.png?1654064350',
            'coingeckoId': 'qfora',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WETH',
            'name': 'WETH',
            'address': '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2.png',
            'coingeckoId': 'weth',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WCX',
            'name': 'WeCoOwn',
            'address': '0xC014186Cf1Ba36032aaEc7f96088f09eb3934347',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14497/thumb/WCX-Token-Logo.png?1616555141',
            'coingeckoId': 'wecoown',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALEPH',
            'name': 'aleph.im',
            'address': '0xC0134b5B924c2FCA106eFB33C45446c466FBe03e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1634696583689_8146654283626338.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SNX',
            'name': 'Synthetix Network',
            'address': '0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f.png',
            'coingeckoId': 'havven',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'optimism',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CUBT',
            'name': 'CubToken',
            'address': '0xBf9e72eEb5adB8B558334c8672950B7a379D4266',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26197/thumb/photo-2022-04-20-13-35-28.jpg?1656471496',
            'coingeckoId': 'cubtoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DJBZ',
            'name': 'DaddyBezos',
            'address': '0xBf825207c74B6C3C01aB807c4f4A4FcE26EBdf0f',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19782/thumb/tOyy80Z.png?1635846414',
            'coingeckoId': 'daddybezos',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFTFY',
            'name': 'Nftfy',
            'address': '0xBf6Ff49FfD3d104302Ef0AB0F10f5a84324c091c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15292/thumb/nfty.PNG?1620365235',
            'coingeckoId': 'nftfy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MRCH',
            'name': 'MerchDAO',
            'address': '0xBeD4AB0019ff361d83ddeB74883DAC8a70f5ea1e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14540/thumb/logo_256x256.png?1616891356',
            'coingeckoId': 'merchdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWING',
            'name': 'Swing DAO',
            'address': '0xBeC5938FD565CbEc72107eE39CdE1bc78049537d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27779/thumb/aUQKFUM.png?1665731942',
            'coingeckoId': 'swing-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHARD',
            'name': 'Shard Coin',
            'address': '0xBeBdab6DA046Bc49ffBb61fbD7b33157Eb270D05',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3527/thumb/8680493537c30f81917d034613b289e9.png?1547038326',
            'coingeckoId': 'shard',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CBETH',
            'name': 'Coinbase Wrapped Staked ETH',
            'address': '0xBe9895146f7AF43049ca1c1AE358B0541Ea49704',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1661417577387_20378355625066513.png',
            'coingeckoId': 'coinbase-wrapped-staked-eth',
            'listedIn': [
                'coingecko',
                'openocean'
            ]
        },
        {
            'symbol': 'MICRO',
            'name': 'Micromines',
            'address': '0xBe6C8f2810EF39420d2DC2901b8414C8c45FEE6D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7000/thumb/micromines.png?1548085033',
            'coingeckoId': 'micromines',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CVT',
            'name': 'CyberVein',
            'address': '0xBe428c3867F05deA2A89Fc76a102b544eaC7f772',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xbe428c3867f05dea2a89fc76a102b544eac7f772.png',
            'coingeckoId': 'cybervein',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'BETA',
            'name': 'Beta Finance',
            'address': '0xBe1a001FE942f96Eea22bA08783140B9Dcc09D28',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18715/thumb/beta_finance.jpg?1633087053',
            'coingeckoId': 'beta-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'fiREP',
            'name': 'bZx REP iToken',
            'address': '0xBd56E9477Fc6997609Cf45F84795eFbDAC642Ff1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xbd56e9477fc6997609cf45f84795efbdac642ff1.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'EQX',
            'name': 'EQIFi',
            'address': '0xBd3de9a069648c84d27d74d701C9fa3253098B15',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17490/thumb/EQIFI_Logo_Color.png?1627968404',
            'coingeckoId': 'eqifi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WOM',
            'name': 'WOM Protocol',
            'address': '0xBd356a39BFf2cAda8E9248532DD879147221Cf76',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/wom.jpg',
            'coingeckoId': 'wom-token',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'EMTRG',
            'name': 'Meter Governance mapped by Meter io',
            'address': '0xBd2949F67DcdC549c6Ebe98696449Fa79D988A9F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xbd2949f67dcdc549c6ebe98696449fa79d988a9f.png',
            'coingeckoId': 'meter-governance-mapped-by-meter-io',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'QOB',
            'name': 'Qobit',
            'address': '0xBd1E7f594600DCAF7f483af55deaCEc0159A064E',
            'decimals': 8,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': 'qobit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZB',
            'name': 'ZB',
            'address': '0xBd0793332e9fB844A52a205A233EF27a5b34B927',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4773/thumb/zb-token-logo.png?1547040106',
            'coingeckoId': 'zb-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EXENP',
            'name': 'ExenPay',
            'address': '0xBcf91E60a6719eB3E9308aDDf1F7c6185c2AF889',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18975/thumb/LlydYS1w_400x400.png?1634094918',
            'coingeckoId': 'exenpay-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AUSDC',
            'name': 'Aave USDC',
            'address': '0xBcca60bB61934080951369a648Fb03DF4F96263C',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xbcca60bb61934080951369a648fb03df4f96263c.png',
            'coingeckoId': 'aave-usdc',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SKULL',
            'name': 'Skull',
            'address': '0xBcc66ed2aB491e9aE7Bf8386541Fb17421Fa9d35',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10641/thumb/skull.png?1581339740',
            'coingeckoId': 'skull',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CCM',
            'name': 'Car Coin',
            'address': '0xBc6669E7914a2b327Ae428184086d8Ac88d74EfC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17119/thumb/CarCoin_icon_vector.png?1626356048',
            'coingeckoId': 'car-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UUSDC',
            'name': 'Unagii USD Coin',
            'address': '0xBc5991cCd8cAcEba01edC44C2BB9832712c29cAB',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13781/thumb/uUSDC.png?1611729843',
            'coingeckoId': 'unagii-usd-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIBTAMA',
            'name': 'Shibtama',
            'address': '0xBc276f51184ec085cd1437601eCb4bBc3D981896',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23362/thumb/shibtama.png?1643946565',
            'coingeckoId': 'shibtama',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KST',
            'name': 'Karus Starter',
            'address': '0xBc17729fDf562723f0267F79FF25aDE441056d87',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18862/thumb/logo-only.png?1645962885',
            'coingeckoId': 'ksm-starter',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BBND',
            'name': 'BeatBind',
            'address': '0xBc0d84FA6260E065F330d51621d682d2630F4Aa2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16951/thumb/beatbind-logo.png?1625836007',
            'coingeckoId': 'beatbind',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'L2',
            'name': 'Leverj Gluon',
            'address': '0xBbff34E47E559ef680067a6B1c980639EEb64D24',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xbbff34e47e559ef680067a6b1c980639eeb64d24.png',
            'coingeckoId': 'leverj-gluon',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'CALL',
            'name': 'Global Crypto Alliance',
            'address': '0xBbe761EA1447A20b75aA485b7BCad4837415d7D7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10276/thumb/gca-favicon.png?1576969530',
            'coingeckoId': 'global-crypto-alliance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MINTYS',
            'name': 'MintySwap',
            'address': '0xBbd900e05b4aF2124390D206F70bc4E583B1bE85',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17975/thumb/Minty-logo-200x200.png?1629966728',
            'coingeckoId': 'mintyswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LRC',
            'name': 'Laro',
            'address': '0xBbCD3e4EB43aA7F3f57286dA31333d53b24D0D6A',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25925/thumb/lorologo.png?1654673196',
            'coingeckoId': 'laro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'aUniDAIETH',
            'name': 'Aave Interest bearing UniDAIETH',
            'address': '0xBbBb7F2aC04484F7F04A2C2C16f20479791BbB44',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'ipfs://QmaznB5PRhMC696u8yZuzN6Uwrnp7Zmfa5CydVUMvLJc9i/aUNI%20DAI%20ETH.svg',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'HDBTC',
            'name': 'Hidigital btc',
            'address': '0xBb6CD639724417A20a7db0F45C1fb2fE532f490A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27024/thumb/20220824_132403.png?1661485211',
            'coingeckoId': 'hidigital-btc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOUL',
            'name': 'CryptoSoul',
            'address': '0xBb1f24C0c1554b9990222f036b0AaD6Ee4CAec29',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6723/thumb/cryptosoul.png?1547042952',
            'coingeckoId': 'cryptosoul',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOAR',
            'name': 'SOAR.FI',
            'address': '0xBae5F2D8a1299E5c4963eaff3312399253f27Ccb',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xbae5f2d8a1299e5c4963eaff3312399253f27ccb.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SHINNOSUKE',
            'name': 'ShinChan',
            'address': '0xBaa9AF8a83500Ac4137C555b9E58CCB3e1f2269D',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20134/thumb/shinchan.png?1636530348',
            'coingeckoId': 'shinchan-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWFL',
            'name': 'Swapfolio',
            'address': '0xBa21Ef4c9f433Ede00badEFcC2754B8E74bd538A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xba21ef4c9f433ede00badefcc2754b8e74bd538a.png',
            'coingeckoId': 'swapfolio',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'WFX',
            'name': 'WebFlix',
            'address': '0xBa1ED22C69ad00739EE2b4aBD70e270BE9e87eE2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8559/thumb/dr4OEbWK_400x400.jpg?1559199557',
            'coingeckoId': 'webflix',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WORLD',
            'name': 'World',
            'address': '0xBF494F02EE3FdE1F20BEE6242bCe2d1ED0c15e47',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13801/thumb/WORLD.png?1612843088',
            'coingeckoId': 'world-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IMC',
            'name': 'i Money Crypto',
            'address': '0xBF0741E995F469D39e4f96C0780f9a8E43f4b978',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20178/thumb/imc_Logo.png?1636604215',
            'coingeckoId': 'i-money-crypto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BRIGHT',
            'name': 'Bright Union',
            'address': '0xBEaB712832112bd7664226db7CD025B153D3af55',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17552/thumb/BrightToken_Token-only_200x200-1.png?1628227007',
            'coingeckoId': 'bright-union',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MVRS',
            'name': 'Meta MVRS',
            'address': '0xBEDE1F59Fa4412556fEF69f1B9969F2003E3f8c1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22140/thumb/mvrs-200x200.png?1640926136',
            'coingeckoId': 'meta-mvrs',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BEAN',
            'name': 'Bean',
            'address': '0xBEA0000029AD1c77D3d5D23Ba2D8893dB9d1Efab',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xbea0000029ad1c77d3d5d23ba2d8893db9d1efab.png',
            'coingeckoId': 'bean',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'DRYP',
            'name': 'Dripto',
            'address': '0xBE1fa1303e2979Ab4d4e5dF3D1c6e3656ACAb027',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25569/thumb/dryp.png?1652602736',
            'coingeckoId': 'dripto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BANKETH',
            'name': 'BankEth',
            'address': '0xBE0C826f17680d8Da620855bE89DD6544C034cA1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17401/thumb/BankEth_200x200.png?1627529730',
            'coingeckoId': 'banketh',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GUESS',
            'name': 'PeerGuess',
            'address': '0xBDCFbf5C4D91Abc0bC9709C7286d00063c0e6F22',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1743/thumb/guess.png?1547036022',
            'coingeckoId': 'peerguess',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SNOV',
            'name': 'Snovian Space',
            'address': '0xBDC5bAC39Dbe132B1E030e898aE3830017D7d969',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1330/thumb/logo2.png?1547035355',
            'coingeckoId': 'snovio',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KAI',
            'name': 'KardiaChain Token',
            'address': '0xBD6467a31899590474cE1e84F70594c53D628e46',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1634698755339_7648597495546063.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'DSD',
            'name': 'Dynamic Set Dollar',
            'address': '0xBD2F0Cd039E0BFcf88901C98c0bFAc5ab27566e3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xbd2f0cd039e0bfcf88901c98c0bfac5ab27566e3.png',
            'coingeckoId': 'dynamic-set-dollar',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'IDYP',
            'name': 'iDeFiYieldProtocol',
            'address': '0xBD100d061E120b2c67A24453CF6368E63f1Be056',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21976/thumb/i-DYP-Logo-1.png?1640570294',
            'coingeckoId': 'idefiyieldprotocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CYTR',
            'name': 'Cyclops Treasure',
            'address': '0xBD05CeE8741100010D8E93048a80Ed77645ac7bf',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12698/thumb/Untitled-design-9-removebg-preview.png?1601855912',
            'coingeckoId': 'cyclops-treasure',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PRTG',
            'name': 'Pre Retogeum',
            'address': '0xBD04ccc050058a6A422851fA6c0F92BB65EB06ca',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27397/thumb/IMG_20220921_123729_913.jpg?1663827688',
            'coingeckoId': 'pre-retogeum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GXI',
            'name': 'Genexi',
            'address': '0xBCfDAeB22ab6e10DfB99546E6240155EDC1084f7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7126/thumb/bR4FuOeq_400x400.jpg?1547043624',
            'coingeckoId': 'genexi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XNK',
            'name': 'Ink Protocol',
            'address': '0xBC86727E770de68B1060C91f6BB6945c73e10388',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xbc86727e770de68b1060c91f6bb6945c73e10388.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SRNT',
            'name': 'Serenity Financial',
            'address': '0xBC7942054F77b82e8A71aCE170E4B00ebAe67eB6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7334/thumb/serenity.jpg?1547043996',
            'coingeckoId': 'serenity-financial',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GM',
            'name': 'GM',
            'address': '0xBC7250C8c3eCA1DfC1728620aF835FCa489bFdf3',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1636698229196_08244912681377103.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ALUSD',
            'name': 'Alchemix USD',
            'address': '0xBC6DA0FE9aD5f3b0d58160288917AA56653660E9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xbc6da0fe9ad5f3b0d58160288917aa56653660e9.png',
            'coingeckoId': 'alchemix-usd',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'LXT',
            'name': 'LITEX',
            'address': '0xBC46D9961A3932f7D6b64abfdeC80C1816C4B835',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5133/thumb/litex-logo.png?1547040525',
            'coingeckoId': 'litex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BORING',
            'name': 'BoringDAO',
            'address': '0xBC19712FEB3a26080eBf6f2F7849b417FdD792CA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xbc19712feb3a26080ebf6f2f7849b417fdd792ca.png',
            'coingeckoId': 'boringdao',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'HIMEEBITS',
            'name': 'hiMEEBITS',
            'address': '0xBBeca80A4c99c3b1BD3C10E64595d0Fc7dC26eE0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28126/thumb/himeebit.png?1667640906',
            'coingeckoId': 'himeebits',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ERN',
            'name': 'Ethernity Chain',
            'address': '0xBBc2AE13b23d715c30720F079fcd9B4a74093505',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xbbc2ae13b23d715c30720f079fcd9b4a74093505.png',
            'coingeckoId': 'ethernity-chain',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'LRC',
            'name': 'Loopring',
            'address': '0xBBbbCA6A901c926F240b89EacB641d8Aec7AEafD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xbbbbca6a901c926f240b89eacb641d8aec7aeafd.png',
            'coingeckoId': 'loopring',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'optimism',
                'arbitrum_bridge',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ITAMCUBE',
            'name': 'CUBE',
            'address': '0xBBaB3bDb291b0F22BC9881895ff488A5Db67BeC8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22396/thumb/CoinGecko_Logo_%281%29.jpg?1648792005',
            'coingeckoId': 'cube',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHO',
            'name': 'Choise com',
            'address': '0xBBa39Fd2935d5769116ce38d46a71bde9cf03099',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25935/thumb/cho_%282%29.png?1663309407',
            'coingeckoId': 'choise',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TheDAO',
            'name': 'TheDAO',
            'address': '0xBB9bc244D798123fDe783fCc1C72d3Bb8C189413',
            'decimals': 16,
            'chainId': 1,
            'logoURI': 'https://ethereum-optimism.github.io/data/TheDAO/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'AXS',
            'name': 'Axie Infinity',
            'address': '0xBB0E17EF65F82Ab018d8EDd776e8DD940327B28b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xbb0e17ef65f82ab018d8edd776e8dd940327b28b.png',
            'coingeckoId': 'axie-infinity',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'DOG',
            'name': 'The Doge NFT',
            'address': '0xBAac2B4491727D78D2b78815144570b9f2Fe8899',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/11557.png',
            'coingeckoId': 'the-doge-nft',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'MEH',
            'name': 'meh',
            'address': '0xBA98C0FbebC892F5b07a42B0FeBD606913EBc981',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27714/thumb/mlogo.png?1665453127',
            'coingeckoId': 'meh',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'fiWBTC',
            'name': 'bZx WBTC iToken',
            'address': '0xBA9262578EFef8b3aFf7F60Cd629d6CC8859C8b5',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xba9262578efef8b3aff7f60cd629d6cc8859c8b5.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ARPA',
            'name': 'ARPA',
            'address': '0xBA50933C268F567BDC86E1aC131BE072C6B0b71a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8506/thumb/9u0a23XY_400x400.jpg?1559027357',
            'coingeckoId': 'arpa',
            'listedIn': [
                'coingecko',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'GRAVIAURA',
            'name': 'Gravitationally Bound AURA',
            'address': '0xBA485b556399123261a5F9c95d413B4f93107407',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26368/thumb/graviaura.png?1657606852',
            'coingeckoId': 'gravitationally-bound-aura',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MYTH',
            'name': 'Mythos',
            'address': '0xBA41Ddf06B7fFD89D1267b5A93BFeF2424eb2003',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28045/thumb/Mythos_Logos_200x200.png?1667269609',
            'coingeckoId': 'mythos',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAND',
            'name': 'Band Protocol',
            'address': '0xBA11D00c5f74255f56a5E366F4F77f5A186d7f55',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xba11d00c5f74255f56a5e366f4f77f5a186d7f55.png',
            'coingeckoId': 'band-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'REALT-S-17809-CHARE',
            'name': 'RealT   17809 Charest St  Detroit  MI 4',
            'address': '0xBA07997F594A52DF179620284B52B50A4E66227D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16601/thumb/17809-Charest-hero-1.jpg?1624543227',
            'coingeckoId': '17809-charest',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWT',
            'name': 'Swarm City',
            'address': '0xB9e7F8568e08d5659f5D29C4997173d84CdF2607',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/621/thumb/swarm-city.png?1548611426',
            'coingeckoId': 'swarm-city',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'K21',
            'name': 'K21',
            'address': '0xB9d99C33eA2d86EC5eC6b8A4dD816EBBA64404AF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xb9d99c33ea2d86ec5ec6b8a4dd816ebba64404af.png',
            'coingeckoId': 'k21',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'XDB',
            'name': 'DigitalBits',
            'address': '0xB9EefC4b0d472A44be93970254Df4f4016569d27',
            'decimals': 7,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xb9eefc4b0d472a44be93970254df4f4016569d27.png',
            'coingeckoId': 'digitalbits',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BLUSD',
            'name': 'Boosted LUSD',
            'address': '0xB9D7DdDca9a4AC480991865EfEf82E01273F79C3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27681/thumb/bLUSD_logo.png?1665213755',
            'coingeckoId': 'boosted-lusd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AUNI',
            'name': 'Aave UNI',
            'address': '0xB9D7CB55f463405CDfBe4E90a6D2Df01C2B92BF1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xb9d7cb55f463405cdfbe4e90a6d2df01c2b92bf1.png',
            'coingeckoId': 'aave-uni',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SMAC',
            'name': 'Smartchem',
            'address': '0xB9964c8B9C48800678e72b2fe7B9B365d3D0019d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20616/thumb/SMAC-icon200x200.png?1637309473',
            'coingeckoId': 'smartchem',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ABT',
            'name': 'Arcblock',
            'address': '0xB98d4C97425d9908E66E53A6fDf673ACcA0BE986',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2341/thumb/arcblock.png?1547036543',
            'coingeckoId': 'arcblock',
            'listedIn': [
                'coingecko',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'STBZ',
            'name': 'Stabilize Token',
            'address': '0xB987D48Ed8f2C468D52D6405624EADBa5e76d723',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xb987d48ed8f2c468d52d6405624eadba5e76d723.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'PAY',
            'name': 'TenX',
            'address': '0xB97048628DB6B661D4C2aA833e95Dbe1A905B280',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xb97048628db6b661d4c2aa833e95dbe1a905b280.png',
            'coingeckoId': 'tenx',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'AUDT',
            'name': 'Auditchain',
            'address': '0xB90cb79B72EB10c39CbDF86e50B1C89F6a235f2e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6398/thumb/auditchain.png?1547042519',
            'coingeckoId': 'auditchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CALI',
            'name': 'CaliCoin',
            'address': '0xB8fA12f8409DA31A4fc43D15c4c78C33d8213B9B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14998/thumb/cropped-Logo-transparent-background-1.png?1619392357',
            'coingeckoId': 'calicoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HARM',
            'name': 'Harmony Token',
            'address': '0xB8f78Ae3DF5C541657161194ddBd0c5b44D1BEe9',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27237/thumb/Untitled_design_%2815%29.png?1664068375',
            'coingeckoId': 'harmony-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BNB',
            'name': 'BNB',
            'address': '0xB8c77482e45F1F44dE1745F52C74426C631bDD52',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://static.debank.com/image/eth_token/logo_url/0xb8c77482e45f1f44de1745f52c74426c631bdd52/1177dec1b9b7f58a799c3f22d93b04e1.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ARDX',
            'name': 'ArdCoin',
            'address': '0xB8E2e2101eD11e9138803cd3e06e16dd19910647',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9432/thumb/ARDC.png?1627466542',
            'coingeckoId': 'ardcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWRV',
            'name': 'Swerve',
            'address': '0xB8BAa0e4287890a5F79863aB62b7F175ceCbD433',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xb8baa0e4287890a5f79863ab62b7f175cecbd433.png',
            'coingeckoId': 'swerve-dao',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'CFO',
            'name': 'Cfoforum',
            'address': '0xB8981aCbBF7DA95A8fF6Df79Aab935cE63434fC8',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17592/thumb/CFO_logo_200_200px.png?1628580306',
            'coingeckoId': 'cforforum-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USHIBA',
            'name': 'American Shiba',
            'address': '0xB893A8049f250b57eFA8C62D51527a22404D7c9A',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15650/thumb/american_shiba.PNG?1621476610',
            'coingeckoId': 'american-shiba',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MBL',
            'name': 'MovieBloc',
            'address': '0xB879DA8b24c9b8685dE8526cF492E954f165D74b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8460/thumb/MBL_Logo.png?1628258374',
            'coingeckoId': 'moviebloc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KEYFI',
            'name': 'KeyFi',
            'address': '0xB8647e90C0645152Fccf4d9AbB6B59Eb4AA99052',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15098/thumb/keyfi_logo.jpg?1619690054',
            'coingeckoId': 'keyfi',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'STREETH',
            'name': 'STREETH',
            'address': '0xB840d10D840eF47c233FEC1fd040F5B145a6DfA5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24954/thumb/streeth.png?1649507723',
            'coingeckoId': 'streeth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TIGER',
            'name': 'JungleKing TigerCoin',
            'address': '0xB83c27805aAcA5C7082eB45C868d955Cf04C337F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26494/thumb/jungleparty.png?1658300842',
            'coingeckoId': 'jungleking-tigercoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTV',
            'name': 'BitValve',
            'address': '0xB837b6467B4878fb5B3b81664042852593F15126',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20681/thumb/bitvalve-icon-200x200.png?1637551577',
            'coingeckoId': 'bitvalve-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VBIT',
            'name': 'VALOBIT',
            'address': '0xB8366948B4A3F07BcBf14EB1739daA42A26b07c4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12394/thumb/Untitled-design-3.png?1599550715',
            'coingeckoId': 'valobit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PAY',
            'name': 'Webpay',
            'address': '0xB827F14c95C32ae2375618eA5E505B65B5e3914D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26429/thumb/logo.jpg?1657930482',
            'coingeckoId': 'webpay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEXG',
            'name': 'Dextoken Governance',
            'address': '0xB81D70802a816B5DacBA06D708B5acF19DcD436D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12223/thumb/dextoken-logo-v2_200.png?1598408669',
            'coingeckoId': 'dextoken-governance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TEAM',
            'name': 'Maximus TEAM',
            'address': '0xB7c9E99Da8A857cE576A830A9c19312114d9dE02',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27711/thumb/TEAM.png?1665451692',
            'coingeckoId': 'maximus-team',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SYBC',
            'name': 'SYBC Coin',
            'address': '0xB74755f2896e088790f81205F7f3746e2a4b358B',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12901/thumb/sybc.png?1603346599',
            'coingeckoId': 'sybc-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GTC',
            'name': 'Game',
            'address': '0xB70835D7822eBB9426B56543E391846C107bd32C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2046/thumb/game.png?1547742459',
            'coingeckoId': 'game',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IDEX',
            'name': 'IDEX',
            'address': '0xB705268213D593B8FD88d3FDEFF93AFF5CbDcfAE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xb705268213d593b8fd88d3fdeff93aff5cbdcfae.png',
            'coingeckoId': 'aurora-dao',
            'listedIn': [
                'coingecko',
                'uniswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SYNC',
            'name': 'Sync Network',
            'address': '0xB6ff96B8A8d214544Ca0dBc9B33f7AD6503eFD32',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xb6ff96b8a8d214544ca0dbc9b33f7ad6503efd32.png',
            'coingeckoId': 'sync-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SMC',
            'name': 'Smart Medical Coin',
            'address': '0xB6eDA82597808c96969C21a88bf6c749B441c44A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17327/thumb/smc.png?1627313618',
            'coingeckoId': 'smart-medical-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '0XBTC',
            'name': '0xBitcoin',
            'address': '0xB6eD7644C69416d67B522e20bC294A9a9B405B31',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xb6ed7644c69416d67b522e20bc294a9a9b405b31.png',
            'coingeckoId': 'oxbitcoin',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'optimism',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'ADCO',
            'name': 'Advertise Coin',
            'address': '0xB6c3DC857845a713d3531cea5ac546F6767992F4',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14344/thumb/advertisecoin200x200.png?1615503478',
            'coingeckoId': 'advertise-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MUSE',
            'name': 'Muse DAO',
            'address': '0xB6Ca7399B4F9CA56FC27cBfF44F4d2e4Eef1fc81',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xb6ca7399b4f9ca56fc27cbff44f4d2e4eef1fc81.png',
            'coingeckoId': 'muse-2',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'DS',
            'name': 'DeStorage',
            'address': '0xB67bEB26eBeb0DCEec354aE0942256d03c01771b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15984/thumb/DS_LOG_Transparent_200x200.png?1622535280',
            'coingeckoId': 'destorage',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INNBC',
            'name': 'Innovative Bioresearch Coin',
            'address': '0xB67718b98d52318240c52E71A898335da4A28c42',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4043/thumb/INNBC.png?1582800857',
            'coingeckoId': 'innovative-bioresearch',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NTO',
            'name': 'Neton',
            'address': '0xB668473944d2E25B6aF6D46917Eb0233DBaC53ae',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25418/thumb/lqT0CJNj_400x400.jpg?1651735850',
            'coingeckoId': 'neton',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PRV',
            'name': 'Incognito',
            'address': '0xB64fde8f199F073F41c132B9eC7aD5b61De0B1B7',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21971/thumb/50738351.png?1640560573',
            'coingeckoId': 'incognito-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STORJ',
            'name': 'Storj',
            'address': '0xB64ef51C888972c908CFacf59B47C1AfBC0Ab8aC',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xb64ef51c888972c908cfacf59b47c1afbc0ab8ac.png',
            'coingeckoId': 'storj',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'openocean',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MCO',
            'name': 'MCO',
            'address': '0xB63B606Ac810a52cCa15e44bB630fd42D8d1d83d',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xb63b606ac810a52cca15e44bb630fd42d8d1d83d.png',
            'coingeckoId': 'monaco',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'NEXO',
            'name': 'NEXO',
            'address': '0xB62132e35a6c13ee1EE0f84dC5d40bad8d815206',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xb62132e35a6c13ee1ee0f84dc5d40bad8d815206.png',
            'coingeckoId': 'nexo',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'LFT',
            'name': 'Lend Flare Dao',
            'address': '0xB620Be8a1949AA9532e6a3510132864EF9Bc3F82',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24846/thumb/e5x55-kU_400x400.jpg?1649116244',
            'coingeckoId': 'lend-flare-dao-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DIVINE',
            'name': 'divinedao',
            'address': '0xB5e09e6Bf6a5E96934B3fd99a40F7EDACa1173Ed',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xB5e09e6Bf6a5E96934B3fd99a40F7EDACa1173Ed/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'DEVT',
            'name': 'DeHorizon',
            'address': '0xB5c578947de0fd71303F71F2C3d41767438bD0de',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21373/thumb/A3RjDqet_400x400.jpg?1639031847',
            'coingeckoId': 'dehorizon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FNK',
            'name': 'Fnk com',
            'address': '0xB5FE099475d3030DDe498c3BB6F3854F762A48Ad',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xb5fe099475d3030dde498c3bb6f3854f762a48ad.png',
            'coingeckoId': 'fnkcom',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'STILT',
            'name': 'Stilton',
            'address': '0xB5F1457d6FBa1956Fb8d31B0B7CAcA14BDe0bE4b',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26132/thumb/2vfQHbe9_400x400.png?1655969754',
            'coingeckoId': 'stilton',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DTO',
            'name': 'DotOracle',
            'address': '0xB57420FaD6731B004309D5a0ec7C6C906Adb8df7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19527/thumb/84438129.png?1635371187',
            'coingeckoId': 'dotoracle',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'ACYC',
            'name': 'All Coins Yield Capital',
            'address': '0xB56A1f3310578f23120182Fb2e58c087EFE6e147',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xb56a1f3310578f23120182fb2e58c087efe6e147.png',
            'coingeckoId': 'all-coins-yield-capital',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ATO',
            'name': 'EAutocoin',
            'address': '0xB551d08D2189eF67b4788BE2c35C0743693625Ca',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7132/thumb/ato.png?1547043631',
            'coingeckoId': 'eautocoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAHA',
            'name': 'MahaDAO',
            'address': '0xB4d930279552397bbA2ee473229f89Ec245bc365',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xb4d930279552397bba2ee473229f89ec245bc365.png',
            'coingeckoId': 'mahadao',
            'listedIn': [
                'coingecko',
                '1inch',
                'dfyn',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'SWEAT',
            'name': 'Sweatcoin   Sweat Economy',
            'address': '0xB4b9DC1C77bdbb135eA907fd5a08094d98883A35',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xb4b9dc1c77bdbb135ea907fd5a08094d98883a35.png',
            'coingeckoId': 'sweatcoin',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'THE',
            'name': 'THENODE',
            'address': '0xB4a677B0E363c3815d46326954a4E4d2B1ACe357',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10292/thumb/thenode_logo.png?1577334103',
            'coingeckoId': 'the-node',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OGUC',
            'name': 'Only Graded Coin',
            'address': '0xB4F74994FCBd60E1bC3603b9a33069eA1b32d4A4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'RPL',
            'name': 'Rocket Pool',
            'address': '0xB4EFd85c19999D84251304bDA99E90B92300Bd93',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://api.rubic.exchange/assets/ethereum/0xb4efd85c19999d84251304bda99e90b92300bd93/logo.png',
            'coingeckoId': 'rocket-pool',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SPA',
            'name': 'Sperax',
            'address': '0xB4A3B0Faf0Ab53df58001804DdA5Bfc6a3D59008',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12232/thumb/sperax_logo.jpg?1598342904',
            'coingeckoId': 'sperax',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOFI',
            'name': 'RAI Finance',
            'address': '0xB49fa25978abf9a248b8212Ab4b87277682301c0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14686/thumb/sofi.png?1640249595',
            'coingeckoId': 'rai-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NOVA',
            'name': 'NOVA',
            'address': '0xB48B7E5bF6563B3e0A85055821A83Deb8CFc12f6',
            'decimals': 3,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6545/thumb/N7d0x35q_400x400.jpg?1547042760',
            'coingeckoId': 'nova',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWTH',
            'name': 'Carbon Protocol',
            'address': '0xB4371dA53140417CBb3362055374B10D97e420bB',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3645/thumb/SWTH_Symbol_Origin.png?1645000262',
            'coingeckoId': 'switcheo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XCHF',
            'name': 'CryptoFranc',
            'address': '0xB4272071eCAdd69d933AdcD19cA99fe80664fc08',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xb4272071ecadd69d933adcd19ca99fe80664fc08.png',
            'coingeckoId': 'cryptofranc',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'LTCBEAR',
            'name': '3X Short Litecoin Token',
            'address': '0xB422e605fBd765B80D2C4b5d8196C2f94144438B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10223/thumb/683JEXMN_400x400_%281%29.png?1576588627',
            'coingeckoId': '3x-short-litecoin-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ICNQ',
            'name': 'Iconic ICNQ',
            'address': '0xB3e2Cb7CccfE139f8FF84013823Bf22dA6B6390A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7830/thumb/2_Iconic_Holding_icon.png?1593396172',
            'coingeckoId': 'iconiq-lab-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-15796-HARTW',
            'name': 'RealT   15796 Hartwell St  Detroit  MI ',
            'address': '0xB3D3C1bBcEf737204AADb4fA6D90e974bc262197',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16607/thumb/15796-Hartwell-hero-1.jpeg?1624545151',
            'coingeckoId': '15796-hartwell',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PHUNK',
            'name': 'PHUNK Vault  NFTX ',
            'address': '0xB39185e33E8c28e0BB3DbBCe24DA5dEA6379Ae91',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17074/thumb/Phunks.png?1626152056',
            'coingeckoId': 'phunk-vault-nftx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COVA',
            'name': 'Cova Unity',
            'address': '0xB37a769B37224449d92AAc57dE379E1267Cd3B00',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4707/thumb/covalent.jpg?1547040005',
            'coingeckoId': 'covalent-cova',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CZRX',
            'name': 'c0x',
            'address': '0xB3319f5D18Bc0D84dD1b4825Dcde5d5f7266d407',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10644/thumb/czrx1.JPG?1581390510',
            'coingeckoId': 'compound-0x',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PIF',
            'name': 'Play It Forward DAO',
            'address': '0xB30F5d11b94efBBfDeaa4de38eDFFCeEc0bE6513',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22904/thumb/pif.png?1642938913',
            'coingeckoId': 'play-it-forward-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOFI',
            'name': 'MobiFi',
            'address': '0xB2dbF14D0b47ED3Ba02bDb7C954e05A72deB7544',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14697/thumb/MOFI_Coin_Green_200x200.png?1634024056',
            'coingeckoId': 'mobifi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DD',
            'name': 'DDstable',
            'address': '0xB2c1d397eC86Acb569CDab7889E66C3964fe43b6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FOLO',
            'name': 'Alpha Impact',
            'address': '0xB2a63A5Dd36C91eC2DA59B188ff047F66fac122a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19988/thumb/folo.PNG?1636357322',
            'coingeckoId': 'follow-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'nuBTC',
            'name': 'Unvaxxed Sperm',
            'address': '0xB2979Ab4705FA0621FCaB059748B4e77dd76b78e',
            'decimals': 9,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'a1INCH',
            'name': 'Aave interest bearing 1INCH',
            'address': '0xB29130CBcC3F791f077eAdE0266168E808E5151e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xb29130cbcc3f791f077eade0266168e808e5151e.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MINDS',
            'name': 'Minds',
            'address': '0xB26631c6dda06aD89B93C71400D25692de89c068',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xb26631c6dda06ad89b93c71400d25692de89c068.png',
            'coingeckoId': 'minds',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'WAIF',
            'name': 'Waifu Genesis Card Collection',
            'address': '0xB2279B6769CFBa691416F00609b16244c0cF4b20',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12100/thumb/Small-Waifu_token.png?1597120029',
            'coingeckoId': 'waifu-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUA',
            'name': 'LuaSwap',
            'address': '0xB1f66997A5760428D3a87D68b90BfE0aE64121cC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xb1f66997a5760428d3a87d68b90bfe0ae64121cc.png',
            'coingeckoId': 'lua-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'FLASH',
            'name': 'Flashstake',
            'address': '0xB1f1F47061A7Be15C69f378CB3f69423bD58F2F8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethereum-optimism.github.io/data/FLASH/logo.svg',
            'coingeckoId': 'flash-stake',
            'listedIn': [
                'coingecko',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'SEELE',
            'name': 'Seele',
            'address': '0xB1e93236ab6073fdAC58adA5564897177D4bcC43',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3294/thumb/Seele.png?1649325804',
            'coingeckoId': 'seele',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZEFU',
            'name': 'Zenfuse',
            'address': '0xB1e9157c2Fdcc5a856C8DA8b2d89b6C32b3c1229',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xb1e9157c2fdcc5a856c8da8b2d89b6c32b3c1229.png',
            'coingeckoId': 'zenfuse',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'EURST',
            'name': 'Euro Stable Token',
            'address': '0xB1aBd7ABa7d99BBEfb33D1dfc66B0dD522335350',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20988/thumb/AF3E1D9F-4817-4FC4-BC80-FFF30A3E1B91.PNG?1638169603',
            'coingeckoId': 'euro-stable-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SL',
            'name': 'SoloxCoin',
            'address': '0xB1F233835de2440620332267ba729bFE74FA2CfD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25704/thumb/22223333333.png?1653460112',
            'coingeckoId': 'soloxcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIBLI',
            'name': 'Studio Shibli',
            'address': '0xB1A88c33091490218965787919fcc9862C1798eE',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xb1a88c33091490218965787919fcc9862c1798ee.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'HMR',
            'name': 'Homeros',
            'address': '0xB1A30851E3f7d841b231B086479608e17198363A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11048/thumb/g1jAE5knZSlNUdLN8fEAQFMI4mTXCuNdrjj3i77rWhsIQADp3VWH2BHTkH7-oWwExxJk7Fu545LPeT3gMNzGlCZ63oX3-9sTjiqlSDo3fRLwHmhUgjD_jtKTl1AJ_F_UOOpsTqob9CxiWHXsSTlz0zODxOrjnqpMe4_cPT_C4EskSEVee_oooTasTQ6ONrv85Zcvc8Eeb8cHLsV.jpg?1587532938',
            'coingeckoId': 'homeros',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SMT',
            'name': 'Swarm Markets',
            'address': '0xB17548c7B510427baAc4e267BEa62e800b247173',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17488/thumb/swarm-SMT-token-symbol_200x200.png?1655373659',
            'coingeckoId': 'swarm-markets',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ISR',
            'name': 'Insureum',
            'address': '0xB16d3Ed603D62b125c6bd45519EDa40829549489',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4888/thumb/insureum.png?1547974141',
            'coingeckoId': 'insureum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HID',
            'name': 'Hypersign Identity',
            'address': '0xB14eBF566511B9e6002bB286016AB2497B9b9c9D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16158/thumb/hypersign_ONLYlogo_Yellow.png?1623140987',
            'coingeckoId': 'hypersign-identity-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GMAT',
            'name': 'GoWithMi',
            'address': '0xB13dE094Cc5CEe6C4cC0A3737bf0290166D9Ca5D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7162/thumb/qXNbxVny_400x400.png?1564433322',
            'coingeckoId': 'gowithmi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FND',
            'name': 'Foundation',
            'address': '0xB13d747d783BF1A9F1D65Df74C080C890045f17e',
            'decimals': 7,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28099/thumb/ddsaaa.png?1667538993',
            'coingeckoId': 'foundation',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ICOM',
            'name': 'iCommunity',
            'address': '0xB131F337C45D386cEeC234e194b2663D5c3d9dCF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23667/thumb/logo-icommunity.png?1644993652',
            'coingeckoId': 'icommunity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'aUNIv1',
            'name': 'Aave Interest bearing Uniswap',
            'address': '0xB124541127A0A657f056D9Dd06188c4F1b0e5aab',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xb124541127a0a657f056d9dd06188c4f1b0e5aab.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'KIRO',
            'name': 'KIRO',
            'address': '0xB1191F691A355b43542Bea9B8847bc73e7Abb137',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xb1191f691a355b43542bea9b8847bc73e7abb137.png',
            'coingeckoId': 'kirobo',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BMEX',
            'name': 'BitMEX',
            'address': '0xB113c6CF239F60D380359b762E95C13817275277',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21831/thumb/bitmex-token.jpeg?1640081706',
            'coingeckoId': 'bitmex-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IF',
            'name': 'IF',
            'address': '0xB0e1fc65C1a741b4662B813eB787d369b8614Af1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://static.debank.com/image/eth_token/logo_url/0xb0e1fc65c1a741b4662b813eb787d369b8614af1/94e820f9a8d3377155811dafe645b9b4.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MAGIC',
            'name': 'Magic',
            'address': '0xB0c7a3Ba49C7a6EaBa6cD4a96C55a1391070Ac9A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18623/thumb/Magic.png?1635755672',
            'coingeckoId': 'magic',
            'listedIn': [
                'coingecko',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'BOB',
            'name': 'BOB',
            'address': '0xB0B195aEFA3650A6908f15CdaC7D92F8a5791B0B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b.png',
            'coingeckoId': 'bob',
            'listedIn': [
                'coingecko',
                '1inch',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'HELLSING',
            'name': 'Hellsing Inu',
            'address': '0xB087C2180e3134Db396977065817aed91FEa6EAD',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19795/thumb/hellsing.png?1638182960',
            'coingeckoId': 'hellsing-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUP',
            'name': 'BuildUp',
            'address': '0xB04DFdb8271ed2d5e13858562C44A77D3CEb9e57',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13206/thumb/BUP_-_LOGO.png?1606199462',
            'coingeckoId': 'buildup',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SILK',
            'name': 'Spider Tanks',
            'address': '0xB045f7f363fE4949954811b113bd56d208c67B23',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28057/thumb/SILK_Logo.png?1667295996',
            'coingeckoId': 'silk',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EGS',
            'name': 'EdgeSwap',
            'address': '0xB009BFaAF85e53F55d8657781Eb69feAaed83672',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24878/thumb/egs.png?1649218020',
            'coingeckoId': 'edgeswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BIG',
            'name': 'BIG Token',
            'address': '0xAfAEb84415c1cd2bC99cd1F5F0B3090baE1BEb6c',
            'decimals': 9,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FOY',
            'name': 'Fund Of Yours',
            'address': '0xAf91E8aFbe87642dC628786188a54B78580A4d76',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16648/thumb/foy200.png?1624591862',
            'coingeckoId': 'fund-of-yours',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EGCC',
            'name': 'Engine',
            'address': '0xAf8A215e81FAea7C180CE22b72483525121813BD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4531/thumb/2852.png?1547039827',
            'coingeckoId': 'engine',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STG',
            'name': 'Stargate Finance',
            'address': '0xAf5191B0De278C7286d6C7CC6ab6BB8A73bA2Cd6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xaf5191b0de278c7286d6c7cc6ab6bb8a73ba2cd6.png',
            'coingeckoId': 'stargate-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'S4F',
            'name': 'S4FE',
            'address': '0xAec7d1069e3a914a3EB50f0BFB1796751f2ce48a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7405/thumb/logo_%284%29.png?1547085640',
            'coingeckoId': 's4fe',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PHX',
            'name': 'Phoenix Finance',
            'address': '0xAeC65404DdC3af3C897AD89571d5772C1A695F22',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17675/thumb/phx_logo.png?1628832432',
            'coingeckoId': 'phoenix-token',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'SMETA',
            'name': 'StarkMeta',
            'address': '0xAdc3F2C3D728202658930860158C726d8180a38F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25851/thumb/logo_starkmeta.png?1654150866',
            'coingeckoId': 'starkmeta',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RHEGIC2',
            'name': 'rHEGIC2',
            'address': '0xAd7Ca17e23f13982796D27d1E6406366Def6eE5f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13969/thumb/ezgif-4-b5306690cb32.jpg?1613385300',
            'coingeckoId': 'rhegic2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BCDT',
            'name': 'EvidenZ',
            'address': '0xAcfa209Fb73bF3Dd5bBfb1101B9Bc999C49062a5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xacfa209fb73bf3dd5bbfb1101b9bc999c49062a5.png',
            'coingeckoId': 'blockchain-certified-data-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'FNF',
            'name': 'FunFi',
            'address': '0xAcf8D5E515Ed005655DfeFA09C22673a37A7CDEE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25872/thumb/FNF-200x200.png?1654241524',
            'coingeckoId': 'funfi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CINU',
            'name': 'CHEEMS INU',
            'address': '0xAc8cC32Fab2368a1A095722AAf760C45f578E17B',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20924/thumb/Screenshot-2021-11-23-at-00-45-30.png?1637932412',
            'coingeckoId': 'cheems-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YFO',
            'name': 'YFIONE',
            'address': '0xAc0C8dA4A4748d8d821A0973d00b157aA78C473D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13670/thumb/256.png?1610676054',
            'coingeckoId': 'yfione',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PUDGY',
            'name': 'PUDGY Vault  NFTX ',
            'address': '0xAbeA7663c472648d674bd3403D94C858dFeEF728',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18330/thumb/pudgy.png?1631605038',
            'coingeckoId': 'pudgy-vault-nftx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YAMV2',
            'name': 'YAM v2',
            'address': '0xAba8cAc6866B83Ae4eec97DD07ED254282f6aD8A',
            'decimals': 24,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12179/thumb/YAM-v2.png?1597892396',
            'coingeckoId': 'yam-v2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GF',
            'name': 'GuildFi',
            'address': '0xAaEf88cEa01475125522e117BFe45cF32044E238',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1640081801768_052492456544793065.png',
            'coingeckoId': 'guildfi',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'FAR',
            'name': 'Farmland Protocol',
            'address': '0xAaE3Cf9968D26925BDb73cE3864e0084a20f4687',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13497/thumb/70059912.jpg?1609193763',
            'coingeckoId': 'farmland-protocol',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'AURORA',
            'name': 'Aurora',
            'address': '0xAaAAAA20D9E0e2461697782ef11675f668207961',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xaaaaaa20d9e0e2461697782ef11675f668207961.png',
            'coingeckoId': 'aurora-near',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'FMTA',
            'name': 'Fundamenta',
            'address': '0xAa9D866666C2A3748d6B23Ff69E63E52f08d9AB4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12983/thumb/fundamenta.png?1604065939',
            'coingeckoId': 'fundamenta',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EVC',
            'name': 'Eco Value Coin',
            'address': '0xAa843f65872a25D6E9552eA0B360Fb1d5E333124',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7868/thumb/temp_1524073947125.-325764774.png?1561602099',
            'coingeckoId': 'eco-value-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETH2X-FLI',
            'name': 'Index Coop   ETH 2x Flexible Leverage I',
            'address': '0xAa6E8127831c9DE45ae56bB1b0d4D4Da6e5665BD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xaa6e8127831c9de45ae56bb1b0d4d4da6e5665bd.png',
            'coingeckoId': 'eth-2x-flexible-leverage-index',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MFI',
            'name': 'Marginswap',
            'address': '0xAa4e3edb11AFa93c41db59842b29de64b72E355B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xaa4e3edb11afa93c41db59842b29de64b72e355b.png',
            'coingeckoId': 'marginswap',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'PMGT',
            'name': 'Perth Mint Gold Token',
            'address': '0xAFFCDd96531bCd66faED95FC61e443D08F79eFEf',
            'decimals': 5,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10730/thumb/pmgt_logo_256x256.png?1582668331',
            'coingeckoId': 'perth-mint-gold-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VINU',
            'name': 'Vita Inu',
            'address': '0xAFCdd4f666c84Fed1d8BD825aA762e3714F652c9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20594/thumb/vita-inu-head-wallet-icon-transparent.png?1637286122',
            'coingeckoId': 'vita-inu',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ACY',
            'name': 'ACY Finance',
            'address': '0xAF9dB9E362e306688AF48c4ACB9618C06db38Ac3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21883/thumb/ubfPhcEK_400x400.jpg?1640214613',
            'coingeckoId': 'acy-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PNODE',
            'name': 'Pinknode',
            'address': '0xAF691508BA57d416f895e32a1616dA1024e882D2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15469/thumb/icon-Logo-pink.png?1644476523',
            'coingeckoId': 'pinknode',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PAWTH',
            'name': 'Pawthereum',
            'address': '0xAEcc217a749c2405b5ebC9857a16d58Bdc1c367F',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xaecc217a749c2405b5ebc9857a16d58bdc1c367f.png',
            'coingeckoId': 'pawthereum',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'MV',
            'name': 'GensoKishi Metaverse',
            'address': '0xAE788F80F2756A86aa2F410C651F2aF83639B95b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/17704.png',
            'coingeckoId': null,
            'listedIn': [
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'KISHIMOTO',
            'name': 'Kishimoto',
            'address': '0xAE36155a55F04a696b8362777620027882B31dB5',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28011/thumb/kishimoto.png?1666944015',
            'coingeckoId': 'kishimoto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YOP',
            'name': 'Yield Optimization Platform   Protocol',
            'address': '0xAE1eaAE3F627AAca434127644371b67B18444051',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xae1eaae3f627aaca434127644371b67b18444051.png',
            'coingeckoId': 'yield-optimization-platform',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'C98',
            'name': 'Coin98',
            'address': '0xAE12C5930881c53715B369ceC7606B70d8EB229f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xae12c5930881c53715b369cec7606b70d8eb229f.png',
            'coingeckoId': 'coin98',
            'listedIn': [
                'coingecko',
                'uniswap',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ADAL',
            'name': 'Adalend',
            'address': '0xAE1107fC7CeF1294F09185aC475c9886527DcD8a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24554/thumb/19012.png?1648134755',
            'coingeckoId': 'adalend',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APOLLO',
            'name': 'Apollo Crypto',
            'address': '0xADf86E75d8f0F57e0288D0970E7407eaA49b3CAb',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22606/thumb/apollo-icon-green-black-w3-200.png?1656312049',
            'coingeckoId': 'apollo-crypto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHELL',
            'name': 'Turtle Shell Islands',
            'address': '0xADf0Ac3059400edFB6131b9fe65FeEa6cA83ad76',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26925/thumb/slogo.png?1660833387',
            'coingeckoId': 'turtle-shell-islands',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HARAMBE',
            'name': 'Harambe',
            'address': '0xADe6FDAba1643E4D1eeF68Da7170F234470938c6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23489/thumb/unnamed.jpg?1644220775',
            'coingeckoId': 'harambe',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWC',
            'name': 'Scanetchain',
            'address': '0xADF8B8050639b6236915f7516d69dE714672F0bF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6876/thumb/scanetchain.jpg?1547043219',
            'coingeckoId': 'scanetchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADX',
            'name': 'Ambire AdEx',
            'address': '0xADE00C28244d5CE17D72E40330B1c318cD12B7c3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xade00c28244d5ce17d72e40330b1c318cd12b7c3.png',
            'coingeckoId': 'adex',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'COV',
            'name': 'Covesting',
            'address': '0xADA86b1b313D1D5267E3FC0bB303f0A2b66D0Ea7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1950/thumb/covesting.png?1547036237',
            'coingeckoId': 'covesting',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAXS',
            'name': 'BoxAxis',
            'address': '0xACf3D402e5E2C3eDD5b8129e966017D293F12a4C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7732/thumb/MaUNF_dJFGQujn1_y5zYWG_U1NCglt4yBsjyftdpPr5H7LqTsu0XEqcKnnRWcjTUA0DsOLCr1Ekhe37__Wpx83X29R-nbs0UTxz5eElpzw7Mcl0y8-ReYLck8dNYAxJpXG1k48pA4RousepQFj7H9JKq09h5Z4EzBwnJf2P6qFWALryESUUmAOmtByKUD5WoCWI4DIlRorsWSbbl6.jpg?1550108268',
            'coingeckoId': 'boxaxis',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VSF',
            'name': 'VeriSafe',
            'address': '0xAC9ce326e95f51B5005e9fE1DD8085a01F18450c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7862/thumb/verisafe_logo.png?1563852491',
            'coingeckoId': 'verisafe',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABYDOGE',
            'name': 'BabyDoge ETH',
            'address': '0xAC8E13ecC30Da7Ff04b842f21A62a1fb0f10eBd5',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17166/thumb/logo_256px_%281%29.png?1626684127',
            'coingeckoId': 'babydoge-coin-eth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABYDOGE',
            'name': 'Baby Doge Coin',
            'address': '0xAC57De9C1A09FeC648E93EB98875B212DB0d460B',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16125/thumb/Baby_Doge.png?1623044048',
            'coingeckoId': 'baby-doge-coin',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ALICE',
            'name': 'My Neighbor Alice',
            'address': '0xAC51066d7bEC65Dc4589368da368b212745d63E8',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xac51066d7bec65dc4589368da368b212745d63e8.png',
            'coingeckoId': 'my-neighbor-alice',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'LBY',
            'name': 'Lobby',
            'address': '0xAC042d9284Df95cc6Bd35982f6A61E3E7a6F875B',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20699/thumb/6197f0a241be5b582742b263_lobbySocial256w.png?1637565411',
            'coingeckoId': 'lobby',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XFT',
            'name': 'Offshift',
            'address': '0xABe580E7ee158dA464b51ee1a83Ac0289622e6be',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xabe580e7ee158da464b51ee1a83ac0289622e6be.png',
            'coingeckoId': 'offshift',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'P2P',
            'name': 'P2P',
            'address': '0xABE9b2e4bbD5A8C718752c41254Ed81aaE7d98BF',
            'decimals': 10,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12486/thumb/p2p.png?1600207324',
            'coingeckoId': 'p2p',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FILE',
            'name': 'FILE',
            'address': '0xABDf43c835577f0983686a9851841D314385aafE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xabdf43c835577f0983686a9851841d314385aafe.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'PAL',
            'name': 'Paladin',
            'address': '0xAB846Fb6C81370327e784Ae7CbB6d6a6af6Ff4BF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24558/thumb/Circle-Logo.png?1648180728',
            'coingeckoId': 'paladin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QUAD',
            'name': 'Quadency',
            'address': '0xAB2A7B5876D707e0126B3A75EF7781c77c8877EE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xab2a7b5876d707e0126b3a75ef7781c77c8877ee.png',
            'coingeckoId': 'quadency',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'UCN',
            'name': 'UChain',
            'address': '0xAAf37055188Feee4869dE63464937e683d61b2a1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4254/thumb/UChain-logo.jpg?1547039592',
            'coingeckoId': 'uchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DAC',
            'name': 'Davinci Coin',
            'address': '0xAAD54C9f27B876D2538455DdA69207279fF673a5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5494/thumb/dac.png?1644149184',
            'coingeckoId': 'davinci-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BIOS',
            'name': '0x nodes',
            'address': '0xAACa86B876ca011844b5798ECA7a67591A9743C8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xAACa86B876ca011844b5798ECA7a67591A9743C8/logo.png',
            'coingeckoId': 'bios',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'QANX',
            'name': 'QANplatform',
            'address': '0xAAA7A10a8ee237ea61E8AC46C50A8Db8bCC1baaa',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15977/thumb/qanx.png?1637574290',
            'coingeckoId': 'qanplatform',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'ATNT',
            'name': 'Artizen',
            'address': '0xAA2d8c9a8bd0F7945143bfD509bE3ff23dd78918',
            'decimals': 3,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27263/thumb/ATNT-symbol_200.png?1663059371',
            'coingeckoId': 'artizen',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SUTER',
            'name': 'Suterusu',
            'address': '0xAA2ce7Ae64066175E0B90497CE7d9c190c315DB4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9830/thumb/p-NFlBlw_400x400.jpg?1572472860',
            'coingeckoId': 'suterusu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LCS',
            'name': 'LocalCoinSwap',
            'address': '0xAA19961b6B858D9F18a115f25aa1D98ABc1fdBA8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3998/thumb/LocalCoinSwap.png?1547039086',
            'coingeckoId': 'localcoinswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AAMMUNIRENWETH',
            'name': 'Aave AMM UniRENWETH',
            'address': '0xA9e201A4e269d6cd5E9F0FcbcB78520cf815878B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17223/thumb/aAmmUniRENWETH.png?1626880629',
            'coingeckoId': 'aave-amm-unirenweth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZIP',
            'name': 'Zipper Network',
            'address': '0xA9d2927d3a04309E008B6af6E2e282AE2952e7fD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4524/thumb/zip_token_logo.png?1547039822',
            'coingeckoId': 'zip',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-15860-HARTW',
            'name': 'RealT   15860 Hartwell St  Detroit  MI ',
            'address': '0xA9F30C907321718e655B74463CA665B690B78894',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16595/thumb/15860-Hartwell-hero-1.jpg?1624540454',
            'coingeckoId': '15860-hartwell',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AUCTION',
            'name': 'Bounce',
            'address': '0xA9B1Eb5908CfC3cdf91F9B8B3a74108598009096',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa9b1eb5908cfc3cdf91f9b8b3a74108598009096.png',
            'coingeckoId': 'auction',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BTCM',
            'name': 'BTCMoon',
            'address': '0xA9Aad2dC3a8315caeee5F458B1d8EDc31D8467BD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1081/thumb/btcmoon.png?1547035045',
            'coingeckoId': 'btcmoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'THX',
            'name': 'Thx ',
            'address': '0xA98ED1fD277EaD2c00D143Cbe1465F59E65A0066',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9134/thumb/dtUHCa05GdIeMwiuO1Vdhpxe8f3xbPP_lIwFrnw2ic800CB3fhlk2eOp8hAGg-EUwrCTHwcQQqv3N3ikf5qWOKmbJePX5iK333iXdIlVF4UyAHb7ZsCi8nYBqBCJ7KZ6BwYcdlbguRPth6YSDWL5EBDIJQGmmkWEb-qTX7rnYEqS-9crxEkQJujPBnCVUV1qz87RgHV9KOvWL5HW6twlwJ.jpg?1564611313',
            'coingeckoId': 'thx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INCX',
            'name': 'International CryptoX',
            'address': '0xA984A92731C088F1eA4D53b71A2565a399F7D8D5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4244/thumb/download_%2833%29.jpg?1547039576',
            'coingeckoId': 'international-cryptox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RBXS',
            'name': 'RBXSamurai',
            'address': '0xA9639160481b625ba43677bE753E0A70bf58c647',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22342/thumb/rbxs.jpg?1641536586',
            'coingeckoId': 'rbxsamurai',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTS',
            'name': 'Metis MTS',
            'address': '0xA9598333B99d14D90Bc81CAd8Af82C4C70625e75',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13218/thumb/1Vd_NuQU_400x400.jpg?1606292031',
            'coingeckoId': 'metis',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FBS',
            'name': 'Forbitspace',
            'address': '0xA953736904e23Fafb42e353D4122420899999999',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25541/thumb/FBS-forbitspace-logo.png?1652265910',
            'coingeckoId': 'forbitspace',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DFI',
            'name': 'Amun DeFi Index',
            'address': '0xA9536B9c75A9E0faE3B56a96AC8EdF76AbC91978',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa9536b9c75a9e0fae3b56a96ac8edf76abc91978.png',
            'coingeckoId': 'amun-defi-index',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'DFCH',
            'name': 'DeFi ch',
            'address': '0xA92cDf011c895780733b2a6A6e15C4c1ed836148',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18842/thumb/defi200px.png?1633586754',
            'coingeckoId': 'defi-ch',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BEL',
            'name': 'Bella Protocol',
            'address': '0xA91ac63D040dEB1b7A5E4d4134aD23eb0ba07e14',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12478/thumb/Bella.png?1602230054',
            'coingeckoId': 'bella-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IVI',
            'name': 'Inoovi',
            'address': '0xA91464AbD4625A23aB719e3F0FCE84DaDd54E546',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10372/thumb/Bvp7U25U_400x400.jpg?1578674638',
            'coingeckoId': 'inoovi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZLOT',
            'name': 'zLOT',
            'address': '0xA8e7AD77C60eE6f30BaC54E2E7c0617Bd7B5A03E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/zlot.jpg',
            'coingeckoId': 'zlot',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'LYXE',
            'name': 'LUKSO',
            'address': '0xA8b919680258d369114910511cc87595aec0be6D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa8b919680258d369114910511cc87595aec0be6d.png',
            'coingeckoId': 'lukso-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'PBTC35A',
            'name': 'pBTC35A',
            'address': '0xA8b12Cc90AbF65191532a12bb5394A714A46d358',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa8b12cc90abf65191532a12bb5394a714a46d358.png',
            'coingeckoId': 'pbtc35a',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'KP4R',
            'name': 'Keep4r',
            'address': '0xA89ac6e529aCf391CfbBD377F3aC9D93eae9664e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa89ac6e529acf391cfbbd377f3ac9d93eae9664e.png',
            'coingeckoId': 'keep4r',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'IFEX',
            'name': 'Interfinex Bills',
            'address': '0xA891CF72AEDa692570928eFe1A832342e9783CDC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13346/thumb/U6uYZry.png?1607662020',
            'coingeckoId': 'interfinex-bills',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DARUMA',
            'name': 'Daruma',
            'address': '0xA888D9616C2222788fa19f05F77221A290eEf704',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27357/thumb/CGlogo.png?1663661942',
            'coingeckoId': 'daruma',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EARN',
            'name': 'EverEarn',
            'address': '0xA87Ed75C257f1ec38393bEA0A83d55Ac2279D79c',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23310/thumb/hCHEYlIv_400x400.jpg?1643753184',
            'coingeckoId': 'everearn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUNR',
            'name': 'Lunr',
            'address': '0xA87135285Ae208e22068AcDBFf64B11Ec73EAa5A',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19256/thumb/lunr.png?1639975807',
            'coingeckoId': 'lunr-token',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'OTB',
            'name': 'OTCBTC',
            'address': '0xA86a0Da9D05d0771955DF05B44Ca120661aF16DE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5246/thumb/otcbtc-token.jpg?1547040734',
            'coingeckoId': 'otcbtc-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FOL',
            'name': 'Folder Protocol',
            'address': '0xA8580F3363684d76055bdC6660CaeFe8709744e1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13755/thumb/folderlabs_logo.png?1611560881',
            'coingeckoId': 'folder-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MVL',
            'name': 'MVL',
            'address': '0xA849EaaE994fb86Afa73382e9Bd88c2B6b18Dc71',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3476/thumb/mass-vehicle-ledger.png?1547978299',
            'coingeckoId': 'mass-vehicle-ledger',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'OPNN',
            'name': 'Opennity',
            'address': '0xA829F97373069ee5d23175e4105dF8fD49238Be7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9358/thumb/53672059_554715805021197_5765693264996859904_n.jpg?1566526789',
            'coingeckoId': 'opennity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NEU',
            'name': 'Neumark',
            'address': '0xA823E6722006afe99E91c30FF5295052fe6b8E32',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2218/thumb/neumark.png?1547036501',
            'coingeckoId': 'neumark',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HEL',
            'name': 'Hel Games',
            'address': '0xA8155cf81AdAa78f9ceC69d698Be1859D4F3BF3B',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26635/thumb/logo_%281%29.png?1659327747',
            'coingeckoId': 'hel-games',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REFI',
            'name': 'Reimagined Finance',
            'address': '0xA808B22ffd2c472aD1278088F16D4010E6a54D5F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21543/thumb/refi.PNG?1639451123',
            'coingeckoId': 'refi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SNL',
            'name': 'Sport and Leisure',
            'address': '0xA806B3FEd6891136940cF81c4085661500aa2709',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7762/thumb/745ba052-73b7-4735-a0bf-e75474def055.png?1550456328',
            'coingeckoId': 'sport-and-leisure',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CTZN',
            'name': 'Totem',
            'address': '0xA803778AB953d3FfE4FBD20Cfa0042eCeFE8319D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21558/thumb/ctzn.png?1642930817',
            'coingeckoId': 'totem-earth-systems',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'EOC',
            'name': 'EveryonesCrypto',
            'address': '0xA8006e3Ac1bD94e54E3136B8e5dD75db0163e6f4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13081/thumb/logo_blue.png?1604976930',
            'coingeckoId': 'everyonescrypto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPIKE',
            'name': 'Spiking',
            'address': '0xA7fC5D2453E3F68aF0cc1B78bcFEe94A1B293650',
            'decimals': 10,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xa7fc5d2453e3f68af0cc1b78bcfee94a1b293650.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'MUNI',
            'name': 'MUNI',
            'address': '0xA7dE94227Eb6828497f4343B9923959E41Ae9CD1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28166/thumb/I03PYZqO_400x400-removebg-preview.png?1668172860',
            'coingeckoId': 'muni',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'fiZRX',
            'name': 'bZx ZRX iToken',
            'address': '0xA7Eb2bc82df18013ecC2A6C533fc29446442EDEe',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xa7eb2bc82df18013ecc2a6c533fc29446442edee.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'LXF',
            'name': 'LuxFi',
            'address': '0xA799C4ADcf62E025cE4D8aBE6A77CebC487d772A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20095/thumb/LPue04PB_400x400_%281%29.png?1636494545',
            'coingeckoId': 'luxfi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BETTER',
            'name': 'Better Money',
            'address': '0xA7925aA2A6e4575ab0C74D169f3BC3e03D4C319A',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12838/thumb/BETTER_MONEY_MASK_ICON.png?1602899651',
            'coingeckoId': 'better-money',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HNS',
            'name': 'Handshake',
            'address': '0xA771b49064Da011DF051052848477f18DbA1d2Ac',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10562/thumb/circle-handshakeLogo.png?1613668983',
            'coingeckoId': 'handshake',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SEAN',
            'name': 'Starfish Finance',
            'address': '0xA719CB79Af39A9C10eDA2755E0938bCE35e9DE24',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27533/thumb/SEAN_Token_icon.png?1664411041',
            'coingeckoId': 'starfish-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CBRL',
            'name': 'Crypto BRL',
            'address': '0xA6FA6531acDf1f9F96EDdD66a0F9481E35c2e42A',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://api.rubic.exchange/assets/ethereum/0xa6fa6531acdf1f9f96eddd66a0f9481e35c2e42a/logo.png',
            'coingeckoId': 'cryptobrl',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MNY',
            'name': 'MoonieNFT',
            'address': '0xA6F7645ed967FAF708A614a2fcA8D4790138586f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18226/thumb/sq8oK6mX_400x400.png?1631026813',
            'coingeckoId': 'moonienft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QUINT',
            'name': 'QUINT',
            'address': '0xA6EBe316317de53724967c5BF462e007E9bdf761',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'EZY',
            'name': 'Ezystayz',
            'address': '0xA6D5C720a9af5A405dFB6b9F44Fc44FaB5D4A58D',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9062/thumb/5521.png?1603333090',
            'coingeckoId': 'ezystayz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WTF',
            'name': 'Fees wtf',
            'address': '0xA68Dd8cB83097765263AdAD881Af6eeD479c4a33',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa68dd8cb83097765263adad881af6eed479c4a33.png',
            'coingeckoId': 'wtf-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BANDEX',
            'name': 'Banana Index',
            'address': '0xA67b8e40111A0EDD30C3210b77aadb86AD234c43',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26700/thumb/6233e7f496c72994c313a50a_cactusboy90_%282%29-p-1080.png?1659616101',
            'coingeckoId': 'banana-index',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'KLEE',
            'name': 'KleeKai',
            'address': '0xA67E9F021B9d208F7e3365B2A155E3C55B27de71',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa67e9f021b9d208f7e3365b2a155e3c55b27de71.png',
            'coingeckoId': 'kleekai',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'LETSGO',
            'name': 'Lets Go Brandon',
            'address': '0xA6586E19EF681b1AC0ED3D46413D199a555dBB95',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24034/thumb/3zKU0T83_400x400.jpeg?1646117523',
            'coingeckoId': 'lets-go-brandon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOKU',
            'name': 'Goku',
            'address': '0xA64dFe8D86963151E6496BEe513E366F6e42ED79',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa64dfe8d86963151e6496bee513e366f6e42ed79.png',
            'coingeckoId': 'goku',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'RICE',
            'name': 'Rice Wallet',
            'address': '0xA64c3a85ddc4cD351Eeb7aeceBc6a44A64A76392',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17204/thumb/RICE-200x200.png?1626847877',
            'coingeckoId': 'rice-wallet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALINK',
            'name': 'Aave LINK v1',
            'address': '0xA64BD6C70Cb9051F6A9ba1F163Fdc07E0DfB5F84',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa64bd6c70cb9051f6a9ba1f163fdc07e0dfb5f84.png',
            'coingeckoId': 'aave-link-v1',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'DBD',
            'name': 'Day By Day',
            'address': '0xA5f1DbB0e55bc31f32c6d032BeE330288490e722',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21691/thumb/DBD-icon_200x200_%281%29.png?1639721079',
            'coingeckoId': 'day-by-day',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRYBBEAR',
            'name': '3X Short BiLira Token',
            'address': '0xA5dDFCA8B837cCD0cf80fe6C24E2A9018FB50dbA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10451/thumb/683JEXMN_400x400.png?1579561868',
            'coingeckoId': '3x-short-bilira-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SSN',
            'name': 'SuperSkyNet',
            'address': '0xA5b46FF9a887180C8FB2d97146398Ddfc5FEF1Cd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8944/thumb/p3dTwXMK_400x400.jpg?1563153164',
            'coingeckoId': 'superskynet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUCHOW',
            'name': 'LunaChow',
            'address': '0xA5Ef74068d04ba0809B7379dD76Af5Ce34Ab7C57',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18805/thumb/J-MwYfhD_400x400.jpg?1633475157',
            'coingeckoId': 'lunachow',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CBANK',
            'name': 'Crypto Bank',
            'address': '0xA5E412ba6FcA1e07b15dEFcaA4236Ff7B5A7f086',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13473/thumb/HHeUVDBF5clKgE4vW_dyCtcTZqWDRw_vDe1MA3lIJWI4wEZW2BwF5dHFbm_ZpJNTWf85sZxCBVl2R7u29kGg37bE8XkGh3CneW6SkVwbnQEe0r2WR6yy6THxMvzIMiicWza82-NyjMitEo84_2E_C5LCiBcdTPFrjNQiMs1DTSJuaRTNB81KRsdcXNW0p2j7PH8O7Xk3QrWVjPe.jpg?1608853514',
            'coingeckoId': 'crypto-bank',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PSDN',
            'name': 'Poseidon',
            'address': '0xA5B947687163FE88C3e6af5b17Ae69896F4abccf',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25316/thumb/psdn_32.png?1651216148',
            'coingeckoId': 'poseidon-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AWX',
            'name': 'AurusDeFi',
            'address': '0xA51Fc71422a30FA7FFa605B360c3B283501b5bf6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12684/thumb/2021-12-06-Aurus-tokens-for-coingecko-AWX-flat-color-v1-r1-AS.png?1640223413',
            'coingeckoId': 'auruscoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SRWD',
            'name': 'ShibRWD',
            'address': '0xA518c9f3724cCED4715e6813858DC2cE9B21eD78',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21777/thumb/SRWD-LOGO-1.png?1643186506',
            'coingeckoId': 'shibrwd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOOMOLD',
            'name': 'Loom Network  OLD ',
            'address': '0xA4e8C3Ec456107eA67d3075bF9e3DF3A75823DB0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xa4e8c3ec456107ea67d3075bf9e3df3a75823db0.png',
            'coingeckoId': 'loom-network',
            'listedIn': [
                'coingecko',
                'uniswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'mooVelodromesUSD-USDC',
            'name': 'Moo Velodrome sUSD-USDC',
            'address': '0xA4b6573c9AE09d81e4D1360e6402B81F52557098',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'RBC',
            'name': 'Rubic',
            'address': '0xA4EED63db85311E22dF4473f87CcfC3DaDCFA3E3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa4eed63db85311e22df4473f87ccfc3dadcfa3e3.png',
            'coingeckoId': 'rubic',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'MMC',
            'name': 'MemeCard',
            'address': '0xA4C612296aa97057c160B95255f902F9dDe6704c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28096/thumb/IMG_20221102_023910_703.png?1667537410',
            'coingeckoId': 'memecard',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDS',
            'name': 'Stably USDS',
            'address': '0xA4Bdb11dc0a2bEC88d24A3aa1E6Bb17201112eBe',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa4bdb11dc0a2bec88d24a3aa1e6bb17201112ebe.png',
            'coingeckoId': 'stableusd',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'MUTE',
            'name': 'Mute',
            'address': '0xA49d7499271aE71cd8aB9Ac515e6694C755d400c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14331/thumb/MUTE.png?1617618967',
            'coingeckoId': 'mute',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DERI',
            'name': 'Deri Protocol',
            'address': '0xA487bF43cF3b10dffc97A9A744cbB7036965d3b9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa487bf43cf3b10dffc97a9a744cbb7036965d3b9.png',
            'coingeckoId': 'deri-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'PLANETINU',
            'name': 'Planet Inu',
            'address': '0xA461258c192cB6057aD8729589B0d18B08CcACe8',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21025/thumb/61a411c49069ade3e2229ae2_2021-11-29_00.32_1.png?1638196668',
            'coingeckoId': 'planet-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WUKONG',
            'name': 'Monkey King',
            'address': '0xA3ec7DF93090f4DE46FaEA09F73cc40ebe7dD714',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27238/thumb/wukong.png?1662877212',
            'coingeckoId': 'monkey-king',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CELEB',
            'name': 'Celeb',
            'address': '0xA3e53fe692eEda3502cf5CcfD8a535E1f93d23Dd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16402/thumb/celeb.PNG?1623914565',
            'coingeckoId': 'celeb',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SNFT',
            'name': 'SeedSwap',
            'address': '0xA3c4Dc4A9cE2a6B40B57F25F8b50DeCc2c64deC2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa3c4dc4a9ce2a6b40b57f25f8b50decc2c64dec2.png',
            'coingeckoId': 'seedswap',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'TEMP',
            'name': 'Tempus',
            'address': '0xA36FDBBAE3c9d55a1d67EE5821d53B50B63A1aB9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa36fdbbae3c9d55a1d67ee5821d53b50b63a1ab9.png',
            'coingeckoId': 'tempus',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'DRNT',
            'name': 'D RUNT',
            'address': '0xA3665c69213BB9AffCf92AB90100fBe273e3aB74',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28245/thumb/d-run_ticker_200x200.png?1668590995',
            'coingeckoId': 'd-runt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ABUSD',
            'name': 'Aave BUSD',
            'address': '0xA361718326c15715591c299427c62086F69923D9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa361718326c15715591c299427c62086f69923d9.png',
            'coingeckoId': 'aave-busd',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ROOBEE',
            'name': 'Roobee',
            'address': '0xA31B1767e09f842ECFd4bc471Fe44F830E3891AA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8791/thumb/Group_11.png?1580344629',
            'coingeckoId': 'roobee',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'XCN',
            'name': 'Chain',
            'address': '0xA2cd3D43c775978A96BdBf12d733D5A1ED94fb18',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24210/thumb/Chain_icon_200x200.png?1646895054',
            'coingeckoId': 'chain-2',
            'listedIn': [
                'coingecko',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'KISHU',
            'name': 'Kishu Inu',
            'address': '0xA2b4C0Af19cC16a6CfAcCe81F192B024d625817D',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa2b4c0af19cc16a6cfacce81f192b024d625817d_1.png',
            'coingeckoId': 'kishu-inu',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'DFT',
            'name': 'DigiFinex',
            'address': '0xA2A54f1Ec1f09316eF12c1770D32ed8F21B1Fb6A',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4819/thumb/digifinextoken.png?1547040187',
            'coingeckoId': 'digifinextoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-581-587-JEF',
            'name': 'RealT   581 587 Jefferson Ave  Rocheste',
            'address': '0xA29ae272bC89e5f315B2793925f700045F845d82',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16637/thumb/571-jefferson-corner-1.1.jpg?1624557964',
            'coingeckoId': '581-587-jefferson',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OIL',
            'name': 'OILage',
            'address': '0xA29a8e360C0CbA25A3719763fde43A1eE570271d',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11232/thumb/download_%2812%29.png?1589767119',
            'coingeckoId': 'oilage',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YNG',
            'name': 'Young',
            'address': '0xA26Cbb76156090f4B40A1799A220fc4C946aFB3c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26390/thumb/YNG.png?1657752304',
            'coingeckoId': 'young',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MEME',
            'name': 'Meme Protocol',
            'address': '0xA223e8a8d445B5B9a2F477c00AE79267A8f94225',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27795/thumb/memeprotocol.png?1665821284',
            'coingeckoId': 'meme-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATA',
            'name': 'Automata',
            'address': '0xA2120b9e674d3fC3875f415A7DF52e382F141225',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15985/thumb/ATA.jpg?1622535745',
            'coingeckoId': 'automata',
            'listedIn': [
                'coingecko',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ELG',
            'name': 'Escoin',
            'address': '0xA2085073878152aC3090eA13D1e41bD69e60Dc99',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13566/thumb/escoin-200.png?1609833886',
            'coingeckoId': 'escoin-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEKU',
            'name': 'Deku Inu',
            'address': '0xA1a88cea335EDAF30ce90F103f1434a773ea46BD',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18434/thumb/logo_382.png?1631944724',
            'coingeckoId': 'deku-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CVNX',
            'name': 'CVNX',
            'address': '0xA1a4E303e9C56962F201C5e834abC1E677A3C4F3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/862/thumb/CVNX-logo.png?1649528798',
            'coingeckoId': 'cvnx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEB',
            'name': 'Anduschain',
            'address': '0xA1E770be76BDe604F8Ebb66f640250a787B9422B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20842/thumb/DEB.png?1637743593',
            'coingeckoId': 'anduschain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KINGS',
            'name': 'Kings Coin',
            'address': '0xA1B26B9918b350e9657D9Ab43A45088805d65E4e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27643/thumb/photo_2022-10-05_10.01.29.jpeg?1665019588',
            'coingeckoId': 'kings-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AAMMUNIBATWETH',
            'name': 'Aave AMM UniBATWETH',
            'address': '0xA1B0edF4460CC4d8bFAA18Ed871bFF15E5b57Eb4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17217/thumb/aAmmUniBATWETH.png?1626878218',
            'coingeckoId': 'aave-amm-unibatweth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RFI',
            'name': 'reflect finance',
            'address': '0xA1AFFfE3F4D611d252010E3EAf6f4D77088b0cd7',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa1afffe3f4d611d252010e3eaf6f4d77088b0cd7.png',
            'coingeckoId': 'reflect-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'NPXS',
            'name': 'Pundi X  OLD ',
            'address': '0xA15C7Ebe1f07CaF6bFF097D8a589fb8AC49Ae5B3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0xa15c7ebe1f07caf6bff097d8a589fb8ac49ae5b3.png',
            'coingeckoId': 'pundi-x',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'PETH18C',
            'name': 'pETH18C',
            'address': '0xA15690E9205De386Ce849889831C1668c300C1ad',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14646/thumb/peth8a.png?1617489575',
            'coingeckoId': 'peth18c',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SENC',
            'name': 'Sentinel Chain',
            'address': '0xA13f0743951B4f6E3e3AA039f682E17279f52bc3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2960/thumb/sentinel-chain.jpg?1547037201',
            'coingeckoId': 'sentinel-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BB-A-USD',
            'name': 'Balancer Boosted Aave USD',
            'address': '0xA13a9247ea42D743238089903570127DdA72fE44',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25769/thumb/0x7b50775383d3d6f0215a8f290f2c9e2eebbeceb2.png?1653604699',
            'coingeckoId': 'balancer-booster-aave-usd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SBC',
            'name': 'Sky Bandit Coin',
            'address': '0xA13C75387C6677362f540F432BD8928C014e4892',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27868/thumb/SBC.png?1666154249',
            'coingeckoId': 'sky-bandit-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWASH',
            'name': 'Swash',
            'address': '0xA130E3a33a4d84b04c3918c4E5762223Ae252F80',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18774/thumb/swash.png?1634089759',
            'coingeckoId': 'swash',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'PINK',
            'name': 'PINK PANTHER',
            'address': '0xA113b79C09f0794568b8864a24197e0B817041EA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18822/thumb/logopp.png?1633520496',
            'coingeckoId': 'pink-panther',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USD Coin',
            'address': '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.png',
            'coingeckoId': 'usd-coin',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'optimism',
                'arbitrum_bridge',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CRO',
            'name': 'Cronos',
            'address': '0xA0b73E1Ff0B80914AB6fe0444E65848C4C34450b',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b.png',
            'coingeckoId': 'crypto-com-chain',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'SPH',
            'name': 'Spheroid Universe',
            'address': '0xA0CF46eb152656C7090e769916eb44a138aaa406',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xa0cf46eb152656c7090e769916eb44a138aaa406.png',
            'coingeckoId': 'spheroid-universe',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'CIRUS',
            'name': 'Cirus',
            'address': '0xA01199c61841Fce3b3daFB83FeFC1899715c8756',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17798/thumb/8p0Bvi90_400x400.jpg?1629281912',
            'coingeckoId': 'cirus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GPT',
            'name': 'GoPower',
            'address': '0xA00425D3e2D3E9FF74F3e112B4D3A7978d7D88c2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2991/thumb/82EMDIqURn_Y_O021ulWgQ.png?1547037256',
            'coingeckoId': 'tokengo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GMB',
            'name': 'GAMB',
            'address': '0xA0008F510fE9eE696E7E320C9e5cbf61E27791Ee',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4497/thumb/gmb.png?1547039806',
            'coingeckoId': 'gamb',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WIVA',
            'name': 'WIVA',
            'address': '0xA00055e6EE4D1f4169096EcB682F70cAa8c29987',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18142/thumb/n8Vu43vs.png?1630656531',
            'coingeckoId': 'wiva',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HDOT',
            'name': 'Huobi Polkadot',
            'address': '0x9ffc3bCDe7B68C46a6dC34f0718009925c1867cB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14107/thumb/hdot.png?1614333466',
            'coingeckoId': 'huobi-polkadot',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AWBTC',
            'name': 'Aave WBTC',
            'address': '0x9ff58f4fFB29fA2266Ab25e75e2A8b3503311656',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x9ff58f4ffb29fa2266ab25e75e2a8b3503311656.png',
            'coingeckoId': 'aave-wbtc',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'JAM',
            'name': 'JAM Token',
            'address': '0x9fb9Ec13a18EFf7072Ee8cA7Ee7a2B295649e00d',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26787/thumb/296706797_1760425707628456_5310396823145359110_n.jpg?1660102688',
            'coingeckoId': 'jam-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DERC',
            'name': 'DeRace',
            'address': '0x9fa69536d1cda4A04cFB50688294de75B505a9aE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x9fa69536d1cda4a04cfb50688294de75b505a9ae.png',
            'coingeckoId': 'derace',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'FEVR',
            'name': 'RealFevr',
            'address': '0x9fB83c0635De2E815fd1c21b3a292277540C2e8d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17136/thumb/Fevr-Token.png?1626869849',
            'coingeckoId': 'realfevr',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'YOT',
            'name': 'PayYoda',
            'address': '0x9f978Aa425148CdD9223eb175446a877B86727Ff',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13632/thumb/w_72D81R_400x400.png?1610492214',
            'coingeckoId': 'payyoda',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CENT',
            'name': 'Centurion Inu',
            'address': '0x9f91d9f9070b0478Abb5a9918c79B5DD533F672C',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18493/thumb/cent.png?1632207211',
            'coingeckoId': 'centurion-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MKR',
            'name': 'Maker',
            'address': '0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2.png',
            'coingeckoId': 'maker',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'optimism',
                'arbitrum_bridge',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'TAD',
            'name': 'Tadpole',
            'address': '0x9f7229aF0c4b9740e207Ea283b9094983f78ba04',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13134/thumb/9DmF_cs3_400x400.jpg?1605574755',
            'coingeckoId': 'tadpole-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LKN',
            'name': 'LinkCoin',
            'address': '0x9f549ebFD4974cD4eD4A1550D40394B44A7382AA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6098/thumb/vWhhedXQ.png?1547042094',
            'coingeckoId': 'linkcoin-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GZLR',
            'name': 'Guzzler',
            'address': '0x9f4909Cc95FB870BF48C128C1Fdbb5F482797632',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20970/thumb/Screenshot-2021-11-27-at-22-57-22.png?1638153729',
            'coingeckoId': 'guzzler',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TLNT',
            'name': 'Talent Coin',
            'address': '0x9f300B4aC0Bf94cAD77e7E2d3F850352b8Bb264c',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13771/thumb/TLNT.png?1611698474',
            'coingeckoId': 'talent-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STMATIC',
            'name': 'Lido Staked Matic',
            'address': '0x9ee91F9f426fA633d227f7a9b000E28b9dfd8599',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24185/thumb/stMATIC.png?1646789287',
            'coingeckoId': 'lido-staked-matic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XTR',
            'name': 'Xtremcoin',
            'address': '0x9ee45ADBB2F2083ab5CD9BC888C77a662Dbd55fE',
            'decimals': 8,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'GME',
            'name': 'GameStop Finance',
            'address': '0x9eb6bE354d88fD88795a04DE899a57A77C545590',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13820/thumb/gamestop_logo.jpg?1612070725',
            'coingeckoId': 'gamestop-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AKI',
            'name': 'Akitsuki',
            'address': '0x9eFdFC77017cB9b878300eCdcA21fAB0529a22A0',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27746/thumb/akitsuinu-200.png?1665644986',
            'coingeckoId': 'akitsuki',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HALV',
            'name': 'Halving',
            'address': '0x9e7cb236e43c4bd042fE463df6a175d4479ee186',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12085/thumb/Halv-logo-200x200.png?1597009175',
            'coingeckoId': 'halving-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OCT',
            'name': 'Wrapped OCT',
            'address': '0x9e70240d2A8a30a592d3F076441C4F303b26dE12',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x9e70240d2a8a30a592d3f076441c4f303b26de12.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ISHND',
            'name': 'StrongHands Finance',
            'address': '0x9e6b19874e97fe8E8CAD77f2c0AB5E7a693E5dbf',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20158/thumb/ISHND_512x512px.png?1647512898',
            'coingeckoId': 'stronghands-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MDC',
            'name': 'MindCell',
            'address': '0x9e6C59321CEB205d5d3BC6c539c017aF6159B16c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17894/thumb/oemzNgO.png?1629720177',
            'coingeckoId': 'mindcell',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DINGER',
            'name': 'Dinger',
            'address': '0x9e5BD9D9fAd182ff0A93bA8085b664bcab00fA68',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19443/thumb/dinger.png?1661498131',
            'coingeckoId': 'dinger-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CEC',
            'name': 'Chemix Ecosystem Coin',
            'address': '0x9e564eb5550E1A9b1448D916fd85a8d876661bdC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://get.celer.app/cbridge-icons/CEC.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CC',
            'name': 'CryptoCart',
            'address': '0x9e547061A345015869D26C7B6Ee4aB5b63424441',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://api.rubic.exchange/assets/ethereum/0x9e547061a345015869d26c7b6ee4ab5b63424441/logo.png',
            'coingeckoId': 'cryptocart',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'THEOS',
            'name': 'Theos',
            'address': '0x9e10f61749c4952C320412A6B26901605Ff6Da1d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18150/thumb/theos_logo.png?1630714224',
            'coingeckoId': 'theos',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '1EARTH',
            'name': 'EarthFund',
            'address': '0x9e04F519b094F5F8210441e285f603f4d2b50084',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20822/thumb/earthfund.PNG?1637722232',
            'coingeckoId': 'earthfund',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KOYO',
            'name': 'Yofune Nushi',
            'address': '0x9dAeF41E08Bc88183572b03796C96a7ace1Ec9d4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27697/thumb/Capture-removebg-preview_%283%29.png?1665303166',
            'coingeckoId': 'yofune-nushi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POLY',
            'name': 'POLY Maximus',
            'address': '0x9d93692E826A4bd9e903e2A27D7FbD1e116efdad',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28236/thumb/photo_2022-11-09_12-53-56.jpg?1668576107',
            'coingeckoId': 'poly-maximus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NVIR',
            'name': 'NvirWorld',
            'address': '0x9d71CE49ab8A0E6D2a1e7BFB89374C9392FD6804',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21359/thumb/Nvir_symbol.png?1639013234',
            'coingeckoId': 'nvirworld',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BKT',
            'name': 'Blocktanium',
            'address': '0x9d62526f5Ce701950c30F2cACa70Edf70f9fbf0F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16650/thumb/bkt.png?1624592883',
            'coingeckoId': 'blocktanium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EDC',
            'name': 'Edcoin',
            'address': '0x9d5963ba32e877871dfF3e2E697283dc64066271',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13872/thumb/EDCOIN-1.png?1612447671',
            'coingeckoId': 'aga-rewards',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DRCT',
            'name': 'Ally Direct',
            'address': '0x9d561d63375672ABd02119b9Bc4FB90EB9E307Ca',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16807/thumb/ally_icon.png?1625148232',
            'coingeckoId': 'ally-direct',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YVBOOST',
            'name': 'Yearn Compounding veCRV yVault',
            'address': '0x9d409a0A012CFbA9B15F6D4B36Ac57A46966Ab9a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/yvboost.jpg',
            'coingeckoId': 'yvboost',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'DRGB',
            'name': 'Dragonbit',
            'address': '0x9d3e0892D11f19f5181d4a4C5d04187a9e0d7032',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10123/thumb/drgb.PNG?1576125394',
            'coingeckoId': 'dragonbit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PECO',
            'name': 'Amun Polygon Ecosystem Index',
            'address': '0x9d3EE6B64e69Ebe12a4bF0b01D031CB80F556eE4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x9d3EE6B64e69Ebe12a4bF0b01D031CB80F556eE4/logo.png',
            'coingeckoId': 'polygon-ecosystem-index',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'QUID',
            'name': 'Quid Ika',
            'address': '0x9d38F670D15C14716BE1F109a4F453E966A2B6D4',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18995/thumb/PcJP5nK.png?1634126273',
            'coingeckoId': 'quid-ika',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MSLV',
            'name': 'Mirrored iShares Si',
            'address': '0x9d1555d8cB3C846Bb4f7D5B1B1080872c3166676',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/mslv.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'UGT',
            'name': 'Unreal Finance',
            'address': '0x9cf98Eb8A8B28c83E8612046cf55701Ce3Eb0063',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17943/thumb/11292.png?1629883358',
            'coingeckoId': 'unreal-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MSHIBA',
            'name': 'Meta Shiba',
            'address': '0x9cF77be84214beb066F26a4ea1c38ddcc2AFbcf7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19962/thumb/mshiba.png?1636342508',
            'coingeckoId': 'meta-shiba',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOKI',
            'name': 'Doki Doki',
            'address': '0x9cEB84f92A0561fa3Cc4132aB9c0b76A59787544',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x9ceb84f92a0561fa3cc4132ab9c0b76a59787544.png',
            'coingeckoId': 'doki-doki-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MCG',
            'name': 'MicroChains Gov Token',
            'address': '0x9cCf191169A613Eca433a249d3F40131178CAeBc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26663/thumb/B2QwV0BL_400x400.jpeg?1659449087',
            'coingeckoId': 'microchains-gov-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LBK',
            'name': 'LBK',
            'address': '0x9cB1AEaFcc8A9406632C5B084246Ea72f62d37b6',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9492/thumb/lbk.jpeg?1586144855',
            'coingeckoId': 'lbk',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALITA',
            'name': 'Alita Network',
            'address': '0x9c659CB48c4406Cd2857aEcEfF1609b2dB280d0E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17329/thumb/2LbKO1Yk_400x400.jpg?1627340043',
            'coingeckoId': 'alita-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOGY',
            'name': 'DogeYield',
            'address': '0x9c405acf8688AfB61B3197421cDeeC1A266c6839',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13734/thumb/dogy200px.png?1611266985',
            'coingeckoId': 'dogeyield',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OGV',
            'name': 'Origin Dollar Governance',
            'address': '0x9c354503C38481a7A7a51629142963F98eCC12D0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x9c354503c38481a7a7a51629142963f98ecc12d0.png',
            'coingeckoId': 'origin-dollar-governance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'LOCI',
            'name': 'LOCIcoin',
            'address': '0x9c23D67AEA7B95D80942e3836BCDF7E708A747C2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x9c23d67aea7b95d80942e3836bcdf7e708a747c2.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'HNB',
            'name': 'HashNet BitEco',
            'address': '0x9c197c4b58527fAAAb67CB35E3145166B23D242e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8126/thumb/GyEELaxS_400x400.jpg?1555226121',
            'coingeckoId': 'hashnet-biteco',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'D11',
            'name': 'DeFi11',
            'address': '0x9bf02cF6B0435A0523E6f6e0D2f35A920144F5FA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18174/thumb/wjuVH0sC_400x400.jpg?1631267074',
            'coingeckoId': 'defi11',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YLA',
            'name': 'Yearn Lazy Ape',
            'address': '0x9ba60bA98413A60dB4C651D4afE5C937bbD8044B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x9ba60bA98413A60dB4C651D4afE5C937bbD8044B/logo.png',
            'coingeckoId': 'yearn-lazy-ape',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'AUSDC',
            'name': 'Aave USDC v1',
            'address': '0x9bA00D6856a4eDF4665BcA2C2309936572473B7E',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x9ba00d6856a4edf4665bca2c2309936572473b7e.png',
            'coingeckoId': 'aave-usdc-v1',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'KITTY',
            'name': 'KITTY Vault  NFTX ',
            'address': '0x9b9fB226E98C4e90DB2830C9aefa9cfcBE3b000a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17037/thumb/KITTY.png?1626145386',
            'coingeckoId': 'kitty-vault-nftx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPROUT',
            'name': 'The Plant Dao',
            'address': '0x9b8e9d523D1D6bC8EB209301c82C7D64D10b219E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22455/thumb/56z0x6j.png?1641867803',
            'coingeckoId': 'the-plant-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KMX',
            'name': 'KIMEX',
            'address': '0x9b8C184439245B7bb24a5B2EC51Ec81c39589E8A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8062/thumb/XSsfMr8.png?1554083098',
            'coingeckoId': 'kimex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPICE',
            'name': 'Spice DAO',
            'address': '0x9b6dB7597a74602a5A806E33408e7E2DAFa58193',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21086/thumb/KfMQkToD_400x400.jpg?1638312662',
            'coingeckoId': 'spice-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DBET',
            'name': 'DecentBet',
            'address': '0x9b68bFaE21DF5A510931A262CECf63f41338F264',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1168/thumb/decent-bet.jpg?1547035195',
            'coingeckoId': 'decentbet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ECELL',
            'name': 'Consensus Cell Network',
            'address': '0x9b62Ec1453cEa5Dde760AAf662048cA6eEB66E7f',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1634696998913_8222706681114342.png',
            'coingeckoId': 'celletf',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'STPL',
            'name': 'Stream Protocol',
            'address': '0x9b5C2BE869a19e84BDBcb1386dAD83a2ec8DAe82',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13277/thumb/STPL.png?1606897574',
            'coingeckoId': 'stream-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-11300-ROXBU',
            'name': 'RealT   11300 Roxbury St  Detroit  MI 4',
            'address': '0x9b5B4886033B4ABc5Eb0552ffF7c15A67C3c47C7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16610/thumb/11300-Roxbury-hero-1.jpg?1624545386',
            'coingeckoId': '11300-roxbury',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HY',
            'name': 'Hybrix',
            'address': '0x9b53E429B0baDd98ef7F01F03702986c516a5715',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x9b53e429b0badd98ef7f01f03702986c516a5715.png',
            'coingeckoId': 'hybrix',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MF1',
            'name': 'Meta Finance',
            'address': '0x9b5161a41B58498Eb9c5FEBf89d60714089d2253',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21954/thumb/eC1-dKKt_400x400.jpg?1640326512',
            'coingeckoId': 'meta_finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MT',
            'name': 'MyToken',
            'address': '0x9b4e2B4B13d125238Aa0480dD42B4f6fC71b37CC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4109/thumb/2712.png?1547039296',
            'coingeckoId': 'mytoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CPD',
            'name': 'CoinsPaid',
            'address': '0x9b31bb425D8263fA1b8B9d090b83CF0C31665355',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18092/thumb/coinspaid.PNG?1630452234',
            'coingeckoId': 'coinspaid',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GEM',
            'name': 'NFTmall',
            'address': '0x9b17bAADf0f21F03e35249e0e59723F34994F806',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16217/thumb/Icon-1000x1000.png?1623316471',
            'coingeckoId': 'nftmall',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'XSP',
            'name': 'XSwap',
            'address': '0x9b06D48E0529ecF05905fF52DD426ebEc0EA3011',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12541/thumb/256x256_%282%29.png?1600645409',
            'coingeckoId': 'xswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WSIENNA',
            'name': 'Sienna  ERC 20 ',
            'address': '0x9b00e6E8D787b13756eb919786c9745054DB64f9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15420/thumb/sienna.jpeg?1620782072',
            'coingeckoId': 'sienna-erc20',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OIN',
            'name': 'OIN Finance',
            'address': '0x9aeB50f542050172359A0e1a25a9933Bc8c01259',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x9aeb50f542050172359a0e1a25a9933bc8c01259.png',
            'coingeckoId': 'oin-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'IZI',
            'name': 'Izumi Finance',
            'address': '0x9ad37205d608B8b219e6a2573f922094CEc5c200',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1668848861399_12189867205383109.png',
            'coingeckoId': 'izumi-finance',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'AOA',
            'name': 'Aurora Chain',
            'address': '0x9ab165D795019b6d8B3e971DdA91071421305e5a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4755/thumb/aurora-logo.png?1547040076',
            'coingeckoId': 'aurora',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EVN',
            'name': 'Evolution Finance',
            'address': '0x9aF15D7B8776fa296019979E70a5BE53c714A7ec',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x9af15d7b8776fa296019979e70a5be53c714a7ec.png',
            'coingeckoId': 'evolution-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'CNC',
            'name': 'Conic',
            'address': '0x9aE380F0272E2162340a5bB646c354271c0F5cFC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24747/thumb/MJEDO-OY_400x400.png?1648785199',
            'coingeckoId': 'conic-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOGO',
            'name': 'GOGO Finance',
            'address': '0x9a96E767bFCcE8E80370BE00821ED5BA283D4A17',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13857/thumb/gogofinance.png?1612404853',
            'coingeckoId': 'gogo-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ABX',
            'name': 'Arbidex',
            'address': '0x9a794Dc1939F1d78fa48613b89B8f9d0A20dA00E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x9a794dc1939f1d78fa48613b89b8f9d0a20da00e.png',
            'coingeckoId': 'arbidex',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SKZ',
            'name': 'SNKRZ',
            'address': '0x9a6469aaC7cd70385bdCa6fA9E9356ACec9c5B16',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27657/thumb/skz_200_200.png?1665099924',
            'coingeckoId': 'snkrz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDAP',
            'name': 'BondAppetite USD',
            'address': '0x9a1997C130f4b2997166975D9AFf92797d5134c2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14874/thumb/USDap_%281%29.png?1618822197',
            'coingeckoId': 'bondappetite-usd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WTL',
            'name': 'Welltrado',
            'address': '0x9a0587EaE7eF64b2B38A10442a44CfA43EDd7D2A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3612/thumb/welltrado.png?1548760605',
            'coingeckoId': 'welltrado',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GAJ',
            'name': 'Gaj Finance',
            'address': '0x9Fda7cEeC4c18008096C2fE2B85F05dc300F94d0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15257/thumb/logo200x200.png?1629887093',
            'coingeckoId': 'gaj',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-15095-HARTW',
            'name': 'RealT  15095 Hartwell St  Detroit  MI 4',
            'address': '0x9FEF44FC4C571010BCCD5b63e1Cdc807D3b347bF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16664/thumb/15095-Hartwell-hero-1.jpeg?1624625590',
            'coingeckoId': '15095-hartwell',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PNL',
            'name': 'True PNL',
            'address': '0x9FC8f0ca1668E87294941b7f627e9C15eA06B459',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15282/thumb/256x256logo.png?1623230007',
            'coingeckoId': 'true-pnl',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GLQ',
            'name': 'GraphLinq Protocol',
            'address': '0x9F9c8ec3534c3cE16F928381372BfbFBFb9F4D24',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x9f9c8ec3534c3ce16f928381372bfbfbfb9f4d24.png',
            'coingeckoId': 'graphlinq-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BITT',
            'name': 'BITT',
            'address': '0x9F9913853f749b3fE6D6D4e16a1Cc3C1656B6D51',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13783/thumb/BITT_Logo_256pixels.png?1611733961',
            'coingeckoId': 'bittoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-13114-GLENF',
            'name': 'RealT   13114 Glenfield Ave  Detroit  M',
            'address': '0x9F923653A19537b5a1b003854A1920fe67a8ffEB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16678/thumb/13114-Glenfield-Ave.jpeg?1624629523',
            'coingeckoId': '13114-glenfield',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRACE',
            'name': 'Trace Network Labs',
            'address': '0x9F7fC686CfD64aA5Ae15b351d03071e91533094b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23266/thumb/Token_Icon_02.png?1655797843',
            'coingeckoId': 'trace-network-labs',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TP3',
            'name': 'Token Play',
            'address': '0x9F76Ff3336597FeAB30f5A66a053ae4A4a7eBe13',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17996/thumb/WyzcFow.png?1630047790',
            'coingeckoId': 'token-play',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KRAUSE',
            'name': 'KRAUSE',
            'address': '0x9F6F91078A5072A8B54695DAfA2374Ab3cCd603b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21022/thumb/-HEQTtGz_400x400.png?1638193269',
            'coingeckoId': 'krause',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DDD',
            'name': 'Scry info',
            'address': '0x9F5F3CFD7a32700C93F971637407ff17b91c7342',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2569/thumb/Scry.info.jpg?1547036695',
            'coingeckoId': 'scry-info',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SIPHER',
            'name': 'Sipher',
            'address': '0x9F52c8ecbEe10e00D9faaAc5Ee9Ba0fF6550F511',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21070/thumb/SipherToken.png?1638312272',
            'coingeckoId': 'sipher',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KTON',
            'name': 'Darwinia Commitment',
            'address': '0x9F284E1337A815fe77D2Ff4aE46544645B20c5ff',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11895/thumb/KTON.png?1615271813',
            'coingeckoId': 'darwinia-commitment-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NBC',
            'name': 'Niobium Coin',
            'address': '0x9F195617fA8fbAD9540C5D113A99A0a0172aaEDC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4095/thumb/logo-niobium.png?1547039273',
            'coingeckoId': 'niobium-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FORTUNE',
            'name': 'Fortune',
            'address': '0x9F009D03E1b7F02065017C90e8e0D5Cb378eB015',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20374/thumb/TZcfq5b_%281%29.png?1636949601',
            'coingeckoId': 'fortune',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNISTAKE',
            'name': 'Unistake',
            'address': '0x9Ed8e7C9604790F7Ec589F99b94361d8AAB64E5E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x9ed8e7c9604790f7ec589f99b94361d8aab64e5e.png',
            'coingeckoId': 'unistake',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-18900-MANSF',
            'name': 'RealT   18900 Mansfield St  Detroit  MI',
            'address': '0x9Eb90Ec3FAafC22092C9b91559FdDde538042093',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16697/thumb/18900-Mansfield-flowers.jpeg?1624640555',
            'coingeckoId': '18900-mansfield',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DELTA',
            'name': 'Delta Financial',
            'address': '0x9EA3b5b4EC044b70375236A281986106457b20EF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x9EA3b5b4EC044b70375236A281986106457b20EF/logo.png',
            'coingeckoId': 'delta-financial',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'MAP',
            'name': 'MAP Protocol',
            'address': '0x9E976F211daea0D652912AB99b0Dc21a7fD728e4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10085/thumb/53819931.png?1649044945',
            'coingeckoId': 'marcopolo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNIFI',
            'name': 'Covenants',
            'address': '0x9E78b8274e1D6a76a0dBbf90418894DF27cBCEb5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x9e78b8274e1d6a76a0dbbf90418894df27cbceb5.png',
            'coingeckoId': 'unifi',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'NCT',
            'name': 'PolySwarm',
            'address': '0x9E46A38F5DaaBe8683E10793b06749EEF7D733d1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1642565812320_16986004049342518.png',
            'coingeckoId': 'polyswarm',
            'listedIn': [
                'coingecko',
                'uniswap',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'METIS',
            'name': 'Metis',
            'address': '0x9E32b13ce7f2E80A01932B42553652E053D6ed8e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x9e32b13ce7f2e80a01932b42553652e053d6ed8e.png',
            'coingeckoId': 'metis-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'DANDY',
            'name': 'Dandy Dego',
            'address': '0x9Dfc4B433D359024Eb3E810d77d60fbE8B0d9B82',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12764/thumb/487ogltc_400x400.jpg?1602463048',
            'coingeckoId': 'dandy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOTTO',
            'name': 'Botto',
            'address': '0x9DFAD1b7102D46b1b197b90095B5c4E9f5845BBA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18892/thumb/bottos_logo.jpg?1633791571',
            'coingeckoId': 'botto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METANO',
            'name': 'Metano',
            'address': '0x9D9e399e5385e2b9A58d4F775A1E16441b571afb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28183/thumb/logo200x.jpeg?1668256131',
            'coingeckoId': 'metano',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AKNC',
            'name': 'Aave KNC v1',
            'address': '0x9D91BE44C06d373a8a226E1f3b146956083803eB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x9d91be44c06d373a8a226e1f3b146956083803eb.png',
            'coingeckoId': 'aave-knc-v1',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-12405-SANTA',
            'name': 'RealT   12405 Santa Rosa Dr  Detroit  M',
            'address': '0x9D918eE39a356bE8eF99734599c7e70160dB4Db6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16671/thumb/12405-Santa-Rosa-hero-1.jpg?1624627801',
            'coingeckoId': '12405-santa-rosa',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OPEN',
            'name': 'Open Platform',
            'address': '0x9D86b1B2554ec410ecCFfBf111A6994910111340',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2953/thumb/open-platform.png?1548330231',
            'coingeckoId': 'open-platform',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NSBT',
            'name': 'Neutrino System Base',
            'address': '0x9D79d5B61De59D882ce90125b18F74af650acB93',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x9D79d5B61De59D882ce90125b18F74af650acB93/logo.png',
            'coingeckoId': 'neutrino-system-base-token',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'SSV',
            'name': 'SSV Network',
            'address': '0x9D65fF81a3c488d585bBfb0Bfe3c7707c7917f54',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19155/thumb/ssv.png?1638181902',
            'coingeckoId': 'ssv-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ROPE',
            'name': 'Rope',
            'address': '0x9D47894f8BECB68B9cF3428d256311Affe8B068B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x9d47894f8becb68b9cf3428d256311affe8b068b.png',
            'coingeckoId': 'rope',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'VIRAL',
            'name': 'Viral',
            'address': '0x9D37F31A4e8c6af7f64F1cE6241D24F5cACd391C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25036/thumb/4iT-NXVI_400x400.jpg?1649853009',
            'coingeckoId': 'viral',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BNBBULL',
            'name': '3X Long BNB Token',
            'address': '0x9D1a62c2AD99019768b9126fdA004a9952853F6E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10218/thumb/683JEXMN_400x400_%281%29.png?1576576413',
            'coingeckoId': '3x-long-bnb-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LIME',
            'name': 'iMe Lab',
            'address': '0x9D0B65a76274645B29e4cc41B8f23081fA09f4A3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16243/thumb/lim_200.2.png?1623376205',
            'coingeckoId': 'ime-lab',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHEX',
            'name': 'CHEX Token',
            'address': '0x9Ce84F6A69986a83d92C324df10bC8E64771030f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10349/thumb/1_0zxuLe6QnvfsZPFzOoUteQ.png?1578434355',
            'coingeckoId': 'chex-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DZAR',
            'name': 'Digital Rand',
            'address': '0x9Cb2f26A23b8d89973F08c957C4d7cdf75CD341c',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x9cb2f26a23b8d89973f08c957c4d7cdf75cd341c.png',
            'coingeckoId': 'digital-rand',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'YFIC',
            'name': 'Yearn Cash',
            'address': '0x9CF4679c67BEE8dA2D6F58c64592fFf6beE79330',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24777/thumb/yfic.png?1648900564',
            'coingeckoId': 'yearn-cash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MUST',
            'name': 'Must',
            'address': '0x9C78EE466D6Cb57A4d01Fd887D2b5dFb2D46288f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x9C78EE466D6Cb57A4d01Fd887D2b5dFb2D46288f/logo.png',
            'coingeckoId': 'must',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'TERRAFORM',
            'name': 'Terraform DAO',
            'address': '0x9C5673002d854de18e9F6Feb7f9825F88c5bf1E4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26742/thumb/tf-logo-200x200.png?1660005166',
            'coingeckoId': 'terraform-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TCR',
            'name': 'Tracer DAO',
            'address': '0x9C4A4204B79dd291D6b6571C5BE8BbcD0622F050',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/12341.png',
            'coingeckoId': 'tracer-dao',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'LIF',
            'name': 'Lif',
            'address': '0x9C38688E5ACB9eD6049c8502650db5Ac8Ef96465',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1760/thumb/lif-logo.png?1622196538',
            'coingeckoId': 'winding-tree',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTCPX',
            'name': 'BTC Proxy',
            'address': '0x9C32185b81766a051E08dE671207b34466DD1021',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22630/thumb/MB1aYO7T_400x400.jpg?1642342656',
            'coingeckoId': 'btc-proxy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COR',
            'name': 'Coreto',
            'address': '0x9C2dc0c3CC2BADdE84B0025Cf4df1c5aF288D835',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12856/large/COR.png?1620210966',
            'coingeckoId': 'coreto',
            'listedIn': [
                'coingecko',
                'dfyn',
                'rubic'
            ]
        },
        {
            'symbol': 'BBTC',
            'name': 'Binance Wrapped BTC',
            'address': '0x9BE89D2a4cd102D8Fecc6BF9dA793be995C22541',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x9be89d2a4cd102d8fecc6bf9da793be995c22541.png',
            'coingeckoId': 'binance-wrapped-btc',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'MATTER',
            'name': 'AntiMatter',
            'address': '0x9B99CcA871Be05119B2012fd4474731dd653FEBe',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x1c9491865a1de77c5b6e19d2e6a5f1d7a6f2b25f_1.png',
            'coingeckoId': 'antimatter',
            'listedIn': [
                'coingecko',
                '1inch',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'KOK',
            'name': 'KOK',
            'address': '0x9B9647431632AF44be02ddd22477Ed94d14AacAa',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10502/thumb/logo_navy.png?1580250344',
            'coingeckoId': 'kok',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOGEFI',
            'name': 'DogeFi',
            'address': '0x9B9087756eCa997C5D595C840263001c9a26646D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12290/thumb/DOGEFI-Logo.png?1598868716',
            'coingeckoId': 'dogefi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GMR',
            'name': 'Gimmer',
            'address': '0x9B8D5f3402F74C7a61d9f09c32D3cA07b45c1466',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6693/thumb/gimmer_token.jpg?1547042897',
            'coingeckoId': 'gimmer',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PPB',
            'name': 'PPBToken',
            'address': '0x9B85babc0cc89899cCd47e9226A0b1FaE577B19E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x9b85babc0cc89899ccd47e9226a0b1fae577b19e.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ARTEM',
            'name': 'Artem',
            'address': '0x9B83f827928aBdf18cF1F7e67053572b9bceff3a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21998/thumb/ARTM_Logo_MAIN_%281%29.png?1644469331',
            'coingeckoId': 'artem',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OSM',
            'name': 'Options Market',
            'address': '0x9B75848172677042269C63365B57B0A51c21d031',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14562/thumb/OSM_Logo.png?1642667815',
            'coingeckoId': 'options-market',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TCH',
            'name': 'TigerCash',
            'address': '0x9B39A0B97319a9bd5fed217c1dB7b030453bac91',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4956/thumb/tigercash-logo.png?1547040378',
            'coingeckoId': 'tigercash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FAIR',
            'name': 'FairGame',
            'address': '0x9B20DaBcec77f6289113E61893F7BEeFAEB1990a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2456/thumb/fairgame.png?1547036592',
            'coingeckoId': 'fairgame',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPI',
            'name': 'Shopping io OLD',
            'address': '0x9B02dD390a603Add5c07f9fd9175b7DABE8D63B7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13537/thumb/SPI_Token.png?1623763713',
            'coingeckoId': 'shopping-io',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QDT',
            'name': 'Quadrans',
            'address': '0x9Adc7710E9d1b29d8a78c04d52D32532297C2Ef3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26493/thumb/mu-N6mCY_400x400.png?1658285089',
            'coingeckoId': 'quadrans',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARA',
            'name': 'Adora',
            'address': '0x9Ac5C63dDcb93612E316ab31dFc8192bC8961988',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17713/thumb/Adora-200px-200px_rostsy.png?1629083234',
            'coingeckoId': 'adora-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RYOSHI',
            'name': 'RYOSHI TOKEN',
            'address': '0x9Ac59862934ebc36072D4D8ADA37c62373A13856',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://static.debank.com/image/eth_token/logo_url/0x9ac59862934ebc36072d4d8ada37c62373a13856/fda4d110f8e6c769e1b52772c23a6a39.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MOCA',
            'name': 'Museum of Crypto Art',
            'address': '0x9Ac07635DDBDE5db18648c360DEFb00F5f22537e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15829/thumb/photo_2021-06-04_09.36.16.jpeg?1622770606',
            'coingeckoId': 'museum-of-crypto-art',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WWY',
            'name': 'WeWay',
            'address': '0x9Ab70e92319f0b9127df78868Fd3655fB9f1E322',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22418/thumb/wwy.png?1661499324',
            'coingeckoId': 'weway',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HFIL',
            'name': 'Huobi Fil',
            'address': '0x9AFb950948c2370975fb91a441F36FDC02737cD4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14109/thumb/HFIL.png?1614334127',
            'coingeckoId': 'huobi-fil',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HOT',
            'name': 'Hydro Protocol',
            'address': '0x9AF839687F6C94542ac5ece2e317dAAE355493A1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2570/thumb/Hydro-Protocol.png?1558069424',
            'coingeckoId': 'hydro-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DRT',
            'name': 'DomRaider',
            'address': '0x9AF4f26941677C706cfEcf6D3379FF01bB85D5Ab',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x9af4f26941677c706cfecf6d3379ff01bb85d5ab.png',
            'coingeckoId': 'domraider',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'GOG',
            'name': 'Guild of Guardians',
            'address': '0x9AB7bb7FdC60f4357ECFef43986818A2A3569c62',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x9AB7bb7FdC60f4357ECFef43986818A2A3569c62/logo.png',
            'coingeckoId': 'guild-of-guardians',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'HUNT',
            'name': 'Hunt',
            'address': '0x9AAb071B4129B083B01cB5A0Cb513Ce7ecA26fa5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7989/thumb/HUNT.png?1571024256',
            'coingeckoId': 'hunt-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFTA',
            'name': 'NFracTion',
            'address': '0x9A54A39a8d78cD6031636628A11C890124b1b5cf',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18693/thumb/nfta.png?1632984393',
            'coingeckoId': 'nfraction',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USD++',
            'name': 'PieDAO USD++ Pool',
            'address': '0x9A48BD0EC040ea4f1D3147C025cd4076A2e71e3e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x9a48bd0ec040ea4f1d3147c025cd4076a2e71e3e.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'EYE',
            'name': 'MEDIA EYE',
            'address': '0x9A257C90Fa239fBA07771ef7da2d554D148c2E89',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20643/thumb/eye.png?1656052464',
            'coingeckoId': 'media-eye',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EVNY',
            'name': 'EVNY',
            'address': '0x9A24B8E8A6D4563c575A707b1275381119298E60',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14555/thumb/envy.jpg?1616999935',
            'coingeckoId': 'evny-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AC',
            'name': 'ACoconut',
            'address': '0x9A0aBA393aac4dFbFf4333B06c407458002C6183',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x9a0aba393aac4dfbff4333b06c407458002c6183.png',
            'coingeckoId': 'acoconut',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SX',
            'name': 'SX Network',
            'address': '0x99fE3B1391503A1bC1788051347A1324bff41452',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x99fE3B1391503A1bC1788051347A1324bff41452/logo.png',
            'coingeckoId': 'sx-network',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'QSP',
            'name': 'Quantstamp',
            'address': '0x99ea4dB9EE77ACD40B119BD1dC4E33e1C070b80d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1219/thumb/0_E0kZjb4dG4hUnoDD_.png?1604815917',
            'coingeckoId': 'quantstamp',
            'listedIn': [
                'coingecko',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BABYTRUMP',
            'name': 'Baby Trump',
            'address': '0x99d36e97676A68313ffDc627fd6b56382a2a08B6',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18014/thumb/Baby_Trump_Transpa.png?1630237728',
            'coingeckoId': 'baby-trump',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FMT',
            'name': 'Finminity',
            'address': '0x99c6e435eC259A7E8d65E1955C9423DB624bA54C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14696/thumb/finminity.png?1617834914',
            'coingeckoId': 'finminity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPONGS',
            'name': 'SpongeBob Square',
            'address': '0x99bB54f7A11EB8af1d486fe644ebed304F52f85a',
            'decimals': 9,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MIM',
            'name': 'Magic Internet Money',
            'address': '0x99D8a9C45b2ecA8864373A26D1459e3Dff1e17F3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x99d8a9c45b2eca8864373a26d1459e3dff1e17f3.png',
            'coingeckoId': 'magic-internet-money',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'POLY',
            'name': 'Polymath',
            'address': '0x9992eC3cF6A55b00978cdDF2b27BC6882d88D1eC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x9992ec3cf6a55b00978cddf2b27bc6882d88d1ec.png',
            'coingeckoId': 'polymath',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'BANCA',
            'name': 'Banca',
            'address': '0x998b3B82bC9dBA173990Be7afb772788B5aCB8Bd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2795/thumb/banca.png?1547036972',
            'coingeckoId': 'banca',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IDRT',
            'name': 'Rupiah',
            'address': '0x998FFE1E43fAcffb941dc337dD0468d52bA5b48A',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9441/thumb/57421944_1371636006308255_3647136573922738176_n.jpg?1567462531',
            'coingeckoId': 'rupiah-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BT',
            'name': 'Bitenium',
            'address': '0x997507cc49FBf0CD6ce5e1EE543218556fAFdEBc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14618/thumb/BT-token-logo-200.png?1617262055',
            'coingeckoId': 'bitenium-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TCH',
            'name': 'Thorecash  ERC 20 ',
            'address': '0x9972A0F24194447E73a7e8b6CD26a52e02DDfAD5',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4754/thumb/thorecash-logo.png?1547040075',
            'coingeckoId': 'thorecash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SKI',
            'name': 'Skillchain',
            'address': '0x996Dc5dfc819408Dd98Cd92c9a76f64b0738Dc3D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3780/thumb/SKI.png?1589121168',
            'coingeckoId': 'skillchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BHIG',
            'name': 'BuckHath Coin',
            'address': '0x996229D0c6a485c7F4B52E092EAa907cB2def5C6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7821/thumb/logstex.PNG?1585121237',
            'coingeckoId': 'buckhath-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FEN',
            'name': 'First Ever NFT',
            'address': '0x995a6C0944322b7285D0712824acf0Cf9BF3993a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24336/thumb/FEN_TOKEN_COIN_edited.png?1647361805',
            'coingeckoId': 'first-ever-nft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AMATICB',
            'name': 'Ankr MATIC Reward Earning Bond',
            'address': '0x99534Ef705Df1FFf4e4bD7bbaAF9b0dFf038EbFe',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1637331807689_3136114719013714.png',
            'coingeckoId': 'ankr-matic-reward-earning-bond',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SDAO',
            'name': 'SingularityDAO',
            'address': '0x993864E43Caa7F7F12953AD6fEb1d1Ca635B875F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15385/thumb/200x200_logo.png?1665743635',
            'coingeckoId': 'singularitydao',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'SOUL',
            'name': 'Soulsaver',
            'address': '0x992D339532A9C42F1b0E59A57e95F38da38c66F6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27494/thumb/SOUL_LOGO.png?1664268950',
            'coingeckoId': 'soulsaver',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LPL',
            'name': 'LinkPool',
            'address': '0x99295f1141d58A99e939F7bE6BBe734916a875B8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x99295f1141d58a99e939f7be6bbe734916a875b8.png',
            'coingeckoId': 'linkpool',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'STRONG',
            'name': 'Strong',
            'address': '0x990f341946A3fdB507aE7e52d17851B87168017c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x990f341946a3fdb507ae7e52d17851b87168017c.png',
            'coingeckoId': 'strong',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'KSC',
            'name': 'KStarCoin',
            'address': '0x990E081A7B7d3Ccba26a2f49746A68CC4fF73280',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1630/thumb/ksc.png?1547035850',
            'coingeckoId': 'kstarcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TOKE',
            'name': 'Non Fungible TOKE',
            'address': '0x98ddC72bD02d448F68C4226f26122c66C5BD711E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17969/thumb/toke.PNG?1629947405',
            'coingeckoId': 'non-fungible-toke',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BXA',
            'name': 'Blockchain Exchange Alliance',
            'address': '0x98d8d146e644171Cd47fF8588987B7bdeEF72A87',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7842/thumb/images.png?1551144999',
            'coingeckoId': 'blockchain-exchange-alliance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TVT',
            'name': 'TVT',
            'address': '0x98E0438d3eE1404FEA48E38e92853BB08Cfa68bD',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8388/thumb/g8hXw4QX_400x400.jpg?1557973343',
            'coingeckoId': 'tvt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QIQ',
            'name': 'QoinIQ',
            'address': '0x98Af2e926206F1Eb5Af46AEDDD144727267d0487',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17905/thumb/qiq.PNG?1629774762',
            'coingeckoId': 'qoiniq',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOONCAT',
            'name': 'MOONCAT Vault  NFTX ',
            'address': '0x98968f0747E0A261532cAcC0BE296375F5c08398',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17055/thumb/Mooncats.png?1626149563',
            'coingeckoId': 'mooncat-vault-nftx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BMX',
            'name': 'BitMart',
            'address': '0x986EE2B944c42D017F52Af21c4c69B84DBeA35d8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5236/thumb/bitmart-token.png?1548835017',
            'coingeckoId': 'bitmart-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NEWO',
            'name': 'New Order',
            'address': '0x98585dFc8d9e7D48F0b1aE47ce33332CF4237D96',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x98585dFc8d9e7D48F0b1aE47ce33332CF4237D96/logo.png',
            'coingeckoId': 'new-order',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'REALT-S-18273-MONTE',
            'name': 'RealT   18273 Monte Vista St  Detroit  ',
            'address': '0x9856C5CA15A4Ac9C65AAC090c38a9f39EB3b5eeC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16665/thumb/18273-Monte-Vista-hero-1b.jpeg?1624626435',
            'coingeckoId': '18273-monte-vista',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NUTS',
            'name': 'NutsDAO',
            'address': '0x981dc247745800bD2cA28a4bF147f0385Eaa0bc0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28165/thumb/NSD.png?1668171752',
            'coingeckoId': 'nutsdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BONE',
            'name': 'Bone ShibaSwap',
            'address': '0x9813037ee2218799597d83D4a5B6F3b6778218d9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16916/thumb/bone_icon.png?1625625505',
            'coingeckoId': 'bone-shibaswap',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'MNFT',
            'name': 'MemeNFT Official',
            'address': '0x97fef38B79640ee95EaEF250aAf8733C70007a58',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26773/thumb/MNFT-ICON-NO-BAKGROUND.png?1660048771',
            'coingeckoId': 'memenft-official',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NPLC',
            'name': 'Plus Coin',
            'address': '0x97fB6Fc2AD532033Af97043B563131C5204F8A35',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4686/thumb/plus-coin-logo.png?1547039959',
            'coingeckoId': 'plus-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIDAS',
            'name': 'Midas',
            'address': '0x97e6e31aFb2d93d437301e006D9DA714616766A5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6453/thumb/OMrkHMb.png?1547042650',
            'coingeckoId': 'midas',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UniUSDCETH',
            'name': 'Uniswap USDC LP',
            'address': '0x97deC872013f6B5fB443861090ad931542878126',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'ipfs://Qme9QQcNzKvk3FEwEZvvKJWSvDUd41z5geWHNpuJb6di9y',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ELONONE',
            'name': 'AstroElon',
            'address': '0x97b65710D03E12775189F0D113202cc1443b0aa2',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16082/thumb/AstroElon.png?1622791921',
            'coingeckoId': 'astroelon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MNTO',
            'name': 'Minato',
            'address': '0x97a9bac06f90940bCe9CAeC2b880ff17707519e4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24622/thumb/MNTO_200x200.png?1648448664',
            'coingeckoId': 'minato',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ASTRAFER',
            'name': 'Astrafer',
            'address': '0x97Bbbc5d96875fB78D2F14b7FF8d7a3a74106F17',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26246/thumb/ATSRA_Token.png?1657276411',
            'coingeckoId': 'astrafer',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'SENT',
            'name': 'Sentiment',
            'address': '0x97AbEe33Cd075c58BFdd174e0885e08E8f03556F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16388/thumb/94SwpUOP_400x400.jpg?1623894898',
            'coingeckoId': 'sentiment-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPUNK',
            'name': 'SPUNK Vault  NFTX ',
            'address': '0x97Aa8e14db0bc073cC7e2d42AC715427717d6042',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17063/thumb/Spunks_%281%29.png?1626150896',
            'coingeckoId': 'spunk-vault-nftx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BXR',
            'name': 'Blockster',
            'address': '0x97A3BD8a445cC187c6A751F392e15C3B2134D695',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19857/thumb/bxr-left2.png?1636064209',
            'coingeckoId': 'blockster',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'FSN',
            'name': 'FUSION',
            'address': '0x979aCA85bA37c675e78322ed5d97fa980B9Bdf00',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2515/thumb/Fusion_200x200.png?1639629907',
            'coingeckoId': 'fsn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TBC',
            'name': 'TeraBlock',
            'address': '0x9798dF2f5d213a872c787bD03b2b91F54D0D04A1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15622/thumb/tbc.png?1621345335',
            'coingeckoId': 'terablock',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STRP',
            'name': 'Strips Finance',
            'address': '0x97872EAfd79940C7b24f7BCc1EADb1457347ADc9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/11848.png',
            'coingeckoId': 'strips-finance',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'COLLAR',
            'name': 'Dog Collar',
            'address': '0x9783B81438C24848f85848f8df31845097341771',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18324/thumb/dcLogo.png?1631532599',
            'coingeckoId': 'dog-collar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'A4',
            'name': 'A4 Finance',
            'address': '0x9767203e89dcD34851240B3919d4900d3E5069f1',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21992/thumb/ba384ad07217a4be75cb85314f5760f7.jpg?1640582786',
            'coingeckoId': 'a4-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETCBULL',
            'name': '3X Long Ethereum Classic Token',
            'address': '0x974c98Bc2e82FA18de92B7e697A1D9BD25682e80',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10326/thumb/683JEXMN_400x400_%281%29.png?1578050594',
            'coingeckoId': '3x-long-ethereum-classic-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DX',
            'name': 'DxChain',
            'address': '0x973e52691176d36453868D9d86572788d27041A9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4700/thumb/VdZwy0Pv_400x400.png?1603089728',
            'coingeckoId': 'dxchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WAGMI',
            'name': 'WAGMI',
            'address': '0x9724f51e3aFB6B2ae0A5D86fd3b88c73283BC38F',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20531/thumb/PbEXCkig.jpg?1637201496',
            'coingeckoId': 'wagmi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BASAN',
            'name': 'Basan',
            'address': '0x970cf867Ca0530a989f222bE01FdD67C1ab5b2bF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27988/thumb/BigBasanLogo_%281%29.png?1666864518',
            'coingeckoId': 'basan',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FUSE',
            'name': 'Fuse',
            'address': '0x970B9bB2C0444F5E81e9d0eFb84C8ccdcdcAf84d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x970b9bb2c0444f5e81e9d0efb84c8ccdcdcaf84d.png',
            'coingeckoId': 'fuse-network-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'POMI',
            'name': 'Pomi',
            'address': '0x96ef7f9cF1B6eCC66E482A6598fc9F009E9277DA',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22428/thumb/pomilogo.png?1641821967',
            'coingeckoId': 'pomi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MPS',
            'name': 'Mt Pelerin Shares',
            'address': '0x96c645D3D3706f793Ef52C19bBACe441900eD47D',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11471/thumb/MPS.png?1590319120',
            'coingeckoId': 'mt-pelerin-shares',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETHBN',
            'name': 'EtherBone',
            'address': '0x96b52B5BF8D902252D0714A1BD2651A785Fd2660',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11596/thumb/ETHBN.png?1615803426',
            'coingeckoId': 'etherbone',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPORT',
            'name': 'Sport',
            'address': '0x96b14E64b53B9950d2a4D2292f8dc7d51a4f467A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'IBEUR',
            'name': 'Iron Bank EURO',
            'address': '0x96E61422b6A9bA0e068B6c5ADd4fFaBC6a4aae27',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17285/thumb/Iron_Bank_Euro.png?1627900506',
            'coingeckoId': 'iron-bank-euro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DFYN',
            'name': 'Dfyn Network',
            'address': '0x9695e0114e12C0d3A3636fAb5A18e6b737529023',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x9695e0114e12c0d3a3636fab5a18e6b737529023.png',
            'coingeckoId': 'dfyn-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'NWC',
            'name': 'Newscrypto Coin',
            'address': '0x968F6f898a6Df937fC1859b323aC2F14643e3fED',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9805/thumb/Tu1_NI3s_%281%29.png?1614745476',
            'coingeckoId': 'newscrypto-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TPY',
            'name': 'Thrupenny',
            'address': '0x968Cbe62c830A0Ccf4381614662398505657A2A9',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26799/thumb/rsz_1mpmojfho_400x400.png?1660189540',
            'coingeckoId': 'thrupenny',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OCEAN',
            'name': 'Ocean Protocol',
            'address': '0x967da4048cD07aB37855c090aAF366e4ce1b9F48',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x967da4048cd07ab37855c090aaf366e4ce1b9f48.png',
            'coingeckoId': 'ocean-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'XETA',
            'name': 'XANA',
            'address': '0x967Fb0D760ED3ce53AfE2f0A071674cccAe73550',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24379/thumb/XANA_Logo_neon_pink.png?1658197388',
            'coingeckoId': 'xana',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-4380-BEACON',
            'name': 'RealT   4380 Beaconsfield St  Detroit  ',
            'address': '0x96700Ffae33c651bC329c3f3fbFE56e1f291f117',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16603/thumb/4380-Beaconsfield-hero-1.jpeg?1624543909',
            'coingeckoId': '4380-beaconsfield',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BCNT',
            'name': 'Bincentive',
            'address': '0x9669890e48f330ACD88b78D63E1A6b3482652CD9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9661/thumb/bcnt.png?1640328324',
            'coingeckoId': 'bincentive',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EJS',
            'name': 'Enjinstarter',
            'address': '0x96610186F3ab8d73EBEe1CF950C750f3B1Fb79C2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18732/thumb/l-R1nYA0_400x400.jpg?1633297242',
            'coingeckoId': 'enjinstarter',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'M2',
            'name': 'M2',
            'address': '0x965d79F1A1016B574a62986e13Ca8Ab04DfdD15C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18239/thumb/m2_%281%29.png?1631068956',
            'coingeckoId': 'm2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SFIL',
            'name': 'Filecoin Standard Full Hashrate',
            'address': '0x965b85D4674F64422c4898C8F8083187f02B32C0',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21669/thumb/_70BfuBY_400x400.jpg?1639706531',
            'coingeckoId': 'filecoin-standard-full-hashrate',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IMON',
            'name': 'Intelligent Monsters',
            'address': '0x9631be8566fC71d91970b10AcfdEe29F21Da6C27',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26077/thumb/imonToken.png?1655690407',
            'coingeckoId': 'intelligent-monsters',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDF',
            'name': 'USD Freedom',
            'address': '0x963035453633f6f7433032c958F82Eb3043D8cD2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26920/thumb/USDF_200x200.png?1660815930',
            'coingeckoId': 'usd-freedom',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KAP',
            'name': 'Kapital DAO',
            'address': '0x9625cE7753ace1fa1865A47aAe2c5C2Ce4418569',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27682/thumb/KAP-200px.png?1665214016',
            'coingeckoId': 'kapital-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DYP',
            'name': 'DeFi Yield Protocol',
            'address': '0x961C8c0B1aaD0c0b10a51FeF6a867E3091BCef17',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x961c8c0b1aad0c0b10a51fef6a867e3091bcef17.png',
            'coingeckoId': 'defi-yield-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'DAPPT',
            'name': 'Dapp com',
            'address': '0x96184d9C811Ea0624fC30C80233B1d749B9E485B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8282/thumb/dappt_logo.png?1593074788',
            'coingeckoId': 'dapp-com',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANTv1',
            'name': 'Aragon Network Token',
            'address': '0x960b236A07cf122663c4303350609A66A7B288C0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x960b236a07cf122663c4303350609a66a7b288c0.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'XLMBEAR',
            'name': '3X Short Stellar',
            'address': '0x960Efd63aE895F165d874E6cc62501fd0e7Dc50a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13335/thumb/assets_coingecko_com-ftt.jpg?1607567062',
            'coingeckoId': '3x-short-stellar-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CBM',
            'name': 'CryptoBonusMiles',
            'address': '0x95eFD1Fe6099F65a7ED524DEF487483221094947',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9302/thumb/CryptoBonusMiles.png?1565865043',
            'coingeckoId': 'cryptobonusmiles',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CMKR',
            'name': 'cMKR',
            'address': '0x95b4eF2869eBD94BEb4eEE400a99824BF5DC325b',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17528/thumb/cmkr.PNG?1628122490',
            'coingeckoId': 'compound-maker',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PLAY',
            'name': 'InfinityGaming',
            'address': '0x95b4e47025372Ded4B73F9b5F0671B94a81445bC',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21804/thumb/ig.png?1640066214',
            'coingeckoId': 'infinitygaming',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNIDX',
            'name': 'UniDex',
            'address': '0x95b3497bBcCcc46a8F45F5Cf54b0878b39f8D96C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13178/thumb/unidx.png?1634888975',
            'coingeckoId': 'unidex',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'SHIB',
            'name': 'Shiba Inu',
            'address': '0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce.png',
            'coingeckoId': 'shiba-inu',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'PXP',
            'name': 'PointPay',
            'address': '0x95aA5d2DbD3c16ee3fdea82D5C6EC3E38CE3314f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8950/thumb/6f9823ed-464a-4413-8940-72aa61d30b43.png?1645271610',
            'coingeckoId': 'pointpay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APY',
            'name': 'APY Finance',
            'address': '0x95a4492F028aa1fd432Ea71146b433E7B4446611',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x95a4492f028aa1fd432ea71146b433e7b4446611.png',
            'coingeckoId': 'apy-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'KDAG',
            'name': 'King DAG',
            'address': '0x95E40E065AFB3059dcabe4aaf404c1F92756603a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10809/thumb/3xcLUorv_400x400.jpg?1591000563',
            'coingeckoId': 'kdag',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OCCT',
            'name': 'Official Crypto Cowboy',
            'address': '0x95A1796437BAd6502d1c1cce165Cd76E522409a9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20302/thumb/OCCT-SPROCCT_200x200.PNG?1644480861',
            'coingeckoId': 'official-crypto-cowboy-token',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'SCOR',
            'name': 'SCORCHER',
            'address': '0x9590d8C06BA451bbaD0893F2eF0D2A8B5AcC67d3',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26683/thumb/SCORCHER_LOGO_-_200.png?1659594039',
            'coingeckoId': 'scorcher',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COLL',
            'name': 'Collateral Pay',
            'address': '0x957891C11616D3E0B0A76a76fb42724C382E0eF3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14682/thumb/cpay.jpg?1617767452',
            'coingeckoId': 'collateral-pay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FEI',
            'name': 'Fei USD',
            'address': '0x956F47F50A910163D8BF957Cf5846D573E7f87CA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x956f47f50a910163d8bf957cf5846d573e7f87ca.png',
            'coingeckoId': 'fei-usd',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'PP',
            'name': 'PoorPleb',
            'address': '0x9565c2036963697786705120Fc59310F747bCfD0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28063/thumb/pp-logo-roundall-black-large.png?1667725674',
            'coingeckoId': 'poorpleb',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RETH',
            'name': 'rETH',
            'address': '0x9559Aaa82d9649C7A7b220E7c461d2E74c9a3593',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14452/thumb/rETH.png?1616660903',
            'coingeckoId': 'reth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CARD',
            'name': 'Cardstack',
            'address': '0x954b890704693af242613edEf1B603825afcD708',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3247/thumb/cardstack.png?1547037769',
            'coingeckoId': 'cardstack',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'aUniLINKETH',
            'name': 'Aave Interest bearing UniLINKETH',
            'address': '0x9548DB8b1cA9b6c757485e7861918b640390169c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'ipfs://QmaznB5PRhMC696u8yZuzN6Uwrnp7Zmfa5CydVUMvLJc9i/aUNI%20LINK%20ETH.svg',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BLOOD',
            'name': 'Impostors Blood',
            'address': '0x95392f142Af1c12F6e39897Ff9B09c599666B50c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25527/thumb/RV4nKK2RDO47XzfSLgGs_llgXivhdfpI3tso0.png?1652240523',
            'coingeckoId': 'impostors-blood',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PYR',
            'name': 'Vulcan Forged',
            'address': '0x9534ad65fb398E27Ac8F4251dAe1780B989D136e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1637933566013_3094333553365305.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ETT',
            'name': 'Etrade',
            'address': '0x952B65D976E8669c4cE92a17CcE5B2586912Adb5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9111/thumb/NkGLvEO2_400x400.jpg?1564435046',
            'coingeckoId': 'etrade',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GCME',
            'name': 'GoCryptoMe',
            'address': '0x9528cCEb678B90dAf02cA5cA45622D5cBaF58A30',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://static.debank.com/image/eth_token/logo_url/0x9528cceb678b90daf02ca5ca45622d5cbaf58a30/d3cf454aad2f068862097463dd31dfd2.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-14231-STRAT',
            'name': 'RealT   14231 Strathmoor St  Detroit  M',
            'address': '0x9528a7402C0Fe85B817aa6E106EAFa03A02924c4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16563/thumb/14231-Strathmoor-hero-1.jpg?1624459471',
            'coingeckoId': '14231-strathmoor',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOCK',
            'name': 'Meridian Network',
            'address': '0x95172ccBe8344fecD73D0a30F54123652981BD6F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x95172ccbe8344fecd73d0a30f54123652981bd6f.png',
            'coingeckoId': 'meridian-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'MLT',
            'name': 'Media Licensing Token',
            'address': '0x9506d37f70eB4C3d79C398d326C871aBBf10521d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x9506d37f70eb4c3d79c398d326c871abbf10521d.png',
            'coingeckoId': 'media-licensing-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'EMT',
            'name': 'easyMine',
            'address': '0x9501BFc48897DCEEadf73113EF635d2fF7ee4B97',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1749/thumb/emt.png?1547036034',
            'coingeckoId': 'easymine',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BANANA',
            'name': 'Banana',
            'address': '0x94e496474F1725f1c1824cB5BDb92d7691A4F03a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17521/thumb/banana-token-cg.png?1646285527',
            'coingeckoId': 'banana',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AVS',
            'name': 'AlgoVest',
            'address': '0x94d916873B22C9C1b53695f1c002F78537B9b3b2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13874/thumb/icon-200.png?1626094416',
            'coingeckoId': 'algovest',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADEL',
            'name': 'Delphi',
            'address': '0x94d863173EE77439E4292284fF13fAD54b3BA182',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12300/thumb/adel_on_white_10x.png?1598967061',
            'coingeckoId': 'akropolis-delphi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-1244-SAVERS',
            'name': 'RealT   1244 S  Avers St  Chicago  IL 6',
            'address': '0x94Fa7F8cb8453AD57cd133363b3012044647078C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16634/thumb/1244-S-Avers-hero-3.jpeg?1624555551',
            'coingeckoId': '1244-s-avers',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XRPBEAR',
            'name': '3X Short XRP Token',
            'address': '0x94FC5934cF5970E944a67de806eEB5a4b493c6E6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10210/thumb/683JEXMN_400x400_%281%29.png?1576535444',
            'coingeckoId': '3x-short-xrp-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NBLS',
            'name': 'Noblesscoin',
            'address': '0x94Eb98dB969124178189bB765bEAa2Fd36F1c5A4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26147/thumb/13-53-57-IMG-20220623-125356-998.jpg?1656040863',
            'coingeckoId': 'noblesscoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BREED',
            'name': 'BreederDAO',
            'address': '0x94E9EB8b5Ab9fd6B9ea3169D55FFAde62a01702e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25203/small/BreederDAO-Breed_Token-FINAL.png?1650638916',
            'coingeckoId': 'breederdao',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'UNIC',
            'name': 'Unicly',
            'address': '0x94E0BAb2F6Ab1F19F4750E42d7349f2740513aD5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14720/thumb/Unicly.png?1621132059',
            'coingeckoId': 'unicly',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZLP',
            'name': 'Zuplo',
            'address': '0x94D8Db14831c2c08943798542C450df2844913e5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13066/thumb/logo2x56_Mesa_de_trabajo_1.png?1604886987',
            'coingeckoId': 'zuplo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AMIS',
            'name': 'AMIS',
            'address': '0x949bEd886c739f1A3273629b3320db0C5024c719',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8445/thumb/amis-newlogo.png?1559633345',
            'coingeckoId': 'amis',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MC',
            'name': 'Merit Circle',
            'address': '0x949D48EcA67b17269629c7194F4b727d4Ef9E5d6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x949d48eca67b17269629c7194f4b727d4ef9e5d6.png',
            'coingeckoId': 'merit-circle',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'XP',
            'name': 'PolkaFantasy',
            'address': '0x948c70Dc6169Bfb10028FdBE96cbC72E9562b2Ac',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18299/thumb/XP_Token_Icon.png?1631498467',
            'coingeckoId': 'polkafantasy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RAGE',
            'name': 'Rage Fan',
            'address': '0x94804dc4948184fFd7355f62Ccbb221c9765886F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14585/thumb/Copy_of_RAGE_FAN_LOGO_ONLY.png?1627476357',
            'coingeckoId': 'rage-fan',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MDX',
            'name': 'Mandala Exchange',
            'address': '0x947AEb02304391f8fbE5B25D7D98D649b57b1788',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13736/thumb/png-cmc.png?1611288725',
            'coingeckoId': 'mandala-exchange-token',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'FME',
            'name': 'FME',
            'address': '0x947938339BF61c84669E303Bc39c794D65A525D0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12897/thumb/FME_token.png?1603336122',
            'coingeckoId': 'fme',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RING',
            'name': 'Darwinia Network',
            'address': '0x9469D013805bFfB7D3DEBe5E7839237e535ec483',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x9469d013805bffb7d3debe5e7839237e535ec483.png',
            'coingeckoId': 'darwinia-network-native-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ONE',
            'name': 'One',
            'address': '0x946551DD05C5Abd7CC808927480225ce36D8c475',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4960/thumb/Screenshot_39.png?1561103318',
            'coingeckoId': 'one',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HYDRO',
            'name': 'Hydro',
            'address': '0x946112efaB61C3636CBD52DE2E1392D7A75A6f01',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3678/thumb/New_Hydro_logo.png?1625473878',
            'coingeckoId': 'hydro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MBTC',
            'name': 'mStable BTC',
            'address': '0x945Facb997494CC2570096c74b5F66A3507330a1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x945facb997494cc2570096c74b5f66a3507330a1.png',
            'coingeckoId': 'mstable-btc',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'DDRT',
            'name': 'DigiDinar Token',
            'address': '0x94501b6A153C8973fd1f321fCC8188d40dC5d72d',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10415/thumb/8WbpGRw.png?1579128643',
            'coingeckoId': 'digidinar-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MUNCH',
            'name': 'Munch',
            'address': '0x944eeE930933BE5E23b690c8589021Ec8619a301',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x944eee930933be5e23b690c8589021ec8619a301.png',
            'coingeckoId': 'munch-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'FTI',
            'name': 'FansTime',
            'address': '0x943ED852DadB5C3938ECdC6883718df8142DE4C8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4952/thumb/fanstime.png?1547395397',
            'coingeckoId': 'fanstime',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VIRTUE',
            'name': 'Virtue',
            'address': '0x9416bA76e88D873050A06e5956A3EBF10386b863',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25834/thumb/discord.png?1654067486',
            'coingeckoId': 'virtue',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DUSK',
            'name': 'DUSK Network',
            'address': '0x940a2dB1B7008B6C776d4faaCa729d6d4A4AA551',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x940a2db1b7008b6c776d4faaca729d6d4a4aa551.png',
            'coingeckoId': 'dusk-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'JFIN',
            'name': 'JFIN Coin',
            'address': '0x940BdCb99A0Ee5Fb008A606778AE87Ed9789F257',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13370/thumb/JFin-Coin-Logo.png?1607984823',
            'coingeckoId': 'jfin-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTL',
            'name': 'Bitlocus',
            'address': '0x93e32efaFd24973d45f363A76D73ccB9Edf59986',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20913/thumb/btl.png?1637922710',
            'coingeckoId': 'bitlocus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RARE',
            'name': 'Unique One',
            'address': '0x93dfaf57D986B9cA77Df9376c50878E013D9c7C8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13458/thumb/Logo_Unique.png?1612757355',
            'coingeckoId': 'unique-one',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BITO',
            'name': 'BITO Coin',
            'address': '0x93b1E78a3e652cd2e71C4a767595B77282344932',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7796/thumb/bitopro_28.png?1550628495',
            'coingeckoId': 'bito-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'META',
            'name': 'Meta Inu Token',
            'address': '0x93EcdFe316De6DC44C2104183B7f6736d83521C2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20466/thumb/META-INU.png?1637064437',
            'coingeckoId': 'meta-inu-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PNK',
            'name': 'Kleros',
            'address': '0x93ED3FBe21207Ec2E8f2d3c3de6e058Cb73Bc04d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x93ed3fbe21207ec2e8f2d3c3de6e058cb73bc04d.png',
            'coingeckoId': 'kleros',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'gALCX',
            'name': 'governanceALCX',
            'address': '0x93Dede06AE3B5590aF1d4c111BC54C3f717E4b35',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DIYAR',
            'name': 'Diyarbekirspor',
            'address': '0x93CfE232311F49B53D4285cd54D31261980496BA',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22466/thumb/NHeXj778_400x400.jpg?1641883928',
            'coingeckoId': 'diyarbekirspor',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BCUBE',
            'name': 'B cube ai',
            'address': '0x93C9175E26F57d2888c7Df8B470C9eeA5C0b0A93',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15948/thumb/bcube.PNG?1622511353',
            'coingeckoId': 'b-cube-ai',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DAPP',
            'name': 'LiquidApps',
            'address': '0x939B462ee3311f8926c047D2B576C389092b1649',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8116/thumb/dapp-logo.jpg?1554996565',
            'coingeckoId': 'dapp',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JEJUDOGE',
            'name': 'Jejudoge',
            'address': '0x939A7A577D93ad29B64C1595B1284ce660A479B9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15983/thumb/jejudoge.png?1634782252',
            'coingeckoId': 'jejudoge',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'DEC',
            'name': 'Dark Energy Crystals',
            'address': '0x9393fdc77090F31c7db989390D43F454B1A6E7F3',
            'decimals': 3,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12923/thumb/DEC_token.png?1603683753',
            'coingeckoId': 'dark-energy-crystals',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EVMOS',
            'name': 'Evmos',
            'address': '0x93581991f68DBaE1eA105233b67f7FA0D6BDeE7b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24023/thumb/evmos.png?1653958927',
            'coingeckoId': 'evmos',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WHALE',
            'name': 'WHALE',
            'address': '0x9355372396e3F6daF13359B7b607a3374cc638e0',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x9355372396e3f6daf13359b7b607a3374cc638e0.png',
            'coingeckoId': 'whale',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'FLAG',
            'name': 'For Loot And Glory',
            'address': '0x9348E94A447bF8B2ec11f374D3F055FD47d936Df',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20726/thumb/token_logo.ico?1637595445',
            'coingeckoId': 'for-loot-and-glory',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNP',
            'name': 'UniPlay',
            'address': '0x9332dFc361763d58565139da819c86E773E17249',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27768/thumb/yHxDwXCN_400x400.png?1665721111',
            'coingeckoId': 'uniplay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OBLOX',
            'name': 'Oceidon Blox',
            'address': '0x9319820aD5447180D0CBb76c1C06c870562aEf93',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23708/thumb/Oceidon-Blox-Logo-Square-GC.png?1645083132',
            'coingeckoId': 'oceidon-blox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AAMMUNIDAIWETH',
            'name': 'Aave AMM UniDAIWETH',
            'address': '0x9303EabC860a743aABcc3A1629014CaBcc3F8D36',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17230/thumb/aAmmUniDAIWETH.png?1626920804',
            'coingeckoId': 'aave-amm-unidaiweth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ECTR',
            'name': 'eCeltron',
            'address': '0x9301466c40B94942aF9ba63cbaEA1c784FF219B2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27511/thumb/vW1LS7PgPcq9R39knqB_zb9bsVoI8yknrUQ5yIA3mVQp9gJDjSP7C-CMjZeVhavUoPSI32aCLlIwtvlyIXOdk8oKppSqEQ6ZckU9DO4Z429LUJV2vfAnsknft6Gl8bkKjktX2nZXYRHagLRfCgvcVvjDbr-EcmNi3gqgBN2liRqHNmaXJ809xCnnzIxA1Yf2fKX-PlZqxShwMSL7VICFLYo61S23HvL5nK3f4kb-94IQ5cd.jpeg?1664350712',
            'coingeckoId': 'eceltron',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWG',
            'name': 'Swirge',
            'address': '0x92eF4FFBfe0Df030837b65d7FcCFE1ABd6549579',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x92ef4ffbfe0df030837b65d7fccfe1abd6549579.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'UCASH',
            'name': 'U CASH',
            'address': '0x92e52a1A235d9A103D970901066CE910AAceFD37',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2901/thumb/ucash.png?1547037074',
            'coingeckoId': 'ucash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRDG',
            'name': 'TRDGtoken',
            'address': '0x92a42Db88Ed0F02c71D439e55962Ca7CAB0168b5',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14371/thumb/trdg.png?1635830350',
            'coingeckoId': 'tardigrades-finance',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'IDV',
            'name': 'Idavoll DAO',
            'address': '0x92Ec47DF1AA167806dFa4916D9Cfb99da6953b8F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14229/thumb/71922199.png?1615169236',
            'coingeckoId': 'idavoll-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DUCK',
            'name': 'Unit Protocol',
            'address': '0x92E187a03B6CD19CB6AF293ba17F2745Fd2357D5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x92e187a03b6cd19cb6af293ba17f2745fd2357d5.png',
            'coingeckoId': 'unit-protocol-duck',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'BANANA',
            'name': 'ApeSwap',
            'address': '0x92DF60c51C710a1b1C20E42D85e221f3A1bFc7f2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14870/thumb/banana.png?1648887183',
            'coingeckoId': 'apeswap-finance',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'DYDX',
            'name': 'dYdX',
            'address': '0x92D6C1e31e14520e676a687F0a93788B716BEff5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17500/small/hjnIm9bV.jpg?1628009360',
            'coingeckoId': 'dydx',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'OLE',
            'name': 'OpenLeverage',
            'address': '0x92CfbEC26C206C90aeE3b7C66A9AE673754FaB7e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26098/thumb/256x256_OLE_Token_Logo.png?1655859035',
            'coingeckoId': 'openleverage',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ENK',
            'name': 'EnkronosToken',
            'address': '0x92B914f1DDcBb1D117a718E83C9ED7eB32fc44d1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'crDAI',
            'name': 'Cream Dai Stablecoin',
            'address': '0x92B767185fB3B04F881e3aC8e5B0662a027A1D9f',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x92b767185fb3b04f881e3ac8e5b0662a027a1d9f.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ERT',
            'name': 'Eristica',
            'address': '0x92A5B04D0ED5D94D7a193d1d334D3D16996f4E13',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6485/thumb/6.jpg?1595305071',
            'coingeckoId': 'eristica',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SYL',
            'name': 'SYLTARE',
            'address': '0x92925Acf2771Bc59143228499f9610FE5176eb9C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26962/thumb/syl_tokenlogo.png?1661078949',
            'coingeckoId': 'syltare',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'mBTM',
            'name': 'mBTM',
            'address': '0x9288A57D6c5644B25569e9D1d5e9e328228D26c6',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1634705490428_8148326090310605.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'BRIDGE',
            'name': 'Cross Chain Bridge',
            'address': '0x92868A5255C628dA08F550a858A802f5351C5223',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20223/thumb/0x92868A5255C628dA08F550a858A802f5351C5223.png?1636684446',
            'coingeckoId': 'cross-chain-bridge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLIKS',
            'name': 'Film Coin',
            'address': '0x9280abF1AF455d6228def9c93b5E6D85F3F1ecc8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25117/thumb/Filmcoin_icon_32x32.png?1650349948',
            'coingeckoId': 'film-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NBNG',
            'name': 'Nobunaga',
            'address': '0x9275e8386A5BDdA160c0e621e9A6067b8fd88ea2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16798/thumb/nbng.jpeg?1625056917',
            'coingeckoId': 'nobunaga',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DGP',
            'name': 'DGPayment',
            'address': '0x927159670C50042109d7C0f4aEd0Cee89452433E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13291/thumb/dgpay-icon-logo.png?1607062736',
            'coingeckoId': 'dgpayment',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POCC',
            'name': 'POC Chain',
            'address': '0x926Be13B4d93F29eA254E4e518f33099e45d7f06',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7718/thumb/logo_%281%29.png?1549956313',
            'coingeckoId': 'poc-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHOW',
            'name': 'Chow Chow Finance',
            'address': '0x925f2C11b99c1A4c46606898ee91eD3D450cFedA',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14275/thumb/chow-logo.png?1620787242',
            'coingeckoId': 'chow-chow-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CINO',
            'name': 'Cino Games',
            'address': '0x9250E33f8AE7B9FE02fb9af97a0c55b42A5aD9d8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24157/thumb/cino.png?1646575256',
            'coingeckoId': 'cino-games',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEBASE',
            'name': 'Debase',
            'address': '0x9248c485b0B80f76DA451f167A8db30F33C70907',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x9248c485b0b80f76da451f167a8db30f33c70907.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'CTR',
            'name': 'Creator Platform',
            'address': '0x923b83c26B3809d960fF80332Ed00aA46D7Ed375',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18252/thumb/logo_%281%29.png?1631137441',
            'coingeckoId': 'creator-platform',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EVE',
            'name': 'Devery',
            'address': '0x923108a439C4e8C2315c4f6521E5cE95B44e9B4c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2681/thumb/devery.png?1547036885',
            'coingeckoId': 'devery',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YEE',
            'name': 'Yee',
            'address': '0x922105fAd8153F516bCfB829f56DC097a0E1D705',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2576/thumb/YeeCo-logo.png?1598519113',
            'coingeckoId': 'yee',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIKOTO',
            'name': 'Tsukuyomi no Mikoto',
            'address': '0x921fa0f0FE7e01e861b2cF583a54A8c3e9b40B1E',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27189/thumb/output-onlinepngtools_%285%29.png?1662440321',
            'coingeckoId': 'tsukuyomi-no-mikoto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'wsSPA',
            'name': 'Wrapped sSPA',
            'address': '0x921f99719Eb6C01b4B8f0BA7973A7C24891e740A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-10084-GRAYT',
            'name': 'RealT   10084 Grayton St  Detroit  MI 4',
            'address': '0x92161385C9dE8798ad5fB01c0bE99fFcbC84dFD8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16693/thumb/Grayton_84_side1x.jpeg?1624638633',
            'coingeckoId': '10084-grayton',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REAL',
            'name': 'REAL',
            'address': '0x9214eC02CB71CbA0ADA6896b8dA260736a67ab10',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x9214ec02cb71cba0ada6896b8da260736a67ab10.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'RAISE',
            'name': 'Raise Finance',
            'address': '0x920B73e4A8d27B9291efc762e88b3b676FCDDaEc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26393/thumb/RFI_Token_Logo.png?1657753383',
            'coingeckoId': 'raise-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BONDLY',
            'name': 'Forj',
            'address': '0x91dFbEE3965baAEE32784c2d546B7a0C62F268c9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x91dfbee3965baaee32784c2d546b7a0c62f268c9.png',
            'coingeckoId': 'bondly',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'HULK',
            'name': 'Hulk Inu',
            'address': '0x91a5de30e57831529a3c1aF636A78a7E4E83f3aa',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26358/thumb/HULKINU_LOGO.png?1657593698',
            'coingeckoId': 'hulk-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAP',
            'name': 'Sapchain',
            'address': '0x91F529E972D6Cf43d36ABFa91C1118122FF7F16C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9109/thumb/sap.PNG?1564433894',
            'coingeckoId': 'sapchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAXA',
            'name': 'BAXagent Coin',
            'address': '0x91B08f4A7C1251dfCcF5440F8894F8DaA10c8De5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethereum-optimism.github.io/data/BAXA/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'AERGO',
            'name': 'Aergo',
            'address': '0x91Af0fBB28ABA7E31403Cb457106Ce79397FD4E6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x91Af0fBB28ABA7E31403Cb457106Ce79397FD4E6/logo.png',
            'coingeckoId': 'aergo',
            'listedIn': [
                'coingecko',
                'uniswap',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'PUSSY',
            'name': 'Pussy Financial',
            'address': '0x9196E18Bc349B1F64Bc08784eaE259525329a1ad',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15213/thumb/pussytoken.png?1620110339',
            'coingeckoId': 'pussy-financial',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JACY',
            'name': 'JACY',
            'address': '0x916c5DE09cF63f6602d1e1793FB41F6437814A62',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x916c5de09cf63f6602d1e1793fb41f6437814a62.png',
            'coingeckoId': 'jacy',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'GTX',
            'name': 'GoalTime N',
            'address': '0x916885426255235DA7a0BD90447986c00675f9EC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10705/thumb/GTX_200x200.png?1607580745',
            'coingeckoId': 'goaltime-n',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AIPRO',
            'name': 'AIPRO',
            'address': '0x913c9509b94D32Ee38B0d98431C1FF109d24ff16',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28217/thumb/AIPro_logo_png.png?1668429140',
            'coingeckoId': 'aipro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UHP',
            'name': 'Ulgen Hash Power',
            'address': '0x9135D92e3A34e2A94e4474B74b9DC2d51118eeD5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11897/thumb/download_%2816%29.png?1595892702',
            'coingeckoId': 'ulgen-hash-power',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WACO',
            'name': 'Waste Digital Coin',
            'address': '0x910c4DA718CAF4EE38cE5c2490CDDAEca689204e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19035/thumb/waco.png?1634197749',
            'coingeckoId': 'waste-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'X8X',
            'name': 'X8X',
            'address': '0x910Dfc18D6EA3D6a7124A6F8B5458F281060fa4c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x910dfc18d6ea3d6a7124a6f8b5458f281060fa4c.png',
            'coingeckoId': 'x8-project',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'AZUKI',
            'name': 'Azuki',
            'address': '0x910524678C0B1B23FFB9285a81f99C29C11CBaEd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x910524678c0b1b23ffb9285a81f99c29c11cbaed.png',
            'coingeckoId': 'azuki',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'PLSF',
            'name': 'PulseFloki',
            'address': '0x90F36d7BfBa633F17eCcc62Ec31C58a9a3C04C2a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26833/thumb/Pulse_Floki_Transparant.PNG?1660298457',
            'coingeckoId': 'pulsefloki',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RHLLOR',
            'name': 'Token of Fire',
            'address': '0x90E2Fa98DfC518317600Dd3DC571DE8D071a7238',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28100/thumb/logo_200x.png?1667539104',
            'coingeckoId': 'token-of-fire',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UDT',
            'name': 'Unlock Protocol',
            'address': '0x90DE74265a416e1393A450752175AED98fe11517',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x90de74265a416e1393a450752175aed98fe11517.png',
            'coingeckoId': 'unlock-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'YFFS',
            'name': 'YFFS Finance',
            'address': '0x90D702f071d2af33032943137AD0aB4280705817',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12309/thumb/KijHtdcg_400x400.jpg?1599041092',
            'coingeckoId': 'yffs',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIMO',
            'name': 'Mimo Governance',
            'address': '0x90B831fa3Bebf58E9744A14D638E25B4eE06f9Bc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16449/thumb/mimodefi.PNG?1624240539',
            'coingeckoId': 'mimo-parallel-governance-token',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ALTBEAR',
            'name': '3X Short Altcoin Index Token',
            'address': '0x90B417Ab462440Cf59767BCf72D0d91CA42F21ED',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10199/thumb/683JEXMN_400x400_%281%29.png?1576533954',
            'coingeckoId': '3x-short-altcoin-index-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZKP',
            'name': 'Panther Protocol',
            'address': '0x909E34d3f6124C324ac83DccA84b74398a6fa173',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18611/thumb/1_FVXTRf8HogOiXOQDzonE5g.png?1653199708',
            'coingeckoId': 'panther',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HRIMP',
            'name': 'WhaleStreet  hrimp',
            'address': '0x9077F9e1eFE0eA72867ac89046b2a6264CbcaeF5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17646/thumb/logo_-_2021-08-12T134059.988.png?1628746866',
            'coingeckoId': 'whalestreet-shrimp-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHE',
            'name': 'ShineChain',
            'address': '0x9064c91e51d7021A85AD96817e1432aBf6624470',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6035/thumb/shinechain.png?1547041982',
            'coingeckoId': 'shinechain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTC',
            'name': 'Doc com',
            'address': '0x905E337c6c8645263D3521205Aa37bf4d034e745',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4177/thumb/2711.png?1650864171',
            'coingeckoId': 'medical-token-currency',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PDAI',
            'name': 'Prime DAI',
            'address': '0x9043d4d51C9d2e31e3F169de4551E416970c27Ef',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12338/thumb/pdai-200px.png?1599136775',
            'coingeckoId': 'prime-dai',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PRO',
            'name': 'ProToken',
            'address': '0x9041Fe5B3FDEA0f5e4afDC17e75180738D877A01',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x9041fe5b3fdea0f5e4afdc17e75180738d877a01.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'STND',
            'name': 'Standard Protocol',
            'address': '0x9040e237C3bF18347bb00957Dc22167D0f2b999d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x9040e237C3bF18347bb00957Dc22167D0f2b999d/logo.png',
            'coingeckoId': 'standard-protocol',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ICHI',
            'name': 'Legacy ICHI',
            'address': '0x903bEF1736CDdf2A537176cf3C64579C3867A881',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13119/small/ICHI_%28Round%29.jpg?1614308761',
            'coingeckoId': 'legacy-ichi',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic',
                'xyfinance'
            ]
        },
        {
            'symbol': 'DOGUS',
            'name': 'Dogus',
            'address': '0x903904Cb39bac33d4983EAd3b3F573D720c7965e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19196/thumb/rsZ3wmA.png?1634643309',
            'coingeckoId': 'dogus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GIV',
            'name': 'Giveth',
            'address': '0x900dB999074d9277c5DA2A43F252D74366230DA0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21792/thumb/GIVToken_200x200.png?1640055088',
            'coingeckoId': 'giveth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GEM',
            'name': 'Gemie',
            'address': '0x9008064E6cf73e27a3Aba4B10E69f855A4F8efcC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27745/thumb/icon-transparent.png?1665632270',
            'coingeckoId': 'gemie',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-1815-SAVERS',
            'name': 'RealT   1815 S Avers Ave  Chicago  IL 6',
            'address': '0x8fcB39A25e639C8fbD28E8a018227D6570E02352',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16674/thumb/1815-S-Avers-hero-1.5.jpg?1624628519',
            'coingeckoId': '1815-s-avers',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ECU',
            'name': 'ECOSC',
            'address': '0x8fc9b6354E839AB1c8B31F4afa53607092B8C2e5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10989/thumb/ecosc-logo.png?1586914826',
            'coingeckoId': 'ecoscu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SMAX',
            'name': 'ShibaMax',
            'address': '0x8fa025E8342EaD1037d2954b95333FF2Bf57162b',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16218/thumb/ShibaMax.png?1623652199',
            'coingeckoId': 'shibamax',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IPM',
            'name': 'Timers',
            'address': '0x8fEEf860E9fA9326ff9d7E0058F637bE8579Cc29',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12649/thumb/logo200x200_%282%29.png?1601421807',
            'coingeckoId': 'timers',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'oORN',
            'name': 'Old Orion Protocol',
            'address': '0x8fB00FDeBb4E83f2C58b3bcD6732AC1B6A7b7221',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1634704367569_15150291709452413.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'REQ',
            'name': 'Request',
            'address': '0x8f8221aFbB33998d8584A2B05749bA73c37a938a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x8f8221afbb33998d8584a2b05749ba73c37a938a.png',
            'coingeckoId': 'request-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'NFCR',
            'name': 'NFCore',
            'address': '0x8f6E8cDFA6Cee7080864dcBB6b973d17d8D80a8f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25655/thumb/nfcr.png?1653048464',
            'coingeckoId': 'nfcore',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DATA',
            'name': 'Streamr',
            'address': '0x8f693ca8D21b157107184d29D398A8D082b38b76',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x8f693ca8d21b157107184d29d398a8d082b38b76.png',
            'coingeckoId': 'streamr',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'VERI',
            'name': 'Veritaseum',
            'address': '0x8f3470A7388c05eE4e7AF3d01D8C722b0FF52374',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/695/thumb/veritaseum.png?1547034460',
            'coingeckoId': 'veritaseum',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'MES',
            'name': 'MesChain',
            'address': '0x8f1135eA4f8946949441716d66e5390C5A990DF0',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9719/thumb/TtPovcoX_400x400.jpg?1571263455',
            'coingeckoId': 'meschain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POS32',
            'name': 'PoS 32',
            'address': '0x8eb5bD8c9Ab0F8ad28e94693F3c889F490bE2aB0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27358/thumb/90532f66-9158-4bd4-8928-bcb8ec012579.png?1663663764',
            'coingeckoId': 'pos-32',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TXL',
            'name': 'Autobahn Network',
            'address': '0x8eEF5a82E6Aa222a60F009ac18c24EE12dBf4b41',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x8eef5a82e6aa222a60f009ac18c24ee12dbf4b41.png',
            'coingeckoId': 'autobahn-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'AGI',
            'name': 'SingularityNET Token',
            'address': '0x8eB24319393716668D768dCEC29356ae9CfFe285',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x8eb24319393716668d768dcec29356ae9cffe285.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'POCHI',
            'name': 'Pochi Inu',
            'address': '0x8eB1779A32678452eB273A22d413207299904d90',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23816/thumb/logo-gold.png?1645513711',
            'coingeckoId': 'pochi-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HUB',
            'name': 'Minter Hub',
            'address': '0x8e9A29e7Ed21DB7c5B2E1cd75e676dA0236dfB45',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14639/thumb/hub200.png?1651724396',
            'coingeckoId': 'minter-hub',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VGO',
            'name': 'Virtual Goods',
            'address': '0x8e87F1811De0025D2335174dbc7338a43dF6d7cc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8889/thumb/1QdEK96k_400x400.png?1562574531',
            'coingeckoId': 'virtual-goods-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XDNA',
            'name': 'extraDNA',
            'address': '0x8e57c27761EBBd381b0f9d09Bb92CeB51a358AbB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12667/thumb/Logo_coin_black.png?1601463830',
            'coingeckoId': 'extradna',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CNFT',
            'name': 'Communifty',
            'address': '0x8e2B4badaC15a4ec8c56020f4Ce60faa7558c052',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x8e2B4badaC15a4ec8c56020f4Ce60faa7558c052/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'EGT',
            'name': 'Egretia',
            'address': '0x8e1b448EC7aDFc7Fa35FC2e885678bD323176E34',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3614/thumb/egretia.png?1547224764',
            'coingeckoId': 'egretia',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FREN',
            'name': 'FrenChain',
            'address': '0x8e14c88aB0644eF41bd7138ab91C0160D8c1583A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28113/thumb/200.png?1667550745',
            'coingeckoId': 'frenchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ECO',
            'name': 'ECO',
            'address': '0x8dBF9A4c99580fC7Fd4024ee08f3994420035727',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27961/thumb/_ECO.png?1666751247',
            'coingeckoId': 'eco',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNB',
            'name': 'Unbound Finance',
            'address': '0x8dB253a1943DdDf1AF9bcF8706ac9A0Ce939d922',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/7846.png',
            'coingeckoId': 'unbound-finance',
            'listedIn': [
                'coingecko',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'ETHPAD',
            'name': 'ETHPad',
            'address': '0x8dB1D28Ee0d822367aF8d220C0dc7cB6fe9DC442',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17520/thumb/tHAbIBQK_400x400.jpg?1628075612',
            'coingeckoId': 'ethpad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TBTC',
            'name': 'tBTC',
            'address': '0x8dAEBADE922dF735c38C80C7eBD708Af50815fAa',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x8daebade922df735c38c80c7ebd708af50815faa.png',
            'coingeckoId': 'tbtc',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ACRV',
            'name': 'Aave CRV',
            'address': '0x8dAE6Cb04688C62d939ed9B68d32Bc62e49970b1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17250/thumb/aCRV_2x.png?1626941642',
            'coingeckoId': 'aave-crv',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BZR',
            'name': 'Bazaars',
            'address': '0x8d96B4ab6C741a4C8679AE323a100d74f085BA8F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26392/thumb/bazaars_logo-color-black-icon.png?1657753265',
            'coingeckoId': 'bazaars',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNT',
            'name': 'Unity Network',
            'address': '0x8d610E20481F4C4f3aCB87bBa9c46BeF7795fdFe',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/14602.png',
            'coingeckoId': 'unity-network',
            'listedIn': [
                'coingecko',
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'UPUNK',
            'name': 'Unicly CryptoPunks Collection',
            'address': '0x8d2BFfCbB19Ff14A698C424FbcDcFd17aab9b905',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14905/thumb/cryptopunk7804.png?1618978888',
            'coingeckoId': 'unicly-cryptopunks-collection',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SQGL',
            'name': 'SQGL Vault  NFTX ',
            'address': '0x8d137e3337eb1B58A222Fef2B2Cc7C423903d9cf',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17041/thumb/SQGL.png?1626147070',
            'coingeckoId': 'sqgl-vault-nftx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AIRX',
            'name': 'Aircoins',
            'address': '0x8cb1d155a5a1d5d667611b7710920fD9D1CD727F',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9201/thumb/Aircoins.png?1591615033',
            'coingeckoId': 'aircoins',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWL',
            'name': 'Swiftlance',
            'address': '0x8cD480260A47f04589670a313D27A15b321AD266',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6052/thumb/swiftlance_qssxxz.png?1547042011',
            'coingeckoId': 'swiftlance-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DDDD',
            'name': 'People s Punk',
            'address': '0x8cA9a0fbd8DB501F013F2e9e33a1B9dC129A48E0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17973/thumb/unnamed_%284%29.png?1629956290',
            'coingeckoId': 'peoples-punk',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AET',
            'name': 'AEROTOKEN',
            'address': '0x8c9E4CF756b9d01D791b95bc2D0913EF2Bf03784',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9772/thumb/sVM73x16_400x400.jpg?1571707385',
            'coingeckoId': 'aerotoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FABRIC',
            'name': 'MetaFabric',
            'address': '0x8c6Fa66c21aE3fC435790E451946a9Ea82E6e523',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21233/thumb/LISTING-icon.png?1638758217',
            'coingeckoId': 'metafabric',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'aUniMKRETH',
            'name': 'Aave Interest bearing UniMKRETH',
            'address': '0x8c69f7A4C9B38F1b48005D216c398Efb2F1Ce3e4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'ipfs://QmaznB5PRhMC696u8yZuzN6Uwrnp7Zmfa5CydVUMvLJc9i/aUNI%20MKR%20ETH.svg',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'XLAB',
            'name': 'XCELTOKEN PLUS',
            'address': '0x8c4E7f814d40f8929F9112C5D09016F923d34472',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8508/thumb/CXQJPNyO_400x400.jpg?1559030200',
            'coingeckoId': 'xceltoken-plus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STOP',
            'name': 'SatoPay',
            'address': '0x8c3eE4F778E282B59D42d693A97b80b1ed80f4Ee',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12220/thumb/stop.png?1598241582',
            'coingeckoId': 'satopay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFU',
            'name': 'Safuchain',
            'address': '0x8c1bee08bdCb69E68fd23F1D02495a2D59F2a01a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27381/thumb/QmZh4N7mpwJv9wRUffy9P4bc35k4DuzCRkmLD1fSYUKzpA.png?1663743589',
            'coingeckoId': 'safuchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FX',
            'name': 'Function X',
            'address': '0x8c15Ef5b4B21951d50E53E4fbdA8298FFAD25057',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8186/thumb/47271330_590071468072434_707260356350705664_n.jpg?1556096683',
            'coingeckoId': 'fx-coin',
            'listedIn': [
                'coingecko',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'PUMLX',
            'name': 'PUMLx',
            'address': '0x8c088775e4139af116Ac1FA6f281Bbf71E8c1c73',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27253/thumb/pumlx.png?1665154140',
            'coingeckoId': 'pumlx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APEIN',
            'name': 'Ape In',
            'address': '0x8bbf1DcCBEdD5c70d8E793d432fB56b848DD1698',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18262/thumb/apein.PNG?1631158082',
            'coingeckoId': 'ape-in',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HANDY',
            'name': 'Handy',
            'address': '0x8bbe1a2961B41340468D0548c2cd5B7DFA9b684c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21944/thumb/Handy_Token_symbol-01.png?1640313628',
            'coingeckoId': 'handy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDZ',
            'name': 'USD ZEE',
            'address': '0x8baEF8c9568c21B1a2b2fd048F8b4Da835691fD0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24876/thumb/003.png?1649217016',
            'coingeckoId': 'usd-zee',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SI14',
            'name': 'Si14Bet',
            'address': '0x8b98dF4Dff429E64E9A56fc6Eebe2380c6c3409c',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14973/thumb/logo-si14.png?1619213479',
            'coingeckoId': 'si14bet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AGVC',
            'name': 'AgaveCoin',
            'address': '0x8b79656FC38a04044E495e22fAD747126ca305C4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7056/thumb/G4TML4cb_400x400.jpg?1547043511',
            'coingeckoId': 'agavecoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SFCP',
            'name': 'SF Capital',
            'address': '0x8b6CdA5CC518c904e8844f445E1A7C7d2DB0fF16',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6239/thumb/36952079_1621155091328297_7456989147790573568_n.jpg?1547042274',
            'coingeckoId': 'sf-capital',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LABS',
            'name': 'LABS Group',
            'address': '0x8b0E42F366bA502d787BB134478aDfAE966C8798',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x8b0e42f366ba502d787bb134478adfae966c8798.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'LLTH',
            'name': 'Lilith Swap',
            'address': '0x8ab893e33B2CFfF425fF9C67B958036C938A2649',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23499/thumb/-c-tn85C_400x400.jpeg?1644293485',
            'coingeckoId': 'lilith-swap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OKBBULL',
            'name': '3X Long OKB Token',
            'address': '0x8aF785687ee8D75114B028997c9ca36b5CC67Bc4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10431/thumb/683JEXMN_400x400.png?1579276257',
            'coingeckoId': '3x-long-okb-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IG',
            'name': 'IGT',
            'address': '0x8a88f04e0c905054D2F33b26BB3A46D7091A039A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5434/thumb/igtoken.png?1547041141',
            'coingeckoId': 'igtoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GET',
            'name': 'GET Protocol',
            'address': '0x8a854288a5976036A725879164Ca3e91d30c6A1B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x8a854288a5976036a725879164ca3e91d30c6a1b.png',
            'coingeckoId': 'get-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'MGP',
            'name': 'MangoChain',
            'address': '0x8a845Fc339CeB022A695281554890429a34DF120',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11140/thumb/JJLGKTqL8jZunfdW5-gyQYxZBeCoHoLzoXfpaEwVYARssAaJoObmOxIRqz0f1oMRIbiMWP0Jfq1aM99-_aEI3fqoMvpUy2sD-d5ZGZAR3gxeY26OQrom9MmaY7GmONjoLebGcdA3r0IVlLxKhtcAO2XJrirbnXO6RxsJbImGPLlkE7c8KxH4Y1zjnAMqHi7xgv7PnV5iwpEiIUa.jpg?1588910984',
            'coingeckoId': 'mangochain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MXX',
            'name': 'Multiplier',
            'address': '0x8a6f3BF52A26a21531514E23016eEAe8Ba7e7018',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x8a6f3bf52a26a21531514e23016eeae8ba7e7018.png',
            'coingeckoId': 'multiplier',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BLOCKS',
            'name': 'BLOCKS',
            'address': '0x8a6D4C8735371EBAF8874fBd518b56Edd66024eB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x8a6D4C8735371EBAF8874fBd518b56Edd66024eB/logo.png',
            'coingeckoId': 'blocks',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'SFG',
            'name': 'S Finance',
            'address': '0x8a6ACA71A218301c7081d4e96D64292D3B275ce0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12605/thumb/Z7D8B4b.png?1605346184',
            'coingeckoId': 's-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FXF',
            'name': 'FINXFLO',
            'address': '0x8a40c222996f9F3431f63Bf80244C36822060f12',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13916/thumb/raVh1Iw.png?1618978607',
            'coingeckoId': 'finxflo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UCO',
            'name': 'Archethic',
            'address': '0x8a3d77e9d6968b780564936d15B09805827C21fa',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12330/thumb/Archethic_logo.png?1665916980',
            'coingeckoId': 'archethic',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'UNKAI',
            'name': 'Unkai',
            'address': '0x8a34D707189fBFa46930ABFDe3D22abDC48AB73D',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27458/thumb/2022-09-24_005337-removebg-preview_%281%29.png?1664107395',
            'coingeckoId': 'unkai',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAXR',
            'name': 'Max Revive',
            'address': '0x8a2eAd49D2123eDEE893181FB69527A022235C36',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22402/thumb/17030.png?1641794243',
            'coingeckoId': 'max-revive',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COI',
            'name': 'Coinnec',
            'address': '0x8a1a9477a710D470575b1Da335e524b27e8091ab',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2561/thumb/coi.png?1547036684',
            'coingeckoId': 'coinnec',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IGG',
            'name': 'IG Gold',
            'address': '0x8FfE40A3D0f80C0CE6b203D5cDC1A6a86d9AcaeA',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7697/thumb/N7aEdYrY_400x400.png?1561587437',
            'coingeckoId': 'ig-gold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DFI',
            'name': 'DeFiChain',
            'address': '0x8Fc8f8269ebca376D046Ce292dC7eaC40c8D358A',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11757/thumb/symbol-defi-blockchain_200.png?1597306538',
            'coingeckoId': 'defichain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTNYX',
            'name': 'BitOnyx',
            'address': '0x8Fb6c8A44A4e23fD1f5a936818b39083B4cdc865',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13684/thumb/token-logo-200px.png?1610860892',
            'coingeckoId': 'bitonyx-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HEART',
            'name': 'Humans ai',
            'address': '0x8FAc8031e079F409135766C7d5De29cf22EF897C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21273/thumb/h_logo_1x.png?1638858402',
            'coingeckoId': 'humans-ai',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'xINCHa',
            'name': 'xINCH',
            'address': '0x8F6A193C8B3c949E1046f1547C3A3f0836944E4b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x8f6a193c8b3c949e1046f1547c3a3f0836944e4b.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'stXYM',
            'name': 'stakedXYM',
            'address': '0x8F5E78888f8933Ed676a62bB6B58a4b279aBE408',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/styxm.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'CE',
            'name': 'Crypto Excellence',
            'address': '0x8F12Dfc7981DE79A8A34070a732471f2D335EecE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14809/thumb/New_logo_CE.jpg?1643879310',
            'coingeckoId': 'crypto-excellence',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FYP',
            'name': 'FlypMe',
            'address': '0x8F0921f30555624143d427b340b1156914882C10',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1098/thumb/flypme.png?1548126453',
            'coingeckoId': 'flypme',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BECOIN',
            'name': 'bePAY Finance',
            'address': '0x8F081Eb884Fd47b79536D28E2DD9d4886773F783',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21275/thumb/logo-becoin.png?1638861929',
            'coingeckoId': 'bepay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'oneWING',
            'name': 'oneWING',
            'address': '0x8F041A3940a5e6FB580075C3774E15FcFA0E1618',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1635752067919_7233413982445538.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'PAYT',
            'name': 'PayAccept',
            'address': '0x8EF47555856f6Ce2E0cd7C36AeF4FAb317d2e2E2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12513/thumb/logo.png?1603801944',
            'coingeckoId': 'payaccept',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DGMV',
            'name': 'DigiMetaverse',
            'address': '0x8EEdEFe828A0f16C8fc80e46a87Bc0f1De2d960c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23701/thumb/DigiCorpLabs_token.png?1645081296',
            'coingeckoId': 'digimetaverse',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'TYRANT',
            'name': 'Fable Of The Dragon',
            'address': '0x8EE325AE3E54e83956eF2d5952d3C8Bc1fa6ec27',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27911/thumb/da9bdde1-d446-4291-a79d-1b1c2ec183ff.png?1666342614',
            'coingeckoId': 'fable-of-the-dragon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLZ',
            'name': 'Fellaz',
            'address': '0x8E964e35A76103Af4C7D7318e1B1a82c682ae296',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26169/thumb/NUERy1Wy_400x400.png?1656336115',
            'coingeckoId': 'fellaz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDP',
            'name': 'Pax Dollar',
            'address': '0x8E870D67F660D95d5be530380D0eC0bd388289E1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x8e870d67f660d95d5be530380d0ec0bd388289e1_1.png',
            'coingeckoId': 'paxos-standard',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'STARL',
            'name': 'StarLink',
            'address': '0x8E6cd950Ad6ba651F6DD608Dc70e5886B1AA6B24',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x8e6cd950ad6ba651f6dd608dc70e5886b1aa6b24.png',
            'coingeckoId': 'starlink',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'C4C',
            'name': 'Crypto 4 A Cause Fund',
            'address': '0x8E347e33d991a51F5b654245C054Bc5A6BC60cE1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DVP',
            'name': 'Decentralized Vulnerability Platform',
            'address': '0x8E30ea2329D95802Fd804f4291220b0e2F579812',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9424/thumb/4520.png?1568598223',
            'coingeckoId': 'decentralized-vulnerability-platform',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BFIL',
            'name': 'Binance Wrapped FIL',
            'address': '0x8E16bf47065Fe843A82f4399bAF5aBac4E0822B7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://api.rubic.exchange/assets/ethereum/0x8e16bf47065fe843a82f4399baf5abac4e0822b7/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'NFTD',
            'name': 'NFTrade',
            'address': '0x8E0fE2947752BE0d5ACF73aaE77362Daf79cB379',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18578/thumb/nftrade.png?1632486088',
            'coingeckoId': 'nftrade',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GLDM',
            'name': 'GoldMint',
            'address': '0x8Dbd43048e60e3c9a602de7F5f1F1c8A9C6Ffee1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://static.debank.com/image/eth_token/logo_url/0x8dbd43048e60e3c9a602de7f5f1f1c8a9c6ffee1/a0ad566fffd6af750d3af9e8e0840800.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WSB',
            'name': 'Wallstreetbets com',
            'address': '0x8DF586aa346c3d9d1c99A21316A2735d71355eC8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24170/thumb/18622.png?1646718687',
            'coingeckoId': 'wallstreetbets-com',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZITI',
            'name': 'Ziticoin',
            'address': '0x8DD4228605e467671941Ffb4caE15cf7959C8D9D',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15255/thumb/logo200x200_%284%29.png?1620267412',
            'coingeckoId': 'ziticoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PORTAL',
            'name': 'Portal',
            'address': '0x8DB90E3e7D04C875a51997092f9178FCac9DefdB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6626/thumb/Nljbrphf_400x400.jpg?1547042827',
            'coingeckoId': 'portal',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SUB',
            'name': 'Substratum',
            'address': '0x8D75959f1E61EC2571aa72798237101F084DE63a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/950/thumb/substratum.png?1548611133',
            'coingeckoId': 'substratum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIMATIC',
            'name': 'MAI',
            'address': '0x8D6CeBD76f18E1558D4DB88138e2DeFB3909fAD6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15264/thumb/mimatic-red.png?1620281018',
            'coingeckoId': 'mimatic',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BUND',
            'name': 'Bundles',
            'address': '0x8D3E855f3f55109D473735aB76F753218400fe96',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x8d3e855f3f55109d473735ab76f753218400fe96.png',
            'coingeckoId': 'bundles',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'AGT',
            'name': 'AISF',
            'address': '0x8D2FAb1ff34f1c545673a816f1438B02D0a2E32D',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12859/thumb/AISF_token.jpg?1603095207',
            'coingeckoId': 'aisf',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MSTO',
            'name': 'Millennium Sapphire',
            'address': '0x8D26ccA0D58913932157dD4b294d4C95066172dF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24020/thumb/eliUfGAG_400x400.jpg?1646040397',
            'coingeckoId': 'millennium-sapphire',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEFI++',
            'name': 'PieDAO DEFI  ',
            'address': '0x8D1ce361eb68e9E05573443C407D4A3Bed23B033',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13304/thumb/piedao__.png?1607322674',
            'coingeckoId': 'piedao-defi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFU',
            'name': 'SafeZone',
            'address': '0x8D1E50BC4f5399F6012fd24a768a67F30630330a',
            'decimals': 19,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-15753-HARTW',
            'name': 'RealT   15753 Hartwell St  Detroit  MI ',
            'address': '0x8D1090dF790FFAFdACCda03015c05dF3b4cC9c21',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16623/thumb/15753-Hartwell-hero-2.jpg?1624551749',
            'coingeckoId': '15753-hartwell',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDTBULL',
            'name': '3X Long Tether Token',
            'address': '0x8Cce19943A01E78B7C277794fb081816F6151Bab',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10207/thumb/683JEXMN_400x400_%281%29.png?1576535011',
            'coingeckoId': '3x-long-tether-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SXP',
            'name': 'SXP',
            'address': '0x8CE9137d39326AD0cD6491fb5CC0CbA0e089b6A9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x8ce9137d39326ad0cd6491fb5cc0cba0e089b6a9.png',
            'coingeckoId': 'swipe',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ENVT',
            'name': 'NerveNetwork',
            'address': '0x8CD6e29d3686d24d3C2018CEe54621eA0f89313B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x8cd6e29d3686d24d3c2018cee54621ea0f89313b.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ARTH',
            'name': 'ARTH',
            'address': '0x8CC0F052fff7eaD7f2EdCCcaC895502E884a8a71',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16876/small/ARTH_Token.png?1625651538',
            'coingeckoId': 'arth',
            'listedIn': [
                'coingecko',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'SWAPP',
            'name': 'SWAPP Protocol',
            'address': '0x8CB924583681cbFE487A62140a994A49F833c244',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x8cb924583681cbfe487a62140a994a49f833c244.png',
            'coingeckoId': 'swapp',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ZARP',
            'name': 'ZARP Stablecoin',
            'address': '0x8CB24ed2e4f7e2065f4eB2bE5f6B0064B1919850',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27333/thumb/45F630F8-53ED-46BA-959E-ABAE03A1D5F3.jpeg?1663403124',
            'coingeckoId': 'zarp-stablecoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IDLEWBTCYIELD',
            'name': 'IdleWBTC  Best Yield ',
            'address': '0x8C81121B15197fA0eEaEE1DC75533419DcfD3151',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11936/thumb/idlewbtcv3maxyield_32.png?1596263736',
            'coingeckoId': 'idle-wbtc-yield',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GTE',
            'name': 'GreenTek',
            'address': '0x8C7e3b5e720B7a9BBCF15c38Be5B657bB2B71dBa',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21770/thumb/small-200x200-logo.png?1639994780',
            'coingeckoId': 'greentek',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GHC',
            'name': 'Galaxy Heroes',
            'address': '0x8C77057C3343B7DCC97CA21dA274730396162a98',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AAA',
            'name': 'Moon Rabbit',
            'address': '0x8C6bf16C273636523C29Db7DB04396143770F6A0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17310/thumb/logo_moon_no_inscriptions-01.png?1627275874',
            'coingeckoId': 'moon-rabbit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DSP',
            'name': 'Delio DSP',
            'address': '0x8C661806f716652B637728355cC4e2620D428F99',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26236/thumb/4Wbz4ZFt_400x400.png?1656901850',
            'coingeckoId': 'delio-dsp',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VLX',
            'name': 'Velas',
            'address': '0x8C543AED163909142695f2d2aCd0D55791a9Edb9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x8c543aed163909142695f2d2acd0d55791a9edb9.png',
            'coingeckoId': 'velas',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'YFF',
            'name': 'YFF Finance',
            'address': '0x8Be6a6158f6B8a19fe60569C757d16e546C2296D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12578/thumb/5ymP5emT_400x400.jpg?1600913790',
            'coingeckoId': 'yff-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLKC',
            'name': 'BlackHat Coin',
            'address': '0x8BCd06492416a749C9369009b3429861b7F27F6E',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15987/thumb/logo_light.png?1622537905',
            'coingeckoId': 'blackhat-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALEX',
            'name': 'Alex',
            'address': '0x8BA6DcC667d3FF64C1A2123cE72FF5F0199E5315',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10972/thumb/ALEX.png?1586742545',
            'coingeckoId': 'alex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WHALE',
            'name': 'WHALE Vault  NFTX ',
            'address': '0x8B7C94bC9ec137D67fbddb203B2814F0F1F9b377',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18347/thumb/whale.png?1631603573',
            'coingeckoId': 'whale-vault-nftx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRIO',
            'name': 'Tripio',
            'address': '0x8B40761142B9aa6dc8964e61D0585995425C3D94',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4648/thumb/tripio-logo.jpg?1547039914',
            'coingeckoId': 'tripio',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PKF',
            'name': 'PolkaFoundry',
            'address': '0x8B39B70E39Aa811b69365398e0aACe9bee238AEb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x8b39b70e39aa811b69365398e0aace9bee238aeb.png',
            'coingeckoId': 'polkafoundry',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'IOI',
            'name': 'IOI',
            'address': '0x8B3870Df408fF4D7C3A26DF852D41034eDa11d81',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15952/thumb/IOI_new_logo.png?1636797701',
            'coingeckoId': 'ioi-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAITAMA',
            'name': 'Saitama Inu',
            'address': '0x8B3192f5eEBD8579568A2Ed41E6FEB402f93f73F',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://api.rubic.exchange/assets/ethereum/0x8b3192f5eebd8579568a2ed41e6feb402f93f73f/logo.png',
            'coingeckoId': 'saitama-inu',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'AMM',
            'name': 'MicroMoney',
            'address': '0x8B1F49491477e0fB46a29fef53F1EA320D13c349',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1356/thumb/micromoney.png?1548085045',
            'coingeckoId': 'micromoney',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PLSA',
            'name': 'PulseApeCoin',
            'address': '0x8Af38D3530bbd802ff2D132Ae361897Aca004ec1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26891/thumb/2022-08-15_16.40.06.jpg?1660706016',
            'coingeckoId': 'pulseapecoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TOC',
            'name': 'TouchCon',
            'address': '0x8Af078d5A3e4a18Ff62e3AEeD4b1c448aB90b304',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8003/thumb/touchcon.png?1629088447',
            'coingeckoId': 'touchcon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PTOY',
            'name': 'Patientory',
            'address': '0x8Ae4BF2C33a8e667de34B54938B0ccD03Eb8CC06',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/746/thumb/patientory.png?1548330777',
            'coingeckoId': 'patientory',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AKRO',
            'name': 'Akropolis',
            'address': '0x8Ab7404063Ec4DBcfd4598215992DC3F8EC853d7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x8ab7404063ec4dbcfd4598215992dc3f8ec853d7.png',
            'coingeckoId': 'akropolis',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'M87',
            'name': 'MESSIER',
            'address': '0x8AF5FedC0f263841C18F31D9DbCC97A47e1aB462',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25957/thumb/MESSIERlogonew_%281%29.png?1666773848',
            'coingeckoId': 'messier',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BKKG',
            'name': 'Biokkoin',
            'address': '0x8AF22fBDeFe01b4dC7960EC04Ec73e8502F4a6B0',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13125/thumb/7F-imH-r_400x400.png?1605496322',
            'coingeckoId': 'biokkoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XTZBULL',
            'name': '3X Long Tezos Token',
            'address': '0x8AF17a6396c8f315f6b6DBC6AA686C85f9b3E554',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10254/thumb/683JEXMN_400x400_%281%29.png?1576675670',
            'coingeckoId': '3x-long-tezos-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KOI',
            'name': 'The Dragon Gate',
            'address': '0x8AE340d333fFB274969f505A6520b91024BC856C',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26730/thumb/koi.png?1659882726',
            'coingeckoId': 'the-dragon-gate',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NCT',
            'name': 'Name Change',
            'address': '0x8A9c4dfe8b9D8962B31e4e16F8321C44d48e246E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x8a9c4dfe8b9d8962b31e4e16f8321c44d48e246e.png',
            'coingeckoId': 'name-changing-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'JRT',
            'name': 'Jarvis Reward',
            'address': '0x8A9C67fee641579dEbA04928c4BC45F66e26343A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x8a9c67fee641579deba04928c4bc45f66e26343a.png',
            'coingeckoId': 'jarvis-reward-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SPUNK',
            'name': 'Shiba Punkz',
            'address': '0x8A8116a794744977941C7d3743517410969aaCBb',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27163/thumb/shibapunkzlogo.JPG?1662283310',
            'coingeckoId': 'shiba-punkz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLID',
            'name': 'Bolide',
            'address': '0x8A7aDc1B690E81c758F1BD0F72DFe27Ae6eC56A5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25548/thumb/BLID_token_logo_200x200.png?1652334831',
            'coingeckoId': 'bolide',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'LTK',
            'name': 'Litecoin LTK',
            'address': '0x8A732BC91c33c167F868E0af7e6f31e0776d0f71',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7573/thumb/LTK.jpg?1550626784',
            'coingeckoId': 'litecoin-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FOMOETH',
            'name': 'FomoETH',
            'address': '0x8A65B987D9813f0a97446eDa0dE918b2573Ae406',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21934/thumb/qSCAqd6m_400x400.jpg?1640301488',
            'coingeckoId': 'fomoeth',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'CHR',
            'name': 'Chromia',
            'address': '0x8A2279d4A90B6fe1C4B30fa660cC9f926797bAA2',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1635845229522_9204529507553625.png',
            'coingeckoId': 'chromaway',
            'listedIn': [
                'coingecko',
                'uniswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SPHRI',
            'name': 'Spherium',
            'address': '0x8A0cdfaB62eD35b836DC0633482798421C81b3Ec',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17787/thumb/Group_15.png?1629252159',
            'coingeckoId': 'spherium',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'XGC',
            'name': 'Xiglute Coin',
            'address': '0x89d3c0249307Ae570A316030764D12f53BB191FD',
            'decimals': 14,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15240/thumb/256x256_%282%29.png?1620196016',
            'coingeckoId': 'xiglute-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAI',
            'name': 'Sai',
            'address': '0x89d24A6b4CcB1B6fAA2625fE562bDD9a23260359',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359.png',
            'coingeckoId': 'sai',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'NUX',
            'name': 'Peanut',
            'address': '0x89bD2E7e388fAB44AE88BEf4e1AD12b4F1E0911c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x89bd2e7e388fab44ae88bef4e1ad12b4f1e0911c.png',
            'coingeckoId': 'peanut',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ATT',
            'name': 'Attila',
            'address': '0x89Fb927240750c1B15d4743cd58440fc5f14A11C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11337/thumb/LOGO_%2874%29.png?1589942265',
            'coingeckoId': 'attila',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STKR',
            'name': 'Staker DAO',
            'address': '0x89DcFF5Fd892f2bfc8B75dBA12804B651f769579',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14535/thumb/stakerdao_logo.png?1616812877',
            'coingeckoId': 'staker-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DIONE',
            'name': 'Dione',
            'address': '0x89B69F2d1adffA9A253d40840B6Baa7fC903D697',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26931/thumb/dione_200x200.png?1660886063',
            'coingeckoId': 'dione',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PNT',
            'name': 'pNetwork',
            'address': '0x89Ab32156e46F46D02ade3FEcbe5Fc4243B9AAeD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x89ab32156e46f46d02ade3fecbe5fc4243b9aaed.png',
            'coingeckoId': 'pnetwork',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'LULZ',
            'name': 'LULZ',
            'address': '0x89A64014d429509CfFdA1AEBc7eB36B9435794BD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17439/thumb/qHQiwr1I_400x400.jpg?1629882056',
            'coingeckoId': 'lulz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LAMB',
            'name': 'Lambda',
            'address': '0x8971f9fd7196e5cEE2C1032B50F656855af7Dd26',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4703/thumb/lambda_200.png?1666431649',
            'coingeckoId': 'lambda',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WASABI',
            'name': 'WasabiX',
            'address': '0x896e145568624a498c5a909187363AE947631503',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x896e145568624a498c5a909187363AE947631503/logo.png',
            'coingeckoId': 'wasabix',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'AVAX',
            'name': 'Avalanche',
            'address': '0x8965349fb649A33a30cbFDa057D8eC2C48AbE2A2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'PYRO',
            'name': 'PYROMATIC',
            'address': '0x89568569DA9C83CB35E59F92f5Df2F6CA829EEeE',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26662/thumb/PYRO200.jpg?1659448765',
            'coingeckoId': 'pyromatic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DRM',
            'name': 'DoDreamChain',
            'address': '0x89551b940e2A8ED8eCcF509935bAc9213fE30584',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10469/thumb/57414069_286321078958466_8377709696580059136_n.jpg?1579733292',
            'coingeckoId': 'dodreamchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZURR',
            'name': 'ZURRENCY',
            'address': '0x89509aA1D14a8E1E5364Ec4C3B041213bcDbe08D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22240/thumb/logo.png?1641274307',
            'coingeckoId': 'zurrency',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'THALES',
            'name': 'Thales',
            'address': '0x8947da500Eb47F82df21143D0C01A29862a8C3c5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethereum-optimism.github.io/data/THALES/logo.png',
            'coingeckoId': 'thales',
            'listedIn': [
                'coingecko',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'EMT',
            'name': 'Emanate',
            'address': '0x893700A1a86EE68B92536bf6fd4d3200d7369F7d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x893700A1a86EE68B92536bf6fd4d3200d7369F7d/logo.png',
            'coingeckoId': 'emanate',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'REF',
            'name': 'Ref',
            'address': '0x89303500a7Abfb178B274FD89F2469C264951e1f',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2575/thumb/reftoken.png?1548608011',
            'coingeckoId': 'reftoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XFUND',
            'name': 'xFUND',
            'address': '0x892A6f9dF0147e5f079b0993F486F9acA3c87881',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x892a6f9df0147e5f079b0993f486f9aca3c87881.png',
            'coingeckoId': 'xfund',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SHIRD',
            'name': 'Shibird',
            'address': '0x8916187112fd6e3624a80C42FA354Cd9756E695f',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28074/thumb/CGLOGO-1.png?1667386097',
            'coingeckoId': 'shibird',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EDN',
            'name': 'Edenchain',
            'address': '0x89020f0D5C5AF4f3407Eb5Fe185416c457B0e93e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3292/thumb/Eden.png?1574126935',
            'coingeckoId': 'edenchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRB',
            'name': 'Tellor',
            'address': '0x88dF592F8eb5D7Bd38bFeF7dEb0fBc02cf3778a0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0ba45a8b5d5575935b8158a88c631e9f9c95a2e5.png',
            'coingeckoId': 'tellor',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'CPT',
            'name': 'Cryptaur',
            'address': '0x88d50B466BE55222019D71F9E8fAe17f5f45FCA1',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2481/thumb/n3w6Z9xW_400x400.jpg?1548224365',
            'coingeckoId': 'cryptaur',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DXP',
            'name': 'Vela Exchange',
            'address': '0x88aa4a6C5050b9A1b2Aa7e34D0582025cA6AB745',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25114/thumb/1_0w4P8R5heRFwRomJ4vPCWQ.png?1650348942',
            'coingeckoId': 'dexpools',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIST',
            'name': 'Alchemist',
            'address': '0x88ACDd2a6425c3FaAE4Bc9650Fd7E27e0Bebb7aB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x88acdd2a6425c3faae4bc9650fd7e27e0bebb7ab.png',
            'coingeckoId': 'alchemist',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'FEAR',
            'name': 'Fear',
            'address': '0x88A9A52F944315D5B4e917b9689e65445C401E83',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15825/thumb/fear-logo-400-400.png?1625552865',
            'coingeckoId': 'fear',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'OKBHEDGE',
            'name': '1X Short OKB',
            'address': '0x889BC62E94bb6902D022bB82B38f7FCd637Df28C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12057/thumb/683JEXMN_400x400.png?1596704656',
            'coingeckoId': '1x-short-okb-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SBECOM',
            'name': 'SheBollETH Commerce',
            'address': '0x888E88E71378133b7ADa5A90c08Bc97D772A0A28',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18186/thumb/sbe.PNG?1630905841',
            'coingeckoId': 'shebolleth-commerce',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OPIUM',
            'name': 'Opium',
            'address': '0x888888888889C00c67689029D7856AAC1065eC11',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x888888888889c00c67689029d7856aac1065ec11.png',
            'coingeckoId': 'opium',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'IPISTR',
            'name': 'IPI Shorter',
            'address': '0x888888888888f195E27A2E0F98D712952aB9348e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27002/thumb/box-1.png?1661329648',
            'coingeckoId': 'ipi-shorter',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TLM',
            'name': 'Alien Worlds',
            'address': '0x888888848B652B3E3a0f34c96E00EEC0F3a23F72',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x888888848b652b3e3a0f34c96e00eec0f3a23f72.png',
            'coingeckoId': 'alien-worlds',
            'listedIn': [
                'coingecko',
                'uniswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'DFX',
            'name': 'DFX Finance',
            'address': '0x888888435FDe8e7d4c54cAb67f206e4199454c60',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x888888435fde8e7d4c54cab67f206e4199454c60.png',
            'coingeckoId': 'dfx-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'MPH',
            'name': '88mph',
            'address': '0x8888801aF4d980682e47f1A9036e589479e835C5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x8888801af4d980682e47f1a9036e589479e835c5.png',
            'coingeckoId': '88mph',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'WALLET',
            'name': 'Ambire Wallet',
            'address': '0x88800092fF476844f74dC2FC427974BBee2794Ae',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23154/thumb/wallet.PNG?1643352408',
            'coingeckoId': 'ambire-wallet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SKRT',
            'name': 'Sekuritance',
            'address': '0x887168120cb89Fb06F3E74Dc4AF20D67dF0977f6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15495/thumb/skrt.png?1621053881',
            'coingeckoId': 'sekuritance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CYC',
            'name': 'Cyclone Protocol',
            'address': '0x8861cfF2366C1128fd699B68304aD99a0764Ef9a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14065/thumb/b3_DFjFp_400x400.jpg?1614128740',
            'coingeckoId': 'cyclone-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USV',
            'name': 'Atlas USV',
            'address': '0x88536C9B2C4701b8dB824e6A16829D5B5Eb84440',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x88536C9B2C4701b8dB824e6A16829D5B5Eb84440/logo.png',
            'coingeckoId': 'atlas-usv',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'CARR',
            'name': 'Carnomaly',
            'address': '0x884DDBb5DC6c2cEf77d3E74c6CcCa315797d655B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14403/thumb/tcjOTKE3_400x400.png?1615895296',
            'coingeckoId': 'carnomaly',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRFI',
            'name': 'CrossFi',
            'address': '0x8848812BD31AEEe33313C10A840FfC3169078C5b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15162/thumb/11901619752967_.pic_hd.png?1620006681',
            'coingeckoId': 'crossfi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZAPC',
            'name': 'Zappay',
            'address': '0x88400F09934F817BAf7E5E5be3b88961A501eeaD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26535/thumb/zappay200.png?1658715592',
            'coingeckoId': 'zappay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CNG',
            'name': 'CNG Casino',
            'address': '0x883a158c9b28f8d626ACFCFbE1028f49E70c9D75',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11573/thumb/cng_casino.png?1591230675',
            'coingeckoId': 'cng-casino',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GAME',
            'name': 'X Consoles',
            'address': '0x882e5b370D595E50c24b2a0e7a94e87Cc32ADdA1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21616/thumb/photo_2021-12-25_01-03-14.jpg?1640519963',
            'coingeckoId': 'x-consoles',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELYX',
            'name': 'Elynet',
            'address': '0x881a7E25D44591C467a37Da96adf3c3705E7251b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13326/thumb/KakaoTalk_20201208_102026847.png?1607483005',
            'coingeckoId': 'elynet-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '00',
            'name': '00 Token',
            'address': '0x881Ba05de1E78f549cC63a8f6Cabb1d4AD32250D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27742/thumb/200x200.png?1665623317',
            'coingeckoId': 'zer0zer0',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BITH',
            'name': 'Bithachi',
            'address': '0x8811e4Dd5EC5Eb8764b97CC814B1339089717adA',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11765/thumb/bith3.png?1593656342',
            'coingeckoId': 'bithachi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADI',
            'name': 'Aditus',
            'address': '0x8810C63470d38639954c6B41AaC545848C46484a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1867/thumb/adi.png?1514446309',
            'coingeckoId': 'aditus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UQC',
            'name': 'Uquid Coin',
            'address': '0x8806926Ab68EB5a7b909DcAf6FdBe5d93271D6e2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1341/thumb/uquid-coin.png?1548759712',
            'coingeckoId': 'uquid-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTCONE',
            'name': 'BitCoin One',
            'address': '0x87f5E8c3425218837f3CB67dB941aF0C01323E56',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5413/thumb/b-one.png?1574907888',
            'coingeckoId': 'bitcoin-one',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PITCH',
            'name': 'Pitch',
            'address': '0x87f56Ee356B434187105b40F96B230F5283c0AB4',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2855/thumb/pitch_token.jpg?1547037026',
            'coingeckoId': 'pitch',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWAG',
            'name': 'SWAG Finance',
            'address': '0x87eDfFDe3E14c7a66c9b9724747a1C5696b742e6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x87edffde3e14c7a66c9b9724747a1c5696b742e6.png',
            'coingeckoId': 'swag-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'NFTX',
            'name': 'NFTX',
            'address': '0x87d73E916D7057945c9BcD8cdd94e42A6F47f776',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x87d73e916d7057945c9bcd8cdd94e42a6f47f776.png',
            'coingeckoId': 'nftx',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'COIN',
            'name': 'Coin Artist',
            'address': '0x87b008E57F640D94Ee44Fd893F0323AF933F9195',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12375/thumb/coin_artist_logo.png?1599403918',
            'coingeckoId': 'coin-artist',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AUC',
            'name': 'Advanced United Continent',
            'address': '0x87a92428bBc876d463C21C8e51b903f127d9A9F4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24369/thumb/200x200.png?1647441606',
            'coingeckoId': 'advanced-united-continent',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VRX',
            'name': 'Verox',
            'address': '0x87DE305311D5788e8da38D19bb427645b09CB4e5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x87de305311d5788e8da38d19bb427645b09cb4e5.png',
            'coingeckoId': 'verox',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'SANA',
            'name': 'Storage Area Network Anywhere',
            'address': '0x87CDc02f0812f08Cd50F946793706fAD9c265e2d',
            'decimals': 16,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17666/thumb/20210811154811.png?1628815808',
            'coingeckoId': 'storage-area-network-anywhere',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XSUSHI',
            'name': 'xSUSHI',
            'address': '0x8798249c2E607446EfB7Ad49eC89dD1865Ff4272',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x8798249c2e607446efb7ad49ec89dd1865ff4272.png',
            'coingeckoId': 'xsushi',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'WIZARD',
            'name': 'WIZARD Vault  NFTX ',
            'address': '0x87931E7AD81914e7898d07c68F145fC0A553D8Fb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17068/thumb/Wizards.png?1626151233',
            'coingeckoId': 'wizard-vault-nftx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KOMBAT',
            'name': 'Crypto Kombat',
            'address': '0x878CF148ccBb50426043a9AFFe54Ba408221C7fA',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15147/thumb/Kombat-token-logo-200.jpg?1619859575',
            'coingeckoId': 'crypto-kombat',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIRL',
            'name': 'Made In Real Life',
            'address': '0x8770b7Dd89E5f759eE3b226e0C45e890f87dDc48',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28021/thumb/mirl.png?1667028324',
            'coingeckoId': 'made-in-real-life',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MGH',
            'name': 'MetaGameHub DAO',
            'address': '0x8765b1A0eb57ca49bE7EACD35b24A574D0203656',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20625/thumb/mgh.PNG?1637318966',
            'coingeckoId': 'metagamehub-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RSR',
            'name': 'Reserve Rights',
            'address': '0x8762db106B2c2A0bccB3A80d1Ed41273552616E8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x8762db106b2c2a0bccb3a80d1ed41273552616e8.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'IDLE',
            'name': 'IDLE',
            'address': '0x875773784Af8135eA0ef43b5a374AaD105c5D39e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x875773784af8135ea0ef43b5a374aad105c5d39e.png',
            'coingeckoId': 'idle',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BNOX',
            'name': 'BlockNoteX',
            'address': '0x8752bf7AD53D25A4165b9370F2becc22dD8aE838',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11066/thumb/bnox-color-h256.png?1587777583',
            'coingeckoId': 'blocknotex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GDR',
            'name': 'Guider',
            'address': '0x874D4C9B980f1a13dD44CBcDB912e24Ef0671eD0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8425/thumb/mROuNfEF_400x400.png?1571308333',
            'coingeckoId': 'guider',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GAIN',
            'name': 'Gain DAO',
            'address': '0x874CB28A655e0Ef827d810E211aE4b9cBcbaF614',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26570/thumb/GAIN_listing_200.png?1658816125',
            'coingeckoId': 'gaindao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GREEN',
            'name': 'Green Token',
            'address': '0x874464211649Eb0c504903195902415fb6986AaD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AXUS',
            'name': 'Axus Coin',
            'address': '0x872D63d889D4b445C89A0887dcdBCc179B026432',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20713/thumb/-rUSqDLk_400x400.png?1637575970',
            'coingeckoId': 'axus-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JET',
            'name': 'Jetcoin',
            'address': '0x8727c112C712c4a03371AC87a74dD6aB104Af768',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2487/thumb/jetcoin.png?1547974820',
            'coingeckoId': 'jetcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FZY',
            'name': 'Frenzy',
            'address': '0x8720C8429b78df262360b0F39917a03f9B894746',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11096/thumb/frenzy-singapore.jpg?1588212527',
            'coingeckoId': 'frenzy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETHBULL',
            'name': '3X Long Ethereum Token',
            'address': '0x871baeD4088b863fd6407159f3672D70CD34837d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10163/thumb/683JEXMN_400x400_%281%29.png?1576504568',
            'coingeckoId': '3x-long-ethereum-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UGAS',
            'name': 'Ultrain',
            'address': '0x8716Fc5Da009D3A208f0178b637a50F4ef42400F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4684/thumb/ultrain.png?1547039956',
            'coingeckoId': 'ultrain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CNN',
            'name': 'Content Neutrality Network',
            'address': '0x8713d26637CF49e1b6B4a7Ce57106AaBc9325343',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2787/thumb/cnn_logo_big.png?1547036967',
            'coingeckoId': 'cnn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPC',
            'name': 'SpaceChain  ERC 20 ',
            'address': '0x86ed939B500E121C0C5f493F399084Db596dAd20',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x86ed939b500e121c0c5f493f399084db596dad20.png',
            'coingeckoId': 'spacechain-erc-20',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BNTX',
            'name': 'Bintex Futures',
            'address': '0x86d1d12523B65203851c571FcC029bF90903fB6d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12117/thumb/Bintexfutures_Icon_Logo.png?1597272672',
            'coingeckoId': 'bintex-futures',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UBSN',
            'name': 'Silent Notary',
            'address': '0x86EFc496DcA70bcFD92D19194290e8457a375773',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1599/thumb/silent-notary.png?1548609544',
            'coingeckoId': 'silent-notary',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HTBEAR',
            'name': '3X Short Huobi Token Token',
            'address': '0x86EB791495bE777db763142a2C547D1112554Fb8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10430/thumb/683JEXMN_400x400.png?1579276187',
            'coingeckoId': '3x-short-huobi-token-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QUIN',
            'name': 'QUINADS',
            'address': '0x86E44543164D9b97B14ef7f6f3aB7bA670CAB346',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7041/thumb/quinads-logo.png?1547043484',
            'coingeckoId': 'quinads',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HUH',
            'name': 'HUH',
            'address': '0x86D49fbD3B6f989d641E700a15599d3b165002AB',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21549/thumb/huh.png?1639462106',
            'coingeckoId': 'huh',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'GBPT',
            'name': 'poundtoken',
            'address': '0x86B4dBE5D203e634a12364C0e428fa242A3FbA98',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26591/thumb/gbpt.jpeg?1658958061',
            'coingeckoId': 'poundtoken',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'DOKI',
            'name': 'Okidoki Social',
            'address': '0x869DBe51Dc214fcb663604b0F7B548592F8C71f5',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27018/thumb/Okidoki_Logo.png?1661480220',
            'coingeckoId': 'okidoki-social',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LCMS',
            'name': 'LCMS',
            'address': '0x86911B82BfA596e9377836838AC1f0d9AD4Ecc19',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17324/thumb/lcms.jpeg?1627309697',
            'coingeckoId': 'lcms',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CPHX',
            'name': 'Crypto Phoenix',
            'address': '0x8689D850CdF3b74A1F6A5eB60302c785B71c2fc7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17942/thumb/xEO0oeVz_400x400.jpg?1629881740',
            'coingeckoId': 'crypto-phoenix',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LY',
            'name': 'Lilly Finance',
            'address': '0x8686525d6627A25C68De82c228448f43c97999F2',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23787/thumb/jQlMieu.png?1645437427',
            'coingeckoId': 'lilly-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRXBEAR',
            'name': '3X Short TRX Token',
            'address': '0x86807Da5B92d31F67E128771CAcb85F3579646eA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10234/thumb/683JEXMN_400x400_%281%29.png?1576618946',
            'coingeckoId': '3x-short-trx-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NDX',
            'name': 'Indexed Finance',
            'address': '0x86772b1409b61c639EaAc9Ba0AcfBb6E238e5F83',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x86772b1409b61c639eaac9ba0acfbb6e238e5f83.png',
            'coingeckoId': 'indexed-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'EBSO',
            'name': 'eBlockStock',
            'address': '0x866f8a50A64e68ca66E97e032c5Da99538B3F942',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18636/thumb/eBSO_logo_szines.png?1632730502',
            'coingeckoId': 'eblockstock',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SCC',
            'name': 'SoftChain',
            'address': '0x86696431D6ACA9bae5CE6536ECF5D437F2e6Dba2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6613/thumb/FgUyfr6K_400x400.jpg?1547042814',
            'coingeckoId': 'softchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOC',
            'name': 'Mossland',
            'address': '0x865ec58b06bF6305B886793AA20A2da31D034E68',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2374/thumb/moc.png?1547036557',
            'coingeckoId': 'mossland',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOMAT',
            'name': 'Moma Protocol',
            'address': '0x865bB9A28041259b4baDAFD37799A288aAbbfC8c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16910/thumb/moma.PNG?1625618080',
            'coingeckoId': 'moma-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LIST',
            'name': 'KList Protocol',
            'address': '0x865C77B13a882Cc264D0506f85E58DD8231d0D73',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24645/thumb/MrZr9nsN_400x400.jpg?1648479425',
            'coingeckoId': 'klist-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOLA',
            'name': 'Dola',
            'address': '0x865377367054516e17014CcdED1e7d814EDC9ce4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x865377367054516e17014ccded1e7d814edc9ce4.png',
            'coingeckoId': 'dola-usd',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'PROS',
            'name': 'Prosper',
            'address': '0x8642A849D0dcb7a15a974794668ADcfbe4794B56',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x8642a849d0dcb7a15a974794668adcfbe4794b56.png',
            'coingeckoId': 'prosper',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'LIFE',
            'name': 'DEVITA',
            'address': '0x8640353CDc9778DEaB0dF45D12FB3013dEAC079C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22268/thumb/divita.PNG?1641339910',
            'coingeckoId': 'devita-global',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-10604-SOMER',
            'name': 'RealT   10604 Somerset Ave  Detroit  MI',
            'address': '0x8626B38267e4FC0D8C92E0bB86F97Acab3f6AA05',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16682/thumb/10604-Somerset-hero-1.jpeg?1624632454',
            'coingeckoId': '10604-somerset',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FXUSD',
            'name': 'handleUSD',
            'address': '0x8616E8EA83f048ab9A5eC513c9412Dd2993bcE3F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26954/thumb/fxUSDLogo_200px-200px.png?1660988882',
            'coingeckoId': 'handleusd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CTRT',
            'name': 'Cryptrust',
            'address': '0x8606a8F28e1e2FD50B9074d65C01548B1F040B32',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6110/thumb/cryptrust.png?1547042119',
            'coingeckoId': 'cryptrust',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLY',
            'name': 'Franklin',
            'address': '0x85f6eB2BD5a062f5F8560BE93FB7147e16c81472',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14810/thumb/fly_logo_sq_bArtboard_4.png?1626420796',
            'coingeckoId': 'franklin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PLG',
            'name': 'Pledgecamp',
            'address': '0x85ca6710D0F1D511d130f6935eDDA88ACBD921bD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5120/thumb/pledgecamop.png?1550204115',
            'coingeckoId': 'pledgecamp',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BBCH',
            'name': 'Binance Wrapped BCH',
            'address': '0x85c4EdC43724e954e5849cAAab61A26a9CB65F1B',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://api.rubic.exchange/assets/ethereum/0x85c4edc43724e954e5849caaab61a26a9cb65f1b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'LTY',
            'name': 'Ledgity',
            'address': '0x85Ffb35957203dfD12061eAeCD708dB623Bd567C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17939/thumb/ledgity.PNG?1629876428',
            'coingeckoId': 'ledgity',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'NEAR',
            'name': 'NEAR',
            'address': '0x85F17Cf997934a597031b2E18a9aB6ebD4B9f6a4',
            'decimals': 24,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x85F17Cf997934a597031b2E18a9aB6ebD4B9f6a4/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'KEEP',
            'name': 'Keep Network',
            'address': '0x85Eee30c52B0b379b046Fb0F85F4f3Dc3009aFEC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x85eee30c52b0b379b046fb0f85f4f3dc3009afec.png',
            'coingeckoId': 'keep-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'openocean',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'WKDA',
            'name': 'Wrapped Kadena',
            'address': '0x85D7bdfc9c3426b33A684241eEEE70385Bc42820',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x85D7bdfc9c3426b33A684241eEEE70385Bc42820/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'AOG',
            'name': 'smARTOFGIVING',
            'address': '0x8578530205CEcbe5DB83F7F29EcfEEC860C297C2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6050/thumb/logo_%286%29.png?1547042007',
            'coingeckoId': 'smartofgiving',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LEVR',
            'name': 'LEVR - levr.ly Logistics Token',
            'address': '0x8564bA78F88B744FcC6F9407B9AF503Ad35adAFC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'UBXT',
            'name': 'UpBots',
            'address': '0x8564653879a18C560E7C0Ea0E084c516C62F5653',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x8564653879a18c560e7c0ea0e084c516c62f5653.png',
            'coingeckoId': 'upbots',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'FRAX',
            'name': 'Frax',
            'address': '0x853d955aCEf822Db058eb8505911ED77F175b99e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x853d955acef822db058eb8505911ed77f175b99e.png',
            'coingeckoId': 'frax',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'ONE1INCH',
            'name': 'Stable 1inch',
            'address': '0x853Bb55c1f469902F088A629db8C8803A9BE3857',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x853bb55c1f469902f088a629db8c8803a9be3857.png',
            'coingeckoId': 'stable-1inch',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'METADOGE',
            'name': 'Meta Doge',
            'address': '0x8530b66ca3DDf50E0447eae8aD7eA7d5e62762eD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19656/thumb/metadoge.png?1636333901',
            'coingeckoId': 'meta-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NIIFI',
            'name': 'NiiFi',
            'address': '0x852e5427c86A3b46DD25e5FE027bb15f53c4BCb8',
            'decimals': 15,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16033/thumb/niifi.PNG?1622674467',
            'coingeckoId': 'niifi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BURN',
            'name': 'BlockBurn',
            'address': '0x8515cD0f00aD81996d24b9A9C35121a3b759D6Cd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8851/thumb/blockburn.JPG?1582774870',
            'coingeckoId': 'blockburn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KONO',
            'name': 'Konomi Network',
            'address': '0x850aAB69f0e0171A9a49dB8BE3E71351c8247Df4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x850aab69f0e0171a9a49db8be3e71351c8247df4.png',
            'coingeckoId': 'konomi-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MEED',
            'name': 'Meeds DAO',
            'address': '0x8503a7b00B4b52692cC6c14e5b96F142E30547b7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24281/thumb/s-YfFWYu_400x400.png?1647218173',
            'coingeckoId': 'meeds-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INX',
            'name': 'Insight Protocol',
            'address': '0x84fE25f3921f3426395c883707950d0c00367576',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11694/thumb/logo_%EC%B0%90%EB%B8%94%EB%A3%A8.png?1592879247',
            'coingeckoId': 'insight-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JCR',
            'name': 'JustCarbon Removal',
            'address': '0x84f20BF5bB4Be345D3ab37c565f732753435dBe3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25251/thumb/Just-Carbon-JCR-2.png?1651132559',
            'coingeckoId': 'justcarbon-removal',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BNPL',
            'name': 'BNPL Pay',
            'address': '0x84d821F7FbDD595c4C4A50842913e6b1E07d7a53',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x84d821F7FbDD595c4C4A50842913e6b1E07d7a53/logo.png',
            'coingeckoId': 'bnpl-pay',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'DEFIT',
            'name': 'Digital Fitness',
            'address': '0x84cffa78B2fBbeeC8c37391d2B12A04d2030845e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x84cffa78b2fbbeec8c37391d2b12a04d2030845e.png',
            'coingeckoId': 'defit',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'DIA',
            'name': 'DIA',
            'address': '0x84cA8bc7997272c7CfB4D0Cd3D55cd942B3c9419',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x84ca8bc7997272c7cfb4d0cd3d55cd942b3c9419.png',
            'coingeckoId': 'dia-data',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'MSB',
            'name': 'Misbloc',
            'address': '0x84c722e6F1363E8D5C6dB3eA600bEF9a006Da824',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13021/thumb/xREV4lXV.png?1604396822',
            'coingeckoId': 'misbloc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTHD',
            'name': 'Method Finance',
            'address': '0x84bA4aEcfDe39D69686a841BAb434C32d179a169',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14619/thumb/mthd.PNG?1617262620',
            'coingeckoId': 'method-fi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GVR',
            'name': 'Grove',
            'address': '0x84FA8f52E437Ac04107EC1768764B2b39287CB3e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25549/thumb/200x200.png?1652335189',
            'coingeckoId': 'grove',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BBO',
            'name': 'Bigbom',
            'address': '0x84F7c44B6Fed1080f647E354D552595be2Cc602F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3795/thumb/bigbom-logo.png?1547038875',
            'coingeckoId': 'bigbom-eco',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PPC',
            'name': 'PHILLIPS PAY COIN',
            'address': '0x84F710Bae3316A74Fb0fCb01904d2578A4cc6A26',
            'decimals': 1,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10311/thumb/ppc.PNG?1588127720',
            'coingeckoId': 'philips-pay-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AKSMB',
            'name': 'ANKR Reward Earning KSM',
            'address': '0x84DA8e731172827fCB233B911678E2a82E27Baf2',
            'decimals': 12,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26028/thumb/a-ksm-b-99bb6bfd6eddad0ce2a44dac4667ec34.png?1655349825',
            'coingeckoId': 'ankr-reward-earning-ksm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STR',
            'name': 'Stater',
            'address': '0x84Bb947fcEdba6B9C7DCEad42dF07e113bb03007',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14476/thumb/str.png?1639046202',
            'coingeckoId': 'stater',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'aUniSETHETH',
            'name': 'Aave Interest bearing UniSETHETH',
            'address': '0x84BBcaB430717ff832c3904fa6515f97fc63C76F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'ipfs://QmaznB5PRhMC696u8yZuzN6Uwrnp7Zmfa5CydVUMvLJc9i/aUNI%20sETH%20ETH.svg',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'THECITADEL',
            'name': 'The Citadel',
            'address': '0x849bA2278cdAe7fA7006c0661FEA1c35D5Af3336',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20445/thumb/B307-A646-EBD2-456-C-B29-D-1-B00-A470-F8-CE.jpg?1637041816',
            'coingeckoId': 'the-citadel',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CGU',
            'name': 'Crypto Global United',
            'address': '0x849A226f327b89E3133D9930d927F9EB9346F8C9',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20751/thumb/2022_CGU-MG_RGB.png?1666606977',
            'coingeckoId': 'crypto-global-united',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABYTSUKA',
            'name': 'Dwagon',
            'address': '0x848592388097D2A3CA9E285F445e5B92B6af52Ad',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27480/thumb/photo_2022-09-25_21-37-24.jpg?1664242733',
            'coingeckoId': 'dwagon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PMA',
            'name': 'PumaPay',
            'address': '0x846C66cf71C43f80403B51fE3906B3599D63336f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2307/thumb/pumapay_dark_rounded_256x256.png?1622176373',
            'coingeckoId': 'pumapay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHONK',
            'name': 'Chonk',
            'address': '0x84679bc467DC6c2c40ab04538813AfF3796351f1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12899/thumb/a2LHjXZ.jpeg?1603418225',
            'coingeckoId': 'chonk',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SATOZ',
            'name': 'Satozhi',
            'address': '0x845e2e8B42DCED7DEDCDBa9BdE32C9e338224f97',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14786/thumb/0GeSrIaQ_400x400.jpg?1618416420',
            'coingeckoId': 'satozhi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AVT',
            'name': 'Artverse',
            'address': '0x845576c64f9754CF09d87e45B720E82F3EeF522C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19727/thumb/ewnektoB_400x400.png?1635767094',
            'coingeckoId': 'artverse-token',
            'listedIn': [
                'coingecko',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'NHT',
            'name': 'Neighbourhoods',
            'address': '0x84342e932797FC62814189f01F0Fb05F52519708',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22055/thumb/social_media_logo_black_bg.png?1640682530',
            'coingeckoId': 'neighbourhoods',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIH',
            'name': 'Shih Tzu',
            'address': '0x841FB148863454A3b3570f515414759BE9091465',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x841fb148863454a3b3570f515414759be9091465.png',
            'coingeckoId': 'shih-tzu',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'WTT',
            'name': 'Giga Watt',
            'address': '0x84119cb33E8F590D75c2D6Ea4e6B0741a7494EDA',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/907/thumb/wtt.png?1547034739',
            'coingeckoId': 'giga-watt-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UBT',
            'name': 'Unibright',
            'address': '0x8400D94A5cb0fa0D041a3788e395285d61c9ee5e',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x8400d94a5cb0fa0d041a3788e395285d61c9ee5e.png',
            'coingeckoId': 'unibright',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'yUSDTv2',
            'name': 'iearn USDT v2',
            'address': '0x83f798e925BcD4017Eb265844FDDAbb448f1707D',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x83f798e925bcd4017eb265844fddabb448f1707d.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'POLS',
            'name': 'Polkastarter',
            'address': '0x83e6f1E41cdd28eAcEB20Cb649155049Fac3D5Aa',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x83e6f1e41cdd28eaceb20cb649155049fac3d5aa.png',
            'coingeckoId': 'polkastarter',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ZXC',
            'name': '0xcert',
            'address': '0x83e2BE8d114F9661221384B3a50d24B96a5653F5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4552/thumb/0xcert.png?1547039841',
            'coingeckoId': '0xcert',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INFT',
            'name': 'Infinito',
            'address': '0x83d60E7aED59c6829fb251229061a55F35432c4d',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9461/thumb/5TOvk2A.png?1604885818',
            'coingeckoId': 'infinito',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LILFLOKICEO',
            'name': 'LilFlokiCeo',
            'address': '0x83d4cF08BE796A08041a71152b653c828211c866',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19860/thumb/ceo.jpeg?1636076347',
            'coingeckoId': 'lilflokiceo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MNTP',
            'name': 'Goldmint',
            'address': '0x83cee9e086A77e492eE0bB93C2B0437aD6fdECCc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x83cee9e086a77e492ee0bb93c2b0437ad6fdeccc.png',
            'coingeckoId': 'goldmint',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'LINKBULL',
            'name': '3X Long Chainlink Token',
            'address': '0x83aD87C988aC0c6277C0c6234Cc8108b20bB5d9B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10235/thumb/683JEXMN_400x400_%281%29.png?1576632547',
            'coingeckoId': '3x-long-chainlink-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZUT',
            'name': 'Zero Utility',
            'address': '0x83F873388Cd14b83A9f47FabDe3C9850b5C74548',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12706/thumb/logo.png?1605007113',
            'coingeckoId': 'zero-utility-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GUILD',
            'name': 'BlockchainSpace',
            'address': '0x83E9F223e1edb3486F876EE888d76bFba26c475A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21271/small/BednjMw.png?1638857799',
            'coingeckoId': 'blockchainspace',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'GENBLOK',
            'name': 'The Genesis Block',
            'address': '0x83C141Ee88c502cB9654DE93598e9F16555082A8',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27471/thumb/6313c6043c13ed270ede4600_aaaa-p-500.jpg?1664195154',
            'coingeckoId': 'the-genesis-block',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VVS',
            'name': 'VVS Finance',
            'address': '0x839e71613f9aA06E5701CF6de63E303616B0DDE3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20210/thumb/8glAYOTM_400x400.jpg?1636667919',
            'coingeckoId': 'vvs-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REM',
            'name': 'Remme',
            'address': '0x83984d6142934bb535793A82ADB0a46EF0F66B6d',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x83984d6142934bb535793a82adb0a46ef0f66b6d.png',
            'coingeckoId': 'remme',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'EXM',
            'name': 'EXMO Coin',
            'address': '0x83869DE76B9Ad8125e22b857f519F001588c0f62',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9154/thumb/200x200.png?1668998803',
            'coingeckoId': 'exmo-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAKT',
            'name': 'Backed Protocol',
            'address': '0x8379f52d09b9998eCbA731288EE4fE532fD91C0B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16773/thumb/QXP11lB.png?1624945482',
            'coingeckoId': 'backed-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZHEGIC',
            'name': 'zHEGIC',
            'address': '0x837010619aeb2AE24141605aFC8f66577f6fb2e7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/zhegic.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'WOOL',
            'name': 'Wolf Game Wool',
            'address': '0x8355DBE8B0e275ABAd27eB843F3eaF3FC855e525',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20760/thumb/KM3RwIVx_400x400.jpg?1637650093',
            'coingeckoId': 'wolf-game-wool',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PIS',
            'name': 'Polkainsure Finance',
            'address': '0x834cE7aD163ab3Be0C5Fd4e0a81E67aC8f51E00C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13544/thumb/Logo_Polkainsure___Final-200x200-01.png?1609686092',
            'coingeckoId': 'polkainsure-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FAMILY',
            'name': 'The Bitcoin Family',
            'address': '0x833E4c02c47B7e38f5b9A80b26eb07D23d1961f4',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13776/thumb/the_bitcoin_family.png?1611712529',
            'coingeckoId': 'the-bitcoin-family',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DBNB',
            'name': 'DecentraBNB',
            'address': '0x833850BE8858722Cfc5E5e75f2Fe6275E055d888',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26544/small/Screenshot_2022-07-22_at_18.16.11.png?1658735503',
            'coingeckoId': 'decentrabnb',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'GIGA',
            'name': 'GigaSwap',
            'address': '0x83249c6794BCa5a77Eb8c0AF9F1A86E055459cea',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26919/thumb/final_62fcaacdbcc9640975d3fb7c_822256.png?1660815521',
            'coingeckoId': 'gigaswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MMAON',
            'name': 'MMAON',
            'address': '0x8315472Bae77F9a2B856A67Eb0796480AafCd51c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14645/thumb/MMAON.png?1618295603',
            'coingeckoId': 'mmaon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GFARM2',
            'name': 'Gains Farm',
            'address': '0x831091dA075665168E01898c6DAC004A867f1e1B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/8444.png',
            'coingeckoId': 'gains-farm',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'REALT-S-19201-WESTP',
            'name': 'RealT   19201 Westphalia St  Detroit  M',
            'address': '0x830B0e9a5ecf36D0A886D21e1C20043cD2d16515',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16613/thumb/19201-Westphalia-hero-1.jpeg?1624547689',
            'coingeckoId': '19201-westphalia',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HIBAYC',
            'name': 'hiBAYC',
            'address': '0x83031984B45553070a088273f341BfF7fB4f2F46',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26623/thumb/hibayc.png?1659150840',
            'coingeckoId': 'hibayc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DTX',
            'name': 'Digital Ticks',
            'address': '0x82fdedfB7635441aA5A92791D001fA7388da8025',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4898/thumb/digital-ticks-logo.jpg?1533010074',
            'coingeckoId': 'digital-ticks',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KIKI',
            'name': 'Kiki Finance',
            'address': '0x82ca5FCd9eF2D6cEEb49a057bb11c3E091560979',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23151/thumb/DLq9z6qp_400x400.jpg?1643351049',
            'coingeckoId': 'kiki-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOGZ',
            'name': 'Dogz',
            'address': '0x82A77710495A35549D2aDD797412b4a4497D33eF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7544/thumb/dogz.png?1604655282',
            'coingeckoId': 'dogz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANKR',
            'name': 'Ankr',
            'address': '0x8290333ceF9e6D528dD5618Fb97a76f268f3EDD4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x8290333cef9e6d528dd5618fb97a76f268f3edd4.png',
            'coingeckoId': 'ankr',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'SWINGBY',
            'name': 'Swingby',
            'address': '0x8287C7b963b405b7B8D467DB9d79eEC40625b13A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11861/thumb/swingby.png?1595822302',
            'coingeckoId': 'swingby',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JWL',
            'name': 'Jewel',
            'address': '0x8275eBF521Dc217aa79C88132017A5BCEf001dd9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6996/thumb/jewelpay_token.jpg?1547043400',
            'coingeckoId': 'jewel',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BB-A-USDC',
            'name': 'Balancer Boosted Aave USDC',
            'address': '0x82698aeCc9E28e9Bb27608Bd52cF57f704BD1B83',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25780/thumb/bb-a-usdc.png?1653735108',
            'coingeckoId': 'balancer-boosted-aave-usdc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RBX',
            'name': 'RBX',
            'address': '0x8254e26e453EB5aBd29B3c37AC9E8Da32E5d3299',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x8254e26e453eb5abd29b3c37ac9e8da32e5d3299.png',
            'coingeckoId': 'rbx-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ICHIGO',
            'name': 'Ichigo Inu',
            'address': '0x8254C1C134436F74047F79eaAeA97E3324eF78B5',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19243/thumb/ichigoinu.png?1634779236',
            'coingeckoId': 'ichigo-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TIA',
            'name': 'TIA',
            'address': '0x824E35f7A75324f99300aFAC75ECF7354E17Ea26',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23994/thumb/tia.png?1645952362',
            'coingeckoId': 'tia',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'mooJoeUSDT-USDTe',
            'name': 'Moo Joe USDT-USDTe',
            'address': '0x8239A6b877804206C7799028232A7188DA487CeC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ASTO',
            'name': 'Altered State Machine',
            'address': '0x823556202e86763853b40e9cDE725f412e294689',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24893/thumb/ASTO.png?1649303806',
            'coingeckoId': 'altered-state-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ROND',
            'name': 'ROND Coin',
            'address': '0x82197d7446a435105d6F3a430D72a6713960E34D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ATIS',
            'name': 'Atlantis ATIS',
            'address': '0x821144518dfE9e7b44fCF4d0824e15e8390d4637',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x821144518dfe9e7b44fcf4d0824e15e8390d4637.png',
            'coingeckoId': 'atlantis-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'OGN',
            'name': 'Origin Protocol',
            'address': '0x8207c1FfC5B6804F6024322CcF34F29c3541Ae26',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x8207c1ffc5b6804f6024322ccf34f29c3541ae26.png',
            'coingeckoId': 'origin-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'VITA',
            'name': 'VitaDAO',
            'address': '0x81f8f0bb1cB2A06649E51913A151F0E7Ef6FA321',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16580/thumb/QmRjwywiAfpzSfQTuWM3zCTghSHN7G6ohQaar7Ht6WANUp.png?1624506420',
            'coingeckoId': 'vitadao',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'PAXGBULL',
            'name': '3X Long PAX Gold Token',
            'address': '0x81f09eD4b98B1c8e99b1Fa838B72acB842AFE94c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10435/thumb/683JEXMN_400x400.png?1579276663',
            'coingeckoId': '3x-long-pax-gold-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PUP',
            'name': 'Pupper',
            'address': '0x81dBc1c8e40C3095071949Eda9800C2209a7279A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16038/thumb/puppy.PNG?1622680387',
            'coingeckoId': 'pupper',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MARVIN',
            'name': 'Elon s Marvin',
            'address': '0x81C159f7aBAa9139227Aff62959b86B4141F6EB2',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20886/thumb/marvin.png?1637832213',
            'coingeckoId': 'elons-marvin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RARE',
            'name': 'Rare',
            'address': '0x81B1bFD6CB9Ad42DB395c2a27F73D4DCf5777e2D',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12868/thumb/rare_logo.png?1603170092',
            'coingeckoId': 'rare',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SCS',
            'name': 'Speedcash',
            'address': '0x81995ff7AEe5c780192b47e0B42a7a86692d1415',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2929/thumb/scs_logo_200x200.png?1606439865',
            'coingeckoId': 'speedcash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALN',
            'name': 'Aluna',
            'address': '0x8185Bc4757572Da2a610f887561c32298f1A5748',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/5544.png',
            'coingeckoId': 'aluna',
            'listedIn': [
                'coingecko',
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'BVOL',
            'name': 'Bitcoin Volatility Token',
            'address': '0x81824663353A9d29b01B2DE9dd9a2Bb271d298cD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11056/thumb/download_%2810%29.png?1587641855',
            'coingeckoId': '1x-long-btc-implied-volatility-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CUDOS',
            'name': 'Cudos',
            'address': '0x817bbDbC3e8A1204f3691d14bB44992841e3dB35',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x817bbdbc3e8a1204f3691d14bb44992841e3db35.png',
            'coingeckoId': 'cudos',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'NOKU',
            'name': 'Noku',
            'address': '0x8167D3B1024cB51A2DD1B4d889ddf7023420796a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1577/thumb/Noku_Logo_round_coingeko.png?1643171688',
            'coingeckoId': 'noku',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MDT',
            'name': 'Measurable Data',
            'address': '0x814e0908b12A99FeCf5BC101bB5d0b8B5cDf7d26',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x814e0908b12a99fecf5bc101bb5d0b8b5cdf7d26.png',
            'coingeckoId': 'measurable-data-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'DATP',
            'name': 'Decentralized Asset Trading Platform',
            'address': '0x813b428aF3920226E059B68A62e4c04933D4eA7a',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6565/thumb/Decentralized_Asset_Trading_Platform.jpg?1547042776',
            'coingeckoId': 'decentralized-asset-trading-platform',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LEND',
            'name': 'Aave  OLD ',
            'address': '0x80fB784B7eD66730e8b1DBd9820aFD29931aab03',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x80fb784b7ed66730e8b1dbd9820afd29931aab03.png',
            'coingeckoId': 'ethlend',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'MILK2',
            'name': 'Spaceswap MILK2',
            'address': '0x80c8C3dCfB854f9542567c8Dac3f44D709eBc1de',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x80c8c3dcfb854f9542567c8dac3f44d709ebc1de.png',
            'coingeckoId': 'spaceswap-milk2',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'DOGDEFI',
            'name': 'DogDeFiCoin',
            'address': '0x80bb277f4355A43CDbB86a82F9876C946476d9Eb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12912/thumb/dogdefi_logo.jpg?1603425386',
            'coingeckoId': 'dogdeficoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CYFI',
            'name': 'cYFI',
            'address': '0x80a2AE356fc9ef4305676f7a3E2Ed04e12C33946',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17530/thumb/cyfi.PNG?1628123155',
            'coingeckoId': 'compound-yearn-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OPUL',
            'name': 'Opulous',
            'address': '0x80D55c03180349Fff4a229102F62328220A96444',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16548/thumb/opulous.PNG?1624418744',
            'coingeckoId': 'opulous',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LKR',
            'name': 'Lokr',
            'address': '0x80CE3027a70e0A928d9268994e9B85d03Bd4CDcf',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14692/thumb/lokr.png?1648886932',
            'coingeckoId': 'lokr',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SLEEPEE',
            'name': 'SleepFuture',
            'address': '0x80CD73baDb406eA36B9a7CDeb8df06AeFa7E12d9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25469/thumb/sleepee.png?1651916574',
            'coingeckoId': 'sleepfuture',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CLV',
            'name': 'Clover Finance',
            'address': '0x80C62FE4487E1351b47Ba49809EBD60ED085bf52',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15278/thumb/clover.png?1645084454',
            'coingeckoId': 'clover-finance',
            'listedIn': [
                'coingecko',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'MTD',
            'name': 'Minted',
            'address': '0x809E130E10e787139c54e1d12d3D1971b7A675Bf',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26893/thumb/MTD_Token_%282%29.png?1660708482',
            'coingeckoId': 'minted',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NCASH',
            'name': 'Nucleus Vision',
            'address': '0x809826cceAb68c387726af962713b64Cb5Cb3CCA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3135/thumb/nv.jpg?1547037544',
            'coingeckoId': 'nucleus-vision',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PENDLE',
            'name': 'Pendle',
            'address': '0x808507121B80c02388fAd14726482e061B8da827',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x808507121b80c02388fad14726482e061b8da827.png',
            'coingeckoId': 'pendle',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-9169-BOLEYN',
            'name': 'RealT   9169 Boleyn St  Detroit  MI  48',
            'address': '0x806690B7a093d2Cf6419a515ABEdb7f28595bc5E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16691/thumb/Boleyn-hero.jpeg?1624637832',
            'coingeckoId': '9169-boleyn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OBTC',
            'name': 'BoringDAO BTC',
            'address': '0x8064d9Ae6cDf087b1bcd5BDf3531bD5d8C537a68',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/obtc.jpg',
            'coingeckoId': 'boringdao-btc',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'TSCT',
            'name': 'Transient',
            'address': '0x805EA9c07B49dd23cE11ec66dC6d8a2957385035',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19061/thumb/200x200_%2833%29.png?1634266319',
            'coingeckoId': 'transient',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ARTEQ',
            'name': 'art Q NFT Investment Fund',
            'address': '0x805C2077f3ab224D889f9c3992B41B2F4722c787',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23407/thumb/arteq.png?1644146442',
            'coingeckoId': 'arteq-nft-investment-fund',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BES',
            'name': 'battle esports coin',
            'address': '0x805856f176625843d14097017618E224F4607D2D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22866/thumb/9R4ImfBo_400x400.jpg?1642755223',
            'coingeckoId': 'battle-esports-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATD',
            'name': 'A2DAO',
            'address': '0x8052327F1BAF94A9DC8B26b9100f211eE3774f54',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14509/thumb/8926.png?1645686353',
            'coingeckoId': 'catapult',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WHALE',
            'name': 'Whale Coin',
            'address': '0x804E26C4EFf0BB196b805bDFb5B29AB828cF0B1f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10743/thumb/whalecoin200.png?1582835568',
            'coingeckoId': 'whale-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'P4C',
            'name': 'Parts of Four Coin',
            'address': '0x8037b1B69d6fA63a9cc053c25f7e168e6e6d857A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x8037b1b69d6fa63a9cc053c25f7e168e6e6d857a.png',
            'coingeckoId': 'parts-of-four-coin',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'RELAX',
            'name': 'Relaxable',
            'address': '0x801dE502C493aa9d3F9F79A0348fbc00521b3001',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28129/thumb/6B1F96A9-E306-4328-862A-FBE9209D1C0D.jpeg?1667649137',
            'coingeckoId': 'relaxable',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOOP',
            'name': 'Boop',
            'address': '0x8013266cb5c9dd48bE3Ad7D1CE832874d64B3Ce1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27918/thumb/boop.png?1666351418',
            'coingeckoId': 'boop',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KNDX',
            'name': 'Kondux  OLD ',
            'address': '0x7ff7A55A7c637E3953Ab25569C335e04b96C475b',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16987/thumb/bzYx6E41zeLl2gopOj9UqNmIdFgrGU2zSA.jpeg?1646104675',
            'coingeckoId': 'kondux',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POP',
            'name': 'POP',
            'address': '0x7fC3eC3574d408F3b59CD88709baCb42575EBF2b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14598/thumb/pop.png?1617195542',
            'coingeckoId': 'pop',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'WSTETH',
            'name': 'Wrapped stETH',
            'address': '0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0.png',
            'coingeckoId': 'wrapped-steth',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'X',
            'name': 'X',
            'address': '0x7f3141c4D6b047fb930991b450f1eD996a51CB26',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22334/small/x.PNG?1641519663',
            'coingeckoId': 'x-2',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'xyfinance'
            ]
        },
        {
            'symbol': 'FNC',
            'name': 'Fancy Games',
            'address': '0x7f280daC515121DcdA3EaC69eB4C13a52392CACE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x7f280dac515121dcda3eac69eb4c13a52392cace.png',
            'coingeckoId': 'fancy-games',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'TAP',
            'name': 'TapX',
            'address': '0x7f1F2D3dFa99678675ECE1C243d3f7bC3746db5D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x7f1f2d3dfa99678675ece1c243d3f7bc3746db5d.png',
            'coingeckoId': 'tapmydata',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'NEBO',
            'name': 'CSP DAO Network',
            'address': '0x7f0c8B125040f707441cad9e5eD8a8408673b455',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13436/thumb/csp_dao.png?1608535699',
            'coingeckoId': 'csp-dao-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ROYA',
            'name': 'Royale',
            'address': '0x7eaF9C89037e4814DC0d9952Ac7F888C784548DB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x7eaf9c89037e4814dc0d9952ac7f888c784548db.png',
            'coingeckoId': 'royale',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'EOSDAC',
            'name': 'eosDAC',
            'address': '0x7e9e431a0B8c4D532C745B1043c7FA29a48D4fBa',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3878/thumb/2644.png?1547038931',
            'coingeckoId': 'eosdac',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MBTC',
            'name': 'MiniBitcoin',
            'address': '0x7e8C149f70437eba6785f9059190A5b08aBf03dE',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7180/thumb/logo_brown.png?1547043731',
            'coingeckoId': 'minibitcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BDIGG',
            'name': 'Badger Sett Digg',
            'address': '0x7e7E112A68d8D2E221E11047a72fFC1065c38e1a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/bdigg.jpg',
            'coingeckoId': 'badger-sett-digg',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'SHINTAMA',
            'name': 'Shintama',
            'address': '0x7e794eD35788b698AE60cefC98eE48015C4876dA',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23405/thumb/shintama.png?1644145782',
            'coingeckoId': 'shintama',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NIF',
            'name': 'Unifty',
            'address': '0x7e291890B01E5181f7ecC98D79ffBe12Ad23df9e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13726/thumb/logo_transparent.png?1628254980',
            'coingeckoId': 'unifty',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MATICBULL',
            'name': '3X Long Matic Token',
            'address': '0x7e03521b9dA891Ca3F79A8728E2eaeb24886c5f9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10167/thumb/683JEXMN_400x400_%281%29.png?1576505037',
            'coingeckoId': '3x-long-matic-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KDC',
            'name': 'DroneFly',
            'address': '0x7e00b63483C92975A3be0a71432277935326a618',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17243/thumb/dronefly_symbol_200x200.png?1626936898',
            'coingeckoId': 'dronefly',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AMKR',
            'name': 'Aave MKR v1',
            'address': '0x7deB5e830be29F91E298ba5FF1356BB7f8146998',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x7deb5e830be29f91e298ba5ff1356bb7f8146998.png',
            'coingeckoId': 'aave-mkr-v1',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'VOLT',
            'name': 'Volt Inu',
            'address': '0x7db5af2B9624e1b3B4Bb69D6DeBd9aD1016A58Ac',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x7db5af2b9624e1b3b4bb69d6debd9ad1016a58ac.png',
            'coingeckoId': 'volt-inu-2',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'XRT',
            'name': 'Robonomics Network',
            'address': '0x7dE91B204C1C737bcEe6F000AAA6569Cf7061cb7',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x7de91b204c1c737bcee6f000aaa6569cf7061cb7.png',
            'coingeckoId': 'robonomics-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ARX',
            'name': 'ARCS',
            'address': '0x7d8DafF6d70CEAd12c6f077048552Cf89130A2B1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10068/thumb/arcs.png?1575426741',
            'coingeckoId': 'arcs',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VR',
            'name': 'Victoria VR',
            'address': '0x7d5121505149065b562C789A0145eD750e6E8cdD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21178/thumb/vr.png?1638496975',
            'coingeckoId': 'victoria-vr',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CAG',
            'name': 'Change',
            'address': '0x7d4b8Cce0591C9044a22ee543533b72E976E36C3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1021/thumb/200x200.png?1610766224',
            'coingeckoId': 'change',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CBSN',
            'name': 'BlockSwap Network  OLD ',
            'address': '0x7d4B1d793239707445305D8d2456D2c735F6B25B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14781/thumb/bsn_dp_high.png?1618865566',
            'coingeckoId': 'blockswap-network',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'PBL',
            'name': 'Pebble',
            'address': '0x7d226bff737C5855254C307523afB3c464C5680b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'I9C',
            'name': 'i9 Coin',
            'address': '0x7cf74238D48Ce383db00a1EFff8da99303552cb0',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12399/thumb/Vvm9V6YM_400x400.png?1599556271',
            'coingeckoId': 'i9-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SVS',
            'name': 'GivingToServices SVS',
            'address': '0x7ca62545a380e7d71F8F5CFa14B9211002075930',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16436/thumb/9UbufKC.png?1655180835',
            'coingeckoId': 'givingtoservices-svs',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALPA',
            'name': 'Alpaca City',
            'address': '0x7cA4408137eb639570F8E647d9bD7B7E8717514A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x7ca4408137eb639570f8e647d9bd7b7e8717514a.png',
            'coingeckoId': 'alpaca',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'NII',
            'name': 'Nahmii',
            'address': '0x7c8155909cd385F120A56eF90728dD50F9CcbE52',
            'decimals': 15,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x7c8155909cd385f120a56ef90728dd50f9ccbe52.png',
            'coingeckoId': 'nahmii',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'PTM',
            'name': 'Potentiam',
            'address': '0x7c32DB0645A259FaE61353c1f891151A2e7f8c1e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2349/thumb/ptm.png?1547036546',
            'coingeckoId': 'potentiam',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LDXG',
            'name': 'LondonCoinGold',
            'address': '0x7c0853Ab074f2f17E37c5482DCF6C5F6c1246E8b',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24419/thumb/E9eOuH6W_400x400.jpg?1647611828',
            'coingeckoId': 'londoncoingold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIBU',
            'name': 'Shiba Universe',
            'address': '0x7bFDE33d790411A88D46E9e1be32Fc86228891a4',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25423/thumb/logocmc.png?1651737047',
            'coingeckoId': 'shiba-universe',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ACXT',
            'name': 'ACDX Exchange',
            'address': '0x7bE00ed6796B21656732E8f739Fc1b8F1C53DA0D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13293/thumb/ACDX_Original_05.png?1607064933',
            'coingeckoId': 'ac-exchange-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BXTB',
            'name': 'BXTB Foundation',
            'address': '0x7bA9caa5D19002618F1D93e691490377361D5E60',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24663/thumb/bxtb.png?1648532386',
            'coingeckoId': 'bxtb-foundation',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WIX',
            'name': 'Wixlar',
            'address': '0x7bA19B7F7d106A9a1e0985397B94F38EEe0b555e',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6306/thumb/JtT8UEU.png?1547042361',
            'coingeckoId': 'wixlar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOOT',
            'name': 'LootBox io',
            'address': '0x7b3D36Eb606f873A75A6aB68f8c999848B04F935',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x7b3d36eb606f873a75a6ab68f8c999848b04f935.png',
            'coingeckoId': 'nftlootbox',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'LEAG',
            'name': 'LeagueDAO Governance',
            'address': '0x7b39917f9562C8Bc83c7a6c2950FF571375D505D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x7b39917f9562C8Bc83c7a6c2950FF571375D505D/logo.png',
            'coingeckoId': 'leaguedao-governance-token',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'IMX',
            'name': 'Impermax  OLD ',
            'address': '0x7b35Ce522CB72e4077BaeB96Cb923A5529764a00',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/9532.png',
            'coingeckoId': 'impermax',
            'listedIn': [
                'coingecko',
                'arbitrum_bridge',
                'rubic'
            ]
        },
        {
            'symbol': 'ACTP',
            'name': 'Archetypal Network',
            'address': '0x7b2dF125567815ac9b57DA04B620F50bc93B320C',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5946/thumb/logo_%284%29.png?1547041885',
            'coingeckoId': 'archetypal-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUIDL',
            'name': 'dfohub',
            'address': '0x7b123f53421b1bF8533339BFBdc7C98aA94163db',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x7b123f53421b1bf8533339bfbdc7c98aa94163db.png',
            'coingeckoId': 'dfohub',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'GMM',
            'name': 'Gold Mining Members',
            'address': '0x7aF89c8A06719271A96e62E290Ea9Ed192E73FC1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11660/thumb/gmm_logo_token.png?1592432359',
            'coingeckoId': 'gold-mining-members',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'Archa',
            'name': 'Archangel',
            'address': '0x7aE7ACC3B3A9679EAe7e4F7495eAd1429ac7dE7E',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://api.rubic.exchange/assets/ethereum/0x7ae7acc3b3a9679eae7e4f7495ead1429ac7de7e/logo.png',
            'coingeckoId': 'archangel-token',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PAPER',
            'name': 'Dope Wars Paper',
            'address': '0x7aE1D57b58fA6411F32948314BadD83583eE0e8C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethereum-optimism.github.io/data/PAPER/logo.svg',
            'coingeckoId': 'dope-wars-paper',
            'listedIn': [
                'coingecko',
                'optimism',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'VERSE',
            'name': 'Shibaverse',
            'address': '0x7aE0d42f23C33338dE15bFa89C7405c068d9dC0a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18407/thumb/logo_200.png?1631795328',
            'coingeckoId': 'shibaverse',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'EXPO',
            'name': 'Expo',
            'address': '0x7aCB51E690301b114a2A65B2E557cC1B7e644ba8',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6548/thumb/t4GE48eH_400x400.png?1547042762',
            'coingeckoId': 'online-expo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CMCT',
            'name': 'Cyber Movie Chain',
            'address': '0x7aBc60B3290F68c85f495fD2e0c3Bd278837a313',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6497/thumb/cyber-movie-chain.png?1547791095',
            'coingeckoId': 'cyber-movie-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FBT',
            'name': 'FANBI TOKEN',
            'address': '0x7a9716685F852ee268Feb86Dffa562D214cC13dB',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9828/thumb/logo1_%281%29.png?1572472211',
            'coingeckoId': 'fanbi-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HIOD',
            'name': 'hiOD',
            'address': '0x7a83FF237e7870d8d6C3485920EBe654D2841315',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28238/thumb/hiod.png?1668579474',
            'coingeckoId': 'hiod',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RADIO',
            'name': 'RadioShack',
            'address': '0x7a5d3A9Dcd33cb8D527f7b5F96EB4Fef43d55636',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://elk.finance/tokens/logos/ethereum/0x7a5d3A9Dcd33cb8D527f7b5F96EB4Fef43d55636/logo.png',
            'coingeckoId': 'radioshack',
            'listedIn': [
                'coingecko',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BALPHA',
            'name': 'bAlpha',
            'address': '0x7a5ce6abD131EA6B148a022CB76fc180ae3315A6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x7a5ce6abd131ea6b148a022cb76fc180ae3315a6.png',
            'coingeckoId': 'balpha',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'WAXE',
            'name': 'WAXE',
            'address': '0x7a2Bc711E19ba6aff6cE8246C546E8c4B4944DFD',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x7a2bc711e19ba6aff6ce8246c546e8c4b4944dfd.png',
            'coingeckoId': 'waxe',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'LQD',
            'name': 'LiquidSwap',
            'address': '0x7Fe8dAC51394157811C71bBf74c133a224a9FF44',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23323/thumb/Cq7F8wZr_400x400.jpg?1643836712',
            'coingeckoId': 'liquidswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AAVE',
            'name': 'Aave',
            'address': '0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9.png',
            'coingeckoId': 'aave',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'optimism',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'mooMaiMAI-av3CRV',
            'name': 'Moo Mai MAI-av3CRV',
            'address': '0x7FaAbDA3De9be051f97bEABA3d539666933b2fA8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DDOS',
            'name': 'disBalancer',
            'address': '0x7FBEC0bb6A7152e77C30D005B5D49cbC08A602C3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14791/thumb/communityIcon_o2yriheuszk61.png?1618463138',
            'coingeckoId': 'disbalancer',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GGC',
            'name': 'Global Game Coin',
            'address': '0x7F969C4D388Ca0AE39A4FdDB1A6f89878CA2fBf8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10301/thumb/ggcoin_200.png?1577690908',
            'coingeckoId': 'gg-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XTK',
            'name': 'xToken',
            'address': '0x7F3EDcdD180Dbe4819Bd98FeE8929b5cEdB3AdEB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x7f3edcdd180dbe4819bd98fee8929b5cedb3adeb.png',
            'coingeckoId': 'xtoken',
            'listedIn': [
                'coingecko',
                '1inch',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'GM',
            'name': 'we love gm',
            'address': '0x7F0693074F8064cFbCf9fA6E5A3Fa0e4F58CcCCF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/logos/main/network/ethereum/0x7F0693074F8064cFbCf9fA6E5A3Fa0e4F58CcCCF.jpg',
            'coingeckoId': 'we-love-gm',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'DOI',
            'name': 'DOI',
            'address': '0x7Ef5ac1FE8B6832EbAddEE1856E4A21E46843565',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x7ef5ac1fe8b6832ebaddee1856e4a21e46843565.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ECOREAL',
            'name': 'Ecoreal Estate',
            'address': '0x7ECbb21346C501Fd07eb165E406120fA32381C16',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6431/thumb/golden_leave_in_200_x_200.png?1655886676',
            'coingeckoId': 'ecoreal-estate',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASTRA',
            'name': 'Astra DAO',
            'address': '0x7E9c15C43f0D6C4a12E6bdfF7c7D55D0f80e3E23',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27255/thumb/astra200x200px_blue_%282%29.png?1663035884',
            'coingeckoId': 'astra-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FF',
            'name': 'Forefront',
            'address': '0x7E9D8f07A64e363e97A648904a89fb4cd5fB94CD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14659/thumb/N2kir6jx_400x400.jpg?1617608020',
            'coingeckoId': 'forefront',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-15039-WARD-',
            'name': 'RealT   15039 Ward Ave  Detroit  MI 482',
            'address': '0x7E95b310724334FF74537dc08bfD3377d25E65Ce',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16632/thumb/15039-Ward-hero-1.jpeg?1624554456',
            'coingeckoId': '15039-ward',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UDOKI',
            'name': 'Unicly Doki Doki Collection',
            'address': '0x7E6C38D007740931E4B419bF15A68c79a0fb0c66',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14900/thumb/uDOKI.jpg?1618935840',
            'coingeckoId': 'unicly-doki-doki-collection',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GMT',
            'name': 'GMT',
            'address': '0x7Ddc52c4De30e94Be3A6A0A2b259b2850f421989',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15662/thumb/gmt.png?1640069038',
            'coingeckoId': 'gmt-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WOLFY',
            'name': 'WOLFY',
            'address': '0x7DbbCaE15d4DB168e01673400D7844870cc1e36f',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15742/thumb/Wofly.png?1621761884',
            'coingeckoId': 'wolfy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANW',
            'name': 'Anchor Neural World',
            'address': '0x7DbDD9DaFdC4c1c03D67925a4f85daA398aF32B0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11104/thumb/logo_scan.png?1588567155',
            'coingeckoId': 'anchor-neural-world-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GLM',
            'name': 'Golem',
            'address': '0x7DD9c5Cba05E151C895FDe1CF355C9A1D5DA6429',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x7dd9c5cba05e151c895fde1cf355c9a1d5da6429.png',
            'coingeckoId': 'golem',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'MARS',
            'name': 'Mars Protocol',
            'address': '0x7DD1a71898dD122B4613A12D897f7B64c4CCAC64',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27469/thumb/MARSlogo-200x200.png?1664186518',
            'coingeckoId': 'mars-protocol-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UCG',
            'name': 'Universe Crystal Gene',
            'address': '0x7D92a06808B4c4833623F809218ed403e4A85FE1',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24619/thumb/19073.png?1648418625',
            'coingeckoId': 'universe-crystal-gene',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLOCKIFY',
            'name': 'Blockify Games',
            'address': '0x7D608CfAa805a752788847e5C46Ce9C03Fb43C21',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25693/thumb/cmc-logo.png?1653382345',
            'coingeckoId': 'blockify-games',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOOR',
            'name': 'DOOR',
            'address': '0x7D48FBe0A877bB1f511fcf9B57F12420C75841e9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16885/thumb/door256.png?1625548384',
            'coingeckoId': 'door',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'aLENDv1',
            'name': 'Aave Interest bearing LEND',
            'address': '0x7D2D3688Df45Ce7C552E19c27e007673da9204B8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x7d2d3688df45ce7c552e19c27e007673da9204b8.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'VXV',
            'name': 'Vectorspace AI',
            'address': '0x7D29A64504629172a429e64183D6673b9dAcbFCe',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2063/thumb/vectorspace-ai-logo.jpeg?1547036362',
            'coingeckoId': 'vectorspace',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MATIC',
            'name': 'Polygon',
            'address': '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0.png',
            'coingeckoId': 'matic-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'IBP',
            'name': 'Innovation Blockchain Payment',
            'address': '0x7D14b842630cbc2530cB288109E5719e0C4d67d7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12903/thumb/ibp.?1603347704',
            'coingeckoId': 'innovation-blockchain-payment',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OKINU',
            'name': 'Arcade Canine',
            'address': '0x7D1356BfcAA08AF4Ab0D643AF1B6b4cd75Ccc2d0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27748/thumb/SwJ8JwH.png?1665645891',
            'coingeckoId': 'arcade-canine',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CPTINU',
            'name': 'Captain Inu',
            'address': '0x7Cca2e1c9B0519f52029467914A15E782BF66971',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21600/thumb/200x200-transparent-logo.png?1639548692',
            'coingeckoId': 'captain-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WOLVERINU',
            'name': 'Wolverinu',
            'address': '0x7Cc97Bf17C5AdaBe25F9D19D15A1eC8A1ad65f14',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27872/thumb/LOGO3232.jpg?1666166703',
            'coingeckoId': 'wolverinu-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAYP',
            'name': 'Maya Preferred',
            'address': '0x7CDA79830Faf07Ed696Fe220566116951CED36A7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8081/thumb/2u0OXhT9_400x400.png?1554426529',
            'coingeckoId': 'maya-preferred-223',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STARS',
            'name': 'StarShip Stars',
            'address': '0x7CCFeEF4F0Ff48B0E0abD19bBBebae90939f180D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17533/thumb/DhtU6Z.jpg?1628130727',
            'coingeckoId': 'starship-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KNT',
            'name': 'Knekted',
            'address': '0x7CC62d8E80Be9bEa3947F3443aD136f50f75b505',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6488/thumb/42318068_480165755801061_8741315709394485248_n.jpg?1565651343',
            'coingeckoId': 'knekted',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SLVM',
            'name': 'Silver Mint',
            'address': '0x7CBCFd88C952a1f5EAa954945EBC7ED8f1015ec0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'KNDX',
            'name': 'Kondux',
            'address': '0x7CA5af5bA3472AF6049F63c1AbC324475D44EFC1',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28244/thumb/kndx.png?1668587937',
            'coingeckoId': 'kondux-v2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHPING',
            'name': 'Shping',
            'address': '0x7C84e62859D0715eb77d1b1C4154Ecd6aBB21BEC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2588/thumb/r_yabKKi_400x400.jpg?1639470164',
            'coingeckoId': 'shping',
            'listedIn': [
                'coingecko',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'COMB',
            'name': 'Combo',
            'address': '0x7C81542ED859A2061538FEE22B6544a235B9557D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13053/thumb/7zYe50X.png?1604703767',
            'coingeckoId': 'combo-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOON',
            'name': 'Loon Network',
            'address': '0x7C5d5100B339Fe7D995a893AF6CB496B9474373c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11673/thumb/ErUvUA2D4Q8hPWXxZBIuiI8MD0nrU2rDEY56d6EtVzJDTqlflAFXa2bgJ_kFwb8OtotfAvESvm-dik7d_SeJJh23f6RosUJ0AzU64QcKk5H3fu300KOJjFDFsntpRWARFKI5S4Cc8F3pcRPBkFIw6oPF58mKi83sa2DJPl-E5JMqmj6rJh5acb7Fvi80kfziWv3DHhhpg1YJYMP.jpg?1592536236',
            'coingeckoId': 'loon-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAN',
            'name': 'Santiment Network',
            'address': '0x7C5A0CE9267ED19B22F8cae653F198e3E8daf098',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x7c5a0ce9267ed19b22f8cae653f198e3e8daf098.png',
            'coingeckoId': 'santiment-network-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'HAVY',
            'name': 'Havy',
            'address': '0x7C2E5b7ec572199D3841f6a38F7D4868BD0798f1',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6043/thumb/havy.png?1547041989',
            'coingeckoId': 'havy-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ICETH',
            'name': 'Interest Compounding ETH Index',
            'address': '0x7C07F7aBe10CE8e33DC6C5aD68FE033085256A84',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x7c07f7abe10ce8e33dc6c5ad68fe033085256a84.png',
            'coingeckoId': 'interest-compounding-eth-index',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ZIG',
            'name': 'ZigCoin',
            'address': '0x7BeBd226154E865954A87650FAefA8F485d36081',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/zig.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'SHOPX',
            'name': 'SHOPX',
            'address': '0x7BEF710a5759d197EC0Bf621c3Df802C2D60D848',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x7bef710a5759d197ec0bf621c3df802c2d60d848.png',
            'coingeckoId': 'splyt',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'LM',
            'name': 'LeisureMeta',
            'address': '0x7BEC98609cB6378D6F995e8f8097Ee78376fbec9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25761/thumb/SVG_16533804486374586M.jpg?1653548604',
            'coingeckoId': 'leisuremeta',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOZ',
            'name': 'Mozik',
            'address': '0x7BD82B320EbC28D8EB3C4F5Fa2af7B14dA5b90C3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1634700095378_888300101439486.png',
            'coingeckoId': 'mozik',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'oneVBTC',
            'name': 'oneVBTC',
            'address': '0x7BD198b9107496fD5cC3d7655AF52f43a8eDBc4C',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/onevbtc.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'KPOP',
            'name': 'KPOP Coin',
            'address': '0x7B7983967409Fce461EA8bbDF9ED37631b1d59C9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15923/thumb/kpop.PNG?1622431964',
            'coingeckoId': 'kpop-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PFI',
            'name': 'Protocol Finance',
            'address': '0x7B69D465c0F9FB22AffAE56aa86149973e9B0966',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13810/thumb/17RgoN2.png?1612047312',
            'coingeckoId': 'protocol-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DC',
            'name': 'Dogechain',
            'address': '0x7B4328c127B85369D9f82ca0503B000D09CF9180',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26828/thumb/dogechain.jpeg?1660292741',
            'coingeckoId': 'dogechain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RBIF',
            'name': 'Robo Inu Finance',
            'address': '0x7B32e70e8D73ac87c1B342e063528B2930b15ceB',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20821/thumb/O68Gs5SE_400x400.jpg?1654929220',
            'coingeckoId': 'robo-inu-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MRPH',
            'name': 'Morpheus Network',
            'address': '0x7B0C06043468469967DBA22d1AF33d77d44056c8',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x7b0c06043468469967dba22d1af33d77d44056c8.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'GNBT',
            'name': 'Genebank',
            'address': '0x7Aa46A51F717404D944051AF3075bBcb49B2288B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15963/thumb/Gene-Bank-500px.jpg?1622519558',
            'coingeckoId': 'genebank-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOGEBULL',
            'name': '3X Long Dogecoin Token',
            'address': '0x7AA6b33fB7F395DDBca7b7A33264A3c799Fa626f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10283/thumb/683JEXMN_400x400.png?1577007477',
            'coingeckoId': '3x-long-dogecoin-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EVZ',
            'name': 'Electric Vehicle Zone',
            'address': '0x7A939Bb714fd2A48EbeB1E495AA9aaa74BA9fA68',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9456/thumb/kLohzILUIln6mHFYOlecpWjINVIH-BVghP2vRTeuD0XteaQa7Lpn4sLcuPN4gHw8MU2pKWZCJRNwBmyyl1CYxplCLDcgSVihMC7vvfmkepY-_O_ImWBA27s4pKNlhcBnBYrc8y5WH0ZB2CjmqPh-32nPslrv329tqFWr2DAR8dl4R5LZGgeZ1ubCdtMoUua6gEL3umYShHBxrYLto.jpg?1567563510',
            'coingeckoId': 'electric-vehicle-zone',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BIZZ',
            'name': 'BIZZCOIN',
            'address': '0x7A8Ca2f815A260660158a38C34ca321A3605eCFE',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10979/thumb/bizzcoin.PNG?1586766076',
            'coingeckoId': 'bizzcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSY',
            'name': 'Bestay',
            'address': '0x7A647637E75d34A7798Ba06BD1aDF5c084bb5dd5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12166/thumb/photo-2019-07-04-17-03-29.jpg?1597787199',
            'coingeckoId': 'bestay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PEOPLE',
            'name': 'ConstitutionDAO',
            'address': '0x7A58c0Be72BE218B41C608b7Fe7C5bB630736C71',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20612/small/GN_UVm3d_400x400.jpg?1637294355',
            'coingeckoId': 'constitutiondao',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'ZSC',
            'name': 'Zeusshield',
            'address': '0x7A41e0517a5ecA4FdbC7FbebA4D4c47B9fF6DC63',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1009/thumb/zeusshield.png?1548761440',
            'coingeckoId': 'zeusshield',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PANDO',
            'name': 'Pando',
            'address': '0x7A3C45F34eA5DF6fE5F6aF710eC8A04D388a71D1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14150/thumb/pando_logo.png?1614667045',
            'coingeckoId': 'pando',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XNS',
            'name': 'Xeonbit XNS',
            'address': '0x79c71D3436F39Ce382D0f58F1B011D88100B9D91',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8744/thumb/200x200_icon_darkbg.png?1560826732',
            'coingeckoId': 'xeonbit-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AAMMDAI',
            'name': 'Aave AMM DAI',
            'address': '0x79bE75FFC64DD58e66787E4Eae470c8a1FD08ba4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17197/thumb/aAMMDAI_2x.png?1626940032',
            'coingeckoId': 'aave-amm-dai',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARV',
            'name': 'Ariva',
            'address': '0x79C7EF95aD32DcD5ECadB231568Bb03dF7824815',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1665457004993_019767397414035992.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'EDI',
            'name': 'Freight Trust Network',
            'address': '0x79C5a1Ae586322A07BfB60be36E1b31CE8C84A1e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11074/thumb/e6YLf6kD_400x400.jpg?1587970897',
            'coingeckoId': 'freight-trust-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LSVR',
            'name': 'Lasrever',
            'address': '0x79A06aCb8bdd138BEEECcE0f1605971f3AC7c09B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26681/thumb/E312D296-5EB2-4CF1-B98C-A2E47736BA37.jpeg?1659538133',
            'coingeckoId': 'lasrever',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTRST',
            'name': 'Braintrust',
            'address': '0x799ebfABE77a6E34311eeEe9825190B9ECe32824',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x799ebfabe77a6e34311eeee9825190b9ece32824.png',
            'coingeckoId': 'braintrust',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'HIT',
            'name': 'HitChain',
            'address': '0x7995ab36bB307Afa6A683C24a25d90Dc1Ea83566',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5555/thumb/hitchain.png?1547041361',
            'coingeckoId': 'hitchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DIGG',
            'name': 'DIGG',
            'address': '0x798D1bE841a82a273720CE31c822C61a67a601C3',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x798d1be841a82a273720ce31c822c61a67a601c3.png',
            'coingeckoId': 'digg',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'GPOOL',
            'name': 'Genesis Pool',
            'address': '0x797de1Dc0b9fAf5E25c1f7Efe8dF9599138fA09d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18122/thumb/GP_BRAND_GREEN_Main_Icon_.png?1630562175',
            'coingeckoId': 'genesis-pool',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELD',
            'name': 'Electrum Dark',
            'address': '0x796E47B85A0d759F300f1de96A3583004235D4D8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6469/thumb/logo_%2817%29.png?1547042683',
            'coingeckoId': 'electrum-dark',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HGET',
            'name': 'Hedget',
            'address': '0x7968bc6a03017eA2de509AAA816F163Db0f35148',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x7968bc6a03017ea2de509aaa816f163db0f35148.png',
            'coingeckoId': 'hedget',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'AIT',
            'name': 'AICHAIN',
            'address': '0x79650799e7899A802cB96C0Bc33a6a8d4CE4936C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2525/thumb/aichain.png?1547036638',
            'coingeckoId': 'aichain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YIN',
            'name': 'YIN Finance',
            'address': '0x794Baab6b878467F93EF17e2f2851ce04E3E34C8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18272/thumb/e37387ae6ee756fd.jpg?1631176921',
            'coingeckoId': 'yin-finance',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'AXIAV3',
            'name': 'Axia',
            'address': '0x793786e2dd4Cc492ed366a94B88a3Ff9ba5E7546',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12906/thumb/axia_200x200.png?1613988058',
            'coingeckoId': 'axia',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PHOON',
            'name': 'Typhoon Cash',
            'address': '0x79256DB1BDB6259315a1a3D7Dd237F69cADFd8FC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13730/thumb/photo_2021-01-21_19-05-27.jpg?1611227665',
            'coingeckoId': 'typhoon-cash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DMT',
            'name': 'Dark Matter',
            'address': '0x79126d32a86e6663F3aAac4527732d0701c1AE6c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14223/thumb/dmt.jpg?1615089067',
            'coingeckoId': 'dark-matter',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CBC',
            'name': 'CryptoBossCoin',
            'address': '0x790bFaCaE71576107C068f494c8A6302aea640cb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7114/thumb/eqIkj-ZZ_400x400.jpg?1549521587',
            'coingeckoId': 'cryptobosscoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CYMT',
            'name': 'CyberMusic',
            'address': '0x78c292D1445E6b9558bf42e8BC369271DeD062eA',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6039/thumb/cybermusic.png?1547791125',
            'coingeckoId': 'cybermusic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEFI+L',
            'name': 'PieDAO DEFI Large Cap',
            'address': '0x78F225869c08d478c34e5f645d07A87d3fe8eb78',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x78F225869c08d478c34e5f645d07A87d3fe8eb78/logo.png',
            'coingeckoId': 'piedao-defi-large-cap',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'SETH',
            'name': 'Sether',
            'address': '0x78B039921E84E726EB72E7b1212bb35504c645cA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2101/thumb/sether.png?1548609212',
            'coingeckoId': 'sether',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CVA',
            'name': 'Crypto Village Accelerator',
            'address': '0x78A52E12c7b63d05c12F9608307587CF654EC3d0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10071/thumb/logo_tondo_%281%29.png?1575499784',
            'coingeckoId': 'crypto-village-accelerator',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MVD',
            'name': 'Metavault DAO',
            'address': '0x788B6D2B37Aa51D916F2837Ae25b05f0e61339d1',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23899/thumb/mvd.png?1660680251',
            'coingeckoId': 'metavault-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BDOT',
            'name': 'Binance Wrapped DOT',
            'address': '0x7884F51dC1410387371ce61747CB6264E1dAeE0B',
            'decimals': 10,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23268/thumb/binance-dot_32.png?1643414783',
            'coingeckoId': 'binance-wrapped-dot',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LWD',
            'name': 'Landworld',
            'address': '0x786e995A975C2ab7C1dd252A9019fB0c91c9Ca83',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25642/thumb/v-MhJXeSQuKSjz5gJRpptF5E5v95862DXJhkSeVabQcVsbBxzryaaQQ-eqwp0hvjrXQQmQRUKYW3gFfzUi3zVjhWoZKg79rmFF5dL6igKT0iWWpDtfNRcr6XA1mL9WiLgg6EOqwfmuLNu1-qan38OQJve32FKgJ7FL_fAyZRHvSuuIwO4qmcLPVqWo08oYELCC5ParMmS7FfNsHtu38u6j0gxw.jpg?1652949037',
            'coingeckoId': 'landworld',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PVT',
            'name': 'Pivot',
            'address': '0x7869c4A1a3f6F8684FBCC422a21aD7Abe3167834',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8977/thumb/j5ovdTKP_400x400.png?1563405624',
            'coingeckoId': 'pivot-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KIT',
            'name': 'DexKit',
            'address': '0x7866E48C74CbFB8183cd1a929cd9b95a7a5CB4F4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x7866e48c74cbfb8183cd1a929cd9b95a7a5cb4f4.png',
            'coingeckoId': 'dexkit',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SNTVT',
            'name': 'Sentivate',
            'address': '0x7865af71cf0b288b4E7F654f4F7851EB46a2B7F8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x7865af71cf0b288b4e7f654f4f7851eb46a2b7f8.png',
            'coingeckoId': 'sentivate',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BUMP',
            'name': 'Bumper',
            'address': '0x785c34312dfA6B74F6f1829f79ADe39042222168',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17822/thumb/Bumper-Icon-Inverse-200.png?1629344025',
            'coingeckoId': 'bumper',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KERMAN',
            'name': 'KERMAN',
            'address': '0x7841B2A48D1F6e78ACeC359FEd6D874Eb8a0f63c',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11536/thumb/Kerman.png?1590776066',
            'coingeckoId': 'kerman',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'mooCurveG3CRV',
            'name': 'Moo Curve g3CRV',
            'address': '0x783fbEDf704E45a4ed0D7e3AE85BE8C376eCba1C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'RODO',
            'name': 'RODO',
            'address': '0x783C68814126b66B9242C4c6538Ae47dB5e33169',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://static.debank.com/image/eth_token/logo_url/0x783c68814126b66b9242c4c6538ae47db5e33169/3caaca5b960e06265e2653dc2e0ba7e9.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BHC',
            'name': 'Billion Happiness',
            'address': '0x7837fd820bA38f95c54D6dAC4ca3751b81511357',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WTLOS',
            'name': 'Wrapped Telos',
            'address': '0x7825e833D495F3d1c28872415a4aee339D26AC88',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/logos/main/network/ethereum/0x7825e833D495F3d1c28872415a4aee339D26AC88.jpg',
            'coingeckoId': 'wrapped-telos',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'RSFT',
            'name': 'ROYAL SMART FUTURE TOKEN',
            'address': '0x78223d31298107f3E310B09797b07967832046A6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26630/thumb/RSF200x200.png?1659325435',
            'coingeckoId': 'royal-smart-future-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YEL',
            'name': 'Yel Finance',
            'address': '0x7815bDa662050D84718B988735218CFfd32f75ea',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x7815bda662050d84718b988735218cffd32f75ea.png',
            'coingeckoId': 'yel-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'LINU',
            'name': 'Luna Inu',
            'address': '0x78132543D8E20D2417d8a07D9ae199D458A0D581',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25623/thumb/output-onlinepngtools.png?1653983337',
            'coingeckoId': 'luna-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOXX',
            'name': 'Blockparty',
            'address': '0x780116D91E5592E58a3b3c76A351571b39abCEc6',
            'decimals': 15,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x780116d91e5592e58a3b3c76a351571b39abcec6.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'LAIKA',
            'name': 'Laika',
            'address': '0x77f9CF0bd8C500CfFdF420e72343893aeCC2EC0b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26717/thumb/Laikacmcfinal.png?1659857807',
            'coingeckoId': 'laika',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'fiETH',
            'name': 'bZx ETH iToken',
            'address': '0x77f973FCaF871459aa58cd81881Ce453759281bC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x77f973fcaf871459aa58cd81881ce453759281bc.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'DVD',
            'name': 'DAOventures',
            'address': '0x77dcE26c03a9B833fc2D7C31C22Da4f42e9d9582',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13971/thumb/DAOventures.co_final_icon_200x200.png?1613388224',
            'coingeckoId': 'daoventures',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FNTG',
            'name': 'Fan2Go',
            'address': '0x77a7b7DefcC0c582DAdB9d2375975e2a75Fb4538',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28180/thumb/ftgo_logo%28200x200%29.png?1668253761',
            'coingeckoId': 'fan2go',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FORTH',
            'name': 'Ampleforth Governance',
            'address': '0x77FbA179C79De5B7653F68b5039Af940AdA60ce0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x77fba179c79de5b7653f68b5039af940ada60ce0.png',
            'coingeckoId': 'ampleforth-governance-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'CITIZEN',
            'name': 'KONG Land Alpha Citizenship',
            'address': '0x77F0cc420dEa0aE726Db6Bef1460A4B69176A8Ea',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17751/thumb/QmQmZNp7JNdvYAA8ichVr5bVZUUTfU83zJ8hTZoQfb9YBh.png?1629175195',
            'coingeckoId': 'kong-land-alpha-citizenship',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEV',
            'name': 'Dev Doing Something',
            'address': '0x77E20FC7f2F4084b74284E1190810fCa3679aEa0',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26805/thumb/200x200.png?1660208225',
            'coingeckoId': 'dev-doing-something',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWUSD',
            'name': 'Swerve fi USD',
            'address': '0x77C6E4a580c0dCE4E5c7a17d0bc077188a83A059',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12918/thumb/swerve.png?1603631063',
            'coingeckoId': 'swusd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BRZE',
            'name': 'Breezecoin',
            'address': '0x77C07555aF5ffdC946Fb47ce15EA68620E4e7170',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6727/thumb/breezecoin.png?1547563369',
            'coingeckoId': 'breezecoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VST',
            'name': 'Voice Street',
            'address': '0x77A1f4E744d810239F465043E35d067Ca33De259',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23147/thumb/LbMUkaD9_400x400.jpg?1643348841',
            'coingeckoId': 'voice-street',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TAG',
            'name': 'Dog Tag',
            'address': '0x7797C85b46F548eAcC07C229f6Cd207d6370442f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26418/thumb/TAG_PNG.png?1657926565',
            'coingeckoId': 'dog-tag',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NEWS',
            'name': 'PUBLISH',
            'address': '0x777fD20c983d6658c1D50b3958B3A1733d1cd1E1',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9572/thumb/PUBLISH_Logo_280X280.png?1617605563',
            'coingeckoId': 'publish',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RYOSHI',
            'name': 'Ryoshis Vision',
            'address': '0x777E2ae845272a2F540ebf6a3D03734A5a8f618e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17614/thumb/OFFICIAL_RYOSHI_LOGO-removebg-preview_2_200x200.png?1629794604',
            'coingeckoId': 'ryoshis-vision',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OOE',
            'name': 'OpenOcean',
            'address': '0x7778360F035C589fCE2f4eA5786CbD8B36e5396B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1666922421979_4320901515536779.png',
            'coingeckoId': 'openocean',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'TORN',
            'name': 'Tornado Cash',
            'address': '0x77777FeDdddFfC19Ff86DB637967013e6C6A116C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13496/small/ZINt8NSB_400x400.jpg?1609193407',
            'coingeckoId': 'tornado-cash',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'SIG',
            'name': 'xSigma',
            'address': '0x7777777777697cFEECF846A76326dA79CC606517',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x7777777777697cfeecf846a76326da79cc606517.png',
            'coingeckoId': 'xsigma',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'XY',
            'name': 'XY Finance',
            'address': '0x77777777772cf0455fB38eE0e75f38034dFa50DE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21541/small/xy.png?1639913622',
            'coingeckoId': 'xy-finance',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'BETS',
            'name': 'BetSwirl Token',
            'address': '0x77609d4F3ae31382389374101Aca6108D95b093A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'UETH',
            'name': 'Unagii ETH',
            'address': '0x77607588222e01bf892a29Abab45796A2047fc7b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14624/thumb/uETH.png?1617294107',
            'coingeckoId': 'unagii-eth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SEFI',
            'name': 'Secret Finance',
            'address': '0x773258b03c730F84aF10dFcB1BfAa7487558B8Ac',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14601/thumb/_Icon___SVG__Primary_-_Metallic_Gold_in_Circle_%282%29.png?1635066611',
            'coingeckoId': 'secret-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NIFTSY',
            'name': 'Envelop  Niftsy ',
            'address': '0x7728cd70b3dD86210e2bd321437F448231B81733',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19161/thumb/logo200x200.png?1643188130',
            'coingeckoId': 'niftsy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MINTME',
            'name': 'MintMe com Coin',
            'address': '0x772722B55cdC2A086aBd064267a17855Eb15e8b3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5127/thumb/MINTME_logo.png?1666845801',
            'coingeckoId': 'webchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BKR',
            'name': 'Balkari',
            'address': '0x77271813bd9167e75B5DF9c230cf58d64f0A58Fd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18662/thumb/BKR_log_200.png?1632818558',
            'coingeckoId': 'balkari-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NMS',
            'name': 'Nemesis',
            'address': '0x77252494C25444F8598A0c74Ffc90ADc535291a9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18904/thumb/NMS-150x150.jpg?1633849465',
            'coingeckoId': 'nemesis',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'RELIC',
            'name': 'Relic',
            'address': '0x77132Bb08B02a7a87732ec289EFaB45edF49884F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26424/thumb/EQiM831z_400x400.png?1657930563',
            'coingeckoId': 'relic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BNK',
            'name': 'Bankera',
            'address': '0x7707aAdA3Ce7722Ac63B91727DAF1999849F6835',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1949/thumb/bankera.png?1650883375',
            'coingeckoId': 'bankera',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SMT',
            'name': 'Smart Marketing',
            'address': '0x76dbCba41e11784BFA95cE30e529fA533C3fA4aD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21905/thumb/smt-logo.png?1640234993',
            'coingeckoId': 'smart-marketing-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BT',
            'name': 'BT Finance',
            'address': '0x76c5449F4950f6338A393F53CdA8b53B0cd3Ca3a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x76c5449f4950f6338a393f53cda8b53b0cd3ca3a.png',
            'coingeckoId': 'bt-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SARCO',
            'name': 'Sarcophagus',
            'address': '0x7697B462A7c4Ff5F8b55BDBC2F4076c2aF9cF51A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x7697b462a7c4ff5f8b55bdbc2f4076c2af9cf51a.png',
            'coingeckoId': 'sarcophagus',
            'listedIn': [
                'coingecko',
                '1inch',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'RED',
            'name': 'Red',
            'address': '0x76960Dccd5a1fe799F7c29bE9F19ceB4627aEb2f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4523/thumb/red.png?1548607994',
            'coingeckoId': 'red',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FOGE',
            'name': 'Fat Doge',
            'address': '0x76851A93977bEA9264C32255b6457882035C7501',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15497/thumb/foge.png?1621057848',
            'coingeckoId': 'fat-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MEMELON',
            'name': 'MEME ELON DOGE FLOKI',
            'address': '0x7683F14614A1F68Ea71474680e80b9bD93Db74D1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ILV',
            'name': 'Illuvium',
            'address': '0x767FE9EDC9E0dF98E07454847909b5E959D7ca0E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x767fe9edc9e0df98e07454847909b5e959d7ca0e.png',
            'coingeckoId': 'illuvium',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'BBR',
            'name': 'Bitberry',
            'address': '0x7671904eed7f10808B664fc30BB8693FD7237abF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13673/thumb/1_AdMyBccrRv0e6rhW7UKJSw.png?1610681228',
            'coingeckoId': 'bitberry-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DTX',
            'name': 'DaTa eXchange DTX',
            'address': '0x765f0C16D1Ddc279295c1a7C24B0883F62d33F75',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2920/thumb/dtx.png?1578973961',
            'coingeckoId': 'databroker-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METEOR',
            'name': 'Meteorite Network',
            'address': '0x765BAeFCB5418fA9f7dddACb1ccc07BD0e890e4e',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14889/thumb/Meteor-token-200.png?1618900489',
            'coingeckoId': 'meteorite-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XCAD',
            'name': 'XCAD Network',
            'address': '0x7659CE147D0e714454073a5dd7003544234b6Aa0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x7659ce147d0e714454073a5dd7003544234b6aa0.png',
            'coingeckoId': 'xcad-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'FLUX',
            'name': 'Flux Protocol',
            'address': '0x7645DdfEecedA57e41f92679c4aCd83c56A81D14',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/9837.png',
            'coingeckoId': 'flux-protocol',
            'listedIn': [
                'coingecko',
                'openocean',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'zUSD',
            'name': 'Zerogoki USD',
            'address': '0x76417E660DF3E5c90C0361674C192da152A806E4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x76417e660df3e5c90c0361674c192da152a806e4.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'LIT',
            'name': 'Lition',
            'address': '0x763Fa6806e1acf68130D2D0f0df754C93cC546B2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6580/thumb/Lition_ico.png?1547042787',
            'coingeckoId': 'lition',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ACR',
            'name': 'Acreage Coin',
            'address': '0x76306F029f8F99EFFE509534037Ba7030999E3CF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5100/thumb/acreage-coin.jpg?1547040494',
            'coingeckoId': 'acreage-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WET',
            'name': 'WaifuEnhancementToken',
            'address': '0x76280AF9D18a868a0aF3dcA95b57DDE816c1aaf2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x76280AF9D18a868a0aF3dcA95b57DDE816c1aaf2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'ELON',
            'name': 'Dogelon Mars',
            'address': '0x761D38e5ddf6ccf6Cf7c55759d5210750B5D60F3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x761d38e5ddf6ccf6cf7c55759d5210750b5d60f3.png',
            'coingeckoId': 'dogelon-mars',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'AMC',
            'name': 'AMC Fight Night',
            'address': '0x7603de2eA4cbceB0250118De03Fcb70Fe1e8e935',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17290/thumb/amc.PNG?1627252646',
            'coingeckoId': 'amc-fight-night',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-18481-WESTP',
            'name': 'RealT   18481 Westphalia St  Detroit  M',
            'address': '0x75f06B482adbFb04b877D8ee683E2FCDf18AD153',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16633/thumb/18481-Westphalia-hero-1.jpeg?1624554981',
            'coingeckoId': '18481-westphalia',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATOMBULL',
            'name': '3X Long Cosmos Token',
            'address': '0x75F0038B8fbfCCAFe2aB9a51431658871bA5182C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10181/thumb/683JEXMN_400x400_%281%29.png?1576507613',
            'coingeckoId': '3x-long-cosmos-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CSR',
            'name': 'CSR',
            'address': '0x75Ecb52e403C617679FBd3e77A50f9d10A842387',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25585/thumb/CSR-LOGO-200x200-1.png?1652767956',
            'coingeckoId': 'csr',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MYOBU',
            'name': 'Myōbu',
            'address': '0x75D12E4F91Df721faFCae4c6cD1d5280381370AC',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x75d12e4f91df721fafcae4c6cd1d5280381370ac.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ASEC',
            'name': 'ASEC Frontier',
            'address': '0x75B02Aa1084a12b8729F5acBE1078BD450AbE552',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15939/thumb/58947b_49a936ef42ab4b7da4d2beb95852d49a_mv2.png?1622459327',
            'coingeckoId': 'asec-frontier',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PHONON',
            'name': 'Phonon DAO',
            'address': '0x758B4684BE769E92eeFeA93f60DDA0181eA303Ec',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22308/thumb/ezgif-2-e7fb84364d.png?1641449852',
            'coingeckoId': 'phonon-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOUL',
            'name': 'Phantasma',
            'address': '0x75858677e27C930FB622759FeafFeE2b754Af07F',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4130/thumb/phantasma.png?1548331035',
            'coingeckoId': 'phantasma',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CO2',
            'name': 'Collective',
            'address': '0x75739d5944534115d7C54ee8C73F186D793BAE02',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14542/thumb/the_collective_logo.png?1616912000',
            'coingeckoId': 'collective',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUNNY',
            'name': 'Bunny',
            'address': '0x755eb14D2fefF2939EB3026f5CaD9D03775b9fF4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3185/thumb/bunnytoken.png?1547564097',
            'coingeckoId': 'bunnytoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VVT',
            'name': 'VersoView',
            'address': '0x755be920943eA95e39eE2DC437b268917B580D6e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13380/thumb/HkfxEtWh_400x400.jpg?1608031723',
            'coingeckoId': 'versoview',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAI',
            'name': 'Mindsync',
            'address': '0x75387e1287Dd85482aB66102DA9f6577E027f609',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7581/thumb/mindsync-logo.jpg?1548636745',
            'coingeckoId': 'mindsync',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INTX',
            'name': 'INTEXCOIN',
            'address': '0x7533D63A2558965472398Ef473908e1320520AE2',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11854/thumb/INTX.png?1595167044',
            'coingeckoId': 'intexcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COMFI',
            'name': 'CompliFi',
            'address': '0x752Efadc0a7E05ad1BCCcDA22c141D01a75EF1e4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15006/thumb/complifi.PNG?1619413977',
            'coingeckoId': 'complifi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OKB',
            'name': 'OKB',
            'address': '0x75231F58b43240C9718Dd58B4967c5114342a86c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/okb.jpg',
            'coingeckoId': 'okb',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic'
            ]
        },
        {
            'symbol': 'WM',
            'name': 'WenMoon',
            'address': '0x750c83707112E9aCc452692c190cB55E30f42510',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20263/thumb/Wen-Moon200x200.png?1636704100',
            'coingeckoId': 'wenmoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HDAO',
            'name': 'HyperDAO',
            'address': '0x74faaB6986560fD1140508e4266D8a7b87274Ffd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10780/thumb/B7-ppPfE_400x400.png?1583467291',
            'coingeckoId': 'hyperdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPKU',
            'name': 'Spooky Uni',
            'address': '0x74bEEf5283F7605Be17Bd0bB3B118ad6AE9200E5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27702/thumb/Spooky-Uni200x200.png?1665307264',
            'coingeckoId': 'spooky-uni',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HIT',
            'name': 'HitBTC',
            'address': '0x74b1Af114274335598da72f5C6ed7b954a016EeD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16395/thumb/JgMkz95.png?1623909204',
            'coingeckoId': 'hitbtc-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOAT',
            'name': 'G O A T',
            'address': '0x74EDAf28fc4b9E6A1618D613839Daaf6A9D075dB',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21537/thumb/IMG_1871.JPG?1639401972',
            'coingeckoId': 'g-o-a-t-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CSI',
            'name': 'Credit Suisse Inu',
            'address': '0x74BFF2018687B8a1ff7132E13cD4FdC763725BcF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27612/thumb/Credit-Suisse-Inu.jpg?1664853199',
            'coingeckoId': 'credit-suisse-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MEME',
            'name': 'Meme Inu',
            'address': '0x74B988156925937bD4E082f0eD7429Da8eAea8Db',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20755/thumb/photo_2021-11-20_13-39-45.jpg?1637638928',
            'coingeckoId': 'meme-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LSD',
            'name': 'Bad Trip',
            'address': '0x749b964F3dd571b177fc6E415A07F62b05047dA4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/lsd.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'ARNC',
            'name': 'Arnoya classic',
            'address': '0x7495e5Cc8F27e0bd5bD4cb86d17F0D841cA58EE4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28194/thumb/ARNC_200.png?1668332937',
            'coingeckoId': 'arnoya-classic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NDS',
            'name': 'Nodeseeds',
            'address': '0x747f564d258612ec5c4E24742C5fd4110bCbe46B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14658/thumb/n1EiZLt.png?1618374330',
            'coingeckoId': 'nodeseeds',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABYFD',
            'name': 'Baby Floki Doge',
            'address': '0x747C4Ce9622EA750EA8048423B38A746B096C8e8',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19543/thumb/babyfd.png?1635641884',
            'coingeckoId': 'baby-floki-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ACCEL',
            'name': 'Accel Defi',
            'address': '0x7475C42f8BF2c19F4EAF12fEaabaBA859fdC8914',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x7475c42f8bf2c19f4eaf12feaababa859fdc8914_1.png',
            'coingeckoId': 'accel-defi',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BOA',
            'name': 'BOSAGORA',
            'address': '0x746DdA2ea243400D5a63e0700F190aB79f06489e',
            'decimals': 7,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9202/thumb/boaLogo.png?1565231346',
            'coingeckoId': 'bosagora',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETGF',
            'name': 'ETG Finance',
            'address': '0x74603e780545d02C4257E7D2BE19c74dE7BE1952',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13032/thumb/etgf_logo.png?1604482450',
            'coingeckoId': 'etg-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOA',
            'name': 'LOA Protocol',
            'address': '0x7458fd786B2fe8CD801C0381f88b61C5071A006F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11164/thumb/1a67ac81c1803ee172b7ce8805b5da3d-full.jpg?1589411408',
            'coingeckoId': 'loa-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SNT',
            'name': 'Status',
            'address': '0x744d70FDBE2Ba4CF95131626614a1763DF805B9E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x744d70fdbe2ba4cf95131626614a1763df805b9e.png',
            'coingeckoId': 'status',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'TSYGAN',
            'name': 'The Real Tsygan',
            'address': '0x744DC59f30aC1992b28564287970441441def136',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26812/thumb/qscBke9-_400x400.jpg?1660273459',
            'coingeckoId': 'the-real-tsygan',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STRK',
            'name': 'Strike',
            'address': '0x74232704659ef37c08995e386A2E26cc27a8d7B1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14607/thumb/Jw-36llq_400x400.jpg?1617243607',
            'coingeckoId': 'strike',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JASMY',
            'name': 'JasmyCoin',
            'address': '0x7420B4b9a0110cdC71fB720908340C03F9Bc03EC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x7420b4b9a0110cdc71fb720908340c03f9bc03ec.png',
            'coingeckoId': 'jasmycoin',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ARTX',
            'name': 'ARTX',
            'address': '0x741b0428Efdf4372A8DF6FB54B018dB5e5aB7710',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14652/thumb/logo_black_cmc.png?1617556500',
            'coingeckoId': 'artx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TEN',
            'name': 'Tenet',
            'address': '0x74159651A992952e2bF340D7628459aA4593fc05',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13545/thumb/iMqC3F_p_400x400.png?1609711856',
            'coingeckoId': 'tenet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JGBP',
            'name': 'Jarvis Synthetic British Pound',
            'address': '0x7409856CAE628f5d578B285B45669b36E7005283',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/jgbp.jpg',
            'coingeckoId': 'jarvis-synthetic-british-pound',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'DYT',
            'name': 'DoYourTip',
            'address': '0x740623d2c797b7D8D1EcB98e9b4Afcf99Ec31E14',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8936/thumb/image1.png?1578033515',
            'coingeckoId': 'dynamite',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DUK+',
            'name': 'Dukascoin',
            'address': '0x73f2651EA38d48B3B808ffdDbd398Fd600aB5BA7',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14374/thumb/dukascoin_200x200_silver.png?1615774652',
            'coingeckoId': 'dukascoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IXS',
            'name': 'IX Swap',
            'address': '0x73d7c860998CA3c01Ce8c808F5577d94d545d1b4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x73d7c860998ca3c01ce8c808f5577d94d545d1b4.png',
            'coingeckoId': 'ix-swap',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'AXI',
            'name': 'Axioms',
            'address': '0x73EE6D7e6b203125aDd89320E9F343d65ec7c39a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12562/thumb/axioms_logo.png?1600772415',
            'coingeckoId': 'axioms',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTRS',
            'name': 'Bitball Treasure',
            'address': '0x73C9275c3a2Dd84b5741fD59AEbF102C91Eb033F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7273/thumb/btrs.png?1637556868',
            'coingeckoId': 'bitball-treasure',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTE',
            'name': 'Betero',
            'address': '0x73B708E84837FFCcDE2933e3A1531fe61D5e80Ef',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25153/thumb/B_green200.png?1650461529',
            'coingeckoId': 'betero',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'WLEO',
            'name': 'Wrapped LEO',
            'address': '0x73A9fb46e228628f8f9BB9004eCa4f4F529D3998',
            'decimals': 3,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12626/thumb/4XfO3w3.png?1601286769',
            'coingeckoId': 'wrapped-leo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '0NE',
            'name': 'Civfund Stone',
            'address': '0x73A83269b9bbAFC427E76Be0A2C1a1db2a26f4C2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25156/thumb/stone.png?1650462014',
            'coingeckoId': 'civfund-stone',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SCROH',
            'name': 'Scro',
            'address': '0x73A058dA53220D25bCb2C4cb8Ff8520626Ea26E7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26882/thumb/SCRO.png?1660646485',
            'coingeckoId': 'scro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NDR',
            'name': 'Node Runners',
            'address': '0x739763a258640919981F9bA610AE65492455bE53',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x739763a258640919981f9ba610ae65492455be53.png',
            'coingeckoId': 'noderunners',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SDT',
            'name': 'Stake DAO',
            'address': '0x73968b9a57c6E53d41345FD57a6E6ae27d6CDB2F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x73968b9a57c6e53d41345fd57a6e6ae27d6cdb2f.png',
            'coingeckoId': 'stake-dao',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'AGRS',
            'name': 'Agoras  Currency of Tau',
            'address': '0x738865301A9b7Dd80Dc3666dD48cF034ec42bdDa',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x738865301a9b7dd80dc3666dd48cf034ec42bdda.png',
            'coingeckoId': 'agoras-currency-of-tau',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MEDIBIT',
            'name': 'MEDIBIT',
            'address': '0x737fA0372c8D001904Ae6aCAf0552d4015F9c947',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6606/thumb/medibit.png?1547978632',
            'coingeckoId': 'medibit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AMN',
            'name': 'Amon',
            'address': '0x737F98AC8cA59f2C68aD658E3C3d8C8963E40a4c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x737f98ac8ca59f2c68ad658e3c3d8c8963e40a4c.png',
            'coingeckoId': 'amon',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'TSX',
            'name': 'TradeStars',
            'address': '0x734C90044a0bA31B3F2e640c10dC5d3540499Bfd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1634703166846_13459735724776078.png',
            'coingeckoId': 'tradestars',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'BRCP',
            'name': 'BRCP',
            'address': '0x73484A262730d1d422610729e828346F9B2ff480',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14388/thumb/brcp-500x500-verde.png?1615855378',
            'coingeckoId': 'brcp-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JGN',
            'name': 'Juggernaut',
            'address': '0x73374Ea518De7adDD4c2B624C0e8B113955ee041',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x73374ea518de7addd4c2b624c0e8b113955ee041.png',
            'coingeckoId': 'juggernaut',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SMARTCREDIT',
            'name': 'SmartCredit',
            'address': '0x72e9D9038cE484EE986FEa183f8d8Df93f9aDA13',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x72e9d9038ce484ee986fea183f8d8df93f9ada13.png',
            'coingeckoId': 'smartcredit-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MVI',
            'name': 'Metaverse Index',
            'address': '0x72e364F2ABdC788b7E918bc238B21f109Cd634D7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x72e364f2abdc788b7e918bc238b21f109cd634d7.png',
            'coingeckoId': 'metaverse-index',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BCP',
            'name': 'BlockchainPoland',
            'address': '0x72e203a17adD19A3099137c9d7015fD3e2b7DBa9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8143/thumb/43Stz1Tw.png?1555551797',
            'coingeckoId': 'blockchainpoland',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NGC',
            'name': 'NAGA',
            'address': '0x72dD4b6bd852A3AA172Be4d6C5a6dbEc588cf131',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1439/thumb/naga.jpg?1547035515',
            'coingeckoId': 'naga',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WRC',
            'name': 'Worldcore',
            'address': '0x72aDadb447784dd7AB1F472467750fC485e4cb2d',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1358/thumb/worldcore.png?1548761189',
            'coingeckoId': 'worldcore',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CVAG',
            'name': 'Crypto Village Accelerator CVAG',
            'address': '0x72a66e54B66892aE3BBe54DF7bb7Dd5Ae927a6f9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17124/thumb/cvag.PNG?1626401266',
            'coingeckoId': 'crypto-village-accelerator-cvag',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PLOT',
            'name': 'PlotX',
            'address': '0x72F020f8f3E8fd9382705723Cd26380f8D0c66Bb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x72f020f8f3e8fd9382705723cd26380f8d0c66bb.png',
            'coingeckoId': 'plotx',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'HANU',
            'name': 'Hanu Yokia',
            'address': '0x72E5390EDb7727E3d4e3436451DADafF675dBCC0',
            'decimals': 12,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x72e5390edb7727e3d4e3436451dadaff675dbcc0.png',
            'coingeckoId': 'hanu-yokia',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'LED',
            'name': 'LedgerScore',
            'address': '0x72De803b67B6AB05B61EFab2Efdcd414D16eBF6D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16144/thumb/photo-2021-06-05-07-45-06.jpg?1623119314',
            'coingeckoId': 'ledgerscore',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XDEFI',
            'name': 'XDEFI',
            'address': '0x72B886d09C117654aB7dA13A14d603001dE0B777',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x72b886d09c117654ab7da13a14d603001de0b777.png',
            'coingeckoId': 'xdefi',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'TRV',
            'name': 'TrustVerse',
            'address': '0x72955eCFf76E48F2C8AbCCe11d54e5734D6f3657',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8793/thumb/trv_256px.png?1623928469',
            'coingeckoId': 'trustverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CLEV',
            'name': 'CLever',
            'address': '0x72953a5C32413614d24C29c84a66AE4B59581Bbf',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27727/thumb/%E6%88%AA%E5%B1%8F2022-10-12_%E4%B8%8A%E5%8D%8810.40.19.png?1665550689',
            'coingeckoId': 'clever-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GHX',
            'name': 'GamerCoin',
            'address': '0x728f30fa2f100742C7949D1961804FA8E0B1387d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14714/thumb/ghx_icon.png?1618819440',
            'coingeckoId': 'gamercoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORION',
            'name': 'Orion Money',
            'address': '0x727f064A78DC734D33eEc18d5370aef32Ffd46e4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18630/thumb/YtrqPIWc.png?1632710781',
            'coingeckoId': 'orion-money',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHARIZARD',
            'name': 'Charizard Inu',
            'address': '0x727e8260877F8507F8D61917e9778b6aF8491e63',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19415/thumb/QXjLCDl.png?1635212769',
            'coingeckoId': 'charizard-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EPAN',
            'name': 'Paypolitan',
            'address': '0x72630B1e3B42874bf335020Ba0249e3E9e47Bafc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x72630b1e3b42874bf335020ba0249e3e9e47bafc.png',
            'coingeckoId': 'paypolitan-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BMI',
            'name': 'Bridge Mutual',
            'address': '0x725C263e32c72dDC3A19bEa12C5a0479a81eE688',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x725c263e32c72ddc3a19bea12c5a0479a81ee688.png',
            'coingeckoId': 'bridge-mutual',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'MONDAY',
            'name': 'MondayClub',
            'address': '0x7252d57Ae4Ec9aF61DB3B82A6eeDAB74EEC5D1dc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24767/thumb/token.png?1648817450',
            'coingeckoId': 'mondayclub',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AVAK',
            'name': 'Avakus',
            'address': '0x724dD18Be5De3ed3D6aD7Bb46D7387d867cDBdcc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18337/thumb/avakus.PNG?1631588881',
            'coingeckoId': 'avakus',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'TIC',
            'name': 'Thingschain',
            'address': '0x72430A612Adc007c50e3b6946dBb1Bb0fd3101D1',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5320/thumb/thingschain-logo.png?1547040881',
            'coingeckoId': 'thingschain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OCTO',
            'name': 'OctoFi',
            'address': '0x7240aC91f01233BaAf8b064248E80feaA5912BA3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x7240ac91f01233baaf8b064248e80feaa5912ba3.png',
            'coingeckoId': 'octofi',
            'listedIn': [
                'coingecko',
                '1inch',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'PHL',
            'name': 'Philcoin',
            'address': '0x723bD1f87A327e94ceaBf68d8f022E0f54B9cC1a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24178/thumb/2ZFyoMSk.png?1646901982',
            'coingeckoId': 'philcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTCP',
            'name': 'Bitcoin Pro',
            'address': '0x723CbfC05e2cfcc71d3d89e770D32801A5eEf5Ab',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3545/thumb/DSiD9ZhWsAE_8cS.png?1547038353',
            'coingeckoId': 'bitcoin-pro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MGG',
            'name': 'MetaGaming Guild',
            'address': '0x7237C0B30B1355f1B76355582f182F6f04B08740',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23287/thumb/mgg.png?1643543010',
            'coingeckoId': 'metagaming-guild',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VRN',
            'name': 'Varen',
            'address': '0x72377f31e30a405282b522d588AEbbea202b4f23',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x72377f31e30a405282b522d588AEbbea202b4f23/logo.png',
            'coingeckoId': 'varen',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'UCM',
            'name': 'UCROWDME',
            'address': '0x722f97A435278B7383a1e3c47F41773bebF3232C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12079/thumb/UKm2qXh.png?1605346168',
            'coingeckoId': 'ucrowdme',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADF',
            'name': 'Ad Flex',
            'address': '0x7220e92D418E2EB59D0C25d195FA004bfD3aFC42',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5620/thumb/ad-flex-token.png?1547041485',
            'coingeckoId': 'ad-flex-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOOT',
            'name': 'Lootex',
            'address': '0x721A1B990699eE9D90b6327FaaD0A3E840aE8335',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22895/small/loot.png?1642850037',
            'coingeckoId': 'loot',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'ES',
            'name': 'Era Swap',
            'address': '0x72108a8CC3254813C6BE2F1b77be53E185abFdD9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6031/thumb/era_swap.PNG?1570920926',
            'coingeckoId': 'era-swap-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AUSDT',
            'name': 'Aave USDT v1',
            'address': '0x71fc860F7D3A592A4a98740e39dB31d25db65ae8',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x71fc860f7d3a592a4a98740e39db31d25db65ae8.png',
            'coingeckoId': 'aave-usdt-v1',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'XDAO',
            'name': 'XDAO',
            'address': '0x71eebA415A523F5C952Cc2f06361D5443545Ad28',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27363/thumb/XDAO_Token_img.png?1665738325',
            'coingeckoId': 'xdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIMIR',
            'name': 'Mimir',
            'address': '0x71dc40668682a124231301414167E4CF7f55383C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19551/thumb/xaq5Xlzg_400x400.jpg?1635401626',
            'coingeckoId': 'mimir-token',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'MBX',
            'name': 'MobieCoin',
            'address': '0x71ba91dC68C6a206Db0A6A92B4b1De3f9271432d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12874/thumb/MBX_token.jpg?1603186697',
            'coingeckoId': 'mobiecoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUNCH',
            'name': 'LunchDAO',
            'address': '0x71a28feAEe902966DC8D355e7B8Aa427D421e7e0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25808/thumb/200x200.png?1653979480',
            'coingeckoId': 'lunchdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RAIN',
            'name': 'Rainmaker Games',
            'address': '0x71Fc1F555a39E0B698653AB0b475488EC3c34D57',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21485/thumb/Final-Flip-Rain-Makers-44-1.png?1639362827',
            'coingeckoId': 'rainmaker-games',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'HIGH',
            'name': 'Highstreet',
            'address': '0x71Ab77b7dbB4fa7e017BC15090b2163221420282',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1639708897185_36657692058712255.png',
            'coingeckoId': 'highstreet',
            'listedIn': [
                'coingecko',
                'uniswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'PNYD',
            'name': 'PonyDAO',
            'address': '0x71921C08586295b0B68e44A78a2DCA1E3f259721',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26843/thumb/ponydao.png?1660389977',
            'coingeckoId': 'ponydao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '1PECO',
            'name': '1peco',
            'address': '0x7169bA45cF03E0377B464462AACbccdf1f721125',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22312/thumb/1PECO_200x200.png?1641467088',
            'coingeckoId': '1peco',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KSW',
            'name': 'KillSwitch',
            'address': '0x7162469321ae5880F077D250B626F3271b21b903',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20215/thumb/logo_%2824%29.png?1636670633',
            'coingeckoId': 'killswitch',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AENJ',
            'name': 'Aave ENJ v1',
            'address': '0x712DB54daA836B53Ef1EcBb9c6ba3b9Efb073F40',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x712db54daa836b53ef1ecbb9c6ba3b9efb073f40.png',
            'coingeckoId': 'aave-enj-v1',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'LUFFY',
            'name': 'Luffy',
            'address': '0x7121D00b4fA18F13Da6c2e30d19C04844E6AfDC8',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x7121d00b4fa18f13da6c2e30d19c04844e6afdc8.png',
            'coingeckoId': 'luffy-inu',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SOURCE',
            'name': 'ReSource Protocol',
            'address': '0x7118057ff0F4Fd0994fb9d2D94de8231d5cca79E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x7118057ff0F4Fd0994fb9d2D94de8231d5cca79E/logo.png',
            'coingeckoId': 'resource-protocol',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'HEART',
            'name': 'Hearts',
            'address': '0x710Aa623c2c881b0d7357bCf9aEedf660E606C22',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24121/thumb/heart.png?1646456042',
            'coingeckoId': 'hearts',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'X7DAO',
            'name': 'X7DAO',
            'address': '0x7105E64bF67ECA3Ae9b123F0e5Ca2b83b2eF2dA0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27709/thumb/X7DAO.png?1665314516',
            'coingeckoId': 'x7dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'aREPv1',
            'name': 'Aave Interest bearing REP',
            'address': '0x71010A9D003445aC60C4e6A7017c1E89A477B438',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x71010a9d003445ac60c4e6a7017c1e89a477b438.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'BLZN',
            'name': 'Blaze Network',
            'address': '0x70eFDc485a10210B056eF8e0A32993Bc6529995E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12248/thumb/A8JOuPSJ_400x400.jpg?1598511402',
            'coingeckoId': 'blaze-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XSGD',
            'name': 'XSGD',
            'address': '0x70e8dE73cE538DA2bEEd35d14187F6959a8ecA96',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x70e8de73ce538da2beed35d14187f6959a8eca96.png',
            'coingeckoId': 'xsgd',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'CCOMP',
            'name': 'cCOMP',
            'address': '0x70e36f6BF80a52b3B46b3aF8e106CC0ed743E8e4',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12840/thumb/1_z8UrVtod3bme4-J_pXAQQA_2x.png?1602936322',
            'coingeckoId': 'ccomp',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XQC',
            'name': 'Quras',
            'address': '0x70da48f4B7e83c386ef983D4CEF4e58c2c09D8Ac',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10112/thumb/FZFHac2z_400x400.jpg?1575964560',
            'coingeckoId': 'quras-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LINKETHMOON',
            'name': 'LINKETH 2x Long',
            'address': '0x70cDD88638cB569f1Ae1d4bD3529cc7FB331cbab',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x70cDD88638cB569f1Ae1d4bD3529cc7FB331cbab/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'GL',
            'name': 'Green Light',
            'address': '0x70FaDBE1F2CCCbaF98ac88FDCf94A0509A48E46d',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11015/thumb/greenlight.PNG?1587114464',
            'coingeckoId': 'green-light',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHEQ',
            'name': 'CHEQD Network',
            'address': '0x70EDF1c215D0ce69E7F16FD4E6276ba0d99d4de7',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20855/thumb/79901197.png?1637791407',
            'coingeckoId': 'cheqd-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ICC',
            'name': 'Immortal Cat',
            'address': '0x70E67ec0939ec87c3a77b089CA08E0443ea4a177',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26397/thumb/l4P_a2cv_400x400.png?1657753905',
            'coingeckoId': 'immortal-cat',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UPI',
            'name': 'Pawtocol',
            'address': '0x70D2b7C19352bB76e4409858FF5746e500f2B67c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x70d2b7c19352bb76e4409858ff5746e500f2b67c.png',
            'coingeckoId': 'pawtocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'NST',
            'name': 'Ninja Squad',
            'address': '0x70Bef3bB2f001dA2fDDb207dAe696cD9FAFf3f5d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/logos/main/network/ethereum/0x70Bef3bB2f001dA2fDDb207dAe696cD9FAFf3f5d.jpg',
            'coingeckoId': 'ninja-squad',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'YFOX',
            'name': 'YFOX Finance',
            'address': '0x706CB9E741CBFee00Ad5b3f5ACc8bd44D1644a74',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12702/thumb/Yfox.png?1601865990',
            'coingeckoId': 'yfox-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POLL',
            'name': 'ClearPoll',
            'address': '0x705EE96c1c160842C92c1aeCfCFfccc9C412e3D9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1050/thumb/clearpoll.png?1547034985',
            'coingeckoId': 'clearpoll',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHANGE',
            'name': 'ChangeX',
            'address': '0x7051faED0775f664a0286Af4F75ef5ed74e02754',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26487/thumb/Changex-mark-200x200.png?1665655365',
            'coingeckoId': 'changex',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'TCASH',
            'name': 'TCASH',
            'address': '0x7051620d11042c4335069AaA4f10Cd3B4290C681',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6574/thumb/jiPkVuI7_400x400.jpg?1547042782',
            'coingeckoId': 'tcash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BIRD',
            'name': 'Bird Money',
            'address': '0x70401dFD142A16dC7031c56E862Fc88Cb9537Ce0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x70401dfd142a16dc7031c56e862fc88cb9537ce0.png',
            'coingeckoId': 'bird-money',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'TSA',
            'name': 'Teaswap Art',
            'address': '0x703D8574E19428D662a73c301D106dc236aa9BFC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17826/thumb/tsa.PNG?1629358290',
            'coingeckoId': 'teaswap-art',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XRA',
            'name': 'Xriba',
            'address': '0x7025baB2EC90410de37F488d1298204cd4D6b29d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5639/thumb/xriba.png?1548761095',
            'coingeckoId': 'xriba',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OAX',
            'name': 'OAX',
            'address': '0x701C244b988a513c945973dEFA05de933b23Fe1D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/853/thumb/OAXlogo.png?1598686792',
            'coingeckoId': 'openanx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'X7R',
            'name': 'X7R',
            'address': '0x70008F18Fc58928dcE982b0A69C2c21ff80Dca54',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27710/thumb/X7R.png?1665314632',
            'coingeckoId': 'x7r',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PROPS',
            'name': 'Props',
            'address': '0x6fe56C0bcdD471359019FcBC48863d6c3e9d4F41',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x6fe56c0bcdd471359019fcbc48863d6c3e9d4f41.png',
            'coingeckoId': 'props',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'HBX',
            'name': 'HashBX',
            'address': '0x6fE355c62C6faf6946cE888fFABa9fD12355ae27',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7091/thumb/46489646-905693182887642-8012727636373536768-n.png?1550724892',
            'coingeckoId': 'hashbx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAINT',
            'name': 'Saint Inu',
            'address': '0x6fC5Af63990aA9E5c5543f5cd8eD148bfa6d9d19',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19724/thumb/saint_inu.png?1648269236',
            'coingeckoId': 'saint-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UMB',
            'name': 'Umbrella Network',
            'address': '0x6fC13EACE26590B80cCCAB1ba5d51890577D83B2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x6fc13eace26590b80cccab1ba5d51890577d83b2.png',
            'coingeckoId': 'umbrella-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'IOTX',
            'name': 'IoTeX',
            'address': '0x6fB3e0A217407EFFf7Ca062D46c26E5d60a14d69',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x6fb3e0a217407efff7ca062d46c26e5d60a14d69.png',
            'coingeckoId': 'iotex',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'IDTT',
            'name': 'Identity',
            'address': '0x6fB1E018f107d3352506c23777e4cd62e063584a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18384/thumb/identity.PNG?1631745919',
            'coingeckoId': 'identity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SILO',
            'name': 'Silo Finance',
            'address': '0x6f80310CA7F2C654691D1383149Fa1A57d8AB1f8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21454/thumb/y0iYKZOv_400x400.png?1639269432',
            'coingeckoId': 'silo-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INST',
            'name': 'Instadapp',
            'address': '0x6f40d4A6237C257fff2dB00FA0510DeEECd303eb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x6f40d4a6237c257fff2db00fa0510deeecd303eb.png',
            'coingeckoId': 'instadapp',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'HT',
            'name': 'Huobi',
            'address': '0x6f259637dcD74C767781E37Bc6133cd6A68aa161',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x6f259637dcd74c767781e37bc6133cd6a68aa161.png',
            'coingeckoId': 'huobi-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'EBSP',
            'name': 'EBSP',
            'address': '0x6f063c0fcda0eA6dCC01D5A7cB3066ed4F90d1A8',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7650/thumb/mwlDkYEKIpcWAP00hfCU7KPQti7jgbew1OKea9RGlGwpdkwOTWp7shOWG5EAsXpUonAo8ZnQoVYoWIz0Tju71QcYMesj8-4Qz_CAyxUy12MTknHGjwfpITWwDaRtHXeTDCCGMvPRPR5ebXultUTIQAtB1x9gtvA03QAi5RVg4GkfX6kB_r04Qx6w2lxuLuyUAqd4x6zaQo_1Hy8NV333I.jpg?1549001856',
            'coingeckoId': 'ebsp-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NORD',
            'name': 'Nord Finance',
            'address': '0x6e9730EcFfBed43fD876A264C982e254ef05a0DE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x6e9730ecffbed43fd876a264c982e254ef05a0de.png',
            'coingeckoId': 'nord-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'dfyn',
                'lifinance'
            ]
        },
        {
            'symbol': 'BIST',
            'name': 'Bistroo',
            'address': '0x6e8908cfa881C9f6f2C64d3436E7b80b1bf0093F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15645/thumb/bistroo.png?1659342030',
            'coingeckoId': 'bistroo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRADE',
            'name': 'Polytrade',
            'address': '0x6e5970DBd6fc7eb1f29C6D2eDF2bC4c36124C0C1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16416/thumb/Logo_colored_200.png?1623979654',
            'coingeckoId': 'polytrade',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUILD',
            'name': 'BUILD Finance',
            'address': '0x6e36556B3ee5Aa28Def2a8EC3DAe30eC2B208739',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12380/thumb/build.PNG?1599463828',
            'coingeckoId': 'build-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LCG',
            'name': 'LCG',
            'address': '0x6e1A58e7E9e801f32bb82462636a8a2E7B65e036',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12851/thumb/LCG_token.jpg?1603083545',
            'coingeckoId': 'lcg',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WFIL',
            'name': 'Wrapped Filecoin',
            'address': '0x6e1A19F235bE7ED8E3369eF73b196C07257494DE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/fil.jpg',
            'coingeckoId': 'wrapped-filecoin',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'BSVBULL',
            'name': '3X Long Bitcoin SV Token',
            'address': '0x6e13A9e4AE3d0678E511Fb6d2ad531fcF0e247bf',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10267/thumb/683JEXMN_400x400_%281%29.png?1576834731',
            'coingeckoId': '3x-long-bitcoin-sv-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QRX',
            'name': 'QuiverX',
            'address': '0x6e0daDE58D2d89eBBe7aFc384e3E4f15b70b14D8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x6e0dade58d2d89ebbe7afc384e3e4f15b70b14d8.png',
            'coingeckoId': 'quiverx',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MS',
            'name': 'Morphswap',
            'address': '0x6d6554939D646f274d0FC3cEcB7dab5d76bc908F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28114/thumb/mslogo200.png?1668056773',
            'coingeckoId': 'morphswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VNXAU',
            'name': 'VNX Gold',
            'address': '0x6d57B2E05F26C26b549231c866bdd39779e4a488',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28019/thumb/wnxgold.png?1667026518',
            'coingeckoId': 'vnx-gold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLCT',
            'name': 'Bloomzed Loyalty Club Ticket',
            'address': '0x6d2C508fc4a588A41713Ff59212F85489291d244',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10810/thumb/esRodCO5_400x400.jpg?1583906706',
            'coingeckoId': 'bloomzed-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UCT',
            'name': 'UnitedCrowd',
            'address': '0x6d1DC3928604b00180Bb570BdAe94b9698d33b79',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14956/thumb/eUvRU9wm.png?1619142511',
            'coingeckoId': 'unitedcrowd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SIV',
            'name': 'Sivasspor',
            'address': '0x6cf9464B2C628Db187f2Bc1DDc0C43fDA72EfDd5',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22043/thumb/16528.png?1640674444',
            'coingeckoId': 'sivasspor',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWPR',
            'name': 'Swapr',
            'address': '0x6cAcDB97e3fC8136805a9E7c342d866ab77D0957',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/12368.png',
            'coingeckoId': 'swapr',
            'listedIn': [
                'coingecko',
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'FRY',
            'name': 'FoundryDAO Logistics',
            'address': '0x6c972b70c533E2E045F333Ee28b9fFb8D717bE69',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x6c972b70c533e2e045f333ee28b9ffb8d717be69.png',
            'coingeckoId': 'foundrydao-logistics',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'LIFE',
            'name': 'Life Crypto',
            'address': '0x6c936D4AE98E6d2172dB18c16C4b601C99918EE6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18245/thumb/communityIcon_t3kzc5skazh81.png?1666058038',
            'coingeckoId': 'life-crypto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XLS',
            'name': 'ELIS',
            'address': '0x6c862f803fF42A97D4A483AB761256ad8c90f4F8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13613/thumb/Elis-Symbol-Green.png?1610285892',
            'coingeckoId': 'elis',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HOT',
            'name': 'Holo',
            'address': '0x6c6EE5e31d828De241282B9606C8e98Ea48526E2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x6c6ee5e31d828de241282b9606c8e98ea48526e2.png',
            'coingeckoId': 'holotoken',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'PHA',
            'name': 'Phala Network',
            'address': '0x6c5bA91642F10282b576d91922Ae6448C9d52f4E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x6c5ba91642f10282b576d91922ae6448c9d52f4e.png',
            'coingeckoId': 'pha',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'INES',
            'name': 'Inescoin',
            'address': '0x6c58Ee9794882C608E6015bde2802412B3D1377e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26537/thumb/Piece_face_inescoin_1_3_bryefr.png?1658720155',
            'coingeckoId': 'inescoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '3CRV',
            'name': 'LP 3pool Curve',
            'address': '0x6c3F90f043a72FA612cbac8115EE7e52BDe6E490',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x6c3f90f043a72fa612cbac8115ee7e52bde6e490.png',
            'coingeckoId': 'lp-3pool-curve',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'CNNS',
            'name': 'CNNS',
            'address': '0x6c3BE406174349cfa4501654313d97e6a31072e1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8362/thumb/cnns.png?1557992686',
            'coingeckoId': 'cnns',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELET',
            'name': 'Elementeum Games',
            'address': '0x6c37Bf4f042712C978A73e3fd56D1F5738dD7C43',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x6c37bf4f042712c978a73e3fd56d1f5738dd7c43.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'QUICK',
            'name': 'Quickswap  OLD ',
            'address': '0x6c28AeF8977c9B773996d0e8376d2EE379446F2f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13970/thumb/1_pOU6pBMEmiL-ZJVb0CYRjQ.png?1613386659',
            'coingeckoId': 'quick',
            'listedIn': [
                'coingecko',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BPTN',
            'name': 'Bit Public Talent Network',
            'address': '0x6c22B815904165F3599F0A4a092D458966bD8024',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8412/thumb/7f346702db390a289f5770f008563173.png?1558077057',
            'coingeckoId': 'bit-public-talent-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EURU',
            'name': 'Upper Euro',
            'address': '0x6c139349ee94eBAaff55eD52d382673C263B22d6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12353/thumb/euru.png?1642929585',
            'coingeckoId': 'upper-euro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HTB',
            'name': 'Hotbit',
            'address': '0x6be61833FC4381990e82D7D4a9F4c9B3F67eA941',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5990/thumb/hotbit-token.png?1547041932',
            'coingeckoId': 'hotbit-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOLLY',
            'name': 'BollyCoin',
            'address': '0x6bd361e10c1afed0d95259e7C0115f3A60e4ea99',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21610/thumb/IMG-20211208-124337-701.jpg?1639579540',
            'coingeckoId': 'bollycoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EXCHBEAR',
            'name': '3X Short Exchange Token Index Token',
            'address': '0x6baA91cd8AA07431760EF2eedFedCEF662A6B8B3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10331/thumb/683JEXMN_400x400_%281%29.png?1578052093',
            'coingeckoId': '3x-short-exchange-token-index-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XSR',
            'name': 'Xensor',
            'address': '0x6bC1F3A1ae56231DbB64d3E82E070857EAe86045',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9224/thumb/Xensor_Logo_%28White__200x200px%29.png?1593570861',
            'coingeckoId': 'xensor',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOP',
            'name': 'Drops Ownership Power',
            'address': '0x6bB61215298F296C55b19Ad842D3Df69021DA2ef',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15696/thumb/dop.png?1640325078',
            'coingeckoId': 'drops-ownership-power',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VLT',
            'name': 'Bankroll Vault',
            'address': '0x6b785a0322126826d8226d77e173d75DAfb84d11',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x6b785a0322126826d8226d77e173d75dafb84d11.png',
            'coingeckoId': 'bankroll-vault',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'UBU',
            'name': 'UBU',
            'address': '0x6b74Dd5d01f8320081247F5CF1F7a48324700Db6',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10686/thumb/NFGYEIaA_400x400_%281%29.png?1586763969',
            'coingeckoId': 'ubu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'V',
            'name': 'VendettaDAO',
            'address': '0x6b4FC67b7866121036B7E36BA202B0c6BAb8Cd82',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27029/thumb/Vendetta-DAO-200x200.png?1661493958',
            'coingeckoId': 'vendettadao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZUC',
            'name': 'ZeuxCoin',
            'address': '0x6b4689E4514957699eDeB2Ee91C947F18E439806',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9034/thumb/HVMZgdBY_400x400.jpg?1563831075',
            'coingeckoId': 'zeuxcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TOR',
            'name': 'TOR',
            'address': '0x6b37374FE7980Ae33FC87B0d66A490Ec6903e87a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DECI',
            'name': 'Maximus DECI',
            'address': '0x6b32022693210cD2Cfc466b9Ac0085DE8fC34eA6',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27693/thumb/Deci.png?1665222159',
            'coingeckoId': 'maximus-deci',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BXX',
            'name': 'Baanx',
            'address': '0x6b1a8f210Ec6b7B6643cea3583Fb0c079f367898',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17108/thumb/BXX_Token_logo.png?1626311836',
            'coingeckoId': 'baanx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PETS',
            'name': 'PolkaPet World',
            'address': '0x6afCFF9189e8ed3fCc1CFfa184FEB1276f6A82A5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19409/thumb/pets_polka.PNG?1635211453',
            'coingeckoId': 'polkapet-world',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BERRY',
            'name': 'Rentberry',
            'address': '0x6aEB95F06CDA84cA345c2dE0F3B7f96923a44f4c',
            'decimals': 14,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1787/thumb/rentberry.png?1548608046',
            'coingeckoId': 'rentberry',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EUM',
            'name': 'Elitium',
            'address': '0x6aB4A7d75B0A42B6Bc83E852daB9E121F9C610Aa',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7874/thumb/elitium-coin.png?1551864695',
            'coingeckoId': 'elitium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PAF',
            'name': 'Pacific',
            'address': '0x6a969D379700B2E5EA4E684d273d63C1c050Ba49',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20915/thumb/paf.png?1637922989',
            'coingeckoId': 'pacific',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PDX',
            'name': 'PDX Coin',
            'address': '0x6a8fee0E33Cb65a7E8D21BadCA62E87639ef74B3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25095/thumb/vBi23nWw_400x400.jpg?1650271510',
            'coingeckoId': 'pdx-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TON',
            'name': 'TON',
            'address': '0x6a6c2adA3Ce053561C2FbC3eE211F23d9b8C520a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12334/thumb/ton.jpg?1599128436',
            'coingeckoId': 'tontoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'bDAI',
            'name': 'BTU Incentivized DAI',
            'address': '0x6a4FFAafa8DD400676Df8076AD6c724867b0e2e8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x6a4ffaafa8dd400676df8076ad6c724867b0e2e8.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'LPNT',
            'name': 'Luxurious Pro Network',
            'address': '0x6a4C76874e686A7d080D173987A35A9c48905583',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14126/thumb/logo_%2894%29.png?1614570735',
            'coingeckoId': 'luxurious-pro-network-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FIDU',
            'name': 'Fidu',
            'address': '0x6a445E9F40e0b97c92d0b8a3366cEF1d67F700BF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25944/thumb/GFI-asset-icon.png?1654827482',
            'coingeckoId': 'fidu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AZRX',
            'name': 'Aave ZRX v1',
            'address': '0x6Fb0855c404E09c47C3fBCA25f08d4E41f9F062f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x6fb0855c404e09c47c3fbca25f08d4e41f9f062f.png',
            'coingeckoId': 'aave-zrx-v1',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'B21',
            'name': 'B21',
            'address': '0x6Faa826aF0568d1866Fca570dA79B318ef114dAb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3510/thumb/B21_%283%29.png?1608706191',
            'coingeckoId': 'b21',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TOTM',
            'name': 'TotemFi',
            'address': '0x6FF1BFa14A57594a5874B37ff6AC5efbD9F9599A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14680/thumb/TOTM.png?1617721704',
            'coingeckoId': 'totemfi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AMANA',
            'name': 'Aave MANA v1',
            'address': '0x6FCE4A401B6B80ACe52baAefE4421Bd188e76F6f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x6fce4a401b6b80ace52baaefe4421bd188e76f6f.png',
            'coingeckoId': 'aave-mana-v1',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ROBO',
            'name': 'Robo',
            'address': '0x6FC2f1044A3b9bB3e43A43EC8F840843Ed753061',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14611/thumb/rlogo200.jpg?1617247446',
            'coingeckoId': 'robo-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OATH',
            'name': 'Oath Token',
            'address': '0x6F9C26Fa731C7Ea4139Fa669962Cf8F1CE6C8b0B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TRADE',
            'name': 'Unitrade',
            'address': '0x6F87D756DAf0503d08Eb8993686c7Fc01Dc44fB1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x6f87d756daf0503d08eb8993686c7fc01dc44fb1.png',
            'coingeckoId': 'unitrade',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'LASM',
            'name': 'LasMeta',
            'address': '0x6F66f3012E0210baBD2384e31863C30f70356237',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WPC',
            'name': 'WePiggy Coin',
            'address': '0x6F620EC89B8479e97A6985792d0c64F237566746',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21914/thumb/WPC200.png?1640262945',
            'coingeckoId': 'wepiggy-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORCA',
            'name': 'ORCA Alliance',
            'address': '0x6F59e0461Ae5E2799F1fB3847f05a63B16d0DbF8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/5183.png',
            'coingeckoId': null,
            'listedIn': [
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'CBP',
            'name': 'CashBackPro',
            'address': '0x6F4ee03Ca6c942c9397D2ba5f8F83eA58f918f47',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12893/thumb/logo_%2818%29.png?1603766120',
            'coingeckoId': 'cashbackpro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-13895-SARAT',
            'name': 'RealT   13895 Saratoga St  Detroit  MI ',
            'address': '0x6F442Da588232DC57Bf0096E8dE48D6961D5CC83',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16599/thumb/13895-Saratoga-hero-1.jpeg?1624541955',
            'coingeckoId': 'realtoken-s-13895-saratoga-st-detroit-mi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HATCH',
            'name': 'Hatch DAO',
            'address': '0x6F3009663470475F0749A6b76195375f95495fcB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12526/thumb/hatch-dao.jpg?1600480488',
            'coingeckoId': 'hatch-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CPRO',
            'name': 'Cloud Protocol',
            'address': '0x6F2A550259532F7429530dCB93D86269629e3f2A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21594/thumb/o8UiNqFg_400x400.jpg?1639546169',
            'coingeckoId': 'cloud-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELENA',
            'name': 'Elena Protocol',
            'address': '0x6F0f28CeeE6aE686Ee0f939375674C01b156365a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15078/thumb/elena.png?1619648619',
            'coingeckoId': 'elena-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DIE',
            'name': 'Die Protocol',
            'address': '0x6Ef6610d24593805144d73b13d4405E00A4E4aC7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27903/thumb/newlogo.jpg?1666334549',
            'coingeckoId': 'die-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ABUSD',
            'name': 'Aave BUSD v1',
            'address': '0x6Ee0f7BB50a54AB5253dA0667B0Dc2ee526C30a8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x6ee0f7bb50a54ab5253da0667b0dc2ee526c30a8.png',
            'coingeckoId': 'aave-busd-v1',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'GNX',
            'name': 'Genaro Network',
            'address': '0x6EC8a24CaBdc339A06a172F8223ea557055aDAa5',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1361/thumb/genaro-network.png?1547035387',
            'coingeckoId': 'genaro-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AUR',
            'name': 'Aurix',
            'address': '0x6E98E5401ADCB0D76F4DEBfc3d794B3031F48790',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14372/thumb/gold-brown-2.png?1615769158',
            'coingeckoId': 'aurix',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'KANGAL',
            'name': 'Kangal',
            'address': '0x6E765D26388A17A6e86c49A8E41DF3F58aBcd337',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14241/thumb/logo-200.png?1622341641',
            'coingeckoId': 'kangal',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'CATT',
            'name': 'Catex',
            'address': '0x6E605c269E0C92e70BEeB85486f1fC550f9380BD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6353/thumb/catex.png?1548733307',
            'coingeckoId': 'catex-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAV3',
            'name': 'SAV3',
            'address': '0x6E10AAcb89A28d6FA0FE68790777fec7E7f01890',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13132/thumb/sav3_logo.png?1605536471',
            'coingeckoId': 'sav3',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CNHT',
            'name': 'CNH Tether',
            'address': '0x6E109E9dD7Fa1a58BC3eff667e8e41fC3cc07AEF',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5251/thumb/cny-tether-logo.png?1547040741',
            'coingeckoId': 'cnh-tether',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HNB',
            'name': 'HNB Protocol',
            'address': '0x6E0615a03eD9527a6013FcD5B556E36EF4DaB1FF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26182/thumb/HNB_200x200.png?1656383307',
            'coingeckoId': 'hnb-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RNDR',
            'name': 'Render',
            'address': '0x6De037ef9aD2725EB40118Bb1702EBb27e4Aeb24',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x6de037ef9ad2725eb40118bb1702ebb27e4aeb24.png',
            'coingeckoId': 'render-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'USHI',
            'name': 'Ushi',
            'address': '0x6Dca182ac5E3f99985bC4Ee0f726d6472aB1ec55',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x6dca182ac5e3f99985bc4ee0f726d6472ab1ec55.png',
            'coingeckoId': 'ushi',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-5942-AUDUBO',
            'name': 'RealT   5942 Audubon Rd  Detroit  MI 48',
            'address': '0x6Db6d540F5614e6BaB7475Af3F430F46a0B083e2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16699/thumb/5942-Audubon-HERO-2.jpeg?1624641512',
            'coingeckoId': '5942-audubon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LQTY',
            'name': 'Liquity',
            'address': '0x6DEA81C8171D0bA574754EF6F8b412F2Ed88c54D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x6dea81c8171d0ba574754ef6f8b412f2ed88c54d.png',
            'coingeckoId': 'liquity',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'GENE',
            'name': 'Parkgene',
            'address': '0x6DD4e4Aad29A40eDd6A409b9c1625186C9855b4D',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2298/thumb/gene.png?1547036520',
            'coingeckoId': 'parkgene',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WCHI',
            'name': 'XAYA',
            'address': '0x6DC02164d75651758aC74435806093E421b64605',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/5541.png',
            'coingeckoId': 'chimaera',
            'listedIn': [
                'coingecko',
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'KITSU',
            'name': 'Kitsune Inu',
            'address': '0x6D645193001e674e62aAa0eaC5d56E4D21BBCCeF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://api.rubic.exchange/assets/ethereum/0x6d645193001e674e62aaa0eac5d56e4d21bbccef/logo.png',
            'coingeckoId': 'kitsune-inu',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SKEB',
            'name': 'Skeb',
            'address': '0x6D614686550B9E1c1DF4b2cD8f91C9D4df66C810',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27155/thumb/skebcoin-cmc.png?1662206072',
            'coingeckoId': 'skeb',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CPU',
            'name': 'CPUcoin',
            'address': '0x6D52DfeFb16BB9Cdc78bfCA09061e44574886626',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9544/thumb/uaz.ms.png?1624024119',
            'coingeckoId': 'cpucoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PRT',
            'name': 'Portion',
            'address': '0x6D0F5149c502faf215C89ab306ec3E50b15e2892',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x6d0f5149c502faf215c89ab306ec3e50b15e2892.png',
            'coingeckoId': 'portion',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'KOMET',
            'name': 'Komet',
            'address': '0x6CfB6dF56BbdB00226AeFfCdb2CD1FE8Da1ABdA7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13196/thumb/komet_finance_logo.png?1606120745',
            'coingeckoId': 'komet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEEP',
            'name': 'DeepCloud AI',
            'address': '0x6CbEDEc4F1ac9D874987D2769596544E0d9161ab',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4549/thumb/Deepcloud.png?1564168501',
            'coingeckoId': 'deepcloud-ai',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HINT',
            'name': 'Hintchain',
            'address': '0x6CE21e5f5383c95691d243879A86A6025E0870c0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9329/thumb/JwEnTYRktjoozNKeI6qtQ0CEuiwNhl_vWNoXvob7OO-ZJ7YsXqK6oUmBjdXy0XRTnLcOywgKyWP-3WPcpelcT2ZQyBYoYEGLXA4Q-n6utwbn43oTkW3Ro6Mm93tr9lC1lrb3E4vPTS17TYRvYDP_6c9KLulnS4y_E4FlU9u6Y2FkoYhFdrV9-XF1CPif17GxyVa9vr45xfgGmqmU5.jpg?1566284372',
            'coingeckoId': 'hintchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TREAT',
            'name': 'Good Doge',
            'address': '0x6CDda72FC9D1a8aA0750ac640C5D6a8922De3B72',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27349/thumb/88.png?1663655057',
            'coingeckoId': 'good-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POLAR',
            'name': 'Polar Sync',
            'address': '0x6CD6CB131764c704bA9167C29930Fbdc38901aB7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MIDA',
            'name': 'MIDA',
            'address': '0x6CD13E1856286dE773a435C8Ba93A7C73A04F82d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27337/thumb/_MIDA_dark.png?1663499691',
            'coingeckoId': 'mida',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'UPT',
            'name': 'Universal Protocol Token',
            'address': '0x6CA88Cc8D9288f5cAD825053B6A1B179B05c76fC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10136/thumb/yS35aK0t_400x400_%281%29.jpg?1576191179',
            'coingeckoId': 'universal-protocol-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOOL',
            'name': 'Boolean',
            'address': '0x6C929cdE908481F3d1D775008791F42B1B89DBB0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9888/thumb/booleanSiteLogo.png?1572943862',
            'coingeckoId': 'boolean',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CBAT',
            'name': 'cBAT',
            'address': '0x6C8c6b02E7b2BE14d4fA6022Dfd6d75921D90E4E',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x6c8c6b02e7b2be14d4fa6022dfd6d75921d90e4e.png',
            'coingeckoId': 'compound-basic-attention-token',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ANGEL',
            'name': 'Polylauncher',
            'address': '0x6C7B97c7e09E790D161769a52F155125FAc6d5A1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17739/thumb/Polylauncher_-_200_x_200.png?1629121147',
            'coingeckoId': 'polylauncher',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASUSD',
            'name': 'Aave SUSD',
            'address': '0x6C5024Cd4F8A59110119C56f8933403A539555EB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x6c5024cd4f8a59110119c56f8933403a539555eb.png',
            'coingeckoId': 'aave-susd',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'DPY',
            'name': 'Delphy',
            'address': '0x6C2adC2073994fb2CCC5032cC2906Fa221e9B391',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1133/thumb/delphy.png?1547035133',
            'coingeckoId': 'delphy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALK',
            'name': 'Alkemi Network DAO',
            'address': '0x6C16119B20fa52600230F074b349dA3cb861a7e3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18391/thumb/alkemi-logo-icon-256x256.png?1631760166',
            'coingeckoId': 'alkemi-network-dao-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GAMMA',
            'name': 'Gamma Strategies',
            'address': '0x6BeA7CFEF803D1e3d5f7C0103f7ded065644e197',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x6bea7cfef803d1e3d5f7c0103f7ded065644e197.png',
            'coingeckoId': 'gamma-strategies',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'GAS',
            'name': 'Gas DAO',
            'address': '0x6Bba316c48b49BD1eAc44573c5c871ff02958469',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x6bba316c48b49bd1eac44573c5c871ff02958469.png',
            'coingeckoId': 'gas-dao',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'UP',
            'name': 'UpToken',
            'address': '0x6Ba460AB75Cd2c56343b3517ffeBA60748654D26',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x6ba460ab75cd2c56343b3517ffeba60748654d26.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'BAEPAY',
            'name': 'BAEPAY',
            'address': '0x6BFfa07a1B0ceBC474cE6833eAf2bE6326252449',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13101/thumb/baepay_logo.png?1605150696',
            'coingeckoId': 'baepay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FYZ',
            'name': 'Fyooz',
            'address': '0x6BFf2fE249601ed0Db3a87424a2E923118BB0312',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12202/thumb/fyooz.png?1598017956',
            'coingeckoId': 'fyooz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LNC',
            'name': 'Linker Coin',
            'address': '0x6BEB418Fc6E1958204aC8baddCf109B8E9694966',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3885/thumb/linker-coin.png?1547975979',
            'coingeckoId': 'linker-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GEEQ',
            'name': 'GEEQ',
            'address': '0x6B9f031D718dDed0d681c20cB754F97b3BB81b78',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x6b9f031d718dded0d681c20cb754f97b3bb81b78.png',
            'coingeckoId': 'geeq',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'BFIGHT',
            'name': 'BARFIGHT',
            'address': '0x6B5DDe2c94EcC10C93e8A44288bEFAD2a89bd657',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27491/thumb/Safeimagekit-resized-imgpng_%2819%29.png?1664256020',
            'coingeckoId': 'barfight',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MM',
            'name': 'Million',
            'address': '0x6B4c7A5e3f0B99FCD83e9c089BDDD6c7FCe5c611',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x6b4c7a5e3f0b99fcd83e9c089bddd6c7fce5c611.png',
            'coingeckoId': 'million',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SUSHI',
            'name': 'Sushi',
            'address': '0x6B3595068778DD592e39A122f4f5a5cF09C90fE2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x6b3595068778dd592e39a122f4f5a5cf09c90fe2.png',
            'coingeckoId': 'sushi',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'DAI',
            'name': 'Dai',
            'address': '0x6B175474E89094C44Da98b954EedeAC495271d0F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x6b175474e89094c44da98b954eedeac495271d0f.png',
            'coingeckoId': 'dai',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'optimism',
                'arbitrum_bridge',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ALI',
            'name': 'Alethea Artificial Liquid Intelligence',
            'address': '0x6B0b3a982b4634aC68dD83a4DBF02311cE324181',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22062/thumb/alethea-logo-transparent-colored.png?1642748848',
            'coingeckoId': 'alethea-artificial-liquid-intelligence-token',
            'listedIn': [
                'coingecko',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'LUCKY',
            'name': 'Maximus LUCKY',
            'address': '0x6B0956258fF7bd7645aa35369B55B61b8e6d6140',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27685/thumb/IMG_1129.PNG?1665214648',
            'coingeckoId': 'maximus-lucky',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTCRED',
            'name': 'Bitcoin Red',
            'address': '0x6Aac8CB9861E42bf8259F5AbDC6aE3Ae89909E11',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1054/thumb/bitcoin-red.png?1547034993',
            'coingeckoId': 'bitcoin-red',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SERENE',
            'name': 'Serenity V2',
            'address': '0x6Aa40C573dEf21abD6Fb30a5Fb53749325bC5D65',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SPIRAL',
            'name': 'Up Spiral',
            'address': '0x6AEdb157B9Ca86E32200857AA2579D47098acE39',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26615/thumb/200logo.png?1664098207',
            'coingeckoId': 'up-spiral',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIBDOGE',
            'name': 'ShibaDoge',
            'address': '0x6ADb2E268de2aA1aBF6578E4a8119b960E02928F',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22018/thumb/5HMWoFW0_400x400.jpg?1640596392',
            'coingeckoId': 'shibadoge',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'KEN',
            'name': 'Kenysians Network',
            'address': '0x6A7Ef4998eB9d0f706238756949F311a59E05745',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x6a7ef4998eb9d0f706238756949f311a59e05745.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'CORA',
            'name': 'Corra Finance',
            'address': '0x6A7260e44789AEF24fDaf72D80ecdC253aAa079e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15684/thumb/Corra_C_Logo.png?1631116498',
            'coingeckoId': 'corra-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IBY',
            'name': 'iBetYou',
            'address': '0x6A68DE599E8E0b1856E322CE5Bd11c5C3C79712B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x6A68DE599E8E0b1856E322CE5Bd11c5C3C79712B/logo.png',
            'coingeckoId': 'ibetyou',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'BKBT',
            'name': 'BeeKan   Beenews',
            'address': '0x6A27348483D59150aE76eF4C0f3622A78B0cA698',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5004/thumb/beekan.png?1547040430',
            'coingeckoId': 'beekan',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NRCH',
            'name': 'EnreachDAO',
            'address': '0x69fa8e7F6bf1ca1fB0de61e1366f7412b827CC51',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x69fa8e7f6bf1ca1fb0de61e1366f7412b827cc51.png',
            'coingeckoId': 'enreachdao',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'XRUNE',
            'name': 'Thorstarter',
            'address': '0x69fa0feE221AD11012BAb0FdB45d444D3D2Ce71c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x69fa0feE221AD11012BAb0FdB45d444D3D2Ce71c/logo.png',
            'coingeckoId': 'thorstarter',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic'
            ]
        },
        {
            'symbol': 'OPEN',
            'name': 'OPEN Governance',
            'address': '0x69e8b9528CABDA89fe846C67675B5D73d463a916',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x69e8b9528cabda89fe846c67675b5d73d463a916.png',
            'coingeckoId': 'open-governance-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'YUP',
            'name': 'Yup',
            'address': '0x69bBC3F8787d573F1BBDd0a5f40C7bA0Aee9BCC9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12322/thumb/photo_2021-10-26_00-47-35.jpg?1635227479',
            'coingeckoId': 'yup',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DTA',
            'name': 'DATA',
            'address': '0x69b148395Ce0015C13e36BFfBAd63f49EF874E03',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2604/thumb/data.png?1547036749',
            'coingeckoId': 'data',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MASK',
            'name': 'Mask Network',
            'address': '0x69af81e73A73B40adF4f3d4223Cd9b1ECE623074',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x69af81e73a73b40adf4f3d4223cd9b1ece623074.png',
            'coingeckoId': 'mask-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'optimism',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'KAERI',
            'name': 'Kaeri',
            'address': '0x69Ed89ecd35082E031fE52b75123F801DB083306',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27977/thumb/63370e2a1f2bea31fc7f6977_512x512_v2-p-500-2.png?1666846544',
            'coingeckoId': 'kaeri',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KONGTAMA',
            'name': 'Kongtama',
            'address': '0x69E58172575De6Ff336aDB116A820B2D9a347A32',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24313/thumb/Nl7zwYj.jpeg?1647353276',
            'coingeckoId': 'kongtama',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OOKS',
            'name': 'Onooks',
            'address': '0x69D9905B2e5f6F5433212b7F3c954433F23C1572',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16281/thumb/onooks-logo.png?1623637635',
            'coingeckoId': 'onooks',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-1542-SRIDGE',
            'name': 'RealT   1542 S Ridgeway Ave  Chicago  I',
            'address': '0x69D1B42b20f3DED07bEC322253D0140b04Cbb6F5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16628/thumb/1542-S-Ridgeway-hero-2.5.jpg?1624553308',
            'coingeckoId': '1542-s-ridgeway',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NTK',
            'name': 'Neuro NTK',
            'address': '0x69BEaB403438253f13b6e92Db91F7FB849258263',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3028/thumb/neurotoken.png?1547037334',
            'coingeckoId': 'neurotoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POOLZ',
            'name': 'Poolz Finance',
            'address': '0x69A95185ee2a045CDC4bCd1b1Df10710395e4e23',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x69a95185ee2a045cdc4bcd1b1df10710395e4e23.png',
            'coingeckoId': 'poolz-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'AREN',
            'name': 'Aave REN v1',
            'address': '0x69948cC03f478B95283F7dbf1CE764d0fc7EC54C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x69948cc03f478b95283f7dbf1ce764d0fc7ec54c.png',
            'coingeckoId': 'aave-ren-v1',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'Saja',
            'name': 'Saja',
            'address': '0x698C6aC9CA5f16cAbC5a636D3a619329c0958cBA',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://api.rubic.exchange/assets/ethereum/0x698c6ac9ca5f16cabc5a636d3a619329c0958cba/logo.png',
            'coingeckoId': 'saja',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'TRIBL',
            'name': 'Tribal Token',
            'address': '0x6988A804C74Fd04F37DA1EA4781CEa68c9C00F86',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27774/thumb/TRIBL_Logo_200x200.png?1665724961',
            'coingeckoId': 'tribal-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ISLA',
            'name': 'Insula',
            'address': '0x697eF32B4a3F5a4C39dE1cB7563f24CA7BfC5947',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10884/thumb/isla.PNG?1585522028',
            'coingeckoId': 'insula',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AWG',
            'name': 'AurusGOLD',
            'address': '0x696Acc2De564b48682D71D0847B3632f87c9A402',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11594/thumb/2021-12-06-Aurus-tokens-for-coingecko-AWG-flat-color-v1-r1-AS.png?1640223324',
            'coingeckoId': 'aurusgold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHADS',
            'name': 'CHADS VC',
            'address': '0x69692D3345010a207b759a7D1af6fc7F38b35c5E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12455/thumb/Chad_VC.png?1599940044',
            'coingeckoId': 'chads-vc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IBGBP',
            'name': 'Iron Bank GBP',
            'address': '0x69681f8fde45345C3870BCD5eaf4A05a60E7D227',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18576/thumb/logo-128_%283%29.png?1632470148',
            'coingeckoId': 'iron-bank-gbp',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSGG',
            'name': 'Betswap gg',
            'address': '0x69570f3E84f51Ea70b7B68055c8d667e77735a25',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/17169.png',
            'coingeckoId': 'betswap-gg',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'RouterProtocol',
                'lifinance'
            ]
        },
        {
            'symbol': 'BNS',
            'name': 'BNS Token  OLD ',
            'address': '0x695106Ad73f506f9D0A9650a78019A93149AE07C',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11926/thumb/HS7eNJdt_400x400.jpg?1596170654',
            'coingeckoId': 'bns-token-old',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IZE',
            'name': 'IZE',
            'address': '0x6944d3e38973C4831dA24E954fbD790c7E688bDd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11031/thumb/logo.png?1587294254',
            'coingeckoId': 'ize',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWISS',
            'name': 'Swiss Token',
            'address': '0x692eb773E0b5B7A79EFac5A015C8b36A2577F65c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://api.rubic.exchange/assets/ethereum/0x692eb773e0b5b7a79efac5a015c8b36a2577f65c/logo.png',
            'coingeckoId': 'swiss-finance',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SUNC',
            'name': 'Sunrise',
            'address': '0x692aCCdD8b86692427E0aa4752AE917Df01CC56F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17601/thumb/ticker_sunc_200_200.png?1628864229',
            'coingeckoId': 'sunrise',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SNP',
            'name': 'Synapse Network',
            'address': '0x6911F552842236bd9E8ea8DDBB3fb414e2C5FA9d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17962/thumb/Webp-net-resizeimage_%282%29.png?1629943450',
            'coingeckoId': 'synapse-network',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BULL',
            'name': '3X Long Bitcoin Token',
            'address': '0x68eb95Dc9934E19B86687A10DF8e364423240E94',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9992/thumb/683JEXMN_400x400.png?1574418750',
            'coingeckoId': '3x-long-bitcoin-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SRN',
            'name': 'Sirin Labs',
            'address': '0x68d57c9a1C35f63E2c83eE8e49A64e9d70528D25',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x68d57c9a1c35f63e2c83ee8e49a64e9d70528d25.png',
            'coingeckoId': 'sirin-labs-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'NFTP',
            'name': 'NFT Platform Index',
            'address': '0x68bB81B3F67f7AAb5fd1390ECB0B8e1a806F2465',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14543/thumb/nftp-light-circular.c54a6825.png?1616935648',
            'coingeckoId': 'nft-platform-index',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IQQ',
            'name': 'Iqoniq',
            'address': '0x68a9d92Fe19399FEEBEd6A9a0980a7ea7638074C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14672/thumb/IQONIQ-Cointransparant.png?1617678461',
            'coingeckoId': 'iqoniq',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOON',
            'name': 'MoonSwap',
            'address': '0x68a3637bA6E75c0f66B61A42639c4e9fCD3D4824',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x68a3637ba6e75c0f66b61a42639c4e9fcd3d4824.png',
            'coingeckoId': 'moonswap',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'WCSOV',
            'name': 'CrownSterling',
            'address': '0x68E9C0d9aA450254aed2CD102503d4DFf6B3C37c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25179/thumb/9kL0liy4_400x400.jpg?1650541820',
            'coingeckoId': 'crownsterling',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CMK',
            'name': 'Credmark',
            'address': '0x68CFb82Eacb9f198d508B514d898a403c449533E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x68CFb82Eacb9f198d508B514d898a403c449533E/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'AWO',
            'name': 'AiWork',
            'address': '0x68C85B9f78F30A0df5aC5723e4e700037f185415',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15373/thumb/aiwork.PNG?1620691299',
            'coingeckoId': 'aiwork',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UAXIE',
            'name': 'Unicly Mystic Axies Collection',
            'address': '0x68B1CaDb8D5Ab0C97Fe9D9fbE0Eb60acB329Fe3f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14921/thumb/uaxie-mystic.png?1619055573',
            'coingeckoId': 'unicly-mystic-axies-collection',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BASED',
            'name': 'Based Money',
            'address': '0x68A118Ef45063051Eac49c7e647CE5Ace48a68a5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12115/thumb/Based.png?1597261198',
            'coingeckoId': 'based-money',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EVN',
            'name': 'EvenCoin',
            'address': '0x68909e586eeAC8F47315e84B4c9788DD54Ef65Bb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4634/thumb/evencoin.png?1548125647',
            'coingeckoId': 'evencoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SIG',
            'name': 'Signal SIG',
            'address': '0x6888a16eA9792c15A4DCF2f6C623D055c8eDe792',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3508/thumb/5906667d95a2d43d31378ce7_60x60logo.png?1547038276',
            'coingeckoId': 'signal-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QCH',
            'name': 'QChi',
            'address': '0x687BfC3E73f6af55F0CccA8450114D107E781a0e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5392/thumb/qchi.png?1548607478',
            'coingeckoId': 'qchi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MCT',
            'name': 'MicroTuber',
            'address': '0x6876EbA317272FE221C67405C5e8EB3B24535547',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15489/thumb/mct.PNG?1621040638',
            'coingeckoId': 'microtuber',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XAUT',
            'name': 'Tether Gold',
            'address': '0x68749665FF8D2d112Fa859AA293F07A622782F38',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10481/thumb/Tether_Gold.png?1668148656',
            'coingeckoId': 'tether-gold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LORDS',
            'name': 'LORDS',
            'address': '0x686f2404e77Ab0d9070a46cdfb0B7feCDD2318b0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22171/thumb/Frame_1.png?1642334107',
            'coingeckoId': 'lords',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CAT+',
            'name': 'Capital Aggregator',
            'address': '0x686c77609aFe6A2fac50421888530288B441fbd6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24794/thumb/cat_.png?1648977982',
            'coingeckoId': 'capital-aggregator-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AAB',
            'name': 'AAX',
            'address': '0x686C650dbcFEaa75D09B883621Ad810F5952bD5d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11073/thumb/GluwoJk__400x400.jpg?1587969347',
            'coingeckoId': 'aax-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLO',
            'name': 'Based Loans Ownership',
            'address': '0x68481f2c02BE3786987ac2bC3327171C5D05F9Bd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x68481f2c02BE3786987ac2bC3327171C5D05F9Bd/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'NXL',
            'name': 'Next Level',
            'address': '0x6839aC5Ea8082688B706C773D5d2aFd7d503FDD4',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15178/thumb/NXL-token-logo-200.png?1620026497',
            'coingeckoId': 'next-level',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RPZX',
            'name': 'Rapidz',
            'address': '0x68350d30D9F58C81aaaA41929f1bfC52FFf4Ea49',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7663/thumb/rapidzpay-logo.jpg?1549038850',
            'coingeckoId': 'rapidz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'mooMaiUSDC-miMATIC',
            'name': 'Moo Mai USDC-miMATIC',
            'address': '0x6834a32aFFdd9002Ce828418dDA365496F68A92A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'UMOON',
            'name': 'Unicly MoonCats Collection',
            'address': '0x683239A4caB49642c6E025cF81D283f9c87bC07D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15674/thumb/MoonCats.jpg?1621498102',
            'coingeckoId': 'unicly-mooncats-collection',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GORILLAINU',
            'name': 'Gorilla Inu',
            'address': '0x68257309fCdfE1e6eDb592DA97aC8215fe583908',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19426/thumb/gorilla_logo.png?1635216321',
            'coingeckoId': 'gorilla-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LST',
            'name': 'Luckstar',
            'address': '0x681Ecc5a0bFD18c308A1138fF607F818baC5E417',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7804/thumb/49211002_377935006115216_8098246624284770304_n.png?1550735229',
            'coingeckoId': 'luckstar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GNO',
            'name': 'Gnosis',
            'address': '0x6810e776880C02933D47DB1b9fc05908e5386b96',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x6810e776880c02933d47db1b9fc05908e5386b96.png',
            'coingeckoId': 'gnosis',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'PAR',
            'name': 'Parallel',
            'address': '0x68037790A0229e9Ce6EaA8A99ea92964106C4703',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x68037790a0229e9ce6eaa8a99ea92964106c4703.png',
            'coingeckoId': 'par-stablecoin',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MARK',
            'name': 'Benchmark Protocol',
            'address': '0x67c597624B17b16fb77959217360B7cD18284253',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x67c597624b17b16fb77959217360b7cd18284253.png',
            'coingeckoId': 'benchmark-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'GST',
            'name': 'Game Stars',
            'address': '0x67a9099f0008C35C61c00042cd9Fb03684451097',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4075/thumb/game-stars.png?1548126796',
            'coingeckoId': 'game-stars',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLOKI',
            'name': 'Floki Musk',
            'address': '0x67Cc621AB2d086a101Cff3340df0A065Ac75827c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18850/thumb/1632145919586.png?1633592006',
            'coingeckoId': 'floki-musk',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'G$',
            'name': 'GoodDollar',
            'address': '0x67C5870b4A41D4Ebef24d2456547A03F1f3e094B',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://static.debank.com/image/eth_token/logo_url/0x67c5870b4a41d4ebef24d2456547a03f1f3e094b/7db0aed77313b82cf929faf48b78aa59.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'KYL',
            'name': 'Kylin Network',
            'address': '0x67B6D479c7bB412C54e03dCA8E1Bc6740ce6b99C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14164/thumb/kyl_logo.jpg?1647507720',
            'coingeckoId': 'kylin-network',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'IBETH',
            'name': 'Interest Bearing ETH',
            'address': '0x67B66C99D3Eb37Fa76Aa3Ed1ff33E8e39F0b9c7A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13131/thumb/7675.png?1605535879',
            'coingeckoId': 'interest-bearing-eth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CCO',
            'name': 'Ccore',
            'address': '0x679BADc551626e01B23CeecEFBc9B877EA18fc46',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1287/thumb/LOGO_CCO.png?1547035312',
            'coingeckoId': 'ccore',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KEK',
            'name': 'KeKChain',
            'address': '0x67954768E721FAD0f0f21E33e874497C73ED6a82',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27061/thumb/20220816_155427.png?1661668348',
            'coingeckoId': 'kekchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRTS',
            'name': 'Cratos',
            'address': '0x678e840C640F619E17848045D23072844224dD37',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17322/thumb/cratos.png?1627301460',
            'coingeckoId': 'cratos',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'veKWENTA',
            'name': 'veKwenta',
            'address': '0x6789D8a7a7871923Fc6430432A602879eCB6520a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethereum-optimism.github.io/data/veKWENTA/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'ZAP',
            'name': 'Zap',
            'address': '0x6781a0F84c7E9e846DCb84A9a5bd49333067b104',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2180/thumb/zap.png?1547036476',
            'coingeckoId': 'zap',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'VUSD',
            'name': 'Vesper V Dollar',
            'address': '0x677ddbd918637E5F2c79e164D402454dE7dA8619',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x677ddbd918637e5f2c79e164d402454de7da8619.png',
            'coingeckoId': 'vesper-vdollar',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ERA',
            'name': 'Era',
            'address': '0x67776f14E5E9e7845e33fB0c2a3C709d259D01EB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27718/thumb/ERA_logo.jpg?1665459246',
            'coingeckoId': 'era',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KOACOMBAT',
            'name': 'KoaCombat',
            'address': '0x6769D86f9C430f5AC6d9c861a0173613F1C5544C',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23827/thumb/jGYAi2LB_400x400.jpg?1645515278',
            'coingeckoId': 'koacombat',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TALI',
            'name': 'Talaria Inu',
            'address': '0x6765Fdd028bE3d7874bc2Bb3D7d5CA01c1Bf14b2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21849/thumb/200_%2815%29.png?1640137716',
            'coingeckoId': 'talaria-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CLS',
            'name': 'Coldstack',
            'address': '0x675BBC7514013E2073DB7a919F6e4cbeF576de37',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15499/thumb/logo_200x200.png?1621072403',
            'coingeckoId': 'coldstack',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POA20',
            'name': 'POA',
            'address': '0x6758B7d441a9739b98552B373703d8d3d14f9e62',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x6758b7d441a9739b98552b373703d8d3d14f9e62.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'GWD',
            'name': 'GreenWorld',
            'address': '0x674c964AC0E89d847d6B0aBd144b797bf78BA56b',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27771/thumb/200x200logo.png?1665737292',
            'coingeckoId': 'greenworld',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDN',
            'name': 'Neutrino USD',
            'address': '0x674C6Ad92Fd080e4004b2312b45f796a192D27a0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x674c6ad92fd080e4004b2312b45f796a192d27a0.png',
            'coingeckoId': 'neutrino',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'bbcDAI',
            'name': 'BarnBridge junior cDAI',
            'address': '0x673f9488619821aB4f4155FdFFe06f6139De518F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/bbcdai.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'MSC',
            'name': 'Multi Strategies Capital',
            'address': '0x673A2722e5a8f614bEAA66a2Ba73384D98424d51',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23846/thumb/logo200.png?1645519048',
            'coingeckoId': 'multi-strategies-capital',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MPWR',
            'name': 'ClubRare Empower',
            'address': '0x6731827Cb6879a2091ce3ab3423f7bf20539b579',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25456/thumb/MPWR_200x200.png?1651815610',
            'coingeckoId': 'clubrare-empower',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRED',
            'name': 'Verify',
            'address': '0x672a1AD4f667FB18A333Af13667aa0Af1F5b5bDD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1349/thumb/verify.png?1548759755',
            'coingeckoId': 'verify',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WOWS',
            'name': 'Wolves of Wall Street',
            'address': '0x672EF7E4Fe230B5cA1466C5fDD40588d30FdF90a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14365/thumb/wows_logo.png?1615704838',
            'coingeckoId': 'wolves-of-wall-street',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MFG',
            'name': 'Smart MFG',
            'address': '0x6710c63432A2De02954fc0f851db07146a6c0312',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x6710c63432a2de02954fc0f851db07146a6c0312.png',
            'coingeckoId': 'smart-mfg',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'GOLD',
            'name': 'XBullion',
            'address': '0x670f9D9a26D3D42030794ff035d35a67AA092ead',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15658/thumb/WhatsApp_Image_2021-05-17_at_2.24.16_PM.jpeg?1621484245',
            'coingeckoId': 'xbullion',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UBEX',
            'name': 'Ubex',
            'address': '0x6704B673c70dE9bF74C8fBa4b4bd748F0e2190E1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4163/thumb/ubex.png?1547039421',
            'coingeckoId': 'ubex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LMY',
            'name': 'Lunch Money',
            'address': '0x66fD97a78d8854fEc445cd1C80a07896B0b4851f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x66fd97a78d8854fec445cd1c80a07896b0b4851f.png',
            'coingeckoId': 'lunch-money',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'PACT',
            'name': 'PactSwap',
            'address': '0x66e7CE35578A37209d01F99F3d2fF271f981F581',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15265/thumb/pact.PNG?1620281262',
            'coingeckoId': 'packswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UZZ',
            'name': 'UZURAS',
            'address': '0x66e5D4063219a54A8244078AFFB49E23982D9640',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7306/thumb/uzura.png?1572096519',
            'coingeckoId': 'azuras',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KOIN',
            'name': 'Koinos',
            'address': '0x66d28cb58487a7609877550E1a34691810A6b9FC',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13116/thumb/koinos-mark-circle-250px.png?1614870541',
            'coingeckoId': 'koinos',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUH',
            'name': 'Buhund',
            'address': '0x66bcD0C22Bb15cD1bA7199C7a383DC89528f4427',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27835/thumb/CG.jpeg?1665996180',
            'coingeckoId': 'buhund',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WISE',
            'name': 'Wise',
            'address': '0x66a0f676479Cee1d7373f3DC2e2952778BfF5bd6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x66a0f676479cee1d7373f3dc2e2952778bff5bd6.png',
            'coingeckoId': 'wise-token11',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'DXGM',
            'name': 'DexGame',
            'address': '0x66F73D0fD4161cfad4302DC145Ff994375c13475',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21084/thumb/dxgm-token.png?1638310949',
            'coingeckoId': 'dex-game',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MARS',
            'name': 'Mars',
            'address': '0x66C0DDEd8433c9EA86C8cf91237B14e10b4d70B7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x66c0dded8433c9ea86c8cf91237b14e10b4d70b7.png',
            'coingeckoId': 'mars',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BABYSHIBA',
            'name': 'Baby Shiba Coin',
            'address': '0x66A64a8A9cdc54EF97e45a051bA1c8f3D4b41A1D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22707/thumb/9CmjzSIW_400x400.jpg?1642457763',
            'coingeckoId': 'baby-shiba-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TNT',
            'name': 'Talent',
            'address': '0x6692De64716a177c15360D8d010BC522bBc530a0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25563/thumb/tnt.png?1652521166',
            'coingeckoId': 'talent',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SMSCT',
            'name': 'SMSCodes',
            'address': '0x668f7dfb8c8D716839fa5dBEa317D8723Ebe6110',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16014/thumb/logo200_%2813%29.png?1622609030',
            'coingeckoId': 'smscodes',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BNSD',
            'name': 'BNSD Finance',
            'address': '0x668DbF100635f593A3847c0bDaF21f0a09380188',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12368/thumb/bnsd.png?1599358388',
            'coingeckoId': 'bnsd-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COSHI',
            'name': 'CoShi Inu',
            'address': '0x668C50B1c7f46EFFBE3f242687071d7908AAB00A',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15219/thumb/200.jpg?1620119215',
            'coingeckoId': 'coshi-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AGS',
            'name': 'Collector Coin',
            'address': '0x667Fd83E24Ca1D935d36717D305D54fA0CAC991C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16918/thumb/AGS.png?1625626120',
            'coingeckoId': 'collector-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CPOOL',
            'name': 'Clearpool',
            'address': '0x66761Fa41377003622aEE3c7675Fc7b5c1C2FaC5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19252/thumb/photo_2022-08-31_12.45.02.jpeg?1662105063',
            'coingeckoId': 'clearpool',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TKX',
            'name': 'Tokenize Xchange',
            'address': '0x667102BD3413bFEaa3Dffb48fa8288819E480a88',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4984/thumb/Tokenize.png?1561602968',
            'coingeckoId': 'tokenize-xchange',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WINGS',
            'name': 'Wings',
            'address': '0x667088b212ce3d06a1b553a7221E1fD19000d9aF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x667088b212ce3d06a1b553a7221e1fd19000d9af.png',
            'coingeckoId': 'wings',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'BTSE',
            'name': 'BTSE Token',
            'address': '0x666d875C600AA06AC1cf15641361dEC3b00432Ef',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10807/thumb/BTSE_logo_Square.jpeg?1583965964',
            'coingeckoId': 'btse-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TFG1',
            'name': 'Energoncoin',
            'address': '0x666a64F5567c3145fbA7CA9EF73648Cd4fA2008F',
            'decimals': 8,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': 'energoncoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TAXHAVENINU',
            'name': 'Tax Haven Inu',
            'address': '0x664C6E221c77313307467B121528ad563107bD01',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27362/thumb/tax_haven_inu.png?1663665897',
            'coingeckoId': 'tax-haven-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'URFA',
            'name': 'Sanliurfaspor Token',
            'address': '0x6641B8df62e4b0e00D3b61F8ecA63B2052404FD9',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24816/thumb/urfa.png?1649029396',
            'coingeckoId': 'sanliurfaspor-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORC',
            'name': 'Orbit Chain',
            'address': '0x662b67d00A13FAf93254714DD601F5Ed49Ef2F51',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9782/thumb/-p1Br7oh_400x400.png?1571716593',
            'coingeckoId': 'orbit-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BT',
            'name': 'BitHash',
            'address': '0x6628606c321FaF52b7230A57b26c01B19aA68e82',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13224/thumb/bt.png?1606358296',
            'coingeckoId': 'bithash-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOWS',
            'name': 'Shadows',
            'address': '0x661Ab0Ed68000491d98C796146bcF28c20d7c559',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x661ab0ed68000491d98c796146bcf28c20d7c559.png',
            'coingeckoId': 'shadows',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'MAL',
            'name': 'My Alpha Leaderboard',
            'address': '0x6619078Bdd8324E01E9a8D4b3d761b050E5ECF06',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'MDS',
            'name': 'MediShares',
            'address': '0x66186008C1050627F979d464eABb258860563dbE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1343/thumb/medishares.png?1547978625',
            'coingeckoId': 'medishares',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DVLD',
            'name': 'DIVI LAND',
            'address': '0x66079fb941c03f848f8D6601190C8B876461237A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25559/thumb/dvld.jpg?1652441515',
            'coingeckoId': 'divi-land',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KUN',
            'name': 'Chemix Ecology Governance',
            'address': '0x65d9bC970aA9B2413027fA339F7f179B3F3f2604',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/7721.png',
            'coingeckoId': 'chemix-ecology-governance-token',
            'listedIn': [
                'coingecko',
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'EGG',
            'name': 'Nestree',
            'address': '0x65cCD72c0813CE6f2703593B633202a0F3Ca6a0c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9362/thumb/nestree.png?1566529061',
            'coingeckoId': 'nestree',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ITEN',
            'name': 'ITEN',
            'address': '0x65b678936c489A2639036755Aa2107Ec09569198',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12314/thumb/logo-light_%281%29.png?1599084234',
            'coingeckoId': 'iten',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GFX',
            'name': 'GamyFi',
            'address': '0x65ad6A2288b2Dd23E466226397c8F5D1794e58fC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14559/thumb/circle-cropped_%281%29.png?1617008124',
            'coingeckoId': 'gamyfi-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AMAS',
            'name': 'Amasa',
            'address': '0x65a8fbA02F641a13Bb7B01d5E1129b0521004f52',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18799/thumb/agmqWjv8_400x400.png?1633473272',
            'coingeckoId': 'amasa',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOMEE',
            'name': 'SoMee Social',
            'address': '0x65F9A292f1AEED5D755Aa2fD2Fb17AB2E9431447',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16406/thumb/SoMeeBallLogo200x200.png?1623920285',
            'coingeckoId': 'somee-social',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MULTI',
            'name': 'Multichain',
            'address': '0x65Ef703f5594D2573eb71Aaf55BC0CB548492df4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1669114798325_6203856236857588.png',
            'coingeckoId': 'multichain',
            'listedIn': [
                'coingecko',
                'uniswap',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'PIXEL',
            'name': 'PixelVerse',
            'address': '0x65E6B60Ea01668634D68D0513Fe814679F925BaD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19934/thumb/pixelverse.PNG?1636325521',
            'coingeckoId': 'pixelverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KAE',
            'name': 'Kanpeki',
            'address': '0x65Def5029A0e7591e46B38742bFEdd1Fb7b24436',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x65Def5029A0e7591e46B38742bFEdd1Fb7b24436/logo.png',
            'coingeckoId': 'kanpeki',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'OLY',
            'name': 'Olyverse',
            'address': '0x6595b8fD9C920C81500dCa94e53Cdc712513Fb1f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x6595b8fd9c920c81500dca94e53cdc712513fb1f.png',
            'coingeckoId': 'olyverse',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'GRAIN',
            'name': 'Grain',
            'address': '0x6589fe1271A0F29346796C6bAf0cdF619e25e58e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13331/thumb/784594063499853904.png?1607531032',
            'coingeckoId': 'grain-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PCNT',
            'name': 'Playcent',
            'address': '0x657B83A0336561C8f64389a6f5aDE675C04b0C3b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14335/thumb/1_B5bFcgBld5poUj_c-_K1Jw.png?1615444831',
            'coingeckoId': 'playcent',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNM',
            'name': 'UNIUM',
            'address': '0x6570fFe19dA7e2b425329B157d9109b87f18304b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24567/thumb/Unium_logo.png?1648195706',
            'coingeckoId': 'unium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EFI',
            'name': 'Efinity',
            'address': '0x656C00e1BcD96f256F224AD9112FF426Ef053733',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x656c00e1bcd96f256f224ad9112ff426ef053733.png',
            'coingeckoId': 'efinity',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MOF',
            'name': 'Molecular Future',
            'address': '0x653430560bE843C4a3D143d0110e896c2Ab8ac0D',
            'decimals': 16,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2607/thumb/molecular_future.png?1547036754',
            'coingeckoId': 'molecular-future',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BZN',
            'name': 'Benzene',
            'address': '0x6524B87960c2d573AE514fd4181777E7842435d4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17368/thumb/bzn-logo-200.png?1627437320',
            'coingeckoId': 'benzene',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DYN',
            'name': 'Dynasty Global Investments AG',
            'address': '0x65167E381388Bc803Aa2F22cd99d093068E98007',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20078/thumb/dyn.PNG?1636452679',
            'coingeckoId': 'dynasty-global-investments-ag',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MEOW',
            'name': 'Meowshi',
            'address': '0x650F44eD6F1FE0E1417cb4b3115d52494B4D9b6D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x650F44eD6F1FE0E1417cb4b3115d52494B4D9b6D/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'SOBA',
            'name': 'SOBA',
            'address': '0x65032604Dab202aFf9ADf89300CdB4bD0d059F55',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14673/thumb/soba-logo-black.png?1617680379',
            'coingeckoId': 'soba-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHOP',
            'name': 'Shopping io',
            'address': '0x64b78325d7495D6d4be92f234fa3f3B8d8964B8b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27242/thumb/shop.png?1663123573',
            'coingeckoId': 'shopping-io-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OHM',
            'name': 'Olympus',
            'address': '0x64aa3364F17a4D01c6f1751Fd97C2BD3D7e7f1D5',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x64aa3364f17a4d01c6f1751fd97c2bd3d7e7f1d5.png',
            'coingeckoId': 'olympus',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'CRAMER',
            'name': 'Cramer Coin',
            'address': '0x64Df3aAB3b21cC275bB76c4A581Cf8B726478ee0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27463/thumb/cramercoin.jpg?1664176668',
            'coingeckoId': 'cramer-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASH',
            'name': 'ASH',
            'address': '0x64D91f12Ece7362F91A6f8E7940Cd55F05060b92',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15714/thumb/omnPqaTY.png?1622820503',
            'coingeckoId': 'ash',
            'listedIn': [
                'coingecko',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'IPL',
            'name': 'InsurePal',
            'address': '0x64CdF819d3E75Ac8eC217B3496d7cE167Be42e80',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1858/thumb/ipl.png?1547036172',
            'coingeckoId': 'insurepal',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWISSNFTFUND',
            'name': 'Swiss NFT Fund',
            'address': '0x64C1C9a38038AE73C3699B8dc347fEFDa2221E93',
            'decimals': 9,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': 'swiss-nft-fund',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GRPFT',
            'name': 'Grapefruit Coin',
            'address': '0x64B986211c0CC675143F895C437b79c3cadf364A',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17002/thumb/logo-square-1.png?1626061191',
            'coingeckoId': 'grapefruit-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DAWGS',
            'name': 'SpaceDawgs',
            'address': '0x64A77277e37D44957FE5815d6FF442ab8b16cC29',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17076/thumb/twitter-facebook-Instagram-pfp.png?1646792743',
            'coingeckoId': 'spacedawgs',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'OLT',
            'name': 'OneLedger',
            'address': '0x64A60493D888728Cf42616e034a0dfEAe38EFCF0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3422/thumb/One_Ledger.jpeg?1547038108',
            'coingeckoId': 'one-ledger',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CXADA',
            'name': 'CelsiusX Wrapped ADA',
            'address': '0x64875Aaa68d1d5521666C67d692Ee0B926b08b2F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23536/thumb/cxADA_128x128.png?1644388456',
            'coingeckoId': 'celsiusx-wrapped-ada',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EXRD',
            'name': 'e Radix',
            'address': '0x6468e79A80C0eaB0F9A2B574c8d5bC374Af59414',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x6468e79a80c0eab0f9a2b574c8d5bc374af59414.png',
            'coingeckoId': 'e-radix',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'RIDGE',
            'name': 'Ridge',
            'address': '0x64609A845Ad463d07ee51e91a88D1461C3Dc3165',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20487/thumb/logo_200x200_%289%29.png?1637119058',
            'coingeckoId': 'ridge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RANKER',
            'name': 'RankerDao',
            'address': '0x6431FA4B812a2DCC062A38CB55cc7D18135AdEAd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23902/thumb/ranker.png?1645676549',
            'coingeckoId': 'rankerdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MEEB',
            'name': 'MEEB Vault  NFTX ',
            'address': '0x641927E970222B10b2E8CDBC96b1B4F427316f16',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17044/thumb/Meebs.png?1626147616',
            'coingeckoId': 'meeb-vault-nftx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CVNT',
            'name': 'Conscious Value Network',
            'address': '0x6400B5522f8D448C0803e6245436DD1c81dF09ce',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5158/thumb/cvnt.png?1634711407',
            'coingeckoId': 'content-value-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GAME',
            'name': 'GameCredits',
            'address': '0x63f88A2298a5c4AEE3c216Aa6D926B184a4b2437',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/193/thumb/XlQmXoU.png?1595304945',
            'coingeckoId': 'gamecredits',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAX',
            'name': 'Maxity',
            'address': '0x63f7B1b538a78cB699E5399621B3d2e047C40de4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26933/thumb/MAX_token_200.png?1666345433',
            'coingeckoId': 'maxity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOX',
            'name': 'ContentBox',
            'address': '0x63f584FA56E60e4D0fE8802b27C7e6E3b33E007f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4929/thumb/contentbox.png?1547740046',
            'coingeckoId': 'contentbox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CFI',
            'name': 'CyberFi',
            'address': '0x63b4f3e3fa4e438698CE330e365E831F7cCD1eF4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x63b4f3e3fa4e438698ce330e365e831f7ccd1ef4.png',
            'coingeckoId': 'cyberfi',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'DGCL',
            'name': 'DigiCol',
            'address': '0x63B8b7d4A3EFD0735c4BFFBD95B332a55e4eB851',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13519/thumb/DigiCol_Logo-01.png?1609372199',
            'coingeckoId': 'digicol-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GNBU',
            'name': 'Nimbus Governance',
            'address': '0x639ae8F3EEd18690bF451229d14953a5A5627b72',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16391/thumb/gnbu.PNG?1623898676',
            'coingeckoId': 'nimbus-governance-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PREMIA',
            'name': 'Premia',
            'address': '0x6399C842dD2bE3dE30BF99Bc7D1bBF6Fa3650E70',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x6399c842dd2be3de30bf99bc7d1bbf6fa3650e70.png',
            'coingeckoId': 'premia',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'optimism',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'CIX100',
            'name': 'Cryptoindex com 100',
            'address': '0x6393E822874728f8Afa7e1C9944E417D37CA5878',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6106/thumb/cix.png?1563780642',
            'coingeckoId': 'cryptoindex-io',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SLIME',
            'name': 'SquishiVerse',
            'address': '0x638F24096fF33e047E05E59fabc76325207090dc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25004/thumb/19481.png?1649759045',
            'coingeckoId': 'squishiverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'mooQiMai3Pool',
            'name': 'Moo QiDao MAI+3Pool ',
            'address': '0x636Db84815d6A5af9202CBb44DAd7fd672F178e1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MPH',
            'name': 'Morpher',
            'address': '0x6369c3DadfC00054A42BA8B2c09c48131dd4Aa38',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12619/thumb/morpher_200_200.png?1601524084',
            'coingeckoId': 'morpher',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XTP',
            'name': 'Tap',
            'address': '0x6368e1E18c4C419DDFC608A0BEd1ccb87b9250fc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10291/thumb/0_3SJYkk_400x400.jpg?1577229220',
            'coingeckoId': 'tap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PRBLY',
            'name': 'Probably Nothing',
            'address': '0x6361F338Ab8DEf2AF3f2A1Be7BD8A7dB3156F7E7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20193/thumb/pn.png?1645338665',
            'coingeckoId': 'probably-nothing',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADD',
            'name': 'Add xyz  NEW ',
            'address': '0x635d081fD8F6670135D8a3640E2cF78220787d56',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14351/thumb/ADDBK.png?1622444542',
            'coingeckoId': 'add-xyz-new',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HIMAYC',
            'name': 'hiMAYC',
            'address': '0x635F15eb7Aa2e62d122f6B1f9f519fDCcf4aBdda',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27602/thumb/himayc.png?1664716538',
            'coingeckoId': 'himayc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CORD',
            'name': 'Cordium',
            'address': '0x6347978f54F75d76630b819897F9Ce68C83ce2a2',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28013/thumb/IMG-20221026-WA0025.png?1666947806',
            'coingeckoId': 'cordium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DES',
            'name': 'DeSpace Protocol',
            'address': '0x634239cfA331Df0291653139d1a6083B9cf705e3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18517/thumb/NQQu-EsT_400x400.jpg?1632267572',
            'coingeckoId': 'despace-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PDI',
            'name': 'Phuture DeFi Index',
            'address': '0x632806BF5c8f062932Dd121244c9fbe7becb8B48',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x632806bf5c8f062932dd121244c9fbe7becb8b48.png',
            'coingeckoId': 'phuture-defi-index',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'PEAK',
            'name': 'PEAKDEFI',
            'address': '0x630d98424eFe0Ea27fB1b3Ab7741907DFFEaAd78',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x630d98424efe0ea27fb1b3ab7741907dffeaad78.png',
            'coingeckoId': 'marketpeak',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'GCR',
            'name': 'Global Coin Research',
            'address': '0x6307B25A665Efc992EC1C1bC403c38F3dDd7c661',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14815/thumb/gcr.jpeg?1641461197',
            'coingeckoId': 'global-coin-research',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFEBTC',
            'name': 'SafeBitcoin',
            'address': '0x62d693fE5C13b5A5b24C9ec3F423E51C35F5624F',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14635/thumb/Logo_Black_200x200.png?1631160793',
            'coingeckoId': 'safebitcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EGAME',
            'name': 'Every Game',
            'address': '0x62d3c05b9C3d916FBC111819bbD3CEE52906C1Ae',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20758/thumb/egame.png?1637648306',
            'coingeckoId': 'every-game',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DHV',
            'name': 'DeHive',
            'address': '0x62Dc4817588d53a056cBbD18231d91ffCcd34b2A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x62dc4817588d53a056cbbd18231d91ffccd34b2a.png',
            'coingeckoId': 'dehive',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'AKIHIKO',
            'name': 'Akihiko Inu',
            'address': '0x62Cc7d1790E5a9470BE22Ae9f14065CbFE44BF10',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25669/thumb/__rCQoaf_400x400.jpeg?1653300038',
            'coingeckoId': 'akihiko-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CVXCRV',
            'name': 'Convex CRV',
            'address': '0x62B9c7356A2Dc64a1969e19C23e4f579F9810Aa7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/cvxcrv.jpg',
            'coingeckoId': 'convex-crv',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'rubic'
            ]
        },
        {
            'symbol': 'INNIT',
            'name': 'InnitForTheTECH',
            'address': '0x62A8C2818BD7034DC24CD22368C3E53E8eB47c18',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26724/thumb/INNITlogo.jpg?1659876626',
            'coingeckoId': 'innitforthetech',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TDS',
            'name': 'TokenDesk',
            'address': '0x6295Ab2BE04A617747481B292c390BfcA592Cf28',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1821/thumb/tokendesk.png?1548758709',
            'coingeckoId': 'tokendesk',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DFA',
            'name': 'DeFine',
            'address': '0x62959c699A52eC647622c91e79CE73344e4099f5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17579/thumb/define.PNG?1628558827',
            'coingeckoId': 'define',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SILV',
            'name': 'XBullion Silver',
            'address': '0x628aB8b061FEA2AF1239B68eFA5e46135D186666',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24171/thumb/SILV_Logo_%28transparent_circle%29.png?1646816506',
            'coingeckoId': 'xbullion_silver',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TECH',
            'name': 'Cryptomeda',
            'address': '0x6286A9e6f7e745A6D884561D88F94542d6715698',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17983/thumb/tech.png?1654330771',
            'coingeckoId': 'cryptomeda',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IBVOL',
            'name': 'Inverse Bitcoin Volatility',
            'address': '0x627e2Ee3dbDA546e168eaAFF25A2C5212E4A95a0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11057/thumb/download_%2810%29.png?1587642128',
            'coingeckoId': '1x-short-btc-implied-volatility',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LESS',
            'name': 'LessToken',
            'address': '0x62786Eeacc9246b4018e0146cb7a3efeACD9459D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://api.rubic.exchange/assets/ethereum/0x62786eeacc9246b4018e0146cb7a3efeacd9459d/logo.png',
            'coingeckoId': 'less-network',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'AIOZ',
            'name': 'AIOZ Network',
            'address': '0x626E8036dEB333b408Be468F951bdB42433cBF18',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x626e8036deb333b408be468f951bdb42433cbf18.png',
            'coingeckoId': 'aioz-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'LUSHI',
            'name': 'Lucky Shinu',
            'address': '0x626082f2C5ED30e228f9349a68Ceb155c1c26E2d',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23645/thumb/output-onlinepngtools.png?1644910575',
            'coingeckoId': 'lucky-shinu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASUSD',
            'name': 'Aave SUSD v1',
            'address': '0x625aE63000f46200499120B906716420bd059240',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x625ae63000f46200499120b906716420bd059240.png',
            'coingeckoId': 'aave-susd-v1',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'MXT',
            'name': 'MixTrust',
            'address': '0x6251E725CD45Fb1AF99354035a414A2C0890B929',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x6251e725cd45fb1af99354035a414a2c0890b929.png',
            'coingeckoId': 'mixtrust',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SSP',
            'name': 'Smartshare',
            'address': '0x624d520BAB2E4aD83935Fa503fB130614374E850',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4642/thumb/smartshare.png?1548609894',
            'coingeckoId': 'smartshare',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HTL',
            'name': 'Hotelium',
            'address': '0x6247C86B016Bc4d9aE141849C0a9Eb38C004B742',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8812/thumb/hotelium.PNG?1561539590',
            'coingeckoId': 'hotelium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLX',
            'name': 'Reflexer Ungovernance',
            'address': '0x6243d8CEA23066d098a15582d81a598b4e8391F4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x6243d8cea23066d098a15582d81a598b4e8391f4.png',
            'coingeckoId': 'reflexer-ungovernance-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'FIWT',
            'name': 'Firulais Wallet',
            'address': '0x62406995CaFd18f57e7375e8E0060725Acebce58',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20716/thumb/FIWT-Logo.png?1637584712',
            'coingeckoId': 'firulais-wallet-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CORE',
            'name': 'cVault finance',
            'address': '0x62359Ed7505Efc61FF1D56fEF82158CcaffA23D7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x62359ed7505efc61ff1d56fef82158ccaffa23d7.png',
            'coingeckoId': 'cvault-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'AUTO',
            'name': 'Cube Intelligence',
            'address': '0x622dFfCc4e83C64ba959530A5a5580687a57581b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3503/thumb/2559.png?1547038267',
            'coingeckoId': 'cube-intelligence',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTV',
            'name': 'MultiVAC',
            'address': '0x6226e00bCAc68b0Fe55583B90A1d727C14fAB77f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4937/thumb/MultiVAC.png?1558598585',
            'coingeckoId': 'multivac',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LAR',
            'name': 'LinkArt',
            'address': '0x6226caA1857AFBc6DFB6ca66071Eb241228031A1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x6226caa1857afbc6dfb6ca66071eb241228031a1.png',
            'coingeckoId': 'linkart',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'PBTC',
            'name': 'pTokens BTC',
            'address': '0x62199B909FB8B8cf870f97BEf2cE6783493c4908',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x5228a22e72ccc52d415ecfd199f99d0665e7733b.png',
            'coingeckoId': 'ptokens-btc-2',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'IDK',
            'name': 'IDK',
            'address': '0x61fd1c62551850D0c04C76FcE614cBCeD0094498',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9301/thumb/idk-logo.png?1565861759',
            'coingeckoId': 'idk',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REL',
            'name': 'RELEASE',
            'address': '0x61bFC979EA8160Ede9b862798B7833a97baFa02a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5387/thumb/release.jpg?1547041000',
            'coingeckoId': 'release-ico-project',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOKA',
            'name': 'League of Kingdoms',
            'address': '0x61E90A50137E1F645c9eF4a0d3A4f01477738406',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22572/thumb/loka_64pix.png?1642643271',
            'coingeckoId': 'league-of-kingdoms',
            'listedIn': [
                'coingecko',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'QASH',
            'name': 'QASH',
            'address': '0x618E75Ac90b12c6049Ba3b27f5d5F8651b0037F6',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1220/thumb/qash.jpeg?1547035247',
            'coingeckoId': 'qash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XYZ',
            'name': 'Universe XYZ',
            'address': '0x618679dF9EfCd19694BB1daa8D00718Eacfa2883',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x618679df9efcd19694bb1daa8d00718eacfa2883_1.png',
            'coingeckoId': 'universe-xyz',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'aUniETH',
            'name': 'Aave Interest bearing UniETH',
            'address': '0x6179078872605396Ee62960917128F9477a5DdbB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'KTO',
            'name': 'Kounotori',
            'address': '0x616ef40D55C0D2c506f4d6873Bda8090b79BF8fC',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x616ef40d55c0d2c506f4d6873bda8090b79bf8fc.png',
            'coingeckoId': 'kounotori',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'AURABAL',
            'name': 'Aura BAL',
            'address': '0x616e8BfA43F920657B3497DBf40D6b1A02D4608d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26538/thumb/auraBAL.png?1658721102',
            'coingeckoId': 'aura-bal',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TYT',
            'name': 'Tianya',
            'address': '0x614FD8F06cE4D93AA2361B342C86554eB5CB39f1',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7568/thumb/images_%283%29.png?1548411643',
            'coingeckoId': 'tianya-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLAST',
            'name': 'SafeBlast',
            'address': '0x614D7f40701132E25fe6fc17801Fbd34212d2Eda',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15686/thumb/safeblast.png?1621561206',
            'coingeckoId': 'safeblast',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'LPOOL',
            'name': 'Launchpool',
            'address': '0x6149C26Cd2f7b5CCdb32029aF817123F6E37Df5B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x6149c26cd2f7b5ccdb32029af817123f6e37df5b.png',
            'coingeckoId': 'launchpool',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'CCV2',
            'name': 'CryptoCart V2',
            'address': '0x612E1726435fE38dD49A0B35b4065B56f49c8F11',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15210/thumb/DP7-T6rox-400x400_%281%29.png?1638928576',
            'coingeckoId': 'cryptocart',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'RBN',
            'name': 'Ribbon Finance',
            'address': '0x6123B0049F904d730dB3C36a31167D9d4121fA6B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1635076467753_0303745636106596.png',
            'coingeckoId': 'ribbon-finance',
            'listedIn': [
                'coingecko',
                'uniswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'UMI',
            'name': 'Umi Digital',
            'address': '0x61107a409fFFe1965126aa456Af679719695C69C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17874/thumb/Umi-200-x-200.png?1629700077',
            'coingeckoId': 'umi-digital',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OS',
            'name': 'Ethereans',
            'address': '0x6100dd79fCAA88420750DceE3F735d168aBcB771',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19592/thumb/newOS.png?1635474948',
            'coingeckoId': 'ethereans',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PAGE',
            'name': 'Page',
            'address': '0x60e683C6514Edd5F758A55b6f393BeBBAfaA8d5e',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19015/thumb/Page_Icon_LG.png?1634182470',
            'coingeckoId': 'page',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'xyETH',
            'name': 'xyETH',
            'address': '0x60c779b8348c0a0517F8e2B0489a88CeaF87822F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/279/small/ethereum.png?1595348880',
            'coingeckoId': null,
            'listedIn': [
                'xyfinance'
            ]
        },
        {
            'symbol': 'PERC',
            'name': 'Perion',
            'address': '0x60bE1e1fE41c1370ADaF5d8e66f07Cf1C2Df2268',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23302/thumb/perion.png?1661498749',
            'coingeckoId': 'perion',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'I9X',
            'name': 'i9X Coin',
            'address': '0x60a16b9EfD33bb45c18833AeD45cA66045b3b714',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14124/thumb/unnamed_%282%29.jpg?1614565794',
            'coingeckoId': 'i9x-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLURRY',
            'name': 'Flurry Finance',
            'address': '0x60F63B76E2Fc1649E57a3489162732A90ACf59FE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16235/thumb/flurry_logo_only_200x200.png?1623365114',
            'coingeckoId': 'flurry',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PIKA',
            'name': 'Pika',
            'address': '0x60F5672A271C7E39E787427A18353ba59A4A3578',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14419/thumb/pika-logo-2022-nbg.png?1641971069',
            'coingeckoId': 'pikachu',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'LOCG',
            'name': 'LOCGame',
            'address': '0x60Eb57d085C59932d5fAa6c6026268A4386927d0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15175/thumb/LOC_GAME.png?1620025060',
            'coingeckoId': 'locgame',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOCHI',
            'name': 'Mochi Inu',
            'address': '0x60EF10EDfF6D600cD91caeCA04caED2a2e605Fe5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20164/thumb/4DileY_b_400x400.jpg?1636598711',
            'coingeckoId': 'mochi-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LEMO',
            'name': 'LemoChain',
            'address': '0x60C24407d01782C2175D32fe7C8921ed732371D1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3108/thumb/lemochain.png?1547975597',
            'coingeckoId': 'lemochain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FBX',
            'name': 'Finance Blocks',
            'address': '0x60BB16c4A931b1a0B8A7D945C651DD90f41D42Cf',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24117/thumb/financeblocksshadow.png?1646380305',
            'coingeckoId': 'finance-blocks',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MEME20',
            'name': 'MEME LTD',
            'address': '0x60ACD58d00b2BcC9a8924fdaa54A2F7C0793B3b2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17858/thumb/unnamed_%285%29.jpg?1629682905',
            'coingeckoId': 'meme-ltd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RLC',
            'name': 'iExec RLC',
            'address': '0x607F4C5BB672230e8672085532f7e901544a7375',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x607f4c5bb672230e8672085532f7e901544a7375.png',
            'coingeckoId': 'iexec-rlc',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'PIE',
            'name': 'DeFiPie',
            'address': '0x607C794cDa77efB21F8848B7910ecf27451Ae842',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x607c794cda77efb21f8848b7910ecf27451ae842.png',
            'coingeckoId': 'defipie',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SBRT',
            'name': 'SaveBritney',
            'address': '0x606Ce698Aea1DCa5A2627A4583da13a340667f09',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17140/thumb/4NqipCv__400x400_%281%29.jpg?1626589552',
            'coingeckoId': 'savebritney',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MUSK',
            'name': 'MUSK Gold',
            'address': '0x6069c9223e8a5DA1ec49ac5525d4BB757Af72Cd8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21696/thumb/musk-icon-200x200.png?1649329802',
            'coingeckoId': 'musk-gold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DSU',
            'name': 'Digital Standard Unit',
            'address': '0x605D26FBd5be761089281d5cec2Ce86eeA667109',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17482/thumb/photo_2021-08-03_09-24-16.png?1627953917',
            'coingeckoId': 'digital-standard-unit',
            'listedIn': [
                'coingecko',
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'YRISE',
            'name': 'yRise Finance',
            'address': '0x6051C1354Ccc51b4d561e43b02735DEaE64768B8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12853/thumb/logoyrise-200px.png?1603084410',
            'coingeckoId': 'yrise-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XJP',
            'name': 'eXciting Japan Coin',
            'address': '0x604026696fDB3C6720AE3049C46d59AC604dEA0A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11963/thumb/XJP_LOGO.png?1596453963',
            'coingeckoId': 'exciting-japan-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOPS',
            'name': 'Mops',
            'address': '0x602f65BB8B8098Ad804E99DB6760Fd36208cD967',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26900/thumb/mops.png?1660724129',
            'coingeckoId': 'mops',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EGOLD',
            'name': 'eGold',
            'address': '0x6025F65f6b2f93d8eD1efeDc752acfd4bdbCec3E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1105/thumb/egold.png?1547035084',
            'coingeckoId': 'egold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APXP',
            'name': 'APEX Protocol',
            'address': '0x601a0eE18E9DCdc70658d8B87a748e11344D3A45',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21542/thumb/APXP-logo_200x200.png?1639448329',
            'coingeckoId': 'apex-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETHPY',
            'name': 'Etherpay',
            'address': '0x601938988f0FDd937373Ea185c33751462B1d194',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12417/thumb/Captura-de-Tela-2020-09-09-a-s-13-54-20.png?1599692074',
            'coingeckoId': 'etherpay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHAKE',
            'name': 'Spaceswap SHAKE',
            'address': '0x6006FC2a849fEdABa8330ce36F5133DE01F96189',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x6006fc2a849fedaba8330ce36f5133de01f96189.png',
            'coingeckoId': 'spaceswap-shake',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SHAMAN',
            'name': 'Shaman',
            'address': '0x5fCe9Fc9B5d62aF082A59D0823A062F7529eFA5a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25292/thumb/0v-V3FPz_400x400.jpg?1651139525',
            'coingeckoId': 'shaman',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KYSR',
            'name': 'Kayserispor',
            'address': '0x5fC251C13c4ef172D87a32aB082897132B49435C',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21930/thumb/YQKw1S0__400x400.jpg?1640299486',
            'coingeckoId': 'kayserispor',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTZ',
            'name': 'Bitazza',
            'address': '0x5fBc3cb8B428cC00A04808870295D39962CC7FeE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24597/thumb/BTZ_Logo_%28200x200%29.png?1649777047',
            'coingeckoId': 'bitazza',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GAL',
            'name': 'Galxe',
            'address': '0x5fAa989Af96Af85384b8a938c2EdE4A7378D9875',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24530/thumb/GAL-Token-Icon.png?1651483533',
            'coingeckoId': 'project-galaxy',
            'listedIn': [
                'coingecko',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'FROGEX',
            'name': 'FrogeX',
            'address': '0x5fA54fdDF1870C344DbFaBb37dFab8700Ec0Def1',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14775/thumb/-p8cz7Bk_400x400.png?1618371907',
            'coingeckoId': 'froge-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFTT',
            'name': 'NFT Tech',
            'address': '0x5fA2E9Ba5757504B3d6e8f6da03cc40d4ce19499',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19297/thumb/nft_tech.PNG?1634916467',
            'coingeckoId': 'nft-tech',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUSD',
            'name': 'Liquity USD',
            'address': '0x5f98805A4E8be255a32880FDeC7F6728C6568bA0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x5f98805a4e8be255a32880fdec7f6728c6568ba0.png',
            'coingeckoId': 'liquity-usd',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'optimism',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'GCAKE',
            'name': 'Pancake Games',
            'address': '0x5f944B0c4315Cb7c3a846b025AB4045da44abf6c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20217/thumb/6oc-L2UC_400x400.png?1636671365',
            'coingeckoId': 'pancake-games',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'dSNX',
            'name': 'SNX Debt Mirror',
            'address': '0x5f7F94a1dd7b15594d17543BEB8B30b111DD464c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DMOD',
            'name': 'Demodyfi',
            'address': '0x5f6c5C2fB289dB2228d159C69621215e354218d7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1634697540739_3272200377083141.png',
            'coingeckoId': 'demodyfi',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'RUN',
            'name': '5KM RUN',
            'address': '0x5f4c148D17Effd165C2e2d46b46d2BD6e3eBDC3e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26072/thumb/logo-200.png?1655621986',
            'coingeckoId': '5km-run',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'FSC',
            'name': 'Fileshare Platform',
            'address': '0x5f4361971667C8198706dEc8C59cF7607e405C6e',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28190/thumb/fsc.png?1668325601',
            'coingeckoId': 'fileshare-platform',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLASH',
            'name': 'Flash Loans',
            'address': '0x5f0366c9962193fA774cdce9602195593B49f23C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19827/thumb/flash.PNG?1635979476',
            'coingeckoId': 'flash-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AKM',
            'name': 'COST COIN ',
            'address': '0x5f02cf3c7ada49DFC4A3645Fc85C8aE86808Dd9b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11705/thumb/akm.PNG?1592984686',
            'coingeckoId': 'cost-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHACK',
            'name': 'Shack',
            'address': '0x5f018e73C185aB23647c82bD039e762813877f0e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25699/thumb/shack_no_bg_no_pad3.png?1653441433',
            'coingeckoId': 'shack',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BCHIP',
            'name': 'BLUECHIPS',
            'address': '0x5eF227F7cE4e96c9Ce90E32D4850545a6C5D099B',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7119/thumb/xIh2PBdp_400x400.jpg?1547043616',
            'coingeckoId': 'bluechips-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAITANOBI',
            'name': 'Saitanobi',
            'address': '0x5e9F35E8163c44cD7e606BdD716AbED32AD2F1C6',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23473/thumb/saitanobi.png?1645856764',
            'coingeckoId': 'saitanobi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VNDT',
            'name': 'Vendit',
            'address': '0x5e9997684d061269564F94E5d11Ba6Ce6Fa9528C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18101/thumb/vndt.PNG?1630475586',
            'coingeckoId': 'vendit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SETH',
            'name': 'sETH',
            'address': '0x5e74C9036fb86BD7eCdcb084a0673EFc32eA31cb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x5e74c9036fb86bd7ecdcb084a0673efc32ea31cb.png',
            'coingeckoId': 'seth',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'KATSUMI',
            'name': 'Katsumi',
            'address': '0x5e4Efb364071C64Ee3641fe1E68cB5d2D5558709',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22861/thumb/output-onlinepngtools.png?1642752533',
            'coingeckoId': 'katsumi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LNX',
            'name': 'LNX Protocol',
            'address': '0x5e3845A1d78DB544613EdbE43Dc1Ea497266d3b8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8776/thumb/Linix.png?1561103529',
            'coingeckoId': 'linix',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOC',
            'name': 'LockTrip',
            'address': '0x5e3346444010135322268a4630d2ED5F8D09446c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x5e3346444010135322268a4630d2ed5f8d09446c.png',
            'coingeckoId': 'lockchain',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'DKA',
            'name': 'dKargo',
            'address': '0x5dc60C4D5e75D22588FA17fFEB90A63E535efCE0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11875/thumb/bVD0g0dlmrEOPIkt943KZIBZ086eCshyY0jIQFti4zxYdOlFltU8tKa6uJlcA14HvNjX4bc7dxdMvlpoW5NFMND85oG4aiiCbFRhI6eowDfKEBY3BoSVY0IrBbA9SFGIxh_IYrkNC5uNdG-roZ0_TlGO3098now6Tbzga0p4IDqVk6lnaX3TuRC7pgnAYWZM15RD-uEIHr3O_3OoIIWP-.jpg?1595563347',
            'coingeckoId': 'dkargo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUC',
            'name': 'Level Up Coin',
            'address': '0x5dbe296F97B23C4A6AA6183D73e574D02bA5c719',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1772/thumb/luc.png?1547036065',
            'coingeckoId': 'play2live',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YVAULT-LP-YCURVE',
            'name': 'yUSD',
            'address': '0x5dbcF33D8c2E976c6b560249878e6F1491Bca25c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x5dbcf33d8c2e976c6b560249878e6f1491bca25c.png',
            'coingeckoId': 'yvault-lp-ycurve',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'JPEGS',
            'name': 'IlliquidDAO',
            'address': '0x5dDAFa0856F883A634051dCb4dD710863d85a0c1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22498/thumb/sXd3uwVJ_400x400.png?1641951772',
            'coingeckoId': 'illiquiddao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BRIGHT',
            'name': 'BrightID',
            'address': '0x5dD57Da40e6866C9FcC34F4b6DDC89F1BA740DfE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18415/thumb/bright.PNG?1631841211',
            'coingeckoId': 'bright-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TEA',
            'name': 'Tea',
            'address': '0x5dCEd3c2fab61E21B25177c6050D3f166f696110',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14426/thumb/teachain-logo.png?1616031044',
            'coingeckoId': 'tea-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OKLG',
            'name': 'ok lets go ',
            'address': '0x5dBB9F64cd96E2DbBcA58d14863d615B67B42f2e',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20377/thumb/Screenshot-2021-11-14-at-23-45-11.png?1636950187',
            'coingeckoId': 'ok-lets-go',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAAS',
            'name': 'BaaSid',
            'address': '0x5d929AA919E489505CcAAd8A199619c6DCA0c2de',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5463/thumb/baasid.png?1547041199',
            'coingeckoId': 'baasid',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NAS',
            'name': 'Nebulas',
            'address': '0x5d65D971895Edc438f465c17DB6992698a52318D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2431/thumb/193394331.png?1597976208',
            'coingeckoId': 'nebulas',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MYB',
            'name': 'MyBit',
            'address': '0x5d60d8d7eF6d37E16EBABc324de3bE57f135e0BC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x5d60d8d7ef6d37e16ebabc324de3be57f135e0bc.png',
            'coingeckoId': 'mybit-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'GTCOIN',
            'name': 'Game Tree',
            'address': '0x5d5E244660cA05C42073c9a526616d99f2c99516',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24719/thumb/hUEmunGU_400x400.jpg?1648696738',
            'coingeckoId': 'game-tree',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NCC',
            'name': 'NeuroChain',
            'address': '0x5d48F293BaED247A2D0189058bA37aa238bD4725',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3367/thumb/neurochain.png?1548085917',
            'coingeckoId': 'neurochain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ROG',
            'name': 'ROGin AI',
            'address': '0x5d43b66da68706D39f6C97F7f1415615672b446b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25922/thumb/20490.png?1654672163',
            'coingeckoId': 'rogin-ai',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CDAI',
            'name': 'cDAI',
            'address': '0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x5d3a536e4d6dbd6114cc1ead35777bab948e3643.png',
            'coingeckoId': 'cdai',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'IDEA',
            'name': 'Ideaology',
            'address': '0x5d3a4F62124498092Ce665f865E0b38fF6F5FbEa',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13938/thumb/idea_logo.png?1613022658',
            'coingeckoId': 'ideaology',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PERI',
            'name': 'PERI Finance',
            'address': '0x5d30aD9C6374Bf925D0A75454fa327AACf778492',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15313/thumb/6xVEMS1.png?1620375905',
            'coingeckoId': 'peri-finance',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MIX',
            'name': 'MixMarvel',
            'address': '0x5d285F735998F36631F678FF41fb56A10A4d0429',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8222/thumb/8878caf93b1e3b6cfb3b414bda3b5250.png?1613945432',
            'coingeckoId': 'mixmarvel',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'UWU',
            'name': 'UWU Vault  NFTX ',
            'address': '0x5cE188B44266c7B4bbC67Afa3D16b2eB24eD1065',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18355/thumb/uwu.png?1631604745',
            'coingeckoId': 'uwu-vault-nftx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEV',
            'name': 'Dev Protocol',
            'address': '0x5cAf454Ba92e6F2c929DF14667Ee360eD9fD5b26',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x5caf454ba92e6f2c929df14667ee360ed9fd5b26.png',
            'coingeckoId': 'dev-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'YT',
            'name': 'Cherry YT',
            'address': '0x5c89736e9454200141B80C37Eb28eaCECA2cE8Cb',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8321/thumb/cherry.png?1557480352',
            'coingeckoId': 'cherry-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COT',
            'name': 'CoTrader',
            'address': '0x5c872500c00565505F3624AB435c222E558E9ff8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x5c872500c00565505f3624ab435c222e558e9ff8.png',
            'coingeckoId': 'cotrader',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'CHIZ',
            'name': 'Sewer Rat Social Club CHIZ Token',
            'address': '0x5c761c1a21637362374204000e383204d347064C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19147/thumb/ZXb2HoUG_400x400.jpg?1634534839',
            'coingeckoId': 'sewer-rat-social-club-chiz-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FTB',
            'name': 'Fit Beat',
            'address': '0x5c6Ff62552a25d68D453277dD3693f94C7E8c964',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18871/thumb/ftb.png?1638183072',
            'coingeckoId': 'fit-beat',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'B-80BAL-20WETH',
            'name': 'Balancer 80 BAL 20 WETH',
            'address': '0x5c6Ee304399DBdB9C8Ef030aB642B10820DB8F56',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26452/thumb/0x5c6ee304399dbdb9c8ef030ab642b10820db8f56.png?1658113613',
            'coingeckoId': 'balancer-80-bal-20-weth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VJC',
            'name': 'VENJOCOIN',
            'address': '0x5c62Da804298D5972a323C80B539B8E7517a0dDe',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8088/thumb/vjc-logo.jpg?1554454773',
            'coingeckoId': 'venjocoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORT',
            'name': 'XREATORS',
            'address': '0x5c59a5B139B0538CB106D775a022caD98Dd14b5a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25678/thumb/XREATORS_ORT_Logo.png?1653548310',
            'coingeckoId': 'xreators',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STQ',
            'name': 'Storiqa',
            'address': '0x5c3a228510D246b78a3765C20221Cbf3082b44a4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1929/thumb/storiqa.png?1548611108',
            'coingeckoId': 'storiqa',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '$MF',
            'name': 'MemeForce',
            'address': '0x5c2fCe5a43A56FC158a5a86CAc4FCC4fBDc577F4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WFLOW',
            'name': 'Wrapped Flow',
            'address': '0x5c147e74D63B1D31AA3Fd78Eb229B65161983B2b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x5c147e74d63b1d31aa3fd78eb229b65161983b2b.png',
            'coingeckoId': 'wrapped-flow',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'AAG',
            'name': 'AAG',
            'address': '0x5ba19d656B65f1684cFea4Af428C23B9f3628F97',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21382/thumb/aag-token-icon.png?1661849633',
            'coingeckoId': 'aag-ventures',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BF',
            'name': 'Bitforex',
            'address': '0x5b71BEE9D961b1B848f8485EEC8d8787f80217F5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5330/thumb/BitForex-Logo.png?1573808168',
            'coingeckoId': 'bitforex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DMT',
            'name': 'DarkMatter',
            'address': '0x5b1D655C93185b06B00f7925791106132Cb3ad75',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23989/thumb/dmt.png?1645948367',
            'coingeckoId': 'darkmatter',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TDP',
            'name': 'TrueDeck',
            'address': '0x5b11aAcB6Bddb9ffab908FDCE739Bf4aed554327',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6567/thumb/truedeck.png?1548759129',
            'coingeckoId': 'truedeck',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DTH',
            'name': 'Dether',
            'address': '0x5adc961D6AC3f7062D2eA45FEFB8D8167d44b190',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x5adc961d6ac3f7062d2ea45fefb8d8167d44b190.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'EVED',
            'name': 'Evedo',
            'address': '0x5aaEFe84E0fB3DD1f0fCfF6fA7468124986B91bd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7721/thumb/Variations-09.png?1549979992',
            'coingeckoId': 'evedo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFE',
            'name': 'Safe Token',
            'address': '0x5aFE3855358E112B5647B952709E6165e1c1eEEe',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x5afe3855358e112b5647b952709e6165e1c1eeee.png',
            'coingeckoId': 'safe',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'IMPACTX',
            'name': 'ImpactX',
            'address': '0x5aF6ad286C8Ed6633284f2F135C4716057d52669',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20433/thumb/gen-impact-logo-2-276x300.png?1637035516',
            'coingeckoId': 'impactx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TNGL',
            'name': 'Tangle',
            'address': '0x5aF28EB9deC75e86CDFBD2e41a474b54B211C1C2',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18312/thumb/tangle.PNG?1631510159',
            'coingeckoId': 'tangle',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MANDI',
            'name': 'Mandi',
            'address': '0x5aA485E6b794bcf5F834BF5c7FF43B9B83322764',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11747/thumb/DFE10T9.png?1593414801',
            'coingeckoId': 'mandi-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ESW',
            'name': 'EmiSwap',
            'address': '0x5a75A093747b72a0e14056352751eDF03518031d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17526/thumb/Emiswap_Logo_light_colour_horizontal.png?1628114900',
            'coingeckoId': 'emiswap',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MARSH',
            'name': 'Unmarshal',
            'address': '0x5a666c7d92E5fA7Edcb6390E4efD6d0CDd69cF37',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x5a666c7d92e5fa7edcb6390e4efd6d0cdd69cf37.png',
            'coingeckoId': 'unmarshal',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'DART',
            'name': 'dART Insurance',
            'address': '0x5a4623F305A8d7904ED68638AF3B4328678edDBF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14834/thumb/dart.PNG?1618611161',
            'coingeckoId': 'dart-insurance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWEEP',
            'name': 'Sweep Capital',
            'address': '0x5FDaA123bf828d0D6A1C4Ae62a95A6b3bade57C6',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24007/thumb/logo_sweep_CG.png?1646028643',
            'coingeckoId': 'sweep-capital',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MCDOGE',
            'name': 'McDoge',
            'address': '0x5FDFE5ee55AE0fB7E0dba3481EA46f22fC92cBbB',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23096/thumb/IL1fIBa.png?1643183135',
            'coingeckoId': 'mcdoge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LIQ',
            'name': 'Liquidus',
            'address': '0x5F69b7Ab8F7cAb199a310Fd5A27B43Fef44ddcC0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://static.debank.com/image/eth_token/logo_url/0x5f69b7ab8f7cab199a310fd5a27b43fef44ddcc0/0c283acd66b0e2dcfed28ff2140986ee.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DEXTF',
            'name': 'Domani Protocol',
            'address': '0x5F64Ab1544D28732F0A24F4713c2C8ec0dA089f0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x5f64ab1544d28732f0a24f4713c2c8ec0da089f0.png',
            'coingeckoId': 'dextf',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'DEFX',
            'name': 'DeFinity',
            'address': '0x5F474906637bdCDA05f29C74653F6962bb0f8eDa',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15875/thumb/definity-listing-logo.png?1622414896',
            'coingeckoId': 'definity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOBA',
            'name': 'Bobamask',
            'address': '0x5F20F15d40f24DAe50a72Be3B5EddDDDFb5A5BD0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24072/thumb/200_x_200_logo.png?1651220892',
            'coingeckoId': 'bobatama',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DFH',
            'name': 'DeFiHelper Governance Token',
            'address': '0x5F2080542ab6ae7e0b06778F0B2d263006297840',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://static.debank.com/image/eth_token/logo_url/0x5f2080542ab6ae7e0b06778f0b2d263006297840/4c9c30908fdd558a773fd2ca67835d4c.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ASAN',
            'name': 'ASAN VERSE',
            'address': '0x5F1F11a3dD7a0C39Da1BaA3C7b8585b52a77f435',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28189/thumb/200200_copy.png?1668324848',
            'coingeckoId': 'asan-verse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VENT',
            'name': 'Vent Finance',
            'address': '0x5F0bc16D50F72d10b719dBF6845DE2E599eb5624',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17925/thumb/Artboard_29.png?1629804982',
            'coingeckoId': 'vent-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WHITE',
            'name': 'Whiteheart',
            'address': '0x5F0E628B693018f639D10e4A4F59BD4d8B2B6B44',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x5f0e628b693018f639d10e4a4f59bd4d8b2b6b44.png',
            'coingeckoId': 'whiteheart',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'VPP',
            'name': 'Virtue Poker Points',
            'address': '0x5EeAA2DCb23056F4E8654a349E57eBE5e76b5e6e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3386/thumb/vp-logo-200x200.png?1622068750',
            'coingeckoId': 'virtue-poker',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CSO',
            'name': 'Crespo',
            'address': '0x5Ecb025E51415dbA9Fd272C551076357Cf4069F5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9125/thumb/crespo.png?1564547729',
            'coingeckoId': 'crespo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RAZE',
            'name': 'Raze Network',
            'address': '0x5Eaa69B29f99C84Fe5dE8200340b4e9b4Ab38EaC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14767/thumb/logo-2.png?1623867120',
            'coingeckoId': 'raze-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BQT',
            'name': 'Blockchain Quotations Index',
            'address': '0x5EB87cAA0105a63aa87A36C7Bd2573Bd13E84faE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5814/thumb/blockchain-quotations-index-token.png?1547351058',
            'coingeckoId': 'blockchain-quotations-index-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTR',
            'name': 'BTRIPS',
            'address': '0x5EA82C27efc7634f1C5Ad20a3561c453433a2f3a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19273/thumb/btr.png?1638182009',
            'coingeckoId': 'btrips',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LXTO',
            'name': 'LuxTTO',
            'address': '0x5E92624fF08194ad65C982861F69889b07c3D3e8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26001/thumb/luxtto_logo%28200x200%29.png?1655277426',
            'coingeckoId': 'luxtto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FRXETH',
            'name': 'Frax Ether',
            'address': '0x5E8422345238F34275888049021821E8E08CAa1f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28284/thumb/JjqQ9ROz_400x400.jpeg?1669170320',
            'coingeckoId': 'frax-ether',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ITC',
            'name': 'IoT Chain',
            'address': '0x5E6b6d9aBAd9093fdc861Ea1600eBa1b355Cd940',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1304/thumb/iot-chain-logo.png?1547035331',
            'coingeckoId': 'iot-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DVC',
            'name': 'DragonVein',
            'address': '0x5E6FFe7B174A50c81fF3f3c54c04fD3c11E20830',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8263/thumb/LrDC1OmYiqq05_-E3PFLO6oJou6HOvVMFpjEqbYdcvaIPgQp7mF52lpj_rCmQW1FhAoBg5E2Z9o16L3Z7n8SQrMIp1YZNEGHRzc3P0P3jOZnyKvHNuTXQx9q13_kxXG1oMzaN3HfWdUnTMAfKRwghCa7BX67W9pSV-AJkcQ4-5Qy_qlAN28UCUgVnM0k6ZXlNWZo7Vi00CD7657qw.jpg?1557125797',
            'coingeckoId': 'dragonvein',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ACE',
            'name': 'ACEToken',
            'address': '0x5E492b3B79dF8037A83771d905d057EF1557b72a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TUDA',
            'name': 'Tutor s Diary',
            'address': '0x5E3002dff591C5e75Bb9DEdae268049742E6b13a',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7846/thumb/dbOq7_JEa8Vzq1ljCHJygOrLjk-GmQj3qo3XuwFxa3-vKb9kbe4sFi7msqJE5eRie0WMbcPf29qS_nmNGfI_96lYJZlssCwlk-mDlBoC-LbJNxEBpMFN1gBxZLHzoHtbOn5Yk9BnKhRaz6rKA8QbMWcvXHWjK7hAqOBCqnkHdpQ_iGXHJcMkyWZJ0sMbDR4Lbi9wfrJ2vEurY45q3.jpg?1551149529',
            'coingeckoId': 'tutors-diary',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-11201-COLLE',
            'name': 'RealT   11201 College St  Detroit  MI 4',
            'address': '0x5E2a09064B2DCa8c44aaD8A5b69a69Bb1854fE72',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16594/thumb/11201-College-hero-1.jpg?1624540102',
            'coingeckoId': '11201-college',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DAF',
            'name': 'DaFIN',
            'address': '0x5E031a5bC1c6b6E86A49e0B0F2e757800F1D0FFf',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17378/thumb/dafin.PNG?1627454158',
            'coingeckoId': 'dafin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GMCI',
            'name': 'Game City',
            'address': '0x5Dc74029509752F4ed9A609C2bb52216275E4c1D',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7121/thumb/gamecity.jpg?1612234592',
            'coingeckoId': 'game-city',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOND_finance',
            'name': 'bonded.finance',
            'address': '0x5Dc02Ea99285E17656b8350722694c35154DB1E8',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x5dc02ea99285e17656b8350722694c35154db1e8.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'XBE',
            'name': 'XBE',
            'address': '0x5DE7Cc4BcBCa31c473F6D2F27825Cfb09cc0Bb16',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x5DE7Cc4BcBCa31c473F6D2F27825Cfb09cc0Bb16/logo.png',
            'coingeckoId': 'xbe-token',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'POP',
            'name': 'POP Network',
            'address': '0x5D858bcd53E085920620549214a8b27CE2f04670',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7281/thumb/Logo_%28Light_Mode%29_%281%29.png?1644482888',
            'coingeckoId': 'pop-chest-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RELAY',
            'name': 'Relay Chain',
            'address': '0x5D843Fa9495d23dE997C394296ac7B4D721E841c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17816/thumb/relay-logo-200.png?1629339288',
            'coingeckoId': 'relay-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIN',
            'name': 'MINDOL',
            'address': '0x5D64D850c8368008aFB39224E92aD0DcEFf3CF38',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6366/thumb/mindol-logo.png?1547042468',
            'coingeckoId': 'mindol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NTK',
            'name': 'Netkoin',
            'address': '0x5D4d57cd06Fa7fe99e26fdc481b468f77f05073C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5162/thumb/NTK.png?1606878538',
            'coingeckoId': 'netkoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KAPPA',
            'name': 'Kappa',
            'address': '0x5D2C6545d16e3f927a25b4567E39e2cf5076BeF4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18643/thumb/Wp3ALed.png?1632753579',
            'coingeckoId': 'kappa',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RICKMORTYDOXX',
            'name': 'RickMortyDoxx',
            'address': '0x5D29011D843B0b1760C43E10d66F302174bcCd1A',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19148/thumb/cropped-IMG_20211203_223720_101.png?1643004811',
            'coingeckoId': 'rickmortydoxx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ECASH',
            'name': 'Ethereum Cash',
            'address': '0x5D21eF5f25a985380B65c8e943A0082fEDa0Db84',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1010/thumb/ethereumcash.png?1510040235',
            'coingeckoId': 'ethereum-cash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NKN',
            'name': 'NKN',
            'address': '0x5Cf04716BA20127F1E2297AdDCf4B5035000c9eb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x5cf04716ba20127f1e2297addcf4b5035000c9eb.png',
            'coingeckoId': 'nkn',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BIPS',
            'name': 'Moneybrain BiPS',
            'address': '0x5Cb888182fBfFdb62C08fb4B5a343914F00FdfeE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15556/thumb/bips_logo_200x200_Trans.png?1621204965',
            'coingeckoId': 'moneybrain-bips',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MXC',
            'name': 'MXC',
            'address': '0x5Ca381bBfb58f0092df149bD3D243b08B9a8386e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4604/thumb/mxc.png?1655534336',
            'coingeckoId': 'mxc',
            'listedIn': [
                'coingecko',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'FPI',
            'name': 'Frax Price Index',
            'address': '0x5Ca135cB8527d76e932f34B5145575F9d8cbE08E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24945/thumb/fpi.png?1649485834',
            'coingeckoId': 'frax-price-index',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADOTB',
            'name': 'ANKR Reward Earning DOT',
            'address': '0x5CC56C266143F29a5054b9aE07f3ac3513a7965E',
            'decimals': 10,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26029/thumb/a-dot-b-d58ca40f7b15ef03310fa1faa974a3b0.png?1655349902',
            'coingeckoId': 'ankr-reward-earning-dot',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUSY',
            'name': 'Busy',
            'address': '0x5CB3ce6D081fB00d5f6677d196f2d70010EA3f4a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14966/thumb/busy.PNG?1619165503',
            'coingeckoId': 'busy-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COT',
            'name': 'Cosplay Token',
            'address': '0x5CAc718A3AE330d361e39244BF9e67AB17514CE8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x5cac718a3ae330d361e39244bf9e67ab17514ce8.png',
            'coingeckoId': 'cosplay-token-2',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'AE',
            'name': 'Aeternity',
            'address': '0x5CA9a71B1d01849C0a95490Cc00559717fCF0D1d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1091/thumb/aeternity.png?1547035060',
            'coingeckoId': 'aeternity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RKTN',
            'name': 'RocketToken',
            'address': '0x5CA5a5Efb57dBaF4462eDBD15dA889448b1919ED',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25052/thumb/5F8FyJDt_400x400.jpg?1649925450',
            'coingeckoId': 'rockettoken-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CART',
            'name': 'CryptoArt Ai',
            'address': '0x5C8C8D560048F34E5f7f8ad71f2f81a89DBd273e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17583/thumb/logo-64.png?1629277823',
            'coingeckoId': 'cryptoart-ai',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CUSD',
            'name': 'CUSD',
            'address': '0x5C406D99E04B8494dc253FCc52943Ef82bcA7D75',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x5c406d99e04b8494dc253fcc52943ef82bca7d75.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'CNG',
            'name': 'Changer',
            'address': '0x5C1d9aA868a30795F92fAe903eDc9eFF269044bf',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21786/thumb/cng.png?1640327628',
            'coingeckoId': 'changer',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GTRX',
            'name': 'GTraX',
            'address': '0x5C1a02211072D7633465c64a1eD115D98D987394',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27506/thumb/lcSVRB3TqwxffjniiK5S4n4C4lMRhx0wXIWA02iiQCHvz6sS96X69Iy_kb4YZXpe1OpmJTjE7518Bl6nmFh8BhpB_vTVWzk3pGiEjHJ4dHvQKkioP6xcO7VUCkwj2QqY6iN7j39OjzDp_FuBvN1ua4Ov1wVxxkeC6ozV37cLKcU5uuC0N5gFx6lioJyybw8QQ9rOwSEmdWPpJ5KAMgjr9ii-Hj4GwW3M6q8DsTZHy4gXXvH.jpeg?1664333232',
            'coingeckoId': 'gtrax',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XFI',
            'name': 'Xfinance',
            'address': '0x5BEfBB272290dD5b8521D4a938f6c4757742c430',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12449/thumb/go.png?1599904281',
            'coingeckoId': 'xfinance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BARK',
            'name': 'BarkCoin',
            'address': '0x5BD7EF7113a32b56127ac32272609c42c97849fF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'DUSD',
            'name': 'DefiDollar',
            'address': '0x5BC25f649fc4e26069dDF4cF4010F9f706c23831',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x5bc25f649fc4e26069ddf4cf4010f9f706c23831.png',
            'coingeckoId': 'defidollar',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ONS',
            'name': 'One Share',
            'address': '0x5BB29c33C4A3C29f56F8ACa40B4dB91d8a5fe2c5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13531/thumb/bss.a1671c75.png?1609452258',
            'coingeckoId': 'one-share',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AGIX',
            'name': 'SingularityNET',
            'address': '0x5B7533812759B45C2B44C19e320ba2cD2681b542',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x5b7533812759b45c2b44c19e320ba2cd2681b542.png',
            'coingeckoId': 'singularitynet',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'CMCX',
            'name': 'CORE MultiChain',
            'address': '0x5B685863494C33F344081F75e5430C260c224A32',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18848/thumb/O4IzY2CQ_400x400.png?1633590798',
            'coingeckoId': 'core',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DAD',
            'name': 'DAD',
            'address': '0x5B322514FF727253292637D9054301600c2C81e8',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9833/thumb/q_Vt0ajV_400x400.jpg?1572475136',
            'coingeckoId': 'decentralized-advertising',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NERDY',
            'name': 'Nerdy Inu',
            'address': '0x5B0f6Ad5875DA96Ac224Ba797C6f362F4c3a2b3b',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19517/thumb/nerdy.jpeg?1635322382',
            'coingeckoId': 'nerdy-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAD',
            'name': 'MADNetwork',
            'address': '0x5B09A0371C1DA44A8E24D36Bf5DEb1141a84d875',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x5b09a0371c1da44a8e24d36bf5deb1141a84d875.png',
            'coingeckoId': 'mad-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'HORSE',
            'name': 'Ethorse',
            'address': '0x5B0751713b2527d7f002c0c4e2a37e1219610A6B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2531/thumb/ethorse.png?1548125546',
            'coingeckoId': 'ethorse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ULX',
            'name': 'ULTRON',
            'address': '0x5Aa158404fEd6b4730C13F49d3a7F820e14A636F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26977/thumb/ULX.png?1661168951',
            'coingeckoId': 'ultron',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MESA',
            'name': 'metavisa',
            'address': '0x5AFFF9876C1F98b7d2b53bCB69EB57e92408319F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23246/thumb/twitteravatar_circle.png?1653551813',
            'coingeckoId': 'metavisa',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAT',
            'name': 'Super Athletes Token',
            'address': '0x5ABf88cF3444611D13F6D1B39F3f3EE8575c91a2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27761/thumb/SAT_200.png?1665658758',
            'coingeckoId': 'super-athletes-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IBZ',
            'name': 'Ibiza',
            'address': '0x5AA7C403c7dE4B3bb0cc07079a03e389671a4771',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19349/thumb/AZHnAHiY_400x400.jpg?1635127261',
            'coingeckoId': 'ibiza-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '18C',
            'name': 'Block 18',
            'address': '0x5A9bF6bADCd24Fe0d58E1087290c2FE2c728736a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7605/thumb/block_18.jpg?1548828772',
            'coingeckoId': 'block-18',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LDO',
            'name': 'Lido DAO',
            'address': '0x5A98FcBEA516Cf06857215779Fd812CA3beF1B32',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x5a98fcbea516cf06857215779fd812ca3bef1b32.png',
            'coingeckoId': 'lido-dao',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'optimism',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'CXD',
            'name': 'CortexDAO',
            'address': '0x5A56Da75c50aA2733F5Fa9A2442AaEfcBc60B2e6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25224/thumb/cortex.jpg?1650979502',
            'coingeckoId': 'cortexdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GFT',
            'name': 'Game Federation',
            'address': '0x5A41290104b8f65ECe9Ed9D67A4D82F8080B7310',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26760/thumb/gft.png?1660311385',
            'coingeckoId': 'game-federation',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YFB2',
            'name': 'Yearn Finance Bit2',
            'address': '0x59e7B5DB9Be0BDD26Fa048d39E01FEe456AB674E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13064/thumb/yfb2_logo.png?1604884273',
            'coingeckoId': 'yearn-finance-bit2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIDBULL',
            'name': '3X Long Midcap Index Token',
            'address': '0x59db60bD41bbC8cA4c1EfEE6Ea2A97EAe1E30cF5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10229/thumb/683JEXMN_400x400_%281%29.png?1576618503',
            'coingeckoId': '3x-long-midcap-index-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JUSTICE',
            'name': 'AssangeDAO',
            'address': '0x59d1e836F7b7210A978b25a855085cc46fd090B5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23555/thumb/JUSTICE_token_logo.png?1644532689',
            'coingeckoId': 'assangedao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TROVE',
            'name': 'TroveDAO',
            'address': '0x59c6900949aD1835f07a04321f4d9934a054E114',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24764/thumb/coin.png?1655723800',
            'coingeckoId': 'treasure-token-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GDS',
            'name': 'Golddoge Sachs',
            'address': '0x59a63E3bc9bC1CE9Abfd7b928D13d02d98b818d6',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27022/thumb/IMG_20220824_194242_889.jpg?1661484378',
            'coingeckoId': 'golddoge-sachs',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETHA',
            'name': 'ETHA Lend',
            'address': '0x59E9261255644c411AfDd00bD89162d09D862e38',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14141/thumb/etha_logo200x200.png?1614646986',
            'coingeckoId': 'etha-lend',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BEC',
            'name': 'Betherchip',
            'address': '0x59C033Ec65e6B9C501C1ee34fb42f2575da4B517',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12447/thumb/icone-bth-preta.jpg?1599903494',
            'coingeckoId': 'betherchip',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MGOOGL',
            'name': 'M Google',
            'address': '0x59A921Db27Dd6d4d974745B7FfC5c33932653442',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/mgoogl.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'LOL',
            'name': 'LOLTOKEN',
            'address': '0x5978708d6ccE1CC9640Eed47422D64c91BbD5171',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8551/thumb/logo100.png?1596534732',
            'coingeckoId': 'loltoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POWR',
            'name': 'Power Ledger',
            'address': '0x595832F8FC6BF59c85C527fEC3740A1b7a361269',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x595832f8fc6bf59c85c527fec3740a1b7a361269.png',
            'coingeckoId': 'power-ledger',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'GLDY',
            'name': 'Buzzshow',
            'address': '0x594207C791afd06a8D087d84D99d1DA53CCbD45F',
            'decimals': 3,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2585/thumb/lLY2uKBE_400x400.jpg?1583447824',
            'coingeckoId': 'buzzshow',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XEMX',
            'name': 'Xeniumx',
            'address': '0x59321ace77C8087FF8Cb9F94C8384807E4Fd8a3c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11094/thumb/AmoNWiZ_vx17KF3g6q5RQRaAYrXBXXWjRH5sU2m-1JYt3rJ4c88yo-KS9qKh43lfN2QkuS6AVuYAxcpQQSHQMjqwh-6HsdCyF-yi0xH9Dio5E6UdvNlh0fAMe-RSzp6x2UN6RhAorfUp_wMDDDnHSANWR9iOmVKtemiBLwpUyF-NbY3xdhW5hHAfbARvdnGODq14ujlFu0MJuLi.jpg?1588212665',
            'coingeckoId': 'xeniumx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BDP',
            'name': 'BidiPass',
            'address': '0x593114f03A0A575aece9ED675e52Ed68D2172B8c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3890/thumb/AxiFAoHc_400x400.jpg?1567461770',
            'coingeckoId': 'bidipass',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EXCHBULL',
            'name': '3X Long Exchange Token Index Token',
            'address': '0x592ef68C18F05A22C5890263DEa5D952dd140d2A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10436/thumb/683JEXMN_400x400.png?1579276837',
            'coingeckoId': '3x-long-exchange-token-index-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STN',
            'name': 'Sting',
            'address': '0x592481A5F6b4F078cc303C2cDE4337dFA2d76fA0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13760/thumb/sting.png?1611612491',
            'coingeckoId': 'sting',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XMETA',
            'name': 'TTX Metaverse',
            'address': '0x591127253E40d4f63bF29CcF3D81FD062A149C8c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23332/thumb/xmeta.png?1644907223',
            'coingeckoId': 'ttx-metaverse',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'POLAR',
            'name': 'Polar Sync',
            'address': '0x58fcAa970339a9b1f8c0a5b4F3Fcd7af2bA3075E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18102/thumb/polar-sync-logo-200.jpeg?1668595860',
            'coingeckoId': 'polar-sync',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRL',
            'name': 'Triall',
            'address': '0x58f9102bF53Cf186682Bd9a281d3Cd3c616eEc41',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18679/thumb/-B7ftfN8_400x400.png?1632964301',
            'coingeckoId': 'triall',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GRLC',
            'name': 'Garlicoin',
            'address': '0x58f7345b5295E43aA454911571f13be186655BE9',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2699/thumb/garlicoin.png?1548126827',
            'coingeckoId': 'garlicoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LPT',
            'name': 'Livepeer',
            'address': '0x58b6A8A3302369DAEc383334672404Ee733aB239',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x58b6a8a3302369daec383334672404ee733ab239.png',
            'coingeckoId': 'livepeer',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'MIY',
            'name': 'Icel Idman Yurdu',
            'address': '0x58aaB16f5bA19827505Bb4Fe0a867fA0B7977e72',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25981/thumb/20570.png?1655113476',
            'coingeckoId': 'icel-idman-yurdu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPAY',
            'name': 'SpaceY 2025',
            'address': '0x58FaD9E3C3AE54c9BA98c3f0E4bF88aB3E8Cf3c5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20499/thumb/spacey2025.PNG?1637132488',
            'coingeckoId': 'spacey-2025',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'mooVelodromeMAI-USDC',
            'name': 'Moo Velodrome MAI-USDC',
            'address': '0x58A4E4F9F0BD1BFc2fe287493a130649F349255a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ETHDOX',
            'name': 'ETHDOX',
            'address': '0x5892F5d533E81f9dF91CD4A61886D555BAE6b166',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25352/thumb/ethdox.png?1651311929',
            'coingeckoId': 'ethdox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JPGC',
            'name': 'JPGoldCoin',
            'address': '0x5891599664Ed15C6e88041B4F5bc08594f026f0e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27321/thumb/JPGC_LOGO-_Coingecko.png?1663319056',
            'coingeckoId': 'jpgoldcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BIFI',
            'name': 'beefy.finance',
            'address': '0x5870700f1272a1AdbB87C3140bD770880a95e55D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FIAT',
            'name': 'Fixed Income Asset',
            'address': '0x586Aa273F262909EEF8fA02d90Ab65F5015e0516',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25477/thumb/fiat.png?1652007537',
            'coingeckoId': 'fixed-income-asset-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HEGIC',
            'name': 'Hegic',
            'address': '0x584bC13c7D411c00c01A62e8019472dE68768430',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x584bc13c7d411c00c01a62e8019472de68768430.png',
            'coingeckoId': 'hegic',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'AIDOC',
            'name': 'AI Doctor',
            'address': '0x584B44853680ee34a0F337B712a8f66d816dF151',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2449/thumb/aidoc.png?1547036587',
            'coingeckoId': 'ai-doctor',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHD',
            'name': 'ShardingDAO',
            'address': '0x5845Cd0205b5d43AF695412a79Cf7C1Aeddb060F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14700/thumb/I1QLkPTq_400x400.png?1617836174',
            'coingeckoId': 'shardingdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XRUN',
            'name': 'XRun',
            'address': '0x5833dBB0749887174b254bA4a5df747FF523a905',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25272/thumb/19787.png?1651118626',
            'coingeckoId': 'xrun',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TON',
            'name': 'The Open Network',
            'address': '0x582d872A1B094FC48F5DE31D3B73F2D9bE47def1',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x582d872a1b094fc48f5de31d3b73f2d9be47def1.png',
            'coingeckoId': 'the-open-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MVC',
            'name': 'MileVerse',
            'address': '0x581911b360B6eB3a14eF295a83a91DC2bCE2D6f7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13146/thumb/kXSdwuxD_400x400.jpg?1605668418',
            'coingeckoId': 'mileverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DAWN',
            'name': 'Dawn Protocol',
            'address': '0x580c8520dEDA0a441522AEAe0f9F7A5f29629aFa',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11555/thumb/dawn_protocol.png?1591060256',
            'coingeckoId': 'dawn-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHFT',
            'name': 'Crypto Holding Frank',
            'address': '0x58002A6B6E659A16dE9F02F529B10536E307b0d9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11184/thumb/chft.png?1589523711',
            'coingeckoId': 'crypto-holding-frank-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SYMM',
            'name': 'Symmetric',
            'address': '0x57dB3FfCa78dBbE0eFa0EC745D55f62aa0Cbd345',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18525/thumb/SYMM-Coin-2.png?1632276841',
            'coingeckoId': 'symmetric',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OPENX',
            'name': 'OpenSwap One',
            'address': '0x57d579F483854c62FEf850B8a5332B0d8424b7E2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19274/thumb/X_color.png?1654833718',
            'coingeckoId': 'openswap-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SNAP',
            'name': 'SnapEx',
            'address': '0x57cd5A91c18D21DeDd72d3bcf255b60Aef290f53',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21458/thumb/snap.png?1639291894',
            'coingeckoId': 'snapex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KODACHI',
            'name': 'Kodachi Token',
            'address': '0x57c411e9a358e2d2d0a6b058cEDb709175e8fd16',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26927/thumb/logo200_%282%29.png?1660874765',
            'coingeckoId': 'kodachi-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUILD',
            'name': 'BUILD',
            'address': '0x57b59f981730c6257dF57cF6F0D98283749A9Eeb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26533/thumb/BUILD.png?1658714842',
            'coingeckoId': 'build',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'VDG',
            'name': 'VeriDocGlobal',
            'address': '0x57C75ECCc8557136D32619a191fBCDc88560d711',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5604/thumb/VDG-ICON.png?1547041457',
            'coingeckoId': 'veridocglobal',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POND',
            'name': 'Marlin',
            'address': '0x57B946008913B82E4dF85f501cbAeD910e58D26C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x57b946008913b82e4df85f501cbaed910e58d26c.png',
            'coingeckoId': 'marlin',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SUSD',
            'name': 'sUSD',
            'address': '0x57Ab1ec28D129707052df4dF418D58a2D46d5f51',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x57ab1ec28d129707052df4df418d58a2d46d5f51.png',
            'coingeckoId': 'nusd',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'APEX',
            'name': 'Apex Predator',
            'address': '0x579e5EAfabAe696aAd762aD36E77A16Ae9F0ef91',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24837/thumb/apex.jpg?1649066165',
            'coingeckoId': 'apex-predator',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IQ',
            'name': 'IQ',
            'address': '0x579CEa1889991f68aCc35Ff5c3dd0621fF29b0C9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x579cea1889991f68acc35ff5c3dd0621ff29b0c9.png',
            'coingeckoId': 'everipedia',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'LINKA',
            'name': 'LINKA',
            'address': '0x578B49C45961f98d8DF92854b53F1641AF0A5036',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9487/thumb/C2RRuVH6eVbXIDbew-0VJoGpPb_bMPjRjM9qlYud7tqtGgb1GskioTkIntKuugvXnX-Y3O54L-4zWeWWm_Fffo1kJiqJG5vE4Iu1FSvZ-N750y_tlB7yAGeYztAD-MDrwEPaF3Y9j6E94mPVyKuidqbxf9vBeR5r4GAya3friWZjMzpnJ_q9v0jiIBxEC5YAy1C0Zx6yqVoHJjiTW.jpg?1568013160',
            'coingeckoId': 'linka',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRUST',
            'name': 'TRUST DAO',
            'address': '0x57700244B20f84799a31c6C96DadFF373ca9D6c5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x57700244b20f84799a31c6c96dadff373ca9d6c5.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'BUD',
            'name': 'Buddy',
            'address': '0x57652Fc91f522f9EFF0b38CDF1D51f5FB5764215',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4697/thumb/buddy.jpg?1554103368',
            'coingeckoId': 'buddy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ROX',
            'name': 'Robotina',
            'address': '0x574F84108a98c575794F75483d801d1d5DC861a5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3153/thumb/rox.png?1519831400',
            'coingeckoId': 'robotina',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XIM',
            'name': 'xDollar Interverse Money',
            'address': '0x573d2505a7ee69D136A8667b4Cd915f039AC54e5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BLZ',
            'name': 'Bluzelle',
            'address': '0x5732046A883704404F284Ce41FfADd5b007FD668',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x5732046a883704404f284ce41ffadd5b007fd668.png',
            'coingeckoId': 'bluzelle',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'PIXUL',
            'name': 'Pixul',
            'address': '0x5718aF2DD07cD76ab0606f66565275F6c29f132f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25992/thumb/jHyoPis.png?1655192889',
            'coingeckoId': 'pixul',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DUO',
            'name': 'DUO Network',
            'address': '0x56e0B2C7694E6e10391E870774daA45cf6583486',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4653/thumb/Duo_Network.png?1556593318',
            'coingeckoId': 'duo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RENA',
            'name': 'RENA Finance',
            'address': '0x56de8BC61346321D4F2211e3aC3c0A7F00dB9b76',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15667/thumb/photo_2021-05-21_17-31-35.png?1621825861',
            'coingeckoId': 'rena-finance',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BZRX',
            'name': 'bZx Protocol',
            'address': '0x56d811088235F11C8920698a204A5010a788f4b3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x56d811088235f11c8920698a204a5010a788f4b3.png',
            'coingeckoId': 'bzx-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'HUB',
            'name': 'HubCoin',
            'address': '0x56bd0C900acAF04125Ee26f546d6214634fD970F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25671/thumb/logo-200_%281%29.png?1653358021',
            'coingeckoId': 'hubcoin-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRISM',
            'name': 'Trism',
            'address': '0x56b4F8C39E07D4d5D91692acf9d0F6d4d3493763',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13549/thumb/76106366.png?1609715454',
            'coingeckoId': 'trism',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MBABA',
            'name': 'Mirrored Alibaba',
            'address': '0x56aA298a19C93c6801FDde870fA63EF75Cc0aF72',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/mbaba.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'STACK',
            'name': 'StackOS',
            'address': '0x56A86d648c435DC707c8405B78e2Ae8eB4E60Ba4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x56a86d648c435dc707c8405b78e2ae8eb4e60ba4.png',
            'coingeckoId': 'stackos',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SPLINK',
            'name': 'Space Link',
            'address': '0x56A41Eef4AbA11292c58B39F61Dabc82eD22C79B',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19609/thumb/splink.png?1635925515',
            'coingeckoId': 'space-link',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HSN',
            'name': 'Helper Search',
            'address': '0x567300e14f8d67e1F6720a95291Dce2511a86723',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6463/thumb/hsn.png?1547042670',
            'coingeckoId': 'helper-search-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASW',
            'name': 'AdaSwap',
            'address': '0x56694577564FdD577a0ABB20FE95C1E2756C2a11',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24453/thumb/rA5cmPtX_400x400.jpg?1647677878',
            'coingeckoId': 'adaswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EXZO',
            'name': 'ExzoCoin 2.0',
            'address': '0x56501B0B12Ee9518c2991451Bbc8d7F9267949d2',
            'decimals': 9,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SMHDOGE',
            'name': 'SuperMegaHyperDoge',
            'address': '0x5644bb2B594fcF6F74384D2aD26C68F02a47981C',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21217/thumb/alUgvzB7_400x400.jpg?1638670544',
            'coingeckoId': 'supermegahyperdoge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TVNT',
            'name': 'TravelNote',
            'address': '0x5635ddEaBf9cdDA686995Fe90BEB5411831563FC',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7051/thumb/omwKldFY_400x400.jpg?1547043503',
            'coingeckoId': 'travelnote',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XMU',
            'name': 'Mushe',
            'address': '0x56123908ACA89bf60cE9659aE9F4589E46c8D2cF',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27965/thumb/2.png?1666762043',
            'coingeckoId': 'mushe',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'I7',
            'name': 'ImpulseVen',
            'address': '0x560cC7De81B2A594F6518713cBE122bCF297A6E8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14514/thumb/Impulseven.png?1631092895',
            'coingeckoId': 'impulseven',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CAT',
            'name': 'Mooncat CAT',
            'address': '0x56015BBE3C01fE05bc30A8a9a9Fd9A88917e7dB3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12177/thumb/happy2-2.jpg?1619593488',
            'coingeckoId': 'cat-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-13045-WADE-',
            'name': 'RealT   13045 Wade St  Detroit  MI 4821',
            'address': '0x5600e25b4f24c63Afa655C3bd96E3C178B654Fa1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16617/thumb/13045-Wade-hero-1.jpg?1624549855',
            'coingeckoId': '13045-wade',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BINU',
            'name': 'Bully Inu',
            'address': '0x55d1d16fB42fCE47B899010c996a3a31f6db8fD6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22155/thumb/gM-mFn7L_400x400.jpg?1640941304',
            'coingeckoId': 'bully-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XRPHEDGE',
            'name': '1X Short XRP',
            'address': '0x55b54D8fB1640d1321D5164590e7B020BA43def2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12059/thumb/683JEXMN_400x400.png?1596705201',
            'coingeckoId': '1x-short-xrp-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOO',
            'name': 'Spookyswap',
            'address': '0x55aF5865807b196bD0197e0902746F31FBcCFa58',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15223/thumb/logo_200x200.png?1621992076',
            'coingeckoId': 'spookyswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BITTO',
            'name': 'BITTO',
            'address': '0x55a290f08Bb4CAe8DcF1Ea5635A3FCfd4Da60456',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2814/thumb/BITTO.jpg?1547036986',
            'coingeckoId': 'bitto-exchange',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UWU',
            'name': 'UwU Lend',
            'address': '0x55C08ca52497e2f1534B59E2917BF524D4765257',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27408/thumb/uwu_logo_no-bg_1024.png?1663842922',
            'coingeckoId': 'uwu-lend',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VOLT',
            'name': 'Volt Protocol',
            'address': '0x559eBC30b0E58a45Cc9fF573f77EF1e5eb1b3E18',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25218/thumb/200x200_Volt_logo.png?1650869138',
            'coingeckoId': 'volt-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QI',
            'name': 'QiDao',
            'address': '0x559b7bfC48a5274754b08819F75C5F27aF53D53b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WXBTC',
            'name': 'Wrapped xBTC',
            'address': '0x5592C5Aa89492Ea918d55B804E177B5Ca7dCcd5a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15688/thumb/XBTC-Logo-6.png?1621564837',
            'coingeckoId': 'wrapped-xbtc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RBC',
            'name': 'Ruby Currency',
            'address': '0x5590e22f78441da30463B55c3db0b5eA80cAbccA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17494/thumb/IMG-20210802-213909-942-removebg-preview.png?1627972202',
            'coingeckoId': 'ruby-currency',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BRD',
            'name': 'Bread',
            'address': '0x558EC3152e2eb2174905cd19AeA4e34A23DE9aD6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1440/thumb/bread.png?1547563238',
            'coingeckoId': 'bread',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AUV',
            'name': 'VIIIDA Gold',
            'address': '0x558C7ebb10514A6786D83A26c322d0b53e39D603',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25191/thumb/VG_-_LOGO_AUV.png?1650605583',
            'coingeckoId': 'viiida-gold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REVV',
            'name': 'REVV',
            'address': '0x557B933a7C2c45672B610F8954A3deB39a51A8Ca',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x557b933a7c2c45672b610f8954a3deb39a51a8ca.png',
            'coingeckoId': 'revv',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'PRDX',
            'name': 'Predix Network',
            'address': '0x556148562d5DdeB72545D7EC4B3eC8edc8F55Ba7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x556148562d5ddeb72545d7ec4b3ec8edc8f55ba7.png',
            'coingeckoId': 'predix-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'KAWA',
            'name': 'Kawakami',
            'address': '0x5552E5a89A70cB2eF5AdBbC45a6BE442fE7160Ec',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16369/thumb/kawakami-2022-red-logo.png?1665374338',
            'coingeckoId': 'kawakami',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SMOL',
            'name': 'Smolting Inu',
            'address': '0x553539d40AE81FD0d9C4b991B0b77bE6f6Bc030e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25420/thumb/awL5SVbh_400x400.jpg?1651736210',
            'coingeckoId': 'smolting-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XYO',
            'name': 'XYO Network',
            'address': '0x55296f69f40Ea6d20E478533C15A6B08B654E758',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x55296f69f40ea6d20e478533c15a6b08b654e758.png',
            'coingeckoId': 'xyo-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'IXP',
            'name': 'IMPACTXPRIME',
            'address': '0x54Cb643ab007f47882E8120A8c57B639005c2688',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28128/thumb/IXP_LOGO.jpg?1667641972',
            'coingeckoId': 'impactxprime',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BART',
            'name': 'BarterTrade',
            'address': '0x54C9EA2E9C9E8eD865Db4A4ce6711C2a0d5063Ba',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12142/thumb/200x200-transparent.png?1606958206',
            'coingeckoId': 'bartertrade',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TIC',
            'name': 'Infinite Arcade TIC',
            'address': '0x549E4D92285ff5A16c9484Ff79211E4358b1f202',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25919/thumb/VNReVzc5Sg6NqHONgxEQ_4BI9yq3820sYQx0R.jpg?1654593140',
            'coingeckoId': 'infinite-arcade-tic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UCOIL',
            'name': 'UCONetwork',
            'address': '0x549044000a94870ab7c5017cd8fB0AEfa9239a13',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27633/thumb/logo.png?1664961037',
            'coingeckoId': 'uconetwork',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SIDUS',
            'name': 'Sidus',
            'address': '0x549020a9Cb845220D66d3E9c6D9F9eF61C981102',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21401/thumb/SIDUS_coin_logo.png?1639405031',
            'coingeckoId': 'sidus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TXT',
            'name': 'Taxa Network',
            'address': '0x547b2F82ceCfAb9C2B1D36fddA96eF9F58C63B8C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': 'taxa-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INSUR',
            'name': 'InsurAce',
            'address': '0x544c42fBB96B39B21DF61cf322b5EDC285EE7429',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x544c42fbb96b39b21df61cf322b5edc285ee7429.png',
            'coingeckoId': 'insurace',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'GEN',
            'name': 'DAOstack',
            'address': '0x543Ff227F64Aa17eA132Bf9886cAb5DB55DCAddf',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x543ff227f64aa17ea132bf9886cab5db55dcaddf.png',
            'coingeckoId': 'daostack',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ANARCHY',
            'name': 'Anarchy',
            'address': '0x53fD2342B43eCD24AEf1535BC3797F509616Ce8c',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26583/thumb/90EWu8yp_400x400.jpg?1658891996',
            'coingeckoId': 'anarchy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JCHF',
            'name': 'Jarvis Synthetic Swiss Franc',
            'address': '0x53dfEa0A8CC2A2A2e425E1C174Bc162999723ea0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/jchf.jpg',
            'coingeckoId': 'jarvis-synthetic-swiss-franc',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'WLTC',
            'name': 'Wrapped Litecoin',
            'address': '0x53c4871322Bb47e7A24136fce291a6dcC832a294',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x53c4871322Bb47e7A24136fce291a6dcC832a294/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'DG',
            'name': 'DeGate',
            'address': '0x53C8395465A84955c95159814461466053DedEDE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x53c8395465a84955c95159814461466053dedede.png',
            'coingeckoId': 'degate',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SMBSWAP',
            'name': 'SimbCoin Swap',
            'address': '0x53Bd789F2cDb846b227d8ffc7B46eD4263231FDf',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16808/thumb/smb.png?1625134960',
            'coingeckoId': 'simbcoin-swap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FERA',
            'name': 'Fera',
            'address': '0x539F3615C1dBAfa0D008d87504667458acBd16Fa',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12143/thumb/IMG_20200908_085545_557.jpg?1599563732',
            'coingeckoId': 'fera',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLUE',
            'name': 'Blue Protocol',
            'address': '0x539EfE69bCDd21a83eFD9122571a64CC25e0282b',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1036/thumb/blue-protocol.jpg?1547034969',
            'coingeckoId': 'blue',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AAMMUNIYFIWETH',
            'name': 'Aave AMM UniYFIWETH',
            'address': '0x5394794Be8b6eD5572FCd6b27103F46b5F390E8f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17258/thumb/aAmmUniYFIWETH.png?1626958146',
            'coingeckoId': 'aave-amm-uniyfiweth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHM',
            'name': 'Cryptochrome',
            'address': '0x538a151Dd910C1D1227719bd400D6C4f99ea06d0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13028/thumb/cryptochrome_logo.png?1604461218',
            'coingeckoId': 'cryptochrome',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MNS',
            'name': 'Monnos',
            'address': '0x53884b61963351C283118a8E1Fc05BA464a11959',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11640/thumb/logo_%2880%29.png?1592274153',
            'coingeckoId': 'monnos',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'LOVE',
            'name': 'UkraineDAO Flag NFT',
            'address': '0x5380442d3C4EC4f5777f551f5EDD2FA0F691A27C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24092/thumb/download_%282%29.png?1646344453',
            'coingeckoId': 'ukrainedao-flag-nft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SDOG',
            'name': 'Small Doge',
            'address': '0x537edD52ebcb9F48ff2f8a28c51FCdB9D6a6E0D4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15377/thumb/small_dog.PNG?1620693455',
            'coingeckoId': 'small-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HLNK',
            'name': 'HydroLink',
            'address': '0x536a7BcdccFaff3a9A2Aa765369631Db2F247b09',
            'decimals': 10,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19664/thumb/200x-logo.png?1635736696',
            'coingeckoId': 'hydrolink',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PAN',
            'name': 'Pantos',
            'address': '0x536381a8628dBcC8C70aC9A30A7258442eAb4c92',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9639/thumb/Pantos-logo-compact.png?1619079883',
            'coingeckoId': 'pantos',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSN',
            'name': 'Blockswap Network',
            'address': '0x534D1F5E617e0f72A6b06a04Aa599839AF776A5e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27023/thumb/BSN_brandmark_in_black_circle_preview.png?1661484890',
            'coingeckoId': 'blockswap-network-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LEAP',
            'name': 'LEAP Token',
            'address': '0x53263d9EF74Db583b15fbC6D5D4e8B83833fa134',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27258/thumb/LEAPtoken_LOGO.png?1663053803',
            'coingeckoId': 'leap-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZLW',
            'name': 'Zelwin',
            'address': '0x5319e86F0e41a06E49eb37046b8c11D78bcAd68C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11547/thumb/5614.png?1590991128',
            'coingeckoId': 'zelwin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'renLUNA',
            'name': 'renLUNA',
            'address': '0x52d87F22192131636F93c5AB18d0127Ea52CB641',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x52d87F22192131636F93c5AB18d0127Ea52CB641/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'CIA',
            'name': 'CIA',
            'address': '0x52F4D5EE6c91e01Be67CA1F64b11eD0EE370817d',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21569/thumb/Untitled-design-24.png?1641975750',
            'coingeckoId': 'cia',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APEX',
            'name': 'ApeX',
            'address': '0x52A8845DF664D76C69d2EEa607CD793565aF42B8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25266/thumb/CxpMECpk_400x400_%281%29.png?1651067984',
            'coingeckoId': 'apex-token-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PULSE',
            'name': 'PulseMarkets',
            'address': '0x52A047Ee205701895Ee06A375492490EC9C597cE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19011/thumb/photo_2021-11-11_06-24-09.jpg?1636616734',
            'coingeckoId': 'pulse-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HART',
            'name': 'Hara',
            'address': '0x52928C95C4C7e934E0EfcfAB08853A0E4558861d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5138/thumb/hara.jpg?1547040533',
            'coingeckoId': 'hara-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DXO',
            'name': 'DeepSpace DXO',
            'address': '0x528B3e98c63cE21C6f680b713918E0F89DfaE555',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18068/thumb/dxo.png?1635638762',
            'coingeckoId': 'deepspace-token',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'IDLEUSDCYIELD',
            'name': 'IdleUSDC  Yield ',
            'address': '0x5274891bEC421B39D23760c04A6755eCB444797C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11932/thumb/idleusdcv3_32.png?1596263673',
            'coingeckoId': 'idle-usdc-yield',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MYID',
            'name': 'My Identity Coin',
            'address': '0x5273063725a43A323300C502478C22FbB4e92C2D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15063/thumb/MYID-LOGO.png?1619594142',
            'coingeckoId': 'my-identity-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TENSHI',
            'name': 'Tenshi',
            'address': '0x52662717e448be36Cb54588499D5A8328BD95292',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x52662717e448be36cb54588499d5a8328bd95292.png',
            'coingeckoId': 'tenshi',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'NFTI',
            'name': 'Scalara NFT Index',
            'address': '0x525eF76138Bf76118d786DbedeaE5F87aaBf4a81',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x525ef76138bf76118d786dbedeae5f87aabf4a81.png',
            'coingeckoId': 'scalara-nft-index',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'NYM',
            'name': 'Nym',
            'address': '0x525A8F6F3Ba4752868cde25164382BfbaE3990e1',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24488/thumb/NYM_Token.png?1649926353',
            'coingeckoId': 'nym',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TSHP',
            'name': '12Ships',
            'address': '0x525794473F7ab5715C81d06d10f52d11cC052804',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9351/thumb/12ships.png?1566485390',
            'coingeckoId': '12ships',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HERO',
            'name': 'Challenge Coin',
            'address': '0x524Ebc93BeEf838F70b4Ae54b675d3e971D5884E',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26567/thumb/bug-CC-200.png?1658812509',
            'coingeckoId': 'challenge-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RAY',
            'name': 'Raydium',
            'address': '0x5245C0249e5EEB2A0838266800471Fd32Adb1089',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x5245C0249e5EEB2A0838266800471Fd32Adb1089/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'PBTC',
            'name': 'pTokens BTC  OLD ',
            'address': '0x5228a22e72ccC52d415EcFd199F99D0665E7733b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x5228a22e72ccc52d415ecfd199f99d0665e7733b.png',
            'coingeckoId': 'ptokens-btc',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ANOM',
            'name': 'Anomus Coin',
            'address': '0x5224F552f110eC78E6E0468138950AE5F3040942',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20865/thumb/w07_TWqn_400x400.png?1637807012',
            'coingeckoId': 'anomus-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ERSDL',
            'name': 'unFederalReserve',
            'address': '0x5218E472cFCFE0b64A064F055B43b4cdC9EfD3A6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x5218e472cfcfe0b64a064f055b43b4cdc9efd3a6.png',
            'coingeckoId': 'unfederalreserve',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'DMTR',
            'name': 'Dimitra',
            'address': '0x51cB253744189f11241becb29BeDd3F1b5384fdB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18530/thumb/HqEiru32_400x400.jpg?1632293527',
            'coingeckoId': 'dimitra',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PSDNOCEAN',
            'name': 'Poseidon OCEAN',
            'address': '0x51Fa2efd62ee56a493f24AE963eAce7D0051929E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27859/thumb/psdnOCEAN.png?1666097011',
            'coingeckoId': 'poseidon-ocean',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VPAD',
            'name': 'VLaunch',
            'address': '0x51FE2E572e97BFEB1D719809d743Ec2675924EDc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20838/thumb/vlaunch_22.png?1637738535',
            'coingeckoId': 'vlaunch',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MDA',
            'name': 'Moeda Loyalty Points',
            'address': '0x51DB5Ad35C671a87207d88fC11d593AC0C8415bd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/895/thumb/moeda-loyalty-points.png?1547034720',
            'coingeckoId': 'moeda-loyalty-points',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRYN',
            'name': 'CRYN',
            'address': '0x51Bb3aB03Ab49ec5CB3883705949657838a015FD',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24601/thumb/cryn.png?1648365782',
            'coingeckoId': 'cryn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTCV',
            'name': 'Bitcoin Volatility Index Token',
            'address': '0x51B0bcBEFf204B39Ce792D1E16767Fe6F7631970',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x51b0bcbeff204b39ce792d1e16767fe6f7631970.png',
            'coingeckoId': 'bitcoin-volatility-index-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ZZC',
            'name': 'ZudgeZury',
            'address': '0x51A673e060E1ea091B23B53d192058fFE0F2D6b6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27336/thumb/ZZcoin200x200.jpg?1663483736',
            'coingeckoId': 'zudgezury',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PSWAP',
            'name': 'Polkaswap',
            'address': '0x519C1001D550C0a1DaE7d1fC220f7d14c2A521BB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15475/thumb/pswap-token-logomark-ticker-icon-200px-transparent-optimized.png?1622447028',
            'coingeckoId': 'polkaswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FORGE',
            'name': 'DeFi Forge',
            'address': '0x5198625A8ABf34a0d2A1F262861fF3b3079302bF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28047/thumb/ForgeLogo200x200.jpg?1667274359',
            'coingeckoId': 'defi-forge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YFIV',
            'name': 'Yearn Finance Value',
            'address': '0x519083fc539F23131C3b7046992584592772D12A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13159/thumb/download_%285%29.png?1605770239',
            'coingeckoId': 'yearn-finance-value',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VOL',
            'name': 'Volatility Protocol',
            'address': '0x5166E09628b696285E3A151e84FB977736a83575',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x5166e09628b696285e3a151e84fb977736a83575.png',
            'coingeckoId': 'volatility-protocol-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'AYFI',
            'name': 'Aave YFI',
            'address': '0x5165d24277cD063F5ac44Efd447B27025e888f37',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x5165d24277cd063f5ac44efd447b27025e888f37.png',
            'coingeckoId': 'aave-yfi',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'GDAO',
            'name': 'Governor DAO',
            'address': '0x515d7E9D75E2b76DB60F8a051Cd890eBa23286Bc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x515d7e9d75e2b76db60f8a051cd890eba23286bc.png',
            'coingeckoId': 'governor-dao',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ZDEX',
            'name': 'Zeedex',
            'address': '0x5150956E082C748Ca837a5dFa0a7C10CA4697f9c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12412/thumb/Untitled-design-4.png?1599647173',
            'coingeckoId': 'zeedex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REDPANDA',
            'name': 'RedPanda Earth',
            'address': '0x514cdb9cd8A2fb2BdCf7A3b8DDd098CaF466E548',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x514cdb9cd8a2fb2bdcf7a3b8ddd098caf466e548.png',
            'coingeckoId': 'redpanda-earth',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'LINK',
            'name': 'Chainlink',
            'address': '0x514910771AF9Ca656af840dff83E8264EcF986CA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x514910771af9ca656af840dff83e8264ecf986ca.png',
            'coingeckoId': 'chainlink',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'optimism',
                'arbitrum_bridge',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'LIFT',
            'name': 'Uplift',
            'address': '0x513C3200F227ebB62e3B3d00B7a83779643a71CF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20666/thumb/logo-200x200.png?1657675050',
            'coingeckoId': 'uplift',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'HISQUIGGLE',
            'name': 'hiSQUIGGLE',
            'address': '0x51395AdE06Eae126f590e7B06Dc8F6Baf511f13F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28127/thumb/hisquiggle.png?1667641238',
            'coingeckoId': 'hisquiggle',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IDH',
            'name': 'indaHash',
            'address': '0x5136C98A80811C3f46bDda8B5c4555CFd9f812F0',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1909/thumb/indahash.png?1547973902',
            'coingeckoId': 'indahash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TCS',
            'name': 'Timechain Swap Token ',
            'address': '0x511D35c52a3C244E7b8bd92c0C297755FbD89212',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'LDC',
            'name': 'Lead Coin',
            'address': '0x5102791cA02FC3595398400BFE0e33d7B6C82267',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x5102791ca02fc3595398400bfe0e33d7b6c82267.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'EPS',
            'name': 'Epanus',
            'address': '0x50eC35d1E18D439F02Fa895746FC3e1BEF311780',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12295/thumb/SBqy8NapjZHtkIb4gkQrAXfxFI-j5G4mvfTxCVb2b1HuNGVv0m_6JTq4Ika8BOSHgbWBm0zKSH1dSohftNOaMI00Nb9hW_q5wnMoMi78BIbqTmf810zAHJPpXlFuMhZJ1Q1pUIjyKhb476mFwn5fys_EIF_26GhEE_n9TgAuyMI71hnYDCguzD-ZA7SsMoq.jpg?1598924999',
            'coingeckoId': 'epanus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CV',
            'name': 'carVertical',
            'address': '0x50bC2Ecc0bfDf5666640048038C1ABA7B7525683',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2098/thumb/carvertical.png?1547738903',
            'coingeckoId': 'carvertical',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RAZOR',
            'name': 'Razor Network',
            'address': '0x50DE6856358Cc35f3A9a57eAAA34BD4cB707d2cd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x50de6856358cc35f3a9a57eaaa34bd4cb707d2cd.png',
            'coingeckoId': 'razor-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'FTT',
            'name': 'FTX',
            'address': '0x50D1c9771902476076eCFc8B2A83Ad6b9355a4c9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x50d1c9771902476076ecfc8b2a83ad6b9355a4c9.png',
            'coingeckoId': 'ftx-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'BST',
            'name': 'Blocksquare',
            'address': '0x509A38b7a1cC0dcd83Aa9d06214663D9eC7c7F4a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x509a38b7a1cc0dcd83aa9d06214663d9ec7c7f4a.png',
            'coingeckoId': 'blocksquare',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'CRF',
            'name': 'Crafting Finance',
            'address': '0x508df5aa4746bE37b5b6A69684DfD8BDC322219d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21559/thumb/tokenlogo.png?1639611564',
            'coingeckoId': 'crafting-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KALE',
            'name': 'Bluelight',
            'address': '0x508626d9a29D13EbA26F843A2BD7bF7b00a45be5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x508626d9a29d13eba26f843a2bd7bf7b00a45be5.png',
            'coingeckoId': 'bluelight',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'UART',
            'name': 'UniArts',
            'address': '0x507BDe03A87a6aA134d16634545E3D79c11c137D',
            'decimals': 12,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22092/thumb/3Rx82r_w_400x400.png?1640823145',
            'coingeckoId': 'uniarts',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIBGF',
            'name': 'SHIBGF',
            'address': '0x505a84a03e382331A1Be487b632Cf357748b65d6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19699/thumb/shibgf_logo.png?1637293051',
            'coingeckoId': 'shibgf',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CWEB',
            'name': 'Coinweb',
            'address': '0x505B5eDa5E25a67E1c24A2BF1a527Ed9eb88Bf04',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1641272650134_8834514311784676.png',
            'coingeckoId': 'coinweb',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'CENX',
            'name': 'Centralex',
            'address': '0x504cDe95dBC5d90d09B802F43B371971adbEcf79',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16025/thumb/Centralex_Logo.png?1622618498',
            'coingeckoId': 'centralex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JS',
            'name': 'JavaScript',
            'address': '0x5046E860ff274fb8c66106B0Ffb8155849fB0787',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1061/thumb/js.png?1511578998',
            'coingeckoId': 'javascript-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIWA',
            'name': 'Shiwa',
            'address': '0x5022Cb6D39001CDD6F996e8a66500c86128f1cc4',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28070/thumb/shiwa200.png?1667376980',
            'coingeckoId': 'shiwa',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BRMV',
            'name': 'BRMV',
            'address': '0x501e8726d06Cdef66f3e0cb67f54924CcA1cC894',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14389/thumb/brmv-logo-256px.png?1615856173',
            'coingeckoId': 'brmv-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOLACE',
            'name': 'SOLACE',
            'address': '0x501acE9c35E60f03A2af4d484f49F9B1EFde9f40',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21277/thumb/IMG_6599.png?1638862378',
            'coingeckoId': 'solace',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MUSK',
            'name': 'Musk',
            'address': '0x5003B168b457B663c3c18FFcF5B6a24bEe8f59C7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6614/thumb/1aCM-fLB_400x400.jpg?1547042815',
            'coingeckoId': 'musk',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STEP',
            'name': '1Step.finance',
            'address': '0x50026ad58b338Cf3eccC2b422dEB8Faa725F377F',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x50026ad58b338cf3eccc2b422deb8faa725f377f.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'FROG',
            'name': 'FrogSwap',
            'address': '0x4fEe21439F2b95b72da2F9f901b3956f27fE91D5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21449/thumb/logo.54d7099f.png?1639174332',
            'coingeckoId': 'frogswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NU',
            'name': 'NuCypher',
            'address': '0x4fE83213D56308330EC302a8BD641f1d0113A4Cc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x4fe83213d56308330ec302a8bd641f1d0113a4cc.png',
            'coingeckoId': 'nucypher',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'MO',
            'name': 'MO',
            'address': '0x4fD5b9B5dcc9a5D5931d007ba4aE573e760d9B64',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12961/thumb/Group-9.png?1649143210',
            'coingeckoId': 'mo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XVC',
            'name': 'Xave Coin',
            'address': '0x4fABf135bCF8111671870d4399af739683198f96',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24084/thumb/k8qjP9t9_400x400.jpg?1646292464',
            'coingeckoId': 'xave-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TETU',
            'name': 'TETU Reward Token',
            'address': '0x4f851750a3e6f80f1E1f89C67B56960Bfc29A934',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'XDG',
            'name': 'Decentral Games Governance',
            'address': '0x4f81C790581b240A5C948Afd173620EcC8C71c8d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21176/thumb/xDG_Logo.png?1638508706',
            'coingeckoId': 'decentral-games-governance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DBS',
            'name': 'Decentralized Business Systems',
            'address': '0x4f7AFf8f0c78B51c0E30F02f27a67B5A6A11552b',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25489/thumb/27e0a1_7f0b762e0eeb4584af7cff0fdccedc8e_mv2.png?1652856877',
            'coingeckoId': 'decentralized-business-systems',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORE',
            'name': 'ORE Network',
            'address': '0x4f640F2529ee0cF119A2881485845FA8e61A782A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x4f640f2529ee0cf119a2881485845fa8e61a782a.png',
            'coingeckoId': 'ptokens-ore',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'GUM',
            'name': 'Gourmet Galaxy',
            'address': '0x4f5fa8f2d12e5eB780f6082Dd656C565C48E0f24',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x4f5fa8f2d12e5eb780f6082dd656c565c48e0f24.png',
            'coingeckoId': 'gourmetgalaxy',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'TOR',
            'name': 'Torchain',
            'address': '0x4f5f2EEA4ED3485E5e23a39704d5fD9d0A423886',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8636/thumb/Z01mRB2BHvjsIsR95aZ4l8UTF87S5JVTgTvvdC5PVpEPaFXOoXuYwdKvEDiK3o5s-tx-o-S5KW1y2zgwn-9ftk6QUfNQsc_gnzrbvfbIgbm-_FYNoTG50PHc59XU32p6vihaXFYUnHZ9cMCHzGvq9fSqKRul9AEiUe0M9SkS65UNuL--P1ulJtc7r-rQTZpPZ3TS0VhXSGPsdwMglbhIHQDs_.jpg?1566612218',
            'coingeckoId': 'torchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DGX',
            'name': 'Digix Gold',
            'address': '0x4f3AfEC4E5a3F2A6a1A411DEF7D7dFe50eE057bF',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x4f3afec4e5a3f2a6a1a411def7d7dfe50ee057bf.png',
            'coingeckoId': 'digix-gold',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'BITE',
            'name': 'DragonBite',
            'address': '0x4eED0fa8dE12D5a86517f214C2f11586Ba2ED88D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16386/thumb/dragonbite.PNG?1623893567',
            'coingeckoId': 'dragonbite',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MBBT',
            'name': 'MeebitsDAO Pool',
            'address': '0x4eE4f96838454E67Fce92B2c53b0f1a97D047179',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20986/thumb/ZgVuisG__400x400.jpg?1638168731',
            'coingeckoId': 'meebitsdao-pool',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YAE',
            'name': 'Cryptonovae',
            'address': '0x4eE438be38F8682ABB089F2BFeA48851C5E71EAF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14693/thumb/yae.png?1640337904',
            'coingeckoId': 'cryptonovae',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'FLY',
            'name': 'Flycoin FLY',
            'address': '0x4e568ab95F029E8dF1e39b30c9d6D076eaA15945',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26596/thumb/flatArtboard_20tfr.png?1658974032',
            'coingeckoId': 'flycoin-fly',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIT',
            'name': 'I will poop it NFT',
            'address': '0x4e4a47cAC6A28A62dcC20990ed2cdA9BC659469F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25945/thumb/PCNoOl8.png?1654833800',
            'coingeckoId': 'i-will-poop-it-nft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QDT',
            'name': 'QChain QDT',
            'address': '0x4e432A62733a7ee38Ad2e16b3cc0731457eA5B55',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26502/thumb/qdt_logo.png?1658366015',
            'coingeckoId': 'qchain-qdt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CVX',
            'name': 'Convex Finance',
            'address': '0x4e3FBD56CD56c3e72c1403e103b45Db9da5B9D2B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b.png',
            'coingeckoId': 'convex-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'MCB',
            'name': 'MUX Protocol',
            'address': '0x4e352cF164E64ADCBad318C3a1e222E9EBa4Ce42',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x4e352cf164e64adcbad318c3a1e222e9eba4ce42.png',
            'coingeckoId': 'mcdex',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'LST',
            'name': 'Lendroid Support',
            'address': '0x4de2573e27E648607B50e1Cfff921A33E4A34405',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x4de2573e27e648607b50e1cfff921a33e4a34405.png',
            'coingeckoId': 'lendroid-support-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'PRINTS',
            'name': 'FingerprintsDAO',
            'address': '0x4dd28568D05f09b02220b09C2cb307bFd837cb95',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18143/thumb/prints.png?1634698868',
            'coingeckoId': 'fingerprints',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ONEICHI',
            'name': 'oneICHI',
            'address': '0x4db2c02831c9ac305FF9311Eb661f80f1dF61e07',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25908/thumb/74116843.png?1654585863',
            'coingeckoId': 'oneichi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'stkAAVE',
            'name': 'Staked Aave',
            'address': '0x4da27a545c0c5B758a6BA100e3a049001de870f5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://static.debank.com/image/eth_token/logo_url/0x4da27a545c0c5b758a6ba100e3a049001de870f5/b69eeb5171b3bb0f6f6ec0929e603450.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'EPIK',
            'name': 'Epik Prime',
            'address': '0x4da0C48376C277cdBd7Fc6FdC6936DEE3e4AdF75',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17907/thumb/EPIK_Prime_LOGO.jpg?1630738458',
            'coingeckoId': 'epik-prime',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MONONOKE-INU',
            'name': 'Mononoke Inu',
            'address': '0x4da08a1Bff50BE96bdeD5C7019227164b49C2bFc',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18769/thumb/z9YDK0f.png?1633357322',
            'coingeckoId': 'mononoke-inu',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'QUA',
            'name': 'Quasacoin',
            'address': '0x4dAeb4a06F70f4b1A5C329115731fE4b89C0B227',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21965/thumb/coin_200.png?1643454438',
            'coingeckoId': 'quasacoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELFI',
            'name': 'ELYFI',
            'address': '0x4dA34f8264CB33A5c9F17081B9EF5Ff6091116f4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23733/thumb/elyfi_logo.png?1645691480',
            'coingeckoId': 'elyfi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APE',
            'name': 'ApeCoin',
            'address': '0x4d224452801ACEd8B2F0aebE155379bb5D594381',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x4d224452801aced8b2f0aebe155379bb5d594381.png',
            'coingeckoId': 'apecoin',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'REALT-S-15373-PARKS',
            'name': 'RealT   15373 Parkside St  Detroit  MI ',
            'address': '0x4d0dA4E75d40Bd7D9C4f7A292BF883BcDf38c45D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16562/thumb/15373-Parkside-hero-1.jpg?1624458568',
            'coingeckoId': '15373-parkside',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WTK',
            'name': 'WadzPay',
            'address': '0x4cfF49d0A19ed6Ff845a9122fa912AbcFB1F68A6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13083/thumb/200xWadzToken.png?1604984329',
            'coingeckoId': 'wadzpay-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHAIN',
            'name': 'CURE Chain',
            'address': '0x4cd4441F169e472dD7EA462167bA93180b6E6074',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27291/thumb/cure_chain_logo.png?1663147515',
            'coingeckoId': 'cure-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RIGEL',
            'name': 'Rigel Finance',
            'address': '0x4cA0654f4fc1025cF1a17B7459c20aC0479522aD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13674/thumb/logo-200x200_%282%29.png?1610682780',
            'coingeckoId': 'rigel-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KCT',
            'name': 'Konnect',
            'address': '0x4c601dc69AfFb0D4Fc8dE1Ac303705e432A4A27E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25756/thumb/EAO_5JhW_400x400.jpg?1653516279',
            'coingeckoId': 'konnect',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AUTUMN',
            'name': 'Autumn',
            'address': '0x4c3bAe16c79c30eEB1004Fb03C878d89695e3a99',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25366/thumb/autumn.png?1651317533',
            'coingeckoId': 'autumn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GHOST',
            'name': 'GHOST',
            'address': '0x4c327471C44B2dacD6E90525f9D629bd2e4f662C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x4c327471c44b2dacd6e90525f9d629bd2e4f662c.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ORAI',
            'name': 'Oraichain',
            'address': '0x4c11249814f11b9346808179Cf06e71ac328c1b5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x4c11249814f11b9346808179cf06e71ac328c1b5.png',
            'coingeckoId': 'oraichain-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ORBIT',
            'name': 'Orbit',
            'address': '0x4bf5cd1AC6FfF12E88AEDD3c70EB4148F90F8894',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25807/thumb/apple-touch-icon.png?1653978963',
            'coingeckoId': 'orbit-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YFIII',
            'name': 'Dify Finance',
            'address': '0x4be40bc9681D0A7C24A99b4c92F85B9053Fc2A45',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12628/thumb/zNTAjrF.png?1601294851',
            'coingeckoId': 'dify-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MODEX',
            'name': 'Modex',
            'address': '0x4bceA5E4d0F6eD53cf45e7a28FebB2d3621D7438',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2851/thumb/LhWIeAg.png?1602566568',
            'coingeckoId': 'modex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HXRO',
            'name': 'Hxro',
            'address': '0x4bD70556ae3F8a6eC6C4080A0C327B24325438f3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7805/thumb/Hxro_Profile_Transparent.png?1622443308',
            'coingeckoId': 'hxro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DG',
            'name': 'Decentral Games',
            'address': '0x4b520c812E8430659FC9f12f6d0c39026C83588D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x4b520c812e8430659fc9f12f6d0c39026c83588d.png',
            'coingeckoId': 'decentral-games',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MIS',
            'name': 'MIS',
            'address': '0x4b4D2e899658FB59b1D518b68fe836B100ee8958',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x4b4d2e899658fb59b1d518b68fe836b100ee8958.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'LMR',
            'name': 'Lumerin',
            'address': '0x4b1D0b9F081468D780Ca1d5d79132b64301085d1',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24593/thumb/Lumerin-icon.png?1650688090',
            'coingeckoId': 'lumerin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZHT',
            'name': 'ZeroHybrid Network',
            'address': '0x4b13A8a95Da56773312cA0d4e0670530aa700FcF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18692/thumb/LOGO1_%287%29.png?1635230502',
            'coingeckoId': 'zerohybrid',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MYC',
            'name': 'Mycelium',
            'address': '0x4b13006980aCB09645131b91D259eaA111eaF5Ba',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x4b13006980acb09645131b91d259eaa111eaf5ba.png',
            'coingeckoId': 'mycelium',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'POLL',
            'name': 'Pollchain',
            'address': '0x4b0f027d0b694Aae2761ED2d426295d4f949F5d0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19034/thumb/pollchain.PNG?1634197337',
            'coingeckoId': 'pollchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GPO',
            'name': 'GoldPesa Option',
            'address': '0x4ad7a056191F4c9519fAcd6D75FA94CA26003aCE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23813/thumb/15462.png?1645513212',
            'coingeckoId': 'goldpesa-option',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XFIT',
            'name': 'Xfit',
            'address': '0x4aa41bC1649C9C3177eD16CaaA11482295fC7441',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14904/thumb/xfit_logo.png?1618967174',
            'coingeckoId': 'xfit',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'UDAI',
            'name': 'Unagii Dai',
            'address': '0x4aD0b81f92B16624BBcF46FC0030cFBBf8d02376',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13782/thumb/uDAI.png?1611730113',
            'coingeckoId': 'unagii-dai',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHLT',
            'name': 'Chellitcoin',
            'address': '0x4a9d711100afF9A444a3c40284F70269Bb3f0487',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19192/thumb/chellit_ticker-01.png?1634632273',
            'coingeckoId': 'chellitcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POLVEN',
            'name': 'Polka Ventures',
            'address': '0x4a6AB9792e9f046C3AB22D8602450DE5186Be9A7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14613/thumb/polven.png?1617253185',
            'coingeckoId': 'polka-ventures',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CYFM',
            'name': 'CyberFM',
            'address': '0x4a621d9f1b19296d1C0f87637b3A8D4978e9bf82',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5476/thumb/cyberfm.png?1547041216',
            'coingeckoId': 'cyberfm',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'NAOS',
            'name': 'NAOS Finance',
            'address': '0x4a615bB7166210CCe20E6642a6f8Fb5d4D044496',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x4a615bB7166210CCe20E6642a6f8Fb5d4D044496/logo.png',
            'coingeckoId': 'naos-finance',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'FXC',
            'name': 'Flexacoin',
            'address': '0x4a57E687b9126435a9B19E4A802113e266AdeBde',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x4a57e687b9126435a9b19e4a802113e266adebde.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'MITX',
            'name': 'Morpheus Labs',
            'address': '0x4a527d8fc13C5203AB24BA0944F4Cb14658D1Db6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3164/thumb/mitx.png?1604888269',
            'coingeckoId': 'morpheus-labs',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QNT',
            'name': 'Quant',
            'address': '0x4a220E6096B25EADb88358cb44068A3248254675',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x4a220e6096b25eadb88358cb44068a3248254675.png',
            'coingeckoId': 'quant-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'NIFT',
            'name': 'Niftify',
            'address': '0x4a1d542b52a95AD01Ddc70C2e7df0c7bBAAdc56f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20558/thumb/AWPDLxLK_400x400.jpg?1637209747',
            'coingeckoId': 'niftify',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GRG',
            'name': 'RigoBlock',
            'address': '0x4FbB350052Bca5417566f188eB2EBCE5b19BC964',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1532/thumb/Symbol-RigoblockRGB.png?1547035682',
            'coingeckoId': 'rigoblock',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TOK',
            'name': 'Tokenplace',
            'address': '0x4Fb721eF3Bf99e0f2c193847afA296b9257d3C30',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15779/thumb/output-onlinepngtools_%283%29.png?1621837855',
            'coingeckoId': 'tokenplace',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUSD',
            'name': 'Binance USD',
            'address': '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x4fabb145d64652a948d72533023f6e7a623c7c53.png',
            'coingeckoId': 'binance-usd',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'WAR',
            'name': 'Wrapped AR',
            'address': '0x4FaDC7A98f2Dc96510e42dD1A74141eEae0C1543',
            'decimals': 12,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17093/thumb/war.PNG?1626224053',
            'coingeckoId': 'wrapped-ar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OPT',
            'name': 'Open Predict Token',
            'address': '0x4FE5851C9af07df9e5AD8217aFAE1ea72737Ebda',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x4fe5851c9af07df9e5ad8217afae1ea72737ebda.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'WPT',
            'name': 'WPT Investing Corp',
            'address': '0x4FD51Cb87ffEFDF1711112b5Bd8aB682E54988eA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x4fd51cb87ffefdf1711112b5bd8ab682e54988ea.png',
            'coingeckoId': 'wpt-investing-corp',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'STAT',
            'name': 'STAT',
            'address': '0x4FC15c91a9c4A9efB404174464687E8e128730C2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26602/thumb/stat.png?1667291701',
            'coingeckoId': 'stat',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BBGC',
            'name': 'BigBang Game',
            'address': '0x4FBb0B4cD8f960aC3428194F1c94c805D5b35836',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7719/thumb/rxpRt7tH_400x400.jpg?1549957251',
            'coingeckoId': 'bigbang-game',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CELR',
            'name': 'Celer Network',
            'address': '0x4F9254C83EB525f9FCf346490bbb3ed28a81C667',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/3814.png',
            'coingeckoId': 'celer-network',
            'listedIn': [
                'coingecko',
                'uniswap',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'YFD',
            'name': 'Your Finance Decentralized',
            'address': '0x4F4F0Ef7978737ce928BFF395529161b44e27ad9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13225/thumb/YFD2.png?1613362452',
            'coingeckoId': 'yfdfi-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MEWTWO',
            'name': 'Mewtwo Inu',
            'address': '0x4F2AB9D03ce5b8D0d3BcA09259c78005D2775E08',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19562/thumb/mewtwo_update.png?1635422500',
            'coingeckoId': 'mewtwo-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANFD',
            'name': 'Angry Doge',
            'address': '0x4F14cDBd815B79E9624121f564f24685c6B1211b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25921/thumb/angry-doge-anfd.png?1654653373',
            'coingeckoId': 'angry-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BDT',
            'name': 'BlackDragon',
            'address': '0x4Efe8665e564bF454cCF5C90Ee16817F7485d5Cf',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13426/thumb/Black-Dragon-Black.png?1608515220',
            'coingeckoId': 'blackdragon-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MBN',
            'name': 'Membrana',
            'address': '0x4Eeea7B48b9C3ac8F70a9c932A8B1E8a5CB624c7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5117/thumb/membrana_logo.png?1565238625',
            'coingeckoId': 'membrana-platform',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DBUY',
            'name': 'Doont Buy',
            'address': '0x4EcE5C5CfB9B960A49aAe739E15cDB6cfDcc5782',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17895/thumb/VaxEiZ3.png?1629727429',
            'coingeckoId': 'doont-buy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VANY',
            'name': 'Vanywhere',
            'address': '0x4EDD66235349E353eb8CB8e40596599644bfE91c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2701/thumb/vany.png?1547036908',
            'coingeckoId': 'vanywhere',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EDGE',
            'name': 'Edge',
            'address': '0x4EC1b60B96193a64AcAe44778e51f7BfF2007831',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1848/thumb/EDGE.png?1632300420',
            'coingeckoId': 'edge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSCGIRL',
            'name': 'Binance Smart Chain Girl',
            'address': '0x4E9A46EA6A22f3894aBEE2302Ad42fd3b69E21E2',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17271/thumb/icon_200px_16bit.png?1627005622',
            'coingeckoId': 'binance-smart-chain-girl',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NSS',
            'name': 'NSS Coin',
            'address': '0x4E3Bddd468AbfC6C88bc25dAA5d894380CEd5bc8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9501/thumb/adddcf817f.png?1568108247',
            'coingeckoId': 'nss-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FTM',
            'name': 'Fantom',
            'address': '0x4E15361FD6b4BB609Fa63C81A2be19d873717870',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x4e15361fd6b4bb609fa63c81a2be19d873717870.png',
            'coingeckoId': 'fantom',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'JACK',
            'name': 'Jack Token',
            'address': '0x4E12EB8e506Ccd1427F6b8F7faa3e88fB698EB28',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10991/thumb/Jacktoken200x200.png?1586938972',
            'coingeckoId': 'jack-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JOY',
            'name': 'Joystick',
            'address': '0x4E114d405b9Ba2F59524941733e505Ae03Fb1FB5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27666/thumb/awKbFbPi_400x400.jpg?1665140248',
            'coingeckoId': 'joystick1',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOOST',
            'name': 'Boost',
            'address': '0x4E0fCa55a6C3A94720ded91153A27F60E26B9AA8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/11216.png',
            'coingeckoId': 'boost',
            'listedIn': [
                'coingecko',
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'QATAR',
            'name': 'Qatar Inu Token',
            'address': '0x4E0DF4560cEDfda5d793F607CeFa30383BDa7327',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27894/thumb/2022-10-18_20.39.03.jpg?1666254735',
            'coingeckoId': 'qatar-inu-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DODI',
            'name': 'DoubleDice',
            'address': '0x4E08F03079c5CD3083eA331Ec61bCC87538B7665',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21035/thumb/crEjNfhF_400x400.jpg?1638250608',
            'coingeckoId': 'doubledice-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CETH',
            'name': 'cETH',
            'address': '0x4Ddc2D193948926D02f9B1fE9e1daa0718270ED5',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x4ddc2d193948926d02f9b1fe9e1daa0718270ed5.png',
            'coingeckoId': 'compound-ether',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'CON',
            'name': 'CONUN',
            'address': '0x4Dd672e77c795844fe3A464eF8eF0FAAe617C8fB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7480/thumb/conun.png?1547716938',
            'coingeckoId': 'conun',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XAUR',
            'name': 'Xaurum',
            'address': '0x4DF812F6064def1e5e029f1ca858777CC98D2D81',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/461/thumb/xaurum.png?1548761197',
            'coingeckoId': 'xaurum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATUSD',
            'name': 'Aave TUSD v1',
            'address': '0x4DA9b813057D04BAef4e5800E36083717b4a0341',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x4da9b813057d04baef4e5800e36083717b4a0341.png',
            'coingeckoId': 'aave-tusd-v1',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'MINI',
            'name': 'Mini',
            'address': '0x4D953cf077c0C95Ba090226E59A18FcF97db44EC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x4d953cf077c0c95ba090226e59a18fcf97db44ec.png',
            'coingeckoId': 'mini',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'DOGEZ',
            'name': 'DogeZilla Token',
            'address': '0x4D8046B2f7986FEB5a4b0781EbF5Fa2D7a808617',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20511/thumb/dogezilla.png?1637807027',
            'coingeckoId': 'doge-zilla',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLUESPARROW',
            'name': 'BlueSparrow  OLD ',
            'address': '0x4D67EDef87a5fF910954899f4e5a0AaF107afd42',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19995/thumb/bluesparrow.png?1636361716',
            'coingeckoId': 'bluesparrow-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SUMMER',
            'name': 'Summer',
            'address': '0x4D4f3715050571A447FfFa2Cd4Cf091C7014CA5c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25365/thumb/summer.png?1651317403',
            'coingeckoId': 'summer',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AGFI',
            'name': 'Aggregated Finance',
            'address': '0x4D0F56d728c5232ab07fAA0BdcbA23670A35451f',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26332/thumb/79LDzdib_400x400_%281%29.jpg?1657269938',
            'coingeckoId': 'aggregated-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MYST',
            'name': 'Mysterium',
            'address': '0x4Cf89ca06ad997bC732Dc876ed2A7F26a9E7f361',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x4cf89ca06ad997bc732dc876ed2a7f26a9e7f361.png',
            'coingeckoId': 'mysterium',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'DCNT',
            'name': 'Decanect',
            'address': '0x4Ce4C025692B3142dbdE1cd432ef55b9A8D18701',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27309/thumb/200-x-200-jpeg.jpg?1663239875',
            'coingeckoId': 'decanect',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KEY',
            'name': 'Key',
            'address': '0x4Cd988AfBad37289BAAf53C13e98E2BD46aAEa8c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4182/thumb/bihu-key-token.png?1547039459',
            'coingeckoId': 'key',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WPR',
            'name': 'WePower',
            'address': '0x4CF488387F035FF08c371515562CBa712f9015d4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1951/thumb/wpr.png?1547036237',
            'coingeckoId': 'wepower',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FREE',
            'name': 'FreeRossDAO',
            'address': '0x4CD0c43B0D53bc318cc5342b77EB6f124E47f526',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21648/thumb/free.jpg?1639639586',
            'coingeckoId': 'freerossdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KEY',
            'name': 'SelfKey',
            'address': '0x4CC19356f2D37338b9802aa8E8fc58B0373296E7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x4cc19356f2d37338b9802aa8e8fc58b0373296e7.png',
            'coingeckoId': 'selfkey',
            'listedIn': [
                'coingecko',
                'uniswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'PAINT',
            'name': 'MurAll',
            'address': '0x4C6eC08CF3fc987c6C4BEB03184D335A2dFc4042',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x4c6ec08cf3fc987c6c4beb03184d335a2dfc4042.png',
            'coingeckoId': 'paint',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'NNT',
            'name': 'NunuToken',
            'address': '0x4C4D878faE704ffF9E00325f7C2bC758489202EC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SOKU',
            'name': 'SokuSwap',
            'address': '0x4C3A8ECeB656Ec63eaE80a4ebD565E4887DB6160',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18378/thumb/VCIEHaG.png?1653547690',
            'coingeckoId': 'sokuswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAS',
            'name': 'Stand Share',
            'address': '0x4C38D0e726B6C86F64c1B281348E725973542043',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13543/thumb/sac.jpg?1609648278',
            'coingeckoId': 'stand-share',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FODL',
            'name': 'Fodl Finance',
            'address': '0x4C2e59D098DF7b6cBaE0848d66DE2f8A4889b9C3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x4c2e59d098df7b6cbae0848d66de2f8a4889b9c3.png',
            'coingeckoId': 'fodl-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'FONT',
            'name': 'Font',
            'address': '0x4C25Bdf026Ea05F32713F00f73Ca55857Fbf6342',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x4c25bdf026ea05f32713f00f73ca55857fbf6342.png',
            'coingeckoId': 'font',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SILK',
            'name': 'SilkChain',
            'address': '0x4C1e085d8c2D2a8377834d0D7b38f12cc5b86898',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3810/thumb/2.png?1561537249',
            'coingeckoId': 'silkchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRU',
            'name': 'TrueFi',
            'address': '0x4C19596f5aAfF459fA38B0f7eD92F11AE6543784',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x4c19596f5aaff459fa38b0f7ed92f11ae6543784.png',
            'coingeckoId': 'truefi',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'BCHBULL',
            'name': '3X Long Bitcoin Cash Token',
            'address': '0x4C133E081dFB5858e39ccA74E69bf603d409e57A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10306/thumb/683JEXMN_400x400.png?1577743740',
            'coingeckoId': '3x-long-bitcoin-cash-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BYN',
            'name': 'BeyondFi',
            'address': '0x4Bb3205bf648B7F59EF90Dee0F1B62F6116Bc7ca',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14746/thumb/byn.png?1636079656',
            'coingeckoId': 'beyond-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VETH',
            'name': 'Vether',
            'address': '0x4Ba6dDd7b89ed838FEd25d208D4f644106E34279',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11375/thumb/vether-symbol-coingecko.png?1622341592',
            'coingeckoId': 'vether',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JPEG',
            'name': 'JPEG',
            'address': '0x4BFcE5A1aCC3B847AFa9579bA91DA33b08e66fb7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://get.celer.app/cbridge-icons/JPEG.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'XT',
            'name': 'XT com',
            'address': '0x4BE10dA47A07716af28Ad199FbE020501BddD7aF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8391/thumb/XT_token.jpg?1557979399',
            'coingeckoId': 'xtcom-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ACQ',
            'name': 'Acquire Fi',
            'address': '0x4BDCb66B968060D9390C1D12bd29734496205581',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27199/thumb/ACQisotypeSept2022_1000x1000px.png?1662566695',
            'coingeckoId': 'acquire-fi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EFIL',
            'name': 'Ethereum Wrapped Filecoin',
            'address': '0x4B7ee45f30767F36f06F79B32BF1FCa6f726DEda',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18231/thumb/output-onlinepngtools_%287%29.png?1631060559',
            'coingeckoId': 'ethereum-wrapped-filecoin',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'LEVER',
            'name': 'LeverFi',
            'address': '0x4B5f49487ea7B3609b1aD05459BE420548789f1f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26205/thumb/WI72SpBl_400x400.jpeg?1656494480',
            'coingeckoId': 'lever',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HTN',
            'name': 'Heart Number',
            'address': '0x4B4b1d389d4f4E082B30F75c6319c0CE5ACBd619',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9002/thumb/htn%28200x200%29.png?1600757668',
            'coingeckoId': 'heartnumber',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LITX',
            'name': 'Lith Token',
            'address': '0x4B48068864F77261838C7849A12853FB94c77a91',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26327/thumb/200x200px_LOGO.jpeg?1657768654',
            'coingeckoId': 'litx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DISTX',
            'name': 'DistX',
            'address': '0x4B4701f3f827E1331fb22FF8e2BEaC24b17Eb055',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12291/thumb/DISTX-icon.png?1598885812',
            'coingeckoId': 'distx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YEFIM',
            'name': 'Yearn Finance Management',
            'address': '0x4B34c0CBeEF271F895d339c5F76322d71A60782B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12836/thumb/jOVGT0Y.png?1602886889',
            'coingeckoId': 'yearn-finance-management',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KIBA',
            'name': 'Kiba Inu',
            'address': '0x4B2C54b80B77580dc02A0f6734d3BAD733F50900',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://api.rubic.exchange/assets/ethereum/0x4b2c54b80b77580dc02a0f6734d3bad733f50900/logo.png',
            'coingeckoId': 'kiba-inu',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'JUP',
            'name': 'Jupiter',
            'address': '0x4B1E80cAC91e2216EEb63e29B957eB91Ae9C2Be8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x4b1e80cac91e2216eeb63e29b957eb91ae9c2be8.png',
            'coingeckoId': 'jupiter',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'CSUSHI',
            'name': 'cSUSHI',
            'address': '0x4B0181102A0112A2ef11AbEE5563bb4a3176c9d7',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17529/thumb/csushi.PNG?1628122792',
            'coingeckoId': 'compound-sushi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAID',
            'name': 'MaidCoin',
            'address': '0x4Af698B479D0098229DC715655c667Ceb6cd8433',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/maid.jpg',
            'coingeckoId': 'maidcoin',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'EHIVE',
            'name': 'eHive',
            'address': '0x4Ae2Cd1F5B8806a973953B76f9Ce6d5FAB9cdcfd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27239/thumb/ehive_200x200.png?1662714434',
            'coingeckoId': 'ehive',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZINC',
            'name': 'ZINC',
            'address': '0x4AaC461C86aBfA71e9d00d9a2cde8d74E4E1aeEa',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x4aac461c86abfa71e9d00d9a2cde8d74e4e1aeea.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'RGP',
            'name': 'Rigel Protocol',
            'address': '0x4AF5ff1A60a6eF6C7c8f9C4E304cD9051fCa3Ec0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15837/thumb/A_qRYvB2_400x400.png?1622080940',
            'coingeckoId': 'rigel-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AKAMARU',
            'name': 'Akamaru Inu',
            'address': '0x4ABaC7A6AcF3Ce84F1C2Fa07D91e72cdd6081CD3',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19375/thumb/akamaru.png?1635136512',
            'coingeckoId': 'akamaru-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KEL',
            'name': 'KelVPN',
            'address': '0x4ABB9cC67BD3da9Eb966d1159A71a0e68BD15432',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1634699078192_22645254775198143.png',
            'coingeckoId': 'kelvpn',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'INXT',
            'name': 'Internxt',
            'address': '0x4A8f5F96D5436e43112c2fBC6a9f70DA9e4E16D4',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/986/thumb/inxt.png?1639651885',
            'coingeckoId': 'internxt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTMC',
            'name': 'Bit Miner Chain',
            'address': '0x4A8F44BE523580a11cdB20e2C7C470Adf44Ec9BB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6177/thumb/9ecCxf_s_400x400.jpg?1547042203',
            'coingeckoId': 'bit-miner-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WZEC',
            'name': 'Wrapped Zcash',
            'address': '0x4A64515E5E1d1073e83f30cB97BEd20400b66E10',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x4A64515E5E1d1073e83f30cB97BEd20400b66E10/logo.png',
            'coingeckoId': 'wrapped-zcash',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'GSBL',
            'name': 'GasBlock',
            'address': '0x4A5dec561de897c04062137e687db6093C3278B8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28191/thumb/gasblock_logo_200xW.png?1668327478',
            'coingeckoId': 'gasblock',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PEPE',
            'name': 'PEPEGOLD',
            'address': '0x4A27e9aAb8F8bA9de06766C8476eD1D16494E35F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26864/thumb/logo-200x200.png?1660533136',
            'coingeckoId': 'pepegold-6ea5105a-8bbe-45bc-bd1c-dc9b01a19be7',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VALUE',
            'name': 'Value DeFi',
            'address': '0x49E833337ECe7aFE375e44F4E3e8481029218E5c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12525/thumb/value_logo_-_500x500.png?1601478115',
            'coingeckoId': 'value-liquidity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRETH2',
            'name': 'Cream ETH 2',
            'address': '0x49D72e3973900A195A155a46441F0C08179FdB64',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13568/thumb/creth2.png?1609837099',
            'coingeckoId': 'cream-eth2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VIX',
            'name': 'Vixco',
            'address': '0x49Bf0220C9Ce17E52dCcA3d217231746D676085B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15405/thumb/vixco.png?1620811916',
            'coingeckoId': 'vixco',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JOJO',
            'name': 'JoJos Adventure',
            'address': '0x49B4F92431c5B8cF260F983c4d3ed28E1fd0b991',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27369/thumb/jojo.png?1663722472',
            'coingeckoId': 'jojos-adventure',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-17813-BRADF',
            'name': 'RealT   17813 Bradford St  Detroit  MI ',
            'address': '0x499A6c19F5537dd6005E2B5c6E1263103f558Ba4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16606/thumb/17813-Bradford-hero-1.jpeg?1624544519',
            'coingeckoId': '17813-bradford',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KRW',
            'name': 'KROWN',
            'address': '0x499568c250Ab2a42292261d6121525d70691894b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16530/thumb/KRW_token_logo_200x200.png?1624343058',
            'coingeckoId': 'krown',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RENBTCCURVE',
            'name': 'LP renBTC Curve',
            'address': '0x49849C98ae39Fff122806C06791Fa73784FB3675',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11957/thumb/Curvefi_renCrv_32.png?1596435739',
            'coingeckoId': 'lp-renbtc-curve',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DINO',
            'name': 'DinoLFG',
            'address': '0x49642110B712C1FD7261Bc074105E9E44676c68F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28239/thumb/photo_2022-11-16_04-57-23.jpg?1668581540',
            'coingeckoId': 'dinolfg',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDQ',
            'name': 'USDQ',
            'address': '0x4954Db6391F4feB5468b6B943D4935353596aEC9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x4954db6391f4feb5468b6b943d4935353596aec9.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'FOAM',
            'name': 'FOAM',
            'address': '0x4946Fcea7C692606e8908002e55A582af44AC121',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x4946fcea7c692606e8908002e55a582af44ac121.png',
            'coingeckoId': 'foam-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'KTN',
            'name': 'Kattana',
            'address': '0x491E136FF7FF03E6aB097E54734697Bb5802FC1C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14739/thumb/256-256-1.png?1638363577',
            'coingeckoId': 'kattana',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LIBERTAS',
            'name': 'LIBERTAS',
            'address': '0x49184E6dAe8C8ecD89d8Bdc1B950c597b8167c90',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x49184e6dae8c8ecd89d8bdc1b950c597b8167c90.png',
            'coingeckoId': 'libertas-token',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'CTSI',
            'name': 'Cartesi',
            'address': '0x491604c0FDF08347Dd1fa4Ee062a822A5DD06B5D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x491604c0fdf08347dd1fa4ee062a822a5dd06b5d.png',
            'coingeckoId': 'cartesi',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'optimism',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'TRR',
            'name': 'Terran Coin',
            'address': '0x490e3f4af13e1616EC97A8C6600c1061a8D0253e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15351/thumb/TERRAN-500px.png?1620626066',
            'coingeckoId': 'terran-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XZAR',
            'name': 'South African Tether',
            'address': '0x48f07301E9E29c3C38a80ae8d9ae771F224f1054',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x48f07301e9e29c3c38a80ae8d9ae771f224f1054.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BEARSHIT',
            'name': '3X Short Shitcoin Index Token',
            'address': '0x48dEE19C81B89A9aB473361bAE7a19210f2DEaA4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10206/thumb/683JEXMN_400x400_%281%29.png?1576534838',
            'coingeckoId': '3x-short-shitcoin-index-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELE',
            'name': 'Elevate',
            'address': '0x48bE867B240D2fFafF69e0746130F2c027d8d3d2',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13802/thumb/elevate-logo.png?1611980666',
            'coingeckoId': 'elevate',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MMTKN',
            'name': 'MetaMerce Token',
            'address': '0x48a58Fdf91Ab56B5700D853733b860b4cDE08b26',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27467/thumb/MMTKNLogo.png?1664178229',
            'coingeckoId': 'metamerce-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VBNT',
            'name': 'Bancor Governance',
            'address': '0x48Fb253446873234F2fEBbF9BdeAA72d9d387f94',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x48fb253446873234f2febbf9bdeaa72d9d387f94.png',
            'coingeckoId': 'bancor-governance-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SWISE',
            'name': 'StakeWise',
            'address': '0x48C3399719B582dD63eB5AADf12A40B4C3f52FA2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x48c3399719b582dd63eb5aadf12a40b4c3f52fa2.png',
            'coingeckoId': 'stakewise',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'KUMA',
            'name': 'Kuma Inu',
            'address': '0x48C276e8d03813224bb1e55F953adB6d02FD3E02',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15526/thumb/kuma_inu.PNG?1621128824',
            'coingeckoId': 'kuma-inu',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'HAND',
            'name': 'ShowHand',
            'address': '0x48C1B2f3eFA85fbafb2ab951bF4Ba860a08cdBB7',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5554/thumb/showhand.png?1548609179',
            'coingeckoId': 'showhand',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHUBBIES20',
            'name': 'Chubbies',
            'address': '0x48Bef6bd05bD23b5e6800Cf0406e524b517af250',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17857/thumb/unnamed_%281%29.gif?1629681572',
            'coingeckoId': 'chubbies',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALPHA',
            'name': ' Alpha',
            'address': '0x48AF7b1c9dac8871C064f62FcEC0d9d6F7c269f5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24340/thumb/alpha_icon.png?1647395407',
            'coingeckoId': 'alphatoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOF',
            'name': 'Golff',
            'address': '0x488E0369f9BC5C40C002eA7c1fe4fd01A198801c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x488e0369f9bc5c40c002ea7c1fe4fd01a198801c.png',
            'coingeckoId': 'golff',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'GOM2',
            'name': 'GoMoney2',
            'address': '0x48783486ddD7fa85ECa6B0C4AE8920Bc25DfbcD7',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10374/thumb/lvAhDIqmH0fh6U3NIiYLmKETR3uUBcySAv-K28eW6CCFm-ODhCdId71Ug5c4TCoEtxsre30Efe08muctK0MlK-JPdAbxilzZ7dHyiBNOCvcc_9AmJIo09TRLaiAafgqcFKsxpNOON2D28oTLnVTaqwxWL8zKSzjbI6ChKTCJKOiM2mq7VhQRZYe93StR30mf2O7DnkqmGEbZ5_i.jpg?1578675305',
            'coingeckoId': 'gomoney2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TIME',
            'name': 'chrono tech',
            'address': '0x485d17A6f1B8780392d53D64751824253011A260',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x485d17a6f1b8780392d53d64751824253011a260.png',
            'coingeckoId': 'chronobank',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'POLP',
            'name': 'PolkaParty',
            'address': '0x48592de8CdED16f6Bb56c896Fe1Affc37630889C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17867/thumb/polp_logo.jpg?1641355239',
            'coingeckoId': 'polkaparty',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MARVIN',
            'name': 'MarvinInu',
            'address': '0x48515e2BaEe5283e3b7cdc624f3C63Caef13140A',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19028/thumb/ea3lTPa.png?1634193762',
            'coingeckoId': 'marvininu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FAMOUS',
            'name': 'Famous Coin',
            'address': '0x4838903d6319E483AB82Ae3f09A1Ec36489A4193',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14416/thumb/famouscoin.jpg?1615951801',
            'coingeckoId': 'famous-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NLA',
            'name': 'No Limit Ape',
            'address': '0x482B078486079d66334389B55ab974BC8282984b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24299/thumb/nla200x200.png?1647268853',
            'coingeckoId': 'no-limit-ape',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TCT',
            'name': 'TokenClub',
            'address': '0x4824A7b64E3966B0133f4f4FFB1b9D6bEb75FFF7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2455/thumb/tokenclub.png?1558012103',
            'coingeckoId': 'tokenclub',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PXG',
            'name': 'PlayGame',
            'address': '0x47e67BA66b0699500f18A53F94E2b9dB3D47437e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7242/thumb/pxg-coin_3x.png?1547043841',
            'coingeckoId': 'playgame',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OXO',
            'name': 'OXO Farm',
            'address': '0x47daC6BD80f024575a6d367aF5Ba8e89202A09fc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15107/thumb/OXO200.png?1619734005',
            'coingeckoId': 'oxo-farm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GRS',
            'name': 'Giresunspor Token',
            'address': '0x47d49d010c03B40F88F422502D694ff49fE6c9C8',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24810/thumb/18444.png?1649024268',
            'coingeckoId': 'giresunspor-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ONSTON',
            'name': 'Onston',
            'address': '0x47b9F01B16E9C9cb99191DCA68c9cc5bF6403957',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20669/thumb/onston.PNG?1637547859',
            'coingeckoId': 'onston',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'FNB',
            'name': 'FNB Protocol',
            'address': '0x47b28F365Bf4CB38DB4B6356864BDE7bc4B35129',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8157/thumb/FNB_protocol.jpg?1555650192',
            'coingeckoId': 'fnb-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PLUG',
            'name': 'PL Gnet',
            'address': '0x47DA5456bC2e1ce391b645Ce80F2E97192e4976a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15824/thumb/PLUG_token.png?1622007572',
            'coingeckoId': 'plgnet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOG',
            'name': 'The Golden Dog',
            'address': '0x47D920a911cB9a0Ad4618698bA3feF800aBC868D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26542/thumb/DOG-200x200.png?1658733855',
            'coingeckoId': 'the-golden-dog',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KCAL',
            'name': 'Phantasma Energy',
            'address': '0x47C1178F49140ECdBfbdF0aE2935cDB640D579F9',
            'decimals': 10,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25447/thumb/Screenshot_from_2022-05-06_09-46-03.png?1651801573',
            'coingeckoId': 'phantasma-energy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'xRNBW',
            'name': 'Rainbow Pool',
            'address': '0x47BE779De87de6580d0548cde80710a93c502405',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SRM',
            'name': 'Serum',
            'address': '0x476c5E26a75bd202a9683ffD34359C0CC15be0fF',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x476c5e26a75bd202a9683ffd34359c0cc15be0ff.png',
            'coingeckoId': 'serum',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'APO',
            'name': 'ApolloFi',
            'address': '0x475DB18D764DF7FaDfbD3e73fcCbBC1e14342aB3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26451/thumb/apo.png?1660311541',
            'coingeckoId': 'apollofi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MPT',
            'name': 'Meetple',
            'address': '0x47481c1b44F2A1c0135c45AA402CE4F4dDE4D30e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14323/thumb/Tqlsvhn1_400x400.png?1615427198',
            'coingeckoId': 'meetple',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RDT',
            'name': 'Ridotto',
            'address': '0x4740735AA98Dc8aa232BD049f8F0210458E7fCa3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18671/thumb/200x200_%2832%29.png?1632875527',
            'coingeckoId': 'ridotto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNO',
            'name': 'Uno Re',
            'address': '0x474021845C4643113458ea4414bdb7fB74A01A77',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x474021845c4643113458ea4414bdb7fb74a01a77.png',
            'coingeckoId': 'uno-re',
            'listedIn': [
                'coingecko',
                '1inch',
                'RouterProtocol',
                'lifinance'
            ]
        },
        {
            'symbol': 'ELV',
            'name': 'Elvantis',
            'address': '0x4734Baf528766ec4C420A6C13f8DBa7bB1920181',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23393/thumb/elvantis.PNG?1644116336',
            'coingeckoId': 'elvantis',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FOUR',
            'name': '4thpillar technologies',
            'address': '0x4730fB1463A6F1F44AEB45F6c5c422427f37F4D0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x4730fb1463a6f1f44aeb45f6c5c422427f37f4d0.png',
            'coingeckoId': 'the-4th-pillar',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'GST-ETH',
            'name': 'STEPN Green Satoshi Token on ETH',
            'address': '0x473037de59cf9484632f4A27B509CFE8d4a31404',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26528/thumb/gst_eth.png?1658487279',
            'coingeckoId': 'green-satoshi-token-on-eth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NIL',
            'name': 'Nil DAO',
            'address': '0x47252A63C723889814AeBcAC0683E615624ceC64',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x47252A63C723889814AeBcAC0683E615624ceC64/logo.png',
            'coingeckoId': 'nil-dao',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'KNG',
            'name': 'Kanga Exchange',
            'address': '0x471d113059324321749E097705197a2B44A070FC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21188/thumb/kanga.PNG?1638514737',
            'coingeckoId': 'kanga-exchange',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RMRK',
            'name': 'RMRK',
            'address': '0x471Ea49dd8E60E697f4cac262b5fafCc307506e4',
            'decimals': 10,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18656/thumb/download_%281%29_%281%29.png?1632865271',
            'coingeckoId': 'rmrk',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ME',
            'name': 'All me',
            'address': '0x47140a767A861F7a1f3B0Dd22A2F463421c28814',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5497/thumb/all.me-logo.jpg?1547041239',
            'coingeckoId': 'all-me',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GMI',
            'name': 'Bankless DeFi Innovation Index',
            'address': '0x47110d43175f7f2C2425E7d15792acC5817EB44f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x47110d43175f7f2c2425e7d15792acc5817eb44f.png',
            'coingeckoId': 'bankless-defi-innovation-index',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'TEMPLE',
            'name': 'TempleDAO',
            'address': '0x470EBf5f030Ed85Fc1ed4C2d36B9DD02e77CF1b7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x470EBf5f030Ed85Fc1ed4C2d36B9DD02e77CF1b7/logo.png',
            'coingeckoId': 'temple',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'PRIMATE',
            'name': 'Primate',
            'address': '0x46e98FFE40E408bA6412bEb670507e083C8B95ff',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25245/thumb/benji-logo-512x512.png?1651028701',
            'coingeckoId': 'primate',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SURF',
            'name': 'SurfExUtilityToken',
            'address': '0x46d473a0B3eEEc9F55FADE641bC576d5bc0b2246',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10783/thumb/200x200-logo-blu-grey-bkg-4-e1583512409629.png?1583539501',
            'coingeckoId': 'surfexutilitytoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SNM',
            'name': 'SONM',
            'address': '0x46d0DAc0926fa16707042CAdC23F1EB4141fe86B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/861/thumb/sonm.png?1548609871',
            'coingeckoId': 'sonm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-18433-FAUST',
            'name': 'RealT   18433 Faust Ave  Detroit  MI  4',
            'address': '0x46F8A600337dec5CaB03aa9b8F67f1D5B788ce28',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16684/thumb/Faust-1.jpeg?1624633417',
            'coingeckoId': '18433-faust',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GRC',
            'name': 'GreenCoin AI',
            'address': '0x46D886887B6908183032c75dee1b731B26D653c6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18376/thumb/Untitled-design-2-removebg-preview.png?1631692214',
            'coingeckoId': 'green-flow',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLUX',
            'name': 'Datamine FLUX',
            'address': '0x469eDA64aEd3A3Ad6f868c44564291aA415cB1d9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/5876.png',
            'coingeckoId': 'flux',
            'listedIn': [
                'coingecko',
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'UMI',
            'name': 'UMI',
            'address': '0x46943113Ae84e732bB510B5F7686D8B76fF56774',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16953/thumb/umi.PNG?1625793521',
            'coingeckoId': 'umi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WOO',
            'name': 'WOO Network',
            'address': '0x4691937a7508860F876c9c0a2a617E7d9E945D4B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x4691937a7508860f876c9c0a2a617e7d9e945d4b.png',
            'coingeckoId': 'woo-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'arbitrum_bridge',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'VIS',
            'name': 'Envision',
            'address': '0x469084939d1c20Fae3C73704FE963941C51bE863',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23907/thumb/J3JCKVq2.png?1645681725',
            'coingeckoId': 'envision',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YELD',
            'name': 'Yeld Finance',
            'address': '0x468ab3b1f63A1C14b361bC367c3cC92277588Da1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12389/thumb/X4bTDcZ.png?1622177749',
            'coingeckoId': 'yeld-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COVER',
            'name': 'Cover Protocol',
            'address': '0x4688a8b1F292FDaB17E9a90c8Bc379dC1DBd8713',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x4688a8b1f292fdab17e9a90c8bc379dc1dbd8713.png',
            'coingeckoId': 'cover-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'TEL',
            'name': 'Telcoin',
            'address': '0x467Bccd9d29f223BcE8043b84E8C8B282827790F',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x467bccd9d29f223bce8043b84e8c8b282827790f.png',
            'coingeckoId': 'telcoin',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'AXL',
            'name': 'Axelar',
            'address': '0x467719aD09025FcC6cF6F8311755809d45a5E5f3',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27277/thumb/V-65_xQ1_400x400.jpeg?1663121730',
            'coingeckoId': 'axelar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BRKL',
            'name': 'Brokoli',
            'address': '0x4674a4F24C5f63D53F22490Fb3A08eAAAD739ff8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18763/thumb/brkl.png?1633356263',
            'coingeckoId': 'brokoli',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GXT',
            'name': 'Gem Exchange and Trading',
            'address': '0x4674672BcDdDA2ea5300F5207E1158185c944bc0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12226/thumb/a.png?1653378779',
            'coingeckoId': 'gem-exchange-and-trading',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PUSD',
            'name': 'PUSd',
            'address': '0x466a756E9A7401B5e2444a3fCB3c2C12FBEa0a54',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25858/thumb/PUSd.png?1654161232',
            'coingeckoId': 'pusd-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OCB',
            'name': 'BLOCKMAX',
            'address': '0x466912bAA9430a4a460b141ee8C580D817441449',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12315/thumb/200x200-01.png?1599086761',
            'coingeckoId': 'blockmax',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WXMR',
            'name': 'Wrapped XMR by BTSE',
            'address': '0x465e07d6028830124BE2E4aA551fBe12805dB0f5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/wxmr.jpg',
            'coingeckoId': 'wrapped-xmr-btse',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'KRL',
            'name': 'KRYLL',
            'address': '0x464eBE77c293E473B48cFe96dDCf88fcF7bFDAC0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2807/thumb/krl.png?1547036979',
            'coingeckoId': 'kryll',
            'listedIn': [
                'coingecko',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'REALM',
            'name': 'Realm',
            'address': '0x464FdB8AFFC9bac185A7393fd4298137866DCFB8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18366/thumb/realm.PNG?1631665838',
            'coingeckoId': 'realm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BREE',
            'name': 'CBDAO',
            'address': '0x4639cd8cd52EC1CF2E496a606ce28D8AfB1C792F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12096/thumb/cbdao_logo.jpg?1597059848',
            'coingeckoId': 'cbdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HRD',
            'name': 'Hoard',
            'address': '0x461B71cff4d4334BbA09489acE4b5Dc1A1813445',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27300/thumb/hoard.png?1663212320',
            'coingeckoId': 'hoard',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KIND',
            'name': 'Kind Ads',
            'address': '0x4618519de4C304F3444ffa7f812dddC2971cc688',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5302/thumb/kind-ads-token.png?1547975306',
            'coingeckoId': 'kind-ads-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BCP',
            'name': 'Block Commerce Protocol',
            'address': '0x4612021C75809160Be60DB21fBc9D6adD0B32deF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17562/thumb/BCP_200x200.png?1628234484',
            'coingeckoId': 'block-commerce-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDS',
            'name': 'SpiceUSD',
            'address': '0x45fDb1b92a649fb6A64Ef1511D3Ba5Bf60044838',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25697/thumb/USDS.png?1653440948',
            'coingeckoId': 'spiceusd',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'YFV',
            'name': 'YFValue',
            'address': '0x45f24BaEef268BB6d63AEe5129015d69702BCDfa',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x45f24baeef268bb6d63aee5129015d69702bcdfa.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'STKXPRT',
            'name': 'pSTAKE Staked XPRT',
            'address': '0x45e007750Cc74B1D2b4DD7072230278d9602C499',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x45e007750Cc74B1D2b4DD7072230278d9602C499/logo.png',
            'coingeckoId': 'persistence-staked-xprt',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'DOMI',
            'name': 'Domi',
            'address': '0x45C2F8c9B4c0bDC76200448cc26C48ab6ffef83F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21633/thumb/Transparent_Circle_Logo_2.png?1639627183',
            'coingeckoId': 'domi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RENBCH',
            'name': 'renBCH',
            'address': '0x459086F2376525BdCebA5bDDA135e4E9d3FeF5bf',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x459086f2376525bdceba5bdda135e4e9d3fef5bf.png',
            'coingeckoId': 'renbch',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'PAXG',
            'name': 'PAX Gold',
            'address': '0x45804880De22913dAFE09f4980848ECE6EcbAf78',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x45804880de22913dafe09f4980848ece6ecbaf78.png',
            'coingeckoId': 'pax-gold',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DON',
            'name': 'Donkey DON',
            'address': '0x4576E6825B462b6916D2a41E187626E9090A92c6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18257/thumb/Donkey_Logo_CMC.png?1631154862',
            'coingeckoId': 'donkey-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OXT',
            'name': 'Orchid Protocol',
            'address': '0x4575f41308EC1483f3d399aa9a2826d74Da13Deb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://elk.finance/tokens/logos/ethereum/0x4575f41308EC1483f3d399aa9a2826d74Da13Deb/logo.png',
            'coingeckoId': 'orchid-protocol',
            'listedIn': [
                'coingecko',
                'uniswap',
                'sushiswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ROGE',
            'name': 'Rogue Doge',
            'address': '0x45734927Fa2f616FbE19E65f42A0ef3d37d1c80A',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17440/thumb/njbVg2f.png?1645356462',
            'coingeckoId': 'roge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CAVA',
            'name': 'Cavapoo',
            'address': '0x456D8f0D25A4e787eE60c401F8B963a465148f70',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x456d8f0d25a4e787ee60c401f8b963a465148f70.png',
            'coingeckoId': 'cavapoo',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'VSN',
            'name': 'Vision Network',
            'address': '0x456AE45c0CE901E2e7c99c0718031cEc0A7A59Ff',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x456ae45c0ce901e2e7c99c0718031cec0a7a59ff.png',
            'coingeckoId': 'vision-network',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'CHAMP',
            'name': 'Ultimate Champions',
            'address': '0x456125Cd98107ae0480Ba566f1b716D48Ba31453',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28091/thumb/champ.png?1667472074',
            'coingeckoId': 'ultimate-champions',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALOHA',
            'name': 'Aloha',
            'address': '0x455F7Ef6D8BCfc35f9337e85aEe1B0600a59FabE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14048/thumb/aloha.png?1615527835',
            'coingeckoId': 'aloha',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KZEN',
            'name': 'Kaizen',
            'address': '0x4550003152F12014558e5CE025707E4DD841100F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24396/thumb/PKl5OVRv_400x400.png?1647522756',
            'coingeckoId': 'kaizen',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YLAB',
            'name': 'Yearn finance Infrastructure Labs',
            'address': '0x454CB9D0845bB4a28462F98C21a4fAFD16ceb25f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12744/thumb/kKGUHNFn_400x400.jpg?1602193258',
            'coingeckoId': 'yearn-finance-infrastructure-labs',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ID',
            'name': 'IdenDEFI',
            'address': '0x453660Ec2b63F0453d42f72C808350c1E2C70153',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27851/thumb/2022-10-16_17-47-37.png?1666094387',
            'coingeckoId': 'idendefi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'P2PS',
            'name': 'P2P solutions foundation',
            'address': '0x4527a3B4A8A150403090a99b87efFC96F2195047',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2798/thumb/p2ps.png?1547036973',
            'coingeckoId': 'p2p-solutions-foundation',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SANI',
            'name': 'Sanin Inu',
            'address': '0x4521C9aD6A3D4230803aB752Ed238BE11F8B342F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25222/thumb/B7r0ocfQ_400x400.jpg?1650892157',
            'coingeckoId': 'sanin-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EGT',
            'name': 'Elon GOAT',
            'address': '0x450E7F6e3a2f247A51b98C39297a9a5BfbDb3170',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22301/thumb/ElonGoat.jpg?1641438513',
            'coingeckoId': 'elon-goat',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FVT',
            'name': 'Finance Vote',
            'address': '0x45080a6531d671DDFf20DB42f93792a489685e32',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13181/thumb/finance.png?1606015010',
            'coingeckoId': 'finance-vote',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XIV',
            'name': 'Planet Inverse',
            'address': '0x44f262622248027f8E2a8Fb1090c4Cf85072392C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14231/thumb/photo_2021-06-07_22-10-41.jpg?1623075271',
            'coingeckoId': 'project-inverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATTR',
            'name': 'Attrace',
            'address': '0x44e2deC86B9F0e0266E9AA66e10323A2bd69CF9A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x44e2deC86B9F0e0266E9AA66e10323A2bd69CF9A/logo.png',
            'coingeckoId': 'attrace',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'QBU',
            'name': 'Quannabu',
            'address': '0x44dcf4Dc9Ec228b7748422C51B6C5C57F5f229ec',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17892/thumb/HsYrOmEy_400x400.jpg?1629717802',
            'coingeckoId': 'quannabu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'nTRUMP',
            'name': 'No Trump',
            'address': '0x44Ea84a85616F8e9cD719Fc843DE31D852ad7240',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/ntrump.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'INVOX',
            'name': 'Invox Finance',
            'address': '0x4485561Db76614Ff727f8E0a3Ea95690b8b16022',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3200/thumb/invox.png?1547037651',
            'coingeckoId': 'invox-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RADAR',
            'name': 'DappRadar',
            'address': '0x44709a920fCcF795fbC57BAA433cc3dd53C44DbE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x44709a920fccf795fbc57baa433cc3dd53c44dbe.png',
            'coingeckoId': 'dappradar',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'NBT',
            'name': 'NanoByte',
            'address': '0x446F2A8A39Cc730ef378Be759A3c57f1a3fE824c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23698/thumb/WpcmdLW.png?1645080885',
            'coingeckoId': 'nanobyte',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'SOLVE',
            'name': 'SOLVE',
            'address': '0x446C9033E7516D820cc9a2ce2d0B7328b579406F',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1768/thumb/Solve.Token_logo_200_200_wiyhout_BG.png?1575869846',
            'coingeckoId': 'solve-care',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TXA',
            'name': 'TXA',
            'address': '0x4463e6A3dEd0dBE3F6e15bC8420dFc55e5FeA830',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17746/thumb/jwjcN5Kx_400x400.jpg?1646381259',
            'coingeckoId': 'txa',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CSMS',
            'name': 'Cosmostarter',
            'address': '0x4461CFD640da24d1A4642Fa5f9EA3e6da966b831',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19875/thumb/cosmosstarter.PNG?1636083064',
            'coingeckoId': 'cosmostarter',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VIDT',
            'name': 'V-ID blockchain',
            'address': '0x445f51299Ef3307dBD75036dd896565F5B4BF7A5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x445f51299ef3307dbd75036dd896565f5b4bf7a5.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'BOREDM',
            'name': 'BoredMemes',
            'address': '0x445d711C8974d80643745A4666803D255a589390',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27934/thumb/pIhAX2u-_400x400.png?1666509284',
            'coingeckoId': 'boredmemes',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BASK',
            'name': 'BasketDAO',
            'address': '0x44564d0bd94343f72E3C8a0D22308B7Fa71DB0Bb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x44564d0bd94343f72e3c8a0d22308b7fa71db0bb.png',
            'coingeckoId': 'basketdao',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'CHAT',
            'name': 'BeeChat',
            'address': '0x442Bc47357919446eAbC18C7211E57a13d983469',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14386/thumb/ezgif-6-b78b8423d870.png?1615803294',
            'coingeckoId': 'beechat',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELTCOIN',
            'name': 'Eltcoin',
            'address': '0x44197A4c44D6A059297cAf6be4F7e172BD56Caaf',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1118/thumb/eltcoin.png?1547958349',
            'coingeckoId': 'eltcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNFI',
            'name': 'Unifi Protocol DAO',
            'address': '0x441761326490cACF7aF299725B6292597EE822c2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1634702180726_07549521334011788.png',
            'coingeckoId': 'unifi-protocol-dao',
            'listedIn': [
                'coingecko',
                'uniswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ACX',
            'name': 'Across Protocol',
            'address': '0x44108f0223A3C3028F5Fe7AEC7f9bb2E66beF82F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethereum-optimism.github.io/data/ACX/logo.png',
            'coingeckoId': 'across-protocol',
            'listedIn': [
                'coingecko',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'USD',
            'name': 'unified Stable Dollar',
            'address': '0x44086035439E676c02D411880FcCb9837CE37c57',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12259/thumb/Uniswap_State_Dollar.png?1598550804',
            'coingeckoId': 'uniswap-state-dollar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIBMERICAN',
            'name': 'Shibmerican',
            'address': '0x440238CC07186aDEA6653a2E8cb9a24737615609',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17008/thumb/60e1bb9c402f36243557ca79_200.png?1626066342',
            'coingeckoId': 'shibmerican',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STKATOM',
            'name': 'pSTAKE Staked ATOM',
            'address': '0x44017598f2AF1bD733F9D87b5017b4E7c1B28DDE',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x44017598f2af1bd733f9d87b5017b4e7c1b28dde.png',
            'coingeckoId': 'pstake-staked-atom',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'FLOKI',
            'name': 'FLOKI',
            'address': '0x43f11c02439e2736800433b4594994Bd43Cd066D',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://api.rubic.exchange/assets/ethereum/0x43f11c02439e2736800433b4594994bd43cd066d/logo.png',
            'coingeckoId': 'floki-inu',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ADABULL',
            'name': '3X Long Cardano Token',
            'address': '0x43dE1145Cd22f0a9Cc99e51c205e6e81161Df6B9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10203/thumb/683JEXMN_400x400_%281%29.png?1576534440',
            'coingeckoId': '3x-long-cardano-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DODO',
            'name': 'DODO',
            'address': '0x43Dfc4159D86F3A37A5A4B3D4580b888ad7d4DDd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x43dfc4159d86f3a37a5a4b3d4580b888ad7d4ddd.png',
            'coingeckoId': 'dodo',
            'listedIn': [
                'coingecko',
                '1inch',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'D2D',
            'name': 'Prime',
            'address': '0x43D4A3cd90ddD2F8f4f693170C9c8098163502ad',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21609/thumb/RJD82RrV_400x400.jpg?1639559164',
            'coingeckoId': 'prime',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POW',
            'name': 'POW',
            'address': '0x43Ab765ee05075d78AD8aa79dcb1978CA3079258',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22333/thumb/16963.png?1641515902',
            'coingeckoId': 'punks-comic-pow',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HORD',
            'name': 'Hord',
            'address': '0x43A96962254855F16b925556f9e97BE436A43448',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14972/thumb/Avatar_white.png?1619513849',
            'coingeckoId': 'hord',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FANV',
            'name': 'FanVerse',
            'address': '0x43835ca7646c07B6933D0c1C16ddC41C4Cb5600e',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23317/thumb/xcz7ZN7r_400x400.jpg?1643756752',
            'coingeckoId': 'fanverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YOSHI',
            'name': 'Yoshi.exchange',
            'address': '0x4374F26F0148a6331905eDf4cD33B89d8Eed78d1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://static.debank.com/image/eth_token/logo_url/0x4374f26f0148a6331905edf4cd33b89d8eed78d1/2935276c50b87dfdb23b9f34e192a29b.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ZEUM',
            'name': 'Colizeum',
            'address': '0x436dA116249044E8B4464F0Cf21Dd93311d88190',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24448/small/AB0cGpnx_400x400.jpg?1647681843',
            'coingeckoId': 'colizeum',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'RTE',
            'name': 'Rate3',
            'address': '0x436F0F3a982074c4a05084485D421466a994FE53',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3342/thumb/rate3-logo.png?1547037953',
            'coingeckoId': 'rate3',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NODE',
            'name': 'Pocket Node',
            'address': '0x435d664F72D6F194ef67d63B5f3936650187b131',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10376/thumb/u1L_YtHS_400x400.jpg?1583377126',
            'coingeckoId': 'pocket-node',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HAC',
            'name': 'Hackspace Capital',
            'address': '0x43567eb78638A55bbE51E9f9FB5B2D7AD1F125aa',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2336/thumb/hac.png?1547036543',
            'coingeckoId': 'hackspace-capital',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OPT',
            'name': 'Opus',
            'address': '0x4355fC160f74328f9b383dF2EC589bB3dFd82Ba0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/916/thumb/opus.png?1548330180',
            'coingeckoId': 'opus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APCG',
            'name': 'ALLPAYCOIN',
            'address': '0x434Cb4fC4b952872967914D430878Eee53Ebd502',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26836/thumb/apcg.png?1660299504',
            'coingeckoId': 'allpaycoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CNW',
            'name': 'CoinWealth',
            'address': '0x433FCe7dfbEc729A79999EAf056Cb073B2153ebA',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23769/thumb/cw_logo-4955f59a5c8079f246fa07ac71b2541870ca7d906ca1d9c26d74a3870fafef2f_%281%29.png?1645425522',
            'coingeckoId': 'coinwealth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APEFI',
            'name': 'Ape Finance',
            'address': '0x4332f8A38f14BD3D8D1553aF27D7c7Ac6C27278D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25625/small/APEFI.png?1652857977',
            'coingeckoId': 'ape-finance',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'MVP',
            'name': 'Merculet',
            'address': '0x432A2C54de2DDE941A36d2EB8c424Ed666F74Aef',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3483/thumb/feature_token.png?1547038223',
            'coingeckoId': 'merculet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DF',
            'name': 'dForce',
            'address': '0x431ad2ff6a9C365805eBaD47Ee021148d6f7DBe0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x431ad2ff6a9c365805ebad47ee021148d6f7dbe0.png',
            'coingeckoId': 'dforce-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'optimism',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'JPYC',
            'name': 'JPY Coin',
            'address': '0x431D5dfF03120AFA4bDf332c61A6e1766eF37BDB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25971/thumb/JPYC_logo_3D_cmyk_0-1_%282%29.jpg?1667467959',
            'coingeckoId': 'jpy-coin',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'PYR',
            'name': 'Vulcan Forged',
            'address': '0x430EF9263E76DAE63c84292C3409D61c598E9682',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14770/thumb/1617088937196.png?1619414736',
            'coingeckoId': 'vulcan-forged',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHFU',
            'name': 'Upper Swiss Franc',
            'address': '0x430E705C7a90D380e9c2D9DA4F547d221d6E88Cb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20038/thumb/CHFU.png?1636424729',
            'coingeckoId': 'upper-swiss-franc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CAP',
            'name': 'Cap',
            'address': '0x43044f861ec040DB59A7e324c40507adDb673142',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x43044f861ec040db59a7e324c40507addb673142.png',
            'coingeckoId': 'cap',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'BN',
            'name': 'Bitnorm',
            'address': '0x42edc1c5ff57Ff5240C90E2D8DfA269D77D68013',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14920/thumb/logo_2.71b730c9.png?1619043921',
            'coingeckoId': 'bitnorm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MEM',
            'name': 'Memecoin',
            'address': '0x42dbBd5ae373FEA2FC320F62d44C058522Bb3758',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16370/thumb/mem_gold_200x200_copy.png?1623821100',
            'coingeckoId': 'memecoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPANK',
            'name': 'SpankChain',
            'address': '0x42d6622deCe394b54999Fbd73D108123806f6a18',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethereum-optimism.github.io/data/SPANK/logo.png',
            'coingeckoId': 'spankchain',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'BOBA',
            'name': 'Boba Network',
            'address': '0x42bBFa2e77757C645eeaAd1655E0911a7553Efbc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20285/thumb/BOBA.png?1636811576',
            'coingeckoId': 'boba-network',
            'listedIn': [
                'coingecko',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'CTK',
            'name': 'Cryptyk',
            'address': '0x42a501903AFaa1086B5975773375c80E363f4063',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2729/thumb/th.jpg?1667122361',
            'coingeckoId': 'cryptyk',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RBI',
            'name': 'Durham Inu',
            'address': '0x42F3A4901B2B2c5E2d6bc8dADb8c1D8d5AfD2618',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24016/thumb/wk9djJpG_400x400.jpg?1646032297',
            'coingeckoId': 'durham-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COPI',
            'name': 'Cornucopias',
            'address': '0x42Baf1f659D765C65ADE5BB7E08eb2C680360d9d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21452/thumb/g56WwJDA_400x400.jpg?1639174968',
            'coingeckoId': 'cornucopias',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLINK',
            'name': 'BlockMason Link',
            'address': '0x42BEdD647E387daBeC65A7dc3A3bAbCc68BB664d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8525/thumb/link-by-blockmason.jpg?1559103173',
            'coingeckoId': 'blockmason-link',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HDPUNK',
            'name': 'HDPUNK Vault  NFTX ',
            'address': '0x42B4dF7e402A71EAE743c6C5410CE3BBb63aEf22',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17069/thumb/HDPunks.png?1626151243',
            'coingeckoId': 'hdpunk-vault-nftx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IMP',
            'name': 'Imperial Obelisk  OLD ',
            'address': '0x42A0D24Cb5c423EAAF926cE3984aAfF0C4ff6FE2',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19083/thumb/imp_logo.png?1634377746',
            'coingeckoId': 'imperial-obelisk',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PLTC',
            'name': 'PlatonCoin',
            'address': '0x429D83Bb0DCB8cdd5311e34680ADC8B12070a07f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7178/thumb/PLTC.png?1616126045',
            'coingeckoId': 'platoncoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PICKLE',
            'name': 'Pickle Finance',
            'address': '0x429881672B9AE42b8EbA0E26cD9C73711b891Ca5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x429881672b9ae42b8eba0e26cd9c73711b891ca5.png',
            'coingeckoId': 'pickle-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'CNT',
            'name': 'Cryption Network',
            'address': '0x429876c4a6f89FB470E92456B8313879DF98B63c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/9747.png',
            'coingeckoId': 'cryption-network',
            'listedIn': [
                'coingecko',
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'HOTCROSS',
            'name': 'Hot Cross',
            'address': '0x4297394c20800E8a38A619A243E9BbE7681Ff24E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15706/thumb/Hotcross.png?1632197570',
            'coingeckoId': 'hot-cross',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UIP',
            'name': 'UnlimitedIP',
            'address': '0x4290563C2D7c255B5EEC87f2D3bD10389f991d68',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2642/thumb/unlimitedip.png?1547036818',
            'coingeckoId': 'unlimitedip',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MQL',
            'name': 'MiraQle',
            'address': '0x428dc22668E6F3468273634067e5545ED5417A3E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12278/thumb/2VTVSgXn_400x400.jpg?1598738364',
            'coingeckoId': 'miraqle',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALI',
            'name': 'AiLink',
            'address': '0x4289c043A12392F1027307fB58272D8EBd853912',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5917/thumb/ailink-token.png?1547041855',
            'coingeckoId': 'ailink-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ISIKC',
            'name': 'Isiklar Coin',
            'address': '0x42726d074BBa68Ccc15200442B72Afa2D495A783',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10992/thumb/logo_%2866%29.png?1586940186',
            'coingeckoId': 'isiklar-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FOTA',
            'name': 'Fortuna',
            'address': '0x4270bb238f6DD8B1c3ca01f96CA65b2647c06D3C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2689/thumb/fortuna.png?1547483931',
            'coingeckoId': 'fortuna',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BPLC',
            'name': 'BlackPearl',
            'address': '0x426FC8BE95573230f6e6bc4af91873F0c67b21b4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8931/thumb/EJIpComQ_400x400.png?1584653141',
            'coingeckoId': 'blackpearl-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MNE',
            'name': 'Minereum',
            'address': '0x426CA1eA2406c07d75Db9585F22781c096e3d0E0',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/702/thumb/mne.png?1587615060',
            'coingeckoId': 'minereum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STR',
            'name': 'Staker',
            'address': '0x426567F78e74577f8a6233B635970eb729631e05',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5122/thumb/staker-logo.png?1547040513',
            'coingeckoId': 'staker',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MADHAT',
            'name': 'Mad Hatter Society',
            'address': '0x425c5b7B55F9C981C71935418ED044E79D8080E2',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27407/thumb/MADHAT_TOKEN_CROP.png?1663842237',
            'coingeckoId': 'mad-hatter-society',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LOOM',
            'name': 'Loom Network  NEW ',
            'address': '0x42476F744292107e34519F9c357927074Ea3F75D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14735/thumb/LOOM.png?1617967111',
            'coingeckoId': 'loom-network-new',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOT',
            'name': 'GoNetwork',
            'address': '0x423b5F62b328D0D6D44870F4Eee316befA0b2dF5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3291/thumb/gonetwork.png?1547743011',
            'coingeckoId': 'gonetwork',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JMPT',
            'name': 'JumpToken',
            'address': '0x420a24c9c65bd44c48bfB1cC8D6cD1ea8B1Ac840',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22603/thumb/200x200.png?1642162597',
            'coingeckoId': 'jumptoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BRZ',
            'name': 'Brazilian Digital',
            'address': '0x420412E765BFa6d85aaaC94b4f7b708C89be2e2B',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8472/thumb/2_vtiD3xwce9knG2COxtSMaQ.png?1597786961',
            'coingeckoId': 'brz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CVC',
            'name': 'Civic',
            'address': '0x41e5560054824eA6B0732E656E3Ad64E20e94E45',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x41e5560054824ea6b0732e656e3ad64e20e94e45.png',
            'coingeckoId': 'civic',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'MTN',
            'name': 'Medicalchain',
            'address': '0x41dBECc1cdC5517C6f76f6a6E836aDbEe2754DE3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2850/thumb/medicalchain.png?1547037019',
            'coingeckoId': 'medicalchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NOAHP',
            'name': 'Noah Decentralized State Coin',
            'address': '0x41b3F18c6384Dc9A39c33AFEcA60d9b8e61eAa9F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3442/thumb/FvG3XweD.png?1610437675',
            'coingeckoId': 'noah-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDU',
            'name': 'Upper Dollar',
            'address': '0x41a03E41eF555392c9f0AD60f4F61E263078BF10',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12332/thumb/usdu.png?1642929548',
            'coingeckoId': 'upper-dollar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INV',
            'name': 'Inverse Finance',
            'address': '0x41D5D79431A913C4aE7d69a668ecdfE5fF9DFB68',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x41d5d79431a913c4ae7d69a668ecdfe5ff9dfb68.png',
            'coingeckoId': 'inverse-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'MMSFT',
            'name': 'Mirrored Microsoft',
            'address': '0x41BbEDd7286dAab5910a1f15d12CBda839852BD7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/mmsft.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'XDCE',
            'name': 'XinFin',
            'address': '0x41AB1b6fcbB2fA9DCEd81aCbdeC13Ea6315F2Bf2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x41ab1b6fcbb2fa9dced81acbdec13ea6315f2bf2.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'BLANK',
            'name': 'BlockWallet',
            'address': '0x41A3Dba3D677E573636BA691a70ff2D606c29666',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xaec7e1f531bb09115103c53ba76829910ec48966.png',
            'coingeckoId': 'blank',
            'listedIn': [
                'coingecko',
                '1inch',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'ABPT',
            'name': 'Aave Balancer Pool Token',
            'address': '0x41A08648C3766F9F9d85598fF102a08f4ef84F84',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16788/thumb/ABPT_2x.png?1625046056',
            'coingeckoId': 'aave-balancer-pool-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DRGN',
            'name': 'Dragonchain',
            'address': '0x419c4dB4B9e25d6Db2AD9691ccb832C8D9fDA05E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1289/thumb/dragonchain.png?1547957761',
            'coingeckoId': 'dragonchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FUN',
            'name': 'FUN',
            'address': '0x419D0d8BdD9aF5e606Ae2232ed285Aff190E711b',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x419d0d8bdd9af5e606ae2232ed285aff190e711b.png',
            'coingeckoId': 'funfair',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ALT',
            'name': 'AltEstate',
            'address': '0x419B8ED155180A8c9C64145e76DaD49c0A4Efb97',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3076/thumb/AltEstate_Token.jpg?1547037426',
            'coingeckoId': 'alt-estate',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BNB',
            'name': 'Binance Coin  Wormhole ',
            'address': '0x418D75f65a02b3D53B2418FB8E1fe493759c7605',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22884/thumb/BNB_wh_small.png?1644224553',
            'coingeckoId': 'binance-coin-wormhole',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FTXT',
            'name': 'FUTURAX',
            'address': '0x41875C2332B0877cDFAA699B641402b7D4642c32',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5796/thumb/futurax.png?1547484031',
            'coingeckoId': 'futurax',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TPT',
            'name': 'TokenPocket Token',
            'address': '0x4161725D019690a3E0de50f6bE67b07a86A9fAe1',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7603/thumb/pocket.jpg?1658907018',
            'coingeckoId': 'token-pocket',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-14319-ROSEM',
            'name': 'RealT   14319 Rosemary St  Detroit  MI ',
            'address': '0x41599149f1B52035392402F9e311b1edb0C9f699',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16592/thumb/14319-Rosemary-hero-1-800x600.jpeg?1624539171',
            'coingeckoId': '14319-rosemary',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SALT',
            'name': 'SALT',
            'address': '0x4156D3342D5c385a87D264F90653733592000581',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x4156d3342d5c385a87d264f90653733592000581.png',
            'coingeckoId': 'salt',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'FORT',
            'name': 'Forta',
            'address': '0x41545f8b9472D758bB669ed8EaEEEcD7a9C4Ec29',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25060/thumb/Forta_lgo_%281%29.png?1655353696',
            'coingeckoId': 'forta',
            'listedIn': [
                'coingecko',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'EXE',
            'name': '8X8 Protocol',
            'address': '0x412D397DDCa07D753E3E0C61e367fb1b474B3E7D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9466/thumb/8x8_symbol_512x.png?1574320199',
            'coingeckoId': '8x8-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QRDO',
            'name': 'Qredo',
            'address': '0x4123a133ae3c521FD134D7b13A2dEC35b56c2463',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1668215197123_2832419181148085.png',
            'coingeckoId': 'qredo',
            'listedIn': [
                'coingecko',
                'uniswap',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ONI',
            'name': 'Onino',
            'address': '0x411f3e09C66B30E7FACFec45Cd823B2E19dFAD2D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BUILD',
            'name': 'Gr33n',
            'address': '0x410e7696dF8Be2a123dF2cf88808c6ddAb2ae2BF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26932/thumb/Screen-Shot-2022-08-18-at-12-15-03-PM_%281%29.png?1660887525',
            'coingeckoId': 'gr33n',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ONIT',
            'name': 'ONBUFF',
            'address': '0x410e731c2970Dce3AdD351064AcF5cE9E33FDBf0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12252/thumb/d4suDHSzEZjDXc0uxFGPZXfDFp0L1tfoEusBx03zB1bvMLLbxa7Yq5naXAOutkirIlLXacFRRebX_hF2Ez69_fIPUfr2LuN0Z_UrXTLYds4aT95C6eHGXsZnezMHKAaOmjLpKto0xPTiYkXExvEvXrqL7O_J1wecb4eJsgwCoVprMLpm89BdKO9IyMmpNsfbW7JJFMiHgL8vq9fPhaeEi.jpg?1598519096',
            'coingeckoId': 'onbuff',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APW',
            'name': 'APWine',
            'address': '0x4104b135DBC9609Fc1A9490E61369036497660c8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x4104b135dbc9609fc1a9490e61369036497660c8.png',
            'coingeckoId': 'apwine',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SHA',
            'name': 'Safe Haven',
            'address': '0x40fEd5691e547885cABd7A2990De719DCc8497FC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2584/thumb/safehaven.png?1620037471',
            'coingeckoId': 'safe-haven',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CON',
            'name': 'Connectico',
            'address': '0x40d2025Ed2E89632D3A41d8541DF9Ed2AC0e2b1c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22853/thumb/9005.png?1642750464',
            'coingeckoId': 'connectico',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOLD',
            'name': 'Golden Goose',
            'address': '0x40d1F63B5D2048e67E9bEdB1B4c2F1A9fb4b6817',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7892/thumb/tsRcntSR_400x400.png?1611024068',
            'coingeckoId': 'golden-goose',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YEA',
            'name': 'YeaFinance',
            'address': '0x40b92fCE37CEfA03baf7603e7913C1d34dD1a4EC',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12705/thumb/logoeth.png?1601877470',
            'coingeckoId': 'yeafinance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IDAI',
            'name': 'Instadapp DAI',
            'address': '0x40a9d39aa50871Df092538c5999b107f34409061',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25821/thumb/iDAI_100x100.png?1654055549',
            'coingeckoId': 'instadapp-dai',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XOR',
            'name': 'Sora',
            'address': '0x40FD72257597aA14C7231A7B1aaa29Fce868F677',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x40fd72257597aa14c7231a7b1aaa29fce868f677.png',
            'coingeckoId': 'sora',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'IAG',
            'name': 'Iagon',
            'address': '0x40EB746DEE876aC1E78697b7Ca85142D178A1Fc8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3264/thumb/d8c5hLbX9u0GwYCKcZRbXS2vAQ0Vd-Hfjg-3zQ73ucSZQoFYsLH4NEKN8EQkwwBVR8OPJgrTRG-_dW_XVHL058ezYSvwsSB4bjYtHH7xjZNHBaAaX1NZl7axG8zm2FIRV6AUmgdmxcbP0BcuWvUJkcUKrYYEDf0Msx2_3arxgmS1V85YMb_1SVbWt6E3QnkpvLcGyC0SxN6rGTr.jpg?1627448798',
            'coingeckoId': 'iagon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APE',
            'name': 'APE',
            'address': '0x40E0a6eF9DbADfc83C5e0d15262FEB4638588D77',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20762/thumb/uXhjvO7.png?1637651257',
            'coingeckoId': 'ape',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GALI',
            'name': 'Galileo',
            'address': '0x40A11F82A8469c8DC015ba74438536584978d63C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28007/thumb/gali.jpg?1666939897',
            'coingeckoId': 'galileo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TOS',
            'name': 'TONStarter',
            'address': '0x409c4D8cd5d2924b9bc5509230d16a61289c8153',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17878/thumb/tos.PNG?1629703380',
            'coingeckoId': 'tonstarter',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UFARM',
            'name': 'UniFarm',
            'address': '0x40986a85B4cFCDb054A6CBFB1210194fee51af88',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15247/thumb/ufarm.jpeg?1620218765',
            'coingeckoId': 'unifarm',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'DAISY',
            'name': 'Daisy Protocol',
            'address': '0x40955D77F87123b71b145098358A60573ac7BE96',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17844/thumb/daisy.PNG?1629497116',
            'coingeckoId': 'daisy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LPG',
            'name': 'LPGToken',
            'address': '0x40932D9cd67e89749406F16eDD2D211B7ab1ff50',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'OCN',
            'name': 'Odyssey',
            'address': '0x4092678e4E78230F46A1534C0fbc8fA39780892B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2559/thumb/ocn.png?1547036683',
            'coingeckoId': 'odyssey',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REN',
            'name': 'REN',
            'address': '0x408e41876cCCDC0F92210600ef50372656052a38',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x408e41876cccdc0f92210600ef50372656052a38.png',
            'coingeckoId': 'republic-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FLETA',
            'name': 'FLETA',
            'address': '0x40897C872214303b6F479a37E549eE1516B264A2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7865/thumb/Fleta_new_logo_%282%29.png?1623520180',
            'coingeckoId': 'fleta',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BRWL',
            'name': 'Blockchain Brawlers',
            'address': '0x4086E77C5E993FDB90a406285d00111a974F877a',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24701/thumb/fuDdvI8Y_400x400.png?1648642627',
            'coingeckoId': 'blockchain-brawlers',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QUAI',
            'name': 'Quai Dao',
            'address': '0x40821CD074dfeCb1524286923bC69315075b5c89',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13815/thumb/QUIA_32x32.png?1656133041',
            'coingeckoId': 'quai-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPOOL',
            'name': 'Spool DAO',
            'address': '0x40803cEA2b2A32BdA1bE61d3604af6a814E70976',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21532/thumb/spool.png?1639388809',
            'coingeckoId': 'spool-dao-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OTHR',
            'name': 'OtherDAO',
            'address': '0x407a3E019c655B779ccD098Ff50377E4C5F1C334',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25882/thumb/othr.png?1654334543',
            'coingeckoId': 'otherdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MEISHU',
            'name': 'Meishu',
            'address': '0x406B9DCA8b52f08385014eC1eD1cf6A0D5c01289',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24112/thumb/LDtBuFcu_400x400.jpg?1646378468',
            'coingeckoId': 'meishu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HONEYD',
            'name': 'Honey Deluxe',
            'address': '0x40615B82999b8aa46803F11493BeDAB0314EB5E7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21164/thumb/honeyd.png?1638530042',
            'coingeckoId': 'honey-deluxe',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METR',
            'name': 'Metria Network',
            'address': '0x405CE8B2eAeeA7D4Ba5Fc160848cB2A6569e03f0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24870/thumb/Metria-logo_1.png?1649213114',
            'coingeckoId': 'metria',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TFBX',
            'name': 'Truefeedback',
            'address': '0x4057Db5bD9f67A566aA10E5587b1a964afFc6a16',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8842/thumb/5rd7a55q_400x400.png?1562902557',
            'coingeckoId': 'truefeedbackchain',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'MGO',
            'name': 'MobileGo',
            'address': '0x40395044Ac3c0C57051906dA938B54BD6557F212',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/768/thumb/mobilego.png?1548085237',
            'coingeckoId': 'mobilego',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOV',
            'name': 'MOTIV Protocol',
            'address': '0x40284109c3309A7C3439111bFD93BF5E0fBB706c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11869/thumb/jvftnBL-_400x400.jpg?1595498693',
            'coingeckoId': 'motiv-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LIMEX',
            'name': 'Limestone Network',
            'address': '0x400b1d8a7dd8C471026B2c8CBe1062b27d120538',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11907/thumb/nw1FE_f4_400x400.png?1596074376',
            'coingeckoId': 'limestone-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KEK',
            'name': 'Cryptokek',
            'address': '0x3fa400483487A489EC9b1dB29C4129063EEC4654',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13513/thumb/Cryptokek-Logo-256px.png?1609292074',
            'coingeckoId': 'cryptokek',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'IDLEDAIYIELD',
            'name': 'IdleDAI  Best Yield ',
            'address': '0x3fE7940616e5Bc47b0775a0dccf6237893353bB4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11901/thumb/idledai_32.png?1595990312',
            'coingeckoId': 'idle-dai-yield',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'mooJoeUSDC.e-USDC',
            'name': 'Moo Joe USDC.e-USDC',
            'address': '0x3fB256CFefedb6A54De7465c0EE86dC574AE464D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DKUMA',
            'name': 'KumaDex Token',
            'address': '0x3f5dd1A1538a4F9f82E543098f01F22480B0A3a8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26693/thumb/dkumaOG02_CoinGecko.png?1660618296',
            'coingeckoId': 'kumadex-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STZ',
            'name': '99Starz',
            'address': '0x3f5294DF68F871241c4B18fcF78ebD8Ac18aB654',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x3f5294DF68F871241c4B18fcF78ebD8Ac18aB654/logo.png',
            'coingeckoId': '99starz',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'JINDOGE',
            'name': 'Jindoge',
            'address': '0x3f4Cd830543Db25254EC0f05eAc058D4D6E86166',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15417/thumb/JINDOGE.png?1620774122',
            'coingeckoId': 'jindoge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SATA',
            'name': 'Signata',
            'address': '0x3ebb4A4e91Ad83BE51F8d596533818b246F4bEe1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14704/thumb/logo.png?1617853256',
            'coingeckoId': 'signata',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'NYR',
            'name': 'New Year Resolution',
            'address': '0x3eCF9840DEB8e3c395E1941Fc39ceB662BF5A1Dd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22201/thumb/Q-5kE0wd_400x400.jpg?1641193367',
            'coingeckoId': 'new-year-resolution',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOOST',
            'name': 'Boosted Finance',
            'address': '0x3e780920601D61cEdb860fe9c4a90c9EA6A35E78',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3e780920601d61cedb860fe9c4a90c9ea6a35e78.png',
            'coingeckoId': 'boosted-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'GPX',
            'name': 'GPEX',
            'address': '0x3e7804c51a70Ba26E904c2e0ab440C5623a8A83F',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22584/thumb/GPEX-E_200.png?1642142068',
            'coingeckoId': 'gpex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UUNICLY',
            'name': 'Unicly Genesis Collection',
            'address': '0x3d9233F15BB93C78a4f07B5C5F7A018630217cB3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14903/thumb/Unicly_%28unicly%29.jpg?1618937278',
            'coingeckoId': 'unicly-genesis-collection',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TAILS',
            'name': 'Tails',
            'address': '0x3d79Abb948bc76794ff4a0bCd60170a741f26360',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19065/thumb/Tails_LOGO.png?1634266919',
            'coingeckoId': 'tails',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CARDS',
            'name': 'Cardstarter',
            'address': '0x3d6F0DEa3AC3C607B3998e6Ce14b6350721752d9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3d6f0dea3ac3c607b3998e6ce14b6350721752d9.png',
            'coingeckoId': 'cardstarter',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'EOSBEAR',
            'name': '3X Short EOS Token',
            'address': '0x3d3dd61b0F9A558759a21dA42166042B114E12D5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10186/thumb/683JEXMN_400x400_%281%29.png?1576508730',
            'coingeckoId': '3x-short-eos-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WCV',
            'name': 'Wellness Convertible',
            'address': '0x3d3A4098bca71F1Fee2e28d0B9DB9BbE0F708EA5',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24849/thumb/WCV-TOKEN-LOGO-Wellness-Group.png?1649131483',
            'coingeckoId': 'wellness-convertible',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OMG',
            'name': 'Omega Finance',
            'address': '0x3d1f5E133c2988D14a0C13BDd2b58d260e74ef9a',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26511/thumb/1D23BCAF-AD7E-4176-B652-DFC5AE943191.png?1658448874',
            'coingeckoId': 'omega-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RVT',
            'name': 'Rivetz',
            'address': '0x3d1BA9be9f66B8ee101911bC36D3fB562eaC2244',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x3d1ba9be9f66b8ee101911bc36d3fb562eac2244.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'METRA',
            'name': 'Metra',
            'address': '0x3cD06B0010fEba4216B85B4477125cAc1E708de0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23676/thumb/metra_%281%29.png?1644996640',
            'coingeckoId': 'metra',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PANDA',
            'name': 'PandaDAO',
            'address': '0x3cBb7f5d7499Af626026E96a2f05df806F2200DC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24926/thumb/PandaDAO_logo_512.png?1649388983',
            'coingeckoId': 'pandadao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOR',
            'name': 'BoringDAO  OLD ',
            'address': '0x3c9d6c1C73b31c837832c72E04D3152f051fc1A9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/bor.jpg',
            'coingeckoId': 'boringdao-[old]',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'LEOBEAR',
            'name': '3X Short LEO Token',
            'address': '0x3c955e35b6da1fF623D38D750c85b3Aed89A10c1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10327/thumb/683JEXMN_400x400_%281%29.png?1578050692',
            'coingeckoId': '3x-short-leo-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFTL',
            'name': 'Nifty League',
            'address': '0x3c8D2FCE49906e11e71cB16Fa0fFeB2B16C29638',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x3c8D2FCE49906e11e71cB16Fa0fFeB2B16C29638/logo.png',
            'coingeckoId': 'nifty-league',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'FIT',
            'name': '300FIT',
            'address': '0x3c72fca8523686fd9e5740b0826FA4bB376E0241',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9690/thumb/6mkyI4Ss_400x400.png?1570832640',
            'coingeckoId': '300fit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MODX',
            'name': 'MODEL X coin',
            'address': '0x3c6Da7763cAA0e4b684BbC733f04a8EC08Af3762',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6603/thumb/LOGO-MODX.png?1547042808',
            'coingeckoId': 'model-x-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CDS',
            'name': 'Capital DAO Starter',
            'address': '0x3c48Ca59bf2699E51d4974d4B6D284AE52076e5e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20332/thumb/cds.png?1661762062',
            'coingeckoId': 'capital-dao-starter-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KNIGHTS',
            'name': 'Elden Knights',
            'address': '0x3bbd4cB91442d8aeaEB0523A5F99540658A685ea',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27406/thumb/Eldenknights-LOGO.png?1663842018',
            'coingeckoId': 'elden-knights',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GHOUL',
            'name': 'Ghoul',
            'address': '0x3bb86d867A9F3adDF994cdaDb210Fa82F0D4157A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16455/thumb/rsz-ghoul2.png?1624249816',
            'coingeckoId': 'ghoul-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XPN',
            'name': 'PANTHEON X',
            'address': '0x3b9e094D56103611f0ACEfDAb43182347BA60dF4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8297/thumb/Hr9wHItp_400x400.jpg?1557307478',
            'coingeckoId': 'pantheon-x',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WMEMO',
            'name': 'Wonderful Memories',
            'address': '0x3b79a28264fC52c7b4CEA90558AA0B162f7Faf57',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22392/thumb/wMEMO.png?1665832927',
            'coingeckoId': 'wrapped-memory',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'CYCLUB',
            'name': 'Cyclub',
            'address': '0x3b58c52C03ca5Eb619EBa171091c86C34d603e5f',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12524/thumb/mcicoin-logo.png?1600471686',
            'coingeckoId': 'mci-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GHD',
            'name': 'Giftedhands',
            'address': '0x3b544e6fcf6C8dCE9D8B45A4FdF21C9B02f9fDa9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12540/thumb/K-8uHktJ.png?1600644856',
            'coingeckoId': 'giftedhands',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ITAM',
            'name': 'ITAM',
            'address': '0x3b4f7CB9e60362A49dD04EB0091A374d340E3EfD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x3b4f7cb9e60362a49dd04eb0091a374d340e3efd.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SOS',
            'name': 'OpenDAO',
            'address': '0x3b484b82567a09e2588A13D54D032153f0c0aEe0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3b484b82567a09e2588a13d54d032153f0c0aee0.png',
            'coingeckoId': 'opendao',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'wsSQUID',
            'name': 'Wrapped sSQUID',
            'address': '0x3b1388eB39c72D2145f092C01067C02Bb627d4BE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': '$FS',
            'name': 'Floki Santa',
            'address': '0x3afCc1Eab77631F8F503592dAFEE09c7735988c0',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://api.rubic.exchange/assets/ethereum/0x3afcc1eab77631f8f503592dafee09c7735988c0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'KROM',
            'name': 'Kromatika',
            'address': '0x3af33bEF05C2dCb3C7288b77fe1C8d2AeBA4d789',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3af33bef05c2dcb3c7288b77fe1c8d2aeba4d789.png',
            'coingeckoId': 'kromatika',
            'listedIn': [
                'coingecko',
                '1inch',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'CJT',
            'name': 'ConnectJob',
            'address': '0x3abdfF32F76b42E7635bdb7e425f0231A5F3aB17',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2207/thumb/connectjob.png?1547739980',
            'coingeckoId': 'connectjob',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JBX',
            'name': 'Juicebox',
            'address': '0x3abF2A4f8452cCC2CF7b4C1e4663147600646f66',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21120/thumb/CCeIEmvE_400x400.jpg?1638341224',
            'coingeckoId': 'juicebox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CSX',
            'name': 'Coinstox',
            'address': '0x3aadc3BCe49724ce299fD9F3850211211c399C8c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14373/thumb/CSX-token-200.png?1615771653',
            'coingeckoId': 'coinstox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XMON',
            'name': 'XMON',
            'address': '0x3aaDA3e213aBf8529606924d8D1c55CbDc70Bf74',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3aada3e213abf8529606924d8d1c55cbdc70bf74.png',
            'coingeckoId': 'xmon',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'DSLA',
            'name': 'DSLA Protocol',
            'address': '0x3aFfCCa64c2A6f4e3B6Bd9c64CD2C969EFd1ECBe',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3affcca64c2a6f4e3b6bd9c64cd2c969efd1ecbe.png',
            'coingeckoId': 'stacktical',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'UWBTC',
            'name': 'Unagii Wrapped Bitcoin',
            'address': '0x3aF5Ba94C29a8407785f5f6d90eF5d69a8EB2436',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14625/thumb/uBTC.png?1617294190',
            'coingeckoId': 'unagii-wrapped-bitcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PARA',
            'name': 'Paralink Network',
            'address': '0x3a8d5BC8A8948b68DfC0Ce9C14aC4150e083518c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15789/thumb/para.PNG?1621893038',
            'coingeckoId': 'paralink-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PLA',
            'name': 'PlayDapp',
            'address': '0x3a4f40631a4f906c2BaD353Ed06De7A5D3fCb430',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3a4f40631a4f906c2bad353ed06de7a5d3fcb430.png',
            'coingeckoId': 'playdapp',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'XLMBULL',
            'name': '3X Long Stellar',
            'address': '0x3a43a04D80F9881d88080bf9fA8bB720AFb6c966',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13334/thumb/assets_coingecko_com-ftt.jpg?1607566862',
            'coingeckoId': '3x-long-stellar-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AETH',
            'name': 'Aave ETH v1',
            'address': '0x3a3A65aAb0dd2A17E3F1947bA16138cd37d08c04',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3a3a65aab0dd2a17e3f1947ba16138cd37d08c04.png',
            'coingeckoId': 'aave-eth-v1',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'FLP',
            'name': 'Gameflip',
            'address': '0x3a1Bda28AdB5B0a812a7CF10A1950c920F79BcD3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2173/thumb/flp.png?1547036470',
            'coingeckoId': 'gameflip',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PINU',
            'name': 'Piccolo Inu',
            'address': '0x3a1311B8C404629E38f61D566cefEFed083B9670',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19335/thumb/Piccolo-Inu.png?1643191682',
            'coingeckoId': 'piccolo-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STPC',
            'name': 'StarPlay',
            'address': '0x3Fb8D8A28AFf053CcF446BC075eEcb7a0Ef65D0c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9705/thumb/8fp5wsxp_400x400.png?1570920359',
            'coingeckoId': 'starplay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REW',
            'name': 'Rewardiqa',
            'address': '0x3FFfFA8F3cc943e43f9f17a83Cbb18f4Bbb9f4AC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11614/thumb/REW_medium.png?1594698840',
            'coingeckoId': 'rewardiqa',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RTH',
            'name': 'Rotharium',
            'address': '0x3FD8f39A962eFDA04956981C31AB89FAB5FB8bC8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2030/thumb/L7kpRMda_400x400.jpg?1547036314',
            'coingeckoId': 'rotharium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CAST',
            'name': 'Castello Coin',
            'address': '0x3FAb0bBAa03BCEAF7C49E2b12877dB0142BE65FC',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25216/thumb/19705.png?1650866493',
            'coingeckoId': 'castello-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOVE',
            'name': 'Mover',
            'address': '0x3FA729B4548beCBAd4EaB6EF18413470e6D5324C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3fa729b4548becbad4eab6ef18413470e6d5324c.png',
            'coingeckoId': 'holyheld-2',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'PHM',
            'name': 'Phantom Protocol',
            'address': '0x3F9BEC82c776c47405BcB38070d2395Fd18F89d3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18253/thumb/phm.PNG?1631137732',
            'coingeckoId': 'phantom-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VOLT',
            'name': 'Volt Inu  OLD ',
            'address': '0x3F7Aff0EF20AA2E646290DfA4E67611B2220C597',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21832/thumb/nl250.png?1643191234',
            'coingeckoId': 'volt-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SCLP',
            'name': 'ScallopX',
            'address': '0x3F6D1649A1366b0E82173D33e365953f9F1Cc84C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ICONS',
            'name': 'SportsIcon',
            'address': '0x3F68e7B44e9bCB486C2FeAdB7A2289D9cdFC9088',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20856/thumb/icons-coin-gold.png?1638349473',
            'coingeckoId': 'sportsicon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RHO',
            'name': 'Rho',
            'address': '0x3F3Cd642E81d030D7b514a2aB5e3a5536bEb90Ec',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25540/thumb/Rho-icon-03.png?1652265700',
            'coingeckoId': 'rho-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GHST',
            'name': 'Aavegotchi',
            'address': '0x3F382DbD960E3a9bbCeaE22651E88158d2791550',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3f382dbd960e3a9bbceae22651e88158d2791550.png',
            'coingeckoId': 'aavegotchi',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'AMPT',
            'name': 'Amplify',
            'address': '0x3F17cfAd23C2014C5a32722557Df87DFF46819dA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23695/thumb/ampt_pictogram_color_1920px_2.png?1645080512',
            'coingeckoId': 'amplify',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KSEED',
            'name': 'Kush Finance',
            'address': '0x3F09400313e83d53366147e3ea0e4e2279D80850',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12545/thumb/kush.finance-512.png?1600844515',
            'coingeckoId': 'kush-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AUSDT',
            'name': 'Aave USDT',
            'address': '0x3Ed3B47Dd13EC9a98b44e6204A523E766B225811',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3ed3b47dd13ec9a98b44e6204a523e766b225811.png',
            'coingeckoId': 'aave-usdt',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BRNG',
            'name': 'bRing Finance',
            'address': '0x3Ecb96039340630c8B82E5A7732bc88b2aeadE82',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17836/thumb/bring_finance.PNG?1629417184',
            'coingeckoId': 'bring-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ENTC',
            'name': 'EnterButton',
            'address': '0x3Ecab35B64345bfC472477A653e4A3abE70532D9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19677/thumb/ENTC_symbol.png?1635740712',
            'coingeckoId': 'enterbutton',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GRO',
            'name': 'Gro DAO',
            'address': '0x3Ec8798B81485A254928B70CDA1cf0A2BB0B74D7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18673/thumb/613f171979749061aaa1edf9_Icon-GRO-256x256-Square.png?1632876204',
            'coingeckoId': 'gro-dao-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLX',
            'name': 'FluxProtocol',
            'address': '0x3Ea8ea4237344C9931214796d9417Af1A1180770',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21137/thumb/flux-flx.jpeg?1638372222',
            'coingeckoId': 'flux-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUNNY',
            'name': 'Rocket Bunny',
            'address': '0x3Ea50B7Ef6a7eaf7E966E2cb72b519C16557497c',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14350/thumb/n8TgW06A_400x400.jpg?1615514406',
            'coingeckoId': 'rocket-bunny',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAO',
            'name': 'Sator',
            'address': '0x3EF389f264e07fFF3106A3926F2a166d1393086F',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19410/thumb/sator-logo-CMC.png?1635211626',
            'coingeckoId': 'sator',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BIXCPRO',
            'name': 'BIXCPRO',
            'address': '0x3E9e371f8d2E9fCA315fB0A747533cEd8A3FCbCb',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7893/thumb/download.jpg?1551429825',
            'coingeckoId': 'bixcpro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LINA',
            'name': 'Linear',
            'address': '0x3E9BC21C9b189C09dF3eF1B824798658d5011937',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3e9bc21c9b189c09df3ef1b824798658d5011937.png',
            'coingeckoId': 'linear',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SOME',
            'name': 'Mixsome',
            'address': '0x3E8FFc8c3Cb0DB3081Df85DeC91B63abBbe99F71',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15005/thumb/mixsome.PNG?1619412351',
            'coingeckoId': 'mixsome',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CLNY',
            'name': 'Colony Network',
            'address': '0x3E828ac5C480069D4765654Fb4b8733b910b13b2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23269/thumb/Logo.png?1644482438',
            'coingeckoId': 'colony-network-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XMT',
            'name': 'MetalSwap',
            'address': '0x3E5D9D8a63CC8a88748f229999CF59487e90721e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22075/thumb/xmt.png?1640943637',
            'coingeckoId': 'metalswap',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'CATCOIN',
            'name': 'CatCoin com',
            'address': '0x3E362283B86C1b45097CC3FB02213b79CF6211Df',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25279/thumb/logo_%281%29.png?1651126078',
            'coingeckoId': 'catcoin-cash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HQT',
            'name': 'HyperQuant',
            'address': '0x3E1d5A855aD9D948373aE68e4fe1f094612b1322',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4410/thumb/hqt.png?1547039746',
            'coingeckoId': 'hyperquant',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TOWN',
            'name': 'Town Star',
            'address': '0x3Dd98C8A089dBCFF7e8FC8d4f532BD493501Ab7F',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19581/thumb/town.png?1635840875',
            'coingeckoId': 'town-star',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'DEGO',
            'name': 'Dego Finance',
            'address': '0x3Da932456D082CBa208FEB0B096d49b202Bf89c8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12503/thumb/c185FKx.png?1600298167',
            'coingeckoId': 'dego-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YFN',
            'name': 'Yearn Finance Network',
            'address': '0x3DD66732113Af9981A861Cf489431533aebA33B8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12821/thumb/9v36orK.jpg?1603191959',
            'coingeckoId': 'yearn-finance-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LTO',
            'name': 'LTO Network Token',
            'address': '0x3DB6Ba6ab6F95efed1a6E794caD492fAAabF294D',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3db6ba6ab6f95efed1a6e794cad492faaabf294d.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'PAA',
            'name': 'Palace',
            'address': '0x3D9Ac8e7a9C9bE11DFac1677dDa901E28d44527f',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11141/thumb/da443cb2601f5fbdab9a2e176d62d6ce.png?1588911369',
            'coingeckoId': 'palace',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COVAL',
            'name': 'Circuits of Value',
            'address': '0x3D658390460295FB963f54dC0899cfb1c30776Df',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3d658390460295fb963f54dc0899cfb1c30776df.png',
            'coingeckoId': 'circuits-of-value',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'HONEYBADGER',
            'name': 'Honey Badger',
            'address': '0x3D60f2bDe6762ce9cE45945d05bc6846d12A140e',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21497/thumb/w9AapCgG_400x400.jpg?1639368909',
            'coingeckoId': 'honey-badger',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UCX',
            'name': 'UCX',
            'address': '0x3D3af44cf092a49280e316f09c8f20ecf97BC933',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10131/thumb/UCX_200200.png?1576186720',
            'coingeckoId': 'ucx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VIDYA',
            'name': 'Vidya',
            'address': '0x3D3D35bb9bEC23b06Ca00fe472b50E7A4c692C30',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3d3d35bb9bec23b06ca00fe472b50e7a4c692c30.png',
            'coingeckoId': 'vidya',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ANV',
            'name': 'Aniverse',
            'address': '0x3D382228C54736d831FAC2748F4734D9177c7332',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16348/thumb/anv.PNG?1623794994',
            'coingeckoId': 'aniverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YOUC',
            'name': 'YOUcash',
            'address': '0x3D371413dd5489F3A04C07c0C2CE369c20986ceb',
            'decimals': 10,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11152/thumb/round-400x400.png?1589162715',
            'coingeckoId': 'youcash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AAMMUNIUNIWETH',
            'name': 'Aave AMM UniUNIWETH',
            'address': '0x3D26dcd840fCC8e4B2193AcE8A092e4a65832F9f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17225/thumb/aAmmUniUNIWETH.png?1626881095',
            'coingeckoId': 'aave-amm-uniuniweth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YCO',
            'name': 'Y Coin',
            'address': '0x3D0293f06daF4311B482564330D57C8Db6C10893',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14128/thumb/BIG-POTAT-200x200-removebg-preview.png?1614576159',
            'coingeckoId': 'y-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STSUKA',
            'name': 'ShibaTsuka',
            'address': '0x3Cf19Ba410FA07e20854e8520227f756c8Afd655',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27495/thumb/tsuka200.png?1664269591',
            'coingeckoId': 'shibatsuka',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DIGI',
            'name': 'Digible',
            'address': '0x3CbF23c081fAA5419810ce0F6BC1ECb73006d848',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14960/thumb/RE3Fiua.png?1619148839',
            'coingeckoId': 'digible',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'DBX',
            'name': 'DBX',
            'address': '0x3CBc780D2934d55A06069e837fABd3e6Fc23DAB0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15682/thumb/logo.png?1646903349',
            'coingeckoId': 'dbx-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PRY',
            'name': 'PRY',
            'address': '0x3C81D482172cC273c3b91dD9D8eb212023D00521',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1634701133521_805081181511839.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'PERX',
            'name': 'PeerEx Network',
            'address': '0x3C6ff50c9Ec362efa359317009428d52115fe643',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3c6ff50c9ec362efa359317009428d52115fe643.png',
            'coingeckoId': 'peerex-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-9165-KENSIN',
            'name': 'RealT   9165 Kensington Ave  Detroit  M',
            'address': '0x3C56D5E887D8fA7AE1Ba65BF7eCCC25eC09EAF18',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16687/thumb/9165-Kensington-hero-1.jpeg?1624634948',
            'coingeckoId': '9165-kensington',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BitBTC',
            'name': 'BitBTC',
            'address': '0x3C513dB8Bdc3806e4489d62C3d549A5Aaf6A4e97',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethereum-optimism.github.io/data/BitBTC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'PAXGBEAR',
            'name': '3X Short PAX Gold Token',
            'address': '0x3C4a46F0C075A7F191A7459bb51EB1f81ac36F8A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10432/thumb/683JEXMN_400x400.png?1579276381',
            'coingeckoId': '3x-short-pax-gold-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VGX',
            'name': 'Voyager VGX',
            'address': '0x3C4B6E6e1eA3D4863700D7F76b36B7f3D3f13E3d',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3c4b6e6e1ea3d4863700d7f76b36b7f3d3f13e3d.png',
            'coingeckoId': 'ethos',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ANK',
            'name': 'Apple Network',
            'address': '0x3C45B24359fB0E107a4eAA56Bd0F2cE66C99A0E5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x3c45b24359fb0e107a4eaa56bd0f2ce66c99a0e5.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'HAO',
            'name': 'HistoryDAO',
            'address': '0x3C4008eca800Ec1283E4cf500E68D06BFaBc00a8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27318/thumb/5961657012978_.pic.jpg?1663316216',
            'coingeckoId': 'historydao',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'WPCI',
            'name': 'Wrapped Paycoin',
            'address': '0x3C2A309d9005433c1BC2C92EF1bE06489e5bf258',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24390/thumb/new_logo_square_%281%29.png?1648792416',
            'coingeckoId': 'wrapped-paycoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CVR',
            'name': 'CoverCompared',
            'address': '0x3C03b4EC9477809072FF9CC9292C9B25d4A8e6c6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3c03b4ec9477809072ff9cc9292c9b25d4a8e6c6.png',
            'coingeckoId': 'covercompared',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'VIDT',
            'name': 'VIDT DAO',
            'address': '0x3BE7bF1A5F23BD8336787D0289B70602f1940875',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27552/thumb/RiHSX2MH_400x400.jpeg?1664501495',
            'coingeckoId': 'vidt-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LSS',
            'name': 'Lossless',
            'address': '0x3B9BE07d622aCcAEd78f479BC0EDabFd6397E320',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3b9be07d622accaed78f479bc0edabfd6397e320.png',
            'coingeckoId': 'lossless',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'DYNMT',
            'name': 'Dynamite',
            'address': '0x3B7f247f21BF3A07088C2D3423F64233d4B069F7',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8951/thumb/dynamite_logo.jpg?1598851224',
            'coingeckoId': 'dynamite-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WAGMIGAMES',
            'name': 'WAGMI Game',
            'address': '0x3B604747ad1720C01ded0455728b62c0d2F100F0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25906/thumb/Eohd79rn_400x400.jpg?1654582966',
            'coingeckoId': 'wagmi-game-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LAEEB',
            'name': 'Laeeb Inu ERC',
            'address': '0x3B1a3C5d9D5C60651D4841C7814aA0FbB45A863d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27905/thumb/LaeebLogo200x200.png?1666335729',
            'coingeckoId': 'laeeb-inu-erc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RPTC',
            'name': 'Reptilian',
            'address': '0x3B08c03Fa8278cF81B9043B228183760376fcdBB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18989/thumb/rept.PNG?1634114856',
            'coingeckoId': 'reptilian',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DREP',
            'name': 'Drep',
            'address': '0x3Ab6Ed69Ef663bd986Ee59205CCaD8A20F98b4c2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14578/thumb/KotgsCgS_400x400.jpg?1617094445',
            'coingeckoId': 'drep-new',
            'listedIn': [
                'coingecko',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'GST',
            'name': 'GrEarn',
            'address': '0x3AFA1902b1f8a802aBC18e5aD982D1bCd34AfE22',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6193/thumb/GVlSl0Oi_400x400.jpg?1547042222',
            'coingeckoId': 'grearn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PRIX',
            'name': 'Privatix',
            'address': '0x3ADfc4999F77D04c8341BAC5F3A76f58DfF5B37A',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1183/thumb/dZZrRl8.png?1547035211',
            'coingeckoId': 'privatix',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GVT',
            'name': 'Gro Vault',
            'address': '0x3ADb04E127b9C0a5D36094125669d4603AC52a0c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18628/thumb/613f170fa75cd0ec6fd17f50_Icon-Vault-256x256-Square.png?1632980198',
            'coingeckoId': 'gro-vault-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHUF',
            'name': 'Shuffle.Monster V3',
            'address': '0x3A9FfF453d50D4Ac52A6890647b823379ba36B9E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3a9fff453d50d4ac52a6890647b823379ba36b9e.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'TBX',
            'name': 'Tokenbox',
            'address': '0x3A92bD396aEf82af98EbC0Aa9030D25a23B11C6b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x3a92bd396aef82af98ebc0aa9030d25a23b11c6b.png',
            'coingeckoId': 'tokenbox',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'TITAN',
            'name': 'TitanSwap',
            'address': '0x3A8cCCB969a61532d1E6005e2CE12C200caeCe87',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12606/thumb/nqGlQzdz_400x400.png?1601019805',
            'coingeckoId': 'titanswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DDX',
            'name': 'DerivaDAO',
            'address': '0x3A880652F47bFaa771908C07Dd8673A787dAEd3A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3a880652f47bfaa771908c07dd8673a787daed3a.png',
            'coingeckoId': 'derivadao',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'KOGECOIN',
            'name': 'kogecoin.io',
            'address': '0x3A85772aA10992976CF0ef1550B7a9cEA06538b8',
            'decimals': 9,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TRIAS',
            'name': 'TriasLab',
            'address': '0x3A856d4effa670C54585a5D523e96513e148e95d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13963/thumb/5ELqtwp__400x400_%281%29.jpg?1613527958',
            'coingeckoId': 'trias-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TYC',
            'name': 'Tycoon',
            'address': '0x3A82D3111aB5faF39d847D46023d9090261A658F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15165/thumb/coingecko.png?1622177525',
            'coingeckoId': 'tycoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AIN',
            'name': 'AI Network',
            'address': '0x3A810ff7211b40c4fA76205a14efe161615d0385',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13211/thumb/AI_Network_Logo_200x200.png?1606205615',
            'coingeckoId': 'ai-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IETHV',
            'name': 'Inverse Ethereum Volatility Index Token',
            'address': '0x3A707d56D538e85B783E8CE12B346e7fB6511F90',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3a707d56d538e85b783e8ce12b346e7fb6511f90.png',
            'coingeckoId': 'inverse-ethereum-volatility-index-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'VEN',
            'name': 'Vendetta Finance',
            'address': '0x3A529A8d4f2ea64D206339fA12A3aF4D431F53c3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26601/thumb/VF-Logo.png?1663768906',
            'coingeckoId': 'vendetta-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FTG',
            'name': 'fantomGO',
            'address': '0x3A4CaB3DcfaB144FE7eB2B5a3e288Cc03dC07659',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20154/thumb/D6B_ffAH_400x400.jpg?1636584919',
            'coingeckoId': 'fantomgo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPELLFIRE',
            'name': 'Spellfire',
            'address': '0x3A0b022f32b3191D44E5847da12dc0B63fb07C91',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23107/thumb/17316.png?1643261163',
            'coingeckoId': 'spellfire',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WXRP',
            'name': 'Wrapped XRP',
            'address': '0x39fBBABf11738317a448031930706cd3e612e1B9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x39fbbabf11738317a448031930706cd3e612e1b9_1.png',
            'coingeckoId': 'wrapped-xrp',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'HBDC',
            'name': 'Happy Birthday Coin',
            'address': '0x39d30828a163713D91c4EADBBA2C497a9139ec5C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12814/thumb/%EC%A7%84%ED%92%88.png?1630840854',
            'coingeckoId': 'happy-birthday-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DXY',
            'name': 'DXY Finance',
            'address': '0x39Fa206c1648944f92E8F7B626e1CBdf78d7E9dB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12839/thumb/dxy_finance.png?1602903489',
            'coingeckoId': 'dxy-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HONR',
            'name': 'DeltaFlare',
            'address': '0x39Ea10E507720783C27eDd5F96Bf2D6e199579B8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28059/thumb/DF_Logo_200x200.png?1667298738',
            'coingeckoId': 'deltaflare',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BORUTO',
            'name': 'Boruto Inu',
            'address': '0x39Cacb4DcD57c0eB0C1446D9c16a26Ed43718b44',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17926/thumb/Doge-Boruto-200x200.png?1629808980',
            'coingeckoId': 'boruto-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AKNC',
            'name': 'Aave KNC',
            'address': '0x39C6b3e42d6A679d7D776778Fe880BC9487C2EDA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x39c6b3e42d6a679d7d776778fe880bc9487c2eda.png',
            'coingeckoId': 'aave-knc',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ZPTC',
            'name': 'Zeptacoin',
            'address': '0x39Ae6D231d831756079ec23589D2D37A739F2E89',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19109/thumb/2000x2000zptc_square3_%281%29.png?1639667686',
            'coingeckoId': 'zeptagram',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CUSDC',
            'name': 'cUSDC',
            'address': '0x39AA39c021dfbaE8faC545936693aC917d5E7563',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x39aa39c021dfbae8fac545936693ac917d5e7563.png',
            'coingeckoId': 'compound-usd-coin',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'PEKC',
            'name': 'Peacockcoin  ETH ',
            'address': '0x398dC1c12a268b657aBD0cD88999156228acB91a',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18373/thumb/pekc.jpg?1631679728',
            'coingeckoId': 'peacockcoin-eth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FAIR',
            'name': 'Fairmint',
            'address': '0x3983C6DA8235F59e16a034EE773e60A69e8F14B2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27109/thumb/fairmint.png?1662881621',
            'coingeckoId': 'fairmint',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YGN',
            'name': 'YUGEN',
            'address': '0x397E10ee5e315F69b23cB6c8A913A589b5850c80',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'XELS',
            'name': 'XELS',
            'address': '0x397Deb686C72384FAd502A81f4d7fDb89e1f1280',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14729/thumb/Xels_logo_square_%281%29.png?1617957186',
            'coingeckoId': 'xels',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHARE',
            'name': 'Seigniorage Shares',
            'address': '0x39795344CBCc76cC3Fb94B9D1b15C23c2070C66D',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x39795344cbcc76cc3fb94b9d1b15c23c2070c66d.png',
            'coingeckoId': 'seigniorage-shares',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BUY',
            'name': 'Buying com',
            'address': '0x396eC402B42066864C406d1ac3bc86B575003ed8',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5290/thumb/buying_200x200.png?1628587856',
            'coingeckoId': 'buying',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRZ',
            'name': 'Trazable',
            'address': '0x394A16744Dcd805BB0cA7252e70691f0dcAc56AA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24851/thumb/Logo_TRZ-Token_200.png?1649133506',
            'coingeckoId': 'trazable',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BRAINS',
            'name': 'Brainiac',
            'address': '0x39317B8A1aE06C30bb615d88CDC5522781499f1c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21356/thumb/CVIaisb.png?1639006193',
            'coingeckoId': 'brainiac',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INUS',
            'name': 'MultiPlanetary Inus',
            'address': '0x39207D2E2fEEF178FBdA8083914554C59D9f8c00',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22648/thumb/logo.png?1644479310',
            'coingeckoId': 'multiplanetary-inus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AAMMUNIUSDCWETH',
            'name': 'Aave AMM UniUSDCWETH',
            'address': '0x391E86e2C002C70dEe155eAceB88F7A3c38f5976',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17219/thumb/aAmmUniUSDCWETH.png?1626879118',
            'coingeckoId': 'aave-amm-uniusdcweth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NRP',
            'name': 'Neural Protocol',
            'address': '0x3918C42F14F2eB1168365F911f63E540E5A306b5',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6313/thumb/neural-protocol.png?1548085897',
            'coingeckoId': 'neural-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CVP',
            'name': 'PowerPool Concentrated Voting Power',
            'address': '0x38e4adB44ef08F22F5B5b76A8f0c2d0dCbE7DcA1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x38e4adb44ef08f22f5b5b76a8f0c2d0dcbe7dca1.png',
            'coingeckoId': 'concentrated-voting-power',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'BLXM',
            'name': 'bloXmove',
            'address': '0x38d9eb07A7b8Df7D86F440A4A5c4a4c1a27E1a08',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19310/thumb/blxm_200x200.png?1635238192',
            'coingeckoId': 'bloxmove-erc20',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DKYC',
            'name': 'DataKYC',
            'address': '0x38d1B0D157529Bd5D936719A8a5F8379aFB24fAA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7123/thumb/1btu7Lp__400x400.jpg?1547043621',
            'coingeckoId': 'datakyc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AMO',
            'name': 'AMO Coin',
            'address': '0x38c87AA89B2B8cD9B95b736e1Fa7b612EA972169',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4009/thumb/amo-logomark_black.png?1547039106',
            'coingeckoId': 'amo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GP',
            'name': 'Wizards And Dragons',
            'address': '0x38Ec27c6F05a169e7eD03132bcA7d0cfeE93C2C5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21162/thumb/GPGP.jpg?1638427072',
            'coingeckoId': 'wizards-and-dragons',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AAMMUNISNXWETH',
            'name': 'Aave AMM UniSNXWETH',
            'address': '0x38E491A71291CD43E8DE63b7253E482622184894',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17224/thumb/aAmmUniSNXWETH.png?1626880874',
            'coingeckoId': 'aave-amm-unisnxweth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANML',
            'name': 'Animal Concerts Token',
            'address': '0x38B0e3A59183814957D83dF2a97492AED1F003e2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://static.debank.com/image/eth_token/logo_url/0x38b0e3a59183814957d83df2a97492aed1f003e2/b3d2118e9b5af1c5031a484d97dab030.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'F9',
            'name': 'Falcon Nine',
            'address': '0x38A94e92A19E970c144DEd0B2DD47278CA11CC1F',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16858/thumb/logo-f9.png?1625465513',
            'coingeckoId': 'falcon-nine',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PHNX',
            'name': 'PhoenixDAO',
            'address': '0x38A2fDc11f526Ddd5a607C1F251C065f40fBF2f7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x38a2fdc11f526ddd5a607c1f251c065f40fbf2f7.png',
            'coingeckoId': 'phoenixdao',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'FEG',
            'name': 'FEG',
            'address': '0x389999216860AB8E0175387A0c90E5c52522C945',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x389999216860ab8e0175387a0c90e5c52522c945.png',
            'coingeckoId': 'feg-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'MITH',
            'name': 'Mithril',
            'address': '0x3893b9422Cd5D70a81eDeFfe3d5A1c6A978310BB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3484/thumb/mithril.png?1548085086',
            'coingeckoId': 'mithril',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAND',
            'name': 'The Sandbox',
            'address': '0x3845badAde8e6dFF049820680d1F14bD3903a5d0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3845badade8e6dff049820680d1f14bd3903a5d0.png',
            'coingeckoId': 'the-sandbox',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HEAL',
            'name': 'Heal The World',
            'address': '0x383B0b2ce2e1757b5e1D087D8f36013eA595541a',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24123/thumb/GJe1qAQS_400x400.jpg?1646461929',
            'coingeckoId': 'heal-the-world',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OHM',
            'name': 'Olympus v1',
            'address': '0x383518188C0C6d7730D91b2c03a03C837814a899',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/16209.png',
            'coingeckoId': 'olympus-v1',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'SPHTX',
            'name': 'SophiaTX',
            'address': '0x3833ddA0AEB6947b98cE454d89366cBA8Cc55528',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1443/thumb/sophiatx.png?1548609885',
            'coingeckoId': 'sophiatx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RENDOGE',
            'name': 'renDOGE',
            'address': '0x3832d2F059E55934220881F831bE501D180671A7',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3832d2f059e55934220881f831be501d180671a7.png',
            'coingeckoId': 'rendoge',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'RUSH',
            'name': 'RushCoin',
            'address': '0x382A1667C9062F0621362F49076Ef6e4fE4C9eC7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25053/thumb/Kax1yBSz_400x400.png?1649979044',
            'coingeckoId': 'rushcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HDRN',
            'name': 'Hedron',
            'address': '0x3819f64f282bf135d62168C1e513280dAF905e06',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24208/thumb/hdrn.png?1647079428',
            'coingeckoId': 'hedron',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MPAY',
            'name': 'Menapay',
            'address': '0x3810A4Ddf41E586Fa0dbA1463A7951B748cEcFca',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6360/thumb/Menapay_ico.png?1547042454',
            'coingeckoId': 'menapay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GETH',
            'name': 'Guarded Ether',
            'address': '0x3802c218221390025BCEABBaD5D8C59F40eB74B8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14001/thumb/guarda-shield-logo.png?1613603915',
            'coingeckoId': 'guarded-ether',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELT',
            'name': 'Elite Swap',
            'address': '0x380291A9A8593B39f123cF39cc1cc47463330b1F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13715/thumb/logo_-_2021-01-20T105016.992.png?1611111027',
            'coingeckoId': 'elite-swap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CIV',
            'name': 'Civilization',
            'address': '0x37fE0f067FA808fFBDd12891C0858532CFE7361d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x37fe0f067fa808ffbdd12891c0858532cfe7361d.png',
            'coingeckoId': 'civilization',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'GRNC',
            'name': 'VeganNation GreenCoin',
            'address': '0x37d299eF5548BF19503Cb72C085799e331F9097d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21581/thumb/logo-green_%281%29.png?1639532854',
            'coingeckoId': 'vegannation-greencoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VCASH',
            'name': 'void cash',
            'address': '0x37cd4E8875E3EDafFDFe9Be63958f07eFfBD0Bfd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26976/thumb/Avatar_v2.png?1661168692',
            'coingeckoId': 'void-cash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MBET',
            'name': 'MetaBET',
            'address': '0x37c4822DFcEa7211d1D9876dABd48c62D46dbAAb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22778/thumb/y5TphxL.jpeg?1642577860',
            'coingeckoId': 'metabet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CBX',
            'name': 'CropBytes',
            'address': '0x37FC4b48CE93469dbEA9918468993C735049642a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19907/thumb/cbx.png?1647698560',
            'coingeckoId': 'cropbytes',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DILI',
            'name': 'D Community',
            'address': '0x37F74e99794853777a10ea1dc08a64C86958F06a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9485/thumb/BYf_d6kekNWOY34VLGPvf7RcVj8SeRi7P65Nk0W7ji1jir_qSLfssffnNW0ZzQSWzhhsNRkMCL7qcX4CCaMTbgKb0D7b5yNeStKVMm2deZd9cONMuItgoKUXxIYkHElfk1PW2uiNZbg6a2K0OHtZTvkt1xo8YVKuthskXaryOkM6Ax-MXQlHOfbjwCcnQQjHxAucufrCZJfDgrY.jpg?1571685571',
            'coingeckoId': 'd-community',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VEST',
            'name': 'VestChain',
            'address': '0x37F04d2C3AE075Fad5483bB918491F656B12BDB6',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6700/thumb/logo_200x200_%281%29.png?1547042908',
            'coingeckoId': 'vestchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AID',
            'name': 'AidCoin',
            'address': '0x37E8789bB9996CaC9156cD5F5Fd32599E6b91289',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x37e8789bb9996cac9156cd5f5fd32599e6b91289.png',
            'coingeckoId': 'aidcoin',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'PHCR',
            'name': 'PhotoChromic',
            'address': '0x37E83a94c6B1Bdb816B59aC71dd02CF154d8111F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23640/thumb/L7vabJibqXHHmGtsIfIEQRaR3SjaE94QSaKWGlqYU7fpuz--nKsWG4BlB6Ddhn5-tGPWo-MDhfZPVAYG7ZM2vpWrVZlSx1Euw78tZcNey6FvpKX6a8w26qBWYeV7wxFzvW7VKieijrMXQ0BkISpt8d6jrCMBvx2ZFKVxF1x31_2X7WhRyqAgIG3epFPNz7v0j0ECaS7HmE0_sTHM4RS3CW1d0a.jpg?1644908390',
            'coingeckoId': 'photochromic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RI',
            'name': 'Xiotri RI',
            'address': '0x37E808F084101F75783612407e7C3f5F92d8ee3F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12536/thumb/Ri_logo_512x512.png?1601775196',
            'coingeckoId': 'ri-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YCC',
            'name': 'Yuan Chain Coin',
            'address': '0x37E1160184F7dD29f00b78C050Bf13224780b0B0',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5328/thumb/G5gCEo-J_400x400.jpg?1547040897',
            'coingeckoId': 'yuan-chain-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PILOT',
            'name': 'Unipilot',
            'address': '0x37C997B35C619C21323F3518B9357914E8B99525',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x37c997b35c619c21323f3518b9357914e8b99525.png',
            'coingeckoId': 'unipilot',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'PIXIU',
            'name': 'Pixiu Finance',
            'address': '0x37A15C92e67686aA268df03d4C881A76340907E8',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17297/thumb/pixiu_picture_.png?1627264684',
            'coingeckoId': 'pixiu-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FREN',
            'name': 'FREN',
            'address': '0x37941b3Fdb2bD332e667D452a58Be01bcacb923e',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20259/thumb/5oR6DSf.png?1636702537',
            'coingeckoId': 'fren',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NEXT',
            'name': 'NEXT',
            'address': '0x377d552914E7A104bC22B4F3B6268dDC69615Be7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7014/thumb/NEXT.exchange_Logo.png?1569212613',
            'coingeckoId': 'nextexchange',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PDT',
            'name': 'ParagonsDAO',
            'address': '0x375aBB85C329753b1Ba849a601438AE77eEc9893',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22642/thumb/6_Ms7ltG_400x400.jpg?1642395792',
            'coingeckoId': 'paragonsdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNIQ',
            'name': 'Uniqly',
            'address': '0x3758e00b100876C854636eF8Db61988931BB8025',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14808/thumb/Hnet-com-image.png?1618538742',
            'coingeckoId': 'uniqly',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YDR',
            'name': 'YDragon',
            'address': '0x3757232B55E60da4A8793183aC030CfCE4c3865d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17807/thumb/icon.png?1629302175',
            'coingeckoId': 'ydragon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CWD',
            'name': 'CROWD',
            'address': '0x374fB05c96c36348b92E38fB088b26B8511e3b3D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24125/thumb/cwd.png?1646463219',
            'coingeckoId': 'crowd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAO',
            'name': 'Bao Finance',
            'address': '0x374CB8C27130E2c9E04F44303f3c8351B9De61C1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x374cb8c27130e2c9e04f44303f3c8351b9de61c1.png',
            'coingeckoId': 'bao-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'SHIBORG',
            'name': 'ShibRobi',
            'address': '0x372C95CE229a7Af37D141F627d09f6Df1DbAa741',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23505/thumb/_-5o_lop_400x400.jpg?1644297264',
            'coingeckoId': 'shibrobi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EUROS',
            'name': 'SpiceEURO',
            'address': '0x37129b96896891e56bc099540fe7D1841005A367',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26443/thumb/E4B6C93A-E2A1-4F53-BB26-15D0FE802812.png?1658110215',
            'coingeckoId': 'spiceeuro',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'AAMMUNIMKRWETH',
            'name': 'Aave AMM UniMKRWETH',
            'address': '0x370adc71f67f581158Dc56f539dF5F399128Ddf9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17222/thumb/aAmmUniMKRWETH.png?1626880292',
            'coingeckoId': 'aave-amm-unimkrweth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATEM',
            'name': 'ATEM',
            'address': '0x3708DEA52A5846FD16d0060AaB01c87bb5932b02',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BAMB',
            'name': 'Bamboonium',
            'address': '0x3703F712945f8111fE2C5f9aE155A52560e2065c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25193/thumb/bamb_logo.png?1650608279',
            'coingeckoId': 'bamboonium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HIDOODLES',
            'name': 'hiDOODLES',
            'address': '0x3700Adfd26d5bC062cB8b8a77e68fbd43F58eCAB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27427/thumb/hidoodles.png?1663918652',
            'coingeckoId': 'hidoodles',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IPAD',
            'name': 'Infinity Pad  OLD ',
            'address': '0x36ed7baaD9a571B5dad55d096C0Ed902188D6d3c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17302/thumb/ipad.jpg?1627268574',
            'coingeckoId': 'infinity-pad',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLYP',
            'name': 'FLYPE DAO',
            'address': '0x36d076480Eb31c717137f400F9761a5151124C4b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27283/thumb/FLYPE_LOGO_200X200.jpg?1663140374',
            'coingeckoId': 'flype-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XMARK',
            'name': 'xMARK',
            'address': '0x36b679bd64Ed73DBfd88909cDCB892cB66Bd4CBb',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14188/thumb/omPQ9-nF_400x400.jpg?1614830988',
            'coingeckoId': 'xmark',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIBAMON',
            'name': 'Shibamon',
            'address': '0x36b00c4c6CE3653a091c7940fC98C3acb0043871',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19891/thumb/cm170VEL_400x400.jpg?1636093140',
            'coingeckoId': 'shibamon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XCM',
            'name': 'Coinmetro',
            'address': '0x36ac219f90f5A6A3C77f2a7B660E3cC701f68e25',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3125/thumb/XCM_Token_Logo_.png?1646280053',
            'coingeckoId': 'coinmetro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRT',
            'name': 'Cryptonits',
            'address': '0x36a28c7c9B3DEA22F07F4dF67833cBe764fEEeb4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13392/thumb/logo-200x200.png?1608100148',
            'coingeckoId': 'cryptonits',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ESD',
            'name': 'Empty Set Dollar',
            'address': '0x36F3FD68E7325a35EB768F1AedaAe9EA0689d723',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x36f3fd68e7325a35eb768f1aedaae9ea0689d723.png',
            'coingeckoId': 'empty-set-dollar',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ARCHA',
            'name': 'ArchAngel',
            'address': '0x36E43065e977bC72CB86Dbd8405fae7057CDC7fD',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18814/thumb/ARCHA200x200.png?1633714225',
            'coingeckoId': 'archangel-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PSLIP',
            'name': 'Pinkslip Finance',
            'address': '0x36CE7a52CDa404b8fa87a98d0d17EC7dd0B144ED',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17960/thumb/MgCiNzk.png?1629943128',
            'coingeckoId': 'pinkslip-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KIKI',
            'name': 'Kiki',
            'address': '0x369b77bBeEeE50e6eA206dcf41Ee670c47360055',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22289/thumb/A8oLUJW8_400x400.jpg?1641367572',
            'coingeckoId': 'kiki',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KUSUNOKI',
            'name': 'Kusunoki Samurai',
            'address': '0x36919a60a2B67B6d2329863093D180d23d5A0308',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24688/thumb/68747470733a2f2f7777772e6b7573756e6f6b6973616d757261692e636f6d2f696d616765732f6b7573756e6f6b692d30312e706e67.png?1648604014',
            'coingeckoId': 'kusunoki-samurai',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTO',
            'name': 'Bottos',
            'address': '0x36905Fc93280f52362A1CBAB151F25DC46742Fb5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2048/thumb/bottos.png?1517478551',
            'coingeckoId': 'bottos',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KKO',
            'name': 'KKO Protocol',
            'address': '0x368C5290b13cAA10284Db58B4ad4F3E9eE8bf4c9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x368c5290b13caa10284db58b4ad4f3e9ee8bf4c9/logo.png',
            'coingeckoId': 'kineko',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'CFXT',
            'name': 'Chainflix',
            'address': '0x368BF9F1A1CA767935E39F20439D9041707E2634',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17671/thumb/Logo_200_%282%29.png?1628822696',
            'coingeckoId': 'chainflix',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIC',
            'name': 'MIC',
            'address': '0x368B3a58B5f49392e5C9E4C998cb0bB966752E51',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x368b3a58b5f49392e5c9e4c998cb0bb966752e51.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'GUNTHY',
            'name': 'GUNTHY',
            'address': '0x3684b581dB1F94b721EE0022624329FEb16Ab653',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7869/thumb/GUNTHY_128.png?1562130711',
            'coingeckoId': 'gunthy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IFV',
            'name': 'INFLIV',
            'address': '0x3656bD0f3f07623Bb7f429B390D208f894e44eCE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5279/thumb/INFLIV_LOGO_PNG.png?1550657927',
            'coingeckoId': 'infliv',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LPF',
            'name': 'Loopfi',
            'address': '0x3650B69f86cB593f116e276C30666834336c0647',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethereum-optimism.github.io/data/LPF/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'META',
            'name': 'Metalk',
            'address': '0x364fcd7325C035CC4F2cdE8b6c8D7Df5e7Db6589',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24801/thumb/meta.png?1648983422',
            'coingeckoId': 'metalk',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PL2',
            'name': 'Plenny',
            'address': '0x3642c0680329ae3e103E2B5AB29DDfed4d43CBE5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'FEENIXV2',
            'name': 'ProjectFeenixv2',
            'address': '0x3633F95E4d5900cb7525F5801639aEabC998c750',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22768/thumb/Project-Feenix-Logo_32x32.png?1642575940',
            'coingeckoId': 'projectfeenixv2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PRQ',
            'name': 'PARSIQ',
            'address': '0x362bc847A3a9637d3af6624EeC853618a43ed7D2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x362bc847a3a9637d3af6624eec853618a43ed7d2.png',
            'coingeckoId': 'parsiq',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'DSK',
            'name': 'Dar   afaka Sports Club',
            'address': '0x362bc4B0b2A8134d87acE9143d284a2eCeD3fd2A',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25980/thumb/20572.png?1655113316',
            'coingeckoId': 'darussafaka-sports-club',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARTI',
            'name': 'Arti Project',
            'address': '0x3625b55f653F8C7c884aC668696881df43631D44',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18878/thumb/arti.PNG?1633688264',
            'coingeckoId': 'arti-project',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASNX',
            'name': 'Aave SNX',
            'address': '0x35f6B052C598d933D69A4EEC4D04c73A191fE6c2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x35f6b052c598d933d69a4eec4d04c73a191fe6c2.png',
            'coingeckoId': 'aave-snx',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'XAI',
            'name': 'SideShift',
            'address': '0x35e78b3982E87ecfD5b3f3265B601c046cDBe232',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15719/thumb/sideshift-icon_3x.png?1621595183',
            'coingeckoId': 'sideshift-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FWB',
            'name': 'Friends With Benefits Pro',
            'address': '0x35bD01FC9d6D5D81CA9E055Db88Dc49aa2c699A8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14391/thumb/xRGEXmQN_400x400.png?1615868085',
            'coingeckoId': 'friends-with-benefits-pro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STONK',
            'name': 'Stonk Swap',
            'address': '0x35b55c25731E9b05B1d8480ba39463d52C9D0211',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11690/thumb/PNG_logo_200px.png?1622340402',
            'coingeckoId': 'stonk',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EM',
            'name': 'Eminer',
            'address': '0x35b08722AA26bE119c1608029CcbC976ac5C1082',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9155/thumb/KDbQPKIu_400x400.png?1564735775',
            'coingeckoId': 'eminer',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BETA',
            'name': 'Beta',
            'address': '0x35F67c1D929E106FDfF8D1A55226AFe15c34dbE2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25539/thumb/Beta-47.png?1652265414',
            'coingeckoId': 'beta-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CUNI',
            'name': 'cUNI',
            'address': '0x35A18000230DA775CAc24873d00Ff85BccdeD550',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x35a18000230da775cac24873d00ff85bccded550.png',
            'coingeckoId': 'compound-uniswap',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'DENT',
            'name': 'Dent',
            'address': '0x3597bfD533a99c9aa083587B074434E61Eb0A258',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3597bfd533a99c9aa083587b074434e61eb0a258.png',
            'coingeckoId': 'dent',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'OM',
            'name': 'MANTRA',
            'address': '0x3593D125a4f7849a1B059E64F4517A86Dd60c95d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3593d125a4f7849a1b059e64f4517a86dd60c95d.png',
            'coingeckoId': 'mantra-dao',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'AAMMBPTWBTCWETH',
            'name': 'Aave AMM BptWBTCWETH',
            'address': '0x358bD0d980E031E23ebA9AA793926857703783BD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17260/thumb/aAmmBptWBTCWETH.png?1626959325',
            'coingeckoId': 'aave-amm-bptwbtcweth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARES',
            'name': 'Ares Protocol',
            'address': '0x358AA737e033F34df7c54306960a38d09AaBd523',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x358aa737e033f34df7c54306960a38d09aabd523.png',
            'coingeckoId': 'ares-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': '1337',
            'name': '1337',
            'address': '0x35872fea6A4843fACBCDbCe99e3B69596A3680b8',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13348/thumb/logo.png?1607791847',
            'coingeckoId': 'e1337',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INVI',
            'name': 'INVI',
            'address': '0x356A5160F2B34BC8d88FB084745465eBBbed0174',
            'decimals': 13,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17363/thumb/INVI-Ticker.png?1627374966',
            'coingeckoId': 'invi-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GM',
            'name': 'GhostMarket',
            'address': '0x35609dC59E15d03c5c865507e1348FA5abB319A8',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22765/thumb/gm.png?1642574907',
            'coingeckoId': 'ghostmarket',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NEPT',
            'name': 'Metanept',
            'address': '0x355a824bEa1adc22733978A3748271E1bbB34130',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26798/thumb/OH1UiJ16_400x400.jpeg?1660202251',
            'coingeckoId': 'metanept',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNL',
            'name': 'Unilock Network',
            'address': '0x354E514c135c8603f840ffADb4c33cDE6D2A37e0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23576/thumb/unilock-logo.png?1650449646',
            'coingeckoId': 'unilock-network-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UUU',
            'name': 'U Network',
            'address': '0x3543638eD4a9006E4840B105944271Bcea15605D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3877/thumb/2645.png?1547038930',
            'coingeckoId': 'u-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KNIGHT',
            'name': 'CitaDAO',
            'address': '0x3541A5C1b04AdABA0B83F161747815cd7B1516bC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3541a5c1b04adaba0b83f161747815cd7b1516bc.png',
            'coingeckoId': 'citadao',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'TPRO',
            'name': 'TPRO',
            'address': '0x3540abE4f288B280A0740ad5121aEc337c404D15',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26694/thumb/tpro-logo-200x200.png?1659607453',
            'coingeckoId': 'tpro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MDAO',
            'name': 'Metaverse DAO',
            'address': '0x353E41E66F58ce91D07d778520685473f38A7125',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23242/thumb/ezgif.com-gif-maker_%281%29.jpg?1664937350',
            'coingeckoId': 'metaverse-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARW',
            'name': 'Arowana',
            'address': '0x351cAa9045D65107b9d311D922D15887cfd634E4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17114/thumb/ARW_logo_final.jpg?1626616242',
            'coingeckoId': 'arowana-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEX',
            'name': 'AlphaDex',
            'address': '0x3516415161C478DF10ADBb8bb884Cc83FbD5F11a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12949/thumb/AlphaDex.png?1603779030',
            'coingeckoId': 'alphadex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHIRO',
            'name': 'Chihiro Inu',
            'address': '0x35156b404C3f9bdaf45ab65Ba315419bcDe3775c',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19721/thumb/chiro200x200.png?1642166355',
            'coingeckoId': 'chihiro-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BHSC',
            'name': 'BlackHoleSwap Compound DAI USDC',
            'address': '0x35101c731b1548B5e48bb23F99eDBc2f5c341935',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12347/thumb/blackhole.PNG?1599201917',
            'coingeckoId': 'blackholeswap-compound-dai-usdc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BCT',
            'name': 'BananaClubToken',
            'address': '0x350D3f0f41b5B21F0e252fe2645AE9D55562150a',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x350d3f0f41b5b21f0e252fe2645ae9d55562150a.png',
            'coingeckoId': 'bananaclubtoken',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'CHZ',
            'name': 'Chiliz',
            'address': '0x3506424F91fD33084466F402d5D97f05F8e3b4AF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3506424f91fd33084466f402d5d97f05f8e3b4af.png',
            'coingeckoId': 'chiliz',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SWM',
            'name': 'Swarm Network',
            'address': '0x3505F494c3f0fed0B594E01Fa41Dd3967645ca39',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3505f494c3f0fed0b594e01fa41dd3967645ca39.png',
            'coingeckoId': 'swarm',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BACON',
            'name': 'BaconDAO',
            'address': '0x34f797e7190C131cF630524655A618b5BD8738e7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18059/thumb/xDV_bhdA_400x400.jpg?1630333329',
            'coingeckoId': 'bacondao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-19317-GABLE',
            'name': 'RealT   19317 Gable St  Detroit  MI 482',
            'address': '0x34eD9e71449529E034d0326cfBB3b5ccDCa00CBC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16638/thumb/19317-Gable-hero-1.jpeg?1624558479',
            'coingeckoId': '19317-gable',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAUDISHIB',
            'name': 'SAUDI SHIBA INU',
            'address': '0x34d31446a522252270b89b09016296ec4c98e23d',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26708/thumb/FvmN7Wa.png?1659666550',
            'coingeckoId': 'saudi-shiba-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BKC',
            'name': 'FACTS',
            'address': '0x34bdf48A8F753de4822a6CFB1FEE275F9b4D662e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8461/thumb/sFNOkmVx_400x400.jpg?1558690624',
            'coingeckoId': 'facts',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TESS',
            'name': 'Tesseract',
            'address': '0x34F7Da1243A4Aaa69DE3639a2f124Fa56f4DD5cd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27966/thumb/photo_2022-10-20_10.54.55.jpeg?1666766468',
            'coingeckoId': 'teseract',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PLSD',
            'name': 'PulseDogecoin',
            'address': '0x34F0915a5f15a66Eba86F6a58bE1A471FB7836A7',
            'decimals': 12,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25327/thumb/token-icon-200x200.png?1651234941',
            'coingeckoId': 'pulsedogecoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOLD',
            'name': 'Golden',
            'address': '0x34D6A0F5C2f5D0082141fE73d93B9dd00ca7CE11',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8939/thumb/4uf1Sim9_400x400.png?1563116366',
            'coingeckoId': 'golden-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SENATE',
            'name': 'SENATE',
            'address': '0x34Be5b8C30eE4fDe069DC878989686aBE9884470',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21272/thumb/siIBOmcs.png?1638857898',
            'coingeckoId': 'senate',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TXAG',
            'name': 'tSILVER',
            'address': '0x34ABce75D2f8f33940c721dCA0f562617787bfF3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27829/thumb/tSILVER_token_2D.jpg?1666165851',
            'coingeckoId': 'tsilver',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NUM',
            'name': 'NUM Token',
            'address': '0x3496B523e5C00a4b4150D6721320CdDb234c3079',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://storage.googleapis.com/xy-finance-images/token-list/Num.png',
            'coingeckoId': 'numbers-protocol',
            'listedIn': [
                'coingecko',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'COW',
            'name': 'CoinWind',
            'address': '0x34965f73Cfa05Bf8d8aF37cB4af64Fa950605Ea8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15766/thumb/logo.c3d2c062.png?1621827743',
            'coingeckoId': 'coinwind',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WOZX',
            'name': 'Efforce',
            'address': '0x34950Ff2b487d9E5282c5aB342d08A2f712eb79F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x34950ff2b487d9e5282c5ab342d08a2f712eb79f.png',
            'coingeckoId': 'wozx',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ELONGD',
            'name': 'Elongate Deluxe',
            'address': '0x348B7f3106B5Da47405332534d06069fF9CE4d1B',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15487/thumb/tT2g66pd.jpg?1623764695',
            'coingeckoId': 'elongate-duluxe',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOUL',
            'name': 'SoulPower',
            'address': '0x34862060EFF6DA2AF04D382C209a433279377d16',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ASIC',
            'name': 'ASIC Token',
            'address': '0x347a96a5BD06D2E15199b032F46fB724d6c73047',
            'decimals': 12,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27952/thumb/ASIC_Token_Logo.png?1666668286',
            'coingeckoId': 'asic-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NUME',
            'name': 'NumisMe',
            'address': '0x34769D3e122C93547836AdDD3eb298035D68F1C3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24766/thumb/nume_token_logo.png?1648817270',
            'coingeckoId': 'numisme',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOTZ',
            'name': 'Beep',
            'address': '0x3475fC1D0d2e03761C19D5C7601fe476f0E02F80',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27583/thumb/photo_2022-10-06_01-05-39.png?1665139287',
            'coingeckoId': 'beep',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TOS',
            'name': 'Tosa Inu',
            'address': '0x34748FEDAd669C788aaBe4fb325A4587941F7B48',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27248/thumb/Capture6.JPG?1662891903',
            'coingeckoId': 'tosa-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BADGER',
            'name': 'Badger DAO',
            'address': '0x3472A5A71965499acd81997a54BBA8D852C6E53d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3472a5a71965499acd81997a54bba8d852c6e53d.png',
            'coingeckoId': 'badger-dao',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'DPC',
            'name': 'Developer Coin',
            'address': '0x346D2664FaFa7F1723074BF3d0b903a059B42Af1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ARTE',
            'name': 'Items',
            'address': '0x34612903Db071e888a4dADcaA416d3EE263a87b9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x34612903db071e888a4dadcaa416d3ee263a87b9.png',
            'coingeckoId': 'ethart',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'KORI',
            'name': 'Kori Inu',
            'address': '0x345DADb10A200F10814Ad8523fCA0f2d958C3370',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21555/thumb/kori.png?1639465507',
            'coingeckoId': 'kori-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAC',
            'name': 'Basis Cash',
            'address': '0x3449FC1Cd036255BA1EB19d65fF4BA2b8903A69a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3449fc1cd036255ba1eb19d65ff4ba2b8903a69a.png',
            'coingeckoId': 'basis-cash',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'DACT',
            'name': 'Decentralized Activism',
            'address': '0x343c6de13833bc7d9890eb6B1cd3FBeBC730EBec',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27700/thumb/logodact200x200.png?1665305210',
            'coingeckoId': 'decentralized-activism',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MeM',
            'name': 'Magic Ethereum Money',
            'address': '0x343E59D9D835e35b07fe80f5Bb544f8ED1CD3B11',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://api.rubic.exchange/assets/ethereum/0x343e59d9d835e35b07fe80f5bb544f8ed1cd3b11/logo.png',
            'coingeckoId': 'magic-ethereum-money',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'YOU',
            'name': 'YOU Chain',
            'address': '0x34364BEe11607b1963d66BCA665FDE93fCA666a8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4649/thumb/youchain-logo.jpg?1547039915',
            'coingeckoId': 'you-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FXS',
            'name': 'Frax Share',
            'address': '0x3432B6A60D23Ca0dFCa7761B7ab56459D9C964D0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3432b6a60d23ca0dfca7761b7ab56459d9c964d0.png',
            'coingeckoId': 'frax-share',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'GERO',
            'name': 'GeroWallet',
            'address': '0x3431F91b3a388115F00C5Ba9FdB899851D005Fb5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3431f91b3a388115f00c5ba9fdb899851d005fb5.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'VEE',
            'name': 'BLOCKv',
            'address': '0x340D2bdE5Eb28c1eed91B2f790723E3B160613B7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x340d2bde5eb28c1eed91b2f790723e3b160613b7.png',
            'coingeckoId': 'blockv',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'WOW',
            'name': 'WOWswap',
            'address': '0x3405A1bd46B85c5C029483FbECf2F3E611026e45',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x3405A1bd46B85c5C029483FbECf2F3E611026e45/logo.png',
            'coingeckoId': 'wowswap',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': '127760',
            'name': 'QAO',
            'address': '0x3402E15b3EA0f1aEC2679c4Be4c6d051ceF93953',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17159/thumb/qao.PNG?1626672944',
            'coingeckoId': 'qao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BURP',
            'name': 'Burp',
            'address': '0x33f391F4c4fE802b70B77AE37670037A92114A7c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17317/thumb/Big_Town_Chef_-__BURP_-_Avatar_3x.png?1646202255',
            'coingeckoId': 'burp',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PLAY',
            'name': 'Metaverse NFT Index',
            'address': '0x33e18a092a93ff21aD04746c7Da12e35D34DC7C4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x33e18a092a93ff21aD04746c7Da12e35D34DC7C4/logo.png',
            'coingeckoId': 'metaverse-nft-index',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'DATA',
            'name': 'Data Economy Index',
            'address': '0x33d63Ba1E57E54779F7dDAeaA7109349344cf5F1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x33d63ba1e57e54779f7ddaeaa7109349344cf5f1.png',
            'coingeckoId': 'data-economy-index',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'WIZ',
            'name': 'BlueWizard',
            'address': '0x33d20575f20C6A1881F8ab08E69F6fbAFfAEedF2',
            'decimals': 12,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15261/thumb/BW.jpg?1620270880',
            'coingeckoId': 'bluewizard',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RCCC',
            'name': 'RCCC',
            'address': '0x33bFD20660eeAF952E8D5Bc3236E1918701F17D0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6627/thumb/rccc_token.png?1547042828',
            'coingeckoId': 'rccc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HIODBS',
            'name': 'hiODBS',
            'address': '0x33bD66c334274989b673a8e8C8d1A3f1B8De5889',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27296/thumb/hiodbs.png?1668579342',
            'coingeckoId': 'hiodbs',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELAND',
            'name': 'Etherland',
            'address': '0x33E07f5055173cF8FeBedE8B21B12D1e2b523205',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14432/thumb/eland.png?1616044253',
            'coingeckoId': 'etherland',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SEREN',
            'name': 'Serenity',
            'address': '0x33D6064F0DFb62462A74049f30909DDD4f683Ba2',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23412/thumb/seren.png?1644151893',
            'coingeckoId': 'serenity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MANDOX',
            'name': 'MandoX',
            'address': '0x33D203FA03bb30b133De0fE2d6533C268bA286B6',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x33d203fa03bb30b133de0fe2d6533c268ba286b6.png',
            'coingeckoId': 'mandox-2',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'RAMP',
            'name': 'RAMP  OLD ',
            'address': '0x33D0568941C0C64ff7e0FB4fbA0B11BD37deEd9f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x33d0568941c0c64ff7e0fb4fba0b11bd37deed9f.png',
            'coingeckoId': 'ramp',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'PEPEBET',
            'name': 'PEPE bet',
            'address': '0x33Cf519030235F75A13F29aFcFF5D6ff4Fd10350',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27970/thumb/IMG_20221025_143614_101.jpg?1666771114',
            'coingeckoId': 'pepe-bet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INDI',
            'name': 'IndiGG',
            'address': '0x3392D8A60B77F8d3eAa4FB58F09d835bD31ADD29',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23857/thumb/https___bucketeer-e05bbc84-baa3-437e-9518-adb32be77984.s3.amazonaws.com_public_images_0cb39c1f-db6d-4dd9-9143-1f30ee38d74d_743x726.jpeg?1645594083',
            'coingeckoId': 'indigg',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IDLEUSDCSAFE',
            'name': 'IdleUSDC  Risk Adjusted ',
            'address': '0x3391bc034f2935eF0E1e41619445F998b2680D35',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11930/thumb/idleusdc-safe.png?1596263257',
            'coingeckoId': 'idle-usdc-risk-adjusted',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EBOX',
            'name': 'Ebox',
            'address': '0x33840024177A7DacA3468912363BeD8b425015c5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x33840024177a7daca3468912363bed8b425015c5.png',
            'coingeckoId': 'ebox',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'WANATHA',
            'name': 'Wrapped ANATHA',
            'address': '0x3383c5a8969Dc413bfdDc9656Eb80A1408E4bA20',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3383c5a8969dc413bfddc9656eb80a1408e4ba20.png',
            'coingeckoId': 'wrapped-anatha',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'YFE',
            'name': 'YFE Money',
            'address': '0x33811D4EdBCaED10A685254eB5D3C4e4398520D2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12644/thumb/logo-round.png?1601373377',
            'coingeckoId': 'yfe-money',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-19136-TRACE',
            'name': 'RealT   19136 Tracey St  Detroit MI 482',
            'address': '0x33722EA778dF197f1b7B1Cf0b124d7A962181d65',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16706/thumb/19136-Tracey-hero-1.jpeg?1624644977',
            'coingeckoId': '19136-tracey',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CCT',
            'name': 'Crystal Clear',
            'address': '0x336F646F87D9f6bC6Ed42Dd46E8b3fD9DbD15C22',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/995/thumb/crystal-clear.png?1547034897',
            'coingeckoId': 'crystal-clear',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NATION',
            'name': 'Nation3',
            'address': '0x333A4823466879eeF910A04D473505da62142069',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25106/thumb/Orb_gradient.png?1650321118',
            'coingeckoId': 'nation3',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DRGNBULL',
            'name': '3X Long Dragon Index Token',
            'address': '0x3335f16AF9008bFd32f1eE6C2Be5d4f84FA0b9da',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10197/thumb/683JEXMN_400x400_%281%29.png?1576533729',
            'coingeckoId': '3x-long-dragon-index-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MPL',
            'name': 'Maple',
            'address': '0x33349B282065b0284d756F0577FB39c158F935e6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x33349b282065b0284d756f0577fb39c158f935e6.png',
            'coingeckoId': 'maple',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'CHEESE',
            'name': 'CheeseFry',
            'address': '0x332E824e46FcEeB9E59ba9491B80d3e6d42B0B59',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x332E824e46FcEeB9E59ba9491B80d3e6d42B0B59/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'EXMR',
            'name': 'EXMR FDN',
            'address': '0x331fA6C97c64e47475164b9fC8143b533c5eF529',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3285/thumb/exmr_transparent.png?1604655926',
            'coingeckoId': 'exmr-monero',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TCP',
            'name': 'Token CashPay',
            'address': '0x331A4589516EAE384eA5F557853AF6aF73B9534e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11616/thumb/559059354_256256.png?1591932551',
            'coingeckoId': 'token-cashpay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AKITA',
            'name': 'Akita Inu',
            'address': '0x3301Ee63Fb29F863f2333Bd4466acb46CD8323E6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3301ee63fb29f863f2333bd4466acb46cd8323e6.png',
            'coingeckoId': 'akita-inu',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'CRU',
            'name': 'Crust Network',
            'address': '0x32a7C02e79c4ea1008dD6564b35F131428673c41',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x32a7c02e79c4ea1008dd6564b35f131428673c41.png',
            'coingeckoId': 'crust-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SOLO',
            'name': 'SOLO Vault  NFTX ',
            'address': '0x32Fb2A84aF5515f77515806EA5aDdB54c923237d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18349/thumb/solo.png?1631603899',
            'coingeckoId': 'solo-vault-nftx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPN',
            'name': 'Sportzchain',
            'address': '0x32EA3Dc70E2962334864A9665254d2433E4ddbfD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24915/thumb/Sportzchain_logo_195_195.png?1650240200',
            'coingeckoId': 'sportzchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTBS',
            'name': 'BitBase Token',
            'address': '0x32E6C34Cd57087aBBD59B5A4AECC4cB495924356',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17414/thumb/btbs.PNG?1627596633',
            'coingeckoId': 'bitbase-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLC',
            'name': 'Flowchain',
            'address': '0x32C4ADB9cF57f972bc375129de91C897b4F364F1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7446/thumb/logo_%2889%29.png?1597459811',
            'coingeckoId': 'flowchaincoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EMAID',
            'name': 'MaidSafeCoin',
            'address': '0x329c6E459FFa7475718838145e5e85802Db2a303',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/80/thumb/maidsafecoin.png?1547033750',
            'coingeckoId': 'maidsafecoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASNX',
            'name': 'Aave SNX v1',
            'address': '0x328C4c80BC7aCa0834Db37e6600A6c49E12Da4DE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x328c4c80bc7aca0834db37e6600a6c49e12da4de.png',
            'coingeckoId': 'aave-snx-v1',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'LMT',
            'name': 'Lympo Market',
            'address': '0x327673aE6B33Bd3d90f0096870059994f30Dc8AF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15155/thumb/coin_%282%29.png?1619995483',
            'coingeckoId': 'lympo-market-token',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'LAND',
            'name': 'Land',
            'address': '0x3258cd8134b6b28e814772dD91D5EcceEa512818',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://etherscan.io/token/images/farmland_32.png',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'ORC',
            'name': 'Oracle System',
            'address': '0x324AF2D5353f2dD138E234b359d30d67C64b1b20',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12517/thumb/www.oraclesystem.io-home-org-logo.png?1600382654',
            'coingeckoId': 'oracle-system',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DHN',
            'name': 'Dohrnii',
            'address': '0x32462bA310E447eF34FF0D15BCE8613aa8C4A244',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24966/thumb/dhn.png?1649586405',
            'coingeckoId': 'dohrnii',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MNFT',
            'name': 'MNFT',
            'address': '0x3235B13708F178Af6F110dE7177ED5De10c1093d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AGLD',
            'name': 'Adventure Gold',
            'address': '0x32353A6C91143bfd6C7d363B546e62a9A2489A20',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x32353A6C91143bfd6C7d363B546e62a9A2489A20/logo.png',
            'coingeckoId': 'adventure-gold',
            'listedIn': [
                'coingecko',
                'uniswap',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'EURE',
            'name': 'Monerium EUR emoney',
            'address': '0x3231Cb76718CDeF2155FC47b5286d82e6eDA273f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23354/thumb/eur.png?1643926562',
            'coingeckoId': 'monerium-eur-money',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CTX',
            'name': 'Cryptex Finance',
            'address': '0x321C2fE4446C7c963dc41Dd58879AF648838f98D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x321c2fe4446c7c963dc41dd58879af648838f98d.png',
            'coingeckoId': 'cryptex-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'MEGA',
            'name': 'MegaCryptoPolis',
            'address': '0x3218A02F8F8B5c3894ce30EB255F10Bcba13E654',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3218a02f8f8b5c3894ce30eb255f10bcba13e654.png',
            'coingeckoId': 'megacryptopolis',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'IMBTC',
            'name': 'The Tokenized Bitcoin',
            'address': '0x3212b29E33587A00FB1C83346f5dBFA69A458923',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x3212b29e33587a00fb1c83346f5dbfa69a458923.png',
            'coingeckoId': 'the-tokenized-bitcoin',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'LHC',
            'name': 'Lightcoin',
            'address': '0x320d31183100280CcdF69366CD56180Ea442A3E8',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16381/thumb/lhc.PNG?1623835248',
            'coingeckoId': 'lightcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TMT',
            'name': 'Traxia',
            'address': '0x3209f98BeBF0149B769ce26D71F7aEA8E435EfEa',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3312/thumb/tmt.png?1547037907',
            'coingeckoId': 'traxia',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CUP',
            'name': 'CouponBay',
            'address': '0x3209d14Ff61766359E64aceFf91877cEC2AD968E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24696/thumb/logo_200.png?1648625906',
            'coingeckoId': 'couponbay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RSR',
            'name': 'Reserve Rights',
            'address': '0x320623b8E4fF03373931769A31Fc52A4E78B5d70',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8365/thumb/rsr.png?1637983320',
            'coingeckoId': 'reserve-rights-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STORE',
            'name': 'Bit Store',
            'address': '0x31ea0de8119307aA264Bb4b38727aAb4E36b074f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20463/thumb/bit_store.PNG?1637064215',
            'coingeckoId': 'bit-store-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PEECH',
            'name': 'Peach Finance',
            'address': '0x31d939dC09A6bB55642fCBF9d56166EbC0BDeC9a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15376/thumb/PEECH-token-logo-200.png?1620692908',
            'coingeckoId': 'peach-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RAD',
            'name': 'Radicle',
            'address': '0x31c8EAcBFFdD875c74b94b077895Bd78CF1E64A3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x31c8eacbffdd875c74b94b077895bd78cf1e64a3.png',
            'coingeckoId': 'radicle',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'MUSO',
            'name': 'M US Oil',
            'address': '0x31c63146a635EB7465e5853020b39713AC356991',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/muso.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'UMAD',
            'name': 'MADworld',
            'address': '0x31c2415c946928e9FD1Af83cdFA38d3eDBD4326f',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20939/thumb/UMAD_.png?1638238294',
            'coingeckoId': 'madworld',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUY',
            'name': 'Burency',
            'address': '0x31Fdd1c6607F47C14a2821f599211c67AC20FA96',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12216/thumb/Ddphl9BD.png?1598238121',
            'coingeckoId': 'burency',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GBYTE',
            'name': 'Obyte',
            'address': '0x31F69dE127C8A0fF10819C0955490a4Ae46fcc2a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/561/thumb/byteball.png?1547564361',
            'coingeckoId': 'byteball',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XAUTBEAR',
            'name': '3X Short Tether Gold Token',
            'address': '0x31CbF205e26Ba63296FdBD254a6b1bE3ED28CE47',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11980/thumb/683JEXMN_400x400__1_.png?1596618975',
            'coingeckoId': '3x-short-tether-gold-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'stZEN',
            'name': 'stakedZEN',
            'address': '0x31B595e7cfDB624D10A3E7A562eD98c3567e3865',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/stzen.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'REALT-S-14882-TROES',
            'name': 'RealT   14882 Troester St  Detroit  MI ',
            'address': '0x31AA5fA895Fd186fDE12347A6fCaf540875b6434',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16600/thumb/14882-Troester-hero-1.jpg?1624542180',
            'coingeckoId': '14882-troester',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PUN',
            'name': 'CryptoPunt',
            'address': '0x31903E333809897eE57Af57567f4377a1a78756c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19230/thumb/cryptopunt.PNG?1634767637',
            'coingeckoId': 'cryptopunt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-11653-NOTTI',
            'name': 'RealT   11653 Nottingham Rd  Detroit  M',
            'address': '0x31820af2d43c08BD82Bd94B08974062482bD98D3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16622/thumb/11653-Nottingham-hero-1.jpg?1624551450',
            'coingeckoId': '11653-nottingham',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRVFRAX',
            'name': 'Curve fi FRAX USDC',
            'address': '0x3175Df0976dFA876431C2E9eE6Bc45b65d3473CC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26829/thumb/W1sQNVWo_400x400.jpeg?1660294792',
            'coingeckoId': 'curve-fi-frax-usdc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QDAO',
            'name': 'Q DAO Governance v1 0',
            'address': '0x3166C570935a7D8554c8f4eA792ff965D2EFe1f2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x3166c570935a7d8554c8f4ea792ff965d2efe1f2.png',
            'coingeckoId': 'q-dao-governance-token-v1-0',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'PIST',
            'name': 'Pist Trust',
            'address': '0x315dc1b524de57AE8e809A2e97699dbc895B8a21',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16397/thumb/main_img01_pc.png?1623910650',
            'coingeckoId': 'pist-trust',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-14078-CARLI',
            'name': 'RealT   14078 Carlisle St  Detroit  MI ',
            'address': '0x315699f1BA88383CFF2F2f30FcaD187aDb2E4D72',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16597/thumb/14078-Carlisle-hero-1-800x600.jpeg?1624541175',
            'coingeckoId': '14078-carlisle',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RUNE',
            'name': 'THORChain  ERC20 ',
            'address': '0x3155BA85D5F96b2d030a4966AF206230e46849cb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x3155ba85d5f96b2d030a4966af206230e46849cb.png',
            'coingeckoId': 'thorchain-erc20',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-19596-GOULB',
            'name': 'RealT   19596 Goulburn Ave  Detroit  MI',
            'address': '0x3150f0EbC0eFEe280b5348b9C8C271AD44Eb8B13',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16608/thumb/19596-Goulburn-hero-1.jpg?1624544786',
            'coingeckoId': '19596-goulburn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANGLE',
            'name': 'ANGLE',
            'address': '0x31429d1856aD1377A8A0079410B297e1a9e214c2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x31429d1856ad1377a8a0079410b297e1a9e214c2.png',
            'coingeckoId': 'angle-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'EVR',
            'name': 'Everus',
            'address': '0x3137619705b5fc22a3048989F983905e456b59Ab',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1027/thumb/everus.png?1547395108',
            'coingeckoId': 'everus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COFI',
            'name': 'CoinFi',
            'address': '0x3136eF851592aCf49CA4C825131E364170FA32b3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1709/thumb/coinfi.png?1547035969',
            'coingeckoId': 'coinfi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COIL',
            'name': 'Coil',
            'address': '0x3130C55d6138809687a1bFbE0F0cC258DF37B81E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27777/thumb/R1JruDU3_400x400.jpg?1665728793',
            'coingeckoId': 'coil',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-19996-JOANN',
            'name': 'RealT   19996 Joann Ave  Detroit  MI 48',
            'address': '0x311FC485f1FeA0c8Cc9b5c783E79f4313DDFa720',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16666/thumb/19996-Joanne-hero-1.jpg?1624626495',
            'coingeckoId': '19996-joann',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNDEAD',
            'name': 'Undead Blocks',
            'address': '0x310C8F00b9dE3c31Ab95ea68feb6C877538f7947',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25382/thumb/AB0Uemhlw6jR.png?1651488079',
            'coingeckoId': 'undead-blocks',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADS',
            'name': 'Alkimi',
            'address': '0x3106a0a076BeDAE847652F42ef07FD58589E001f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17979/thumb/alkimi.PNG?1630022799',
            'coingeckoId': 'alkimi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XIOT',
            'name': 'Xiotri',
            'address': '0x31024A4C3e9aEeb256B825790F5cb7ac645e7cD5',
            'decimals': 3,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12182/thumb/xiot_logo_512x512.png?1601775223',
            'coingeckoId': 'xiotri',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEC',
            'name': 'Decentr',
            'address': '0x30f271C9E86D2B7d00a6376Cd96A1cFBD5F0b9b3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x30f271c9e86d2b7d00a6376cd96a1cfbd5f0b9b3.png',
            'coingeckoId': 'decentr',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'RESET',
            'name': 'MetaReset',
            'address': '0x30df7D7EE52c1b03cd009e656F00AB875AdCEeD2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26631/thumb/logo.png?1659325867',
            'coingeckoId': 'metareset',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YDF',
            'name': 'Yieldification',
            'address': '0x30dcBa0405004cF124045793E1933C798Af9E66a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26699/thumb/logo.png?1659609811',
            'coingeckoId': 'yieldification',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SEED',
            'name': 'MetaGame',
            'address': '0x30cF203b48edaA42c3B4918E955fED26Cd012A3F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13099/thumb/V8phEz8V.png?1612854078',
            'coingeckoId': 'metagame',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PUNKS',
            'name': 'PUNKS Comic',
            'address': '0x30c084890FC07D78F3499ffc818B3225bC8812EA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22337/thumb/PunksIcon_Small.png?1641520641',
            'coingeckoId': 'punks-comic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MSM',
            'name': 'MoonShot Max',
            'address': '0x30Ffd603c601bf4B696D24F498af950aCcfcd6ae',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22857/thumb/1E-wfcKG_400x400.jpg?1642751522',
            'coingeckoId': 'moonshot-max',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SD',
            'name': 'Stader',
            'address': '0x30D20208d987713f46DFD34EF128Bb16C404D10f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20658/thumb/sd.png?1642927667',
            'coingeckoId': 'stader',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UGOTCHI',
            'name': 'Unicly Aavegotchi Astronauts Collection',
            'address': '0x30C2a84AeD6Db30E31cf4d7059b1836c12c68068',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14901/thumb/uGOTCHI.jpg?1618936478',
            'coingeckoId': 'unicly-aavegotchi-astronauts-collection',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FISH',
            'name': 'Penguin Party Fish',
            'address': '0x30BCd71b8d21FE830e493b30e90befbA29de9114',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14359/thumb/Screen-Shot-2021-03-10-at-1-05-40-AM.png?1615629402',
            'coingeckoId': 'penguin-party-fish',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LR',
            'name': 'Looks Rare',
            'address': '0x30Abb1a1e2913D0991Bbf06Dd79dfCC224361de9',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20253/thumb/CGLRLOGO.png?1636701518',
            'coingeckoId': 'looks-rare',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'F11',
            'name': 'First Eleven',
            'address': '0x309c1b3282c49E4dC6796644417f8c76b7C8233C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21295/thumb/f11.PNG?1638875819',
            'coingeckoId': 'first-eleven',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDB',
            'name': 'USD Bancor',
            'address': '0x309627af60F0926daa6041B8279484312f2bf060',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x309627af60f0926daa6041b8279484312f2bf060.png',
            'coingeckoId': 'usd-bancor',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'HLP',
            'name': 'HLP',
            'address': '0x308564DC5217c39386F5eaE96545159e1D396661',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12668/thumb/simbolo-hlp-token.png?1601467816',
            'coingeckoId': 'help-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFTY',
            'name': 'NFTY DeFi Protocol',
            'address': '0x3085154623F51b00Dedfc6Ceeb5197277A66B17B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18584/thumb/nfty_logo.png?1632551145',
            'coingeckoId': 'nifty-token',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'PLA',
            'name': 'PLANET',
            'address': '0x307d45Afbb7E84F82ef3D251A6bb0F00Edf632E4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9279/thumb/LOGO_BLACK.png?1565731302',
            'coingeckoId': 'planet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HAPPY',
            'name': 'HappyFans',
            'address': '0x3079F61704E9eFa2BcF1db412f735d8d4cFa26f4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19027/thumb/O1pUoR8G_400x400.jpeg?1654248996',
            'coingeckoId': 'happyfans',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARAW',
            'name': 'ARAW',
            'address': '0x30680AC0a8A993088223925265fD7a76bEb87E7F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4281/thumb/araw.png?1547039642',
            'coingeckoId': 'araw-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MORE',
            'name': 'More',
            'address': '0x305DE070488C8469dFAC957226c9c900c4BFbA22',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/742/thumb/1722.png?1620080666',
            'coingeckoId': 'legends-room',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GDC',
            'name': 'Global Digital Content',
            'address': '0x301C755bA0fcA00B1923768Fffb3Df7f4E63aF31',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x301c755ba0fca00b1923768fffb3df7f4e63af31.png',
            'coingeckoId': 'global-digital-content',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SOX',
            'name': 'Ethersocks',
            'address': '0x300a902513815028e97FC79E92082Ce6a98d3b74',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14943/thumb/LOGO-for-CMC-V1.png?1619429911',
            'coingeckoId': 'ethersocks',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JDC',
            'name': 'JD Coin',
            'address': '0x2fe39f22EAC6d3c1C86DD9D143640EbB94609FCE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9958/thumb/Cv-2ca6p_400x400.jpg?1575926305',
            'coingeckoId': 'jd-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MCS',
            'name': 'MCS',
            'address': '0x2fdF40C484b1BD6F1c214ACAC737FEDc8b03E5a8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18925/thumb/MCS.png?1633928135',
            'coingeckoId': 'mcs-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRB',
            'name': 'CRB Coin',
            'address': '0x2fd8BC03D9e827F77faC20B5130eE98B7F80149d',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19399/thumb/crb.png?1635158925',
            'coingeckoId': 'crb-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SYM',
            'name': 'SymVerse',
            'address': '0x2fd61567c29E7ADB4Ca17e60E1f4a3Fcfe68aCb8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9449/thumb/Picture1.png?1567551375',
            'coingeckoId': 'symverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LATX',
            'name': 'LatiumX',
            'address': '0x2f85E502a988AF76f7ee6D83b7db8d6c0A823bf9',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3500/thumb/latium.jpg?1547038261',
            'coingeckoId': 'latiumx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NAMI',
            'name': 'Nami Corporation',
            'address': '0x2f7B618993cc3848d6C7ed9CdD5e835E4Fe22b98',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13729/thumb/nami_logo_256.png?1611224464',
            'coingeckoId': 'nami-corporation-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EKTA',
            'name': 'Ekta',
            'address': '0x2f75113b13D136F861d212Fa9b572F2C79Ac81C4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22350/thumb/token.jpg?1641882684',
            'coingeckoId': 'ekta-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETHBEAR',
            'name': '3X Short Ethereum Token',
            'address': '0x2f5e2c9002C058c063d21A06B6cabb50950130c8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10172/thumb/683JEXMN_400x400_%281%29.png?1576506124',
            'coingeckoId': '3x-short-ethereum-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNCL',
            'name': 'UNCL',
            'address': '0x2f4eb47A1b1F4488C71fc10e39a4aa56AF33Dd49',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13102/thumb/uncl_logo.png?1605230945',
            'coingeckoId': 'uncl',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'FER',
            'name': 'Ferro',
            'address': '0x2f32b39023dA7d6A6486A85d12B346EB9C2A0D19',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26111/thumb/FER_Token.png?1655887206',
            'coingeckoId': 'ferro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIT',
            'name': 'Music Infinity',
            'address': '0x2f29CE2A733983978C01ae3e3c7f224eD0EF7E72',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26290/thumb/20882.png?1657158988',
            'coingeckoId': 'music-infinity-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REV',
            'name': 'Revain',
            'address': '0x2ef52Ed7De8c5ce03a4eF0efbe9B7450F2D7Edc9',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1107/thumb/256x256.png?1587018647',
            'coingeckoId': 'revain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MVDG',
            'name': 'MetaVerse Dog',
            'address': '0x2eE543b8866F46cC3dC93224C6742a8911a59750',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23581/thumb/imgonline-com-ua-Resize-5J7DYgBqxK7I.png?1644646598',
            'coingeckoId': 'metaverse-dog',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZEE',
            'name': 'ZeroSwap',
            'address': '0x2eDf094dB69d6Dcd487f1B3dB9febE2eeC0dd4c5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x2edf094db69d6dcd487f1b3db9febe2eec0dd4c5.png',
            'coingeckoId': 'zeroswap',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'TOKE',
            'name': 'Tokemak',
            'address': '0x2e9d63788249371f1DFC918a52f8d799F4a38C94',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x2e9d63788249371f1dfc918a52f8d799f4a38c94.png',
            'coingeckoId': 'tokemak',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': '3OH',
            'name': 'ThreeOh DAO',
            'address': '0x2e922f84EC5BB9CedfBb1A99543b143AA43D94b6',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26398/thumb/98052236.jpeg?1657770947',
            'coingeckoId': 'threeoh-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KATA',
            'name': 'Katana Inu',
            'address': '0x2e85ae1C47602f7927bCabc2Ff99C40aA222aE15',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21872/thumb/Katana_Inu512.png?1640236880',
            'coingeckoId': 'katana-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CERES',
            'name': 'Ceres',
            'address': '0x2e7B0d4F9B2EaF782eD3D160e3a0a4b1a7930aDA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17959/thumb/sQLDgqx.png?1648442923',
            'coingeckoId': 'ceres',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WER1',
            'name': 'Protectors of the Realm',
            'address': '0x2e6a8306286600E564c16Aa0b7A93fcED09D586A',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27805/thumb/WeR1logo200x200.png?1667293518',
            'coingeckoId': 'protectors-of-the-realm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YFDOT',
            'name': 'Yearn Finance DOT',
            'address': '0x2e6539edc3b76f1E21B71d214527FAbA875F70F3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12791/thumb/logo_%2815%29.png?1602580328',
            'coingeckoId': 'yearn-finance-dot',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VOICE',
            'name': 'Voice',
            'address': '0x2e2364966267B5D7D2cE6CD9A9B5bD19d9C7C6A9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x2e2364966267b5d7d2ce6cd9a9b5bd19d9c7c6a9.png',
            'coingeckoId': 'nix-bridge-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MTLX',
            'name': 'Mettalex',
            'address': '0x2e1E15C44Ffe4Df6a0cb7371CD00d5028e571d14',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x2e1e15c44ffe4df6a0cb7371cd00d5028e571d14.png',
            'coingeckoId': 'mettalex',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SHIBBOO',
            'name': 'Shibboo Inu',
            'address': '0x2e1368Fe7B9CbB3F777c2d85e9e804f8f67D7074',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19601/thumb/shibboo.jpeg?1635491024',
            'coingeckoId': 'shibboo-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DICE',
            'name': 'Etheroll',
            'address': '0x2e071D2966Aa7D8dECB1005885bA1977D6038A65',
            'decimals': 16,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1232/thumb/etheroll.png?1548125481',
            'coingeckoId': 'etheroll',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHARI',
            'name': 'Sharity',
            'address': '0x2df488b8A4270bAc5C2cE5FF467A0C5fd2AA49d6',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21796/thumb/HHGyEDIv_400x400.jpg?1640060553',
            'coingeckoId': 'sharity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DBI',
            'name': 'Don t Buy Inu',
            'address': '0x2dE509bf0014ddF697b220bE628213034d320EcE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28058/thumb/1Artboard_1_%282%29.png?1667298091',
            'coingeckoId': 'don-t-buy-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CERE',
            'name': 'Cere Network',
            'address': '0x2dA719DB753dFA10a62E140f436E1d67F2ddB0d6',
            'decimals': 10,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1636424977825_5595788980190983.png',
            'coingeckoId': 'cere-network',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'BANK',
            'name': 'Bankless DAO',
            'address': '0x2d94AA3e47d9D5024503Ca8491fcE9A2fB4DA198',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x2d94aa3e47d9d5024503ca8491fce9a2fb4da198.png',
            'coingeckoId': 'bankless-dao',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'POINT',
            'name': 'Point Coin',
            'address': '0x2d64750eC85f6eC442E3093D74C7b1b0a133BE6a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27295/thumb/point.png?1663150205',
            'coingeckoId': 'point-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DMT',
            'name': 'DMarket',
            'address': '0x2ccbFF3A042c68716Ed2a2Cb0c544A9f1d1935E1',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1602/thumb/dmarket.png?1547223830',
            'coingeckoId': 'dmarket',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LPK',
            'name': 'Kripton',
            'address': '0x2cc71c048A804Da930e28E93F3211dC03c702995',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://static.debank.com/image/eth_token/logo_url/0x2cc71c048a804da930e28e93f3211dc03c702995/5c96d1ce2205d313171db49ddf422ba0.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SCHA',
            'name': 'Schain Wallet',
            'address': '0x2cAd4991f62fc6Fcd8EC219f37E7DE52B688B75A',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12135/thumb/schain.png?1597462731',
            'coingeckoId': 'schain-wallet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANB',
            'name': 'Angryb',
            'address': '0x2c9acEb63181cd08a093d052ec041e191f229692',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17270/thumb/AngryBlockchain.png?1627005396',
            'coingeckoId': 'angryb',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LCE',
            'name': 'Lance Coin',
            'address': '0x2c644C3bBeA053ed95a6bC04A94C9CE928Ff9881',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14707/thumb/logo200x200_%282%29.png?1617873370',
            'coingeckoId': 'lance-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HLTC',
            'name': 'Huobi Litecoin',
            'address': '0x2c000c0093dE75a8fA2FccD3d97b314e20b431C3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14110/thumb/HLTC.png?1614335967',
            'coingeckoId': 'huobi-litecoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TON',
            'name': 'Tokamak Network',
            'address': '0x2be5e8c109e2197D077D13A82dAead6a9b3433C5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12260/thumb/D919x5-s_400x400.png?1598568068',
            'coingeckoId': 'tokamak-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADB',
            'name': 'adbank',
            'address': '0x2baac9330Cf9aC479D819195794d79AD0c7616e3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2806/thumb/adbank.png?1547036978',
            'coingeckoId': 'adbank',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OMv1',
            'name': 'OM Token',
            'address': '0x2baEcDf43734F22FD5c152DB08E3C27233F0c7d2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x2baecdf43734f22fd5c152db08e3c27233f0c7d2.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'CREAM',
            'name': 'Cream',
            'address': '0x2ba592F78dB6436527729929AAf6c908497cB200',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x2ba592f78db6436527729929aaf6c908497cb200.png',
            'coingeckoId': 'cream-2',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'SKIN',
            'name': 'SkinCoin',
            'address': '0x2bDC0D42996017fCe214b21607a515DA41A9E0C5',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/830/thumb/skincoin.png?1547034622',
            'coingeckoId': 'skincoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HSC',
            'name': 'HashCoin',
            'address': '0x2bBA3CF6DE6058cc1B4457Ce00deb359E2703d7F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4989/thumb/hashfuture-logo.png?1531741876',
            'coingeckoId': 'hashcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ACRV',
            'name': 'Aladdin cvxCRV',
            'address': '0x2b95A1Dcc3D405535f9ed33c219ab38E8d7e0884',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethereum-optimism.github.io/data/aCRV/logo.svg',
            'coingeckoId': 'aladdin-cvxcrv',
            'listedIn': [
                'coingecko',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'MAPS',
            'name': 'Maps.me Token',
            'address': '0x2b915b505c017ABb1547aA5Ab355FbE69865cC6D',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x2b915b505c017abb1547aa5ab355fbe69865cc6d.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'LBLOCK',
            'name': 'Lucky Block',
            'address': '0x2b867efD2dE4Ad2B583Ca0CB3dF9C4040Ef4D329',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23126/thumb/logo-200x200-LB-09.png?1643268144',
            'coingeckoId': 'lucky-block',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CPI',
            'name': 'Crypto Price Index',
            'address': '0x2b67D1a87A8D8B280A23e97bC55095215eE0ec53',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12178/thumb/Z7x7eUa.png?1597886884',
            'coingeckoId': 'crypto-price-index',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HEX',
            'name': 'HEX',
            'address': '0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x2b591e99afe9f32eaa6214f7b7629768c40eeb39.png',
            'coingeckoId': 'hex',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'MARX',
            'name': 'MarX',
            'address': '0x2b100Be232F90Dd33d3B6e4b9ad70D1E8AC0E208',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16258/thumb/marx.PNG?1623402476',
            'coingeckoId': 'marx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-14494-CHELS',
            'name': 'RealT   14494 Chelsea Ave  Detroit  MI ',
            'address': '0x2adc1cfA726A45264A328D9d2e2C692ceac97458',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16618/thumb/14494-Chelsea-hero-1.jpg?1624550267',
            'coingeckoId': '14494-chelsea',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SLC',
            'name': 'Selenium',
            'address': '0x2ac22EbC138fF127566F68db600Addad7dF38d38',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11169/thumb/jbIJzuZw_400x400.jpg?1589449310',
            'coingeckoId': 'selenium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BYTZ',
            'name': 'BYTZ',
            'address': '0x2aaD9Dbc82611485a52325923e1187734e951B78',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4017/thumb/bytz.png?1584519997',
            'coingeckoId': 'bytz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WHL',
            'name': 'WhaleRoom',
            'address': '0x2aF72850c504dDD3c1876C66a914cAee7Ff8a46A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14328/thumb/ArEX8tkV_400x400.png?1615432175',
            'coingeckoId': 'whaleroom',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BED',
            'name': 'Bankless BED Index',
            'address': '0x2aF1dF3AB0ab157e1E2Ad8F88A7D04fbea0c7dc6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x2af1df3ab0ab157e1e2ad8f88a7d04fbea0c7dc6.png',
            'coingeckoId': 'bankless-bed-index',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'RULER',
            'name': 'Ruler Protocol',
            'address': '0x2aECCB42482cc64E087b6D2e5Da39f5A7A7001f8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x2aeccb42482cc64e087b6d2e5da39f5a7a7001f8.png',
            'coingeckoId': 'ruler-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'EGT',
            'name': 'Elastic Governance',
            'address': '0x2aA5cE395b00CC486159aDbDD97c55b535CF2cf9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x2aA5cE395b00CC486159aDbDD97c55b535CF2cf9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'AQT',
            'name': 'Alpha Quark',
            'address': '0x2a9bDCFF37aB68B95A53435ADFd8892e86084F93',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12872/thumb/alpha_quark_logo.png?1605169527',
            'coingeckoId': 'alpha-quark-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPA',
            'name': 'Sperax',
            'address': '0x2a95FE4c7e64e09856989F9eA0b57B9AB5f770CB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://etherscan.io/token/images/speraxtoken_32.png',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'WILD',
            'name': 'Wilder World',
            'address': '0x2a3bFF78B79A009976EeA096a51A948a3dC00e34',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15407/thumb/WWLogo_Gradient_Cirlce.png?1620743969',
            'coingeckoId': 'wilder-world',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'PATH',
            'name': 'PathDAO',
            'address': '0x2a2550e0A75aCec6D811AE3930732F7f3ad67588',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21146/thumb/thumbnail.png?1655283979',
            'coingeckoId': 'pathdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UniDAIETH',
            'name': 'Uniswap DAI LP',
            'address': '0x2a1530C4C41db0B0b2bB646CB5Eb1A67b7158667',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'ipfs://QmYNz8J1h5yefkaAw6tZwUYoJyBTWmBXgAY28ZWZ5rPsLR',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'EXG',
            'name': 'Exgold',
            'address': '0x2a1174d1Cd4348Cb1EAeC3f00310908ca289E5be',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14068/thumb/-HgHqzsy_400x400.png?1614137624',
            'coingeckoId': 'exgold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DV',
            'name': 'Dreamverse',
            'address': '0x2a03A891ADD2dc6d0F7b94419086630ba5cB65b6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21554/thumb/dv.png?1639464955',
            'coingeckoId': 'dreamverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TEMCO',
            'name': 'TEMCO',
            'address': '0x2Fc246aA66F0da5bB1368F688548ecBBE9bdee5d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6210/thumb/bSZ7HUuS_400x400.jpg?1549002381',
            'coingeckoId': 'temco',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADINU',
            'name': 'Adventure Inu',
            'address': '0x2F9f363685fFecc8d7BEd0486d8C2b21232BDC5a',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20036/thumb/Adventure200x200.png?1636424176',
            'coingeckoId': 'adventure-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATH',
            'name': 'Athens',
            'address': '0x2F9411088cEF82Fd9fB904Eb8092f28eB485C8F6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24514/thumb/ATH-logo-round.png?1647951491',
            'coingeckoId': 'athens',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SCARD',
            'name': 'SCARDust',
            'address': '0x2F6Ad7743924B1901a0771746152dde44C5F11DE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22098/thumb/SCARDust-token-icon-full-color-transparent-bg.png?1640796874',
            'coingeckoId': 'scardust',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDX',
            'name': 'Dollars',
            'address': '0x2F6081E3552b1c86cE4479B80062A1ddA8EF23E3',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12377/thumb/rCdP56C.png?1599445426',
            'coingeckoId': 'dollars',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DFP2',
            'name': 'DefiPlaza',
            'address': '0x2F57430a6ceDA85a67121757785877b4a71b8E6D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19552/thumb/profile-round.png?1654153498',
            'coingeckoId': 'defiplaza',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BB-A-USDT',
            'name': 'Balancer Boosted Aave USDT',
            'address': '0x2F4eb100552ef93840d5aDC30560E5513DFfFACb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25826/thumb/0x2bbf681cc4eb09218bee85ea2a5d3d13fa40fc0c.png?1654063979',
            'coingeckoId': 'balancer-boosted-aave-usdt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EAGON',
            'name': 'EagonSwap',
            'address': '0x2F25d402829cA4085B8Ea4D3BC68Bf203F5a9faB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20431/thumb/IMG-20210912-WA0003.jpg?1637035180',
            'coingeckoId': 'eagonswap-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FREE',
            'name': 'FREEdom coin',
            'address': '0x2F141Ce366a2462f02cEA3D12CF93E4DCa49e4Fd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5585/thumb/free.png?1639547764',
            'coingeckoId': 'freedom-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OCC',
            'name': 'OccamFi',
            'address': '0x2F109021aFe75B949429fe30523Ee7C0D5B27207',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x2f109021afe75b949429fe30523ee7c0d5b27207.png',
            'coingeckoId': 'occamfi',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MATSUSWAP',
            'name': 'MatsuSwap',
            'address': '0x2F0F622a93c85C43Fd47f4Ab119360f5846026C4',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28041/thumb/200x200-1.png?1667202469',
            'coingeckoId': 'matsuswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PRVG',
            'name': 'Privilege',
            'address': '0x2Ec3275f7aCe4044e499823F511cd58250be8E3d',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27585/thumb/prvg.png?1664701884',
            'coingeckoId': 'privilege',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MET',
            'name': 'Metronome',
            'address': '0x2Ebd53d035150f328bd754D6DC66B99B0eDB89aa',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3249/thumb/metronome.png?1548084800',
            'coingeckoId': 'metronome',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SMC1',
            'name': 'semicon1',
            'address': '0x2EDc01F6E8B2387e76872cBBE1b69407ec058f34',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27503/thumb/mvsyBzOGKm20JndbDQrD8jXwAVwVTIA87Xc1ULBqb7NcFCv0fnpN71bACOvyIbc798xCyssiXAtEmTyumW2APB_hdvz7E_wti4nFTHTSBclhMWXO5Osiilpb6haIfFFHp1VStEPZ-RiHJtIbiqzyqtnAoD7BE2flxQ1fBLYnzff8-_tQ9-olz-31m8kZQGMPvW7zDw9WFhm-xUGQUd8HJVsFo9_74cK_Ko3fb4sKLidVs0P.jpeg?1664331574',
            'coingeckoId': 'semicon1',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SBET',
            'name': 'Sports Bet',
            'address': '0x2ED2Cc2c858A8a8219fD2F2d9E170285dBd02756',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25312/thumb/sbet_logo_256.png?1651214220',
            'coingeckoId': 'sports-bet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GEIST',
            'name': 'Geist.Finance Protocol Token',
            'address': '0x2EBfF165CB363002C5f9cBcfd6803957BA0B7208',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'THN',
            'name': 'Throne',
            'address': '0x2E95Cea14dd384429EB3c4331B776c4CFBB6FCD9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16965/thumb/aXhD3i_g_400x400.jpg?1625804145',
            'coingeckoId': 'throne',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TEP',
            'name': 'Tepleton',
            'address': '0x2E65E12b5f0fD1D58738c6F38dA7D57F5F183d1c',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9622/thumb/tep.PNG?1569856834',
            'coingeckoId': 'tepleton',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PACHA',
            'name': 'Kay Pacha',
            'address': '0x2Dd1B9410c73E16b60240E529e38E30425F00d4E',
            'decimals': 10,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27194/thumb/0_Lzf-8HxwOnPjYWi-.jpeg?1662539360',
            'coingeckoId': 'kay-pacha',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COOL20',
            'name': 'Cool Cats',
            'address': '0x2Dab4cE3490BB50b2EA4C07Ab1B6a9CfE29D89B3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17862/thumb/unnamed_%283%29.png?1629683969',
            'coingeckoId': 'cool-cats',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WRAP',
            'name': 'Wrapped',
            'address': '0x2DDbFb13677E8Df85F37Aa8E578424E99AF7Cb62',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x2DDbFb13677E8Df85F37Aa8E578424E99AF7Cb62/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'EYES',
            'name': 'EYES Protocol',
            'address': '0x2DCA19E944453e46d9130950Ca135461b3Bc0c30',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9734/thumb/pTaAZUI6_400x400.jpg?1571308667',
            'coingeckoId': 'eyes-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AGA',
            'name': 'AGA',
            'address': '0x2D80f5F5328FdcB6ECeb7Cacf5DD8AEDaEC94e20',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12180/thumb/aga-logo.png?1597937396',
            'coingeckoId': 'aga-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MANA',
            'name': 'Genesis Mana',
            'address': '0x2D77f5b3EFA51821aD6483ADAf38EA4cb1824cC5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19031/thumb/genesis-icon.png?1634196000',
            'coingeckoId': 'genesis-mana',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IMP',
            'name': 'Imperial Obelisk',
            'address': '0x2D5C73f3597B07F23C2bB3F2422932E67eca4543',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27625/thumb/dxanVPfL_400x400.jpeg?1664935231',
            'coingeckoId': 'imperial-obelisk-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFXC',
            'name': 'NFX Coin',
            'address': '0x2D39EC4da54329D28d230B4973F5Aa27886C3AeE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10694/thumb/nfx.png?1604656044',
            'coingeckoId': 'nfx-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '2CRZ',
            'name': '2crazyNFT',
            'address': '0x2C9C19cE3b15ae77C6d80aEc3C1194Cfd6F7F3fA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16797/thumb/2crz.png?1635525909',
            'coingeckoId': '2crazynft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VIB',
            'name': 'Viberate',
            'address': '0x2C974B2d0BA1716E644c1FC59982a89DDD2fF724',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x2c974b2d0ba1716e644c1fc59982a89ddd2ff724.png',
            'coingeckoId': 'viberate',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'VID',
            'name': 'Vivid Labs',
            'address': '0x2C9023bBc572ff8dc1228c7858A280046Ea8C9E5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3376/thumb/1638280236344.jpg?1645083911',
            'coingeckoId': 'videocoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPDX',
            'name': 'SPENDER X',
            'address': '0x2C756e74B7309d785b5e2960ef262c4f14a87930',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9259/thumb/spdx.PNG?1565650786',
            'coingeckoId': 'spender-x',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-18776-SUNDE',
            'name': 'RealT   18776 Sunderland Rd  Detroit  M',
            'address': '0x2C6f00A020B613C9419bb6C1Ac220036dbE9e622',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16602/thumb/18776-Sunderland-hero-1.jpg?1624543588',
            'coingeckoId': '18776-sunderland',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STKETH',
            'name': 'pSTAKE Staked ETH',
            'address': '0x2C5Bcad9Ade17428874855913Def0A02D8bE2324',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25798/thumb/Ethereum.png?1653921854',
            'coingeckoId': 'pstake-staked-eth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRYB',
            'name': 'BiLira',
            'address': '0x2C537E5624e4af88A7ae4060C022609376C8D0EB',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x2c537e5624e4af88a7ae4060c022609376c8d0eb.png',
            'coingeckoId': 'bilira',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'OST',
            'name': 'OST',
            'address': '0x2C4e8f2D746113d0696cE89B35F0d8bF88E0AEcA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x2c4e8f2d746113d0696ce89b35f0d8bf88e0aeca.png',
            'coingeckoId': 'simple-token',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'MNTL',
            'name': 'AssetMantle',
            'address': '0x2C4F1DF9c7DE0C59778936C9b145fF56813F3295',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25181/thumb/thumbnail.png?1658821784',
            'coingeckoId': 'assetmantle',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UniMKRETH',
            'name': 'Uniswap MKR LP',
            'address': '0x2C4Bd064b998838076fa341A83d007FC2FA50957',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'ipfs://QmSS94EJyBeHeUmoDmGjQjeuUHQxTcMaD8Zvw8W8XdGDBv',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'TAN',
            'name': 'Taklimakan Network',
            'address': '0x2C36204a0712A2a50E54A62F7c4F01867e78cB53',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1963/thumb/takli.png?1566738740',
            'coingeckoId': 'taklimakan-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KITTYDINGER',
            'name': 'Schrodinger',
            'address': '0x2C33B28527A63Cdf13C0B24Ce4cf5BF9C9fB3BC6',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19649/thumb/kitty_dinger.png?1636332557',
            'coingeckoId': 'schrodinger',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PRARE',
            'name': 'Polkarare',
            'address': '0x2C2f7e7C5604D162d75641256b80F1Bf6f4dC796',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15388/thumb/Image_from_iOS.png?1621145396',
            'coingeckoId': 'polkarare',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DAMA',
            'name': 'DAMA',
            'address': '0x2C0da41C89AdB5a1d4430E5761b9B400911426B0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23636/thumb/img-token-dama.png?1644907682',
            'coingeckoId': 'dama',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BCMC',
            'name': 'Blockchain Monster Hunt',
            'address': '0x2BA8349123de45E931a8C8264c332E6e9CF593F9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x2ba8349123de45e931a8c8264c332e6e9cf593f9.png',
            'coingeckoId': 'blockchain-monster-hunt',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'WSCRT',
            'name': 'Secret  ERC20 ',
            'address': '0x2B89bF8ba858cd2FCee1faDa378D5cd6936968Be',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/wscrt.jpg',
            'coingeckoId': 'secret-erc20',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'MINER',
            'name': 'Meta Miner',
            'address': '0x2B645A57e82123796c3EA7d78DE27c3e3ff7f016',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26876/thumb/200x200.png?1660620947',
            'coingeckoId': 'meta-miner',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDFL',
            'name': 'USDFreeLiquidity',
            'address': '0x2B4200A8D373d484993C37d63eE14AeE0096cd12',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x2b4200a8d373d484993c37d63ee14aee0096cd12.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SAHU',
            'name': 'Sakhalin Husky',
            'address': '0x2B1Fe2cea92436E8C34B7C215Af66Aaa2932a8b2',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15750/thumb/HUSKY_H-1024x683-1.png?1621785464',
            'coingeckoId': 'sakhalin-husky',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VOY',
            'name': 'enVoy DeFi',
            'address': '0x2Ac8172D8Ce1C5Ad3D869556FD708801a42c1c0E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22105/thumb/voy_tokenconcepts-01-32x32-1-e1640735076388.png?1640828528',
            'coingeckoId': 'envoy-defi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TONE',
            'name': 'TE FOOD',
            'address': '0x2Ab6Bb8408ca3199B8Fa6C92d5b455F820Af03c4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x2ab6bb8408ca3199b8fa6c92d5b455f820af03c4.png',
            'coingeckoId': 'te-food',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'LEO',
            'name': 'LEO Token',
            'address': '0x2AF5D2aD76741191D15Dfe7bF6aC92d4Bd912Ca3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x2af5d2ad76741191d15dfe7bf6ac92d4bd912ca3.png',
            'coingeckoId': 'leo-token',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'OUSD',
            'name': 'Origin Dollar',
            'address': '0x2A8e1E676Ec238d8A992307B495b45B3fEAa5e86',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x2a8e1e676ec238d8a992307b495b45b3feaa5e86.png',
            'coingeckoId': 'origin-dollar',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'WXTZ',
            'name': 'StakerDAO Wrapped Tezos',
            'address': '0x2A69655C22eDa32ff48d315bB26ED45f150700b4',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14763/thumb/wXTZ-token-FullColor.png?1618281765',
            'coingeckoId': 'wrapped-tezos',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDI',
            'name': 'Interest Protocol USDi',
            'address': '0x2A54bA2964C8Cd459Dc568853F79813a60761B58',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25999/thumb/20598.png?1655277115',
            'coingeckoId': 'interest-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ACU',
            'name': 'ACU Platform',
            'address': '0x2A4F6675469Ee5a31664925b486EBADDfb838dd0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20845/thumb/acu_platform.PNG?1637744893',
            'coingeckoId': 'acu-platform',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RNB',
            'name': 'Rentible',
            'address': '0x2A039B1D9bBDccBb91be28691b730ca893e5e743',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15176/thumb/Rentible-Token-Logo.png?1620025850',
            'coingeckoId': 'rentible',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EVER',
            'name': 'Everscale',
            'address': '0x29d578CEc46B50Fa5C88a99C6A4B70184C062953',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12783/thumb/everscale_badge_main_round_1x.png?1640050196',
            'coingeckoId': 'everscale',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HEC',
            'name': 'Hector',
            'address': '0x29b3d220f0f1E37b342Cf7c48c1164BF5bf79eFa',
            'decimals': 9,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FREL',
            'name': 'Freela',
            'address': '0x29CeDDCF0Da3c1D8068a7DFbD0FB06c2E438FF70',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1634699889541_6218818265268933.png',
            'coingeckoId': 'freela',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'TIDAL',
            'name': 'Tidal Finance',
            'address': '0x29CbD0510EEc0327992CD6006e63F9Fa8E7f33B7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x29cbd0510eec0327992cd6006e63f9fa8e7f33b7.png',
            'coingeckoId': 'tidal-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ESPRO',
            'name': 'EsportsPro',
            'address': '0x29C56E7CB9C840d2b2371b17e28BaB44AD3c3ead',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14187/thumb/logo.jpg?1614830245',
            'coingeckoId': 'esportspro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHKOOBY',
            'name': 'SHKOOBY INU',
            'address': '0x29A5c1Db7321C5c9EaE57F9366eE8eef00cA11fb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19790/thumb/shkooby.png?1645255468',
            'coingeckoId': 'shkooby-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFP',
            'name': 'New Frontier Presents',
            'address': '0x299698B4B44BD6d023981A7317798dEe12860834',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x299698b4b44bd6d023981a7317798dee12860834.png',
            'coingeckoId': 'new-frontier-presents',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'FMG',
            'name': 'FM Gallery',
            'address': '0x2991341D28Eaea277785D20e1d878D478c7bA4C7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14527/thumb/FR44th_I_400x400.jpg?1616742157',
            'coingeckoId': 'fm-gallery',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PBR',
            'name': 'PolkaBridge',
            'address': '0x298d492e8c1d909D3F63Bc4A36C66c64ACB3d695',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x298d492e8c1d909d3f63bc4a36c66c64acb3d695.png',
            'coingeckoId': 'polkabridge',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'VALOR',
            'name': 'Smart Valor',
            'address': '0x297E4e5e59Ad72B1B0A2fd446929e76117be0E0a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7231/thumb/smart_valor.png?1555925772',
            'coingeckoId': 'smart-valor',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRDL',
            'name': 'Strudel Finance',
            'address': '0x297D33e17e61C2Ddd812389C2105193f8348188a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x297d33e17e61c2ddd812389c2105193f8348188a.png',
            'coingeckoId': 'strudel-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SHINSHU',
            'name': 'Shinshu Inu',
            'address': '0x2965395F71B7d97ede251E9B63e44dfA9647cC0A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28093/thumb/shinshu200.png?1667536301',
            'coingeckoId': 'shinshu-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NOONE',
            'name': 'No One',
            'address': '0x296233E84c1D7bfF11121BF6D60f0FFA39C3f0cf',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19393/thumb/logo-200x200_%289%29.png?1635150101',
            'coingeckoId': 'no-one',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XI',
            'name': 'Xi',
            'address': '0x295B42684F90c77DA7ea46336001010F2791Ec8c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18640/thumb/v5NMxPo.png?1632751046',
            'coingeckoId': 'xi-token',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'FROGE',
            'name': 'froge.finance',
            'address': '0x29502fE4d233EF0b45C3647101Fa1252cE0634BD',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://api.rubic.exchange/assets/ethereum/0x29502fe4d233ef0b45c3647101fa1252ce0634bd/logo.png',
            'coingeckoId': 'froge-finance',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'METAL',
            'name': 'Metal Blockchain',
            'address': '0x294559FA758C88D639Fd085751e463FEe7806eab',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27222/thumb/metal-chain.png?1662685566',
            'coingeckoId': 'metal-blockchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EHASH',
            'name': 'EHash',
            'address': '0x2942E3B38E33123965bfbc21E802bE943a76bbC6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14180/thumb/ehash.png?1614820416',
            'coingeckoId': 'ehash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SLAY2EARN',
            'name': 'Slay To Earn',
            'address': '0x2932624Ff57f5920Ce498Bb343385C0332Ec6E40',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26257/thumb/cmc-logo.png?1657600932',
            'coingeckoId': 'slay-to-earn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TOZ',
            'name': 'Tozex',
            'address': '0x29239242A83479a4074Cb1c9e2A3e6705A4A4455',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13772/thumb/Logo_toz_200x200.png?1611698823',
            'coingeckoId': 'tozex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MONO',
            'name': 'MonoX',
            'address': '0x2920f7d6134f4669343e70122cA9b8f19Ef8fa5D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20901/thumb/e51c6UaU_400x400.png?1637879085',
            'coingeckoId': 'monox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SIFU',
            'name': 'SIFU',
            'address': '0x29127fE04ffa4c32AcAC0fFe17280ABD74eAC313',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24692/thumb/token_%283%29.png?1648620836',
            'coingeckoId': 'sifu-vision',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PURE',
            'name': 'Puriever',
            'address': '0x2904b9b16652d7d0408EcCfA23A19D4A3358230f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12600/thumb/200x200_pure_logo.png?1600999358',
            'coingeckoId': 'puriever',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARV',
            'name': 'Artemis Vision',
            'address': '0x28fDA76721a8077A5dE802Ab0212849B8c38429E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23716/thumb/uUMU6_Cr_400x400.jpg?1645147096',
            'coingeckoId': 'artemis-vision',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IDLEUSDTSAFE',
            'name': 'IdleUSDT  Risk Adjusted ',
            'address': '0x28fAc5334C9f7262b3A3Fe707e250E01053e07b5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11931/thumb/idleusdt-safe.png?1596263270',
            'coingeckoId': 'idle-usdt-risk-adjusted',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XBP',
            'name': 'BlitzPick',
            'address': '0x28dee01D53FED0Edf5f6E310BF8Ef9311513Ae40',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x28dee01d53fed0edf5f6e310bf8ef9311513ae40.png',
            'coingeckoId': 'blitzpredict',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'YFL',
            'name': 'YF Link',
            'address': '0x28cb7e841ee97947a86B06fA4090C8451f64c0be',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x28cb7e841ee97947a86b06fa4090c8451f64c0be.png',
            'coingeckoId': 'yflink',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'CHOPPER',
            'name': 'Chopper Inu',
            'address': '0x28c5805B64d163588A909012a628b5a03c1041f9',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x28c5805b64d163588a909012a628b5a03c1041f9.png',
            'coingeckoId': 'chopper-inu',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'NPX',
            'name': 'Napoleon X',
            'address': '0x28b5E12CcE51f15594B0b91d5b5AdaA70F684a02',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1471/thumb/napoleon-x.jpg?1547035570',
            'coingeckoId': 'napoleon-x',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ARGO',
            'name': 'ArGoApp',
            'address': '0x28Cca76f6e8eC81e4550ecd761f899110b060E97',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15477/thumb/Group_118.png?1647877401',
            'coingeckoId': 'argo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAG',
            'name': 'BondAppetit Governance',
            'address': '0x28A06c02287e657ec3F8e151A13C36A1D43814b0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x28a06c02287e657ec3f8e151a13c36a1d43814b0.png',
            'coingeckoId': 'bondappetit-gov-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'PICK',
            'name': 'PICK',
            'address': '0x287609A15A683640A5bbC4d93D4D5f4Ed6bAD3A0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12243/thumb/nZlFKAmk_400x400.jpg?1598444299',
            'coingeckoId': 'pick',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KWIK',
            'name': 'Kwikswap',
            'address': '0x286C0936c7Eaf6651099Ab5DAb9ee5A6cB5d229D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://static.debank.com/image/eth_token/logo_url/0x286c0936c7eaf6651099ab5dab9ee5a6cb5d229d/9c928678a00199f4283f3278142252c0.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WABI',
            'name': 'Wabi',
            'address': '0x286BDA1413a2Df81731D4930ce2F862a35A609fE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1338/thumb/Tael.png?1547035364',
            'coingeckoId': 'wabi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SST',
            'name': 'SIMBA Storage',
            'address': '0x2863916C6ebDBBf0c6f02F87b7eB478509299868',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11908/thumb/sst.png?1660381253',
            'coingeckoId': 'simba-storage-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DAOVC',
            'name': 'DAOvc',
            'address': '0x284b59cf2539544559C6EfA11E2795e06D535345',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18259/thumb/daovc.PNG?1631156207',
            'coingeckoId': 'daovc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BNBHEDGE',
            'name': '1X Short BNB',
            'address': '0x2840aD41cf25Ad58303Ba24C416E79dCe4161b4F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12062/thumb/683JEXMN_400x400.png?1596706881',
            'coingeckoId': '1x-short-bnb-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PROGE',
            'name': 'Protector Roge',
            'address': '0x282D0AD1FA03DFBDB88243B958e77349c73737D1',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16993/thumb/Proge_200x200.png?1668677143',
            'coingeckoId': 'protector-roge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QQQ',
            'name': 'Poseidon Network',
            'address': '0x2822f6D1B2f41F93f33d937bc7d84A8Dfa4f4C21',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8442/thumb/LNBLzKy2_400x400.jpg?1558562627',
            'coingeckoId': 'qqq-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-19020-ROSEM',
            'name': 'RealT   19020 Rosemont Ave  Detroit  MI',
            'address': '0x280e9ed3B20c580a2f4219657bB9332D80Bc01f1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16705/thumb/19020-Rosemont-hero-1.jpeg?1624644549',
            'coingeckoId': '19020-rosemont',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WAND',
            'name': 'WandX',
            'address': '0x27f610BF36ecA0939093343ac28b1534a721DBB4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x27f610bf36eca0939093343ac28b1534a721dbb4.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'GBPU',
            'name': 'Upper Pound',
            'address': '0x27ed129C298c5Df130364083F491e2920E5A2f29',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12357/thumb/gbpu.png?1642929642',
            'coingeckoId': 'upper-pound',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XRPBULL',
            'name': '3X Long XRP Token',
            'address': '0x27c1bA4F85b8dC1c150157816623A6Ce80b7F187',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10211/thumb/683JEXMN_400x400_%281%29.png?1576535641',
            'coingeckoId': '3x-long-xrp-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LEASH',
            'name': 'Doge Killer',
            'address': '0x27C70Cd1946795B66be9d954418546998b546634',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x27c70cd1946795b66be9d954418546998b546634.png',
            'coingeckoId': 'leash',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'VALX',
            'name': 'Validator',
            'address': '0x27C4af9A860c4caDc358005F8b48140b2E434A7B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://etherscan.io/token/images/validator_32.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'ST-YCRV',
            'name': 'Staked Yearn CRV Vault',
            'address': '0x27B5739e22ad9033bcBf192059122d163b60349D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27623/thumb/styearncrv_32.png?1664885292',
            'coingeckoId': 'staked-yearn-crv-vault',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AWRT',
            'name': 'Active World Rewards',
            'address': '0x27Ad8e47c48063b209fa5CA14c8c46eCe49B82d2',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24800/thumb/awrt.png?1648982991',
            'coingeckoId': 'active-world-rewards-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BIFI',
            'name': 'BiFi',
            'address': '0x2791BfD60D232150Bff86b39B7146c0eaAA2BA81',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x2791bfd60d232150bff86b39b7146c0eaaa2ba81.png',
            'coingeckoId': 'bifi',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'NKPL',
            'name': 'Nukplan',
            'address': '0x2786F9C443F323f590dE1881d7215DB305aB846e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28182/thumb/logo_200x200.png?1668255855',
            'coingeckoId': 'nukplan',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EL',
            'name': 'ELYSIA',
            'address': '0x2781246fe707bB15CeE3e5ea354e2154a2877B16',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10887/thumb/CeyRVXPY_400x400.jpg?1585559128',
            'coingeckoId': 'elysia',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LGOLD',
            'name': 'Lyfe Gold',
            'address': '0x27778E14Ce36d3B85e1efFeB43816a17bBB7088A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14938/thumb/LGOLD-Logo-200.png?1619082558',
            'coingeckoId': 'lyfe-gold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALEPH',
            'name': 'Aleph im',
            'address': '0x27702a26126e0B3702af63Ee09aC4d1A084EF628',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x27702a26126e0b3702af63ee09ac4d1a084ef628.png',
            'coingeckoId': 'aleph',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'MONA',
            'name': 'Monavale',
            'address': '0x275f5Ad03be0Fa221B4C6649B8AeE09a42D9412A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x275f5ad03be0fa221b4c6649b8aee09a42d9412a.png',
            'coingeckoId': 'monavale',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'IFT',
            'name': 'IFT',
            'address': '0x2731d151CBDf84A8A4C6d9D0BaE74012Db51E428',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14049/thumb/ift_200x200.jpg?1614046057',
            'coingeckoId': 'iftoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNI',
            'name': 'UNICORN',
            'address': '0x2730d6FdC86C95a74253BefFaA8306B40feDecbb',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9383/thumb/54231438.png?1566777314',
            'coingeckoId': 'unicorn-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ABAL',
            'name': 'Aave BAL',
            'address': '0x272F97b7a56a387aE942350bBC7Df5700f8a4576',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17242/thumb/aBAL_2x.png?1626940749',
            'coingeckoId': 'aave-bal',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SEFA',
            'name': 'Mesefa',
            'address': '0x27201232579491Ce9b116Ac6F37D354Cc723A2f3',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10977/thumb/Sefa_Pool_Logo_Bleu.png?1636769260',
            'coingeckoId': 'mesefa',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AST',
            'name': 'AirSwap',
            'address': '0x27054b13b1B798B345b591a4d22e6562d47eA75a',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x27054b13b1b798b345b591a4d22e6562d47ea75a.png',
            'coingeckoId': 'airswap',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'BORA',
            'name': 'BORA',
            'address': '0x26fb86579e371c7AEdc461b2DdEF0A8628c93d3B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7646/thumb/mqFw8hxm_400x400.jpeg?1656657343',
            'coingeckoId': 'bora',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHIHUA',
            'name': 'Chihua',
            'address': '0x26fF6D16549A00BA8b36ce3159b5277E6e798d18',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15655/thumb/chihua_token_icon_200x200.png?1622129847',
            'coingeckoId': 'chihua-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AMATICC',
            'name': 'Ankr Reward Earning MATIC',
            'address': '0x26dcFbFa8Bc267b250432c01C982Eaf81cC5480C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1653739570902_2935634424552658.png',
            'coingeckoId': 'ankr-reward-earning-matic',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'mbBASED',
            'name': 'Moonbase',
            'address': '0x26cF82e4aE43D31eA51e72B663d26e26a75AF729',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/mbbased.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'CELL',
            'name': 'Cellframe',
            'address': '0x26c8AFBBFE1EBaca03C2bB082E69D0476Bffe099',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x26c8afbbfe1ebaca03c2bb082e69d0476bffe099.png',
            'coingeckoId': 'cellframe',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'CORX',
            'name': 'CorionX',
            'address': '0x26a604DFFE3ddaB3BEE816097F81d3C4a2A4CF97',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13129/thumb/x_log.png?1605515405',
            'coingeckoId': 'corionx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'sSPELL',
            'name': 'Staked Spell Tokens',
            'address': '0x26FA3fFFB6EfE8c1E69103aCb4044C26B9A106a9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'yUSDCv3',
            'name': 'iearn USDC v3',
            'address': '0x26EA744E5B887E5205727f55dFBE8685e3b21951',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x26ea744e5b887e5205727f55dfbe8685e3b21951.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'APE',
            'name': 'APE Punk',
            'address': '0x26EA1f595f6567b7050fBbA24F6A66e19Db4D560',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14510/thumb/ape-punk-shards.png?1616596505',
            'coingeckoId': 'ape-punk',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DGVC',
            'name': 'DegenVC',
            'address': '0x26E43759551333e57F073bb0772F50329A957b30',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12208/thumb/degen_vc_logo.png?1598186601',
            'coingeckoId': 'degenvc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KT',
            'name': 'Kuai',
            'address': '0x26DDF6CabADcBF4F013841BD8d914830BeB0d984',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5314/thumb/kuai-token.png?1547040868',
            'coingeckoId': 'kuaitoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CBC',
            'name': 'CBC network',
            'address': '0x26DB5439F651CAF491A87d48799dA81F191bDB6b',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2477/thumb/Icon_for_White_Bg_75x75.png?1637155730',
            'coingeckoId': 'cashbet-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFUP',
            'name': 'Natural Farm Union Protocol',
            'address': '0x26CBC7008cD879f4B63B69a915378f2D9b17bBF0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21052/thumb/logo_-_2021-11-30T163428.088.png?1638261294',
            'coingeckoId': 'natural-farm-union-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CYL',
            'name': 'Crystal CYL',
            'address': '0x26CB3641aaA43911f1D4cB2ce544eb652AAc7c47',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6419/thumb/crystal-token.png?1547042585',
            'coingeckoId': 'crystal-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RATIODOOM',
            'name': 'ETHBTC 1x Short',
            'address': '0x26AB7c9ec873633D03C8f3a828F657878245A2dd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x26AB7c9ec873633D03C8f3a828F657878245A2dd/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'DBOMB',
            'name': 'Defi Bomb',
            'address': '0x26A9c0c6F28ae2e69270BC39F13dB87A27Db4CE5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27946/thumb/IMG_8762E9D638E0-1.jpeg?1666606436',
            'coingeckoId': 'defi-bomb',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PUNK',
            'name': 'Punk Vault  NFTX ',
            'address': '0x269616D549D7e8Eaa82DFb17028d0B212D11232A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17018/thumb/Punk.png?1626086346',
            'coingeckoId': 'punk-vault-nftx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSOV',
            'name': 'BitcoinSoV',
            'address': '0x26946adA5eCb57f3A1F91605050Ce45c482C9Eb1',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9205/thumb/bsov.png?1578020375',
            'coingeckoId': 'bitcoinsov',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PIPT',
            'name': 'Power Index Pool',
            'address': '0x26607aC599266b21d13c7aCF7942c7701a8b699c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x26607ac599266b21d13c7acf7942c7701a8b699c.png',
            'coingeckoId': 'power-index-pool-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'NGL',
            'name': 'Gold Fever Native Gold',
            'address': '0x2653891204F463fb2a2F4f412564b19e955166aE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20633/thumb/2ypNydrG_400x400.jpg?1637338673',
            'coingeckoId': 'gold-fever-native-gold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOTR',
            'name': 'Cydotori',
            'address': '0x26524e35E44e265eB181C7Ba6eC084983181aaE3',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28109/thumb/dotr.png?1667545446',
            'coingeckoId': 'cydotori',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FUTURA',
            'name': 'Futura',
            'address': '0x26487eC318b0138dcf6CD1f43Ee68927756dbc1d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26802/thumb/62f22c02295fa13e56e5e7c9_futura-logo.png?1660205551',
            'coingeckoId': 'futura',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CSM',
            'name': 'Crust Shadow',
            'address': '0x2620638EDA99F9e7E902Ea24a285456EE9438861',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16037/thumb/2_mnCYZfHmLg2bFrjM3vKtPw.png?1622680217',
            'coingeckoId': 'crust-storage-market',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SKYRIM',
            'name': 'Skyrim Finance',
            'address': '0x2610F0bFC21EF389fe4D03CFB7De9ac1E6C99D6E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x2610F0bFC21EF389fe4D03CFB7De9ac1E6C99D6E/logo.png',
            'coingeckoId': 'skyrim-finance',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'MOONS',
            'name': 'MoonTools',
            'address': '0x260e63d91fCCC499606BAe3FE945c4ed1CF56A56',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x260e63d91fccc499606bae3fe945c4ed1cf56a56.png',
            'coingeckoId': 'moontools',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SHINURI',
            'name': 'Shinuri',
            'address': '0x260D5F9688353af138d35ece6F0Cd9C80Ef982dd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26640/thumb/CG.png?1659347370',
            'coingeckoId': 'shinuri',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PKT',
            'name': 'PlayKey',
            'address': '0x2604FA406Be957E542BEb89E6754fCdE6815e83f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2116/thumb/playkey.png?1548331394',
            'coingeckoId': 'playkey',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VADER',
            'name': 'Vader Protocol',
            'address': '0x2602278EE1882889B946eb11DC0E810075650983',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x2602278ee1882889b946eb11dc0e810075650983.png',
            'coingeckoId': 'vader-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'YGG',
            'name': 'Yield Guild Games',
            'address': '0x25f8087EAD173b73D6e8B84329989A8eEA16CF73',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17358/small/le1nzlO6_400x400.jpg?1632465691',
            'coingeckoId': 'yield-guild-games',
            'listedIn': [
                'coingecko',
                'uniswap',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'DOGETAMA',
            'name': 'Dogetama',
            'address': '0x25e44e0250f0492C188918f2d726B4861263ef5b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23602/thumb/dogetama_logo.png?1644735646',
            'coingeckoId': 'dogetama',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BID',
            'name': 'Bidao',
            'address': '0x25e1474170c4c0aA64fa98123bdc8dB49D7802fa',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x25e1474170c4c0aa64fa98123bdc8db49d7802fa.png',
            'coingeckoId': 'bidao',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'COOOM',
            'name': 'Incooom Genesis',
            'address': '0x25b539B1C179fbe2af92ABDFDc7E152Cbc97aDdC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18988/thumb/cooom.png?1634112193',
            'coingeckoId': 'incooom-genesis',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AXL',
            'name': 'AXL INU',
            'address': '0x25b24B3c47918b7962B3e49C4F468367F73CC0E0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22191/thumb/Original_Logo-01.png?1641188792',
            'coingeckoId': 'axl-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WVG0',
            'name': 'Wrapped Virgin Gen 0 CryptoKittties',
            'address': '0x25C7b64A93Eb1261E130eC21a3e9918CaA38b611',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12875/thumb/wvg0.png?1603211534',
            'coingeckoId': 'wrapped-virgin-gen-0-cryptokitties',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LML',
            'name': 'Link Machine Learning',
            'address': '0x25B6325f5BB1c1E03cfbC3e53F470E1F1ca022E3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7238/thumb/LML.png?1626237501',
            'coingeckoId': 'link-machine-learning',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'MFC',
            'name': 'Multi Farm Capital',
            'address': '0x25B3E27f03FD51163818b111690066e1b088F800',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20694/thumb/mfclogo.png?1637564399',
            'coingeckoId': 'multi-farm-capital',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLOKIPUP',
            'name': 'Floki Pup',
            'address': '0x259fba5AE8b626483e1E589E8D60A5413a2157d2',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19066/thumb/gkmusmC.png?1634268953',
            'coingeckoId': 'floki-pup',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EST',
            'name': 'Erica Social Token',
            'address': '0x259AB9b9EAB62b0fD98729B97BE121073D5B3479',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethereum-optimism.github.io/data/EST/logo.png',
            'coingeckoId': 'erica-social-token',
            'listedIn': [
                'coingecko',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'FTRB',
            'name': 'Faith Tribe',
            'address': '0x2596825a84888E8f24b747DF29e11b5dd03C81D7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23939/thumb/ym1Hf4x2_400x400.jpg?1645766940',
            'coingeckoId': 'faith-tribe',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IBTCV',
            'name': 'Inverse Bitcoin Volatility Index Token',
            'address': '0x2590F1fD14Ef8Bb0A46C7A889c4CBc146510f9C3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x2590f1fd14ef8bb0a46c7a889c4cbc146510f9c3.png',
            'coingeckoId': 'inverse-bitcoin-volatility-index-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SOLV',
            'name': 'Solv Protocol',
            'address': '0x256F2d67e52fE834726D2DDCD8413654F5Eb8b53',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27987/thumb/Solv-Logo5.png?1666863912',
            'coingeckoId': 'solv-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WALV',
            'name': 'Alvey Chain',
            'address': '0x256D1fCE1b1221e8398f65F9B36033CE50B2D497',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26964/thumb/logo200x200.png?1661079637',
            'coingeckoId': 'alvey-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASM',
            'name': 'Assemble Protocol',
            'address': '0x2565ae0385659badCada1031DB704442E1b69982',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11605/thumb/gpvrlkSq_400x400_%281%29.jpg?1591775789',
            'coingeckoId': 'assemble-protocol',
            'listedIn': [
                'coingecko',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'RDN',
            'name': 'Raiden Network',
            'address': '0x255Aa6DF07540Cb5d3d297f0D0D4D84cb52bc8e6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x255aa6df07540cb5d3d297f0d0d4d84cb52bc8e6.png',
            'coingeckoId': 'raiden-network',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'WAIT',
            'name': 'Hourglass',
            'address': '0x2559813BBb508C4C79e9CCCE4703Bcb1f149EDd7',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27516/thumb/Hourglass-Logo.png?1664353478',
            'coingeckoId': 'hourglass',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SIMBA',
            'name': 'Simba',
            'address': '0x254417f7B56328a48f554b173dCa7Bdda7A2a0d2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16060/thumb/200x200_%2819%29.png?1622710548',
            'coingeckoId': 'simba-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SBREE',
            'name': 'Synthetic CBDAO',
            'address': '0x25377ddb16c79C93B0CBf46809C8dE8765f03FCd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x25377ddb16c79c93b0cbf46809c8de8765f03fcd.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'HATI',
            'name': 'Hati',
            'address': '0x251457b7c5d85251Ca1aB384361c821330bE2520',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25736/thumb/hati-logo.png?1653461606',
            'coingeckoId': 'hati',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AMA',
            'name': 'Amaten',
            'address': '0x2509eE05b8df07EC75046E24BBf1CfCdB8b2A183',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11010/thumb/0_%283%29.png?1587104344',
            'coingeckoId': 'amaten',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'K0DE',
            'name': 'The  k0de',
            'address': '0x250050ACD30e382A39b00f2D172058160050A9E4',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26788/thumb/logo200x200.png?1660103280',
            'coingeckoId': 'the-k0de',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POM',
            'name': 'Pomeranian ETH',
            'address': '0x24ffe459F51ea20C5d8ad49843529fc33654e7E4',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28246/thumb/Pom.jpeg?1668591756',
            'coingeckoId': 'pomeranian-eth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GAT',
            'name': 'Global AEX',
            'address': '0x24efE6b87bF1Bfe9ea2cCB5A9D0a959C7172b364',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7008/thumb/a3b65903bffb6e47b225bdd70c635701.png?1601426171',
            'coingeckoId': 'global-aex-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLUESPARROW',
            'name': 'BlueSparrow',
            'address': '0x24cCeDEBF841544C9e6a62Af4E8c2fA6e5a46FdE',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26085/thumb/bluesparrow.?1655766000',
            'coingeckoId': 'bluesparrow',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ESS',
            'name': 'Empty Set Share',
            'address': '0x24aE124c4CC33D6791F8E8B63520ed7107ac8b3e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17481/thumb/photo_2021-08-03_03-26-29.png?1627953584',
            'coingeckoId': 'empty-set-share',
            'listedIn': [
                'coingecko',
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'STAR',
            'name': 'Star',
            'address': '0x24Eb24647786135352cbB14f888B23277F0014e7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x24Eb24647786135352cbB14f888B23277F0014e7/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'MOOV',
            'name': 'dotmoovs',
            'address': '0x24EC2Ca132abf8F6f8a6E24A1B97943e31f256a7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15817/thumb/dotmoovs-symbol-gradient.png?1635332626',
            'coingeckoId': 'dotmoovs',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'TKING',
            'name': 'Tiger King Coin',
            'address': '0x24E89bDf2f65326b94E36978A7EDeAc63623DAFA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x24e89bdf2f65326b94e36978a7edeac63623dafa.png',
            'coingeckoId': 'tiger-king',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'KTLYO',
            'name': 'Katalyo',
            'address': '0x24E3794605C84E580EEA4972738D633E8a7127c8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13347/thumb/katalyo_logo_aqua_256.png?1607762430',
            'coingeckoId': 'katalyo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XD',
            'name': 'Data Transaction XD',
            'address': '0x24DCc881E7Dd730546834452F21872D5cb4b5293',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3460/thumb/xd_01.png?1560142295',
            'coingeckoId': 'scroll-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SGTV2',
            'name': 'SharedStake Governance v2',
            'address': '0x24C19F7101c1731b85F1127EaA0407732E36EcDD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13948/thumb/sgt-png.png?1615189100',
            'coingeckoId': 'sharedstake-governance-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CVT',
            'name': 'concertVR',
            'address': '0x24B47299E756aF0571F512232A3629E0DaBb52Ed',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2914/thumb/cvt.png?1547037094',
            'coingeckoId': 'concertvr',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MEDI',
            'name': 'Medi',
            'address': '0x24B20dA7A2Fa0D1D5aFcD693e1c8AFff20507eFd',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23037/thumb/Medi_200_x_200.PNG?1643096227',
            'coingeckoId': 'medi-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BANK',
            'name': 'Float Protocol',
            'address': '0x24A6A37576377F63f194Caa5F518a60f45b42921',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x24a6a37576377f63f194caa5f518a60f45b42921.png',
            'coingeckoId': 'float-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'UFO',
            'name': 'UFO Gaming',
            'address': '0x249e38Ea4102D0cf8264d3701f1a0E39C4f2DC3B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x249e38ea4102d0cf8264d3701f1a0e39c4f2dc3b.png',
            'coingeckoId': 'ufo-gaming',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'SHIELD',
            'name': 'Shield Protocol Token',
            'address': '0x24861414c8845b8115397302e9DcfAaB3f239826',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24014/thumb/Shield_Universal_Avatar_black.png?1646030958',
            'coingeckoId': 'shield-protocol-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OSC',
            'name': 'Oasis City',
            'address': '0x24700A297960E8477Ce3CA6C58b70a7Af3410398',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7038/thumb/wJepb5N.png?1550038108',
            'coingeckoId': 'oasis-city',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UKG',
            'name': 'Unikoin Gold',
            'address': '0x24692791Bc444c5Cd0b81e3CBCaba4b04Acd1F3B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1120/thumb/unikoin-gold.png?1548759441',
            'coingeckoId': 'unikoin-gold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHI',
            'name': 'Shina Inu',
            'address': '0x243cACb4D5fF6814AD668C3e225246efA886AD5a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25208/thumb/coingecko-shina-purple-bg.png?1655266989',
            'coingeckoId': 'shina-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KWATT',
            'name': '4New',
            'address': '0x241bA672574A78a3A604CDd0a94429A73a84a324',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4975/thumb/4new.png?1547040398',
            'coingeckoId': '4new',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WTF',
            'name': 'Waterfall Governance Token',
            'address': '0x23da57c5f99CA7DEA8d38B15419c7003ee85D76c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'MAS',
            'name': 'Midas Protocol',
            'address': '0x23Ccc43365D9dD3882eab88F43d515208f832430',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4287/thumb/Bestand_000.png?1607560518',
            'coingeckoId': 'midas-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOCKS',
            'name': 'Unisocks',
            'address': '0x23B608675a2B2fB1890d3ABBd85c5775c51691d5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x23b608675a2b2fb1890d3abbd85c5775c51691d5.png',
            'coingeckoId': 'unisocks',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'JAM',
            'name': 'Geojam',
            'address': '0x23894DC9da6c94ECb439911cAF7d337746575A72',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24648/thumb/ey40AzBN_400x400.jpg?1648507272',
            'coingeckoId': 'geojam',
            'listedIn': [
                'coingecko',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ANGEL',
            'name': 'Angel Inu',
            'address': '0x2373c5DC96238A64CE4062e74000fd3dAcFD3bf7',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21024/thumb/NMqevqZ.png?1638193979',
            'coingeckoId': 'angel-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JPYC',
            'name': 'JPY Coin v1',
            'address': '0x2370f9d504c7a6E775bf6E14B3F12846b594cD53',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://jpyc.jp/static/media/jpyc.0d1e5d3f.png',
            'coingeckoId': 'jpyc',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'SHUSKY',
            'name': 'Siberian Husky',
            'address': '0x236d53148F83706C3d670064809577385f923a75',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16711/thumb/shusky.png?1624782891',
            'coingeckoId': 'siberian-husky',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-9336-PATTON',
            'name': 'RealT   9336 Patton St  Detroit  MI 482',
            'address': '0x23684569c0636C9aEa246551879d457D0a0E6F58',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16701/thumb/9336-Patton-HERO-2.jpeg?1624642655',
            'coingeckoId': '9336-patton',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AAMMUNIWBTCUSDC',
            'name': 'Aave AMM UniWBTCUSDC',
            'address': '0x2365a4890eD8965E564B7E2D27C38Ba67Fec4C6F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17255/thumb/aAmmUniWBTCUSDC.png?1626956709',
            'coingeckoId': 'aave-amm-uniwbtcusdc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NMOON',
            'name': 'Moonbirds NFT Index by MEXC',
            'address': '0x23581767a106ae21c074b2276D25e5C3e136a68b',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25209/thumb/8nZJL9eQ_400x400_%281%29.jpg?1650788143',
            'coingeckoId': 'moonbirds-nft-index-by-mexc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SCAN',
            'name': 'CoinScan',
            'address': '0x234D51eE02be808A0160b19b689660Fb7BFA871b',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24254/thumb/scan.png?1647155535',
            'coingeckoId': 'coinscan',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PROT',
            'name': 'ProStarter',
            'address': '0x2341Dd0A96a0Dab62Aa1efB93D59FF7F3bDB8932',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15259/thumb/rpot.PNG?1620269970',
            'coingeckoId': 'prostarter-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TMATIC',
            'name': 'tMATIC',
            'address': '0x2336C10a1D3100343fA9911a2c57b77c333599A3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26971/thumb/tenderMATIC.png?1661152567',
            'coingeckoId': 'tmatic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FDZ',
            'name': 'Friendz',
            'address': '0x23352036E911A22Cfc692B5E2E196692658ADED9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3685/thumb/download_%2812%29.png?1547038682',
            'coingeckoId': 'friendz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHEESHA',
            'name': 'Sheesha Finance  ERC20 ',
            'address': '0x232FB065D9d24c34708eeDbF03724f2e95ABE768',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x232fb065d9d24c34708eedbf03724f2e95abe768.png',
            'coingeckoId': 'sheesha-finance-erc20',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SHIBGEKI',
            'name': 'Shibgeki',
            'address': '0x2327590Bb709F1505b64d1E4573D7c0DCEa4350c',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24032/thumb/Untitled-design-1.png?1646116490',
            'coingeckoId': 'shibgeki',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HELIX',
            'name': 'Helix',
            'address': '0x231CC03E6d8b7368eC2aBfAfb5f73D216c8af980',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WAL',
            'name': 'The Wasted Lands',
            'address': '0x22c5543d1a35178cB03b33f929A959145E538532',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24273/thumb/Coin_WAL_%281%29.png?1647178350',
            'coingeckoId': 'the-wasted-lands',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CVC',
            'name': 'CoinViewCap',
            'address': '0x22b59a7387F7D25fe2b1c692Ee825e1802E6E3d5',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25044/thumb/cvc_trans.png?1651206377',
            'coingeckoId': 'coinviewcap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLOCKS',
            'name': 'JoinBlocks',
            'address': '0x22BB7D3f4DCF074b979021Ca4E214361e14C6B26',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26320/thumb/BLOCKS-DISCORD.png?1657245114',
            'coingeckoId': 'joinblocks',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COC',
            'name': 'cocktailbar finance',
            'address': '0x22B6C31c2bEB8f2d0d5373146Eed41Ab9eDe3caf',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13121/thumb/coc.png?1647079316',
            'coingeckoId': 'cocktailbar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XOT',
            'name': 'Okuru',
            'address': '0x22B48E1f20043D1Db5F2a11CbF1d520A4F20b198',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25473/thumb/xot.png?1652005616',
            'coingeckoId': 'okuru',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'XERM',
            'name': 'Xerium',
            'address': '0x22Ad96aCF613539d7900b5Ec6e0E65dBCC668d2A',
            'decimals': 7,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26284/thumb/Xerium_Logo_200x200_PNG.png?1657077128',
            'coingeckoId': 'xerium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SRT',
            'name': 'Smart Reward Token',
            'address': '0x22987407FD1fC5A971e3FDA3B3e74C88666cDa91',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25035/thumb/me-44EyS_400x400.jpg?1649852504',
            'coingeckoId': 'smart-reward-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GSC',
            'name': 'Global Social Chain',
            'address': '0x228ba514309FFDF03A81a205a6D040E429d6E80C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4304/thumb/global-social-chain.png?1547742843',
            'coingeckoId': 'global-social-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MILADY',
            'name': 'Milady Vault  NFTX ',
            'address': '0x227c7DF69D3ed1ae7574A1a7685fDEd90292EB48',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26540/thumb/256x256.png?1658730893',
            'coingeckoId': 'milady-vault-nftx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNN',
            'name': 'UNION Protocol Governance',
            'address': '0x226f7b842E0F0120b7E194D05432b3fd14773a9D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x226f7b842e0f0120b7e194d05432b3fd14773a9d.png',
            'coingeckoId': 'union-protocol-governance-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'PRO',
            'name': 'Propy',
            'address': '0x226bb599a12C826476e3A771454697EA52E9E220',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/869/thumb/propy.png?1548332100',
            'coingeckoId': 'propy',
            'listedIn': [
                'coingecko',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'WBTC',
            'name': 'Wrapped Bitcoin',
            'address': '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x2260fac5e5542a773aa44fbcfedf7c193bc2c599.png',
            'coingeckoId': 'wrapped-bitcoin',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'optimism',
                'arbitrum_bridge',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'DRGNBEAR',
            'name': '3X Short Dragon Index Token',
            'address': '0x223FB5c14C00Cfb70cF56BB63c2EeF2d74fE1A78',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10256/thumb/683JEXMN_400x400_%281%29.png?1576677145',
            'coingeckoId': '3x-short-dragon-index-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CLV',
            'name': 'Clover',
            'address': '0x22222C03318440305aC3e8a7820563d6A9FD777F',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12888/thumb/clover_logo.png?1603274615',
            'coingeckoId': 'clover',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QMALL',
            'name': 'Qmall',
            'address': '0x2217e5921B7edfB4BB193a6228459974010D2198',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23630/thumb/tjVN6bQ5_400x400.jpg?1644891636',
            'coingeckoId': 'qmall',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REP',
            'name': 'Augur',
            'address': '0x221657776846890989a759BA2973e427DfF5C9bB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x221657776846890989a759ba2973e427dff5c9bb.png',
            'coingeckoId': 'augur',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'CWT',
            'name': 'Crown CWT',
            'address': '0x22143217662334F073652Da5E2F1e0AE7349240c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24201/thumb/Crown_Token_JPG_%282%29.jpg?1646838419',
            'coingeckoId': 'crown-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DIS',
            'name': 'TosDis',
            'address': '0x220B71671b649c03714dA9c621285943f3cbcDC6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x220b71671b649c03714da9c621285943f3cbcdc6.png',
            'coingeckoId': 'tosdis',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SMT',
            'name': 'SmartMesh',
            'address': '0x21f15966E07a10554C364b988e91DaB01D32794A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1346/thumb/smartmesh.png?1548609531',
            'coingeckoId': 'smartmesh',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YLC',
            'name': 'YOLOCash',
            'address': '0x21d5678A62DFe63a47062469Ebb2fAc2817D8832',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4368/thumb/received_2320303067998561.png?1547039717',
            'coingeckoId': 'yolo-cash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GROK',
            'name': 'Grok',
            'address': '0x21d486F6B7EB7B556978c5A0C96c79A08Eb841De',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24574/thumb/ZtRMy9Z7_400x400.png?1648197386',
            'coingeckoId': 'grok',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JEM',
            'name': 'Jem',
            'address': '0x21cf09BC065082478Dcc9ccB5fd215A978Dc8d86',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13044/thumb/itchiro-defi.png?1605892179',
            'coingeckoId': 'jem',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTSLA',
            'name': 'Mirrored Tesla',
            'address': '0x21cA39943E91d704678F5D00b6616650F066fD63',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/mtsla.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'SQUID',
            'name': 'Squid',
            'address': '0x21ad647b8F4Fe333212e735bfC1F36B4941E6Ad2',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x21ad647b8F4Fe333212e735bfC1F36B4941E6Ad2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'BANANA',
            'name': 'Banana Finance',
            'address': '0x21F54372c07B930B79c5c2d9bb0EAACa86c3b298',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13419/thumb/bananacoin.png?1608446599',
            'coingeckoId': 'banana-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OVR',
            'name': 'Ovr',
            'address': '0x21BfBDa47A0B4B5b1248c767Ee49F7caA9B23697',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x21bfbda47a0b4b5b1248c767ee49f7caa9b23697.png',
            'coingeckoId': 'ovr',
            'listedIn': [
                'coingecko',
                '1inch',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'DON',
            'name': 'Don key',
            'address': '0x217ddEad61a42369A266F1Fb754EB5d3EBadc88a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15482/thumb/donkey_logo.jpg?1621012824',
            'coingeckoId': 'don-key',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PFAN',
            'name': 'PowerFan',
            'address': '0x217BED0d3A967d063eb287445A1eccac5C2E09BC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25899/thumb/white_logo.png?1654500723',
            'coingeckoId': 'powerfan',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LION',
            'name': 'CoinLion',
            'address': '0x2167FB82309CF76513E83B25123f8b0559d6b48f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1917/thumb/Coinlion_Brandmark_Color.png?1620706193',
            'coingeckoId': 'coinlion',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LBL',
            'name': 'LABEL Foundation',
            'address': '0x2162f572B25f7358db9376AB58a947a4e45CeDE1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19202/thumb/tele_profile_%EB%8C%80%EC%A7%80_1_-_%282%29.png?1634680838',
            'coingeckoId': 'label-foundation',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AUT',
            'name': 'Autentica',
            'address': '0x214fEebda3AE6Bb842Bd17D91A0f346eeBcD7898',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27154/thumb/coingecko_200x200.png?1662205740',
            'coingeckoId': 'autentica',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GENE',
            'name': 'GenomesDAO',
            'address': '0x21413c119b0C11C5d96aE1bD328917bC5C8ED67E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x21413c119b0C11C5d96aE1bD328917bC5C8ED67E/logo.png',
            'coingeckoId': 'genomesdao',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'DSS',
            'name': 'Defi Shopping Stake',
            'address': '0x213C53C96A01a89E6Dcc5683cF16473203E17513',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12602/thumb/DSS.png?1601002204',
            'coingeckoId': 'defi-shopping-stake',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FORM',
            'name': 'Formation FI',
            'address': '0x21381e026Ad6d8266244f2A583b35F9E4413FA2a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16145/thumb/FORM.png?1623119824',
            'coingeckoId': 'formation-fi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-19200-STRAS',
            'name': 'RealT   19200 Strasburg St  Detroit  MI',
            'address': '0x211618Fa0934910666f2C2731101F5A3aC013fD8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16620/thumb/19200-Strasburg-hero-1.jpg?1624550918',
            'coingeckoId': '19200-strasburg',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FWT',
            'name': 'Freeway',
            'address': '0x20e7125677311Fca903A8897042b9983f22Ea295',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13012/thumb/S5h7MHR.png?1652862323',
            'coingeckoId': 'freeway',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PLY',
            'name': 'PlayNity',
            'address': '0x20d60c6eb195868d4643f2c9B0809e4De6Cc003d',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21479/thumb/ply.png?1639651574',
            'coingeckoId': 'playnity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOONEY',
            'name': 'Moon DAO',
            'address': '0x20d4DB1946859E2Adb0e5ACC2eac58047aD41395',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22905/thumb/cVWTHdA.png?1645690722',
            'coingeckoId': 'mooney',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ESG',
            'name': 'ESG',
            'address': '0x20cD2E7Ec8F5d8b337fe46a4F565Ccef1561b9a9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24932/thumb/esg.png?1649397514',
            'coingeckoId': 'esg',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DFD',
            'name': 'DefiDollar DAO',
            'address': '0x20c36f062a31865bED8a5B1e512D9a1A20AA333A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x20c36f062a31865bed8a5b1e512d9a1a20aa333a.png',
            'coingeckoId': 'defidollar-dao',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'GST',
            'name': 'CoinGhost',
            'address': '0x20b2d0A368d34aDc544720C841281fA8a99f5A2E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25978/thumb/20568.png?1655112863',
            'coingeckoId': 'coinghost',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DNXC',
            'name': 'DinoX',
            'address': '0x20a8CEC5fffea65Be7122BCaB2FFe32ED4Ebf03a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17321/thumb/asset_icon_dnxc_200.png?1627292452',
            'coingeckoId': 'dinox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ISLA',
            'name': 'DefiVille Island',
            'address': '0x20a68F9e34076b2dc15ce726d7eEbB83b694702d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14471/thumb/256.png?1616384288',
            'coingeckoId': 'defiville-island',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPN',
            'name': 'Sapien',
            'address': '0x20F7A3DdF244dc9299975b4Da1C39F8D5D75f05A',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x20f7a3ddf244dc9299975b4da1c39f8d5d75f05a.png',
            'coingeckoId': 'sapien',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'NB',
            'name': 'No Bull',
            'address': '0x20Be82943e8d9c682580e11D424EC15DB95B4a24',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21366/thumb/Logo-E-2.png?1652944972',
            'coingeckoId': 'no-bull',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RETH2',
            'name': 'rETH2',
            'address': '0x20BC832ca081b91433ff6c17f85701B6e92486c5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16570/thumb/red256.png?1624495231',
            'coingeckoId': 'reth2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NSURE',
            'name': 'Nsure Network',
            'address': '0x20945cA1df56D237fD40036d47E866C7DcCD2114',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x20945ca1df56d237fd40036d47e866c7dccd2114.png',
            'coingeckoId': 'nsure-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BNA',
            'name': 'BananaTok',
            'address': '0x20910e5b5f087f6439DFcB0ddA4e27d1014Ac2b8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10289/thumb/sMCxdYBa_400x400.jpg?1577013480',
            'coingeckoId': 'bananatok',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEFY',
            'name': 'DEFY',
            'address': '0x205eD31C867bF715E4182137Af95AFE9177Cd8E7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26877/thumb/8ybr83fb0ca45cm1yvrcaclwbvcp.jpeg?1660622066',
            'coingeckoId': 'defy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZUZ',
            'name': 'ZUZ Protocol',
            'address': '0x202f1877e1DB1120Ca3e9A98C5D505e7f035C249',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14444/thumb/ZUZ_Icon2-100.png?1616115188',
            'coingeckoId': 'zuz-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZCO',
            'name': 'Zebi',
            'address': '0x2008e3057BD734e10AD13c9EAe45Ff132aBc1722',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2756/thumb/zebi.png?1548761131',
            'coingeckoId': 'zebi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CLT',
            'name': 'CoinLoan',
            'address': '0x2001f2A0Cf801EcFda622f6C28fb6E10d803D969',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1713/thumb/blue-logo-transparent-200x200.png?1595912819',
            'coingeckoId': 'coinloan',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MET',
            'name': 'Metroverse',
            'address': '0x1ffe8A8177D3C261600A8bD8080D424d64b7FBC2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23303/thumb/met.png?1644054503',
            'coingeckoId': 'metroverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REAP',
            'name': 'ReapChain',
            'address': '0x1fc5EF0337AEA85C5f9198853a6E3A579a7A6987',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13109/thumb/REAP.jpg?1605259422',
            'coingeckoId': 'reapchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CGG',
            'name': 'Chain Guardians',
            'address': '0x1fE24F25b1Cf609B9c4e7E12D802e3640dFA5e43',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x1fe24f25b1cf609b9c4e7e12d802e3640dfa5e43.png',
            'coingeckoId': 'chain-guardians',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'SPICE',
            'name': 'SPICE',
            'address': '0x1fDaB294EDA5112B7d066ED8F2E4E562D5bCc664',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13398/thumb/VUenmQt_%281%29.png?1624954218',
            'coingeckoId': 'spice-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNI',
            'name': 'Uniswap',
            'address': '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x1f9840a85d5af5bf1d1762f925bdaddc4201f984.png',
            'coingeckoId': 'uniswap',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'optimism',
                'arbitrum_bridge',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ATR',
            'name': 'Atauro',
            'address': '0x1f3CE12f7D9c589bF825CEc15642Bc37EAE9E89e',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22021/thumb/output-onlinepngtools.png?1640607268',
            'coingeckoId': 'atauro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CMS',
            'name': 'CryptoMoonShots',
            'address': '0x1f22AD99C334e6689bAe751CC225bCC38fe54F82',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18730/thumb/UEYC0ty.png?1633251229',
            'coingeckoId': 'cryptomoonshots',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VEST',
            'name': 'DAO Invest',
            'address': '0x1f19f83fC9a25F3C861260143E36c17706257986',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17901/thumb/logo-round-200.png?1629773511',
            'coingeckoId': 'dao-invest',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SLD',
            'name': 'Shield  SLD ',
            'address': '0x1ef6A7e2c966fb7C5403EFEFdE38338b1a95a084',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16170/thumb/SLD_TOKEN.png?1656907214',
            'coingeckoId': 'shield-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DMC',
            'name': 'Deploying More Capital',
            'address': '0x1eC184Ec2db325140B041F9214F98a537F80E1A7',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27564/thumb/dmc-logo.png?1664520787',
            'coingeckoId': 'deploying-more-capital',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BCDN',
            'name': 'BlockCDN',
            'address': '0x1e797Ce986C3CFF4472F7D38d5C4aba55DfEFE40',
            'decimals': 15,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1301/thumb/blockcdn.png?1547350830',
            'coingeckoId': 'blockcdn',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TUZLA',
            'name': 'Tuzlaspor Token',
            'address': '0x1e4EC900dD162EBaf6CF76cFE8A546f34D7a483d',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24812/thumb/Tuzlaspors-Token-Supply-Started-at-Bitexen-980x400.png?1649024989',
            'coingeckoId': 'tuzlaspor',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IOEN',
            'name': 'Internet of Energy Network',
            'address': '0x1e4E46b7BF03ECE908c88FF7cC4975560010893A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19095/thumb/12799.png?1634422996',
            'coingeckoId': 'internet-of-energy-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CELO',
            'name': 'Celo native',
            'address': '0x1e33dBA7cd47e79C4385Ba39442A693B910a0A8a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/celo.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'PICIPO',
            'name': 'Picipo',
            'address': '0x1e05f68B29b286FB3BbAd3c688D7e2ABda549b80',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18758/thumb/kEpQ95VG_400x400.jpg?1633350594',
            'coingeckoId': 'picipo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BKK',
            'name': 'BKEX Chain',
            'address': '0x1df7AA5551E801e280007dc0Fc0454E2D06C1A89',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7734/thumb/b8a9c996583ee19c2a98dca34b92a26.png?1646290715',
            'coingeckoId': 'bkex-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CCS',
            'name': 'CloutContracts',
            'address': '0x1da4858ad385cc377165A298CC2CE3fce0C5fD31',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19072/thumb/200.png?1634829111',
            'coingeckoId': 'cloutcontracts',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPD',
            'name': 'SPINDLE',
            'address': '0x1dEa979ae76f26071870F824088dA78979eb91C8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x1dea979ae76f26071870f824088da78979eb91c8.png',
            'coingeckoId': 'spindle',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'LEAD',
            'name': 'Lead',
            'address': '0x1dD80016e3d4ae146Ee2EBB484e8edD92dacC4ce',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x1dd80016e3d4ae146ee2ebb484e8edd92dacc4ce.png',
            'coingeckoId': 'lead-token',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'BID',
            'name': 'DeFi Bids',
            'address': '0x1dA01e84F3d4e6716F274c987Ae4bEE5DC3C8288',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12344/thumb/IMG_20200919_115022_477.png?1600739441',
            'coingeckoId': 'defi-bids',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HEDGESHIT',
            'name': '1X Short Shitcoin Index Token',
            'address': '0x1d9cd2180Fd4E9771fCA28681034D02390B14e4c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10450/thumb/683JEXMN_400x400.png?1579561773',
            'coingeckoId': '1x-short-shitcoin-index-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'START',
            'name': 'Starter xyz',
            'address': '0x1d7Ca62F6Af49ec66f6680b8606E634E55Ef22C1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14301/thumb/logo_poly_sym.png?1624893386',
            'coingeckoId': 'bscstarter',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CAN',
            'name': 'CanYa',
            'address': '0x1d462414fe14cf489c7A21CaC78509f4bF8CD7c0',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x1d462414fe14cf489c7a21cac78509f4bf8cd7c0.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'CHART',
            'name': 'ChartEx',
            'address': '0x1d37986F252d0e349522EA6C3B98Cb935495E63E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x1d37986f252d0e349522ea6c3b98cb935495e63e.png',
            'coingeckoId': 'chartex',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'POA',
            'name': 'Proof Of Apes',
            'address': '0x1d2d542e6D9d85A712deB4D1a7D96a16CE00B8cE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27896/thumb/POA.png?1666318268',
            'coingeckoId': 'proof-of-apes',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MND',
            'name': 'Mind Music',
            'address': '0x1cd2528522A17B6Be63012fB63AE81f3e3e29D97',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19104/thumb/logo-main_%282%29.png?1634506312',
            'coingeckoId': 'mind-music',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WAVES',
            'name': 'Waves',
            'address': '0x1cF4592ebfFd730c7dc92c1bdFFDfc3B9EfCf29a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/waves.jpg',
            'coingeckoId': 'waves',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'KP3R',
            'name': 'Keep3rV1',
            'address': '0x1cEB5cB57C4D4E2b2433641b95Dd330A33185A44',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x1ceb5cb57c4d4e2b2433641b95dd330a33185a44.png',
            'coingeckoId': 'keep3rv1',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'BABY',
            'name': 'Metababy',
            'address': '0x1cCF27211e8bf052f6255329ed641B4E94E80603',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27175/thumb/7psZaqh0_400x400.jpeg?1662369176',
            'coingeckoId': 'metababy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFY',
            'name': 'Non Fungible Yearn',
            'address': '0x1cBb83EbcD552D5EBf8131eF8c9CD9d9BAB342bC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x1cbb83ebcd552d5ebf8131ef8c9cd9d9bab342bc.png',
            'coingeckoId': 'non-fungible-yearn',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BKING',
            'name': 'King Arthur',
            'address': '0x1cA02DD95f3F1e33DA7F5AfE15EA866dAb07aF04',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16162/thumb/king-arthur.png?1623142524',
            'coingeckoId': 'king-arthur',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RND',
            'name': 'The RandomDAO',
            'address': '0x1c7E83f8C581a967940DBfa7984744646AE46b29',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24256/thumb/rnd.png?1647160021',
            'coingeckoId': 'the-randomdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AUSCM',
            'name': 'Auric Network',
            'address': '0x1c7BBADc81E18F7177A95eb1593e5f5f35861B10',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13015/thumb/auric-1-high-res_icon_300_PNG.png?1604384136',
            'coingeckoId': 'auric-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TEAM',
            'name': 'TEAM',
            'address': '0x1c79ab32C66aCAa1e9E81952B8AAa581B43e54E7',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x1c79ab32c66acaa1e9e81952b8aaa581b43e54e7.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'USDK',
            'name': 'USDK',
            'address': '0x1c48f86ae57291F7686349F12601910BD8D470bb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x1c48f86ae57291f7686349f12601910bd8d470bb.png',
            'coingeckoId': 'usdk',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BCPT',
            'name': 'Blockmason Credit Protocol',
            'address': '0x1c4481750daa5Ff521A2a7490d9981eD46465Dbd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1022/thumb/mason.jpg?1547034948',
            'coingeckoId': 'blockmason-credit-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FINU',
            'name': 'First Inu',
            'address': '0x1bdC5e5aA2749B4934C33441e050b8854B77a331',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19705/thumb/finu.png?1638346534',
            'coingeckoId': 'first-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'QTCON',
            'name': 'Quiztok',
            'address': '0x1bF7Fd22709733cCD7c45AB27Dd02C7EC8E50078',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8208/thumb/QTCON.png?1587543372',
            'coingeckoId': 'quiztok',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GEAR',
            'name': 'Bitgear',
            'address': '0x1b980e05943dE3dB3a459C72325338d327B6F5a9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12127/thumb/bitgear_logo.png?1597377982',
            'coingeckoId': 'bitgear',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TOPC',
            'name': 'TopChain',
            'address': '0x1b6C5864375b34aF3Ff5Bd2E5f40Bc425B4a8D79',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2547/thumb/topchain.png?1516372992',
            'coingeckoId': 'topchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELAN',
            'name': 'Elan',
            'address': '0x1b5036bEc1B82D44d52Fa953A370b3c6Cd9328B5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27635/thumb/%D0%97%D0%BD%D1%96%D0%BC%D0%BE%D0%BA_%D0%B5%D0%BA%D1%80%D0%B0%D0%BD%D0%B0_2022-10-04_%D0%BE_17.23.34.png?1664961737',
            'coingeckoId': 'elan',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VSP',
            'name': 'Vesper Finance',
            'address': '0x1b40183EFB4Dd766f11bDa7A7c3AD8982e998421',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x1b40183efb4dd766f11bda7a7c3ad8982e998421.png',
            'coingeckoId': 'vesper-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'C4G3',
            'name': 'Cage Token',
            'address': '0x1b308d8c92EE6b06faBAFE3c8ed21a9DbBE5FaCD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WELL',
            'name': 'Bitwell',
            'address': '0x1b06B43f3b0bBAd73784d858c782e029F3F5Af14',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15878/thumb/IYW0e-3S_400x400.jpg?1622187107',
            'coingeckoId': 'bitwell-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MELOS',
            'name': 'Melos Studio',
            'address': '0x1afb69DBC9f54d08DAB1bD3436F8Da1af819E647',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24411/thumb/18551.png?1647558428',
            'coingeckoId': 'melos-studio',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NTP',
            'name': 'NFT Track Protocol',
            'address': '0x1aF4F195A8AAa7FFd752C28A13B719b84056f0d6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27108/thumb/NTP_Symbol_CoinGecko.jpg?1662009323',
            'coingeckoId': 'nft-track-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EUROC',
            'name': 'Euro Coin',
            'address': '0x1aBaEA1f7C830bD89Acc67eC4af516284b1bC33c',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x1abaea1f7c830bd89acc67ec4af516284b1bc33c.png',
            'coingeckoId': 'euro-coin',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'PLY',
            'name': 'Aurigami',
            'address': '0x1aB43204a195a0fD37EdeC621482AFd3792ef90b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24074/thumb/EbB5N8IN_400x400.jpg?1646230298',
            'coingeckoId': 'aurigami',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUGG',
            'name': 'Bugg Inu',
            'address': '0x1aABf9B575e4329b8C8F272428AD5E43ab4AeFC8',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16413/thumb/bugg.PNG?1623971743',
            'coingeckoId': 'bugg-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WINRY',
            'name': 'Winry Inu',
            'address': '0x1a87077C4F834884691B8ba4fc808D2eC93A9F30',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19420/thumb/winry.png?1640575013',
            'coingeckoId': 'winry-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AGEUR',
            'name': 'agEUR',
            'address': '0x1a7e4e63778B4f12a199C062f3eFdD288afCBce8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x1a7e4e63778b4f12a199c062f3efdd288afcbce8.png',
            'coingeckoId': 'ageur',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'APPC',
            'name': 'AppCoins',
            'address': '0x1a7a8BD9106F2B8D977E08582DC7d24c723ab0DB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1876/thumb/appcoins.png?1547036186',
            'coingeckoId': 'appcoins',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MCC',
            'name': 'MultiChainCapital',
            'address': '0x1a7981D87E3b6a95c1516EB820E223fE979896b3',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://api.rubic.exchange/assets/ethereum/0x1a7981d87e3b6a95c1516eb820e223fe979896b3/logo.png',
            'coingeckoId': 'multi-chain-capital',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ROCK',
            'name': 'MoonRock V2',
            'address': '0x1a6921F7B92113c79306f1A9E148D83445ac42BC',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25147/thumb/96251267.png?1650438435',
            'coingeckoId': 'moonrock-v2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MDF',
            'name': 'MatrixETF',
            'address': '0x1a57367C6194199e5D9aEa1cE027431682dfB411',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18538/thumb/MDF.png?1632304949',
            'coingeckoId': 'matrixetf',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TA',
            'name': 'The Ape',
            'address': '0x1a4f4d457786314668bd5dE0e49F89Fe6E2A4802',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27950/thumb/THE_Ape.png?1666666627',
            'coingeckoId': 'the-ape',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COFI',
            'name': 'CoFiX',
            'address': '0x1a23a6BfBAdB59fa563008c0fB7cf96dfCF34Ea1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12781/thumb/dnPnSkfa_400x400.png?1602885321',
            'coingeckoId': 'cofix',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ECHT',
            'name': 'e Chat',
            'address': '0x1a2277C83930b7a64C3e3D5544Eaa8C4f946B1B7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1535/thumb/e-chat.png?1547035686',
            'coingeckoId': 'e-chat',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-17500-EVERG',
            'name': 'RealT   17500 Evergreen Rd  Detroit  MI',
            'address': '0x1FdB4015fD5E031C5641752C1e03B973ad5eA168',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16624/thumb/17500-Evergreen-hero-1.jpg?1624552051',
            'coingeckoId': '17500-evergreen',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWD',
            'name': 'SW DAO',
            'address': '0x1Fd154B4d0E3753B714B511a53Fe1fb72dc7AE1C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19336/thumb/mxhLivtN.png?1635122459',
            'coingeckoId': 'sw-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SCA',
            'name': 'Scaleswap',
            'address': '0x1FbD3dF007eB8A7477A1Eab2c63483dCc24EfFD6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x1fbd3df007eb8a7477a1eab2c63483dcc24effd6.png',
            'coingeckoId': 'scaleswap-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'PHTK',
            'name': 'Phun Token',
            'address': '0x1FEE5588cb1De19c70B6aD5399152D8C643FAe7b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethereum-optimism.github.io/data/PHTK/logo.svg',
            'coingeckoId': 'phuntoken',
            'listedIn': [
                'coingecko',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'RNTB',
            'name': 'BitRent',
            'address': '0x1FE70bE734e473e5721ea57C8B5B01e6Caa52686',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3499/thumb/photo.png?1547273521',
            'coingeckoId': 'bitrent',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FOR',
            'name': 'ForTube',
            'address': '0x1FCdcE58959f536621d76f5b7FfB955baa5A672F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/4118.png',
            'coingeckoId': 'force-protocol',
            'listedIn': [
                'coingecko',
                'arbitrum_bridge',
                'rubic'
            ]
        },
        {
            'symbol': 'HEDGE',
            'name': '1X Short Bitcoin Token',
            'address': '0x1FA3bc860bF823d792f04F662f3AA3a500a68814',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10219/thumb/683JEXMN_400x400.png?1596706467',
            'coingeckoId': '1x-short-bitcoin-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STAK',
            'name': 'Jigstack',
            'address': '0x1F8A626883d7724DBd59eF51CBD4BF1Cf2016D13',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x1f8a626883d7724dbd59ef51cbd4bf1cf2016d13.png',
            'coingeckoId': 'jigstack',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BNT',
            'name': 'Bancor Network',
            'address': '0x1F573D6Fb3F13d689FF844B4cE37794d79a7FF1C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x1f573d6fb3f13d689ff844b4ce37794d79a7ff1c.png',
            'coingeckoId': 'bancor',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WOOF',
            'name': 'Woof Token',
            'address': '0x1F56aE850930460D076E8C2177d92dAa6dD046c3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22518/thumb/S2s77NqfYuMOh7r8xno5JmkkeqdX_wMsNlCbKU5on2CWnyd3FHb92aDb15CrVaz1zedzKUqyNcs8V4mXhBsfZqQQkeduUQyj6yVSXAkgTrW8itxKOqn3TMQtip4Ca__DPHuaW_Dyt_TmqYxeeIp6CU-lMFGjUlVPKz6pRFd2r0XRFrei77gK1iddcY6LGy0WdF_6lvdscHVkm-.jpg?1641969715',
            'coingeckoId': 'woof-token-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STF',
            'name': 'Structure Finance',
            'address': '0x1F4cb968b76931C494Ff92ED80CCB169ad641cb1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18409/thumb/v4RAXKdq_400x400_%281%29.jpg?1631797904',
            'coingeckoId': 'structure-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARCH',
            'name': 'Archer DAO Governance',
            'address': '0x1F3f9D3068568F8040775be2e8C03C103C61f3aF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x1f3f9d3068568f8040775be2e8c03c103c61f3af.png',
            'coingeckoId': 'archer-dao-governance-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'VNDC',
            'name': 'VNDC',
            'address': '0x1F3F677Ecc58F6A1F9e2CF410dF4776a8546b5DE',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9670/thumb/vndc-gold-coin.png?1571032826',
            'coingeckoId': 'vndc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VCG',
            'name': 'VCGamers',
            'address': '0x1F36FB2D91d9951Cf58aE4c1956C0b77e224F1E9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22371/thumb/VCG-Token-Logo-PNG.png?1643187590',
            'coingeckoId': 'vcgamers',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHINJI',
            'name': 'Shinji Inu',
            'address': '0x1F31DD60b84ea2a273cfC56876437E069aE80afd',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22846/thumb/Attachment_1645539736.png?1646300191',
            'coingeckoId': 'shinji-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAT',
            'name': 'SmartX',
            'address': '0x1F0F468Ee03A6D99CD8A09dd071494a83Dc1c0e5',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11788/thumb/FZf8lPa3_400x400.jpg?1594018352',
            'coingeckoId': 'smartx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MON',
            'name': 'Defi Franc Moneta',
            'address': '0x1EA48B9965bb5086F3b468E50ED93888a661fc17',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x1ea48b9965bb5086f3b468e50ed93888a661fc17.png',
            'coingeckoId': 'defi-franc-moneta',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'NEKO',
            'name': 'The Neko',
            'address': '0x1E9D0bB190Ac34492aa11B80D28c1C86487a341F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22692/thumb/FL6SU9b5_400x400.jpg?1642409749',
            'coingeckoId': 'the-neko',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'X2Y2',
            'name': 'X2Y2',
            'address': '0x1E4EDE388cbc9F4b5c79681B7f94d36a11ABEBC9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x1e4ede388cbc9f4b5c79681b7f94d36a11abebc9.png',
            'coingeckoId': 'x2y2',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'SHIRYO-INU',
            'name': 'Shiryo',
            'address': '0x1E2F15302B90EddE696593607b6bD444B64e8F02',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19652/thumb/shiryo_inu.png?1648267828',
            'coingeckoId': 'shiryo-inu',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'GAMMA',
            'name': 'Gamma',
            'address': '0x1E1EEd62F8D82ecFd8230B8d283D5b5c1bA81B55',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18642/thumb/6CVBUY5.png?1632753323',
            'coingeckoId': 'gamma',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KIMCHI',
            'name': 'KIMCHI finance',
            'address': '0x1E18821E69B9FAA8e6e75DFFe54E7E25754beDa0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12302/thumb/VBa2Z60o_400x400.png?1598982471',
            'coingeckoId': 'kimchi-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KOMPETE',
            'name': 'Kompete',
            'address': '0x1E0b2992079B620AA13A7c2E7c88D2e1E18E46E9',
            'decimals': 10,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24298/thumb/sdkSQyZ.jpeg?1647268750',
            'coingeckoId': 'kompete',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'TT',
            'name': 'ThunderCore',
            'address': '0x1E053D89E08c24aA2CE5C5B4206744Dc2D7BD8F5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4375/thumb/ThunderCore_Logo_Mark_Gradient_%283%29.png?1650455349',
            'coingeckoId': 'thunder-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EQZ',
            'name': 'Equalizer',
            'address': '0x1Da87b114f35E1DC91F72bF57fc07A768Ad40Bb0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethereum-optimism.github.io/data/EQZ/logo.png',
            'coingeckoId': 'equalizer',
            'listedIn': [
                'coingecko',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'BNF',
            'name': 'BonFi',
            'address': '0x1DE5e000C41C8d35b9f1f4985C23988f05831057',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12862/thumb/bonfi_logo.png?1603114422',
            'coingeckoId': 'bonfi',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'QWLA',
            'name': 'Qawalla',
            'address': '0x1D96Fd43EE07Aa79f8Fd003cbdF404fb5ce41AD2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15242/thumb/qwla.png?1648268288',
            'coingeckoId': 'qawalla',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CCX',
            'name': 'ClearCryptos',
            'address': '0x1D6405138A335cE5FD7364086334EfB3E4F28B59',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27900/thumb/3e88b61451995da1a5aa572cd4f48e78023e010c0bf984cd5456b2a7bd85f7a5.png?1666322609',
            'coingeckoId': 'clearcryptos',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'aUniUSDCETH',
            'name': 'Aave Interest bearing UniUSDCETH',
            'address': '0x1D0e53A0e524E3CC92C1f0f33Ae268FfF8D7E7a5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'ipfs://QmaznB5PRhMC696u8yZuzN6Uwrnp7Zmfa5CydVUMvLJc9i/aUNI%20USDC%20ETH.svg',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'USDTSO',
            'name': 'Tether USD  Wormhole ',
            'address': '0x1CDD2EaB61112697626F7b4bB0e23Da4FeBF7B7C',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22881/thumb/USDTso_wh_small.png?1644223113',
            'coingeckoId': 'tether-usd-wormhole',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FACE',
            'name': 'Faceter',
            'address': '0x1CCAA0F2a7210d76E1fDec740d5F323E2E1b1672',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4383/thumb/faceter-logo.png?1547039727',
            'coingeckoId': 'face',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'IBCHF',
            'name': 'Iron Bank CHF',
            'address': '0x1CC481cE2BD2EC7Bf67d1Be64d4878b16078F309',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18577/thumb/logo-128_%284%29.png?1632470239',
            'coingeckoId': 'iron-bank-chf',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KOL',
            'name': 'Kollect',
            'address': '0x1CC30e2EAc975416060Ec6FE682041408420d414',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1640052278270_36124752508396885.png',
            'coingeckoId': 'kollect',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'TOWER',
            'name': 'Tower',
            'address': '0x1C9922314ED1415c95b9FD453c3818fd41867d0B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x1c9922314ed1415c95b9fd453c3818fd41867d0b.png',
            'coingeckoId': 'tower',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BOMB',
            'name': 'BOMB',
            'address': '0x1C95b093d6C236d3EF7c796fE33f9CC6b8606714',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7713/thumb/Bomb-token.png?1549944422',
            'coingeckoId': 'bomb',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BRO',
            'name': 'Bro',
            'address': '0x1C84656Cb4c286DaA16f27D8B305F1053B5a8771',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24119/thumb/bro-256.png?1646384412',
            'coingeckoId': 'bro-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHN',
            'name': 'ShineDAO',
            'address': '0x1C7ede23b1361acC098A1e357C9085D131b34a01',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20555/thumb/SHN-Logo.png?1653470622',
            'coingeckoId': 'shinedao',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'TQUEEN',
            'name': 'TigerQueen',
            'address': '0x1C720999A4F8B2B0E9199039D353D2c9A77A73fC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22531/thumb/Logo-Tiger-Queen.png?1641997189',
            'coingeckoId': 'tigerqueen',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TORQ',
            'name': 'TORQ Coin',
            'address': '0x1C65C261cb89178b02CF2aEE20058b992787D770',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3486/thumb/torq-coin.png?1547038229',
            'coingeckoId': 'torq-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RENZEC',
            'name': 'renZEC',
            'address': '0x1C5db575E2Ff833E46a2E9864C22F4B22E0B37C2',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x1c5db575e2ff833e46a2e9864c22f4b22e0b37c2.png',
            'coingeckoId': 'renzec',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'OROX',
            'name': 'Cointorox',
            'address': '0x1C5b760F133220855340003B43cC9113EC494823',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7572/thumb/Cointorox.png?1559635132',
            'coingeckoId': 'cointorox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OKINAMI',
            'name': 'Kanagawa Nami',
            'address': '0x1C4853Ec0d55e420002c5eFaBc7Ed8e0bA7A4121',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26629/thumb/KANAGAWA_%284%29.png?1659324703',
            'coingeckoId': 'kanagawa-nami',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FEX',
            'name': 'FIDEX Exchange',
            'address': '0x1C1C14A6B5074905Ce5d367B0A7E098b58EbFD47',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6843/thumb/1LC9qeLf_400x400.jpg?1547043159',
            'coingeckoId': 'fidex-exchange',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PAR',
            'name': 'Parachute',
            'address': '0x1BeEF31946fbbb40B877a72E4ae04a8D1A5Cee06',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x1beef31946fbbb40b877a72e4ae04a8d1a5cee06.png',
            'coingeckoId': 'parachute',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'Z',
            'name': 'Zinja',
            'address': '0x1BE56412c9606E7285280F76A105EBa56996e491',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24513/thumb/DPaP9wkr_400x400.jpg?1647950008',
            'coingeckoId': 'zinja',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HG',
            'name': 'Hygenercoin',
            'address': '0x1BC9F31c327Ce04b6fA9D56FD84c14Cc0B0A4f47',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8817/thumb/60762471_431874227611586_5376521021266329600_n.png?1561551785',
            'coingeckoId': 'hygenercoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VOW',
            'name': 'Vow',
            'address': '0x1BBf25e71EC48B84d773809B4bA55B6F4bE946Fb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18202/thumb/72Nd63R0_400x400.png?1630974351',
            'coingeckoId': 'vow',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CLIFF',
            'name': 'Clifford Inu',
            'address': '0x1B9BAF2A3EdeA91eE431f02d449a1044d5726669',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21624/thumb/Cliff-Logo-with-with-Circle-NO-Background.png?1655968797',
            'coingeckoId': 'clifford-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HQX',
            'name': 'HOQU',
            'address': '0x1B957Dc4aEfeed3b4A2351a6A6d5cbfbbA0CeCFa',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1793/thumb/hqx.png?1547036096',
            'coingeckoId': 'hoqu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SCX',
            'name': 'Scarcity',
            'address': '0x1B8568FbB47708E9E9D31Ff303254f748805bF21',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19064/thumb/scx_gecko.png?1634266881',
            'coingeckoId': 'scarcity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DFC',
            'name': 'DeFiScale',
            'address': '0x1B2a76Da77D03b7FC21189d9838f55Bd849014aF',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14974/thumb/logo200x200-2.png?1619214186',
            'coingeckoId': 'defiscale',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BEST',
            'name': 'Bitpanda Ecosystem',
            'address': '0x1B073382E63411E3BcfFE90aC1B9A43feFa1Ec6F',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8738/thumb/BEST-Coin-Logo.png?1619079782',
            'coingeckoId': 'bitpanda-ecosystem-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '1ECO',
            'name': '1eco',
            'address': '0x1Af2eAEAf2B1d9dDA800861268e6bbB3995a6C3b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22118/thumb/mgrOT_dx_400x400.png?1640847402',
            'coingeckoId': '1eco',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YORI',
            'name': 'The Path Of Light',
            'address': '0x1Ab052Db3216835182926A6d516762B0f1634661',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27734/thumb/yori_icon200.png?1665563891',
            'coingeckoId': 'the-path-of-light',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OWL',
            'name': 'OWL Token',
            'address': '0x1A5F9352Af8aF974bFC03399e3767DF6370d82e4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x1a5f9352af8af974bfc03399e3767df6370d82e4.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'BIT',
            'name': 'BitDAO',
            'address': '0x1A4b46696b2bB4794Eb3D4c26f1c55F9170fa4C5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1667890109417_36834610336949347.png',
            'coingeckoId': 'bitdao',
            'listedIn': [
                'coingecko',
                'uniswap',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'CTT',
            'name': 'Castweet',
            'address': '0x1A4743Cf1af4C289351390A2B3fe7c13D2F7C235',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9602/thumb/200x200color.png?1569479415',
            'coingeckoId': 'castweet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SML',
            'name': 'Saltmarble',
            'address': '0x1A403E1c96792dFedb8232cF56400Eb72Ab95Acb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27504/thumb/SML_200px.png?1664331670',
            'coingeckoId': 'saltmarble',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEP',
            'name': 'DEAPCOIN',
            'address': '0x1A3496C18d558bd9C6C8f609E1B129f67AB08163',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10970/thumb/DEAPcoin_01.png?1586741677',
            'coingeckoId': 'deapcoin',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'PARMA',
            'name': 'PARMA',
            'address': '0x1A2933fbA0c6e959c9A2D2c933f3f8AD4aa9f06e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23847/thumb/200_%281%29.png?1645519586',
            'coingeckoId': 'parma',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AGPC',
            'name': 'AGPC',
            'address': '0x19fdC290974B574065Ff8db4303a0b76AECf223E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17772/thumb/AGPC.jpg?1629441589',
            'coingeckoId': 'agpc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARTM',
            'name': 'ARTM',
            'address': '0x19ebAA7F212b09de2aee2a32D40338553C70e2e3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21577/thumb/artm_token_logo.png?1639530067',
            'coingeckoId': 'artm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CARAT',
            'name': 'CARAT',
            'address': '0x19ea630bCBc1a511a16e65b6ECd447c92E1C087c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6434/thumb/carat.png?1547738711',
            'coingeckoId': 'carat',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BGB',
            'name': 'Bitget Token',
            'address': '0x19de6b897Ed14A376Dda0Fe53a5420D2aC828a28',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11610/thumb/photo_2022-01-24_14-08-03.jpg?1643019457',
            'coingeckoId': 'bitget-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BCEO',
            'name': 'bitCEO',
            'address': '0x19cA83a13b4C4BE43FA82c5E415E16f1D86f57F7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8202/thumb/bitceo.jpg?1556512592',
            'coingeckoId': 'bitceo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TXPT',
            'name': 'tPLATINUM',
            'address': '0x19b22DbADc298c359A1D1b59e35f352A2b40E33c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27830/thumb/tPLATINUM_token_2D.jpg?1666165908',
            'coingeckoId': 'tplatinum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COVN',
            'name': 'Covenant',
            'address': '0x19ac2659599fD01c853dE846919544276aD26F50',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22280/thumb/16837.png?1641364060',
            'coingeckoId': 'covenant-child',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COLA',
            'name': 'Cola',
            'address': '0x19E98c4921aAb7E3f5FD2aDca36CFb669c63E926',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18806/thumb/colawork_logo_200x200.png?1633479503',
            'coingeckoId': 'cola-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BNS',
            'name': 'BNS',
            'address': '0x19E2a43Fbbc643C3B2D9667D858D49cAd17bc2b5',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25150/thumb/bitbns_logo.png?1650450366',
            'coingeckoId': 'bns-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BBADGER',
            'name': 'Badger Sett Badger',
            'address': '0x19D97D8fA813EE2f51aD4B4e04EA08bAf4DFfC28',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/bbadger.jpg',
            'coingeckoId': 'badger-sett-badger',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'CELC',
            'name': 'CelCoin',
            'address': '0x19B58d95929586Ad750893CAad43E77aa6e8Ce9E',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6078/thumb/celcoin-logo.png?1547042055',
            'coingeckoId': 'celcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NEWINU',
            'name': 'Newinu',
            'address': '0x1997830B5beB723f5089bb8fc38766d419a0444d',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16192/thumb/Newinu.png?1625583532',
            'coingeckoId': 'newinu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFT',
            'name': 'APENFT',
            'address': '0x198d14F2Ad9CE69E76ea330B374DE4957C3F850a',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15687/thumb/apenft.jpg?1621562368',
            'coingeckoId': 'apenft',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REP',
            'name': 'Reputation Augur v1',
            'address': '0x1985365e9f78359a9B6AD760e32412f4a445E862',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x1985365e9f78359a9B6AD760e32412f4a445E862/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ASTETH',
            'name': 'Aave Interest Bearing STETH',
            'address': '0x1982b2F5814301d4e9a8b0201555376e62F82428',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24163/thumb/steth.jpg?1646634315',
            'coingeckoId': 'aave-interest-bearing-steth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DRE',
            'name': 'DoRen',
            'address': '0x1977bE49C33dFacF6590C16ca9a9CfA0463f663c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16901/thumb/DoRen_project_whitepaper_v1_1_EN_pdf.png?1625572581',
            'coingeckoId': 'doren',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RSV',
            'name': 'Reserve',
            'address': '0x196f4727526eA7FB1e17b2071B3d8eAA38486988',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x196f4727526ea7fb1e17b2071b3d8eaa38486988.png',
            'coingeckoId': 'reserve',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'CTASK',
            'name': 'CryptoTask',
            'address': '0x196c81385Bc536467433014042788Eb707703934',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x196c81385bc536467433014042788eb707703934.png',
            'coingeckoId': 'cryptotask-2',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'NDX',
            'name': 'nDEX',
            'address': '0x1966d718A565566e8E202792658D7b5Ff4ECe469',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5576/thumb/nDEX-logo.png?1547041405',
            'coingeckoId': 'ndex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BDG',
            'name': 'BitDegree',
            'address': '0x1961B3331969eD52770751fC718ef530838b6dEE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1653/thumb/bitdegree.jpg?1547035900',
            'coingeckoId': 'bitdegree',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WPP',
            'name': 'WPP Token',
            'address': '0x1955d744F9435522Be508D1Ba60E3c12D0690B6A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8103/thumb/WzdD53fh_400x400.jpg?1554860792',
            'coingeckoId': 'wpp-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NLIFE',
            'name': 'Night Life Crypto',
            'address': '0x1951aB088141e69A3713a351b0d55bA3acDa192C',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16277/thumb/F6jp9eh.png?1623634100',
            'coingeckoId': 'night-life-crypto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CABIN',
            'name': 'Cabin',
            'address': '0x1934E252f840aA98dfCe2b6205B3E45c41AeF830',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23874/thumb/z8CMVDb8_400x400.jpg?1645600167',
            'coingeckoId': 'cabin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INFS',
            'name': 'Infinity Esaham',
            'address': '0x193408cA0576B73156Ed42A2EA7D6fD3f6507162',
            'decimals': 1,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10716/thumb/WBUD9cx.png?1582524776',
            'coingeckoId': 'infinity-esaham',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NNT',
            'name': 'Nunu Spirits',
            'address': '0x19193F450086d0442157b852081976D41657aD56',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24378/thumb/NNT_99cc33.png?1649120059',
            'coingeckoId': 'nunu-spirits',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ECO',
            'name': 'Ormeus Ecosystem',
            'address': '0x191557728e4d8CAa4Ac94f86af842148c0FA8F7E',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x191557728e4d8caa4ac94f86af842148c0fa8f7e.png',
            'coingeckoId': 'ormeus-ecosystem',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'UMC',
            'name': 'Umbrella Coin',
            'address': '0x190fB342aa6a15eB82903323ae78066fF8616746',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1700/thumb/umbrellacoin.jpg?1547035958',
            'coingeckoId': 'umbrellacoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BZZ',
            'name': 'Swarm',
            'address': '0x19062190B1925b5b6689D7073fDfC8c2976EF8Cb',
            'decimals': 16,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16509/thumb/Swarm_Logo_1_%E2%97%8B_1_200px.png?1628508564',
            'coingeckoId': 'swarm-bzz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TMM',
            'name': 'Take My Muffin',
            'address': '0x19042021329FDdcFBea5f934FB5b2670C91F7D20',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x19042021329fddcfbea5f934fb5b2670c91f7d20.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'CBG',
            'name': 'Chainbing',
            'address': '0x1900E8B5619a3596745F715d0427Fe617c729BA9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18052/thumb/j3saoPU.png?1630330010',
            'coingeckoId': 'chainbing',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MPM',
            'name': 'Monopoly Meta',
            'address': '0x18b52F500e6D9C8b0455eC3483846D9f8edd7e1b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26965/thumb/TkA1Iou8_400x400.jpeg?1661081008',
            'coingeckoId': 'monopoly-meta',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DCA',
            'name': 'Decentralize Currency',
            'address': '0x18aa7c90d3ae4C5BB219d0a2813F441704084625',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7673/thumb/DCA.png?1573037394',
            'coingeckoId': 'decentralized-currency-assets',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AUDIO',
            'name': 'Audius',
            'address': '0x18aAA7115705e8be94bfFEBDE57Af9BFc265B998',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x18aaa7115705e8be94bffebde57af9bfc265b998.png',
            'coingeckoId': 'audius',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SPICE',
            'name': 'Spice Trade',
            'address': '0x18a1Ea69A50A85752b7bC204a2c45A95Ce6E429d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25770/thumb/SPICE.png?1653607602',
            'coingeckoId': 'spice-trade',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'oneLINK',
            'name': 'oneLINK',
            'address': '0x18Cc17a1EeD37C02A77B0B96b7890C7730E2a2CF',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/onelink.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'CEJI',
            'name': 'Ceji',
            'address': '0x189fC141854BFc6146777406d53fbC89F4e70407',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26458/thumb/CEJI.png?1658129614',
            'coingeckoId': 'ceji',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LITH',
            'name': 'Lithium Finance',
            'address': '0x188E817B02e635D482AE4D81e25DdA98A97C4a42',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17278/thumb/Lithium_Logo-03200x200.png?1627018557',
            'coingeckoId': 'lithium-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOAR',
            'name': 'Moar Finance',
            'address': '0x187Eff9690E1F1A61d578C7c492296eaAB82701a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15057/thumb/moar.PNG?1619589388',
            'coingeckoId': 'moar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FUZE',
            'name': 'FUZE',
            'address': '0x187D1018E8ef879BE4194d6eD7590987463eAD85',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8941/thumb/logo-fuze-fix-big.png?1563117524',
            'coingeckoId': 'fuze-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TRAVA',
            'name': 'TravaFinance',
            'address': '0x186D0Ba3dfC3386C464eECd96A61fBB1E2dA00bf',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/11209.png',
            'coingeckoId': null,
            'listedIn': [
                'dfyn'
            ]
        },
        {
            'symbol': 'PIB',
            'name': 'Pibble',
            'address': '0x1864cE27E9F7517047933CaAE530674e8C70b8A7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2972/thumb/pibble.jpg?1551683103',
            'coingeckoId': 'pibble',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KMT',
            'name': 'FiveKM KMT',
            'address': '0x184b6f31aeAa6a19Eb207dFeA01F4289629e0f0f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25410/thumb/logo-200.png?1651732812',
            'coingeckoId': 'fivekm-kmt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TANGO',
            'name': 'keyTango',
            'address': '0x182F4c4C97cd1c24E1Df8FC4c053E5C47bf53Bef',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x182f4c4c97cd1c24e1df8fc4c053e5c47bf53bef.png',
            'coingeckoId': 'keytango',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'FXT',
            'name': 'FuzeX',
            'address': '0x1829aA045E21E0D59580024A951DB48096e01782',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3692/thumb/fuzex-token.png?1547038696',
            'coingeckoId': 'fuzex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOJO',
            'name': 'DOJO',
            'address': '0x180dAe91D6d56235453a892d2e56a3E40Ba81DF8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16396/thumb/074606deafab3872.png?1623910120',
            'coingeckoId': 'dojo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ISK',
            'name': 'ISKRA Token',
            'address': '0x17d2628D30F8e9E966c9Ba831c9B9b01ea8Ea75C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27428/thumb/ISKRA_logo.png?1663919121',
            'coingeckoId': 'iskra-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CC10',
            'name': 'Cryptocurrency Top 10 Tokens Index',
            'address': '0x17aC188e09A7890a1844E5E65471fE8b0CcFadF3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x17ac188e09a7890a1844e5e65471fe8b0ccfadf3.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'AAMMUSDT',
            'name': 'Aave AMM USDT',
            'address': '0x17a79792Fe6fE5C95dFE95Fe3fCEE3CAf4fE4Cb7',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17200/thumb/aAMMUSDT_2x.png?1626873261',
            'coingeckoId': 'aave-amm-usdt',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RVP',
            'name': 'Revolution Populi',
            'address': '0x17EF75AA22dD5f6C2763b8304Ab24f40eE54D48a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14772/thumb/vsd0Wnc2_400x400.png?1618369912',
            'coingeckoId': 'revolution-populi',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'WBI',
            'name': 'World Bet Inu',
            'address': '0x17Ce5A8187c817D659B21083455455A092a173BB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28279/thumb/WBIlogo.png?1669107582',
            'coingeckoId': 'world-bet-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SNB',
            'name': 'SynchroBitcoin',
            'address': '0x179E31FB25E433441a2839389A7b8EC9c4654b7B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10727/thumb/Logosnb.png?1582667361',
            'coingeckoId': 'synchrobitcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IXI',
            'name': 'IxiCash',
            'address': '0x179Cd91631d96e8faFEe6A744eAC6fFdbB923520',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8368/thumb/ixi.png?1565866972',
            'coingeckoId': 'ixicash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PMON',
            'name': 'Polychain Monsters',
            'address': '0x1796ae0b0fa4862485106a0de9b654eFE301D0b2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x1796ae0b0fa4862485106a0de9b654efe301d0b2.png',
            'coingeckoId': 'polychain-monsters',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'EWTB',
            'name': 'Energy Web Token Bridged',
            'address': '0x178c820f862B14f316509ec36b13123DA19A6054',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x178c820f862b14f316509ec36b13123da19a6054.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'UUSDT',
            'name': 'Unagii Tether USD',
            'address': '0x178Bf8fD04b47D2De3eF3f6b3D112106375ad584',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13778/thumb/uUSDT.png?1611729448',
            'coingeckoId': 'unagii-tether-usd',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OAP',
            'name': 'OpenAlexa Protocol',
            'address': '0x1788430620960F9a70e3DC14202a3A35ddE1A316',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12400/thumb/256x256-OAP.png?1599556701',
            'coingeckoId': 'openalexa-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CDT',
            'name': 'Blox',
            'address': '0x177d39AC676ED1C67A2b268AD7F1E58826E5B0af',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1231/thumb/Blox_Staking_Logo_2.png?1609117544',
            'coingeckoId': 'blox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KIF',
            'name': 'KittenFinance',
            'address': '0x177BA0cac51bFC7eA24BAd39d81dcEFd59d74fAa',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12340/thumb/SnQPkABT_400x400.png?1599173267',
            'coingeckoId': 'kittenfinance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NMR',
            'name': 'Numeraire',
            'address': '0x1776e1F26f98b1A5dF9cD347953a26dd3Cb46671',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x1776e1f26f98b1a5df9cd347953a26dd3cb46671.png',
            'coingeckoId': 'numeraire',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'BACON',
            'name': 'BaconSwap',
            'address': '0x175Ab41E2CEDF3919B2e4426C19851223CF51046',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12336/thumb/bacon_swap_logo.png?1599133231',
            'coingeckoId': 'baconswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YPIE',
            'name': 'PieDAO Yearn Ecosystem Pie',
            'address': '0x17525E4f4Af59fbc29551bC4eCe6AB60Ed49CE31',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/ypie.jpg',
            'coingeckoId': 'piedao-yearn-ecosystem-pie',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'HUM',
            'name': 'Humanscape',
            'address': '0x174aFE7A032b5A33a3270a9f6C30746E25708532',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4243/thumb/Webp.net-resizeimage_%2836%29.png?1547039574',
            'coingeckoId': 'humanscape',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHIDO',
            'name': 'Shido',
            'address': '0x173E552Bf97BBD50b455514ac52991Ef639ba703',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26674/thumb/Shido.png?1667361249',
            'coingeckoId': 'shido',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'URQA',
            'name': 'UREEQA',
            'address': '0x1735Db6AB5BAa19eA55d0AdcEeD7bcDc008B3136',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x1735db6ab5baa19ea55d0adceed7bcdc008b3136.png',
            'coingeckoId': 'ureeqa',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SHOX',
            'name': 'Shinjiro',
            'address': '0x1726b8d5dc3A93CC08Fa079477d4EBe782b25Bf7',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25018/thumb/Shinjiro_200x200.png?1649830769',
            'coingeckoId': 'shinjiro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOTG',
            'name': 'MetaOctagon',
            'address': '0x171d76d931529384639BC9aad5b77b77041ED604',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26496/thumb/iJoylNuO_400x400.jpeg?1658304109',
            'coingeckoId': 'metaoctagon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LIMIT',
            'name': 'LimitSwap',
            'address': '0x1712Aad2C773ee04bdC9114b32163c058321CD85',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12760/thumb/limit_swap_logo.png?1602347106',
            'coingeckoId': 'limitswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OWN',
            'name': 'OWNDATA',
            'address': '0x170b275CEd089FffAEBFe927F445a350ED9160DC',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3903/thumb/owndata.png?1548330403',
            'coingeckoId': 'owndata',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UTT',
            'name': 'United Traders',
            'address': '0x16f812Be7FfF02cAF662B85d5d58a5da6572D4Df',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1741/thumb/utt.png?1547036018',
            'coingeckoId': 'united-traders-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'yDAIv2',
            'name': 'iearn DAIv2',
            'address': '0x16de59092dAE5CcF4A1E6439D611fd0653f0Bd01',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x16de59092dae5ccf4a1e6439d611fd0653f0bd01.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'TCAP',
            'name': 'Total Crypto Market Cap',
            'address': '0x16c52CeeCE2ed57dAd87319D91B5e3637d50aFa4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x16c52ceece2ed57dad87319d91b5e3637d50afa4.png',
            'coingeckoId': 'total-crypto-market-cap-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'OH',
            'name': 'Oh  Finance',
            'address': '0x16ba8Efe847EBDFef99d399902ec29397D403C30',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19493/thumb/oh-token-logo-200.png?1635303795',
            'coingeckoId': 'oh-finance',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'GDO',
            'name': 'GroupDao',
            'address': '0x16F78145AD0B9Af58747e9A97EBd99175378bd3D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22204/thumb/logo.png?1641194137',
            'coingeckoId': 'groupdao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ROUTE',
            'name': 'Router Protocol',
            'address': '0x16ECCfDbb4eE1A85A33f3A9B21175Cd7Ae753dB4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x16eccfdbb4ee1a85a33f3a9b21175cd7ae753db4.png',
            'coingeckoId': 'route',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'MARS4',
            'name': 'MARS4',
            'address': '0x16CDA4028e9E872a38AcB903176719299beAed87',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x16CDA4028e9E872a38AcB903176719299beAed87/logo.png',
            'coingeckoId': 'mars4',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'VXL',
            'name': 'Voxel X Network',
            'address': '0x16CC8367055aE7e9157DBcB9d86Fd6CE82522b31',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x16cc8367055ae7e9157dbcb9d86fd6ce82522b31.png',
            'coingeckoId': 'voxel-x-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SMBR',
            'name': 'Sombra',
            'address': '0x16B3E050e9e2f0Ac4f1BEA1b3E4fdc43d7f062Dd',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17884/thumb/sombra-200.png?1629710204',
            'coingeckoId': 'sombra-network',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ALG',
            'name': 'Algory',
            'address': '0x16B0a1a87ae8aF5C792faBC429C4FE248834842B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12231/thumb/logo-2.png?1605256312',
            'coingeckoId': 'algory',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VGR',
            'name': 'Voyager',
            'address': '0x16987C021C14ca1045cd0afEbB33c124a58Bf16C',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7381/thumb/PKQbxUtP_400x400.jpg?1547044079',
            'coingeckoId': 'voyager',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KEX',
            'name': 'KIRA Network',
            'address': '0x16980b3B4a3f9D89E33311B5aa8f80303E5ca4F8',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x16980b3b4a3f9d89e33311b5aa8f80303e5ca4f8.png',
            'coingeckoId': 'kira-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ISLE',
            'name': 'Island',
            'address': '0x1681bcB589b3cFCF0c0616B0cE9b19b240643dc1',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x1681bcb589b3cfcf0c0616b0ce9b19b240643dc1.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': '300',
            'name': 'Spartan',
            'address': '0x167E2A574669b0EeB552aaF3Da47c728cb348a41',
            'decimals': 7,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13371/thumb/spartan300-200x200.png?1607986424',
            'coingeckoId': 'spartan',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHEDDA',
            'name': 'Chedda',
            'address': '0x16756EC1DEb89A2106C35E0B586a799D0A61837D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22654/thumb/cMckil70_400x400.jpg?1642400459',
            'coingeckoId': 'chedda',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GENES',
            'name': 'GENES Chain',
            'address': '0x1673A63aA0047294d75954226f3F2F98De77b16f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8857/thumb/logo256_%281%29.png?1562205433',
            'coingeckoId': 'genes-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VERA',
            'name': 'VERA Exchange',
            'address': '0x166F1a7eCAe00bd43876A25B10a63C575e05c0e7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9766/thumb/hn9LRFGrVzyuPJrDIrVeCdUN2IfxIwWDk6mM5U9cGm_b0oPyENjOrXzTJ7vwHE65PIzM_H4nGlcHJHPBV5qHoQ8cfwRSud89RJHUngAGMnYIoLZ-UzEk9HuspfOmNu2Finm8RZW-UIM1_Jykw3Cj743iNPqjsqNxkuazLsa0h76oIwf61Sn3h9-IFiwgVLXyfRVuCO0bTY6q_aLJLwzE-P1OALNJfpLSVY52ygRlDGMZS1qj.jpg?1575358608',
            'coingeckoId': 'vera-exchange',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TKB',
            'name': 'TokenBot',
            'address': '0x16594930D16f3970e1a4317c6016555cb2e7b7Fc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27106/thumb/tokenbot-logo-icon.png?1662007634',
            'coingeckoId': 'tokenbot',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'SRC',
            'name': 'Simracer Coin',
            'address': '0x16587cF43F044aBa0165ffA00AcF412631194e4b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13027/thumb/SimRacer-500px.png?1604456794',
            'coingeckoId': 'simracer-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FKX',
            'name': 'FortKnoxster',
            'address': '0x16484d73Ac08d2355F466d448D2b79D2039F6EBB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5050/thumb/fkx.png?1644048298',
            'coingeckoId': 'fortknoxter',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PAID',
            'name': 'PAID Network',
            'address': '0x1614F18Fc94f47967A3Fbe5FfcD46d4e7Da3D787',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x1614f18fc94f47967a3fbe5ffcd46d4e7da3d787.png',
            'coingeckoId': 'paid-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'stFIRO',
            'name': 'stakedFiro',
            'address': '0x160B1E5aaBFD70B2FC40Af815014925D71CEEd7E',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/stfiro.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'APC',
            'name': 'Alpha Coin',
            'address': '0x15bdA08c3afbf5955D6e9B235Fd55a1FD0DbC829',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6725/thumb/alpha-coin.png?1547042955',
            'coingeckoId': 'alpha-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GEL',
            'name': 'Gelato',
            'address': '0x15b7c0c907e4C6b9AdaAaabC300C08991D6CEA05',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x15b7c0c907e4c6b9adaaaabc300c08991d6cea05.png',
            'coingeckoId': 'gelato',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'DENA',
            'name': 'Decentralized Nations',
            'address': '0x15F0EEDF9Ce24fc4b6826E590A8292CE5524a1DA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22167/thumb/denations.PNG?1641166526',
            'coingeckoId': 'decentralized-nations',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BITANT',
            'name': 'BitANT',
            'address': '0x15Ee120fD69BEc86C1d38502299af7366a41D1a6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethereum-optimism.github.io/data/BitANT/logo.png',
            'coingeckoId': 'bitant',
            'listedIn': [
                'coingecko',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'DXF',
            'name': 'Dexfin',
            'address': '0x15Eabb7500E44B7Fdb6e4051cA8DecA430cF9FB8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13660/thumb/dxf-symbol.png?1610599440',
            'coingeckoId': 'dexfin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AEGIS',
            'name': 'StonkLeague',
            'address': '0x15Dd37a83564559927bBDcDbfcF85F60A2C6a419',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26294/thumb/iShot_2022-07-07_10.44.16.png?1657161870',
            'coingeckoId': 'stonkleague',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GALA',
            'name': 'GALA',
            'address': '0x15D4c048F83bd7e37d49eA4C83a07267Ec4203dA',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x15d4c048f83bd7e37d49ea4c83a07267ec4203da.png',
            'coingeckoId': 'gala',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'STC',
            'name': 'Student Coin',
            'address': '0x15B543e986b8c34074DFc9901136d9355a537e7E',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x15b543e986b8c34074dfc9901136d9355a537e7e.png',
            'coingeckoId': 'student-coin',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'RFX',
            'name': 'Reflex',
            'address': '0x159A1dFAe19057de57dFfFcbB3DA1aE784678965',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12745/thumb/2MKGMRCT_400x400.png?1602194456',
            'coingeckoId': 'reflex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INFI',
            'name': 'Insured Finance',
            'address': '0x159751323A9E0415DD3d6D42a1212fe9F4a0848C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x159751323a9e0415dd3d6d42a1212fe9f4a0848c.png',
            'coingeckoId': 'insured-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'Pixel',
            'name': 'PIXEL',
            'address': '0x1590ABe3612Be1CB3ab5B0f28874D521576e97Dc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/pixel.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'EMAX',
            'name': 'EthereumMax',
            'address': '0x15874d65e649880c2614e7a480cb7c9A55787FF6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x15874d65e649880c2614e7a480cb7c9a55787ff6.png',
            'coingeckoId': 'ethereummax',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'cREP',
            'name': 'Compound Augur',
            'address': '0x158079Ee67Fce2f58472A96584A73C7Ab9AC95c1',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x158079ee67fce2f58472a96584a73c7ab9ac95c1.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'IFARM',
            'name': 'iFARM',
            'address': '0x1571eD0bed4D987fe2b498DdBaE7DFA19519F651',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14472/thumb/ifarm.png?1616389133',
            'coingeckoId': 'ifarm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EYE',
            'name': 'Behodler',
            'address': '0x155ff1A85F440EE0A382eA949f24CE4E0b751c65',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12804/thumb/etherscan-eye-2-1.png?1629881415',
            'coingeckoId': 'beholder',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EDEN',
            'name': 'EDEN',
            'address': '0x1559FA1b8F28238FD5D76D9f434ad86FD20D1559',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x1559fa1b8f28238fd5d76d9f434ad86fd20d1559.png',
            'coingeckoId': 'eden',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'REVO',
            'name': 'Revomon',
            'address': '0x155040625D7ae3e9caDA9a73E3E44f76D3Ed1409',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x155040625d7ae3e9cada9a73e3e44f76d3ed1409.png',
            'coingeckoId': 'revomon',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'RAID',
            'name': 'Raid',
            'address': '0x154e35c2b0024B3e079c5c5e4fC31c979c189cCB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18133/thumb/raid_200_oswlvz.png?1630631876',
            'coingeckoId': 'raid-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TREKS',
            'name': 'PlayTreks',
            'address': '0x15492208Ef531EE413BD24f609846489a082F74C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21378/thumb/trekscoin-200x200-tr.png?1639033673',
            'coingeckoId': 'playtreks',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XOV',
            'name': 'XOVBank',
            'address': '0x153eD9CC1b792979d2Bde0BBF45CC2A7e436a5F9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5396/thumb/xov.jpg?1549857681',
            'coingeckoId': 'xov',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WBIND',
            'name': 'Wrapped BIND',
            'address': '0x15334DCb171e8b65D6650321581dcA83bE870115',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13055/thumb/o1w2cBW.png?1604720921',
            'coingeckoId': 'wrapped-bind',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEB',
            'name': 'Debitum Network',
            'address': '0x151202C9c18e495656f372281F493EB7698961D5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1648/thumb/debitum_network_token.jpg?1547035890',
            'coingeckoId': 'debitum-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BBT',
            'name': 'BitBoost',
            'address': '0x1500205f50bf3FD976466d0662905c9ff254fc9c',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1194/thumb/bitboost.jpg?1547035224',
            'coingeckoId': 'bitboost',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KAT',
            'name': 'Kambria',
            'address': '0x14da230d6726C50F759Bc1838717F8CE6373509C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4784/thumb/kambria.png?1547040127',
            'coingeckoId': 'kambria',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'iDEFI',
            'name': 'Synth iDEFI',
            'address': '0x14d10003807AC60d07BB0ba82cAeaC8d2087c157',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x14d10003807ac60d07bb0ba82caeac8d2087c157.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'KTT',
            'name': 'K Tune',
            'address': '0x14cC8DfaF2258E1B8B2869300dBa1B734dc0fE43',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13939/thumb/KakaoTalk_20220928_162525453.png?1664356062',
            'coingeckoId': 'k-tune',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COLR',
            'name': 'colR Coin',
            'address': '0x14b40AD2EBA6c1B31db2bA817b07578AFB414415',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26701/thumb/colverse.jpeg?1659662193',
            'coingeckoId': 'colr-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KYOKO',
            'name': 'Kyoko',
            'address': '0x14a32f050FACF226Ec60882398A9BF36d91DBaC2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24454/small/kyoko.png?1649576935',
            'coingeckoId': 'kyoko',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'BCUG',
            'name': 'Blockchain Cuties Universe Governance',
            'address': '0x14Da7b27b2E0FedEfe0a664118b0c9bc68e2E9AF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x14da7b27b2e0fedefe0a664118b0c9bc68e2e9af.png',
            'coingeckoId': 'blockchain-cuties-universe-governance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BETHER',
            'name': 'Bethereum',
            'address': '0x14C926F2290044B647e1Bf2072e67B495eff1905',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2738/thumb/bethereum.png?1547036939',
            'coingeckoId': 'bethereum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DPI',
            'name': 'DeFi Pulse Index',
            'address': '0x1494CA1F11D487c2bBe4543E90080AeBa4BA3C2b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x1494ca1f11d487c2bbe4543e90080aeba4ba3c2b.png',
            'coingeckoId': 'defipulse-index',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'GEO',
            'name': 'GeoDB',
            'address': '0x147faF8De9d8D8DAAE129B187F0D02D819126750',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x147faf8de9d8d8daae129b187f0d02d819126750.png',
            'coingeckoId': 'geodb',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'N00D',
            'name': 'n00dle',
            'address': '0x147aB744f058A52e828BEE84A9C58660F2fF5Ff9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27850/thumb/%E8%9E%A2%E5%B9%95%E6%88%AA%E5%9C%96_2022-10-17_%E4%B8%8B%E5%8D%888.36.07.png?1666092864',
            'coingeckoId': 'n00dle',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIB',
            'name': 'MIB Coin',
            'address': '0x146D8D942048ad517479C9bab1788712Af180Fde',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5691/thumb/mibcoin.png?1547041559',
            'coingeckoId': 'mib-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHX',
            'name': 'WeOwn',
            'address': '0x1460a58096d80a50a2F1f956DDA497611Fa4f165',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1943/thumb/ZlGXwUDv_400x400.jpg?1571388675',
            'coingeckoId': 'chainium',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDP',
            'name': 'USDP Stablecoin',
            'address': '0x1456688345527bE1f37E9e627DA0837D6f08C925',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x1456688345527be1f37e9e627da0837d6f08c925.png',
            'coingeckoId': 'usdp',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'TEND',
            'name': 'Tendies',
            'address': '0x1453Dbb8A29551ADe11D89825CA812e05317EAEB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x1453dbb8a29551ade11d89825ca812e05317eaeb.png',
            'coingeckoId': 'tendies',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SRLTY',
            'name': 'SaitaRealty',
            'address': '0x142a774E8b52550E88E196CedD7A5835acB646D0',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27289/thumb/s_mksCdB_400x400.jpeg?1663145048',
            'coingeckoId': 'saitarealty',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JSHIBA',
            'name': 'Jomon Shiba',
            'address': '0x1426cC6D52D1B14e2B3b1Cb04d57ea42B39c4c7c',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15395/thumb/Jomon.png?1622960557',
            'coingeckoId': 'jomon-shiba',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KAN',
            'name': 'BitKan',
            'address': '0x1410434b0346f5bE678d0FB554E5c7ab620f8f4a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4651/thumb/kan-token.png?1547039917',
            'coingeckoId': 'kan',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHPP',
            'name': 'ShipItPro',
            'address': '0x13b1E6B036aC0a1495F876313f92A0706CC10871',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14687/thumb/photo_2020-12-28_16-37-33.jpg?1617785737',
            'coingeckoId': 'shipitpro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MTRL',
            'name': 'Material',
            'address': '0x13C99770694f07279607A6274F28a28c33086424',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22069/thumb/square-200x200.png?1640749501',
            'coingeckoId': 'material',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PHI',
            'name': 'PHI Token',
            'address': '0x13C2fab6354d3790D8ece4f0f1a3280b4A25aD96',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AAMMWBTC',
            'name': 'Aave AMM WBTC',
            'address': '0x13B2f6928D7204328b0E8E4BCd0379aA06EA21FA',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17215/thumb/aAMMWBTC_2x.png?1626879028',
            'coingeckoId': 'aave-amm-wbtc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'mQQQ',
            'name': 'Wrapped Mirror QQQ Token',
            'address': '0x13B02c8dE71680e71F0820c996E4bE43c2F57d15',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/mqqq.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'EMP',
            'name': 'Export Motors Platform',
            'address': '0x13A0599c493Cc502ED8C4Dd26F22F5cEDC248fC4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24422/thumb/emp_logo_200.png?1647654574',
            'coingeckoId': 'export-mortos-platform',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOUTS',
            'name': 'BoutsPro',
            'address': '0x139d9397274bb9E2C29A9aa8Aa0b5874d30D62E3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4296/thumb/boutspro-ico-logo-300x300.jpg?1527072201',
            'coingeckoId': 'boutspro',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASET',
            'name': 'Parasset',
            'address': '0x139cEC55d1EC47493dFA25cA77C9208aBa4D3C68',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19395/thumb/token_200.png?1635151501',
            'coingeckoId': 'parasset',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALPHA',
            'name': 'AlphaCoin',
            'address': '0x138C2F1123cF3f82E4596d097c118eAc6684940B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x138c2f1123cf3f82e4596d097c118eac6684940b_1.png',
            'coingeckoId': 'alphacoin',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'YFDT',
            'name': 'Yearn Finance Diamond',
            'address': '0x1378eC93Ab2B07ba5A0eAEf19Cf354A33f64B9FD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12824/thumb/yfdt_logo.png?1602814305',
            'coingeckoId': 'yearn-finance-diamond-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLASH',
            'name': 'Flash Inu',
            'address': '0x13739cF9c9BC2fC1E06E74413c9C192757a65587',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24037/thumb/logo.png?1646118116',
            'coingeckoId': 'flash-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAX',
            'name': 'Max Bidding',
            'address': '0x1370E3B7Aa261dcbE19Ee24c456e56Ff186AEc85',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26936/thumb/BPIJ7HUN_400x400.jpg?1660901132',
            'coingeckoId': 'max-bidding',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CUT',
            'name': 'Ctrl X',
            'address': '0x135783B60cf5d71DAFF7a377f9eb7dB8D2dEAb9e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28068/thumb/logo.png?1667567710',
            'coingeckoId': 'ctrl-x',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'R3FI',
            'name': 'Recharge Finance',
            'address': '0x13572851103bEd49FF743AF4C4BB5ace88B22E2F',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14036/thumb/IMG_20210518_073652_114.png?1623833528',
            'coingeckoId': 'recharge-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NEC',
            'name': 'New era',
            'address': '0x1353a77abD236207D0588bCbBb52Bc3087f85351',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'MOM',
            'name': 'Monetum',
            'address': '0x1351986732367ff6B51784c6A75f63502dE13a9a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethereum-optimism.github.io/data/MOM/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'TRIPS',
            'name': 'Trips Community',
            'address': '0x1350Fbe8Ce27762ec19134bf8FC405a427fe9Bf1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14648/thumb/trips_32.png?1617491133',
            'coingeckoId': 'trips-community',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZOOT',
            'name': 'Zoo',
            'address': '0x1341A2257fa7b770420Ef70616f888056f90926c',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15629/thumb/gyzERsO.png?1621394316',
            'coingeckoId': 'zoo-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARNXM',
            'name': 'Armor NXM',
            'address': '0x1337DEF18C680aF1f9f45cBcab6309562975b1dD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x1337def18c680af1f9f45cbcab6309562975b1dd.png',
            'coingeckoId': 'armor-nxm',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ARMOR',
            'name': 'ARMOR',
            'address': '0x1337DEF16F9B486fAEd0293eb623Dc8395dFE46a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x1337def16f9b486faed0293eb623dc8395dfe46a.png',
            'coingeckoId': 'armor',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'CUR',
            'name': 'CurToken',
            'address': '0x13339fD07934CD674269726EdF3B5ccEE9DD93de',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x13339fd07934cd674269726edf3b5ccee9dd93de.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ARCX',
            'name': 'ARC Governance',
            'address': '0x1321f1f1aa541A56C31682c57b80ECfCCd9bB288',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xed30dd7e50edf3581ad970efc5d9379ce2614adb.png',
            'coingeckoId': 'arc-governance',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'IMT',
            'name': 'Money IMT',
            'address': '0x13119E34E140097a507B07a5564bDe1bC375D9e6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2955/thumb/imt.png?1547037191',
            'coingeckoId': 'moneytoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BIGSB',
            'name': 'BigShortBets',
            'address': '0x131157c6760f78f7dDF877C0019Eba175BA4b6F6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18235/thumb/bigsb-token-logo.png?1631065645',
            'coingeckoId': 'bigshortbets',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANG',
            'name': 'Aureus Nummus Gold',
            'address': '0x130914E1B240a7F4c5D460B7d3a2Fd3846b576fa',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12134/thumb/AureusNummusGold-logo.png?1597462266',
            'coingeckoId': 'aureus-nummus-gold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EC',
            'name': 'Echoin',
            'address': '0x12fD19DAC0Fab61bEd5e0F09091B470C452D4d61',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9354/thumb/L32_KzNQ_400x400.jpg?1566513096',
            'coingeckoId': 'echoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UDOO',
            'name': 'Hyprr',
            'address': '0x12f649A9E821F90BB143089a6e56846945892ffB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x12f649a9e821f90bb143089a6e56846945892ffb.png',
            'coingeckoId': 'howdoo',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'aYFIv1',
            'name': 'Aave Interest bearing YFI',
            'address': '0x12e51E77DAAA58aA0E9247db7510Ea4B46F9bEAd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x12e51e77daaa58aa0e9247db7510ea4b46f9bead.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'TAMA',
            'name': 'Tamadoge',
            'address': '0x12b6893cE26Ea6341919FE289212ef77e51688c8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27501/thumb/CaltNDWu_400x400.jpeg?1664329157',
            'coingeckoId': 'tamadoge',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'CRI',
            'name': 'Crypto International',
            'address': '0x12E951934246186F50146235d541D3bD1D463E4d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24700/thumb/logo200x200.png?1648641961',
            'coingeckoId': 'crypto-international',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GENART',
            'name': 'GENART',
            'address': '0x12E56851Ec22874520Dc4c7fa0A8a8d7DBa1BaC8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23055/thumb/token_logo.jpeg?1643151315',
            'coingeckoId': 'genart',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VOX',
            'name': 'Vox Finance',
            'address': '0x12D102F06da35cC0111EB58017fd2Cd28537d0e1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/7465.png',
            'coingeckoId': 'vox-finance',
            'listedIn': [
                'coingecko',
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'RACA',
            'name': 'Radio Caca',
            'address': '0x12BB890508c125661E03b09EC06E404bc9289040',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1638873863587_35780494681135644.png',
            'coingeckoId': 'radio-caca',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'SXDT',
            'name': 'Spectre ai Dividend',
            'address': '0x12B306fA98F4CbB8d4457FdFf3a0A0a56f07cCdf',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2550/thumb/spectre-dividend.png?1547036667',
            'coingeckoId': 'spectre-dividend-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GRID',
            'name': 'GridPlus  OLD ',
            'address': '0x12B19D3e2ccc14Da04FAe33e63652ce469b3F2FD',
            'decimals': 12,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x12b19d3e2ccc14da04fae33e63652ce469b3f2fd.png',
            'coingeckoId': 'grid',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'HISAND33',
            'name': 'hiSAND33',
            'address': '0x12AEf5C60C2C86c8ECD3079f22F285f326371340',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27006/thumb/logo.ab86781.png?1661337838',
            'coingeckoId': 'hisand33',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LADZ',
            'name': 'LADZ',
            'address': '0x1287c0509df9a475Ef178471aB2132b9dfD312B3',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13315/thumb/ladz_logo.jpg?1607408640',
            'coingeckoId': 'ladz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DEGEN',
            'name': 'DEGEN Index',
            'address': '0x126c121f99e1E211dF2e5f8De2d96Fa36647c855',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x126c121f99e1e211df2e5f8de2d96fa36647c855.png',
            'coingeckoId': 'degen-index',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'AME',
            'name': 'AME Chain',
            'address': '0x12513335ffD5DAfc2334e98625d27c1CA84bff86',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13539/thumb/amechain-logo-200x200.png?1646663912',
            'coingeckoId': 'amepay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CAT',
            'name': 'BitClave',
            'address': '0x1234567461d3f8Db7496581774Bd869C83D51c93',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x1234567461d3f8db7496581774bd869c83d51c93.png',
            'coingeckoId': 'bitclave',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'wDGLD',
            'name': 'wrapped-DGLD',
            'address': '0x123151402076fc819B7564510989e475c9cD93CA',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x123151402076fc819b7564510989e475c9cd93ca.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MOVD',
            'name': 'MOVE Network',
            'address': '0x12300ED54F65648524c1bFAf86712927dA6cC6fC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19236/thumb/MOVD_logo.png?1634772468',
            'coingeckoId': 'move-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RVST',
            'name': 'Revest Finance',
            'address': '0x120a3879da835A5aF037bB2d1456beBd6B54d4bA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18622/thumb/Qma94n6waADECpde1ukBBS8iNiECcdVcxjfgubnWPE9ZT7.png?1632701737',
            'coingeckoId': 'revest-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MX',
            'name': 'MX',
            'address': '0x11eeF04c884E24d9B7B4760e7476D06ddF797f36',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8545/thumb/TII1YIdv_400x400.png?1559180170',
            'coingeckoId': 'mx-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LNKO',
            'name': 'LNKO',
            'address': '0x11afE7Fa792589dd1236257f99ba09f510460Ad9',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9199/thumb/logo_%289%29.png?1565077907',
            'coingeckoId': 'lnko-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BRC',
            'name': 'BridgeCoin',
            'address': '0x11C49E5cA7222f89909A6Ec42D81Eb6b2Af5ff40',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27334/thumb/brc.png?1663481808',
            'coingeckoId': 'bridgecoin-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STR',
            'name': 'Starwire',
            'address': '0x11C1a6B3Ed6Bb362954b29d3183cfA97A0c806Aa',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18232/thumb/starwirelogo_%281%29.png?1631064145',
            'coingeckoId': 'starwire',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SFSHLD',
            'name': 'Safe Shield',
            'address': '0x11A605D7e12B64D713E93C487277d819A1d14B99',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17673/thumb/Safe-Shield-SFSHLD-200x200-logo.png?1628830115',
            'coingeckoId': 'safe-shield',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OG',
            'name': 'OriginDAO',
            'address': '0x11A0762da58a487c075249B9B9edF9f7eb1BC9f5',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27699/thumb/Origin.jpeg?1665303707',
            'coingeckoId': 'origindao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NYXT',
            'name': 'Nyx',
            'address': '0x118b552725e1892137740cB4d29390D952709639',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22174/thumb/NYX-logo-medium-size.png?1641174126',
            'coingeckoId': 'nyx-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BMAX',
            'name': 'BMAX',
            'address': '0x116c4B65e14449947bC6fa1bbe844CB16A162d53',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26427/thumb/BMAX_logo.png?1657929623',
            'coingeckoId': 'bmax',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CAPP',
            'name': 'Cappasity',
            'address': '0x11613b1f840bb5A40F8866d857e24DA126B79D73',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1302/thumb/cappasity.png?1547564648',
            'coingeckoId': 'cappasity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRE',
            'name': 'Carry',
            'address': '0x115eC79F1de567eC68B7AE7eDA501b406626478e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3994/thumb/Carry.png?1592402610',
            'coingeckoId': 'carry',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COOL',
            'name': 'COOL Vault  NFTX ',
            'address': '0x114f1388fAB456c4bA31B1850b244Eedcd024136',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17032/thumb/COOL.png?1626142075',
            'coingeckoId': 'cool-vault-nftx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CENNZ',
            'name': 'CENNZnet',
            'address': '0x1122B6a0E00DCe0563082b6e2953f3A943855c1F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3408/thumb/CENNZ_ticker.png?1650943548',
            'coingeckoId': 'centrality',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MODA',
            'name': 'MODA DAO',
            'address': '0x1117aC6Ad6Cdf1A3BC543baD3B133724620522d5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20870/thumb/ModaDAO__logomark-primary_3x.png?1637809145',
            'coingeckoId': 'moda-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ITR',
            'name': 'Intercoin',
            'address': '0x1111158f88410DA5F92c7E34c01e7B8649Bc0155',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20180/thumb/VU72TTZ3zN3w.png?1636604639',
            'coingeckoId': 'intercoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ICHI',
            'name': 'ICHI',
            'address': '0x111111517e4929D3dcbdfa7CCe55d30d4B6BC4d6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x111111517e4929d3dcbdfa7cce55d30d4b6bc4d6.png',
            'coingeckoId': 'ichi-farm',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': '1INCH',
            'name': '1inch',
            'address': '0x111111111117dC0aa78b770fA6A738034120C302',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x111111111117dc0aa78b770fa6a738034120c302.png',
            'coingeckoId': '1inch',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'TSLA',
            'name': 'Tessla Coin',
            'address': '0x110FE5f21C1EDd4F03a6e0DE0D96D0c2eED0B9CC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13045/thumb/Tessla-Icon.png?1604630729',
            'coingeckoId': 'tessla-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALCAZAR',
            'name': 'Alcazar',
            'address': '0x10f44a834097469AC340592d28c479c442E99bFe',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28010/thumb/Alcazar.jpeg?1666943350',
            'coingeckoId': 'alcazar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETHHEDGE',
            'name': '1X Short Ethereum Token',
            'address': '0x10e1E953DDBa597011f8bFA806aB0cC3415a622b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10220/thumb/683JEXMN_400x400_%281%29.png?1576577133',
            'coingeckoId': '1x-short-ethereum-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DPT',
            'name': 'Diamond Platform',
            'address': '0x10c71515602429C19d53011EA7040B87a4894838',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8309/thumb/RSCL14XL_400x400.jpg?1557400596',
            'coingeckoId': 'diamond-platform-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RAISE',
            'name': 'Raise',
            'address': '0x10bA8C420e912bF07BEdaC03Aa6908720db04e0c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4411/thumb/Raise.png?1590671180',
            'coingeckoId': 'hero-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UMX',
            'name': 'UniMex Network',
            'address': '0x10Be9a8dAe441d276a5027936c3aADEd2d82bC15',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x10be9a8dae441d276a5027936c3aaded2d82bc15.png',
            'coingeckoId': 'unimex-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'VRO',
            'name': 'VeraOne',
            'address': '0x10BC518c32fbAE5e38Ecb50A612160571bD81e44',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11112/thumb/wsBaVF.png?1588639964',
            'coingeckoId': 'veraone',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OH-GEEZ',
            'name': 'Oh..Geez',
            'address': '0x1098269bFc70b26DeA43f18F812D2b9854E874bA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x1098269bFc70b26DeA43f18F812D2b9854E874bA/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'ERZ',
            'name': 'Erzurumspor Token',
            'address': '0x1095D4a344a4760900071025d6103a17a361aBAD',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24809/thumb/17059.png?1649024114',
            'coingeckoId': 'erzurumspor-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TGT',
            'name': 'THORWallet DEX',
            'address': '0x108a850856Db3f85d0269a2693D896B394C80325',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x108a850856Db3f85d0269a2693D896B394C80325/logo.png',
            'coingeckoId': 'thorwallet',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'MTC',
            'name': 'Meta Cosmos',
            'address': '0x10864cD0C4250F7de15DD3Cd3788dc704cDb6706',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BLT',
            'name': 'Bloom',
            'address': '0x107c4504cd79C5d2696Ea0030a8dD4e92601B82e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2662/thumb/bloom.png?1547036854',
            'coingeckoId': 'bloom',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LABRA',
            'name': 'LabraCoin',
            'address': '0x106d3C66d22D2DD0446df23D7F5960752994d600',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15024/thumb/Labra_Coin.jpg?1622613634',
            'coingeckoId': 'labracoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KEANU',
            'name': 'Keanu Inu',
            'address': '0x106552C11272420aAd5d7e94f8AcAb9095A6c952',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x106552c11272420aad5d7e94f8acab9095a6c952.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'BAS',
            'name': 'Basis Share',
            'address': '0x106538CC16F938776c7c180186975BCA23875287',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x106538cc16f938776c7c180186975bca23875287.png',
            'coingeckoId': 'basis-share',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'DVI',
            'name': 'Dvision Network',
            'address': '0x10633216E7E8281e33c86F02Bf8e565a635D9770',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x10633216e7e8281e33c86f02bf8e565a635d9770.png',
            'coingeckoId': 'dvision-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'PORTX',
            'name': 'ChainPort',
            'address': '0x104F3152D8ebFC3f679392977356962Ff36566aC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24490/thumb/VE-tUL-q_400x400.png?1647855096',
            'coingeckoId': 'chainport',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DFIAT',
            'name': 'DeFiato',
            'address': '0x1045F5cCb01DAEA4f8eaB055F5FcBB7C0E7c89F0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13386/thumb/Defiato.png?1608085873',
            'coingeckoId': 'defiato',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GVT',
            'name': 'Genesis Vision',
            'address': '0x103c3A209da59d3E7C4A89307e66521e081CFDF0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1173/thumb/Genesis-vision.png?1558045005',
            'coingeckoId': 'genesis-vision',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LIZ',
            'name': 'Theranos Coin',
            'address': '0x102e941B77Bcaa7e35D368CaFE51eF8F79c8d1Ef',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethereum-optimism.github.io/data/LIZ/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'SMTX',
            'name': 'SUMOTEX',
            'address': '0x102203517ce35AC5Cab9a2cda80DF03f26c7419b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27478/thumb/Sumotex_telegram_image_PNG.jpg?1664198249',
            'coingeckoId': 'sumotex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATUSD',
            'name': 'Aave TUSD',
            'address': '0x101cc05f4A51C0319f570d5E146a8C625198e636',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x101cc05f4a51c0319f570d5e146a8c625198e636.png',
            'coingeckoId': 'aave-tusd',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BCV',
            'name': 'BitCapitalVendor',
            'address': '0x1014613E2B3CBc4d575054D4982E580d9b99d7B1',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5326/thumb/hqQw2WGE_400x400.jpg?1547040893',
            'coingeckoId': 'bcv',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TMTG',
            'name': 'The Midas Touch Gold',
            'address': '0x10086399DD8c1e3De736724AF52587a2044c9fA2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6255/thumb/the_midas_touch_gold.png?1547042292',
            'coingeckoId': 'the-midas-touch-gold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HND',
            'name': 'Hundred Finance',
            'address': '0x10010078a54396F62c96dF8532dc2B4847d47ED3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x10010078a54396F62c96dF8532dc2B4847d47ED3/logo.png',
            'coingeckoId': 'hundred-finance',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'RDPX',
            'name': 'Dopex Rebate',
            'address': '0x0ff5A8451A839f5F0BB3562689D9A44089738D11',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/12057.png',
            'coingeckoId': 'dopex-rebate-token',
            'listedIn': [
                'coingecko',
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'YFPRO',
            'name': 'YFPRO Finance',
            'address': '0x0fdC5313333533cC0c00C22792BfF7383d3055F2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12712/thumb/STOV7niY_400x400.png?1602630888',
            'coingeckoId': 'yfpro-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DNC',
            'name': 'Danat Coin',
            'address': '0x0fbe9Cc858D7AD6e246Fe9D01Aed22abD2A66f0B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13170/thumb/1528717728file.png?1605855085',
            'coingeckoId': 'danat-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LAYER',
            'name': 'UniLayer',
            'address': '0x0fF6ffcFDa92c53F615a4A75D982f399C989366b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0ff6ffcfda92c53f615a4a75d982f399c989366b.png',
            'coingeckoId': 'unilayer',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SKC',
            'name': 'SKINCHAIN',
            'address': '0x0fE156436F203B114C6c562Cb1a2A81aa2801090',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10878/thumb/8ZtRME2h_400x400.jpg?1585472542',
            'coingeckoId': 'skinchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SHOE',
            'name': 'ShoeFy',
            'address': '0x0fD67B4ceb9b607Ef206904eC73459c4880132c9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19082/thumb/SHOEFY.jpg?1634371392',
            'coingeckoId': 'shoefy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XMX',
            'name': 'XMax',
            'address': '0x0f8c45B896784A1E408526B9300519ef8660209c',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4643/thumb/xmx.png?1574682262',
            'coingeckoId': 'xmax',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XIO',
            'name': 'Blockzero Labs',
            'address': '0x0f7F961648aE6Db43C75663aC7E5414Eb79b5704',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0f7f961648ae6db43c75663ac7e5414eb79b5704.png',
            'coingeckoId': 'xio',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ARCONA',
            'name': 'Arcona',
            'address': '0x0f71B8De197A1C84d31de0F1fA7926c365F052B3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0f71b8de197a1c84d31de0f1fa7926c365f052b3.png',
            'coingeckoId': 'arcona',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'DAO',
            'name': 'DAO Maker',
            'address': '0x0f51bb10119727a7e5eA3538074fb341F56B09Ad',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0f51bb10119727a7e5ea3538074fb341f56b09ad.png',
            'coingeckoId': 'dao-maker',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SYN',
            'name': 'Synapse',
            'address': '0x0f2D719407FdBeFF09D87557AbB7232601FD9F29',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0f2d719407fdbeff09d87557abb7232601fd9f29.png',
            'coingeckoId': 'synapse-2',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'JEUR',
            'name': 'Jarvis Synthetic Euro',
            'address': '0x0f17BC9a994b87b5225cFb6a2Cd4D667ADb4F20B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/jeur.jpg',
            'coingeckoId': 'jarvis-synthetic-euro',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ATC',
            'name': 'Aster',
            'address': '0x0eb3032bcAc2BE1fa95E296442F225edb80fc3CD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17325/thumb/atc.png?1627311439',
            'coingeckoId': 'aster',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WTN',
            'name': 'Wale',
            'address': '0x0ea984e789302B7B612147E4e4144e64f21425Eb',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6404/thumb/waletoken_2_sosmed_Square.png?1547042539',
            'coingeckoId': 'waletoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AAMMUNICRVWETH',
            'name': 'Aave AMM UniCRVWETH',
            'address': '0x0ea20e7fFB006d4Cfe84df2F72d8c7bD89247DB0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17220/thumb/aAmmUniCRVWETH.png?1626879022',
            'coingeckoId': 'aave-amm-unicrvweth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNIFI',
            'name': 'UNIFI DeFi',
            'address': '0x0eF3b2024ae079e6dBC2b37435cE30d2731F0101',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12533/thumb/unifi_defi_logo.jpg?1600531278',
            'coingeckoId': 'unifi-defi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STRM',
            'name': 'Instrumental Finance',
            'address': '0x0eDF9bc41Bbc1354c70e2107F80C42caE7FBBcA8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21407/thumb/HRZ0RUOh_400x400.jpg?1639088027',
            'coingeckoId': 'instrumental-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MDZA',
            'name': 'Medooza Ecosystem',
            'address': '0x0eCDd783dc7bF820614044B51862ED29714d2BA5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13448/thumb/medooza-red-symbol-logo-exchange.png?1608681531',
            'coingeckoId': 'medooza-ecosystem',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BPT',
            'name': 'BlackPool',
            'address': '0x0eC9F76202a7061eB9b3a7D6B59D36215A7e37da',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x0eC9F76202a7061eB9b3a7D6B59D36215A7e37da/logo.png',
            'coingeckoId': 'blackpool-token',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'ZERO',
            'name': 'Zero Tech',
            'address': '0x0eC78ED49C2D27b315D462d43B5BAB94d2C79bf8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21530/thumb/UlyJW87D_400x400.jpg?1639384115',
            'coingeckoId': 'zero-tech',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MFB',
            'name': 'Mirrored Facebook',
            'address': '0x0e99cC0535BB6251F6679Fa6E65d6d3b430e840B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/mfb.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'STREAMERINU',
            'name': 'Streamer Inu',
            'address': '0x0e8D2EB7D6bDF28393c25a1966385Ad32Ff0259a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27994/thumb/logo.png?1666922914',
            'coingeckoId': 'streamer-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LAND',
            'name': 'LandBox',
            'address': '0x0e2ef8AeCB3c01Ad5D596f1B67134e178199984D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14875/thumb/jmKvZDn7_400x400.jpg?1618826129',
            'coingeckoId': 'new-landbox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POE',
            'name': 'Po et',
            'address': '0x0e0989b1f9B8A38983c2BA8053269Ca62Ec9B195',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/910/thumb/poet.png?1548331583',
            'coingeckoId': 'poet',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METACAT',
            'name': 'MetaCat',
            'address': '0x0dC7d0192c148d7d2D6fa32DC280f953c0AD6A34',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x0dC7d0192c148d7d2D6fa32DC280f953c0AD6A34/logo.png',
            'coingeckoId': 'metacat',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'AVT',
            'name': 'Aventus',
            'address': '0x0d88eD6E74bbFD96B831231638b66C05571e824F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/901/thumb/Aventus.png?1625122968',
            'coingeckoId': 'aventus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAXI',
            'name': 'Maximus DAO',
            'address': '0x0d86EB9f43C57f6FF3BC9E23D8F9d82503f0e84b',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25126/thumb/maxilogo200.png?1650372648',
            'coingeckoId': 'maximus-dao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WNXM',
            'name': 'Wrapped NXM',
            'address': '0x0d438F3b5175Bebc262bF23753C1E53d03432bDE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0d438f3b5175bebc262bf23753c1e53d03432bde.png',
            'coingeckoId': 'wrapped-nxm',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'BEND',
            'name': 'BendDAO',
            'address': '0x0d02755a5700414B26FF040e1dE35D337DF56218',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22829/thumb/benddao.PNG?1642664553',
            'coingeckoId': 'benddao',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDTBEAR',
            'name': '3X Short Tether Token',
            'address': '0x0cd6c8161f1638485A1A2F5Bf1A0127E45913C2F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10307/thumb/683JEXMN_400x400.png?1577743905',
            'coingeckoId': '3x-short-tether-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DFM',
            'name': 'DeFi on MCW',
            'address': '0x0ccD5DD52Dee42B171a623478e5261C1eaaE092A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13831/thumb/logo_200x200_%281%29.png?1612168790',
            'coingeckoId': 'defi-on-mcw',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'THE',
            'name': 'The Protocol',
            'address': '0x0cbA60Ca5eF4D42f92A5070A8fEDD13BE93E2861',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27849/thumb/cSar5sDM_400x400.jpg?1666085191',
            'coingeckoId': 'the-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MAMZN',
            'name': 'Mirrored Amazon',
            'address': '0x0cae9e4d663793c2a2A0b211c1Cf4bBca2B9cAa7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/mamzn.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'POOL',
            'name': 'PoolTogether',
            'address': '0x0cEC1A9154Ff802e7934Fc916Ed7Ca50bDE6844e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0cec1a9154ff802e7934fc916ed7ca50bde6844e.png',
            'coingeckoId': 'pooltogether',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'EXN',
            'name': 'Exeno Coin',
            'address': '0x0c9b3aB1bd0CF0745625381F5C3Aa1CD9BBc7Abb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25845/thumb/20323.png?1654145185',
            'coingeckoId': 'exeno',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HGOLD',
            'name': 'HollyGold',
            'address': '0x0c93B616933b0CD03B201b29Cd8a22681DD9E0d9',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13652/thumb/QnfGCea3aO_Qb-Gd9n6MSFE_eBVAr87WPoxAzf0gKdcGOEL4K5J6A5cpGinzLvHclj_UHkCnw9XTlGoNYd0H8T_Ebe8voLS49MAZO2NIknCxytaYFtt5_u12RQg-kpmWHcETzAFlDgk9iNXAL-88fB_l-DRi8WEsgSJzXXjztKzQlpXuET6d4_98pykED6uyq1-4PMVg05P7tUN.jpg?1610574345',
            'coingeckoId': 'hollygold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HAN',
            'name': 'HanChain',
            'address': '0x0c90C57aaf95A3A87eadda6ec3974c99D786511F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethereum-optimism.github.io/data/HAN/logo.svg',
            'coingeckoId': 'hanchain',
            'listedIn': [
                'coingecko',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'BFC',
            'name': 'Bifrost',
            'address': '0x0c7D5ae016f806603CB1782bEa29AC69471CAb9c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0c7d5ae016f806603cb1782bea29ac69471cab9c.png',
            'coingeckoId': 'bifrost',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'WAS',
            'name': 'Wasder',
            'address': '0x0c572544a4Ee47904d54aaA6A970AF96B6f00E1b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15374/thumb/wasderBoxedLogoWhite-200x200.png?1659095342',
            'coingeckoId': 'wasder',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'miniSAITAMA',
            'name': 'mini SAITAMA',
            'address': '0x0c3685559Af6F3d20C501b1076A8056A0A14426a',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0c3685559af6f3d20c501b1076a8056a0a14426a.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'YFI',
            'name': 'yearn finance',
            'address': '0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e.png',
            'coingeckoId': 'yearn-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SWFTC',
            'name': 'SWFTCOIN',
            'address': '0x0bb217E40F8a5Cb79Adf04E1aAb60E5abd0dfC1e',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2346/thumb/SWFTCoin.jpg?1618392022',
            'coingeckoId': 'swftcoin',
            'listedIn': [
                'coingecko',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'WEAPON',
            'name': 'Megaweapon',
            'address': '0x0b8057C3Cc676C329c25C1d0Cd27776efA73762d',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20833/thumb/weapon.png?1659792713',
            'coingeckoId': 'megaweapon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WLITI',
            'name': 'wLITI',
            'address': '0x0b63128C40737B13647552e0C926bCFEccC35f93',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16810/thumb/liticapital_real.png?1625151260',
            'coingeckoId': 'wliti',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KUNO',
            'name': 'KunoichiX',
            'address': '0x0b5ECBb411d8FE829e5eAc253EE1F2Dc05D8d1Ae',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21847/thumb/94E3B862-B2ED-4D30-A1E4-1AFB560835F5.png?1640131932',
            'coingeckoId': 'kunoichix',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GENCAP',
            'name': 'GenCoin Capital',
            'address': '0x0b569fA433Faa7f01f3ea880193dE38044B41DE0',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22282/thumb/200x200_logo.png?1641364979',
            'coingeckoId': 'gencoin-capital',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETHO',
            'name': 'Etho Protocol',
            'address': '0x0b5326Da634f9270FB84481DD6F94d3dC2cA7096',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5194/thumb/ether1new-transparent.png?1578298993',
            'coingeckoId': 'ether-1',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'API3',
            'name': 'API3',
            'address': '0x0b38210ea11411557c13457D4dA7dC6ea731B88a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0b38210ea11411557c13457d4da7dc6ea731b88a.png',
            'coingeckoId': 'api3',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'AGVE',
            'name': 'Agave',
            'address': '0x0b006E475620Af076915257C6A9E40635AbdBBAd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'YAXIS',
            'name': 'yAxis',
            'address': '0x0adA190c81b814548ddC2F6AdC4a689ce7C1FE73',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0ada190c81b814548ddc2f6adc4a689ce7c1fe73.png',
            'coingeckoId': 'yaxis',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ILSI',
            'name': 'Invest Like Stakeborg Index',
            'address': '0x0acC0FEE1D86D2cD5AF372615bf59b298D50cd69',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21788/thumb/16292.png?1640048590',
            'coingeckoId': 'invest-like-stakeborg-index',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOHM',
            'name': 'Governance OHM',
            'address': '0x0ab87046fBb341D058F17CBC4c1133F25a20a52f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0ab87046fbb341d058f17cbc4c1133f25a20a52f.png',
            'coingeckoId': 'governance-ohm',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MOMENTO',
            'name': 'Momento',
            'address': '0x0aE8B74Cd2D566853715800c9927f879d6B76A37',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18532/thumb/momento.png?1632295065',
            'coingeckoId': 'momento',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HBT',
            'name': 'Habitat',
            'address': '0x0aCe32f6E87Ac1457A5385f8eb0208F37263B415',
            'decimals': 10,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0ace32f6e87ac1457a5385f8eb0208f37263b415.png',
            'coingeckoId': 'habitat',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'OJA',
            'name': 'Ojamu',
            'address': '0x0aA7eFE4945Db24d95cA6E117BBa65Ed326e291A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18947/thumb/ojamu-icon-PNK.png?1634006741',
            'coingeckoId': 'ojamu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RES',
            'name': 'Resfinex Token',
            'address': '0x0a9f693FcE6F00A51A8e0db4351B5a8078B4242e',
            'decimals': 5,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10026/thumb/logo_%281%29.png?1588935633',
            'coingeckoId': 'resfinex-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CHP',
            'name': 'CoinPoker',
            'address': '0x0a6E18fB2842855C3AF925310B0F50a4BfA17909',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1808/thumb/coinpoker.jpg?1547036113',
            'coingeckoId': 'coinpoker',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USX',
            'name': 'dForce USD',
            'address': '0x0a5E677a6A24b2F1A2Bf4F3bFfC443231d2fDEc8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/13080.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'SQUAWK',
            'name': 'Squawk',
            'address': '0x0a58531518DbA2009BdfBf1AF79602bfD312FdF1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25850/thumb/SYp95hCy_400x400.jpg?1654150818',
            'coingeckoId': 'squawk-2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LGO',
            'name': 'LGO',
            'address': '0x0a50C93c762fDD6E56D86215C24AaAD43aB629aa',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2353/thumb/2_JNnfVRPMBuA1hwnRubH72A.png?1595311622',
            'coingeckoId': 'legolas-exchange',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NASH',
            'name': 'NeoWorld Cash',
            'address': '0x0a255F700B16c0acF5673565C757b94fb38B27F2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6737/thumb/D7F231BA7E294954A0C2C4EBA6BCEFEB.png?1547042977',
            'coingeckoId': 'neoworld-cash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ERP',
            'name': 'Entropyfi',
            'address': '0x0a0e3bfD5a8cE610E735D4469Bc1b3b130402267',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19633/small/erp.PNG?1635715161',
            'coingeckoId': 'entropyfi',
            'listedIn': [
                'coingecko',
                'xyfinance'
            ]
        },
        {
            'symbol': 'ETHMAXY',
            'name': 'ETH Max Yield Index',
            'address': '0x0FE20E0Fa9C78278702B05c333Cc000034bb69E2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24047/thumb/ethmaxy.png?1646194193',
            'coingeckoId': 'eth-max-yield-index',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NOA',
            'name': 'NOA PLAY',
            'address': '0x0FD3822072ad001aac1c90A09d9506f097F24458',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15723/thumb/XHFjmBTx_400x400.png?1621635644',
            'coingeckoId': 'noa-play',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PUNDIX',
            'name': 'Pundi X',
            'address': '0x0FD10b9899882a6f2fcb5c371E17e70FdEe00C38',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14571/small/vDyefsXq_400x400.jpg?1617085003',
            'coingeckoId': 'pundi-x-2',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'QTF',
            'name': 'Quantfury',
            'address': '0x0FCbC31c503b4A9eD90E87f8Ff46C318a4a14260',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14033/thumb/FtSOX9Vy_400x400.jpg?1613946524',
            'coingeckoId': 'quantfury',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ENTS',
            'name': 'EUNOMIA',
            'address': '0x0F612a09eAd55Bb81b6534e80ed5A21Bf0a27B16',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6524/thumb/eunomia.png?1548125623',
            'coingeckoId': 'eunomia',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MANA',
            'name': 'Decentraland',
            'address': '0x0F5D2fB29fb7d3CFeE444a200298f468908cC942',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0f5d2fb29fb7d3cfee444a200298f468908cc942.png',
            'coingeckoId': 'decentraland',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'XPOP',
            'name': 'XPOP',
            'address': '0x0F3a02BaEC9607f2b52B3ab629CBCEa2047E1124',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26251/thumb/%EC%9E%90%EC%82%B0_1.png?1656936169',
            'coingeckoId': 'xpop',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANC',
            'name': 'Anchor Protocol',
            'address': '0x0F3ADC247E91c3c50bC08721355A41037E89Bc20',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14420/thumb/anchor_protocol_logo.jpg?1615965420',
            'coingeckoId': 'anchor-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SEDO',
            'name': 'SEDO POW',
            'address': '0x0F00f1696218EaeFa2D2330Df3D6D1f94813b38f',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6866/thumb/sedocoin-logo.jpg?1547043199',
            'coingeckoId': 'sedo-pow-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'wsHUMP',
            'name': 'Wrapped sHUMP',
            'address': '0x0EfA5328FEfCe96C8d10661bd97403764D477853',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'GOLD1',
            'name': 'Teh Golden One',
            'address': '0x0Ec72CD6690db40B16be166858299F19D4f8E5B0',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27290/thumb/teh_golden_one_200x200.png?1663147215',
            'coingeckoId': 'teh-golden-one',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CPAY',
            'name': 'Cryptopay',
            'address': '0x0Ebb614204E47c09B6C3FeB9AAeCad8EE060E23E',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2216/thumb/cryptopay.png?1547036499',
            'coingeckoId': 'cryptopay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NIL',
            'name': 'NIL',
            'address': '0x0Eb638648207D00b9025684d13B1cB53806deBe4',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21612/thumb/nil.PNG?1639608851',
            'coingeckoId': 'nil-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NBLH',
            'name': 'NBLH',
            'address': '0x0ECC0E963625e0c5504718ec460116176b3Aa06C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27077/thumb/nblh.png?1661756906',
            'coingeckoId': 'nblh',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TNODE',
            'name': 'Trusted Node',
            'address': '0x0E95B13539D0381AB20B4E2893E926Fc99b3d8Dc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ABYSS',
            'name': 'Abyss',
            'address': '0x0E8d6b471e332F140e7d9dbB99E5E3822F728DA6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0e8d6b471e332f140e7d9dbb99e5e3822f728da6.png',
            'coingeckoId': 'the-abyss',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'CNTM',
            'name': 'Connectome',
            'address': '0x0E5f00DA8AAef196a719d045DB89b5DA8F371b32',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8528/thumb/200_200_CNTM-LOGO-07.png?1600751947',
            'coingeckoId': 'connectome',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RPG',
            'name': 'Rangers Protocol',
            'address': '0x0E5C8C387C5EBa2eCbc137aD012aeD5Fe729e251',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18791/thumb/tO8MlqiM_400x400.png?1633421196',
            'coingeckoId': 'rangers-protocol-gas',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'RELI',
            'name': 'Relite Finance',
            'address': '0x0E58ED58E150dba5fd8e5D4A49F54C7e1E880124',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14906/thumb/xPZRuspj_400x400.jpg?1618983798',
            'coingeckoId': 'relite-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HAKKA',
            'name': 'Hakka Finance',
            'address': '0x0E29e5AbbB5FD88e28b2d355774e73BD47dE3bcd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0e29e5abbb5fd88e28b2d355774e73bd47de3bcd.png',
            'coingeckoId': 'hakka-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'STM',
            'name': 'Streamity',
            'address': '0x0E22734e078d6e399BCeE40a549DB591C4EA46cB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x0e22734e078d6e399bcee40a549db591c4ea46cb.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'HXN',
            'name': 'Havens Nook',
            'address': '0x0E1fe60bc4aC0e3102343752ae7E49d01d444c0b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14876/thumb/havennook.PNG?1618881827',
            'coingeckoId': 'havens-nook',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FST',
            'name': 'Futureswap',
            'address': '0x0E192d382a36De7011F795Acc4391Cd302003606',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/8961.png',
            'coingeckoId': 'futureswap',
            'listedIn': [
                'coingecko',
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'CLIQ',
            'name': 'DefiCliq',
            'address': '0x0Def8d8addE14c9eF7c2a986dF3eA4Bd65826767',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13242/thumb/deficliq_logo.png?1606660146',
            'coingeckoId': 'deficliq',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'OOKI',
            'name': 'Ooki',
            'address': '0x0De05F6447ab4D22c8827449EE4bA2D5C288379B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0de05f6447ab4d22c8827449ee4ba2d5c288379b.png',
            'coingeckoId': 'ooki',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SOTA',
            'name': 'SOTA Finance',
            'address': '0x0DDe6F6e345bfd23f3F419F0DFe04E93143b44FB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14067/thumb/sota_logo.png?1614132527',
            'coingeckoId': 'sota-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASG',
            'name': 'Asgard',
            'address': '0x0DC5189Ec8CDe5732a01F0F592e927B304370551',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x0DC5189Ec8CDe5732a01F0F592e927B304370551/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'IQN',
            'name': 'IQeon',
            'address': '0x0DB8D8b76BC361bAcbB72E2C491E06085A97Ab31',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1736/thumb/IQN_logo_64x64.png?1547036010',
            'coingeckoId': 'iqeon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PONY',
            'name': 'PONY Index',
            'address': '0x0D97Fee619d955509e54B046c9992B6E9F5B0630',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0d97fee619d955509e54b046c9992b6e9f5b0630.png',
            'coingeckoId': 'pony-index',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BAT',
            'name': 'Basic Attention',
            'address': '0x0D8775F648430679A709E98d2b0Cb6250d2887EF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0d8775f648430679a709e98d2b0cb6250d2887ef.png',
            'coingeckoId': 'basic-attention-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HTBULL',
            'name': '3X Long Huobi Token Token',
            'address': '0x0D5E2681D2AaDC91F7DA4146740180A2190f0c79',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10239/thumb/683JEXMN_400x400_%281%29.png?1576633312',
            'coingeckoId': '3x-long-huobi-token-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SONO',
            'name': 'SonoCoin',
            'address': '0x0D15009896EfE9972F8E086bdD3BcBa5c1f74bF3',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7230/thumb/MeWTs09-_400x400.jpg?1582700314',
            'coingeckoId': 'sonocoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XDATA',
            'name': 'Streamr XDATA',
            'address': '0x0Cf0Ee63788A0849fE5297F3407f701E122cC023',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x0cf0ee63788a0849fe5297f3407f701e122cc023.png',
            'coingeckoId': 'streamr-xdata',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'DCNT',
            'name': 'Decenturion',
            'address': '0x0Ce6d5a093d4166237C7A9ff8E0553B0293214a1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6195/thumb/X5_20dt1_400x400.jpg?1547042224',
            'coingeckoId': 'decenturion',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INNBCL',
            'name': 'InnovativeBioresearchClassic',
            'address': '0x0Cc9FCCFF81252F4bd8C5c6b359B14ae2Ed851cf',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7911/thumb/INNBCL.png?1551777826',
            'coingeckoId': 'innovativebioresearchclassic',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MINT',
            'name': 'Public Mint',
            'address': '0x0CDF9acd87E940837ff21BB40c9fd55F68bba059',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0cdf9acd87e940837ff21bb40c9fd55f68bba059.png',
            'coingeckoId': 'public-mint',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'WOMBAT',
            'name': 'Wombat',
            'address': '0x0C9c7712C83B3C70e7c5E11100D33D9401BdF9dd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26430/thumb/Project_Page_Icon.png?1657930951',
            'coingeckoId': 'wombat',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WHIRL',
            'name': 'OmniWhirl',
            'address': '0x0C75dD36aF9a59BA1d248a98Fe91b2384cfea9be',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17165/thumb/logo-200x.png?1626683241',
            'coingeckoId': 'omniwhirl',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'NODE',
            'name': 'Whole Network',
            'address': '0x0C3eF32f802967DB75B9D49fE1e76620151cCB81',
            'decimals': 5,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9522/thumb/61410314_394870017826322_8137973916426567680_n.png?1568604555',
            'coingeckoId': 'whole-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARNX',
            'name': 'Aeron',
            'address': '0x0C37Bcf456bC661C14D596683325623076D7e283',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1124/thumb/ARNX-token-logo-256x256.png?1602652111',
            'coingeckoId': 'aeron',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLOKICHAIN',
            'name': 'Floki Chain',
            'address': '0x0C23A0CE478ada00dF4E3C951b8FA6fFe7f1C56b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27180/thumb/photo_2022-09-05_07-15-54.jpg?1662426957',
            'coingeckoId': 'floki-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDD',
            'name': 'USDD',
            'address': '0x0C10bF8FcB7Bf5412187A595ab97a3609160b5c6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0c10bf8fcb7bf5412187a595ab97a3609160b5c6.png',
            'coingeckoId': 'usdd',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'UND',
            'name': 'Unbound Dollar',
            'address': '0x0C0F2b41F758d66bB8e694693B0f9e6FaE726499',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/7848.png',
            'coingeckoId': null,
            'listedIn': [
                'dfyn'
            ]
        },
        {
            'symbol': 'MYT',
            'name': 'Mytrade',
            'address': '0x0BF0D26A527384bcc4072A6e2bcA3fc79e49fa2d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24134/thumb/transparent_logo.png?1646543530',
            'coingeckoId': 'mytrade',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AGFI',
            'name': 'AggregatedFinance',
            'address': '0x0BE4447860DdF283884BBaa3702749706750b09e',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://api.rubic.exchange/assets/ethereum/0x0be4447860ddf283884bbaa3702749706750b09e/logo.png',
            'coingeckoId': 'aggregatedfinance',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'WAD',
            'name': 'WardenSwap',
            'address': '0x0B6F3c17e1626a7cBfA4302CE4E3c45522d23A83',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethereum-optimism.github.io/data/WAD/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'CLM',
            'name': 'CoinClaim',
            'address': '0x0B4C2708F052dca413600e237675e4d6778A9375',
            'decimals': 16,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6687/thumb/coinclaim.jpg?1547042891',
            'coingeckoId': 'coinclaim',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DAX',
            'name': 'DAEX',
            'address': '0x0B4BdC478791897274652DC15eF5C135cae61E60',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4063/thumb/daex.png?1547791412',
            'coingeckoId': 'daex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTC2X-FLI',
            'name': 'BTC 2x Flexible Leverage Index',
            'address': '0x0B498ff89709d3838a063f1dFA463091F9801c2b',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0b498ff89709d3838a063f1dfa463091f9801c2b.png',
            'coingeckoId': 'btc-2x-flexible-leverage-index',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'BST',
            'name': 'Beshare',
            'address': '0x0B4680e848f842b86E4Df38D9FA0d9f9419F37EC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16870/thumb/BST_symbol_transparent_background_200.png?1625476744',
            'coingeckoId': 'beshare-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SUZUME',
            'name': 'Shita kiri Suzume',
            'address': '0x0B452278223D3954F4AC050949D7998e373e7E43',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26813/thumb/shitakiri_cmc.png?1660275049',
            'coingeckoId': 'shita-kiri-suzume',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DC',
            'name': 'DATACHAIN FOUNDATION',
            'address': '0x0B44547be0A0Df5dCd5327de8EA73680517c5a54',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24783/thumb/dc.png?1648903780',
            'coingeckoId': 'datachain-foundation',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ONES',
            'name': 'OneSwap DAO',
            'address': '0x0B342C51d1592C41068d5D4b4DA4A68C0a04d5A4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12552/thumb/logo.png?1600682344',
            'coingeckoId': 'oneswap-dao-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PLBT',
            'name': 'Polybius',
            'address': '0x0AfFa06e7Fbe5bC9a764C979aA66E8256A631f02',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/766/thumb/polybius.png?1547034516',
            'coingeckoId': 'polybius',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SLICE',
            'name': 'Tranche Finance',
            'address': '0x0AeE8703D34DD9aE107386d3eFF22AE75Dd616D1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14119/thumb/slice.png?1635826973',
            'coingeckoId': 'tranche-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STAKE',
            'name': 'STAKE',
            'address': '0x0Ae055097C6d159879521C384F1D2123D1f195e6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0ae055097c6d159879521c384f1d2123d1f195e6.png',
            'coingeckoId': 'xdai-stake',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'DNT',
            'name': 'district0x',
            'address': '0x0AbdAce70D3790235af448C88547603b945604ea',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0abdace70d3790235af448c88547603b945604ea.png',
            'coingeckoId': 'district0x',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'YAM',
            'name': 'YAM',
            'address': '0x0AaCfbeC6a24756c20D41914F2caba817C0d8521',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0aacfbec6a24756c20d41914f2caba817c0d8521.png',
            'coingeckoId': 'yam-2',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'UAC',
            'name': 'Ulanco',
            'address': '0x0AF5855a348C58a8BbF65BCc7B9BBaCD0e7e2238',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20662/thumb/udacity_logo_icon_169367.png?1637545628',
            'coingeckoId': 'ulanco',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSL',
            'name': 'BankSocial',
            'address': '0x0AF55d5fF28A3269d69B98680Fd034f115dd53Ac',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0af55d5ff28a3269d69b98680fd034f115dd53ac.png',
            'coingeckoId': 'banksocial',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'MTX',
            'name': 'MATRYX',
            'address': '0x0AF44e2784637218dD1D32A322D44e603A8f0c6A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1495/thumb/matryx.png?1547978542',
            'coingeckoId': 'matryx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KVERSE',
            'name': 'KEEPs Coin',
            'address': '0x0AB39AC604f992aAeC3C36dE337c3CD3917a7d26',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19622/thumb/keeps.PNG?1635498049',
            'coingeckoId': 'keeps-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SFT',
            'name': 'Scorefam',
            'address': '0x0AAbCC65Ef352AD84b1326dF188c95b6Ab856c1C',
            'decimals': 8,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DOS',
            'name': 'DOS Network',
            'address': '0x0A913beaD80F321E7Ac35285Ee10d9d922659cB7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x0a913bead80f321e7ac35285ee10d9d922659cb7.png',
            'coingeckoId': 'dos-network',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'WAG8',
            'name': 'Wrapped ATROMG8',
            'address': '0x0A8b16b27D5219c8C6b57D5442CE31D81573EeE4',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15182/thumb/ag8.jpg?1620031331',
            'coingeckoId': 'wrapped-atromg8',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELITE',
            'name': 'Ethereum Lite',
            'address': '0x0A76aad21948eA1ef447D26DEe91a54370E151e0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1062/thumb/elite.png?1547035007',
            'coingeckoId': 'ethereum-lite',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SYC',
            'name': 'YCLUB',
            'address': '0x0A41E6d4D4897E8Cec819ce7D10B2D7cDa61Dc94',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28267/thumb/9DC88DDF-CAB3-410A-87BA-6AB98DCD1B86.jpeg?1668771177',
            'coingeckoId': 'yclub',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IUSD',
            'name': 'iZUMi Bond USD',
            'address': '0x0A3BB08b3a15A19b4De82F8AcFc862606FB69A2D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25388/thumb/iusd-logo-symbol-10k%E5%A4%A7%E5%B0%8F.png?1651660620',
            'coingeckoId': 'izumi-bond-usd',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'GLEX',
            'name': 'GLEX',
            'address': '0x0A0DB74Ef8b4480cc29b7D68647727fEeB1ea4eC',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11609/thumb/1578901576974.png?1591777246',
            'coingeckoId': 'glex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WCK',
            'name': 'Wrapped CryptoKitties',
            'address': '0x09fE5f0236F0Ea5D930197DCE254d77B04128075',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8797/thumb/WCK.png?1561705836',
            'coingeckoId': 'wrapped-cryptokitties',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DFL',
            'name': 'DeFIL',
            'address': '0x09ce2B746C32528B7d864A1e3979Bd97d2f095AB',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17708/thumb/defillogo200200.png?1629080381',
            'coingeckoId': 'defil',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIR',
            'name': 'Mirror Protocol',
            'address': '0x09a3EcAFa817268f77BE1283176B946C4ff2E608',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x09a3ecafa817268f77be1283176b946c4ff2e608.png',
            'coingeckoId': 'mirror-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ZOO',
            'name': 'ZooDAO',
            'address': '0x09F098B155D561Fc9F7BcCc97038b7e3d20bAF74',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24305/thumb/Zt2BM_8D_400x400.jpg?1647305115',
            'coingeckoId': 'zoodao',
            'listedIn': [
                'coingecko',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'LEVL',
            'name': 'Levolution',
            'address': '0x09970aec766b6f3223aCA9111555E99DC50Ff13a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7370/thumb/L_Icon_Blue_200x200.png?1618983083',
            'coingeckoId': 'levolution',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ULT',
            'name': 'Shardus',
            'address': '0x09617F6fD6cF8A71278ec86e23bBab29C04353a7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8383/thumb/final_logo_photoshop.png?1557890272',
            'coingeckoId': 'shardus',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'POC',
            'name': 'Pocket Arena',
            'address': '0x095cF7f3e82a1dcAdBF0FbC59023F419883eA296',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8731/thumb/poc.png?1638521756',
            'coingeckoId': 'pocket-arena',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOLC',
            'name': 'GOLCOIN',
            'address': '0x095797FD4297fb79883cC912a5Ba6313B15c445d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27341/thumb/logo_in_the_middle_.png?1663551501',
            'coingeckoId': 'golcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INDEX',
            'name': 'Index Cooperative',
            'address': '0x0954906da0Bf32d5479e25f46056d22f08464cab',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0954906da0bf32d5479e25f46056d22f08464cab.png',
            'coingeckoId': 'index-cooperative',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'LND',
            'name': 'Lendingblock',
            'address': '0x0947b0e6D821378805c9598291385CE7c791A6B2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2988/thumb/LB_Icon__L.png?1631625796',
            'coingeckoId': 'lendingblock',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PLT',
            'name': 'Palette',
            'address': '0x0944D5848BD9F60A34Ba92aEa300d4286696Eb76',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22153/thumb/262aoVTp_400x400.jpg?1640940192',
            'coingeckoId': 'palette',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MRI',
            'name': 'Marshall Inu',
            'address': '0x0913dDAE242839f8995c0375493f9a1A3Bddc977',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23784/thumb/mri.png?1647693409',
            'coingeckoId': 'marshall-rogan-inu',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'SKY',
            'name': 'SkyToken',
            'address': '0x090FC4D5436D98E13473c4d6109De6A18BCfB0d4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28037/thumb/sky.png?1667129732',
            'coingeckoId': 'sky-v2',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPELL',
            'name': 'Spell',
            'address': '0x090185f2135308BaD17527004364eBcC2D37e5F6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x090185f2135308bad17527004364ebcc2d37e5f6.png',
            'coingeckoId': 'spell-token',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'arbitrum_bridge',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'TNT',
            'name': 'Tierion',
            'address': '0x08f5a9235B08173b7569F83645d2c7fB55e8cCD8',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/923/thumb/tierion.png?1547034767',
            'coingeckoId': 'tierion',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MATH',
            'name': 'MATH',
            'address': '0x08d967bb0134F2d07f7cfb6E246680c53927DD30',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x08d967bb0134f2d07f7cfb6e246680c53927dd30.png',
            'coingeckoId': 'math',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'openocean',
                'arbitrum_bridge',
                'lifinance'
            ]
        },
        {
            'symbol': 'DCN',
            'name': 'Dentacoin',
            'address': '0x08d32b0da63e2C3bcF8019c9c5d849d7a9d791e6',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://ethereum-optimism.github.io/data/DCN/logo.svg',
            'coingeckoId': 'dentacoin',
            'listedIn': [
                'coingecko',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'STOS',
            'name': 'Stratos',
            'address': '0x08c32b0726C5684024ea6e141C50aDe9690bBdcc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16213/thumb/token_512x512.png?1623313898',
            'coingeckoId': 'stratos',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CENT',
            'name': 'Centaurify',
            'address': '0x08ba718F288c3b12B01146816bef9FA03cC635bc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20512/thumb/GTU5RBD0.png?1645205770',
            'coingeckoId': 'centaurify',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CKU',
            'name': 'Cryptoku',
            'address': '0x08F7be99ED83369541501d60f4e66F8e34c3F736',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25813/thumb/Cryptoku.png?1653988443',
            'coingeckoId': 'cryptoku',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REALT-S-13116-KILBO',
            'name': 'RealT   13116 Kilbourne Ave  Detroit  M',
            'address': '0x08Ad1F3a48Be1D23C723a6cC8486b247F5dE935a',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16679/thumb/13116-Kilbourne-hero-1.jpeg?1624630007',
            'coingeckoId': '13116-kilbourne',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XPT',
            'name': 'Cryptobuyer',
            'address': '0x08Aa0ed0040736dd28d4c8B16Ab453b368248d19',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10845/thumb/XPT.png?1584775672',
            'coingeckoId': 'cryptobuyer-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RWS',
            'name': 'Robonomics Web Services',
            'address': '0x08AD83D779BDf2BBE1ad9cc0f78aa0D24AB97802',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11885/thumb/rws_logo.png?1595745253',
            'coingeckoId': 'robonomics-web-services',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EDG',
            'name': 'Edgeless',
            'address': '0x08711D3B02C8758F2FB3ab4e80228418a7F8e39c',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/635/thumb/Edgeless-Token.jpg?1631795809',
            'coingeckoId': 'edgeless',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASX',
            'name': 'AllStars',
            'address': '0x083DFb3566bde80380446718eEDa55B800464636',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27492/thumb/asx.png?1667044777',
            'coingeckoId': 'allstars',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GOL',
            'name': 'GogolCoin',
            'address': '0x083D41d6DD21EE938f0c055CA4fb12268DF0EfaC',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14824/thumb/GOL.png?1618559300',
            'coingeckoId': 'gogolcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CRPT',
            'name': 'Crypterium',
            'address': '0x08389495D7456E1951ddF7c3a1314A4bfb646d8B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1901/thumb/crypt.png?1547036205',
            'coingeckoId': 'crypterium',
            'listedIn': [
                'coingecko',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'ROCKS',
            'name': 'Social Rocket',
            'address': '0x0829d2d5cC09d3d341E813c821B0cfAE272D9fb2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1634700497929_4474593154855173.png',
            'coingeckoId': 'social-rocket',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'CET',
            'name': 'CoinEx',
            'address': '0x081F67aFA0cCF8c7B17540767BBe95DF2bA8D97F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4817/thumb/coinex-token.png?1547040183',
            'coingeckoId': 'coinex-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DAR',
            'name': 'Mines of Dalarnia',
            'address': '0x081131434f93063751813C619Ecca9C4dC7862a3',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1636026917864_4450657201941066.jpg',
            'coingeckoId': 'mines-of-dalarnia',
            'listedIn': [
                'coingecko',
                'uniswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'CNP',
            'name': 'Cryptonia Poker',
            'address': '0x0809bD190C94F4408e691C410E67BFf0DF5d225d',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3615/thumb/cpc.png?1547038522',
            'coingeckoId': 'cryptonia-poker',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NFTS',
            'name': 'NFT Stars',
            'address': '0x08037036451C768465369431Da5C671ad9B37dBc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16141/thumb/j2KHi8zR_400x400.png?1623116004',
            'coingeckoId': 'nft-stars',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'BNANA',
            'name': 'Chimpion',
            'address': '0x07eF9E82721AC16809D24DAfBE1792Ce01654DB4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7522/thumb/banana-token-logo.jpg?1548167777',
            'coingeckoId': 'banana-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DLT',
            'name': 'Agrello',
            'address': '0x07e3c70653548B04f0A75970C1F81B4CBbFB606f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/900/thumb/delta_200x200.png?1616645398',
            'coingeckoId': 'agrello',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EROWAN',
            'name': 'Sifchain',
            'address': '0x07baC35846e5eD502aA91AdF6A9e7aA210F2DcbE',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x07bac35846e5ed502aa91adf6a9e7aa210f2dcbe.png',
            'coingeckoId': 'sifchain',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ARTII',
            'name': 'ARTII',
            'address': '0x07a858fc699f99ddF2b186bf162Fd7f4d42F7f63',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20767/thumb/logo-img.png?1637654806',
            'coingeckoId': 'artii-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RFWSTETH',
            'name': 'Respawn Finance Wrapped Staked Ethereum',
            'address': '0x07FA101efde726E0956Edd2c4D5C8d3d1A5E9c53',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethereum-optimism.github.io/data/RFWSTETH/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'OBT',
            'name': 'Oobit',
            'address': '0x07F9702ce093db82dfdC92c2C6E578d6EA8D5E22',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19855/thumb/obt.png?1636062272',
            'coingeckoId': 'oobit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DALC',
            'name': 'Dalecoin',
            'address': '0x07D9e49Ea402194bf48A8276dAfB16E4eD633317',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/932/thumb/dalecoin.png?1547791491',
            'coingeckoId': 'dalecoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FSCC',
            'name': 'FISCO Coin',
            'address': '0x0789dbAE94fb18e5789B8e4489BCB7A1ADB58622',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6507/thumb/AnyConv.com__fscc_icon_fin.png?1608779052',
            'coingeckoId': 'fisco',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LHCOIN',
            'name': 'LHCoin',
            'address': '0x0778Cc2E8bBAd3d483E82371606D100cc8604522',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6709/thumb/lhcoin.jpg?1547042926',
            'coingeckoId': 'lhcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NIROS',
            'name': 'Niros',
            'address': '0x0770E5018B71f5FBDA00F42Dd13507E03D101A90',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22409/thumb/oJEphc3R_400x400.jpg?1641797798',
            'coingeckoId': 'niros',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOP',
            'name': 'SoPay',
            'address': '0x076641aF1B8f06B7f8C92587156143C109002cbe',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6736/thumb/sopay.png?1548609877',
            'coingeckoId': 'sopay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SUKU',
            'name': 'SUKU',
            'address': '0x0763fdCCF1aE541A5961815C0872A8c5Bc6DE4d7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethereum-optimism.github.io/data/SUKU/logo.png',
            'coingeckoId': 'suku',
            'listedIn': [
                'coingecko',
                'uniswap',
                'openocean',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'CRVRENWSBTC',
            'name': 'Curve fi renBTC wBTC sBTC',
            'address': '0x075b1bb99792c9E1041bA13afEf80C91a1e70fB3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11958/thumb/Curvefi_sbtcCrv_32.png?1596436054',
            'coingeckoId': 'curve-fi-renbtc-wbtc-sbtc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '1UP',
            'name': 'Uptrennd',
            'address': '0x07597255910a51509CA469568B048F2597E72504',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x07597255910a51509ca469568b048f2597e72504.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'BNV',
            'name': 'BunnyVerse',
            'address': '0x072987D5B36aD8d45552aEd98879a7101cCdd749',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21846/thumb/Screenshot-2021-12-21-at-11-11-46.png?1640130263',
            'coingeckoId': 'bunnyverse',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BASE',
            'name': 'Base Protocol',
            'address': '0x07150e919B4De5fD6a63DE1F9384828396f25fDC',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x07150e919b4de5fd6a63de1f9384828396f25fdc.png',
            'coingeckoId': 'base-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'STILTON',
            'name': 'Stilton Musk  OLD ',
            'address': '0x06fcbf38e823efc1e609b9491AaB546334c6ee69',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23572/thumb/2022-02-09_11.32.27-removebg-preview.png?1644475684',
            'coingeckoId': 'stilton-musk',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TLW',
            'name': 'TilWiki',
            'address': '0x06f3CDabae564B0546529b4DD8FeF1bcD4235753',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12915/thumb/89706561_1111490899211097_8975783115158978560_n.jpg?1603441976',
            'coingeckoId': 'tilwiki',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CAGE',
            'name': 'Coinage Finance',
            'address': '0x06ebc9c542357e2129D16717CD02c02FBC835d33',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22300/thumb/Coinage_v2_200x200_white.png?1653464214',
            'coingeckoId': 'coinage-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTB',
            'name': 'Bitball',
            'address': '0x06e0feB0D74106c7adA8497754074D222Ec6BCDf',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7039/thumb/btb.png?1637556233',
            'coingeckoId': 'bitball',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SCOOBI',
            'name': 'Scoobi Doge',
            'address': '0x06a87F6aFEc4a739c367bEF69eEfE383D27106bd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16101/thumb/scoobi_doge.jpg?1622963978',
            'coingeckoId': 'scoobi-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MASQ',
            'name': 'MASQ',
            'address': '0x06F3C323f0238c72BF35011071f2b5B7F43A054c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x06f3c323f0238c72bf35011071f2b5b7f43a054c.png',
            'coingeckoId': 'masq',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'REALT-S-10617-HATHA',
            'name': 'RealT   10617 Hathaway Ave  Cleveland  ',
            'address': '0x06D0e5Aee443093aC5635B709C8a01342E59Df19',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16709/thumb/10617-Hathaway-hero-1.jpeg?1624647056',
            'coingeckoId': '10617-hathaway',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UNBNK',
            'name': 'Unbanked',
            'address': '0x06B884e60794Ce02AafAb13791B59A2e6A07442f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20171/thumb/logo_-_2021-11-11T115427.135.png?1636602880',
            'coingeckoId': 'unbanked',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'CHAI',
            'name': 'Chai Token',
            'address': '0x06AF07097C9Eeb7fD685c692751D5C66dB49c215',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x06af07097c9eeb7fd685c692751d5c66db49c215.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'CP',
            'name': 'CryptoProfile',
            'address': '0x06A6FC23e6eC8A2b2aeEefd70D772Dc3D6B45010',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7350/thumb/CryptoProfile-logo.png?1547044024',
            'coingeckoId': 'cryptoprofile',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SKEY',
            'name': 'Skey Network',
            'address': '0x06A01a4d579479Dd5D884EBf61A31727A3d8D442',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x06a01a4d579479dd5d884ebf61a31727a3d8d442.png',
            'coingeckoId': 'skey-network',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'XNL',
            'name': 'Chronicle',
            'address': '0x06A00715E6F92210Af9d7680B584931FAF71A833',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18413/thumb/xnl_logo.png?1633517024',
            'coingeckoId': 'chronicle',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HZM',
            'name': 'HZM Coin',
            'address': '0x069f967be0CA21C7D793D8C343f71e597D9a49b3',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18032/thumb/hzm.PNG?1630296878',
            'coingeckoId': 'hzm-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STA',
            'name': 'STOA Network',
            'address': '0x06874F973Dc3c96dc22A10eF0D0609F877f335EA',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16710/thumb/RSloE6X1_400x400.jpeg?1624684021',
            'coingeckoId': 'defi-stoa',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DOPE',
            'name': 'Dopamine',
            'address': '0x067edE5097574b387ff30999e3a1775f3D7D569d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SAKE',
            'name': 'SakeSwap',
            'address': '0x066798d9ef0833ccc719076Dab77199eCbd178b0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/6997.png',
            'coingeckoId': 'sake-token',
            'listedIn': [
                'coingecko',
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'WQT',
            'name': 'Work Quest',
            'address': '0x06677Dc4fE12d3ba3C7CCfD0dF8Cd45e4D4095bF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14662/thumb/WQT-1_%281%29.png?1627652762',
            'coingeckoId': 'work-quest',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TCP',
            'name': 'The Crypto Prophecies',
            'address': '0x06576eB3B212d605B797dC15523d9Dc9F4F66DB4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15054/thumb/tcp.PNG?1619579195',
            'coingeckoId': 'the-crypto-prophecies',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XEN',
            'name': 'XEN Crypto',
            'address': '0x06450dEe7FD2Fb8E39061434BAbCFC05599a6Fb8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://cloudstorage.openocean.finance/images/1667898365308_4432821220808989.png',
            'coingeckoId': 'xen-crypto',
            'listedIn': [
                'coingecko',
                'openocean'
            ]
        },
        {
            'symbol': 'H2O',
            'name': 'H2O',
            'address': '0x0642026E7f0B6cCaC5925b4E7Fa61384250e1701',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25315/thumb/h2o_32.png?1651215712',
            'coingeckoId': 'h2o',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'mDAI',
            'name': 'DMM: DAI',
            'address': '0x06301057D77D54B6e14c7FafFB11Ffc7Cab4eaa7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x06301057d77d54b6e14c7faffb11ffc7cab4eaa7.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'ACE',
            'name': 'ACE',
            'address': '0x06147110022B768BA8F99A8f385df11a151A9cc8',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x06147110022b768ba8f99a8f385df11a151a9cc8.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'FS',
            'name': 'FantomStarter',
            'address': '0x05eC81610aB47A094502bAC0D7eC256f06a1c6f8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'XNO',
            'name': 'Xeno',
            'address': '0x05bBe7240De66F6480C9aeda77C1376B13393F83',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13830/thumb/XNO_logo_200x200.png?1612168285',
            'coingeckoId': 'xeno-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YOK',
            'name': 'YOKcoin',
            'address': '0x05Fcc72CFb4150AbAE415c885f7a433Ff523296F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12425/thumb/YOKcoin_200x200.png?1599732314',
            'coingeckoId': 'yokcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'S-CATS',
            'name': 'SUPERCATS',
            'address': '0x05Fc938Cc60fB71381514877D66478bab7E2e1cE',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26675/thumb/Supercats_logo.png?1659517034',
            'coingeckoId': 'supercats',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HAI',
            'name': 'Hacken HAI',
            'address': '0x05Fb86775Fd5c16290f1E838F5caaa7342bD9a63',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11081/thumb/logo_haken_vector.png?1593511379',
            'coingeckoId': 'hackenai',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ABAT',
            'name': 'Aave BAT',
            'address': '0x05Ec93c0365baAeAbF7AefFb0972ea7ECdD39CF1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x05ec93c0365baaeabf7aeffb0972ea7ecdd39cf1.png',
            'coingeckoId': 'aave-bat',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'TOMOE',
            'name': 'TomoChain ERC 20',
            'address': '0x05D3606d5c81EB9b7B18530995eC9B29da05FaBa',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x05d3606d5c81eb9b7b18530995ec9b29da05faba.png',
            'coingeckoId': 'tomoe',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'YFPI',
            'name': 'Yearn Finance Passive Income',
            'address': '0x05D27CdD23E22ca63e7f9c7C6D1B79ede9C4fCF5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12640/thumb/200.png?1601369185',
            'coingeckoId': 'yearn-finance-passive-income',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DONA',
            'name': 'DONA',
            'address': '0x058bC8Ef040bD3971418E36aA88b64899378CcF4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18429/thumb/DONA.jpg?1631889823',
            'coingeckoId': 'dona',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GUSD',
            'name': 'Gemini Dollar',
            'address': '0x056Fd409E1d7A124BD7017459dFEa2F387b6d5Cd',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x056fd409e1d7a124bd7017459dfea2f387b6d5cd.png',
            'coingeckoId': 'gemini-dollar',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'openocean',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SUPERBID',
            'name': 'SuperBid',
            'address': '0x0563DCe613D559a47877fFD1593549fb9d3510D6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0563dce613d559a47877ffd1593549fb9d3510d6.png',
            'coingeckoId': 'superbid',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'TRIX',
            'name': 'TriumphX',
            'address': '0x056354F3Ff20743aa4c0DA365603871c7000b081',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12270/thumb/TRIX_Logo_transparent.png?1598619314',
            'coingeckoId': 'triumphx',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CFXQ',
            'name': 'CFX Quantum',
            'address': '0x0557E0d15aeC0b9026dD17aA874fDf7d182A2cEB',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15031/thumb/CFXQ_TOKEN_LOGO_200x200.png?1619500058',
            'coingeckoId': 'cfx-quantum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UPXAU',
            'name': 'Universal Gold',
            'address': '0x0557Df767419296474C3f551Bb0A0ED4c2DD3380',
            'decimals': 5,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13534/thumb/UPXAU-1.png?1609540908',
            'coingeckoId': 'universal-gold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FIN',
            'name': 'DeFiner',
            'address': '0x054f76beED60AB6dBEb23502178C52d6C5dEbE40',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12780/thumb/PdaW8Lb.png?1602500407',
            'coingeckoId': 'definer',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'PPAY',
            'name': 'Plasma Finance',
            'address': '0x054D64b73d3D8A21Af3D764eFd76bCaA774f3Bb2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x054d64b73d3d8a21af3d764efd76bcaa774f3bb2.png',
            'coingeckoId': 'plasma-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'INU',
            'name': 'Inu ',
            'address': '0x050D94685c6B0477E1Fc555888AF6e2bB8dFBda5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26259/thumb/inulogo_200x200.png?1656941363',
            'coingeckoId': 'inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTSG',
            'name': 'BitSong',
            'address': '0x05079687D35b93538cbd59fe5596380cae9054A9',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x05079687d35b93538cbd59fe5596380cae9054a9.png',
            'coingeckoId': 'bitsong',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'NEST',
            'name': 'Nest Protocol',
            'address': '0x04abEdA201850aC0124161F037Efd70c74ddC74C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/11284/thumb/52954052.png?1589868539',
            'coingeckoId': 'nest',
            'listedIn': [
                'coingecko',
                'uniswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'UMA',
            'name': 'UMA',
            'address': '0x04Fa0d235C4abf4BcF4787aF4CF447DE572eF828',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x04fa0d235c4abf4bcf4787af4cf447de572ef828.png',
            'coingeckoId': 'uma',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'optimism',
                'lifinance',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SETS',
            'name': 'Sensitrust',
            'address': '0x04E0Af0af1b7f0023c6B12af5a94Df59B0e8cF59',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15035/thumb/SETS-token-logo-200.png?1619503990',
            'coingeckoId': 'sensitrust',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ODA',
            'name': 'Eiichiro Oda Inu',
            'address': '0x04DC37B220A055c5F93680815F670babCD912c2C',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19546/thumb/oda.png?1635390942',
            'coingeckoId': 'eiichiro-oda-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IFUND',
            'name': 'Unifund',
            'address': '0x04B5E13000C6e9A3255Dc057091F3e3Eeee7b0f0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12833/thumb/unifund_logo.png?1602859047',
            'coingeckoId': 'unifund',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'yBTC',
            'name': 'iearn WBTC',
            'address': '0x04Aa51bbcB46541455cCF1B8bef2ebc5d3787EC9',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x04aa51bbcb46541455ccf1b8bef2ebc5d3787ec9.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'ETH20',
            'name': 'Proof Of Memes   Ethereum',
            'address': '0x04A6b6DE116Fb8bF57e5eE8b05e0293EA3639fE8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27305/thumb/Screenshot_2022-09-22_173342-removebg-preview.png?1664112523',
            'coingeckoId': 'proof-of-memes',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'SHR',
            'name': 'ShiraINU',
            'address': '0x04A5198063e45D84B1999516D3228167146417A6',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20244/thumb/3234bad559608.png?1638363295',
            'coingeckoId': 'shirainu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HERB',
            'name': 'Herbalist',
            'address': '0x04A020325024F130988782bd5276e53595e8d16E',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6892/thumb/herbalist-token-twitter.png?1547043247',
            'coingeckoId': 'herbalist-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WOMI',
            'name': 'Wrapped ECOMI',
            'address': '0x04969cD041C0cafB6AC462Bd65B536A5bDB3A670',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14675/thumb/ecomi.jpg?1617689100',
            'coingeckoId': 'wrapped-ecomi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLOT',
            'name': 'Fire Lotto',
            'address': '0x049399a6B048D52971F7D122aE21A1532722285F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2201/thumb/fire-lotto.png?1548126078',
            'coingeckoId': 'fire-lotto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LSC',
            'name': 'Live Swap Coin',
            'address': '0x048eb9B9c08E2dF45644cf0DB1fcB3e866c401Ad',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15287/thumb/lsc.PNG?1620356318',
            'coingeckoId': 'live-swap-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SKM',
            'name': 'Skrumble Network',
            'address': '0x048Fe49BE32adfC9ED68C37D32B5ec9Df17b3603',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3826/thumb/skrumble-network.png?1548609513',
            'coingeckoId': 'skrumble-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'aUniDAI',
            'name': 'Aave Interest bearing UniDAI',
            'address': '0x048930eec73c91B44b0844aEACdEBADC2F2b6efb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'SRK',
            'name': 'SparkPoint',
            'address': '0x0488401c3F535193Fa8Df029d9fFe615A06E74E6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0488401c3f535193fa8df029d9ffe615a06e74e6.png',
            'coingeckoId': 'sparkpoint',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'CUTE',
            'name': 'Blockchain Cuties Universe',
            'address': '0x047686fB287e7263A23873dEa66b4501015a2226',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8328/thumb/bnLvIEl1_400x400.jpg?1557533240',
            'coingeckoId': 'blockchain-cuties-universe',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DCHF',
            'name': 'DeFi Franc',
            'address': '0x045da4bFe02B320f4403674B3b7d121737727A36',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x045da4bfe02b320f4403674b3b7d121737727a36.png',
            'coingeckoId': 'defi-franc',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BOX',
            'name': 'B watch Box',
            'address': '0x045414e728067Ab3da4bCeafC0D992D59183463A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25324/thumb/Screenshot_from_2022-04-29_17-04-42.png?1651223108',
            'coingeckoId': 'b-watch-box',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PPC',
            'name': 'Peercoin',
            'address': '0x044d078F1c86508e13328842Cc75AC021B272958',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4/thumb/peercoin-icon-green-transparent_6x.png?1547033591',
            'coingeckoId': 'peercoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KITTY',
            'name': 'Kitty Inu',
            'address': '0x044727e50ff30DB57fad06Ff4F5846eAb5eA52a2',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19385/thumb/2Hy412Bd_400x400.png?1635146893',
            'coingeckoId': 'kitty-inu',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'SUM',
            'name': 'SumSwap',
            'address': '0x043C308BB8a5aE96D0093444be7f56459F1340b1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/11208.png',
            'coingeckoId': 'sumswap',
            'listedIn': [
                'coingecko',
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'JPC',
            'name': 'JPEX Coin',
            'address': '0x043827A6dCFfFb7fE21953d3BAD32A1c74bB73bF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27717/thumb/200_200_JPEX_LOGO_transparent.png?1665453898',
            'coingeckoId': 'jpex-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LID',
            'name': 'Liquidity Dividends Protocol',
            'address': '0x0417912b3a7AF768051765040A55BB0925D4DDcF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x0417912b3a7af768051765040a55bb0925d4ddcf.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'WRK',
            'name': 'BlockWRK',
            'address': '0x0407B4c4eAEd35CE3C5B852bDFA1640B09EeEDF4',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17282/thumb/blkwr.png?1635470283',
            'coingeckoId': 'blockwrk',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SWAN',
            'name': 'BlackSwan Nodes',
            'address': '0x03c7a08451Ed5c820958d64116D74De1f7B285d8',
            'decimals': 9,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': 'blackswan-nodes',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RAI',
            'name': 'Rai Reflex Index',
            'address': '0x03ab458634910AaD20eF5f1C8ee96F1D6ac54919',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x03ab458634910aad20ef5f1c8ee96f1d6ac54919.png',
            'coingeckoId': 'rai',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'optimism',
                'arbitrum_bridge',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'NABOX',
            'name': 'Nabox',
            'address': '0x03D1E72765545729A035e909eDd9371a405f77Fb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16445/thumb/NyemjVRA_400x400.png?1624235013',
            'coingeckoId': 'nabox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FOREST',
            'name': 'YAKUSHIMA',
            'address': '0x03CF23eFdB2A6cF6cf6eB8B5A3383966f5c6B6D5',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26984/thumb/photo_2022-08-20_23-40-23.jpg?1661181326',
            'coingeckoId': 'yakushima',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CAPS',
            'name': 'Ternoa',
            'address': '0x03Be5C903c727Ee2C8C4e9bc0AcC860Cca4715e2',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x03be5c903c727ee2c8c4e9bc0acc860cca4715e2.png',
            'coingeckoId': 'coin-capsule',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'SWACE',
            'name': 'Swace',
            'address': '0x03B155AF3F4459193A276395dD76e357BB472DA1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3727/thumb/swace-symbol.png?1564553792',
            'coingeckoId': 'swace',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOND',
            'name': 'BarnBridge',
            'address': '0x0391D2021f89DC339F60Fff84546EA23E337750f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0391d2021f89dc339f60fff84546ea23e337750f.png',
            'coingeckoId': 'barnbridge',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'optimism',
                'arbitrum_bridge',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'GLCH',
            'name': 'Glitch Protocol',
            'address': '0x038a68FF68c393373eC894015816e33Ad41BD564',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x038a68ff68c393373ec894015816e33ad41bd564.png',
            'coingeckoId': 'glitch-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'TSL',
            'name': 'Tesla TSL',
            'address': '0x03806Ce5ef69Bd9780EDFb04c29da1F23Db96294',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1222/thumb/energo.png?1547035249',
            'coingeckoId': 'energo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LCX',
            'name': 'LCX',
            'address': '0x037A54AaB062628C9Bbae1FDB1583c195585fe41',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x037a54aab062628c9bbae1fdb1583c195585fe41.png',
            'coingeckoId': 'lcx',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'BTCR',
            'name': 'Bitcurate',
            'address': '0x0371f7b219fff864b437bcfb564810F323FfFccA',
            'decimals': 4,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10895/thumb/Bitcurate_logo_200x200.png?1585694787',
            'coingeckoId': 'bitcurate',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STU',
            'name': 'bitJob',
            'address': '0x0371A82e4A9d0A4312f3ee2Ac9c6958512891372',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1172/thumb/bitjob.jpg?1547035202',
            'coingeckoId': 'bitjob',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '0XMR',
            'name': '0xMonero',
            'address': '0x035dF12E0F3ac6671126525f1015E47D79dFEDDF',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x035df12e0f3ac6671126525f1015e47d79dfeddf.png',
            'coingeckoId': '0xmonero',
            'listedIn': [
                'coingecko',
                '1inch',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'ULU',
            'name': 'Universal Liquidity Union',
            'address': '0x035bfe6057E15Ea692c0DfdcaB3BB41a64Dd2aD4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12376/thumb/ulu_finance_logo.ico?1599444401',
            'coingeckoId': 'universal-liquidity-union',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SRUNE',
            'name': 'sRUNE',
            'address': '0x0352557B007A4Aae1511C114409b932F06F9E2f4',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14953/thumb/sRUNE.png?1619129376',
            'coingeckoId': 'srune',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZENITH',
            'name': 'Zenith Chain',
            'address': '0x0343131C0257aC21Ea5A8dc83841f071eFd9285C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21266/thumb/Jqtp-OVG_400x400.png?1638837643',
            'coingeckoId': 'zenith-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ZIN',
            'name': 'Zin',
            'address': '0x033e223870f766644f7f7a4B7dc2E91573707d06',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13250/thumb/coingecko_logo.png?1606716375',
            'coingeckoId': 'zin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NITRO',
            'name': 'Nitro League',
            'address': '0x0335A7610D817aeCA1bEBbEfbd392ecC2eD587B8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21668/thumb/_X6vYBDM_400x400.jpg?1639705848',
            'coingeckoId': 'nitro-league',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BTC++',
            'name': 'PieDAO BTC  ',
            'address': '0x0327112423F3A68efdF1fcF402F6c5CB9f7C33fd',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0327112423f3a68efdf1fcf402f6c5cb9f7c33fd.png',
            'coingeckoId': 'piedao-btc',
            'listedIn': [
                'coingecko',
                '1inch',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'HBTC',
            'name': 'Huobi BTC',
            'address': '0x0316EB71485b0Ab14103307bf65a021042c6d380',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0316eb71485b0ab14103307bf65a021042c6d380.png',
            'coingeckoId': 'huobi-btc',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'MKRETHDOOM',
            'name': 'MKRETH 1x Short',
            'address': '0x030ed557a0775e110394C1a543f3eB181AeEa05F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x030ed557a0775e110394C1a543f3eB181AeEa05F/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'AWETH',
            'name': 'Aave WETH',
            'address': '0x030bA81f1c18d280636F32af80b9AAd02Cf0854e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x030ba81f1c18d280636f32af80b9aad02cf0854e.png',
            'coingeckoId': 'aave-weth',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BDI',
            'name': 'BasketDAO DeFi Index',
            'address': '0x0309c98B1bffA350bcb3F9fB9780970CA32a5060',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x0309c98B1bffA350bcb3F9fB9780970CA32a5060/logo.png',
            'coingeckoId': 'basketdao-defi-index',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'BCS',
            'name': 'Basis Coin Share',
            'address': '0x03066dA434e5264ef0B32f787923f974A5726fDc',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13530/thumb/Basiscoin_Share.png?1609406623',
            'coingeckoId': 'basiscoin-share',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TBC',
            'name': 'Ten Best Coins',
            'address': '0x03042ae6fcFd53E3A0BAa1FaB5Ce70E0cb74E6fb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27312/thumb/tbc.png?1663312003',
            'coingeckoId': 'ten-best-coins',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CNTR',
            'name': 'Centaur',
            'address': '0x03042482d64577A7bdb282260e2eA4c8a89C064B',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12743/thumb/logo_%2898%29.png?1602630445',
            'coingeckoId': 'centaur',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JPG',
            'name': 'JPG NFT Index',
            'address': '0x02e7Ac540409D32C90BfB51114003a9E1fF0249c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25071/thumb/JPG-token-logo-01.png?1650242041',
            'coingeckoId': 'jpg-nft-index',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PKG',
            'name': 'PKG',
            'address': '0x02F2D4a04E6E01aCE88bD2Cd632875543b2eF577',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5422/thumb/pkg-token.png?1548331357',
            'coingeckoId': 'pkg-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BCHHEDGE',
            'name': '1X Short Bitcoin Cash Token',
            'address': '0x02E88a689fdfB920e7Aa6174Fb7AB72add3C5694',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10332/thumb/683JEXMN_400x400.png?1596707014',
            'coingeckoId': '1x-short-bitcoin-cash-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IONX',
            'name': 'Charged Particles',
            'address': '0x02D3A27Ac3f55d5D91Fb0f52759842696a864217',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15836/thumb/DrKjSQMH_400x400.png?1622080222',
            'coingeckoId': 'charged-particles',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADAI',
            'name': 'Aave DAI',
            'address': '0x028171bCA77440897B824Ca71D1c56caC55b68A3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x028171bca77440897b824ca71d1c56cac55b68a3.png',
            'coingeckoId': 'aave-dai',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'OIL',
            'name': 'Oiler',
            'address': '0x0275E1001e293C46CFe158B3702AADe0B99f88a5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15249/thumb/oiler.png?1620237607',
            'coingeckoId': 'oiler',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BIRD',
            'name': 'Birdchain',
            'address': '0x026e62dDEd1a6aD07D93D39f96b9eabd59665e0d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1994/thumb/MLSILOZA_400x400.png?1564971298',
            'coingeckoId': 'birdchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ORN',
            'name': 'Orion Protocol',
            'address': '0x0258F474786DdFd37ABCE6df6BBb1Dd5dfC4434a',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0258f474786ddfd37abce6df6bbb1dd5dfc4434a.png',
            'coingeckoId': 'orion-protocol',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'sushiswap',
                'openocean',
                'rubic',
                'lifinance'
            ]
        },
        {
            'symbol': 'MIS',
            'name': 'Mithril Share',
            'address': '0x024B6e7DC26F4d5579bDD936F8d7BC31f2339999',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13516/thumb/MIS.png?1609306996',
            'coingeckoId': 'mithril-share',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BILL',
            'name': 'Bill',
            'address': '0x0239d3a3485Ec54511beE9d77D92695E443bf060',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18480/thumb/photo_2021-08-16_23-27-37.jpg?1632151517',
            'coingeckoId': 'bill-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GLOX',
            'name': 'Glox Finance',
            'address': '0x0222bE1F1B8413b2d7d76EBfC9e0285C1300692f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13312/thumb/glox_finance.png?1607395418',
            'coingeckoId': 'glox-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UFT',
            'name': 'UniLend Finance',
            'address': '0x0202Be363B8a4820f3F4DE7FaF5224fF05943AB1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0202be363b8a4820f3f4de7faf5224ff05943ab1.png',
            'coingeckoId': 'unlend-finance',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BFT',
            'name': 'BnkToTheFuture',
            'address': '0x01fF50f8b7f74E4f00580d9596cd3D0d6d6E326f',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3468/thumb/bnktothefuture.png?1547351865',
            'coingeckoId': 'bnktothefuture',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GTON',
            'name': 'GTON CAPITAL',
            'address': '0x01e0E2e61f554eCAaeC0cC933E739Ad90f24a86d',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x01e0e2e61f554ecaaec0cc933e739ad90f24a86d_1.png',
            'coingeckoId': 'gton-capital',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'SPT',
            'name': 'SPECTRUM',
            'address': '0x01cC4151fe5f00EfB8dF2F90ff833725d3a482a3',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/7688/thumb/SPT-Logo-200x200.png?1549596154',
            'coingeckoId': 'spectrum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BMP',
            'name': 'Brother Music Platform',
            'address': '0x01b23286FF60a543ec29366aE8D6B6274cA20541',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13207/thumb/BMP_logo.png?1606199707',
            'coingeckoId': 'brother-music-platform',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SELECT',
            'name': 'Smart Electrum',
            'address': '0x01FB70eA8AcFDd5435c8e8bd1531d96D57cEd0ec',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25131/thumb/NRKjS5Cm_400x400.jpg?1650420526',
            'coingeckoId': 'smart-electrum',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'UAT',
            'name': 'UltrAlpha',
            'address': '0x01C0987E88F778DF6640787226bc96354E1a9766',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9300/thumb/UltrAlpha.png?1565882277',
            'coingeckoId': 'ultralpha',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LYRA',
            'name': 'Lyra Finance',
            'address': '0x01BA67AAC7f75f647D94220Cc98FB30FCc5105Bf',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethereum-optimism.github.io/data/LYRA/logo.png',
            'coingeckoId': 'lyra-finance',
            'listedIn': [
                'coingecko',
                'sushiswap',
                'optimism',
                'lifinance'
            ]
        },
        {
            'symbol': 'PLA',
            'name': 'PlayChip',
            'address': '0x0198f46f520F33cd4329bd4bE380a25a90536CD5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/4567/thumb/pla.png?1547039851',
            'coingeckoId': 'playchip',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MP3',
            'name': 'MP3',
            'address': '0x018fb5Af9d015Af25592a014C4266a84143De7a0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x018fb5af9d015af25592a014c4266a84143de7a0.png',
            'coingeckoId': 'mp3',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': '$FORWARD',
            'name': 'Forward',
            'address': '0x01824357D7D7EAF4677Bc17786aBd26CBdEc9Ad7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'QLT',
            'name': 'Quantland',
            'address': '0x0173661769325565d4f011b2e5Cda688689Cc87c',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24536/thumb/8jYfKBTk_400x400.jpg?1648051247',
            'coingeckoId': 'quantland',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BEAR',
            'name': '3X Short Bitcoin Token',
            'address': '0x016ee7373248a80BDe1fD6bAA001311d233b3CFa',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9991/thumb/683JEXMN_400x400.png?1574418639',
            'coingeckoId': '3x-short-bitcoin-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MIRO',
            'name': 'Mirocana',
            'address': '0x0168703872fa06741Ecaa9Dff7803168e83f7aE0',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1651/thumb/mirocana.jpg?1547035896',
            'coingeckoId': 'mirocana',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KXC',
            'name': 'KingXChain',
            'address': '0x016396044709EB3edc69C44f4d5Fa6996917E4e8',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/5690/thumb/kingxchain.png?1547975313',
            'coingeckoId': 'kingxchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XCON',
            'name': 'Connect Coin',
            'address': '0x015df42d36Bc851c7F15f80bd1D4e8dBF02aed0c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/6592/thumb/connect_coin.jpg?1547042796',
            'coingeckoId': 'connect-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'UZD',
            'name': 'Zunami USD',
            'address': '0x015B94AB2B0A14A96030573FBcD0F3D3d763541F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27621/thumb/UZD_Logo_200x200.png?1666428507',
            'coingeckoId': 'zunami-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BENT',
            'name': 'Bent Finance',
            'address': '0x01597E397605Bf280674Bf292623460b4204C375',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/logos/main/network/ethereum/0x01597E397605Bf280674Bf292623460b4204C375.jpg',
            'coingeckoId': 'bent-finance',
            'listedIn': [
                'coingecko',
                'sushiswap'
            ]
        },
        {
            'symbol': 'SHEESH',
            'name': 'SHEESHETH',
            'address': '0x014550A34149231b8432B8dD210fADd81b1d2668',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26678/thumb/LOGO.png?1659522896',
            'coingeckoId': 'sheesheth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PYN',
            'name': 'Paycent',
            'address': '0x0142C3B2fC51819B5aF5dFc4AA52Df9722790851',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2008/thumb/paycentos-logo-maroon.png?1547036284',
            'coingeckoId': 'paycent',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SKT',
            'name': 'Sukhavati Network',
            'address': '0x012E0e6342308b247F36Ee500ECb14dc77a7a8C1',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17674/thumb/logo2.png?1628830203',
            'coingeckoId': 'sukhavati-network',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HKC',
            'name': 'HK Coin',
            'address': '0x011C5C4E4A86FC95A7A6d5C49a69Cdf0CB1d0467',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20747/thumb/HKC200x200.png?1637634544',
            'coingeckoId': 'hk-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'REI',
            'name': 'Zerogoki',
            'address': '0x011864D37035439E078d64630777Ec518138aF05',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17015/thumb/iconZerogoki.png?1626618921',
            'coingeckoId': 'zerogoki',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TETHERDOOM',
            'name': 'Tether 3x Short',
            'address': '0x0110aD3Cf10A6d04993BeC37BDCCBA6EE2C48A27',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x0110aD3Cf10A6d04993BeC37BDCCBA6EE2C48A27/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'XCF',
            'name': 'XCF Token',
            'address': '0x010D14d36C3eA6570D240ae3ac9d660398f7C48e',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10987/thumb/xcf-icon-01-1-flat.png?1622195426',
            'coingeckoId': 'xcf-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AUTH',
            'name': 'Authencity',
            'address': '0x0107C4aAa31940eF88760aCb1F32424CA8d8bD80',
            'decimals': 8,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26960/thumb/1.png?1661062419',
            'coingeckoId': 'authencity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ECOP',
            'name': 'Eco DeFi',
            'address': '0x0106A1122FE94a9CF151097c1Fe17229Ec78FFaD',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19535/thumb/eco_global.PNG?1635374624',
            'coingeckoId': 'eco-defi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HITOP',
            'name': 'Hitop',
            'address': '0x01022591fCe0609B57670bebA60102dFd2b95D77',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25959/thumb/20540.png?1654860802',
            'coingeckoId': 'hitop',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALETH',
            'name': 'Alchemix ETH',
            'address': '0x0100546F2cD4C9D97f798fFC9755E47865FF7Ee6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16271/thumb/uS04dyYy_400x400.jpeg?1663051098',
            'coingeckoId': 'alchemix-eth',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'TCO',
            'name': 'Tcoin fun',
            'address': '0x00ff902d4B2bfDBD0DB38887412447C30B565aa0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13811/thumb/logo200_%289%29.png?1612047833',
            'coingeckoId': 'tcoin-fun',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'VNXLU',
            'name': 'VNX Exchange',
            'address': '0x00fC270C9cc13e878Ab5363D00354bebF6f05C15',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/9945/thumb/vnx.PNG?1573639467',
            'coingeckoId': 'vnx-exchange',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DAPPX',
            'name': 'dAppstore',
            'address': '0x00d8318E44780EdEEFcF3020A5448F636788883C',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15780/thumb/large-logo.png?1621841062',
            'coingeckoId': 'dappstore',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SKL',
            'name': 'SKALE',
            'address': '0x00c83aeCC790e8a4453e5dD3B0B4b3680501a7A7',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x00c83aecc790e8a4453e5dd3b0b4b3680501a7a7.png',
            'coingeckoId': 'skale',
            'listedIn': [
                'coingecko',
                '1inch',
                'uniswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ALBT',
            'name': 'AllianceBlock',
            'address': '0x00a8b738E453fFd858a7edf03bcCfe20412f0Eb0',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x00a8b738e453ffd858a7edf03bccfe20412f0eb0.png',
            'coingeckoId': 'allianceblock',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'IB',
            'name': 'IronBank',
            'address': '0x00a35FD824c717879BF370E70AC6868b95870Dfb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'INU',
            'name': 'INU',
            'address': '0x00F29171D7bCDC464a0758cF3217fE83173772b9',
            'decimals': 9,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16898/thumb/logo.png?1632227807',
            'coingeckoId': 'inu-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TACO',
            'name': 'Tacos',
            'address': '0x00D1793D7C3aAE506257Ba985b34C76AaF642557',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x00d1793d7c3aae506257ba985b34c76aaf642557.png',
            'coingeckoId': 'tacos',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'STATE',
            'name': 'New World Order',
            'address': '0x00C2999c8B2AdF4ABC835cc63209533973718eB1',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27387/thumb/icon.png?1663812357',
            'coingeckoId': 'new-world-order',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CTC',
            'name': 'CyberTronchain',
            'address': '0x00B7db6B4431e345eee5cc23D21E8dbC1d5cADA3',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13391/thumb/zNdoAnW.png?1608095358',
            'coingeckoId': 'cybertronchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'EZ',
            'name': 'EasyFi V2',
            'address': '0x00AbA6fE5557De1a1d565658cBDdddf7C710a1eb',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/7332.png',
            'coingeckoId': 'easyfi',
            'listedIn': [
                'coingecko',
                'dfyn',
                'RouterProtocol'
            ]
        },
        {
            'symbol': 'HIENS4',
            'name': 'hiENS4',
            'address': '0x00A7EC2F2b451cb0233E8aDbf4C9a951027C2B02',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26921/thumb/62f9ec6fd1c44e000102051d_MicrosoftTeams-image.png?1660817481',
            'coingeckoId': 'hiens4',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BIX',
            'name': 'Bibox',
            'address': '0x009c43B42AEFAC590C719E971020575974122803',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/1441/thumb/bibox-token.png?1547035519',
            'coingeckoId': 'bibox-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TERA',
            'name': 'Terareum',
            'address': '0x009668A9691e456972c8ec4Cc84e99486308b84D',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23880/thumb/Tera-200x200-1.png?1645600935',
            'coingeckoId': 'terareum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '1SOL',
            'name': '1Sol',
            'address': '0x009178997aFf09A67D4cACcFeB897Fb79d036214',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21615/thumb/YyGDie9f_400x400.jpg?1639610633',
            'coingeckoId': '1sol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPS',
            'name': 'Splintershards',
            'address': '0x00813E3421E1367353BfE7615c7f7f133C89df74',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x00813E3421E1367353BfE7615c7f7f133C89df74/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'STX',
            'name': 'Stox',
            'address': '0x006BeA43Baa3f7A6f765F14f10A1a1b08334EF45',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x006bea43baa3f7a6f765f14f10a1a1b08334ef45.png',
            'coingeckoId': 'stox',
            'listedIn': [
                'coingecko',
                'uniswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'KIBA',
            'name': 'Kiba Inu',
            'address': '0x005D1123878Fc55fbd56b54C73963b234a64af3c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19525/thumb/kiba.png?1648549322',
            'coingeckoId': 'kiba-inu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSD',
            'name': 'Basis Dollar',
            'address': '0x003e0af2916e598Fa5eA5Cb2Da4EDfdA9aEd9Fde',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13409/thumb/bdollar_logo.png?1608263890',
            'coingeckoId': 'basis-dollar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NRFB',
            'name': 'NuriFootBall',
            'address': '0x00281dFCe4cfD72c0b6FDA2AAaF077258743f9E8',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23360/thumb/pcMlP25e_400x400.jpg?1643945452',
            'coingeckoId': 'nurifootball',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PKR',
            'name': 'Polker',
            'address': '0x001A8Ffcb0f03e99141652eBCdecDb0384E3bd6c',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16803/thumb/200x200-PKR_Chip.png?1625589565',
            'coingeckoId': 'polker',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ICB',
            'name': 'Incube Chain',
            'address': '0x00166a5b93FD4f87ecA1c267d31D82dF3f133a7F',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26323/thumb/photo_2022-07-06_16-58-56.jpg?1657248479',
            'coingeckoId': 'incube-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AMON',
            'name': 'AmonD',
            'address': '0x00059AE69c1622A7542EdC15E8d17b060fE307b6',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8952/thumb/AmonD_512x512.png?1563227436',
            'coingeckoId': 'amond',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALCH',
            'name': 'Alchemy',
            'address': '0x0000A1c00009A619684135B824Ba02f7FbF3A572',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14719/thumb/sbEW5W8.png?1617939648',
            'coingeckoId': null,
            'listedIn': [
                'arbitrum_bridge'
            ]
        },
        {
            'symbol': 'DLTA',
            'name': 'delta theta',
            'address': '0x0000000DE40dfa9B17854cBC7869D80f9F98D823',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15697/thumb/logo-DT-square.png?1621570627',
            'coingeckoId': 'delta-theta',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CNV',
            'name': 'Concave',
            'address': '0x000000007a58f5f58E697e51Ab0357BC9e260A04',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24492/thumb/concave.jpg?1648795376',
            'coingeckoId': 'concave',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TGBP',
            'name': 'TrueGBP',
            'address': '0x00000000441378008EA67F4284A57932B1c000a5',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x00000000441378008ea67f4284a57932b1c000a5.png',
            'coingeckoId': null,
            'listedIn': [
                '1inch',
                'sushiswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'TRYC',
            'name': 'TRYC',
            'address': '0x0000000005c6B7C1fd10915a05f034F90d524D6E',
            'decimals': 6,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21985/thumb/16357.png?1640577395',
            'coingeckoId': 'tryc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GST2',
            'name': 'GasToken',
            'address': '0x0000000000b3F879cb30FE243b4Dfee438691c04',
            'decimals': 2,
            'chainId': 1,
            'logoURI': 'https://ethapi.openocean.finance/logos/0x0000000000b3f879cb30fe243b4dfee438691c04.png',
            'coingeckoId': 'gastoken',
            'listedIn': [
                'coingecko',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'LON',
            'name': 'Tokenlon',
            'address': '0x0000000000095413afC295d19EDeb1Ad7B71c952',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0000000000095413afc295d19edeb1ad7b71c952.png',
            'coingeckoId': 'tokenlon',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'lifinance'
            ]
        },
        {
            'symbol': 'TUSD',
            'name': 'TrueUSD',
            'address': '0x0000000000085d4780B73119b644AE5ecd22b376',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0000000000085d4780b73119b644ae5ecd22b376.png',
            'coingeckoId': 'true-usd',
            'listedIn': [
                'coingecko',
                '1inch',
                'sushiswap',
                'openocean',
                'optimism',
                'arbitrum_bridge',
                'lifinance',
                'xyfinance'
            ]
        },
        {
            'symbol': 'XDEX',
            'name': 'XDEFI Governance',
            'address': '0x000000000000d0151E748d25b766e77efe2A6c83',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14485/thumb/logo.png?1616472896',
            'coingeckoId': 'xdefi-governance-token',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'CHI',
            'name': 'Chi Gas',
            'address': '0x0000000000004946c0e9F43F4Dee607b0eF1fA1c',
            'decimals': 0,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0x0000000000004946c0e9f43f4dee607b0ef1fa1c.png',
            'coingeckoId': 'chi-gastoken',
            'listedIn': [
                'coingecko',
                '1inch',
                'lifinance'
            ]
        },
        {
            'symbol': 'BID',
            'name': 'TopBidder',
            'address': '0x00000000000045166C45aF0FC6E4Cf31D9E14B9A',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://assets.coingecko.com/coins/images/14820/thumb/topbidder_logo_square_256.png?1618556813',
            'coingeckoId': 'topbidder',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ETH',
            'name': 'Ethereum',
            'address': '0x0000000000000000000000000000000000000000',
            'decimals': 18,
            'chainId': 1,
            'logoURI': 'https://tokens.1inch.io/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee.png',
            'coingeckoId': 'ethereum',
            'listedIn': [
                '1inch',
                'openocean',
                'optimism',
                'rubic',
                'lifinance',
                'xyfinance'
            ]
        }
    ],
    '-1': [
        {
            'symbol': 'SOLPAY',
            'name': 'SolPay Finance',
            'address': 'zwqe1Nd4eiWyCcqdo4FgCq7LYZHdSeGKKudv6RwiAEn',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/nguilbaud/token-list/main/assets/mainnet/zwqe1Nd4eiWyCcqdo4FgCq7LYZHdSeGKKudv6RwiAEn/logo.png',
            'coingeckoId': 'solpay-finance',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'JUUJUU',
            'name': 'JUUJUU',
            'address': 'zmFoYNC3CuGY1VmgAcxXzfLMnSMVZpJF6RGJU5vDxvT',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/zmFoYNC3CuGY1VmgAcxXzfLMnSMVZpJF6RGJU5vDxvT/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ZBC',
            'name': 'Zebec Protocol',
            'address': 'zebeczgi5fSEtbpfQKVZKCJ3WgYXxjkMUkNNx7fLKAF',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/zebeczgi5fSEtbpfQKVZKCJ3WgYXxjkMUkNNx7fLKAF/logo.png',
            'coingeckoId': 'zebec-protocol',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'apWETH',
            'name': 'Wrapped WETH (Allbridge from Polygon)',
            'address': 'zdABz6DyNFsH62xAoh1RThk2DH2mDPeEiFnmWcJTHTP',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/zdabz6dynfsh62xaoh1rthk2dh2mdpeeifnmwcjthtp/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'CRC',
            'name': 'Care Coin',
            'address': 'z9WZXekbCtwoxyfAwEJn1euXybvqLzPVv3NDzJzkq7C',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/z9WZXekbCtwoxyfAwEJn1euXybvqLzPVv3NDzJzkq7C/logo.png',
            'coingeckoId': 'care-coin',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'OXY',
            'name': 'Oxygen',
            'address': 'z3dn17yLaGMKffVogeFHQ9zWVcXgqgf3PQnDsNs2g6M',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/z3dn17yLaGMKffVogeFHQ9zWVcXgqgf3PQnDsNs2g6M/logo.svg',
            'coingeckoId': 'oxygen',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'CODI',
            'name': 'Codi Finance',
            'address': 'yvbrxE6zjrA8SxxSpL7oojDBB5QDmF5CVqJWea8JcQE',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/yvbrxE6zjrA8SxxSpL7oojDBB5QDmF5CVqJWea8JcQE/logo.png',
            'coingeckoId': 'codi-finance',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'yPRT',
            'name': 'yPRT (Parrot Yield Token)',
            'address': 'yPRTUpLDftNej7p6QofNYgRArRXsm6Mvkzohj4bh4WM',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/yPRTUpLDftNej7p6QofNYgRArRXsm6Mvkzohj4bh4WM/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SLIM',
            'name': 'Solanium',
            'address': 'xxxxa1sKNGwFtw2kFn8XauW9xq8hBZ5kVtcSesTT9fW',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/xxxxa1sKNGwFtw2kFn8XauW9xq8hBZ5kVtcSesTT9fW/logo.png',
            'coingeckoId': 'solanium',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'SRMET',
            'name': 'Serum  Wormhole from Ethereum ',
            'address': 'xnorPhAzWXUczCP3KjU5yDxmKKZi5cSbxytQ1LgE3kG',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/xnorPhAzWXUczCP3KjU5yDxmKKZi5cSbxytQ1LgE3kG/logo.png',
            'coingeckoId': 'serum-wormhole-from-ethereum',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'XALGO',
            'name': 'Wrapped ALGO',
            'address': 'xALGoH1zUfRmpCriy94qbfoMXHtK6NDnMKzT4Xdvgms',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26335/thumb/xALGO.png?1657494713',
            'coingeckoId': 'wrapped-algo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MOONRACE',
            'name': 'Moonrace',
            'address': 'vqU8NVkkgpFtt3YECwuQRD3RhX7LYaqZKrotZbdiBJn',
            'decimals': 3,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/vqU8NVkkgpFtt3YECwuQRD3RhX7LYaqZKrotZbdiBJn/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'USDR',
            'name': 'RockDeFi Stablecoin',
            'address': 'usdrQqxAGgWsBRzzcckAi9ZAzHp19rFCNn87p4Q8Eir',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/usdrQqxAGgWsBRzzcckAi9ZAzHp19rFCNn87p4Q8Eir/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SBNK',
            'name': 'Solbank',
            'address': 'uNrix3Q5g51MCEUrYBUEBDdQ96RQDQspQJJnnQ4T3Vc',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/uNrix3Q5g51MCEUrYBUEBDdQ96RQDQspQJJnnQ4T3Vc/logo.png',
            'coingeckoId': 'solbank-token',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'AIRBOX',
            'name': 'AirBox Token',
            'address': 'tsthEKwxAncDJJUUdLKNfS82Vm6Mk1M7hT9chKnq5C2',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/tsthekwxancdjjuudlknfs82vm6mk1m7ht9chknq5c2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SVT',
            'name': 'Solvent',
            'address': 'svtMpL5eQzdmB3uqK9NXaQkq8prGZoKQFNVJghdWCkV',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/svtMpL5eQzdmB3uqK9NXaQkq8prGZoKQFNVJghdWCkV/logo.png',
            'coingeckoId': 'solvent',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SONAR',
            'name': 'SonarWatch',
            'address': 'sonarX4VtVkQemriJeLm6CKeW3GDMyiBnnAEMw1MRAE',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/sonarX4VtVkQemriJeLm6CKeW3GDMyiBnnAEMw1MRAE/logo.png',
            'coingeckoId': 'sonarwatch',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SODA',
            'name': 'cheesesoda token',
            'address': 'sodaNXUbtjMvHe9c5Uw7o7VAcVpXPHAvtaRaiPVJQuE',
            'decimals': 0,
            'chainId': -1,
            'logoURI': 'https://cdn.jsdelivr.net/gh/solana-labs/token-list@main/assets/mainnet/sodaNXUbtjMvHe9c5Uw7o7VAcVpXPHAvtaRaiPVJQuE/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': '$SOLID',
            'name': 'Solidarity Solutions',
            'address': 'soL6gkvtJa7wtiqHXPZ2G6g2V9ZdT7GzFRJJPxTRS67',
            'decimals': 2,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/sol6gkvtja7wtiqhxpz2g6g2v9zdt7gzfrjjpxtrs67/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SEEDED',
            'name': 'Seeded Network',
            'address': 'seedEDBqu63tJ7PFqvcbwvThrYUkQeqT6NLf81kLibs',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/seedEDBqu63tJ7PFqvcbwvThrYUkQeqT6NLf81kLibs/logo.png',
            'coingeckoId': 'seeded-network',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SRLY',
            'name': 'Rally  Solana ',
            'address': 'sRLY3migNrkC1HLgqotpvi66qGkdNedqPZ9TJpAQhyh',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23239/thumb/srly.png?1643531979',
            'coingeckoId': 'rally-solana',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ROCK',
            'name': 'RockDeFi',
            'address': 'roCKojKezC7HhPxph5qb4UBasvmZJWgegCF57PvaV2f',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/roCKojKezC7HhPxph5qb4UBasvmZJWgegCF57PvaV2f/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'RATIO',
            'name': 'Ratio Governance Token',
            'address': 'ratioMVg27rSZbSvBopUvsdrGUzeALUfFma61mpxc8J',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ratioMVg27rSZbSvBopUvsdrGUzeALUfFma61mpxc8J/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'DAOJONES',
            'name': 'Fractionalized SMB 2367',
            'address': 'r8nuuzXCchjtqsmQZVZDPXXq928tuk7KVH479GsKVpy',
            'decimals': 2,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22611/thumb/daojones.png?1642228974',
            'coingeckoId': 'fractionalized-smb-2367',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'STVA',
            'name': 'SOLtiva',
            'address': 'qXu8Tj65H5XR8KHuaKKoyLCWj592KbTG3YWJwsuFrPS',
            'decimals': 3,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/qXu8Tj65H5XR8KHuaKKoyLCWj592KbTG3YWJwsuFrPS/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'TRTLS',
            'name': 'Turtles',
            'address': 'q4bpaRKw3fJB1AJBeeBaKv3TjYzWsmntLgnSB275YUb',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://www.turtles.com/turtles.png',
            'coingeckoId': 'turtles-token',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'PUPPY',
            'name': 'Puppy',
            'address': 'puppy8Lhckjh768j7vCPLr4244a64ZqioskcXxyb4rC',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/puppy8Lhckjh768j7vCPLr4244a64ZqioskcXxyb4rC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'POLIS',
            'name': 'Star Atlas DAO',
            'address': 'poLisWXnNRwC6oBu1vHiuKQzFjGL4XDSu4g9qjz9qVk',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/poLisWXnNRwC6oBu1vHiuKQzFjGL4XDSu4g9qjz9qVk/logo.png',
            'coingeckoId': 'star-atlas-dao',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'BXS',
            'name': 'Bancambios AX',
            'address': 'pH5wWJc3KhdeVQSt86DU31pdcL9c8P88x2FQoKEJVHC',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25644/thumb/81660481.png?1652949329',
            'coingeckoId': 'bancambios-ax',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASYC',
            'name': 'AsynCoin',
            'address': 'pCKvG8uhzvBc7JEK6bBktwKixesUsbWjxdbLfcuSxh7',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/pckvg8uhzvbc7jek6bbktwkixesusbwjxdblfcusxh7/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'TMI',
            'name': 'TUMI',
            'address': 'p9tNnBf4PDA7WSSFj5EVZddai6WoEiNk5B5FMyeQLtu',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/p9tNnBf4PDA7WSSFj5EVZddai6WoEiNk5B5FMyeQLtu/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ARTC',
            'name': 'Artchive Coin',
            'address': 'p31qJ7LDLNRC57rU5GsXxFGBsnXheFXSsEn3avPoKDc',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/p31qj7ldlnrc57ru5gsxxfgbsnxhefxssen3avpokdc/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ORCA',
            'name': 'Orca',
            'address': 'orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/orcaEKTdK7LKz57vaAYr9QeNsVEPfiu6QeMU1kektZE/logo.png',
            'coingeckoId': 'orca',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'NOS',
            'name': 'Nosana',
            'address': 'nosXBVoaCTtYdLvKY6Csb4AC8JCdQKKAaWYtx2ZMoo7',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/nosXBVoaCTtYdLvKY6Csb4AC8JCdQKKAaWYtx2ZMoo7/logo.png',
            'coingeckoId': 'nosana',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'GTON',
            'name': 'GTON CAPITAL',
            'address': 'nVZnRKdr3pmcgnJvYDE8iafgiMiBqxiffQMcyv5ETdA',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/15728/thumb/GC_logo_200x200.png?1642669327',
            'coingeckoId': 'gton-capital',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CEL',
            'name': 'Celsius (Portal)',
            'address': 'nRtfwU9G82CSHhHGJNxFhtn7FLvWP2rqvQvje1WtL69',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/nRtfwU9G82CSHhHGJNxFhtn7FLvWP2rqvQvje1WtL69/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'MSOL',
            'name': 'Marinade staked SOL',
            'address': 'mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/mSoLzYCxHdYgdzU16g5QSh3i5K3z3KZK7ytfqcJm7So/logo.png',
            'coingeckoId': 'msol',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'KIN',
            'name': 'Kin',
            'address': 'kinXdEcpDQeHPEuQnqmUgtYykqKGVFq6CeVX5iAHJq6',
            'decimals': 5,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/kinXdEcpDQeHPEuQnqmUgtYykqKGVFq6CeVX5iAHJq6/logo.png',
            'coingeckoId': 'kin',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'KKO',
            'name': 'KKO Protocol',
            'address': 'kiNeKo77w1WBEzFFCXrTDRWGRWGP8yHvKC9rX6dqjQh',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://kineko.io/img/cat.jpg',
            'coingeckoId': 'kineko',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'KI',
            'name': 'Genopets KI',
            'address': 'kiGenopAScF8VF31Zbtx2Hg8qA5ArGqvnVtXb83sotc',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26135/thumb/genopets_ki.png?1660017469',
            'coingeckoId': 'genopet-ki',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KZEN',
            'name': 'Kaizen',
            'address': 'kZEn3aDxEzcFADPe2VQ6WcJRbS1hVGjUcgCw4HiuYSU',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24396/thumb/PKl5OVRv_400x400.png?1647522756',
            'coingeckoId': 'kaizen',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KNK',
            'name': 'Kineko',
            'address': 'kNkT1RDnexWqYP3EYGyWv5ZtazB8CfgGAfJtv9AQ3kz',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26597/thumb/knk-cmc-logo.png?1658974690',
            'coingeckoId': 'kineko-knk',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'sBTC',
            'name': 'Solana Bitcoin',
            'address': 'k5Ybbtmnd1eAtBZoTqB9uzd24bVPz8Aip5EGVCJXQCM',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://i.ibb.co/SmxfhQT/sbtc.jpg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'IV',
            'name': 'Invoker',
            'address': 'invSTFnhB1779dyku9vKSmGPxeBNKhdf7ZfGL1vTH3u',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23396/thumb/inv.png?1644132833',
            'coingeckoId': 'invoke',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'IN',
            'name': 'Invictus',
            'address': 'inL8PMVd6iiW3RCBJnr5AsrRN6nqr4BTrcNuQWQSkvY',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/inL8PMVd6iiW3RCBJnr5AsrRN6nqr4BTrcNuQWQSkvY/logo-owl.png',
            'coingeckoId': 'invictus',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'IDOLZ',
            'name': 'IDOLZ Token',
            'address': 'idoLztG5ZGMVEjjoQWjeSu2eVkVsp3YnrFZKf7dNi4j',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://arweave.net/mdvmPYEgiX_uPGRtiIZA3HrpilDK5JfW2bXkDBRuzVM?ext=png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'IVN',
            'name': 'Investin',
            'address': 'iVNcrNE9BRZBC9Aqf753iZiZfbszeAVUoikgT9yvr2a',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/iVNcrNE9BRZBC9Aqf753iZiZfbszeAVUoikgT9yvr2a/logo.png',
            'coingeckoId': 'investin',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'HTO',
            'name': 'Heavenland HTO',
            'address': 'htoHLBJV1err8xP5oxyQdV2PLQhtVjxLXpKB7FsgJQD',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25553/thumb/rTFh6BD.png?1652420842',
            'coingeckoId': 'heavenland-hto',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANKR',
            'name': 'Ankr Network (Wormhole)',
            'address': 'gq2norj1kbembp3mpfkgaumhmmmnfmy4zeyi26trcxfb',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/gq2norj1kbembp3mpfkgaumhmmmnfmy4zeyi26trcxfb/logo.png',
            'coingeckoId': 'ankr-network-wormhole',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'GMSOL',
            'name': 'GMSOL',
            'address': 'gmdu3snwW28DmmxCseChp9owWLUhamH9eS3hWfHG8Vg',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://github.com/icnivadmai/gm-logo/blob/master/logo.png',
            'coingeckoId': 'gmsol',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'FUJI',
            'name': 'Fuji',
            'address': 'fujiCeCeP9AFDVCv27P5JRcKLoH7wfs2C9xmDECs24m',
            'decimals': 3,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/MarkSackerberg/token-list/main/assets/mainnet/fujiCeCeP9AFDVCv27P5JRcKLoH7wfs2C9xmDECs24m/logo.png',
            'coingeckoId': 'fuji',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'FRTN',
            'name': 'Fortune Finance',
            'address': 'frtnaScfGPuo56uyPGmij1QTc64SBdjnXC3RXmcVmxw',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23578/thumb/ZviRqli.png?1644542837',
            'coingeckoId': 'fortune-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'apUSDC',
            'name': 'Wrapped USDC (Allbridge from Polygon)',
            'address': 'eqKJTf1Do4MDPyKisMYqVaUFpkEFAs3riGF3ceDH2Ca',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BXXkv6z8ykpG1yuvUDPgh732wzVHB69RnB9YgSYh3itW/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'AMPL',
            'name': 'Ampleforth (Wormhole)',
            'address': 'ehkqvjgu48ydka4d3rivrknytjtksdos32uafxsx1yak',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/ehkqvjgu48ydka4d3rivrknytjtksdos32uafxsx1yak/logo.png',
            'coingeckoId': 'ampleforth-wormhole',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'EFK',
            'name': 'EFK Token',
            'address': 'efk1hwJ3QNV9dc5qJaLyaw9fhrRdjzDTsxbtWXBh1Xu',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27876/thumb/E_VFDP8f_400x400.jpeg?1666178253',
            'coingeckoId': 'efk-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DELFI',
            'name': 'DeltaFi',
            'address': 'de1QJkP1qDCk5JYCCXCeq27bQQUdCaiv7xVKFrhPSzF',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24085/thumb/logo.png?1646293860',
            'coingeckoId': 'deltafi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'soAAVE',
            'name': 'Wrapped Aave (Sollet)',
            'address': 'dK83wTVypEpa1pqiBbHY3MNuUnT3ADUZM4wk9VZXZEc',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/dK83wTVypEpa1pqiBbHY3MNuUnT3ADUZM4wk9VZXZEc/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'AMP',
            'name': 'Amp (Wormhole)',
            'address': 'd559hwgjygdysxpmfukxhftewutvs9sya1kxiycvogcv',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/d559hwgjygdysxpmfukxhftewutvs9sya1kxiycvogcv/logo.png',
            'coingeckoId': 'amp-wormhole',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'CHICKS',
            'name': 'SolChicks',
            'address': 'cxxShYRVcepDudXhe7U62QHvw8uBJoKFifmzggGKVC2',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/cxxShYRVcepDudXhe7U62QHvw8uBJoKFifmzggGKVC2/logo.png',
            'coingeckoId': 'solchicks-token',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SCUM',
            'name': 'Solana CUM',
            'address': 'cqNTpypmbwghrf1G9VGvSENcw7M7wGSQ7JS8UTQWXwb',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/cqNTpypmbwghrf1G9VGvSENcw7M7wGSQ7JS8UTQWXwb/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'acUSD-USDC',
            'name': 'Saber acUSD-USDC LP',
            'address': 'cUSDDDBZRhpDW7eyUUPMuw6u1SiMnzu6i7movwf5jxk',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/cusdddbzrhpdw7eyuupmuw6u1simnzu6i7movwf5jxk/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'WENSIR',
            'name': 'Wensircoin',
            'address': 'c8JyuF2fD84G6Vk4AmeVfTUoseNRmv2A9JpymAXi4B6',
            'decimals': 7,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/c8JyuF2fD84G6Vk4AmeVfTUoseNRmv2A9JpymAXi4B6/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'NNI',
            'name': 'Neonomad Finance',
            'address': 'buMnhMd5xSyXBssTQo15jouu8VhuEZJCfbtBUZgRcuW',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25068/thumb/SeSkZxx7_400x400.jpeg?1658118217',
            'coingeckoId': 'neonomad-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BMA',
            'name': 'Boom Army',
            'address': 'boomh1LQnwDnHtKxWTFgxcbdRjPypRSjdwxkAEJkFSH',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/boomh1LQnwDnHtKxWTFgxcbdRjPypRSjdwxkAEJkFSH/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'BONES',
            'name': 'Soul Dogs City Bones',
            'address': 'bonegFPgrpZ4bfVn3kQK1aMbGYddWtfMAywNt5LsuVE',
            'decimals': 2,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25025/thumb/JEepabYB_400x400.png?1649838257',
            'coingeckoId': 'soul-dog-city-bones',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BSOL',
            'name': 'BlazeStake Staked SOL',
            'address': 'bSo13r4TkiE4KumL71LsHTPpL2euBYLFx6h9HP3piy1',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26636/thumb/blazesolana.png?1659328728',
            'coingeckoId': 'blazestake-staked-sol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '1INCH',
            'name': '1INCH Token (Wormhole)',
            'address': 'ajkpkq3nsyde1ykcbyzt7n4ak4evv9om9tzhqd3wsrc',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/ajkpkq3nsyde1ykcbyzt7n4ak4evv9om9tzhqd3wsrc/logo.png',
            'coingeckoId': '1inch-wormhole',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'aeUSDT-USDT',
            'name': 'Saber aeUSDT-USDT LP',
            'address': 'aeTwxcJhujVCq6rwbJri3s6ViYifsJUCFirMjLHgHZ7',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/aetwxcjhujvcq6rwbjri3s6viyifsjucfirmjlhghz7/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'aeDAI-USDC',
            'name': 'Saber aeDAI-USDC LP',
            'address': 'aeDebgky5BssqgLo426rXoQTmGrAn1JjEXp6aXFNLic',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/aedebgky5bssqglo426rxoqtmgran1jjexp6axfnlic/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ABR',
            'name': 'Allbridge',
            'address': 'a11bdAAuV8iB2fu7X6AxAvDTo1QZ8FXB3kk5eecdasp',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/allbridge-io/media/main/token.svg',
            'coingeckoId': 'allbridge',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'wstETH',
            'name': 'Lido Wrapped Staked ETH',
            'address': 'ZScHuTtqZukUrtZS43teTKGs2VqkKL8k4QCouR2n6Uo',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ZScHuTtqZukUrtZS43teTKGs2VqkKL8k4QCouR2n6Uo/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ZEE',
            'name': 'ZEE',
            'address': 'ZEExktbqMM5ZMS569pCNbzky92KeEmiFeVwR3exfBNn',
            'decimals': 0,
            'chainId': -1,
            'logoURI': 'https://cdn.jsdelivr.net/gh/solana-labs/token-list@main/assets/mainnet/ZEExktbqMM5ZMS569pCNbzky92KeEmiFeVwR3exfBNn/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'CHB',
            'name': 'Charactbit',
            'address': 'YtfMZ4jg2ubdz4GasY86iuGjHdo5rCPJnFqgSf8gxAz',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24863/thumb/200x_logo.png?1649165917',
            'coingeckoId': 'charactbit',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YAW',
            'name': 'Yawww',
            'address': 'YAWtS7vWCSRPckx1agB6sKidVXiXiDUfehXdEUSRGKE',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/YAWtS7vWCSRPckx1agB6sKidVXiXiDUfehXdEUSRGKE/yaw.png',
            'coingeckoId': 'yawww',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'STEAK',
            'name': 'STEAK',
            'address': 'Y71XaLmJPvuPY4h4LnTZfFgSR6vP3qCxGLpCx25JTMA',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Y71XaLmJPvuPY4h4LnTZfFgSR6vP3qCxGLpCx25JTMA/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'BURR',
            'name': 'Burrito Boyz Floor Index',
            'address': 'XwTZraiF1dVh69cZ2SpqyjDLmei2uVps5CYHD9vqK6d',
            'decimals': 2,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27169/thumb/7ryKn0fdux0PnpBEiwmop9DaFq2KG8WHmGhhtOYPoAU.png?1662286753',
            'coingeckoId': 'burrito-boyz-floor-index',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WNZ',
            'name': 'Winerz',
            'address': 'WNZzxM1WqWFH8DpDZSqr6EoHKWXeMx9NLLd2R5RzGPA',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24786/thumb/wnz.png?1648905714',
            'coingeckoId': 'winerz',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BABY',
            'name': 'Baby Samo Coin',
            'address': 'Uuc6hiKT9Y6ASoqs2phonGGw2LAtecfJu9yEohppzWH',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Uuc6hiKT9Y6ASoqs2phonGGw2LAtecfJu9yEohppzWH/logo.png',
            'coingeckoId': 'baby-samo-coin',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'UXP',
            'name': 'UXD Protocol',
            'address': 'UXPhBoR3qG4UCiGNJfV7MqhHyFqKN68g45GoYvAeL2M',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/UXPhBoR3qG4UCiGNJfV7MqhHyFqKN68g45GoYvAeL2M/uxp-icon-black.png',
            'coingeckoId': 'uxd-protocol-token',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'swtUST-9',
            'name': 'Saber Wrapped UST (Portal) (9 decimals)',
            'address': 'UST98bfV6EASdTFQrRwCBczpehdMFwYCUdLT5tEbhpW',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/UST98bfV6EASdTFQrRwCBczpehdMFwYCUdLT5tEbhpW/icon.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'sUST-8',
            'name': 'Saber Wrapped UST (Portal) (8 decimals)',
            'address': 'UST8SCn7jrqsq51odVLqcmvnC658HkqrKrPL3w2hHQ7',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/UST8SCn7jrqsq51odVLqcmvnC658HkqrKrPL3w2hHQ7/icon.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'USDR',
            'name': 'Ratio Stable Coin',
            'address': 'USDrbBQwQbQ2oWHUPfA8QBHcyVxKUq1xHyXsSLKdUq2',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26066/thumb/usdr_logo.png?1655469254',
            'coingeckoId': 'ratio-stable-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USDH',
            'name': 'USDH Hubble Stablecoin',
            'address': 'USDH1SM1ojwWUga67PGrgFWUHibbjqMvuMaDkRJTgkX',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/USDH1SM1ojwWUga67PGrgFWUHibbjqMvuMaDkRJTgkX/usdh.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'UNQ',
            'name': 'Unique Venture clubs',
            'address': 'UNQtEecZ5Zb4gSSVHCAWUQEoNnSVEbWiKCi1v9kdUJJ',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/UNQtEecZ5Zb4gSSVHCAWUQEoNnSVEbWiKCi1v9kdUJJ/logo.png',
            'coingeckoId': 'unq',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'TULIP',
            'name': 'Tulip Protocol',
            'address': 'TuLipcqtGVXP9XR62wM8WWCm6a9vhLs7T1uoWBk6FDs',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/sol-farm/token-logos/main/tulip.png',
            'coingeckoId': 'solfarm',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'TAKI',
            'name': 'Taki',
            'address': 'Taki7fi3Zicv7Du1xNAWLaf6mRK7ikdn77HeGzgwvo4',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25271/thumb/logo.png?1651118069',
            'coingeckoId': 'taki',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'sUSDT-8',
            'name': 'Saber Wrapped USDT (8 decimals)',
            'address': 'T8KdT8hDzNhbGx5sjpEUxepnbDB1TZoCa7vtC5JjsMw',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/T8KdT8hDzNhbGx5sjpEUxepnbDB1TZoCa7vtC5JjsMw/icon.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SB',
            'name': 'SuperBonds',
            'address': 'SuperbZyz7TsSdSoFAZ6RYHfAWe9NmjXBLVQpS8hqdx',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SuperbZyz7TsSdSoFAZ6RYHfAWe9NmjXBLVQpS8hqdx/logo.svg',
            'coingeckoId': 'superbonds',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'STEP',
            'name': 'Step Finance',
            'address': 'StepAscQoEioFxxWGnh2sLBDFp9d8rvKz2Yp39iDpyT',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/StepAscQoEioFxxWGnh2sLBDFp9d8rvKz2Yp39iDpyT/logo.png',
            'coingeckoId': 'step-finance',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'AMTSOL',
            'name': 'Amulet Staked SOL',
            'address': 'SoLW9muuNQmEAoBws7CWfYQnXRXMVEG12cQhy6LE2Zf',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27786/thumb/amtSOL200x200.png?1665814113',
            'coingeckoId': 'amulet-staked-sol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOL',
            'name': 'Wrapped Solana',
            'address': 'So11111111111111111111111111111111111111112',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png',
            'coingeckoId': 'wrapped-solana',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SOL',
            'name': 'Solana',
            'address': 'So11111111111111111111111111111111111111111',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/so11111111111111111111111111111111111111111/logo.png',
            'coingeckoId': 'solana',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SBR',
            'name': 'Saber',
            'address': 'Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Saber2gLauYim4Mvftnrasomsv6NvAuncvMEZwcLpD1/logo.svg',
            'coingeckoId': 'saber',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SWAN',
            'name': 'Swanlana',
            'address': 'SWANaZUGxF82KyVsbxeeNsMaVECtimze5VyCdywkvkH',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/SwanLana/logo/main/SWANLANA_PNG.png',
            'coingeckoId': 'swanlana',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SUNNY',
            'name': 'Sunny Aggregator',
            'address': 'SUNNYWgPQmFxe9wTZzNK7iPnJ3vYDrkgnxJRJm1s3ag',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SUNNYWgPQmFxe9wTZzNK7iPnJ3vYDrkgnxJRJm1s3ag/logo.svg',
            'coingeckoId': 'sunny-aggregator',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SRM',
            'name': 'Serum',
            'address': 'SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SRMuApVNdxXokk5GT7XD5cUUgXMBCoAz2LHeuAoKWRt/logo.png',
            'coingeckoId': 'serum',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'SQR',
            'name': 'Magic Square',
            'address': 'SQRNmMb9mKjjkihQS7fCmAwo3gVs1FSQBVeDZzA7CP3',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26377/thumb/A-WlK2Dl_400x400.jpeg?1657615169',
            'coingeckoId': 'magic-square',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SNS',
            'name': 'Synesis One',
            'address': 'SNSNkV9zfG5ZKWQs6x4hxvBRV6s8SqMfSGCtECDvdMd',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/Synesis-One/spl-token/main/icon.png',
            'coingeckoId': 'synesis-one',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SLT',
            'name': 'Solit',
            'address': 'SLT3iSYKeBuCyxvnfij4RUhMfKxZCY3s12Z5pfkTXhV',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SLT3iSYKeBuCyxvnfij4RUhMfKxZCY3s12Z5pfkTXhV/logo.png',
            'coingeckoId': 'solit',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SLRS',
            'name': 'Solrise Finance',
            'address': 'SLRSSpSLUTP7okbCUBYStWCo1vUgyt775faPqz8HUMr',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SLRSSpSLUTP7okbCUBYStWCo1vUgyt775faPqz8HUMr/logo.png',
            'coingeckoId': 'solrise-finance',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SLND',
            'name': 'Solend',
            'address': 'SLNDpmoWTVADgEdndyvWzroNL7zSi1dF9PC3xHGtPwp',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SLNDpmoWTVADgEdndyvWzroNL7zSi1dF9PC3xHGtPwp/logo.png',
            'coingeckoId': 'solend',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SLNA',
            'name': 'Soluna',
            'address': 'SLNAAQ8VT6DRDc3W9UPDjFyRt7u4mzh8Z4WYMDjJc35',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24845/thumb/icon.f97a40025f9cd28101e4903f23b304ff.png?1649112521',
            'coingeckoId': 'soluna',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SLCL',
            'name': 'Solcial',
            'address': 'SLCLww7nc1PD2gQPQdGayHviVVcpMthnqUz2iWKhNQV',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SLCLww7nc1PD2gQPQdGayHviVVcpMthnqUz2iWKhNQV/logo.png',
            'coingeckoId': 'solcial',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'sETH-8',
            'name': 'Saber Wrapped Ethereum (Sollet) (8 decimals)',
            'address': 'SL819j8K9FuFPL84UepVcFkEZqDUUvVzwDmJjCHySYj',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SL819j8K9FuFPL84UepVcFkEZqDUUvVzwDmJjCHySYj/icon.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SHDW',
            'name': 'Shadow Token',
            'address': 'SHDWyBxihqiCj6YekG2GUr7wqKLeLAMK1gHZck9pL6y',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SHDWyBxihqiCj6YekG2GUr7wqKLeLAMK1gHZck9pL6y/logo.png',
            'coingeckoId': 'genesysgo-shadow',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SGT',
            'name': 'Solana Ghoest Token',
            'address': 'SGTdtpAiPU1Fg9a3DqUN1852V24sfo92ePEHpyqkrSN',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SGTdtpAiPU1Fg9a3DqUN1852V24sfo92ePEHpyqkrSN/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'soSXP',
            'name': 'Wrapped SXP (Sollet)',
            'address': 'SF3oTvfWzEP3DTwGSvUXRrGTvr75pdZNnBLAH9bzMuX',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SF3oTvfWzEP3DTwGSvUXRrGTvr75pdZNnBLAH9bzMuX/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SNTR',
            'name': 'Sentre',
            'address': 'SENBBKVCM7homnf5RX9zqpf1GFe935hnbU4uVzY1Y6M',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19268/thumb/sentre.PNG?1634866010',
            'coingeckoId': 'sentre',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SCY',
            'name': 'Synchrony',
            'address': 'SCYfrGCw8aDiqdgcpdGjV6jp4UVVQLuphxTDLNWu36f',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SCYfrGCw8aDiqdgcpdGjV6jp4UVVQLuphxTDLNWu36f/logo.png',
            'coingeckoId': 'synchrony',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'sBTC-8',
            'name': 'Saber Wrapped Bitcoin (Sollet) (8 decimals)',
            'address': 'SBTCB6pWqeDo6zGi9WVRMLCsKsN6JiR1RMUqvLtgSRv',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SBTCB6pWqeDo6zGi9WVRMLCsKsN6JiR1RMUqvLtgSRv/icon.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SAMU',
            'name': 'Samusky',
            'address': 'SAMUmmSvrE8yqtcG94oyP1Zu2P9t8PSRSV3vewsGtPM',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/SAMUmmSvrE8yqtcG94oyP1Zu2P9t8PSRSV3vewsGtPM/logo.png',
            'coingeckoId': 'samusky-token',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'RLB',
            'name': 'Rollbit Coin',
            'address': 'RLBxxFkseAZ4RgJH3Sqn8jXxhmGoz9jWxDNJMh8pL7a',
            'decimals': 2,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/RLBxxFkseAZ4RgJH3Sqn8jXxhmGoz9jWxDNJMh8pL7a/logo.png',
            'coingeckoId': 'rollbit-coin',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'APEM',
            'name': 'APEMOON',
            'address': 'QuYNbuTjnAUQ8YxtrmGfu8P1UAEvcG3CngFpXCo3Cts',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/quynbutjnauq8yxtrmgfu8p1uaevcg3cngfpxco3cts/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BURD',
            'name': 'tudaBirds',
            'address': 'Qikhhhg9Ta3Jg7WoDFbSYuCAE14hx9hPvdz1zVp3zUw',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Qikhhhg9Ta3Jg7WoDFbSYuCAE14hx9hPvdz1zVp3zUw/logo.png',
            'coingeckoId': 'tudabirds',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'PSY',
            'name': 'PsyOptions',
            'address': 'PsyFiqqjiv41G7o5SMRzDJCu4psptThNR2GtfeGHfSq',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://user-images.githubusercontent.com/32071703/149460918-3694084f-2a37-4c95-93d3-b5aaf078d444.png',
            'coingeckoId': 'psyoptions',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'PORT',
            'name': 'Port Finance',
            'address': 'PoRTjZMPXb9T7dyU7tpLEZRQj7e6ssfAE62j2oQuc6y',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PoRTjZMPXb9T7dyU7tpLEZRQj7e6ssfAE62j2oQuc6y/PORT.png',
            'coingeckoId': 'port-finance',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'POLE',
            'name': 'Pole Aggregator Governance Token',
            'address': 'PoLEXM5xikzieXENFvP7WwHJPorcFiCUVuMo9BAGZT3',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PoLEXM5xikzieXENFvP7WwHJPorcFiCUVuMo9BAGZT3/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'PRIME',
            'name': 'SolanaPrime',
            'address': 'PRiME7gDoiG1vGr95a3CRMv9xHY7UGjd4JKvfSkmQu2',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25911/thumb/solana_black200x200.png?1654587637',
            'coingeckoId': 'solanaprime',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PRT',
            'name': 'Parrot Protocol',
            'address': 'PRT88RkA4Kg5z7pKnezeNH4mafTvtQdfFgpQTGRjz44',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PRT88RkA4Kg5z7pKnezeNH4mafTvtQdfFgpQTGRjz44/logo.svg',
            'coingeckoId': 'parrot-protocol',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'PRISM',
            'name': 'Prism',
            'address': 'PRSMNsEPqhGVCH1TtWiJqPjJyh2cKrLostPZTNy1o5x',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PRSMNsEPqhGVCH1TtWiJqPjJyh2cKrLostPZTNy1o5x/logo.svg',
            'coingeckoId': 'prism',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'PRANA',
            'name': 'Nirvana prANA',
            'address': 'PRAxfbouRoJ9yZqhyejEAH6RvjJ86Y82vfiZTBSM3xG',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/PRAxfbouRoJ9yZqhyejEAH6RvjJ86Y82vfiZTBSM3xG/logo.png',
            'coingeckoId': 'nirvana-prana',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'apUSDT-USDT',
            'name': 'Saber apUSDT-USDT LP',
            'address': 'PLYJZgSkcV8UXTWhTyf2WLCMeBoZum1Y4rXgXkoYiNj',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/plyjzgskcv8uxtwhtyf2wlcmebozum1y4rxgxkoyinj/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'NSPACE',
            'name': 'My NFT Space',
            'address': 'NpgsBSfavf5hmUeGQAbMz5pHDtXhn9ZFNRQypTr8Tfv',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NpgsBSfavf5hmUeGQAbMz5pHDtXhn9ZFNRQypTr8Tfv/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'NAGA',
            'name': 'Naga Kingdom',
            'address': 'NaFJTgvemQFfTTGAq2PR1uBny3NENWMur5k6eBsG5ii',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24895/thumb/Naga-200x200.png?1649304588',
            'coingeckoId': 'naga-kingdom',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NIRV',
            'name': 'Nirvana NIRV',
            'address': 'NRVwhjBQiUPYtfDT5zRBVJajzFQHaBUNtC7SNVvqRFa',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NRVwhjBQiUPYtfDT5zRBVJajzFQHaBUNtC7SNVvqRFa/NIRV.png',
            'coingeckoId': 'nirvana-nirv',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'AINU',
            'name': 'AvatarInu',
            'address': 'NJdK95TPKguYLUzhNPEumEbwC7cjciEQUzG4UrvhcJv',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/njdk95tpkguyluzhnpeumebwc7cjciequzg4urvhcjv/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'YAKU',
            'name': 'Yaku',
            'address': 'NGK3iHqqQkyRZUj4uhJDQqEyKKcZ7mdawWpqwMffM3s',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26785/thumb/_YAKU_logo.png?1660101303',
            'coingeckoId': 'yaku',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLOCK',
            'name': 'Blockasset',
            'address': 'NFTUkR4u7wKxy9QLaX2TGvd9oZSWoMo4jqSJqdMb7Nk',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NFTUkR4u7wKxy9QLaX2TGvd9oZSWoMo4jqSJqdMb7Nk/logo.png',
            'coingeckoId': 'blockasset',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'EWOOF',
            'name': 'ElonWoof',
            'address': 'NA45Qgq1xn2EcrrKik7o9rVPMSgmDXK6kv8134Q8ADW',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/NA45Qgq1xn2EcrrKik7o9rVPMSgmDXK6kv8134Q8ADW/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'MOSHI',
            'name': 'Moshiheads',
            'address': 'MoshMwLkVu4iwrPBaWpYkh43qJiSXsnyzNLuMXFv5F4',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MoshMwLkVu4iwrPBaWpYkh43qJiSXsnyzNLuMXFv5F4/logo.png',
            'coingeckoId': 'moshiheads',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'MEK',
            'name': 'MekkaCoin',
            'address': 'MekkANZkBpzbGeTWsD1cRRuQxZMTFfBJyLrKywGQRss',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27530/thumb/unknown_%2819%29.png?1664372136',
            'coingeckoId': 'mekkacoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DPUNKZ',
            'name': 'Duck Punkz Universe Floor Index',
            'address': 'McpgFn2CxFYFq6JLiBxeC6viNfebLsfsf9Sv5wcwKvL',
            'decimals': 2,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27171/thumb/2588.png?1662287086',
            'coingeckoId': 'duck-punkz-universe-floor-index',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MNGO',
            'name': 'Mango',
            'address': 'MangoCzJ36AjZyKwVj3VnYU4GTonjfVEnJmvvWaxLac',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MangoCzJ36AjZyKwVj3VnYU4GTonjfVEnJmvvWaxLac/token.png',
            'coingeckoId': 'mango-markets',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'MAGA',
            'name': 'Magic Eggs',
            'address': 'Ma4dse7fmzXLQYymNsDDjq6VgRXtEFTJw1CvmRrBoKN',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24843/thumb/Maga-200x200.png?1649107298',
            'coingeckoId': 'magic-eggs',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MNDE',
            'name': 'Marinade',
            'address': 'MNDEFzGvMt87ueuHvVU9VcTqsAP5b3fTGPsHuuPA5ey',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MNDEFzGvMt87ueuHvVU9VcTqsAP5b3fTGPsHuuPA5ey/logo.png',
            'coingeckoId': 'marinade',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'MMA',
            'name': 'MMA Gaming',
            'address': 'MMAx26JtJgSWv6yH48nEHCGZcVvRbf9Lt9ALa7jSipe',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MMAx26JtJgSWv6yH48nEHCGZcVvRbf9Lt9ALa7jSipe/logo.png',
            'coingeckoId': 'mma-gaming',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'MILK',
            'name': 'MILK',
            'address': 'MLKmUCaj1dpBY881aFsrBwR9RUMoKic8SWT3u1q5Nkj',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27168/thumb/UClogoMLKtoken.png?1662285921',
            'coingeckoId': 'udder-chaos-milk',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MGM',
            'name': 'Maho Genies',
            'address': 'MGM57zGF6ghF9Aax7FfoPHfxuoLKuEGM6twJALyCFqx',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MGM57zGF6ghF9Aax7FfoPHfxuoLKuEGM6twJALyCFqx/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SLC',
            'name': 'Solice',
            'address': 'METAmTMXwdb8gYzyCPfXXFmZZw4rUsXX58PNsDg7zjL',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/METAmTMXwdb8gYzyCPfXXFmZZw4rUsXX58PNsDg7zjL/logo.png',
            'coingeckoId': 'solice',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'MPLX',
            'name': 'Metaplex',
            'address': 'METAewgxyPbgwsseH8T16a39CQ5VyVxZi9zXiDPY18m',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27344/thumb/mplx.png?1663636769',
            'coingeckoId': 'metaplex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MER',
            'name': 'Mercurial',
            'address': 'MERt85fc5boKw3BW1eYdxonEuJNvXbiMbs6hvheau5K',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MERt85fc5boKw3BW1eYdxonEuJNvXbiMbs6hvheau5K/logo.png',
            'coingeckoId': 'mercurial',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'MEAN',
            'name': 'Mean DAO',
            'address': 'MEANeD3XDdUmNMsRGjASkSWdC8prLYsoRJ61pPeHctD',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MEANeD3XDdUmNMsRGjASkSWdC8prLYsoRJ61pPeHctD/logo.svg',
            'coingeckoId': 'meanfi',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'MAPS',
            'name': 'MAPS',
            'address': 'MAPS41MDahZ9QdKXhVa4dWB9RuyfV4XqhyAZ8XcYepb',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/MAPS41MDahZ9QdKXhVa4dWB9RuyfV4XqhyAZ8XcYepb/logo.svg',
            'coingeckoId': 'maps',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'LARIX',
            'name': 'Larix',
            'address': 'Lrxqnh6ZHKbGy3dcrCED43nsoLkM1LTzU2jRfWe8qUC',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Lrxqnh6ZHKbGy3dcrCED43nsoLkM1LTzU2jRfWe8qUC/logo.jpg',
            'coingeckoId': 'larix',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'LILY',
            'name': 'Solily Protocol',
            'address': 'LiLyT885cG9xZKYQk9x6VWMzmcui4ueV9J1uzPDDajY',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25611/thumb/logo200.png?1652846780',
            'coingeckoId': 'solily-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LUST',
            'name': 'Succuverse',
            'address': 'LUSTdLASZy86pR6V5VjMpXxW9oVtCQt8q3fJ9iHZtPY',
            'decimals': 3,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/dynaris/lust/main/icon.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'sLUNA-9',
            'name': 'Saber Wrapped LUNA (Portal) (9 decimals)',
            'address': 'LUNGEjUXyP48nrC1GYY5o4eTAkwm4RdX8BxFUxWJBLB',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/LUNGEjUXyP48nrC1GYY5o4eTAkwm4RdX8BxFUxWJBLB/icon.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'LPFI',
            'name': 'LP Finance DAO',
            'address': 'LPFiNAybMobY5oHfYVdy9jPozFBGKpPiEGoobK2xCe3',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27704/thumb/output-onlinepngtools_%2827%29.png?1666087863',
            'coingeckoId': 'lp-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LFNTY',
            'name': 'Lifinity',
            'address': 'LFNTYraetVioAPnGJht4yNg2aUZFXR776cMeN9VMjXp',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25406/thumb/LFNTY_s.png?1651731251',
            'coingeckoId': 'lifinity',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LAINESOL',
            'name': 'Laine Stake',
            'address': 'LAinEtNLgpmCP9Rvsf5Hn8W6EhNiKLZQti1xfWMLy6X',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28184/thumb/laineSOL.png?1668257050',
            'coingeckoId': 'laine-stake',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LABS',
            'name': 'LABS Protocol',
            'address': 'LABSfApdYpC5Ek1tQiCFAoQP5K8CvADe2GgdYrA2QHh',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26913/thumb/monsta-scientist.jpg?1660787720',
            'coingeckoId': 'labs-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AVAX',
            'name': 'Avalanche  Wormhole ',
            'address': 'KgV1GvrHQmRBY8sHQQeUKwTm2r2h8t4C8qt12Cw1HVE',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KgV1GvrHQmRBY8sHQQeUKwTm2r2h8t4C8qt12Cw1HVE/logo.png',
            'coingeckoId': 'avalanche-wormhole',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'CUBE',
            'name': 'Cubecoin',
            'address': 'KUPoVbJmipJb1M7xzQEND5w7u1BbmBytu9wZ2QPjQx4',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KUPoVbJmipJb1M7xzQEND5w7u1BbmBytu9wZ2QPjQx4/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'srenLUNA-9',
            'name': 'Saber Wrapped renLUNA (9 decimals)',
            'address': 'KUANeD8EQvwpT1W7QZDtDqctLEh2FfSTy5pThE9CogT',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KUANeD8EQvwpT1W7QZDtDqctLEh2FfSTy5pThE9CogT/icon.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'swhETH-9',
            'name': 'Saber Wrapped Ether (Portal) (9 decimals)',
            'address': 'KNVfdSJyq1pRQk9AKKv1g5uyGuk6wpm4WG16Bjuwdma',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/KNVfdSJyq1pRQk9AKKv1g5uyGuk6wpm4WG16Bjuwdma/icon.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'JTT',
            'name': 'Japan Travel Token',
            'address': 'JTTez7NDqtU4ZqZJmLLXt6K9f75izfTApQqmvMCn4jU',
            'decimals': 0,
            'chainId': -1,
            'logoURI': 'http://www.japantravel.me/jtticon.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'JET',
            'name': 'JET',
            'address': 'JET6zMJWkCN9tpRT2v2jfAmm5VnQFDpUBCyaKojmGtz',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JET6zMJWkCN9tpRT2v2jfAmm5VnQFDpUBCyaKojmGtz/logo.png',
            'coingeckoId': 'jet',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SWIM',
            'name': 'SWIM',
            'address': 'JEHHZr57hJ7By3dL74HB9G9R77ZrTvDr1P2vSjQCAewF',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://arweave.net/x5q9Se-3YMgTRaZg8tfHRaQVnGnMbAw7Jd6F-I9fplE?ext=png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'sUSDC-9',
            'name': 'Saber Wrapped USD Coin (9 decimals)',
            'address': 'JEFFSQ3s8T3wKsvp4tnRAsUBW7Cqgnf8ukBZC4C8XBm1',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JEFFSQ3s8T3wKsvp4tnRAsUBW7Cqgnf8ukBZC4C8XBm1/icon.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ThugPandas',
            'name': 'Thug Pandas',
            'address': 'JBK72yMfskz6tkaDCSM6bmaTf6ub7zDZUgBNxTWRAx8p',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JBK72yMfskz6tkaDCSM6bmaTf6ub7zDZUgBNxTWRAx8p/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ABP',
            'name': 'ABP token',
            'address': 'JB9sPerGhfdwCDajmd8x4y2gkC4EtXVevoAoPwni39ik',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/jb9sperghfdwcdajmd8x4y2gkc4etxvevoaopwni39ik/logo.png',
            'coingeckoId': 'asset-backed-protocol',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SOLUST',
            'name': 'solUST',
            'address': 'JAa3gQySiTi8tH3dpkvgztJWHQC1vGXr5m6SQ9LEM55T',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/JAa3gQySiTi8tH3dpkvgztJWHQC1vGXr5m6SQ9LEM55T/solust.svg',
            'coingeckoId': 'solust',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'MONGOOSE',
            'name': 'MongooseCoin',
            'address': 'J7WYVzFNynk9D28eBCccw2EYkygygiLDCVCabV7CupWL',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J7WYVzFNynk9D28eBCccw2EYkygygiLDCVCabV7CupWL/logo.png',
            'coingeckoId': 'mongoosecoin',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'FAROUT',
            'name': 'Far-Out Token',
            'address': 'J5gLhk6mmQ4PSoir1Ufh8JY2ytEHA93YupzYiTFVCgcL',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/J5gLhk6mmQ4PSoir1Ufh8JY2ytEHA93YupzYiTFVCgcL/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'GRUNT',
            'name': 'GRUNT Token',
            'address': 'J4tV8qjZyzwsYhGmPREDEyehCusPwa7uYm3UssQ6X4A8',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/derysendiry/orang/main/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ABION',
            'name': 'aBion',
            'address': 'J3ts1ZEyQeUAbUyYHjZR6sE93YQTrfBzho8UKWnEa1j',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/j3ts1zeyqeuabuyyhjzr6se93yqtrfbzho8ukwnea1j/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'JITOSOL',
            'name': 'Jito Staked SOL',
            'address': 'J1toso1uCk3RLmjorhTtrVwY9HJ7X8V9yYac6Y7kGCPn',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28046/thumb/JitoSOL-200.png?1667271467',
            'coingeckoId': 'jito-staked-sol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AXSET',
            'name': 'Axie Infinity Shard  Wormhole ',
            'address': 'HysWcbHiYY9888pHbaqhwLYZQeZrcQMXKQWRqS7zcPK5',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HysWcbHiYY9888pHbaqhwLYZQeZrcQMXKQWRqS7zcPK5/logo.png',
            'coingeckoId': 'axie-infinity-shard-wormhole',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'HXRO',
            'name': 'Hxro',
            'address': 'HxhWkVpk5NS4Ltg5nij2G671CKXFRKPK8vy271Ub4uEK',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HxhWkVpk5NS4Ltg5nij2G671CKXFRKPK8vy271Ub4uEK/logo.png',
            'coingeckoId': 'hxro',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'ZAP',
            'name': 'Zap',
            'address': 'HxPoEHMt1vKeqjKCePcqTj6yYgn6Xqq1fKTY3Pjx4YrX',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2180/thumb/zap.png?1547036476',
            'coingeckoId': 'zap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATHENA',
            'name': 'ATHENA',
            'address': 'Htqzmit2pAYcTzWKYnwxM7QeX8nEQXMj8SEjWUuixVcB',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/htqzmit2payctzwkynwxm7qex8neqxmj8sejwuuixvcb/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DKM',
            'name': 'Dead Knight',
            'address': 'HtbhBYdcfXbbD2JiH6jtsTt2m2FXjn7h4k6iXfz98k5W',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24442/thumb/SAq1GaQc_400x400.jpg?1647673953',
            'coingeckoId': 'dead-knight',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADOR OPENS',
            'name': 'ADOR OPENS',
            'address': 'HsY8PNar8VExU335ZRYzg89fX7qa4upYu6vPMPFyCDdK',
            'decimals': 0,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/hsy8pnar8vexu335zryzg89fx7qa4upyu6vpmpfycddk/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'soHNT',
            'name': 'Wrapped Helium (Sollet)',
            'address': 'HqB7uswoVg4suaQiDP3wjxob1G5WdZ144zhdStwMCq7e',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HqB7uswoVg4suaQiDP3wjxob1G5WdZ144zhdStwMCq7e/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'FLOCK',
            'name': 'Flock',
            'address': 'Hq9MuLDvUAWqC29JhqP2CUJP9879LfqNBHyRRREEXwtZ',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22775/thumb/fUNf0Lu.png?1642577185',
            'coingeckoId': 'flock',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALCE',
            'name': 'AlchemyCoin',
            'address': 'HowJ6tCJmQ1qtcNpd76mxX8KaukxHhzWYdvcJYPmFFKr',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/howj6tcjmq1qtcnpd76mxx8kaukxhhzwydvcjypmffkr/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FCON',
            'name': 'Space Falcon',
            'address': 'HovGjrBGTfna4dvg6exkMxXuexB3tUfEZKcut8AWowXj',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/spacefalcon-io/crypto/main/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'HONEY',
            'name': 'Honey Finance',
            'address': 'HonyeYAaTPgKUgQpayL914P6VAqbQZPrbkGMETZvW4iN',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HonyeYAaTPgKUgQpayL914P6VAqbQZPrbkGMETZvW4iN/logo.svg',
            'coingeckoId': 'honey-finance',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'SUCH',
            'name': 'Such Shiba',
            'address': 'HnZiKrSKYQkEfzjQs6qkvuGbBmrBP9YzjB1SMM7tiGZ1',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HnZiKrSKYQkEfzjQs6qkvuGbBmrBP9YzjB1SMM7tiGZ1/logo.png',
            'coingeckoId': 'such-shiba',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'PSOL',
            'name': 'Parasol Finance',
            'address': 'Hmatmu1ktLbobSvim94mfpZmjL5iiyoM1zidtXJRAdLZ',
            'decimals': 7,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/parasol-finance/white-paper/main/logo.png',
            'coingeckoId': 'parasol-finance',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SHLT',
            'name': 'Sehlat',
            'address': 'Hjc6Ku7VpMD8TqPUuimDXWvT3RWpnbm1viaUe3dUco3L',
            'decimals': 2,
            'chainId': -1,
            'logoURI': 'https://cdn.jsdelivr.net/gh/solana-labs/token-list@main/assets/mainnet/Hjc6Ku7VpMD8TqPUuimDXWvT3RWpnbm1viaUe3dUco3L/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'afDAI',
            'name': 'Wrapped DAI (Allbridge from Fantom)',
            'address': 'HjUhUzi6fVkY1BndaSc4Dcg2mCzvnqzXjVJtXsj78ver',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FYpdBuyAHSbdaAyD1sKkxyLWbAP8uUW9h6uvdhK74ij1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'MERIT',
            'name': 'Single Earth',
            'address': 'HjUMVG3yQK7uMTq1TerG6C8JzAjRvMdYCoX7ZUzKTgjH',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27020/thumb/logo_%284%29.png?1661482963',
            'coingeckoId': 'single-earth',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SNJ',
            'name': 'Sola Ninja',
            'address': 'Hj4sTP4L4rvR9WBR6KyK99sxPptBQQczNWe4y15mxhRD',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/SolaToken/token-list/ninja/assets/mainnet/Hj4sTP4L4rvR9WBR6KyK99sxPptBQQczNWe4y15mxhRD/logo.png',
            'coingeckoId': 'sola-ninja',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'CREAM',
            'name': 'Cream (Portal)',
            'address': 'HihxL2iM6L6P1oqoSeiixdJ3PhPYNxvSKH9A2dDqLVDH',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HihxL2iM6L6P1oqoSeiixdJ3PhPYNxvSKH9A2dDqLVDH/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ESOL',
            'name': 'Eversol Staked SOL',
            'address': 'Hg35Vd8K3BS2pLB3xwC2WqQV8pmpCm3oNRGYP1PEpmCM',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hg35Vd8K3BS2pLB3xwC2WqQV8pmpCm3oNRGYP1PEpmCM/logo.png',
            'coingeckoId': 'eversol-staked-sol',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'CWAR',
            'name': 'Cryowar',
            'address': 'HfYFjMKNZygfMC8LsQ8LtpPsPxEJoXJx4M6tqi75Hajo',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HfYFjMKNZygfMC8LsQ8LtpPsPxEJoXJx4M6tqi75Hajo/logo.png',
            'coingeckoId': 'cryowar-token',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'INA',
            'name': 'Inanna',
            'address': 'HfMVgG3fQr45JtrQD3jpVki6E5H5BSdjN8kCAvDEDKMQ',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/781d71f6cb76d2921c7f101fac3aa3010c627cdc/assets/mainnet/HfMVgG3fQr45JtrQD3jpVki6E5H5BSdjN8kCAvDEDKMQ/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'HERO',
            'name': 'Solhero Finance',
            'address': 'Hero6s7zJXsw9hfCXLVR5stLqgCok3E7CCkpQEoLAk2g',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Hero6s7zJXsw9hfCXLVR5stLqgCok3E7CCkpQEoLAk2g/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'CRY',
            'name': 'Crystal',
            'address': 'HbrmyoumgcK6sDFBi6EZQDi4i4ZgoN16eRB2JseKc7Hi',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HbrmyoumgcK6sDFBi6EZQDi4i4ZgoN16eRB2JseKc7Hi/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SXP',
            'name': 'Stacc eXPerience Points',
            'address': 'HavbxBPK1uY9kMNqKPkWDEQXWw6FYERrLxeMtWiXnwko',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/dyor-market/token-list/main/assets/mainnet/HavbxBPK1uY9kMNqKPkWDEQXWw6FYERrLxeMtWiXnwko/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'LDO',
            'name': 'Lido DAO  Wormhole ',
            'address': 'HZRCwxP2Vq9PCpPXooayhJ2bxTpo5xfpQrwB1svh332p',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HZRCwxP2Vq9PCpPXooayhJ2bxTpo5xfpQrwB1svh332p/logo.png',
            'coingeckoId': 'lido-dao-wormhole',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'TWT',
            'name': 'Trust Wallet (Portal)',
            'address': 'HZNpqL7RT9gxf9eWoWsWzC5DfjzQ41XTQgEA7p3VzaaD',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HZNpqL7RT9gxf9eWoWsWzC5DfjzQ41XTQgEA7p3VzaaD/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'DOGELON',
            'name': 'DOGELON SOLANA',
            'address': 'HYoGYzMcbYq3tAvpg15d8VFYVHw6jWEVuGgpNTrG8hps',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HYoGYzMcbYq3tAvpg15d8VFYVHw6jWEVuGgpNTrG8hps/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'xBTC',
            'name': 'Synthetic BTC',
            'address': 'HWxpSV3QAGzLQzGAtvhSYAEr7sTQugQygnni1gnUGh1D',
            'decimals': 10,
            'chainId': -1,
            'logoURI': 'https://www.synthetify.io/icons/xbtc.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'VISION',
            'name': 'VisionGame',
            'address': 'HVkFqcMHevVPb4XKrf4XowjEaVVsBoqJ2U1EG59Dfk5j',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24460/thumb/eQh2HGH3_400x400.jpg?1647679828',
            'coingeckoId': 'visiongame',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALTBULL',
            'name': 'ALTBULL',
            'address': 'HRhaNssoyv5tKFRcbPg69ULEbcD8DPv99GdXLcdkgc1A',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/hrhanssoyv5tkfrcbpg69ulebcd8dpv99gdxlcdkgc1a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'WNAV',
            'name': 'Wrapped Navcoin',
            'address': 'HRBrRXGCrPro6TtryKQkLXuZqg3LdBMN9ZWx2v66pT4L',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HRBrRXGCrPro6TtryKQkLXuZqg3LdBMN9ZWx2v66pT4L/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'LITTE',
            'name': 'LitteCoin',
            'address': 'HPeWNsBtYtfMeYS6Sqwb3uvDvuarekCnrjCq41XqMQdf',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/EbenezerLitte/logo_assets/main/assets/litte_logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'AGRO',
            'name': 'Agroland',
            'address': 'HNtzvJgduuyNsFDnqH4GyHDqNQJShJMxzUcGnUW9xeWi',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/hntzvjgduuynsfdnqh4gyhdqnqjshjmxzucgnuw9xewi/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SER',
            'name': 'Secretum',
            'address': 'HNpdP2rL6FR6jM3bDxFX2Zo32D1YG2ZCztf9zzCrKMEX',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25378/thumb/ser.png?1651410512',
            'coingeckoId': 'secretum',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ahBTC-renBTC',
            'name': 'Saber ahBTC-renBTC LP',
            'address': 'HLPPmd7NzTTNiqKR6rAZYgrH9VhU47kxftecQSk2oD6J',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/hlppmd7nzttniqkr6razygrh9vhu47kxftecqsk2od6j/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ahUSDT-USDC',
            'name': 'Saber ahUSDT-USDC LP',
            'address': 'HLPC9r4gbeP6KagT3qJLzFj7iWcYTJs245k9tuHFQGyR',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/hlpc9r4gbep6kagt3qjlzfj7iwcytjs245k9tuhfqgyr/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'TINY',
            'name': 'Tiny Colony',
            'address': 'HKfs24UEDQpHS5hUyKYkHd9q7GY5UQ679q2bokeL2whu',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22264/thumb/Kq4gAMJi_400x400.png?1641336657',
            'coingeckoId': 'tiny-colony',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATS',
            'name': 'Atlas DEX',
            'address': 'HJbNXx2YMRxgfUJ6K4qeWtjatMK5KYQT1QnsCdDWywNv',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HJbNXx2YMRxgfUJ6K4qeWtjatMK5KYQT1QnsCdDWywNv/logo.png',
            'coingeckoId': 'atlas-dex',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'FTR',
            'name': 'Future',
            'address': 'HEhMLvpSdPviukafKwVN8BnBUTamirptsQ6Wxo5Cyv8s',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HEhMLvpSdPviukafKwVN8BnBUTamirptsQ6Wxo5Cyv8s/logo.png',
            'coingeckoId': 'future',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'KSAMO',
            'name': 'King Samo',
            'address': 'HDiA4quoMibAGeJQzvxajp3Z9cvnkNng99oVrnuNj6px',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HDiA4quoMibAGeJQzvxajp3Z9cvnkNng99oVrnuNj6px/logo.png',
            'coingeckoId': 'king-samo',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'MILLI',
            'name': 'MILLIONSY',
            'address': 'HDLRMKW1FDz2q5Zg778CZx26UgrtnqpUDkNNJHhmVUFr',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/millionsy/token-list/main/assets/mainnet/HDLRMKW1FDz2q5Zg778CZx26UgrtnqpUDkNNJHhmVUFr/logo.png',
            'coingeckoId': 'millionsy',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'STARS',
            'name': 'StarLaunch',
            'address': 'HCgybxq5Upy8Mccihrp7EsmwwFqYZtrHrsmsKwtGXLgW',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HCgybxq5Upy8Mccihrp7EsmwwFqYZtrHrsmsKwtGXLgW/logo.png',
            'coingeckoId': 'starlaunch',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'C999',
            'name': 'Cosmogol 999',
            'address': 'HC9qZTgTYf12cFPaK3dK2HZJ9M47r2JenrsvQ1Ewnds8',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HC9qZTgTYf12cFPaK3dK2HZJ9M47r2JenrsvQ1Ewnds8/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'NAMEK',
            'name': 'Namek',
            'address': 'HBxHiTHpmnps5ALo2jzZbVmUzPPpZsUT8wN8KtqkBU9h',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://github.com/neptuncoin/namek/raw/main/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SLNDN',
            'name': 'Solanadon',
            'address': 'HBHMiauecxer5FCzPeXgE2A8ZCf7fQgxxwo4vfkFtC7s',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HBHMiauecxer5FCzPeXgE2A8ZCf7fQgxxwo4vfkFtC7s/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'HBB',
            'name': 'Hubble',
            'address': 'HBB111SCo9jkCejsZfz8Ec8nH7T6THF8KEKSnvwT6XK6',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HBB111SCo9jkCejsZfz8Ec8nH7T6THF8KEKSnvwT6XK6/logo.svg',
            'coingeckoId': 'hubble',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'MEOW',
            'name': 'Solcats',
            'address': 'HAgX1HSfok8DohiNCS54FnC2UJkDSrRVnT38W3iWFwc8',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/HAgX1HSfok8DohiNCS54FnC2UJkDSrRVnT38W3iWFwc8/logo.png',
            'coingeckoId': 'solcats',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'OOGI',
            'name': 'OOGI',
            'address': 'H7Qc9APCWWGDVxGD5fJHmLTmdEgT9GFatAKFNg6sHh8A',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://oogi.com/icon.png',
            'coingeckoId': 'oogi',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SPX',
            'name': 'Sphinxel',
            'address': 'H6JocWxg5g1Lcs4oPnBecmjQ4Y1bkZhGJHtjMunmjyrp',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H6JocWxg5g1Lcs4oPnBecmjQ4Y1bkZhGJHtjMunmjyrp/logo.png',
            'coingeckoId': 'sphinxel',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'BDE',
            'name': 'Big Defi Energy',
            'address': 'H5gczCNbrtso6BqGKihF97RaWaxpUEZnFuFUKK4YX3s2',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/bigchickendefi/bigchicken/main/logo.png',
            'coingeckoId': 'big-defi-energy',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'METARARITY',
            'name': 'METARARITY',
            'address': 'H36ykN443TZ6pC8oryicCYr5YB1em4fuSyezu5aoskNv',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H36ykN443TZ6pC8oryicCYr5YB1em4fuSyezu5aoskNv/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ROLL',
            'name': 'Let\'sroll DAO',
            'address': 'H2EJUxt2KSPk7BWGZRfLMqh56wCmWygDJVTvjTJFHeym',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/H2EJUxt2KSPk7BWGZRfLMqh56wCmWygDJVTvjTJFHeym/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'LOOT',
            'name': 'Loot',
            'address': 'GzpRsvnKXKz586kRLkjdppR4dUCFwHa2qaszKkPUQx6g',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://arttest12312321.s3.us-west-1.amazonaws.com/pics/Bag_Open+(3).png',
            'coingeckoId': 'loot-token',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'MATICPO',
            'name': 'MATIC  Wormhole ',
            'address': 'Gz7VkD4MacbEB6yC5XD3HcumEiYx2EtDYYrfikGsvopG',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gz7VkD4MacbEB6yC5XD3HcumEiYx2EtDYYrfikGsvopG/logo.png',
            'coingeckoId': 'matic-wormhole',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SOULO',
            'name': 'SouloCoin',
            'address': 'Gz3u6eJaKEviYpPC5AwUziz891kNX76PNdsmJrnaNNY4',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22082/thumb/logo.png?1640759758',
            'coingeckoId': 'soulocoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DPAY',
            'name': 'PayDex',
            'address': 'GyUYoBT1gcZBEVffWeGKQ3E2gzfNP5b8GEvnqAGjL6Hs',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26530/thumb/paydexlogo.png?1658666536',
            'coingeckoId': 'paydex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KRW',
            'name': 'KROWN',
            'address': 'Gw7M5dqZJ6B6a8dYkDry6z9t9FuUA2xPUokjV2cortoq',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/16530/thumb/KRW_token_logo_200x200.png?1624343058',
            'coingeckoId': 'krown',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'Potion',
            'name': 'Potion',
            'address': 'GtHxqAqbaZB8eo8R8pGXUhWxs6X8WQWMWTUWKTgSFbHo',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://i.imgur.com/zURNQAX.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'tuSLRS',
            'name': 'tuSLRS',
            'address': 'GtFtWCcLYtWQT8NLRwEfUqc9sgVnq4SbuSnMCpwcutNk',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/sol-farm/token-logos/main/tuSLRS.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'GSAIL',
            'name': 'SolanaSail Governance',
            'address': 'Gsai2KN28MTGcSZ1gKYFswUpFpS7EM9mvdR9c8f6iVXJ',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solanasail/token-list/main/assets/mainnet/Gsai2KN28MTGcSZ1gKYFswUpFpS7EM9mvdR9c8f6iVXJ/logo.png',
            'coingeckoId': 'solanasail-governance-token',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'DXL',
            'name': 'Dexlab',
            'address': 'GsNzxJfFn6zQdJGeYsupJWzUAm57Ba7335mfhWvFiE9Z',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/dexlab-project/assets/master/dexlab/dexlab_symbol_logo.svg',
            'coingeckoId': 'dexlab',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SOLMO',
            'name': 'SolMoon',
            'address': 'Gro98oTmXxCVX8HKr3q2tMnP5ztoC77q6KehFDnAB983',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22113/thumb/solmo.png?1640842991',
            'coingeckoId': 'solmoon',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'afUSDC',
            'name': 'Wrapped USDC (Allbridge from Fantom)',
            'address': 'Grk6b4UMRWkgyq4Y6S1BnNRF4hRgtnMFp7Sorkv6Ez4u',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BXXkv6z8ykpG1yuvUDPgh732wzVHB69RnB9YgSYh3itW/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'CAPE',
            'name': 'Crazy Ape Coin',
            'address': 'GpYMp8eP3HADY8x1jLVfFVBVYqxFNxT5mFhZAZt9Poco',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GpYMp8eP3HADY8x1jLVfFVBVYqxFNxT5mFhZAZt9Poco/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'GOLDY',
            'name': 'DeFi Land Gold',
            'address': 'GoLDYyyiVeXnVf9qgoK712N5esm1cCbHEK9aNJFx47Sx',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25647/thumb/GODLY.png?1653025856',
            'coingeckoId': 'defi-land-gold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DJN',
            'name': 'Fenix Danjon',
            'address': 'GnzxEyULVPQYb5F5hxGc8dEGivctVrfr5mtsdp4z5xU2',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GnzxEyULVPQYb5F5hxGc8dEGivctVrfr5mtsdp4z5xU2/logo.png',
            'coingeckoId': 'fenix-danjon',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'BULL',
            'name': 'BULL',
            'address': 'GkSPaHdY2raetuYzsJYacHtrAtQUfWt64bpd1VzxJgSD',
            'decimals': 6,
            'chainId': -1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SOL100',
            'name': 'SOL100',
            'address': 'GkDg1ZfoFkroLAwLqtJNXhxCDg8gmKxHAGxSUZagYFfE',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GkDg1ZfoFkroLAwLqtJNXhxCDg8gmKxHAGxSUZagYFfE/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'Gangsta',
            'name': 'Gangsta',
            'address': 'Gh6jp5U3yfcJwkZ7RoY6Ak2tgd752dSqBSYwHnfK3gft',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gh6jp5U3yfcJwkZ7RoY6Ak2tgd752dSqBSYwHnfK3gft/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ahUSDT',
            'name': 'Wrapped USDT (Allbridge from HECO)',
            'address': 'GfzU1fLASNV3r4NtEyrnwTyTakJkYzoivnaL3Snh45oj',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GfzU1fLASNV3r4NtEyrnwTyTakJkYzoivnaL3Snh45oj/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'SOLPAD',
            'name': 'Solpad Finance',
            'address': 'GfJ3Vq2eSTYf1hJP6kKLE9RT6u7jF9gNszJhZwo5VPZp',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GfJ3Vq2eSTYf1hJP6kKLE9RT6u7jF9gNszJhZwo5VPZp/logo.png',
            'coingeckoId': 'solpad-finance',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'JFI',
            'name': 'Jungle DeFi',
            'address': 'GePFQaZKHcWE5vpxHfviQtH5jgxokSs51Y5Q4zgBiMDs',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GePFQaZKHcWE5vpxHfviQtH5jgxokSs51Y5Q4zgBiMDs/logo.png',
            'coingeckoId': 'jungle-defi',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'wFTT_v1',
            'name': 'FTT (Wormhole v1)',
            'address': 'GbBWwtYTMPis4VHb8MrBbdibPhn28TSrLB53KvUmb7Gi',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GbBWwtYTMPis4VHb8MrBbdibPhn28TSrLB53KvUmb7Gi/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'BARK',
            'name': 'Bark o Finance',
            'address': 'GaAzf7jwEKTouDXJExH9TKfvX3Ae7fLaGwNuEajq7KsE',
            'decimals': 1,
            'chainId': -1,
            'logoURI': 'https://i.imgur.com/X90vi6d.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'mPLAT',
            'name': 'SolMiner Platinum',
            'address': 'GZNrMEdrt6Vg428JzvJYRGGPpVxgjUPsg6WLqKBvmNLw',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GZNrMEdrt6Vg428JzvJYRGGPpVxgjUPsg6WLqKBvmNLw/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SAC',
            'name': 'Stoned Ape Crew Index',
            'address': 'GZL4yjPohDShW4RofJ6dEWu2Fv7qEa5mBT7Dpje5hqe7',
            'decimals': 2,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24385/thumb/stone_ape.PNG?1647501586',
            'coingeckoId': 'stoned-ape-crew-index',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GYC',
            'name': 'GameYoo',
            'address': 'GYCVdmDthkf3jSz5ns6fkzCmHub7FSZxjVCfbfGqkH7P',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/gameyoo/materials/main/gyc.png',
            'coingeckoId': 'gameyoo',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'ATC',
            'name': 'ArtiCoin',
            'address': 'GXnw9YSt6DANCt84Ti6ZpbaXvrvuEJFCYqrDjygnq4R8',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25232/thumb/LOGO-256.png?1650953344',
            'coingeckoId': 'articoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'soTOMO',
            'name': 'Wrapped TOMO (Sollet)',
            'address': 'GXMvfY2jpQctDqZ9RoU3oWPhufKiCcFEfchvYumtX7jd',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GXMvfY2jpQctDqZ9RoU3oWPhufKiCcFEfchvYumtX7jd/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'PCN',
            'name': 'Pollen Coin',
            'address': 'GWsZd8k85q2ie9SNycVSLeKkX7HLZfSsgx6Jdat9cjY1',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25687/thumb/97762641.png?1653374572',
            'coingeckoId': 'pollen-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAFU',
            'name': '1SAFU',
            'address': 'GWgwUUrgai3BFeEJZp7bdsBSYiuDqNmHf9uRusWsf3Yi',
            'decimals': 0,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GWgwUUrgai3BFeEJZp7bdsBSYiuDqNmHf9uRusWsf3Yi/logo.png',
            'coingeckoId': '1safu',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'GWT',
            'name': 'Galaxy War',
            'address': 'GWTipxSJVPmmW2wCjBdkbnEJbCRCyrhL2x9zuHRPPTj1',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22166/thumb/e2x7gMJ4_400x400.jpg?1641166277',
            'coingeckoId': 'galaxy-war',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PEEL',
            'name': 'PEEL',
            'address': 'GVsdtSe3E2fQoP3TzNT2M1VUchJ7sBmDBuvBZmGDGvmB',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GVsdtSe3E2fQoP3TzNT2M1VUchJ7sBmDBuvBZmGDGvmB/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SVIZ',
            'name': 'Space Vizsla',
            'address': 'GV6n9Uow3XzMWSs8vwTCML8SvMA6ozbidaEfdPoSoraQ',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GV6n9Uow3XzMWSs8vwTCML8SvMA6ozbidaEfdPoSoraQ/VizslaMoonLogo100x100.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'soKEEP',
            'name': 'Wrapped KEEP (Sollet)',
            'address': 'GUohe4DJUA5FKPWo3joiPgsB7yzer7LpDmt1Vhzy3Zht',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GUohe4DJUA5FKPWo3joiPgsB7yzer7LpDmt1Vhzy3Zht/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'XgSAIL',
            'name': 'gSAIL DEPRECATED',
            'address': 'GSaiLQxREzaxUcE3v28HxBacoUQPZNtXx1eQsCFsX9Bg',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GSaiLQxREzaxUcE3v28HxBacoUQPZNtXx1eQsCFsX9Bg/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'WAVE',
            'name': 'Lost At Sea WAVE',
            'address': 'GS6E87SLTioRDG3uSVRwQmuKKMxDmWU7fktCTJ5xkEM8',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GS6E87SLTioRDG3uSVRwQmuKKMxDmWU7fktCTJ5xkEM8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'BBI',
            'name': 'Bridgesplit Brand Index',
            'address': 'GRsoqmhsS7fCLpEqqE7oRM92ag3WVy8VbJAi6KfWSeHS',
            'decimals': 2,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25640/thumb/a.png?1652942415',
            'coingeckoId': 'bridgesplit-brand-index',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARKK',
            'name': 'ARK Innovation ETF',
            'address': 'GPoBx2hycDs3t4Q8DeBme9RHb9nQpzH3a36iUoojHe16',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/gpobx2hycds3t4q8debme9rhb9nqpzh3a36iuoojhe16/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'CELO',
            'name': 'CELO (Allbridge from Celo)',
            'address': 'GNCjk3FmPPgZTkbQRSxr6nCvLtYMbXKMnRxg8BgJs62e',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GNCjk3FmPPgZTkbQRSxr6nCvLtYMbXKMnRxg8BgJs62e/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SOLAB',
            'name': 'Solabrador',
            'address': 'GLmaRDRmYd4u3YLfnj9eq1mrwxa1YfSweZYYZXZLTRdK',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GLmaRDRmYd4u3YLfnj9eq1mrwxa1YfSweZYYZXZLTRdK/solabradon250x250.png',
            'coingeckoId': 'solabrador',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'ACTN',
            'name': 'ACTON Coin',
            'address': 'GKKtUp6UTubkPTd6x9EG55rN7Svg3Ys76g24reDYFcFX',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/gkktup6utubkptd6x9eg55rn7svg3ys76g24redyfcfx/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ALTAR',
            'name': 'Altar',
            'address': 'GJQ1iDoPWWo7pXeNKhC9BLD3FHL2tgFEVGMHYGEfQZT',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GJQ1iDoPWWo7pXeNKhC9BLD3FHL2tgFEVGMHYGEfQZT/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'SOLAPE',
            'name': 'SOLAPE',
            'address': 'GHvFFSZ9BctWsEc5nujR1MTmmJWY7tgQz2AXE6WVFtGN',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GHvFFSZ9BctWsEc5nujR1MTmmJWY7tgQz2AXE6WVFtGN/logo.png',
            'coingeckoId': 'solape-token',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'DOGEC',
            'name': 'Dogecoin Cash',
            'address': 'GGupQCMnyEmHKcqFu72qCTm6yEYpVyhouY9dSAMEXLsC',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GGupQCMnyEmHKcqFu72qCTm6yEYpVyhouY9dSAMEXLsC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SCTT',
            'name': 'SOLANA CRYPT TOKEN',
            'address': 'GGFNWQ2oEzYVPu1kGSjXZWfyTibnCGJfqZ7uPx8Jkj7B',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GGFNWQ2oEzYVPu1kGSjXZWfyTibnCGJfqZ7uPx8Jkj7B/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ALTR',
            'name': 'Altar Finance',
            'address': 'GG2Je65Rx55MreTBEFNfFFHPEXcmfGq2vuZVLDN2GXYk',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/gg2je65rx55mretbefnfffhpexcmfgq2vuzvldn2gxyk/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'AQS',
            'name': 'AQSCOIN',
            'address': 'GFpSv8R5AxiC2ZKC4yqf3jfVFKt1KVB5jk9x6YEQ7XSQ',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/gfpsv8r5axic2zkc4yqf3jfvfkt1kvb5jk9x6yeq7xsq/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'aeLINK',
            'name': 'Wrapped LINK (Allbridge from Ethereum)',
            'address': 'GFdVCMBimnf2Dmx2D4DtPhghTkLYh6JG4u5hKB89t9n1',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/gfdvcmbimnf2dmx2d4dtphghtklyh6jg4u5hkb89t9n1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'GOFX',
            'name': 'GooseFX',
            'address': 'GFX1ZjR2P15tmrSwow6FjyDYcEkoFb4p4gJCpLBjaxHD',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GFX1ZjR2P15tmrSwow6FjyDYcEkoFb4p4gJCpLBjaxHD/logo.png',
            'coingeckoId': 'goosefx',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'POTATO',
            'name': 'Potato',
            'address': 'GEYrotdkRitGUK5UMv3aMttEhVAZLhRJMcG82zKYsaWB',
            'decimals': 3,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GEYrotdkRitGUK5UMv3aMttEhVAZLhRJMcG82zKYsaWB/logo.png',
            'coingeckoId': 'potato',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'GENZ',
            'name': 'GENZ Token',
            'address': 'GENZexWRRGNS2Ko5rEgGG1snRXpaa3CDDGYnhTSmE3kd',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28039/thumb/Qq_rj-aG_400x400.png?1667199323',
            'coingeckoId': 'genz-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GENE',
            'name': 'Genopets',
            'address': 'GENEtH5amGSi8kHAtQoezp1XEXwZJ8vcuePYnXdKrMYz',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GENEtH5amGSi8kHAtQoezp1XEXwZJ8vcuePYnXdKrMYz/logo.png',
            'coingeckoId': 'genopets',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'DAOSOL',
            'name': 'daoSOL',
            'address': 'GEJpt3Wjmr628FqXxTgxMce1pLntcPV4uFi8ksxMyPQh',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/GEJpt3Wjmr628FqXxTgxMce1pLntcPV4uFi8ksxMyPQh/logo.png',
            'coingeckoId': 'daosol',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'HIRAM',
            'name': 'Hiram',
            'address': 'GDsVXtyt2CBwieKSYMEsjjZXXvqz2G2VwudD7EvXzoEU',
            'decimals': 3,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23457/thumb/logo_%281%29.png?1644215352',
            'coingeckoId': 'hiram',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NUTS',
            'name': 'NUTS',
            'address': 'GCxgQbbvJc4UyqGCsUAUa38npzZX27EMxZwckLuWeEkt',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://user-images.githubusercontent.com/93886730/140664862-6dd80bff-be30-4c68-a978-fcb205011d61.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'PUFF',
            'name': 'PUFF',
            'address': 'G9tt98aYSznRk7jWsfuz9FnTdokxS6Brohdo9hSmjTRB',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/G9tt98aYSznRk7jWsfuz9FnTdokxS6Brohdo9hSmjTRB/logo.png',
            'coingeckoId': 'puff',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'ASKO',
            'name': 'Asset Koin',
            'address': 'G9sTdFXB6ysWB95bD8FFgB4Ckc9d74ws9GsqxfgF6JPt',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/g9stdfxb6yswb95bd8ffgb4ckc9d74ws9gsqxfgf6jpt/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'CSTR',
            'name': 'CoreStarter',
            'address': 'G7uYedVqFy97mzjygebnmmaMUVxWHFhNZotY6Zzsprvf',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/CoreStarter/token-logo/main/corestarter_logo.png',
            'coingeckoId': 'corestarter',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'EYE',
            'name': 'NftEyez',
            'address': 'G7eETAaUzmsBPKhokZyfbaT4tD9igdZSmfQGEYWem8Sw',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/NftEyez/assets/main/assets/eye-coin.png',
            'coingeckoId': 'nfteyez',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'AGG',
            'name': 'Aggie Coin',
            'address': 'G5gqGPsrpkRYZPThJJpoVQRtgjo8zapPZ27iCSp2wPX',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/g5gqgpsrpkryzpthjjpovqrtgjo8zappz27icsp2wpx/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ABC',
            'name': 'AmerisourceBergen Corp',
            'address': 'G2Cg4XoXdEJT5sfrSy9N6YCC3uuVV3AoTQSvMeSqT8ZV',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/g2cg4xoxdejt5sfrsy9n6ycc3uuvv3aotqsvmesqt8zv/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'AHDT',
            'name': 'AHollaDolla Token',
            'address': 'FzcmRR3wfGXLAcTkKkocmnQLjZKSScb3QhCB8jbUfj9D',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/fzcmrr3wfgxlactkkkocmnqljzksscb3qhcb8jbufj9d/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'aaUSDT',
            'name': 'Wrapped USDT (Allbridge from Avalanche)',
            'address': 'FwEHs3kJEdMa2qZHv7SgzCiFXUQPEycEXksfBkwmS8gj',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'SOLNUT',
            'name': 'Solana Nut',
            'address': 'Fv3ZG56M2cWvF8sy9VWzWyvtHPhugNc1BAzpyoAPvL7r',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fv3ZG56M2cWvF8sy9VWzWyvtHPhugNc1BAzpyoAPvL7r/logo.png',
            'coingeckoId': 'solana-nut',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'BRZ',
            'name': 'Brazilian Digital',
            'address': 'FtgGSFADXBtroxq8VCausXRr2of47QBf5AS1NtZCu4GD',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FtgGSFADXBtroxq8VCausXRr2of47QBf5AS1NtZCu4GD/logo.png',
            'coingeckoId': 'brz',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'TRPY',
            'name': 'Trippy Leaf',
            'address': 'Fse2oFDfbwT89CqtuoFaHCBnGTMFLartDYDjPLZyc7e',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fse2oFDfbwT89CqtuoFaHCBnGTMFLartDYDjPLZyc7e/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'XGLI',
            'name': 'Glitter Finance',
            'address': 'FsPncBfeDV3Uv9g6yyx1NnKidvUeCaAiT2NtBAPy17xg',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23292/thumb/glitter_finance.png?1660285639',
            'coingeckoId': 'glitter-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '$FROG',
            'name': 'Frog',
            'address': 'Frog8vt6gmuuUuuerd7ispP6yavssBUtMpEP5DCEuUgD',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/frog8vt6gmuuuuuerd7ispp6yavssbutmpep5dceuugd/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FRIES',
            'name': 'Soltato FRIES',
            'address': 'FriCEbw1V99GwrJRXPnSQ6su2TabHabNxiZ3VNsVFPPN',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19533/thumb/soltato.png?1635745612',
            'coingeckoId': 'soltato-fries',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XFTT',
            'name': 'Synthetic FTT',
            'address': 'Fr3W7NPVvdVbwMcHgA7Gx2wUxP43txdsn3iULJGFbKz9',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://www.synthetify.io/icons/xftt.svg',
            'coingeckoId': 'synthetic-ftt',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'ABNB',
            'name': 'Airbnb',
            'address': 'FqqVanFZosh4M4zqxzWUmEnky6nVANjghiSLaGqUAYGi',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/fqqvanfzosh4m4zqxzwumenky6nvanjghislagquaygi/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'NAXAR',
            'name': 'Naxar',
            'address': 'Fp4gjLpTsPqBN6xDGpDHwtnuEofjyiZKxxZxzvJnjxV6',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fp4gjLpTsPqBN6xDGpDHwtnuEofjyiZKxxZxzvJnjxV6/logo.png',
            'coingeckoId': 'naxar',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'BIP',
            'name': 'The Starship Finance',
            'address': 'FoqP7aTaibT5npFKYKQQdyonL99vkW8YALNPwWepdvf5',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21587/thumb/logo_-_2021-12-15T120333.709.png?1639541030',
            'coingeckoId': 'the-starship-finance',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FOXY',
            'name': 'Famous Fox Federation',
            'address': 'FoXyMu5xwXre7zEoSvzViRk3nGawHUp9kUh97y2NDhcq',
            'decimals': 0,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26191/thumb/uFYaQEsU_400x400.jpg?1656397523',
            'coingeckoId': 'famous-fox-federation',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FORGE',
            'name': 'Blocksmith Labs Forge',
            'address': 'FoRGERiW7odcCBGU1bztZi16osPBHjxharvDathL5eds',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25411/thumb/Logo_%281%29.png?1651733020',
            'coingeckoId': 'blocksmith-labs-forge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SNIPPLES',
            'name': 'Solana Nipples',
            'address': 'FncRHFTSigcNzH66WP3Jh7kupaEHtGV48x8RyMm9cU6d',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FncRHFTSigcNzH66WP3Jh7kupaEHtGV48x8RyMm9cU6d/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SYP',
            'name': 'Sypool',
            'address': 'FnKE9n6aGjQoNWRBZXy4RW6LZVao7qwBonUbiD7edUmZ',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/b0e47e39f84cffb655f406eb569c57a88b5211cc/assets/mainnet/FnKE9n6aGjQoNWRBZXy4RW6LZVao7qwBonUbiD7edUmZ/logo.png',
            'coingeckoId': 'sypool',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'GOOSE',
            'name': 'Mongoose',
            'address': 'FmpqmVcT4hSUfNhach31YUpf6M75bBYaC59JLMyCVNHH',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FmpqmVcT4hSUfNhach31YUpf6M75bBYaC59JLMyCVNHH/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'MBS',
            'name': 'MonkeyLeague',
            'address': 'Fm9rHUTF5v3hwMLbStjZXqNBBoZyGriQaFM6sTFz3K8A',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fm9rHUTF5v3hwMLbStjZXqNBBoZyGriQaFM6sTFz3K8A/logo.png',
            'coingeckoId': 'monkeyball',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'LEARN',
            'name': 'Solearna',
            'address': 'Fj4js23EXVLoUQ26VPfmwVbt76XLn6souUGis71FvNmM',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Fj4js23EXVLoUQ26VPfmwVbt76XLn6souUGis71FvNmM/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'MM',
            'name': 'Million',
            'address': 'FiCiuX9DetEE89PgRAU1hmoptnem8b1fkpEq8PGYTYkd',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FiCiuX9DetEE89PgRAU1hmoptnem8b1fkpEq8PGYTYkd/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ACB',
            'name': 'Aurora Cannabis Inc',
            'address': 'FgcUo7Ymua8r5xxsn9puizkLGN5w4i3nnBmasXvkcWfJ',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/fgcuo7ymua8r5xxsn9puizklgn5w4i3nnbmasxvkcwfj/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'NINJA',
            'name': 'Ninja Protocol',
            'address': 'FgX1WD9WzMU3yLwXaFSarPfkgzjLb2DZCqmkx9ExpuvJ',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FgX1WD9WzMU3yLwXaFSarPfkgzjLb2DZCqmkx9ExpuvJ/logo.png',
            'coingeckoId': 'ninja-protocol',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'pUSDC',
            'name': 'Port Finance USDC',
            'address': 'FgSsGV8GByPaMERxeQJPvZRZHf7zCBhrdYtztKorJS58',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FgSsGV8GByPaMERxeQJPvZRZHf7zCBhrdYtztKorJS58/USDC.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'AMC',
            'name': 'AMC Entertainment Holdings',
            'address': 'FenmUGWjsW5AohtHRbgLoPUZyWSK36Cd5a31XJWjnRur',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/fenmugwjsw5aohthrbglopuzywsk36cd5a31xjwjnrur/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'wCAPS_v1',
            'name': 'Wrapped Capsule Coin (Wormhole v1)',
            'address': 'FeLoyXk8ac2AYVmDhAWEKNWWT63Z9TczeidYbpDvxF3T',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FeLoyXk8ac2AYVmDhAWEKNWWT63Z9TczeidYbpDvxF3T/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'afBTC',
            'name': 'Wrapped BTC (Allbridge from Fantom)',
            'address': 'FdvkkCbCgYht1xTR1W9MBJhEF1JEPVhHtW1NXBYRzZts',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/qfnqNqs3nCAHjnyCgLRDbBtq4p2MtHZxw8YjSyYhPoL/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'POOS',
            'name': 'Legend Of PooShi',
            'address': 'FdDEakNRY4k3orJuBXUcm9VkcXd8YXVPjuG5WuRN2tWH',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/ba3893b7f7822992a645bc130f78fa433baff2d3/assets/mainnet/FdDEakNRY4k3orJuBXUcm9VkcXd8YXVPjuG5WuRN2tWH/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'aaWBTC',
            'name': 'Wrapped BTC (Allbridge from Avalanche)',
            'address': 'Fd8xyHHRjTvxfZrBirb6MaxSmrZYw99gRSqFUKdFwFvw',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/qfnqNqs3nCAHjnyCgLRDbBtq4p2MtHZxw8YjSyYhPoL/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'TGT',
            'name': 'Twirl Governance',
            'address': 'FciGvHj9FjgSGgCBF1b9HY814FM9D28NijDd5SJrKvPo',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FciGvHj9FjgSGgCBF1b9HY814FM9D28NijDd5SJrKvPo/logo.png',
            'coingeckoId': 'twirl-governance-token',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'MKD',
            'name': 'Musk Doge',
            'address': 'FatneQg39zhrG6XdwYb8fzM4VgybpgqjisJYESSBD7FV',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FatneQg39zhrG6XdwYb8fzM4VgybpgqjisJYESSBD7FV/logo.png',
            'coingeckoId': 'musk-doge',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'VCC',
            'name': 'VentureCapital',
            'address': 'FZgL5motNWEDEa24xgfSdBDfXkB9Ru9KxfEsey9S58bb',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FZgL5motNWEDEa24xgfSdBDfXkB9Ru9KxfEsey9S58bb/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'wDAI_v1',
            'name': 'Dai Stablecoin (Wormhole v1)',
            'address': 'FYpdBuyAHSbdaAyD1sKkxyLWbAP8uUW9h6uvdhK74ij1',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FYpdBuyAHSbdaAyD1sKkxyLWbAP8uUW9h6uvdhK74ij1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SOLA',
            'name': 'SOLA',
            'address': 'FYfQ9uaRaYvRiaEGUmct45F9WKam3BYXArTrotnTNFXF',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FYfQ9uaRaYvRiaEGUmct45F9WKam3BYXArTrotnTNFXF/logo.png',
            'coingeckoId': 'sola-token',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'TFMC',
            'name': 'Tap Fantasy MC',
            'address': 'FYUkUybywqUUyrUwiAezbvhTp2DUgx1eg8tQNiKkXqJ9',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26483/thumb/Tap-Fantasy-MC-Logo.png?1658279327',
            'coingeckoId': 'tap-fantasy-mc',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CORNI',
            'name': 'Corni',
            'address': 'FYNmEg12xqyuNrRn8A1cqkEapcUCh3M7ZARUN1yj1bEs',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27901/thumb/corni_logo_m.png?1666330995',
            'coingeckoId': 'corni',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ASH',
            'name': 'Ashera',
            'address': 'FY6XDSCubMhpkU9FAsUjB7jmN8YHYZGezHTWo9RHBSyX',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FY6XDSCubMhpkU9FAsUjB7jmN8YHYZGezHTWo9RHBSyX/logo.png',
            'coingeckoId': 'ashera',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'ACCT',
            'name': 'Agrinix Carbon Credit Token',
            'address': 'FXdxsZhNYGSBdne2LZ448SJ1QDXk8KaEzvKivCvc38h3',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/fxdxszhnygsbdne2lz448sj1qdxk8kaezvkivcvc38h3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ORKA',
            'name': 'ORKA Climate Solutions',
            'address': 'FXSrKsQ34jMmMtciuzhr3KSTG5UMZQfXBJKGqYYUTxg1',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FXSrKsQ34jMmMtciuzhr3KSTG5UMZQfXBJKGqYYUTxg1/orka-logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': '$DMT',
            'name': 'DMT',
            'address': 'FUHEdLxGtu2cpWuTX4u9Md8hYEb2WKScJXE2pTh9AUWt',
            'decimals': 0,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/fuhedlxgtu2cpwutx4u9md8hyeb2wkscjxe2pth9auwt/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MINECRAFT',
            'name': 'Synex Coin',
            'address': 'FTkj421DxbS1wajE74J34BJ5a1o9ccA97PkK6mYq9hNQ',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FTkj421DxbS1wajE74J34BJ5a1o9ccA97PkK6mYq9hNQ/logo.png',
            'coingeckoId': 'synex-coin',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'aeFTT-wFTT',
            'name': 'Saber aeFTT-wFTT LP',
            'address': 'FTXjwjwWqituSXEHnL5VF1mjDhZoAyJqvHiRPsRq3KWK',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/ftxjwjwwqitusxehnl5vf1mjdhzoayjqvhirpsrq3kwk/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'sFTT-9',
            'name': 'Saber Wrapped Wrapped FTT (Sollet) (9 decimals)',
            'address': 'FTT9rBBrYwcHam4qLvkzzzhrsihYMbZ3k6wJbdoahxAt',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FTT9rBBrYwcHam4qLvkzzzhrsihYMbZ3k6wJbdoahxAt/icon.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'swFTT-9',
            'name': 'Saber Wrapped FTT (Portal) (9 decimals)',
            'address': 'FTT9GrHBVHvDeUTgLU8FxVJouGqg9uiWGmmjETdm32Sx',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FTT9GrHBVHvDeUTgLU8FxVJouGqg9uiWGmmjETdm32Sx/icon.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ssoFTT-8',
            'name': 'Saber Wrapped Wrapped FTT (Sollet) (8 decimals)',
            'address': 'FTT8cGNp3rfTC6c44uPTuEFLqmsVDhjd2BhH65v2uppr',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FTT8cGNp3rfTC6c44uPTuEFLqmsVDhjd2BhH65v2uppr/icon.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'BIRD',
            'name': 'Bird Money',
            'address': 'FTPnEQ3NfRRZ9tvmpDW6JFrvweBE5sanxnXSpJL1dvbB',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FTPnEQ3NfRRZ9tvmpDW6JFrvweBE5sanxnXSpJL1dvbB/logo.png',
            'coingeckoId': 'bird-money',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'FRAX',
            'name': 'Frax',
            'address': 'FR87nWEUxVgerFGhZM8Y4AggKGLnaXswr1Pd8wZ4kZcp',
            'decimals': 18,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FR87nWEUxVgerFGhZM8Y4AggKGLnaXswr1Pd8wZ4kZcp/logo.png',
            'coingeckoId': 'frax',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'TTT',
            'name': 'TabTrader',
            'address': 'FNFKRV3V8DtA3gVJN6UshMiLGYA8izxFwkNWmJbFjmRj',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FNFKRV3V8DtA3gVJN6UshMiLGYA8izxFwkNWmJbFjmRj/logo.svg',
            'coingeckoId': 'tabtrader',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'LRA',
            'name': 'Lumos Rewards',
            'address': 'FMJotGUW16AzexRD3vXJQ94AL71cwrhtFaCTGtK1QHXm',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FMJotGUW16AzexRD3vXJQ94AL71cwrhtFaCTGtK1QHXm/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'BACON',
            'name': 'BACON tokens',
            'address': 'FMBfAxhiTwDmujiEGbexFtExHR9q7nqnRF1Rjd5UmhS7',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://zplphm7g437efyuli2psx6tmh4nf7ux6zf3iw65xnk5zjltaomca.arweave.net/y9bzs-bm_kLii0afK_psPxpf0v7Jdot7t2q7lK5gcwQ',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'DOOK',
            'name': 'Dook Token',
            'address': 'FLoD6AwcJCnbznnWfV6HkBHh5FYtr8wJYj3mBnwNdLLg',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FLoD6AwcJCnbznnWfV6HkBHh5FYtr8wJYj3mBnwNdLLg/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'FLWR',
            'name': 'SOL Flowers',
            'address': 'FLWRna1gxehQ9pSyZMzxfp4UhewvLPwuKfdUTgdZuMBY',
            'decimals': 2,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23534/thumb/FLWR-Token-Front-ALPHA.png?1644387944',
            'coingeckoId': 'sol-flowers',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALEPH',
            'name': 'tuALEPH',
            'address': 'FJtaAZd6tXNCFGTq7ifRHt9AWoVdads6gWNc4SXCPw1k',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/fjtaazd6txncfgtq7ifrht9awovdads6gwnc4sxcpw1k/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ANAK',
            'name': 'Anakoin',
            'address': 'FHF1TDmPGKTLUi6mjrT7NDMrFXs4SCVvB7QWn3yswdao',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/fhf1tdmpgktlui6mjrt7ndmrfxs4scvvb7qwn3yswdao/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ALTREC',
            'name': 'ALTREC Coin',
            'address': 'FGpMT3xLwk67hWsT7Lgp7WjovS3rejx9KBmCG1bBtB9U',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/fgpmt3xlwk67hwst7lgp7wjovs3rejx9kbmcg1bbtb9u/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SHIVER',
            'name': 'Shibaverse SHIVER',
            'address': 'FGMTuwmVVz9hUJzA8shYiEnM16wsYDoSmYoy13UZe1kk',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FGMTuwmVVz9hUJzA8shYiEnM16wsYDoSmYoy13UZe1kk/logo.png',
            'coingeckoId': 'shibaverse-token',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'FADE',
            'name': 'FADE',
            'address': 'FDWmQxD9hQruYKtFK8wt6UhKGv6frPvU8EhjKiEPQoyD',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/yazidox/fadedfoxlogo/main/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'USDCbs',
            'name': 'USD Coin (Portal from BSC)',
            'address': 'FCqfQSujuPxy6V42UvafBhsysWtEq1vhjfMN1PUbgaxA',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FCqfQSujuPxy6V42UvafBhsysWtEq1vhjfMN1PUbgaxA/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': '108',
            'name': 'One Hundred and Eight',
            'address': 'FAbqiU27CNBoFwdWqryAF5KdHiMo4Q3GmamEEDKp6QfZ',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/fabqiu27cnbofwdwqryaf5kdhimo4q3gmameedkp6qfz/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FANT',
            'name': 'Phantasia',
            'address': 'FANTafPFBAt93BNJVpdu25pGPmca3RfwdsDsRrT3LX1r',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FANTafPFBAt93BNJVpdu25pGPmca3RfwdsDsRrT3LX1r/logo.png',
            'coingeckoId': 'phantasia',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'srenBTC-9',
            'name': 'Saber Wrapped renBTC (9 decimals)',
            'address': 'FACTQhZBfRzC7A76antnpAoZtiwYmUfdAN8wz7e8rxC5',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FACTQhZBfRzC7A76antnpAoZtiwYmUfdAN8wz7e8rxC5/icon.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'INUGAMI',
            'name': 'INUGAMI Coin',
            'address': 'F9tytWqLUAPXQTy6dejGtSgvJQZWYC71naD5bCi6caGX',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/INVESTOR888/content/main/F9tytWqLUAPXQTy6dejGtSgvJQZWYC71naD5bCi6caGX.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'LUNC',
            'name': 'Terra Classic  Wormhole ',
            'address': 'F6v4wfAdJB8D8p77bMXZgYt8TDKsYxLYxH5AFhUkYx9W',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/F6v4wfAdJB8D8p77bMXZgYt8TDKsYxLYxH5AFhUkYx9W/logo.png',
            'coingeckoId': 'luna-wormhole',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'ARIA',
            'name': 'Legends Of Aria',
            'address': 'F4q5mMxk9RA2a9dwfa2FgJjhvuqbk8SC9jEpnDVz5TFy',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27157/thumb/ARIA_LOGO.png?1662276215',
            'coingeckoId': 'legends-of-aria',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AART',
            'name': 'ALL ART',
            'address': 'F3nefJBcejYbtdREjui1T9DPh5dBgpkKq7u2GAAMXs5B',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/allartprotocol/token-list/main/assets/mainnet/F3nefJBcejYbtdREjui1T9DPh5dBgpkKq7u2GAAMXs5B/logo.jpg',
            'coingeckoId': 'all-art',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'MOONBURN',
            'name': 'MOONBURN',
            'address': 'F14Cp89oAXMrNnaC4mKMNKHPWw2p2R4DRFAZEdJhUBkD',
            'decimals': 5,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/Joblo197/Crypto/main/Shroom%20Logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'CSM',
            'name': 'Cricket Star Manager',
            'address': 'EzfnjRUKtc5vweE1GCLdHV4MkDQ3ebSpQXLobSKgQ9RB',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25774/thumb/CSM_token.png?1653631158',
            'coingeckoId': 'cricket-star-manager',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FTT',
            'name': 'FTX  Wormhole ',
            'address': 'EzfgjvkSwthhgHaceR3LnKXUoRkP6NUhfghdaHAj1tUv',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EzfgjvkSwthhgHaceR3LnKXUoRkP6NUhfghdaHAj1tUv/logo.png',
            'coingeckoId': 'ftx-wormhole',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'CONDOMS',
            'name': 'SolCondoms',
            'address': 'EzL6LLmv4vgfF7irkjG7ZxM92bTJ9f6nFopDXJTow7zj',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21814/thumb/DboqD2_o_400x400.jpg?1640071580',
            'coingeckoId': 'solcondoms',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'abETH',
            'name': 'Wrapped ETH (Allbridge from BSC)',
            'address': 'EyrnrbE5ujd3HQG5PZd9MbECN9yaQrqc8pRwGtaLoyC',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FeGn77dhg1KXRRFeSwwMiykZnZPw5JXW6naf2aQgZDQf/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': '$MCREW',
            'name': 'MONKE CREW',
            'address': 'Exz2u9EhSXzGDef4v8bfXVjcUbsFm4kMKoXxn58fDUSa',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Exz2u9EhSXzGDef4v8bfXVjcUbsFm4kMKoXxn58fDUSa/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'EXIST',
            'name': 'Exist',
            'address': 'ExistEr1h19DiEPPzaDpwx3DnjQbrVbXpaxKDYBSNoWj',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25002/thumb/200x200.png?1649740868',
            'coingeckoId': 'exist',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'apMATIC',
            'name': 'Wrapped MATIC (Allbridge from Polygon)',
            'address': 'EwzpcfgyE3UZsNbpMkKjgQWiXYDmY3LFcvc7DKjtdvWk',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/ewzpcfgye3uzsnbpmkkjgqwixydmy3lfcvc7dkjtdvwk/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ACUSD',
            'name': 'Wrapped CUSD  Allbridge from Celo ',
            'address': 'EwxNF8g9UfmsJVcZFTpL9Hx5MCkoQFoJi6XNWzKf1j8e',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/7236.png',
            'coingeckoId': 'wrapped-cusd-allbridge-from-celo',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'UPS',
            'name': 'UPFI Network',
            'address': 'EwJN2GqUGXXzYmoAciwuABtorHczTA5LqbukKXV1viH7',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EwJN2GqUGXXzYmoAciwuABtorHczTA5LqbukKXV1viH7/logo.png',
            'coingeckoId': 'upfi-network',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'NPC',
            'name': 'NPC DAO',
            'address': 'EuD5L5XSYKzyDC1YyYzmoWC8gmJhpEh2vMj4f8LeRW8r',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EuD5L5XSYKzyDC1YyYzmoWC8gmJhpEh2vMj4f8LeRW8r/logo.png',
            'coingeckoId': 'nole-npc',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'PHY',
            'name': 'Physis',
            'address': 'EswgBj2hZKdgovX2ihWSUDnuBg9VNbGmSGoH5yjNsPRa',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EswgBj2hZKdgovX2ihWSUDnuBg9VNbGmSGoH5yjNsPRa/logo.png',
            'coingeckoId': 'physis',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'FTM',
            'name': 'FTM (Allbridge from Fantom)',
            'address': 'EsPKhGTMf3bGoy4Qm7pCv3UCcWqAmbC1UGHBTDxRjjD4',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EsPKhGTMf3bGoy4Qm7pCv3UCcWqAmbC1UGHBTDxRjjD4/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'Tether',
            'address': 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB/logo.svg',
            'coingeckoId': 'tether',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'FRKT',
            'name': 'FRAKT',
            'address': 'ErGB9xa24Szxbk1M28u2Tx8rKPqzL6BroNkkzk5rG4zj',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ErGB9xa24Szxbk1M28u2Tx8rKPqzL6BroNkkzk5rG4zj/logo.png',
            'coingeckoId': 'frakt-token',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'soLUA',
            'name': 'Wrapped LUA (Sollet)',
            'address': 'EqWCKXfs3x47uVosDpTRgFniThL9Y8iCztJaapxbEaVX',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EqWCKXfs3x47uVosDpTRgFniThL9Y8iCztJaapxbEaVX/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'TURD',
            'name': 'Actual Shitcoin',
            'address': 'EpnJLu5oc1UWG25jNvSzuezz1ENrxbDnLg1wQDC7Hfbw',
            'decimals': 2,
            'chainId': -1,
            'logoURI': 'https://cdn.jsdelivr.net/gh/solana-labs/token-list@main/assets/mainnet/EpnJLu5oc1UWG25jNvSzuezz1ENrxbDnLg1wQDC7Hfbw/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SOB',
            'name': 'SolaLambo',
            'address': 'EkDf4Nt89x4Usnxkj4sGHX7sWxkmmpiBzA4qdDkgEN6b',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EkDf4Nt89x4Usnxkj4sGHX7sWxkmmpiBzA4qdDkgEN6b/logo.png',
            'coingeckoId': 'solalambo',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'DAI',
            'name': 'Dai Stablecoin (Portal)',
            'address': 'EjmyN6qEC1Tf1JxiG1ae7UTJhUxSwk1TCWNWqxWV4J6o',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EjmyN6qEC1Tf1JxiG1ae7UTJhUxSwk1TCWNWqxWV4J6o/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'KZN',
            'name': 'Kartazion',
            'address': 'EjSwAfwi4F6uYtoi2WuCSYSWPVUPJCdemmShZ9tdy65P',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EjSwAfwi4F6uYtoi2WuCSYSWPVUPJCdemmShZ9tdy65P/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': '$PNDC',
            'name': 'Pandar Coin',
            'address': 'EjKuHMKXXjbsc1MbVgXzv7GRWTgBaaTiAvQMKpJPAzTp',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/ejkuhmkxxjbsc1mbvgxzv7grwtgbaatiavqmkpjpaztp/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ROYALE',
            'name': 'Royale token',
            'address': 'EiNEYyUcPHpGt2btoMeuTrLtsAeayY74ECvPRYzcdPpo',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EiNEYyUcPHpGt2btoMeuTrLtsAeayY74ECvPRYzcdPpo/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ACE',
            'name': 'ACEVERSE',
            'address': 'EiJEQs1Hdo41RWvdFeRJi1umZLuvkgBYbipWS7Udpdxc',
            'decimals': 2,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/eijeqs1hdo41rwvdferji1umzluvkgbybipws7udpdxc/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'aaDAI',
            'name': 'Wrapped DAI (Allbridge from Avalanche)',
            'address': 'EgQ3yNtVhdHz7g1ZhjfGbxhFKMPPaFkz8QHXM5RBZBgi',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FYpdBuyAHSbdaAyD1sKkxyLWbAP8uUW9h6uvdhK74ij1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'AMA',
            'name': 'AMA token',
            'address': 'EeFrrySDy3mp8vHyqMFrFyHREfqcdrXPzkAyVjpwbfMk',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/eefrrysdy3mp8vhyqmfrfyhrefqcdrxpzkayvjpwbfmk/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ATT',
            'name': 'AlterToken',
            'address': 'Ee9zTuGPX1YpAnpgPm5pi2juy8NrGQnky5RspvFh8JfE',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/ee9ztugpx1ypanpgpm5pi2juy8nrgqnky5rspvfh8jfe/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FAB',
            'name': 'Fabric',
            'address': 'EdAhkbj5nF9sRM7XN7ewuW8C9XEUMs8P7cnoQ57SYE96',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EdAhkbj5nF9sRM7XN7ewuW8C9XEUMs8P7cnoQ57SYE96/logo.png',
            'coingeckoId': 'fabric',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SANTA',
            'name': 'Santaclaus',
            'address': 'EctmRn2jMAdTDvQdG7mxadyiTvhGZiGYNrt9PWe6zioG',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EctmRn2jMAdTDvQdG7mxadyiTvhGZiGYNrt9PWe6zioG/logo.png',
            'coingeckoId': 'santaclaus',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'FIDA',
            'name': 'Bonfida',
            'address': 'EchesyfXePKdLtoiZSL8pBe8Myagyy8ZRqsACNCFGnvp',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EchesyfXePKdLtoiZSL8pBe8Myagyy8ZRqsACNCFGnvp/logo.svg',
            'coingeckoId': 'bonfida',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'WLKN',
            'name': 'Walken',
            'address': 'EcQCUYv57C4V6RoPxkVUiDwtX1SP8y8FP5AEToYL8Az',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25566/thumb/wlkn.jpg?1652523301',
            'coingeckoId': 'walken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NOCH',
            'name': 'NodeBunch',
            'address': 'EcFyPDjqpnyMvh1LhACtC6rrCZ41DMez7RZYocjhmUVS',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24880/thumb/logo.png?1649224634',
            'coingeckoId': 'nodebunch',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PAI',
            'name': 'Parrot USD',
            'address': 'Ea5SjE2Y6yvCeW5dYTn7PYMuW5ikXkvbGdcmSnXeaLjS',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ea5SjE2Y6yvCeW5dYTn7PYMuW5ikXkvbGdcmSnXeaLjS/logo.svg',
            'coingeckoId': 'parrot-usd',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'FUM',
            'name': 'FUMoney',
            'address': 'EZF2sPJRe26e8iyXaCrmEefrGVBkqqNGv9UPGG9EnTQz',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/FUMoney801/token-list/main/assets/mainnet/EZF2sPJRe26e8iyXaCrmEefrGVBkqqNGv9UPGG9EnTQz/logo.png',
            'coingeckoId': 'fumoney',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'BST',
            'name': 'Balisari',
            'address': 'EYDEQW4xQzLqHcFwHTgGvpdjsa5EFn74KzuqLX5emjD2',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EYDEQW4xQzLqHcFwHTgGvpdjsa5EFn74KzuqLX5emjD2/logo.png',
            'coingeckoId': 'balisari',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'STAR',
            'name': 'Star Chain',
            'address': 'EXGqHqvKBs4Z1mCwhiGE7kT2TXGFirAjvQzPSQP8nvuw',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25848/thumb/logo.png?1654150342',
            'coingeckoId': 'star-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOLBERRY',
            'name': 'SolBerry',
            'address': 'EWS2ATMt5fQk89NWLJYNRmGaNoji8MhFZkUB4DiWCCcz',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EWS2ATMt5fQk89NWLJYNRmGaNoji8MhFZkUB4DiWCCcz/logo.png',
            'coingeckoId': 'solberry',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'sagEUR-9',
            'name': 'Saber Wrapped agEUR (Portal) (9 decimals)',
            'address': 'EU9aLffrTckFCs16da6CppHy63fAxMPF9ih1erQTuuRt',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EU9aLffrTckFCs16da6CppHy63fAxMPF9ih1erQTuuRt/icon.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'MEDIA',
            'name': 'Media Network',
            'address': 'ETAtLmCmsoiEEKfNrHKJ2kYy3MoABhU6NQvpSfij5tDs',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ETAtLmCmsoiEEKfNrHKJ2kYy3MoABhU6NQvpSfij5tDs/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'MIT',
            'name': 'Muskimum Impact Token',
            'address': 'ERPueLaiBW48uBhqX1CvCYBv2ApHN6ZFuME1MeQGTdAi',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ERPueLaiBW48uBhqX1CvCYBv2ApHN6ZFuME1MeQGTdAi/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'APEX',
            'name': 'Monster Apex',
            'address': 'EQvh5nmHoD16dTTiZ7222EBSUQ563rh6o8kJ1W4Mwh7Z',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/eqvh5nmhod16dttiz7222ebsuq563rh6o8kj1w4mwh7z/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USD Coin',
            'address': 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png',
            'coingeckoId': 'usd-coin',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'ANGRY',
            'name': 'WhySoAngry',
            'address': 'EPae2UjdHnQiB6SaC8WJ2jMa7NJEMmuW9ZR4Z4yRMUhy',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/epae2ujdhnqib6sac8wj2jma7njemmuw9zr4z4yrmuhy/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'KRILL',
            'name': 'Krill',
            'address': 'EP2aYBDD4WvdhnwWLUMyqU69g1ePtEjgYK6qyEAFCHTx',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23917/thumb/Krill_towen.png?1645684293',
            'coingeckoId': 'krill',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'wHEX_v1',
            'name': 'HEX (Wormhole v1)',
            'address': 'ELSnGFd5XnSdYFFSgYQp7n89FEbDqxN4npuRLW4PPPLv',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ELSnGFd5XnSdYFFSgYQp7n89FEbDqxN4npuRLW4PPPLv/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'VIDI',
            'name': 'VIDI',
            'address': 'ELADrKrvyv7mtQ5DbCvPDGogn4fcApH3jDeTy2qpfhsA',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ELADrKrvyv7mtQ5DbCvPDGogn4fcApH3jDeTy2qpfhsA/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ABC',
            'name': 'ABC Floor Index',
            'address': 'EKLq86cHRwc8Spkcx2noPnfoVyQvcWSeud5JMJnTxNAD',
            'decimals': 2,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27172/thumb/abc.png?1662287244',
            'coingeckoId': 'abc-floor-index',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DRAY',
            'name': 'Draygon Investment',
            'address': 'EK1rBdnucX4yf8JDCFQEC7rTejXEUqsjazDxHZaHSKT7',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/benpazzo/dray/main/assets/draylogo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'WEYU',
            'name': 'WEYU',
            'address': 'EHaEBhYHWA7HSphorXXosysJem6qF4agccoqDqQKCUge',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18112/thumb/weyu.PNG?1630542552',
            'coingeckoId': 'weyu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RAC',
            'name': 'Rainc',
            'address': 'EH49ziLeKhJtzUzdys5238pSKpvrgJvmi3EStrZ9QaY7',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EH49ziLeKhJtzUzdys5238pSKpvrgJvmi3EStrZ9QaY7/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'BIT',
            'name': 'Bitmon',
            'address': 'EGiWZhNk3vUNJr35MbL2tY5YD6D81VVZghR2LgEFyXZh',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Gtj6NZHgMZ7QHg8PDN6izEn4GjVoX8yFLv4ESNKsWb62/BT-logo.png',
            'coingeckoId': 'bitmon',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SPKL',
            'name': 'Spookeletons Token',
            'address': 'EFYKDdppK1FjixaxExpVhoTd8gtAmncbhQYruzWyG6Cx',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://www.spookeletons.com/assets/spookeletons_token.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'AVDO',
            'name': 'AvocadoCoin',
            'address': 'EE5L8cMU4itTsCSuor7NLK6RZx6JhsBe8GGV3oaAHm3P',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23675/thumb/PNLL1B2g_400x400.jpg?1644996225',
            'coingeckoId': 'avocadocoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '007E',
            'name': '007 Exchange',
            'address': 'EAniGDVY2VKUtZxvpHnbazHfZgfo3bp61TxUGHzw3Cn7',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/eanigdvy2vkutzxvphnbazhfzgfo3bp61txughzw3cn7/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'RPC',
            'name': 'Republic Credits',
            'address': 'EAefyXw6E8sny1cX3LTH6RSvtzH6E5EFy1XsE2AiH1f3',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EAefyXw6E8sny1cX3LTH6RSvtzH6E5EFy1XsE2AiH1f3/logo.png',
            'coingeckoId': 'republic-credits',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'CCC',
            'name': 'Chairman Chow',
            'address': 'E9bjYSAqabYAd2Zaev4qAMVNAiX7Z2Dp4Sn1JgsTn2b6',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E9bjYSAqabYAd2Zaev4qAMVNAiX7Z2Dp4Sn1JgsTn2b6/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'aeUNI',
            'name': 'Wrapped UNI (Allbridge from Ethereum)',
            'address': 'E7zDjtDVWRgsdgMDcbtzTMVMkcWcyF35Do6Qmis5nvBZ',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/e7zdjtdvwrgsdgmdcbtztmvmkcwcyf35do6qmis5nvbz/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PPUG',
            'name': 'Pizza Pug Coin',
            'address': 'E7WqtfRHcY8YW8z65u9WmD7CfMmvtrm2qPVicSzDxLaT',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E7WqtfRHcY8YW8z65u9WmD7CfMmvtrm2qPVicSzDxLaT/logo.png',
            'coingeckoId': 'pizza-pug-coin',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'abUSDT',
            'name': 'Wrapped USDT (Allbridge from BSC)',
            'address': 'E77cpQ4VncGmcAXX16LHFFzNBEBb2U7Ar7LBmZNfCgwL',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BQcdHdAQW1hczDbBi9hiegXAR7A98Q9jx3X3iBBBDiq4/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'PLAYA',
            'name': 'Playground',
            'address': 'E6oCGvmSYW7qhy7oeDfiNZLX6hEmPCVxBC8AknwAj82B',
            'decimals': 2,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24387/thumb/playa.PNG?1647502195',
            'coingeckoId': 'playground',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AIR',
            'name': 'Balloonsville AIR',
            'address': 'E6eCEE3KqjRD5UxcBYQTdV8Z535hyaBuFin9Udm6s6bz',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24853/thumb/9CmgcH6.png?1649134566',
            'coingeckoId': 'balloonsville-air',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'DGLN',
            'name': 'Dogelana',
            'address': 'E6UU5M1z4CvSAAF99d9wRoXsasWMEXsvHrz3JQRXtm2X',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E6UU5M1z4CvSAAF99d9wRoXsasWMEXsvHrz3JQRXtm2X/logo.png',
            'coingeckoId': 'dogelana',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'DICK',
            'name': 'Dickcoin',
            'address': 'E6Hkw5o48QfNo6iUi1aepjEBzVq4ZjQLxh7xVtdTqoyB',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E6Hkw5o48QfNo6iUi1aepjEBzVq4ZjQLxh7xVtdTqoyB/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'WOO',
            'name': 'WOO Network',
            'address': 'E5rk3nmgLUuKUiS94gg4bpWwWwyjCMtddsAXkTFLtHEy',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E5rk3nmgLUuKUiS94gg4bpWwWwyjCMtddsAXkTFLtHEy/logo.png',
            'coingeckoId': 'woo-network',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'RIN',
            'name': 'Aldrin',
            'address': 'E5ndSkaB17Dm7CsD22dvcjfrYSDLCxFcMd6z8ddCk5wp',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E5ndSkaB17Dm7CsD22dvcjfrYSDLCxFcMd6z8ddCk5wp/logo.png',
            'coingeckoId': 'aldrin',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'MCK',
            'name': 'Mickey',
            'address': 'E48Ueg1o9avL5s7XBjfLViercSrNSJCvmbZMvnwN873',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E48Ueg1o9avL5s7XBjfLViercSrNSJCvmbZMvnwN873/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'APE',
            'name': 'Cyber Ape',
            'address': 'E3RN9omoTNdqKXGj988X8JuCXwNZ6ZHXbfpaZ1nVZbrA',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/e3rn9omotndqkxgj988x8jucxwnz6zhxbfpaz1nvzbra/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'USDCPO',
            'name': 'USD Coin  PoS   Wormhole ',
            'address': 'E2VmbootbVCBkMNNxKQgCLMS1X3NoGMaYAsufaAsf7M',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E2VmbootbVCBkMNNxKQgCLMS1X3NoGMaYAsufaAsf7M/logo.png',
            'coingeckoId': 'usd-coin-pos-wormhole',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'GOLD',
            'name': 'SolGold',
            'address': 'E1zxRweqCWzviAraKjNjqupuyYTzm1bukJgb8KiBN1sN',
            'decimals': 5,
            'chainId': -1,
            'logoURI': 'https://lizardtoken.xyz/gallery/gold-ts1635027612.jpg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'CAC',
            'name': 'Cosmic Ape Coin',
            'address': 'E1s2muWwiLT2n3EQUL27hgviaPRRXWkpXD7ShpfgRvVz',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22570/thumb/Cosmic-Ape-Logosc.png?1643186237',
            'coingeckoId': 'cosmic-ape-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'INO',
            'name': 'NoGoal',
            'address': 'E1PvPRPQvZNivZbXRL61AEGr71npZQ5JGxh4aWX7q9QA',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/E1PvPRPQvZNivZbXRL61AEGr71npZQ5JGxh4aWX7q9QA/logo.png',
            'coingeckoId': 'nogoaltoken',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': '$HIT',
            'name': 'Shitcoin',
            'address': 'DzyZr2caKBt6cTzeXwx3VMPa91kvQg8uejH4xts9LmtJ',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/dzyzr2cakbt6ctzexwx3vmpa91kvqg8uejh4xts9lmtj/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BOX',
            'name': 'Solootbox DAO',
            'address': 'DysbQiM8nPdZbBhvHM1EgcSE73EwtFWDanXwY8CDD3Jn',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DysbQiM8nPdZbBhvHM1EgcSE73EwtFWDanXwY8CDD3Jn/logo.png',
            'coingeckoId': 'solootbox-dao',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SOLJAV',
            'name': 'SOLJAV',
            'address': 'Dypr2gWcVuqt3z6Uh31YD8Wm2V2ZCqWVBYEWhZNF9odk',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dypr2gWcVuqt3z6Uh31YD8Wm2V2ZCqWVBYEWhZNF9odk/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ATT',
            'name': 'Attention Coin',
            'address': 'DxcDecsWbXBy5S8phbRAMfNH8hJZWLwUkMug8JNT77d2',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/dxcdecswbxby5s8phbramfnh8hjzwlwukmug8jnt77d2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'CRP',
            'name': 'CropperFinance',
            'address': 'DubwWZNWiNGMMeeQHPnMATNj77YZPZSAz2WVR5WjLJqz',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DubwWZNWiNGMMeeQHPnMATNj77YZPZSAz2WVR5WjLJqz/logo.png',
            'coingeckoId': 'cropperfinance',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'GXE',
            'name': 'Galaxy Essential',
            'address': 'DsVPH4mAppxKrmdzcizGfPtLYEBAkQGK4eUch32wgaHY',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25754/thumb/GXE.png?1653466290',
            'coingeckoId': 'galaxy-essential',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BLD',
            'name': 'BladesToken',
            'address': 'DrcPRJPBiakQcWqon3gZms7sviAqdQS5zS5wvaG5v6wu',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DrcPRJPBiakQcWqon3gZms7sviAqdQS5zS5wvaG5v6wu/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ROAR',
            'name': 'SOL Tigers Roar',
            'address': 'DqxzPWQ2FKHn8pRoy9jCpA6M3GkEqYfieiAVwMYWVyXr',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DqxzPWQ2FKHn8pRoy9jCpA6M3GkEqYfieiAVwMYWVyXr/logo.png',
            'coingeckoId': 'roar-token',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'DSC',
            'name': 'DoggyStyle Coin',
            'address': 'DogscQVvNVj7ndEnhWiCXPVPKKwNy9fJd4ATF7mVi5J',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/Doggystylecoin/DSC/main/logo.png',
            'coingeckoId': 'doggystyle-coin',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'DLANA',
            'name': 'Dogelana Token',
            'address': 'DogeLZECE9CthXasBLFxgeA2umEyt8CcV7Jsf6P5ZTFo',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DogeLZECE9CthXasBLFxgeA2umEyt8CcV7Jsf6P5ZTFo/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'abLINK',
            'name': 'Wrapped LINK (Allbridge from BSC)',
            'address': 'DoH3AKgMCShGxzpSgHeGyU3AosnHmJbYShgjZwNeFEsm',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/doh3akgmcshgxzpsghegyu3aosnhmjbyshgjzwnefesm/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'HALO',
            'name': 'HALO',
            'address': 'Do5AbqdEbj742B2Cm8BypAGg3h1skLaAVTbT2mLRcW8c',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/farhan067/HaloTokenLogo/main/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'USDTET',
            'name': 'Tether USD  Wormhole from Ethereum ',
            'address': 'Dn4noZ5jgGfkntzcQSUZ8czkreiZ1ForXYoV2H8Dm7S1',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dn4noZ5jgGfkntzcQSUZ8czkreiZ1ForXYoV2H8Dm7S1/logo.png',
            'coingeckoId': 'tether-usd-wormhole-from-ethereum',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'WEENS',
            'name': 'Ween',
            'address': 'DmXfDUeyRJqnpvdjssGgUXwZrRFPXvu2DfMq4jfTTC9C',
            'decimals': 0,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21764/thumb/logo_%281%29.png?1639991986',
            'coingeckoId': 'ween-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOLBEAR',
            'name': 'Solar Bear',
            'address': 'DktNJUJAWJyeLw3ykCkFNpGohE24SoEhevKBskRi6P1y',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DktNJUJAWJyeLw3ykCkFNpGohE24SoEhevKBskRi6P1y/logo.png',
            'coingeckoId': 'solar-bear',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'QTCON',
            'name': 'Quiztok',
            'address': 'DkNihsQs1hqEwf9TgKP8FmGv7dmMQ7hnKjS2ZSmMZZBE',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/8208/thumb/QTCON.png?1587543372',
            'coingeckoId': 'quiztok',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AUSS',
            'name': 'Ausshole',
            'address': 'Djoz8btdR7p6xWHoVtPYF3zyN9LU5BBfMoDk4HczSDqc',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Djoz8btdR7p6xWHoVtPYF3zyN9LU5BBfMoDk4HczSDqc/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'VOID',
            'name': 'Void Games',
            'address': 'DjPt6xxMoZx1DyyWUHGs4mwqWWX48Fwf6ZJgqv2F9qwc',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21307/thumb/h5lkasZH_400x400.jpg?1638886042',
            'coingeckoId': 'void-games',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '$DIGI',
            'name': 'Digi Coin',
            'address': 'Digi7SnUD9ddiitEqkNfby1c4BfkgokFKWu9KScbizes',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Digi7SnUD9ddiitEqkNfby1c4BfkgokFKWu9KScbizes/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SHIBA',
            'name': 'Shibalana',
            'address': 'Dhg9XnzJWzSQqH2aAnhPTEJHGQAkALDfD98MA499A7pa',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Dhg9XnzJWzSQqH2aAnhPTEJHGQAkALDfD98MA499A7pa/logo.png',
            'coingeckoId': 'shibalana',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'MEK',
            'name': 'MEK',
            'address': 'DhYTJPmUa5kQZfLgHb1soubgaK4VLZMxb8CTNY1vZ93S',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DhYTJPmUa5kQZfLgHb1soubgaK4VLZMxb8CTNY1vZ93S/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'soCEL',
            'name': 'Wrapped Celsius (Sollet)',
            'address': 'DgHK9mfhMtUwwv54GChRrU54T2Em5cuszq2uMuen1ZVE',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DgHK9mfhMtUwwv54GChRrU54T2Em5cuszq2uMuen1ZVE/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'aeUSDC',
            'name': 'Wrapped USDC (Allbridge from Ethereum)',
            'address': 'DdFPRnccQqLD4zCHrBqdY95D6hvw6PLWp9DEXj1fLCL9',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'FLUFF',
            'name': 'SolSamos',
            'address': 'Dd7pji6EruuFPuAxuZG5LwZUdPSzYCTN6NsttrkHeedS',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solsamos/token-list/main/assets/mainnet/Dd7pji6EruuFPuAxuZG5LwZUdPSzYCTN6NsttrkHeedS/FluffIcon.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'BOOGI',
            'name': 'BABY OOGI',
            'address': 'DcvJP16Cw5oqTbtHmpJ4JGXaqBvV5m6eMZj5rGsFLpwU',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DcvJP16Cw5oqTbtHmpJ4JGXaqBvV5m6eMZj5rGsFLpwU/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'GMP',
            'name': 'Gamerpull',
            'address': 'DbRA7Jp8p3tztoPWrDQeJqpKLKXJpotUzJoeiiCdxewz',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DbRA7Jp8p3tztoPWrDQeJqpKLKXJpotUzJoeiiCdxewz/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'MARIJUANA',
            'name': 'Marijuana Joint',
            'address': 'DYbRXaQcnj44SH9woxvyFdtcKkSoPoCEshRTQDZSjsBm',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DYbRXaQcnj44SH9woxvyFdtcKkSoPoCEshRTQDZSjsBm/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'pBTC',
            'name': 'pBTC (Parrot BTC)',
            'address': 'DYDWu4hE4MN3aH897xQ3sRTs5EAjJDmQsKLNhbpUiKun',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DYDWu4hE4MN3aH897xQ3sRTs5EAjJDmQsKLNhbpUiKun/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'GOATS',
            'name': 'GOATS',
            'address': 'DVPWKGLFHK73PwgKgTtW28iCZGewQdva2N5HeBLDorVJ',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'https://www.solgoats.io/logo_full.png',
            'coingeckoId': 'goats',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'DUST',
            'name': 'DUST Protocol',
            'address': 'DUSTawucrTsGU8hcqRdHDCbuYhCPADMLM2VcCb8VnFnQ',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24289/thumb/dust-protocol-degod.png?1647306854',
            'coingeckoId': 'dust-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WHALES',
            'name': 'Catalina Whales Index',
            'address': 'DSXWF79VQ3RzFBB67WeNfCzfzAQq5X6m97zi85bq1TRx',
            'decimals': 2,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27053/thumb/whales.png?1661507402',
            'coingeckoId': 'catalina-whales-index',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AKG',
            'name': 'ArkEnGel',
            'address': 'DQNdtA2KjZZZpC76BGh9QTtJdaZ8ir5wf7yNafEMzsD',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/dqndta2kjzzzpc76bgh9qttjdaz8ir5wf7ynafemzsd/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'APUSDT',
            'name': 'Wrapped USDT  Allbridge from Polygon ',
            'address': 'DNhZkUaxHXYvpxZ7LNnHtss8sQgdAfd1ZYS1fB7LKWUZ',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BQcdHdAQW1hczDbBi9hiegXAR7A98Q9jx3X3iBBBDiq4/logo.png',
            'coingeckoId': 'wrapped-usdt-allbridge-from-polygon',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'UM',
            'name': 'UncleMine',
            'address': 'DMCUFm2ZAnSU7UgsdVq23gRogMU3MEBjPgQF1gK53rEn',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22871/thumb/unclemine_icon_400.png?1642756525',
            'coingeckoId': 'unclemine',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KAKA',
            'name': 'KAKA',
            'address': 'DM8WStrzE7XHLr5EeuE8693VAqAx3MiTcAqfH6WcsSSC',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DM8WStrzE7XHLr5EeuE8693VAqAx3MiTcAqfH6WcsSSC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': '0T',
            'name': 'Jeweloophole',
            'address': 'DL1WSv3tZpYr6pN489XXgP5GFvLyGtoDy6EW6dL6bFfL',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/dl1wsv3tzpyr6pn489xxgp5gfvlygtody6ew6dl6bffl/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BITXBIT',
            'name': 'BITXBIT',
            'address': 'DK6PWMyuZ4NMjsm9AWNCTMKrajQYrtfMjMJ3QauX2UH5',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DK6PWMyuZ4NMjsm9AWNCTMKrajQYrtfMjMJ3QauX2UH5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ABOMB',
            'name': 'ArtBomb',
            'address': 'DK64rmGSZupv1dLYn57e3pUVgs9jL9EKLXDVZZPsMDz8',
            'decimals': 5,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/dk64rmgszupv1dlyn57e3puvgs9jl9eklxdvzzpsmdz8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'soHXRO',
            'name': 'Wrapped HXRO (Sollet)',
            'address': 'DJafV9qemGp7mLMEn5wrfqaFwxsbLgUsGVS16zKRk9kc',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DJafV9qemGp7mLMEn5wrfqaFwxsbLgUsGVS16zKRk9kc/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'acUSDC',
            'name': 'Wrapped USDC (Allbridge from Celo)',
            'address': 'DHpoYejUDqzByb6HAdaLWF7KZvwUv2vWYDY9cTENNZui',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'BAMB',
            'name': 'BAMB',
            'address': 'DHbGBhZc1yLLgpPqAzr7KGs47oCMfbg2q6Fmg5NCSM1C',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DHbGBhZc1yLLgpPqAzr7KGs47oCMfbg2q6Fmg5NCSM1C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SOLAB',
            'name': 'SOLAB Finance',
            'address': 'DH5KjPM53i7NMj69CEZ6FiF82ipbgz1U6QzNfQNY87Pr',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/Protossnam/token-list/main/assets/mainnet/DH5KjPM53i7NMj69CEZ6FiF82ipbgz1U6QzNfQNY87Pr/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'DFL',
            'name': 'DeFi Land',
            'address': 'DFL1zNkaGPWm1BqAVqRjCZvHmwTFrEaJtbzJWgseoNJh',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DFL1zNkaGPWm1BqAVqRjCZvHmwTFrEaJtbzJWgseoNJh/logo.png',
            'coingeckoId': 'defi-land',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'soUNI',
            'name': 'Wrapped UNI (Sollet)',
            'address': 'DEhAasscXF4kEGxFgJ3bq4PpVGp5wyUxMRvn6TzGVHaw',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/DEhAasscXF4kEGxFgJ3bq4PpVGp5wyUxMRvn6TzGVHaw/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'FOUR',
            'name': '4thpillar technologies',
            'address': 'DAtU322C23YpoZyWBm8szk12QyqHa9rUQe1EYXzbm1JE',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/3434/thumb/four-ticker-2021-256x256.png?1617702287',
            'coingeckoId': 'the-4th-pillar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MCS',
            'name': 'Million Coin Solana',
            'address': 'DAZbw2FG5PCssV24SsP1E3m1whDn8paXZMwBdfVHRt5w',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://cdn.discordapp.com/attachments/908414498933735525/908414576838733854/Square-512.jpg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'OOAH',
            'name': 'OOAH Monkey',
            'address': 'D8Fc2HLd9L9V2mJnEUpnys6muJUawKYFnJWcUiaGKnyP',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D8Fc2HLd9L9V2mJnEUpnys6muJUawKYFnJWcUiaGKnyP/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'FUDAI',
            'name': 'FUDAI Token',
            'address': 'D6xt2imesfZ1zL57o72BBzjaDs91Q72kgoSGC6qpXY9T',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://fortunenekos.io/images/fudai.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'AMCN',
            'name': 'Ameer Coin',
            'address': 'D6jj3SiCwng8oDMZajPFPgw3KiQH5Qfw52Ch5Qpxvnhm',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/d6jj3sicwng8odmzajpfpgw3kiqh5qfw52ch5qpxvnhm/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': '$KAIDO',
            'name': 'Kaidos Token',
            'address': 'D5YJf7nqSp9JtQpMcZCthdEpMghK2gKmkLMLi2RhKSJt',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D5YJf7nqSp9JtQpMcZCthdEpMghK2gKmkLMLi2RhKSJt/Kaido.jpg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'BONE',
            'name': 'Bulldog Billionaires',
            'address': 'D3eyBjfgJMPHZyYDRtbf1cSxeLiNwKumwHzQK3h3TRRq',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://user-images.githubusercontent.com/95661911/153796714-8061210f-ebef-4db9-a646-ab8dc6b57e0f.png',
            'coingeckoId': 'bulldog-billionaires',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'THECA',
            'name': 'Theca',
            'address': 'D3cm6WRnyBct3p7vFqyTt2CaynsGPuVQT2zW6WHSTX6q',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/D3cm6WRnyBct3p7vFqyTt2CaynsGPuVQT2zW6WHSTX6q/logo.png',
            'coingeckoId': 'theca',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': '$BOJACK',
            'name': 'Bojack Coin',
            'address': 'D1Kyn6tyKQPy3QZTvjKpavEPAWZXqQUH8q9Fc4ZWKHnN',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/d1kyn6tykqpy3qztvjkpavepawzxqquh8q9fc4zwkhnn/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'NESTA',
            'name': 'Nest Arcade',
            'address': 'Czt7Fc4dz6BpLh2vKiSYyotNK2uPPDhvbWrrLeD9QxhV',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Czt7Fc4dz6BpLh2vKiSYyotNK2uPPDhvbWrrLeD9QxhV/logo.png',
            'coingeckoId': 'nest-arcade',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'DRGNZ',
            'name': 'Boryoku Genesis Dragonz Index',
            'address': 'CzXF8oUJSsB9ADKV99WAi2TgytqAyKvQw6EihwiL9em4',
            'decimals': 2,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24386/thumb/AzJI7FQ.png?1647501836',
            'coingeckoId': 'boryoku-genesis-dragonz-index',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANT',
            'name': 'Ant',
            'address': 'CtKP9sK6MHfQquhME6tyooc7LaztAN1pUBmHZT5RVQLR',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/ctkp9sk6mhfqquhme6tyooc7laztan1pubmhzt5rvqlr/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SXS',
            'name': 'SoldierXSolvivor Coin',
            'address': 'Ct7mbdwLmdFC6zgVRXFidvvgYbtGo2icsntNSSgzxoLs',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ct7mbdwLmdFC6zgVRXFidvvgYbtGo2icsntNSSgzxoLs/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'soALEPH',
            'name': 'Wrapped ALEPH (Sollet)',
            'address': 'CsZ5LZkDS7h9TDKjrbL7VAwQZ9nsRu8vJLhRYfmGaN8K',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CsZ5LZkDS7h9TDKjrbL7VAwQZ9nsRu8vJLhRYfmGaN8K/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'Vikings',
            'name': 'Viking Legend',
            'address': 'CrhUSH7FDwB37BYvPsVnVbsGVeE81biBzfkD4A4fyJMv',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CrhUSH7FDwB37BYvPsVnVbsGVeE81biBzfkD4A4fyJMv/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'DARC',
            'name': 'Konstellation',
            'address': 'CpFE715P5DnDoJj9FbCRcuyHHeTXNdRnvzNkHvq1o23U',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/2943/thumb/darctoken.png?1645230834',
            'coingeckoId': 'darcmatter-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'COW',
            'name': 'CashCow',
            'address': 'CowKesoLUaHSbAMaUxJUj7eodHHsaLsS65cy8NFyRDGP',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CowKesoLUaHSbAMaUxJUj7eodHHsaLsS65cy8NFyRDGP/icon.png',
            'coingeckoId': 'cashcow',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': '$COOL',
            'name': 'COOLTURA',
            'address': 'CooL7VvP1BoSmnPDTXUnKQgRo1tMUo9mHfmwMWn4sm9F',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/cool7vvp1bosmnpdtxunkqgro1tmuo9mhfmwmwn4sm9f/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PEOPLE',
            'name': 'ConstitutionDAO  Wormhole ',
            'address': 'CobcsUrt3p91FwvULYKorQejgsm5HoQdv5T8RUZ6PnLA',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CobcsUrt3p91FwvULYKorQejgsm5HoQdv5T8RUZ6PnLA/logo.png',
            'coingeckoId': 'constitutiondao-wormhole',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'GM',
            'name': 'GM Solana',
            'address': 'CmSryDa4mnDYUicq7qSESsTKAdgBP26jSYcg8zavVoJd',
            'decimals': 7,
            'chainId': -1,
            'logoURI': 'https://cdn.jsdelivr.net/gh/brendanhernou/gm/GMSolana.jpg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SHIB',
            'name': 'Shiba Inu  Wormhole ',
            'address': 'CiKu4eHsVrc1eueVQeHn7qhXTcVu95gSQmBpX4utjL9z',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CiKu4eHsVrc1eueVQeHn7qhXTcVu95gSQmBpX4utjL9z/logo.png',
            'coingeckoId': 'shiba-inu-wormhole',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'PLUT',
            'name': 'PlutusFi',
            'address': 'CiAkzbxkQCyY7hFtNeUHMbqiL8CXtbWaRnUJpJz5sBrE',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27964/thumb/LeQ9aIhU_400x400.jpeg?1666754146',
            'coingeckoId': 'plutusfi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SUSHI',
            'name': 'Sushi',
            'address': 'ChVzxWRmrTeSgwd3Ui3UumcN8KX7VK3WaD4KGeSKpypj',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ChVzxWRmrTeSgwd3Ui3UumcN8KX7VK3WaD4KGeSKpypj/logo.png',
            'coingeckoId': 'sushi',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'MEND',
            'name': 'Mend',
            'address': 'Ch9NFVk5sqEPQHtw2gJVgnHfTm7FW1JspYwc7SxLi6q3',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ch9NFVk5sqEPQHtw2gJVgnHfTm7FW1JspYwc7SxLi6q3/logo.png',
            'coingeckoId': 'mend',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'VINU',
            'name': 'Viral Inu',
            'address': 'CgbJxXyaHeU8VsquBpySuFXA94b6LWXxioZ28wRr8fs9',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CgbJxXyaHeU8VsquBpySuFXA94b6LWXxioZ28wRr8fs9/logo.png',
            'coingeckoId': 'viral-inu',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'DATE',
            'name': 'SolDate',
            'address': 'Ce3PSQfkxT5ua4r2JqCoWYrMwKWC5hEzwsrT9Hb7mAz9',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ce3PSQfkxT5ua4r2JqCoWYrMwKWC5hEzwsrT9Hb7mAz9/DATE.svg',
            'coingeckoId': 'soldate-token',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'EURONIN',
            'name': 'Euronin',
            'address': 'CbU2bWHoy73HqCm9PQjGcniaxhFkQ65zWTJyUfNU5694',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24173/thumb/Social_Profile-1_%281%29.jpeg?1646751614',
            'coingeckoId': 'euronin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AGEUR',
            'name': 'agEUR  Wormhole ',
            'address': 'CbNYA9n3927uXUukee2Hf4tm3xxkffJPPZvGazc2EAH1',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CbNYA9n3927uXUukee2Hf4tm3xxkffJPPZvGazc2EAH1/logo.png',
            'coingeckoId': 'ageur-wormhole',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'CHUG',
            'name': 'CHUG',
            'address': 'CbDwU8JrTYv3GzU7msni8qtfFkAGpcyFAzuhuGq5SVqp',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://solthirsty.io/images/chug.png',
            'coingeckoId': 'chug-token',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'OKAYB',
            'name': 'Okay Bears Floor Index',
            'address': 'Ca9LxRYdZ7jK4QAqjLo4iaYmiV8FNdngtSkzM69hzgDX',
            'decimals': 2,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27170/thumb/9815.png?1662286926',
            'coingeckoId': 'okay-bears-floor-index',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DIVX',
            'name': 'Dividex',
            'address': 'CYbXZ7U1AeV8kjtJG3YqKMLaWtdMLa24JojrikZZdXAG',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CYbXZ7U1AeV8kjtJG3YqKMLaWtdMLa24JojrikZZdXAG/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'NFD',
            'name': 'Feisty Doge NFT',
            'address': 'CY2E69dSG9vBsMoaXDvYmMDSMEP4SZtRY1rqVQ9tkNDu',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CY2E69dSG9vBsMoaXDvYmMDSMEP4SZtRY1rqVQ9tkNDu/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'USTC',
            'name': 'Wrapped USTC',
            'address': 'CXLBjMMcwkc17GfJtBos6rQCo1ypeH6eDbB82Kby4MRm',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CXLBjMMcwkc17GfJtBos6rQCo1ypeH6eDbB82Kby4MRm/logo.png',
            'coingeckoId': 'wrapped-ust',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'GIF',
            'name': 'Giraffe',
            'address': 'CWUUV3ym4Uphw4CVgkpNxrR7FsttF7h7mLggEUJ1J1aV',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CWUUV3ym4Uphw4CVgkpNxrR7FsttF7h7mLggEUJ1J1aV/logo.jpg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SOLINK',
            'name': 'Wrapped Chainlink  Sollet ',
            'address': 'CWE8jPTUYhdCTZYWPTe1o5DFqfdjzWKc9WKz6rSjQUdG',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CWE8jPTUYhdCTZYWPTe1o5DFqfdjzWKc9WKz6rSjQUdG/logo.png',
            'coingeckoId': 'wrapped-chainlink-sollet',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'ATPAY',
            'name': 'AtPay',
            'address': 'CWBzupvyXN1Cf5rsBEHbzfTFvreLfUaJ77BMNLVJ739y',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27254/thumb/atpay.png?1663049746',
            'coingeckoId': 'atpay',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CoW',
            'name': 'Culture of Women Token',
            'address': 'CVj6FV4HmhEsn7xQXCjj5iqbDQHB7hQ8AwZjB6P8UMDu',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://cdn.jsdelivr.net/gh/cultureofwomen/CoW-token/CoW.jpeg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'CUSD',
            'name': 'Coin98 Dollar',
            'address': 'CUSDvqAQLbt7fRofcmV2EXfPA2t36kzj7FjzdmqDiNQL',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26588/thumb/CUSD-01.png?1658909049',
            'coingeckoId': 'coin98-dollar',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HUNT',
            'name': 'Hunter Diamond',
            'address': 'CTYiHf58UGShfHtpkTwx7vjPDA779dd6iVaeD281fEVx',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27566/thumb/Token_Hunters__CoinMarketCap.png?1664522159',
            'coingeckoId': 'hunter-diamond',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DANG',
            'name': 'DANG',
            'address': 'CSQn7G3SmbBVFRMvNH5SJV5sd2HipWSCphfDVcXwY3K6',
            'decimals': 5,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CSQn7G3SmbBVFRMvNH5SJV5sd2HipWSCphfDVcXwY3K6/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SEI',
            'name': 'Solanium Ecosystem Index',
            'address': 'CRkwd2QedqDi5u6W2w6jeAViAUd1pR4AXs2aKvh7GW7M',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CRkwd2QedqDi5u6W2w6jeAViAUd1pR4AXs2aKvh7GW7M/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'CRWNY',
            'name': 'Crowny',
            'address': 'CRWNYkqdgvhGGae9CKfNka58j6QQkaD5bLhKXvUYqnc1',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CRWNYkqdgvhGGae9CKfNka58j6QQkaD5bLhKXvUYqnc1/logo.png',
            'coingeckoId': 'crowny-token',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'CREAMY',
            'name': 'Creamy',
            'address': 'CREAMpdDimXxj2zTCwP5wMEtba4NYaKCrTBEQTSKtqHe',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CREAMpdDimXxj2zTCwP5wMEtba4NYaKCrTBEQTSKtqHe/logo.png',
            'coingeckoId': 'creamy',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'WVIP',
            'name': 'The WAGMI VIP Club',
            'address': 'CQkTVkohEmyydNPNPH82c6aNPeXE72AatrhZcbntiAfg',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CQkTVkohEmyydNPNPH82c6aNPeXE72AatrhZcbntiAfg/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'CPX',
            'name': 'Circlepod Protocol Token',
            'address': 'CPXDs2uhNwDKAt9V3vXvtspv9U7rsQ2fVr1qAUDmuCaq',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CPXDs2uhNwDKAt9V3vXvtspv9U7rsQ2fVr1qAUDmuCaq/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'CPX',
            'name': 'Circlepod',
            'address': 'CPXDs2uhNwDKAt9V3vXvtspv9U7rsQ2fVr1qA',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20453/thumb/cpx_logo.png?1637228050',
            'coingeckoId': 'circlepod',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOKU',
            'name': 'Boryoku Dragonz',
            'address': 'CN7qFa5iYkHz99PTctvT4xXUHnxwjQ5MHxCuTJtPN5uS',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/BokuBrew.png',
            'coingeckoId': 'boku',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'AGX',
            'name': 'AGX Coin',
            'address': 'CMdr2YEhJbnf82NSPci8PdG1zfViQPGExbbZoy5LJL7v',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/cmdr2yehjbnf82nspci8pdg1zfviqpgexbbzoy5ljl7v/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'acUSD-CASH',
            'name': 'Saber acUSD-CASH LP',
            'address': 'CLPLCvWFycur9CysMT3pmdkUXxPfBjXVkWyxTGntzoZ7',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/clplcvwfycur9cysmt3pmdkuxxpfbjxvkwyxtgntzoz7/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'CLH',
            'name': 'Clash',
            'address': 'CLAsHPfTPpsXmzZzdexdEuKeRzZrWjZFRHQEPu2kSgWM',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27836/thumb/New-Clash-Icon_copy.png?1665996878',
            'coingeckoId': 'clash',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALP',
            'name': 'CoinAlpha',
            'address': 'CKtm7ZMYdKmFSCGukzKjhsp4JFTFGk9uEMGF7XYEFKgK',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/cktm7zmydkmfscgukzkjhsp4jftfgk9uemgf7xyefkgk/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'GARI',
            'name': 'Gari Network',
            'address': 'CKaKtYvz6dKPyMvYq9Rh3UBrnNqYZAyd7iF4hJtjUvks',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CKaKtYvz6dKPyMvYq9Rh3UBrnNqYZAyd7iF4hJtjUvks/logo.png',
            'coingeckoId': 'gari-network',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'GLXY',
            'name': 'Astrals GLXY',
            'address': 'CJ5U6wPmjxFUyTJpUTS7Rt1UqhTmSVRMvmJ8WD4nndXW',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25389/thumb/glxy.png?1651661031',
            'coingeckoId': 'astrals-glxy',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BONER',
            'name': 'BONER',
            'address': 'CJ2K2J3HYU6ibR1JwLkUmD9RM8eytfxtMcLzYPqoQQKo',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://cdn.jsdelivr.net/gh/solana-labs/token-list@main/assets/mainnet/CJ2K2J3HYU6ibR1JwLkUmD9RM8eytfxtMcLzYPqoQQKo/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SOLX',
            'name': 'Soldex',
            'address': 'CH74tuRLTYcxG7qNJCsV9rghfLXJCQJbsu7i52a8F1Gn',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CH74tuRLTYcxG7qNJCsV9rghfLXJCQJbsu7i52a8F1Gn/logo.png',
            'coingeckoId': 'soldex',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'BORG',
            'name': 'Cyborg Apes',
            'address': 'CFbdjaKonbBQTYG2GC6CmB7exofgDYGCDR8tp8KVGS7T',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CFbdjaKonbBQTYG2GC6CmB7exofgDYGCDR8tp8KVGS7T/logo.png',
            'coingeckoId': 'cyborg-apes',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SHIBS',
            'name': 'ShibSol',
            'address': 'CDxwZo3ayxvTmxin7F6o9xg6SjdE4qWEDXV6MZFBevqw',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://discordapp.com/channels/@me/903775821704073247/903777435231846420',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'renBTC',
            'name': 'renBTC',
            'address': 'CDJWUqTcYTVAKXAVXoQZFes5JUFc7owSeq7eMQcDSbo5',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CDJWUqTcYTVAKXAVXoQZFes5JUFc7owSeq7eMQcDSbo5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'afLINK',
            'name': 'Wrapped LINK (Allbridge from Fantom)',
            'address': 'CChDnBH5UdEPGtYfwrFf7Bdmtvc5WnSKtN77oVsPkHBK',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/cchdnbh5udepgtyfwrff7bdmtvc5wnsktn77ovspkhbk/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MILK',
            'name': 'Tiddy Juice Coin',
            'address': 'CCKDRAd4Xwjoovtf2s1duu3d4TPTmFRyh1hfrb3ZUGR2',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://arweave.net/S71DNASA5TYlrK9giRy7QgqFNw1m4mxqY6OdtgY1E7k',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'GUARD',
            'name': 'VANGUARD',
            'address': 'CBV12y1pehFbhdnDpUfgPe88SbUZ5G2s1kLA449Yu3Ad',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CBV12y1pehFbhdnDpUfgPe88SbUZ5G2s1kLA449Yu3Ad/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'KSOL',
            'name': 'KITTYCOIN SOL',
            'address': 'CBPfSGeSf76o3r4628k7BcZ5YBNxHh7hkCzu4AmVgk2Q',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CBPfSGeSf76o3r4628k7BcZ5YBNxHh7hkCzu4AmVgk2Q/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'sCASH-8',
            'name': 'Saber Wrapped Cashio Dollar (8 decimals)',
            'address': 'CASHedBw9NfhsLBXq1WNVfueVznx255j8LLTScto3S6s',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CASHedBw9NfhsLBXq1WNVfueVznx255j8LLTScto3S6s/icon.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'CASH',
            'name': 'Cashio Dollar',
            'address': 'CASHVDm2wsJXfhj6VWxb7GiMdoLc17Du7paH4bNr5woT',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CASHVDm2wsJXfhj6VWxb7GiMdoLc17Du7paH4bNr5woT/icon.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'CAPY',
            'name': 'Capybara',
            'address': 'CAPYD6Lrm7bTZ6C7t7JvSxvpEcfKQ9YNB7kUjh6p6XBN',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/CAPYD6Lrm7bTZ6C7t7JvSxvpEcfKQ9YNB7kUjh6p6XBN/logo.png',
            'coingeckoId': 'capybara',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': '$CANS',
            'name': 'Homeless Coin',
            'address': 'CANSqmUfQ9jfVXJP1ei7eeTw5odbdnHkFGq6GARq8ad8',
            'decimals': 2,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/cansqmufq9jfvxjp1ei7eetw5odbdnhkfgq6garq8ad8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'sCASH-9',
            'name': 'Saber Wrapped CASH (9 decimals)',
            'address': 'C9xqJe3gMTUDKidZsZ6jJ7tL9zSLimDUKVpgUbLZnNbi',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C9xqJe3gMTUDKidZsZ6jJ7tL9zSLimDUKVpgUbLZnNbi/icon.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'C98',
            'name': 'Coin98',
            'address': 'C98A4nkJXhpVZNAZdHUA95RpTF3T4whtQubL3YobiUX9',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://coin98.s3.ap-southeast-1.amazonaws.com/Coin/c98-512.svg',
            'coingeckoId': 'coin98',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': '$PDSGT',
            'name': '-PDS- Golden Token',
            'address': 'C7MnWhwB4JyA21bo6wnomQA5jJtd31WoaiCSKbVTHq5n',
            'decimals': 0,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/c7mnwhwb4jya21bo6wnomqa5jjtd31woaicskbvthq5n/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'LSTAR',
            'name': 'Learning Star',
            'address': 'C6qep3y7tCZUJYDXHiwuK46Gt6FsoxLi8qV1bTCRYaY1',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25986/thumb/20581.png?1655155804',
            'coingeckoId': 'learning-star',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PAXG',
            'name': 'Paxos Gold  Wormhole ',
            'address': 'C6oFsE8nXRDThzrMEQ5SxaNFGKoyyfWDDVPw37JKvPTe',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/C6oFsE8nXRDThzrMEQ5SxaNFGKoyyfWDDVPw37JKvPTe/logo.png',
            'coingeckoId': 'paxos-gold-wormhole',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'T1NY',
            'name': 'Tiny Bonez',
            'address': 'C5xtJBKm24WTt3JiXrvguv7vHCe7CknDB7PNabp4eYX6',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24426/thumb/coin.png?1647848074',
            'coingeckoId': 'tiny-bonez',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ThugMonkey',
            'name': 'Thug Monkey',
            'address': 'BzY2yoAPi3tD5xqVqEzrSPu5CSv9Vk7V2fsjJAQLqLv8',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BzY2yoAPi3tD5xqVqEzrSPu5CSv9Vk7V2fsjJAQLqLv8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'WMP',
            'name': 'Whalemap',
            'address': 'BygDd5LURoqztD3xETc99WCxLUbTi6WYSht9XiBgZ4HW',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/ssi91/crypto/main/logo.svg',
            'coingeckoId': 'whalemap',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'wHUSD_v1',
            'name': 'HUSD Stablecoin (Wormhole v1)',
            'address': 'BybpSTBoZHsmKnfxYG47GDhVPKrnEKX31CScShbrzUhX',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BybpSTBoZHsmKnfxYG47GDhVPKrnEKX31CScShbrzUhX/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'POM',
            'name': 'Pom Token',
            'address': 'ByJ8a9NWk6G4Jg4iFyFNdrya1iVcusL1aL9aGXWXeoVG',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ByJ8a9NWk6G4Jg4iFyFNdrya1iVcusL1aL9aGXWXeoVG/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'PGNT',
            'name': 'Pigeon Sol',
            'address': 'BxHJqGtC629c55swCqWXFGA2rRF1igbbTmh22H8ePUWG',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BxHJqGtC629c55swCqWXFGA2rRF1igbbTmh22H8ePUWG/logo.png',
            'coingeckoId': 'pigeon-sol',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'TOX',
            'name': 'trollbox',
            'address': 'Bx4ykEMurwPQBAFNvthGj73fMBVTvHa8e9cbAyaK4ZSh',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bx4ykEMurwPQBAFNvthGj73fMBVTvHa8e9cbAyaK4ZSh/logo.png',
            'coingeckoId': 'trollbox',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SOLC',
            'name': 'Solcubator',
            'address': 'Bx1fDtvTN6NvE4kjdPHQXtmGSg582bZx9fGy4DQNMmAT',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bx1fDtvTN6NvE4kjdPHQXtmGSg582bZx9fGy4DQNMmAT/logo.png',
            'coingeckoId': 'solcubator',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'XENO',
            'name': 'The Xenobots Project',
            'address': 'Bwfe7DwmEDvjEBZGbQnDU8CrwZsuvYaed1VuQ8KDTGsS',
            'decimals': 0,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24054/thumb/W3OlCRoK_400x400.jpg?1646201237',
            'coingeckoId': 'the-xenobots-project',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'srenBTC-10',
            'name': 'Saber Wrapped renBTC (10 decimals)',
            'address': 'BtX7AfzEJLnU8KQR1AgHrhGH5s2AHUTbfjhUQP8BhPvi',
            'decimals': 10,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BtX7AfzEJLnU8KQR1AgHrhGH5s2AHUTbfjhUQP8BhPvi/icon.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'GSTONKS',
            'name': 'Gamestonks',
            'address': 'BrwgXmUtNd32dTKdP5teie68EmBnjGq8Wp3MukHehUBY',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BrwgXmUtNd32dTKdP5teie68EmBnjGq8Wp3MukHehUBY/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'HOTTO',
            'name': 'HottoShotto',
            'address': 'Bqd2ujCTEzpKzfjb1FHL7FKrdM6n1rZSnRecJK57EoKz',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bqd2ujCTEzpKzfjb1FHL7FKrdM6n1rZSnRecJK57EoKz/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ECHO',
            'name': 'EchoDao',
            'address': 'BqRtfrNpvRAW3KW319hvhPoTu76wKU2LTdXJyG9CyDze',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://i.imgur.com/HnnxQND.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ATOM',
            'name': 'Atomfire Coin',
            'address': 'Bp7aKzRUjusE887totYfwLNYXkiPEWKiAE863sTFRKh4',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/bp7akzrujuse887totyfwlnyxkipewkiae863stfrkh4/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BOXCH',
            'name': 'Boxch',
            'address': 'Boxch1343xWQWbahVBPhYHuYLXNHnWYHG6QbuqfNugQ1',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26119/thumb/Group_212.png?1667726216',
            'coingeckoId': 'boxch',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ahBTC',
            'name': 'Wrapped BTC (Allbridge from HECO)',
            'address': 'Bo4ehCeRcRj2wp5tQpjfCJxYFn4KyRacfDzSa4Aj27tH',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Bo4ehCeRcRj2wp5tQpjfCJxYFn4KyRacfDzSa4Aj27tH/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'NOIA',
            'name': 'NOIA',
            'address': 'BnV3XcZUbNsuonNKqkQrZSvCN8tVYTJtDgfUx6DJ9riy',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BnV3XcZUbNsuonNKqkQrZSvCN8tVYTJtDgfUx6DJ9riy/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'aeUSDT',
            'name': 'Wrapped USDT (Allbridge from Ethereum)',
            'address': 'Bn113WT6rbdgwrm12UJtnmNqGqZjY4it2WoUQuQopFVn',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'SOLRC',
            'name': 'SolRaca',
            'address': 'Bjgh4YsLdicr8WArz9ftdSmpWNcQjsZ9KV3w9fkjiLG',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/Solraca/token-list/main/assets/mainnet/Bjgh4YsLdicr8WArz9ftdSmpWNcQjsZ9KV3w9fkjiLG/solraca.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'PUSSY',
            'name': 'Pussy',
            'address': 'BjTUmZjNUUAPKHVdTs8yZsCmecW5isSK4AbuFihXoUwa',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BjTUmZjNUUAPKHVdTs8yZsCmecW5isSK4AbuFihXoUwa/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'afETH',
            'name': 'Wrapped ETH (Allbridge from Fantom)',
            'address': 'BiryxNvVTABRs3pEE4fvVuu4d17aAYEsNmcPnJ8E8WeT',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FeGn77dhg1KXRRFeSwwMiykZnZPw5JXW6naf2aQgZDQf/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'DIO',
            'name': 'Decimated',
            'address': 'BiDB55p4G3n1fGhwKFpxsokBMqgctL4qnZpDH1bVQxMD',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BiDB55p4G3n1fGhwKFpxsokBMqgctL4qnZpDH1bVQxMD/logo.png',
            'coingeckoId': 'decimated',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'ANT',
            'name': 'Ant Coin',
            'address': 'BhxPoUPkVASfT9y4ipJ6t4sN8uEuThz9voKaPrkv2sFQ',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/bhxpoupkvasft9y4ipj6t4sn8ueuthz9vokaprkv2sfq/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BANA',
            'name': 'Shibana',
            'address': 'BhPXDQio8xtNC6k5Bg5fnUVL9kGN8uvRDNwW8MZBu8DL',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BhPXDQio8xtNC6k5Bg5fnUVL9kGN8uvRDNwW8MZBu8DL/Shibana.png',
            'coingeckoId': 'shibana',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'DEP',
            'name': 'DEAPCOIN',
            'address': 'BgwQjVNMWvt2d8CN51CsbniwRWyZ9H9HfHkEsvikeVuZ',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/10970/thumb/DEAPcoin_01.png?1586741677',
            'coingeckoId': 'deapcoin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAPE',
            'name': 'Bored Ape Social Club',
            'address': 'BgeRyFWWGHeVouqfHfcXUxmvfkgekhrXYVqQWf63kpJB',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BgeRyFWWGHeVouqfHfcXUxmvfkgekhrXYVqQWf63kpJB/logo.png',
            'coingeckoId': 'bored-ape-social-club',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'CLN',
            'name': 'Central Loyalty Network',
            'address': 'BfkeTseqgoxUn8gF1fGQC4GoqHMaCfzmQUgKF4nKDFhr',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BfkeTseqgoxUn8gF1fGQC4GoqHMaCfzmQUgKF4nKDFhr/logoo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'BIRD',
            'name': 'SolBird2',
            'address': 'BfbhLmrhtELjfFzrtcxpB1GoTpmiVK8qcpSYf7AM914h',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'https://lizardtoken.xyz/gallery/bird2-ts1635029482.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'GAPE',
            'name': 'Gapes on Sol',
            'address': 'BebGokMwTrFp2wRV4Z5CftVq7pvgMbj176VND3vTVSKJ',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://arweave.net/9XlB-rI0RiFtophrUQkw8Rj_H9Gqn3yK0j8NXy7zIyA?ext=png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'prtSOL',
            'name': 'prtSOL (Parrot Staked SOL)',
            'address': 'BdZPG9xWrG3uFrx2KrUW1jT4tZ9VKPDWknYihzoPRJS3',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BdZPG9xWrG3uFrx2KrUW1jT4tZ9VKPDWknYihzoPRJS3/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'xSOL',
            'name': 'Synthetic SOL',
            'address': 'BdUJucPJyjkHxLMv6ipKNUhSeY3DWrVtgxAES1iSBAov',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://www.synthetify.io/icons/xsol.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'AOD',
            'name': 'Apes Of Dragons',
            'address': 'BcZP34sFyzM28VP44V3vP98Lr1VT97CBewkPFmCx3BnG',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/bczp34sfyzm28vp44v3vp98lr1vt97cbewkpfmcx3bng/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BASIS',
            'name': 'basis markets',
            'address': 'Basis9oJw9j8cw53oMV7iqsgo6ihi9ALw4QR31rcjUJa',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Basis9oJw9j8cw53oMV7iqsgo6ihi9ALw4QR31rcjUJa/logo.png',
            'coingeckoId': 'basis-markets',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'NEAR',
            'name': 'NEAR (Allbridge from Near)',
            'address': 'BYPsjxa3YuZESQz1dKuBw1QSFCSpecsm8nCQhY5xbU1Z',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BYPsjxa3YuZESQz1dKuBw1QSFCSpecsm8nCQhY5xbU1Z/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'GUMA',
            'name': 'GUM ARABIC',
            'address': 'BXVR8wqs8ixPMHnuUq65buJQmimwG9WG5pNKKKBRd2S4',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://cdn.jsdelivr.net/gh/AbdallaMalik/GUMA/logo/GUMA.jpg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'abBUSD-USDC',
            'name': 'Saber abBUSD-USDC LP',
            'address': 'BUSDjE9NEQ15aRFTxKFAjUf5vzqBhEgTNbYevWcSB5qp',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/busdje9neq15arftxkfajuf5vzqbhegtnbyevwcsb5qp/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BUD',
            'name': 'BunnyDucky',
            'address': 'BUD1144GGYwmMRFs4Whjfkom5UHqC9a8dZHPVvR2vfPx',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25934/thumb/bdlogo.png?1654758682',
            'coingeckoId': 'bunnyducky',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'cUSDT',
            'name': 'Solend USDT',
            'address': 'BTsbZDV7aCMRJ3VNy9ygV4Q2UeEo9GpR8D6VvmMZzNr8',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BTsbZDV7aCMRJ3VNy9ygV4Q2UeEo9GpR8D6VvmMZzNr8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SHELL',
            'name': 'MetaShells',
            'address': 'BRg8CLYEStYAFQad3CVMCYy1cgeuvUnarAZLV8K8Hyfv',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://cdn.jsdelivr.net/gh/metashells/main/metashellslogo.png',
            'coingeckoId': 'metashells',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'CYS',
            'name': 'Cykura',
            'address': 'BRLsMczKuaR5w9vSubF4j8HwEGGprVAyyVgS4EX7DKEg',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BRLsMczKuaR5w9vSubF4j8HwEGGprVAyyVgS4EX7DKEg/logo.svg',
            'coingeckoId': 'cyclos',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'soUSDT',
            'name': 'Wrapped USDT (Sollet)',
            'address': 'BQcdHdAQW1hczDbBi9hiegXAR7A98Q9jx3X3iBBBDiq4',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BQcdHdAQW1hczDbBi9hiegXAR7A98Q9jx3X3iBBBDiq4/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ASGARD',
            'name': 'Asgard Army DAO Token',
            'address': 'BQTN97PwrQGkbNepQxjvcYfRPYbPNgd5PqoioYwBt4qX',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/bqtn97pwrqgkbnepqxjvcyfrpybpngd5pqoioywbt4qx/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BNTY',
            'name': 'Bounty',
            'address': 'BNTY5DaMP9CZhEtmQfMLHfUwwkXropHuCz4m96YqpqKm',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24975/thumb/bnty.png?1649652061',
            'coingeckoId': 'bounty',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOP',
            'name': 'Boring Protocol',
            'address': 'BLwTnYKqf7u4qjgZrrsKeNs2EzWkMLqVCu6j8iHyrNA3',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/boringprotocol/brand-assets/main/boplogo.png',
            'coingeckoId': 'boring-protocol',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': '918C',
            'name': 'PAC918 Coin',
            'address': 'BLjf2hgEoZNtrKFqbnXYcHnnXB6YecDEh6npUvEBe4uT',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/bljf2hgeozntrkfqbnxychnnxb6yecdeh6npuvebe4ut/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BLT',
            'name': 'Blocto',
            'address': 'BLT1noyNr3GttckEVrtcfC6oyK6yV1DpPgSyXbncMwef',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/18657/thumb/BLT_token.png?1633082645',
            'coingeckoId': 'blocto-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARIES',
            'name': 'SOLARIES Financial Token',
            'address': 'BL6X5awy2TstWE6gJGZMLXwW1Wi3VsdCDWEzzK2cuzrw',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/bl6x5awy2tstwe6gjgzmlxww1wi3vsdcdwezzk2cuzrw/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FBZ',
            'name': 'FakeBiz',
            'address': 'BKydRTNdaMJ8B4zPva3YhwUQcpvAsyZaGJnKA6F44fX7',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BKydRTNdaMJ8B4zPva3YhwUQcpvAsyZaGJnKA6F44fX7/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'HAWK',
            'name': 'Hawksight',
            'address': 'BKipkearSqAUdNKa1WDstvcMjoPsSKBuNyvKDQDDu9WE',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BKipkearSqAUdNKa1WDstvcMjoPsSKBuNyvKDQDDu9WE/logo.png',
            'coingeckoId': 'hawksight',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SDC',
            'name': 'SandDollarClassic',
            'address': 'BKMWPkPS8jXw59ezYwK2ueNTZRF4m8MYHDjh9HwUmkQ7',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BKMWPkPS8jXw59ezYwK2ueNTZRF4m8MYHDjh9HwUmkQ7/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'KissMe',
            'name': 'Kiss Me ',
            'address': 'BKGp1At3yLDK1NE2gfMuwv1QMAHBwnqgSdULsyzjUagA',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BKGp1At3yLDK1NE2gfMuwv1QMAHBwnqgSdULsyzjUagA/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'HONO',
            'name': 'Hono',
            'address': 'BGN9c9JJxMgmm7rUqeLanYwWwo2GbedjUFaXn7tAeuXK',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BGN9c9JJxMgmm7rUqeLanYwWwo2GbedjUFaXn7tAeuXK/hono.png',
            'coingeckoId': 'hono',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'aeFTT',
            'name': 'Wrapped FTT (Allbridge from Ethereum)',
            'address': 'BFsCwfk8VsEbSfLkkgmoKsAPk2N6FMJjeTsuxfGa9VEf',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AGFEad2et2ZJif9jaGpdMixQqvW5i81aBdvKe7PHNfz3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'BTSG',
            'name': 'BitSong',
            'address': 'BDxWSxkMLW1nJ3VggamUKkEKrtCaVqzFxoDApM8HdBks',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BDxWSxkMLW1nJ3VggamUKkEKrtCaVqzFxoDApM8HdBks/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'NOVA',
            'name': 'Nova Finance',
            'address': 'BDrL8huis6S5tpmozaAaT5zhE5A7ZBAB2jMMvpKEeF8A',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BDrL8huis6S5tpmozaAaT5zhE5A7ZBAB2jMMvpKEeF8A/logo.svg',
            'coingeckoId': 'nova-finance',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'NUGGET',
            'name': 'Degen NUGGET',
            'address': 'BBZbqM7RPKzncqaC26gtq6Z4dkm5ksZXib4nJYjk8L3X',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26617/thumb/Nuggets_%28NUGGET%29.png?1659073141',
            'coingeckoId': 'degen-nugget',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'aeFEI',
            'name': 'Wrapped FEI (Allbridge from Ethereum)',
            'address': 'BAexggGFsiLCKr17cSZF12wkHd8BkR1DBhzuSb78WTR2',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BAexggGFsiLCKr17cSZF12wkHd8BkR1DBhzuSb78WTR2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'SBABYDOGE',
            'name': 'SOL Baby Doge',
            'address': 'BABYsocP6cB95xvBDXnjXKX96VBNC37dmNWUtaV9Jk6v',
            'decimals': 2,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21436/thumb/logo_%282%29.png?1639121887',
            'coingeckoId': 'sol-baby-doge',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METASOL',
            'name': 'META SOL',
            'address': 'B8NrYG3ZGbmDS6Xv5PUSdpJmXor9VvtxibvDRKNq3rnc',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B8NrYG3ZGbmDS6Xv5PUSdpJmXor9VvtxibvDRKNq3rnc/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'RAD',
            'name': 'RAD',
            'address': 'B6aJ3TGfme3SMnLSouHXqWXjVFqYyqj7czzhzr8WJFAi',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/NFTBrickLayer/zillaz-assets/main/RAD-logo.png',
            'coingeckoId': 'rad',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'TrumpcoinXSOL',
            'name': 'TRUMPXSOL',
            'address': 'B6NyNs3k2DZm6XYNL5wyC8sWEkEL4S8eekonRaSjjD7B',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B6NyNs3k2DZm6XYNL5wyC8sWEkEL4S8eekonRaSjjD7B/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'pyUSDC',
            'name': 'Port Finance 2022 March Yield USDC',
            'address': 'B64haiHLQoWdrvcJqufRG5dEMms96rDpwuaTjYTihQEo',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FgSsGV8GByPaMERxeQJPvZRZHf7zCBhrdYtztKorJS58/USDC.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'PARTI',
            'name': 'PARTI',
            'address': 'B3Ggjjj3QargPkFTAJiR6BaD8CWKFUaWRXGcDQ1nyeeD',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B3Ggjjj3QargPkFTAJiR6BaD8CWKFUaWRXGcDQ1nyeeD/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'BLOCK',
            'name': 'BlockParty BLOCK',
            'address': 'B1ock8ufjvuEPo4eDhnTHtY1uzk2TLg9zpoLnmMpa3Ht',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B1ock8ufjvuEPo4eDhnTHtY1uzk2TLg9zpoLnmMpa3Ht/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'COBRA',
            'name': 'Cobra Coin',
            'address': 'B1nrnT8LvkxqJFw3A9tWoXCpbLUNKYkn8gW8qYZoTRaN',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/B1nrnT8LvkxqJFw3A9tWoXCpbLUNKYkn8gW8qYZoTRaN/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'TICKET',
            'name': 'Ticket Finance',
            'address': 'AymKzSDznoLT7Vhsb4wSRnCj1gjcG3zkgYFY8fxsHHer',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AymKzSDznoLT7Vhsb4wSRnCj1gjcG3zkgYFY8fxsHHer/logo.png',
            'coingeckoId': 'ticket-finance',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'EDGE',
            'name': 'Lord Edge Elon',
            'address': 'Ax9MbdUbr7cPQhkipXnBh2QNDSzf245Sn4xKfQUDuJGD',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ax9MbdUbr7cPQhkipXnBh2QNDSzf245Sn4xKfQUDuJGD/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'PANDA',
            'name': 'Panda Coin',
            'address': 'Aw8qLRHGhMcKq7rxs5XBNCd9oe3BvoAhpNMVz7AdGmty',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Aw8qLRHGhMcKq7rxs5XBNCd9oe3BvoAhpNMVz7AdGmty/logo.png',
            'coingeckoId': 'panda-coin',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'FLOKIS',
            'name': 'FlokiSol',
            'address': 'AvB7Ffmt3H16bhq7ToXb839ynKzFgJxu2WDHsR1S9Yft',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AvB7Ffmt3H16bhq7ToXb839ynKzFgJxu2WDHsR1S9Yft/Flokisol.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'NXDF',
            'name': 'NeXt DeFi Protocol',
            'address': 'Au6EdrSDubCUc34awy9c6iQAg5GSos9pPBXyZQtyZewV',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Au6EdrSDubCUc34awy9c6iQAg5GSos9pPBXyZQtyZewV/logo.png',
            'coingeckoId': 'next-defi-protocol',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'BGS',
            'name': 'Battle of Guardians Share',
            'address': 'At7RLMbA6ZUjj7riyvFq2j5NHQ19aJabCju2VxLDAqso',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22285/thumb/K3hU77wS_400x400.jpg?1641365642',
            'coingeckoId': 'battle-of-guardians-share',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BRWNDO',
            'name': 'BRAWNDO',
            'address': 'At5j3zhbEj8mfFsSy1MPbjVhrX2uNmRMPEDZiyFcETNX',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/At5j3zhbEj8mfFsSy1MPbjVhrX2uNmRMPEDZiyFcETNX/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SHIBL',
            'name': 'Shibalana Inu',
            'address': 'AsVNhq2nnoUgMWciCvePRyHk7xAv6i4ruV6oRHFWBcwF',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AsVNhq2nnoUgMWciCvePRyHk7xAv6i4ruV6oRHFWBcwF/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'DOGETH',
            'name': 'Doge Thug',
            'address': 'ArhMyF2N8XpaujYUxTTDt9EuaBCaGaccxfwaZmkm9XeF',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ArhMyF2N8XpaujYUxTTDt9EuaBCaGaccxfwaZmkm9XeF/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'RENDOGE',
            'name': 'renDOGE',
            'address': 'ArUkYE2XDKzqy77PRRGjo4wREWwqk6RXTfM9NeqzPvjU',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ArUkYE2XDKzqy77PRRGjo4wREWwqk6RXTfM9NeqzPvjU/logo.png',
            'coingeckoId': 'rendoge',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': '8PI',
            'name': '8teapi',
            'address': 'ApvVgC5vc3yxrDJw933UZX7zB7CAy6JCeeZdqEjdsRsV',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/apvvgc5vc3yxrdjw933uzx7zb7cay6jceezdqejdsrsv/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'OINK',
            'name': 'OINK',
            'address': 'Aojru8bfwZK6sgrx6exNazxASFZUjPpRY59byMrs6izt',
            'decimals': 10,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/dungnc/luckypigsNFT/main/SYMBOL.jpeg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'JUNGLE',
            'name': 'Jungle',
            'address': 'Aogv6j1wWiBAZcqRNN1Y89eozda2ke6rkc4CYy7c4iCi',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Aogv6j1wWiBAZcqRNN1Y89eozda2ke6rkc4CYy7c4iCi/logo.png',
            'coingeckoId': 'jungle',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'ANDY',
            'name': 'Andy token',
            'address': 'AndyTyTHiXSHT3DhKSehsg3BEdAWMHbw9xVeeDS3WZYh',
            'decimals': 0,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/andytythixsht3dhksehsg3bedawmhbw9xveeds3wzyh/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FROG',
            'name': 'FROG',
            'address': 'Amt5wUJREJQC5pX7Z48YSK812xmu4j3sQVupNhtsEuY8',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Amt5wUJREJQC5pX7Z48YSK812xmu4j3sQVupNhtsEuY8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'BOT',
            'name': 'Starbots',
            'address': 'AkhdZGVbJXPuQZ53u2LrimCjkRP6ZyxG1SoM85T98eE1',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AkhdZGVbJXPuQZ53u2LrimCjkRP6ZyxG1SoM85T98eE1/logo.png',
            'coingeckoId': 'starbots',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'AoD',
            'name': 'Apes On Dragons',
            'address': 'AkJF8CkEjmV5vt3uhDD73dNWxfjTKKdCsqeg2XazWEca',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/akjf8ckejmv5vt3uhdd73dnwxfjtkkdcsqeg2xazweca/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ARK',
            'name': 'Sol.Ark',
            'address': 'AhRozpV8CDLJ5z9k8CJWF4P12MVvxdnnU2y2qUhUuNS5',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/ahrozpv8cdlj5z9k8cjwf4p12mvvxdnnu2y2quhuuns5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FIRE',
            'name': 'Solfire Finance',
            'address': 'AfXLBfMZd32pN6QauazHCd7diEWoBgw1GNUALDw3suVZ',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AfXLBfMZd32pN6QauazHCd7diEWoBgw1GNUALDw3suVZ/logo.png',
            'coingeckoId': 'solfire-finance',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'KROOK',
            'name': 'Krook Coin',
            'address': 'AfARcLLqRHsZc4xPWHE9nXZAswZaW294Ff1xcYQbjkLq',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://user-images.githubusercontent.com/1009138/143821720-d9c6f5fd-96d7-424f-9b1f-b185451cbb31.png',
            'coingeckoId': 'krook-coin',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'AGB',
            'name': 'Angry Bird',
            'address': 'Aeiwkg1ep4YYQRomeZrr1J1hfbu7M36N3BwQ7AFJNCEz',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/aeiwkg1ep4yyqromezrr1j1hfbu7m36n3bwq7afjncez/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'APT3',
            'name': 'APT3 Coin',
            'address': 'AcndTryRKudMeYQLmn8cxXRKCrHow8paJUndxS3YmBC8',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/acndtryrkudmeyqlmn8cxxrkcrhow8pajundxs3ymbc8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SINU',
            'name': 'Samo INU',
            'address': 'Ac7GiHwC7vZU2y97GRh9rqCqqnKAAgopYrTAtKccHxUk',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Ac7GiHwC7vZU2y97GRh9rqCqqnKAAgopYrTAtKccHxUk/logo.png',
            'coingeckoId': 'samo-inu',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'aeWETH',
            'name': 'Wrapped ETH (Allbridge from Ethereum)',
            'address': 'AaAEw2VCw1XzgvKB8Rj2DyK2ZVau9fbt2bE8hZFWsMyE',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FeGn77dhg1KXRRFeSwwMiykZnZPw5JXW6naf2aQgZDQf/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'AKT',
            'name': 'Alexey Kulikov Token',
            'address': 'AYb1hhPDFxGADUGKbCfe7qUvyHct3ucqvkmCS65y2HtA',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/ayb1hhpdfxgadugkbcfe7quvyhct3ucqvkmcs65y2hta/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SBFC',
            'name': 'SBF Coin',
            'address': 'AWW5UQfMBnPsTaaxCK7cSEmkj1kbX2zUrqvgKXStjBKx',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AWW5UQfMBnPsTaaxCK7cSEmkj1kbX2zUrqvgKXStjBKx/logo.png',
            'coingeckoId': 'sbf-coin',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'aaUSDT-USDT',
            'name': 'Saber aaUSDT-USDT LP',
            'address': 'AVTrxHq5P57fYZTYjMuCRWFqsrLmom2gGThNtgEgK1ip',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/avtrxhq5p57fyztyjmucrwfqsrlmom2ggthntgegk1ip/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PART',
            'name': 'Particle Technology',
            'address': 'AVKnbqNQgXDY8kbnno9eSGfwpVz5idimBnDKiz1vbWAh',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21302/thumb/logo.png?1638882252',
            'coingeckoId': 'particle-technology',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'aaDAI-USDC',
            'name': 'Saber aaDAI-USDC LP',
            'address': 'AVDuGckLavyLr5YifViaxnoveY6rwqDezHw5kiKiRQEC',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/avdugcklavylr5yifviaxnovey6rwqdezhw5kikirqec/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'aaUSDC-USDC',
            'name': 'Saber aaUSDC-USDC LP',
            'address': 'AVC7uVb6R9B34T8zWxQMEK8twvYk26U71gworsujxFNv',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/avc7uvb6r9b34t8zwxqmek8twvyk26u71gworsujxfnv/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'aaWBTC-renBTC',
            'name': 'Saber aaWBTC-renBTC LP',
            'address': 'AVBDpg1UYpDYQLbzEnRY76R3u82PYHtDuc3NBdFS2k39',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/avbdpg1uypdyqlbzenry76r3u82pyhtduc3nbdfs2k39/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'AVAX',
            'name': 'AVAX (Allbridge from Avalanche)',
            'address': 'AUrMpCDYYcPuHhyNX8gEEqbmDPFUpBpHrNW3vPeCFn5Z',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/12559/small/coin-round-red.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'AUT',
            'name': 'Avenue University Token',
            'address': 'AUT1gfMZw37wMMQqAxk89nfpjZpEEf2XSoBUd8V5ydnS',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28087/thumb/T-R84Zo32rDOA5Wo66T8jAlqcyjD7Folt1oeCfjlizU.png?1667724695',
            'coingeckoId': 'avenue-university-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AURY',
            'name': 'Aurory',
            'address': 'AURYydfxJib1ZkTir1Jn1J9ECYUtjb6rKQVmtYaixWPP',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AURYydfxJib1ZkTir1Jn1J9ECYUtjb6rKQVmtYaixWPP/logo.png',
            'coingeckoId': 'aurory',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'FONE',
            'name': 'Fone',
            'address': 'ATZERmcPfopS9vGqw9kxqRj9Bmdi3Z268nHXkGsMa3Pf',
            'decimals': 2,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23390/thumb/Fl-zRI0g_400x400.jpg?1644115198',
            'coingeckoId': 'fone',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATLAS',
            'name': 'Star Atlas',
            'address': 'ATLASXmbPQxBUYbxPsV97usA3fPQYEqzQBUHgiFCUsXx',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ATLASXmbPQxBUYbxPsV97usA3fPQYEqzQBUHgiFCUsXx/logo.png',
            'coingeckoId': 'star-atlas',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'MEMI',
            'name': 'Metacraft Mineral',
            'address': 'ATEMTyZVC1yDHYUg1aqHC6cpn8KVLe4Bbn7G74xSRDqG',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27214/thumb/Metacraft_Mineral_MEMI_icon_200x200px.png?1662610576',
            'coingeckoId': 'metacraft-mineral',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'aSOL',
            'name': 'aSOL Aggregate Solana Stake Pool',
            'address': 'ASoLXbfe7cd6igh5yiEsU8M7FW64QRxPKkxk7sjAfond',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ASoLXbfe7cd6igh5yiEsU8M7FW64QRxPKkxk7sjAfond/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': '$ASH',
            'name': 'Mortuary Inc. Ashes',
            'address': 'ASHTTPcMddo7RsYHEyTv3nutMWvK8S4wgFUy3seAohja',
            'decimals': 0,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/ashttpcmddo7rsyheytv3nutmwvk8s4wgfuy3seaohja/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ARCADE',
            'name': 'Arcade',
            'address': 'ARcAdExwxKEaTMKFjAWL17YespofK3a7poexuBvAPmrN',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/arcadexwxkeatmkfjawl17yespofk3a7poexubvapmrn/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'soSUSHI',
            'name': 'Wrapped SUSHI (Sollet)',
            'address': 'AR1Mtgh7zAtxuxGd2XPovXPVjcSdY3i4rQYisNadjfKy',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AR1Mtgh7zAtxuxGd2XPovXPVjcSdY3i4rQYisNadjfKy/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'AARD',
            'name': 'Aardvark Coin',
            'address': 'AQcvRhLY9ebLLXkoScWH5QQTvPg1xpnCdngMkqiXww74',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/aqcvrhly9ebllxkoscwh5qqtvpg1xpncdngmkqixww74/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'APESZN_TEE_SHIRT',
            'name': 'APESZN_TEE_SHIRT',
            'address': 'APhyVWtzjdTVYhyta9ngSiCDk2pLi8eEZKsHGSbsmwv6',
            'decimals': 0,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/aphyvwtzjdtvyhyta9ngsicdk2pli8eezkshgsbsmwv6/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'apUSDC-USDC',
            'name': 'Saber apUSDC-USDC LP',
            'address': 'APUVVYA8Xf7T1PqLyDvNxLtwQ9rRDf3RUxfMttreVzHP',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/apuvvya8xf7t1pqlydvnxltwq9rrdf3ruxfmttrevzhp/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'APRT',
            'name': 'Apricot',
            'address': 'APTtJyaRX5yGTsJU522N4VYWg3vCvSb65eam5GrPT5Rt',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/APTtJyaRX5yGTsJU522N4VYWg3vCvSb65eam5GrPT5Rt/logo.svg',
            'coingeckoId': 'apricot',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'MTK',
            'name': 'MetaToken',
            'address': 'ANXqXpSkTEuCnR27YK2AoHNH2CCbiSaKYAKcDQVMi6ar',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28053/thumb/metatoken_icon.png?1667286996',
            'coingeckoId': 'metatoken',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ANA',
            'name': 'Nirvana ANA',
            'address': 'ANAxByE6G2WjFp7A4NqtWYXb3mgruyzZYg3spfxe6Lbo',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25012/thumb/ANA_Logo.png?1649822203',
            'coingeckoId': 'nirvana-ana',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'JUNKz',
            'name': 'JUNK',
            'address': 'AMzmwvDRKdt5AQ3m1m28tWjzBxmQNe1PsmHnYitVZwzp',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/XAGBack/JUNKToken1/main/JunkToken.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'GAMESHIB',
            'name': 'GAME SHIB COIN',
            'address': 'AMzb4Tc7gDGHrsz1zUQzjtmQS2AXWuejveAKXKSpsoPU',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://gameshib.com/images/gameshib420.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ASTRA',
            'name': 'AstraPad',
            'address': 'AMp8Jo18ZjK2tuQGfjKAkkWnVP4NWX5sav4NJH6pXF2D',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20028/thumb/WzDPqfV.png?1636418940',
            'coingeckoId': 'astrapad',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'MOLA',
            'name': 'MoonLana',
            'address': 'AMdnw9H5DFtQwZowVFr4kUgSXJzLokKSinvgGiUoLSps',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AMdnw9H5DFtQwZowVFr4kUgSXJzLokKSinvgGiUoLSps/logo.png',
            'coingeckoId': 'moonlana',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'BITCH',
            'name': 'Bitch Of Solana',
            'address': 'AMNoi4727tzy7adu4wnx3cN2VQbQdG71DqaPoSm7isJ3',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AMNoi4727tzy7adu4wnx3cN2VQbQdG71DqaPoSm7isJ3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'MDF',
            'name': 'MatrixETF DAO Finance',
            'address': 'ALQ9KMWjFmxVbew3vMkJj3ypbAKuorSgGst6svCHEe2z',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALQ9KMWjFmxVbew3vMkJj3ypbAKuorSgGst6svCHEe2z/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'afDAI-USDC',
            'name': 'Saber afDAI-USDC LP',
            'address': 'ALPqczrbEXS8k5JF69tEPyu6TTE8qJbiwrzADfjVfKov',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/alpqczrbexs8k5jf69tepyu6tte8qjbiwrzadfjvfkov/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'afBTC-renBTC',
            'name': 'Saber afBTC-renBTC LP',
            'address': 'ALPoQFUuKsoN6P4gLBiKXMSaYFP9YChniKwvKAFRPvn5',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/alpoqfuukson6p4glbikxmsayfp9ychnikwvkafrpvn5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'abETH-weWETH',
            'name': 'Saber abETH-weWETH LP',
            'address': 'ALPi51sXwH9kNcQuneDwz7kPzJs3hJ5xV6SmnuyqodQP',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/alpi51sxwh9kncqunedwz7kpzjs3hj5xv6smnuyqodqp/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'aeMIM-wtUST',
            'name': 'Saber aeMIM-wtUST LP',
            'address': 'ALPfb7HJd4oenNBknjzCbVc2RooC5N1H6N391hbZ82ky',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/alpfb7hjd4oennbknjzcbvc2rooc5n1h6n391hbz82ky/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'aeMIM-CASH',
            'name': 'Saber aeMIM-CASH LP',
            'address': 'ALPbh25PVwDDEhmJizhrtyhfgXNjh17RtbZc4i5ZAHdh',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/alpbh25pvwddehmjizhrtyhfgxnjh17rtbzc4i5zahdh/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'abBTCB-renBTC',
            'name': 'Saber abBTCB-renBTC LP',
            'address': 'ALPb4SssuKFScyUFnTcXLtF3NAkwFuFpQFric5yo4Qpo',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/alpb4sssukfscyufntcxltf3nakwfufpqfric5yo4qpo/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'aeFEI-CASH',
            'name': 'Saber aeFEI-CASH LP',
            'address': 'ALPaX3bS8zPKsVN6eS7Ln7dvEbDAfDtXsLz5pe2JRddq',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/alpax3bs8zpksvn6es7ln7dvebdafdtxslz5pe2jrddq/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'abUSDT-USDT',
            'name': 'Saber abUSDT-USDT LP',
            'address': 'ALPaPPo6xmeGv3a63Pc4S8NJFAAuchhD7XnkkWJqzvXJ',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/alpappo6xmegv3a63pc4s8njfaauchhd7xnkkwjqzvxj/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'abUSDC-USDC',
            'name': 'Saber abUSDC-USDC LP',
            'address': 'ALPX6x8FkkdQyn9YuoVZjPAapL4nUC7vjJ3AtwStmj9P',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/alpx6x8fkkdqyn9yuovzjpaapl4nuc7vjj3atwstmj9p/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'afETH-weWETH',
            'name': 'Saber afETH-weWETH LP',
            'address': 'ALPGFAuqQsWDB8NSKQ7rgQVhShj4LBCNC72ebdZrJs2e',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/alpgfauqqswdb8nskq7rgqvhshj4lbcnc72ebdzrjs2e/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'aeFEI-wtUST',
            'name': 'Saber aeFEI-wtUST LP',
            'address': 'ALPDpWSYbwNkkuVB3wd1nZx7dZBLV7fEGvbDu9KJxLik',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/alpdpwsybwnkkuvb3wd1nzx7dzblv7fegvbdu9kjxlik/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'afUSDC-USDC',
            'name': 'Saber afUSDC-USDC LP',
            'address': 'ALP8mkba7FHrpn18hGMRURF1aRNS7P2y1SRzqE6ra3Zo',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/alp8mkba7fhrpn18hgmrurf1arns7p2y1srzqe6ra3zo/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'aSOL-SOL',
            'name': 'Saber aSOL-SOL LP',
            'address': 'ALP89a89ASo1h5VosTSABtQBKLBgeoaWQexYQrRCMNfV',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/alp89a89aso1h5vostsabtqbklbgeoawqexyqrrcmnfv/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ALM',
            'name': 'Almond',
            'address': 'ALMmmmbt5KNrPPUBFE4dAKUKSPWTop5s3kUGCdF69gmw',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALMmmmbt5KNrPPUBFE4dAKUKSPWTop5s3kUGCdF69gmw/logo.png',
            'coingeckoId': 'almond',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'NEKI',
            'name': 'Maneki neko',
            'address': 'ALKiRVrfLgzeAV2mCT7cJHKg3ZoPvsCRSV7VCRWnE8zQ',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ALKiRVrfLgzeAV2mCT7cJHKg3ZoPvsCRSV7VCRWnE8zQ/logo.png',
            'coingeckoId': 'maneki-neko',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'PNT',
            'name': 'Phant',
            'address': 'AKxR1NLTtPnsVcWwPSEGat1TC9da3Z2vX7sY4G7ZLj1r',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22799/thumb/logogradientsm.png?1642583142',
            'coingeckoId': 'phant',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'wBUSD_v1',
            'name': 'Binance USD (Wormhole v1)',
            'address': 'AJ1W9A9N9dEMdVyoDiam2rV44gnBm2csrPDP7xqcapgX',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AJ1W9A9N9dEMdVyoDiam2rV44gnBm2csrPDP7xqcapgX/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'AHT',
            'name': 'Avenue Hamilton Token',
            'address': 'AHT1yynTv45s3P3KrRfQCVMHckdHeMVA3fteEg34xt9y',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28086/thumb/7FbZmTSKMsSqtlfe8yH1n3QgdBc3RBBt0B6dsdprVBs.png?1667724654',
            'coingeckoId': 'avenue-hamilton-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ARAB',
            'name': 'ARAB MONEY',
            'address': 'AHGXtqajWiph31bh5y3MfSK4Bo6JGyt3xpveEZ41ZLaX',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/ahgxtqajwiph31bh5y3mfsk4bo6jgyt3xpveez41zlax/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SSU',
            'name': 'SunnySideUp',
            'address': 'AGkFkKgXUEP7ZXazza5a25bSKbz5dDpgafPhqywuQnpf',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AGkFkKgXUEP7ZXazza5a25bSKbz5dDpgafPhqywuQnpf/logo.png',
            'coingeckoId': 'sunnysideup',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'soFTT',
            'name': 'Wrapped FTT (Sollet)',
            'address': 'AGFEad2et2ZJif9jaGpdMixQqvW5i81aBdvKe7PHNfz3',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AGFEad2et2ZJif9jaGpdMixQqvW5i81aBdvKe7PHNfz3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'GST-SOL',
            'name': 'STEPN Green Satoshi Token on Solana',
            'address': 'AFbX8oGjGpmVFywbVouvhQSRmiW2aR1mohfahi4Y2AdB',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AFbX8oGjGpmVFywbVouvhQSRmiW2aR1mohfahi4Y2AdB/logo.png',
            'coingeckoId': 'green-satoshi-token',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'LSTR',
            'name': 'Lstars',
            'address': 'AErxrfertfwzFANXPpgpKkgBPY5kMZzRsafBU3a5mFw6',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AErxrfertfwzFANXPpgpKkgBPY5kMZzRsafBU3a5mFw6/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'sUSDT-9',
            'name': 'Saber Wrapped USDT (9 decimals)',
            'address': 'AEUT5uFm1D575FVCoQd5Yq891FJEqkncZUbBFoFcAhTV',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AEUT5uFm1D575FVCoQd5Yq891FJEqkncZUbBFoFcAhTV/icon.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'aeUSDC-USDC',
            'name': 'Saber aeUSDC-USDC LP',
            'address': 'AECpyKJWfXVyWnk2d9md5dUj3RuzHRKfQra8MakjuVRz',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/aecpykjwfxvywnk2d9md5duj3ruzhrkfqra8makjuvrz/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'REAL',
            'name': 'Realy Metaverse',
            'address': 'AD27ov5fVU2XzwsbvnFvb1JpCBaCB5dRXrczV9CqSVGb',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AD27ov5fVU2XzwsbvnFvb1JpCBaCB5dRXrczV9CqSVGb/logo.svg',
            'coingeckoId': 'realy-metaverse',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'ACM',
            'name': 'Acumen',
            'address': 'ACUMENkbnxQPAsN8XrNA11sY3NmXDNKVCqS82EiDqMYB',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/ACUMENkbnxQPAsN8XrNA11sY3NmXDNKVCqS82EiDqMYB/logo3.svg',
            'coingeckoId': 'acumen',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'apWBTC',
            'name': 'Wrapped WBTC (Allbridge from Polygon)',
            'address': 'AAuuhM6a9TJ6MsoKbSmBGbqBhDQkyuNUgDkH9E9j3Xzm',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/aauuhm6a9tj6msokbsmbgbqbhdqkyunugdkh9e9j3xzm/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SNAP',
            'name': 'SNAPSHOTS',
            'address': 'AAoJ5eYd61QsUBRBxjCcAdsQZpQYxrob2wS4Hzoaeoas',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/ZvsK4/snapshotslogo/main/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'RACEFI',
            'name': 'RaceFi',
            'address': 'AAmGoPDFLG6bE82BgZWjVi8k95tj9Tf3vUN7WvtUm2BU',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21873/thumb/XIF9z8Z6_400x400.jpg?1640209612',
            'coingeckoId': 'racefi',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'DGE',
            'name': 'DarleyGo Essence',
            'address': 'AAXng5czWLNtTXHdWEn9Ef7kXMXEaraHj2JQKo7ZoLux',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/DarleyGo/token-list/main/assets/mainnet/AAXng5czWLNtTXHdWEn9Ef7kXMXEaraHj2JQKo7ZoLux/icon.png',
            'coingeckoId': 'darleygo-essence',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SLX',
            'name': 'Solex Finance',
            'address': 'AASdD9rAefJ4PP7iM89MYUsQEyCQwvBofhceZUGDh5HZ',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solexfin/token-list/main/assets/mainnet/AASdD9rAefJ4PP7iM89MYUsQEyCQwvBofhceZUGDh5HZ/logo.png',
            'coingeckoId': 'solex-finance',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'USDCET',
            'name': 'USD Coin  Wormhole from Ethereum ',
            'address': 'A9mUU4qviSctJVPJdBJWkb28deg915LYJKrzQ19ji3FM',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A9mUU4qviSctJVPJdBJWkb28deg915LYJKrzQ19ji3FM/logo.png',
            'coingeckoId': 'usd-coin-wormhole-from-ethereum',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'DEGN',
            'name': 'Degen',
            'address': 'A9UhP1xfQHWUhSd54NgKPub2XB3ZuQMdPEvf9aMTHxGT',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/flazewang/degencoinsolana/main/degen.png',
            'coingeckoId': 'degen',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'MODUL',
            'name': 'Modul',
            'address': 'A98UDy7z8MfmWnTQt6cKjje7UfqV3pTLf4yEbuwL2HrH',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/parametrise/ParametriseLogo/main/Modul_Logomark_White_Pinky_Background.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'atUST',
            'name': 'Wrapped UST (Allbridge from Terra)',
            'address': 'A96PoNcxa9LMxcF9HhKAfA1p3M1dGbubPMWf19gHAkgJ',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/a96poncxa9lmxcf9hhkafa1p3m1dgbubpmwf19ghakgj/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'TRYB',
            'name': 'BiLira',
            'address': 'A94X2fRy3wydNShU4dRaDyap2UuoeWJGWyATtyp61WZf',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A94X2fRy3wydNShU4dRaDyap2UuoeWJGWyATtyp61WZf/logo.png',
            'coingeckoId': 'bilira',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'ZION',
            'name': 'Zion',
            'address': 'A7rqejP8LKN8syXMr4tvcKjs2iJ4WtZjXNs1e6qP3m9g',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/LousyNFTs/token-list/main/assets/mainnet/A7rqejP8LKN8syXMr4tvcKjs2iJ4WtZjXNs1e6qP3m9g/Ziontoken_2_84.png',
            'coingeckoId': 'zion',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': '$EC',
            'name': 'Elder Coin',
            'address': 'A5jZTDzSxySC1YGGN85XjoyDbxxcjKBAWb5viX8KhivA',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/a5jztdzsxysc1yggn85xjoydbxxcjkbawb5vix8khiva/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SOLID',
            'name': 'Solid Protocol',
            'address': 'A5UevXJdphkzXhRtTXj8JyoYYrWnkCLHVS986JHtRLyj',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A5UevXJdphkzXhRtTXj8JyoYYrWnkCLHVS986JHtRLyj/logo.png',
            'coingeckoId': 'solid-protocol',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'VYNC',
            'name': 'VYNK Chain',
            'address': 'A5NF1e6RnYkVwtg3V3z1qeUz4PZfHCXmQ9RotuJWgi6F',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17743/thumb/vynk_chain.PNG?1629150126',
            'coingeckoId': 'vynk-chain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'NTCK',
            'name': 'NetworkChuck Coin',
            'address': 'A4zyBooAFkpfy7osonRJMQ8a6zArGxN5fNXjXo1ZTZK2',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/theNetworkChuck/crypto/main/NetworkChuckCoin/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': '$SHIVER',
            'name': 'Shibaverse',
            'address': 'A3iozx9T9wgrtybnecQ9rv56y9RF8ThUrwRGWiF7hsmZ',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A3iozx9T9wgrtybnecQ9rv56y9RF8ThUrwRGWiF7hsmZ/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'WOOP',
            'name': 'WOOP',
            'address': 'A3HyGZqe451CBesNqieNPfJ4A9Mu332ui8ni6dobVSLB',
            'decimals': 5,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A3HyGZqe451CBesNqieNPfJ4A9Mu332ui8ni6dobVSLB/logo.png',
            'coingeckoId': 'woop',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'HAMS',
            'name': 'Space Hamster',
            'address': 'A2T2jDe2bxyEHkKtS8AtrTRmJ9VZRwyY8Kr7oQ8xNyfb',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/A2T2jDe2bxyEHkKtS8AtrTRmJ9VZRwyY8Kr7oQ8xNyfb/logo.png',
            'coingeckoId': 'space-hamster',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'AMPL',
            'name': 'AMPOLO Coin',
            'address': 'A2JPQDuPC3f2sv31zxgaXz2HPEEWVTmpPmBPJnMMHLaw',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/a2jpqdupc3f2sv31zxgaxz2hpeewvtmppmbpjnmmhlaw/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PKR2',
            'name': 'PKR2',
            'address': 'A1C9Shy732BThWvHAN936f33N7Wm1HbFvxb2zDSoBx8F',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/C-e-r-b-e-r-u-s/token-list/main/assets/mainnet/A1C9Shy732BThWvHAN936f33N7Wm1HbFvxb2zDSoBx8F/pkr2-logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'STR',
            'name': 'Solster',
            'address': '9zoqdwEBKWEi9G5Ze8BSkdmppxGgVv1Kw4LuigDiNr9m',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9zoqdwEBKWEi9G5Ze8BSkdmppxGgVv1Kw4LuigDiNr9m/logo.png',
            'coingeckoId': 'solster',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'wUSDT_v1',
            'name': 'Tether USD (Wormhole v1)',
            'address': '9w97GdWUYYaamGwdKMKZgGzPduZJkiFizq4rz5CPXRv2',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9w97GdWUYYaamGwdKMKZgGzPduZJkiFizq4rz5CPXRv2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'aeDAI',
            'name': 'Wrapped DAI (Allbridge from Ethereum)',
            'address': '9w6LpS7RU1DKftiwH3NgShtXbkMM1ke9iNU4g3MBXSUs',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FYpdBuyAHSbdaAyD1sKkxyLWbAP8uUW9h6uvdhK74ij1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'ASF',
            'name': 'Armstrong Forever',
            'address': '9vi6PTKBFHR2hXgyjoTZx6h7WXNkFAA5dCsZRSi4higK',
            'decimals': 0,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/9vi6ptkbfhr2hxgyjotzx6h7wxnkfaa5dcszrsi4higk/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'UST',
            'name': 'TerraUSD  Wormhole ',
            'address': '9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9vMJfxuKxXBoEa7rM12mYLMwTacLMLDJqHozw96WQL8i/logo.png',
            'coingeckoId': 'terrausd-wormhole',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': '$ALPHA',
            'name': 'Alpha NFT Token',
            'address': '9ubSPNJoTjbvo1aVArBBXHqoTXxdHKvNGiDnHWmskdv7',
            'decimals': 0,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/9ubspnjotjbvo1avarbbxhqotxxdhkvngidnhwmskdv7/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ARB',
            'name': 'ARB Protocol',
            'address': '9tzZzEHsKnwFL1A3DyFJwj36KnZj3gZ7g4srWp9YTEoh',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26046/thumb/IMG_3600.png?1656916820',
            'coingeckoId': 'arb-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CMPN',
            'name': 'Companion',
            'address': '9tQhCmFtCh56qqf9szLQ8dNjYcd4TTv6MWPpw6MqLubu',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27914/thumb/cmpn.png?1666343810',
            'coingeckoId': 'companion',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GAP',
            'name': 'Solana Gap',
            'address': '9sQtcMxC7zwoVm9vsbZD6XkbZMik5882sA22oc6kb6bU',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9sQtcMxC7zwoVm9vsbZD6XkbZMik5882sA22oc6kb6bU/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ASSS',
            'name': 'Ape Strong',
            'address': '9r6vbKHTY5ZGVhCZMgCDw1MQMeXrRtSgfErgNzXt1q9P',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/9r6vbkhty5zgvhczmgcdw1mqmexrrtsgfergnzxt1q9p/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': '$CULT',
            'name': 'Culture Coin',
            'address': '9qTA3A113oG94ppSpiJTwWCyj44wyNcgPAs5i9d7QQne',
            'decimals': 2,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/9qta3a113og94ppspijtwwcyj44wyncgpas5i9d7qqne/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': '$TBS',
            'name': 'Texaglo Blockchain Service',
            'address': '9pypdozsBG1KVABkQvHTzgEuAXfSj5cQour9cB73SLLg',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/9pypdozsbg1kvabkqvhtzgeuaxfsj5cqour9cb73sllg/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': '$FLY',
            'name': 'FLY',
            'address': '9oCf3dx1PoSP1tnhNS6LBQXzixU1vkzNHvFwY1oFCD8M',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9oCf3dx1PoSP1tnhNS6LBQXzixU1vkzNHvFwY1oFCD8M/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'WOOF',
            'name': 'WOOF',
            'address': '9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9nEqaUcb16sQ3Tn1psbkWqyhPdLmfHWjKGymREjsAgTE/logo.png',
            'coingeckoId': 'woof-token',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SOBTC',
            'name': 'Wrapped Bitcoin  Sollet ',
            'address': '9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9n4nbM75f5Ui33ZbPYXn59EwSgE8CGsHtAeTH5YFeJ9E/logo.png',
            'coingeckoId': 'wrapped-bitcoin-sollet',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'MIMATIC',
            'name': 'MAI',
            'address': '9mWRABuz2x6koTPCWiCPM49WUbcrNqGTHBV9T9k7y1o7',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/0xlaozi/qidao/main/images/mimatic-red.png',
            'coingeckoId': 'mimatic',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'UNIVERSE',
            'name': 'universe',
            'address': '9k27FY1wmxKEyoMGqK4zJMT2Y8dvkiYRGM2ijjLLTrjq',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9k27FY1wmxKEyoMGqK4zJMT2Y8dvkiYRGM2ijjLLTrjq/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'JRDN',
            'name': 'Fraktionalized Triumphant',
            'address': '9jWgVR3Q3QjfmaXNiZ6jht2K43W7sqkn6tZFeoK9B48t',
            'decimals': 3,
            'chainId': -1,
            'logoURI': 'https://www.arweave.net/B1rrktxxGta7w34MkEuFoJQxDzKLPXlYtBcR_1TkQPQ?ext=png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'POT',
            'name': 'Positron',
            'address': '9iz45n44TQUPyoRymdZXEunqvZUksZyhzS6zQ7sLMadj',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23910/thumb/logo.png?1645682226',
            'coingeckoId': 'positron-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WEZ',
            'name': 'WeSleep',
            'address': '9igG1YEgda7M2LjWkCiaKwcwYkFW174VDzrrPvoAT6DJ',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28265/thumb/Logo_WEZ_png.png?1668766709',
            'coingeckoId': 'wesleep',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'USH',
            'name': 'Hedge USD',
            'address': '9iLH8T7zoWhY7sBmj1WK9ENbWdS1nL8n9wAxaeRitTa6',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9iLH8T7zoWhY7sBmj1WK9ENbWdS1nL8n9wAxaeRitTa6/logo.png',
            'coingeckoId': 'hedge-usd',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'ISOL',
            'name': 'Infinity Solana',
            'address': '9gVyinZd945tJddQBP7Lwo97kUpos7zNw6VkBA55VyPd',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9gVyinZd945tJddQBP7Lwo97kUpos7zNw6VkBA55VyPd/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'BNB',
            'name': 'Binance Coin (Portal)',
            'address': '9gP2kCy3wA1ctvYWQk75guqXuHfrEomqydHLtcTCqiLa',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9gP2kCy3wA1ctvYWQk75guqXuHfrEomqydHLtcTCqiLa/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ITSC',
            'name': 'ITS Cash',
            'address': '9fzQfEM5aq1GLugzHMM6prq8tsURN2pxQMjARaWGd2py',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9fzQfEM5aq1GLugzHMM6prq8tsURN2pxQMjARaWGd2py/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'DRW',
            'name': 'Dragon War',
            'address': '9ajPmmLNtwFsHjeU289Y1v9MU6WwoBGVcAnRVyPcj5YY',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9ajPmmLNtwFsHjeU289Y1v9MU6WwoBGVcAnRVyPcj5YY/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ARS',
            'name': 'Artists Coin',
            'address': '9aj5LDmjjwBXk5ijfoyQej3X6waV1rnk7QN4KVqjX8wH',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/9aj5ldmjjwbxk5ijfoyqej3x6wav1rnk7qn4kvqjx8wh/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'WIPE',
            'name': 'WipeMyAss',
            'address': '9ae76zqD3cgzR9gvf5Thc2NN3ACF7rqqnrLqxNzgcre6',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://cdn.jsdelivr.net/gh/rxrxrxrx/WipeMyAss/wipemyass.jpg',
            'coingeckoId': 'wipemyass',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SLDR',
            'name': 'Solderland',
            'address': '9ZLBKPCzkvDv85hojKofsogsESkJMN164QCVUtxvBxEQ',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25284/thumb/logo.jpg?1651128638',
            'coingeckoId': 'solderland',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SOLUM',
            'name': 'Solum',
            'address': '9XtRZwKzDXEJ61A7qCqbPz8jXMYHGT3LwxqrEzB6fqxv',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9XtRZwKzDXEJ61A7qCqbPz8jXMYHGT3LwxqrEzB6fqxv/logo.png',
            'coingeckoId': 'solum',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SPORE',
            'name': 'Flower View',
            'address': '9XYbEGVjBK2BWvtvjoJBZtnoHtkkdGiw321tdN6eLa4A',
            'decimals': 3,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9XYbEGVjBK2BWvtvjoJBZtnoHtkkdGiw321tdN6eLa4A/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'WEEB',
            'name': 'Weeb Finance Token',
            'address': '9VgfFUFkGGrRePvpKLPkp9DR3crRepf6CJsYU3UmudtY',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9VgfFUFkGGrRePvpKLPkp9DR3crRepf6CJsYU3UmudtY/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ASI',
            'name': 'ASICOIN',
            'address': '9VSTThdFzN53eUqMdz5EmfXPjmTf2LHE3aSdX8DqpkyQ',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/9vstthdfzn53euqmdz5emfxpjmtf2lhe3asdx8dqpkyq/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SHIBMOON',
            'name': 'Shib Moon',
            'address': '9VH6kTELjTFd1RunKZJsCvtzAVLTTsb44kQzWywixLbX',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9VH6kTELjTFd1RunKZJsCvtzAVLTTsb44kQzWywixLbX/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'MIMO',
            'name': 'Million Monke',
            'address': '9TE7ebz1dsFo1uQ2T4oYAKSm39Y6fWuHrd6Uk6XaiD16',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9TE7ebz1dsFo1uQ2T4oYAKSm39Y6fWuHrd6Uk6XaiD16/logo.png',
            'coingeckoId': 'million-monke',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'soFRONT',
            'name': 'Wrapped FRONT (Sollet)',
            'address': '9S4t2NEAiJVMvPdRYKVrfJpBafPBLtvbvyS3DecojQHw',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9S4t2NEAiJVMvPdRYKVrfJpBafPBLtvbvyS3DecojQHw/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'MOUNT',
            'name': 'MetaMounts',
            'address': '9QXAu7FTf7hmswBQwKxvuqGgWH42FyQjqXJanUt6y4eC',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://arweave.net/fy9pbbRbYRg-Y40B34ojIGrb3wxpadQgsiktan1LfKY?ext=png',
            'coingeckoId': 'metamounts',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'TRYB',
            'name': 'BiLira (Portal)',
            'address': '9QBTKuSCDaJjtxYnYcVzoiKENMdJ5DRei5ZUCEeWyZnj',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9QBTKuSCDaJjtxYnYcVzoiKENMdJ5DRei5ZUCEeWyZnj/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ALTBEAR',
            'name': 'ALTBEAR',
            'address': '9Mu1KmjBKTUWgpDoeTJ5oD7XFQmEiZxzspEd3TZGkavx',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/9mu1kmjbktuwgpdoetj5od7xfqmeizxzsped3tzgkavx/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': '$FORCE',
            'name': 'Force',
            'address': '9MjAmgHXbu5drkNa9XpzfozgsM5Dcq6bSnKZzdNrwscC',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9MjAmgHXbu5drkNa9XpzfozgsM5Dcq6bSnKZzdNrwscC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'ACMN',
            'name': 'ACUMEN',
            'address': '9MhNoxy1PbmEazjPo9kiZPCcG7BiFbhi3bWZXZgacfpp',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/9mhnoxy1pbmeazjpo9kizpccg7bifbhi3bwzxzgacfpp/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'AUDIO',
            'name': 'Audius  Wormhole ',
            'address': '9LzCMqDgTKYz9Drzqnpgee3SGa89up3a247ypMj2xrqM',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9LzCMqDgTKYz9Drzqnpgee3SGa89up3a247ypMj2xrqM/logo.png',
            'coingeckoId': 'audius-wormhole',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SGP',
            'name': 'Stacc Gold Points',
            'address': '9KYMTqKY7f2cJKW2wYfNRpLb9zbB1tTyEbaTuzy4Gwwc',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/dyor-market/token-list/main/assets/mainnet/9KYMTqKY7f2cJKW2wYfNRpLb9zbB1tTyEbaTuzy4Gwwc/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'OPPA',
            'name': 'OPPA',
            'address': '9K4uNquZjVSBBN6fBsp62gtYLropyAxAbdZC7D9XErih',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9K4uNquZjVSBBN6fBsp62gtYLropyAxAbdZC7D9XErih/logo.png',
            'coingeckoId': 'oppa',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'ALPS',
            'name': 'Alpstake Ownership',
            'address': '9Jog79BFVaD2eiR4evZvXDqvdphd62etqwqCPEM325uq',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/9jog79bfvad2eir4evzvxdqvdphd62etqwqcpem325uq/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'soSWAG',
            'name': 'Wrapped SWAG (Sollet)',
            'address': '9F9fNTT6qwjsu4X4yWYKZpsbw5qT7o6yR2i57JF2jagy',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9F9fNTT6qwjsu4X4yWYKZpsbw5qT7o6yR2i57JF2jagy/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'pSOL',
            'name': 'pSOL (Parrot SOL)',
            'address': '9EaLkQrbjmbbuZG9Wdpo8qfNUEjHATJFSycEmw6f1rGX',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/9EaLkQrbjmbbuZG9Wdpo8qfNUEjHATJFSycEmw6f1rGX/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'CTI',
            'name': 'ClinTex CTi',
            'address': '9ET2QCQJdFkeKkuaampNbmicbA8eLYauFCWch9Ddh9p5',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13266/thumb/CTI.png?1606817542',
            'coingeckoId': 'clintex-cti',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'MEW',
            'name': 'Solcatcoin',
            'address': '9BiqBycZWkWH21vYqCbu2bL1PjZbR5GxWGA8LQkShbyt',
            'decimals': 0,
            'chainId': -1,
            'logoURI': 'https://cdn.jsdelivr.net/gh/solana-labs/token-list@main/assets/mainnet/9BiqBycZWkWH21vYqCbu2bL1PjZbR5GxWGA8LQkShbyt/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'APHD',
            'name': 'Applehead',
            'address': '9BYwEQ3aEDmnXaF8t4mPLCfRCiGzBBVPWcZAL6KYJxRx',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/9byweq3aedmnxaf8t4mplcfrcigzbbvpwczal6kyjxrx/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ADJ',
            'name': 'Andrew Jackson Coin',
            'address': '99M5AUeqcfiaY6gc5SCbCSToygpRV98PuGBJRiUzNTJ2',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/99m5aueqcfiay6gc5scbcstoygprv98pugbjriuzntj2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'cUSDC',
            'name': 'Solend USDC',
            'address': '993dVFL2uXWYeoXuEBFXR4BijeXdTv4s6BzsCjJZuwqk',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/993dVFL2uXWYeoXuEBFXR4BijeXdTv4s6BzsCjJZuwqk/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'LADA',
            'name': 'LadderCaster',
            'address': '95bzgMCtKw2dwaWufV9iZyu64DQo1eqw6QWnFMUSnsuF',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25108/thumb/Logo_Small_Resized.png?1650533759',
            'coingeckoId': 'laddercaster',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SMRT',
            'name': 'Solminter',
            'address': '95KN8q3qubEVjPf9trgyur2nHx8T5RCmztRbLuQ5E5i',
            'decimals': 0,
            'chainId': -1,
            'logoURI': 'https://cdn.jsdelivr.net/gh/solana-labs/token-list@main/assets/mainnet/95KN8q3qubEVjPf9trgyur2nHx8T5RCmztRbLuQ5E5i/logo.png',
            'coingeckoId': 'solminter',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SYX',
            'name': 'Solanyx',
            'address': '94jMUy411XNUw1CnkFr2514fq6KRc49W3kAmrjJiuZLx',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/94jMUy411XNUw1CnkFr2514fq6KRc49W3kAmrjJiuZLx/logo.png',
            'coingeckoId': 'solanyx',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SHIMOO',
            'name': 'Shibi Samo Official',
            'address': '939KAUTAyNdmpahj1vQmbS67D7auhyJnkMt4sv2tzBwU',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/939KAUTAyNdmpahj1vQmbS67D7auhyJnkMt4sv2tzBwU/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'KSW',
            'name': 'KillSwitch',
            'address': '8ymjMjitLchSFU9zkcbjsJENhSXou4YKh7RD2U3yvqdJ',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20215/thumb/logo_%2824%29.png?1636670633',
            'coingeckoId': 'killswitch',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SDOGE',
            'name': 'SolDoge',
            'address': '8ymi88q5DtmdNTn2sPRNFkvMkszMHuLJ1e3RVdWjPa3s',
            'decimals': 0,
            'chainId': -1,
            'logoURI': 'https://cdn.jsdelivr.net/gh/solana-labs/token-list@main/assets/mainnet/8ymi88q5DtmdNTn2sPRNFkvMkszMHuLJ1e3RVdWjPa3s/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'renLUNA',
            'name': 'renLUNA',
            'address': '8wv2KAykQstNAj2oW6AHANGBiFKVFhvMiyyzzjhkmGvE',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8wv2KAykQstNAj2oW6AHANGBiFKVFhvMiyyzzjhkmGvE/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'GRAPE',
            'name': 'Grape Protocol',
            'address': '8upjSpvjcdpuzhfR1zriwg5NXkwDruejqNE9WNbPRtyA',
            'decimals': 2,
            'chainId': -1,
            'logoURI': 'https://lh3.googleusercontent.com/y7Wsemw9UVBc9dtjtRfVilnS1cgpDt356PPAjne5NvMXIwWz9_x7WKMPH99teyv8vXDmpZinsJdgiFQ16_OAda1dNcsUxlpw9DyMkUk=s0',
            'coingeckoId': 'grape-2',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SFI',
            'name': 'SolanaFi',
            'address': '8udZmv2RrHpU8rPZhphUGhHpmyAqc9UzV4UihpThKvYh',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8udZmv2RrHpU8rPZhphUGhHpmyAqc9UzV4UihpThKvYh/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'PIPANA',
            'name': 'Pipana',
            'address': '8tbAqS4dFNEeC6YGWpNnusc3JcxoFLMiiLPyHctgGYFe',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8tbAqS4dFNEeC6YGWpNnusc3JcxoFLMiiLPyHctgGYFe/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'DGMOON',
            'name': 'DogeMoonxSOL',
            'address': '8sMa1Jfcpt2eSkKDtcd6rurX27gqxkrEvXn5jHt3suGB',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8sMa1Jfcpt2eSkKDtcd6rurX27gqxkrEvXn5jHt3suGB/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'CKC',
            'name': 'ChikinCoin',
            'address': '8s9FCz99Wcr3dHpiauFRi6bLXzshXfcGTfgQE7UEopVx',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8s9FCz99Wcr3dHpiauFRi6bLXzshXfcGTfgQE7UEopVx/logo.svg',
            'coingeckoId': 'chikincoin',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': '420TOKE',
            'name': 'TOKES',
            'address': '8qXGZR71AViyKMZxNesqumG5m6549t2aoQFpxoZa1x87',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/8qxgzr71aviykmzxnesqumg5m6549t2aoqfpxoza1x87/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'USDTbs',
            'name': 'Tether USD (Portal from BSC)',
            'address': '8qJSyQprMC57TWKaYEmetUR3UUiTP2M3hXdcvFhkZdmv',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8qJSyQprMC57TWKaYEmetUR3UUiTP2M3hXdcvFhkZdmv/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'wHBTC_v1',
            'name': 'HBTC (Wormhole v1)',
            'address': '8pBc4v9GAwCBNWPB5XKA93APexMGAS4qMr37vNke9Ref',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8pBc4v9GAwCBNWPB5XKA93APexMGAS4qMr37vNke9Ref/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'OASIS',
            'name': 'Oasis',
            'address': '8oiPhiFrmXS93iC98M4ATev8emQ6XGtf8pz8sntbbqGt',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8oiPhiFrmXS93iC98M4ATev8emQ6XGtf8pz8sntbbqGt/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'VIBE',
            'name': 'VIBE',
            'address': '8o66EVAf4u2Hr21m2tuRrPtEXFPLr8G8aL1ETStP8fDu',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://cdn.jsdelivr.net/gh/solana-labs/token-list@main/assets/mainnet/8o66EVAf4u2Hr21m2tuRrPtEXFPLr8G8aL1ETStP8fDu/VIBE-logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'DXB',
            'name': 'DefiXBet Token',
            'address': '8mgeCL7k2cB2KTN8NhD5biqvcbkfrmBogDpYoHmn5cKQ',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5GG1LbgY4EEvPR51YQPNr65QKcZemrHWPooTqC5gRPBA/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SHARDS',
            'name': 'SolChicks Shards',
            'address': '8j3hXRK5rdoZ2vSpGLRmXtWmW6iYaRUw5xVk4Kzmc9Hp',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23728/thumb/logo_%282%29.png?1645163819',
            'coingeckoId': 'solchicks-shards',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '1Coin',
            'name': '1Coin',
            'address': '8hK6Vq53gwwYmvM2HuEeycGFn6ZDfynccHSuNJhWrTyd',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/8hk6vq53gwwymvm2hueeycgfn6zdfyncchsunjhwrtyd/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PORN',
            'name': 'Pornlana',
            'address': '8gWEnKqB4qVQgC8yAorMxhiEKqsDcxZSVKFVbQ8g1fzB',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/Agaveis/pornlana/main/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'DLN',
            'name': 'Goekdln',
            'address': '8fd5eUPMNHuyKRshFbfmKRAm2gowJ75m8WjT7tLio6J3',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8fd5eUPMNHuyKRshFbfmKRAm2gowJ75m8WjT7tLio6J3/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'GARY',
            'name': 'Gary',
            'address': '8c71AvjQeKKeWRe8jtTGG1bJ2WiYXQdbjqFbUfhHgSVk',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26448/thumb/gary_logo_icon.png?1658111709',
            'coingeckoId': 'gary',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'xETH',
            'name': 'Synthetic ETH',
            'address': '8bqjz8DeSuim1sEAsQatjJN4zseyxSPdhHQcuuhL8PCK',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://www.synthetify.io/icons/xeth.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'AAPL',
            'name': 'Apple Inc.',
            'address': '8bpRdBGPt354VfABL5xugP3pmYZ2tQjzRcqjg2kmwfbF',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/8bprdbgpt354vfabl5xugp3pmyz2tqjzrcqjg2kmwfbf/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': '$ROBO',
            'name': 'ROBO Coin',
            'address': '8ZepSXp47WFyDK21QbvMiiKVWRHnGrAegiwDr71PfGi3',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/CMHayden/crypto/master/robocoin.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'aaUSDC',
            'name': 'Wrapped USDC (Allbridge from Avalanche)',
            'address': '8Yv9Jz4z7BUHP68dz8E8m3tMe6NKgpMUKn8KVqrPA6Fr',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'abUSDC',
            'name': 'Wrapped USDC (Allbridge from BSC)',
            'address': '8XSsNvaKU9FDhYWAv7Yc7qSNwuJSzVrXBNEk7AFiWF69',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/BXXkv6z8ykpG1yuvUDPgh732wzVHB69RnB9YgSYh3itW/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': '42',
            'name': '42',
            'address': '8Wmonq7dhFJXuHqFCcVgWTmmUPCBC4C6J5xbB5HhGb6n',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://cdn.jsdelivr.net/gh/fortytwotoken/42token/forty-two.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'KIWE',
            'name': 'Kiwe Markets',
            'address': '8WftAet8HSHskSp8RUVwdPt6xr3CtF76UF5FPmazY7bt',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25430/thumb/KIWE_200x200.png?1664114180',
            'coingeckoId': 'kiwe-markets',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'YARD',
            'name': 'Solyard Finance',
            'address': '8RYSc3rrS4X4bvBCtSJnhcpPpMaAJkXnVKZPzANxQHgz',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8RYSc3rrS4X4bvBCtSJnhcpPpMaAJkXnVKZPzANxQHgz/logo.png',
            'coingeckoId': 'solyard-finance',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'WSGS',
            'name': 'GameStonk',
            'address': '8Qc1ZtQeR46aq6CEcf16XngA4dASqNHMrejfkmZPXy9z',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8Qc1ZtQeR46aq6CEcf16XngA4dASqNHMrejfkmZPXy9z/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ROPE',
            'name': 'Rope Token',
            'address': '8PMHT4swUMtBzgHnh5U564N5sjPSiUz2cjEQzFnnP1Fo',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8PMHT4swUMtBzgHnh5U564N5sjPSiUz2cjEQzFnnP1Fo/logo.svg',
            'coingeckoId': 'rope-token',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'JOKE',
            'name': 'Jokes Meme',
            'address': '8NGgmXzBzhsXz46pTC3ioSBxeE3w2EXpc741N3EQ8E6r',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8NGgmXzBzhsXz46pTC3ioSBxeE3w2EXpc741N3EQ8E6r/logo.png',
            'coingeckoId': 'jokes-meme',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'wFRAX_v1',
            'name': 'Frax (Wormhole v1)',
            'address': '8L8pDf3jutdpdr4m3np68CL9ZroLActrqwxi6s9Ah5xU',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8L8pDf3jutdpdr4m3np68CL9ZroLActrqwxi6s9Ah5xU/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SOLI',
            'name': 'Solana Ecosystem Index',
            'address': '8JnNWJ46yfdq8sKgT1Lk4G7VWkAA8Rhh7LhqgJ6WY41G',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://cdn.lima.amun.com/solana/8jnnwj46yfdq8skgt1lk4g7vwkaa8rhh7lhqgj6wy41g.png',
            'coingeckoId': 'solana-ecosystem-index',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'BABYTIGER',
            'name': 'BabyTigerGold',
            'address': '8JjBJdV73zPPmZvkgC91ni8RsbXWTkhpuSdxeZgaw6hD',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8JjBJdV73zPPmZvkgC91ni8RsbXWTkhpuSdxeZgaw6hD/logo.png',
            'coingeckoId': 'babytigergold',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'COPE',
            'name': 'Cope',
            'address': '8HGyAAB1yoM1ttS7pXjHMa3dukTFGQggnFFH3hJZgzQh',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8HGyAAB1yoM1ttS7pXjHMa3dukTFGQggnFFH3hJZgzQh/logo.png',
            'coingeckoId': 'cope',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'UNI',
            'name': 'Uniswap  Wormhole ',
            'address': '8FU95xFJhUUkyyCLU13HSzDLs7oC4QZdXQHL6SCeab36',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8FU95xFJhUUkyyCLU13HSzDLs7oC4QZdXQHL6SCeab36/logo.png',
            'coingeckoId': 'uniswap-wormhole',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'sSOL',
            'name': 'SunnySideUp staked SOL (sSOL)',
            'address': '8EDaoeBqpcVACwvkYXh1vAcU29HiBiNhqoF4pRsuUsZS',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8EDaoeBqpcVACwvkYXh1vAcU29HiBiNhqoF4pRsuUsZS/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'APPLE',
            'name': 'Apple Fruit',
            'address': '8E5W9PMhnEvdvM2Q9XBLMJW7UsFiieXnRHPj8zhtB23h',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8E5W9PMhnEvdvM2Q9XBLMJW7UsFiieXnRHPj8zhtB23h/logo.png',
            'coingeckoId': 'apple-fruit',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'ThugShiba',
            'name': 'Thug Shiba',
            'address': '8Au2WcQrgn1oTKfnnaFfb3iMjfdXYp7Y7CrHi8JaNSUZ',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/8Au2WcQrgn1oTKfnnaFfb3iMjfdXYp7Y7CrHi8JaNSUZ/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'GRLC',
            'name': 'Garlic',
            'address': '88YqDBWxYhhwPbExF966EdaCYBKP51xVm1oGBcbWzcf2',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/88YqDBWxYhhwPbExF966EdaCYBKP51xVm1oGBcbWzcf2/logo.png',
            'coingeckoId': 'garlic',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'aeWBTC',
            'name': 'Wrapped BTC (Allbridge from Ethereum)',
            'address': '88GEfTd3JKyQtRE3kYCup73MFe2gqHtZSCgqaUB5zDHV',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/88geftd3jkyqtre3kycup73mfe2gqhtzscgqaub5zdhv/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'sUSDC-8',
            'name': 'Saber Wrapped USD Coin (8 decimals)',
            'address': '88881Hu2jGMfCs9tMu5Rr7Ah7WBNBuXqde4nR5ZmKYYy',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/88881Hu2jGMfCs9tMu5Rr7Ah7WBNBuXqde4nR5ZmKYYy/icon.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ENX',
            'name': 'Equinox',
            'address': '87rSGrpYdmTxfNBf8o2cpyiNcxCmNhUPBXjT8aoyfob5',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25234/thumb/ReKXsCZUnqSChIZZg4dlCIHQTKU0owxPuvj1feBDWaE.png?1650954548',
            'coingeckoId': 'equinox',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'soUBXT',
            'name': 'Wrapped Upbots (Sollet)',
            'address': '873KLxCbz7s9Kc4ZzgYRtNmhfkQrhfyWGZJBmyCbC3ei',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/873KLxCbz7s9Kc4ZzgYRtNmhfkQrhfyWGZJBmyCbC3ei/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': '420',
            'name': 'Legalize420',
            'address': '84A5n7GsQUQXpkbgWBctouRk8hLwwYuq6KfjSQN5oY6Q',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/84a5n7gsquqxpkbgwbctourk8hlwwyuq6kfjsqn5oy6q/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'XUSD',
            'name': 'Synthetic USD',
            'address': '83LGLCm7QKpYZbX8q4W2kYWbtt8NJBwbVwEepzkVnJ9y',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://www.synthetify.io/icons/xusd.svg',
            'coingeckoId': 'synthetic-usd',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'ARDX',
            'name': 'Wrapped ArdCoin (Sollet)',
            'address': '7zsKqN7Fg2s9VsqAq6XBoiShCVohpGshSUvoWBc6jKYh',
            'decimals': 2,
            'chainId': -1,
            'logoURI': 'https://cdn.jsdelivr.net/gh/solana-labs/token-list@main/assets/mainnet/7zsKqN7Fg2s9VsqAq6XBoiShCVohpGshSUvoWBc6jKYh/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'BUNNY',
            'name': 'SolBunny',
            'address': '7zhbkbKpGaUsJW7AD4yyAfGGoy53Xx2H3Ai5BKcwGKHw',
            'decimals': 0,
            'chainId': -1,
            'logoURI': 'https://cdn.jsdelivr.net/gh/solana-labs/token-list@main/assets/mainnet/7zhbkbKpGaUsJW7AD4yyAfGGoy53Xx2H3Ai5BKcwGKHw/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'VI',
            'name': 'Vybit',
            'address': '7zBWymxbZt7PVHQzfi3i85frc1YRiQc23K7bh3gos8ZC',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7zBWymxbZt7PVHQzfi3i85frc1YRiQc23K7bh3gos8ZC/logo.png',
            'coingeckoId': 'vybit',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'LFGO',
            'name': 'Mekka Froggo',
            'address': '7z1eQmEhhM9e1AVCBQc6BzMZWmCZRqHCLJtkDgHxzYnQ',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22244/thumb/LK5s4mx-d5GIPPPmilZLRBtxH0OvOAp02sbgYCbFRtE.png?1641275800',
            'coingeckoId': 'mekka-froggo',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ATWORLD',
            'name': 'ATWORLD',
            'address': '7yyTQNQdi45pRVCkDXB8yLRcPw9KFvd9P2Bw3u22HUj9',
            'decimals': 3,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/7yytqnqdi45prvckdxb8ylrcpw9kfvd9p2bw3u22huj9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'KERMIT',
            'name': 'Kermit Finance',
            'address': '7xzovRepzLvXbbpVZLYKzEBhCNgStEv1xpDqf1rMFFKX',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7xzovRepzLvXbbpVZLYKzEBhCNgStEv1xpDqf1rMFFKX/logo.png',
            'coingeckoId': 'kermit',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'FREN',
            'name': 'SOL Frens',
            'address': '7xd71KP4HwQ4sM936xL8JQZCwE4amUko1AdCCf6Znjrt',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7xd71KP4HwQ4sM936xL8JQZCwE4amUko1AdCCf6Znjrt/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SAMO',
            'name': 'Samoyedcoin',
            'address': '7xKXtg2CW87d97TXJSDpbD5jBkheTqA83TZRuJosgAsU',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7xKXtg2CW87d97TXJSDpbD5jBkheTqA83TZRuJosgAsU/logo.png',
            'coingeckoId': 'samoyedcoin',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': '$UFO',
            'name': 'Solian UFO Token',
            'address': '7wKWd1kXz1wM5epW5hsu4Em3GAByGyDc3wr2AspMWjDG',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/7wkwd1kxz1wm5epw5hsu4em3gabygydc3wr2aspmwjdg/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ETH',
            'name': 'Ethereum  Wormhole ',
            'address': '7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs/logo.png',
            'coingeckoId': 'ethereum-wormhole',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'PAC',
            'name': 'PAC Coin',
            'address': '7vKX5rx57VPE1ozJesFzojdPjGZ3M89894PT27i6seUF',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7vKX5rx57VPE1ozJesFzojdPjGZ3M89894PT27i6seUF/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'DOGExTESLA',
            'name': 'DogeTesla',
            'address': '7v5K9VFiqNTWnmkK4wofVfRzG7f7AGQ7WLXLmP91UibU',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7v5K9VFiqNTWnmkK4wofVfRzG7f7AGQ7WLXLmP91UibU/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'BOLE',
            'name': 'Boleld',
            'address': '7uv3ZvZcQLd95bUp5WMioxG7tyAZVXFfr8JYkwhMYrnt',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7uv3ZvZcQLd95bUp5WMioxG7tyAZVXFfr8JYkwhMYrnt/logo.png',
            'coingeckoId': 'bole-token',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'COBAN',
            'name': 'COBAN',
            'address': '7udMmYXh6cuWVY6qQVCd9b429wDVn2J71r5BdxHkQADY',
            'decimals': 3,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7udMmYXh6cuWVY6qQVCd9b429wDVn2J71r5BdxHkQADY/logo.png',
            'coingeckoId': 'coban',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'FLW',
            'name': 'Flow Coin',
            'address': '7tE99RKS4RwQxEjvZfh4CQMoQMMTRdL5KQbugsfhNYPg',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7tE99RKS4RwQxEjvZfh4CQMoQMMTRdL5KQbugsfhNYPg/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'EDO',
            'name': 'EDO',
            'address': '7sWMHQaJJPn1rkeizq41iPiW5gG7Ry7PXYAVHaUwY4qu',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7sWMHQaJJPn1rkeizq41iPiW5gG7Ry7PXYAVHaUwY4qu/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'GEAR',
            'name': 'Gear',
            'address': '7s6NLX42eURZfpyuKkVLrr9ED9hJE8718cyXFsYKqq5g',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://github.com/ArthurPaivaT/token-list/blob/main/assets/mainnet/7s6NLX42eURZfpyuKkVLrr9ED9hJE8718cyXFsYKqq5g/logo.png?raw=true',
            'coingeckoId': 'gear',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'PTOC',
            'name': 'Pluto Coin',
            'address': '7rYHNNU1Quk56mzxXxGiaTWV6Hb1Dh1QNUyShVEdi2Qp',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://github.com/neptuncoin/pluto-coin/raw/main/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SOLV',
            'name': 'Solview',
            'address': '7q3AdgKuMeDRnjaMQs7ppXjaw4HUxjsdyMrrfiSZraiN',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/Solviewapp/solviewlogo/main/icon.png',
            'coingeckoId': 'solview',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'LEONIDAS',
            'name': 'Leonidas Token',
            'address': '7puG5H5Mc6QpvaXjAVLr6GnL5hhUMnpLcUm8G3mEsgHQ',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7puG5H5Mc6QpvaXjAVLr6GnL5hhUMnpLcUm8G3mEsgHQ/logo.png',
            'coingeckoId': 'leonidas-token',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'THUG',
            'name': 'Fraktionalized THUG 2856',
            'address': '7osS84AkAG2TCrUvrE1wfKwfAqWTCrHnaCsrsyVJd5pY',
            'decimals': 3,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22678/thumb/q4h6GvG6MQfhXXNJTbLILbZY1OIgLqkJNHzNLClHDiw.png?1642406678',
            'coingeckoId': 'fraktionalized-thug-2856',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SBreakpoint',
            'name': 'Solana Breakpoint',
            'address': '7mNihWEjzWv9yCZc8capE4mS8v5Xvp5YH2yQhtZrQV5B',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/tribeland/token-list/main/assets/mainnet/7mNihWEjzWv9yCZc8capE4mS8v5Xvp5YH2yQhtZrQV5B/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'UXD',
            'name': 'UXD Stablecoin',
            'address': '7kbnvuGBxxj8AG9qp8Scn56muWGaRaFqxg1FsRp3PaFT',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7kbnvuGBxxj8AG9qp8Scn56muWGaRaFqxg1FsRp3PaFT/uxd-icon-black.png',
            'coingeckoId': 'uxd-stablecoin',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SOLR',
            'name': 'SolRazr',
            'address': '7j7H7sgsnNDeCngAPjpaCN4aaaru4HS7NAFYSEUyzJ3k',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7j7H7sgsnNDeCngAPjpaCN4aaaru4HS7NAFYSEUyzJ3k/SOLR.png',
            'coingeckoId': 'solrazr',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'GMT',
            'name': 'STEPN',
            'address': '7i5KKsX2weiTkry7jA4ZwSuXGhs5eJBEjY8vVxR4pfRx',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7i5KKsX2weiTkry7jA4ZwSuXGhs5eJBEjY8vVxR4pfRx/logo.png',
            'coingeckoId': 'stepn',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'AMD',
            'name': 'Advanced Micro Devices',
            'address': '7grgNP3tAJh7DRELmotHzC5Efth4e4SoBvgmFYTX9jPB',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/7grgnp3tajh7drelmothzc5efth4e4sobvgmfytx9jpb/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'acEUR',
            'name': 'Wrapped CEUR (Allbridge from Celo)',
            'address': '7g166TuBmnoHKvS2PEkZx6kREZtbfjUxCHGWjCqoDXZv',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7g166TuBmnoHKvS2PEkZx6kREZtbfjUxCHGWjCqoDXZv/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SHBL',
            'name': 'Shoebill Coin',
            'address': '7fCzz6ZDHm4UWC9Se1RPLmiyeuQ6kStxpcAP696EuE1E',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7fCzz6ZDHm4UWC9Se1RPLmiyeuQ6kStxpcAP696EuE1E/logo.png',
            'coingeckoId': 'shoebill-coin',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'abUNI',
            'name': 'Wrapped UNI (Allbridge from BSC)',
            'address': '7eYsZw57mTK3y63R8xv3uehPm3bKTdoyAtAqHUxVxuyP',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/7eyszw57mtk3y63r8xv3uehpm3bktdoyataqhuxvxuyp/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MANA',
            'name': 'Decentraland  Wormhole ',
            'address': '7dgHoN8wBZCc5wbnQ2C47TDnBMAxG4Q5L3KjP67z8kNi',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7dgHoN8wBZCc5wbnQ2C47TDnBMAxG4Q5L3KjP67z8kNi/logo.png',
            'coingeckoId': 'decentraland-wormhole',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'HBTC',
            'name': 'Huobi BTC (Portal)',
            'address': '7dVH61ChzgmN9BwG4PkzwRP8PbYwPJ7ZPNF2vamKT2H8',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7dVH61ChzgmN9BwG4PkzwRP8PbYwPJ7ZPNF2vamKT2H8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'STSOL',
            'name': 'Lido Staked SOL',
            'address': '7dHbWXmci3dT8UFYWYZweBLXgycu7Y3iL6trKn1Y7ARj',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7dHbWXmci3dT8UFYWYZweBLXgycu7Y3iL6trKn1Y7ARj/logo.png',
            'coingeckoId': 'lido-staked-sol',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'LUNY',
            'name': 'Luna Yield',
            'address': '7a4cXVvVT7kF6hS5q5LDqtzWfHfys4a9PoK6pf87RKwf',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7a4cXVvVT7kF6hS5q5LDqtzWfHfys4a9PoK6pf87RKwf/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'BANANA',
            'name': 'Banana Bucks',
            'address': '7YhfUG27m7ceDCBnB48dGy4mAJab2hqi6YKkp9Ho7ybv',
            'decimals': 2,
            'chainId': -1,
            'logoURI': 'https://i.ibb.co/DMQNjc0/bananabucks-thumbnail.jpg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'Aiko',
            'name': 'Aiko',
            'address': '7YBn43cTvko7mfZ9YV1iyAUfHNuMp6aAy4rX7wehXcbU',
            'decimals': 0,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/7ybn43ctvko7mfz9yv1iyaufhnump6aay4rx7wehxcbu/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'HUSD',
            'name': 'HUSD (Portal)',
            'address': '7VQo3HFLNH5QqGtM8eC3XQbPkJUu7nS9LeGWjerRh5Sw',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7VQo3HFLNH5QqGtM8eC3XQbPkJUu7nS9LeGWjerRh5Sw/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'MOON',
            'name': 'MoonSol',
            'address': '7TXxsfjYt8gR1XZh9vZZNRxhA4t2VxtYbsy9JWHRjFhJ',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'https://lizardtoken.xyz/gallery/moon-ts1635027621.jpg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SDO',
            'name': 'TheSolanDAO',
            'address': '7SZUnH7H9KptyJkUhJ5L4Kee5fFAbqVgCHvt7B6wg4Xc',
            'decimals': 5,
            'chainId': -1,
            'logoURI': 'https://thesolandao.com/logohero.png',
            'coingeckoId': 'thesolandao',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'WET',
            'name': 'Weble Ecosystem Token',
            'address': '7R7rZ7SsLDXkYAfJyRCBScLuZwizeMWaTWrwFhSZU2Jq',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7R7rZ7SsLDXkYAfJyRCBScLuZwizeMWaTWrwFhSZU2Jq/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'AKELA',
            'name': 'AKELA',
            'address': '7QTcx3LuVfPSZxVzFJcTmbpQCL35jkfoNSzx4qouX495',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/7qtcx3luvfpszxvzfjctmbpqcl35jkfonszx4qoux495/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'JSOL',
            'name': 'JPool',
            'address': '7Q2afV64in6N6SeZsAAB81TJzwDoD6zpqmHkzi9Dcavn',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7Q2afV64in6N6SeZsAAB81TJzwDoD6zpqmHkzi9Dcavn/logo.svg',
            'coingeckoId': 'jpool',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'GKC',
            'name': 'Ghost Coin',
            'address': '7MirouXpJ1J9wYT3jB9xSp8GKwjx9fJ2hHut5HxWxdLa',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7MirouXpJ1J9wYT3jB9xSp8GKwjx9fJ2hHut5HxWxdLa/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ATCOIN',
            'name': 'ATCOIN',
            'address': '7K5AQHswmRMNCFQjT9y8DyQeDMGDvAEjVRob8sJdGrsN',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/7k5aqhswmrmncfqjt9y8dyqedmgdvaejvrob8sjdgrsn/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'Orbs',
            'name': 'Orbs',
            'address': '7JnHPPJBBKSTJ7iEmsiGSBcPJgbcKw28uCRXtQgimncp',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7JnHPPJBBKSTJ7iEmsiGSBcPJgbcKw28uCRXtQgimncp/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'WEED',
            'name': 'Solana Weed',
            'address': '7JYZmXjHenJxgLUtBxgYsFfoABmWQFA1fW3tHQKUBThV',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7JYZmXjHenJxgLUtBxgYsFfoABmWQFA1fW3tHQKUBThV/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'Synapses',
            'name': 'Synapses',
            'address': '7HGJwFyKC5wPLZ9ctgVSgjBARJ4dZrGCjMRQmzWwe277',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://arweave.net/h5NOMoLgFKGBZGOkipic6XFRBdCi7PKWLf5qtm-urzg?ext=png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ANFT',
            'name': 'AffinityLabs',
            'address': '7Geyz6iiRe8buvunsU6TXndxnpLt9mg6iPxqhn6cr3c6',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/7geyz6iire8buvunsu6txndxnplt9mg6ipxqhn6cr3c6/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'TRAC',
            'name': 'Terea Coin',
            'address': '7Af1biRuBcAQTEU3YkWaGGtQDFoxYmqLMyrBNQKnuogn',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://github.com/neptuncoin/Terea-Coin/raw/main/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'FLB',
            'name': 'Floki Baby',
            'address': '7AAtpqK78qbc7vx6BVWQ1D4PEjoccDbU293oGh74ovzN',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/7AAtpqK78qbc7vx6BVWQ1D4PEjoccDbU293oGh74ovzN/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SLNT',
            'name': 'SLNT',
            'address': '78ZnfsncDVyhE2HVPe5LscUrgKsJpwP3wJDHRF2TuC1v',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/78ZnfsncDVyhE2HVPe5LscUrgKsJpwP3wJDHRF2TuC1v/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'QF',
            'name': 'QuietFire',
            'address': '76ijxiMkj4DX8q9QMtqpzTxFnT4KPmWv47sZf2kKoVwk',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/76ijxiMkj4DX8q9QMtqpzTxFnT4KPmWv47sZf2kKoVwk/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ROLL',
            'name': 'High Roller Hippo Clique',
            'address': '76aYNHbDfHemxSS7vmh6eJGfjodK8m7srCxiYCrKxzY1',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://hrhc.io/images/logo.png',
            'coingeckoId': 'high-roller-hippo-clique',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'GNAR',
            'name': 'GNAR',
            'address': '74YedyBSKbjYzWMhwuBQz3mwsN6vuSSdAfzX9WLZQUtq',
            'decimals': 2,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21534/thumb/hL60Xh2.png?1639400726',
            'coingeckoId': 'gnar-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HIMA',
            'name': 'Himalayan Cat Coin',
            'address': '72hgmvS5zFxaFJfMizq6Gp4gjBqXjTPyX9GDP38krorQ',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/72hgmvS5zFxaFJfMizq6Gp4gjBqXjTPyX9GDP38krorQ/logo.png',
            'coingeckoId': 'himalayan-cat-coin',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'FLOKIS',
            'name': 'Floki Shiba Solana',
            'address': '6zQwyDe541Ys4Q85vkhvHMMRW7ypA4PyHCSF1doWZ4tw',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://i.imgur.com/aBLrIE0.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'QUEST',
            'name': 'QUEST',
            'address': '6ybxMQpMgQhtsTLhvHZqk8uqao7kvoexY6e8JmCTqAB1',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6ybxMQpMgQhtsTLhvHZqk8uqao7kvoexY6e8JmCTqAB1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'CHIH',
            'name': 'ChihuahuaSol',
            'address': '6xtyNYX6Rf4Kp3629X11m1jqUmkV89mf9xQakUtUQfHq',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/20528/thumb/rsz_chihuahua-token.png?1637200907',
            'coingeckoId': 'chihuahuasol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FOSSIL',
            'name': 'Fossil',
            'address': '6xcfmgzPgABAuAfGDhvvLLMfMDur4at7tU7j3NudUviK',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6xcfmgzPgABAuAfGDhvvLLMfMDur4at7tU7j3NudUviK/logo.png',
            'coingeckoId': 'fossil',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'ASH',
            'name': 'Ash Coin',
            'address': '6xUsqjDu9iboUJimq1cv19QU65vfXHWkkKFem7FYHwYm',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/6xusqjdu9iboujimq1cv19qu65vfxhwkkkfem7fyhwym/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': '22MTA',
            'name': 'META 22',
            'address': '6xGpXJre3Lp4yr8y22QdQGzooKaa2PTgksjwGUpMJoJi',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/6xgpxjre3lp4yr8y22qdqgzookaa2ptgksjwgupmjoji/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'TUT',
            'name': 'Turnt Up Tikis',
            'address': '6wShYhqA2gs3HUAZ4MyaPDpKPBWFJUQQUGaCoy2k1Tgz',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6wShYhqA2gs3HUAZ4MyaPDpKPBWFJUQQUGaCoy2k1Tgz/logo.png',
            'coingeckoId': 'turnt-up-tikis',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'Kishu',
            'name': 'Kishu Inu',
            'address': '6wFgUMohoSavTuEneDYcrb9qF3JsYVVXyB8jb3PaXCJ4',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6wFgUMohoSavTuEneDYcrb9qF3JsYVVXyB8jb3PaXCJ4/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'NANA',
            'name': 'Nana',
            'address': '6uZ7MRGGf3FJhzk9TUk3QRMR2fz83WY9BEVBukRvMRVX',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6uZ7MRGGf3FJhzk9TUk3QRMR2fz83WY9BEVBukRvMRVX/logo.png',
            'coingeckoId': 'chimp-fight',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'CRYPT',
            'name': 'Cryptor',
            'address': '6pSK3JkbfFcQvu6TuTsRnG61jKxdbaoRRkp1H6jhxXV3',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6pSK3JkbfFcQvu6TuTsRnG61jKxdbaoRRkp1H6jhxXV3/logo_light.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'WEN',
            'name': 'WEN Token',
            'address': '6o4f6tuvVQTa9PTrHN9pvUeXEPusN6RLgMam1Zc7tYbm',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6o4f6tuvVQTa9PTrHN9pvUeXEPusN6RLgMam1Zc7tYbm/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ABBUSD',
            'name': 'Wrapped BUSD  Allbridge from BSC ',
            'address': '6nuaX3ogrr2CaoAPjtaKHAoBNWok32BMcRozuf32s2QF',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/AJ1W9A9N9dEMdVyoDiam2rV44gnBm2csrPDP7xqcapgX/logo.png',
            'coingeckoId': 'wrapped-busd-allbridge-from-bsc',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'SCRAP',
            'name': 'Scrap',
            'address': '6naWDMGNWwqffJnnXFLBCLaYu1y5U9Rohe5wwJPHvf1p',
            'decimals': 3,
            'chainId': -1,
            'logoURI': 'https://art.pixilart.com/bd1b1275fdc0ac1.png',
            'coingeckoId': 'scrap',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'ELON',
            'name': 'Dogelon Mars  Wormhole ',
            'address': '6nKUU36URHkewHg5GGGAgxs6szkE4VTioGUT5txQqJFU',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23041/thumb/ELON_wh_small.png?1644225332',
            'coingeckoId': 'dogelon-mars-wormhole',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SAIL',
            'name': 'SAIL',
            'address': '6kwTqmdQkJd8qRr9RjSnUX9XJ24RmJRSrU1rsragP97Y',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6kwTqmdQkJd8qRr9RjSnUX9XJ24RmJRSrU1rsragP97Y/logo.png',
            'coingeckoId': 'sail',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'DRA',
            'name': 'Drachma Exchange',
            'address': '6jAHVBYY2B4dU6hGxXpCqFwHdjDL6aiLtTvJKn8fWRo1',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26768/thumb/Drachma_Exchange_%281%29.png?1660038908',
            'coingeckoId': 'drachma-exchange',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BRERO',
            'name': 'Fraktionalized SMB 3394',
            'address': '6j28waP2NyoCBJrrVNHZuEzLDL25DXdNxMFsMNMxYht7',
            'decimals': 3,
            'chainId': -1,
            'logoURI': 'https://arweave.net/2OINffkTFvUwtPFfNKkNzQ7h8m-1UZsqQkr6bXKIH70',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'KSH',
            'name': 'Keeshond',
            'address': '6j14WyX1Ag2pLWvn99euK4xp2VcZD62VeJv2iwCrYmT8',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21353/thumb/lkdYjrVS_400x400.jpg?1638999576',
            'coingeckoId': 'keeshond',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'AKI',
            'name': 'AKIHIGE Token',
            'address': '6dGR9kAt499jzsojDHCvDArKxpTarNbhdSkiS7jeMAib',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/6dgr9kat499jzsojdhcvdarkxptarnbhdskis7jemaib/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SHILL',
            'name': 'Project SEED SHILL',
            'address': '6cVgJUqo4nmvQpbgrDZwyfd6RwWw5bfnCamS3M9N1fd',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6cVgJUqo4nmvQpbgrDZwyfd6RwWw5bfnCamS3M9N1fd/logo.png',
            'coingeckoId': 'shill-token',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'ppUSDC',
            'name': 'Port Finance 2022 March Principal USDC',
            'address': '6cKnRJskSTonD6kZiWt2Fy3NB6ZND6CbgA3vHiZ1kHEU',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/FgSsGV8GByPaMERxeQJPvZRZHf7zCBhrdYtztKorJS58/USDC.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'KINGSHIB',
            'name': 'King Shiba',
            'address': '6cH34XtzNgCDwb7NFbiji1a1N8F3FgmXTrFxvzBZNVui',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6cH34XtzNgCDwb7NFbiji1a1N8F3FgmXTrFxvzBZNVui/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'FANI',
            'name': 'FaniTrade',
            'address': '6c4L5nTH2sBKkfeuP3WhGp6Vq1tE4Suh4ezRp5KSu8Z7',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6c4L5nTH2sBKkfeuP3WhGp6Vq1tE4Suh4ezRp5KSu8Z7/logo.svg',
            'coingeckoId': 'fanitrade',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'AAP',
            'name': 'AstralApes',
            'address': '6c2rt6aYpRKwddwwtE68BS2ok8y4U8VUZdThCjfcX38M',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/6c2rt6ayprkwddwwte68bs2ok8y4u8vuzdthcjfcx38m/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PAWS',
            'name': 'Solana Paws',
            'address': '6bLp99VoqKU1C3Qp6VTNvSoCoc78jMGxPkGSSopq8wHB',
            'decimals': 2,
            'chainId': -1,
            'logoURI': 'https://cdn.jsdelivr.net/gh/solana-labs/token-list@main/assets/mainnet/6bLp99VoqKU1C3Qp6VTNvSoCoc78jMGxPkGSSopq8wHB/logo.png',
            'coingeckoId': 'solana-paws',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'MEKKA',
            'name': 'MekkaFroggo',
            'address': '6YAXGyWb3hhLVQQ3vqg9ZYewXk4Cknnr1raTfDwbf8XG',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6YAXGyWb3hhLVQQ3vqg9ZYewXk4Cknnr1raTfDwbf8XG/logo.png',
            'coingeckoId': 'mekkafroggo',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'DINO',
            'name': 'Dino',
            'address': '6Y7LbYB3tfGBG6CSkyssoxdtHb77AEMTRVXe8JUJRwZ7',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6Y7LbYB3tfGBG6CSkyssoxdtHb77AEMTRVXe8JUJRwZ7/logo.png',
            'coingeckoId': 'dino',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'KITTY',
            'name': 'Kitty Coin Solana',
            'address': '6XWfkyg5mzGtKNftSDgYjyoPyUsLRf2rafj95XSFSFrr',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6XWfkyg5mzGtKNftSDgYjyoPyUsLRf2rafj95XSFSFrr/logo.png',
            'coingeckoId': 'kitty-coin-solana',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'ATG',
            'name': 'AbstergoAI',
            'address': '6X4jtyjKQmNx9zEPjzt1A3hcKEX7fi6BX3ruQ79sLa75',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/6x4jtyjkqmnx9zepjzt1a3hckex7fi6bx3ruq79sla75/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'soAKRO',
            'name': 'Wrapped AKRO (Sollet)',
            'address': '6WNVCuxCGJzNjmMZoKyhZJwvJ5tYpsLyAtagzYASqBoF',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6WNVCuxCGJzNjmMZoKyhZJwvJ5tYpsLyAtagzYASqBoF/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'wDingocoin',
            'name': 'Wrapped Dingocoin',
            'address': '6VYF5jXq6rfq4QRgGMG6co7b1Ev1Lj7KSbHBxfQ9e1L3',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://wrap.dingocoin.org/wDingocoin.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'HAPI',
            'name': 'HAPI',
            'address': '6VNKqgz9hk7zRShTFdg5AnkfKwZUcojzwAkzxSH3bnUm',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6VNKqgz9hk7zRShTFdg5AnkfKwZUcojzwAkzxSH3bnUm/logo.png',
            'coingeckoId': 'hapi',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'OTR',
            'name': 'Otter Finance',
            'address': '6TgvYd7eApfcZ7K5Mur7MaUQ2xT7THB4cLHWuMkQdU5Z',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6TgvYd7eApfcZ7K5Mur7MaUQ2xT7THB4cLHWuMkQdU5Z.png',
            'coingeckoId': 'otter-finance',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'PEACH',
            'name': 'PEACHO TOKEN',
            'address': '6StzwSrFeQEkF2xwADqdoz63RXR8dftf9BZnk91o52rm',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6StzwSrFeQEkF2xwADqdoz63RXR8dftf9BZnk91o52rm/peach.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'GOLEM',
            'name': 'Golem',
            'address': '6PBEGe6YaKmDPw1Ebza823SuvQWQgGZ2NTANBgaKdxHq',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6PBEGe6YaKmDPw1Ebza823SuvQWQgGZ2NTANBgaKdxHq/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'Amc',
            'name': 'Amazing Coin',
            'address': '6MsHo9eB1cyvdNurs36KDQ7DBo5Yh7F8C5AB4hNxZX5B',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/6msho9eb1cyvdnurs36kdq7dbo5yh7f8c5ab4hnxzx5b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ThugPenguin',
            'name': 'Thug Penguin',
            'address': '6MpQesMjehBwJzgDRDsbUXqjHetf1LGE94H7FDzRVL9Y',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6MpQesMjehBwJzgDRDsbUXqjHetf1LGE94H7FDzRVL9Y/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'xLUNA',
            'name': 'Synthetic LUNA',
            'address': '6MeoZEcUMhAB788YXTQN4x7K8MnwSt6RHWsLkuq9GJb2',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://www.synthetify.io/icons/xluna.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'FXS',
            'name': 'Frax Share',
            'address': '6LX8BhMQ4Sy2otmAWj7Y5sKd9YTVVUgfMsBzT6B9W7ct',
            'decimals': 18,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13423/thumb/frax_share.png?1608478989',
            'coingeckoId': 'frax-share',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'RUG',
            'name': 'RugCoin',
            'address': '6Km8PRUQxPmNX6EhmAuu3sFEnCP6uT2Yt42zPFR6VNnD',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6Km8PRUQxPmNX6EhmAuu3sFEnCP6uT2Yt42zPFR6VNnD/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'KITTY',
            'name': 'Kitty Solana',
            'address': '6JdcMdhqgCtcP4U9tieRqmKLhPLxRMLC67QfmdXAJBvZ',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6JdcMdhqgCtcP4U9tieRqmKLhPLxRMLC67QfmdXAJBvZ/logo.png',
            'coingeckoId': 'kitty-solana',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'AMY',
            'name': 'Princess Amy',
            'address': '6J35JYuNeWsr7T3Dvynfv29EWYaTbu7Te4u9usbZ74rU',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/6j35jyunewsr7t3dvynfv29ewyatbu7te4u9usbz74ru/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SBD',
            'name': 'Solana Bird',
            'address': '6HKq7SoHESuDz5ZbjZqgQkhrTiDFTfQdJapavMv7DbFb',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6HKq7SoHESuDz5ZbjZqgQkhrTiDFTfQdJapavMv7DbFb/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'AINU',
            'name': 'Akamaru Inu',
            'address': '6H87YFkp5LHyN3KzDXa5r3QYce7WTcwYJi9SqwH3TXkQ',
            'decimals': 1,
            'chainId': -1,
            'logoURI': 'https://cdn.jsdelivr.net/gh/solana-labs/token-list@main/assets/mainnet/6H87YFkp5LHyN3KzDXa5r3QYce7WTcwYJi9SqwH3TXkQ/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'HOOD',
            'name': 'HoodRatsNFT Token',
            'address': '6GVGRXC5wf7NjnTQxaqEZErtdsHupke7Fiz5pUG5VkkM',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/lito95/cheezV2/main/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'RUN',
            'name': 'Run',
            'address': '6F9XriABHfWhit6zmMUYAQBSy6XK5VF1cHXuW5LDpRtC',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6F9XriABHfWhit6zmMUYAQBSy6XK5VF1cHXuW5LDpRtC/RunGear.png',
            'coingeckoId': 'run',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'ARTE',
            'name': 'ARTE',
            'address': '6Dujewcxn1qCd6rcj448SXQL9YYqTcqZCNQdCn3xJAKS',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/ArtPunks/ARTE-token/main/assets/token_512.png',
            'coingeckoId': 'arte',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'BUXX',
            'name': 'BUXX',
            'address': '6CEH3RdzsubHF94fRuU7DWGNh5XpatXmu6jqJnh7kqfM',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6CEH3RdzsubHF94fRuU7DWGNh5XpatXmu6jqJnh7kqfM/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'DAPE',
            'name': 'Degenerate Ape Academy Floor Index',
            'address': '6AarZpv8KwmPBxBEZdRmd3g1q2tUBaSgTNQ5e621qcZQ',
            'decimals': 2,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27054/thumb/dape.png?1661508642',
            'coingeckoId': 'degenerate-ape-academy-floor-index',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WOOD',
            'name': 'Mindfolk Wood',
            'address': '674PmuiDtgKx3uKuJ1B16f9m5L84eFvNwj3xDMvHcbo7',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22512/thumb/tokenlogo.png?1641966072',
            'coingeckoId': 'mindfolk-wood',
            'listedIn': [
                'coingecko',
                'rubic'
            ]
        },
        {
            'symbol': 'PRGC',
            'name': 'ProtoReality Games',
            'address': '66edZnAPEJSxnAK4SckuupssXpbu5doV57FUcghaqPsY',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://www.protorealitygames.com/images/PRGC_Token.png',
            'coingeckoId': 'protoreality-games',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'ORNC',
            'name': 'Orion Coin',
            'address': '665t3SYTfoVtaRPP7QRbBG3V7ePVtWVKQXYkSaUfxS7u',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://github.com/neptuncoin/orion-coin/raw/main/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'STEPN',
            'name': 'STEPN - Run To Earn',
            'address': '6156vEwBw11hGF6rkr3um5RPNWfBCYBFH7XcbEF47erH',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/6156vEwBw11hGF6rkr3um5RPNWfBCYBFH7XcbEF47erH/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'RICE',
            'name': 'Rice',
            'address': '5yw793FZPCaPcuUN4F61VJh2ehsFX87zvHbCA4oRebfn',
            'decimals': 2,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24010/thumb/rice_200_200.png?1646030160',
            'coingeckoId': 'rice',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'TDX',
            'name': 'Tiddie Token',
            'address': '5yUX1XpjLSTDyNBTQ3N3oYpu6RH4gckqnSS6Ecg79fAL',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://cdn.jsdelivr.net/gh/tiddietoken/Icon2/Screenshot_20211109-102934_WhatsApp.jpg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'DSOL',
            'name': 'DecentSol',
            'address': '5y1YcGVPFy8bEiCJi79kegF9igahmvDe5UrqswFvnpMJ',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'https://gateway.pinata.cloud/ipfs/QmfV1LNrqefadJQ7PzMvrTnio9GzsFLcbwRXAgVQad3ykt/logo.png',
            'coingeckoId': 'decentsol',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'JESUS',
            'name': 'JESUS',
            'address': '5xq71UHmPSZ5s68DkXL8wrBVsWCh4zXgcn4wTWkqFdxa',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5xq71UHmPSZ5s68DkXL8wrBVsWCh4zXgcn4wTWkqFdxa/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'QUID',
            'name': 'Quid',
            'address': '5xnRrqoyoLBixNwjVet6Xb2ZTyBSXhENyUWj4sqzRGrv',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://cdn.jsdelivr.net/gh/quidtoken/main/quidlogo.png',
            'coingeckoId': 'quid-token',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'so1INCH',
            'name': 'Wrapped 1INCH (Sollet)',
            'address': '5wihEYGca7X4gSe97C5mVcqNsfxBzhdTwpv72HKs25US',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5wihEYGca7X4gSe97C5mVcqNsfxBzhdTwpv72HKs25US/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SOLD',
            'name': 'Solanax',
            'address': '5v6tZ1SiAi7G8Qg4rBF1ZdAn4cn6aeQtefewMr1NLy61',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5v6tZ1SiAi7G8Qg4rBF1ZdAn4cn6aeQtefewMr1NLy61/logo.png',
            'coingeckoId': 'solanax',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SCIFI',
            'name': 'SciFi-Verse',
            'address': '5uE8w9yoMMu88NV8wUaZMuxCiufBBoSiJbNDAEGmDx7x',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5uE8w9yoMMu88NV8wUaZMuxCiufBBoSiJbNDAEGmDx7x/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'WAG',
            'name': 'Waggle Network',
            'address': '5tN42n9vMi6ubp67Uy4NnmM5DMZYN8aS8GeB3bEDHr6E',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5tN42n9vMi6ubp67Uy4NnmM5DMZYN8aS8GeB3bEDHr6E/logo.png',
            'coingeckoId': 'waggle-network',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'BMBO',
            'name': 'Bamboo Coin',
            'address': '5sM9xxcBTM9rWza6nEgq2cShA87JjTBx1Cu82LjgmaEg',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/rishkumaria/bamboo/main/bamboo.png',
            'coingeckoId': 'bamboo-coin',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'ENRX',
            'name': 'Enrex',
            'address': '5s4BYUXLuvs9ZcVDTxkTpKhThWFSpaU8GG55q2iySe2N',
            'decimals': 2,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24952/thumb/enrx.png?1649505778',
            'coingeckoId': 'enrex',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SLCAT',
            'name': 'Salad Cat Coin',
            'address': '5rFmh8C6Zj1VfL7ogB6PyVDnQkqsayvEPsEbbkUPX8f',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5rFmh8C6Zj1VfL7ogB6PyVDnQkqsayvEPsEbbkUPX8f/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'CATO',
            'name': 'CATO',
            'address': '5p2zjqCd1WJzAVgcEnjhb9zWDU7b9XVhFhx4usiyN7jB',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5p2zjqCd1WJzAVgcEnjhb9zWDU7b9XVhFhx4usiyN7jB/logo.png',
            'coingeckoId': 'cato',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SCNSOL',
            'name': 'Socean Staked Sol',
            'address': '5oVNBeEEQvYi1cX3ir8Dx5n1P7pdxydbGF2X4TxVusJm',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5oVNBeEEQvYi1cX3ir8Dx5n1P7pdxydbGF2X4TxVusJm/logo.png',
            'coingeckoId': 'socean-staked-sol',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'AAPE',
            'name': 'AAPE',
            'address': '5kjfp2qfRbqCXTQeUYgHNnTLf13eHoKjC5hHynW9DvQE',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5kjfp2qfRbqCXTQeUYgHNnTLf13eHoKjC5hHynW9DvQE/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'INU',
            'name': 'Solana Inu',
            'address': '5jFnsfx36DyGk8uVGrbXnVUMTsBkPXGpx6e69BiGFzko',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5jFnsfx36DyGk8uVGrbXnVUMTsBkPXGpx6e69BiGFzko/logo.png',
            'coingeckoId': 'solana-inu',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'NPTC',
            'name': 'Neptun Coin',
            'address': '5j81MNxc3ru546HtUKq5b3qDg9qmqATZz89MYyKhdwhm',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/neptuncoin/token-list/main/assets/mainnet/5j81MNxc3ru546HtUKq5b3qDg9qmqATZz89MYyKhdwhm/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'CHAD',
            'name': 'ChadTrader Token',
            'address': '5j6BmiZTfHssaWPT23EQYQci3w57VTw7QypKArQZbSZ9',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5j6BmiZTfHssaWPT23EQYQci3w57VTw7QypKArQZbSZ9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'PRS',
            'name': 'Perseus Fintech',
            'address': '5idSc21Ht4FTC7jSNe34d6v5FmY8gonswYHpgC7QZCZW',
            'decimals': 2,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26310/thumb/D39-q9sY_400x400.jpg?1657232052',
            'coingeckoId': 'perseus-fintech',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'cSOL',
            'name': 'Solend SOL',
            'address': '5h6ssFpeDeRbzsEHDbTQNH7nVGgsKrZydxdSTnLm6QdV',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5h6ssFpeDeRbzsEHDbTQNH7nVGgsKrZydxdSTnLm6QdV/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'XTAG',
            'name': 'xHashtag',
            'address': '5gs8nf4wojB5EXgDUWNLwXpknzgV2YWDhveAeBZpVLbp',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5gs8nf4wojB5EXgDUWNLwXpknzgV2YWDhveAeBZpVLbp/logo.png',
            'coingeckoId': 'xhashtag',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'USDTPO',
            'name': 'Tether USD  PoS   Wormhole ',
            'address': '5goWRao6a3yNC4d6UjMdQxonkCMvKBwdpubU3qhfcdf1',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5goWRao6a3yNC4d6UjMdQxonkCMvKBwdpubU3qhfcdf1/logo.png',
            'coingeckoId': 'tether-usd-pos-wormhole',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'AMBR',
            'name': 'AMBROSIA',
            'address': '5fibbme9FE1ttgaMHjKnt529va9Pf9oYEjJLU9F3G3s4',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/5fibbme9fe1ttgamhjknt529va9pf9oyejjlu9f3g3s4/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ACE',
            'name': 'ACE',
            'address': '5fhXkD8tXyDB9rmYZSNJ6LneLr2nMteMpCVxeDDEgXa3',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5fhXkD8tXyDB9rmYZSNJ6LneLr2nMteMpCVxeDDEgXa3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'WHEY',
            'name': 'Shredded Apes Whey',
            'address': '5fTwKZP2AK39LtFN9Ayppu6hdCVKfMGVm79F2EgHCtsi',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://secureservercdn.net/160.153.138.53/cjm.06a.myftpupload.com/wp-content/uploads/2021/12/whey-coin-2048x2048.png',
            'coingeckoId': 'whey-token',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'Armen',
            'name': 'Armenia',
            'address': '5ZXZEPxpC35nBxEiVhs257HMNeTd3htYk7FUvBsYFfcr',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/5zxzepxpc35nbxeivhs257hmnetd3htyk7fuvbsyffcr/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MSP',
            'name': 'MSP',
            'address': '5Z7bWSvcxVeUkroSypFW3Tsw7vPoJUcCxhTFNenLxNoR',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5Z7bWSvcxVeUkroSypFW3Tsw7vPoJUcCxhTFNenLxNoR/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'UPGRADE',
            'name': 'UPGRADE',
            'address': '5X6AuKY8QF2xzYUEYYCxf9t9FXhuG76hHJNAB8qUbKqz',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5X6AuKY8QF2xzYUEYYCxf9t9FXhuG76hHJNAB8qUbKqz/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'CMFI',
            'name': 'Compendium Fi',
            'address': '5Wsd311hY8NXQhkt9cWHwTnqafk7BGEbLu8Py3DSnPAr',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5Wsd311hY8NXQhkt9cWHwTnqafk7BGEbLu8Py3DSnPAr/logo.png',
            'coingeckoId': 'compendium-fi',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'TPC',
            'name': 'TOYPOODLE COIN',
            'address': '5WWRMYPchxgh3VmYGPqoq2kfzCtBLxXB9vFH2TeFeR9m',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5WWRMYPchxgh3VmYGPqoq2kfzCtBLxXB9vFH2TeFeR9m/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SPWN',
            'name': 'Bitspawn',
            'address': '5U9QqCPhqXAJcEv9uyzFJd5zhN93vuPk1aNNkXnUfPnt',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5U9QqCPhqXAJcEv9uyzFJd5zhN93vuPk1aNNkXnUfPnt/logo.png',
            'coingeckoId': 'bitspawn',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'IBVOL',
            'name': 'IBlive',
            'address': '5TY71D29Cyuk9UrsSxLXw2quJBpS7xDDFuFu2K9W7Wf9',
            'decimals': 6,
            'chainId': -1,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'BBY',
            'name': 'BabylonDAO',
            'address': '5SZSVgnQDgKKxtCe3UA3x4T7tcSRNDaL3NmfdEqpuLzo',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5SZSVgnQDgKKxtCe3UA3x4T7tcSRNDaL3NmfdEqpuLzo/logo.jpg',
            'coingeckoId': 'babylondao',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'BUSDbs',
            'name': 'BUSD Token (Portal from BSC)',
            'address': '5RpUwQ8wtdPCZHhu6MERp2RGrpobsbZ6MH5dDHkUjs2',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5RpUwQ8wtdPCZHhu6MERp2RGrpobsbZ6MH5dDHkUjs2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'HDG',
            'name': 'Hedge Protocol',
            'address': '5PmpMzWjraf3kSsGEKtqdUsCoLhptg4yriZ17LKKdBBy',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25482/thumb/hdg.png?1652011201',
            'coingeckoId': 'hedge-protocol',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APEC',
            'name': 'APEC',
            'address': '5MoDJmq3JqsNnottRdot3WHmhmD6h8VRodqsBKVu9LPk',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/5modjmq3jqsnnottrdot3whmhmd6h8vrodqsbkvu9lpk/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DOGO',
            'name': 'DogemonGo',
            'address': '5LSFpvLDkcdV2a3Kiyzmg5YmJsj2XDLySaXvnfP1cgLT',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/17480/thumb/dogemongo.PNG?1627950869',
            'coingeckoId': 'dogemon-go',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ILUMA',
            'name': 'ILUMA COIN',
            'address': '5L87fjh5XZWERN4UGbK62TM1funxFvXSRUGmvbHBGqn1',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://github.com/neptuncoin/iluma/raw/main/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'PIXL',
            'name': 'Pixels so',
            'address': '5L2YboFbHAUpBDDJjvDB5M6pu9CW2FRjyDB2asZyvjtE',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21390/thumb/logo.png?1639036659',
            'coingeckoId': 'pixels-so',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'GU',
            'name': 'Kugle GU',
            'address': '5KV2W2XPdSo97wQWcuAVi6G4PaCoieg4Lhhi61PAMaMJ',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5KV2W2XPdSo97wQWcuAVi6G4PaCoieg4Lhhi61PAMaMJ/logo.png',
            'coingeckoId': 'gu',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'YORK',
            'name': 'Yorkipoo',
            'address': '5K1JtWpdSksVKaL6R2DuLpCDAjzxK6sq2CpXaXDWHVLg',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5K1JtWpdSksVKaL6R2DuLpCDAjzxK6sq2CpXaXDWHVLg/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'APYS',
            'name': 'APYSwap',
            'address': '5JnZ667P3VcjDinkJFysWh2K2KtViy63FZ3oL5YghEhW',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5JnZ667P3VcjDinkJFysWh2K2KtViy63FZ3oL5YghEhW/logo.png',
            'coingeckoId': 'apyswap',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'wID_v1',
            'name': 'Everest ID (Wormhole v1)',
            'address': '5HHv6HAyBtaihyHEapCJvjE6iRbGLRmm3F5EZjz6EzHV',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5HHv6HAyBtaihyHEapCJvjE6iRbGLRmm3F5EZjz6EzHV/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'soCREAM',
            'name': 'Wrapped Cream Finance (Sollet)',
            'address': '5Fu5UUgbjpUvdBveb3a1JTNirL8rXtiYeSMWvKjtUNQv',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5Fu5UUgbjpUvdBveb3a1JTNirL8rXtiYeSMWvKjtUNQv/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'MALL',
            'name': 'MetaMall',
            'address': '5EbpXhW7t8ypBF3Q1X7odFaHjuh7XJfCohXR3VYAW32i',
            'decimals': 3,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/5EbpXhW7t8ypBF3Q1X7odFaHjuh7XJfCohXR3VYAW32i/logo.png',
            'coingeckoId': 'metamall',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'LIZARD',
            'name': 'Lizard',
            'address': '5ENUvV3Ur3o3Fg6LVRfHL4sowidiVTMHHsEFqNJXRz6o',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'http://lizardtoken.xyz/gallery/lizard_logo-ts1634859660.jpg',
            'coingeckoId': 'lizard-token',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'DONKEY',
            'name': 'Donkey Hee Haw',
            'address': '58yYYVT5FoVx2jtvD9xtX4JxE8jogtA5tjMkJudgERMS',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/58yYYVT5FoVx2jtvD9xtX4JxE8jogtA5tjMkJudgERMS/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'CRYY',
            'name': 'Cry Cat Coin',
            'address': '56tNQ29XBrbovm5K5SThuQatjCy92w2wKUaUeQ8WCD9g',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/56tNQ29XBrbovm5K5SThuQatjCy92w2wKUaUeQ8WCD9g/logo.png',
            'coingeckoId': 'cry-coin',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'VOO',
            'name': 'VooVoo',
            'address': '55t1PfJngPgMS4c4HeSHPy54VWfkMEk7XBQhSkdz6Cm6',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/55t1PfJngPgMS4c4HeSHPy54VWfkMEk7XBQhSkdz6Cm6/logo.png',
            'coingeckoId': 'voovoo',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'LGBR',
            'name': 'LGBR - LETS GO BRANDON',
            'address': '53dqN1unCex98QWzLZtk1ssJptEcRwZapTrv8pakcgNB',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'http://gopromoted.com/brandon.jpg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'APEX',
            'name': 'ApeXit Finance',
            'address': '51tMb3zBKDiQhNwGqpgwbavaGH54mk8fXFzxTc1xnasg',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/51tMb3zBKDiQhNwGqpgwbavaGH54mk8fXFzxTc1xnasg/logo.png',
            'coingeckoId': 'apexit-finance',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'LIQ',
            'name': 'LIQ Protocol',
            'address': '4wjPQJ6PrkC4dHhYghwJzGBVP78DkBzA2U3kHoFNBuhj',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4wjPQJ6PrkC4dHhYghwJzGBVP78DkBzA2U3kHoFNBuhj/logo.png',
            'coingeckoId': 'liq-protocol',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'ARDN',
            'name': 'Ariadne Solana',
            'address': '4wfCtMp8KQ7r61V4qH2VtHxFjVjUdsWhgAmZgQi33UkT',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/4wfctmp8kq7r61v4qh2vthxfjvjudswhgamzgqi33ukt/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'HONSHU',
            'name': 'HONSHU',
            'address': '4vXidyArpT8fyQTmfXfMNHitj5ay1Fjcnbw9fJLL2zBa',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4vXidyArpT8fyQTmfXfMNHitj5ay1Fjcnbw9fJLL2zBa/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'WAVES',
            'name': 'Playground Waves Floor Index',
            'address': '4uRn7vxRPWYP4HuAa4UNXwEPLRL8oQ71YByMhr6yBnL4',
            'decimals': 2,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24388/thumb/playgorund_waves.PNG?1647502973',
            'coingeckoId': 'playground-waves-floor-index',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ELU',
            'name': 'Elumia',
            'address': '4tJZhSdGePuMEfZQ3h5LaHjTPsw1iWTRFTojnZcwsAU6',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24433/thumb/me4oOqTM_400x400.png?1647662654',
            'coingeckoId': 'elumia',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'APESZN_HOODIE',
            'name': 'APESZN_HOODIE',
            'address': '4qzEcYvT6TuJME2EMZ5vjaLvQja6R4hKjarA73WQUwt6',
            'decimals': 0,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/4qzecyvt6tujme2emz5vjalvqja6r4hkjara73wquwt6/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SSK',
            'name': 'SolShark',
            'address': '4qw5MNc9oLKS22hiFTK6TNBHCqegDK3qzhMDgawtwnUL',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4qw5MNc9oLKS22hiFTK6TNBHCqegDK3qzhMDgawtwnUL/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SLNK',
            'name': 'Solanka Coin',
            'address': '4qcHQruwW1NcSMxQ6v2eYKGxnGSDHdEZ9i7JvaL1ZADL',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://arweave.net/u0Nbp8wLcvtMsawdnddN4LH5rdxmQtKoul3TN7e2r0Q/solanka.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'N2H4',
            'name': 'Hydrazine',
            'address': '4q5UBXJxE91BZKX548qhU8i5QBWvZdXzS3RZwfTgLQda',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4q5UBXJxE91BZKX548qhU8i5QBWvZdXzS3RZwfTgLQda/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ODOP',
            'name': 'oDOP',
            'address': '4pk3pf9nJDN1im1kNwWJN1ThjE8pCYCTexXYGyFjqKVf',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4pk3pf9nJDN1im1kNwWJN1ThjE8pCYCTexXYGyFjqKVf/logo.png',
            'coingeckoId': 'odop',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'MIX',
            'name': 'MIX',
            'address': '4oZyezadeP4KdskT3oDXWFR6Nsado4rGanaH6p6wNR3P',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4oZyezadeP4KdskT3oDXWFR6Nsado4rGanaH6p6wNR3P/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'WAMO',
            'name': 'WAMO',
            'address': '4nQqJkBx3Dnovc6ueEdkJfFr2zi2fc77834czmoymR1b',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4nQqJkBx3Dnovc6ueEdkJfFr2zi2fc77834czmoymR1b/symbol.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'abBNB',
            'name': 'Wrapped BNB (Allbridge from BSC)',
            'address': '4nLJaxZbHMNXPpg6BSEruyU1vh4sAbRxSkjEkmJVJDKy',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/4nljaxzbhmnxppg6bseruyu1vh4sabrxskjekmjvjdky/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'RAY',
            'name': 'Raydium',
            'address': '4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4k3Dyjzvzp8eMZWUXbBCjEvwSkkk59S5iCNLY3QrkX6R/logo.png',
            'coingeckoId': 'raydium',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'GTON',
            'name': 'Graviton',
            'address': '4hJ6sjwmsvvFag6TKL97yhWiBSDX9BABWoiXgb3EPXxB',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://v1.graviton.one/img/brand/download/graviton-logo/primary-logo-png.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SWARM',
            'name': 'MIM',
            'address': '4dydh8EGNEdTz6grqnGBxpduRg55eLnwNZXoNZJetadu',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4dydh8EGNEdTz6grqnGBxpduRg55eLnwNZXoNZJetadu/logo.png',
            'coingeckoId': 'mim',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SNY',
            'name': 'Synthetify',
            'address': '4dmKkXNHdgYsXqBHCuMikNQWwVomZURhYvkkX5c4pQ7y',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4dmKkXNHdgYsXqBHCuMikNQWwVomZURhYvkkX5c4pQ7y/logo.png',
            'coingeckoId': 'synthetify-token',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'APHA',
            'name': 'Aphria Inc',
            'address': '4cr7NH1BD2PMV38JQp58UaHUxzqhxeSiF7b6q1GCS7Ae',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/4cr7nh1bd2pmv38jqp58uahuxzqhxesif7b6q1gcs7ae/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'APEM',
            'name': 'APEMOON',
            'address': '4ZwWddrPzfgMxyEgQ7kzVrqoqX5D9BQJPwduQUBMmePs',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4ZwWddrPzfgMxyEgQ7kzVrqoqX5D9BQJPwduQUBMmePs/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'CATOMIAOU',
            'name': 'Cato Miaouss',
            'address': '4ZEDNmqoLbzwJVAJZNhRgz31Da8DauDkpSfH9iU2vXA4',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4ZEDNmqoLbzwJVAJZNhRgz31Da8DauDkpSfH9iU2vXA4/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ASL',
            'name': 'ASL Moto Token',
            'address': '4ZEC2PNED2i2H8fxjEzLrnKjUriWjWhaXCH76cTCC6Zd',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/4zec2pned2i2h8fxjezlrnkjuriwjwhaxch76ctcc6zd/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DHAN',
            'name': 'Dhancoin',
            'address': '4Wk4qLfLEXFTJqH9zn2LBqccorX2K2rjV9UwyujjUByW',
            'decimals': 5,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/dhancoinofficial/token-list/main/assets/mainnet/4Wk4qLfLEXFTJqH9zn2LBqccorX2K2rjV9UwyujjUByW/logo.jpg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'AGC',
            'name': 'Automatic Get Coin',
            'address': '4WSv4nmFA8JRKWkV84DHRFWbwaUhaGjNbXibitXQiYGS',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/4wsv4nmfa8jrkwkv84dhrfwbwauhagjnbxibitxqiygs/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'XSB',
            'name': 'Solareum Wallet',
            'address': '4UuGQgkD3rSeoXatXRWwRfRd21G87d5LiCfkVzNNv1Tt',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://solareum.app/icons/XSB-G.png',
            'coingeckoId': 'solareum-wallet',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'ADA',
            'name': 'CARDASOL',
            'address': '4U7hSJxbgDoAcQqL2SZpB3hik225ZuG3L33VyrpZD8BA',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/4u7hsjxbgdoacqql2szpb3hik225zug3l33vyrpzd8ba/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'WSO',
            'name': 'Widi Soul',
            'address': '4TyXexYsJqy9n1vVFVkHn1CUxabP2cht3BSE74PSG1pq',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22832/thumb/wso2_200x200.png?1642668402',
            'coingeckoId': 'widi-soul',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '1SOL',
            'name': '1sol io  Wormhole ',
            'address': '4ThReWAbAVZjNVgs5Ui9Pk3cZ5TYaD9u6Y89fp6EFzoF',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4ThReWAbAVZjNVgs5Ui9Pk3cZ5TYaD9u6Y89fp6EFzoF/logo.png',
            'coingeckoId': '1sol-io-wormhole',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'SCT',
            'name': 'SolClout',
            'address': '4Te4KJgjtnZe4aE2zne8G4NPfrPjCwDmaiEx9rKnyDVZ',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4Te4KJgjtnZe4aE2zne8G4NPfrPjCwDmaiEx9rKnyDVZ/logo.png',
            'coingeckoId': 'solclout',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'OXS',
            'name': 'Oxbull Solana',
            'address': '4TGxgCSJQx2GQk9oHZ8dC5m3JNXTYZHjXumKAW3vLnNx',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4TGxgCSJQx2GQk9oHZ8dC5m3JNXTYZHjXumKAW3vLnNx/logo.png',
            'coingeckoId': 'oxbull-solana',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'CAVE',
            'name': 'CaveWorld',
            'address': '4SZjjNABoqhbd4hnapbvoEPEqT8mnNkfbEoAwALf1V8t',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://ftoblquxiunjey7bu4eevlz2u7kwg5s3yvou7adgge2jbihcu7qq.arweave.net/LNwVwpdFGpJj4acISq86p9VjdlvFXU-AZjE0kKDip-E/?ext=png',
            'coingeckoId': 'cave',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'ANU',
            'name': 'ANUBEAST',
            'address': '4R8DBzZEzjBQzPJe4qqaxXM97am7unGM1ZYfviS6oSFe',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/4r8dbzzezjbqzpje4qqaxxm97am7ungm1zyfvis6osfe/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'AGTE',
            'name': 'Agronomist',
            'address': '4QV4wzDdy7S1EV6y2r9DkmaDsHeoKz6HUvFLVtAsu6dV',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4QV4wzDdy7S1EV6y2r9DkmaDsHeoKz6HUvFLVtAsu6dV/logo.png',
            'coingeckoId': 'agronomist',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'KLB',
            'name': 'Black Label',
            'address': '4NPzwMK2gfgQ6rTv8x4EE1ZvKW6MYyYTSrAZCx7zxyaX',
            'decimals': 0,
            'chainId': -1,
            'logoURI': 'https://cdn.jsdelivr.net/gh/solana-labs/token-list@main/assets/mainnet/4NPzwMK2gfgQ6rTv8x4EE1ZvKW6MYyYTSrAZCx7zxyaX/logo.svg',
            'coingeckoId': 'black-label',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'WAV',
            'name': 'Fractionalized WAVE 999',
            'address': '4NGNdLiQ1KG8GgqZimKku4WCLdXbNw6UQJvqax3fE6CJ',
            'decimals': 2,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22176/thumb/IMG-20220101-021048.jpg?1641176801',
            'coingeckoId': 'fractionalized-wave-999',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '$ASS',
            'name': 'Ass Coin',
            'address': '4JEaBv49a4KdSrMduKZS3PcBCcPmPEmaY3uP7kXv6cj6',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/4jeabv49a4kdsrmdukzs3pcbccpmpemay3up7kxv6cj6/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DYDX',
            'name': 'dYdX  Wormhole ',
            'address': '4Hx6Bj56eGyw8EJrrheM6LBQAvVYRikYCWsALeTrwyRU',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4Hx6Bj56eGyw8EJrrheM6LBQAvVYRikYCWsALeTrwyRU/logo.png',
            'coingeckoId': 'dydx-wormhole',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'FLX',
            'name': 'Fluxx',
            'address': '4D2umdRkmjgsFj4Vf9foJGMkTjNQ41jXaGuAL3xb4dQj',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4D2umdRkmjgsFj4Vf9foJGMkTjNQ41jXaGuAL3xb4dQj/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SWOLE',
            'name': 'Swole Doge',
            'address': '4BzxVoBQzwKoqm1dQc78r42Yby3EzAeZmMiYFdCjeu5Z',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4BzxVoBQzwKoqm1dQc78r42Yby3EzAeZmMiYFdCjeu5Z/logo.png',
            'coingeckoId': 'swole-doge',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'NPC',
            'name': 'Nole NPC',
            'address': '4BPw4jwHWqQCbkD2VWtLFL5PLBRmkHZiievTm1ebiWYJ',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/4BPw4jwHWqQCbkD2VWtLFL5PLBRmkHZiievTm1ebiWYJ/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'PSY',
            'name': 'PSY Coin',
            'address': '49jpm8SpyTwaGaJfUa4AmU28hmW1HoKuqzXkgykysowU',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/charliebakerr/psy-token/main/psycoinlogo.png',
            'coingeckoId': 'psy-coin',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SAND',
            'name': 'The Sandbox  Wormhole ',
            'address': '49c7WuCZkQgc3M4qH8WuEUNXfgwupZf1xqWkDQ7gjRGt',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/49c7WuCZkQgc3M4qH8WuEUNXfgwupZf1xqWkDQ7gjRGt/logo.png',
            'coingeckoId': 'the-sandbox-wormhole',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'DRAW',
            'name': 'Dragon War',
            'address': '48AEwauAHsJibyt3WqjQ6EoHnFBcnyHASfo7vB2eCXPS',
            'decimals': 0,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23100/thumb/logo_%284%29.png?1643184693',
            'coingeckoId': 'dragon-war',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BOFB',
            'name': 'bofb',
            'address': '45wdSjpSqZCk9mkqmq5Nh7beCEqqUJMJcVduwYCip5eq',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/45wdSjpSqZCk9mkqmq5Nh7beCEqqUJMJcVduwYCip5eq/logo.svg',
            'coingeckoId': 'bofb',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SWERVE',
            'name': 'SWERVE Protocol',
            'address': '45ojchnvC3agGNvs86MWBq8N4miiTY6X8ECQzgQNDE4v',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/swervesolana/swerve-assets/main/logo_400x400.jpg',
            'coingeckoId': 'swerve-protocol',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SHIWO',
            'name': 'ShibWolf',
            'address': '43q34gUCKfgBQcJSNq2M4s8uVGuGtfAFQXES1BzW5UBv',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/43q34gUCKfgBQcJSNq2M4s8uVGuGtfAFQXES1BzW5UBv/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'USDK',
            'name': 'USDK (Portal)',
            'address': '43m2ewFV5nDepieFjT9EmAQnc1HRtAF247RBpLGFem5F',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/43m2ewFV5nDepieFjT9EmAQnc1HRtAF247RBpLGFem5F/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'STNK',
            'name': 'Stonks',
            'address': '43VWkd99HjqkhFTZbWBpMpRhjG469nWa7x7uEsgSH7We',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/43VWkd99HjqkhFTZbWBpMpRhjG469nWa7x7uEsgSH7We/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'GMFC',
            'name': 'Gamify Club',
            'address': '42Y3CgJQLnHjdScYMu8VS4TbeZMUNVdBMKYbf7hz7aum',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://pbs.twimg.com/profile_images/1444964749897175046/wdz26CzM_400x400.jpg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'LINU',
            'name': 'LittleInu',
            'address': '41TwwURtuv4k8TuFxp1vfFYP9noMbHXqtscse8xLM26V',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22029/thumb/unknown_%281%29.png?1640657606',
            'coingeckoId': 'littleinu',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'sBull',
            'name': 'SolBull - GoPromotedToken',
            'address': '3xVf2hPbkE5TuZNUPLQXFgFLD4LpvCM45BodbPmnpSSV',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://gopromoted.com/sbull200.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SKEM',
            'name': 'SKEM',
            'address': '3x7UeXDF4imKSKnizK9mYyx1M5bTNzpeALfPeB8S6XT9',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3x7UeXDF4imKSKnizK9mYyx1M5bTNzpeALfPeB8S6XT9/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'FRNT',
            'name': 'Final Frontier',
            'address': '3vHSsV6mgvpa1JVuuDZVB72vYbeUNzW4mBxiBftwzHEA',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3vHSsV6mgvpa1JVuuDZVB72vYbeUNzW4mBxiBftwzHEA/logo.png',
            'coingeckoId': 'final-frontier',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'FLVR',
            'name': 'Flokiverse',
            'address': '3vDfXEw3MZQgjYpLbjoZDYmgVci16CsC6ZDLgUzmcKR2',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://cdn.jsdelivr.net/gh/DaveBane/Flokiverse/Flokiverselogo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'AAVE',
            'name': 'Aave  Wormhole ',
            'address': '3vAs4D1WE6Na4tCgt4BApgFfENbm8WY7q4cSPD1yM4Cg',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3vAs4D1WE6Na4tCgt4BApgFfENbm8WY7q4cSPD1yM4Cg/logo.png',
            'coingeckoId': 'aave-wormhole',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'IP3',
            'name': 'Cripco',
            'address': '3uejHm24sWmniGA5m4j4S1DVuGqzYBR5DJpevND4mivq',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26344/thumb/f55kBYa2_400x400.jpeg?1657586586',
            'coingeckoId': 'cripco',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ALEPH',
            'name': 'Aleph.im (Wormhole)',
            'address': '3ucmisnkcnkpe1pgq5ggpcbv6dxgvuy16tmmue1wpg9x',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/3ucmisnkcnkpe1pgq5ggpcbv6dxgvuy16tmmue1wpg9x/logo.png',
            'coingeckoId': 'aleph-im-wormhole',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'KS',
            'name': 'Kalisten',
            'address': '3swraHsc77KMg1tFvwH3tfYcd8SWr5fcUhtmRxjavG7H',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25486/thumb/kalisten_token.png?1652067781',
            'coingeckoId': 'kalisten',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'PHPC',
            'name': 'Philippine Coin',
            'address': '3qvg2hSA4NHhe73Xv6rUuhFoGM77VBvZrmE4tWSHMQWe',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3qvg2hSA4NHhe73Xv6rUuhFoGM77VBvZrmE4tWSHMQWe/logo.jpg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ANGELZ',
            'name': 'ANGELZ Token',
            'address': '3qtvTtcoAXaoTd8f1dmF8wzw17A1XPphuaDuGiBeVuTA',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/3qtvttcoaxaotd8f1dmf8wzw17a1xpphuadugibevuta/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'abBTCB',
            'name': 'Wrapped BTC (Allbridge from BSC)',
            'address': '3os2M3bX9qta154PRbU9rzaPUYAKAqVpaMMS8u2hoUQu',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/qfnqNqs3nCAHjnyCgLRDbBtq4p2MtHZxw8YjSyYhPoL/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'Otter',
            'name': 'Ottercoin',
            'address': '3oLpKntC8W9AxiFhafRGBeALGuKdimduUXVPo1GQNHuX',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://cdn.jsdelivr.net/gh/ottercoin11/ottercoin/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ANA',
            'name': 'Anahata Meta',
            'address': '3nWodcvMjowsrDa2TB3FG1gaCJ4d2DstDzo4roBdMWKY',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/3nwodcvmjowsrda2tb3fg1gacj4d2dstdzo4robdmwky/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SAMOY',
            'name': 'Samoy Meme Dog',
            'address': '3mXx1bNiB5bhgwznk4eeqM9eoy6DU3CeCkm1LPabeoEh',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3mXx1bNiB5bhgwznk4eeqM9eoy6DU3CeCkm1LPabeoEh/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'WAGMI',
            'name': 'WAGMI On Solana',
            'address': '3m7A2A8HHdqmiDrjAfaddj7Hxd88FrBHA1KSoqjoELtu',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3m7A2A8HHdqmiDrjAfaddj7Hxd88FrBHA1KSoqjoELtu/logo.png',
            'coingeckoId': 'wagmi-on-solana',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'FLOOF',
            'name': 'FLOOF',
            'address': '3jzdrXXKxwkBk82u2eCWASZLCKoZs1LQTg87HBEAmBJw',
            'decimals': 1,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/GreenFailure/Floof/main/OkyT9kpz_400x400.png',
            'coingeckoId': 'floof',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'AIEA',
            'name': 'AI Token',
            'address': '3jasj72YfitGmo3jtHvmCQXFNqAoJoGxLiVnqaDeZdXf',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/3jasj72yfitgmo3jthvmcqxfnqaojogxlivnqadezdxf/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DoggyStyle',
            'name': 'Doggy Style',
            'address': '3in9a9yHtdjDFRjDyGTTpGUwJpT9zZBcyjQ8J7nqqNtq',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3in9a9yHtdjDFRjDyGTTpGUwJpT9zZBcyjQ8J7nqqNtq/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'DCCT',
            'name': 'DocuChain',
            'address': '3fXCWpQaEHEsnHSYAqcxm3QLPGLxYiZzoJbqRY9wWxV2',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24763/thumb/zf3Q-GS4_400x400.jpg?1648814553',
            'coingeckoId': 'docuchain',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'FLOKI',
            'name': 'Floki Viking',
            'address': '3fFHsncY59ue2HPduo1KhbZRWYRd8iek5tj88sPXMgFk',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3fFHsncY59ue2HPduo1KhbZRWYRd8iek5tj88sPXMgFk/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ELONINU',
            'name': 'ElonInu',
            'address': '3edUPhBRqprSVdEPheVsFYb34eYPq4xZrYjN6v9fqUrd',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://i.ibb.co/xDpJjDs/eloninu.jpg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SGG',
            'name': 'SolX Gaming Guild',
            'address': '3eLpKZBgu6pKG2TSpvTfTeeimT294yxV2AEiBKZdY2ai',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22673/thumb/YhhMhwOn_400x400.jpg?1642405643',
            'coingeckoId': 'solx-gaming-guild',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BAIL',
            'name': 'SolPatrol Bail',
            'address': '3e9pHUxa2nvAqso2Kr2KqJxYvZaz9qZLjoLaG77uQwB1',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25787/thumb/hammer.png?1653882632',
            'coingeckoId': 'solpatrol-bail',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AMZN',
            'name': 'Amazon',
            'address': '3bjpzTTK49eP8m1bYxw6HYAFGtzyWjvEyGYcFS4gbRAx',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/3bjpzttk49ep8m1byxw6hyafgtzywjveygycfs4gbrax/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'LIKE',
            'name': 'Only1',
            'address': '3bRTivrVsitbmCTGtqwp7hxXPsybkjn4XLNtPsHqa3zR',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://only1.io/like-token.svg',
            'coingeckoId': 'only1',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'CLASH',
            'name': 'Clash Of Cars',
            'address': '3aAYh35n81F8HPG2QBdE48aYdzGFj2fsLccg91X4AcRc',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/23307/thumb/logo_%286%29.png?1643697035',
            'coingeckoId': 'clash-of-cars',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'METAS',
            'name': 'METASEER',
            'address': '3Ztt53vwGhQGoEp3n1RjSu4CFnGRfqzwo6L8KN8gmXfd',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://metaseer.io/img/home-one/logo256.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'apDAI',
            'name': 'Wrapped DAI (Allbridge from Polygon)',
            'address': '3X2UQQkHhbW1mHtS7BQgt7hUALw8pHS334FMpuoBADPt',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/3x2uqqkhhbw1mhts7bqgt7hualw8phs334fmpuobadpt/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PAW',
            'name': 'CopyCats token',
            'address': '3WV4fTWGvtWNvQb8oVU4t99By8KztDLtExqHnkPfHAA9',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://imagedelivery.net/aAmca-Po70V1nH4n9cT-uA/98c9b68b-1494-43d1-0c2c-31e5d0129a00/public',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'TUTL',
            'name': 'TurtleTraders',
            'address': '3VhB8EAL8dZ457SiksLPpMUR1pyACpbNh5rTjQUEVCcH',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3VhB8EAL8dZ457SiksLPpMUR1pyACpbNh5rTjQUEVCcH/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'PTR',
            'name': 'Partner Coin',
            'address': '3UcBMHnSTCXaxUbP6B96kHcED98DgEnNa9rGKzwXKMf4',
            'decimals': 0,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21381/thumb/144531867-a8016f41-3b31-4d6f-97a1-372a58d48626.png?1639034184',
            'coingeckoId': 'partneroid',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SFOX',
            'name': 'SOL FOX',
            'address': '3TMdBbnXKASdx9rBcZ5HQsyqCky7Gt2ea44gYB6Ro15A',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://cdn.jsdelivr.net/gh/Cryptodoggo97/Looptoken/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'pUSDT',
            'name': 'Port Finance USDT',
            'address': '3RudPTAkfcq9Q9Jk8SVeCoecCBmdKMj6q5smsWzxqtqZ',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3RudPTAkfcq9Q9Jk8SVeCoecCBmdKMj6q5smsWzxqtqZ/USDT.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'TYNA',
            'name': 'wTYNA',
            'address': '3QuAYThYKFXSmrTcSHsdd7sAxaFBobaCkLy2DBYJLMDs',
            'decimals': 3,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3QuAYThYKFXSmrTcSHsdd7sAxaFBobaCkLy2DBYJLMDs/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': '$DOPE',
            'name': 'HellaDope',
            'address': '3QE2K4QSHo1Y3rjjuAf9VEhCJxKRdpePD5jLP3NwKMmF',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/3qe2k4qsho1y3rjjuaf9vehcjxkrdpepd5jlp3nwkmmf/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SQUIDGAME',
            'name': 'Squid Game',
            'address': '3NcCuwvTMnnf7TU2UEVhp6v2nzbLXQiDgzQySS6m8A7P',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3NcCuwvTMnnf7TU2UEVhp6v2nzbLXQiDgzQySS6m8A7P/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'MORN',
            'name': 'GoodMorning Token',
            'address': '3LCSAo9Hf64cxtPbArLog3PKkwGkZFN7Ttz1zLdPWPTS',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://i.ibb.co/8dYwfYw/MORN-Circle.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'WOLFE',
            'name': 'Wolfecoin',
            'address': '3KnVxWhoYdc9UwDr5WMVkZp2LpF7gnojg7We7MUd6ixQ',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3KnVxWhoYdc9UwDr5WMVkZp2LpF7gnojg7We7MUd6ixQ/logo.png',
            'coingeckoId': 'wolfecoin',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'CC',
            'name': 'Castle Coin',
            'address': '3KTkQJEMJXP741EJBFcAS34Lx9t8GsBYaW2BUUWkeyDH',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3KTkQJEMJXP741EJBFcAS34Lx9t8GsBYaW2BUUWkeyDH/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'XCOPE',
            'name': 'XCOPE',
            'address': '3K6rftdAaQYMPunrtNRHgnK2UAtjm2JwyT2oCiTDouYE',
            'decimals': 0,
            'chainId': -1,
            'logoURI': 'https://cdn.jsdelivr.net/gh/solana-labs/token-list@main/assets/mainnet/3K6rftdAaQYMPunrtNRHgnK2UAtjm2JwyT2oCiTDouYE/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SOYFI',
            'name': 'Wrapped YFI  Sollet ',
            'address': '3JSf5tPeuscJGtaCp5giEiDhv51gQ4v3zWg8DGgyLfAB',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3JSf5tPeuscJGtaCp5giEiDhv51gQ4v3zWg8DGgyLfAB/logo.png',
            'coingeckoId': 'wrapped-yfi-sollet',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'cmSOL',
            'name': 'Solend mSOL',
            'address': '3JFC4cB56Er45nWVe29Bhnn5GnwQzSmHVf6eUq9ac91h',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3JFC4cB56Er45nWVe29Bhnn5GnwQzSmHVf6eUq9ac91h/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ELONCASH',
            'name': 'ElonCash',
            'address': '3HaDnJ2PEt7v7RE8dPaSzSCgBL5dWvWtTkGaEGs5ap3N',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3HaDnJ2PEt7v7RE8dPaSzSCgBL5dWvWtTkGaEGs5ap3N/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ORO',
            'name': 'Shizen Orochi - ORO',
            'address': '3GfdtDnQC6mjkdr9cEaSr9mjS2VnSYoVypQiT2PPRBch',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3GfdtDnQC6mjkdr9cEaSr9mjS2VnSYoVypQiT2PPRBch/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'CHEEMS',
            'name': 'Cheems',
            'address': '3FoUAsGDbvTD6YZ4wVKJgTB76onJUKz7GPEBNiR5b8wc',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3FoUAsGDbvTD6YZ4wVKJgTB76onJUKz7GPEBNiR5b8wc/logo.png',
            'coingeckoId': 'cheems',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'STYLE',
            'name': 'Style',
            'address': '3FHpkMTQ3QyAJoLoXVdBpH4TfHiehnL2kXmv9UXBpYuF',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/28060/thumb/78oZvtZW_400x400.jpeg?1667442304',
            'coingeckoId': 'style',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'HIPPO',
            'name': 'Hippo Coin',
            'address': '3EkHyexJLGCvSxzn5umbtd9N69GoT4p5pfdLTFqCNP9Y',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3EkHyexJLGCvSxzn5umbtd9N69GoT4p5pfdLTFqCNP9Y/logo.png',
            'coingeckoId': 'hippo-coin',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'ARNM',
            'name': 'Arenum',
            'address': '3Dy8KFyvpUJ8nfRCbvk4HLWjNRRzxiVhTeE9PQF9RARD',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3Dy8KFyvpUJ8nfRCbvk4HLWjNRRzxiVhTeE9PQF9RARD/logo.png',
            'coingeckoId': 'arenum',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'DAWG',
            'name': 'DAWG',
            'address': '3DHPqxdMXogNNnpqBMF8N4Zs4dn1WR31H7UjWq6FExwG',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://user-images.githubusercontent.com/15795037/143055147-72513c02-be9b-4639-bb30-3c1c037cb030.png',
            'coingeckoId': 'dawg',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SXP',
            'name': 'Swipe (Portal)',
            'address': '3CyiEDRehaGufzkpXJitCP5tvh7cNhRqd9rPBxZrgK5z',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3CyiEDRehaGufzkpXJitCP5tvh7cNhRqd9rPBxZrgK5z/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SOLFI',
            'name': 'Solfina',
            'address': '3CaBxqxWsP5oqS84Pkja4wLxyZYsHzMivQbnfwFJQeL1',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/3CaBxqxWsP5oqS84Pkja4wLxyZYsHzMivQbnfwFJQeL1/logo.png',
            'coingeckoId': 'solfina',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'TRBL',
            'name': 'Tribeland',
            'address': '3CKQgrcvwhvFqVXXxLTb1u262nh26SJ3uutkSCTtbZxH',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/21728/thumb/15994.png?1639964642',
            'coingeckoId': 'tribeland',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'XHamster',
            'name': 'xHamster',
            'address': '39cG39AZ4cG7oGNMe4RhD3xAzjy1nkiNgk8W6WbDCgeR',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/39cG39AZ4cG7oGNMe4RhD3xAzjy1nkiNgk8W6WbDCgeR/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ELON',
            'name': 'Dogelon Mars',
            'address': '37mG5XYuwMSutQnvERDUZqxumes5hYp89X2gpBbedpZ2',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/37mG5XYuwMSutQnvERDUZqxumes5hYp89X2gpBbedpZ2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'XCUR',
            'name': 'Curate',
            'address': '35r2jMGKytAJ7FyKfKRHPanT8kpjg3emPy7WG6GANCNB',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/13327/thumb/400x400_%281%29_%283%29_%282%29.png?1613998208',
            'coingeckoId': 'curate',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BUSDet',
            'name': 'Binance USD (Portal from Ethereum)',
            'address': '33fsBLA8djQm82RpHmE3SuVrPGtZBWNYExsEUeKX1HXX',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/33fsBLA8djQm82RpHmE3SuVrPGtZBWNYExsEUeKX1HXX/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'ISOLA',
            'name': 'Intersola',
            'address': '333iHoRM2Awhf9uVZtSyTfU8AekdGrgQePZsKMFPgKmS',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/333iHoRM2Awhf9uVZtSyTfU8AekdGrgQePZsKMFPgKmS/logo.png',
            'coingeckoId': 'intersola',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'DAB',
            'name': 'DAB Coin',
            'address': '32CHtMAuGaCAZx8Rgp54jSFG3ihbpN5brSvRAWpwEHPv',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25275/thumb/basc-coin.png?1651123747',
            'coingeckoId': 'dab-coin',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SPKL',
            'name': 'Spookeletons',
            'address': '31tCNEE6LiL9yW4Bu153Dq4vi2GuorXxCA9pW9aA6ecU',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://www.spookeletons.com/assets/spookeletons_tokenv2.png',
            'coingeckoId': 'spookeletons-token',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'ANGLE',
            'name': 'ANGLE (Wormhole)',
            'address': '31GpPxe1SW8pn7GXimM73paD8PZyCsmVSGTLkwUAJvZ8',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/31gppxe1sw8pn7gximm73pad8pzycsmvsgtlkwuajvz8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': '1SP',
            'name': 'Onespace',
            'address': '2zzC22UBgJGCYPdFyo7GDwz7YHq5SozJc1nnBqLU8oZb',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26474/thumb/1SP_logo.png?1658195640',
            'coingeckoId': 'onespace',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'LINK',
            'name': 'Chainlink  Wormhole ',
            'address': '2wpTofQ8SkACrkZWrZDjXPitYa8AwWgX8AfxdeBRRVLX',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2wpTofQ8SkACrkZWrZDjXPitYa8AwWgX8AfxdeBRRVLX/logo.png',
            'coingeckoId': 'chainlink-wormhole',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SOLAR',
            'name': 'Solar',
            'address': '2wmKXX1xsxLfrvjEPrt2UHiqj8Gbzwxvffr9qmNjsw8g',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/SolarDexOfficial/solar_token_icon/main/token-icon.jpeg',
            'coingeckoId': 'solar',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SHROOMZ',
            'name': 'Crypto Mushroomz',
            'address': '2vRgBSJEVPXxayrhXoazQyCKSGFYQG3ZdfT2Gv5gZykL',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2vRgBSJEVPXxayrhXoazQyCKSGFYQG3ZdfT2Gv5gZykL/logo.png',
            'coingeckoId': 'crypto-mushroomz',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SLB',
            'name': 'Solberg',
            'address': '2uRFEWRBQLEKpLmF8mohFZGDcFQmrkQEEZmHQvMUBvY7',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2uRFEWRBQLEKpLmF8mohFZGDcFQmrkQEEZmHQvMUBvY7/logo.svg',
            'coingeckoId': 'solberg',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'ETD',
            'name': 'EntropyDex',
            'address': '2qfSePaCqvWkYnYUsYSm1VZYKtbzKYHh7gsnKcyrgspp',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2qfSePaCqvWkYnYUsYSm1VZYKtbzKYHh7gsnKcyrgspp/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SAMOL',
            'name': 'Samolana NFT',
            'address': '2qRHKgE9k7doshwy7ZfENuSHW256pDhcbyspDgU3Ek8C',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2qRHKgE9k7doshwy7ZfENuSHW256pDhcbyspDgU3Ek8C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'TOPSS',
            'name': 'TOPSS',
            'address': '2pVkjwPJHXopCknbdsHgXQnhptEWWWkfiw6pDcnNnBPC',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/27508/thumb/Topss_logo_final_logo_light.png?1664334182',
            'coingeckoId': 'topss',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'ADIC',
            'name': 'ADDICTED',
            'address': '2oXgY5EfpewjWoMxG9eWqHZUhevjeHEwnPabCwHSiFSM',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/2oxgy5efpewjwomxg9ewqhzuhevjehewnpabcwhsifsm/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'CORE',
            'name': 'CORE',
            'address': '2maDvG9nXGVstjdnsCZoSsNtjoda1SsZTLrHBVRgLR5F',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2x4fH7kWNgSLpBjCuFBKKKiNHMcJb6fHs8ahQZUWJj1p/Core.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'TBK',
            'name': 'TokenBook',
            'address': '2mDJPcvv7vigZo9ZPxhHLpKQSixCkbohVY35eX6NkN6m',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2mDJPcvv7vigZo9ZPxhHLpKQSixCkbohVY35eX6NkN6m/logo.png',
            'coingeckoId': 'tokenbook',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'VIVAION',
            'name': 'Vivaion',
            'address': '2jw1uFmc1hhfJH3EqGhaE2rfZMMC2YBpxkZcdUbPppMn',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://arweave.net/Xz7ErS3TZYmBnoh3OPkIQM_O6TPotyEJb6H5WFOe_qc',
            'coingeckoId': 'vivaion',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'ADAM',
            'name': 'adamho',
            'address': '2juwHtqBUEaV26WM5sVvjFsjvCXfwP3ZPndmr5ywVwgZ',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/2juwhtqbueav26wm5svvjfsjvcxfwp3zpndmr5ywvwgz/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'McNoot',
            'name': 'McNoot',
            'address': '2geYxMQ9o466tQ3JitUVR5Xmuk4a11KXs7ZmSX1hiSJp',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://arweave.net/NyYZJt_ItdAhB3Jc4M4j8zrO_Ytz2RrSsuzqcp3YmZ8?ext=png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'MSI',
            'name': 'Matrix Solana Index',
            'address': '2e7yNwrmTgXp9ABUmcPXvFJTSrEVLj4YMyrb4GUM4Pdd',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2e7yNwrmTgXp9ABUmcPXvFJTSrEVLj4YMyrb4GUM4Pdd/logo.png',
            'coingeckoId': 'matrix-solana-index',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'ACF',
            'name': 'Alien Chicken Farm',
            'address': '2cZv8HrgcWSvC6n1uEiS48cEQGb1d3fiowP2rpa4wBL9',
            'decimals': 2,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/25441/thumb/acf-token_%281%29.png?1651760005',
            'coingeckoId': 'alien-chicken-farm',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'WAS',
            'name': 'Wasder',
            'address': '2cW8Yosn4tSYJYjfUkcpKnYBSMYDqXfJmQXVu4RJzBTw',
            'decimals': 18,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2cW8Yosn4tSYJYjfUkcpKnYBSMYDqXfJmQXVu4RJzBTw/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'PLD',
            'name': 'Plutonian DAO',
            'address': '2cJgFtnqjaoiu9fKVX3fny4Z4pRzuaqfJ3PBTMk2D9ur',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2cJgFtnqjaoiu9fKVX3fny4Z4pRzuaqfJ3PBTMk2D9ur/logo.png',
            'coingeckoId': 'plutonian-dao',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'KTN',
            'name': 'Katana',
            'address': '2bXBmaYJwJfj5Cu8TDqLLrW1b9L7dK6sLgKoBBYRBzTH',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2bXBmaYJwJfj5Cu8TDqLLrW1b9L7dK6sLgKoBBYRBzTH/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'PTN',
            'name': 'Photon',
            'address': '2ZLYEWypSrQhruqsTDqWNWuzFXe5G75dX9PoHQWtKZ31',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2ZLYEWypSrQhruqsTDqWNWuzFXe5G75dX9PoHQWtKZ31/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'VRS',
            'name': 'Verasaw Plant',
            'address': '2YCQcQgy9nNhgukjAur1jCvMXgSTQ5FVDc3ae3BcspXS',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26110/thumb/17-56-48-vrs.png?1655884668',
            'coingeckoId': 'verasaw-plant-token',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'wLUNA_v1',
            'name': 'Wrapped LUNA Token (Wormhole v1)',
            'address': '2Xf2yAXJfg82sWwdLUo2x9mZXy6JCdszdMZkcF1Hf4KV',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Xf2yAXJfg82sWwdLUo2x9mZXy6JCdszdMZkcF1Hf4KV/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'BSAMO',
            'name': 'Buff Samo',
            'address': '2XSuy8RSESbtYRBbVHxGWuoikn3B6iXKVKzN4i3owTCf',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2XSuy8RSESbtYRBbVHxGWuoikn3B6iXKVKzN4i3owTCf/logo.png',
            'coingeckoId': 'buff-samo',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'DINOEGG',
            'name': 'DINOEGG',
            'address': '2TxM6S3ZozrBHZGHEPh9CtM74a9SVXbr7NQ7UxkRvQij',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/22291/thumb/xOcKL1Fw_400x400.png?1641367958',
            'coingeckoId': 'dinoegg',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'KEKW',
            'name': 'Kekwcoin',
            'address': '2QK9vxydd7WoDwvVFT5JSU8cwE9xmbJSzeqbRESiPGMG',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2QK9vxydd7WoDwvVFT5JSU8cwE9xmbJSzeqbRESiPGMG/logo.png',
            'coingeckoId': 'kekwcoin',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': '$AFRO',
            'name': 'Afro',
            'address': '2QA5NrCMzeTqZVWTQXEc62yeHWB2vVfdVjcSYtewJ1GB',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/2qa5nrcmzetqzvwtqxec62yehwb2vvfdvjcsytewj1gb/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MM',
            'name': 'Million',
            'address': '2PoF4gqWg97yjJk276yUYaGVkkASE7tqAU7H5faEBkeC',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2PoF4gqWg97yjJk276yUYaGVkkASE7tqAU7H5faEBkeC/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'GCC',
            'name': 'GitCompile Coin',
            'address': '2NURMkJEkLWUXF91kbhBETkZ5E2D674DF2Wi5X2ZTsTH',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/gitcompile/assets/main/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'IVRY',
            'name': 'Portals Ivory Index',
            'address': '2MtPZqwNKTNsBoFCwm4ZTWk3ySz4LSd82ucDGeTk7VNu',
            'decimals': 2,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/24389/thumb/portal.PNG?1647503207',
            'coingeckoId': 'portals-ivory-index',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'BORK',
            'name': 'Bork',
            'address': '2LxZrcJJhzcAju1FBHuGvw929EVkX7R7Q8yA2cdp8q7b',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://arweave.net/VtJL5kdepu6AyVHnHi4GImgyWxmcb2XMPN7jURW_yXQ',
            'coingeckoId': 'bork',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': '107',
            'name': '107 Coin',
            'address': '2KozeiQ6xnZsLRpjv9KfJdYbvzzLVttRQZ8HT7ibdsX9',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/2kozeiq6xnzslrpjv9kfjdybvzzlvttrqz8ht7ibdsx9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'KURO',
            'name': 'Kurobi',
            'address': '2Kc38rfQ49DFaKHQaWbijkE7fcymUMLY5guUiUsDmFfn',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Kc38rfQ49DFaKHQaWbijkE7fcymUMLY5guUiUsDmFfn/logo.png',
            'coingeckoId': 'kurobi',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'SAO',
            'name': 'Sator',
            'address': '2HeykdKjzHKGm2LKHw8pDYwjKPiFEoXAz74dirhUgQvq',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/19410/thumb/sator-logo-CMC.png?1635211626',
            'coingeckoId': 'sator',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': '4RM',
            'name': 'ReichMark',
            'address': '2Gw79PQcuJABAq8qZqN8oVAXMKzuV1FFgyRwrCMKtqLx',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/2gw79pqcujabaq8qzqn8ovaxmkzuv1ffgyrwrcmktqlx/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DGOLD',
            'name': 'Degen Gold',
            'address': '2Giihhh4rD5QMF49EExf5k8qbxftaqRWzLi4tS6YcrvR',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26616/thumb/DegenGold_%28DGOLD%29.png?1659073125',
            'coingeckoId': 'degen-gold',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'SMBT',
            'name': 'Monke Token',
            'address': '2FkuyFr3N9RzvVahPqzXKfa8H9KhYpChwQZSeMKkkVPJ',
            'decimals': 4,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2FkuyFr3N9RzvVahPqzXKfa8H9KhYpChwQZSeMKkkVPJ/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'SOETH',
            'name': 'Wrapped Ethereum  Sollet ',
            'address': '2FPyTwcZLUg1MDrwsyoP4D6s1tM7hAkHYRjkNb5w6Pxk',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2FPyTwcZLUg1MDrwsyoP4D6s1tM7hAkHYRjkNb5w6Pxk/logo.png',
            'coingeckoId': 'wrapped-ethereum-sollet',
            'listedIn': [
                'coingecko',
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'VITAL',
            'name': 'Vitall Markets',
            'address': '2FKuYE5D75e9Fjg3ymGBrFfVc8tVKac4SeyvZn5dGNUz',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2FKuYE5D75e9Fjg3ymGBrFfVc8tVKac4SeyvZn5dGNUz/logo.png',
            'coingeckoId': 'vitall-markets',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': '$SSO',
            'name': 'Solly Solana',
            'address': '2Ete4fPkfRpgEzA8ypZUxVoiRAv8ZdrD9rZ6YA9b9m3j',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/2ete4fpkfrpgeza8ypzuxvoirav8zdrd9rz6ya9b9m3j/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BAB',
            'name': 'Banana Bucks',
            'address': '2Dzzc14S1D7cEFGJyMZMACuoQRHVUYFhVE74C5o8Fwau',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2Dzzc14S1D7cEFGJyMZMACuoQRHVUYFhVE74C5o8Fwau/logo.svg',
            'coingeckoId': 'banana-bucks',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': '1000X',
            'name': '1000X Exchange Token',
            'address': '2Dk6yiK64X77sGehh9uqTEeBkd7xtLcspWRAjYZDUydu',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/2dk6yik64x77sgehh9uqteebkd7xtlcspwrajyzduydu/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SOUP',
            'name': 'GoodSoup',
            'address': '2DDyLzN1pxVddhkgZYJdJH6YFUbeSVPFZBMSxcLswwap',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2DDyLzN1pxVddhkgZYJdJH6YFUbeSVPFZBMSxcLswwap/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'aeMIM',
            'name': 'Wrapped MIM (Allbridge from Ethereum)',
            'address': '2ASbApnFVSTp2RJvMLgLVfbDwJvu1FRXdhJWrGs89Lhj',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2ASbApnFVSTp2RJvMLgLVfbDwJvu1FRXdhJWrGs89Lhj/logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol',
                'rubic'
            ]
        },
        {
            'symbol': 'SJP',
            'name': 'Stacc Job Points',
            'address': '2A5esErqMaJXhrs1i6CtjbVxTbgsY9JbDedVsuVMQ6aY',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/dyor-market/token-list/main/assets/mainnet/2A5esErqMaJXhrs1i6CtjbVxTbgsY9JbDedVsuVMQ6aY/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'abDAI',
            'name': 'Wrapped DAI (Allbridge from BSC)',
            'address': '29r14BEftB1RnSdq2ygVnwmEThbhmwvaaXhkmv5GvyyG',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/29r14beftb1rnsdq2ygvnwmethbhmwvaaxhkmv5gvyyg/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SHIBT',
            'name': 'Shiba Light',
            'address': '2946ofy854iifvXCQmHX2AJgxRBoQcchy1gfD26RtkHp',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/2946ofy854iifvXCQmHX2AJgxRBoQcchy1gfD26RtkHp/logo.png',
            'coingeckoId': 'shiba-light',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'XVC',
            'name': 'Xverse',
            'address': '25Vu6457o2gdZRGVVt5K8NbAvaP3esYaQNHbNDitVtw1',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/25Vu6457o2gdZRGVVt5K8NbAvaP3esYaQNHbNDitVtw1/logo.png',
            'coingeckoId': 'xverse',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'JINDO',
            'name': 'Solana Jindo Inu',
            'address': '24WQvWoqJuTS5LoqeBJpa2smqg94V6iqQDWC5cPd8tve',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://cdn.jsdelivr.net/gh/soljindo/main/soljindoinulogo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'GEAR',
            'name': 'Starbots GEAR',
            'address': '23WuycvPjEuzJTsBPBZqnbFZFcBtBKAMTowUDHwagkuD',
            'decimals': 0,
            'chainId': -1,
            'logoURI': 'https://assets.coingecko.com/coins/images/26651/thumb/logo_%282%29.png?1659408350',
            'coingeckoId': 'starbots-gear',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'CEX',
            'name': 'Catena X',
            'address': '21vatMcwZz53Eu2EUDCS9xoZUXdJ9ABMTQYNMKKkzoNW',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/21vatMcwZz53Eu2EUDCS9xoZUXdJ9ABMTQYNMKKkzoNW/Logo.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'sUSD',
            'name': 'Solcasino USD',
            'address': '21jZ1ESEFYh9SBwjT5gqE3jHv3JnaGhgvypLTR96QXW9',
            'decimals': 6,
            'chainId': -1,
            'logoURI': 'https://files.solcasino.io/susd.png',
            'coingeckoId': null,
            'listedIn': [
                '1sol'
            ]
        },
        {
            'symbol': 'NRA',
            'name': 'Nora',
            'address': '1C2EYVrwmoXAGbiKirFFBeDFDYUBHPhDeg9trhibTND',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/1C2EYVrwmoXAGbiKirFFBeDFDYUBHPhDeg9trhibTND/logo.png',
            'coingeckoId': 'nora-token',
            'listedIn': [
                'coingecko',
                '1sol'
            ]
        },
        {
            'symbol': 'AKRO',
            'name': 'Akropolis (Wormhole)',
            'address': '12uhjozdvgygwelqq8dmcrbig8amw5vmvzu3fdmmdcag',
            'decimals': 8,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/12uhjozdvgygwelqq8dmcrbig8amw5vmvzu3fdmmdcag/logo.png',
            'coingeckoId': 'akro-wormhole',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ARTEL',
            'name': 'ArtelCoin',
            'address': '12jnEAyj5qkW9UPfQS7z7Yxz3LB9cHXdg65sZTfMj6Gg',
            'decimals': 9,
            'chainId': -1,
            'logoURI': 'https://api.rubic.exchange/assets/solana/12jneayj5qkw9upfqs7z7yxz3lb9chxdg65sztfmj6gg/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        }
    ],
    '9001': [
        {
            'symbol': 'OCEAN',
            'name': 'Ocean Token',
            'address': '0xf27Ee99622C3C9b264583dACB2cCE056e194494f',
            'decimals': 18,
            'chainId': 9001,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'G-USDT',
            'name': 'Gravity Bridge Tether',
            'address': '0xecEEEfCEE421D8062EF8d6b4D814efe4dc898265',
            'decimals': 6,
            'chainId': 9001,
            'logoURI': 'https://assets.coingecko.com/coins/images/28170/thumb/gusdt.png?1668243861',
            'coingeckoId': 'gravity-bridge-tether',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ceUSDC',
            'name': 'USD Coin | Celer cBridge',
            'address': '0xe46910336479F254723710D57e7b683F3315b22B',
            'decimals': 6,
            'chainId': 9001,
            'logoURI': 'https://static.debank.com/image/evmos_token/logo_url/0xe46910336479f254723710d57e7b683f3315b22b/43cebbf7a996ebbb31c6b1513e282f0b.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FXS',
            'name': 'Frax Share',
            'address': '0xd8176865DD0D672c6Ab4A427572f80A72b4B4A9C',
            'decimals': 18,
            'chainId': 9001,
            'logoURI': 'https://assets.coingecko.com/coins/images/13423/thumb/frax_share.png?1608478989',
            'coingeckoId': 'frax-share',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'G-DAI',
            'name': 'Gravity Bridge DAI',
            'address': '0xd567B3d7B8FE3C79a1AD8dA978812cfC4Fa05e75',
            'decimals': 18,
            'chainId': 9001,
            'logoURI': 'https://assets.coingecko.com/coins/images/28169/thumb/g-dai.png?1668243431',
            'coingeckoId': 'gravity-bridge-dai',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'madFXS',
            'name': 'Frax Share | Nomad',
            'address': '0xd0ec216A38F199B0229AE668a96c3Cd9F9f118A6',
            'decimals': 18,
            'chainId': 9001,
            'logoURI': 'https://raw.githubusercontent.com/cronus-finance/token-list/main/assets/evmos/0xd0ec216A38F199B0229AE668a96c3Cd9F9f118A6/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'G-WETH',
            'name': 'Gravity Bridge WETH',
            'address': '0xc03345448969Dd8C00e9E4A85d2d9722d093aF8E',
            'decimals': 18,
            'chainId': 9001,
            'logoURI': 'https://assets.coingecko.com/coins/images/28174/thumb/gweth.png?1668246159',
            'coingeckoId': 'gravity-bridge-weth',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'CEWBTC',
            'name': 'Wrapped Bitcoin   Celer',
            'address': '0xb98e169C37ce30Dd47Fdad1f9726Fb832191e60b',
            'decimals': 8,
            'chainId': 9001,
            'logoURI': 'https://assets.coingecko.com/coins/images/25013/thumb/3717.png?1649825574',
            'coingeckoId': 'wrapped-bitcoin-celer',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ceUSDT',
            'name': 'Tether USD | Celer cBridge',
            'address': '0xb72A7567847abA28A2819B855D7fE679D4f59846',
            'decimals': 6,
            'chainId': 9001,
            'logoURI': 'https://raw.githubusercontent.com/cronus-finance/token-list/main/assets/evmos/0xb72A7567847abA28A2819B855D7fE679D4f59846/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BETS',
            'name': 'BetSwirl Token',
            'address': '0xb12c13e66AdE1F72f71834f2FC5082Db8C091358',
            'decimals': 18,
            'chainId': 9001,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'CELR',
            'name': 'Celer Network',
            'address': '0xFe6998C5c22936CCa749b14Fcf5F190398cfa8F8',
            'decimals': 18,
            'chainId': 9001,
            'logoURI': 'https://raw.githubusercontent.com/cronus-finance/token-list/main/assets/evmos/0xFe6998C5c22936CCa749b14Fcf5F190398cfa8F8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ACRE',
            'name': 'Arable Protocol',
            'address': '0xFEa7a6a0B346362BF88A9e4A88416B77a57D6c2A',
            'decimals': 18,
            'chainId': 9001,
            'logoURI': 'https://mvp.arable.finance/public/assets/tokens/acre_token.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'OSMO',
            'name': 'Osmosis',
            'address': '0xFA3C22C069B9556A4B2f7EcE1Ee3B467909f4864',
            'decimals': 6,
            'chainId': 9001,
            'logoURI': 'https://assets.coingecko.com/coins/images/16724/thumb/osmo.png?1632763885',
            'coingeckoId': 'osmosis',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'WBTC',
            'name': 'WBTC',
            'address': '0xF80699Dc594e00aE7bA200c7533a07C1604A106D',
            'decimals': 8,
            'chainId': 9001,
            'logoURI': 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AXLWBTC',
            'name': 'axlWBTC',
            'address': '0xF5b24c0093b65408ACE53df7ce86a02448d53b25',
            'decimals': 8,
            'chainId': 9001,
            'logoURI': 'https://assets.coingecko.com/coins/images/28173/thumb/axlwbtc.png?1668245218',
            'coingeckoId': 'axlwbtc',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'GTPS',
            'name': 'Global Transaction Payment Solution',
            'address': '0xF480f38C366dAaC4305dC484b2Ad7a496FF00CeA',
            'decimals': 18,
            'chainId': 9001,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FRAX',
            'name': 'Frax',
            'address': '0xE03494D0033687543a80c9B1ca7D6237F2EA8BD8',
            'decimals': 18,
            'chainId': 9001,
            'logoURI': 'https://assets.coingecko.com/coins/images/13422/thumb/frax_logo.png?1608476506',
            'coingeckoId': 'frax',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'EVMOS',
            'name': 'Evmos',
            'address': '0xD4949664cD82660AaE99bEdc034a0deA8A0bd517',
            'decimals': 18,
            'chainId': 9001,
            'logoURI': 'https://assets.coingecko.com/coins/images/24023/thumb/evmos.png?1653958927',
            'coingeckoId': 'evmos',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'ATOM',
            'name': 'Cosmos Hub',
            'address': '0xC5e00D3b04563950941f7137B5AfA3a534F0D6d6',
            'decimals': 6,
            'chainId': 9001,
            'logoURI': 'https://assets.coingecko.com/coins/images/1481/thumb/cosmos_hub.png?1555657960',
            'coingeckoId': 'cosmos',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'multiUSDT',
            'name': 'Tether USD | Multichain',
            'address': '0xC1Be9a4D5D45BeeACAE296a7BD5fADBfc14602C4',
            'decimals': 6,
            'chainId': 9001,
            'logoURI': 'https://raw.githubusercontent.com/cronus-finance/token-list/main/assets/evmos/0xC1Be9a4D5D45BeeACAE296a7BD5fADBfc14602C4/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'OAV',
            'name': 'Orbital Apes',
            'address': '0xBbD37BF85f7474b5bDe689695674faB1888565Ad',
            'decimals': 18,
            'chainId': 9001,
            'logoURI': 'https://storage.googleapis.com/us-central1-dgc-berlin-0-470cbba9-bucket/tokenlist/oav.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ceDAI',
            'name': 'Dai Stablecoin | Celer cBridge',
            'address': '0x940dAAbA3F713abFabD79CdD991466fe698CBe54',
            'decimals': 18,
            'chainId': 9001,
            'logoURI': 'https://raw.githubusercontent.com/cronus-finance/token-list/main/assets/evmos/0x940dAAbA3F713abFabD79CdD991466fe698CBe54/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'GRAV',
            'name': 'Graviton',
            'address': '0x80b5a32E4F032B2a058b4F29EC95EEfEEB87aDcd',
            'decimals': 6,
            'chainId': 9001,
            'logoURI': 'https://assets.coingecko.com/coins/images/24818/thumb/Graviton_-_Blue_200x200i.png?1649038479',
            'coingeckoId': 'graviton',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'AVAX',
            'name': 'Avalanche | Celer cBridge',
            'address': '0x8006320739fC281da67Ee62eB9b4Ef8ADD5C903a',
            'decimals': 18,
            'chainId': 9001,
            'logoURI': 'https://static.debank.com/image/evmos_token/logo_url/0x8006320739fc281da67ee62eb9b4ef8add5c903a/e195cdd89f44bf3d0c65d38ce2c6c662.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'USDT',
            'address': '0x7FF4a56B32ee13D7D4D405887E0eA37d61Ed919e',
            'decimals': 6,
            'chainId': 9001,
            'logoURI': 'https://static.debank.com/image/evmos_token/logo_url/0x7ff4a56b32ee13d7d4d405887e0ea37d61ed919e/3c1a718331e468abe1fc2ebe319f6c77.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'multiWETH',
            'name': 'Wrapped Ether | Multichain',
            'address': '0x7C598c96D02398d89FbCb9d41Eab3DF0C16F227D',
            'decimals': 18,
            'chainId': 9001,
            'logoURI': 'https://raw.githubusercontent.com/cronus-finance/token-list/main/assets/evmos/0x7C598c96D02398d89FbCb9d41Eab3DF0C16F227D/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'xDIFF',
            'name': 'xDiffusion',
            'address': '0x75aeE82a16BD1fB98b11879af93AB7CE055f66Da',
            'decimals': 18,
            'chainId': 9001,
            'logoURI': 'https://storage.googleapis.com/us-central1-dgc-berlin-0-470cbba9-bucket/tokenlist/xDIFF.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'BNB',
            'name': 'BNB | Celer cBridge',
            'address': '0x75364D4F779d0Bd0facD9a218c67f87dD9Aff3b4',
            'decimals': 18,
            'chainId': 9001,
            'logoURI': 'https://static.debank.com/image/evmos_token/logo_url/0x75364d4f779d0bd0facd9a218c67f87dd9aff3b4/118a23beef33c9550d09d394e34e50d4.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'FTM',
            'name': 'Fantom | Celer cBridge',
            'address': '0x729416B1F442f204989f1C9f0d58321F878808eD',
            'decimals': 18,
            'chainId': 9001,
            'logoURI': 'https://raw.githubusercontent.com/cronus-finance/token-list/main/assets/evmos/0x729416B1F442f204989f1C9f0d58321F878808eD/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'madDAI',
            'name': 'Dai Stablecoin | Nomad',
            'address': '0x63743ACF2c7cfee65A5E356A4C4A005b586fC7AA',
            'decimals': 18,
            'chainId': 9001,
            'logoURI': 'https://static.debank.com/image/evmos_token/logo_url/0x63743acf2c7cfee65a5e356a4c4a005b586fc7aa/61b18dee6896c6dab0684a78d0eee10a.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'STARS',
            'name': 'Stargaze',
            'address': '0x5aD523d94Efb56C400941eb6F34393b84c75ba39',
            'decimals': 6,
            'chainId': 9001,
            'logoURI': 'https://assets.coingecko.com/coins/images/22363/small/stars.png',
            'coingeckoId': 'stargaze',
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'G-USDC',
            'name': 'Gravity Bridge USDC',
            'address': '0x5FD55A1B9FC24967C4dB09C513C3BA0DFa7FF687',
            'decimals': 6,
            'chainId': 9001,
            'logoURI': 'https://assets.coingecko.com/coins/images/28175/thumb/gusdc.png?1668246961',
            'coingeckoId': 'gravity-bridge-usdc',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'WETH',
            'name': 'WETH',
            'address': '0x5842C5532b61aCF3227679a8b1BD0242a41752f2',
            'decimals': 18,
            'chainId': 9001,
            'logoURI': 'https://static.debank.com/image/evmos_token/logo_url/0x5842c5532b61acf3227679a8b1bd0242a41752f2/fa2f1f04a6761644701860eea7c4a47a.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USD Coin',
            'address': '0x51e44FfaD5C2B122C8b635671FCC8139dc636E82',
            'decimals': 6,
            'chainId': 9001,
            'logoURI': 'https://assets.coingecko.com/coins/images/6319/thumb/USD_Coin_icon.png?1547042389',
            'coingeckoId': 'usd-coin',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'BUSD',
            'name': 'Binance USD | Celer cBridge',
            'address': '0x516e6D96896Aea92cE5e78B0348FD997F13802ad',
            'decimals': 18,
            'chainId': 9001,
            'logoURI': 'https://static.debank.com/image/evmos_token/logo_url/0x516e6d96896aea92ce5e78b0348fd997f13802ad/84f65dc7b2eced0236342ae88e4ed2ac.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'AXLWETH',
            'name': 'axlWETH',
            'address': '0x50dE24B3f0B3136C50FA8A3B8ebc8BD80a269ce5',
            'decimals': 18,
            'chainId': 9001,
            'logoURI': 'https://assets.coingecko.com/coins/images/28171/thumb/axlweth.png?1668244532',
            'coingeckoId': 'axlweth',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'AURORA',
            'name': 'Aurora | Celer cBridge',
            'address': '0x48421FF1c6B93988138130865C4B7Cce10358271',
            'decimals': 18,
            'chainId': 9001,
            'logoURI': 'https://raw.githubusercontent.com/cronus-finance/token-list/main/assets/evmos/0x48421FF1c6B93988138130865C4B7Cce10358271/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'EVD',
            'name': 'Evmos Domains',
            'address': '0x47685B6AC7bB4de761A57828877A7B8254c8B145',
            'decimals': 18,
            'chainId': 9001,
            'logoURI': 'https://assets.coingecko.com/coins/images/27785/thumb/evmos-domains.png?1665798992',
            'coingeckoId': 'evmos-domains',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'DAI',
            'name': 'Dai Stablecoin',
            'address': '0x461d52769884ca6235B685EF2040F47d30C94EB5',
            'decimals': 18,
            'chainId': 9001,
            'logoURI': 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x6B175474E89094C44Da98b954EedeAC495271d0F/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'DIFF',
            'name': 'Diffusion',
            'address': '0x3f75ceabCDfed1aCa03257Dc6Bdc0408E2b4b026',
            'decimals': 18,
            'chainId': 9001,
            'logoURI': 'https://assets.coingecko.com/coins/images/25331/thumb/photo5451952870917257644.jpg?1651826321',
            'coingeckoId': 'diffusion',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'JUNO',
            'name': 'JUNO',
            'address': '0x3452e23F9c4cC62c70B7ADAd699B264AF3549C19',
            'decimals': 6,
            'chainId': 9001,
            'logoURI': 'https://assets.coingecko.com/coins/images/19249/thumb/juno.png?1642838082',
            'coingeckoId': 'juno-network',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'multiWBTC',
            'name': 'Wrapped Bitcoin | Multichain',
            'address': '0x332730a4F6E03D9C55829435f10360E13cfA41Ff',
            'decimals': 8,
            'chainId': 9001,
            'logoURI': 'https://raw.githubusercontent.com/cronus-finance/token-list/main/assets/evmos/0x332730a4F6E03D9C55829435f10360E13cfA41Ff/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'multiUSDC',
            'name': 'USD Coin | Multichain',
            'address': '0x2C78f1b70Ccf63CDEe49F9233e9fAa99D43AA07e',
            'decimals': 6,
            'chainId': 9001,
            'logoURI': 'https://raw.githubusercontent.com/cronus-finance/token-list/main/assets/evmos/0x2C78f1b70Ccf63CDEe49F9233e9fAa99D43AA07e/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'madFRAX',
            'name': 'Frax | Nomad',
            'address': '0x28eC4B29657959F4A5052B41079fe32919Ec3Bd3',
            'decimals': 18,
            'chainId': 9001,
            'logoURI': 'https://raw.githubusercontent.com/cronus-finance/token-list/main/assets/evmos/0x28eC4B29657959F4A5052B41079fe32919Ec3Bd3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'G-WBTC',
            'name': 'Gravity Bridge WBTC',
            'address': '0x1D54EcB8583Ca25895c512A8308389fFD581F9c9',
            'decimals': 8,
            'chainId': 9001,
            'logoURI': 'https://assets.coingecko.com/coins/images/28172/thumb/gwbtc.png?1668244896',
            'coingeckoId': 'gravity-bridge-wbtc',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'EMO',
            'name': 'EvmoSwap',
            'address': '0x181C262b973B22C307C646a67f64B76410D19b6B',
            'decimals': 18,
            'chainId': 9001,
            'logoURI': 'https://assets.coingecko.com/coins/images/25800/thumb/logox200.png?1653970616',
            'coingeckoId': 'evmoswap',
            'listedIn': [
                'coingecko'
            ]
        },
        {
            'symbol': 'AXLUSDC',
            'name': 'axlUSDC',
            'address': '0x15C3Eb3B621d1Bff62CbA1c9536B7c1AE9149b57',
            'decimals': 6,
            'chainId': 9001,
            'logoURI': 'https://assets.coingecko.com/coins/images/26476/thumb/axlUSDC.png?1658207579',
            'coingeckoId': 'axlusdc',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'CEWETH',
            'name': 'Wrapped Ether   Celer',
            'address': '0x153A59d48AcEAbedbDCf7a13F67Ae52b434B810B',
            'decimals': 18,
            'chainId': 9001,
            'logoURI': 'https://assets.coingecko.com/coins/images/24827/thumb/weth.png?1649047091',
            'coingeckoId': 'wrapped-ether-celer',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'CRN',
            'name': 'Cronus Finance',
            'address': '0x1488346419FFc85C6D54E71be80a222971fb2240',
            'decimals': 18,
            'chainId': 9001,
            'logoURI': 'https://assets.coingecko.com/coins/images/24022/thumb/h8GHzr2W_400x400.jpg?1646096205',
            'coingeckoId': 'cronus-finance',
            'listedIn': [
                'coingecko',
                'lifinance'
            ]
        },
        {
            'symbol': 'EVMOS',
            'name': 'EVMOS',
            'address': '0x0000000000000000000000000000000000000000',
            'decimals': 18,
            'chainId': 9001,
            'logoURI': 'https://static.debank.com/image/evmos_token/logo_url/evmos/26e038b4d5475d5a4b92f7fc08bdabc9.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        }
    ],
    '5': [
        {
            'symbol': 'veKWENTA',
            'name': 'veKwenta',
            'address': '0xf36c9a9E8333663F8CA3608C5582916628E79e3f',
            'decimals': 18,
            'chainId': 5,
            'logoURI': 'https://ethereum-optimism.github.io/data/veKWENTA/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'MOM',
            'name': 'Monetum',
            'address': '0xd3D1c79D8a5F8316D07c4A391805E5006c71648C',
            'decimals': 18,
            'chainId': 5,
            'logoURI': 'https://ethereum-optimism.github.io/data/MOM/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'Tether USD',
            'address': '0xC2C527C0CACF457746Bd31B2a698Fe89de2b6d49',
            'decimals': 6,
            'chainId': 5,
            'logoURI': 'https://ethereum-optimism.github.io/data/USDT/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'WBTC',
            'name': 'Wrapped BTC',
            'address': '0xC04B0d3107736C32e19F1c62b2aF67BE61d63a05',
            'decimals': 8,
            'chainId': 5,
            'logoURI': 'https://ethereum-optimism.github.io/data/WBTC/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'WETH',
            'name': 'Wrapped Ether',
            'address': '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
            'decimals': 18,
            'chainId': 5,
            'logoURI': 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'uniswap',
                'sushiswap'
            ]
        },
        {
            'symbol': 'WMP',
            'name': 'WAMP',
            'address': '0x753BD3E00B9585AC9a94Fdf287f41d8D51e5CF8b',
            'decimals': 18,
            'chainId': 5,
            'logoURI': 'https://ethereum-optimism.github.io/data/WMP/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'TESTLDO',
            'name': 'TEST Lido DAO Token',
            'address': '0x56340274fB5a72af1A3C6609061c451De7961Bd4',
            'decimals': 18,
            'chainId': 5,
            'logoURI': 'https://ethereum-optimism.github.io/data/LDO/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'SUSHI',
            'name': 'SushiToken',
            'address': '0x5457Cc9B34eA486eB8d3286329F3536f71fa8A8B',
            'decimals': 18,
            'chainId': 5,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'SNX',
            'name': 'Synthetix',
            'address': '0x51f44ca59b867E005e48FA573Cb8df83FC7f7597',
            'decimals': 18,
            'chainId': 5,
            'logoURI': 'https://ethereum-optimism.github.io/data/SNX/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'MOCHI',
            'name': 'Mochi',
            'address': '0x48B942C65a5991dD64360CdDE442792bD829Af80',
            'decimals': 18,
            'chainId': 5,
            'logoURI': 'https://ethereum-optimism.github.io/data/MOCHI/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'OUTb',
            'name': 'OptimismUselessToken-Bridged',
            'address': '0x32B3b2281717dA83463414af4E8CfB1970E56287',
            'decimals': 18,
            'chainId': 5,
            'logoURI': 'https://ethereum-optimism.github.io/data/OUTb/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'LINK',
            'name': 'Chainlink',
            'address': '0x326C977E6efc84E512bB9C30f76E30c160eD06FB',
            'decimals': 18,
            'chainId': 5,
            'logoURI': 'https://ethereum-optimism.github.io/data/LINK/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'PERP',
            'name': 'Perpetual',
            'address': '0x29DdacF754877123157e0eeed26770736B304d0C',
            'decimals': 18,
            'chainId': 5,
            'logoURI': 'https://ethereum-optimism.github.io/data/PERP/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'UNI',
            'name': 'Uniswap',
            'address': '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
            'decimals': 18,
            'chainId': 5,
            'logoURI': 'ipfs://QmXttGpZrECX5qCyXbBQiqgQNytVGeZW5Anewvh2jc4psg',
            'coingeckoId': null,
            'listedIn': [
                'uniswap',
                'sushiswap'
            ]
        },
        {
            'symbol': 'DAI',
            'name': 'Dai Stablecoin',
            'address': '0x11fE4B6AE13d2a6055C8D9cF65c55bac32B5d844',
            'decimals': 18,
            'chainId': 5,
            'logoURI': 'https://ethereum-optimism.github.io/data/DAI/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'HAN',
            'name': 'HanChain',
            'address': '0x0c90C57aaf95A3A87eadda6ec3974c99D786511F',
            'decimals': 18,
            'chainId': 5,
            'logoURI': 'https://ethereum-optimism.github.io/data/HAN/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'INSURE',
            'name': 'InsureToken',
            'address': '0x0C4a63D472120d7859E2842b7C2Bafbd8eDe8f44',
            'decimals': 18,
            'chainId': 5,
            'logoURI': 'https://ethereum-optimism.github.io/data/INSURE/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USD Coin',
            'address': '0x07865c6E87B9F70255377e024ace6630C1Eaa37F',
            'decimals': 6,
            'chainId': 5,
            'logoURI': 'https://ethereum-optimism.github.io/data/USDC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'ETH',
            'name': 'Ether',
            'address': '0x0000000000000000000000000000000000000000',
            'decimals': 18,
            'chainId': 5,
            'logoURI': 'https://ethereum-optimism.github.io/data/ETH/logo.svg',
            'coingeckoId': 'ethereum',
            'listedIn': [
                'optimism'
            ]
        }
    ],
    '42': [
        {
            'symbol': 'EQZ',
            'name': 'Equalizer',
            'address': '0xea281a04cf517aa0D4645bDDA0353b0958E4B1b4',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/EQZ/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'Tether USD',
            'address': '0xe0BB0D3DE8c10976511e5030cA403dBf4c25165B',
            'decimals': 6,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/USDT/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'WBTC',
            'name': 'Wrapped BTC',
            'address': '0xd3A691C852CDB01E281545A27064741F0B7f6825',
            'decimals': 8,
            'chainId': 42,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'SUKU',
            'name': 'SUKU',
            'address': '0xd35f5965B4B84382ca27072fF3B6b42e7053e672',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/SUKU/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'WETH',
            'name': 'Wrapped Ether',
            'address': '0xd0A1E359811322d97991E03f863a0C30C2cF029C',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xd0A1E359811322d97991E03f863a0C30C2cF029C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'uniswap',
                'sushiswap'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USD Coin USDC',
            'address': '0xb7a4F3E9097C08dA09517b5aB877F7a917224ede',
            'decimals': 6,
            'chainId': 42,
            'logoURI': 'https://raw.githubusercontent.com/compound-finance/token-list/master/assets/asset_USDC.svg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'cSAI',
            'name': 'Compound Sai',
            'address': '0xb3f7fB482492f4220833De6D6bfCC81157214bEC',
            'decimals': 8,
            'chainId': 42,
            'logoURI': 'https://raw.githubusercontent.com/compound-finance/token-list/master/assets/ctoken_sai.svg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'USDD',
            'name': 'Decentralized USD',
            'address': '0xae73dDeAFc00Bc5aD03A96C2b268C3f5325C8D49',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/USDD/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'wstETH',
            'name': 'Wrapped liquid staked Ether 2.0',
            'address': '0xa88751C0a08623E11ff38c6B70F2BbEe7865C17c',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/wstETH/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'LINK',
            'name': 'Chainlink',
            'address': '0xa36085F69e2889c224210F603D836748e7dC0088',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/LINK/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'cWBTC',
            'name': 'Compound Wrapped BTC',
            'address': '0xa1fAA15655B0e7b6B6470ED3d096390e6aD93Abb',
            'decimals': 8,
            'chainId': 42,
            'logoURI': 'https://raw.githubusercontent.com/compound-finance/token-list/master/assets/ctoken_wbtc.svg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'USX',
            'name': 'dForce USD',
            'address': '0xF76eAd4da04BbeB97d29F83e2Ec3a621d0FB3c6e',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/USX/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'BitBTC',
            'name': 'BitBTC',
            'address': '0xF6DD2A9B840826D53c9842207628502B79E6B8c2',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/BitBTC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'cDAI',
            'name': 'Compound Dai',
            'address': '0xF0d0EB522cfa50B716B3b1604C4F0fA6f04376AD',
            'decimals': 8,
            'chainId': 42,
            'logoURI': 'https://raw.githubusercontent.com/compound-finance/token-list/master/assets/ctoken_dai.svg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'GYSR',
            'name': 'Geyser',
            'address': '0xDA9b55DE6e04404F6c77673D4B243142a4efC6B8',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/GYSR/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'SAI',
            'name': 'Sai Stablecoin v1.0',
            'address': '0xD1308F63823221518Ec88EB209CBaa1ac182105f',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://raw.githubusercontent.com/compound-finance/token-list/master/assets/asset_SAI.svg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'LAND',
            'name': 'Land',
            'address': '0xC13DD39606d4C2489ce5D9806d060FeD9a7d063d',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/LAND/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'SNX',
            'name': 'Synthetix',
            'address': '0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/SNX/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'GTC',
            'name': 'Gitcoin',
            'address': '0xB7E230f904971724C600ad5217b88d219dDD1525',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/GTC/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'cZRX',
            'name': 'Compound 0x',
            'address': '0xAf45ae737514C8427D373D50Cd979a242eC59e5a',
            'decimals': 8,
            'chainId': 42,
            'logoURI': 'https://raw.githubusercontent.com/compound-finance/token-list/master/assets/ctoken_zrx.svg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'MKR',
            'name': 'Maker',
            'address': '0xAaF64BFCC32d0F15873a02163e7E500671a4ffcD',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xAaF64BFCC32d0F15873a02163e7E500671a4ffcD/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'uniswap',
                'sushiswap'
            ]
        },
        {
            'symbol': 'cREP',
            'name': 'Compound Augur',
            'address': '0xA4eC170599a1Cf87240a35b9B1B8Ff823f448b57',
            'decimals': 8,
            'chainId': 42,
            'logoURI': 'https://raw.githubusercontent.com/compound-finance/token-list/master/assets/ctoken_rep.svg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'AAVE',
            'name': 'Aave Token',
            'address': '0x9BE876c6DC42215B00d7efe892E2691C3bc35d10',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/AAVE/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'RFWSTETH',
            'name': 'Respawn Finance Wrapped Staked Ethereum',
            'address': '0x891C46986708b3914A00883B72564AB76817799A',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/RFWSTETH/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'TheDAO',
            'name': 'TheDAO',
            'address': '0x8274Ea38fE9Bea66f8e7C6F3eF742B85d86aeB5d',
            'decimals': 16,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/TheDAO/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'TRB',
            'name': 'Tellor',
            'address': '0x7B8AC044ebce66aCdF14197E8De38C1Cc802dB4A',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/TRB/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'DF',
            'name': 'dForce',
            'address': '0x79E40d67DA6eAE5eB4A93Fc6a56A7961625E15F3',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/DF/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'RAI',
            'name': 'Rai Reflex Index',
            'address': '0x76b06a2f6dF6f0514e7BEC52a9AfB3f603b477CD',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/RAI/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'WBTC',
            'name': 'Wrapped BTC',
            'address': '0x68f180fcCe6836688e9084f035309E29Bf0A2095',
            'decimals': 8,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/WBTC/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'COMP',
            'name': 'Compound',
            'address': '0x61460874a7196d6a22D1eE4922473664b3E95270',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://raw.githubusercontent.com/compound-finance/token-list/master/assets/asset_COMP.svg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'BOND',
            'name': 'BarnBridge Governance Token',
            'address': '0x521EE0CeDbed2a5A130B9218551fe492C5c402e4',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/BOND/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USD Coin',
            'address': '0x50dC5200082d37d5dd34B4b0691f36e3632fE1A8',
            'decimals': 6,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/USDC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'EURT',
            'name': 'Tether EUR',
            'address': '0x50EB44e3a68f1963278b4c74c6c343508d31704C',
            'decimals': 6,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/EURT/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'REP',
            'name': 'Reputation',
            'address': '0x50DD65531676F718B018De3dc48F92B53D756996',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://raw.githubusercontent.com/compound-finance/token-list/master/assets/asset_REP.svg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'cUSDC',
            'name': 'Compound USD Coin',
            'address': '0x4a92E71227D294F041BD82dd8f78591B75140d63',
            'decimals': 8,
            'chainId': 42,
            'logoURI': 'https://raw.githubusercontent.com/compound-finance/token-list/master/assets/ctoken_usdc.svg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'cBAT',
            'name': 'Compound Basic Attention Token',
            'address': '0x4a77fAeE9650b09849Ff459eA1476eaB01606C7a',
            'decimals': 8,
            'chainId': 42,
            'logoURI': 'https://raw.githubusercontent.com/compound-finance/token-list/master/assets/ctoken_bat.svg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'DAI',
            'name': 'Dai Stablecoin',
            'address': '0x4F96Fe3b7A6Cf9725f59d353F723c1bDb64CA6Aa',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/DAI/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'uniswap',
                'sushiswap',
                'optimism'
            ]
        },
        {
            'symbol': 'KNC',
            'name': 'Kyber Network Crystal v2',
            'address': '0x4BC18Ae212F798FD7b0eae94dfCA18c0333157c4',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/KNC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'BAT',
            'name': 'Basic Attention Token',
            'address': '0x482dC9bB08111CB875109B075A40881E48aE02Cd',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://raw.githubusercontent.com/compound-finance/token-list/master/assets/asset_BAT.svg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'LIZ',
            'name': 'Theranos Coin',
            'address': '0x47Aa2a683b88E23c4d76f91Aa4181A59d0E4FBfb',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/LIZ/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'cETH',
            'name': 'Compound Ether',
            'address': '0x41B5844f4680a8C38fBb695b7F9CFd1F64474a72',
            'decimals': 8,
            'chainId': 42,
            'logoURI': 'https://raw.githubusercontent.com/compound-finance/token-list/master/assets/ctoken_eth.svg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'BAL',
            'name': 'Balancer',
            'address': '0x41286Bb1D3E870f3F750eB7E1C25d7E48c8A1Ac7',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/BAL/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'cUSDT',
            'name': 'Compound USDT',
            'address': '0x3f0A0EA2f86baE6362CF9799B523BA06647Da018',
            'decimals': 8,
            'chainId': 42,
            'logoURI': 'https://raw.githubusercontent.com/compound-finance/token-list/master/assets/ctoken_usdt.svg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'BitANT',
            'name': 'BitANT',
            'address': '0x3Ef0aBa205134ba2f449Fa04A0A0673020c36270',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/BitANT/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'DOLA',
            'name': 'Dola USD Stablecoin',
            'address': '0x39445DEc9Ba7Fb3776E8E5f9922864ddb9089304',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/DOLA/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'SPANK',
            'name': 'SPANK',
            'address': '0x2eA8f9B29AC1d70e01AE9D23d0451D2242e8d609',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/SPANK/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'SDL',
            'name': 'Saddle DAO',
            'address': '0x2b7A5a5923ECa5C00c6572Cf3E8e08384F563F93',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/SDL/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'WAD',
            'name': 'WardenSwap',
            'address': '0x2C7A8122A1b512AdC848024cE72e837001EcB586',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/WAD/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': '0xBTC',
            'name': '0xBitcoin',
            'address': '0x24a19eE5A5C8757ACDEBe542a9436D9C796d1c9E',
            'decimals': 8,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/0xBTC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'UNI',
            'name': 'Uniswap',
            'address': '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/UNI/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'uniswap',
                'sushiswap',
                'optimism'
            ]
        },
        {
            'symbol': 'UBI',
            'name': 'Universal Basic Income',
            'address': '0x1ac5F168C220De2515Af6068c5A153aFe2c76d36',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/UBI/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'TUSD',
            'name': 'TrueUSD',
            'address': '0x1794230aBe1995fcFe6B14865D4B6928121AB9d9',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/TUSD/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'ZRX',
            'name': '0x Protocol Token',
            'address': '0x162c44e53097e7B5aaE939b297ffFD6Bf90D1EE3',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://raw.githubusercontent.com/compound-finance/token-list/master/assets/asset_ZRX.svg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'aCRV',
            'name': 'AladdinCRV',
            'address': '0x12b1326459d72F2Ab081116bf27ca46cD97762A0',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/aCRV/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'BAXA',
            'name': 'BAXagent Coin',
            'address': '0x0Fc18b0CB89611C03Fc1Ba6876bdfE22317924FE',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/BAXA/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'Tether USD',
            'address': '0x07de306FF27a2B630B1141956844eB1552B956B5',
            'decimals': 6,
            'chainId': 42,
            'logoURI': 'https://raw.githubusercontent.com/compound-finance/token-list/master/assets/asset_USDT.svg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'SUSHI',
            'name': 'SushiToken',
            'address': '0x0769fd68dFb93167989C6f7254cd0D766Fb2841F',
            'decimals': 18,
            'chainId': 42,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'KROM',
            'name': 'Kromatika',
            'address': '0x0436F489525c47C1A4877a06bE0BEaba2A2E2e2d',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/KROM/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'PERP',
            'name': 'Perpetual',
            'address': '0x002BE8A5961e0f352092D6693133A6944b7846Ba',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/PERP/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'DCN',
            'name': 'Dentacoin',
            'address': '0x001dedC8c67EF6D1f4BB66FB28581d466650Cd76',
            'decimals': 0,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/DCN/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'ETH',
            'name': 'Ether',
            'address': '0x0000000000000000000000000000000000000000',
            'decimals': 18,
            'chainId': 42,
            'logoURI': 'https://ethereum-optimism.github.io/data/ETH/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        }
    ],
    '80001': [
        {
            'symbol': 'ELK',
            'name': 'Elk',
            'address': '0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE',
            'decimals': 18,
            'chainId': 80001,
            'logoURI': 'https://elk.finance/tokens/logos/mumbai/0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'WMATIC',
            'name': 'Wrapped Matic',
            'address': '0xd0A1E359811322d97991E03f863a0C30C2cF029C',
            'decimals': 18,
            'chainId': 80001,
            'logoURI': 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'DAI',
            'name': 'Dai Stablecoin',
            'address': '0xcB1e72786A6eb3b44C2a2429e317c8a2462CFeb1',
            'decimals': 18,
            'chainId': 80001,
            'logoURI': 'https://elk.finance/tokens/logos/mumbai/0xcB1e72786A6eb3b44C2a2429e317c8a2462CFeb1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'elkfinance'
            ]
        },
        {
            'symbol': 'lELK',
            'name': 'Elk Legacy',
            'address': '0xE1C8f3d529BEa8E3fA1FAC5B416335a2f998EE1C',
            'decimals': 18,
            'chainId': 80001,
            'logoURI': 'https://elk.finance/tokens/logos/mumbai/0xE1C8f3d529BEa8E3fA1FAC5B416335a2f998EE1C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'oELK',
            'name': 'Old Elk',
            'address': '0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C',
            'decimals': 18,
            'chainId': 80001,
            'logoURI': 'https://elk.finance/tokens/logos/mumbai/0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SAND',
            'name': 'The Sandbox',
            'address': '0xE03489D4E90b22c59c5e23d45DFd59Fc0dB8a025',
            'decimals': 18,
            'chainId': 80001,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/sand.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'WETH',
            'name': 'Wrapped Ether',
            'address': '0xA6FA4fB5f76172d178d61B04b0ecd319C5d1C0aa',
            'decimals': 18,
            'chainId': 80001,
            'logoURI': 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'uniswap'
            ]
        },
        {
            'symbol': 'WMATIC',
            'name': 'Wrapped Matic',
            'address': '0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889',
            'decimals': 18,
            'chainId': 80001,
            'logoURI': 'https://assets.coingecko.com/coins/images/4713/thumb/matic-token-icon.png?1624446912',
            'coingeckoId': null,
            'listedIn': [
                'uniswap'
            ]
        },
        {
            'symbol': 'ETH',
            'name': 'Ether',
            'address': '0x714550C2C1Ea08688607D86ed8EeF4f5E4F22323',
            'decimals': 18,
            'chainId': 80001,
            'logoURI': 'https://elk.finance/tokens/logos/mumbai/0x714550C2C1Ea08688607D86ed8EeF4f5E4F22323/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WMATIC',
            'name': 'Wrapped Matic',
            'address': '0x5B67676a984807a212b1c59eBFc9B3568a474F0a',
            'decimals': 18,
            'chainId': 80001,
            'logoURI': 'https://elk.finance/tokens/logos/mumbai/0x5B67676a984807a212b1c59eBFc9B3568a474F0a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'mumbaiPNG',
            'name': 'Pangolin',
            'address': '0x4828a3D98E428e73184374845f23C40eB76bA695',
            'decimals': 18,
            'chainId': 80001,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/80001/0x4828a3D98E428e73184374845f23C40eB76bA695/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'Tether USD',
            'address': '0x3813e82e6f7098b9583FC0F33a962D02018B6803',
            'decimals': 6,
            'chainId': 80001,
            'logoURI': 'https://elk.finance/tokens/logos/mumbai/0x3813e82e6f7098b9583FC0F33a962D02018B6803/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'elkfinance'
            ]
        }
    ],
    '4': [
        {
            'symbol': 'DAI',
            'name': 'Dai Stablecoin',
            'address': '0xc7AD46e0b8a400Bb3C915120d284AafbA8fc4735',
            'decimals': 18,
            'chainId': 4,
            'logoURI': 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xc7AD46e0b8a400Bb3C915120d284AafbA8fc4735/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'uniswap',
                'sushiswap'
            ]
        },
        {
            'symbol': 'WETH',
            'name': 'Wrapped Ether',
            'address': '0xc778417E063141139Fce010982780140Aa0cD5Ab',
            'decimals': 18,
            'chainId': 4,
            'logoURI': 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xc778417E063141139Fce010982780140Aa0cD5Ab/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'uniswap',
                'sushiswap'
            ]
        },
        {
            'symbol': 'MKR',
            'name': 'Maker',
            'address': '0xF9bA5210F91D0474bd1e1DcDAeC4C58E359AaD85',
            'decimals': 18,
            'chainId': 4,
            'logoURI': 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xF9bA5210F91D0474bd1e1DcDAeC4C58E359AaD85/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'uniswap',
                'sushiswap'
            ]
        },
        {
            'symbol': 'SUSHI',
            'name': 'SushiToken',
            'address': '0x5457Cc9B34eA486eB8d3286329F3536f71fa8A8B',
            'decimals': 18,
            'chainId': 4,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'UNI',
            'name': 'Uniswap',
            'address': '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
            'decimals': 18,
            'chainId': 4,
            'logoURI': 'ipfs://QmXttGpZrECX5qCyXbBQiqgQNytVGeZW5Anewvh2jc4psg',
            'coingeckoId': null,
            'listedIn': [
                'uniswap',
                'sushiswap'
            ]
        }
    ],
    '3': [
        {
            'symbol': 'WETH',
            'name': 'Wrapped Ether',
            'address': '0xc778417E063141139Fce010982780140Aa0cD5Ab',
            'decimals': 18,
            'chainId': 3,
            'logoURI': 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xc778417E063141139Fce010982780140Aa0cD5Ab/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'uniswap',
                'sushiswap'
            ]
        },
        {
            'symbol': 'DAI',
            'name': 'Dai Stablecoin',
            'address': '0xc2118d4d90b274016cB7a54c03EF52E6c537D957',
            'decimals': 18,
            'chainId': 3,
            'logoURI': 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xaD6D458402F60fD3Bd25163575031ACDce07538D/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'DAI',
            'name': 'Dai Stablecoin',
            'address': '0xaD6D458402F60fD3Bd25163575031ACDce07538D',
            'decimals': 18,
            'chainId': 3,
            'logoURI': 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xaD6D458402F60fD3Bd25163575031ACDce07538D/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'uniswap'
            ]
        },
        {
            'symbol': 'UNI',
            'name': 'Uniswap',
            'address': '0x71d82Eb6A5051CfF99582F4CDf2aE9cD402A4882',
            'decimals': 18,
            'chainId': 3,
            'logoURI': 'ipfs://QmXttGpZrECX5qCyXbBQiqgQNytVGeZW5Anewvh2jc4psg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'SUSHI',
            'name': 'SushiToken',
            'address': '0x5457Cc9B34eA486eB8d3286329F3536f71fa8A8B',
            'decimals': 18,
            'chainId': 3,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'UNI',
            'name': 'Uniswap',
            'address': '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
            'decimals': 18,
            'chainId': 3,
            'logoURI': 'ipfs://QmXttGpZrECX5qCyXbBQiqgQNytVGeZW5Anewvh2jc4psg',
            'coingeckoId': null,
            'listedIn': [
                'uniswap'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USD Coin',
            'address': '0x0D9C8723B343A8368BebE0B5E89273fF8D712e3C',
            'decimals': 6,
            'chainId': 3,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        }
    ],
    '97': [
        {
            'symbol': 'BUSD',
            'name': 'BUSD Token',
            'address': '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
            'decimals': 18,
            'chainId': 97,
            'logoURI': 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/binance/assets/BUSD-BD1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'WBNB',
            'name': 'WBNB Token',
            'address': '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
            'decimals': 18,
            'chainId': 97,
            'logoURI': 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/binance/info/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'USDT Token',
            'address': '0x337610d27c682E347C9cD60BD4b3b107C9d34dDd',
            'decimals': 18,
            'chainId': 97,
            'logoURI': 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/binance/assets/USDT-6D8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        }
    ],
    '1024': [
        {
            'symbol': 'WCLV',
            'name': 'Wrapped Clover',
            'address': '',
            'decimals': 18,
            'chainId': 1024,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/clv.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        }
    ],
    '4002': [
        {
            'symbol': 'WFTM',
            'name': 'Wrapped Fantom',
            'address': '0xf1277d1Ed8AD466beddF92ef448A132661956621',
            'decimals': 18,
            'chainId': 4002,
            'logoURI': 'https://elk.finance/tokens/logos/ftmtest/0xf1277d1Ed8AD466beddF92ef448A132661956621/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ELK',
            'name': 'Elk',
            'address': '0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE',
            'decimals': 18,
            'chainId': 4002,
            'logoURI': 'https://elk.finance/tokens/logos/ftmtest/0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'lELK',
            'name': 'Elk Legacy',
            'address': '0xE1C8f3d529BEa8E3fA1FAC5B416335a2f998EE1C',
            'decimals': 18,
            'chainId': 4002,
            'logoURI': 'https://elk.finance/tokens/logos/ftmtest/0xE1C8f3d529BEa8E3fA1FAC5B416335a2f998EE1C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'oELK',
            'name': 'Old Elk',
            'address': '0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C',
            'decimals': 18,
            'chainId': 4002,
            'logoURI': 'https://elk.finance/tokens/logos/ftmtest/0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'FUSD',
            'name': 'Fantom USD',
            'address': '0x91ea991bd52EE3C40EdA2509701d905e1Ee54074',
            'decimals': 18,
            'chainId': 4002,
            'logoURI': 'https://elk.finance/tokens/logos/ftmtest/0x91ea991bd52EE3C40EdA2509701d905e1Ee54074/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SFTM',
            'name': 'Staked FTM',
            'address': '0x3b28f151899BD945AC1559a3540b5741C7d2BD55',
            'decimals': 18,
            'chainId': 4002,
            'logoURI': 'https://elk.finance/tokens/logos/ftmtest/0x3b28f151899BD945AC1559a3540b5741C7d2BD55/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        }
    ],
    '43113': [
        {
            'symbol': 'ELK',
            'name': 'Elk',
            'address': '0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE',
            'decimals': 18,
            'chainId': 43113,
            'logoURI': 'https://elk.finance/tokens/logos/fuji/0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'WAVAX',
            'name': 'Wrapped AVAX',
            'address': '0xd00ae08403B9bbb9124bB305C09058E32C39A48c',
            'decimals': 18,
            'chainId': 43113,
            'logoURI': 'https://elk.finance/tokens/logos/fuji/0xd00ae08403B9bbb9124bB305C09058E32C39A48c/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'joe',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DAI',
            'name': 'Dai Stablecoin',
            'address': '0xFc7215C9498Fc12b22Bc0ed335871Db4315f03d3',
            'decimals': 18,
            'chainId': 43113,
            'logoURI': 'https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0xFc7215C9498Fc12b22Bc0ed335871Db4315f03d3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'joe'
            ]
        },
        {
            'symbol': 'WWAGMI',
            'name': 'Wrapped WAGMI',
            'address': '0xF561800EdedF4CE146ED427Cacf1Fb8aB3B2D452',
            'decimals': 18,
            'chainId': 43113,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43113/0xF561800EdedF4CE146ED427Cacf1Fb8aB3B2D452/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin'
            ]
        },
        {
            'symbol': 'lELK',
            'name': 'Elk Legacy',
            'address': '0xE1C8f3d529BEa8E3fA1FAC5B416335a2f998EE1C',
            'decimals': 18,
            'chainId': 43113,
            'logoURI': 'https://elk.finance/tokens/logos/fuji/0xE1C8f3d529BEa8E3fA1FAC5B416335a2f998EE1C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'oELK',
            'name': 'Old Elk',
            'address': '0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C',
            'decimals': 18,
            'chainId': 43113,
            'logoURI': 'https://elk.finance/tokens/logos/fuji/0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'fujiPNG',
            'name': 'Fuji PNG',
            'address': '0xAF5D473b3f8F96A5B21c6bbB97e09b491335acb9',
            'decimals': 18,
            'chainId': 43113,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43113/0xAF5D473b3f8F96A5B21c6bbB97e09b491335acb9/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin'
            ]
        },
        {
            'symbol': 'FUJIMOON',
            'name': 'To the Moon',
            'address': '0x97132C109c6816525F7f338DCb7435E1412A7668',
            'decimals': 9,
            'chainId': 43113,
            'logoURI': 'https://i.imgur.com/jNTqyQA.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin'
            ]
        },
        {
            'symbol': 'LINK',
            'name': 'ChainLink Token',
            'address': '0x73b4C0C45bfB90FC44D9013FA213eF2C2d908D0A',
            'decimals': 18,
            'chainId': 43113,
            'logoURI': 'https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0x73b4C0C45bfB90FC44D9013FA213eF2C2d908D0A/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'joe'
            ]
        },
        {
            'symbol': 'RIP',
            'name': 'The Crypt',
            'address': '0x224695Ba2a98E4a096a519B503336E06D9116E48',
            'decimals': 18,
            'chainId': 43113,
            'logoURI': 'https://raw.githubusercontent.com/antiyro/WagmiTokenList/master/wagmi.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin'
            ]
        },
        {
            'symbol': 'FUJISTABLE',
            'name': 'The Fuji stablecoin',
            'address': '0x2058ec2791dD28b6f67DB836ddf87534F4Bbdf22',
            'decimals': 6,
            'chainId': 43113,
            'logoURI': 'https://i.imgur.com/LPZDXoT.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin'
            ]
        },
        {
            'symbol': 'JAWZ',
            'name': 'JAWZ Fuji TestNet',
            'address': '0x0a8c21858aC24e1305BaFBDdf4DfB73a2CC9ddBC',
            'decimals': 18,
            'chainId': 43113,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/43113/0x0a8c21858aC24e1305BaFBDdf4DfB73a2CC9ddBC/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin'
            ]
        },
        {
            'symbol': 'WBTC',
            'name': 'Wrapped BTC',
            'address': '0x09C85Ef96e93f0ae892561052B48AE9DB29F2458',
            'decimals': 18,
            'chainId': 43113,
            'logoURI': 'https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/logos/0x09C85Ef96e93f0ae892561052B48AE9DB29F2458/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'joe'
            ]
        }
    ],
    '122': [
        {
            'symbol': 'fDoge',
            'name': 'Fuse Doge',
            'address': '0xebB82851b8e1348Cc774442735B710b4Cd105210',
            'decimals': 9,
            'chainId': 122,
            'logoURI': 'https://elk.finance/tokens/logos/fuse/0xebB82851b8e1348Cc774442735B710b4Cd105210/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'agEUR',
            'name': 'agEUR',
            'address': '0xeFAeeE334F0Fd1712f9a8cc375f427D9Cdd40d73',
            'decimals': 18,
            'chainId': 122,
            'logoURI': 'https://raw.githubusercontent.com/voltfinance/token-logos/main/logos/0xeFAeeE334F0Fd1712f9a8cc375f427D9Cdd40d73/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'ELK',
            'name': 'Elk',
            'address': '0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE',
            'decimals': 18,
            'chainId': 122,
            'logoURI': 'https://elk.finance/tokens/logos/fuse/0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE/logo.png',
            'coingeckoId': 'elk-finance',
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'OLE',
            'name': 'OpenLeverage',
            'address': '0xe6801928061CDbE32AC5AD0634427E140EFd05F9',
            'decimals': 18,
            'chainId': 122,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WETH (Deprecated)',
            'name': 'Wrapped Ether on Fuse',
            'address': '0xd8Bf72f3e163B9CF0C73dFdCC316417A5ac20670',
            'decimals': 18,
            'chainId': 122,
            'logoURI': 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'fuseswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'CNS',
            'name': 'Coineus',
            'address': '0xc2E299b47398963c618De5b05C6BdeCd4cc64022',
            'decimals': 18,
            'chainId': 122,
            'logoURI': 'https://raw.githubusercontent.com/voltfinance/token-logos/main/logos/0xc2E299b47398963c618De5b05C6BdeCd4cc64022/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WETH',
            'name': 'Wrapped Ether',
            'address': '0xa722c13135930332Eb3d749B2F0906559D2C5b99',
            'decimals': 18,
            'chainId': 122,
            'logoURI': 'https://elk.finance/tokens/logos/fuse/0xa722c13135930332Eb3d749B2F0906559D2C5b99/logo.png',
            'coingeckoId': 'weth',
            'listedIn': [
                'sushiswap',
                'fuseswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'tGod',
            'name': 'TigerGod',
            'address': '0xFe4D5E383BEaA6D2357Ce7BA9883019B760Ad134',
            'decimals': 18,
            'chainId': 122,
            'logoURI': 'https://elk.finance/tokens/logos/fuse/0xFe4D5E383BEaA6D2357Ce7BA9883019B760Ad134/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'Tether USD',
            'address': '0xFaDbBF8Ce7D5b7041bE672561bbA99f79c532e10',
            'decimals': 6,
            'chainId': 122,
            'logoURI': 'https://elk.finance/tokens/logos/fuse/0xFaDbBF8Ce7D5b7041bE672561bbA99f79c532e10/logo.png',
            'coingeckoId': 'tether',
            'listedIn': [
                'sushiswap',
                'fuseswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ELK',
            'name': 'Elk',
            'address': '0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C',
            'decimals': 18,
            'chainId': 122,
            'logoURI': 'https://elk.finance/tokens/logos/fuse/0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'fuseswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DAI',
            'name': 'Dai Stablecoin',
            'address': '0x94Ba7A27c7A95863d1bdC7645AC2951E0cca06bA',
            'decimals': 18,
            'chainId': 122,
            'logoURI': 'https://elk.finance/tokens/logos/fuse/0x94Ba7A27c7A95863d1bdC7645AC2951E0cca06bA/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'fuseswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SUSHI',
            'name': 'Sushi Token',
            'address': '0x90708b20ccC1eb95a4FA7C8b18Fd2C22a0Ff9E78',
            'decimals': 18,
            'chainId': 122,
            'logoURI': 'https://elk.finance/tokens/logos/fuse/0x90708b20ccC1eb95a4FA7C8b18Fd2C22a0Ff9E78/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'fuseswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'oneFUSE',
            'name': 'oneFUSE on Fuse',
            'address': '0x8A5eE71Cd4Db6b7ffdCE37313006e48FaD6ADdB0',
            'decimals': 18,
            'chainId': 122,
            'logoURI': 'https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0xBbcE03B2E7f53caDCA93251CA4c928aF01Db6404/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'fuseswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'DELTA',
            'name': 'DeltaFuse',
            'address': '0x7ec73806F81B4044D64416468C4b05688f38E365',
            'decimals': 18,
            'chainId': 122,
            'logoURI': 'https://elk.finance/tokens/logos/fuse/0x7ec73806F81B4044D64416468C4b05688f38E365/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'OM',
            'name': 'MANTRA DAO',
            'address': '0x7F59aE3a787C0d1D640F99883d0e48c22188C54f',
            'decimals': 18,
            'chainId': 122,
            'logoURI': 'https://elk.finance/tokens/logos/fuse/0x7F59aE3a787C0d1D640F99883d0e48c22188C54f/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'fuseswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BNB',
            'name': 'BNB',
            'address': '0x6acb34b1Df86E254b544189Ec32Cf737e2482058',
            'decimals': 18,
            'chainId': 122,
            'logoURI': 'https://elk.finance/tokens/logos/fuse/0x6acb34b1Df86E254b544189Ec32Cf737e2482058/logo.png',
            'coingeckoId': 'wbnb',
            'listedIn': [
                'sushiswap',
                'fuseswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BUSD',
            'name': 'Binance USD on Fuse',
            'address': '0x6a5F6A8121592BeCd6747a38d67451B310F7f156',
            'decimals': 18,
            'chainId': 122,
            'logoURI': 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x4Fabb145d64652a948d72533023f6E7A623C7C53/logo.png',
            'coingeckoId': 'binance-usd',
            'listedIn': [
                'fuseswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USD Coin',
            'address': '0x620fd5fa44BE6af63715Ef4E65DDFA0387aD13F5',
            'decimals': 6,
            'chainId': 122,
            'logoURI': 'https://elk.finance/tokens/logos/fuse/0x620fd5fa44BE6af63715Ef4E65DDFA0387aD13F5/logo.png',
            'coingeckoId': 'usd-coin',
            'listedIn': [
                'sushiswap',
                'fuseswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ELON',
            'name': 'Dogelon on Fuse',
            'address': '0x5DD8015cec49F4dB01fd228F688BF30337d3e0A9',
            'decimals': 18,
            'chainId': 122,
            'logoURI': 'https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/ethereum/assets/0x761D38e5ddf6ccf6Cf7c55759d5210750B5D60F3/logo.png',
            'coingeckoId': 'dogelon-mars',
            'listedIn': [
                'fuseswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'atLUNA',
            'name': 'Luna Terra',
            'address': '0x588e24DEd8f850b14BB2e62E9c50A7Cd5Ee13Da9',
            'decimals': 18,
            'chainId': 122,
            'logoURI': 'https://raw.githubusercontent.com/fuseio/tokenlist-assets/master/icons/luna.png',
            'coingeckoId': null,
            'listedIn': [
                'fuseswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'G$',
            'name': 'GoodDollar',
            'address': '0x495d133B938596C9984d462F007B676bDc57eCEC',
            'decimals': 2,
            'chainId': 122,
            'logoURI': 'https://elk.finance/tokens/logos/fuse/0x495d133B938596C9984d462F007B676bDc57eCEC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'fuseswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'KNC',
            'name': 'Kyber Network Crystal',
            'address': '0x43B17749B246fd2a96DE25d9e4184E27E09765b0',
            'decimals': 18,
            'chainId': 122,
            'logoURI': 'https://elk.finance/tokens/logos/fuse/0x43B17749B246fd2a96DE25d9e4184E27E09765b0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'fuseswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ISWT',
            'name': 'In Seth We Trust',
            'address': '0x42E44f16D2183d7eD27C501b04F29084eF4C2D24',
            'decimals': 18,
            'chainId': 122,
            'logoURI': 'https://elk.finance/tokens/logos/fuse/0x42E44f16D2183d7eD27C501b04F29084eF4C2D24/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'GRIM',
            'name': 'GrimaceCoin',
            'address': '0x427C995a0623157a3c1c6b8b86410B5B3A26061B',
            'decimals': 9,
            'chainId': 122,
            'logoURI': 'https://elk.finance/tokens/logos/fuse/0x427C995a0623157a3c1c6b8b86410B5B3A26061B/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'VOLT',
            'name': 'VoltToken',
            'address': '0x34Ef2Cc892a88415e9f02b91BfA9c91fC0bE6bD4',
            'decimals': 18,
            'chainId': 122,
            'logoURI': 'https://static.debank.com/image/fuse_token/logo_url/0x34ef2cc892a88415e9f02b91bfa9c91fc0be6bd4/af184e8935d0d8909b35cc37b2979552.png',
            'coingeckoId': 'fusefi',
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'WBTC',
            'name': 'Wrapped BTC',
            'address': '0x33284f95ccb7B948d9D352e1439561CF83d8d00d',
            'decimals': 8,
            'chainId': 122,
            'logoURI': 'https://elk.finance/tokens/logos/fuse/0x33284f95ccb7B948d9D352e1439561CF83d8d00d/logo.png',
            'coingeckoId': 'wrapped-bitcoin',
            'listedIn': [
                'sushiswap',
                'fuseswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DEXT',
            'name': 'DEXTools',
            'address': '0x2f60a843302F1Be3FA87429CA9d684f9091b003c',
            'decimals': 18,
            'chainId': 122,
            'logoURI': 'https://elk.finance/tokens/logos/fuse/0x2f60a843302F1Be3FA87429CA9d684f9091b003c/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'fuseswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BIFI',
            'name': 'beefy.finance',
            'address': '0x2bF9b864cdc97b08B6D79ad4663e71B8aB65c45c',
            'decimals': 18,
            'chainId': 122,
            'logoURI': 'https://assets.trustwalletapp.com/blockchains/smartchain/assets/0xCa3F508B8e4Dd382eE878A314789373D80A5190A/logo.png',
            'coingeckoId': 'beefy-finance',
            'listedIn': [
                'fuseswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'AMP',
            'name': 'Ampere Token',
            'address': '0x2b09179D26FdDB27a24ee25328890791c7A984c2',
            'decimals': 18,
            'chainId': 122,
            'logoURI': 'https://raw.githubusercontent.com/voltfinance/token-logos/main/logos/0x2b09179D26FdDB27a24ee25328890791c7A984c2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'lifinance'
            ]
        },
        {
            'symbol': 'fUSD',
            'name': 'Fuse Dollar',
            'address': '0x249BE57637D8B013Ad64785404b24aeBaE9B098B',
            'decimals': 18,
            'chainId': 122,
            'logoURI': 'https://elk.finance/tokens/logos/fuse/0x249BE57637D8B013Ad64785404b24aeBaE9B098B/logo.png',
            'coingeckoId': 'fuse-dollar',
            'listedIn': [
                'sushiswap',
                'fuseswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'atUST',
            'name': 'UST Terra',
            'address': '0x0D58a44be3dCA0aB449965dcc2c46932547Fea2f',
            'decimals': 18,
            'chainId': 122,
            'logoURI': 'https://raw.githubusercontent.com/fuseio/tokenlist-assets/master/icons/ust.png',
            'coingeckoId': null,
            'listedIn': [
                'fuseswap',
                'lifinance'
            ]
        },
        {
            'symbol': 'WFUSE',
            'name': 'Wrapped Fuse',
            'address': '0x0BE9e53fd7EDaC9F859882AfdDa116645287C629',
            'decimals': 18,
            'chainId': 122,
            'logoURI': 'https://elk.finance/tokens/logos/fuse/0x0BE9e53fd7EDaC9F859882AfdDa116645287C629/logo.png',
            'coingeckoId': 'fuse-network-token',
            'listedIn': [
                'sushiswap',
                'fuseswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'LINK',
            'name': 'ChainLink Token',
            'address': '0x0972F26e8943679b043de23df2fD3852177A7c48',
            'decimals': 18,
            'chainId': 122,
            'logoURI': 'https://elk.finance/tokens/logos/fuse/0x0972F26e8943679b043de23df2fD3852177A7c48/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'fuseswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GRT',
            'name': 'Graph Token',
            'address': '0x025a4c577198D116Ea499193E6D735FDb2e6E841',
            'decimals': 18,
            'chainId': 122,
            'logoURI': 'https://elk.finance/tokens/logos/fuse/0x025a4c577198D116Ea499193E6D735FDb2e6E841/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'fuseswap',
                'lifinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FUSE',
            'name': 'FUSE',
            'address': '0x0000000000000000000000000000000000000000',
            'decimals': 18,
            'chainId': 122,
            'logoURI': 'https://static.debank.com/image/fuse_token/logo_url/fuse/6342e1cc4646e22d1e91956fdee942eb.png',
            'coingeckoId': 'fuse-network-token',
            'listedIn': [
                'lifinance'
            ]
        }
    ],
    '1666700000': [
        {
            'symbol': 'WONE',
            'name': 'Wrapped ONE',
            'address': '0x7a2afac38517d512E55C0bCe3b6805c10a04D60F',
            'decimals': 18,
            'chainId': 1666700000,
            'logoURI': 'https://assets.coingecko.com/coins/images/4344/small/Y88JAze.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'WBTC',
            'name': 'Wrapped BTC',
            'address': '0x6c4387C4f570Aa8cAdcaFFc5E73ecb3D0F8Fc593',
            'decimals': 8,
            'chainId': 1666700000,
            'logoURI': 'https://assets.coingecko.com/coins/images/7598/small/wrapped_bitcoin_wbtc.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': '1LINK',
            'name': 'OneChainlink',
            'address': '0x2C6e26B2faD89bc52d043e78E3D980A08af0Ce88',
            'decimals': 18,
            'chainId': 1666700000,
            'logoURI': 'https://assets.coingecko.com/coins/images/877/small/chainlink-new-logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': '1ETH',
            'name': 'OneETH',
            'address': '0x1E120B3b4aF96e7F394ECAF84375b1C661830013',
            'decimals': 18,
            'chainId': 1666700000,
            'logoURI': 'https://assets.coingecko.com/coins/images/279/small/ethereum.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': '1BUSD',
            'name': 'OneBUSD',
            'address': '0x0E80905676226159cC3FF62B1876C907C91F7395',
            'decimals': 18,
            'chainId': 1666700000,
            'logoURI': 'https://assets.coingecko.com/coins/images/9576/small/BUSD.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        }
    ],
    '256': [
        {
            'symbol': 'ELK',
            'name': 'Elk',
            'address': '0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE',
            'decimals': 18,
            'chainId': 256,
            'logoURI': 'https://elk.finance/tokens/logos/hecotest/0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'oELK',
            'name': 'Old Elk',
            'address': '0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C',
            'decimals': 18,
            'chainId': 256,
            'logoURI': 'https://elk.finance/tokens/logos/hecotest/0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'WHT',
            'name': 'Wrapped HT',
            'address': '0x7aF326B6351C8A9b8fb8CD205CBe11d4Ac5FA836',
            'decimals': 18,
            'chainId': 256,
            'logoURI': 'https://elk.finance/tokens/logos/hecotest/0x7aF326B6351C8A9b8fb8CD205CBe11d4Ac5FA836/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'WHT',
            'name': 'Wrapped HT',
            'address': '0x5B2DA6F42CA09C77D577a12BeaD0446148830687',
            'decimals': 18,
            'chainId': 256,
            'logoURI': 'https://hecoinfo.com/token/images/HT_32.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        }
    ],
    '128': [
        {
            'symbol': 'BOO',
            'name': 'BOO',
            'address': '0xff96dccf2763D512B6038Dc60b7E96d1A9142507',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xff96dccf2763D512B6038Dc60b7E96d1A9142507/logo.png',
            'coingeckoId': 'booster',
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HGB',
            'name': 'HGB',
            'address': '0xfe04C1d41502Ac0D664B1B41dc23E1a4803188e2',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xfe04C1d41502Ac0D664B1B41dc23E1a4803188e2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HGT',
            'name': 'HGT',
            'address': '0xfD6cE15009D46c6327649218431E8643F82f6D64',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xfD6cE15009D46c6327649218431E8643F82f6D64/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'OGT',
            'name': 'OGT',
            'address': '0xf6EF1f99314D9B1A51FD5B69C7D6ad0008083477',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xf6EF1f99314D9B1A51FD5B69C7D6ad0008083477/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HOBA',
            'name': 'HOBA',
            'address': '0xf2d3640eeCB890984c3A4D282811f805Db2fA93b',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xf2d3640eeCB890984c3A4D282811f805Db2fA93b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NVT',
            'name': 'Heco NVT Token',
            'address': '0xf0E406c49C63AbF358030A299C0E00118C4C6BA5',
            'decimals': 8,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xf0E406c49C63AbF358030A299C0E00118C4C6BA5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HLTC',
            'name': 'Heco-Peg HLTC Token',
            'address': '0xecb56cf772B5c9A6907FB7d32387Da2fCbfB63b4',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xecb56cf772B5c9A6907FB7d32387Da2fCbfB63b4/logo.png',
            'coingeckoId': 'huobi-litecoin',
            'listedIn': [
                'sushiswap',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WOW',
            'name': 'WOWSwap',
            'address': '0xeFAeeE334F0Fd1712f9a8cc375f427D9Cdd40d73',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0x3405A1bd46B85c5C029483FbECf2F3E611026e45/logo.png',
            'coingeckoId': 'wowswap',
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'HBCH',
            'name': 'Heco-Peg HBCH Token',
            'address': '0xeF3CEBD77E0C52cb6f60875d9306397B5Caca375',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xeF3CEBD77E0C52cb6f60875d9306397B5Caca375/logo.png',
            'coingeckoId': 'huobi-bitcoin-cash',
            'listedIn': [
                'sushiswap',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ELK',
            'name': 'Elk',
            'address': '0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'CON',
            'name': 'CON',
            'address': '0xeEF1324343ca7Bf6e743e21Dd9e92Dfa4eFc3a56',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xeEF1324343ca7Bf6e743e21Dd9e92Dfa4eFc3a56/logo.png',
            'coingeckoId': 'converter-finance',
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FIRE',
            'name': 'FIRE',
            'address': '0xe97C7F83ec91E29569f1a08De95ad3Bb0e8B6B3A',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://ethapi.openocean.finance/logos/heco/0xe97c7f83ec91e29569f1a08de95ad3bb0e8b6b3a.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'CNCE',
            'name': 'CNCE',
            'address': '0xde8490B5b858a55703A6Bc7683d7cD73064512F0',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://ethapi.openocean.finance/logos/heco/0xde8490b5b858a55703a6bc7683d7cd73064512f0.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'OFE',
            'name': 'OFE',
            'address': '0xdc216b57cFd24080a920eC66A484c9c01503D6fe',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://ethapi.openocean.finance/logos/heco/0xdc216b57cfd24080a920ec66a484c9c01503d6fe.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'mBTM',
            'name': 'mBTM',
            'address': '0xd97b21993187391F44D497DD4d961122e0a6f958',
            'decimals': 8,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xd97b21993187391F44D497DD4d961122e0a6f958/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BLACK',
            'name': 'BLACK',
            'address': '0xd714d91A169127e11D8FAb3665d72E8b7ef9Dbe2',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xd714d91A169127e11D8FAb3665d72E8b7ef9Dbe2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'LION',
            'name': 'LION',
            'address': '0xd44c3Ba075D73c812E272c03D42FDB6448Ae10cd',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xd44c3Ba075D73c812E272c03D42FDB6448Ae10cd/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'STK',
            'name': 'STK',
            'address': '0xd187e65f5A6e88d84437D96d2aE8c909Cc329f95',
            'decimals': 12,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xd187e65f5A6e88d84437D96d2aE8c909Cc329f95/logo.png',
            'coingeckoId': 'super-three-kingdoms',
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'hFLUX',
            'name': 'hFLUX',
            'address': '0xd10852DF03Ea8b8Af0CC0B09cAc3f7dbB15e0433',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xd10852DF03Ea8b8Af0CC0B09cAc3f7dbB15e0433/logo.png',
            'coingeckoId': 'flux-protocol',
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DHM',
            'name': 'DHM',
            'address': '0xca757A8fc34c5d65f38792f329b05E7d9ca8b18E',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xca757A8fc34c5d65f38792f329b05E7d9ca8b18E/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BXH',
            'name': 'BXH',
            'address': '0xcBD6Cb9243d8e3381Fea611EF023e17D1B7AeDF0',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xcBD6Cb9243d8e3381Fea611EF023e17D1B7AeDF0/logo.png',
            'coingeckoId': 'bxh',
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HIC',
            'name': 'HIC',
            'address': '0xc6794C116198861fa371b820BD5BF1ecf94a3565',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xc6794C116198861fa371b820BD5BF1ecf94a3565/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MNC',
            'name': 'MNC',
            'address': '0xc66496BD7616EE02691E56d17608E59E6C9f94eA',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xc66496BD7616EE02691E56d17608E59E6C9f94eA/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BLS',
            'name': 'Heco BLS Token',
            'address': '0xc44468670837b8e0Cc39Cb822FFd2fE36Fb02Bb7',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xc44468670837b8e0Cc39Cb822FFd2fE36Fb02Bb7/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'COB',
            'name': 'COB',
            'address': '0xc424Ad368d901F6159e43b4f59dD15f90c06Ac67',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xc424Ad368d901F6159e43b4f59dD15f90c06Ac67/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PUSD',
            'name': 'PUSD',
            'address': '0xc36fF5B42092A1822948e8fac103509CC8B68DC6',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xc36fF5B42092A1822948e8fac103509CC8B68DC6/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DHD',
            'name': 'DHD',
            'address': '0xc35eFB44D01f9C121F7070f3De53E600233378f8',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xc35eFB44D01f9C121F7070f3De53E600233378f8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HBSV',
            'name': 'Heco-Peg HBSV Token',
            'address': '0xc2CB6B5357CcCE1B99Cd22232942D9A225Ea4eb1',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xc2CB6B5357CcCE1B99Cd22232942D9A225Ea4eb1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DOTC',
            'name': 'DOTC',
            'address': '0xc0748CF90e231b8f21F75b7AD69732F19C76B751',
            'decimals': 12,
            'chainId': 128,
            'logoURI': 'https://ethapi.openocean.finance/logos/heco/0xc0748cf90e231b8f21f75b7ad69732f19c76b751.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'LRC',
            'name': 'Heco-Peg LRC Token',
            'address': '0xbf22F76657601A522Cf9Ac832718A3404302D6bC',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://hecoinfo.com/images/main/empty-token.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'CIR',
            'name': 'CircleSwap Governance Token',
            'address': '0xbe5DF2fac88BB096A973e664171E60586bC5940c',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://hecoinfo.com/images/main/empty-token.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'BXS',
            'name': 'BXS',
            'address': '0xbe0e001A5553f4421DC250A20bBdAb0e735495e3',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xbe0e001A5553f4421DC250A20bBdAb0e735495e3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'LEV',
            'name': 'LEV',
            'address': '0xbc194e6f748a222754C3E8b9946922c09E7d4e91',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xbc194e6f748a222754C3E8b9946922c09E7d4e91/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'sHT',
            'name': 'sHT',
            'address': '0xbBe6C2337a2543239FF313Bed370e4eAEda268eF',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xbBe6C2337a2543239FF313Bed370e4eAEda268eF/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'sFILDA',
            'name': 'sFILDA',
            'address': '0xb813b811967a77e30B3B1582A578753520C2a4d0',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xb813b811967a77e30B3B1582A578753520C2a4d0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'LGC',
            'name': 'LGC',
            'address': '0xb7A819D170e59D79f6289b91cA1D1a9D0C788A2a',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xb7A819D170e59D79f6289b91cA1D1a9D0C788A2a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SCASH',
            'name': 'SCASH',
            'address': '0xb6ccFA7Ef3a295932536E0988CffD85228cB177c',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xb6ccFA7Ef3a295932536E0988CffD85228cB177c/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HTMoon',
            'name': 'HTMoon',
            'address': '0xb62E3b6a3866f5754FdeFcf82e733310e2851043',
            'decimals': 9,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xb62E3b6a3866f5754FdeFcf82e733310e2851043/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'XC',
            'name': 'XC',
            'address': '0xb1A9a1585A553f2170fC68D402a278Af5D186c79',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xb1A9a1585A553f2170fC68D402a278Af5D186c79/logo.png',
            'coingeckoId': 'xcom',
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HFIL',
            'name': 'Heco-Peg HFIL Token',
            'address': '0xae3a768f9aB104c69A7CD6041fE16fFa235d1810',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xae3a768f9aB104c69A7CD6041fE16fFa235d1810/logo.png',
            'coingeckoId': 'huobi-fil',
            'listedIn': [
                'sushiswap',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BADI',
            'name': 'BADI',
            'address': '0xacef56397556502B27048a4a546D0F2c2f54b840',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xacef56397556502B27048a4a546D0F2c2f54b840/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'LUCK',
            'name': 'LUCK',
            'address': '0xaab4B32014189e924a9759701586e8bFb0FeaE3c',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xaab4B32014189e924a9759701586e8bFb0FeaE3c/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FA',
            'name': 'FA',
            'address': '0xaCA179e010a5d0624d103D2e8E563b26716194CE',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xaCA179e010a5d0624d103D2e8E563b26716194CE/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PIPI',
            'name': 'PIPI',
            'address': '0xaAaE746b5e55D14398879312660e9fDe07FBC1DC',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xaAaE746b5e55D14398879312660e9fDe07FBC1DC/logo.png',
            'coingeckoId': 'pippi-finance',
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'USDTHECO',
            'name': 'Heco-Peg USDTHECO Token',
            'address': '0xa71EdC38d189767582C38A3145b5873052c3e47a',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xa71EdC38d189767582C38A3145b5873052c3e47a/logo.png',
            'coingeckoId': 'tether',
            'listedIn': [
                'sushiswap',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ELA',
            'name': 'ELA',
            'address': '0xa1ecFc2beC06E4b43dDd423b94Fef84d0dBc8F5c',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xa1ecFc2beC06E4b43dDd423b94Fef84d0dBc8F5c/logo.png',
            'coingeckoId': 'elastos',
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BAG',
            'name': 'BAG',
            'address': '0xa042fb0e60125A4022670014AC121931e7501Af4',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xa042fb0e60125A4022670014AC121931e7501Af4/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SAP',
            'name': 'SAP',
            'address': '0xFE2F1890d8DC69cf16D611C71fEf4A811ca84575',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://ethapi.openocean.finance/logos/heco/0xfe2f1890d8dc69cf16d611c71fef4a811ca84575.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'HOGT',
            'name': 'HOGT',
            'address': '0xFC33785f44c1069010b5De466eE7657C27aB8A0E',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xFC33785f44c1069010b5De466eE7657C27aB8A0E/logo.png',
            'coingeckoId': 'heco-origin-token',
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PAUL',
            'name': 'PAUL',
            'address': '0xFC01b8f883a89278235ba674bbE2bb48db96d9Cf',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xFC01b8f883a89278235ba674bbE2bb48db96d9Cf/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GOLD',
            'name': 'GOLD',
            'address': '0xFB371c8B99ba0b239E66E0a66bB6296b00dCD09f',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://ethapi.openocean.finance/logos/heco/0xfb371c8b99ba0b239e66e0a66bb6296b00dcd09f.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'YFX',
            'name': 'YFX',
            'address': '0xF55a93b613D172b86c2Ba3981a849DaE2aeCDE2f',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xF55a93b613D172b86c2Ba3981a849DaE2aeCDE2f/logo.png',
            'coingeckoId': 'yfx',
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GURU',
            'name': 'GURU',
            'address': '0xF288A18b65FEe193C915158908143AbDde4D5106',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xF288A18b65FEe193C915158908143AbDde4D5106/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'eCell',
            'name': 'eCell',
            'address': '0xF23E43AB4f3990651b562411CDa2A053F8590563',
            'decimals': 2,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xF23E43AB4f3990651b562411CDa2A053F8590563/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'COS',
            'name': 'COS',
            'address': '0xF08A2A19e4766B342b5eD6644550B05acEdeE5A8',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xF08A2A19e4766B342b5eD6644550B05acEdeE5A8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'O3',
            'name': 'O3',
            'address': '0xEe9801669C6138E84bD50dEB500827b776777d28',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xEe9801669C6138E84bD50dEB500827b776777d28/logo.png',
            'coingeckoId': 'o3-swap',
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BIRD',
            'name': 'BIRD',
            'address': '0xED6a80032336bdbA650d581F07AC64AEC58b0f3D',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://ethapi.openocean.finance/logos/heco/0xed6a80032336bdba650d581f07ac64aec58b0f3d.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'BCV',
            'name': 'BCV',
            'address': '0xE8145dF475e819C64ad68d66CD09d1a5E5E60219',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xE8145dF475e819C64ad68d66CD09d1a5E5E60219/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NDCEX',
            'name': 'NDCEX',
            'address': '0xE7aAD5EB7d46C8fCECdd1dc88114CCA2385C2317',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xE7aAD5EB7d46C8fCECdd1dc88114CCA2385C2317/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ATM',
            'name': 'ATM',
            'address': '0xE5ed86704819b5C5ec41eeeb52BB739fd36Aec20',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xE5ed86704819b5C5ec41eeeb52BB739fd36Aec20/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HPT',
            'name': 'Heco-Peg HPT Token',
            'address': '0xE499Ef4616993730CEd0f31FA2703B92B50bB536',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xE499Ef4616993730CEd0f31FA2703B92B50bB536/logo.png',
            'coingeckoId': 'huobi-pool-token',
            'listedIn': [
                'sushiswap',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FILDA',
            'name': 'FilDA on Heco',
            'address': '0xE36FFD17B2661EB57144cEaEf942D95295E637F0',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xE36FFD17B2661EB57144cEaEf942D95295E637F0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HOO',
            'name': 'HOO',
            'address': '0xE1d1F66215998786110Ba0102ef558b22224C016',
            'decimals': 8,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xE1d1F66215998786110Ba0102ef558b22224C016/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'oELK',
            'name': 'Old Elk',
            'address': '0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'LAMB',
            'name': 'Heco-Peg LAMB Token',
            'address': '0xE131F048D85f0391A24435eEFB7763199B587d0e',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xE131F048D85f0391A24435eEFB7763199B587d0e/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'XF',
            'name': 'XF',
            'address': '0xE0fE25EeFCfcadDEF844fe30b8Be1d68ac6B7aF3',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xE0fE25EeFCfcadDEF844fe30b8Be1d68ac6B7aF3/logo.png',
            'coingeckoId': 'xfarmer',
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'VNLA',
            'name': 'VNLA',
            'address': '0xE09A504071C0c1483E86727fb95f550a0559d9D2',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xE09A504071C0c1483E86727fb95f550a0559d9D2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FIX',
            'name': 'FIX',
            'address': '0xDe9495De889996404b14dDBf05f66Db7401F0733',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xDe9495De889996404b14dDBf05f66Db7401F0733/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PNX',
            'name': 'PNX',
            'address': '0xDb0ad86386Dc5C12CD2B9Cb7f17f31f405c7E376',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xDb0ad86386Dc5C12CD2B9Cb7f17f31f405c7E376/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NULS',
            'name': 'Heco NULS Token',
            'address': '0xD938e45680dA19ad36646AE8D4C671B2b1270F39',
            'decimals': 8,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xD938e45680dA19ad36646AE8D4C671B2b1270F39/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CVT',
            'name': 'CVT',
            'address': '0xD27547A4e3bA5FBc8ac6C2477151aB8E17e4D341',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xD27547A4e3bA5FBc8ac6C2477151aB8E17e4D341/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'COMP',
            'name': 'COMP',
            'address': '0xCe0A5CA134fb59402B723412994B30E02f083842',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xCe0A5CA134fb59402B723412994B30E02f083842/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SHIB',
            'name': 'SHIB',
            'address': '0xC38072AA3F8E049De541223A9c9772132bB48634',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xC38072AA3F8E049De541223A9c9772132bB48634/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'JT',
            'name': 'JT',
            'address': '0xC0eCC17C6C87B32b2a86D9F4a783974cB1e6507C',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xC0eCC17C6C87B32b2a86D9F4a783974cB1e6507C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SYN',
            'name': 'SYN',
            'address': '0xC0EA346E4A6eA25a92975B268183Eb2628585f19',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xC0EA346E4A6eA25a92975B268183Eb2628585f19/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ETHST',
            'name': 'ETHST',
            'address': '0xB86e05F2CbEd6DE54874763E4237be6b6391Bf48',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xB86e05F2CbEd6DE54874763E4237be6b6391Bf48/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BETH',
            'name': 'Heco-Peg BETH Token',
            'address': '0xB6F4c418514dd4680F76d5caa3bB42dB4A893aCb',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://hecoinfo.com/images/main/empty-token.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'YFI',
            'name': 'Heco-Peg YFI Token',
            'address': '0xB4F019bEAc758AbBEe2F906033AAa2f0F6Dacb35',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xB4F019bEAc758AbBEe2F906033AAa2f0F6Dacb35/logo.png',
            'coingeckoId': 'yearn-finance',
            'listedIn': [
                'sushiswap',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BEE',
            'name': 'BEE',
            'address': '0xB1F80844a1B84c61ab80CafD88B1f8c09f9342e1',
            'decimals': 8,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xB1F80844a1B84c61ab80CafD88B1f8c09f9342e1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GFC',
            'name': 'GFC',
            'address': '0xAFe739209C8bD6993D0ABDB50caB9bC4Eafe68c4',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xAFe739209C8bD6993D0ABDB50caB9bC4Eafe68c4/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'EOS',
            'name': 'EOS',
            'address': '0xAE3A94A6Dc7fCe46B40D63bbf355a3ab2AA2a588',
            'decimals': 4,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xAE3A94A6Dc7fCe46B40D63bbf355a3ab2AA2a588/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NFTB',
            'name': 'NFTB',
            'address': '0xACdBB3329D9c46E6c2C22D123f92A5f6705540FC',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://ethapi.openocean.finance/logos/heco/0xacdbb3329d9c46e6c2c22d123f92a5f6705540fc.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'PFi',
            'name': 'PFi',
            'address': '0xA51EEf95A1Da7E26ED7d06A28e1Bb3507cC34eBd',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xA51EEf95A1Da7E26ED7d06A28e1Bb3507cC34eBd/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'LYFI',
            'name': 'Heco LYFI Token',
            'address': '0xA441b796E160E204Cd0f53D3216275056a355980',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xA441b796E160E204Cd0f53D3216275056a355980/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GTL',
            'name': 'GTL',
            'address': '0xA352bAa250dea5a279C825CF826891d9543bF21c',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xA352bAa250dea5a279C825CF826891d9543bF21c/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HDOT',
            'name': 'Heco-Peg HDOT Token',
            'address': '0xA2c49cEe16a5E5bDEFDe931107dc1fae9f7773E3',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xA2c49cEe16a5E5bDEFDe931107dc1fae9f7773E3/logo.png',
            'coingeckoId': 'huobi-polkadot',
            'listedIn': [
                'sushiswap',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'XRP',
            'name': 'XRP',
            'address': '0xA2F3C2446a3E20049708838a779Ff8782cE6645a',
            'decimals': 6,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xA2F3C2446a3E20049708838a779Ff8782cE6645a/logo.png',
            'coingeckoId': 'heco-peg-xrp',
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'COO',
            'name': 'COO',
            'address': '0xA2B7CB98585634ad3b91CE7E719a4C696090aDF1',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0xA2B7CB98585634ad3b91CE7E719a4C696090aDF1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'TPT',
            'name': 'TPT',
            'address': '0x9ef1918a9beE17054B35108bD3E2665e2Af1Bb1b',
            'decimals': 4,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x9ef1918a9beE17054B35108bD3E2665e2Af1Bb1b/logo.png',
            'coingeckoId': 'token-pocket',
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'LINK',
            'name': 'Heco-Peg LINK Token',
            'address': '0x9e004545c59D359F6B7BFB06a26390b087717b42',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x9e004545c59D359F6B7BFB06a26390b087717b42/logo.png',
            'coingeckoId': 'chainlink',
            'listedIn': [
                'sushiswap',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FIDO',
            'name': 'FIDO',
            'address': '0x9c80d1Ff2452dF8c7d061ee277082B6D51b3f6A0',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x9c80d1Ff2452dF8c7d061ee277082B6D51b3f6A0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GOAT',
            'name': 'GOAT',
            'address': '0x9a359907a1B8f80F59653B3B0844615dda109730',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x9a359907a1B8f80F59653B3B0844615dda109730/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PWT',
            'name': 'PWT',
            'address': '0x9FFA142B9c7726E819bb637d4cb70F02224B2185',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x9FFA142B9c7726E819bb637d4cb70F02224B2185/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HYOT',
            'name': 'HYOT',
            'address': '0x9EDE2Df15DEeEE26A916B9B93Ea7d9363D7531D7',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x9EDE2Df15DEeEE26A916B9B93Ea7d9363D7531D7/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ANO',
            'name': 'ANO',
            'address': '0x9E9842bd60cA6CDD4D17532433a2EA41c723a40D',
            'decimals': 8,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x9E9842bd60cA6CDD4D17532433a2EA41c723a40D/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ET',
            'name': 'ET',
            'address': '0x99336B9a5f70f52B4B1fC2886ba9bd2D2263f5D5',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x99336B9a5f70f52B4B1fC2886ba9bd2D2263f5D5/logo.png',
            'coingeckoId': 'ethst-governance-token',
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ODT',
            'name': 'ODT',
            'address': '0x991075Cc14A91Fe001528943B3EB6DF31CfF431F',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x991075Cc14A91Fe001528943B3EB6DF31CfF431F/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HFI',
            'name': 'HFI',
            'address': '0x98fc3b60Ed4A504F588342A53746405E355F9347',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x98fc3b60Ed4A504F588342A53746405E355F9347/logo.png',
            'coingeckoId': 'hecofi',
            'listedIn': [
                'sushiswap',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'sNULS',
            'name': 'sNULS',
            'address': '0x9812C79099418486838a218037D49b2f823F120c',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x9812C79099418486838a218037D49b2f823F120c/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SKM',
            'name': 'Heco-Peg SKM Token',
            'address': '0x96674f8da3F9c6ACb4A56b393AF9A490D70D16d0',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://hecoinfo.com/images/main/empty-token.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'NHA',
            'name': 'NHA',
            'address': '0x958788357d9C9f0C3eAc4BFDF6869aB94710734D',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x958788357d9C9f0C3eAc4BFDF6869aB94710734D/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'IDT',
            'name': 'IDT',
            'address': '0x950BFdda329d120f6763d3C7bEDb35d2880bF7Bf',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x950BFdda329d120f6763d3C7bEDb35d2880bF7Bf/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ITF',
            'name': 'ITF',
            'address': '0x943D97ad0b597F73425d6f740596D0efD3d62507',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x943D97ad0b597F73425d6f740596D0efD3d62507/logo.png',
            'coingeckoId': 'ins3-finance-coin',
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'USDCHECO',
            'name': 'Heco-Peg USDCHECO Token',
            'address': '0x9362Bbef4B8313A8Aa9f0c9808B80577Aa26B73B',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x9362Bbef4B8313A8Aa9f0c9808B80577Aa26B73B/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CRU',
            'name': 'CRU',
            'address': '0x926875854917C871EbBd44B75Ac33AC8386D4eD5',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x926875854917C871EbBd44B75Ac33AC8386D4eD5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GTLT',
            'name': 'GTLT',
            'address': '0x9234d966a3aC7436ad770fb6b340B424A90b0CBb',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x9234d966a3aC7436ad770fb6b340B424A90b0CBb/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'GDB',
            'name': 'GDB',
            'address': '0x9193754bA76c2DE16BeC8bE02590821cc9798acC',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x9193754bA76c2DE16BeC8bE02590821cc9798acC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WAR',
            'name': 'WAR',
            'address': '0x910651F81a605a6Ef35d05527d24A72fecef8bF0',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x910651F81a605a6Ef35d05527d24A72fecef8bF0/logo.png',
            'coingeckoId': 'westarter',
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'APYS',
            'name': 'APYS',
            'address': '0x90e8896b12a92D51CD213b681C2CaD83A9a6bD49',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x90e8896b12a92D51CD213b681C2CaD83A9a6bD49/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MX',
            'name': 'MX',
            'address': '0x8d854e603dc777337134286f5b3408261736a88F',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x8d854e603dc777337134286f5b3408261736a88F/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HBELT',
            'name': 'HBELT',
            'address': '0x8c517631a7cD20FC365aA9A4025955FcDAA11f57',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x8c517631a7cD20FC365aA9A4025955FcDAA11f57/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SKK',
            'name': 'SKK',
            'address': '0x8a3B7bCbcFA1cF18D415db34E8Ed39bDA95aCfc8',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x8a3B7bCbcFA1cF18D415db34E8Ed39bDA95aCfc8/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'LHB',
            'name': 'LendHub',
            'address': '0x8F67854497218043E1f72908FFE38D0Ed7F24721',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x8F67854497218043E1f72908FFE38D0Ed7F24721/logo.png',
            'coingeckoId': 'lendhub',
            'listedIn': [
                'sushiswap',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'EMJ',
            'name': 'EMJ',
            'address': '0x8DC9c7521cD538A9969A422E3f0f2A309713484a',
            'decimals': 12,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x8DC9c7521cD538A9969A422E3f0f2A309713484a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NT',
            'name': 'NT',
            'address': '0x8B70512b5248E7C1f0F6996E2FDE2E952708c4C9',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://ethapi.openocean.finance/logos/heco/0x8b70512b5248e7c1f0f6996e2fde2e952708c4c9.png',
            'coingeckoId': 'nextype-finance',
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'BLC',
            'name': 'Heco BLC Token',
            'address': '0x8AD1caE277B17Ca0Ad0393100E6EBd545B9367Cb',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x8AD1caE277B17Ca0Ad0393100E6EBd545B9367Cb/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ASS',
            'name': 'ASS',
            'address': '0x89F8E4473842e92441165c3506a0b909049f6Faa',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x89F8E4473842e92441165c3506a0b909049f6Faa/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HBC',
            'name': 'Heco-Peg HBC Token',
            'address': '0x894b2917c783514c9e4c24229bF60f3Cb4c9c905',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://ethapi.openocean.finance/logos/heco/0x894b2917c783514c9e4c24229bf60f3cb4c9c905.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean'
            ]
        },
        {
            'symbol': 'AS',
            'name': 'AS',
            'address': '0x891dAABF6de7a648C9665928E1097b808C1721e2',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x891dAABF6de7a648C9665928E1097b808C1721e2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HBO',
            'name': 'Hash Bridge Oracle',
            'address': '0x8764Bd4fcc027faF72bA83c0b2028a3BAE0D2D57',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x8764Bd4fcc027faF72bA83c0b2028a3BAE0D2D57/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'XFORCE',
            'name': 'XFORCE',
            'address': '0x8637ac40300296fE3e5C9E67f73AC1d16749A7bf',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x8637ac40300296fE3e5C9E67f73AC1d16749A7bf/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DMC',
            'name': 'Decentralized Mining Coin',
            'address': '0x854Bb58fDDa85F20b5aB20B20d888f0554c02560',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x854Bb58fDDa85F20b5aB20B20d888f0554c02560/logo.png',
            'coingeckoId': 'decentralized-mining-exchange',
            'listedIn': [
                'sushiswap',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ADA',
            'name': 'ADA',
            'address': '0x843Af718EF25708765a8E0942F89edEae1D88DF0',
            'decimals': 6,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x843Af718EF25708765a8E0942F89edEae1D88DF0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SIOO',
            'name': 'SIOO',
            'address': '0x81989cD57271565DBFfe9807E917Afc098B30c9A',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://ethapi.openocean.finance/logos/heco/0x81989cd57271565dbffe9807e917afc098b30c9a.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'DBC',
            'name': 'DBC',
            'address': '0x80DccC9BDC9b1659a226AE669457BD1e0b293A9b',
            'decimals': 8,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x80DccC9BDC9b1659a226AE669457BD1e0b293A9b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HTM',
            'name': 'HTM',
            'address': '0x80C66D460E2bB9d31a8De54b4016fcA986D0811F',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x80C66D460E2bB9d31a8De54b4016fcA986D0811F/logo.png',
            'coingeckoId': 'htm',
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'COW',
            'name': 'COW',
            'address': '0x80861A817106665bcA173DB6AC2ab628a738c737',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x80861A817106665bcA173DB6AC2ab628a738c737/logo.png',
            'coingeckoId': 'coinwind',
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SGB',
            'name': 'SGB',
            'address': '0x7f8AB1593f84B0C450385a3f70e921cB796401Ba',
            'decimals': 6,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x7f8AB1593f84B0C450385a3f70e921cB796401Ba/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HBS',
            'name': 'HBS',
            'address': '0x7d7063f3dca97eaE63545a266c77587dD0296090',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x7d7063f3dca97eaE63545a266c77587dD0296090/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ZT',
            'name': 'ZT',
            'address': '0x7d4B6CDCC6fD68Ab651c97d787aF462B78F55cD0',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x7d4B6CDCC6fD68Ab651c97d787aF462B78F55cD0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BSD',
            'name': 'BSD',
            'address': '0x7c657cc7B58f4fc8AC6d951d82D31C7dD370106D',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x7c657cc7B58f4fc8AC6d951d82D31C7dD370106D/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MAN',
            'name': 'MAN',
            'address': '0x7a45D615f26Da940fFFF43EcA1274d8EA116Ce0C',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x7a45D615f26Da940fFFF43EcA1274d8EA116Ce0C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PTT',
            'name': 'PTT',
            'address': '0x7Eed8a3ccfe3d507ec4Df443773eaE792b9eF2d7',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x7Eed8a3ccfe3d507ec4Df443773eaE792b9eF2d7/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'IFNT',
            'name': 'IFNT',
            'address': '0x7D4c107EC8fD251fD39c32199C70B76EaE4e67C1',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://ethapi.openocean.finance/logos/heco/0x7d4c107ec8fd251fd39c32199c70b76eae4e67c1.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'DORA',
            'name': 'DORA',
            'address': '0x7Ab088FedAE4FA8ada4Df638c07CEF6C23Aff002',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x7Ab088FedAE4FA8ada4Df638c07CEF6C23Aff002/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SNX',
            'name': 'Heco-Peg SNX Token',
            'address': '0x777850281719d5a96C29812ab72f822E0e09F3Da',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x777850281719d5a96C29812ab72f822E0e09F3Da/logo.png',
            'coingeckoId': 'havven',
            'listedIn': [
                'sushiswap',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'XY',
            'name': 'XY',
            'address': '0x770c3565daDB34edE50a226A21d1d0671A89BCFD',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x770c3565daDB34edE50a226A21d1d0671A89BCFD/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BIFI',
            'name': 'BIFI',
            'address': '0x765277EebeCA2e31912C9946eAe1021199B39C61',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x765277EebeCA2e31912C9946eAe1021199B39C61/logo.png',
            'coingeckoId': 'beefy-finance',
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MEL',
            'name': 'MEL',
            'address': '0x764B290D97Ca0119387133feA63391A765213dc5',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x764B290D97Ca0119387133feA63391A765213dc5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'AURA',
            'name': 'AURA',
            'address': '0x745e5A2f4dB6C78C71911E86E5978990a3630666',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://ethapi.openocean.finance/logos/heco/0x745e5a2f4db6c78c71911e86e5978990a3630666.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'COOK',
            'name': 'COOK',
            'address': '0x74189862B069e2Be5f7c8E6ff08eA8E1B1948519',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x74189862B069e2Be5f7c8E6ff08eA8E1B1948519/logo.png',
            'coingeckoId': 'cook',
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'LOM',
            'name': 'LOM',
            'address': '0x7404DB858261673d47bDdc2970a23d2163720a5F',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x7404DB858261673d47bDdc2970a23d2163720a5F/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HBC',
            'name': 'HBC',
            'address': '0x72bd642c5d097344814aFE23F7e6001F19f6204A',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x72bd642c5d097344814aFE23F7e6001F19f6204A/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'HNB',
            'name': 'HNB',
            'address': '0x704F96F04279ec8eB570e520EFcE56F9245CAc38',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x704F96F04279ec8eB570e520EFcE56F9245CAc38/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'UDog',
            'name': 'UDog',
            'address': '0x6aB015683914CD09A209b500f9CB24C39352973d',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x6aB015683914CD09A209b500f9CB24C39352973d/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'LLC',
            'name': 'LLC',
            'address': '0x6A4db3965CB6293dBA0F63F14FB36873172E38d3',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x6A4db3965CB6293dBA0F63F14FB36873172E38d3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'EDC',
            'name': 'EarnDefiCoin',
            'address': '0x68a0A1fEF18DfCC422Db8bE6F0F486dEa1999EDC',
            'decimals': 9,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x68a0A1fEF18DfCC422Db8bE6F0F486dEa1999EDC/logo.png',
            'coingeckoId': 'earndefi',
            'listedIn': [
                'sushiswap',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BAGS',
            'name': 'BAGS',
            'address': '0x6868D406a125Eb30886A6DD6B651D81677d1F22c',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x6868D406a125Eb30886A6DD6B651D81677d1F22c/logo.png',
            'coingeckoId': 'basis-gold-share-heco',
            'listedIn': [
                'sushiswap',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CMP',
            'name': 'CMP',
            'address': '0x681472b80D6E5c4fC27760A9b92E77d7EEA99679',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x681472b80D6E5c4fC27760A9b92E77d7EEA99679/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'Dogmoon',
            'name': 'Dogmoon',
            'address': '0x67BAB912eE30074cF9a94826E2e02D9936842781',
            'decimals': 9,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x67BAB912eE30074cF9a94826E2e02D9936842781/logo.png',
            'coingeckoId': 'dog-landing-on-the-moon',
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HBTC',
            'name': 'Heco-Peg HBTC Token',
            'address': '0x66a79D23E58475D2738179Ca52cd0b41d73f0BEa',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x66a79D23E58475D2738179Ca52cd0b41d73f0BEa/logo.png',
            'coingeckoId': 'huobi-btc',
            'listedIn': [
                'sushiswap',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'PNEO',
            'name': 'PNEO',
            'address': '0x6514a5Ebff7944099591Ae3e8A5c0979C83B2571',
            'decimals': 8,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x6514a5Ebff7944099591Ae3e8A5c0979C83B2571/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ETH',
            'name': 'Heco-Peg ETH Token',
            'address': '0x64FF637fB478863B7468bc97D30a5bF3A428a1fD',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x64FF637fB478863B7468bc97D30a5bF3A428a1fD/logo.png',
            'coingeckoId': 'huobi-ethereum',
            'listedIn': [
                'sushiswap',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'RSD',
            'name': 'RSD',
            'address': '0x61Ed1C66239d29Cc93C8597c6167159e8F69a823',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x61Ed1C66239d29Cc93C8597c6167159e8F69a823/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'UBT',
            'name': 'UBT',
            'address': '0x5c1a372b944f76E6464E3caaDE017C0860B252c5',
            'decimals': 3,
            'chainId': 128,
            'logoURI': 'https://ethapi.openocean.finance/logos/heco/0x5c1a372b944f76e6464e3caade017c0860b252c5.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'LLS',
            'name': 'LLS',
            'address': '0x5a42eeD7200d23F0D4CF35Ccd582D6d363F16BFc',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x5a42eeD7200d23F0D4CF35Ccd582D6d363F16BFc/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MFIL',
            'name': 'MFIL',
            'address': '0x5CDAFd00AfF95DA3304791A5C9aE00f84506a909',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x5CDAFd00AfF95DA3304791A5C9aE00f84506a909/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ARPA',
            'name': 'Heco-Peg ARPA Token',
            'address': '0x5A6B72Dd6209A770aE1C02a7A2E1900636072d0b',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://hecoinfo.com/images/main/empty-token.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'TXT',
            'name': 'TXT',
            'address': '0x58E24Ab51Ca52dac8d5413a061C83a371f8f241f',
            'decimals': 8,
            'chainId': 128,
            'logoURI': 'https://ethapi.openocean.finance/logos/heco/0x58e24ab51ca52dac8d5413a061c83a371f8f241f.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'COC',
            'name': 'COC',
            'address': '0x588694f56AF825b5A1Bb80e6ce1AAF08755064de',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x588694f56AF825b5A1Bb80e6ce1AAF08755064de/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'Lava',
            'name': 'Lavaswap',
            'address': '0x56f95662E71f30b333b456439248c6dE589082a4',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x56f95662E71f30b333b456439248c6dE589082a4/logo.png',
            'coingeckoId': 'lavaswap',
            'listedIn': [
                'sushiswap',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HHO',
            'name': 'HHO',
            'address': '0x56746a8099a7e6D962802A23e01FeDdc1282cDAe',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://ethapi.openocean.finance/logos/heco/0x56746a8099a7e6d962802a23e01feddc1282cdae.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'HTS',
            'name': 'Heco HTS Token',
            'address': '0x559c0af22D0d3efA9a8b95f2E4eaf6017aEa4c22',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x559c0af22D0d3efA9a8b95f2E4eaf6017aEa4c22/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WHT',
            'name': 'Wrapped HT',
            'address': '0x5545153CCFcA01fbd7Dd11C0b23ba694D9509A6F',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x5545153CCFcA01fbd7Dd11C0b23ba694D9509A6F/logo.png',
            'coingeckoId': 'wrapped-huobi-token',
            'listedIn': [
                'sushiswap',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ANY',
            'name': 'Anyswap',
            'address': '0x538CEe985E930557D16C383783ca957fA90b63b3',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x538CEe985E930557D16C383783ca957fA90b63b3/logo.png',
            'coingeckoId': 'anyswap',
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'PTD',
            'name': 'PTD',
            'address': '0x52Ee54dd7a68e9cf131b0a57fd6015C74d7140E2',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x52Ee54dd7a68e9cf131b0a57fd6015C74d7140E2/logo.png',
            'coingeckoId': 'pilot',
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SUSHI',
            'name': 'SUSHI',
            'address': '0x52E00B2dA5Bd7940fFe26B609A42F957f31118D5',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/sushi.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'XMPT',
            'name': 'XMPT',
            'address': '0x4fA8F0a59a47BeaBf950A8795933C01455Dd9C81',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x4fA8F0a59a47BeaBf950A8795933C01455Dd9C81/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NUT',
            'name': 'NUT',
            'address': '0x4f0A338DFa1C9369F62109C9022042e1F0aE479b',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x4f0A338DFa1C9369F62109C9022042e1F0aE479b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SLNV2',
            'name': 'SLN-Token V2',
            'address': '0x4e252342cf35Ff02c4CCA9bc655129f5b4a2f901',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x4e252342cf35Ff02c4CCA9bc655129f5b4a2f901/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CMC',
            'name': 'CMC',
            'address': '0x4bC94ffe38cA9BbbFbfd62eDba0aA5574418A65e',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x4bC94ffe38cA9BbbFbfd62eDba0aA5574418A65e/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BNB',
            'name': 'BNB',
            'address': '0x4F99d10e16972FF2FE315EEe53A95FC5A5870CE3',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x4F99d10e16972FF2FE315EEe53A95FC5A5870CE3/logo.png',
            'coingeckoId': 'heco-peg-bnb',
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CNNS',
            'name': 'Heco-Peg CNNS Token',
            'address': '0x4BF06f76C68D81BDE1F87535fdCb60Adadb01CF5',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://hecoinfo.com/images/main/empty-token.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'SOVI',
            'name': 'Sovi Token',
            'address': '0x49e16563a2ba84E560780946f0Fb73A8B32C841E',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x49e16563a2ba84E560780946f0Fb73A8B32C841E/logo.png',
            'coingeckoId': 'sovi-token',
            'listedIn': [
                'sushiswap',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ETET',
            'name': 'ETET',
            'address': '0x4911F439936b7E34453BffF4322520E23243cfA3',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x4911F439936b7E34453BffF4322520E23243cfA3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DEP',
            'name': 'DEP',
            'address': '0x48C859531254F25e57D1C1A8E030Ef0B1c895c27',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x48C859531254F25e57D1C1A8E030Ef0B1c895c27/logo.png',
            'coingeckoId': 'depth-token',
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FEI',
            'name': 'FEI',
            'address': '0x485cdBFF08A4F91a16689E73893a11ae8B76af6D',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x485cdBFF08A4F91a16689E73893a11ae8B76af6D/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'COPR',
            'name': 'COPR',
            'address': '0x46EFB7724364ded21b3598519D754323A4f66EC9',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x46EFB7724364ded21b3598519D754323A4f66EC9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'sMDX',
            'name': 'sMDX',
            'address': '0x46914A848405fd79a0B937F388936C9798bF6eAC',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x46914A848405fd79a0B937F388936C9798bF6eAC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HXTZ',
            'name': 'Heco-Peg HXTZ Token',
            'address': '0x45e97daD828AD735af1dF0473fc2735F0Fd5330c',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x45e97daD828AD735af1dF0473fc2735F0Fd5330c/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ORB',
            'name': 'ORB',
            'address': '0x440ab529708ce0aF5bb3a4d77cfd270BEED701C5',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x440ab529708ce0aF5bb3a4d77cfd270BEED701C5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DFT',
            'name': 'DFT',
            'address': '0x42712dF5009c20fee340B245b510c0395896cF6e',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x42712dF5009c20fee340B245b510c0395896cF6e/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DBS',
            'name': 'DBS',
            'address': '0x414fE43435086924b7b5A32D0218EE3a65bbfbeb',
            'decimals': 8,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x414fE43435086924b7b5A32D0218EE3a65bbfbeb/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SHARE',
            'name': 'SHARE',
            'address': '0x41198c6736d23734E6d17a6F0B4f2D15d2621A3a',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x41198c6736d23734E6d17a6F0B4f2D15d2621A3a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'DOGE',
            'name': 'DOGE',
            'address': '0x40280E26A572745B1152A54D1D44F365DaA51618',
            'decimals': 8,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x40280E26A572745B1152A54D1D44F365DaA51618/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SH',
            'name': 'SH',
            'address': '0x3dc2536480775d0aB5d1D010B853cd2166cf6e11',
            'decimals': 12,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x3dc2536480775d0aB5d1D010B853cd2166cf6e11/logo.png',
            'coingeckoId': 'super-hero',
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'WOO',
            'name': 'WOO',
            'address': '0x3beFB2308bcE92dA97264077fAf37dcd6C8A75E6',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x3beFB2308bcE92dA97264077fAf37dcd6C8A75E6/logo.png',
            'coingeckoId': 'woo-network',
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HYN',
            'name': 'HYN',
            'address': '0x3aC19481FAce71565155F370B3E34A1178745382',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x3aC19481FAce71565155F370B3E34A1178745382/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DAIHECO',
            'name': 'Heco-Peg DAIHECO Token',
            'address': '0x3D760a45D0887DFD89A2F5385a236B29Cb46ED2a',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x3D760a45D0887DFD89A2F5385a236B29Cb46ED2a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SGT',
            'name': 'SGT',
            'address': '0x3Ca3d376BFe88510712B3E8CA5AEE2cfd8c3d956',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x3Ca3d376BFe88510712B3E8CA5AEE2cfd8c3d956/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'pUSDT',
            'name': 'pUSDT',
            'address': '0x38C499dd2a14Af3b695901bcEC76961008BBC227',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x38C499dd2a14Af3b695901bcEC76961008BBC227/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'TRIBE',
            'name': 'TRIBE',
            'address': '0x38999Fa3a7320bD2c3609BF0f8cB5CD4C11D3Fe1',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x38999Fa3a7320bD2c3609BF0f8cB5CD4C11D3Fe1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HIS',
            'name': 'HIS',
            'address': '0x3513F22a33357Ba82373680B14f0B50642d0709d',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x3513F22a33357Ba82373680B14f0B50642d0709d/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BKK',
            'name': 'BKK',
            'address': '0x350aE659B0010895b9E18400e9356B43BABc9139',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x350aE659B0010895b9E18400e9356B43BABc9139/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MKR',
            'name': 'MKR',
            'address': '0x34D75515090902a513F009f4505A750efaaD63b0',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x34D75515090902a513F009f4505A750efaaD63b0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SWFTC',
            'name': 'Heco-Peg SWFTC Token',
            'address': '0x329dda64Cbc4DFD5FA5072b447B3941CE054ebb3',
            'decimals': 8,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x329dda64Cbc4DFD5FA5072b447B3941CE054ebb3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'sNEO',
            'name': 'sNEO',
            'address': '0x2f92C6e8ed836B5d507251b59d816A55F1B853cC',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x2f92C6e8ed836B5d507251b59d816A55F1B853cC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HWIN',
            'name': 'HWIN',
            'address': '0x2eFFC887B3195D7c8f3966A07c421D828E8F8AeE',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x2eFFC887B3195D7c8f3966A07c421D828E8F8AeE/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GOF',
            'name': 'Heco-Peg GOF Token',
            'address': '0x2AAFe3c9118DB36A20dd4A942b6ff3e78981dce1',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x2AAFe3c9118DB36A20dd4A942b6ff3e78981dce1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SHARE',
            'name': 'SHARE',
            'address': '0x29E8e0055Cd5C41bDff16A649Be2D1e653901625',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x29E8e0055Cd5C41bDff16A649Be2D1e653901625/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MDX',
            'name': 'MDX Token',
            'address': '0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x25D2e80cB6B86881Fd7e07dd263Fb79f4AbE033c/logo.png',
            'coingeckoId': 'mdex',
            'listedIn': [
                'sushiswap',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'AFT',
            'name': 'AFT',
            'address': '0x25032dBfC1C889ECC78ef1ADCfdf65e45A3E02EC',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x25032dBfC1C889ECC78ef1ADCfdf65e45A3E02EC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'UNI',
            'name': 'Heco-Peg UNI Token',
            'address': '0x22C54cE8321A4015740eE1109D9cBc25815C46E6',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x22C54cE8321A4015740eE1109D9cBc25815C46E6/logo.png',
            'coingeckoId': 'uniswap',
            'listedIn': [
                'sushiswap',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'AAVE',
            'name': 'Heco-Peg AAVE Token',
            'address': '0x202b4936fE1a82A4965220860aE46d7d3939Bb25',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x202b4936fE1a82A4965220860aE46d7d3939Bb25/logo.png',
            'coingeckoId': 'aave',
            'listedIn': [
                'sushiswap',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SID',
            'name': 'SID',
            'address': '0x1f7E5d3ACf238674Df0Cbfb64f66428dbAB3D442',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x1f7E5d3ACf238674Df0Cbfb64f66428dbAB3D442/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'CAN',
            'name': 'Channels',
            'address': '0x1e6395E6B059fc97a4ddA925b6c5ebf19E05c69f',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x1e6395E6B059fc97a4ddA925b6c5ebf19E05c69f/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HTC',
            'name': 'Heco HTC Token',
            'address': '0x1d9B88ffFb7B78De890729aF67B68aec2eA57D52',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x1d9B88ffFb7B78De890729aF67B68aec2eA57D52/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FCS',
            'name': 'Heco FCS Token',
            'address': '0x1b31b55cD94493d06C7a51678fb1Fe8963258A5d',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x1b31b55cD94493d06C7a51678fb1Fe8963258A5d/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'RDTK',
            'name': 'RDTK',
            'address': '0x1E0aBaF926013fAdC7da19aD41665bb753639A94',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x1E0aBaF926013fAdC7da19aD41665bb753639A94/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MATTER',
            'name': 'MATTER',
            'address': '0x1C9491865a1DE77C5b6e19d2E6a5F1D7a6F2b25F',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x1C9491865a1DE77C5b6e19d2E6a5F1D7a6F2b25F/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'kungfu',
            'name': 'kungfu',
            'address': '0x1B625dd82aEb3aac21398ea9933fBd56e9652383',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x1B625dd82aEb3aac21398ea9933fBd56e9652383/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NIO',
            'name': 'NIO',
            'address': '0x19EcE5243ED8CBF4b4aabc7F897881EB5759FB7b',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x19EcE5243ED8CBF4b4aabc7F897881EB5759FB7b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DOJO',
            'name': 'DOJO',
            'address': '0x180dAe91D6d56235453a892d2e56a3E40Ba81DF8',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x180dAe91D6d56235453a892d2e56a3E40Ba81DF8/logo.png',
            'coingeckoId': 'dojo',
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MOMAT',
            'name': 'MOMAT',
            'address': '0x162EDC778dfD179A1E54E4bCAAf650Dc293BB2C9',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://ethapi.openocean.finance/logos/heco/0x162edc778dfd179a1e54e4bcaaf650dc293bb2c9.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean'
            ]
        },
        {
            'symbol': 'RING',
            'name': 'RING',
            'address': '0x15e65456310ecb216B51EfBd8a1dBf753353DcF9',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x15e65456310ecb216B51EfBd8a1dBf753353DcF9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BXC',
            'name': 'BXC',
            'address': '0x15F4c382926F8A083bC924AbD3aa84Ce3982CAa9',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x15F4c382926F8A083bC924AbD3aa84Ce3982CAa9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'KIKA',
            'name': 'KIKA',
            'address': '0x14FADc277f18f848B10d120BA723A3f0f4189a05',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x14FADc277f18f848B10d120BA723A3f0f4189a05/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FC',
            'name': 'Heco FC Token',
            'address': '0x12fAC6B5Fa0bc6250058D99d3a1727628651c12E',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x12fAC6B5Fa0bc6250058D99d3a1727628651c12E/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'NDFIL',
            'name': 'NDFIL',
            'address': '0x10C41DBf49B935f268b6cAb0A0a9e9b62a654BDe',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x10C41DBf49B935f268b6cAb0A0a9e9b62a654BDe/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'AAB',
            'name': 'AAB',
            'address': '0x0f73280A282F3B87a3ae2e343cEb6A91e7D87Acd',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x0f73280A282F3B87a3ae2e343cEb6A91e7D87Acd/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'GDT',
            'name': 'GDT',
            'address': '0x0F1ed0E22a74A90D92746A1794394DA117e4De40',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x0F1ed0E22a74A90D92746A1794394DA117e4De40/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'YF',
            'name': 'YF',
            'address': '0x0D1cde65E2DBa76D08c29867Aa3bC1b84e1E3AEd',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x0D1cde65E2DBa76D08c29867Aa3bC1b84e1E3AEd/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'DOG',
            'name': 'DOG',
            'address': '0x099626783842d35C221E5d01694C2B928eB3B0AD',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x099626783842d35C221E5d01694C2B928eB3B0AD/logo.png',
            'coingeckoId': 'dogswap-token',
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'KISHU',
            'name': 'KISHU',
            'address': '0x06943F317B0111BD5d3EFF7E83F33b05914721c5',
            'decimals': 9,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x06943F317B0111BD5d3EFF7E83F33b05914721c5/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'RP',
            'name': 'RP',
            'address': '0x057899Dd6FcB69b23f3b4DC6c7c2bFA4A8a0b0EE',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x057899Dd6FcB69b23f3b4DC6c7c2bFA4A8a0b0EE/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ONE',
            'name': 'ONE',
            'address': '0x04BAf95Fd4C52fd09a56D840bAEe0AB8D7357bf0',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x04BAf95Fd4C52fd09a56D840bAEe0AB8D7357bf0/logo.png',
            'coingeckoId': 'one',
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BAL',
            'name': 'Heco-Peg BAL Token',
            'address': '0x045De15Ca76e76426E8Fc7cba8392A3138078D0F',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x045De15Ca76e76426E8Fc7cba8392A3138078D0F/logo.png',
            'coingeckoId': 'balancer',
            'listedIn': [
                'sushiswap',
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SWF',
            'name': 'SWF',
            'address': '0x0302A9778A61ABd0c9cc1A8FB8f9246f3fBCd18F',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x0302A9778A61ABd0c9cc1A8FB8f9246f3fBCd18F/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'openocean',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HUSD',
            'name': 'Heco-Peg HUSD Token',
            'address': '0x0298c2b32eaE4da002a15f36fdf7615BEa3DA047',
            'decimals': 8,
            'chainId': 128,
            'logoURI': 'https://elk.finance/tokens/logos/heco/0x0298c2b32eaE4da002a15f36fdf7615BEa3DA047/logo.png',
            'coingeckoId': 'husd',
            'listedIn': [
                'sushiswap',
                'elkfinance'
            ]
        },
        {
            'symbol': 'HT',
            'name': 'Huobi Token',
            'address': '0x0000000000000000000000000000000000000000',
            'decimals': 18,
            'chainId': 128,
            'logoURI': 'https://ethapi.openocean.finance/logos/heco/0x0000000000000000000000000000000000000000.png',
            'coingeckoId': 'huobi',
            'listedIn': [
                'openocean'
            ]
        }
    ],
    '1287': [
        {
            'symbol': 'WGLMR',
            'name': 'Wrapped Glimmer',
            'address': '0xe73763DB808ecCDC0E36bC8E32510ED126910394',
            'decimals': 18,
            'chainId': 1287,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/6836.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'ERC20S',
            'name': 'Custom ERC20 Token',
            'address': '0xd222a876B303dAe4F8e2099ba3B26ECB5A1b8521',
            'decimals': 18,
            'chainId': 1287,
            'logoURI': 'https://s2.coinmarketcap.com/static/img/coins/64x64/6836.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        }
    ],
    '65': [
        {
            'symbol': 'USDT',
            'name': 'Tether',
            'address': '0xe579156f9dEcc4134B5E3A30a24Ac46BB8B01281',
            'decimals': 10,
            'chainId': 65,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'WOKT',
            'name': 'Wrapped OKExChain',
            'address': '0x2219845942d28716c0F7C605765fABDcA1a7d9E0',
            'decimals': 18,
            'chainId': 65,
            'logoURI': 'https://assets.coingecko.com/coins/images/13708/small/OKExChain.jpg?1611051839',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        }
    ],
    '11297108109': [
        {
            'symbol': 'WPALM',
            'name': 'Wrapped Palm',
            'address': '0xF98cABF0a963452C5536330408B2590567611a71',
            'decimals': 18,
            'chainId': 11297108109,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/palm.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'WETH',
            'name': 'Wrapped Ether',
            'address': '0x726138359C17F1E56bA8c4F737a7CAf724F6010b',
            'decimals': 18,
            'chainId': 11297108109,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/eth.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'PRT',
            'name': 'Portion',
            'address': '0x5eE074D24f462E0311814C992c99a178458C39fc',
            'decimals': 18,
            'chainId': 11297108109,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/unknown.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'DAI',
            'name': 'Dai Stablecoin',
            'address': '0x4C1f6fCBd233241bF2f4D02811E3bF8429BC27B8',
            'decimals': 18,
            'chainId': 11297108109,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/icons/master/token/dai.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        }
    ],
    '40': [
        {
            'symbol': 'ETH',
            'name': 'Ether',
            'address': '0xfA9343C3897324496A05fC75abeD6bAC29f8A40f',
            'decimals': 18,
            'chainId': 40,
            'logoURI': 'https://elk.finance/tokens/logos/telos/0xfA9343C3897324496A05fC75abeD6bAC29f8A40f/logo.png',
            'coingeckoId': 'weth',
            'listedIn': [
                'sushiswap',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BTC',
            'name': 'Bitcoin',
            'address': '0xf390830DF829cf22c53c8840554B98eafC5dCBc2',
            'decimals': 8,
            'chainId': 40,
            'logoURI': 'https://elk.finance/tokens/logos/telos/0xf390830DF829cf22c53c8840554B98eafC5dCBc2/logo.png',
            'coingeckoId': 'wrapped-bitcoin',
            'listedIn': [
                'sushiswap',
                'elkfinance'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'Tether USD',
            'address': '0xeFAeeE334F0Fd1712f9a8cc375f427D9Cdd40d73',
            'decimals': 6,
            'chainId': 40,
            'logoURI': 'https://elk.finance/tokens/logos/telos/0xeFAeeE334F0Fd1712f9a8cc375f427D9Cdd40d73/logo.png',
            'coingeckoId': 'tether',
            'listedIn': [
                'sushiswap',
                'elkfinance'
            ]
        },
        {
            'symbol': 'ELK',
            'name': 'Elk',
            'address': '0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE',
            'decimals': 18,
            'chainId': 40,
            'logoURI': 'https://elk.finance/tokens/logos/telos/0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE/logo.png',
            'coingeckoId': 'elk-finance',
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'CHARM',
            'name': 'Charm',
            'address': '0xd2504a02fABd7E546e41aD39597c377cA8B0E1Df',
            'decimals': 18,
            'chainId': 40,
            'logoURI': 'https://elk.finance/tokens/logos/telos/0xd2504a02fABd7E546e41aD39597c377cA8B0E1Df/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'DOUGE',
            'name': 'Dougecoin',
            'address': '0xc6BC7A8dfA0f57Fe7746Ac434c01cD39679b372c',
            'decimals': 18,
            'chainId': 40,
            'logoURI': 'https://elk.finance/tokens/logos/telos/0xc6BC7A8dfA0f57Fe7746Ac434c01cD39679b372c/logo.png',
            'coingeckoId': 'douge',
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'oELK',
            'name': 'Old Elk',
            'address': '0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C',
            'decimals': 18,
            'chainId': 40,
            'logoURI': 'https://elk.finance/tokens/logos/telos/0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'WTLOS',
            'name': 'Wrapped Telos',
            'address': '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
            'decimals': 18,
            'chainId': 40,
            'logoURI': 'https://elk.finance/tokens/logos/telos/0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E/logo.png',
            'coingeckoId': 'wrapped-telos',
            'listedIn': [
                'sushiswap',
                'elkfinance'
            ]
        },
        {
            'symbol': 'FTM',
            'name': 'Fantom',
            'address': '0xC1Be9a4D5D45BeeACAE296a7BD5fADBfc14602C4',
            'decimals': 18,
            'chainId': 40,
            'logoURI': 'https://elk.finance/tokens/logos/telos/0xC1Be9a4D5D45BeeACAE296a7BD5fADBfc14602C4/logo.png',
            'coingeckoId': 'wrapped-fantom',
            'listedIn': [
                'sushiswap',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SUSHI',
            'name': 'SushiToken',
            'address': '0x922D641a426DcFFaeF11680e5358F34d97d112E1',
            'decimals': 18,
            'chainId': 40,
            'logoURI': 'https://elk.finance/tokens/logos/telos/0x922D641a426DcFFaeF11680e5358F34d97d112E1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap',
                'elkfinance'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USD Coin',
            'address': '0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b',
            'decimals': 6,
            'chainId': 40,
            'logoURI': 'https://elk.finance/tokens/logos/telos/0x818ec0A7Fe18Ff94269904fCED6AE3DaE6d6dC0b/logo.png',
            'coingeckoId': 'usd-coin',
            'listedIn': [
                'sushiswap',
                'elkfinance'
            ]
        },
        {
            'symbol': 'AVAX',
            'name': 'Avalanche',
            'address': '0x7C598c96D02398d89FbCb9d41Eab3DF0C16F227D',
            'decimals': 18,
            'chainId': 40,
            'logoURI': 'https://elk.finance/tokens/logos/telos/0x7C598c96D02398d89FbCb9d41Eab3DF0C16F227D/logo.png',
            'coingeckoId': 'wrapped-avax',
            'listedIn': [
                'sushiswap',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SC',
            'name': 'ShibaTelos Coin',
            'address': '0x76aE0b4C828DdCa1841a4FE394Af5D8679Baf118',
            'decimals': 9,
            'chainId': 40,
            'logoURI': 'https://raw.githubusercontent.com/sushiswap/logos/main/network/telos/0x76aE0b4C828DdCa1841a4FE394Af5D8679Baf118.jpg',
            'coingeckoId': null,
            'listedIn': [
                'sushiswap'
            ]
        },
        {
            'symbol': 'MATIC',
            'name': 'Polygon',
            'address': '0x332730a4F6E03D9C55829435f10360E13cfA41Ff',
            'decimals': 18,
            'chainId': 40,
            'logoURI': 'https://elk.finance/tokens/logos/telos/0x332730a4F6E03D9C55829435f10360E13cfA41Ff/logo.png',
            'coingeckoId': 'wmatic',
            'listedIn': [
                'sushiswap',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BNB',
            'name': 'Binance Coin',
            'address': '0x2C78f1b70Ccf63CDEe49F9233e9fAa99D43AA07e',
            'decimals': 18,
            'chainId': 40,
            'logoURI': 'https://elk.finance/tokens/logos/telos/0x2C78f1b70Ccf63CDEe49F9233e9fAa99D43AA07e/logo.png',
            'coingeckoId': 'wbnb',
            'listedIn': [
                'sushiswap',
                'elkfinance'
            ]
        },
        {
            'symbol': 'TSQRL',
            'name': 'T-Squirrel NFT',
            'address': '0x20e757800e04b370FD1Ed8326709aDFf889d2d6B',
            'decimals': 18,
            'chainId': 40,
            'logoURI': 'https://elk.finance/tokens/logos/telos/0x20e757800e04b370FD1Ed8326709aDFf889d2d6B/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        }
    ],
    '69': [
        {
            'symbol': 'DCN',
            'name': 'Dentacoin',
            'address': '0xfD6ab60E0744e95c35FB17AFDA478eeAE3835dDf',
            'decimals': 0,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/DCN/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'SPANK',
            'name': 'SPANK',
            'address': '0xcfD1D50ce23C46D3Cf6407487B2F8934e96DC8f9',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/SPANK/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'SDL',
            'name': 'Saddle DAO',
            'address': '0xc96f4F893286137aC17e07Ae7F217fFca5db3AB6',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/SDL/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'BAL',
            'name': 'Balancer',
            'address': '0xc72751efd79B153D5bdC7E1a43b4b98aA2aA04C7',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/BAL/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'GTC',
            'name': 'Gitcoin',
            'address': '0xaf8cA653Fa2772d58f4368B0a71980e9E3cEB888',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/GTC/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'USX',
            'name': 'dForce USD',
            'address': '0xab7020476D814C52629ff2e4cebC7A8cdC04F18E',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/USX/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'sUSD',
            'name': 'Synthetix USD',
            'address': '0xaA5068dC2B3AADE533d3e52C6eeaadC6a8154c57',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/sUSD/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'TUSD',
            'name': 'TrueUSD',
            'address': '0xFa956eB0c4b3E692aD5a6B2f08170aDE55999ACa',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/TUSD/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'wstETH',
            'name': 'Wrapped liquid staked Ether 2.0',
            'address': '0xF9C842dE4381a70eB265d10CF8D43DceFF5bA935',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/wstETH/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'aCRV',
            'name': 'AladdinCRV',
            'address': '0xF882Ac9259a378e102F058f2Fb093DFfECa96437',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/aCRV/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'CTSI',
            'name': 'Cartesi Token',
            'address': '0xEc6adef5E1006bb305bB1975333e8fc4071295bf',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/CTSI/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'ETH',
            'name': 'Ether',
            'address': '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/ETH/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'DAI',
            'name': 'Dai Stablecoin',
            'address': '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/DAI/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'BAXA',
            'name': 'BAXagent Coin',
            'address': '0xD7eA82D19f1f59FF1aE95F1945Ee6E6d86A25B96',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/BAXA/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'WAD',
            'name': 'WardenSwap',
            'address': '0xD1917629B3E6A72E6772Aab5dBe58Eb7FA3C2F33',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/WAD/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'UBI',
            'name': 'Universal Basic Income',
            'address': '0xBb586Ed34974B15049a876fd5366A4C2d1203115',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/UBI/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'BOND',
            'name': 'BarnBridge Governance Token',
            'address': '0x9e5AAC1Ba1a2e6aEd6b32689DFcF62A509Ca96f3',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/BOND/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'KROM',
            'name': 'Kromatika',
            'address': '0x9e1028F5F1D5eDE59748FFceE5532509976840E0',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/KROM/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'EQZ',
            'name': 'Equalizer',
            'address': '0x8Ee73c484A26e0A5df2Ee2a4960B789967dd0415',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/EQZ/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'SUKU',
            'name': 'SUKU',
            'address': '0x871f2F2ff935FD1eD867842FF2a7bfD051A5E527',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/SUKU/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'BitBTC',
            'name': 'BitBTC',
            'address': '0x83643C9eF0c5707A7815124754d0828c9A38be3a',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/BitBTC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'KNC',
            'name': 'Kyber Network Crystal v2',
            'address': '0x83251BD7EbB55f9CED617030107cE32ef06F54f8',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/KNC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'Tether USD',
            'address': '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
            'decimals': 6,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/USDT/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'AAVE',
            'name': 'Aave Token',
            'address': '0x76FB31fb4af56892A25e32cFC43De717950c9278',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/AAVE/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'RAI',
            'name': 'Rai Reflex Index',
            'address': '0x743224E4822710A3e40d754244f3e0f1dB2e5d8F',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/RAI/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'EURT',
            'name': 'Tether EUR',
            'address': '0x65e44970ebFe42f98F83c4B67062DE94B9f3Da7D',
            'decimals': 6,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/EURT/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'TRB',
            'name': 'Tellor',
            'address': '0x5e70AfFE232e2919792f77EB94e566db0320fa88',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/TRB/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'UNI',
            'name': 'Uniswap',
            'address': '0x5e31B81eaFba4b9371e77F34d6f3DA8091C3F2a0',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/UNI/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': '0xBTC',
            'name': '0xBitcoin',
            'address': '0x56B4F5f44d348EC3F07Bf1f31A3B566E5304BedE',
            'decimals': 8,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/0xBTC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'PERP',
            'name': 'Perpetual',
            'address': '0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/PERP/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USD Coin',
            'address': '0x4e62882864fB8CE54AFfcAf8D899A286762B011B',
            'decimals': 6,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/USDC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'LINK',
            'name': 'Chainlink',
            'address': '0x4911b761993b9c8c0d14Ba2d86902AF6B0074F5B',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/LINK/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'OP',
            'name': 'Optimism',
            'address': '0x4200000000000000000000000000000000000042',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/OP/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'DF',
            'name': 'dForce',
            'address': '0x3e7eF8f50246f725885102E8238CBba33F276747',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/DF/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'LIZ',
            'name': 'Theranos Coin',
            'address': '0x3bB4445D30AC020a84c1b5A8A2C6248ebC9779D0',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/LIZ/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'TheDAO',
            'name': 'TheDAO',
            'address': '0x35597dC6f8fDc81d71b311A9E4e2710ef6accb68',
            'decimals': 16,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/TheDAO/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'LAND',
            'name': 'Land',
            'address': '0x2E2015aa4ac40B083189A03a75AD229dfB10172c',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/LAND/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'WBTC',
            'name': 'Wrapped BTC L2',
            'address': '0x2382a8f65b9120E554d1836a504808aC864E169d',
            'decimals': 8,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/WBTC/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'BitANT',
            'name': 'BitANT',
            'address': '0x1f748732AF4442Cf508DEf0882ad9fcB5E5205a2',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/BitANT/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'USDD',
            'name': 'Decentralized USD',
            'address': '0x1F9bD96DDB4Bd07d6061f8933e9bA9EDE9967550',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/USDD/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'GYSR',
            'name': 'Geyser',
            'address': '0x197D38DC562DfB2490eC1A1d5C4CC4319d178Bb4',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/GYSR/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'RFWSTETH',
            'name': 'Respawn Finance Wrapped Staked Ethereum',
            'address': '0x1696baFBe18EFB141823a041E22E43d12B144407',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/RFWSTETH/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'DOLA',
            'name': 'Dola USD Stablecoin',
            'address': '0x0D760ee479401Bb4C40BDB7604b329FfF411b3f2',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/DOLA/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'SNX',
            'name': 'Synthetix',
            'address': '0x0064A673267696049938AA47595dD0B3C2e705A1',
            'decimals': 18,
            'chainId': 69,
            'logoURI': 'https://ethereum-optimism.github.io/data/SNX/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        }
    ],
    '420': [
        {
            'symbol': 'INSURE',
            'name': 'InsureToken',
            'address': '0xeF26Ca2844b0b470AfC1b0aeF5F65e677dE6CD1f',
            'decimals': 18,
            'chainId': 420,
            'logoURI': 'https://ethereum-optimism.github.io/data/INSURE/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'sUSD',
            'name': 'Synthetix USD',
            'address': '0xeBaEAAD9236615542844adC5c149F86C36aD1136',
            'decimals': 18,
            'chainId': 420,
            'logoURI': 'https://ethereum-optimism.github.io/data/sUSD/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'WBTC',
            'name': 'Wrapped BTC L2',
            'address': '0xe0a592353e81a94Db6E3226fD4A99F881751776a',
            'decimals': 8,
            'chainId': 420,
            'logoURI': 'https://ethereum-optimism.github.io/data/WBTC/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'LINK',
            'name': 'Chainlink',
            'address': '0xdc2CC710e42857672E7907CF474a69B63B93089f',
            'decimals': 18,
            'chainId': 420,
            'logoURI': 'https://ethereum-optimism.github.io/data/LINK/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'TESTLDO',
            'name': 'TEST Lido DAO Token',
            'address': '0xE265FC71d45fd791c9ebf3EE0a53FBB220Eb8f75',
            'decimals': 18,
            'chainId': 420,
            'logoURI': 'https://ethereum-optimism.github.io/data/LDO/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'ETH',
            'name': 'Ether',
            'address': '0xDeadDeAddeAddEAddeadDEaDDEAdDeaDDeAD0000',
            'decimals': 18,
            'chainId': 420,
            'logoURI': 'https://ethereum-optimism.github.io/data/ETH/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'DAI',
            'name': 'Dai Stablecoin',
            'address': '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
            'decimals': 18,
            'chainId': 420,
            'logoURI': 'https://ethereum-optimism.github.io/data/DAI/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'HAN',
            'name': 'HanChain',
            'address': '0x9e1028F5F1D5eDE59748FFceE5532509976840E0',
            'decimals': 18,
            'chainId': 420,
            'logoURI': 'https://ethereum-optimism.github.io/data/HAN/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'Tether USD',
            'address': '0x853eb4bA5D0Ba2B77a0A5329Fd2110d5CE149ECE',
            'decimals': 6,
            'chainId': 420,
            'logoURI': 'https://ethereum-optimism.github.io/data/USDT/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USD Coin',
            'address': '0x7E07E15D2a87A24492740D16f5bdF58c16db0c4E',
            'decimals': 6,
            'chainId': 420,
            'logoURI': 'https://ethereum-optimism.github.io/data/USDC/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'MOCHI',
            'name': 'Mochi',
            'address': '0x7Af72aD5E8793023DC722d617fe3338ac6b5ccE9',
            'decimals': 18,
            'chainId': 420,
            'logoURI': 'https://ethereum-optimism.github.io/data/MOCHI/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'MOM',
            'name': 'Monetum',
            'address': '0x4621b7A9c75199271F773Ebd9A499dbd165c3191',
            'decimals': 18,
            'chainId': 420,
            'logoURI': 'https://ethereum-optimism.github.io/data/MOM/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'OUTb',
            'name': 'OptimismUselessToken-Bridged',
            'address': '0x3e7eF8f50246f725885102E8238CBba33F276747',
            'decimals': 18,
            'chainId': 420,
            'logoURI': 'https://ethereum-optimism.github.io/data/OUTb/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'veKWENTA',
            'name': 'veKwenta',
            'address': '0x3e52b5f840eafD79394c6359E93Bf3FfdAE89ee4',
            'decimals': 18,
            'chainId': 420,
            'logoURI': 'https://ethereum-optimism.github.io/data/veKWENTA/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'WMP',
            'name': 'WAMP',
            'address': '0x35D48A789904E9b15705977192e5d95e2aF7f1D3',
            'decimals': 18,
            'chainId': 420,
            'logoURI': 'https://ethereum-optimism.github.io/data/WMP/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'SNX',
            'name': 'Synthetix',
            'address': '0x2E5ED97596a8368EB9E44B1f3F25B2E813845303',
            'decimals': 18,
            'chainId': 420,
            'logoURI': 'https://ethereum-optimism.github.io/data/SNX/logo.svg',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        },
        {
            'symbol': 'PERP',
            'name': 'Perpetual',
            'address': '0x0994206dfE8De6Ec6920FF4D779B0d950605Fb53',
            'decimals': 18,
            'chainId': 420,
            'logoURI': 'https://ethereum-optimism.github.io/data/PERP/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'optimism'
            ]
        }
    ],
    '16': [
        {
            'symbol': 'PCT',
            'name': 'Pangolin',
            'address': '0x0A8744c2a48b0e09a3e4C3A381A8E0d8d900bAFe',
            'decimals': 18,
            'chainId': 16,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/16/0x0A8744c2a48b0e09a3e4C3A381A8E0d8d900bAFe/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin'
            ]
        }
    ],
    '11111': [
        {
            'symbol': 'OG',
            'name': 'OG',
            'address': '0xf1db872E6454D553686b088c1Ea3889cF2FE3ABe',
            'decimals': 18,
            'chainId': 11111,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/11111/0xf1db872E6454D553686b088c1Ea3889cF2FE3ABe/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin'
            ]
        },
        {
            'symbol': 'wagmiPNG',
            'name': 'Pangolin',
            'address': '0xbdf33c7128fBB220fc0e4Be277697cEeef8BdfF5',
            'decimals': 18,
            'chainId': 11111,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/11111/0xbdf33c7128fBB220fc0e4Be277697cEeef8BdfF5/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin'
            ]
        },
        {
            'symbol': 'staySAFU',
            'name': 'staySAFU',
            'address': '0x4eaA03A9C9c9CE745517538d262653B9e43c51f2',
            'decimals': 18,
            'chainId': 11111,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/11111/0x4eaA03A9C9c9CE745517538d262653B9e43c51f2/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin'
            ]
        },
        {
            'symbol': 'wWAGMI',
            'name': 'Wrapped WAGMI',
            'address': '0x3Ee7094DADda15810F191DD6AcF7E4FFa37571e4',
            'decimals': 18,
            'chainId': 11111,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/11111/0x3Ee7094DADda15810F191DD6AcF7E4FFa37571e4/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin'
            ]
        },
        {
            'symbol': 'WAVAX',
            'name': 'Wrapped AVAX',
            'address': '0x21cf0eB2E3Ab483a67C900b27dA8F34185991982',
            'decimals': 18,
            'chainId': 11111,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/11111/0x21cf0eB2E3Ab483a67C900b27dA8F34185991982/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin'
            ]
        }
    ],
    '19': [
        {
            'symbol': 'COOT',
            'name': 'Cootie Coin',
            'address': '0xe4671844Fcb3cA9A80A1224B6f9A0A6c2Ba2a7d5',
            'decimals': 18,
            'chainId': 19,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/19/0xe4671844Fcb3cA9A80A1224B6f9A0A6c2Ba2a7d5/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin'
            ]
        },
        {
            'symbol': 'PSB',
            'name': 'Pangolin Songbird',
            'address': '0xb2987753D1561570f726Aa373F48E77e27aa5FF4',
            'decimals': 18,
            'chainId': 19,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/19/0xb2987753D1561570f726Aa373F48E77e27aa5FF4/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin'
            ]
        },
        {
            'symbol': 'ORACLE',
            'name': 'OracleSwap',
            'address': '0xD7565b16b65376e2Ddb6c71E7971c7185A7Ff3Ff',
            'decimals': 18,
            'chainId': 19,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/19/0xD7565b16b65376e2Ddb6c71E7971c7185A7Ff3Ff/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin'
            ]
        },
        {
            'symbol': 'EXFI',
            'name': 'Experimental Finance Token',
            'address': '0xC348F894d0E939FE72c467156E6d7DcbD6f16e21',
            'decimals': 18,
            'chainId': 19,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/19/0xC348F894d0E939FE72c467156E6d7DcbD6f16e21/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin'
            ]
        },
        {
            'symbol': 'HS',
            'name': 'Honey Stick',
            'address': '0x9dC8639bff70B019088f0b7D960561654269B5BE',
            'decimals': 18,
            'chainId': 19,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/19/0x9dC8639bff70B019088f0b7D960561654269B5BE/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin'
            ]
        },
        {
            'symbol': 'SFORT',
            'name': 'sFortuna',
            'address': '0x9E2E6c16803878C18E54Ed74F05AeafCCe464626',
            'decimals': 18,
            'chainId': 19,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/19/0x9E2E6c16803878C18E54Ed74F05AeafCCe464626/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin'
            ]
        },
        {
            'symbol': 'CNYX',
            'name': 'CanaryX',
            'address': '0x8d32E20d119d936998575B4AAff66B9999011D27',
            'decimals': 18,
            'chainId': 19,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/19/0x8d32E20d119d936998575B4AAff66B9999011D27/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin'
            ]
        },
        {
            'symbol': 'sDOOD',
            'name': 'sDOOD Token',
            'address': '0x697bb3B5E1eCf4fEbE6016321b0648d3d6C270B6',
            'decimals': 18,
            'chainId': 19,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/19/0x697bb3B5E1eCf4fEbE6016321b0648d3d6C270B6/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin'
            ]
        },
        {
            'symbol': 'OCN',
            'name': 'Ocean',
            'address': '0x671ee7ac6ef2803776dD1711e577540A6007cC25',
            'decimals': 18,
            'chainId': 19,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/19/0x671ee7ac6ef2803776dD1711e577540A6007cC25/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin'
            ]
        },
        {
            'symbol': 'WSGB',
            'name': 'Wrapped Songbird',
            'address': '0x02f0826ef6aD107Cfc861152B32B52fD11BaB9ED',
            'decimals': 18,
            'chainId': 19,
            'logoURI': 'https://raw.githubusercontent.com/pangolindex/tokens/main/assets/19/0x02f0826ef6aD107Cfc861152B32B52fD11BaB9ED/logo_24.png',
            'coingeckoId': null,
            'listedIn': [
                'pangolin'
            ]
        }
    ],
    '321': [
        {
            'symbol': 'KUST',
            'name': 'KUST',
            'address': '0xfC56A7E70F6c970538020cc39939929b4d393F1F',
            'decimals': 18,
            'chainId': 321,
            'logoURI': 'https://elk.finance/tokens/logos/kcc/0xfC56A7E70F6c970538020cc39939929b4d393F1F/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'WBTC',
            'name': 'WBTC',
            'address': '0xfA93C12Cd345c658bc4644D1D4E1B9615952258C',
            'decimals': 18,
            'chainId': 321,
            'logoURI': 'https://assets.coingecko.com/coins/images/7598/small/wrapped_bitcoin_wbtc.png?1548822744',
            'coingeckoId': null,
            'listedIn': [
                'xyfinance'
            ]
        },
        {
            'symbol': 'WETH',
            'name': 'WETH',
            'address': '0xf55aF137A98607F7ED2eFEfA4cd2DfE70E4253b1',
            'decimals': 18,
            'chainId': 321,
            'logoURI': 'https://assets.coingecko.com/coins/images/2518/small/weth.png?1628852295',
            'coingeckoId': 'weth',
            'listedIn': [
                'xyfinance'
            ]
        },
        {
            'symbol': 'ELK',
            'name': 'Elk',
            'address': '0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE',
            'decimals': 18,
            'chainId': 321,
            'logoURI': 'https://elk.finance/tokens/logos/kcc/0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SFI',
            'name': 'Spice',
            'address': '0xd55D9DF77B23A7455613f2244Ee4B6a45b6e2D00',
            'decimals': 18,
            'chainId': 321,
            'logoURI': 'https://elk.finance/tokens/logos/kcc/0xd55D9DF77B23A7455613f2244Ee4B6a45b6e2D00/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'mojitoswap',
                'elkfinance'
            ]
        },
        {
            'symbol': 'COOHA',
            'name': 'CoolHash',
            'address': '0xd0F2e8CF77C1d3DCC4d2d03f99D959bb5cE496BF',
            'decimals': 18,
            'chainId': 321,
            'logoURI': 'https://assets.coingecko.com/coins/images/23894/small/fjPuciCc_400x400.jpg?1645607690',
            'coingeckoId': 'coolmining',
            'listedIn': [
                'mojitoswap',
                'xyfinance'
            ]
        },
        {
            'symbol': 'KOFFEE',
            'name': 'KOFFEE',
            'address': '0xc0ffeE0000921eB8DD7d506d4dE8D5B79b856157',
            'decimals': 18,
            'chainId': 321,
            'logoURI': 'https://elk.finance/tokens/logos/kcc/0xc0ffeE0000921eB8DD7d506d4dE8D5B79b856157/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'BABYDOGE',
            'name': 'BABYDOGE Token',
            'address': '0xb2c22A9fb4FC02eb9D1d337655Ce079a04a526C7',
            'decimals': 9,
            'chainId': 321,
            'logoURI': 'https://assets.coingecko.com/coins/images/22087/small/1_Wyot-SDGZuxbjdkaOeT2-A.png?1640764238',
            'coingeckoId': null,
            'listedIn': [
                'mojitoswap',
                'xyfinance'
            ]
        },
        {
            'symbol': 'SAND',
            'name': 'SAND',
            'address': '0xb12c13e66AdE1F72f71834f2FC5082Db8C091358',
            'decimals': 18,
            'chainId': 321,
            'logoURI': 'https://assets.coingecko.com/coins/images/12129/small/sandbox_logo.jpg?1597397942',
            'coingeckoId': null,
            'listedIn': [
                'xyfinance'
            ]
        },
        {
            'symbol': 'UNI',
            'name': 'UNI',
            'address': '0xEe58E4D62b10A92dB1089d4D040B759C28aE16Cd',
            'decimals': 18,
            'chainId': 321,
            'logoURI': 'https://assets.coingecko.com/coins/images/12504/small/uniswap-uni.png?1600306604',
            'coingeckoId': null,
            'listedIn': [
                'xyfinance'
            ]
        },
        {
            'symbol': 'oELK',
            'name': 'Old Elk',
            'address': '0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C',
            'decimals': 18,
            'chainId': 321,
            'logoURI': 'https://elk.finance/tokens/logos/kcc/0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'APE',
            'name': 'APECOIN',
            'address': '0xDAe6c2A48BFAA66b43815c5548b10800919c993E',
            'decimals': 18,
            'chainId': 321,
            'logoURI': 'https://assets.coingecko.com/coins/images/24383/small/apecoin.jpg?1647476455',
            'coingeckoId': null,
            'listedIn': [
                'mojitoswap',
                'xyfinance'
            ]
        },
        {
            'symbol': 'TIKU',
            'name': 'TIKU Token',
            'address': '0xD4b52510719C594514CE7FED6CC876C03278cCf8',
            'decimals': 18,
            'chainId': 321,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'mojitoswap'
            ]
        },
        {
            'symbol': 'MANA',
            'name': 'MANA',
            'address': '0xC19a5caCC2bb68Ff09f2Fcc695F31493A039Fa5e',
            'decimals': 18,
            'chainId': 321,
            'logoURI': 'https://assets.coingecko.com/coins/images/878/small/decentraland-mana.png?1550108745',
            'coingeckoId': null,
            'listedIn': [
                'xyfinance'
            ]
        },
        {
            'symbol': 'KUD',
            'name': 'Kudex',
            'address': '0xBd451b952dE19F2C7bA2c8c516b0740484E953B2',
            'decimals': 18,
            'chainId': 321,
            'logoURI': 'https://elk.finance/tokens/logos/kcc/0xBd451b952dE19F2C7bA2c8c516b0740484E953B2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USDC',
            'address': '0x980a5AfEf3D17aD98635F6C5aebCBAedEd3c3430',
            'decimals': 18,
            'chainId': 321,
            'logoURI': 'https://elk.finance/tokens/logos/kcc/0x980a5AfEf3D17aD98635F6C5aebCBAedEd3c3430/logo.png',
            'coingeckoId': 'usd-coin',
            'listedIn': [
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'MLS',
            'name': 'MetaLand Shares',
            'address': '0x974E54266708292383C2989DCA5FDbb115666D4f',
            'decimals': 18,
            'chainId': 321,
            'logoURI': null,
            'coingeckoId': 'pikaster',
            'listedIn': [
                'mojitoswap'
            ]
        },
        {
            'symbol': 'ESW',
            'name': 'EmiSwap - EmiDao Token',
            'address': '0x8933a6e58eEeE063B5fd3221F2E1D17821Dc1031',
            'decimals': 18,
            'chainId': 321,
            'logoURI': 'https://elk.finance/tokens/logos/kcc/0x8933a6e58eEeE063B5fd3221F2E1D17821Dc1031/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SAX',
            'name': 'IdleStone age Token',
            'address': '0x8367B781316eDb622DB3847f863e610edf1f4a20',
            'decimals': 18,
            'chainId': 321,
            'logoURI': 'https://cdn.jsdelivr.net/gh/MojitoFinance/mjtTokenList@master/sax.png',
            'coingeckoId': 'sax-token',
            'listedIn': [
                'mojitoswap',
                'xyfinance'
            ]
        },
        {
            'symbol': 'SHIBA',
            'name': 'SHIBA',
            'address': '0x73b6086955c820370A18002F60E9b51FB67d7e1A',
            'decimals': 18,
            'chainId': 321,
            'logoURI': 'https://assets.coingecko.com/coins/images/11939/small/shiba.png?1622619446',
            'coingeckoId': null,
            'listedIn': [
                'xyfinance'
            ]
        },
        {
            'symbol': 'KUDO',
            'name': 'KuDoge',
            'address': '0x6665D66aFA48F527d86623723342CfA258cB8666',
            'decimals': 18,
            'chainId': 321,
            'logoURI': 'https://elk.finance/tokens/logos/kcc/0x6665D66aFA48F527d86623723342CfA258cB8666/logo.png',
            'coingeckoId': 'kudoge',
            'listedIn': [
                'mojitoswap',
                'elkfinance'
            ]
        },
        {
            'symbol': 'BORING',
            'name': 'BoringDAO',
            'address': '0x5D78bF8f79A66e43f5932c1Ae0b8fA6563F97f74',
            'decimals': 18,
            'chainId': 321,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'mojitoswap'
            ]
        },
        {
            'symbol': 'KUCINO',
            'name': 'Kucino Casino',
            'address': '0x4c9fa6297D3413FbbE7bA935e14022d0e5a39DC7',
            'decimals': 18,
            'chainId': 321,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'mojitoswap'
            ]
        },
        {
            'symbol': 'PPI',
            'name': 'Swappi',
            'address': '0x4E720DD3Ac5CFe1e1fbDE4935f386Bb1C66F4642',
            'decimals': 18,
            'chainId': 321,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'mojitoswap'
            ]
        },
        {
            'symbol': 'CFX',
            'name': 'Conflux',
            'address': '0x4792C1EcB969B036eb51330c63bD27899A13D84e',
            'decimals': 18,
            'chainId': 321,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'mojitoswap'
            ]
        },
        {
            'symbol': 'LINK',
            'name': 'LINK',
            'address': '0x47841910329aaa6b88D5e9DcdE9000195151dc72',
            'decimals': 18,
            'chainId': 321,
            'logoURI': 'https://assets.coingecko.com/coins/images/877/small/chainlink-new-logo.png?1547034700',
            'coingeckoId': null,
            'listedIn': [
                'xyfinance'
            ]
        },
        {
            'symbol': 'PENCIL',
            'name': 'Pencil DAO Token',
            'address': '0x44a6D299EEd7ac941f090257380eE1d1934FAdbE',
            'decimals': 18,
            'chainId': 321,
            'logoURI': null,
            'coingeckoId': 'pencil-dao',
            'listedIn': [
                'mojitoswap'
            ]
        },
        {
            'symbol': 'KCS',
            'name': 'KCS Token',
            'address': '0x4446Fc4eb47f2f6586f9fAAb68B3498F86C07521',
            'decimals': 18,
            'chainId': 321,
            'logoURI': 'https://elk.finance/tokens/logos/kcc/0x4446Fc4eb47f2f6586f9fAAb68B3498F86C07521/logo.png',
            'coingeckoId': 'wrapped-kcs',
            'listedIn': [
                'mojitoswap',
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'SNX',
            'name': 'SNX',
            'address': '0x31965b5c9c55f5579eb49F4b3AcC59aA10a7B98E',
            'decimals': 18,
            'chainId': 321,
            'logoURI': 'https://assets.coingecko.com/coins/images/3406/small/SNX.png?1598631139',
            'coingeckoId': null,
            'listedIn': [
                'xyfinance'
            ]
        },
        {
            'symbol': 'MJT',
            'name': 'MJT',
            'address': '0x2cA48b4eeA5A731c2B54e7C3944DBDB87c0CFB6F',
            'decimals': 18,
            'chainId': 321,
            'logoURI': 'https://assets.coingecko.com/coins/images/21662/small/Y--WYD4r_400x400.jpg?1639698983',
            'coingeckoId': 'mojitoswap',
            'listedIn': [
                'xyfinance'
            ]
        },
        {
            'symbol': 'COOGA',
            'name': 'CoolGas',
            'address': '0x23e1aCD3D979025cf38C5A1B4A9df20ecAF32d5D',
            'decimals': 18,
            'chainId': 321,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'mojitoswap'
            ]
        },
        {
            'symbol': 'OLE',
            'name': 'Openleverage',
            'address': '0x1CcCA1cE62c62F7Be95d4A67722a8fDbed6EEcb4',
            'decimals': 18,
            'chainId': 321,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'mojitoswap'
            ]
        },
        {
            'symbol': 'KuDOS',
            'name': 'KuDOS',
            'address': '0x1B465775469C71b898Bb51B53b2D4464b7Cd3448',
            'decimals': 18,
            'chainId': 321,
            'logoURI': 'https://elk.finance/tokens/logos/kcc/0x1B465775469C71b898Bb51B53b2D4464b7Cd3448/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'KOGE',
            'name': 'KogeCoin',
            'address': '0x06893639c09CB7Eae1B384DC6DC2EF7fE1473f2E',
            'decimals': 18,
            'chainId': 321,
            'logoURI': null,
            'coingeckoId': null,
            'listedIn': [
                'mojitoswap'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'USDT',
            'address': '0x0039f574eE5cC39bdD162E9A88e3EB1f111bAF48',
            'decimals': 18,
            'chainId': 321,
            'logoURI': 'https://elk.finance/tokens/logos/kcc/0x0039f574eE5cC39bdD162E9A88e3EB1f111bAF48/logo.png',
            'coingeckoId': 'tether',
            'listedIn': [
                'xyfinance',
                'elkfinance'
            ]
        },
        {
            'symbol': 'KCS',
            'name': 'KCS',
            'address': '0x0000000000000000000000000000000000000000',
            'decimals': 18,
            'chainId': 321,
            'logoURI': 'https://assets.coingecko.com/coins/images/1047/small/sa9z79.png?1610678720',
            'coingeckoId': 'kucoin-shares',
            'listedIn': [
                'xyfinance'
            ]
        }
    ],
    '-2': [
        {
            'symbol': 'ZOD',
            'name': 'Zod Fungible Token',
            'address': 'zod.near',
            'decimals': 24,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/zod.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'xREF',
            'name': 'xRef Finance Token',
            'address': 'xtoken.ref-finance.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/xtoken.ref-finance.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'WST',
            'name': 'Wolves Token',
            'address': 'wst.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/wst.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'wNEAR',
            'name': 'Wrapped NEAR fungible token',
            'address': 'wrap.near',
            'decimals': 24,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/wrap.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'WHALES',
            'name': 'NEAR WHALES',
            'address': 'whales.tkn.near',
            'decimals': 4,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/whales.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'WAFI',
            'name': 'Water & Fire Token',
            'address': 'wafi.tkn.near',
            'decimals': 10,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/wafi.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'nUSDO',
            'name': 'nUSDO',
            'address': 'v3.oin_finance.near',
            'decimals': 8,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/v3.oin_finance.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'POTATO',
            'name': 'Potato ð¥',
            'address': 'v1.dacha-finance.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/v1.dacha-finance.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'REF',
            'name': 'Ref Finance Token',
            'address': 'token.v2.ref-finance.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/token.v2.ref-finance.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SKYWARD',
            'name': 'Skyward Finance Token',
            'address': 'token.skyward.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/token.skyward.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SHRM',
            'name': 'Shroom Kingdom',
            'address': 'token.shrm.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/token.shrm.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PARAS',
            'name': 'PARAS',
            'address': 'token.paras.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/token.paras.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'Cheddar',
            'name': 'Cheddar',
            'address': 'token.cheddar.near',
            'decimals': 24,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/token.cheddar.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'JAR',
            'name': 'Jargon',
            'address': 'tipjargon.near',
            'decimals': 7,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/tipjargon.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PSNG',
            'name': 'PISANG Coin',
            'address': 'themunkymonkey.near',
            'decimals': 8,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/themunkymonkey.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'TER',
            'name': 'SkyNet',
            'address': 'ter.tkn.near',
            'decimals': 10,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/ter.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'TCKT',
            'name': 'Mintickt',
            'address': 'tckt.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/tckt.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'TAMIL',
            'name': 'TAMIL CRYPTO',
            'address': 'tamil.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/tamil.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'TALIBAN',
            'name': 'Taliban Token',
            'address': 'taliban.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/taliban.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'STKE',
            'name': 'sticky',
            'address': 'stke.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/stke.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SOCIALMEET',
            'name': 'SOCIALMEET',
            'address': 'socialmeet.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/socialmeet.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SLUSH',
            'name': 'Slushie',
            'address': 'slush.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/slush.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'sPXT',
            'name': 'Skyward Wrapped Pixeltoken',
            'address': 'skyward-pixeltoken.near',
            'decimals': 6,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/skyward-pixeltoken.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SHMEKS',
            'name': 'Schmeckels',
            'address': 'shmeks.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/shmeks.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SHIT',
            'name': 'Pile of Shit (rare)',
            'address': 'shit.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/shit.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'REKT',
            'name': 'Rekt Token',
            'address': 'rekt.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/rekt.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'Ralfusha',
            'name': 'Ralfusha token',
            'address': 'ralfusha.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/ralfusha.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PW',
            'name': 'Portis Wallet Token',
            'address': 'pw.tkn.near',
            'decimals': 10,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/pw.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PUTT',
            'name': 'Puttcoinz',
            'address': 'putt.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/putt.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'pixiv',
            'name': 'pixi',
            'address': 'pixiv.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/pixiv.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PXT',
            'name': 'Pixeltoken',
            'address': 'pixeltoken.near',
            'decimals': 6,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/pixeltoken.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PIXEL',
            'name': 'Pixel Token',
            'address': 'pixel.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/pixel.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PANDA',
            'name': 'Panda Token',
            'address': 'panda.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/panda.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PADTHAI',
            'name': 'Padthai Protocol Token',
            'address': 'padthai.near',
            'decimals': 8,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/padthai.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'O',
            'name': 'Omicron',
            'address': 'o.tkn.near',
            'decimals': 0,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/o.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'NVP',
            'name': 'NOVAPUNK',
            'address': 'nvp.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/nvp.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'NVISION',
            'name': 'NVISION',
            'address': 'nvision.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/nvision.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'NVC',
            'name': 'nVCELKA',
            'address': 'nvc.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/nvc.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'NUT',
            'name': 'NEAR University Token',
            'address': 'nut.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/nut.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'nTRASH',
            'name': 'NEAR TRASH',
            'address': 'ntrash.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/ntrash.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'nMEME',
            'name': 'NEAR MEME DAILY',
            'address': 'nmeme.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/nmeme.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'NK',
            'name': 'NearKat',
            'address': 'nk.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/nk.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'NGMI',
            'name': 'N.G.M.I.',
            'address': 'ngmi.tkn.near',
            'decimals': 10,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/ngmi.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'Net',
            'name': 'near express',
            'address': 'net.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/net.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'NEIR',
            'name': 'NEIR',
            'address': 'neir.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/neir.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'NEARPUNK',
            'name': 'NEARPUNK',
            'address': 'nearpunk.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/nearpunk.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'NEARKAT',
            'name': 'NEARKAT',
            'address': 'nearkat.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/nearkat.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'neardoge',
            'name': 'neardoge',
            'address': 'neardoge.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/neardoge.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'NEARDOG',
            'name': 'NEARDOG',
            'address': 'neardog.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/neardog.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'NEARBIT',
            'name': 'NEARBIT',
            'address': 'nearbit.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/nearbit.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'Near',
            'name': 'Near',
            'address': 'near',
            'decimals': 24,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/near/logo.png',
            'coingeckoId': 'near',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ND',
            'name': 'NearDog',
            'address': 'nd.tkn.near',
            'decimals': 8,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/nd.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'mz',
            'name': 'Meinen Zutrunk!',
            'address': 'mz.tkn.near',
            'decimals': 0,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/mz.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MYRIA',
            'name': 'Myria',
            'address': 'myriadcore.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/myriadcore.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MRKTNG',
            'name': 'Marketing token',
            'address': 'mrktng.tkn.near',
            'decimals': 6,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/mrktng.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MIKA',
            'name': 'Mikasa',
            'address': 'mika.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/mika.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'METACOIN',
            'name': 'Metacoin',
            'address': 'metacoin.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/metacoin.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': '$META',
            'name': 'Meta Token',
            'address': 'meta-token.near',
            'decimals': 24,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/meta-token.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'STNEAR',
            'name': 'Staked NEAR',
            'address': 'meta-pool.near',
            'decimals': 24,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/meta-pool.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MERITOCRACY',
            'name': 'MERITOCRACY',
            'address': 'meritocracy.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/meritocracy.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MEME',
            'name': 'MEME ðð Universal Idea Token',
            'address': 'meme.tkn.near',
            'decimals': 8,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/meme.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MBGA',
            'name': 'Make Blockchain Great Again',
            'address': 'mbga.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/mbga.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'marmaj',
            'name': 'marma j token',
            'address': 'marmaj.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/marmaj.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MAGIC',
            'name': 'Magic Token',
            'address': 'magic.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/magic.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'LUCK',
            'name': 'Luck Token',
            'address': 'lucky_nft.near',
            'decimals': 2,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/lucky_nft.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'LGBT',
            'name': 'HeyðheyðHoðhoðHomophobia\'sðgotðtoðgoð',
            'address': 'lgbt.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/lgbt.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'LFG',
            'name': 'L.F.G.!',
            'address': 'lfg.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/lfg.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'KVLAD',
            'name': 'KryptoVlada',
            'address': 'kvlad.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/kvlad.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'JBOUW',
            'name': 'The Original Joshua J. Bouw Cred Points',
            'address': 'jbouw.tkn.near',
            'decimals': 0,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/jbouw.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'INFINITY',
            'name': 'ð´INFINITY',
            'address': 'infinity.tkn.near',
            'decimals': 10,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/infinity.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'Indulgency',
            'name': 'Forgive Yourself',
            'address': 'indulgency.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/indulgency.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'IlliaPolosukhin',
            'name': 'Illia Polosukhin',
            'address': 'illiapolosukhin.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/illiapolosukhin.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'IKENGA',
            'name': ' DEDE',
            'address': 'ikenga.tkn.near',
            'decimals': 24,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/ikenga.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'hongdou',
            'name': 'hongdou',
            'address': 'hongdou.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/hongdou.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'HODL',
            'name': 'HODL',
            'address': 'hodl.tkn.near',
            'decimals': 1,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/hodl.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'HAK',
            'name': 'Hakuna Matata',
            'address': 'hak.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/hak.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'GZA',
            'name': 'Gazua',
            'address': 'gza.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/gza.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'GROOVE',
            'name': 'Groove',
            'address': 'groove.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/groove.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'GOLD',
            'name': 'LandtoEmpire.com game GOLD!',
            'address': 'gold.l2e.near',
            'decimals': 0,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/gold.l2e.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'GEM',
            'name': 'LandtoEmpire.com game GEM!',
            'address': 'gems.l2e.near',
            'decimals': 4,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/gems.l2e.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'GACHI',
            'name': 'gachinuchi',
            'address': 'gachi.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/gachi.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'frigg',
            'name': 'frigg',
            'address': 'frigg.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/frigg.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FLOKI',
            'name': 'FLOKINEAR',
            'address': 'floki.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/floki.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FLOBO',
            'name': 'Flobo',
            'address': 'flobo.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/flobo.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FLCT',
            'name': 'Falcon Token',
            'address': 'flct.tkn.near',
            'decimals': 0,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/flct.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FIRERUNE',
            'name': 'FIRE RUNE',
            'address': 'firerune.tkn.near',
            'decimals': 9,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/firerune.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FERAL',
            'name': 'Feral',
            'address': 'feral.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/feral.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'CUCUMBER',
            'name': 'Cucumber',
            'address': 'farm.berryclub.ek.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/farm.berryclub.ek.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FAR',
            'name': 'FAR',
            'address': 'far.tkn.near',
            'decimals': 8,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/far.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'OCT',
            'name': 'Octopus Network Token',
            'address': 'f5cfbc74057c610c8ef151a439252680ac68c6dc.factory.bridge.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/f5cfbc74057c610c8ef151a439252680ac68c6dc.factory.bridge.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ELIXIR',
            'name': 'LandtoEmpire.com game ELIXIR!',
            'address': 'elixir.l2e.near',
            'decimals': 0,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/elixir.l2e.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DUCK',
            'name': 'DUCK',
            'address': 'duck.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/duck.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'GTC',
            'name': 'Gitcoin',
            'address': 'de30da39c46104798bb5aa3fe8b9e0e1f348163f.factory.bridge.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/de30da39c46104798bb5aa3fe8b9e0e1f348163f.factory.bridge.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DBIO',
            'name': 'Debio',
            'address': 'dbio.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/dbio.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'dan',
            'name': 'Danny Token',
            'address': 'dan.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/dan.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DAMN',
            'name': 'DAMN',
            'address': 'damn.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/damn.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'Tether USD',
            'address': 'dac17f958d2ee523a2206206994597c13d831ec7.factory.bridge.near',
            'decimals': 6,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/dac17f958d2ee523a2206206994597c13d831ec7.factory.bridge.near/logo.png',
            'coingeckoId': 'tether',
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'HAPI',
            'name': 'HAPI',
            'address': 'd9c2d319cd7e6177336b0a9c93c21cb48d84fb54.factory.bridge.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/d9c2d319cd7e6177336b0a9c93c21cb48d84fb54.factory.bridge.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'D3G3N',
            'name': 'DEGENERATE PONZI SCAM SECOND DOG COIN',
            'address': 'd3g3n.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/d3g3n.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'CZDOG',
            'name': 'Crazy Dog',
            'address': 'czdog.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/czdog.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'CTZN',
            'name': 'Citizen Token',
            'address': 'ctzn.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/ctzn.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'CHF',
            'name': 'Helvetia',
            'address': 'chf.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/chf.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'cheems',
            'name': 'cheems',
            'address': 'cheems.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/cheems.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'CAT',
            'name': 'Catalyst',
            'address': 'cat.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/cat.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'GRT',
            'name': 'Graph Token',
            'address': 'c944e90c64b2c07662a292be6244bdf05cda44a7.factory.bridge.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/c944e90c64b2c07662a292be6244bdf05cda44a7.factory.bridge.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'WETH',
            'name': 'Wrapped Ether',
            'address': 'c02aaa39b223fe8d0a0e5c4f27ead9083c756cc2.factory.bridge.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/c02aaa39b223fe8d0a0e5c4f27ead9083c756cc2.factory.bridge.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BXF',
            'name': 'Super puper token',
            'address': 'bxf.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/bxf.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BSA',
            'name': 'BigShortAlliance',
            'address': 'bsa.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/bsa.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BOO',
            'name': 'Pomeranian',
            'address': 'boo.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/boo.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'bones',
            'name': 'Chicken Bones',
            'address': 'bones.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/bones.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BLAZE',
            'name': 'BlazeN Fast',
            'address': 'blaze.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/blaze.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BANANA',
            'name': 'Banana',
            'address': 'berryclub.ek.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/berryclub.ek.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BEER',
            'name': 'Beer Token',
            'address': 'beer.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/beer.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'MRCH',
            'name': 'MerchDAO',
            'address': 'bed4ab0019ff361d83ddeb74883dac8a70f5ea1e.factory.bridge.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/bed4ab0019ff361d83ddeb74883dac8a70f5ea1e.factory.bridge.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'bHOME',
            'name': 'bHome',
            'address': 'b8919522331c59f5c16bdfaa6a121a6e03a91f62.factory.bridge.near',
            'decimals': 6,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/b8919522331c59f5c16bdfaa6a121a6e03a91f62.factory.bridge.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FLA',
            'name': 'Flora',
            'address': 'azerotha.near',
            'decimals': 8,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/azerotha.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'AVRIT',
            'name': 'Avrit fungible token',
            'address': 'avrit.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/avrit.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'AVB',
            'name': 'AVB',
            'address': 'avb.tkn.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/avb.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ETH',
            'name': 'Ether',
            'address': 'aurora',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/aurora/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ASC',
            'name': 'Art Stars Club',
            'address': 'asc.tkn.near',
            'decimals': 10,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/asc.tkn.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'AD',
            'name': 'AD#7: Roketo is going to the MOON! https://roke.to',
            'address': 'adtoken.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/adtoken.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'ABR',
            'name': 'ABR',
            'address': 'abr.a11bd.near',
            'decimals': 24,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/abr.a11bd.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'AURORA',
            'name': 'Aurora',
            'address': 'aaaaaa20d9e0e2461697782ef11675f668207961.factory.bridge.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/aaaaaa20d9e0e2461697782ef11675f668207961.factory.bridge.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': '1MIL',
            'name': '1MILNFT',
            'address': 'a4ef4b0b23c1fc81d3f9ecf93510e64f58a4a016.factory.bridge.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/a4ef4b0b23c1fc81d3f9ecf93510e64f58a4a016.factory.bridge.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USD Coin',
            'address': 'a0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.factory.bridge.near',
            'decimals': 6,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/a0b86991c6218b36c1d19d4a2e9eb0ce3606eb48.factory.bridge.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'OIN',
            'name': 'oinfinance',
            'address': '9aeb50f542050172359a0e1a25a9933bc8c01259.factory.bridge.near',
            'decimals': 8,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/9aeb50f542050172359a0e1a25a9933bc8c01259.factory.bridge.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'HT',
            'name': 'HuobiToken',
            'address': '6f259637dcd74c767781e37bc6133cd6a68aa161.factory.bridge.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/6f259637dcd74c767781e37bc6133cd6a68aa161.factory.bridge.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'DAI',
            'name': 'Dai Stablecoin',
            'address': '6b175474e89094c44da98b954eedeac495271d0f.factory.bridge.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/6b175474e89094c44da98b954eedeac495271d0f.factory.bridge.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'PULSE',
            'name': 'Pulse',
            'address': '52a047ee205701895ee06a375492490ec9c597ce.factory.bridge.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/52a047ee205701895ee06a375492490ec9c597ce.factory.bridge.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'LINK',
            'name': 'ChainLink Token',
            'address': '514910771af9ca656af840dff83e8264ecf986ca.factory.bridge.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/514910771af9ca656af840dff83e8264ecf986ca.factory.bridge.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'SOLACE',
            'name': 'solace',
            'address': '501ace9c35e60f03a2af4d484f49f9b1efde9f40.factory.bridge.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/501ace9c35e60f03a2af4d484f49f9b1efde9f40.factory.bridge.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'FLX',
            'name': 'Flux Token',
            'address': '3ea8ea4237344c9931214796d9417af1a1180770.factory.bridge.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/3ea8ea4237344c9931214796d9417af1a1180770.factory.bridge.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'GWJ',
            'name': 'GREATWANGJING',
            'address': '288f5c0fc03d073378d004201129bc145a4a82fc.factory.bridge.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/288f5c0fc03d073378d004201129bc145a4a82fc.factory.bridge.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'WBTC',
            'name': 'Wrapped BTC',
            'address': '2260fac5e5542a773aa44fbcfedf7c193bc2c599.factory.bridge.near',
            'decimals': 8,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/2260fac5e5542a773aa44fbcfedf7c193bc2c599.factory.bridge.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'UNI',
            'name': 'Uniswap',
            'address': '1f9840a85d5af5bf1d1762f925bdaddc4201f984.factory.bridge.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/1f9840a85d5af5bf1d1762f925bdaddc4201f984.factory.bridge.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': '1INCH',
            'name': '1INCH Token',
            'address': '111111111117dc0aa78b770fa6a738034120c302.factory.bridge.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/111111111117dc0aa78b770fa6a738034120c302.factory.bridge.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        },
        {
            'symbol': 'BAT',
            'name': 'Basic Attention Token',
            'address': '0d8775f648430679a709e98d2b0cb6250d2887ef.factory.bridge.near',
            'decimals': 18,
            'chainId': -2,
            'logoURI': 'https://api.rubic.exchange/assets/near/0d8775f648430679a709e98d2b0cb6250d2887ef.factory.bridge.near/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'rubic'
            ]
        }
    ],
    '199': [
        {
            'symbol': 'ELK',
            'name': 'Elk',
            'address': '0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE',
            'decimals': 18,
            'chainId': 199,
            'logoURI': 'https://elk.finance/tokens/logos/kava/0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'wBTT',
            'name': 'Wrapped BTT',
            'address': '0x8D193c6efa90BCFf940A98785d1Ce9D093d3DC8A',
            'decimals': 18,
            'chainId': 199,
            'logoURI': 'https://elk.finance/tokens/logos/bttc/0x8D193c6efa90BCFf940A98785d1Ce9D093d3DC8A/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'USDD_t',
            'name': 'Decentralized USD_TRON',
            'address': '0x17F235FD5974318E4E2a5e37919a209f7c37A6d1',
            'decimals': 18,
            'chainId': 199,
            'logoURI': 'https://elk.finance/tokens/logos/bttc/0x17F235FD5974318E4E2a5e37919a209f7c37A6d1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        }
    ],
    '20': [
        {
            'symbol': 'HT',
            'name': 'HT on Elastos',
            'address': '0xeceefC50f9aAcF0795586Ed90a8b9E24f55Ce3F3',
            'decimals': 18,
            'chainId': 20,
            'logoURI': 'https://elk.finance/tokens/logos/elastos/0xeceefC50f9aAcF0795586Ed90a8b9E24f55Ce3F3/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ELK',
            'name': 'Elk',
            'address': '0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE',
            'decimals': 18,
            'chainId': 20,
            'logoURI': 'https://elk.finance/tokens/logos/elastos/0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'GLIDE',
            'name': 'Glide',
            'address': '0xd39eC832FF1CaaFAb2729c76dDeac967ABcA8F27',
            'decimals': 18,
            'chainId': 20,
            'logoURI': 'https://elk.finance/tokens/logos/elastos/0xd39eC832FF1CaaFAb2729c76dDeac967ABcA8F27/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'HUSD',
            'name': 'HUSD on Elastos',
            'address': '0xF9Ca2eA3b1024c0DB31adB224B407441bECC18BB',
            'decimals': 8,
            'chainId': 20,
            'logoURI': 'https://elk.finance/tokens/logos/elastos/0xF9Ca2eA3b1024c0DB31adB224B407441bECC18BB/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'oELK',
            'name': 'Old Elk',
            'address': '0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C',
            'decimals': 18,
            'chainId': 20,
            'logoURI': 'https://elk.finance/tokens/logos/elastos/0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SLOTH',
            'name': 'Sloth',
            'address': '0xA70Efd687EADFb738389e0739a26fb9036D99A1E',
            'decimals': 18,
            'chainId': 20,
            'logoURI': 'https://elk.finance/tokens/logos/elastos/0xA70Efd687EADFb738389e0739a26fb9036D99A1E/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USDC on Elastos',
            'address': '0xA06be0F5950781cE28D965E5EFc6996e88a8C141',
            'decimals': 6,
            'chainId': 20,
            'logoURI': 'https://elk.finance/tokens/logos/elastos/0xA06be0F5950781cE28D965E5EFc6996e88a8C141/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'WETH',
            'name': 'ETH on Elastos',
            'address': '0x802c3e839E4fDb10aF583E3E759239ec7703501e',
            'decimals': 18,
            'chainId': 20,
            'logoURI': 'https://elk.finance/tokens/logos/elastos/0x802c3e839E4fDb10aF583E3E759239ec7703501e/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'WELA',
            'name': 'Wrapped Elastos',
            'address': '0x517E9e5d46C1EA8aB6f78677d6114Ef47F71f6c4',
            'decimals': 18,
            'chainId': 20,
            'logoURI': 'https://elk.finance/tokens/logos/elastos/0x517E9e5d46C1EA8aB6f78677d6114Ef47F71f6c4/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        }
    ],
    '4689': [
        {
            'symbol': 'UNI',
            'name': 'UNISWAP',
            'address': '0xedEeFAca6a1581FE2349cdFc3083D4EfA8188e55',
            'decimals': 18,
            'chainId': 4689,
            'logoURI': 'https://elk.finance/tokens/logos/iotex/0xedEeFAca6a1581FE2349cdFc3083D4EfA8188e55/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ELK',
            'name': 'Elk',
            'address': '0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE',
            'decimals': 18,
            'chainId': 4689,
            'logoURI': 'https://elk.finance/tokens/logos/iotex/0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'oELK',
            'name': 'Old Elk',
            'address': '0xe1cE1c0fa22EC693bAca6F5076bcdC4D0183DE1C',
            'decimals': 18,
            'chainId': 4689,
            'logoURI': 'https://elk.finance/tokens/logos/iotex/0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'BUSD',
            'name': 'Binance USD',
            'address': '0xacEE9B11CD4B3f57e58880277aC72c8c41ABe4e4',
            'decimals': 18,
            'chainId': 4689,
            'logoURI': 'https://elk.finance/tokens/logos/iotex/0xacEE9B11CD4B3f57e58880277aC72c8c41ABe4e4/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'WBTC',
            'name': 'Wrapped Bitcoin',
            'address': '0xC7b93720F73b037394cE00f954f849Ed484a3dEA',
            'decimals': 8,
            'chainId': 4689,
            'logoURI': 'https://elk.finance/tokens/logos/iotex/0xC7b93720F73b037394cE00f954f849Ed484a3dEA/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'VITA',
            'name': 'Vitality',
            'address': '0xB8744aE4032Be5E5ef9FaB94ee9C3bf38D5D2AE0',
            'decimals': 18,
            'chainId': 4689,
            'logoURI': 'https://elk.finance/tokens/logos/iotex/0xB8744aE4032Be5E5ef9FaB94ee9C3bf38D5D2AE0/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'WIOTX',
            'name': 'Wrapped IoTeX',
            'address': '0xA00744882684C3e4747faEFD68D283eA44099D03',
            'decimals': 18,
            'chainId': 4689,
            'logoURI': 'https://elk.finance/tokens/logos/iotex/0xA00744882684C3e4747faEFD68D283eA44099D03/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'CIOTX',
            'name': 'Crosschain IOTX',
            'address': '0x99B2B0eFb56E62E36960c20cD5ca8eC6ABD5557A',
            'decimals': 18,
            'chainId': 4689,
            'logoURI': 'https://elk.finance/tokens/logos/iotex/0x99B2B0eFb56E62E36960c20cD5ca8eC6ABD5557A/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'WBNB',
            'name': 'Wrapped Binance Coin',
            'address': '0x97e6c48867FDC391A8DFe9d169EcD005d1d90283',
            'decimals': 18,
            'chainId': 4689,
            'logoURI': 'https://elk.finance/tokens/logos/iotex/0x97e6c48867FDC391A8DFe9d169EcD005d1d90283/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'UP',
            'name': 'UP',
            'address': '0x9178f4Ec8a7fF6Fe08E848Eeac3Ddbe1a5fAc70d',
            'decimals': 18,
            'chainId': 4689,
            'logoURI': 'https://elk.finance/tokens/logos/iotex/0x9178f4Ec8a7fF6Fe08E848Eeac3Ddbe1a5fAc70d/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'WMATIC',
            'name': 'Wrapped MATIC',
            'address': '0x8e66c0d6B70C0B23d39f4B21A1eAC52BBA8eD89a',
            'decimals': 18,
            'chainId': 4689,
            'logoURI': 'https://elk.finance/tokens/logos/iotex/0x8e66c0d6B70C0B23d39f4B21A1eAC52BBA8eD89a/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'SDI',
            'name': 'SonicDiamond',
            'address': '0x888A47D81844376cbdE6737BfC702a10A2175f97',
            'decimals': 8,
            'chainId': 4689,
            'logoURI': 'https://elk.finance/tokens/logos/iotex/0x888A47D81844376cbdE6737BfC702a10A2175f97/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ZOOM',
            'name': 'ZOOM TOKEN',
            'address': '0x86702a7f8898B172de396eB304d7D81207127915',
            'decimals': 18,
            'chainId': 4689,
            'logoURI': 'https://elk.finance/tokens/logos/iotex/0x86702a7f8898B172de396eB304d7D81207127915/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'BUSDb',
            'name': 'BUSD',
            'address': '0x84ABcb2832bE606341a50128AEB1DB43AA017449',
            'decimals': 18,
            'chainId': 4689,
            'logoURI': 'https://elk.finance/tokens/logos/iotex/0x84ABcb2832bE606341a50128AEB1DB43AA017449/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'Tether USDT',
            'address': '0x6fbCdc1169B5130C59E72E51Ed68A84841C98cd1',
            'decimals': 6,
            'chainId': 4689,
            'logoURI': 'https://elk.finance/tokens/logos/iotex/0x6fbCdc1169B5130C59E72E51Ed68A84841C98cd1/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'DAIm',
            'name': 'Matic DAI Stablecoin',
            'address': '0x62a9D987Cbf4C45a550DEEd5B57b200d7a319632',
            'decimals': 18,
            'chainId': 4689,
            'logoURI': 'https://elk.finance/tokens/logos/iotex/0x62a9D987Cbf4C45a550DEEd5B57b200d7a319632/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'GFS',
            'name': 'GameFantasyStar',
            'address': '0x5d0f4cA481FD725c9BC6b415c0Ce5B3c3bD726CF',
            'decimals': 18,
            'chainId': 4689,
            'logoURI': 'https://elk.finance/tokens/logos/iotex/0x5d0f4cA481FD725c9BC6b415c0Ce5B3c3bD726CF/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'CYC',
            'name': 'Cyclone Protocol',
            'address': '0x4D7b88403AA2F502bf289584160db01ca442426c',
            'decimals': 18,
            'chainId': 4689,
            'logoURI': 'https://elk.finance/tokens/logos/iotex/0x4D7b88403AA2F502bf289584160db01ca442426c/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'iMAGIC',
            'name': 'iMAGICToken',
            'address': '0x490CfbF9b9C43633DdD1968d062996227ef438A9',
            'decimals': 18,
            'chainId': 4689,
            'logoURI': 'https://elk.finance/tokens/logos/iotex/0x490CfbF9b9C43633DdD1968d062996227ef438A9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'METX',
            'name': 'Metanyx',
            'address': '0x4752456e00def6025c77b55A88A2F8A1701F92F9',
            'decimals': 18,
            'chainId': 4689,
            'logoURI': 'https://elk.finance/tokens/logos/iotex/0x4752456e00def6025c77b55A88A2F8A1701F92F9/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'USDTm',
            'name': 'Matic Tether USD',
            'address': '0x3CDb7c48E70B854ED2Fa392E21687501D84B3AFc',
            'decimals': 6,
            'chainId': 4689,
            'logoURI': 'https://elk.finance/tokens/logos/iotex/0x3CDb7c48E70B854ED2Fa392E21687501D84B3AFc/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'USD Coin',
            'address': '0x3B2bf2b523f54C4E454F08Aa286D03115aFF326c',
            'decimals': 6,
            'chainId': 4689,
            'logoURI': 'https://elk.finance/tokens/logos/iotex/0x3B2bf2b523f54C4E454F08Aa286D03115aFF326c/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'FILDA',
            'name': 'FilDA',
            'address': '0x32085B8ea854529178bD0F4E92D3fd2475A3A159',
            'decimals': 18,
            'chainId': 4689,
            'logoURI': 'https://elk.finance/tokens/logos/iotex/0x32085B8ea854529178bD0F4E92D3fd2475A3A159/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'PAXG',
            'name': 'Paxos Gold',
            'address': '0x2a6003e4B618ff3457A4a2080D028b0249b51c80',
            'decimals': 18,
            'chainId': 4689,
            'logoURI': 'https://elk.finance/tokens/logos/iotex/0x2a6003e4B618ff3457A4a2080D028b0249b51c80/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'DAI',
            'name': 'DAI',
            'address': '0x1CbAd85Aa66Ff3C12dc84C5881886EEB29C1bb9b',
            'decimals': 18,
            'chainId': 4689,
            'logoURI': 'https://elk.finance/tokens/logos/iotex/0x1CbAd85Aa66Ff3C12dc84C5881886EEB29C1bb9b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'GFT',
            'name': 'GameFantasyToken',
            'address': '0x17Df9fBFC1CdAB0f90eDDC318C4f6FcADA730cf2',
            'decimals': 18,
            'chainId': 4689,
            'logoURI': 'https://elk.finance/tokens/logos/iotex/0x17Df9fBFC1CdAB0f90eDDC318C4f6FcADA730cf2/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ioETH',
            'name': 'ioETH',
            'address': '0x0258866edAf84D6081df17660357aB20A07d0c80',
            'decimals': 18,
            'chainId': 4689,
            'logoURI': 'https://elk.finance/tokens/logos/iotex/0x0258866edAf84D6081df17660357aB20A07d0c80/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        }
    ],
    '2222': [
        {
            'symbol': 'USDC',
            'name': 'USDC on Kava',
            'address': '0xfA9343C3897324496A05fC75abeD6bAC29f8A40f',
            'decimals': 6,
            'chainId': 2222,
            'logoURI': 'https://elk.finance/tokens/logos/kava/0xfA9343C3897324496A05fC75abeD6bAC29f8A40f/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'ELK',
            'name': 'Elk',
            'address': '0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE',
            'decimals': 18,
            'chainId': 2222,
            'logoURI': 'https://elk.finance/tokens/logos/kava/0xeEeEEb57642040bE42185f49C52F7E9B38f8eeeE/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'wKAVA',
            'name': 'Wrapped Kava',
            'address': '0xc86c7C0eFbd6A49B35E8714C5f59D99De09A225b',
            'decimals': 18,
            'chainId': 2222,
            'logoURI': 'https://elk.finance/tokens/logos/kava/0xc86c7C0eFbd6A49B35E8714C5f59D99De09A225b/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'WETH',
            'name': 'ETH on Kava',
            'address': '0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D',
            'decimals': 18,
            'chainId': 2222,
            'logoURI': 'https://elk.finance/tokens/logos/kava/0xE3F5a90F9cb311505cd691a46596599aA1A0AD7D/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'USDT on Kava',
            'address': '0xB44a9B6905aF7c801311e8F4E76932ee959c663C',
            'decimals': 6,
            'chainId': 2222,
            'logoURI': 'https://elk.finance/tokens/logos/kava/0xB44a9B6905aF7c801311e8F4E76932ee959c663C/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'LION',
            'name': 'Lion',
            'address': '0x990e157fC8a492c28F5B50022F000183131b9026',
            'decimals': 18,
            'chainId': 2222,
            'logoURI': 'https://elk.finance/tokens/logos/kava/0x990e157fC8a492c28F5B50022F000183131b9026/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'TIGER',
            'name': 'Tiger',
            'address': '0x471F79616569343e8e84a66F342B7B433b958154',
            'decimals': 18,
            'chainId': 2222,
            'logoURI': 'https://elk.finance/tokens/logos/kava/0x471F79616569343e8e84a66F342B7B433b958154/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'BEAR',
            'name': 'Bear',
            'address': '0x38481Fdc1aF61E6E72E0Ff46F069315A59779C65',
            'decimals': 18,
            'chainId': 2222,
            'logoURI': 'https://elk.finance/tokens/logos/kava/0x38481Fdc1aF61E6E72E0Ff46F069315A59779C65/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        },
        {
            'symbol': 'BUSD',
            'name': 'BUSD on Kava',
            'address': '0x332730a4F6E03D9C55829435f10360E13cfA41Ff',
            'decimals': 18,
            'chainId': 2222,
            'logoURI': 'https://elk.finance/tokens/logos/kava/0x332730a4F6E03D9C55829435f10360E13cfA41Ff/logo.png',
            'coingeckoId': null,
            'listedIn': [
                'elkfinance'
            ]
        }
    ],
    '1818': [
        {
            'symbol': 'HT',
            'name': 'Cube-Peg HT Token',
            'address': '0xFE99Ea1f1FD9C121Efb602bD4f098f9090c52909',
            'decimals': 18,
            'chainId': 1818,
            'logoURI': 'https://zenith-prd-starslabs-lendingmgt.s3.ap-northeast-1.amazonaws.com/6047A4B82A6D8DC8BABBE1160313A47D',
            'coingeckoId': null,
            'listedIn': [
                'capricorn_finance'
            ]
        },
        {
            'symbol': 'WCUBE',
            'name': 'Wrapped CUBE',
            'address': '0x9D3F61338d6Eb394e378D28C1Fd17d5909aC6591',
            'decimals': 18,
            'chainId': 1818,
            'logoURI': 'https://zenith-prd-starslabs-lendingmgt.s3.ap-northeast-1.amazonaws.com/DB7F9CBA6F44F04BAA309E89A007CCE1',
            'coingeckoId': null,
            'listedIn': [
                'capricorn_finance'
            ]
        },
        {
            'symbol': 'USDT',
            'name': 'Cube-Peg USDT Token',
            'address': '0x79F1520268A20c879EF44d169A4E3812D223C6de',
            'decimals': 18,
            'chainId': 1818,
            'logoURI': 'https://zenith-prd-starslabs-lendingmgt.s3.ap-northeast-1.amazonaws.com/30F19C1EA88D88CE1341729F22B3483C',
            'coingeckoId': null,
            'listedIn': [
                'capricorn_finance'
            ]
        },
        {
            'symbol': 'CORN',
            'name': 'CORN',
            'address': '0x6F8A58be5497c82E129B31E1d9B7604ED9b59451',
            'decimals': 18,
            'chainId': 1818,
            'logoURI': 'https://zenith-prd-starslabs-lendingmgt.s3.ap-northeast-1.amazonaws.com/1AFE1C7E211639EDB705B336F643BD92',
            'coingeckoId': null,
            'listedIn': [
                'capricorn_finance'
            ]
        },
        {
            'symbol': 'ETH',
            'name': 'Cube-Peg ETH Token',
            'address': '0x57EeA49Ec1087695274A9c4f341e414eb64328c2',
            'decimals': 18,
            'chainId': 1818,
            'logoURI': 'https://zenith-prd-starslabs-lendingmgt.s3.ap-northeast-1.amazonaws.com/B71D8601A6CCA6B57654AA2F4D9C1098',
            'coingeckoId': null,
            'listedIn': [
                'capricorn_finance'
            ]
        },
        {
            'symbol': 'DAI',
            'name': 'Cube-Peg DAI Token',
            'address': '0x3a1F6e3E6F26E92bB0D07841EB68F8E84f39751E',
            'decimals': 18,
            'chainId': 1818,
            'logoURI': 'https://zenith-prd-starslabs-lendingmgt.s3.ap-northeast-1.amazonaws.com/35976EF7931AE5B0605CFF86FBC9D2C8',
            'coingeckoId': null,
            'listedIn': [
                'capricorn_finance'
            ]
        },
        {
            'symbol': 'BTC',
            'name': 'Cube-Peg BTC Token',
            'address': '0x040eA5C10e6BA4Badb6c433A365cCC4968697230',
            'decimals': 18,
            'chainId': 1818,
            'logoURI': 'https://zenith-prd-starslabs-lendingmgt.s3.ap-northeast-1.amazonaws.com/334E60FCA050BCA747B3069F3E9769DC',
            'coingeckoId': null,
            'listedIn': [
                'capricorn_finance'
            ]
        },
        {
            'symbol': 'USDC',
            'name': 'Cube-Peg USDC Token',
            'address': '0x00f0D8595797943c12605cD59bc0D9f63D750cCf',
            'decimals': 18,
            'chainId': 1818,
            'logoURI': 'https://zenith-prd-starslabs-lendingmgt.s3.ap-northeast-1.amazonaws.com/62BE8A4DAD5FBE57E53397F909C88F01',
            'coingeckoId': null,
            'listedIn': [
                'capricorn_finance'
            ]
        }
    ]
}
]